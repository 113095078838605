<div class="row d-flex justify-content-center modalWrapper">
  <ejs-dialog
    #DialogEmailSignature
    [buttons]="buttonsEmailSignature"
    [header]="'Firemní e-mailové podpisy'"
    [isModal]="true"
    [visible]="false"
    width="85%"
  >
    <ng-template #content>
      <div class="dialogContent">
        <div
          *ngIf="htmlSignatures && htmlSignatures.length > 0"
          class="col-12"
        >
          <p class="text-muted text-center mx-auto pt-3">
            Firemní podpisy používejte pro první emailový kontakt se
            zákazníkem, klientem nebo v případě jakékoli obchodní
            komunikace. Využívejte stejným způsobem jako vizitku
            tištěnou.
          </p>
          <hr />
          <div class="row">
            <div
              *ngFor="let htmlSignature of htmlSignatures"
              class="col-12 col-md-6 mb-4"
              style="border: solid thin #eee"
            >
              <div class="d-flex justify-content-between">
                <h4>
                  <strong>{{
                      htmlSignature.company.name
                    }}</strong>
                </h4>
                <button
                  (click)="copyText(htmlSignature.id)"
                  cssClass="e-btn e-info e-outline btn-p-zero e-small float-right m-1"
                  ejs-button
                  title="zkopírovat HTML kód do schránky"
                  value="Kopírovat HTML"
                >
                  Kopírovat HTML<br /><small
                >(thunderbird)</small
                >
                </button>
              </div>
              <hr class="p-1 m-1" />
              <div contenteditable="true">
                <div
                  [innerHTML]="htmlSignature.html_stringHTML"
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
  </ejs-dialog>
  <ejs-dialog
    #confirmationDelete
    [buttons]="buttonsDelete"
    [content]="'Opravdu chcete deautorizovat tohoto uživatele?'"
    [header]="'Deautorizovat uživatele'"
    [isModal]="true"
    [showCloseIcon]="true"
    [visible]="false"
    width="400px"
  ></ejs-dialog>
  <ejs-dialog
    #confirmationReset
    [buttons]="buttonsReset"
    [content]="'Opravdu chcete resetovat heslo tohoto uživatele?'"
    [header]="'Resetovat heslo uživatele'"
    [isModal]="true"
    [showCloseIcon]="true"
    [visible]="false"
    width="400px"
  ></ejs-dialog>
  <ejs-dialog
    #confirmationAuthorized
    [buttons]="buttonsAuthorized"
    [content]="'Opravdu chcete re-autorizovat tohoto uživatele?'"
    [header]="'RE-Autorizovat uživatele'"
    [isModal]="true"
    [showCloseIcon]="true"
    [visible]="false"
    width="400px"
  ></ejs-dialog>
  <ejs-dialog
    #confirmationSyncToAcsAPI
    [buttons]="buttonsSyncToAcsAPI"
    [content]="
            'Opravdu chcete synchronizovat tento profil do systému pro přehled docházky?'
        "
    [header]="'Synchronizovat účet v systému pro přehled docházky'"
    [isModal]="true"
    [showCloseIcon]="true"
    [visible]="false"
    width="400px"
  ></ejs-dialog>
  <ejs-dialog
    #confirmationSyncToMtnAPI
    [buttons]="buttonsSyncToMtnAPI"
    [content]="
            'Opravdu chcete synchronizovat tento profil do systému pro dohled sítě?'
        "
    [header]="'Synchronizovat účet v systému pro dohled sítě'"
    [isModal]="true"
    [showCloseIcon]="true"
    [visible]="false"
    width="400px"
  ></ejs-dialog>
  <ejs-dialog
    #confirmationSyncToErpAPI
    [buttons]="buttonsSyncToErpAPI"
    [content]="
            'Opravdu chcete synchronizovat tento profil do systému pro plánování zdrojů?'
        "
    [header]="'Synchronizovat účet v systému pro plánování zdrojů'"
    [isModal]="true"
    [showCloseIcon]="true"
    [visible]="false"
    width="400px"
  ></ejs-dialog>
  <ejs-dialog
    #confirmationSyncToCrmAPI
    [buttons]="buttonsSyncToCrmAPI"
    [content]="
            'Opravdu chcete synchronizovat tento profil do systému pro správu zákazníků?'
        "
    [header]="'Synchronizovat účet v systému pro správu zákazníků'"
    [isModal]="true"
    [showCloseIcon]="true"
    [visible]="false"
    width="400px"
  ></ejs-dialog>
  <ejs-dialog
    #confirmationSyncToCarsAPI
    [buttons]="buttonsSyncToCarsAPI"
    [content]="
            'Opravdu chcete synchronizovat tento profil do systému pro správu firemních vozů?'
        "
    [header]="'Synchronizovat účet v systému pro správu firmeních vozů'"
    [isModal]="true"
    [showCloseIcon]="true"
    [visible]="false"
    width="400px"
  ></ejs-dialog>
  <ejs-dialog
    #confirmationCreateToAcsAPI
    [buttons]="buttonsCreateToAcsAPI"
    [content]="
            'Opravdu chcete vytvořit tento profil do systému pro přehled docházky?'
        "
    [header]="'Vytvořit účet v systému pro přehled docházky'"
    [isModal]="true"
    [showCloseIcon]="true"
    [visible]="false"
    width="400px"
  ></ejs-dialog>
  <ejs-dialog
    #confirmationCreateToMtnAPI
    [buttons]="buttonsCreateToMtnAPI"
    [content]="
            'Opravdu chcete vytvořit tento profil do systému pro dohled sítě?'
        "
    [header]="'Vytvořit účet v systému pro dohled sítě'"
    [isModal]="true"
    [showCloseIcon]="true"
    [visible]="false"
    width="400px"
  ></ejs-dialog>
  <ejs-dialog
    #confirmationCreateToErpAPI
    [buttons]="buttonsCreateToErpAPI"
    [content]="
            'Opravdu chcete vytvořit tento profil do systému pro plánování zdrojů?'
        "
    [header]="'>Vytvořit účet v systému pro plánování zdrojů'"
    [isModal]="true"
    [showCloseIcon]="true"
    [visible]="false"
    width="400px"
  ></ejs-dialog>
  <ejs-dialog
    #confirmationCreateToCrmAPI
    [buttons]="buttonsCreateToCrmAPI"
    [content]="
            'Opravdu chcete vytvořit tento profil do systému pro správu zákazníků?'
        "
    [header]="'Vytvořit účet v systému pro správu zákazníků'"
    [isModal]="true"
    [showCloseIcon]="true"
    [visible]="false"
    width="400px"
  ></ejs-dialog>
  <ejs-dialog
    #confirmationCreateToCarsAPI
    [buttons]="buttonsCreateToCarsAPI"
    [content]="
            'Opravdu chcete vytvořit tento profil do systému pro správu firemních vozů?'
        "
    [header]="'Vytvořit účet v systému pro správu firemních vozů'"
    [isModal]="true"
    [showCloseIcon]="true"
    [visible]="false"
    width="400px"
  ></ejs-dialog>
  <ejs-dialog
    #passwordWarning
    [header]="'Upozornění zabezpečení!'"
    [height]="'500px'"
    [isModal]="true"
    [showCloseIcon]="true"
    [visible]="false"
    [width]="'85%'"
    id="passwordWarning"
  >
    <ng-template #content>
      <div class="dialogContent">
        <h3>Vaše heslo není bezpečené!</h3>
        <div class="col-12">
          <p>Přejděte na svůj profil a pokračujte změnou hesla.</p>
        </div>
        <div class="col-12 mb-5 text-center">
          <small class="text-muted"
          >tlačítko pro změnu hesla vypadá takto:</small
          >
          <button
            class="e-btn e-primary btn-p-zero e-small col-12"
            ejs-button
          >
            <i aria-hidden="true" class="fa-light fa-user-lock"></i>
          </button>
        </div>
        <div class="col-12">
          <p>
            <strong
            ><u>Důležité!!</u> Aktualizujte si svoje telefonní
              číslo na svých profilech pomocí formuláře v pravé
              části, políčko:
              <i><u>"pracovní telefon"</u></i></strong
            >
          </p>
        </div>
      </div>
    </ng-template>
    <ng-template #footerTemplate>
      <div class="d-flex justify-content-end">
        <button
          (click)="passwordWarning.hide()"
          [routerLink]="['/users/detail/', currentUser.id]"
          cssClass="e-btn e-warning btn-p-zero e-small"
          ejs-button
        >
          Aktualizovat profil
        </button>
        <button
          (click)="passwordWarning.hide()"
          cssClass="e-btn e-danger e-outline btn-p-zero e-small"
          ejs-button
          type="button"
        >
          ZRUŠIT
        </button>
      </div>
    </ng-template>
  </ejs-dialog>
  <app-user-edit-password-form
    (passwordEditFormDialogState)="renderChangeUserPassword = $event"
    [isVisible]="renderChangeUserPassword"
    [user]="user"
  ></app-user-edit-password-form>
  <app-user-form
    (userFormDialogState)="
            renderUserFormDialog = $event;
            isCreate = $event;
            isUpdate = $event;
            isCopy = $event
        "
    [isCopy]="isCopy"
    [isCreate]="isCreate"
    [isUpdate]="isUpdate"
    [isVisible]="renderUserFormDialog"
    [user]="user && (isUpdate || isCopy) ? user : null"
  ></app-user-form>
</div>
<div class="row my-2">
  <ng-container *ngIf="user && !loadingUser">
    <div class="col-12 col-md-3">
      <div class="e-card">
        <div class="e-card-header">
          <div class="avatar w-25">
            <input
              #fileInput
              (change)="onFileChanged($event)"
              accept="image/*"
              style="display: none"
              type="file"
            />
            <div class="view overlay zoom">
              <img
                alt="profilový obrázek"
                class="img-fluid rounded-circle hoverable"
                src="assets/img{{ user.personalphoto }}"
              />
              <div class="mask flex-center rgba-black-light">
                <p
                  (click)="fileInput.click()"
                  class="white-text"
                >vyměnit</p
                >
              </div>
            </div>
          </div>
        </div>
        <div class="e-card-content text-center">
          <h5 class="pb-2 m-0 text-black-imp font-weight-bold"
          >{{ user.fullname }}</h5
          >
          <h6 class="pb-1 m-0 text-black-imp">{{
              user.official_position_name
            }}</h6>
          <ul class="list-group list-group-flush mb-2">
            <li class="list-group-item p-2">
              <div class="d-flex justify-content-center">
                <strong *ngIf="user" class="col-4">
                  <i
                    *ngIf="user.authorized"
                    aria-hidden="true"
                    class="fa-light fa-user-check text-success mr-1"
                    title="autorizovaný"
                  ></i>
                  <i
                    *ngIf="!user.authorized"
                    aria-hidden="true"
                    class="fa-light fa-user-times text-danger mr-1"
                    title="de-autorizovaný"
                  ></i>
                </strong>
                <strong *ngIf="user" class="col-4">
                  <i
                    *ngIf="user.emailverify"
                    aria-hidden="true"
                    class="fa-light fa-envelope-open text-success mr-1"
                    title="email ověřen"
                  ></i>
                  <i
                    *ngIf="!user.emailverify"
                    aria-hidden="true"
                    class="fa-light fa-envelope text-danger mr-1"
                    title="email není ověřen"
                  ></i>
                </strong>
                <strong
                  *ngIf="vacationState && !loadingVacation"
                  class="col-4 text-success"
                >
                  <i
                    *ngIf="vacationState.atWork"
                    aria-hidden="true"
                    class="fa-light fa-island-tropical mr-1 text-success"
                    title="v práci"
                  ></i>
                  <i
                    *ngIf="!vacationState.atWork"
                    aria-hidden="true"
                    class="fa-light fa-island-tropical mr-1 text-danger"
                    title="čerpání žádanky"
                  ></i>
                </strong>
                <strong *ngIf="loadingVacation" class="col-4">
                  <app-loader></app-loader>
                </strong>
              </div>
            </li>
            <li class="list-group-item p-2">
              <small>
                <strong class="text-muted">
                  <i
                    aria-hidden="true"
                    class="fa-light fa-mobile mr-1"
                  ></i>
                  <a href="tel:{{ user.telnumber }}">{{
                      user.telnumber
                    }}</a>
                </strong>
              </small>
              <br />
              <small>
                <strong class="text-muted">
                  <i
                    aria-hidden="true"
                    class="fa-light fa-envelope mr-1"
                  ></i>
                  <a href="mailto:{{ user.workemail }}">{{
                      user.workemail
                    }}</a>
                </strong>
              </small>
            </li>
          </ul>
          <ul class="list-group list-group-flush mb-2">
            <li
              *ngIf="user.employer"
              class="list-group-item text-black-imp"
            >
              <strong>zaměstnavatel:</strong><br />
              {{ user.employer.name }}
            </li>
            <li
              *ngIf="user.boss"
              class="list-group-item text-black-imp"
            >
              <strong>nadřízený:</strong><br />
              {{ user.boss.firstname }}&nbsp;{{
                user.boss.secondname
              }}
            </li>
            <li
              *ngIf="
                                user.assignations &&
                                user.assignations.length > 0
                            "
              class="list-group-item text-black-imp"
            >
              <strong>role:</strong><br />
              <ul class="list-group">
                <li
                  *ngFor="
                                        let assignation of user.assignations
                                    "
                  class="list-group-item"
                >
                  <small *ngIf="assignation.department">
                    {{
                      assignation.department.company.name
                    }}
                    / {{ assignation.department.name }} /
                    {{ assignation.role.name }}
                  </small>
                </li>
              </ul>
            </li>
          </ul>
          <div
            *ngIf="!loadingAcsApi"
            class="d-flex justify-content-center mb-2"
          >
            <ng-container
              *ngIf="
                                apis.acs &&
                                apis.acs.active &&
                                apis.acs.state &&
                                !loadingAcsApi
                            "
            >
                            <span
                            ><i
                              aria-hidden="true"
                              class="fa-light fa-lg fa-fingerprint mr-1 text-success"
                              title="{{ apis.acs.title }} online"
                            ></i
                            ></span>
              <span class="e-badge e-badge-success m-auto"
              >online&nbsp;&nbsp;<i
                aria-hidden="true"
                class="fa-light fa-globe-europe"
              ></i
              ></span>
            </ng-container>
            <ng-container
              *ngIf="
                                (!apis.acs ||
                                    !apis.acs.active ||
                                    !apis.acs.state) &&
                                !loadingAcsApi
                            "
            >
                            <span
                            ><i
                              aria-hidden="true"
                              class="fa-light fa-lg fa-fingerprint mr-1 text-danger"
                              title="{{ apis.acs.title }} offline"
                            ></i
                            ></span>
              <span
                *ngIf="!apis.acs.state"
                class="e-badge e-badge-danger m-auto"
              >offline &nbsp;&nbsp;<i
                aria-hidden="true"
                class="fa-light fa-exclamation-triangle"
              ></i
              ></span>
              <span
                *ngIf="!apis.acs.active"
                class="e-badge e-badge-warning m-auto"
              >de-activated&nbsp;&nbsp;<i
                aria-hidden="true"
                class="fa-light fa-exclamation-triangle"
              ></i
              ></span>
            </ng-container>
            <strong *ngIf="!user.ascApiUser_data && !loadingAcsApi">
              <i
                aria-hidden="true"
                class="fa-light fa-lg fa-user-times text-danger mr-1"
                title="{{ apis.acs.title }} účet nepřipojen"
              ></i>
              <button
                (click)="confirmationCreateToAcsAPI.show()"
                *ngIf="
                                    user.authorized &&
                                    (user.boss_id === currentUser.id ||
                                        (currentUser.rule &&
                                            (currentUser.rule
                                                .is_administrative ||
                                                currentUser.rule.is_admin)))
                                "
                class="e-btn e-primary btn-p-zero e-small m-0"
                ejs-button
                title="vytvoří uživatele v {{ apis.acs.title }}"
              >
                <i
                  aria-hidden="true"
                  class="fa-light fa-cloud-upload-alt"
                ></i>
              </button>
            </strong>
            <strong *ngIf="user.ascApiUser_data && !loadingAcsApi">
              <small class="text-muted"
              >ID:
                {{
                  user.ascApiUser_data.id
                    ? user.ascApiUser_data.id
                    :''
                }}&nbsp;</small
              >
              <i
                aria-hidden="true"
                class="fa-light fa-lg fa-user-check text-success mr-1"
                title="{{ apis.acs.title }} účet připojen"
              ></i>
              <button
                (click)="confirmationSyncToAcsAPI.show()"
                *ngIf="
                                    user.authorized &&
                                    (user.boss_id === currentUser.id ||
                                        (currentUser.rule &&
                                            (currentUser.rule
                                                .is_administrative ||
                                                currentUser.rule.is_admin)))
                                "
                cssClass="e-btn e-warning btn-p-zero e-smallm-0"
                ejs-button
                title="synchronizuje uživatele do {{
                                    apis.acs.title
                                }}"
              >
                <i
                  aria-hidden="true"
                  class="fa-light fa-sync-alt"
                ></i>
              </button>
            </strong>
          </div>
          <div
            *ngIf="loadingAcsApi"
            class="d-flex justify-content-end mb-2"
          >
            <app-loader></app-loader>
          </div>
          <div
            *ngIf="!loadingCrmApi"
            class="d-flex justify-content-center mb-2"
          >
            <ng-container
              *ngIf="
                                apis.crm &&
                                apis.crm.active &&
                                apis.crm.state &&
                                !loadingCrmApi
                            "
            >
                            <span
                            ><i
                              aria-hidden="true"
                              class="fa-light fa-lg fa-user-chart mr-1 text-success"
                              title="{{ apis.crm.title }} online"
                            ></i
                            ></span>
              <span class="e-badge e-badge-success m-auto"
              >online&nbsp;&nbsp;<i
                aria-hidden="true"
                class="fa-light fa-globe-europe"
              ></i
              ></span>
            </ng-container>
            <ng-container
              *ngIf="
                                (!apis.crm ||
                                    !apis.crm.active ||
                                    !apis.crm.state) &&
                                !loadingCrmApi
                            "
            >
                            <span
                            ><i
                              aria-hidden="true"
                              class="fa-light fa-lg fa-user-chart mr-1 text-danger"
                              title="{{ apis.crm.title }} offline"
                            ></i
                            ></span>
              <span
                *ngIf="!apis.crm.state"
                class="e-badge e-badge-danger m-auto"
              >offline &nbsp;&nbsp;<i
                aria-hidden="true"
                class="fa-light fa-exclamation-triangle"
              ></i
              ></span>
              <span
                *ngIf="!apis.crm.active"
                class="e-badge e-badge-warning m-auto"
              >de-activated&nbsp;&nbsp;<i
                aria-hidden="true"
                class="fa-light fa-exclamation-triangle"
              ></i
              ></span>
            </ng-container>
            <strong *ngIf="!user.crmApiUser_data && !loadingCrmApi">
              <i
                aria-hidden="true"
                class="fa-light fa-lg fa-user-times text-danger mr-1"
                title="{{ apis.crm.title }} účet nepřipojen"
              ></i>
              <button
                (click)="confirmationCreateToCrmAPI.show()"
                *ngIf="
                                    user.authorized &&
                                    (user.boss_id === currentUser.id ||
                                        (currentUser.rule &&
                                            (currentUser.rule
                                                .is_administrative ||
                                                currentUser.rule.is_admin)))
                                "
                class="e-btn e-primary btn-p-zero e-small m-0"
                ejs-button
                title="vytvoří uživatele v {{ apis.crm.title }}"
              >
                <i
                  aria-hidden="true"
                  class="fa-light fa-cloud-upload-alt"
                ></i>
              </button>
            </strong>
            <strong *ngIf="user.crmApiUser_data && !loadingCrmApi">
              <small class="text-muted"
              >ID:
                {{
                  user.crmApiUser_data.id
                    ? user.crmApiUser_data.id
                    :user.crmApiUser_data.name
                }}&nbsp;</small
              >
              <i
                aria-hidden="true"
                class="fa-light fa-lg fa-user-check text-success mr-1"
                title="{{ apis.crm.title }} účet připojen"
              ></i>
              <button
                (click)="confirmationSyncToCrmAPI.show()"
                *ngIf="
                                    user.authorized &&
                                    (user.boss_id === currentUser.id ||
                                        (currentUser.rule &&
                                            (currentUser.rule
                                                .is_administrative ||
                                                currentUser.rule.is_admin)))
                                "
                cssClass="e-btn e-warning btn-p-zero e-smallm-0"
                ejs-button
                title="synchronizuje uživatele do {{
                                    apis.crm.title
                                }}"
              >
                <i
                  aria-hidden="true"
                  class="fa-light fa-sync-alt"
                ></i>
              </button>
            </strong>
          </div>
          <div
            *ngIf="loadingCrmApi"
            class="d-flex justify-content-end mb-2"
          >
            <app-loader></app-loader>
          </div>
          <div
            *ngIf="!loadingErpApi"
            class="d-flex justify-content-center mb-2"
          >
            <ng-container
              *ngIf="
                                apis.erp &&
                                apis.erp.active &&
                                apis.erp.state &&
                                !loadingErpApi
                            "
            >
                            <span
                            ><i
                              aria-hidden="true"
                              class="fa-light fa-lg fa-calculator-alt mr-1 text-success"
                              title="{{ apis.erp.title }} online"
                            ></i
                            ></span>
              <span class="e-badge e-badge-success m-auto"
              >online&nbsp;&nbsp;<i
                aria-hidden="true"
                class="fa-light fa-globe-europe"
              ></i
              ></span>
            </ng-container>
            <ng-container
              *ngIf="
                                (!apis.erp ||
                                    !apis.erp.active ||
                                    !apis.erp.state) &&
                                !loadingErpApi
                            "
            >
                            <span
                            ><i
                              aria-hidden="true"
                              class="fa-light fa-lg fa-calculator-alt mr-1 text-danger"
                              title="{{ apis.erp.title }} offline"
                            ></i
                            ></span>
              <span
                *ngIf="!apis.erp.state"
                class="e-badge e-badge-danger m-auto"
              >offline &nbsp;&nbsp;<i
                aria-hidden="true"
                class="fa-light fa-exclamation-triangle"
              ></i
              ></span>
              <span
                *ngIf="!apis.erp.active"
                class="e-badge e-badge-warning m-auto"
              >de-activated&nbsp;&nbsp;<i
                aria-hidden="true"
                class="fa-light fa-exclamation-triangle"
              ></i
              ></span>
            </ng-container>
            <strong *ngIf="!user.erpApiUser_data && !loadingErpApi">
              <i
                aria-hidden="true"
                class="fa-light fa-lg fa-user-times text-danger mr-1"
                title="{{ apis.erp.title }} účet nepřipojen"
              ></i>
              <button
                (click)="confirmationCreateToErpAPI.show()"
                *ngIf="
                                    user.authorized &&
                                    (user.boss_id === currentUser.id ||
                                        (currentUser.rule &&
                                            (currentUser.rule
                                                .is_administrative ||
                                                currentUser.rule.is_admin)))
                                "
                class="e-btn e-primary btn-p-zero e-small m-0"
                ejs-button
                title="vytvoří uživatele v {{ apis.erp.title }}"
              >
                <i
                  aria-hidden="true"
                  class="fa-light fa-cloud-upload-alt"
                ></i>
              </button>
            </strong>
            <strong *ngIf="user.erpApiUser_data && !loadingErpApi">
              <small class="text-muted"
              >ID:
                {{
                  user.erpApiUser_data.id
                    ? user.erpApiUser_data.id
                    :user.erpApiUser_data.name
                }}&nbsp;</small
              >
              <i
                aria-hidden="true"
                class="fa-light fa-lg fa-user-check text-success mr-1"
                title="{{ apis.erp.title }} účet připojen"
              ></i>
              <button
                (click)="confirmationSyncToErpAPI.show()"
                *ngIf="
                                    user.authorized &&
                                    (user.boss_id === currentUser.id ||
                                        (currentUser.rule &&
                                            (currentUser.rule
                                                .is_administrative ||
                                                currentUser.rule.is_admin)))
                                "
                cssClass="e-btn e-warning btn-p-zero e-smallm-0"
                ejs-button
                title="synchronizuje uživatele do {{
                                    apis.erp.title
                                }}"
              >
                <i
                  aria-hidden="true"
                  class="fa-light fa-sync-alt"
                ></i>
              </button>
            </strong>
          </div>
          <div
            *ngIf="loadingErpApi"
            class="d-flex justify-content-end mb-2"
          >
            <app-loader></app-loader>
          </div>
          <div
            *ngIf="!loadingMntApi"
            class="d-flex justify-content-center mb-2"
          >
            <ng-container
              *ngIf="
                                apis.siteMonitoring &&
                                apis.siteMonitoring.active &&
                                apis.siteMonitoring.state &&
                                !loadingMntApi
                            "
            >
                            <span
                            ><i
                              aria-hidden="true"
                              class="fa-light fa-lg fa-monitor-heart-rate mr-1 text-success"
                              title="{{
                                        apis.siteMonitoring.title
                                    }} online"
                            ></i
                            ></span>
              <span class="e-badge e-badge-success m-auto"
              >online&nbsp;&nbsp;<i
                aria-hidden="true"
                class="fa-light fa-globe-europe"
              ></i
              ></span>
            </ng-container>
            <ng-container
              *ngIf="
                                (!apis.siteMonitoring ||
                                    !apis.siteMonitoring.active ||
                                    !apis.siteMonitoring.state) &&
                                !loadingMntApi
                            "
            >
                            <span
                            ><i
                              aria-hidden="true"
                              class="fa-light fa-lg fa-monitor-heart-rate mr-1 text-danger"
                              title="{{
                                        apis.siteMonitoring.title
                                    }} offline"
                            ></i
                            ></span>
              <span
                *ngIf="!apis.siteMonitoring.state"
                class="e-badge e-badge-danger m-auto"
              >offline &nbsp;&nbsp;<i
                aria-hidden="true"
                class="fa-light fa-exclamation-triangle"
              ></i
              ></span>
              <span
                *ngIf="!apis.siteMonitoring.active"
                class="e-badge e-badge-warning m-auto"
              >de-activated&nbsp;&nbsp;<i
                aria-hidden="true"
                class="fa-light fa-exclamation-triangle"
              ></i
              ></span>
            </ng-container>
            <strong *ngIf="!user.mntApiUser_data && !loadingMntApi">
              <i
                aria-hidden="true"
                class="fa-light fa-lg fa-user-times text-danger mr-1"
                title="{{
                                    apis.siteMonitoring.title
                                }} účet nepřipojen"
              ></i>
              <button
                (click)="confirmationCreateToMtnAPI.show()"
                *ngIf="
                                    user.authorized &&
                                    (user.boss_id === currentUser.id ||
                                        (currentUser.rule &&
                                            (currentUser.rule
                                                .is_administrative ||
                                                currentUser.rule.is_admin)))
                                "
                class="e-btn e-primary btn-p-zero e-small m-0"
                ejs-button
                title="vytvoří uživatele v {{
                                    apis.siteMonitoring.title
                                }}"
              >
                <i
                  aria-hidden="true"
                  class="fa-light fa-cloud-upload-alt"
                ></i>
              </button>
            </strong>
            <strong *ngIf="user.mntApiUser_data && !loadingMntApi">
              <small class="text-muted"
              >ID:
                {{
                  user.mntApiUser_data.id
                    ? user.mntApiUser_data.id
                    :user.mntApiUser_data.name
                }}&nbsp;</small
              >
              <i
                aria-hidden="true"
                class="fa-light fa-lg fa-user-check text-success mr-1"
                title="{{
                                    apis.siteMonitoring.title
                                }} účet připojen"
              ></i>
              <button
                (click)="confirmationSyncToMtnAPI.show()"
                *ngIf="
                                    user.authorized &&
                                    (user.boss_id === currentUser.id ||
                                        (currentUser.rule &&
                                            (currentUser.rule
                                                .is_administrative ||
                                                currentUser.rule.is_admin)))
                                "
                cssClass="e-btn e-warning btn-p-zero e-smallm-0"
                ejs-button
                title="synchronizuje uživatele do {{
                                    apis.siteMonitoring.title
                                }}"
              >
                <i
                  aria-hidden="true"
                  class="fa-light fa-sync-alt"
                ></i>
              </button>
            </strong>
          </div>
          <div
            *ngIf="loadingMntApi"
            class="d-flex justify-content-end mb-2"
          >
            <app-loader></app-loader>
          </div>
          <div
            *ngIf="!loadingCarsApi"
            class="d-flex justify-content-center mb-2"
          >
            <ng-container
              *ngIf="
                                apis.cars &&
                                apis.cars.active &&
                                apis.cars.state &&
                                !loadingCarsApi
                            "
            >
                            <span
                            ><i
                              aria-hidden="true"
                              class="fa-light fa-lg fa-cars mr-1 text-success"
                              title="{{ apis.cars.title }} online"
                            ></i
                            ></span>
              <span class="e-badge e-badge-success m-auto"
              >online&nbsp;&nbsp;<i
                aria-hidden="true"
                class="fa-light fa-globe-europe"
              ></i
              ></span>
            </ng-container>
            <ng-container
              *ngIf="
                                (!apis.cars ||
                                    !apis.cars.active ||
                                    !apis.cars.state) &&
                                !loadingCarsApi
                            "
            >
                            <span
                            ><i
                              aria-hidden="true"
                              class="fa-light fa-lg fa-cars mr-1 text-danger"
                              title="{{ apis.cars.title }} offline"
                            ></i
                            ></span>
              <span
                *ngIf="!apis.cars.state"
                class="e-badge e-badge-danger m-auto"
              >offline &nbsp;&nbsp;<i
                aria-hidden="true"
                class="fa-light fa-exclamation-triangle"
              ></i
              ></span>
              <span
                *ngIf="!apis.cars.active"
                class="e-badge e-badge-warning m-auto"
              >de-activated&nbsp;&nbsp;<i
                aria-hidden="true"
                class="fa-light fa-exclamation-triangle"
              ></i
              ></span>
            </ng-container>
            <strong
              *ngIf="!user.carsApiUser_data && !loadingCarsApi"
            >
              <i
                aria-hidden="true"
                class="fa-light fa-lg fa-user-times text-danger mr-1"
                title="{{ apis.cars.title }} účet nepřipojen"
              ></i>
              <button
                (click)="confirmationCreateToCarsAPI.show()"
                *ngIf="
                                    user.authorized &&
                                    (user.boss_id === currentUser.id ||
                                        (currentUser.rule &&
                                            (currentUser.rule
                                                .is_administrative ||
                                                currentUser.rule.is_admin)))
                                "
                class="e-btn e-primary btn-p-zero e-small m-0"
                ejs-button
                title="vytvoří uživatele v {{
                                    apis.cars.title
                                }}"
              >
                <i
                  aria-hidden="true"
                  class="fa-light fa-cloud-upload-alt"
                ></i>
              </button>
            </strong>
            <strong
              *ngIf="user.carsApiUser_data && !loadingCarsApi"
            >
              <small class="text-muted"
              >ID:
                {{
                  user.carsApiUser_data.id
                    ? user.carsApiUser_data.id
                    :user.carsApiUser_data.name
                }}&nbsp;</small
              >
              <i
                aria-hidden="true"
                class="fa-light fa-lg fa-user-check text-success mr-1"
                title="{{ apis.cars.title }} účet připojen"
              ></i>
              <button
                (click)="confirmationSyncToCarsAPI.show()"
                *ngIf="
                                    user.authorized &&
                                    (user.boss_id === currentUser.id ||
                                        (currentUser.rule &&
                                            (currentUser.rule
                                                .is_administrative ||
                                                currentUser.rule.is_admin)))
                                "
                cssClass="e-btn e-warning btn-p-zero e-smallm-0"
                ejs-button
                title="synchronizuje uživatele do {{
                                    apis.cars.title
                                }}"
              >
                <i
                  aria-hidden="true"
                  class="fa-light fa-sync-alt"
                ></i>
              </button>
            </strong>
          </div>
          <div
            *ngIf="loadingCarsApi"
            class="d-flex justify-content-end mb-2"
          >
            <app-loader></app-loader>
          </div>
        </div>
      </div>
      <div class="row">
        <div
          *ngIf="apis.acs && apis.acs.active && apis.acs.state"
          class="col-12 mt-3"
        >
          <div class="card">
            <div class="e-card-content p-2">
              <div class="d-flex justify-content-between">
                                <span
                                ><i
                                  aria-hidden="true"
                                  class="fa-light fa-fingerprint"
                                ></i
                                >&nbsp;Systém docházky:</span
                                >
                <span
                  *ngIf="!user.ascApiUser_data"
                  class="col-12 col-lg-6 text-right"
                >
                                    <i
                                      aria-hidden="true"
                                      class="fa-light fa-lg fa-user-times text-danger"
                                      title="účet v ACS API nedohledán"
                                    ></i>
                                </span>
                <div
                  *ngIf="
                                        acsApiState &&
                                        user &&
                                        user.acsApiAtWork &&
                                        !loadingAcsApi
                                    "
                  class="col-12 col-lg-6 text-right"
                >
                                    <span
                                      *ngIf="!user.acsApiAtWork.atWork"
                                      class="e-badge e-badge-danger"
                                    >nepřítomen</span
                                    >
                  <span
                    *ngIf="user.acsApiAtWork.atWork"
                    class="e-badge e-badge-success"
                  >v práci
                                    </span>
                  <ng-container
                    *ngIf="
                                            user.acsApiAtWork.lastLog &&
                                            user.acsApiAtWork.lastLog.category
                                        "
                  >
                                        <span
                                          *ngIf="
                                                user.acsApiAtWork.lastLog
                                                    .category.name ===
                                                'Work Time'
                                            "
                                          class="e-badge e-badge-primary"
                                        >
                                            na pracovišti
                                        </span>
                    <span
                      *ngIf="
                                                user.acsApiAtWork.lastLog
                                                    .category.name === 'Výjezd'
                                            "
                      class="e-badge e-badge-primary"
                    >
                                            výjezd
                                        </span>
                    <span
                      *ngIf="
                                                user.acsApiAtWork.lastLog
                                                    .category.name ===
                                                'HomeOffice'
                                            "
                      class="e-badge e-badge-primary"
                    >
                                            home-office
                                        </span>
                    <span
                      *ngIf="
                                                user.acsApiAtWork.lastLog
                                                    .category.name ===
                                                'Collective Dinner'
                                            "
                      class="e-badge e-badge-primary"
                    >
                                            firemní oběd/večeře
                                        </span>
                    <span
                      *ngIf="
                                                user.acsApiAtWork.lastLog
                                                    .category.name ===
                                                    'Break' ||
                                                user.acsApiAtWork.lastLog
                                                    .category.name ===
                                                    'Break (auto)'
                                            "
                      class="e-badge e-badge-warning"
                    >
                                            přestávka
                                        </span>
                    <span
                      *ngIf="
                                                user.acsApiAtWork.lastLog
                                                    .category.name ===
                                                'Cigarette Break'
                                            "
                      class="e-badge e-badge-warning"
                    >
                                            kouřová přestávka
                                        </span>
                    <span
                      *ngIf="
                                                user.acsApiAtWork.lastLog
                                                    .category.name ===
                                                    'Dinner' ||
                                                user.acsApiAtWork.lastLog
                                                    .category.name ===
                                                    'Dinner (auto)'
                                            "
                      class="e-badge e-badge-primary"
                    >
                                            přestávka na občerstvení
                                        </span>
                    <span
                      *ngIf="
                                                user.acsApiAtWork.lastLog
                                                    .category.name ===
                                                'Refunded overtime'
                                            "
                      class="e-badge e-badge-success"
                    >
                                            přesčas
                                        </span>
                    <span
                      *ngIf="
                                                user.acsApiAtWork.lastLog
                                                    .category.name ===
                                                    'Lunch 2' ||
                                                user.acsApiAtWork.lastLog
                                                    .category.name === 'Lunch 1'
                                            "
                      class="e-badge e-badge-warning"
                    >
                                            Oběd
                                        </span>
                    <span
                      *ngIf="
                                                user.acsApiAtWork.lastLog
                                                    .category.name === 'Pass'
                                            "
                      class="e-badge e-badge-info"
                    >
                                            pass
                                        </span>
                    <span
                      *ngIf="
                                                user.acsApiAtWork.lastLog
                                                    .category.name === 'Service'
                                            "
                      class="e-badge e-badge-info"
                    >
                                            service
                                        </span>
                    <span
                      *ngIf="
                                                user.acsApiAtWork.lastLog
                                                    .category.name ===
                                                'Half-day Vacation'
                                            "
                      class="e-badge e-badge-warning"
                    >
                                            půl den dovolené
                                        </span>
                    <span
                      *ngIf="
                                                user.acsApiAtWork.lastLog
                                                    .category.name ===
                                                'Private Leave'
                                            "
                      class="e-badge e-badge-warning"
                    >
                                            soukromé volno
                                        </span>
                    <span
                      *ngIf="
                                                user.acsApiAtWork.lastLog
                                                    .category.name ===
                                                'Study Leave'
                                            "
                      class="e-badge e-badge-warning"
                    >
                                            studijní volno
                                        </span>
                    <span
                      *ngIf="
                                                user.acsApiAtWork.lastLog
                                                    .category.name === 'Absence'
                                            "
                      class="e-badge e-badge-warning"
                    >
                                            absence
                                        </span>
                    <span
                      *ngIf="
                                                user.acsApiAtWork.lastLog
                                                    .category.name ===
                                                'External Work'
                                            "
                      class="e-badge e-badge-primary"
                    >
                                            externí práce
                                        </span>
                    <span
                      *ngIf="
                                                user.acsApiAtWork.lastLog
                                                    .category.name ===
                                                'Medical Examination with Family Member'
                                            "
                      class="e-badge e-badge-danger"
                    >
                                            doprovod k lékaři
                                        </span>
                    <span
                      *ngIf="
                                                user.acsApiAtWork.lastLog
                                                    .category.name ===
                                                'Family Member Care'
                                            "
                      class="e-badge e-badge-danger"
                    >
                                            péče o člena rodiny
                                        </span>
                    <span
                      *ngIf="
                                                user.acsApiAtWork.lastLog
                                                    .category.name ===
                                                'Work Trip'
                                            "
                      class="e-badge e-badge-primary"
                    >
                                            pracovní cesta
                                        </span>
                    <span
                      *ngIf="
                                                user.acsApiAtWork.lastLog
                                                    .category.name ===
                                                'Training'
                                            "
                      class="e-badge e-badge-primary"
                    >
                                            školení
                                        </span>
                    <span
                      *ngIf="
                                                user.acsApiAtWork.lastLog
                                                    .category.name ===
                                                'Sick Leave'
                                            "
                      class="e-badge e-badge-warning"
                    >
                                            sick day
                                        </span>
                    <span
                      *ngIf="
                                                user.acsApiAtWork.lastLog
                                                    .category.name ===
                                                'Medical Examination'
                                            "
                      class="e-badge e-badge-danger"
                    >
                                            lékař
                                        </span>
                    <span
                      *ngIf="
                                                user.acsApiAtWork.lastLog
                                                    .category.name ===
                                                'Vacation'
                                            "
                      class="e-badge e-badge-warning"
                    >
                                            dovolená
                                        </span>
                    <span
                      *ngIf="
                                                user.acsApiAtWork.lastLog
                                                    .category.name === 'Holiday'
                                            "
                      class="e-badge e-badge-warning"
                    >
                                            svátek
                                        </span>
                    <span
                      *ngIf="
                                                user.acsApiAtWork.lastLog &&
                                                !user.acsApiAtWork.lastLog
                                                    .finished_at &&
                                                !user.acsApiAtWork.lastLog
                                                    .finished_at_real &&
                                                (user.acsApiAtWork.lastLog
                                                    .started_at
                                                    | date: 'yyyy-MM-dd') ===
                                                    today
                                            "
                      class="e-badge e-badge-warning"
                    >
                                            probíhá
                                        </span>
                    <span
                      *ngIf="
                                                user.acsApiAtWork.lastLog &&
                                                !user.acsApiAtWork.lastLog
                                                    .finished_at &&
                                                !user.acsApiAtWork.lastLog
                                                    .finished_at_real &&
                                                (user.acsApiAtWork.lastLog
                                                    .started_at
                                                    | date: 'yyyy-MM-dd') !==
                                                    today
                                            "
                      class="e-badge e-badge-danger"
                    >odhlášen automaticky
                                        </span>
                    <span
                      *ngIf="
                                                user.acsApiAtWork.lastLog &&
                                                (user.acsApiAtWork.lastLog
                                                    .finished_at ||
                                                    user.acsApiAtWork.lastLog
                                                        .finished_at_real)
                                            "
                      class="e-badge e-badge-success"
                    >
                                            ukončeno
                                        </span>
                  </ng-container>
                </div>
              </div>
              <div
                *ngIf="
                                    acsApiState &&
                                    !loadingAcsApi &&
                                    acsUserLogs &&
                                    acsUserLogs.length > 0
                                "
                class="d-flex justify-content-between"
              >
                <div
                  class="table-responsive p-2"
                  style="max-height: 300px; overflow-y: auto"
                >
                  <table class="table mb-1">
                    <tbody>
                    <tr>
                      <th>typ/stav</th>
                      <th>začátek</th>
                      <th>konec</th>
                    </tr>
                    <tr *ngFor="let log of acsUserLogs">
                      <td
                        style="
                                                        text-align: left !important;
                                                    "
                      >
                        <ng-container
                          *ngIf="
                                                            log && log.category
                                                        "
                        >
                                                        <span
                                                          *ngIf="
                                                                log.category
                                                                    .name ===
                                                                'Work Time'
                                                            "
                                                          class="e-badge e-badge-primary ml-1"
                                                        >
                                                            na pracovišti
                                                        </span>
                          <span
                            *ngIf="
                                                                log.category
                                                                    .name ===
                                                                'Výjezd'
                                                            "
                            class="e-badge e-badge-primary ml-1"
                          >
                                                            výjezd
                                                        </span>
                          <span
                            *ngIf="
                                                                log.category
                                                                    .name ===
                                                                'HomeOffice'
                                                            "
                            class="e-badge e-badge-primary ml-1"
                          >
                                                            home-office
                                                        </span>
                          <span
                            *ngIf="
                                                                log.category
                                                                    .name ===
                                                                'Collective Dinner'
                                                            "
                            class="e-badge e-badge-primary ml-1"
                          >
                                                            firemní oběd/večeře
                                                        </span>
                          <span
                            *ngIf="
                                                                log.category
                                                                    .name ===
                                                                    'Break' ||
                                                                log.category
                                                                    .name ===
                                                                    'Break (auto)'
                                                            "
                            class="e-badge e-badge-warning ml-1"
                          >
                                                            přestávka
                                                        </span>
                          <span
                            *ngIf="
                                                                log.category
                                                                    .name ===
                                                                'Cigarette Break'
                                                            "
                            class="e-badge e-badge-warning ml-1"
                          >
                                                            kouřová přestávka
                                                        </span>
                          <span
                            *ngIf="
                                                                log.category
                                                                    .name ===
                                                                    'Dinner' ||
                                                                log.category
                                                                    .name ===
                                                                    'Dinner (auto)'
                                                            "
                            class="e-badge e-badge-primary ml-1"
                          >
                                                            přestávka na
                                                            občerstvení
                                                        </span>
                          <span
                            *ngIf="
                                                                log.category
                                                                    .name ===
                                                                'Refunded overtime'
                                                            "
                            class="e-badge e-badge-success ml-1"
                          >
                                                            přesčas
                                                        </span>
                          <span
                            *ngIf="
                                                                log.category
                                                                    .name ===
                                                                    'Lunch 2' ||
                                                                log.category
                                                                    .name ===
                                                                    'Lunch 1'
                                                            "
                            class="e-badge e-badge-warning ml-1"
                          >
                                                            Oběd
                                                        </span>
                          <span
                            *ngIf="
                                                                log.category
                                                                    .name ===
                                                                'Pass'
                                                            "
                            class="e-badge e-badge-info ml-1"
                          >
                                                            pass
                                                        </span>
                          <span
                            *ngIf="
                                                                log.category
                                                                    .name ===
                                                                'Service'
                                                            "
                            class="e-badge e-badge-info ml-1"
                          >
                                                            service
                                                        </span>
                          <span
                            *ngIf="
                                                                log.category
                                                                    .name ===
                                                                'Half-day Vacation'
                                                            "
                            class="e-badge e-badge-warning ml-1"
                          >
                                                            půl den dovolené
                                                        </span>
                          <span
                            *ngIf="
                                                                log.category
                                                                    .name ===
                                                                'Private Leave'
                                                            "
                            class="e-badge e-badge-warning ml-1"
                          >
                                                            soukromé volno
                                                        </span>
                          <span
                            *ngIf="
                                                                log.category
                                                                    .name ===
                                                                'Study Leave'
                                                            "
                            class="e-badge e-badge-warning ml-1"
                          >
                                                            studijní volno
                                                        </span>
                          <span
                            *ngIf="
                                                                log.category
                                                                    .name ===
                                                                'Absence'
                                                            "
                            class="e-badge e-badge-warning ml-1"
                          >
                                                            absence
                                                        </span>
                          <span
                            *ngIf="
                                                                log.category
                                                                    .name ===
                                                                'External Work'
                                                            "
                            class="e-badge e-badge-primary ml-1"
                          >
                                                            externí práce
                                                        </span>
                          <span
                            *ngIf="
                                                                log.category
                                                                    .name ===
                                                                'Medical Examination with Family Member'
                                                            "
                            class="e-badge e-badge-danger ml-1"
                          >
                                                            doprovod k lékaři
                                                        </span>
                          <span
                            *ngIf="
                                                                log.category
                                                                    .name ===
                                                                'Family Member Care'
                                                            "
                            class="e-badge e-badge-danger ml-1"
                          >
                                                            péče o člena rodiny
                                                        </span>
                          <span
                            *ngIf="
                                                                log.category
                                                                    .name ===
                                                                'Work Trip'
                                                            "
                            class="e-badge e-badge-primary ml-1"
                          >
                                                            pracovní cesta
                                                        </span>
                          <span
                            *ngIf="
                                                                log.category
                                                                    .name ===
                                                                'Training'
                                                            "
                            class="e-badge e-badge-primary ml-1"
                          >
                                                            školení
                                                        </span>
                          <span
                            *ngIf="
                                                                log.category
                                                                    .name ===
                                                                'Sick Leave'
                                                            "
                            class="e-badge e-badge-warning ml-1"
                          >
                                                            sick day
                                                        </span>
                          <span
                            *ngIf="
                                                                log.category
                                                                    .name ===
                                                                'Medical Examination'
                                                            "
                            class="e-badge e-badge-danger ml-1"
                          >
                                                            lékař
                                                        </span>
                          <span
                            *ngIf="
                                                                log.category
                                                                    .name ===
                                                                'Vacation'
                                                            "
                            class="e-badge e-badge-warning ml-1"
                          >
                                                            dovolená
                                                        </span>
                          <span
                            *ngIf="
                                                                log.category
                                                                    .name ===
                                                                'Holiday'
                                                            "
                            class="e-badge e-badge-warning ml-1"
                          >
                                                            svátek
                                                        </span>
                          <span
                            *ngIf="
                                                                !log.finished_at &&
                                                                !log.finished_at_real &&
                                                                (log.started_at
                                                                    | date
                                                                        : 'yyyy-MM-dd') ===
                                                                    today
                                                            "
                            class="e-badge e-badge-warning ml-1"
                          >
                                                            probíhá
                                                        </span>
                          <span
                            *ngIf="
                                                                !log.finished_at &&
                                                                !log.finished_at_real &&
                                                                (log.started_at
                                                                    | date
                                                                        : 'yyyy-MM-dd') !==
                                                                    today
                                                            "
                            class="e-badge e-badge-danger ml-1"
                          >odhlášen
                                                            automaticky
                                                        </span>
                          <span
                            *ngIf="
                                                                log.finished_at ||
                                                                log.finished_at_real
                                                            "
                            class="e-badge e-badge-success ml-1"
                          >
                                                            ukončeno
                                                        </span>
                        </ng-container>
                      </td>
                      <td>
                        {{
                          log.started_at
                            ? (log.started_at
                              | dateFormat
                              : 'DD.MM.YYYY HH:mm')
                            :(log.started_at_real
                              | dateFormat
                              : 'DD.MM.YYYY HH:mm')
                        }}
                      </td>
                      <td>
                        {{
                          log.finished_at ||
                          log.finished_at_real
                            ? log.finished_at
                              ? (log.finished_at
                                | dateFormat
                                : 'DD.MM.YYYY HH:mm')
                              :(log.finished_at_real
                                | dateFormat
                                : 'DD.MM.YYYY HH:mm')
                            :'--.--.---- --:--'
                        }}
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div
                *ngIf="
                                    loadingAcsApi ||
                                    !acsApiState ||
                                    !acsUserLogs
                                "
                class="col-12 text-center p-3"
              >
                <app-loader></app-loader>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 mt-3">
          <div class="card">
            <div class="e-card-content p-2">
              <div class="d-flex justify-content-between">
                                <span
                                ><i
                                  aria-hidden="true"
                                  class="fa-light fa-island-tropical"
                                ></i
                                >&nbsp;Dovolená:</span
                                >
                <div *ngIf="vacationState && !loadingVacation">
                                    <span
                                      *ngIf="!vacationState.atWork"
                                      class="e-badge e-badge-danger"
                                    >nepřítomen</span
                                    >
                  <span
                    *ngIf="vacationState.atWork"
                    class="e-badge e-badge-success"
                  >v práci</span
                  >
                  <ng-container
                    *ngIf="
                                            vacationState.vacations.length > 0
                                        "
                  >
                                        <span class="e-badge e-badge-primary">{{
                                            vacationState.vacations[0].category
                                              .name
                                          }}</span>
                    <span
                      *ngIf="
                                                !vacationState.vacations[0]
                                                    .decline_at &&
                                                !vacationState.vacations[0]
                                                    .deleted_date &&
                                                !vacationState.vacations[0]
                                                    .decline &&
                                                vacationState.vacations[0]
                                                    .confirm
                                            "
                      class="e-badge e-badge-warning"
                    >
                                            probíhá
                                        </span>
                    <span
                      *ngIf="
                                                vacationState.vacations[0]
                                                    .decline_at ||
                                                vacationState.vacations[0]
                                                    .deleted_date ||
                                                vacationState.vacations[0]
                                                    .decline
                                            "
                      class="e-badge e-badge-success"
                    >
                                            ukončeno
                                        </span>
                  </ng-container>
                </div>
              </div>
              <div
                class="d-flex justify-content-between border-bottom pb-1"
              >
                <span><strong>Fond dovolené:</strong></span>
                <span
                  [ngPlural]="user.vacation_fond"
                  class="text-primary"
                >
                                    <ng-template ngPluralCase="=1"
                                    >{{
                                        user.vacation_fond | number: '1.1-1'
                                      }}
                                      den dovolené</ng-template
                                    >
                                    <ng-template ngPluralCase="=2"
                                    >{{
                                        user.vacation_fond | number: '1.1-1'
                                      }}
                                      dny dovolené</ng-template
                                    >
                                    <ng-template ngPluralCase="=3"
                                    >{{
                                        user.vacation_fond | number: '1.1-1'
                                      }}
                                      dny ndovolené</ng-template
                                    >
                                    <ng-template ngPluralCase="=4"
                                    >{{
                                        user.vacation_fond | number: '1.1-1'
                                      }}
                                      dny dovolené</ng-template
                                    >
                                    <ng-template ngPluralCase="other"
                                    >{{
                                        user.vacation_fond | number: '1.1-1'
                                      }}
                                      dní dovolené</ng-template
                                    >
                                </span>
                /
                <span
                  [ngPlural]="usedVacDays"
                  class="text-danger"
                >
                                    <ng-template ngPluralCase="=1"
                                    >{{ usedVacDays | number: '1.1-1' }} den
                                        dovolené</ng-template
                                    >
                                    <ng-template ngPluralCase="=2"
                                    >{{ usedVacDays | number: '1.1-1' }} dny
                                        dovolené</ng-template
                                    >
                                    <ng-template ngPluralCase="=3"
                                    >{{ usedVacDays | number: '1.1-1' }} dny
                                        dovolené</ng-template
                                    >
                                    <ng-template ngPluralCase="=4"
                                    >{{ usedVacDays | number: '1.1-1' }} dny
                                        dovolené</ng-template
                                    >
                                    <ng-template ngPluralCase="other"
                                    >{{ usedVacDays | number: '1.1-1' }} dní
                                        dovolené</ng-template
                                    >
                                </span>
              </div>
              <div
                class="d-flex justify-content-between border-bottom pb-1"
              >
                <span><strong>Fond sickdays:</strong></span>
                <span
                  [ngPlural]="user.sickdays_fond"
                  class="text-primary"
                >
                                    <ng-template ngPluralCase="=1"
                                    >{{
                                        user.sickdays_fond | number: '1.1-1'
                                      }}
                                      den sickdays</ng-template
                                    >
                                    <ng-template ngPluralCase="=2"
                                    >{{
                                        user.sickdays_fond | number: '1.1-1'
                                      }}
                                      dny sickdays</ng-template
                                    >
                                    <ng-template ngPluralCase="=3"
                                    >{{
                                        user.sickdays_fond | number: '1.1-1'
                                      }}
                                      dny sickdays</ng-template
                                    >
                                    <ng-template ngPluralCase="=4"
                                    >{{
                                        user.sickdays_fond | number: '1.1-1'
                                      }}
                                      dny sickdays</ng-template
                                    >
                                    <ng-template ngPluralCase="other"
                                    >{{
                                        user.sickdays_fond | number: '1.1-1'
                                      }}
                                      dní sickdays</ng-template
                                    >
                                </span>
                /
                <span
                  [ngPlural]="usedSickDays"
                  class="text-danger"
                >
                                    <ng-template ngPluralCase="=1"
                                    >{{
                                        usedSickDays | number: '1.1-1'
                                      }}
                                      den sickdays</ng-template
                                    >
                                    <ng-template ngPluralCase="=2"
                                    >{{
                                        usedSickDays | number: '1.1-1'
                                      }}
                                      dny sickdays</ng-template
                                    >
                                    <ng-template ngPluralCase="=3"
                                    >{{
                                        usedSickDays | number: '1.1-1'
                                      }}
                                      dny sickdays</ng-template
                                    >
                                    <ng-template ngPluralCase="=4"
                                    >{{
                                        usedSickDays | number: '1.1-1'
                                      }}
                                      dny sickdays</ng-template
                                    >
                                    <ng-template ngPluralCase="other"
                                    >{{
                                        usedSickDays | number: '1.1-1'
                                      }}
                                      dní sickdays</ng-template
                                    >
                                </span>
              </div>
              <div
                *ngIf="vacationState && !loadingVacation"
                style="
                                    display: block;
                                    width: 100%;
                                    height: auto;
                                "
              >
                <ejs-chart
                  #vacationsChart
                  [legendSettings]="legendSettings"
                  [margin]="{
                                        left: 5,
                                        right: 5,
                                        top: 5,
                                        bottom: 5
                                    }"
                  [primaryXAxis]="primaryXAxis"
                  [primaryYAxis]="primaryYAxis"
                  [title]="'Dovolená & SickDays'"
                  [tooltip]="tooltip"
                  id="vacationsChart"
                  style="display: block"
                >
                  <e-series-collection>
                    <e-series
                      [dataSource]="chartVacData"
                      name="Dovolená"
                      type="Bar"
                      xName="month"
                      yName="days"
                    ></e-series>
                    <e-series
                      [dataSource]="chartSickData"
                      name="SickDays"
                      type="Bar"
                      xName="month"
                      yName="days"
                    ></e-series>
                  </e-series-collection>
                </ejs-chart>
              </div>
              <div
                *ngIf="loadingVacation || !vacationState"
                class="d-flex text-center p-3"
              >
                <app-loader></app-loader>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 col-md-9">
      <div
        aria-label="Profilová nástrojová lišta"
        class="d-flex justify-content-end"
        role="toolbar"
      >
        <div class="col-12 text-md-right text-center">
          <div
            *ngIf="
                            (user.authorized && user.id === currentUser.id) ||
                            (currentUser.rule &&
                                (currentUser.rule.is_administrative ||
                                    currentUser.rule.is_admin))
                        "
            aria-label="export"
            class="e-btn-group m-2"
            role="group"
          >
            <button
              (click)="DialogEmailSignature.show()"
              cssClass="e-btn e-info btn-p-zero e-small"
              ejs-button
              title="stáhnout emailový podpis"
            >
              <i
                aria-hidden="true"
                class="fa-light fa-id-card mr-1"
              ></i
              ><span class="d-none d-lg-inline">podpis</span>
            </button>
          </div>
          <div
            aria-label="conatct"
            class="e-btn-group m-2"
            role="group"
          >
            <a
              href="mailto:{{ user.workemail }}"
              title="napsat email"
            >
              <button
                cssClass="e-btn e-primary btn-p-zero e-small"
                ejs-button
              >
                <i
                  aria-hidden="true"
                  class="fa-light fa-envelope mr-1"
                ></i
                ><span class="d-none d-lg-inline"
              >napsat e-mail</span
              >
              </button>
            </a>
            <a href="tel:{{ user.telnumber }}" title="zavolat">
              <button
                cssClass="e-btn e-primary btn-p-zero e-small"
                ejs-button
              >
                <i
                  aria-hidden="true"
                  class="fa-light fa-mobile mr-1 d-sm-inline"
                ></i
                ><span class="d-none d-lg-inline">volat</span>
              </button>
            </a>
          </div>
          <div
            aria-label="actions"
            class="e-btn-group m-2"
            role="group"
          >
            <button
              (click)="editUser()"
              *ngIf="
                                user.authorized &&
                                (user.id === currentUser.id ||
                                    user.boss_id === currentUser.id ||
                                    (currentUser.rule &&
                                        (currentUser.rule.is_administrative ||
                                            currentUser.rule.is_admin)))
                            "
              cssClass="e-btn e-warning btn-p-zero e-small"
              ejs-button
              title="upravit uživatele"
            >
              <i
                aria-hidden="true"
                class="fa-light fa-user-edit mr-1"
              ></i
              ><span class="d-none d-lg-inline">upravit</span>
            </button>
            <button
              (click)="confirmationDelete.show()"
              *ngIf="
                                user.authorized &&
                                (user.boss_id === currentUser.id ||
                                    (currentUser.rule &&
                                        (currentUser.rule.is_administrative ||
                                            currentUser.rule.is_admin)))
                            "
              cssClass="e-btn e-danger btn-p-zero e-small"
              ejs-button
              title="deautorizuje uživatele"
            >
              <i
                aria-hidden="true"
                class="fa-light fa-user-times mr-1"
              ></i
              ><span class="d-none d-lg-inline"
            >de-autorizovat</span
            >
            </button>
            <button
              (click)="confirmationAuthorized.show()"
              *ngIf="
                                !user.authorized &&
                                (user.boss_id === currentUser.id ||
                                    (currentUser.rule &&
                                        (currentUser.rule.is_administrative ||
                                            currentUser.rule.is_admin)))
                            "
              cssClass="e-btn e-success btn-p-zero e-small"
              ejs-button
              title="autorizuje uživatele"
            >
              <i
                aria-hidden="true"
                class="fa-light fa-user-plus mr-1"
              ></i
              ><span class="d-none d-lg-inline"
            >re-autorizovat</span
            >
            </button>
            <button
              (click)="changePassword()"
              *ngIf="currentUser.id === user.id"
              class="e-btn e-primary btn-p-zero e-small"
              ejs-button
              title="změnit heslo uživatele"
            >
              <i
                aria-hidden="true"
                class="fa-light fa-user-lock mr-1"
              ></i
              ><span class="d-none d-lg-inline"
            >změnit heslo</span
            >
            </button>
            <button
              (click)="confirmationReset.show()"
              *ngIf="
                                user.authorized &&
                                (user.boss_id === currentUser.id ||
                                    (currentUser.rule &&
                                        (currentUser.rule.is_administrative ||
                                            currentUser.rule.is_admin)))
                            "
              cssClass="e-btn e-warning btn-p-zero e-small"
              ejs-button
              title="resetovat heslo uživatele"
            >
              <i
                aria-hidden="true"
                class="fa-light fa-lock mr-1"
              ></i
              ><span class="d-none d-lg-inline"
            >resetovat heslo</span
            >
            </button>
          </div>
        </div>
      </div>
      <hr class="mb-2" />
      <div class="row mb-2">
        <div class="col-12 col-md-6 col-lg-3 p-2">
          <div
            *ngIf="
                            apis.siteMonitoring &&
                            apis.siteMonitoring.active &&
                            apis.siteMonitoring.state
                        "
            class="card h-100"
          >
            <div class="e-card-content p-2">
              <div class="row no-gutters">
                <div class="col-12 pb-2">
                  <i
                    aria-hidden="true"
                    class="fa-light fa-monitor-heart-rate"
                  ></i
                  >&nbsp;Systém dohledu sítě:
                  <hr class="mb-1" />
                </div>
                <div class="col-6">
                  <strong> tikety: </strong>
                </div>
                <div class="col-6 text-right">
                  0
                  <button
                    cssClass="e-btn e-info btn-p-zero e-small ml-1"
                    ejs-button
                  >
                    <i
                      aria-hidden="true"
                      class="fa-light fa-search"
                    ></i>
                  </button>
                </div>
                <div class="col-6">
                  <strong> servisní zásahy: </strong>
                </div>
                <div class="col-6 text-right">
                  0
                  <button
                    cssClass="e-btn e-info btn-p-zero e-small ml-1"
                    ejs-button
                  >
                    <i
                      aria-hidden="true"
                      class="fa-light fa-search"
                    ></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-3 p-2">
          <div
            *ngIf="apis.crm && apis.crm.active && apis.crm.state"
            class="card h-100"
          >
            <div class="e-card-content p-2">
              <div class="row no-gutters">
                <div class="col-12 pb-2">
                  <i
                    aria-hidden="true"
                    class="fa-light fa-user-chart"
                  ></i
                  >&nbsp;Systém správy klientů:
                  <hr class="mb-1" />
                </div>
                <div class="col-6">
                  <strong> tikety: </strong>
                </div>
                <div class="col-6 text-right">
                  0
                  <button
                    cssClass="e-btn e-info btn-p-zero e-small ml-1"
                    ejs-button
                  >
                    <i
                      aria-hidden="true"
                      class="fa-light fa-search"
                    ></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-3 p-2">
          <div
            *ngIf="apis.erp && apis.erp.active && apis.erp.state"
            class="card h-100"
          >
            <div class="e-card-content p-2">
              <div class="row no-gutters">
                <div class="col-12 pb-2">
                  <i
                    aria-hidden="true"
                    class="fa-light fa-calculator-alt"
                  ></i
                  >&nbsp;Informační systém:
                  <hr class="mb-1" />
                </div>
                <div class="col-6">
                  <strong> Faktury: </strong>
                </div>
                <div class="col-6 text-right">
                  0
                  <button
                    cssClass="e-btn e-info btn-p-zero e-small ml-1"
                    ejs-button
                  >
                    <i
                      aria-hidden="true"
                      class="fa-light fa-search"
                    ></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-3 p-2">
          <div
            *ngIf="apis.cars && apis.cars.active && apis.cars.state"
            class="card h-100"
          >
            <div class="e-card-content p-2">
              <div class="row no-gutters">
                <div class="col-12 pb-2">
                  <i
                    aria-hidden="true"
                    class="fa-light fa-cars"
                  ></i
                  >&nbsp;Systém správy firmeních vozidel:
                  <hr class="mb-1" />
                </div>
                <div class="col-6">
                  <strong> Přiřazené vozy: </strong>
                </div>
                <div class="col-6 text-right">
                  0
                  <button
                    cssClass="e-btn e-info btn-p-zero e-small ml-1"
                    ejs-button
                  >
                    <i
                      aria-hidden="true"
                      class="fa-light fa-search"
                    ></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr class="mb-2" />
      <div class="row mb-2">
        <div class="col-12">
          <div class="e-card">
            <div class="e-card-content">
              <!-- TODO
              <div class="alert alert-danger" role="alert" style="font-size: 10px!important;">
                  <h6 class="alert-heading">Po termínu:</h6>
                  <strong class="">Tickety:</strong><span class="float-right">10</span>
                  <hr>
                  <strong class="">Úkoly:</strong><span class="float-right">10</span>
              </div>
              <div class="alert alert-warning" role="alert" style="font-size: 10px!important;">
                  <h6 class="alert-heading">Služby hotline:</h6>
                  <ul class="list-group-sm list-group-flush m-0 p-0">
                      <li class="list-group-item p-1">Cras justo odio</li>
                      <li class="list-group-item p-1">Dapibus ac facilisis in</li>
                      <li class="list-group-item p-1">Morbi leo risus</li>
                      <li class="list-group-item p-1">Porta ac consectetur ac</li>
                      <li class="list-group-item p-1">Vestibulum at eros</li>
                  </ul>
              </div>-->
              <div *ngIf="loadingCalendar" class="row">
                <div class="col-12 text-center">
                  <app-loader></app-loader>
                </div>
              </div>
              <full-calendar
                #personalCalendar
                *ngIf="!loadingCalendar"
                [deepChangeDetection]="true"
                [options]="calendarOptions"
              ></full-calendar>
            </div>
            <div class="card-footer">
              <div class="col-12">
                <small class="text-muted">legenda:</small>&nbsp;
                <span
                  *ngFor="let category of categories"
                  [ngStyle]="{
                                        'background-color': category.colortag
                                    }"
                  class="e-badge mr-1 text-white"
                >{{ category.name }}</span
                >
                <span
                  class="e-badge mr-1 text-white"
                  style="background-color: #36b7db"
                >Tickety</span
                >
                <span
                  class="e-badge mr-1 text-white"
                  style="background-color: #ffbb32"
                >Úkoly</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="loadingUser || !user">
    <div class="col-12 col-md-3 col-lg-2">
      <div class="row">
        <div class="col-12 mb-1 text-center">
          <div class="e-card">
            <div class="e-card-content">
              <app-loader></app-loader>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 col-md-9 col-lg-10">
      <div class="row">
        <div class="col-12 mb-1 text-center">
          <div class="e-card">
            <div class="e-card-content">
              <app-loader></app-loader>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</div>
