import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnInit,
    ViewChild,
} from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {registerLocaleData} from '@angular/common';
import {Observable, Observer} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthenticationService} from '@src/app/_services/authentication.service';
import {DataService} from '@src/app/_services/data.service';
import {DataNetbaseService} from '@src/app/_services/data-netbase.service';
import {RuianGridItems, ServicesService} from '@src/app/features/services/services.service';
import {PermissionService} from '@src/app/_services/permission.service';
import {MessageService} from '@src/app/_services/message.service';
import {User} from '@src/app/_models/user/user';
import {AddressConnectedRuian} from '@src/app/_models/services/address-connected-ruian';
import {ChangeEventArgs, DropDownListComponent} from '@syncfusion/ej2-angular-dropdowns';
import {ClickEventArgs} from '@syncfusion/ej2-angular-navigations';
import {
    ColumnModel,
    DataResult,
    EditSettingsModel,
    ExcelExportProperties,
    FilterSettingsModel,
    GridComponent,
    GroupSettingsModel,
    PageSettingsModel,
    RowDataBoundEventArgs,
    SelectionSettingsModel,
    ToolbarItems,
} from '@syncfusion/ej2-angular-grids';
import {ButtonPropsModel, DialogComponent} from '@syncfusion/ej2-angular-popups';
import {Project} from '@src/app/_models/projects/project';

import moment from 'moment';
import localeCs from '@angular/common/locales/cs';
import {TextBoxComponent} from '@syncfusion/ej2-angular-inputs';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';

moment.updateLocale('cs', {workingWeekdays: [1, 2, 3, 4, 5]});
registerLocaleData(localeCs);

@UntilDestroy()
@Component({
    selector: 'app-service-connected-ruian',
    templateUrl: './service-connected-ruian.component.html',
    styleUrls: ['./service-connected-ruian.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ServiceConnectedRuianComponent implements OnInit {
    // Grid
    currencyFormat: {format: string} = {format: '#,###.00\',- CZK\''};

    dateFormat: string;

    toolbar: ToolbarItems[] | object;

    data: object;

    filterSettings: FilterSettingsModel;

    selectionSettings: SelectionSettingsModel;

    editSettings: EditSettingsModel;

    groupOptions: GroupSettingsModel;

    pageOptions: PageSettingsModel;

    technologyColumns: ColumnModel[];

    buildingColumns: ColumnModel[];

    addressColumns: ColumnModel[];

    marketingColumns: ColumnModel[];

    serviceColumns: ColumnModel[];

    filterDate: object;

    requiredRules: object;

    sortOptions: object;

    exportCurrentPage = false;

    // Dropdowns
    height = '240px';

    categorySelect: Array<{value: boolean | number | string; label: string}> = [];

    // Dialogs
    renderProjectFormDialog = false;

    renderRuianFormDialog = false;

    buttonsDelete: ButtonPropsModel[] = [
        {
            click: async (): Promise<void> => {
                await this.deleteRUIAN();
            },
            isFlat: false,
            buttonModel: {
                content: 'OK',
                cssClass: 'e-success e-outline',
            },
        },
        {
            click: (): void => {
                this.confirmationDelete.hide();
            },
            buttonModel: {
                content: 'ZRUŠIT',
                cssClass: 'e-danger e-outline',
            },
        },
    ];

    buttonsCancel: ButtonPropsModel[] = [
        {
            click: (): void => {
                this.confirmationCancel.hide();
            },
            buttonModel: {
                isPrimary: true,
                content: 'OK',
            },
        },
    ];

    // Forms
    isCreate = false;

    isUpdate = false;

    isCopy = false;

    isProjectCreate = false;

    isProjectUpdate = false;

    isProjectCopy = false;

    // Variables
    currentUser: User | null;

    ruian: AddressConnectedRuian;

    ruians: AddressConnectedRuian[] | undefined = [];

    project: Project;

    validRuian = null;

    ruianAddress = null;

    smartWholeAddress = null;

    categoryFilterData: string[] = [];

    clickedRow: AddressConnectedRuian[] = [];

    clickedRow$ = new Observable<AddressConnectedRuian[]>(
        (observer: Observer<AddressConnectedRuian[]>) => {
            observer.next(this.clickedRow);
        },
    );

    // Loaders
    ruianAddressLoad = true;

    // Grid
    @ViewChild('ruianGrid') gridRuians: GridComponent;

    @ViewChild('searchtext') searchtextObj: TextBoxComponent;

    @ViewChild('templateKeysBadges') templateKeysBadges: string;

    @ViewChild('templateLiftBadges') templateLiftBadges: string;

    @ViewChild('templateClipBadges') templateClipBadges: string;

    // Dropdowns
    @ViewChild('viewFilter') viewFilterInstance: DropDownListComponent;

    // Dialogs
    @ViewChild('confirmationDelete') confirmationDelete: DialogComponent;

    @ViewChild('confirmationCancel') confirmationCancel: DialogComponent;

    constructor(
        private readonly router: Router,
        private readonly route: ActivatedRoute,
        private readonly ref: ChangeDetectorRef,
        private readonly formBuilder: FormBuilder,
        private readonly authenticationService: AuthenticationService,
        private readonly dataService: DataService,
        private readonly dataNetbaseService: DataNetbaseService,
        private readonly servicesService: ServicesService,
        private readonly permissionService: PermissionService,
        private readonly messages: MessageService,
    ) {
        this.currentUser = this.authenticationService.currentUserValue;
    }

    ngOnInit(): void {
        this.filterSettings = {
            type: 'Excel',
            showFilterBarOperator: true,
            showFilterBarStatus: true,
            ignoreAccent: true,
        };

        this.selectionSettings = {
            persistSelection: true,
            type: 'Multiple',
            checkboxOnly: true,
        };

        this.toolbar = [
            'Print',
            'ExcelExport',
            'Add',
            'Edit',
            {
                text: 'Kopírovat',
                tooltipText: 'Kopírovat RUIAN adresu',
                prefixIcon: 'e-copy',
                id: 'copy',
            },
            {
                text: 'Smazat',
                tooltipText: 'Smazat RUIAN adres',
                prefixIcon: 'e-remove',
                id: 'delete',
            },
            {
                text: 'Detail',
                tooltipText: 'Detail RUIAN adresy',
                prefixIcon: 'e-openfolder',
                id: 'detail',
            },
            {
                text: 'Občerstvit data',
                tooltipText: 'Občerstvit data',
                prefixIcon: 'e-refresh',
                id: 'customRefresh',
            },
        ];

        this.categoryFilterData = [
            'všechny adresy',
            'pouze aktivní adresy',
            'potenciál nad 10',
            'služby nad 10',
            'klienti nad 10',
        ];
        this.pageOptions = {pageSize: 50};
        this.dateFormat = 'dd.MM.yyyy HH:mm';
        this.filterDate = {type: 'Menu', params: {format: this.dateFormat}};
        this.groupOptions = {
            showGroupedColumn: true,
            disablePageWiseAggregates: false,
        };

        this.editSettings = {
            allowEditing: true,
            allowAdding: true,
            allowDeleting: true,
            allowEditOnDblClick: false,
            mode: 'Dialog',
        };
        this.requiredRules = {required: true};
        this.technologyColumns = [
            {
                field: 'transfer',
                headerText: 'Přenos',
                filter: this.filterSettings,
                width: 130,
                maxWidth: 130,
                minWidth: 60,
                clipMode: 'EllipsisWithTooltip',
                autoFit: true,
            },
            {
                field: 'technology',
                headerText: 'Technologie',
                filter: this.filterSettings,
                width: 130,
                maxWidth: 130,
                minWidth: 60,
                clipMode: 'EllipsisWithTooltip',
                autoFit: true,
            },
        ];

        this.buildingColumns = [
            {
                field: 'unit_type',
                headerText: 'Typ jednotky',
                filter: this.filterSettings,
                width: 130,
                maxWidth: 130,
                minWidth: 100,
                clipMode: 'EllipsisWithTooltip',
                autoFit: true,
            },
            {
                field: 'number_of_units',
                headerText: 'Počet bytů',
                filter: this.filterSettings,
                format: 'N',
                width: 80,
                textAlign: 'Right',
                minWidth: 60,
                clipMode: 'EllipsisWithTooltip',
                autoFit: true,
            },
            {
                field: 'number_of_storeys',
                headerText: 'Počet podlaží',
                filter: this.filterSettings,
                format: 'N',
                width: 80,
                textAlign: 'Right',
                minWidth: 60,
                clipMode: 'EllipsisWithTooltip',
                autoFit: true,
            },
            {
                field: 'lift',
                headerText: 'Výtah',
                filter: this.filterSettings,
                width: 60,
                textAlign: 'Center',
                minWidth: 40,
                clipMode: 'EllipsisWithTooltip',
                autoFit: true,
                template: this.templateLiftBadges,
            },
            {
                field: 'flat_space',
                headerText: 'Prostor',
                filter: this.filterSettings,
                width: 130,
                maxWidth: 130,
                minWidth: 60,
                clipMode: 'EllipsisWithTooltip',
                autoFit: true,
            },
            {
                field: 'owner_name',
                headerText: 'Vlastník',
                filter: this.filterSettings,
                width: 130,
                maxWidth: 130,
                minWidth: 60,
                clipMode: 'EllipsisWithTooltip',
                autoFit: true,
            },
            {
                field: 'manager_name',
                headerText: 'Správce',
                filter: this.filterSettings,
                width: 130,
                maxWidth: 130,
                minWidth: 60,
                clipMode: 'EllipsisWithTooltip',
                autoFit: true,
            },
            {
                field: 'electrical_counter',
                headerText: 'Elektroměr',
                filter: this.filterSettings,
                width: 130,
                textAlign: 'Center',
                minWidth: 60,
                maxWidth: 130,
                clipMode: 'EllipsisWithTooltip',
                autoFit: true,
            },
            {
                field: 'keys',
                headerText: 'Klíče',
                filter: this.filterSettings,
                width: 60,
                textAlign: 'Center',
                minWidth: 40,
                clipMode: 'EllipsisWithTooltip',
                autoFit: true,
                template: this.templateKeysBadges,
            },
        ];

        this.addressColumns = [
            {
                field: 'address_street',
                headerText: 'Ulice',
                filter: this.filterSettings,
                width: 130,
                maxWidth: 130,
                minWidth: 60,
                clipMode: 'EllipsisWithTooltip',
                autoFit: true,
            },
            {
                field: 'address_city',
                headerText: 'Město',
                filter: this.filterSettings,
                width: 130,
                maxWidth: 130,
                minWidth: 60,
                clipMode: 'EllipsisWithTooltip',
                autoFit: true,
            },
            {
                field: 'address_zip',
                headerText: 'PSČ',
                filter: this.filterSettings,
                width: 130,
                maxWidth: 130,
                minWidth: 60,
                clipMode: 'EllipsisWithTooltip',
                autoFit: true,
            },
            {
                field: 'address_district',
                headerText: 'Okres',
                filter: this.filterSettings,
                width: 130,
                maxWidth: 130,
                minWidth: 60,
                clipMode: 'EllipsisWithTooltip',
                autoFit: true,
            },
            {
                field: 'address_region',
                headerText: 'Kraj',
                filter: this.filterSettings,
                width: 130,
                maxWidth: 130,
                minWidth: 60,
                clipMode: 'EllipsisWithTooltip',
                autoFit: true,
            },
        ];

        this.marketingColumns = [
            {
                field: 'clip_frame',
                headerText: 'Klip rám',
                filter: this.filterSettings,
                width: 130,
                maxWidth: 130,
                minWidth: 60,
                clipMode: 'EllipsisWithTooltip',
                autoFit: true,
                template: this.templateClipBadges,
            },
            {
                field: 'marketingnote',
                headerText: 'Marketingová poznámka',
                filter: this.filterSettings,
                width: 200,
                maxWidth: 200,
                minWidth: 60,
                clipMode: 'EllipsisWithTooltip',
                autoFit: true,
            },
            {
                field: 'marketing_ads',
                headerText: 'Ostatní reklamy',
                filter: this.filterSettings,
                width: 200,
                maxWidth: 200,
                minWidth: 60,
                clipMode: 'EllipsisWithTooltip',
                autoFit: true,
            },
        ];

        this.serviceColumns = [
            {
                field: 'net_packages',
                headerText: 'NET Tarify',
                filter: this.filterSettings,
                width: 100,
                maxWidth: 250,
                minWidth: 60,
                clipMode: 'EllipsisWithTooltip',
                autoFit: true,
            },
            {
                field: 'tv_services',
                headerText: 'TV služby',
                filter: this.filterSettings,
                width: 150,
                maxWidth: 250,
                minWidth: 100,
                clipMode: 'EllipsisWithTooltip',
                autoFit: true,
            },
        ];
    }

    loadData(): DataResult[] {
        this.gridRuians.clearRowSelection();

        console.info('NEW DATA BOUND');

        const elements: RuianGridItems[] = [];

        if (this.ruians && this.ruians.length > 0) {
            // eslint-disable-next-line complexity
            this.ruians.map((ruian: AddressConnectedRuian) => {
                let tvServices = '';
                let netPackages = '';

                ruian.net_packages.forEach(netPackage => {
                    netPackages += `${netPackage.name}, `;
                });

                ruian.tv_services?.forEach(tvPackage => {
                    tvServices += `${tvPackage.name}, `;
                });

                if (
                    this.currentUser &&
                    this.permissionService.checkUserISServiceAdmin(this.currentUser)
                ) {
                    elements.push({
                        id: ruian.id,
                        keys: ruian.keys ? 'ANO' : 'NE',
                        electrical_counter: ruian.electrical_counter,
                        clip_frame: ruian.clip_frame ? 'ANO' : 'NE',
                        number_of_storeys: ruian.number_of_storeys,
                        lift: ruian.lift ? 'ANO' : 'NE',
                        business_note: ruian.business_note,
                        provider: ruian.provider.name,
                        project: ruian.project ? ruian.project.name : null,
                        realized_at: ruian.realized_at ? new Date(ruian.realized_at) : null,
                        technology: ruian.technology.name,
                        transfer: ruian.transfer.name,
                        descriptive: ruian.descriptive,
                        flat_space: ruian.flat_space
                            ? `${ruian.flat_space.metric_until} - ${ruian.flat_space.metric_to} m2`
                            : null,
                        discount: ruian.discount ? ruian.discount.discount : null,
                        deal_type: ruian.deal_type ? ruian.deal_type.age : null,
                        unit_type: ruian.unit_type ? ruian.unit_type.name : null,
                        net_packages: netPackages,
                        tv_services: tvServices,
                        marketingnote: ruian.marketingnote,
                        marketing_ads: ruian.marketing_ads,
                        owner_name: ruian.owner_name,
                        manager_name: ruian.manager_name,
                        number_of_units: ruian.number_of_units,
                        ruian: ruian.ruian,
                        address: ruian.address,
                        address_street: ruian.address_obj ? ruian.address_obj.street : null,
                        address_city: ruian.address_obj ? ruian.address_obj.city : null,
                        address_zip: ruian.address_obj ? ruian.address_obj.zip : null,
                        address_district: ruian.address_obj ? ruian.address_obj.district : null,
                        address_region: ruian.address_obj ? ruian.address_obj.region : null,
                        creator_id: ruian.created_by,
                        creator_str: `${ruian.creator.secondname} ${ruian.creator.firstname}`,
                        creator_img: ruian.creator.personalphoto,
                        created_date: new Date(ruian.created_date),
                        updated_date: ruian.updated_date ? new Date(ruian.updated_date) : null,
                        deleted_date: ruian.deleted_date ? new Date(ruian.deleted_date) : null,
                    });
                }
            });
        }

        this.ruianAddressLoad = false;
        this.ref.markForCheck();

        return elements as unknown as DataResult[];
    }

    onCreated(): void {
        this.disableToolBarBtn();
    }

    setInitialGridFiltering(): void {
        this.gridRuians.clearFiltering();
        // this.gridRuians.filterByColumn('created_by', 'equal', this.currentUser.id, 'and', false, true);
        // this.viewFilterInstance.value = 'moje žádanky - všechny';
        this.ref.markForCheck();
    }

    onLoad(): void {
        const wrapper1 = document.getElementById('scroll_wrapper1_ruians');
        const wrapper2 = document.getElementById('scroll_wrapper2_ruians');

        if (wrapper1 && wrapper2) {
            wrapper1.onscroll = (): void => {
                wrapper2.scrollLeft = wrapper1.scrollLeft;
            };

            wrapper2.onscroll = (): void => {
                wrapper1.scrollLeft = wrapper2.scrollLeft;
            };
        }

        this.dataNetbaseService.ruianAddressSource.pipe(untilDestroyed(this))
            .subscribe({
                next: (addresses: AddressConnectedRuian[] | undefined) => {
                    this.ruians = addresses;
                    this.data = this.gridRuians.dataSource = this.loadData();
                    this.sortOptions = {
                        columns: [
                            {field: 'created_date', direction: 'Descending'},
                            {
                                field: 'id',
                                direction: 'Descending',
                            },
                        ],
                    };
                },
                error: error => {
                    console.error(error);
                    this.ruianAddressLoad = false;
                    this.ref.markForCheck();
                },
            });
    }

    search(): void {
        this.gridRuians.search(this.searchtextObj.value);
    }

    onDataBound(): void {
        this.rowSelected();

        const width = document.getElementById('ruianGrid_content_table')?.offsetWidth;
        const parent1 = document.getElementById('scroll_div_ruians');
        const parent2 = document.getElementById('grid_parent_ruians');

        if (parent1 && parent2 && width) {
            parent1.style.width = `${width}px`;
            parent2.style.width = `${width}px`;
        }

        this.gridRuians.autoFitColumns();
        this.ref.markForCheck();
    }

    rowDataBound(args: RowDataBoundEventArgs): void {
        const data = args.data as AddressConnectedRuian;

        if (args.row && data.deleted_date) {
            args.row.getElementsByClassName('e-gridchkbox')[0].classList.add('disablecheckbox');
            args.row
                .getElementsByClassName('e-checkbox-wrapper')[0]
                .classList.add('disablecheckbox');
            args.row.classList.add('e-disabled');
        }

        if (
            this.currentUser &&
            args.row &&
            data.created_by !== this.currentUser.id &&
            !this.permissionService.checkUserISServiceAdmin(this.currentUser) &&
            !this.permissionService.checkUserISAdmin(this.currentUser)
        ) {
            args.row.getElementsByClassName('e-gridchkbox')[0].classList.add('disablecheckbox');
            args.row
                .getElementsByClassName('e-checkbox-wrapper')[0]
                .classList.add('disablecheckbox');
        }

        this.ref.markForCheck();
    }

    onStatusFilterChange(e: ChangeEventArgs): void {
        if (e.value === 'všechny adresy') {
            this.gridRuians.clearFiltering();
        } else if (e.value === 'pouze aktivní adresy') {
            this.gridRuians.clearFiltering();
            this.gridRuians.filterByColumn('deleted_date', 'notequal', null, 'and', false, true);
        } else if (e.value === 'pouze smazané adresy') {
            this.gridRuians.clearFiltering();
            this.gridRuians.filterByColumn('deleted_date', 'notequal', null, 'and', false, true);
        }
    }

    cancelBtnClick(): void {
        this.searchtextObj.value = '';
        this.gridRuians.searchSettings.key = '';
    }

    toolbarClick(args: ClickEventArgs): void {
        const xlsProp: ExcelExportProperties = {
            fileName: 'js_ruians_export.xlsx',
            enableFilter: true,
            exportType: this.exportCurrentPage ? 'CurrentPage' : 'AllPages',
        };

        if (args.item.id === 'ruianGrid_excelexport') {
            void this.gridRuians.excelExport(xlsProp);
        } else if (args.item.id === 'delete') {
            if (this.gridRuians.getSelectedRecords().length !== 0) {
                this.confirmationDelete.content = `Potvrďte smazání ${this.clickedRow.length} adres RUIAN`;
                this.confirmationDelete.show();
            } else {
                this.confirmationCancel.content = 'Musíte vybrat alespoň jednu adresu RUIAN';
                this.confirmationCancel.show();
            }
        } else if (args.item.id === 'copy') {
            if (this.gridRuians.getSelectedRecords().length !== 0) {
                this.isUpdate = false;
                this.isCreate = false;
                this.isCopy = true;
                this.renderRuianFormDialog = true;
                this.ref.markForCheck();
            } else {
                this.confirmationCancel.content = 'Musíte vybrat jednu RUIAN adresu';
                this.confirmationCancel.show();
            }
        } else if (args.item.id === 'detail') {
            void this.router.navigate(['/services/ruian/detail/', this.clickedRow[0].id]);
        } else if (args.item.id === 'customRefresh') {
            this.ruianAddressLoad = true;
            console.info('refresh DATA!');
            this.ref.markForCheck();
            this.dataNetbaseService.clearRuianAddressCache();
            this.dataNetbaseService.setRuianAddressDataSource();
        }
    }

    enableToolbarBtn(): void {
        if (this.currentUser && this.permissionService.checkUserISServiceAdmin(this.currentUser)) {
            this.gridRuians.toolbarModule.enableItems(
                ['ruianGrid_edit', 'delete', 'isp_service', 'detail'],
                true,
            );
        } else {
            this.gridRuians.toolbarModule.enableItems(['detail'], true);
        }
    }

    disableToolBarBtn(): void {
        this.gridRuians.toolbarModule.enableItems(
            ['ruianGrid_edit', 'delete', 'isp_service', 'detail'],
            false,
        );
    }

    rowSelected(): void {
        this.clickedRow = [];

        const selectedrecords: object[] = this.gridRuians.getSelectedRecords();

        if (selectedrecords.length > 0) {
            selectedrecords.map((row: AddressConnectedRuian) => {
                const tempData: AddressConnectedRuian | undefined = this.ruians?.find(
                    x => x.id === row.id,
                );

                if (tempData) {
                    this.clickedRow.push(tempData);
                }

                this.ref.markForCheck();
            });

            this.clickedRow$ = new Observable<AddressConnectedRuian[]>(
                (observer: Observer<AddressConnectedRuian[]>) => {
                    observer.next(this.clickedRow);
                },
            );
            this.enableToolbarBtn();
        } else {
            this.disableToolBarBtn();
            this.clickedRow$ = new Observable<AddressConnectedRuian[]>(
                (observer: Observer<AddressConnectedRuian[]>) => {
                    observer.next(this.clickedRow);
                },
            );
        }
    }

    actionBegin(args): void {
        if (args.requestType === 'add' || args.requestType === 'beginEdit') {
            args.cancel = true;

            if (args.requestType === 'beginEdit') {
                this.isUpdate = true;
                this.isCreate = false;
                this.isCopy = false;
            }

            if (args.requestType === 'add') {
                this.isCreate = true;
                this.isUpdate = false;
                this.isCopy = false;
            }

            this.renderRuianFormDialog = true;
            this.ref.markForCheck();
        }
    }

    actionComplete(args): void {
        if (args.requestType === 'beginEdit' || args.requestType === 'add') {
            args.form.ej2_instances[0].rules = {};
        }
    }

    async deleteRUIAN(): Promise<void> {
        const promises: Promise<AddressConnectedRuian | undefined>[] = [];
        let rejects = 0;

        this.ruianAddressLoad = true;
        this.ref.markForCheck();

        for (const row of this.clickedRow) {
            if (
                this.currentUser &&
                !row.deleted_date &&
                this.permissionService.checkUserISServiceAdmin(this.currentUser)
            ) {
                row.deleted_by = this.currentUser.id;

                const declineObj = this.servicesService.deleteAddressRuian(row)
                    ?.toPromise();

                if (declineObj) {
                    promises.push(declineObj);
                }
            } else {
                rejects++;
            }
        }

        await Promise.all(promises)
            .then(result => {
                if (result.length > 0) {
                    const body = `Celkem smazáno: ${result.length} adres`;
                    const options = {progressBar: true, timeOut: 5000};

                    this.messages.showSuccess('Smazání úspěšně dokončeno', body, options);
                }

                if (rejects > 0) {
                    const body = `Celkem u: ${rejects} adres`;
                    const options = {progressBar: true, timeOut: 5000};

                    this.messages.showError('Nedostatečné oprávnění', body, options);
                }

                this.gridRuians.clearRowSelection();
                this.dataNetbaseService.setRuianAddressDataSource();
                this.rowSelected();
                this.ruianAddressLoad = false;
                this.confirmationDelete.hide();
                this.ref.markForCheck();
            });
    }

    newProject(): void {
        if (this.currentUser && this.permissionService.checkUserISServiceAdmin(this.currentUser)) {
            this.isProjectCopy = false;
            this.isProjectCreate = true;
            this.isProjectUpdate = false;
            this.renderProjectFormDialog = true;
        } else {
            const body = 'Nemáte oprávnění provést tuto akci...';
            const options = {progressBar: true, timeOut: 5000};

            this.messages.showError('Nedostatečné oprávnění', body, options);
            this.ruianAddressLoad = false;
            this.ref.markForCheck();
        }
    }
}
