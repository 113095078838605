import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {NetPackages} from '@src/app/_models/services/net-packages';
import {TvPackages} from '@src/app/_models/services/tv-packages';
import {Bonuses} from '@src/app/_models/services/bonuses';
import {Discounts} from '@src/app/_models/services/discounts';
import {TvChannels} from '@src/app/_models/services/tv-channels';
import {TvThemes} from '@src/app/_models/services/tv-themes';
import {TransferTypes} from '@src/app/_models/services/transfer-types';
import {DealTypes} from '@src/app/_models/services/deal-types';
import {SpeedTypes} from '@src/app/_models/services/speed-types';
import {TechnologyTypes} from '@src/app/_models/services/technology-types';
import {ExtendServices} from '@src/app/_models/services/extend-services';
import {SpaceTypes} from '@src/app/_models/services/space-types';
import {UnitTypes} from '@src/app/_models/services/unit-types';
import {InstallTypes} from '@src/app/_models/services/install-types';
import {Hardware} from '@src/app/_models/services/hardware';
import {Places} from '@src/app/_models/services/places';
import {AddressConnectedField} from '@src/app/_models/services/address-connected-field';
import {AddressConnectedRuian} from '@src/app/_models/services/address-connected-ruian';
import {AddressExcluded} from '@src/app/_models/services/address-excluded';
import {AddressUntrusted} from '@src/app/_models/services/address-untrusted';
import {catchError, tap} from 'rxjs/operators';
import {MessageService} from '@src/app/_services/message.service';
import {HardwareConfig} from '@src/app/_models/services/hardware-config';
import {Project} from '@src/app/_models/projects/project';
import {ProjectFile} from '@src/app/_models/projects/project-file';
import {AddressConnectedFieldPhotos} from '@src/app/_models/services/address-connected-field-photos';
import {AddressConnectedRuianPhotos} from '@src/app/_models/services/address-connected-ruian-photos';
import {TvServices} from '@src/app/_models/services/tv-services';
import {DataNetbaseService} from '@src/app/_services/data-netbase.service';
import {Orders} from '@src/app/_models/netbase/orders';
import {Leads} from '@src/app/_models/netbase/leads';
import {EnvironmentService} from '@src/app/_services/environment.service';
import {User} from '@src/app/_models/user/user';
import {Company} from '@src/app/_models/company/company';
import {Address} from '@src/app/_models/services/address';
import {MapMarker} from '@angular/google-maps';
import LatLngBoundsLiteral = google.maps.LatLngBoundsLiteral;
import LatLngBounds = google.maps.LatLngBounds;

export interface ServiceUntrustedAddressGridItem {
    id: number;
    address: string;
    ruian: number;
    descriptive: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    creator_id: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    creator_str: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    creator_img: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    created_date: Date;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    updated_date: Date | null;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    deleted_date: Date | null;
}

export interface ExcludeAddressGridItem {
    id?: number;
    address?: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    net_packages?: NetPackages[] | null;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    tv_services?: TvServices[] | null;
    descriptive?: string;
    ruian?: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    street_code?: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    city_code?: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    city_part_code?: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    district_code?: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    region_code?: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    creator_id?: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    creator_str?: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    creator_img?: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    created_date?: Date;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    updated_date?: Date | null;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    deleted_date?: Date | null;
}

export interface OrderGridItems {
    id: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    crm_id: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    erp_id: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    stmnt_id: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    type_id: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    provider_id: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    deal_id: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    unit_id: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    space_id: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    speed_id: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    hw_id: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    lead_id: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    operator_id: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    operator_str: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    operator_img: string;
    status: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    client_status: string;
    address: string;
    ruian: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    field_number: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    cl_cnumber: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    cl_firstname: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    cl_lastname: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    cl_fullname: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    cl_email: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    cl_billing_email: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    cl_technical_email: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    cl_business_email: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    cl_telephone: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    cl_mobilephone: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    cl_account_number: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    cl_bank_number: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    cl_address: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    cl_billing_address: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    cl_vat_id: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    cl_national_id_number: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    cl_send_invoices_by_post: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    cl_send_invoice_by_email: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    invoice_by_year: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    vip_install: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    internal_note: string;
    tvs: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    tvs_str: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    wicon_own_router: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    wicon_basics: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    wicon_garden: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    wicon_cabels: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    wicon_roaming: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    created_date: Date;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    updated_date: Date | null;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    deleted_date: Date | null;
    provider: Company;
    operator: User;
    type: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    hw_package: Hardware | null | undefined;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    hw_package_str: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    speed_type: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    unit_type: string | null;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    deal_type: number | string | null;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    flat_space: string | null;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    net_package: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    tv_service: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    tv_packages: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    ext_services: ExtendServices[] | null | undefined;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    ext_services_str: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    total_price: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    internet_price: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    television_price: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    ext_services_price: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    wifi_price: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    total_fee: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    internet_fee: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    television_fee: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    ext_services_fee: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    wifi_fee: number;
}

export interface OrderItemsGridItems {
    id: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    created_by: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    creator_str: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    creator_img: string;
    name: string;
    amount: number;
    price: number;
    dph: boolean;
    category: string;
    status: string;
    budget: string;
    department: string;
    company: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    material_id: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    to_storage: boolean;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    created_date: Date;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    updated_date: Date | null;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    deleted_date: Date | null;
}

export interface ServiceOrderInput {
    id?: number | undefined;
    address?: string;
    ruian?: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    field_number?: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    cl_firstname?: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    cl_lastname?: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    cl_email?: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    cl_billing_email?: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    cl_technical_email?: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    cl_business_email?: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    cl_telephone?: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    cl_mobilephone?: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    cl_account_number?: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    cl_bank_number?: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    cl_address?: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    cl_billing_address?: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    cl_vat_id?: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    cl_national_id_number?: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    cl_send_invoices_by_post?: boolean;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    cl_send_invoice_by_email?: boolean;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    cl_cnumber?: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    invoice_by_year?: boolean;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    vip_install?: boolean;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    internal_note?: string;
    tvs?: string | null;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    wicon_own_router?: boolean;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    wicon_basics?: boolean;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    wicon_garden?: boolean;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    wicon_cabels?: boolean;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    wicon_roaming?: boolean;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    operator_id?: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    type_id?: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    provider_id?: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    deal_id?: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    deal_age?: number | null;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    unit_id?: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    space_id?: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    speed_id?: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    hw_id?: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    net_package_id?: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    tv_service_id?: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    ext_services?: ExtendServices[];
    // eslint-disable-next-line @typescript-eslint/naming-convention
    total_price?: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    total_fee?: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    internet_price?: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    internet_fee?: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    television_price?: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    television_fee?: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    ext_services_price?: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    ext_services_fee?: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    wifi_price?: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    wifi_fee?: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    tv_packages?: TvPackages[];
    status?: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    client_status?: string;
}

export interface ClientGridItems {
    id: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    client_number: string;
    name: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    isp_link: string;
}

export interface ServiceGridItems {
    id: number;
    name: string;
    price: number;
}

export interface Marker {
    position: {lng: number; lat: number};
    label: {color: string; text: `Marker label ${number}`};
    title: `Marker title ${number}`;
    info: string;
    options: {animation: google.maps.Animation};
}

export interface Location {
    lat: number;
    lng: number;
    viewport?: LatLngBounds | LatLngBoundsLiteral | boolean;
    zoom: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    address_level_1?: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    address_level_2?: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    address_country?: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    address_zip?: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    address_state?: string;
    marker?: MapMarker;
}

export interface RuianGridItems {
    id: number;
    keys: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    electrical_counter: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    clip_frame: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    number_of_storeys: number;
    lift: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    business_note: string;
    provider: string;
    project: string | null;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    realized_at: Date | null;
    technology: string;
    transfer: string;
    descriptive: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    flat_space: string | null;
    discount: number | string | null;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    deal_type: number | string | null;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    unit_type: string | null;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    net_packages: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    tv_services: string;
    marketingnote: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    marketing_ads: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    owner_name: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    manager_name: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    number_of_units: number;
    ruian: number;
    address: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    address_street?: string | null;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    address_city: string | null;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    address_zip: number | null;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    address_district: string | null;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    address_region: string | null;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    creator_id: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    creator_str: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    creator_img: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    created_date: Date;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    updated_date: Date | null;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    deleted_date: Date | null;
}

export interface FieldGridItems {
    id: number;
    keys: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    electrical_counter: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    clip_frame: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    business_note: string;
    provider: string;
    project?: string | null;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    realized_at: Date | null;
    technology: string;
    transfer: string;
    descriptive: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    flat_space: string | null;
    discount?: number | string | null;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    deal_type?: number | string | null;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    unit_type?: string | null;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    net_packages: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    tv_services: string;
    marketingnote: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    marketing_ads: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    owner_name: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    manager_name: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    number_of_units: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    number_of_storeys: number;
    lift: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    field_number: number;
    address: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    address_street?: string | null;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    address_city: string | null;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    address_zip: number | null;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    address_district: string | null;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    address_region: string | null;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    creator_id: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    creator_str: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    creator_img: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    created_date: Date;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    updated_date: Date | null;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    deleted_date: Date | null;
}

export interface LeadsGridItems {
    id: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    crm_id: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    erp_id: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    stmnt_id: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    type_id: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    provider_id: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    deal_id: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    unit_id: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    space_id: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    speed_id: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    hw_id: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    operator_id: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    operator_str: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    operator_img: string;
    status: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    client_status: string;
    address: string;
    ruian: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    field_number: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    cl_cnumber: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    cl_firstname: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    cl_lastname: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    cl_fullname: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    cl_email: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    cl_billing_email: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    cl_technical_email: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    cl_business_email: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    cl_telephone: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    cl_mobilephone: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    cl_account_number: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    cl_bank_number: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    cl_address: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    cl_billing_address: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    cl_vat_id: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    cl_national_id_number: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    cl_send_invoices_by_post: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    cl_send_invoice_by_email: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    invoice_by_year: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    vip_install: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    internal_note: string;
    tvs: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    tvs_str: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    wicon_own_router: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    // eslint-disable-next-line @typescript-eslint/naming-convention
    wicon_basics: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    wicon_garden: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    wicon_cabels: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    wicon_roaming: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    created_date: Date;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    updated_date: Date | null;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    deleted_date: Date | null;
    provider?: Company;
    operator: User;
    type: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    hw_package: Hardware | null | undefined;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    hw_package_str: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    speed_type: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    unit_type: string | null;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    deal_type: number | string | null;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    flat_space: string | null;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    net_package: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    tv_service: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    tv_packages: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    ext_services: ExtendServices[] | null | undefined;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    ext_services_str: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    total_price: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    internet_price: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    television_price: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    ext_services_price: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    wifi_price: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    total_fee: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    internet_fee: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    television_fee: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    ext_services_fee: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    wifi_fee: number;
}

export interface SmartAddress {
    address?: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    oriental_number?: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    house_number?: number;
    ruian?: number;
    street?: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    street_code?: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    city_part?: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    city_part_code?: number;
    city?: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    city_code?: number;
    zip?: number;
    district?: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    district_code?: number;
    region?: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    region_code?: number;
    state?: string;
    gps?: string;
    jstk?: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    address_obj?: Address;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    number_of_units?: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    number_of_storeys?: number;
    lift?: boolean;
}

export interface ExcludeAddressInput {
    id?: number;
    address?: string;
    ruian?: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    city_code?: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    district_code?: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    street_code?: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    city_part_code?: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    region_code?: number;
    state?: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    addr_oriental_number?: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    addr_house_number?: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    addr_ruian?: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    addr_street?: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    addr_street_code?: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    addr_city?: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    addr_city_code?: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    addr_city_part?: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    addr_city_part_code?: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    addr_district?: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    addr_district_code?: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    addr_region?: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    addr_region_code?: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    addr_state?: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    addr_gps?: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    addr_jstk?: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    addr_zip?: number;
    descriptive?: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    net_packages?: number[];
    // eslint-disable-next-line @typescript-eslint/naming-convention
    tv_services?: number[];
    // eslint-disable-next-line @typescript-eslint/naming-convention
    created_by?: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    updated_by?: number;
}

export interface UntrustedAddressInput {
    id?: number;
    address?: string;
    ruian?: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    provider_id?: number;
    descriptive?: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    updated_by?: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    created_by?: number;
}

export interface LeadInput {
    id?: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    crm_id?: number | null;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    erp_id?: number | null;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    stmnt_id?: number | null;
    address?: string | null;
    ruian?: number | null;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    oriental_number?: number | null;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    house_number?: number | null;
    street?: string | null;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    street_code?: number | null;
    city?: string | null;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    city_code?: number | null;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    city_part?: string | null;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    city_part_code?: number | null;
    district?: number | null;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    district_code?: number | null;
    region?: string | null;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    region_code?: number | null;
    state?: string | null;
    gps?: string | null;
    jstk?: string | null;
    zip?: number | null;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    field_number?: number | null;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    cl_firstname?: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    cl_lastname?: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    cl_email?: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    cl_billing_email?: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    cl_technical_email?: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    cl_business_email?: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    cl_telephone?: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    cl_mobilephone?: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    cl_account_number?: number | null;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    cl_bank_number?: number | null;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    cl_address?: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    cl_billing_address?: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    cl_vat_id?: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    cl_national_id_number?: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    cl_send_invoices_by_post?: boolean | null;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    cl_send_invoice_by_email?: boolean | null;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    cl_cnumber?: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    cl_isplink?: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    cl_bilance?: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    cl_active_services?: string[];
    // eslint-disable-next-line @typescript-eslint/naming-convention
    invoice_by_year?: boolean | null;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    vip_install?: boolean | null;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    internal_note?: string;
    tvs?: string | null;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    wicon_own_router?: boolean;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    wicon_basics?: boolean | null;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    wicon_garden?: boolean | null;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    wicon_cabels?: boolean | null;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    wicon_roaming?: boolean | null;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    operator_id?: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    type_id?: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    provider_id?: number | null;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    deal_id?: number | null;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    deal_age?: number | null;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    unit_id?: number | null;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    space_id?: number | null;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    speed_id?: number | null;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    hw_id?: number | null;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    net_package_id?: number | null;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    tv_service_id?: number | null;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    ext_services?: number[] | null;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    total_price?: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    total_fee?: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    internet_price?: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    internet_fee?: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    television_price?: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    television_fee?: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    ext_services_price?: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    ext_services_fee?: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    wifi_price?: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    wifi_fee?: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    tv_packages?: number[] | null;
    status?: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    client_status?: string;
}

export interface HardwareConfigurationInput {
    id?: number;
    basics?: string;
    cabels?: string;
    garden?: string;
    roaming?: string;
    unit?: string;
    space?: string;
    hardware?: string;
    speed?: string;
    creator?: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    unit_id?: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    space_id?: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    hardware_id?: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    speed_id?: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    created_by?: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    updated_by?: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    created_date?: Date;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    updated_date?: Date | null;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    deleted_date?: Date | null;
}

export interface BonusInput {
    id?: number;
    name?: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    created_by?: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    updated_by?: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    creator_str?: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    creator_img?: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    created_date?: Date;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    updated_date?: Date | null;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    deleted_date?: Date | null;
}

export interface ChannelInput {
    id?: number;
    name?: string;
    logo?: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    app_available?: boolean;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    hd_resolution?: boolean;
    theme?: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    created_by?: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    creator_str?: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    creator_img?: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    created_date?: Date;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    updated_date?: Date | null;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    deleted_date?: Date | null;
}

export interface DealInput {
    id?: number;
    age?: number;
    description?: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    inst_price?: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    created_by?: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    updated_by?: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    creator_str?: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    creator_img?: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    created_date?: Date;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    updated_date?: Date | null;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    deleted_date?: Date | null;
}

export interface DiscountInput {
    id?: number;
    discount?: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    created_by?: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    updated_by?: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    creator_str?: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    creator_img?: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    created_date?: Date;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    updated_date?: Date | null;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    deleted_date?: Date | null;
}

export interface ExtendServiceInput {
    id?: number;
    name?: string;
    price?: number;
    description?: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    updated_by?: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    created_by?: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    creator_str?: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    creator_img?: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    created_date?: Date;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    updated_date?: Date | null;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    deleted_date?: Date | null;
}

export interface SpaceInput {
    id?: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    metric_until?: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    metric_to?: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    created_by?: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    updated_by?: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    creator_str?: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    creator_img?: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    created_date?: Date;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    updated_date?: Date | null;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    deleted_date?: Date | null;
}

export interface UnitInput {
    id?: number;
    name?: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    created_by?: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    updated_by?: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    creator_str?: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    creator_img?: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    created_date?: Date;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    updated_date?: Date | null;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    deleted_date?: Date | null;
}

export interface HardwareInput {
    id?: number;
    name?: string;
    fee?: number;
    price?: number;
    photo?: string | null;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    photo_url?: string | null;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    photo_thumb_url?: string | null;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    created_by?: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    updated_by?: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    creator_str?: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    creator_img?: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    created_date?: Date;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    updated_date?: Date | null;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    deleted_date?: Date | null;
}

export interface InstallInput {
    id?: number;
    name?: string;
    description?: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    inst_price?: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    created_by?: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    creator_str?: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    creator_img?: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    created_date?: Date;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    updated_date?: Date | null;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    deleted_date?: Date | null;
}

export interface SpeedInput {
    id?: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    speed_until?: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    speed_to?: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    created_by?: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    updated_by?: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    creator_str?: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    creator_img?: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    created_date?: Date;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    updated_date?: Date | null;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    deleted_date?: Date | null;
}

export interface PlaceInput {
    id?: number;
    name?: string;
    type?: string;
    code?: number;
    address?: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    created_by?: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    creator_str?: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    creator_img?: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    created_date?: Date;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    updated_date?: Date | null;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    deleted_date?: Date | null;
    ruian?: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    city_code?: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    district_code?: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    street_code?: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    city_part_code?: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    region_code?: number;
    state?: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    addr_oriental_number?: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    addr_house_number?: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    addr_ruian?: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    addr_street?: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    addr_street_code?: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    addr_city?: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    addr_city_code?: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    addr_city_part?: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    addr_city_part_code?: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    addr_district?: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    addr_district_code?: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    addr_region?: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    addr_region_code?: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    addr_state?: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    addr_gps?: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    addr_jstk?: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    addr_zip?: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    updated_by?: number;
}

export interface TvServiceInput {
    id?: number;
    name?: string;
    price?: number;
    provider?: string;
    recommended?: boolean;
    extension?: boolean;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    sale_order?: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    ext_services?: string;
    transfer?: string;
    discount?: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    tv_packages?: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    tv_places?: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    tv_bonuses?: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    created_by?: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    creator_str?: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    creator_img?: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    created_date?: Date;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    updated_date?: Date | null;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    deleted_date?: Date | null;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    provider_id?: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    transfer_id?: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    discount_id?: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    pernament_price?: number;
    packages?: TvPackages[];
    bonuses?: Bonuses[];
    extServices?: ExtendServices[];
    places?: Places[];
    // eslint-disable-next-line @typescript-eslint/naming-convention
    updated_by?: number;
}

export interface TvChannelsGrid {
    id: number;
    name: string;
    logo: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    app_available: boolean;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    hd_resolution: boolean;
    theme: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    created_by: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    creator_str: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    creator_img: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    created_date: Date;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    updated_date: Date | null;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    deleted_date: Date | null;
}

export interface NetPackageInput {
    id?: number;
    name?: string;
    price?: number;
    provider?: string;
    aggregation?: string;
    recommended?: boolean;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    provider_id?: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    transfer_id?: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    discount_id?: number;
    extension?: boolean;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    sale_order?: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    speed_up?: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    speed_down?: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    ext_services?: string;
    transfer?: string;
    discount?: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    net_places?: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    net_bonuses?: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    created_by?: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    updated_by?: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    creator_str?: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    creator_img?: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    created_date?: Date;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    updated_date?: Date | null;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    deleted_date?: Date | null;
}

export interface TechnologyInput {
    id?: number;
    name?: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    created_by?: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    updated_by?: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    creator_str?: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    creator_img?: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    created_date?: Date;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    updated_date?: Date | null;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    deleted_date?: Date | null;
}

export interface TransferInput {
    id?: number;
    name?: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    connection_method?: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    created_by?: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    updated_by?: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    creator_str?: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    creator_img?: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    created_date?: Date;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    updated_date?: Date | null;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    deleted_date?: Date | null;
}

export interface TvPackageInput {
    id?: number;
    name?: string;
    price?: number;
    services?: string;
    recommended?: boolean;
    extension?: boolean;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    sale_order?: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    tv_channels?: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    created_by?: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    updated_by?: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    creator_str?: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    creator_img?: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    created_date?: Date;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    updated_date?: Date | null;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    deleted_date?: Date | null;
}

export interface FieldInput {
    id?: number;
    keys?: boolean;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    electrical_counter?: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    clip_frame?: boolean;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    business_note?: string;
    descriptive?: string;
    marketingnote?: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    marketing_ads?: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    owner_name?: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    manager_name?: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    number_of_units?: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    field_number?: number;
    address?: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    provider_id?: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    project_id?: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    realized_at?: string | null;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    unit_id?: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    deal_id?: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    discount_id?: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    space_id?: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    transfer_id?: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    net_packages?: NetPackages[] | number[] | null;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    tv_services?: number[] | TvServices[] | null;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    technology_id?: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    created_by?: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    valid_ruian?: boolean;
    street?: string | null;
    city?: string | null;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    city_part?: string | null;
    district?: string | null;
    region?: string | null;
    state?: string | null;
    gps?: string | null;
    jstk?: string | null;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    speed_id?: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    number_of_storeys?: number;
    lift?: boolean;
    zip?: number | null;
}

export interface RuianInput {
    id?: number;
    keys?: boolean;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    electrical_counter?: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    clip_frame?: boolean;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    business_note?: string;
    descriptive?: string;
    marketingnote?: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    marketing_ads?: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    owner_name?: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    manager_name?: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    number_of_units?: number;
    ruian?: number;
    address?: string | null;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    provider_id?: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    project_id?: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    realized_at?: Date | string | null;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    unit_id?: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    deal_id?: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    discount_id?: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    space_id?: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    transfer_id?: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    net_packages?: NetPackages[];
    // eslint-disable-next-line @typescript-eslint/naming-convention
    tv_services?: TvServices[];
    // eslint-disable-next-line @typescript-eslint/naming-convention
    technology_id?: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    created_by?: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    valid_ruian?: boolean;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    oriental_number?: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    house_number?: number;
    street?: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    street_code?: number;
    city?: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    city_code?: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    city_part?: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    city_part_code?: number;
    district?: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    district_code?: number;
    region?: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    region_code?: number;
    state?: string;
    gps?: string;
    jstk?: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    speed_id?: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    number_of_storeys?: number;
    lift?: boolean;
    zip?: number;
}

export interface ProjectInput {
    id?: number;
    name?: string;
    description?: string;
    budget?: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    start_at?: Date;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    end_at?: Date;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    created_by?: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    updated_by?: number;
}

export interface TvChannelInput {
    id?: number;
    name?: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    app_available?: boolean;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    hd_resolution?: boolean;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    theme_id?: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    created_by?: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    updated_by?: number;
}

export interface SmartTvInterface {
    [key: string]: boolean | string;
}

const httpOptions = {
    headers: new HttpHeaders({'Content-Type': 'application/json'}),
};

@Injectable({
    providedIn: 'root',
})
export class ServicesService {
    private readonly lead = new BehaviorSubject<Leads | null>(null);

    private readonly netPackageUrl: string;

    private readonly tvPackageUrl: string;

    private readonly tvChannelsUrl: string;

    private readonly tvThemesUrl: string;

    private readonly discountsUrl: string;

    private readonly extendedServicesUrl: string;

    private readonly bonusesUrl: string;

    private readonly hardwareUrl: string;

    private readonly hardwareConfigUrl: string;

    private readonly transferTypesUrl: string;

    private readonly dealTypessUrl: string;

    private readonly speedTypesUrl: string;

    private readonly technologyTypesUrl: string;

    private readonly spaceTypesUrl: string;

    private readonly installTypesUrl: string;

    private readonly unitTypesUrl: string;

    private readonly placesUrl: string;

    private readonly servicesAddressUrl: string;

    private readonly untrustedAddressUrl: string;

    private readonly excludedAddressUrl: string;

    private readonly fileUrl: string;

    private readonly projectUrl: string;

    private readonly tvServicesUrl: string;

    private readonly leadsUrl: string;

    private readonly ordersUrl: string;

    private readonly downloadFileUrl: string;

    leadSource = this.lead.asObservable();

    constructor(
        private readonly http: HttpClient,
        private readonly dataNetbaseService: DataNetbaseService,
        private readonly messageService: MessageService,
        private readonly environmentService: EnvironmentService,
    ) {
        this.netPackageUrl = this.environmentService.backendURL + '/api/netbase/netpackage';
        this.tvPackageUrl = this.environmentService.backendURL + '/api/netbase/tvpackage';
        this.tvChannelsUrl = this.environmentService.backendURL + '/api/netbase/channel';
        this.tvThemesUrl = this.environmentService.backendURL + '/api/netbase/theme';
        this.discountsUrl = this.environmentService.backendURL + '/api/netbase/discount';
        this.extendedServicesUrl =
            this.environmentService.backendURL + '/api/netbase/service/extend';
        this.bonusesUrl = this.environmentService.backendURL + '/api/netbase/bonuses';
        this.hardwareUrl = this.environmentService.backendURL + '/api/netbase/hardware';
        this.hardwareConfigUrl =
            this.environmentService.backendURL + '/api/netbase/hardware/configuration';
        this.transferTypesUrl = this.environmentService.backendURL + '/api/netbase/type/transfer';
        this.dealTypessUrl = this.environmentService.backendURL + '/api/netbase/type/deal';
        this.speedTypesUrl = this.environmentService.backendURL + '/api/netbase/type/speed';
        this.technologyTypesUrl =
            this.environmentService.backendURL + '/api/netbase/type/technology';
        this.spaceTypesUrl = this.environmentService.backendURL + '/api/netbase/type/space';
        this.installTypesUrl = this.environmentService.backendURL + '/api/netbase/type/install';
        this.unitTypesUrl = this.environmentService.backendURL + '/api/netbase/type/unit';
        this.placesUrl = this.environmentService.backendURL + '/api/netbase/places';
        this.servicesAddressUrl = this.environmentService.backendURL + '/api/netbase/address';
        this.untrustedAddressUrl =
            this.environmentService.backendURL + '/api/netbase/address-untrusted';

        this.excludedAddressUrl =
            this.environmentService.backendURL + '/api/netbase/address-excluded';
        this.fileUrl = this.environmentService.backendURL + '/api/file';
        this.projectUrl = this.environmentService.backendURL + '/api/projects';
        this.tvServicesUrl = this.environmentService.backendURL + '/api/netbase/service/tv';
        this.leadsUrl = this.environmentService.backendURL + '/api/netbase/lead';
        this.ordersUrl = this.environmentService.backendURL + '/api/netbase/order';
        this.downloadFileUrl = this.environmentService.backendURL + '/api/file/download/';
    }

    /**
     * Handle Http operation that failed.
     * Let the app continue.
     * @param operation - name of the operation that failed
     * @param result - optional value to return as the observable result
     */
    private handleError<T>(operation = 'operation', result?: T) {
        return (error: {[key: string]: string}): Observable<T | undefined> => {
            console.error(error);
            this.log(`${operation} failed: ${error.message}`);

            return of(result);
        };
    }

    /** Log a ticketService message with the MessageService */
    private log(message: string): void {
        this.messageService.addNotification(`Material: ${message}`, false);
    }

    addNetPackage(netPackage: NetPackageInput): Observable<NetPackages> {
        this.dataNetbaseService.clearNetPackagesCache();

        return this.http.post<NetPackages>(this.netPackageUrl, netPackage, httpOptions);
    }

    updateNetPackage(netPackage: NetPackageInput): Observable<NetPackages> | undefined {
        if (!netPackage.id) {
            console.error('ID is missing...');

            return;
        }

        const url = `${this.netPackageUrl}/${netPackage.id}`;

        this.dataNetbaseService.clearNetPackagesCache();

        return this.http.put<NetPackages>(url, netPackage, httpOptions);
    }

    addTvPackage(tvPackage: TvPackageInput): Observable<TvPackages> {
        this.dataNetbaseService.clearTvPackagesCache();
        this.dataNetbaseService.clearTvServicesCache();

        return this.http.post<TvPackages>(this.tvPackageUrl, tvPackage, httpOptions);
    }

    addTvService(tvService: TvServiceInput): Observable<TvServices> {
        this.dataNetbaseService.clearTvServicesCache();
        this.dataNetbaseService.clearTvPackagesCache();

        return this.http.post<TvServices>(this.tvServicesUrl, tvService, httpOptions);
    }

    updateTvPackage(tvPackage: TvPackageInput): Observable<TvPackages> | undefined {
        if (!tvPackage.id) {
            console.error('ID is missing...');

            return;
        }

        const url = `${this.tvPackageUrl}/${tvPackage.id}`;

        this.dataNetbaseService.clearTvPackagesCache();
        this.dataNetbaseService.clearTvServicesCache();

        return this.http.put<TvPackages>(url, tvPackage, httpOptions);
    }

    updateTvService(tvService: TvServiceInput): Observable<TvServices> | undefined {
        if (!tvService.id) {
            console.error('ID is missing...');

            return;
        }

        const url = `${this.tvServicesUrl}/${tvService.id}`;

        this.dataNetbaseService.clearTvServicesCache();
        this.dataNetbaseService.clearTvPackagesCache();

        return this.http.put<TvServices>(url, tvService, httpOptions);
    }

    addHardware(hardware: HardwareInput): Observable<Hardware> {
        this.dataNetbaseService.clearHardwareCache();

        return this.http.post<Hardware>(this.hardwareUrl, hardware, httpOptions);
    }

    updateHardware(hardware: HardwareInput): Observable<Hardware> | undefined {
        if (!hardware.id) {
            console.error('ID is missing...');

            return;
        }

        const url = `${this.hardwareUrl}/${hardware.id}`;

        this.dataNetbaseService.clearHardwareCache();

        return this.http.put<Hardware>(url, hardware, httpOptions);
    }

    deleteHardware(hardware: Hardware): Observable<Hardware | undefined> | undefined {
        if (!hardware.deleted_by) {
            console.error('deleted_by is missing...');

            return;
        }

        const url = `${this.hardwareUrl}/${hardware.id}`;
        const params = new HttpParams().set('deleted_by', hardware.deleted_by.toString());
        const headers = new HttpHeaders({'Content-Type': 'application/json'});

        this.dataNetbaseService.clearHardwareCache();

        return this.http.delete<Hardware>(url, {headers, params});
    }

    addHardwareConfig(config: HardwareConfigurationInput): Observable<HardwareConfig> {
        this.dataNetbaseService.clearHardwareConfigCache();

        return this.http.post<HardwareConfig>(this.hardwareConfigUrl, config, httpOptions);
    }

    updateHardwareConfig(
        config: HardwareConfigurationInput,
    ): Observable<HardwareConfig> | undefined {
        if (!config.id) {
            console.error('ID is missing...');

            return;
        }

        const url = `${this.hardwareConfigUrl}/${config.id}`;

        this.dataNetbaseService.clearHardwareConfigCache();

        return this.http.put<HardwareConfig>(url, config, httpOptions);
    }

    deleteHardwareConfig(
        config: HardwareConfig,
    ): Observable<HardwareConfig | undefined> | undefined {
        if (!config.deleted_by) {
            console.error('deleted_by is missing...');

            return;
        }

        const url = `${this.hardwareConfigUrl}/${config.id}`;
        const params = new HttpParams().set('deleted_by', config.deleted_by.toString());
        const headers = new HttpHeaders({'Content-Type': 'application/json'});

        this.dataNetbaseService.clearHardwareConfigCache();

        return this.http.delete<HardwareConfig>(url, {headers, params});
    }

    addDiscount(discount: DiscountInput): Observable<Discounts> {
        this.dataNetbaseService.clearDiscountsCache();

        return this.http.post<Discounts>(this.discountsUrl, discount, httpOptions);
    }

    updateDiscount(discount: DiscountInput): Observable<Discounts> | undefined {
        if (!discount.id) {
            console.error('ID is missing...');

            return;
        }

        const url = `${this.discountsUrl}/${discount.id}`;

        this.dataNetbaseService.clearDiscountsCache();

        return this.http.put<Discounts>(url, discount, httpOptions);
    }

    deleteDiscount(discount: Discounts): Observable<Discounts | undefined> | undefined {
        if (!discount.deleted_by) {
            console.error('deleted_by is missing...');

            return;
        }

        const url = `${this.discountsUrl}/${discount.id}`;
        const params = new HttpParams().set('deleted_by', discount.deleted_by.toString());
        const headers = new HttpHeaders({'Content-Type': 'application/json'});

        this.dataNetbaseService.clearDiscountsCache();

        return this.http.delete<Discounts>(url, {headers, params});
    }

    addExtendedService(service: ExtendServiceInput): Observable<ExtendServices> {
        this.dataNetbaseService.clearExtendServicesCache();

        return this.http.post<ExtendServices>(this.extendedServicesUrl, service, httpOptions);
    }

    updateExtendedService(service: ExtendServiceInput): Observable<ExtendServices> | undefined {
        if (!service.id) {
            console.error('ID is missing...');

            return;
        }

        const url = `${this.extendedServicesUrl}/${service.id}`;

        this.dataNetbaseService.clearExtendServicesCache();

        return this.http.put<ExtendServices>(url, service, httpOptions);
    }

    deleteExtendedService(
        service: ExtendServices,
    ): Observable<ExtendServices | undefined> | undefined {
        if (!service.deleted_by) {
            console.error('deleted_by is missing...');

            return;
        }

        const url = `${this.extendedServicesUrl}/${service.id}`;
        const params = new HttpParams().set('deleted_by', service.deleted_by.toString());
        const headers = new HttpHeaders({'Content-Type': 'application/json'});

        this.dataNetbaseService.clearExtendServicesCache();

        return this.http.delete<ExtendServices>(url, {headers, params});
    }

    addTvChannel(channel: TvChannelInput): Observable<TvChannels> {
        this.dataNetbaseService.clearTvChannelsCache();

        return this.http.post<TvChannels>(this.tvChannelsUrl, channel, httpOptions);
    }

    updateTvChannel(channel: TvChannelInput): Observable<TvChannels> | undefined {
        if (!channel.id) {
            console.error('ID is missing...');

            return;
        }

        const url = `${this.tvChannelsUrl}/${channel.id}`;

        this.dataNetbaseService.clearTvChannelsCache();

        return this.http.put<TvChannels>(url, channel, httpOptions);
    }

    deleteTvChannel(channel: TvChannels): Observable<TvChannels | undefined> | undefined {
        if (!channel.deleted_by) {
            console.error('deleted_by is missing...');

            return;
        }

        const url = `${this.tvChannelsUrl}/${channel.id}`;
        const params = new HttpParams().set('deleted_by', channel.deleted_by.toString());
        const headers = new HttpHeaders({'Content-Type': 'application/json'});

        this.dataNetbaseService.clearTvChannelsCache();

        return this.http.delete<TvChannels>(url, {headers, params});
    }

    addTvThemes(theme: TvThemes): Observable<TvThemes> {
        this.dataNetbaseService.clearTvThemesCache();

        return this.http.post<TvThemes>(this.tvThemesUrl, theme, httpOptions);
    }

    updateTvThemes(theme: TvThemes): Observable<TvThemes> {
        const url = `${this.tvThemesUrl}/${theme.id}`;

        this.dataNetbaseService.clearTvThemesCache();

        return this.http.put<TvPackages>(url, theme, httpOptions);
    }

    deleteTvThemes(theme: TvThemes): Observable<TvThemes | undefined> | undefined {
        if (!theme.deleted_by) {
            console.error('deleted_by is missing...');

            return;
        }

        const url = `${this.tvThemesUrl}/${theme.id}`;
        const params = new HttpParams().set('deleted_by', theme.deleted_by.toString());
        const headers = new HttpHeaders({'Content-Type': 'application/json'});

        this.dataNetbaseService.clearTvThemesCache();

        return this.http.delete<TvThemes>(url, {headers, params});
    }

    addBonus(bonus: BonusInput): Observable<Bonuses> {
        this.dataNetbaseService.clearBonuseCache();

        return this.http.post<Bonuses>(this.bonusesUrl, bonus, httpOptions);
    }

    updateBonus(bonus: BonusInput): Observable<Bonuses> | undefined {
        if (!bonus.id) {
            console.error('ID is missing...');

            return;
        }

        const url = `${this.bonusesUrl}/${bonus.id}`;

        this.dataNetbaseService.clearBonuseCache();

        return this.http.put<Bonuses>(url, bonus, httpOptions);
    }

    deleteBonus(bonus: Bonuses): Observable<Bonuses | undefined> | undefined {
        if (!bonus.deleted_by) {
            console.error('deleted_by is missing...');

            return;
        }

        const url = `${this.bonusesUrl}/${bonus.id}`;
        const params = new HttpParams().set('deleted_by', bonus.deleted_by.toString());
        const headers = new HttpHeaders({'Content-Type': 'application/json'});

        this.dataNetbaseService.clearBonuseCache();

        return this.http.delete<Bonuses>(url, {headers, params});
    }

    addTransferType(transferType: TransferInput): Observable<TransferTypes> {
        this.dataNetbaseService.clearTransferTypesCache();

        return this.http.post<TransferTypes>(this.transferTypesUrl, transferType, httpOptions);
    }

    updateTransferType(transferType: TransferInput): Observable<TransferTypes> | undefined {
        if (!transferType.id) {
            console.error('ID is missing...');

            return;
        }

        const url = `${this.transferTypesUrl}/${transferType.id}`;

        this.dataNetbaseService.clearTransferTypesCache();

        return this.http.put<TransferTypes>(url, transferType, httpOptions);
    }

    deleteTransferType(type: TransferTypes): Observable<TransferTypes | undefined> | undefined {
        if (!type.deleted_by) {
            console.error('deleted_by is missing...');

            return;
        }

        const url = `${this.transferTypesUrl}/${type.id}`;
        const params = new HttpParams().set('deleted_by', type.deleted_by.toString());
        const headers = new HttpHeaders({'Content-Type': 'application/json'});

        this.dataNetbaseService.clearTransferTypesCache();

        return this.http.delete<TransferTypes>(url, {headers, params});
    }

    addDealType(dealType: DealInput): Observable<DealTypes> {
        this.dataNetbaseService.clearDealTypesCache();

        return this.http.post<DealTypes>(this.dealTypessUrl, dealType, httpOptions);
    }

    updateDealType(dealType: DealInput): Observable<DealTypes> | undefined {
        if (!dealType.id) {
            console.error('ID is missing...');

            return;
        }

        const url = `${this.dealTypessUrl}/${dealType.id}`;

        this.dataNetbaseService.clearDealTypesCache();

        return this.http.put<DealTypes>(url, dealType, httpOptions);
    }

    deleteDealType(type: DealTypes): Observable<DealTypes | undefined> | undefined {
        if (!type.deleted_by) {
            console.error('deleted_by is missing...');

            return;
        }

        const url = `${this.dealTypessUrl}/${type.id}`;
        const params = new HttpParams().set('deleted_by', type.deleted_by.toString());
        const headers = new HttpHeaders({'Content-Type': 'application/json'});

        this.dataNetbaseService.clearDealTypesCache();

        return this.http.delete<DealTypes>(url, {headers, params});
    }

    addSpeedType(speedType: SpeedInput): Observable<SpeedTypes> {
        this.dataNetbaseService.clearSpeedTypesCache();

        return this.http.post<SpeedTypes>(this.speedTypesUrl, speedType, httpOptions);
    }

    updateSpeedType(speedType: SpeedInput): Observable<SpeedTypes> | undefined {
        if (!speedType.id) {
            console.error('ID is missing...');

            return;
        }

        const url = `${this.speedTypesUrl}/${speedType.id}`;

        this.dataNetbaseService.clearSpeedTypesCache();

        return this.http.put<SpeedTypes>(url, speedType, httpOptions);
    }

    deleteSpeedType(type: SpeedTypes): Observable<SpeedTypes | undefined> | undefined {
        if (!type.deleted_by) {
            console.error('deleted_by is missing...');

            return;
        }

        const url = `${this.speedTypesUrl}/${type.id}`;
        const params = new HttpParams().set('deleted_by', type.deleted_by.toString());
        const headers = new HttpHeaders({'Content-Type': 'application/json'});

        this.dataNetbaseService.clearSpeedTypesCache();

        return this.http.delete<SpeedTypes>(url, {headers, params});
    }

    addTechnologyType(technologyType: TechnologyInput): Observable<TechnologyTypes> {
        this.dataNetbaseService.clearTechnologyTypesCache();

        return this.http.post<TechnologyTypes>(
            this.technologyTypesUrl,
            technologyType,
            httpOptions,
        );
    }

    updateTechnologyType(technologyType: TechnologyInput): Observable<TechnologyTypes> | undefined {
        if (!technologyType.id) {
            console.error('ID is missing...');

            return;
        }

        const url = `${this.technologyTypesUrl}/${technologyType.id}`;

        this.dataNetbaseService.clearTechnologyTypesCache();

        return this.http.put<TechnologyTypes>(url, technologyType, httpOptions);
    }

    deleteTechnologyType(
        type: TechnologyTypes,
    ): Observable<TechnologyTypes | undefined> | undefined {
        if (!type.deleted_by) {
            console.error('deleted_by is missing...');

            return;
        }

        const url = `${this.technologyTypesUrl}/${type.id}`;
        const params = new HttpParams().set('deleted_by', type.deleted_by.toString());
        const headers = new HttpHeaders({'Content-Type': 'application/json'});

        this.dataNetbaseService.clearTechnologyTypesCache();

        return this.http.delete<TechnologyTypes>(url, {headers, params});
    }

    addSpaceType(spaceType: SpaceInput): Observable<SpaceTypes> {
        this.dataNetbaseService.clearSpaceTypesCache();

        return this.http.post<SpaceTypes>(this.spaceTypesUrl, spaceType, httpOptions);
    }

    updateSpaceType(spaceType: SpaceInput): Observable<SpaceTypes> | undefined {
        if (!spaceType.id) {
            console.error('ID is missing...');

            return;
        }

        const url = `${this.spaceTypesUrl}/${spaceType.id}`;

        this.dataNetbaseService.clearSpaceTypesCache();

        return this.http.put<SpaceTypes>(url, spaceType, httpOptions);
    }

    deleteSpaceType(type: SpaceTypes): Observable<SpaceTypes | undefined> | undefined {
        if (!type.deleted_by) {
            console.error('deleted_by is missing...');

            return;
        }

        const url = `${this.spaceTypesUrl}/${type.id}`;
        const params = new HttpParams().set('deleted_by', type.deleted_by.toString());
        const headers = new HttpHeaders({'Content-Type': 'application/json'});

        this.dataNetbaseService.clearSpaceTypesCache();

        return this.http.delete<SpaceTypes>(url, {headers, params});
    }

    addUnitType(unitType: UnitInput): Observable<UnitTypes> {
        this.dataNetbaseService.clearUnitTypesCache();

        return this.http.post<UnitTypes>(this.unitTypesUrl, unitType, httpOptions);
    }

    updateUnitType(unitType: UnitInput): Observable<UnitTypes> | undefined {
        if (!unitType.id) {
            console.error('ID is missing...');

            return;
        }

        const url = `${this.unitTypesUrl}/${unitType.id}`;

        this.dataNetbaseService.clearUnitTypesCache();

        return this.http.put<UnitTypes>(url, unitType, httpOptions);
    }

    deleteUnitType(type: UnitTypes): Observable<UnitTypes | undefined> | undefined {
        if (!type.deleted_by) {
            console.error('deleted_by is missing...');

            return;
        }

        const url = `${this.unitTypesUrl}/${type.id}`;
        const params = new HttpParams().set('deleted_by', type.deleted_by.toString());
        const headers = new HttpHeaders({'Content-Type': 'application/json'});

        this.dataNetbaseService.clearUnitTypesCache();

        return this.http.delete<UnitTypes>(url, {headers, params});
    }

    addInstallType(installType: InstallInput): Observable<InstallTypes> {
        this.dataNetbaseService.clearInstallTypesCache();

        return this.http.post<InstallTypes>(this.installTypesUrl, installType, httpOptions);
    }

    updateInstallType(installType: InstallInput): Observable<InstallTypes> | undefined {
        if (!installType.id) {
            console.error('ID is missing...');

            return;
        }

        const url = `${this.installTypesUrl}/${installType.id}`;

        this.dataNetbaseService.clearInstallTypesCache();

        return this.http.put<InstallTypes>(url, installType, httpOptions);
    }

    deleteInstallType(type: InstallTypes): Observable<InstallTypes | undefined> | undefined {
        if (!type.deleted_by) {
            console.error('deleted_by is missing...');

            return;
        }

        const url = `${this.installTypesUrl}/${type.id}`;
        const params = new HttpParams().set('deleted_by', type.deleted_by.toString());
        const headers = new HttpHeaders({'Content-Type': 'application/json'});

        this.dataNetbaseService.clearInstallTypesCache();

        return this.http.delete<InstallTypes>(url, {headers, params});
    }

    addPlace(place: PlaceInput): Observable<Places> {
        this.dataNetbaseService.clearPlacesCache();

        return this.http.post<Places>(this.placesUrl, place, httpOptions);
    }

    updatePlace(place: PlaceInput): Observable<Places> | undefined {
        if (!place.id) {
            console.error('ID is missing...');

            return;
        }

        const url = `${this.placesUrl}/${place.id}`;

        this.dataNetbaseService.clearPlacesCache();

        return this.http.put<Places>(url, place, httpOptions);
    }

    deletePlace(place: Places): Observable<Places | undefined> | undefined {
        if (!place.deleted_by) {
            console.error('deleted_by is missing...');

            return;
        }

        const url = `${this.placesUrl}/${place.id}`;
        const params = new HttpParams().set('deleted_by', place.deleted_by.toString());
        const headers = new HttpHeaders({'Content-Type': 'application/json'});

        this.dataNetbaseService.clearPlacesCache();

        return this.http.delete<Places>(url, {headers, params});
    }

    deleteNetPackage(netPackage: NetPackages): Observable<NetPackages | undefined> | undefined {
        if (!netPackage.deleted_by) {
            console.error('deleted_by is missing...');

            return;
        }

        const url = `${this.netPackageUrl}/${netPackage.id}`;
        const params = new HttpParams().set('deleted_by', netPackage.deleted_by.toString());
        const headers = new HttpHeaders({'Content-Type': 'application/json'});

        this.dataNetbaseService.clearNetPackagesCache();

        return this.http.delete<NetPackages>(url, {headers, params});
    }

    deleteTvPackage(tvPackage: TvPackages): Observable<TvPackages | undefined> | undefined {
        if (!tvPackage.deleted_by) {
            console.error('deleted_by is missing...');

            return;
        }

        const url = `${this.tvPackageUrl}/${tvPackage.id}`;
        const params = new HttpParams().set('deleted_by', tvPackage.deleted_by.toString());
        const headers = new HttpHeaders({'Content-Type': 'application/json'});

        this.dataNetbaseService.clearTvServicesCache();
        this.dataNetbaseService.clearTvPackagesCache();

        return this.http.delete<TvPackages>(url, {headers, params});
    }

    deleteTvService(tvService: TvServices): Observable<TvServices | undefined> | undefined {
        if (!tvService.deleted_by) {
            console.error('deleted_by is missing...');

            return;
        }

        const url = `${this.tvServicesUrl}/${tvService.id}`;
        const params = new HttpParams().set('deleted_by', tvService.deleted_by.toString());
        const headers = new HttpHeaders({'Content-Type': 'application/json'});

        this.dataNetbaseService.clearTvServicesCache();
        this.dataNetbaseService.clearTvPackagesCache();

        return this.http.delete<TvServices>(url, {headers, params});
    }

    addAddressField(field: FieldInput): Observable<AddressConnectedField> {
        this.dataNetbaseService.clearFieldAddressCache();

        return this.http.post<AddressConnectedField>(this.servicesAddressUrl, field, httpOptions);
    }

    updateAddressField(field: FieldInput): Observable<AddressConnectedField> | undefined {
        if (!field.id) {
            console.error('ID is missing...');

            return;
        }

        const url = `${this.servicesAddressUrl}/${field.id}`;

        this.dataNetbaseService.clearFieldAddressCache();

        return this.http.put<AddressConnectedField>(url, field, httpOptions);
    }

    deleteAddressField(
        field: AddressConnectedField,
    ): Observable<AddressConnectedField | undefined> | undefined {
        if (!field.deleted_by) {
            console.error('deleted_by is missing...');

            return;
        }

        const url = `${this.servicesAddressUrl}/${field.id}`;
        const params = new HttpParams()
            .set('deleted_by', field.deleted_by.toString())
            .set('valid_ruian', false.toString());
        const headers = new HttpHeaders({'Content-Type': 'application/json'});

        this.dataNetbaseService.clearFieldAddressCache();

        return this.http.delete<AddressConnectedField>(url, {headers, params});
    }

    addAddressRuian(ruian: RuianInput): Observable<AddressConnectedRuian> {
        this.dataNetbaseService.clearRuianAddressCache();

        return this.http.post<AddressConnectedRuian>(this.servicesAddressUrl, ruian, httpOptions);
    }

    updateAddressRuian(ruian: RuianInput): Observable<AddressConnectedRuian> | undefined {
        if (!ruian.id) {
            console.error('ID is missing...');

            return;
        }

        const url = `${this.servicesAddressUrl}/${ruian.id}`;

        this.dataNetbaseService.clearRuianAddressCache();

        return this.http.put<AddressConnectedRuian>(url, ruian, httpOptions);
    }

    deleteAddressRuian(
        ruian: AddressConnectedRuian,
    ): Observable<AddressConnectedRuian | undefined> | undefined {
        if (!ruian.deleted_by) {
            console.error('deleted_by is missing...');

            return;
        }

        const url = `${this.servicesAddressUrl}/${ruian.id}`;
        const params = new HttpParams()
            .set('deleted_by', ruian.deleted_by.toString())
            .set('valid_ruian', true.toString());
        const headers = new HttpHeaders({'Content-Type': 'application/json'});

        this.dataNetbaseService.clearRuianAddressCache();

        return this.http.delete<AddressConnectedRuian>(url, {headers, params});
    }

    addAddressExcluded(address: ExcludeAddressInput): Observable<AddressExcluded> {
        this.dataNetbaseService.clearExcludedAddressCache();

        return this.http.post<AddressExcluded>(this.excludedAddressUrl, address, httpOptions);
    }

    updateAddressExcluded(address: ExcludeAddressInput): Observable<AddressExcluded> | undefined {
        if (!address.id) {
            console.error('ID is missing...');

            return;
        }

        const url = `${this.excludedAddressUrl}/${address.id}`;

        this.dataNetbaseService.clearExcludedAddressCache();

        return this.http.put<AddressExcluded>(url, address, httpOptions);
    }

    deleteAddressExcluded(
        address: AddressExcluded,
    ): Observable<AddressExcluded | undefined> | undefined {
        if (!address.deleted_by) {
            console.error('deleted_by is missing...');

            return;
        }

        const url = `${this.excludedAddressUrl}/${address.id}`;
        const params = new HttpParams().set('deleted_by', address.deleted_by.toString());
        const headers = new HttpHeaders({'Content-Type': 'application/json'});

        this.dataNetbaseService.clearExcludedAddressCache();

        return this.http.delete<AddressExcluded>(url, {headers, params});
    }

    addAddressUntrusted(address: UntrustedAddressInput): Observable<AddressUntrusted> {
        this.dataNetbaseService.clearUntrustedAddressCache();

        return this.http.post<AddressUntrusted>(this.untrustedAddressUrl, address, httpOptions);
    }

    updateAddressUntrusted(
        address: UntrustedAddressInput,
    ): Observable<AddressUntrusted | undefined> | undefined {
        if (!address.id) {
            console.error('ID is missing...');

            return;
        }

        const url = `${this.untrustedAddressUrl}/${address.id}`;

        this.dataNetbaseService.clearUntrustedAddressCache();

        return this.http.put<AddressUntrusted>(url, address, httpOptions);
    }

    deleteAddressUntrusted(
        address: AddressUntrusted,
    ): Observable<AddressUntrusted | undefined> | undefined {
        if (!address.deleted_by) {
            console.error('deleted_by is missing...');

            return;
        }

        const url = `${this.untrustedAddressUrl}/${address.id}`;
        const params = new HttpParams().set('deleted_by', address.deleted_by.toString());
        const headers = new HttpHeaders({'Content-Type': 'application/json'});

        this.dataNetbaseService.clearUntrustedAddressCache();

        return this.http.delete<AddressUntrusted>(url, {headers, params});
    }

    addProject(project: ProjectInput): Observable<Project> {
        return this.http.post<Project>(this.projectUrl, project, httpOptions);
    }

    updateProject(project: ProjectInput): Observable<Project> | undefined {
        if (!project.id) {
            console.error('ID is missing...');

            return;
        }

        const url = `${this.projectUrl}/${project.id}`;

        return this.http.put<Project>(url, project, httpOptions);
    }

    deleteProject(project: Project): Observable<Project | undefined> | undefined {
        if (!project.deleted_by) {
            console.error('deleted_by is missing...');

            return;
        }

        const url = `${this.projectUrl}/${project.id}`;
        const params = new HttpParams().set('deleted_by', project.deleted_by.toString());
        const headers = new HttpHeaders({'Content-Type': 'application/json'});

        return this.http.delete<Project>(url, {headers, params});
    }

    downloadServiceFile(
        file: AddressConnectedFieldPhotos | AddressConnectedRuianPhotos,
    ): Observable<AddressConnectedFieldPhotos | AddressConnectedRuianPhotos | Blob | undefined> {
        return this.http
            .post(
                `${this.downloadFileUrl}`,
                {file: file.name, module: 'netbase_services', id: file.service_id},
                {
                    responseType: 'blob',
                    headers: new HttpHeaders().append('Content-Type', 'application/json'),
                },
            )
            .pipe(
                tap(() => {
                    this.log(`start download services_ruian file name=${file.name}`);
                }),
                catchError(
                    this.handleError<AddressConnectedFieldPhotos | AddressConnectedRuianPhotos>(
                        `file name=${file.name}`,
                    ),
                ),
            );
    }

    downloadHardwarePhoto(hardware: Hardware): Observable<Blob | Hardware | undefined> {
        return this.http
            .post(
                `${this.downloadFileUrl}`,
                {file: hardware.photo_url, module: 'hardware', id: hardware.id},
                {
                    responseType: 'blob',
                    headers: new HttpHeaders().append('Content-Type', 'application/json'),
                },
            )
            .pipe(
                tap(() => {
                    this.log(`start download hardware name=${hardware.name}`);
                }),
                catchError(this.handleError<Hardware>(`HW name=${hardware.name}`)),
            );
    }

    downloadProjectFile(file: ProjectFile): Observable<Blob | ProjectFile | undefined> {
        return this.http
            .post(
                `${this.downloadFileUrl}`,
                {file: file.name, module: 'projects', id: file.project_id},
                {
                    responseType: 'blob',
                    headers: new HttpHeaders().append('Content-Type', 'application/json'),
                },
            )
            .pipe(
                tap(() => {
                    this.log(`start download proejct file name=${file.name}`);
                }),
                catchError(this.handleError<ProjectFile>(`file name=${file.name}`)),
            );
    }

    downloadTvLogo(file: TvChannels): Observable<Blob | TvChannels | undefined> {
        return this.http
            .post(
                `${this.downloadFileUrl}`,
                {file: file.logo, module: 'channel', id: file.id},
                {
                    responseType: 'blob',
                    headers: new HttpHeaders().append('Content-Type', 'application/json'),
                },
            )
            .pipe(
                tap(() => {
                    this.log(`start download tv logo file name=${file.logo}`);
                }),
                catchError(this.handleError<TvChannels>(`tv logo name=${file.logo}`)),
            );
    }

    deleteServiceFile(
        file: AddressConnectedFieldPhotos | AddressConnectedRuianPhotos,
        validRuian: boolean,
    ):
        | Observable<AddressConnectedFieldPhotos | AddressConnectedRuianPhotos | undefined>
        | undefined {
        if (!file.deleted_by) {
            console.error('deleted_by is missing...');

            return;
        }

        const headers = new HttpHeaders({'Content-Type': 'application/json'});
        const params = new HttpParams()
            .set('type', 'netbase_services')
            .set('deleted_by', file.deleted_by.toString())
            .set('valid_ruian', validRuian.toString());
        const url = `${this.fileUrl}/${file.id}`;

        return this.http.delete<AddressConnectedFieldPhotos | AddressConnectedRuianPhotos>(url, {
            headers,
            params,
        });
    }

    deleteProjectFile(file: ProjectFile): Observable<ProjectFile | undefined> | undefined {
        if (!file.deleted_by) {
            console.error('deleted_by is missing...');

            return;
        }

        const headers = new HttpHeaders({'Content-Type': 'application/json'});
        const params = new HttpParams()
            .set('type', 'projects')
            .set('deleted_by', file.deleted_by.toString());
        const url = `${this.fileUrl}/${file.id}`;

        return this.http.delete<ProjectFile>(url, {headers, params});
    }

    deleteTvLogo(file: TvChannels): Observable<TvChannels | undefined> | undefined {
        if (!file.deleted_by) {
            console.error('deleted_by is missing...');

            return;
        }

        const headers = new HttpHeaders({'Content-Type': 'application/json'});
        const params = new HttpParams()
            .set('type', 'channel')
            .set('deleted_by', file.deleted_by.toString());
        const url = `${this.fileUrl}/${file.id}`;

        return this.http.delete<TvChannels>(url, {headers, params});
    }

    setLead(data: Leads | null): void {
        this.lead.next(data);
    }

    createLead(lead: LeadInput | Leads): Observable<Leads> {
        this.dataNetbaseService.clearLeadsCache();

        return this.http.post<Leads>(this.leadsUrl, lead, httpOptions);
    }

    deleteLead(lead: Leads): Observable<Leads | undefined> | undefined {
        if (!lead.deleted_by) {
            console.error('deleted_by is missing...');

            return;
        }

        const headers = new HttpHeaders({'Content-Type': 'application/json'});
        const params = new HttpParams().set('deleted_by', lead.deleted_by.toString());
        const url = `${this.leadsUrl}/${lead.id}`;

        this.dataNetbaseService.clearLeadsCache();

        return this.http.delete<Leads>(url, {headers, params});
    }

    createOrder(order: Orders): Observable<Orders> {
        this.dataNetbaseService.clearOrdersCache();

        return this.http.post<Orders>(this.ordersUrl, order, httpOptions);
    }

    updateOrder(order: ServiceOrderInput): Observable<Orders> | undefined {
        if (!order.id) {
            console.error('ID is missing...');

            return;
        }

        const url = `${this.ordersUrl}/${order.id}`;

        this.dataNetbaseService.clearOrdersCache();

        return this.http.put<Orders>(url, order, httpOptions);
    }

    deleteOrder(order: Orders): Observable<Orders | undefined> | undefined {
        if (!order.deleted_by) {
            console.error('deleted_by is missing...');

            return;
        }

        const headers = new HttpHeaders({'Content-Type': 'application/json'});
        const params = new HttpParams().set('deleted_by', order.deleted_by.toString());
        const url = `${this.ordersUrl}/${order.id}`;

        this.dataNetbaseService.clearOrdersCache();

        return this.http.delete<Orders>(url, {headers, params});
    }
}
