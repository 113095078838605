import {Injectable} from '@angular/core';
import {ApisService} from '@src/app/_api/apis.service';
import {ApiPaths} from '@src/app/_models/api/paths';
import {BehaviorSubject} from 'rxjs';
import {ErpUsers} from '@src/app/_models/_api_erp/users';

@Injectable({
    providedIn: 'root',
})
export class ErpService {
    private readonly resultErpUsersSource = new BehaviorSubject<
        ErpUsers | unknown | null | undefined
    >(null);

    erpUsersSource = this.resultErpUsersSource.asObservable();

    constructor(private readonly apisService: ApisService) {}

    setErpUsers(path: ApiPaths | undefined): void {
        if (typeof path !== 'undefined') {
            this.apisService.getRemoteData(35, path.maps, false, '?detail=full')
                .subscribe({
                    next: (data: unknown[] | null | undefined) => {
                        this.resultErpUsersSource.next(data);
                    },
                    error: error => {
                        console.error(error);
                    },
                });
        }
    }
}
