import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    HostListener,
    Input,
    OnChanges,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {
    AbstractControl,
    FormArray,
    FormBuilder,
    FormGroup,
    ValidatorFn,
    Validators,
} from '@angular/forms';
import {Observable} from 'rxjs';
import {Hotline} from '@src/app/_models/hotline/hotline';
import {DialogComponent} from '@syncfusion/ej2-angular-popups';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {DataService} from '@src/app/_services/data.service';
import {PermissionService} from '@src/app/_services/permission.service';
import {AuthenticationService} from '@src/app/_services/authentication.service';
import {MessageService} from '@src/app/_services/message.service';
import {User} from '@src/app/_models/user/user';
import {DropDownListComponent, FilteringEventArgs} from '@syncfusion/ej2-angular-dropdowns';
import {Hotliner} from '@src/app/_models/hotline/hotliner';
import {Company} from '@src/app/_models/company/company';
import {Holiday} from '@src/app/_models/holiday/holiday';
import {EmitType} from '@syncfusion/ej2-base';
import {Query} from '@syncfusion/ej2-data';
import {registerLocaleData} from '@angular/common';
import moment, {MomentInput} from 'moment-business-days';
import localeCs from '@angular/common/locales/cs';
import {ComponentCanDeactivate} from '@src/app/_guards/changes.guard';
import {VacationService} from '@src/app/features/vacation/vacation.service';
import {HotlineInput, HotlineService} from '@src/app/features/hotline/hotline.service';

moment.updateLocale('cs', {workingWeekdays: [1, 2, 3, 4, 5]});
registerLocaleData(localeCs);

@UntilDestroy()
@Component({
    selector: 'app-hotline-form',
    templateUrl: './hotline-form.component.html',
    styleUrls: ['./hotline-form.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HotlineFormComponent implements OnInit, OnChanges, ComponentCanDeactivate {
    height = '240px';

    fields: object = {text: 'label', value: 'value'};

    hotlinerSelect: Array<{value: boolean | number | string; label: string}> = [];

    companySelect: Array<{value: boolean | number | string; label: string}> = [];

    typeSelect: Array<{value: boolean | number | string; label: string}> = [];

    // Forms
    hotlineForm: FormGroup;

    hotlinesList: FormArray;

    isDirty = false;

    submited = false;

    isChecked = false;

    // Variables
    today = new Date();

    currentUser: User | null;

    workTimeStart = '08:00';

    workTimeEnd = '22:00';

    holidays: Array<string> = [];

    hotlines: Hotline[];

    // Loaders
    hotlineLoading = false;

    @Input() hotline: Hotline | null = null;

    @Input() isCreate = false;

    @Input() isUpdate = false;

    @Input() isCopy = false;

    @Input() isVisible = false;

    @Output() readonly hotlineFormDialogState = new EventEmitter<boolean>();

    // Dialogs
    @ViewChild('formDialog') formDialogObj: DialogComponent;

    // Dropdowms
    @ViewChild('company') companyObj: DropDownListComponent;

    @ViewChild('hotliner') hotlinerObj: DropDownListComponent;

    @ViewChild('category') categoryObj: DropDownListComponent;

    constructor(
        private readonly router: Router,
        private readonly route: ActivatedRoute,
        private readonly ref: ChangeDetectorRef,
        private readonly formBuilder: FormBuilder,
        private readonly dataService: DataService,
        private readonly vacationService: VacationService,
        private readonly hotlineService: HotlineService,
        private readonly permissionService: PermissionService,
        private readonly authenticationService: AuthenticationService,
        private readonly messages: MessageService,
    ) {
        this.currentUser = this.authenticationService.currentUserValue;
    }

    get f(): {[key: string]: AbstractControl} {
        return this.hotlineForm.controls;
    }

    get hotlineformGroup(): FormArray {
        return this.hotlineForm.get('hotlines') as FormArray;
    }

    @HostListener('window:beforeunload')
    canDeactivate(): Observable<boolean> | boolean {
        return !this.isDirty;
    }

    lessThanDate(fieldDate: string): ValidatorFn {
        return (control: AbstractControl): {lessThan: {value: string}} | null => {
            const fieldToDateCompare = control.parent?.get(fieldDate);
            const endDate = control.value;
            const startDate = fieldToDateCompare?.value;
            const isLessThan =
                Number(moment(startDate as MomentInput)
                    .valueOf()) >
                Number(moment(endDate as MomentInput)
                    .valueOf());

            return isLessThan ? {lessThan: {value: control.value}} : null;
        };
    }

    greaterThanDate(fieldDate: string): ValidatorFn {
        return (control: AbstractControl): {greaterThan: {value: string}} | null => {
            const fieldToDateCompare = control.parent?.get(fieldDate);
            const startDate = control.value;
            const endDate = fieldToDateCompare?.value;
            const isGreaterThan =
                Number(moment(startDate as MomentInput)
                    .valueOf()) >
                Number(moment(endDate as MomentInput)
                    .valueOf());

            return isGreaterThan ? {greaterThan: {value: control.value}} : null;
        };
    }

    ngOnInit(): void {
        this.typeSelect = [
            {value: 'support', label: 'příslužba'},
            {value: 'technician', label: 'služba'},
        ];

        this.dataService.hotlineSource.pipe(untilDestroyed(this))
            .subscribe(
                (hotlines: Hotline[]) => {
                    this.hotlines = hotlines;
                },
                error => {
                    console.error(error);
                },
            );

        this.dataService.hotlinersSource.pipe(untilDestroyed(this))
            .subscribe(
                (data: Hotliner[]) => {
                    this.hotlinerSelect = [];
                    data.map((hotliner: Hotliner) => {
                        if (!hotliner.deleted_date) {
                            this.hotlinerSelect = [
                                ...this.hotlinerSelect,
                                {
                                    value: hotliner.id,
                                    label: hotliner.user.firstname + ' ' + hotliner.user.secondname,
                                },
                            ];
                        }
                    });
                    this.ref.markForCheck();
                },
                error => {
                    console.error(error);
                },
            );

        this.dataService.companySource.pipe(untilDestroyed(this))
            .subscribe(
                (companies: Company[]) => {
                    this.companySelect = [];
                    companies.map((company: Company) => {
                        if (!company.deleted_date) {
                            this.companySelect = [
                                ...this.companySelect,
                                {value: company.id, label: company.name},
                            ];
                        }
                    });
                },
                error => {
                    console.error(error);
                },
            );

        this.vacationService
            .getCurrCZEHolidays()
            .pipe(untilDestroyed(this))
            .subscribe(
                (holidays: Holiday[]) => {
                    this.holidays = [];
                    holidays.map(holiday => {
                        if (holiday.isPublicHoliday) {
                            this.holidays.push(moment(holiday.date)
                                .format('YYYY-MM-DD'));
                            moment.updateLocale('cs', {
                                holidays: this.holidays,
                                holidayFormat: 'YYYY-MM-DD',
                                workingWeekdays: [1, 2, 3, 4, 5],
                            });
                        }
                    });
                    this.ref.markForCheck();
                },
                error => {
                    console.error(error);
                },
            );

        this.hotlineForm = this.formBuilder.group({
            hotlines: this.formBuilder.array([this.createHotline()]),
        });

        this.hotlineForm.valueChanges.pipe(untilDestroyed(this))
            .subscribe(() => {
                this.isDirty = this.hotlineForm.dirty;
                this.showInputErrors();
            });
        this.hotlinesList = this.hotlineForm.get('hotlines') as FormArray;

        this.dataService.setCompanyDataSource();
        this.dataService.setHotlinersDataSource();
    }

    ngOnChanges(): void {
        if (this.isVisible) {
            const queryParams: Params = {form: null};

            void this.router.navigate([], {
                relativeTo: this.route,
                queryParams,
                queryParamsHandling: 'merge',
            });

            if (this.isUpdate && this.hotline) {
                this.formDialogObj.header = `Hot-line ${this.hotline.id} - ${this.hotline.hotliner.user.fullname}`;
                this.fillTheForm();
                this.showInputErrors();
            }

            if (this.isCreate) {
                this.hotlineForm.reset();
                this.formDialogObj.header = 'Nová hot-line';
            }

            if (this.isCopy) {
                this.hotlineForm.reset();
                this.formDialogObj.header = 'Nová hot-line';
                this.fillTheForm();
                this.showInputErrors();
            }
        }
    }

    changeDialogState(value: boolean): void {
        this.hotlineFormDialogState.emit(value);
    }

    createHotline(hotline?: HotlineInput): FormGroup {
        const formArrayField = this.formBuilder.group({
            hotliner_id: [
                hotline ? hotline.hotliner_id : null,
                Validators.compose([Validators.required]),
            ],
            type: [hotline ? hotline.type : null, Validators.compose([Validators.required])],
            company_id: [
                hotline ? hotline.company_id : null,
                Validators.compose([Validators.required]),
            ],
            start_at: [
                hotline
                    ? new Date(moment(hotline.start_at)
                        .toISOString())
                    : new Date(
                        moment(
                            moment()
                                .format('YYYY-MM-DD') + ' ' + this.workTimeStart,
                        )
                            .toISOString(),
                    ),
                Validators.compose([Validators.required]),
            ],
            end_at: [
                hotline
                    ? new Date(moment(hotline.end_at)
                        .toISOString())
                    : new Date(
                        moment(
                            moment()
                                .format('YYYY-MM-DD') + ' ' + this.workTimeEnd,
                        )
                            .toISOString(),
                    ),
                Validators.compose([Validators.required]),
            ],
        });

        formArrayField.get('end_at')
            ?.addValidators(this.lessThanDate('start_at'));
        formArrayField.get('start_at')
            ?.addValidators(this.greaterThanDate('end_at'));

        return formArrayField;
    }

    getHotlineFormGroup(index: number): FormGroup {
        return this.hotlinesList.controls[index] as FormGroup;
    }

    addHotlineInput(): void {
        this.hotlinesList.push(this.createHotline());
    }

    removeHotlineInput(index: number): void {
        this.hotlinesList.removeAt(index);
    }

    onFilteringHotliner: EmitType<FilteringEventArgs> = (e: FilteringEventArgs) => {
        if (e.text === '') {
            e.updateData(this.hotlinerSelect);
        } else {
            let query: Query = new Query();

            query = e.text !== '' ? query.where('label', 'contains', e.text, true, true) : query;
            e.updateData(this.hotlinerSelect, query);
        }
    };

    onFilteringCategories: EmitType<FilteringEventArgs> = (e: FilteringEventArgs) => {
        if (e.text === '') {
            e.updateData(this.typeSelect);
        } else {
            let query: Query = new Query();

            query = e.text !== '' ? query.where('label', 'contains', e.text, true, true) : query;
            e.updateData(this.typeSelect, query);
        }
    };

    onFilteringCompanies: EmitType<FilteringEventArgs> = (e: FilteringEventArgs) => {
        if (e.text === '') {
            e.updateData(this.companySelect);
        } else {
            let query: Query = new Query();

            query = e.text !== '' ? query.where('label', 'contains', e.text, true, true) : query;
            e.updateData(this.companySelect, query);
        }
    };

    fillTheForm(): void {
        if (!this.hotline) {
            console.error('this.hotline is missing...');

            return;
        }

        while (this.hotlinesList.length) {
            this.hotlinesList.removeAt(0);
        }

        const hotlines: HotlineInput[] = [];

        hotlines.push({
            hotliner_id: this.hotline.hotliner_id,
            type: this.hotline.type,
            company_id: this.hotline.company_id,
            start_at: new Date(moment(this.hotline.start_at)
                .format('YYYY-MM-DD HH:mm')),
            end_at: new Date(moment(this.hotline.end_at)
                .format('YYYY-MM-DD HH:mm')),
        });

        hotlines.forEach(hotline => {
            this.hotlinesList.push(this.createHotline(hotline));
        });
        this.hotlineForm.get('hotlines')
            ?.setValue(hotlines, {onlySelf: true, emitEvent: false});
    }

    onSubmit(): void {
        this.submited = true;
        this.isDirty = false;
        this.hotlineLoading = true;

        if (this.isCreate || this.isCopy) {
            this.addHotline();
        }

        if (this.isUpdate) {
            this.editHotline();
        }
    }

    addHotline(): void {
        if (this.currentUser && this.permissionService.checkUserISHotlineAdmin(this.currentUser)) {
            if (this.hotlineForm.invalid) {
                console.error('form is not valid!');

                return;
            }

            const hotlineResults: HotlineInput[] = [];
            const hotlinesValues = this.hotlinesList.value;

            hotlinesValues.forEach((hotline: HotlineInput) => {
                let points = 0;

                for (
                    const date = moment(hotline.start_at as MomentInput);
                    date <= moment(hotline.end_at as MomentInput);
                    date.add(1, 'days')
                ) {
                    if (
                        moment(date, 'YYYY-MM-DD')
                            .isBusinessDay() &&
                        !this.holidays.includes(moment(date)
                            .format('YYYY-MM-DD'))
                    ) {
                        points = 10;
                    } else if (
                        !moment(date, 'YYYY-MM-DD')
                            .isBusinessDay() &&
                        !this.holidays.includes(moment(date)
                            .format('YYYY-MM-DD'))
                    ) {
                        points = 100;
                    } else if (
                        moment(date, 'YYYY-MM-DD')
                            .isBusinessDay() &&
                        this.holidays.includes(moment(date)
                            .format('YYYY-MM-DD'))
                    ) {
                        points = 500;
                    } else if (
                        !moment(date, 'YYYY-MM-DD')
                            .isBusinessDay() &&
                        this.holidays.includes(moment(date)
                            .format('YYYY-MM-DD'))
                    ) {
                        points = 1000;
                    }

                    const existCheck = this.hotlines.find(
                        x =>
                            moment(x.start_at)
                                .format('YYYY-MM-DD') ===
                            moment(date)
                                .format('YYYY-MM-DD') &&
                            moment(x.end_at)
                                .format('YYYY-MM-DD') ===
                            moment(date)
                                .format('YYYY-MM-DD') &&
                            x.type === hotline.type &&
                            x.hotliner_id === hotline.hotliner_id &&
                            x.company_id === hotline.company_id,
                    );

                    if (!existCheck && this.currentUser) {
                        hotlineResults.push({
                            start_at: moment(date)
                                .format('YYYY-MM-DD') + ' 08:00:00',
                            end_at: moment(date)
                                .format('YYYY-MM-DD') + ' 22:00:00',
                            type: hotline.type,
                            score: points,
                            created_by: this.currentUser.id,
                            hotliner_id: hotline.hotliner_id,
                            company_id: hotline.company_id,
                        });
                    } else {
                        const options = {
                            progressBar: true,
                            timeOut: 5000,
                            toastClass: 'warning',
                        };

                        this.messages.showWarning('Hotline', 'tento záznam již existuje', options);
                    }
                }
            });

            if (hotlineResults.length > 0) {
                this.hotlineService
                    .addHotline(hotlineResults)
                    .pipe(untilDestroyed(this))
                    .subscribe(
                        (hotlines: Hotline[]) => {
                            const body = `Přidáno celkem: ${hotlines.length} služeb hot-line`;
                            const options = {
                                progressBar: true,
                                timeOut: 5000,
                                toastClass: 'success',
                            };

                            this.messages.showSuccess('Hot-line úspěšně vytvořena', body, options);
                            this.dataService.setHotlinesDataSource();
                            this.isCreate = false;
                            this.hotlineLoading = false;
                            this.formDialogObj.hide();
                            this.ref.markForCheck();
                        },
                        error => {
                            console.error(error);

                            const body = 'Zkuste to znovu později...';
                            const options = {
                                progressBar: true,
                                timeOut: 5000,
                                toastClass: 'red',
                            };

                            this.messages.showError('Chyba při vytváření hot-line', body, options);
                            this.hotlineLoading = false;
                            this.ref.markForCheck();
                        },
                    );
            } else {
                const body = 'Služby hot-line neprošly kontrolou...';
                const options = {progressBar: true, timeOut: 5000};

                this.messages.showError('Nebyla přidána žádná služba hot-line', body, options);
                this.hotlineLoading = false;
                this.isCreate = false;
                this.ref.markForCheck();
            }
        } else {
            const body = 'Nemáte oprávnění provést tuto akci...';
            const options = {progressBar: true, timeOut: 5000};

            this.messages.showError('Nedostatečné oprávnění', body, options);
            this.hotlineLoading = false;
            this.ref.markForCheck();
        }
    }

    editHotline(): void {
        if (this.currentUser && this.permissionService.checkUserISHotlineAdmin(this.currentUser)) {
            if (this.hotlineForm.invalid) {
                console.error('form is not valid!');

                return;
            }

            const hotlineResults: unknown[] = [];
            const hotlinesValues = this.hotlinesList.value;

            hotlinesValues.forEach((hotline: HotlineInput) => {
                let points = 0;

                for (
                    const date = moment(hotline.start_at as MomentInput);
                    date <= moment(hotline.end_at as MomentInput);
                    date.add(1, 'days')
                ) {
                    if (
                        moment(date, 'YYYY-MM-DD')
                            .isBusinessDay() &&
                        !this.holidays.includes(moment(date)
                            .format('YYYY-MM-DD'))
                    ) {
                        points = 10;
                    } else if (
                        !moment(date, 'YYYY-MM-DD')
                            .isBusinessDay() &&
                        !this.holidays.includes(moment(date)
                            .format('YYYY-MM-DD'))
                    ) {
                        points = 100;
                    } else if (
                        moment(date, 'YYYY-MM-DD')
                            .isBusinessDay() &&
                        this.holidays.includes(moment(date)
                            .format('YYYY-MM-DD'))
                    ) {
                        points = 500;
                    } else if (
                        !moment(date, 'YYYY-MM-DD')
                            .isBusinessDay() &&
                        this.holidays.includes(moment(date)
                            .format('YYYY-MM-DD'))
                    ) {
                        points = 1000;
                    }

                    const existCheck = this.hotlines.find(
                        x =>
                            moment(x.start_at)
                                .format('YYYY-MM-DD') ===
                            moment(date)
                                .format('YYYY-MM-DD') &&
                            moment(x.end_at)
                                .format('YYYY-MM-DD') ===
                            moment(date)
                                .format('YYYY-MM-DD') &&
                            x.type === hotline.type &&
                            x.hotliner_id === hotline.hotliner_id &&
                            x.company_id === hotline.company_id,
                    );

                    if (!existCheck && this.currentUser && this.hotline) {
                        hotlineResults.push({
                            id: this.hotline.id,
                            start_at: moment(date)
                                .format('YYYY-MM-DD') + ' 08:00:00',
                            end_at: moment(date)
                                .format('YYYY-MM-DD') + ' 22:00:00',
                            type: hotline.type,
                            score: points,
                            updated_by: this.currentUser.id,
                            hotliner_id: hotline.hotliner_id,
                            company_id: hotline.company_id,
                        });
                    } else {
                        const options = {
                            progressBar: true,
                            timeOut: 5000,
                            toastClass: 'warning',
                        };

                        this.messages.showWarning('Hotline', 'tento záznam již existuje', options);
                    }
                }
            });

            if (hotlineResults.length > 0) {
                this.hotlineService
                    .updateHotline(hotlineResults[0] as Hotline)
                    .pipe(untilDestroyed(this))
                    .subscribe(
                        result => {
                            const options = {
                                progressBar: true,
                                timeOut: 5000,
                                toastClass: 'success',
                            };

                            this.messages.showSuccess('Hotline', 'úspěšně upravena', options);
                            this.dataService.setHotlinesDataSource();
                            this.dataService.setHotlineDetailDataSource(result.id);
                            this.isUpdate = false;
                            this.hotlineLoading = false;
                            this.formDialogObj.hide();
                            this.ref.markForCheck();
                        },
                        error => {
                            console.error(error);

                            const options = {
                                progressBar: true,
                                timeOut: 5000,
                                toastClass: 'red',
                            };

                            this.messages.showError('Hotline', 'chyba během upravování', options);
                            this.hotlineLoading = false;
                            this.ref.markForCheck();
                        },
                    );
            } else {
                const body = 'Služby hot-line neprošly kontrolou...';
                const options = {progressBar: true, timeOut: 5000};

                this.messages.showError('Nebyla přidána žádná služba hot-line', body, options);
                this.isUpdate = false;
                this.hotlineLoading = false;
                this.ref.markForCheck();
            }
        } else {
            const body = 'Nemáte oprávnění provést tuto akci...';
            const options = {progressBar: true, timeOut: 5000};

            this.messages.showError('Nedostatečné oprávnění', body, options);
            this.isUpdate = false;
            this.hotlineLoading = false;
            this.ref.markForCheck();
        }
    }

    updateTreeValidity(group: FormArray | FormGroup): void {
        Object.keys(group.controls)
            .forEach((key: string) => {
                const abstractControl = group.controls[key];

                if (abstractControl instanceof FormGroup || abstractControl instanceof FormArray) {
                    this.updateTreeValidity(abstractControl);
                } else {
                    abstractControl.updateValueAndValidity({
                        onlySelf: true,
                        emitEvent: false,
                    });
                }
            });
    }

    showInputErrors(): void {
        this.isChecked = true;
        /*  console.log('VALID: ' + this.hotlineForm.valid);
        Object.keys(this.hotlineForm.controls).forEach(key => {
            const controlErrors: ValidationErrors = this.hotlineForm.get(key).errors;

            if (controlErrors != null) {
                Object.keys(controlErrors).forEach(keyError => {
                    console.log(
                        'Key control: ' + key + ', keyError: ' + keyError + ', err value: ',
                        controlErrors[keyError]
                    );
                });
            }
        });*/
        this.updateTreeValidity(this.hotlinesList);
        this.hotlineForm.markAllAsTouched();
        this.ref.markForCheck();
    }
}
