import {
    AfterViewChecked,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnInit,
    ViewChild,
} from '@angular/core';
import {registerLocaleData} from '@angular/common';
import {FormBuilder} from '@angular/forms';
import {Material} from '@src/app/_models/material/material';
import {MaterialBudget} from '@src/app/_models/material/material-budget';
import {User} from '@src/app/_models/user/user';
import {Department} from '@src/app/_models/department/department';
import {Observable, Observer} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthenticationService} from '@src/app/_services/authentication.service';
import {ExcelService} from '@src/app/_services/excel.service';
import {PermissionService} from '@src/app/_services/permission.service';
import {MessageService} from '@src/app/_services/message.service';
import {DataService} from '@src/app/_services/data.service';
import {saveAs} from 'file-saver';
import {OrderItem} from '@src/app/_models/material/material-item';
import {MaterialAssignation} from '@src/app/_models/material/material-assignation';
import {MaterialFile} from '@src/app/_models/material/material-file';
import {ButtonPropsModel, DialogComponent} from '@syncfusion/ej2-angular-popups';
import {
    ColumnModel,
    DataResult,
    EditSettingsModel,
    ExcelExportProperties,
    FilterSettingsModel,
    GridComponent,
    GroupSettingsModel,
    PageSettingsModel,
    RowDataBoundEventArgs,
    SelectionSettingsModel,
    ToolbarItems,
} from '@syncfusion/ej2-angular-grids';
import {ChangeEventArgs, DropDownListComponent} from '@syncfusion/ej2-angular-dropdowns';
import {ClickEventArgs} from '@syncfusion/ej2-angular-navigations';

import moment from 'moment';
import localeCs from '@angular/common/locales/cs';
import {TextBoxComponent} from '@syncfusion/ej2-angular-inputs';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {
    InvoicesGridItems,
    MaterialExportItems,
    MaterialService,
} from '@src/app/features/material/material.service';

moment.updateLocale('cs', {workingWeekdays: [1, 2, 3, 4, 5]});
registerLocaleData(localeCs);

@UntilDestroy()
@Component({
    selector: 'app-material-invoices',
    templateUrl: './material-invoices.component.html',
    styleUrls: ['./material-invoices.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MaterialInvoicesComponent implements OnInit, AfterViewChecked {
    // Dialogs
    renderMaterialFormDialog = false;

    buttonsReceiptDialog: ButtonPropsModel[] = [
        {
            click: this.confirmReceipt.bind(this),
            isFlat: false,
            buttonModel: {
                content: 'OK',
                cssClass: 'e-success e-outline',
            },
        },
        {
            click: (): void => {
                this.receiptDialog.hide();
            },
            buttonModel: {content: 'ZRUŠIT', cssClass: 'e-danger e-outline'},
        },
    ];

    buttonsDelete: ButtonPropsModel[] = [
        {
            click: (): void => {
                this.stornoApplication();
            },
            isFlat: false,
            buttonModel: {
                content: 'OK',
                cssClass: 'e-success e-outline',
            },
        },
        {
            click: (): void => {
                this.confirmationDelete.hide();
            },
            buttonModel: {content: 'ZRUŠIT', cssClass: 'e-danger e-outline'},
        },
    ];

    buttonsDecline: ButtonPropsModel[] = [
        {
            click: (): void => {
                this.declineApplication();
            },
            isFlat: false,
            buttonModel: {
                content: 'OK',
                cssClass: 'e-success e-outline',
            },
        },
        {
            click: (): void => {
                this.confirmationDecline.hide();
            },
            buttonModel: {content: 'ZRUŠIT', cssClass: 'e-danger e-outline'},
        },
    ];

    buttonsConfirm: ButtonPropsModel[] = [
        {
            click: (): void => {
                this.confirmApplication();
            },
            isFlat: false,
            buttonModel: {
                content: 'OK',
                cssClass: 'e-success e-outline',
            },
        },
        {
            click: (): void => {
                this.confirmationConfirm.hide();
            },
            buttonModel: {content: 'ZRUŠIT', cssClass: 'e-danger e-outline'},
        },
    ];

    buttonsAdminDecline: ButtonPropsModel[] = [
        {
            click: (): void => {
                this.declineAdminApplication();
            },
            isFlat: false,
            buttonModel: {
                content: 'OK',
                cssClass: 'e-success e-outline',
            },
        },
        {
            click: (): void => {
                this.adminconfirmationDecline.hide();
            },
            buttonModel: {content: 'ZRUŠIT', cssClass: 'e-danger e-outline'},
        },
    ];

    buttonsAdminConfirm: ButtonPropsModel[] = [
        {
            click: (): void => {
                this.confirmAdminApplication();
            },
            isFlat: false,
            buttonModel: {
                content: 'OK',
                cssClass: 'e-success e-outline',
            },
        },
        {
            click: (): void => {
                this.adminconfirmationConfirm.hide();
            },
            buttonModel: {content: 'ZRUŠIT', cssClass: 'e-danger e-outline'},
        },
    ];

    buttonsCancel: ButtonPropsModel[] = [
        {
            click: (): void => {
                this.confirmationCancel.hide();
            },
            buttonModel: {
                isPrimary: true,
                content: 'OK',
            },
        },
    ];

    // Grid
    currencyFormat: {[key: string]: string} = {format: '#,###.00\',- CZK\''};

    dateFormat: string;

    toolbar: ToolbarItems[] | object;

    data: object;

    invoicesExcelExport: MaterialExportItems[] = [];

    fields: object = {text: 'label', value: 'value'};

    filterSettings: FilterSettingsModel;

    filterCheckboxSettings: FilterSettingsModel;

    selectionSettings: SelectionSettingsModel;

    editSettings: EditSettingsModel;

    groupOptions: GroupSettingsModel;

    pageOptions: PageSettingsModel;

    columnsItems: ColumnModel[];

    filterDate: object;

    sortOptions: object;

    requiredRules: object;

    exportCurrentPage = false;

    // Dropdowns
    categoryFilterData: Array<string> = [];

    // Forms
    isCreate = false;

    isUpdate = false;

    isCopy = false;

    // Variables
    currentUser: User | null;

    today = moment()
        .format('YYYY-MM-DD HH:mm:ss');

    materials: Material[] = [];

    items: OrderItem[] = [];

    budgets: MaterialBudget[] | undefined = [];

    clickedRow: Material[];

    clickedRow$ = new Observable<Material[]>((observer: Observer<Material[]>) => {
        observer.next(this.clickedRow);
    });

    // Loaders
    tableDocLoad = true;

    // grafy
    @ViewChild('confirmationDelete') confirmationDelete: DialogComponent;

    @ViewChild('confirmationDecline') confirmationDecline: DialogComponent;

    @ViewChild('confirmationConfirm') confirmationConfirm: DialogComponent;

    @ViewChild('confirmationCancel') confirmationCancel: DialogComponent;

    @ViewChild('AdminconfirmationConfirm')
    adminconfirmationConfirm: DialogComponent;

    @ViewChild('AdminconfirmationDecline')
    adminconfirmationDecline: DialogComponent;

    @ViewChild('receiptDialog') receiptDialog: DialogComponent;

    // Grid
    @ViewChild('invoicesGrid') gridInvoices: GridComponent;

    @ViewChild('searchtext') searchtextObj: TextBoxComponent;

    @ViewChild('itemsTemplate', {static: true}) itemsTemplate: string;

    // Dropdowns
    @ViewChild('viewFilter') viewFilterInstance: DropDownListComponent;

    constructor(
        private readonly router: Router,
        private readonly route: ActivatedRoute,
        private readonly ref: ChangeDetectorRef,
        private readonly formBuilder: FormBuilder,
        private readonly authenticationService: AuthenticationService,
        private readonly excelService: ExcelService,
        private readonly permissionService: PermissionService,
        private readonly materialService: MaterialService,
        private readonly messages: MessageService,
        private readonly dataService: DataService,
    ) {
        this.currentUser = this.authenticationService.currentUserValue;
    }

    ngOnInit(): void {
        this.filterSettings = {
            type: 'Menu',
            showFilterBarStatus: true,
            ignoreAccent: true,
        };

        this.filterCheckboxSettings = {
            type: 'CheckBox',
            showFilterBarStatus: true,
            ignoreAccent: true,
        };

        this.filterSettings = {
            type: 'Excel',
            showFilterBarOperator: true,
            showFilterBarStatus: true,
            ignoreAccent: true,
        };

        this.selectionSettings = {
            persistSelection: true,
            type: 'Multiple',
            checkboxOnly: true,
        };

        this.toolbar = [
            {
                text: 'Občerstvit data',
                tooltipText: 'Občerstvit data',
                prefixIcon: 'e-refresh',
                id: 'customRefresh',
            },
            'Print',
            'ExcelExport',
            'Add',
            'Edit',
            {
                text: 'Smazat',
                tooltipText: 'Smazat objednávku',
                prefixIcon: 'e-remove',
                id: 'delete',
            },
            {
                text: 'Kopírovat',
                tooltipText: 'Kopírovat objednávku',
                prefixIcon: 'e-copy',
                id: 'copy',
            },
            {
                text: 'Detail',
                tooltipText: 'Detail objednávky',
                prefixIcon: 'e-openfolder',
                id: 'detail',
            },
            {
                text: 'Účtenka',
                tooltipText: 'Změnit stav účtenky',
                prefixIcon: 'e-receipt',
                id: 'receipt',
            },
        ];

        this.categoryFilterData = [
            'všechny objednávky',
            'fronta objednávek',
            'schválené objednávky',
            'zamítnuté objednávky',
            'stornované objednávky',
            'platba v hotovosti s dodanou účtenkou',
            'platba v hotovosti s nedodanou účtenkou',
        ];
        this.pageOptions = {pageSize: 50};
        this.dateFormat = 'dd.MM.yyyy HH:mm';
        this.filterDate = {type: 'Menu', params: {format: this.dateFormat}};
        this.groupOptions = {
            showGroupedColumn: true,
            disablePageWiseAggregates: false,
        };

        this.editSettings = {
            showConfirmDialog: false,
            showDeleteConfirmDialog: false,
            allowEditing: true,
            allowAdding: true,
            allowDeleting: true,
            allowEditOnDblClick: false,
            mode: 'Dialog',
        };
        this.requiredRules = {required: true};
        this.columnsItems = [
            {
                field: 'items',
                headerText: 'Název',
                textAlign: 'Left',
                type: 'string',
                minWidth: 300,
                maxWidth: 300,
                width: 300,
                clipMode: 'EllipsisWithTooltip',
                autoFit: true,
                allowFiltering: false,
                allowSorting: false,
                template: this.itemsTemplate,
            },
            {
                field: 'itemsAmount',
                headerText: 'Množství celkem',
                type: 'number',
                format: 'N',
                textAlign: 'Right',
                minWidth: 40,
                maxWidth: 150,
                clipMode: 'EllipsisWithTooltip',
                autoFit: true,
            },
            {
                field: 'itemsPrice',
                headerText: 'Cena celkem',
                type: 'number',
                format: '#,###.00\',- CZK bez DPH\'',
                textAlign: 'Right',
                minWidth: 40,
                maxWidth: 150,
                clipMode: 'EllipsisWithTooltip',
                autoFit: true,
            },
        ];

        this.dataService.budgetSource.pipe(untilDestroyed(this))
            .subscribe({
                next: (budgets: MaterialBudget[]) => {
                    this.budgets = budgets;
                },
                error: error => {
                    console.error(error);
                },
            });

        if (this.budgets && this.budgets.length > 0) {
            this.dataService.materialItemSource.pipe(untilDestroyed(this))
                .subscribe({
                    next: (items: OrderItem[]) => {
                        this.items = items;
                    },
                    error: error => {
                        console.error(error);
                    },
                });
        }

        this.dataService.setMaterialsItemsDataSource();
        this.dataService.setMaterialsDataSource();
    }

    ngAfterViewChecked(): void {
        this.route.queryParamMap.pipe(untilDestroyed(this))
            .subscribe(params => {
                if (params.get('form') === 'addMaterial') {
                    this.isCreate = true;
                    this.renderMaterialFormDialog = true;
                    this.ref.markForCheck();
                }
            });
    }

    loadData(): DataResult[] {
        this.gridInvoices.clearRowSelection();

        console.info('NEW DATA BOUND');

        const elements: InvoicesGridItems[] = [];

        this.materials.map((material: Material) => {
            let budgetsStr = '';
            let confirmersStr = '';
            let receiptsStr = '';
            let itemsStr = '';

            material.usedBudgets.map((budget, i, row) => {
                if (i + 1 === row.length) {
                    budgetsStr += budget.name;
                } else {
                    budgetsStr += budget.name + ', ';
                }
            });

            material.orderitems.map((item: OrderItem, i, row) => {
                if (i + 1 === row.length) {
                    itemsStr += item.name;
                } else {
                    itemsStr += item.name + ', ';
                }
            });

            material.receipts.map((receipt: MaterialFile, i, row) => {
                if (i + 1 === row.length) {
                    receiptsStr += receipt.name;
                } else {
                    receiptsStr += receipt.name + ', ';
                }
            });

            material.confirmers.map((confirmer: User, i, row) => {
                if (i + 1 === row.length) {
                    confirmersStr += confirmer.fullname;
                } else {
                    confirmersStr += confirmer.fullname + ', ';
                }
            });

            material.orderitems.map((item: OrderItem) => {
                if (item.confirmed && !item.deleted_date) {
                    material.status = 'schváleno';
                }

                if (item.declined && !item.deleted_date) {
                    material.status = 'zamítnuto';
                }

                if (item.deleted_date) {
                    material.status = 'stornováno';
                }

                if (!item.declined && !item.confirmed && !item.deleted_date) {
                    material.status = 've frontě';
                }
            });

            elements.push({
                id: material.id,
                created_by: material.creator.id,
                creator_str: material.creator.fullname,
                creator_img: material.creator.personalphoto,
                reason: material.reason,
                status: material.status,
                type: material.payment.name,
                receipts: material.receipts,
                budgets: material.usedBudgets,
                items: material.orderitems,
                itemsAmount: material.totalCountItem,
                itemsPrice: material.totalCountPrice,
                confirmers: material.confirmers,
                to_storage: material.to_storage ? 'na sklad' : 'na přeprodej',
                receipt: material.receipt ? 'účtenka dodána' : 'účtenka nedodána',
                created_date: new Date(material.created_date),
                updated_date: material.updated_date ? new Date(material.updated_date) : null,
                deleted_date: material.deleted_date ? new Date(material.deleted_date) : null,
            });

            this.invoicesExcelExport.push({
                id: material.id,
                created_by: material.creator.id,
                creator_str: material.creator.fullname,
                creator_img: material.creator.personalphoto,
                reason: material.reason,
                status: material.status,
                type: material.payment.name,
                receipts: receiptsStr,
                budgets: budgetsStr,
                items: itemsStr,
                itemsAmount: material.totalCountItem,
                itemsPrice: material.totalCountPrice,
                confirmers: confirmersStr,
                to_storage: material.to_storage ? 'na sklad' : 'na přeprodej',
                receipt: material.receipt ? 'účtenka dodána' : 'účtenka nedodána',
                created_date: new Date(material.created_date),
                updated_date: material.updated_date ? new Date(material.updated_date) : null,
                deleted_date: material.deleted_date ? new Date(material.deleted_date) : null,
            });
        });
        this.tableDocLoad = false;

        return elements as unknown as DataResult[];
    }

    onCreated(): void {
        this.disableToolBarBtn();
    }

    setInitialGridFiltering(): void {
        this.gridInvoices.clearFiltering();
        // this.gridInvoices.filterByColumn('created_by', 'equal', this.currentUser.id, 'and', false, true);
        // this.viewFilterInstance.value = 'moje žádanky - všechny';
        this.ref.markForCheck();
    }

    onLoad(): void {
        const wrapper1 = document.getElementById('scroll_wrapper1_invoices');
        const wrapper2 = document.getElementById('scroll_wrapper2_invoices');

        if (wrapper1 && wrapper2) {
            wrapper1.onscroll = (): void => {
                wrapper2.scrollLeft = wrapper1.scrollLeft;
            };

            wrapper2.onscroll = (): void => {
                wrapper1.scrollLeft = wrapper2.scrollLeft;
            };
        }

        this.dataService.materialSource.pipe(untilDestroyed(this))
            .subscribe({
                next: (materials: Material[]) => {
                    this.materials = materials;
                    this.data = this.gridInvoices.dataSource = this.loadData();
                    this.sortOptions = {
                        columns: [
                            {field: 'created_date', direction: 'Descending'},
                            {
                                field: 'id',
                                direction: 'Descending',
                            },
                        ],
                    };
                },
                error: error => {
                    console.error(error);
                    this.tableDocLoad = false;
                    this.ref.markForCheck();
                },
            });
    }

    search(): void {
        this.gridInvoices.search(this.searchtextObj.value);
    }

    onDataBound(): void {
        this.rowSelected();

        const width = document.getElementById('invoicesGrid_content_table')?.offsetWidth;
        const parent1 = document.getElementById('scroll_div_invoices');
        const parent2 = document.getElementById('grid_parent_invoices');

        if (parent1 && parent2 && width) {
            parent1.style.width = `${width}px`;
            parent2.style.width = `${width}px`;
        }

        this.gridInvoices.autoFitColumns();
        this.ref.markForCheck();
    }

    rowDataBound(args: RowDataBoundEventArgs): void {
        const data = args.data as Material;

        if (args.row && data.deleted_date) {
            args.row.getElementsByClassName('e-gridchkbox')[0].classList.add('disablecheckbox');
            args.row
                .getElementsByClassName('e-checkbox-wrapper')[0]
                .classList.add('disablecheckbox');
            args.row.classList.add('e-disabled');
        }

        if (
            this.currentUser &&
            args.row &&
            data.created_by !== this.currentUser.id &&
            !this.permissionService.checkUserISMaterialAdmin(this.currentUser)
        ) {
            args.row.getElementsByClassName('e-gridchkbox')[0].classList.add('disablecheckbox');
            args.row
                .getElementsByClassName('e-checkbox-wrapper')[0]
                .classList.add('disablecheckbox');
        }

        this.ref.markForCheck();
    }

    onStatusFilterChange({value}: ChangeEventArgs): void {
        if (value === 'všechny objednávky') {
            this.gridInvoices.clearFiltering();
        } else if (value === 'fronta objednávek') {
            this.gridInvoices.filterSettings = {
                columns: [
                    {
                        field: 'status',
                        operator: 'equal',
                        value: 've frontě',
                        predicate: 'and',
                        matchCase: false,
                        ignoreAccent: true,
                    },
                    {
                        field: 'deleted_date',
                        operator: 'equal',
                        value: null as unknown as string,
                        predicate: 'and',
                        matchCase: false,
                        ignoreAccent: true,
                    },
                ],
            };
        } else if (value === 'schválené objednávky') {
            this.gridInvoices.filterSettings = {
                columns: [
                    {
                        field: 'status',
                        operator: 'equal',
                        value: 'schváleno',
                        predicate: 'and',
                        matchCase: false,
                        ignoreAccent: true,
                    },
                    {
                        field: 'deleted_date',
                        operator: 'equal',
                        value: null as unknown as string,
                        predicate: 'and',
                        matchCase: false,
                        ignoreAccent: true,
                    },
                ],
            };
        } else if (value === 'zamítnuté objednávky') {
            this.gridInvoices.filterSettings = {
                columns: [
                    {
                        field: 'status',
                        operator: 'equal',
                        value: 'zamítnuto',
                        predicate: 'and',
                        matchCase: false,
                        ignoreAccent: true,
                    },
                    {
                        field: 'deleted_date',
                        operator: 'equal',
                        value: null as unknown as string,
                        predicate: 'and',
                        matchCase: false,
                        ignoreAccent: true,
                    },
                ],
            };
        } else if (value === 'stornované objednávky') {
            this.gridInvoices.filterSettings = {
                columns: [
                    {
                        field: 'status',
                        operator: 'equal',
                        value: 'stornováno',
                        predicate: 'and',
                        matchCase: false,
                        ignoreAccent: true,
                    },
                    {
                        field: 'deleted_date',
                        operator: 'notequal',
                        value: null as unknown as string,
                        predicate: 'and',
                        matchCase: false,
                        ignoreAccent: true,
                    },
                ],
            };
        } else if (value === 'platba v hotovosti s dodanou účtenkou') {
            this.gridInvoices.filterSettings = {
                columns: [
                    {
                        field: 'type',
                        operator: 'equal',
                        value: 'platba v hotovosti',
                        predicate: 'and',
                        matchCase: false,
                        ignoreAccent: true,
                    },
                    {
                        field: 'receipt',
                        operator: 'equal',
                        value: 'účtenka dodána',
                        predicate: 'and',
                        matchCase: false,
                        ignoreAccent: true,
                    },
                    {
                        field: 'deleted_date',
                        operator: 'equal',
                        value: null as unknown as string,
                        predicate: 'and',
                        matchCase: false,
                        ignoreAccent: true,
                    },
                ],
            };
        } else if (value === 'platba v hotovosti s nedodanou účtenkou') {
            this.gridInvoices.filterSettings = {
                columns: [
                    {
                        field: 'type',
                        operator: 'equal',
                        value: 'platba v hotovosti',
                        predicate: 'and',
                        matchCase: false,
                        ignoreAccent: true,
                    },
                    {
                        field: 'receipt',
                        operator: 'equal',
                        value: 'účtenka nedodána',
                        predicate: 'and',
                        matchCase: false,
                        ignoreAccent: true,
                    },
                    {
                        field: 'deleted_date',
                        operator: 'equal',
                        value: null as unknown as string,
                        predicate: 'and',
                        matchCase: false,
                        ignoreAccent: true,
                    },
                ],
            };
        }
    }

    cancelBtnClick(): void {
        this.searchtextObj.value = '';
        this.gridInvoices.searchSettings.key = '';
    }

    toolbarClick(args: ClickEventArgs): void {
        const xlsProp: ExcelExportProperties = {
            dataSource: this.invoicesExcelExport,
            fileName: 'js_invoices_export.xlsx',
            enableFilter: true,
            exportType: this.exportCurrentPage ? 'CurrentPage' : 'AllPages',
        };

        if (args.item.id === 'invoicesGrid_excelexport') {
            void this.gridInvoices.excelExport(xlsProp);
        } else if (args.item.id === 'delete') {
            if (this.gridInvoices.getSelectedRecords().length !== 0) {
                this.confirmationDelete.content = `Potvrďte smazání ${this.clickedRow.length} objednávek`;
                this.confirmationDelete.show();
            } else {
                this.confirmationCancel.content = 'Musíte vybrat alespoň jednu objednávku';
                this.confirmationCancel.show();
            }
        } else if (args.item.id === 'copy') {
            if (this.gridInvoices.getSelectedRecords().length !== 0) {
                this.isUpdate = false;
                this.isCreate = false;
                this.isCopy = true;
                this.renderMaterialFormDialog = true;
                this.ref.markForCheck();
            } else {
                this.confirmationCancel.content = 'Musíte vybrat alespoň jednu objednávku';
                this.confirmationCancel.show();
            }
        } else if (args.item.id === 'detail') {
            if (this.gridInvoices.getSelectedRecords().length !== 0) {
                void this.router.navigate(['/material/detail', this.clickedRow[0].id]);
            } else {
                this.confirmationCancel.content = 'Musíte vybrat alespoň jednu objednávku';
                this.confirmationCancel.show();
            }
        } else if (args.item.id === 'receipt') {
            if (this.gridInvoices.getSelectedRecords().length !== 0) {
                this.receiptDialog.show();
            } else {
                this.confirmationCancel.content = 'Musíte vybrat alespoň jednu objednávku';
                this.confirmationCancel.show();
            }
        } else if (args.item.id === 'customRefresh') {
            this.tableDocLoad = true;
            console.info('refresh DATA!');
            this.ref.markForCheck();
            this.dataService.clearMaterialsCache();
            this.dataService.setMaterialsDataSource();
        }
    }

    enableToolbarBtn(): void {
        this.gridInvoices.toolbarModule.enableItems(
            ['invoicesGrid_edit', 'delete', 'copy', 'detail', 'receipt'],
            true,
        );
    }

    disableToolBarBtn(): void {
        this.gridInvoices.toolbarModule.enableItems(
            ['invoicesGrid_edit', 'delete', 'copy', 'detail', 'receipt'],
            false,
        );
    }

    rowSelected(): void {
        this.clickedRow = [];

        const selectedrecords: object[] = this.gridInvoices.getSelectedRecords();

        if (selectedrecords.length > 0) {
            selectedrecords.map((row: Material) => {
                const tempData: Material | undefined = this.materials.find(x => x.id === row.id);

                if (tempData) {
                    this.clickedRow.push(tempData);
                }

                this.ref.markForCheck();
            });

            this.clickedRow$ = new Observable<Material[]>((observer: Observer<Material[]>) => {
                observer.next(this.clickedRow);
            });
            this.enableToolbarBtn();
        } else {
            this.disableToolBarBtn();
            this.clickedRow$ = new Observable<Material[]>((observer: Observer<Material[]>) => {
                observer.next(this.clickedRow);
            });
        }
    }

    actionBegin(args): void {
        if (args.requestType === 'add' || args.requestType === 'beginEdit') {
            args.cancel = true;

            if (args.requestType === 'beginEdit') {
                this.isUpdate = true;
                this.isCreate = false;
                this.isCopy = false;
            }

            if (args.requestType === 'add') {
                this.isCreate = true;
                this.isUpdate = false;
                this.isCopy = false;
            }

            this.renderMaterialFormDialog = true;
            this.ref.markForCheck();
        }
    }

    actionComplete(args): void {
        if (args.requestType === 'beginEdit' || args.requestType === 'add') {
            args.form.ej2_instances[0].rules = {};
        }
    }

    confirmApplication(): void {
        this.tableDocLoad = true;

        if (
            this.currentUser &&
            this.clickedRow[0]?.amiboss &&
            !this.clickedRow[0].deleted_date &&
            !this.clickedRow[0].submited &&
            !this.clickedRow[0].declined
        ) {
            this.materialService
                .confirmInvoice(this.clickedRow[0].id, this.currentUser.id, this.today)
                .pipe(untilDestroyed(this))
                .subscribe(
                    (assignation: MaterialAssignation) => {
                        const body = `objednávka: #${assignation.id}`;
                        const options = {
                            progressBar: true,
                            timeOut: 5000,
                            toastClass: 'success',
                        };

                        this.messages.showSuccess('Úspěšně schválená objednávka', body, options);
                        this.dataService.setMaterialsDataSource();
                        this.tableDocLoad = false;
                        this.confirmationConfirm.hide();
                        this.ref.markForCheck();
                    },
                    error => {
                        console.error(error);

                        const body = 'Zkuste to později ...';
                        const options = {
                            progressBar: true,
                            timeOut: 5000,
                            toastClass: 'red',
                        };

                        this.messages.showError(
                            'Chyba během schvalování objednávky',
                            body,
                            options,
                        );
                        this.tableDocLoad = false;
                        this.ref.markForCheck();
                    },
                );
        } else {
            const body = 'Nemáte oprávnění provést tuto akci...';
            const options = {progressBar: true, timeOut: 5000};

            this.messages.showError('Nedostatečné oprávnění', body, options);
            this.tableDocLoad = false;
            this.confirmationConfirm.hide();
            this.ref.markForCheck();
        }
    }

    confirmReceipt(): void {
        this.tableDocLoad = true;

        if (
            !this.clickedRow[0].deleted_date &&
            !this.clickedRow[0].receipt &&
            this.clickedRow[0].payment.name === 'platba v hotovosti' &&
            this.clickedRow[0].submited
        ) {
            if (
                this.currentUser?.rule &&
                (this.currentUser.rule.is_cashdesk_admin ||
                    this.currentUser.rule.is_admin ||
                    this.currentUser.rule.is_budget_admin)
            ) {
                this.clickedRow[0].receipt = true;
                this.clickedRow[0].updated_by = this.currentUser.id;
                this.materialService
                    .updateInvoice(this.clickedRow[0], true)
                    ?.pipe(untilDestroyed(this))
                    .subscribe({
                        next: (material: Material) => {
                            const body = `objednávka: #${material.id}`;
                            const options = {
                                progressBar: true,
                                timeOut: 5000,
                                toastClass: 'success',
                            };

                            this.messages.showSuccess(
                                'Objednávka materiálu úspěšně upravena',
                                body,
                                options,
                            );
                            this.dataService.setMaterialsDataSource();
                            this.tableDocLoad = false;
                            this.receiptDialog.hide();
                            this.ref.markForCheck();
                        },
                        error: error => {
                            console.error(error);

                            const body = 'Zkuste to později ...';
                            const options = {
                                progressBar: true,
                                timeOut: 5000,
                                toastClass: 'red',
                            };

                            this.messages.showError(
                                'Chyba při úpravě objednávky materiálu,',
                                body,
                                options,
                            );
                            this.tableDocLoad = false;
                            this.ref.markForCheck();
                        },
                    });
            } else {
                const body = 'Nemáte oprávnění provést tuto akci...';
                const options = {progressBar: true, timeOut: 5000};

                this.messages.showError('Nedostatečné oprávnění', body, options);
                this.tableDocLoad = false;
                this.receiptDialog.hide();
                this.ref.markForCheck();
            }
        } else {
            const body = 'Objednávka není ve správném stavu pro tuto akci...';
            const options = {progressBar: true, timeOut: 5000};

            this.messages.showWarning('Špatný stav objednávky', body, options);
            this.tableDocLoad = false;
            this.receiptDialog.hide();
            this.ref.markForCheck();
        }
    }

    confirmAdminApplication(): void {
        this.tableDocLoad = true;

        if (
            this.currentUser &&
            this.permissionService.checkUserISMaterialAdmin(this.currentUser) &&
            !this.clickedRow[0].deleted_date &&
            !this.clickedRow[0].submited &&
            !this.clickedRow[0].declined
        ) {
            this.materialService
                .confirmAdminInvoice(this.clickedRow[0].id, this.today)
                .pipe(untilDestroyed(this))
                .subscribe(
                    (assignation: MaterialAssignation) => {
                        const body = `objednávka: #${assignation.id}`;
                        const options = {
                            progressBar: true,
                            timeOut: 5000,
                            toastClass: 'success',
                        };

                        this.messages.showSuccess('Úspěšně schválená objednávka', body, options);
                        this.dataService.setMaterialsDataSource();
                        this.tableDocLoad = false;
                        this.adminconfirmationConfirm.hide();
                        this.ref.markForCheck();
                    },
                    error => {
                        console.error(error);

                        const body = 'Zkuste to později ...';
                        const options = {
                            progressBar: true,
                            timeOut: 5000,
                            toastClass: 'red',
                        };

                        this.messages.showError(
                            'Chyba během schvalování objednávky',
                            body,
                            options,
                        );
                        this.tableDocLoad = false;
                        this.ref.markForCheck();
                    },
                );
        } else {
            const body = 'Nemáte oprávnění provést tuto akci...';
            const options = {progressBar: true, timeOut: 5000};

            this.messages.showError('Nedostatečné oprávnění', body, options);
            this.tableDocLoad = false;
            this.adminconfirmationConfirm.hide();
            this.ref.markForCheck();
        }
    }

    stornoApplication(): void {
        this.tableDocLoad = true;

        if (
            this.currentUser &&
            ((this.clickedRow[0].created_by === this.currentUser.id &&
                    !this.clickedRow[0].deleted_date &&
                    !this.clickedRow[0].submited &&
                    !this.clickedRow[0].declined) ||
                (this.permissionService.checkUserISMaterialAdmin(this.currentUser) &&
                    !this.clickedRow[0].deleted_date))
        ) {
            this.clickedRow[0].deleted_by = this.currentUser.id;
            this.materialService
                .deleteInvoice(this.clickedRow[0])
                .pipe(untilDestroyed(this))
                .subscribe(
                    (material: Material) => {
                        const body = `objednávka: #${material.id}`;
                        const options = {
                            progressBar: true,
                            timeOut: 5000,
                            toastClass: 'success',
                        };

                        this.messages.showSuccess('Objednávka úspěšně smazána', body, options);
                        this.dataService.setMaterialsDataSource();
                        this.tableDocLoad = false;
                        this.confirmationDelete.hide();
                        this.ref.markForCheck();
                    },
                    error => {
                        console.error(error);

                        const body = 'Zkuste to později ...';
                        const options = {
                            progressBar: true,
                            timeOut: 5000,
                            toastClass: 'red',
                        };

                        this.messages.showError('Chyba během storna objednávky', body, options);
                        this.tableDocLoad = false;
                        this.ref.markForCheck();
                    },
                );
        } else {
            const body = 'Nemáte oprávnění provést tuto akci...';
            const options = {progressBar: true, timeOut: 5000};

            this.messages.showError('Nedostatečné oprávnění', body, options);
            this.tableDocLoad = false;
            this.confirmationDelete.hide();
            this.ref.markForCheck();
        }
    }

    declineApplication(): void {
        this.tableDocLoad = true;

        if (
            this.currentUser &&
            this.clickedRow[0].amiboss &&
            !this.clickedRow[0].deleted_date &&
            !this.clickedRow[0].submited &&
            !this.clickedRow[0].declined
        ) {
            this.materialService
                .declineInvoice(this.clickedRow[0].id, this.currentUser.id, this.today)
                .pipe(untilDestroyed(this))
                .subscribe(
                    (assignation: MaterialAssignation) => {
                        const body = `objednávka: #${assignation.id}`;
                        const options = {
                            progressBar: true,
                            timeOut: 5000,
                            toastClass: 'success',
                        };

                        this.messages.showSuccess('Úspěšně zamítnutá objednávka', body, options);
                        this.dataService.setMaterialsDataSource();
                        this.tableDocLoad = false;
                        this.confirmationDecline.hide();
                        this.ref.markForCheck();
                    },
                    error => {
                        console.error(error);

                        const body = 'Zkuste to později ...';
                        const options = {
                            progressBar: true,
                            timeOut: 5000,
                            toastClass: 'red',
                        };

                        this.messages.showError('Chyba během zamítnutí objednávky', body, options);
                        this.tableDocLoad = false;
                        this.ref.markForCheck();
                    },
                );
        } else {
            const body = 'Nemáte oprávnění provést tuto akci...';
            const options = {progressBar: true, timeOut: 5000};

            this.messages.showError('Nedostatečné oprávnění', body, options);
            this.tableDocLoad = false;
            this.confirmationDecline.hide();
            this.ref.markForCheck();
        }
    }

    declineAdminApplication(): void {
        this.tableDocLoad = true;

        if (
            this.currentUser &&
            this.permissionService.checkUserISMaterialAdmin(this.currentUser) &&
            !this.clickedRow[0].deleted_date &&
            !this.clickedRow[0].submited &&
            !this.clickedRow[0].declined
        ) {
            this.materialService
                .declineAdminInvoice(this.clickedRow[0].id, this.today)
                .pipe(untilDestroyed(this))
                .subscribe(
                    (assignation: MaterialAssignation) => {
                        const body = `objednávka: #${assignation.id}`;
                        const options = {
                            progressBar: true,
                            timeOut: 5000,
                            toastClass: 'success',
                        };

                        this.messages.showSuccess('Úspěšně zamítnutá objednávka', body, options);
                        this.dataService.setMaterialsDataSource();
                        this.tableDocLoad = false;
                        this.adminconfirmationDecline.hide();
                        this.ref.markForCheck();
                    },
                    error => {
                        console.error(error);

                        const body = 'Zkuste to později ...';
                        const options = {
                            progressBar: true,
                            timeOut: 5000,
                            toastClass: 'red',
                        };

                        this.messages.showError('Chyba během zamítnutí objednávky', body, options);
                        this.tableDocLoad = false;
                        this.ref.markForCheck();
                    },
                );
        } else {
            const body = 'Nemáte oprávnění provést tuto akci...';
            const options = {progressBar: true, timeOut: 5000};

            this.messages.showError('Nedostatečné oprávnění', body, options);
            this.tableDocLoad = false;
            this.adminconfirmationDecline.hide();
            this.ref.markForCheck();
        }
    }

    downloadFile(file: MaterialFile): void {
        this.materialService
            .downloadFile(file)
            .pipe(untilDestroyed(this))
            .subscribe(
                data => {
                    saveAs(data, file.name);
                },
                err => {
                    alert('Chyba během stahování souboru.');
                    console.error(err);
                },
            );
    }

    setBudget(department: Department | number): MaterialBudget | undefined {
        const departmentId = typeof department === 'number' ? department : department.id;
        const matchedBudget = this.budgets?.find(
            budget =>
                !budget.deleted_date &&
                budget.department_id === departmentId &&
                moment()
                    .format('YYYY-MM-DD HH:mm:ss') >= budget.start_at &&
                moment()
                    .format('YYYY-MM-DD HH:mm:ss') < budget.expire_at,
        );

        if (!matchedBudget) {
            const body = 'Rozpočet nedohledán';
            const options = {progressBar: true, timeOut: 5000, toastClass: 'red'};

            this.messages.showError('Chyba při hledání rozpočtu...', body, options);
        } else {
            return matchedBudget;
        }

        this.ref.markForCheck();
    }
}
