<ejs-dialog
  #formDialog
  (close)="
        isUpdate = false;
        isCreate = false;
        isDirty = false;
        isChecked = false;
        submited = false;
        changeDialogState(false)
    "
  [isModal]="true"
  [showCloseIcon]="true"
  [visible]="isVisible"
  [width]="'85%'"
  id="formDialog"
>
  <form [formGroup]="carForm">
    <ng-template #content>
      <div class="dialogContent">
        <div *ngIf="car" class="row pb-3">
          <div class="col-12">
            <h5
              *ngIf="car.status === 'confirmed'"
              class="e-badge e-badge-success"
            >
              schváleno
            </h5>
            <h5
              *ngIf="car.status === 'declined'"
              class="e-badge e-badge-danger"
            >
              zamítnuto
            </h5>
            <h5
              *ngIf="car.status === 'deleted'"
              class="e-badge e-badge-danger"
            >
              staženo
            </h5>
            <h5
              *ngIf="car.status === 'queue'"
              class="e-badge e-badge-warning"
            >
              ve frontě
            </h5>
          </div>
        </div>
        <div class="tab mb-3" id="tab0">
          <div class="row">
            <div class="form-group col-12">
              <label
                [ngClass]="
                                    f.owned_by.valid
                                        ? 'text-success'
                                        : f.owned_by.invalid
                                        ? 'text-danger'
                                        : 'text-muted'
                                "
                for="owned_by"
              >* Uživatel vozu:</label
              >
              <ejs-dropdownlist
                #owner
                (filtering)="onFilteringUsers($event)"
                [allowFiltering]="true"
                [dataSource]="usersSelect"
                [enabled]="
                                    (currentUser.rule &&
                                        (currentUser.rule.is_vacation_admin ||
                                            currentUser.rule.is_admin)) ||
                                    currentUser.is_boss
                                "
                [fields]="fields"
                [filterBarPlaceholder]="'Vyhledat uživatele...'"
                [ignoreAccent]="true"
                [placeholder]="'Vyberte uživatele vozu'"
                [popupHeight]="height"
                formControlName="owned_by"
                id="owned_by"
                sortOrder="Ascending"
              ></ejs-dropdownlist>
              <small
                *ngIf="
                                    f.owned_by.touched &&
                                    f.owned_by.errors &&
                                    f.owned_by.errors.required
                                "
                class="text-danger"
              >Uživatel vozu je povinný</small
              >
            </div>
          </div>
          <div class="row">
            <div class="form-group col-12">
              <label
                [ngClass]="
                                    f.company_id.valid
                                        ? 'text-success'
                                        : f.company_id.invalid
                                        ? 'text-danger'
                                        : 'text-muted'
                                "
                for="company_id"
              >* Vlastník vozu:</label
              >
              <ejs-dropdownlist
                #company
                (filtering)="onFilteringEngines($event)"
                [allowFiltering]="true"
                [dataSource]="companySelect"
                [fields]="fields"
                [filterBarPlaceholder]="
                                    'Vyhledat společnost...'
                                "
                [ignoreAccent]="true"
                [placeholder]="'Vyberte společnost'"
                [popupHeight]="height"
                formControlName="company_id"
                id="company_id"
                sortOrder="Ascending"
              ></ejs-dropdownlist>
              <small
                *ngIf="
                                    f.company_id.touched &&
                                    f.company_id.errors &&
                                    f.company_id.errors.required
                                "
                class="text-danger"
              >Vlastník je povinný</small
              >
            </div>
          </div>
          <div class="row">
            <div class="form-group col-12">
              <label
                [ngClass]="
                                    f.engine.valid
                                        ? 'text-success'
                                        : f.engine.invalid
                                        ? 'text-danger'
                                        : 'text-muted'
                                "
                for="engine"
              >* Typ pohonu:</label
              >
              <ejs-multiselect
                #engine
                (filtering)="onFilteringEngines($event)"
                [allowFiltering]="true"
                [dataSource]="engineSelect"
                [fields]="fields"
                [filterBarPlaceholder]="
                                    'Vyhledat typ pohonu...'
                                "
                [ignoreAccent]="true"
                [mode]="'CheckBox'"
                [placeholder]="'Vyberte typ pohonu'"
                [popupHeight]="height"
                [selectAllText]="'Všechny typy pohonu'"
                formControlName="engine"
                id="engine"
                showSelectAll="true"
                sortOrder="Ascending"
              ></ejs-multiselect>
              <small
                *ngIf="
                                    f.engine.touched &&
                                    f.engine.errors &&
                                    f.engine.errors.required
                                "
                class="text-danger"
              >Typ pohonu je povinný</small
              >
            </div>
          </div>
          <div class="row">
            <div class="form-group col-12">
              <label
                [ngClass]="
                                    f.name.valid
                                        ? 'text-success'
                                        : f.name.invalid
                                        ? 'text-danger'
                                        : 'text-muted'
                                "
                for="reason"
              >* Název:</label
              >
              <ejs-textbox
                [cssClass]="
                                    f.name.invalid
                                        ? 'e-error'
                                        : f.name.valid
                                        ? 'e-success'
                                        : ''
                                "
                floatLabelType="Auto"
                formControlName="name"
                id="name"
              ></ejs-textbox>
              <small
                *ngIf="
                                    f.name.touched &&
                                    f.name.errors &&
                                    f.name.errors.required
                                "
                class="text-danger"
              >Název vozu je povinný</small
              >
            </div>
          </div>
          <div class="row">
            <div class="form-group col-8">
              <label
                [ngClass]="
                                    f.recharge_time.valid
                                        ? 'text-success'
                                        : f.recharge_time.invalid
                                        ? 'text-danger'
                                        : 'text-muted'
                                "
                for="recharge_time"
              >* Doba dobíjení v (h):</label
              >
              <ejs-numerictextbox
                [format]="'N'"
                [ngClass]="
                                    f.recharge_time.invalid
                                        ? 'e-error'
                                        : f.recharge_time.valid
                                        ? 'e-success'
                                        : ''
                                "
                formControlName="recharge_time"
                id="recharge_time"
                min="1"
                step="1"
              ></ejs-numerictextbox>
              <small
                *ngIf="
                                    f.recharge_time.touched &&
                                    f.recharge_time.errors &&
                                    f.recharge_time.errors.required
                                "
                class="text-danger"
              >Doba dobíjení je povinná</small
              >
            </div>
            <div class="form-group col-4">
              <ejs-checkbox
                [ngClass]="
                                    f.bookable.valid
                                        ? 'text-success'
                                        : f.bookable.invalid
                                        ? 'text-danger'
                                        : 'text-muted'
                                "
                cssClass="e-primary p-0 m-0"
                formControlName="bookable"
                label="Možnost rezervace"
              ></ejs-checkbox>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
    <ng-template #footerTemplate>
      <div class="d-flex justify-content-end">
                <span
                  *ngIf="carForm.invalid"
                  class="e-badge e-badge-warning p-2 size_2"
                  role="alert"
                >
                    <strong>Formulář je špatně vyplněný</strong>
                </span>
        <span
          *ngIf="carForm.valid"
          class="e-badge e-badge-success p-2 size_2"
          role="alert"
        >
                    <strong>Formulář je správně vyplněný</strong>
                </span>
      </div>
      <div class="row">
        <div class="col-12 pt-2">
          <button
            (click)="onSubmit()"
            [disabled]="loadingCar || carForm.invalid || !isChecked"
            cssClass="e-btn e-success btn-p-zero e-small m-1"
            ejs-button
            type="button"
          >
                        <span
                          *ngIf="loadingCar === true"
                          aria-hidden="true"
                          class="spinner-grow spinner-grow-sm"
                          role="status"
                        ></span>
            <span *ngIf="loadingCar === false">ULOŽIT</span>
            <span *ngIf="loadingCar === true">Provádím...</span>
          </button>
          <button
            (click)="formDialogObj.hide()"
            cssClass="e-btn e-danger e-outline btn-p-zero e-small m-1"
            ejs-button
            type="button"
          >
            ZRUŠIT
          </button>
        </div>
      </div>
    </ng-template>
  </form>
</ejs-dialog>
