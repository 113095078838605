<div class="row d-flex justify-content-center modalWrapper">
  <ejs-dialog
    #deleteTaskDialog
    [buttons]="buttonsDeleteTaskDialog"
    [content]="'Opravdu chcete smazat označený úkol?'"
    [header]="'Smazat úkol'"
    [height]="'200px'"
    [isModal]="true"
    [showCloseIcon]="true"
    [visible]="false"
    [width]="'450px'"
    id="deleteTaskDialog"
  ></ejs-dialog>
  <ejs-dialog
    #deleteNoteDialog
    [buttons]="buttonsDeleteNoteDialog"
    [content]="
            'Opravdu chcete smazat označenou poznámku z postupů v úkolu?'
        "
    [header]="'Smazat poznámnku postupu z úkolu'"
    [height]="'200px'"
    [isModal]="true"
    [showCloseIcon]="true"
    [visible]="false"
    [width]="'450px'"
    id="deleteNoteDialog"
  ></ejs-dialog>
  <ejs-dialog
    #activeTaskDialog
    [buttons]="buttonsActiveTaskDialog"
    [content]="'Tímto vrátíte úkol k opětovnému vyřešení'"
    [header]="'Obnovit úkol'"
    [height]="'200px'"
    [isModal]="true"
    [showCloseIcon]="true"
    [visible]="false"
    [width]="'450px'"
    id="activeTaskDialog"
  ></ejs-dialog>
  <ejs-dialog
    #deleteCommentDialog [buttons]="buttonsDeleteCommentDialog"
    [content]="'Opravdu chcete smazat označený komentář z diskuze ticketu?'"
    [header]="'Smazat komentář z ticketu'" [height]="'200px'" [isModal]="true" [showCloseIcon]="true"
    [visible]="false" [width]="'450px'" id="deleteCommentDialog"
  ></ejs-dialog>
  <ejs-dialog
    #addSolvingDialog
    [header]="'Zadat řešení úkolu'"
    [height]="'500px'"
    [isModal]="true"
    [showCloseIcon]="true"
    [visible]="false"
    [width]="'85%'"
    id="addSolvingDialog"
  >
    <form (submit)="onSolve()" [formGroup]="createSolvingForm">
      <label
        [ngClass]="
                    solutionControl.solution.valid &&
                    (solutionControl.solution.dirty ||
                        solutionControl.solution.touched)
                        ? 'text-success active'
                        : solutionControl.solution.invalid &&
                          (solutionControl.solution.dirty ||
                              solutionControl.solution.touched)
                        ? 'text-danger active'
                        : 'text-muted active'
                "
        for="taskSolution"
      >* Řešení úkolu:</label
      >
      <ejs-richtexteditor
        #taskSolution
        (actionBegin)="handleFullScreen($event)"
        (actionComplete)="actionSolutionCompleteHandler($event)"
        (created)="onCreateRteSolution()"
        [enablePersistence]="false"
        [fontFamily]="fontFamily"
        [insertImageSettings]="insertImageSettings"
        [maxLength]="maxLength"
        [ngClass]="
                    solutionControl.solution.touched &&
                    solutionControl.solution.invalid
                        ? 'e-error'
                        : solutionControl.solution.touched &&
                          solutionControl.solution.valid
                        ? 'e-success'
                        : ''
                "
        [pasteCleanupSettings]="pasteCleanupSettings"
        [quickToolbarSettings]="quickToolbarSettings"
        [showCharCount]="true"
        [toolbarSettings]="tools"
        formControlName="solution"
        id="taskSolution"
        locale="cs"
        placeholder="Začněte psát řešení..."
      >
      </ejs-richtexteditor>
      <small
        *ngIf="
                    solutionControl.solution.touched &&
                    solutionControl.solution.errors &&
                    solutionControl.solution.errors.required
                "
        class="text-danger"
      >Řešení úkolu je povinné</small
      >
    </form>
    <ng-template #footerTemplate>
      <div class="d-flex justify-content-between">
        <div class="col-6">
          <div
            *ngIf="createSolvingForm.invalid"
            class="alert alert-warning"
            role="alert"
          >
            Formulář je špatně vyplněný
            <button
              (click)="showSolvingErrors()"
              cssClass="e-btn e-warning btn-p-zero e-smallfloat-right"
              ejs-button
              type="button"
            >
              označ chyby
            </button>
          </div>
          <div
            *ngIf="createSolvingForm.valid"
            class="alert alert-success"
            role="alert"
          >
            Formulář je správně vyplněný
          </div>
        </div>
        <div class="col-6">
          <button
            (click)="onSolve()"
            [disabled]="
                            (loadingTask$ | async) || createSolvingForm.invalid
                        "
            cssClass="e-btn e-success btn-p-zero e-small"
            ejs-button
            type="submit"
          >
                        <span
                          *ngIf="loadingTask$ | async"
                          aria-hidden="true"
                          class="spinner-grow spinner-grow-sm"
                          role="status"
                        ></span>
            <span
              *ngIf="(loadingTask$ | async) === false"
            >Vložit řešení</span
            >
            <span *ngIf="loadingTask$ | async">Provádím...</span>
          </button>
          <button
            (click)="addSolvingDialog.hide()"
            cssClass="e-btn e-danger e-outline btn-p-zero e-small"
            ejs-button
            type="button"
          >
            ZRUŠIT
          </button>
        </div>
      </div>
    </ng-template>
  </ejs-dialog>
  <app-task-form
    (taskFormDialogState)="
            renderTaskFormDialog = $event;
            isCreate = $event;
            isUpdate = $event;
            isCopy = $event;
            gridLogs?.clearRowSelection();
            disableToolBarBtn()
        "
    [isCopy]="isCopy"
    [isCreate]="false"
    [isUpdate]="isUpdate"
    [isVisible]="renderTaskFormDialog"
    [task]="task && (isUpdate || isCopy) ? task : null"
  ></app-task-form>
  <app-task-note-form
    (taskNoteFormDialogState)="
            renderTaskNoteFormDialog = $event;
            isCreateNote = $event;
            isUpdateNote = $event;
            isCopyNote = $event
        "
    [isCopy]="isCopyNote"
    [isCreate]="isCreateNote"
    [isUpdate]="isUpdateNote"
    [isVisible]="renderTaskNoteFormDialog"
    [note]="highlightedNote"
    [task]="task"
  ></app-task-note-form>
  <app-task-comment-form
    (taskCommentFormDialogState)="
            renderTaskCommentFormDialog = $event;
            isCreateComment = $event;
            isUpdateComment = $event;
            isCopyComment = $event
        " [comment]="highlightedComment" [isCopy]="isCopyComment" [isCreate]="isCreateComment"
    [isUpdate]="isUpdateComment" [isVisible]="renderTaskCommentFormDialog" [task]="task"
  ></app-task-comment-form>
</div>
<div class="row">
  <div class="mainTaksWrapper col-12 col-md-8 col-lg-9 p-2">
    <div class="e-card" tabindex="0">
      <ng-container *ngIf="task && (loadingTask$ | async) === false">
        <div class="e-card-header d-flex justify-content-between">
          <div class="e-card-header-caption">
            <div class="e-card-header-title">
              <strong
                [className]="
                                    task.status.name === 'solved'
                                        ? 'h4-responsive m-0 p-0 text-success'
                                        : task.status.name === 'deleted'
                                        ? 'h4-responsive m-0 p-0 text-danger'
                                        : 'h4-responsive m-0 p-0 text-warning'
                                "
                class="m-0 p-0"
              >Úkol: {{ task.subject }}</strong
              >
            </div>
          </div>
          <app-task-toolbar
            (activeTaskEvent)="reactiveTask()" (addSolvingEvent)="addSolvingDialog.show()"
            (copyTaskEvent)="copyTask()" (createCommentEvent)="createComment()"
            (deleteTaskEvent)="deleteTaskDialog.show()" (editTaskEvent)="editTask()"
            (reactiveSolutionEvent)="reactiveSolution()" [task]="task"
          ></app-task-toolbar>
        </div>
        <div class="e-card-separator mb-1 mt-1"></div>
        <div class="e-card-content">
          <div class="row">
            <div class="col-12 mt-2 mb-4">
              <h6>ZADÁNÍ:</h6>
              <div
                class="scrollbar scrollbar-indigo thin"
                style="max-height: 450px; overflow-y: auto"
              >
                <div class="force-overflow">
                  <div
                    [innerHTML]="task.maintaskHTML"
                    id="maintaskText"
                  ></div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12 mt-2">
              <button
                (click)="createComment()"
                *ngIf="(task?.created_by === currentUser?.id ||
                                    (currentUser?.rule &&
                                        (currentUser?.rule.is_task_admin ||
                                            currentUser?.rule.is_admin)) ||
                                    task?.creator.boss_id === currentUser?.id ||
                                    task?.solver.id === currentUser?.id) &&
                                !task?.finished_at &&
                                !task?.deleted_date"
                cssClass="e-btn e-primary btn-p-zero e-small"
                ejs-button
                title="přidat komentář do diskuze" type="button"
              >
                <i
                  aria-hidden="true"
                  class="fa-light fa-edit mr-lg-1"
                ></i>
                <span class="d-none d-lg-inline"> Přidat komentář </span>
              </button>
              <div class="e-card-separator mb-1 mt-1"></div>
              <app-task-comments
                (addTaskCommentEvent)="reloadData()"
                (deleteTaskCommentEvent)="highlightedComment = $event; deleteCommentDialog.show()"
                (editTaskCommentEvent)="highlightedComment = $event; editComment()"
                [task]="task"
              ></app-task-comments>
            </div>
          </div>
          <div *ngIf="task.finished_at" class="row">
            <div class="col-12 mt-3 mb-2">
              <div class="e-card green lighten-4">
                <div class="e-card-header bg-success">
                  <h6 class="text-white">ŘEŠENÍ ({{ task.finished_at ? 1:0 }}):</h6>
                </div>
                <app-task-notes
                  (deleteTaskNoteEvent)="highlightedNote = $event; deleteNoteDialog.show()"
                  (editSolutionNoteEvent)="reactiveSolution()"
                  (editTaskNoteEvent)="editTaskNote($event)"
                  (reactiveTaskEvent)="reactiveTask()"
                  [isSolving]="true"
                  [task]="task"
                ></app-task-notes>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="!task || (loadingTask$ | async)">
        <div class="e-card-content text-center">
          <app-loader></app-loader>
        </div>
      </ng-container>
    </div>
    <div class="e-card">
      <div class="e-card-header">
        <h5 class="e-card-title">
          <i class="fa-light fa-history mr-2"></i>Historie změn
        </h5>
      </div>
      <div class="e-card-content">
        <ejs-toolbar
          id="filterToolbar"
          overflowMode="Scrollable"
          scrollStep="500"
        >
          <e-items>
            <e-item
              [cssClass]="'statusFilter'"
              tooltipText="Přednastavené hledání"
              type="input"
            >
              <ng-template #template>
                <ejs-dropdownlist
                  #viewFilter
                  (change)="onStatusFilterChange($event)"
                  [dataSource]="categoryFilterData"
                  [width]="500"
                  placeholder="Vyberte přednastavený pohled..."
                ></ejs-dropdownlist>
              </ng-template>
            </e-item>
            <e-item type="Separator"></e-item>
            <e-item
              [cssClass]="'fulltextFilter'"
              tooltipText="Text pro vyhledávání"
              type="input"
            >
              <ng-template #template>
                <ejs-textbox
                  #searchtext
                  [width]="500"
                  class="mr-2"
                  placeholder="Vyhledat..."
                ></ejs-textbox>
                <button
                  (click)="search()"
                  class="e-btn e-info btn-p-zero e-small"
                >
                  <i class="fa-light fa-lg fa-search mr-1"></i
                  ><span
                  class="d-none d-lg-inline"
                >vyhledat</span
                >
                </button>
                <button
                  (click)="cancelBtnClick()"
                  class="e-btn e-danger btn-p-zero e-small"
                >
                  <i class="fa-light fa-lg fa-times"></i>
                </button>
              </ng-template>
            </e-item>
          </e-items>
        </ejs-toolbar>
        <div class="control-section">
          <div class="scroll_wrapper1" id="scroll_wrapper1_tasksLogs">
            <div class="scroll_div" id="scroll_div_tasksLogs"></div>
          </div>
          <div class="scroll_wrapper2" id="scroll_wrapper2_tasksLogs">
            <div class="grid_parent" id="grid_parent_tasksLogs">
              <ejs-grid
                #taskLogsGrid
                (actionBegin)="actionBegin($event)"
                (actionComplete)="actionComplete($event)"
                (created)="onCreated()"
                (dataBound)="onDataBound()"
                (load)="onLoad()"
                (loadeddata)="setInitialGridFiltering()"
                (rowDataBound)="rowDataBound($event)"
                (rowDeselected)="rowSelected()"
                (rowSelected)="rowSelected()"
                (toolbarClick)="toolbarClick($event)"
                [allowExcelExport]="true"
                [allowFiltering]="true"
                [allowMultiSorting]="true"
                [allowPdfExport]="false"
                [allowSelection]="true"
                [allowSorting]="true"
                [dataSource]="data"
                [enableHover]="false"
                [enablePersistence]="false"
                [enableVirtualization]="true"
                [filterSettings]="filterSettings"
                [groupSettings]="groupOptions"
                [pageSettings]="pageOptions"
                [selectionSettings]="selectionSettings"
                [sortSettings]="sortOptions"
                [toolbar]="toolbar"
                allowGrouping="true"
                allowReordering="true"
                allowResizing="true"
                allowTextWrap="true"
                height="100%"
                id="taskLogsGrid"
                locale="cs"
                rowHeight="38"
                showColumnMenu="true"
                width="100%"
              >
                <e-columns>
                  <e-column
                    [allowEditing]="false"
                    [allowFiltering]="false"
                    [allowReordering]="false"
                    [allowResizing]="false"
                    [allowSorting]="false"
                    [autoFit]="false"
                    type="checkbox"
                  ></e-column>
                  <e-column
                    [allowEditing]="false"
                    [allowFiltering]="true"
                    [allowReordering]="false"
                    [allowResizing]="false"
                    [autoFit]="true"
                    [isPrimaryKey]="true"
                    [visible]="false"
                    field="id"
                    headerText="Log ID"
                  >
                  </e-column>
                  <e-column
                    [allowEditing]="false"
                    [allowFiltering]="true"
                    [allowReordering]="false"
                    [allowResizing]="false"
                    [autoFit]="true"
                    [visible]="true"
                    field="task_id"
                    headerText="Task ID"
                  >
                    <ng-template #template let-data>
                      <a
                        [routerLink]="[
                                                    '/tasks/detail/',
                                                    data.task_id
                                                ]"
                      >
                        {{ data.task_id }}
                      </a>
                    </ng-template>
                  </e-column>
                  <e-column
                    [allowEditing]="false"
                    [allowFiltering]="true"
                    [allowReordering]="false"
                    [allowResizing]="false"
                    [autoFit]="true"
                    [visible]="true"
                    field="ticket_id"
                    headerText="Ticket ID"
                  >
                    <ng-template #template let-data>
                      <a
                        [routerLink]="[
                                                    '/tickets/detail/',
                                                    data.ticket_id
                                                ]"
                      >
                        {{ data.ticket_id }}
                      </a>
                    </ng-template>
                  </e-column>
                  <e-column
                    [allowEditing]="false"
                    [allowFiltering]="true"
                    [allowReordering]="false"
                    [allowResizing]="false"
                    [autoFit]="true"
                    [visible]="false"
                    field="user_id"
                    headerText="User ID"
                  >
                    <ng-template #template let-data>
                      <a
                        [routerLink]="[
                                                    '/users/detail/',
                                                    data.user_id
                                                ]"
                      >
                        {{ data.user_id }}
                      </a>
                    </ng-template>
                  </e-column>
                  <e-column
                    [autoFit]="true"
                    [filter]="filterSettings"
                    clipMode="EllipsisWithTooltip"
                    editType="dropdownedit"
                    field="solver_str"
                    headerText="Řešitel"
                  >
                    <ng-template #template let-data>
                      <div>
                        <img
                          alt="profilový obrázek"
                          class="img-fluid rounded-circle avatar-xs mr-1"
                          src="assets/img{{
                                                        data.solver_img
                                                    }}"
                        />
                        <a
                          [routerLink]="[
                                                        '/users/detail/',
                                                        data.user_id
                                                    ]"
                        >
                          {{ data.solver_str }}
                        </a>
                      </div>
                    </ng-template>
                  </e-column>
                  <e-column
                    [allowEditing]="false"
                    [allowFiltering]="true"
                    [allowReordering]="false"
                    [allowResizing]="false"
                    [autoFit]="true"
                    [visible]="false"
                    field="created_by"
                    headerText="Creator ID"
                  >
                    <ng-template #template let-data>
                      <a
                        [routerLink]="[
                                                    '/users/detail/',
                                                    data.created_by
                                                ]"
                      >
                        {{ data.created_by }}
                      </a>
                    </ng-template>
                  </e-column>
                  <e-column
                    [allowEditing]="false"
                    [allowFiltering]="true"
                    [allowReordering]="false"
                    [allowResizing]="false"
                    [autoFit]="true"
                    [visible]="false"
                    field="updated_by"
                    headerText="Editor ID"
                  >
                    <ng-template #template let-data>
                      <a
                        [routerLink]="[
                                                    '/users/detail/',
                                                    data.updated_by
                                                ]"
                      >
                        {{ data.updated_by }}
                      </a>
                    </ng-template>
                  </e-column>
                  <e-column
                    [allowEditing]="false"
                    [allowFiltering]="true"
                    [allowReordering]="false"
                    [allowResizing]="false"
                    [autoFit]="true"
                    [visible]="false"
                    field="deleted_by"
                    headerText="Remover ID"
                  >
                    <ng-template #template let-data>
                      <a
                        [routerLink]="[
                                                    '/users/detail/',
                                                    data.deleted_by
                                                ]"
                      >
                        {{ data.deleted_by }}
                      </a>
                    </ng-template>
                  </e-column>
                  <e-column
                    [allowEditing]="false"
                    [allowFiltering]="true"
                    [allowReordering]="false"
                    [allowResizing]="false"
                    [autoFit]="true"
                    [visible]="false"
                    field="category_id"
                    headerText="Category ID"
                  ></e-column>
                  <e-column
                    [allowEditing]="false"
                    [allowFiltering]="true"
                    [allowReordering]="false"
                    [allowResizing]="false"
                    [autoFit]="true"
                    [visible]="false"
                    field="status_id"
                    headerText="Status ID"
                  ></e-column>
                  <e-column
                    [allowEditing]="false"
                    [allowFiltering]="true"
                    [allowReordering]="false"
                    [allowResizing]="false"
                    [autoFit]="true"
                    [visible]="false"
                    field="tasks_type"
                    headerText="Type ID"
                  ></e-column>
                  <e-column
                    [allowEditing]="false"
                    [autoFit]="true"
                    [filter]="filterSettings"
                    clipMode="EllipsisWithTooltip"
                    field="type"
                    headerText="Typ logu"
                    textAlign="center"
                  >
                    <ng-template #template let-data>
                                            <span
                                              *ngIf="data.tasks_type === 1"
                                              class="e-badge e-badge-success e-badge-pill"
                                            >{{ data.type }}</span
                                            >
                      <span
                        *ngIf="data.tasks_type === 2"
                        class="e-badge e-badge-warning e-badge-pill"
                      >{{ data.type }}</span
                      >
                      <span
                        *ngIf="data.tasks_type === 3"
                        class="e-badge e-badge-danger e-badge-pill"
                      >{{ data.type }}</span
                      >
                    </ng-template>
                  </e-column>
                  <e-column
                    [allowEditing]="false"
                    [autoFit]="true"
                    [filter]="filterSettings"
                    clipMode="EllipsisWithTooltip"
                    field="subject"
                    headerText="Předmět"
                  ></e-column>
                  <e-column
                    [allowEditing]="false"
                    [autoFit]="true"
                    [disableHtmlEncode]="false"
                    [filter]="filterSettings"
                    clipMode="EllipsisWithTooltip"
                    field="maintask"
                    headerText="Zadání"
                  >
                    <ng-template #template let-data>
                      <div
                        [innerHTML]="
                                                    data.maintask
                                                        | truncateText: 300
                                                "
                      ></div>
                    </ng-template>
                  </e-column>
                  <e-column
                    [autoFit]="true"
                    [filter]="filterSettings"
                    clipMode="EllipsisWithTooltip"
                    editType="dropdownedit"
                    field="creator_str"
                    headerText="Vytvořil"
                  >
                    <ng-template #template let-data>
                      <div>
                        <img
                          *ngIf="data.creator_img"
                          alt="profilový obrázek"
                          class="img-fluid rounded-circle avatar-xs mr-1"
                          src="assets/img{{
                                                        data.creator_img
                                                    }}"
                        />
                        <a
                          [routerLink]="[
                                                        '/users/detail/',
                                                        data.created_by
                                                    ]"
                        >
                          {{ data.creator_str }}
                        </a>
                      </div>
                    </ng-template>
                  </e-column>
                  <e-column
                    [allowEditing]="false"
                    [autoFit]="true"
                    [filter]="filterDate"
                    [format]="dateFormat"
                    clipMode="EllipsisWithTooltip"
                    editType="datepickeredit"
                    field="created_date"
                    headerText="Vytvořeno"
                    textAlign="Right"
                    type="date"
                  ></e-column>
                  <e-column
                    [autoFit]="true"
                    [filter]="filterSettings"
                    clipMode="EllipsisWithTooltip"
                    editType="dropdownedit"
                    field="editor_str"
                    headerText="Upravil"
                  >
                    <ng-template #template let-data>
                      <div *ngIf="data.updated_by">
                        <img
                          alt="profilový obrázek"
                          class="img-fluid rounded-circle avatar-xs mr-1"
                          src="assets/img{{
                                                        data.editor_img
                                                    }}"
                        />
                        <a
                          [routerLink]="[
                                                        '/users/detail/',
                                                        data.updated_by
                                                    ]"
                        >
                          {{ data.editor_str }}
                        </a>
                      </div>
                    </ng-template>
                  </e-column>
                  <e-column
                    [allowEditing]="false"
                    [autoFit]="true"
                    [filter]="filterDate"
                    [format]="dateFormat"
                    clipMode="EllipsisWithTooltip"
                    editType="datepickeredit"
                    field="updated_date"
                    headerText="Naposledy upraveno"
                    textAlign="Right"
                    type="date"
                  ></e-column>
                  <e-column
                    [autoFit]="true"
                    [filter]="filterSettings"
                    clipMode="EllipsisWithTooltip"
                    editType="dropdownedit"
                    field="remover_str"
                    headerText="Smazal"
                  >
                    <ng-template #template let-data>
                      <div *ngIf="data.deleted_by">
                        <img
                          alt="profilový obrázek"
                          class="img-fluid rounded-circle avatar-xs mr-1"
                          src="assets/img{{
                                                        data.remover_img
                                                    }}"
                        />
                        <a
                          [routerLink]="[
                                                        '/users/detail/',
                                                        data.deleted_by
                                                    ]"
                        >
                          {{ data.remover_str }}
                        </a>
                      </div>
                    </ng-template>
                  </e-column>
                  <e-column
                    [allowEditing]="false"
                    [autoFit]="true"
                    [filter]="filterDate"
                    [format]="dateFormat"
                    clipMode="EllipsisWithTooltip"
                    editType="datepickeredit"
                    field="deleted_date"
                    headerText="Smazáno"
                    textAlign="Right"
                    type="date"
                  ></e-column>
                  <e-column
                    [allowEditing]="false"
                    [autoFit]="true"
                    [filter]="filterDate"
                    [format]="dateFormat"
                    clipMode="EllipsisWithTooltip"
                    editType="datepickeredit"
                    field="timestamp"
                    headerText="Log Vytvořen"
                    textAlign="Right"
                    type="date"
                  ></e-column>
                </e-columns>
              </ejs-grid>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-12 col-md-4 col-lg-3 p-2">
    <app-task-sidebar [task]="task"></app-task-sidebar>
  </div>
</div>
