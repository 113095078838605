import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    OnChanges,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import {AuthenticationService} from '@src/app/_services/authentication.service';
import {ElasticResult, ElasticsearchService} from '@src/app/_services/elasticsearch.service';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Tickets} from '@src/app/_models/ticket/tickets';
import {Vacation} from '@src/app/_models/vacation/vacation';
import {Material} from '@src/app/_models/material/material';
import {User} from '@src/app/_models/user/user';
import {Task} from '@src/app/_models/task/task';
import {DataService} from '@src/app/_services/data.service';
import {MaterialBudget} from '@src/app/_models/material/material-budget';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {Knowledge} from '@src/app/_models/knowledge/knowledge';
import {KnowledgeComment} from '@src/app/_models/knowledge/knowledge-comment';
import {TicketComment} from '@src/app/_models/ticket/ticket-comment';
import {DialogComponent} from '@syncfusion/ej2-angular-popups';
import {Observable} from 'rxjs';
import {ComponentCanDeactivate} from '@src/app/_guards/changes.guard';

@UntilDestroy()
@Component({
    selector: 'app-elastic-results',
    templateUrl: './elastic-results.component.html',
    styleUrls: ['./elastic-results.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ElasticResultsComponent implements OnInit, OnChanges, ComponentCanDeactivate {
    // Forms
    searchForm: FormGroup;

    isDirty = false;

    isChecked = false;

    submited = false;

    // Variables
    currentUser: User | null;

    userResults: User[] = [];

    ticketResults: Tickets[] = [];

    taskResults: Task[] = [];

    vacationResults: Vacation[] = [];

    materialResults: Material[] = [];

    budgetResult: MaterialBudget[] = [];

    knowledgeResult: Knowledge[] = [];

    knowledgeCommentResult: KnowledgeComment[] = [];

    ticketCommentResult: TicketComment[] = [];

    tasks: Task[] = [];

    tickets: Tickets[] = [];

    vacations: Vacation[] = [];

    users: User[] = [];

    budgets: MaterialBudget[] = [];

    materials: Material[] = [];

    knowledges: Knowledge[] = [];

    ticketComments: TicketComment[] = [];

    knowledgeComments: KnowledgeComment[] = [];

    // Loaders
    esLoading = true;

    @Input() query = '';

    @Input() isVisible = false;

    @Output() readonly serachResultsDialogState = new EventEmitter<boolean>();

    // Dialogs
    @ViewChild('formDialog') formDialogObj: DialogComponent;

    @ViewChild('fulltextSearch', {static: false}) fullTextSearch: ElementRef;

    constructor(
        private readonly authenticationService: AuthenticationService,
        private readonly ref: ChangeDetectorRef,
        private readonly elasticService: ElasticsearchService,
        private readonly dataService: DataService,
        private readonly formBuilder: FormBuilder,
    ) {
        this.currentUser = this.authenticationService.currentUserValue;
        this.dataService.setTicketsDataSource();
        this.dataService.setTasksDataSource();
        this.dataService.setVacationsDataSource();
        this.dataService.setMaterialsDataSource();
    }

    get f(): {[key: string]: AbstractControl} {
        return this.searchForm.controls;
    }

    @HostListener('window:beforeunload')
    canDeactivate(): Observable<boolean> | boolean {
        return !this.isDirty;
    }

    ngOnInit(): void {
        this.searchForm = this.formBuilder.group({
            searchQuery: [
                this.query,
                Validators.compose([
                    Validators.required,
                    Validators.minLength(3),
                    Validators.maxLength(100),
                ]),
            ],
        });

        this.searchForm.valueChanges.pipe(untilDestroyed(this))
            .subscribe(() => {
                this.isDirty = this.searchForm.dirty;
                this.showInputErrors();
            });

        this.dataService.taskSource.pipe(untilDestroyed(this))
            .subscribe({
                next: (tasks: Task[]) => {
                    this.tasks = tasks;
                },
                error: error => {
                    console.error(error);
                },
            });

        this.dataService.ticketSource.pipe(untilDestroyed(this))
            .subscribe({
                next: (tickets: Tickets[]) => {
                    this.tickets = tickets;
                },
                error: error => {
                    console.error(error);
                },
            });

        this.dataService.ticketCommentSource.pipe(untilDestroyed(this))
            .subscribe({
                next: (comments: TicketComment[]) => {
                    this.ticketComments = comments;
                },
                error: error => {
                    console.error(error);
                },
            });

        this.dataService.knowledgeCommentSource.pipe(untilDestroyed(this))
            .subscribe({
                next: (comments: KnowledgeComment[]) => {
                    this.knowledgeComments = comments;
                },
                error: error => {
                    console.error(error);
                },
            });

        this.dataService.vacationSource.pipe(untilDestroyed(this))
            .subscribe({
                next: (vacations: Vacation[]) => {
                    this.vacations = vacations;
                },
                error: error => {
                    console.error(error);
                },
            });

        this.dataService.budgetSource.pipe(untilDestroyed(this))
            .subscribe({
                next: (budgets: MaterialBudget[]) => {
                    this.budgets = budgets;
                },
                error: error => {
                    console.error(error);
                },
            });

        this.dataService.materialSource.pipe(untilDestroyed(this))
            .subscribe({
                next: (materials: Material[]) => {
                    this.materials = materials;
                },
                error: error => {
                    console.error(error);
                },
            });

        this.dataService.userSource.pipe(untilDestroyed(this))
            .subscribe({
                next: (users: User[]) => {
                    this.users = users;
                },
                error: error => {
                    console.error(error);
                },
            });

        this.dataService.knowledgeSource.pipe(untilDestroyed(this))
            .subscribe({
                next: (knowledges: Knowledge[]) => {
                    this.knowledges = knowledges;
                },
                error: error => {
                    console.error(error);
                },
            });
    }

    changeDialogState(value: boolean): void {
        this.serachResultsDialogState.emit(value);
    }

    ngOnChanges(): void {
        if (this.query && this.query.length >= 3) {
            this.searchForm.controls.searchQuery.patchValue(this.query);
            this.startSearch();
        }
    }

    removeCurrentResults(): void {
        this.ticketResults = [];
        this.taskResults = [];
        this.vacationResults = [];
        this.materialResults = [];
        this.budgetResult = [];
        this.knowledgeResult = [];
        this.ticketCommentResult = [];
        this.knowledgeCommentResult = [];
    }

    // eslint-disable-next-line max-lines-per-function
    startSearch(): void {
        if (this.query && this.query.length >= 3) {
            this.isDirty = true;
            this.esLoading = true;
            this.submited = true;
            this.elasticService
                .searchUserDocuments(this.query)
                .pipe(untilDestroyed(this))
                .subscribe({
                    next: (response: ElasticResult | undefined) => {
                        this.userResults = [];

                        if (typeof response === 'undefined') {
                            console.error('response is type of undefined...');

                            return;
                        }

                        if (response.hits.total.value > 0) {
                            // eslint-disable-next-line @typescript-eslint/naming-convention
                            response.hits.hits.forEach((hit: {_source: User}) => {
                                const findResult = this.users.find(
                                    // eslint-disable-next-line no-underscore-dangle
                                    user => user.id === hit._source.id,
                                );

                                if (typeof findResult !== 'undefined') {
                                    this.userResults.push(findResult);
                                }
                            });
                        }

                        this.esLoading = false;
                        this.ref.markForCheck();
                    },
                    error: error => {
                        console.error(error);
                        this.esLoading = false;
                        this.ref.markForCheck();
                    },
                });

            this.elasticService
                .searchTicketDocuments(this.query)
                .pipe(untilDestroyed(this))
                .subscribe({
                    next: (response: ElasticResult | undefined) => {
                        this.ticketResults = [];

                        if (typeof response === 'undefined') {
                            console.error('response is type of undefined...');

                            return;
                        }

                        if (response.hits.total.value > 0) {
                            // eslint-disable-next-line @typescript-eslint/naming-convention
                            response.hits.hits.forEach((hit: {_source: Tickets}) => {
                                const loopResult = this.tickets.find(
                                    // eslint-disable-next-line no-underscore-dangle
                                    ticket => ticket.id === hit._source.id,
                                );

                                if (loopResult && typeof loopResult !== 'undefined') {
                                    this.ticketResults.push(loopResult);
                                }
                            });
                        }

                        this.esLoading = false;
                        this.ref.markForCheck();
                    },
                    error: error => {
                        console.error(error);
                        this.esLoading = false;
                        this.ref.markForCheck();
                    },
                });

            this.elasticService
                .searchTaskDocuments(this.query)
                .pipe(untilDestroyed(this))
                .subscribe({
                    next: (response: ElasticResult | undefined) => {
                        this.taskResults = [];

                        if (typeof response === 'undefined') {
                            console.error('response is type of undefined...');

                            return;
                        }

                        if (response.hits.total.value > 0) {
                            // eslint-disable-next-line @typescript-eslint/naming-convention
                            response.hits.hits.forEach((hit: {_source: Task}) => {
                                const loopResult = this.tasks.find(
                                    // eslint-disable-next-line no-underscore-dangle
                                    task => task.id === hit._source.id,
                                );

                                if (loopResult && typeof loopResult !== 'undefined') {
                                    this.taskResults.push(loopResult);
                                }
                            });
                        }

                        this.esLoading = false;
                        this.ref.markForCheck();
                    },
                    error: error => {
                        console.error(error);
                        this.esLoading = false;
                        this.ref.markForCheck();
                    },
                });

            this.elasticService
                .searchVacationDocuments(this.query)
                .pipe(untilDestroyed(this))
                .subscribe({
                    next: (response: ElasticResult | undefined) => {
                        this.vacationResults = [];

                        if (typeof response === 'undefined') {
                            console.error('response is type of undefined...');

                            return;
                        }

                        if (response.hits.total.value > 0) {
                            // eslint-disable-next-line @typescript-eslint/naming-convention
                            response.hits.hits.forEach((hit: {_source: Vacation}) => {
                                const loopResult = this.vacations.find(
                                    // eslint-disable-next-line no-underscore-dangle
                                    vacation => vacation.id === hit._source.id,
                                );

                                if (loopResult && typeof loopResult !== 'undefined') {
                                    this.vacationResults.push(loopResult);
                                }
                            });
                        }

                        this.esLoading = false;
                        this.ref.markForCheck();
                    },
                    error: error => {
                        console.error(error);
                        this.esLoading = false;
                        this.ref.markForCheck();
                    },
                });

            this.elasticService
                .searchMaterialDocuments(this.query)
                .pipe(untilDestroyed(this))
                .subscribe({
                    next: (response: ElasticResult | undefined) => {
                        this.materialResults = [];

                        if (typeof response === 'undefined') {
                            console.error('response is type of undefined...');

                            return;
                        }

                        if (response.hits.total.value > 0) {
                            // eslint-disable-next-line @typescript-eslint/naming-convention
                            response.hits.hits.forEach((hit: {_source: Material}) => {
                                const loopResult = this.materials.find(
                                    // eslint-disable-next-line no-underscore-dangle
                                    material => material.id === hit._source.id,
                                );

                                if (loopResult && typeof loopResult !== 'undefined') {
                                    this.materialResults.push(loopResult);
                                }
                            });
                        }

                        this.esLoading = false;
                        this.ref.markForCheck();
                    },
                    error: error => {
                        console.error(error);
                        this.esLoading = false;
                        this.ref.markForCheck();
                    },
                });

            this.elasticService
                .searchBudgetDocuments(this.query)
                .pipe(untilDestroyed(this))
                .subscribe({
                    next: (response: ElasticResult | undefined) => {
                        this.budgetResult = [];

                        if (typeof response === 'undefined') {
                            console.error('response is type of undefined...');

                            return;
                        }

                        if (response.hits.total.value > 0) {
                            // eslint-disable-next-line @typescript-eslint/naming-convention
                            response.hits.hits.forEach((hit: {_source: MaterialBudget}) => {
                                const loopResult = this.budgets.find(
                                    // eslint-disable-next-line no-underscore-dangle
                                    budget => budget.id === hit._source.id,
                                );

                                if (loopResult && typeof loopResult !== 'undefined') {
                                    this.budgetResult.push(loopResult);
                                }
                            });
                        }

                        this.esLoading = false;
                        this.ref.markForCheck();
                    },
                    error: error => {
                        console.error(error);
                        this.esLoading = false;
                        this.ref.markForCheck();
                    },
                });

            this.elasticService
                .searchKnowledgeDocuments(this.query)
                .pipe(untilDestroyed(this))
                .subscribe({
                    next: (response: ElasticResult | undefined) => {
                        this.knowledgeResult = [];

                        if (typeof response === 'undefined') {
                            console.error('response is type of undefined...');

                            return;
                        }

                        if (response.hits.total.value > 0) {
                            // eslint-disable-next-line @typescript-eslint/naming-convention
                            response.hits.hits.forEach((hit: {_source: Knowledge}) => {
                                const loopResult = this.knowledges.find(
                                    // eslint-disable-next-line no-underscore-dangle
                                    knowledge => knowledge.id === hit._source.id,
                                );

                                if (loopResult && typeof loopResult !== 'undefined') {
                                    this.knowledgeResult.push(loopResult);
                                }
                            });
                        }

                        this.esLoading = false;
                        this.ref.markForCheck();
                    },
                    error: error => {
                        console.error(error);
                        this.esLoading = false;
                        this.ref.markForCheck();
                    },
                });

            this.elasticService
                .searchTicketCommentsDocuments(this.query)
                .pipe(untilDestroyed(this))
                .subscribe({
                    next: (response: ElasticResult | undefined) => {
                        this.ticketCommentResult = [];

                        if (typeof response === 'undefined') {
                            console.error('response is type of undefined...');

                            return;
                        }

                        this.tickets.map((ticket: Tickets) => {
                            if (ticket.comments.length > 0) {
                                if (response.hits.total.value > 0) {
                                    response.hits.hits.forEach(
                                        // eslint-disable-next-line @typescript-eslint/naming-convention
                                        (hit: {_source: TicketComment}) => {
                                            const loopResult = ticket.comments.find(
                                                // eslint-disable-next-line no-underscore-dangle
                                                comment => comment.id === hit._source.id,
                                            );

                                            if (loopResult && typeof loopResult !== 'undefined') {
                                                this.ticketCommentResult.push(loopResult);
                                            }
                                        },
                                    );
                                }
                            }
                        });
                        this.esLoading = false;
                        this.ref.markForCheck();
                    },
                    error: error => {
                        console.error(error);
                        this.esLoading = false;
                        this.ref.markForCheck();
                    },
                });

            this.elasticService
                .searchKnowledgeCommentsDocuments(this.query)
                .pipe(untilDestroyed(this))
                .subscribe({
                    next: (response: ElasticResult | undefined) => {
                        this.knowledgeCommentResult = [];

                        if (typeof response === 'undefined') {
                            console.error('response is type of undefined...');

                            return;
                        }

                        if (response.hits.total.value > 0) {
                            // eslint-disable-next-line @typescript-eslint/naming-convention
                            response.hits.hits.forEach((hit: {_source: KnowledgeComment}) => {
                                const loopResult = this.knowledgeComments.find(
                                    // eslint-disable-next-line no-underscore-dangle
                                    comment => comment.id === hit._source.id,
                                );

                                if (loopResult && typeof loopResult !== 'undefined') {
                                    this.knowledgeCommentResult.push(loopResult);
                                }
                            });
                        }

                        this.esLoading = false;
                        this.ref.markForCheck();
                    },
                    error: error => {
                        console.error(error);
                        this.esLoading = false;
                        this.ref.markForCheck();
                    },
                });
        }
    }

    trackByUserID(index: number, user: User): number {
        return user.id;
    }

    showInputErrors(): void {
        this.isChecked = true;
        /* console.log('VALID: ' + this.ticketForm.valid);
        Object.keys(this.ticketForm.controls).forEach(key => {
            const controlErrors: ValidationErrors = this.ticketForm.get(key).errors;
            if (controlErrors != null) {
                Object.keys(controlErrors).forEach(keyError => {
                    console.log('Key control: ' + key + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError]);
                });
            }
        });*/
        this.searchForm.markAllAsTouched();
        this.ref.markForCheck();
    }
}
