import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import {AuthenticationService} from '@src/app/_services/authentication.service';
import {DataService} from '@src/app/_services/data.service';
import {Tickets} from '@src/app/_models/ticket/tickets';
import {strings as czechStrings} from 'ngx-timeago/language-strings/cs';
import {TimeagoIntl} from 'ngx-timeago';
import {registerLocaleData} from '@angular/common';
import {ActivatedRoute, Params, Router} from '@angular/router';

import moment from 'moment';
import localeCs from '@angular/common/locales/cs';
import {TabComponent, ToolbarComponent} from '@syncfusion/ej2-angular-navigations';
import {SelectEventArgs} from '@syncfusion/ej2-navigations';
import {User} from '@src/app/_models/user/user';
import {IProgressValueEventArgs, ProgressBarComponent} from '@syncfusion/ej2-angular-progressbar';
import {AnimationModel, FontModel} from '@syncfusion/ej2-progressbar';
import {ChartComponent} from '@syncfusion/ej2-angular-charts';
import {NavHandlerService} from '@src/app/_services/nav-handler.service';
import {DashboardLayoutComponent} from '@syncfusion/ej2-angular-layouts';
import {FieldSettingsModel} from '@syncfusion/ej2-lists';
import {Tags} from '@src/app/_models/tags/tags';
import {TicketCategory} from '@src/app/_models/ticket/ticket-category';
import {ListViewComponent} from '@syncfusion/ej2-angular-lists';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {DataManager, Query} from '@syncfusion/ej2-data';
import {Subject} from 'rxjs';
import {debounceTime} from 'rxjs/operators';

moment.updateLocale('cs', {workingWeekdays: [1, 2, 3, 4, 5]});
registerLocaleData(localeCs);

@UntilDestroy()
@Component({
    selector: 'app-ticket',
    templateUrl: './ticket.component.html',
    styleUrls: ['./ticket.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TicketComponent implements OnInit {
    // DashBoard
    count = 8;

    cellSpacing: number[] = [10, 10];

    cellAspectRatio: number = 100 / 100;

    // ProgressBars
    animation: AnimationModel;

    labelStyle: FontModel;

    showProgressValue: boolean;

    // Navigations
    headerText = [
        {text: 'tabulka'},
        {text: 'to-do'},
        {text: 'plánování'},
        {text: 'statistiky'},
        {text: 'import'},
    ];

    // Lists
    dataCategories: {
        [key: string]: Array<{[key: string]: number | string}> | number | string;
    }[] = [];

    dataTags: {[key: string]: {[key: string]: number | string}[] | number | string}[] = [];

    headerTitleCategories = 'Kategorie ticketů';

    headerTitleTags = 'Štítky ticketů';

    fields: FieldSettingsModel = {
        iconCss: 'icon',
        tooltip: 'text',
        sortBy: 'text',
    };

    // Variables
    live: true;

    currentUser: User | null;

    tickets: Tickets[] | undefined = [];

    stats: {
        solved: {indue: number; overdue: number};
        deleted: {indue: number; overdue: number};
        queue: {indue: number; overdue: number};
    };

    // Charts
    palette = ['#E94649', '#F6B53F', '#6FAAB0', '#C4C24A'];

    chartAnimation = {enable: true};

    primaryXAxis: {[key: string]: boolean | number | string | undefined};

    primaryYAxis: {[key: string]: boolean | number | string | undefined};

    chartDataInDue: object;

    chartDataOverDue: object;

    legendSettings: object;

    tooltip: object;

    datalabel: object;

    // Loaders
    loadingTicket = true;

    // Maintoolbar
    @ViewChild('moduleToolbar') moduleToolbarObj?: ToolbarComponent;

    // DashBoard
    @ViewChild('defaultDashboard', {static: false})
    dashboard?: DashboardLayoutComponent;

    // ProgressBars
    @ViewChild('ticketsPercentage') ticketsPercentage: ProgressBarComponent;

    // Navigations
    @ViewChild('ticketTab') ticketTabObj: TabComponent;

    // Lists
    @ViewChild('listviewTags') listviewTagsObj: ListViewComponent;

    @ViewChild('listviewCategories') listviewCategoriesObj: ListViewComponent;

    // Charts
    @ViewChild('ticketChart') ticketChartObj?: ChartComponent;

    // Filters
    private readonly subjectTagsFilter: Subject<string> = new Subject();

    private readonly subjectCategoriesFilter: Subject<string> = new Subject();

    constructor(
        protected readonly authenticationService: AuthenticationService,
        protected readonly router: Router,
        protected readonly ref: ChangeDetectorRef,
        protected readonly navHandlerService: NavHandlerService,
        protected readonly route: ActivatedRoute,
        protected readonly dataService: DataService,
        protected readonly intl: TimeagoIntl,
    ) {
        this.currentUser = this.authenticationService.currentUserValue;
        this.intl.strings = czechStrings;
        this.intl.changes.next();
    }

    ngOnInit(): void {
        this.primaryXAxis = {
            valueType: 'Category',
            edgeLabelPlacement: 'Shift',
            isInversed: true,
        };

        this.primaryYAxis = {
            minimum: 0,
            maximum: 200,
            labelFormat: '{value} ticketů',
            rangePadding: 'None',
        };

        this.legendSettings = {
            visible: true,
        };

        this.tooltip = {
            enable: true,
            header: 'Ticketů celkem',
        };
        this.datalabel = {visible: true};

        this.animation = {enable: true, duration: 2000, delay: 0};
        this.labelStyle = {
            color: '#FFFFFF',
            fontWeight: '900',
            fontFamily: 'Montserrat, sans-serif',
            fontStyle: 'normal',
        };
        this.showProgressValue = true;
        this.dataService.ticketSource.pipe(untilDestroyed(this))
            .subscribe(
                (tickets: Tickets[] | undefined) => {
                    this.tickets = tickets;
                    this.primaryYAxis.maximum = this.tickets?.length;

                    if (this.tickets && this.tickets.length > 0) {
                        this.loadStatsData();
                        this.dataService.tagSource.pipe(untilDestroyed(this))
                            .subscribe(
                                (tags: Tags[]) => {
                                    this.dataTags = [];
                                    tags.sort((a, b) => {
                                        const nameA = a.name;
                                        const nameB = b.name;

                                        return nameA > nameB ? 1 : -1;
                                    })
                                        .forEach((tag: Tags) => {
                                            const child: {[key: string]: number | string}[] = [];

                                            this.tickets
                                                ?.filter(ticket =>
                                                    ticket.tags.find(ticketTag => ticketTag.id === tag.id),
                                                )
                                                .forEach((ticket: Tickets) => {
                                                    child.push({
                                                        id: `${tag.id}-${ticket.id}`,
                                                        text: ticket.subject,
                                                        icon: 'fa-light fa-lg fa-ticket',
                                                        ticket_id: ticket.id,
                                                    });
                                                });

                                            this.dataTags.push({
                                                id: tag.id,
                                                text: tag.name,
                                                icon: 'fa-light fa-lg fa-tags',
                                                child,
                                            });
                                        });
                                    this.ref.markForCheck();
                                },
                                error => {
                                    console.error(error);
                                },
                            );

                        this.dataService.ticketCategoriesSource.pipe(untilDestroyed(this))
                            .subscribe(
                                (categories: TicketCategory[]) => {
                                    this.dataCategories = [];
                                    categories
                                        .sort((a, b) => {
                                            const nameA = a.name;
                                            const nameB = b.name;

                                            return nameA > nameB ? 1 : -1;
                                        })
                                        .map((category: TicketCategory) => {
                                            const child: {[key: string]: number | string}[] = [];

                                            this.tickets
                                                ?.filter(ticket => ticket.category_id === category.id)
                                                .forEach((ticket: Tickets) => {
                                                    child.push({
                                                        id: `${category.id}-${ticket.id}`,
                                                        text: ticket.subject,
                                                        icon: 'fa-light fa-lg fa-ticket',
                                                        ticket_id: ticket.id,
                                                    });
                                                });

                                            this.dataCategories.push({
                                                id: category.id,
                                                text: category.name,
                                                icon: 'fa-light fa-lg fa-th-list',
                                                child,
                                            });
                                        });
                                    this.ref.markForCheck();
                                },
                                error => {
                                    console.error(error);
                                },
                            );
                    }
                },
                error => {
                    console.error(error);
                },
            );

        this.navHandlerService.navState.pipe(untilDestroyed(this))
            .subscribe(
                () => {
                    this.refreshContent();
                },
                error => {
                    console.error(error);
                },
            );

        this.subjectTagsFilter.pipe(debounceTime(500))
            .subscribe(searchTextValue => {
                const data: {[key: string]: object}[] | DataManager | number[] | object[] | string[] =
                    new DataManager(this.dataTags).executeLocal(
                        new Query().where('text', 'contains', searchTextValue, true, true),
                    );

                if (!searchTextValue) {
                    this.listviewTagsObj.dataSource = this.dataTags.slice();
                } else {
                    (this.listviewTagsObj.dataSource as object[]) = data;
                }

                this.listviewTagsObj.dataBind();
            });

        this.subjectCategoriesFilter.pipe(debounceTime(500))
            .subscribe(searchTextValue => {
                const data: {[key: string]: object}[] | DataManager | number[] | object[] | string[] =
                    new DataManager(this.dataCategories).executeLocal(
                        new Query().where('text', 'contains', searchTextValue, true, true),
                    );

                if (!searchTextValue) {
                    this.listviewCategoriesObj.dataSource = this.dataCategories.slice();
                } else {
                    (this.listviewCategoriesObj.dataSource as object[]) = data;
                }

                this.listviewCategoriesObj.dataBind();
            });

        this.dataService.setTicketsDataSource();
        this.dataService.setTicketCategoriesSource();
        this.dataService.setDepartmentDataSource();
        this.dataService.setTagsDataSource();
    }

    onFilterTags(event: KeyboardEvent): void {
        const target: EventTarget | null = event.target;

        this.subjectTagsFilter.next((target as HTMLInputElement).value);
    }

    onFilterCategories(event): void {
        const target: EventTarget | null = event.target;

        this.subjectCategoriesFilter.next((target as HTMLInputElement).value);
    }

    changedBar(args: IProgressValueEventArgs): void {
        if (args.value < 30) {
            args.progressColor = '#629337';
        } else if (args.value >= 30 && args.value <= 70) {
            args.progressColor = '#FFBB34';
        } else if (args.value > 70) {
            args.progressColor = '#FF3547';
        }
    }

    onCreate(): void {
        // Nastavení GUI podle URL parametrů
        this.route.queryParamMap.pipe(untilDestroyed(this))
            .subscribe(queryParams => {
                if (queryParams.get('view') === 'grid') {
                    this.ticketTabObj.select(0);
                } else if (queryParams.get('view') === 'todo') {
                    this.ticketTabObj.select(1);
                } else if (queryParams.get('view') === 'sheduler') {
                    this.ticketTabObj.select(2);
                } else if (queryParams.get('view') === 'stats') {
                    this.ticketTabObj.select(3);
                } else if (queryParams.get('view') === 'import') {
                    this.ticketTabObj.select(4);
                } else {
                    this.ticketTabObj.select(0);
                }
            });
    }

    refreshContent(): void {
        setTimeout(() => {
            if (this.dashboard) {
                this.dashboard.refresh();
            }

            if (this.ticketChartObj) {
                this.ticketChartObj.refresh();
            }

            if (this.moduleToolbarObj) {
                this.moduleToolbarObj.refresh();
            }
        }, 1000);
    }

    onCloseIconHandler(event: MouseEvent): void {
        if (event.target === null) {
            return;
        }

        const elementOffset = (event.target as HTMLElement).offsetParent;

        if (elementOffset) {
            this.dashboard?.removePanel(elementOffset.id);
        }
    }

    preventSwipe(e: SelectEventArgs): void {
        if (e.isSwiped) {
            e.cancel = true;
        }
    }

    navigateToTab(view: string, form: string | null): void {
        const queryParams = {view, form};

        if (view === 'grid') {
            this.ticketTabObj.select(0);
        } else if (view === 'todo') {
            this.ticketTabObj.select(1);
        } else if (view === 'sheduler') {
            this.ticketTabObj.select(2);
        } else if (view === 'stats') {
            this.ticketTabObj.select(3);
        } else if (view === 'import') {
            this.ticketTabObj.select(4);
        } else {
            this.ticketTabObj.select(0);
        }

        void this.router.navigate([], {
            relativeTo: this.route,
            queryParams,
            queryParamsHandling: 'merge',
        });
    }

    select(e: SelectEventArgs): void {
        let queryParams: Params = {view: null};

        this.route.queryParamMap.pipe(untilDestroyed(this))
            .subscribe(params => {
                const form = params.get('form') === 'addTicket' ? 'addTicket' : null;

                if (e.selectedItem.textContent === 'tabulka') {
                    queryParams = {
                        view: 'grid',
                        form,
                    };
                }

                if (e.selectedItem.textContent === 'to-do') {
                    queryParams = {
                        view: 'todo',
                        form,
                    };
                }

                if (e.selectedItem.textContent === 'plánování') {
                    queryParams = {
                        view: 'sheduler',
                        form,
                    };
                }

                if (e.selectedItem.textContent === 'statistiky') {
                    queryParams = {
                        view: 'stats',
                        form,
                    };
                }

                if (e.selectedItem.textContent === 'import') {
                    queryParams = {
                        view: 'import',
                        form,
                    };
                }

                void this.router.navigate([], {
                    relativeTo: this.route,
                    queryParams,
                    queryParamsHandling: 'merge',
                });
            });
    }

    private loadStatsData(start?: string, end?: string): void {
        this.stats = {
            solved: {indue: 0, overdue: 0},
            deleted: {indue: 0, overdue: 0},
            queue: {indue: 0, overdue: 0},
        };

        let chartIndueDataSolved = 0;
        let chartIndueDataQueue = 0;
        let chartIndueDataDeleted = 0;
        let chartOverdueDataSolved = 0;
        let chartOverdueDataQueue = 0;
        let chartOverdueDataDeleted = 0;

        this.tickets = this.tickets?.sort((a, b) => {
            const dateA = moment(a.created_date)
                .valueOf();
            const dateB = moment(b.created_date)
                .valueOf();

            return dateA > dateB ? -1 : 1;
        });

        if (start) {
            this.tickets = this.tickets?.filter(
                (result: Tickets) => moment(result.start_deadline)
                    .format('YYYY-MM-DD') >= start,
            );
        }

        if (end) {
            this.tickets = this.tickets?.filter(
                (result: Tickets) => moment(result.deadline)
                    .format('YYYY-MM-DD') <= end,
            );
        }

        // eslint-disable-next-line complexity
        this.tickets?.map((ticket: Tickets) => {
            if (ticket.ticketTime) {
                // splněno v termínu
                if (ticket.finished_at && !ticket.ticketTime.overtime) {
                    chartIndueDataSolved = chartIndueDataSolved + 1;
                    this.stats.solved.indue = this.stats.solved.indue + 1;
                }

                // splněno po termínu
                if (ticket.finished_at && ticket.ticketTime.overtime) {
                    chartOverdueDataSolved = chartOverdueDataSolved + 1;
                    this.stats.solved.overdue = this.stats.solved.overdue + 1;
                }

                // fronta v termínu
                if (!ticket.finished_at && !ticket.deleted_date && !ticket.ticketTime.overtime) {
                    chartIndueDataQueue = chartIndueDataQueue + 1;
                    this.stats.queue.indue = this.stats.queue.indue + 1;
                }

                // fronta po termínu
                if (!ticket.finished_at && !ticket.deleted_date && ticket.ticketTime.overtime) {
                    chartOverdueDataQueue = chartOverdueDataQueue + 1;
                    this.stats.queue.overdue = this.stats.queue.overdue + 1;
                }

                // smazáno v termínu
                if (!ticket.finished_at && ticket.deleted_date && !ticket.ticketTime.overtime) {
                    chartIndueDataDeleted = chartIndueDataDeleted + 1;
                    this.stats.deleted.indue = this.stats.deleted.indue + 1;
                }

                // smazáno po termínu
                if (!ticket.finished_at && ticket.deleted_date && ticket.ticketTime.overtime) {
                    chartOverdueDataDeleted = chartOverdueDataDeleted + 1;
                    this.stats.deleted.overdue = this.stats.deleted.overdue + 1;
                }
            } else {
                if (ticket.finished_at) {
                    chartIndueDataSolved = chartIndueDataSolved + 1;
                    this.stats.solved.indue = this.stats.solved.indue + 1;
                }

                if (!ticket.finished_at && !ticket.deleted_date) {
                    chartIndueDataQueue = chartIndueDataQueue + 1;
                    this.stats.queue.indue = this.stats.queue.indue + 1;
                }

                if (!ticket.finished_at && ticket.deleted_date) {
                    chartIndueDataDeleted = chartIndueDataDeleted + 1;
                    this.stats.deleted.indue = this.stats.deleted.indue + 1;
                }
            }
        });

        this.chartDataInDue = [
            {
                state: 'Vyřešeno',
                tasks: chartIndueDataSolved,
                text: `Vyřešeno: ${chartIndueDataSolved}`,
            },
            {
                state: 'Ve frontě',
                tasks: chartIndueDataQueue,
                text: `Ve frontě: ${chartIndueDataQueue}`,
            },
            {
                state: 'Smazáno',
                tasks: chartIndueDataDeleted,
                text: `Smazáno: ${chartIndueDataDeleted}`,
            },
        ];

        this.chartDataOverDue = [
            {
                state: 'Vyřešeno',
                tasks: chartOverdueDataSolved,
                text: `Vyřešeno: ${chartOverdueDataSolved}`,
            },
            {
                state: 'Ve frontě',
                tasks: chartOverdueDataQueue,
                text: `Ve frontě: ${chartOverdueDataQueue}`,
            },
            {
                state: 'Smazáno',
                tasks: chartOverdueDataDeleted,
                text: `Smazáno: ${chartOverdueDataDeleted}`,
            },
        ];
        this.loadingTicket = false;
        this.ref.markForCheck();
    }
}
