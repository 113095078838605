import {
    AfterViewChecked,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnInit,
    ViewChild,
} from '@angular/core';
import {Observable, Observer} from 'rxjs';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {AuthenticationService} from '@src/app/_services/authentication.service';
import {DataService} from '@src/app/_services/data.service';
import {MessageService} from '@src/app/_services/message.service';
import {TimeagoIntl} from 'ngx-timeago';
import {strings as czechStrings} from 'ngx-timeago/language-strings/cs';
import {ApiConnections} from '@src/app/_models/api/connections';
import {ApisService} from '@src/app/_api/apis.service';
import {ApiPaths} from '@src/app/_models/api/paths';
import {ApiHeaders} from '@src/app/_models/api/headers';

import moment from 'moment';
import localeCs from '@angular/common/locales/cs';
import {registerLocaleData} from '@angular/common';
import {User} from '@src/app/_models/user/user';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {
    ColumnModel,
    DataResult,
    EditSettingsModel,
    ExcelExportProperties,
    FilterSettingsModel,
    GridComponent,
    GroupSettingsModel,
    PageSettingsModel,
    RowDataBoundEventArgs,
    SelectionSettingsModel,
    ToolbarItems,
} from '@syncfusion/ej2-angular-grids';
import {TextBoxComponent} from '@syncfusion/ej2-angular-inputs';
import {ChangeEventArgs, DropDownListComponent} from '@syncfusion/ej2-angular-dropdowns';
import {ClickEventArgs, TabComponent} from '@syncfusion/ej2-angular-navigations';
import {ButtonPropsModel, DialogComponent} from '@syncfusion/ej2-angular-popups';
import {PermissionService} from '@src/app/_services/permission.service';
import {SelectEventArgs} from '@syncfusion/ej2-navigations';
import {UsersService} from '@src/app/features/users/users.service';

moment.updateLocale('cs', {workingWeekdays: [1, 2, 3, 4, 5]});
registerLocaleData(localeCs);

@UntilDestroy()
@Component({
    selector: 'app-api-detail',
    templateUrl: './api-detail.component.html',
    styleUrls: ['./api-detail.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ApiDetailComponent implements OnInit, AfterViewChecked {
    // Navigations
    headerText = [{text: 'Data'}, {text: 'Historie změn'}];

    // Grid
    currencyFormat: object = {format: '#,###.00\',- CZK\''};

    dateFormat: string;

    toolbarPath: ToolbarItems[] | object;

    dataPath: object;

    toolbarHeader: ToolbarItems[] | object;

    dataHeader: object;

    fields: object = {text: 'label', value: 'value'};

    filterSettings: FilterSettingsModel;

    filterCheckboxSettings: FilterSettingsModel;

    selectionSettings: SelectionSettingsModel;

    editSettings: EditSettingsModel;

    groupOptions: GroupSettingsModel;

    pageOptions: PageSettingsModel;

    expendituresColumnsPrice: ColumnModel[];

    expendituresColumnsItems: ColumnModel[];

    filterDate: object;

    sortOptionsPath: object;

    sortOptionsHeader: object;

    requiredRules: object;

    exportCurrentPage = false;

    // Dialogs
    renderApiPathFormDialog = false;

    renderApiHeaderFormDialog = false;

    renderApiFormDialog = false;

    buttonsCancel: ButtonPropsModel[] = [
        {
            click: (): void => {
                this.confirmationCancel.hide();
            },
            buttonModel: {
                isPrimary: true,
                content: 'OK',
            },
        },
    ];

    buttonsDeleteApi: ButtonPropsModel[] = [
        {
            click: (): void => {
                this.removeConnection();
            },
            isFlat: false,
            buttonModel: {
                content: 'OK',
                cssClass: 'e-success e-outline',
            },
        },
        {
            click: (): void => {
                this.confirmationDeleteApi.hide();
            },
            buttonModel: {
                content: 'ZRUŠIT',
                cssClass: 'e-danger e-outline',
            },
        },
    ];

    buttonsDeleteApiPath: ButtonPropsModel[] = [
        {
            click: (): void => {
                this.removePath();
            },
            isFlat: false,
            buttonModel: {
                content: 'OK',
                cssClass: 'e-success e-outline',
            },
        },
        {
            click: (): void => {
                this.confirmationDeleteApiPath.hide();
            },
            buttonModel: {
                content: 'ZRUŠIT',
                cssClass: 'e-danger e-outline',
            },
        },
    ];

    buttonsDeleteApiHeader: ButtonPropsModel[] = [
        {
            click: (): void => {
                this.removeHeader();
            },
            isFlat: false,
            buttonModel: {
                content: 'OK',
                cssClass: 'e-success e-outline',
            },
        },
        {
            click: (): void => {
                this.confirmationDeleteApiHeader.hide();
            },
            buttonModel: {
                content: 'ZRUŠIT',
                cssClass: 'e-danger e-outline',
            },
        },
    ];

    buttonsDeactivateApi: ButtonPropsModel[] = [
        {
            click: (): void => {
                this.deactivateConnection();
            },
            isFlat: false,
            buttonModel: {
                content: 'OK',
                cssClass: 'e-success e-outline',
            },
        },
        {
            click: (): void => {
                this.confirmationDeactivateApi.hide();
            },
            buttonModel: {
                content: 'ZRUŠIT',
                cssClass: 'e-danger e-outline',
            },
        },
    ];

    buttonsActivateApi: ButtonPropsModel[] = [
        {
            click: (): void => {
                this.activateConnection();
            },
            isFlat: false,
            buttonModel: {
                content: 'OK',
                cssClass: 'e-success e-outline',
            },
        },
        {
            click: (): void => {
                this.confirmationActivateApi.hide();
            },
            buttonModel: {
                content: 'ZRUŠIT',
                cssClass: 'e-danger e-outline',
            },
        },
    ];

    // Dropdowns
    height = '240px';

    categoryFilterPathData: Array<string> = [];

    categoryFilterHeaderData: Array<string> = [];

    // Forms
    isCreate = false;

    isUpdate = false;

    isCopy = false;

    isCreatePath = false;

    isCreateHeader = false;

    isUpdatePath = false;

    isUpdateHeader = false;

    isCopyPath = false;

    isCopyHeader = false;

    // Variables
    currentUser: User | null;

    api: ApiConnections;

    paths: ApiPaths[] = [];

    headers: ApiHeaders[] = [];

    unixCreatedTime = 0;

    live: true;

    today = moment()
        .format('YYYY-MM-DD HH:mm:ss');

    clickedPathRow: ApiPaths[] = [];

    clickedHeaderRow: ApiHeaders[] = [];

    clickedPathRow$ = new Observable<ApiPaths[]>((observer: Observer<ApiPaths[]>) => {
        observer.next(this.clickedPathRow);
    });

    clickedHeaderRow$ = new Observable<ApiHeaders[]>((observer: Observer<ApiHeaders[]>) => {
        observer.next(this.clickedHeaderRow);
    });

    // Loaders
    loadingApi = true;

    loadingPaths = true;

    loadingHeaders = true;

    // Navigations
    @ViewChild('apisDetailTab') apisDetailTabObj: TabComponent;

    // Grid
    @ViewChild('pathsGrid') gridApiPaths: GridComponent;

    @ViewChild('headersGrid') gridApiHeaders: GridComponent;

    @ViewChild('searchtextPath') searchtextPathObj: TextBoxComponent;

    @ViewChild('searchtextHeader') searchtextHeaderObj: TextBoxComponent;

    // Dropdowns
    @ViewChild('viewFilterPath') viewFilterInstancePath: DropDownListComponent;

    @ViewChild('viewFilterHeader')
    viewFilterInstanceHeader: DropDownListComponent;

    // Dialogs
    @ViewChild('confirmationDeleteApi') confirmationDeleteApi: DialogComponent;

    @ViewChild('confirmationDeactivateApi')
    confirmationDeactivateApi: DialogComponent;

    @ViewChild('confirmationActivateApi')
    confirmationActivateApi: DialogComponent;

    @ViewChild('confirmationDeletePath')
    confirmationDeleteApiPath: DialogComponent;

    @ViewChild('confirmationDeleteHeader')
    confirmationDeleteApiHeader: DialogComponent;

    @ViewChild('confirmationCancel') confirmationCancel: DialogComponent;

    constructor(
        private readonly route: ActivatedRoute,
        private readonly router: Router,
        private readonly ref: ChangeDetectorRef,
        private readonly authenticationService: AuthenticationService,
        private readonly permissionService: PermissionService,
        private readonly apiService: ApisService,
        private readonly userService: UsersService,
        private readonly dataService: DataService,
        private readonly messages: MessageService,
        private readonly intl: TimeagoIntl,
    ) {
        this.currentUser = this.authenticationService.currentUserValue;
        this.intl.strings = czechStrings;
        this.intl.changes.next();
    }

    ngOnInit(): void {
        this.filterSettings = {
            type: 'Menu',
            showFilterBarStatus: true,
            ignoreAccent: true,
        };

        this.filterCheckboxSettings = {
            type: 'CheckBox',
            showFilterBarStatus: true,
            ignoreAccent: true,
        };

        this.filterSettings = {
            type: 'Excel',
            showFilterBarOperator: true,
            showFilterBarStatus: true,
            ignoreAccent: true,
        };

        this.selectionSettings = {
            persistSelection: true,
            type: 'Multiple',
            checkboxOnly: true,
        };

        this.toolbarPath = [
            {
                text: 'Občerstvit data',
                tooltipText: 'Občerstvit data',
                prefixIcon: 'e-refresh',
                id: 'customRefresh',
            },
            'Print',
            'ExcelExport',
            'Add',
            'Edit',
            {
                text: 'Smazat',
                tooltipText: 'Smazat cestu api',
                prefixIcon: 'e-remove',
                id: 'delete',
            },
            {
                text: 'Kopírovat',
                tooltipText: 'Kopírovat cestu api',
                prefixIcon: 'e-copy',
                id: 'copy',
            },
        ];

        this.toolbarHeader = [
            {
                text: 'Občerstvit data',
                tooltipText: 'Občerstvit data',
                prefixIcon: 'e-refresh',
                id: 'customRefresh',
            },
            'Print',
            'ExcelExport',
            'Add',
            'Edit',
            {
                text: 'Smazat',
                tooltipText: 'Smazat hlavičku api',
                prefixIcon: 'e-remove',
                id: 'delete',
            },
            {
                text: 'Kopírovat',
                tooltipText: 'Kopírovat hlavičku api',
                prefixIcon: 'e-copy',
                id: 'copy',
            },
        ];

        this.categoryFilterPathData = [
            'všechny cesty api',
            'aktivní cesty api',
            'neaktivní cesty api',
        ];

        this.categoryFilterHeaderData = [
            'všechny hlavičky api',
            'aktivní hlavičky api',
            'neaktivní hlavičky api',
        ];
        this.pageOptions = {pageSize: 50};
        this.dateFormat = 'dd.MM.yyyy HH:mm';
        this.filterDate = {type: 'Menu', params: {format: this.dateFormat}};
        this.groupOptions = {
            showGroupedColumn: true,
            disablePageWiseAggregates: false,
        };

        this.editSettings = {
            showConfirmDialog: false,
            showDeleteConfirmDialog: false,
            allowEditing: true,
            allowAdding: true,
            allowDeleting: true,
            allowEditOnDblClick: false,
            mode: 'Dialog',
        };
        this.requiredRules = {required: true};

        this.route.params.pipe(untilDestroyed(this))
            .subscribe(params => {
                this.dataService.setApiDetailDataSource(parseInt(params.id as string, 10));
            });
    }

    ngAfterViewChecked(): void {
        this.route.queryParamMap.pipe(untilDestroyed(this))
            .subscribe(params => {
                if (params.get('form') === 'addApiPath') {
                    this.isCreatePath = true;
                    this.isUpdatePath = false;
                    this.isCopyPath = false;
                    this.renderApiPathFormDialog = true;
                    this.ref.markForCheck();
                }

                if (params.get('form') === 'addApiHeader') {
                    this.isUpdateHeader = false;
                    this.isCopyHeader = false;
                    this.isCreateHeader = true;
                    this.renderApiHeaderFormDialog = true;
                    this.ref.markForCheck();
                }
            });
    }

    preventSwipe(e: SelectEventArgs): void {
        if (e.isSwiped) {
            e.cancel = true;
        }
    }

    onCreate(): void {
        // Nastavení GUI podle URL parametrů
        this.route.queryParamMap.pipe(untilDestroyed(this))
            .subscribe(queryParams => {
                if (queryParams.get('view') === 'data') {
                    this.apisDetailTabObj.selectedItem = 0;
                } else if (queryParams.get('view') === 'history') {
                    this.apisDetailTabObj.selectedItem = 1;
                } else {
                    this.apisDetailTabObj.selectedItem = 0;
                }
            });
    }

    select(e: SelectEventArgs): void {
        let queryParams: Params = {view: null};

        if (e.selectedItem.textContent === 'Data') {
            queryParams = {
                view: 'data',
                form: null,
            };
        }

        if (e.selectedItem.textContent === 'Historie změn') {
            queryParams = {
                view: 'history',
                form: null,
            };
        }

        void this.router.navigate([], {
            relativeTo: this.route,
            queryParams,
            queryParamsHandling: 'merge',
        });
    }

    loadDataPath(): DataResult[] {
        console.info('NEW TV DATA BOUND');

        const elements: unknown[] = [];

        if (typeof this.api.paths !== 'undefined' && this.api.paths.length > 0) {
            this.api.paths.map((path: ApiPaths) => {
                elements.push({
                    id: path.id,
                    path: path.path,
                    method: path.method,
                    ping: path.ping,
                    created_by: path.creator.id,
                    creator_str: path.creator.fullname,
                    creator_img: path.creator.personalphoto,
                    created_date: new Date(path.created_date),
                    updated_date: path.updated_date ? new Date(path.updated_date) : null,
                    deleted_date: path.deleted_date ? new Date(path.deleted_date) : null,
                });
            });
        }

        this.loadingApi = false;

        return elements as DataResult[];
    }

    loadDataHeader(): DataResult[] {
        console.info('NEW NET DATA BOUND');

        const elements: unknown[] = [];

        if (typeof this.api.headers !== 'undefined' && this.api.headers.length > 0) {
            this.api.headers.map((header: ApiHeaders) => {
                elements.push({
                    id: header.id,
                    title: header.title,
                    content: header.content,
                    created_by: header.creator.id,
                    creator_str: header.creator.fullname,
                    creator_img: header.creator.personalphoto,
                    created_date: new Date(header.created_date),
                    updated_date: header.updated_date ? new Date(header.updated_date) : null,
                    deleted_date: header.deleted_date ? new Date(header.deleted_date) : null,
                });
            });
        }

        this.loadingApi = false;

        return elements as DataResult[];
    }

    onCreatedPath(): void {
        this.disableToolBarBtnPath();
    }

    onCreatedHeader(): void {
        this.disableToolBarBtnHeader();
    }

    setInitialGridFiltering(): void {
        this.gridApiPaths.clearFiltering();
        this.gridApiHeaders.clearFiltering();
        this.ref.markForCheck();
    }

    onLoad(): void {
        const wrapper1 = document.getElementById('scroll_wrapper1_path');
        const wrapper2 = document.getElementById('scroll_wrapper2_path');

        if (wrapper1 === null || wrapper2 === null) {
            return;
        }

        wrapper1.onscroll = (): void => {
            wrapper2.scrollLeft = wrapper1.scrollLeft;
        };

        wrapper2.onscroll = (): void => {
            wrapper1.scrollLeft = wrapper2.scrollLeft;
        };

        this.dataService.apiDetailSource.pipe(untilDestroyed(this))
            .subscribe(
                (data: ApiConnections) => {
                    this.api = data;

                    if (typeof this.api === 'undefined') {
                        console.error('undefined value');

                        return;
                    }

                    this.dataPath = this.gridApiPaths.dataSource = this.loadDataPath();
                    this.sortOptionsPath = {
                        columns: [
                            {field: 'created_date', direction: 'Descending'},
                            {
                                field: 'id',
                                direction: 'Descending',
                            },
                        ],
                    };

                    this.dataHeader = this.gridApiPaths.dataSource = this.loadDataHeader();
                    this.sortOptionsHeader = {
                        columns: [
                            {field: 'created_date', direction: 'Descending'},
                            {
                                field: 'id',
                                direction: 'Descending',
                            },
                        ],
                    };
                },
                error => {
                    console.error(error);
                    this.loadingApi = false;
                    this.ref.markForCheck();
                },
            );
    }

    searchPath(): void {
        this.gridApiPaths.search(this.searchtextPathObj.value);
    }

    searchHeader(): void {
        this.gridApiHeaders.search(this.searchtextHeaderObj.value);
    }

    onDataBoundPath(): void {
        this.rowSelectedPath();
        this.ref.markForCheck();
    }

    onDataBoundHeader(): void {
        this.rowSelectedHeader();
        this.ref.markForCheck();
    }

    rowDataBoundPath(args: RowDataBoundEventArgs): void {
        const data = args.data as ApiConnections;

        if (args.row && data.deleted_date) {
            args.row.getElementsByClassName('e-gridchkbox')[0].classList.add('disablecheckbox');
            args.row
                .getElementsByClassName('e-checkbox-wrapper')[0]
                .classList.add('disablecheckbox');
            args.row.classList.add('e-disabled');
        }

        if (
            this.currentUser &&
            args.row &&
            data.created_by !== this.currentUser.id &&
            !this.permissionService.checkUserISAdministrative(this.currentUser)
        ) {
            args.row.getElementsByClassName('e-gridchkbox')[0].classList.add('disablecheckbox');
            args.row
                .getElementsByClassName('e-checkbox-wrapper')[0]
                .classList.add('disablecheckbox');
        }

        this.ref.markForCheck();
    }

    rowDataBoundHeader(args: RowDataBoundEventArgs): void {
        const data = args.data as ApiHeaders;

        if (args.row && data.deleted_date) {
            args.row.getElementsByClassName('e-gridchkbox')[0].classList.add('disablecheckbox');
            args.row
                .getElementsByClassName('e-checkbox-wrapper')[0]
                .classList.add('disablecheckbox');
            args.row.classList.add('e-disabled');
        }

        if (
            this.currentUser &&
            args.row &&
            data.created_by !== this.currentUser.id &&
            !this.permissionService.checkUserISAdministrative(this.currentUser)
        ) {
            args.row.getElementsByClassName('e-gridchkbox')[0].classList.add('disablecheckbox');
            args.row
                .getElementsByClassName('e-checkbox-wrapper')[0]
                .classList.add('disablecheckbox');
        }

        this.ref.markForCheck();
    }

    onStatusFilterChangePath(e: ChangeEventArgs): void {
        if (e.value === 'všechny cesty api') {
            this.gridApiPaths.clearFiltering();
        } else if (e.value === 'aktivní cesty api') {
            this.gridApiPaths.clearFiltering();
            this.gridApiPaths.filterByColumn('deleted_date', 'equal', null, 'and', false, true);
        } else if (e.value === 'neaktivní cesty api') {
            this.gridApiPaths.clearFiltering();
            this.gridApiPaths.filterByColumn('deleted_date', 'notequal', null, 'and', false, true);
        }
    }

    onStatusFilterChangeHeader(e: ChangeEventArgs): void {
        if (e.value === 'všechny hlavičky api') {
            this.gridApiHeaders.clearFiltering();
        } else if (e.value === 'aktivní hlavičky api') {
            this.gridApiHeaders.clearFiltering();
            this.gridApiHeaders.filterByColumn('deleted_date', 'equal', null, 'and', false, true);
        } else if (e.value === 'neaktivní hlavičky api') {
            this.gridApiHeaders.clearFiltering();
            this.gridApiHeaders.filterByColumn(
                'deleted_date',
                'notequal',
                null,
                'and',
                false,
                true,
            );
        }
    }

    cancelBtnPathClick(): void {
        this.searchtextPathObj.value = '';
        this.gridApiPaths.searchSettings.key = '';
    }

    cancelBtnHeaderClick(): void {
        this.searchtextHeaderObj.value = '';
        this.gridApiHeaders.searchSettings.key = '';
    }

    toolbarClickPath(args: ClickEventArgs): void {
        const xlsProp: ExcelExportProperties = {
            fileName: 'js_apiPaths_export.xlsx',
            enableFilter: true,
            exportType: this.exportCurrentPage ? 'CurrentPage' : 'AllPages',
        };

        if (args.item.id === 'pathsGrid_excelexport') {
            void this.gridApiPaths.excelExport(xlsProp);
        } else if (args.item.id === 'delete') {
            if (this.gridApiPaths.getSelectedRecords().length !== 0) {
                this.confirmationDeleteApiPath.content = `Potvrďte smazání ${this.clickedPathRow.length} NET tarifů`;
                this.confirmationDeleteApiPath.show();
            } else {
                this.confirmationCancel.content = 'Musíte vybrat alespoň jeden NET tarif';
                this.confirmationCancel.show();
            }
        } else if (args.item.id === 'copy') {
            if (this.gridApiPaths.getSelectedRecords().length !== 0) {
                this.isUpdatePath = false;
                this.isCreatePath = false;
                this.isCopyPath = true;
                this.renderApiPathFormDialog = true;
                this.ref.markForCheck();
            } else {
                this.confirmationCancel.content = 'Musíte vybrat alespoň jeden NET tarif';
                this.confirmationCancel.show();
            }
        } else if (args.item.id === 'customRefresh') {
            this.loadingApi = true;
            console.info('refresh DATA!');
            this.ref.markForCheck();
            this.dataService.setApiDataSource();
        }
    }

    toolbarClickHeader(args: ClickEventArgs): void {
        const xlsProp: ExcelExportProperties = {
            fileName: 'js_apiHeaders_export.xlsx',
            enableFilter: true,
            exportType: this.exportCurrentPage ? 'CurrentPage' : 'AllPages',
        };

        if (args.item.id === 'headersGrid_excelexport') {
            void this.gridApiHeaders.excelExport(xlsProp);
        } else if (args.item.id === 'delete') {
            if (this.gridApiHeaders.getSelectedRecords().length !== 0) {
                this.confirmationDeleteApiHeader.content = `Potvrďte smazání ${this.clickedHeaderRow.length} TV služeb`;
                this.confirmationDeleteApiHeader.show();
            } else {
                this.confirmationCancel.content = 'Musíte vybrat alespoň jednu TV službu';
                this.confirmationCancel.show();
            }
        } else if (args.item.id === 'copy') {
            if (this.gridApiHeaders.getSelectedRecords().length !== 0) {
                this.isUpdateHeader = false;
                this.isCreateHeader = false;
                this.isCopyHeader = true;
                this.renderApiHeaderFormDialog = true;
                this.ref.markForCheck();
            } else {
                this.confirmationCancel.content = 'Musíte vybrat alespoň jednu TV službu';
                this.confirmationCancel.show();
            }
        } else if (args.item.id === 'customRefresh') {
            this.loadingApi = true;
            console.info('refresh DATA!');
            this.ref.markForCheck();
            this.dataService.setApiDataSource();
        } else if (args.item.id === undefined) {
            {
                throw new Error('Not implemented yet: undefined case');
            }
        }
    }

    enableToolbarBtnHeader(): void {
        this.gridApiHeaders.toolbarModule.enableItems(['headersGrid_edit', 'delete', 'copy'], true);
    }

    disableToolBarBtnHeader(): void {
        this.gridApiHeaders.toolbarModule.enableItems(
            ['headersGrid_edit', 'delete', 'copy'],
            false,
        );
    }

    enableToolbarBtnPath(): void {
        this.gridApiPaths.toolbarModule.enableItems(['pathsGrid_edit', 'delete', 'copy'], true);
    }

    disableToolBarBtnPath(): void {
        this.gridApiPaths.toolbarModule.enableItems(['pathsGrid_edit', 'delete', 'copy'], false);
    }

    rowSelectedPath(): void {
        this.clickedPathRow = [];

        const selectedrecords: ApiPaths[] = this.gridApiPaths.getSelectedRecords() as ApiPaths[];

        if (selectedrecords.length > 0) {
            selectedrecords.map(row => {
                const tempData: ApiPaths | undefined = this.paths.find(x => x.id === row.id);

                if (typeof tempData === 'undefined') {
                    console.error('undefined value');

                    return;
                }

                this.clickedPathRow.push(tempData);
                this.ref.markForCheck();
            });

            this.clickedPathRow$ = new Observable<ApiPaths[]>((observer: Observer<ApiPaths[]>) => {
                observer.next(this.clickedPathRow);
            });
            this.enableToolbarBtnPath();
        } else {
            this.disableToolBarBtnPath();
            this.clickedPathRow$ = new Observable<ApiPaths[]>((observer: Observer<ApiPaths[]>) => {
                observer.next(this.clickedPathRow);
            });
        }
    }

    rowSelectedHeader(): void {
        this.clickedHeaderRow = [];

        const selectedrecords: ApiHeaders[] =
            this.gridApiHeaders.getSelectedRecords() as ApiHeaders[];

        if (selectedrecords.length > 0) {
            selectedrecords.map((row: ApiHeaders) => {
                const tempData: ApiHeaders | undefined = this.headers.find(x => x.id === row.id);

                if (typeof tempData === 'undefined') {
                    console.error('undefined value');

                    return;
                }

                this.clickedHeaderRow.push(tempData);
                this.ref.markForCheck();
            });

            this.clickedHeaderRow$ = new Observable<ApiHeaders[]>(
                (observer: Observer<ApiHeaders[]>) => {
                    observer.next(this.clickedHeaderRow);
                },
            );
            this.enableToolbarBtnHeader();
        } else {
            this.disableToolBarBtnHeader();
            this.clickedHeaderRow$ = new Observable<ApiHeaders[]>(
                (observer: Observer<ApiHeaders[]>) => {
                    observer.next(this.clickedHeaderRow);
                },
            );
        }
    }

    actionBeginPath(args): void {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        if (args.requestType === 'add' || args.requestType === 'beginEdit') {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            args.cancel = true;

            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            if (args.requestType === 'beginEdit') {
                this.isUpdatePath = true;
                this.isCreatePath = false;
                this.isCopyPath = false;
            }

            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            if (args.requestType === 'add') {
                this.isCreatePath = true;
                this.isUpdatePath = false;
                this.isCopyPath = false;
            }

            this.renderApiPathFormDialog = true;
            this.ref.markForCheck();
        }
    }

    actionBeginHeader(args): void {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        if (args.requestType === 'add' || args.requestType === 'beginEdit') {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            args.cancel = true;

            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            if (args.requestType === 'beginEdit') {
                this.isUpdateHeader = true;
                this.isCreateHeader = false;
                this.isCopyHeader = false;
            }

            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            if (args.requestType === 'add') {
                this.isCreateHeader = true;
                this.isUpdateHeader = false;
                this.isCopyHeader = false;
            }

            this.renderApiHeaderFormDialog = true;
            this.ref.markForCheck();
        }
    }

    actionComplete(args): void {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        if (args.requestType === 'beginEdit' || args.requestType === 'add') {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            args.form.ej2_instances[0].rules = {};
        }
    }

    editConnection(): void {
        this.isCopy = false;
        this.isCreate = false;
        this.isUpdate = true;
        this.renderApiFormDialog = true;
    }

    activateConnection(): void {
        if (typeof this.api === 'undefined') {
            return;
        }

        if (this.currentUser?.rule?.is_admin && !this.api.deleted_date && this.api.active) {
            this.api.active = true;
            this.apiService
                .updateApi(this.api)
                .pipe(untilDestroyed(this))
                .subscribe(
                    (api: ApiConnections) => {
                        const body = `API: #${api.id}`;
                        const options = {progressBar: true, timeOut: 5000};

                        this.messages.showSuccess('API úspěšně aktivována', body, options);
                        this.dataService.setApiDataSource();
                        this.loadingApi = false;
                        this.confirmationActivateApi.hide();
                        this.ref.markForCheck();
                    },
                    error => {
                        console.error(error);

                        const body = 'Zkuste to později ...';
                        const options = {progressBar: true, timeOut: 5000};

                        this.messages.showError('Chyba během aktivování API', body, options);
                        this.loadingApi = false;
                        this.ref.markForCheck();
                    },
                );
        } else {
            const body = 'Nemáte oprávnění provést tuto akci...';
            const options = {progressBar: true, timeOut: 5000};

            this.messages.showError('Nedostatečné oprávnění', body, options);
            this.loadingApi = false;
            this.confirmationActivateApi.hide();
            this.ref.markForCheck();
        }
    }

    deactivateConnection(): void {
        if (
            typeof this.api !== 'undefined' &&
            this.currentUser?.rule &&
            this.currentUser.rule.is_admin &&
            !this.api.deleted_date &&
            this.api.active
        ) {
            this.api.active = false;
            this.apiService
                .updateApi(this.api)
                .pipe(untilDestroyed(this))
                .subscribe(
                    (api: ApiConnections) => {
                        const body = `API: #${api.id}`;
                        const options = {progressBar: true, timeOut: 5000};

                        this.messages.showSuccess('API úspěšně deaktivována', body, options);
                        this.dataService.setApiDataSource();
                        this.loadingApi = false;
                        this.confirmationDeactivateApi.hide();
                        this.ref.markForCheck();
                    },
                    error => {
                        console.error(error);

                        const body = 'Zkuste to později ...';
                        const options = {progressBar: true, timeOut: 5000};

                        this.messages.showError('Chyba během deaktivování API', body, options);
                        this.loadingApi = false;
                        this.ref.markForCheck();
                    },
                );
        } else {
            const body = 'Nemáte oprávnění provést tuto akci...';
            const options = {progressBar: true, timeOut: 5000};

            this.messages.showError('Nedostatečné oprávnění', body, options);
            this.loadingApi = false;
            this.confirmationDeactivateApi.hide();
            this.ref.markForCheck();
        }
    }

    removeConnection(): void {
        if (typeof this.api === 'undefined') {
            return;
        }

        if (this.currentUser?.rule?.is_admin && !this.api.deleted_date && this.api.active) {
            this.api.active = true;
            this.api.deleted_date = this.today;
            this.apiService
                .removeApi(this.api)
                ?.pipe(untilDestroyed(this))
                .subscribe(
                    (api: ApiConnections) => {
                        const body = `API: #${api.id}`;
                        const options = {progressBar: true, timeOut: 5000};

                        this.messages.showSuccess('API úspěšně smazána', body, options);
                        this.dataService.setApiDataSource();
                        this.loadingApi = false;
                        this.confirmationDeleteApi.hide();
                        this.ref.markForCheck();
                    },
                    error => {
                        console.error(error);

                        const body = 'Zkuste to později ...';
                        const options = {progressBar: true, timeOut: 5000};

                        this.messages.showError('Chyba během mazání API', body, options);
                        this.loadingApi = false;
                        this.ref.markForCheck();
                    },
                );
        } else {
            const body = 'Nemáte oprávnění provést tuto akci...';
            const options = {progressBar: true, timeOut: 5000};

            this.messages.showError('Nedostatečné oprávnění', body, options);
            this.loadingApi = false;
            this.confirmationDeleteApi.hide();
            this.ref.markForCheck();
        }
    }

    removePath(): void {
        if (typeof this.api === 'undefined') {
            return;
        }

        if (this.currentUser?.rule?.is_admin && !this.api.deleted_date && this.api.active) {
            this.api.active = true;
            this.api.deleted_date = this.today;
            this.apiService
                .removeApi(this.api)
                ?.pipe(untilDestroyed(this))
                .subscribe(
                    (api: ApiConnections) => {
                        const body = `API: #${api.id}`;
                        const options = {progressBar: true, timeOut: 5000};

                        this.messages.showSuccess('API úspěšně smazána', body, options);
                        this.dataService.setApiDataSource();
                        this.loadingApi = false;
                        this.confirmationDeleteApiPath.hide();
                        this.ref.markForCheck();
                    },
                    error => {
                        console.error(error);

                        const body = 'Zkuste to později ...';
                        const options = {progressBar: true, timeOut: 5000};

                        this.messages.showError('Chyba během mazání API', body, options);
                        this.loadingApi = false;
                        this.ref.markForCheck();
                    },
                );
        } else {
            const body = 'Nemáte oprávnění provést tuto akci...';
            const options = {progressBar: true, timeOut: 5000};

            this.messages.showError('Nedostatečné oprávnění', body, options);
            this.loadingApi = false;
            this.confirmationDeleteApiPath.hide();
            this.ref.markForCheck();
        }
    }

    removeHeader(): void {
        if (typeof this.api === 'undefined') {
            return;
        }

        if (this.currentUser?.rule?.is_admin && !this.api.deleted_date && this.api.active) {
            this.api.active = true;
            this.api.deleted_date = this.today;
            this.apiService
                .removeApi(this.api)
                ?.pipe(untilDestroyed(this))
                .subscribe(
                    (api: ApiConnections) => {
                        const body = `API: #${api.id}`;
                        const options = {progressBar: true, timeOut: 5000};

                        this.messages.showSuccess('API úspěšně smazána', body, options);
                        this.dataService.setApiDataSource();
                        this.loadingApi = false;
                        this.confirmationDeleteApiHeader.hide();
                        this.ref.markForCheck();
                    },
                    error => {
                        console.error(error);

                        const body = 'Zkuste to později ...';
                        const options = {progressBar: true, timeOut: 5000};

                        this.messages.showError('Chyba během mazání API', body, options);
                        this.loadingApi = false;
                        this.ref.markForCheck();
                    },
                );
        } else {
            const body = 'Nemáte oprávnění provést tuto akci...';
            const options = {progressBar: true, timeOut: 5000};

            this.messages.showError('Nedostatečné oprávnění', body, options);
            this.loadingApi = false;
            this.confirmationDeleteApiHeader.hide();
            this.ref.markForCheck();
        }
    }
}
