import {User} from '@src/app/_models/user/user';
import {ApiTypes} from '@src/app/_models/api/types';
import {ApiPaths} from '@src/app/_models/api/paths';
import {ApiProtocols} from '@src/app/_models/api/protocols';
import {ApiHeaders} from '@src/app/_models/api/headers';
import {Company} from '@src/app/_models/company/company';

export class ApiConnections {
    id: number;

    // eslint-disable-next-line @typescript-eslint/naming-convention
    type_id: number;

    // eslint-disable-next-line @typescript-eslint/naming-convention
    protocol_id: number;

    // eslint-disable-next-line @typescript-eslint/naming-convention
    company_id: number;

    title: string;

    // eslint-disable-next-line @typescript-eslint/naming-convention
    host_url: string;

    state: boolean;

    port: number;

    // eslint-disable-next-line @typescript-eslint/naming-convention
    last_check: Date | string | null;

    active: boolean;

    // eslint-disable-next-line @typescript-eslint/naming-convention
    created_date: string;

    // eslint-disable-next-line @typescript-eslint/naming-convention
    updated_date?: string | null;

    // eslint-disable-next-line @typescript-eslint/naming-convention
    deleted_date?: string | null;

    // eslint-disable-next-line @typescript-eslint/naming-convention
    created_by: number;

    // eslint-disable-next-line @typescript-eslint/naming-convention
    updated_by?: number | null;

    // eslint-disable-next-line @typescript-eslint/naming-convention
    deleted_by?: number | null;

    creator: User;

    type: ApiTypes;

    protocol: ApiProtocols;

    headers: ApiHeaders[];

    paths: ApiPaths[];

    owner: Company;
}
