import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    HostListener,
    Input,
    OnChanges,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import {AbstractControl, FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {DataService} from '@src/app/_services/data.service';
import {SettingsService} from '@src/app/features/settings/settings.service';
import {MessageService} from '@src/app/_services/message.service';
import {AuthenticationService} from '@src/app/_services/authentication.service';
import {Company} from '@src/app/_models/company/company';
import {DataNetbaseService} from '@src/app/_services/data-netbase.service';
import {TransferTypes} from '@src/app/_models/services/transfer-types';
import {Discounts} from '@src/app/_models/services/discounts';
import {Places} from '@src/app/_models/services/places';
import {NetPackageInput, ServicesService} from '@src/app/features/services/services.service';
import {NetPackages} from '@src/app/_models/services/net-packages';
import {ExtendServices} from '@src/app/_models/services/extend-services';
import {Bonuses} from '@src/app/_models/services/bonuses';
import {User} from '@src/app/_models/user/user';
import {
    DropDownListComponent,
    FilteringEventArgs,
    MultiSelectComponent,
} from '@syncfusion/ej2-angular-dropdowns';
import {EmitType} from '@syncfusion/ej2-base';
import {Query} from '@syncfusion/ej2-data';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {Observable} from 'rxjs';
import {DialogComponent} from '@syncfusion/ej2-angular-popups';
import {ComponentCanDeactivate} from '@src/app/_guards/changes.guard';
import {ActivatedRoute, Params, Router} from '@angular/router';

@UntilDestroy()
@Component({
    selector: 'app-net-package-form',
    templateUrl: './net-package-form.component.html',
    styleUrls: ['./net-package-form.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NetPackageFormComponent implements OnInit, OnChanges, ComponentCanDeactivate {
    // Dropdowms
    height = '240px';

    fields: object = {text: 'label', value: 'value'};

    extServiceSelect: Array<{value: boolean | number | string; label: string}> = [];

    bonusSelect: Array<{value: boolean | number | string; label: string}> = [];

    placeSelect: Array<{value: boolean | number | string; label: string}> = [];

    transferSelect: Array<{value: boolean | number | string; label: string}> = [];

    discountSelect: Array<{value: boolean | number | string; label: string}> = [];

    companySelect: Array<{value: boolean | number | string; label: string}> = [];

    // Forms
    netPackageForm: FormGroup;

    placesList: FormArray;

    bonusesList: FormArray;

    extServicesList: FormArray;

    isDirty = false;

    isChecked = false;

    submited = false;

    // Variables
    currentUser: User | null;

    // Loaders
    loadingNetPackage = false;

    @Input() netPackage: NetPackages | null = null;

    @Input() isCreate = false;

    @Input() isUpdate = false;

    @Input() isVisible = false;

    @Input() isCopy = false;

    @Output() readonly netPackageFormDialogState = new EventEmitter<boolean>();

    // Dialogs
    @ViewChild('formDialog') formDialogObj: DialogComponent;

    // Dropdowms
    @ViewChild('provider') providerObj: DropDownListComponent;

    @ViewChild('transfer') transferObj: DropDownListComponent;

    @ViewChild('discount') discountObj: DropDownListComponent;

    @ViewChild('places') placesObj: MultiSelectComponent;

    @ViewChild('bonuses') bonusesObj: MultiSelectComponent;

    @ViewChild('extServices') extServicesObj: MultiSelectComponent;

    constructor(
        private readonly route: ActivatedRoute,
        private readonly router: Router,
        private readonly formBuilder: FormBuilder,
        private readonly ref: ChangeDetectorRef,
        private readonly dataService: DataService,
        private readonly dataNetbaseService: DataNetbaseService,
        private readonly settingsService: SettingsService,
        private readonly servicesService: ServicesService,
        private readonly messages: MessageService,
        private readonly authenticationService: AuthenticationService,
    ) {
        this.currentUser = this.authenticationService.currentUserValue;
    }

    get f(): {[key: string]: AbstractControl} {
        return this.netPackageForm.controls;
    }

    @HostListener('window:beforeunload')
    canDeactivate(): Observable<boolean> | boolean {
        return !this.isDirty;
    }

    ngOnInit(): void {
        this.dataService.companySource.pipe(untilDestroyed(this))
            .subscribe(
                (companies: Company[]) => {
                    this.companySelect = [];
                    companies.map((company: Company) => {
                        this.companySelect = [
                            ...this.companySelect,
                            {
                                value: company.id,
                                label: company.name,
                            },
                        ];
                    });
                    this.ref.markForCheck();
                },
                error => {
                    console.error(error);
                },
            );

        this.dataNetbaseService.transferTypesSource.pipe(untilDestroyed(this))
            .subscribe(
                (transfers: TransferTypes[]) => {
                    this.transferSelect = [];
                    transfers.map((transfer: TransferTypes) => {
                        if (!transfer.deleted_date) {
                            this.transferSelect = [
                                ...this.transferSelect,
                                {
                                    value: transfer.id,
                                    label: transfer.name,
                                },
                            ];
                        }
                    });
                    this.ref.markForCheck();
                },
                error => {
                    console.error(error);
                },
            );

        this.dataNetbaseService.discountSource.pipe(untilDestroyed(this))
            .subscribe(
                (discounts: Discounts[]) => {
                    this.discountSelect = [];
                    discounts.map((discount: Discounts) => {
                        if (!discount.deleted_date) {
                            this.discountSelect = [
                                ...this.discountSelect,
                                {
                                    value: discount.id,
                                    label: `${discount.discount}% z ceny`,
                                },
                            ];
                        }
                    });
                    this.ref.markForCheck();
                },
                error => {
                    console.error(error);
                },
            );

        this.dataNetbaseService.placesSource.pipe(untilDestroyed(this))
            .subscribe(
                (places: Places[]) => {
                    this.placeSelect = [];
                    places.map((place: Places) => {
                        if (!place.deleted_date) {
                            this.placeSelect = [
                                ...this.placeSelect,
                                {
                                    value: place.id,
                                    label: place.name,
                                },
                            ];
                        }
                    });
                    this.ref.markForCheck();
                },
                error => {
                    console.error(error);
                },
            );

        this.dataNetbaseService.extendServices.pipe(untilDestroyed(this))
            .subscribe(
                (services: ExtendServices[]) => {
                    this.extServiceSelect = [];
                    services.map((service: ExtendServices) => {
                        if (!service.deleted_date) {
                            this.extServiceSelect = [
                                ...this.extServiceSelect,
                                {
                                    value: service.id,
                                    label: service.name,
                                },
                            ];
                        }
                    });
                    this.ref.markForCheck();
                },
                error => {
                    console.error(error);
                },
            );

        this.dataNetbaseService.bonusesSource.pipe(untilDestroyed(this))
            .subscribe(
                (bonuses: Bonuses[]) => {
                    this.bonusSelect = [];
                    bonuses.map((bonus: Bonuses) => {
                        if (!bonus.deleted_date) {
                            this.bonusSelect = [
                                ...this.bonusSelect,
                                {
                                    value: bonus.id,
                                    label: bonus.name,
                                },
                            ];
                        }
                    });
                    this.ref.markForCheck();
                },
                error => {
                    console.error(error);
                },
            );

        this.netPackageForm = this.formBuilder.group({
            name: [
                '',
                Validators.compose([
                    Validators.required,
                    Validators.maxLength(100),
                    Validators.minLength(2),
                ]),
            ],
            price: [
                0,
                Validators.compose([
                    Validators.required,
                    Validators.max(100000),
                    Validators.min(1),
                ]),
            ],
            provider_id: [null, Validators.required],
            transfer_id: [null, Validators.required],
            discount_id: [null],
            recommended: [false, Validators.required],
            extension: [false, Validators.required],
            sale_order: [
                0,
                Validators.compose([Validators.required, Validators.max(20), Validators.min(0)]),
            ],
            speed_up: [
                0,
                Validators.compose([Validators.required, Validators.max(2000), Validators.min(1)]),
            ],
            speed_down: [
                0,
                Validators.compose([Validators.required, Validators.max(2000), Validators.min(1)]),
            ],
            aggregation: ['', Validators.compose([Validators.maxLength(100)])],
            net_places: [null],
            net_bonuses: [null],
            ext_services: [null],
        });

        this.netPackageForm.valueChanges.pipe(untilDestroyed(this))
            .subscribe(() => {
                this.isDirty = this.netPackageForm.dirty;
                this.showInputErrors();
            });

        this.dataService.setCompanyDataSource();
        this.dataNetbaseService.setTransferTypesDataSource();
        this.dataNetbaseService.setDiscountsDataSource();
        this.dataNetbaseService.setPlacesDataSource();
        this.dataNetbaseService.setBonusesDataSource();
        this.dataNetbaseService.setExtendedServicesDataSource();
    }

    ngOnChanges(): void {
        if (this.isVisible) {
            const queryParams: Params = {form: null};

            void this.router.navigate([], {
                relativeTo: this.route,
                queryParams,
                queryParamsHandling: 'merge',
            });

            if (this.isUpdate && this.netPackage) {
                this.netPackageForm.reset();
                this.formDialogObj.header = `NET tarif #${this.netPackage.id} - ${this.netPackage.name}`;
                this.fillTheForm();
                this.showInputErrors();
            }

            if (this.isCreate) {
                this.netPackageForm.reset();
                this.formDialogObj.header = 'Nový NET tarif';
            }

            if (this.isCopy) {
                this.netPackageForm.reset();
                this.formDialogObj.header = 'Nový NET tarif';
                this.fillTheForm();
                this.showInputErrors();
            }

            this.netPackageForm.controls.recommended.patchValue(false);
            this.netPackageForm.controls.extension.patchValue(false);
        }
    }

    changeDialogState(value: boolean): void {
        this.netPackageFormDialogState.emit(value);
    }

    onFilteringProviders: EmitType<FilteringEventArgs> = (e: FilteringEventArgs) => {
        if (e.text === '') {
            e.updateData(this.companySelect);
        } else {
            let query: Query = new Query();

            query = e.text !== '' ? query.where('label', 'contains', e.text, true, true) : query;
            e.updateData(this.companySelect, query);
        }
    };

    onFilteringTransferTypes: EmitType<FilteringEventArgs> = (e: FilteringEventArgs) => {
        if (e.text === '') {
            e.updateData(this.transferSelect);
        } else {
            let query: Query = new Query();

            query = e.text !== '' ? query.where('label', 'contains', e.text, true, true) : query;
            e.updateData(this.transferSelect, query);
        }
    };

    onFilteringDiscounts: EmitType<FilteringEventArgs> = (e: FilteringEventArgs) => {
        if (e.text === '') {
            e.updateData(this.discountSelect);
        } else {
            let query: Query = new Query();

            query = e.text !== '' ? query.where('label', 'contains', e.text, true, true) : query;
            e.updateData(this.discountSelect, query);
        }
    };

    onFilteringPlaces: EmitType<FilteringEventArgs> = (e: FilteringEventArgs) => {
        if (e.text === '') {
            e.updateData(this.placeSelect);
        } else {
            let query: Query = new Query();

            query = e.text !== '' ? query.where('label', 'contains', e.text, true, true) : query;
            e.updateData(this.placeSelect, query);
        }
    };

    onFilteringBonuses: EmitType<FilteringEventArgs> = (e: FilteringEventArgs) => {
        if (e.text === '') {
            e.updateData(this.bonusSelect);
        } else {
            let query: Query = new Query();

            query = e.text !== '' ? query.where('label', 'contains', e.text, true, true) : query;
            e.updateData(this.bonusSelect, query);
        }
    };

    onFilteringExtServices: EmitType<FilteringEventArgs> = (e: FilteringEventArgs) => {
        if (e.text === '') {
            e.updateData(this.extServiceSelect);
        } else {
            let query: Query = new Query();

            query = e.text !== '' ? query.where('label', 'contains', e.text, true, true) : query;
            e.updateData(this.extServiceSelect, query);
        }
    };

    fillTheForm(): void {
        const tempPlaces: number[] = [];
        const tempBonuses: number[] = [];
        const tempServices: number[] = [];

        this.netPackageForm.controls.name.patchValue(this.netPackage?.name);
        this.netPackageForm.controls.price.patchValue(this.netPackage?.price);
        this.netPackageForm.controls.provider_id.patchValue(this.netPackage?.provider_id);
        this.netPackageForm.controls.transfer_id.patchValue(this.netPackage?.transfer_id);
        this.netPackageForm.controls.discount_id.patchValue(
            this.netPackage?.discount_id ? this.netPackage.discount_id : null,
        );
        this.netPackageForm.controls.recommended.patchValue(this.netPackage?.recommended);
        this.netPackageForm.controls.extension.patchValue(this.netPackage?.extension);
        this.netPackageForm.controls.sale_order.patchValue(this.netPackage?.sale_order);
        this.netPackageForm.controls.speed_up.patchValue(this.netPackage?.speed_up);
        this.netPackageForm.controls.speed_down.patchValue(this.netPackage?.speed_down);
        this.netPackageForm.controls.aggregation.patchValue(this.netPackage?.aggregation);

        this.netPackage?.net_places.map((place: Places) => {
            tempPlaces.push(place.id);
        });

        this.netPackage?.net_bonuses.map((bonuses: Bonuses) => {
            tempBonuses.push(bonuses.id);
        });

        this.netPackage?.ext_services.map((extService: ExtendServices) => {
            tempServices.push(extService.id);
        });

        this.netPackageForm
            .get('net_places')
            ?.setValue(tempPlaces, {onlySelf: true, emitEvent: false});

        this.netPackageForm
            .get('net_bonuses')
            ?.setValue(tempBonuses, {onlySelf: true, emitEvent: false});

        this.netPackageForm
            .get('ext_services')
            ?.setValue(tempServices, {onlySelf: true, emitEvent: false});
    }

    onSubmit(): void {
        this.submited = true;
        this.isDirty = false;
        this.loadingNetPackage = true;

        if (this.isCreate || this.isCopy) {
            this.createNetPackage();
        }

        if (this.isUpdate) {
            this.editNetPackage();
        }
    }

    createNetPackage(): void {
        this.submited = true;

        if (this.netPackageForm.invalid) {
            console.error('form is not valid!');

            return;
        }

        this.loadingNetPackage = true;

        const netPackageData: NetPackageInput = {
            name: this.f.name.value,
            price: this.f.price.value,
            provider_id: this.f.provider_id.value,
            transfer_id: this.f.transfer_id.value,
            discount_id: this.f.discount_id.value,
            recommended: this.f.recommended.value,
            extension: this.f.extension.value,
            sale_order: this.f.sale_order.value,
            speed_up: this.f.speed_up.value,
            speed_down: this.f.speed_down.value,
            aggregation: this.f.aggregation.value,
            net_bonuses: this.f.net_bonuses.value,
            ext_services: this.f.ext_services.value,
            net_places: this.f.net_places.value,
            created_by: this.currentUser?.id,
        };

        this.servicesService
            .addNetPackage(netPackageData)
            .pipe(untilDestroyed(this))
            .subscribe(
                (data: NetPackages) => {
                    const body = `${data.name} přidán do nabídky`;
                    const options = {progressBar: true, timeOut: 5000};

                    this.messages.showSuccess('Tarif internetu úspěšně vytvořen', body, options);
                    this.dataNetbaseService.setNetPackagesDataSource();
                    this.isCreate = false;
                    this.loadingNetPackage = false;
                    this.ref.markForCheck();
                    this.formDialogObj.hide();
                },
                error => {
                    console.error(error);

                    const body = 'Zkuste to znovu později';
                    const options = {
                        progressBar: true,
                        timeOut: 5000,
                        toastClass: 'red',
                    };

                    this.messages.showError(
                        'Chyba při vytváření internetového tarifu',
                        body,
                        options,
                    );
                    this.loadingNetPackage = false;
                    this.ref.markForCheck();
                },
            );
    }

    editNetPackage(): void {
        this.submited = true;

        if (this.netPackageForm.invalid) {
            console.error('form is not valid!');

            return;
        }

        this.loadingNetPackage = true;

        const netPackageData: NetPackageInput = {
            id: this.netPackage?.id,
            name: this.f.name.value,
            price: this.f.price.value,
            provider_id: this.f.provider_id.value,
            transfer_id: this.f.transfer_id.value,
            discount_id: this.f.discount_id.value,
            recommended: this.f.recommended.value,
            extension: this.f.extension.value,
            sale_order: this.f.sale_order.value,
            speed_up: this.f.speed_up.value,
            speed_down: this.f.speed_down.value,
            aggregation: this.f.aggregation.value,
            net_bonuses: this.f.net_bonuses.value,
            ext_services: this.f.ext_services.value,
            net_places: this.f.net_places.value,
            updated_by: this.currentUser?.id,
        };

        this.servicesService
            .updateNetPackage(netPackageData)
            ?.pipe(untilDestroyed(this))
            .subscribe(
                (data: NetPackages) => {
                    const body = `${data.name} upraven`;
                    const options = {progressBar: true, timeOut: 5000};

                    this.messages.showSuccess('Tarif internetu úspěšně upraven', body, options);
                    this.dataNetbaseService.setNetPackagesDataSource();
                    this.isUpdate = false;
                    this.loadingNetPackage = false;
                    this.ref.markForCheck();
                    this.formDialogObj.hide();
                },
                error => {
                    console.error(error);

                    const body = 'Zkuste to znovu později';
                    const options = {
                        progressBar: true,
                        timeOut: 5000,
                        toastClass: 'red',
                    };

                    this.messages.showError('Chyba při úpravě internetového tarifu', body, options);
                    this.loadingNetPackage = false;
                    this.ref.markForCheck();
                },
            );
    }

    showInputErrors(): void {
        this.isChecked = true;
        /* console.log('VALID: ' + this.ticketForm.valid);
        Object.keys(this.ticketForm.controls).forEach(key => {
            const controlErrors: ValidationErrors = this.ticketForm.get(key).errors;
            if (controlErrors != null) {
                Object.keys(controlErrors).forEach(keyError => {
                    console.log('Key control: ' + key + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError]);
                });
            }
        });*/
        this.netPackageForm.markAllAsTouched();
        this.ref.markForCheck();
    }
}
