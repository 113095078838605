import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    HostListener,
    Input,
    OnChanges,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MessageService} from '@src/app/_services/message.service';
import {AuthenticationService} from '@src/app/_services/authentication.service';
import {DataService} from '@src/app/_services/data.service';
import {
    FontFamilyModel,
    ImageSettingsModel,
    QuickToolbarSettingsModel,
    RichTextEditorComponent,
} from '@syncfusion/ej2-angular-richtexteditor';
import {ToolbarModule} from '@syncfusion/ej2-angular-navigations';
import {addClass, Browser, createElement, removeClass} from '@syncfusion/ej2-base';
import {User} from '@src/app/_models/user/user';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {DialogComponent} from '@syncfusion/ej2-angular-popups';
import {Observable} from 'rxjs';
import {ComponentCanDeactivate} from '@src/app/_guards/changes.guard';
import {EnvironmentService} from '@src/app/_services/environment.service';
import {CommentsService} from '@src/app/shared_components/services/comments.service';
import {TaskComment} from '@src/app/_models/task/task-comment';
import {Task} from '@src/app/_models/task/task';

@UntilDestroy()
@Component({
    selector: 'app-task-comment-form',
    templateUrl: './task-comment-form.component.html',
    styleUrls: ['./task-comment-form.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TaskCommentFormComponent implements OnInit, OnChanges, ComponentCanDeactivate {
    // RTE
    maxLength = 15000;

    textArea: HTMLElement;

    insertImageSettings: ImageSettingsModel;

    fontFamily: FontFamilyModel = {
        items: [
            {
                text: 'Segoe UI',
                value: 'Segoe UI',
                command: 'Font',
                subCommand: 'FontName',
            },
            {
                text: 'Roboto',
                value: 'Roboto',
                command: 'Font',
                subCommand: 'FontName',
            }, // here font is added
            {
                text: 'Great vibes',
                value: 'Great Vibes,cursive',
                command: 'Font',
                subCommand: 'FontName',
            }, // here font is added
            {
                text: 'Noto Sans',
                value: 'Noto Sans',
                command: 'Font',
                subCommand: 'FontName',
            },
            {
                text: 'Impact',
                value: 'Impact,Charcoal,sans-serif',
                command: 'Font',
                subCommand: 'FontName',
            },
            {
                text: 'Tahoma',
                value: 'Tahoma,Geneva,sans-serif',
                command: 'Font',
                subCommand: 'FontName',
            },
        ],
    };

    tools: ToolbarModule = {
        type: 'MultiRow',
        items: [
            'Bold',
            'Italic',
            'Underline',
            'StrikeThrough',
            'FontName',
            'FontSize',
            'FontColor',
            'BackgroundColor',
            'LowerCase',
            'UpperCase',
            'SuperScript',
            'SubScript',
            '|',
            'Formats',
            'Alignments',
            'OrderedList',
            'UnorderedList',
            'Outdent',
            'Indent',
            '|',
            'CreateTable',
            'CreateLink',
            'Image',
            '|',
            'ClearFormat',
            'Print',
            'SourceCode',
            'FullScreen',
            '|',
            'Undo',
            'Redo',
        ],
    };

    quickToolbarSettings: QuickToolbarSettingsModel = {
        table: [
            'TableHeader',
            'TableRows',
            'TableColumns',
            'TableCell',
            '-',
            'BackgroundColor',
            'TableRemove',
            'TableCellVerticalAlign',
            'Styles',
        ],
    };

    pasteCleanupSettings: object = {
        prompt: true,
        plainText: false,
        keepFormat: false,
        deniedTags: ['a'],
        deniedAttrs: ['class', 'title', 'id'],
        allowedStyleProps: ['color', 'margin', 'font-size'],
    };

    // Forms
    commentForm: FormGroup;

    isDirty = false;

    isChecked = false;

    submited = false;

    // Variables
    currentUser: User | null;

    // Loaders
    loadingComment = false;

    @Input() comment: TaskComment | null = null;

    @Input() task?: Task | null = null;

    @Input() isCreate = false;

    @Input() isUpdate = false;

    @Input() isVisible = false;

    @Input() isCopy = false;

    @Output() readonly taskCommentFormDialogState = new EventEmitter<boolean>();

    // Dialogs
    @ViewChild('formDialog') formDialogObj: DialogComponent;

    // RTE
    @ViewChild('formComment') formComment?: RichTextEditorComponent;

    constructor(
        private readonly formBuilder: FormBuilder,
        private readonly commentService: CommentsService,
        private readonly ref: ChangeDetectorRef,
        private readonly authenticationService: AuthenticationService,
        private readonly messages: MessageService,
        private readonly dataService: DataService,
        private readonly environmentService: EnvironmentService,
    ) {
        this.currentUser = this.authenticationService.currentUserValue;
        this.insertImageSettings = {
            path: `${this.environmentService.backendURL}/api/file/preview/task/`,
            saveUrl: `${this.environmentService.backendURL}/api/upload/attachments/tasks?type=attachments&subfolder=tasks`,
        };
    }

    get editCommentControl(): {[key: string]: AbstractControl} {
        return this.commentForm.controls;
    }

    @HostListener('window:beforeunload')
    canDeactivate(): Observable<boolean> | boolean {
        return !this.isDirty;
    }

    ngOnInit(): void {
        this.commentForm = this.formBuilder.group({
            text: ['', Validators.required],
        });

        this.commentForm.valueChanges.pipe(untilDestroyed(this))
            .subscribe(() => {
                this.isDirty = this.commentForm.dirty;
                this.showInputErrors();
            });
    }

    ngOnChanges(): void {
        if (this.isVisible) {
            if (this.isUpdate && this.comment) {
                this.commentForm.reset();
                this.formDialogObj.header = `Komentář #${this.comment.id}`;
                this.fillTheForm();
                this.showInputErrors();
            }

            if (this.isCreate) {
                this.commentForm.reset();
                this.formDialogObj.header = 'Nový komentář';
            }

            if (this.isCopy) {
                this.commentForm.reset();
                this.formDialogObj.header = 'Nový komentář';
                this.fillTheForm();
                this.showInputErrors();
            }
        }
    }

    changeDialogState(value: boolean): void {
        this.taskCommentFormDialogState.emit(value);
    }

    fillTheForm(): void {
        if (!this.comment) {
            console.error('this.comment is missing...');

            return;
        }

        this.commentForm.controls.text.patchValue(this.comment.text);
    }

    onSubmit(): void {
        this.submited = true;
        this.isDirty = false;
        this.loadingComment = true;

        if (this.isCreate || this.isCopy) {
            this.createComment();
        }

        if (this.isUpdate) {
            this.editComment();
        }
    }

    createComment(): void {
        if (this.commentForm.invalid) {
            console.error('form is not valid!');

            return;
        }

        const comment = {
            text: this.editCommentControl.text.value,
            task_id: this.task?.id,
            created_by: this.currentUser?.id,
            is_thread: true,
        };

        this.commentService
            .addTaskComment(comment)
            .pipe(untilDestroyed(this))
            .subscribe(
                () => {
                    const body = 'Komentář byl úšpěšně publikován.';
                    const options = {progressBar: true, timeOut: 5000};

                    this.messages.showSuccess('Diskuze aktualizována', body, options);
                    this.isCreate = false;
                    this.loadingComment = false;
                    this.ref.markForCheck();
                    this.formDialogObj.hide();

                    if (!this.task) {
                        console.error('this.task is missing...');

                        return;
                    }

                    this.dataService.setTaskDetailSource(this.task.id);
                },
                error => {
                    console.error(error);

                    const body = 'Zkuste to znovu později ...';
                    const options = {
                        progressBar: true,
                        timeOut: 5000,
                        toastClass: 'red',
                    };

                    this.messages.showError(
                        'Chyba při publikování komentáře v úkolu',
                        body,
                        options,
                    );
                    this.loadingComment = false;
                    this.ref.markForCheck();
                },
            );
    }

    editComment(): void {
        if (this.commentForm.invalid || !this.comment) {
            console.error('form is not valid! || this.comment is missing...');

            return;
        }

        this.comment.text = this.editCommentControl.text.value.replace(/\r?\n/g, '<br />');
        this.comment.updated_by = this.currentUser?.id;
        this.commentService
            .updateTaskComment(this.comment)
            .pipe(untilDestroyed(this))
            .subscribe(
                () => {
                    const body = 'Komentář byl úspěšně aktualizován.';
                    const options = {progressBar: true, timeOut: 5000};

                    this.messages.showSuccess('Diskuze aktualizována', body, options);
                    this.isUpdate = false;
                    this.loadingComment = false;
                    this.ref.markForCheck();
                    this.formDialogObj.hide();

                    if (!this.comment) {
                        console.error('this.comment is missing...');

                        return;
                    }

                    this.dataService.setTaskDetailSource(this.comment.task_id);
                },
                error => {
                    console.error(error);

                    const body = 'Zkuste to znovu později';
                    const options = {
                        progressBar: true,
                        timeOut: 5000,
                        toastClass: 'red',
                    };

                    this.messages.showError(
                        'Chyba při aktualizaci komentáře úkolu',
                        body,
                        options,
                    );
                    this.loadingComment = false;
                    this.ref.markForCheck();
                },
            );
    }

    // RTE
    onCreateRte(): void {
        this.formComment?.refreshUI();
        this.formComment?.autoResize();
    }

    handleFullScreen(e: unknown): void {
        const sbCntEle: HTMLElement | null = document.querySelector('.sb-content.e-view');
        const sbHdrEle: HTMLElement | null = document.querySelector('.sb-header.e-view');
        const leftBar: HTMLElement | null = document.querySelector('#left-sidebar');

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        if (e.targetItem === 'Maximize') {
            if (Browser.isDevice && Browser.isIos && sbCntEle && sbHdrEle) {
                addClass([sbCntEle, sbHdrEle], ['hide-header']);
            }

            if (leftBar) {
                addClass([leftBar], ['e-close']);
                removeClass([leftBar], ['e-open']);
            }
        }
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        else if (e.targetItem === 'Minimize') {
            if (Browser.isDevice && Browser.isIos && sbCntEle && sbHdrEle) {
                removeClass([sbCntEle, sbHdrEle], ['hide-header']);
            }

            if (leftBar) {
                removeClass([leftBar], ['e-close']);

                if (!Browser.isDevice) {
                    addClass([leftBar], ['e-open']);
                }
            }
        }
    }

    mirrorConversion(e: unknown): void {
        if (this.formComment) {
            const id = `${this.formComment.getID()}mirror-view`;
            let mirrorView: HTMLElement | null = this.formComment.element.querySelector(`#${id}`);
            const charCount: HTMLElement | null =
                this.formComment.element.querySelector('.e-rte-character-count');

            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            if (e.targetItem === 'Preview' && mirrorView && charCount) {
                this.textArea.style.display = 'block';
                mirrorView.style.display = 'none';
                charCount.style.display = 'block';
            } else {
                if (!mirrorView && this.textArea.parentNode) {
                    mirrorView = createElement('div', {className: 'e-content'});
                    mirrorView.id = id;
                    this.textArea.parentNode.appendChild(mirrorView);
                } else if (mirrorView) {
                    mirrorView.innerHTML = '';
                }

                if (mirrorView) {
                    this.textArea.style.display = 'none';
                    mirrorView.style.display = 'block';
                }

                if (charCount) {
                    charCount.style.display = 'none';
                }
            }
        }
    }

    actionCompleteHandler(e: unknown): void {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        if (e.targetItem && (e.targetItem === 'SourceCode' || e.targetItem === 'Preview')) {
            (this.formComment?.sourceCodeModule.getPanel() as HTMLTextAreaElement).style.display =
                'none';
            this.mirrorConversion(e);
        } else {
            setTimeout(() => {
                this.formComment?.toolbarModule.refreshToolbarOverflow();
            }, 400);
        }
    }

    showInputErrors(): void {
        this.isChecked = true;
        /* console.log('VALID: ' + this.ticketForm.valid);
    Object.keys(this.ticketForm.controls).forEach(key => {
        const controlErrors: ValidationErrors = this.ticketForm.get(key).errors;
        if (controlErrors != null) {
            Object.keys(controlErrors).forEach(keyError => {
                console.log('Key control: ' + key + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError]);
            });
        }
    });*/
        this.commentForm.markAllAsTouched();
        this.ref.markForCheck();
    }
}
