<div class="control-section">
  <div class="content-wrapper">
    <ejs-kanban
      #kanbanObj
      (cardRendered)="cardRendered($event)"
      (dialogOpen)="dialogOpen($event)"
      [allowDragAndDrop]="false"
      [cardSettings]="cardSettings"
      [dataSource]="kanbanData"
      [dialogSettings]="dialogSettings"
      [swimlaneSettings]="swimlaneSettings"
      cssClass="kanban-overview"
      enableTooltip="true"
      keyField="Type"
    >
      <e-columns>
        <e-column
          *ngFor="let column of columns"
          allowToggle="{{ column.allowToggle }}"
          headerText="{{ column.headerText }}"
          keyField="{{ column.keyField }}"
        >
          <ng-template #template let-data>
            <div class="header-template-wrap">
              <div
                class="header-icon e-icons {{ data.keyField }}"
              ></div>
              <div class="header-text">{{ data.headerText }}</div>
            </div>
          </ng-template>
        </e-column>
      </e-columns>
      <ng-template #cardSettingsTemplate let-data>
        <div class="card-template">
          <div class="e-card-header">
            <div class="e-card-header-caption">
              <div class="e-card-header-title e-tooltip-text">
                {{ data.Title }}
              </div>
            </div>
          </div>
          <div class="e-card-content e-tooltip-text">
            <div class="e-text">
              {{ data.StartDate | date: 'dd.MM.yyyy HH:mm' }} -
              {{ data.EndDate | date: 'dd.MM.yyyy HH:mm' }}
            </div>
            <div class="e-text">{{ data.Summary }}</div>
          </div>
          <div class="e-card-custom-footer">
            <div
              *ngFor="let tag of data.Tags.split(',')"
              class="e-card-tag-field e-tooltip-text"
            >
              {{ tag }}
            </div>
            <div class="e-card-avatar">{{
                getString(data.Assignee)
              }}
            </div>
          </div>
        </div>
      </ng-template>
    </ejs-kanban>
  </div>
</div>
