import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnInit,
    ViewChild,
} from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {registerLocaleData} from '@angular/common';
import {Observable, Observer} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthenticationService} from '@src/app/_services/authentication.service';
import {DataService} from '@src/app/_services/data.service';
import {DataNetbaseService} from '@src/app/_services/data-netbase.service';
import {FieldGridItems, ServicesService} from '@src/app/features/services/services.service';
import {PermissionService} from '@src/app/_services/permission.service';
import {MessageService} from '@src/app/_services/message.service';
import {User} from '@src/app/_models/user/user';
import {AddressConnectedField} from '@src/app/_models/services/address-connected-field';
import {ChangeEventArgs, DropDownListComponent} from '@syncfusion/ej2-angular-dropdowns';
import {
    ColumnModel,
    DataResult,
    EditSettingsModel,
    ExcelExportProperties,
    FilterSettingsModel,
    GridComponent,
    GroupSettingsModel,
    PageSettingsModel,
    RowDataBoundEventArgs,
    SelectionSettingsModel,
    ToolbarItems,
} from '@syncfusion/ej2-angular-grids';
import {ButtonPropsModel, DialogComponent} from '@syncfusion/ej2-angular-popups';
import {Project} from '@src/app/_models/projects/project';
import {ClickEventArgs} from '@syncfusion/ej2-angular-navigations';

import moment from 'moment';
import localeCs from '@angular/common/locales/cs';
import {TextBoxComponent} from '@syncfusion/ej2-angular-inputs';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';

moment.updateLocale('cs', {workingWeekdays: [1, 2, 3, 4, 5]});
registerLocaleData(localeCs);

@UntilDestroy()
@Component({
    selector: 'app-service-connected-field',
    templateUrl: './service-connected-field.component.html',
    styleUrls: ['./service-connected-field.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ServiceConnectedFieldComponent implements OnInit {
    // Grid
    currencyFormat: {format: string} = {format: '#,###.00\',- CZK\''};

    dateFormat: string;

    toolbar: ToolbarItems[] | object;

    data: object;

    filterSettings: FilterSettingsModel;

    selectionSettings: SelectionSettingsModel;

    editSettings: EditSettingsModel;

    groupOptions: GroupSettingsModel;

    pageOptions: PageSettingsModel;

    technologyColumns: ColumnModel[];

    buildingColumns: ColumnModel[];

    addressColumns: ColumnModel[];

    marketingColumns: ColumnModel[];

    serviceColumns: ColumnModel[];

    filterDate: object;

    requiredRules: object;

    sortOptions: object;

    exportCurrentPage = false;

    // Dropdowns
    height = '240px';

    categoryFilterData: string[] = [];

    // Dialogs
    renderProjectFormDialog = false;

    renderFieldFormDialog = false;

    renderCheckRuianFormDialog = false;

    buttonsDelete: ButtonPropsModel[] = [
        {
            click: async (): Promise<void> => {
                await this.deleteFIELD();
            },
            isFlat: false,
            buttonModel: {
                content: 'OK',
                cssClass: 'e-success e-outline',
            },
        },
        {
            click: (): void => {
                this.confirmationDelete.hide();
            },
            buttonModel: {
                content: 'ZRUŠIT',
                cssClass: 'e-danger e-outline',
            },
        },
    ];

    buttonsCancel: ButtonPropsModel[] = [
        {
            click: (): void => {
                this.confirmationCancel.hide();
            },
            buttonModel: {
                isPrimary: true,
                content: 'OK',
            },
        },
    ];

    // Forms
    isCreate = false;

    isUpdate = false;

    isCopy = false;

    isProjectCreate = false;

    isProjectUpdate = false;

    isProjectCopy = false;

    // Variables
    currentUser: User | null;

    fields: AddressConnectedField[] | undefined = [];

    project: Project;

    clickedRow: AddressConnectedField[] = [];

    clickedRow$ = new Observable<AddressConnectedField[]>(
        (observer: Observer<AddressConnectedField[]>) => {
            observer.next(this.clickedRow);
        },
    );

    // Loaders
    fieldAddressLoad = true;

    // Grid
    @ViewChild('fieldGrid') gridFields: GridComponent;

    @ViewChild('searchtext') searchtextObj: TextBoxComponent;

    @ViewChild('templateKeysBadges') templateKeysBadges: string;

    @ViewChild('templateLiftBadges') templateLiftBadges: string;

    @ViewChild('templateClipBadges') templateClipBadges: string;

    // Dropdowns
    @ViewChild('viewFilter') viewFilterInstance: DropDownListComponent;

    // Dialogs
    @ViewChild('checkRuianDialog') checkRuianDialogObj: DialogComponent;

    @ViewChild('confirmationDelete') confirmationDelete: DialogComponent;

    @ViewChild('confirmationCancel') confirmationCancel: DialogComponent;

    constructor(
        private readonly router: Router,
        private readonly route: ActivatedRoute,
        private readonly ref: ChangeDetectorRef,
        private readonly formBuilder: FormBuilder,
        private readonly authenticationService: AuthenticationService,
        private readonly dataService: DataService,
        private readonly dataNetbaseService: DataNetbaseService,
        private readonly servicesService: ServicesService,
        private readonly permissionService: PermissionService,
        private readonly messages: MessageService,
    ) {
        this.currentUser = this.authenticationService.currentUserValue;
    }

    ngOnInit(): void {
        this.filterSettings = {
            type: 'Excel',
            showFilterBarOperator: true,
            showFilterBarStatus: true,
            ignoreAccent: true,
        };

        this.selectionSettings = {
            persistSelection: true,
            type: 'Multiple',
            checkboxOnly: true,
        };

        this.toolbar = [
            {
                text: 'Občerstvit data',
                tooltipText: 'Občerstvit data',
                prefixIcon: 'e-refresh',
                id: 'customRefresh',
            },
            'Print',
            'ExcelExport',
            'Add',
            'Edit',
            {
                text: 'Kopírovat',
                tooltipText: 'Kopírovat parcelu',
                prefixIcon: 'e-copy',
                id: 'copy',
            },
            {
                text: 'Detail',
                tooltipText: 'Detail parcely',
                prefixIcon: 'e-openfolder',
                id: 'detail',
            },
            {
                text: 'Smazat',
                tooltipText: 'Smazat parcelu',
                prefixIcon: 'e-remove',
                id: 'delete',
            },
            {
                text: 'Zjistit RUIAN',
                tooltipText: 'Zjistit RUIAN',
                prefixIcon: 'e-ruian',
                id: 'ruian',
            },
        ];

        this.categoryFilterData = [
            'všechny parcely',
            'pouze aktivní parcely',
            'potenciál nad 10',
            'služby nad 10',
            'klienti nad 10',
        ];
        this.pageOptions = {pageSize: 50};
        this.dateFormat = 'dd.MM.yyyy HH:mm';
        this.filterDate = {type: 'Menu', params: {format: this.dateFormat}};
        this.groupOptions = {
            showGroupedColumn: true,
            disablePageWiseAggregates: false,
        };

        this.editSettings = {
            allowEditing: true,
            allowAdding: true,
            allowDeleting: true,
            allowEditOnDblClick: false,
            mode: 'Dialog',
        };
        this.requiredRules = {required: true};
        this.technologyColumns = [
            {
                field: 'transfer',
                headerText: 'Přenos',
                filter: this.filterSettings,
                width: 130,
                maxWidth: 130,
                minWidth: 60,
                clipMode: 'EllipsisWithTooltip',
                autoFit: true,
            },
            {
                field: 'technology',
                headerText: 'Technologie',
                filter: this.filterSettings,
                width: 130,
                maxWidth: 130,
                minWidth: 60,
                clipMode: 'EllipsisWithTooltip',
                autoFit: true,
            },
        ];

        this.buildingColumns = [
            {
                field: 'unit_type',
                headerText: 'Typ jednotky',
                filter: this.filterSettings,
                width: 130,
                maxWidth: 130,
                minWidth: 100,
                clipMode: 'EllipsisWithTooltip',
                autoFit: true,
            },
            {
                field: 'number_of_units',
                headerText: 'Počet bytů',
                filter: this.filterSettings,
                format: 'N',
                width: 80,
                textAlign: 'Right',
                minWidth: 60,
                clipMode: 'EllipsisWithTooltip',
                autoFit: true,
            },
            {
                field: 'number_of_storeys',
                headerText: 'Počet podlaží',
                filter: this.filterSettings,
                format: 'N',
                width: 80,
                textAlign: 'Right',
                minWidth: 60,
                clipMode: 'EllipsisWithTooltip',
                autoFit: true,
            },
            {
                field: 'lift',
                headerText: 'Výtah',
                filter: this.filterSettings,
                width: 60,
                textAlign: 'Center',
                minWidth: 40,
                clipMode: 'EllipsisWithTooltip',
                autoFit: true,
                template: this.templateLiftBadges,
            },
            {
                field: 'flat_space',
                headerText: 'Prostor',
                filter: this.filterSettings,
                width: 130,
                maxWidth: 130,
                minWidth: 60,
                clipMode: 'EllipsisWithTooltip',
                autoFit: true,
            },
            {
                field: 'owner_name',
                headerText: 'Vlastník',
                filter: this.filterSettings,
                width: 130,
                maxWidth: 130,
                minWidth: 60,
                clipMode: 'EllipsisWithTooltip',
                autoFit: true,
            },
            {
                field: 'manager_name',
                headerText: 'Správce',
                filter: this.filterSettings,
                width: 130,
                maxWidth: 130,
                minWidth: 60,
                clipMode: 'EllipsisWithTooltip',
                autoFit: true,
            },
            {
                field: 'electrical_counter',
                headerText: 'Elektroměr',
                filter: this.filterSettings,
                width: 130,
                textAlign: 'Center',
                minWidth: 60,
                maxWidth: 130,
                clipMode: 'EllipsisWithTooltip',
                autoFit: true,
            },
            {
                field: 'keys',
                headerText: 'Klíče',
                filter: this.filterSettings,
                width: 60,
                textAlign: 'Center',
                minWidth: 40,
                clipMode: 'EllipsisWithTooltip',
                autoFit: true,
                template: this.templateKeysBadges,
            },
        ];

        this.addressColumns = [
            {
                field: 'address_street',
                headerText: 'Ulice',
                filter: this.filterSettings,
                width: 130,
                maxWidth: 130,
                minWidth: 60,
                clipMode: 'EllipsisWithTooltip',
                autoFit: true,
            },
            {
                field: 'address_city',
                headerText: 'Město',
                filter: this.filterSettings,
                width: 130,
                maxWidth: 130,
                minWidth: 60,
                clipMode: 'EllipsisWithTooltip',
                autoFit: true,
            },
            {
                field: 'address_zip',
                headerText: 'PSČ',
                filter: this.filterSettings,
                width: 130,
                maxWidth: 130,
                minWidth: 60,
                clipMode: 'EllipsisWithTooltip',
                autoFit: true,
            },
            {
                field: 'address_district',
                headerText: 'Okres',
                filter: this.filterSettings,
                width: 130,
                maxWidth: 130,
                minWidth: 60,
                clipMode: 'EllipsisWithTooltip',
                autoFit: true,
            },
            {
                field: 'address_region',
                headerText: 'Kraj',
                filter: this.filterSettings,
                width: 130,
                maxWidth: 130,
                minWidth: 60,
                clipMode: 'EllipsisWithTooltip',
                autoFit: true,
            },
        ];

        this.marketingColumns = [
            {
                field: 'clip_frame',
                headerText: 'Klip rám',
                filter: this.filterSettings,
                width: 130,
                maxWidth: 130,
                minWidth: 60,
                clipMode: 'EllipsisWithTooltip',
                autoFit: true,
                template: this.templateClipBadges,
            },
            {
                field: 'marketingnote',
                headerText: 'Marketingová poznámka',
                filter: this.filterSettings,
                width: 200,
                maxWidth: 200,
                minWidth: 60,
                clipMode: 'EllipsisWithTooltip',
                autoFit: true,
            },
            {
                field: 'marketing_ads',
                headerText: 'Ostatní reklamy',
                filter: this.filterSettings,
                width: 200,
                maxWidth: 200,
                minWidth: 60,
                clipMode: 'EllipsisWithTooltip',
                autoFit: true,
            },
        ];

        this.serviceColumns = [
            {
                field: 'net_packages',
                headerText: 'NET Tarify',
                filter: this.filterSettings,
                width: 100,
                maxWidth: 250,
                minWidth: 60,
                clipMode: 'EllipsisWithTooltip',
                autoFit: true,
            },
            {
                field: 'tv_services',
                headerText: 'TV služby',
                filter: this.filterSettings,
                width: 150,
                maxWidth: 250,
                minWidth: 100,
                clipMode: 'EllipsisWithTooltip',
                autoFit: true,
            },
        ];

        this.dataNetbaseService.setFieldAddressDataSource();
    }

    loadData(): DataResult[] {
        this.gridFields.clearRowSelection();

        console.info('NEW DATA BOUND');

        const elements: FieldGridItems[] = [];

        if (this.fields && this.fields.length > 0) {
            // eslint-disable-next-line complexity
            this.fields.map((field: AddressConnectedField) => {
                let tvServices = '';
                let netPackages = '';

                field.net_packages.forEach(netPackage => {
                    netPackages += `${netPackage.name}, `;
                });

                field.tv_services?.forEach(tvPackage => {
                    tvServices += `${tvPackage.name}, `;
                });

                if (
                    this.currentUser &&
                    this.permissionService.checkUserISServiceAdmin(this.currentUser)
                ) {
                    elements.push({
                        id: field.id,
                        keys: field.keys ? 'ANO' : 'NE',
                        electrical_counter: field.electrical_counter,
                        clip_frame: field.clip_frame ? 'ANO' : 'NE',
                        business_note: field.business_note,
                        provider: field.provider.name,
                        project: field.project?.name,
                        realized_at: field.realized_at ? new Date(field.realized_at) : null,
                        technology: field.technology.name,
                        transfer: field.transfer.name,
                        descriptive: field.descriptive,
                        flat_space: field.flat_space
                            ? `${field.flat_space.metric_to} - ${field.flat_space.metric_until} m2`
                            : null,
                        discount: field.discount?.discount,
                        deal_type: field.deal_type?.age,
                        unit_type: field.unit_type?.name,
                        net_packages: netPackages,
                        tv_services: tvServices,
                        marketingnote: field.marketingnote,
                        marketing_ads: field.marketing_ads,
                        owner_name: field.owner_name,
                        manager_name: field.manager_name,
                        number_of_units: field.number_of_units,
                        number_of_storeys: field.number_of_storeys,
                        lift: field.lift ? 'ANO' : 'NE',
                        field_number: field.field_number,
                        address: field.address,
                        address_street: field.address_obj ? field.address_obj.street : null,
                        address_city: field.address_obj ? field.address_obj.city : null,
                        address_zip: field.address_obj ? field.address_obj.zip : null,
                        address_district: field.address_obj ? field.address_obj.district : null,
                        address_region: field.address_obj ? field.address_obj.region : null,
                        creator_id: field.created_by,
                        creator_str: `${field.creator.secondname} ${field.creator.firstname}`,
                        creator_img: field.creator.personalphoto,
                        created_date: new Date(field.created_date),
                        updated_date: field.updated_date ? new Date(field.updated_date) : null,
                        deleted_date: field.deleted_date ? new Date(field.deleted_date) : null,
                    });
                }
            });
        }

        this.fieldAddressLoad = false;
        this.ref.markForCheck();

        return elements as unknown as DataResult[];
    }

    onCreated(): void {
        this.disableToolBarBtn();
    }

    setInitialGridFiltering(): void {
        this.gridFields.clearFiltering();
        // this.gridFields.filterByColumn('created_by', 'equal', this.currentUser.id, 'and', false, true);
        // this.viewFilterInstance.value = 'moje žádanky - všechny';
        this.ref.markForCheck();
    }

    onLoad(): void {
        const wrapper1 = document.getElementById('scroll_wrapper1_fields');
        const wrapper2 = document.getElementById('scroll_wrapper2_fields');

        if (wrapper1 && wrapper2) {
            wrapper1.onscroll = (): void => {
                wrapper2.scrollLeft = wrapper1.scrollLeft;
            };

            wrapper2.onscroll = (): void => {
                wrapper1.scrollLeft = wrapper2.scrollLeft;
            };
        }

        this.dataNetbaseService.fieldAddressSource.pipe(untilDestroyed(this))
            .subscribe(
                (addresses: AddressConnectedField[] | undefined) => {
                    this.fields = addresses;

                    if (this.fields && this.fields.length > 0) {
                        this.data = this.gridFields.dataSource = this.loadData();
                        this.sortOptions = {
                            columns: [
                                {field: 'created_date', direction: 'Descending'},
                                {
                                    field: 'id',
                                    direction: 'Descending',
                                },
                            ],
                        };
                    } else {
                        this.fieldAddressLoad = false;
                    }
                },
                error => {
                    console.error(error);
                    this.fieldAddressLoad = false;
                    this.ref.markForCheck();
                },
            );
    }

    search(): void {
        this.gridFields.search(this.searchtextObj.value);
    }

    onDataBound(): void {
        this.rowSelected();

        const width = document.getElementById('fieldGrid_content_table')?.offsetWidth;
        const parent1 = document.getElementById('scroll_div_fields');
        const parent2 = document.getElementById('grid_parent_fields');

        if (parent1 && parent2 && width) {
            parent1.style.width = `${width}px`;
            parent2.style.width = `${width}px`;
        }

        this.gridFields.autoFitColumns();
        this.ref.markForCheck();
    }

    rowDataBound(args: RowDataBoundEventArgs): void {
        const data = args.data as AddressConnectedField;

        if (args.row && data.deleted_date) {
            args.row.getElementsByClassName('e-gridchkbox')[0].classList.add('disablecheckbox');
            args.row
                .getElementsByClassName('e-checkbox-wrapper')[0]
                .classList.add('disablecheckbox');
            args.row.classList.add('e-disabled');
        }

        if (
            this.currentUser &&
            args.row &&
            data.created_by !== this.currentUser.id &&
            !this.permissionService.checkUserISServiceAdmin(this.currentUser) &&
            !this.permissionService.checkUserISAdmin(this.currentUser)
        ) {
            args.row.getElementsByClassName('e-gridchkbox')[0].classList.add('disablecheckbox');
            args.row
                .getElementsByClassName('e-checkbox-wrapper')[0]
                .classList.add('disablecheckbox');
        }

        this.ref.markForCheck();
    }

    onStatusFilterChange(e: ChangeEventArgs): void {
        if (e.value === 'všechny adresy') {
            this.gridFields.clearFiltering();
        } else if (e.value === 'pouze aktivní adresy') {
            this.gridFields.clearFiltering();
            this.gridFields.filterByColumn('deleted_date', 'notequal', null, 'and', false, true);
        } else if (e.value === 'pouze smazané adresy') {
            this.gridFields.clearFiltering();
            this.gridFields.filterByColumn('deleted_date', 'notequal', null, 'and', false, true);
        }
    }

    cancelBtnClick(): void {
        this.searchtextObj.value = '';
        this.gridFields.searchSettings.key = '';
    }

    toolbarClick(args: ClickEventArgs): void {
        const xlsProp: ExcelExportProperties = {
            fileName: 'js_fields_export.xlsx',
            enableFilter: true,
            exportType: this.exportCurrentPage ? 'CurrentPage' : 'AllPages',
        };

        if (args.item.id === 'fieldGrid_excelexport') {
            void this.gridFields.excelExport(xlsProp);
        } else if (args.item.id === 'delete') {
            if (this.gridFields.getSelectedRecords().length !== 0) {
                this.confirmationDelete.content = `Potvrďte smazání ${this.clickedRow.length} parcel`;
                this.confirmationDelete.show();
            } else {
                this.confirmationCancel.content = 'Musíte vybrat alespoň jednu parcelu';
                this.confirmationCancel.show();
            }
        } else if (args.item.id === 'copy') {
            if (this.gridFields.getSelectedRecords().length !== 0) {
                this.isUpdate = false;
                this.isCreate = false;
                this.isCopy = true;
                this.renderFieldFormDialog = true;
                this.ref.markForCheck();
            } else {
                this.confirmationCancel.content = 'Musíte vybrat jednu parcelu';
                this.confirmationCancel.show();
            }
        } else if (args.item.id === 'ruian') {
            if (this.gridFields.getSelectedRecords().length !== 0) {
                this.renderCheckRuianFormDialog = true;
                this.ref.markForCheck();
            } else {
                this.confirmationCancel.content = 'Musíte vybrat jednu parcelu';
                this.confirmationCancel.show();
            }
        } else if (args.item.id === 'detail') {
            void this.router.navigate(['/services/field/detail/', this.clickedRow[0].id]);
        } else if (args.item.id === 'customRefresh') {
            this.fieldAddressLoad = true;
            console.info('refresh DATA!');
            this.ref.markForCheck();
            this.dataNetbaseService.clearFieldAddressCache();
            this.dataNetbaseService.setFieldAddressDataSource();
        }
    }

    enableToolbarBtn(): void {
        if (this.currentUser && this.permissionService.checkUserISServiceAdmin(this.currentUser)) {
            this.gridFields.toolbarModule.enableItems(
                ['fieldGrid_edit', 'delete', 'copy', 'detail', 'ruian'],
                true,
            );
        } else {
            this.gridFields.toolbarModule.enableItems(['detail'], true);
        }
    }

    disableToolBarBtn(): void {
        this.gridFields.toolbarModule.enableItems(
            ['fieldGrid_edit', 'delete', 'copy', 'detail', 'ruian'],
            false,
        );
    }

    rowSelected(): void {
        this.clickedRow = [];

        const selectedrecords: AddressConnectedField[] =
            this.gridFields.getSelectedRecords() as AddressConnectedField[];

        if (selectedrecords.length > 0) {
            selectedrecords.map((row: AddressConnectedField) => {
                const tempData: AddressConnectedField | undefined = this.fields?.find(
                    x => x.id === row.id,
                );

                if (tempData) {
                    this.clickedRow.push(tempData);
                }

                this.ref.markForCheck();
            });

            this.clickedRow$ = new Observable<AddressConnectedField[]>(
                (observer: Observer<AddressConnectedField[]>) => {
                    observer.next(this.clickedRow);
                },
            );
            this.enableToolbarBtn();
        } else {
            this.disableToolBarBtn();
            this.clickedRow$ = new Observable<AddressConnectedField[]>(
                (observer: Observer<AddressConnectedField[]>) => {
                    observer.next(this.clickedRow);
                },
            );
        }
    }

    actionBegin(args): void {
        if (args.requestType === 'add' || args.requestType === 'beginEdit') {
            args.cancel = true;

            if (args.requestType === 'beginEdit') {
                this.isUpdate = true;
                this.isCreate = false;
                this.isCopy = false;
            }

            if (args.requestType === 'add') {
                this.isCreate = true;
                this.isUpdate = false;
                this.isCopy = false;
            }

            this.renderFieldFormDialog = true;
            this.ref.markForCheck();
        }
    }

    actionComplete(args): void {
        if (args.requestType === 'beginEdit' || args.requestType === 'add') {
            args.form.ej2_instances[0].rules = {};
        }
    }

    async deleteFIELD(): Promise<void> {
        const promises: Promise<AddressConnectedField | undefined>[] = [];
        let rejects = 0;

        this.fieldAddressLoad = true;
        this.ref.markForCheck();

        for (const row of this.clickedRow) {
            if (
                this.currentUser &&
                !row.deleted_date &&
                this.permissionService.checkUserISServiceAdmin(this.currentUser)
            ) {
                row.deleted_by = this.currentUser.id;

                const declineObj = this.servicesService.deleteAddressField(row)
                    ?.toPromise();

                if (declineObj) {
                    promises.push(declineObj);
                }
            } else {
                rejects++;
            }
        }

        await Promise.all(promises)
            .then(result => {
                if (result.length > 0) {
                    const body = `Celkem smazáno: ${result.length} parcel`;
                    const options = {progressBar: true, timeOut: 5000};

                    this.messages.showSuccess('Smazání úspěšně dokončeno', body, options);
                }

                if (rejects > 0) {
                    const body = `Celkem u: ${rejects} parcel`;
                    const options = {progressBar: true, timeOut: 5000};

                    this.messages.showError('Nedostatečné oprávnění', body, options);
                }

                this.gridFields.clearRowSelection();
                this.dataNetbaseService.setFieldAddressDataSource();
                this.rowSelected();
                this.fieldAddressLoad = false;
                this.confirmationDelete.hide();
                this.ref.markForCheck();
            });
    }

    newProject(): void {
        if (this.currentUser && this.permissionService.checkUserISServiceAdmin(this.currentUser)) {
            this.isProjectCopy = false;
            this.isProjectCreate = true;
            this.isProjectUpdate = false;
            this.renderProjectFormDialog = true;
        } else {
            const body = 'Nemáte oprávnění provést tuto akci...';
            const options = {progressBar: true, timeOut: 5000};

            this.messages.showError('Nedostatečné oprávnění', body, options);
            this.fieldAddressLoad = false;
            this.ref.markForCheck();
        }
    }
}
