import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import {OrderItem} from '@src/app/_models/material/material-item';
import {Router} from '@angular/router';
import {AuthenticationService} from '@src/app/_services/authentication.service';
import {Material} from '@src/app/_models/material/material';
import {MaterialBudget} from '@src/app/_models/material/material-budget';
import {User} from '@src/app/_models/user/user';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {FormBuilder} from '@angular/forms';
import {DataService} from '@src/app/_services/data.service';
import {PermissionService} from '@src/app/_services/permission.service';
import {DialogComponent} from '@syncfusion/ej2-angular-popups';
import {
    ColumnModel,
    DataResult,
    EditSettingsModel,
    ExcelExportProperties,
    FilterSettingsModel,
    GridComponent,
    GroupSettingsModel,
    PageSettingsModel,
    RowDataBoundEventArgs,
    SelectionSettingsModel,
    ToolbarItems,
} from '@syncfusion/ej2-angular-grids';
import {TextBoxComponent} from '@syncfusion/ej2-angular-inputs';
import {ChangeEventArgs, DropDownListComponent} from '@syncfusion/ej2-angular-dropdowns';
import {ClickEventArgs} from '@syncfusion/ej2-angular-navigations';
import {MaterialService, OrderItemGridItems} from '@src/app/features/material/material.service';

@UntilDestroy()
@Component({
    selector: 'app-budget-items',
    templateUrl: './budget-items.component.html',
    styleUrls: ['./budget-items.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BudgetItemsComponent implements OnInit, OnChanges {
    // Grid
    currencyFormat: {[key: string]: string} = {format: '#,###.00\',- CZK\''};

    dateFormat: string;

    toolbar: ToolbarItems[] | object;

    data: object;

    fields: object = {text: 'label', value: 'value'};

    filterSettings: FilterSettingsModel;

    filterCheckboxSettings: FilterSettingsModel;

    selectionSettings: SelectionSettingsModel;

    editSettings: EditSettingsModel;

    groupOptions: GroupSettingsModel;

    pageOptions: PageSettingsModel;

    expendituresColumnsPrice: ColumnModel[];

    expendituresColumnsItems: ColumnModel[];

    filterDate: object;

    sortOptions: object;

    requiredRules: object;

    exportCurrentPage = false;

    // Dropdowns
    height = '240px';

    categoryFilterData: Array<string> = [];

    // Variables
    currentUser: User | null;

    items: OrderItem[] = [];

    material: Material;

    // Loaders
    itemsLoading = false;

    @Input() budget: MaterialBudget | null = null;

    @Input() isVisible = false;

    @Output() readonly budgetItemsFormDialogState = new EventEmitter<boolean>();

    // Grid
    @ViewChild('itemsGrid') gridItems: GridComponent;

    @ViewChild('searchtext') searchtextObj: TextBoxComponent;

    // Dialogs
    @ViewChild('formDialog') formDialogObj: DialogComponent;

    // Dropdowns
    @ViewChild('viewFilter') viewFilterInstance: DropDownListComponent;

    // Dialogs
    @ViewChild('itemsDialog') itemsDialogObj: DialogComponent;

    constructor(
        private readonly router: Router,
        private readonly ref: ChangeDetectorRef,
        private readonly formBuilder: FormBuilder,
        private readonly dataService: DataService,
        private readonly materialService: MaterialService,
        private readonly permissionService: PermissionService,
        private readonly authenticationService: AuthenticationService,
    ) {
        this.currentUser = this.authenticationService.currentUserValue;
    }

    ngOnInit(): void {
        this.filterSettings = {
            type: 'Menu',
            showFilterBarStatus: true,
            ignoreAccent: true,
        };

        this.filterCheckboxSettings = {
            type: 'CheckBox',
            showFilterBarStatus: true,
            ignoreAccent: true,
        };

        this.filterSettings = {
            type: 'Excel',
            showFilterBarOperator: true,
            showFilterBarStatus: true,
            ignoreAccent: true,
        };

        this.selectionSettings = {
            persistSelection: true,
            type: 'Multiple',
            checkboxOnly: true,
        };

        this.toolbar = [
            {
                text: 'Občerstvit data',
                tooltipText: 'Občerstvit data',
                prefixIcon: 'e-refresh',
                id: 'customRefresh',
            },
            'Print',
            'ExcelExport',
            {
                text: 'Detail',
                tooltipText: 'Detail objednávky',
                prefixIcon: 'e-openfolder',
                id: 'detail',
            },
        ];

        this.categoryFilterData = [
            'všechny položky objednávek',
            'fronta položek objednávek',
            'schválené položky objednávek',
            'zamítnuté položky objednávek',
            'stornované položky objednávek',
        ];
        this.pageOptions = {pageSize: 50};
        this.dateFormat = 'dd.MM.yyyy HH:mm';
        this.filterDate = {type: 'Menu', params: {format: this.dateFormat}};
        this.groupOptions = {
            showGroupedColumn: true,
            disablePageWiseAggregates: false,
        };

        this.editSettings = {
            showConfirmDialog: false,
            showDeleteConfirmDialog: false,
            allowEditing: false,
            allowAdding: false,
            allowDeleting: false,
            allowEditOnDblClick: false,
            mode: 'Dialog',
        };
        this.requiredRules = {required: true};
    }

    ngOnChanges(): void {
        if (this.budget) {
            this.formDialogObj.header = `Rozpočet #${this.budget.id} - ${this.budget.name}: položky`;
            this.materialService
                .getBudgetItem(this.budget.id)
                .pipe(untilDestroyed(this))
                .subscribe(
                    (items: OrderItem[]) => {
                        this.items = items;
                        this.data = this.gridItems.dataSource = this.loadData();
                        this.sortOptions = {
                            columns: [
                                {field: 'created_date', direction: 'Descending'},
                                {
                                    field: 'id',
                                    direction: 'Descending',
                                },
                            ],
                        };
                    },
                    error => {
                        console.error(error);
                        this.itemsLoading = false;
                        this.ref.markForCheck();
                    },
                );
        }
    }

    changeDialogState(value: boolean): void {
        this.budgetItemsFormDialogState.emit(value);
    }

    loadData(): DataResult[] {
        this.gridItems.clearRowSelection();

        console.info('NEW DATA BOUND');

        const elements: OrderItemGridItems[] = [];

        // eslint-disable-next-line complexity
        this.items.map((item: OrderItem) => {
            item.confirmed = false;
            item.declined = false;

            let decline = 0;
            let submit = 0;
            const reqSubmits = item.budget.approvers.length;

            item.budget.approvers.forEach((approver: User) => {
                item.order.confirmers.forEach((confirmer: User) => {
                    if (
                        approver.id === confirmer.id &&
                        confirmer.material_assignation_users?.confirmed_date
                    ) {
                        submit = submit + 1;
                    }

                    if (
                        approver.id === confirmer.id &&
                        confirmer.material_assignation_users?.declined_date
                    ) {
                        decline = decline + 1;
                    }
                });
            });

            if (submit > 0 && decline === 0 && submit === reqSubmits) {
                item.confirmed = true;
            }

            if (decline > 0) {
                item.declined = true;
            }

            if (item.confirmed && !item.deleted_date) {
                item.status = 'schváleno';
            }

            if (item.declined && !item.deleted_date) {
                item.status = 'zamítnuto';
            }

            if (item.deleted_date) {
                item.status = 'stornováno';
            }

            if (!item.declined && !item.confirmed && !item.deleted_date) {
                item.status = 've frontě';
            }

            elements.push({
                id: item.id,
                created_by: item.order.creator.id,
                creator_str: item.order.creator.fullname,
                creator_img: item.order.creator.personalphoto,
                name: item.name,
                amount: item.amount,
                price: item.dph ? item.price - item.price * 0.21 : item.price,
                dph: !!item.dph,
                category: item.category.name,
                status: item.status,
                budget: item.budget.name,
                department: item.budget.department.name,
                company: item.budget.department.company.name,
                material_id: item.material_id,
                to_storage: item.order.to_storage,
                created_date: new Date(item.created_date),
                updated_date: item.updated_date ? new Date(item.updated_date) : null,
                deleted_date: item.deleted_date ? new Date(item.deleted_date) : null,
            });
        });
        this.itemsLoading = false;

        return elements as unknown as DataResult[];
    }

    setInitialGridFiltering(): void {
        this.gridItems.clearFiltering();
        // this.gridItems.filterByColumn('created_by', 'equal', this.currentUser.id, 'and', false, true);
        // this.viewFilterInstance.value = 'moje žádanky - všechny';
        this.ref.markForCheck();
    }

    onLoad(): void {
        const wrapper1 = document.getElementById('scroll_wrapper1_items');
        const wrapper2 = document.getElementById('scroll_wrapper2_items');

        if (wrapper1 && wrapper2) {
            wrapper1.onscroll = (): void => {
                wrapper2.scrollLeft = wrapper1.scrollLeft;
            };

            wrapper2.onscroll = (): void => {
                wrapper1.scrollLeft = wrapper2.scrollLeft;
            };
        }

        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        if (this.gridItems) {
            this.data = this.gridItems.dataSource = this.loadData();
            this.sortOptions = {
                columns: [
                    {field: 'created_date', direction: 'Descending'},
                    {
                        field: 'id',
                        direction: 'Descending',
                    },
                ],
            };
        }
    }

    search(): void {
        this.gridItems.search(this.searchtextObj.value);
    }

    rowDataBound(args: RowDataBoundEventArgs): void {
        const data = args.data as OrderItem;

        if (args.row && data.deleted_date) {
            args.row.getElementsByClassName('e-gridchkbox')[0].classList.add('disablecheckbox');
            args.row
                .getElementsByClassName('e-checkbox-wrapper')[0]
                .classList.add('disablecheckbox');
            args.row.classList.add('e-disabled');
        }

        if (
            this.currentUser &&
            args.row &&
            !this.permissionService.checkUserISBudgetAdmin(this.currentUser)
        ) {
            args.row.getElementsByClassName('e-gridchkbox')[0].classList.add('disablecheckbox');
            args.row
                .getElementsByClassName('e-checkbox-wrapper')[0]
                .classList.add('disablecheckbox');
        }

        this.ref.markForCheck();
    }

    onStatusFilterChange(e: ChangeEventArgs): void {
        if (e.value === 'všechny rozpočty') {
            this.gridItems.clearFiltering();
        } else if (e.value === 'aktivní rozpočty') {
            this.gridItems.filterSettings = {
                columns: [
                    {
                        field: 'expire_at',
                        operator: 'equal',
                        value: null as unknown as string,
                        predicate: 'and',
                        matchCase: false,
                        ignoreAccent: true,
                    },
                    {
                        field: 'deleted_date',
                        operator: 'notequal',
                        value: null as unknown as string,
                        predicate: 'and',
                        matchCase: false,
                        ignoreAccent: true,
                    },
                ],
            };
        } else if (e.value === 'expirované rozpočty') {
            this.gridItems.clearFiltering();
            this.gridItems.filterByColumn('expire_at', 'notequal', '', 'and', false, true);
        } else if (e.value === 'smazané rozpočty') {
            this.gridItems.clearFiltering();
            this.gridItems.filterByColumn('deleted_date', 'notequal', null, 'and', false, true);
        }
    }

    cancelBtnClick(): void {
        this.searchtextObj.value = '';
        this.gridItems.searchSettings.key = '';
    }

    toolbarClick(args: ClickEventArgs): void {
        const xlsProp: ExcelExportProperties = {
            fileName: 'js_budget_items_export.xlsx',
            enableFilter: true,
            exportType: this.exportCurrentPage ? 'CurrentPage' : 'AllPages',
        };

        if (args.item.id === 'itemsGrid_excelexport') {
            void this.gridItems.excelExport(xlsProp);
        } else if (args.item.id === 'customRefresh') {
            this.itemsLoading = true;
            console.info('refresh DATA!');
            this.ref.markForCheck();
            this.dataService.setMaterialsItemsDataSource();
        }
    }
}
