import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    HostListener,
    Input,
    OnChanges,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import {HotlineReport} from '@src/app/_models/hotline/report';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {map, Observable} from 'rxjs';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {AuthenticationService} from '@src/app/_services/authentication.service';
import {PermissionService} from '@src/app/_services/permission.service';
import {MessageService} from '@src/app/_services/message.service';
import {DataService} from '@src/app/_services/data.service';
import {Hotline} from '@src/app/_models/hotline/hotline';
import {ComponentCanDeactivate} from '@src/app/_guards/changes.guard';
import {registerLocaleData} from '@angular/common';
import {User} from '@src/app/_models/user/user';
import {
    FontFamilyModel,
    ImageSettingsModel,
    QuickToolbarSettingsModel,
    RichTextEditorComponent,
} from '@syncfusion/ej2-angular-richtexteditor';
import {ToolbarModule} from '@syncfusion/ej2-angular-navigations';
import moment from 'moment';
import localeCs from '@angular/common/locales/cs';
import {addClass, Browser, createElement, EmitType, removeClass} from '@syncfusion/ej2-base';
import {DropDownListComponent, FilteringEventArgs} from '@syncfusion/ej2-angular-dropdowns';
import {Query} from '@syncfusion/ej2-data';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {DialogComponent} from '@syncfusion/ej2-angular-popups';
import {EnvironmentService} from '@src/app/_services/environment.service';
import {HotlineReportInput, HotlineService} from '@src/app/features/hotline/hotline.service';

moment.updateLocale('cs', {workingWeekdays: [1, 2, 3, 4, 5]});
registerLocaleData(localeCs);

@UntilDestroy()
@Component({
    selector: 'app-hotline-report-form',
    templateUrl: './hotline-report-form.component.html',
    styleUrls: ['./hotline-report-form.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HotlineReportFormComponent implements OnInit, OnChanges, ComponentCanDeactivate {
    height = '240px';

    fields: object = {text: 'label', value: 'value'};

    hotlineSelect: Array<{value: boolean | number | string; label: string}> = [];

    categorySelect: Array<{value: boolean | number | string; label: string}> = [];

    // RTE
    maxLength = 15000;

    textArea: HTMLElement;

    insertImageSettings: ImageSettingsModel;

    fontFamily: FontFamilyModel = {
        items: [
            {
                text: 'Segoe UI',
                value: 'Segoe UI',
                command: 'Font',
                subCommand: 'FontName',
            },
            {
                text: 'Roboto',
                value: 'Roboto',
                command: 'Font',
                subCommand: 'FontName',
            },
            {
                text: 'Great vibes',
                value: 'Great Vibes,cursive',
                command: 'Font',
                subCommand: 'FontName',
            },
            {
                text: 'Noto Sans',
                value: 'Noto Sans',
                command: 'Font',
                subCommand: 'FontName',
            },
            {
                text: 'Impact',
                value: 'Impact,Charcoal,sans-serif',
                command: 'Font',
                subCommand: 'FontName',
            },
            {
                text: 'Tahoma',
                value: 'Tahoma,Geneva,sans-serif',
                command: 'Font',
                subCommand: 'FontName',
            },
        ],
    };

    tools: ToolbarModule = {
        type: 'MultiRow',
        items: [
            'Bold',
            'Italic',
            'Underline',
            'StrikeThrough',
            'FontName',
            'FontSize',
            'FontColor',
            'BackgroundColor',
            'LowerCase',
            'UpperCase',
            'SuperScript',
            'SubScript',
            '|',
            'Formats',
            'Alignments',
            'OrderedList',
            'UnorderedList',
            'Outdent',
            'Indent',
            '|',
            'CreateTable',
            'CreateLink',
            'Image',
            '|',
            'ClearFormat',
            'Print',
            'SourceCode',
            'FullScreen',
            '|',
            'Undo',
            'Redo',
        ],
    };

    quickToolbarSettings: QuickToolbarSettingsModel = {
        table: [
            'TableHeader',
            'TableRows',
            'TableColumns',
            'TableCell',
            '-',
            'BackgroundColor',
            'TableRemove',
            'TableCellVerticalAlign',
            'Styles',
        ],
    };

    pasteCleanupSettings: object = {
        prompt: true,
        plainText: false,
        keepFormat: false,
        deniedTags: ['a'],
        deniedAttrs: ['class', 'title', 'id'],
        allowedStyleProps: ['color', 'margin', 'font-size'],
    };

    // Forms
    reportForm: FormGroup;

    isDirty = false;

    isChecked = false;

    submited = false;

    // Variables
    currentUser: User | null;

    hotlines: Hotline[];

    reports: HotlineReport[];

    // Loaders
    loadingReport = false;

    @Input() report?: HotlineReport | null = null;

    @Input() hotline?: Hotline | null = null;

    @Input() isCreate = false;

    @Input() isUpdate = false;

    @Input() isVisible = false;

    @Input() isCopy = false;

    @Output() readonly hotlineReportFormDialogState = new EventEmitter<boolean>();

    // Dialogs
    @ViewChild('formDialog') formDialogObj: DialogComponent;

    // Dropdowms
    @ViewChild('hotline') hotlineObj: DropDownListComponent;

    // RTE
    @ViewChild('reportText') reportTextObj?: RichTextEditorComponent;

    constructor(
        private readonly router: Router,
        private readonly route: ActivatedRoute,
        private readonly ref: ChangeDetectorRef,
        private readonly formBuilder: FormBuilder,
        private readonly authenticationService: AuthenticationService,
        private readonly hotlineService: HotlineService,
        private readonly permissionService: PermissionService,
        private readonly messages: MessageService,
        private readonly dataService: DataService,
        private readonly environmentService: EnvironmentService,
    ) {
        this.currentUser = this.authenticationService.currentUserValue;
        this.insertImageSettings = {
            path: this.environmentService.backendURL + '/api/file/preview/hotline/',
            saveUrl:
                this.environmentService.backendURL +
                '/api/upload/attachments/hotline?type=attachments&subfolder=hotline',
        };
    }

    get f(): {[p: string]: AbstractControl} {
        return this.reportForm.controls;
    }

    @HostListener('window:beforeunload')
    canDeactivate(): Observable<boolean> | boolean {
        return !this.isDirty;
    }

    ngOnInit(): void {
        this.dataService.hotlineSource
            .pipe(
                map((results: Hotline[]) =>
                    results.sort((a: Hotline, b: Hotline) => {
                        const dateA = moment(a.start_at)
                            .valueOf();
                        const dateB = moment(b.start_at)
                            .valueOf();

                        return dateA > dateB ? -1 : 1;
                    }),
                ),
                untilDestroyed(this),
            )
            .subscribe(
                (data: Hotline[]) => {
                    this.hotlineSelect = [];
                    this.hotlines = data;

                    data.sort((a, b) => {
                        const dateA = moment(a.start_at)
                            .valueOf();
                        const dateB = moment(b.start_at)
                            .valueOf();

                        return dateA > dateB ? -1 : 1;
                    });

                    data.forEach((hotline: Hotline) => {
                        if (
                            !hotline.deleted_date &&
                            hotline.hotliner.user.id === this.currentUser?.id
                        ) {
                            const typeTrnslt =
                                hotline.type === 'technician' ? 'služba' : 'příslužba';

                            this.hotlineSelect = [
                                ...this.hotlineSelect,
                                {
                                    value: hotline.id,
                                    label:
                                        typeTrnslt +
                                        ': ' +
                                        hotline.hotliner.user.firstname +
                                        ' ' +
                                        hotline.hotliner.user.secondname +
                                        ' ' +
                                        moment(hotline.start_at)
                                            .format('DD.MM.YYYY') +
                                        ' - ' +
                                        moment(hotline.end_at)
                                            .format('DD.MM.YYYY'),
                                },
                            ];
                        }
                    });
                    this.ref.markForCheck();
                },
                error => {
                    console.error(error);
                },
            );
        this.dataService.setHotlinesDataSource();

        this.reportForm = this.formBuilder.group({
            reportSubject: [
                '',
                Validators.compose([
                    Validators.required,
                    Validators.minLength(2),
                    Validators.maxLength(100),
                ]),
            ],
            reportText: ['', Validators.required],
            hotline: ['', Validators.required],
        });

        this.reportForm
            .get('hotline')
            ?.valueChanges
            .pipe(untilDestroyed(this))
            .subscribe(value => {
                if (value) {
                    this.hotline = this.hotlines.find(x => x.id === value);
                    // eslint-disable-next-line max-len
                    this.reportForm.controls.reportSubject.patchValue(
                        `Zápis hot-line ze dne: ${moment(this.hotline?.start_at)
                            .format(
                                'DD.MM.YYYY',
                            )} - ${moment(this.hotline?.end_at)
                            .format('DD.MM.YYYY')}`,
                    );
                    this.ref.markForCheck();
                }
            });

        this.reportForm.valueChanges.pipe(untilDestroyed(this))
            .subscribe(() => {
                this.isDirty = this.reportForm.dirty;
                this.showInputErrors();
            });
    }

    onFilteringHotline: EmitType<FilteringEventArgs> = (e: FilteringEventArgs) => {
        if (e.text === '') {
            e.updateData(this.hotlineSelect);
        } else {
            let query: Query = new Query();

            query = e.text !== '' ? query.where('label', 'contains', e.text, true, true) : query;
            e.updateData(this.hotlineSelect, query);
        }
    };

    ngOnChanges(): void {
        if (this.isVisible) {
            const queryParams: Params = {form: null};

            void this.router.navigate([], {
                relativeTo: this.route,
                queryParams,
                queryParamsHandling: 'merge',
            });

            if (this.isUpdate && this.report) {
                this.reportForm.reset();
                this.formDialogObj.header = `Report hot-line #${this.report.id}`;
                this.fillTheForm();
                this.showInputErrors();
            }

            if (this.isCreate) {
                this.reportForm.reset();
                this.formDialogObj.header = 'Nový report hot-line';

                if (this.hotline) {
                    this.fillTheForm();
                }

                this.showInputErrors();
            }

            if (this.isCopy) {
                this.reportForm.reset();
                this.formDialogObj.header = 'Nový report hot-line';
                this.fillTheForm();
                this.showInputErrors();
            }
        }
    }

    changeDialogState(value: boolean): void {
        this.hotlineReportFormDialogState.emit(value);
    }

    fillTheForm(): void {
        this.reportForm.controls.hotline.patchValue(this.hotline?.id);

        if (this.report && (this.isUpdate || this.isCopy)) {
            this.reportForm.controls.reportText.patchValue(this.report.report);
            this.reportForm.controls.reportSubject.patchValue(this.report.subject);
        }

        this.ref.markForCheck();
    }

    onSubmit(): void {
        this.submited = true;
        this.isDirty = false;
        this.loadingReport = true;

        if (this.isCreate || this.isCopy) {
            this.createReport();
        }

        if (this.isUpdate) {
            this.editReport();
        }
    }

    createReport(): void {
        if (this.reportForm.invalid) {
            console.error('form is not valid!');

            return;
        }

        const report: HotlineReportInput = {
            subject: this.f.reportSubject.value,
            report: this.f.reportText.value,
            created_by: this.currentUser?.id,
            hotline_id: this.f.hotline.value,
        };

        this.hotlineService
            .addReport(report)
            .pipe(untilDestroyed(this))
            .subscribe({
                next: (data: HotlineReport) => {
                    const body =
                        'Zápis hot-line: ' +
                        moment(data.hotline.start_at)
                            .format('DD.MM.YYYY') +
                        ' - ' +
                        moment(data.hotline.end_at)
                            .format('DD.MM.YYYY');
                    const options = {progressBar: true, timeOut: 5000};

                    this.messages.showSuccess('Zápis úspěšně vytvořen', body, options);
                    this.dataService.setHotlinesDataSource();
                    this.dataService.setHotlineDetailDataSource(data.hotline.id);
                    this.isCreate = false;
                    this.loadingReport = false;
                    this.ref.markForCheck();
                    this.formDialogObj.hide();
                    void this.router.navigate(['/hotline/detail', data.hotline.id]);
                },
                error: error => {
                    console.error(error);

                    const body = 'Zkuste to znovu později';
                    const options = {progressBar: true, timeOut: 5000};

                    this.messages.showError('Chyba při vytváření zápisu hot-line,', body, options);
                    this.loadingReport = false;
                    this.ref.markForCheck();
                },
            });
    }

    editReport(): void {
        if (!this.report) {
            console.error('this.report is missing...');

            return;
        }

        if (this.reportForm.invalid) {
            console.error('form is not valid!');

            return;
        }

        const report: HotlineReportInput = {
            id: this.report.id,
            subject: this.f.reportSubject.value,
            report: this.f.reportText.value,
            updated_by: this.currentUser?.id,
            hotline_id: this.f.hotline.value,
        };

        this.hotlineService
            .updateReport(report)
            ?.pipe(untilDestroyed(this))
            .subscribe({
                next: (data: HotlineReport) => {
                    const body =
                        'Zápis hot-line: ' +
                        moment(data.hotline.start_at)
                            .format('DD.MM.YYYY') +
                        ' - ' +
                        moment(data.hotline.end_at)
                            .format('DD.MM.YYYY');
                    const options = {progressBar: true, timeOut: 5000};

                    this.messages.showSuccess('Zápis úspěšně upraven', body, options);
                    this.dataService.setHotlinesDataSource();
                    this.dataService.setHotlineDetailDataSource(data.hotline.id);
                    this.isUpdate = false;
                    this.loadingReport = false;
                    this.ref.markForCheck();
                    this.formDialogObj.hide();
                    void this.router.navigate(['/hotline/detail', data.hotline.id]);
                },
                error: error => {
                    console.error(error);

                    const body = 'Zkuste to znovu později';
                    const options = {progressBar: true, timeOut: 5000};

                    this.messages.showError('Chyba při úpravě zápisu hot-line,', body, options);
                    this.loadingReport = false;
                    this.ref.markForCheck();
                },
            });
    }

    // RTE
    onCreateRte(): void {
        this.reportTextObj?.refreshUI();
        this.reportTextObj?.autoResize();
    }

    handleFullScreen(e: unknown): void {
        const sbCntEle: HTMLElement | null = document.querySelector('.sb-content.e-view');
        const sbHdrEle: HTMLElement | null = document.querySelector('.sb-header.e-view');
        const leftBar: HTMLElement | null = document.querySelector('#left-sidebar');

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        if (e.targetItem === 'Maximize') {
            if (Browser.isDevice && Browser.isIos && sbCntEle && sbHdrEle) {
                addClass([sbCntEle, sbHdrEle], ['hide-header']);
            }

            if (leftBar) {
                addClass([leftBar], ['e-close']);
                removeClass([leftBar], ['e-open']);
            }
        }
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        else if (e.targetItem === 'Minimize') {
            if (Browser.isDevice && Browser.isIos && sbCntEle && sbHdrEle) {
                removeClass([sbCntEle, sbHdrEle], ['hide-header']);
            }

            if (leftBar) {
                removeClass([leftBar], ['e-close']);

                if (!Browser.isDevice) {
                    addClass([leftBar], ['e-open']);
                }
            }
        }
    }

    mirrorConversion(e: unknown): void {
        if (this.reportTextObj) {
            const id = `${this.reportTextObj.getID()}mirror-view`;
            let mirrorView: HTMLElement | null = this.reportTextObj.element.querySelector('#' + id);
            const charCount: HTMLElement | null =
                this.reportTextObj.element.querySelector('.e-rte-character-count');

            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            if (e.targetItem === 'Preview' && mirrorView && charCount) {
                this.textArea.style.display = 'block';
                mirrorView.style.display = 'none';
                charCount.style.display = 'block';
            } else {
                if (!mirrorView && this.textArea.parentNode) {
                    mirrorView = createElement('div', {className: 'e-content'});
                    mirrorView.id = id;
                    this.textArea.parentNode.appendChild(mirrorView);
                } else if (mirrorView) {
                    mirrorView.innerHTML = '';
                }

                if (mirrorView) {
                    this.textArea.style.display = 'none';
                    mirrorView.style.display = 'block';
                }

                if (charCount) {
                    charCount.style.display = 'none';
                }
            }
        }
    }

    actionCompleteHandler(e: unknown): void {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        if (e.targetItem && (e.targetItem === 'SourceCode' || e.targetItem === 'Preview')) {
            (this.reportTextObj?.sourceCodeModule.getPanel() as HTMLTextAreaElement).style.display =
                'none';
            this.mirrorConversion(e);
        } else {
            setTimeout(() => {
                this.reportTextObj?.toolbarModule.refreshToolbarOverflow();
            }, 400);
        }
    }

    showInputErrors(): void {
        this.isChecked = true;
        /* console.log('VALID: ' + this.ticketForm.valid);
      Object.keys(this.ticketForm.controls).forEach(key => {
          const controlErrors: ValidationErrors = this.ticketForm.get(key).errors;
          if (controlErrors != null) {
              Object.keys(controlErrors).forEach(keyError => {
                  console.log('Key control: ' + key + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError]);
              });
          }
      });*/
        this.reportForm.markAllAsTouched();
        this.ref.markForCheck();
    }
}
