import {APP_INITIALIZER} from '@angular/core';
import {AutologoutService} from '@src/app/_services/autologout.service';
import {AuthenticationService} from '@src/app/_services/authentication.service';

const initializeFactory =
    (
        autologoutService: AutologoutService,
        authenticationService: AuthenticationService,
    ): (() => void) =>
        (): void => {
            if (authenticationService.isLoggedIn) {
                autologoutService.check();
            }
        };

export const INITIALIZER_PROVIDER = {
    provide: APP_INITIALIZER,
    useFactory: initializeFactory,
    deps: [AutologoutService, AuthenticationService],
    multi: true,
};
