<ejs-dialog
  #deleteFileDialog
  [buttons]="buttonsDeleteFileDialog"
  [content]="'Opravdu chcete smazat tento soubor z pracovního postupu?'"
  [header]="'Smazat soubor z pracovního postupu'"
  [height]="'200px'"
  [isModal]="true"
  [showCloseIcon]="true"
  [visible]="false"
  [width]="'450px'"
  id="deleteFileDialog"
></ejs-dialog>

<ng-container *ngIf="loading; then FilesLoading; else FilesList"></ng-container>
<ng-template #FilesList>
  <div class="row">
    <div *ngIf="files.length > 0; else NoFilesAvailable" class="col-12">
      <h5>Seznam souborů</h5>
      <div class="files-block">
        <div
          *ngFor="let file of files | paginate: { id: 'files_list',itemsPerPage: pageSize,currentPage: pageFiles}; let i = index"
          class="file-item"
        >
          <div class="file-toolbar">
            <div class="file-desc">
              <ng-container
                *ngIf="
                                    file.extension === 'image/jpeg' ||
                                    file.extension === 'image/png'
                                "
              >
                <br />
                <img
                  (click)="open(i)"
                  [src]="file.url_thumbnail || file.url"
                  alt="imageFile"
                  height="40"
                  width="40"
                />
              </ng-container>
              <span>{{ file.name }}</span>
              <small class="text-muted">
                typ:
                {{
                  file.extension
                }}&nbsp;&middot;&nbsp;velikost:{{
                  file.size | filesize
                }}
              </small>
            </div>
            <div
              aria-label="File toolbar"
              class="file-buttons"
              role="group"
            >
              <ng-container
                *ngIf="
                                        file.extension ===
                                            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
                                        file.extension ===
                                            'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
                                        file.extension ===
                                            'application/msword' ||
                                        file.extension ===
                                            'application/vnd.ms-powerpoint' ||
                                        file.extension === 'text/csv' ||
                                        file.extension ===
                                            'application/vnd.ms-excel' ||
                                        file.extension === 'application/pdf'
                                    "
              >
                <button
                  (click)="
                                            selectViewer(viewer.name);
                                            selectedDoc = file.url;
                                            scrollToTarget('targetView')
                                        "
                  *ngFor="let viewer of viewers"
                  [class.active]="
                                            selectedViewer.name ===
                                                viewer.name &&
                                            selectedDoc === file.url
                                        "
                  cssClass="e-btn e-info e-outline btn-p-zero e-small"
                  ejs-button
                >
                  <i
                    *ngIf="viewer.name === 'google'"
                    aria-hidden="true"
                    class="fab fa-google mr-1"
                  ></i>
                  <i
                    *ngIf="viewer.name === 'office'"
                    aria-hidden="true"
                    class="fab fa-windows mr-1"
                  ></i>
                  <i
                    *ngIf="viewer.name === 'pdf'"
                    aria-hidden="true"
                    class="fa-light fa-file-pdf mr-1"
                  ></i>
                  {{ viewer.name }}
                </button>
              </ng-container>
              <button
                (click)="downloadFile(file)"
                cssClass="e-btn e-primary btn-p-zero e-small"
                ejs-button
              >
                <i
                  aria-hidden="true"
                  class="fa-light fa-download"
                ></i>
              </button>
              <button
                (click)="
                                        deleteFileId = file.id;
                                        deleteFileDialog.show()
                                    "
                cssClass="e-btn e-danger btn-p-zero e-small"
                ejs-button
              >
                <i
                  aria-hidden="true"
                  class="fa-light fa-trash"
                ></i>
              </button>
            </div>
          </div>
        </div>
      </div>
      <nav class="d-flex justify-content-center mt-5">
        <pagination-controls
          (pageChange)="pageFiles = $event"
          autoHide="true"
          directionLinks="true"
          id="files_list"
          maxSize="9"
          nextLabel="Další"
          previousLabel="Předchozí"
          responsive="true"
        ></pagination-controls>
      </nav>
      <h5>Galerie</h5>
      <div class="image-thumb-group">
        <img
          (click)="open(i)"
          *ngFor="let img of images; let i = index"
          [src]="img.thumb || img.src"
          alt="imageFile"
        />
      </div>
      <h5>Prohlížeč souborů</h5>
      <div class="d-flex">
        <ng-container *ngIf="selectedViewer.custom">
          <ejs-textbox
            #input
            cssClass="w-100 m-2"
            floatLabelType="Auto"
            placeholder="URL adresa souboru"
          ></ejs-textbox>
          <button
            (click)="selectedDoc = input.value"
            cssClass="e-btn e-info e-outline btn-p-zero e-small m-2"
            ejs-button
          >
            Ukaž
          </button>
        </ng-container>
      </div>
      <ngx-doc-viewer
        #targetView
        [url]="selectedDoc"
        [viewerUrl]="selectedViewer.viewerUrl"
        [viewer]="selectedViewer.name"
        id="targetView"
        queryParams="cs=CS"
        style="width: 100%; height: 80vh"
      >
      </ngx-doc-viewer>
    </div>
  </div>
</ng-template>
<ng-template #FilesLoading>
  <app-loader></app-loader>
</ng-template>
<ng-template #NoFilesAvailable>
  <h6 class="text-muted col-12 text-center">
    žádné soubory nebyly zatím přiloženy
  </h6>
</ng-template>
