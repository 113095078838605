import {ChangeDetectionStrategy, ChangeDetectorRef, Component, ViewChild} from '@angular/core';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {DataNetbaseService} from '@src/app/_services/data-netbase.service';
import {AuthenticationService} from '@src/app/_services/authentication.service';
import {TabComponent} from '@syncfusion/ej2-angular-navigations';
import {SelectEventArgs} from '@syncfusion/ej2-navigations';
import {User} from '@src/app/_models/user/user';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
    selector: 'app-service-searcher',
    templateUrl: './service-searcher.component.html',
    styleUrls: ['./service-searcher.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ServiceSearcherComponent {
    headerText = [
        {text: 'CallWizard', iconCss: 'fa-light fa-user-headset'},
        {text: 'Vyhledávač služeb', iconCss: 'fa-light fa-search'},
        {text: 'Leady', iconCss: 'fa-light fa-pennant'},
        {text: 'Objednávky', iconCss: 'fa-light fa-money-check-edit-alt'},
    ];

    currentUser: User | null;

    @ViewChild('seracherTab') seracherTabObj: TabComponent;

    constructor(
        private readonly router: Router,
        private readonly ref: ChangeDetectorRef,
        private readonly route: ActivatedRoute,
        private readonly dataNetbaseService: DataNetbaseService,
        private readonly authenticationService: AuthenticationService,
    ) {
        this.currentUser = this.authenticationService.currentUserValue;
    }

    onCreate(): void {
        // Nastavení GUI podle URL parametrů
        this.route.queryParamMap.pipe(untilDestroyed(this))
            .subscribe(queryParams => {
                if (queryParams.get('view') === 'callwizard') {
                    this.seracherTabObj.selectedItem = 0;
                } else if (queryParams.get('view') === 'searcher') {
                    this.seracherTabObj.selectedItem = 1;
                } else if (queryParams.get('view') === 'leads') {
                    this.seracherTabObj.selectedItem = 2;
                } else if (queryParams.get('view') === 'orders') {
                    this.seracherTabObj.selectedItem = 3;
                } else {
                    this.seracherTabObj.selectedItem = 0;
                }
            });
    }

    preventSwipe(e: SelectEventArgs): void {
        if (e.isSwiped) {
            e.cancel = true;
        }
    }

    select(e: SelectEventArgs): void {
        let queryParams: Params = {view: null};

        if (e.selectedItem.textContent === 'CallWizard') {
            queryParams = {
                view: 'callwizard',
            };
        }

        if (e.selectedItem.textContent === 'Vyhledávač služeb') {
            queryParams = {
                view: 'searcher',
            };
        }

        if (e.selectedItem.textContent === 'Leady') {
            queryParams = {
                view: 'leads',
            };
        }

        if (e.selectedItem.textContent === 'Objednávky') {
            queryParams = {
                view: 'orders',
            };
        }

        void this.router.navigate([], {
            relativeTo: this.route,
            queryParams,
            queryParamsHandling: 'merge',
        });
    }
}
