import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnInit,
    ViewChild,
} from '@angular/core';
import {AuthenticationService} from '@src/app/_services/authentication.service';
import {MessageService} from '@src/app/_services/message.service';
import {registerLocaleData} from '@angular/common';
import {Vacation} from '@src/app/_models/vacation/vacation';
import {DataService} from '@src/app/_services/data.service';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {PermissionService} from '@src/app/_services/permission.service';
import {TabComponent, ToolbarComponent} from '@syncfusion/ej2-angular-navigations';
import {SelectEventArgs} from '@syncfusion/ej2-navigations';

import moment from 'moment';
import localeCs from '@angular/common/locales/cs';
import {User} from '@src/app/_models/user/user';
import {AnimationModel, FontModel} from '@syncfusion/ej2-progressbar';
import {IProgressValueEventArgs, ProgressBarComponent} from '@syncfusion/ej2-angular-progressbar';
import {NavHandlerService} from '@src/app/_services/nav-handler.service';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {DashboardLayoutComponent} from '@syncfusion/ej2-angular-layouts';
import {ApiConnections} from '@src/app/_models/api/connections';
import {AcsUsers} from '@src/app/_models/_api_acs/users';
import {AcsUserLogs} from '@src/app/_models/_api_acs/logs';
import {AcsCategory} from '@src/app/_models/_api_acs/category';
import {ChartComponent} from '@syncfusion/ej2-angular-charts';
import {ApisService} from '@src/app/_api/apis.service';
import {AcsService} from '@src/app/_api/acs/acs.service';
import {VacationInput} from '@src/app/features/vacation/vacation.service';

moment.updateLocale('cs', {workingWeekdays: [1, 2, 3, 4, 5]});
registerLocaleData(localeCs);

@UntilDestroy()
@Component({
    selector: 'app-vacation',
    templateUrl: './vacation.component.html',
    styleUrls: ['./vacation.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VacationComponent implements OnInit {
    // DashBoard
    count = 8;

    cellSpacing: number[] = [10, 10];

    cellAspectRatio: number = 100 / 100;

    // Dialogs
    renderAccountantFormDialog = false;

    // Navigation
    headerText = [
        {text: 'tabulka'},
        {text: 'kalendář'},
        {text: 'statistiky'},
        {text: 'import'},
    ];

    // ProgressBars
    animation: AnimationModel;

    labelStyle: FontModel;

    showProgressValue: boolean;

    // Variables
    today = moment()
        .format('YYYY-MM-DD');

    currentUser: User | null;

    vacations?: Vacation[];

    stats: {[key: string]: {[key: string]: number}} = {
        vacation: {confirmed: 0, declined: 0, deleted: 0, queueCnt: 0},
        sickdays: {confirmed: 0, declined: 0, deleted: 0, queueCnt: 0},
        homeoffice: {confirmed: 0, declined: 0, deleted: 0, queueCnt: 0},
        dayoff: {confirmed: 0, declined: 0, deleted: 0, queueCnt: 0},
        doctor: {confirmed: 0, declined: 0, deleted: 0, queueCnt: 0},
        disease: {confirmed: 0, declined: 0, deleted: 0, queueCnt: 0},
        sicknote: {confirmed: 0, declined: 0, deleted: 0, queueCnt: 0},
    };

    acsState: {[key: string]: AcsUserLogs | boolean | null};

    arrivals: Array<{[key: string]: string}> = [];

    leaves: Array<{[key: string]: string}> = [];

    vacationsData: VacationInput[] = [];

    // eats-api data
    api: ApiConnections | null = null;

    acsUserLogs: AcsUserLogs[] = [];

    acsUser: AcsUsers | unknown | null | undefined = null;

    // Charts
    primaryXAxis: object;

    primaryYAxis: object;

    chartData: object;

    legendSettings: object;

    tooltip: object;

    datalabel: object;

    // Loaders
    loadingVacationWidget = true;

    loadingAcs = true;

    // Navigation
    @ViewChild('vacationTab') vacationTabObj?: TabComponent;

    // ProgressBars
    @ViewChild('sickDaysPercentage') sickDaysPercentageObj?: ProgressBarComponent;

    @ViewChild('vacationsPercentage')
    vacationsPercentageObj?: ProgressBarComponent;

    // Charts
    @ViewChild('vacationsChart') vacationsChart?: ChartComponent;

    // Maintoolbar
    @ViewChild('moduleToolbar') moduleToolbarObj?: ToolbarComponent;

    // DashBoard
    @ViewChild('default_dashboard') dashboard?: DashboardLayoutComponent;

    constructor(
        protected readonly authenticationService: AuthenticationService,
        protected readonly router: Router,
        protected readonly ref: ChangeDetectorRef,
        protected readonly route: ActivatedRoute,
        protected readonly apisService: ApisService,
        protected readonly acsService: AcsService,
        protected readonly navHandlerService: NavHandlerService,
        protected readonly dataService: DataService,
        protected readonly messages: MessageService,
        protected readonly permissionService: PermissionService,
    ) {
        this.currentUser = this.authenticationService.currentUserValue;
    }

    // Sestavení dat pro docházku z API (Acs)
    private loadAcsData(acsUser: AcsUsers | unknown | null | undefined): void {
        // Uložení parametru do proměnné komponenty
        this.acsUser = acsUser;

        if (this.acsUser && this.acsUser instanceof AcsUsers) {
            // Logy docházky z API
            this.acsService
                .getAcsLogs(this.acsUser.id, moment()
                    .format('M'), moment()
                    .year())
                .pipe(untilDestroyed(this))
                .subscribe({
                    next: (logs: AcsUserLogs[]) => {
                        // Uložení do proměnné komponenty + seřazení podle data začátku logu
                        this.acsUserLogs = logs.sort((a, b) => {
                            const dateA = a.started_at
                                ? moment(a.started_at)
                                    .valueOf()
                                : moment(a.started_at_real)
                                    .valueOf();
                            const dateB = b.started_at
                                ? moment(b.started_at)
                                    .valueOf()
                                : moment(b.started_at_real)
                                    .valueOf();

                            return dateA > dateB ? -1 : 1;
                        });

                        // Kategorie logů docházky z API
                        this.acsService
                            .getAcsCategories()
                            .pipe(untilDestroyed(this))
                            .subscribe({
                                next: (categories: AcsCategory[]) => {
                                    this.acsUserLogs.map((log: AcsUserLogs, index) => {
                                        // přidělení pole kategorie do objektu logu
                                        const category = categories.find(
                                            x => x.id === log.event_category_id,
                                        );

                                        if (category) {
                                            log.category = category;
                                        }

                                        // uložení pole kategorie do objektu logu
                                        this.acsUserLogs[index] = log;

                                        // příchody
                                        this.arrivals[index] = {
                                            x: moment(log.started_at)
                                                .format('YYYY-MM-DD'),
                                            y: moment(log.started_at)
                                                .format('HH:mm:ss'),
                                        };

                                        // odchody
                                        this.leaves[index] = {
                                            x: log.finished_at
                                                ? moment(log.finished_at)
                                                    .format('YYYY-MM-DD')
                                                : moment(log.started_at)
                                                    .format('YYYY-MM-DD'),
                                            y: log.finished_at
                                                ? moment(log.finished_at)
                                                    .format('HH:mm:ss')
                                                : '17:00:00',
                                        };
                                    });

                                    // Aktuální stav docházky uživatele podle logů z API
                                    this.acsState = this.acsService.giveAcsState(this.acsUserLogs);
                                    this.loadingAcs = false;
                                    this.ref.markForCheck();
                                },
                                error: error => {
                                    console.error(error);

                                    const body = 'API Acs';
                                    const options = {
                                        progressBar: true,
                                        timeOut: 5000,
                                        toastClass: 'red',
                                    };

                                    this.messages.showError(
                                        'nepodařilo se načíst data...',
                                        body,
                                        options,
                                    );
                                    this.loadingAcs = false;
                                    this.ref.markForCheck();
                                },
                            });
                    },
                    error: error => {
                        console.error(error);

                        const body = 'API Acs';
                        const options = {progressBar: true, timeOut: 5000};

                        this.messages.showError('nepodařilo se načíst data...', body, options);
                        this.loadingAcs = false;
                        this.ref.markForCheck();
                    },
                });
        } else {
            this.loadingAcs = false;
            this.ref.markForCheck();
        }
    }

    ngOnInit(): void {
        this.animation = {enable: true, duration: 2000, delay: 0};
        this.labelStyle = {
            color: '#FFFFFF',
            fontWeight: '900',
            fontFamily: 'Montserrat, sans-serif',
            fontStyle: 'normal',
        };
        this.showProgressValue = true;

        this.primaryXAxis = {
            valueType: 'Category',
            edgeLabelPlacement: 'Shift',
            isInversed: true,
        };

        this.primaryYAxis = {
            minimum: 0,
            maximum: 40,
            labelFormat: '{value} dní',
            rangePadding: 'None',
        };

        this.legendSettings = {
            visible: true,
        };

        this.tooltip = {
            enable: true,
            header: 'Čerpáno dní celkem',
        };
        this.datalabel = {visible: true};

        // subscribe dat pro absence
        this.dataService.vacationSource.pipe(untilDestroyed(this))
            .subscribe({
                next: (vacations: Vacation[]) => {
                    // defaultní stavy proměnných
                    this.vacations = vacations;
                    this.stats = {
                        vacation: {confirmed: 0, declined: 0, deleted: 0, queueCnt: 0},
                        sickdays: {confirmed: 0, declined: 0, deleted: 0, queueCnt: 0},
                        homeoffice: {confirmed: 0, declined: 0, deleted: 0, queueCnt: 0},
                        dayoff: {confirmed: 0, declined: 0, deleted: 0, queueCnt: 0},
                        doctor: {confirmed: 0, declined: 0, deleted: 0, queueCnt: 0},
                        disease: {confirmed: 0, declined: 0, deleted: 0, queueCnt: 0},
                        sicknote: {confirmed: 0, declined: 0, deleted: 0, queueCnt: 0},
                    };
                    this.vacationsData = [];

                    const chartData: number[] = [];

                    chartData[0] = 0;
                    chartData[1] = 0;
                    chartData[2] = 0;
                    chartData[3] = 0;
                    chartData[4] = 0;
                    chartData[5] = 0;
                    chartData[6] = 0;
                    chartData[7] = 0;
                    chartData[8] = 0;
                    chartData[9] = 0;
                    chartData[10] = 0;
                    chartData[11] = 0;

                    const data: Vacation[] = vacations.filter(
                        (result: Vacation) => moment()
                            .year() === moment(result.start_at)
                            .year(),
                    );

                    // eslint-disable-next-line complexity
                    data.map((vacation: Vacation) => {
                        // absence pouze přihlášeného uživatele
                        if (vacation.creator.id === this.currentUser?.id) {
                            // nastavení stavu absence
                            if (vacation.confirm && !vacation.deleted_date) {
                                vacation.status = 'confirmed';
                            }

                            if (vacation.decline && !vacation.deleted_date) {
                                vacation.status = 'declined';
                            }

                            if (vacation.deleted_date) {
                                vacation.status = 'deleted';
                            }

                            if (!vacation.decline && !vacation.confirm && !vacation.deleted_date) {
                                vacation.status = 'queue';
                            }

                            // sestavení schvalovatele podle stavu absence
                            if (!vacation.confirmer && !vacation.decliner) {
                                vacation.boss = vacation.creator.boss;
                            }

                            if (vacation.decliner && !vacation.deleted_date) {
                                vacation.boss = vacation.decliner;
                            }

                            if (vacation.confirmer && !vacation.deleted_date) {
                                vacation.boss = vacation.confirmer;
                            }

                            // schválená dovolená + graf dovolené
                            if (
                                vacation.confirm &&
                                !vacation.deleted_date &&
                                vacation.category_id === 1
                            ) {
                                this.stats.vacation.confirmed += vacation.workdays;
                                // nastavení základních dat absence na rok
                                this.vacationsData.push({
                                    id: vacation.id,
                                    // eslint-disable-next-line max-len
                                    user: `<img src="assets/img${vacation.creator.personalphoto}" class="img-fluid rounded-circle avatar-xs mr-1" alt="profilový obrázek"/>${vacation.creator.secondname} ${vacation.creator.firstname}`,
                                    reason: vacation.reason,
                                    status: vacation.status,
                                    type: vacation.category,
                                    workdays: vacation.workdays,
                                    start_at: vacation.start_at,
                                    end_at: vacation.end_at,
                                    created_date: vacation.created_date,
                                    boss: vacation.boss,
                                });

                                for (let i = 0; i < 12; i++) {
                                    chartData[i] +=
                                        new Date(vacation.start_at).getUTCMonth() + 1 === i + 1
                                            ? vacation.workdays
                                            : 0;
                                }
                            }

                            // zamítnutá dovolená
                            if (
                                vacation.decline &&
                                !vacation.deleted_date &&
                                vacation.category_id === 1
                            ) {
                                this.stats.vacation.declined += vacation.workdays;
                            }

                            // stornovaná dovolená
                            if (vacation.deleted_date && vacation.category_id === 1) {
                                this.stats.vacation.deleted += vacation.workdays;
                            }

                            // schválené sickdays
                            if (
                                vacation.confirm &&
                                !vacation.deleted_date &&
                                vacation.category_id === 5
                            ) {
                                this.stats.sickdays.confirmed += vacation.workdays;
                            }

                            // zamítnuté sickdays
                            if (
                                vacation.decline &&
                                !vacation.deleted_date &&
                                vacation.category_id === 5
                            ) {
                                this.stats.sickdays.declined += vacation.workdays;
                            }

                            // stornované sickdays
                            if (vacation.deleted_date && vacation.category_id === 5) {
                                this.stats.sickdays.deleted += vacation.workdays;
                            }

                            // sickdays ve frontě
                            if (
                                !vacation.decline &&
                                !vacation.confirm &&
                                !vacation.deleted_date &&
                                vacation.category_id === 5
                            ) {
                                this.stats.sickdays.queueCnt += vacation.workdays;
                            }

                            // dovolená ve frontě
                            if (
                                !vacation.decline &&
                                !vacation.confirm &&
                                !vacation.deleted_date &&
                                vacation.category_id === 1
                            ) {
                                this.stats.vacation.queueCnt += vacation.workdays;
                            }

                            // schválený home office
                            if (
                                vacation.confirm &&
                                !vacation.deleted_date &&
                                vacation.category_id === 3
                            ) {
                                this.stats.homeoffice.confirmed += vacation.workdays;
                            }

                            // zamítnutý home office
                            if (
                                vacation.decline &&
                                !vacation.deleted_date &&
                                vacation.category_id === 3
                            ) {
                                this.stats.homeoffice.declined += vacation.workdays;
                            }

                            // stornovaný home office
                            if (vacation.deleted_date && vacation.category_id === 3) {
                                this.stats.homeoffice.deleted += vacation.workdays;
                            }

                            // home office ve frontě
                            if (
                                !vacation.decline &&
                                !vacation.confirm &&
                                !vacation.deleted_date &&
                                vacation.category_id === 3
                            ) {
                                this.stats.homeoffice.queueCnt += vacation.workdays;
                            }

                            // schválené neplacené volno
                            if (
                                vacation.confirm &&
                                !vacation.deleted_date &&
                                vacation.category_id === 2
                            ) {
                                this.stats.dayoff.confirmed += vacation.workdays;
                            }

                            // zamítnuté neplacené volno
                            if (
                                vacation.decline &&
                                !vacation.deleted_date &&
                                vacation.category_id === 2
                            ) {
                                this.stats.dayoff.declined += vacation.workdays;
                            }

                            // stornované neplacené volno
                            if (vacation.deleted_date && vacation.category_id === 2) {
                                this.stats.dayoff.deleted += vacation.workdays;
                            }

                            // neplacené volno ve frontě
                            if (
                                !vacation.decline &&
                                !vacation.confirm &&
                                !vacation.deleted_date &&
                                vacation.category_id === 2
                            ) {
                                this.stats.dayoff.queueCnt += vacation.workdays;
                            }

                            // schválený lékař
                            if (
                                vacation.confirm &&
                                !vacation.deleted_date &&
                                vacation.category_id === 4
                            ) {
                                this.stats.doctor.confirmed += vacation.workdays;
                            }

                            // zamítnutá lékař
                            if (
                                vacation.decline &&
                                !vacation.deleted_date &&
                                vacation.category_id === 4
                            ) {
                                this.stats.doctor.declined += vacation.workdays;
                            }

                            // stornovaný lékař
                            if (vacation.deleted_date && vacation.category_id === 4) {
                                this.stats.doctor.deleted += vacation.workdays;
                            }

                            // lékař ve frontě
                            if (
                                !vacation.decline &&
                                !vacation.confirm &&
                                !vacation.deleted_date &&
                                vacation.category_id === 4
                            ) {
                                this.stats.doctor.queueCnt += vacation.workdays;
                            }

                            // schválená nemoc
                            if (
                                vacation.confirm &&
                                !vacation.deleted_date &&
                                vacation.category_id === 6
                            ) {
                                this.stats.disease.confirmed += vacation.workdays;
                            }

                            // zamítnutá nemoc
                            if (
                                vacation.decline &&
                                !vacation.deleted_date &&
                                vacation.category_id === 6
                            ) {
                                this.stats.disease.declined += vacation.workdays;
                            }

                            // stornovaná nemoc
                            if (vacation.deleted_date && vacation.category_id === 6) {
                                this.stats.disease.deleted += vacation.workdays;
                            }

                            // nemoc ve frontě
                            if (
                                !vacation.decline &&
                                !vacation.confirm &&
                                !vacation.deleted_date &&
                                vacation.category_id === 6
                            ) {
                                this.stats.disease.queueCnt += vacation.workdays;
                            }

                            // schválená neschopenka
                            if (
                                vacation.confirm &&
                                !vacation.deleted_date &&
                                vacation.category_id === 7
                            ) {
                                this.stats.sicknote.confirmed += vacation.workdays;
                            }

                            // zamítnutá neschopenka
                            if (
                                vacation.decline &&
                                !vacation.deleted_date &&
                                vacation.category_id === 7
                            ) {
                                this.stats.sicknote.declined += vacation.workdays;
                            }

                            // stornovaná neschopenka
                            if (vacation.deleted_date && vacation.category_id === 7) {
                                this.stats.sicknote.deleted += vacation.workdays;
                            }

                            // neschopenka ve frontě
                            if (
                                !vacation.decline &&
                                !vacation.confirm &&
                                !vacation.deleted_date &&
                                vacation.category_id === 7
                            ) {
                                this.stats.sicknote.queueCnt += vacation.workdays;
                            }
                        }
                    });

                    // řazení absencí
                    this.vacationsData = this.vacationsData.sort((a, b) => {
                        const dateA = moment(a.start_at)
                            .valueOf();
                        const dateB = moment(b.start_at)
                            .valueOf();

                        return dateA > dateB ? -1 : 1;
                    });

                    // nastavení grafu
                    this.chartData = [
                        {
                            month: 'Leden',
                            days: chartData[0],
                            text: `Leden: ${chartData[0]}`,
                        },
                        {
                            month: 'Únor',
                            days: chartData[1],
                            text: `Únor: ${chartData[1]}`,
                        },
                        {
                            month: 'Březen',
                            days: chartData[2],
                            text: `Březen: ${chartData[2]}`,
                        },
                        {
                            month: 'Duben',
                            days: chartData[3],
                            text: `Duben: ${chartData[3]}`,
                        },
                        {
                            month: 'Květen',
                            days: chartData[4],
                            text: `Květen: ${chartData[4]}`,
                        },
                        {
                            month: 'Červen',
                            days: chartData[5],
                            text: `Červen: ${chartData[5]}`,
                        },
                        {
                            month: 'Červenec',
                            days: chartData[6],
                            text: `Červenec: ${chartData[6]}`,
                        },
                        {
                            month: 'Srpen',
                            days: chartData[7],
                            text: `Srpen: ${chartData[7]}`,
                        },
                        {
                            month: 'Září',
                            days: chartData[8],
                            text: `Září: ${chartData[8]}`,
                        },
                        {
                            month: 'Říjen',
                            days: chartData[9],
                            text: `Říjen: ${chartData[9]}`,
                        },
                        {
                            month: 'Listopad',
                            days: chartData[10],
                            text: `Listopad: ${chartData[10]}`,
                        },
                        {
                            month: 'Prosinec',
                            days: chartData[11],
                            text: `Prosinec: ${chartData[11]}`,
                        },
                    ];
                    this.loadingVacationWidget = false;
                    this.ref.markForCheck();
                },
                error: error => {
                    this.loadingVacationWidget = false;
                    this.ref.markForCheck();
                    console.error(error);
                },
            });

        // subscribe dat pro docházku API (Acs)
        this.apisService.allowedApis.pipe(untilDestroyed(this))
            .subscribe(
                (apis: ApiConnections[]) => {
                    this.acsService.acsUsersSource.pipe(untilDestroyed(this))
                        .subscribe(
                            () => {
                                if (this.currentUser?.ascApiUser_data) {
                                    apis.forEach((api: ApiConnections) => {
                                        if (
                                            api.type.name === 'ACS' &&
                                            this.currentUser?.ascApiUser_data &&
                                            api.active &&
                                            api.state
                                        ) {
                                            this.api = api;
                                            this.loadAcsData(this.currentUser.ascApiUser_data);
                                        } else {
                                            this.loadingAcs = false;
                                            this.ref.markForCheck();
                                        }
                                    });
                                }
                            },
                            error => {
                                console.error(error);
                            },
                        );
                },
                error => {
                    console.error(error);
                },
            );

        this.navHandlerService.navState.pipe(untilDestroyed(this))
            .subscribe(
                () => {
                    this.refreshContent();
                },
                error => {
                    console.error(error);
                },
            );

        this.dataService.setDepartmentDataSource();
        this.dataService.setVacationsDataSource();
        this.dataService.setVacationCategoriesDataSource();
    }

    refreshContent(): void {
        setTimeout(() => {
            if (this.sickDaysPercentageObj) {
                this.sickDaysPercentageObj.redraw = true;
            }

            if (this.vacationsPercentageObj) {
                this.vacationsPercentageObj.redraw = true;
            }

            if (this.dashboard) {
                this.dashboard.refresh();
            }

            if (this.moduleToolbarObj) {
                this.moduleToolbarObj.refresh();
            }

            if (this.vacationsChart) {
                this.vacationsChart.chartResize();
            }
        }, 1000);
    }

    onCloseIconHandler(event: MouseEvent): void {
        if (event.target === null) {
            return;
        }

        const elementOffset = (event.target as HTMLElement).offsetParent;

        if (elementOffset) {
            this.dashboard?.removePanel(elementOffset.id);
        }
    }

    changedSickBar(args: IProgressValueEventArgs): void {
        if (args.value < 30) {
            args.progressColor = '#629337';
        } else if (args.value >= 30 && args.value <= 70) {
            args.progressColor = '#FFBB34';
        } else if (args.value > 70) {
            args.progressColor = '#FF3547';
        }
    }

    changedVacBar(args: IProgressValueEventArgs): void {
        if (args.value < 30) {
            args.progressColor = '#629337';
        } else if (args.value >= 30 && args.value <= 70) {
            args.progressColor = '#FFBB34';
        } else if (args.value > 70) {
            args.progressColor = '#FF3547';
        }
    }

    onCreate(): void {
        // Nastavení GUI podle URL parametrů
        this.route.queryParamMap.pipe(untilDestroyed(this))
            .subscribe(queryParams => {
                if (this.vacationTabObj) {
                    if (queryParams.get('view') === 'grid') {
                        this.vacationTabObj.select(0);
                    } else if (queryParams.get('view') === 'calendar') {
                        this.vacationTabObj.select(1);
                    } else if (queryParams.get('view') === 'stats') {
                        this.vacationTabObj.select(2);
                    } else if (queryParams.get('view') === 'import') {
                        this.vacationTabObj.select(3);
                    } else {
                        this.vacationTabObj.select(0);
                    }
                }
            });
    }

    preventSwipe(e: SelectEventArgs): void {
        if (e.isSwiped) {
            e.cancel = true;
        }
    }

    navigateToTab(view: string, form: string | null): void {
        const queryParams = {view, form};

        if (this.vacationTabObj) {
            if (view === 'grid') {
                this.vacationTabObj.select(0);
            } else if (view === 'calendar') {
                this.vacationTabObj.select(1);
            } else if (view === 'stats') {
                this.vacationTabObj.select(2);
            } else if (view === 'import') {
                this.vacationTabObj.select(3);
            } else {
                this.vacationTabObj.select(0);
            }
        }

        void this.router.navigate([], {
            relativeTo: this.route,
            queryParams,
            queryParamsHandling: 'merge',
        });
    }

    select(e: SelectEventArgs): void {
        let queryParams: Params = {view: null};

        this.route.queryParamMap.pipe(untilDestroyed(this))
            .subscribe(params => {
                const form = params.get('form') === 'addVacation' ? 'addVacation' : null;

                if (e.selectedItem.textContent === 'tabulka') {
                    queryParams = {
                        view: 'grid',
                        form,
                    };
                }

                if (e.selectedItem.textContent === 'kalendář') {
                    queryParams = {
                        view: 'calendar',
                        form,
                    };
                }

                if (e.selectedItem.textContent === 'statistiky') {
                    queryParams = {
                        view: 'stats',
                        form,
                    };
                }

                if (e.selectedItem.textContent === 'import') {
                    queryParams = {
                        view: 'import',
                        form,
                    };
                }

                void this.router.navigate([], {
                    relativeTo: this.route,
                    queryParams,
                    queryParamsHandling: 'merge',
                });
            });
    }

    exportAsAccountantXLSX(): void {
        if (this.currentUser && this.permissionService.checkUserISVacationAdmin(this.currentUser)) {
            this.renderAccountantFormDialog = true;
            this.ref.markForCheck();
        } else {
            const body = 'Nemáte oprávnění provést tuto akci...';
            const options = {progressBar: true, timeOut: 5000};

            this.messages.showError('Nedostatečné oprávnění', body, options);
            this.ref.markForCheck();
        }
    }
}
