<div *ngIf="loadingCalendar" class="row">
  <div class="col-12 text-center">
    <app-loader></app-loader>
  </div>
</div>
<div class="d-flex justify-content-end">
  <small class="text-muted">legenda:</small>&nbsp;
  <span
    *ngFor="let category of categories"
    [ngStyle]="{
            'background-color': category.colortag,
            'border-color': category.colortag,
            color: '#ffffff'
        }"
    class="e-badge"
  >{{ category.name }}</span
  >
  <span
    class="e-badge"
    style="background-color: #ff8c00; border-color: #ff8c00; color: #ffffff"
  >státní svátek</span
  >
</div>
<full-calendar
  #vacationCalendar
  *ngIf="!loadingCalendar"
  [deepChangeDetection]="true"
  [options]="calendarOptions"
></full-calendar>
