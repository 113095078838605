<ejs-dialog
  #deleteFileDialog
  [buttons]="buttonsDeleteFileDialog"
  [content]="'Opravdu chcete smazat tento soubor?'"
  [header]="'Smazat soubor'"
  [height]="'200px'"
  [isModal]="true"
  [showCloseIcon]="true"
  [visible]="false"
  [width]="'450px'"
  id="deleteFileDialog"
  target="#formDialog"
></ejs-dialog>
<ejs-dialog
  #formDialog
  (close)="
        isUpdate = false;
        isCreate = false;
        isDirty = false;
        isChecked = false;
        isCopy = false;
        submited = false;
        changeDialogState(false)
    "
  (open)="onCreateRte()"
  [isModal]="true"
  [showCloseIcon]="true"
  [visible]="isVisible"
  [width]="'85%'"
  id="formDialog"
>
  <form [formGroup]="ticketForm">
    <ng-template #content>
      <div class="dialogContent">
        <div class="row">
          <div class="form-group col-12 mb-3">
            <label
              [ngClass]="
                                f.ticketSubject.valid &&
                                (f.ticketSubject.dirty ||
                                    f.ticketSubject.touched)
                                    ? 'text-success'
                                    : f.ticketSubject.invalid &&
                                      (f.ticketSubject.dirty ||
                                          f.ticketSubject.touched)
                                    ? 'text-danger'
                                    : 'text-muted'
                            "
              for="ticketForm-subject"
            >* Název:</label
            >
            <ejs-textbox
              [ngClass]="
                                (f.ticketSubject.dirty ||
                                    f.ticketSubject.touched) &&
                                f.ticketSubject.invalid
                                    ? 'e-error'
                                    : (f.ticketSubject.dirty ||
                                          f.ticketSubject.touched) &&
                                      f.ticketSubject.valid
                                    ? 'e-success'
                                    : ''
                            "
              floatLabelType="Auto"
              formControlName="ticketSubject"
              id="ticketForm-subject"
            ></ejs-textbox>
            <small
              *ngIf="
                                (f.ticketSubject.dirty ||
                                    f.ticketSubject.touched) &&
                                f.ticketSubject.errors &&
                                f.ticketSubject.errors.required
                            "
              class="text-danger"
            >Název ticketu je povinný</small
            >
          </div>
          <div class="form-group col-12 col-md-6 mb-3">
            <label
              [ngClass]="
                                f.ticketCategory.valid
                                    ? 'text-success'
                                    : f.ticketCategory.invalid &&
                                      (f.ticketCategory.dirty ||
                                          f.ticketCategory.touched)
                                    ? 'text-danger'
                                    : 'text-muted'
                            "
              for="ticketForm-category"
            >* Kategorie ticketu:</label
            >
            <ejs-dropdownlist
              #category
              (filtering)="onFilteringCategories($event)"
              [allowFiltering]="true"
              [cssClass]="
                                f.ticketCategory.touched &&
                                f.ticketCategory.invalid
                                    ? 'e-error'
                                    : f.ticketCategory.touched &&
                                      f.ticketCategory.valid
                                    ? 'e-success'
                                    : ''
                            "
              [dataSource]="categorySelect"
              [fields]="fields"
              [filterBarPlaceholder]="'Vyhledat kategorii...'"
              [ignoreAccent]="true"
              [placeholder]="'Vyberte kategorii'"
              [popupHeight]="height"
              formControlName="ticketCategory"
              id="ticketForm-category"
              sortOrder="Ascending"
            ></ejs-dropdownlist>
            <small
              *ngIf="
                                (f.ticketCategory.dirty ||
                                    f.ticketCategory.touched) &&
                                f.ticketCategory.errors &&
                                f.ticketCategory.errors.required
                            "
              class="text-danger"
            >Vyberte alespoň jednu kategorii ticketu</small
            >
          </div>
          <div class="form-group col-12 col-md-6 mb-3">
            <tag-input
              [displayBy]="'name'"
              [identifyBy]="'id'"
              [onlyFromAutocomplete]="false"
              formControlName="tags"
            >
              <tag-input-dropdown
                [autocompleteItems]="tagsAutocomplete"
                [displayBy]="'name'"
                [identifyBy]="'id'"
              >
              </tag-input-dropdown>
            </tag-input>
          </div>
        </div>
        <ng-container *ngIf="isCompany">
          <div class="row">
            <div class="form-group col-12 mb-3">
              <label
                [ngClass]="
                                f.businessId.valid &&
                                (f.businessId.dirty ||
                                    f.businessId.touched)
                                    ? 'text-success'
                                    : f.businessId.invalid &&
                                      (f.businessId.dirty ||
                                          f.businessId.touched)
                                    ? 'text-danger'
                                    : 'text-muted'
                            "
                for="ticketForm-businessId"
              >* IČO:</label
              >
              <ejs-textbox
                [ngClass]="
                                (f.businessId.dirty ||
                                    f.businessId.touched) &&
                                f.businessId.invalid
                                    ? 'e-error'
                                    : (f.businessId.dirty ||
                                          f.businessId.touched) &&
                                      f.businessId.valid
                                    ? 'e-success'
                                    : ''
                            "
                floatLabelType="Auto"
                formControlName="businessId"
                id="ticketForm-businessId"
              ></ejs-textbox>
            </div>
          </div>
        </ng-container>
        <div class="row">
          <div class="form-group col-12">
            <label
              [ngClass]="
                                f.ticketMaintask.valid &&
                                (f.ticketMaintask.dirty ||
                                    f.ticketMaintask.touched)
                                    ? 'text-success'
                                    : f.ticketMaintask.invalid &&
                                      (f.ticketMaintask.dirty ||
                                          f.ticketMaintask.touched)
                                    ? 'text-danger'
                                    : 'text-muted'
                            "
              for="ticketMainTask"
            >* Zadání:</label
            >
            <ejs-richtexteditor
              #mainTask
              (actionBegin)="handleFullScreen($event)"
              (actionComplete)="actionCompleteHandler($event)"
              [enablePersistence]="false"
              [fontFamily]="fontFamily"
              [insertImageSettings]="insertImageSettings"
              [maxLength]="maxLength"
              [ngClass]="
                                (f.ticketMaintask.dirty ||
                                    f.ticketMaintask.touched) &&
                                f.ticketMaintask.invalid
                                    ? 'e-error'
                                    : (f.ticketMaintask.dirty ||
                                          f.ticketMaintask.touched) &&
                                      f.ticketMaintask.valid
                                    ? 'e-success'
                                    : ''
                            "
              [pasteCleanupSettings]="pasteCleanupSettings"
              [quickToolbarSettings]="quickToolbarSettings"
              [showCharCount]="true"
              [toolbarSettings]="tools"
              formControlName="ticketMaintask"
              id="ticketMainTask"
              locale="cs"
              placeholder="Začněte psát zadání..."
            >
            </ejs-richtexteditor>
            <small
              *ngIf="
                                (f.ticketMaintask.dirty ||
                                    f.ticketMaintask.touched) &&
                                f.ticketMaintask.errors &&
                                f.ticketMaintask.errors.required
                            "
              class="text-danger"
            >Zadání ticketu je povinné</small
            >
          </div>
        </div>
        <div class="row">
          <div class="form-group col-12 col-md-6">
            <ejs-datetimepicker
              [cssClass]="
                                f.ticketStartDeadlineDate.invalid
                                    ? 'e-error'
                                    : f.ticketStartDeadlineDate.valid
                                    ? 'e-success'
                                    : ''
                            "
              [firstDayOfWeek]="1"
              [min]="today"
              [value]="today"
              floatLabelType="Always"
              formControlName="ticketStartDeadlineDate"
              id="ticketStartDeadlineDate"
              placeholder="Začátek termínu:"
            ></ejs-datetimepicker>
            <small
              *ngIf="
                                f.ticketStartDeadlineDate.touched &&
                                f.ticketStartDeadlineDate.errors &&
                                f.ticketStartDeadlineDate.errors.required
                            "
              class="text-danger"
            >Datum začátku termínu ticketu je povinné</small
            ><br />
            <small
              *ngIf="
                                f.ticketStartDeadlineDate.touched &&
                                f.ticketStartDeadlineDate.errors &&
                                f.ticketStartDeadlineDate.errors.greaterThan
                            "
              class="text-danger"
            >Datum začátku termínu je větší než datum
              konce</small
            >
          </div>
          <div class="form-group col-12 col-md-6">
            <ejs-datetimepicker
              [cssClass]="
                                f.ticketDeadlineDate.invalid
                                    ? 'e-error'
                                    : f.ticketDeadlineDate.valid
                                    ? 'e-success'
                                    : ''
                            "
              [firstDayOfWeek]="1"
              [min]="f.ticketStartDeadlineDate.value"
              floatLabelType="Always"
              formControlName="ticketDeadlineDate"
              id="ticketDeadlineDate"
              placeholder="Konec termínu:"
            ></ejs-datetimepicker>
            <small
              *ngIf="
                                f.ticketDeadlineDate.errors &&
                                f.ticketDeadlineDate.errors.required
                            "
              class="text-danger"
            >Datum konce termínu ticketu je povinné</small
            ><br />
            <small
              *ngIf="
                                f.ticketDeadlineDate.touched &&
                                f.ticketDeadlineDate.errors &&
                                f.ticketDeadlineDate.errors.lessThan
                            "
              class="text-danger"
            >Datum konce termínu je menší než datum
              začátku</small
            >
          </div>
        </div>
        <div class="row">
          <div class="form-group col-12 col-md-6">
            <label
              [ngClass]="
                                f.userAssignations.valid &&
                                (f.userAssignations.dirty ||
                                    f.userAssignations.touched)
                                    ? 'text-success'
                                    : f.userAssignations.invalid &&
                                      (f.userAssignations.dirty ||
                                          f.userAssignations.touched)
                                    ? 'text-danger'
                                    : 'text-muted'
                            "
              for="usersTicket"
            >Uživatelé:</label
            >
            <ejs-multiselect
              #users
              (filtering)="onFilteringUsers($event)"
              [allowFiltering]="true"
              [cssClass]="
                                f.userAssignations.touched &&
                                f.userAssignations.invalid
                                    ? 'e-error'
                                    : f.userAssignations.touched &&
                                      f.userAssignations.valid
                                    ? 'e-success'
                                    : ''
                            "
              [dataSource]="usersSelect"
              [fields]="fields"
              [filterBarPlaceholder]="'Vyhledat uživatele...'"
              [ignoreAccent]="true"
              [mode]="'CheckBox'"
              [placeholder]="'Vyberte uživatele'"
              [popupHeight]="height"
              [selectAllText]="'Všechny uživatele'"
              formControlName="userAssignations"
              id="usersTicket"
              showSelectAll="true"
              sortOrder="Ascending"
            ></ejs-multiselect>
            <small
              *ngIf="
                                f.userAssignations.touched &&
                                f.userAssignations.errors &&
                                f.userAssignations.errors.required
                            "
              class="text-danger"
            >Vyberte alespoň jednoho uživatele</small
            >
          </div>
          <div class="form-group col-12 col-md-6">
            <label
              [ngClass]="
                                f.departmentAssignations.valid &&
                                (f.departmentAssignations.dirty ||
                                    f.departmentAssignations.touched)
                                    ? 'text-success'
                                    : f.departmentAssignations.invalid &&
                                      (f.departmentAssignations.dirty ||
                                          f.departmentAssignations.touched)
                                    ? 'text-danger'
                                    : 'text-muted'
                            "
              for="ticketDepartments"
            >Oddělení:</label
            >
            <ejs-multiselect
              #departments
              (filtering)="onFilteringDepartments($event)"
              [allowFiltering]="true"
              [cssClass]="
                                f.departmentAssignations.touched &&
                                f.departmentAssignations.invalid
                                    ? 'e-error'
                                    : f.departmentAssignations.touched &&
                                      f.departmentAssignations.valid
                                    ? 'e-success'
                                    : ''
                            "
              [dataSource]="departmentSelect"
              [fields]="fields"
              [filterBarPlaceholder]="'Vyhledat oddělení...'"
              [ignoreAccent]="true"
              [mode]="'CheckBox'"
              [placeholder]="'Vyberte oddělení'"
              [popupHeight]="height"
              [selectAllText]="'Všechna oddělení'"
              formControlName="departmentAssignations"
              id="ticketDepartments"
              showSelectAll="true"
              sortOrder="Ascending"
            ></ejs-multiselect>
            <small
              *ngIf="
                                f.departmentAssignations.touched &&
                                f.departmentAssignations.errors &&
                                f.departmentAssignations.errors.required
                            "
              class="text-danger"
            >Vyberte alespoň jedno oddělení</small
            >
          </div>
          <div *ngIf="isUpdate" class="col-12 mb-3">
            <label
              class="text-muted" for="preloadfileupload"
            >Již nahrané soubory:</label
            >
            <br />
            <ng-container
              *ngIf="
                                ticket && ticket.files && ticket.files.length
                            "
            >
              <div
                *ngFor="let file of ticket.files"
                class="e-card"
              >
                <div class="e-card-content row">
                  <div class="col-3">
                    {{ file.name | fileExtension: false }}
                  </div>
                  <div class="col-3 text-center">
                    {{ file.size | humanizeBytes }}
                  </div>
                  <div class="col-3 text-center">
                    {{ file.name | fileExtension: true }}
                  </div>
                  <div class="col-3 text-right">
                    <i
                      (click)="
                                                deleteFileID = file.id;
                                                deleteFileDialog.show()
                                            "
                      class="fa-light fa-lg fa-trash text-danger pr-4 align-middle"
                      style="cursor: pointer"
                    ></i>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
          <div class="col-12 mb-3">
            <label
              [ngClass]="
                                f.files.valid
                                    ? 'text-success'
                                    : f.files.invalid &&
                                      (f.files.dirty || f.files.touched)
                                    ? 'text-danger'
                                    : 'text-muted'
                            "
              for="preloadfileupload"
            >Soubory:</label
            >
            <ejs-uploader
              #preloadupload
              (removing)="onFileRemove($event)"
              (selected)="onFileSelected($event)"
              [asyncSettings]="path"
              [autoUpload]="autoUpload"
              [dropArea]="dropElement"
              allowedExtensions=".jpg,.png,.jpeg,.xls,.xlsx,.pdf,.doc,.docx,.rar,.zip,.csv,.json,.odt,.ods,.txt,.xml"
              formControlName="files"
              id="preloadfileupload"
              maxFileSize="5000000"
              multiple="true"
            >
            </ejs-uploader>
            <small
              *ngIf="
                                (f.files.dirty || f.files.touched) &&
                                f.files.errors &&
                                f.files.errors.required
                            "
              class="text-danger"
            >Soubor je povinný</small
            >
          </div>
        </div>
      </div>
    </ng-template>
    <ng-template #footerTemplate>
      <div class="d-flex justify-content-end">
                <span
                  *ngIf="ticketForm.invalid"
                  class="e-badge e-badge-warning p-2 size_2"
                  role="alert"
                >
                    <strong>Formulář je špatně vyplněný</strong>
                </span>
        <span
          *ngIf="ticketForm.valid"
          class="e-badge e-badge-success p-2 size_2"
          role="alert"
        >
                    <strong>Formulář je správně vyplněný</strong>
                </span>
      </div>
      <div class="row">
        <div class="col-12 pt-2">
          <button
            (click)="onSubmit()"
            [disabled]="
                            ticketLoading || ticketForm.invalid || !isChecked
                        "
            cssClass="e-btn e-success btn-p-zero e-small mr-1"
            ejs-button
            type="submit"
          >
                        <span
                          *ngIf="ticketLoading === true"
                          aria-hidden="true"
                          class="spinner-grow spinner-grow-sm"
                          role="status"
                        ></span>
            <span *ngIf="ticketLoading === false">ULOŽIT</span>
            <span *ngIf="ticketLoading === true">Provádím...</span>
          </button>
          <button
            (click)="formDialogObj.hide()"
            cssClass="e-btn e-danger e-outline btn-p-zero e-small"
            ejs-button
            type="button"
          >
            ZRUŠIT
          </button>
        </div>
      </div>
    </ng-template>
  </form>
</ejs-dialog>
