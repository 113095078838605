import {ChangeDetectionStrategy, Component} from '@angular/core';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'app-wiki-detail',
    templateUrl: './wiki-detail.component.html',
    styleUrls: ['./wiki-detail.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WikiDetailComponent {}
