import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnInit,
    ViewChild,
} from '@angular/core';
import {MaterialBudget} from '@src/app/_models/material/material-budget';
import {AuthenticationService} from '@src/app/_services/authentication.service';
import {MessageService} from '@src/app/_services/message.service';
import {User} from '@src/app/_models/user/user';
import {ActivatedRoute} from '@angular/router';
import {FormBuilder} from '@angular/forms';
import {PermissionService} from '@src/app/_services/permission.service';
import {registerLocaleData} from '@angular/common';
import {Observable, Observer} from 'rxjs';
import {DataService} from '@src/app/_services/data.service';

import moment from 'moment';
import localeCs from '@angular/common/locales/cs';
import {ButtonPropsModel, DialogComponent} from '@syncfusion/ej2-angular-popups';
import {
    ActionEventArgs,
    ColumnModel,
    DataResult,
    EditSettingsModel,
    ExcelExportProperties,
    FilterSettingsModel,
    GridComponent,
    GroupSettingsModel,
    PageSettingsModel,
    RowDataBoundEventArgs,
    SelectionSettingsModel,
    ToolbarItems,
} from '@syncfusion/ej2-angular-grids';
import {ChangeEventArgs, DropDownListComponent} from '@syncfusion/ej2-angular-dropdowns';
import {ClickEventArgs} from '@syncfusion/ej2-angular-navigations';
import {TextBoxComponent} from '@syncfusion/ej2-angular-inputs';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {NavHandlerService} from '@src/app/_services/nav-handler.service';
import {ITooltipRenderEventArgs} from '@syncfusion/ej2-charts';
import {Internationalization} from '@syncfusion/ej2-base';
import {MaterialService} from '@src/app/features/material/material.service';

moment.updateLocale('cs', {workingWeekdays: [1, 2, 3, 4, 5]});
registerLocaleData(localeCs);

@UntilDestroy()
@Component({
    selector: 'app-material-budgets',
    templateUrl: './material-budgets.component.html',
    styleUrls: ['./material-budgets.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MaterialBudgetsComponent implements OnInit {
    // Charts
    primaryXAxis: object;

    chartData: object[] = [];

    title: string;

    primaryYAxis: object;

    tooltip: object;

    marker: object;

    // Dialogs
    renderBudgetItemsFormDialog = false;

    renderBudgetFormDialog = false;

    buttonsDelete: ButtonPropsModel[] = [
        {
            click: async (): Promise<void> => {
                await this.deactivateBudget();
            },
            isFlat: false,
            buttonModel: {
                content: 'OK',
                cssClass: 'e-success e-outline',
            },
        },
        {
            click: (): void => {
                this.confirmationDelete.hide();
            },
            buttonModel: {
                content: 'ZRUŠIT',
                cssClass: 'e-danger e-outline',
            },
        },
    ];

    buttonsCancel: ButtonPropsModel[] = [
        {
            click: (): void => {
                this.confirmationCancel.hide();
            },
            buttonModel: {
                isPrimary: true,
                content: 'OK',
            },
        },
    ];

    // Grid
    currencyFormat: {[key: string]: string} = {format: '#,###.00\',- CZK\''};

    dateFormat: string;

    toolbar: ToolbarItems[] | object;

    data: object;

    fields: object = {text: 'label', value: 'value'};

    filterSettings: FilterSettingsModel;

    selectionSettings: SelectionSettingsModel;

    editSettings: EditSettingsModel;

    groupOptions: GroupSettingsModel;

    pageOptions: PageSettingsModel;

    expendituresColumnsPrice: ColumnModel[];

    expendituresColumnsItems: ColumnModel[];

    filterDate: object;

    requiredRules: object;

    exportCurrentPage = false;

    // Dropdowns
    height = '240px';

    categoryFilterData: Array<string> = [];

    // Variables
    currentUser: User | null;

    tableBudgetsLoad = true;

    budgets: MaterialBudget[] = [];

    clickedRow: MaterialBudget[] = [];

    clickedRow$ = new Observable<MaterialBudget[]>((observer: Observer<MaterialBudget[]>) => {
        observer.next(this.clickedRow);
    });

    // Forms
    isCreate = false;

    isUpdate = false;

    isCopy = false;

    // Dropdowns
    @ViewChild('viewFilter') viewFilterInstance?: DropDownListComponent;

    // Charts
    @ViewChild('budgetChart') budgetChartObj?: DialogComponent;

    // Grid
    @ViewChild('budgetsGrid') gridBudgets?: GridComponent;

    @ViewChild('searchtext') searchtextObj?: TextBoxComponent;

    // Dialogs
    @ViewChild('confirmationDelete') confirmationDelete: DialogComponent;

    @ViewChild('confirmationCancel') confirmationCancel: DialogComponent;

    constructor(
        private readonly route: ActivatedRoute,
        private readonly formBuilder: FormBuilder,
        private readonly ref: ChangeDetectorRef,
        private readonly authenticationService: AuthenticationService,
        private readonly permissionsService: PermissionService,
        private readonly materialService: MaterialService,
        private readonly navHandlerService: NavHandlerService,
        private readonly permissionService: PermissionService,
        private readonly message: MessageService,
        private readonly messages: MessageService,
        private readonly dataService: DataService,
    ) {
        this.currentUser = this.authenticationService.currentUserValue;
    }

    ngOnInit(): void {
        this.filterSettings = {
            type: 'Excel',
            showFilterBarOperator: true,
            showFilterBarStatus: true,
            ignoreAccent: true,
        };

        this.selectionSettings = {
            persistSelection: true,
            type: 'Multiple',
            checkboxOnly: true,
        };

        this.toolbar = [
            {
                text: 'Občerstvit data',
                tooltipText: 'Občerstvit data',
                prefixIcon: 'e-refresh',
                id: 'customRefresh',
            },
            'Print',
            'ExcelExport',
            'Add',
            'Edit',
            {
                text: 'Kopírovat',
                tooltipText: 'Kopírovat rozpočet',
                prefixIcon: 'e-copy',
                id: 'copy',
            },
            {
                text: 'Položky rozpočtu',
                tooltipText: 'Zobrazit položky',
                prefixIcon: 'e-list',
                id: 'list',
            },
            {
                text: 'Smazat',
                tooltipText: 'Smazat rozpočet',
                prefixIcon: 'e-remove',
                id: 'delete',
            },
        ];

        this.categoryFilterData = [
            'všechny rozpočty',
            'aktivní rozpočty',
            'expirované rozpočty',
            'smazané rozpočty',
        ];
        this.pageOptions = {pageSize: 50};
        this.dateFormat = 'dd.MM.yyyy HH:mm';
        this.filterDate = {type: 'Menu', params: {format: this.dateFormat}};
        this.groupOptions = {
            showGroupedColumn: true,
            disablePageWiseAggregates: false,
        };

        this.editSettings = {
            allowEditing:
                this.currentUser !== null &&
                this.permissionService.checkUserISBudgetAdmin(this.currentUser),
            allowAdding:
                this.currentUser !== null &&
                this.permissionService.checkUserISBudgetAdmin(this.currentUser),
            allowDeleting:
                this.currentUser !== null &&
                this.permissionService.checkUserISBudgetAdmin(this.currentUser),
            allowEditOnDblClick: false,
            mode: 'Dialog',
        };
        this.requiredRules = {required: true};
        this.expendituresColumnsPrice = [
            {
                field: 'expendituresQueuePrice',
                headerText: 'Fronta',
                format: '#,###.00\',- CZK\'',
                textAlign: 'Right',
                maxWidth: 150,
                minWidth: 80,
                clipMode: 'EllipsisWithTooltip',
                autoFit: true,
            },
            {
                field: 'expendituresDeletedPrice',
                headerText: 'Smazáno',
                format: '#,###.00\',- CZK\'',
                textAlign: 'Right',
                maxWidth: 150,
                minWidth: 100,
                clipMode: 'EllipsisWithTooltip',
                autoFit: true,
            },
            {
                field: 'expendituresSuccessPrice',
                headerText: 'Schváleno',
                format: '#,###.00\',- CZK\'',
                textAlign: 'Right',
                maxWidth: 150,
                minWidth: 100,
                clipMode: 'EllipsisWithTooltip',
                autoFit: true,
            },
            {
                field: 'expendituresResiduePrice',
                headerText: 'Zbývá',
                format: '#,###.00\',- CZK\'',
                textAlign: 'Right',
                maxWidth: 150,
                minWidth: 100,
                clipMode: 'EllipsisWithTooltip',
                autoFit: true,
            },
        ];

        this.expendituresColumnsItems = [
            {
                field: 'expendituresQueueItems',
                headerText: 'Fronta',
                format: 'N',
                textAlign: 'Right',
                maxWidth: 125,
                minWidth: 100,
                clipMode: 'EllipsisWithTooltip',
                autoFit: true,
            },
            {
                field: 'expendituresDeletedItems',
                headerText: 'Smazáno',
                format: 'N',
                textAlign: 'Right',
                maxWidth: 125,
                minWidth: 100,
                clipMode: 'EllipsisWithTooltip',
                autoFit: true,
            },
            {
                field: 'expendituresSuccessItems',
                headerText: 'Schváleno',
                format: 'N',
                textAlign: 'Right',
                maxWidth: 125,
                minWidth: 100,
                clipMode: 'EllipsisWithTooltip',
                autoFit: true,
            },
            {
                field: 'expendituresTotalItems',
                headerText: 'Celkem',
                format: 'N',
                textAlign: 'Right',
                maxWidth: 125,
                minWidth: 100,
                clipMode: 'EllipsisWithTooltip',
                autoFit: true,
            },
        ];

        this.primaryXAxis = {
            title: 'Rozpočty',
            interval: 1,
            labelIntersectAction: 'Rotate45',
            valueType: 'Category',
        };

        this.primaryYAxis = {
            title: 'Výdaje',
            labelFormat: '{value},- CZK',
            interval: 100000,
        };

        this.tooltip = {
            enable: true,
        };
        this.marker = {visible: true, width: 10, opacity: 0.6, height: 10};
        this.title = 'Přehled aktivních rozpočtů a jejich čerpání';

        this.navHandlerService.navState.pipe(untilDestroyed(this))
            .subscribe(
                () => {
                    this.refreshContent();
                },
                error => {
                    console.error(error);
                },
            );
    }

    refreshContent(): void {
        setTimeout(() => {
            if (this.budgetChartObj) {
                this.budgetChartObj.refresh();
            }
        }, 1000);
    }

    tooltipRender(args: ITooltipRenderEventArgs): void {
        const intl: Internationalization = new Internationalization();
        const value = args.point.y as string;

        args.text = `${intl.formatNumber(parseInt(value, 10), {skeleton: '#,###.00'})},- CZK`;
    }

    loadData(): DataResult[] {
        this.gridBudgets?.clearRowSelection();

        console.info('NEW DATA BOUND');

        const elements: unknown[] = [];

        this.chartData = [];

        if (this.budgets.length > 0) {
            this.budgets.map((budget: MaterialBudget) => {
                let approversStr = '<ul>';

                if (budget.approvers.length > 0) {
                    budget.approvers.map(approver => {
                        approversStr += '<li>' + approver.fullname + '</li>';
                    });
                } else {
                    approversStr = 'nemá žádné správce';
                }

                approversStr += '</ul>';

                let viewersStr = '<ul>';

                if (budget.viewers.length > 0) {
                    budget.viewers.map(viewer => {
                        viewersStr += '<li>' + viewer.fullname + '</li>';
                    });
                } else {
                    viewersStr = 'nemá žádné diváky';
                }

                viewersStr += '</ul>';
                elements.push({
                    id: budget.id,
                    department_id: budget.department.id,
                    company_id: budget.department.company.id,
                    created_by: budget.creator.id,
                    creator_str: budget.creator.fullname,
                    creator_img: budget.creator.personalphoto,
                    name: budget.name,
                    amount: budget.amount,
                    status: budget.deleted_date
                        ? 'smazán'
                        : moment(budget.expire_at)
                            .format('YYYY-MM-DD HH:mm:ss') <=
                        moment()
                            .format('YYYY-MM-DD HH:mm:ss')
                            ? 'expiroval'
                            : 'aktivní',
                    department: budget.department.name,
                    company: budget.department.company.name,
                    approvers: approversStr,
                    viewers: viewersStr,
                    expendituresQueuePrice: budget.expenditures.queue.price,
                    expendituresDeletedPrice: budget.expenditures.deleted.price,
                    expendituresSuccessPrice: budget.expenditures.success.price,
                    expendituresResiduePrice: budget.expenditures.residue,
                    expendituresQueueItems: budget.expenditures.queue.items,
                    expendituresDeletedItems: budget.expenditures.deleted.items,
                    expendituresSuccessItems: budget.expenditures.success.items,
                    expendituresTotalItems: budget.budgetitems.length,
                    start_at: new Date(budget.start_at),
                    expire_at: new Date(budget.expire_at),
                    created_date: new Date(budget.created_date),
                    updated_date: budget.updated_date ? new Date(budget.updated_date) : null,
                    deleted_date: budget.deleted_date ? new Date(budget.deleted_date) : null,
                    months: budget.months,
                });

                if (!budget.deleted_date) {
                    this.chartData.push({
                        x: budget.name,
                        y: budget.expenditures.queue.price,
                        y1: budget.expenditures.success.price,
                        y2: budget.expenditures.deleted.price,
                        y3: budget.expenditures.residue,
                        y4: budget.amount,
                    });
                }
            });
        }

        this.tableBudgetsLoad = false;
        this.ref.markForCheck();

        return elements as DataResult[];
    }

    onCreated(): void {
        this.disableToolBarBtn();
    }

    setInitialGridFiltering(): void {
        this.gridBudgets?.clearFiltering();
        // this.gridBudgets.filterByColumn('created_by', 'equal', this.currentUser.id, 'and', false, true);
        // this.viewFilterInstance.value = 'moje žádanky - všechny';
        this.ref.markForCheck();
    }

    onLoad(): void {
        const wrapper1 = document.getElementById('scroll_wrapper1_budgets');
        const wrapper2 = document.getElementById('scroll_wrapper2_budgets');

        if (wrapper1 && wrapper2) {
            wrapper1.onscroll = (): void => {
                wrapper2.scrollLeft = wrapper1.scrollLeft;
            };

            wrapper2.onscroll = (): void => {
                wrapper1.scrollLeft = wrapper2.scrollLeft;
            };
        }

        this.dataService.budgetSource.pipe(untilDestroyed(this))
            .subscribe(
                (budgets: MaterialBudget[] | undefined) => {
                    this.budgets = [];
                    budgets?.forEach((budget: MaterialBudget) => {
                        if (this.permissionsService.viewBudget(budget)) {
                            this.budgets.push(budget);
                        }
                    });

                    if (this.budgets.length > 0 && this.gridBudgets) {
                        this.data = this.gridBudgets.dataSource = this.loadData();
                    } else {
                        this.tableBudgetsLoad = false;
                    }
                },
                error => {
                    console.error(error);
                    this.tableBudgetsLoad = false;
                    this.ref.markForCheck();
                },
            );
    }

    search(): void {
        if (this.searchtextObj) {
            this.gridBudgets?.search(this.searchtextObj.value);
        }
    }

    onDataBound(): void {
        this.rowSelected();

        const width = document.getElementById('budgetsGrid_content_table')?.offsetWidth;
        const parent1 = document.getElementById('scroll_div_budgets');
        const parent2 = document.getElementById('grid_parent_budgets');

        if (parent1 && parent2) {
            parent1.style.width = `${width ?? 0} px`;
            parent2.style.width = `${width ?? 0} px`;
        }

        this.gridBudgets?.autoFitColumns();
        this.ref.markForCheck();
    }

    rowDataBound(args: RowDataBoundEventArgs): void {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        if (args.row && args.data?.deleted_date) {
            args.row.classList.add('e-removed');
        }

        if (
            args.row &&
            this.currentUser &&
            !this.permissionService.checkUserISBudgetAdmin(this.currentUser)
        ) {
            args.row.getElementsByClassName('e-gridchkbox')[0].classList.add('disablecheckbox');
            args.row
                .getElementsByClassName('e-checkbox-wrapper')[0]
                .classList.add('disablecheckbox');
        }

        this.ref.markForCheck();
    }

    onStatusFilterChange(e: ChangeEventArgs): void {
        if (this.gridBudgets) {
            if (e.value === 'všechny rozpočty') {
                this.gridBudgets.clearFiltering();
            } else if (e.value === 'aktivní rozpočty') {
                this.gridBudgets.filterSettings = {
                    columns: [
                        {
                            field: 'deleted_date',
                            operator: 'equal',
                            value: null as unknown as string,
                            predicate: 'and',
                            matchCase: false,
                            ignoreAccent: true,
                        },
                    ],
                };
            } else if (e.value === 'expirované rozpočty') {
                this.gridBudgets.filterSettings = {
                    columns: [
                        {
                            field: 'expire_at',
                            operator: 'lessthanorequal',
                            value: new Date(),
                            predicate: 'and',
                            matchCase: false,
                            ignoreAccent: true,
                        },
                        {
                            field: 'deleted_date',
                            operator: 'notequal',
                            value: null as unknown as string,
                            predicate: 'and',
                            matchCase: false,
                            ignoreAccent: true,
                        },
                    ],
                };
            } else if (e.value === 'smazané rozpočty') {
                this.gridBudgets.filterSettings = {
                    columns: [
                        {
                            field: 'expire_at',
                            operator: 'equal',
                            value: null as unknown as string,
                            predicate: 'and',
                            matchCase: false,
                            ignoreAccent: true,
                        },
                        {
                            field: 'deleted_date',
                            operator: 'notequal',
                            value: null as unknown as string,
                            predicate: 'and',
                            matchCase: false,
                            ignoreAccent: true,
                        },
                    ],
                };
            }
        }
    }

    cancelBtnClick(): void {
        if (this.gridBudgets && this.searchtextObj) {
            this.searchtextObj.value = '';
            this.gridBudgets.searchSettings.key = '';
        }
    }

    toolbarClick(args: ClickEventArgs): void {
        const xlsProp: ExcelExportProperties = {
            fileName: 'js_budgets_export.xlsx',
            enableFilter: true,
            exportType: this.exportCurrentPage ? 'CurrentPage' : 'AllPages',
        };

        if (this.gridBudgets) {
            if (args.item.id === 'budgetsGrid_excelexport') {
                void this.gridBudgets.excelExport(xlsProp);
            } else if (args.item.id === 'delete') {
                if (this.gridBudgets.getSelectedRecords().length !== 0) {
                    this.confirmationDelete.content = `Potvrďte smazání ${this.clickedRow.length} rozpočtů`;
                    this.confirmationDelete.show();
                } else {
                    this.confirmationCancel.content = 'Musíte vybrat alespoň jeden rozpočet';
                    this.confirmationCancel.show();
                }
            } else if (args.item.id === 'copy') {
                if (this.gridBudgets.getSelectedRecords().length !== 0) {
                    this.isUpdate = false;
                    this.isCreate = false;
                    this.isCopy = true;
                    this.renderBudgetFormDialog = true;
                    this.ref.markForCheck();
                } else {
                    this.confirmationCancel.content = 'Musíte vybrat alespoň jednu objednávku';
                    this.confirmationCancel.show();
                }
            } else if (args.item.id === 'list') {
                if (this.gridBudgets.getSelectedRecords().length !== 0) {
                    this.renderBudgetItemsFormDialog = true;
                } else {
                    this.confirmationCancel.content = 'Musíte vybrat alespoň jeden rozpočet';
                    this.confirmationCancel.show();
                }
            } else if (args.item.id === 'customRefresh') {
                this.tableBudgetsLoad = true;
                console.info('refresh DATA!');
                this.ref.markForCheck();
                this.dataService.clearBudgetsCache();
                this.dataService.setBudgetsDataSource();
            }
        }
    }

    enableToolbarBtn(args: MaterialBudget[]): void {
        this.disableToolBarBtn();
        args.forEach((data: MaterialBudget) => {
            if (
                this.currentUser &&
                this.permissionService.checkUserISBudgetAdmin(this.currentUser)
            ) {
                const items = data.deleted_date
                    ? ['copy', 'list']
                    : ['budgetsGrid_edit', 'delete', 'copy', 'list'];

                this.gridBudgets?.toolbarModule.enableItems(items, true);
            }
        });
    }

    disableToolBarBtn(): void {
        this.gridBudgets?.toolbarModule.enableItems(
            ['budgetsGrid_edit', 'delete', 'copy', 'list'],
            false,
        );
    }

    rowSelected(): void {
        this.clickedRow = [];

        const selectedrecords: MaterialBudget[] =
            this.gridBudgets?.getSelectedRecords() as MaterialBudget[];

        if (selectedrecords.length > 0) {
            selectedrecords.map((row: MaterialBudget) => {
                const tempData: MaterialBudget | undefined = this.budgets.find(
                    x => x.id === row.id,
                );

                if (tempData) {
                    this.clickedRow.push(tempData);
                    this.ref.markForCheck();
                }
            });

            this.clickedRow$ = new Observable<MaterialBudget[]>(
                (observer: Observer<MaterialBudget[]>) => {
                    observer.next(this.clickedRow);
                },
            );
            this.enableToolbarBtn(this.clickedRow);
        } else {
            this.disableToolBarBtn();
            this.clickedRow$ = new Observable<MaterialBudget[]>(
                (observer: Observer<MaterialBudget[]>) => {
                    observer.next(this.clickedRow);
                },
            );
        }
    }

    actionBegin(args: ActionEventArgs): void {
        if (args.requestType === 'add' || args.requestType === 'beginEdit') {
            args.cancel = true;

            if (args.requestType === 'beginEdit') {
                this.isUpdate = true;
                this.isCreate = false;
                this.isCopy = false;
            }

            if (args.requestType === 'add') {
                this.isCreate = true;
                this.isUpdate = false;
                this.isCopy = false;
            }

            this.renderBudgetFormDialog = true;
            this.ref.markForCheck();
        }
    }

    actionComplete(args: ActionEventArgs): void {
        if (args.form && (args.requestType === 'beginEdit' || args.requestType === 'add')) {
            args.form.ej2_instances[0].rules = {};
        }
    }

    riseBudget(): void {
        this.ref.markForCheck();
    }

    async deactivateBudget(): Promise<void> {
        const promises: unknown[] = [];
        let rejects = 0;

        this.tableBudgetsLoad = true;

        for (const row of this.clickedRow) {
            if (
                this.currentUser &&
                !row.deleted_date &&
                this.permissionService.checkUserISBudgetAdmin(this.currentUser)
            ) {
                row.deleted_by = this.currentUser.id;

                const declineObj = await this.materialService.deleteBudget(row)
                    .toPromise();

                promises.push(declineObj);
            } else {
                rejects++;
            }
        }

        void Promise.all(promises)
            .then(result => {
                if (result.length > 0) {
                    const body = `Celkem smazáno: ${result.length} rozpočtů`;
                    const options = {progressBar: true, timeOut: 5000};

                    this.messages.showSuccess('Smazání úspěšně dokončeno', body, options);
                }

                if (rejects > 0) {
                    const body = `Celkem u: ${rejects} rozpočtů`;
                    const options = {progressBar: true, timeOut: 5000};

                    this.messages.showError('Nedostatečné oprávnění', body, options);
                }

                this.gridBudgets?.clearRowSelection();
                this.dataService.setBudgetsDataSource();
                this.rowSelected();
                this.tableBudgetsLoad = false;
                this.confirmationDelete.hide();
                this.ref.markForCheck();
            });
    }

    refreshData(): void {
        this.tableBudgetsLoad = true;
        this.dataService.clearBudgetsCache();
        this.dataService.setBudgetsDataSource();
    }
}
