<div class="row d-flex justify-content-center modalWrapper">
  <ejs-dialog
    #confirmationDelete
    [buttons]="buttonsDelete"
    [content]="'Opravdu chcete smazat tuto objednávku?'"
    [header]="'Smazání objednávky'"
    [isModal]="true"
    [showCloseIcon]="true"
    [visible]="false"
    width="250px"
  ></ejs-dialog>
  <ejs-dialog
    #confirmationDecline
    [buttons]="buttonsDecline"
    [content]="'Opravdu chcete zamítnout tuto objednávku?'"
    [header]="'Zamítnutí objednávky'"
    [isModal]="true"
    [showCloseIcon]="true"
    [visible]="false"
    width="250px"
  ></ejs-dialog>
  <ejs-dialog
    #confirmationConfirm
    [buttons]="buttonsConfirm"
    [content]="'Opravdu chcete schválit tuto objednávku?'"
    [header]="'Schválení objednávky'"
    [isModal]="true"
    [showCloseIcon]="true"
    [visible]="false"
    width="250px"
  ></ejs-dialog>
  <ejs-dialog
    #AdminconfirmationConfirm
    [buttons]="buttonsAdminConfirm"
    [content]="
            'Opravdu chcete schválit tuto objednávku? Tímto schválite tuto objednávku za všechny přiřazené schvalovatele, kteří ještě nepotvrdili nákup tohoto zboží.'
        "
    [header]="'Nadřízené schválení objednávky'"
    [isModal]="true"
    [showCloseIcon]="true"
    [visible]="false"
    width="250px"
  ></ejs-dialog>
  <ejs-dialog
    #AdminconfirmationDecline
    [buttons]="buttonsAdminDecline"
    [content]="
            'Opravdu chcete zamítnout tuto objednávku? Tímto zamítnete tuto objednávku za všechny přiřazené schvalovatele, kteří ještě nepotvrdili nákup tohoto zboží.'
        "
    [header]="'Nadřízené zamítnutí objednávky'"
    [isModal]="true"
    [showCloseIcon]="true"
    [visible]="false"
    width="250px"
  ></ejs-dialog>
  <ejs-dialog
    #confirmationItemDelete
    [buttons]="buttonsItemDelete"
    [content]="'Opravdu chcete smazat označenou položku objednávky?'"
    [header]="'Smazat položku objednávky'"
    [isModal]="true"
    [showCloseIcon]="true"
    [visible]="false"
    width="250px"
  ></ejs-dialog>
  <ejs-dialog
    #confirmationCancel
    [buttons]="buttonsCancel"
    [header]="'Nelze provést akci'"
    [isModal]="true"
    [showCloseIcon]="true"
    [visible]="false"
    width="250px"
  ></ejs-dialog>
  <app-material-form
    (materialFormDialogState)="
            renderMaterialFormDialog = $event;
            isCreate = $event;
            isUpdate = $event;
            isCopy = $event;
            gridItems?.clearRowSelection();
            disableToolBarBtn();
            gridLogs?.clearRowSelection();
            disableToolBarBtn()
        "
    [isCopy]="isCopy"
    [isCreate]="isCreate"
    [isUpdate]="isUpdate"
    [isVisible]="renderMaterialFormDialog"
    [material]="material && (isUpdate || isCopy) ? material : null"
  ></app-material-form>
  <app-material-item-form
    (materialItemFormDialogState)="
            renderMaterialItemFormDialog = $event;
            isCreateItem = $event;
            isUpdateItem = $event;
            isCopyItem = $event
        "
    [isCopy]="isCopyItem"
    [isCreate]="isCreateItem"
    [isUpdate]="isUpdateItem"
    [isVisible]="renderMaterialItemFormDialog"
    [item]="
            clickedRow && clickedRow[0] && (isUpdateItem || isCopyItem)
                ? clickedRow[0]
                : null
        "
  ></app-material-item-form>
</div>
<div class="row">
  <div class="col-12 col-md-8 col-lg-9 p-2">
    <ejs-tab
      #materialDetailTab
      (created)="onCreate()"
      (selected)="select($event)"
      (selecting)="preventSwipe($event)"
      enablePersistence="true"
      height="auto"
      heightAdjustMode="Content"
      id="materialDetailTab"
    >
      <e-tabitems>
        <e-tabitem [header]="headerText[0]">
          <ng-template #content>
            <div class="e-card" tabindex="0">
              <ng-container *ngIf="material && !loadingMaterial">
                <div
                  class="e-card-header stylish-color-dark p-2"
                >
                  <div class="e-card-header-caption">
                    <div
                      class="e-card-header-title text-white"
                    >
                      <strong
                        [className]="
                                                    !material.submited
                                                        ? 'h4-responsive m-0 p-0 text-warning'
                                                        : 'h4-responsive m-0 p-0 text-success'
                                                "
                        class="m-0 p-0"
                      >Objednávka: #{{
                          material.id
                        }}</strong
                      ><br />
                      <strong
                        *ngIf="
                                                    material.submited &&
                                                    !material.declined &&
                                                    !material.deleted_date
                                                "
                        class="e-badge e-badge-success"
                      >schváleno</strong
                      >
                      <strong
                        *ngIf="
                                                    !material.submited &&
                                                    material.declined &&
                                                    !material.deleted_date
                                                "
                        class="e-badge e-badge-danger"
                      >zamítnuto</strong
                      >
                      <strong
                        *ngIf="material.deleted_date"
                        class="e-badge e-badge-danger"
                      >staženo</strong
                      >
                      <strong
                        *ngIf="
                                                    !material.submited &&
                                                    !material.declined &&
                                                    !material.deleted_date
                                                "
                        class="e-badge e-badge-warning"
                      >ve frontě</strong
                      >
                    </div>
                  </div>
                </div>
                <div class="row stylish-color-dark p-2">
                  <div
                    aria-label="Material toolbar"
                    class="col-12 text-right"
                    role="toolbar"
                  >
                    <button
                      (click)="editMaterial()"
                      *ngIf="
                                                material &&
                                                ((material.created_by ===
                                                    currentUser.id &&
                                                    !material.submited &&
                                                    !material.declined) ||
                                                    (currentUser.rule &&
                                                        currentUser.rule
                                                            .is_admin)) &&
                                                !material.deleted_date
                                            "
                      cssClass="e-btn e-warning btn-p-zero e-small"
                      ejs-button
                    >
                      <i
                        aria-hidden="true"
                        class="fa-light fa-pencil mr-lg-1"
                      ></i>
                      <span class="d-none d-lg-inline"
                      >upravit</span
                      >
                    </button>
                    <button
                      (click)="confirmationDelete.show()"
                      *ngIf="
                                                material &&
                                                ((material.created_by ===
                                                    currentUser.id &&
                                                    !material.submited &&
                                                    !material.declined) ||
                                                    (currentUser.rule &&
                                                        currentUser.rule
                                                            .is_admin)) &&
                                                !material.deleted_date
                                            "
                      cssClass="e-btn e-danger btn-p-zero e-small"
                      ejs-button
                    >
                      <i
                        aria-hidden="true"
                        class="fa-light fa-trash mr-lg-1"
                      ></i>
                      <span class="d-none d-lg-inline"
                      >smazat</span
                      >
                    </button>
                    <button
                      (click)="
                                                adminconfirmationConfirm.show()
                                            "
                      *ngIf="
                                                material &&
                                                currentUser.rule &&
                                                currentUser.rule.is_admin &&
                                                !material.deleted_date &&
                                                !material.submited &&
                                                !material.declined
                                            "
                      cssClass="e-btn e-success btn-p-zero e-small"
                      data-target="#modalAdminConfirm"
                      ejs-button
                    >
                      přidělit schválení
                      <i
                        aria-hidden="true"
                        class="fa-light fa-check ml-1"
                      ></i>
                    </button>
                    <button
                      (click)="
                                                adminconfirmationDecline.show()
                                            "
                      *ngIf="
                                                material &&
                                                currentUser.rule &&
                                                currentUser.rule.is_admin &&
                                                !material.deleted_date &&
                                                !material.submited &&
                                                !material.declined
                                            "
                      cssClass="e-btn e-danger btn-p-zero e-small"
                      data-target="#modalAdminDecline"
                      ejs-button
                    >
                      přidělit zamítnutí
                      <i
                        aria-hidden="true"
                        class="fa-light fa-times ml-1"
                      ></i>
                    </button>
                  </div>
                </div>
                <div class="e-card-content">
                  <div class="row mt-3 mb-2 p-2 pt-0">
                    <div class="col-12 text-right mb-2">
                      <strong
                        [ngClass]="{
                                                    striked:
                                                        material.deleted_date
                                                }"
                        class="text-danger"
                      >
                        {{
                          material.totalCountPrice
                            | number: '1.2-2'
                        }},- CZK bez DPH </strong
                      >&nbsp;
                      <small
                        [ngClass]="{
                                                    striked:
                                                        material.deleted_date
                                                }"
                        class="text-black-imp"
                      >
                        v
                        {{ material.totalCountItem }}
                        položkách
                      </small>
                    </div>
                    <div
                      class="col-12"
                      style="overflow-x: auto"
                    >
                      <table
                        class="col-12 table-sm-responsive text-black-imp"
                      >
                        <tr class="border-bottom">
                          <th>Důvod:</th>
                          <td class="text-right">{{
                              material.reason
                            }}
                          </td>
                        </tr>
                        <tr class="border-bottom">
                          <th>Vytvořeno:</th>
                          <td class="text-right">
                            {{
                              material.created_date
                                | dateFormat
                                : 'DD.MM.YYYY HH:mm'
                            }}
                            <span
                              *ngIf="
                                                                material.unixCreatedTime
                                                            "
                              class="text-muted"
                            >(
                              {{
                                material.unixCreatedTime
                                  | timeago
                                  : live
                              }}
                              )</span
                            >
                          </td>
                        </tr>
                        <tr class="border-bottom">
                          <th>Typ platby:</th>
                          <td class="text-right">
                                                        <span
                                                          *ngIf="
                                                                material.payment
                                                                    .name
                                                            "
                                                        >{{
                                                            material.payment
                                                              .name
                                                          }}</span
                                                        >
                          </td>
                        </tr>
                        <tr class="border-bottom">
                          <th>Počet položek:</th>
                          <td class="text-right">
                                                        <span
                                                          *ngIf="
                                                                material
                                                                    .orderitems
                                                                    .length > 0
                                                            "
                                                        >{{
                                                            material
                                                              .orderitems
                                                              .length
                                                          }}</span
                                                        >
                          </td>
                        </tr>
                        <tr class="border-bottom">
                          <th>Typ materiálu:</th>
                          <td class="text-right">
                                                        <span
                                                          *ngIf="
                                                                material.to_storage
                                                            "
                                                        >Materiál na
                                                            doplnění skladových
                                                            zásob
                                                            <small
                                                              class="text-danger"
                                                            >(odečítá se z
                                                                rozpočtů)</small
                                                            ></span
                                                        >
                            <span
                              *ngIf="
                                                                !material.to_storage
                                                            "
                            >Materiál na
                                                            přeprodej
                                                            <small
                                                              class="text-success"
                                                            >(neodečítá se z
                                                                rozpočtů)</small
                                                            ></span
                            >
                          </td>
                        </tr>
                        <tr>
                          <th>Poznámka:</th>
                          <td class="text-right">
                            <p>
                              {{ material.note }}
                            </p>
                          </td>
                        </tr>
                      </table>
                    </div>
                  </div>
                  <div
                    *ngIf="
                                            material.to_storage &&
                                            material.usedBudgets &&
                                            material.usedBudgets.length > 0
                                        "
                    class="row mb-2"
                  >
                    <div class="col-12">
                      <div class="e-card">
                        <div class="e-card-content">
                                                    <span class="text-black-imp"
                                                    >Čerpání rozpočtů:</span
                                                    >
                          <hr class="p-1 m-0" />
                          <ul
                            class="list-group list-group-flush"
                            id="budgetsList"
                          >
                            <li
                              *ngFor="
                                                                let budget of material.usedBudgets;
                                                                let i = index
                                                            "
                              [ngClass]="{
                                                                'list-group-item-danger':
                                                                    budget
                                                                        .expenditures
                                                                        .residue <
                                                                    0,
                                                                'list-group-item-warning':
                                                                    budget.amount -
                                                                        budget
                                                                            .expenditures
                                                                            .success
                                                                            .price -
                                                                        budget
                                                                            .expenditures_current
                                                                            .price <=
                                                                    0
                                                            }"
                              class="list-group-item p-1 border-top-0"
                            >
                              <div
                                class="row p-2"
                              >
                                <div
                                  class="col-12"
                                >
                                  <div
                                    *ngIf="
                                                                            budget
                                                                                .expenditures
                                                                                .residue <=
                                                                            0
                                                                        "
                                    class="alert alert-danger p-1"
                                    role="alert"
                                  >
                                    <i
                                      aria-hidden="true"
                                      class="fa-light fa-lg fa-exclamation-triangle mr-2"
                                    ></i
                                    >Rozpočet
                                    již je
                                    <strong
                                    >vyčerpán</strong
                                    >.
                                  </div>
                                  <div
                                    *ngIf="
                                                                            !material.submited &&
                                                                            !material.declined &&
                                                                            !material.deleted_date &&
                                                                            budget.amount -
                                                                                budget
                                                                                    .expenditures
                                                                                    .success
                                                                                    .price -
                                                                                budget
                                                                                    .expenditures_current
                                                                                    .price <=
                                                                                0
                                                                        "
                                    class="alert alert-warning p-1"
                                    role="alert"
                                  >
                                    <i
                                      aria-hidden="true"
                                      class="fa-light fa-lg fa-exclamation-triangle mr-2"
                                    ></i
                                    >Touto
                                    objednávkou
                                    bude
                                    <strong
                                    >přečerpán
                                      rozpočet</strong
                                    >.
                                  </div>
                                </div>
                                <div
                                  class="col-12 col-md-6 text-center text-md-left"
                                >
                                  <small>
                                    <a
                                      [routerLink]="[
                                                                                '/material/budgets/',
                                                                                budget.id
                                                                            ]"
                                    >
                                      {{
                                        budget
                                          .department
                                          .name
                                      }}&nbsp;&middot;&nbsp;<strong
                                    >{{
                                        budget.name
                                      }}</strong
                                    >
                                    </a>
                                    <br />
                                    <span
                                      class="text-primary"
                                    >
                                                                            Původní:
                                      {{
                                        budget.amount
                                          | number
                                          : '1.2-2'
                                      }},-
                                                                            CZK
                                                                        </span>
                                    <br />
                                    <span
                                      class="text-danger"
                                    >
                                                                            Čerpáno:
                                      {{
                                        budget
                                          .expenditures
                                          .success
                                          .price
                                          | number
                                          : '1.2-2'
                                      }}
                                      ,-
                                                                            CZK
                                                                            bez
                                                                            DPH
                                                                        </span>
                                    <br />
                                    <span
                                      class="text-success"
                                    >
                                                                            Zbývá:
                                      {{
                                        budget
                                          .expenditures
                                          .residue
                                          | number
                                          : '1.2-2'
                                      }},-
                                                                            CZK
                                                                        </span>
                                    <br />
                                    <span
                                      *ngIf="
                                                                                budget
                                                                                    .expenditures
                                                                                    .residue <
                                                                                0
                                                                            "
                                      class="e-badge e-badge-danger"
                                    >přečerpáno</span
                                    >
                                    <span
                                      *ngIf="
                                                                                budget[
                                                                                    'startUnix'
                                                                                ] <=
                                                                                    todayUnix &&
                                                                                budget[
                                                                                    'endUnix'
                                                                                ] >=
                                                                                    todayUnix &&
                                                                                !budget.deleted_date
                                                                            "
                                      class="e-badge e-badge-success"
                                    >aktivní</span
                                    >
                                    <span
                                      *ngIf="
                                                                                budget[
                                                                                    'startUnix'
                                                                                ] >
                                                                                    todayUnix &&
                                                                                budget[
                                                                                    'endUnix'
                                                                                ] <
                                                                                    todayUnix &&
                                                                                !budget.deleted_date
                                                                            "
                                      class="e-badge e-badge-danger"
                                    >neaktivní</span
                                    >
                                    <span
                                      *ngIf="
                                                                                budget.deleted_date
                                                                            "
                                      class="e-badge e-badge-danger"
                                    >deaktivováno</span
                                    >
                                  </small>
                                </div>
                                <div
                                  class="col-12 col-md-6 text-center text-md-right"
                                >
                                  <strong
                                    [ngClass]="{
                                                                            striked:
                                                                                material.deleted_date
                                                                        }"
                                    class="text-danger"
                                  >
                                    -
                                    {{
                                      budget
                                        .expenditures_current
                                        .price
                                        | number
                                        : '1.2-2'
                                    }}
                                    ,- CZK
                                    bez DPH </strong
                                  >&nbsp;
                                  <small
                                    [ngClass]="{
                                                                            striked:
                                                                                material.deleted_date
                                                                        }"
                                    class="text-black-imp"
                                  >
                                    v
                                    {{
                                      budget
                                        .expenditures_current
                                        .items
                                    }}
                                    položkách
                                  </small>
                                  <br />
                                  <ul
                                    class="list-group"
                                    id="budgetApproversList"
                                  >
                                    <li
                                      *ngFor="
                                                                                let confirmer of budget.approvers;
                                                                                let i = index
                                                                            "
                                      [ngClass]="{
                                                                                'list-group-item-danger':
                                                                                    budget
                                                                                        .expenditures
                                                                                        .residue <
                                                                                    0,
                                                                                'list-group-item-warning':
                                                                                    budget.amount -
                                                                                        budget
                                                                                            .expenditures
                                                                                            .success
                                                                                            .price -
                                                                                        budget
                                                                                            .expenditures_current
                                                                                            .price <=
                                                                                    0
                                                                            }"
                                      class="list-group-item p-1 border-top-0"
                                    >
                                      <img
                                        alt="profilový obrázek"
                                        class="img-fluid rounded-circle avatar-xs mr-1 ml-2"
                                        src="assets/img{{
                                                                                    confirmer.personalphoto
                                                                                }}"
                                      />
                                      <span
                                        class="float-right text-right"
                                      >
                                                                                <small
                                                                                  class="text-black-imp"
                                                                                >{{
                                                                                    confirmer.firstname
                                                                                  }}
                                                                                  {{
                                                                                    confirmer.secondname
                                                                                  }}</small
                                                                                >
                                                                            </span>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    *ngIf="!material.to_storage"
                    class="row mb-2"
                  >
                    <div class="col-12">
                      <div
                        class="alert alert-primary"
                        role="alert"
                      >
                        Tato objednávka je na přeprodej,
                        očekává se její refundace.
                      </div>
                    </div>
                  </div>
                </div>
              </ng-container>
              <ng-container *ngIf="!material || loadingMaterial">
                <div class="e-card-content text-center">
                  <app-loader></app-loader>
                </div>
              </ng-container>
            </div>
          </ng-template>
        </e-tabitem>
        <e-tabitem [header]="headerText[1]">
          <ng-template #content>
            <div class="e-card" tabindex="0">
              <ng-container *ngIf="material && !loadingMaterial">
                <div class="e-card-content">
                  <ejs-toolbar
                    id="filterToolbar"
                    overflowMode="Scrollable"
                    scrollStep="500"
                  >
                    <e-items>
                      <e-item
                        [cssClass]="'statusFilter'"
                        tooltipText="Přednastavené hledání"
                        type="input"
                      >
                        <ng-template #template>
                          <ejs-dropdownlist
                            #viewFilter
                            (change)="
                                                            onStatusFilterChange(
                                                                $event
                                                            )
                                                        "
                            [dataSource]="
                                                            categoryFilterData
                                                        "
                            [width]="500"
                            placeholder="Vyberte přednastavený pohled..."
                          ></ejs-dropdownlist>
                        </ng-template>
                      </e-item>
                      <e-item type="Separator"></e-item>
                      <e-item
                        [cssClass]="'fulltextFilter'"
                        tooltipText="Text pro vyhledávání"
                        type="input"
                      >
                        <ng-template #template>
                          <ejs-textbox
                            #searchtext
                            [width]="500"
                            class="mr-2"
                            placeholder="Vyhledat..."
                          ></ejs-textbox>
                          <button
                            (click)="search()"
                            class="e-btn e-info btn-p-zero e-small"
                          >
                            <i
                              class="fa-light fa-lg fa-search mr-1"
                            ></i
                            ><span
                            class="d-none d-lg-inline"
                          >vyhledat</span
                          >
                          </button>
                          <button
                            (click)="
                                                            cancelBtnClick()
                                                        "
                            class="e-btn e-danger e-outline btn-p-zero e-small"
                          >
                            <i
                              class="fa-light fa-lg fa-times"
                            ></i>
                          </button>
                        </ng-template>
                      </e-item>
                    </e-items>
                  </ejs-toolbar>
                  <div class="control-section">
                    <div
                      class="scroll_wrapper1"
                      id="scroll_wrapper1_items"
                    >
                      <div
                        class="scroll_div"
                        id="scroll_div_items"
                      ></div>
                    </div>
                    <div
                      class="scroll_wrapper2"
                      id="scroll_wrapper2_items"
                    >
                      <div
                        class="grid_parent"
                        id="grid_parent_items"
                      >
                        <ejs-grid
                          #itemsGrid
                          (created)="onCreated()"
                          (dataBound)="onDataBound()"
                          (load)="onLoad()"
                          (loadeddata)="
                                                        setInitialGridFiltering()
                                                    "
                          (rowDataBound)="
                                                        rowDataBound($event)
                                                    "
                          (rowDeselected)="
                                                        rowSelected()
                                                    "
                          (rowSelected)="
                                                        rowSelected()
                                                    "
                          (toolbarClick)="
                                                        toolbarClick($event)
                                                    "
                          [allowExcelExport]="true"
                          [allowFiltering]="true"
                          [allowMultiSorting]="true"
                          [allowPdfExport]="false"
                          [allowSelection]="true"
                          [allowSorting]="true"
                          [dataSource]="data"
                          [editSettings]="
                                                        editSettings
                                                    "
                          [enableHover]="false"
                          [enablePersistence]="false"
                          [enableVirtualization]="
                                                        true
                                                    "
                          [filterSettings]="
                                                        filterSettings
                                                    "
                          [groupSettings]="
                                                        groupOptions
                                                    "
                          [pageSettings]="pageOptions"
                          [selectionSettings]="
                                                        selectionSettings
                                                    "
                          [sortSettings]="
                                                        initialItemsSort
                                                    "
                          [toolbar]="toolbar"
                          allowGrouping="true"
                          allowReordering="true"
                          allowResizing="true"
                          allowTextWrap="true"
                          height="100%"
                          id="itemsGrid"
                          locale="cs"
                          rowHeight="38"
                          showColumnMenu="true"
                          width="100%"
                        >
                          <e-columns>
                            <e-column
                              [allowEditing]="
                                                                false
                                                            "
                              [allowFiltering]="
                                                                false
                                                            "
                              [allowReordering]="
                                                                false
                                                            "
                              [allowResizing]="
                                                                false
                                                            "
                              [allowSorting]="
                                                                false
                                                            "
                              [autoFit]="true"
                              type="checkbox"
                            ></e-column>
                            <e-column
                              [allowFiltering]="
                                                                false
                                                            "
                              [allowReordering]="
                                                                true
                                                            "
                              [allowResizing]="
                                                                false
                                                            "
                              [autoFit]="true"
                              [isPrimaryKey]="
                                                                true
                                                            "
                              [visible]="true"
                              field="id"
                              headerText="ID"
                            >
                              <ng-template
                                #template
                                let-data
                              >
                                <a
                                  routerLink="/material/detail/{{
                                                                        data.material_id
                                                                    }}"
                                >{{
                                    data.id
                                  }}</a
                                >
                              </ng-template>
                            </e-column>
                            <e-column
                              [allowEditing]="
                                                                false
                                                            "
                              [allowFiltering]="
                                                                true
                                                            "
                              [allowReordering]="
                                                                false
                                                            "
                              [allowResizing]="
                                                                false
                                                            "
                              [autoFit]="true"
                              [visible]="false"
                              field="created_by"
                              headerText="Creator ID"
                            ></e-column>
                            <e-column
                              [autoFit]="true"
                              [filter]="
                                                                filterSettings
                                                            "
                              clipMode="EllipsisWithTooltip"
                              field="name"
                              headerText="Název položky"
                            ></e-column>
                            <e-column
                              [autoFit]="true"
                              [filter]="
                                                                filterSettings
                                                            "
                              clipMode="EllipsisWithTooltip"
                              field="status"
                              headerText="Stav"
                              textAlign="center"
                            >
                              <ng-template
                                #template
                                let-data
                              >
                                                                <span
                                                                  *ngIf="
                                                                        data.status ===
                                                                        've frontě'
                                                                    "
                                                                  class="e-badge e-badge-pill e-badge-warning"
                                                                >ve
                                                                    frontě</span
                                                                >
                                <span
                                  *ngIf="
                                                                        data.status ===
                                                                        'stornováno'
                                                                    "
                                  class="e-badge e-badge-pill e-badge-danger"
                                >stornováno</span
                                >
                                <span
                                  *ngIf="
                                                                        data.status ===
                                                                        'schváleno'
                                                                    "
                                  class="e-badge e-badge-pill e-badge-success"
                                >schváleno</span
                                >
                                <span
                                  *ngIf="
                                                                        data.status ===
                                                                        'zamítnuto'
                                                                    "
                                  class="e-badge e-badge-pill e-badge-danger"
                                >zamítnuto</span
                                >
                              </ng-template>
                            </e-column>
                            <e-column
                              [autoFit]="true"
                              [filter]="
                                                                filterSettings
                                                            "
                              [format]="'N'"
                              clipMode="EllipsisWithTooltip"
                              field="amount"
                              headerText="Množství"
                              textAlign="Right"
                            >
                            </e-column>
                            <e-column
                              [autoFit]="true"
                              [filter]="
                                                                filterSettings
                                                            "
                              [format]="
                                                                '#,###.00\',- CZK\''
                                                            "
                              clipMode="EllipsisWithTooltip"
                              field="price"
                              headerText="Cena"
                              textAlign="Right"
                            >
                            </e-column>
                            <e-column
                              [autoFit]="true"
                              [filter]="
                                                                filterSettings
                                                            "
                              clipMode="EllipsisWithTooltip"
                              field="dph"
                              headerText="DPH"
                              textAlign="center"
                            >
                              <ng-template
                                #template
                                let-data
                              >
                                                                <span
                                                                  *ngIf="
                                                                        data.dph
                                                                    "
                                                                  class="e-badge e-badge-pill e-badge-success"
                                                                >s DPH</span
                                                                >
                                <span
                                  *ngIf="
                                                                        !data.dph
                                                                    "
                                  class="e-badge e-badge-pill e-badge-danger"
                                >bez
                                                                    DPH</span
                                >
                              </ng-template>
                            </e-column>
                            <e-column
                              [autoFit]="true"
                              [filter]="
                                                                filterSettings
                                                            "
                              clipMode="EllipsisWithTooltip"
                              field="category"
                              headerText="Kategorie materiálu"
                            >
                            </e-column>
                            <e-column
                              [autoFit]="true"
                              [filter]="
                                                                filterSettings
                                                            "
                              clipMode="EllipsisWithTooltip"
                              field="budget"
                              headerText="Rozpočet"
                            >
                            </e-column>
                            <e-column
                              [autoFit]="true"
                              [filter]="
                                                                filterSettings
                                                            "
                              clipMode="EllipsisWithTooltip"
                              field="department"
                              headerText="Oddělení"
                            >
                            </e-column>
                            <e-column
                              [autoFit]="true"
                              [filter]="
                                                                filterSettings
                                                            "
                              clipMode="EllipsisWithTooltip"
                              field="company"
                              headerText="Společnost"
                            >
                            </e-column>
                            <e-column
                              [autoFit]="true"
                              [filter]="
                                                                filterSettings
                                                            "
                              clipMode="EllipsisWithTooltip"
                              field="creator_str"
                              headerText="Vytvořil"
                            >
                              <ng-template
                                #template
                                let-data
                              >
                                <div>
                                  <img
                                    *ngIf="
                                                                            data.creator_img
                                                                        "
                                    alt="profilový obrázek"
                                    class="img-fluid rounded-circle avatar-xs mr-1"
                                    src="assets/img{{
                                                                            data.creator_img
                                                                        }}"
                                  />
                                  <span>{{
                                      data.creator_str
                                    }}</span>
                                </div>
                              </ng-template>
                            </e-column>
                            <e-column
                              [allowEditing]="
                                                                false
                                                            "
                              [autoFit]="true"
                              [filter]="
                                                                filterDate
                                                            "
                              [format]="
                                                                dateFormat
                                                            "
                              clipMode="EllipsisWithTooltip"
                              editType="datepickeredit"
                              field="created_date"
                              headerText="Vytvořeno"
                              textAlign="Right"
                              type="date"
                            ></e-column>
                            <e-column
                              [allowEditing]="
                                                                false
                                                            "
                              [autoFit]="true"
                              [filter]="
                                                                filterDate
                                                            "
                              [format]="
                                                                dateFormat
                                                            "
                              clipMode="EllipsisWithTooltip"
                              editType="datepickeredit"
                              field="updated_date"
                              headerText="Naposledy upraveno"
                              textAlign="Right"
                              type="date"
                            ></e-column>
                            <e-column
                              [allowEditing]="
                                                                false
                                                            "
                              [autoFit]="true"
                              [filter]="
                                                                filterDate
                                                            "
                              [format]="
                                                                dateFormat
                                                            "
                              clipMode="EllipsisWithTooltip"
                              editType="datepickeredit"
                              field="deleted_date"
                              headerText="Smazáno"
                              textAlign="Right"
                              type="date"
                            ></e-column>
                          </e-columns>
                          <e-aggregates>
                            <e-aggregate>
                              <e-columns>
                                <e-column
                                  [format]="
                                                                        'N'
                                                                    "
                                  field="id"
                                  type="Count"
                                >
                                  <ng-template
                                    #footerTemplate
                                    let-data
                                  >
                                    Celkem
                                    řádků:
                                    {{
                                      data.Count
                                    }}
                                  </ng-template
                                  >
                                </e-column>
                                <e-column
                                  [format]="
                                                                        'N'
                                                                    "
                                  field="amount"
                                  type="Sum"
                                >
                                  <ng-template
                                    #footerTemplate
                                    let-data
                                  >
                                    Celkové
                                    množství:
                                    {{
                                      data.Sum
                                    }}
                                  </ng-template>
                                </e-column>
                                <e-column
                                  [format]="
                                                                        '#,###.00\',- CZK\''
                                                                    "
                                  field="price"
                                  type="Sum"
                                >
                                  <ng-template
                                    #footerTemplate
                                    let-data
                                  >
                                    Celkeová
                                    cena:
                                    {{
                                      data.Sum
                                    }}
                                  </ng-template>
                                </e-column>
                              </e-columns>
                            </e-aggregate>
                            <e-aggregate>
                              <e-columns>
                                <e-column
                                  [format]="
                                                                        'N'
                                                                    "
                                  field="amount"
                                  type="Average"
                                >
                                  <ng-template
                                    #footerTemplate
                                    let-data
                                  >
                                    Průměrné
                                    množství:
                                    {{
                                      data.Average
                                    }}
                                  </ng-template>
                                </e-column>
                                <e-column
                                  [format]="
                                                                        '#,###.00\',- CZK\''
                                                                    "
                                  field="price"
                                  type="Average"
                                >
                                  <ng-template
                                    #footerTemplate
                                    let-data
                                  >
                                    Průměrná
                                    cena:
                                    {{
                                      data.Average
                                    }}
                                  </ng-template>
                                </e-column>
                              </e-columns>
                            </e-aggregate>
                            <e-aggregate>
                              <e-columns>
                                <e-column
                                  [format]="
                                                                        'N'
                                                                    "
                                  field="amount"
                                  type="Max"
                                >
                                  <ng-template
                                    #footerTemplate
                                    let-data
                                  >
                                    Maximální
                                    množství:
                                    {{
                                      data.Max
                                    }}
                                  </ng-template>
                                </e-column>
                                <e-column
                                  [format]="
                                                                        '#,###.00\',- CZK\''
                                                                    "
                                  field="price"
                                  type="Max"
                                >
                                  <ng-template
                                    #footerTemplate
                                    let-data
                                  >
                                    Maximální
                                    cena:
                                    {{
                                      data.Max
                                    }}
                                  </ng-template>
                                </e-column>
                              </e-columns>
                            </e-aggregate>
                          </e-aggregates>
                        </ejs-grid>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-container>
              <ng-container *ngIf="!material || loadingMaterial">
                <div class="e-card-content text-center">
                  <app-loader></app-loader>
                </div>
              </ng-container>
            </div>
          </ng-template>
        </e-tabitem>
        <e-tabitem [header]="headerText[2]">
          <ng-template #content>
            <div class="e-card" tabindex="0">
              <ng-container *ngIf="material && !loadingMaterial">
                <div class="e-card-content">
                  <div class="row">
                    <ng-container
                      *ngIf="
                                                !material.submited &&
                                                !material.declined &&
                                                !material.deleted_date
                                            "
                    >
                      <div class="col-12 text-center">
                        <label for="preloadfileupload"
                        >Faktura/Doklad:
                        </label>
                        <ejs-uploader
                          #preloadupload
                          (beforeUpload)="
                                                        beforeFileUpload($event)
                                                    "
                          (created)="
                                                        applyDeafultUploadSetting()
                                                    "
                          (selected)="
                                                        onFileSelected($event)
                                                    "
                          (success)="
                                                        onSuccessUpload($event)
                                                    "
                          (uploading)="
                                                        onFileUpload($event)
                                                    "
                          [asyncSettings]="path"
                          [dropArea]="dropElement"
                          [enabled]="
                                                        !material.deleted_date
                                                    "
                          allowedExtensions=".jpg,.png,.jpeg,.xls,.xlsx,.pdf,.doc,.docx,.rar,.zip,.csv,.json,.odt,.ods,.txt,.xml"
                          id="preloadfileupload"
                          maxFileSize="400000000"
                        >
                        </ejs-uploader>
                      </div>
                    </ng-container>
                  </div>
                  <div
                    class="e-card-separator mb-1 mt-1"
                  ></div>
                  <app-file-viewer
                    (deleteFileEventEvent)="
                                            deleteFileId = $event;
                                            removeMaterialFile()
                                        "
                    [files]="material.receipts"
                    [images]="imagesBasic"
                  ></app-file-viewer>
                </div>
              </ng-container>
              <ng-container *ngIf="!material || loadingMaterial">
                <div class="e-card-content text-center">
                  <app-loader></app-loader>
                </div>
              </ng-container>
            </div>
          </ng-template>
        </e-tabitem>
        <e-tabitem [header]="headerText[3]">
          <ng-template #content>
            <div class="e-card">
              <ng-container *ngIf="material && !loadingMaterial">
                <div class="e-card-header">
                  <h5 class="e-card-title">
                    <i class="fa-light fa-history mr-2"></i
                    >Historie změn
                  </h5>
                </div>
                <div class="e-card-content">
                  <ejs-toolbar
                    id="filterLogsToolbar"
                    overflowMode="Scrollable"
                    scrollStep="500"
                  >
                    <e-items>
                      <e-item
                        [cssClass]="'statusFilter'"
                        tooltipText="Přednastavené hledání"
                        type="input"
                      >
                        <ng-template #template>
                          <ejs-dropdownlist
                            #viewLogsFilter
                            (change)="
                                                            onStatusLogsFilterChange(
                                                                $event
                                                            )
                                                        "
                            [dataSource]="
                                                            categoryLogsFilterData
                                                        "
                            [width]="500"
                            placeholder="Vyberte přednastavený pohled..."
                          ></ejs-dropdownlist>
                        </ng-template>
                      </e-item>
                      <e-item type="Separator"></e-item>
                      <e-item
                        [cssClass]="'fulltextFilter'"
                        tooltipText="Text pro vyhledávání"
                        type="input"
                      >
                        <ng-template #template>
                          <ejs-textbox
                            #searchLogsText
                            [width]="500"
                            class="mr-2"
                            placeholder="Vyhledat..."
                          ></ejs-textbox>
                          <button
                            (click)="searchLogs()"
                            class="e-btn e-info btn-p-zero e-small"
                          >
                            <i
                              class="fa-light fa-lg fa-search mr-1"
                            ></i
                            ><span
                            class="d-none d-lg-inline"
                          >vyhledat</span
                          >
                          </button>
                          <button
                            (click)="
                                                            cancelBtnLogsClick()
                                                        "
                            class="e-btn e-danger e-outline btn-p-zero e-small"
                          >
                            <i
                              class="fa-light fa-lg fa-times"
                            ></i>
                          </button>
                        </ng-template>
                      </e-item>
                    </e-items>
                  </ejs-toolbar>
                  <div class="control-section">
                    <div
                      class="scroll_wrapper1"
                      id="scroll_wrapper1_logs"
                    >
                      <div class="scroll_div"></div>
                    </div>
                    <div
                      class="scroll_wrapper2"
                      id="scroll_wrapper2_logs"
                    >
                      <div class="grid_parent">
                        <ejs-grid
                          #materialLogsGrid
                          (created)="
                                                        onHistoryCreated()
                                                    "
                          (dataBound)="
                                                        onHistoryDataBound()
                                                    "
                          (load)="onHistoryLoad()"
                          (loadeddata)="
                                                        setInitialHistoryGridFiltering()
                                                    "
                          (rowDataBound)="
                                                        rowHistoryDataBound(
                                                            $event
                                                        )
                                                    "
                          (rowDeselected)="
                                                        rowHistorySelected()
                                                    "
                          (rowSelected)="
                                                        rowHistorySelected()
                                                    "
                          (toolbarClick)="
                                                        toolbarHistoryClick(
                                                            $event
                                                        )
                                                    "
                          [allowExcelExport]="true"
                          [allowFiltering]="true"
                          [allowMultiSorting]="true"
                          [allowPdfExport]="false"
                          [allowSelection]="true"
                          [allowSorting]="true"
                          [dataSource]="dataHistory"
                          [enableHover]="false"
                          [enablePersistence]="false"
                          [enableVirtualization]="
                                                        true
                                                    "
                          [filterSettings]="
                                                        filterSettings
                                                    "
                          [groupSettings]="
                                                        groupOptions
                                                    "
                          [pageSettings]="pageOptions"
                          [selectionSettings]="
                                                        selectionSettings
                                                    "
                          [sortSettings]="
                                                        sortHistoryOptions
                                                    "
                          [toolbar]="toolbarHistory"
                          allowGrouping="true"
                          allowReordering="true"
                          allowResizing="true"
                          allowTextWrap="true"
                          height="100%"
                          id="materialLogsGrid"
                          locale="cs"
                          rowHeight="38"
                          showColumnMenu="true"
                          width="100%"
                        >
                          <e-columns>
                            <e-column
                              [allowEditing]="
                                                                false
                                                            "
                              [allowFiltering]="
                                                                false
                                                            "
                              [allowReordering]="
                                                                false
                                                            "
                              [allowResizing]="
                                                                false
                                                            "
                              [allowSorting]="
                                                                false
                                                            "
                              [autoFit]="false"
                              type="checkbox"
                            ></e-column>
                            <e-column
                              [allowEditing]="
                                                                false
                                                            "
                              [allowFiltering]="
                                                                true
                                                            "
                              [allowReordering]="
                                                                false
                                                            "
                              [allowResizing]="
                                                                false
                                                            "
                              [autoFit]="true"
                              [isPrimaryKey]="
                                                                true
                                                            "
                              [visible]="false"
                              field="id"
                              headerText="Log ID"
                            >
                            </e-column>
                            <e-column
                              [allowEditing]="
                                                                false
                                                            "
                              [allowFiltering]="
                                                                true
                                                            "
                              [allowReordering]="
                                                                false
                                                            "
                              [allowResizing]="
                                                                false
                                                            "
                              [autoFit]="true"
                              [visible]="true"
                              field="material_id"
                              headerText="Material ID"
                            >
                              <ng-template
                                #template
                                let-data
                              >
                                <a
                                  routerLink="/material/detail/{{
                                                                        data.material_id
                                                                    }}"
                                >{{
                                    data.material_id
                                  }}</a
                                >
                              </ng-template>
                            </e-column>
                            <e-column
                              [allowEditing]="
                                                                false
                                                            "
                              [allowFiltering]="
                                                                true
                                                            "
                              [allowReordering]="
                                                                false
                                                            "
                              [allowResizing]="
                                                                false
                                                            "
                              [autoFit]="true"
                              [visible]="false"
                              field="created_by"
                              headerText="Creator ID"
                            >
                              <ng-template
                                #template
                                let-data
                              >
                                <a
                                  routerLink="/users/detail/{{
                                                                        data.created_by
                                                                    }}"
                                >{{
                                    data.created_by
                                  }}</a
                                >
                              </ng-template>
                            </e-column>
                            <e-column
                              [allowEditing]="
                                                                false
                                                            "
                              [allowFiltering]="
                                                                true
                                                            "
                              [allowReordering]="
                                                                false
                                                            "
                              [allowResizing]="
                                                                false
                                                            "
                              [autoFit]="true"
                              [visible]="false"
                              field="updated_by"
                              headerText="Editor ID"
                            >
                              <ng-template
                                #template
                                let-data
                              >
                                <a
                                  routerLink="/users/detail/{{
                                                                        data.updated_by
                                                                    }}"
                                >{{
                                    data.updated_by
                                  }}</a
                                >
                              </ng-template>
                            </e-column>
                            <e-column
                              [allowEditing]="
                                                                false
                                                            "
                              [allowFiltering]="
                                                                true
                                                            "
                              [allowReordering]="
                                                                false
                                                            "
                              [allowResizing]="
                                                                false
                                                            "
                              [autoFit]="true"
                              [visible]="false"
                              field="deleted_by"
                              headerText="Remover ID"
                            >
                              <ng-template
                                #template
                                let-data
                              >
                                <a
                                  routerLink="/users/detail/{{
                                                                        data.deleted_by
                                                                    }}"
                                >{{
                                    data.deleted_by
                                  }}</a
                                >
                              </ng-template>
                            </e-column>
                            <e-column
                              [allowEditing]="
                                                                false
                                                            "
                              [allowFiltering]="
                                                                true
                                                            "
                              [allowReordering]="
                                                                false
                                                            "
                              [allowResizing]="
                                                                false
                                                            "
                              [autoFit]="true"
                              [visible]="false"
                              field="payment_id"
                              headerText="Payment ID"
                            ></e-column>
                            <e-column
                              [allowEditing]="
                                                                false
                                                            "
                              [allowFiltering]="
                                                                true
                                                            "
                              [allowReordering]="
                                                                false
                                                            "
                              [allowResizing]="
                                                                false
                                                            "
                              [autoFit]="true"
                              [visible]="false"
                              field="type_id"
                              headerText="Type ID"
                            ></e-column>
                            <e-column
                              [allowEditing]="
                                                                false
                                                            "
                              [autoFit]="true"
                              [filter]="
                                                                filterSettings
                                                            "
                              clipMode="EllipsisWithTooltip"
                              field="type"
                              headerText="Typ logu"
                              textAlign="center"
                            >
                              <ng-template
                                #template
                                let-data
                              >
                                                                <span
                                                                  *ngIf="
                                                                        data.type_id ===
                                                                        1
                                                                    "
                                                                  class="e-badge e-badge-success e-badge-pill"
                                                                >{{
                                                                    data.type
                                                                  }}</span
                                                                >
                                <span
                                  *ngIf="
                                                                        data.type_id ===
                                                                        2
                                                                    "
                                  class="e-badge e-badge-warning e-badge-pill"
                                >{{
                                    data.type
                                  }}</span
                                >
                                <span
                                  *ngIf="
                                                                        data.type_id ===
                                                                        3
                                                                    "
                                  class="e-badge e-badge-danger e-badge-pill"
                                >{{
                                    data.type
                                  }}</span
                                >
                              </ng-template>
                            </e-column>
                            <e-column
                              [allowEditing]="
                                                                false
                                                            "
                              [autoFit]="true"
                              [disableHtmlEncode]="
                                                                false
                                                            "
                              [filter]="
                                                                filterSettings
                                                            "
                              clipMode="EllipsisWithTooltip"
                              field="reason"
                              headerText="Důvod"
                            >
                              <ng-template
                                #template
                                let-data
                              >
                                <div
                                  [innerHTML]="
                                                                        data.reason
                                                                    "
                                ></div>
                              </ng-template>
                            </e-column>
                            <e-column
                              [allowEditing]="
                                                                false
                                                            "
                              [autoFit]="true"
                              [filter]="
                                                                filterSettings
                                                            "
                              clipMode="EllipsisWithTooltip"
                              field="note"
                              headerText="Poznámka"
                            ></e-column>
                            <e-column
                              [autoFit]="true"
                              [filter]="
                                                                filterSettings
                                                            "
                              clipMode="EllipsisWithTooltip"
                              field="to_storage"
                              headerText="Typ nákupu"
                              textAlign="center"
                            >
                              <ng-template
                                #template
                                let-data
                              >
                                                                <span
                                                                  *ngIf="
                                                                        data.to_storage
                                                                    "
                                                                  class="e-badge e-badge-pill e-badge-success"
                                                                >na
                                                                    sklad</span
                                                                >
                                <span
                                  *ngIf="
                                                                        !data.to_storage
                                                                    "
                                  class="e-badge e-badge-pill e-badge-danger"
                                >na
                                                                    přeprodej</span
                                >
                              </ng-template>
                            </e-column>
                            <e-column
                              [autoFit]="true"
                              [filter]="
                                                                filterSettings
                                                            "
                              clipMode="EllipsisWithTooltip"
                              field="payment_type"
                              headerText="Typ platby"
                            >
                            </e-column>
                            <e-column
                              [autoFit]="true"
                              [filter]="
                                                                filterSettings
                                                            "
                              clipMode="EllipsisWithTooltip"
                              field="receipt"
                              headerText="Dodaná účtenka"
                              textAlign="center"
                            >
                              <ng-template
                                #template
                                let-data
                              >
                                                                <span
                                                                  *ngIf="
                                                                        data.receipt &&
                                                                        data.type ===
                                                                            'platba v hotovosti'
                                                                    "
                                                                  class="e-badge e-badge-pill e-badge-success"
                                                                >účtenka
                                                                    dodána</span
                                                                >
                                <span
                                  *ngIf="
                                                                        !data.receipt &&
                                                                        data.type ===
                                                                            'platba v hotovosti'
                                                                    "
                                  class="e-badge e-badge-pill e-badge-danger"
                                >účtenka
                                                                    nedodána</span
                                >
                                <span
                                  *ngIf="
                                                                        data.type ===
                                                                        'bezhotovostní platba'
                                                                    "
                                  class="e-badge e-badge-pill e-badge-warning"
                                >platba
                                                                    převodem</span
                                >
                              </ng-template>
                            </e-column>
                            <e-column
                              [autoFit]="true"
                              [filter]="
                                                                filterSettings
                                                            "
                              clipMode="EllipsisWithTooltip"
                              editType="dropdownedit"
                              field="creator_str"
                              headerText="Vytvořil"
                            >
                              <ng-template
                                #template
                                let-data
                              >
                                <div>
                                  <img
                                    *ngIf="
                                                                            data.creator_img
                                                                        "
                                    alt="profilový obrázek"
                                    class="img-fluid rounded-circle avatar-xs mr-1"
                                    src="assets/img{{
                                                                            data.creator_img
                                                                        }}"
                                  />
                                  <a
                                    routerLink="/users/detail/{{
                                                                            data.created_by
                                                                        }}"
                                  >{{
                                      data.creator_str
                                    }}</a
                                  >
                                </div>
                              </ng-template>
                            </e-column>
                            <e-column
                              [allowEditing]="
                                                                false
                                                            "
                              [autoFit]="true"
                              [filter]="
                                                                filterDate
                                                            "
                              [format]="
                                                                dateFormat
                                                            "
                              clipMode="EllipsisWithTooltip"
                              editType="datepickeredit"
                              field="created_date"
                              headerText="Vytvořeno"
                              textAlign="Right"
                              type="date"
                            ></e-column>
                            <e-column
                              [autoFit]="true"
                              [filter]="
                                                                filterSettings
                                                            "
                              clipMode="EllipsisWithTooltip"
                              editType="dropdownedit"
                              field="editor_str"
                              headerText="Upravil"
                            >
                              <ng-template
                                #template
                                let-data
                              >
                                <div
                                  *ngIf="
                                                                        data.updated_by
                                                                    "
                                >
                                  <img
                                    alt="profilový obrázek"
                                    class="img-fluid rounded-circle avatar-xs mr-1"
                                    src="assets/img{{
                                                                            data.editor_img
                                                                        }}"
                                  />
                                  <a
                                    routerLink="/users/detail/{{
                                                                            data.updated_by
                                                                        }}"
                                  >{{
                                      data.editor_str
                                    }}</a
                                  >
                                </div>
                              </ng-template>
                            </e-column>
                            <e-column
                              [allowEditing]="
                                                                false
                                                            "
                              [autoFit]="true"
                              [filter]="
                                                                filterDate
                                                            "
                              [format]="
                                                                dateFormat
                                                            "
                              clipMode="EllipsisWithTooltip"
                              editType="datepickeredit"
                              field="updated_date"
                              headerText="Naposledy upraveno"
                              textAlign="Right"
                              type="date"
                            ></e-column>
                            <e-column
                              [autoFit]="true"
                              [filter]="
                                                                filterSettings
                                                            "
                              clipMode="EllipsisWithTooltip"
                              editType="dropdownedit"
                              field="remover_str"
                              headerText="Smazal"
                            >
                              <ng-template
                                #template
                                let-data
                              >
                                <div
                                  *ngIf="
                                                                        data.deleted_by
                                                                    "
                                >
                                  <img
                                    alt="profilový obrázek"
                                    class="img-fluid rounded-circle avatar-xs mr-1"
                                    src="assets/img{{
                                                                            data.remover_img
                                                                        }}"
                                  />
                                  <a
                                    routerLink="/users/detail/{{
                                                                            data.deleted_by
                                                                        }}"
                                  >{{
                                      data.remover_str
                                    }}</a
                                  >
                                </div>
                              </ng-template>
                            </e-column>
                            <e-column
                              [allowEditing]="
                                                                false
                                                            "
                              [autoFit]="true"
                              [filter]="
                                                                filterDate
                                                            "
                              [format]="
                                                                dateFormat
                                                            "
                              clipMode="EllipsisWithTooltip"
                              editType="datepickeredit"
                              field="deleted_date"
                              headerText="Smazáno"
                              textAlign="Right"
                              type="date"
                            ></e-column>
                            <e-column
                              [allowEditing]="
                                                                false
                                                            "
                              [autoFit]="true"
                              [filter]="
                                                                filterDate
                                                            "
                              [format]="
                                                                dateFormat
                                                            "
                              clipMode="EllipsisWithTooltip"
                              editType="datepickeredit"
                              field="timestamp"
                              headerText="Log Vytvořen"
                              textAlign="Right"
                              type="date"
                            ></e-column>
                          </e-columns>
                        </ejs-grid>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-container>
              <ng-container *ngIf="!material || loadingMaterial">
                <div class="e-card-content text-center">
                  <app-loader></app-loader>
                </div>
              </ng-container>
            </div>
          </ng-template>
        </e-tabitem>
      </e-tabitems>
    </ejs-tab>
  </div>
  <div class="col-12 col-md-4 col-lg-3 p-2">
    <div class="e-card">
      <ng-container *ngIf="material && !loadingMaterial">
        <div class="e-card-header stylish-color-dark p-2">
          <div class="e-card-header-caption">
            <div
              class="d-flex justify-content-between e-card-header-title text-white"
            >
              <strong>Objednávka</strong>
              <span>ID: {{ material.id }}</span>
            </div>
          </div>
        </div>
        <div class="e-card-content pt-0 mt-0">
          <!--Name-->
          <div class="text-center">
            <img
              alt="profilový obrázek"
              class="img-fluid rounded-circle avatar-md p-2"
              src="assets/img/{{
                                material.creator.personalphoto
                            }}"
            />
            <h5 class="mb-2 font-bold">
              <strong
              >{{ material.creator.firstname }}
                {{ material.creator.secondname }}</strong
              >
            </h5>
            <h6 class="font-bold blue-text mb-2">zadavatel</h6>
          </div>
          <ul class="striped list-unstyled text-center">
            <li>
              <small class="font-weight-bold"
              >E-mail:
                <a
                  href="mailto:{{
                                        material.creator.workemail
                                    }}"
                  title="napsat zprávu"
                >{{ material.creator.workemail }}</a
                >
              </small>
            </li>
            <li>
              <small class="font-weight-bold"
              >Telefon:
                <a
                  href="tel:{{ material.creator.telnumber }}"
                  title="zavolat"
                >{{ material.creator.telnumber }}</a
                ></small
              >
            </li>
          </ul>
        </div>
        <div class="e-card-content pt-2 mt-0">
          <!--Name-->
          <div class="text-center">
            <h6 class="font-bold blue-text mb-2">Schvalovatelé</h6>
          </div>
          <ul
            *ngIf="
                            material.confirmers &&
                            material.confirmers.length > 0
                        "
            class="list-group"
            id="approversList"
          >
            <li
              *ngFor="
                                let user of material.confirmers;
                                let i = index
                            "
              [ngClass]="
                                material.submited
                                    ? 'list-group-item-success'
                                    : material.declined
                                    ? 'list-group-item-danger'
                                    : material.deleted_date
                                    ? 'list-group-item-danger'
                                    : user.material_assignation_users &&
                                      user.material_assignation_users
                                          .confirmed_date
                                    ? 'list-group-item-success'
                                    : user.material_assignation_users &&
                                      user.material_assignation_users
                                          .declined_date
                                    ? 'list-group-item-danger'
                                    : 'list-group-item-warning'
                            "
              class="list-group-item p-1 border-top-0"
            >
              <img
                alt="profilový obrázek"
                class="img-fluid rounded-circle avatar-xs mr-1 ml-2"
                src="assets/img{{ user.personalphoto }}"
              />
              <div class="float-right text-right">
                <small class="text-black-imp font-weight-bold"
                >{{ user.firstname }}
                  {{ user.secondname }}</small
                >
                <br />
                <div
                  aria-label="Material toolbar"
                  class="e-btn-group"
                  role="group"
                >
                  <button
                    (click)="confirmationConfirm.show()"
                    *ngIf="
                                            material &&
                                            user.id === currentUser.id &&
                                            !material.deleted_date &&
                                            !material.submited &&
                                            user.material_assignation_users &&
                                            !user.material_assignation_users
                                                .confirmed_date &&
                                            !user.material_assignation_users
                                                .declined_date
                                        "
                    cssClass="e-btn e-success btn-p-zero e-small"
                    ejs-button
                  >
                    <i
                      aria-hidden="true"
                      class="fa-light fa-check mr-1"
                    ></i>
                    schválit
                  </button>
                  <button
                    (click)="confirmationDecline.show()"
                    *ngIf="
                                            material &&
                                            user.id === currentUser.id &&
                                            !material.deleted_date &&
                                            !material.submited &&
                                            user.material_assignation_users &&
                                            !user.material_assignation_users
                                                .confirmed_date &&
                                            !user.material_assignation_users
                                                .declined_date
                                        "
                    cssClass="e-btn e-danger btn-p-zero e-small"
                    ejs-button
                  >
                    <i
                      aria-hidden="true"
                      class="fa-light fa-times mr-1"
                    ></i>
                    zamítnout
                  </button>
                </div>
                <small
                  *ngIf="
                                        user.material_assignation_users &&
                                        !user.material_assignation_users
                                            .confirmed_date &&
                                        user.material_assignation_users
                                            .declined_date
                                    "
                  class="text-danger"
                >{{
                    user.material_assignation_users
                      .declined_date
                      | dateFormat: 'DD.MM.YYYY HH:mm'
                  }}</small
                >
                <small
                  *ngIf="
                                        user.material_assignation_users &&
                                        user.material_assignation_users
                                            .confirmed_date &&
                                        !user.material_assignation_users
                                            .declined_date
                                    "
                  class="text-success"
                >{{
                    user.material_assignation_users
                      .confirmed_date
                      | dateFormat: 'DD.MM.YYYY HH:mm'
                  }}</small
                >
              </div>
            </li>
          </ul>
        </div>
      </ng-container>
      <ng-container *ngIf="!material || loadingMaterial">
        <div class="e-card-content text-center">
          <app-loader></app-loader>
        </div>
      </ng-container>
    </div>
  </div>
</div>
