import {ChangeDetectionStrategy, Component} from '@angular/core';

@Component({
    selector: 'app-event-detail',
    templateUrl: './event-detail.component.html',
    styleUrls: ['./event-detail.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EventDetailComponent {
    // TODO
}
