import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    HostListener,
    Input,
    OnChanges,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import {ComponentCanDeactivate} from '@src/app/_guards/changes.guard';
import {User} from '@src/app/_models/user/user';
import {DialogComponent} from '@syncfusion/ej2-angular-popups';
import {DataService} from '@src/app/_services/data.service';
import {PermissionService} from '@src/app/_services/permission.service';
import {AuthenticationService} from '@src/app/_services/authentication.service';
import {MessageService} from '@src/app/_services/message.service';
import {Observable} from 'rxjs';
import {UntilDestroy} from '@ngneat/until-destroy';
import {
    BeforeUploadEventArgs,
    FileInfo,
    RemovingEventArgs,
    SelectedEventArgs,
    SuccessEventArgs,
    UploaderComponent,
    UploadingEventArgs,
} from '@syncfusion/ej2-angular-inputs';
import {EmitType, isNullOrUndefined} from '@syncfusion/ej2-base';
import {FilePath} from '@src/app/features/hotline/hotline.service';
import * as XLSX from 'xlsx';
import {WorkSheet} from 'xlsx';
import {EnvironmentService} from '@src/app/_services/environment.service';
import {MOBILE_IMPORT_TYPE} from '@src/app/features/sync/types/import-type.enum';
import {MobileImportInput} from '@src/app/features/sync/types/mobile-import-input.type';
import {SyncService} from '@src/app/features/sync/sync.service';

@UntilDestroy()
@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'app-sim',
    templateUrl: './sim.component.html',
    styleUrls: ['./sim.component.scss'],
})
export class SimComponent implements OnInit, OnChanges, ComponentCanDeactivate {
    // Upload
    pathVodafone: FilePath;

    pathTMCZ: FilePath;

    dropElement: HTMLElement = document.getElementsByClassName('control-fluid')[0] as HTMLElement;

    // Dialogs
    height = '240px';

    // Variables
    currentUser: User | null;

    removeFile: Array<FileInfo[]> = [];

    importData: Array<MobileImportInput> = [];

    isDirty = false;

    // Loaders
    uploadLoading = false;

    @Input() readonly isVisible = false;

    @Input() readonly importType?: keyof typeof MOBILE_IMPORT_TYPE;

    @Output() readonly uploadDialogState = new EventEmitter<boolean>();

    // Upload
    @ViewChild('preloadupload') uploadObj: UploaderComponent;

    // Dialogs
    @ViewChild('uploadDialog') uploadDialogObj: DialogComponent;

    @ViewChild('deleteFileDialog') deleteFileDialog: DialogComponent;

    buttonsDeleteFileDialog = [
        {
            click: this.removeImportFile.bind(this),
            buttonModel: {
                content: 'OK',
                cssClass: 'e-success e-outline',
                isFlat: false,
            },
        },
        {
            click: (): void => {
                this.deleteFileDialog.hide();
            },
            buttonModel: {content: 'ZRUŠIT', cssClass: 'e-danger e-outline'},
        },
    ];

    constructor(
        private readonly ref: ChangeDetectorRef,
        private readonly dataService: DataService,
        private readonly permissionService: PermissionService,
        private readonly authenticationService: AuthenticationService,
        private readonly messages: MessageService,
        private readonly environmentService: EnvironmentService,
        private readonly syncService: SyncService,
    ) {
        this.currentUser = this.authenticationService.currentUserValue;
        this.pathVodafone = {
            saveUrl:
                this.environmentService.backendURL +
                '/api/upload/sheet/sync?type=vodafone&subfolder=import',
            removeUrl: '',
        };

        this.pathTMCZ = {
            saveUrl:
                this.environmentService.backendURL +
                '/api/upload/sheet/sync?type=tmobile&subfolder=import',
            removeUrl: '',
        };
    }

    @HostListener('window:beforeunload')
    canDeactivate(): Observable<boolean> | boolean {
        return !this.uploadLoading;
    }

    ngOnInit(): void {
        this.dataService.setDepartmentDataSource();
    }

    ngOnChanges(): void {
        if (this.isVisible) {
            this.uploadDialogObj.header = `Import zdrojových dat: ${this.importType === 'TMCZ' ? 'T-Mobile' : 'Vodafone'}`;
        }
    }

    changeDialogState(value: boolean): void {
        this.uploadDialogState.emit(value);
    }

    result(): void {
        const promises: Promise<MobileImportInput[] | undefined>[] = [];
        let rejects = 0;
        const skipped = 0;

        if (this.importData.length > 0) {
            this.uploadLoading = true;

            const dataFeed: MobileImportInput[] = [];

            for (const row of this.importData) {
                if (
                    this.currentUser &&
                    this.permissionService.checkUserISHotlineAdmin(this.currentUser)
                ) {

                } else {
                    rejects++;
                }
            }

            const createObj = this.syncService.processImport(dataFeed)
                .toPromise();

            promises.push(createObj);
            void Promise.all(promises)
                .then(() => {
                    if (dataFeed.length > 0) {
                        const body = `Celkem přidáno: ${dataFeed.length} hot-line`;
                        const options = {progressBar: true, timeOut: 5000};

                        this.messages.showSuccess('Import hot-line úspěšně dokončen', body, options);
                    }

                    if (rejects > 0) {
                        const body = `Celkem u: ${rejects} hot-line`;
                        const options = {progressBar: true, timeOut: 5000};

                        this.messages.showError('Nedostatečné oprávnění', body, options);
                    }

                    if (skipped > 0) {
                        const body = `Celkem: ${skipped} aktivních hot-line již existuje`;
                        const options = {
                            progressBar: true,
                            timeOut: 5000,
                            toastClass: 'warning',
                        };

                        this.messages.showWarning('Hotline', body, options);
                    }

                    this.isDirty = false;
                    this.uploadLoading = false;
                    this.dataService.clearHotlinesCache();
                    this.dataService.setHotlinesDataSource();
                    this.ref.markForCheck();
                });
        }
    }

    onFileSelected(args: SelectedEventArgs): void {
        // Filter the 5 files only to showcase
        args.filesData.splice(5);

        const filesData: FileInfo[] = this.uploadObj.getFilesData();
        const allFiles: FileInfo[] = filesData.concat(args.filesData);

        if (allFiles.length > 5) {
            // eslint-disable-next-line @typescript-eslint/prefer-for-of
            for (let i = 0; i < allFiles.length; i++) {
                if (allFiles.length > 5) {
                    allFiles.shift();
                }
            }

            args.filesData = allFiles;
            // set the modified custom data
            args.modifiedFilesData = args.filesData;
        }

        let existingFiles: FileInfo[] = this.uploadObj.getFilesData();

        for (let i = 0; i < args.filesData.length; i++) {
            // eslint-disable-next-line @typescript-eslint/prefer-for-of
            for (let j = 0; j < existingFiles.length; j++) {
                if (!isNullOrUndefined(args.filesData[i])) {
                    // eslint-disable-next-line eqeqeq
                    if (existingFiles[j].name == args.filesData[i].name) {
                        args.filesData.splice(i, 1);
                    }
                }
            }
        }

        existingFiles = existingFiles.concat(args.filesData);
        args.modifiedFilesData = existingFiles;
        args.isModified = true;
    }

    beforeFileUpload(args: BeforeUploadEventArgs): void {
        if (this.currentUser) {
            args.customFormData = [{created_by: this.currentUser.id}];
        }
    }

    onFileUpload: EmitType<SelectedEventArgs> = (args: UploadingEventArgs) => {
        if (this.currentUser) {
            args.currentRequest?.setRequestHeader(
                'Authorization',
                `Bearer ${this.currentUser.token}`,
            );
            args.customFormData = [{created_by: this.currentUser.id}];
        }
    };

    onSuccessUpload(args: SuccessEventArgs): void {
        this.importData = [];

        if (args.file) {
            const body = 'Soubor: ' + args.file.name;
            const options = {progressBar: true, timeOut: 5000, toastClass: 'success'};

            this.messages.showSuccess('Soubor úspěšně nahrán', body, options);
            this.getXLSXData(args.file.rawFile as Blob, (dataRow: MobileImportInput[]) => {
                dataRow.forEach((data: MobileImportInput) => {
                    if (this.currentUser) {
                        const importData: MobileImportInput = {
                            phone_number: data.phone_number,
                            amount: data.amount,
                            price: data.price,
                            service: data.service,
                            company: data.company,
                            created_by: this.currentUser.id,
                        };

                        this.importData.push(importData);
                    }
                });
                this.result();
            });
        }
    }

    onFileRemove(args: RemovingEventArgs): void {
        this.removeFile = [];
        args.postRawFile = false;
        this.removeFile.push(args.filesData);
        this.deleteFileDialog.show();
    }

    removeImportFile(): void {
        this.uploadLoading = true;
        this.uploadObj.clearAll();
        this.deleteFileDialog.hide();
        this.removeFile = [];
        this.uploadLoading = false;
    }

    private getXLSXData(file: Blob, callback: (dataRow: MobileImportInput[]) => void): void {
        let data: string[] | string;
        const row: MobileImportInput[] = [];
        const reader = new FileReader();

        reader.onload = (e): void => {
            const bstr = e.target?.result;
            const workbook = XLSX.read(bstr, {type: 'binary'});
            const sheetNameList = workbook.SheetNames;

            sheetNameList.forEach(y => {
                /* iterate through sheets */
                const worksheet: WorkSheet = workbook.Sheets[y];

                for (data in worksheet) {
                    const phoneObject: MobileImportInput = {};

                    if (
                        // eslint-disable-next-line @typescript-eslint/prefer-string-starts-ends-with
                        data[0] === '!' ||
                        // eslint-disable-next-line @typescript-eslint/prefer-string-starts-ends-with
                        data[0] === 'B1'
                    ) {
                        continue;
                    }

                    if (data !== 'A1' && data !== 'B1' && data !== 'C1') {
                        phoneObject.company = 'tmcz';
                        if (data.includes('B')) {
                            const phoneCell: string = worksheet[data].w.toString();

                            phoneObject.phone_number = phoneCell;
                        }

                        if (data.includes('G')) {
                            const serviceCell: string = worksheet[data].w.toString();

                            phoneObject.service = serviceCell;
                        }

                        row.push(phoneObject);
                    }
                }
            });

            callback(row);
        };

        reader.readAsBinaryString(file);
    }
}
