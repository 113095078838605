import { Injectable } from '@angular/core';
import { BACKEND_URL, ENVIRONMENT, VERSION_URL } from '@src/environments/environment';

@Injectable({
    providedIn: 'root',
})
export class EnvironmentService {
    backendURL: string;

    versionURL: boolean | string;

    constructor() {
        const mode: 'dev' | 'prod' | 'test' = 'prod';

        const backend = BACKEND_URL[mode];
        const version = VERSION_URL[mode];

        for (const [key, value] of Object.entries(backend)) {
            this[key] = value;
        }

        if (ENVIRONMENT.production) {
            for (const [key, value] of Object.entries(version)) {
                this[key] = value;
            }
        }
        else {
            this.versionURL = false;
        }
    }
}
