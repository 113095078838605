import {ChangeDetectionStrategy, ChangeDetectorRef, Component, ViewChild} from '@angular/core';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {User} from '@src/app/_models/user/user';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {TabComponent, ToolbarComponent} from '@syncfusion/ej2-angular-navigations';
import {SelectEventArgs} from '@syncfusion/ej2-navigations';
import {AuthenticationService} from '@src/app/_services/authentication.service';

@UntilDestroy()
@Component({
    selector: 'app-services-settings',
    templateUrl: './services-settings.component.html',
    styleUrls: ['./services-settings.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ServicesSettingsComponent {
    // Navigations
    headerText = [
        {text: 'úvazky smluv'},
        {text: 'technologie přenosu'},
        {text: 'bytové jednotky'},
        {text: 'limity rychlostí'},
        {text: 'technologie instalace'},
        {text: 'typy instalací'},
    ];

    currentUser: User | null;

    // Maintoolbar
    @ViewChild('moduleToolbar') moduleToolbarObj: ToolbarComponent;

    // Navigations
    @ViewChild('servicesTab') servicesTabObj: TabComponent;

    constructor(
        private readonly router: Router,
        private readonly ref: ChangeDetectorRef,
        private readonly route: ActivatedRoute,
        private readonly authenticationService: AuthenticationService,
    ) {
        this.currentUser = this.authenticationService.currentUserValue;
    }

    preventSwipe(e: SelectEventArgs): void {
        if (e.isSwiped) {
            e.cancel = true;
        }
    }

    onCreate(): void {
        // Nastavení GUI podle URL parametrů
        this.route.queryParamMap.pipe(untilDestroyed(this))
            .subscribe(queryParams => {
                if (queryParams.get('subview') === 'deal_types') {
                    this.servicesTabObj.selectedItem = 0;
                } else if (queryParams.get('subview') === 'transfer_types') {
                    this.servicesTabObj.selectedItem = 1;
                } else if (queryParams.get('subview') === 'flat_units') {
                    this.servicesTabObj.selectedItem = 2;
                } else if (queryParams.get('subview') === 'speed_types') {
                    this.servicesTabObj.selectedItem = 3;
                } else if (queryParams.get('subview') === 'technology_types') {
                    this.servicesTabObj.selectedItem = 4;
                } else if (queryParams.get('subview') === 'installation_types') {
                    this.servicesTabObj.selectedItem = 5;
                } else {
                    this.servicesTabObj.selectedItem = 0;
                }
            });
    }

    select(e: SelectEventArgs): void {
        let queryParams: Params = {view: null};

        if (e.selectedItem.textContent === 'úvazky smluv') {
            queryParams = {
                view: 'settings',
                subview: 'deal_types',
                form: null,
            };
        }

        if (e.selectedItem.textContent === 'technologie přenosu') {
            queryParams = {
                view: 'settings',
                subview: 'transfer_types',
                form: null,
            };
        }

        if (e.selectedItem.textContent === 'bytové jednotky') {
            queryParams = {
                view: 'settings',
                subview: 'flat_units',
                form: null,
            };
        }

        if (e.selectedItem.textContent === 'limity rychlostí') {
            queryParams = {
                view: 'settings',
                subview: 'speed_types',
                form: null,
            };
        }

        if (e.selectedItem.textContent === 'technologie instalace') {
            queryParams = {
                view: 'settings',
                subview: 'technology_types',
                form: null,
            };
        }

        if (e.selectedItem.textContent === 'typy instalací') {
            queryParams = {
                view: 'settings',
                subview: 'installation_types',
                form: null,
            };
        }

        void this.router.navigate([], {
            relativeTo: this.route,
            queryParams,
            queryParamsHandling: 'merge',
        });
    }
}
