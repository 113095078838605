<div class="row d-flex justify-content-center modalWrapper">
  <ejs-dialog
    #confirmationDelete
    [buttons]="buttonsDelete"
    [visible]="false"
    content="Potvrďte smazání objednávky"
    width="250px"
  ></ejs-dialog>
  <ejs-dialog
    #confirmationDecline
    [buttons]="buttonsDecline"
    [visible]="false"
    content="Potvrďte zamítnutí objednávky"
    width="250px"
  ></ejs-dialog>
  <ejs-dialog
    #confirmationConfirm
    [buttons]="buttonsConfirm"
    [visible]="false"
    content="Potvrďte schválení objednávky"
    width="250px"
  ></ejs-dialog>
  <ejs-dialog
    #confirmationCancel
    [buttons]="buttonsCancel"
    [visible]="false"
    content="Potvrďte empty objednávky"
    width="250px"
  ></ejs-dialog>
  <ejs-dialog
    #createTicket2SiteMonitor
    [buttons]="buttonsSMTicket"
    [visible]="false"
    content="Potvrďte vytvoření ticketu v Site Monitor"
    width="250px"
  ></ejs-dialog>
  <app-order-form
    (orderFormDialogState)="
            renderOrderFormDialog = $event; isUpdate = $event
        "
    [isUpdate]="isUpdate"
    [isVisible]="renderOrderFormDialog"
    [order]="clickedRow && clickedRow[0] && isUpdate ? clickedRow[0] : null"
  ></app-order-form>
</div>
<ejs-toolbar id="filterToolbar" overflowMode="Scrollable" scrollStep="500">
  <e-items>
    <e-item
      [cssClass]="'statusFilter'"
      tooltipText="Přednastavené hledání"
      type="input"
    >
      <ng-template #template>
        <ejs-dropdownlist
          #viewFilter
          (change)="onStatusFilterChange($event)"
          [dataSource]="categoryFilterData"
          [width]="500"
          placeholder="Vyberte přednastavený pohled..."
        ></ejs-dropdownlist>
      </ng-template>
    </e-item>
    <e-item type="Separator"></e-item>
    <e-item
      [cssClass]="'fulltextFilter'"
      tooltipText="Text pro vyhledávání"
      type="input"
    >
      <ng-template #template>
        <ejs-textbox
          #searchtext
          [width]="500"
          class="mr-2"
          placeholder="Vyhledat..."
        ></ejs-textbox>
        <button
          (click)="search()"
          class="e-btn e-info btn-p-zero e-small"
        >
          <i class="fa-light fa-lg fa-search mr-1"></i
          ><span class="d-none d-lg-inline">vyhledat</span>
        </button>
        <button
          (click)="cancelBtnClick()"
          class="e-btn e-danger btn-p-zero e-small"
        >
          <i class="fa-light fa-lg fa-times"></i>
        </button>
      </ng-template>
    </e-item>
  </e-items>
</ejs-toolbar>
<div class="control-section">
  <div class="scroll_wrapper1" id="scroll_wrapper1_orders">
    <div class="scroll_div" id="scroll_div_orders"></div>
  </div>
  <div class="scroll_wrapper2" id="scroll_wrapper2_orders">
    <div class="grid_parent" id="grid_parent_orders">
      <ejs-grid
        #ordersGrid
        (actionBegin)="actionBegin($event)"
        (actionComplete)="actionComplete($event)"
        (created)="onCreatedOrder()"
        (dataBound)="onDataBound()"
        (load)="onLoadOrder()"
        (loadeddata)="setInitialGridFiltering()"
        (rowDataBound)="rowDataBound($event)"
        (rowDeselected)="rowSelected()"
        (rowSelected)="rowSelected()"
        (toolbarClick)="toolbarClick($event)"
        [allowExcelExport]="true"
        [allowFiltering]="true"
        [allowMultiSorting]="true"
        [allowPdfExport]="false"
        [allowSelection]="true"
        [allowSorting]="true"
        [dataSource]="dataOrders"
        [editSettings]="editSettings"
        [enableHover]="false"
        [enablePersistence]="false"
        [enableVirtualization]="true"
        [filterSettings]="filterSettings"
        [groupSettings]="groupOptions"
        [pageSettings]="pageOptions"
        [selectionSettings]="selectionSettings"
        [toolbar]="toolbar"
        allowGrouping="true"
        allowReordering="true"
        allowResizing="true"
        allowTextWrap="true"
        height="100%"
        id="ordersGrid"
        locale="cs"
        rowHeight="38"
        showColumnMenu="true"
        width="100%"
      >
        <e-columns>
          <e-column
            [allowEditing]="false"
            [allowFiltering]="false"
            [allowReordering]="false"
            [allowResizing]="false"
            [allowSorting]="false"
            [autoFit]="true"
            type="checkbox"
          ></e-column>
          <e-column
            [allowFiltering]="false"
            [allowReordering]="false"
            [allowResizing]="false"
            [autoFit]="true"
            [isPrimaryKey]="true"
            [visible]="true"
            field="id"
            headerText="ID"
          >
            <ng-template #template let-data>
              <a [routerLink]="['/services/orders/', data.id]">
                {{ data.id }}
              </a>
            </ng-template>
          </e-column>
          <e-column
            [allowEditing]="false"
            [allowFiltering]="false"
            [allowReordering]="false"
            [allowResizing]="false"
            [autoFit]="true"
            [visible]="false"
            field="lead_id"
            headerText="LEAD ID"
          ></e-column>
          <e-column
            [allowFiltering]="false"
            [allowReordering]="false"
            [allowResizing]="false"
            [autoFit]="true"
            [format]="'N'"
            [visible]="false"
            field="operator_id"
            headerText="OPERATOR ID"
            textAlign="right"
          ></e-column>
          <e-column
            [allowFiltering]="false"
            [allowReordering]="false"
            [allowResizing]="false"
            [autoFit]="true"
            [format]="'N'"
            [visible]="false"
            field="type_id"
            headerText="TYPE ID"
            textAlign="right"
          ></e-column>
          <e-column
            [allowEditing]="false"
            [allowFiltering]="false"
            [allowReordering]="false"
            [allowResizing]="false"
            [autoFit]="true"
            [format]="'N'"
            [visible]="false"
            field="provider_id"
            headerText="PROVIDER ID"
            textAlign="right"
          ></e-column>
          <e-column
            [allowEditing]="false"
            [allowFiltering]="false"
            [allowReordering]="false"
            [allowResizing]="false"
            [autoFit]="true"
            [format]="'N'"
            [visible]="false"
            clipMode="EllipsisWithTooltip"
            field="deal_id"
            headerText="DEAL ID"
            textAlign="right"
          ></e-column>
          <e-column
            [allowEditing]="false"
            [allowFiltering]="false"
            [allowReordering]="false"
            [allowResizing]="false"
            [autoFit]="true"
            [format]="'N'"
            [visible]="false"
            field="unit_id"
            headerText="UNIT ID"
            textAlign="right"
          ></e-column>
          <e-column
            [allowEditing]="false"
            [allowFiltering]="false"
            [allowReordering]="false"
            [allowResizing]="false"
            [autoFit]="true"
            [format]="'N'"
            [visible]="false"
            field="space_id"
            headerText="SPACE ID"
            textAlign="right"
          ></e-column>
          <e-column
            [allowEditing]="false"
            [allowFiltering]="false"
            [allowReordering]="false"
            [allowResizing]="false"
            [autoFit]="true"
            [format]="'N'"
            [visible]="false"
            field="speed_id"
            headerText="SPEED ID"
            textAlign="right"
          ></e-column>
          <e-column
            [allowEditing]="false"
            [allowFiltering]="false"
            [allowReordering]="false"
            [allowResizing]="false"
            [autoFit]="true"
            [format]="'N'"
            [visible]="false"
            field="hw_id"
            headerText="HARDWARE ID"
            textAlign="right"
          ></e-column>
          <e-column
            [allowEditing]="false"
            [allowReordering]="false"
            [allowResizing]="false"
            [autoFit]="true"
            [format]="'N'"
            [visible]="false"
            field="crm_id"
            headerText="CRM ID"
            textAlign="right"
          ></e-column>
          <e-column
            [allowEditing]="false"
            [allowReordering]="false"
            [allowResizing]="false"
            [autoFit]="true"
            [format]="'N'"
            [visible]="false"
            field="erp_id"
            headerText="ERP ID"
            textAlign="right"
          ></e-column>
          <e-column
            [allowEditing]="false"
            [allowReordering]="false"
            [allowResizing]="false"
            [autoFit]="true"
            [format]="'N'"
            [visible]="false"
            field="stmnt_id"
            headerText="MONITOR ID"
            textAlign="right"
          ></e-column>
          <e-column
            [allowEditing]="false"
            [allowReordering]="false"
            [allowResizing]="false"
            [autoFit]="true"
            [format]="'N'"
            [visible]="true"
            field="cl_cnumber"
            headerText="CLIENT NUMBER"
            textAlign="right"
          ></e-column>
          <e-column
            [autoFit]="true"
            clipMode="EllipsisWithTooltip"
            editType="dropdownedit"
            field="operator_str"
            headerText="Uživatel"
          >
            <ng-template #template let-data>
              <div>
                <img
                  *ngIf="data.operator_img"
                  alt="profilový obrázek"
                  class="img-fluid rounded-circle avatar-xs mr-1"
                  src="assets/img{{ data.operator_img }}"
                />
                <span>{{ data.operator_str }}</span>
              </div>
            </ng-template>
          </e-column>
          <e-column
            [autoFit]="true"
            clipMode="EllipsisWithTooltip"
            field="status"
            headerText="Stav objednávky"
            textAlign="center"
          ></e-column>
          <e-column
            [autoFit]="true"
            clipMode="EllipsisWithTooltip"
            field="client_status"
            headerText="Stav klienta"
            textAlign="center"
          ></e-column>
          <e-column
            [autoFit]="true"
            clipMode="EllipsisWithTooltip"
            field="address"
            headerText="Adresa"
          ></e-column>
          <e-column
            [autoFit]="true"
            clipMode="EllipsisWithTooltip"
            editType="dropdownedit"
            field="type"
            headerText="Typ"
            textAlign="Center"
          ></e-column>
          <e-column
            [autoFit]="true"
            clipMode="EllipsisWithTooltip"
            field="cl_fullname"
            headerText="Jméno"
          ></e-column>
          <e-column
            [autoFit]="true"
            clipMode="EllipsisWithTooltip"
            field="cl_email"
            headerText="Email"
          >
            <ng-template #template let-data>
              <a
                *ngIf="data.cl_email"
                href="mailto:{{ data.cl_email }}"
              >{{ data.cl_email }}
                <small class="text-muted">(hlavní)</small></a
              >
              <br *ngIf="data.cl_email" />
              <a
                *ngIf="data.cl_business_email"
                href="mailto:{{ data.cl_business_email }}"
              >{{ data.cl_business_email }}
                <small class="text-muted">(obchodní)</small></a
              >
              <br *ngIf="data.cl_business_email" />
              <a
                *ngIf="data.cl_billing_email"
                href="mailto:{{ data.cl_billing_email }}"
              >{{ data.cl_billing_email }}
                <small class="text-muted"
                >(fakturační)</small
                ></a
              >
              <br *ngIf="data.cl_billing_email" />
            </ng-template>
          </e-column>
          <e-column
            [autoFit]="true"
            clipMode="EllipsisWithTooltip"
            field="cl_telephone"
            headerText="Telefon"
          >
            <ng-template #template let-data>
              <a href="tel:{{ data.cl_telephone }}"
              >telefon: {{ data.cl_telephone }}</a
              >
              <br />
              <a href="tel:{{ data.cl_mobilephone }}"
              >mobil: {{ data.cl_mobilephone }}</a
              >
            </ng-template>
          </e-column>
          <e-column
            [autoFit]="true"
            clipMode="EllipsisWithTooltip"
            field="cl_address"
            headerText="Trvalé bydliště"
          >
          </e-column>
          <e-column
            [autoFit]="true"
            clipMode="EllipsisWithTooltip"
            field="cl_send_invoices_by_post"
            headerText="FA poštou"
            textAlign="center"
          >
            <ng-template #template let-data>
                            <span
                              *ngIf="data.cl_send_invoices_by_post === 'ANO'"
                              class="e-badge e-badge-success"
                            >ANO</span
                            >
              <span
                *ngIf="data.cl_send_invoices_by_post === 'NE'"
                class="e-badge e-badge-danger"
              >NE</span
              >
            </ng-template>
          </e-column>
          <e-column
            [autoFit]="true"
            clipMode="EllipsisWithTooltip"
            field="cl_send_invoice_by_email"
            headerText="Elektornická FA"
            textAlign="center"
          >
            <ng-template #template let-data>
                            <span
                              *ngIf="data.cl_send_invoice_by_email === 'ANO'"
                              class="e-badge e-badge-success"
                            >ANO</span
                            >
              <span
                *ngIf="data.cl_send_invoice_by_email === 'NE'"
                class="e-badge e-badge-danger"
              >NE</span
              >
            </ng-template>
          </e-column>
          <e-column
            [autoFit]="true"
            clipMode="EllipsisWithTooltip"
            field="invoice_by_year"
            headerText="Roční předplatné"
            textAlign="center"
          >
            <ng-template #template let-data>
                            <span
                              *ngIf="data.invoice_by_year === 'ANO'"
                              class="e-badge e-badge-success"
                            >ANO</span
                            >
              <span
                *ngIf="data.invoice_by_year === 'NE'"
                class="e-badge e-badge-danger"
              >NE</span
              >
            </ng-template>
          </e-column>
          <e-column
            [autoFit]="true"
            clipMode="EllipsisWithTooltip"
            field="vip_install"
            headerText="VIP instalace"
            textAlign="center"
          >
            <ng-template #template let-data>
                            <span
                              *ngIf="data.vip_install === 'ANO'"
                              class="e-badge e-badge-success"
                            >ANO</span
                            >
              <span
                *ngIf="data.vip_install === 'NE'"
                class="e-badge e-badge-danger"
              >NE</span
              >
            </ng-template>
          </e-column>
          <e-column
            [autoFit]="true"
            [disableHtmlEncode]="false"
            clipMode="EllipsisWithTooltip"
            field="net_package"
            headerText="NET tarif"
          >
          </e-column>
          <e-column
            [autoFit]="true"
            [disableHtmlEncode]="false"
            clipMode="EllipsisWithTooltip"
            field="tv_service"
            headerText="TV tarif"
          >
          </e-column>
          <e-column
            [autoFit]="true"
            [disableHtmlEncode]="false"
            clipMode="EllipsisWithTooltip"
            field="tv_packages"
            headerText="TV balíčky"
          >
          </e-column>
          <e-column
            [autoFit]="true"
            [disableHtmlEncode]="false"
            clipMode="EllipsisWithTooltip"
            field="ext_services_str"
            headerText="Rozšiřující služby"
          >
          </e-column>
          <e-column
            [autoFit]="true"
            clipMode="EllipsisWithTooltip"
            field="hw_package_str"
            headerText="HW služby"
          >
            <ng-template #template let-data>
              <img
                *ngIf="
                                    data.hw_package &&
                                    data.hw_package.photo_thumb_url
                                "
                alt="obrázek zařízení"
                class="img-fluid avatar-xs mr-1"
                src="assets/img{{
                                    data.hw_package.photo_thumb_url
                                }}"
              />
              <span>{{ data.hw_package_str }}</span>
              <div
                *ngIf="
                                    data.wicon_own_router === 'NE' &&
                                    data.hw_package
                                "
              >
                <span>typ bytu: {{ data.unit_type }}</span> /
                <span>prostor: {{ data.flat_space }} m2</span> /
                <span
                >rychlost: {{ data.speed_type }} Mbit</span
                >
                <br />
                <span
                  *ngIf="data.wicon_basics === 'ANO'"
                  class="e-badge e-badge-success mr-1"
                >základy: ANO</span
                >
                <span
                  *ngIf="data.wicon_basics === 'NE'"
                  class="e-badge e-badge-danger mr-1"
                >základy: NE</span
                >
                <span
                  *ngIf="data.wicon_cabels === 'ANO'"
                  class="e-badge e-badge-success mr-1"
                >kabeláž: ANO</span
                >
                <span
                  *ngIf="data.wicon_cabels === 'NE'"
                  class="e-badge e-badge-danger mr-1"
                >kabeláž: NE</span
                >
                <span
                  *ngIf="data.wicon_garden === 'ANO'"
                  class="e-badge e-badge-success mr-1"
                >zahrada: ANO</span
                >
                <span
                  *ngIf="data.wicon_garden === 'NE'"
                  class="e-badge e-badge-danger mr-1"
                >zahrada: NE</span
                >
                <span
                  *ngIf="data.wicon_roaming === 'ANO'"
                  class="e-badge e-badge-success mr-1"
                >roaming: ANO</span
                >
                <span
                  *ngIf="data.wicon_roaming === 'NE'"
                  class="e-badge e-badge-danger mr-1"
                >roaming: NE</span
                >
              </div>
            </ng-template>
          </e-column>
          <e-column
            [autoFit]="true"
            clipMode="EllipsisWithTooltip"
            field="wicon_own_router"
            headerText="Vlastní router"
            textAlign="center"
          >
            <ng-template #template let-data>
                            <span
                              *ngIf="data.wicon_own_router === 'ANO'"
                              class="e-badge e-badge-success"
                            >ANO</span
                            >
              <span
                *ngIf="data.wicon_own_router === 'NE'"
                class="e-badge e-badge-danger"
              >NE</span
              >
            </ng-template>
          </e-column>
          <e-column
            [autoFit]="true"
            [disableHtmlEncode]="false"
            clipMode="EllipsisWithTooltip"
            field="tvs_str"
            headerText="Televize"
          ></e-column>
          <e-column
            [columns]="feesColumns"
            headerText="Pravidelné poplatky"
          ></e-column>
          <e-column
            [columns]="pricesColumns"
            headerText="Jednorázové poplatky"
          ></e-column>
          <e-column
            [autoFit]="true"
            clipMode="EllipsisWithTooltip"
            field="internal_note"
            headerText="Interní poznámka"
          ></e-column>
          <e-column
            [allowEditing]="false"
            [autoFit]="true"
            [filter]="filterDate"
            [format]="dateFormat"
            clipMode="EllipsisWithTooltip"
            editType="datepickeredit"
            field="created_date"
            headerText="Vytvořeno"
            textAlign="Right"
            type="date"
          ></e-column>
          <e-column
            [allowEditing]="false"
            [autoFit]="true"
            [filter]="filterDate"
            [format]="dateFormat"
            clipMode="EllipsisWithTooltip"
            editType="datepickeredit"
            field="updated_date"
            headerText="Naposledy upraveno"
            textAlign="Right"
            type="date"
          ></e-column>
          <e-column
            [allowEditing]="false"
            [autoFit]="true"
            [filter]="filterDate"
            [format]="dateFormat"
            clipMode="EllipsisWithTooltip"
            editType="datepickeredit"
            field="deleted_date"
            headerText="Smazáno"
            textAlign="Right"
            type="date"
          ></e-column>
        </e-columns>
        <e-aggregates>
          <e-aggregate>
            <e-columns>
              <e-column field="id" type="Count">
                <ng-template #footerTemplate let-data>
                  Celkem řádků: {{ data.Count }}
                </ng-template
                >
              </e-column>
              <e-column
                [format]="'#,###.00\',- CZK\''"
                field="internet_price"
                type="Sum"
              >
                <ng-template #footerTemplate let-data>
                  Celkem internet: {{ data.Sum }}
                </ng-template>
              </e-column>
              <e-column
                [format]="'#,###.00\',- CZK\''"
                field="television_price"
                type="Sum"
              >
                <ng-template #footerTemplate let-data>
                  Celkem televize: {{ data.Sum }}
                </ng-template>
              </e-column>
              <e-column
                [format]="'#,###.00\',- CZK\''"
                field="wifi_price"
                type="Sum"
              >
                <ng-template #footerTemplate let-data>
                  Celkem wi-fi: {{ data.Sum }}
                </ng-template>
              </e-column>
              <e-column
                [format]="'#,###.00\',- CZK\''"
                field="ext_services_price"
                type="Sum"
              >
                <ng-template #footerTemplate let-data>
                  Celkem rozšíření: {{ data.Sum }}
                </ng-template>
              </e-column>
              <e-column
                [format]="'#,###.00\',- CZK\''"
                field="total_price"
                type="Sum"
              >
                <ng-template #footerTemplate let-data>
                  Celkem: {{ data.Sum }}
                </ng-template>
              </e-column>
              <e-column
                [format]="'#,###.00\',- CZK\''"
                field="internet_fee"
                type="Sum"
              >
                <ng-template #footerTemplate let-data>
                  Celkem internet: {{ data.Sum }}
                </ng-template>
              </e-column>
              <e-column
                [format]="'#,###.00\',- CZK\''"
                field="television_fee"
                type="Sum"
              >
                <ng-template #footerTemplate let-data>
                  Celkem televize: {{ data.Sum }}
                </ng-template>
              </e-column>
              <e-column
                [format]="'#,###.00\',- CZK\''"
                field="wifi_fee"
                type="Sum"
              >
                <ng-template #footerTemplate let-data>
                  Celkem wi-fi: {{ data.Sum }}
                </ng-template>
              </e-column>
              <e-column
                [format]="'#,###.00\',- CZK\''"
                field="ext_services_fee"
                type="Sum"
              >
                <ng-template #footerTemplate let-data>
                  Celkem rozšíření: {{ data.Sum }}
                </ng-template>
              </e-column>
              <e-column
                [format]="'#,###.00\',- CZK\''"
                field="total_fee"
                type="Sum"
              >
                <ng-template #footerTemplate let-data>
                  Celkem: {{ data.Sum }}
                </ng-template>
              </e-column>
            </e-columns>
          </e-aggregate>
          <e-aggregate>
            <e-column
              [format]="'#,###.00\',- CZK\''"
              field="internet_price"
              type="Average"
            >
              <ng-template #footerTemplate let-data>
                Průměr internet: {{ data.Average }}
              </ng-template>
            </e-column>
            <e-column
              [format]="'#,###.00\',- CZK\''"
              field="television_price"
              type="Average"
            >
              <ng-template #footerTemplate let-data>
                Průměr televize: {{ data.Average }}
              </ng-template>
            </e-column>
            <e-column
              [format]="'#,###.00\',- CZK\''"
              field="wifi_price"
              type="Sum"
            >
              <ng-template #footerTemplate let-data>
                Průměr wi-fi: {{ data.Average }}
              </ng-template>
            </e-column>
            <e-column
              [format]="'#,###.00\',- CZK\''"
              field="ext_services_price"
              type="Average"
            >
              <ng-template #footerTemplate let-data>
                Průměr rozšíření: {{ data.Average }}
              </ng-template>
            </e-column>
            <e-column
              [format]="'#,###.00\',- CZK\''"
              field="total_price"
              type="Average"
            >
              <ng-template #footerTemplate let-data>
                Průměr celkem: {{ data.Average }}
              </ng-template>
            </e-column>
            <e-column
              [format]="'#,###.00\',- CZK\''"
              field="internet_fee"
              type="Average"
            >
              <ng-template #footerTemplate let-data>
                Průměr internet: {{ data.Average }}
              </ng-template>
            </e-column>
            <e-column
              [format]="'#,###.00\',- CZK\''"
              field="television_fee"
              type="Average"
            >
              <ng-template #footerTemplate let-data>
                Průměr televize: {{ data.Average }}
              </ng-template>
            </e-column>
            <e-column
              [format]="'#,###.00\',- CZK\''"
              field="wifi_fee"
              type="Average"
            >
              <ng-template #footerTemplate let-data>
                Průměr wi-fi: {{ data.Average }}
              </ng-template>
            </e-column>
            <e-column
              [format]="'#,###.00\',- CZK\''"
              field="ext_services_fee"
              type="Average"
            >
              <ng-template #footerTemplate let-data>
                Průměr rozšíření: {{ data.Average }}
              </ng-template>
            </e-column>
            <e-column
              [format]="'#,###.00\',- CZK\''"
              field="total_fee"
              type="Average"
            >
              <ng-template #footerTemplate let-data>
                Průměr celkem: {{ data.Average }}
              </ng-template>
            </e-column>
          </e-aggregate>
          <e-aggregate>
            <e-column
              [format]="'#,###.00\',- CZK\''"
              field="internet_price"
              type="Max"
            >
              <ng-template #footerTemplate let-data>
                Max internet: {{ data.Max }}
              </ng-template>
            </e-column>
            <e-column
              [format]="'#,###.00\',- CZK\''"
              field="television_price"
              type="Max"
            >
              <ng-template #footerTemplate let-data>
                Max televize: {{ data.Max }}
              </ng-template>
            </e-column>
            <e-column
              [format]="'#,###.00\',- CZK\''"
              field="wifi_price"
              type="Max"
            >
              <ng-template #footerTemplate let-data>
                Max wi-fi: {{ data.Max }}
              </ng-template>
            </e-column>
            <e-column
              [format]="'#,###.00\',- CZK\''"
              field="ext_services_price"
              type="Max"
            >
              <ng-template #footerTemplate let-data>
                Max rozšíření: {{ data.Max }}
              </ng-template>
            </e-column>
            <e-column
              [format]="'#,###.00\',- CZK\''"
              field="total_price"
              type="Max"
            >
              <ng-template #footerTemplate let-data>
                Max celkem: {{ data.Max }}
              </ng-template>
            </e-column>
            <e-column
              [format]="'#,###.00\',- CZK\''"
              field="internet_fee"
              type="Max"
            >
              <ng-template #footerTemplate let-data>
                Max internet: {{ data.Max }}
              </ng-template>
            </e-column>
            <e-column
              [format]="'#,###.00\',- CZK\''"
              field="television_fee"
              type="Max"
            >
              <ng-template #footerTemplate let-data>
                Max televize: {{ data.Max }}
              </ng-template>
            </e-column>
            <e-column
              [format]="'#,###.00\',- CZK\''"
              field="wifi_fee"
              type="Max"
            >
              <ng-template #footerTemplate let-data>
                Max wi-fi: {{ data.Max }}
              </ng-template>
            </e-column>
            <e-column
              [format]="'#,###.00\',- CZK\''"
              field="ext_services_fee"
              type="Max"
            >
              <ng-template #footerTemplate let-data>
                Max rozšíření: {{ data.Max }}
              </ng-template>
            </e-column>
            <e-column
              [format]="'#,###.00\',- CZK\''"
              field="total_fee"
              type="Max"
            >
              <ng-template #footerTemplate let-data>
                Max celkem: {{ data.Max }}
              </ng-template>
            </e-column>
          </e-aggregate>
        </e-aggregates>
      </ejs-grid>
    </div>
  </div>
</div>
