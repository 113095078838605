import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule, Routes} from '@angular/router';
import {AuthGuard} from '@src/app/_guards/auth.guard';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {EventsComponent} from '@src/app/features/events/components/events/events.component';
import {EventDetailComponent} from '@src/app/features/events/components/event-detail/event-detail.component';
import {EventsService} from '@src/app/features/events/events.service';
import {SharedFeaturesModule} from '@src/app/shared_features/shared-features.module';
import {EventFormComponent} from './_forms/event-form/event-form.component';

const routes: Routes = [
    {
        path: 'events',
        children: [
            {
                path: '',
                component: EventsComponent,
                data: {
                    title: 'Události nástěnka',
                    breadcrumb: [
                        {
                            label: 'Události',
                            url: '/events',
                        },
                    ],
                },
            },
            {
                path: 'detail/:id',
                component: EventDetailComponent,
                data: {
                    title: 'Detail události',
                    breadcrumb: [
                        {
                            label: 'Události',
                            url: '/events',
                        },
                        {
                            label: 'Detail události',
                            url: '',
                        },
                    ],
                },
            },
        ],
        canActivate: [AuthGuard],
    },
];

@NgModule({
    declarations: [EventDetailComponent, EventFormComponent, EventsComponent],
    imports: [
        BrowserAnimationsModule,
        RouterModule.forChild(routes),
        BrowserModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        SharedFeaturesModule,
    ],
    providers: [EventsService],
    exports: [EventFormComponent],
})
export class EventsModule {}
