<ejs-dialog
  #formDialog
  (close)="
        isUpdate = false;
        isCreate = false;
        isDirty = false;
        isChecked = false;
        isCopy = false;
        submited = false;
        changeDialogState(false)
    "
  (open)="onCreateRte()"
  [isModal]="true"
  [showCloseIcon]="true"
  [visible]="isVisible"
  [width]="'85%'"
  id="formDialog"
>
  <form [formGroup]="reportForm">
    <ng-template #content>
      <div class="dialogContent">
        <div class="row">
          <div class="col-12 mb-3">
            <label
              [ngClass]="
                                f.hotline.valid
                                    ? 'text-success'
                                    : f.hotline.invalid &&
                                      (f.hotline.dirty || f.hotline.touched)
                                    ? 'text-danger'
                                    : 'text-muted'
                            "
              for="reportForm-hotline"
            >* Hot-line:</label
            >
            <ejs-dropdownlist
              #hotline
              (filtering)="onFilteringHotline($event)"
              [allowFiltering]="true"
              [cssClass]="
                                (f.hotline.dirty || f.hotline.touched) &&
                                f.hotline.invalid
                                    ? 'e-error'
                                    : (f.hotline.dirty || f.hotline.touched) &&
                                      f.hotline.valid
                                    ? 'e-success'
                                    : ''
                            "
              [dataSource]="hotlineSelect"
              [fields]="fields"
              [filterBarPlaceholder]="'Vyhledat hot-line...'"
              [ignoreAccent]="true"
              [placeholder]="'Vyberte hot-line'"
              [popupHeight]="height"
              [sortOrder]="'None'"
              formControlName="hotline"
              id="reportForm-hotline"
              sortOrder="None"
            ></ejs-dropdownlist>
            <small
              *ngIf="
                                (f.hotline.dirty || f.hotline.touched) &&
                                f.hotline.errors &&
                                f.hotline.errors.required
                            "
              class="text-danger"
            >Vyberte alespoň jednu hot-line</small
            >
          </div>
          <div class="col-12 mb-3">
            <label
              [ngClass]="
                                f.reportSubject.valid
                                    ? 'text-success'
                                    : f.reportSubject.invalid &&
                                      (f.reportSubject.dirty ||
                                          f.reportSubject.touched)
                                    ? 'text-danger'
                                    : 'text-muted'
                            "
              for="reportForm-subject"
            >* Předmět zápisu:</label
            >
            <ejs-textbox
              [ngClass]="
                                (f.reportSubject.dirty ||
                                    f.reportSubject.touched) &&
                                f.reportSubject.invalid
                                    ? 'e-error'
                                    : (f.reportSubject.dirty ||
                                          f.reportSubject.touched) &&
                                      f.reportSubject.valid
                                    ? 'e-success'
                                    : ''
                            "
              class="disabled"
              floatLabelType="Auto"
              formControlName="reportSubject"
              id="reportForm-subject"
            ></ejs-textbox>
            <small
              *ngIf="
                                (f.reportSubject.dirty ||
                                    f.reportSubject.touched) &&
                                f.reportSubject.errors &&
                                f.reportSubject.errors.required
                            "
              class="text-danger"
            >Předmět zápisu je povinný</small
            >
          </div>
          <div class="col-12">
            <label
              [ngClass]="
                                f.reportText.valid &&
                                (f.reportText.dirty || f.reportText.touched)
                                    ? 'text-success active'
                                    : f.reportText.invalid &&
                                      (f.reportText.dirty ||
                                          f.reportText.touched)
                                    ? 'text-danger active'
                                    : 'text-muted active'
                            "
              for="reportTextEdit"
            >* Text zápisu:</label
            >
            <ejs-richtexteditor
              #reportText
              (actionBegin)="handleFullScreen($event)"
              (actionComplete)="actionCompleteHandler($event)"
              [enablePersistence]="false"
              [fontFamily]="fontFamily"
              [insertImageSettings]="insertImageSettings"
              [maxLength]="maxLength"
              [ngClass]="
                                f.reportText.invalid
                                    ? 'e-error'
                                    : f.reportText.valid
                                    ? 'e-success'
                                    : ''
                            "
              [pasteCleanupSettings]="pasteCleanupSettings"
              [quickToolbarSettings]="quickToolbarSettings"
              [showCharCount]="true"
              [toolbarSettings]="tools"
              formControlName="reportText"
              id="reportTextEdit"
              locale="cs"
              placeholder="Začněte psát report..."
            >
            </ejs-richtexteditor>
            <small
              *ngIf="
                                (f.reportText.dirty || f.reportText.touched) &&
                                f.reportText.errors &&
                                f.reportText.errors.required
                            "
              class="text-danger"
            >Text zápisu je povinný</small
            >
          </div>
        </div>
      </div>
    </ng-template>
    <ng-template #footerTemplate>
      <div class="d-flex justify-content-end">
                <span
                  *ngIf="reportForm.invalid"
                  class="e-badge e-badge-warning p-2 size_2"
                  role="alert"
                >
                    <strong>Formulář je špatně vyplněný</strong>
                </span>
        <span
          *ngIf="reportForm.valid"
          class="e-badge e-badge-success p-2 size_2"
          role="alert"
        >
                    <strong>Formulář je správně vyplněný</strong>
                </span>
      </div>
      <div class="row">
        <div class="col-12 pt-2">
          <button
            (click)="onSubmit()"
            [disabled]="
                            loadingReport || reportForm.invalid || !isChecked
                        "
            cssClass="e-btn e-success btn-p-zero e-small mr-1"
            ejs-button
            type="submit"
          >
                        <span
                          *ngIf="loadingReport === true"
                          aria-hidden="true"
                          class="spinner-grow spinner-grow-sm"
                          role="status"
                        ></span>
            <span *ngIf="loadingReport === false">ULOŽIT</span>
            <span *ngIf="loadingReport === true">Provádím...</span>
          </button>
          <button
            (click)="formDialogObj.hide()"
            cssClass="e-btn e-danger e-outline btn-p-zero e-small"
            ejs-button
            type="button"
          >
            ZRUŠIT
          </button>
        </div>
      </div>
    </ng-template>
  </form>
</ejs-dialog>
