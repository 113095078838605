import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnInit,
    ViewChild,
} from '@angular/core';
import {Router} from '@angular/router';
import {FormBuilder} from '@angular/forms';
import {MessageService} from '@src/app/_services/message.service';
import {VacationCategory} from '@src/app/_models/vacation/vacation-category';
import {Vacation} from '@src/app/_models/vacation/vacation';
import {AuthenticationService} from '@src/app/_services/authentication.service';
import {registerLocaleData} from '@angular/common';
import {DataService} from '@src/app/_services/data.service';
import {FullCalendarComponent} from '@fullcalendar/angular';
import {CalendarOptions} from '@fullcalendar/core';
import csLocale from '@fullcalendar/core/locales/cs';
import {PermissionService} from '@src/app/_services/permission.service';
import {User} from '@src/app/_models/user/user';

import moment from 'moment';
import localeCs from '@angular/common/locales/cs';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {NavHandlerService} from '@src/app/_services/nav-handler.service';
import {VacationService} from '@src/app/features/vacation/vacation.service';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import timeGrid from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import googleCalendarPlugin from '@fullcalendar/google-calendar';

moment.updateLocale('cs', {workingWeekdays: [1, 2, 3, 4, 5]});
registerLocaleData(localeCs);

interface VacationEvents {
    googleCalendarId?: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    vacation_data?: Vacation;
    title?: string;
    start?: string;
    end?: string;
    backgroundColor?: string;
    borderColor?: string;
    textColor?: string;
    allDay?: boolean;
    editable?: boolean;
    startEditable?: boolean;
    durationEditable?: boolean;
    timeFormat?: string;
}

interface IStats {
    vacation: {declined: number; deleted: number; confirmed: number; queue: number};
    homeoffice: {declined: number; deleted: number; confirmed: number; queue: number};
    dayoff: {declined: number; deleted: number; confirmed: number; queue: number};
    doctor: {declined: number; deleted: number; confirmed: number; queue: number};
}

@UntilDestroy()
@Component({
    selector: 'app-vacation-calendar',
    templateUrl: './vacation-calendar.component.html',
    styleUrls: ['./vacation-calendar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VacationCalendarComponent implements OnInit {
    loadingCalendar = false;

    // proměnné pro data
    currentUser: User | null;

    stats: IStats;

    vacations: Vacation[] = [];

    vacation: Vacation;

    categories: VacationCategory[];

    categoriesSelect: Array<{value: boolean | number | string; label: string}> = [];

    calendarVacationEvents: VacationEvents[] = [];

    // FullCalendar
    calendarOptions: CalendarOptions = {
        googleCalendarApiKey: 'AIzaSyCCLvaMHyCqnbJC9DsQTvXGQ8lgS4D_hzo',
        initialView: 'dayGridMonth',
        firstDay: 1,
        locale: csLocale,
        headerToolbar: {
            start: 'title',
            center: 'dayGridMonth,timeGridWeek,listWeek',
            end: 'today prev,next',
        },
        buttonText: {
            today: 'dnes',
            month: 'měsíc',
            week: 'týden',
            day: 'den',
            list: 'seznam',
        },
        weekends: false,
        allDayText: 'celý den',
        dateClick: this.addEvent.bind(this),
        eventClick: this.eventClick.bind(this),
        initialEvents: [],
        eventSources: [],
        eventTimeFormat: {
            hour: '2-digit',
            minute: '2-digit',
            meridiem: false,
        },
        plugins: [dayGridPlugin, interactionPlugin, timeGrid, listPlugin, googleCalendarPlugin],
    };

    @ViewChild('vacationCalendar') calendarComponent?: FullCalendarComponent;

    constructor(
        private readonly router: Router,
        private readonly ref: ChangeDetectorRef,
        private readonly formBuilder: FormBuilder,
        private readonly vacationService: VacationService,
        private readonly permissionService: PermissionService,
        private readonly navHandlerService: NavHandlerService,
        private readonly authenticationService: AuthenticationService,
        private readonly messages: MessageService,
        private readonly dataService: DataService,
    ) {
        this.currentUser = this.authenticationService.currentUserValue;
    }

    private loadData(): void {
        this.calendarVacationEvents = [
            {
                googleCalendarId: 'cs.czech.official#holiday@group.v.calendar.google.com',
            },
        ];

        this.vacations.map((vacations: Vacation) => {
            if (!vacations.deleted_date && !vacations.decline_at) {
                this.calendarVacationEvents.push({
                    vacation_data: vacations,
                    title:
                        vacations.creator.firstname +
                        ' ' +
                        vacations.creator.secondname +
                        ' (' +
                        vacations.category.name +
                        ')',
                    start: moment(vacations.start_at)
                        .format('YYYY-MM-DD HH:mm:ss'),
                    end: moment(vacations.end_at)
                        .format('YYYY-MM-DD HH:mm:ss'),
                    backgroundColor:
                        (vacations.decline || vacations.confirm) && !vacations.decline_at
                            ? vacations.category.colortag
                            : '#666666',
                    borderColor:
                        (vacations.decline || vacations.confirm) && !vacations.decline_at
                            ? vacations.category.colortag
                            : '#666666',
                    textColor: '#ffffff',
                    allDay: false,
                    editable: true,
                    startEditable: true,
                    durationEditable: true,
                    timeFormat: 'H:mm',
                });
            }
        });

        this.calendarOptions.eventSources = [
            {
                googleCalendarId: 'cs.czech.official#holiday@group.v.calendar.google.com',
                color: 'yellow',
                backgroundColor: '#ff8c00',
                borderColor: '#ff8c00',
                textColor: '#ffffff',
                editable: false,
                startEditable: false,
                durationEditable: false,
            },
            this.calendarVacationEvents,
        ];
        this.loadingCalendar = false;
        this.refreshContent();
        this.ref.markForCheck();
    }

    ngOnInit(): void {
        // defaultní stavy proměnných
        this.loadingCalendar = true;
        this.stats = {
            vacation: {confirmed: 0, declined: 0, deleted: 0, queue: 0},
            homeoffice: {confirmed: 0, declined: 0, deleted: 0, queue: 0},
            dayoff: {confirmed: 0, declined: 0, deleted: 0, queue: 0},
            doctor: {confirmed: 0, declined: 0, deleted: 0, queue: 0},
        };

        this.navHandlerService.navState.pipe(untilDestroyed(this))
            .subscribe(
                () => {
                    this.refreshContent();
                },
                error => {
                    console.error(error);
                },
            );

        // subscribe dat kategorií absence
        this.vacationService
            .getActiveCategories()
            .pipe(untilDestroyed(this))
            .subscribe(
                (categories: VacationCategory[]) => {
                    this.categories = categories;
                    categories.map((category: VacationCategory) => {
                        this.categoriesSelect = [
                            ...this.categoriesSelect,
                            {value: category.id, label: category.name},
                        ];
                    });
                    this.ref.markForCheck();
                },
                error => {
                    console.error(error);
                },
            );

        // subscribe dat absence
        this.dataService.vacationSource.pipe(untilDestroyed(this))
            .subscribe(
                (data: Vacation[]) => {
                    this.loadingCalendar = true;
                    this.vacations = data;
                    this.loadData();
                },
                error => {
                    console.error(error);
                },
            );
    }

    refreshContent(): void {
        setTimeout(() => {
            if (this.calendarComponent) {
                this.calendarComponent.getApi()
                    .render();
            }
        }, 1000);
    }

    addEvent(arg): void {
        console.info(arg);
    }

    eventClick(arg): void {
        if (arg.event.extendedProps.vacation_data === 'publicHoliday') {
            const body = 'Státní svátek nemá náhled ani deatil...';
            const options = {progressBar: true, timeOut: 5000, toastClass: 'red'};

            this.messages.showWarning('Státní svátek', body, options);
        } else {
            void this.router.navigate([
                '/vacation/detail',
                arg.event.extendedProps.vacation_data.id,
            ]);
        }
    }
}
