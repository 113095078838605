<div class="e-card">
  <ng-container *ngIf="knowledge">
    <div class="e-card-header stylish-color-dark p-2">
      <div class="e-card-header-caption">
        <div class="d-flex justify-content-between e-card-header-title text-white">
          <strong>Pracovní postup</strong>
          <span>ID: {{ knowledge.id }}</span>
        </div>
      </div>
    </div>
    <div class="e-card-content p-2">
      <div class="text-center mb-1">
        <h6 class="font-weight-bold">
          {{ knowledge.subject }}
        </h6>
        <span class="text-muted font-weight-bold">
                    <img
                      alt="profilový obrázek"
                      class="img-fluid rounded-circle avatar-xs mr-1"
                      src="assets/img{{knowledge.creator.personalphoto}}"
                    />
          {{ knowledge.creator.firstname }}
          {{ knowledge.creator.secondname }}
                </span>
        <div class="e-card-separator mb-1 mt-1"></div>
        <div
          *ngIf="knowledge.unixCreatedTime"
          class="d-flex mb-2 text-center text-muted justify-content-center"
        >
          <small class="text-muted font-weight-bold">
            vytvořeno:
            {{ knowledge.created_date | dateFormat: 'DD.MM.YYYY HH:mm' }}
            <br />
            ( {{ knowledge.unixCreatedTime | timeago: live }} )
          </small>
        </div>
        <div
          *ngIf="knowledge.unixUpdatedTime"
          class="d-flex mb-2 text-center text-muted justify-content-center"
        >
          <small class="text-muted font-weight-bold">
            poslední změna:
            {{ knowledge.updated_date | dateFormat: 'DD.MM.YYYY HH:mm' }}
            <br />
            ( {{ knowledge.unixUpdatedTime | timeago: live }} )
          </small>
        </div>
        <div
          *ngIf="knowledge.unixDeletedTime"
          class="d-flex mb-2 text-center text-muted justify-content-center"
        >
          <small class="font-weight-bold">
            smazáno:
            {{ knowledge.deleted_date | dateFormat: 'DD.MM.YYYY HH:mm' }}
            <br />
            ( {{ knowledge.unixDeletedTime | timeago: live }} )
          </small>
        </div>
        <div class="e-card-separator mb-1 mt-1"></div>
        <small
          *ngIf="knowledge.category"
          class="text-muted font-weight-bold"
          style="font-weight: bold"
        >
          Kategorie: {{ knowledge.category.name }}
        </small>
      </div>
      <div class="e-card-separator mb-1 mt-1"></div>
      <div
        aria-label="Assignations toolbar"
        class="d-flex justify-content-center"
        role="group"
      >
        <button
          (click)="addUser()"
          *ngIf="!knowledge.deleted_date"
          cssClass="e-btn e-success btn-p-zero e-small"
          ejs-button
          title="přidat uživatele do pracovního postupu"
        >
          <i
            aria-hidden="true"
            class="fa-light fa-user-plus mr-1"
          ></i>
          zapojit uživatele
        </button>
        <button
          (click)="addDepartments()"
          *ngIf="!knowledge.deleted_date"
          cssClass="e-btn e-success btn-p-zero e-small"
          ejs-button
          title="přidat oddělení do pracovního postupu"
        >
          <i
            aria-hidden="true"
            class="fa-light fa-users mr-1"
          ></i>
          zapojit oddělení
        </button>
      </div>
      <div
        *ngIf="knowledge.departments || knowledge.users"
        class="mt-1"
        style="max-height: 250px; overflow-y: auto"
      >
        <small class="font-weight-bold">
          Řešitelé&nbsp;({{
            (knowledge.departments.length
              ? knowledge.departments.length
              :0) +
            (knowledge.users.length
              ? knowledge.users.length
              :0)
          }}):
        </small
        >
        <div class="mt-0 mb-0">
          <ejs-textbox
            cssClass="side_search_input"
            floatLabelType="Auto"
            id="solversFilterInput"
            placeholder="Vyhledat řešitele..."
          ></ejs-textbox>
        </div>
        <ul
          class="list-group list-group-flush"
          id="solversList"
        >
          <li
            *ngFor="let user of knowledge.users; let i = index"
            class="list-group-item list-group-item-action p-1 border-top-0"
          >
            <img
              alt="profilový obrázek"
              class="img-fluid rounded-circle avatar-xs mr-1 ml-2"
              src="assets/img{{ user.personalphoto }}"
            />
            <span class="float-right text-right">
                                    <small class="font-weight-bold">
                                        {{ user.firstname }} {{ user.secondname }}
                                    </small>
                                    <br />
                                    <small>
                                        <button
                                          (click)="deleteUser(user)"
                                          *ngIf="
                                                knowledge &&
                                                (knowledge.created_by ===
                                                    currentUser.id ||
                                                    (currentUser.rule &&
                                                        currentUser.rule
                                                            .is_admin) ||
                                                    knowledge.creator
                                                        .boss_id ===
                                                        currentUser.id) &&
                                                !knowledge.deleted_date
                                            "
                                          cssClass="e-btn e-danger btn-p-zero e-small"
                                          ejs-button
                                          type="button"
                                        >
                                            <i
                                              aria-hidden="true"
                                              class="fa-light fa-times mr-lg-1"
                                            ></i>
                                            <span class="d-none d-lg-inline">
                                                ZRUŠIT
                                            </span>
                                        </button>
                                    </small>
                                </span>
          </li>
          <li
            *ngFor="let department of knowledge.departments; let i = index"
            class="list-group-item list-group-item-action p-1"
          >
            <i
              aria-hidden="true"
              class="fa-light fa-users fa-lg ml-1"
            ></i>
            <span class="float-right text-right">
                            <small class="font-weight-bold">
                                {{ department.name }}&nbsp;&middot;&nbsp;{{ department.company.name }}
                            </small>
                            <br />
                            <small>
                                <button
                                  (click)="deleteDepartment(department)"
                                  *ngIf="
                                                knowledge &&
                                                (knowledge.created_by ===
                                                    currentUser.id ||
                                                    (currentUser.rule &&
                                                        currentUser.rule
                                                            .is_admin) ||
                                                    knowledge.creator
                                                        .boss_id ===
                                                        currentUser.id) &&
                                                !knowledge.deleted_date
                                            "
                                  cssClass="e-btn e-danger btn-p-zero e-small"
                                  ejs-button
                                  type="button"
                                >
                                    <i
                                      aria-hidden="true"
                                      class="fa-light fa-times mr-lg-1"
                                    ></i>
                                    <span class="d-none d-lg-inline">
                                        ZRUŠIT
                                    </span>
                                </button>
                            </small>
                        </span>
          </li>
        </ul>
      </div>
    </div>
  </ng-container>
</div>
