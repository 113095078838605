<form [formGroup]="addressForm">
  <div class="col-12 mb-3 p-1">
    <ejs-textbox
      (focusin)="reinitSmartForm()"
      [ngClass]="
                addressFormCtrl.address.touched && addressForm.invalid
                    ? 'e-error'
                    : addressFormCtrl.address.touched && addressForm.valid
                    ? 'e-success'
                    : ''
            "
      autocomplete="smartform-auto-address"
      class="smartform-address-whole-address smartform-instance-address"
      floatLabelType="Auto"
      formControlName="address"
      id="searchAddressFormAddress"
      placeholder="* Adresa:"
    ></ejs-textbox>
    <small
      *ngIf="
                addressFormCtrl.address.touched &&
                addressFormCtrl.address.errors &&
                addressFormCtrl.address.errors.required
            "
      class="text-danger"
    >Vyberte adresu pomocí našeptávače</small
    >
  </div>
</form>
<ng-container *ngIf="addressLoading">
  <div class="row">
    <div class="col-12 text-center p-2">
      <app-loader></app-loader>
    </div>
  </div>
</ng-container>
<div class="map">
  <google-map>
    <map-marker
      #markerElem
      (mapClick)="openInfo(markerElem, marker.info)"
      *ngFor="let marker of markers"
      [label]="marker.label"
      [options]="marker.options"
      [position]="marker.position"
      [title]="marker.title"
    >
    </map-marker>

    <map-info-window>{{ infoContent }}</map-info-window>
  </google-map>
  <button (click)="zoomIn()">Zoom in</button>
  <button (click)="zoomOut()">Zoom out</button>
  <button (click)="logCenter()">Log center</button>
  <button (click)="addMarker()">Add marker</button>
</div>
