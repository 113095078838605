import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    HostListener,
    Input,
    OnChanges,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {DataService} from '@src/app/_services/data.service';
import {DataNetbaseService} from '@src/app/_services/data-netbase.service';
import {DealInput, ServicesService} from '@src/app/features/services/services.service';
import {MessageService} from '@src/app/_services/message.service';
import {AuthenticationService} from '@src/app/_services/authentication.service';
import {DealTypes} from '@src/app/_models/services/deal-types';
import {User} from '@src/app/_models/user/user';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {DialogComponent} from '@syncfusion/ej2-angular-popups';
import {Observable} from 'rxjs';
import {ComponentCanDeactivate} from '@src/app/_guards/changes.guard';
import {ActivatedRoute, Params, Router} from '@angular/router';

@UntilDestroy()
@Component({
    selector: 'app-deal-type-form',
    templateUrl: './deal-type-form.component.html',
    styleUrls: ['./deal-type-form.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DealTypeFormComponent implements OnInit, OnChanges, ComponentCanDeactivate {
    // Forms
    dealTypeForm: FormGroup;

    isDirty = false;

    isChecked = false;

    submited = false;

    // Variables
    currentUser: User | null;

    // Loaders
    loadingDealType = false;

    @Input() dealType: DealTypes | null = null;

    @Input() isCreate = false;

    @Input() isUpdate = false;

    @Input() isVisible = false;

    @Input() isCopy = false;

    @Output() readonly dealTypeFormDialogState = new EventEmitter<boolean>();

    // Dialogs
    @ViewChild('formDialog') formDialogObj: DialogComponent;

    constructor(
        private readonly router: Router,
        private readonly route: ActivatedRoute,
        private readonly formBuilder: FormBuilder,
        private readonly ref: ChangeDetectorRef,
        private readonly dataService: DataService,
        private readonly dataNetbaseService: DataNetbaseService,
        private readonly servicesService: ServicesService,
        private readonly messages: MessageService,
        private readonly authenticationService: AuthenticationService,
    ) {
        this.currentUser = this.authenticationService.currentUserValue;
    }

    get f(): {[key: string]: AbstractControl} {
        return this.dealTypeForm.controls;
    }

    @HostListener('window:beforeunload')
    canDeactivate(): Observable<boolean> | boolean {
        return !this.isDirty;
    }

    ngOnInit(): void {
        this.dealTypeForm = this.formBuilder.group({
            age: [
                0,
                Validators.compose([Validators.required, Validators.max(100), Validators.min(0)]),
            ],
            inst_price: [0, Validators.compose([Validators.required, Validators.min(0)])],
            description: [''],
        });

        this.dealTypeForm.valueChanges.pipe(untilDestroyed(this))
            .subscribe(() => {
                this.isDirty = this.dealTypeForm.dirty;
                this.showInputErrors();
            });
    }

    ngOnChanges(): void {
        if (this.isVisible) {
            const queryParams: Params = {form: null};

            void this.router.navigate([], {
                relativeTo: this.route,
                queryParams,
                queryParamsHandling: 'merge',
            });

            if (this.isUpdate && this.dealType) {
                this.dealTypeForm.reset();
                this.formDialogObj.header = `Smluvní úvazek #${this.dealType.id}`;
                this.fillTheForm();
                this.showInputErrors();
            }

            if (this.isCreate) {
                this.dealTypeForm.reset();
                this.formDialogObj.header = 'Nový smluvní úvazek';
            }

            if (this.isCopy) {
                this.dealTypeForm.reset();
                this.formDialogObj.header = 'Nový smluvní úvazek';
                this.fillTheForm();
                this.showInputErrors();
            }
        }
    }

    changeDialogState(value: boolean): void {
        this.dealTypeFormDialogState.emit(value);
    }

    fillTheForm(): void {
        this.dealTypeForm.controls.age.patchValue(this.dealType?.age);
        this.dealTypeForm.controls.inst_price.patchValue(this.dealType?.inst_price);
        this.dealTypeForm.controls.description.patchValue(this.dealType?.description);
    }

    onSubmit(): void {
        this.submited = true;
        this.isDirty = false;
        this.loadingDealType = true;

        if (this.isCreate || this.isCopy) {
            this.createDealType();
        }

        if (this.isUpdate) {
            this.editDealType();
        }
    }

    createDealType(): void {
        if (this.dealTypeForm.invalid) {
            console.error('form is not valid!');

            return;
        }

        this.loadingDealType = true;

        const dealTypeData: DealInput = {
            age: this.f.age.value,
            inst_price: this.f.inst_price.value,
            description: this.f.description.value,
            created_by: this.currentUser?.id,
        };

        this.servicesService
            .addDealType(dealTypeData)
            .pipe(untilDestroyed(this))
            .subscribe(
                (data: DealTypes) => {
                    const body = `${data.age} měsíců období přidáno do nabídky`;
                    const options = {progressBar: true, timeOut: 5000};

                    this.messages.showSuccess(
                        'Typ smluvního úvazku úspěšně vytvořen',
                        body,
                        options,
                    );
                    this.dataNetbaseService.setDealTypesDataSource();
                    this.isCreate = false;
                    this.loadingDealType = false;
                    this.ref.markForCheck();
                    this.formDialogObj.hide();
                },
                error => {
                    console.error(error);

                    const body = 'Zkuste to znovu později';
                    const options = {
                        progressBar: true,
                        timeOut: 5000,
                        toastClass: 'red',
                    };

                    this.messages.showError('Chyba při vytváření smluvního úvazku', body, options);
                    this.loadingDealType = false;
                    this.ref.markForCheck();
                },
            );
    }

    editDealType(): void {
        if (this.dealTypeForm.invalid || !this.currentUser) {
            console.error('form is not valid! || this.currentUser is missing...');

            return;
        }

        this.loadingDealType = true;

        const data: DealInput = {
            id: this.dealType?.id,
            age: this.f.age.value,
            inst_price: this.f.inst_price.value,
            description: this.f.description.value,
            updated_by: this.currentUser.id,
        };

        this.servicesService
            .updateDealType(data)
            ?.pipe(untilDestroyed(this))
            .subscribe(
                (result: DealTypes) => {
                    const body = `${result.age} měsíců období upraveno`;
                    const options = {progressBar: true, timeOut: 5000};

                    this.messages.showSuccess(
                        'Typ smluvního úvazku úspěšně upraven',
                        body,
                        options,
                    );
                    this.dataNetbaseService.setDealTypesDataSource();
                    this.isUpdate = false;
                    this.loadingDealType = false;
                    this.ref.markForCheck();
                    this.formDialogObj.hide();
                },
                error => {
                    console.error(error);

                    const body = 'Zkuste to znovu později';
                    const options = {
                        progressBar: true,
                        timeOut: 5000,
                        toastClass: 'red',
                    };

                    this.messages.showError('Chyba při úpravě smluvního úvazku', body, options);
                    this.loadingDealType = false;
                    this.ref.markForCheck();
                },
            );
    }

    showInputErrors(): void {
        this.isChecked = true;
        /* console.log('VALID: ' + this.ticketForm.valid);
        Object.keys(this.ticketForm.controls).forEach(key => {
            const controlErrors: ValidationErrors = this.ticketForm.get(key).errors;
            if (controlErrors != null) {
                Object.keys(controlErrors).forEach(keyError => {
                    console.log('Key control: ' + key + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError]);
                });
            }
        });*/
        this.dealTypeForm.markAllAsTouched();
        this.ref.markForCheck();
    }
}
