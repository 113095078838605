import { BackendEntry, VersionEntry } from './utils/environment-config';

export const ENVIRONMENT = {
    production: true,
};

export const BACKEND_URL: { [key: string]: BackendEntry } = {
    test: {
        backendURL: 'https://188.75.128.94:3001',
    },
    dev: {
        backendURL: 'http://localhost:3001',
    },
    prod: {
        backendURL: 'https://system.jon.cz:3003',
    },
};

export const VERSION_URL: { [key: string]: VersionEntry } = {
    test: {
        versionURL: 'https://188.75.128.94/version.json',
    },
    dev: {
        versionURL: 'https://localhost/version.json',
    },
    prod: {
        versionURL: 'https://system.jon.cz/version.json',
    },
};
