import {CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TimeagoModule} from 'ngx-timeago';
import {ReactiveFormsModule} from '@angular/forms';
import {FullCalendarModule} from '@fullcalendar/angular';
import {InfiniteScrollModule} from 'ngx-infinite-scroll';
import {NgxPaginationModule} from 'ngx-pagination';
import {PermissionService} from '@src/app/_services/permission.service';
import {ElasticResultsComponent} from '@src/app/_modals/elastic-results/elastic-results.component';
import {RouterModule} from '@angular/router';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {TagInputModule} from 'ngx-chips';
import {NgxDocViewerModule} from '@src/app/_viewer';
import {CountdownModule} from 'ngx-countdown';
import {
    AggregateService,
    ColumnMenuService,
    EditService,
    ExcelExportService,
    FilterService,
    ForeignKeyService,
    GridAllModule,
    GroupService,
    PageService,
    PdfExportService,
    ReorderService,
    ResizeService,
    SortService,
    ToolbarService,
    VirtualScrollService,
} from '@syncfusion/ej2-angular-grids';
import {
    ButtonAllModule,
    CheckBoxAllModule,
    RadioButtonAllModule,
    SwitchAllModule,
} from '@syncfusion/ej2-angular-buttons';
import {
    AutoCompleteModule,
    CheckBoxSelectionService,
    ComboBoxAllModule,
    DropDownListAllModule,
    MultiSelectAllModule,
} from '@syncfusion/ej2-angular-dropdowns';
import {
    DatePickerAllModule,
    DateRangePickerAllModule,
    DateTimePickerAllModule,
    TimePickerAllModule,
} from '@syncfusion/ej2-angular-calendars';
import {
    AccordionAllModule,
    ContextMenuAllModule,
    SidebarModule,
    TabAllModule,
    ToolbarModule,
} from '@syncfusion/ej2-angular-navigations';
import {
    NumericTextBoxAllModule,
    TextBoxAllModule,
    UploaderAllModule,
} from '@syncfusion/ej2-angular-inputs';
import {DialogModule, TooltipModule} from '@syncfusion/ej2-angular-popups';
import {
    DetailsViewService,
    FileManagerAllModule,
    NavigationPaneService,
} from '@syncfusion/ej2-angular-filemanager';
import {CircularGaugeAllModule} from '@syncfusion/ej2-angular-circulargauge';
import {InPlaceEditorModule, RteService} from '@syncfusion/ej2-angular-inplace-editor';
import {
    FileManagerService,
    HtmlEditorService,
    ImageService,
    LinkService,
    RichTextEditorAllModule,
    TableService,
} from '@syncfusion/ej2-angular-richtexteditor';
import {TreeGridAllModule} from '@syncfusion/ej2-angular-treegrid';
import {
    AccumulationChartAllModule,
    ChartAllModule,
    RangeNavigatorAllModule,
} from '@syncfusion/ej2-angular-charts';
import {DashboardLayoutModule} from '@syncfusion/ej2-angular-layouts';
import {ProgressBarAllModule} from '@syncfusion/ej2-angular-progressbar';
import {NavHandlerService} from '@src/app/_services/nav-handler.service';
import {CarsService} from '@src/app/_api/cars/cars.service';
import {AcsService} from '@src/app/_api/acs/acs.service';
import {ErpService} from '@src/app/_api/erp/erp.service';
import {CrmService} from '@src/app/_api/crm/crm.service';
import {ApisService} from '@src/app/_api/apis.service';
import {MonitoringService} from '@src/app/_api/monitoring/monitoring.service';
import {ListViewAllModule} from '@syncfusion/ej2-angular-lists';
import {DropDownButtonAllModule} from '@syncfusion/ej2-angular-splitbuttons';
import {GoogleMapsModule} from '@angular/google-maps';
import {LightboxModule} from 'ngx-lightbox';
import {FileSizePipe} from '@src/app/shared_components/pipes/file-size.pipe';
import {FilterPipe} from '@src/app/shared_components/pipes/filter.pipe';
import {MomentPipe} from '@src/app/shared_components/pipes/moment.pipe';
import {TaskFormComponent} from '@src/app/features/tasks/_forms/task-form/task-form.component';
import {ThreadsPipe} from '@src/app/shared_components/pipes/threads.pipe';
import {TruncateTextPipe} from '@src/app/shared_components/pipes/truncate-text.pipe';
import {UrlencodePipe} from '@src/app/shared_components/pipes/urlencode.pipe';
import {CommentsService} from '@src/app/shared_components/services/comments.service';
import {LuxonModule} from 'luxon-angular';
import {HumanizeBytesPipe} from './pipes/humanize-bytes.pipe';
import {FileExtensionPipe} from './pipes/file-extension.pipe';

TagInputModule.withDefaults({
    tagInput: {
        placeholder: 'Přidat nový štítek',
        secondaryPlaceholder: '+ přidat štítek',
    },
    dropdown: {
        showDropdownIfEmpty: true,
        keepOpen: false,
        focusFirstElement: true,
        minimumTextLength: 1,
        limitItemsTo: 20,
        zIndex: 100000,
    },
});

@NgModule({
    imports: [
        AccordionAllModule,
        AccumulationChartAllModule,
        AutoCompleteModule,
        ButtonAllModule,
        ChartAllModule,
        CheckBoxAllModule,
        CircularGaugeAllModule,
        ComboBoxAllModule,
        TimeagoModule.forRoot(),
        CommonModule,
        ContextMenuAllModule,
        CountdownModule,
        DashboardLayoutModule,
        DatePickerAllModule,
        DateRangePickerAllModule,
        DateTimePickerAllModule,
        DialogModule,
        DragDropModule,
        DropDownButtonAllModule,
        DropDownListAllModule,
        FileManagerAllModule,
        FullCalendarModule,
        GoogleMapsModule,
        GridAllModule,
        InfiniteScrollModule,
        InPlaceEditorModule,
        LightboxModule,
        ListViewAllModule,
        LuxonModule,
        MultiSelectAllModule,
        NgxDocViewerModule,
        NgxPaginationModule,
        NumericTextBoxAllModule,
        ProgressBarAllModule,
        RadioButtonAllModule,
        RangeNavigatorAllModule,
        ReactiveFormsModule,
        RichTextEditorAllModule,
        RouterModule,
        SidebarModule,
        SwitchAllModule,
        TabAllModule,
        TagInputModule,
        TextBoxAllModule,
        TimePickerAllModule,
        ToolbarModule,
        TooltipModule,
        TreeGridAllModule,
        UploaderAllModule,
    ],
    declarations: [
        ElasticResultsComponent,
        FileExtensionPipe,
        FileSizePipe,
        FilterPipe,
        HumanizeBytesPipe,
        MomentPipe,
        TaskFormComponent,
        ThreadsPipe,
        TruncateTextPipe,
        UrlencodePipe,
    ],
    exports: [
        AccordionAllModule,
        AccumulationChartAllModule,
        AutoCompleteModule,
        ButtonAllModule,
        ChartAllModule,
        CheckBoxAllModule,
        CircularGaugeAllModule,
        ComboBoxAllModule,
        ContextMenuAllModule,
        CountdownModule,
        DashboardLayoutModule,
        DatePickerAllModule,
        DateRangePickerAllModule,
        DateTimePickerAllModule,
        DialogModule,
        DragDropModule,
        DropDownButtonAllModule,
        DropDownListAllModule,
        ElasticResultsComponent,
        FileExtensionPipe,
        FileManagerAllModule,
        FileSizePipe,
        FilterPipe,
        FullCalendarModule,
        GoogleMapsModule,
        GridAllModule,
        HumanizeBytesPipe,
        InfiniteScrollModule,
        InPlaceEditorModule,
        LightboxModule,
        ListViewAllModule,
        LuxonModule,
        MomentPipe,
        MultiSelectAllModule,
        NgxDocViewerModule,
        NgxPaginationModule,
        NumericTextBoxAllModule,
        ProgressBarAllModule,
        RadioButtonAllModule,
        RangeNavigatorAllModule,
        RichTextEditorAllModule,
        SidebarModule,
        SwitchAllModule,
        TabAllModule,
        TagInputModule,
        TaskFormComponent,
        TextBoxAllModule,
        ThreadsPipe,
        TimeagoModule,
        TimePickerAllModule,
        ToolbarModule,
        TooltipModule,
        TreeGridAllModule,
        TruncateTextPipe,
        UploaderAllModule,
        UrlencodePipe,
    ],
    providers: [
        AcsService,
        AggregateService,
        ApisService,
        CarsService,
        CheckBoxSelectionService,
        ColumnMenuService,
        CommentsService,
        CrmService,
        DetailsViewService,
        EditService,
        ErpService,
        ExcelExportService,
        FileManagerService,
        FilterService,
        ForeignKeyService,
        GroupService,
        HtmlEditorService,
        ImageService,
        LinkService,
        MonitoringService,
        NavHandlerService,
        NavigationPaneService,
        PageService,
        PdfExportService,
        PermissionService,
        ReorderService,
        ResizeService,
        RteService,
        SortService,
        TableService,
        ToolbarService,
        VirtualScrollService,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class SharedModule {}
