import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    HostListener,
    Input,
    OnChanges,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import {
    AbstractControl,
    FormArray,
    FormBuilder,
    FormControl,
    FormGroup,
    ValidatorFn,
    Validators,
} from '@angular/forms';
import {Tags} from '@src/app/_models/tags/tags';
import {Observable} from 'rxjs';
import {User} from '@src/app/_models/user/user';
import {Department} from '@src/app/_models/department/department';
import {Tickets} from '@src/app/_models/ticket/tickets';
import {DropDownListComponent, FilteringEventArgs, MultiSelectComponent} from '@syncfusion/ej2-angular-dropdowns';
import {DialogComponent} from '@syncfusion/ej2-angular-popups';
import {DataService} from '@src/app/_services/data.service';
import {AuthenticationService} from '@src/app/_services/authentication.service';
import {MessageService} from '@src/app/_services/message.service';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {addClass, Browser, createElement, EmitType, isNullOrUndefined, removeClass} from '@syncfusion/ej2-base';
import {Query} from '@syncfusion/ej2-data';
import {
    FontFamilyModel,
    ImageSettingsModel,
    QuickToolbarSettingsModel,
    RichTextEditorComponent,
} from '@syncfusion/ej2-angular-richtexteditor';
import {ToolbarModule} from '@syncfusion/ej2-angular-navigations';
import moment from 'moment';
import localeCs from '@angular/common/locales/cs';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {registerLocaleData} from '@angular/common';
import {TicketCategory} from '@src/app/_models/ticket/ticket-category';
import {ComponentCanDeactivate} from '@src/app/_guards/changes.guard';
import {FileInfo, RemovingEventArgs, SelectedEventArgs, UploaderComponent} from '@syncfusion/ej2-angular-inputs';
import {TicketFile} from '@src/app/_models/ticket/ticket-file';
import {EnvironmentService} from '@src/app/_services/environment.service';
import {MomentInput} from 'moment/moment';
import {map} from 'rxjs/operators';
import {BaseModel} from '@src/app/_models/_base/base-model';
import {TicketInput, TicketsService} from '@src/app/features/tickets/tickets.service';
import {TicketComponent} from '@src/app/features/tickets/components';
import {NavHandlerService} from '@src/app/_services/nav-handler.service';
import {TimeagoIntl} from 'ngx-timeago';

moment.updateLocale('cs', {workingWeekdays: [1, 2, 3, 4, 5]});
registerLocaleData(localeCs);

@UntilDestroy()
@Component({
    selector: 'app-ticket-form',
    templateUrl: './ticket-form.component.html',
    styleUrls: ['./ticket-form.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TicketFormComponent extends TicketComponent implements OnInit, OnChanges, ComponentCanDeactivate {
    // RTE
    maxLength = 15000;

    textArea: HTMLElement;

    insertImageSettings: ImageSettingsModel;

    fontFamily: FontFamilyModel = {
        items: [
            {
                text: 'Segoe UI',
                value: 'Segoe UI',
                command: 'Font',
                subCommand: 'FontName',
            },
            {
                text: 'Roboto',
                value: 'Roboto',
                command: 'Font',
                subCommand: 'FontName',
            },
            {
                text: 'Great vibes',
                value: 'Great Vibes,cursive',
                command: 'Font',
                subCommand: 'FontName',
            },
            {
                text: 'Noto Sans',
                value: 'Noto Sans',
                command: 'Font',
                subCommand: 'FontName',
            },
            {
                text: 'Impact',
                value: 'Impact,Charcoal,sans-serif',
                command: 'Font',
                subCommand: 'FontName',
            },
            {
                text: 'Tahoma',
                value: 'Tahoma,Geneva,sans-serif',
                command: 'Font',
                subCommand: 'FontName',
            },
        ],
    };

    tools: ToolbarModule = {
        type: 'MultiRow',
        items: [
            'Bold',
            'Italic',
            'Underline',
            'StrikeThrough',
            'FontName',
            'FontSize',
            'FontColor',
            'BackgroundColor',
            'LowerCase',
            'UpperCase',
            'SuperScript',
            'SubScript',
            '|',
            'Formats',
            'Alignments',
            'OrderedList',
            'UnorderedList',
            'Outdent',
            'Indent',
            '|',
            'CreateTable',
            'CreateLink',
            'Image',
            '|',
            'ClearFormat',
            'Print',
            'SourceCode',
            'FullScreen',
            '|',
            'Undo',
            'Redo',
        ],
    };

    quickToolbarSettings: QuickToolbarSettingsModel = {
        table: [
            'TableHeader',
            'TableRows',
            'TableColumns',
            'TableCell',
            '-',
            'BackgroundColor',
            'TableRemove',
            'TableCellVerticalAlign',
            'Styles',
        ],
    };

    pasteCleanupSettings: object = {
        prompt: true,
        plainText: false,
        keepFormat: false,
        deniedTags: ['a'],
        deniedAttrs: ['class', 'title', 'id'],
        allowedStyleProps: ['color', 'margin', 'font-size'],
    };

    // Upload
    path: {
        saveUrl: string;
        removeUrl: string;
    };

    autoUpload = false;

    dropElement: HTMLElement = document.getElementsByClassName('control-fluid')[0] as HTMLElement;

    removeFile: TicketFile | undefined;

    deleteFileID: number;

    // Dialogs
    buttonsDeleteFileDialog = [
        {
            click: this.removeTicketFile.bind(this),
            buttonModel: {
                content: 'OK',
                cssClass: 'e-success e-outline',
                isFlat: false,
            },
        },
        {
            click: (): void => {
                this.deleteFileDialogObj.hide();
            },
            buttonModel: {content: 'ZRUŠIT', cssClass: 'e-danger e-outline'},
        },
    ];

    // Dropdowns
    height = '240px';

    fields: object = {text: 'label', value: 'value'};

    usersSelect: Array<{value: boolean | number | string; label: string}> = [];

    tagsAutocomplete: Array<{id: number; name: string}> = [];

    departmentSelect: Array<{value: boolean | number | string; label: string}> = [];

    categorySelect: Array<{value: boolean | number | string; label: string}> = [];

    // Forms
    filterTag: Tags[];

    ticketForm: FormGroup;

    assignationUserList: FormArray;

    assignationDepartmentList: FormArray;

    isDirty = false;

    isChecked = false;

    submited = false;

    isCompany = false;

    // Variables
    today = new Date();

    currentUser: User | null;

    // Loaders
    ticketLoading = false;

    @Input() ticket?: Tickets | null = null;

    @Input() isCreate = false;

    @Input() isUpdate = false;

    @Input() isVisible = false;

    @Input() isCopy = false;

    @Output() readonly ticketFormDialogState = new EventEmitter<boolean>();

    // RTE
    @ViewChild('mainTask') mainTaskObj?: RichTextEditorComponent;

    // Upload
    @ViewChild('preloadupload') uploadObj: UploaderComponent;

    // Dialogs
    @ViewChild('formDialog') formDialogObj: DialogComponent;

    @ViewChild('deleteFileDialog') deleteFileDialogObj: DialogComponent;

    // Dropdowns
    @ViewChild('category') categoryObj: DropDownListComponent;

    @ViewChild('departments') departmentsObj: MultiSelectComponent;

    @ViewChild('users') usersObj: MultiSelectComponent;

    constructor(
        authenticationService: AuthenticationService,
        router: Router,
        ref: ChangeDetectorRef,
        navHandlerService: NavHandlerService,
        route: ActivatedRoute,
        dataService: DataService,
        intl: TimeagoIntl,
        private readonly formBuilder: FormBuilder,
        private readonly ticketService: TicketsService,
        private readonly messages: MessageService,
        private readonly environmentService: EnvironmentService,
    ) {
        super(
            authenticationService,
            router,
            ref,
            navHandlerService,
            route,
            dataService,
            intl,
        );
        this.currentUser = this.authenticationService.currentUserValue;
        this.insertImageSettings = {
            path: `${this.environmentService.backendURL}/api/file/preview/ticket/`,
            saveUrl: `${this.environmentService.backendURL}/api/upload/attachments/tickets?type=attachments&subfolder=tickets`,
        };

        this.path = {
            saveUrl: `${this.environmentService.backendURL}/api/upload/ticket?type=tickets`,
            removeUrl: '',
        };
    }

    get f(): {[key: string]: AbstractControl} {
        return this.ticketForm.controls;
    }

    @HostListener('window:beforeunload')
    canDeactivate(): Observable<boolean> | boolean {
        return !this.isDirty;
    }

    lessThanDate(fieldDate: string): ValidatorFn {
        return (control: AbstractControl): {lessThan: {value: string}} | null => {
            const fieldToDateCompare = control.parent?.get(fieldDate);
            const endDate = control.value;
            const startDate = fieldToDateCompare?.value;
            const isLessThan =
                Number(moment(startDate as MomentInput)
                    .valueOf()) >
                Number(moment(endDate as MomentInput)
                    .valueOf());

            return isLessThan ? {lessThan: {value: control.value}} : null;
        };
    }

    greaterThanDate(fieldDate: string): ValidatorFn {
        return (control: AbstractControl): {greaterThan: {value: string}} | null => {
            const fieldToDateCompare = control.parent?.get(fieldDate);
            const startDate = control.value;
            const endDate = fieldToDateCompare?.value;
            const isGreaterThan =
                Number(moment(startDate as MomentInput)
                    .valueOf()) >
                Number(moment(endDate as MomentInput)
                    .valueOf());

            return isGreaterThan ? {greaterThan: {value: control.value}} : null;
        };
    }

    ngOnInit(): void {
        this.dataService.userSource.pipe(untilDestroyed(this))
            .subscribe({
                next: (data: User[]) => {
                    this.usersSelect = [];
                    data.map((user: User) => {
                        if (user.authorized && !user.deleted_date) {
                            this.usersSelect = [
                                ...this.usersSelect,
                                {value: user.id, label: `${user.firstname} ${user.secondname}`},
                            ];
                        }
                    });
                },
                error: error => {
                    console.error(error);
                },
            });

        this.dataService.departmentSource.pipe(untilDestroyed(this))
            .subscribe({
                next: (data: Department[]) => {
                    this.departmentSelect = [];
                    data.map((department: Department) => {
                        this.departmentSelect = [
                            ...this.departmentSelect,
                            {
                                value: department.id,
                                label: `${department.company.name} - ${department.name}`,
                            },
                        ];
                    });
                },
                error: error => {
                    console.error(error);
                },
            });

        this.dataService.ticketCategoriesSource.pipe(untilDestroyed(this))
            .subscribe({
                next: (data: TicketCategory[]) => {
                    this.categorySelect = [];
                    data.map((category: TicketCategory) => {
                        this.categorySelect = [
                            ...this.categorySelect,
                            {
                                value: category.id,
                                label: `${category.name} | SLA: ${category.sla.duration} minut`,
                            },
                        ];
                    });
                },
                error: error => {
                    console.error(error);
                },
            });

        this.dataService.tagSource.pipe(untilDestroyed(this))
            .subscribe({
                next: (data: Tags[]) => {
                    this.tagsAutocomplete = [];
                    data.map((tag: Tags) => {
                        this.tagsAutocomplete = [
                            ...this.tagsAutocomplete,
                            {
                                id: tag.id,
                                name: tag.name,
                            },
                        ];
                    });
                },
                error: error => {
                    console.error(error);
                },
            });

        this.ticketForm = this.formBuilder.group({
            ticketSubject: [
                '',
                Validators.compose([
                    Validators.required,
                    Validators.minLength(2),
                    Validators.maxLength(100),
                ]),
            ],
            ticketMaintask: ['', Validators.required],
            tags: [[]],
            ticketStartDeadlineDate: [''],
            ticketDeadlineDate: [''],
            userAssignations: [null],
            departmentAssignations: [null],
            ticketCategory: [1, Validators.compose([Validators.required])],
            files: [null],
        });

        this.ticketForm
            .get('ticketDeadlineDate')
            ?.addValidators(this.lessThanDate('ticketStartDeadlineDate'));

        this.ticketForm
            .get('ticketStartDeadlineDate')
            ?.addValidators(this.greaterThanDate('ticketDeadlineDate'));

        this.ticketForm.valueChanges.pipe(untilDestroyed(this))
            .subscribe(() => {
                this.isDirty = this.ticketForm.dirty;
                this.showInputErrors();
            });

        this.ticketForm.get('ticketCategory')
            ?.valueChanges
            .subscribe(value => {
                if (value === 7) {
                    this.ticketForm.addControl('businessId',
                        new FormControl(
                            {
                                value: '',
                                disabled: false,
                            },
                            [
                                Validators.required,
                                Validators.pattern('^(\\d{8})$'),
                                Validators.maxLength(8),
                                Validators.minLength(8),
                            ],
                        ),
                    );
                    this.isCompany = true;
                    this.ref.markForCheck();
                } else {
                    this.isCompany = false;
                    this.ref.markForCheck();
                }
            });

        this.assignationUserList = this.ticketForm.get('userAssignations') as FormArray;
        this.assignationDepartmentList = this.ticketForm.get('departmentAssignations') as FormArray;

        this.dataService.setUsersDataSource();
    }

    ngOnChanges(): void {
        if (this.isVisible) {
            const queryParams: Params = {form: null};

            void this.router.navigate([], {
                relativeTo: this.route,
                queryParams,
                queryParamsHandling: 'merge',
            });

            if (this.isUpdate && this.ticket) {
                this.ticketForm.reset();
                this.formDialogObj.header = `Ticket #${this.ticket.id} - ${this.ticket.subject}`;
                this.fillTheForm();
                this.showInputErrors();
            }

            if (this.isCreate) {
                this.ticketForm.reset();
                this.formDialogObj.header = 'Nový ticket';
            }

            if (this.isCopy) {
                this.ticketForm.reset();
                this.formDialogObj.header = 'Nový ticket';
                this.fillTheForm();
                this.showInputErrors();
            }
        }
    }

    changeDialogState(value: boolean): void {
        this.ticketFormDialogState.emit(value);
    }

    onFilteringUsers: EmitType<FilteringEventArgs> = (e: FilteringEventArgs) => {
        if (e.text === '') {
            e.updateData(this.usersSelect);
        } else {
            let query: Query = new Query();

            query = e.text !== '' ? query.where('label', 'contains', e.text, true, true) : query;
            e.updateData(this.usersSelect, query);
        }
    };

    onFilteringDepartments: EmitType<FilteringEventArgs> = (e: FilteringEventArgs) => {
        if (e.text === '') {
            e.updateData(this.departmentSelect);
        } else {
            let query = new Query();

            query = e.text !== '' ? query.where('label', 'contains', e.text, true) : query;
            e.updateData(this.departmentSelect, query);
        }
    };

    onFilteringCategories: EmitType<FilteringEventArgs> = (e: FilteringEventArgs) => {
        if (e.text === '') {
            e.updateData(this.categorySelect);
        } else {
            let query = new Query();

            query = e.text !== '' ? query.where('label', 'contains', e.text, true) : query;
            e.updateData(this.categorySelect, query);
        }
    };

    fillTheForm(): void {
        const users: number[] = [];
        const departments: number[] = [];

        this.ticket?.users.map((user: User) => {
            users.push(user.id);
        });

        this.ticket?.departments.map((department: Department) => {
            departments.push(department.id);
        });
        this.ticketForm.controls.ticketSubject.patchValue(this.ticket?.subject);
        this.ticketForm.controls.ticketMaintask.patchValue(this.ticket?.maintask);
        this.ticketForm.controls.tags.patchValue(this.ticket?.tags);
        this.ticketForm.controls.ticketCategory.patchValue(this.ticket?.category_id);

        if (this.ticket?.category_id === 7) {
            this.ticketForm.controls.businessId.patchValue(this.ticket.business_id);
        }

        this.ticketForm.controls.ticketStartDeadlineDate.patchValue(this.ticket?.start_deadline);
        this.ticketForm.controls.ticketDeadlineDate.patchValue(this.ticket?.deadline);
        this.ticketForm.controls.userAssignations.patchValue(users);
        this.ticketForm.controls.departmentAssignations.patchValue(departments);
    }

    onSubmit(): void {
        this.submited = true;
        this.isDirty = false;
        this.ticketLoading = true;

        if (this.isCreate || this.isCopy) {
            this.createTicket();
        }

        if (this.isUpdate) {
            this.editTicket();
        }
    }

    createTicket(): void {
        if (this.ticketForm.invalid) {
            console.error('form is not valid!');

            return;
        }

        const data: TicketInput = {
            subject: this.f.ticketSubject.value,
            maintask: this.f.ticketMaintask.value,
            start_deadline: this.f.ticketStartDeadlineDate.value
                ? moment(this.f.ticketStartDeadlineDate.value as MomentInput)
                    .format(
                        'YYYY-MM-DD HH:mm:ss',
                    )
                : null,
            deadline: this.f.ticketDeadlineDate.value
                ? moment(this.f.ticketDeadlineDate.value as MomentInput)
                    .format(
                        'YYYY-MM-DD HH:mm:ss',
                    )
                : null,
            tags: this.f.tags.value,
            users: this.f.userAssignations.value,
            departments: this.f.departmentAssignations.value,
            business_id: this.f.ticketCategory.value === 7 && this.f.businessId.value ? parseInt(this.f.businessId.value, 10) : null,
            created_by: this.currentUser?.id,
            category_id: this.f.ticketCategory.value ? this.f.ticketCategory.value : 1,
            status_id: 1,
        };

        this.ticketService
            .addTicket(data)
            .pipe(
                untilDestroyed(this),
                map((response: BaseModel) => response.data[0] as Tickets),
            )
            .subscribe({
                next: (ticket: Tickets) => {
                    if (this.currentUser && this.uploadObj.getFilesData().length > 0) {
                        this.uploadObj.asyncSettings.saveUrl = `${this.environmentService.backendURL}/api/upload/ticket?type=tickets&id=${ticket.id}&created_by=${this.currentUser.id}`;

                        for (const file of this.uploadObj.getFilesData()) {
                            this.uploadObj.upload(file);
                        }

                        this.ref.markForCheck();
                    }

                    const body = `Delegováno uživateli: ${ticket.id} ${ticket.id}`;
                    const options = {progressBar: true, timeOut: 5000};

                    this.messages.showSuccess('Ticket úspěšně vytvořen', body, options);
                    this.formDialogObj.hide();
                    this.dataService.setTicketsDataSource();
                    this.dataService.setTagsDataSource();
                    this.isCreate = false;
                    this.ticketLoading = false;
                    this.ref.markForCheck();
                    this.formDialogObj.hide();
                    void this.router.navigate(['/tickets/detail', ticket.id]);
                },
                error: error => {
                    console.error(error);

                    const body = 'Zkuste to znovu později';
                    const options = {progressBar: true, timeOut: 5000};

                    this.messages.showError('Chyba při vytváření ticketu,', body, options);
                    this.ticketLoading = false;
                    this.ref.markForCheck();
                },
            });
    }

    editTicket(): void {
        if (this.ticketForm.invalid) {
            console.error('form is not valid!');

            return;
        }

        const data: TicketInput = {
            id: this.ticket?.id,
            subject: this.f.ticketSubject.value,
            maintask: this.f.ticketMaintask.value,
            start_deadline: this.f.ticketStartDeadlineDate.value
                ? moment(this.f.ticketStartDeadlineDate.value as MomentInput)
                    .format(
                        'YYYY-MM-DD HH:mm:ss',
                    )
                : null,
            deadline: this.f.ticketDeadlineDate.value
                ? moment(this.f.ticketDeadlineDate.value as MomentInput)
                    .format(
                        'YYYY-MM-DD HH:mm:ss',
                    )
                : null,
            tags: this.f.tags.value,
            users: this.f.userAssignations.value,
            departments: this.f.departmentAssignations.value,
            updated_by: this.currentUser?.id,
            status_id: this.ticket?.status_id,
            category_id: this.f.ticketCategory.value ? this.f.ticketCategory.value : 1,
            business_id: this.f.ticketCategory.value === 7 && this.f.businessId.value ? parseInt(this.f.businessId.value, 10) : null,
        };

        this.ticketService
            .updateTicket(data)
            ?.pipe(
                untilDestroyed(this),
                map((response: BaseModel) => response.data[0] as Tickets),
            )
            .subscribe({
                next: (ticket: Tickets) => {
                    if (this.currentUser && this.uploadObj.getFilesData().length > 0) {
                        this.uploadObj.asyncSettings.saveUrl = `${this.environmentService.backendURL}/api/upload/ticket?type=tickets&id=${ticket.id}&created_by=${this.currentUser.id}`;

                        for (const file of this.uploadObj.getFilesData()) {
                            this.uploadObj.upload(file);
                        }

                        this.ref.markForCheck();
                    }

                    const body = `Ticket #${ticket.id}`;
                    const options = {progressBar: true, timeOut: 5000};

                    this.messages.showSuccess('Ticket úspěšně upraven', body, options);
                    this.formDialogObj.hide();
                    this.dataService.setTagsDataSource();
                    this.dataService.setTicketsDataSource();
                    this.dataService.setTicketDetailSource(ticket.id);
                    this.isUpdate = false;
                    this.ticketLoading = false;
                    this.ref.markForCheck();
                    this.formDialogObj.hide();
                },
                error: error => {
                    console.error(error);

                    const body = 'Zkuste to znovu později';
                    const options = {
                        progressBar: true,
                        timeOut: 5000,
                        toastClass: 'red',
                    };

                    this.messages.showError('Chyba při úpravě ticketu,', body, options);
                    this.ticketLoading = false;
                    this.ref.markForCheck();
                },
            });
    }

    // RTE
    onCreateRte(): void {
        this.mainTaskObj?.refreshUI();
        this.mainTaskObj?.autoResize();
    }

    handleFullScreen(e: unknown): void {
        const sbCntEle: HTMLElement | null = document.querySelector('.sb-content.e-view');
        const sbHdrEle: HTMLElement | null = document.querySelector('.sb-header.e-view');
        const leftBar: HTMLElement | null = document.querySelector('#left-sidebar');

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        if (e.targetItem === 'Maximize') {
            if (Browser.isDevice && Browser.isIos && sbCntEle && sbHdrEle) {
                addClass([sbCntEle, sbHdrEle], ['hide-header']);
            }

            if (leftBar) {
                addClass([leftBar], ['e-close']);
                removeClass([leftBar], ['e-open']);
            }
        }
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        else if (e.targetItem === 'Minimize') {
            if (Browser.isDevice && Browser.isIos && sbCntEle && sbHdrEle) {
                removeClass([sbCntEle, sbHdrEle], ['hide-header']);
            }

            if (leftBar) {
                removeClass([leftBar], ['e-close']);

                if (!Browser.isDevice) {
                    addClass([leftBar], ['e-open']);
                }
            }
        }
    }

    mirrorConversion(e: unknown): void {
        if (this.mainTaskObj) {
            const id = `${this.mainTaskObj.getID()}mirror-view`;
            let mirrorView: HTMLElement | null = this.mainTaskObj.element.querySelector(`#${id}`);
            const charCount: HTMLElement | null =
                this.mainTaskObj.element.querySelector('.e-rte-character-count');

            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            if (e.targetItem === 'Preview' && mirrorView && charCount) {
                this.textArea.style.display = 'block';
                mirrorView.style.display = 'none';
                // eslint-disable-next-line max-len
                // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-member-access
                charCount.style.display = 'block';
            } else {
                if (!mirrorView && this.textArea.parentNode) {
                    mirrorView = createElement('div', {className: 'e-content'});
                    mirrorView.id = id;
                    this.textArea.parentNode.appendChild(mirrorView);
                } else if (mirrorView) {
                    mirrorView.innerHTML = '';
                }

                if (mirrorView) {
                    this.textArea.style.display = 'none';
                    mirrorView.style.display = 'block';
                }

                if (charCount) {
                    charCount.style.display = 'none';
                }
            }
        }
    }

    actionCompleteHandler(e: unknown): void {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        if (e.targetItem && (e.targetItem === 'SourceCode' || e.targetItem === 'Preview')) {
            (this.mainTaskObj?.sourceCodeModule.getPanel() as HTMLTextAreaElement).style.display =
                'none';
            this.mirrorConversion(e);
        } else {
            setTimeout(() => {
                this.mainTaskObj?.toolbarModule.refreshToolbarOverflow();
            }, 400);
        }
    }

    // Files
    onFileSelected(args: SelectedEventArgs): void {
        // Filter the 5 files only to showcase
        args.filesData.splice(5);

        const filesData: FileInfo[] = this.uploadObj.getFilesData();
        const allFiles: FileInfo[] = filesData.concat(args.filesData);

        if (allFiles.length > 5) {
            // eslint-disable-next-line @typescript-eslint/prefer-for-of
            for (let i = 0; i < allFiles.length; i++) {
                if (allFiles.length > 5) {
                    allFiles.shift();
                }
            }

            args.filesData = allFiles;
            // set the modified custom data
            args.modifiedFilesData = args.filesData;
        }

        let existingFiles: FileInfo[] = this.uploadObj.getFilesData();

        for (let i = 0; i < args.filesData.length; i++) {
            // eslint-disable-next-line @typescript-eslint/prefer-for-of
            for (let j = 0; j < existingFiles.length; j++) {
                if (!isNullOrUndefined(args.filesData[i])) {
                    // eslint-disable-next-line eqeqeq
                    if (existingFiles[j].name == args.filesData[i].name) {
                        args.filesData.splice(i, 1);
                    }
                }
            }
        }

        existingFiles = existingFiles.concat(args.filesData);
        args.modifiedFilesData = existingFiles;
        args.isModified = true;
    }

    onFileRemove(args: RemovingEventArgs): void {
        args.postRawFile = false;
    }

    removeTicketFile(): void {
        this.ticketLoading = true;

        if (this.deleteFileID) {
            this.removeFile = this.ticket?.files.find(x => x.id === this.deleteFileID);

            if (typeof this.removeFile !== 'undefined') {
                this.removeFile.deleted_by = this.currentUser?.id;
                this.ticketService
                    .deleteFile(this.removeFile)
                    ?.pipe(untilDestroyed(this))
                    .subscribe({
                        next: (data: TicketFile) => {
                            const body = `Soubor #${data.id}`;
                            const options = {progressBar: true, timeOut: 5000};

                            this.messages.showSuccess('Soubor úspěšně smazán', body, options);
                            this.dataService.setTicketsDataSource();
                            this.dataService.setTicketDetailSource(data.ticket_id);

                            this.ticketLoading = false;
                            this.deleteFileDialogObj.hide();
                            this.ref.markForCheck();
                        },
                        error: error => {
                            alert('Chyba během mazání souboru.');
                            this.ticketLoading = false;
                            this.ref.markForCheck();
                            console.error(error);
                        },
                    });
            }
        }
    }

    showInputErrors(): void {
        this.isChecked = true;
        /* console.log('VALID: ' + this.ticketForm.valid);
Object.keys(this.ticketForm.controls).forEach(key => {
const controlErrors: ValidationErrors = this.ticketForm.get(key).errors;
if (controlErrors != null) {
Object.keys(controlErrors).forEach(keyError => {
console.log('Key control: ' + key + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError]);
});
}
});*/
        this.ticketForm
            .get('ticketDeadlineDate')
            ?.updateValueAndValidity({onlySelf: true, emitEvent: false});

        this.ticketForm
            .get('ticketStartDeadlineDate')
            ?.updateValueAndValidity({onlySelf: true, emitEvent: false});
        this.ticketForm.markAllAsTouched();
        this.ref.markForCheck();
    }
}
