import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnInit,
    ViewChild,
} from '@angular/core';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {AuthenticationService} from '@src/app/_services/authentication.service';
import {DataNetbaseService} from '@src/app/_services/data-netbase.service';
import {TabComponent} from '@syncfusion/ej2-angular-navigations';
import {SelectEventArgs} from '@syncfusion/ej2-navigations';
import {User} from '@src/app/_models/user/user';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {DataService} from '@src/app/_services/data.service';

@UntilDestroy()
@Component({
    selector: 'app-service-address',
    templateUrl: './service-address.component.html',
    styleUrls: ['./service-address.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ServiceAddressComponent implements OnInit {
    headerText = [
        {text: 'Připojené RUIAN'},
        {text: 'Připojené parcely'},
        {text: 'Vyloučené adresy z tarifů'},
        {text: 'Rizikové adresy'},
        {text: 'Výpadky služeb'},
    ];

    currentUser: User | null;

    @ViewChild('adressTab') adressTabObj: TabComponent;

    constructor(
        private readonly router: Router,
        private readonly ref: ChangeDetectorRef,
        private readonly route: ActivatedRoute,
        private readonly dataNetbaseService: DataNetbaseService,
        private readonly dataService: DataService,
        private readonly authenticationService: AuthenticationService,
    ) {
        this.currentUser = this.authenticationService.currentUserValue;
    }

    ngOnInit(): void {
        this.dataService.setCompanyDataSource();
        this.dataService.setProjectsDataSource();
        this.dataNetbaseService.setTechnologyTypesDataSource();
        this.dataNetbaseService.setDiscountsDataSource();
        this.dataNetbaseService.setDealTypesDataSource();
        this.dataNetbaseService.setUnitTypesDataSource();
        this.dataNetbaseService.setSpaceTypesDataSource();
        this.dataNetbaseService.setSpeedTypesDataSource();
        this.dataNetbaseService.setNetPackagesDataSource();
        this.dataNetbaseService.setTvServicesDataSource();
        this.dataNetbaseService.setTransferTypesDataSource();
        this.dataNetbaseService.setRuianAddressDataSource();
        this.dataNetbaseService.setUntrustedAddressDataSource();
        this.dataNetbaseService.setExcludedAddressDataSource();
    }

    onCreate(): void {
        // Nastavení GUI podle URL parametrů
        this.route.queryParamMap.pipe(untilDestroyed(this))
            .subscribe(queryParams => {
                if (queryParams.get('view') === 'ruian') {
                    this.adressTabObj.selectedItem = 0;
                } else if (queryParams.get('view') === 'field') {
                    this.adressTabObj.selectedItem = 1;
                } else if (queryParams.get('view') === 'excluded') {
                    this.adressTabObj.selectedItem = 2;
                } else if (queryParams.get('view') === 'untrusted') {
                    this.adressTabObj.selectedItem = 3;
                } else if (queryParams.get('view') === 'outages') {
                    this.adressTabObj.selectedItem = 4;
                } else {
                    this.adressTabObj.selectedItem = 0;
                }
            });
    }

    preventSwipe(e: SelectEventArgs): void {
        if (e.isSwiped) {
            e.cancel = true;
        }
    }

    select(e: SelectEventArgs): void {
        let queryParams: Params = {view: null};

        if (e.selectedItem.textContent === 'Připojené RUIAN') {
            queryParams = {
                view: 'ruian',
                form: null,
            };
        }

        if (e.selectedItem.textContent === 'Připojené parcely') {
            queryParams = {
                view: 'field',
            };
        }

        if (e.selectedItem.textContent === 'Vyloučené adresy z tarifů') {
            queryParams = {
                view: 'excluded',
                form: null,
            };
        }

        if (e.selectedItem.textContent === 'Rizikové adresy') {
            queryParams = {
                view: 'untrusted',
                form: null,
            };
        }

        if (e.selectedItem.textContent === 'Výpadky služeb') {
            queryParams = {
                view: 'outages',
                form: null,
            };
        }

        void this.router.navigate([], {
            relativeTo: this.route,
            queryParams,
            queryParamsHandling: 'merge',
        });
    }
}
