import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    HostListener,
    Input,
    OnChanges,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {DataService} from '@src/app/_services/data.service';
import {SettingsService} from '@src/app/features/settings/settings.service';
import {MessageService} from '@src/app/_services/message.service';
import {AuthenticationService} from '@src/app/_services/authentication.service';
import {DataNetbaseService} from '@src/app/_services/data-netbase.service';
import {ServicesService, TvPackageInput} from '@src/app/features/services/services.service';
import {TvPackages} from '@src/app/_models/services/tv-packages';
import {TvChannels} from '@src/app/_models/services/tv-channels';
import {TvServices} from '@src/app/_models/services/tv-services';
import {User} from '@src/app/_models/user/user';
import {EmitType} from '@syncfusion/ej2-base';
import {FilteringEventArgs, MultiSelectComponent} from '@syncfusion/ej2-angular-dropdowns';
import {Query} from '@syncfusion/ej2-data';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {DialogComponent} from '@syncfusion/ej2-angular-popups';
import {Observable} from 'rxjs';
import {ComponentCanDeactivate} from '@src/app/_guards/changes.guard';
import {ActivatedRoute, Params, Router} from '@angular/router';

@UntilDestroy()
@Component({
    selector: 'app-tv-package-form',
    templateUrl: './tv-package-form.component.html',
    styleUrls: ['./tv-package-form.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TvPackageFormComponent implements OnInit, OnChanges, ComponentCanDeactivate {
    // Dropdowms
    height = '240px';

    fields: object = {text: 'label', value: 'value'};

    serviceSelect: Array<{value: boolean | number | string; label: string}> = [];

    channelSelect: Array<{value: boolean | number | string; label: string}> = [];

    // Forms
    tvPackageForm: FormGroup;

    isDirty = false;

    isChecked = false;

    submited = false;

    // Variables
    currentUser: User | null;

    services: TvServices[] = [];

    // Loaders
    loadingTvPackages = false;

    @Input() tvPackage: TvPackages | null = null;

    @Input() isCreate = false;

    @Input() isUpdate = false;

    @Input() isVisible = false;

    @Input() isCopy = false;

    @Output() readonly tvServiceFormDialogState = new EventEmitter<boolean>();

    // Dialogs
    @ViewChild('formDialog') formDialogObj: DialogComponent;

    // Dropdowms
    @ViewChild('services') placesObj: MultiSelectComponent;

    @ViewChild('channels') bonusesObj: MultiSelectComponent;

    constructor(
        private readonly route: ActivatedRoute,
        private readonly router: Router,
        private readonly formBuilder: FormBuilder,
        private readonly ref: ChangeDetectorRef,
        private readonly dataService: DataService,
        private readonly dataNetbaseService: DataNetbaseService,
        private readonly settingsService: SettingsService,
        private readonly servicesService: ServicesService,
        private readonly messages: MessageService,
        private readonly authenticationService: AuthenticationService,
    ) {
        this.currentUser = this.authenticationService.currentUserValue;
    }

    get f(): {[key: string]: AbstractControl} {
        return this.tvPackageForm.controls;
    }

    @HostListener('window:beforeunload')
    canDeactivate(): Observable<boolean> | boolean {
        return !this.isDirty;
    }

    ngOnInit(): void {
        this.dataNetbaseService.tvServicesSource.pipe(untilDestroyed(this))
            .subscribe(
                (services: TvServices[]) => {
                    this.serviceSelect = [];
                    this.services = services;
                    services.map((service: TvServices) => {
                        if (!service.deleted_date) {
                            this.serviceSelect = [
                                ...this.serviceSelect,
                                {
                                    value: service.id,
                                    label: service.name,
                                },
                            ];
                        }
                    });
                    this.ref.markForCheck();
                },
                error => {
                    console.error(error);
                },
            );

        this.dataNetbaseService.tvChannelsSource.pipe(untilDestroyed(this))
            .subscribe(
                (channels: TvChannels[]) => {
                    this.channelSelect = [];
                    channels.map((channnel: TvChannels) => {
                        if (!channnel.deleted_date) {
                            this.channelSelect = [
                                ...this.channelSelect,
                                {
                                    value: channnel.id,
                                    label: channnel.hd_resolution
                                        ? `${channnel.name} HD`
                                        : channnel.name,
                                },
                            ];
                        }
                    });
                    this.ref.markForCheck();
                },
                error => {
                    console.error(error);
                },
            );

        this.tvPackageForm = this.formBuilder.group({
            services: [null, Validators.compose([Validators.required])],
            name: [
                '',
                Validators.compose([
                    Validators.required,
                    Validators.maxLength(100),
                    Validators.minLength(2),
                ]),
            ],
            price: [
                0,
                Validators.compose([
                    Validators.required,
                    Validators.max(100000),
                    Validators.min(1),
                ]),
            ],
            extension: [false, Validators.required],
            recommended: [false, Validators.required],
            sale_order: [
                0,
                Validators.compose([Validators.required, Validators.max(20), Validators.min(0)]),
            ],
            channels: [null, Validators.compose([Validators.required])],
        });

        this.tvPackageForm.valueChanges.pipe(untilDestroyed(this))
            .subscribe(() => {
                this.isDirty = this.tvPackageForm.dirty;
                this.showInputErrors();
            });

        this.dataNetbaseService.setTvServicesDataSource();
        this.dataNetbaseService.setTvChannelsDataSource();
    }

    ngOnChanges(): void {
        if (this.isVisible) {
            const queryParams: Params = {form: null};

            void this.router.navigate([], {
                relativeTo: this.route,
                queryParams,
                queryParamsHandling: 'merge',
            });

            if (this.isUpdate && this.tvPackage) {
                this.tvPackageForm.reset();
                this.formDialogObj.header = `TV balíček #${this.tvPackage.id} - ${this.tvPackage.name}`;
                this.fillTheForm();
                this.showInputErrors();
            }

            if (this.isCreate) {
                this.tvPackageForm.reset();
                this.formDialogObj.header = 'Nový TV balíček';
            }

            if (this.isCopy) {
                this.tvPackageForm.reset();
                this.formDialogObj.header = 'Nový TV balíček';
                this.fillTheForm();
                this.showInputErrors();
            }

            this.tvPackageForm.controls.recommended.patchValue(false);
            this.tvPackageForm.controls.extension.patchValue(false);
        }
    }

    changeDialogState(value: boolean): void {
        this.tvServiceFormDialogState.emit(value);
    }

    onFilteringChannels: EmitType<FilteringEventArgs> = (e: FilteringEventArgs) => {
        if (e.text === '') {
            e.updateData(this.channelSelect);
        } else {
            let query: Query = new Query();

            query = e.text !== '' ? query.where('label', 'contains', e.text, true, true) : query;
            e.updateData(this.channelSelect, query);
        }
    };

    onFilteringServices: EmitType<FilteringEventArgs> = (e: FilteringEventArgs) => {
        if (e.text === '') {
            e.updateData(this.serviceSelect);
        } else {
            let query: Query = new Query();

            query = e.text !== '' ? query.where('label', 'contains', e.text, true, true) : query;
            e.updateData(this.serviceSelect, query);
        }
    };

    fillTheForm(): void {
        const tempChannels: number[] = [];
        const tempServices: number[] = [];

        this.tvPackageForm.controls.name.patchValue(this.tvPackage?.name);
        this.tvPackageForm.controls.price.patchValue(this.tvPackage?.price);
        this.tvPackageForm.controls.extension.patchValue(this.tvPackage?.extension);
        this.tvPackageForm.controls.recommended.patchValue(this.tvPackage?.recommended);
        this.tvPackageForm.controls.sale_order.patchValue(this.tvPackage?.sale_order);

        this.tvPackage?.tv_channels.map((channel: TvChannels) => {
            tempChannels.push(channel.id);
        });

        this.tvPackage?.services.map((service: TvServices) => {
            tempServices.push(service.id);
        });

        this.tvPackageForm
            .get('channels')
            ?.setValue(tempChannels, {onlySelf: true, emitEvent: false});

        this.tvPackageForm
            .get('services')
            ?.setValue(tempServices, {onlySelf: true, emitEvent: false});
    }

    onSubmit(): void {
        this.submited = true;
        this.isDirty = false;
        this.loadingTvPackages = true;

        if (this.isCreate || this.isCopy) {
            this.createTvPackage();
        }

        if (this.isUpdate) {
            this.editTvPackage();
        }
    }

    createTvPackage(): void {
        this.submited = true;

        if (this.tvPackageForm.invalid) {
            console.error('form is not valid!');

            return;
        }

        this.loadingTvPackages = true;

        const tvPackageData = {
            name: this.f.name.value,
            price: this.f.price.value,
            services: this.f.services.value,
            recommended: this.f.recommended.value,
            extension: this.f.extension.value,
            sale_order: this.f.sale_order.value,
            tv_channels: this.f.channels.value,
            created_by: this.currentUser?.id,
        };

        this.servicesService
            .addTvPackage(tvPackageData)
            .pipe(untilDestroyed(this))
            .subscribe(
                (data: TvPackages) => {
                    const body = `${data.name} přidán do nabídky`;
                    const options = {progressBar: true, timeOut: 5000};

                    this.messages.showSuccess('Tarif televize úspěšně vytvořen', body, options);
                    this.dataNetbaseService.setTvPackagesDataSource();
                    this.dataNetbaseService.setTvServicesDataSource();
                    this.isCreate = false;
                    this.loadingTvPackages = false;
                    this.ref.markForCheck();
                    this.formDialogObj.hide();
                },
                error => {
                    console.error(error);

                    const body = 'Zkuste to znovu později';
                    const options = {
                        progressBar: true,
                        timeOut: 5000,
                        toastClass: 'red',
                    };

                    this.messages.showError(
                        'Chyba při vytváření televizního tarifu',
                        body,
                        options,
                    );
                    this.loadingTvPackages = false;
                    this.ref.markForCheck();
                },
            );
    }

    editTvPackage(): void {
        this.submited = true;

        if (this.tvPackageForm.invalid) {
            console.error('form is not valid!');

            return;
        }

        this.loadingTvPackages = true;

        const tvPackageData: TvPackageInput = {
            id: this.tvPackage?.id,
            name: this.f.name.value,
            price: this.f.price.value,
            services: this.f.services.value,
            recommended: this.f.recommended.value,
            extension: this.f.extension.value,
            sale_order: this.f.sale_order.value,
            tv_channels: this.f.channels.value,
            updated_by: this.currentUser?.id,
        };

        this.servicesService
            .updateTvPackage(tvPackageData)
            ?.pipe(untilDestroyed(this))
            .subscribe(
                (data: TvPackages) => {
                    this.tvPackage = data;

                    const body = `${data.name} upraven`;
                    const options = {progressBar: true, timeOut: 5000};

                    this.messages.showSuccess('Tarif televize úspěšně upraven', body, options);
                    this.dataNetbaseService.setTvPackagesDataSource();
                    this.dataNetbaseService.setTvServicesDataSource();
                    this.isUpdate = false;
                    this.loadingTvPackages = false;
                    this.ref.markForCheck();
                    this.formDialogObj.hide();
                },
                error => {
                    console.error(error);

                    const body = 'Zkuste to znovu později';
                    const options = {
                        progressBar: true,
                        timeOut: 5000,
                        toastClass: 'red',
                    };

                    this.messages.showError('Chyba při úpravě televizního tarifu', body, options);
                    this.loadingTvPackages = false;
                    this.ref.markForCheck();
                },
            );
    }

    showInputErrors(): void {
        this.isChecked = true;
        /* console.log('VALID: ' + this.ticketForm.valid);
        Object.keys(this.ticketForm.controls).forEach(key => {
            const controlErrors: ValidationErrors = this.ticketForm.get(key).errors;
            if (controlErrors != null) {
                Object.keys(controlErrors).forEach(keyError => {
                    console.log('Key control: ' + key + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError]);
                });
            }
        });*/
        this.tvPackageForm.markAllAsTouched();
        this.ref.markForCheck();
    }
}
