import {Pipe, PipeTransform} from '@angular/core';
import {isInteger, isNumberFinite, isPositive, toDecimal} from '../utils/utils';

export type ByteUnit = 'B' | 'GB' | 'KB' | 'kB' | 'MB' | 'TB';

@Pipe({
    name: 'humanizeBytes',
})
export class HumanizeBytesPipe implements PipeTransform {
    static formats: {[key: string]: {max: number; prev?: ByteUnit}} = {
        B: {max: 1024},
        kB: {max: Math.pow(1024, 2), prev: 'B'},
        KB: {max: Math.pow(1024, 2), prev: 'B'}, // Backward compatible
        MB: {max: Math.pow(1024, 3), prev: 'kB'},
        GB: {max: Math.pow(1024, 4), prev: 'MB'},
        TB: {max: Number.MAX_SAFE_INTEGER, prev: 'GB'},
    };

    static formatResult(result: number, unit: string): string {
        return `${result} ${unit}`;
    }

    static calculateResult(format: {max: number; prev?: ByteUnit}, bytes: number): number {
        const prev = format.prev ? HumanizeBytesPipe.formats[format.prev] : undefined;

        return prev ? bytes / prev.max : bytes;
    }

    transform(
        input: number | string,
        decimal = 0,
        from: ByteUnit = 'B',
        to?: ByteUnit,
    ): number | string | undefined {
        if (
            !(
                isNumberFinite(input) &&
                isNumberFinite(decimal) &&
                isInteger(decimal) &&
                isPositive(decimal)
            )
        ) {
            return input;
        }

        let bytes: number | string = input;
        let unit: ByteUnit | undefined = from;

        while (unit && unit !== 'B') {
            bytes *= 1024;
            unit = HumanizeBytesPipe.formats[unit].prev;
        }

        if (to) {
            const format = HumanizeBytesPipe.formats[to];

            const result = toDecimal(HumanizeBytesPipe.calculateResult(format, bytes), decimal);

            return HumanizeBytesPipe.formatResult(result, to);
        }

        for (const key in HumanizeBytesPipe.formats) {
            // eslint-disable-next-line no-prototype-builtins
            if (HumanizeBytesPipe.formats.hasOwnProperty(key)) {
                const format = HumanizeBytesPipe.formats[key];

                if (bytes < format.max) {
                    const result = toDecimal(
                        HumanizeBytesPipe.calculateResult(format, bytes),
                        decimal,
                    );

                    return HumanizeBytesPipe.formatResult(result, key);
                }
            }
        }
    }
}
