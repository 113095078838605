<table class="p-1 w-100">
  <tr>
    <td colspan="2">
      <form [formGroup]="addressForm">
        <label
          [ngClass]="
                        addressFormCtrl.address.valid &&
                        (addressFormCtrl.address.dirty ||
                            addressFormCtrl.address.touched)
                            ? 'text-success'
                            : addressFormCtrl.address.invalid &&
                              (addressFormCtrl.address.dirty ||
                                  addressFormCtrl.address.touched)
                            ? 'text-danger'
                            : 'text-muted'
                    "
          for="address"
        >* Adresa:</label
        >
        <ejs-textbox
          [ngClass]="
                        addressFormCtrl.address.touched &&
                        addressFormCtrl.address.invalid
                            ? 'e-error'
                            : addressFormCtrl.address.touched &&
                              addressFormCtrl.address.valid
                            ? 'e-success'
                            : ''
                    "
          autocomplete="smartform-auto-address"
          class="smartform-whole-address smartform-instance-create"
          floatLabelType="Auto"
          formControlName="address"
          id="address"
        ></ejs-textbox>
        <small
          *ngIf="
                        addressFormCtrl.address.touched &&
                        addressFormCtrl.address.errors &&
                        addressFormCtrl.address.errors.required
                    "
          class="text-danger"
        >Vyberte adresu pomocí našeptávače</small
        >
      </form>
      <div class="map">
        <google-map>
          <map-marker
            #markerElem
            (mapClick)="openInfo(markerElem, marker.info)"
            *ngFor="let marker of markers"
            [label]="marker.label"
            [options]="marker.options"
            [position]="marker.position"
            [title]="marker.title"
          >
          </map-marker>

          <map-info-window>{{ infoContent }}</map-info-window>
        </google-map>
        <button (click)="zoomIn()">Zoom in</button>
        <button (click)="zoomOut()">Zoom out</button>
        <button (click)="logCenter()">Log center</button>
        <button (click)="addMarker()">Add marker</button>
      </div>
    </td>
  </tr>
  <ng-container *ngIf="addressLoading">
    <tr>
      <td class="text-center" colspan="2">
        <app-loader></app-loader>
      </td>
    </tr>
  </ng-container>
  <ng-container *ngIf="!addressLoading && !addressForm.valid">
    <tr>
      <td class="text-center text-danger" colspan="2">
        adresa není správně vyplněna
      </td>
    </tr>
  </ng-container>
  <ng-container
    *ngIf="
            !addressLoading &&
            addressForm.valid &&
            (matchedConnectedRuianAddress ||
                matchedConnectedFieldAddress ||
                matchedWirelessAddress)
        "
  >
    <tr>
      <th colspan="2">
        <h6>Technická specifikace:</h6>
      </th>
    </tr>
    <tr>
      <td class="text-center" colspan="2">
        <ng-container
          *ngIf="matchedConnectedRuianAddress"
          class="text-center"
        >
          <div class="chip" title="Technologie přenosu">
            <i
              [ngClass]="
                                matchedConnectedRuianAddress.transfer
                                    .connection_method === 'optical cable'
                                    ? 'fa-disc-drive'
                                    : matchedConnectedRuianAddress.transfer
                                          .connection_method === 'metalic cable'
                                    ? 'fa-ethernet'
                                    : matchedConnectedRuianAddress.transfer
                                          .connection_method === 'radio'
                                    ? 'fa-broadcast-tower'
                                    : 'fa-satellite-dish'
                            "
              aria-hidden="true"
              class="fa-light fa-lg mr-2"
            ></i>
            {{
              matchedConnectedRuianAddress.transfer
                .connection_method
            }}
          </div>
          <div class="chip" title="Technologie připojení / zařízení">
            <i
              aria-hidden="true"
              class="fa-light fa-lg fa-sitemap mr-2"
            ></i>
            {{ matchedConnectedRuianAddress.technology.name }}
          </div>
          <div class="chip" title="Kód adresního místa">
            <i
              aria-hidden="true"
              class="fa-light fa-lg fa-map-marked-alt mr-2"
            ></i>
            {{ matchedConnectedRuianAddress.ruian }}
          </div>
          <div class="chip" title="Budova s číslem popisným">
            <i
              aria-hidden="true"
              class="fa-light fa-lg fa-home mr-2"
            ></i>
            Adresa z katastru s ČP
          </div>
        </ng-container>
        <ng-container
          *ngIf="
                        !matchedConnectedRuianAddress &&
                        matchedConnectedFieldAddress
                    "
          class="text-center"
        >
          <div class="chip" title="Technologie přenosu">
            <i
              [ngClass]="
                                matchedConnectedFieldAddress.transfer
                                    .connection_method === 'optical cable'
                                    ? 'fa-disc-drive'
                                    : matchedConnectedFieldAddress.transfer
                                          .connection_method === 'metalic cable'
                                    ? 'fa-ethernet'
                                    : matchedConnectedFieldAddress.transfer
                                          .connection_method === 'radio'
                                    ? 'fa-broadcast-tower'
                                    : 'fa-satellite-dish'
                            "
              aria-hidden="true"
              class="fa-light fa-lg mr-2"
            ></i>
            {{
              matchedConnectedFieldAddress.transfer
                .connection_method
            }}
          </div>
          <div class="chip" title="Technologie připojení / zařízení">
            <i
              aria-hidden="true"
              class="fa-light fa-lg fa-sitemap mr-2"
            ></i>
            {{ matchedConnectedFieldAddress.technology.name }}
          </div>
          <div class="chip" title="Kód parcely">
            <i
              aria-hidden="true"
              class="fa-light fa-lg fa-map-marked-alt mr-2"
            ></i>
            {{ matchedConnectedFieldAddress.field_number }}
          </div>
          <div class="chip" title="Parcela bez čísla popisného">
            <i
              aria-hidden="true"
              class="fa-light fa-lg fa-construction mr-2"
            ></i>
            Parcela bez ČP
          </div>
        </ng-container>
        <ng-container
          *ngIf="
                        !matchedConnectedRuianAddress &&
                        !matchedConnectedFieldAddress &&
                        matchedWirelessAddress
                    "
          class="text-center"
        >
          <div class="chip" title="Technologie přenosu">
            <i
              [ngClass]="
                                matchedWirelessAddress.connection_method ===
                                'optical cable'
                                    ? 'fa-disc-drive'
                                    : matchedWirelessAddress.connection_method ===
                                      'metalic cable'
                                    ? 'fa-ethernet'
                                    : matchedWirelessAddress.connection_method ===
                                      'radio'
                                    ? 'fa-broadcast-tower'
                                    : 'fa-satellite-dish'
                            "
              aria-hidden="true"
              class="fa-light fa-lg mr-2"
            ></i>
            {{ matchedWirelessAddress.connection_method }}
          </div>
          <div class="chip" title="Technologie připojení / zařízení">
            <i
              aria-hidden="true"
              class="fa-light fa-lg fa-sitemap mr-2"
            ></i>
            {{ matchedWirelessAddress.technology }}
          </div>
          <div
            *ngIf="ruianCode"
            class="chip"
            title="Kód adresního místa"
          >
            <i
              aria-hidden="true"
              class="fa-light fa-lg fa-map-marked-alt mr-2"
            ></i>
            {{ matchedWirelessAddress.ruian }}
          </div>
          <div
            *ngIf="ruianCode"
            class="chip"
            title="Budova s číslem popisným"
          >
            <i
              aria-hidden="true"
              class="fa-light fa-lg fa-home mr-2"
            ></i>
            Adresa z katastru s ČP
          </div>
          <div
            *ngIf="!ruianCode"
            class="chip"
            title="Parcela bez čísla popisného"
          >
            <i
              aria-hidden="true"
              class="fa-light fa-lg fa-construction mr-2"
            ></i>
            Parcela bez ČP
          </div>
        </ng-container>
      </td>
    </tr>
    <tr>
      <td>
        <div class="e-card text-center">
          <div
            [ngClass]="
                            addressFormCtrl.address.touched &&
                            addressForm.invalid
                                ? 'e-error'
                                : (addressFormCtrl.address.touched &&
                                      addressForm.valid &&
                                      matchedOutages &&
                                      matchedOutages.length === 0) ||
                                  !matchedOutages
                                ? 'e-success'
                                : 'e-warning'
                        "
            class="e-card-header"
          >
            Ohlášené výpadky
            <span
              *ngIf="matchedOutages && matchedOutages.length > 0"
              class="e-badge e-badge-warning ml-2"
            >{{ matchedOutages.length }}</span
            >
            <span
              *ngIf="
                                (matchedOutages &&
                                    matchedOutages.length === 0) ||
                                !matchedOutages
                            "
              class="e-badge e-badge-success ml-2"
            >0</span
            >
          </div>
          <div
            *ngIf="
                            matchedOutages &&
                            matchedOutages.length > 0 &&
                            addressForm.valid
                        "
            class="e-card-content"
          >
            <i
              aria-hidden="true"
              class="fa-light fa-lg fa-exclamation-triangle mr-2"
            ></i>
            Tato adresa je v seznamu
            <strong>plánovaných výpadků</strong>.
            <div class="e-card-separator"></div>
            <p
              *ngFor="let outage of matchedOutages"
              style="font-size: 11px !important"
            >
              {{ outage.address }}: (termín:
              {{
                outage.start_at | dateFormat: 'DD.MM.YYYY HH:mm'
              }}
              -
              {{
                outage.end_at | dateFormat: 'DD.MM.YYYY HH:mm'
              }}) -
              {{ outage.reason }}
            </p>
          </div>
          <strong
            *ngIf="
                            ((matchedOutages && matchedOutages.length === 0) ||
                                !matchedOutages) &&
                            addressForm.valid
                        "
            class="text-success"
          >Na této lokaci nejsou hlášeny žádné výpadky
            služeb</strong
          >
        </div>
      </td>
      <td>
        <div class="e-card text-center">
          <div
            [ngClass]="
                            addressFormCtrl.address.touched &&
                            addressForm.invalid
                                ? 'e-error'
                                : addressFormCtrl.address.touched &&
                                  addressForm.valid &&
                                  !matchedUntrustedAddresses
                                ? 'e-success'
                                : 'e-warning'
                        "
            class="e-card-header"
          >
            Známá rizika
            <span
              *ngIf="matchedUntrustedAddresses"
              class="e-badge e-badge-warning ml-2"
            >SHODA!</span
            >
            <span
              *ngIf="!matchedUntrustedAddresses"
              class="e-badge e-badge-success ml-2"
            >V POŘÁDKU</span
            >
          </div>
          <div
            *ngIf="matchedUntrustedAddresses && addressForm.valid"
            class="e-card-content"
          >
            <i
              aria-hidden="true"
              class="fa-light fa-lg fa-exclamation-triangle mr-2"
            ></i>
            <span class="text-warning"
            >Tato adresa je v seznamu
                            <strong>rizikových adres</strong>. Postupujte
                            obezřetněji při vytváření objedánky v rámci solvenci
                            klienta.</span
            >
            <div class="e-card-separator"></div>
            <p style="font-size: 11px !important">
              {{ matchedUntrustedAddresses.descriptive }}
            </p>
          </div>
          <strong
            *ngIf="!matchedUntrustedAddresses && addressForm.valid"
            class="text-success"
          >Na této lokaci nejsou známá žádná rizika</strong
          >
        </div>
      </td>
    </tr>
    <tr>
      <td colspan="2">
        <ejs-accordion>
          <e-accordionitems>
            <e-accordionitem>
              <ng-template #header>
                <div>Technická poznámka</div>
              </ng-template>
              <ng-template #content>
                <p>{{
                    matchedConnectedRuianAddress.descriptive
                  }}</p>
              </ng-template>
            </e-accordionitem>
            <e-accordionitem>
              <ng-template #header>
                <div>Obchodní poznámka</div>
              </ng-template>
              <ng-template #content>
                <p>{{
                    matchedConnectedRuianAddress.business_note
                  }}</p>
              </ng-template>
            </e-accordionitem>
            <e-accordionitem>
              <ng-template #header>
                <div>Marketingová poznámka</div>
              </ng-template>
              <ng-template #content>
                <p>{{
                    matchedConnectedRuianAddress.marketingnote
                  }}</p>
              </ng-template>
            </e-accordionitem>
          </e-accordionitems>
        </ejs-accordion>
      </td>
    </tr>
    <tr>
      <th colspan="2">
        <h6>Klienti a služby:</h6>
      </th>
    </tr>
    <tr style="vertical-align: top">
      <td>
        <ejs-grid
          #gridClients
          (created)="createdClients()"
          (rowDeselected)="rowSelected()"
          (rowSelected)="rowSelected()"
          [allowPaging]="false"
          [dataSource]="clientElements"
          [pageSettings]="pageSettings"
          [selectionSettings]="selectionSettings"
          [sortSettings]="initialClientSort"
          [toolbar]="toolbar"
          allowSorting="true"
        >
          <e-columns>
            <e-column
              field="name"
              headerText="Jméno"
              width="150"
            ></e-column>
            <e-column
              [format]="'N'"
              field="client_number"
              headerText="Zákaznické číslo"
              textAlign="Right"
              width="150"
            ></e-column>
            <e-column
              field="isp_link"
              headerText="ISP Link"
              textAlign="Right"
              width="150"
            >
              <ng-template #template let-clientElements>
                <a
                  href="{{ clientElements.isp_link }}"
                  rel="nofollow"
                  target="_blank"
                  title="Profil uživatele v ISP Admin"
                >ISP profil</a
                >
              </ng-template>
            </e-column>
          </e-columns>
        </ejs-grid>
      </td>
      <td>
        <ejs-grid
          #gridServices
          (created)="createdServices()"
          [dataSource]="serviceElements"
          [pageSettings]="pageSettings"
          [selectionSettings]="selectionSettings"
          [sortSettings]="initialServiceSort"
          [toolbar]="toolbar"
          allowPaging="true"
          allowSorting="true"
        >
          <e-columns>
            <e-column
              field="name"
              headerText="Název služby"
              width="150"
            ></e-column>
            <e-column
              [format]="'#,###.00\',- CZK\''"
              field="price"
              headerText="Cena služby"
              textAlign="Right"
              width="150"
            ></e-column>
          </e-columns>
          <e-aggregates>
            <e-aggregate>
              <e-columns>
                <e-column field="price" type="Sum">
                  <ng-template
                    #footerTemplate
                    let-serviceElements
                  >
                    Celkem:
                    {{ serviceElements.Sum }}
                  </ng-template
                  >
                </e-column>
              </e-columns>
            </e-aggregate>
            <e-aggregate>
              <e-columns>
                <e-column field="price" type="Average">
                  <ng-template
                    #footerTemplate
                    let-serviceElements
                  >
                    Průměr:
                    {{
                      serviceElements.Average
                    }}
                  </ng-template
                  >
                </e-column>
              </e-columns>
            </e-aggregate>
            <e-aggregate>
              <e-columns>
                <e-column field="price" type="Max">
                  <ng-template
                    #footerTemplate
                    let-serviceElements
                  >
                    Max:
                    {{ serviceElements.Max }}
                  </ng-template
                  >
                </e-column>
              </e-columns>
            </e-aggregate>
          </e-aggregates>
        </ejs-grid>
      </td>
    </tr>
    <tr
      *ngIf="
                searchedConnectedFieldAddress &&
                searchedConnectedFieldAddress.length > 0
            "
    >
      <th colspan="2">
        <h6>Parcely:</h6>
      </th>
    </tr>
    <tr
      *ngIf="
                searchedConnectedFieldAddress &&
                searchedConnectedFieldAddress.length > 0
            "
    >
      <td colspan="2">
        <div class="e-card">
          <div class="e-card-header">
            Připojené parcely / stavby / adresy bez RUIAN
          </div>
          <div class="e-card-content">
            <ul class="list-group">
              <li
                (click)="chooseFieldAddress(field)"
                *ngFor="
                                    let field of searchedConnectedFieldAddress
                                "
                [ngClass]="
                                    matchedConnectedFieldAddress &&
                                    matchedConnectedFieldAddress.id === field.id
                                        ? 'active'
                                        : ''
                                "
                class="list-group-item"
                style="cursor: pointer"
              >
                {{ field.address }} -
                {{ field.technology.name }} /
                {{ field.transfer.name }} ({{
                  field.provider.name
                }})
              </li>
            </ul>
          </div>
        </div>
      </td>
    </tr>
    <tr>
      <th colspan="2">
        <h6>Internet:</h6>
      </th>
    </tr>
    <tr
      *ngIf="
                addressForm.valid &&
                matchedConnectedRuianAddress &&
                matchedConnectedRuianAddress.net_packages
            "
    >
      <td colspan="2">
        <div
          *ngFor="
                        let netPackage of matchedConnectedRuianAddress.net_packages
                    "
          class="e-card mb-1"
        >
          <div class="e-card-content">
                        <span>
                            <strong>
                                <i
                                  *ngIf="
                                        netPackage.transfer
                                            .connection_method ===
                                        'optical cable'
                                    "
                                  aria-hidden="true"
                                  class="fa-light fa-lg fa-disc-drive"
                                ></i>
                                <i
                                  *ngIf="
                                        netPackage.transfer
                                            .connection_method ===
                                        'metalic cable'
                                    "
                                  aria-hidden="true"
                                  class="fa-light fa-lg fa-ethernet"
                                ></i>
                                <i
                                  *ngIf="
                                        netPackage.transfer
                                            .connection_method === 'radio'
                                    "
                                  aria-hidden="true"
                                  class="fa-light fa-lg fa-broadcast-tower"
                                ></i>
                                <i
                                  *ngIf="
                                        netPackage.transfer
                                            .connection_method === 'DSL'
                                    "
                                  aria-hidden="true"
                                  class="fa-light fa-lg fa-satellite-dish"
                                ></i>
                            </strong>
                        </span>
            <span>
                            <strong>
                                {{
                                netPackage.transfer.connection_method===
                                'optical cable'
                                  ? 'optické připojení'
                                  :netPackage.transfer
                                    .connection_method===
                                  'metalic cable'
                                    ? 'kabelové připojení'
                                    :netPackage.transfer
                                      .connection_method==='radio'
                                      ? 'rádiové připojení'
                                      :'xDSL připojení'
                              }}
                            </strong>
                        </span>
            -
            <strong>
              {{ netPackage.name }}
            </strong>
            <span
              *ngIf="netPackage.recommended"
              class="e-badge e-badge-primary float-right"
            >doporučujeme</span
            >
            <span
              *ngIf="netPackage.excludeOff"
              class="e-badge e-badge-danger float-right"
            >nelze instalovat</span
            >
            <span
              *ngIf="netPackage.extension"
              class="e-badge deep-purple darken-2 text-white float-right"
            >rozšíření</span
            >
            <div class="e-card-separator"></div>
            <div
              class="d-flex justify-content-between"
              style="font-size: 10px !important"
            >
                            <span *ngIf="netPackage.provider"
                            >provider: {{ netPackage.provider.name }}</span
                            >
              <span *ngIf="netPackage.aggregation"
              >agregace: {{ netPackage.aggregation }}</span
              >
              <span
                *ngIf="
                                    netPackage.speed_up || netPackage.speed_down
                                "
              >
                                rychlost up:
                {{
                  netPackage.speed_up
                    ? netPackage.speed_up
                    :0
                }}
                Mbit/s<br />
                                rychlost down:
                {{
                  netPackage.speed_down
                    ? netPackage.speed_down
                    :0
                }}
                Mbit/s
                            </span>
              <span
              >sleva:
                {{
                  netPackage.discount
                    ? netPackage.discount.discount
                    :0
                }}%</span
              >
            </div>
            <div class="e-card-separator"></div>
            <div
              *ngIf="
                                netPackage.net_bonuses &&
                                netPackage.net_bonuses.length > 0
                            "
              class="d-flex"
              style="font-size: 10px !important"
            >
              <strong>Bonusy tarifu:</strong>
              <span
                *ngFor="let bonus of netPackage.net_bonuses"
                class="ml-2"
              >
                                <i
                                  aria-hidden="true"
                                  class="fa-light fa-lg fa-question-circle mr-1"
                                  style="cursor: pointer"
                                ></i
                                >{{ bonus.name }}
                            </span>
            </div>
            <div class="d-flex" style="font-size: 10px !important">
              <div
                *ngIf="
                                    netPackage.net_places &&
                                    netPackage.net_places.length > 0
                                "
              >
                <strong>Oblast pokrytí:</strong>
                <span
                  *ngFor="let place of netPackage.net_places"
                  class="ml-2"
                >{{ place.name }}</span
                >
              </div>
            </div>
            <div class="d-flex" style="font-size: 10px !important">
              <div
                *ngIf="
                                    netPackage.ext_services &&
                                    netPackage.ext_services.length > 0
                                "
              >
                <strong>Rozšíření tarifu:</strong>
                <span
                  *ngFor="
                                        let extService of netPackage.ext_services
                                    "
                >
                                    <i
                                      (click)="
                                            showHint(extService.description)
                                        "
                                      aria-hidden="true"
                                      class="fa-light fa-lg fa-question-circle mr-1"
                                      style="cursor: pointer"
                                    ></i
                                    >{{ extService.name }}&nbsp;&nbsp;+&nbsp;{{
                    extService.price | number: '1.2-2'
                  }}
                  ,-
                                </span>
              </div>
            </div>
            <div class="e-card-separator"></div>
            <strong class="float-right"
            >{{ netPackage.price ? netPackage.price:0 }},- CZK
              s DPH / měsíčně</strong
            >
          </div>
        </div>
      </td>
    </tr>
    <tr
      *ngIf="
                addressForm.valid &&
                !matchedConnectedRuianAddress &&
                matchedConnectedFieldAddress &&
                matchedConnectedFieldAddress.net_packages
            "
    >
      <td colspan="2">
        <div
          *ngFor="
                        let netPackage of matchedConnectedFieldAddress.net_packages
                    "
          class="e-card mb-1"
        >
          <div class="e-card-content">
                        <span>
                            <strong>
                                <i
                                  *ngIf="
                                        netPackage.transfer
                                            .connection_method ===
                                        'optical cable'
                                    "
                                  aria-hidden="true"
                                  class="fa-light fa-lg fa-disc-drive"
                                ></i>
                                <i
                                  *ngIf="
                                        netPackage.transfer
                                            .connection_method ===
                                        'metalic cable'
                                    "
                                  aria-hidden="true"
                                  class="fa-light fa-lg fa-ethernet"
                                ></i>
                                <i
                                  *ngIf="
                                        netPackage.transfer
                                            .connection_method === 'radio'
                                    "
                                  aria-hidden="true"
                                  class="fa-light fa-lg fa-broadcast-tower"
                                ></i>
                                <i
                                  *ngIf="
                                        netPackage.transfer
                                            .connection_method === 'DSL'
                                    "
                                  aria-hidden="true"
                                  class="fa-light fa-lg fa-satellite-dish"
                                ></i>
                            </strong>
                        </span>
            <span>
                            <strong>
                                {{
                                netPackage.transfer.connection_method===
                                'optical cable'
                                  ? 'optické připojení'
                                  :netPackage.transfer
                                    .connection_method===
                                  'metalic cable'
                                    ? 'kabelové připojení'
                                    :netPackage.transfer
                                      .connection_method==='radio'
                                      ? 'rádiové připojení'
                                      :'xDSL připojení'
                              }}
                            </strong>
                        </span>
            -
            <strong>
              {{ netPackage.name }}
            </strong>
            <span
              *ngIf="netPackage.recommended"
              class="e-badge e-badge-primary float-right"
            >doporučujeme</span
            >
            <span
              *ngIf="netPackage.excludeOff"
              class="e-badge e-badge-danger float-right"
            >nelze instalovat</span
            >
            <span
              *ngIf="netPackage.extension"
              class="e-badge deep-purple darken-2 text-white float-right"
            >rozšíření</span
            >
            <div class="e-card-separator"></div>
            <div
              class="d-flex justify-content-between"
              style="font-size: 10px !important"
            >
                            <span *ngIf="netPackage.provider"
                            >provider: {{ netPackage.provider.name }}</span
                            >
              <span *ngIf="netPackage.aggregation"
              >agregace: {{ netPackage.aggregation }}</span
              >
              <span
                *ngIf="
                                    netPackage.speed_up || netPackage.speed_down
                                "
              >
                                rychlost up:
                {{
                  netPackage.speed_up
                    ? netPackage.speed_up
                    :0
                }}
                Mbit/s<br />
                                rychlost down:
                {{
                  netPackage.speed_down
                    ? netPackage.speed_down
                    :0
                }}
                Mbit/s
                            </span>
              <span
              >sleva:
                {{
                  netPackage.discount
                    ? netPackage.discount.discount
                    :0
                }}%</span
              >
            </div>
            <div class="e-card-separator"></div>
            <div
              *ngIf="
                                netPackage.net_bonuses &&
                                netPackage.net_bonuses.length > 0
                            "
              class="d-flex"
              style="font-size: 10px !important"
            >
              <strong>Bonusy tarifu:</strong>
              <span
                *ngFor="let bonus of netPackage.net_bonuses"
                class="ml-2"
              >
                                <i
                                  aria-hidden="true"
                                  class="fa-light fa-lg fa-question-circle mr-1"
                                  style="cursor: pointer"
                                ></i
                                >{{ bonus.name }}
                            </span>
            </div>
            <div
              *ngIf="
                                netPackage.net_places &&
                                netPackage.net_places.length > 0
                            "
              class="d-flex"
              style="font-size: 10px !important"
            >
              <strong>Oblast pokrytí:</strong>
              <span
                *ngFor="let place of netPackage.net_places"
                class="ml-2"
              >{{ place.name }}</span
              >
            </div>
            <div
              *ngIf="
                                netPackage.ext_services &&
                                netPackage.ext_services.length > 0
                            "
              class="d-flex"
              style="font-size: 10px !important"
            >
              <strong>Rozšíření tarifu:</strong>
              <span
                *ngFor="
                                    let extService of netPackage.ext_services
                                "
                class="ml-2"
              >
                                <i
                                  (click)="showHint(extService.description)"
                                  aria-hidden="true"
                                  class="fa-light fa-lg fa-question-circle mr-1"
                                  style="cursor: pointer"
                                ></i
                                >{{ extService.name }}&nbsp;&nbsp;+&nbsp;{{
                  extService.price | number: '1.2-2'
                }}
                ,-
                            </span>
            </div>
            <div class="e-card-separator"></div>
            <strong class="float-right"
            >{{ netPackage.price ? netPackage.price:0 }},- CZK
              s DPH / měsíčně</strong
            >
          </div>
        </div>
      </td>
    </tr>
    <tr
      *ngIf="
                addressForm.valid &&
                !matchedConnectedRuianAddress &&
                !matchedConnectedFieldAddress &&
                matchedWirelessAddress &&
                matchedWirelessAddress.net_packages
            "
    >
      <td colspan="2">
        <div
          *ngFor="
                        let netPackage of matchedWirelessAddress.net_packages
                    "
          class="e-card mb-1"
        >
          <div class="e-card-content">
                        <span>
                            <strong>
                                <i
                                  *ngIf="
                                        netPackage.transfer
                                            .connection_method === 'radio'
                                    "
                                  aria-hidden="true"
                                  class="fa-light fa-lg fa-broadcast-tower"
                                ></i>
                                <i
                                  *ngIf="
                                        netPackage.transfer
                                            .connection_method === 'DSL'
                                    "
                                  aria-hidden="true"
                                  class="fa-light fa-lg fa-satellite-dish"
                                ></i>
                            </strong>
                        </span>
            <span>
                            <strong>
                                {{
                                netPackage.transfer.connection_method===
                                'radio'
                                  ? 'rádiové připojení'
                                  :'xDSL připojení'
                              }}
                            </strong>
                        </span>
            -
            <strong>
              {{ netPackage.name }}
            </strong>
            <span
              *ngIf="netPackage.recommended"
              class="e-badge e-badge-primary float-right"
            >doporučujeme</span
            >
            <span
              *ngIf="netPackage.excludeOff"
              class="e-badge e-badge-danger float-right"
            >nelze instalovat</span
            >
            <span
              *ngIf="netPackage.extension"
              class="e-badge deep-purple darken-2 text-white float-right"
            >rozšíření</span
            >
            <div class="e-card-separator"></div>
            <div
              class="d-flex justify-content-between"
              style="font-size: 10px !important"
            >
                            <span *ngIf="netPackage.provider"
                            >provider: {{ netPackage.provider.name }}</span
                            >
              <span *ngIf="netPackage.aggregation"
              >agregace: {{ netPackage.aggregation }}</span
              >
              <span
                *ngIf="
                                    netPackage.speed_up || netPackage.speed_down
                                "
              >
                                rychlost up:
                {{
                  netPackage.speed_up
                    ? netPackage.speed_up
                    :0
                }}
                Mbit/s
                                <br />
                                rychlost down:
                {{
                  netPackage.speed_down
                    ? netPackage.speed_down
                    :0
                }}
                Mbit/s
                            </span>
              <span
              >sleva:
                {{
                  netPackage.discount
                    ? netPackage.discount.discount
                    :0
                }}%</span
              >
            </div>
            <div class="e-card-separator"></div>
            <div
              *ngIf="
                                netPackage.net_bonuses &&
                                netPackage.net_bonuses.length > 0
                            "
              class="d-flex"
              style="font-size: 10px !important"
            >
              <strong>Bonusy tarifu:</strong>
              <span
                *ngFor="let bonus of netPackage.net_bonuses"
                class="ml-2"
              >
                                <i
                                  aria-hidden="true"
                                  class="fa-light fa-lg fa-question-circle mr-1"
                                  style="cursor: pointer"
                                ></i
                                >{{ bonus.name }}
                            </span>
            </div>
            <div class="d-flex" style="font-size: 10px !important">
              <div
                *ngIf="
                                    netPackage.net_places &&
                                    netPackage.net_places.length > 0
                                "
              >
                <strong>Oblast pokrytí:</strong>
                <span
                  *ngFor="let place of netPackage.net_places"
                  class="ml-2"
                >{{ place.name }}</span
                >
              </div>
            </div>
            <div class="d-flex" style="font-size: 10px !important">
              <div
                *ngIf="
                                    netPackage.ext_services &&
                                    netPackage.ext_services.length > 0
                                "
              >
                <strong>Rozšíření tarifu:</strong>
                <span
                  *ngFor="
                                        let extService of netPackage.ext_services
                                    "
                  class="ml-2"
                >
                                    <i
                                      (click)="
                                            showHint(extService.description)
                                        "
                                      aria-hidden="true"
                                      class="fa-light fa-lg fa-question-circle mr-1"
                                      style="cursor: pointer"
                                    ></i
                                    >{{ extService.name }}&nbsp;&nbsp;+&nbsp;{{
                    extService.price | number: '1.2-2'
                  }}
                  ,-
                                </span>
              </div>
            </div>
            <div class="e-card-separator"></div>
            <strong class="float-right"
            >{{ netPackage.price ? netPackage.price:0 }},- CZK
              s DPH / měsíčně</strong
            >
          </div>
        </div>
      </td>
    </tr>
    <tr>
      <th colspan="2">
        <h6>Televize:</h6>
      </th>
    </tr>
    <tr
      *ngIf="
                addressForm.valid &&
                matchedConnectedRuianAddress &&
                matchedConnectedRuianAddress.tv_services
            "
    >
      <td colspan="2">
        <div
          *ngFor="
                        let tvService of matchedConnectedRuianAddress.tv_services
                    "
          class="e-card mb-1"
        >
          <div class="e-card-content">
                        <span>
                            <strong>
                                <i
                                  *ngIf="
                                        tvService.transfer.connection_method ===
                                        'optical cable'
                                    "
                                  aria-hidden="true"
                                  class="fa-light fa-lg fa-disc-drive"
                                ></i>
                                <i
                                  *ngIf="
                                        tvService.transfer.connection_method ===
                                        'metalic cable'
                                    "
                                  aria-hidden="true"
                                  class="fa-light fa-lg fa-ethernet"
                                ></i>
                                <i
                                  *ngIf="
                                        tvService.transfer.connection_method ===
                                        'radio'
                                    "
                                  aria-hidden="true"
                                  class="fa-light fa-lg fa-broadcast-tower"
                                ></i>
                                <i
                                  *ngIf="
                                        tvService.transfer.connection_method ===
                                        'DSL'
                                    "
                                  aria-hidden="true"
                                  class="fa-light fa-lg fa-satellite-dish"
                                ></i>
                            </strong>
                        </span>
            <span>
                            <strong>
                                {{
                                tvService.transfer.connection_method===
                                'optical cable'
                                  ? 'optické připojení'
                                  :tvService.transfer
                                    .connection_method===
                                  'metalic cable'
                                    ? 'kabelové připojení'
                                    :tvService.transfer
                                      .connection_method==='radio'
                                      ? 'rádiové připojení'
                                      :'xDSL připojení'
                              }}
                            </strong>
                        </span>
            -
            <strong>
              {{ tvService.name }}
            </strong>
            <span *ngIf="tvService.recommended">
                            <span class="e-badge e-badge-primary float-right"
                            >doporučujeme</span
                            >
                        </span>
            <span
              *ngIf="tvService.excludeOff"
              class="e-badge e-badge-danger float-right"
            >nelze instalovat</span
            >
            <span *ngIf="tvService.extension">
                            <span
                              class="e-badge deep-purple darken-2 text-white float-right"
                            >rozšíření</span
                            >
                        </span>
            <div class="e-card-separator"></div>
            <div
              class="d-flex justify-content-between"
              style="font-size: 10px !important"
            >
                            <span *ngIf="tvService.provider">
                                provider: {{ tvService.provider.name }}
                            </span>
              <span *ngIf="tvService.tv_packages">
                                balíčků: {{ tvService.tv_packages.length }}
                            </span>
              <span>
                                sleva:
                {{
                  tvService.discount
                    ? tvService.discount.discount
                    :0
                }}%
                            </span>
            </div>
            <div class="e-card-separator"></div>
            <div class="d-flex" style="font-size: 10px !important">
              <ng-container
                *ngIf="
                                    tvService.tv_bonuses &&
                                    tvService.tv_bonuses.length > 0
                                "
              >
                <strong>Bonusy tarifu:</strong>
                <div *ngFor="let bonus of tvService.tv_bonuses">
                                    <span
                                      class="text-muted p-1 m-0"
                                      style="font-size: 10px !important"
                                    >{{ bonus.name }}</span
                                    >
                  <i
                    aria-hidden="true"
                    class="fa-light fa-lg fa-question-circle"
                    style="cursor: pointer"
                  ></i>
                  <br />
                </div>
                <hr class="p-1 m-0" />
              </ng-container>
              <div
                *ngIf="
                                    tvService.tv_bonuses &&
                                    tvService.tv_bonuses.length > 0
                                "
              >
                <strong>Bonusy tarifu:</strong>
                <span
                  *ngFor="let bonus of tvService.tv_bonuses"
                  class="ml-2"
                >
                                    <i
                                      aria-hidden="true"
                                      class="fa-light fa-lg fa-question-circle mr-1"
                                      style="cursor: pointer"
                                    ></i
                                    >{{ bonus.name }}
                                </span>
              </div>
            </div>
            <div class="d-flex" style="font-size: 10px !important">
              <div
                *ngIf="
                                    tvService.tv_places &&
                                    tvService.tv_places.length > 0
                                "
              >
                <strong>Oblast pokrytí:</strong>
                <span
                  *ngFor="let place of tvService.tv_places"
                  class="ml-2"
                >{{ place.name }}</span
                >
              </div>
            </div>
            <div class="d-flex" style="font-size: 10px !important">
              <div
                *ngIf="
                                    tvService.ext_services &&
                                    tvService.ext_services.length > 0
                                "
              >
                <strong>Rozšíření tarifu:</strong>
                <span
                  *ngFor="
                                        let extService of tvService.ext_services
                                    "
                  class="ml-2"
                >
                                    <i
                                      (click)="
                                            showHint(extService.description)
                                        "
                                      aria-hidden="true"
                                      class="fa-light fa-lg fa-question-circle mr-1"
                                      style="cursor: pointer"
                                    ></i
                                    >{{ extService.name }}&nbsp;&nbsp;+&nbsp;{{
                    extService.price | number: '1.2-2'
                  }}
                  ,-
                                </span>
              </div>
            </div>
            <div class="e-card-separator"></div>
            <strong class="float-right"
            >{{
                tvService.pernament_price
                  ? tvService.pernament_price
                  :0
              }},- CZK s DPH / měsíčně</strong
            >
            <div class="clearfix"></div>
            <div class="e-card-separator"></div>
            <div
              *ngFor="let tvPackage of tvService.tv_packages"
              class="d-flex justify-content-between m-0 p-1"
              style="font-size: 10px !important"
            >
              <strong>
                {{ tvPackage.name }}
              </strong>
              <strong>
                {{
                  tvPackage.tv_channels &&
                  tvPackage.tv_channels.length > 0
                    ? tvPackage.tv_channels.length
                    :0
                }}
                programů
              </strong>
              <div>
                                <span
                                  *ngIf="tvPackage.recommended"
                                  class="e-badge e-badge-primary mr-1"
                                >doporučujeme</span
                                >
                <span
                  *ngIf="tvPackage.extension"
                  class="e-badge deep-purple darken-2 text-white mr-1"
                >rozšíření</span
                >
                <span
                  *ngIf="!tvPackage.extension"
                  class="e-badge e-badge-warning mr-1"
                >TV balíček</span
                >
              </div>
              <strong
              >{{ tvPackage.price ? tvPackage.price:0 }},-
                CZK s DPH / měsíčně</strong
              >
            </div>
          </div>
        </div>
      </td>
    </tr>
    <tr
      *ngIf="
                addressForm.valid &&
                !matchedConnectedRuianAddress &&
                matchedConnectedFieldAddress &&
                matchedConnectedFieldAddress.tv_services
            "
    >
      <td colspan="2">
        <div
          *ngFor="
                        let tvService of matchedConnectedFieldAddress.tv_services
                    "
          class="e-card mb-1"
        >
          <div class="e-card-content">
                        <span>
                            <strong>
                                <i
                                  *ngIf="
                                        tvService.transfer.connection_method ===
                                        'optical cable'
                                    "
                                  aria-hidden="true"
                                  class="fa-light fa-lg fa-disc-drive"
                                ></i>
                                <i
                                  *ngIf="
                                        tvService.transfer.connection_method ===
                                        'metalic cable'
                                    "
                                  aria-hidden="true"
                                  class="fa-light fa-lg fa-ethernet"
                                ></i>
                                <i
                                  *ngIf="
                                        tvService.transfer.connection_method ===
                                        'radio'
                                    "
                                  aria-hidden="true"
                                  class="fa-light fa-lg fa-broadcast-tower"
                                ></i>
                                <i
                                  *ngIf="
                                        tvService.transfer.connection_method ===
                                        'DSL'
                                    "
                                  aria-hidden="true"
                                  class="fa-light fa-lg fa-satellite-dish"
                                ></i>
                            </strong>
                        </span>
            <span>
                            <strong>
                                {{
                                tvService.transfer.connection_method===
                                'optical cable'
                                  ? 'optické připojení'
                                  :tvService.transfer
                                    .connection_method===
                                  'metalic cable'
                                    ? 'kabelové připojení'
                                    :tvService.transfer
                                      .connection_method==='radio'
                                      ? 'rádiové připojení'
                                      :'xDSL připojení'
                              }}
                            </strong>
                        </span>
            -
            <strong>
              {{ tvService.name }}
            </strong>
            <span *ngIf="tvService.recommended">
                            <span class="e-badge e-badge-primary float-right"
                            >doporučujeme</span
                            >
                        </span>
            <span
              *ngIf="tvService.excludeOff"
              class="e-badge e-badge-danger float-right"
            >nelze instalovat</span
            >
            <span *ngIf="tvService.extension">
                            <span
                              class="e-badge deep-purple darken-2 text-white float-right"
                            >rozšíření</span
                            >
                        </span>
            <div class="e-card-separator"></div>
            <div
              class="d-flex justify-content-between"
              style="font-size: 10px !important"
            >
                            <span *ngIf="tvService.provider">
                                provider: {{ tvService.provider.name }}
                            </span>
              <span *ngIf="tvService.tv_packages">
                                balíčků: {{ tvService.tv_packages.length }}
                            </span>
              <span>
                                sleva:
                {{
                  tvService.discount
                    ? tvService.discount.discount
                    :0
                }}%
                            </span>
            </div>
            <div class="e-card-separator"></div>
            <div class="d-flex" style="font-size: 10px !important">
              <ng-container
                *ngIf="
                                    tvService.tv_bonuses &&
                                    tvService.tv_bonuses.length > 0
                                "
              >
                <strong>Bonusy tarifu:</strong>
                <div *ngFor="let bonus of tvService.tv_bonuses">
                                    <span
                                      class="text-muted p-1 m-0"
                                      style="font-size: 10px !important"
                                    >{{ bonus.name }}</span
                                    >
                  <i
                    aria-hidden="true"
                    class="fa-light fa-lg fa-question-circle"
                    style="cursor: pointer"
                  ></i>
                  <br />
                </div>
                <hr class="p-1 m-0" />
              </ng-container>
              <div
                *ngIf="
                                    tvService.tv_bonuses &&
                                    tvService.tv_bonuses.length > 0
                                "
              >
                <strong>Bonusy tarifu:</strong>
                <span
                  *ngFor="let bonus of tvService.tv_bonuses"
                  class="ml-2"
                >
                                    <i
                                      aria-hidden="true"
                                      class="fa-light fa-lg fa-question-circle mr-1"
                                      style="cursor: pointer"
                                    ></i
                                    >{{ bonus.name }}
                                </span>
              </div>
            </div>
            <div class="d-flex" style="font-size: 10px !important">
              <div
                *ngIf="
                                    tvService.tv_places &&
                                    tvService.tv_places.length > 0
                                "
              >
                <strong>Oblast pokrytí:</strong>
                <span
                  *ngFor="let place of tvService.tv_places"
                  class="ml-2"
                >{{ place.name }}</span
                >
              </div>
            </div>
            <div class="d-flex" style="font-size: 10px !important">
              <div
                *ngIf="
                                    tvService.ext_services &&
                                    tvService.ext_services.length > 0
                                "
              >
                <strong>Rozšíření tarifu:</strong>
                <span
                  *ngFor="
                                        let extService of tvService.ext_services
                                    "
                  class="ml-2"
                >
                                    <i
                                      (click)="
                                            showHint(extService.description)
                                        "
                                      aria-hidden="true"
                                      class="fa-light fa-lg fa-question-circle mr-1"
                                      style="cursor: pointer"
                                    ></i
                                    >{{ extService.name }}&nbsp;&nbsp;+&nbsp;{{
                    extService.price | number: '1.2-2'
                  }}
                  ,-
                                </span>
              </div>
            </div>
            <div class="e-card-separator"></div>
            <strong class="float-right"
            >{{
                tvService.pernament_price
                  ? tvService.pernament_price
                  :0
              }},- CZK s DPH / měsíčně</strong
            >
            <div class="clearfix"></div>
            <div class="e-card-separator"></div>
            <div
              *ngFor="let tvPackage of tvService.tv_packages"
              class="d-flex justify-content-between m-0 p-1"
              style="font-size: 10px !important"
            >
              <strong>
                {{ tvPackage.name }}
              </strong>
              <strong>
                {{
                  tvPackage.tv_channels &&
                  tvPackage.tv_channels.length > 0
                    ? tvPackage.tv_channels.length
                    :0
                }}
                programů
              </strong>
              <div>
                                <span
                                  *ngIf="tvPackage.recommended"
                                  class="e-badge e-badge-primary mr-1"
                                >doporučujeme</span
                                >
                <span
                  *ngIf="tvPackage.extension"
                  class="e-badge deep-purple darken-2 text-white mr-1"
                >rozšíření</span
                >
                <span
                  *ngIf="!tvPackage.extension"
                  class="e-badge e-badge-warning mr-1"
                >TV balíček</span
                >
              </div>
              <strong
              >{{ tvPackage.price ? tvPackage.price:0 }},-
                CZK s DPH / měsíčně</strong
              >
            </div>
          </div>
        </div>
      </td>
    </tr>
    <tr
      *ngIf="
                addressForm.valid &&
                !matchedConnectedRuianAddress &&
                !matchedConnectedFieldAddress &&
                matchedWirelessAddress &&
                matchedWirelessAddress.tv_services
            "
    >
      <td colspan="2">
        <div
          *ngFor="let tvService of matchedWirelessAddress.tv_services"
          class="e-card mb-1"
        >
          <div class="e-card-content">
                        <span>
                            <strong>
                                <i
                                  *ngIf="
                                        tvService.transfer.connection_method ===
                                        'optical cable'
                                    "
                                  aria-hidden="true"
                                  class="fa-light fa-lg fa-disc-drive"
                                ></i>
                                <i
                                  *ngIf="
                                        tvService.transfer.connection_method ===
                                        'metalic cable'
                                    "
                                  aria-hidden="true"
                                  class="fa-light fa-lg fa-ethernet"
                                ></i>
                                <i
                                  *ngIf="
                                        tvService.transfer.connection_method ===
                                        'radio'
                                    "
                                  aria-hidden="true"
                                  class="fa-light fa-lg fa-broadcast-tower"
                                ></i>
                                <i
                                  *ngIf="
                                        tvService.transfer.connection_method ===
                                        'DSL'
                                    "
                                  aria-hidden="true"
                                  class="fa-light fa-lg fa-satellite-dish"
                                ></i>
                            </strong>
                        </span>
            <span>
                            <strong>
                                {{
                                tvService.transfer.connection_method===
                                'optical cable'
                                  ? 'optické připojení'
                                  :tvService.transfer
                                    .connection_method===
                                  'metalic cable'
                                    ? 'kabelové připojení'
                                    :tvService.transfer
                                      .connection_method==='radio'
                                      ? 'rádiové připojení'
                                      :'xDSL připojení'
                              }}
                            </strong>
                        </span>
            -
            <strong>
              {{ tvService.name }}
            </strong>
            <span *ngIf="tvService.recommended">
                            <span class="e-badge e-badge-primary float-right"
                            >doporučujeme</span
                            >
                        </span>
            <span
              *ngIf="tvService.excludeOff"
              class="e-badge e-badge-danger float-right"
            >nelze instalovat</span
            >
            <span *ngIf="tvService.extension">
                            <span
                              class="e-badge deep-purple darken-2 text-white float-right"
                            >rozšíření</span
                            >
                        </span>
            <div class="e-card-separator"></div>
            <div
              class="d-flex justify-content-between"
              style="font-size: 10px !important"
            >
                            <span *ngIf="tvService.provider">
                                provider: {{ tvService.provider.name }}
                            </span>
              <span *ngIf="tvService.tv_packages">
                                balíčků: {{ tvService.tv_packages.length }}
                            </span>
              <span>
                                sleva:
                {{
                  tvService.discount
                    ? tvService.discount.discount
                    :0
                }}%
                            </span>
            </div>
            <div class="e-card-separator"></div>
            <div class="d-flex" style="font-size: 10px !important">
              <ng-container
                *ngIf="
                                    tvService.tv_bonuses &&
                                    tvService.tv_bonuses.length > 0
                                "
              >
                <strong>Bonusy tarifu:</strong>
                <span
                  *ngFor="let bonus of tvService.tv_bonuses"
                  class="ml-2"
                >
                                    {{
                    bonus.name
                  }}<i
                  aria-hidden="true"
                  class="fa-light fa-lg fa-question-circle"
                  style="cursor: pointer"
                ></i>
                                </span>
                <hr class="p-1 m-0" />
              </ng-container>
              <div
                *ngIf="
                                    tvService.tv_bonuses &&
                                    tvService.tv_bonuses.length > 0
                                "
              >
                <strong>Bonusy tarifu:</strong>
                <span
                  *ngFor="let bonus of tvService.tv_bonuses"
                  class="ml-2"
                >
                                    <i
                                      aria-hidden="true"
                                      class="fa-light fa-lg fa-question-circle mr-1"
                                      style="cursor: pointer"
                                    ></i
                                    >{{ bonus.name }}
                                </span>
              </div>
            </div>
            <div class="d-flex" style="font-size: 10px !important">
              <div
                *ngIf="
                                    tvService.tv_places &&
                                    tvService.tv_places.length > 0
                                "
              >
                <strong>Oblast pokrytí:</strong>
                <span
                  *ngFor="let place of tvService.tv_places"
                  class="ml-2"
                >{{ place.name }}</span
                >
              </div>
            </div>
            <div class="d-flex" style="font-size: 10px !important">
              <div
                *ngIf="
                                    tvService.ext_services &&
                                    tvService.ext_services.length > 0
                                "
              >
                <strong>Rozšíření tarifu:</strong>
                <span
                  *ngFor="
                                        let extService of tvService.ext_services
                                    "
                  class="ml-2"
                >
                                    <i
                                      (click)="
                                            showHint(extService.description)
                                        "
                                      aria-hidden="true"
                                      class="fa-light fa-lg fa-question-circle mr-1"
                                      style="cursor: pointer"
                                    ></i
                                    >{{ extService.name }}&nbsp;&nbsp;+&nbsp;{{
                    extService.price | number: '1.2-2'
                  }}
                  ,-
                                </span>
              </div>
            </div>
            <div class="e-card-separator"></div>
            <strong class="float-right"
            >{{
                tvService.pernament_price
                  ? tvService.pernament_price
                  :0
              }},- CZK s DPH / měsíčně</strong
            >
            <div class="clearfix"></div>
            <div class="e-card-separator"></div>
            <div
              *ngFor="let tvPackage of tvService.tv_packages"
              class="d-flex justify-content-between m-0 p-1"
              style="font-size: 10px !important"
            >
              <strong>
                {{ tvPackage.name }}
              </strong>
              <strong>
                {{
                  tvPackage.tv_channels &&
                  tvPackage.tv_channels.length > 0
                    ? tvPackage.tv_channels.length
                    :0
                }}
                programů
              </strong>
              <div>
                                <span
                                  *ngIf="tvPackage.recommended"
                                  class="e-badge e-badge-primary mr-1"
                                >doporučujeme</span
                                >
                <span
                  *ngIf="tvPackage.extension"
                  class="e-badge deep-purple darken-2 text-white mr-1"
                >rozšíření</span
                >
                <span
                  *ngIf="!tvPackage.extension"
                  class="e-badge e-badge-warning mr-1"
                >TV balíček</span
                >
              </div>
              <strong
              >{{ tvPackage.price ? tvPackage.price:0 }},-
                CZK s DPH / měsíčně</strong
              >
            </div>
          </div>
        </div>
      </td>
    </tr>
    <tr>
      <th colspan="2">
        <h6>Wi-Fi:</h6>
      </th>
    </tr>
    <tr *ngIf="addressForm.valid">
      <td colspan="2">
        <form [formGroup]="wifiForm">
          <div class="row">
            <div class="col-12 mb-3">
              <label
                [ngClass]="
                                    wifiFormCtrl.unit_id.valid
                                        ? 'text-success'
                                        : wifiFormCtrl.unit_id.invalid &&
                                          (wifiFormCtrl.unit_id.dirty ||
                                              wifiFormCtrl.unit_id.touched)
                                        ? 'text-danger'
                                        : 'text-muted'
                                "
                for="searchAddressForm-unit"
              >* Typ bytové jednotky:</label
              >
              <ejs-dropdownlist
                #unit
                [allowFiltering]="true"
                [dataSource]="unitsSelect"
                [fields]="fields"
                [filterBarPlaceholder]="
                                    'Vyhledat typ bytové jednotk...'
                                "
                [ignoreAccent]="true"
                [ngClass]="
                                    (wifiFormCtrl.unit_id.dirty ||
                                        wifiFormCtrl.unit_id.touched) &&
                                    wifiFormCtrl.unit_id.invalid
                                        ? 'e-error'
                                        : (wifiFormCtrl.unit_id.dirty ||
                                              wifiFormCtrl.unit_id.touched) &&
                                          wifiFormCtrl.unit_id.valid
                                        ? 'e-success'
                                        : ''
                                "
                [placeholder]="'Vyberte typ bytové jednotky'"
                [popupHeight]="'240px'"
                formControlName="unit_id"
                id="searchAddressForm-unit"
                sortOrder="Ascending"
              ></ejs-dropdownlist>
              <small
                *ngIf="
                                    (wifiFormCtrl.unit_id.dirty ||
                                        wifiFormCtrl.unit_id.touched) &&
                                    wifiFormCtrl.unit_id.errors &&
                                    wifiFormCtrl.unit_id.errors.required
                                "
                class="text-danger"
              >Vyberte typ bytové jednotky</small
              >
            </div>
            <div class="col-12 mb-3">
              <label
                [ngClass]="
                                    wifiFormCtrl.space_id.valid
                                        ? 'text-success'
                                        : wifiFormCtrl.space_id.invalid &&
                                          (wifiFormCtrl.space_id.dirty ||
                                              wifiFormCtrl.space_id.touched)
                                        ? 'text-danger'
                                        : 'text-muted'
                                "
                for="searchAddressForm-space"
              >* Prostor bytové jednotky:</label
              >
              <ejs-dropdownlist
                #space
                [allowFiltering]="true"
                [dataSource]="spacesSelect"
                [disabled]="
                                    !wifiFormCtrl.unit_id.value ||
                                    wifiFormCtrl.unit_id.invalid
                                "
                [fields]="fields"
                [filterBarPlaceholder]="
                                    'Vyhledat prostor bytové jednotk...'
                                "
                [ignoreAccent]="true"
                [ngClass]="
                                    (wifiFormCtrl.space_id.dirty ||
                                        wifiFormCtrl.space_id.touched) &&
                                    wifiFormCtrl.space_id.invalid
                                        ? 'e-error'
                                        : (wifiFormCtrl.space_id.dirty ||
                                              wifiFormCtrl.space_id.touched) &&
                                          wifiFormCtrl.space_id.valid
                                        ? 'e-success'
                                        : ''
                                "
                [placeholder]="
                                    'Vyberte prostor bytové jednotky'
                                "
                [popupHeight]="'240px'"
                formControlName="space_id"
                id="searchAddressForm-space"
                sortOrder="Ascending"
              ></ejs-dropdownlist>
              <small
                *ngIf="
                                    (wifiFormCtrl.space_id.dirty ||
                                        wifiFormCtrl.space_id.touched) &&
                                    wifiFormCtrl.space_id.errors &&
                                    wifiFormCtrl.space_id.errors.required
                                "
                class="text-danger"
              >Vyberte prostor bytové jednotky</small
              >
            </div>
            <div class="col-12 mb-3">
              <label
                [ngClass]="
                                    wifiFormCtrl.speed_id.valid
                                        ? 'text-success'
                                        : wifiFormCtrl.speed_id.invalid &&
                                          (wifiFormCtrl.speed_id.dirty ||
                                              wifiFormCtrl.speed_id.touched)
                                        ? 'text-danger'
                                        : 'text-muted'
                                "
                for="searchAddressForm-speed"
              >* Limity rychlostí:</label
              >
              <ejs-dropdownlist
                #speed
                [allowFiltering]="true"
                [dataSource]="speedsSelect"
                [disabled]="
                                    !wifiFormCtrl.space_id.value ||
                                    wifiFormCtrl.space_id.invalid
                                "
                [fields]="fields"
                [filterBarPlaceholder]="
                                    'Vyhledat limity rychlostí...'
                                "
                [ignoreAccent]="true"
                [ngClass]="
                                    (wifiFormCtrl.speed_id.dirty ||
                                        wifiFormCtrl.speed_id.touched) &&
                                    wifiFormCtrl.speed_id.invalid
                                        ? 'e-error'
                                        : (wifiFormCtrl.speed_id.dirty ||
                                              wifiFormCtrl.speed_id.touched) &&
                                          wifiFormCtrl.speed_id.valid
                                        ? 'e-success'
                                        : ''
                                "
                [placeholder]="'Vyberte limity rychlostí'"
                [popupHeight]="'240px'"
                formControlName="speed_id"
                id="searchAddressForm-speed"
                sortOrder="Ascending"
              ></ejs-dropdownlist>
              <small
                *ngIf="
                                    (wifiFormCtrl.speed_id.dirty ||
                                        wifiFormCtrl.speed_id.touched) &&
                                    wifiFormCtrl.speed_id.errors &&
                                    wifiFormCtrl.speed_id.errors.required
                                "
                class="text-danger"
              >Vyberte limity rychlostí</small
              >
            </div>
            <div class="col-12 mb-3 text-center">
              <ejs-checkbox
                [checked]="false"
                [disabled]="
                                    !wifiFormCtrl.speed_id.value ||
                                    wifiFormCtrl.speed_id.invalid ||
                                    !wifiFormCtrl.space_id.value ||
                                    wifiFormCtrl.space_id.invalid ||
                                    !wifiFormCtrl.unit_id.value ||
                                    wifiFormCtrl.unit_id.invalid
                                "
                cssClass="e-primary mr-3"
                formControlName="basics"
                label="Základy"
              ></ejs-checkbox>
              <ejs-checkbox
                [checked]="false"
                [disabled]="
                                    !wifiFormCtrl.speed_id.value ||
                                    wifiFormCtrl.speed_id.invalid ||
                                    !wifiFormCtrl.space_id.value ||
                                    wifiFormCtrl.space_id.invalid ||
                                    !wifiFormCtrl.unit_id.value ||
                                    wifiFormCtrl.unit_id.invalid
                                "
                cssClass="e-primary mr-3"
                formControlName="cabels"
                label="Kabeláž"
              ></ejs-checkbox>
              <ejs-checkbox
                [checked]="false"
                [disabled]="
                                    !wifiFormCtrl.speed_id.value ||
                                    wifiFormCtrl.speed_id.invalid ||
                                    !wifiFormCtrl.space_id.value ||
                                    wifiFormCtrl.space_id.invalid ||
                                    !wifiFormCtrl.unit_id.value ||
                                    wifiFormCtrl.unit_id.invalid
                                "
                cssClass="e-primary mr-3"
                formControlName="garden"
                label="Zahrada"
              ></ejs-checkbox>
              <ejs-checkbox
                [checked]="false"
                [disabled]="
                                    !wifiFormCtrl.speed_id.value ||
                                    wifiFormCtrl.speed_id.invalid ||
                                    !wifiFormCtrl.space_id.value ||
                                    wifiFormCtrl.space_id.invalid ||
                                    !wifiFormCtrl.unit_id.value ||
                                    wifiFormCtrl.unit_id.invalid
                                "
                cssClass="e-primary mr-3"
                formControlName="roaming"
                label="Wi-Fi roaming"
              ></ejs-checkbox>
            </div>
          </div>
        </form>
        <div
          *ngIf="
                        activeWifiSets &&
                        (!matchedWifiSets || matchedWifiSets.length === 0)
                    "
          class="alert alert-danger p-1"
          role="alert"
          style="font-size: 14px !important"
        >
          <i
            aria-hidden="true"
            class="fa-light fa-lg fa-exclamation-triangle mr-2"
          ></i
          >Tato sestava nemá přidělen <strong>hardware</strong>.
          <button
            (click)="resetWifiForm()"
            class="e-btn e-warning e-small btn-p-zero ml-2"
            ejs-button
            type="button"
          >
            resetovat formulář
          </button>
        </div>
      </td>
    </tr>
    <tr
      *ngIf="
                addressForm.valid &&
                matchedWifiSets &&
                matchedWifiSets.length > 0
            "
    >
      <td colspan="2">
        <div *ngFor="let sets of matchedWifiSets" class="e-card mb-1">
          <div class="e-card-content">
            <strong>
              {{ sets.hardware.name }}
            </strong>
            <span *ngIf="sets.recommended">
                            <span class="e-badge e-badge-primary float-right"
                            >doporučujeme</span
                            >
                        </span>
            <div class="e-card-separator"></div>
            <div
              class="d-flex justify-content-between"
              style="font-size: 10px !important"
            >
                            <span
                            >cena:
                              {{
                                sets.hardware.price
                                  ? sets.hardware.price
                                  :0
                              }},- CZK</span
                            >
              <span
              >pronájem:
                {{
                  sets.hardware.fee ? sets.hardware.fee:0
                }},- CZK</span
              >
            </div>
            <div class="e-card-separator"></div>
            <ng-container
              *ngIf="sets.hardware && sets.hardware.photo_url"
            >
              <img
                (click)="open(0)"
                [src]="
                                    sets.hardware.photo_thumb_url ||
                                    sets.hardware.photo_url
                                "
                style="cursor: pointer"
              />
            </ng-container>
          </div>
        </div>
      </td>
    </tr>
  </ng-container>
</table>
