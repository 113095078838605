import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {catchError, tap} from 'rxjs/operators';
import {MessageService} from '@src/app/_services/message.service';
import {AcsUserLogs} from '@src/app/_models/_api_acs/logs';
import {AcsCategory} from '@src/app/_models/_api_acs/category';
import {ApiPaths} from '@src/app/_models/api/paths';
import {ApisService} from '@src/app/_api/apis.service';
import {registerLocaleData} from '@angular/common';
import moment from 'moment';
import localeCs from '@angular/common/locales/cs';
import {EnvironmentService} from '@src/app/_services/environment.service';

moment.updateLocale('cs', {workingWeekdays: [1, 2, 3, 4, 5]});
registerLocaleData(localeCs);

@Injectable({
    providedIn: 'root',
})
export class AcsService {
    private readonly acsLogUrl: string;

    private readonly acsCategoriesUrl: string;

    private readonly resultAcsUsersSource = new BehaviorSubject<unknown[] | null | undefined>(null);

    acsUsersSource = this.resultAcsUsersSource.asObservable();

    constructor(
        private readonly http: HttpClient,
        private readonly messageService: MessageService,
        private readonly apisService: ApisService,
        private readonly environmentService: EnvironmentService,
    ) {
        this.acsLogUrl = this.environmentService.backendURL + '/api/vacation/_api/acs/logs';
        this.acsCategoriesUrl =
            this.environmentService.backendURL + '/api/vacation/_api/acs/categories';
    }

    setAcsUsers(path: ApiPaths | undefined): void {
        if (typeof path !== 'undefined') {
            this.apisService.getRemoteData(1, path.maps)
                .subscribe(
                    (data: unknown[] | null | undefined) => {
                        this.resultAcsUsersSource.next(data);
                    },
                    error => {
                        console.error(error);
                    },
                );
        }
    }

    getAcsLogs(userId: number, month: string, year: number): Observable<AcsUserLogs[] | undefined> {
        const headers = new HttpHeaders({'Content-Type': 'application/json'});
        const params = new HttpParams()
            .set('user_id', userId.toString())
            .set('month', month)
            .set('year', year.toString());

        return this.http.get<AcsUserLogs[]>(this.acsLogUrl, {headers, params})
            .pipe(
                tap(() => {
                    this.log('fetched acs logs');
                }),
                catchError(this.handleError<AcsUserLogs[]>('getQueue', [])),
            );
    }

    getAcsCategories(): Observable<AcsCategory[] | undefined> {
        return this.http.get<AcsCategory[]>(this.acsCategoriesUrl)
            .pipe(
                tap(() => {
                    this.log('fetched acs categories');
                }),
                catchError(this.handleError<AcsCategory[]>('getQueue', [])),
            );
    }

    giveAcsState(logs: AcsUserLogs[] | undefined): {
        lastLog: AcsUserLogs | null;
        atWork: boolean;
    } {
        const state: {atWork: boolean; lastLog: AcsUserLogs | null} = {
            atWork: false,
            lastLog: null,
        };

        if (typeof logs !== 'undefined') {
            // eslint-disable-next-line max-len
            logs.map((log: AcsUserLogs) => {
                if (
                    !log.finished_at &&
                    !log.finished_at_real &&
                    moment(log.started_at)
                        .format('YYYY-MM-DD') === moment()
                        .format('YYYY-MM-DD')
                ) {
                    state.lastLog = log;
                    state.atWork = true;
                }
            });
        }

        return state;
    }

    /**
     * Handle Http operation that failed.
     * Let the app continue.
     * @param operation - name of the operation that failed
     * @param result - optional value to return as the observable result
     */
    private handleError<T>(operation = 'operation', result?: T) {
        return (error: {[key: string]: string}): Observable<T | undefined> => {
            // TODO: send the error to remote logging infrastructure
            console.error(error); // log to console instead

            // TODO: better job of transforming error for user consumption
            this.log(`${operation} failed: ${error.message}`);

            // Let the app keep running by returning an empty result.
            return of(result);
        };
    }

    /** Log a vacationService message with the MessageService */
    private log(message: string): void {
        this.messageService.addNotification(`Vacation: ${message}`, false);
    }
}
