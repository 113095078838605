import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule, Routes} from '@angular/router';
import {AuthGuard} from '@src/app/_guards/auth.guard';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {SignaturesCreatorService} from '@src/app/_services/signatures-creator.service';
import {UsersComponent} from '@src/app/features/users/components/users/users.component';
import {UsersListComponent} from '@src/app/features/users/components/users-list/users-list.component';
import {UsersDetailComponent} from '@src/app/features/users/components/users-detail/users-detail.component';
import {UserEditPasswordFormComponent} from '@src/app/features/users/_forms/user-edit-password-form/user-edit-password-form.component';
import {SettingsService} from '@src/app/features/settings/settings.service';
import {UsersService} from '@src/app/features/users/users.service';
import {SharedFeaturesModule} from '@src/app/shared_features/shared-features.module';
import {UserFormComponent} from './_forms/user-form/user-form.component';

const routes: Routes = [
    {
        path: 'users',
        children: [
            {
                path: '',
                component: UsersComponent,
                data: {
                    title: 'Uživatelé',
                    breadcrumb: [
                        {
                            label: 'Uživatelé',
                            url: '',
                        },
                    ],
                },
            },
            {
                path: 'list',
                component: UsersListComponent,
                data: {
                    title: 'Adresář',
                    breadcrumb: [
                        {
                            label: 'Uživatelé',
                            url: '',
                        },
                        {
                            label: 'Adresář',
                            url: '',
                        },
                    ],
                },
            },
            {
                path: 'detail/:id',
                component: UsersDetailComponent,
                data: {
                    title: 'Profil uživatele',
                    breadcrumb: [
                        {
                            label: 'Uživatelé',
                            url: '',
                        },
                        {
                            label: 'Adresář',
                            url: '/users/list',
                        },
                        {
                            label: 'Profil uživatele',
                            url: '',
                        },
                    ],
                },
            },
        ],
        canActivate: [AuthGuard],
    },
];

@NgModule({
    declarations: [
        UserEditPasswordFormComponent,
        UserFormComponent,
        UsersComponent,
        UsersDetailComponent,
        UsersListComponent,
    ],
    imports: [
        BrowserAnimationsModule,
        RouterModule.forChild(routes),
        BrowserModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        SharedFeaturesModule,
    ],
    exports: [UsersComponent, UsersDetailComponent, UsersListComponent],
    providers: [SettingsService, SignaturesCreatorService, UsersService],
})
export class UsersModule {}
