import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    HostListener,
    Input,
    OnChanges,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import {AbstractControl, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {Role} from '@src/app/_models/role/role';
import {ComponentCanDeactivate} from '@src/app/_guards/changes.guard';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {DialogComponent} from '@syncfusion/ej2-angular-popups';
import {Observable} from 'rxjs';
import {User} from '@src/app/_models/user/user';
import {AuthenticationService} from '@src/app/_services/authentication.service';
import {DataService} from '@src/app/_services/data.service';
import {RoleInput, SettingsService} from '@src/app/features/settings/settings.service';
import {UsersService} from '@src/app/features/users/users.service';
import {MessageService} from '@src/app/_services/message.service';

@UntilDestroy()
@Component({
    selector: 'app-role-form',
    templateUrl: './role-form.component.html',
    styleUrls: ['./role-form.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RoleFormComponent implements OnInit, OnChanges, ComponentCanDeactivate {
    // Forms
    roleForm: FormGroup;

    isDirty = false;

    isChecked = false;

    submited = false;

    // Variables
    currentUser: User | null;

    // Loaders
    loadingRole = false;

    @Input() role: Role | null | undefined = null;

    @Input() isCreate = false;

    @Input() isUpdate = false;

    @Input() isVisible = false;

    @Input() isCopy = false;

    @Output() readonly roleFormDialogState = new EventEmitter<boolean>();

    // Dialogs
    @ViewChild('formDialog') formDialogObj: DialogComponent;

    constructor(
        private readonly formBuilder: FormBuilder,
        private readonly route: ActivatedRoute,
        private readonly router: Router,
        private readonly dataService: DataService,
        private readonly settingsService: SettingsService,
        private readonly authenticationService: AuthenticationService,
        private readonly userService: UsersService,
        private readonly message: MessageService,
        private readonly ref: ChangeDetectorRef,
    ) {
        this.currentUser = this.authenticationService.currentUserValue;
    }

    get f(): {[key: string]: AbstractControl} {
        return this.roleForm.controls;
    }

    @HostListener('window:beforeunload')
    canDeactivate(): Observable<boolean> | boolean {
        return !this.isDirty;
    }

    ngOnInit(): void {
        this.roleForm = this.formBuilder.group({
            name: [
                '',
                Validators.compose([
                    Validators.required,
                    Validators.minLength(2),
                    Validators.maxLength(100),
                ]),
            ],
        });

        this.roleForm.valueChanges.pipe(untilDestroyed(this))
            .subscribe(() => {
                this.isDirty = this.roleForm.dirty;
                this.showInputErrors();
            });
    }

    isNameUnique(control: FormControl): Promise<{[key: string]: boolean} | null> | undefined {
        if (control.value) {
            return new Promise(resolve => {
                setTimeout(() => {
                    this.settingsService
                        .isCompanyNameRegistered(control.value as string)
                        .pipe(untilDestroyed(this))
                        .subscribe(
                            (data: boolean) => {
                                if (data) {
                                    resolve({
                                        taken: true,
                                    });
                                } else {
                                    resolve(null);
                                }
                            },
                            () => {
                                resolve(null);
                            },
                        );
                }, 1000);
            });
        }
    }

    ngOnChanges(): void {
        if (this.isVisible) {
            const queryParams: Params = {form: null};

            void this.router.navigate([], {
                relativeTo: this.route,
                queryParams,
                queryParamsHandling: 'merge',
            });

            if (this.isUpdate && this.role) {
                this.roleForm.reset();
                this.formDialogObj.header = `Pozice #${this.role.id} - ${this.role.name}`;
                this.fillTheForm();
                this.showInputErrors();
            }

            if (this.isCreate) {
                this.roleForm.reset();
                this.formDialogObj.header = 'Nová pozice';
            }

            if (this.isCopy) {
                this.roleForm.reset();
                this.formDialogObj.header = 'Nová pozice';
                this.fillTheForm();
                this.showInputErrors();
            }
        }
    }

    changeDialogState(value: boolean): void {
        this.roleFormDialogState.emit(value);
    }

    fillTheForm(): void {
        this.roleForm.controls.name.patchValue(this.role?.name);
    }

    onSubmit(): void {
        this.submited = true;
        this.isDirty = false;
        this.loadingRole = true;

        if (this.isCreate || this.isCopy) {
            this.createRole();
        }

        if (this.isUpdate) {
            this.editRole();
        }
    }

    createRole(): void {
        this.loadingRole = true;
        this.ref.markForCheck();

        if (this.roleForm.invalid) {
            console.error('form is not valid!');

            return;
        }

        const role = {
            name: this.f.name.value,
        };

        this.settingsService
            .addRole(role)
            .pipe(untilDestroyed(this))
            .subscribe(
                () => {
                    const body = 'Úspěšně jste vytvořili uživatelskou pozici';
                    const options = {
                        progressBar: true,
                        timeOut: 5000,
                        toastClass: 'success',
                    };

                    this.message.showSuccess('Pozice vytvořena', body, options);
                    this.dataService.setRoleDataSource();
                    this.isCreate = false;
                    this.loadingRole = false;
                    this.ref.markForCheck();
                    this.formDialogObj.hide();
                },
                error => {
                    console.error(error);

                    const body = 'Zkuste to znovu později';
                    const options = {
                        progressBar: true,
                        timeOut: 5000,
                        toastClass: 'red',
                    };

                    this.message.showError('Chyba při vytváření pozice', body, options);
                    this.loadingRole = false;
                    this.ref.markForCheck();
                },
            );
    }

    editRole(): void {
        this.loadingRole = true;
        this.ref.markForCheck();

        if (this.roleForm.invalid || !this.role) {
            console.error('form is not valid! || this.role is missing...');

            return;
        }

        const role: RoleInput = {
            name: this.f.name.value,
        };

        this.settingsService
            .editRole(role)
            .pipe(untilDestroyed(this))
            .subscribe(
                () => {
                    const body = 'Úspěšně jste upravil/a pozici';
                    const options = {
                        progressBar: true,
                        timeOut: 5000,
                        toastClass: 'success',
                    };

                    this.message.showSuccess('Pozice upravnea', body, options);
                    this.dataService.setRoleDataSource();
                    this.isUpdate = false;
                    this.loadingRole = false;
                    this.ref.markForCheck();
                    this.formDialogObj.hide();
                },
                error => {
                    console.error(error);

                    const body = 'Zkuste to znovu později';
                    const options = {
                        progressBar: true,
                        timeOut: 5000,
                        toastClass: 'red',
                    };

                    this.message.showError('Chyba při úpravě pozice', body, options);
                    this.loadingRole = false;
                    this.ref.markForCheck();
                },
            );
    }

    showInputErrors(): void {
        this.isChecked = true;
        /* console.log('VALID: ' + this.ticketForm.valid);
        Object.keys(this.ticketForm.controls).forEach(key => {
            const controlErrors: ValidationErrors = this.ticketForm.get(key).errors;
            if (controlErrors != null) {
                Object.keys(controlErrors).forEach(keyError => {
                    console.log('Key control: ' + key + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError]);
                });
            }
        });*/
        this.roleForm.markAllAsTouched();
        this.ref.markForCheck();
    }
}
