<ejs-dialog
  #formDialog
  (close)="
        isUpdate = false;
        isCreate = false;
        isDirty = false;
        isChecked = false;
        isCopy = false;
        submited = false;
        changeDialogState(false)
    "
  [isModal]="true"
  [showCloseIcon]="true"
  [visible]="isVisible"
  [width]="'85%'"
  id="formDialog"
>
  <form [formGroup]="hotlineForm">
    <ng-template #content>
      <div class="dialogContent">
        <div class="row" formArrayName="hotlines">
          <div
            *ngFor="
                            let hotline of hotlineformGroup.controls;
                            let i = index
                        "
            class="col-12"
          >
            <div [formGroupName]="i" class="row p-0 m-0">
              <div class="form-group col-12 col-md-6 mb-1">
                <label
                  [ngClass]="
                                        getHotlineFormGroup(i).controls[
                                            'start_at'
                                        ].valid
                                            ? 'text-success'
                                            : getHotlineFormGroup(i).controls[
                                                  'start_at'
                                              ].invalid
                                            ? 'text-danger'
                                            : 'text-muted'
                                    "
                  for="hortlineForm-start_at"
                >* Začátek termínu:</label
                >
                <ejs-datetimepicker
                  [cssClass]="
                                        (getHotlineFormGroup(i).controls[
                                            'start_at'
                                        ].dirty ||
                                            getHotlineFormGroup(i).controls[
                                                'start_at'
                                            ].touched) &&
                                        getHotlineFormGroup(i).controls[
                                            'start_at'
                                        ].invalid
                                            ? 'e-error'
                                            : getHotlineFormGroup(i).controls[
                                                  'start_at'
                                              ].valid
                                            ? 'e-success'
                                            : ''
                                    "
                  [firstDayOfWeek]="1"
                  floatLabelType="Always"
                  formControlName="start_at"
                  id="hortlineForm-start_at"
                >
                </ejs-datetimepicker>
                <div class="d-flex justify-content-end">
                  <small
                    *ngIf="
                                            (getHotlineFormGroup(i).controls[
                                                'start_at'
                                            ].dirty ||
                                                getHotlineFormGroup(i).controls[
                                                    'start_at'
                                                ].touched) &&
                                            getHotlineFormGroup(i).controls[
                                                'end_at'
                                            ].errors &&
                                            getHotlineFormGroup(i).controls[
                                                'start_at'
                                            ].hasError('required')
                                        "
                    class="text-danger"
                  >Datum začátku termínu hot-line je
                    povinné</small
                  >
                  <small
                    *ngIf="
                                            getHotlineFormGroup(i).controls[
                                                'start_at'
                                            ].touched &&
                                            getHotlineFormGroup(i).controls[
                                                'start_at'
                                            ].errors &&
                                            getHotlineFormGroup(i).controls[
                                                'start_at'
                                            ].hasError('greaterThan')
                                        "
                    class="text-danger"
                  >Datum začátku termínu hot-line je větší
                    než datum konce</small
                  >
                </div>
              </div>
              <div class="form-group col-12 col-md-6 mb-1">
                <label
                  [ngClass]="
                                        getHotlineFormGroup(i).controls[
                                            'end_at'
                                        ].valid
                                            ? 'text-success'
                                            : getHotlineFormGroup(i).controls[
                                                  'end_at'
                                              ].invalid
                                            ? 'text-danger'
                                            : 'text-muted'
                                    "
                  for="hortlineForm-end_at"
                >* Konec termínu:</label
                >
                <ejs-datetimepicker
                  [cssClass]="
                                        (getHotlineFormGroup(i).controls[
                                            'end_at'
                                        ].dirty ||
                                            getHotlineFormGroup(i).controls[
                                                'end_at'
                                            ].touched) &&
                                        getHotlineFormGroup(i).controls[
                                            'end_at'
                                        ].invalid
                                            ? 'e-error'
                                            : getHotlineFormGroup(i).controls[
                                                  'end_at'
                                              ].valid
                                            ? 'e-success'
                                            : ''
                                    "
                  [firstDayOfWeek]="1"
                  [min]="
                                        getHotlineFormGroup(i).controls[
                                            'start_at'
                                        ].value
                                            ? getHotlineFormGroup(i).controls[
                                                  'start_at'
                                              ].value
                                            : ''
                                    "
                  [value]="
                                        getHotlineFormGroup(i).controls[
                                            'start_at'
                                        ].value
                                    "
                  floatLabelType="Always"
                  formControlName="end_at"
                  id="hortlineForm-end_at"
                >
                </ejs-datetimepicker>
                <div class="d-flex justify-content-end">
                  <small
                    *ngIf="
                                            (getHotlineFormGroup(i).controls[
                                                'end_at'
                                            ].dirty ||
                                                getHotlineFormGroup(i).controls[
                                                    'end_at'
                                                ].touched) &&
                                            getHotlineFormGroup(i).controls[
                                                'end_at'
                                            ].errors &&
                                            getHotlineFormGroup(i).controls[
                                                'end_at'
                                            ].hasError('required')
                                        "
                    class="text-danger"
                  >Datum konce termínu hot-line je
                    povinné</small
                  >
                  <small
                    *ngIf="
                                            getHotlineFormGroup(i).controls[
                                                'end_at'
                                            ].touched &&
                                            getHotlineFormGroup(i).controls[
                                                'end_at'
                                            ].errors &&
                                            getHotlineFormGroup(i).controls[
                                                'end_at'
                                            ].hasError('lessThan')
                                        "
                    class="text-danger"
                  >Datum konce termínu hot-line je menší
                    než datum začátku</small
                  >
                </div>
              </div>
              <div class="form-group col-12 col-md-6 mb-1">
                <label
                  [ngClass]="
                                        getHotlineFormGroup(i).controls[
                                            'hotliner_id'
                                        ].valid
                                            ? 'text-success'
                                            : getHotlineFormGroup(i).controls[
                                                  'hotliner_id'
                                              ].invalid
                                            ? 'text-danger'
                                            : 'text-muted'
                                    "
                  for="hotliner_id"
                >* Hot-liner:</label
                >
                <ejs-dropdownlist
                  #hotliner
                  (filtering)="onFilteringHotliner($event)"
                  [allowFiltering]="true"
                  [dataSource]="hotlinerSelect"
                  [fields]="fields"
                  [filterBarPlaceholder]="
                                        'Vyhledat uživatele...'
                                    "
                  [ignoreAccent]="true"
                  [ngClass]="
                                        (getHotlineFormGroup(i).controls[
                                            'hotliner_id'
                                        ].dirty ||
                                            getHotlineFormGroup(i).controls[
                                                'hotliner_id'
                                            ].touched) &&
                                        getHotlineFormGroup(i).controls[
                                            'hotliner_id'
                                        ].invalid
                                            ? 'e-error'
                                            : (getHotlineFormGroup(i).controls[
                                                  'hotliner_id'
                                              ].dirty ||
                                                  getHotlineFormGroup(i)
                                                      .controls['hotliner_id']
                                                      .touched) &&
                                              getHotlineFormGroup(i).controls[
                                                  'hotliner_id'
                                              ].valid
                                            ? 'e-success'
                                            : ''
                                    "
                  [placeholder]="'Vyberte uživatele'"
                  [popupHeight]="height"
                  formControlName="hotliner_id"
                  id="hotliner_id"
                  sortOrder="Ascending"
                ></ejs-dropdownlist>
                <small
                  *ngIf="
                                        getHotlineFormGroup(i).controls[
                                            'hotliner_id'
                                        ].touched &&
                                        getHotlineFormGroup(i).controls[
                                            'hotliner_id'
                                        ].errors &&
                                        getHotlineFormGroup(i).controls[
                                            'hotliner_id'
                                        ].hasError('required')
                                    "
                  class="text-danger"
                >Vyberte jednoho z hotlinerů</small
                >
              </div>
              <div class="form-group col-12 col-md-6 mb-1">
                <label
                  [ngClass]="
                                        getHotlineFormGroup(i).controls['type']
                                            .valid
                                            ? 'text-success'
                                            : getHotlineFormGroup(i).controls[
                                                  'type'
                                              ].invalid
                                            ? 'text-danger'
                                            : 'text-muted'
                                    "
                  for="type"
                >* Typ hot-line:</label
                >
                <ejs-dropdownlist
                  #category
                  (filtering)="onFilteringCategories($event)"
                  [allowFiltering]="true"
                  [dataSource]="typeSelect"
                  [fields]="fields"
                  [filterBarPlaceholder]="
                                        'Vyhledat typ služby...'
                                    "
                  [ignoreAccent]="true"
                  [placeholder]="'Vyberte typ služnby'"
                  [popupHeight]="height"
                  formControlName="type"
                  id="type"
                  sortOrder="Ascending"
                ></ejs-dropdownlist>
                <small
                  *ngIf="
                                        getHotlineFormGroup(i).controls['type']
                                            .touched &&
                                        getHotlineFormGroup(i).controls['type']
                                            .errors &&
                                        getHotlineFormGroup(i).controls[
                                            'type'
                                        ].hasError('required')
                                    "
                  class="text-danger"
                >Vyberte jeden z typů</small
                >
              </div>
              <div class="form-group col-8 mb-1">
                <label
                  [ngClass]="
                                        getHotlineFormGroup(i).controls[
                                            'company_id'
                                        ].valid
                                            ? 'text-success'
                                            : getHotlineFormGroup(i).controls[
                                                  'company_id'
                                              ].invalid
                                            ? 'text-danger'
                                            : 'text-muted'
                                    "
                  for="company_id"
                >* Společnost:</label
                >
                <ejs-dropdownlist
                  #company
                  (filtering)="onFilteringCompanies($event)"
                  [allowFiltering]="true"
                  [dataSource]="companySelect"
                  [fields]="fields"
                  [filterBarPlaceholder]="
                                        'Vyhledat společnost...'
                                    "
                  [ignoreAccent]="true"
                  [ngClass]="
                                        (getHotlineFormGroup(i).controls[
                                            'company_id'
                                        ].dirty ||
                                            getHotlineFormGroup(i).controls[
                                                'company_id'
                                            ].touched) &&
                                        getHotlineFormGroup(i).controls[
                                            'company_id'
                                        ].invalid
                                            ? 'e-error'
                                            : (getHotlineFormGroup(i).controls[
                                                  'company_id'
                                              ].dirty ||
                                                  getHotlineFormGroup(i)
                                                      .controls['company_id']
                                                      .touched) &&
                                              getHotlineFormGroup(i).controls[
                                                  'company_id'
                                              ].valid
                                            ? 'e-success'
                                            : ''
                                    "
                  [placeholder]="'Vyberte společnost'"
                  [popupHeight]="height"
                  formControlName="company_id"
                  id="company_id"
                  sortOrder="Ascending"
                ></ejs-dropdownlist>
                <small
                  *ngIf="
                                        getHotlineFormGroup(i).controls[
                                            'company_id'
                                        ].touched &&
                                        getHotlineFormGroup(i).controls[
                                            'company_id'
                                        ].errors &&
                                        getHotlineFormGroup(i).controls[
                                            'company_id'
                                        ].hasError('required')
                                    "
                  class="text-danger"
                >Vyberte jednu ze společností</small
                >
              </div>
              <div class="form-group col-4 mb-1 text-right">
                <button
                  (click)="removeHotlineInput(i)"
                  [disabled]="isUpdate"
                  cssClass="e-btn e-danger btn-p-zero e-small"
                  ejs-button
                  type="button"
                >
                  Odebrat
                </button>
              </div>
            </div>
            <hr />
          </div>
          <div class="col-12">
            <button
              (click)="addHotlineInput()"
              [disabled]="isUpdate"
              cssClass="e-btn e-success btn-p-zero e-small"
              ejs-button
              type="button"
            >
                            <span
                              *ngIf="hotlineLoading === true"
                              aria-hidden="true"
                              class="spinner-grow spinner-grow-sm"
                              role="status"
                            ></span>
              <span *ngIf="hotlineLoading === false"
              >Přidat hot-line</span
              >
              <span *ngIf="hotlineLoading === true"
              >Provádím...</span
              >
            </button>
          </div>
        </div>
      </div>
    </ng-template>
    <ng-template #footerTemplate>
      <div class="d-flex justify-content-end">
                <span
                  *ngIf="hotlineForm.invalid"
                  class="e-badge e-badge-warning p-2 size_2"
                  role="alert"
                >
                    <strong>Formulář je špatně vyplněný</strong>
                </span>
        <span
          *ngIf="hotlineForm.valid"
          class="e-badge e-badge-success p-2 size_2"
          role="alert"
        >
                    <strong>Formulář je správně vyplněný</strong>
                </span>
      </div>
      <div class="row">
        <div class="col-12 pt-2">
          <button
            (click)="onSubmit()"
            [disabled]="
                            hotlineLoading || hotlineForm.invalid || !isChecked
                        "
            cssClass="e-btn e-success btn-p-zero e-small mr-1"
            ejs-button
            type="submit"
          >
                        <span
                          *ngIf="hotlineLoading === true"
                          aria-hidden="true"
                          class="spinner-grow spinner-grow-sm"
                          role="status"
                        ></span>
            <span *ngIf="hotlineLoading === false">ULOŽIT</span>
            <span *ngIf="hotlineLoading === true">Provádím...</span>
          </button>
          <button
            (click)="formDialogObj.hide()"
            cssClass="e-btn e-danger e-outline btn-p-zero e-small"
            ejs-button
            type="button"
          >
            ZRUŠIT
          </button>
        </div>
      </div>
    </ng-template>
  </form>
</ejs-dialog>
