<ejs-dialog
  #formDialog
  (close)="
        isUpdate = false;
        isCreate = false;
        isDirty = false;
        isChecked = false;
        submited = false;
        changeDialogState(false)
    "
  (open)="onCreateEventRte(); onCreateTicketRte()"
  [isModal]="true"
  [showCloseIcon]="true"
  [visible]="isVisible"
  [width]="'85%'"
  id="formDialog"
>
  <form [formGroup]="bookingForm">
    <ng-template #content>
      <div class="dialogContent">
        <div class="tab mb-3" id="tab0">
          <div class="row mb-3">
            <div class="col-12">
              <button
                #officeButton
                (click)="
                                    toggleReservationType('office');
                                    carButton.cssClass = 'e-outline'
                                "
                [ngClass]="
                                    reservationType === 'office'
                                        ? 'e-filled'
                                        : 'e-outline'
                                "
                class="e-btn e-round-corner e-primary btn-p-zero e-small"
                ejs-button
                title="rezervovat kancelář"
                type="button"
              >
                <i
                  aria-hidden="true"
                  class="fa-light fa-lg fa-building"
                ></i
                >&nbsp;rezervovat kancelář
              </button>
              <button
                #carButton
                (click)="
                                    toggleReservationType('car');
                                    officeButton.cssClass = 'e-outline'
                                "
                [ngClass]="
                                    reservationType === 'car'
                                        ? 'e-filled'
                                        : 'e-outline'
                                "
                class="e-btn e-round-corner e-primary btn-p-zero e-small"
                ejs-button
                title="rezervovat automobil"
                type="button"
              >
                <i
                  aria-hidden="true"
                  class="fa-light fa-lg fa-car"
                ></i
                >&nbsp;rezervovat automobil
              </button>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-md-6 mb-2 form-group">
              <label
                [ngClass]="
                                    f.start.valid
                                        ? 'text-success'
                                        : f.start.invalid
                                        ? 'text-danger'
                                        : 'text-muted'
                                "
                for="start"
              >* Začátek události:</label
              >
              <ejs-datetimepicker
                [firstDayOfWeek]="1"
                [min]="todayLimit"
                floatLabelType="Always"
                formControlName="start"
                id="start"
              >
              </ejs-datetimepicker>
              <div class="d-flex justify-content-end">
                <small
                  *ngIf="
                                        f.start.touched &&
                                        f.start.errors &&
                                        f.start.errors.required
                                    "
                  class="text-danger"
                >Datum začátku události je povinné</small
                >
                <small
                  *ngIf="
                                        f.start.touched &&
                                        f.start.errors &&
                                        f.start.errors.greaterThan
                                    "
                  class="text-danger"
                >Datum začátku události je větší než datum
                  konce</small
                >
              </div>
            </div>
            <div class="col-12 col-md-6 mb-2 form-group">
              <label
                [ngClass]="
                                    f.end.valid
                                        ? 'text-success'
                                        : f.end.invalid
                                        ? 'text-danger'
                                        : 'text-muted'
                                "
                for="end"
              >* Konec události:</label
              >
              <ejs-datetimepicker
                (focusin)="fillSuggestedDate()"
                [firstDayOfWeek]="1"
                [min]="f.start.value ? f.start.value : ''"
                floatLabelType="Always"
                formControlName="end"
                id="end"
              >
              </ejs-datetimepicker>
              <div class="d-flex justify-content-end">
                <small
                  *ngIf="
                                        f.end.touched &&
                                        f.end.errors &&
                                        f.end.errors.required
                                    "
                  class="text-danger"
                >Datum konce události je povinné</small
                >
                <small
                  *ngIf="
                                        f.end.touched &&
                                        f.end.errors &&
                                        f.end.errors.lessThan
                                    "
                  class="text-danger"
                >Datum konce události je menší než datum
                  začátku</small
                >
              </div>
            </div>
          </div>
          <div *ngIf="reservationType === 'car'" class="row">
            <div class="form-group col-12">
              <label
                [ngClass]="
                                    f.car_id.valid
                                        ? 'text-success'
                                        : f.car_id.invalid
                                        ? 'text-danger'
                                        : 'text-muted'
                                "
                for="car_id"
              >Vozidlo:</label
              >
              <ejs-dropdownlist
                #carList
                (filtering)="onFilteringCars($event)"
                [allowFiltering]="true"
                [dataSource]="carsSelect"
                [enabled]="
                                    (currentUser.rule &&
                                        (currentUser.rule.is_vacation_admin ||
                                            currentUser.rule.is_admin)) ||
                                    currentUser.is_boss
                                "
                [fields]="fields"
                [filterBarPlaceholder]="'Vyhledat vozidlo...'"
                [ignoreAccent]="true"
                [placeholder]="'Vyberte vozidlo'"
                [popupHeight]="height"
                formControlName="car_id"
                id="car_id"
                sortOrder="Ascending"
              ></ejs-dropdownlist>
              <small
                *ngIf="
                                    f.car_id.touched &&
                                    f.car_id.errors &&
                                    f.car_id.errors.required
                                "
                class="text-danger"
              >Vůz je povinný</small
              >
            </div>
          </div>
          <div *ngIf="reservationType === 'office'" class="row">
            <div class="form-group col-12">
              <label
                [ngClass]="
                                    f.office_id.valid
                                        ? 'text-success'
                                        : f.office_id.invalid
                                        ? 'text-danger'
                                        : 'text-muted'
                                "
                for="office_id"
              >Kancelář:</label
              >
              <ejs-dropdownlist
                #officeList
                (filtering)="onFilteringOffices($event)"
                [allowFiltering]="true"
                [dataSource]="officesSelect"
                [fields]="fields"
                [filterBarPlaceholder]="'Vyhledat kancelář...'"
                [ignoreAccent]="true"
                [placeholder]="'Vyberte kancelář'"
                [popupHeight]="height"
                formControlName="office_id"
                id="office_id"
                sortOrder="Ascending"
              ></ejs-dropdownlist>
              <small
                *ngIf="
                                    f.office_id.touched &&
                                    f.office_id.errors &&
                                    f.office_id.errors.required
                                "
                class="text-danger"
              >Kancelář je povinná</small
              >
            </div>
          </div>
          <div class="row">
            <div class="form-group col-12">
              <label
                [ngClass]="
                                    f.reason.valid
                                        ? 'text-success'
                                        : f.reason.invalid
                                        ? 'text-danger'
                                        : 'text-muted'
                                "
                for="reason"
              >* Důvod rezervace:</label
              >
              <ejs-textbox
                [cssClass]="
                                    f.reason.invalid
                                        ? 'e-error'
                                        : f.reason.valid
                                        ? 'e-success'
                                        : ''
                                "
                floatLabelType="Auto"
                formControlName="reason"
                id="reason"
              ></ejs-textbox>
              <small
                *ngIf="
                                    f.reason.touched &&
                                    f.reason.errors &&
                                    f.reason.errors.required
                                "
                class="text-danger"
              >Důvod rezervace je povinný</small
              >
            </div>
          </div>
          <div class="row mt-3">
            <div class="e-card">
              <div
                [ngClass]="emptyEvents ? 'text-danger' : ''"
                class="card-header"
              >
                <h6>Přidat událost k rezervaci</h6>
                <button
                  (click)="addEvent()"
                  cssClass="e-btn e-success btn-p-zero e-small mr-1"
                  ejs-button
                  type="button"
                >
                  Přidat událost
                </button>
                <ng-container *ngIf="emptyEvents">
                  <br />
                  <small class="text-danger">
                    Rezervace musí obsahovat alespoň jednu
                    událost!
                  </small>
                </ng-container>
              </div>
              <div
                class="e-card-content"
                formArrayName="bookEvents"
              >
                <div class="row p-3">
                  <div
                    *ngFor="
                                            let f of bookEventsFormGroup.controls;
                                            let i = index
                                        "
                    class="col-12"
                  >
                    <div [formGroupName]="i" class="row">
                      <div class="col-12 col-md-11">
                        <div class="row">
                          <div
                            class="col-12 col-md-6 mb-2 form-group"
                          >
                            <label
                              [ngClass]="
                                                                getBookEventsFormGroup(
                                                                    i
                                                                ).controls[
                                                                    'start_at'
                                                                ].valid
                                                                    ? 'text-success'
                                                                    : getBookEventsFormGroup(
                                                                          i
                                                                      )
                                                                          .controls[
                                                                          'start_at'
                                                                      ].invalid
                                                                    ? 'text-danger'
                                                                    : 'text-muted'
                                                            "
                              for="createEventForm-start_at"
                            >* Začátek
                              události:</label
                            >
                            <ejs-datetimepicker
                              [firstDayOfWeek]="1"
                              [max]="
                                                                getBookEventsFormGroup(
                                                                    i
                                                                ).controls[
                                                                    'end_at'
                                                                ].value
                                                                    ? getBookEventsFormGroup(
                                                                          i
                                                                      )
                                                                          .controls[
                                                                          'end_at'
                                                                      ].value
                                                                    : ''
                                                            "
                              floatLabelType="Always"
                              formControlName="start_at"
                              id="createEventForm-start_at"
                            >
                            </ejs-datetimepicker>
                            <div
                              class="d-flex justify-content-end"
                            >
                              <small
                                *ngIf="
                                                                    getBookEventsFormGroup(
                                                                        i
                                                                    ).controls[
                                                                        'start_at'
                                                                    ].touched &&
                                                                    getBookEventsFormGroup(
                                                                        i
                                                                    ).controls[
                                                                        'start_at'
                                                                    ].errors &&
                                                                    getBookEventsFormGroup(
                                                                        i
                                                                    ).controls[
                                                                        'start_at'
                                                                    ].errors
                                                                        .required
                                                                "
                                class="text-danger"
                              >Datum začátku
                                události je
                                povinné</small
                              >
                              <small
                                *ngIf="
                                                                    getBookEventsFormGroup(
                                                                        i
                                                                    ).controls[
                                                                        'start_at'
                                                                    ].touched &&
                                                                    getBookEventsFormGroup(
                                                                        i
                                                                    ).controls[
                                                                        'start_at'
                                                                    ].errors &&
                                                                    getBookEventsFormGroup(
                                                                        i
                                                                    ).controls[
                                                                        'start_at'
                                                                    ].errors
                                                                        .greaterThan
                                                                "
                                class="text-danger"
                              >Datum začátku
                                události je
                                větší než datum
                                konce</small
                              >
                            </div>
                          </div>
                          <div
                            class="col-12 col-md-6 mb-2 form-group"
                          >
                            <label
                              [ngClass]="
                                                                getBookEventsFormGroup(
                                                                    i
                                                                ).controls[
                                                                    'end_at'
                                                                ].valid
                                                                    ? 'text-success'
                                                                    : getBookEventsFormGroup(
                                                                          i
                                                                      )
                                                                          .controls[
                                                                          'end_at'
                                                                      ].invalid
                                                                    ? 'text-danger'
                                                                    : 'text-muted'
                                                            "
                              for="createEventForm-end_at"
                            >* Konec
                              události:</label
                            >
                            <ejs-datetimepicker
                              [firstDayOfWeek]="1"
                              [min]="
                                                                getBookEventsFormGroup(
                                                                    i
                                                                ).controls[
                                                                    'start_at'
                                                                ].value
                                                                    ? getBookEventsFormGroup(
                                                                          i
                                                                      )
                                                                          .controls[
                                                                          'start_at'
                                                                      ].value
                                                                    : ''
                                                            "
                              floatLabelType="Always"
                              formControlName="end_at"
                              id="createEventForm-end_at"
                            >
                            </ejs-datetimepicker>
                            <div
                              class="d-flex justify-content-end"
                            >
                              <small
                                *ngIf="
                                                                    getBookEventsFormGroup(
                                                                        i
                                                                    ).controls[
                                                                        'end_at'
                                                                    ].touched &&
                                                                    getBookEventsFormGroup(
                                                                        i
                                                                    ).controls[
                                                                        'end_at'
                                                                    ].errors &&
                                                                    getBookEventsFormGroup(
                                                                        i
                                                                    ).controls[
                                                                        'end_at'
                                                                    ].errors
                                                                        .required
                                                                "
                                class="text-danger"
                              >Datum konce
                                události je
                                povinné</small
                              >
                              <small
                                *ngIf="
                                                                    getBookEventsFormGroup(
                                                                        i
                                                                    ).controls[
                                                                        'end_at'
                                                                    ].touched &&
                                                                    getBookEventsFormGroup(
                                                                        i
                                                                    ).controls[
                                                                        'end_at'
                                                                    ].errors &&
                                                                    getBookEventsFormGroup(
                                                                        i
                                                                    ).controls[
                                                                        'end_at'
                                                                    ].errors
                                                                        .lessThan
                                                                "
                                class="text-danger"
                              >Datum konce
                                události je
                                menší než datum
                                začátku</small
                              >
                            </div>
                          </div>
                          <div
                            class="col-12 mb-2 form-group"
                          >
                            <label
                              [ngClass]="
                                                                getBookEventsFormGroup(
                                                                    i
                                                                ).controls[
                                                                    'subject'
                                                                ].valid
                                                                    ? 'text-success'
                                                                    : getBookEventsFormGroup(
                                                                          i
                                                                      )
                                                                          .controls[
                                                                          'subject'
                                                                      ]
                                                                          .invalid &&
                                                                      (getBookEventsFormGroup(
                                                                          i
                                                                      )
                                                                          .controls[
                                                                          'subject'
                                                                      ].dirty ||
                                                                          getBookEventsFormGroup(
                                                                              i
                                                                          )
                                                                              .controls[
                                                                              'subject'
                                                                          ]
                                                                              .touched)
                                                                    ? 'text-danger'
                                                                    : 'text-muted'
                                                            "
                              for="createEventForm-subject"
                            >Předmět:</label
                            >
                            <ejs-textbox
                              [ngClass]="{
                                                                'e-error':
                                                                    submited &&
                                                                    getBookEventsFormGroup(
                                                                        i
                                                                    ).controls[
                                                                        'subject'
                                                                    ].errors
                                                            }"
                              floatLabelType="Auto"
                              formControlName="subject"
                              id="createEventForm-subject"
                            ></ejs-textbox>
                            <small
                              *ngIf="
                                                                (getBookEventsFormGroup(
                                                                    i
                                                                ).controls[
                                                                    'subject'
                                                                ].dirty ||
                                                                    getBookEventsFormGroup(
                                                                        i
                                                                    ).controls[
                                                                        'subject'
                                                                    ]
                                                                        .touched) &&
                                                                getBookEventsFormGroup(
                                                                    i
                                                                ).controls[
                                                                    'subject'
                                                                ].errors &&
                                                                getBookEventsFormGroup(
                                                                    i
                                                                ).controls[
                                                                    'subject'
                                                                ].errors
                                                                    .required
                                                            "
                              class="text-danger"
                            >Vyplňte předmět
                              události</small
                            >
                          </div>
                          <div
                            class="col-12 mb-2 form-group"
                          >
                            <label
                              [ngClass]="
                                                                getBookEventsFormGroup(
                                                                    i
                                                                ).controls[
                                                                    'address'
                                                                ].valid
                                                                    ? 'text-success'
                                                                    : getBookEventsFormGroup(
                                                                          i
                                                                      )
                                                                          .controls[
                                                                          'address'
                                                                      ]
                                                                          .invalid &&
                                                                      (getBookEventsFormGroup(
                                                                          i
                                                                      )
                                                                          .controls[
                                                                          'address'
                                                                      ].dirty ||
                                                                          getBookEventsFormGroup(
                                                                              i
                                                                          )
                                                                              .controls[
                                                                              'address'
                                                                          ]
                                                                              .touched)
                                                                    ? 'text-danger'
                                                                    : 'text-muted'
                                                            "
                              for="createEventForm-address"
                            >Adresa:</label
                            >
                            <ejs-textbox
                              [ngClass]="{
                                                                'e-error':
                                                                    submited &&
                                                                    getBookEventsFormGroup(
                                                                        i
                                                                    ).controls[
                                                                        'address'
                                                                    ].errors
                                                            }"
                              autocomplete="smartform-auto-address"
                              class="smartform-whole-address smartform-instance-create"
                              floatLabelType="Auto"
                              formControlName="address"
                              id="createEventForm-address"
                            ></ejs-textbox>
                            <small
                              *ngIf="
                                                                (getBookEventsFormGroup(
                                                                    i
                                                                ).controls[
                                                                    'address'
                                                                ].dirty ||
                                                                    getBookEventsFormGroup(
                                                                        i
                                                                    ).controls[
                                                                        'address'
                                                                    ]
                                                                        .touched) &&
                                                                getBookEventsFormGroup(
                                                                    i
                                                                ).controls[
                                                                    'address'
                                                                ].errors &&
                                                                getBookEventsFormGroup(
                                                                    i
                                                                ).controls[
                                                                    'address'
                                                                ].errors
                                                                    .required
                                                            "
                              class="text-danger"
                            >Vyplňte adresu
                              události</small
                            >
                          </div>
                          <div
                            class="col-12 mb-2 form-group"
                          >
                            <label
                              [ngClass]="
                                                                getBookEventsFormGroup(
                                                                    i
                                                                ).controls[
                                                                    'description'
                                                                ].valid
                                                                    ? 'text-success'
                                                                    : getBookEventsFormGroup(
                                                                          i
                                                                      )
                                                                          .controls[
                                                                          'description'
                                                                      ]
                                                                          .invalid &&
                                                                      (getBookEventsFormGroup(
                                                                          i
                                                                      )
                                                                          .controls[
                                                                          'description'
                                                                      ].dirty ||
                                                                          getBookEventsFormGroup(
                                                                              i
                                                                          )
                                                                              .controls[
                                                                              'description'
                                                                          ]
                                                                              .touched)
                                                                    ? 'text-danger'
                                                                    : 'text-muted'
                                                            "
                              for="createEventForm-description"
                            >Popis události:
                            </label>
                            <ejs-richtexteditor
                              #eventDescription
                              (actionBegin)="
                                                                handleFullScreenEvent(
                                                                    $event
                                                                )
                                                            "
                              (actionComplete)="
                                                                actionCompleteHandlerEvent(
                                                                    $event
                                                                )
                                                            "
                              [enablePersistence]="
                                                                false
                                                            "
                              [fontFamily]="
                                                                fontFamily
                                                            "
                              [insertImageSettings]="
                                                                insertImageSettings
                                                            "
                              [maxLength]="
                                                                maxLength
                                                            "
                              [ngClass]="
                                                                getBookEventsFormGroup(
                                                                    i
                                                                ).controls[
                                                                    'description'
                                                                ].touched &&
                                                                getBookEventsFormGroup(
                                                                    i
                                                                ).controls[
                                                                    'description'
                                                                ].invalid
                                                                    ? 'e-error'
                                                                    : getBookEventsFormGroup(
                                                                          i
                                                                      )
                                                                          .controls[
                                                                          'description'
                                                                      ]
                                                                          .touched &&
                                                                      getBookEventsFormGroup(
                                                                          i
                                                                      )
                                                                          .controls[
                                                                          'description'
                                                                      ].valid
                                                                    ? 'e-success'
                                                                    : ''
                                                            "
                              [pasteCleanupSettings]="
                                                                pasteCleanupSettings
                                                            "
                              [quickToolbarSettings]="
                                                                quickToolbarSettings
                                                            "
                              [showCharCount]="
                                                                true
                                                            "
                              [toolbarSettings]="
                                                                tools
                                                            "
                              formControlName="description"
                              id="createEventForm-description"
                              locale="cs"
                              placeholder="Začněte psát popis události..."
                            >
                            </ejs-richtexteditor>
                            <small
                              *ngIf="
                                                                getBookEventsFormGroup(
                                                                    i
                                                                ).controls[
                                                                    'description'
                                                                ].touched &&
                                                                getBookEventsFormGroup(
                                                                    i
                                                                ).controls[
                                                                    'description'
                                                                ].errors &&
                                                                getBookEventsFormGroup(
                                                                    i
                                                                ).controls[
                                                                    'description'
                                                                ].errors
                                                                    .required
                                                            "
                              class="text-danger"
                            >Popis události je
                              povinné</small
                            >
                          </div>
                          <div
                            class="col-12 col-md-6 mb-2 form-group"
                          >
                            <label
                              [ngClass]="
                                                                getBookEventsFormGroup(
                                                                    i
                                                                ).controls[
                                                                    'users'
                                                                ].valid
                                                                    ? 'text-success'
                                                                    : getBookEventsFormGroup(
                                                                          i
                                                                      )
                                                                          .controls[
                                                                          'users'
                                                                      ]
                                                                          .invalid &&
                                                                      (getBookEventsFormGroup(
                                                                          i
                                                                      )
                                                                          .controls[
                                                                          'users'
                                                                      ].dirty ||
                                                                          getBookEventsFormGroup(
                                                                              i
                                                                          )
                                                                              .controls[
                                                                              'users'
                                                                          ]
                                                                              .touched)
                                                                    ? 'text-danger'
                                                                    : 'text-muted'
                                                            "
                              for="createEventForm-users"
                            >Zapojit
                              uživatele:</label
                            >
                            <ejs-multiselect
                              #users
                              (filtering)="
                                                                onFilteringUsers(
                                                                    $event
                                                                )
                                                            "
                              [allowFiltering]="
                                                                true
                                                            "
                              [dataSource]="
                                                                usersSelect
                                                            "
                              [fields]="fields"
                              [filterBarPlaceholder]="
                                                                'Vyhledat uživatele...'
                                                            "
                              [ignoreAccent]="
                                                                true
                                                            "
                              [mode]="'CheckBox'"
                              [placeholder]="
                                                                'Vyberte uživatele'
                                                            "
                              [popupHeight]="
                                                                height
                                                            "
                              [selectAllText]="
                                                                'Všechny uživatele'
                                                            "
                              formControlName="users"
                              id="createEventForm-users"
                              showSelectAll="true"
                              sortOrder="Ascending"
                            ></ejs-multiselect>
                            <small
                              *ngIf="
                                                                getBookEventsFormGroup(
                                                                    i
                                                                ).controls[
                                                                    'users'
                                                                ].touched &&
                                                                getBookEventsFormGroup(
                                                                    i
                                                                ).controls[
                                                                    'users'
                                                                ].errors &&
                                                                getBookEventsFormGroup(
                                                                    i
                                                                ).controls[
                                                                    'users'
                                                                ].errors
                                                                    .required
                                                            "
                              class="text-danger"
                            >Vyberte
                              uživatele</small
                            >
                          </div>
                          <div
                            class="col-12 col-md-6 mb-2 form-group"
                          >
                            <label
                              [ngClass]="
                                                                getBookEventsFormGroup(
                                                                    i
                                                                ).controls[
                                                                    'departments'
                                                                ].valid
                                                                    ? 'text-success'
                                                                    : getBookEventsFormGroup(
                                                                          i
                                                                      )
                                                                          .controls[
                                                                          'departments'
                                                                      ]
                                                                          .invalid &&
                                                                      (getBookEventsFormGroup(
                                                                          i
                                                                      )
                                                                          .controls[
                                                                          'departments'
                                                                      ].dirty ||
                                                                          getBookEventsFormGroup(
                                                                              i
                                                                          )
                                                                              .controls[
                                                                              'departments'
                                                                          ]
                                                                              .touched)
                                                                    ? 'text-danger'
                                                                    : 'text-muted'
                                                            "
                              for="createEventForm-departments"
                            >Zapojit
                              oddělení:</label
                            >
                            <ejs-multiselect
                              #departments
                              (filtering)="
                                                                onFilteringDepartments(
                                                                    $event
                                                                )
                                                            "
                              [allowFiltering]="
                                                                true
                                                            "
                              [dataSource]="
                                                                departmentSelect
                                                            "
                              [fields]="fields"
                              [filterBarPlaceholder]="
                                                                'Vyhledat oddělení...'
                                                            "
                              [ignoreAccent]="
                                                                true
                                                            "
                              [mode]="'CheckBox'"
                              [placeholder]="
                                                                'Vyberte oddělení'
                                                            "
                              [popupHeight]="
                                                                height
                                                            "
                              [selectAllText]="
                                                                'Všechny oddělení'
                                                            "
                              formControlName="departments"
                              id="createEventForm-departments"
                              showSelectAll="true"
                              sortOrder="Ascending"
                            ></ejs-multiselect>
                            <small
                              *ngIf="
                                                                getBookEventsFormGroup(
                                                                    i
                                                                ).controls[
                                                                    'departments'
                                                                ].touched &&
                                                                getBookEventsFormGroup(
                                                                    i
                                                                ).controls[
                                                                    'departments'
                                                                ].errors &&
                                                                getBookEventsFormGroup(
                                                                    i
                                                                ).controls[
                                                                    'departments'
                                                                ].errors
                                                                    .required
                                                            "
                              class="text-danger"
                            >Vyberte
                              oddělení</small
                            >
                          </div>
                          <div class="col-12 mb-2">
                            <ejs-checkbox
                              #attendCheckBox
                              (created)="
                                                                setValueOfCheckBox(
                                                                    i
                                                                )
                                                            "
                              [checked]="false"
                              [ngClass]="
                                                                getBookEventsFormGroup(
                                                                    i
                                                                ).controls[
                                                                    'required'
                                                                ].valid
                                                                    ? 'text-success'
                                                                    : getBookEventsFormGroup(
                                                                          i
                                                                      )
                                                                          .controls[
                                                                          'required'
                                                                      ].invalid
                                                                    ? 'text-danger'
                                                                    : 'text-muted'
                                                            "
                              cssClass="e-primary p-0 m-0"
                              formControlName="required"
                              label="Účast povinná"
                            ></ejs-checkbox>
                          </div>
                        </div>
                      </div>
                      <div
                        class="col-12 col-md-1 text-center"
                      >
                        <button
                          (click)="removeEvent(i)"
                          [disabled]="i === 0"
                          [ngClass]="
                                                        i === 0
                                                            ? 'e-disabled'
                                                            : 'e-danger'
                                                    "
                          cssClass="e-btn btn-p-zero e-small"
                          ejs-button
                          type="button"
                        >
                          <i
                            aria-hidden="true"
                            class="fa-light fa-lg fa-trash"
                          ></i>
                        </button>
                      </div>
                      <div class="col-12">
                        <hr />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row mt-3">
            <div class="e-card">
              <div
                [ngClass]="emptyTickets ? 'text-danger' : ''"
                class="card-header"
              >
                <h6>Přidat ticket k rezervaci</h6>
                <button
                  (click)="addTicket()"
                  cssClass="e-btn e-success btn-p-zero e-small mr-1 float-right"
                  ejs-button
                  type="button"
                >
                  Přidat ticket
                </button>
                <ng-container *ngIf="emptyTickets">
                  <br />
                  <small class="text-danger">
                    Rezervace musí obsahovat alespoň jeden
                    ticket!
                  </small>
                </ng-container>
              </div>
              <div
                class="e-card-content"
                formArrayName="bookTickets"
              >
                <div class="row p-3">
                  <div
                    *ngFor="
                                            let ft of bookTicketsFormGroup.controls;
                                            let i = index
                                        "
                    class="col-12"
                  >
                    <div [formGroupName]="i" class="row">
                      <div class="col-12 col-md-11">
                        <div class="row">
                          <div
                            class="form-group col-12 mb-3"
                          >
                            <label
                              [ngClass]="
                                                                getBookTicketFormGroup(
                                                                    i
                                                                ).controls[
                                                                    'subject'
                                                                ].valid &&
                                                                (getBookTicketFormGroup(
                                                                    i
                                                                ).controls[
                                                                    'subject'
                                                                ].dirty ||
                                                                    getBookTicketFormGroup(
                                                                        i
                                                                    ).controls[
                                                                        'subject'
                                                                    ].touched)
                                                                    ? 'text-success'
                                                                    : getBookTicketFormGroup(
                                                                          i
                                                                      )
                                                                          .controls[
                                                                          'subject'
                                                                      ]
                                                                          .invalid &&
                                                                      (getBookTicketFormGroup(
                                                                          i
                                                                      )
                                                                          .controls[
                                                                          'subject'
                                                                      ].dirty ||
                                                                          getBookTicketFormGroup(
                                                                              i
                                                                          )
                                                                              .controls[
                                                                              'subject'
                                                                          ]
                                                                              .touched)
                                                                    ? 'text-danger'
                                                                    : 'text-muted'
                                                            "
                              for="ticketForm-subject"
                            >* Název:</label
                            >
                            <ejs-textbox
                              [ngClass]="
                                                                (getBookTicketFormGroup(
                                                                    i
                                                                ).controls[
                                                                    'subject'
                                                                ].dirty ||
                                                                    getBookTicketFormGroup(
                                                                        i
                                                                    ).controls[
                                                                        'subject'
                                                                    ]
                                                                        .touched) &&
                                                                getBookTicketFormGroup(
                                                                    i
                                                                ).controls[
                                                                    'subject'
                                                                ].invalid
                                                                    ? 'e-error'
                                                                    : (getBookTicketFormGroup(
                                                                          i
                                                                      )
                                                                          .controls[
                                                                          'subject'
                                                                      ].dirty ||
                                                                          getBookTicketFormGroup(
                                                                              i
                                                                          )
                                                                              .controls[
                                                                              'subject'
                                                                          ]
                                                                              .touched) &&
                                                                      getBookTicketFormGroup(
                                                                          i
                                                                      )
                                                                          .controls[
                                                                          'subject'
                                                                      ].valid
                                                                    ? 'e-success'
                                                                    : ''
                                                            "
                              floatLabelType="Auto"
                              formControlName="subject"
                              id="ticketForm-subject"
                            ></ejs-textbox>
                            <small
                              *ngIf="
                                                                (getBookTicketFormGroup(
                                                                    i
                                                                ).controls[
                                                                    'subject'
                                                                ].dirty ||
                                                                    getBookTicketFormGroup(
                                                                        i
                                                                    ).controls[
                                                                        'subject'
                                                                    ]
                                                                        .touched) &&
                                                                getBookTicketFormGroup(
                                                                    i
                                                                ).controls[
                                                                    'subject'
                                                                ].errors &&
                                                                getBookTicketFormGroup(
                                                                    i
                                                                ).controls[
                                                                    'subject'
                                                                ].errors
                                                                    .required
                                                            "
                              class="text-danger"
                            >Název ticketu je
                              povinný</small
                            >
                          </div>
                          <div
                            class="form-group col-12 col-md-6 mb-3"
                          >
                            <label
                              [ngClass]="
                                                                getBookTicketFormGroup(
                                                                    i
                                                                ).controls[
                                                                    'category_id'
                                                                ].valid
                                                                    ? 'text-success'
                                                                    : getBookTicketFormGroup(
                                                                          i
                                                                      )
                                                                          .controls[
                                                                          'category_id'
                                                                      ]
                                                                          .invalid &&
                                                                      (getBookTicketFormGroup(
                                                                          i
                                                                      )
                                                                          .controls[
                                                                          'category_id'
                                                                      ].dirty ||
                                                                          getBookTicketFormGroup(
                                                                              i
                                                                          )
                                                                              .controls[
                                                                              'category_id'
                                                                          ]
                                                                              .touched)
                                                                    ? 'text-danger'
                                                                    : 'text-muted'
                                                            "
                              for="ticketForm-category"
                            >* Kategorie
                              ticketu:</label
                            >
                            <ejs-dropdownlist
                              #category
                              (filtering)="
                                                                onFilteringCategories(
                                                                    $event
                                                                )
                                                            "
                              [allowFiltering]="
                                                                true
                                                            "
                              [cssClass]="
                                                                getBookTicketFormGroup(
                                                                    i
                                                                ).controls[
                                                                    'category_id'
                                                                ].touched &&
                                                                getBookTicketFormGroup(
                                                                    i
                                                                ).controls[
                                                                    'category_id'
                                                                ].invalid
                                                                    ? 'e-error'
                                                                    : getBookTicketFormGroup(
                                                                          i
                                                                      )
                                                                          .controls[
                                                                          'category_id'
                                                                      ]
                                                                          .touched &&
                                                                      getBookTicketFormGroup(
                                                                          i
                                                                      )
                                                                          .controls[
                                                                          'category_id'
                                                                      ].valid
                                                                    ? 'e-success'
                                                                    : ''
                                                            "
                              [dataSource]="
                                                                categorySelect
                                                            "
                              [fields]="fields"
                              [filterBarPlaceholder]="
                                                                'Vyhledat kategorii...'
                                                            "
                              [ignoreAccent]="
                                                                true
                                                            "
                              [placeholder]="
                                                                'Vyberte kategorii'
                                                            "
                              [popupHeight]="
                                                                height
                                                            "
                              formControlName="category_id"
                              id="ticketForm-category"
                              sortOrder="Ascending"
                            ></ejs-dropdownlist>
                            <small
                              *ngIf="
                                                                (getBookTicketFormGroup(
                                                                    i
                                                                ).controls[
                                                                    'category_id'
                                                                ].dirty ||
                                                                    getBookTicketFormGroup(
                                                                        i
                                                                    ).controls[
                                                                        'category_id'
                                                                    ]
                                                                        .touched) &&
                                                                getBookTicketFormGroup(
                                                                    i
                                                                ).controls[
                                                                    'category_id'
                                                                ].errors &&
                                                                getBookTicketFormGroup(
                                                                    i
                                                                ).controls[
                                                                    'category_id'
                                                                ].errors
                                                                    .required
                                                            "
                              class="text-danger"
                            >Vyberte alespoň
                              jednu kategorii
                              ticketu</small
                            >
                          </div>
                          <div
                            class="form-group col-12 col-md-6 mb-3"
                          >
                            <tag-input
                              [displayBy]="'name'"
                              [identifyBy]="'id'"
                              [onlyFromAutocomplete]="
                                                                false
                                                            "
                              formControlName="tags"
                            >
                              <tag-input-dropdown
                                [autocompleteItems]="
                                                                    tagsAutocomplete
                                                                "
                                [displayBy]="
                                                                    'name'
                                                                "
                                [identifyBy]="
                                                                    'id'
                                                                "
                              >
                              </tag-input-dropdown>
                            </tag-input>
                          </div>
                        </div>
                        <div class="row">
                          <div
                            class="form-group col-12"
                          >
                            <label
                              [ngClass]="
                                                                getBookTicketFormGroup(
                                                                    i
                                                                ).controls[
                                                                    'maintask'
                                                                ].valid &&
                                                                (getBookTicketFormGroup(
                                                                    i
                                                                ).controls[
                                                                    'maintask'
                                                                ].dirty ||
                                                                    getBookTicketFormGroup(
                                                                        i
                                                                    ).controls[
                                                                        'maintask'
                                                                    ].touched)
                                                                    ? 'text-success'
                                                                    : getBookTicketFormGroup(
                                                                          i
                                                                      )
                                                                          .controls[
                                                                          'maintask'
                                                                      ]
                                                                          .invalid &&
                                                                      (getBookTicketFormGroup(
                                                                          i
                                                                      )
                                                                          .controls[
                                                                          'maintask'
                                                                      ].dirty ||
                                                                          getBookTicketFormGroup(
                                                                              i
                                                                          )
                                                                              .controls[
                                                                              'maintask'
                                                                          ]
                                                                              .touched)
                                                                    ? 'text-danger'
                                                                    : 'text-muted'
                                                            "
                              for="maintask"
                            >* Zadání:</label
                            >
                            <ejs-richtexteditor
                              #mainTask
                              (actionBegin)="
                                                                handleFullScreenTicket(
                                                                    $event
                                                                )
                                                            "
                              (actionComplete)="
                                                                actionCompleteHandlerTicket(
                                                                    $event
                                                                )
                                                            "
                              [enablePersistence]="
                                                                false
                                                            "
                              [fontFamily]="
                                                                fontFamily
                                                            "
                              [insertImageSettings]="
                                                                insertImageSettings
                                                            "
                              [maxLength]="
                                                                maxLength
                                                            "
                              [ngClass]="
                                                                (getBookTicketFormGroup(
                                                                    i
                                                                ).controls[
                                                                    'maintask'
                                                                ].dirty ||
                                                                    getBookTicketFormGroup(
                                                                        i
                                                                    ).controls[
                                                                        'maintask'
                                                                    ]
                                                                        .touched) &&
                                                                getBookTicketFormGroup(
                                                                    i
                                                                ).controls[
                                                                    'maintask'
                                                                ].invalid
                                                                    ? 'e-error'
                                                                    : (getBookTicketFormGroup(
                                                                          i
                                                                      )
                                                                          .controls[
                                                                          'maintask'
                                                                      ].dirty ||
                                                                          getBookTicketFormGroup(
                                                                              i
                                                                          )
                                                                              .controls[
                                                                              'maintask'
                                                                          ]
                                                                              .touched) &&
                                                                      getBookTicketFormGroup(
                                                                          i
                                                                      )
                                                                          .controls[
                                                                          'maintask'
                                                                      ].valid
                                                                    ? 'e-success'
                                                                    : ''
                                                            "
                              [pasteCleanupSettings]="
                                                                pasteCleanupSettings
                                                            "
                              [quickToolbarSettings]="
                                                                quickToolbarSettings
                                                            "
                              [showCharCount]="
                                                                true
                                                            "
                              [toolbarSettings]="
                                                                tools
                                                            "
                              formControlName="maintask"
                              id="maintask"
                              locale="cs"
                              placeholder="Začněte psát zadání..."
                            >
                            </ejs-richtexteditor>
                            <small
                              *ngIf="
                                                                (getBookTicketFormGroup(
                                                                    i
                                                                ).controls[
                                                                    'maintask'
                                                                ].dirty ||
                                                                    getBookTicketFormGroup(
                                                                        i
                                                                    ).controls[
                                                                        'maintask'
                                                                    ]
                                                                        .touched) &&
                                                                getBookTicketFormGroup(
                                                                    i
                                                                ).controls[
                                                                    'maintask'
                                                                ].errors &&
                                                                getBookTicketFormGroup(
                                                                    i
                                                                ).controls[
                                                                    'maintask'
                                                                ].errors
                                                                    .required
                                                            "
                              class="text-danger"
                            >Zadání ticketu je
                              povinné</small
                            >
                          </div>
                        </div>
                        <div class="row">
                          <div
                            class="form-group col-12 col-md-6"
                          >
                            <ejs-datetimepicker
                              [cssClass]="
                                                                getBookTicketFormGroup(
                                                                    i
                                                                ).controls[
                                                                    'start_deadline'
                                                                ].invalid
                                                                    ? 'e-error'
                                                                    : getBookTicketFormGroup(
                                                                          i
                                                                      )
                                                                          .controls[
                                                                          'start_deadline'
                                                                      ].valid
                                                                    ? 'e-success'
                                                                    : ''
                                                            "
                              [firstDayOfWeek]="1"
                              [min]="today"
                              [value]="today"
                              floatLabelType="Always"
                              formControlName="start_deadline"
                              id="start_deadline"
                              placeholder="Začátek termínu:"
                            ></ejs-datetimepicker>
                            <small
                              *ngIf="
                                                                getBookTicketFormGroup(
                                                                    i
                                                                ).controls[
                                                                    'start_deadline'
                                                                ].touched &&
                                                                getBookTicketFormGroup(
                                                                    i
                                                                ).controls[
                                                                    'start_deadline'
                                                                ].errors &&
                                                                getBookTicketFormGroup(
                                                                    i
                                                                ).controls[
                                                                    'start_deadline'
                                                                ].errors
                                                                    .required
                                                            "
                              class="text-danger"
                            >Datum začátku
                              termínu ticketu je
                              povinné</small
                            ><br />
                            <small
                              *ngIf="
                                                                getBookTicketFormGroup(
                                                                    i
                                                                ).controls[
                                                                    'start_deadline'
                                                                ].touched &&
                                                                getBookTicketFormGroup(
                                                                    i
                                                                ).controls[
                                                                    'start_deadline'
                                                                ].errors &&
                                                                getBookTicketFormGroup(
                                                                    i
                                                                ).controls[
                                                                    'start_deadline'
                                                                ].errors
                                                                    .greaterThan
                                                            "
                              class="text-danger"
                            >Datum začátku
                              termínu je větší než
                              datum konce</small
                            >
                          </div>
                          <div
                            class="form-group col-12 col-md-6"
                          >
                            <ejs-datetimepicker
                              [cssClass]="
                                                                getBookTicketFormGroup(
                                                                    i
                                                                ).controls[
                                                                    'deadline'
                                                                ].invalid
                                                                    ? 'e-error'
                                                                    : getBookTicketFormGroup(
                                                                          i
                                                                      )
                                                                          .controls[
                                                                          'deadline'
                                                                      ].valid
                                                                    ? 'e-success'
                                                                    : ''
                                                            "
                              [firstDayOfWeek]="1"
                              [min]="
                                                                getBookTicketFormGroup(
                                                                    i
                                                                ).controls[
                                                                    'start_deadline'
                                                                ].value
                                                            "
                              floatLabelType="Always"
                              formControlName="deadline"
                              id="deadline"
                              placeholder="Konec termínu:"
                            ></ejs-datetimepicker>
                            <small
                              *ngIf="
                                                                getBookTicketFormGroup(
                                                                    i
                                                                ).controls[
                                                                    'deadline'
                                                                ].errors &&
                                                                getBookTicketFormGroup(
                                                                    i
                                                                ).controls[
                                                                    'deadline'
                                                                ].errors
                                                                    .required
                                                            "
                              class="text-danger"
                            >Datum konce termínu
                              ticketu je
                              povinné</small
                            ><br />
                            <small
                              *ngIf="
                                                                getBookTicketFormGroup(
                                                                    i
                                                                ).controls[
                                                                    'deadline'
                                                                ].touched &&
                                                                getBookTicketFormGroup(
                                                                    i
                                                                ).controls[
                                                                    'deadline'
                                                                ].errors &&
                                                                getBookTicketFormGroup(
                                                                    i
                                                                ).controls[
                                                                    'deadline'
                                                                ].errors
                                                                    .lessThan
                                                            "
                              class="text-danger"
                            >Datum konce termínu
                              je menší než datum
                              začátku</small
                            >
                          </div>
                        </div>
                        <div class="row">
                          <div
                            class="form-group col-12 col-md-6"
                          >
                            <label
                              [ngClass]="
                                                                getBookTicketFormGroup(
                                                                    i
                                                                ).controls[
                                                                    'users'
                                                                ].valid &&
                                                                (getBookTicketFormGroup(
                                                                    i
                                                                ).controls[
                                                                    'users'
                                                                ].dirty ||
                                                                    getBookTicketFormGroup(
                                                                        i
                                                                    ).controls[
                                                                        'users'
                                                                    ].touched)
                                                                    ? 'text-success'
                                                                    : getBookTicketFormGroup(
                                                                          i
                                                                      )
                                                                          .controls[
                                                                          'users'
                                                                      ]
                                                                          .invalid &&
                                                                      (getBookTicketFormGroup(
                                                                          i
                                                                      )
                                                                          .controls[
                                                                          'users'
                                                                      ].dirty ||
                                                                          getBookTicketFormGroup(
                                                                              i
                                                                          )
                                                                              .controls[
                                                                              'users'
                                                                          ]
                                                                              .touched)
                                                                    ? 'text-danger'
                                                                    : 'text-muted'
                                                            "
                              for="usersTicket"
                            >Uživatelé:</label
                            >
                            <ejs-multiselect
                              #users
                              (filtering)="
                                                                onFilteringUsers(
                                                                    $event
                                                                )
                                                            "
                              [allowFiltering]="
                                                                true
                                                            "
                              [cssClass]="
                                                                getBookTicketFormGroup(
                                                                    i
                                                                ).controls[
                                                                    'users'
                                                                ].touched &&
                                                                getBookTicketFormGroup(
                                                                    i
                                                                ).controls[
                                                                    'users'
                                                                ].invalid
                                                                    ? 'e-error'
                                                                    : getBookTicketFormGroup(
                                                                          i
                                                                      )
                                                                          .controls[
                                                                          'users'
                                                                      ]
                                                                          .touched &&
                                                                      getBookTicketFormGroup(
                                                                          i
                                                                      )
                                                                          .controls[
                                                                          'users'
                                                                      ].valid
                                                                    ? 'e-success'
                                                                    : ''
                                                            "
                              [dataSource]="
                                                                usersSelect
                                                            "
                              [fields]="fields"
                              [filterBarPlaceholder]="
                                                                'Vyhledat uživatele...'
                                                            "
                              [ignoreAccent]="
                                                                true
                                                            "
                              [mode]="'CheckBox'"
                              [placeholder]="
                                                                'Vyberte uživatele'
                                                            "
                              [popupHeight]="
                                                                height
                                                            "
                              [selectAllText]="
                                                                'Všechny uživatele'
                                                            "
                              formControlName="users"
                              id="usersTicket"
                              showSelectAll="true"
                              sortOrder="Ascending"
                            ></ejs-multiselect>
                            <small
                              *ngIf="
                                                                getBookTicketFormGroup(
                                                                    i
                                                                ).controls[
                                                                    'users'
                                                                ].touched &&
                                                                getBookTicketFormGroup(
                                                                    i
                                                                ).controls[
                                                                    'users'
                                                                ].errors &&
                                                                getBookTicketFormGroup(
                                                                    i
                                                                ).controls[
                                                                    'users'
                                                                ].errors
                                                                    .required
                                                            "
                              class="text-danger"
                            >Vyberte alespoň
                              jednoho
                              uživatele</small
                            >
                          </div>
                          <div
                            class="form-group col-12 col-md-6"
                          >
                            <label
                              [ngClass]="
                                                                getBookTicketFormGroup(
                                                                    i
                                                                ).controls[
                                                                    'departments'
                                                                ].valid &&
                                                                (getBookTicketFormGroup(
                                                                    i
                                                                ).controls[
                                                                    'departments'
                                                                ].dirty ||
                                                                    getBookTicketFormGroup(
                                                                        i
                                                                    ).controls[
                                                                        'departments'
                                                                    ].touched)
                                                                    ? 'text-success'
                                                                    : getBookTicketFormGroup(
                                                                          i
                                                                      )
                                                                          .controls[
                                                                          'departments'
                                                                      ]
                                                                          .invalid &&
                                                                      (getBookTicketFormGroup(
                                                                          i
                                                                      )
                                                                          .controls[
                                                                          'departments'
                                                                      ].dirty ||
                                                                          getBookTicketFormGroup(
                                                                              i
                                                                          )
                                                                              .controls[
                                                                              'departments'
                                                                          ]
                                                                              .touched)
                                                                    ? 'text-danger'
                                                                    : 'text-muted'
                                                            "
                              for="ticketDepartments"
                            >Oddělení:</label
                            >
                            <ejs-multiselect
                              #departments
                              (filtering)="
                                                                onFilteringDepartments(
                                                                    $event
                                                                )
                                                            "
                              [allowFiltering]="
                                                                true
                                                            "
                              [cssClass]="
                                                                getBookTicketFormGroup(
                                                                    i
                                                                ).controls[
                                                                    'departments'
                                                                ].touched &&
                                                                getBookTicketFormGroup(
                                                                    i
                                                                ).controls[
                                                                    'departments'
                                                                ].invalid
                                                                    ? 'e-error'
                                                                    : getBookTicketFormGroup(
                                                                          i
                                                                      )
                                                                          .controls[
                                                                          'departments'
                                                                      ]
                                                                          .touched &&
                                                                      getBookTicketFormGroup(
                                                                          i
                                                                      )
                                                                          .controls[
                                                                          'departments'
                                                                      ].valid
                                                                    ? 'e-success'
                                                                    : ''
                                                            "
                              [dataSource]="
                                                                departmentSelect
                                                            "
                              [fields]="fields"
                              [filterBarPlaceholder]="
                                                                'Vyhledat oddělení...'
                                                            "
                              [ignoreAccent]="
                                                                true
                                                            "
                              [mode]="'CheckBox'"
                              [placeholder]="
                                                                'Vyberte oddělení'
                                                            "
                              [popupHeight]="
                                                                height
                                                            "
                              [selectAllText]="
                                                                'Všechna oddělení'
                                                            "
                              formControlName="departments"
                              id="ticketDepartments"
                              showSelectAll="true"
                              sortOrder="Ascending"
                            ></ejs-multiselect>
                            <small
                              *ngIf="
                                                                getBookTicketFormGroup(
                                                                    i
                                                                ).controls[
                                                                    'departments'
                                                                ].touched &&
                                                                getBookTicketFormGroup(
                                                                    i
                                                                ).controls[
                                                                    'departments'
                                                                ].errors &&
                                                                getBookTicketFormGroup(
                                                                    i
                                                                ).controls[
                                                                    'departments'
                                                                ].errors
                                                                    .required
                                                            "
                              class="text-danger"
                            >Vyberte alespoň
                              jedno
                              oddělení</small
                            >
                          </div>
                        </div>
                      </div>
                      <div
                        class="col-12 col-md-1 text-center"
                      >
                        <button
                          (click)="removeTicket(i)"
                          cssClass="e-btn e-danger btn-p-zero e-small"
                          ejs-button
                          type="button"
                        >
                          <i
                            aria-hidden="true"
                            class="fa-light fa-lg fa-trash"
                          ></i>
                        </button>
                      </div>
                      <div class="col-12">
                        <hr />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
    <ng-template #footerTemplate>
      <div class="d-flex justify-content-end">
                <span
                  *ngIf="bookingForm.invalid"
                  class="e-badge e-badge-warning p-2 size_2"
                  role="alert"
                >
                    <strong>Formulář je špatně vyplněný</strong>
                </span>
        <span
          *ngIf="bookingForm.valid"
          class="e-badge e-badge-success p-2 size_2"
          role="alert"
        >
                    <strong>Formulář je správně vyplněný</strong>
                </span>
      </div>
      <div class="row">
        <div class="col-12 pt-2">
          <button
            (click)="onSubmit()"
            [disabled]="
                            loadingBooking || bookingForm.invalid || !isChecked
                        "
            cssClass="e-btn e-success btn-p-zero e-small m-1"
            ejs-button
            type="button"
          >
                        <span
                          *ngIf="loadingBooking"
                          aria-hidden="true"
                          class="spinner-grow spinner-grow-sm"
                          role="status"
                        ></span>
            <span *ngIf="!loadingBooking">ULOŽIT</span>
            <span *ngIf="loadingBooking">Provádím...</span>
          </button>
          <button
            (click)="formDialogObj.hide()"
            cssClass="e-btn e-danger e-outline btn-p-zero e-small m-1"
            ejs-button
            type="button"
          >
            ZRUŠIT
          </button>
        </div>
      </div>
    </ng-template>
  </form>
</ejs-dialog>
