import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule, Routes} from '@angular/router';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {AuthGuard} from '@src/app/_guards/auth.guard';
import {KanbanAllModule} from '@syncfusion/ej2-angular-kanban';
import {ScheduleAllModule} from '@syncfusion/ej2-angular-schedule';
import {TaskNoteFormComponent} from '@src/app/features/tasks/_forms/task-note-form/task-note-form.component';
import {TasksService} from '@src/app/features/tasks/tasks.service';
import {SharedFeaturesModule} from '@src/app/shared_features/shared-features.module';
import {
    TaskCalendarComponent,
    TaskDragAndDropComponent,
    TaskImportComponent,
    TaskNotesComponent,
    TaskSidebarComponent,
    TaskTimeComponent,
    TaskToolbarComponent,
    TaskDetailComponent,
    TaskComponent,
    TaskQueueComponent,
} from '@src/app/features/tasks/components';
import {TicketsModule} from '@src/app/features/tickets/tickets.module';
import {TaskCommentsComponent} from '@src/app/features/tasks/components/task-comments/task-comments.component';
import {TaskCommentFormComponent} from '@src/app/features/tasks/_forms/task-comment-form/task-comment-form.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {BrowserModule} from '@angular/platform-browser';

const routes: Routes = [
    {
        path: 'tasks',
        children: [
            {
                path: '',
                component: TaskComponent,
                data: {
                    title: 'Úkoly',
                    breadcrumb: [
                        {
                            label: 'Úkoly',
                            url: '',
                        },
                    ],
                },
            },
            {
                path: 'detail/:id',
                component: TaskDetailComponent,
                data: {
                    title: 'Detail úkolu',
                    breadcrumb: [
                        {
                            label: 'Úkoly',
                            url: '/tasks/',
                        },
                        {
                            label: 'Detail úkolu',
                            url: '',
                        },
                    ],
                },
            },
        ],
        canActivate: [AuthGuard],
    },
];

@NgModule({
    declarations: [
        TaskCalendarComponent,
        TaskCommentFormComponent,
        TaskCommentsComponent,
        TaskComponent,
        TaskDetailComponent,
        TaskDragAndDropComponent,
        TaskImportComponent,
        TaskNoteFormComponent,
        TaskNotesComponent,
        TaskQueueComponent,
        TaskSidebarComponent,
        TaskTimeComponent,
        TaskToolbarComponent,
    ],
    imports: [
        BrowserAnimationsModule,
        BrowserModule,
        CommonModule,
        RouterModule.forChild(routes),
        FormsModule,
        KanbanAllModule,
        ReactiveFormsModule,
        ScheduleAllModule,
        SharedFeaturesModule,
        TicketsModule,
    ],
    exports: [TaskComponent, TaskDetailComponent, TaskQueueComponent],
    providers: [TasksService],
})
export class TaskModule {
}
