<ng-container *ngIf="isSolving then solutionNote else progressNote"></ng-container>
<ng-template #progressNote>
  <div class="e-card-content container-fluid scrollbar scrollbar-sunny-morning thin">
    <div *ngIf="ticket.notes && ticket.notes.length > 0" class="threads-block">
      <ng-container *ngFor="let note of ticket.notes">
        <div class="thread">
          <div class="note-toolbar">
            <div class="user-signature">
              <img
                alt="profilový obrázek"
                class="avatar-xs rounded-circle z-depth-1"
                src="assets/img{{note.creator.personalphoto}}"
              />
              <div class="font-weight-bold time-block">
                <small class="text-muted font-weight-bold">
                  {{ note.creator.firstname }} {{ note.creator.secondname }}
                </small>
                &nbsp;&nbsp;
                <small
                  *ngIf="note.unixCreatedTime"
                  class="text-muted font-weight-bold"
                  style="
                                                                                            font-size: 11px;
                                                                                        "
                >{{
                    note.created_date
                      | dateFormat
                      : 'DD.MM.YYYY HH:mm'
                  }}&nbsp;&middot;&nbsp;{{
                    note.unixCreatedTime
                      | timeago
                      : live
                  }}</small
                >
              </div>
            </div>
            <div
              *ngIf="!ticket.finished_at"
              aria-label="notes toolbar"
              class="note-buttons e-small"
              role="group"
            >
              <button
                (click)="editNote(note)"
                *ngIf="note && note.creator.id === currentUser.id && !note.deleted_date"
                cssClass="e-btn e-warning btn-p-zero e-small"
                ejs-button
              >
                <i
                  aria-hidden="true"
                  class="fa-light fa-pencil mr-1"
                ></i>
                upravit
              </button>
              <button
                (click)="deleteNote(note)"
                *ngIf="note && note.creator.id === currentUser.id && !note.deleted_date"
                cssClass="e-btn e-danger btn-p-zero e-small"
                ejs-button
              >
                <i
                  aria-hidden="true"
                  class="fa-light fa-trash mr-1"
                ></i>
                smazat
              </button>
            </div>
          </div>
          <div class="note-text border-bottom">
            <p [innerHTML]="note.textHTML" class="text-ticket"></p>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</ng-template>
<ng-template #solutionNote>
  <div class="e-card-content container-fluid scrollbar scrollbar-dusty-grass thin">
    <div class="threads-block">
      <div class="thread">
        <div class="note-toolbar">
          <div class="user-signature">
            <img
              *ngIf="ticket.solver"
              alt="profilový obrázek"
              class="avatar-xs rounded-circle z-depth-1 d-flex mx-auto mb-2"
              src="assets/img{{ticket.solver.personalphoto}}"
            />
            <div class="font-weight-bold time-block">
              <small
                *ngIf="ticket.solver"
                class="text-muted font-weight-bold"
              >
                {{ ticket.solver.firstname }} {{ ticket.solver.secondname }}
              </small>
              &nbsp;&nbsp;
              <small
                *ngIf="ticket.unixFinishedTime"
                class="text-muted font-weight-bold"
                style="
                                                                        font-size: 11px;
                                                                    "
              >
                {{ ticket.finished_at | dateFormat : 'DD.MM.YYYY HH:mm' }}&nbsp;&middot;&nbsp;
                {{ ticket.unixFinishedTime | timeago : live }}
              </small>
            </div>
          </div>
          <div
            aria-label="notes toolbar"
            class="note-buttons e-small"
            role="group"
          >
            <button
              (click)="editSolution()"
              *ngIf="(ticket.created_by === currentUser.id || ticketsService.checkPromotedAdministrators(currentUser) || (currentUser.rule && currentUser.rule.is_admin) || ticket.creator.boss_id === currentUser.id) && !ticket.deleted_date && ticket.finished_at"
              cssClass="e-btn e-warning btn-p-zero e-small"
              ejs-button
              type="button"
            >
              upravit řešení
            </button>
            <button
              (click)="reactiveTicket()"
              *ngIf="(ticket.created_by === currentUser.id || ticketsService.checkPromotedAdministrators(currentUser) || (currentUser.rule && currentUser.rule.is_admin) || ticket.creator.boss_id === currentUser.id) && (ticket.deleted_date || ticket.finished_at)"
              cssClass="e-btn e-danger btn-p-zero e-small"
              ejs-button
              type="button"
            >
              <i
                aria-hidden="true"
                class="fa-light fa-repeat mr-lg-1"
              ></i>
              <span class="d-none d-lg-inline">
                            re-aktivovat ticket
                        </span>
            </button>
          </div>
        </div>
        <div class="note-text border-bottom">
          <p [innerHTML]="ticket.solutionHTML" class="text-ticket"></p>
        </div>
      </div>
    </div>
  </div>
</ng-template>
