<div
  *ngIf="tableDocLoad"
  style="
        display: block !important;
        z-index: 1000000;
        width: 250px;
        height: 250px;
        margin: auto;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    "
>
  <div class="loader">
    <span></span>
    <span></span>
    <span></span>
    <span></span>
  </div>
  <div
    class="lds-circle"
    style="
            margin: auto;
            position: absolute;
            width: 150px;
            height: auto;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        "
  >
    <div></div>
  </div>
</div>
<div class="row d-flex justify-content-center modalWrapper">
  <ejs-dialog
    #confirmationCancel
    [buttons]="buttonsCancel"
    [header]="'Nelze provést akci'"
    [isModal]="true"
    [showCloseIcon]="true"
    [visible]="false"
    width="350px"
  ></ejs-dialog>
  <ejs-dialog
    #confirmationDelete
    [buttons]="buttonsDelete"
    [content]="'Opravdu chcete smazat tuto objednávku?'"
    [header]="'Smazání objednávky'"
    [isModal]="true"
    [showCloseIcon]="true"
    [visible]="false"
    width="350px"
  ></ejs-dialog>
  <ejs-dialog
    #confirmationDecline
    [buttons]="buttonsDecline"
    [content]="'Opravdu chcete zamítnout tuto objednávku?'"
    [header]="'Zamítnutí objednávky'"
    [isModal]="true"
    [showCloseIcon]="true"
    [visible]="false"
    width="350px"
  ></ejs-dialog>
  <ejs-dialog
    #confirmationConfirm
    [buttons]="buttonsConfirm"
    [content]="'Opravdu chcete schválit tuto objednávku?'"
    [header]="'Schválení objednávky'"
    [isModal]="true"
    [showCloseIcon]="true"
    [visible]="false"
    width="350px"
  ></ejs-dialog>
  <ejs-dialog
    #AdminconfirmationConfirm
    [buttons]="buttonsAdminConfirm"
    [content]="
            'Opravdu chcete schválit tuto objednávku? Tímto schválite tuto objednávku za všechny přiřazené schvalovatele, kteří ještě nepotvrdili nákup tohoto zboží.'
        "
    [header]="'Nadřízené schválení objednávky'"
    [isModal]="true"
    [showCloseIcon]="true"
    [visible]="false"
    width="350px"
  ></ejs-dialog>
  <ejs-dialog
    #AdminconfirmationDecline
    [buttons]="buttonsAdminDecline"
    [content]="
            'Opravdu chcete zamítnout tuto objednávku? Tímto zamítnete tuto objednávku za všechny přiřazené schvalovatele, kteří ještě nepotvrdili nákup tohoto zboží.'
        "
    [header]="'Nadřízené zamítnutí objednávky'"
    [isModal]="true"
    [showCloseIcon]="true"
    [visible]="false"
    width="350px"
  ></ejs-dialog>
  <ejs-dialog
    #receiptDialog
    [buttons]="buttonsReceiptDialog"
    [content]="
            'Opravdu chcete potvrdit převzetí účtenky pro objednávku placenou v hotovosti?'
        "
    [header]="'Potvrdit převzetí účtenky'"
    [isModal]="true"
    [showCloseIcon]="true"
    [visible]="false"
    width="350px"
  ></ejs-dialog>
  <app-material-form
    (materialFormDialogState)="
            renderMaterialFormDialog = $event;
            isCreate = $event;
            isUpdate = $event;
            isCopy = $event;
            gridInvoices?.clearRowSelection();
            disableToolBarBtn()
        "
    [isCopy]="isCopy"
    [isCreate]="isCreate"
    [isUpdate]="isUpdate"
    [isVisible]="renderMaterialFormDialog"
    [material]="
            clickedRow && clickedRow[0] && (isUpdate || isCopy)
                ? clickedRow[0]
                : null
        "
  ></app-material-form>
</div>
<ejs-toolbar id="filterToolbar" overflowMode="Scrollable" scrollStep="500">
  <e-items>
    <e-item
      [cssClass]="'statusFilter'"
      tooltipText="Přednastavené hledání"
      type="input"
    >
      <ng-template #template>
        <ejs-dropdownlist
          #viewFilter
          (change)="onStatusFilterChange($event)"
          [dataSource]="categoryFilterData"
          [width]="500"
          placeholder="Vyberte přednastavený pohled..."
        ></ejs-dropdownlist>
      </ng-template>
    </e-item>
    <e-item type="Separator"></e-item>
    <e-item
      [cssClass]="'fulltextFilter'"
      tooltipText="Text pro vyhledávání"
      type="input"
    >
      <ng-template #template>
        <ejs-textbox
          #searchtext
          [width]="500"
          class="mr-2"
          placeholder="Vyhledat..."
        ></ejs-textbox>
        <button
          (click)="search()"
          class="e-btn e-info btn-p-zero e-small"
        >
          <i class="fa-light fa-lg fa-search mr-1"></i
          ><span class="d-none d-lg-inline">vyhledat</span>
        </button>
        <button
          (click)="cancelBtnClick()"
          class="e-btn e-danger btn-p-zero e-small"
        >
          <i class="fa-light fa-lg fa-times"></i>
        </button>
      </ng-template>
    </e-item>
  </e-items>
</ejs-toolbar>
<div class="control-section">
  <div class="scroll_wrapper1" id="scroll_wrapper1_invoices">
    <div class="scroll_div" id="scroll_div_invoices"></div>
  </div>
  <div class="scroll_wrapper2" id="scroll_wrapper2_invoices">
    <div class="grid_parent" id="grid_parent_invoices">
      <ejs-grid
        #invoicesGrid
        (actionBegin)="actionBegin($event)"
        (actionComplete)="actionComplete($event)"
        (created)="onCreated()"
        (dataBound)="onDataBound()"
        (load)="onLoad()"
        (loadeddata)="setInitialGridFiltering()"
        (rowDataBound)="rowDataBound($event)"
        (rowDeselected)="rowSelected()"
        (rowSelected)="rowSelected()"
        (toolbarClick)="toolbarClick($event)"
        [allowExcelExport]="true"
        [allowFiltering]="true"
        [allowMultiSorting]="true"
        [allowPdfExport]="false"
        [allowSelection]="true"
        [allowSorting]="true"
        [dataSource]="data"
        [editSettings]="editSettings"
        [enableHover]="false"
        [enablePersistence]="false"
        [enableVirtualization]="true"
        [filterSettings]="filterSettings"
        [groupSettings]="groupOptions"
        [pageSettings]="pageOptions"
        [selectionSettings]="selectionSettings"
        [sortSettings]="sortOptions"
        [toolbar]="toolbar"
        allowGrouping="true"
        allowReordering="true"
        allowResizing="true"
        allowTextWrap="true"
        height="100%"
        id="invoicesGrid"
        locale="cs"
        rowHeight="38"
        showColumnMenu="true"
        width="100%"
      >
        <e-columns>
          <e-column
            [allowEditing]="false"
            [allowFiltering]="false"
            [allowReordering]="false"
            [allowResizing]="false"
            [allowSorting]="false"
            [autoFit]="true"
            type="checkbox"
          ></e-column>
          <e-column
            [allowFiltering]="false"
            [allowReordering]="true"
            [allowResizing]="false"
            [autoFit]="true"
            [isPrimaryKey]="true"
            [visible]="true"
            field="id"
            headerText="ID"
          >
            <ng-template #template let-data>
              <a routerLink="/material/detail/{{ data.id }}">{{
                  data.id
                }}</a>
            </ng-template>
          </e-column>
          <e-column
            [allowEditing]="false"
            [allowFiltering]="true"
            [allowReordering]="false"
            [allowResizing]="false"
            [autoFit]="true"
            [visible]="false"
            field="created_by"
            headerText="Creator ID"
          ></e-column>
          <e-column
            [autoFit]="true"
            [filter]="filterSettings"
            clipMode="EllipsisWithTooltip"
            field="reason"
            headerText="Důvod objednávky"
          ></e-column>
          <e-column
            [autoFit]="true"
            [filter]="filterSettings"
            clipMode="EllipsisWithTooltip"
            field="status"
            headerText="Stav"
            textAlign="center"
          >
            <ng-template #template let-data>
                            <span
                              *ngIf="data.status === 've frontě'"
                              class="e-badge e-badge-pill e-badge-warning"
                            >ve frontě</span
                            >
              <span
                *ngIf="data.status === 'stornováno'"
                class="e-badge e-badge-pill e-badge-danger"
              >stornováno</span
              >
              <span
                *ngIf="data.status === 'schváleno'"
                class="e-badge e-badge-pill e-badge-success"
              >schváleno</span
              >
              <span
                *ngIf="data.status === 'zamítnuto'"
                class="e-badge e-badge-pill e-badge-danger"
              >zamítnuto</span
              >
            </ng-template>
          </e-column>
          <e-column
            [autoFit]="true"
            [filter]="filterSettings"
            clipMode="EllipsisWithTooltip"
            field="to_storage"
            headerText="Typ nákupu"
            textAlign="center"
          >
            <ng-template #template let-data>
                            <span
                              *ngIf="data.to_storage === 'na sklad'"
                              class="e-badge e-badge-pill e-badge-success"
                            >na sklad</span
                            >
              <span
                *ngIf="data.to_storage === 'na přeprodej'"
                class="e-badge e-badge-pill e-badge-danger"
              >na přeprodej</span
              >
            </ng-template>
          </e-column>
          <e-column
            [autoFit]="true"
            [filter]="filterSettings"
            clipMode="EllipsisWithTooltip"
            field="type"
            headerText="Typ platby"
          >
          </e-column>
          <e-column
            [autoFit]="true"
            [filter]="filterSettings"
            clipMode="EllipsisWithTooltip"
            field="receipt"
            headerText="Dodaná účtenka"
            textAlign="center"
          >
            <ng-template #template let-data>
                            <span
                              *ngIf="
                                    data.receipt === 'účtenka dodána' &&
                                    data.type === 'platba v hotovosti'
                                "
                              class="e-badge e-badge-pill e-badge-success"
                            >účtenka dodána</span
                            >
              <span
                *ngIf="
                                    data.receipt === 'účtenka nedodána' &&
                                    data.type === 'platba v hotovosti'
                                "
                class="e-badge e-badge-pill e-badge-danger"
              >účtenka nedodána</span
              >
              <span
                *ngIf="data.type === 'bezhotovostní platba'"
                class="e-badge e-badge-pill e-badge-warning"
              >platba převodem</span
              >
            </ng-template>
          </e-column>
          <e-column
            [allowFiltering]="false"
            [allowSorting]="false"
            [autoFit]="true"
            clipMode="EllipsisWithTooltip"
            field="receipts"
            headerText="Faktura"
          >
            <ng-template #template let-data>
              <ol *ngIf="data.receipts.length > 0">
                <li *ngFor="let receipt of data.receipts">
                  <small
                  ><strong>{{
                      receipt.name
                    }}</strong></small
                  ><br />
                  <small class="text-muted">
                    velikost:
                    {{ receipt.size ?? 0 | filesize }}
                    <i
                      (click)="downloadFile(receipt)"
                      aria-hidden="true"
                      class="fa-light fa-lg fa-download ml-1"
                      style="cursor: pointer"
                    ></i>
                  </small>
                </li>
              </ol>
            </ng-template>
          </e-column>
          <e-column
            [autoFit]="true"
            [columns]="columnsItems"
            headerText="Položky objednávky"
          ></e-column>
          <e-column
            [allowFiltering]="false"
            [allowSorting]="false"
            [autoFit]="true"
            clipMode="EllipsisWithTooltip"
            field="budgets"
            headerText="Rozpočet"
          >
            <ng-template #template let-data>
              <ul
                *ngIf="data.to_storage && data.budgets"
                class="list-group scrollbar scrollbar-indigo thin"
                style="max-height: 200px; overflow-y: auto"
              >
                <li
                  *ngFor="let budget of data.budgets"
                  [ngClass]="
                                        !budget.confirmed &&
                                        !budget.declined &&
                                        !budget.deleted_date
                                            ? 'list-group-item-warning'
                                            : budget.confirmed &&
                                              !budget.declined &&
                                              !budget.deleted_date
                                            ? 'list-group-item-success'
                                            : (!budget.confirmed &&
                                                  budget.declined) ||
                                              budget.deleted_date
                                            ? 'list-group-item-danger'
                                            : 'list-group-item-secondary'
                                    "
                  class="list-group-item p-1"
                >
                  <div class="d-flex justify-content-between">
                    <a
                      [routerLink]="[
                                                '/material/budgets/',
                                                budget.id
                                            ]"
                    >
                      <strong>{{ budget.name }}</strong>
                    </a>
                    <small>{{
                        budget.department.name
                      }}</small>
                  </div>
                  <div
                    *ngIf="budget.expenditures.residue <= 0"
                    class="alert alert-danger p-1"
                    role="alert"
                  >
                    <small>
                      <i
                        aria-hidden="true"
                        class="fa-light fa-lg fa-exclamation-triangle mr-2"
                      ></i>
                      Rozpočet je
                      <strong>vyčerpán</strong>.
                    </small>
                  </div>
                  <div
                    *ngIf="
                                            data.status === 've frontě' &&
                                            !data.deleted_date &&
                                            budget.amount -
                                                budget.expenditures.success
                                                    .price -
                                                budget.expenditures_current
                                                    .price <=
                                                0
                                        "
                    class="alert alert-warning p-1"
                    role="alert"
                  >
                    <small>
                      <i
                        aria-hidden="true"
                        class="fa-light fa-lg fa-exclamation-triangle mr-2"
                      ></i>
                      Touto objednávkou bude
                      <strong>přečerpán</strong> rozpočet.
                    </small>
                  </div>
                  <hr class="p-1 m-0" />
                  <div
                    [ngClass]="{
                                            'text-danger':
                                                budget.expenditures.residue <= 0
                                        }"
                    class="d-flex text-muted justify-content-between"
                  >
                    <span>zbývá:</span>
                    <span
                    >{{
                        budget.expenditures.residue
                          | number: '1.2-2'
                      }},- CZK</span
                    >
                  </div>
                  <hr class="p-1 m-0" />
                  <div
                    class="d-flex text-danger justify-content-between"
                  >
                                        <span
                                          *ngIf="
                                                !budget.confirmed &&
                                                !budget.declined &&
                                                !budget.deleted_date
                                            "
                                        >bude čerpáno:</span
                                        >
                    <span
                      *ngIf="
                                                budget.confirmed ||
                                                budget.declined ||
                                                budget.deleted_date
                                            "
                    >bylo čerpáno:</span
                    >
                    <span>
                                            <strong
                                              *ngIf="
                                                    budget.declined ||
                                                    budget.deleted_date
                                                "
                                            >
                                                <del
                                                >{{
                                                    budget
                                                      .expenditures_current
                                                      .price
                                                      | number: '1.2-2'
                                                  }},- CZK</del
                                                >
                                            </strong>
                                            <strong
                                              *ngIf="
                                                    (!budget.confirmed &&
                                                        !budget.declined &&
                                                        !budget.deleted_date) ||
                                                    budget.confirmed
                                                "
                                            >
                                                {{
                                                budget.expenditures_current
                                                  .price
                                                  | number: '1.2-2'
                                              }},- CZK
                                            </strong>
                                        </span>
                  </div>
                </li>
              </ul>
              <div *ngIf="!data.to_storage" class="row mb-2">
                <div class="col-12">
                  <div
                    class="alert alert-primary"
                    role="alert"
                  >
                    Tato objednávka je na přeprodej, očekává
                    se její refundace.
                  </div>
                </div>
              </div>
            </ng-template>
          </e-column>
          <e-column
            [allowFiltering]="false"
            [allowSorting]="false"
            [autoFit]="true"
            clipMode="EllipsisWithTooltip"
            field="confirmers"
            headerText="Schvalovatelé"
          >
            <ng-template #template let-data>
              <ul
                *ngIf="data.confirmers"
                class="list-group col-12 scrollbar scrollbar-indigo thin"
                style="max-height: 200px; overflow-y: auto"
              >
                <li
                  *ngFor="let confirmer of data.confirmers"
                  [ngClass]="
                                        !confirmer.material_assignation_users
                                            .declined_date &&
                                        !confirmer.material_assignation_users
                                            .confirmed_date &&
                                        !confirmer.material_assignation_users
                                            .deleted_date
                                            ? 'list-group-item-warning'
                                            : (!confirmer
                                                  .material_assignation_users
                                                  .confirmed_date &&
                                                  confirmer
                                                      .material_assignation_users
                                                      .declined_date &&
                                                  !confirmer
                                                      .material_assignation_users
                                                      .deleted_date) ||
                                              confirmer
                                                  .material_assignation_users
                                                  .deleted_date
                                            ? 'list-group-item-danger'
                                            : confirmer
                                                  .material_assignation_users
                                                  .confirmed_date &&
                                              !confirmer
                                                  .material_assignation_users
                                                  .declined_date &&
                                              !confirmer
                                                  .material_assignation_users
                                                  .deleted_date
                                            ? 'list-group-item-success'
                                            : 'list-group-item-secondary'
                                    "
                  class="list-group-item p-1"
                >
                  <div class="row">
                    <div class="col-4 p-auto">
                      <img
                        alt="profilový obrázek"
                        class="img-fluid rounded-circle avatar-xs mx-auto"
                        src="assets/img{{
                                                    confirmer.personalphoto
                                                }}"
                      />
                    </div>
                    <div class="col-8 text-right">
                      {{ confirmer.secondname }}
                      {{ confirmer.firstname }}<br />
                      <small
                        *ngIf="
                                                    !confirmer
                                                        .material_assignation_users
                                                        .declined_date &&
                                                    !confirmer
                                                        .material_assignation_users
                                                        .confirmed_date &&
                                                    !confirmer
                                                        .material_assignation_users
                                                        .deleted_date
                                                "
                        class="text-muted"
                      >
                        čeká na schválení
                      </small>
                      <small
                        *ngIf="
                                                    !confirmer
                                                        .material_assignation_users
                                                        .confirmed_date &&
                                                    confirmer
                                                        .material_assignation_users
                                                        .declined_date &&
                                                    !confirmer
                                                        .material_assignation_users
                                                        .deleted_date
                                                "
                      >
                        zamítnuto:
                        {{
                          confirmer
                            .material_assignation_users
                            .declined_date
                            | dateFormat
                            : 'DD.MM.YYYY HH:mm'
                        }}
                      </small>
                      <small
                        *ngIf="
                                                    confirmer
                                                        .material_assignation_users
                                                        .confirmed_date &&
                                                    !confirmer
                                                        .material_assignation_users
                                                        .declined_date &&
                                                    !confirmer
                                                        .material_assignation_users
                                                        .deleted_date
                                                "
                      >
                        schváleno:
                        {{
                          confirmer
                            .material_assignation_users
                            .confirmed_date
                            | dateFormat
                            : 'DD.MM.YYYY HH:mm'
                        }}
                      </small>
                      <small
                        *ngIf="
                                                    confirmer
                                                        .material_assignation_users
                                                        .deleted_date
                                                "
                      >
                        zrušeno:
                        {{
                          confirmer
                            .material_assignation_users
                            .deleted_date
                            | dateFormat
                            : 'DD.MM.YYYY HH:mm'
                        }}
                      </small>
                    </div>
                  </div>
                </li>
              </ul>
            </ng-template>
          </e-column>
          <e-column
            [autoFit]="true"
            [filter]="filterSettings"
            clipMode="EllipsisWithTooltip"
            field="creator_str"
            headerText="Vytvořil"
          >
            <ng-template #template let-data>
              <div>
                <img
                  *ngIf="data.creator_img"
                  alt="profilový obrázek"
                  class="img-fluid rounded-circle avatar-xs mr-1"
                  src="assets/img{{ data.creator_img }}"
                />
                <span>{{ data.creator_str }}</span>
              </div>
            </ng-template>
          </e-column>
          <e-column
            [allowEditing]="false"
            [autoFit]="true"
            [filter]="filterDate"
            [format]="dateFormat"
            clipMode="EllipsisWithTooltip"
            editType="datepickeredit"
            field="created_date"
            headerText="Vytvořeno"
            textAlign="Right"
            type="date"
          ></e-column>
          <e-column
            [allowEditing]="false"
            [autoFit]="true"
            [filter]="filterDate"
            [format]="dateFormat"
            clipMode="EllipsisWithTooltip"
            editType="datepickeredit"
            field="updated_date"
            headerText="Naposledy upraveno"
            textAlign="Right"
            type="date"
          ></e-column>
          <e-column
            [allowEditing]="false"
            [autoFit]="true"
            [filter]="filterDate"
            [format]="dateFormat"
            clipMode="EllipsisWithTooltip"
            editType="datepickeredit"
            field="deleted_date"
            headerText="Smazáno"
            textAlign="Right"
            type="date"
          ></e-column>
        </e-columns>
        <e-aggregates>
          <e-aggregate>
            <e-columns>
              <e-column [format]="'N'" field="id" type="Count">
                <ng-template #footerTemplate let-data>
                  Celkem řádků: {{ data.Count }}
                </ng-template
                >
              </e-column>
              <e-column
                [format]="'N'"
                field="itemsAmount"
                type="Sum"
              >
                <ng-template #footerTemplate let-data>
                  Celkové množství: {{ data.Sum }}
                </ng-template>
              </e-column>
              <e-column
                [format]="'#,###.00\',- CZK\''"
                field="itemsPrice"
                type="Sum"
              >
                <ng-template #footerTemplate let-data>
                  Celkeová cena: {{ data.Sum }}
                </ng-template>
              </e-column>
            </e-columns>
          </e-aggregate>
          <e-aggregate>
            <e-columns>
              <e-column
                [format]="'N'"
                field="itemsAmount"
                type="Average"
              >
                <ng-template #footerTemplate let-data>
                  Průměrné množství: {{ data.Average }}
                </ng-template>
              </e-column>
              <e-column
                [format]="'#,###.00\',- CZK\''"
                field="itemsPrice"
                type="Average"
              >
                <ng-template #footerTemplate let-data>
                  Průměrná cena: {{ data.Average }}
                </ng-template>
              </e-column>
            </e-columns>
          </e-aggregate>
          <e-aggregate>
            <e-columns>
              <e-column
                [format]="'N'"
                field="itemsAmount"
                type="Max"
              >
                <ng-template #footerTemplate let-data>
                  Maximální množství: {{ data.Max }}
                </ng-template>
              </e-column>
              <e-column
                [format]="'#,###.00\',- CZK\''"
                field="itemsPrice"
                type="Max"
              >
                <ng-template #footerTemplate let-data>
                  Maximální cena: {{ data.Max }}
                </ng-template>
              </e-column>
            </e-columns>
          </e-aggregate>
        </e-aggregates>
      </ejs-grid>
    </div>
  </div>
  <ng-template #itemsTemplate let-data>
    <ul
      *ngIf="data.items"
      class="list-group col-12 scrollbar scrollbar-indigo thin"
      style="max-height: 200px; overflow-y: auto"
    >
      <li
        *ngFor="let item of data.items"
        [ngClass]="
                    !item.confirmed && !item.declined && !item.deleted_date
                        ? 'list-group-item-warning'
                        : (!item.confirmed && item.declined) ||
                          item.deleted_date
                        ? 'list-group-item-danger'
                        : item.confirmed && !item.declined && !item.deleted_date
                        ? 'list-group-item-success'
                        : 'list-group-item-secondary'
                "
        class="list-group-item p-1"
      >
        <small>
          {{ item.name }}&nbsp;(ks: {{ item.amount }}) za
          <strong> {{ item.price | number: '1.2-2' }},- CZK </strong>
        </small>
        <small *ngIf="item.dph" class="text-muted"> s DPH</small>
        <small *ngIf="!item.dph" class="text-muted"> bez DPH</small>
        <br />
        <small>
          {{ item.category.name }}
        </small>
      </li>
    </ul>
  </ng-template>
</div>
