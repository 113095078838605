import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    HostListener,
    Input,
    OnChanges,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Material} from '@src/app/_models/material/material';
import {MaterialPayment} from '@src/app/_models/material/material-payment';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthenticationService} from '@src/app/_services/authentication.service';
import {MessageService} from '@src/app/_services/message.service';
import {ComponentCanDeactivate} from '@src/app/_guards/changes.guard';
import {Observable} from 'rxjs';
import {User} from '@src/app/_models/user/user';
import {DropDownListComponent, FilteringEventArgs} from '@syncfusion/ej2-angular-dropdowns';
import {EmitType} from '@syncfusion/ej2-base';
import {Query} from '@syncfusion/ej2-data';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {DialogComponent} from '@syncfusion/ej2-angular-popups';
import {OrderItem} from '@src/app/_models/material/material-item';
import {MaterialService} from '@src/app/features/material/material.service';
import {SettingsService} from '@src/app/features/settings/settings.service';

@UntilDestroy()
@Component({
    selector: 'app-material-item-form',
    templateUrl: './material-item-form.component.html',
    styleUrls: ['./material-item-form.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MaterialItemFormComponent implements OnInit, OnChanges, ComponentCanDeactivate {
    // Dropdowms
    height = '240px';

    fields: object = {text: 'label', value: 'value'};

    paymentSelect: Array<{value: boolean | number | string; label: string}> = [];

    departmentSelect: Array<{value: boolean | number | string; label: string}> = [];

    categorySelect: Array<{value: boolean | number | string; label: string}> = [];

    // Forms
    speedTypeForm: FormGroup;

    isDirty = false;

    isChecked = false;

    submited = false;

    // Variables
    currentUser: User | null;

    materialItemForm: FormGroup;

    material: Material;

    payments: MaterialPayment[];

    // Loaders
    loadingMaterial = false;

    @Input() item: OrderItem | null = null;

    @Input() isCreate = false;

    @Input() isUpdate = false;

    @Input() isVisible = false;

    @Input() isCopy = false;

    @Output() readonly materialItemFormDialogState = new EventEmitter<boolean>();

    // Dialogs
    @ViewChild('formDialog') formDialogObj: DialogComponent;

    // Dropdowms
    @ViewChild('payment') paymentObj: DropDownListComponent;

    @ViewChild('department') departmentObj: DropDownListComponent;

    @ViewChild('category') categoryObj: DropDownListComponent;

    constructor(
        private readonly formBuilder: FormBuilder,
        private readonly route: ActivatedRoute,
        private readonly router: Router,
        private readonly authenticationService: AuthenticationService,
        private readonly materialsService: MaterialService,
        private readonly settingsService: SettingsService,
        private readonly messages: MessageService,
        private readonly ref: ChangeDetectorRef,
    ) {
        this.currentUser = this.authenticationService.currentUserValue;
    }

    get f(): {[key: string]: AbstractControl} {
        return this.materialItemForm.controls;
    }

    @HostListener('window:beforeunload')
    canDeactivate(): Observable<boolean> | boolean {
        return !this.isDirty;
    }

    ngOnInit(): void {
        this.materialsService
            .getPayments()
            .pipe(untilDestroyed(this))
            .subscribe(
                (payments: MaterialPayment[]) => {
                    this.payments = payments;
                    payments.map((payment: MaterialPayment) => {
                        this.paymentSelect = [
                            ...this.paymentSelect,
                            {value: payment.id, label: payment.name},
                        ];
                    });
                },
                error => {
                    console.error(error);
                },
            );

        this.materialItemForm = this.formBuilder.group({
            reason: ['', Validators.required],
            note: [''],
            payment: [false, Validators.required],
            to_storage: [false, Validators.required],
        });

        this.materialItemForm.valueChanges.pipe(untilDestroyed(this))
            .subscribe(() => {
                this.isDirty = this.materialItemForm.dirty;
            });
    }

    onFilteringCategory: EmitType<FilteringEventArgs> = (e: FilteringEventArgs) => {
        if (e.text === '') {
            e.updateData(this.categorySelect);
        } else {
            let query: Query = new Query();

            query = e.text !== '' ? query.where('label', 'contains', e.text, true, true) : query;
            e.updateData(this.categorySelect, query);
        }
    };

    onFilteringPayments: EmitType<FilteringEventArgs> = (e: FilteringEventArgs) => {
        if (e.text === '') {
            e.updateData(this.paymentSelect);
        } else {
            let query: Query = new Query();

            query = e.text !== '' ? query.where('label', 'contains', e.text, true, true) : query;
            e.updateData(this.paymentSelect, query);
        }
    };

    onFilteringDepartments: EmitType<FilteringEventArgs> = (e: FilteringEventArgs) => {
        if (e.text === '') {
            e.updateData(this.departmentSelect);
        } else {
            let query: Query = new Query();

            query = e.text !== '' ? query.where('label', 'contains', e.text, true, true) : query;
            e.updateData(this.departmentSelect, query);
        }
    };

    ngOnChanges(): void {
        if (this.isVisible) {
            if (this.isUpdate && this.item) {
                this.materialItemForm.reset();
                this.formDialogObj.header = `Položka #${this.item.id}`;
                this.fillTheForm();
                this.showInputErrors();
            }

            if (this.isCreate) {
                this.materialItemForm.reset();
                this.formDialogObj.header = 'Nová položka objednávky';
            }

            if (this.isCopy) {
                this.materialItemForm.reset();
                this.formDialogObj.header = 'Nová položka objednávky';
                this.fillTheForm();
                this.showInputErrors();
            }
        }
    }

    changeDialogState(value: boolean): void {
        this.materialItemFormDialogState.emit(value);
    }

    fillTheForm(): void {
        this.materialItemForm.controls.reason.patchValue(this.material.reason);
        this.materialItemForm.controls.note.patchValue(this.material.note);
        this.materialItemForm.controls.payment.patchValue(this.material.payment_id);
        this.materialItemForm.controls.to_storage.patchValue(this.material.to_storage);
    }

    onSubmit(): void {
        this.submited = true;

        if (this.materialItemForm.invalid) {
            console.error('form is not valid!');

            return;
        }

        this.isDirty = false;
        this.loadingMaterial = true;
        this.material.reason = this.f.reason.value;
        this.material.to_storage = this.f.to_storage.value;
        this.material.payment_id = this.f.payment.value;
        this.material.note = this.f.note.value;
        this.material.updated_by = this.currentUser?.id;
        this.materialsService
            .updateInvoice(this.material)
            ?.pipe(untilDestroyed(this))
            .subscribe(
                () => {
                    const body = 'Schvalování vyresetováno...';
                    const options = {progressBar: true, timeOut: 5000};

                    this.messages.showSuccess(
                        'Objednávka materiálu úspěšně upravena',
                        body,
                        options,
                    );
                    this.loadingMaterial = false;
                    this.isUpdate = false;
                    this.loadingMaterial = false;
                    this.ref.markForCheck();
                    this.formDialogObj.hide();
                },
                error => {
                    console.error(error);

                    const body = 'Zkuste to znovu později';
                    const options = {
                        progressBar: true,
                        timeOut: 5000,
                        toastClass: 'red',
                    };

                    this.messages.showError(
                        'Chyba při úpravě objednávky materiálu,',
                        body,
                        options,
                    );
                    this.loadingMaterial = false;
                    this.ref.markForCheck();
                },
            );
    }

    showInputErrors(): void {
        this.isChecked = true;
        /* console.log('VALID: ' + this.ticketForm.valid);
        Object.keys(this.ticketForm.controls).forEach(key => {
            const controlErrors: ValidationErrors = this.ticketForm.get(key).errors;
            if (controlErrors != null) {
                Object.keys(controlErrors).forEach(keyError => {
                    console.log('Key control: ' + key + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError]);
                });
            }
        });*/
        this.materialItemForm.markAllAsTouched();
        this.ref.markForCheck();
    }
}
