import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {AuthenticationService} from '@src/app/_services/authentication.service';
import {User} from '@src/app/_models/user/user';
import {Task} from '@src/app/_models/task/task';
import {TaskNotes} from '@src/app/_models/task/task-notes';
import {TasksService} from '@src/app/features/tasks/tasks.service';

@Component({
    selector: 'app-task-notes',
    templateUrl: './task-notes.component.html',
    styleUrls: ['./task-notes.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TaskNotesComponent {
    @Input() task?: Task | null;
    @Input() isSolving?: boolean | null;

    @Output() readonly deleteTaskNoteEvent = new EventEmitter<TaskNotes>();
    @Output() readonly editTaskNoteEvent = new EventEmitter<TaskNotes>();
    @Output() readonly editSolutionNoteEvent = new EventEmitter<boolean>();
    @Output() readonly reactiveTaskEvent = new EventEmitter<boolean>();

    currentUser: User | null;
    live: true;

    constructor(
        private readonly authenticationService: AuthenticationService,
        public tasksService: TasksService,
    ) {
        this.currentUser = this.authenticationService.currentUserValue;
    }

    deleteNote(note: TaskNotes): void {
        this.deleteTaskNoteEvent.emit(note);
    }

    editNote(note: TaskNotes): void {
        this.editTaskNoteEvent.emit(note);
    }

    reactiveTask(): void {
        this.reactiveTaskEvent.emit(true);
    }

    editSolution(): void {
        this.editSolutionNoteEvent.emit(true);
    }
}
