import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthenticationService} from '@src/app/_services/authentication.service';
import {DataService} from '@src/app/_services/data.service';
import {registerLocaleData} from '@angular/common';

import moment from 'moment';
import localeCs from '@angular/common/locales/cs';
import {User} from '@src/app/_models/user/user';
import {NavHandlerService} from '@src/app/_services/nav-handler.service';
import {MessageService} from '@src/app/_services/message.service';
import {PermissionService} from '@src/app/_services/permission.service';
import {ExcelService} from '@src/app/_services/excel.service';
import {UntilDestroy} from '@ngneat/until-destroy';
import {HotlineService} from '@src/app/features/hotline/hotline.service';

moment.updateLocale('cs', {workingWeekdays: [1, 2, 3, 4, 5]});
registerLocaleData(localeCs);

@UntilDestroy()
@Component({
    selector: 'app-notification-dashboard',
    templateUrl: './notification-dashboard.component.html',
    styleUrls: ['./notification-dashboard.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationDashboardComponent implements OnInit {
    // Variables
    currentUser: User | null;

    isDirty = false;

    // Loaders
    loadingNotification = true;

    constructor(
        private readonly router: Router,
        private readonly ref: ChangeDetectorRef,
        private readonly route: ActivatedRoute,
        private readonly navHandlerService: NavHandlerService,
        private readonly dataService: DataService,
        private readonly permissionService: PermissionService,
        private readonly hotlineService: HotlineService,
        private readonly excelService: ExcelService,
        private readonly messages: MessageService,
        private readonly authenticationService: AuthenticationService,
    ) {
        this.currentUser = this.authenticationService.currentUserValue;
    }

    ngOnInit(): void {}
}
