import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    HostListener,
    Input,
    OnChanges,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import {ComponentCanDeactivate} from '@src/app/_guards/changes.guard';
import {AddressConnectedRuian} from '@src/app/_models/services/address-connected-ruian';
import {DialogComponent} from '@syncfusion/ej2-angular-popups';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {User} from '@src/app/_models/user/user';
import {DataService} from '@src/app/_services/data.service';
import {DataNetbaseService} from '@src/app/_services/data-netbase.service';
import {ServicesService, SmartAddress} from '@src/app/features/services/services.service';
import {MessageService} from '@src/app/_services/message.service';
import {AuthenticationService} from '@src/app/_services/authentication.service';
import {Observable} from 'rxjs';
import {
    DropDownListComponent,
    FilteringEventArgs,
    MultiSelectComponent,
} from '@syncfusion/ej2-angular-dropdowns';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {Department} from '@src/app/_models/department/department';
import {Company} from '@src/app/_models/company/company';
import {NetPackages} from '@src/app/_models/services/net-packages';
import {TvServices} from '@src/app/_models/services/tv-services';
import {TechnologyTypes} from '@src/app/_models/services/technology-types';
import {UnitTypes} from '@src/app/_models/services/unit-types';
import {SpeedTypes} from '@src/app/_models/services/speed-types';
import {DealTypes} from '@src/app/_models/services/deal-types';
import {Discounts} from '@src/app/_models/services/discounts';
import {SpaceTypes} from '@src/app/_models/services/space-types';
import {TransferTypes} from '@src/app/_models/services/transfer-types';
import {Project} from '@src/app/_models/projects/project';
import {EmitType} from '@syncfusion/ej2-base';
import {Query} from '@syncfusion/ej2-data';
import moment from 'moment';
import {PermissionService} from '@src/app/_services/permission.service';
import {MomentInput} from 'moment/moment';

declare let smartform;

@UntilDestroy()
@Component({
    selector: 'app-ruian-form',
    templateUrl: './ruian-form.component.html',
    styleUrls: ['./ruian-form.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RuianFormComponent implements OnInit, OnChanges, ComponentCanDeactivate {
    // Forms
    ruianForm: FormGroup;

    isDirty = false;

    isChecked = false;

    submited = false;

    // Dropdowns
    ruianFieldObj: object = {text: 'label', value: 'value'};

    height = '240px';

    departmentSelect: Array<{value: boolean | number | string; label: string}> = [];

    usersSelect: Array<{value: boolean | number | string; label: string}> = [];

    typeSelect: Array<{value: boolean | number | string; label: string}> = [];

    ownerSelect: Array<{value: boolean | number | string; label: string}> = [];

    viewSelect: Array<{value: boolean | number | string; label: string}> = [];

    companySelect: Array<{value: boolean | number | string; label: string}> = [];

    technologySelect: Array<{value: boolean | number | string; label: string}> = [];

    unitSelect: Array<{value: boolean | number | string; label: string}> = [];

    dealSelect: Array<{value: boolean | number | string; label: string}> = [];

    discountSelect: Array<{value: boolean | number | string; label: string}> = [];

    transferSelect: Array<{value: boolean | number | string; label: string}> = [];

    spaceSelect: Array<{value: boolean | number | string; label: string}> = [];

    servicesNetSelect: Array<{value: boolean | number | string; label: string}> = [];

    servicesTvSelect: Array<{value: boolean | number | string; label: string}> = [];

    projectSelect: Array<{value: boolean | number | string; label: string}> = [];

    speedsSelect: Array<{value: boolean | number | string; label: string}> = [];

    // Variables
    currentUser: User | null;

    ruians: AddressConnectedRuian[] = [];

    project: Project;

    validRuian: boolean | null = null;

    smartWholeAddress = null;

    // Loaders
    loadingRuian = false;

    @Input() ruian: AddressConnectedRuian | null = null;

    @Input() isCreate = false;

    @Input() isUpdate = false;

    @Input() isVisible = false;

    @Input() isCopy = false;

    @Output() readonly ruianFormDialogState = new EventEmitter<boolean>();

    // Dialogs
    @ViewChild('formDialog') formDialogObj: DialogComponent;

    // Dropdowns
    @ViewChild('creator') creatorObj: DropDownListComponent;

    @ViewChild('type') typeObj: DropDownListComponent;

    @ViewChild('deal_type') dealTypeObj: DropDownListComponent;

    @ViewChild('unit_type') unitTypeObj: DropDownListComponent;

    @ViewChild('provider') providerObj: DropDownListComponent;

    @ViewChild('discount') discountObj: DropDownListComponent;

    @ViewChild('flat_space') flatSpaceObj: DropDownListComponent;

    @ViewChild('speed_type') speedTypeObj: DropDownListComponent;

    @ViewChild('hardware') hardwareObj: DropDownListComponent;

    @ViewChild('technology') technologyObj: DropDownListComponent;

    @ViewChild('transfer') transferObj: DropDownListComponent;

    @ViewChild('project') projectObj: DropDownListComponent;

    @ViewChild('net_packages') netPackagesObj: MultiSelectComponent;

    @ViewChild('tv_services') tvServicesObj: MultiSelectComponent;

    constructor(
        private readonly formBuilder: FormBuilder,
        private readonly ref: ChangeDetectorRef,
        private readonly dataService: DataService,
        private readonly dataNetbaseService: DataNetbaseService,
        private readonly servicesService: ServicesService,
        private readonly permissionService: PermissionService,
        private readonly messages: MessageService,
        private readonly authenticationService: AuthenticationService,
    ) {
        this.currentUser = this.authenticationService.currentUserValue;
    }

    get f(): {[key: string]: AbstractControl} {
        return this.ruianForm.controls;
    }

    private checkRuianUnique(ruian): AddressConnectedRuian | null {
        if (this.ruians.length > 0) {
            const match = this.ruians.find(addr => addr.ruian === ruian);

            return typeof match !== 'undefined' ? match : null;
        }

        return null;
    }

    @HostListener('window:beforeunload')
    canDeactivate(): Observable<boolean> | boolean {
        return !this.isDirty;
    }

    reinitSmartForm(): void {
        console.info('Reinitialize SF objects...');
        smartform.rebindAllForms(false, () => {
            if (smartform.getInstance('smartform-instance-create')) {
                const instanceCreate = smartform.getInstance('smartform-instance-create');

                instanceCreate.setZIndex(100000000);
                instanceCreate.setSelectionBackgroundColor('#4285f3');
                instanceCreate.addAnnounceCallback((validationType, addressArray) => {
                    if (validationType === smartform.ValidationResultType.HIT) {
                        const addressData: SmartAddress = {};

                        this.smartWholeAddress = addressArray[0].ADDRESS_WHOLE;
                        this.ruianForm.controls.address.patchValue(this.smartWholeAddress);
                        addressData.oriental_number = parseInt(
                            addressArray[0].NUMBER_ORIENTACNI as string,
                            10,
                        );

                        addressData.house_number = parseInt(
                            addressArray[0].NUMBER_POPISNE as string,
                            10,
                        );
                        addressData.street = addressArray[0].STREET;
                        addressData.street_code = parseInt(
                            addressArray[0].STREET_CODE as string,
                            10,
                        );
                        addressData.city_part = addressArray[0].PART;
                        addressData.city_part_code = parseInt(
                            addressArray[0].PART_CODE as string,
                            10,
                        );
                        addressData.city = addressArray[0].CITY;
                        addressData.city_code = parseInt(addressArray[0].CITY_CODE as string, 10);
                        addressData.zip = parseInt(addressArray[0].ZIP as string, 10);
                        addressData.district = addressArray[0].DISTRICT;
                        addressData.district_code = parseInt(
                            addressArray[0].DISTRICT_CODE as string,
                            10,
                        );
                        addressData.region = addressArray[0].REGION;
                        addressData.region_code = parseInt(
                            addressArray[0].REGION_CODE as string,
                            10,
                        );

                        addressData.number_of_units = parseInt(
                            addressArray[0].NUMBER_OF_FLATS as string,
                            10,
                        );

                        addressData.number_of_storeys = parseInt(
                            addressArray[0].NUMBER_OF_STOREYS as string,
                            10,
                        );
                        addressData.lift = addressArray[0].BUILDING_WITH_LIFT === 'true';
                        addressData.state = addressArray[0].COUNTRY;
                        addressData.gps = `${addressArray[0].GPS_LAT as string} / ${
                            addressArray[0].GPS_LONG as string
                        }`;

                        addressData.jstk = `${addressArray[0].COORD_X as string} / ${
                            addressArray[0].COORD_Y as string
                        }`;
                        this.addressValidator(addressData);
                        this.ruianForm.controls.ruian.setValue(addressArray[0].CODE, {
                            emitEvent: true,
                        });
                    } else {
                        this.ruianForm.controls.ruian.setValue(0, {emitEvent: true});
                    }

                    this.ref.markForCheck();
                });
            }
        });
    }

    // eslint-disable-next-line max-lines-per-function
    ngOnInit(): void {
        this.ownerSelect = [
            {value: 'all', label: 'všechny adresy'},
            {value: 'deleted', label: 'smazané adresy'},
            {value: 'active', label: 'připojené adresy'},
        ];

        this.viewSelect = [
            {value: 'all', label: 'všechny adresy'},
            {value: 'deleted', label: 'smazané adresy'},
            {value: 'active', label: 'připojené adresy'},
        ];

        this.dataService.departmentSource.pipe(untilDestroyed(this))
            .subscribe({
                next: (data: Department[]) => {
                    data.map((department: Department) => {
                        this.departmentSelect = [
                            ...this.departmentSelect,
                            {
                                value: department.id,
                                label: `${department.company.name} - ${department.name}`,
                            },
                        ];
                    });
                },
                error: error => {
                    console.error(error);
                },
            });

        this.dataService.userSource.pipe(untilDestroyed(this))
            .subscribe({
                next: (data: User[]) => {
                    data.map((user: User) => {
                        if (user.authorized && !user.deleted_date) {
                            this.usersSelect = [
                                ...this.usersSelect,
                                {
                                    value: user.id,
                                    label: `${user.firstname} ${user.secondname}`,
                                },
                            ];
                        }
                    });
                },
                error: error => {
                    console.error(error);
                },
            });

        this.dataService.companySource.pipe(untilDestroyed(this))
            .subscribe({
                next: (companies: Company[]) => {
                    this.companySelect = [];
                    companies.map((company: Company) => {
                        if (!company.deleted_date) {
                            this.companySelect = [
                                ...this.companySelect,
                                {
                                    value: company.id,
                                    label: company.name,
                                },
                            ];
                        }
                    });
                    this.ref.markForCheck();
                },
                error: error => {
                    console.error(error);
                },
            });

        this.dataNetbaseService.netPackagesSource.pipe(untilDestroyed(this))
            .subscribe({
                next: (netTarifs: NetPackages[]) => {
                    this.servicesNetSelect = [];
                    netTarifs.map((netTarif: NetPackages) => {
                        if (!netTarif.deleted_date) {
                            this.servicesNetSelect = [
                                ...this.servicesNetSelect,
                                {
                                    value: netTarif.id,
                                    label: `${netTarif.name}${
                                        netTarif.transfer.connection_method === 'optical cable'
                                            ? ' - optické připojení'
                                            : netTarif.transfer.connection_method === 'metalic cable'
                                                ? ' - kabelové připojení'
                                                : netTarif.transfer.connection_method === 'radio'
                                                    ? ' - rádiové připojení'
                                                    : '- xDSL připojení'
                                    }`,
                                },
                            ];
                        }
                    });
                    this.ref.markForCheck();
                },
                error: error => {
                    console.error(error);
                },
            });

        this.dataNetbaseService.tvServicesSource.pipe(untilDestroyed(this))
            .subscribe({
                next: (tvServices: TvServices[]) => {
                    this.servicesTvSelect = [];
                    tvServices.map((tvService: TvServices) => {
                        if (!tvService.deleted_date) {
                            this.servicesTvSelect = [
                                ...this.servicesTvSelect,
                                {
                                    value: tvService.id,
                                    label: `${tvService.name}${
                                        tvService.transfer.connection_method === 'optical cable'
                                            ? ' - optické připojení'
                                            : tvService.transfer.connection_method === 'metalic cable'
                                                ? ' - kabelové připojení'
                                                : tvService.transfer.connection_method === 'radio'
                                                    ? ' - rádiové připojení'
                                                    : '- xDSL připojení'
                                    }`,
                                },
                            ];
                        }
                    });
                    this.ref.markForCheck();
                },
                error: error => {
                    console.error(error);
                },
            });

        this.dataNetbaseService.technologyTypesSource.pipe(untilDestroyed(this))
            .subscribe({
                next: (technologies: TechnologyTypes[]) => {
                    this.technologySelect = [];
                    technologies.map((technology: TechnologyTypes) => {
                        if (!technology.deleted_date) {
                            this.technologySelect = [
                                ...this.technologySelect,
                                {value: technology.id, label: technology.name},
                            ];
                        }
                    });
                    this.ref.markForCheck();
                },
                error: error => {
                    console.error(error);
                },
            });

        this.dataNetbaseService.unitTypesSource.pipe(untilDestroyed(this))
            .subscribe({
                next: (units: UnitTypes[]) => {
                    this.unitSelect = [];
                    units.map((unit: UnitTypes) => {
                        if (!unit.deleted_date) {
                            this.unitSelect = [
                                ...this.unitSelect,
                                {value: unit.id, label: unit.name},
                            ];
                        }
                    });
                    this.ref.markForCheck();
                },
                error: error => {
                    console.error(error);
                },
            });

        this.dataNetbaseService.speedTypesSource.pipe(untilDestroyed(this))
            .subscribe({
                next: (speeds: SpeedTypes[]) => {
                    this.speedsSelect = [];
                    speeds.sort((a, b) => {
                        const aValue = a.speed_until;
                        const bValue = b.speed_until;

                        return aValue > bValue ? -1 : 1;
                    });

                    speeds.map((speed: SpeedTypes) => {
                        if (!speed.deleted_date) {
                            this.speedsSelect = [
                                ...this.speedsSelect,
                                {
                                    value: speed.id,
                                    label: `${speed.speed_until} Mbit - ${
                                        speed.speed_to > 0 ? speed.speed_to : '*'
                                    } Mbit`,
                                },
                            ];
                        }
                    });
                    this.ref.markForCheck();
                },
                error: error => {
                    console.error(error);
                },
            });

        this.dataNetbaseService.dealTypesSource.pipe(untilDestroyed(this))
            .subscribe({
                next: (deals: DealTypes[]) => {
                    this.dealSelect = [];
                    deals.map((deal: DealTypes) => {
                        if (!deal.deleted_date) {
                            this.dealSelect = [
                                ...this.dealSelect,
                                {value: deal.id, label: `${deal.age} měsíců`},
                            ];
                        }
                    });
                    this.ref.markForCheck();
                },
                error: error => {
                    console.error(error);
                },
            });

        this.dataNetbaseService.discountSource.pipe(untilDestroyed(this))
            .subscribe({
                next: (discounts: Discounts[]) => {
                    this.discountSelect = [];
                    discounts.map((discount: Discounts) => {
                        if (!discount.deleted_date) {
                            this.discountSelect = [
                                ...this.discountSelect,
                                {value: discount.id, label: `${discount.discount}%`},
                            ];
                        }
                    });
                    this.ref.markForCheck();
                },
                error: error => {
                    console.error(error);
                },
            });

        this.dataNetbaseService.spaceTypesSource.pipe(untilDestroyed(this))
            .subscribe({
                next: (spaces: SpaceTypes[]) => {
                    this.spaceSelect = [];
                    spaces.map((space: SpaceTypes) => {
                        if (!space.deleted_date) {
                            this.spaceSelect = [
                                ...this.spaceSelect,
                                {
                                    value: space.id,
                                    label: `${space.metric_until} m2 - ${
                                        space.metric_to ? space.metric_to : '*'
                                    } m2`,
                                },
                            ];
                        }
                    });
                    this.ref.markForCheck();
                },
                error: error => {
                    console.error(error);
                },
            });

        this.dataNetbaseService.transferTypesSource.pipe(untilDestroyed(this))
            .subscribe({
                next: (transfers: TransferTypes[]) => {
                    this.transferSelect = [];
                    transfers.map((transfer: TransferTypes) => {
                        if (!transfer.deleted_date) {
                            this.transferSelect = [
                                ...this.transferSelect,
                                {
                                    value: transfer.id,
                                    label: transfer.name,
                                },
                            ];
                        }
                    });
                    this.ref.markForCheck();
                },
                error: error => {
                    console.error(error);
                },
            });

        this.dataService.projectSource.pipe(untilDestroyed(this))
            .subscribe({
                next: (projects: Project[]) => {
                    this.projectSelect = [];
                    projects.map((project: Project) => {
                        if (!project.deleted_date) {
                            this.projectSelect = [
                                ...this.projectSelect,
                                {
                                    value: project.id,
                                    label: project.name,
                                },
                            ];
                        }
                    });
                    this.ref.markForCheck();
                },
                error: error => {
                    console.error(error);
                },
            });

        this.dataNetbaseService.ruianAddressSource.pipe(untilDestroyed(this))
            .subscribe({
                next: (addresses: AddressConnectedRuian[]) => {
                    this.ruians = addresses;
                },
                error: error => {
                    console.error(error);
                },
            });

        this.ruianForm = this.formBuilder.group({
            address: ['', Validators.required],
            ruian: [{value: null, disabled: true}],
            provider_id: [null, Validators.required],
            deal_id: [null],
            unit_id: [null],
            space_id: [null],
            speed_id: [null],
            project_id: [null],
            discount_id: [null],
            transfer_id: [null, Validators.required],
            technology_id: [null, Validators.required],
            keys: [false],
            electrical_counter: [''],
            clip_frame: [false],
            business_note: [''],
            descriptive: [''],
            marketingnote: [''],
            marketing_ads: [''],
            owner_name: [''],
            manager_name: [''],
            number_of_units: [
                {value: 1, disabled: true},
                Validators.compose([Validators.required, Validators.min(1), Validators.max(1000)]),
            ],
            number_of_storeys: [
                {value: 0, disabled: true},
                Validators.compose([Validators.required, Validators.min(0), Validators.max(1000)]),
            ],
            lift: [{value: false, disabled: true}],
            realized_at: [null],
            net_packages: [null, Validators.required],
            tv_services: [null],
            oriental_number: [{value: null, disabled: true}],
            house_number: [{value: null, disabled: true}, Validators.required],
            street: [{value: null, disabled: true}],
            street_code: [{value: null, disabled: true}],
            city: [{value: null, disabled: true}, Validators.required],
            city_code: [{value: null, disabled: true}],
            city_part: [{value: null, disabled: true}],
            city_part_code: [{value: null, disabled: true}],
            zip: [{value: null, disabled: true}, Validators.required],
            district: [{value: null, disabled: true}, Validators.required],
            district_code: [{value: null, disabled: true}],
            region: [{value: null, disabled: true}, Validators.required],
            region_code: [{value: null, disabled: true}],
            state: [{value: 'Česká republika', disabled: true}, Validators.required],
            gps: [{value: null, disabled: true}],
            jstk: [{value: null, disabled: true}],
        });

        this.ruianForm.valueChanges.pipe(untilDestroyed(this))
            .subscribe(() => {
                this.isDirty = this.ruianForm.dirty;
                this.showInputErrors();
            });

        this.ruianForm
            .get('ruian')
            ?.valueChanges
            .pipe(untilDestroyed(this))
            .subscribe(value => {
                if (value > 0) {
                    this.ruianValidator(value);
                }
            });

        this.ruianForm
            .get('provider_id')
            ?.valueChanges
            .pipe(untilDestroyed(this))
            .subscribe(value => {
                if (value) {
                    this.dataNetbaseService.netPackagesSource.pipe(untilDestroyed(this))
                        .subscribe({
                            next: (netTarifs: NetPackages[]) => {
                                this.servicesNetSelect = [];
                                this.f.net_packages.patchValue([]);
                                netTarifs.map((netTarif: NetPackages) => {
                                    if (
                                        !netTarif.deleted_date &&
                                        this.f.transfer_id.value === netTarif.transfer_id &&
                                        value === netTarif.provider_id
                                    ) {
                                        this.servicesNetSelect = [
                                            ...this.servicesNetSelect,
                                            {
                                                value: netTarif.id,
                                                label: `${netTarif.name}${
                                                    netTarif.transfer.connection_method ===
                                                    'optical cable'
                                                        ? ' - optické připojení'
                                                        : netTarif.transfer.connection_method ===
                                                        'metalic cable'
                                                            ? ' - kabelové připojení'
                                                            : netTarif.transfer.connection_method ===
                                                            'radio'
                                                                ? ' - rádiové připojení'
                                                                : '- xDSL připojení'
                                                }`,
                                            },
                                        ];
                                    }
                                });
                                this.ref.markForCheck();
                            },
                            error: error => {
                                console.error(error);
                            },
                        });

                    this.dataNetbaseService.tvServicesSource.pipe(untilDestroyed(this))
                        .subscribe({
                            next: (tvServices: TvServices[]) => {
                                this.servicesTvSelect = [];
                                this.f.tv_services.patchValue([]);
                                tvServices.map((tvService: TvServices) => {
                                    if (
                                        !tvService.deleted_date &&
                                        this.f.transfer_id.value === tvService.transfer_id &&
                                        value === tvService.provider_id
                                    ) {
                                        this.servicesTvSelect = [
                                            ...this.servicesTvSelect,
                                            {
                                                value: tvService.id,
                                                label: `${tvService.name}${
                                                    tvService.transfer.connection_method ===
                                                    'optical cable'
                                                        ? ' - optické připojení'
                                                        : tvService.transfer.connection_method ===
                                                        'metalic cable'
                                                            ? ' - kabelové připojení'
                                                            : tvService.transfer.connection_method ===
                                                            'radio'
                                                                ? ' - rádiové připojení'
                                                                : '- xDSL připojení'
                                                }`,
                                            },
                                        ];
                                    }
                                });
                                this.ref.markForCheck();
                            },
                            error: error => {
                                console.error(error);
                            },
                        });
                }
            });

        this.ruianForm
            .get('transfer_id')
            ?.valueChanges
            .pipe(untilDestroyed(this))
            .subscribe(value => {
                if (value) {
                    this.dataNetbaseService.netPackagesSource.pipe(untilDestroyed(this))
                        .subscribe({
                            next: (netTarifs: NetPackages[]) => {
                                this.servicesNetSelect = [];
                                this.f.net_packages.patchValue([]);
                                netTarifs.map((netTarif: NetPackages) => {
                                    if (
                                        !netTarif.deleted_date &&
                                        this.f.provider_id.value === netTarif.provider_id &&
                                        value === netTarif.transfer_id
                                    ) {
                                        this.servicesNetSelect = [
                                            ...this.servicesNetSelect,
                                            {
                                                value: netTarif.id,
                                                label: `${netTarif.name}${
                                                    netTarif.transfer.connection_method ===
                                                    'optical cable'
                                                        ? ' - optické připojení'
                                                        : netTarif.transfer.connection_method ===
                                                        'metalic cable'
                                                            ? ' - kabelové připojení'
                                                            : netTarif.transfer.connection_method ===
                                                            'radio'
                                                                ? ' - rádiové připojení'
                                                                : '- xDSL připojení'
                                                }`,
                                            },
                                        ];
                                    }
                                });
                                this.ref.markForCheck();
                            },
                            error: error => {
                                console.error(error);
                            },
                        });

                    this.dataNetbaseService.tvServicesSource.pipe(untilDestroyed(this))
                        .subscribe({
                            next: (tvServices: TvServices[]) => {
                                this.servicesTvSelect = [];
                                this.f.tv_services.patchValue([]);
                                tvServices.map((tvService: TvServices) => {
                                    if (
                                        !tvService.deleted_date &&
                                        this.f.provider_id.value === tvService.provider_id &&
                                        value === tvService.transfer_id
                                    ) {
                                        this.servicesTvSelect = [
                                            ...this.servicesTvSelect,
                                            {
                                                value: tvService.id,
                                                label: `${tvService.name}${
                                                    tvService.transfer.connection_method ===
                                                    'optical cable'
                                                        ? ' - optické připojení'
                                                        : tvService.transfer.connection_method ===
                                                        'metalic cable'
                                                            ? ' - kabelové připojení'
                                                            : tvService.transfer.connection_method ===
                                                            'radio'
                                                                ? ' - rádiové připojení'
                                                                : '- xDSL připojení'
                                                }`,
                                            },
                                        ];
                                    }
                                });
                                this.ref.markForCheck();
                            },
                            error: error => {
                                console.error(error);
                            },
                        });
                }
            });
    }

    ngOnChanges(): void {
        if (this.isVisible) {
            this.reinitSmartForm();
            this.resetForm();

            if (this.isCreate) {
                this.formDialogObj.header = 'Nová připojená RUIAN adresa';
            }

            if (this.isCopy) {
                this.formDialogObj.header = 'Nová připojená RUIAN adresa';
                this.fillTheForm();
                this.showInputErrors();
            }

            if (this.isUpdate && this.ruian) {
                this.formDialogObj.header = `Připojená RUIAN adresa #${this.ruian.id} - ${this.ruian.address}`;
                this.fillTheForm();
                this.showInputErrors();
            }
        }
    }

    changeDialogState(value: boolean): void {
        this.ruianFormDialogState.emit(value);
    }

    onFilteringTvPackages: EmitType<FilteringEventArgs> = (e: FilteringEventArgs) => {
        if (e.text === '') {
            e.updateData(this.servicesTvSelect);
        } else {
            let query: Query = new Query();

            query = e.text !== '' ? query.where('label', 'contains', e.text, true, true) : query;
            e.updateData(this.servicesTvSelect, query);
        }
    };

    onFilteringNetPackages: EmitType<FilteringEventArgs> = (e: FilteringEventArgs) => {
        if (e.text === '') {
            e.updateData(this.servicesNetSelect);
        } else {
            let query: Query = new Query();

            query = e.text !== '' ? query.where('label', 'contains', e.text, true, true) : query;
            e.updateData(this.servicesNetSelect, query);
        }
    };

    ruianValidator(ruianCode): void {
        this.ruian = this.checkRuianUnique(ruianCode);
        this.validRuian = !!this.ruian?.ruian;

        if (this.validRuian && this.ruian) {
            this.isCreate = false;
            this.isCopy = false;
            this.isUpdate = true;
            this.formDialogObj.header = `Připojená RUIAN adresa #${this.ruian.id} - ${this.ruian.address}`;
            this.fillTheForm();

            if (this.ruian.address_obj) {
                this.addressValidator(this.ruian.address_obj);
            }

            this.showInputErrors();
        }
    }

    addressValidator(address): void {
        if (address) {
            this.ruianForm.controls.oriental_number.patchValue(address.oriental_number);
            this.ruianForm.controls.house_number.patchValue(address.house_number);
            this.ruianForm.controls.street.patchValue(address.street);
            this.ruianForm.controls.street_code.patchValue(address.street_code);
            this.ruianForm.controls.city_part.patchValue(address.city_part);
            this.ruianForm.controls.city_part_code.patchValue(address.city_part_code);
            this.ruianForm.controls.city.patchValue(address.city);
            this.ruianForm.controls.city_code.patchValue(address.city_code);
            this.ruianForm.controls.zip.patchValue(address.zip);
            this.ruianForm.controls.district.patchValue(address.district);
            this.ruianForm.controls.district_code.patchValue(address.district_code);
            this.ruianForm.controls.region.patchValue(address.region);
            this.ruianForm.controls.region_code.patchValue(address.region_code);
            this.ruianForm.controls.state.patchValue(address.state);
            this.ruianForm.controls.gps.patchValue(address.gps);
            this.ruianForm.controls.jstk.patchValue(address.jstk);
            this.ruianForm.controls.zip.patchValue(address.zip);
            this.ruianForm.controls.lift.patchValue(address.lift);
            this.ruianForm.controls.number_of_units.patchValue(
                address.number_of_units ? address.number_of_units : 1,
            );

            this.ruianForm.controls.number_of_storeys.patchValue(
                address.number_of_storeys ? address.number_of_storeys : 1,
            );
        }
    }

    resetForm(): void {
        this.validRuian = false;
        this.ruianForm.controls.address.reset();
        this.ruianForm.controls.ruian.reset();
        this.ruianForm.controls.electrical_counter.reset();
        this.ruianForm.controls.business_note.reset();
        this.ruianForm.controls.descriptive.reset();
        this.ruianForm.controls.marketingnote.reset();
        this.ruianForm.controls.marketing_ads.reset();
        this.ruianForm.controls.owner_name.reset();
        this.ruianForm.controls.manager_name.reset();
        this.ruianForm.controls.number_of_units.reset();
        this.ruianForm.controls.realized_at.reset();
        this.ruianForm.controls.provider_id.reset();
        this.ruianForm.controls.net_packages.reset();
        this.ruianForm.controls.tv_services.reset();
        this.ruianForm.controls.unit_id.reset();
        this.ruianForm.controls.deal_id.reset();
        this.ruianForm.controls.discount_id.reset();
        this.ruianForm.controls.space_id.reset();
        this.ruianForm.controls.project_id.reset();
        this.ruianForm.controls.technology_id.reset();
        this.ruianForm.controls.transfer_id.reset();
        this.ruianForm.controls.oriental_number.reset();
        this.ruianForm.controls.house_number.reset();
        this.ruianForm.controls.street.reset();
        this.ruianForm.controls.street_code.reset();
        this.ruianForm.controls.city_part.reset();
        this.ruianForm.controls.city_part_code.reset();
        this.ruianForm.controls.city.reset();
        this.ruianForm.controls.city_code.reset();
        this.ruianForm.controls.zip.reset();
        this.ruianForm.controls.district.reset();
        this.ruianForm.controls.district_code.reset();
        this.ruianForm.controls.region.reset();
        this.ruianForm.controls.region_code.reset();
        this.ruianForm.controls.state.reset();
        this.ruianForm.controls.gps.reset();
        this.ruianForm.controls.jstk.reset();
        this.ruianForm.controls.zip.reset();
        this.ruianForm.controls.state.patchValue('Česká republika');
        this.ruianForm.controls.number_of_units.patchValue(1);
        this.ruianForm.controls.number_of_storeys.patchValue(0);
        this.ruianForm.controls.keys.patchValue(false);
        this.ruianForm.controls.clip_frame.patchValue(false);
        this.ruianForm.controls.lift.patchValue(false);
    }

    fillTheForm(): void {
        const netPackages: number[] = [];
        const tvServices: number[] = [];

        this.ruian?.net_packages.forEach((netPackage: NetPackages) => {
            netPackages.push(netPackage.id);
        });

        this.ruian?.tv_services?.forEach((tvService: TvServices) => {
            tvServices.push(tvService.id);
        });
        this.ruianForm.controls.address.patchValue(this.ruian?.address);

        if (!(this.validRuian && this.ruian)) {
            this.ruianForm.controls.ruian.patchValue(this.ruian?.ruian);
        }

        this.ruianForm.controls.provider_id.patchValue(this.ruian?.provider_id);
        this.ruianForm.controls.deal_id.patchValue(this.ruian?.deal_id);
        this.ruianForm.controls.unit_id.patchValue(this.ruian?.unit_id);
        this.ruianForm.controls.space_id.patchValue(this.ruian?.space_id);
        this.ruianForm.controls.speed_id.patchValue(this.ruian?.speed_id);
        this.ruianForm.controls.project_id.patchValue(this.ruian?.project_id);
        this.ruianForm.controls.discount_id.patchValue(this.ruian?.discount_id);
        this.ruianForm.controls.transfer_id.patchValue(this.ruian?.transfer_id);
        this.ruianForm.controls.technology_id.patchValue(this.ruian?.technology_id);
        this.ruianForm.controls.keys.patchValue(this.ruian?.keys);
        this.ruianForm.controls.electrical_counter.patchValue(this.ruian?.electrical_counter);
        this.ruianForm.controls.clip_frame.patchValue(this.ruian?.clip_frame);
        this.ruianForm.controls.business_note.patchValue(this.ruian?.business_note);
        this.ruianForm.controls.descriptive.patchValue(this.ruian?.descriptive);
        this.ruianForm.controls.marketingnote.patchValue(this.ruian?.marketingnote);
        this.ruianForm.controls.marketing_ads.patchValue(this.ruian?.marketing_ads);
        this.ruianForm.controls.owner_name.patchValue(this.ruian?.owner_name);
        this.ruianForm.controls.manager_name.patchValue(this.ruian?.manager_name);
        this.ruianForm.controls.number_of_units.patchValue(this.ruian?.number_of_units);
        this.ruianForm.controls.number_of_storeys.patchValue(this.ruian?.number_of_storeys);
        this.ruianForm.controls.lift.patchValue(this.ruian?.lift);
        this.ruianForm.controls.realized_at.patchValue(this.ruian?.realized_at);
        this.ruianForm.controls.net_packages.patchValue(netPackages);
        this.ruianForm.controls.tv_services.patchValue(tvServices);
    }

    onSubmit(): void {
        this.submited = true;
        this.isDirty = false;
        this.loadingRuian = true;

        if (this.isCreate || this.isCopy) {
            this.createRuian();
        }

        if (this.isUpdate) {
            this.editRuian();
        }
    }

    createRuian(): void {
        if (this.ruianForm.invalid) {
            console.error('form is not valid!');

            return;
        }

        this.loadingRuian = true;
        this.ref.markForCheck();

        if (this.currentUser && this.permissionService.checkUserISServiceAdmin(this.currentUser)) {
            const data = {
                keys: !!this.f.keys.value,
                electrical_counter: this.f.electrical_counter.value,
                clip_frame: !!this.f.clip_frame.value,
                business_note: this.f.business_note.value,
                descriptive: this.f.descriptive.value,
                marketingnote: this.f.marketingnote.value,
                marketing_ads: this.f.marketing_ads.value,
                owner_name: this.f.owner_name.value,
                manager_name: this.f.manager_name.value,
                number_of_units: this.f.number_of_units.value,
                ruian: this.f.ruian.value,
                address: this.smartWholeAddress,
                provider_id: this.f.provider_id.value,
                project_id: this.f.project_id.value,
                realized_at: this.f.realized_at.value
                    ? moment(this.f.realized_at.value as MomentInput)
                        .format('YYYY-MM-DD HH:mm:ss')
                    : null,
                unit_id: this.f.unit_id.value,
                deal_id: this.f.deal_id.value,
                discount_id: this.f.discount_id.value,
                space_id: this.f.space_id.value,
                transfer_id: this.f.transfer_id.value,
                net_packages: this.f.net_packages.value,
                tv_services: this.f.tv_services.value,
                technology_id: this.f.technology_id.value,
                created_by: this.currentUser.id,
                valid_ruian: !!this.f.ruian.value,
                oriental_number: this.f.oriental_number.value,
                house_number: this.f.house_number.value,
                street: this.f.street.value,
                street_code: this.f.street_code.value,
                city: this.f.city.value,
                city_code: this.f.city_code.value,
                city_part: this.f.city_part.value,
                city_part_code: this.f.city_part_code.value,
                district: this.f.district.value,
                district_code: this.f.district_code.value,
                region: this.f.region.value,
                region_code: this.f.region_code.value,
                state: this.f.state.value,
                gps: this.f.gps.value,
                jstk: this.f.jstk.value,
                speed_id: this.f.speed_id.value,
                number_of_storeys: this.f.number_of_storeys.value,
                lift: this.f.lift.value,
                zip: this.f.zip.value,
            };

            this.servicesService
                .addAddressRuian(data)
                .pipe(untilDestroyed(this))
                .subscribe({
                    next: (ruian: AddressConnectedRuian) => {
                        const body = `Adresa #${ruian.id}`;
                        const options = {
                            progressBar: true,
                            timeOut: 5000,
                            toastClass: 'success',
                        };

                        this.messages.showSuccess('Adresa úspěšně přidána', body, options);
                        this.loadingRuian = false;
                        this.isCreate = false;
                        this.dataNetbaseService.setRuianAddressDataSource();
                        this.formDialogObj.hide();
                        this.ref.markForCheck();
                    },
                    error: error => {
                        console.error(error);

                        const body = 'Zkuste to později ...';
                        const options = {
                            progressBar: true,
                            timeOut: 5000,
                            toastClass: 'red',
                        };

                        this.messages.showError(
                            'Chyba během přidávání připojené adresy',
                            body,
                            options,
                        );
                        this.loadingRuian = false;
                        this.ref.markForCheck();
                    },
                });
        } else {
            const body = 'Adresu nemůžete vytvořit';
            const options = {progressBar: true, timeOut: 5000};

            this.messages.showError('Nedostatečné oprávnění', body, options);
            this.loadingRuian = false;
            this.isCreate = false;
            this.formDialogObj.hide();
            this.ref.markForCheck();
        }
    }

    editRuian(): void {
        if (this.ruianForm.invalid) {
            console.error('form is not valid!');

            return;
        }

        this.loadingRuian = true;
        this.ref.markForCheck();

        if (this.currentUser && this.permissionService.checkUserISServiceAdmin(this.currentUser)) {
            const data = {
                id: this.ruian?.id,
                keys: !!this.f.keys.value,
                electrical_counter: this.f.electrical_counter.value,
                clip_frame: !!this.f.clip_frame.value,
                business_note: this.f.business_note.value,
                descriptive: this.f.descriptive.value,
                marketingnote: this.f.marketingnote.value,
                marketing_ads: this.f.marketing_ads.value,
                owner_name: this.f.owner_name.value,
                manager_name: this.f.manager_name.value,
                number_of_units: this.f.number_of_units.value,
                ruian: this.f.ruian.value,
                address: this.smartWholeAddress,
                provider_id: this.f.provider_id.value,
                project_id: this.f.project_id.value,
                realized_at: this.f.realized_at.value
                    ? moment(this.f.realized_at.value as MomentInput)
                        .format('YYYY-MM-DD HH:mm:ss')
                    : null,
                unit_id: this.f.unit_id.value,
                deal_id: this.f.deal_id.value,
                discount_id: this.f.discount_id.value,
                space_id: this.f.space_id.value,
                transfer_id: this.f.transfer_id.value,
                net_packages: this.f.net_packages.value,
                tv_services: this.f.tv_services.value,
                technology_id: this.f.technology_id.value,
                created_by: this.currentUser.id,
                valid_ruian: !!this.f.ruian.value,
                oriental_number: this.f.oriental_number.value,
                house_number: this.f.house_number.value,
                street: this.f.street.value,
                street_code: this.f.street_code.value,
                city: this.f.city.value,
                city_code: this.f.city_code.value,
                city_part: this.f.city_part.value,
                city_part_code: this.f.city_part_code.value,
                district: this.f.district.value,
                district_code: this.f.district_code.value,
                region: this.f.region.value,
                region_code: this.f.region_code.value,
                state: this.f.state.value,
                gps: this.f.gps.value,
                jstk: this.f.jstk.value,
                speed_id: this.f.speed_id.value,
                number_of_storeys: this.f.number_of_storeys.value,
                lift: this.f.lift.value,
                zip: this.f.zip.value,
            };

            this.servicesService
                .updateAddressRuian(data)
                ?.pipe(untilDestroyed(this))
                .subscribe({
                    next: (ruian: AddressConnectedRuian) => {
                        const body = `Adresa #${ruian.id}`;
                        const options = {
                            progressBar: true,
                            timeOut: 5000,
                            toastClass: 'success',
                        };

                        this.messages.showSuccess('Adresa úspěšně upravena', body, options);
                        this.loadingRuian = false;
                        this.isUpdate = false;
                        this.dataNetbaseService.setRuianAddressDataSource();
                        this.formDialogObj.hide();
                        this.ref.markForCheck();
                    },
                    error: error => {
                        console.error(error);

                        const body = 'Zkuste to později ...';
                        const options = {
                            progressBar: true,
                            timeOut: 5000,
                            toastClass: 'red',
                        };

                        this.messages.showError(
                            'Chyba během upravování připojené adresy',
                            body,
                            options,
                        );
                        this.loadingRuian = false;
                        this.ref.markForCheck();
                    },
                });
        } else {
            const body = 'Adresu nemůžete upravit';
            const options = {progressBar: true, timeOut: 5000};

            this.messages.showError('Nedostatečné oprávnění', body, options);
            this.loadingRuian = false;
            this.isUpdate = false;
            this.formDialogObj.hide();
            this.ref.markForCheck();
        }
    }

    showInputErrors(): void {
        this.isChecked = true;
        /* console.log('VALID: ' + this.ticketForm.valid);
        Object.keys(this.ticketForm.controls).forEach(key => {
            const controlErrors: ValidationErrors = this.ticketForm.get(key).errors;
            if (controlErrors != null) {
                Object.keys(controlErrors).forEach(keyError => {
                    console.log('Key control: ' + key + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError]);
                });
            }
        });*/
        this.ruianForm.markAllAsTouched();
        this.ref.markForCheck();
    }
}
