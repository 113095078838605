import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {Tickets} from '@src/app/_models/ticket/tickets';
import {TicketAssignation} from '@src/app/_models/assignation/ticket_assignation';
import {catchError, shareReplay, tap} from 'rxjs/operators';
import {MessageService} from '@src/app/_services/message.service';
import {User} from '@src/app/_models/user/user';
import {Department} from '@src/app/_models/department/department';
import {TicketFile} from '@src/app/_models/ticket/ticket-file';
import {Assignation} from '@src/app/_models/assignation/assignation';
import {TicketNotes} from '@src/app/_models/ticket/ticket-notes';
import {DataService} from '@src/app/_services/data.service';

import moment from 'moment';
import localeCs from '@angular/common/locales/cs';
import {registerLocaleData} from '@angular/common';
import {SafeHtml} from '@angular/platform-browser';
import {Tags} from '@src/app/_models/tags/tags';
import {containsObject} from '@src/app/_helpers/utils';
import {BaseModel} from '@src/app/_models/_base/base-model';
import {EnvironmentService} from '@src/app/_services/environment.service';

moment.updateLocale('cs', {workingWeekdays: [1, 2, 3, 4, 5]});
registerLocaleData(localeCs);

export interface TicketGridItems {
    id: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    category_id: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    status_id: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    created_by: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    creator_str: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    creator_img: string;
    subject: string;
    maintask: string;
    category: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    business_id?: number | null;
    status: string;
    due: string;
    users: string;
    departments: string;
    tags: SafeHtml;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    start_deadline: Date | null;
    deadline: Date | null;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    created_date: Date;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    updated_date: Date | null;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    deleted_date: Date | null;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    finished_at: Date | null;
}

export interface TicketImportItems {
    id: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    created_by: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    creator_str: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    creator_img: string;
    subject: string;
    maintask: string;
    category: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    business_id?: number | null;
    status: string;
    due: string;
    users: string;
    departments: string;
    tags: SafeHtml;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    start_deadline: Date | null;
    deadline: Date | null;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    created_date: Date;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    updated_date: Date | null;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    deleted_date: Date | null;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    finished_at: Date | null;
}

export interface TicketLogsItems {
    id: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    type_id: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    ticket_id: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    category_id: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    business_id?: number | null;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    status_id: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    created_by: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    creator_str: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    creator_img: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    updated_by: number | null | undefined;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    editor_str: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    editor_img: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    deleted_by: number | null | undefined;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    remover_str: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    remover_img: string;
    type: string;
    subject: string;
    maintask: string;
    deadline: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    created_date: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    updated_date: string | null | undefined;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    deleted_date: string | null;
    timestamp: Date;
}

export interface TicketInput {
    id?: number;
    subject?: string;
    maintask?: string;
    solution?: string | null;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    start_deadline?: Date | string | null;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    finished_at?: Date | string | null;
    deadline?: Date | string | null;
    tags?: Tags[];
    users?: User[];
    departments?: Department[];
    // eslint-disable-next-line @typescript-eslint/naming-convention
    business_id?: number | null;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    solved_by?: number | null;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    updated_by?: number | null;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    status_id?: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    category_id?: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    created_by?: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    deleted_by?: number | null;
}

export interface TicketNoteInput {
    text: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    ticket_id: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    created_by?: number;
}

const httpOptions = {
    headers: new HttpHeaders({'Content-Type': 'application/json'}),
};

@Injectable({
    providedIn: 'root',
})
export class TicketsService {
    private readonly ticketsUrl: string;

    private readonly ticketsNotesUrl: string;

    private readonly ticketsAssignationUrl: string;

    private readonly ticketsFilesUrl: string;

    private readonly ticketFileUrl: string;

    private readonly logsUrl: string;

    ticket: Tickets;

    currentAdministrators: BehaviorSubject<number[]> = new BehaviorSubject<number[]>([]);

    constructor(
        private readonly http: HttpClient,
        private readonly dataService: DataService,
        private readonly messageService: MessageService,
        private readonly environmentService: EnvironmentService,
    ) {
        this.ticketsUrl = this.environmentService.backendURL + '/api/ticket';
        this.ticketsNotesUrl = this.environmentService.backendURL + '/api/ticket/note';
        this.ticketsAssignationUrl = this.environmentService.backendURL + '/api/ticket/membership';
        this.ticketsFilesUrl = this.environmentService.backendURL + '/api/file/download/';
        this.ticketFileUrl = this.environmentService.backendURL + '/api/file';
        this.logsUrl = this.environmentService.backendURL + '/api/ticket/log';
    }

    /**
     * Handle Http operation that failed.
     * Let the app continue.
     * @param operation - name of the operation that failed
     * @param result - optional value to return as the observable result
     */
    private handleError<T>(operation = 'operation', result?: T) {
        return (error: {[key: string]: string}): Observable<T | undefined> => {
            // TODO: send the error to remote logging infrastructure
            console.error(error); // log to console instead

            // TODO: better job of transforming error for user consumption
            this.log(`${operation} failed: ${error.message}`);

            // Let the app keep running by returning an empty result.
            // eslint-disable-next-line @typescript-eslint/no-unsafe-return
            return of(result);
        };
    }

    /** Log a ticketService message with the MessageService */
    private log(message: string): void {
        this.messageService.addNotification(`Ticket: ${message}`, false);
    }

    downloadFile(file: TicketFile): Observable<Blob | TicketFile | undefined> {
        return this.http
            .post(
                `${this.ticketsFilesUrl}`,
                {file: file.name, module: 'ticket', id: file.ticket_id},
                {
                    responseType: 'blob',
                    headers: new HttpHeaders().append('Content-Type', 'application/json'),
                },
            )
            .pipe(
                tap(() => {
                    this.log(`start download ticket file name=${file.name}`);
                }),
                catchError(this.handleError<TicketFile>(`file name=${file.name}`)),
            );
    }

    downloadImportSheet(file: string): Observable<Blob | TicketFile | undefined> {
        return this.http
            .post(
                `${this.ticketsFilesUrl}`,
                {file, module: 'ticket_sheet'},
                {
                    responseType: 'blob',
                    headers: new HttpHeaders().append('Content-Type', 'application/json'),
                },
            )
            .pipe(
                tap(() => {
                    this.log(`start download ticket file name=${file}`);
                }),
                catchError(this.handleError<TicketFile>(`file name=${file}`)),
            );
    }

    addTicket(ticket: TicketInput): Observable<BaseModel> {
        this.dataService.clearTicketsCache();
        this.dataService.clearTagsCache();

        return this.http.post<BaseModel>(this.ticketsUrl, ticket, httpOptions);
    }

    addSolvingNote(note: TicketNoteInput): Observable<TicketNotes> {
        this.dataService.clearTicketsCache();

        return this.http.post<TicketNotes>(this.ticketsNotesUrl, note, httpOptions);
    }

    deleteTicketNote(note: TicketNotes): Observable<TicketNotes | undefined> | undefined {
        if (!note.deleted_by) {
            console.error('note.deleted_by is missing...');

            return;
        }

        const headers = new HttpHeaders({'Content-Type': 'application/json'});
        const params = new HttpParams().set('deleted_by', note.deleted_by.toString());
        const url = `${this.ticketsNotesUrl}/${note.id}`;

        this.dataService.clearTicketsCache();

        return this.http.delete<TicketNotes>(url, {headers, params});
    }

    updateTicketNote(note: TicketNotes): Observable<BaseModel> {
        const url = `${this.ticketsNotesUrl}/${note.id}`;

        this.dataService.clearTicketsCache();

        return this.http.put<BaseModel>(url, note, httpOptions);
    }

    updateTicket(ticket: TicketInput): Observable<BaseModel> | undefined {
        if (!ticket.id) {
            console.error('ID is missing...');

            return;
        }

        const url = `${this.ticketsUrl}/update/${ticket.id}`;

        this.dataService.clearTicketsCache();
        this.dataService.clearTagsCache();

        return this.http.put<BaseModel>(url, ticket, httpOptions);
    }

    deleteTicket(ticket: Tickets): Observable<Tickets | undefined> | undefined {
        if (!ticket.deleted_by) {
            console.error('deleted_by is missing...');

            return;
        }

        const url = `${this.ticketsUrl}/ticket/${ticket.id}`;
        const params = new HttpParams().set('deleted_by', ticket.deleted_by.toString());
        const headers = new HttpHeaders({'Content-Type': 'application/json'});

        this.dataService.clearTicketsCache();

        return this.http.delete<Tickets>(url, {headers, params});
    }

    setAssignations(
        ticket: Tickets | number,
        creator: User | number,
        users?: number[],
        departments?: number[],
    ): Observable<TicketAssignation | undefined> {
        const ticketId = typeof ticket === 'number' ? ticket : ticket.id;
        const creatorId = typeof creator === 'number' ? creator : creator.id;
        const data = {
            ticket_id: ticketId,
            creator_id: creatorId,
            users: users ?? [],
            departments: departments ?? [],
        };

        this.dataService.clearTicketsCache();

        return this.http
            .post<TicketAssignation>(`${this.ticketsAssignationUrl}`, data, httpOptions)
            .pipe(
                tap(() => {
                    this.log(`updated ticket id=${ticketId}`);
                }),
                catchError(this.handleError<TicketAssignation>(`ticket id=${ticketId}`)),
            );
    }

    setUsersViewsTimestamps(
        ticket: Tickets | number,
        user: User,
    ): Observable<TicketAssignation | undefined> {
        const ticketId = typeof ticket === 'number' ? ticket : ticket.id;
        const data = {
            ticket_id: ticketId,
            user_id: user.id,
            viewed_at: !user.ticket_assignation_users?.viewed_at
                ? moment()
                    .format('YYYY-MM-DD HH:mm:ss')
                : user.ticket_assignation_users.viewed_at,
            last_viewed_at: moment()
                .format('YYYY-MM-DD HH:mm:ss'),
        };

        this.dataService.clearTicketsCache();

        return this.http
            .put<TicketAssignation>(`${this.ticketsAssignationUrl}`, data, httpOptions)
            .pipe(
                tap(() => {
                    this.log(`updated ticket id=${ticketId}`);
                }),
                catchError(this.handleError<TicketAssignation>(`ticket id=${ticketId}`)),
            );
    }

    setDepartmentsViewsTimestamps(
        ticket: Tickets | number,
        departments: Department,
    ): Observable<TicketAssignation | undefined> {
        const ticketId = typeof ticket === 'number' ? ticket : ticket.id;
        const data = {
            ticket_id: ticketId,
            department_id: departments.id,
            viewed_at: !departments.ticket_assignation_departs.viewed_at
                ? moment()
                    .format('YYYY-MM-DD HH:mm:ss')
                : departments.ticket_assignation_departs.viewed_at,
            last_viewed_at: moment()
                .format('YYYY-MM-DD HH:mm:ss'),
        };

        this.dataService.clearTicketsCache();

        return this.http
            .put<TicketAssignation>(`${this.ticketsAssignationUrl}`, data, httpOptions)
            .pipe(
                tap(() => {
                    this.log(`updated ticket id=${ticketId}`);
                }),
                catchError(this.handleError<TicketAssignation>(`ticket id=${ticketId}`)),
            );
    }

    deleteDepartmentAssignation(
        department: Department | number,
        canceler: User | number,
        ticket: Tickets | number,
    ): Observable<TicketAssignation | undefined> | undefined {
        const departmentId = typeof department === 'number' ? department : department.id;
        const ticketId = typeof ticket === 'number' ? ticket : ticket.id;
        const deletedBy = typeof canceler === 'number' ? canceler : canceler.id;
        const headers = new HttpHeaders({'Content-Type': 'application/json'});
        const params = new HttpParams()
            .set('department_id', departmentId.toString())
            .set('ticket_id', ticketId.toString())
            .set('deleted_by', deletedBy.toString());

        this.dataService.clearTicketsCache();

        return this.http.delete<TicketAssignation>(`${this.ticketsAssignationUrl}`, {
            headers,
            params,
        });
    }

    deleteUserAssignation(
        user: User | number,
        canceler: User | number,
        ticket: Tickets | number,
    ): Observable<TicketAssignation | undefined> | undefined {
        const userId = typeof user === 'number' ? user : user.id;
        const ticketId = typeof ticket === 'number' ? ticket : ticket.id;
        const deletedBy = typeof canceler === 'number' ? canceler : canceler.id;
        const headers = new HttpHeaders({'Content-Type': 'application/json'});
        const params = new HttpParams()
            .set('user_id', userId.toString())
            .set('ticket_id', ticketId.toString())
            .set('deleted_by', deletedBy.toString());

        this.dataService.clearTicketsCache();

        return this.http.delete<TicketAssignation>(`${this.ticketsAssignationUrl}`, {
            headers,
            params,
        });
    }

    promoteUser(
        ticket: Tickets | number,
        user: User | number,
    ): Observable<TicketAssignation | undefined> {
        const ticketId = typeof ticket === 'number' ? ticket : ticket.id;
        const userId = typeof user === 'number' ? user : user.id;
        const data = {
            ticket_id: ticketId,
            user_id: userId,
            can_manage: true,
        };

        this.dataService.clearTicketsCache();

        return this.http
            .put<TicketAssignation>(`${this.ticketsAssignationUrl}`, data, httpOptions)
            .pipe(
                tap(() => {
                    this.log(`updated ticket id=${ticketId}`);
                }),
                catchError(this.handleError<TicketAssignation>(`ticket id=${ticketId}`)),
            );
    }

    unPromoteUser(
        ticket: Tickets | number,
        user: User | number,
    ): Observable<TicketAssignation | undefined> {
        const ticketId = typeof ticket === 'number' ? ticket : ticket.id;
        const userId = typeof user === 'number' ? user : user.id;
        const data = {
            ticket_id: ticketId,
            user_id: userId,
            can_manage: false,
        };

        this.dataService.clearTicketsCache();

        return this.http
            .put<TicketAssignation>(`${this.ticketsAssignationUrl}`, data, httpOptions)
            .pipe(
                tap(() => {
                    this.log(`updated ticket id=${ticketId}`);
                }),
                catchError(this.handleError<TicketAssignation>(`ticket id=${ticketId}`)),
            );
    }

    setPromotedAdministrators(ticket: Tickets): void {
        const administrators: number[] = [];

        ticket.users.forEach((user: User) => {
            if (user.ticket_assignation_users?.can_manage) {
                administrators.push(user.id);
            }
        });

        this.currentAdministrators.next(administrators);
    }

    checkPromotedAdministrators(user: User): boolean {
        return containsObject(user, this.currentAdministrators.getValue());
    }

    deleteFile(file: TicketFile): Observable<TicketFile | undefined> | undefined {
        if (!file.deleted_by) {
            console.error('deleted_by is missing...');

            return;
        }

        const headers = new HttpHeaders({'Content-Type': 'application/json'});
        const params = new HttpParams()
            .set('type', 'ticket')
            .set('deleted_by', file.deleted_by.toString());
        const url = `${this.ticketFileUrl}/${file.id}`;

        this.dataService.clearTicketsCache();

        return this.http.delete<TicketFile>(url, {headers, params});
    }

    checkTicketAssignation(ticket: Tickets, user: User): boolean {
        let amIIn = false;

        ticket.users.forEach((data: User) => {
            if (data.id === user.id) {
                amIIn = true;
            }
        });

        ticket.departments.forEach((data: Department) => {
            user.assignations?.forEach((assignation: Assignation) => {
                if (data.id === assignation.department_id) {
                    amIIn = true;
                }
            });
        });

        return amIIn;
    }

    getLogs(ticket: Tickets | number): Observable<BaseModel | undefined> {
        const id = typeof ticket === 'number' ? ticket : ticket.id;
        const url = `${this.logsUrl}/${id}`;

        return this.http.get<BaseModel>(url)
            .pipe(
                tap(() => {
                    this.log('fetched ticket logs');
                }),
                shareReplay(), // this tells Rx to cache the latest emitted
                catchError(this.handleError<BaseModel>('getTicketLogs', new BaseModel())),
            );
    }
}
