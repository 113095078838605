import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {AuthenticationService} from '@src/app/_services/authentication.service';
import {PermissionService} from '@src/app/_services/permission.service';
import {User} from '@src/app/_models/user/user';
import {Knowledge} from '@src/app/_models/knowledge/knowledge';
import {KnowledgeService} from '@src/app/features/knowledge/knowledge.service';

@Component({
    selector: 'app-knowledge-toolbar',
    templateUrl: './knowledge-toolbar.component.html',
    styleUrls: ['./knowledge-toolbar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KnowledgeToolbarComponent {
    @Input() knowledge?: Knowledge | null;

    @Output() readonly loading = new EventEmitter<boolean>();

    @Output() readonly editKnowledgeEvent = new EventEmitter<boolean>();

    @Output() readonly deleteKnowledgeEvent = new EventEmitter<boolean>();

    currentUser: User | null;

    constructor(
        private readonly authenticationService: AuthenticationService,
        public knowledgeService: KnowledgeService,
        public permissionService: PermissionService,
    ) {
        this.currentUser = this.authenticationService.currentUserValue;
    }

    editKnowledge(): void {
        this.editKnowledgeEvent.emit(true);
    }

    deleteKnowledge(): void {
        this.deleteKnowledgeEvent.emit(true);
    }
}
