<ejs-dialog
  #deleteFileDialog
  [buttons]="buttonsDeleteFileDialog"
  [content]="'Opravdu chcete smazat tuto fotku zařízení?'"
  [header]="'Smazat fotku zařízení'"
  [height]="'200px'"
  [isModal]="true"
  [showCloseIcon]="true"
  [visible]="false"
  [width]="'450px'"
  id="deleteFileDialog"
  target="#formDialog"
></ejs-dialog>
<ejs-dialog
  #formDialog
  (close)="
        isUpdate = false;
        isCreate = false;
        isDirty = false;
        isChecked = false;
        isCopy = false;
        submited = false;
        changeDialogState(false)
    "
  [isModal]="true"
  [showCloseIcon]="true"
  [visible]="isVisible"
  [width]="'85%'"
  id="formDialog"
>
  <form [formGroup]="hardwareForm">
    <ng-template #content>
      <div class="dialogContent">
        <div class="row">
          <div class="col-12 mb-3">
            <label
              [ngClass]="
                                f.name.valid && (f.name.dirty || f.name.touched)
                                    ? 'text-success'
                                    : f.name.invalid &&
                                      (f.name.dirty || f.name.touched)
                                    ? 'text-danger'
                                    : 'text-muted'
                            "
              for="hardwareForm-name"
            >* Název zařízení:</label
            >
            <ejs-textbox
              [ngClass]="
                                (f.name.dirty || f.name.touched) &&
                                f.name.invalid
                                    ? 'e-error'
                                    : (f.name.dirty || f.name.touched) &&
                                      f.name.valid
                                    ? 'e-success'
                                    : ''
                            "
              floatLabelType="Auto"
              formControlName="name"
              id="hardwareForm-name"
            ></ejs-textbox>
            <small
              *ngIf="
                                (f.name.dirty || f.name.touched) &&
                                f.name.errors &&
                                f.name.errors.required
                            "
              class="text-danger"
            >Název zařízení je povinný</small
            >
          </div>
          <div class="col-12 mb-3">
            <label
              [ngClass]="
                                f.price.valid &&
                                (f.price.dirty || f.price.touched)
                                    ? 'text-success'
                                    : f.price.invalid &&
                                      (f.price.dirty || f.price.touched)
                                    ? 'text-danger'
                                    : 'text-muted'
                            "
              for="hardwareForm-price"
            >* Nákupní cena zařízení:</label
            >
            <ejs-numerictextbox
              [format]="'#,###.00\',- CZK\''"
              [ngClass]="
                                (f.price.dirty || f.price.touched) &&
                                f.price.invalid
                                    ? 'e-error'
                                    : (f.price.dirty || f.price.touched) &&
                                      f.price.valid
                                    ? 'e-success'
                                    : ''
                            "
              formControlName="price"
              id="hardwareForm-price"
              step="0.01"
            ></ejs-numerictextbox>
            <small
              *ngIf="
                                (f.price.dirty || f.price.touched) &&
                                f.price.errors &&
                                f.price.errors.required
                            "
              class="text-danger"
            >Nákupní cena zařízení je povinná</small
            >
          </div>
          <div class="col-12 mb-3">
            <label
              [ngClass]="
                                f.fee.valid && (f.fee.dirty || f.fee.touched)
                                    ? 'text-success'
                                    : f.fee.invalid &&
                                      (f.fee.dirty || f.fee.touched)
                                    ? 'text-danger'
                                    : 'text-muted'
                            "
              for="hardwareForm-fee"
            >* Poplatek za zařízení:</label
            >
            <ejs-numerictextbox
              [format]="'#,###.00\',- CZK\''"
              [ngClass]="
                                (f.fee.dirty || f.fee.touched) && f.fee.invalid
                                    ? 'e-error'
                                    : (f.fee.dirty || f.fee.touched) &&
                                      f.fee.valid
                                    ? 'e-success'
                                    : ''
                            "
              formControlName="fee"
              id="hardwareForm-fee"
              step="0.01"
            ></ejs-numerictextbox>
            <small
              *ngIf="
                                (f.fee.dirty || f.fee.touched) &&
                                f.fee.errors &&
                                f.fee.errors.required
                            "
              class="text-danger"
            >Poplatek za zařízení</small
            >
          </div>
          <div class="col-12 mb-3">
            <ejs-uploader
              #preloadupload
              (removing)="onFileRemove($event)"
              (selected)="onFileSelected($event)"
              (success)="onSuccessUpload($event)"
              [asyncSettings]="path"
              [autoUpload]="autoUpload"
              [dropArea]="dropElement"
              allowedExtensions=".jpg,.png,.jpeg"
              id="preloadfileupload"
              maxFileSize="5000000"
              multiple="false"
            >
              <e-files *ngIf="hardware && hardware.photo_url">
                <e-uploadedfiles
                  [name]="
                                        hardware.photo_url.substring(
                                            hardware.photo_url.lastIndexOf(
                                                '/'
                                            ) + 1
                                        ) | fileExtension: false
                                    "
                  size="0"
                  type="{{
                                        hardware.photo_url.substring(
                                            hardware.photo_url.lastIndexOf(
                                                '/'
                                            ) + 1
                                        ) | fileExtension: true
                                    }}"
                ></e-uploadedfiles>
              </e-files>
            </ejs-uploader>
            <small
              *ngIf="
                                (f.photoUrl.dirty || f.photoUrl.touched) &&
                                f.photoUrl.errors &&
                                f.photoUrl.errors.required
                            "
              class="text-danger"
            >Fotka zařízení je povinná</small
            >
          </div>
        </div>
      </div>
    </ng-template>
    <ng-template #footerTemplate>
      <div class="d-flex justify-content-end">
                <span
                  *ngIf="hardwareForm.invalid"
                  class="e-badge e-badge-warning p-2 size_2"
                  role="alert"
                >
                    <strong>Formulář je špatně vyplněný</strong>
                </span>
        <span
          *ngIf="hardwareForm.valid"
          class="e-badge e-badge-success p-2 size_2"
          role="alert"
        >
                    <strong>Formulář je správně vyplněný</strong>
                </span>
      </div>
      <div class="row">
        <div class="col-12 pt-2">
          <button
            (click)="onSubmit()"
            [disabled]="
                            loadingHardware ||
                            hardwareForm.invalid ||
                            !isChecked
                        "
            cssClass="e-btn e-success btn-p-zero e-small mr-1"
            ejs-button
            type="submit"
          >
                        <span
                          *ngIf="loadingHardware === true"
                          aria-hidden="true"
                          class="spinner-grow spinner-grow-sm"
                          role="status"
                        ></span>
            <span *ngIf="loadingHardware === false">ULOŽIT</span>
            <span *ngIf="loadingHardware === true"
            >Provádím...</span
            >
          </button>
          <button
            (click)="formDialogObj.hide()"
            cssClass="e-btn e-danger e-outline btn-p-zero e-small"
            ejs-button
            type="button"
          >
            ZRUŠIT
          </button>
        </div>
      </div>
    </ng-template>
  </form>
</ejs-dialog>
