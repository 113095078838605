<div class="row d-flex justify-content-center modalWrapper">
  <app-event-form
    (eventFormDialogState)="
            renderEventFormDialog = $event;
            isCreate = $event;
            isUpdate = $event;
            isCopy = $event
        "
    [event]="
            clickedRow && clickedRow[0] && (isUpdate || isCopy)
                ? clickedRow[0]
                : null
        "
    [isCopy]="isCopy"
    [isCreate]="isCreate"
    [isUpdate]="isUpdate"
    [isVisible]="renderEventFormDialog"
  ></app-event-form>
  <ejs-dialog
    #deleteEventDialog
    [buttons]="buttonsDeleteEventDialog"
    [content]="'Opravdu chcete smazat označenou událost?'"
    [header]="'Smazat událost'"
    [height]="'200px'"
    [isModal]="true"
    [showCloseIcon]="true"
    [visible]="false"
    [width]="'450px'"
    id="deleteEventDialog"
  ></ejs-dialog>
</div>
<div class="row mt-3">
  <div class="col-12 col-md-6 col-lg-5">
    <div class="row">
      <div class="col-12 text-right">
        <button
          (click)="isCreate = true; renderEventFormDialog = true"
          class="e-btn e-primary btn-p-zero e-small mb-4"
          type="button"
        >
          Přidat událost
        </button>
      </div>
      <div *ngIf="loadingEvents" class="col-12 text-center">
        <app-loader></app-loader>
      </div>
      <div class="col-12">
        <ejs-accordion>
          <e-accordionitems>
            <e-accordionitem
              *ngIf="!loadingEvents && eventsDisplay.length > 0"
              expanded="true"
            >
              <ng-template #header>
                                <span>
                                    <i
                                      aria-hidden="true"
                                      class="fa-light fa-users mr-1"
                                    ></i>
                                    Události
                                </span>
                <span
                  *ngIf="eventsDisplay.length > 0"
                  class="e-badge e-badge-primary"
                >{{ eventsDisplay.length }}</span
                >
              </ng-template>
              <ng-template #content>
                <div>
                  <ng-container
                    *ngFor="let event of eventsDisplay"
                  >
                    <div class="media mb-3">
                      <div
                        class="d-flex justify-content-between align-items-center"
                      >
                        <h5
                          *ngIf="
                                                    today ===
                                                    (event.start_at
                                                        | date: 'yyyy-MM-dd')
                                                "
                          class="h5-responsive font-weight-bold mr-3"
                        >
                          {{
                            event.start_at
                              | date: 'HH:mm'
                          }}<br /><small
                          class="text-muted"
                        >začátek</small
                        >
                        </h5>
                        <div
                          *ngIf="
                                                        today ===
                                                            (event.end_at
                                                                | date
                                                                    : 'yyyy-MM-dd') &&
                                                        today ===
                                                            (event.start_at
                                                                | date
                                                                    : 'yyyy-MM-dd')
                                                    "
                          class="arrow"
                        >
                        </div>
                        <h5
                          *ngIf="
                                                    today ===
                                                    (event.end_at
                                                        | date: 'yyyy-MM-dd')
                                                "
                          class="h5-responsive font-weight-bold mr-3"
                        >
                          {{
                            event.end_at | date: 'HH:mm'
                          }}<br /><small
                          class="text-muted"
                        >konec</small
                        >
                        </h5>
                        <h5
                          *ngIf="
                                                    today !==
                                                        (event.end_at
                                                            | date
                                                                : 'yyyy-MM-dd') &&
                                                    today !==
                                                        (event.start_at
                                                            | date
                                                                : 'yyyy-MM-dd')
                                                "
                          class="h5-responsive font-weight-bold mr-3"
                        >
                          Celý den
                        </h5>
                      </div>
                      <div class="media-body mb-3">
                        <div
                          class="d-flex justify-content-between align-items-center"
                        >
                          <div class="btn-container">
                            <button
                              (click)="
                                                        isCreate = false;
                                                        isUpdate = false;
                                                        isCopy = false;
                                                        clickRow(event);
                                                        deleteEventDialog.show()
                                                    "
                              cssClass="e-btn e-danger e-outline btn-p-zero e-small m-auto"
                              ejs-button
                              style="
                                                                cursor: pointer;
                                                            "
                              title="smazat rezervaci"
                            >
                              <i
                                aria-hidden="true"
                                class="fa-light fa-1x fa-times"
                              ></i>
                            </button>
                            <button
                              (click)="
                                                        isCreate = false;
                                                        isUpdate = true;
                                                        isCopy = false;
                                                        clickRow(event)
                                                    "
                              cssClass="e-btn e-warning e-outline btn-p-zero e-small m-auto"
                              ejs-button
                              style="
                                                                cursor: pointer;
                                                            "
                              title="upravit rezervaci"
                            >
                              <i
                                aria-hidden="true"
                                class="fa-light fa-1x fa-pencil"
                              ></i>
                            </button>
                          </div>
                          <div
                            class="description w-100 text-right"
                          >
                            <h6
                              class="mt-0 font-weight-bold"
                            >
                              <a
                                [routerLink]="[
                                                            '/events/detail/',
                                                            event.id
                                                        ]"
                              >
                                {{ event.subject }}
                              </a>
                            </h6>
                            <small
                              *ngIf="
                                                        event.users.length >
                                                            0 ||
                                                        event.departments
                                                            .length > 0
                                                    "
                              class="mb-0 ml-1"
                            >
                                                    <span
                                                      *ngFor="
                                                            let user of event.users
                                                        "
                                                    >
                                                        <a
                                                          class="mr-1"
                                                          routerLink="/users/detail/{{
                                                                user.id
                                                            }}"
                                                        >
                                                            <img
                                                              alt="profilový obrázek"
                                                              class="img-fluid rounded-circle avatar-xs mr-1"
                                                              src="assets/img{{
                                                                    user.personalphoto
                                                                }}"
                                                            />{{
                                                            user.firstname
                                                          }}
                                                          {{
                                                            user.secondname
                                                          }}
                                                        </a>
                                                    </span>
                              <span
                                *ngFor="
                                                            let department of event.departments
                                                        "
                              >
                                                                <i
                                                                  aria-hidden="true"
                                                                  class="fa-light fa-users mr-1"
                                                                ></i
                                                                >{{ department.name }}
                                                            </span>
                            </small>
                            <div
                              *ngIf="event.description"
                              class="mb-0 p-3"
                            >
                              <div
                                [innerHTML]="
                                                            event?.descriptionHTML
                                                        "
                                class="text-muted"
                              ></div>
                            </div>
                            <p
                              *ngIf="event.address"
                              class="mb-0"
                            >
                              <i
                                class="fa fa-lg fa-location-arrow"
                              ></i>
                              {{ event?.address }}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ng-container>
                </div>
              </ng-template>
            </e-accordionitem>
            <e-accordionitem
              *ngIf="!loadingEvents && ticketsDisplay.length > 0"
            >
              <ng-template #header>
                                <span>
                                    <i
                                      aria-hidden="true"
                                      class="fa-light fa-ticket-alt mr-1"
                                    ></i>
                                    Tickety
                                </span>
                <span
                  *ngIf="ticketsDisplay.length > 0"
                  class="e-badge e-badge-primary"
                >{{ ticketsDisplay.length }}</span
                >
              </ng-template>
              <ng-template #content>
                tetsx
                <ng-container
                  *ngFor="let ticket of ticketsDisplay"
                >
                  <div class="media mb-3">
                    <div
                      class="d-flex justify-content-between align-items-center"
                    >
                      <h5
                        *ngIf="
                                                today ===
                                                (ticket.created_date
                                                    | date: 'yyyy-MM-dd')
                                            "
                        class="h5-responsive font-weight-bold mr-3"
                      >
                        {{
                          ticket.created_date
                            | date: 'HH:mm'
                        }}<br />
                        <small
                          class="text-muted"
                        >začátek</small
                        >
                      </h5>
                      <div
                        *ngIf="
                                                        today ===
                                                            (ticket.deadline
                                                                | date
                                                                    : 'yyyy-MM-dd') &&
                                                        today ===
                                                            (ticket.start_deadline
                                                                | date
                                                                    : 'yyyy-MM-dd')
                                                    "
                        class="arrow"
                      >
                      </div>
                      <h5
                        *ngIf="
                                                today ===
                                                (ticket.deadline
                                                    | date: 'yyyy-MM-dd')
                                            "
                        class="h5-responsive font-weight-bold mr-3"
                      >
                        {{
                          ticket.deadline | date: 'HH:mm'
                        }}<br /><small
                        class="text-muted"
                      >konec</small
                      >
                      </h5>
                      <h5
                        *ngIf="
                                                today !==
                                                    (ticket.created_date
                                                        | date: 'yyyy-MM-dd') &&
                                                today !==
                                                    (ticket.deadline
                                                        | date: 'yyyy-MM-dd')
                                            "
                        class="h5-responsive font-weight-bold mr-3"
                      >
                        Celý den
                      </h5>
                    </div>
                    <div class="media-body mb-3">
                      <h6 class="mt-0 font-weight-bold">
                        <a
                          [routerLink]="[
                                                        '/tickets/detail/',
                                                        ticket.id
                                                    ]"
                        >
                          {{ ticket.subject }}
                        </a>
                      </h6>
                      <small class="text-muted mr-2">{{
                          ticket.category
                        }}</small>
                      <span
                        *ngIf="
                                                    ticket.status.name ===
                                                    'solved'
                                                "
                        class="e-badge e-badge-success"
                      >dokončeno</span
                      >
                      <span
                        *ngIf="
                                                    ticket.status.name ===
                                                    'in queue'
                                                "
                        class="e-badge e-badge-warning"
                      >ve frontě</span
                      >
                      <span
                        *ngIf="ticket.due === 'overdue'"
                        class="e-badge e-badge-danger"
                      >po termínu</span
                      >
                      <span
                        *ngIf="ticket.due === 'indue'"
                        class="e-badge e-badge-success"
                      >v termínu</span
                      >
                      <small class="mb-0 ml-1">
                                                <span
                                                  *ngFor="
                                                        let user of ticket.users
                                                    "
                                                >
                                                    <a
                                                      class="mr-1"
                                                      routerLink="/users/detail/{{
                                                            user.id
                                                        }}"
                                                    ><img
                                                      alt="profilový obrázek"
                                                      class="img-fluid rounded-circle avatar-xs mr-1"
                                                      src="assets/img{{
                                                                user.personalphoto
                                                            }}"
                                                    />{{ user.firstname }}
                                                      {{ user.secondname }}</a
                                                    >
                                                </span>
                        <span
                          *ngFor="
                                                        let department of ticket.departments
                                                    "
                        >
                                                    <i
                                                      aria-hidden="true"
                                                      class="fa-light fa-users mr-1"
                                                    ></i>
                          {{ department.name }}
                                                </span>
                      </small>
                      <hr class="hr-bold mb-0 my-2" />
                      <p class="mb-0 p-1 pt-3">
                        <small
                          [innerHTML]="
                                                        ticket.maintask
                                                            | truncateText: 300
                                                    "
                          class="text-muted"
                        ></small>
                      </p>
                    </div>
                  </div>
                </ng-container>
              </ng-template>
            </e-accordionitem>
            <e-accordionitem
              *ngIf="!loadingEvents && hotlinesDisplay.length > 0"
            >
              <ng-template #header>
                                <span>
                                    <i
                                      aria-hidden="true"
                                      class="fa-light fa-ballot-check mr-1"
                                    ></i>
                                    Hot-line
                                </span>
                <span
                  *ngIf="hotlinesDisplay.length > 0"
                  class="e-badge e-badge-primary"
                >{{ hotlinesDisplay.length }}</span
                >
              </ng-template>
              <ng-template #content>
                <ng-container
                  *ngFor="let hotline of hotlinesDisplay"
                >
                  <div class="media mb-3">
                    <div
                      class="d-flex justify-content-between align-items-center"
                    >
                      <h5
                        *ngIf="
                                                today ===
                                                (hotline.start_at
                                                    | date: 'yyyy-MM-dd')
                                            "
                        class="h5-responsive font-weight-bold mr-3"
                      >
                        {{
                          hotline.start_at | date: 'HH:mm'
                        }}<br /><small
                        class="text-muted"
                      >začátek</small
                      >
                      </h5>
                      <div
                        *ngIf="
                                                        today ===
                                                            (hotline.end_at
                                                                | date
                                                                    : 'yyyy-MM-dd') &&
                                                        today ===
                                                            (hotline.start_at
                                                                | date
                                                                    : 'yyyy-MM-dd')
                                                    "
                        class="arrow"
                      >
                      </div>
                      <h5
                        *ngIf="
                                                today ===
                                                (hotline.end_at
                                                    | date: 'yyyy-MM-dd')
                                            "
                        class="h5-responsive font-weight-bold mr-3"
                      >
                        {{
                          hotline.end_at | date: 'HH:mm'
                        }}<br /><small
                        class="text-muted"
                      >konec</small
                      >
                      </h5>
                      <h5
                        *ngIf="
                                                today !==
                                                    (hotline.start_at
                                                        | date: 'yyyy-MM-dd') &&
                                                today !==
                                                    (hotline.end_at
                                                        | date: 'yyyy-MM-dd')
                                            "
                        class="h5-responsive font-weight-bold mr-3"
                      >
                        Celý den
                      </h5>
                    </div>
                    <div class="media-body mb-3">
                      <h6 class="mt-0 font-weight-bold">
                        <a
                          routerLink="/hotline/detail/{{
                                                        hotline.id
                                                    }}"
                        >
                          <small class="ml-2">{{
                              hotline.hotliner.user
                                .fullname
                            }}</small>
                          -
                          <span
                            *ngIf="
                                                            hotline.type ===
                                                            'technician'
                                                        "
                            class="e-badge e-badge-success"
                          >služba</span
                          >
                          <span
                            *ngIf="
                                                            hotline.type !==
                                                            'technician'
                                                        "
                            class="e-badge e-badge-primary"
                          >příslužba</span
                          >
                        </a>
                      </h6>
                      <hr class="hr-bold mb-0 my-2" />
                      <p class="mb-0 p-1 pt-3">
                        <small
                          class="text-muted"
                        ></small>
                      </p>
                    </div>
                  </div>
                </ng-container>
              </ng-template>
            </e-accordionitem>
          </e-accordionitems>
        </ejs-accordion>
      </div>
      <div
        *ngIf="
                    !loadingEvents &&
                    eventsDisplay.length < 1 &&
                    ticketsDisplay.length < 1 &&
                    tasksDisplay.length < 1 &&
                    hotlinesDisplay.length < 1
                "
      >
        <p>Žádné výsledky hledání...</p>
      </div>
    </div>
  </div>
  <div class="col-12 col-md-6 col-lg-7">
    <h3 class="text-uppercase my-3">Rozvrh</h3>
    <h6
      *ngIf="
                eventsDisplay.length +
                    ticketsDisplay.length +
                    tasksDisplay.length +
                    hotlinesDisplay.length ===
                0
            "
      class="my-3"
    >
      Na dnes nemáte v rozvrhu žádné údálosti.
    </h6>
    <h6
      *ngIf="
                eventsDisplay.length +
                    ticketsDisplay.length +
                    tasksDisplay.length +
                    hotlinesDisplay.length >
                    0 &&
                eventsDisplay.length +
                    ticketsDisplay.length +
                    tasksDisplay.length +
                    hotlinesDisplay.length <
                    5
            "
      class="my-3"
    >
      Dnes toho v rozvrhu moc nemáte. Máte dnes v rozvrhu<b>
      {{
        eventsDisplay.length +
        ticketsDisplay.length +
        tasksDisplay.length +
        hotlinesDisplay.length
      }}
      údálosti </b
    >.
    </h6>
    <h6
      *ngIf="
                eventsDisplay.length +
                    ticketsDisplay.length +
                    tasksDisplay.length +
                    hotlinesDisplay.length >=
                    5 &&
                eventsDisplay.length +
                    ticketsDisplay.length +
                    tasksDisplay.length +
                    hotlinesDisplay.length <=
                    10
            "
      class="my-3"
    >
      It's going to be busy that today. Máte dnes v rozvrhu<b>
      {{
        eventsDisplay.length +
        ticketsDisplay.length +
        tasksDisplay.length +
        hotlinesDisplay.length
      }}
      událostí </b
    >.
    </h6>
    <h6
      *ngIf="
                eventsDisplay.length +
                    ticketsDisplay.length +
                    tasksDisplay.length +
                    hotlinesDisplay.length >
                10
            "
      class="my-3"
    >
      Vypadá to, že dnes budete velmi zaneprázdněn. Máte dnes v rozvrhu<b>
      {{
        eventsDisplay.length +
        ticketsDisplay.length +
        tasksDisplay.length +
        hotlinesDisplay.length
      }}
      událostí </b
    >.
    </h6>
    <div *ngIf="loadingCalendar" class="row">
      <app-loader></app-loader>
    </div>
    <full-calendar
      #sheludeCalendar
      *ngIf="!loadingCalendar"
      [deepChangeDetection]="true"
      [options]="calendarOptions"
    ></full-calendar>
  </div>
</div>
