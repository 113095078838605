import {
    AfterViewChecked,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnInit,
    ViewChild,
} from '@angular/core';
import {registerLocaleData} from '@angular/common';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthenticationService} from '@src/app/_services/authentication.service';
import {PermissionService} from '@src/app/_services/permission.service';
import {MessageService} from '@src/app/_services/message.service';
import {DataService} from '@src/app/_services/data.service';
import {Event} from '@src/app/_models/event/event';
import {Tickets} from '@src/app/_models/ticket/tickets';
import {Task} from '@src/app/_models/task/task';
import {FormBuilder} from '@angular/forms';
import {User} from '@src/app/_models/user/user';
import {Department} from '@src/app/_models/department/department';
import {FullCalendarComponent} from '@fullcalendar/angular';

import {Hotline} from '@src/app/_models/hotline/hotline';
import {RichTextEditorComponent} from '@syncfusion/ej2-angular-richtexteditor';
import {ButtonPropsModel, DialogComponent} from '@syncfusion/ej2-angular-popups';
import {MultiSelectComponent} from '@syncfusion/ej2-angular-dropdowns';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';

import csLocale from '@fullcalendar/core/locales/cs';
import moment from 'moment-business-days';
import localeCs from '@angular/common/locales/cs';
import {Vacation} from '@src/app/_models/vacation/vacation';
import {Holiday} from '@src/app/_models/holiday/holiday';
import {CalendarOptions, EventInput} from '@fullcalendar/core';
import {EventsService} from '@src/app/features/events/events.service';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import timeGrid from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import googleCalendarPlugin from '@fullcalendar/google-calendar';

moment.updateLocale('cs', {workingWeekdays: [1, 2, 3, 4, 5]});
registerLocaleData(localeCs);

export interface VacationEvents {
    vacationData: Vacation;
    title: string;
    start: string;
    end: string;
    backgroundColor: string;
    borderColor: string;
    textColor: string;
    allDay: boolean;
    editable: boolean;
    startEditable: boolean;
    durationEditable: boolean;
    timeFormat: string;
}

export interface TicketEvents {
    ticketData: Tickets;
    title: string;
    start: string;
    end: string | null;
    backgroundColor: string;
    borderColor: string;
    textColor: string;
    allDay: boolean;
    editable: boolean;
    startEditable: boolean;
    durationEditable: boolean;
    timeFormat: string;
}

export interface TaskEvents {
    taskData: Task;
    title: string;
    start: string;
    end: string;
    backgroundColor: string;
    borderColor: string;
    textColor: string;
    allDay: boolean;
    editable: boolean;
    startEditable: boolean;
    durationEditable: boolean;
    timeFormat: string;
}

export interface HotlineEvents {
    hotlineData: Hotline;
    title: string;
    type: string;
    start: string;
    end: string;
    backgroundColor: string;
    borderColor: string;
    textColor: string;
    allDay: boolean;
    timeFormat: string;
}

export interface HotlidayEvents {
    hotlidayData: Holiday;
    title: string;
    start: string;
    end: string;
    backgroundColor: string;
    borderColor: string;
    textColor: string;
    allDay: boolean;
    editable: boolean;
    startEditable: boolean;
    durationEditable: boolean;
    timeFormat: string;
}

interface EventEvents {
    eventData: Event;
    title: string;
    type: string;
    start: string;
    end: string;
    backgroundColor: string;
    borderColor: string;
    textColor: string;
    allDay: boolean;
    timeFormat: string;
}

@UntilDestroy()
@Component({
    selector: 'app-events',
    templateUrl: './events.component.html',
    styleUrls: ['./events.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EventsComponent implements OnInit, AfterViewChecked {
    // Dialogs
    renderEventFormDialog = false;

    buttonsDeleteEventDialog: ButtonPropsModel[] = [
        {
            click: (): void => {
                this.deleteEvent();
            },
            isFlat: false,
            buttonModel: {
                content: 'OK',
                cssClass: 'e-success e-outline',
            },
        },
        {
            click: (): void => {
                this.deleteEventDialog.hide();
            },
            buttonModel: {content: 'ZRUŠIT', cssClass: 'e-danger e-outline'},
        },
    ];

    // Dropdowns
    fields: object = {text: 'label', value: 'value'};

    height = '240px';

    usersSelect: Array<{[key: string]: boolean | number | string}> = [];

    departmentSelect: Array<{[key: string]: boolean | number | string}> = [];

    // Forms
    isDirty = false;

    isCreate = false;

    isUpdate = false;

    isCopy = false;

    isChecked = false;

    submited = false;

    // Variables
    today = moment()
        .format('YYYY-MM-DD');

    currentUser: User | null;

    users: User[];

    departments: Department[];

    event: Event;

    events: unknown[] = [];

    eventsDisplay: Event[] = [];

    tickets: unknown[] = [];

    ticketsDisplay: Tickets[] = [];

    tasks: unknown[] = [];

    tasksDisplay: Task[] = [];

    hotlines: unknown[] = [];

    hotlinesDisplay: Hotline[] = [];

    // FullCalendar
    calendarOptions: CalendarOptions = {
        googleCalendarApiKey: 'AIzaSyCCLvaMHyCqnbJC9DsQTvXGQ8lgS4D_hzo',
        initialView: 'dayGridMonth',
        firstDay: 1,
        locale: csLocale,
        selectable: true,
        headerToolbar: {
            start: 'title',
            center: 'dayGridMonth,timeGridWeek,listWeek',
            end: 'today prev,next',
        },
        buttonText: {
            today: 'dnes',
            month: 'měsíc',
            week: 'týden',
            day: 'den',
            list: 'seznam',
        },
        allDayText: 'celý den',
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        dateClick: this.clickedEvent.bind(this),
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        eventClick: this.navigateToEvent.bind(this),
        initialEvents: [],
        eventSources: [],
        eventTimeFormat: {
            hour: '2-digit',
            minute: '2-digit',
            meridiem: false,
        },
        timeZone: 'local',
        weekends: true,
        plugins: [dayGridPlugin, interactionPlugin, timeGrid, listPlugin, googleCalendarPlugin],
    };

    calendarEvents: Array<unknown> = [];

    ticketEvents: Array<TicketEvents> = [];

    vacationEvents: Array<VacationEvents> = [];

    taskEvents: Array<TaskEvents> = [];

    holidayEvents: Array<HotlidayEvents> = [];

    eventEvents: Array<EventEvents> = [];

    hotlineEvents: Array<HotlineEvents> = [];

    holidays: Array<Holiday> = [];

    // Loaders
    loadingCalendar = true;

    loadingEvents = true;

    clickedRow: Event;

    // FullCalendar
    @ViewChild('sheludeCalendar') calendarComponent: FullCalendarComponent;

    // Dropdowns
    @ViewChild('users') usersObj: MultiSelectComponent;

    @ViewChild('departments') departmentsObj: MultiSelectComponent;

    // Dialogs
    @ViewChild('deleteEventDialog') deleteEventDialog: DialogComponent;

    @ViewChild('formDialog') formDialogObj: DialogComponent;

    // RTE
    @ViewChild('eventDescription') eventDescription?: RichTextEditorComponent;

    constructor(
        private readonly route: ActivatedRoute,
        private readonly router: Router,
        private readonly ref: ChangeDetectorRef,
        private readonly formBuilder: FormBuilder,
        private readonly eventService: EventsService,
        private readonly authenticationService: AuthenticationService,
        private readonly permissionService: PermissionService,
        private readonly messages: MessageService,
        private readonly dataService: DataService,
    ) {
        this.currentUser = this.authenticationService.currentUserValue;
    }

    private loadCalendar(): void {
        this.ticketEvents = [];
        this.taskEvents = [];
        this.hotlineEvents = [];
        this.eventEvents = [];

        if (this.events.length > 0) {
            this.events.map((event: Event) => {
                this.eventEvents.push({
                    eventData: event,
                    title: event.subject,
                    type: 'event',
                    start: moment(event.start_at)
                        .format('YYYY-MM-DD HH:mm:ss'),
                    end: moment(event.end_at)
                        .format('YYYY-MM-DD HH:mm:ss'),
                    backgroundColor: '#33af2a',
                    borderColor: '#33af2a',
                    textColor: '#ffffff',
                    allDay: false,
                    timeFormat: 'H:mm',
                });
            });
        }

        if (this.hotlines.length > 0) {
            this.hotlines.map((hotline: Hotline) => {
                if (!hotline.deleted_date) {
                    this.hotlineEvents.push({
                        hotlineData: hotline,
                        title:
                            'Hot-line: ' +
                            moment(hotline.start_at)
                                .format('YYYY-MM-DD HH:mm') +
                            ' - ' +
                            moment(hotline.end_at)
                                .format('YYYY-MM-DD HH:mm'),
                        type: 'hotline',
                        start: moment(hotline.start_at)
                            .format('YYYY-MM-DD HH:mm:ss'),
                        end: moment(hotline.end_at)
                            .format('YYYY-MM-DD HH:mm:ss'),
                        backgroundColor: '#4b0b80',
                        borderColor: '#af2a35',
                        textColor: '#ffffff',
                        allDay: false,
                        timeFormat: 'H:mm',
                    });
                }
            });
        }

        if (this.tickets.length > 0) {
            this.tickets.map((ticket: Tickets) => {
                if (
                    !ticket.deleted_date &&
                    !ticket.finished_at &&
                    this.permissionService.assignationTicket(ticket)
                ) {
                    this.ticketEvents.push({
                        ticketData: ticket,
                        title: 'Ticket: ' + ticket.subject,
                        start: moment(ticket.created_date)
                            .format('YYYY-MM-DD HH:mm:ss'),
                        end: ticket.deadline
                            ? moment(ticket.deadline)
                                .format('YYYY-MM-DD HH:mm:ss')
                            : null,
                        backgroundColor: '#36b7db',
                        borderColor: '#36b7db',
                        textColor: '#ffffff',
                        allDay: false,
                        editable: true,
                        startEditable: true,
                        durationEditable: true,
                        timeFormat: 'H:mm',
                    });
                }
            });
        }

        if (this.tasks.length > 0) {
            this.tasks.map((task: Task) => {
                if (
                    !task.deleted_date &&
                    !task.finished_at &&
                    this.permissionService.assignationTask(task)
                ) {
                    this.taskEvents.push({
                        taskData: task,
                        title: 'Úkol: ' + task.subject,
                        start: moment(task.created_date)
                            .format('YYYY-MM-DD HH:mm:ss'),
                        end: moment(task.deadline)
                            .format('YYYY-MM-DD HH:mm:ss'),
                        backgroundColor: '#ffbb32',
                        borderColor: '#ffbb32',
                        textColor: '#ffffff',
                        allDay: false,
                        editable: true,
                        startEditable: true,
                        durationEditable: true,
                        timeFormat: 'H:mm',
                    });
                }
            });
        }

        this.calendarOptions.eventSources = [
            {
                googleCalendarId: 'cs.czech.official#holiday@group.v.calendar.google.com',
                color: 'yellow',
                backgroundColor: '#ff8c00',
                borderColor: '#ff8c00',
                textColor: '#ffffff',
                editable: false,
                startEditable: false,
                durationEditable: false,
            },
            this.calendarEvents
                .concat(this.ticketEvents)
                .concat(this.taskEvents)
                .concat(this.eventEvents)
                .concat(this.hotlineEvents) as EventInput[],
        ];
        this.loadingCalendar = false;
        this.ref.markForCheck();
    }

    ngOnInit(): void {
        this.dataService.eventSource.pipe(untilDestroyed(this))
            .subscribe(
                (events: Event[]) => {
                    this.events = [];
                    events.map((event: Event) => {
                        if (!event.deleted_date) {
                            this.events.push(event);
                        }
                    });

                    this.events = this.events.sort((a: Event, b: Event) => {
                        const dateA = moment(a.start_at)
                            .valueOf();
                        const dateB = moment(b.start_at)
                            .valueOf();

                        return dateA > dateB ? -1 : 1;
                    });
                    this.loadingEvents = false;
                    this.loadEvents();
                    this.loadCalendar();
                    this.ref.markForCheck();
                },
                error => {
                    console.error(error);
                    this.loadingEvents = false;
                    this.ref.markForCheck();
                },
            );

        this.dataService.hotlineSource.pipe(untilDestroyed(this))
            .subscribe(
                (hotlines: Hotline[]) => {
                    this.hotlines = [];
                    hotlines.map((hotline: Hotline) => {
                        if (!hotline.deleted_date) {
                            this.hotlines.push({
                                id: hotline.id,
                                // eslint-disable-next-line max-len
                                hotliner: hotline.hotliner,
                                start_at: hotline.start_at,
                                end_at: hotline.end_at,
                                created_date: moment(hotline.created_date)
                                    .format(
                                        'YYYY-MM-DD HH:mm:ss',
                                    ),
                            });

                            this.hotlineEvents.push({
                                hotlineData: hotline,
                                title:
                                    'Hot-line: ' +
                                    moment(hotline.start_at)
                                        .format('YYYY-MM-DD HH:mm') +
                                    ' - ' +
                                    moment(hotline.end_at)
                                        .format('YYYY-MM-DD HH:mm'),
                                type: 'hotline',
                                start: moment(hotline.start_at)
                                    .format('YYYY-MM-DD HH:mm:ss'),
                                end: moment(hotline.end_at)
                                    .format('YYYY-MM-DD HH:mm:ss'),
                                backgroundColor: '#af2a35',
                                borderColor: '#af2a35',
                                textColor: '#ffffff',
                                allDay: false,
                                timeFormat: 'H:mm',
                            });
                        }
                    });

                    this.hotlines = this.hotlines.sort((a: Hotline, b: Hotline) => {
                        const dateA = moment(a.start_at)
                            .valueOf();
                        const dateB = moment(b.start_at)
                            .valueOf();

                        return dateA > dateB ? -1 : 1;
                    });
                    this.loadingEvents = false;
                    this.loadHotline();
                    this.loadCalendar();
                    this.ref.markForCheck();
                },
                error => {
                    console.error(error);
                    this.loadingEvents = false;
                    this.ref.markForCheck();
                },
            );

        this.dataService.ticketSource.pipe(untilDestroyed(this))
            .subscribe({
                next: (tickets: Tickets[]) => {
                    this.tickets = [];
                    tickets.map((ticket: Tickets) => {
                        if (
                            !ticket.deleted_date &&
                            !ticket.finished_at &&
                            this.permissionService.assignationTicket(ticket)
                        ) {
                            this.tickets.push({
                                id: ticket.id,
                                // eslint-disable-next-line max-len
                                creator: `<a href="users/detail/${ticket.creator.id}"><img src="assets/img${ticket.creator.personalphoto}" class="img-fluid rounded-circle avatar-xs mr-1" alt="profilový obrázek">${ticket.creator.secondname} ${ticket.creator.firstname}</a>`,
                                subject: ticket.subject,
                                maintask: ticket.maintask,
                                created_date: ticket.created_date,
                                users: ticket.users,
                                departments: ticket.departments,
                                status: ticket.status.name,
                                due: ticket.due,
                                category: ticket.category.name,
                                deadline: ticket.deadline ? ticket.deadline : null,
                            });
                        }
                    });

                    this.tickets = this.tickets.sort((a: Tickets, b: Tickets) => {
                        const dateA = a.start_deadline
                            ? moment(a.start_deadline)
                                .valueOf()
                            : moment(a.created_date)
                                .valueOf();
                        const dateB = b.start_deadline
                            ? moment(b.start_deadline)
                                .valueOf()
                            : moment(b.created_date)
                                .valueOf();

                        return dateA > dateB ? -1 : 1;
                    });
                    this.loadingEvents = false;
                    this.loadTicket();
                    this.loadCalendar();
                    this.ref.markForCheck();
                },
                error: error => {
                    console.error(error);
                    this.loadingEvents = false;
                    this.ref.markForCheck();
                },
            });

        this.dataService.taskSource.pipe(untilDestroyed(this))
            .subscribe({
                next: (tasks: Task[]) => {
                    this.tasks = [];
                    // eslint-disable-next-line complexity
                    tasks.map((task: Task) => {
                        if (
                            !task.deleted_date &&
                            !task.finished_at &&
                            this.permissionService.assignationTask(task)
                        ) {
                            this.tasks.push({
                                id: task.id,
                                // eslint-disable-next-line max-len
                                user: `<a href="users/detail/${task.solver.id}"><img src="assets/img${task.solver.personalphoto}" class="img-fluid rounded-circle avatar-xs mr-1" alt="profilový obrázek">${task.solver.secondname} ${task.solver.firstname}</a>`,
                                subject: task.subject,
                                maintask: task.maintask,
                                category: task.category,
                                status:
                                    task.finished_at && task.taskTime.overtime && !task.deleted_date
                                        ? 'solved-overdue'
                                        : task.finished_at &&
                                        !task.taskTime.overtime &&
                                        !task.deleted_date
                                            ? 'solved-indue'
                                            : !task.finished_at &&
                                            task.taskTime.overtime &&
                                            !task.deleted_date
                                                ? 'queue-overdue'
                                                : !task.finished_at &&
                                                !task.taskTime.overtime &&
                                                !task.deleted_date
                                                    ? 'queue-indue'
                                                    : 'deleted',
                                ticket_id: task.ticket_id,
                                deadline: task.deadline,
                                created_date: task.created_date,
                                // eslint-disable-next-line max-len
                                creator: `<a href="users/detail/${task.creator.id}"><img src="assets/img${task.creator.personalphoto}" class="img-fluid rounded-circle avatar-xs mr-1" alt="profilový obrázek">${task.creator.secondname} ${task.creator.firstname}</a>`,
                            });
                        }
                    });

                    this.tasks = this.tasks.sort((a: Task, b: Task) => {
                        const dateA = a.start_deadline
                            ? moment(a.start_deadline)
                                .valueOf()
                            : moment(a.created_date)
                                .valueOf();
                        const dateB = b.start_deadline
                            ? moment(b.start_deadline)
                                .valueOf()
                            : moment(b.created_date)
                                .valueOf();

                        return dateA > dateB ? -1 : 1;
                    });
                    this.loadingEvents = false;
                    this.loadTask();
                    this.loadCalendar();
                    this.ref.markForCheck();
                },
                error: error => {
                    console.error(error);
                    this.loadingEvents = false;
                    this.ref.markForCheck();
                },
            });

        this.dataService.setDepartmentDataSource();
        this.dataService.setEventsDataSource();
        this.dataService.setTicketsDataSource();
        this.dataService.setTasksDataSource();
        this.dataService.setHotlinesDataSource();
    }

    ngAfterViewChecked(): void {
        this.route.queryParamMap.pipe(untilDestroyed(this))
            .subscribe(params => {
                if (params.get('form') === 'addEvent') {
                    console.info('get event form');
                    this.isCreate = true;
                    this.isUpdate = false;
                    this.isCopy = false;
                    this.renderEventFormDialog = true;
                    this.ref.markForCheck();
                }
            });
    }

    loadEvents(): void {
        this.eventsDisplay = [];
        this.eventsDisplay = this.events.filter((event: Event) => {
            const dateStart = moment(event.start_at)
                .format('YYYY-MM-DD');
            const dateEnd = moment(event.end_at)
                .format('YYYY-MM-DD');

            return this.today >= dateStart && this.today <= dateEnd;
        }) as Event[];
        this.ref.markForCheck();
    }

    loadTicket(): void {
        this.ticketsDisplay = [];
        this.ticketsDisplay = this.tickets.filter((ticket: Tickets) => {
            const dateStart = ticket.start_deadline
                ? moment(ticket.start_deadline)
                    .format('YYYY-MM-DD')
                : moment(ticket.created_date)
                    .format('YYYY-MM-DD');
            const dateEnd = ticket.deadline ? moment(ticket.deadline)
                .format('YYYY-MM-DD') : null;

            return dateEnd
                ? this.today >= dateStart && this.today <= dateEnd
                : this.today >= dateStart;
        }) as Tickets[];
        this.ref.markForCheck();
    }

    loadTask(): void {
        this.tasksDisplay = [];
        this.tasksDisplay = this.tasks.filter((task: Task) => {
            const dateStart = task.start_deadline
                ? moment(task.start_deadline)
                    .format('YYYY-MM-DD')
                : moment(task.created_date)
                    .format('YYYY-MM-DD');
            const dateEnd = moment(task.deadline)
                .format('YYYY-MM-DD');

            return this.today >= dateStart && this.today <= dateEnd;
        }) as Task[];
        this.ref.markForCheck();
    }

    loadHotline(): void {
        this.hotlinesDisplay = [];
        this.hotlinesDisplay = this.hotlines.filter((hotline: Hotline) => {
            const dateStart = moment(hotline.start_at)
                .format('YYYY-MM-DD');
            const dateEnd = moment(hotline.end_at)
                .format('YYYY-MM-DD');

            return this.today >= dateStart && this.today <= dateEnd;
        }) as Hotline[];
        this.ref.markForCheck();
    }

    navigateToEvent(arg): void {
        if (arg) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            if (arg.event.extendedProps.ticket_data) {
                void this.router.navigate([
                    '/tickets/detail',
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                    arg.event.extendedProps.ticket_data.id,
                ]);
            }

            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            if (arg.event.extendedProps.task_data) {
                void this.router.navigate(['/tasks/detail', arg.event.extendedProps.task_data.id]);
            }

            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            if (arg.event.extendedProps.vacation_data) {
                void this.router.navigate([
                    '/vacation/detail',
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                    arg.event.extendedProps.vacation_data.id,
                ]);
            }

            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            if (arg.event.extendedProps.event_data) {
                void this.router.navigate([
                    '/events/detail',
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                    arg.event.extendedProps.event_data.id,
                ]);
            }

            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            if (arg.event.extendedProps.hotlineData) {
                void this.router.navigate([
                    '/hotline/detail',
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                    arg.event.extendedProps.hotlineData.id,
                ]);
            }
        } else {
            const body = 'Data odkazu nejsou k dispozici...';
            const options = {progressBar: true, timeOut: 5000, toastClass: 'red'};

            this.messages.showError('Odkaz nelze otevřít', body, options);
        }
    }

    clickedEvent(event: any): void {
        this.today = moment(event.dateStr)
            .format('YYYY-MM-DD');
        this.loadTask();
        this.loadTicket();
        this.loadEvents();
        this.loadHotline();
    }

    clickRow(event: Event): void {
        this.clickedRow = event;
        this.renderEventFormDialog = true;
        this.ref.markForCheck();
    }

    deleteEvent(): void {
        this.loadingEvents = true;
        this.clickedRow.deleted_by = this.currentUser?.id;
        this.eventService
            .deleteEvent(this.clickedRow)
            .pipe(untilDestroyed(this))
            .subscribe({
                next: (data: Event) => {
                    const body = `Událost #${data.id}`;
                    const options = {progressBar: true, timeOut: 5000};

                    this.messages.showSuccess('Událost úspěšně smazána', body, options);
                    this.loadingEvents = false;
                    this.dataService.setEventsDataSource();
                    this.deleteEventDialog.hide();
                    this.ref.markForCheck();
                },
                error: error => {
                    console.error(error);

                    const body = 'Zkuste to znovu později ...';
                    const options = {progressBar: true, timeOut: 5000};

                    this.messages.showError('Chyba při mazání události', body, options);
                    this.loadingEvents = false;
                    this.ref.markForCheck();
                },
            });
    }
}
