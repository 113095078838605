import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    SimpleChanges,
} from '@angular/core';
import {AuthenticationService} from '@src/app/_services/authentication.service';
import {User} from '@src/app/_models/user/user';
import {PermissionService} from '@src/app/_services/permission.service';
import {Department} from '@src/app/_models/department/department';
import {Knowledge} from '@src/app/_models/knowledge/knowledge';

@Component({
    selector: 'app-knowledge-sidebar',
    templateUrl: './knowledge-sidebar.component.html',
    styleUrls: ['./knowledge-sidebar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KnowledgetSidebarComponent implements OnChanges {
    @Input() knowledge?: Knowledge | null;

    @Output() readonly addUserEvent = new EventEmitter<boolean>();

    @Output() readonly addDepartmentsEvent = new EventEmitter<boolean>();

    @Output() readonly deleteUserEvent = new EventEmitter<User>();

    @Output() readonly deleteDepartmentEvent = new EventEmitter<Department>();

    live: true;

    currentUser: User | null;

    constructor(
        private readonly authenticationService: AuthenticationService,
        public permissionService: PermissionService,
    ) {
        this.currentUser = this.authenticationService.currentUserValue;
    }

    ngOnChanges(): void {
        console.log('change');
    }

    addUser(): void {
        this.addUserEvent.emit(true);
    }

    addDepartments(): void {
        this.addDepartmentsEvent.emit(true);
    }

    deleteUser(user: User): void {
        this.deleteUserEvent.emit(user);
    }

    deleteDepartment(department: Department): void {
        this.deleteDepartmentEvent.emit(department);
    }
}
