import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    HostListener,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import {Company} from '@src/app/_models/company/company';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Hotliner} from '@src/app/_models/hotline/hotliner';
import {User} from '@src/app/_models/user/user';
import {ActivatedRoute, Router} from '@angular/router';
import {MessageService} from '@src/app/_services/message.service';
import {AuthenticationService} from '@src/app/_services/authentication.service';
import * as XLSX from 'xlsx';
import {WorkSheet} from 'xlsx';
import {saveAs} from 'file-saver';
import {DataService} from '@src/app/_services/data.service';
import {Observable} from 'rxjs';
import {Holiday} from '@src/app/_models/holiday/holiday';
import {ComponentCanDeactivate} from '@src/app/_guards/changes.guard';

import {registerLocaleData} from '@angular/common';
import moment, {MomentInput} from 'moment-business-days';
import localeCs from '@angular/common/locales/cs';
import {
    BeforeUploadEventArgs,
    FileInfo,
    RemovingEventArgs,
    SelectedEventArgs,
    SuccessEventArgs,
    UploaderComponent,
    UploadingEventArgs,
} from '@syncfusion/ej2-angular-inputs';
import {EmitType, isNullOrUndefined} from '@syncfusion/ej2-base';
import {DialogComponent} from '@syncfusion/ej2-angular-popups';
import {Hotline} from '@src/app/_models/hotline/hotline';
import {PermissionService} from '@src/app/_services/permission.service';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {EnvironmentService} from '@src/app/_services/environment.service';
import {
    FileImportHotline,
    FilePath,
    HotlineInput,
    HotlineService,
} from '@src/app/features/hotline/hotline.service';
import {SettingsService} from '@src/app/features/settings/settings.service';
import {UsersService} from '@src/app/features/users/users.service';
import {VacationService} from '@src/app/features/vacation/vacation.service';

moment.updateLocale('cs', {workingWeekdays: [1, 2, 3, 4, 5]});
registerLocaleData(localeCs);

@UntilDestroy()
@Component({
    selector: 'app-hotline-import',
    templateUrl: './hotline-import.component.html',
    styleUrls: ['./hotline-import.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HotlineImportComponent implements OnInit, ComponentCanDeactivate {
    // Upload
    path: FilePath;

    dropElement: HTMLElement = document.getElementsByClassName('control-fluid')[0] as HTMLElement;

    humanizeBytes: (bytes: number) => void;

    // Dialogs
    buttonsDeleteFileDialog = [
        {
            click: this.removeHotlineFile.bind(this),
            buttonModel: {
                content: 'OK',
                cssClass: 'e-success e-outline',
                isFlat: false,
            },
        },
        {
            click: (): void => {
                this.deleteFileDialog.hide();
            },
            buttonModel: {content: 'ZRUŠIT', cssClass: 'e-danger e-outline'},
        },
    ];

    // Variables
    currentUser?: User | null;

    removeFile: Array<FileInfo[]> = [];

    isDirty = false;

    companies: Company[];

    users: User[] = [];

    holidays: Array<string> = [];

    hotlinersAll?: Hotliner[];

    hotlines?: Hotline[];

    hotlinesData: HotlineInput[] = [];

    createFileForm: FormGroup;

    loadingHotline = false;

    submited = false;

    // Upload
    @ViewChild('preloadupload') uploadObj: UploaderComponent;

    // Dialogs
    @ViewChild('deleteFileDialog') deleteFileDialog: DialogComponent;

    @Output() readonly data = new EventEmitter();

    constructor(
        private readonly formBuilder: FormBuilder,
        private readonly route: ActivatedRoute,
        private readonly ref: ChangeDetectorRef,
        private readonly router: Router,
        private readonly hotlineService: HotlineService,
        private readonly settingsService: SettingsService,
        private readonly userService: UsersService,
        private readonly messages: MessageService,
        private readonly vacationService: VacationService,
        private readonly permissionService: PermissionService,
        private readonly authenticationService: AuthenticationService,
        private readonly dataService: DataService,
        private readonly environmentService: EnvironmentService,
    ) {
        this.currentUser = this.authenticationService.currentUserValue;
        this.path = {
            saveUrl:
                this.environmentService.backendURL +
                '/api/upload/sheet/hotline?type=hotline&subfolder=import',
            removeUrl: '',
        };
    }

    get filesControl(): {[key: string]: AbstractControl} {
        return this.createFileForm.controls;
    }

    private getXLSXData(file: Blob, callback: (dataRow: FileImportHotline[]) => void): void {
        let data: string[] | string;
        let date: string;
        const row: FileImportHotline[] = [];
        const reader = new FileReader();

        reader.onload = (e): void => {
            const bstr = e.target?.result;
            const workbook = XLSX.read(bstr, {type: 'binary'});
            const sheetNameList = workbook.SheetNames;

            sheetNameList.forEach(y => {
                /* iterate through sheets */
                const worksheet: WorkSheet = workbook.Sheets[y];
                const company = this.companies.find(x => x.name === y.toString());

                for (data in worksheet) {
                    if (
                        // eslint-disable-next-line @typescript-eslint/prefer-string-starts-ends-with
                        data[0] === '!' ||
                        // eslint-disable-next-line @typescript-eslint/prefer-string-starts-ends-with
                        data[0] === 'A1' ||
                        // eslint-disable-next-line @typescript-eslint/prefer-string-starts-ends-with
                        data[0] === 'B1' ||
                        // eslint-disable-next-line @typescript-eslint/prefer-string-starts-ends-with
                        data[0] === 'C1'
                    ) {
                        continue;
                    }

                    if (data !== 'A1' && data !== 'B1' && data !== 'C1') {
                        if (data.includes('A')) {
                            date = moment(worksheet[data].w.toString() as MomentInput)
                                .format(
                                    'YYYY-MM-DD',
                                );
                        }

                        if (data.includes('B')) {
                            const emailCell: string = worksheet[data].w.toString();
                            let userArray: string[] = [];
                            const checkInclude = emailCell.includes(',');

                            if (checkInclude) {
                                userArray = emailCell.split(',');
                            } else {
                                userArray.push(emailCell);
                            }

                            // eslint-disable-next-line @typescript-eslint/no-loop-func
                            userArray.forEach((email: string) => {
                                const user = this.users.find(x => x.workemail === email.trim());

                                if (user) {
                                    const hotliner = this.hotlinersAll?.find(
                                        x => x.user_id === user.id,
                                    );

                                    if (hotliner?.id && company) {
                                        row.push({
                                            hotliner_id: hotliner.id,
                                            type: 'technician',
                                            company_id: company.id,
                                            date,
                                        });
                                    } else {
                                        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                                        const body = `Uživatel nedohledán v hotlinerech: ${worksheet[
                                            data as string
                                            ].w.toString()}`;
                                        const options = {
                                            progressBar: true,
                                            timeOut: 10000,
                                            toastClass: 'error',
                                        };

                                        this.messages.showError(
                                            'Hotliner nedohledán',
                                            body,
                                            options,
                                        );
                                    }
                                } else {
                                    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                                    const body = `Email neexistuje: ${worksheet[
                                        data as string
                                        ].w.toString()}`;
                                    const options = {
                                        progressBar: true,
                                        timeOut: 10000,
                                        toastClass: 'error',
                                    };

                                    this.messages.showError('Uživatel nedohledán', body, options);
                                }
                            });
                        }

                        if (data.includes('C')) {
                            const emailCell: string = worksheet[data].w.toString();
                            let userArray: string[] = [];
                            const checkInclude = emailCell.includes(',');

                            if (checkInclude) {
                                userArray = emailCell.split(',');
                            } else {
                                userArray.push(emailCell);
                            }

                            // eslint-disable-next-line @typescript-eslint/no-loop-func
                            userArray.forEach((email: string) => {
                                const user = this.users.find(x => x.workemail === email.trim());

                                if (user) {
                                    const hotliner = this.hotlinersAll?.find(
                                        x => x.user_id === user.id,
                                    );

                                    if (hotliner?.id && company) {
                                        row.push({
                                            hotliner_id: hotliner.id,
                                            type: 'support',
                                            company_id: company.id,
                                            date,
                                        });
                                    } else {
                                        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                                        const body = `Uživatel nedohledán v hotlinerech: ${worksheet[
                                            data as string
                                            ].w.toString()}`;
                                        const options = {
                                            progressBar: true,
                                            timeOut: 10000,
                                            toastClass: 'error',
                                        };

                                        this.messages.showError(
                                            'Hotliner nedohledán',
                                            body,
                                            options,
                                        );
                                    }
                                } else {
                                    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                                    const body = `Email neexistuje: ${worksheet[
                                        data as string
                                        ].w.toString()}`;
                                    const options = {
                                        progressBar: true,
                                        timeOut: 10000,
                                        toastClass: 'error',
                                    };

                                    this.messages.showError('Uživatel nedohledán', body, options);
                                }
                            });
                        }
                    }
                }
            });

            callback(row);
        };

        reader.readAsBinaryString(file);
    }

    @HostListener('window:beforeunload')
    canDeactivate(): Observable<boolean> | boolean {
        return !this.isDirty;
    }

    ngOnInit(): void {
        this.hotlineService.currentDataResult.pipe(untilDestroyed(this))
            .subscribe(
                (results: Hotline[]) => {
                    this.hotlinesData = results;
                    this.ref.markForCheck();
                },
                error => {
                    console.error(error);
                },
            );

        this.dataService.hotlinersSource.pipe(untilDestroyed(this))
            .subscribe(
                (data: Hotliner[] | undefined) => {
                    this.hotlinersAll = data;
                    this.ref.markForCheck();
                },
                error => {
                    console.error(error);
                },
            );

        this.vacationService
            .getCurrCZEHolidays()
            .pipe(untilDestroyed(this))
            .subscribe(
                (holidays: Holiday[]) => {
                    this.holidays = [];
                    holidays.map(holiday => {
                        if (holiday.isPublicHoliday) {
                            this.holidays.push(moment(holiday.date)
                                .format('YYYY-MM-DD'));
                            moment.updateLocale('cs', {
                                holidays: this.holidays,
                                holidayFormat: 'YYYY-MM-DD',
                                workingWeekdays: [1, 2, 3, 4, 5],
                            });
                        }
                    });
                    this.ref.markForCheck();
                },
                error => {
                    console.error(error);
                },
            );

        this.dataService.userSource.pipe(untilDestroyed(this))
            .subscribe(
                (data: User[]) => {
                    this.users = data;
                    data.map((user: User) => {
                        if (user.authorized && !user.deleted_date) {
                            this.users.push(user);
                            this.ref.markForCheck();
                        }
                    });
                },
                error => {
                    console.error(error);
                },
            );

        this.dataService.companySource.pipe(untilDestroyed(this))
            .subscribe(
                (companies: Company[]) => {
                    this.companies = companies;
                    this.ref.markForCheck();
                },
                error => {
                    console.error(error);
                },
            );

        this.dataService.hotlineSource.pipe(untilDestroyed(this))
            .subscribe(
                (hotlines: Hotline[]) => {
                    this.hotlines = hotlines;
                },
                error => {
                    console.error(error);
                    this.loadingHotline = false;
                    this.ref.markForCheck();
                },
            );

        this.createFileForm = this.formBuilder.group({
            files: ['', Validators.required],
        });

        this.createFileForm.valueChanges.pipe(untilDestroyed(this))
            .subscribe(() => {
                this.isDirty = this.createFileForm.dirty;
                this.ref.markForCheck();
            });

        this.dataService.setHotlinersDataSource();
        this.dataService.setHotlinesDataSource();
        this.dataService.setCompanyDataSource();
    }

    downloadSheet(name = 'hotline_sheet.xlsx'): void {
        this.hotlineService
            .downloadFile(name)
            .pipe(untilDestroyed(this))
            .subscribe(
                data => {
                    saveAs(data, name);
                },
                err => {
                    alert('Chyba během stahování souboru.');
                    console.error(err);
                },
            );
    }

    onFileSelected(args: SelectedEventArgs): void {
        // Filter the 5 files only to showcase
        args.filesData.splice(5);

        const filesData: FileInfo[] = this.uploadObj.getFilesData();
        const allFiles: FileInfo[] = filesData.concat(args.filesData);

        if (allFiles.length > 5) {
            // eslint-disable-next-line @typescript-eslint/prefer-for-of
            for (let i = 0; i < allFiles.length; i++) {
                if (allFiles.length > 5) {
                    allFiles.shift();
                }
            }

            args.filesData = allFiles;
            // set the modified custom data
            args.modifiedFilesData = args.filesData;
        }

        let existingFiles: FileInfo[] = this.uploadObj.getFilesData();

        for (let i = 0; i < args.filesData.length; i++) {
            // eslint-disable-next-line @typescript-eslint/prefer-for-of
            for (let j = 0; j < existingFiles.length; j++) {
                if (!isNullOrUndefined(args.filesData[i])) {
                    // eslint-disable-next-line eqeqeq
                    if (existingFiles[j].name == args.filesData[i].name) {
                        args.filesData.splice(i, 1);
                    }
                }
            }
        }

        existingFiles = existingFiles.concat(args.filesData);
        args.modifiedFilesData = existingFiles;
        args.isModified = true;
    }

    beforeFileUpload(args: BeforeUploadEventArgs): void {
        if (this.currentUser) {
            args.customFormData = [{created_by: this.currentUser.id}];
        }
    }

    onFileUpload: EmitType<SelectedEventArgs> = (args: UploadingEventArgs) => {
        if (this.currentUser) {
            args.currentRequest?.setRequestHeader(
                'Authorization',
                `Bearer ${this.currentUser.token}`,
            );
            args.customFormData = [{created_by: this.currentUser.id}];
        }
    };

    onSuccessUpload(args: SuccessEventArgs): void {
        this.hotlinesData = [];

        if (args.file) {
            const body = 'Soubor: ' + args.file.name;
            const options = {progressBar: true, timeOut: 5000, toastClass: 'success'};

            this.messages.showSuccess('Soubor úspěšně nahrán', body, options);
            this.getXLSXData(args.file.rawFile as Blob, (dataRow: FileImportHotline[]) => {
                dataRow.forEach((hotline: FileImportHotline) => {
                    let points = 0;
                    const start = moment(`${hotline.date} 08:00:00`)
                        .format('YYYY-MM-DD HH:mm');
                    const end = moment(`${hotline.date} 22:00:00`)
                        .format('YYYY-MM-DD HH:mm');

                    if (
                        moment(start, 'YYYY-MM-DD')
                            .isBusinessDay() &&
                        !this.holidays.includes(start)
                    ) {
                        points = 10;
                    } else if (
                        !moment(start, 'YYYY-MM-DD')
                            .isBusinessDay() &&
                        !this.holidays.includes(start)
                    ) {
                        points = 100;
                    } else if (
                        moment(start, 'YYYY-MM-DD')
                            .isBusinessDay() &&
                        this.holidays.includes(start)
                    ) {
                        points = 500;
                    } else if (
                        !moment(start, 'YYYY-MM-DD')
                            .isBusinessDay() &&
                        this.holidays.includes(start)
                    ) {
                        points = 1000;
                    }

                    if (this.currentUser) {
                        const hotlineData: HotlineInput = {
                            start_at: start,
                            end_at: end,
                            type: hotline.type,
                            hotliner_id: hotline.hotliner_id,
                            company_id: hotline.company_id,
                            created_by: this.currentUser.id,
                            score: points,
                        };

                        this.hotlinesData.push(hotlineData);
                    }
                });
                this.result();
            });
        }
    }

    onFileRemove(args: RemovingEventArgs): void {
        this.removeFile = [];
        args.postRawFile = false;
        this.removeFile.push(args.filesData);
        this.deleteFileDialog.show();
    }

    removeHotlineFile(): void {
        this.loadingHotline = true;
        this.uploadObj.clearAll();
        this.deleteFileDialog.hide();
        this.removeFile = [];
        this.loadingHotline = false;
    }

    result(): void {
        const promises: Promise<Hotline[] | undefined>[] = [];
        let rejects = 0;
        let skipped = 0;

        if (this.hotlinesData.length > 0) {
            this.loadingHotline = true;

            const dataFeed: HotlineInput[] = [];

            for (const row of this.hotlinesData) {
                if (
                    this.currentUser &&
                    this.permissionService.checkUserISHotlineAdmin(this.currentUser)
                ) {
                    for (
                        const date = moment(row.start_at);
                        date <= moment(row.end_at);
                        date.add(1, 'days')
                    ) {
                        const existCheck = this.hotlines?.find(
                            x =>
                                moment(x.start_at)
                                    .format('YYYY-MM-DD') ===
                                moment(date)
                                    .format('YYYY-MM-DD') &&
                                moment(x.end_at)
                                    .format('YYYY-MM-DD') ===
                                moment(date)
                                    .format('YYYY-MM-DD') &&
                                x.type === row.type &&
                                x.hotliner_id === row.hotliner_id &&
                                x.company_id === row.company_id,
                        );

                        if (!existCheck) {
                            row.deleted_by = this.currentUser.id;
                            dataFeed.push(row);
                        } else {
                            skipped++;
                        }
                    }
                } else {
                    rejects++;
                }
            }

            const createObj = this.hotlineService.addHotline(dataFeed)
                .toPromise();

            promises.push(createObj);
            void Promise.all(promises)
                .then(() => {
                    if (dataFeed.length > 0) {
                        const body = `Celkem přidáno: ${dataFeed.length} hot-line`;
                        const options = {progressBar: true, timeOut: 5000};

                        this.messages.showSuccess('Import hot-line úspěšně dokončen', body, options);
                    }

                    if (rejects > 0) {
                        const body = `Celkem u: ${rejects} hot-line`;
                        const options = {progressBar: true, timeOut: 5000};

                        this.messages.showError('Nedostatečné oprávnění', body, options);
                    }

                    if (skipped > 0) {
                        const body = `Celkem: ${skipped} aktivních hot-line již existuje`;
                        const options = {
                            progressBar: true,
                            timeOut: 5000,
                            toastClass: 'warning',
                        };

                        this.messages.showWarning('Hotline', body, options);
                    }

                    this.isDirty = false;
                    this.loadingHotline = false;
                    this.dataService.clearHotlinesCache();
                    this.dataService.setHotlinesDataSource();
                    this.ref.markForCheck();
                });
        }
    }
}
