import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnInit,
    ViewChild,
} from '@angular/core';
import {registerLocaleData} from '@angular/common';
import {ActivatedRoute, Params, Router} from '@angular/router';

import moment from 'moment';
import localeCs from '@angular/common/locales/cs';
import {IAccTooltipRenderEventArgs} from '@syncfusion/ej2-charts';
import {SelectEventArgs} from '@syncfusion/ej2-navigations';
import {TabComponent, ToolbarComponent} from '@syncfusion/ej2-angular-navigations';
import {DashboardLayoutComponent} from '@syncfusion/ej2-angular-layouts';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {DataService} from '@src/app/_services/data.service';

moment.updateLocale('cs', {workingWeekdays: [1, 2, 3, 4, 5]});
registerLocaleData(localeCs);

@UntilDestroy()
@Component({
    selector: 'app-material',
    templateUrl: './material.component.html',
    styleUrls: ['./material.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MaterialComponent implements OnInit {
    // DashBoard
    count = 8;

    cellSpacing: number[] = [10, 10];

    cellAspectRatio: number = 100 / 100;

    // Navigations
    headerText = [
        {text: 'objednávky'},
        {text: 'položky objednávek'},
        {text: 'rozpočty'},
        {text: 'žádosti'},
        {text: 'statistiky'},
        {text: 'import'},
    ];

    // Maintoolbar
    @ViewChild('moduleToolbar') moduleToolbarObj?: ToolbarComponent;

    // DashBoard
    @ViewChild('default_dashboard') dashboard?: DashboardLayoutComponent;

    // Navigations
    @ViewChild('ticketTab') ticketTabObj?: TabComponent;

    constructor(
        private readonly router: Router,
        private readonly ref: ChangeDetectorRef,
        private readonly route: ActivatedRoute,
        private readonly dataService: DataService,
    ) {}

    ngOnInit(): void {
        this.dataService.setBudgetsDataSource();
    }

    onCreate(): void {
        // Nastavení GUI podle URL parametrů
        this.route.queryParamMap.pipe(untilDestroyed(this))
            .subscribe(queryParams => {
                if (this.ticketTabObj) {
                    if (queryParams.get('view') === 'invoices') {
                        this.ticketTabObj.select(0);
                    } else if (queryParams.get('view') === 'items') {
                        this.ticketTabObj.select(1);
                    } else if (queryParams.get('view') === 'budgets') {
                        this.ticketTabObj.select(2);
                    } else if (queryParams.get('view') === 'applications') {
                        this.ticketTabObj.select(3);
                    } else if (queryParams.get('view') === 'stats') {
                        this.ticketTabObj.select(4);
                    } else if (queryParams.get('view') === 'import') {
                        this.ticketTabObj.select(5);
                    } else {
                        this.ticketTabObj.select(0);
                    }
                }
            });
    }

    refreshContent(): void {
        setTimeout(() => {
            if (this.dashboard) {
                this.dashboard.refresh();
            }

            if (this.moduleToolbarObj) {
                this.moduleToolbarObj.refresh();
            }
        }, 1000);
    }

    tooltipRender(args: IAccTooltipRenderEventArgs): void {
        const value = args.point.y / args.series.sumOfPoints * 100;

        args.text = `${args.point.x as string} : ${Math.ceil(value)} %`;
    }

    onCloseIconHandler(event: MouseEvent): void {
        if (event.target === null) {
            return;
        }

        const elementOffset = (event.target as HTMLElement).offsetParent;

        if (elementOffset) {
            this.dashboard?.removePanel(elementOffset.id);
        }
    }

    preventSwipe(e: SelectEventArgs): void {
        if (e.isSwiped) {
            e.cancel = true;
        }
    }

    navigateToTab(view: string, form: string): void {
        const queryParams = {view, form};

        if (this.ticketTabObj) {
            if (view === 'invoices') {
                this.ticketTabObj.select(0);
            } else if (view === 'items') {
                this.ticketTabObj.select(1);
            } else if (view === 'budgets') {
                this.ticketTabObj.select(2);
            } else if (view === 'applications') {
                this.ticketTabObj.select(3);
            } else if (view === 'stats') {
                this.ticketTabObj.select(4);
            } else if (view === 'import') {
                this.ticketTabObj.select(5);
            } else {
                this.ticketTabObj.select(0);
            }
        }

        void this.router.navigate([], {
            relativeTo: this.route,
            queryParams,
            queryParamsHandling: 'merge',
        });
    }

    select(e: SelectEventArgs): void {
        let queryParams: Params = {view: null};

        this.route.queryParamMap.pipe(untilDestroyed(this))
            .subscribe(params => {
                const form = params.get('form') === 'addMaterial' ? 'addMaterial' : null;

                if (e.selectedItem.textContent === 'objednávky') {
                    queryParams = {
                        view: 'invoices',
                        form,
                    };
                }

                if (e.selectedItem.textContent === 'položky objednávek') {
                    queryParams = {
                        view: 'items',
                        form,
                    };
                }

                if (e.selectedItem.textContent === 'rozpočty') {
                    queryParams = {
                        view: 'budgets',
                        form,
                    };
                }

                if (e.selectedItem.textContent === 'žádosti') {
                    queryParams = {
                        view: 'applications',
                        form,
                    };
                }

                if (e.selectedItem.textContent === 'statistiky') {
                    queryParams = {
                        view: 'stats',
                        form,
                    };
                }

                if (e.selectedItem.textContent === 'import') {
                    queryParams = {
                        view: 'import',
                        form,
                    };
                }

                void this.router.navigate([], {
                    relativeTo: this.route,
                    queryParams,
                    queryParamsHandling: 'merge',
                });
            });
    }
}
