import {
    AfterViewChecked,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnInit,
    ViewChild,
} from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {Observable, Observer} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthenticationService} from '@src/app/_services/authentication.service';
import {ExcelService} from '@src/app/_services/excel.service';
import {DataService} from '@src/app/_services/data.service';
import {
    NetPackageInput,
    ServicesService,
    TvServiceInput,
} from '@src/app/features/services/services.service';
import {PermissionService} from '@src/app/_services/permission.service';
import {MessageService} from '@src/app/_services/message.service';
import {NetPackages} from '@src/app/_models/services/net-packages';
import {DataNetbaseService} from '@src/app/_services/data-netbase.service';
import {Bonuses} from '@src/app/_models/services/bonuses';
import {ExtendServices} from '@src/app/_models/services/extend-services';
import {Places} from '@src/app/_models/services/places';
import {TvPackages} from '@src/app/_models/services/tv-packages';
import {TvServices} from '@src/app/_models/services/tv-services';
import {ButtonPropsModel, DialogComponent} from '@syncfusion/ej2-angular-popups';
import {User} from '@src/app/_models/user/user';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {ChangeEventArgs, DropDownListComponent} from '@syncfusion/ej2-angular-dropdowns';
import {
    ColumnModel,
    DataResult,
    EditSettingsModel,
    ExcelExportProperties,
    FilterSettingsModel,
    GridComponent,
    GroupSettingsModel,
    PageSettingsModel,
    RowDataBoundEventArgs,
    SelectionSettingsModel,
    ToolbarItems,
} from '@syncfusion/ej2-angular-grids';
import {TextBoxComponent} from '@syncfusion/ej2-angular-inputs';
import {ClickEventArgs} from '@syncfusion/ej2-angular-navigations';

@UntilDestroy()
@Component({
    selector: 'app-services-tarifs',
    templateUrl: './services-tarifs.component.html',
    styleUrls: ['./services-tarifs.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ServicesTarifsComponent implements OnInit, AfterViewChecked {
    // Grid
    currencyFormat: {format: string} = {format: '#,###.00\',- CZK\''};

    dateFormat: string;

    toolbarNet: ToolbarItems[] | object;

    toolbarTv: ToolbarItems[] | object;

    dataNet: object;

    dataTv: object;

    fields: object = {text: 'label', value: 'value'};

    filterSettings: FilterSettingsModel;

    filterCheckboxSettings: FilterSettingsModel;

    selectionSettings: SelectionSettingsModel;

    editSettings: EditSettingsModel;

    groupOptions: GroupSettingsModel;

    pageOptions: PageSettingsModel;

    expendituresColumnsPrice: ColumnModel[];

    expendituresColumnsItems: ColumnModel[];

    filterDate: object;

    sortOptionsTv: object;

    sortOptionsNet: object;

    requiredRules: object;

    exportCurrentPage = false;

    // Dialogs
    renderTvServiceFormDialog = false;

    renderNetPackageFormDialog = false;

    buttonsCancel: ButtonPropsModel[] = [
        {
            click: (): void => {
                this.confirmationCancel.hide();
            },
            buttonModel: {
                isPrimary: true,
                content: 'OK',
            },
        },
    ];

    buttonsDeleteTvTarif: ButtonPropsModel[] = [
        {
            click: (): void => {
                this.deleteTvService();
            },
            isFlat: false,
            buttonModel: {
                content: 'OK',
                cssClass: 'e-success e-outline',
            },
        },
        {
            click: (): void => {
                this.confirmationDeleteTvTarif.hide();
            },
            buttonModel: {
                content: 'ZRUŠIT',
                cssClass: 'e-danger e-outline',
            },
        },
    ];

    buttonsDeleteNetTarif: ButtonPropsModel[] = [
        {
            click: (): void => {
                this.deleteNetPackage();
            },
            isFlat: false,
            buttonModel: {
                content: 'OK',
                cssClass: 'e-success e-outline',
            },
        },
        {
            click: (): void => {
                this.confirmationDeleteNetTarif.hide();
            },
            buttonModel: {
                content: 'ZRUŠIT',
                cssClass: 'e-danger e-outline',
            },
        },
    ];

    // Dropdowns
    height = '240px';

    categoryFilterNetData: Array<string> = [];

    categoryFilterTvData: Array<string> = [];

    // Forms
    isCreateNet = false;

    isCreateTv = false;

    isUpdateNet = false;

    isUpdateTv = false;

    isCopyNet = false;

    isCopyTv = false;

    // Variables
    currentUser: User | null;

    netPackages: NetPackages[] | undefined = [];

    tvServices: TvServices[] | undefined = [];

    clickedNetRow: NetPackages[] = [];

    clickedTvRow: TvServices[] = [];

    clickedNetRow$ = new Observable<NetPackages[]>((observer: Observer<NetPackages[]>) => {
        observer.next(this.clickedNetRow);
    });

    clickedTvRow$ = new Observable<TvServices[]>((observer: Observer<TvServices[]>) => {
        observer.next(this.clickedTvRow);
    });

    // Loaders
    tableLoad = true;

    // Grid
    @ViewChild('netTarifsGrid') gridNetTarifs: GridComponent;

    @ViewChild('tvTarifsGrid') gridTvTarifs: GridComponent;

    @ViewChild('searchtextNet') searchtextNetObj: TextBoxComponent;

    @ViewChild('searchtextTv') searchtextTvObj: TextBoxComponent;

    // Dialogs
    @ViewChild('confirmationDeleteTvTarif')
    confirmationDeleteTvTarif: DialogComponent;

    @ViewChild('confirmationDeleteNetTarif')
    confirmationDeleteNetTarif: DialogComponent;

    @ViewChild('confirmationCancel') confirmationCancel: DialogComponent;

    // Dropdowns
    @ViewChild('viewFilterNet') viewFilterInstanceNet: DropDownListComponent;

    @ViewChild('viewFilterTv') viewFilterInstanceTv: DropDownListComponent;

    constructor(
        private readonly router: Router,
        private readonly route: ActivatedRoute,
        private readonly ref: ChangeDetectorRef,
        private readonly formBuilder: FormBuilder,
        private readonly authenticationService: AuthenticationService,
        private readonly excelService: ExcelService,
        private readonly dataService: DataService,
        private readonly dataNetbaseService: DataNetbaseService,
        private readonly servicesService: ServicesService,
        private readonly permissionService: PermissionService,
        private readonly messages: MessageService,
    ) {
        this.currentUser = this.authenticationService.currentUserValue;
    }

    ngOnInit(): void {
        this.filterSettings = {
            type: 'Menu',
            showFilterBarStatus: true,
            ignoreAccent: true,
        };

        this.filterCheckboxSettings = {
            type: 'CheckBox',
            showFilterBarStatus: true,
            ignoreAccent: true,
        };

        this.filterSettings = {
            type: 'Excel',
            showFilterBarOperator: true,
            showFilterBarStatus: true,
            ignoreAccent: true,
        };

        this.selectionSettings = {
            persistSelection: true,
            type: 'Multiple',
            checkboxOnly: true,
        };

        this.toolbarNet = [
            {
                text: 'Občerstvit data',
                tooltipText: 'Občerstvit data',
                prefixIcon: 'e-refresh',
                id: 'customRefresh',
            },
            'Print',
            'ExcelExport',
            'Add',
            'Edit',
            {
                text: 'Smazat',
                tooltipText: 'Smazat NET tarif',
                prefixIcon: 'e-remove',
                id: 'delete',
            },
            {
                text: 'Kopírovat',
                tooltipText: 'Kopírovat NET tarif',
                prefixIcon: 'e-copy',
                id: 'copy',
            },
        ];

        this.toolbarTv = [
            {
                text: 'Občerstvit data',
                tooltipText: 'Občerstvit data',
                prefixIcon: 'e-refresh',
                id: 'customRefresh',
            },
            'Print',
            'ExcelExport',
            'Add',
            'Edit',
            {
                text: 'Smazat',
                tooltipText: 'Smazat TV službu',
                prefixIcon: 'e-remove',
                id: 'delete',
            },
            {
                text: 'Kopírovat',
                tooltipText: 'Kopírovat TV službu',
                prefixIcon: 'e-copy',
                id: 'copy',
            },
        ];

        this.categoryFilterNetData = [
            'všechny NET tarify',
            'aktivní NET tarify',
            'neaktivní NET tarify',
        ];

        this.categoryFilterTvData = [
            'všechny TV služby',
            'aktivní TV služby',
            'neaktivní TV služby',
        ];
        this.pageOptions = {pageSize: 50};
        this.dateFormat = 'dd.MM.yyyy HH:mm';
        this.filterDate = {type: 'Menu', params: {format: this.dateFormat}};
        this.groupOptions = {
            showGroupedColumn: true,
            disablePageWiseAggregates: false,
        };

        this.editSettings = {
            showConfirmDialog: false,
            showDeleteConfirmDialog: false,
            allowEditing: true,
            allowAdding: true,
            allowDeleting: true,
            allowEditOnDblClick: false,
            mode: 'Dialog',
        };
        this.requiredRules = {required: true};

        this.dataNetbaseService.setNetPackagesDataSource();
        this.dataNetbaseService.setTvServicesDataSource();
    }

    ngAfterViewChecked(): void {
        this.route.queryParamMap.pipe(untilDestroyed(this))
            .subscribe(params => {
                if (params.get('form') === 'addNetPackage') {
                    this.isCreateNet = true;
                    this.renderNetPackageFormDialog = true;
                    this.ref.markForCheck();
                }

                if (params.get('form') === 'addTvService') {
                    this.isCreateTv = true;
                    this.renderTvServiceFormDialog = true;
                    this.ref.markForCheck();
                }
            });
    }

    loadDataTv(): DataResult[] {
        console.info('NEW TV DATA BOUND');

        const elements: TvServiceInput[] = [];

        if (this.tvServices && this.tvServices.length > 0) {
            this.tvServices.map((tarif: TvServices) => {
                let extServices = '<ul class="list-group list-group-flush">';
                let bonuses = '<ul class="list-group list-group-flush">';
                let places = '<ul class="list-group list-group-flush">';
                let packages = '<ul class="list-group list-group-flush">';

                tarif.ext_services.forEach((service: ExtendServices) => {
                    extServices += `<li class="list-group-item p-1">${service.name}</li>`;
                });

                tarif.tv_bonuses.forEach((bonus: Bonuses) => {
                    bonuses += `<li class="list-group-item p-1">${bonus.name}</li>`;
                });

                tarif.tv_places.forEach((place: Places) => {
                    places += `<li class="list-group-item p-1">${place.name}</li>`;
                });

                tarif.tv_packages?.forEach((tvPackage: TvPackages) => {
                    packages += `<li class="list-group-item p-1">${tvPackage.name}</li>`;
                });
                extServices += '</ul>';
                bonuses += '</ul>';
                places += '</ul>';
                packages += '</ul>';

                elements.push({
                    id: tarif.id,
                    name: tarif.name,
                    price: tarif.pernament_price,
                    provider: tarif.provider.name,
                    recommended: tarif.recommended,
                    extension: tarif.extension,
                    sale_order: tarif.sale_order,
                    ext_services: extServices,
                    transfer:
                        tarif.transfer.connection_method === 'optical cable'
                            ? '<i class="fa-light fa-disc-drive"></i> optické připojení'
                            : tarif.transfer.connection_method === 'metalic cable'
                                ? '<i class="fa-light fa-ethernet"></i> kabelové připojení'
                                : tarif.transfer.connection_method === 'radio'
                                    ? '<i class="fa-light fa-broadcast-tower"></i> rádiové připojení'
                                    : '<i class="fa-light fa-router"></i> xDSL připojení',
                    discount: tarif.discount ? `${tarif.discount.discount}%` : '0%',
                    tv_packages: packages,
                    tv_places: places,
                    tv_bonuses: bonuses,
                    created_by: tarif.creator.id,
                    creator_str: tarif.creator.fullname,
                    creator_img: tarif.creator.personalphoto,
                    created_date: new Date(tarif.created_date),
                    updated_date: tarif.updated_date ? new Date(tarif.updated_date) : null,
                    deleted_date: tarif.deleted_date ? new Date(tarif.deleted_date) : null,
                });
            });
        }

        this.tableLoad = false;

        return elements as unknown as DataResult[];
    }

    loadDataNet(): DataResult[] {
        console.info('NEW NET DATA BOUND');

        const elements: NetPackageInput[] = [];

        if (this.netPackages && this.netPackages.length > 0) {
            this.netPackages.map((tarif: NetPackages) => {
                if (
                    this.currentUser &&
                    this.permissionService.checkUserISServiceAdmin(this.currentUser)
                ) {
                    let extServices = '<ul class="list-group list-group-flush">';
                    let bonuses = '<ul class="list-group list-group-flush">';
                    let places = '<ul class="list-group list-group-flush">';

                    tarif.ext_services.forEach((service: ExtendServices) => {
                        extServices += `<li class="list-group-item p-1">${service.name}</li>`;
                    });

                    tarif.net_bonuses.forEach((bonus: Bonuses) => {
                        bonuses += `<li class="list-group-item p-1">${bonus.name}</li>`;
                    });

                    tarif.net_places.forEach((place: Places) => {
                        places += `<li class="list-group-item p-1">${place.name}</li>`;
                    });
                    extServices += '</ul>';
                    bonuses += '</ul>';
                    places += '</ul>';
                    elements.push({
                        id: tarif.id,
                        name: tarif.name,
                        price: tarif.price,
                        provider: tarif.provider.name,
                        aggregation: tarif.aggregation ? tarif.aggregation : '',
                        recommended: tarif.recommended,
                        extension: tarif.extension,
                        sale_order: tarif.sale_order,
                        speed_up: tarif.speed_up ? `${tarif.speed_up} Mbit` : '',
                        speed_down: tarif.speed_down ? `${tarif.speed_down} Mbit` : '',
                        ext_services: extServices,
                        transfer:
                            tarif.transfer.connection_method === 'optical cable'
                                ? '<i class="fa-light fa-disc-drive"></i> optické připojení'
                                : tarif.transfer.connection_method === 'metalic cable'
                                    ? '<i class="fa-light fa-ethernet"></i> kabelové připojení'
                                    : tarif.transfer.connection_method === 'radio'
                                        ? '<i class="fa-light fa-broadcast-tower"></i> rádiové připojení'
                                        : '<i class="fa-light fa-router"></i> xDSL připojení',
                        discount: tarif.discount ? `${tarif.discount.discount}%` : '0%',
                        net_places: places,
                        net_bonuses: bonuses,
                        created_by: tarif.creator.id,
                        creator_str: tarif.creator.fullname,
                        creator_img: tarif.creator.personalphoto,
                        created_date: new Date(tarif.created_date),
                        updated_date: tarif.updated_date ? new Date(tarif.updated_date) : null,
                        deleted_date: tarif.deleted_date ? new Date(tarif.deleted_date) : null,
                    });
                }
            });
        }

        this.tableLoad = false;

        return elements as unknown as DataResult[];
    }

    onCreatedTv(): void {
        this.disableToolBarBtnTv();
    }

    onCreatedNet(): void {
        this.disableToolBarBtnNet();
    }

    setInitialGridFiltering(): void {
        this.gridNetTarifs.clearFiltering();
        this.gridTvTarifs.clearFiltering();
        this.ref.markForCheck();
    }

    onLoadNet(): void {
        const wrapper1 = document.getElementById('scroll_wrapper1_net');
        const wrapper2 = document.getElementById('scroll_wrapper2_net');

        if (wrapper1 && wrapper2) {
            wrapper1.onscroll = (): void => {
                wrapper2.scrollLeft = wrapper1.scrollLeft;
            };

            wrapper2.onscroll = (): void => {
                wrapper1.scrollLeft = wrapper2.scrollLeft;
            };
        }

        this.dataNetbaseService.netPackagesSource.pipe(untilDestroyed(this))
            .subscribe(
                (netPackages: NetPackages[] | undefined) => {
                    this.netPackages = netPackages;

                    if (this.netPackages && this.netPackages.length > 0) {
                        this.dataNet = this.gridNetTarifs.dataSource = this.loadDataNet();
                        this.sortOptionsNet = {
                            columns: [
                                {field: 'created_date', direction: 'Descending'},
                                {field: 'id', direction: 'Descending'},
                            ],
                        };
                    }
                },
                error => {
                    console.error(error);
                    this.tableLoad = false;
                    this.ref.markForCheck();
                },
            );
    }

    onLoadTv(): void {
        const wrapper1 = document.getElementById('scroll_wrapper1_tv');
        const wrapper2 = document.getElementById('scroll_wrapper2_tv');

        if (wrapper1 && wrapper2) {
            wrapper1.onscroll = (): void => {
                wrapper2.scrollLeft = wrapper1.scrollLeft;
            };

            wrapper2.onscroll = (): void => {
                wrapper1.scrollLeft = wrapper2.scrollLeft;
            };
        }

        this.dataNetbaseService.tvServicesSource.pipe(untilDestroyed(this))
            .subscribe(
                (tvServices: TvServices[] | undefined) => {
                    this.tvServices = tvServices;

                    if (this.tvServices && this.tvServices.length > 0) {
                        this.dataTv = this.gridTvTarifs.dataSource = this.loadDataTv();
                        this.sortOptionsTv = {
                            columns: [
                                {field: 'created_date', direction: 'Descending'},
                                {field: 'id', direction: 'Descending'},
                            ],
                        };
                    }
                },
                error => {
                    console.error(error);
                    this.tableLoad = false;
                    this.ref.markForCheck();
                },
            );
    }

    searchNet(): void {
        this.gridNetTarifs.search(this.searchtextNetObj.value);
    }

    searchTv(): void {
        this.gridTvTarifs.search(this.searchtextTvObj.value);
    }

    onDataBoundNet(): void {
        this.rowSelectedNet();

        const width = document.getElementById('netTarifsGrid_content_table')?.offsetWidth;
        const parent1 = document.getElementById('scroll_div_net');
        const parent2 = document.getElementById('grid_parent_net');

        if (parent1 && parent2 && width) {
            parent1.style.width = `${width}px`;
            parent2.style.width = `${width}px`;
        }

        this.gridNetTarifs.autoFitColumns();
        this.ref.markForCheck();
    }

    onDataBoundTv(): void {
        this.rowSelectedTv();

        const width = document.getElementById('tvTarifsGrid_content_table')?.offsetWidth;
        const parent1 = document.getElementById('scroll_div_tv');
        const parent2 = document.getElementById('grid_parent_tv');

        if (parent1 && parent2 && width) {
            parent1.style.width = `${width}px`;
            parent2.style.width = `${width}px`;
        }

        this.gridTvTarifs.autoFitColumns();
        this.ref.markForCheck();
    }

    rowDataBoundNet(args: RowDataBoundEventArgs): void {
        const data = args.data as NetPackages;

        if (args.row && data.deleted_date) {
            args.row.getElementsByClassName('e-gridchkbox')[0].classList.add('disablecheckbox');
            args.row
                .getElementsByClassName('e-checkbox-wrapper')[0]
                .classList.add('disablecheckbox');
            args.row.classList.add('e-disabled');
        }

        if (
            this.currentUser &&
            args.row &&
            data.created_by !== this.currentUser.id &&
            !this.permissionService.checkUserISAdministrative(this.currentUser)
        ) {
            args.row.getElementsByClassName('e-gridchkbox')[0].classList.add('disablecheckbox');
            args.row
                .getElementsByClassName('e-checkbox-wrapper')[0]
                .classList.add('disablecheckbox');
        }

        this.ref.markForCheck();
    }

    rowDataBoundTv(args: RowDataBoundEventArgs): void {
        const data = args.data as TvServices;

        if (args.row && data.deleted_date) {
            args.row.getElementsByClassName('e-gridchkbox')[0].classList.add('disablecheckbox');
            args.row
                .getElementsByClassName('e-checkbox-wrapper')[0]
                .classList.add('disablecheckbox');
            args.row.classList.add('e-disabled');
        }

        if (
            this.currentUser &&
            args.row &&
            data.created_by !== this.currentUser.id &&
            !this.permissionService.checkUserISAdministrative(this.currentUser)
        ) {
            args.row.getElementsByClassName('e-gridchkbox')[0].classList.add('disablecheckbox');
            args.row
                .getElementsByClassName('e-checkbox-wrapper')[0]
                .classList.add('disablecheckbox');
        }

        this.ref.markForCheck();
    }

    onStatusFilterChangeNet(e: ChangeEventArgs): void {
        if (e.value === 'všechny NET tarify') {
            this.gridNetTarifs.clearFiltering();
        } else if (e.value === 'aktivní NET tarify') {
            this.gridNetTarifs.clearFiltering();
            this.gridNetTarifs.filterByColumn('deleted_date', 'equal', null, 'and', false, true);
        } else if (e.value === 'neaktivní NET tarify') {
            this.gridNetTarifs.clearFiltering();
            this.gridNetTarifs.filterByColumn('deleted_date', 'notequal', null, 'and', false, true);
        }
    }

    onStatusFilterChangeTv(e: ChangeEventArgs): void {
        if (e.value === 'všechny TV služby') {
            this.gridTvTarifs.clearFiltering();
        } else if (e.value === 'aktivní TV služby') {
            this.gridTvTarifs.clearFiltering();
            this.gridTvTarifs.filterByColumn('deleted_date', 'equal', null, 'and', false, true);
        } else if (e.value === 'neaktivní TV služby') {
            this.gridTvTarifs.clearFiltering();
            this.gridTvTarifs.filterByColumn('deleted_date', 'notequal', null, 'and', false, true);
        }
    }

    cancelBtnNetClick(): void {
        this.searchtextNetObj.value = '';
        this.gridNetTarifs.searchSettings.key = '';
    }

    cancelBtnTvClick(): void {
        this.searchtextTvObj.value = '';
        this.gridTvTarifs.searchSettings.key = '';
    }

    toolbarClickNet(args: ClickEventArgs): void {
        const xlsProp: ExcelExportProperties = {
            fileName: 'js_netTarifs_export.xlsx',
            enableFilter: true,
            exportType: this.exportCurrentPage ? 'CurrentPage' : 'AllPages',
        };

        if (args.item.id === 'netTarifsGrid_excelexport') {
            void this.gridNetTarifs.excelExport(xlsProp);
        } else if (args.item.id === 'delete') {
            if (this.gridNetTarifs.getSelectedRecords().length !== 0) {
                this.confirmationDeleteNetTarif.content = `Potvrďte smazání ${this.clickedNetRow.length} NET tarifů`;
                this.confirmationDeleteNetTarif.show();
            } else {
                this.confirmationCancel.content = 'Musíte vybrat alespoň jeden NET tarif';
                this.confirmationCancel.show();
            }
        } else if (args.item.id === 'copy') {
            if (this.gridNetTarifs.getSelectedRecords().length !== 0) {
                this.isUpdateNet = false;
                this.isCreateNet = false;
                this.isCopyNet = true;
                this.renderNetPackageFormDialog = true;
                this.ref.markForCheck();
            } else {
                this.confirmationCancel.content = 'Musíte vybrat alespoň jeden NET tarif';
                this.confirmationCancel.show();
            }
        } else if (args.item.id === 'customRefresh') {
            this.tableLoad = true;
            console.info('refresh DATA!');
            this.ref.markForCheck();
            this.dataNetbaseService.clearNetPackagesCache();
            this.dataNetbaseService.setNetPackagesDataSource();
        }
    }

    toolbarClickTv(args: ClickEventArgs): void {
        const xlsProp: ExcelExportProperties = {
            fileName: 'js_tvTarifs_export.xlsx',
            enableFilter: true,
            exportType: this.exportCurrentPage ? 'CurrentPage' : 'AllPages',
        };

        if (args.item.id === 'tvTarifsGrid_excelexport') {
            void this.gridTvTarifs.excelExport(xlsProp);
        } else if (args.item.id === 'delete') {
            if (this.gridTvTarifs.getSelectedRecords().length !== 0) {
                this.confirmationDeleteTvTarif.content = `Potvrďte smazání ${this.clickedTvRow.length} TV služeb`;
                this.confirmationDeleteTvTarif.show();
            } else {
                this.confirmationCancel.content = 'Musíte vybrat alespoň jednu TV službu';
                this.confirmationCancel.show();
            }
        } else if (args.item.id === 'copy') {
            if (this.gridTvTarifs.getSelectedRecords().length !== 0) {
                this.isUpdateTv = false;
                this.isCreateTv = false;
                this.isCopyTv = true;
                this.renderTvServiceFormDialog = true;
                this.ref.markForCheck();
            } else {
                this.confirmationCancel.content = 'Musíte vybrat alespoň jednu TV službu';
                this.confirmationCancel.show();
            }
        } else if (args.item.id === 'customRefresh') {
            this.tableLoad = true;
            console.info('refresh DATA!');
            this.ref.markForCheck();
            this.dataNetbaseService.clearTvServicesCache();
            this.dataNetbaseService.setTvServicesDataSource();
        }
    }

    enableToolbarBtnTv(): void {
        this.gridTvTarifs.toolbarModule.enableItems(['tvTarifsGrid_edit', 'delete', 'copy'], true);
    }

    disableToolBarBtnTv(): void {
        this.gridTvTarifs.toolbarModule.enableItems(['tvTarifsGrid_edit', 'delete', 'copy'], false);
    }

    enableToolbarBtnNet(): void {
        this.gridNetTarifs.toolbarModule.enableItems(
            ['netTarifsGrid_edit', 'delete', 'copy'],
            true,
        );
    }

    disableToolBarBtnNet(): void {
        this.gridNetTarifs.toolbarModule.enableItems(
            ['netTarifsGrid_edit', 'delete', 'copy'],
            false,
        );
    }

    rowSelectedNet(): void {
        this.clickedNetRow = [];

        const selectedrecords: NetPackages[] =
            this.gridNetTarifs.getSelectedRecords() as NetPackages[];

        if (selectedrecords.length > 0) {
            selectedrecords.map((row: NetPackages) => {
                const tempData: NetPackages | undefined = this.netPackages?.find(
                    x => x.id === row.id,
                );

                if (tempData) {
                    this.clickedNetRow.push(tempData);
                }

                this.ref.markForCheck();
            });

            this.clickedNetRow$ = new Observable<NetPackages[]>(
                (observer: Observer<NetPackages[]>) => {
                    observer.next(this.clickedNetRow);
                },
            );
            this.enableToolbarBtnNet();
        } else {
            this.disableToolBarBtnNet();
            this.clickedNetRow$ = new Observable<NetPackages[]>(
                (observer: Observer<NetPackages[]>) => {
                    observer.next(this.clickedNetRow);
                },
            );
        }
    }

    rowSelectedTv(): void {
        this.clickedTvRow = [];

        const selectedrecords: TvServices[] =
            this.gridTvTarifs.getSelectedRecords() as TvServices[];

        if (selectedrecords.length > 0) {
            selectedrecords.map((row: TvServices) => {
                const tempData: TvServices | undefined = this.tvServices?.find(
                    x => x.id === row.id,
                );

                if (tempData) {
                    this.clickedTvRow.push(tempData);
                }

                this.ref.markForCheck();
            });

            this.clickedTvRow$ = new Observable<TvServices[]>(
                (observer: Observer<TvServices[]>) => {
                    observer.next(this.clickedTvRow);
                },
            );
            this.enableToolbarBtnTv();
        } else {
            this.disableToolBarBtnTv();
            this.clickedTvRow$ = new Observable<TvServices[]>(
                (observer: Observer<TvServices[]>) => {
                    observer.next(this.clickedTvRow);
                },
            );
        }
    }

    actionBeginNet(args): void {
        if (args.requestType === 'add' || args.requestType === 'beginEdit') {
            args.cancel = true;

            if (args.requestType === 'beginEdit') {
                this.isUpdateNet = true;
                this.isCreateNet = false;
                this.isCopyNet = false;
            }

            if (args.requestType === 'add') {
                this.isCreateNet = true;
                this.isUpdateNet = false;
                this.isCopyNet = false;
            }

            this.renderNetPackageFormDialog = true;
            this.ref.markForCheck();
        }
    }

    actionBeginTv(args): void {
        if (args.requestType === 'add' || args.requestType === 'beginEdit') {
            args.cancel = true;

            if (args.requestType === 'beginEdit') {
                this.isUpdateTv = true;
                this.isCreateTv = false;
                this.isCopyTv = false;
            }

            if (args.requestType === 'add') {
                this.isCreateTv = true;
                this.isUpdateTv = false;
                this.isCopyTv = false;
            }

            this.renderTvServiceFormDialog = true;
            this.ref.markForCheck();
        }
    }

    actionComplete(args): void {
        if (args.requestType === 'beginEdit' || args.requestType === 'add') {
            args.form.ej2_instances[0].rules = {};
        }
    }

    deleteNetPackage(): void {
        if (
            this.currentUser &&
            (this.permissionService.checkUserISServiceAdmin(this.currentUser) ||
                this.permissionService.checkUserISAdministrative(this.currentUser) ||
                this.permissionService.checkUserISMarketingAdmin(this.currentUser))
        ) {
            this.clickedNetRow[0].deleted_by = this.currentUser.id;
            this.servicesService
                .deleteNetPackage(this.clickedNetRow[0])
                ?.pipe(untilDestroyed(this))
                .subscribe(
                    (netPackage: NetPackages) => {
                        const body = `Internet tarif #${netPackage.id}`;
                        const options = {
                            progressBar: true,
                            timeOut: 5000,
                            toastClass: 'success',
                        };

                        this.messages.showSuccess('Internet tarif úspěšně smazán', body, options);
                        this.dataNetbaseService.setNetPackagesDataSource();
                        this.tableLoad = false;
                        this.confirmationDeleteNetTarif.hide();
                        this.ref.markForCheck();
                    },
                    error => {
                        console.error(error);

                        const body = 'Zkuste to později ...';
                        const options = {
                            progressBar: true,
                            timeOut: 5000,
                            toastClass: 'red',
                        };

                        this.messages.showError(
                            'Chyba během mazání internet tarifu',
                            body,
                            options,
                        );
                        this.tableLoad = false;
                        this.confirmationDeleteNetTarif.hide();
                        this.ref.markForCheck();
                    },
                );
        } else {
            const body = 'Nemáte oprávnění provést tuto akci...';
            const options = {progressBar: true, timeOut: 5000};

            this.messages.showError('Nedostatečné oprávnění', body, options);
            this.ref.markForCheck();
        }
    }

    deleteTvService(): void {
        if (
            this.currentUser &&
            (this.permissionService.checkUserISServiceAdmin(this.currentUser) ||
                this.permissionService.checkUserISAdministrative(this.currentUser) ||
                this.permissionService.checkUserISMarketingAdmin(this.currentUser))
        ) {
            this.clickedTvRow[0].deleted_by = this.currentUser.id;
            this.servicesService
                .deleteTvService(this.clickedTvRow[0])
                ?.pipe(untilDestroyed(this))
                .subscribe(
                    (tvService: TvServices) => {
                        const body = `Televizní tarif #${tvService.id}`;
                        const options = {progressBar: true, timeOut: 5000};

                        this.messages.showSuccess('Televizní tarif úspěšně smazán', body, options);
                        this.dataNetbaseService.setTvServicesDataSource();
                        this.tableLoad = false;
                        this.confirmationDeleteTvTarif.hide();
                        this.ref.markForCheck();
                    },
                    error => {
                        console.error(error);

                        const body = 'Zkuste to později ...';
                        const options = {progressBar: true, timeOut: 5000};

                        this.messages.showError(
                            'Chyba během mazání televizního tarifu',
                            body,
                            options,
                        );
                        this.tableLoad = false;
                        this.confirmationDeleteTvTarif.hide();
                        this.ref.markForCheck();
                    },
                );
        } else {
            const body = 'Nemáte oprávnění provést tuto akci...';
            const options = {progressBar: true, timeOut: 5000};

            this.messages.showError('Nedostatečné oprávnění', body, options);
            this.ref.markForCheck();
        }
    }
}
