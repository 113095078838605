import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {AuthenticationService} from '@src/app/_services/authentication.service';
import {PermissionService} from '@src/app/_services/permission.service';
import {Location} from '@angular/common';
import {DomSanitizer} from '@angular/platform-browser';
import {ApisService} from '@src/app/_api/apis.service';
import {AcsService} from '@src/app/_api/acs/acs.service';
import {MessageService} from '@src/app/_services/message.service';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {WarehouseItems} from '@src/app/_models/warehouse/warehouse-items';
import {catchError, share, shareReplay, tap} from 'rxjs/operators';
import {WarehouseTypes} from '@src/app/_models/warehouse/warehouse-type';
import {InvoiceDocuments} from '@src/app/_models/invoices/invoice-documnets';
import {InvoiceItems} from '@src/app/_models/invoices/invoice-items';
import {InvoiceDocumentsWrapper} from '@src/app/_models/invoices/invoice-documnets-wrapper';
import {EnvironmentService} from '@src/app/_services/environment.service';

@Injectable({
    providedIn: 'root',
})
export class DataSyncService {
    private readonly warehouseItemsUrl: string;

    private readonly warehouseTypesUrl: string;

    private readonly invoicesUrl: string;

    private readonly resultWareHouseItemsSource = new BehaviorSubject<WarehouseItems[]>([]);

    private readonly resultWareHouseTypesSource = new BehaviorSubject<WarehouseTypes[]>([]);

    private readonly resultInvoicesSource = new BehaviorSubject<InvoiceDocuments[]>([]);

    warehouseItemsSource = this.resultWareHouseItemsSource.asObservable();

    warehouseTypesSource = this.resultWareHouseTypesSource.asObservable();

    invoicesSource = this.resultInvoicesSource.asObservable();

    constructor(
        private readonly router: Router,
        private readonly http: HttpClient,
        private readonly authenticationService: AuthenticationService,
        private readonly permissionsService: PermissionService,
        private readonly location: Location,
        private readonly sanitizer: DomSanitizer,
        private readonly apisService: ApisService,
        private readonly acsService: AcsService,
        private readonly messageService: MessageService,
        private readonly environmentService: EnvironmentService,
    ) {
        this.warehouseItemsUrl = `${this.environmentService.backendURL}/api/warehouse/items`;
        this.warehouseTypesUrl = `${this.environmentService.backendURL}/api/warehouse/types`;
        this.invoicesUrl = `${this.environmentService.backendURL}/api/invoices`;
    }

    /**
     * Handle Http operation that failed.
     * Let the app continue.
     * @param operation - name of the operation that failed
     * @param result - optional value to return as the observable result
     */
    private handleError<T>(operation = 'operation', result?: T) {
        return (error: {[key: string]: string}): Observable<T | undefined> => {
            console.error(error);
            this.log(`${operation} failed: ${error.message}`);

            return of(result);
        };
    }

    /** Log a ticketService message with the MessageService */
    private log(message: string): void {
        this.messageService.addNotification(`Ticket: ${message}`, false);
    }

    getInvoicesData(
        page: number,
        size: number,
        dataOwner?: string,
    ): Observable<InvoiceDocumentsWrapper | undefined> {
        const headers = new HttpHeaders({'Content-Type': 'application/json'});
        const params = new HttpParams()
            .set('data_owner', dataOwner ? dataOwner : '')
            .set('page', page ? page.toString() : '0')
            .set('size', size ? size.toString() : '0');

        return this.http.get<InvoiceDocumentsWrapper>(this.invoicesUrl, {headers, params})
            .pipe(
                tap(() => {
                    this.log('fetched invoices');
                }),
                shareReplay(),
                catchError(this.handleError<InvoiceDocumentsWrapper>('getQueue')),
            );
    }

    setInvoicesDataSource(page: number, size: number, ownedBy?: string): void {
        if (this.resultInvoicesSource.getValue().length === 0) {
            const resultInvoicesData: InvoiceDocuments[] = [];

            this.getInvoicesData(page, size, ownedBy)
                .subscribe(
                    (invoices: InvoiceDocumentsWrapper | undefined) => {
                        if (invoices?.data && invoices.totalItems > 0) {
                            invoices.data.forEach((invoice: InvoiceDocuments) => {
                                let sumPrice = 0;

                                invoice.items.map((item: InvoiceItems) => {
                                    sumPrice = sumPrice + item.cena_bez_dph;
                                });
                                invoice.total_cost = invoices.totalItems;
                                invoice.total_price = sumPrice;
                                resultInvoicesData.push(invoice);
                            });
                        }

                        this.resultInvoicesSource.next(resultInvoicesData);
                    },
                );
        }
    }

    getWarehouseTypesData(ownedBy?: number): Observable<WarehouseTypes[] | undefined> {
        const headers = new HttpHeaders({'Content-Type': 'application/json'});
        const params = new HttpParams().set(
            'owned_by',
            typeof ownedBy !== 'undefined' && ownedBy > 0 ? ownedBy : 0,
        );

        return this.http.get<WarehouseTypes[]>(this.warehouseTypesUrl, {headers, params})
            .pipe(
                tap(() => {
                    this.log('fetched warehouse types');
                }),
                shareReplay(),
                catchError(this.handleError<WarehouseTypes[]>('getQueue', [])),
            );
    }

    getWarehouseItemsData(ownedBy?: number): Observable<WarehouseItems[] | undefined> {
        const headers = new HttpHeaders({'Content-Type': 'application/json'});
        const params = new HttpParams().set(
            'owned_by',
            typeof ownedBy !== 'undefined' && ownedBy > 0 ? ownedBy : 0,
        );

        return this.http.get<WarehouseItems[]>(this.warehouseItemsUrl, {headers, params})
            .pipe(
                tap(() => {
                    this.log('fetched warehouse items');
                }),
                shareReplay(),
                catchError(this.handleError<WarehouseItems[]>('getQueue', [])),
            );
    }

    getWarehouseItemData(item: WarehouseItems | number): Observable<WarehouseItems | undefined> {
        const id = typeof item === 'number' ? item : item.id;
        const url = `${this.warehouseItemsUrl}/${id}`;

        return this.http.get<WarehouseItems>(url)
            .pipe(
                tap(() => {
                    this.log(`fetched warehouse item id=${id}`);
                }),
                share(),
                catchError(this.handleError<WarehouseItems>(`getWHItem id=${id}`)),
            );
    }

    setWarehouseTypesDataSource(ownedBy?: number): void {
        if (this.resultWareHouseTypesSource.getValue().length === 0) {
            const resultTypesData: WarehouseTypes[] = [];

            this.getWarehouseTypesData(ownedBy)
                .subscribe((types: WarehouseTypes[] | undefined) => {
                    if (types && types.length > 0) {
                        types.forEach((type: WarehouseTypes) => {
                            let sumSalePrice = 0;
                            let sumPurchasePrice = 0;
                            let sumUnits = 0;

                            type.items.map((item: WarehouseItems) => {
                                sumSalePrice = sumSalePrice + item.sale_price;
                                sumPurchasePrice = sumPurchasePrice + item.purchase_price;
                                sumUnits = sumUnits + item.units;
                            });
                            type.total_cost = type.items.length;
                            type.total_sale_price = sumSalePrice;
                            type.total_purchase_price = sumPurchasePrice;
                            type.total_units = sumUnits;
                            resultTypesData.push(type);
                        });
                    }

                    this.resultWareHouseTypesSource.next(resultTypesData);
                });
        }
    }

    setWarehouseItemsDataSource(ownedBy?: number): void {
        if (this.resultWareHouseItemsSource.getValue().length === 0) {
            const resultItemsData: WarehouseItems[] = [];

            this.getWarehouseItemsData(ownedBy)
                .subscribe((items: WarehouseItems[] | undefined) => {
                    if (items && items.length > 0) {
                        items.forEach((item: WarehouseItems) => {
                            resultItemsData.push(item);
                        });
                    }

                    this.resultWareHouseItemsSource.next(resultItemsData);
                });
        }
    }

    clearWarehouseTypesCache(): void {
        this.resultWareHouseTypesSource.next([]);
    }

    clearWarehouseItemsCache(): void {
        this.resultWareHouseItemsSource.next([]);
    }

    clearVacationCache(): void {
        this.resultInvoicesSource.next([]);
    }

    clearInvoicesCache(): void {
        this.resultInvoicesSource.next([]);
    }

    clearWholeCache(): void {
        this.resultWareHouseTypesSource.next([]);
        this.resultWareHouseItemsSource.next([]);
    }
}
