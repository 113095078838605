import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnInit,
    ViewChild,
} from '@angular/core';
import {Observable, Observer} from 'rxjs';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {AuthenticationService} from '@src/app/_services/authentication.service';
import {MessageService} from '@src/app/_services/message.service';
import {TimeagoIntl} from 'ngx-timeago';
import {strings as czechStrings} from 'ngx-timeago/language-strings/cs';
import {Vacation} from '@src/app/_models/vacation/vacation';
import {DataService} from '@src/app/_services/data.service';
import {PermissionService} from '@src/app/_services/permission.service';
import {User} from '@src/app/_models/user/user';
import {Holiday} from '@src/app/_models/holiday/holiday';
import {ButtonPropsModel, DialogComponent} from '@syncfusion/ej2-angular-popups';
import {AnimationModel, FontModel} from '@syncfusion/ej2-progressbar';
import {IProgressValueEventArgs, ProgressBarComponent} from '@syncfusion/ej2-angular-progressbar';
import {SelectEventArgs} from '@syncfusion/ej2-navigations';
import {ClickEventArgs, TabComponent} from '@syncfusion/ej2-angular-navigations';
import {ChangeEventArgs, DropDownListComponent} from '@syncfusion/ej2-angular-dropdowns';
import {
    DataResult,
    ExcelExportProperties,
    FilterSettingsModel,
    GridComponent,
    GroupSettingsModel,
    PageSettingsModel,
    RowDataBoundEventArgs,
    SelectionSettingsModel,
    ToolbarItems,
} from '@syncfusion/ej2-angular-grids';
import {VacationLogs} from '@src/app/_models/vacation/vacation-logs';
import {registerLocaleData} from '@angular/common';
import moment, {Moment, MomentInput} from 'moment-business-days';
import localeCs from '@angular/common/locales/cs';
import {AbstractControl, ValidatorFn} from '@angular/forms';
import {VacationCategory} from '@src/app/_models/vacation/vacation-category';
import {TextBoxComponent} from '@syncfusion/ej2-angular-inputs';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {VacationService} from '@src/app/features/vacation/vacation.service';
import {VacationComponent} from '@src/app/features/vacation/components/vacation/vacation.component';
import {ApisService} from '@src/app/_api/apis.service';
import {AcsService} from '@src/app/_api/acs/acs.service';
import {NavHandlerService} from '@src/app/_services/nav-handler.service';

moment.updateLocale('cs', {workingWeekdays: [1, 2, 3, 4, 5]});
registerLocaleData(localeCs);

interface VacationHistoryGridItems {
    id: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    type_id: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    vacation_id: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    category_id: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    created_by: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    creator_str: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    creator_img: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    updated_by: number | null | undefined;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    editor_str: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    editor_img: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    deleted_by: number | null | undefined;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    remover_str: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    remover_img: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    confirmed_by: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    confirmer_str: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    confirmer_img: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    declined_by: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    decliner_str: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    decliner_img: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    vacations_type: number;
    type: string;
    reason: string;
    status: string;
    workdays: number;
    confirm: boolean;
    decline: boolean;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    start_at: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    end_at: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    confirm_at: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    decline_at: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    created_date: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    updated_date: string | null | undefined;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    deleted_date: string | null;
    timestamp: Date;
}

@UntilDestroy()
@Component({
    selector: 'app-vacation-detail',
    templateUrl: './vacation-detail.component.html',
    styleUrls: ['./vacation-detail.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VacationDetailComponent extends VacationComponent implements OnInit {
    // Navigations
    headerText = [{text: 'detail'}, {text: 'historie změn'}];

    // ProgressBars
    animationDetail: AnimationModel;

    labelStyleDetail: FontModel;

    showProgressValueDetail: boolean;

    // Grid
    pageSettings: object;

    currencyFormat: {format: string} = {format: '#,###.00\',- CZK\''};

    dateFormat: object = {type: 'dateTime', format: 'dd.MM.yyyy HH:mm'};

    fields: object = {text: 'label', value: 'value'};

    elements: object;

    filterSettings: FilterSettingsModel;

    groupOptions: GroupSettingsModel;

    selectionSettings: SelectionSettingsModel;

    toolbarHistory: ToolbarItems[] | object;

    filterDate: object;

    sortHistoryOptions: object;

    requiredRules: object;

    dataHistory: object[] = [];

    pageOptions: PageSettingsModel;

    exportCurrentHistoryPage = false;

    // Dialogs
    renderVacationFormDialog = false;

    buttonsDelete: ButtonPropsModel[] = [
        {
            click: (): void => {
                this.stornoApplication();
            },
            isFlat: false,
            buttonModel: {
                content: 'OK',
                cssClass: 'e-success e-outline',
            },
        },
        {
            click: (): void => {
                this.confirmationDelete.hide();
            },
            buttonModel: {
                content: 'ZRUŠIT',
                cssClass: 'e-danger e-outline',
            },
        },
    ];

    buttonsDecline: ButtonPropsModel[] = [
        {
            click: (): void => {
                this.declineApplication();
            },
            isFlat: false,
            buttonModel: {
                content: 'OK',
                cssClass: 'e-success e-outline',
            },
        },
        {
            click: (): void => {
                this.confirmationDecline.hide();
            },
            buttonModel: {
                content: 'ZRUŠIT',
                cssClass: 'e-danger e-outline',
            },
        },
    ];

    buttonsConfirm: ButtonPropsModel[] = [
        {
            click: (): void => {
                this.confirmApplication();
            },
            isFlat: false,
            buttonModel: {
                content: 'OK',
                cssClass: 'e-success e-outline',
            },
        },
        {
            click: (): void => {
                this.confirmationConfirm.hide();
            },
            buttonModel: {
                content: 'ZRUŠIT',
                cssClass: 'e-danger e-outline',
            },
        },
    ];

    buttonsCancel: Array<object> = [
        {
            click: (): void => {
                this.confirmationCancel.hide();
            },
            buttonModel: {
                isPrimary: true,
                content: 'OK',
            },
        },
    ];

    // Dropdowns
    height = '240px';

    categoryFilterData: Array<string> = [];

    categorySelect: Array<object> = [];

    usersSelect: Array<object> = [];

    holidays: Array<string> = [];

    // Forms
    isCreate = false;

    isUpdate = false;

    isCopy = false;

    // proměnné pro data
    todayUnix = moment()
        .valueOf();

    logs: VacationLogs[] = [];

    vacation: Vacation;

    users: User[];

    matchedUser: User | null | undefined = null;

    workTime = 8;

    workTimeStart = '08:00';

    workTimeEnd = '16:00';

    statsVacation = 0;

    statsSickDays = 0;

    workdays = 0;

    workhours = 0;

    clickedRowHistory: VacationLogs[] = [];

    clickedRowHistory$ = new Observable<VacationLogs[]>((observer: Observer<VacationLogs[]>) => {
        observer.next(this.clickedRowHistory);
    });

    // přípaneče a loadingy
    loadingVacation = true;

    live: true;

    // Navigations
    @ViewChild('vacationTab') vacationTabDetailObj: TabComponent;

    // ProgressBars
    @ViewChild('sickDaysPercentage') sickDaysPercentageDetailObj: ProgressBarComponent;

    @ViewChild('vacationsPercentage')
    vacationsPercentageDetailObj: ProgressBarComponent;

    @ViewChild('sickDaysEditPercentage')
    sickDaysEditPercentageObj: ProgressBarComponent;

    @ViewChild('vacationsEditPercentage')
    vacationsEditPercentageObj: ProgressBarComponent;

    // Grid
    @ViewChild('vacationLogsGrid', {static: false}) gridLogs: GridComponent;

    @ViewChild('searchtext') searchtextObj: TextBoxComponent;

    // Dialogs
    @ViewChild('confirmationDelete') confirmationDelete: DialogComponent;

    @ViewChild('confirmationDecline') confirmationDecline: DialogComponent;

    @ViewChild('confirmationConfirm') confirmationConfirm: DialogComponent;

    @ViewChild('confirmationCancel') confirmationCancel: DialogComponent;

    // Dropdowns
    @ViewChild('viewFilter') viewFilterInstance: DropDownListComponent;

    @ViewChild('category') categoryObj: DropDownListComponent;

    @ViewChild('creator') userObj: DropDownListComponent;

    @ViewChild('boss') bossObj: DropDownListComponent;

    constructor(
        authenticationService: AuthenticationService,
        router: Router,
        ref: ChangeDetectorRef,
        route: ActivatedRoute,
        apisService: ApisService,
        acsService: AcsService,
        navHandlerService: NavHandlerService,
        dataService: DataService,
        messages: MessageService,
        permissionService: PermissionService,
        private readonly vacationService: VacationService,
        private readonly intl: TimeagoIntl,
    ) {
        super(
            authenticationService,
            router,
            ref,
            route,
            apisService,
            acsService,
            navHandlerService,
            dataService,
            messages,
            permissionService,
        );
        this.intl.strings = czechStrings;
        this.intl.changes.next();
    }

    lessThanDate(fieldDate: string): ValidatorFn {
        return (control: AbstractControl): {lessThan: {value: string}} | null => {
            const fieldToDateCompare = control.parent?.get(fieldDate);
            const endDate = control.value;
            const startDate = fieldToDateCompare?.value;
            const isLessThan =
                Number(moment(startDate as MomentInput)
                    .valueOf()) >
                Number(moment(endDate as MomentInput)
                    .valueOf());

            return isLessThan ? {lessThan: {value: control.value}} : null;
        };
    }

    greaterThanDate(fieldDate: string): ValidatorFn {
        return (control: AbstractControl): {greaterThan: {value: string}} | null => {
            const fieldToDateCompare = control.parent?.get(fieldDate);
            const startDate = control.value;
            const endDate = fieldToDateCompare?.value;
            const isGreaterThan =
                Number(moment(startDate as MomentInput)
                    .valueOf()) >
                Number(moment(endDate as MomentInput)
                    .valueOf());

            return isGreaterThan ? {greaterThan: {value: control.value}} : null;
        };
    }

    ngOnInit(): void {
        this.animation = {enable: true, duration: 2000, delay: 0};
        this.labelStyle = {
            color: '#FFFFFF',
            fontWeight: '900',
            fontFamily: 'Montserrat, sans-serif',
            fontStyle: 'normal',
        };
        this.showProgressValue = true;
        this.pageSettings = {pageCount: 5};
        this.sortHistoryOptions = {
            columns: [{field: 'created_date', direction: 'Descending'}],
        };
        this.selectionSettings = {type: 'Multiple', mode: 'Both'};
        this.categoryFilterData = [
            'všechny změny',
            'poslední den',
            'poslední týden',
            'poslední měsíc',
            'poslední rok',
        ];

        this.toolbarHistory = [
            {
                text: 'Občerstvit data',
                tooltipText: 'Občerstvit data',
                prefixIcon: 'e-refresh',
                id: 'customRefresh',
            },
            'Print',
            'ExcelExport',
            {
                text: 'Obnovit data',
                tooltipText: 'Obnovit data',
                prefixIcon: 'e-revert',
                id: 'revert',
            },
        ];
        this.pageOptions = {pageSize: 50};
        this.filterDate = {type: 'Menu', params: {format: this.dateFormat}};
        this.filterSettings = {
            type: 'Excel',
            showFilterBarOperator: true,
            showFilterBarStatus: true,
            ignoreAccent: true,
        };
        this.requiredRules = {required: true};
        this.filterDate = {type: 'Menu', params: {format: this.dateFormat}};

        this.dataService.vacationCategoriesSource.pipe(untilDestroyed(this))
            .subscribe({
                next: (categories: VacationCategory[]) => {
                    this.categorySelect = [];
                    categories.map((category: VacationCategory) => {
                        if (!category.deleted_date) {
                            this.categorySelect = [
                                ...this.categorySelect,
                                {
                                    value: category.id,
                                    label: category.name,
                                },
                            ];
                        }
                    });
                    this.ref.markForCheck();
                },
                error: error => {
                    console.error(error);
                },
            });

        this.dataService.userSource.pipe(untilDestroyed(this))
            .subscribe({
                next: (users: User[]) => {
                    this.users = users;
                    this.usersSelect = [];
                    users.map((user: User) => {
                        if (
                            this.currentUser &&
                            user.authorized &&
                            !user.deleted_date &&
                            (user.id === this.currentUser.id ||
                                user.boss_id === this.currentUser.id ||
                                this.permissionService.checkUserISVacationAdmin(this.currentUser))
                        ) {
                            this.usersSelect = [
                                ...this.usersSelect,
                                {
                                    value: user.id,
                                    label: user.secondname + ' ' + user.firstname,
                                },
                            ];
                        }
                    });
                    this.ref.markForCheck();
                },
                error: error => {
                    console.error(error);
                },
            });

        // data státních svátků + detail žádanky
        this.vacationService
            .getCurrCZEHolidays()
            .pipe(untilDestroyed(this))
            .subscribe({
                next: (holidays: Holiday[]) => {
                    holidays.map(holiday => {
                        if (holiday.isPublicHoliday) {
                            this.holidays.push(moment(holiday.date)
                                .format('YYYY-MM-DD'));
                            moment.updateLocale('cs', {
                                holidays: this.holidays,
                                holidayFormat: 'YYYY-MM-DD',
                                workingWeekdays: [1, 2, 3, 4, 5],
                            });
                            this.ref.markForCheck();
                        }
                    });

                    // subscribe dat konkrétní absence
                    this.dataService.vacationDetailSource.pipe(untilDestroyed(this))
                        .subscribe({
                            next: (vacation: Vacation | null | undefined) => {
                                if (vacation) {
                                    this.vacation = vacation;
                                    this.matchedUser = this.users.find(
                                        user =>
                                            user.id === this.vacation.created_by &&
                                            user.authorized &&
                                            !user.deleted_date,
                                    );
                                    this.getWorkingDays(this.vacation.start_at, this.vacation.end_at);
                                    // subscribe absencí
                                    this.dataService.vacationSource
                                        .pipe(untilDestroyed(this))
                                        .subscribe({
                                            next: (vacations: Vacation[] | undefined) => {
                                                this.vacations = vacations;

                                                if (this.vacations && this.matchedUser) {
                                                    this.setStats(this.matchedUser.id);
                                                }
                                            },
                                            error: error => {
                                                console.error(error);
                                                this.loadingVacation = false;
                                                this.ref.markForCheck();
                                            },
                                        });
                                    this.loadingVacation = false;
                                    this.ref.markForCheck();
                                }
                            },
                            error: error => {
                                console.error(error);
                                this.loadingVacation = false;
                                this.ref.markForCheck();
                            },
                        });

                    this.route.params.pipe(untilDestroyed(this))
                        .subscribe(params => {
                            this.dataService.setVacationDetailDataSource(
                                parseInt(params.id as string, 10),
                            );
                        });
                },
                error: error => {
                    console.error(error);
                },
            });

        // data uživatelů
        this.dataService.userSource.pipe(untilDestroyed(this))
            .subscribe({
                next: (data: User[]) => {
                    this.users = data;
                    this.ref.markForCheck();
                },
                error: error => {
                    console.error(error);
                },
            });

        this.dataService.vacationSource.pipe(untilDestroyed(this))
            .subscribe({
                next: (vacations: Vacation[]) => {
                    this.vacations = vacations;
                },
                error: error => {
                    console.error(error);
                    this.loadingVacation = false;
                    this.ref.markForCheck();
                },
            });
    }

    onCreate(): void {
        // Nastavení GUI podle URL parametrů
        this.route.queryParamMap.pipe(untilDestroyed(this))
            .subscribe(queryParams => {
                if (queryParams.get('view') === 'data') {
                    this.vacationTabDetailObj.selectedItem = 0;
                } else if (queryParams.get('view') === 'logs') {
                    this.vacationTabDetailObj.selectedItem = 1;
                } else {
                    this.vacationTabDetailObj.selectedItem = 0;
                }
            });
    }

    preventSwipe(e: SelectEventArgs): void {
        if (e.isSwiped) {
            e.cancel = true;
        }
    }

    select(e: SelectEventArgs): void {
        let queryParams: Params = {view: null};

        if (e.selectedItem.textContent === 'detail') {
            queryParams = {
                view: 'data',
                form: null,
            };
        }

        if (e.selectedItem.textContent === 'historie změn') {
            queryParams = {
                view: 'logs',
            };
        }

        void this.router.navigate([], {
            relativeTo: this.route,
            queryParams,
            queryParamsHandling: 'merge',
        });
    }

    onRenderCell(args): void {
        if (args.date.getDay() === 0 || args.date.getDay() === 6) {
            args.isDisabled = true;
        }
    }

    changedSickBar(args: IProgressValueEventArgs): void {
        if (args.value < 30) {
            args.progressColor = '#629337';
        } else if (args.value >= 30 && args.value <= 70) {
            args.progressColor = '#FFBB34';
        } else if (args.value > 70) {
            args.progressColor = '#FF3547';
        }
    }

    changedVacBar(args: IProgressValueEventArgs): void {
        if (args.value < 30) {
            args.progressColor = '#629337';
        } else if (args.value >= 30 && args.value <= 70) {
            args.progressColor = '#FFBB34';
        } else if (args.value > 70) {
            args.progressColor = '#FF3547';
        }
    }

    // Grid Logs
    onHistoryCreated(): void {
        this.disableToolBarBtn();
    }

    search(): void {
        this.gridLogs.search(this.searchtextObj.value);
    }

    setInitialHistoryGridFiltering(): void {
        this.gridLogs.clearFiltering();
        // this.gridLogs.filterByColumn('created_by', 'equal', this.currentUser.id, 'and', false, true);
        // this.viewFilterInstance.value = 'moje žádanky - všechny';
        this.ref.markForCheck();
    }

    loadHistoryData(): DataResult[] {
        console.info('NEW DATA BOUND');

        const elements: VacationHistoryGridItems[] = [];

        if (this.logs.length > 0) {
            // eslint-disable-next-line complexity
            this.logs.map((log: VacationLogs) => {
                const creator = this.users.find(x => x.id === log.created_by);
                const editor = this.users.find(x => x.id === log.updated_by);
                const remover = this.users.find(x => x.id === log.deleted_by);
                const confirmer = this.users.find(x => x.id === log.confirmed_by);
                const decliner = this.users.find(x => x.id === log.declined_by);
                let status = '';

                if (log.confirm && !log.deleted_date) {
                    status = 'confirmed';
                }

                if (log.decline && !log.deleted_date) {
                    status = 'declined';
                }

                if (log.deleted_date) {
                    status = 'deleted';
                }

                if (!log.decline && !log.confirm && !log.deleted_date) {
                    status = 'queue';
                }

                elements.push({
                    id: log.vacations_id,
                    type_id: log.vacations_type,
                    vacation_id: log.id,
                    category_id: log.category_id,
                    created_by: log.created_by,
                    creator_str: creator ? creator.fullname : '',
                    creator_img: creator ? creator.personalphoto : '',
                    updated_by: log.updated_by,
                    editor_str: editor ? editor.fullname : '',
                    editor_img: editor ? editor.personalphoto : '',
                    deleted_by: log.deleted_by,
                    remover_str: remover ? remover.fullname : '',
                    remover_img: remover ? remover.personalphoto : '',
                    confirmed_by: log.confirmed_by,
                    confirmer_str: confirmer ? confirmer.fullname : '',
                    confirmer_img: confirmer ? confirmer.personalphoto : '',
                    declined_by: log.declined_by,
                    decliner_str: decliner ? decliner.fullname : '',
                    decliner_img: decliner ? decliner.personalphoto : '',
                    vacations_type: log.vacations_type,
                    type:
                        log.vacations_type === 1
                            ? 'vytvoření'
                            : log.vacations_type === 2
                                ? 'upravení'
                                : 'smazání',
                    reason:
                        this.currentUser &&
                        (this.permissionService.checkUserISVacationAdmin(this.currentUser) ||
                            this.currentUser.id === log.created_by)
                            ? log.reason
                            : '*****',
                    status,
                    workdays: log.workdays,
                    confirm: log.confirm,
                    decline: log.decline,
                    start_at: log.start_at,
                    end_at: log.end_at,
                    confirm_at: log.confirm_at,
                    decline_at: log.decline_at,
                    created_date: log.created_date,
                    updated_date: log.updated_date,
                    deleted_date: log.deleted_date ? log.deleted_date : null,
                    timestamp: new Date(log.vacations_timestamp),
                });
            });
        }

        this.loadingVacation = false;

        return elements as unknown as DataResult[];
    }

    onHistoryLoad(): void {
        this.route.params.pipe(untilDestroyed(this))
            .subscribe(params => {
                this.vacationService
                    .getLogs(parseInt(params.id as string, 10))
                    .pipe(untilDestroyed(this))
                    .subscribe(
                        (logs: VacationLogs[]) => {
                            // data uživatelů
                            this.dataService.userSource.pipe(untilDestroyed(this))
                                .subscribe(
                                    (data: User[]) => {
                                        this.users = data;
                                        this.logs = logs;
                                        this.dataHistory = this.gridLogs.dataSource =
                                            this.loadHistoryData();

                                        this.sortHistoryOptions = {
                                            columns: [
                                                {field: 'timestamp', direction: 'Descending'},
                                                {field: 'id', direction: 'Descending'},
                                            ],
                                        };
                                        this.ref.markForCheck();
                                    },
                                    error => {
                                        console.error(error);
                                    },
                                );
                        },
                        error => {
                            console.error(error);
                            this.loadingVacation = false;
                            this.ref.markForCheck();
                        },
                    );
            });
    }

    onHistoryDataBound(): void {
        this.rowHistorySelected();
        this.ref.markForCheck();
    }

    rowHistoryDataBound(args: RowDataBoundEventArgs): void {
        const data = args.data as VacationHistoryGridItems;

        if (args.row && data.deleted_date) {
            args.row.getElementsByClassName('e-gridchkbox')[0].classList.add('disablecheckbox');
            args.row
                .getElementsByClassName('e-checkbox-wrapper')[0]
                .classList.add('disablecheckbox');
            args.row.classList.add('e-disabled');
        }

        if (
            this.currentUser &&
            args.row &&
            data.created_by !== this.currentUser.id &&
            !this.permissionService.checkUserISAdministrative(this.currentUser)
        ) {
            args.row.getElementsByClassName('e-gridchkbox')[0].classList.add('disablecheckbox');
            args.row
                .getElementsByClassName('e-checkbox-wrapper')[0]
                .classList.add('disablecheckbox');
        }

        this.ref.markForCheck();
    }

    onStatusFilterChange(e: ChangeEventArgs): void {
        if (e.value === 'všechny změny') {
            this.gridLogs.clearFiltering();
        } else if (e.value === 'poslední den') {
            this.gridLogs.clearFiltering();
            this.gridLogs.filterByColumn(
                'timestamp',
                'greaterthanorequal',
                new Date(moment()
                    .subtract(1, 'days')
                    .format('YYYY-MM-DD HH:mm:ss')),
                'and',
                false,
                true,
            );
        } else if (e.value === 'poslední týden') {
            this.gridLogs.clearFiltering();
            this.gridLogs.filterByColumn(
                'timestamp',
                'greaterthanorequal',
                new Date(moment()
                    .subtract(1, 'weeks')
                    .format('YYYY-MM-DD HH:mm:ss')),
                'and',
                false,
                true,
            );
        } else if (e.value === 'poslední měsíc') {
            this.gridLogs.clearFiltering();
            this.gridLogs.filterByColumn(
                'timestamp',
                'greaterthanorequal',
                new Date(moment()
                    .subtract(1, 'months')
                    .format('YYYY-MM-DD HH:mm:ss')),
                'and',
                false,
                true,
            );
        } else if (e.value === 'poslední rok') {
            this.gridLogs.clearFiltering();
            this.gridLogs.filterByColumn(
                'timestamp',
                'greaterthanorequal',
                new Date(moment()
                    .subtract(1, 'years')
                    .format('YYYY-MM-DD HH:mm:ss')),
                'and',
                false,
                true,
            );
        }
    }

    cancelBtnClick(): void {
        this.searchtextObj.value = '';
        this.gridLogs.searchSettings.key = '';
    }

    toolbarHistoryClick(args: ClickEventArgs): void {
        const xlsProp: ExcelExportProperties = {
            fileName: 'js_vacations_logs_export.xlsx',
            enableFilter: true,
            exportType: this.exportCurrentHistoryPage ? 'CurrentPage' : 'AllPages',
        };

        if (args.item.id === 'vacationLogsGrid_excelexport') {
            void this.gridLogs.excelExport(xlsProp);
        } else if (args.item.id === 'customRefresh') {
            this.loadingVacation = true;
            console.info('refresh DATA!');
            this.dataService.setVacationDetailDataSource(this.vacation);
            this.ref.markForCheck();
        } else if (args.item.id === 'revert') {
            this.loadingVacation = true;
            console.info('revert DATA!');
            this.revertToTimestamp();
            this.dataService.setVacationDetailDataSource(this.vacation);
            this.ref.markForCheck();
        }
    }

    enableToolbarBtn(): void {
        this.gridLogs.toolbarModule.enableItems(['revert'], true);
    }

    disableToolBarBtn(): void {
        this.gridLogs.toolbarModule.enableItems(['revert'], false);
    }

    rowHistorySelected(): void {
        this.clickedRowHistory = [];

        const selectedrecords: VacationLogs[] =
            this.gridLogs.getSelectedRecords() as VacationLogs[];

        if (selectedrecords.length > 0) {
            selectedrecords.map(row => {
                const tempData: VacationLogs | undefined = this.logs.find(
                    x => x.vacations_id === row.id,
                );

                if (tempData) {
                    this.clickedRowHistory.push(tempData);
                }

                this.ref.markForCheck();
            });

            this.clickedRowHistory$ = new Observable<VacationLogs[]>(
                (observer: Observer<VacationLogs[]>) => {
                    observer.next(this.clickedRowHistory);
                },
            );
            this.enableToolbarBtn();
        } else {
            this.disableToolBarBtn();
            this.clickedRowHistory$ = new Observable<VacationLogs[]>(
                (observer: Observer<VacationLogs[]>) => {
                    observer.next(this.clickedRowHistory);
                },
            );
        }
    }

    revertToTimestamp(): void {
        console.info(this.clickedRowHistory[0]);
    }

    // nastavení vyčerpané dovolené
    setStats(userID: number): void {
        const currYear = moment()
            .year();

        this.statsVacation = 0;
        this.statsSickDays = 0;
        this.vacations?.map((vacation: Vacation) => {
            if (
                vacation.created_by === userID &&
                currYear === moment(vacation.start_at)
                    .year() &&
                vacation.confirm &&
                !vacation.deleted_date &&
                !vacation.decline_at &&
                !vacation.decline &&
                vacation.category_id === 1
            ) {
                this.statsVacation = this.statsVacation + vacation.workdays;
            }

            if (
                vacation.created_by === userID &&
                currYear === moment(vacation.start_at)
                    .year() &&
                !vacation.deleted_date &&
                !vacation.decline_at &&
                !vacation.decline &&
                vacation.category_id === 5
            ) {
                this.statsSickDays = this.statsSickDays + vacation.workdays;
            }
        });
        this.ref.markForCheck();
    }

    // eslint-disable-next-line complexity
    getWorkingDays(start: string, end: string): void {
        if (this.holidays.length === 0) {
            this.workhours = 0;
            this.workdays = 0;
            this.ref.markForCheck();

            const body = 'Kontaktujte administrátora...';
            const options = {progressBar: true, timeOut: 5000, toastClass: 'red'};

            this.messages.showError('Státní svátky nelze načíst.', body, options);
        }

        if (this.holidays.length > 0 && start && end) {
            const dates: string[] = [];
            let hours = 0;
            const lastDate: Moment = moment(end);
            const startDate: Moment = moment(start);
            let date: Moment = moment(start);

            // Get number of working hours
            while (lastDate.diff(date) >= 0) {
                if (
                    moment(date, 'YYYY-MM-DD')
                        .isBusinessDay() &&
                    !this.holidays.includes(date.format('YYYY-MM-DD'))
                ) {
                    dates.push(date.format('YYYY-MM-DD'));

                    if (startDate.format('YYYY-MM-DD') !== lastDate.format('YYYY-MM-DD')) {
                        if (startDate.format('YYYY-MM-DD') === date.format('YYYY-MM-DD')) {
                            const todayWorkEnd =
                                moment(date)
                                    .format('YYYY-MM-DD') + ' ' + this.workTimeEnd;
                            const duration = moment.duration(moment(todayWorkEnd)
                                .diff(date));

                            hours +=
                                duration.hours() > 4 ||
                                duration.hours() === 4 && duration.minutes() > 0
                                    ? 8
                                    : 4;
                        } else if (lastDate.format('YYYY-MM-DD') === date.format('YYYY-MM-DD')) {
                            const duration = moment.duration(moment(lastDate)
                                .diff(date));

                            hours +=
                                duration.hours() > 4 ||
                                duration.hours() === 4 && duration.minutes() > 0
                                    ? 8
                                    : 4;
                        } else {
                            hours += 8;
                        }
                    } else {
                        const duration = moment.duration(lastDate.diff(startDate));

                        hours +=
                            duration.hours() > 4 ||
                            duration.hours() === 4 && duration.minutes() > 0
                                ? 8
                                : 4;
                    }
                }

                date = moment(moment(date)
                    .format('YYYY-MM-DD') + ' ' + this.workTimeStart);
                date.add(1, 'days');
            }

            this.workhours = hours;
            this.workdays = hours / this.workTime;
        }

        this.ref.markForCheck();
    }

    actionEditBegin(): void {
        this.isUpdate = true;
        this.isCreate = false;
        this.isCopy = false;
        this.renderVacationFormDialog = true;
    }

    confirmApplication(): void {
        // musí obsahovat data + musí být ve frontě
        // přímý nadřízený zadavatele, nebo administrátor modulu, nebo superadmin
        if (
            this.currentUser &&
            !this.vacation.deleted_date &&
            !this.vacation.confirm &&
            !this.vacation.decline &&
            (this.permissionService.checkUserISVacationAdmin(this.currentUser) ||
                this.vacation.creator.boss_id === this.currentUser.id)
        ) {
            // nastavení dat pro schválenou absenci
            this.vacation.confirm = true;
            this.vacation.confirm_at = this.today;
            this.vacation.confirmed_by = this.currentUser.id;
            this.vacationService
                .confirmApplication(this.vacation)
                .pipe(untilDestroyed(this))
                .subscribe(
                    (vacation: Vacation) => {
                        const body = `žádanka: #${vacation.id}`;
                        const options = {progressBar: true, timeOut: 5000};

                        this.messages.showSuccess('Úspěšně schválená žádanka', body, options);
                        this.dataService.clearVacationsCache();
                        this.dataService.setVacationsDataSource();
                        this.dataService.setVacationDetailDataSource(vacation.id);
                        this.loadingVacation = false;
                        this.confirmationConfirm.hide();
                        this.ref.markForCheck();
                    },
                    error => {
                        console.error(error);

                        const body = 'Zkuste to později ...';
                        const options = {progressBar: true, timeOut: 5000};

                        this.messages.showError('Chyba během schvalování žádanky', body, options);
                        this.loadingVacation = false;
                        this.confirmationConfirm.hide();
                        this.ref.markForCheck();
                    },
                );
        } else {
            const body = 'Nemáte oprávnění provést tuto akci...';
            const options = {progressBar: true, timeOut: 5000};

            this.messages.showError('Nedostatečné oprávnění', body, options);
            this.loadingVacation = false;
            this.confirmationConfirm.hide();
            this.ref.markForCheck();
        }
    }

    stornoApplication(): void {
        // musí obsahovat data + nesmí být již stornovaná
        // zadavatel, přímý nadřízený zadavatele, nebo administrátor modulu, nebo superadmin
        if (
            this.currentUser &&
            (this.vacation.created_by === this.currentUser.id &&
                this.vacation.unixStartTime &&
                this.vacation.unixStartTime >= this.todayUnix &&
                !this.vacation.confirm &&
                !this.vacation.decline ||
                this.currentUser.rule?.is_admin ||
                this.vacation.creator.boss_id === this.currentUser.id) &&
            !this.vacation.deleted_date
        ) {
            // nastavení dat pro storno absence
            this.vacation.deleted_by = this.currentUser.id;
            this.vacationService
                .deleteVacation(this.vacation)
                ?.pipe(untilDestroyed(this))
                .subscribe(
                    (vacation: Vacation) => {
                        const body = `žádanka: #${vacation.id}`;
                        const options = {progressBar: true, timeOut: 5000};

                        this.messages.showSuccess('Úspěšně stornovaná žádanka', body, options);
                        this.dataService.clearVacationsCache();
                        this.dataService.setVacationsDataSource();
                        this.dataService.setVacationDetailDataSource(vacation.id);
                        this.loadingVacation = false;
                        this.confirmationDelete.hide();
                        this.ref.markForCheck();
                    },
                    error => {
                        console.error(error);

                        const body = 'Zkuste to později ...';
                        const options = {progressBar: true, timeOut: 5000};

                        this.messages.showError('Chyba během storna žádanky', body, options);
                        this.loadingVacation = false;
                        this.confirmationDelete.hide();
                        this.ref.markForCheck();
                    },
                );
        } else {
            const body = 'Nemáte oprávnění provést tuto akci...';
            const options = {progressBar: true, timeOut: 5000};

            this.messages.showError('Nedostatečné oprávnění', body, options);
            this.loadingVacation = false;
            this.confirmationDelete.hide();
            this.ref.markForCheck();
        }
    }

    declineApplication(): void {
        // musí obsahovat data + musí být ve frontě
        // přímý nadřízený zadavatele, nebo administrátor modulu, nebo superadmin
        if (
            this.currentUser &&
            (this.permissionService.checkUserISVacationAdmin(this.currentUser) ||
                this.vacation.creator.boss_id === this.currentUser.id) &&
            !this.vacation.deleted_date &&
            !this.vacation.confirm &&
            !this.vacation.decline
        ) {
            // nastavení dta pro zamítnutou absenci
            this.vacation.decline = true;
            this.vacation.decline_at = this.today;
            this.vacation.declined_by = this.currentUser.id;
            this.vacationService
                .declineApplication(this.vacation)
                .pipe(untilDestroyed(this))
                .subscribe(
                    (vacation: Vacation) => {
                        const body = `žádanka: #${vacation.id}`;
                        const options = {
                            progressBar: true,
                            timeOut: 5000,
                            toastClass: 'success',
                        };

                        this.messages.showSuccess('Úspěšně zamítnutá žádanka', body, options);
                        this.dataService.clearVacationsCache();
                        this.dataService.setVacationsDataSource();
                        this.dataService.setVacationDetailDataSource(vacation.id);
                        this.loadingVacation = false;
                        this.confirmationDecline.hide();
                        this.ref.markForCheck();
                    },
                    error => {
                        console.error(error);

                        const body = 'Zkuste to později ...';
                        const options = {
                            progressBar: true,
                            timeOut: 5000,
                            toastClass: 'red',
                        };

                        this.messages.showError('Chyba během zamítnutí žádanky', body, options);
                        this.loadingVacation = false;
                        this.confirmationDecline.hide();
                        this.ref.markForCheck();
                    },
                );
        } else {
            const body = 'Nemáte oprávnění provést tuto akci...';
            const options = {progressBar: true, timeOut: 5000};

            this.messages.showError('Nedostatečné oprávnění', body, options);
            this.loadingVacation = false;
            this.confirmationDecline.hide();
            this.ref.markForCheck();
        }
    }
}
