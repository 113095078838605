import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {Bonuses} from '@src/app/_models/services/bonuses';
import {Discounts} from '@src/app/_models/services/discounts';
import {Places} from '@src/app/_models/services/places';
import {ExtendServices} from '@src/app/_models/services/extend-services';
import {NetPackages} from '@src/app/_models/services/net-packages';
import {TvPackages} from '@src/app/_models/services/tv-packages';
import {TvChannels} from '@src/app/_models/services/tv-channels';
import {TvThemes} from '@src/app/_models/services/tv-themes';
import {Hardware} from '@src/app/_models/services/hardware';
import {SpaceTypes} from '@src/app/_models/services/space-types';
import {UnitTypes} from '@src/app/_models/services/unit-types';
import {SpeedTypes} from '@src/app/_models/services/speed-types';
import {InstallTypes} from '@src/app/_models/services/install-types';
import {DealTypes} from '@src/app/_models/services/deal-types';
import {TransferTypes} from '@src/app/_models/services/transfer-types';
import {Router} from '@angular/router';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {AuthenticationService} from '@src/app/_services/authentication.service';
import {PermissionService} from '@src/app/_services/permission.service';
import {Location} from '@angular/common';
import {DomSanitizer} from '@angular/platform-browser';
import {ApisService} from '@src/app/_api/apis.service';
import {AcsService} from '@src/app/_api/acs/acs.service';
import {MessageService} from '@src/app/_services/message.service';
import {catchError, map, share, shareReplay, tap} from 'rxjs/operators';
import {TechnologyTypes} from '@src/app/_models/services/technology-types';
import {AddressUntrusted} from '@src/app/_models/services/address-untrusted';
import {AddressExcluded} from '@src/app/_models/services/address-excluded';
import {AddressConnectedField} from '@src/app/_models/services/address-connected-field';
import {AddressConnectedRuian} from '@src/app/_models/services/address-connected-ruian';
import {HardwareConfig} from '@src/app/_models/services/hardware-config';
import {AddressConnectedRuianWrapper} from '@src/app/_models/services/address-connected-ruian-wrapper';
import {AddressConnectedFieldWrapper} from '@src/app/_models/services/address-connected-field-wrapper';
import {TvServices} from '@src/app/_models/services/tv-services';
import {PlanningOutages} from '@src/app/_models/services/planning-outages';
import {ClientServicesWrapper} from '@src/app/_models/clients/client-services-wrapper';
import {DataService} from '@src/app/_services/data.service';
import {ClientService} from '@src/app/_models/clients/client-service';
import {Leads} from '@src/app/_models/netbase/leads';
import {Orders} from '@src/app/_models/netbase/orders';
import {ClientsType} from '@src/app/_models/netbase/clients-type';
import {EnvironmentService} from '@src/app/_services/environment.service';

@Injectable({
    providedIn: 'root',
})
export class DataNetbaseService {
    private readonly discountsUrl: string;

    private readonly bonusesUrl: string;

    private readonly extServicesUrl: string;

    private readonly placesUrl: string;

    private readonly netPackagesUrl: string;

    private readonly tvPackagesUrl: string;

    private readonly tvServicesUrl: string;

    private readonly tvChannelsUrl: string;

    private readonly tvThemesUrl: string;

    private readonly hardwaresUrl: string;

    private readonly spaceTypesUrl: string;

    private readonly unitTypesUrl: string;

    private readonly speedTypesUrl: string;

    private readonly transferTypesUrl: string;

    private readonly installationTypesUrl: string;

    private readonly dealTypesUrl: string;

    private readonly technologyTypesUrl: string;

    private readonly ruianAddressUrl: string;

    private readonly ruianSearchAddressUrl: string;

    private readonly fieldAddressUrl: string;

    private readonly fieldSearchAddressUrl: string;

    private readonly untrustedAddressUrl: string;

    private readonly excludedAddressUrl: string;

    private readonly hardwareConfigUrl: string;

    private readonly outagesUrl: string;

    private readonly leadsUrl: string;

    private readonly ordersUrl: string;

    private readonly clientsTypesUrl: string;

    private readonly resultDiscountsSource = new BehaviorSubject<Discounts[] | undefined>([]);

    private readonly resultTvThemesSource = new BehaviorSubject<TvThemes[] | undefined>([]);

    private readonly resultTvChannelsSource = new BehaviorSubject<TvChannels[] | undefined>([]);

    private readonly resultTvPackagesSource = new BehaviorSubject<TvPackages[] | undefined>([]);

    private readonly resultTvServicesSource = new BehaviorSubject<TvServices[] | undefined>([]);

    private readonly resultNetPackagesSource = new BehaviorSubject<NetPackages[] | undefined>([]);

    private readonly resultBonusesSource = new BehaviorSubject<Bonuses[] | undefined>([]);

    private readonly resultExtendServicesSource = new BehaviorSubject<ExtendServices[] | undefined>(
        [],
    );

    private readonly resultPlacesSource = new BehaviorSubject<Places[] | undefined>([]);

    private readonly resultHardwareSource = new BehaviorSubject<Hardware[] | undefined>([]);

    private readonly resultSpaceTypesSource = new BehaviorSubject<SpaceTypes[] | undefined>([]);

    private readonly resultUnitTypesSource = new BehaviorSubject<UnitTypes[] | undefined>([]);

    private readonly resultSpeedTypesSource = new BehaviorSubject<SpeedTypes[] | undefined>([]);

    private readonly resultInstallTypesSource = new BehaviorSubject<InstallTypes[] | undefined>([]);

    private readonly resultDealTypesSource = new BehaviorSubject<DealTypes[] | undefined>([]);

    private readonly resultTransferTypesSource = new BehaviorSubject<TransferTypes[] | undefined>(
        [],
    );

    private readonly resultTechnologyTypesSource = new BehaviorSubject<
        TechnologyTypes[] | undefined
    >([]);

    private readonly resultRuianAddressSource = new BehaviorSubject<
        AddressConnectedRuian[] | undefined
    >([]);

    private readonly resultRuianDetailSource = new BehaviorSubject<
        AddressConnectedRuian | null | undefined
    >(null);

    private readonly resultRuianSearchSource = new BehaviorSubject<
        AddressConnectedRuian | null | undefined
    >(null);

    private readonly resultFieldAddressSource = new BehaviorSubject<
        AddressConnectedField[] | undefined
    >([]);

    private readonly resultFieldDetailSource = new BehaviorSubject<
        AddressConnectedField | null | undefined
    >(null);

    private readonly resultFieldSearchSource = new BehaviorSubject<
        AddressConnectedField[] | undefined
    >([]);

    private readonly resultExcludedAddressSource = new BehaviorSubject<
        AddressExcluded[] | undefined
    >([]);

    private readonly resultUntrustedAddressSource = new BehaviorSubject<
        AddressUntrusted[] | undefined
    >([]);

    private readonly resultHardwareConfigSource = new BehaviorSubject<HardwareConfig[] | undefined>(
        [],
    );

    private readonly resultOutageSource = new BehaviorSubject<PlanningOutages[] | undefined>([]);

    private readonly resultClientAddressServiceSource = new BehaviorSubject<
        ClientService[] | undefined
    >([]);

    private readonly resultLeadSource = new BehaviorSubject<Leads[] | undefined>([]);

    private readonly resultOrderSource = new BehaviorSubject<Orders[] | undefined>([]);

    private readonly resultClientTypesSource = new BehaviorSubject<ClientsType[] | undefined>([]);

    discountSource = this.resultDiscountsSource.asObservable();

    tvThemesSource = this.resultTvThemesSource.asObservable();

    tvChannelsSource = this.resultTvChannelsSource.asObservable();

    tvPackagesSource = this.resultTvPackagesSource.asObservable();

    tvServicesSource = this.resultTvServicesSource.asObservable();

    netPackagesSource = this.resultNetPackagesSource.asObservable();

    bonusesSource = this.resultBonusesSource.asObservable();

    extendServices = this.resultExtendServicesSource.asObservable();

    placesSource = this.resultPlacesSource.asObservable();

    hardwareSource = this.resultHardwareSource.asObservable();

    spaceTypesSource = this.resultSpaceTypesSource.asObservable();

    unitTypesSource = this.resultUnitTypesSource.asObservable();

    speedTypesSource = this.resultSpeedTypesSource.asObservable();

    installTypesSource = this.resultInstallTypesSource.asObservable();

    dealTypesSource = this.resultDealTypesSource.asObservable();

    transferTypesSource = this.resultTransferTypesSource.asObservable();

    technologyTypesSource = this.resultTechnologyTypesSource.asObservable();

    ruianAddressSource = this.resultRuianAddressSource.asObservable();

    ruianDetailSource = this.resultRuianDetailSource.asObservable();

    ruianSearchSource = this.resultRuianSearchSource.asObservable();

    fieldAddressSource = this.resultFieldAddressSource.asObservable();

    fieldDetailSource = this.resultFieldDetailSource.asObservable();

    fieldSearchSource = this.resultFieldSearchSource.asObservable();

    excludedAddressSource = this.resultExcludedAddressSource.asObservable();

    untrustedAddressSource = this.resultUntrustedAddressSource.asObservable();

    hardwareConfigSource = this.resultHardwareConfigSource.asObservable();

    outageSource = this.resultOutageSource.asObservable();

    serviceAddressSource = this.resultClientAddressServiceSource.asObservable();

    leadSource = this.resultLeadSource.asObservable();

    orderSource = this.resultOrderSource.asObservable();

    clientsTypesSource = this.resultClientTypesSource.asObservable();

    constructor(
        private readonly router: Router,
        private readonly http: HttpClient,
        private readonly authenticationService: AuthenticationService,
        private readonly permissionsService: PermissionService,
        private readonly dataService: DataService,
        private readonly location: Location,
        private readonly sanitizer: DomSanitizer,
        private readonly apisService: ApisService,
        private readonly acsService: AcsService,
        private readonly messageService: MessageService,
        private readonly environmentService: EnvironmentService,
    ) {
        this.discountsUrl = `${this.environmentService.backendURL}/api/netbase/discount`;
        this.bonusesUrl = `${this.environmentService.backendURL}/api/netbase/bonuses`;
        this.extServicesUrl = `${this.environmentService.backendURL}/api/netbase/service/extends`;
        this.placesUrl = `${this.environmentService.backendURL}/api/netbase/places`;
        this.netPackagesUrl = `${this.environmentService.backendURL}/api/netbase/netpackage`;
        this.tvPackagesUrl = `${this.environmentService.backendURL}/api/netbase/tvpackage`;
        this.tvServicesUrl = `${this.environmentService.backendURL}/api/netbase/service/tv`;
        this.tvChannelsUrl = `${this.environmentService.backendURL}/api/netbase/channel`;
        this.tvThemesUrl = `${this.environmentService.backendURL}/api/netbase/theme`;
        this.hardwaresUrl = `${this.environmentService.backendURL}/api/netbase/hardware`;
        this.spaceTypesUrl = `${this.environmentService.backendURL}/api/netbase/type/space`;
        this.unitTypesUrl = `${this.environmentService.backendURL}/api/netbase/type/unit`;
        this.speedTypesUrl = `${this.environmentService.backendURL}/api/netbase/type/speed`;
        this.transferTypesUrl = `${this.environmentService.backendURL}/api/netbase/type/transfer`;
        this.installationTypesUrl = `${this.environmentService.backendURL}/api/netbase/type/install`;
        this.dealTypesUrl = `${this.environmentService.backendURL}/api/netbase/type/deal`;
        this.technologyTypesUrl = `${this.environmentService.backendURL}/api/netbase/type/technology`;
        this.ruianAddressUrl = `${this.environmentService.backendURL}/api/netbase/ruian`;
        this.ruianSearchAddressUrl = `${this.environmentService.backendURL}/api/netbase/ruian/search/ruian`;
        this.fieldAddressUrl = `${this.environmentService.backendURL}/api/netbase/field`;
        this.fieldSearchAddressUrl = `${this.environmentService.backendURL}/api/netbase/field/search`;
        this.untrustedAddressUrl = `${this.environmentService.backendURL}/api/netbase/address-untrusted`;
        this.excludedAddressUrl = `${this.environmentService.backendURL}/api/netbase/address-excluded`;
        this.hardwareConfigUrl = `${this.environmentService.backendURL}/api/netbase/hardware/configuration`;
        this.outagesUrl = `${this.environmentService.backendURL}/api/netbase/outage`;
        this.leadsUrl = `${this.environmentService.backendURL}/api/netbase/lead`;
        this.ordersUrl = `${this.environmentService.backendURL}/api/netbase/order`;
        this.clientsTypesUrl = `${this.environmentService.backendURL}/api/sync/type/clients`;
    }

    /**
     * Handle Http operation that failed.
     * Let the app continue.
     * @param operation - name of the operation that failed
     * @param result - optional value to return as the observable result
     */
    private handleError<T>(operation = 'operation', result?: T) {
        return (error: {[key: string]: string}): Observable<T | undefined> => {
            // TODO: send the error to remote logging infrastructure
            console.error(error); // log to console instead

            // TODO: better job of transforming error for user consumption
            this.log(`${operation} failed: ${error.message}`);

            // Let the app keep running by returning an empty result.
            return of(result);
        };
    }

    /** Log a ticketService message with the MessageService */
    private log(message: string): void {
        this.messageService.addNotification(`Ticket: ${message}`, false);
    }

    getDiscountsData(): Observable<Discounts[] | undefined> {
        return this.http.get<Discounts[]>(this.discountsUrl)
            .pipe(
                tap(() => {
                    this.log('fetched materials');
                }),
                shareReplay(),
                catchError(this.handleError<Discounts[]>('getQueue', [])),
            );
    }

    getDiscountData(discount: Discounts | number): Observable<Discounts | undefined> {
        const id = typeof discount === 'number' ? discount : discount.id;
        const url = `${this.discountsUrl}/search/${id}`;

        return this.http.get<Discounts>(url)
            .pipe(
                tap(() => {
                    this.log(`fetched material id=${id}`);
                }),
                share(),
                catchError(this.handleError<Discounts>(`getMaterial id=${id}`)),
            );
    }

    setDiscountsDataSource(): void {
        if (
            !this.resultDiscountsSource.getValue() ||
            this.resultDiscountsSource.getValue()?.length === 0
        ) {
            this.getDiscountsData()
                .subscribe((discounts: Discounts[]) => {
                    this.resultDiscountsSource.next(discounts);
                });
        }
    }

    getBonusesData(): Observable<Bonuses[] | undefined> {
        return this.http.get<Bonuses[]>(this.bonusesUrl)
            .pipe(
                tap(() => {
                    this.log('fetched materials');
                }),
                shareReplay(),
                catchError(this.handleError<Bonuses[]>('getQueue', [])),
            );
    }

    getBonusData(bonus: Bonuses | number): Observable<Bonuses | undefined> {
        const id = typeof bonus === 'number' ? bonus : bonus.id;
        const url = `${this.bonusesUrl}/search/${id}`;

        return this.http.get<Bonuses>(url)
            .pipe(
                tap(() => {
                    this.log(`fetched material id=${id}`);
                }),
                share(),
                catchError(this.handleError<Bonuses>(`getMaterial id=${id}`)),
            );
    }

    setBonusesDataSource(): void {
        if (
            !this.resultBonusesSource.getValue() ||
            this.resultBonusesSource.getValue()?.length === 0
        ) {
            this.getBonusesData()
                .subscribe((bonuses: Bonuses[]) => {
                    this.resultBonusesSource.next(bonuses);
                });
        }
    }

    getPlacesData(): Observable<Places[] | undefined> {
        return this.http.get<Places[]>(this.placesUrl)
            .pipe(
                tap(() => {
                    this.log('fetched places');
                }),
                shareReplay(),
                catchError(this.handleError<Places[]>('getQueue', [])),
            );
    }

    getPlaceData(place: Places | number): Observable<Places | undefined> {
        const id = typeof place === 'number' ? place : place.id;
        const url = `${this.placesUrl}/search/${id}`;

        return this.http.get<Places>(url)
            .pipe(
                tap(() => {
                    this.log(`fetched place id=${id}`);
                }),
                share(),
                catchError(this.handleError<Places>(`getPlace id=${id}`)),
            );
    }

    setPlacesDataSource(): void {
        if (
            !this.resultPlacesSource.getValue() ||
            this.resultPlacesSource.getValue()?.length === 0
        ) {
            this.getPlacesData()
                .subscribe((places: Places[]) => {
                    this.resultPlacesSource.next(places);
                });
        }
    }

    getExtendedServicesData(): Observable<ExtendServices[] | undefined> {
        return this.http.get<ExtendServices[]>(this.extServicesUrl)
            .pipe(
                tap(() => {
                    this.log('fetched extended services');
                }),
                shareReplay(),
                catchError(this.handleError<ExtendServices[]>('getQueue', [])),
            );
    }

    getExtendedServiceData(
        extService: ExtendServices | number,
    ): Observable<ExtendServices | undefined> {
        const id = typeof extService === 'number' ? extService : extService.id;
        const url = `${this.extServicesUrl}/search/${id}`;

        return this.http.get<ExtendServices>(url)
            .pipe(
                tap(() => {
                    this.log(`fetched extended service id=${id}`);
                }),
                share(),
                catchError(this.handleError<ExtendServices>(`getExtendedService id=${id}`)),
            );
    }

    setExtendedServicesDataSource(): void {
        if (
            !this.resultExtendServicesSource.getValue() ||
            this.resultExtendServicesSource.getValue()?.length === 0
        ) {
            this.getExtendedServicesData()
                .subscribe((extServices: ExtendServices[]) => {
                    this.resultExtendServicesSource.next(extServices);
                });
        }
    }

    getNetPackagesData(): Observable<NetPackages[] | undefined> {
        return this.http.get<NetPackages[]>(this.netPackagesUrl)
            .pipe(
                tap(() => {
                    this.log('fetched internet tarifs');
                }),
                shareReplay(),
                catchError(this.handleError<NetPackages[]>('getQueue', [])),
            );
    }

    getNetPackageData(tarif: NetPackages | number): Observable<NetPackages | undefined> {
        const id = typeof tarif === 'number' ? tarif : tarif.id;
        const url = `${this.netPackagesUrl}/search/${id}`;

        return this.http.get<NetPackages>(url)
            .pipe(
                tap(() => {
                    this.log(`fetched internet tarif id=${id}`);
                }),
                share(),
                catchError(this.handleError<NetPackages>(`getNetPackage id=${id}`)),
            );
    }

    setNetPackagesDataSource(): void {
        if (
            !this.resultNetPackagesSource.getValue() ||
            this.resultNetPackagesSource.getValue()?.length === 0
        ) {
            this.getNetPackagesData()
                .subscribe((tarifs: NetPackages[]) => {
                    this.resultNetPackagesSource.next(tarifs);
                });
        }
    }

    getTvServicesData(): Observable<TvServices[] | undefined> {
        return this.http.get<TvServices[]>(this.tvServicesUrl)
            .pipe(
                tap(() => {
                    this.log('fetched tv services');
                }),
                shareReplay(),
                catchError(this.handleError<TvServices[]>('getQueue', [])),
            );
    }

    getTvServiceData(tarif: TvServices | number): Observable<TvServices | undefined> {
        const id = typeof tarif === 'number' ? tarif : tarif.id;
        const url = `${this.tvServicesUrl}/search/${id}`;

        return this.http.get<TvServices>(url)
            .pipe(
                tap(() => {
                    this.log(`fetched tv service id=${id}`);
                }),
                share(),
                catchError(this.handleError<TvServices>(`getTvService id=${id}`)),
            );
    }

    setTvServicesDataSource(): void {
        if (
            !this.resultTvServicesSource.getValue() ||
            this.resultTvServicesSource.getValue()?.length === 0
        ) {
            this.getTvServicesData()
                .subscribe((services: TvServices[]) => {
                    this.resultTvServicesSource.next(services);
                });
        }
    }

    getTvPackagesData(): Observable<TvPackages[] | undefined> {
        return this.http.get<TvPackages[]>(this.tvPackagesUrl)
            .pipe(
                tap(() => {
                    this.log('fetched extended services');
                }),
                shareReplay(),
                catchError(this.handleError<TvPackages[]>('getQueue', [])),
            );
    }

    getTvPackageData(tarif: TvPackages | number): Observable<TvPackages | undefined> {
        const id = typeof tarif === 'number' ? tarif : tarif.id;
        const url = `${this.tvPackagesUrl}/search/${id}`;

        return this.http.get<TvPackages>(url)
            .pipe(
                tap(() => {
                    this.log(`fetched extended service id=${id}`);
                }),
                share(),
                catchError(this.handleError<TvPackages>(`getExtendedService id=${id}`)),
            );
    }

    setTvPackagesDataSource(): void {
        if (
            !this.resultTvPackagesSource.getValue() ||
            this.resultTvPackagesSource.getValue()?.length === 0
        ) {
            this.getTvPackagesData()
                .subscribe((tarifs: TvPackages[]) => {
                    this.resultTvPackagesSource.next(tarifs);
                });
        }
    }

    getTvThemesData(): Observable<TvThemes[] | undefined> {
        return this.http.get<TvThemes[]>(this.tvThemesUrl)
            .pipe(
                tap(() => {
                    this.log('fetched themes');
                }),
                shareReplay(),
                catchError(this.handleError<TvThemes[]>('getQueue', [])),
            );
    }

    getTvThemeData(place: TvThemes | number): Observable<TvThemes | undefined> {
        const id = typeof place === 'number' ? place : place.id;
        const url = `${this.tvThemesUrl}/search/${id}`;

        return this.http.get<TvThemes>(url)
            .pipe(
                tap(() => {
                    this.log(`fetched theme id=${id}`);
                }),
                share(),
                catchError(this.handleError<TvThemes>(`getTheme id=${id}`)),
            );
    }

    setTvThemesDataSource(): void {
        if (
            !this.resultTvThemesSource.getValue() ||
            this.resultTvThemesSource.getValue()?.length === 0
        ) {
            this.getTvThemesData()
                .subscribe((themes: TvThemes[]) => {
                    this.resultTvThemesSource.next(themes);
                });
        }
    }

    getTvChannelsData(): Observable<TvChannels[] | undefined> {
        return this.http.get<TvChannels[]>(this.tvChannelsUrl)
            .pipe(
                tap(() => {
                    this.log('fetched channels');
                }),
                shareReplay(),
                catchError(this.handleError<TvChannels[]>('getQueue', [])),
            );
    }

    getTvChannelData(channel: TvChannels | number): Observable<TvChannels | undefined> {
        const id = typeof channel === 'number' ? channel : channel.id;
        const url = `${this.tvChannelsUrl}/search/${id}`;

        return this.http.get<TvChannels>(url)
            .pipe(
                tap(() => {
                    this.log(`fetched channel id=${id}`);
                }),
                share(),
                catchError(this.handleError<TvChannels>(`getChannel id=${id}`)),
            );
    }

    setTvChannelsDataSource(): void {
        if (
            !this.resultTvChannelsSource.getValue() ||
            this.resultTvChannelsSource.getValue()?.length === 0
        ) {
            this.getTvChannelsData()
                .subscribe((channels: TvChannels[]) => {
                    this.resultTvChannelsSource.next(channels);
                });
        }
    }

    getHardwaresData(): Observable<Hardware[] | undefined> {
        return this.http.get<Hardware[]>(this.hardwaresUrl)
            .pipe(
                tap(() => {
                    this.log('fetched hardwares');
                }),
                shareReplay(),
                catchError(this.handleError<Hardware[]>('getQueue', [])),
            );
    }

    getHardwareData(hardware: Hardware | number): Observable<Hardware | undefined> {
        const id = typeof hardware === 'number' ? hardware : hardware.id;
        const url = `${this.hardwaresUrl}/search/${id}`;

        return this.http.get<Hardware>(url)
            .pipe(
                tap(() => {
                    this.log(`fetched hardware id=${id}`);
                }),
                share(),
                catchError(this.handleError<Hardware>(`getHardware id=${id}`)),
            );
    }

    setHardwareDataSource(): void {
        if (
            !this.resultHardwareSource.getValue() ||
            this.resultHardwareSource.getValue()?.length === 0
        ) {
            this.getHardwaresData()
                .subscribe((hardwares: Hardware[]) => {
                    this.resultHardwareSource.next(
                        typeof hardwares !== 'undefined' && hardwares.length > 0 ? hardwares : [],
                    );
                });
        }
    }

    // eslint-disable-next-line max-params-no-constructor/max-params-no-constructor,complexity
    getOutagesData(
        address?: string,
        ruian?: number | null | undefined,
        field?: number | null | undefined,
        street?: number | null | undefined,
        city?: number | null | undefined,
        part?: number | null | undefined,
        district?: number | null | undefined,
        region?: number | null | undefined,
        until?: string | undefined,
        to?: string | undefined,
    ): Observable<PlanningOutages[] | undefined> {
        const headers = new HttpHeaders({'Content-Type': 'application/json'});
        const params = new HttpParams()
            .set('address', typeof address !== 'undefined' ? address.toString() : 'null')
            .set('ruian', ruian ? ruian.toString() : 'null')
            .set('field', field ? field.toString() : 'null')
            .set('street', street && typeof street !== 'undefined' ? street.toString() : 'null')
            .set('city', city && typeof city !== 'undefined' ? city.toString() : 'null')
            .set('part', part && typeof part !== 'undefined' ? part.toString() : 'null')
            .set(
                'district',
                district && typeof district !== 'undefined' ? district.toString() : 'null',
            )
            .set('region', region && typeof region !== 'undefined' ? region.toString() : 'null')
            .set('until', typeof until !== 'undefined' ? until.toString() : 'null')
            .set('to', typeof to !== 'undefined' ? to.toString() : 'null');

        return this.http.get<PlanningOutages[]>(this.outagesUrl, {headers, params})
            .pipe(
                tap(() => {
                    this.log('fetched hardwares');
                }),
                shareReplay(),
                catchError(this.handleError<PlanningOutages[]>('getQueue', [])),
            );
    }

    getOutageData(outage: PlanningOutages | number): Observable<PlanningOutages | undefined> {
        const id = typeof outage === 'number' ? outage : outage.id;
        const url = `${this.outagesUrl}/search/${id}`;

        return this.http.get<PlanningOutages>(url)
            .pipe(
                tap(() => {
                    this.log(`fetched hardware id=${id}`);
                }),
                share(),
                catchError(this.handleError<PlanningOutages>(`getHardware id=${id}`)),
            );
    }

    // eslint-disable-next-line max-params-no-constructor/max-params-no-constructor
    setOutageDataSource(
        find: boolean,
        address?: string | null,
        ruian?: number | null | undefined,
        field?: number | null | undefined,
        street?: number | null | undefined,
        city?: number | null | undefined,
        part?: number | null | undefined,
        district?: number | null | undefined,
        region?: number,
        until?: string,
        to?: string,
    ): void {
        if (find) {
            this.clearOutagesCache();
        }

        if (
            !this.resultOutageSource.getValue() ||
            this.resultOutageSource.getValue()?.length === 0
        ) {
            if (!find) {
                this.getOutagesData()
                    .subscribe((outages: PlanningOutages[]) => {
                        this.resultOutageSource.next(outages);
                    });
            } else {
                this.getOutagesData(
                    address ? address.toString() : 'null',
                    ruian,
                    field,
                    street,
                    city,
                    part,
                    district,
                    region,
                    until,
                    to,
                )
                    .subscribe((outages: PlanningOutages[]) => {
                        this.resultOutageSource.next(outages);
                    });
            }
        }
    }

    getSpaceTypesData(): Observable<SpaceTypes[] | undefined> {
        return this.http.get<SpaceTypes[]>(this.spaceTypesUrl)
            .pipe(
                tap(() => {
                    this.log('fetched space types');
                }),
                shareReplay(),
                catchError(this.handleError<SpaceTypes[]>('getQueue', [])),
            );
    }

    getSpaceTypeData(hardware: SpaceTypes | number): Observable<SpaceTypes | undefined> {
        const id = typeof hardware === 'number' ? hardware : hardware.id;
        const url = `${this.spaceTypesUrl}/search/${id}`;

        return this.http.get<SpaceTypes>(url)
            .pipe(
                tap(() => {
                    this.log(`fetched space type id=${id}`);
                }),
                share(),
                catchError(this.handleError<SpaceTypes>(`getSpaceType id=${id}`)),
            );
    }

    setSpaceTypesDataSource(): void {
        if (
            !this.resultSpaceTypesSource.getValue() ||
            this.resultSpaceTypesSource.getValue()?.length === 0
        ) {
            this.getSpaceTypesData()
                .subscribe((spaces: SpaceTypes[]) => {
                    this.resultSpaceTypesSource.next(spaces);
                });
        }
    }

    getUnitTypesData(): Observable<UnitTypes[] | undefined> {
        return this.http.get<UnitTypes[]>(this.unitTypesUrl)
            .pipe(
                tap(() => {
                    this.log('fetched unit types');
                }),
                shareReplay(),
                catchError(this.handleError<UnitTypes[]>('getQueue', [])),
            );
    }

    getUnitTypeData(unit: UnitTypes | number): Observable<UnitTypes | undefined> {
        const id = typeof unit === 'number' ? unit : unit.id;
        const url = `${this.unitTypesUrl}/search/${id}`;

        return this.http.get<UnitTypes>(url)
            .pipe(
                tap(() => {
                    this.log(`fetched unit type id=${id}`);
                }),
                share(),
                catchError(this.handleError<UnitTypes>(`getUnitType id=${id}`)),
            );
    }

    setUnitTypesDataSource(): void {
        if (
            !this.resultUnitTypesSource.getValue() ||
            this.resultUnitTypesSource.getValue()?.length === 0
        ) {
            this.getUnitTypesData()
                .subscribe((units: UnitTypes[]) => {
                    this.resultUnitTypesSource.next(units);
                });
        }
    }

    getTechnologyTypesData(): Observable<TechnologyTypes[] | undefined> {
        return this.http.get<TechnologyTypes[]>(this.technologyTypesUrl)
            .pipe(
                tap(() => {
                    this.log('fetched technology types');
                }),
                shareReplay(),
                catchError(this.handleError<TechnologyTypes[]>('getQueue', [])),
            );
    }

    getTechnologyTypeData(
        technology: TechnologyTypes | number,
    ): Observable<TechnologyTypes | undefined> {
        const id = typeof technology === 'number' ? technology : technology.id;
        const url = `${this.technologyTypesUrl}/search/${id}`;

        return this.http.get<TechnologyTypes>(url)
            .pipe(
                tap(() => {
                    this.log(`fetched technology type id=${id}`);
                }),
                share(),
                catchError(this.handleError<TechnologyTypes>(`getTechnologyType id=${id}`)),
            );
    }

    setTechnologyTypesDataSource(): void {
        if (
            !this.resultTechnologyTypesSource.getValue() ||
            this.resultTechnologyTypesSource.getValue()?.length === 0
        ) {
            this.getTechnologyTypesData()
                .subscribe((technologies: TechnologyTypes[]) => {
                    this.resultTechnologyTypesSource.next(technologies);
                });
        }
    }

    getTransferTypesData(): Observable<TransferTypes[] | undefined> {
        return this.http.get<TransferTypes[]>(this.transferTypesUrl)
            .pipe(
                tap(() => {
                    this.log('fetched transfer types');
                }),
                shareReplay(),
                catchError(this.handleError<TransferTypes[]>('getQueue', [])),
            );
    }

    getTransferTypeData(transfer: TransferTypes | number): Observable<TransferTypes | undefined> {
        const id = typeof transfer === 'number' ? transfer : transfer.id;
        const url = `${this.transferTypesUrl}/search/${id}`;

        return this.http.get<TransferTypes>(url)
            .pipe(
                tap(() => {
                    this.log(`fetched transfer type id=${id}`);
                }),
                share(),
                catchError(this.handleError<TransferTypes>(`getTransferType id=${id}`)),
            );
    }

    setTransferTypesDataSource(): void {
        if (
            !this.resultTransferTypesSource.getValue() ||
            this.resultTransferTypesSource.getValue()?.length === 0
        ) {
            this.getTransferTypesData()
                .subscribe((transfers: TransferTypes[]) => {
                    this.resultTransferTypesSource.next(transfers);
                });
        }
    }

    getSpeedTypesData(): Observable<SpeedTypes[] | undefined> {
        return this.http.get<SpeedTypes[]>(this.speedTypesUrl)
            .pipe(
                tap(() => {
                    this.log('fetched transfer types');
                }),
                shareReplay(),
                catchError(this.handleError<SpeedTypes[]>('getQueue', [])),
            );
    }

    getSpeedTypeData(transfer: SpeedTypes | number): Observable<SpeedTypes | undefined> {
        const id = typeof transfer === 'number' ? transfer : transfer.id;
        const url = `${this.speedTypesUrl}/search/${id}`;

        return this.http.get<SpeedTypes>(url)
            .pipe(
                tap(() => {
                    this.log(`fetched speed type id=${id}`);
                }),
                share(),
                catchError(this.handleError<SpeedTypes>(`getSpeedType id=${id}`)),
            );
    }

    setSpeedTypesDataSource(): void {
        if (
            !this.resultSpeedTypesSource.getValue() ||
            this.resultSpeedTypesSource.getValue()?.length === 0
        ) {
            this.getSpeedTypesData()
                .subscribe((speeds: SpeedTypes[]) => {
                    this.resultSpeedTypesSource.next(speeds);
                });
        }
    }

    getInstallationTypesData(): Observable<InstallTypes[] | undefined> {
        return this.http.get<InstallTypes[]>(this.installationTypesUrl)
            .pipe(
                tap(() => {
                    this.log('fetched installation types');
                }),
                shareReplay(),
                catchError(this.handleError<InstallTypes[]>('getQueue', [])),
            );
    }

    getInstallationTypeData(
        installation: InstallTypes | number,
    ): Observable<InstallTypes | undefined> {
        const id = typeof installation === 'number' ? installation : installation.id;
        const url = `${this.installationTypesUrl}/search/${id}`;

        return this.http.get<InstallTypes>(url)
            .pipe(
                tap(() => {
                    this.log(`fetched installation type id=${id}`);
                }),
                share(),
                catchError(this.handleError<InstallTypes>(`getInstallationType id=${id}`)),
            );
    }

    setInstallationTypesDataSource(): void {
        if (
            !this.resultInstallTypesSource.getValue() ||
            this.resultInstallTypesSource.getValue()?.length === 0
        ) {
            this.getInstallationTypesData()
                .subscribe((installations: InstallTypes[]) => {
                    this.resultInstallTypesSource.next(installations);
                });
        }
    }

    getDealTypesData(): Observable<DealTypes[] | undefined> {
        return this.http.get<DealTypes[]>(this.dealTypesUrl)
            .pipe(
                tap(() => {
                    this.log('fetched deal types');
                }),
                shareReplay(),
                catchError(this.handleError<DealTypes[]>('getQueue', [])),
            );
    }

    getDealTypeData(deal: DealTypes | number): Observable<DealTypes | undefined> {
        const id = typeof deal === 'number' ? deal : deal.id;
        const url = `${this.dealTypesUrl}/search/${id}`;

        return this.http.get<DealTypes>(url)
            .pipe(
                tap(() => {
                    this.log(`fetched deal type id=${id}`);
                }),
                share(),
                catchError(this.handleError<DealTypes>(`getDealType id=${id}`)),
            );
    }

    setDealTypesDataSource(): void {
        if (
            !this.resultDealTypesSource.getValue() ||
            this.resultDealTypesSource.getValue()?.length === 0
        ) {
            this.getDealTypesData()
                .subscribe((deals: DealTypes[]) => {
                    this.resultDealTypesSource.next(deals);
                });
        }
    }

    getRuianAddressesData(
        all: boolean,
        page?: number,
        size?: number,
    ): Observable<AddressConnectedRuianWrapper | undefined> {
        const headers = new HttpHeaders({'Content-Type': 'application/json'});
        const params = new HttpParams()
            .set('page', page ? page.toString() : '0')
            .set('size', size ? size.toString() : '0')
            .set('all', all.toString());

        return this.http
            .get<AddressConnectedRuianWrapper>(this.ruianAddressUrl, {
                headers,
                params,
            })
            .pipe(
                tap(() => {
                    this.log('fetched ruian addresses');
                }),
                shareReplay(),
                catchError(this.handleError<AddressConnectedRuianWrapper>('getQueue')),
            );
    }

    getRuianAddressData(
        ruian: AddressConnectedRuian | number,
    ): Observable<AddressConnectedRuian | undefined> {
        const id = typeof ruian === 'number' ? ruian : ruian.id;
        const url = `${this.ruianAddressUrl}/search/${id}`;

        return this.http.get<AddressConnectedRuian>(url)
            .pipe(
                map((address: AddressConnectedRuian) => {
                    address.unixCreatedTime = Date.parse(address.created_date);
                    address.unixUpdatedTime = address.updated_date
                        ? Date.parse(address.updated_date)
                        : null;

                    return address;
                }),
                tap(() => {
                    this.log(`fetched ruian address id=${id}`);
                }),
                shareReplay(),
                catchError(this.handleError<AddressConnectedRuian>(`getRuianAddress id=${id}`)),
            );
    }

    setClientAddressServicesSource(address: string | null, ruian?: number): void {
        this.dataService
            .getServicesData(true, 0, 0, ruian)
            .subscribe((services: ClientServicesWrapper) => {
                this.resultClientAddressServiceSource.next(services.data);
            });
    }

    searchAddressData(address: string, ruian?: number | null): void {
        if (ruian) {
            const url = `${this.ruianSearchAddressUrl}/${ruian}`;

            this.http
                .get<AddressConnectedRuian>(url)
                .pipe(
                    tap(() => {
                        this.log(`fetched ruian address ruian=${ruian}`);
                    }),
                    share(),
                    catchError(
                        this.handleError<AddressConnectedRuian>(`searchRuianAddress ruian=${ruian}`),
                    ),
                )
                .subscribe((result: AddressConnectedRuian) => {
                    this.resultRuianSearchSource.next(result);
                });
        } else {
            this.searchFieldAddressData(address);
        }
    }

    searchRuianData(ruian: number): Observable<AddressConnectedRuian | undefined> {
        const url = `${this.ruianSearchAddressUrl}/${ruian}`;

        return this.http.get<AddressConnectedRuian>(url)
            .pipe(
                tap(() => {
                    this.log(`fetched ruian address ruian=${ruian}`);
                }),
                share(),
                catchError(this.handleError<AddressConnectedRuian>(`searchRuianAddress ruian=${ruian}`)),
            );
    }

    searchFieldData(
        address?: string | null,
        id?: number,
    ): Observable<AddressConnectedField[] | undefined> {
        const headers = new HttpHeaders({'Content-Type': 'application/json'});
        const params = new HttpParams()
            .set('address', address ? address.toString() : 'null')
            .set('id', id ? id.toString() : 'null');

        const url = `${this.fieldSearchAddressUrl}`;

        return this.http.get<AddressConnectedField[]>(url, {headers, params})
            .pipe(
                tap(() => {
                    this.log(`fetched field address=${address ?? ''}, id=${id ?? ''}`);
                }),
                share(),
                catchError(
                    this.handleError<AddressConnectedField[]>(
                        `searchFieldAddress address=${address ?? ''}, id=${id ?? ''}`,
                    ),
                ),
            );
    }

    // eslint-disable-next-line max-params-no-constructor/max-params-no-constructor
    searchOutageDataSource(
        find: boolean,
        address?: string | null,
        ruian?: number | null,
        field?: number | null,
        street?: number | null,
        city?: number | null,
        part?: number | null,
        district?: number | null,
        region?: number | null,
        until?: string,
        to?: string,
    ): Observable<PlanningOutages[] | undefined> {
        return this.getOutagesData(
            typeof address !== 'undefined' ? address?.toString() : 'null',
            ruian,
            field,
            street,
            city,
            part,
            district,
            region,
            until,
            to,
        );
    }

    setRuianDetailSource(ruian: AddressConnectedRuian | number): void {
        const id = typeof ruian === 'number' ? ruian : ruian.id;

        this.getRuianAddressData(id)
            .subscribe((data: AddressConnectedRuian) => {
                this.resultRuianDetailSource.next(data);
            });
    }

    setRuianAddressDataSource(): void {
        if (
            !this.resultRuianAddressSource.getValue() ||
            this.resultRuianAddressSource.getValue()?.length === 0
        ) {
            this.getRuianAddressesData(true)
                .subscribe((ruians: AddressConnectedRuianWrapper) => {
                    this.resultRuianAddressSource.next(ruians.data);
                });
        }
    }

    getFieldAddressesData(
        all: boolean,
        page?: number,
        size?: number,
    ): Observable<AddressConnectedFieldWrapper | undefined> {
        const headers = new HttpHeaders({'Content-Type': 'application/json'});
        const params = new HttpParams()
            .set('page', page ? page.toString() : '0')
            .set('size', size ? size.toString() : '0')
            .set('all', all.toString());

        return this.http
            .get<AddressConnectedFieldWrapper>(this.fieldAddressUrl, {
                headers,
                params,
            })
            .pipe(
                tap(() => {
                    this.log('fetched field addresses');
                }),
                shareReplay(),
                catchError(this.handleError<AddressConnectedFieldWrapper>('getQueue')),
            );
    }

    getFieldAddressData(
        field: AddressConnectedField | number,
    ): Observable<AddressConnectedField | undefined> {
        const id = typeof field === 'number' ? field : field.id;
        const url = `${this.fieldAddressUrl}/search/${id}`;

        return this.http.get<AddressConnectedField>(url)
            .pipe(
                map((address: AddressConnectedField) => {
                    address.unixCreatedTime = Date.parse(address.created_date);
                    address.unixUpdatedTime = address.updated_date
                        ? Date.parse(address.updated_date)
                        : null;

                    return address;
                }),
                tap(() => {
                    this.log(`fetched field address id=${id}`);
                }),
                share(),
                catchError(this.handleError<AddressConnectedField>(`getFieldAddress id=${id}`)),
            );
    }

    searchFieldAddressData(address: string): void {
        const url = `${this.fieldSearchAddressUrl}/${address}`;

        this.http
            .get<AddressConnectedField[]>(url)
            .pipe(
                tap(() => {
                    this.log(`fetched field address=${address}`);
                }),
                share(),
                catchError(
                    this.handleError<AddressConnectedField>(`searchFieldAddress address=${address}`),
                ),
            )
            .subscribe((result: AddressConnectedField[]) => {
                this.resultFieldSearchSource.next(result);
            });
    }

    setFieldDetailSource(field: AddressConnectedField | number): void {
        const id = typeof field === 'number' ? field : field.id;

        this.getFieldAddressData(id)
            .subscribe((data: AddressConnectedField) => {
                this.resultFieldDetailSource.next(data);
            });
    }

    setFieldAddressDataSource(): void {
        if (
            !this.resultFieldAddressSource.getValue() ||
            this.resultFieldAddressSource.getValue()?.length === 0
        ) {
            this.getFieldAddressesData(true)
                .subscribe((fields: AddressConnectedFieldWrapper) => {
                    this.resultFieldAddressSource.next(fields.data);
                });
        }
    }

    getExcludedAddressesData(): Observable<AddressExcluded[] | undefined> {
        return this.http.get<AddressExcluded[]>(this.excludedAddressUrl)
            .pipe(
                tap(() => {
                    this.log('fetched excluded address');
                }),
                shareReplay(),
                catchError(this.handleError<AddressExcluded[]>('getQueue', [])),
            );
    }

    getExcludedAddressData(
        ruian: AddressExcluded | number,
    ): Observable<AddressExcluded | undefined> {
        const id = typeof ruian === 'number' ? ruian : ruian.id;
        const url = `${this.excludedAddressUrl}/search/${id}`;

        return this.http.get<AddressExcluded>(url)
            .pipe(
                tap(() => {
                    this.log(`fetched excluded address id=${id}`);
                }),
                share(),
                catchError(this.handleError<AddressExcluded>(`getExcludedAddress id=${id}`)),
            );
    }

    setExcludedAddressDataSource(): void {
        if (
            !this.resultExcludedAddressSource.getValue() ||
            this.resultExcludedAddressSource.getValue()?.length === 0
        ) {
            this.getExcludedAddressesData()
                .subscribe((addresses: AddressExcluded[]) => {
                    this.resultExcludedAddressSource.next(addresses);
                });
        }
    }

    getUntrustedAddressesData(): Observable<AddressUntrusted[] | undefined> {
        return this.http.get<AddressUntrusted[]>(this.untrustedAddressUrl)
            .pipe(
                tap(() => {
                    this.log('fetched untrusted address');
                }),
                shareReplay(),
                catchError(this.handleError<AddressUntrusted[]>('getQueue', [])),
            );
    }

    getUntrustedAddressData(
        ruian: AddressUntrusted | number,
    ): Observable<AddressUntrusted | undefined> {
        const id = typeof ruian === 'number' ? ruian : ruian.id;
        const url = `${this.untrustedAddressUrl}/search/${id}`;

        return this.http.get<AddressUntrusted>(url)
            .pipe(
                tap(() => {
                    this.log(`fetched untrusted address id=${id}`);
                }),
                share(),
                catchError(this.handleError<AddressUntrusted>(`getUntrustedAddress id=${id}`)),
            );
    }

    setUntrustedAddressDataSource(): void {
        if (
            !this.resultUntrustedAddressSource.getValue() ||
            this.resultUntrustedAddressSource.getValue()?.length === 0
        ) {
            this.getUntrustedAddressesData()
                .subscribe((addresses: AddressUntrusted[]) => {
                    this.resultUntrustedAddressSource.next(addresses);
                });
        }
    }

    getHardwareConfigsData(): Observable<HardwareConfig[] | undefined> {
        return this.http.get<HardwareConfig[]>(this.hardwareConfigUrl)
            .pipe(
                tap(() => {
                    this.log('fetched hardware configs');
                }),
                shareReplay(),
                catchError(this.handleError<HardwareConfig[]>('getQueue', [])),
            );
    }

    getHardwareConfigData(config: HardwareConfig | number): Observable<HardwareConfig | undefined> {
        const id = typeof config === 'number' ? config : config.id;
        const url = `${this.hardwareConfigUrl}/search/${id}`;

        return this.http.get<HardwareConfig>(url)
            .pipe(
                tap(() => {
                    this.log(`fetched hardware config id=${id}`);
                }),
                share(),
                catchError(this.handleError<HardwareConfig>(`getHardwareConfig id=${id}`)),
            );
    }

    setHardwareConfigDataSource(): void {
        if (
            !this.resultHardwareConfigSource.getValue() ||
            this.resultHardwareConfigSource.getValue()?.length === 0
        ) {
            this.getHardwareConfigsData()
                .subscribe((configs: HardwareConfig[]) => {
                    this.resultHardwareConfigSource.next(configs);
                });
        }
    }

    getLeadsData(): Observable<Leads[] | undefined> {
        return this.http.get<Leads[]>(this.leadsUrl)
            .pipe(
                map((leads: Leads[]) => {
                    leads.forEach((lead: Leads, index) => {
                        leads[index].television_fee = parseFloat(String(lead.television_fee));
                        leads[index].total_fee = parseFloat(String(lead.total_fee));
                        leads[index].wifi_fee = parseFloat(String(lead.wifi_fee));
                        leads[index].ext_services_fee = parseFloat(String(lead.ext_services_fee));
                        leads[index].internet_fee = parseFloat(String(lead.internet_fee));
                        leads[index].total_price = parseFloat(String(lead.total_price));
                        leads[index].internet_price = parseFloat(String(lead.internet_price));
                        leads[index].television_price = parseFloat(String(lead.television_price));
                        leads[index].ext_services_price = parseFloat(String(lead.ext_services_price));
                        leads[index].wifi_price = parseFloat(String(lead.wifi_price));

                        if (lead.tvs) {
                            leads[index].tvs_list = JSON.parse(lead.tvs);
                        }
                    });

                    return leads;
                }),
                tap(() => {
                    this.log('fetched leads');
                }),
                shareReplay(),
                catchError(this.handleError<Leads[]>('getQueue', [])),
            );
    }

    getLeadData(lead: Leads | number): Observable<Leads | undefined> {
        const id = typeof lead === 'number' ? lead : lead.id;
        const url = `${this.leadsUrl}/search/${id}`;

        return this.http.get<Leads>(url)
            .pipe(
                tap(() => {
                    this.log(`fetched lead id=${id}`);
                }),
                share(),
                catchError(this.handleError<Leads>(`getLead id=${id}`)),
            );
    }

    setLeadsSource(): void {
        if (!this.resultLeadSource.getValue() || this.resultLeadSource.getValue()?.length === 0) {
            this.getLeadsData()
                .subscribe((leads: Leads[]) => {
                    this.resultLeadSource.next(leads);
                });
        }
    }

    getOrdersData(): Observable<Orders[] | undefined> {
        return this.http.get<Orders[]>(this.ordersUrl)
            .pipe(
                map((orders: Orders[]) => {
                    orders.forEach((order: Orders, index) => {
                        orders[index].television_fee = parseFloat(String(order.television_fee));
                        orders[index].total_fee = parseFloat(String(order.total_fee));
                        orders[index].wifi_fee = parseFloat(String(order.wifi_fee));
                        orders[index].ext_services_fee = parseFloat(String(order.ext_services_fee));
                        orders[index].internet_fee = parseFloat(String(order.internet_fee));
                        orders[index].total_price = parseFloat(String(order.total_price));
                        orders[index].internet_price = parseFloat(String(order.internet_price));
                        orders[index].television_price = parseFloat(String(order.television_price));
                        orders[index].ext_services_price = parseFloat(String(order.ext_services_price));
                        orders[index].wifi_price = parseFloat(String(order.wifi_price));

                        if (order.tvs) {
                            orders[index].tvs_list = JSON.parse(order.tvs);
                        }
                    });

                    return orders;
                }),
                tap(() => {
                    this.log('fetched orders');
                }),
                shareReplay(),
                catchError(this.handleError<Orders[]>('getQueue', [])),
            );
    }

    getOrderData(config: Orders | number): Observable<Orders | undefined> {
        const id = typeof config === 'number' ? config : config.id;
        const url = `${this.ordersUrl}/search/${id}`;

        return this.http.get<Orders>(url)
            .pipe(
                tap(() => {
                    this.log(`fetched order id=${id}`);
                }),
                share(),
                catchError(this.handleError<Orders>(`getOrder id=${id}`)),
            );
    }

    setOrdersSource(): void {
        if (!this.resultOrderSource.getValue() || this.resultOrderSource.getValue()?.length === 0) {
            this.getOrdersData()
                .subscribe((orders: Orders[]) => {
                    this.resultOrderSource.next(orders);
                });
        }
    }

    getClientsTypesData(): Observable<ClientsType[] | undefined> {
        return this.http.get<ClientsType[]>(this.clientsTypesUrl)
            .pipe(
                tap(() => {
                    this.log('fetched clients types');
                }),
                shareReplay(),
                catchError(this.handleError<ClientsType[]>('getQueue', [])),
            );
    }

    getClientsTypeData(config: ClientsType | number): Observable<ClientsType | undefined> {
        const id = typeof config === 'number' ? config : config.id;
        const url = `${this.clientsTypesUrl}/search/${id}`;

        return this.http.get<ClientsType>(url)
            .pipe(
                tap(() => {
                    this.log(`fetched client type id=${id}`);
                }),
                share(),
                catchError(this.handleError<ClientsType>(`getClientType id=${id}`)),
            );
    }

    setClientsTypesSource(): void {
        if (
            !this.resultClientTypesSource.getValue() ||
            this.resultClientTypesSource.getValue()?.length === 0
        ) {
            this.getClientsTypesData()
                .subscribe((types: ClientsType[]) => {
                    this.resultClientTypesSource.next(types);
                });
        }
    }

    clearTvServicesCache(): void {
        this.resultTvServicesSource.next([]);
    }

    clearBonuseCache(): void {
        this.resultBonusesSource.next([]);
    }

    clearDealTypesCache(): void {
        this.resultDealTypesSource.next([]);
    }

    clearDiscountsCache(): void {
        this.resultDiscountsSource.next([]);
    }

    clearExcludedAddressCache(): void {
        this.resultExcludedAddressSource.next([]);
    }

    clearExtendServicesCache(): void {
        this.resultExtendServicesSource.next([]);
    }

    clearHardwareConfigCache(): void {
        this.resultHardwareConfigSource.next([]);
    }

    clearHardwareCache(): void {
        this.resultHardwareSource.next([]);
    }

    clearInstallTypesCache(): void {
        this.resultInstallTypesSource.next([]);
    }

    clearNetPackagesCache(): void {
        this.resultNetPackagesSource.next([]);
    }

    clearPlacesCache(): void {
        this.resultPlacesSource.next([]);
    }

    clearRuianAddressCache(): void {
        this.resultRuianAddressSource.next([]);
    }

    clearFieldAddressCache(): void {
        this.resultFieldAddressSource.next([]);
    }

    clearSpaceTypesCache(): void {
        this.resultSpaceTypesSource.next([]);
    }

    clearSpeedTypesCache(): void {
        this.resultSpeedTypesSource.next([]);
    }

    clearTvPackagesCache(): void {
        this.resultTvPackagesSource.next([]);
    }

    clearTransferTypesCache(): void {
        this.resultTransferTypesSource.next([]);
    }

    clearTechnologyTypesCache(): void {
        this.resultTechnologyTypesSource.next([]);
    }

    clearTvChannelsCache(): void {
        this.resultTvChannelsSource.next([]);
    }

    clearTvThemesCache(): void {
        this.resultTvThemesSource.next([]);
    }

    clearUnitTypesCache(): void {
        this.resultUnitTypesSource.next([]);
    }

    clearUntrustedAddressCache(): void {
        this.resultUntrustedAddressSource.next([]);
    }

    clearOutagesCache(): void {
        this.resultOutageSource.next([]);
    }

    clearClientServiceCache(): void {
        this.resultClientAddressServiceSource.next([]);
    }

    clearLeadsCache(): void {
        this.resultLeadSource.next([]);
    }

    clearOrdersCache(): void {
        this.resultOrderSource.next([]);
    }

    clearClientsTypeCache(): void {
        this.resultClientTypesSource.next([]);
    }

    clearAllCache(): void {
        this.resultTvServicesSource.next([]);
        this.resultBonusesSource.next([]);
        this.resultDealTypesSource.next([]);
        this.resultDiscountsSource.next([]);
        this.resultExcludedAddressSource.next([]);
        this.resultExtendServicesSource.next([]);
        this.resultHardwareConfigSource.next([]);
        this.resultHardwareSource.next([]);
        this.resultInstallTypesSource.next([]);
        this.resultNetPackagesSource.next([]);
        this.resultPlacesSource.next([]);
        this.resultRuianAddressSource.next([]);
        this.resultFieldAddressSource.next([]);
        this.resultSpaceTypesSource.next([]);
        this.resultTvPackagesSource.next([]);
        this.resultTransferTypesSource.next([]);
        this.resultTechnologyTypesSource.next([]);
        this.resultTvChannelsSource.next([]);
        this.resultTvThemesSource.next([]);
        this.resultUnitTypesSource.next([]);
        this.resultUntrustedAddressSource.next([]);
        this.resultClientAddressServiceSource.next([]);
    }
}
