import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    HostListener,
    Input,
    OnChanges,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {MaterialBudget} from '@src/app/_models/material/material-budget';
import {Router} from '@angular/router';
import {AbstractControl, FormBuilder, FormGroup, ValidatorFn, Validators} from '@angular/forms';
import {DataService} from '@src/app/_services/data.service';
import {PermissionService} from '@src/app/_services/permission.service';
import {AuthenticationService} from '@src/app/_services/authentication.service';
import {MessageService} from '@src/app/_services/message.service';
import {Observable} from 'rxjs';
import {DialogComponent} from '@syncfusion/ej2-angular-popups';
import {
    DropDownListComponent,
    FilteringEventArgs,
    MultiSelectComponent,
} from '@syncfusion/ej2-angular-dropdowns';
import moment from 'moment';
import {Department} from '@src/app/_models/department/department';
import {User} from '@src/app/_models/user/user';
import {EmitType} from '@syncfusion/ej2-base';
import {Query} from '@syncfusion/ej2-data';
import {OrderItem} from '@src/app/_models/material/material-item';
import {ComponentCanDeactivate} from '@src/app/_guards/changes.guard';
import {MomentInput} from 'moment/moment';
import {BudgetInput, MaterialService} from '@src/app/features/material/material.service';

@UntilDestroy()
@Component({
    selector: 'app-budget-form',
    templateUrl: './budget-form.component.html',
    styleUrls: ['./budget-form.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BudgetFormComponent implements OnInit, OnChanges, ComponentCanDeactivate {
    // Dialogs
    height = '240px';

    fields: object = {text: 'label', value: 'value'};

    departmentSelect: Array<{value: boolean | number | string; label: string}> = [];

    usersSelect: Array<{value: boolean | number | string; label: string}> = [];

    // Forms
    budgetForm: FormGroup;

    matchedActiveBudget?: MaterialBudget | null = null;

    isDirty = false;

    isChecked = false;

    submited = false;

    // Variables
    currentUser: User | null;

    budgets: MaterialBudget[] = [];

    // Loaders
    budgetLoading = false;

    @Input() budget: MaterialBudget | null = null;

    @Input() isCreate = false;

    @Input() isUpdate = false;

    @Input() isCopy = false;

    @Input() isVisible = false;

    @Output() readonly budgetFormDialogState = new EventEmitter<boolean>();

    // Dialogs
    @ViewChild('formDialog') formDialogObj: DialogComponent;

    // Dropdowns
    @ViewChild('department') departmentObj: DropDownListComponent;

    @ViewChild('approvers') approversObj: MultiSelectComponent;

    @ViewChild('viewers') viewersObj: MultiSelectComponent;

    constructor(
        private readonly router: Router,
        private readonly ref: ChangeDetectorRef,
        private readonly formBuilder: FormBuilder,
        private readonly dataService: DataService,
        private readonly materialService: MaterialService,
        private readonly permissionService: PermissionService,
        private readonly authenticationService: AuthenticationService,
        private readonly messages: MessageService,
    ) {
        this.currentUser = this.authenticationService.currentUserValue;
    }

    get f(): {[key: string]: AbstractControl} {
        return this.budgetForm.controls;
    }

    @HostListener('window:beforeunload')
    canDeactivate(): Observable<boolean> | boolean {
        return !this.isDirty;
    }

    lessThanDate(fieldDate: string): ValidatorFn {
        return (control: AbstractControl): {lessThan: {value: string}} | null => {
            const fieldToDateCompare = control.parent?.get(fieldDate);
            const endDate = control.value;
            const startDate = fieldToDateCompare?.value;
            const isLessThan =
                Number(moment(startDate as MomentInput)
                    .valueOf()) >
                Number(moment(endDate as MomentInput)
                    .valueOf());

            return isLessThan ? {lessThan: {value: control.value}} : null;
        };
    }

    greaterThanDate(fieldDate: string): ValidatorFn {
        return (control: AbstractControl): {greaterThan: {value: string}} | null => {
            const fieldToDateCompare = control.parent?.get(fieldDate);
            const startDate = control.value;
            const endDate = fieldToDateCompare?.value;
            const isGreaterThan =
                Number(moment(startDate as MomentInput)
                    .valueOf()) >
                Number(moment(endDate as MomentInput)
                    .valueOf());

            return isGreaterThan ? {greaterThan: {value: control.value}} : null;
        };
    }

    ngOnInit(): void {
        this.dataService.budgetSource.pipe(untilDestroyed(this))
            .subscribe(
                (budgets: MaterialBudget[]) => {
                    this.budgets = budgets;
                },
                error => {
                    console.error(error);
                    this.budgetLoading = false;
                    this.ref.markForCheck();
                },
            );

        this.dataService.departmentSource.pipe(untilDestroyed(this))
            .subscribe(
                (departments: Department[]) => {
                    this.departmentSelect = [];
                    departments.map((department: Department) => {
                        if (!department.deleted_date) {
                            this.departmentSelect = [
                                ...this.departmentSelect,
                                {
                                    value: department.id,
                                    label: department.company.name + ' - ' + department.name,
                                },
                            ];
                        }
                    });
                    this.ref.markForCheck();
                },
                error => {
                    console.error(error);
                },
            );

        this.dataService.userSource.pipe(untilDestroyed(this))
            .subscribe(
                (data: User[]) => {
                    this.usersSelect = [];
                    data.map((user: User) => {
                        if (user.authorized && !user.deleted_date) {
                            this.usersSelect = [
                                ...this.usersSelect,
                                {
                                    value: user.id,
                                    label: user.fullname,
                                },
                            ];
                        }
                    });
                    this.ref.markForCheck();
                },
                error => {
                    console.error(error);
                },
            );

        this.budgetForm = this.formBuilder.group({
            name: [
                '',
                Validators.compose([
                    Validators.required,
                    Validators.minLength(2),
                    Validators.maxLength(100),
                ]),
            ],
            amount: [0, Validators.compose([Validators.required, Validators.min(0.01)])],
            start_at: [
                moment()
                    .format('YYYY-MM-DD HH:mm'),
                Validators.compose([Validators.required]),
            ],
            expire_at: [null, Validators.compose([Validators.required])],
            department: [null, Validators.compose([Validators.required])],
            approvers: [[], Validators.compose([Validators.required])],
            viewers: [[]],
        });

        this.budgetForm.get('expire_at')
            ?.addValidators(this.lessThanDate('start_at'));
        this.budgetForm.get('start_at')
            ?.addValidators(this.greaterThanDate('expire_at'));

        this.budgetForm.valueChanges.pipe(untilDestroyed(this))
            .subscribe(() => {
                this.isDirty = this.budgetForm.dirty;
                this.showInputErrors();
            });

        this.budgetForm
            .get('department')
            ?.valueChanges
            .pipe(untilDestroyed(this))
            .subscribe(department => {
                if (department) {
                    this.serachActiveBudget(
                        this.budgetForm.get('department')?.value as number,
                        this.budgetForm.get('start_at')?.value as string,
                        this.budgetForm.get('expire_at')?.value as string,
                    );
                }
            });

        this.budgetForm
            .get('start_at')
            ?.valueChanges
            .pipe(untilDestroyed(this))
            .subscribe((startAt: string) => {
                if (startAt) {
                    this.budgetForm
                        .get('start_at')
                        ?.patchValue(new Date(`${moment(startAt)
                            .format('YYYY-MM-DD')} 00:00`), {
                            onlySelf: true,
                            emitEvent: false,
                        });

                    this.serachActiveBudget(
                        this.budgetForm.get('department')?.value as number,
                        this.budgetForm.get('start_at')?.value as string,
                        this.budgetForm.get('expire_at')?.value as string,
                    );
                }
            });

        this.budgetForm
            .get('expire_at')
            ?.valueChanges
            .pipe(untilDestroyed(this))
            .subscribe((expireAt: string) => {
                if (expireAt) {
                    this.budgetForm
                        .get('expire_at')
                        ?.patchValue(new Date(`${moment(expireAt)
                            .format('YYYY-MM-DD')} 23:59`), {
                            onlySelf: true,
                            emitEvent: false,
                        });

                    this.serachActiveBudget(
                        this.budgetForm.get('department')?.value as number,
                        this.budgetForm.get('start_at')?.value as string,
                        this.budgetForm.get('expire_at')?.value as string,
                    );
                }
            });

        this.dataService.setDepartmentDataSource();
    }

    ngOnChanges(): void {
        if (this.isVisible) {
            if (this.budget && this.isUpdate) {
                this.formDialogObj.header = `Rozpočet #${this.budget.id} - ${this.budget.name}`;
                this.fillTheForm();
                this.showInputErrors();
            }

            if (this.isCreate) {
                this.budgetForm.reset();
                this.formDialogObj.header = 'Nová objednávka';
            }

            if (this.isCopy) {
                this.budgetForm.reset();
                this.formDialogObj.header = 'Nový rozpočet';
                this.fillTheForm();
                this.showInputErrors();
            }
        }
    }

    changeDialogState(value: boolean): void {
        this.budgetFormDialogState.emit(value);
    }

    serachActiveBudget(departmenId: number, startAt: string, expireAt: string): void {
        this.matchedActiveBudget = null;

        if (departmenId && startAt && expireAt) {
            this.matchedActiveBudget = this.budgets.find(
                budget =>
                    budget.department_id === departmenId &&
                    !budget.deleted_date &&
                    ((startAt &&
                            moment(startAt)
                                .format('YYYY-MM-DD HH:mm') >=
                            moment(budget.start_at)
                                .format('YYYY-MM-DD HH:mm') &&
                            moment(startAt)
                                .format('YYYY-MM-DD HH:mm') <=
                            moment(budget.expire_at)
                                .format('YYYY-MM-DD HH:mm')) ||
                        (expireAt &&
                            moment(expireAt)
                                .format('YYYY-MM-DD HH:mm') >=
                            moment(budget.start_at)
                                .format('YYYY-MM-DD HH:mm') &&
                            moment(expireAt)
                                .format('YYYY-MM-DD HH:mm') <=
                            moment(budget.expire_at)
                                .format('YYYY-MM-DD HH:mm'))),
            );
            this.ref.markForCheck();
        }
    }

    onFilteringUsers: EmitType<FilteringEventArgs> = (e: FilteringEventArgs) => {
        if (e.text === '') {
            e.updateData(this.usersSelect);
        } else {
            let query: Query = new Query();

            query = e.text !== '' ? query.where('label', 'contains', e.text, true, true) : query;
            e.updateData(this.usersSelect, query);
        }
    };

    onFilteringDepartments: EmitType<FilteringEventArgs> = (e: FilteringEventArgs) => {
        if (e.text === '') {
            e.updateData(this.departmentSelect);
        } else {
            let query = new Query();

            query = e.text !== '' ? query.where('label', 'contains', e.text, true) : query;
            e.updateData(this.departmentSelect, query);
        }
    };

    fillTheForm(): void {
        const approvers: number[] = [];

        this.budget?.approvers.map((user: User) => {
            approvers.push(user.id);
        });

        const viewers: number[] = [];

        this.budget?.viewers.map((user: User) => {
            viewers.push(user.id);
        });
        this.budgetForm.controls.name.patchValue(this.budget?.name);
        this.budgetForm.controls.amount.patchValue(this.budget?.amount);

        if (!this.isCopy) {
            this.budgetForm.controls.start_at.patchValue(this.budget?.start_at);
            this.budgetForm.controls.expire_at.patchValue(this.budget?.expire_at);
        }

        this.budgetForm.controls.department.patchValue(this.budget?.department_id);
        this.budgetForm.controls.approvers.patchValue(approvers);
        this.budgetForm.controls.viewers.patchValue(viewers);
    }

    onSubmit(): void {
        this.submited = true;
        this.isDirty = false;
        this.budgetLoading = true;

        if (this.isCreate || this.isCopy) {
            this.createBudget();
        }

        if (this.isUpdate) {
            this.editBudget();
        }
    }

    createBudget(): void {
        if (this.currentUser && this.permissionService.checkUserISBudgetAdmin(this.currentUser)) {
            if (this.budgetForm.invalid) {
                console.error('form is not valid!');

                return;
            }

            const budget: BudgetInput = {
                name: this.f.name.value,
                amount: this.f.amount.value,
                start_at: moment(this.f.start_at.value as MomentInput)
                    .format('YYYY-MM-DD HH:mm'),
                expire_at: moment(this.f.expire_at.value as MomentInput)
                    .format('YYYY-MM-DD HH:mm'),
                department_id: this.f.department.value,
                approvers: this.f.approvers.value,
                viewers: this.f.viewers.value,
                created_by: this.currentUser.id,
            };

            this.materialService
                .addBudget(budget)
                .pipe(untilDestroyed(this))
                .subscribe(
                    () => {
                        const body = 'Úspěšně jste vytvořili rozpočet';
                        const options = {
                            progressBar: true,
                            timeOut: 5000,
                            toastClass: 'success',
                        };

                        this.messages.showSuccess('Rozpočet vytvořen', body, options);
                        this.budgetLoading = false;
                        this.dataService.setBudgetsDataSource();
                        this.isCreate = false;
                        this.formDialogObj.hide();
                        this.ref.markForCheck();
                    },
                    error => {
                        console.error(error);

                        const body = 'Zkuste to znovu později';
                        const options = {
                            progressBar: true,
                            timeOut: 5000,
                            toastClass: 'red',
                        };

                        this.messages.showError('Chyba při vytváření rozpočtu', body, options);
                        this.budgetLoading = false;
                        this.ref.markForCheck();
                    },
                );
        } else {
            const body = 'Nemáte oprávnění provést tuto akci...';
            const options = {progressBar: true, timeOut: 5000};

            this.messages.showError('Nedostatečné oprávnění', body, options);
            this.budgetLoading = false;
            this.ref.markForCheck();
        }
    }

    copyBudget(): void {
        if (this.currentUser && this.permissionService.checkUserISBudgetAdmin(this.currentUser)) {
            this.isCreate = true;
            this.isUpdate = false;
            this.budgetForm.reset();
            this.formDialogObj.header = 'Nový rozpočet';
            this.fillTheForm();
            this.showInputErrors();
            this.ref.markForCheck();
            this.formDialogObj.show();
        } else {
            const body = 'Nemáte oprávnění provést tuto akci...';
            const options = {progressBar: true, timeOut: 5000};

            this.messages.showError('Nedostatečné oprávnění', body, options);
            this.budgetLoading = false;
            this.ref.markForCheck();
        }
    }

    editBudget(): void {
        if (this.currentUser && this.permissionService.checkUserISBudgetAdmin(this.currentUser)) {
            this.submited = true;

            if (this.budgetForm.invalid || !this.budget) {
                return;
            }

            this.isDirty = false;
            this.budgetLoading = true;

            const matChanges: number[] = [];

            this.materialService
                .getBudgetItem(this.budget.id)
                .pipe(untilDestroyed(this))
                .subscribe((items: OrderItem[]) => {
                    items.forEach((item: OrderItem) => {
                        item.confirmed = false;
                        item.declined = false;

                        let decline = 0;
                        let submit = 0;
                        const reqSubmits = this.budget?.approvers.length;

                        this.budget?.approvers.forEach((approver: User) => {
                            item.order.confirmers.forEach((confirmer: User) => {
                                if (
                                    approver.id === confirmer.id &&
                                    confirmer.material_assignation_users?.confirmed_date
                                ) {
                                    submit = submit + 1;
                                }

                                if (
                                    approver.id === confirmer.id &&
                                    confirmer.material_assignation_users?.declined_date
                                ) {
                                    decline = decline + 1;
                                }
                            });
                        });

                        if (submit > 0 && decline === 0 && submit === reqSubmits) {
                            item.confirmed = true;
                        }

                        if (decline > 0) {
                            item.declined = true;
                        }

                        if (
                            !item.order.deleted_date &&
                            !item.confirmed &&
                            !item.declined &&
                            !item.deleted_date
                        ) {
                            matChanges.push(item.material_id);
                        }
                    });
                    this.ref.markForCheck();
                });

            const budget = {
                id: this.budget.id,
                name: this.f.name.value,
                amount: this.f.amount.value,
                start_at: moment(this.f.start_at.value as MomentInput)
                    .format('YYYY-MM-DD HH:mm'),
                expire_at: moment(this.f.expire_at.value as MomentInput)
                    .format('YYYY-MM-DD HH:mm'),
                department_id: this.f.department.value,
                approvers: this.f.approvers.value,
                viewers: this.f.viewers.value,
                updated_by: this.currentUser.id,
            };

            this.materialService
                .updateBudget(budget, matChanges)
                ?.pipe(untilDestroyed(this))
                .subscribe(
                    () => {
                        const body = 'Úspěšně jste upravil rozpočet';
                        const options = {
                            progressBar: true,
                            timeOut: 5000,
                            toastClass: 'success',
                        };

                        this.messages.showSuccess('Rozpočet upraven', body, options);
                        this.budgetLoading = false;
                        this.dataService.setBudgetsDataSource();
                        this.formDialogObj.hide();
                        this.ref.markForCheck();
                    },
                    error => {
                        console.error(error);

                        const body = 'Zkuste to znovu později';
                        const options = {
                            progressBar: true,
                            timeOut: 5000,
                            toastClass: 'red',
                        };

                        this.messages.showError('Chyba při úpravě rozpočtu', body, options);
                        this.budgetLoading = false;
                        this.ref.markForCheck();
                    },
                );
        } else {
            const body = 'Nemáte oprávnění provést tuto akci...';
            const options = {progressBar: true, timeOut: 5000};

            this.messages.showError('Nedostatečné oprávnění', body, options);
            this.budgetLoading = false;
            this.ref.markForCheck();
        }
    }

    showInputErrors(): void {
        this.isChecked = true;
        this.budgetForm
            .get('expire_at')
            ?.updateValueAndValidity({onlySelf: true, emitEvent: false});

        this.budgetForm
            .get('start_at')
            ?.updateValueAndValidity({onlySelf: true, emitEvent: false});
        this.budgetForm.markAllAsTouched();
        this.ref.markForCheck();
    }
}
