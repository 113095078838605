<ejs-dialog
  #formDialog
  (close)="
        isUpdate = false;
        isCreate = false;
        isDirty = false;
        isChecked = false;
        isCopy = false;
        submited = false;
        changeDialogState(false)
    "
  [isModal]="true"
  [showCloseIcon]="true"
  [visible]="isVisible"
  [width]="'85%'"
  id="formDialog"
>
  <form [formGroup]="materialItemForm">
    <ng-template #content>
      <div class="dialogContent">
        <div class="row">
          <div class="col-12 mb-3">
            <label for="materialForm-reason"
            >Důvod objednávky materiálu</label
            >
            <ejs-textbox
              [ngClass]="{
                                'e-error': submited && f.reason.errors
                            }"
              floatLabelType="Auto"
              formControlName="reason"
              id="materialForm-reason"
            ></ejs-textbox>
            <div
              *ngIf="submited && f.reason.errors"
              class="invalid-feedback"
            >
              <div *ngIf="f.reason.errors.required">
                Důvod objednávky materiálu je povinný
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="row">
              <div class="col-9">
                <label for="materialForm-payment"
                >Typ platby</label
                >
                <ejs-dropdownlist
                  #payment
                  (filtering)="onFilteringPayments($event)"
                  [allowFiltering]="true"
                  [cssClass]="
                                        f.payment.invalid
                                            ? 'e-error'
                                            : f.payment.valid
                                            ? 'e-success'
                                            : ''
                                    "
                  [dataSource]="paymentSelect"
                  [fields]="fields"
                  [filterBarPlaceholder]="
                                        'Vyhledat typ platby...'
                                    "
                  [ignoreAccent]="true"
                  [placeholder]="'Vyberte typ platby'"
                  [popupHeight]="height"
                  formControlName="payment"
                  id="materialForm-payment"
                  sortOrder="Ascending"
                ></ejs-dropdownlist>
                <div
                  *ngIf="submited && f.payment.errors"
                  class="invalid-feedback"
                >
                  <div *ngIf="f.payment.errors.required">
                    Typ platby objednávky je povinný
                  </div>
                </div>
              </div>
              <div class="col-3">
                <ejs-checkbox
                  [checked]="false"
                  cssClass="e-primary p-0 m-0"
                  formControlName="to_storage"
                  label="Materiál na prodej"
                ></ejs-checkbox>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
    <ng-template #footerTemplate>
      <div class="d-flex justify-content-end">
                <span
                  *ngIf="materialItemForm.invalid"
                  class="e-badge e-badge-warning p-2 size_2"
                  role="alert"
                >
                    <strong>Formulář je špatně vyplněný</strong>
                </span>
        <span
          *ngIf="materialItemForm.valid"
          class="e-badge e-badge-success p-2 size_2"
          role="alert"
        >
                    <strong>Formulář je správně vyplněný</strong>
                </span>
      </div>
      <div class="row">
        <div class="col-12 pt-2">
          <button
            (click)="onSubmit()"
            [disabled]="
                            loadingMaterial ||
                            materialItemForm.invalid ||
                            !isChecked
                        "
            cssClass="e-btn e-success btn-p-zero e-small mr-1"
            ejs-button
            type="submit"
          >
                        <span
                          *ngIf="loadingMaterial === true"
                          aria-hidden="true"
                          class="spinner-grow spinner-grow-sm"
                          role="status"
                        ></span>
            <span *ngIf="loadingMaterial === false">ULOŽIT</span>
            <span *ngIf="loadingMaterial === true"
            >Provádím...</span
            >
          </button>
          <button
            (click)="formDialogObj.hide()"
            cssClass="e-btn e-danger e-outline btn-p-zero e-small"
            ejs-button
            type="button"
          >
            ZRUŠIT
          </button>
        </div>
      </div>
    </ng-template>
  </form>
</ejs-dialog>
