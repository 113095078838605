import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    HostListener,
    OnInit,
    ViewChild,
} from '@angular/core';
import {Hotliner} from '@src/app/_models/hotline/hotliner';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {User} from '@src/app/_models/user/user';
import {MessageService} from '@src/app/_services/message.service';
import {PermissionService} from '@src/app/_services/permission.service';
import {AuthenticationService} from '@src/app/_services/authentication.service';
import {Observable, Observer} from 'rxjs';
import {DataService} from '@src/app/_services/data.service';
import {ButtonPropsModel, DialogComponent} from '@syncfusion/ej2-angular-popups';
import {
    DataResult,
    EditSettingsModel,
    ExcelExportProperties,
    FilterSettingsModel,
    GridComponent,
    GroupSettingsModel,
    PageSettingsModel,
    RowDataBoundEventArgs,
    SearchSettingsModel,
    SelectionSettingsModel,
    ToolbarItems,
} from '@syncfusion/ej2-angular-grids';
import {
    ChangeEventArgs,
    DropDownListComponent,
    FilteringEventArgs,
} from '@syncfusion/ej2-angular-dropdowns';
import {EmitType} from '@syncfusion/ej2-base';
import {Query} from '@syncfusion/ej2-data';
import {ClickEventArgs} from '@syncfusion/ej2-angular-navigations';

import {registerLocaleData} from '@angular/common';
import moment from 'moment';
import localeCs from '@angular/common/locales/cs';
import {CheckBoxComponent} from '@syncfusion/ej2-angular-buttons';
import {TextBoxComponent} from '@syncfusion/ej2-angular-inputs';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {ComponentCanDeactivate} from '@src/app/_guards/changes.guard';
import {HotlinerInput, HotlineService} from '@src/app/features/hotline/hotline.service';

moment.updateLocale('cs', {workingWeekdays: [1, 2, 3, 4, 5]});
registerLocaleData(localeCs);

@UntilDestroy()
@Component({
    selector: 'app-hotline-users',
    templateUrl: './hotline-users.component.html',
    styleUrls: ['./hotline-users.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HotlineUsersComponent implements OnInit, ComponentCanDeactivate {
    // Grid
    currencyFormat: {[key: string]: string} = {format: '#,###.00\',- CZK\''};

    dateFormat: string;

    toolbar: ToolbarItems[] | object;

    data: object;

    fields: object = {text: 'label', value: 'value'};

    filterSettings: FilterSettingsModel;

    selectionSettings: SelectionSettingsModel;

    editSettings: EditSettingsModel;

    groupOptions: GroupSettingsModel;

    pageOptions: PageSettingsModel;

    filterDate: object;

    requiredRules: object;

    searchSettings: SearchSettingsModel;

    exportCurrentPage = false;

    // Dropdowns
    height = '240px';

    categoryFilterData: Array<string> = [];

    usersSelect: Array<{value: boolean | number | string; label: string}> = [];

    // Dialogs
    buttonsDeleteHotlinerDialog: ButtonPropsModel[] = [
        {
            click: this.removeHotliner.bind(this),
            isFlat: false,
            buttonModel: {
                content: 'OK',
                cssClass: 'e-success e-outline',
            },
        },
        {
            click: (): void => {
                this.deleteHotlinerDialog.hide();
            },
            buttonModel: {content: 'ZRUŠIT', cssClass: 'e-danger e-outline'},
        },
    ];

    buttonsActivateHotlinerDialog: ButtonPropsModel[] = [
        {
            click: this.reactiveHotliner.bind(this),
            isFlat: false,
            buttonModel: {
                content: 'OK',
                cssClass: 'e-success e-outline',
            },
        },
        {
            click: (): void => {
                this.activateHotlinerDialog.hide();
            },
            buttonModel: {content: 'ZRUŠIT', cssClass: 'e-danger e-outline'},
        },
    ];

    buttonsCancel: ButtonPropsModel[] = [
        {
            click: (): void => {
                this.confirmationCancel.hide();
            },
            buttonModel: {
                isPrimary: true,
                content: 'OK',
            },
        },
    ];

    // Variables
    currentUser: User | null;

    hotliners?: Hotliner[];

    clickedRow: Hotliner[] = [];

    clickedRow$ = new Observable<Hotliner[]>((observer: Observer<Hotliner[]>) => {
        observer.next(this.clickedRow);
    });

    // Forms
    hotlinerForm: FormGroup;

    isDirty = false;

    isCreate = false;

    isUpdate = false;

    submited = false;

    isChecked = false;

    // Loaders
    loadingHotliner = true;

    // Grid
    @ViewChild('hotlineUsersGrid') gridHotliners: GridComponent;

    @ViewChild('searchtext') searchtextObj: TextBoxComponent;

    // Dropdowns
    @ViewChild('viewFilter') viewFilterInstance: DropDownListComponent;

    @ViewChild('users') usersObj: DropDownListComponent;

    // Checkboxes
    @ViewChild('weekend') weekendObj: CheckBoxComponent;

    // Dialogs
    @ViewChild('confirmationCancel') confirmationCancel: DialogComponent;

    @ViewChild('deleteHotlinerDialog') deleteHotlinerDialog: DialogComponent;

    @ViewChild('activateHotlinerDialog') activateHotlinerDialog: DialogComponent;

    @ViewChild('formDialog') formDialogObj: DialogComponent;

    constructor(
        private readonly ref: ChangeDetectorRef,
        private readonly formBuilder: FormBuilder,
        private readonly authenticationService: AuthenticationService,
        private readonly hotlineService: HotlineService,
        private readonly permissionService: PermissionService,
        private readonly messages: MessageService,
        private readonly dataService: DataService,
    ) {
        this.currentUser = this.authenticationService.currentUserValue;
    }

    get f(): {[key: string]: AbstractControl} {
        return this.hotlinerForm.controls;
    }

    @HostListener('window:beforeunload')
    canDeactivate(): Observable<boolean> | boolean {
        return !this.isDirty;
    }

    ngOnInit(): void {
        this.filterSettings = {
            type: 'Excel',
            showFilterBarOperator: true,
            showFilterBarStatus: true,
            ignoreAccent: true,
        };

        this.selectionSettings = {
            persistSelection: true,
            type: 'Multiple',
            checkboxOnly: true,
        };
        this.searchSettings = {ignoreAccent: true, ignoreCase: true};
        this.toolbar = [
            {
                text: 'Občerstvit data',
                tooltipText: 'Občerstvit data',
                prefixIcon: 'e-refresh',
                id: 'customRefresh',
            },
            'Print',
            'ExcelExport',
            'Add',
            'Edit',
            {
                text: 'Kopírovat',
                tooltipText: 'Kopírovat hot-linera',
                prefixIcon: 'e-copy',
                id: 'copy',
            },
            {
                text: 'Smazat',
                tooltipText: 'Smazat hot-linera',
                prefixIcon: 'e-remove',
                id: 'delete',
            },
        ];

        this.categoryFilterData = [
            'všichni hot-lineři',
            'pouze aktivní hot-lineři',
            'hot-lineři víkend',
            'hot-lineři bez víkendu',
            'smazaní hot-lineři',
        ];
        this.pageOptions = {pageSize: 50};
        this.dateFormat = 'dd.MM.yyyy HH:mm';
        this.filterDate = {type: 'Menu', params: {format: this.dateFormat}};
        this.groupOptions = {
            showGroupedColumn: true,
            disablePageWiseAggregates: false,
        };

        this.editSettings = {
            allowEditing: true,
            allowAdding: true,
            allowDeleting: true,
            allowEditOnDblClick: false,
            mode: 'Dialog',
        };
        this.requiredRules = {required: true};

        this.dataService.userSource.pipe(untilDestroyed(this))
            .subscribe(
                (data: User[]) => {
                    this.usersSelect = [];
                    data.map((user: User) => {
                        if (!user.deleted_date) {
                            this.usersSelect = [
                                ...this.usersSelect,
                                {value: user.id, label: user.firstname + ' ' + user.secondname},
                            ];
                        }
                    });
                },
                error => {
                    console.error(error);
                },
            );

        this.hotlinerForm = this.formBuilder.group({
            user_id: [null, Validators.required],
            weekend: [false, Validators.required],
        });

        this.hotlinerForm.valueChanges.pipe(untilDestroyed(this))
            .subscribe(() => {
                this.isDirty = this.hotlinerForm.dirty;
                this.showInputErrors();
            });

        this.dataService.setCompanyDataSource();
        this.dataService.setDepartmentDataSource();
        this.dataService.setHotlinersDataSource();
    }

    loadData(): DataResult[] {
        this.gridHotliners.clearRowSelection();

        console.info('NEW DATA BOUND');

        const elements: unknown[] = [];

        if (this.hotliners && this.hotliners.length > 0) {
            this.hotliners.map((hotliner: Hotliner) => {
                elements.push({
                    id: hotliner.id,
                    user_id: hotliner.user_id,
                    created_by: hotliner.created_by,
                    user_str: hotliner.user.fullname,
                    user_img: hotliner.user.personalphoto,
                    weekend: hotliner.weekend,
                    assignations: hotliner.assignations,
                    next_hotlines: hotliner.next_hotlines,
                    score: hotliner.score,
                    created_date: new Date(hotliner.created_date),
                    updated_date: hotliner.updated_date ? new Date(hotliner.updated_date) : null,
                    deleted_date: hotliner.deleted_date ? new Date(hotliner.deleted_date) : null,
                    creator_str: hotliner.creator.secondname + ' ' + hotliner.creator.firstname,
                    creator_img: hotliner.creator.personalphoto,
                });
            });
        }

        this.loadingHotliner = false;
        this.ref.markForCheck();

        return elements as DataResult[];
    }

    onCreated(): void {
        this.disableToolBarBtn();
    }

    setInitialGridFiltering(): void {
        this.gridHotliners.clearFiltering();
        // this.gridHotliners.filterByColumn('created_by', 'equal', this.currentUser.id, 'and', false, true);
        // this.viewFilterInstance.value = 'moje žádanky - všechny';
        this.ref.markForCheck();
    }

    onLoad(): void {
        const wrapper1 = document.getElementById('scroll_wrapper1_hotliners');
        const wrapper2 = document.getElementById('scroll_wrapper2_hotliners');

        if (wrapper1 && wrapper2) {
            wrapper1.onscroll = (): void => {
                wrapper2.scrollLeft = wrapper1.scrollLeft;
            };

            wrapper2.onscroll = (): void => {
                wrapper1.scrollLeft = wrapper2.scrollLeft;
            };
        }

        this.dataService.hotlinersSource.pipe(untilDestroyed(this))
            .subscribe(
                (hotliners: Hotliner[]) => {
                    this.hotliners = hotliners;

                    if (this.hotliners.length > 0) {
                        this.data = this.gridHotliners.dataSource = this.loadData();
                    } else {
                        this.loadingHotliner = false;
                    }
                },
                error => {
                    console.error(error);
                    this.loadingHotliner = false;
                    this.ref.markForCheck();
                },
            );
    }

    search(): void {
        this.gridHotliners.search(this.searchtextObj.value);
    }

    onDataBound(): void {
        this.rowSelected();

        const width = document.getElementById('hotlineUsersGrid_content_table')?.offsetWidth;
        const parent1 = document.getElementById('scroll_div_hotliners');
        const parent2 = document.getElementById('grid_parent_hotliners');

        if (parent1 && parent2 && width) {
            parent1.style.width = `${width}px`;
            parent2.style.width = `${width}px`;
        }

        this.gridHotliners.autoFitColumns();
        this.ref.markForCheck();
    }

    rowDataBound(args: RowDataBoundEventArgs): void {
        const data = args.data as Hotliner;

        if (args.row && data.deleted_date) {
            args.row.getElementsByClassName('e-gridchkbox')[0].classList.add('disablecheckbox');
            args.row
                .getElementsByClassName('e-checkbox-wrapper')[0]
                .classList.add('disablecheckbox');
            args.row.classList.add('e-disabled');
        }

        if (
            this.currentUser &&
            args.row &&
            data.created_by !== this.currentUser.id &&
            !this.permissionService.checkUserISHotlineAdmin(this.currentUser) &&
            !this.permissionService.checkUserISAdmin(this.currentUser)
        ) {
            args.row.getElementsByClassName('e-gridchkbox')[0].classList.add('disablecheckbox');
            args.row
                .getElementsByClassName('e-checkbox-wrapper')[0]
                .classList.add('disablecheckbox');
        }

        this.ref.markForCheck();
    }

    onStatusFilterChange(e: ChangeEventArgs): void {
        if (e.value === 'všichni hot-lineři') {
            this.gridHotliners.clearFiltering();
        } else if (e.value === 'pouze aktivní hot-lineři') {
            this.gridHotliners.clearFiltering();
            this.gridHotliners.filterByColumn('deleted_date', 'equal', null, 'and', false, true);
        } else if (e.value === 'hot-lineři víkend') {
            this.gridHotliners.filterSettings = {
                columns: [
                    {
                        field: 'weekend',
                        operator: 'equal',
                        value: true,
                        predicate: 'and',
                        matchCase: false,
                        ignoreAccent: true,
                    },
                    {
                        field: 'deleted_date',
                        operator: 'equal',
                        value: null as unknown as string,
                        predicate: 'and',
                        matchCase: false,
                        ignoreAccent: true,
                    },
                ],
            };
        } else if (e.value === 'hot-lineři bez víkendu') {
            this.gridHotliners.filterSettings = {
                columns: [
                    {
                        field: 'weekend',
                        operator: 'equal',
                        value: false,
                        predicate: 'and',
                        matchCase: false,
                        ignoreAccent: true,
                    },
                    {
                        field: 'deleted_date',
                        operator: 'equal',
                        value: null as unknown as string,
                        predicate: 'and',
                        matchCase: false,
                        ignoreAccent: true,
                    },
                ],
            };
        } else if (e.value === 'smazaní hot-lineři') {
            this.gridHotliners.clearFiltering();
            this.gridHotliners.filterByColumn('deleted_date', 'notequal', null, 'and', false, true);
        }
    }

    onFilteringUsers: EmitType<FilteringEventArgs> = (e: FilteringEventArgs) => {
        if (e.text === '') {
            e.updateData(this.usersSelect);
        } else {
            let query: Query = new Query();

            query = e.text !== '' ? query.where('label', 'contains', e.text, true, true) : query;
            e.updateData(this.usersSelect, query);
        }
    };

    cancelBtnClick(): void {
        this.searchtextObj.value = '';
        this.gridHotliners.searchSettings.key = '';
    }

    toolbarClick(args: ClickEventArgs): void {
        const xlsProp: ExcelExportProperties = {
            fileName: 'js_hotlines_export.xlsx',
            enableFilter: true,
            exportType: this.exportCurrentPage ? 'CurrentPage' : 'AllPages',
        };

        if (args.item.id === 'hotlineUsersGrid_excelexport') {
            void this.gridHotliners.excelExport(xlsProp);
        } else if (args.item.id === 'delete') {
            if (this.gridHotliners.getSelectedRecords().length !== 0) {
                this.deleteHotlinerDialog.content = `Potvrďte smazání ${this.clickedRow.length} uživatele hotline`;
                this.deleteHotlinerDialog.show();
            } else {
                this.confirmationCancel.content =
                    'Musíte vybrat alespoň jednoho uživatele hot-line';
                this.confirmationCancel.show();
            }
        } else if (args.item.id === 'copy') {
            if (this.gridHotliners.getSelectedRecords().length !== 0) {
                this.copyHotliner();
            } else {
                this.confirmationCancel.content =
                    'Musíte vybrat alespoň jednoho uživatele hot-line';
                this.confirmationCancel.show();
            }
        } else if (args.item.id === 'customRefresh') {
            this.loadingHotliner = true;
            console.info('refresh DATA!');
            this.ref.markForCheck();
            this.dataService.clearHotlinersCache();
            this.dataService.setHotlinersDataSource();
        }
    }

    enableToolbarBtn(): void {
        if (this.currentUser && this.permissionService.checkUserISHotlineAdmin(this.currentUser)) {
            this.gridHotliners.toolbarModule.enableItems(
                ['hotlineUsersGrid_edit', 'delete', 'copy'],
                true,
            );
        }
    }

    disableToolBarBtn(): void {
        this.gridHotliners.toolbarModule.enableItems(
            ['hotlineUsersGrid_edit', 'delete', 'copy'],
            false,
        );
    }

    rowSelected(): void {
        this.clickedRow = [];

        const selectedrecords: object[] = this.gridHotliners.getSelectedRecords();

        if (selectedrecords.length > 0) {
            selectedrecords.map((row: Hotliner) => {
                const tempData: Hotliner | undefined = this.hotliners?.find(x => x.id === row.id);

                if (tempData) {
                    this.clickedRow.push(tempData);
                }

                this.ref.markForCheck();
            });

            this.clickedRow$ = new Observable<Hotliner[]>((observer: Observer<Hotliner[]>) => {
                observer.next(this.clickedRow);
            });
            this.enableToolbarBtn();
        } else {
            this.disableToolBarBtn();
            this.clickedRow$ = new Observable<Hotliner[]>((observer: Observer<Hotliner[]>) => {
                observer.next(this.clickedRow);
            });
        }
    }

    actionBegin(args): void {
        if (args.requestType === 'add' || args.requestType === 'beginEdit') {
            args.cancel = true;
            this.submited = false;

            if (args.requestType === 'beginEdit') {
                this.isUpdate = true;
                this.isCreate = false;
                this.formDialogObj.header = `Uživatel hot-line #${this.clickedRow[0].id} - ${this.clickedRow[0].user.fullname}`;
                this.fillTheForm();
                this.showInputErrors();
            }

            if (args.requestType === 'add') {
                this.isCreate = true;
                this.isUpdate = false;
                this.hotlinerForm.reset();
                this.hotlinerForm.get('weekend')
                    ?.patchValue(false);
                this.formDialogObj.header = 'Nový uživatel hot-line';
            }

            this.ref.markForCheck();
            this.formDialogObj.show();
        }
    }

    actionComplete(args): void {
        if (args.requestType === 'beginEdit' || args.requestType === 'add') {
            args.form.ej2_instances[0].rules = {};
        }
    }

    onSubmit(): void {
        this.submited = true;
        this.isDirty = false;
        this.loadingHotliner = true;

        if (this.isCreate) {
            this.addHotliner();
        }

        if (this.isUpdate) {
            this.editHotliner();
        }
    }

    fillTheForm(): void {
        this.hotlinerForm.controls.user_id.patchValue(this.clickedRow[0].user_id);
        this.hotlinerForm.controls.weekend.patchValue(this.clickedRow[0].weekend);
    }

    addHotliner(): void {
        if (this.currentUser && this.permissionService.checkUserISHotlineAdmin(this.currentUser)) {
            if (this.hotlinerForm.invalid) {
                console.error('form is not valid!');

                return;
            }

            this.loadingHotliner = true;

            const values: HotlinerInput = {
                user_id: this.f.user_id.value,
                weekend: !!this.f.weekend.value,
                created_by: this.currentUser.id,
            };

            this.hotlineService
                .addHotliner(values)
                .pipe(untilDestroyed(this))
                .subscribe(
                    (hotliner: Hotliner) => {
                        const body =
                            'uživatel: ' + hotliner.user.firstname + ' ' + hotliner.user.secondname;
                        const options = {progressBar: true, timeOut: 5000};

                        this.messages.showSuccess('Úspěšně zařazen do hotlinerů', body, options);
                        this.loadingHotliner = false;
                        this.isCreate = false;
                        this.dataService.setHotlinersDataSource();
                        this.formDialogObj.hide();
                        this.ref.markForCheck();
                    },
                    error => {
                        console.error(error);
                        this.loadingHotliner = false;

                        const options = {
                            progressBar: true,
                            timeOut: 5000,
                            toastClass: 'red',
                        };
                        const body = 'Zkuste to znovu později...';

                        this.messages.showError('Chyba u přidání hotlinera', body, options);
                        this.ref.markForCheck();
                    },
                );
        } else {
            const body = 'Nemáte oprávnění provést tuto akci...';
            const options = {progressBar: true, timeOut: 5000};

            this.messages.showError('Nedostatečné oprávnění', body, options);
            this.loadingHotliner = false;
            this.isCreate = false;
            this.ref.markForCheck();
        }
    }

    copyHotliner(): void {
        this.isCreate = true;
        this.isUpdate = false;
        this.hotlinerForm.reset();
        this.formDialogObj.header = 'Nový uživatel hot-line';
        this.fillTheForm();
        this.showInputErrors();
        this.ref.markForCheck();
        this.formDialogObj.show();
    }

    editHotliner(): void {
        if (this.currentUser && this.permissionService.checkUserISHotlineAdmin(this.currentUser)) {
            if (this.hotlinerForm.invalid) {
                console.error('form is not valid!');

                return;
            }

            this.loadingHotliner = true;

            const values: HotlinerInput = {
                id: this.clickedRow[0].id,
                user_id: this.f.user_id.value,
                weekend: !!this.f.weekend.value,
                created_by: this.clickedRow[0].created_by,
                updated_by: this.currentUser.id,
            };

            this.hotlineService
                .addHotliner(values)
                .pipe(untilDestroyed(this))
                .subscribe(
                    (hotliner: Hotliner) => {
                        const body =
                            'uživatel: ' + hotliner.user.firstname + ' ' + hotliner.user.secondname;
                        const options = {progressBar: true, timeOut: 5000};

                        this.messages.showSuccess('Úspěšně zařazen do hotlinerů', body, options);
                        this.loadingHotliner = false;
                        this.isUpdate = false;
                        this.dataService.setHotlinersDataSource();
                        this.formDialogObj.hide();
                        this.ref.markForCheck();
                    },
                    error => {
                        console.error(error);
                        this.loadingHotliner = false;

                        const options = {
                            progressBar: true,
                            timeOut: 5000,
                            toastClass: 'red',
                        };
                        const body = 'Zkuste to znovu později...';

                        this.messages.showError('Chyba u přidání hotlinera', body, options);
                        this.ref.markForCheck();
                    },
                );
        } else {
            const body = 'Nemáte oprávnění provést tuto akci...';
            const options = {progressBar: true, timeOut: 5000};

            this.messages.showError('Nedostatečné oprávnění', body, options);
            this.loadingHotliner = false;
            this.isUpdate = false;
            this.ref.markForCheck();
        }
    }

    reactiveHotliner(): void {
        this.loadingHotliner = true;
        this.clickedRow[0].deleted_by = null;
        this.clickedRow[0].deleted_date = null;
        this.hotlineService
            .updateHotliner(this.clickedRow[0])
            .pipe(untilDestroyed(this))
            .subscribe(
                () => {
                    this.messages.showSuccess('Hotliner úspěšně obnoven', '', {
                        progressBar: true,
                        timeOut: 5000,
                    });
                    this.dataService.setHotlinersDataSource();
                    this.loadingHotliner = false;
                    this.activateHotlinerDialog.hide();
                    this.ref.markForCheck();
                },
                error => {
                    console.error(error);
                    this.messages.showError(
                        'Chyba při obnovení hotlinera,',
                        'Zkuste to znovu později',
                        {
                            progressBar: true,
                            timeOut: 5000,
                        },
                    );
                    this.loadingHotliner = false;
                    this.activateHotlinerDialog.hide();
                    this.ref.markForCheck();
                },
            );
    }

    removeHotliner(): void {
        const promises: Promise<Hotliner | undefined>[] = [];
        let rejects = 0;

        this.loadingHotliner = true;

        for (const row of this.clickedRow) {
            if (
                this.currentUser &&
                this.permissionService.checkUserISHotlineAdmin(this.currentUser)
            ) {
                row.deleted_by = this.currentUser.id;

                const declineObj = this.hotlineService.deleteHotliner(row)
                    .toPromise();

                promises.push(declineObj);
            } else {
                rejects++;
            }
        }

        void Promise.all(promises)
            .then(result => {
                if (result.length > 0) {
                    const body = `Celkem smazáno: ${result.length} hot-linerů`;
                    const options = {progressBar: true, timeOut: 5000};

                    this.messages.showSuccess('Smazání úspěšně dokončeno', body, options);
                }

                if (rejects > 0) {
                    const body = `Celkem u: ${rejects} hot-linerů`;
                    const options = {progressBar: true, timeOut: 5000};

                    this.messages.showError('Nedostatečné oprávnění', body, options);
                }

                this.gridHotliners.clearRowSelection();
                this.dataService.setHotlinersDataSource();
                this.rowSelected();
                this.loadingHotliner = false;
                this.deleteHotlinerDialog.hide();
                this.ref.markForCheck();
            });
    }

    refreshData(): void {
        this.loadingHotliner = true;
        this.dataService.clearHotlinersCache();
        this.dataService.setHotlinersDataSource();
    }

    showInputErrors(): void {
        this.isChecked = true;
        /* console.log('VALID: ' + this.ticketForm.valid);
        Object.keys(this.ticketForm.controls).forEach(key => {
            const controlErrors: ValidationErrors = this.ticketForm.get(key).errors;
            if (controlErrors != null) {
                Object.keys(controlErrors).forEach(keyError => {
                    console.log('Key control: ' + key + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError]);
                });
            }
        });*/
        this.hotlinerForm.markAllAsTouched();
        this.ref.markForCheck();
    }
}
