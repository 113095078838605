<div
  aria-label="Ticket toolbar"
  class="d-block text-right p-2"
  role="toolbar"
>
  <button
    (click)="reactiveSolution()"
    *ngIf="(ticket?.created_by === currentUser?.id || (currentUser && ticketsService.checkPromotedAdministrators(currentUser)) || (currentUser?.rule && currentUser?.rule.is_admin) || ticket?.creator.boss_id === currentUser?.id) && !ticket?.deleted_date && ticket?.finished_at"
    cssClass="e-btn e-warning btn-p-zero e-small"
    ejs-button
    title="upravit řešení tiketu"
    type="button"
  >
    <i
      aria-hidden="true"
      class="fa-light fa-edit"
    ></i>
    <span
      class="d-none d-lg-inline"
    >upravit řešení</span
    >
  </button>
  <button
    (click)="activeTicket()"
    *ngIf="(ticket?.created_by === currentUser?.id || (currentUser && ticketsService.checkPromotedAdministrators(currentUser)) || (currentUser?.rule && currentUser?.rule.is_admin) || ticket?.creator.boss_id === currentUser?.id) && (ticket?.deleted_date || ticket?.finished_at)"
    cssClass="e-btn e-danger btn-p-zero e-small"
    ejs-button
    title="re-aktivovat uzamčený tiket"
    type="button"
  >
    <i
      aria-hidden="true"
      class="fa-light fa-repeat"
    ></i>
    <span
      class="d-none d-lg-inline"
    >re-aktivovat ticket</span
    >
  </button>
  <button
    (click)="copyTicket()"
    *ngIf="ticket"
    cssClass="e-btn e-primary btn-p-zero e-small"
    ejs-button
    title="kopírovat tiket"
    type="button"
  >
    <i
      aria-hidden="true"
      class="fa-light fa-copy"
    ></i>
    <span
      class="d-none d-lg-inline"
    >kopírovat</span
    >
  </button>
  <button
    (click)="editTicket()"
    *ngIf="(ticket?.created_by === currentUser?.id || (currentUser && ticketsService.checkPromotedAdministrators(currentUser)) || (currentUser?.rule && currentUser?.rule.is_admin) || ticket?.creator.boss_id === currentUser?.id) && !ticket?.deleted_date && !ticket?.finished_at"
    cssClass="e-btn e-warning btn-p-zero e-small"
    ejs-button
    title="upravit tiket"
    type="button"
  >
    <i
      aria-hidden="true"
      class="fa-light fa-pencil"
    ></i>
    <span
      class="d-none d-lg-inline"
    >upravit</span
    >
  </button>
  <button
    (click)="cerateComment()"
    *ngIf="!ticket?.finished_at && !ticket?.deleted_date"
    cssClass="e-btn e-primary btn-p-zero e-small"
    ejs-button
    title="přidat komentář do diskuze" type="button"
  >
    <i
      aria-hidden="true"
      class="fa-light fa-edit mr-lg-1"
    ></i>
    <span class="d-none d-lg-inline"> Přidat komentář </span>
  </button>
  <button
    (click)="addSolving()"
    *ngIf="!ticket?.finished_at && !ticket?.deleted_date"
    cssClass="e-btn e-success btn-p-zero e-small"
    data-backdrop="false"
    ejs-button
    title="vložit řešení tiketu"
    type="button"
  >
    <i
      aria-hidden="true"
      class="fa-light fa-lock-alt"
    ></i>
    <span
      class="d-none d-lg-inline"
    >Vložit řešení / uzamknout</span
    >
  </button>
  <button
    (click)="deleteTicket()"
    *ngIf="(ticket?.created_by === currentUser?.id || (currentUser && ticketsService.checkPromotedAdministrators(currentUser)) || (currentUser?.rule && currentUser?.rule.is_admin) || ticket?.creator.boss_id === currentUser?.id) && !ticket?.deleted_date && !ticket?.finished_at"
    cssClass="e-btn e-danger btn-p-zero e-small"
    ejs-button
    title="smazat tiket"
    type="button"
  >
    <i
      aria-hidden="true"
      class="fa-light fa-trash"
    ></i>
    <span
      class="d-none d-lg-inline"
    >smazat</span
    >
  </button>
</div>
