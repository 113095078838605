import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {DataService} from '@src/app/_services/data.service';
import {MessageService} from '@src/app/_services/message.service';
import {EnvironmentService} from '@src/app/_services/environment.service';
import {Observable, of} from 'rxjs';
import {Booking} from '@src/app/_models/booking/book.model';
import moment from 'moment-business-days';
import {BookInput} from '@src/app/features/booking/types/input.type';
import {BaseModel} from '@src/app/_models/_base/base-model';

const httpOptions = {
    headers: new HttpHeaders({'Content-Type': 'application/json'}),
};

@Injectable({
    providedIn: 'root',
})
export class BookingService {
    private readonly bookingUrl: string;

    constructor(
        private readonly http: HttpClient,
        private readonly dataService: DataService,
        private readonly messageService: MessageService,
        private readonly environmentService: EnvironmentService,
    ) {
        this.bookingUrl = this.environmentService.backendURL + '/api/booking';
    }

    /**
     * Handle Http operation that failed.
     * Let the app continue.
     * @param operation - name of the operation that failed
     * @param result - optional value to return as the observable result
     */
    private handleError<T>(operation = 'operation', result?: T) {
        return (error: {[key: string]: string}): Observable<T | undefined> => {
            console.error(error);
            this.log(`${operation} failed: ${error.message}`);

            return of(result);
        };
    }

    /** Log a hotlineService message with the MessageService */
    private log(message: string): void {
        this.messageService.addNotification(`Booking: ${message}`, false);
    }

    addBooking(booking: BookInput): Observable<BaseModel> {
        this.dataService.clearBooksCache();

        return this.http.post<BaseModel>(this.bookingUrl, booking, httpOptions);
    }

    updateBooking(booking: BookInput): Observable<Booking> | undefined {
        if (!booking.id) {
            return;
        }

        const url = `${this.bookingUrl}/update/${booking.id}`;

        this.dataService.clearBooksCache();

        return this.http.put<Booking>(url, booking, httpOptions);
    }

    deleteBooking(booking: Booking): Observable<Booking> | undefined {
        if (!booking.deleted_by) {
            return;
        }

        const headers = new HttpHeaders({'Content-Type': 'application/json'});
        const params = new HttpParams().set('deleted_by', booking.deleted_by.toString());
        const url = `${this.bookingUrl}/${booking.id}`;

        this.dataService.clearBooksCache();

        return this.http.delete<Booking>(url, {headers, params});
    }

    checkAvailableTerm(start: string, end: string, booking: Booking[]): boolean {
        const termStartAt = moment(start)
            .valueOf();
        const termEndAt = moment(end)
            .valueOf();

        let validation = true;

        booking.forEach((book: Booking) => {
            const startAt = moment(book.start)
                .valueOf();
            const endAt = moment(book.end)
                .valueOf();

            if (termStartAt <= endAt && termEndAt >= startAt) {
                validation = false;
            }
        });

        return validation;
    }
}
