import {
    AfterViewChecked,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnInit,
    ViewChild,
} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {MessageService} from '@src/app/_services/message.service';
import {Vacation} from '@src/app/_models/vacation/vacation';
import {registerLocaleData} from '@angular/common';
import {AuthenticationService} from '@src/app/_services/authentication.service';
import {PermissionService} from '@src/app/_services/permission.service';
import {Observable, Observer} from 'rxjs';
import {DataService} from '@src/app/_services/data.service';
import {
    DataResult,
    EditSettingsModel,
    ExcelExportProperties,
    FilterSettingsModel,
    GridComponent,
    GroupSettingsModel,
    PageSettingsModel,
    RowDataBoundEventArgs,
    SelectionSettingsModel,
    ToolbarItems,
} from '@syncfusion/ej2-angular-grids';
import {ChangeEventArgs, DropDownListComponent} from '@syncfusion/ej2-angular-dropdowns';
import {ClickEventArgs} from '@syncfusion/ej2-angular-navigations';
import {ButtonPropsModel, DialogComponent} from '@syncfusion/ej2-angular-popups';
import moment from 'moment-business-days';
import localeCs from '@angular/common/locales/cs';
import {TextBoxComponent} from '@syncfusion/ej2-angular-inputs';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {VacationService} from '@src/app/features/vacation/vacation.service';
import {VacationComponent} from '@src/app/features/vacation/components/vacation/vacation.component';
import {ApisService} from '@src/app/_api/apis.service';
import {AcsService} from '@src/app/_api/acs/acs.service';
import {NavHandlerService} from '@src/app/_services/nav-handler.service';

moment.updateLocale('cs', {workingWeekdays: [1, 2, 3, 4, 5]});
registerLocaleData(localeCs);

interface VacationGridItems {
    id: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    created_by: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    boss_id?: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    category_id: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    creator_str: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    creator_img: string;
    reason: string;
    status: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    category_str: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    category_clr: string;
    workdays: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    start_at: Date;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    end_at: Date;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    created_date: Date;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    updated_date: Date | null;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    deleted_date: Date | null;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    boss_str: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    boss_img: string;
    decline: boolean;
    confirm: boolean;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    decline_at: Date | null;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    confirm_at: Date | null;
}

@UntilDestroy()
@Component({
    selector: 'app-vacation-queue',
    templateUrl: './vacation-queue.component.html',
    styleUrls: ['./vacation-queue.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VacationQueueComponent extends VacationComponent implements OnInit, AfterViewChecked {
    today = moment()
        .format('YYYY-MM-DD HH:mm:ss');

    todayUnix = moment()
        .valueOf();

    // Grid
    currencyFormat: {format: string} = {format: '#,###.00\',- CZK\''};

    dateFormat: string;

    loadingIndicator?: any;

    sortOptions: object;

    filterSettings: FilterSettingsModel;

    selectionSettings: SelectionSettingsModel;

    editSettings: EditSettingsModel;

    groupOptions: GroupSettingsModel;

    pageOptions: PageSettingsModel;

    filterDate: object;

    requiredRules: object;

    categoryFilterData: string[] = [];

    vacations: Vacation[] = [];

    toolbar: ToolbarItems[] | object;

    data: object;

    fields: object = {text: 'label', value: 'value'};

    exportCurrentPage = false;

    // Dialogs
    renderVacationFormDialog = false;

    buttonsDelete: ButtonPropsModel[] = [
        {
            click: (): void => {
                this.stornoApplication();
            },
            isFlat: false,
            buttonModel: {
                content: 'OK',
                cssClass: 'e-success e-outline',
            },
        },
        {
            click: (): void => {
                this.confirmationDelete.hide();
            },
            buttonModel: {
                content: 'ZRUŠIT',
                cssClass: 'e-danger e-outline',
            },
        },
    ];

    buttonsDecline: ButtonPropsModel[] = [
        {
            click: (): void => {
                this.declineApplication();
            },
            isFlat: false,
            buttonModel: {
                content: 'OK',
                cssClass: 'e-success e-outline',
            },
        },
        {
            click: (): void => {
                this.confirmationDecline.hide();
            },
            buttonModel: {
                content: 'ZRUŠIT',
                cssClass: 'e-danger e-outline',
            },
        },
    ];

    buttonsConfirm: ButtonPropsModel[] = [
        {
            click: (): void => {
                this.confirmApplication();
            },
            isFlat: false,
            buttonModel: {
                content: 'OK',
                cssClass: 'e-success e-outline',
            },
        },
        {
            click: (): void => {
                this.confirmationConfirm.hide();
            },
            buttonModel: {
                content: 'ZRUŠIT',
                cssClass: 'e-danger e-outline',
            },
        },
    ];

    buttonsCancel: ButtonPropsModel[] = [
        {
            click: (): void => {
                this.confirmationCancel.hide();
            },
            buttonModel: {
                isPrimary: true,
                content: 'OK',
            },
        },
    ];

    // Dropdowns
    height = '240px';

    holidays: Array<string> = [];

    // Forms
    isCreate = false;

    isUpdate = false;

    isCopy = false;

    // přípaneče a loadingy
    loadingVacation = true;

    // proměnné pro data
    clickedRow: Vacation[] = [];

    clickedRow$ = new Observable<Vacation[]>((observer: Observer<Vacation[]>) => {
        observer.next(this.clickedRow);
    });

    // Grid
    @ViewChild('vacationsGrid') gridVacation?: GridComponent;

    @ViewChild('searchtext') searchtextObj?: TextBoxComponent;

    // Dialogs
    @ViewChild('confirmationDelete') confirmationDelete: DialogComponent;

    @ViewChild('confirmationDecline') confirmationDecline: DialogComponent;

    @ViewChild('confirmationConfirm') confirmationConfirm: DialogComponent;

    @ViewChild('confirmationCancel') confirmationCancel: DialogComponent;

    // Dropdowns
    @ViewChild('viewFilter') viewFilterInstance?: DropDownListComponent;

    constructor(
        authenticationService: AuthenticationService,
        router: Router,
        ref: ChangeDetectorRef,
        route: ActivatedRoute,
        apisService: ApisService,
        acsService: AcsService,
        navHandlerService: NavHandlerService,
        dataService: DataService,
        messages: MessageService,
        permissionService: PermissionService,
        private readonly vacationService: VacationService,
    ) {
        super(
            authenticationService,
            router,
            ref,
            route,
            apisService,
            acsService,
            navHandlerService,
            dataService,
            messages,
            permissionService,
        );
    }

    ngOnInit(): void {
        this.loadingIndicator = {indicatorType: 'Shimmer'};
        this.filterSettings = {
            type: 'Excel',
            showFilterBarOperator: true,
            showFilterBarStatus: true,
            ignoreAccent: true,
        };

        this.selectionSettings = {
            persistSelection: true,
            type: 'Multiple',
            checkboxOnly: true,
        };

        this.toolbar = [
            {
                text: 'Občerstvit data',
                tooltipText: 'Občerstvit data',
                prefixIcon: 'e-refresh',
                id: 'customRefresh',
            },
            'Print',
            'ExcelExport',
            'Add',
            'Edit',
            {
                text: 'Smazat',
                tooltipText: 'Smazat / Stáhnout žádanku',
                prefixIcon: 'e-remove',
                id: 'delete',
            },
            {
                text: 'Schválit',
                tooltipText: 'Schválit žádanku',
                prefixIcon: 'e-check',
                id: 'confirm',
            },
            {
                text: 'Zamítnout',
                tooltipText: 'Zamítnout žádanku',
                prefixIcon: 'e-times',
                id: 'decline',
            },
            {
                text: 'Kopírovat',
                tooltipText: 'Kopírovat žádanku',
                prefixIcon: 'e-copy',
                id: 'copy',
            },
            {
                text: 'Detail',
                tooltipText: 'Detail žádanky',
                prefixIcon: 'e-openfolder',
                id: 'detail',
            },
        ];

        this.categoryFilterData = [
            'všechny žádanky',
            'moje žádanky - vyřízené',
            'moje žádanky - fronta',
            'moje žádanky - všechny',
            'žádanky ke schválení',
            'přehled nadřízeného',
        ];
        this.pageOptions = {pageSize: 50};
        this.dateFormat = 'dd.MM.yyyy HH:mm';
        this.filterDate = {type: 'Menu', params: {format: this.dateFormat}};
        this.groupOptions = {
            showGroupedColumn: true,
            disablePageWiseAggregates: false,
        };

        this.editSettings = {
            allowEditing: true,
            allowAdding: true,
            allowDeleting: true,
            allowEditOnDblClick: false,
            mode: 'Dialog',
        };
        this.requiredRules = {required: true};
    }

    ngAfterViewChecked(): void {
        this.route.queryParamMap.pipe(untilDestroyed(this))
            .subscribe(params => {
                if (params.get('form') === 'addVacation') {
                    console.info('get vacation form');
                    this.isCreate = true;
                    this.isUpdate = false;
                    this.isCopy = false;
                    this.renderVacationFormDialog = true;
                    this.ref.markForCheck();
                }
            });
    }

    loadData(): DataResult[] {
        this.gridVacation?.clearRowSelection();

        console.info('NEW DATA BOUND');

        let elements: VacationGridItems[] = [];

        if (this.vacations.length > 0) {
            // eslint-disable-next-line complexity
            this.vacations.map((vacation: Vacation) => {
                elements.push({
                    id: vacation.id,
                    created_by: vacation.created_by,
                    boss_id: vacation.creator.boss_id,
                    category_id: vacation.category_id,
                    // eslint-disable-next-line max-len
                    creator_str: vacation.creator.fullname,
                    creator_img: vacation.creator.personalphoto,
                    reason:
                        this.currentUser &&
                        this.permissionService.checkUserISVacationAdmin(this.currentUser) ||
                        this.currentUser?.id === vacation.creator.boss.id ||
                        this.currentUser?.id === vacation.boss.id ||
                        this.currentUser?.id === vacation.created_by
                            ? vacation.reason
                            : '*****',
                    status:
                        vacation.status === 'queue'
                            ? 've frontě'
                            : vacation.status === 'confirmed'
                                ? 'schváleno'
                                : vacation.status === 'declined'
                                    ? 'zamítnuto'
                                    : vacation.status === 'deleted'
                                        ? 'staženo'
                                        : 'status error',
                    category_str: vacation.category.name,
                    category_clr: vacation.category.colortag,
                    workdays: vacation.workdays,
                    start_at: new Date(vacation.start_at),
                    end_at: new Date(vacation.end_at),
                    created_date: new Date(vacation.created_date),
                    updated_date: vacation.updated_date ? new Date(vacation.updated_date) : null,
                    deleted_date: vacation.deleted_date ? new Date(vacation.deleted_date) : null,
                    boss_str: `${vacation.boss.secondname} ${vacation.boss.firstname}`,
                    boss_img: vacation.boss.personalphoto,
                    decline: vacation.decline,
                    confirm: vacation.confirm,
                    decline_at: vacation.decline_at ? new Date(vacation.decline_at) : null,
                    confirm_at: vacation.confirm_at ? new Date(vacation.confirm_at) : null,
                });
            });

            elements = elements.sort((a: VacationGridItems, b: VacationGridItems) => {
                const dateA = moment(a.created_date)
                    .valueOf();
                const dateB = moment(b.created_date)
                    .valueOf();

                return dateA > dateB ? -1 : 1;
            });
        }

        this.loadingVacation = false;
        this.ref.markForCheck();

        return elements as unknown as DataResult[];
    }

    onCreated(): void {
        this.disableToolBarBtn();
        document.getElementById('vacationsGrid')
            ?.addEventListener('click', (args) => {
                if (!(args.target as any).classList.contains('e-rowcell')) {
                    const rowElement = (args.target as any).closest('.e-rowcell');

                    if (rowElement) {
                        const rowInfo = this.gridVacation?.getRowInfo(rowElement as EventTarget); // get row information

                        if (rowInfo?.cellIndex !== 0) {
                            void this.router.navigate(['/vacation/detail', (rowInfo?.rowData as any)?.id]);
                        }
                    }
                } else {
                    const rowInfo = this.gridVacation?.getRowInfo(args.target!); // get row information

                    if (rowInfo?.cellIndex !== 0) {
                        void this.router.navigate(['/vacation/detail', (rowInfo?.rowData as any)?.id]);
                    }
                }
            });
    }

    setInitialGridFiltering(): void {
        if (this.currentUser && this.viewFilterInstance) {
            this.gridVacation?.clearFiltering();
            this.gridVacation?.filterByColumn(
                'created_by',
                'equal',
                this.currentUser.id,
                'and',
                false,
                true,
            );
            this.viewFilterInstance.value = 'moje žádanky - všechny';
            this.ref.markForCheck();
        }
    }

    onLoad(): void {
        const wrapper1 = document.getElementById('scroll_wrapper1_vacations');
        const wrapper2 = document.getElementById('scroll_wrapper2_vacations');

        if (wrapper1 && wrapper2) {
            wrapper1.onscroll = (): void => {
                wrapper2.scrollLeft = wrapper1.scrollLeft;
            };

            wrapper2.onscroll = (): void => {
                wrapper1.scrollLeft = wrapper2.scrollLeft;
            };
        }

        this.dataService.vacationSource.pipe(untilDestroyed(this))
            .subscribe(
                (vacations: Vacation[]) => {
                    this.vacations = vacations;

                    if (this.gridVacation && this.vacations.length > 0) {
                        this.data = this.gridVacation.dataSource = this.loadData();
                        this.sortOptions = {
                            columns: [
                                {field: 'created_date', direction: 'Descending'},
                                {
                                    field: 'id',
                                    direction: 'Descending',
                                },
                            ],
                        };
                    } else {
                        this.loadingVacation = false;
                    }
                },
                error => {
                    console.error(error);
                    this.loadingVacation = false;
                    this.ref.markForCheck();
                },
            );
    }

    search(): void {
        this.gridVacation?.search(this.searchtextObj?.value ?? '');
    }

    onDataBound(): void {
        this.rowSelected();

        const width = document.getElementById('vacationsGrid_content_table')?.offsetWidth;
        const parent1 = document.getElementById('scroll_div_vacations');
        const parent2 = document.getElementById('grid_parent_vacations');

        if (parent1 && parent2 && width) {
            parent1.style.width = `${width}px`;
            parent2.style.width = `${width}px`;
        }

        this.gridVacation?.autoFitColumns();
        this.ref.markForCheck();
    }

    rowDataBound(args: RowDataBoundEventArgs): void {
        const data = args.data as VacationGridItems;

        if (
            args.row &&
            args.data &&
            this.currentUser &&
            data.created_by !== this.currentUser.id &&
            !this.permissionService.checkUserISVacationAdmin(this.currentUser) &&
            (data.boss_id !== this.currentUser.id || data.deleted_date)
        ) {
            args.row.getElementsByClassName('e-gridchkbox')[0].classList.add('disablecheckbox');
            args.row
                .getElementsByClassName('e-checkbox-wrapper')[0]
                .classList.add('disablecheckbox');
            args.row.classList.add('e-disabled');
        }

        this.ref.markForCheck();
    }

    onStatusFilterChange(e: ChangeEventArgs): void {
        if (this.gridVacation && this.currentUser) {
            if (e.value === 'všechny žádanky') {
                this.gridVacation.clearFiltering();
            } else if (e.value === 'moje žádanky - všechny') {
                this.gridVacation.clearFiltering();
                this.gridVacation.filterByColumn(
                    'created_by',
                    'equal',
                    this.currentUser.id,
                    'and',
                    false,
                    true,
                );
            } else if (e.value === 'moje žádanky - vyřízené') {
                this.gridVacation.filterSettings = {
                    columns: [
                        {
                            field: 'created_by',
                            operator: 'equal',
                            value: this.currentUser.id,
                            predicate: 'and',
                            matchCase: false,
                            ignoreAccent: true,
                        },
                        {
                            field: 'status',
                            operator: 'notequal',
                            value: 've frontě',
                            predicate: 'and',
                            matchCase: false,
                            ignoreAccent: true,
                        },
                    ],
                };
            } else if (e.value === 'moje žádanky - fronta') {
                this.gridVacation.filterSettings = {
                    columns: [
                        {
                            field: 'created_by',
                            operator: 'equal',
                            value: this.currentUser.id,
                            predicate: 'and',
                            matchCase: false,
                            ignoreAccent: true,
                        },
                        {
                            field: 'status',
                            operator: 'equal',
                            value: 've frontě',
                            predicate: 'and',
                            matchCase: false,
                            ignoreAccent: true,
                        },
                    ],
                };
            } else if (e.value === 'žádanky ke schválení') {
                this.gridVacation.filterSettings = {
                    columns: [
                        {
                            field: 'boss_id',
                            operator: 'equal',
                            value: this.currentUser.id,
                            predicate: 'and',
                            matchCase: false,
                            ignoreAccent: true,
                        },
                        {
                            field: 'status',
                            operator: 'equal',
                            value: 've frontě',
                            predicate: 'and',
                            matchCase: false,
                            ignoreAccent: true,
                        },
                    ],
                };
            } else if (e.value === 'přehled nadřízeného') {
                this.gridVacation.clearFiltering();
                this.gridVacation.filterByColumn(
                    'boss_id',
                    'equal',
                    this.currentUser.id,
                    'and',
                    false,
                    true,
                );
            }
        }
    }

    cancelBtnClick(): void {
        if (this.searchtextObj && this.gridVacation) {
            this.searchtextObj.value = '';
            this.gridVacation.searchSettings.key = '';
        }
    }

    toolbarClick(args: ClickEventArgs): void {
        const xlsProp: ExcelExportProperties = {
            fileName: 'js_vacations_export.xlsx',
            enableFilter: true,
            exportType: this.exportCurrentPage ? 'CurrentPage' : 'AllPages',
        };

        if (args.item.id === 'vacationsGrid_excelexport') {
            void this.gridVacation?.excelExport(xlsProp);
        } else if (args.item.id === 'confirm') {
            this.confirmationConfirm.content = `Potvrďte schválení ${this.clickedRow.length} žádanek`;

            if (this.gridVacation?.getSelectedRecords().length !== 0) {
                this.confirmationConfirm.content = `Potvrďte schválení ${this.clickedRow.length} žádanek`;
                this.confirmationConfirm.show();
            } else {
                this.confirmationCancel.content = 'Musíte vybrat alespoň jednu žádanku';
                this.confirmationCancel.show();
            }
        } else if (args.item.id === 'decline') {
            if (this.gridVacation?.getSelectedRecords().length !== 0) {
                this.confirmationDecline.content = `Potvrďte zamítnutí ${this.clickedRow.length} žádanek`;
                this.confirmationDecline.show();
            } else {
                this.confirmationCancel.content = 'Musíte vybrat alespoň jednu žádanku';
                this.confirmationCancel.show();
            }
        } else if (args.item.id === 'delete') {
            if (this.gridVacation?.getSelectedRecords().length !== 0) {
                this.confirmationDelete.content = `Potvrďte smazání ${this.clickedRow.length} žádanek`;
                this.confirmationDelete.show();
            } else {
                this.confirmationCancel.content = 'Musíte vybrat alespoň jednu žádanku';
                this.confirmationCancel.show();
            }
        } else if (args.item.id === 'copy') {
            if (this.gridVacation?.getSelectedRecords().length !== 0) {
                this.isUpdate = false;
                this.isCreate = false;
                this.isCopy = true;
                this.renderVacationFormDialog = true;
                this.ref.markForCheck();
            } else {
                this.confirmationCancel.content = 'Musíte vybrat alespoň jednu žádanku';
                this.confirmationCancel.show();
            }
        } else if (args.item.id === 'detail') {
            void this.router.navigate(['/vacation/detail', this.clickedRow[0].id]);
        } else if (args.item.id === 'customRefresh') {
            this.loadingVacation = true;
            console.info('refresh DATA!');
            this.dataService.clearVacationsCache();
            this.dataService.setVacationsDataSource();
            this.ref.markForCheck();
        }
    }

    enableToolbarBtn(): void {
        if (
            this.currentUser &&
            this.permissionService.checkUserISVacationAdmin(this.currentUser) ||
            this.currentUser?.is_boss
        ) {
            this.gridVacation?.toolbarModule.enableItems(
                ['vacationsGrid_edit', 'delete', 'confirm', 'decline', 'copy', 'detail'],
                true,
            );
        } else {
            this.gridVacation?.toolbarModule.enableItems(
                ['vacationsGrid_edit', 'delete', 'copy', 'detail'],
                true,
            );
        }
    }

    disableToolBarBtn(): void {
        this.gridVacation?.toolbarModule.enableItems(
            ['vacationsGrid_edit', 'delete', 'confirm', 'decline', 'copy', 'detail'],
            false,
        );
    }

    rowSelected(): void {
        this.clickedRow = [];

        const selectedrecords: Vacation[] = this.gridVacation?.getSelectedRecords() as Vacation[];

        if (selectedrecords.length > 0) {
            selectedrecords.map(row => {
                const tempData = this.vacations.find(x => x.id === row.id);

                if (tempData) {
                    this.clickedRow.push(tempData);
                }

                this.ref.markForCheck();
            });

            this.clickedRow$ = new Observable<Vacation[]>((observer: Observer<Vacation[]>) => {
                observer.next(this.clickedRow);
            });

            this.enableToolbarBtn();
        } else {
            this.disableToolBarBtn();
            this.clickedRow$ = new Observable<Vacation[]>((observer: Observer<Vacation[]>) => {
                observer.next(this.clickedRow);
            });
        }
    }

    actionBegin(args): void {
        if (args.requestType === 'add' || args.requestType === 'beginEdit') {
            args.cancel = true;

            if (args.requestType === 'beginEdit') {
                this.isUpdate = true;
                this.isCreate = false;
                this.isCopy = false;
            }

            if (args.requestType === 'add') {
                this.isCreate = true;
                this.isUpdate = false;
                this.isCopy = false;
            }

            this.renderVacationFormDialog = true;
            this.ref.markForCheck();
        }
    }

    actionComplete(args): void {
        if (args.requestType === 'beginEdit' || args.requestType === 'add') {
            args.form.ej2_instances[0].rules = {};
        }
    }

    confirmApplication(): void {
        const promises: Promise<Vacation | undefined>[] = [];
        let rejects = 0;

        this.loadingVacation = true;

        for (const row of this.clickedRow) {
            if (
                this.currentUser &&
                !row.deleted_date &&
                !row.confirm &&
                !row.decline &&
                (this.permissionService.checkUserISVacationAdmin(this.currentUser) ||
                    row.creator.boss_id === this.currentUser.id)
            ) {
                row.confirm = true;
                row.confirm_at = this.today;
                row.confirmed_by = this.currentUser.id;

                const confirmObj = this.vacationService.confirmApplication(row)
                    .toPromise();

                promises.push(confirmObj);
            } else {
                rejects++;
            }
        }

        void Promise.all(promises)
            .then(result => {
                if (result.length > 0) {
                    const body = `Celkem schváleno: ${result.length} žádanek`;
                    const options = {progressBar: true, timeOut: 5000};

                    this.messages.showSuccess('Schvalování úspěšně dokončeno', body, options);
                }

                if (rejects > 0) {
                    const body = `Celkem u: ${rejects} žádanek`;
                    const options = {progressBar: true, timeOut: 5000};

                    this.messages.showError('Nedostatečné oprávnění', body, options);
                }

                this.confirmationConfirm.hide();
                this.gridVacation?.clearRowSelection();
                this.rowSelected();
                this.dataService.clearVacationsCache();
                this.dataService.setVacationsDataSource();
                this.loadingVacation = false;
                this.ref.markForCheck();
            });
    }

    stornoApplication(): void {
        const promises: Array<Promise<Vacation | undefined> | undefined> = [];
        let rejects = 0;

        this.loadingVacation = true;

        for (const row of this.clickedRow) {
            if (
                this.currentUser &&
                (!row.deleted_date &&
                    !row.confirm &&
                    !row.decline &&
                    row.created_by === this.currentUser.id ||
                    !row.deleted_date &&
                    (this.permissionService.checkUserISVacationAdmin(this.currentUser) ||
                        row.creator.boss_id === this.currentUser.id))
            ) {
                row.deleted_by = this.currentUser.id;

                const deleteObj = this.vacationService.deleteVacation(row)
                    ?.toPromise();

                promises.push(deleteObj);
            } else {
                rejects++;
            }
        }

        void Promise.all(promises)
            .then(result => {
                if (result.length > 0) {
                    const body = `Celkem stornováno: ${result.length} žádanek`;
                    const options = {progressBar: true, timeOut: 5000};

                    this.messages.showSuccess('Stornování úspěšně dokončeno', body, options);
                }

                if (rejects > 0) {
                    const body = `Celkem u: ${rejects} žádanek`;
                    const options = {progressBar: true, timeOut: 5000};

                    this.messages.showError('Nedostatečné oprávnění', body, options);
                }

                this.confirmationDelete.hide();
                this.gridVacation?.clearRowSelection();
                this.rowSelected();
                this.dataService.clearVacationsCache();
                this.dataService.setVacationsDataSource();
                this.loadingVacation = false;
                this.ref.markForCheck();
            });
    }

    declineApplication(): void {
        const promises: Promise<Vacation | undefined>[] = [];
        let rejects = 0;

        this.loadingVacation = true;

        for (const row of this.clickedRow) {
            if (
                this.currentUser &&
                !row.deleted_date &&
                !row.confirm &&
                !row.decline &&
                (this.permissionService.checkUserISVacationAdmin(this.currentUser) ||
                    row.creator.boss_id === this.currentUser.id)
            ) {
                row.decline = true;
                row.decline_at = this.today;
                row.declined_by = this.currentUser.id;

                const declineObj = this.vacationService.declineApplication(row)
                    .toPromise();

                promises.push(declineObj);
            } else {
                rejects++;
            }
        }

        void Promise.all(promises)
            .then(result => {
                if (result.length > 0) {
                    const body = `Celkem zamítnuto: ${result.length} žádanek`;
                    const options = {progressBar: true, timeOut: 5000};

                    this.messages.showSuccess('Zamítnutí úspěšně dokončeno', body, options);
                }

                if (rejects > 0) {
                    const body = `Celkem u: ${rejects} žádanek`;
                    const options = {progressBar: true, timeOut: 5000};

                    this.messages.showError('Nedostatečné oprávnění', body, options);
                }

                this.confirmationDecline.hide();
                this.gridVacation?.clearRowSelection();
                this.rowSelected();
                this.dataService.clearVacationsCache();
                this.dataService.setVacationsDataSource();
                this.loadingVacation = false;
                this.ref.markForCheck();
            });
    }
}
