<ejs-dialog
  #formDialog
  (close)="
        isUpdate = false;
        isCreate = false;
        isDirty = false;
        isChecked = false;
        isCopy = false;
        submited = false;
        changeDialogState(false)
    "
  [isModal]="true"
  [showCloseIcon]="true"
  [visible]="isVisible"
  [width]="'85%'"
  id="formDialog"
>
  <form [formGroup]="speedTypeForm">
    <ng-template #content>
      <div class="dialogContent">
        <div class="row">
          <div class="col-12 col-md-6 mb-3">
            <label
              [ngClass]="
                                f.speed_until.valid &&
                                (f.speed_until.dirty || f.speed_until.touched)
                                    ? 'text-success'
                                    : f.speed_until.invalid &&
                                      (f.speed_until.dirty ||
                                          f.speed_until.touched)
                                    ? 'text-danger'
                                    : 'text-muted'
                            "
              for="speedTypeForm-speed_until"
            >* OD (Mbit):</label
            >
            <ejs-numerictextbox
              [format]="'###'"
              [ngClass]="
                                (f.speed_until.dirty ||
                                    f.speed_until.touched) &&
                                f.speed_until.invalid
                                    ? 'e-error'
                                    : (f.speed_until.dirty ||
                                          f.speed_until.touched) &&
                                      f.speed_until.valid
                                    ? 'e-success'
                                    : ''
                            "
              formControlName="speed_until"
              id="speedTypeForm-speed_until"
              step="1"
            ></ejs-numerictextbox>
            <small
              *ngIf="
                                (f.speed_until.dirty ||
                                    f.speed_until.touched) &&
                                f.speed_until.errors &&
                                f.speed_until.errors.required
                            "
              class="text-danger"
            >Limit OD je povinný</small
            >
          </div>
          <div class="col-12 col-md-6 mb-3">
            <label
              [ngClass]="
                                f.speed_to.valid &&
                                (f.speed_to.dirty || f.speed_to.touched)
                                    ? 'text-success'
                                    : f.speed_to.invalid &&
                                      (f.speed_to.dirty || f.speed_to.touched)
                                    ? 'text-danger'
                                    : 'text-muted'
                            "
              for="speedTypeForm-speed_to"
            >* DO (mbit):</label
            >
            <ejs-numerictextbox
              [format]="'###'"
              [ngClass]="
                                (f.speed_to.dirty || f.speed_to.touched) &&
                                f.speed_to.invalid
                                    ? 'e-error'
                                    : (f.speed_to.dirty ||
                                          f.speed_to.touched) &&
                                      f.speed_to.valid
                                    ? 'e-success'
                                    : ''
                            "
              formControlName="speed_to"
              id="speedTypeForm-speed_to"
              step="1"
            ></ejs-numerictextbox>
            <small
              *ngIf="
                                (f.speed_to.dirty || f.speed_to.touched) &&
                                f.speed_to.errors &&
                                f.speed_to.errors.required
                            "
              class="text-danger"
            >Limit DO je povinný</small
            >
          </div>
        </div>
      </div>
    </ng-template>
    <ng-template #footerTemplate>
      <div class="d-flex justify-content-end">
                <span
                  *ngIf="speedTypeForm.invalid"
                  class="e-badge e-badge-warning p-2 size_2"
                  role="alert"
                >
                    <strong>Formulář je špatně vyplněný</strong>
                </span>
        <span
          *ngIf="speedTypeForm.valid"
          class="e-badge e-badge-success p-2 size_2"
          role="alert"
        >
                    <strong>Formulář je správně vyplněný</strong>
                </span>
      </div>
      <div class="row">
        <div class="col-12 pt-2">
          <button
            (click)="onSubmit()"
            [disabled]="
                            loadingSpeedType ||
                            speedTypeForm.invalid ||
                            !isChecked
                        "
            cssClass="e-btn e-success btn-p-zero e-small mr-1"
            ejs-button
            type="submit"
          >
                        <span
                          *ngIf="loadingSpeedType === true"
                          aria-hidden="true"
                          class="spinner-grow spinner-grow-sm"
                          role="status"
                        ></span>
            <span *ngIf="loadingSpeedType === false">ULOŽIT</span>
            <span *ngIf="loadingSpeedType === true"
            >Provádím...</span
            >
          </button>
          <button
            (click)="formDialogObj.hide()"
            cssClass="e-btn e-danger e-outline btn-p-zero e-small"
            ejs-button
            type="button"
          >
            ZRUŠIT
          </button>
        </div>
      </div>
    </ng-template>
  </form>
</ejs-dialog>
