<div
  *ngIf="fieldAddressLoad"
  style="
        display: block !important;
        z-index: 1000000;
        width: 250px;
        height: 250px;
        margin: auto;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    "
>
  <div class="loader">
    <span></span>
    <span></span>
    <span></span>
    <span></span>
  </div>
  <div
    class="lds-circle"
    style="
            margin: auto;
            position: absolute;
            width: 150px;
            height: auto;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        "
  >
    <div></div>
  </div>
</div>
<div class="row d-flex justify-content-center modalWrapper">
  <ejs-dialog
    #confirmationDelete
    [buttons]="buttonsDelete"
    [header]="'Smazání parcely'"
    [isModal]="true"
    [showCloseIcon]="true"
    [visible]="false"
    width="250px"
  ></ejs-dialog>
  <ejs-dialog
    #confirmationCancel
    [buttons]="buttonsCancel"
    [header]="'Nelze provést akci'"
    [isModal]="true"
    [showCloseIcon]="true"
    [visible]="false"
    width="250px"
  ></ejs-dialog>
  <app-project-form
    (projectFormDialogState)="
            renderProjectFormDialog = $event;
            isProjectCreate = $event;
            isProjectUpdate = $event;
            isProjectCopy = $event
        "
    [isCopy]="isProjectCopy"
    [isCreate]="isProjectCreate"
    [isUpdate]="isProjectUpdate"
    [isVisible]="renderProjectFormDialog"
    [project]="project"
  ></app-project-form>
  <app-field-form
    (feildFormDialogState)="
            renderFieldFormDialog = $event;
            isCreate = $event;
            isUpdate = $event;
            isCopy = $event;
            gridFields?.clearRowSelection();
            disableToolBarBtn()
        "
    [field]="
            clickedRow && clickedRow[0] && (isUpdate || isCopy)
                ? clickedRow[0]
                : null
        "
    [isCopy]="isCopy"
    [isCreate]="isCreate"
    [isUpdate]="isUpdate"
    [isVisible]="renderFieldFormDialog"
  ></app-field-form>
  <app-check-ruian-form
    (checkRuianFormDialogState)="renderCheckRuianFormDialog = $event"
    [field]="clickedRow && clickedRow[0] ? clickedRow[0] : null"
    [isVisible]="renderCheckRuianFormDialog"
  ></app-check-ruian-form>
</div>
<ejs-toolbar id="filterToolbar" overflowMode="Scrollable" scrollStep="500">
  <e-items>
    <e-item
      [cssClass]="'statusFilter'"
      tooltipText="Přednastavené hledání"
      type="input"
    >
      <ng-template #template>
        <ejs-dropdownlist
          #viewFilter
          (change)="onStatusFilterChange($event)"
          [dataSource]="categoryFilterData"
          [width]="500"
          placeholder="Vyberte přednastavený pohled..."
        ></ejs-dropdownlist>
      </ng-template>
    </e-item>
    <e-item type="Separator"></e-item>
    <e-item
      [cssClass]="'fulltextFilter'"
      tooltipText="Text pro vyhledávání"
      type="input"
    >
      <ng-template #template>
        <ejs-textbox
          #searchtext
          [width]="500"
          class="mr-2"
          placeholder="Vyhledat..."
        ></ejs-textbox>
        <button
          (click)="search()"
          class="e-btn e-info btn-p-zero e-small"
        >
          <i class="fa-light fa-lg fa-search mr-1"></i
          ><span class="d-none d-lg-inline">vyhledat</span>
        </button>
        <button
          (click)="cancelBtnClick()"
          class="e-btn e-danger btn-p-zero e-small"
        >
          <i class="fa-light fa-lg fa-times"></i>
        </button>
      </ng-template>
    </e-item>
  </e-items>
</ejs-toolbar>
<div class="control-section">
  <div class="scroll_wrapper1" id="scroll_wrapper1_fields">
    <div class="scroll_div" id="scroll_div_fields"></div>
  </div>
  <div class="scroll_wrapper2" id="scroll_wrapper2_fields">
    <div class="grid_parent" id="grid_parent_fields">
      <ejs-grid
        #fieldGrid
        (actionBegin)="actionBegin($event)"
        (actionComplete)="actionComplete($event)"
        (created)="onCreated()"
        (dataBound)="onDataBound()"
        (load)="onLoad()"
        (loadeddata)="setInitialGridFiltering()"
        (rowDataBound)="rowDataBound($event)"
        (rowDeselected)="rowSelected()"
        (rowSelected)="rowSelected()"
        (toolbarClick)="toolbarClick($event)"
        [allowExcelExport]="true"
        [allowFiltering]="true"
        [allowMultiSorting]="true"
        [allowPdfExport]="false"
        [allowSelection]="true"
        [allowSorting]="true"
        [dataSource]="data"
        [editSettings]="editSettings"
        [enableHover]="false"
        [enablePersistence]="false"
        [enableVirtualization]="true"
        [filterSettings]="filterSettings"
        [groupSettings]="groupOptions"
        [pageSettings]="pageOptions"
        [selectionSettings]="selectionSettings"
        [sortSettings]="sortOptions"
        [toolbar]="toolbar"
        allowGrouping="true"
        allowReordering="true"
        allowResizing="true"
        allowTextWrap="true"
        height="100%"
        id="fieldGrid"
        locale="cs"
        rowHeight="38"
        showColumnMenu="true"
        width="100%"
      >
        <e-columns>
          <e-column
            [allowEditing]="false"
            [allowFiltering]="false"
            [allowReordering]="false"
            [allowResizing]="false"
            [allowSorting]="false"
            [autoFit]="true"
            type="checkbox"
          ></e-column>
          <e-column
            [allowFiltering]="false"
            [allowReordering]="false"
            [allowResizing]="false"
            [isPrimaryKey]="true"
            [visible]="true"
            field="id"
            headerText="ID"
          >
            <ng-template #template let-data>
              <a
                [routerLink]="[
                                    '/services/field/detail/',
                                    data.id
                                ]"
              >
                {{ data.id }}
              </a>
            </ng-template>
          </e-column>
          <e-column
            [allowEditing]="false"
            [allowFiltering]="false"
            [allowReordering]="false"
            [allowResizing]="false"
            [autoFit]="true"
            [visible]="false"
            field="created_by"
            headerText="User ID"
          ></e-column>
          <e-column
            [allowEditing]="false"
            [allowFiltering]="false"
            [allowReordering]="false"
            [allowResizing]="false"
            [autoFit]="true"
            [visible]="false"
            field="provider_id"
            headerText="Provider ID"
          ></e-column>
          <e-column
            [allowEditing]="false"
            [allowFiltering]="false"
            [allowReordering]="false"
            [allowResizing]="false"
            [autoFit]="true"
            [visible]="false"
            clipMode="EllipsisWithTooltip"
            field="deal_id"
            headerText="Deal ID"
          ></e-column>
          <e-column
            [allowEditing]="false"
            [allowFiltering]="false"
            [allowReordering]="false"
            [allowResizing]="false"
            [autoFit]="true"
            [visible]="false"
            field="unit_id"
            headerText="Unit ID"
          ></e-column>
          <e-column
            [allowEditing]="false"
            [allowFiltering]="false"
            [allowReordering]="false"
            [allowResizing]="false"
            [autoFit]="true"
            [visible]="false"
            field="space_id"
            headerText="Space ID"
          ></e-column>
          <e-column
            [allowEditing]="false"
            [allowFiltering]="false"
            [allowReordering]="false"
            [allowResizing]="false"
            [autoFit]="true"
            [visible]="false"
            field="speed_id"
            headerText="Speed ID"
          ></e-column>
          <e-column
            [allowEditing]="false"
            [allowFiltering]="false"
            [allowReordering]="false"
            [allowResizing]="false"
            [autoFit]="true"
            [visible]="false"
            field="transfer_id"
            headerText="Transfer ID"
          ></e-column>
          <e-column
            [allowEditing]="false"
            [allowFiltering]="false"
            [allowReordering]="false"
            [allowResizing]="false"
            [autoFit]="true"
            [visible]="false"
            field="technology_id"
            headerText="Technology ID"
          ></e-column>
          <e-column
            [allowEditing]="false"
            [allowFiltering]="false"
            [allowReordering]="false"
            [allowResizing]="false"
            [autoFit]="true"
            [visible]="false"
            field="project_id"
            headerText="Project ID"
          ></e-column>
          <e-column
            [allowFiltering]="false"
            [allowReordering]="true"
            [allowResizing]="true"
            [autoFit]="true"
            [visible]="true"
            field="field_number"
            headerText="Číslo parcely"
          ></e-column>
          <e-column
            [autoFit]="true"
            [filter]="filterSettings"
            clipMode="EllipsisWithTooltip"
            field="provider"
            headerText="Provider"
          >
          </e-column>
          <e-column
            [autoFit]="true"
            [filter]="filterSettings"
            clipMode="EllipsisWithTooltip"
            field="address"
            headerText="Parcela"
          >
          </e-column>
          <e-column
            [autoFit]="true"
            [columns]="addressColumns"
            headerText="Adresa"
          ></e-column>
          <e-column
            [autoFit]="true"
            [columns]="buildingColumns"
            headerText="Budova"
          ></e-column>
          <e-column
            [autoFit]="true"
            [columns]="technologyColumns"
            headerText="Technologie připojení"
          ></e-column>
          <e-column
            [autoFit]="true"
            [columns]="serviceColumns"
            headerText="Dostupné služby"
          ></e-column>
          <e-column
            [autoFit]="true"
            [filter]="filterSettings"
            clipMode="EllipsisWithTooltip"
            field="descriptive"
            headerText="Technická poznámka"
          >
            <ng-template #template let-data>
              {{
                data.descriptive && data.descriptive.length > 0
                  ? (data.descriptive | truncateText: 200)
                  :''
              }}
            </ng-template>
          </e-column>
          <e-column
            [autoFit]="true"
            [filter]="filterSettings"
            clipMode="EllipsisWithTooltip"
            field="business_note"
            headerText="Obchodní poznámka"
          >
            <ng-template #template let-data>
              {{
                data.business_note &&
                data.business_note.length > 0
                  ? (data.business_note | truncateText: 200)
                  :''
              }}
            </ng-template>
          </e-column>
          <e-column
            [autoFit]="true"
            [filter]="filterSettings"
            clipMode="EllipsisWithTooltip"
            field="discount"
            headerText="Sleva"
          >
            <ng-template #template let-data>
                            <span *ngIf="data.discount && data.discount > 0"
                            >{{ data.discount }} %</span
                            >
              <span *ngIf="!data.discount || data.discount === 0"
              >bez slevy</span
              >
            </ng-template>
          </e-column>
          <e-column
            [autoFit]="true"
            [filter]="filterSettings"
            clipMode="EllipsisWithTooltip"
            field="deal_type"
            headerText="Smluvní úvazek"
          >
            <ng-template #template let-data>
                            <span *ngIf="data.deal_type && data.deal_type > 0"
                            >{{ data.deal_type }} měsíců</span
                            >
              <span
                *ngIf="!data.deal_type || data.deal_type === 0"
              >bez úvazku</span
              >
            </ng-template>
          </e-column>
          <e-column
            [columns]="marketingColumns"
            headerText="Marketing"
          ></e-column>
          <e-column
            [autoFit]="true"
            [filter]="filterSettings"
            clipMode="EllipsisWithTooltip"
            field="project"
            headerText="Projekt"
          >
          </e-column>
          <e-column
            [allowEditing]="false"
            [autoFit]="true"
            [filter]="filterDate"
            [format]="dateFormat"
            editType="datepickeredit"
            field="realized_at"
            headerText="Realizace"
            textAlign="Right"
            type="date"
          >
          </e-column>
          <e-column
            [autoFit]="true"
            [filter]="filterSettings"
            clipMode="EllipsisWithTooltip"
            field="creator"
            headerText="Vytvořil"
          >
            <ng-template #template let-data>
              <div>
                <img
                  *ngIf="data.creator_img"
                  alt="profilový obrázek"
                  class="img-fluid rounded-circle avatar-xs mr-1"
                  src="assets/img{{ data.creator_img }}"
                />
                <span>{{ data.creator_str }}</span>
              </div>
            </ng-template>
          </e-column>
          <e-column
            [allowEditing]="false"
            [autoFit]="true"
            [filter]="filterDate"
            [format]="dateFormat"
            clipMode="EllipsisWithTooltip"
            editType="datepickeredit"
            field="created_date"
            headerText="Vytvořeno"
            textAlign="Right"
            type="date"
          ></e-column>
          <e-column
            [allowEditing]="false"
            [autoFit]="true"
            [filter]="filterDate"
            [format]="dateFormat"
            clipMode="EllipsisWithTooltip"
            editType="datepickeredit"
            field="updated_date"
            headerText="Naposledy upraveno"
            textAlign="Right"
            type="date"
          ></e-column>
          <e-column
            [allowEditing]="false"
            [autoFit]="true"
            [filter]="filterDate"
            [format]="dateFormat"
            clipMode="EllipsisWithTooltip"
            editType="datepickeredit"
            field="deleted_date"
            headerText="Smazáno"
            textAlign="Right"
            type="date"
          ></e-column>
        </e-columns>
        <e-aggregates>
          <e-aggregate>
            <e-columns>
              <e-column
                [format]="'N'"
                field="field_number"
                type="Count"
              >
                <ng-template #footerTemplate let-data>
                  Celkem řádků: {{ data.Count }}
                </ng-template
                >
              </e-column>
              <e-column
                [format]="'N'"
                field="number_of_units"
                type="Sum"
              >
                <ng-template #footerTemplate let-data>
                  Celkem: {{ data.Sum }}
                </ng-template>
              </e-column>
            </e-columns>
          </e-aggregate>
          <e-aggregate>
            <e-columns>
              <e-column
                [format]="'N1'"
                field="number_of_units"
                type="Average"
              >
                <ng-template #footerTemplate let-data>
                  Průměr: {{ data.Average }}
                </ng-template>
              </e-column>
            </e-columns>
          </e-aggregate>
          <e-aggregate>
            <e-columns>
              <e-column
                [format]="'N1'"
                field="number_of_units"
                type="Max"
              >
                <ng-template #footerTemplate let-data>
                  Max: {{ data.Max }}
                </ng-template>
              </e-column>
            </e-columns>
          </e-aggregate>
        </e-aggregates>
      </ejs-grid>
    </div>
  </div>
  <ng-template #templateKeysBadges let-data>
        <span *ngIf="data.keys === 'ANO'" class="e-badge e-badge-success">{{
            data.keys
          }}</span>
    <span *ngIf="data.keys === 'NE'" class="e-badge e-badge-danger">{{
        data.keys
      }}</span>
  </ng-template>
  <ng-template #templateLiftBadges let-data>
        <span *ngIf="data.lift === 'ANO'" class="e-badge e-badge-success">{{
            data.lift
          }}</span>
    <span *ngIf="data.lift === 'NE'" class="e-badge e-badge-danger">{{
        data.lift
      }}</span>
  </ng-template>
  <ng-template #templateClipBadges let-data>
        <span
          *ngIf="data.clip_frame === 'ANO'"
          class="e-badge e-badge-success"
        >{{ data.clip_frame }}</span
        >
    <span *ngIf="data.clip_frame === 'NE'" class="e-badge e-badge-danger">{{
        data.clip_frame
      }}</span>
  </ng-template>
</div>
