import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {MobileImportInput} from '@src/app/features/sync/types/mobile-import-input.type';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {MobileImport} from '@src/app/features/sync/types/mobile-import.type';
import {EnvironmentService} from '@src/app/_services/environment.service';

const httpOptions = {
    headers: new HttpHeaders({'Content-Type': 'application/json'}),
};

@Injectable({
    providedIn: 'root',
})
export class SyncService {
    private readonly mobileImportUrl: string;

    constructor(
        private readonly http: HttpClient,
        private readonly environmentService: EnvironmentService,
    ) {
        this.mobileImportUrl = this.environmentService.backendURL + '/api/sync/import';
    }

    processImport(data: MobileImportInput[]): Observable<MobileImport[]> {
        return this.http.post<MobileImport[]>(this.mobileImportUrl, data, httpOptions);
    }
}
