import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class NavHandlerService {
    private readonly subject = new BehaviorSubject<string | null>(null);

    navState = this.subject.asObservable();

    publish(eventName: string): void {
        this.subject.next(eventName);
    }
}
