import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    HostListener,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import {DialogComponent} from '@syncfusion/ej2-angular-popups';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {User} from '@src/app/_models/user/user';
import {DataService} from '@src/app/_services/data.service';
import {DataNetbaseService} from '@src/app/_services/data-netbase.service';
import {
    ServicesService,
    UntrustedAddressInput,
} from '@src/app/features/services/services.service';
import {MessageService} from '@src/app/_services/message.service';
import {AuthenticationService} from '@src/app/_services/authentication.service';
import {Observable, Subject} from 'rxjs';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {ComponentCanDeactivate} from '@src/app/_guards/changes.guard';
import {AddressUntrusted} from '@src/app/_models/services/address-untrusted';
import {PermissionService} from '@src/app/_services/permission.service';
import {Company} from '@src/app/_models/company/company';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {DropDownListComponent, FilteringEventArgs} from '@syncfusion/ej2-angular-dropdowns';
import {EmitType} from '@syncfusion/ej2-base';
import {Query} from '@syncfusion/ej2-data';

declare let smartform;
declare let window: {routerSubject: Subject<string>};
declare let document;

@UntilDestroy()
@Component({
    selector: 'app-address-untrusted-form',
    templateUrl: './address-untrusted-form.component.html',
    styleUrls: ['./address-untrusted-form.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddressUntrustedFormComponent
    implements OnInit, OnChanges, OnDestroy, ComponentCanDeactivate {
    // Dropdowns
    height = '240px';

    addrFieldObj: object = {text: 'label', value: 'value'};

    usersSelect: Array<{value: boolean | number | string; label: string}> = [];

    companySelect: Array<{value: boolean | number | string; label: string}> = [];

    // Forms
    addressUntrustedForm: FormGroup;

    isDirty = false;

    isChecked = false;

    submited = false;

    // Variables
    currentUser: User | null;

    addresses: AddressUntrusted[] = [];

    unAddress: AddressUntrusted | boolean | null | undefined = null;

    smartWholeAddress: string | null = null;

    routerSubject: Subject<string> = new Subject<string>();

    copyAddressFromMapy = false;

    // Loaders
    loadingAddress = false;

    @Input() address: AddressUntrusted | null = null;

    @Input() isCreate = false;

    @Input() isUpdate = false;

    @Input() isVisible = false;

    @Input() isCopy = false;

    @Output() readonly addressUntrustedFormDialogState = new EventEmitter<boolean>();

    // Dropdowns
    @ViewChild('usersSelect') usersSelectObj: DropDownListComponent;

    @ViewChild('companySelect') companySelectObj: DropDownListComponent;

    // Dialogs
    @ViewChild('formDialog') formDialogObj: DialogComponent;

    constructor(
        private readonly router: Router,
        private readonly route: ActivatedRoute,
        private readonly formBuilder: FormBuilder,
        private readonly ref: ChangeDetectorRef,
        private readonly dataService: DataService,
        private readonly dataNetbaseService: DataNetbaseService,
        private readonly servicesService: ServicesService,
        private readonly permissionService: PermissionService,
        private readonly messages: MessageService,
        private readonly authenticationService: AuthenticationService,
    ) {
        this.currentUser = this.authenticationService.currentUserValue;
        window.routerSubject = this.routerSubject;
    }

    get f(): {[key: string]: AbstractControl} {
        return this.addressUntrustedForm.controls;
    }

    private checkUntrustedUnique(ruian): AddressUntrusted | boolean | undefined {
        if (this.addresses.length > 0) {
            const match = this.addresses.find(addr => addr.ruian === parseInt(ruian as string, 10));

            return typeof match !== 'undefined' ? match : false;
        }

        return false;
    }

    @HostListener('window:beforeunload')
    canDeactivate(): Observable<boolean> | boolean {
        return !this.isDirty;
    }

    reinitSmartForm(): void {
        this.unAddress = null;
        this.ref.markForCheck();
        console.info('Reinitialize SF Address objects...');
        smartform.rebindAllForms(false, () => {
            if (smartform.getInstance('smartform-instance-address')) {
                const instanceExcludeAddress = smartform.getInstance('smartform-instance-address');

                instanceExcludeAddress.setZIndex(100000000);
                instanceExcludeAddress.setSelectionBackgroundColor('#4285f3');
                instanceExcludeAddress.addAnnounceCallback((validationType, addressArray) => {
                    if (validationType === smartform.ValidationResultType.HIT) {
                        this.smartWholeAddress = addressArray[0].ADDRESS_WHOLE;
                        this.ruianValidator(addressArray[0].CODE);
                    } else {
                        this.addressUntrustedForm.controls.ruian.setValue(0, {
                            emitEvent: true,
                        });
                    }

                    this.ref.markForCheck();
                });
            }
        });
    }

    ngOnInit(): void {
        this.dataService.companySource.pipe(untilDestroyed(this))
            .subscribe(
                (companies: Company[]) => {
                    this.companySelect = [];
                    companies.map((company: Company) => {
                        if (!company.deleted_date) {
                            this.companySelect = [
                                ...this.companySelect,
                                {
                                    value: company.id,
                                    label: company.name,
                                },
                            ];
                        }
                    });
                    this.ref.markForCheck();
                },
                error => {
                    console.error(error);
                },
            );

        this.dataService.userSource.pipe(untilDestroyed(this))
            .subscribe(
                (data: User[]) => {
                    data.map((user: User) => {
                        if (user.authorized && !user.deleted_date) {
                            this.usersSelect = [
                                ...this.usersSelect,
                                {
                                    value: user.id,
                                    label: `${user.firstname} ${user.secondname}`,
                                },
                            ];
                        }
                    });
                },
                error => {
                    console.error(error);
                },
            );

        this.dataNetbaseService.untrustedAddressSource.pipe(untilDestroyed(this))
            .subscribe({
                next: (addresses: AddressUntrusted[]) => {
                    this.addresses = addresses;
                },
                error: error => {
                    console.error(error);
                },
            });

        this.addressUntrustedForm = this.formBuilder.group({
            address: [
                '',
                Validators.compose([
                    Validators.required,
                    Validators.maxLength(500),
                    Validators.minLength(2),
                ]),
            ],
            provider_id: [null, Validators.required],
            ruian: [{value: null, disabled: true}, Validators.required],
            descriptive: [''],
        });

        this.addressUntrustedForm.valueChanges.pipe(untilDestroyed(this))
            .subscribe(() => {
                this.isDirty = this.addressUntrustedForm.dirty;
                this.showInputErrors();
            });

        this.routerSubject.pipe(untilDestroyed(this))
            .subscribe((url: string) => {
                void this.router.navigateByUrl(url);
            });

        this.route.queryParams.pipe(untilDestroyed(this))
            .subscribe(params => {
                if (params.mapPharse) {
                    document.addEventListener('copy', (e: ClipboardEvent) => {
                        e.clipboardData?.setData('text/plain', params.mapPharse as string);
                        e.preventDefault();
                        document.removeEventListener('copy', null);
                    });
                    document.execCommand('copy');

                    const options = {
                        progressBar: true,
                        timeOut: 5000,
                        toastClass: 'success',
                    };

                    this.messages.showInfo('Adresa úspěšně zkopírována do schránky', '', options);
                    this.copyAddressFromMapy = true;
                }

                this.ref.markForCheck();
            });
    }

    ngOnChanges(): void {
        if (this.isVisible) {
            this.reinitSmartForm();

            const queryParams: Params = {form: null};

            void this.router.navigate([], {
                relativeTo: this.route,
                queryParams,
                queryParamsHandling: 'merge',
            });

            if (this.isUpdate && this.address) {
                this.addressUntrustedForm.reset();
                this.formDialogObj.header = `Riziková adresa #${this.address.id} - ${this.address.address}`;
                this.fillTheForm();
                this.showInputErrors();
            }

            if (this.isCreate) {
                this.addressUntrustedForm.reset();
                this.formDialogObj.header = 'Nová riziková adresa';
            }

            if (this.isCopy) {
                this.addressUntrustedForm.reset();
                this.formDialogObj.header = 'Nová riziková adresa';
                this.fillTheForm();
                this.showInputErrors();
            }
        }
    }

    changeDialogState(value: boolean): void {
        this.addressUntrustedFormDialogState.emit(value);
    }

    onFilteringUsers: EmitType<FilteringEventArgs> = (e: FilteringEventArgs) => {
        if (e.text === '') {
            e.updateData(this.usersSelect);
        } else {
            let query: Query = new Query();

            query = e.text !== '' ? query.where('label', 'contains', e.text, true, true) : query;
            e.updateData(this.usersSelect, query);
        }
    };

    onFilteringCompanies: EmitType<FilteringEventArgs> = (e: FilteringEventArgs) => {
        if (e.text === '') {
            e.updateData(this.companySelect);
        } else {
            let query: Query = new Query();

            query = e.text !== '' ? query.where('label', 'contains', e.text, true, true) : query;
            e.updateData(this.companySelect, query);
        }
    };

    ruianValidator(ruianCode): void {
        if (ruianCode && ruianCode > 0) {
            this.addressUntrustedForm.controls.ruian.setValue(ruianCode, {
                emitEvent: true,
            });
            this.unAddress = this.checkUntrustedUnique(ruianCode);

            if (this.unAddress instanceof AddressUntrusted) {
                this.addressUntrustedForm.controls.address.patchValue(
                    this.smartWholeAddress ? this.smartWholeAddress : this.unAddress.address,
                    {emitEvent: true},
                );

                this.addressUntrustedForm.controls.descriptive.patchValue(
                    this.unAddress.descriptive,
                    {
                        emitEvent: true,
                    },
                );

                this.addressUntrustedForm.controls.provider_id.patchValue(
                    this.unAddress.provider_id,
                    {
                        emitEvent: true,
                    },
                );
            }

            this.ref.markForCheck();
        } else {
            this.unAddress = null;
            this.addressUntrustedForm.reset();
            this.ref.markForCheck();
        }
    }

    fillTheForm(): void {
        this.addressUntrustedForm.controls.address.patchValue(this.address?.address);
        this.addressUntrustedForm.controls.provider_id.patchValue(this.address?.provider_id);
        this.addressUntrustedForm.controls.ruian.patchValue(this.address?.ruian);
        this.addressUntrustedForm.controls.descriptive.patchValue(this.address?.descriptive);
        this.ruianValidator(this.address?.ruian);
    }

    onSubmit(): void {
        this.submited = true;
        this.isDirty = false;
        this.loadingAddress = true;

        if (this.isCreate || this.isCopy) {
            this.createAddress();
        }

        if (this.isUpdate) {
            this.editAddress();
        }
    }

    createAddress(): void {
        if (
            this.currentUser &&
            (this.permissionService.checkUserISServiceAdmin(this.currentUser) ||
                this.permissionService.checkUserISAdministrative(this.currentUser) ||
                this.permissionService.checkUserISMarketingAdmin(this.currentUser))
        ) {
            if (this.addressUntrustedForm.invalid) {
                console.error('form is not valid!');

                return;
            }

            const data: UntrustedAddressInput = {
                address: this.f.address.value,
                ruian: this.f.ruian.value,
                provider_id: this.f.provider_id.value,
                descriptive: this.f.descriptive.value,
                created_by: this.currentUser.id,
            };

            this.servicesService
                .addAddressUntrusted(data)
                .pipe(untilDestroyed(this))
                .subscribe({
                    next: () => {
                        const body = 'Riziková adresa přidána do seznamu...';
                        const options = {progressBar: true, timeOut: 5000};

                        this.messages.showSuccess(
                            'Riziková adresa úspěšně vytvořena',
                            body,
                            options,
                        );
                        this.loadingAddress = false;
                        this.isCreate = false;
                        this.dataNetbaseService.setUntrustedAddressDataSource();
                        this.formDialogObj.hide();
                        this.ref.markForCheck();
                    },
                    error: error => {
                        console.error(error);

                        const body = 'Zkuste to znovu později';
                        const options = {progressBar: true, timeOut: 5000};

                        this.messages.showError(
                            'Chyba při vytváření rizikové adresy',
                            body,
                            options,
                        );
                        this.loadingAddress = false;
                        this.formDialogObj.hide();
                        this.ref.markForCheck();
                    },
                });
        } else {
            const body = 'Nemáte oprávnění provést tuto akci...';
            const options = {progressBar: true, timeOut: 5000};

            this.messages.showError('Nedostatečné oprávnění', body, options);
            this.formDialogObj.hide();
            this.ref.markForCheck();
        }
    }

    editAddress(): void {
        if (
            this.currentUser &&
            (this.permissionService.checkUserISServiceAdmin(this.currentUser) ||
                this.permissionService.checkUserISAdministrative(this.currentUser) ||
                this.permissionService.checkUserISMarketingAdmin(this.currentUser))
        ) {
            if (this.addressUntrustedForm.invalid || !this.address) {
                console.error('form is not valid! || this.address is missing...');

                return;
            }

            const data: UntrustedAddressInput = {
                id: this.address.id,
                address: this.f.address.value,
                ruian: this.f.ruian.value,
                provider_id: this.f.provider_id.value,
                descriptive: this.f.descriptive.value,
                updated_by: this.currentUser.id,
            };

            this.servicesService
                .updateAddressUntrusted(data)
                ?.pipe(untilDestroyed(this))
                .subscribe({
                    next: () => {
                        const options = {progressBar: true, timeOut: 5000};

                        this.messages.showSuccess('Riziková adresa úspěšně upravena', '', options);
                        this.loadingAddress = false;
                        this.dataNetbaseService.setUntrustedAddressDataSource();
                        this.formDialogObj.hide();
                        this.ref.markForCheck();
                    },
                    error: error => {
                        console.error(error);

                        const body = 'Zkuste to znovu později';
                        const options = {progressBar: true, timeOut: 5000};

                        this.messages.showError('Chyba při úpravě rizikové adresy', body, options);
                        this.loadingAddress = false;
                        this.formDialogObj.hide();
                        this.ref.markForCheck();
                    },
                });
        } else {
            const body = 'Nemáte oprávnění provést tuto akci...';
            const options = {progressBar: true, timeOut: 5000};

            this.messages.showError('Nedostatečné oprávnění', body, options);
            this.formDialogObj.hide();
            this.ref.markForCheck();
        }
    }

    showInputErrors(): void {
        this.isChecked = true;
        /* console.log('VALID: ' + this.ticketForm.valid);
          Object.keys(this.ticketForm.controls).forEach(key => {
              const controlErrors: ValidationErrors = this.ticketForm.get(key).errors;
              if (controlErrors != null) {
                  Object.keys(controlErrors).forEach(keyError => {
                      console.log('Key control: ' + key + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError]);
                  });
              }
          });*/
        this.addressUntrustedForm.markAllAsTouched();
        this.ref.markForCheck();
    }

    ngOnDestroy(): void {
        this.routerSubject.unsubscribe();
    }
}
