import {User} from '@src/app/_models/user/user';
import {ExtendServices} from '@src/app/_models/services/extend-services';
import {Discounts} from '@src/app/_models/services/discounts';
import {TransferTypes} from '@src/app/_models/services/transfer-types';
import {Places} from '@src/app/_models/services/places';
import {Bonuses} from '@src/app/_models/services/bonuses';
import {Company} from '@src/app/_models/company/company';
import {AddressExcluded} from '@src/app/_models/services/address-excluded';

export class NetPackages {
    id: number;

    // eslint-disable-next-line @typescript-eslint/naming-convention
    discount_id: number;

    // eslint-disable-next-line @typescript-eslint/naming-convention
    transfer_id: number;

    // eslint-disable-next-line @typescript-eslint/naming-convention
    provider_id: number;

    name: string;

    aggregation: string;

    recommended: boolean;

    extension: boolean;

    price: number;

    // eslint-disable-next-line @typescript-eslint/naming-convention
    sale_order: number;

    // eslint-disable-next-line @typescript-eslint/naming-convention
    searcher_available: boolean;

    // eslint-disable-next-line @typescript-eslint/naming-convention
    speed_up: number;

    // eslint-disable-next-line @typescript-eslint/naming-convention
    speed_down: number;

    // eslint-disable-next-line @typescript-eslint/naming-convention
    created_date: string;

    // eslint-disable-next-line @typescript-eslint/naming-convention
    updated_date?: string | null;

    // eslint-disable-next-line @typescript-eslint/naming-convention
    deleted_date?: string | null;

    // eslint-disable-next-line @typescript-eslint/naming-convention
    created_by: number;

    // eslint-disable-next-line @typescript-eslint/naming-convention
    updated_by?: number | null;

    // eslint-disable-next-line @typescript-eslint/naming-convention
    deleted_by?: number | null;

    unixCreatedTime: number;

    unixUpdatedTime: number;

    excludeOff: boolean;

    creator: User;

    // eslint-disable-next-line @typescript-eslint/naming-convention
    ext_services: ExtendServices[];

    // eslint-disable-next-line @typescript-eslint/naming-convention
    excluded_addresses: AddressExcluded[];

    transfer: TransferTypes;

    discount?: Discounts | null;

    // eslint-disable-next-line @typescript-eslint/naming-convention
    net_places: Places[];

    // eslint-disable-next-line @typescript-eslint/naming-convention
    net_bonuses: Bonuses[];

    provider: Company;
}
