import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {MessageService} from '@src/app/_services/message.service';
import {catchError, map, share, tap} from 'rxjs/operators';
import {ApiConnections} from '@src/app/_models/api/connections';
import {ApiProtocols} from '@src/app/_models/api/protocols';
import {ApiTypes} from '@src/app/_models/api/types';
import {ApiHeaders} from '@src/app/_models/api/headers';
import {ApiPaths} from '@src/app/_models/api/paths';

import moment from 'moment';
import localeCs from '@angular/common/locales/cs';
import {registerLocaleData} from '@angular/common';
import {ApiMapsAssignations} from '@src/app/_models/api/maps_assignations';
import {EnvironmentService} from '@src/app/_services/environment.service';

moment.updateLocale('cs', {workingWeekdays: [1, 2, 3, 4, 5]});
registerLocaleData(localeCs);

const httpOptions = {
    headers: new HttpHeaders({'Content-Type': 'application/json'}),
};

@Injectable({
    providedIn: 'root',
})
export class ApisService {
    private readonly apisUrl: string;

    private readonly apiProtocolsUrl: string;

    private readonly apiTypesUrl: string;

    private readonly apiHeadersUrl: string;

    private readonly apiPathsUrl: string;

    private readonly apisCheckUrl: string;

    private readonly apisMapUrl: string;

    private readonly resultConnectionsSource = new BehaviorSubject<ApiConnections[]>([]);
    allowedApis = this.resultConnectionsSource.asObservable();
    private readonly resultRemoteSource = new BehaviorSubject<unknown>(null);
    remoteSource = this.resultRemoteSource.asObservable();

    constructor(
        private readonly http: HttpClient,
        private readonly messageService: MessageService,
        private readonly environmentService: EnvironmentService,
    ) {
        this.apisUrl = this.environmentService.backendURL + '/api/apis/connections';
        this.apiProtocolsUrl = this.environmentService.backendURL + '/api/apis/protocols';
        this.apiTypesUrl = this.environmentService.backendURL + '/api/apis/types';
        this.apiHeadersUrl = this.environmentService.backendURL + '/api/apis/headers';
        this.apiPathsUrl = this.environmentService.backendURL + '/api/apis/paths';
        this.apisCheckUrl = this.environmentService.backendURL + '/api/apis/check';
        this.apisMapUrl = this.environmentService.backendURL + '/api/apis/map';
    }

    private static mapsSort(maps: ApiMapsAssignations[]): ApiMapsAssignations[] {
        maps = maps.sort((a, b) => {
            const compA = a.level;
            const compB = b.level;

            return compA > compB ? 1 : -1;
        });

        return maps;
    }

    create(api: unknown): Observable<ApiConnections> {
        return this.http.post<ApiConnections>(this.apisUrl, api, httpOptions);
    }

    createPath(path: unknown): Observable<ApiPaths> {
        return this.http.post<ApiPaths>(this.apiPathsUrl, path, httpOptions);
    }

    createHeader(header: unknown): Observable<ApiHeaders> {
        return this.http.post<ApiHeaders>(this.apiHeadersUrl, header, httpOptions);
    }

    getApiProtocols(): Observable<ApiProtocols[] | undefined> {
        return this.http.get<ApiProtocols[]>(this.apiProtocolsUrl)
            .pipe(
                tap(() => {
                    this.log('fetched api protocols');
                }),
                catchError(this.handleError<ApiProtocols[]>('getApiProtocols')),
                share(),
            );
    }

    getApiTypes(): Observable<ApiTypes[] | undefined> {
        return this.http.get<ApiTypes[]>(this.apiTypesUrl)
            .pipe(
                tap(() => {
                    this.log('fetched api types');
                }),
                catchError(this.handleError<ApiTypes[]>('getApiTypes')),
                share(),
            );
    }

    getApiHeaders(): Observable<ApiHeaders[] | undefined> {
        return this.http.get<ApiHeaders[]>(this.apiHeadersUrl)
            .pipe(
                tap(() => {
                    this.log('fetched api headers');
                }),
                catchError(this.handleError<ApiHeaders[]>('getApiHeaders')),
                share(),
            );
    }

    getApiPaths(): Observable<ApiPaths[] | undefined> {
        return this.http.get<ApiPaths[]>(this.apiPathsUrl)
            .pipe(
                tap(() => {
                    this.log('fetched api paths');
                }),
                catchError(this.handleError<ApiPaths[]>('getApiPaths')),
                share(),
            );
    }

    getApiMaps(api: ApiConnections | number): Observable<ApiConnections | undefined> {
        const id = typeof api === 'number' ? api : api.id;
        const url = `${this.apisMapUrl}/search/${id}`;

        return this.http.get<ApiConnections>(url)
            .pipe(
                tap(() => {
                    this.log(`fetched plugin id=${id}`);
                }),
                share(), // this tells Rx to cache the latest emitted
                catchError(this.handleError<ApiConnections>(`getPlugin id=${id}`)),
            );
    }

    updateApi(api: ApiConnections | number): Observable<ApiConnections> {
        const id = typeof api === 'number' ? api : api.id;
        const url = `${this.apisUrl}/update/${id}`;

        return this.http.put<ApiConnections>(url, api, httpOptions);
    }

    updateApiHeader(header: ApiHeaders | number): Observable<ApiHeaders> {
        const id = typeof header === 'number' ? header : header.id;
        const url = `${this.apiHeadersUrl}/update/${id}`;

        return this.http.put<ApiHeaders>(url, header, httpOptions);
    }

    updateApiPath(path: ApiPaths | number): Observable<ApiPaths> {
        const id = typeof path === 'number' ? path : path.id;
        const url = `${this.apiPathsUrl}/update/${id}`;

        return this.http.put<ApiPaths>(url, path, httpOptions);
    }

    removeApi(api: ApiConnections): Observable<ApiConnections | undefined> | undefined {
        if (!api.deleted_by) {
            console.error('deleted_by is missing...');

            return;
        }

        if (typeof api.deleted_by !== 'undefined') {
            const headers = new HttpHeaders({'Content-Type': 'application/json'});
            const params = new HttpParams().set('deleted_by', api.deleted_by.toString());
            const url = `${this.apisUrl}/${api.id}`;

            return this.http.delete<ApiConnections>(url, {headers, params});
        }
    }

    removeApiHeader(header: ApiHeaders): Observable<ApiHeaders | undefined> | undefined {
        if (!header.deleted_by) {
            console.error('deleted_by is missing...');

            return;
        }

        if (typeof header.deleted_by !== 'undefined') {
            const headers = new HttpHeaders({'Content-Type': 'application/json'});
            const params = new HttpParams().set('deleted_by', header.deleted_by.toString());
            const url = `${this.apiHeadersUrl}/${header.id}`;

            return this.http.delete<ApiHeaders>(url, {headers, params});
        }
    }

    removeApiPath(path: ApiPaths): Observable<ApiPaths | undefined> | undefined {
        if (!path.deleted_by) {
            console.error('deleted_by is missing...');

            return;
        }

        if (typeof path.deleted_by !== 'undefined') {
            const headers = new HttpHeaders({'Content-Type': 'application/json'});
            const params = new HttpParams().set('deleted_by', path.deleted_by.toString());
            const url = `${this.apiPathsUrl}/${path.id}`;

            return this.http.delete<ApiPaths>(url, {headers, params});
        }
    }

    // eslint-disable-next-line max-params-no-constructor/max-params-no-constructor,complexity,max-lines-per-function
    getRemoteData(
        path: ApiPaths | number,
        maps?: ApiMapsAssignations[],
        data?: unknown,
        query?: string,
    ): Observable<unknown> {
        const id = typeof path === 'number' ? path : path.id;
        const queryString = query ? encodeURI(query)
            .replace('+', '%2B') : '';
        const url = `${this.apiPathsUrl}/${id}`;

        if (queryString.length > 0) {
            const headers = new HttpHeaders({'Content-Type': 'application/json'});
            const params = new HttpParams().set('querystring', queryString);

            return this.http.post<unknown[]>(url, data ? data : [], {headers, params})
                .pipe(
                    map((result: unknown[]) => {
                        let resultData: unknown = [];

                        if (typeof maps !== 'undefined' && maps.length > 0) {
                            maps = ApisService.mapsSort(maps);

                            if (maps.length === 1) {
                                resultData = result[maps[0].field.field_name];
                            }

                            if (maps.length === 2) {
                                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                                resultData = result[maps[0].field.field_name][maps[1].field.field_name];
                            }
                        } else {
                            resultData = result;
                        }

                        return resultData;
                    }),
                    tap(() => {
                        this.log(`fetched plugin id=${id}`);
                    }),
                    share(), // this tells Rx to cache the latest emitted
                    catchError(this.handleError<unknown>(`getPlugin id=${id}`)),
                );
        }

        return this.http.post<unknown[]>(url, data ? data : [])
            .pipe(
                map((result: unknown[]) => {
                    let resultData: unknown[] = [];

                    if (typeof maps !== 'undefined' && maps.length > 0) {
                        ApisService.mapsSort(maps);
                    } else {
                        resultData = result;
                    }

                    return resultData;
                }),
                tap(() => {
                    this.log(`fetched plugin id=${id}`);
                }),
                share(), // this tells Rx to cache the latest emitted
                catchError(this.handleError<unknown>(`getPlugin id=${id}`)),
            );
    }

    isAvailable(api: ApiConnections | number): Observable<ApiConnections | undefined> {
        const id = typeof api === 'number' ? api : api.id;
        const url = `${this.apisCheckUrl}/${id}`;

        return this.http.get<ApiConnections>(url)
            .pipe(
                tap(() => {
                    this.log(`fetched api id=${id}`);
                }),
                share(), // this tells Rx to cache the latest emitted
                catchError(this.handleError<ApiConnections>(`getApi id=${id}`)),
            );
    }

    checkConnections(apis: ApiConnections[] | undefined): void {
        console.info('INFO: start checking apis states..');

        if (typeof apis !== 'undefined') {
            for (const api of apis) {
                const index = apis.indexOf(api);

                if (api.active) {
                    this.isAvailable(api)
                        .subscribe((state: ApiConnections) => {
                            apis[index].state = !!state;
                            apis[index].last_check = moment()
                                .format('YYYY-MM-DD HH:mm:ss');
                        });
                }
            }

            this.resultConnectionsSource.next(apis);
        } else {
            console.info('INFO: any stored apis for check..');
        }
    }

    /**
     * Handle Http operation that failed.
     * Let the app continue.
     * @param operation - name of the operation that failed
     * @param result - optional value to return as the observable result
     */
    private handleError<T>(operation = 'operation', result?: T) {
        return (error: {[key: string]: string}): Observable<T | undefined> => {
            // TODO: send the error to remote logging infrastructure
            console.error(error); // log to console instead

            // TODO: better job of transforming error for user consumption
            this.log(`${operation} failed: ${error.message}`);

            // Let the app keep running by returning an empty result.
            return of(result);
        };
    }

    /** Log a vacationService message with the MessageService */
    private log(message: string): void {
        this.messageService.addNotification(`Vacation: ${message}`, false);
    }
}
