import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    HostListener,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import {AddressConnectedField} from '@src/app/_models/services/address-connected-field';
import {DialogComponent} from '@syncfusion/ej2-angular-popups';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {User} from '@src/app/_models/user/user';
import {DataService} from '@src/app/_services/data.service';
import {DataNetbaseService} from '@src/app/_services/data-netbase.service';
import {
    RuianInput,
    ServicesService,
    SmartAddress,
} from '@src/app/features/services/services.service';
import {MessageService} from '@src/app/_services/message.service';
import {AuthenticationService} from '@src/app/_services/authentication.service';
import {Observable, Subject} from 'rxjs';
import {ComponentCanDeactivate} from '@src/app/_guards/changes.guard';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {Department} from '@src/app/_models/department/department';
import {Company} from '@src/app/_models/company/company';
import {NetPackages} from '@src/app/_models/services/net-packages';
import {TvServices} from '@src/app/_models/services/tv-services';
import {TechnologyTypes} from '@src/app/_models/services/technology-types';
import {UnitTypes} from '@src/app/_models/services/unit-types';
import {SpeedTypes} from '@src/app/_models/services/speed-types';
import {DealTypes} from '@src/app/_models/services/deal-types';
import {Discounts} from '@src/app/_models/services/discounts';
import {SpaceTypes} from '@src/app/_models/services/space-types';
import {TransferTypes} from '@src/app/_models/services/transfer-types';
import {Project} from '@src/app/_models/projects/project';
import {EmitType} from '@syncfusion/ej2-base';
import {
    DropDownListComponent,
    FilteringEventArgs,
    MultiSelectComponent,
} from '@syncfusion/ej2-angular-dropdowns';
import {Query} from '@syncfusion/ej2-data';
import {PermissionService} from '@src/app/_services/permission.service';
import {AddressConnectedRuian} from '@src/app/_models/services/address-connected-ruian';
import moment from 'moment';
import {ActivatedRoute, Router} from '@angular/router';
import {MomentInput} from 'moment/moment';

declare let smartform;
declare let window;
declare let document;

@UntilDestroy()
@Component({
    selector: 'app-check-ruian-form',
    templateUrl: './check-ruian-form.component.html',
    styleUrls: ['./check-ruian-form.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckRuianFormComponent
    implements OnInit, OnChanges, ComponentCanDeactivate, OnDestroy {
    // Dropdowns
    fieldFieldObj: object = {text: 'label', value: 'value'};

    height = '240px';

    departmentSelect: Array<{value: boolean | number | string; label: string}> = [];

    usersSelect: Array<{value: boolean | number | string; label: string}> = [];

    typeSelect: Array<{value: boolean | number | string; label: string}> = [];

    viewSelect: Array<{value: boolean | number | string; label: string}> = [];

    companySelect: Array<{value: boolean | number | string; label: string}> = [];

    technologySelect: Array<{value: boolean | number | string; label: string}> = [];

    unitSelect: Array<{value: boolean | number | string; label: string}> = [];

    dealSelect: Array<{value: boolean | number | string; label: string}> = [];

    discountSelect: Array<{value: boolean | number | string; label: string}> = [];

    transferSelect: Array<{value: boolean | number | string; label: string}> = [];

    spaceSelect: Array<{value: boolean | number | string; label: string}> = [];

    servicesNetSelect: Array<{value: boolean | number | string; label: string}> = [];

    servicesTvSelect: Array<{value: boolean | number | string; label: string}> = [];

    projectSelect: Array<{value: boolean | number | string; label: string}> = [];

    categorySelect: Array<{value: boolean | number | string; label: string}> = [];

    speedsSelect: Array<{value: boolean | number | string; label: string}> = [];

    // Forms
    checkRuianForm: FormGroup;

    isDirty = false;

    isChecked = false;

    submited = false;

    currentTab = 0;

    hidden = true;

    // Variables
    currentUser: User | null;

    ruians: AddressConnectedRuian[] = [];

    ruianAddress?: AddressConnectedRuian | boolean | null = null;

    smartWholeAddress = null;

    validRuian: boolean | null = null;

    copyAddressFromMapy = false;

    // Loaders
    loadingField = false;

    // Subscriptions
    routerSubject: Subject<string> = new Subject<string>();

    @Input() field: AddressConnectedField | null = null;

    @Input() isVisible = false;

    @Output() readonly checkRuianFormDialogState = new EventEmitter<boolean>();

    // Dialogs
    @ViewChild('formDialog') formDialogObj: DialogComponent;

    // Dropdowns
    @ViewChild('chck_creator') chckCreatorObj: DropDownListComponent;

    @ViewChild('chck_type') chckTypeObj: DropDownListComponent;

    @ViewChild('chck_deal_type') chckDealTypeObj: DropDownListComponent;

    @ViewChild('chck_unit_type') chckUnitTypeObj: DropDownListComponent;

    @ViewChild('chck_provider') chckProviderObj: DropDownListComponent;

    @ViewChild('chck_discount') chckDiscountObj: DropDownListComponent;

    @ViewChild('chck_flat_space') chckFlatSpaceObj: DropDownListComponent;

    @ViewChild('chck_speed_type') chckSpeedTypeObj: DropDownListComponent;

    @ViewChild('chck_hardware') chckHardwareObj: DropDownListComponent;

    @ViewChild('chck_technology') chckTechnologyObj: DropDownListComponent;

    @ViewChild('chck_transfer') chckTransferObj: DropDownListComponent;

    @ViewChild('chck_project') chckProjectObj: DropDownListComponent;

    @ViewChild('chck_net_packages') chckNetPackagesObj: MultiSelectComponent;

    @ViewChild('chck_tv_services') chckTvServicesObj: MultiSelectComponent;

    constructor(
        private readonly formBuilder: FormBuilder,
        private readonly ref: ChangeDetectorRef,
        private readonly router: Router,
        private readonly route: ActivatedRoute,
        private readonly dataService: DataService,
        private readonly dataNetbaseService: DataNetbaseService,
        private readonly servicesService: ServicesService,
        private readonly messages: MessageService,
        private readonly permissionService: PermissionService,
        private readonly authenticationService: AuthenticationService,
    ) {
        this.currentUser = this.authenticationService.currentUserValue;
        window.routerSubject = this.routerSubject;
    }

    get f(): {[key: string]: AbstractControl} {
        return this.checkRuianForm.controls;
    }

    private checkRuianUnique(ruian): AddressConnectedRuian | boolean {
        if (this.ruians.length > 0) {
            // eslint-disable-next-line eqeqeq
            const match = this.ruians.find(addr => addr.ruian == parseInt(ruian as string, 10));

            return typeof match !== 'undefined' ? match : false;
        }

        return false;
    }

    @HostListener('window:beforeunload')
    canDeactivate(): Observable<boolean> | boolean {
        return !this.isDirty;
    }

    reinitSmartForm(): void {
        console.info('Reinitialize SF objects...');
        smartform.rebindAllForms(false, () => {
            if (smartform.getInstance('smartform-instance-ruian')) {
                const instanceCreate = smartform.getInstance('smartform-instance-ruian');

                instanceCreate.setZIndex(100000000);
                instanceCreate.setSelectionBackgroundColor('#4285f3');
                instanceCreate.addAnnounceCallback((validationType, addressArray) => {
                    if (validationType === smartform.ValidationResultType.HIT) {
                        const addressData: SmartAddress = {};

                        this.smartWholeAddress = addressArray[0].ADDRESS_WHOLE;
                        addressData.oriental_number = parseInt(
                            addressArray[0].NUMBER_ORIENTACNI as string,
                            10,
                        );

                        addressData.house_number = parseInt(
                            addressArray[0].NUMBER_POPISNE as string,
                            10,
                        );
                        addressData.street = addressArray[0].STREET;
                        addressData.street_code = parseInt(
                            addressArray[0].STREET_CODE as string,
                            10,
                        );
                        addressData.city_part = addressArray[0].PART;
                        addressData.city_part_code = parseInt(
                            addressArray[0].PART_CODE as string,
                            10,
                        );
                        addressData.city = addressArray[0].CITY;
                        addressData.city_code = parseInt(addressArray[0].CITY_CODE as string, 10);
                        addressData.zip = parseInt(addressArray[0].ZIP as string, 10);
                        addressData.district = addressArray[0].DISTRICT;
                        addressData.district_code = parseInt(
                            addressArray[0].DISTRICT_CODE as string,
                            10,
                        );
                        addressData.region = addressArray[0].REGION;
                        addressData.region_code = parseInt(
                            addressArray[0].REGION_CODE as string,
                            10,
                        );

                        addressData.number_of_units = parseInt(
                            addressArray[0].NUMBER_OF_FLATS as string,
                            10,
                        );

                        addressData.number_of_storeys = parseInt(
                            addressArray[0].NUMBER_OF_STOREYS as string,
                            10,
                        );
                        addressData.lift = addressArray[0].BUILDING_WITH_LIFT === 'true';
                        addressData.state = addressArray[0].COUNTRY;
                        addressData.gps = `${addressArray[0].GPS_LAT as string} / ${
                            addressArray[0].GPS_LONG as string
                        }`;

                        addressData.jstk = `${addressArray[0].COORD_X as string} / ${
                            addressArray[0].COORD_Y as string
                        }`;
                        this.addressValidator(addressData);
                        this.checkRuianForm.controls.ruian.setValue(addressArray[0].CODE, {
                            emitEvent: true,
                        });
                        // this.addressValidator(addressData);
                    } else {
                        this.checkRuianForm.controls.ruian.setValue(0, {emitEvent: true});
                    }

                    this.ref.markForCheck();
                });
            }
        });
    }

    // eslint-disable-next-line max-lines-per-function
    ngOnInit(): void {
        this.dataService.departmentSource.pipe(untilDestroyed(this))
            .subscribe(
                (data: Department[]) => {
                    data.map((department: Department) => {
                        this.departmentSelect = [
                            ...this.departmentSelect,
                            {
                                value: department.id,
                                label: `${department.company.name} - ${department.name}`,
                            },
                        ];
                    });
                },
                error => {
                    console.error(error);
                },
            );

        this.dataService.userSource.pipe(untilDestroyed(this))
            .subscribe(
                (data: User[]) => {
                    data.map((user: User) => {
                        if (user.authorized && !user.deleted_date) {
                            this.usersSelect = [
                                ...this.usersSelect,
                                {
                                    value: user.id,
                                    label: `${user.firstname} ${user.secondname}`,
                                },
                            ];
                        }
                    });
                },
                error => {
                    console.error(error);
                },
            );

        this.dataService.companySource.pipe(untilDestroyed(this))
            .subscribe(
                (companies: Company[]) => {
                    this.companySelect = [];
                    companies.map((company: Company) => {
                        if (!company.deleted_date) {
                            this.companySelect = [
                                ...this.companySelect,
                                {
                                    value: company.id,
                                    label: company.name,
                                },
                            ];
                        }
                    });
                    this.ref.markForCheck();
                },
                error => {
                    console.error(error);
                },
            );

        this.dataNetbaseService.netPackagesSource.pipe(untilDestroyed(this))
            .subscribe(
                (netTarifs: NetPackages[]) => {
                    this.servicesNetSelect = [];
                    netTarifs.map((netTarif: NetPackages) => {
                        if (!netTarif.deleted_date) {
                            this.servicesNetSelect = [
                                ...this.servicesNetSelect,
                                {
                                    value: netTarif.id,
                                    label: `${netTarif.name}${
                                        netTarif.transfer.connection_method === 'optical cable'
                                            ? ' - optické připojení'
                                            : netTarif.transfer.connection_method === 'metalic cable'
                                                ? ' - kabelové připojení'
                                                : netTarif.transfer.connection_method === 'radio'
                                                    ? ' - rádiové připojení'
                                                    : '- xDSL připojení'
                                    }`,
                                },
                            ];
                        }
                    });
                    this.ref.markForCheck();
                },
                error => {
                    console.error(error);
                },
            );

        this.dataNetbaseService.tvServicesSource.pipe(untilDestroyed(this))
            .subscribe(
                (tvServices: TvServices[]) => {
                    this.servicesTvSelect = [];
                    tvServices.map((tvService: TvServices) => {
                        if (!tvService.deleted_date) {
                            this.servicesTvSelect = [
                                ...this.servicesTvSelect,
                                {
                                    value: tvService.id,
                                    label: `${tvService.name}${
                                        tvService.transfer.connection_method === 'optical cable'
                                            ? ' - optické připojení'
                                            : tvService.transfer.connection_method === 'metalic cable'
                                                ? ' - kabelové připojení'
                                                : tvService.transfer.connection_method === 'radio'
                                                    ? ' - rádiové připojení'
                                                    : '- xDSL připojení'
                                    }`,
                                },
                            ];
                        }
                    });
                    this.ref.markForCheck();
                },
                error => {
                    console.error(error);
                },
            );

        this.dataNetbaseService.technologyTypesSource.pipe(untilDestroyed(this))
            .subscribe(
                (technologies: TechnologyTypes[]) => {
                    this.technologySelect = [];
                    technologies.map((technology: TechnologyTypes) => {
                        if (!technology.deleted_date) {
                            this.technologySelect = [
                                ...this.technologySelect,
                                {value: technology.id, label: technology.name},
                            ];
                        }
                    });
                    this.ref.markForCheck();
                },
                error => {
                    console.error(error);
                },
            );

        this.dataNetbaseService.unitTypesSource.pipe(untilDestroyed(this))
            .subscribe(
                (units: UnitTypes[]) => {
                    this.unitSelect = [];
                    units.map((unit: UnitTypes) => {
                        if (!unit.deleted_date) {
                            this.unitSelect = [
                                ...this.unitSelect,
                                {value: unit.id, label: unit.name},
                            ];
                        }
                    });
                    this.ref.markForCheck();
                },
                error => {
                    console.error(error);
                },
            );

        this.dataNetbaseService.speedTypesSource.pipe(untilDestroyed(this))
            .subscribe(
                (speeds: SpeedTypes[]) => {
                    this.speedsSelect = [];
                    speeds.sort((a, b) => {
                        const aValue = a.speed_until;
                        const bValue = b.speed_until;

                        return aValue > bValue ? -1 : 1;
                    });

                    speeds.map((speed: SpeedTypes) => {
                        if (!speed.deleted_date) {
                            this.speedsSelect = [
                                ...this.speedsSelect,
                                {
                                    value: speed.id,
                                    label: `${speed.speed_until} Mbit - ${
                                        speed.speed_to > 0 ? speed.speed_to : '*'
                                    } Mbit`,
                                },
                            ];
                        }
                    });
                    this.ref.markForCheck();
                },
                error => {
                    console.error(error);
                },
            );

        this.dataNetbaseService.dealTypesSource.pipe(untilDestroyed(this))
            .subscribe(
                (deals: DealTypes[]) => {
                    this.dealSelect = [];
                    deals.map((deal: DealTypes) => {
                        if (!deal.deleted_date) {
                            this.dealSelect = [
                                ...this.dealSelect,
                                {value: deal.id, label: `${deal.age} měsíců`},
                            ];
                        }
                    });
                    this.ref.markForCheck();
                },
                error => {
                    console.error(error);
                },
            );

        this.dataNetbaseService.discountSource.pipe(untilDestroyed(this))
            .subscribe(
                (discounts: Discounts[]) => {
                    this.discountSelect = [];
                    discounts.map((discount: Discounts) => {
                        if (!discount.deleted_date) {
                            this.discountSelect = [
                                ...this.discountSelect,
                                {value: discount.id, label: `${discount.discount}%`},
                            ];
                        }
                    });
                    this.ref.markForCheck();
                },
                error => {
                    console.error(error);
                },
            );

        this.dataNetbaseService.spaceTypesSource.pipe(untilDestroyed(this))
            .subscribe(
                (spaces: SpaceTypes[]) => {
                    this.spaceSelect = [];
                    spaces.map((space: SpaceTypes) => {
                        if (!space.deleted_date) {
                            this.spaceSelect = [
                                ...this.spaceSelect,
                                {
                                    value: space.id,
                                    label: `${space.metric_until} m2 - ${
                                        space.metric_to ? space.metric_to : '*'
                                    } m2`,
                                },
                            ];
                        }
                    });
                    this.ref.markForCheck();
                },
                error => {
                    console.error(error);
                },
            );

        this.dataNetbaseService.transferTypesSource.pipe(untilDestroyed(this))
            .subscribe(
                (transfers: TransferTypes[]) => {
                    this.transferSelect = [];
                    transfers.map((transfer: TransferTypes) => {
                        if (!transfer.deleted_date) {
                            this.transferSelect = [
                                ...this.transferSelect,
                                {
                                    value: transfer.id,
                                    label: transfer.name,
                                },
                            ];
                        }
                    });
                    this.ref.markForCheck();
                },
                error => {
                    console.error(error);
                },
            );

        this.dataService.projectSource.pipe(untilDestroyed(this))
            .subscribe(
                (projects: Project[]) => {
                    this.projectSelect = [];
                    projects.map((project: Project) => {
                        if (!project.deleted_date) {
                            this.projectSelect = [
                                ...this.projectSelect,
                                {
                                    value: project.id,
                                    label: project.name,
                                },
                            ];
                        }
                    });
                    this.ref.markForCheck();
                },
                error => {
                    console.error(error);
                },
            );

        this.dataNetbaseService.ruianAddressSource.pipe(untilDestroyed(this))
            .subscribe(
                (addresses: AddressConnectedRuian[]) => {
                    this.ruians = addresses;
                },
                error => {
                    console.error(error);
                },
            );

        this.checkRuianForm = this.formBuilder.group({
            address: ['', Validators.required],
            ruian: [null, Validators.required],
            provider_id: [null, Validators.required],
            deal_id: [null],
            unit_id: [null],
            space_id: [null],
            speed_id: [null],
            project_id: [null],
            discount_id: [null],
            transfer_id: [null, Validators.required],
            technology_id: [null, Validators.required],
            keys: [false],
            electrical_counter: [''],
            clip_frame: [false],
            business_note: [''],
            descriptive: [''],
            marketingnote: [''],
            marketing_ads: [''],
            owner_name: [''],
            manager_name: [''],
            number_of_units: [
                {value: 1, disabled: true},
                Validators.compose([Validators.required, Validators.min(1), Validators.max(1000)]),
            ],
            number_of_storeys: [
                {value: 0, disabled: true},
                Validators.compose([Validators.required, Validators.min(0), Validators.max(1000)]),
            ],
            lift: [{value: false, disabled: true}],
            realized_at: [null],
            net_packages: [null, Validators.required],
            tv_services: [null],
            oriental_number: [{value: null, disabled: true}],
            house_number: [{value: null, disabled: true}, Validators.required],
            street: [{value: null, disabled: true}],
            street_code: [{value: null, disabled: true}],
            city: [{value: null, disabled: true}, Validators.required],
            city_code: [{value: null, disabled: true}],
            city_part: [{value: null, disabled: true}],
            city_part_code: [{value: null, disabled: true}],
            zip: [{value: null, disabled: true}, Validators.required],
            district: [{value: null, disabled: true}, Validators.required],
            district_code: [{value: null, disabled: true}],
            region: [{value: null, disabled: true}, Validators.required],
            region_code: [{value: null, disabled: true}],
            state: [{value: 'Česká republika', disabled: true}, Validators.required],
            gps: [{value: null, disabled: true}],
            jstk: [{value: null, disabled: true}],
        });

        this.checkRuianForm
            .get('ruian')
            ?.valueChanges
            .pipe(untilDestroyed(this))
            .subscribe(value => {
                if (value > 0) {
                    this.ruianValidator(value);
                }
            });

        this.checkRuianForm
            .get('provider_id')
            ?.valueChanges
            .pipe(untilDestroyed(this))
            .subscribe(value => {
                if (value) {
                    this.dataNetbaseService.netPackagesSource.pipe(untilDestroyed(this))
                        .subscribe(
                            (netTarifs: NetPackages[]) => {
                                this.servicesNetSelect = [];
                                this.f.net_packages.patchValue([]);
                                netTarifs.map((netTarif: NetPackages) => {
                                    if (
                                        !netTarif.deleted_date &&
                                        this.f.transfer_id.value === netTarif.transfer_id &&
                                        value === netTarif.provider_id
                                    ) {
                                        this.servicesNetSelect = [
                                            ...this.servicesNetSelect,
                                            {
                                                value: netTarif.id,
                                                label: `${netTarif.name}${
                                                    netTarif.transfer.connection_method ===
                                                    'optical cable'
                                                        ? ' - optické připojení'
                                                        : netTarif.transfer.connection_method ===
                                                        'metalic cable'
                                                            ? ' - kabelové připojení'
                                                            : netTarif.transfer.connection_method ===
                                                            'radio'
                                                                ? ' - rádiové připojení'
                                                                : '- xDSL připojení'
                                                }`,
                                            },
                                        ];
                                    }
                                });
                                this.ref.markForCheck();
                            },
                            error => {
                                console.error(error);
                            },
                        );

                    this.dataNetbaseService.tvServicesSource.pipe(untilDestroyed(this))
                        .subscribe(
                            (tvServices: TvServices[]) => {
                                this.servicesTvSelect = [];
                                this.f.tv_services.patchValue([]);
                                tvServices.map((tvService: TvServices) => {
                                    if (
                                        !tvService.deleted_date &&
                                        this.f.transfer_id.value === tvService.transfer_id &&
                                        value === tvService.provider_id
                                    ) {
                                        this.servicesTvSelect = [
                                            ...this.servicesTvSelect,
                                            {
                                                value: tvService.id,
                                                label: `${tvService.name}${
                                                    tvService.transfer.connection_method ===
                                                    'optical cable'
                                                        ? ' - optické připojení'
                                                        : tvService.transfer.connection_method ===
                                                        'metalic cable'
                                                            ? ' - kabelové připojení'
                                                            : tvService.transfer.connection_method ===
                                                            'radio'
                                                                ? ' - rádiové připojení'
                                                                : '- xDSL připojení'
                                                }`,
                                            },
                                        ];
                                    }
                                });
                                this.ref.markForCheck();
                            },
                            error => {
                                console.error(error);
                            },
                        );
                }
            });

        this.checkRuianForm
            .get('transfer_id')
            ?.valueChanges
            .pipe(untilDestroyed(this))
            .subscribe(value => {
                if (value) {
                    this.dataNetbaseService.netPackagesSource.pipe(untilDestroyed(this))
                        .subscribe(
                            (netTarifs: NetPackages[]) => {
                                this.servicesNetSelect = [];
                                this.f.net_packages.patchValue([]);
                                netTarifs.map((netTarif: NetPackages) => {
                                    if (
                                        !netTarif.deleted_date &&
                                        this.f.provider_id.value === netTarif.provider_id &&
                                        value === netTarif.transfer_id
                                    ) {
                                        this.servicesNetSelect = [
                                            ...this.servicesNetSelect,
                                            {
                                                value: netTarif.id,
                                                label: `${netTarif.name}${
                                                    netTarif.transfer.connection_method ===
                                                    'optical cable'
                                                        ? ' - optické připojení'
                                                        : netTarif.transfer.connection_method ===
                                                        'metalic cable'
                                                            ? ' - kabelové připojení'
                                                            : netTarif.transfer.connection_method ===
                                                            'radio'
                                                                ? ' - rádiové připojení'
                                                                : '- xDSL připojení'
                                                }`,
                                            },
                                        ];
                                    }
                                });
                                this.ref.markForCheck();
                            },
                            error => {
                                console.error(error);
                            },
                        );

                    this.dataNetbaseService.tvServicesSource.pipe(untilDestroyed(this))
                        .subscribe(
                            (tvServices: TvServices[]) => {
                                this.servicesTvSelect = [];
                                this.f.tv_services.patchValue([]);
                                tvServices.map((tvService: TvServices) => {
                                    if (
                                        !tvService.deleted_date &&
                                        this.f.provider_id.value === tvService.provider_id &&
                                        value === tvService.transfer_id
                                    ) {
                                        this.servicesTvSelect = [
                                            ...this.servicesTvSelect,
                                            {
                                                value: tvService.id,
                                                label: `${tvService.name}${
                                                    tvService.transfer.connection_method ===
                                                    'optical cable'
                                                        ? ' - optické připojení'
                                                        : tvService.transfer.connection_method ===
                                                        'metalic cable'
                                                            ? ' - kabelové připojení'
                                                            : tvService.transfer.connection_method ===
                                                            'radio'
                                                                ? ' - rádiové připojení'
                                                                : '- xDSL připojení'
                                                }`,
                                            },
                                        ];
                                    }
                                });
                                this.ref.markForCheck();
                            },
                            error => {
                                console.error(error);
                            },
                        );
                }
            });

        this.routerSubject.pipe(untilDestroyed(this))
            .subscribe((url: string) => {
                void this.router.navigateByUrl(url);
            });

        this.route.queryParams.pipe(untilDestroyed(this))
            .subscribe(params => {
                if (params.mapPharse) {
                    document.addEventListener('copy', (e: ClipboardEvent) => {
                        e.clipboardData?.setData('text/plain', params.mapPharse as string);
                        e.preventDefault();
                        document.removeEventListener('copy', null);
                    });
                    document.execCommand('copy');

                    const options = {
                        progressBar: true,
                        timeOut: 5000,
                        toastClass: 'success',
                    };

                    this.messages.showInfo('Adresa úspěšně zkopírována do schránky', '', options);
                    this.copyAddressFromMapy = true;
                }

                this.ref.markForCheck();
            });

        this.checkRuianForm.valueChanges.pipe(untilDestroyed(this))
            .subscribe(() => {
                this.isDirty = this.checkRuianForm.dirty;
                this.showInputErrors();
            });
    }

    ngOnChanges(): void {
        if (this.isVisible && this.field) {
            this.reinitSmartForm();
            this.resetForm();
            this.formDialogObj.header = `Parcela #${this.field.id} - ${this.field.address}`;
            this.showInputErrors();
        }
    }

    changeDialogState(value: boolean): void {
        this.checkRuianFormDialogState.emit(value);
    }

    nextBtn(): void {
        if (this.currentTab === 0) {
            this.currentTab++;
            this.nextpre(this.currentTab);
        }
    }

    previousBtn(): void {
        this.currentTab--;
        this.nextpre(this.currentTab);
    }

    nextpre(current): void {
        this.hidden = current !== 1;
        this.ref.markForCheck();
    }

    onFilteringTvPackages: EmitType<FilteringEventArgs> = (e: FilteringEventArgs) => {
        if (e.text === '') {
            e.updateData(this.servicesTvSelect);
        } else {
            let query: Query = new Query();

            query = e.text !== '' ? query.where('label', 'contains', e.text, true, true) : query;
            e.updateData(this.servicesTvSelect, query);
        }
    };

    onFilteringNetPackages: EmitType<FilteringEventArgs> = (e: FilteringEventArgs) => {
        if (e.text === '') {
            e.updateData(this.servicesNetSelect);
        } else {
            let query: Query = new Query();

            query = e.text !== '' ? query.where('label', 'contains', e.text, true, true) : query;
            e.updateData(this.servicesNetSelect, query);
        }
    };

    getRuianForField(): void {
        this.checkRuianForm.reset();
        this.validRuian = null;
        this.ruianAddress = null;
        this.smartWholeAddress = null;
        this.currentTab = 0;
        this.hidden = true;
        this.formDialogObj.header = 'Zjistit RUIAN na parcele';
        this.formDialogObj.show();

        let address = '';

        address += this.field?.address_obj?.street ? `${this.field.address_obj.street}, ` : '';

        address += this.field?.address_obj?.zip ? `${this.field.address_obj.zip} ` : '';

        address += this.field?.address_obj?.city ? this.field.address_obj.city : '';
        this.checkRuianForm.get('address')
            ?.patchValue(address);
        this.showInputErrors();
    }

    ruianValidator(ruianCode): void {
        this.ruianAddress = this.checkRuianUnique(ruianCode);

        if (this.ruianAddress instanceof AddressConnectedRuian) {
            this.validRuian = !!this.ruianAddress.ruian;

            if (this.validRuian) {
                this.formDialogObj.header = `Připojená RUIAN adresa #${this.ruianAddress.id} - ${this.ruianAddress.address}`;
                this.fillTheForm();
                this.showInputErrors();
            }
        }
    }

    addressValidator(address): void {
        if (address) {
            this.checkRuianForm.controls.oriental_number.patchValue(address.oriental_number);
            this.checkRuianForm.controls.house_number.patchValue(address.house_number);
            this.checkRuianForm.controls.street.patchValue(address.street);
            this.checkRuianForm.controls.street_code.patchValue(address.street_code);
            this.checkRuianForm.controls.city_part.patchValue(address.city_part);
            this.checkRuianForm.controls.city_part_code.patchValue(address.city_part_code);
            this.checkRuianForm.controls.city.patchValue(address.city);
            this.checkRuianForm.controls.city_code.patchValue(address.city_code);
            this.checkRuianForm.controls.zip.patchValue(address.zip);
            this.checkRuianForm.controls.district.patchValue(address.district);
            this.checkRuianForm.controls.district_code.patchValue(address.district_code);
            this.checkRuianForm.controls.region.patchValue(address.region);
            this.checkRuianForm.controls.region_code.patchValue(address.region_code);
            this.checkRuianForm.controls.state.patchValue(address.state);
            this.checkRuianForm.controls.gps.patchValue(address.gps);
            this.checkRuianForm.controls.jstk.patchValue(address.jstk);
            this.checkRuianForm.controls.zip.patchValue(address.zip);
            this.checkRuianForm.controls.lift.patchValue(address.lift);
            this.checkRuianForm.controls.number_of_units.patchValue(
                address.number_of_units ? address.number_of_units : 1,
            );

            this.checkRuianForm.controls.number_of_storeys.patchValue(
                address.number_of_storeys ? address.number_of_storeys : 1,
            );
        }
    }

    resetForm(): void {
        console.info('form reset');
        this.checkRuianForm.controls.address.reset();
        this.checkRuianForm.controls.ruian.reset();
        this.checkRuianForm.controls.electrical_counter.reset();
        this.checkRuianForm.controls.business_note.reset();
        this.checkRuianForm.controls.descriptive.reset();
        this.checkRuianForm.controls.marketingnote.reset();
        this.checkRuianForm.controls.marketing_ads.reset();
        this.checkRuianForm.controls.owner_name.reset();
        this.checkRuianForm.controls.manager_name.reset();
        this.checkRuianForm.controls.number_of_units.reset();
        this.checkRuianForm.controls.realized_at.reset();
        this.checkRuianForm.controls.provider_id.reset();
        this.checkRuianForm.controls.net_packages.reset();
        this.checkRuianForm.controls.tv_services.reset();
        this.checkRuianForm.controls.unit_id.reset();
        this.checkRuianForm.controls.deal_id.reset();
        this.checkRuianForm.controls.discount_id.reset();
        this.checkRuianForm.controls.space_id.reset();
        this.checkRuianForm.controls.project_id.reset();
        this.checkRuianForm.controls.technology_id.reset();
        this.checkRuianForm.controls.transfer_id.reset();
        this.checkRuianForm.controls.state.patchValue('Česká republika');
        this.checkRuianForm.controls.number_of_units.patchValue(1);
        this.checkRuianForm.controls.number_of_storeys.patchValue(0);
        this.checkRuianForm.controls.keys.patchValue(false);
        this.checkRuianForm.controls.clip_frame.patchValue(false);
        this.checkRuianForm.controls.lift.patchValue(false);
    }

    fillTheForm(): void {
        if (this.ruianAddress && this.ruianAddress instanceof AddressConnectedRuian) {
            const netPackages: number[] = [];
            const tvServices: number[] = [];

            this.ruianAddress.net_packages.forEach((netPackage: NetPackages) => {
                netPackages.push(netPackage.id);
            });

            this.ruianAddress.tv_services?.forEach((tvService: TvServices) => {
                tvServices.push(tvService.id);
            });
            this.checkRuianForm.controls.address.patchValue(this.ruianAddress.address);

            if (!this.validRuian) {
                this.checkRuianForm.controls.ruian.patchValue(this.ruianAddress.ruian);
            }

            this.checkRuianForm.controls.provider_id.patchValue(this.ruianAddress.provider_id);
            this.checkRuianForm.controls.deal_id.patchValue(this.ruianAddress.deal_id);
            this.checkRuianForm.controls.unit_id.patchValue(this.ruianAddress.unit_id);
            this.checkRuianForm.controls.space_id.patchValue(this.ruianAddress.space_id);
            this.checkRuianForm.controls.speed_id.patchValue(this.ruianAddress.speed_id);
            this.checkRuianForm.controls.project_id.patchValue(this.ruianAddress.project_id);
            this.checkRuianForm.controls.discount_id.patchValue(this.ruianAddress.discount_id);
            this.checkRuianForm.controls.transfer_id.patchValue(this.ruianAddress.transfer_id);
            this.checkRuianForm.controls.technology_id.patchValue(this.ruianAddress.technology_id);
            this.checkRuianForm.controls.keys.patchValue(this.ruianAddress.keys);
            this.checkRuianForm.controls.electrical_counter.patchValue(
                this.ruianAddress.electrical_counter,
            );
            this.checkRuianForm.controls.clip_frame.patchValue(this.ruianAddress.clip_frame);
            this.checkRuianForm.controls.business_note.patchValue(this.ruianAddress.business_note);
            this.checkRuianForm.controls.descriptive.patchValue(this.ruianAddress.descriptive);
            this.checkRuianForm.controls.marketingnote.patchValue(this.ruianAddress.marketingnote);
            this.checkRuianForm.controls.marketing_ads.patchValue(this.ruianAddress.marketing_ads);
            this.checkRuianForm.controls.owner_name.patchValue(this.ruianAddress.owner_name);
            this.checkRuianForm.controls.manager_name.patchValue(this.ruianAddress.manager_name);
            this.checkRuianForm.controls.number_of_units.patchValue(
                this.ruianAddress.number_of_units,
            );

            this.checkRuianForm.controls.number_of_storeys.patchValue(
                this.ruianAddress.number_of_storeys,
            );
            this.checkRuianForm.controls.lift.patchValue(this.ruianAddress.lift);
            this.checkRuianForm.controls.realized_at.patchValue(this.ruianAddress.realized_at);
            this.checkRuianForm.controls.net_packages.patchValue(netPackages);
            this.checkRuianForm.controls.tv_services.patchValue(tvServices);
        }
    }

    onSubmit(): void {
        this.submited = true;
        this.isDirty = false;
        this.loadingField = true;
        this.createRuianFromField();
    }

    createRuianFromField(): void {
        if (this.checkRuianForm.invalid) {
            console.error('form is not valid!');

            return;
        }

        if (this.currentUser && this.permissionService.checkUserISServiceAdmin(this.currentUser)) {
            const data: RuianInput = {
                keys: !!this.f.keys.value,
                electrical_counter: this.f.electrical_counter.value,
                clip_frame: !!this.f.clip_frame.value,
                business_note: this.f.business_note.value,
                descriptive: this.f.descriptive.value,
                marketingnote: this.f.marketingnote.value,
                marketing_ads: this.f.marketing_ads.value,
                owner_name: this.f.owner_name.value,
                manager_name: this.f.manager_name.value,
                number_of_units: this.f.number_of_units.value,
                ruian: this.f.ruian.value,
                address: this.smartWholeAddress,
                provider_id: this.f.provider_id.value,
                project_id: this.f.project_id.value,
                realized_at: this.f.realized_at.value
                    ? moment(this.f.realized_at.value as MomentInput)
                        .format('YYYY-MM-DD HH:mm:ss')
                    : null,
                unit_id: this.f.unit_id.value,
                deal_id: this.f.deal_id.value,
                discount_id: this.f.discount_id.value,
                space_id: this.f.space_id.value,
                transfer_id: this.f.transfer_id.value,
                net_packages: this.f.net_packages.value,
                tv_services: this.f.tv_services.value,
                technology_id: this.f.technology_id.value,
                created_by: this.currentUser.id,
                valid_ruian: !!this.f.ruian.value,
                oriental_number: this.f.oriental_number.value,
                house_number: this.f.house_number.value,
                street: this.f.street.value,
                street_code: this.f.street_code.value,
                city: this.f.city.value,
                city_code: this.f.city_code.value,
                city_part: this.f.city_part.value,
                city_part_code: this.f.city_part_code.value,
                district: this.f.district.value,
                district_code: this.f.district_code.value,
                region: this.f.region.value,
                region_code: this.f.region_code.value,
                state: this.f.state.value,
                gps: this.f.gps.value,
                jstk: this.f.jstk.value,
                speed_id: this.f.speed_id.value,
                number_of_storeys: this.f.number_of_storeys.value,
                lift: this.f.lift.value,
                zip: this.f.zip.value,
            };

            this.servicesService
                .addAddressRuian(data)
                .pipe(untilDestroyed(this))
                .subscribe(
                    (ruian: AddressConnectedRuian) => {
                        const body = `Adresa RUIAN #${ruian.id}`;
                        const options = {
                            progressBar: true,
                            timeOut: 5000,
                            toastClass: 'success',
                        };

                        this.messages.showSuccess('Adresa RUIAN úspěšně přidána', body, options);
                        this.loadingField = false;
                        this.dataNetbaseService.setFieldAddressDataSource();
                        this.formDialogObj.hide();
                        this.ref.markForCheck();
                    },
                    error => {
                        console.error(error);

                        const body = 'Zkuste to později ...';
                        const options = {
                            progressBar: true,
                            timeOut: 5000,
                            toastClass: 'red',
                        };

                        this.messages.showError(
                            'Chyba během přidávání adresy RUIAN',
                            body,
                            options,
                        );
                        this.loadingField = false;
                        this.ref.markForCheck();
                    },
                );
        } else {
            const body = 'Adresu RUIAN nemůžete vytvořit';
            const options = {progressBar: true, timeOut: 5000};

            this.messages.showError('Nedostatečné oprávnění', body, options);
            this.loadingField = false;
            this.formDialogObj.hide();
            this.ref.markForCheck();
        }
    }

    showInputErrors(): void {
        this.isChecked = true;
        /* console.log('VALID: ' + this.ticketForm.valid);
        Object.keys(this.ticketForm.controls).forEach(key => {
            const controlErrors: ValidationErrors = this.ticketForm.get(key).errors;
            if (controlErrors != null) {
                Object.keys(controlErrors).forEach(keyError => {
                    console.log('Key control: ' + key + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError]);
                });
            }
        });*/
        this.checkRuianForm.markAllAsTouched();
        this.ref.markForCheck();
    }

    ngOnDestroy(): void {
        this.routerSubject.unsubscribe();
    }
}
