import {AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {MOBILE_IMPORT_TYPE} from '@src/app/features/sync/types/import-type.enum';

@UntilDestroy()
@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'app-crossroad',
    templateUrl: './crossroad.component.html',
    styleUrls: ['./crossroad.component.scss'],
})
export class CrossroadComponent implements OnInit, AfterViewInit {
    renderMobileImport = false;

    mobileImportType?: keyof typeof MOBILE_IMPORT_TYPE;

    constructor(
        private readonly router: Router,
        private readonly ref: ChangeDetectorRef,
        private readonly route: ActivatedRoute,
    ) {
    }

    ngOnInit(): void {

    }

    ngAfterViewInit(): void {
        this.route.queryParamMap.pipe(untilDestroyed(this))
            .subscribe(queryParams => {
                if (queryParams.get('view') === 'import') {
                    if (queryParams.get('type') === 'tmcz') {
                        this.renderMobileImport = true;
                        this.mobileImportType = 'TMCZ';
                    } else if (queryParams.get('type') === 'vdf') {
                        this.renderMobileImport = true;
                        this.mobileImportType = 'VDF';
                    }
                }

                const newParams: Params = {view: null, type: null};

                void this.router.navigate([], {
                    relativeTo: this.route,
                    queryParams: newParams,
                    queryParamsHandling: 'merge',
                });
            });
    }
}
