import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnInit,
    ViewChild,
} from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {registerLocaleData} from '@angular/common';
import {Observable, Observer} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthenticationService} from '@src/app/_services/authentication.service';
import {ExcelService} from '@src/app/_services/excel.service';
import {DataService} from '@src/app/_services/data.service';
import {DataNetbaseService} from '@src/app/_services/data-netbase.service';
import {
    HardwareConfigurationInput,
    ServicesService,
} from '@src/app/features/services/services.service';
import {PermissionService} from '@src/app/_services/permission.service';
import {MessageService} from '@src/app/_services/message.service';
import {User} from '@src/app/_models/user/user';
import {HardwareConfig} from '@src/app/_models/services/hardware-config';
import moment from 'moment';
import localeCs from '@angular/common/locales/cs';
import {ButtonPropsModel, DialogComponent} from '@syncfusion/ej2-angular-popups';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {ChangeEventArgs, DropDownListComponent} from '@syncfusion/ej2-angular-dropdowns';
import {
    ColumnModel,
    DataResult,
    EditSettingsModel,
    ExcelExportProperties,
    FilterSettingsModel,
    GridComponent,
    GroupSettingsModel,
    PageSettingsModel,
    RowDataBoundEventArgs,
    SelectionSettingsModel,
    ToolbarItems,
} from '@syncfusion/ej2-angular-grids';
import {TextBoxComponent} from '@syncfusion/ej2-angular-inputs';
import {ClickEventArgs} from '@syncfusion/ej2-angular-navigations';

moment.updateLocale('cs', {workingWeekdays: [1, 2, 3, 4, 5]});
registerLocaleData(localeCs);

@UntilDestroy()
@Component({
    selector: 'app-services-hw-configurator',
    templateUrl: './services-hw-configurator.component.html',
    styleUrls: ['./services-hw-configurator.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ServicesHwConfiguratorComponent implements OnInit {
    // Grid
    currencyFormat: {format: string} = {format: '#,###.00\',- CZK\''};

    dateFormat: string;

    toolbar: ToolbarItems[] | object;

    data: object;

    fields: object = {text: 'label', value: 'value'};

    filterSettings: FilterSettingsModel;

    filterCheckboxSettings: FilterSettingsModel;

    selectionSettings: SelectionSettingsModel;

    editSettings: EditSettingsModel;

    groupOptions: GroupSettingsModel;

    pageOptions: PageSettingsModel;

    expendituresColumnsPrice: ColumnModel[];

    expendituresColumnsItems: ColumnModel[];

    filterDate: object;

    sortOptions: object;

    requiredRules: object;

    exportCurrentPage = false;

    badgesColumns: ColumnModel[];

    // Dialogs
    renderHardwareConfiguratorForm = false;

    buttonsDelete: ButtonPropsModel[] = [
        {
            click: (): void => {
                this.deleteHardwareConfig();
            },
            isFlat: false,
            buttonModel: {
                content: 'OK',
                cssClass: 'e-success e-outline',
            },
        },
        {
            click: (): void => {
                this.confirmationDelete.hide();
            },
            buttonModel: {
                content: 'ZRUŠIT',
                cssClass: 'e-danger e-outline',
            },
        },
    ];

    buttonsCancel: ButtonPropsModel[] = [
        {
            click: (): void => {
                this.confirmationCancel.hide();
            },
            buttonModel: {
                isPrimary: true,
                content: 'OK',
            },
        },
    ];

    // Dropdowns
    height = '240px';

    categoryFilterData: Array<string> = [];

    // Forms
    isCreate = false;

    isUpdate = false;

    isCopy = false;

    // Variables
    currentUser: User | null;

    today = moment()
        .format('YYYY-MM-DD HH:mm:ss');

    configs: HardwareConfig[] | undefined = [];

    headElements = [
        {value: 'basics', label: 'Základ'},
        {value: 'cabels', label: 'Kabeláž'},
        {value: 'garden', label: 'Zahrada'},
        {value: 'roaming', label: 'Wi-Fi Roaming'},
        {value: 'unit', label: 'Typ bytové jednotky'},
        {value: 'space', label: 'Prostor bytové jednotky'},
        {value: 'speed', label: 'Limit rychlosti'},
        {value: 'creator', label: 'Vytvořil'},
        {value: 'created_date', label: 'Vytvořeno'},
        {value: 'updated_date', label: 'Poslední změna'},
        {value: 'deleted_date', label: 'Smazáno'},
    ];

    clickedRow: HardwareConfig[] = [];

    clickedRow$ = new Observable<HardwareConfig[]>((observer: Observer<HardwareConfig[]>) => {
        observer.next(this.clickedRow);
    });

    // Loaders
    tableLoad = true;

    // Grid
    @ViewChild('configsGrid') gridHardwareConfig: GridComponent;

    @ViewChild('searchtext') searchtextObj: TextBoxComponent;

    @ViewChild('templateBasicsBadges') templateBasicsBadges: string;

    @ViewChild('templateCabelsBadges') templateCabelsBadges: string;

    @ViewChild('templateGardenBadges') templateGardenBadges: string;

    @ViewChild('templateRoamingBadges') templateRoamingBadges: string;

    // Dialogs
    @ViewChild('confirmationDelete') confirmationDelete: DialogComponent;

    @ViewChild('confirmationCancel') confirmationCancel: DialogComponent;

    // Dropdowns
    @ViewChild('viewFilter') viewFilterInstance: DropDownListComponent;

    constructor(
        private readonly router: Router,
        private readonly route: ActivatedRoute,
        private readonly ref: ChangeDetectorRef,
        private readonly formBuilder: FormBuilder,
        private readonly authenticationService: AuthenticationService,
        private readonly excelService: ExcelService,
        private readonly dataService: DataService,
        private readonly dataNetbaseService: DataNetbaseService,
        private readonly servicesService: ServicesService,
        private readonly permissionService: PermissionService,
        private readonly messages: MessageService,
    ) {
        this.currentUser = this.authenticationService.currentUserValue;
    }

    ngOnInit(): void {
        this.filterSettings = {
            type: 'Menu',
            showFilterBarStatus: true,
            ignoreAccent: true,
        };

        this.filterCheckboxSettings = {
            type: 'CheckBox',
            showFilterBarStatus: true,
            ignoreAccent: true,
        };

        this.filterSettings = {
            type: 'Excel',
            showFilterBarOperator: true,
            showFilterBarStatus: true,
            ignoreAccent: true,
        };

        this.selectionSettings = {
            persistSelection: true,
            type: 'Multiple',
            checkboxOnly: true,
        };

        this.toolbar = [
            {
                text: 'Občerstvit data',
                tooltipText: 'Občerstvit data',
                prefixIcon: 'e-refresh',
                id: 'customRefresh',
            },
            'Print',
            'ExcelExport',
            'Add',
            'Edit',
            {
                text: 'Smazat',
                tooltipText: 'Smazat konfiguraci',
                prefixIcon: 'e-remove',
                id: 'delete',
            },
            {
                text: 'Kopírovat',
                tooltipText: 'Kopírovat konfiguraci',
                prefixIcon: 'e-copy',
                id: 'copy',
            },
        ];

        this.categoryFilterData = [
            'všechny konfigurace',
            'aktivní konfigurace',
            'neaktivní konfigurace',
        ];
        this.pageOptions = {pageSize: 50};
        this.dateFormat = 'dd.MM.yyyy HH:mm';
        this.filterDate = {type: 'Menu', params: {format: this.dateFormat}};
        this.groupOptions = {
            showGroupedColumn: true,
            disablePageWiseAggregates: false,
        };

        this.editSettings = {
            showConfirmDialog: false,
            showDeleteConfirmDialog: false,
            allowEditing: true,
            allowAdding: true,
            allowDeleting: true,
            allowEditOnDblClick: false,
            mode: 'Dialog',
        };
        this.requiredRules = {required: true};

        this.badgesColumns = [
            {
                field: 'basics',
                headerText: 'Základy',
                filter: this.filterSettings,
                width: 60,
                textAlign: 'Center',
                minWidth: 40,
                clipMode: 'EllipsisWithTooltip',
                autoFit: true,
                template: this.templateBasicsBadges,
            },
            {
                field: 'cabels',
                headerText: 'Kabeláž',
                filter: this.filterSettings,
                width: 60,
                textAlign: 'Center',
                minWidth: 40,
                clipMode: 'EllipsisWithTooltip',
                autoFit: true,
                template: this.templateCabelsBadges,
            },
            {
                field: 'garden',
                headerText: 'Zahrada',
                filter: this.filterSettings,
                width: 60,
                textAlign: 'Center',
                minWidth: 40,
                clipMode: 'EllipsisWithTooltip',
                autoFit: true,
                template: this.templateGardenBadges,
            },
            {
                field: 'roaming',
                headerText: 'Roaming',
                filter: this.filterSettings,
                width: 60,
                textAlign: 'Center',
                minWidth: 40,
                clipMode: 'EllipsisWithTooltip',
                autoFit: true,
                template: this.templateRoamingBadges,
            },
        ];

        this.dataService.setDepartmentDataSource();
        this.dataNetbaseService.setHardwareConfigDataSource();
    }

    loadData(): DataResult[] {
        this.gridHardwareConfig.clearRowSelection();

        console.info('NEW DATA BOUND');

        const elements: HardwareConfigurationInput[] = [];

        if (this.configs && this.configs.length > 0) {
            this.configs.map((hardware: HardwareConfig) => {
                elements.push({
                    id: hardware.id,
                    basics: hardware.basics ? 'ANO' : 'NE',
                    cabels: hardware.cabels ? 'ANO' : 'NE',
                    garden: hardware.garden ? 'ANO' : 'NE',
                    roaming: hardware.roaming ? 'ANO' : 'NE',
                    unit: hardware.unit.name,
                    space: `${hardware.space.metric_until} - ${hardware.space.metric_to} m2`,
                    hardware: hardware.hardware.name,
                    speed: `${hardware.speed.speed_until} - ${hardware.speed.speed_to} Mbit`,
                    // eslint-disable-next-line max-len
                    creator: `<img src="assets/img${hardware.creator.personalphoto}" class="img-fluid rounded-circle avatar-xs mr-1" alt="profilový obrázek">${hardware.creator.secondname} ${hardware.creator.firstname}`,
                    created_date: new Date(hardware.created_date),
                    updated_date: hardware.updated_date ? new Date(hardware.updated_date) : null,
                    deleted_date: hardware.deleted_date ? new Date(hardware.deleted_date) : null,
                });
            });
        }

        this.tableLoad = false;
        this.ref.markForCheck();

        return elements as DataResult[];
    }

    onCreated(): void {
        this.disableToolBarBtn();
    }

    setInitialGridFiltering(): void {
        this.gridHardwareConfig.clearFiltering();
        // this.gridHardwareConfig.filterByColumn('created_by', 'equal', this.currentUser.id, 'and', false, true);
        // this.viewFilterInstance.value = 'moje žádanky - všechny';
        this.ref.markForCheck();
    }

    onLoad(): void {
        const wrapper1 = document.getElementById('scroll_wrapper1_configs');
        const wrapper2 = document.getElementById('scroll_wrapper2_configs');

        if (wrapper1 && wrapper2) {
            wrapper1.onscroll = (): void => {
                wrapper2.scrollLeft = wrapper1.scrollLeft;
            };

            wrapper2.onscroll = (): void => {
                wrapper1.scrollLeft = wrapper2.scrollLeft;
            };
        }

        this.dataNetbaseService.hardwareConfigSource.pipe(untilDestroyed(this))
            .subscribe({
                next: (configs: HardwareConfig[] | undefined) => {
                    this.configs = configs;

                    if (this.configs && this.configs.length > 0) {
                        this.data = this.gridHardwareConfig.dataSource = this.loadData();
                        this.sortOptions = {
                            columns: [
                                {field: 'created_date', direction: 'Descending'},
                                {
                                    field: 'id',
                                    direction: 'Descending',
                                },
                            ],
                        };
                    }
                },
                error: error => {
                    console.error(error);
                    this.tableLoad = false;
                    this.ref.markForCheck();
                },
            });
    }

    search(): void {
        this.gridHardwareConfig.search(this.searchtextObj.value);
    }

    onDataBound(): void {
        this.rowSelected();

        const width = document.getElementById('configsGrid_content_table')?.offsetWidth;
        const parent1 = document.getElementById('scroll_div_configs');
        const parent2 = document.getElementById('grid_parent_configs');

        if (parent1 && parent2 && width) {
            parent1.style.width = `${width}px`;
            parent2.style.width = `${width}px`;
        }

        this.gridHardwareConfig.autoFitColumns();
        this.ref.markForCheck();
    }

    rowDataBound(args: RowDataBoundEventArgs): void {
        const data = args.data as HardwareConfig;

        if (args.row && data.deleted_date) {
            args.row.getElementsByClassName('e-gridchkbox')[0].classList.add('disablecheckbox');
            args.row
                .getElementsByClassName('e-checkbox-wrapper')[0]
                .classList.add('disablecheckbox');
            args.row.classList.add('e-disabled');
        }

        if (
            this.currentUser &&
            args.row &&
            data.created_by !== this.currentUser.id &&
            !this.permissionService.checkUserISServiceAdmin(this.currentUser) &&
            !this.permissionService.checkUserISAdministrative(this.currentUser) &&
            !this.permissionService.checkUserISMarketingAdmin(this.currentUser)
        ) {
            args.row.getElementsByClassName('e-gridchkbox')[0].classList.add('disablecheckbox');
            args.row
                .getElementsByClassName('e-checkbox-wrapper')[0]
                .classList.add('disablecheckbox');
        }

        this.ref.markForCheck();
    }

    onStatusFilterChange(e: ChangeEventArgs): void {
        if (e.value === 'všechny konfigurace') {
            this.gridHardwareConfig.clearFiltering();
        } else if (e.value === 'aktivní konfigurace') {
            this.gridHardwareConfig.filterSettings = {
                columns: [
                    {
                        field: 'deleted_date',
                        operator: 'equal',
                        value: null as unknown as string,
                        predicate: 'and',
                        matchCase: false,
                        ignoreAccent: true,
                    },
                ],
            };
        } else if (e.value === 'neaktivní konfigurace') {
            this.gridHardwareConfig.clearFiltering();
            this.gridHardwareConfig.filterByColumn(
                'deleted_date',
                'notequal',
                null,
                'and',
                false,
                true,
            );
        }
    }

    cancelBtnClick(): void {
        this.searchtextObj.value = '';
        this.gridHardwareConfig.searchSettings.key = '';
    }

    toolbarClick(args: ClickEventArgs): void {
        const xlsProp: ExcelExportProperties = {
            fileName: 'js_configs_export.xlsx',
            enableFilter: true,
            exportType: this.exportCurrentPage ? 'CurrentPage' : 'AllPages',
        };

        if (args.item.id === 'configsGrid_excelexport') {
            void this.gridHardwareConfig.excelExport(xlsProp);
        } else if (args.item.id === 'delete') {
            if (this.gridHardwareConfig.getSelectedRecords().length !== 0) {
                this.confirmationDelete.content = `Potvrďte smazání ${this.clickedRow.length} konfigurací`;
                this.confirmationDelete.show();
            } else {
                this.confirmationCancel.content = 'Musíte vybrat alespoň jednu konfiguraci';
                this.confirmationCancel.show();
            }
        } else if (args.item.id === 'copy') {
            if (this.gridHardwareConfig.getSelectedRecords().length !== 0) {
                this.isUpdate = false;
                this.isCreate = false;
                this.isCopy = true;
                this.renderHardwareConfiguratorForm = true;
                this.ref.markForCheck();
            } else {
                this.confirmationCancel.content = 'Musíte vybrat alespoň jednu konfiguraci';
                this.confirmationCancel.show();
            }
        } else if (args.item.id === 'customRefresh') {
            this.tableLoad = true;
            console.info('refresh DATA!');
            this.ref.markForCheck();
            this.dataNetbaseService.clearHardwareConfigCache();
            this.dataNetbaseService.setHardwareConfigDataSource();
        }
    }

    enableToolbarBtn(): void {
        this.gridHardwareConfig.toolbarModule.enableItems(
            ['configsGrid_edit', 'delete', 'copy'],
            true,
        );
    }

    disableToolBarBtn(): void {
        this.gridHardwareConfig.toolbarModule.enableItems(
            ['configsGrid_edit', 'delete', 'copy'],
            false,
        );
    }

    rowSelected(): void {
        this.clickedRow = [];

        const selectedrecords: HardwareConfig[] =
            this.gridHardwareConfig.getSelectedRecords() as HardwareConfig[];

        if (selectedrecords.length > 0) {
            selectedrecords.map((row: HardwareConfig) => {
                const tempData: HardwareConfig | undefined = this.configs?.find(
                    x => x.id === row.id,
                );

                if (tempData) {
                    this.clickedRow.push(tempData);
                }

                this.ref.markForCheck();
            });

            this.clickedRow$ = new Observable<HardwareConfig[]>(
                (observer: Observer<HardwareConfig[]>) => {
                    observer.next(this.clickedRow);
                },
            );
            this.enableToolbarBtn();
        } else {
            this.disableToolBarBtn();
            this.clickedRow$ = new Observable<HardwareConfig[]>(
                (observer: Observer<HardwareConfig[]>) => {
                    observer.next(this.clickedRow);
                },
            );
        }
    }

    actionBegin(args): void {
        if (args.requestType === 'add' || args.requestType === 'beginEdit') {
            args.cancel = true;

            if (args.requestType === 'beginEdit') {
                this.isUpdate = true;
                this.isCreate = false;
                this.isCopy = false;
            }

            if (args.requestType === 'add') {
                this.isCreate = true;
                this.isUpdate = false;
                this.isCopy = false;
            }

            this.renderHardwareConfiguratorForm = true;
            this.ref.markForCheck();
        }
    }

    actionComplete(args): void {
        if (args.requestType === 'beginEdit' || args.requestType === 'add') {
            args.form.ej2_instances[0].rules = {};
        }
    }

    addHardwareConfig(): void {
        if (
            this.currentUser &&
            (this.permissionService.checkUserISServiceAdmin(this.currentUser) ||
                this.permissionService.checkUserISAdministrative(this.currentUser) ||
                this.permissionService.checkUserISMarketingAdmin(this.currentUser))
        ) {
            this.renderHardwareConfiguratorForm = true;
            this.isCreate = true;
            this.isUpdate = false;
            this.isCopy = false;
        } else {
            const body = 'Nemáte oprávnění provést tuto akci...';
            const options = {progressBar: true, timeOut: 5000};

            this.messages.showError('Nedostatečné oprávnění', body, options);
            this.ref.markForCheck();
        }
    }

    editHardwareConfig(): void {
        if (
            this.currentUser &&
            (this.permissionService.checkUserISServiceAdmin(this.currentUser) ||
                this.permissionService.checkUserISAdministrative(this.currentUser) ||
                this.permissionService.checkUserISMarketingAdmin(this.currentUser))
        ) {
            this.renderHardwareConfiguratorForm = true;
            this.isCreate = false;
            this.isUpdate = true;
            this.isCopy = false;
        } else {
            const body = 'Nemáte oprávnění provést tuto akci...';
            const options = {progressBar: true, timeOut: 5000};

            this.messages.showError('Nedostatečné oprávnění', body, options);
            this.ref.markForCheck();
        }
    }

    deleteHardwareConfig(): void {
        if (
            this.currentUser &&
            (this.permissionService.checkUserISServiceAdmin(this.currentUser) ||
                this.permissionService.checkUserISAdministrative(this.currentUser) ||
                this.permissionService.checkUserISMarketingAdmin(this.currentUser))
        ) {
            this.tableLoad = true;
            this.clickedRow[0].deleted_by = this.currentUser.id;
            this.servicesService
                .deleteHardwareConfig(this.clickedRow[0])
                ?.pipe(untilDestroyed(this))
                .subscribe({
                    next: (config: HardwareConfig) => {
                        const body = `Konfigurace #${config.id}`;
                        const options = {
                            progressBar: true,
                            timeOut: 5000,
                            toastClass: 'success',
                        };

                        this.messages.showSuccess('Konfigurace úspěšně smazána', body, options);
                        this.dataNetbaseService.setHardwareConfigDataSource();
                        this.confirmationDelete.hide();
                        this.ref.markForCheck();
                    },
                    error: error => {
                        console.error(error);

                        const body = 'Zkuste to později ...';
                        const options = {
                            progressBar: true,
                            timeOut: 5000,
                            toastClass: 'red',
                        };

                        this.messages.showError('Chyba během mazání konfigurace', body, options);
                        this.tableLoad = false;
                        this.confirmationDelete.hide();
                        this.ref.markForCheck();
                    },
                });
        } else {
            const body = 'Nemáte oprávnění provést tuto akci...';
            const options = {progressBar: true, timeOut: 5000};

            this.messages.showError('Nedostatečné oprávnění', body, options);
            this.ref.markForCheck();
        }
    }
}
