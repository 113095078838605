<ejs-toolbar
  #moduleToolbar
  id="moduleToolbar"
  overflowMode="Scrollable"
  scrollStep="300"
>
  <e-items>
    <e-item
      [align]="'center'"
      tooltipText="vytvořit kancelář"
      type="button"
    >
      <ng-template #template>
        <button
          (click)="navigateToTab('grid', 'addOffice')"
          class="e-btn e-primary btn-p-zero e-small"
          ejs-button
          title="vytvořit kancelář"
          type="button"
        >
          <em class="fa-light fa-lg fa-plus mr-2"></em>vytvořit
          kancelář
        </button>
      </ng-template>
    </e-item>
    <e-item type="Separator"></e-item>
    <e-item [align]="'center'" tooltipText="seznam kanceláří" type="button">
      <ng-template #template>
        <button
          (click)="navigateToTab('grid', null)"
          class="e-btn e-warning btn-p-zero e-small"
          ejs-button
          title="seznam kanceláří"
          type="button"
        >
          <em class="fa-light fa-lg fa-list mr-2"></em>seznam
          kanceláří
        </button>
      </ng-template>
    </e-item>
  </e-items>
</ejs-toolbar>
<ejs-tab
  #officesTab
  (created)="onCreate()"
  (selected)="select($event)"
  (selecting)="preventSwipe($event)"
  enablePersistence="true"
  height="auto"
  heightAdjustMode="Content"
  id="officesTab"
>
  <e-tabitems>
    <e-tabitem [header]="headerText[0]">
      <ng-template #content>
        <div class="control-section">
          <div class="card bg-transparent">
            <div
              class="e-card-header d-flex justify-content-between p-3"
            >
                            <span>
                                <em
                                  aria-hidden="true"
                                  class="fa-light rounded fa-chair-office bg-primary fa-2x p-2 text-white mr-2"
                                ></em
                                >Kanceláře
                            </span>
            </div>
            <div class="e-card-content text-center p-0 m-0">
              <div *ngIf="loadingOffice" class="row">
                <div class="col-12 text-center">
                  <small class="text-muted"
                  >načítám data</small
                  >
                </div>
              </div>
              <div
                *ngIf="!loadingOffice"
                class="table-responsive p-2"
                style="max-height: 400px; overflow-y: auto"
              >
                <table class="table mb-1">
                  <tbody>
                  <tr>
                    <th class="text-left">
                      celkem:
                      {{ offices.length }} kanceláří
                    </th>
                  </tr>
                  <tr>
                    <td class="text-left"
                    >S možností rezervovat:
                    </td>
                    <td class="text-right">
                      {{ bookableOfficesCount }}
                      kanceláří
                    </td>
                  </tr>
                  <tr>
                    <td class="text-left"
                    >Bez možností rezervovat:
                    </td>
                    <td class="text-right">
                      {{
                        offices.length -
                        bookableOfficesCount
                      }}
                      kanceláří
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div id="dashBoardParent" style="width: 100% !important">
            <ejs-dashboardlayout
              #defaultDashboard
              [allowDragging]="true"
              [allowResizing]="true"
              [cellAspectRatio]="cellAspectRatio"
              [cellSpacing]="cellSpacing"
              [enablePersistence]="false"
              columns="4"
              id="vacReports_dashboard"
            >
              <e-panels>
                <e-panel
                  [col]="0"
                  [row]="0"
                  [sizeX]="4"
                  [sizeY]="1"
                >
                  <ng-template #content>
                    <em
                      (click)="onCloseIconHandler($event)"
                      aria-hidden="true"
                      class="fa-light fa-lg fa-time"
                    ></em>
                    <div
                      class="e-panel-container scrollbar scrollbar-indigo thin p-2"
                    >
                      <div
                        *ngIf="loadingOffice"
                        class="col-12"
                      >
                        <div class="col-12 text-center">
                          <small class="text-muted"
                          >načítám data</small
                          >
                        </div>
                      </div>
                      <div
                        *ngIf="!loadingOffice"
                        style="display: block"
                      >
                        <ejs-chart
                          #officesChart
                          [legendSettings]="
                                                        legendSettings
                                                    "
                          [margin]="{
                                                        left: 5,
                                                        right: 5,
                                                        top: 5,
                                                        bottom: 5
                                                    }"
                          [palettes]="palette"
                          [primaryXAxis]="
                                                        primaryXAxis
                                                    "
                          [primaryYAxis]="
                                                        primaryYAxis
                                                    "
                          [tooltip]="tooltip"
                          id="officesChart"
                        >
                          <e-series-collection>
                            <e-series
                              [animation]="
                                                                chartAnimation
                                                            "
                              [dataSource]="
                                                                chartDataOpen
                                                            "
                              name="Open-office"
                              type="Column"
                              xName="state"
                              yName="offices"
                            ></e-series>
                            <e-series
                              [animation]="
                                                                chartAnimation
                                                            "
                              [dataSource]="
                                                                chartDataShare
                                                            "
                              name="Sdílená kancelář"
                              type="Column"
                              xName="state"
                              yName="offices"
                            ></e-series>
                            <e-series
                              [animation]="
                                                                chartAnimation
                                                            "
                              [dataSource]="
                                                                chartDataPrivate
                                                            "
                              name="Soukromá kancelář"
                              type="Column"
                              xName="state"
                              yName="offices"
                            ></e-series>
                            <e-series
                              [animation]="
                                                                chartAnimation
                                                            "
                              [dataSource]="
                                                                chartDataMeeting
                                                            "
                              name="Zasedačka"
                              type="Column"
                              xName="state"
                              yName="offices"
                            ></e-series>
                          </e-series-collection>
                        </ejs-chart>
                      </div>
                    </div>
                  </ng-template>
                </e-panel>
                <e-panel
                  [col]="0"
                  [row]="1"
                  [sizeX]="2"
                  [sizeY]="1"
                >
                  <ng-template #content>
                    <em
                      (click)="onCloseIconHandler($event)"
                      aria-hidden="true"
                      class="fa-light fa-lg fa-time"
                    ></em>
                    <div
                      class="e-panel-container scrollbar scrollbar-indigo thin p-2"
                    >
                      <div class="control-section">
                        <ejs-textbox
                          (keyup)="
                                                        onFilterCategories(
                                                            $event
                                                        )
                                                    "
                          id="filterCategoriesList"
                          placeholder="Filtrovat kategorie..."
                          title="Napiš název"
                        ></ejs-textbox>
                        <ejs-listview
                          #listviewCategories
                          [dataSource]="
                                                        dataCategories
                                                    "
                          [fields]="fields"
                          [headerTitle]="
                                                        headerTitleCategories
                                                    "
                          [showHeader]="true"
                          [showIcon]="true"
                          id="listviewCategories"
                        >
                          <ng-template
                            #template
                            let-dataCategories=""
                          >
                            <div
                              class="d-flex justify-content-between"
                            >
                              <div
                                class="e-list-wrapper e-list-multi-line"
                              >
                                <em
                                  class="{{
                                                                        dataCategories.icon
                                                                    }} align-middle mr-2"
                                ></em>
                                <strong
                                  class="e-list-item-header mr-2"
                                >{{
                                    dataCategories.text
                                  }}</strong
                                >
                                <small
                                  *ngIf="
                                                                        !dataCategories.office_id
                                                                    "
                                  class="timeStamp text-muted"
                                >{{
                                    dataCategories.child
                                      ? dataCategories
                                        .child
                                        .length
                                      :0
                                  }}
                                  kanceláří</small
                                >
                              </div>
                              <span>
                                                                <em
                                                                  *ngIf="
                                                                        !dataCategories.office_id
                                                                    "
                                                                  class="fa-light fa-lg fa-chevron-right align-middle"
                                                                ></em>
                                                                <a
                                                                  *ngIf="
                                                                        dataCategories.office_id
                                                                    "
                                                                  [routerLink]="[
                                                                        '/booking/cars/detail/',
                                                                        dataCategories.office_id
                                                                    ]"
                                                                ><em
                                                                  class="fa-light fa-lg fa-search"
                                                                ></em
                                                                ></a>
                                                            </span>
                            </div>
                          </ng-template>
                        </ejs-listview>
                      </div>
                    </div>
                  </ng-template>
                </e-panel>
                <e-panel
                  [col]="2"
                  [row]="1"
                  [sizeX]="2"
                  [sizeY]="1"
                >
                  <ng-template #content>
                    <em
                      (click)="onCloseIconHandler($event)"
                      aria-hidden="true"
                      class="fa-light fa-lg fa-time"
                    ></em>
                    <div
                      class="e-panel-container scrollbar scrollbar-indigo thin p-2"
                    >
                      <div class="control-section">
                        <ejs-textbox
                          (keyup)="
                                                        onFilterUsers($event)
                                                    "
                          id="filterTagsList"
                          placeholder="Filtrovat štítky..."
                          title="Napiš název"
                        ></ejs-textbox>
                        <ejs-listview
                          #listviewUsers
                          [dataSource]="dataUsers"
                          [fields]="fields"
                          [headerTitle]="
                                                        headerTitleTags
                                                    "
                          [showHeader]="true"
                          [showIcon]="true"
                          id="listviewTags"
                        >
                          <ng-template
                            #template
                            let-dataUsers=""
                          >
                            <div
                              class="d-flex justify-content-between"
                            >
                              <div
                                class="e-list-wrapper e-list-multi-line"
                              >
                                <em
                                  class="{{
                                                                        dataUsers.icon
                                                                    }} align-middle mr-2"
                                ></em>
                                <strong
                                  class="e-list-item-header mr-2"
                                >{{
                                    dataUsers.text
                                  }}</strong
                                >
                                <small
                                  *ngIf="
                                                                        !dataUsers.ticket_id
                                                                    "
                                  class="timeStamp text-muted"
                                >{{
                                    dataUsers.child
                                      ? dataUsers
                                        .child
                                        .length
                                      :0
                                  }}
                                  ticketů</small
                                >
                              </div>
                              <span>
                                                                <em
                                                                  *ngIf="
                                                                        !dataUsers.id
                                                                    "
                                                                  class="fa-light fa-lg fa-chevron-right align-middle"
                                                                ></em>
                                                                <a
                                                                  *ngIf="
                                                                        dataUsers.id
                                                                    "
                                                                  [routerLink]="[
                                                                        '/user/detail/',
                                                                        dataUsers.id
                                                                    ]"
                                                                ><em
                                                                  class="fa-light fa-lg fa-search"
                                                                ></em
                                                                ></a>
                                                            </span>
                            </div>
                          </ng-template>
                        </ejs-listview>
                      </div>
                    </div>
                  </ng-template>
                </e-panel>
              </e-panels>
            </ejs-dashboardlayout>
          </div>
        </div>
      </ng-template>
    </e-tabitem>
    <e-tabitem [header]="headerText[1]">
      <ng-template #content>
        <app-office-list></app-office-list>
      </ng-template>
    </e-tabitem>
  </e-tabitems>
</ejs-tab>
