import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    OnInit,
    ViewChild,
} from '@angular/core';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {AuthenticationService} from '@src/app/_services/authentication.service';
import {MessageService} from '@src/app/_services/message.service';
import {TimeagoIntl} from 'ngx-timeago';
import {strings as czechStrings} from 'ngx-timeago/language-strings/cs';
import {Material} from '@src/app/_models/material/material';
import {OrderItem} from '@src/app/_models/material/material-item';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {saveAs} from 'file-saver';
import {registerLocaleData, ViewportScroller} from '@angular/common';
import {Observable, Observer} from 'rxjs';
import {MaterialAssignation} from '@src/app/_models/material/material-assignation';
import {DataService} from '@src/app/_services/data.service';
import {Department} from '@src/app/_models/department/department';
import {MaterialPayment} from '@src/app/_models/material/material-payment';
import {Company} from '@src/app/_models/company/company';
import {MaterialFile} from '@src/app/_models/material/material-file';
import {PermissionService} from '@src/app/_services/permission.service';
import {User} from '@src/app/_models/user/user';
import {ButtonPropsModel, DialogComponent} from '@syncfusion/ej2-angular-popups';
import {
    BeforeUploadEventArgs,
    FileInfo,
    SelectedEventArgs,
    SuccessEventArgs,
    TextBoxComponent,
    UploaderComponent,
    UploadingEventArgs,
} from '@syncfusion/ej2-angular-inputs';
import {EmitType, isNullOrUndefined} from '@syncfusion/ej2-base';
import {SelectEventArgs} from '@syncfusion/ej2-navigations';
import {ClickEventArgs, TabComponent} from '@syncfusion/ej2-angular-navigations';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {
    ColumnModel,
    DataResult,
    EditSettingsModel,
    ExcelExportProperties,
    FilterSettingsModel,
    GridComponent,
    GroupSettingsModel,
    PageSettingsModel,
    RowDataBoundEventArgs,
    SelectionSettingsModel,
    ToolbarItems,
} from '@syncfusion/ej2-angular-grids';
import {
    ChangeEventArgs,
    DropDownListComponent,
    FilteringEventArgs,
} from '@syncfusion/ej2-angular-dropdowns';
import {Query} from '@syncfusion/ej2-data';
import moment from 'moment';
import localeCs from '@angular/common/locales/cs';
import {MaterialLogs} from '@src/app/_models/material/material-logs';
import {EnvironmentService} from '@src/app/_services/environment.service';
import {GalleryImages} from '@src/app/_models/images/images.type';
import {Lightbox} from 'ngx-lightbox';
import {
    MaterialLogsGridItems,
    MaterialService,
    OrderItemGridItems,
} from '@src/app/features/material/material.service';
import {UsersService} from '@src/app/features/users/users.service';

moment.updateLocale('cs', {workingWeekdays: [1, 2, 3, 4, 5]});
registerLocaleData(localeCs);

@UntilDestroy()
@Component({
    selector: 'app-material-detail',
    templateUrl: './material-detail.component.html',
    styleUrls: ['./material-detail.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MaterialDetailComponent implements OnInit {
    // Navigations
    headerText = [
        {text: 'data faktury'},
        {text: 'položky faktury'},
        {text: 'soubory'},
        {text: 'historie změn'},
    ];

    // Dialogs
    renderMaterialFormDialog = false;

    renderMaterialItemFormDialog = false;

    buttonsDelete: ButtonPropsModel[] = [
        {
            click: (): void => {
                this.orderRemove();
            },
            isFlat: false,
            buttonModel: {
                content: 'OK',
                cssClass: 'e-success e-outline',
            },
        },
        {
            click: (): void => {
                this.confirmationDelete.hide();
            },
            buttonModel: {
                content: 'ZRUŠIT',
                cssClass: 'e-danger e-outline',
            },
        },
    ];

    buttonsDecline: ButtonPropsModel[] = [
        {
            click: (): void => {
                this.declineApplication();
            },
            isFlat: false,
            buttonModel: {
                content: 'OK',
                cssClass: 'e-success e-outline',
            },
        },
        {
            click: (): void => {
                this.confirmationDecline.hide();
            },
            buttonModel: {
                content: 'ZRUŠIT',
                cssClass: 'e-danger e-outline',
            },
        },
    ];

    buttonsConfirm: ButtonPropsModel[] = [
        {
            click: (): void => {
                this.confirmApplication();
            },
            isFlat: false,
            buttonModel: {
                content: 'OK',
                cssClass: 'e-success e-outline',
            },
        },
        {
            click: (): void => {
                this.confirmationConfirm.hide();
            },
            buttonModel: {
                content: 'ZRUŠIT',
                cssClass: 'e-danger e-outline',
            },
        },
    ];

    buttonsAdminDecline: ButtonPropsModel[] = [
        {
            click: (): void => {
                this.declineAdminApplication();
            },
            isFlat: false,
            buttonModel: {
                content: 'OK',
                cssClass: 'e-success e-outline',
            },
        },
        {
            click: (): void => {
                this.adminconfirmationDecline.hide();
            },
            buttonModel: {
                content: 'ZRUŠIT',
                cssClass: 'e-danger e-outline',
            },
        },
    ];

    buttonsAdminConfirm: ButtonPropsModel[] = [
        {
            click: (): void => {
                this.confirmAdminApplication();
            },
            isFlat: false,
            buttonModel: {
                content: 'OK',
                cssClass: 'e-success e-outline',
            },
        },
        {
            click: (): void => {
                this.adminconfirmationConfirm.hide();
            },
            buttonModel: {
                content: 'ZRUŠIT',
                cssClass: 'e-danger e-outline',
            },
        },
    ];

    buttonsItemDelete: ButtonPropsModel[] = [
        {
            click: (): void => {
                this.deleteItem();
            },
            isFlat: false,
            buttonModel: {
                content: 'OK',
                cssClass: 'e-success e-outline',
            },
        },
        {
            click: (): void => {
                this.confirmationItemDelete.hide();
            },
            buttonModel: {
                content: 'ZRUŠIT',
                cssClass: 'e-danger e-outline',
            },
        },
    ];

    buttonsCancel: ButtonPropsModel[] = [
        {
            click: (): void => {
                this.confirmationCancel.hide();
            },
            buttonModel: {
                isPrimary: true,
                content: 'OK',
            },
        },
    ];

    // Grid
    currencyFormat: {[key: string]: string} = {format: '#,###.00\',- CZK\''};

    dateFormat: string;

    toolbar: ToolbarItems[] | object;

    toolbarHistory: ToolbarItems[] | object;

    data: object;

    dataHistory: object[] = [];

    fields: object = {text: 'label', value: 'value'};

    filterSettings: FilterSettingsModel;

    filterCheckboxSettings: FilterSettingsModel;

    selectionSettings: SelectionSettingsModel;

    editSettings: EditSettingsModel;

    groupOptions: GroupSettingsModel;

    pageOptions: PageSettingsModel;

    expendituresColumnsPrice: ColumnModel[];

    expendituresColumnsItems: ColumnModel[];

    filterDate: object;

    initialItemsSort: object;

    sortHistoryOptions: object;

    requiredRules: object;

    exportCurrentPage = false;

    exportCurrentHistoryPage = false;

    // Dropdowns
    categoryFilterData: Array<string> = [];

    categoryLogsFilterData: Array<string> = [];

    departmentSelect: Array<{value: boolean | number | string; label: string}> = [];

    categorySelect: Array<{value: boolean | number | string; label: string}> = [];

    companySelect: Array<{value: boolean | number | string; label: string}> = [];

    paymentSelect: Array<{value: boolean | number | string; label: string}> = [];

    // Forms
    createFileForm: FormGroup;

    isDirty = false;

    isCreate = false;

    isCopy = false;

    isUpdate = false;

    isCreateItem = false;

    isCopyItem = false;

    isUpdateItem = false;

    // Files
    imagesBasic: GalleryImages[] = [];

    deleteFileId: number;

    // Upload
    path: {
        saveUrl: string;
        removeUrl: string;
    };

    dropElement: HTMLElement = document.getElementsByClassName('control-fluid')[0] as HTMLElement;

    humanizeBytes: (bytes: number) => void;

    // Variables
    today = moment()
        .format('YYYY-MM-DD HH:mm:ss');

    todayUnix = moment()
        .valueOf();

    live: true;

    currentUser: User | null;

    material: Material | null | undefined;

    logs: MaterialLogs[] | undefined = [];

    users: User[] | undefined = [];

    payments: MaterialPayment[] = [];

    clickedRow: OrderItem[];

    clickedRow$ = new Observable<OrderItem[]>((observer: Observer<OrderItem[]>) => {
        observer.next(this.clickedRow);
    });

    clickedRowHistory: MaterialLogs[] = [];

    clickedRowHistory$ = new Observable<MaterialLogs[]>((observer: Observer<MaterialLogs[]>) => {
        observer.next(this.clickedRowHistory);
    });

    // Loaders
    loadingMaterial = true;

    // Navigations
    @ViewChild('materialDetailTab') materialTabObj: TabComponent;

    @ViewChild('confirmationDelete') confirmationDelete: DialogComponent;

    @ViewChild('confirmationDecline') confirmationDecline: DialogComponent;

    @ViewChild('confirmationConfirm') confirmationConfirm: DialogComponent;

    @ViewChild('AdminconfirmationConfirm')
    adminconfirmationConfirm: DialogComponent;

    @ViewChild('AdminconfirmationDecline')
    adminconfirmationDecline: DialogComponent;

    @ViewChild('confirmationItemDelete') confirmationItemDelete: DialogComponent;

    @ViewChild('confirmationCancel') confirmationCancel: DialogComponent;

    // Grid
    @ViewChild('itemsGrid') gridItems?: GridComponent;

    @ViewChild('materialLogsGrid') gridLogs?: GridComponent;

    @ViewChild('searchtext') searchtextObj: TextBoxComponent;

    @ViewChild('searchLogsText') searchLogsTextObj: TextBoxComponent;

    // Dropdowns
    @ViewChild('viewFilter') viewFilterInstance: DropDownListComponent;

    @ViewChild('viewLogsFilter') viewLogsFilterInstance: DropDownListComponent;

    // Files
    @ViewChild('targetView') targetView: ElementRef;

    // Upload
    @ViewChild('preloadupload') uploadObj?: UploaderComponent;

    constructor(
        private readonly route: ActivatedRoute,
        private readonly router: Router,
        private readonly ref: ChangeDetectorRef,
        private readonly authenticationService: AuthenticationService,
        private readonly materialService: MaterialService,
        private readonly userService: UsersService,
        private readonly dataService: DataService,
        private readonly permissionService: PermissionService,
        private readonly messages: MessageService,
        private readonly formBuilder: FormBuilder,
        private readonly intl: TimeagoIntl,
        private readonly vps: ViewportScroller,
        private readonly environmentService: EnvironmentService,
        private readonly lightbox: Lightbox,
    ) {
        this.currentUser = this.authenticationService.currentUserValue;
        this.intl.strings = czechStrings;
        this.intl.changes.next();
        this.path = {
            saveUrl: `${this.environmentService.backendURL}/api/upload/material?type=material`,
            removeUrl: '',
        };
    }

    private loadData(): void {
        this.imagesBasic = [];

        if (this.material?.receipts && this.material.receipts.length > 0) {
            this.material.receipts.forEach((file: MaterialFile) => {
                if (file.extension === 'image/jpeg' || file.extension === 'image/png') {
                    this.imagesBasic.push({
                        src: file.url,
                        thumb: file.url_thumbnail,
                        caption: file.name,
                    });
                }
            });
        }

        if (this.gridItems) {
            this.data = this.gridItems.dataSource = this.loadItemsData();
        }

        this.loadingMaterial = false;
        this.ref.markForCheck();
    }

    ngOnInit(): void {
        this.filterSettings = {
            type: 'Menu',
            showFilterBarStatus: true,
            ignoreAccent: true,
        };

        this.filterCheckboxSettings = {
            type: 'CheckBox',
            showFilterBarStatus: true,
            ignoreAccent: true,
        };

        this.filterSettings = {
            type: 'Excel',
            showFilterBarOperator: true,
            showFilterBarStatus: true,
            ignoreAccent: true,
        };

        this.selectionSettings = {
            persistSelection: true,
            type: 'Multiple',
            checkboxOnly: true,
        };

        this.toolbar = [
            {
                text: 'Občerstvit data',
                tooltipText: 'Občerstvit data',
                prefixIcon: 'e-refresh',
                id: 'customRefresh',
            },
            'Print',
            'ExcelExport',
            'Add',
            'Edit',
            {
                text: 'Smazat',
                tooltipText: 'Stornovat položku',
                prefixIcon: 'e-remove',
                id: 'delete',
            },
            {
                text: 'Kopírovat',
                tooltipText: 'Kopírovat položku',
                prefixIcon: 'e-copy',
                id: 'copy',
            },
        ];

        this.categoryFilterData = [
            'všechny položky objednávek',
            'fronta položek objednávek',
            'schválené položky objednávek',
            'zamítnuté položky objednávek',
            'stornované položky objednávek',
        ];
        this.pageOptions = {pageSize: 50};
        this.initialItemsSort = {
            columns: [{field: 'created_date', direction: 'Descending'}],
        };
        this.dateFormat = 'dd.MM.yyyy HH:mm';
        this.filterDate = {type: 'Menu', params: {format: this.dateFormat}};
        this.groupOptions = {
            showGroupedColumn: true,
            disablePageWiseAggregates: false,
        };

        this.editSettings = {
            showConfirmDialog: false,
            showDeleteConfirmDialog: false,
            allowEditing: false,
            allowAdding: false,
            allowDeleting: false,
            allowEditOnDblClick: false,
            mode: 'Dialog',
        };
        this.requiredRules = {required: true};
        this.toolbarHistory = ['Search'];
        this.categoryLogsFilterData = [
            'všechny změny',
            'poslední den',
            'poslední týden',
            'poslední měsíc',
            'poslední rok',
        ];

        this.toolbarHistory = [
            {
                text: 'Občerstvit data',
                tooltipText: 'Občerstvit data',
                prefixIcon: 'e-refresh',
                id: 'customRefresh',
            },
            'Print',
            'ExcelExport',
            {
                text: 'Obnovit data',
                tooltipText: 'Obnovit data',
                prefixIcon: 'e-revert',
                id: 'revert',
            },
        ];

        this.dataService.materialDetailSource.pipe(untilDestroyed(this))
            .subscribe({
                next: (material: Material | null | undefined) => {
                    this.material = material;

                    if (this.material) {
                        this.loadData();
                        this.materialService
                            .getLogs(this.material.id)
                            .pipe(untilDestroyed(this))
                            .subscribe({
                                next: (logs: MaterialLogs[] | undefined) => {
                                    // data uživatelů
                                    this.dataService.userSource.pipe(untilDestroyed(this))
                                        .subscribe({
                                            next: (data: User[] | undefined) => {
                                                this.users = data;
                                                this.logs = logs;

                                                if (this.gridLogs) {
                                                    this.dataHistory = this.gridLogs.dataSource =
                                                        this.loadHistoryData();
                                                }

                                                this.sortHistoryOptions = {
                                                    columns: [
                                                        {field: 'timestamp', direction: 'Descending'},
                                                        {field: 'id', direction: 'Descending'},
                                                    ],
                                                };
                                                this.ref.markForCheck();
                                            },
                                            error: error => {
                                                console.error(error);
                                            },
                                        });
                                },
                                error: error => {
                                    console.error(error);
                                    this.loadingMaterial = false;
                                    this.ref.markForCheck();
                                },
                            });
                    }
                },
                error: error => {
                    console.error(error);
                    this.loadingMaterial = false;
                    this.ref.markForCheck();
                },
            });

        this.dataService.companySource.pipe(untilDestroyed(this))
            .subscribe({
                next: (companies: Company[]) => {
                    this.companySelect = [];
                    companies.forEach((company: Company) => {
                        this.companySelect = [
                            ...this.companySelect,
                            {value: company.id, label: company.name},
                        ];
                    });
                    this.ref.markForCheck();
                },
                error: error => {
                    console.error(error);
                },
            });

        this.materialService
            .getPayments()
            .pipe(untilDestroyed(this))
            .subscribe({
                next: (payments: MaterialPayment[]) => {
                    this.paymentSelect = [];
                    this.payments = payments;
                    payments.forEach((payment: MaterialPayment) => {
                        if (!payment.deleted_date) {
                            this.paymentSelect = [
                                ...this.paymentSelect,
                                {value: payment.id, label: payment.name},
                            ];
                        }
                    });
                    this.ref.markForCheck();
                },
                error: error => {
                    console.error(error);
                },
            });

        this.dataService.departmentSource.pipe(untilDestroyed(this))
            .subscribe({
                next: (data: Department[]) => {
                    this.departmentSelect = [];
                    data.forEach((department: Department) => {
                        if (!department.deleted_date) {
                            this.departmentSelect = [
                                ...this.departmentSelect,
                                {
                                    value: department.id,
                                    label: `${department.company.name} - ${department.name}`,
                                },
                            ];
                        }
                    });
                    this.ref.markForCheck();
                },
                error: error => {
                    console.error(error);
                },
            });

        this.createFileForm = this.formBuilder.group({
            files: ['', Validators.required],
        });

        this.route.params.pipe(untilDestroyed(this))
            .subscribe(params => {
                this.dataService.setMaterialDetailDataSource(parseInt(params.id as string, 10));
            });

        this.dataService.setCompanyDataSource();
        this.dataService.setDepartmentDataSource();
    }

    open(index: number): void {
        this.lightbox.open(this.imagesBasic, index);
    }

    close(): void {
        this.lightbox.close();
    }

    onCreate(): void {
        // Nastavení GUI podle URL parametrů
        this.route.queryParamMap.pipe(untilDestroyed(this))
            .subscribe(queryParams => {
                if (queryParams.get('view') === 'data') {
                    this.materialTabObj.selectedItem = 0;
                } else if (queryParams.get('view') === 'items') {
                    this.materialTabObj.selectedItem = 1;
                } else if (queryParams.get('view') === 'files') {
                    this.materialTabObj.selectedItem = 2;
                } else if (queryParams.get('view') === 'logs') {
                    this.materialTabObj.selectedItem = 3;
                } else {
                    this.materialTabObj.selectedItem = 0;
                }
            });
    }

    preventSwipe(e: SelectEventArgs): void {
        if (e.isSwiped) {
            e.cancel = true;
        }
    }

    select(e: SelectEventArgs): void {
        let queryParams: Params = {view: null};

        if (e.selectedItem.textContent === 'data faktury') {
            queryParams = {
                view: 'data',
                form: null,
            };
        }

        if (e.selectedItem.textContent === 'položky faktury') {
            queryParams = {
                view: 'items',
                form: null,
            };
        }

        if (e.selectedItem.textContent === 'soubory') {
            queryParams = {
                view: 'files',
                form: null,
            };
        }

        if (e.selectedItem.textContent === 'historie změn') {
            queryParams = {
                view: 'logs',
            };
        }

        void this.router.navigate([], {
            relativeTo: this.route,
            queryParams,
            queryParamsHandling: 'merge',
        });
    }

    // Grid Items
    loadItemsData(): DataResult[] {
        console.info('NEW DATA BOUND');

        const elements: OrderItemGridItems[] = [];

        if (this.material?.orderitems && this.material.orderitems.length > 0) {
            // eslint-disable-next-line complexity
            this.material.orderitems.forEach((item: OrderItem) => {
                item.confirmed = false;
                item.declined = false;

                let decline = 0;
                let submit = 0;
                const reqSubmits = item.budget.approvers.length;

                item.budget.approvers.forEach((approver: User) => {
                    this.material?.confirmers.forEach((confirmer: User) => {
                        if (
                            approver.id === confirmer.id &&
                            confirmer.material_assignation_users?.confirmed_date
                        ) {
                            submit = submit + 1;
                        }

                        if (
                            approver.id === confirmer.id &&
                            confirmer.material_assignation_users?.declined_date
                        ) {
                            decline = decline + 1;
                        }
                    });
                });

                if (submit > 0 && decline === 0 && submit === reqSubmits) {
                    item.confirmed = true;
                }

                if (decline > 0) {
                    item.declined = true;
                }

                if (item.confirmed && !item.deleted_date) {
                    item.status = 'schváleno';
                }

                if (item.declined && !item.deleted_date) {
                    item.status = 'zamítnuto';
                }

                if (item.deleted_date) {
                    item.status = 'stornováno';
                }

                if (!item.declined && !item.confirmed && !item.deleted_date) {
                    item.status = 've frontě';
                }

                if (this.material) {
                    elements.push({
                        id: item.id,
                        created_by: this.material.creator.id,
                        creator_str: this.material.creator.fullname,
                        creator_img: this.material.creator.personalphoto,
                        name: item.name,
                        amount: item.amount,
                        price: item.dph ? item.price - item.price * 0.21 : item.price,
                        dph: !!item.dph,
                        category: item.category.name,
                        status: item.status,
                        budget: item.budget.name,
                        department: item.budget.department.name,
                        company: item.budget.department.company.name,
                        material_id: item.material_id,
                        to_storage: this.material.to_storage,
                        created_date: new Date(item.created_date),
                        updated_date: item.updated_date ? new Date(item.updated_date) : null,
                        deleted_date: item.deleted_date ? new Date(item.deleted_date) : null,
                    });
                }
            });
        }

        this.loadingMaterial = false;

        return elements as unknown as DataResult[];
    }

    onCreated(): void {
        this.disableToolBarBtn();
    }

    setInitialGridFiltering(): void {
        this.gridItems?.clearFiltering();
        // this.gridItems.filterByColumn('created_by', 'equal', this.currentUser.id, 'and', false, true);
        // this.viewFilterInstance.value = 'moje žádanky - všechny';
        this.ref.markForCheck();
    }

    onLoad(): void {
        const wrapper1 = document.getElementById('scroll_wrapper1_items');
        const wrapper2 = document.getElementById('scroll_wrapper2_items');

        if (wrapper1 && wrapper2) {
            wrapper1.onscroll = (): void => {
                wrapper2.scrollLeft = wrapper1.scrollLeft;
            };

            wrapper2.onscroll = (): void => {
                wrapper1.scrollLeft = wrapper2.scrollLeft;
            };
        }

        if (this.gridItems) {
            this.data = this.gridItems.dataSource = this.loadItemsData();
        }
    }

    search(): void {
        this.gridItems?.search(this.searchtextObj.value);
    }

    onDataBound(): void {
        this.rowSelected();

        const width = document.getElementById('itemsGrid_content_table')?.offsetWidth;
        const parent1 = document.getElementById('scroll_div_items');
        const parent2 = document.getElementById('grid_parent_items');

        if (parent1 && parent2 && width) {
            parent1.style.width = `${width}px`;
            parent2.style.width = `${width}px`;
        }

        this.gridItems?.autoFitColumns();
        this.ref.markForCheck();
    }

    rowDataBound(args: RowDataBoundEventArgs): void {
        const data = args.data as OrderItemGridItems;

        if (args.row && data.deleted_date) {
            args.row.getElementsByClassName('e-gridchkbox')[0].classList.add('disablecheckbox');
            args.row
                .getElementsByClassName('e-checkbox-wrapper')[0]
                .classList.add('disablecheckbox');
            args.row.classList.add('e-disabled');
        }

        if (
            this.currentUser &&
            args.row &&
            data.created_by !== this.currentUser.id &&
            !this.permissionService.checkUserISAdministrative(this.currentUser)
        ) {
            args.row.getElementsByClassName('e-gridchkbox')[0].classList.add('disablecheckbox');
            args.row
                .getElementsByClassName('e-checkbox-wrapper')[0]
                .classList.add('disablecheckbox');
        }

        this.ref.markForCheck();
    }

    onStatusFilterChange(e: ChangeEventArgs): void {
        if (e.value === 'všechny položky objednávek') {
            this.gridItems?.clearFiltering();
        } else if (e.value === 'fronta položek objednávek' && this.gridItems) {
            this.gridItems.filterSettings = {
                columns: [
                    {
                        field: 'status',
                        operator: 'equal',
                        value: 've frontě',
                        predicate: 'and',
                        matchCase: false,
                        ignoreAccent: true,
                    },
                    {
                        field: 'deleted_date',
                        operator: 'equal',
                        value: null as unknown as string,
                        predicate: 'and',
                        matchCase: false,
                        ignoreAccent: true,
                    },
                ],
            };
        } else if (e.value === 'schválené položky objednávek' && this.gridItems) {
            this.gridItems.filterSettings = {
                columns: [
                    {
                        field: 'status',
                        operator: 'equal',
                        value: 'schváleno',
                        predicate: 'and',
                        matchCase: false,
                        ignoreAccent: true,
                    },
                    {
                        field: 'deleted_date',
                        operator: 'equal',
                        value: null as unknown as string,
                        predicate: 'and',
                        matchCase: false,
                        ignoreAccent: true,
                    },
                ],
            };
        } else if (e.value === 'zamítnuté položky objednávek' && this.gridItems) {
            this.gridItems.filterSettings = {
                columns: [
                    {
                        field: 'status',
                        operator: 'equal',
                        value: 'zamítnuto',
                        predicate: 'and',
                        matchCase: false,
                        ignoreAccent: true,
                    },
                    {
                        field: 'deleted_date',
                        operator: 'equal',
                        value: null as unknown as string,
                        predicate: 'and',
                        matchCase: false,
                        ignoreAccent: true,
                    },
                ],
            };
        } else if (e.value === 'stornované položky objednávek' && this.gridItems) {
            this.gridItems.filterSettings = {
                columns: [
                    {
                        field: 'status',
                        operator: 'equal',
                        value: 'stornováno',
                        predicate: 'and',
                        matchCase: false,
                        ignoreAccent: true,
                    },
                    {
                        field: 'deleted_date',
                        operator: 'notequal',
                        value: null as unknown as string,
                        predicate: 'and',
                        matchCase: false,
                        ignoreAccent: true,
                    },
                ],
            };
        }
    }

    onFilteringDepartments: EmitType<FilteringEventArgs> = (e: FilteringEventArgs) => {
        if (e.text === '') {
            e.updateData(this.departmentSelect);
        } else {
            let query = new Query();

            query = e.text !== '' ? query.where('label', 'contains', e.text, true) : query;
            e.updateData(this.departmentSelect, query);
        }
    };

    onFilteringCategories: EmitType<FilteringEventArgs> = (e: FilteringEventArgs) => {
        if (e.text === '') {
            e.updateData(this.categorySelect);
        } else {
            let query = new Query();

            query = e.text !== '' ? query.where('label', 'contains', e.text, true) : query;
            e.updateData(this.categorySelect, query);
        }
    };

    cancelBtnClick(): void {
        if (this.gridItems) {
            this.searchtextObj.value = '';
            this.gridItems.searchSettings.key = '';
        }
    }

    toolbarClick(args: ClickEventArgs): void {
        const xlsProp: ExcelExportProperties = {
            fileName: 'js_invoices_items_export.xlsx',
            enableFilter: true,
            exportType: this.exportCurrentPage ? 'CurrentPage' : 'AllPages',
        };

        if (args.item.id === 'itemsGrid_excelexport') {
            void this.gridItems?.excelExport(xlsProp);
        } else if (args.item.id === 'delete' && this.gridItems) {
            if (this.gridItems.getSelectedRecords().length !== 0) {
                this.confirmationDelete.content = `Potvrďte smazání ${this.clickedRow.length} položek`;
                this.confirmationDelete.show();
            } else {
                this.confirmationCancel.content = 'Musíte vybrat alespoň jednu položku';
                this.confirmationCancel.show();
            }
        } else if (args.item.id === 'copy' && this.gridItems) {
            if (this.gridItems.getSelectedRecords().length !== 0) {
                this.copyItem();
            } else {
                this.confirmationCancel.content = 'Musíte vybrat alespoň jednu položku';
                this.confirmationCancel.show();
            }
        } else if (args.item.id === 'customRefresh') {
            this.loadingMaterial = true;
            console.info('refresh DATA!');
            this.ref.markForCheck();
            this.dataService.clearMaterialsCache();
            this.dataService.setMaterialsDataSource();
        }
    }

    enableToolbarBtn(): void {
        this.gridItems?.toolbarModule.enableItems(['itemsGrid_edit', 'delete', 'copy'], false);
    }

    disableToolBarBtn(): void {
        this.gridItems?.toolbarModule.enableItems(['itemsGrid_edit', 'delete', 'copy'], false);
    }

    rowSelected(): void {
        this.clickedRow = [];

        const selectedrecords: OrderItem[] | undefined = this.gridItems?.getSelectedRecords() as
            | OrderItem[]
            | undefined;

        if (selectedrecords && selectedrecords.length > 0) {
            selectedrecords.forEach((row: OrderItem) => {
                const tempData: OrderItem | undefined = this.material?.orderitems.find(
                    x => x.id === row.id,
                );

                if (tempData) {
                    this.clickedRow.push(tempData);
                }

                this.ref.markForCheck();
            });

            this.clickedRow$ = new Observable<OrderItem[]>((observer: Observer<OrderItem[]>) => {
                observer.next(this.clickedRow);
            });
            this.enableToolbarBtn();
        } else {
            this.disableToolBarBtn();
            this.clickedRow$ = new Observable<OrderItem[]>((observer: Observer<OrderItem[]>) => {
                observer.next(this.clickedRow);
            });
        }
    }

    // Grid Logs
    onHistoryCreated(): void {
        this.disableLogsToolBarBtn();
    }

    setInitialHistoryGridFiltering(): void {
        this.gridLogs?.clearFiltering();
        // this.gridLogs.filterByColumn('created_by', 'equal', this.currentUser.id, 'and', false, true);
        // this.viewFilterInstance.value = 'moje žádanky - všechny';
        this.ref.markForCheck();
    }

    loadHistoryData(): DataResult[] {
        console.info('NEW DATA BOUND');

        const elements: MaterialLogsGridItems[] = [];

        if (this.logs && this.logs.length > 0) {
            this.logs.forEach((log: MaterialLogs) => {
                const creator = this.users?.find(x => x.id === log.created_by);
                const editor = this.users?.find(x => x.id === log.updated_by);
                const remover = this.users?.find(x => x.id === log.deleted_by);
                const payment = this.payments.find(x => x.id === log.payment_id);

                elements.push({
                    id: log.materials_id,
                    type_id: log.materials_type,
                    material_id: log.id,
                    payment_id: log.payment_id,
                    created_by: log.created_by,
                    creator_str: creator ? creator.fullname : '',
                    creator_img: creator ? creator.personalphoto : '',
                    updated_by: log.updated_by,
                    editor_str: editor ? editor.fullname : '',
                    editor_img: editor ? editor.personalphoto : '',
                    deleted_by: log.deleted_by,
                    remover_str: remover ? remover.fullname : '',
                    remover_img: remover ? remover.personalphoto : '',
                    reason: log.reason,
                    storno: log.storno,
                    note: log.note,
                    to_storage: log.to_storage,
                    receipt: log.receipt,
                    payment_type: payment?.name ?? '',
                    type:
                        log.materials_type === 1
                            ? 'vytvoření'
                            : log.materials_type === 2
                                ? 'upravení'
                                : 'smazání',
                    created_date: new Date(log.created_date),
                    updated_date: log.updated_date ? new Date(log.updated_date) : null,
                    deleted_date: log.deleted_date ? new Date(log.deleted_date) : null,
                    timestamp: new Date(log.materials_timestamp),
                });
            });
        }

        this.loadingMaterial = false;

        return elements as unknown as DataResult[];
    }

    onHistoryLoad(): void {
        const wrapper1 = document.getElementById('scroll_wrapper1_logs');
        const wrapper2 = document.getElementById('scroll_wrapper2_logs');

        if (wrapper1 && wrapper2) {
            wrapper1.onscroll = (): void => {
                wrapper2.scrollLeft = wrapper1.scrollLeft;
            };

            wrapper2.onscroll = (): void => {
                wrapper1.scrollLeft = wrapper2.scrollLeft;
            };
        }

        if (this.gridLogs) {
            this.dataHistory = this.gridLogs.dataSource = this.loadHistoryData();
            this.sortHistoryOptions = {
                columns: [
                    {field: 'timestamp', direction: 'Descending'},
                    {field: 'id', direction: 'Descending'},
                ],
            };
            this.ref.markForCheck();
        }
    }

    searchLogs(): void {
        this.gridLogs?.search(this.searchtextObj.value);
    }

    onHistoryDataBound(): void {
        this.rowHistorySelected();
        this.ref.markForCheck();
    }

    rowHistoryDataBound(args: RowDataBoundEventArgs): void {
        const data = args.data as MaterialLogs;

        if (args.row && data.deleted_date) {
            args.row.getElementsByClassName('e-gridchkbox')[0].classList.add('disablecheckbox');
            args.row
                .getElementsByClassName('e-checkbox-wrapper')[0]
                .classList.add('disablecheckbox');
            args.row.classList.add('e-disabled');
        }

        if (
            this.currentUser &&
            args.row &&
            data.created_by !== this.currentUser.id &&
            !this.permissionService.checkUserISAdministrative(this.currentUser)
        ) {
            args.row.getElementsByClassName('e-gridchkbox')[0].classList.add('disablecheckbox');
            args.row
                .getElementsByClassName('e-checkbox-wrapper')[0]
                .classList.add('disablecheckbox');
        }

        this.ref.markForCheck();
    }

    onStatusLogsFilterChange(e: ChangeEventArgs): void {
        if (e.value === 'všechny změny') {
            this.gridLogs?.clearFiltering();
        } else if (e.value === 'poslední den') {
            this.gridLogs?.clearFiltering();
            this.gridLogs?.filterByColumn(
                'timestamp',
                'greaterthanorequal',
                new Date(moment()
                    .subtract(1, 'days')
                    .format('YYYY-MM-DD HH:mm:ss')),
                'and',
                false,
                true,
            );
        } else if (e.value === 'poslední týden') {
            this.gridLogs?.clearFiltering();
            this.gridLogs?.filterByColumn(
                'timestamp',
                'greaterthanorequal',
                new Date(moment()
                    .subtract(1, 'weeks')
                    .format('YYYY-MM-DD HH:mm:ss')),
                'and',
                false,
                true,
            );
        } else if (e.value === 'poslední měsíc') {
            this.gridLogs?.clearFiltering();
            this.gridLogs?.filterByColumn(
                'timestamp',
                'greaterthanorequal',
                new Date(moment()
                    .subtract(1, 'months')
                    .format('YYYY-MM-DD HH:mm:ss')),
                'and',
                false,
                true,
            );
        } else if (e.value === 'poslední rok') {
            this.gridLogs?.clearFiltering();
            this.gridLogs?.filterByColumn(
                'timestamp',
                'greaterthanorequal',
                new Date(moment()
                    .subtract(1, 'years')
                    .format('YYYY-MM-DD HH:mm:ss')),
                'and',
                false,
                true,
            );
        }
    }

    cancelBtnLogsClick(): void {
        if (this.gridLogs) {
            this.searchtextObj.value = '';
            this.gridLogs.searchSettings.key = '';
        }
    }

    toolbarHistoryClick(args: ClickEventArgs): void {
        const xlsProp: ExcelExportProperties = {
            fileName: 'js_material_logs_export.xlsx',
            enableFilter: true,
            exportType: this.exportCurrentHistoryPage ? 'CurrentPage' : 'AllPages',
        };

        if (args.item.id === 'MaterialLogsGrid_excelexport') {
            void this.gridLogs?.excelExport(xlsProp);
        } else if (args.item.id === 'customRefresh') {
            this.loadingMaterial = true;
            console.info('refresh DATA!');
            this.ref.markForCheck();

            if (!this.material) {
                console.error('this.material is missing...');

                return;
            }

            this.dataService.setMaterialDetailDataSource(this.material);
        } else if (args.item.id === 'revert') {
            this.loadingMaterial = true;
            console.info('revert DATA!');
            this.revertToTimestamp();
            this.ref.markForCheck();

            if (!this.material) {
                console.error('this.material is missing...');

                return;
            }

            this.dataService.setMaterialDetailDataSource(this.material);
        }
    }

    enableLogsToolbarBtn(): void {
        this.gridLogs?.toolbarModule.enableItems(['revert'], true);
    }

    disableLogsToolBarBtn(): void {
        this.gridLogs?.toolbarModule.enableItems(['revert'], false);
    }

    rowHistorySelected(): void {
        if (this.gridLogs) {
            this.clickedRowHistory = [];

            const selectedrecords: MaterialLogs[] =
                this.gridLogs.getSelectedRecords() as MaterialLogs[];

            if (selectedrecords.length > 0) {
                selectedrecords.forEach((row: MaterialLogs) => {
                    const tempData: MaterialLogs | undefined = this.logs?.find(
                        x => x.materials_id === row.id,
                    );

                    if (tempData) {
                        this.clickedRowHistory.push(tempData);
                        this.ref.markForCheck();
                    }
                });

                this.clickedRowHistory$ = new Observable<MaterialLogs[]>(
                    (observer: Observer<MaterialLogs[]>) => {
                        observer.next(this.clickedRowHistory);
                    },
                );
                this.enableLogsToolbarBtn();
            } else {
                this.disableLogsToolBarBtn();
                this.clickedRowHistory$ = new Observable<MaterialLogs[]>(
                    (observer: Observer<MaterialLogs[]>) => {
                        observer.next(this.clickedRowHistory);
                    },
                );
            }
        }
    }

    revertToTimestamp(): void {
        console.info(this.clickedRowHistory[0]);
    }

    confirmApplication(): void {
        this.loadingMaterial = true;

        if (!this.material || !this.currentUser) {
            console.error('this.material || this.currentUser is missing...');

            return;
        }

        this.materialService
            .confirmInvoice(this.material, this.currentUser.id, this.today)
            .pipe(untilDestroyed(this))
            .subscribe({
                next: (assignation: MaterialAssignation) => {
                    const body = `objednávka: #${assignation.id}`;
                    const options = {progressBar: true, timeOut: 5000};

                    this.messages.showSuccess('Úspěšně schválená objednávka', body, options);
                    this.loadingMaterial = false;
                    this.confirmationConfirm.hide();
                    this.ref.markForCheck();

                    if (!this.material) {
                        console.error('this.material is missing...');

                        return;
                    }

                    this.dataService.setMaterialDetailDataSource(this.material.id);
                },
                error: error => {
                    console.error(error);

                    const body = 'Zkuste to později ...';
                    const options = {progressBar: true, timeOut: 5000};

                    this.messages.showError('Chyba během schvalování objednávky', body, options);
                    this.loadingMaterial = false;
                    this.confirmationConfirm.hide();
                    this.ref.markForCheck();
                },
            });
    }

    confirmAdminApplication(): void {
        this.loadingMaterial = true;

        if (
            this.material &&
            this.currentUser &&
            this.permissionService.checkUserISMaterialAdmin(this.currentUser) &&
            !this.material.deleted_date &&
            !this.material.submited &&
            !this.material.declined
        ) {
            this.materialService
                .confirmAdminInvoice(this.material, this.today)
                .pipe(untilDestroyed(this))
                .subscribe({
                    next: (assignation: MaterialAssignation) => {
                        const body = `objednávka: #${assignation.id}`;
                        const options = {
                            progressBar: true,
                            timeOut: 5000,
                            toastClass: 'success',
                        };

                        this.messages.showSuccess('Úspěšně schválená objednávka', body, options);
                        this.loadingMaterial = false;
                        this.adminconfirmationConfirm.hide();
                        this.ref.markForCheck();

                        if (!this.material) {
                            console.error('this.material is missing...');

                            return;
                        }

                        this.dataService.setMaterialDetailDataSource(this.material.id);
                    },
                    error: error => {
                        console.error(error);

                        const body = 'Zkuste to později ...';
                        const options = {
                            progressBar: true,
                            timeOut: 5000,
                            toastClass: 'red',
                        };

                        this.messages.showError(
                            'Chyba během schvalování objednávky',
                            body,
                            options,
                        );
                        this.loadingMaterial = false;
                        this.adminconfirmationConfirm.hide();
                        this.ref.markForCheck();
                    },
                });
        } else {
            const body = 'Nemáte oprávnění provést tuto akci...';
            const options = {progressBar: true, timeOut: 5000};

            this.messages.showError('Nedostatečné oprávnění', body, options);
            this.loadingMaterial = false;
            this.adminconfirmationConfirm.hide();
            this.ref.markForCheck();
        }
    }

    declineApplication(): void {
        this.loadingMaterial = true;

        if (!this.material || !this.currentUser) {
            console.error('this.material || this.currentUser is missing...');

            return;
        }

        this.materialService
            .declineInvoice(this.material, this.currentUser.id, this.today)
            .pipe(untilDestroyed(this))
            .subscribe({
                next: (assignation: MaterialAssignation) => {
                    const body = `objednávka: #${assignation.id}`;
                    const options = {progressBar: true, timeOut: 5000};

                    this.messages.showSuccess('Úspěšně zamítnutá objednávka', body, options);
                    this.loadingMaterial = false;
                    this.confirmationDecline.hide();
                    this.ref.markForCheck();

                    if (!this.material) {
                        console.error('this.material is missing...');

                        return;
                    }

                    this.dataService.setMaterialDetailDataSource(this.material.id);
                },
                error: error => {
                    console.error(error);

                    const body = 'Zkuste to později ...';
                    const options = {progressBar: true, timeOut: 5000};

                    this.messages.showError('Chyba během zamítnutí objednávky', body, options);
                    this.loadingMaterial = false;
                    this.confirmationDecline.hide();
                    this.ref.markForCheck();
                },
            });
    }

    declineAdminApplication(): void {
        this.loadingMaterial = true;

        if (
            this.material &&
            this.currentUser &&
            this.permissionService.checkUserISMaterialAdmin(this.currentUser) &&
            !this.material.deleted_date &&
            !this.material.submited &&
            !this.material.declined
        ) {
            this.materialService
                .declineAdminInvoice(this.material, this.today)
                .pipe(untilDestroyed(this))
                .subscribe({
                    next: (assignation: MaterialAssignation) => {
                        const body = `objednávka: #${assignation.id}`;
                        const options = {
                            progressBar: true,
                            timeOut: 5000,
                            toastClass: 'success',
                        };

                        this.messages.showSuccess('Úspěšně zamítnutá objednávka', body, options);
                        this.loadingMaterial = false;
                        this.adminconfirmationDecline.hide();
                        this.ref.markForCheck();

                        if (!this.material) {
                            console.error('this.material is missing...');

                            return;
                        }

                        this.dataService.setMaterialDetailDataSource(this.material.id);
                    },
                    error: error => {
                        console.error(error);

                        const body = 'Zkuste to později ...';
                        const options = {
                            progressBar: true,
                            timeOut: 5000,
                            toastClass: 'red',
                        };

                        this.messages.showError('Chyba během zamítnutí objednávky', body, options);
                        this.loadingMaterial = false;
                        this.adminconfirmationDecline.hide();
                        this.ref.markForCheck();
                    },
                });
        } else {
            const body = 'Nemáte oprávnění provést tuto akci...';
            const options = {progressBar: true, timeOut: 5000};

            this.messages.showError('Nedostatečné oprávnění', body, options);
            this.loadingMaterial = false;
            this.adminconfirmationDecline.hide();
            this.ref.markForCheck();
        }
    }

    editMaterial(): void {
        this.isCopy = false;
        this.isUpdate = true;
        this.renderMaterialFormDialog = true;
        this.ref.markForCheck();
    }

    createItem(): void {
        this.isCopyItem = false;
        this.isCreateItem = true;
        this.isUpdateItem = false;
        this.renderMaterialItemFormDialog = true;
        this.ref.markForCheck();
    }

    copyItem(): void {
        this.isCopyItem = true;
        this.isCreateItem = false;
        this.isUpdateItem = false;
        this.renderMaterialItemFormDialog = true;
        this.ref.markForCheck();
    }

    editItem(): void {
        this.isCopyItem = false;
        this.isCreateItem = false;
        this.isUpdateItem = true;
        this.renderMaterialItemFormDialog = true;
        this.ref.markForCheck();
    }

    deleteItem(): void {
        this.loadingMaterial = true;
        this.materialService
            .deleteItemInvoice(this.clickedRow[0])
            .pipe(untilDestroyed(this))
            .subscribe(
                (data: OrderItem) => {
                    const body = `Položka objednávky #${data.id}`;
                    const options = {progressBar: true, timeOut: 5000};

                    this.messages.showSuccess('Položka objednávky úspěšně smazána', body, options);
                    this.loadingMaterial = false;
                    this.confirmationItemDelete.hide();
                    this.ref.markForCheck();

                    if (!this.material) {
                        console.error('this.material is missing...');

                        return;
                    }

                    this.dataService.setMaterialDetailDataSource(this.material.id);
                },
                error => {
                    console.error(error);
                    this.loadingMaterial = false;
                    this.confirmationItemDelete.hide();
                    this.ref.markForCheck();
                },
            );
    }

    orderRemove(): void {
        this.loadingMaterial = true;

        if (!this.material) {
            console.error('this.material is missing...');

            return;
        }

        this.material.deleted_by = this.currentUser?.id;
        this.materialService
            .deleteInvoice(this.material)
            .pipe(untilDestroyed(this))
            .subscribe(
                (data: Material) => {
                    const body = `Objednávka #${data.id}`;
                    const options = {progressBar: true, timeOut: 5000};

                    this.messages.showSuccess('Objednávka úspěšně smazána', body, options);
                    this.loadingMaterial = false;
                    this.confirmationDelete.hide();
                    this.ref.markForCheck();

                    if (!this.material) {
                        console.error('this.material is missing...');

                        return;
                    }

                    this.dataService.setMaterialDetailDataSource(this.material.id);
                },
                error => {
                    console.error(error);

                    const body = 'Zkuste to později ...';
                    const options = {progressBar: true, timeOut: 5000};

                    this.messages.showError('Chyba během storna objednávky', body, options);
                    this.loadingMaterial = false;
                    this.confirmationDelete.hide();
                    this.ref.markForCheck();
                },
            );
    }

    // Files
    applyDeafultUploadSetting(): void {
        if (this.uploadObj && this.material) {
            this.uploadObj.asyncSettings.saveUrl = `${this.environmentService.backendURL}/api/upload/material?type=material&id=${this.material.id}`;
        }
    }

    downloadFile(file: MaterialFile): void {
        this.materialService
            .downloadFile(file)
            .pipe(untilDestroyed(this))
            .subscribe(
                data => {
                    saveAs(data, file.name);
                },
                err => {
                    alert('Chyba během stahování souboru.');
                    console.error(err);
                },
            );
    }

    onFileSelected(args: SelectedEventArgs): void {
        if (this.uploadObj && this.material) {
            this.uploadObj.asyncSettings.saveUrl = `${this.environmentService.backendURL}/api/upload/material?type=material&id=${this.material.id}`;
            // Filter the 5 files only to showcase
            args.filesData.splice(5);

            const filesData: FileInfo[] = this.uploadObj.getFilesData();
            const allFiles: FileInfo[] = filesData.concat(args.filesData);

            if (allFiles.length > 5) {
                // eslint-disable-next-line @typescript-eslint/prefer-for-of
                for (let i = 0; i < allFiles.length; i++) {
                    if (allFiles.length > 5) {
                        allFiles.shift();
                    }
                }

                args.filesData = allFiles;
                // set the modified custom data
                args.modifiedFilesData = args.filesData;
            }

            let existingFiles: FileInfo[] = this.uploadObj.getFilesData();

            for (let i = 0; i < args.filesData.length; i++) {
                // eslint-disable-next-line @typescript-eslint/prefer-for-of
                for (let j = 0; j < existingFiles.length; j++) {
                    if (!isNullOrUndefined(args.filesData[i])) {
                        // eslint-disable-next-line eqeqeq
                        if (existingFiles[j].name == args.filesData[i].name) {
                            args.filesData.splice(i, 1);
                        }
                    }
                }
            }

            existingFiles = existingFiles.concat(args.filesData);
            args.modifiedFilesData = existingFiles;
            args.isModified = true;
        }
    }

    beforeFileUpload(args: BeforeUploadEventArgs): void {
        if (this.currentUser && this.material) {
            args.customFormData = [{id: this.material.id}, {created_by: this.currentUser.id}];
        }
    }

    onFileUpload: EmitType<SelectedEventArgs> = (args: UploadingEventArgs) => {
        if (this.currentUser && this.material) {
            args.currentRequest?.setRequestHeader(
                'Authorization',
                `Bearer ${this.currentUser.token}`,
            );
            args.customFormData = [{id: this.material.id}, {created_by: this.currentUser.id}];
        }
    };

    onSuccessUpload(args: SuccessEventArgs): void {
        if (args.file) {
            const body = `Soubor: ${args.file.name}`;
            const options = {progressBar: true, timeOut: 5000, toastClass: 'success'};

            this.messages.showSuccess('Soubor úspěšně nahrán', body, options);

            if (!this.material) {
                console.error('this.material is missing...');

                return;
            }

            this.dataService.setMaterialDetailDataSource(this.material);
        }
    }

    removeMaterialFile(): void {
        this.loadingMaterial = true;

        const file = this.material?.receipts.find(x => x.id === this.deleteFileId);

        if (file) {
            file.deleted_by = this.currentUser?.id;
            this.materialService
                .deleteFile(file)
                .pipe(untilDestroyed(this))
                .subscribe({
                    next: (data: MaterialFile | undefined) => {
                        const body = `Soubor #${data?.id ?? 'null'}`;
                        const options = {progressBar: true, timeOut: 5000};

                        this.messages.showSuccess('Soubor úspěšně smazán', body, options);
                        this.loadingMaterial = false;
                        this.ref.markForCheck();

                        if (!this.material?.id) {
                            return;
                        }

                        this.dataService.setMaterialDetailDataSource(this.material.id);
                    },
                    error: error => {
                        alert('Chyba během mazání souboru.');
                        this.loadingMaterial = false;
                        this.ref.markForCheck();
                        console.error(error);
                    },
                });
        }
    }
}
