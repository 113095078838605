import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    OnChanges,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, ValidatorFn, Validators} from '@angular/forms';
import {
    FontFamilyModel,
    ImageSettingsModel,
    QuickToolbarSettingsModel,
    RichTextEditorComponent,
} from '@syncfusion/ej2-angular-richtexteditor';
import {ToolbarModule} from '@syncfusion/ej2-angular-navigations';
import {DialogComponent} from '@syncfusion/ej2-angular-popups';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {DataService} from '@src/app/_services/data.service';
import {AuthenticationService} from '@src/app/_services/authentication.service';
import {MessageService} from '@src/app/_services/message.service';
import {User} from '@src/app/_models/user/user';
import {Observable} from 'rxjs';
import {addClass, Browser, createElement, EmitType, removeClass} from '@syncfusion/ej2-base';
import {DropDownListComponent, FilteringEventArgs, MultiSelectComponent} from '@syncfusion/ej2-angular-dropdowns';
import {Query} from '@syncfusion/ej2-data';
import {TaskCategory} from '@src/app/_models/task/task-category';
import {Tags} from '@src/app/_models/tags/tags';
import {Department} from '@src/app/_models/department/department';
import {Task} from '@src/app/_models/task/task';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import moment from 'moment';
import localeCs from '@angular/common/locales/cs';
import {registerLocaleData} from '@angular/common';
import {Tickets} from '@src/app/_models/ticket/tickets';
import {ComponentCanDeactivate} from '@src/app/_guards/changes.guard';
import {EnvironmentService} from '@src/app/_services/environment.service';
import {MomentInput} from 'moment/moment';
import {TaskInput, TasksService} from '@src/app/features/tasks/tasks.service';
import {TicketsService} from '@src/app/features/tickets/tickets.service';

moment.updateLocale('cs', {workingWeekdays: [1, 2, 3, 4, 5]});
registerLocaleData(localeCs);

@UntilDestroy()
@Component({
    selector: 'app-task-form',
    templateUrl: './task-form.component.html',
    styleUrls: ['./task-form.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TaskFormComponent implements OnInit, OnChanges, AfterViewInit, ComponentCanDeactivate {
    // RTE
    maxLength = 15000;

    textArea: HTMLElement;

    insertImageSettings: ImageSettingsModel;

    fontFamily: FontFamilyModel = {
        items: [
            {
                text: 'Segoe UI',
                value: 'Segoe UI',
                command: 'Font',
                subCommand: 'FontName',
            },
            {
                text: 'Roboto',
                value: 'Roboto',
                command: 'Font',
                subCommand: 'FontName',
            },
            {
                text: 'Great vibes',
                value: 'Great Vibes,cursive',
                command: 'Font',
                subCommand: 'FontName',
            },
            {
                text: 'Noto Sans',
                value: 'Noto Sans',
                command: 'Font',
                subCommand: 'FontName',
            },
            {
                text: 'Impact',
                value: 'Impact,Charcoal,sans-serif',
                command: 'Font',
                subCommand: 'FontName',
            },
            {
                text: 'Tahoma',
                value: 'Tahoma,Geneva,sans-serif',
                command: 'Font',
                subCommand: 'FontName',
            },
        ],
    };

    tools: ToolbarModule = {
        type: 'MultiRow',
        items: [
            'Bold',
            'Italic',
            'Underline',
            'StrikeThrough',
            'FontName',
            'FontSize',
            'FontColor',
            'BackgroundColor',
            'LowerCase',
            'UpperCase',
            'SuperScript',
            'SubScript',
            '|',
            'Formats',
            'Alignments',
            'OrderedList',
            'UnorderedList',
            'Outdent',
            'Indent',
            '|',
            'CreateTable',
            'CreateLink',
            'Image',
            '|',
            'ClearFormat',
            'Print',
            'SourceCode',
            'FullScreen',
            '|',
            'Undo',
            'Redo',
        ],
    };

    quickToolbarSettings: QuickToolbarSettingsModel = {
        table: [
            'TableHeader',
            'TableRows',
            'TableColumns',
            'TableCell',
            '-',
            'BackgroundColor',
            'TableRemove',
            'TableCellVerticalAlign',
            'Styles',
        ],
    };

    pasteCleanupSettings: object = {
        prompt: true,
        plainText: false,
        keepFormat: false,
        deniedTags: ['a'],
        deniedAttrs: ['class', 'title', 'id'],
        allowedStyleProps: ['color', 'margin', 'font-size'],
    };

    // Dropdowns
    height = '240px';

    fields: object = {text: 'label', value: 'value'};

    tagsAutocomplete: Array<{id: number; name: string}> = [];

    usersSelect: Array<{value: boolean | number | string; label: string}> = [];

    categorySelect: Array<{value: boolean | number | string; label: string}> = [];

    departmentSelect: Array<{value: boolean | number | string; label: string}> = [];

    // Froms
    taskForm: FormGroup;

    isDirty = false;

    isChecked = false;

    submited = false;

    // Variables
    today = new Date();

    currentUser: User | null;

    // Loaders
    taskLoading = false;

    targetElement?: HTMLElement;

    @Input() task?: Task | null = null;

    @Input() ticket?: Tickets | null = null;

    @Input() isCreate = false;

    @Input() isUpdate = false;

    @Input() isCopy = false;

    @Input() isVisible = false;

    @Output() readonly taskFormDialogState = new EventEmitter<boolean>();

    // RTE
    @ViewChild('mainTask') mainTaskObj?: RichTextEditorComponent;

    // Dialogs
    @ViewChild('formTaskDialog') formTaskDialogObj: DialogComponent;

    // Create element reference for dialog target element.
    @ViewChild('container', {read: ElementRef}) container: ElementRef | any;

    // Dropdowns
    @ViewChild('category') categoryObj: DropDownListComponent;

    @ViewChild('user') userObj: MultiSelectComponent;


    constructor(
        private readonly router: Router,
        private readonly route: ActivatedRoute,
        private readonly ref: ChangeDetectorRef,
        private readonly formBuilder: FormBuilder,
        private readonly dataService: DataService,
        private readonly taskService: TasksService,
        private readonly ticketService: TicketsService,
        private readonly authenticationService: AuthenticationService,
        private readonly messages: MessageService,
        private readonly environmentService: EnvironmentService,
    ) {
        this.currentUser = this.authenticationService.currentUserValue;
        this.insertImageSettings = {
            path: `${this.environmentService.backendURL}/api/file/preview/task/`,
            saveUrl: `${this.environmentService.backendURL}/api/upload/attachments/tasks?type=attachments&subfolder=tasks`,
        };
    }

    get f(): {[key: string]: AbstractControl} {
        return this.taskForm.controls;
    }

    @HostListener('window:beforeunload')
    canDeactivate(): Observable<boolean> | boolean {
        return !this.isDirty;
    }

    lessThanDate(fieldDate: string): ValidatorFn {
        return (control: AbstractControl): {lessThan: {value: string}} | null => {
            const fieldToDateCompare = control.parent?.get(fieldDate);
            const endDate = control.value;
            const startDate = fieldToDateCompare?.value;
            const isLessThan =
                Number(moment(startDate as MomentInput)
                    .valueOf()) >
                Number(moment(endDate as MomentInput)
                    .valueOf());

            return isLessThan ? {lessThan: {value: control.value}} : null;
        };
    }

    greaterThanDate(fieldDate: string): ValidatorFn {
        return (control: AbstractControl): {greaterThan: {value: string}} | null => {
            const fieldToDateCompare = control.parent?.get(fieldDate);
            const startDate = control.value;
            const endDate = fieldToDateCompare?.value;
            const isGreaterThan =
                Number(moment(startDate as MomentInput)
                    .valueOf()) >
                Number(moment(endDate as MomentInput)
                    .valueOf());

            return isGreaterThan ? {greaterThan: {value: control.value}} : null;
        };
    }

    ngOnInit(): void {
        this.dataService.userSource.pipe(untilDestroyed(this))
            .subscribe(
                (data: User[]) => {
                    this.usersSelect = [];
                    data.map((user: User) => {
                        if (user.authorized && !user.deleted_date) {
                            this.usersSelect = [
                                ...this.usersSelect,
                                {
                                    value: user.id,
                                    label: `${user.firstname} ${user.secondname}`,
                                },
                            ];
                        }
                    });
                },
                error => {
                    console.error(error);
                },
            );

        this.taskService
            .getActiveCategories()
            .pipe(untilDestroyed(this))
            .subscribe(
                (data: TaskCategory[]) => {
                    this.categorySelect = [];
                    data.map((category: TaskCategory) => {
                        this.categorySelect = [
                            ...this.categorySelect,
                            {
                                value: category.id,
                                label: category.name,
                            },
                        ];
                    });
                },
                error => {
                    console.error(error);
                },
            );

        this.dataService.tagSource.pipe(untilDestroyed(this))
            .subscribe(
                (data: Tags[]) => {
                    this.tagsAutocomplete = [];
                    data.map((tag: Tags) => {
                        this.tagsAutocomplete = [
                            ...this.tagsAutocomplete,
                            {
                                id: tag.id,
                                name: tag.name,
                            },
                        ];
                    });
                },
                error => {
                    console.error(error);
                },
            );

        this.dataService.departmentSource.pipe(untilDestroyed(this))
            .subscribe(
                (data: Department[]) => {
                    this.departmentSelect = [];
                    data.map((department: Department) => {
                        this.departmentSelect = [
                            ...this.departmentSelect,
                            {
                                value: department.id,
                                label: `${department.company.name} - ${department.name}`,
                            },
                        ];
                    });
                },
                error => {
                    console.error(error);
                },
            );

        this.taskForm = this.formBuilder.group({
            taskSolvers: ['', Validators.required],
            taskSubject: [
                '',
                Validators.compose([
                    Validators.required,
                    Validators.minLength(2),
                    Validators.maxLength(100),
                ]),
            ],
            taskMaintask: ['', Validators.required],
            tags: [[]],
            taskCategory: [1, Validators.compose([Validators.required])],
            ticket_id: [null],
            taskStartDeadlineDate: [null, Validators.compose([Validators.required])],
            taskDeadlineDate: [null, Validators.compose([Validators.required])],
        });

        this.taskForm
            .get('taskDeadlineDate')
            ?.addValidators(this.lessThanDate('taskStartDeadlineDate'));

        this.taskForm
            .get('taskStartDeadlineDate')
            ?.addValidators(this.greaterThanDate('taskDeadlineDate'));

        this.taskForm.valueChanges.pipe(untilDestroyed(this))
            .subscribe(() => {
                this.isDirty = this.taskForm.dirty;
                this.showInputErrors();
            });
    }

    ngOnChanges(): void {
        if (this.isVisible) {
            const queryParams: Params = {form: null};

            void this.router.navigate([], {
                relativeTo: this.route,
                queryParams,
                queryParamsHandling: 'merge',
            });

            if (this.isUpdate && this.task) {
                this.formTaskDialogObj.header = `Úkol #${this.task.id} - ${this.task.subject}`;
                this.fillTheForm();
                this.showInputErrors();
            }

            if (this.isCreate) {
                this.taskForm.reset();
                this.formTaskDialogObj.header = 'Nový úkol';

                if (this.ticket) {
                    this.taskForm.controls.tags.patchValue(
                        this.ticket.tags.length > 0 ? this.ticket.tags : [],
                    );

                    this.taskForm.controls.taskCategory.patchValue(
                        this.ticket.category_id ? this.ticket.category_id : 1,
                    );

                    this.taskForm.controls.taskStartDeadlineDate.patchValue(
                        this.ticket.start_deadline
                            ? moment(this.ticket.start_deadline)
                                .format('YYYY-MM-DD HH:mm')
                            : moment()
                                .format('YYYY-MM-DD HH:mm'),
                    );

                    this.taskForm.controls.taskDeadlineDate.patchValue(
                        this.ticket.deadline
                            ? moment(this.ticket.deadline)
                                .format('YYYY-MM-DD HH:mm')
                            : moment()
                                .format('YYYY-MM-DD HH:mm'),
                    );
                    this.taskForm.controls.ticket_id.patchValue(this.ticket.id);
                }
            }

            if (this.isCopy) {
                this.taskForm.reset();
                this.formTaskDialogObj.header = 'Nový úkol';
                this.fillTheForm();
                this.showInputErrors();
            }
        }
    }

    ngAfterViewInit(): void {
        this.initilaizeTarget();
    }

    // Initialize the Dialog component target element.
    initilaizeTarget: EmitType<object> = () => {
        this.targetElement = this.container.nativeElement.parentElement;
    };

    closeModal(): void {
        this.isUpdate = false;
        this.isCreate = false;
        this.isDirty = false;
        this.isChecked = false;
        this.isCopy = false;
        this.submited = false;
        this.ref.markForCheck();
        this.changeDialogState(false);
    }

    changeDialogState(value: boolean): void {
        this.taskFormDialogState.emit(value);
    }

    onFilteringUsers: EmitType<FilteringEventArgs> = (e: FilteringEventArgs) => {
        if (e.text === '') {
            e.updateData(this.usersSelect);
        } else {
            let query: Query = new Query();

            query = e.text !== '' ? query.where('label', 'contains', e.text, true, true) : query;
            e.updateData(this.usersSelect, query);
        }
    };

    onFilteringCategories: EmitType<FilteringEventArgs> = (e: FilteringEventArgs) => {
        if (e.text === '') {
            e.updateData(this.categorySelect);
        } else {
            let query = new Query();

            query = e.text !== '' ? query.where('label', 'contains', e.text, true) : query;
            e.updateData(this.categorySelect, query);
        }
    };

    fillTheForm(): void {
        this.taskForm.controls.taskSubject.patchValue(this.task?.subject);
        this.taskForm.controls.taskMaintask.patchValue(this.task?.maintask);
        this.taskForm.controls.tags.patchValue(this.task?.tags);
        this.taskForm.controls.taskCategory.patchValue(this.task?.category_id);
        this.taskForm.controls.taskStartDeadlineDate.patchValue(this.task?.start_deadline);
        this.taskForm.controls.taskDeadlineDate.patchValue(this.task?.deadline);
        this.taskForm.controls.taskSolvers.patchValue([this.task?.user_id]);
        this.taskForm.controls.ticket_id.patchValue(this.ticket ? this.ticket.id : null);
    }

    onSubmit(): void {
        this.submited = true;
        this.isDirty = false;
        this.taskLoading = true;

        if (this.isCreate || this.isCopy) {
            this.createTask();
        }

        if (this.isUpdate) {
            this.editTask();
        }
    }

    createTask(): void {
        if (this.taskForm.invalid) {
            console.error('form is not valid!');

            return;
        }

        const users: number[] = this.f.taskSolvers.value;

        if (users.length > 0) {
            const task = {
                subject: this.f.taskSubject.value,
                maintask: this.f.taskMaintask.value,
                category_id: this.f.taskCategory.value,
                tags: this.f.tags.value,
                start_deadline: this.f.taskStartDeadlineDate.value
                    ? moment(this.f.taskStartDeadlineDate.value as MomentInput)
                        .format(
                            'YYYY-MM-DD HH:mm:ss',
                        )
                    : moment()
                        .format('YYYY-MM-DD HH:mm:ss'),
                deadline: moment(this.f.taskDeadlineDate.value as MomentInput)
                    .format(
                        'YYYY-MM-DD HH:mm:ss',
                    ),
                users,
                created_by: this.currentUser?.id,
                ticket_id: this.f.ticket_id.value ? this.f.ticket_id.value : null,
            };

            this.taskService
                .addTask(task)
                .pipe(untilDestroyed(this))
                .subscribe(
                    (data: Task) => {
                        const currUser: User = data.solver;
                        const body = `#${data.id} úkol delegován uživateli: ${currUser.fullname}`;
                        const options = {
                            progressBar: true,
                            timeOut: 5000,
                            toastClass: 'success',
                        };

                        this.messages.showSuccess('Úkol úspěšně vytvořen!', body, options);
                        this.dataService.setTagsDataSource();
                        this.dataService.setTasksDataSource();

                        if (this.f.ticket_id.value && task.created_by) {
                            this.ticketService
                                .setAssignations(
                                    this.f.ticket_id.value as number,
                                    this.currentUser ?? task.created_by,
                                    users,
                                )
                                .pipe(untilDestroyed(this))
                                .subscribe(
                                    () => {
                                        const bodyUser =
                                            'Uživatel úspěšně přidán jako řešitel ticketu';
                                        const optionsUser = {
                                            progressBar: true,
                                            timeOut: 5000,
                                            toastClass: 'success',
                                        };

                                        this.messages.showSuccess(
                                            'Zapojení uživatle do ticketu',
                                            bodyUser,
                                            optionsUser,
                                        );
                                    },
                                    error => {
                                        console.error(error);

                                        const bodyUser = 'Zkuste to znovu později';
                                        const optionsUser = {
                                            progressBar: true,
                                            timeOut: 5000,
                                            toastClass: 'red',
                                        };

                                        this.messages.showError(
                                            'Chyba při vytváření zapojení uživatele',
                                            bodyUser,
                                            optionsUser,
                                        );
                                    },
                                );
                        }

                        this.taskLoading = false;
                        this.ref.markForCheck();
                        this.formTaskDialogObj.hide();
                        void this.router.navigate(['/tasks/detail', data.id]);
                    },
                    error => {
                        console.error(error);

                        const body = 'Zkuste to znovu později';
                        const options = {
                            progressBar: true,
                            timeOut: 5000,
                            toastClass: 'red',
                        };

                        this.messages.showError('Chyba při vytváření úkolu', body, options);
                        this.taskLoading = false;
                        this.ref.markForCheck();
                    },
                );
        }
    }

    editTask(): void {
        if (this.taskForm.invalid) {
            console.error('form is not valid!');

            return;
        }

        if (this.f.taskSolvers.value) {
            this.f.taskSolvers.value.forEach(userId => {
                const task: TaskInput = {
                    id: this.task?.id,
                    subject: this.f.taskSubject.value,
                    maintask: this.f.taskMaintask.value,
                    category_id: this.f.taskCategory.value,
                    tags: this.f.tags.value,
                    start_deadline: this.f.taskStartDeadlineDate.value
                        ? moment(this.f.taskStartDeadlineDate.value as MomentInput)
                            .format(
                                'YYYY-MM-DD HH:mm:ss',
                            )
                        : moment()
                            .format('YYYY-MM-DD HH:mm:ss'),
                    deadline: moment(this.f.taskDeadlineDate.value as MomentInput)
                        .format(
                            'YYYY-MM-DD HH:mm:ss',
                        ),
                    user_id: userId,
                    status_id: this.task?.status_id,
                    updated_by: this.currentUser?.id,
                    ticket_id: this.f.ticket_id.value ? this.f.ticket_id.value : null,
                };

                this.taskService
                    .updateTask(task)
                    ?.pipe(untilDestroyed(this))
                    .subscribe(
                        (data: Task) => {
                            const body = `úkol #${data.id}`;
                            const options = {
                                progressBar: true,
                                timeOut: 5000,
                                toastClass: 'success',
                            };

                            this.messages.showSuccess('Úkol úspěšně upraven', body, options);
                            this.dataService.setTagsDataSource();
                            this.dataService.setTasksDataSource();
                            this.dataService.setTaskDetailSource(data.id);
                            this.taskLoading = false;
                            this.ref.markForCheck();
                            this.formTaskDialogObj.hide();
                        },
                        error => {
                            console.error(error);

                            const body = 'Zkuste to znovu později';
                            const options = {
                                progressBar: true,
                                timeOut: 5000,
                                toastClass: 'red',
                            };

                            this.messages.showError('Chyba při úpravě úkolu', body, options);
                            this.taskLoading = false;
                            this.ref.markForCheck();
                        },
                    );
            });
        }
    }

    // RTE
    onCreateRte(): void {
        this.mainTaskObj?.refreshUI();
        this.mainTaskObj?.autoResize();
    }

    handleFullScreen(e: unknown): void {
        const sbCntEle: HTMLElement | null = document.querySelector('.sb-content.e-view');
        const sbHdrEle: HTMLElement | null = document.querySelector('.sb-header.e-view');
        const leftBar: HTMLElement | null = document.querySelector('#left-sidebar');

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        if (e.targetItem === 'Maximize') {
            if (Browser.isDevice && Browser.isIos && sbCntEle && sbHdrEle) {
                addClass([sbCntEle, sbHdrEle], ['hide-header']);
            }

            if (leftBar) {
                addClass([leftBar], ['e-close']);
                removeClass([leftBar], ['e-open']);
            }
        }
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        else if (e.targetItem === 'Minimize') {
            if (Browser.isDevice && Browser.isIos && sbCntEle && sbHdrEle) {
                removeClass([sbCntEle, sbHdrEle], ['hide-header']);
            }

            if (leftBar) {
                removeClass([leftBar], ['e-close']);

                if (!Browser.isDevice) {
                    addClass([leftBar], ['e-open']);
                }
            }
        }
    }

    mirrorConversion(e: unknown): void {
        if (this.mainTaskObj) {
            const id = `${this.mainTaskObj.getID()}mirror-view`;
            let mirrorView: HTMLElement | null = this.mainTaskObj.element.querySelector(`#${id}`);
            const charCount: HTMLElement | null =
                this.mainTaskObj.element.querySelector('.e-rte-character-count');

            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            if (e.targetItem === 'Preview' && mirrorView && charCount) {
                this.textArea.style.display = 'block';
                mirrorView.style.display = 'none';
                // eslint-disable-next-line max-len
                // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-member-access
                charCount.style.display = 'block';
            } else {
                if (!mirrorView && this.textArea.parentNode) {
                    mirrorView = createElement('div', {className: 'e-content'});
                    mirrorView.id = id;
                    this.textArea.parentNode.appendChild(mirrorView);
                } else if (mirrorView) {
                    mirrorView.innerHTML = '';
                }

                if (mirrorView) {
                    this.textArea.style.display = 'none';
                    mirrorView.style.display = 'block';
                }

                if (charCount) {
                    charCount.style.display = 'none';
                }
            }
        }
    }

    actionCompleteHandler(e: unknown): void {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        if (e.targetItem && (e.targetItem === 'SourceCode' || e.targetItem === 'Preview')) {
            (this.mainTaskObj?.sourceCodeModule.getPanel() as HTMLTextAreaElement).style.display =
                'none';
            this.mirrorConversion(e);
        } else {
            setTimeout(() => {
                this.mainTaskObj?.toolbarModule.refreshToolbarOverflow();
            }, 400);
        }
    }

    showInputErrors(): void {
        this.isChecked = true;
        /*  Object.keys(this.taskForm.controls).forEach(key => {
        const controlErrors: ValidationErrors = this.taskForm.get(key).errors;
        if (controlErrors != null) {
            Object.keys(controlErrors).forEach(keyError => {
                console.log('Key control: ' + key + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError]);
            });
        }
    });*/
        this.taskForm
            .get('taskDeadlineDate')
            ?.updateValueAndValidity({onlySelf: true, emitEvent: false});

        this.taskForm
            .get('taskStartDeadlineDate')
            ?.updateValueAndValidity({onlySelf: true, emitEvent: false});
        this.taskForm.markAllAsTouched();
        this.ref.markForCheck();
    }
}
