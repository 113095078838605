import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {BehaviorSubject, distinctUntilChanged, Observable, of} from 'rxjs';
import {Tickets} from '@src/app/_models/ticket/tickets';
import {catchError, map, share, shareReplay, tap} from 'rxjs/operators';
import {MessageService} from '@src/app/_services/message.service';
import {Task} from '@src/app/_models/task/task';
import {Vacation} from '@src/app/_models/vacation/vacation';
import {User} from '@src/app/_models/user/user';
import {Material} from '@src/app/_models/material/material';
import {MaterialBudget} from '@src/app/_models/material/material-budget';
import {Knowledge} from '@src/app/_models/knowledge/knowledge';
import {Hotline} from '@src/app/_models/hotline/hotline';
import {PermissionService} from '@src/app/_services/permission.service';
import {TaskNotes} from '@src/app/_models/task/task-notes';
import {TicketNotes} from '@src/app/_models/ticket/ticket-notes';
import {TicketComment} from '@src/app/_models/ticket/ticket-comment';
import {TicketFile} from '@src/app/_models/ticket/ticket-file';
import {Router} from '@angular/router';
import {Assignation} from '@src/app/_models/assignation/assignation';
import {ApisService} from '@src/app/_api/apis.service';
import {AcsService} from '@src/app/_api/acs/acs.service';
import {Notifications} from '@src/app/_models/notification/notification';
import {DomSanitizer} from '@angular/platform-browser';
import {Gallery} from '@src/app/_models/ticket/gallery';
import {Company} from '@src/app/_models/company/company';
import {AuthenticationService} from '@src/app/_services/authentication.service';
import {Department} from '@src/app/_models/department/department';
import {Role} from '@src/app/_models/role/role';
import {Employer} from '@src/app/_models/employer/employer';
import {ApiConnections} from '@src/app/_models/api/connections';
import {ApiProtocols} from '@src/app/_models/api/protocols';
import {ApiTypes} from '@src/app/_models/api/types';
import {ApiHeaders} from '@src/app/_models/api/headers';
import {ApiPaths} from '@src/app/_models/api/paths';
import {Client} from '@src/app/_models/clients/client';
import {Hotliner} from '@src/app/_models/hotline/hotliner';
import {Location, registerLocaleData} from '@angular/common';
import {HotlineReport} from '@src/app/_models/hotline/report';
import {Event} from '@src/app/_models/event/event';
import {KnowledgeComment} from '@src/app/_models/knowledge/knowledge-comment';
import {ClientService} from '@src/app/_models/clients/client-service';
import {VacationCategory} from '@src/app/_models/vacation/vacation-category';
import {OrderItem} from '@src/app/_models/material/material-item';
import {ApiMaps} from '@src/app/_models/api/maps';
import {Tags} from '@src/app/_models/tags/tags';
import {Project} from '@src/app/_models/projects/project';
import {ClientsWrapper} from '@src/app/_models/clients/clients-wrapper';
import {ClientServicesWrapper} from '@src/app/_models/clients/client-services-wrapper';
import {MaterialCategory} from '@src/app/_models/material/material-category';

import moment, {Moment} from 'moment';
import localeCs from '@angular/common/locales/cs';
import {MonitoringService} from '@src/app/_api/monitoring/monitoring.service';
import {CrmService} from '@src/app/_api/crm/crm.service';
import {ErpService} from '@src/app/_api/erp/erp.service';
import {CarsService} from '@src/app/_api/cars/cars.service';
import {TicketCategory} from '@src/app/_models/ticket/ticket-category';
import {TaskCategory} from '@src/app/_models/task/task-category';
import {KnowledgeCategory} from '@src/app/_models/knowledge/knowledge-category';
import {EnvironmentService} from '@src/app/_services/environment.service';
import {CrmUsers} from '@src/app/_models/_api_crm/users';
import {ErpUsers} from '@src/app/_models/_api_erp/users';
import {AcsUsers} from '@src/app/_models/_api_acs/users';
import {Booking} from '@src/app/_models/booking/book.model';
import {Office} from '@src/app/_models/office/office.model';
import {Car} from '@src/app/_models/cars/car.model';
import {Building} from '@src/app/_models/buildings/building.model';
import {BaseModel} from '@src/app/_models/_base/base-model';
import {TaskComment} from '@src/app/_models/task/task-comment';

moment.updateLocale('cs', {workingWeekdays: [1, 2, 3, 4, 5]});
registerLocaleData(localeCs);

const httpOptions = {
    headers: new HttpHeaders({'Content-Type': 'application/json'}),
};

@Injectable({
    providedIn: 'root',
})
export class DataService {
    private readonly ticketsUrl: string;

    private readonly tasksUrl: string;

    private readonly vacationsUrl: string;

    private readonly vacationsCategoryUrl: string;

    private readonly usersUrl: string;

    private readonly materialsUrl: string;

    private readonly materialsCategoryUrl: string;

    private readonly materialsItemUrl: string;

    private readonly budgetsUrl: string;

    private readonly knowledgeUrl: string;

    private readonly hotlineUrl: string;

    private readonly notificationsUrl: string;

    private readonly commentsTicketUrl: string;

    private readonly commentsKwbtUrl: string;

    private readonly notesTicketUrl: string;

    private readonly companyrUrl: string;

    private readonly departmentUrl: string;

    private readonly roleUrl: string;

    private readonly employerUrl: string;

    private readonly apisUrl: string;

    private readonly apiMapsUrl: string;

    private readonly apiProtocolsUrl: string;

    private readonly apiTypesUrl: string;

    private readonly apiHeadersUrl: string;

    private readonly apiPathsUrl: string;

    private readonly clientUrl: string;

    private readonly clientSchemaUrl: string;

    private readonly clientServiceUrl: string;

    private readonly eventUrl: string;

    private readonly hotlinersUrl: string;

    private readonly tagsUrl: string;

    private readonly projectsUrl: string;

    private readonly buildingsUrl: string;

    private readonly knowledgeCategoryUrl: string;

    private readonly ticketsCategoryUrl: string;

    private readonly tasksCategoryUrl: string;

    private readonly carsUrl: string;

    private readonly officesUrl: string;

    private readonly bookingsUrl: string;

    private resultOldNotificationData: Notifications[] = [];

    private notificationsOld: Observable<Notifications[] | undefined>;

    private notificationsNew: Observable<Notifications[] | undefined>;

    private readonly resultNewNotificationSource = new BehaviorSubject<Notifications[] | undefined>(
        [],
    );

    private readonly resultKnowledgeSource = new BehaviorSubject<Knowledge[] | undefined>([]);

    private readonly resultKnowledgeDetailSource = new BehaviorSubject<
        Knowledge | null | undefined
    >(null);

    private readonly resultClientSource = new BehaviorSubject<Client[]>([]);

    private readonly resultClientSchemaSource = new BehaviorSubject<unknown[]>([] as unknown[]);

    private readonly resultClientServiceSource = new BehaviorSubject<unknown[]>([] as unknown[]);

    private readonly resultClientDetailSource = new BehaviorSubject<Client | null | undefined>(
        null,
    );

    private readonly resultEventSource = new BehaviorSubject<Event[] | undefined>([]);

    private readonly resultEventDetailSource = new BehaviorSubject<Event | null | undefined>(null);

    private readonly resultTagSource = new BehaviorSubject<Tags[] | undefined>([]);

    private readonly resultOldNotificationSource = new BehaviorSubject<Notifications[] | undefined>(
        [],
    );

    private readonly resultCompanySource = new BehaviorSubject<Company[] | undefined>([]);

    private readonly resultCompanyDetailSource = new BehaviorSubject<Company | null | undefined>(
        null,
    );

    private readonly resultDepartmentSource = new BehaviorSubject<Department[] | undefined>([]);

    private readonly resultDepartmentDetailSource = new BehaviorSubject<
        Department | null | undefined
    >(null);

    private readonly resultEmployerSource = new BehaviorSubject<Employer[] | undefined>([]);

    private readonly resultEmployerDetailSource = new BehaviorSubject<Employer | null | undefined>(
        null,
    );

    private readonly resultRoleSource = new BehaviorSubject<Role[] | undefined>([]);

    private readonly resultRoleDetailSource = new BehaviorSubject<Role | null | undefined>(null);

    private readonly resultApiSource = new BehaviorSubject<
        | {
        acs: ApiConnections | boolean;
        crm: ApiConnections | boolean;
        erp: ApiConnections | boolean;
        siteMonitoring: ApiConnections | boolean;
        cars: ApiConnections | boolean;
    }
        | undefined
    >({
        acs: false,
        crm: false,
        erp: false,
        siteMonitoring: false,
        cars: false,
    });

    private readonly resultApiDetailSource = new BehaviorSubject<ApiConnections | null | undefined>(
        null,
    );

    private readonly resultApiPathSource = new BehaviorSubject<ApiPaths[] | undefined>([]);

    private readonly resultApiPathDetailSource = new BehaviorSubject<ApiPaths | null | undefined>(
        null,
    );

    private readonly resultApiMapSource = new BehaviorSubject<ApiMaps[] | undefined>([]);

    private readonly resultApiMapDetailSource = new BehaviorSubject<ApiMaps | null | undefined>(
        null,
    );

    private readonly resultTicketSource = new BehaviorSubject<Tickets[] | undefined>([]);

    private readonly resultTicketCommentSource = new BehaviorSubject<TicketComment[] | undefined>(
        [],
    );

    private readonly resultKnowledgeCommentSource = new BehaviorSubject<
        KnowledgeComment[] | undefined
    >([]);

    private readonly resultTicketDetailSource = new BehaviorSubject<Tickets | null | undefined>(
        null,
    );

    private readonly resultTaskSource = new BehaviorSubject<Task[] | undefined>([]);

    private readonly resultTaskDetailSource = new BehaviorSubject<Task | null | undefined>(null);

    private readonly resultUserSource = new BehaviorSubject<User[] | undefined>([]);

    private readonly resultUserDetailSource = new BehaviorSubject<User | null | undefined>(null);

    private readonly resultVacationSource = new BehaviorSubject<Vacation[] | undefined>([]);

    private readonly resultVacationPerUserSource = new BehaviorSubject<Vacation[] | undefined>([]);

    private readonly resultVacationDetailSource = new BehaviorSubject<Vacation | null | undefined>(
        null,
    );

    private readonly resultVacationCategoriesSource = new BehaviorSubject<
        VacationCategory[] | undefined
    >([]);

    private readonly resultMaterialSource = new BehaviorSubject<Material[] | undefined>([]);

    private readonly resultMaterialCategorySource = new BehaviorSubject<
        MaterialCategory[] | undefined
    >([]);

    private readonly resultMaterialItemSource = new BehaviorSubject<OrderItem[] | undefined>([]);

    private readonly resultMaterialDetailSource = new BehaviorSubject<Material | null | undefined>(
        null,
    );

    private readonly resultBudgetSource = new BehaviorSubject<MaterialBudget[] | undefined>([]);

    private readonly resultHotlineSource = new BehaviorSubject<Hotline[] | undefined>([]);

    private readonly resultHotlinersSource = new BehaviorSubject<Hotliner[] | undefined>([]);

    private readonly resultHotlineReportSource = new BehaviorSubject<HotlineReport[] | undefined>(
        [],
    );

    private readonly resultHotlineDetailSource = new BehaviorSubject<Hotline | null | undefined>(
        null,
    );

    private readonly resultHotlinerDetailSource = new BehaviorSubject<Hotliner | null | undefined>(
        null,
    );

    private readonly resultProjectSource = new BehaviorSubject<Project[] | undefined>([]);

    private readonly resultTicketCategorySource = new BehaviorSubject<TicketCategory[] | undefined>(
        [],
    );

    private readonly resultTaskCategorySource = new BehaviorSubject<TaskCategory[] | undefined>([]);

    private readonly resultKnowledgeCategorySource = new BehaviorSubject<
        KnowledgeCategory[] | undefined
    >([]);

    private readonly resultCarsSource = new BehaviorSubject<Car[] | undefined>([]);

    private readonly resultOfficesSource = new BehaviorSubject<Office[] | undefined>([]);

    private readonly resultBookingSource = new BehaviorSubject<Booking[] | undefined>([]);

    loadingBookingSource = new BehaviorSubject<boolean | null>(null);

    private readonly resultBuildingsSource = new BehaviorSubject<Building[] | undefined>([]);

    newNotificationSource = this.resultNewNotificationSource
        .asObservable()
        .pipe(distinctUntilChanged());

    oldNotificationSource = this.resultOldNotificationSource
        .asObservable()
        .pipe(distinctUntilChanged());

    companySource = this.resultCompanySource.asObservable()
        .pipe(distinctUntilChanged());

    companyDetailSource = this.resultCompanyDetailSource
        .asObservable()
        .pipe(distinctUntilChanged());

    departmentSource = this.resultDepartmentSource.asObservable()
        .pipe(distinctUntilChanged());

    departmentDetailSource = this.resultDepartmentDetailSource
        .asObservable()
        .pipe(distinctUntilChanged());

    employerSource = this.resultEmployerSource.asObservable()
        .pipe(distinctUntilChanged());

    employerDetailSource = this.resultEmployerDetailSource
        .asObservable()
        .pipe(distinctUntilChanged());

    roleSource = this.resultRoleSource.asObservable()
        .pipe(distinctUntilChanged());

    roleDetailSource = this.resultRoleDetailSource.asObservable()
        .pipe(distinctUntilChanged());

    apiSource = this.resultApiSource.asObservable()
        .pipe(distinctUntilChanged());

    apiDetailSource = this.resultApiDetailSource.asObservable()
        .pipe(distinctUntilChanged());

    apiPathSource = this.resultApiPathSource.asObservable()
        .pipe(distinctUntilChanged());

    apiPathDetailSource = this.resultApiPathDetailSource
        .asObservable()
        .pipe(distinctUntilChanged());

    apiMapSource = this.resultApiMapSource.asObservable()
        .pipe(distinctUntilChanged());

    apiMapDetailSource = this.resultApiMapDetailSource.asObservable()
        .pipe(distinctUntilChanged());

    ticketSource = this.resultTicketSource.asObservable()
        .pipe(distinctUntilChanged());

    ticketCommentSource = this.resultTicketCommentSource
        .asObservable()
        .pipe(distinctUntilChanged());

    knowledgeCommentSource = this.resultKnowledgeCommentSource
        .asObservable()
        .pipe(distinctUntilChanged());

    ticketDetailSource = this.resultTicketDetailSource.asObservable()
        .pipe(distinctUntilChanged());

    taskSource = this.resultTaskSource.asObservable()
        .pipe(distinctUntilChanged());

    taskDetailSource = this.resultTaskDetailSource.asObservable()
        .pipe(distinctUntilChanged());

    userSource = this.resultUserSource.asObservable()
        .pipe(distinctUntilChanged());

    userDetailSource = this.resultUserDetailSource.asObservable()
        .pipe(distinctUntilChanged());

    vacationSource = this.resultVacationSource.asObservable()
        .pipe(distinctUntilChanged());

    vacationPerUserSource = this.resultVacationPerUserSource
        .asObservable()
        .pipe(distinctUntilChanged());

    vacationDetailSource = this.resultVacationDetailSource
        .asObservable()
        .pipe(distinctUntilChanged());

    vacationCategoriesSource = this.resultVacationCategoriesSource
        .asObservable()
        .pipe(distinctUntilChanged());

    materialSource = this.resultMaterialSource.asObservable()
        .pipe(distinctUntilChanged());

    materialCategorySource = this.resultMaterialCategorySource
        .asObservable()
        .pipe(distinctUntilChanged());

    materialItemSource = this.resultMaterialItemSource.asObservable()
        .pipe(distinctUntilChanged());

    materialDetailSource = this.resultMaterialDetailSource
        .asObservable()
        .pipe(distinctUntilChanged());

    budgetSource = this.resultBudgetSource.asObservable()
        .pipe(distinctUntilChanged());

    hotlineSource = this.resultHotlineSource.asObservable()
        .pipe(distinctUntilChanged());

    hotlinersSource = this.resultHotlinersSource.asObservable()
        .pipe(distinctUntilChanged());

    hotlineReportSource = this.resultHotlineReportSource
        .asObservable()
        .pipe(distinctUntilChanged());

    hotlineDetailSource = this.resultHotlineDetailSource
        .asObservable()
        .pipe(distinctUntilChanged());

    hotlinerSource = this.resultHotlinerDetailSource.asObservable()
        .pipe(distinctUntilChanged());

    knowledgeSource = this.resultKnowledgeSource.asObservable();

    knowledgeDetailSource = this.resultKnowledgeDetailSource
        .asObservable()
        .pipe(distinctUntilChanged());

    clientSource = this.resultClientSource.asObservable()
        .pipe(distinctUntilChanged());

    clientSchemaSource = this.resultClientSchemaSource.asObservable()
        .pipe(distinctUntilChanged());

    serviceSource = this.resultClientServiceSource.asObservable()
        .pipe(distinctUntilChanged());

    eventSource = this.resultEventSource.asObservable()
        .pipe(distinctUntilChanged());

    clientDetailSource = this.resultClientDetailSource.asObservable()
        .pipe(distinctUntilChanged());

    eventDetailSource = this.resultEventDetailSource.asObservable()
        .pipe(distinctUntilChanged());

    tagSource = this.resultTagSource.asObservable()
        .pipe(distinctUntilChanged());

    projectSource = this.resultProjectSource.asObservable()
        .pipe(distinctUntilChanged());

    ticketCategoriesSource = this.resultTicketCategorySource
        .asObservable()
        .pipe(distinctUntilChanged());

    taskCategoriesSource = this.resultTaskCategorySource
        .asObservable()
        .pipe(distinctUntilChanged());

    knowledgeCategoriesSource = this.resultKnowledgeCategorySource
        .asObservable()
        .pipe(distinctUntilChanged());

    carsSource = this.resultCarsSource.asObservable()
        .pipe(distinctUntilChanged());

    officesSource = this.resultOfficesSource.asObservable()
        .pipe(distinctUntilChanged());

    bookingsSource = this.resultBookingSource.asObservable()
        .pipe(distinctUntilChanged());

    buildingsSource = this.resultBuildingsSource.asObservable()
        .pipe(distinctUntilChanged());

    // eslint-disable-next-line max-lines-per-function
    constructor(
        private readonly router: Router,
        private readonly http: HttpClient,
        private readonly authenticationService: AuthenticationService,
        private readonly permissionsService: PermissionService,
        private readonly location: Location,
        private readonly sanitizer: DomSanitizer,
        private readonly apisService: ApisService,
        private readonly acsService: AcsService,
        private readonly crmService: CrmService,
        private readonly erpService: ErpService,
        private readonly monitoringService: MonitoringService,
        private readonly carsService: CarsService,
        private readonly messageService: MessageService,
        private readonly environmentService: EnvironmentService,
    ) {
        this.ticketsUrl = `${this.environmentService.backendURL}/api/ticket`;
        this.tasksUrl = `${this.environmentService.backendURL}/api/task`;
        this.vacationsUrl = `${this.environmentService.backendURL}/api/vacation`;
        this.vacationsCategoryUrl = `${this.environmentService.backendURL}/api/vacation/categories`;
        this.usersUrl = `${this.environmentService.backendURL}/api/user`;
        this.materialsUrl = `${this.environmentService.backendURL}/api/material`;
        this.materialsCategoryUrl = `${this.environmentService.backendURL}/api/material/categories`;
        this.materialsItemUrl = `${this.environmentService.backendURL}/api/material/items/`;
        this.budgetsUrl = `${this.environmentService.backendURL}/api/budget`;
        this.knowledgeUrl = `${this.environmentService.backendURL}/api/knowledge`;
        this.hotlineUrl = `${this.environmentService.backendURL}/api/hotline`;
        this.notificationsUrl = `${this.environmentService.backendURL}/api/notify`;
        this.commentsTicketUrl = `${this.environmentService.backendURL}/api/comments/ticket`;
        this.commentsKwbtUrl = `${this.environmentService.backendURL}/api/comments/kwb`;
        this.notesTicketUrl = `${this.environmentService.backendURL}/api/notes/ticket`;
        this.companyrUrl = `${this.environmentService.backendURL}/api/company/`;
        this.departmentUrl = `${this.environmentService.backendURL}/api/department`;
        this.roleUrl = `${this.environmentService.backendURL}/api/role`;
        this.employerUrl = `${this.environmentService.backendURL}/api/employer`;
        this.apisUrl = `${this.environmentService.backendURL}/api/apis/connections`;
        this.apiMapsUrl = `${this.environmentService.backendURL}/api/apis/map`;
        this.apiProtocolsUrl = `${this.environmentService.backendURL}/api/apis/protocols`;
        this.apiTypesUrl = `${this.environmentService.backendURL}/api/apis/types`;
        this.apiHeadersUrl = `${this.environmentService.backendURL}/api/apis/headers`;
        this.apiPathsUrl = `${this.environmentService.backendURL}/api/apis/paths`;
        this.clientUrl = `${this.environmentService.backendURL}/api/sync/client`;
        this.clientSchemaUrl = `${this.environmentService.backendURL}/api/schema/clients`;
        this.clientServiceUrl = `${this.environmentService.backendURL}/api/sync/client/service`;
        this.eventUrl = `${this.environmentService.backendURL}/api/event`;
        this.hotlinersUrl = `${this.environmentService.backendURL}/api/hotliners`;
        this.tagsUrl = `${this.environmentService.backendURL}/api/tag`;
        this.projectsUrl = `${this.environmentService.backendURL}/api/projects/`;
        this.knowledgeCategoryUrl = `${this.environmentService.backendURL}/api/knowledge/categories`;
        this.ticketsCategoryUrl = `${this.environmentService.backendURL}/api/ticket/categories`;
        this.tasksCategoryUrl = `${this.environmentService.backendURL}/api/task/categories`;
        this.carsUrl = `${this.environmentService.backendURL}/api/car`;
        this.officesUrl = `${this.environmentService.backendURL}/api/office`;
        this.bookingsUrl = `${this.environmentService.backendURL}/api/booking`;
        this.buildingsUrl = `${this.environmentService.backendURL}/api/buildings`;
    }

    /**
     * Handle Http operation that failed.
     * Let the app continue.
     * @param operation - name of the operation that failed
     * @param result? - optional value to return as the observable result
     */
    private handleError<T>(operation = 'operation', result?: T) {
        return (error: {[key: string]: string}): Observable<T | undefined> => {
            // TODO: send the error to remote logging infrastructure
            console.error(error); // log to console instead
            // TODO: better job of transforming error for user consumption
            DataService.log(`${operation} failed: ${error.message}`);

            // eslint-disable-next-line @typescript-eslint/no-unsafe-return
            return of(result);
        };
    }

    /** Log a ticketService message with the MessageService */
    private static log(message: string): void {
        console.info(message);
    }

    private static fontColor(hexcolor: string): string {
        if (hexcolor.startsWith('#')) {
            hexcolor = hexcolor.slice(1);
        }

        const r = parseInt(hexcolor.substring(0, 2), 16);
        const g = parseInt(hexcolor.substring(2, 2), 16);
        const b = parseInt(hexcolor.substring(4, 2), 16);
        const yiq = (r * 299 + g * 587 + b * 114) / 1000;

        return yiq >= 128 ? 'black' : 'white';
    }

    private static monthDiff(d1: Date, d2: Date): number {
        let months: number;

        months = (d2.getFullYear() - d1.getFullYear()) * 12;
        months -= d1.getUTCMonth() + 1;
        months += d2.getUTCMonth() + 1;

        return months <= 0 ? 0 : months;
    }

    // CLIENT GETTERS
    private getClientsData(
        all: boolean,
        page?: number,
        size?: number,
    ): Observable<ClientsWrapper | undefined> {
        const headers = new HttpHeaders({'Content-Type': 'application/json'});
        const params = new HttpParams()
            .set('page', page ? page.toString() : '0')
            .set('size', size ? size.toString() : '0')
            .set('all', all.toString());

        return this.http.get<ClientsWrapper>(this.clientUrl, {headers, params})
            .pipe(
                tap(() => {
                    DataService.log('fetched clients');
                }),
                shareReplay(),
                catchError(this.handleError<ClientsWrapper>('getClientsQueue', {} as ClientsWrapper)),
            );
    }

    private getClientsSchema(): Observable<unknown[] | undefined> {
        return this.http.get<unknown[]>(this.clientSchemaUrl)
            .pipe(
                tap(() => {
                    DataService.log('fetched client schema');
                }),
                shareReplay(),
                catchError(this.handleError<unknown[]>('getSchema')),
            );
    }

    private getClientData(id: number): Observable<Client | undefined> {
        const url = `${this.clientUrl}/search/${id}`;

        return this.http.get<Client>(url)
            .pipe(
                tap(() => {
                    DataService.log('fetched client');
                }),
                shareReplay(),
                catchError(this.handleError<Client>('getDetail')),
            );
    }

    // COMPANIES GETTERS
    private getCompaniesData(): Observable<Company[] | undefined> {
        return this.http.get<Company[]>(this.companyrUrl)
            .pipe(
                tap(() => {
                    DataService.log('fetched companies');
                }),
                shareReplay(),
                catchError(this.handleError<Company[]>('getCompaniesQueue')),
            );
    }

    private getCompanyData(id: number): Observable<Company | undefined> {
        const url = `${this.companyrUrl}/search/${id}`;

        return this.http.get<Company>(url)
            .pipe(
                tap(() => {
                    DataService.log('fetched company');
                }),
                shareReplay(),
                catchError(this.handleError<Company>('getDetail')),
            );
    }

    // DEPARTMENTS GETTERS
    private getDepartmentsData(): Observable<Department[] | undefined> {
        return this.http.get<Department[]>(this.departmentUrl)
            .pipe(
                tap(() => {
                    DataService.log('fetched departments');
                }),
                shareReplay(),
                catchError(this.handleError<Department[]>('getDepartmentQueue')),
            );
    }

    private getDepartmentData(id: number): Observable<Department | undefined> {
        const url = `${this.departmentUrl}/search/${id}`;

        return this.http.get<Department>(url)
            .pipe(
                tap(() => {
                    DataService.log('fetched department');
                }),
                shareReplay(),
                catchError(this.handleError<Department>('getDetail')),
            );
    }

    // ROLES GETTERS
    private getRolesData(): Observable<Role[] | undefined> {
        return this.http.get<Role[]>(this.roleUrl)
            .pipe(
                tap(() => {
                    DataService.log('fetched roles');
                }),
                shareReplay(),
                catchError(this.handleError<Role[]>('getRoleQueue')),
            );
    }

    private getRoleData(id: number): Observable<Role | undefined> {
        const url = `${this.roleUrl}/search/${id}`;

        return this.http.get<Role>(url)
            .pipe(
                tap(() => {
                    DataService.log('fetched role');
                }),
                shareReplay(),
                catchError(this.handleError<Role>('getDetail')),
            );
    }

    // EMPLOYERS GETTERS
    private getEmployersData(): Observable<Employer[] | undefined> {
        return this.http.get<Employer[]>(this.employerUrl)
            .pipe(
                tap(() => {
                    DataService.log('fetched employers');
                }),
                shareReplay(),
                catchError(this.handleError<Employer[]>('getEmployerQueue')),
            );
    }

    private getEmployerData(id: number): Observable<Employer | undefined> {
        const url = `${this.employerUrl}/search/${id}`;

        return this.http.get<Employer>(url)
            .pipe(
                tap(() => {
                    DataService.log('fetched company');
                }),
                shareReplay(),
                catchError(this.handleError<Employer>('getDetail')),
            );
    }

    // TICKETS GETTERS
    private getTicketData(id: number): Observable<BaseModel | undefined> {
        const url = `${this.ticketsUrl}/search/${id}`;

        return this.http.get<BaseModel>(url)
            .pipe(
                tap(() => {
                    DataService.log('fetched ticket');
                }),
                shareReplay({
                    bufferSize: 1,
                    refCount: true,
                }),
                catchError(this.handleError<BaseModel>('getDetail')),
            );
    }

    private getTicketCategories(): Observable<BaseModel | undefined> {
        return this.http.get<BaseModel>(this.ticketsCategoryUrl)
            .pipe(
                shareReplay({
                    bufferSize: 1,
                    refCount: true,
                }),
                tap(() => {
                    DataService.log('fetched tickets categories');
                }),
                catchError(this.handleError<BaseModel>('getTicketCategories', new BaseModel())),
            );
    }

    // NOTIFICATIONS GETTERS
    private getOldNotificationsData(
        user: User | number,
        limit: number,
        offset: number,
    ): Observable<Notifications[] | undefined> {
        const id = typeof user === 'number' ? user : user.id;
        const url = `${this.notificationsUrl}/readed/user/${id}`;

        if (typeof this.notificationsOld === 'undefined' || limit || offset) {
            this.notificationsOld = this.http
                .post<Notifications[]>(url, {user_id: id, limit, offset}, httpOptions)
                .pipe(
                    tap(() => {
                        DataService.log('fetched notifications');
                    }),
                    share(),
                    catchError(this.handleError<Notifications[]>('getQueue OLD', [])),
                );
        }

        return this.notificationsOld;
        // eslint-disable-next-line max-lines
    }

    private getNewNotificationsData(user: User | number): Observable<Notifications[] | undefined> {
        const id = typeof user === 'number' ? user : user.id;
        const url = `${this.notificationsUrl}/unread/user/${id}`;

        this.notificationsNew = this.http
            .post<Notifications[]>(url, {user_id: id, viewed_at: null}, httpOptions)
            .pipe(
                // eslint-disable-next-line max-lines
                tap(() => {
                    DataService.log('fetched notifications');
                }),
                share(),
                catchError(this.handleError<Notifications[]>('getQueue NEW', [])),
            );

        return this.notificationsNew;
    }

    // TASKS GETTERS
    private getTaskData(id: number): Observable<Task | undefined> {
        const url = `${this.tasksUrl}/search/${id}`;

        return this.http.get<Task>(url)
            .pipe(
                tap(() => {
                    DataService.log('fetched task');
                }),
                shareReplay(),
                catchError(this.handleError<Task>('getDetail')),
            );
    }

    private getTaskCategories(): Observable<TaskCategory[] | undefined> {
        return this.http.get<TaskCategory[]>(this.tasksCategoryUrl)
            .pipe(
                tap(() => {
                    DataService.log('fetched tasks categories');
                }),
                catchError(this.handleError<TaskCategory[]>('getTaskCategories', [])),
            );
    }

    // TAGS GETTERS
    private getTagsData(): Observable<Tags[] | undefined> {
        return this.http.get<Tags[]>(this.tagsUrl)
            .pipe(
                tap(() => {
                    DataService.log('fetched tags');
                }),
                shareReplay(),
                catchError(this.handleError<Tags[]>('getTags', [])),
            );
    }

    // VACATIONS GETTERS
    private getVacationData(id: number): Observable<Vacation | undefined> {
        const url = `${this.vacationsUrl}/search/${id}`;

        return this.http.get<Vacation>(url)
            .pipe(
                // eslint-disable-next-line complexity
                map((vacation: Vacation) => {
                    vacation.unixCreatedTime = moment(vacation.created_date)
                        .valueOf();
                    vacation.unixUpdatedTime = vacation.updated_date
                        ? moment(vacation.updated_date)
                            .valueOf()
                        : null;

                    vacation.unixDeletedTime = vacation.deleted_date
                        ? moment(vacation.deleted_date)
                            .valueOf()
                        : null;

                    vacation.unixDeclinedTime = vacation.decline_at
                        ? moment(vacation.decline_at)
                            .valueOf()
                        : null;

                    vacation.unixConfirmedTime = vacation.confirm_at
                        ? moment(vacation.confirm_at)
                            .valueOf()
                        : null;

                    vacation.unixStartTime = vacation.start_at
                        ? moment(vacation.start_at)
                            .valueOf()
                        : null;

                    vacation.unixEndTime = vacation.end_at ? moment(vacation.end_at)
                        .valueOf() : null;

                    if (vacation.confirm && !vacation.deleted_date) {
                        vacation.status = 'confirmed';
                    }

                    if (vacation.decline && !vacation.deleted_date) {
                        vacation.status = 'declined';
                    }

                    if (vacation.deleted_date) {
                        vacation.status = 'deleted';
                    }

                    if (!vacation.decline && !vacation.confirm && !vacation.deleted_date) {
                        vacation.status = 'queue';
                    }

                    if (typeof vacation.confirmer !== 'undefined') {
                        vacation.boss = vacation.confirmer;
                    } else if (typeof vacation.decliner !== 'undefined') {
                        vacation.boss = vacation.decliner;
                    } else {
                        vacation.boss = vacation.creator.boss;
                    }

                    return vacation;
                }),
                tap(() => {
                    DataService.log(`fetched vacation id=${id}`);
                }),
                shareReplay({refCount: true, bufferSize: 1}),
                catchError(this.handleError<Vacation>(`getVacation id=${id}`)),
            );
    }

    private getVacationCategoriesData(): Observable<VacationCategory[] | undefined> {
        return this.http.get<VacationCategory[]>(this.vacationsCategoryUrl)
            .pipe(
                tap(() => {
                    DataService.log('fetched vacations categories');
                }),
                shareReplay({refCount: true, bufferSize: 1}),
                catchError(this.handleError<VacationCategory[]>('getCategoryQueue', [])),
            );
    }

    // COMMENTSS GETTERS
    private getTicketCommentsData(id?: number): Observable<TicketComment[] | undefined> {
        const url = id ? `${this.commentsTicketUrl}/${id}` : `${this.commentsTicketUrl}`;

        return this.http.get<TicketComment[]>(url)
            .pipe(
                tap(() => {
                    DataService.log('fetched ticket comments');
                }),
                shareReplay(),
                catchError(this.handleError<TicketComment[]>('getComments for ticket')),
            );
    }

    // MATERIALS GETTERS
    private getMaterialsData(): Observable<Material[] | undefined> {
        return this.http.get<Material[]>(this.materialsUrl)
            .pipe(
                tap(() => {
                    DataService.log('fetched materials');
                }),
                shareReplay({refCount: true, bufferSize: 1}),
                catchError(this.handleError<Material[]>('getMaterialQueue', [])),
            );
    }

    private getMaterialsCategoryData(): Observable<MaterialCategory[] | undefined> {
        return this.http.get<MaterialCategory[]>(this.materialsCategoryUrl)
            .pipe(
                tap(() => {
                    DataService.log('fetched materials categories');
                }),
                shareReplay({refCount: true, bufferSize: 1}),
                catchError(this.handleError<MaterialCategory[]>('getMaterialCategory', [])),
            );
    }

    private getMaterialsItemsData(): Observable<OrderItem[] | undefined> {
        return this.http.get<OrderItem[]>(this.materialsItemUrl)
            .pipe(
                map((items: OrderItem[]) => {
                    if (typeof items !== 'undefined' && items.length > 0) {
                        items.forEach((item: OrderItem, index) => {
                            items[index].order.viewers = [];

                            if (item.order.confirmers.length > 0) {
                                const bossCheck = item.order.confirmers.find(
                                    // eslint-disable-next-line max-nested-callbacks
                                    x => x.id === this.authenticationService.currentUserValue?.id,
                                );

                                items[index].order.amiboss = !!bossCheck;
                            } else {
                                items[index].order.amiboss = false;
                            }

                            if (item.budget.viewers.length > 0) {
                                const viewerCheck = item.budget.viewers.find(
                                    // eslint-disable-next-line max-nested-callbacks
                                    x => x.id === this.authenticationService.currentUserValue?.id,
                                );

                                items[index].order.amiviewer = !!viewerCheck;
                            } else {
                                items[index].order.amiviewer = false;
                            }
                        });
                    }

                    return items;
                }),
                tap(() => {
                    DataService.log('fetched materials items');
                }),
                shareReplay({refCount: true, bufferSize: 1}),
                catchError(this.handleError<OrderItem[]>('getMaterialItems', [])),
            );
    }

    private getMaterialData(material: Material | number): Observable<Material | undefined> {
        const id = typeof material === 'number' ? material : material.id;
        const url = `${this.materialsUrl}/search/${id}`;

        return this.http.get<Material>(url)
            .pipe(
                tap(() => {
                    DataService.log(`fetched material id=${id}`);
                }),
                shareReplay({refCount: true, bufferSize: 1}),
                catchError(this.handleError<Material>(`getMaterial id=${id}`)),
            );
    }

    // BUDGETS GETTERS
    // eslint-disable-next-line max-lines-per-function
    private getBudgetsData(): Observable<MaterialBudget[] | undefined> {
        return this.http.get<MaterialBudget[]>(this.budgetsUrl)
            .pipe(
                // eslint-disable-next-line max-lines-per-function
                map((budgets: MaterialBudget[]) => {
                    // eslint-disable-next-line max-lines-per-function
                    budgets.forEach((budget: MaterialBudget, index) => {
                        let cashSuccess = 0;
                        let cashQueue = 0;
                        let cashDelete = 0;
                        let itemDelete = 0;
                        let itemQueue = 0;
                        let itemSuccess = 0;

                        budgets[index].startUnix = moment(budget.start_at)
                            .valueOf();
                        budgets[index].endUnix = moment(budget.expire_at)
                            .valueOf();
                        budgets[index].deletedUnix = moment(budget.deleted_date)
                            .valueOf();
                        budgets[index].createdUnix = moment(budget.created_date)
                            .valueOf();
                        budgets[index].updatedUnix = moment(budget.updated_date)
                            .valueOf();
                        // eslint-disable-next-line max-nested-callbacks,complexity,max-lines-per-function
                        budget.budgetitems.forEach((item: OrderItem) => {
                            item.confirmed = false;
                            item.declined = false;

                            let decline = 0;
                            let submit = 0;
                            const reqSubmits = budget.approvers.length;

                            // eslint-disable-next-line max-nested-callbacks
                            budget.approvers.forEach((approver: User) => {
                                // eslint-disable-next-line max-nested-callbacks
                                item.order.confirmers.forEach((confirmer: User) => {
                                    if (
                                        approver.id === confirmer.id &&
                                        confirmer.material_assignation_users?.confirmed_date
                                    ) {
                                        submit = submit + 1;
                                    }

                                    if (
                                        approver.id === confirmer.id &&
                                        confirmer.material_assignation_users?.declined_date
                                    ) {
                                        decline = decline + 1;
                                    }
                                });
                            });

                            if (submit > 0 && decline === 0 && submit === reqSubmits) {
                                item.confirmed = true;
                            }

                            if (decline > 0) {
                                item.declined = true;
                            }

                            if (item.confirmed && !item.declined && !item.deleted_date) {
                                if (item.order.to_storage) {
                                    cashSuccess += item.dph
                                        ? item.price - item.price * 0.21
                                        : item.price;
                                }

                                itemSuccess = itemSuccess + 1;
                            }

                            if (item.declined || item.deleted_date) {
                                if (item.order.to_storage) {
                                    cashDelete += item.dph
                                        ? item.price - item.price * 0.21
                                        : item.price;
                                }

                                itemDelete = itemDelete + 1;
                            }

                            if (!item.declined && !item.confirmed && !item.deleted_date) {
                                if (item.order.to_storage) {
                                    cashQueue += item.dph ? item.price - item.price * 0.21 : item.price;
                                }

                                itemQueue = itemQueue + 1;
                            }
                        });

                        budgets[index].months = DataService.monthDiff(
                            new Date(budget.start_at),
                            new Date(budget.expire_at),
                        );

                        budgets[index].expenditures = {
                            queue: {items: itemQueue, price: cashQueue},
                            deleted: {items: itemDelete, price: cashDelete},
                            success: {items: itemSuccess, price: cashSuccess},
                            residue: budget.amount - cashSuccess,
                        };

                        if (budget.approvers.length > 0) {
                            const bossCheck = budget.approvers.find(
                                // eslint-disable-next-line max-nested-callbacks
                                x => x.id === this.authenticationService.currentUserValue?.id,
                            );

                            budgets[index].amiboss = !!bossCheck;
                        } else {
                            budgets[index].amiboss = false;
                        }

                        if (budget.viewers.length > 0) {
                            const viewerCheck = budget.viewers.find(
                                // eslint-disable-next-line max-nested-callbacks
                                x => x.id === this.authenticationService.currentUserValue?.id,
                            );

                            budgets[index].amiviewer = !!viewerCheck;
                        } else {
                            budgets[index].amiviewer = false;
                        }
                    });

                    return budgets;
                }),
                tap(() => {
                    DataService.log('fetched budgets');
                }),
                shareReplay({refCount: true, bufferSize: 1}),
                catchError(this.handleError<MaterialBudget[]>('getBudgets', [])),
            );
    }

    // KNOWLEDGEBASE GETTERS
    private getKnowledgesData(): Observable<Knowledge[] | undefined> {
        return this.http.get<Knowledge[]>(this.knowledgeUrl)
            .pipe(
                tap(() => {
                    DataService.log('fetched knowledges');
                }),
                shareReplay(),
                catchError(this.handleError<Knowledge[]>('getKnowledgeQueue', [])),
            );
    }

    private getKnowledgeData(knowledge: Knowledge | number): Observable<Knowledge | undefined> {
        const id = typeof knowledge === 'number' ? knowledge : knowledge.id;
        const url = `${this.knowledgeUrl}/search/${id}`;

        return this.http.get<Knowledge>(url)
            .pipe(
                tap(() => {
                    DataService.log(`fetched knowledge id=${id}`);
                }),
                shareReplay(),
                catchError(this.handleError<Knowledge>(`getKnowledge id=${id}`)),
            );
    }

    private getKnowledgeCategories(): Observable<KnowledgeCategory[] | undefined> {
        return this.http.get<KnowledgeCategory[]>(this.knowledgeCategoryUrl)
            .pipe(
                tap(() => {
                    DataService.log('fetched knowledges categories');
                }),
                catchError(this.handleError<KnowledgeCategory[]>('getKnowledgeCategories', [])),
            );
    }

    // CARS GETTERS
    private getCarsData(): Observable<Car[] | undefined> {
        return this.http.get<Car[]>(this.carsUrl)
            .pipe(
                tap(() => {
                    DataService.log('fetched cars');
                }),
                catchError(this.handleError<Car[]>('getCars', [])),
            );
    }

    // OFFICES GETTERS
    private getOfficesData(): Observable<Office[] | undefined> {
        return this.http.get<Office[]>(this.officesUrl)
            .pipe(
                tap(() => {
                    DataService.log('fetched offices');
                }),
                catchError(this.handleError<Office[]>('getOffices', [])),
            );
    }

    // BOOKINGS GETTERS
    private getBookingsData(): Observable<Booking[] | undefined> {
        this.loadingBookingSource.next(true);

        return this.http.get<Booking[]>(this.bookingsUrl)
            .pipe(
                tap(() => {
                    DataService.log('fetched bookings');
                }),
                catchError(this.handleError<Booking[]>('getBookings', [])),
            );
    }

    // USERS GETTERS
    private getUsersData(): Observable<User[] | undefined> {
        return this.http.get<User[]>(this.usersUrl)
            .pipe(
                tap(() => {
                    DataService.log('fetched users');
                }),
                shareReplay(),
                catchError(this.handleError<User[]>('getUsersQueue', [])),
            );
    }

    private getUserData(idUser: number): Observable<User | undefined> {
        const url = `${this.usersUrl}/search/${idUser}`;

        return this.http.get<User>(url)
            .pipe(
                tap(() => {
                    DataService.log(`fetched users id=${idUser}`);
                }),
                share(),
                catchError(this.handleError<User>(`getUser id=${idUser}`)),
            );
    }

    // HOTLINE GETTERS
    private getHotlineData(hotline: Hotline | number): Observable<Hotline | undefined> {
        const id = typeof hotline === 'number' ? hotline : hotline.id;
        const url = `${this.hotlineUrl}/search/${id}`;

        return this.http.get<Hotline>(url)
            .pipe(
                tap(() => {
                    DataService.log(`fetched hotline id=${id}`);
                }),
                shareReplay(),
                catchError(this.handleError<Hotline>(`getHotline id=${id}`)),
            );
    }

    // EVENTS GETTERS
    private getEventsData(): Observable<Event[] | undefined> {
        return this.http.get<Event[]>(this.eventUrl)
            .pipe(
                tap(() => {
                    DataService.log('fetched events');
                }),
                shareReplay(),
                catchError(this.handleError<Event[]>('getEventsQueue', [])),
            );
    }

    // PROJECTS GETTERS
    private getProjectsData(): Observable<Project[] | undefined> {
        return this.http.get<Project[]>(this.projectsUrl)
            .pipe(
                tap(() => {
                    DataService.log('fetched projects');
                }),
                shareReplay(),
                catchError(this.handleError<Project[]>('getProjectsQueue', [])),
            );
    }

    // API
    private getApiConnectionsData(): Observable<ApiConnections[] | undefined> {
        return this.http.get<ApiConnections[]>(this.apisUrl)
            .pipe(
                tap(() => {
                    DataService.log('fetched apis');
                }),
                shareReplay(),
                catchError(this.handleError<ApiConnections[]>('getApis')),
            );
    }

    private getApiConnectionsDetailData(
        api: ApiConnections | number,
    ): Observable<ApiConnections | undefined> {
        const id = typeof api === 'number' ? api : api.id;
        const url = `${this.apisUrl}/search/${id}`;

        return this.http.get<ApiConnections>(url)
            .pipe(
                tap(() => {
                    DataService.log('fetched api connection data');
                }),
                catchError(this.handleError<ApiConnections>('getApiConnectionData')),
            );
    }

    private getApiHeaders(): Observable<ApiHeaders[] | undefined> {
        return this.http.get<ApiHeaders[]>(this.apiHeadersUrl)
            .pipe(
                tap(() => {
                    DataService.log('fetched api headers');
                }),
                catchError(this.handleError<ApiHeaders[]>('getApiHeaders')),
            );
    }

    private getApiPathsData(): Observable<ApiPaths[] | undefined> {
        return this.http.get<ApiPaths[]>(this.apiPathsUrl)
            .pipe(
                tap(() => {
                    DataService.log('fetched api paths');
                }),
                catchError(this.handleError<ApiPaths[]>('getApiPaths')),
            );
    }

    private getApiMapsData(): Observable<ApiMaps[] | undefined> {
        return this.http.get<ApiMaps[]>(this.apiMapsUrl)
            .pipe(
                tap(() => {
                    DataService.log('fetched api maps');
                }),
                catchError(this.handleError<ApiMaps[]>('getApiMaps')),
            );
    }

    private getApiPathDetailData(id: number): Observable<ApiPaths | undefined> {
        const url = `${this.apiPathsUrl}/search/${id}`;

        return this.http.get<ApiPaths>(url)
            .pipe(
                tap(() => {
                    DataService.log('fetched api path data');
                }),
                catchError(this.handleError<ApiPaths>('getApiPathData')),
            );
    }

    private getApiMapDetailData(id: number): Observable<ApiMaps | undefined> {
        const url = `${this.apiMapsUrl}/search/${id}`;

        return this.http.get<ApiMaps>(url)
            .pipe(
                tap(() => {
                    DataService.log('fetched api map data');
                }),
                catchError(this.handleError<ApiMaps>('getApiMapData')),
            );
    }

    private getApiProtocolsData(): Observable<ApiProtocols[] | undefined> {
        return this.http.get<ApiProtocols[]>(this.apiProtocolsUrl)
            .pipe(
                tap(() => {
                    DataService.log('fetched api protocols');
                }),
                catchError(this.handleError<ApiProtocols[]>('getApiProtocols')),
            );
    }

    private getApiTypesData(): Observable<ApiTypes[] | undefined> {
        return this.http.get<ApiTypes[]>(this.apiTypesUrl)
            .pipe(
                tap(() => {
                    DataService.log('fetched api types');
                }),
                catchError(this.handleError<ApiTypes[]>('getApiTypes')),
            );
    }

    private getApiProtocolDetailData(id: number): Observable<ApiProtocols | undefined> {
        const url = `${this.apiProtocolsUrl}/search/${id}`;

        return this.http.get<ApiProtocols>(url)
            .pipe(
                tap(() => {
                    DataService.log('fetched api protocol data');
                }),
                catchError(this.handleError<ApiProtocols>('getApiProtocolData')),
            );
    }

    private getApiTypeDetailData(id: number): Observable<ApiTypes | undefined> {
        const url = `${this.apiTypesUrl}/search/${id}`;

        return this.http.get<ApiTypes>(url)
            .pipe(
                tap(() => {
                    DataService.log('fetched api type data');
                }),
                catchError(this.handleError<ApiTypes>('getApiTypeData')),
            );
    }

    private getApiHeaderDetailData(id: number): Observable<ApiHeaders | undefined> {
        const url = `${this.apiHeadersUrl}/search/${id}`;

        return this.http.get<ApiHeaders>(url)
            .pipe(
                tap(() => {
                    DataService.log('fetched api header data');
                }),
                catchError(this.handleError<ApiHeaders>('getApiHeaderData')),
            );
    }

    private getKnowledgeCommentsData(id?: number): Observable<KnowledgeComment[] | undefined> {
        const url = id ? `${this.commentsKwbtUrl}/${id}` : `${this.commentsKwbtUrl}`;

        return this.http.get<KnowledgeComment[]>(url)
            .pipe(
                tap(() => {
                    DataService.log('fetched knowledge comments');
                }),
                shareReplay(),
                catchError(this.handleError<KnowledgeComment[]>('getComments for knowledge')),
            );
    }

    // SOLVING NOTES GETTERS
    private getTicketNotesData(id: number): Observable<TicketNotes[] | undefined> {
        const url = `${this.notesTicketUrl}/search/${id}`;

        return this.http.get<TicketNotes[]>(url)
            .pipe(
                tap(() => {
                    DataService.log(`fetched notes id ticket=${id}`);
                }),
                shareReplay(),
                catchError(this.handleError<TicketNotes[]>(`getNotes for ticket id=${id}`, [])),
            );
    }

    private getBudgetData(budget: MaterialBudget | number): Observable<MaterialBudget | undefined> {
        const id = typeof budget === 'number' ? budget : budget.id;
        const url = `${this.budgetsUrl}/search/${id}`;

        return this.http.get<MaterialBudget>(url)
            .pipe(
                tap(() => {
                    DataService.log(`fetched material id=${id}`);
                }),
                shareReplay({refCount: true, bufferSize: 1}),
                catchError(this.handleError<MaterialBudget>(`getMaterial id=${id}`)),
            );
    }

    private getHotlinerData(hotline: Hotline | number): Observable<Hotline | undefined> {
        const id = typeof hotline === 'number' ? hotline : hotline.id;
        const url = `${this.hotlineUrl}/search/${id}`;

        return this.http.get<Hotline>(url)
            .pipe(
                tap(() => {
                    DataService.log(`fetched hotline id=${id}`);
                }),
                shareReplay(),
                catchError(this.handleError<Hotline>(`getHotline id=${id}`)),
            );
    }

    private getEventData(event: Event | number): Observable<Event | undefined> {
        const id = typeof event === 'number' ? event : event.id;
        const url = `${this.eventUrl}/search/${id}`;

        return this.http.get<Event>(url)
            .pipe(
                tap(() => {
                    DataService.log(`fetched event id=${id}`);
                }),
                shareReplay(),
                catchError(this.handleError<Event>(`getEvent id=${id}`)),
            );
    }

    private getProjectData(project: Project | number): Observable<Project | undefined> {
        const id = typeof project === 'number' ? project : project.id;
        const url = `${this.projectsUrl}/search/${id}`;

        return this.http.get<Project>(url)
            .pipe(
                tap(() => {
                    DataService.log(`fetched project id=${id}`);
                }),
                shareReplay(),
                catchError(this.handleError<Project>(`getProject id=${id}`)),
            );
    }

    private getGalleryData(idTicket: number): Observable<Gallery[] | undefined> {
        const url = `'https://jsonplaceholder.typicode.com/photos?albumId='${idTicket}`;

        return this.http.get<Gallery[]>(url)
            .pipe(
                tap(() => {
                    DataService.log('fetched gallery images');
                }),
                shareReplay(),
                catchError(this.handleError<Gallery[]>('getImages')),
            );
    }

    private getTagData(id: number): Observable<Tags | undefined> {
        const url = `${this.tagsUrl}/search/${id}`;

        return this.http.get<Tags>(url)
            .pipe(
                tap(() => {
                    DataService.log('fetched tag');
                }),
                shareReplay(),
                catchError(this.handleError<Tags>('getDetail')),
            );
    }

    // BUILDINGS GETTERS
    private getBuildingsData(): Observable<Building[] | undefined> {
        return this.http.get<Building[]>(this.buildingsUrl)
            .pipe(
                tap(() => {
                    DataService.log('fetched buildings');
                }),
                shareReplay(),
                catchError(this.handleError<Building[]>('getBuildings', [])),
            );
    }

    private dynamicColors(): {fontColor: string; backgroundColor: string} {
        const r = Math.floor(Math.random() * 255);
        const g = Math.floor(Math.random() * 255);
        const b = Math.floor(Math.random() * 255);
        const brightness = Math.round((r * 299 + g * 587 + b * 114) / 1000);
        const textColour = brightness > 125 ? 'black' : 'white';

        return {
            fontColor: textColour,
            backgroundColor: `rgb(${r},${g},${b}, 1)`,
        };
    }

    getHotlinersData(): Observable<Hotliner[] | undefined> {
        return this.http.get<Hotliner[]>(this.hotlinersUrl)
            .pipe(
                tap(() => {
                    DataService.log('fetched hotliners');
                }),
                shareReplay(),
                catchError(this.handleError<Hotliner[]>('getHotlinerQueue', [])),
            );
    }

    getTicketsData(user?: User | number): Observable<BaseModel | undefined> {
        let userId = 0;

        if (user) {
            userId = typeof user === 'number' ? user : user.id;
        }

        const headers = new HttpHeaders({'Content-Type': 'application/json'});
        const params = new HttpParams().set('user_id', userId.toString());

        return this.http.get<BaseModel>(this.ticketsUrl, {headers, params})
            .pipe(
                tap(() => {
                    DataService.log('fetched tickets');
                }),
                distinctUntilChanged(),
                share(),
                catchError(this.handleError<BaseModel>('getTicketQueue', new BaseModel())),
            );
    }

    getTasksData(user?: User | number): Observable<Task[] | undefined> {
        let userId = 0;

        if (user) {
            userId = typeof user === 'number' ? user : user.id;
        }

        const headers = new HttpHeaders({'Content-Type': 'application/json'});
        // eslint-disable-next-line max-lines
        const params = new HttpParams().set('user_id', userId.toString());

        return this.http.get<Task[]>(this.tasksUrl, {headers, params})
            .pipe(
                tap(() => {
                    DataService.log('fetched tasks');
                }),
                shareReplay(),
                catchError(this.handleError<Task[]>('getTaskQueue', [])),
            );
    }

    getHotlinesData(user?: User | number): Observable<Hotline[] | undefined> {
        let userId = 0;

        if (user) {
            userId = typeof user === 'number' ? user : user.id;
        }

        const headers = new HttpHeaders({'Content-Type': 'application/json'});
        const params = new HttpParams().set('user_id', userId.toString());

        return this.http.get<Hotline[]>(this.hotlineUrl, {headers, params})
            .pipe(
                tap(() => {
                    DataService.log('fetched hotlines');
                }),
                shareReplay(),
                catchError(this.handleError<Hotline[]>('getHotlineQueue', [])),
            );
    }

    getVacationsData(user?: User | number): Observable<Vacation[] | undefined> {
        let userId = 0;

        if (user) {
            userId = typeof user === 'number' ? user : user.id;
        }

        const headers = new HttpHeaders({'Content-Type': 'application/json'});
        const params = new HttpParams().set('user_id', userId.toString());

        return this.http.get<Vacation[]>(this.vacationsUrl, {headers, params})
            .pipe(
                map((vacations: Vacation[]) => {
                    if (typeof vacations !== 'undefined' && vacations.length > 0) {
                        // eslint-disable-next-line complexity
                        vacations.forEach((vacation: Vacation, index) => {
                            vacations[index].unixCreatedTime = moment(vacation.created_date)
                                .valueOf();
                            vacations[index].unixUpdatedTime = vacation.updated_date
                                ? moment(vacation.updated_date)
                                    .valueOf()
                                : null;

                            vacations[index].unixDeletedTime = vacation.deleted_date
                                ? moment(vacation.deleted_date)
                                    .valueOf()
                                : null;

                            vacations[index].unixDeclinedTime = vacation.decline_at
                                ? moment(vacation.decline_at)
                                    .valueOf()
                                : null;

                            vacations[index].unixConfirmedTime = vacation.confirm_at
                                ? moment(vacation.confirm_at)
                                    .valueOf()
                                : null;

                            vacations[index].unixStartTime = vacation.start_at
                                ? moment(vacation.start_at)
                                    .valueOf()
                                : null;

                            vacations[index].unixEndTime = vacation.end_at
                                ? moment(vacation.end_at)
                                    .valueOf()
                                : null;

                            if (!vacation.decline && !vacation.confirm && !vacation.deleted_date) {
                                vacations[index].status = 'queue';
                            } else if (!vacation.decline && vacation.confirm && !vacation.deleted_date) {
                                vacations[index].status = 'confirmed';
                            } else if (vacation.decline) {
                                vacations[index].status = 'declined';
                            } else {
                                vacations[index].status = 'deleted';
                            }

                            if (vacation.confirmer && typeof vacation.confirmer !== 'undefined') {
                                vacations[index].boss = vacation.confirmer;
                            } else if (vacation.decliner && typeof vacation.decliner !== 'undefined') {
                                vacations[index].boss = vacation.decliner;
                            } else {
                                vacations[index].boss = vacation.creator.boss;
                            }
                        });
                    }

                    return vacations;
                }),
                tap(() => {
                    DataService.log('fetched vacations');
                }),
                shareReplay({refCount: true, bufferSize: 1}),
                catchError(this.handleError<Vacation[]>('getVacationQueue', [])),
            );
    }

    getServicesData(
        all: boolean,
        page?: number,
        size?: number,
        ruian?: number,
    ): Observable<ClientServicesWrapper | undefined> {
        const headers = new HttpHeaders({'Content-Type': 'application/json'});
        const params = new HttpParams()
            .set('page', page ? page.toString() : '0')
            .set('size', size ? size.toString() : '0')
            .set('ruian', ruian ? ruian.toString() : '0')
            .set('all', all.toString());

        return this.http
            .get<ClientServicesWrapper>(this.clientServiceUrl, {headers, params})
            .pipe(
                tap(() => {
                    DataService.log('fetched clients services');
                }),
                shareReplay(),
                catchError(
                    this.handleError<ClientServicesWrapper>(
                        'getServicesQueue',
                        {} as ClientServicesWrapper,
                    ),
                ),
            );
    }

    // eslint-disable-next-line max-lines-per-function
    setApiDataSource(): void {
        const resultApiData: {
            acs: ApiConnections | boolean;
            crm: ApiConnections | boolean;
            erp: ApiConnections | boolean;
            siteMonitoring: ApiConnections | boolean;
            cars: ApiConnections | boolean;
        } = {
            acs: false,
            crm: false,
            erp: false,
            siteMonitoring: false,
            cars: false,
        };

        this.getApiConnectionsData()
            .subscribe((apis: ApiConnections[] | undefined) => {
                this.apisService.checkConnections(apis);
                // eslint-disable-next-line complexity
                apis?.forEach((api: ApiConnections, index) => {
                    if (api.type.name === 'ACS') {
                        resultApiData.acs = api;
                    }

                    if (api.type.name === 'CRM') {
                        resultApiData.crm = api;
                    }

                    if (api.type.name === 'ERP') {
                        resultApiData.erp = api;
                    }

                    if (api.type.name === 'MONITORING') {
                        resultApiData.siteMonitoring = api;
                    }

                    if (api.type.name === 'CARS') {
                        resultApiData.cars = api;
                    }

                    if (api.active) {
                        // eslint-disable-next-line max-nested-callbacks,complexity
                        this.apisService.isAvailable(api)
                            .subscribe((state: ApiConnections) => {
                                apis[index].state = !!state;
                                apis[index].last_check = moment()
                                    .format('YYYY-MM-DD HH:mm:ss');

                                if (api.state && api.type.name === 'ACS') {
                                    // eslint-disable-next-line max-nested-callbacks
                                    const path = api.paths.find(x => x.id === 1);

                                    this.acsService.setAcsUsers(path);
                                }

                                if (api.state && api.type.name === 'MONITORING') {
                                    // eslint-disable-next-line max-nested-callbacks
                                    const path = api.paths.find(x => x.id === 4);

                                    this.monitoringService.setMonitoringUsers(path);
                                }

                                if (api.state && api.type.name === 'CRM') {
                                    // eslint-disable-next-line max-nested-callbacks
                                    const path = api.paths.find(x => x.id === 21);

                                    this.crmService.setCRMUsers(path);
                                }

                                if (api.state && api.type.name === 'ERP') {
                                    // eslint-disable-next-line max-nested-callbacks
                                    const path = api.paths.find(x => x.id === 35);

                                    this.erpService.setErpUsers(path);
                                }

                                if (api.state && api.type.name === 'CARS') {
                                    // eslint-disable-next-line max-nested-callbacks
                                    const path = api.paths.find(x => x.id === 35);

                                    this.carsService.setCarsUsers(path);
                                }
                            });
                    }
                });
                this.resultApiSource.next(resultApiData);
            });
    }

    setApiDetailDataSource(api: ApiConnections | number): void {
        const id = typeof api === 'number' ? api : api.id;

        this.getApiConnectionsDetailData(id)
            .subscribe((data: ApiConnections) => {
                if (
                    this.authenticationService.currentUserValue &&
                    this.permissionsService.checkUserISAdmin(
                        this.authenticationService.currentUserValue,
                    )
                ) {
                    this.resultApiDetailSource.next(data);
                } else {
                    this.resultApiDetailSource.next(null);

                    const body = 'Nejste oprávněn nahlížet do tohoto API spojení...';
                    const options = {progressBar: true, timeOut: 5000, toastClass: 'red'};

                    this.messageService.showError('Nedostatečné oprávnění', body, options);
                    this.location.back();
                }
            });
    }

    setApiPathDataSource(): void {
        const resultApiPathData: ApiPaths[] = [];

        this.getApiPathsData()
            .subscribe((paths: ApiPaths[] | undefined) => {
                if (typeof paths !== 'undefined' && paths.length > 0) {
                    paths.forEach((path: ApiPaths) => {
                        resultApiPathData.push(path);
                    });
                    this.resultApiPathSource.next(resultApiPathData);
                } else {
                    this.resultApiPathSource.next([]);
                }
            });
    }

    setApiPathDetailDataSource(api: ApiPaths | number): void {
        const id = typeof api === 'number' ? api : api.id;

        this.getApiPathDetailData(id)
            .subscribe((path: ApiPaths) => {
                if (
                    this.authenticationService.currentUserValue &&
                    this.permissionsService.checkUserISAdmin(
                        this.authenticationService.currentUserValue,
                    )
                ) {
                    this.resultApiPathDetailSource.next(path);
                } else {
                    this.resultApiPathDetailSource.next(null);

                    const body = 'Nejste oprávněn nahlížet do tohoto API spojení...';
                    const options = {progressBar: true, timeOut: 5000, toastClass: 'red'};

                    this.messageService.showError('Nedostatečné oprávnění', body, options);
                    this.location.back();
                }
            });
    }

    setApiMapsDataSource(): void {
        const resultApiMapData: ApiMaps[] = [];

        // eslint-disable-next-line max-lines
        this.getApiMapsData()
            .subscribe((maps: ApiMaps[] | undefined) => {
                if (typeof maps !== 'undefined' && maps.length > 0) {
                    maps.forEach((apiMap: ApiMaps) => {
                        resultApiMapData.push(apiMap);
                    });
                    this.resultApiMapSource.next(resultApiMapData);
                } else {
                    this.resultApiMapSource.next([]);
                }
            });
    }

    setApiMapDetailDataSource(api: ApiMaps | number): void {
        const id = typeof api === 'number' ? api : api.id;

        this.getApiMapDetailData(id)
            .subscribe((apiMap: ApiMaps) => {
                if (
                    this.authenticationService.currentUserValue &&
                    this.permissionsService.checkUserISAdmin(
                        this.authenticationService.currentUserValue,
                    )
                ) {
                    this.resultApiMapDetailSource.next(apiMap);
                } else {
                    this.resultApiMapDetailSource.next(null);

                    const body = 'Nejste oprávněn nahlížet do tohoto API spojení...';
                    const options = {progressBar: true, timeOut: 5000, toastClass: 'red'};

                    this.messageService.showError('Nedostatečné oprávnění', body, options);
                    this.location.back();
                }
            });
    }

    // CLIENTS SETTER
    setClientDataSource(): void {
        if (
            typeof this.resultClientSource !== 'undefined' &&
            this.resultClientSource.getValue().length === 0
        ) {
            const resultClientData: Client[] = [];

            this.getClientsData(true)
                .subscribe((clients: ClientsWrapper) => {
                    if (typeof clients !== 'undefined' && clients.data.length > 0) {
                        clients.data.forEach((client: Client) => {
                            resultClientData.push(client);
                        });
                    }

                    this.resultClientSource.next(resultClientData);
                });
        }
    }

    setClientDataSchema(): void {
        const resultClientSchema: unknown[] = [];

        this.getClientsSchema()
            .subscribe((schema: unknown[]) => {
                if (schema.length > 0) {
                    schema.forEach((column: {[key: string]: unknown}) => {
                        resultClientSchema.push(column.column_name);
                    });
                }

                this.resultClientSchemaSource.next(resultClientSchema);
            });
    }

    setClientServicesSource(): void {
        if (this.resultClientServiceSource.getValue().length === 0) {
            const resultServiceData: ClientService[] = [];

            this.getServicesData(true)
                .subscribe((services: ClientServicesWrapper | undefined) => {
                    if (typeof services !== 'undefined' && services.data.length > 0) {
                        services.data.forEach((service: ClientService) => {
                            resultServiceData.push(service);
                        });
                    }

                    this.resultClientServiceSource.next(resultServiceData);
                });
        }
    }

    setClientDetailSource(client: Client | number): Observable<Client | undefined> {
        const id = typeof client === 'number' ? client : client.id;

        return this.getClientData(id);
    }

    // BUILDINGS SETTERS
    setBuildingsDataSource(): void {
        if (this.resultBuildingsSource.getValue()?.length === 0) {
            let resultBuildingData: Building[] = [];

            this.getBuildingsData()
                .subscribe((buildings: Building[] | undefined) => {
                    if (typeof buildings !== 'undefined' && buildings.length > 0) {
                        resultBuildingData = buildings;
                    }

                    this.resultBuildingsSource.next(resultBuildingData);
                });
        }
    }

    // COMPANIES SETTERS
    setCompanyDetailSource(company: Company | number): void {
        const id = typeof company === 'number' ? company : company.id;

        this.getCompanyData(id)
            .subscribe((data: Company) => {
                if (
                    this.authenticationService.currentUserValue &&
                    this.permissionsService.checkUserISAdministrative(
                        this.authenticationService.currentUserValue,
                    )
                ) {
                    this.resultCompanyDetailSource.next(data);
                } else {
                    this.resultCompanyDetailSource.next(null);

                    const body = 'Nejste oprávněn nahlížet do této společnosti...';
                    const options = {progressBar: true, timeOut: 5000, toastClass: 'red'};

                    this.messageService.showError('Nedostatečné oprávnění', body, options);
                    this.location.back();
                }
            });
    }

    setCompanyDataSource(): void {
        const resultCompanyData: Company[] = [];

        this.getCompaniesData()
            .subscribe((companies: Company[]) => {
                if (typeof companies !== 'undefined' && companies.length > 0) {
                    companies.forEach((company: Company) => {
                        resultCompanyData.push(company);
                    });
                }

                this.resultCompanySource.next(resultCompanyData);
            });
    }

    // DEPARTMENTS SETTERS
    setDepartmentDetailSource(department: Department | number): void {
        const id = typeof department === 'number' ? department : department.id;

        this.getDepartmentData(id)
            .subscribe((data: Department) => {
                if (
                    this.authenticationService.currentUserValue &&
                    this.permissionsService.checkUserISAdministrative(
                        this.authenticationService.currentUserValue,
                    )
                ) {
                    this.resultDepartmentDetailSource.next(data);
                } else {
                    this.resultDepartmentDetailSource.next(null);

                    const body = 'Nejste oprávněn nahlížet do tohoto oddělení...';
                    const options = {progressBar: true, timeOut: 5000, toastClass: 'red'};

                    this.messageService.showError('Nedostatečné oprávnění', body, options);
                    this.location.back();
                }
            });
    }

    setDepartmentDataSource(): void {
        const resultDepartmentData: Department[] = [];

        this.getDepartmentsData()
            .subscribe((departments: Department[] | undefined) => {
                if (typeof departments !== 'undefined' && departments.length > 0) {
                    departments.forEach((department: Department) => {
                        resultDepartmentData.push(department);
                    });
                }

                this.resultDepartmentSource.next(resultDepartmentData);
            });
    }

    // ROLES SETTERS
    setRoleDetailSource(role: Role | number): void {
        const id = typeof role === 'number' ? role : role.id;

        this.getRoleData(id)
            .subscribe((data: Role) => {
                if (
                    this.authenticationService.currentUserValue &&
                    this.permissionsService.checkUserISAdministrative(
                        this.authenticationService.currentUserValue,
                    )
                ) {
                    this.resultRoleDetailSource.next(data);
                } else {
                    this.resultRoleDetailSource.next(null);

                    const body = 'Nejste oprávněn nahlížet do této role...';
                    const options = {progressBar: true, timeOut: 5000, toastClass: 'red'};

                    this.messageService.showError('Nedostatečné oprávnění', body, options);
                    this.location.back();
                }
            });
    }

    setRoleDataSource(): void {
        const resultRoleData: Role[] = [];

        this.getRolesData()
            .subscribe((roles: Role[]) => {
                if (typeof roles !== 'undefined' && roles.length > 0) {
                    roles.forEach((role: Role) => {
                        resultRoleData.push(role);
                    });
                }

                this.resultRoleSource.next(resultRoleData);
            });
    }

    // EMPLOYERS SETTERS
    setEmployerDetailSource(employer: Employer | number): void {
        const id = typeof employer === 'number' ? employer : employer.id;

        this.getEmployerData(id)
            .subscribe((data: Employer) => {
                if (
                    this.authenticationService.currentUserValue &&
                    this.permissionsService.checkUserISAdministrative(
                        this.authenticationService.currentUserValue,
                    )
                ) {
                    this.resultEmployerDetailSource.next(data);
                } else {
                    this.resultEmployerDetailSource.next(null);

                    const body = 'Nejste oprávněn nahlížet do tohoto zaměstnavatele...';
                    const options = {progressBar: true, timeOut: 5000, toastClass: 'red'};

                    this.messageService.showError('Nedostatečné oprávnění', body, options);
                    this.location.back();
                }
            });
    }

    setEmployerDataSource(): void {
        const resultEmployerData: Employer[] = [];

        this.getEmployersData()
            .subscribe((employers: Employer[]) => {
                if (typeof employers !== 'undefined' && employers.length > 0) {
                    employers.forEach((employer: Employer) => {
                        resultEmployerData.push(employer);
                    });
                }

                this.resultEmployerSource.next(resultEmployerData);
            });
    }

    // NOTIFICATIONS SETTERS
    setNewNotificationsDataSource(userData: User | number | null): void {
        if (!userData) {
            console.error('userData is missing...');

            return;
        }

        this.getNewNotificationsData(userData)
            .subscribe((notifications: Notifications[]) => {
                const resultNewNotificationData: Notifications[] = [];

                if (typeof notifications !== 'undefined' && notifications.length > 0) {
                    notifications.forEach((notification: Notifications) => {
                        notification.unixCreateDate = Date.parse(notification.created_date);
                        resultNewNotificationData.push(notification);
                    });
                }

                this.resultNewNotificationSource.next(resultNewNotificationData);
            });
    }

    setOldNotificationsDataSource(
        userData: User | number | null,
        limit: number,
        offset: number,
    ): void {
        if (!userData) {
            console.error('userData is missing...');

            return;
        }

        this.getOldNotificationsData(userData, limit, offset)
            .subscribe(
                (notifications: Notifications[]) => {
                    if (typeof notifications !== 'undefined' && notifications.length > 0) {
                        notifications.forEach((notification: Notifications) => {
                            notification.unixCreateDate = Date.parse(notification.created_date);
                            this.resultOldNotificationData.push(notification);
                        });
                    }

                    this.resultOldNotificationSource.next(this.resultOldNotificationData);
                },
            );
    }

    // TICKETS SETTERS
    setTicketCategoriesSource(): void {
        const resultCategoryData: TicketCategory[] = [];

        this.getTicketCategories()
            .pipe(map((data: BaseModel) => data.data as TicketCategory[]))
            .subscribe((categories: TicketCategory[]) => {
                if (typeof categories !== 'undefined' && categories.length > 0) {
                    categories.forEach((category: TicketCategory) => {
                        resultCategoryData.push(category);
                    });
                }

                this.resultTicketCategorySource.next(resultCategoryData);
            });
    }

    // eslint-disable-next-line max-lines-per-function
    setTicketDetailSource(ticket: Tickets | number): void {
        const id = typeof ticket === 'number' ? ticket : ticket.id;

        // eslint-disable-next-line complexity,max-lines-per-function
        this.getTicketData(id)
            .pipe(map((data: BaseModel) => data.data[0] as Tickets))
            .subscribe((data: Tickets) => {
                if (this.permissionsService.viewTicket(data)) {
                    data.unixCreatedTime = moment(data.created_date)
                        .valueOf();
                    data.unixUpdatedTime = data.updated_date
                        ? moment(data.updated_date)
                            .valueOf()
                        : null;

                    data.unixDeletedTime = data.deleted_date
                        ? moment(data.deleted_date)
                            .valueOf()
                        : null;

                    data.unixFinishedTime = data.finished_at
                        ? moment(data.finished_at)
                            .valueOf()
                        : null;
                    data.unixDeadlineTime = data.deadline ? moment(data.deadline)
                        .valueOf() : null;
                    data.unixStartDeadlineTime = data.start_deadline
                        ? moment(data.start_deadline)
                            .valueOf()
                        : null;

                    const currentdate = moment()
                        .valueOf();
                    const startdate = data.start_deadline
                        ? moment(data.start_deadline)
                        : moment(data.created_date);
                    const finishdate: Moment | null = data.finished_at
                        ? moment(data.finished_at)
                        : null;

                    if (data.deadline) {
                        const deadline = moment(data.deadline);
                        const fullDiff = Math.abs(startdate.valueOf() - deadline.valueOf());
                        const dueDiff = Math.abs(
                            (finishdate === null ? startdate.valueOf() : finishdate.valueOf()) -
                            deadline.valueOf(),
                        );

                        if (
                            (finishdate === null ? currentdate : finishdate.valueOf()) <
                            deadline.valueOf()
                        ) {
                            const currPercentage = (fullDiff - dueDiff) * 100 / fullDiff;

                            data.ticketTime = {
                                overtime: false,
                                percentage: currPercentage,
                                time_fond: moment.duration(startdate.diff(deadline))
                                    .humanize(),
                            };
                        } else {
                            data.ticketTime = {
                                overtime: true,
                                percentage: 100,
                                time_fond: moment.duration(startdate.diff(deadline))
                                    .humanize(),
                            };
                        }
                    } else {
                        data.ticketTime = null;
                    }

                    data.unixSLATime = startdate
                        .add(data.category.sla.duration, 'minutes')
                        .valueOf();

                    data.maintaskHTML = this.sanitizer.bypassSecurityTrustHtml(data.maintask);
                    data.solutionHTML = this.sanitizer.bypassSecurityTrustHtml(data.solution ?? '');

                    data.tasks.forEach((task: Task, indexTask) => {
                        const deadlineTask = moment(task.deadline)
                            .valueOf();
                        const createdateTask = moment(task.created_date)
                            .valueOf();
                        const startdateTask = task.start_deadline
                            ? moment(task.start_deadline)
                            : moment(task.created_date);
                        const finishdateTask = task.finished_at
                            ? moment(task.finished_at)
                                .valueOf()
                            : task.deleted_date
                                ? moment(task.deleted_date)
                                    .valueOf()
                                : moment()
                                    .valueOf();
                        const fullDiffTask = Math.abs(createdateTask - deadlineTask);
                        const dueDiffTask = Math.abs(finishdateTask - deadlineTask);

                        data.tasks[indexTask].unixCreatedTime = moment(task.created_date)
                            .valueOf();
                        data.tasks[indexTask].unixUpdatedTime = moment(task.updated_date)
                            .valueOf();
                        data.tasks[indexTask].unixDeadlineTime = moment(task.deadline)
                            .valueOf();
                        data.tasks[indexTask].maintaskHTML = this.sanitizer.bypassSecurityTrustHtml(
                            task.maintask,
                        );

                        if (finishdateTask < deadlineTask) {
                            const percentage = (fullDiffTask - dueDiffTask) * 100 / fullDiffTask;

                            data.tasks[indexTask].taskTime = {
                                percentage,
                                overtime: false,
                                time_fond: moment
                                    .duration(startdateTask.diff(deadlineTask))
                                    .humanize(),
                            };
                        } else {
                            data.tasks[indexTask].taskTime = {
                                percentage: 100,
                                overtime: true,
                                time_fond: moment
                                    .duration(startdateTask.diff(deadlineTask))
                                    .humanize(),
                            };
                        }
                    });

                    data.files.forEach((file: TicketFile, indexFile) => {
                        data.files[indexFile].unixCreatedTime = moment(file.created_date)
                            .valueOf();
                        data.files[indexFile].unixUpdatedTime = moment(file.updated_date)
                            .valueOf();
                    });

                    data.comments.forEach((comment: TicketComment, index) => {
                        data.comments[index].textHTML = this.sanitizer.bypassSecurityTrustHtml(
                            comment.text,
                        );

                        data.comments[index].unixCreatedTime = moment(
                            comment.created_date,
                        )
                            .valueOf();

                        data.comments[index].unixUpdatedTime = moment(
                            comment.updated_date,
                        )
                            .valueOf();

                        // eslint-disable-next-line max-nested-callbacks
                        comment.replies.forEach((reply: TicketComment, iterator) => {
                            data.comments[index].replies[iterator].textHTML =
                                this.sanitizer.bypassSecurityTrustHtml(reply.text);

                            data.comments[index].replies[iterator].unixCreatedTime = moment(
                                reply.created_date,
                            )
                                .valueOf();

                            data.comments[index].replies[iterator].unixUpdatedTime = moment(
                                reply.updated_date,
                            )
                                .valueOf();
                        });
                    });

                    data.notes.forEach((note: TicketNotes, index) => {
                        data.notes[index].textHTML = this.sanitizer.bypassSecurityTrustHtml(
                            note.text,
                        );
                        data.notes[index].unixCreatedTime = moment(note.created_date)
                            .valueOf();
                        data.notes[index].unixUpdatedTime = moment(note.updated_date)
                            .valueOf();
                    });

                    data.tags.map((tag: Tags, indexTags) => {
                        data.tags[indexTags].fontColor = DataService.fontColor(tag.color);
                    });

                    this.resultTicketDetailSource.next(data);
                } else {
                    this.resultTicketDetailSource.next(null);

                    const body = 'Nejste oprávněn nahlížet do tohoto ticketu...';
                    const options = {progressBar: true, timeOut: 5000, toastClass: 'red'};

                    this.messageService.showError('Nedostatečné oprávnění', body, options);
                    this.location.back();
                }
            });
    }

    // eslint-disable-next-line max-lines-per-function
    setTicketsDataSource(): void {
        if (this.resultTicketSource.getValue()?.length === 0) {
            const resultTicketData: Tickets[] = [];

            // eslint-disable-next-line max-lines-per-function
            this.getTicketsData()
                .pipe(
                    map((data: BaseModel) => data.data as Tickets[]),
                )
                .subscribe((tickets: Tickets[]) => {
                    // eslint-disable-next-line max-lines-per-function
                    this.getTicketCommentsData()
                        .subscribe(() => {
                            if (typeof tickets !== 'undefined' && tickets.length > 0) {
                                // eslint-disable-next-line complexity,max-lines-per-function,max-nested-callbacks
                                tickets.forEach((ticket: Tickets) => {
                                    // eslint-disable-next-line complexity,max-lines-per-function,max-nested-callbacks
                                    ticket.tags.forEach((tag: Tags, indexTags) => {
                                        ticket.tags[indexTags].fontColor = DataService.fontColor(
                                            tag.color,
                                        );
                                    });

                                    const startdate = ticket.start_deadline
                                        ? moment(ticket.start_deadline)
                                        : moment(ticket.created_date);
                                    const deadline = moment(ticket.deadline)
                                        .valueOf();

                                    ticket.unixCreatedTime = moment(ticket.created_date)
                                        .valueOf();
                                    ticket.unixUpdatedTime = ticket.updated_date
                                        ? moment(ticket.updated_date)
                                            .valueOf()
                                        : null;

                                    ticket.unixDeletedTime = ticket.deleted_date
                                        ? moment(ticket.deleted_date)
                                            .valueOf()
                                        : null;

                                    ticket.unixFinishedTime = ticket.finished_at
                                        ? moment(ticket.finished_at)
                                            .valueOf()
                                        : null;

                                    ticket.unixDeadlineTime = ticket.deadline
                                        ? moment(ticket.deadline)
                                            .valueOf()
                                        : null;

                                    ticket.unixStartDeadlineTime = ticket.start_deadline
                                        ? moment(ticket.start_deadline)
                                            .valueOf()
                                        : null;

                                    if (!ticket.finished_at && ticket.deadline) {
                                        const currentdate = moment()
                                            .valueOf();
                                        const fullDiff = Math.abs(startdate.valueOf() - deadline);
                                        const dueDiff = Math.abs(currentdate - deadline);

                                        if (currentdate < deadline) {
                                            const currPercentage =
                                                (fullDiff - dueDiff) * 100 / fullDiff;

                                            ticket.ticketTime = {
                                                overtime: false,
                                                percentage: currPercentage,
                                                time_fond: moment
                                                    .duration(startdate.diff(deadline))
                                                    .humanize(),
                                            };
                                        } else {
                                            ticket.ticketTime = {
                                                overtime: true,
                                                percentage: 100,
                                                time_fond: moment
                                                    .duration(startdate.diff(deadline))
                                                    .humanize(),
                                            };
                                        }

                                        ticket.due =
                                            ticket.status.name === 'solved' &&
                                            ticket.ticketTime.overtime
                                                ? 'overdue'
                                                : ticket.status.name === 'solved' &&
                                                !ticket.ticketTime.overtime
                                                    ? 'indue'
                                                    : ticket.status.name === 'in queue' &&
                                                    ticket.ticketTime.overtime
                                                        ? 'overdue'
                                                        : ticket.status.name === 'in queue' &&
                                                        !ticket.ticketTime.overtime
                                                            ? 'indue'
                                                            : ticket.status.name === 'deleted' &&
                                                            ticket.ticketTime.overtime
                                                                ? 'overdue'
                                                                : // eslint-disable-next-line max-len
                                                                ticket.status.name === 'deleted' &&
                                                                !ticket.ticketTime.overtime
                                                                    ? 'indue'
                                                                    : false;
                                    } else if (ticket.finished_at && ticket.deadline) {
                                        const finishdate = moment(ticket.finished_at)
                                            .valueOf();
                                        const fullDiff = Math.abs(startdate.valueOf() - deadline);
                                        const dueDiff = Math.abs(finishdate - deadline);

                                        if (finishdate < deadline) {
                                            const currPercentage =
                                                (fullDiff - dueDiff) * 100 / fullDiff;

                                            ticket.ticketTime = {
                                                overtime: false,
                                                percentage: currPercentage,
                                                time_fond: moment
                                                    .duration(startdate.diff(deadline))
                                                    .humanize(),
                                            };
                                        } else {
                                            ticket.ticketTime = {
                                                overtime: true,
                                                percentage: 100,
                                                time_fond: moment
                                                    .duration(startdate.diff(deadline))
                                                    .humanize(),
                                            };
                                        }

                                        ticket.due =
                                            ticket.status.name === 'solved' &&
                                            ticket.ticketTime.overtime
                                                ? 'overdue'
                                                : ticket.status.name === 'solved' &&
                                                !ticket.ticketTime.overtime
                                                    ? 'indue'
                                                    : ticket.status.name === 'in queue' &&
                                                    ticket.ticketTime.overtime
                                                        ? 'overdue'
                                                        : ticket.status.name === 'in queue' &&
                                                        !ticket.ticketTime.overtime
                                                            ? 'indue'
                                                            : ticket.status.name === 'deleted' &&
                                                            ticket.ticketTime.overtime
                                                                ? 'overdue'
                                                                : // eslint-disable-next-line max-len
                                                                ticket.status.name === 'deleted' &&
                                                                !ticket.ticketTime.overtime
                                                                    ? 'indue'
                                                                    : false;
                                    }

                                    ticket.unixSLATime = startdate
                                        .add(ticket.category.sla.duration, 'minutes')
                                        .valueOf();

                                    if (!ticket.deadline) {
                                        ticket.due = 'indue';
                                    }

                                    if (this.permissionsService.viewTicket(ticket)) {
                                        resultTicketData.push(ticket);
                                    }
                                });
                            }

                            this.resultTicketSource.next(resultTicketData);
                        });
                });
        }
    }

    // TASKS SETTERS
    setTaskCategoriesSource(): void {
        const resultCategoryData: TaskCategory[] = [];

        this.getTaskCategories()
            .subscribe((categories: TaskCategory[]) => {
                if (typeof categories !== 'undefined' && categories.length > 0) {
                    categories.forEach((category: TaskCategory) => {
                        resultCategoryData.push(category);
                    });
                }

                this.resultTaskCategorySource.next(resultCategoryData);
            });
    }

    // eslint-disable-next-line max-lines-per-function
    setTaskDetailSource(task: Task | number): void {
        let dateA: number | null = null;
        let dateB: number | null = null;
        const id = typeof task === 'number' ? task : task.id;

        // eslint-disable-next-line max-lines-per-function,complexity
        this.getTaskData(id)
            .subscribe((data: Task) => {
                if (this.permissionsService.viewTask(data, data.ticket)) {
                    const deadline = moment(data.deadline);
                    const startdate = data.start_deadline
                        ? moment(data.start_deadline)
                        : moment(data.created_date);

                    data.unixCreatedTime = moment(data.created_date)
                        .valueOf();
                    data.unixUpdatedTime = data.updated_date
                        ? moment(data.updated_date)
                            .valueOf()
                        : null;

                    data.unixDeletedTime = data.deleted_date
                        ? moment(data.deleted_date)
                            .valueOf()
                        : null;

                    data.unixFinishedTime = data.finished_at
                        ? moment(data.finished_at)
                            .valueOf()
                        : null;
                    data.unixDeadlineTime = moment(data.deadline)
                        .valueOf();
                    data.unixStartDeadlineTime = moment(data.start_deadline)
                        .valueOf();

                    if (!data.finished_at && data.deadline) {
                        const currentdate = moment()
                            .valueOf();
                        const fullDiff = Math.abs(startdate.valueOf() - deadline.valueOf());
                        const dueDiff = Math.abs(currentdate - deadline.valueOf());

                        if (currentdate < deadline.valueOf()) {
                            const percentage = (fullDiff - dueDiff) * 100 / fullDiff;

                            data.taskTime = {
                                overtime: false,
                                percentage,
                                time_fond: moment.duration(startdate.diff(deadline))
                                    .humanize(),
                            };
                        } else {
                            data.taskTime = {
                                overtime: true,
                                percentage: 100,
                                time_fond: moment.duration(startdate.diff(deadline))
                                    .humanize(),
                            };
                        }
                    } else {
                        const finishdate = moment(data.finished_at)
                            .valueOf();
                        const fullDiff = Math.abs(startdate.valueOf() - deadline.valueOf());
                        const dueDiff = Math.abs(finishdate - deadline.valueOf());

                        if (finishdate < deadline.valueOf()) {
                            const percentage = (fullDiff - dueDiff) * 100 / fullDiff;

                            data.taskTime = {
                                overtime: false,
                                percentage,
                                time_fond: moment.duration(startdate.diff(deadline))
                                    .humanize(),
                            };
                        } else {
                            data.taskTime = {
                                overtime: true,
                                percentage: 100,
                                time_fond: moment.duration(startdate.diff(deadline))
                                    .humanize(),
                            };
                        }
                    }

                    data.unixSLATime = startdate.add(data.category?.sla.duration, 'minutes')
                        .valueOf();

                    data.maintaskHTML = this.sanitizer.bypassSecurityTrustHtml(data.maintask);
                    data.solutionHTML = this.sanitizer.bypassSecurityTrustHtml(data.solution ?? '');
                    data.notes.map((note: TaskNotes, index) => {
                        data.notes[index].textHTML = this.sanitizer.bypassSecurityTrustHtml(note.text);
                        data.notes[index].unixCreatedTime = moment(note.created_date)
                            .valueOf();
                        data.notes[index].unixUpdatedTime = moment(note.updated_date)
                            .valueOf();
                    });

                    data.tags.map((tag: Tags, indexTags) => {
                        data.tags[indexTags].fontColor = DataService.fontColor(tag.color);
                    });

                    data.notes = data.notes.sort((a, b) => {
                        dateA = moment(a.created_date)
                            .valueOf();
                        dateB = moment(b.created_date)
                            .valueOf();

                        return dateA > dateB ? -1 : 1;
                    });

                    data.comments.forEach((comment: TaskComment, index) => {
                        data.comments[index].textHTML = this.sanitizer.bypassSecurityTrustHtml(
                            comment.text,
                        );

                        data.comments[index].unixCreatedTime = moment(
                            comment.created_date,
                        )
                            .valueOf();

                        data.comments[index].unixUpdatedTime = moment(
                            comment.updated_date,
                        )
                            .valueOf();

                        // eslint-disable-next-line max-nested-callbacks
                        comment.replies.forEach((reply: TaskComment, iterator) => {
                            data.comments[index].replies[iterator].textHTML =
                                this.sanitizer.bypassSecurityTrustHtml(reply.text);

                            data.comments[index].replies[iterator].unixCreatedTime = moment(
                                reply.created_date,
                            )
                                .valueOf();

                            data.comments[index].replies[iterator].unixUpdatedTime = moment(
                                reply.updated_date,
                            )
                                .valueOf();
                        });
                    });

                    this.resultTaskDetailSource.next(data);
                } else {
                    this.resultTaskDetailSource.next(null);

                    const body = 'Nejste oprávněn nahlížet do tohoto úkolu...';
                    const options = {progressBar: true, timeOut: 5000, toastClass: 'red'};

                    this.messageService.showError('Nedostatečné oprávnění', body, options);
                    this.location.back();
                }
            });
    }

    // eslint-disable-next-line max-lines-per-function
    setTasksDataSource(): void {
        if (this.resultTaskSource.getValue()?.length === 0) {
            const resultTaskData: Task[] = [];

            // eslint-disable-next-line max-lines-per-function
            this.getTasksData()
                .subscribe((tasks: Task[]) => {
                    if (typeof tasks !== 'undefined' && tasks.length > 0) {
                        // eslint-disable-next-line max-lines-per-function,complexity
                        tasks.forEach((task: Task) => {
                            const deadline = moment(task.deadline)
                                .valueOf();
                            const currentdate = moment()
                                .valueOf();
                            const startdate = task.start_deadline
                                ? moment(task.start_deadline)
                                : moment(task.created_date);
                            const finishdate = moment(task.finished_at)
                                .valueOf();

                            task.unixCreatedTime = moment(task.created_date)
                                .valueOf();
                            task.unixUpdatedTime = task.updated_date
                                ? moment(task.updated_date)
                                    .valueOf()
                                : null;

                            task.unixDeletedTime = task.deleted_date
                                ? moment(task.deleted_date)
                                    .valueOf()
                                : null;

                            task.unixFinishedTime = task.finished_at
                                ? moment(task.finished_at)
                                    .valueOf()
                                : null;
                            task.unixDeadlineTime = moment(task.deadline)
                                .valueOf();
                            task.unixStartDeadlineTime = moment(task.start_deadline)
                                .valueOf();

                            // eslint-disable-next-line max-nested-callbacks
                            task.tags.map((tag: Tags, indexTags) => {
                                task.tags[indexTags].fontColor = DataService.fontColor(tag.color);
                            });

                            if (!task.finished_at && task.deadline) {
                                const fullDiff = Math.abs(startdate.valueOf() - deadline);
                                const dueDiff = Math.abs(currentdate - deadline);

                                if (currentdate < deadline) {
                                    const percentage = (fullDiff - dueDiff) * 100 / fullDiff;

                                    task.taskTime = {
                                        overtime: false,
                                        percentage,
                                        time_fond: moment.duration(startdate.diff(deadline))
                                            .humanize(),
                                    };
                                } else {
                                    task.taskTime = {
                                        overtime: true,
                                        percentage: 100,
                                        time_fond: moment.duration(startdate.diff(deadline))
                                            .humanize(),
                                    };
                                }
                            } else {
                                const fullDiff = Math.abs(startdate.valueOf() - deadline);
                                const dueDiff = Math.abs(finishdate - deadline);

                                if (finishdate < deadline) {
                                    const percentage = (fullDiff - dueDiff) * 100 / fullDiff;

                                    task.taskTime = {
                                        overtime: false,
                                        percentage,
                                        time_fond: moment.duration(startdate.diff(deadline))
                                            .humanize(),
                                    };
                                } else {
                                    task.taskTime = {
                                        overtime: true,
                                        percentage: 100,
                                        time_fond: moment.duration(startdate.diff(deadline))
                                            .humanize(),
                                    };
                                }
                            }

                            task.unixSLATime = startdate
                                .add(task.category?.sla.duration, 'minutes')
                                .valueOf();

                            if (this.permissionsService.viewTask(task, task.ticket)) {
                                resultTaskData.push(task);
                            }
                        });
                    }

                    this.resultTaskSource.next(resultTaskData);
                });
        }
    }

    // TAGS SETTERS
    setTagsDataSource(): void {
        this.getTagsData()
            .subscribe((tags: Tags[]) => {
                if (typeof tags !== 'undefined' && tags.length > 0) {
                    this.resultTagSource.next(tags);
                }
            });
    }

    // VACATIONS SETTERS
    setVacationsPerUserDataSource(user: User | number): void {
        const userId = typeof user === 'number' ? user : user.id;

        if (this.resultVacationPerUserSource.getValue()?.length === 0) {
            const resultVacationData: Vacation[] = [];

            this.getVacationsData(userId)
                .subscribe((vacations: Vacation[]) => {
                    if (typeof vacations !== 'undefined' && vacations.length > 0) {
                        vacations.forEach((vacation: Vacation) => {
                            if (this.permissionsService.viewVacation()) {
                                resultVacationData.push(vacation);
                            }
                        });
                    }

                    this.resultVacationPerUserSource.next(resultVacationData);
                });
        }
    }

    setVacationsDataSource(): void {
        if (this.resultVacationSource.getValue()?.length === 0) {
            const resultVacationData: Vacation[] = [];

            this.getVacationsData()
                .subscribe((vacations: Vacation[]) => {
                    if (typeof vacations !== 'undefined' && vacations.length > 0) {
                        vacations.forEach((vacation: Vacation) => {
                            if (this.permissionsService.viewVacation()) {
                                resultVacationData.push(vacation);
                            }
                        });
                    }

                    this.resultVacationSource.next(resultVacationData);
                });
        }
    }

    setVacationCategoriesDataSource(): void {
        if (this.resultVacationCategoriesSource.getValue()?.length === 0) {
            this.getVacationCategoriesData()
                .subscribe((categories: VacationCategory[]) => {
                    this.resultVacationCategoriesSource.next(
                        typeof categories !== 'undefined' && categories.length > 0 ? categories : [],
                    );
                });
        }
    }

    setVacationDetailDataSource(data: Vacation | number): void {
        const id = typeof data === 'number' ? data : data.id;

        this.getVacationData(id)
            .subscribe((vacation: Vacation) => {
                if (this.permissionsService.viewVacation()) {
                    this.resultVacationDetailSource.next(
                        typeof vacation !== 'undefined' ? vacation : null,
                    );
                }
            });
    }

    // MATERIALS SETTERS
    // eslint-disable-next-line max-lines-per-function
    setMaterialsDataSource(): void {
        if (this.resultMaterialSource.getValue()?.length === 0) {
            (this.resultBudgetSource.getValue()?.length === 0
                    ? // eslint-disable-next-line max-lines-per-function
                    this.getBudgetsData()
                    : this.resultBudgetSource
            ).subscribe((budgets: MaterialBudget[]) => {
                (this.resultMaterialItemSource.getValue()?.length === 0
                        ? // eslint-disable-next-line max-lines-per-function,max-len
                        this.getMaterialsItemsData()
                        : this.resultMaterialItemSource
                ).subscribe((items: OrderItem[]) => {
                    // eslint-disable-next-line max-lines-per-function,max-nested-callbacks
                    this.getMaterialsData()
                        .subscribe((materials: Material[]) => {
                            const resultMaterialData: Material[] = [];

                            if (typeof materials !== 'undefined' && materials.length > 0) {
                                // eslint-disable-next-line complexity,max-lines-per-function,max-nested-callbacks
                                materials.forEach((material: Material) => {
                                    const usedBudgets: Array<{
                                        id: number;
                                        creator: User;
                                        name: string;
                                        amount: number;
                                        expenditures: {
                                            queue: {items: number; price: number};
                                            deleted: {items: number; price: number};
                                            success: {items: number; price: number};
                                            residue: number;
                                        };
                                        // eslint-disable-next-line @typescript-eslint/naming-convention
                                        expenditures_current: {
                                            price: number;
                                            items: number;
                                        };
                                        // eslint-disable-next-line @typescript-eslint/naming-convention
                                        start_at: string;
                                        // eslint-disable-next-line @typescript-eslint/naming-convention
                                        expire_at: string;
                                        department: Department;
                                        // eslint-disable-next-line @typescript-eslint/naming-convention
                                        created_date: string;
                                        approvers: User[];
                                        budgetitems: OrderItem[];
                                        startUnix: number;
                                        endUnix: number;
                                        confirmed: boolean;
                                        declined: boolean;
                                    }> = [];
                                    let budgetConfirmed: boolean;
                                    let budgetDeclined: boolean;
                                    let cashTotal = 0;
                                    let cashBudgetTotal = 0;
                                    let materialDeclineCNT = 0;
                                    let materialSubmitCNT = 0;

                                    material.submited = false;
                                    material.declined = false;
                                    material.viewers = [];
                                    material.orderitems = [];
                                    material.orderitems = items.filter(
                                        // eslint-disable-next-line max-nested-callbacks
                                        x => x.material_id === material.id,
                                    );
                                    material.unixCreatedTime = moment(material.created_date)
                                        .valueOf();
                                    material.unixUpdatedTime = material.updated_date
                                        ? moment(material.updated_date)
                                            .valueOf()
                                        : null;

                                    material.unixDeletedTime = material.deleted_date
                                        ? moment(material.deleted_date)
                                            .valueOf()
                                        : null;

                                    material.totalCountItem =
                                        material.orderitems.length > 0 ? material.orderitems.length : 0;

                                    if (material.confirmers.length > 0) {
                                        // eslint-disable-next-line max-len
                                        const bossCheck = material.confirmers.find(
                                            // eslint-disable-next-line max-nested-callbacks
                                            x =>
                                                x.id === this.authenticationService.currentUserValue?.id,
                                        );

                                        material.amiboss = !!bossCheck;
                                    } else {
                                        material.amiboss = false;
                                    }

                                    // eslint-disable-next-line complexity,max-lines-per-function,max-nested-callbacks
                                    material.orderitems.forEach((item: OrderItem) => {
                                        item.confirmed = false;
                                        item.declined = false;
                                        budgetConfirmed = false;
                                        budgetDeclined = false;

                                        let decline = 0;
                                        let submit = 0;

                                        if (typeof budgets !== 'undefined' && budgets.length > 0) {
                                            // eslint-disable-next-line max-len
                                            // eslint-disable-next-line complexity,max-lines-per-function,max-nested-callbacks
                                            budgets.forEach((budget: MaterialBudget) => {
                                                if (budget.id === item.budget_id) {
                                                    item.budget = budget;

                                                    // ITEMS APPROVALS => TO MATERIAL APPROVAL
                                                    const reqSubmits = item.budget.approvers.length;

                                                    // eslint-disable-next-line max-nested-callbacks
                                                    item.budget.approvers.forEach((approver: User) => {
                                                        material.confirmers.forEach(
                                                            // eslint-disable-next-line max-nested-callbacks
                                                            (confirmer: User) => {
                                                                if (
                                                                    approver.id === confirmer.id &&
                                                                    confirmer.material_assignation_users
                                                                        ?.confirmed_date
                                                                ) {
                                                                    submit = submit + 1;
                                                                }

                                                                if (
                                                                    approver.id === confirmer.id &&
                                                                    confirmer.material_assignation_users
                                                                        ?.declined_date
                                                                ) {
                                                                    decline = decline + 1;
                                                                }
                                                            },
                                                        );
                                                    });
                                                    material.amiviewer = false;
                                                    // eslint-disable-next-line max-nested-callbacks
                                                    item.budget.viewers.forEach((viewer: User) => {
                                                        material.viewers.push(viewer);
                                                        material.amiviewer =
                                                            viewer.id ===
                                                            this.authenticationService.currentUserValue
                                                                ?.id;
                                                    });

                                                    if (
                                                        submit > 0 &&
                                                        decline === 0 &&
                                                        submit === reqSubmits
                                                    ) {
                                                        item.confirmed = true;
                                                        materialSubmitCNT = materialSubmitCNT + 1;
                                                        budgetConfirmed = true;
                                                    }

                                                    if (decline > 0) {
                                                        item.declined = true;
                                                        materialDeclineCNT = materialDeclineCNT + 1;
                                                        budgetDeclined = true;
                                                    }

                                                    if (
                                                        materialSubmitCNT > 0 &&
                                                        materialDeclineCNT === 0 &&
                                                        materialSubmitCNT === material.totalCountItem
                                                    ) {
                                                        material.submited = true;
                                                    }

                                                    if (material.deleted_date) {
                                                        budgetDeclined = true;
                                                    }

                                                    if (materialDeclineCNT > 0) {
                                                        material.declined = true;
                                                    }

                                                    // BUDGET => TO MATERIAL
                                                    if (
                                                        // eslint-disable-next-line max-len
                                                        // eslint-disable-next-line max-nested-callbacks,@typescript-eslint/no-unnecessary-condition
                                                        !usedBudgets.find(t => t.id === item.budget.id)
                                                    ) {
                                                        item.budget.totalCountItem =
                                                            item.budget.budgetitems.length;

                                                        item.budget.budgetitems.forEach(
                                                            // eslint-disable-next-line max-nested-callbacks
                                                            (dataBudget: OrderItem) => {
                                                                cashBudgetTotal += dataBudget.dph
                                                                    ? dataBudget.price -
                                                                    dataBudget.price * 0.21
                                                                    : dataBudget.price;
                                                            },
                                                        );
                                                        item.budget.totalCountPrice = cashBudgetTotal;
                                                        usedBudgets.push({
                                                            id: item.budget.id,
                                                            creator: item.budget.creator,
                                                            name: item.budget.name,
                                                            amount: item.budget.amount,
                                                            expenditures: item.budget.expenditures,
                                                            expenditures_current: {
                                                                price: item.dph
                                                                    ? item.price - item.price * 0.21
                                                                    : item.price,
                                                                items: 1,
                                                            },
                                                            start_at: item.budget.start_at,
                                                            expire_at: item.budget.expire_at,
                                                            department: item.budget.department,
                                                            created_date: item.budget.created_date,
                                                            approvers: item.budget.approvers,
                                                            budgetitems: item.budget.budgetitems,
                                                            startUnix: item.budget.startUnix,
                                                            endUnix: item.budget.endUnix,
                                                            confirmed: budgetConfirmed,
                                                            declined: budgetDeclined,
                                                        });
                                                    } else {
                                                        const tempBUdget = usedBudgets.find(
                                                            // eslint-disable-next-line max-nested-callbacks
                                                            t => t.id === item.budget.id,
                                                        );

                                                        if (typeof tempBUdget !== 'undefined') {
                                                            tempBUdget.expenditures_current.price +=
                                                                item.dph
                                                                    ? item.price - item.price * 0.21
                                                                    : item.price;

                                                            tempBUdget.expenditures_current.items += 1;
                                                        }
                                                    }
                                                }
                                            });
                                        }

                                        cashTotal += item.dph
                                            ? item.price - item.price * 0.21
                                            : item.price;
                                    });

                                    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                                    if (material.submited && !material.deleted_date) {
                                        material.status = 'schváleno';
                                    }

                                    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                                    if (material.declined && !material.deleted_date) {
                                        material.status = 'zamítnuto';
                                    }

                                    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                                    if (material.deleted_date) {
                                        material.status = 'stornováno';
                                    }

                                    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                                    if (!material.deleted_date) {
                                        material.status = 've frontě';
                                    }

                                    material.totalCountPrice = cashTotal ? cashTotal : 0;
                                    material.usedBudgets = usedBudgets;

                                    if (this.permissionsService.viewMaterial(material)) {
                                        resultMaterialData.push(material);
                                    }
                                });
                            }

                            this.resultMaterialSource.next(resultMaterialData);
                        });
                });
            });
        }
    }

    setMaterialsCategoryDataSource(): void {
        if (this.resultMaterialCategorySource.getValue()?.length === 0) {
            this.getMaterialsCategoryData()
                .subscribe((categories: MaterialCategory[]) => {
                    this.resultMaterialCategorySource.next(
                        typeof categories !== 'undefined' && categories.length > 0 ? categories : [],
                    );
                });
        }
    }

    setMaterialsItemsDataSource(): void {
        if (this.resultMaterialItemSource.getValue()?.length === 0) {
            this.getMaterialsItemsData()
                .subscribe((items: OrderItem[]) => {
                    this.resultMaterialItemSource.next(
                        typeof items !== 'undefined' && items.length > 0 ? items : [],
                    );
                });
        }
    }

    // eslint-disable-next-line max-lines-per-function
    setMaterialDetailDataSource(data: Material | number): void {
        const id = typeof data === 'number' ? data : data.id;

        // eslint-disable-next-line max-lines-per-function
        this.getBudgetsData()
            .subscribe((budgets: MaterialBudget[]) => {
                // eslint-disable-next-line max-lines-per-function,complexity
                this.getMaterialData(id)
                    .subscribe((material: Material) => {
                        const usedBudgets: Array<{
                            id: number;
                            creator: User;
                            name: string;
                            amount: number;
                            expenditures: {
                                queue: {items: number; price: number};
                                deleted: {items: number; price: number};
                                success: {items: number; price: number};
                                residue: number;
                            };
                            // eslint-disable-next-line @typescript-eslint/naming-convention
                            expenditures_current: {
                                price: number;
                                items: number;
                            };
                            // eslint-disable-next-line @typescript-eslint/naming-convention
                            start_at: string;
                            // eslint-disable-next-line @typescript-eslint/naming-convention
                            expire_at: string;
                            department: Department;
                            // eslint-disable-next-line @typescript-eslint/naming-convention
                            created_date: string;
                            approvers: User[];
                            budgetitems: OrderItem[];
                            startUnix: number;
                            endUnix: number;
                            confirmed: boolean;
                            declined: boolean;
                        }> = [];
                        let budgetConfirmed: boolean;
                        let budgetDeclined: boolean;
                        let cashTotal = 0;
                        let cashBudgetTotal = 0;
                        let materialDeclineCNT = 0;
                        let materialSubmitCNT = 0;

                        material.submited = false;
                        material.declined = false;
                        material.viewers = [];
                        material.unixCreatedTime = moment(material.created_date)
                            .valueOf();
                        material.unixUpdatedTime = material.updated_date
                            ? moment(material.updated_date)
                                .valueOf()
                            : null;

                        material.unixDeletedTime = material.deleted_date
                            ? moment(material.deleted_date)
                                .valueOf()
                            : null;

                        if (material.confirmers.length > 0) {
                            const bossCheck = material.confirmers.find(
                                // eslint-disable-next-line max-nested-callbacks
                                x => x.id === this.authenticationService.currentUserValue?.id,
                            );

                            material.amiboss = !!bossCheck;
                        } else {
                            material.amiboss = false;
                        }

                        // eslint-disable-next-line max-lines-per-function,max-nested-callbacks
                        material.orderitems.forEach((item: OrderItem) => {
                            material.totalCountItem = material.orderitems.length
                                ? material.orderitems.length
                                : 0;
                            item.confirmed = false;
                            item.declined = false;
                            budgetConfirmed = false;
                            budgetDeclined = false;

                            let decline = 0;
                            let submit = 0;

                            if (typeof budgets !== 'undefined') {
                                // eslint-disable-next-line complexity,max-lines-per-function,max-nested-callbacks
                                budgets.forEach((budget: MaterialBudget) => {
                                    if (budget.id === item.budget_id) {
                                        item.budget = budget;

                                        // ITEMS APPROVALS => TO MATERIAL APPROVAL
                                        const reqSubmits = item.budget.approvers.length;

                                        // eslint-disable-next-line max-nested-callbacks
                                        item.budget.approvers.forEach((approver: User) => {
                                            // eslint-disable-next-line max-nested-callbacks
                                            material.confirmers.forEach((confirmer: User) => {
                                                if (
                                                    approver.id === confirmer.id &&
                                                    confirmer.material_assignation_users?.confirmed_date
                                                ) {
                                                    submit = submit + 1;
                                                }

                                                if (
                                                    approver.id === confirmer.id &&
                                                    confirmer.material_assignation_users?.declined_date
                                                ) {
                                                    decline = decline + 1;
                                                }
                                            });
                                        });
                                        material.amiviewer = false;
                                        // eslint-disable-next-line max-nested-callbacks
                                        budget.viewers.forEach((viewer: User) => {
                                            material.viewers.push(viewer);
                                            material.amiviewer =
                                                viewer.id ===
                                                this.authenticationService.currentUserValue?.id;
                                        });

                                        if (submit > 0 && decline === 0 && submit === reqSubmits) {
                                            item.confirmed = true;
                                            materialSubmitCNT = materialSubmitCNT + 1;
                                            budgetConfirmed = true;
                                        }

                                        if (decline > 0) {
                                            item.declined = true;
                                            materialDeclineCNT = materialDeclineCNT + 1;
                                            budgetDeclined = true;
                                        }

                                        if (
                                            materialSubmitCNT > 0 &&
                                            materialDeclineCNT === 0 &&
                                            materialSubmitCNT === material.totalCountItem
                                        ) {
                                            material.submited = true;
                                        }

                                        if (material.deleted_date) {
                                            budgetDeclined = true;
                                        }

                                        if (materialDeclineCNT > 0) {
                                            material.declined = true;
                                        }

                                        if (
                                            // eslint-disable-next-line max-len
                                            // eslint-disable-next-line max-nested-callbacks,@typescript-eslint/no-unnecessary-condition
                                            !usedBudgets.find(t => t.id === item.budget.id)
                                        ) {
                                            item.budget.totalCountItem = item.budget.budgetitems.length;
                                            // eslint-disable-next-line max-nested-callbacks
                                            item.budget.budgetitems.forEach((dataBudget: OrderItem) => {
                                                cashBudgetTotal += dataBudget.dph
                                                    ? dataBudget.price - dataBudget.price * 0.21
                                                    : dataBudget.price;
                                            });
                                            item.budget.totalCountPrice = cashBudgetTotal;
                                            usedBudgets.push({
                                                id: item.budget.id,
                                                creator: item.budget.creator,
                                                name: item.budget.name,
                                                amount: item.budget.amount,
                                                expenditures: item.budget.expenditures,
                                                expenditures_current: {
                                                    price: item.dph
                                                        ? item.price - item.price * 0.21
                                                        : item.price,
                                                    items: 1,
                                                },
                                                start_at: item.budget.start_at,
                                                expire_at: item.budget.expire_at,
                                                department: item.budget.department,
                                                created_date: item.budget.created_date,
                                                approvers: item.budget.approvers,
                                                budgetitems: item.budget.budgetitems,
                                                startUnix: item.budget.startUnix,
                                                endUnix: item.budget.endUnix,
                                                confirmed: budgetConfirmed,
                                                declined: budgetDeclined,
                                            });
                                        } else {
                                            const tempBUdget = usedBudgets.find(
                                                // eslint-disable-next-line max-nested-callbacks
                                                t => t.id === item.budget.id,
                                            );

                                            if (typeof tempBUdget !== 'undefined') {
                                                tempBUdget.expenditures_current.price += item.dph
                                                    ? item.price - item.price * 0.21
                                                    : item.price;

                                                tempBUdget.expenditures_current.items += 1;
                                            }
                                        }
                                    }
                                });
                            }

                            cashTotal += item.dph ? item.price - item.price * 0.21 : item.price;
                        });

                        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                        if (material.submited && !material.deleted_date) {
                            material.status = 'schváleno';
                        }

                        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                        if (material.declined && !material.deleted_date) {
                            material.status = 'zamítnuto';
                        }

                        if (material.deleted_date) {
                            material.status = 'stornováno';
                        }

                        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                        if (!material.declined && !material.submited && !material.deleted_date) {
                            material.status = 've frontě';
                        }

                        material.totalCountItem = material.orderitems.length;
                        material.totalCountPrice = cashTotal;
                        material.usedBudgets = usedBudgets;

                        if (this.permissionsService.viewMaterial(material)) {
                            this.resultMaterialDetailSource.next(material);
                        }
                    });
            });
    }

    // BUDGETS SETTERS
    setBudgetsDataSource(): void {
        if (this.resultBudgetSource.getValue()?.length === 0) {
            this.getBudgetsData()
                .subscribe((budgets: MaterialBudget[]) => {
                    this.resultBudgetSource.next(
                        typeof budgets !== 'undefined' && budgets.length > 0 ? budgets : [],
                    );
                });
        }
    }

    // USERS SETTERS
    setUsersDataSource(active?: boolean): void {
        if (this.resultUserSource.getValue()?.length === 0) {
            const resultUserData: User[] = [];

            this.getUsersData()
                .subscribe((users: User[]) => {
                    if (typeof users !== 'undefined' && users.length > 0) {
                        // eslint-disable-next-line complexity
                        users.forEach((user: User) => {
                            user.roles = [];
                            user.unixCreatedTime = moment(user.created_date)
                                .valueOf();
                            user.unixUpdatedTime = user.updated_date
                                ? moment(user.updated_date)
                                    .valueOf()
                                : null;

                            user.unixDeletedTime = user.deleted_date
                                ? moment(user.deleted_date)
                                    .valueOf()
                                : null;

                            user.assignations?.forEach((assignation: Assignation) => {
                                user.roles?.push({
                                    company: assignation.department.company.name,
                                    department: assignation.department.name,
                                    role: assignation.role.name,
                                });
                            });

                            if (active && user.authorized && !user.deleted_date) {
                                resultUserData.push(user);
                            } else {
                                resultUserData.push(user);
                            }
                        });
                    }

                    this.resultUserSource.next(resultUserData);
                });
        }
    }

    // eslint-disable-next-line max-lines-per-function
    setUserDetailSource(userData: User | number): void {
        const id = typeof userData === 'number' ? userData : userData.id;

        // eslint-disable-next-line max-lines-per-function
        this.getUserData(id)
            .subscribe((user: User) => {
                const leader = this.permissionsService.checkUserISLeader(user);

                user.is_leader = leader.leaderStatus;
                user.is_leader_departments = leader.leaderDepartments;
                user.is_hotliner = false;
                this.getHotlinersData()
                    .subscribe((hotliners: Hotliner[]) => {
                        user.is_hotliner = this.permissionsService.checkUserISHotliner(hotliners);
                    });
                user.unixCreatedTime = moment(user.created_date)
                    .valueOf();
                user.unixUpdatedTime = user.updated_date ? moment(user.updated_date)
                    .valueOf() : null;
                user.unixDeletedTime = user.deleted_date ? moment(user.deleted_date)
                    .valueOf() : null;
                user.ascApiUser_data = null;
                user.mntApiUser_data = null;
                user.crmApiUser_data = null;
                this.resultUserDetailSource.next(user);

                this.acsService.acsUsersSource.subscribe({
                    next: (
                        data: Array<{[key: string]: AcsUsers | unknown | null | undefined}> | null,
                    ) => {
                        if (data && data.length > 0) {
                            const tempResult = data.find(x => (x.email as string) === user.workemail);

                            if (tempResult) {
                                this.resultUserDetailSource.next({
                                    ...user,
                                    ascApiUser_data: tempResult,
                                });
                            } else if (
                                Array.isArray(Object.values(data)) &&
                                Object.values(data).length > 0
                            ) {
                                const tempArray: Array<{[key: string]: unknown}> =
                                    Object.values(data);
                                const secondChance = tempArray.find(x => x.email === user.workemail);

                                this.resultUserDetailSource.next({
                                    ...user,
                                    ascApiUser_data: secondChance ?? null,
                                });
                            } else {
                                this.resultUserDetailSource.next({...user, ascApiUser_data: null});
                            }
                        } else {
                            this.resultUserDetailSource.next({...user, ascApiUser_data: null});
                        }
                    },
                    error: error => {
                        console.error(error);
                    },
                });

                this.carsService.carsUsersSource.subscribe({
                    next: (data: Array<{[key: string]: unknown | null | undefined}> | null) => {
                        if (data && data.length > 0) {
                            const tempResult = data.find(x => (x.email as string) === user.workemail);

                            if (tempResult) {
                                this.resultUserDetailSource.next({
                                    ...user,
                                    carsApiUser_data: tempResult,
                                });
                            } else if (
                                Array.isArray(Object.values(data)) &&
                                Object.values(data).length > 0
                            ) {
                                const tempArray: Array<{[key: string]: unknown}> =
                                    Object.values(data);
                                const secondChance = tempArray.find(x => x.email === user.workemail);

                                this.resultUserDetailSource.next({
                                    ...user,
                                    carsApiUser_data: secondChance ?? null,
                                });
                            } else {
                                this.resultUserDetailSource.next({...user, carsApiUser_data: null});
                            }
                        } else {
                            user.carsApiUser_data = null;
                            this.resultUserDetailSource.next({...user, carsApiUser_data: null});
                        }
                    },
                    error: error => {
                        console.error(error);
                    },
                });

                this.monitoringService.siteMonitorUsersSource.subscribe({
                    next: (
                        data: Array<{
                            [key: string]: MonitoringService | unknown | null | undefined;
                        }> | null,
                    ) => {
                        if (data && data.length > 0) {
                            const tempResult = data.find(x => (x.email as string) === user.workemail);

                            if (tempResult) {
                                this.resultUserDetailSource.next({
                                    ...user,
                                    mntApiUser_data: tempResult,
                                });
                            } else if (
                                Array.isArray(Object.values(data)) &&
                                Object.values(data).length > 0
                            ) {
                                const tempArray: Array<{[key: string]: unknown}> =
                                    Object.values(data);
                                const secondChance = tempArray.find(x => x.email === user.workemail);

                                this.resultUserDetailSource.next({
                                    ...user,
                                    mntApiUser_data: secondChance ?? null,
                                });
                            } else {
                                this.resultUserDetailSource.next({...user, mntApiUser_data: null});
                            }
                        } else {
                            this.resultUserDetailSource.next({...user, mntApiUser_data: null});
                        }
                    },
                    error: error => {
                        console.error(error);
                    },
                });

                this.crmService.crmUsersSource.subscribe({
                    next: (
                        data: Array<{[key: string]: CrmUsers | unknown | null | undefined}> | null,
                    ) => {
                        if (data && data.length > 0) {
                            const tempResult = data.find(x => (x.email as string) === user.workemail);

                            if (tempResult) {
                                this.resultUserDetailSource.next({
                                    ...user,
                                    crmApiUser_data: tempResult,
                                });
                            } else if (
                                Array.isArray(Object.values(data)) &&
                                Object.values(data).length > 0
                            ) {
                                const tempArray: Array<{[key: string]: unknown}> =
                                    Object.values(data);
                                const secondChance = tempArray.find(
                                    x => (x.email as string) === user.workemail,
                                );

                                this.resultUserDetailSource.next({
                                    ...user,
                                    crmApiUser_data: secondChance ?? null,
                                });
                            } else {
                                this.resultUserDetailSource.next({...user, crmApiUser_data: null});
                            }
                        } else {
                            this.resultUserDetailSource.next({...user, crmApiUser_data: null});
                        }
                    },
                    error: error => {
                        console.error(error);
                    },
                });

                this.erpService.erpUsersSource.subscribe({
                    next: (
                        data: Array<{[key: string]: ErpUsers | unknown | null | undefined}> | null,
                    ) => {
                        if (data && data.length > 0) {
                            const tempResult = data.find(x => (x.email as string) === user.workemail);

                            if (tempResult) {
                                this.resultUserDetailSource.next({
                                    ...user,
                                    crmApiUser_data: tempResult,
                                });
                            } else if (
                                Array.isArray(Object.values(data)) &&
                                Object.values(data).length > 0
                            ) {
                                const tempArray: Array<{[key: string]: unknown}> =
                                    Object.values(data);
                                const secondChance = tempArray.find(x => x.email === user.workemail);

                                this.resultUserDetailSource.next({
                                    ...user,
                                    crmApiUser_data: secondChance ?? null,
                                });
                            } else {
                                this.resultUserDetailSource.next({...user, crmApiUser_data: null});
                            }
                        } else {
                            this.resultUserDetailSource.next({...user, crmApiUser_data: null});
                        }
                    },
                    error: error => {
                        console.error(error);
                    },
                });
            });
    }

    // CARS SETTERS
    setCarsDataSource(): void {
        if (this.resultCarsSource.getValue()?.length === 0) {
            this.getCarsData()
                .subscribe((cars: Car[]) => {
                    const resultCarsData: Car[] = [];

                    cars.forEach((car: Car) => {
                        car.unixCreatedTime = moment(car.created_date)
                            .valueOf();
                        car.unixUpdatedTime = car.updated_date
                            ? moment(car.updated_date)
                                .valueOf()
                            : null;

                        car.unixDeletedTime = car.deleted_date
                            ? moment(car.deleted_date)
                                .valueOf()
                            : null;
                        resultCarsData.push(car);
                    });
                    this.resultCarsSource.next(resultCarsData);
                });
        }
    }

    // KNOWLEDGEBASE SETTERS
    setKnowledgeCategoriesSource(): void {
        const resultCategoryData: KnowledgeCategory[] = [];

        this.getKnowledgeCategories()
            .subscribe((categories: KnowledgeCategory[]) => {
                if (typeof categories !== 'undefined' && categories.length > 0) {
                    categories.forEach((category: KnowledgeCategory) => {
                        resultCategoryData.push(category);
                    });
                }

                this.resultKnowledgeCategorySource.next(resultCategoryData);
            });
    }

    setKnowledgesDataSource(): void {
        if (this.resultKnowledgeSource.getValue()?.length === 0) {
            const resultKnowledgeData: Knowledge[] = [];

            this.getKnowledgesData()
                .subscribe((knowledges: Knowledge[]) => {
                    if (typeof knowledges !== 'undefined' && knowledges.length > 0) {
                        // eslint-disable-next-line complexity
                        knowledges.forEach((knowledge: Knowledge) => {
                            knowledge.unixCreatedTime = moment(knowledge.created_date)
                                .valueOf();
                            knowledge.unixUpdatedTime = knowledge.updated_date
                                ? moment(knowledge.updated_date)
                                    .valueOf()
                                : null;

                            knowledge.unixDeletedTime = knowledge.deleted_date
                                ? moment(knowledge.deleted_date)
                                    .valueOf()
                                : null;

                            knowledge.maintaskHTML = this.sanitizer.bypassSecurityTrustHtml(
                                knowledge.maintask,
                            );

                            if (knowledge.departments.length > 0 || knowledge.users.length > 0) {
                                if (this.permissionsService.viewKnowledge(knowledge)) {
                                    resultKnowledgeData.push(knowledge);
                                }
                            }

                            if (knowledge.departments.length === 0 && knowledge.users.length === 0) {
                                resultKnowledgeData.push(knowledge);
                            }
                        });
                    }

                    this.resultKnowledgeSource.next(resultKnowledgeData);
                });
        }
    }

    setKnowledgeDetailDataSource(knowledge: Knowledge | number): void {
        const id = typeof knowledge === 'number' ? knowledge : knowledge.id;

        this.getKnowledgeData(id)
            .subscribe((data: Knowledge) => {
                if (this.permissionsService.viewKnowledge(data)) {
                    data.unixCreatedTime = moment(data.created_date)
                        .valueOf();
                    data.unixUpdatedTime = data.updated_date
                        ? moment(data.updated_date)
                            .valueOf()
                        : null;

                    data.unixDeletedTime = data.deleted_date
                        ? moment(data.deleted_date)
                            .valueOf()
                        : null;
                    data.maintaskHTML = this.sanitizer.bypassSecurityTrustHtml(data.maintask);
                    data.comments.map((comment: KnowledgeComment, index) => {
                        data.comments[index].unixCreatedTime = moment(comment.created_date)
                            .valueOf();
                        data.comments[index].textHTML = this.sanitizer.bypassSecurityTrustHtml(
                            comment.text,
                        );

                        // eslint-disable-next-line max-nested-callbacks
                        comment.replies.map((reply: KnowledgeComment, iterator) => {
                            data.comments[index].replies[iterator].unixCreatedTime = moment(
                                reply.created_date,
                            )
                                .valueOf();

                            data.comments[index].replies[iterator].textHTML =
                                this.sanitizer.bypassSecurityTrustHtml(reply.text);
                        });
                    });
                    this.resultKnowledgeDetailSource.next(data);
                } else {
                    this.resultKnowledgeDetailSource.next(null);

                    const body = 'Nejste oprávněn nahlížet do tohoto postupu...';
                    const options = {progressBar: true, timeOut: 5000, toastClass: 'red'};

                    this.messageService.showError('Nedostatečné oprávnění', body, options);
                    this.location.back();
                }
            });
    }

    // HOTLINE SETTERS
    setHotlinesDataSource(): void {
        if (this.resultHotlineSource.getValue()?.length === 0) {
            this.getHotlinesData()
                .subscribe((hotlines: Hotline[]) => {
                    const resultHotlinesData: Hotline[] = [];

                    hotlines.map((hotline: Hotline) => {
                        hotline.unixStartDate = moment(hotline.start_at)
                            .valueOf();
                        hotline.unixEndDate = moment(hotline.end_at)
                            .valueOf();
                        hotline.unixCreatedTime = moment(hotline.created_date)
                            .valueOf();
                        hotline.unixUpdatedTime = moment(hotline.updated_date)
                            .valueOf();
                        // eslint-disable-next-line max-nested-callbacks
                        hotline.reports.map((report: HotlineReport, index) => {
                            hotline.reports[index].unixCreatedTime = moment(
                                report.created_date,
                            )
                                .valueOf();

                            hotline.reports[index].unixUpdatedTime = moment(
                                report.updated_date,
                            )
                                .valueOf();

                            hotline.reports[index].reportHTML = this.sanitizer.bypassSecurityTrustHtml(
                                report.report,
                            );
                        });

                        if (this.permissionsService.viewHotline(hotline)) {
                            resultHotlinesData.push(hotline);
                        }
                    });
                    this.resultHotlineSource.next(resultHotlinesData);
                });
        }
    }

    setHotlineDetailDataSource(hotline: Hotline | number): void {
        const id = typeof hotline === 'number' ? hotline : hotline.id;

        this.getHotlineData(id)
            .subscribe((data: Hotline) => {
                data.unixStartDate = moment(data.start_at)
                    .valueOf();
                data.unixEndDate = moment(data.end_at)
                    .valueOf();
                data.unixCreatedTime = moment(data.created_date)
                    .valueOf();
                data.unixUpdatedTime = moment(data.updated_date)
                    .valueOf();
                data.reports.map((report: HotlineReport, index) => {
                    data.reports[index].unixCreatedTime = moment(report.created_date)
                        .valueOf();
                    data.reports[index].unixUpdatedTime = moment(report.updated_date)
                        .valueOf();
                    data.reports[index].reportHTML = this.sanitizer.bypassSecurityTrustHtml(
                        report.report,
                    );
                });
                this.resultHotlineDetailSource.next(data);
            });
    }

    // eslint-disable-next-line max-lines-per-function
    setHotlinersDataSource(): void {
        if (this.resultHotlinersSource.getValue()?.length === 0) {
            this.getHotlinersData()
                .subscribe((hotliners: Hotliner[]) => {
                    this.getCompaniesData()
                        .subscribe((companies: Company[]) => {
                            const resultHotlinersData: Hotliner[] = [];

                            if (typeof hotliners !== 'undefined' && hotliners.length > 0) {
                                // eslint-disable-next-line max-nested-callbacks
                                hotliners.map((hotliner: Hotliner) => {
                                    hotliner.score = 0;
                                    hotliner.next_hotlines = [];
                                    hotliner.assignations = [];
                                    hotliner.unixCreatedTime = moment(hotliner.created_date)
                                        .valueOf();
                                    hotliner.unixUpdatedTime = moment(hotliner.updated_date)
                                        .valueOf();
                                    // eslint-disable-next-line max-nested-callbacks
                                    hotliner.hotlines.forEach((hotline: Hotline) => {
                                        const tempCompany = companies.find(
                                            // eslint-disable-next-line max-nested-callbacks
                                            company => company.id === hotline.company_id,
                                        );

                                        if (typeof tempCompany !== 'undefined') {
                                            hotline.company = tempCompany;
                                        }

                                        const start = moment(hotline.start_at)
                                            .format('YYYY-MM-DD');
                                        const end = moment(hotline.end_at)
                                            .format('YYYY-MM-DD');
                                        const curr = moment()
                                            .format('YYYY-MM-DD');

                                        if (start >= curr) {
                                            hotliner.next_hotlines.push(hotline);
                                        }

                                        if (!hotline.deleted_date && end < curr) {
                                            hotliner.score = hotliner.score + hotline.score;
                                        }
                                    });

                                    // eslint-disable-next-line max-nested-callbacks
                                    hotliner.user.assignations?.forEach((assignation: Assignation) => {
                                        hotliner.assignations.push({
                                            company: assignation.department.company.name,
                                            department: assignation.department.name,
                                            role: assignation.role.name,
                                        });
                                    });

                                    // eslint-disable-next-line max-nested-callbacks
                                    hotliner.next_hotlines = hotliner.next_hotlines.sort((a, b) => {
                                        const dateA = moment(a.start_at)
                                            .valueOf();
                                        const dateB = moment(b.start_at)
                                            .valueOf();

                                        return dateA - dateB;
                                    });
                                    resultHotlinersData.push(hotliner);
                                });
                            }

                            this.resultHotlinersSource.next(resultHotlinersData);
                        });
                });
        }
    }

    // EVENTS SETTERS
    setEventsDataSource(): void {
        if (this.resultHotlinersSource.getValue()?.length === 0) {
            this.getEventsData()
                .subscribe((events: Event[]) => {
                    const resultEventsData: Event[] = [];

                    // eslint-disable-next-line complexity
                    events.forEach((event: Event) => {
                        event.unixStartDate = moment(event.start_at)
                            .valueOf();
                        event.unixEndDate = moment(event.end_at)
                            .valueOf();
                        event.unixCreatedTime = moment(event.created_date)
                            .valueOf();
                        event.unixUpdatedTime = event.updated_date
                            ? moment(event.updated_date)
                                .valueOf()
                            : null;

                        event.unixDeletedTime = event.deleted_date
                            ? moment(event.deleted_date)
                                .valueOf()
                            : null;

                        event.descriptionHTML = this.sanitizer.bypassSecurityTrustHtml(
                            event.description,
                        );

                        if (event.departments.length > 0 || event.users.length > 0) {
                            if (this.permissionsService.viewEvent()) {
                                resultEventsData.push(event);
                            }
                        }

                        if (event.departments.length === 0 && event.users.length === 0) {
                            resultEventsData.push(event);
                        }
                    });
                    this.resultEventSource.next(resultEventsData);
                });
        }
    }

    // PROJECTS SETTERS
    setProjectsDataSource(): void {
        if (this.resultProjectSource.getValue()?.length === 0) {
            this.getProjectsData()
                .subscribe((projects: Project[]) => {
                    const resultProjectsData: Project[] = [];

                    projects.forEach((project: Project) => {
                        project.unixStartDate = moment(project.start_at)
                            .valueOf();
                        project.unixEndDate = moment(project.end_at)
                            .valueOf();
                        project.unixCreatedTime = moment(project.created_date)
                            .valueOf();
                        project.unixUpdatedTime = project.updated_date
                            ? moment(project.updated_date)
                                .valueOf()
                            : null;

                        project.unixDeletedTime = project.deleted_date
                            ? moment(project.deleted_date)
                                .valueOf()
                            : null;
                        resultProjectsData.push(project);
                    });
                    this.resultProjectSource.next(resultProjectsData);
                });
        }
    }

    // OFFICES SETTER
    setOfficesDataSource(): void {
        if (this.resultOfficesSource.getValue()?.length === 0) {
            this.getOfficesData()
                .subscribe((offices: Office[]) => {
                    const resultOfficesData: Office[] = [];

                    offices.forEach((office: Office) => {
                        office.unixCreatedTime = moment(office.created_date)
                            .valueOf();
                        office.unixUpdatedTime = office.updated_date
                            ? moment(office.updated_date)
                                .valueOf()
                            : null;

                        office.unixDeletedTime = office.deleted_date
                            ? moment(office.deleted_date)
                                .valueOf()
                            : null;
                        resultOfficesData.push(office);
                    });
                    this.resultOfficesSource.next(resultOfficesData);
                });
        }
    }

    // BOOKINGS SETTER
    setBookingsDataSource(): void {
        if (this.resultBookingSource.getValue()?.length === 0) {
            this.getBookingsData()
                .subscribe((bookings: Booking[]) => {
                    this.loadingBookingSource.next(false);

                    const resultBookingsData: Booking[] = [];

                    bookings.forEach((booking: Booking) => {
                        booking.unixStartDate = moment(booking.start)
                            .valueOf();
                        booking.unixEndDate = moment(booking.end)
                            .valueOf();
                        booking.unixCreatedTime = moment(booking.created_date)
                            .valueOf();
                        booking.unixUpdatedTime = booking.updated_date
                            ? moment(booking.updated_date)
                                .valueOf()
                            : null;

                        booking.unixDeletedTime = booking.deleted_date
                            ? moment(booking.deleted_date)
                                .valueOf()
                            : null;
                        resultBookingsData.push(booking);
                    });
                    this.resultBookingSource.next(resultBookingsData);
                });
        }
    }

    // CLEAR CACHES

    clearDepartmentsCache(): void {
        this.resultDepartmentSource.next([]);
    }

    clearEmployersCache(): void {
        this.resultEmployerSource.next([]);
    }

    clearRolesCache(): void {
        this.resultRoleSource.next([]);
    }

    clearCompaniesCache(): void {
        this.resultCompanySource.next([]);
    }

    clearNotificationOldCache(): void {
        this.resultOldNotificationData = [];
    }

    clearTicketsCache(): void {
        this.resultTicketSource.next([]);
    }

    clearTagsCache(): void {
        this.resultTagSource.next([]);
    }

    clearTicketCommentsCache(): void {
        this.resultTicketCommentSource.next([]);
    }

    clearTasksCache(): void {
        this.resultTaskSource.next([]);
    }

    clearVacationsCache(): void {
        this.resultVacationSource.next([]);
    }

    clearVacationsPerUserCache(): void {
        this.resultVacationPerUserSource.next([]);
    }

    clearMaterialsCache(): void {
        this.resultMaterialSource.next([]);
    }

    clearMaterialsItemsCache(): void {
        this.resultMaterialItemSource.next([]);
    }

    clearBudgetsCache(): void {
        this.resultBudgetSource.next([]);
    }

    clearHotlinesCache(): void {
        this.resultHotlineSource.next([]);
    }

    clearHotlinersCache(): void {
        this.resultHotlinersSource.next([]);
    }

    clearKnowledgesCache(): void {
        this.resultKnowledgeSource.next([]);
    }

    clearKnowledgeCommentsCache(): void {
        this.resultKnowledgeCommentSource.next([]);
    }

    clearUserCache(): void {
        this.resultUserSource.next([]);
    }

    clearClientCache(): void {
        this.resultClientSource.next([]);
    }

    clearClientServiceCache(): void {
        this.resultClientServiceSource.next([]);
    }

    clearCarsCache(): void {
        this.resultCarsSource.next([]);
    }

    clearOfficesCache(): void {
        this.resultOfficesSource.next([]);
    }

    clearBooksCache(): void {
        this.resultBookingSource.next([]);
    }

    clearBuildingsCache(): void {
        this.resultBuildingsSource.next([]);
    }

    clearEventCache(): void {
        this.resultEventSource.next([]);
    }

    clearAllCache(): void {
        this.resultTicketSource.next([]);
        this.resultTaskSource.next([]);
        this.resultKnowledgeSource.next([]);
        this.resultHotlineSource.next([]);
        this.resultHotlinersSource.next([]);
        this.resultVacationSource.next([]);
        this.resultMaterialSource.next([]);
        this.resultUserSource.next([]);
        this.resultClientSource.next([]);
        this.resultClientServiceSource.next([]);
        this.resultBudgetSource.next([]);
        this.resultMaterialItemSource.next([]);
        this.resultBookingSource.next([]);
        this.resultOfficesSource.next([]);
        this.resultCarsSource.next([]);
        this.resultBuildingsSource.next([]);
        this.resultEventSource.next([]);
    }
}
