import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {Material} from '@src/app/_models/material/material';
import {MaterialBudget} from '@src/app/_models/material/material-budget';
import {catchError, shareReplay, tap} from 'rxjs/operators';
import {MessageService} from '@src/app/_services/message.service';
import {MaterialPayment} from '@src/app/_models/material/material-payment';
import {User} from '@src/app/_models/user/user';
import {MaterialFile} from '@src/app/_models/material/material-file';
import {OrderItem} from '@src/app/_models/material/material-item';
import {MaterialAssignation} from '@src/app/_models/material/material-assignation';
import {DataService} from '@src/app/_services/data.service';
import {MaterialLogs} from '@src/app/_models/material/material-logs';
import {EnvironmentService} from '@src/app/_services/environment.service';
import {Department} from '@src/app/_models/department/department';

const httpOptions = {
    headers: new HttpHeaders({'Content-Type': 'application/json'}),
};

export interface OrderInput {
    id?: number;
    reason: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    to_storage: boolean;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    payment_id: number;
    note: string;
    receipt?: MaterialFile[];
    orderitems?: OrderItem[];
    // eslint-disable-next-line @typescript-eslint/naming-convention
    created_by?: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    updated_by?: number;
    confirmers: User[];
}

export interface OrderItemInput {
    id?: number;
    name: string;
    amount: number;
    price: number;
    dph: boolean;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    department_id: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    budget_id?: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    category_id: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    item_id?: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    material_id?: number;
    budget?: BudgetInput;
}

export interface BudgetInput {
    id?: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    created_by?: number;
    creator?: User;
    name?: string;
    amount?: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    start_at?: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    expire_at?: string;
    department?: Department;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    created_date?: string;
    approvers?: User[];
    budgetitems?: OrderItem[];
    expenditures?: {
        price: number;
        items: number;
    };
    // eslint-disable-next-line @typescript-eslint/naming-convention
    expenditures_current?: {
        price: number;
        items: number;
    };
    // eslint-disable-next-line @typescript-eslint/naming-convention
    department_id?: number;
    viewers?: number[];
}

export interface MaterialExportItems {
    id: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    created_by: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    creator_str: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    creator_img: string;
    reason: string;
    status: string;
    type: string;
    receipts: string;
    budgets: string;
    items: string;
    itemsAmount: number;
    itemsPrice: number;
    confirmers: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    to_storage: string;
    receipt: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    created_date: Date;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    updated_date: Date | null;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    deleted_date: Date | null;
}

export interface InvoicesGridItems {
    id: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    created_by: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    creator_str: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    creator_img: string;
    reason: string;
    status: string;
    type: string;
    receipts: MaterialFile[];
    budgets: Array<{
        id: number;
        creator: User;
        name: string;
        amount: number;
        expenditures: {
            queue: {items: number; price: number};
            deleted: {items: number; price: number};
            success: {items: number; price: number};
            residue: number;
        };
        // eslint-disable-next-line @typescript-eslint/naming-convention
        expenditures_current: {price: number; items: number};
        // eslint-disable-next-line @typescript-eslint/naming-convention
        start_at: string;
        // eslint-disable-next-line @typescript-eslint/naming-convention
        expire_at: string;
        department: Department;
        // eslint-disable-next-line @typescript-eslint/naming-convention
        created_date: string;
        approvers: User[];
        budgetitems: OrderItem[];
        startUnix: number;
        endUnix: number;
        confirmed: boolean;
        declined: boolean;
    }>;
    items: OrderItem[];
    itemsAmount: number;
    itemsPrice: number;
    confirmers: User[];
    // eslint-disable-next-line @typescript-eslint/naming-convention
    to_storage: string;
    receipt: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    created_date: Date;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    updated_date: Date | null;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    deleted_date: Date | null;
}

export interface OrderItemGridItems {
    id: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    created_by: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    creator_str: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    creator_img: string;
    name: string;
    amount: number;
    price: number;
    dph: boolean;
    category: string;
    status: string;
    budget: string;
    department: string;
    company: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    material_id: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    to_storage: boolean;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    created_date: Date;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    updated_date: Date | null;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    deleted_date: Date | null;
}

export interface MaterialLogsGridItems {
    id: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    type_id: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    material_id: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    payment_id: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    created_by: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    creator_str: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    creator_img: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    updated_by: number | null | undefined;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    editor_str: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    editor_img: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    deleted_by: number | null | undefined;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    remover_str: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    remover_img: string;
    reason: string;
    storno: boolean;
    note: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    to_storage: boolean;
    receipt: boolean;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    payment_type: string;
    type: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    created_date: Date;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    updated_date: Date | null;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    deleted_date: Date | null;
    timestamp: Date;
}

@Injectable({
    providedIn: 'root',
})
export class MaterialService {
    private readonly materialsUrl: string;

    private readonly budgetsUrl: string;

    private readonly materialsPaymentUrl: string;

    private readonly materialsAssignationUrl: string;

    private readonly materialsFilesUrl: string;

    private readonly materialsFileUrl: string;

    private readonly itemsUrl: string;

    private readonly materialsBudgetUrl: string;

    private readonly logsUrl: string;

    materials: Observable<Material[]>;

    material: Observable<Material>;

    budgets: Observable<MaterialBudget[]>;

    orders: Observable<OrderItem[] | undefined>;

    item: Observable<OrderItem>;

    payments: Observable<MaterialPayment[] | undefined>;

    constructor(
        private readonly http: HttpClient,
        private readonly dataService: DataService,
        private readonly messageService: MessageService,
        private readonly environmentService: EnvironmentService,
    ) {
        this.materialsUrl = this.environmentService.backendURL + '/api/material';
        this.budgetsUrl = this.environmentService.backendURL + '/api/budget';
        this.materialsPaymentUrl = this.environmentService.backendURL + '/api/material/payment';
        this.materialsAssignationUrl =
            this.environmentService.backendURL + '/api/material/membership';
        this.materialsFilesUrl = this.environmentService.backendURL + '/api/file/download/';
        this.materialsFileUrl = this.environmentService.backendURL + '/api/file';
        this.itemsUrl = this.environmentService.backendURL + '/api/budget/items';
        this.materialsBudgetUrl = this.environmentService.backendURL + '/api/budget';
        this.logsUrl = this.environmentService.backendURL + '/api/material/log';
    }

    /**
     * Handle Http operation that failed.
     * Let the app continue.
     * @param operation - name of the operation that failed
     * @param result - optional value to return as the observable result
     */
    private handleError<T>(operation = 'operation', result?: T) {
        return (error: {[key: string]: string}): Observable<T | undefined> => {
            console.error(error);
            this.log(`${operation} failed: ${error.message}`);

            return of(result);
        };
    }

    /** Log a ticketService message with the MessageService */
    private log(message: string): void {
        this.messageService.addNotification(`Material: ${message}`, false);
    }

    getBudgetItem(budgetId: number): Observable<OrderItem[] | undefined> {
        const url = `${this.itemsUrl}/${budgetId}`;

        this.orders = this.http.get<OrderItem[]>(url)
            .pipe(
                tap(() => {
                    this.log(`fetched budget items budget_id=${budgetId}`);
                }),
                shareReplay(),
                catchError(this.handleError<OrderItem[]>(`getItems budget_id=${budgetId}`)),
            );

        return this.orders;
    }

    getPayments(): Observable<MaterialPayment[] | undefined> {
        this.payments = this.http.get<MaterialPayment[]>(this.materialsPaymentUrl)
            .pipe(
                tap(() => {
                    this.log('fetched budgets');
                }),
                shareReplay(),
                catchError(this.handleError<MaterialPayment[]>('getPayments', [])),
            );

        return this.payments;
    }

    addInvoice(material: OrderInput): Observable<Material> {
        this.dataService.clearMaterialsCache();

        return this.http.post<Material>(this.materialsUrl, material, httpOptions);
    }

    deleteInvoice(material: Material | number): Observable<Material> {
        const id = typeof material === 'number' ? material : material.id;
        const url = `${this.materialsUrl}/material/${id}`;

        this.dataService.clearMaterialsCache();

        return this.http.delete<Material>(url, httpOptions);
    }

    deleteItemInvoice(order: OrderItem | number): Observable<OrderItem> {
        const id = typeof order === 'number' ? order : order.id;
        const url = `${this.materialsUrl}/${id}`;

        this.dataService.clearMaterialsCache();

        return this.http.delete<OrderItem>(url, httpOptions);
    }

    updateInvoice(
        material: Material | OrderInput,
        receipt?: boolean,
    ): Observable<Material | undefined> | undefined {
        if (!material.id) {
            console.error('ID is missing...');

            return;
        }

        const url = `${this.materialsUrl}/update/${material.id}`;
        const headers = new HttpHeaders({'Content-Type': 'application/json'});
        const params = new HttpParams().set('receipt_taken', 'true');

        this.dataService.clearMaterialsCache();

        return this.http
            .put<Material>(url, material, receipt ? {headers, params} : httpOptions)
            .pipe(
                tap(() => {
                    this.log(`updated material id=${material.id ?? 'null'}`);
                }),
                catchError(this.handleError<Material>(`material id=${material.id}`)),
            );
    }

    confirmInvoice(
        material: Material | number,
        user: User | number,
        today: string,
    ): Observable<MaterialAssignation> {
        const headers = new HttpHeaders({'Content-Type': 'application/json'});
        const params = new HttpParams().set('confirmed', 'true')
            .set('admin', 'false');
        const materialId = typeof material === 'number' ? material : material.id;
        const userId = typeof user === 'number' ? user : user.id;

        this.dataService.clearMaterialsCache();

        return this.http.put<MaterialAssignation>(
            this.materialsAssignationUrl,
            {
                material_id: materialId,
                user_id: userId,
                confirmed_date: today,
            },
            {headers, params},
        );
    }

    confirmAdminInvoice(
        material: Material | number,
        today: string,
    ): Observable<MaterialAssignation> {
        const headers = new HttpHeaders({'Content-Type': 'application/json'});
        const params = new HttpParams().set('confirmed', 'true')
            .set('admin', 'true');
        const materialId = typeof material === 'number' ? material : material.id;

        this.dataService.clearMaterialsCache();

        return this.http.put<MaterialAssignation>(
            this.materialsAssignationUrl,
            {
                material_id: materialId,
                confirmed_date: today,
            },
            {headers, params},
        );
    }

    declineInvoice(
        material: Material | number,
        user: User | number,
        today: string,
    ): Observable<MaterialAssignation> {
        const headers = new HttpHeaders({'Content-Type': 'application/json'});
        const params = new HttpParams().set('declined', 'true')
            .set('admin', 'false');
        const materialId = typeof material === 'number' ? material : material.id;
        const userId = typeof user === 'number' ? user : user.id;

        this.dataService.clearMaterialsCache();

        return this.http.put<MaterialAssignation>(
            this.materialsAssignationUrl,
            {
                material_id: materialId,
                user_id: userId,
                declined_date: today,
            },
            {headers, params},
        );
    }

    declineAdminInvoice(
        material: Material | number,
        today: string,
    ): Observable<MaterialAssignation> {
        const headers = new HttpHeaders({'Content-Type': 'application/json'});
        const params = new HttpParams().set('declined', 'true')
            .set('admin', 'true');
        const materialId = typeof material === 'number' ? material : material.id;

        this.dataService.clearMaterialsCache();

        return this.http.put<MaterialAssignation>(
            this.materialsAssignationUrl,
            {
                material_id: materialId,
                declined_date: today,
            },
            {headers, params},
        );
    }

    deleteFile(file: MaterialFile | number): Observable<MaterialFile | undefined> {
        const headers = new HttpHeaders({'Content-Type': 'application/json'});
        const params = new HttpParams().set('type', 'material');
        const id = typeof file === 'number' ? file : file.id;
        const url = `${this.materialsFileUrl}/${id}`;

        this.dataService.clearMaterialsCache();

        return this.http.delete<MaterialFile>(url, {headers, params});
    }

    downloadFile(file: MaterialFile): Observable<Blob | MaterialFile | undefined> {
        return this.http
            .post(
                `${this.materialsFilesUrl}`,
                {file: file.name, module: 'material', id: file.material_id},
                {
                    responseType: 'blob',
                    headers: new HttpHeaders().append('Content-Type', 'application/json'),
                },
            )
            .pipe(
                tap(() => {
                    this.log(`start download material file name=${file.name}`);
                }),
                catchError(this.handleError<MaterialFile>(`file name=${file.name}`)),
            );
    }

    checkBudgetAssignation(budget: MaterialBudget, user: User): boolean {
        let amIIn = false;

        if (budget.approvers.length > 0) {
            budget.approvers.forEach((data: User) => {
                if (data.id === user.id) {
                    amIIn = true;
                }
            });
        }

        return amIIn;
    }

    addBudget(budget: BudgetInput): Observable<MaterialBudget> {
        this.dataService.clearBudgetsCache();

        return this.http.post<MaterialBudget>(this.materialsBudgetUrl, budget, httpOptions);
    }

    updateBudget(
        budget: BudgetInput,
        matChanges?: number[],
    ): Observable<MaterialBudget | undefined> | undefined {
        if (!budget.id) {
            console.error('ID is missing...');

            return;
        }

        const headers = new HttpHeaders({'Content-Type': 'application/json'});
        const params = new HttpParams().set('mat_to_change', JSON.stringify(matChanges));
        const url = `${this.materialsBudgetUrl}/update/${budget.id}`;

        this.dataService.clearBudgetsCache();

        return this.http.put<MaterialBudget>(url, budget, {headers, params});
    }

    deleteBudget(budget: MaterialBudget): Observable<MaterialBudget> {
        const url = `${this.materialsBudgetUrl}/${budget.id}`;

        this.dataService.clearBudgetsCache();

        return this.http.delete<MaterialBudget>(url, httpOptions);
    }

    getLogs(material: Material | number): Observable<MaterialLogs[] | undefined> {
        const id = typeof material === 'number' ? material : material.id;
        const url = `${this.logsUrl}/${id}`;

        return this.http.get<MaterialLogs[]>(url)
            .pipe(
                tap(() => {
                    this.log('fetched material logs');
                }),
                shareReplay(), // this tells Rx to cache the latest emitted
                catchError(this.handleError<MaterialLogs[]>('getMaterialLogs', [])),
            );
    }
}
