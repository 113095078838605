import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    Output,
    ViewChild,
} from '@angular/core';
import {Tickets} from '@src/app/_models/ticket/tickets';
import {
    BeforeUploadEventArgs,
    FileInfo,
    SelectedEventArgs,
    SuccessEventArgs,
    UploaderComponent,
    UploadingEventArgs,
} from '@syncfusion/ej2-angular-inputs';
import {EmitType, isNullOrUndefined} from '@syncfusion/ej2-base';
import {AuthenticationService} from '@src/app/_services/authentication.service';
import {DataService} from '@src/app/_services/data.service';
import {MessageService} from '@src/app/_services/message.service';
import {EnvironmentService} from '@src/app/_services/environment.service';
import {PermissionService} from '@src/app/_services/permission.service';
import {Lightbox} from 'ngx-lightbox';
import {GalleryImages} from '@src/app/_models/images/images.type';
import {User} from '@src/app/_models/user/user';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {TicketFile} from '@src/app/_models/ticket/ticket-file';
import {TicketsService} from '@src/app/features/tickets/tickets.service';
import {DialogComponent} from '@syncfusion/ej2-angular-popups';

@UntilDestroy()
@Component({
    selector: 'app-ticket-files',
    templateUrl: './ticket-files.component.html',
    styleUrls: ['./ticket-files.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TicketFilesComponent {
    @Input() ticket?: Tickets | null;
    @Input() imagesBasic?: GalleryImages[] = [];
    @Output() readonly loading = new EventEmitter<boolean>();

    deleteFileId: number;
    currentUser: User | null;

    // Files
    @ViewChild('targetView') targetView?: ElementRef;
    @ViewChild('deleteFileDialog') deleteFileDialog: DialogComponent;
    // Dialogs
    buttonsDeleteFileDialog = [
        {
            click: this.removeTicketFile.bind(this),
            buttonModel: {
                content: 'OK',
                cssClass: 'e-success e-outline',
                isFlat: false,
            },
        },
        {
            click: (): void => {
                this.deleteFileDialog.hide();
            },
            buttonModel: {content: 'ZRUŠIT', cssClass: 'e-danger e-outline'},
        },
    ];

    // Upload
    @ViewChild('preloadupload') uploadObj?: UploaderComponent;
    path: {
        saveUrl: string;
        removeUrl: string;
    };
    dropElement: HTMLElement = document.getElementsByClassName('control-fluid')[0] as HTMLElement;

    constructor(
        private readonly dataService: DataService,
        private readonly authenticationService: AuthenticationService,
        private readonly messages: MessageService,
        public ticketsService: TicketsService,
        private readonly environmentService: EnvironmentService,
        public permissionService: PermissionService,
        private readonly ref: ChangeDetectorRef,
        private readonly lightbox: Lightbox,
    ) {
        this.currentUser = this.authenticationService.currentUserValue;
    }

    setLoading(loading: boolean) {
        this.loading.emit(loading);
    }

    // FILES
    open(index: number): void {
        if (!this.imagesBasic) {
            return;
        }

        this.lightbox.open(this.imagesBasic, index);
    }

    close(): void {
        this.lightbox.close();
    }

    onFileSelected(args: SelectedEventArgs): void {
        if (this.uploadObj && this.ticket) {
            this.uploadObj.asyncSettings.saveUrl = `${this.environmentService.backendURL}/api/upload/ticket?type=tickets&id=${this.ticket.id}`;
            // Filter the 5 files only to showcase
            args.filesData.splice(5);

            const filesData: FileInfo[] = this.uploadObj.getFilesData();
            const allFiles: FileInfo[] = filesData.concat(args.filesData);

            if (allFiles.length > 5) {
                // eslint-disable-next-line @typescript-eslint/prefer-for-of
                for (let i = 0; i < allFiles.length; i++) {
                    if (allFiles.length > 5) {
                        allFiles.shift();
                    }
                }

                args.filesData = allFiles;
                // set the modified custom data
                args.modifiedFilesData = args.filesData;
            }

            let existingFiles: FileInfo[] = this.uploadObj.getFilesData();

            for (let i = 0; i < args.filesData.length; i++) {
                // eslint-disable-next-line @typescript-eslint/prefer-for-of
                for (let j = 0; j < existingFiles.length; j++) {
                    if (!isNullOrUndefined(args.filesData[i])) {
                        // eslint-disable-next-line eqeqeq
                        if (existingFiles[j].name == args.filesData[i].name) {
                            args.filesData.splice(i, 1);
                        }
                    }
                }
            }

            existingFiles = existingFiles.concat(args.filesData);
            args.modifiedFilesData = existingFiles;
            args.isModified = true;
        }
    }

    beforeFileUpload(args: BeforeUploadEventArgs): void {
        if (this.ticket && this.currentUser) {
            args.customFormData = [{id: this.ticket.id}, {created_by: this.currentUser.id}];
        }
    }

    onFileUpload: EmitType<SelectedEventArgs> = (args: UploadingEventArgs) => {
        if (this.ticket && this.currentUser) {
            args.currentRequest?.setRequestHeader(
                'Authorization',
                `Bearer ${this.currentUser.token}`,
            );
            args.customFormData = [{id: this.ticket.id}, {created_by: this.currentUser.id}];
        }
    };

    onSuccessUpload(args: SuccessEventArgs): void {
        const file = args.file;

        if (file) {
            const body = `Soubor: ${file.name}`;
            const options = {progressBar: true, timeOut: 5000, toastClass: 'success'};

            this.messages.showSuccess('Soubor úspěšně nahrán', body, options);
        }

        if (!this.ticket) {
            console.error('this.ticket is missing...');

            return;
        }

        this.dataService.setTicketDetailSource(this.ticket.id);
    }

    removeTicketFile(): void {
        this.setLoading(true);

        const file = this.ticket?.files.find(x => x.id === this.deleteFileId);

        if (file) {
            file.deleted_by = this.currentUser?.id;
            this.ticketsService
                .deleteFile(file)
                ?.pipe(untilDestroyed(this))
                .subscribe(
                    (data: TicketFile) => {
                        const body = `Soubor #${data.id}`;
                        const options = {
                            progressBar: true,
                            timeOut: 5000,
                            toastClass: 'success',
                        };

                        this.messages.showSuccess('Soubor úspěšně smazán', body, options);
                        this.setLoading(false);
                        this.deleteFileDialog.hide();
                        this.ref.markForCheck();

                        if (!this.ticket) {
                            console.error('this.ticket is missing...');

                            return;
                        }

                        this.dataService.setTicketDetailSource(this.ticket.id);
                    },
                    error => {
                        console.error(error);

                        const body = 'Zkuste to znovu později ...';
                        const options = {
                            progressBar: true,
                            timeOut: 5000,
                            toastClass: 'red',
                        };

                        this.messages.showError(
                            'Chyba při mazání souboru v ticketu',
                            body,
                            options,
                        );
                        this.setLoading(false);
                        this.ref.markForCheck();
                    },
                );
        }
    }
}
