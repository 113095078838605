import {AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import {Event, Router, RouterEvent, Scroll} from '@angular/router';
import {Location} from '@angular/common';
import {AuthenticationService} from '@src/app/_services/authentication.service';
import {TitleService} from '@src/app/_services/title.service';
import {MessageService, NotificationMessage} from '@src/app/_services/message.service';
import {VersionCheckService} from '@src/app/_services/version-check.service';
import {ApisService} from '@src/app/_api/apis.service';
import {PluginsService} from '@src/app/_services/plugins.service';
import {Observable} from 'rxjs';
import {ApiConnections} from '@src/app/_models/api/connections';
import {DataService} from '@src/app/_services/data.service';
import {PermissionService} from '@src/app/_services/permission.service';
import {AutologoutService} from '@src/app/_services/autologout.service';
import {NodeClickEventArgs, SidebarComponent, TreeViewComponent} from '@syncfusion/ej2-angular-navigations';
import {Plugins} from '@src/app/_models/plugins/plugins';
import {strings as czechStrings} from 'ngx-timeago/language-strings/cs';
import {TimeagoIntl} from 'ngx-timeago';
import {enableRipple, L10n, loadCldr, setCulture, setCurrencyCode} from '@syncfusion/ej2-base';
import {NavHandlerService} from '@src/app/_services/nav-handler.service';
import {User} from '@src/app/_models/user/user';

import numbers from 'cldr-data/main/cs/numbers.json';
import gregorian from 'cldr-data/main/cs/ca-gregorian.json';
import numberingSystems from 'cldr-data/supplemental/numberingSystems.json';
import timeZoneNames from 'cldr-data/main/cs/timeZoneNames.json';
import weekData from 'cldr-data/supplemental/weekData.json';
import units from 'cldr-data/main/cs/units.json';
import layout from 'cldr-data/main/cs/layout.json';
import measurementSystemNames from 'cldr-data/main/cs/measurementSystemNames.json';
import {DialogComponent} from '@syncfusion/ej2-angular-popups';
import {AcsService} from '@src/app/_api/acs/acs.service';
import {CrmService} from '@src/app/_api/crm/crm.service';
import {ErpService} from '@src/app/_api/erp/erp.service';
import {MonitoringService} from '@src/app/_api/monitoring/monitoring.service';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {MenuEventArgs} from '@syncfusion/ej2-splitbuttons';
import {DropDownButtonComponent, ItemModel} from '@syncfusion/ej2-angular-splitbuttons';
import {TextBoxComponent} from '@syncfusion/ej2-angular-inputs';
import {CountdownComponent} from 'ngx-countdown';
import {map, shareReplay, tap} from 'rxjs/operators';
import {MonitorUsers} from '@src/app/_models/_api_monitor/users';
import {CrmUsers} from '@src/app/_models/_api_crm/users';
import {ErpUsers} from '@src/app/_models/_api_erp/users';
import csLocale from '@syncfusion/ej2-locale/src/cs.json';
import {Hotliner} from '@src/app/_models/hotline/hotliner';
import {ScriptService} from '@src/app/_services/script.service';
import {EnvironmentService} from '@src/app/_services/environment.service';
import {Settings} from 'luxon';
import {NotificationService} from '@src/app/features/notifications/notification.service';
import {ToastComponent} from '@syncfusion/ej2-angular-notifications';
import {LogoutService} from '@src/app/_services/logout.service';
import {BreakpointObserver} from '@angular/cdk/layout';
import {AcsUsers} from './_models/_api_acs/users';

declare let smartform;

loadCldr(
    numbers,
    gregorian,
    numberingSystems,
    timeZoneNames,
    weekData,
    units,
    layout,
    measurementSystemNames,
);
enableRipple(true);
L10n.load(csLocale);

setCulture('cs');
setCurrencyCode('CZK');
Settings.defaultLocale = 'cz';
Settings.defaultNumberingSystem = 'latn';
Settings.defaultZone = 'Europe/Prague';

interface NodeChild {
    nodeId: string;
    nodeText: string;
    iconCss: string;
    url: string;
    acl: boolean;
}

interface NavData {
    nodeId: string;
    nodeText: string;
    iconCss: string;
    acl: boolean;
    url?: string;
    badge?: string;
    message?: number | string;
    nodeChild?: Array<NodeChild>;
}

@UntilDestroy()
@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit, AfterViewInit {
    // Notifications
    position = {X: 'Right', Y: 'Top'};
    notification?: NotificationMessage;
    // Variables
    name = 'JON System';
    currentUser: User | null;
    specialPage: boolean;
    isLogedIn = false;
    currentUrl = '';
    width = '250px';
    cssClass = 'accordiontree';
    mediaQuery = '(min-width: 600px)';
    target = '.main-content';
    closeOnDocumentClick = true;
    data: NavData[] = [];
    field = {
        dataSource: this.data,
        id: 'nodeId',
        text: 'nodeText',
        child: 'nodeChild',
        iconCss: 'iconCss',
    };
    plugins: Plugins[] = [];
    newTicketCnt = 0;
    newTaskCnt = 0;
    newVacationCnt = 0;
    newMaterialCnt = 0;
    newKnowledgelCnt = 0;
    newServiceCnt = 0;
    newHotlineCnt = 0;
    live: true;
    plugins$: Observable<Plugins[]>;
    apis$: Observable<ApiConnections[]>;
    items: ItemModel[] = [
        {
            text: 'Profil',
        },
        {
            text: 'Odhlásit',
        },
    ];
    renderSearchResultDialog = false;
    searchQuery = '';
    autoLogoutDialogState = false;
    // Menus
    @ViewChild('userMenuIcon') userMenuIcon: DropDownButtonComponent | undefined;
    @ViewChild('sidebarTreeviewInstance')
    sidebarTreeviewInstance: SidebarComponent | undefined;
    @ViewChild('treeMenuInstance') treeMenuInstance: TreeViewComponent | undefined;
    // Dialogs
    @ViewChild('notifDialog') notifDialog: DialogComponent | undefined;
    @ViewChild('autoLogoutDialog') autoLogoutDialog: DialogComponent | undefined;
    // Search
    @ViewChild('fulltextSearch') fullTextSearchObj: TextBoxComponent | undefined;
    @ViewChild('notifElement') notifElement: ToastComponent;
    mobile$?: Observable<boolean>;
    timeoutId: NodeJS.Timeout | number | string | undefined;
    private readonly specialPages: string[] = ['/login', '/'];
    @ViewChild('cd', {static: true}) private readonly countdown: CountdownComponent;

    constructor(
        public router: Router,
        public notificationService: NotificationService,
        public messageService: MessageService,
        protected autologoutService: AutologoutService,
        private readonly location: Location,
        private readonly authenticationService: AuthenticationService,
        private readonly navHandlerService: NavHandlerService,
        private readonly permissionsService: PermissionService,
        private readonly pluginsService: PluginsService,
        private readonly scriptService: ScriptService,
        private readonly apisService: ApisService,
        private readonly acsService: AcsService,
        private readonly crmService: CrmService,
        private readonly erpService: ErpService,
        private readonly monitoringService: MonitoringService,
        private readonly dataService: DataService,
        private readonly intl: TimeagoIntl,
        private readonly permissionService: PermissionService,
        private readonly titleService: TitleService,
        private readonly versionCheckService: VersionCheckService,
        private readonly logoutService: LogoutService,
        private readonly environmentService: EnvironmentService,
        private readonly changeDetector: ChangeDetectorRef,
        private readonly breakpoints: BreakpointObserver,
    ) {
        this.name = this.autologoutService.val ?? 'JON System';
        this.intl.strings = czechStrings;
        this.intl.changes.next();
        this.plugins$ = this.pluginsService.allowedPlugins.pipe(shareReplay());
        this.apis$ = this.apisService.allowedApis.pipe(shareReplay());
        this.mobile$ = this.breakpoints.observe(['(max-width: 576px)'])
            .pipe(map(({matches}) => matches));
    }

    onCreatedSideBar(): void {
        this.mobile$?.pipe(
            tap((isMobile) => {
                if (this.sidebarTreeviewInstance) {
                    this.sidebarTreeviewInstance.isOpen = !isMobile;
                }
            }),
        );

        if (!this.currentUser) {
            this.sidebarTreeviewInstance?.destroy();
        }
    }

    onClick(e: any): void {
        e.clickToClose = true;
    }

    onBeforeOpen(e: any): void {
        const progress = e.element.querySelector('.e-toast-progress');

        progress.style.height = '5px';
        progress.style.backgroundColor = 'White';
    }

    ngOnInit(): void {
        this.titleService.init();

        this.router.events.pipe(untilDestroyed(this))
            .subscribe((route: Event) => {
                if (route instanceof Scroll) {
                    this.currentUrl = route.routerEvent.url;
                } else if (route instanceof RouterEvent) {
                    this.currentUrl = route.url;
                }

                this.specialPage = this.specialPages.includes(this.currentUrl);
            });

        void this.scriptService.load('smartForm')
            .then(() => {
                smartform = smartform || {};

                smartform.beforeInit = (): void => {
                    smartform.setClientId('hTusAujHGR');
                };
            });

        this.messageService.notifs$.subscribe((notification: NotificationMessage | null) => {
            if (notification) {
                const notifObj = {
                    title: notification.title,
                    content: notification.body,
                    cssClass:
                        notification.type === 'error'
                            ? 'e-toast-danger'
                            : notification.type === 'warning'
                                ? 'e-toast-warning'
                                : notification.type === 'info'
                                    ? 'e-toast-info'
                                    : notification.type === 'success'
                                        ? 'e-toast-success'
                                        : 'e-toast-primary',
                };

                this.notification = notification;
                this.notifElement.show(notifObj);
            }
        });

        this.authenticationService.currentUser$
            .pipe(untilDestroyed(this))
            .subscribe((user: User | null | undefined) => {
                if (user?.id) {
                    this.currentUser = user;

                    const leader = this.permissionsService.checkUserISLeader(this.currentUser);

                    this.isLogedIn = true;
                    this.currentUser.is_leader = leader.leaderStatus;
                    this.currentUser.is_leader_departments = leader.leaderDepartments;
                    this.dataService.getHotlinersData()
                        .subscribe((hotliners: Hotliner[]) => {
                            if (this.currentUser) {
                                this.currentUser.is_hotliner =
                                    this.permissionsService.checkUserISHotliner(hotliners);
                            }
                        });
                    this.currentUser.ascApiUser_data = null;
                    this.currentUser.mntApiUser_data = null;
                    this.currentUser.crmApiUser_data = null;
                    this.dataService.userSource.pipe(untilDestroyed(this))
                        .subscribe({
                            next: (data: User[] | undefined) => {
                                if (data && this.currentUser) {
                                    const boss = this.permissionsService.checkUserISBoss(
                                        this.currentUser,
                                        data,
                                    );

                                    this.currentUser.is_boss = boss.bossStatus;
                                    this.currentUser.is_boss_users = boss.bossUsers;
                                }
                            },
                            error: error => {
                                console.error(error);
                            },
                        });

                    this.acsService.acsUsersSource.pipe(untilDestroyed(this))
                        .subscribe({
                            next: (data: unknown[] | null | undefined) => {
                                if (this.currentUser) {
                                    if (data) {
                                        if (Array.isArray(data) && data.length > 0) {
                                            this.currentUser.ascApiUser_data = data.find(
                                                (x: AcsUsers) => x.email === this.currentUser?.workemail,
                                            ) as AcsUsers;
                                        } else if (
                                            Array.isArray(Object.values(data)) &&
                                            Object.values(data).length > 0
                                        ) {
                                            const tempArray: unknown[] = Object.values(data);

                                            this.currentUser.ascApiUser_data = tempArray.find(
                                                (x: AcsUsers) => x.email === this.currentUser?.workemail,
                                            ) as AcsUsers;
                                        } else {
                                            this.currentUser.ascApiUser_data = null;
                                        }
                                    } else {
                                        this.currentUser.ascApiUser_data = null;
                                    }
                                }
                            },
                            error: error => {
                                console.error(error);
                            },
                        });

                    this.monitoringService.siteMonitorUsersSource
                        .pipe(untilDestroyed(this))
                        .subscribe({
                            next: (data: unknown[] | null | undefined) => {
                                if (this.currentUser) {
                                    if (data) {
                                        if (Array.isArray(data) && data.length > 0) {
                                            this.currentUser.mntApiUser_data = data.find(
                                                (x: MonitorUsers) =>
                                                    x.email === this.currentUser?.workemail,
                                            ) as MonitorUsers;

                                            console.info(
                                                'INFO: set CURRENT USER MONITORING data ... done.',
                                            );
                                        } else if (
                                            Array.isArray(Object.values(data)) &&
                                            Object.values(data).length > 0
                                        ) {
                                            const tempArray: unknown[] = Object.values(data);

                                            this.currentUser.mntApiUser_data = tempArray.find(
                                                (x: MonitorUsers) =>
                                                    x.email === this.currentUser?.workemail,
                                            ) as MonitorUsers;

                                            console.info(
                                                'INFO: set CURRENT USER MONITORING data ... done.',
                                            );
                                        } else {
                                            this.currentUser.mntApiUser_data = null;
                                            console.info(
                                                'INFO: set CURRENT USER MONITORING data ... empty.',
                                            );
                                        }
                                    } else {
                                        this.currentUser.mntApiUser_data = null;
                                        console.info(
                                            'INFO: set CURRENT USER MONITORING data ... empty.',
                                        );
                                    }
                                }
                            },
                            error: error => {
                                console.error(error);
                            },
                        });

                    this.crmService.crmUsersSource.pipe(untilDestroyed(this))
                        .subscribe({
                            next: (data: unknown[] | null | undefined) => {
                                if (this.currentUser) {
                                    if (data) {
                                        if (Array.isArray(data) && data.length > 0) {
                                            this.currentUser.crmApiUser_data = data.find(
                                                (x: CrmUsers) => x.email === this.currentUser?.workemail,
                                            ) as CrmUsers;
                                            console.info('INFO: set CURRENT USER CRM data ... done.');
                                        } else if (
                                            Array.isArray(Object.values(data)) &&
                                            Object.values(data).length > 0
                                        ) {
                                            const tempArray: unknown[] = Object.values(data);

                                            this.currentUser.crmApiUser_data = tempArray.find(
                                                (x: CrmUsers) => x.email === this.currentUser?.workemail,
                                            ) as CrmUsers;
                                            console.info('INFO: set CURRENT USER CRM data ... done.');
                                        } else {
                                            this.currentUser.crmApiUser_data = null;
                                            console.info('INFO: set CURRENT USER CRM data ... empty.');
                                        }
                                    } else {
                                        this.currentUser.crmApiUser_data = null;
                                        console.info('INFO: set CURRENT USER CRM data ... empty.');
                                    }
                                }
                            },
                            error: error => {
                                console.error(error);
                            },
                        });

                    this.erpService.erpUsersSource.pipe(untilDestroyed(this))
                        .subscribe({
                            next: (data: unknown[] | null | undefined) => {
                                if (this.currentUser) {
                                    if (data) {
                                        if (Array.isArray(data) && data.length > 0) {
                                            this.currentUser.erpApiUser_data = data.find(
                                                (x: ErpUsers) => x.email === this.currentUser?.workemail,
                                            ) as ErpUsers;
                                            console.info('INFO: set CURRENT USER ERP data ... empty.');
                                        } else if (
                                            Array.isArray(Object.values(data)) &&
                                            Object.values(data).length > 0
                                        ) {
                                            const tempArray: unknown[] = Object.values(data);

                                            this.currentUser.erpApiUser_data = tempArray.find(
                                                (x: ErpUsers) => x.email === this.currentUser?.workemail,
                                            ) as ErpUsers;
                                            console.info('INFO: set CURRENT USER ERP data ... empty.');
                                        } else {
                                            this.currentUser.erpApiUser_data = null;
                                            console.info('INFO: set CURRENT USER ERP data ... empty.');
                                        }
                                    } else {
                                        this.currentUser.erpApiUser_data = null;
                                        console.info('INFO: set CURRENT USER CRM data ... empty.');
                                    }
                                }
                            },
                            error: error => {
                                console.error(error);
                            },
                        });

                    this.dataService.setNewNotificationsDataSource(this.currentUser);
                    this.dataService.setApiDataSource();
                    this.dataService.setUsersDataSource();
                    this.pluginsService.setConnections();

                    this.timeoutId = setInterval(() => {
                        if (this.currentUser) {
                            if (this.environmentService.versionURL) {
                                this.versionCheckService.checkVersion(
                                    this.environmentService.versionURL as string,
                                );
                            }

                            this.dataService.setNewNotificationsDataSource(this.currentUser);
                            this.autologoutService.isOpen
                                .pipe(untilDestroyed(this))
                                .subscribe((isOpen: boolean) => {
                                    if (isOpen) {
                                        this.autoLogoutDialog?.show();
                                        this.autoLogoutDialogState = true;
                                    } else {
                                        this.autoLogoutDialog?.hide();
                                        this.autoLogoutDialogState = false;
                                    }
                                });
                        }
                    }, 120000);

                    this.changeDetector.markForCheck();
                } else {
                    this.isLogedIn = false;
                    this.currentUser = null;
                    this.changeDetector.markForCheck();
                    clearInterval(this.timeoutId);
                }
            });
    }

    // TODO copy this approach for ALL EJ-DIALOGS
    ngAfterViewInit(): void {
        document.onclick = (args: any): void => {
            const currentTarget = args.target.closest('ejs-dialog, button, .nav-link');

            if (this.notifDialog?.visible && currentTarget === null) {
                this.notifDialog.hide();
            }
        };
    }

    // eslint-disable-next-line complexity
    onCreateMenu(): void {
        // SELECT AND EXPAND ITEM MENU AFTER NEW RELOAD BASED ON ANGULAR ROUTE
        if (this.currentUser) {
            if (typeof this.treeMenuInstance !== 'undefined') {
                if (this.location.isCurrentPathEqualTo('/')) {
                    this.treeMenuInstance.selectedNodes = ['01'];
                }

                if (this.location.isCurrentPathEqualTo('/events/')) {
                    this.treeMenuInstance.selectedNodes = ['02'];
                }

                if (this.location.isCurrentPathEqualTo('/tickets/')) {
                    this.treeMenuInstance.selectedNodes = ['03'];
                }

                if (this.location.isCurrentPathEqualTo('/tasks/')) {
                    this.treeMenuInstance.selectedNodes = ['04'];
                }

                if (this.location.isCurrentPathEqualTo('/hotline/')) {
                    this.treeMenuInstance.selectedNodes = ['05'];
                }

                if (this.location.isCurrentPathEqualTo('/vacation/')) {
                    this.treeMenuInstance.selectedNodes = ['06'];
                }

                if (this.location.isCurrentPathEqualTo('/material/')) {
                    this.treeMenuInstance.selectedNodes = ['07'];
                }

                if (this.location.isCurrentPathEqualTo('/users/')) {
                    this.treeMenuInstance.selectedNodes = ['08'];
                }

                if (this.location.isCurrentPathEqualTo('/knowledge/')) {
                    this.treeMenuInstance.selectedNodes = ['09'];
                }

                if (this.location.isCurrentPathEqualTo('/services/')) {
                    this.treeMenuInstance.selectedNodes = ['10-02'];
                    this.treeMenuInstance.expandedNodes = ['10'];
                }

                if (this.location.isCurrentPathEqualTo('/services/searcher')) {
                    this.treeMenuInstance.selectedNodes = ['10-01'];
                    this.treeMenuInstance.expandedNodes = ['10'];
                }

                if (this.location.isCurrentPathEqualTo('/services/hardware')) {
                    this.treeMenuInstance.selectedNodes = ['10-03'];
                    this.treeMenuInstance.expandedNodes = ['10'];
                }

                if (this.location.isCurrentPathEqualTo('/services/address')) {
                    this.treeMenuInstance.selectedNodes = ['10-04'];
                    this.treeMenuInstance.expandedNodes = ['10'];
                }

                if (this.location.isCurrentPathEqualTo('/reports/')) {
                    this.treeMenuInstance.selectedNodes = ['11'];
                    this.treeMenuInstance.expandedNodes = ['11'];
                }

                if (this.location.isCurrentPathEqualTo('/reports/vacations')) {
                    this.treeMenuInstance.selectedNodes = ['11-01'];
                    this.treeMenuInstance.expandedNodes = ['11'];
                }

                if (this.location.isCurrentPathEqualTo('/reports/warehouse')) {
                    this.treeMenuInstance.selectedNodes = ['11-02'];
                    this.treeMenuInstance.expandedNodes = ['11'];
                }

                if (this.location.isCurrentPathEqualTo('/reports/crm')) {
                    this.treeMenuInstance.selectedNodes = ['11-03'];
                    this.treeMenuInstance.expandedNodes = ['11'];
                }

                if (this.location.isCurrentPathEqualTo('/reports/invoices')) {
                    this.treeMenuInstance.selectedNodes = ['11-04'];
                    this.treeMenuInstance.expandedNodes = ['11'];
                }

                if (this.location.isCurrentPathEqualTo('/reports/services')) {
                    this.treeMenuInstance.selectedNodes = ['11-05'];
                    this.treeMenuInstance.expandedNodes = ['11'];
                }

                if (this.location.isCurrentPathEqualTo('/reports/hotline')) {
                    this.treeMenuInstance.selectedNodes = ['11-06'];
                    this.treeMenuInstance.expandedNodes = ['11'];
                }

                if (this.location.isCurrentPathEqualTo('/reports/work')) {
                    this.treeMenuInstance.selectedNodes = ['11-07'];
                    this.treeMenuInstance.expandedNodes = ['11'];
                }

                if (this.location.isCurrentPathEqualTo('/reports/netbase')) {
                    this.treeMenuInstance.selectedNodes = ['11-06'];
                    this.treeMenuInstance.expandedNodes = ['11'];
                }

                if (this.location.isCurrentPathEqualTo('/reports/advins')) {
                    this.treeMenuInstance.selectedNodes = ['11-06'];
                    this.treeMenuInstance.expandedNodes = ['11'];
                }

                if (this.location.isCurrentPathEqualTo('/booking/')) {
                    this.treeMenuInstance.selectedNodes = ['12'];
                    this.treeMenuInstance.expandedNodes = ['12'];
                }

                if (this.location.isCurrentPathEqualTo('/booking/cars')) {
                    this.treeMenuInstance.selectedNodes = ['12-01'];
                    this.treeMenuInstance.expandedNodes = ['12'];
                }

                if (this.location.isCurrentPathEqualTo('/booking/office')) {
                    this.treeMenuInstance.selectedNodes = ['12-02'];
                    this.treeMenuInstance.expandedNodes = ['12'];
                }

                if (this.location.isCurrentPathEqualTo('/settings/')) {
                    this.treeMenuInstance.selectedNodes = ['13'];
                }

                // APPLY ACL TO THE DEF DATA SOURCE FOR MENU
                this.data = [
                    {
                        nodeId: '01',
                        nodeText: 'Nástěnka',
                        iconCss: 'fa-light fa-tachometer-alt',
                        url: '/',
                        acl: true,
                    },
                    /* {
                        nodeId: '02',
                        nodeText: 'Rozvrh',
                        iconCss: 'fa-light fa-calendar-week',
                        badge: 'e-badge e-badge-secondary float-right',
                        message: '3 New',
                        url: '/events/',
                        acl: true,
                    },*/
                    {
                        nodeId: '03',
                        nodeText: 'Tickety',
                        iconCss: 'fa-light fa-ticket-alt',
                        badge: 'e-badge e-badge-secondary float-right',
                        url: '/tickets/',
                        message: this.newTicketCnt,
                        acl: true,
                    },
                    {
                        nodeId: '04',
                        nodeText: 'Úkoly',
                        iconCss: 'fa-light fa-ballot-check',
                        badge: 'e-badge e-badge-secondary float-right',
                        url: '/tasks/',
                        message: this.newTaskCnt,
                        acl: true,
                    },
                    {
                        nodeId: '05',
                        nodeText: 'Hotline',
                        iconCss: 'fa-light fa-headset',
                        badge: 'e-badge e-badge-secondary float-right',
                        message: this.newHotlineCnt,
                        url: '/hotline/',
                        acl: true,
                    },
                    {
                        nodeId: '06',
                        nodeText: 'Dovolená',
                        iconCss: 'fa-light fa-calendar-alt',
                        badge: 'e-badge e-badge-secondary float-right',
                        url: '/vacation/',
                        message: this.newVacationCnt,
                        acl: true,
                    },
                    {
                        nodeId: '07',
                        nodeText: 'Materiál',
                        iconCss: 'fa-light fa-box',
                        badge: 'e-badge e-badge-secondary float-right',
                        url: '/material/',
                        message: this.newMaterialCnt,
                        acl: true,
                    },
                    {
                        nodeId: '08',
                        nodeText: 'Uživatelé',
                        iconCss: 'fa-light fa-id-card-alt',
                        url: '/users/',
                        acl: true,
                    },
                    {
                        nodeId: '09',
                        nodeText: 'Pracovní postupy',
                        badge: 'e-badge e-badge-secondary float-right',
                        message: this.newKnowledgelCnt,
                        iconCss: 'fa-light fa-books',
                        url: '/knowledge/list',
                        acl: true,
                    },
                    /* {
                        nodeId: '10',
                        nodeText: 'Služby',
                        iconCss: 'fa-light fa-box-usd',
                        badge: 'e-badge e-badge-secondary float-right',
                        message: this.newServiceCnt,
                        nodeChild: [
                            {
                                nodeId: '10-01',
                                nodeText: 'Vyhledávač služeb',
                                iconCss: 'fa-light fa-angle-right',
                                url: '/services/searcher',
                                acl: true,
                            },
                            {
                                nodeId: '10-02',
                                nodeText: 'Nastavení služeb',
                                iconCss: 'fa-light fa-angle-right',
                                url: '/services/',
                                acl: this.permissionService.checkUserISServiceAdmin(
                                    this.currentUser
                                ),
                            },
                            {
                                nodeId: '10-03',
                                nodeText: 'Hardware služeb',
                                iconCss: 'fa-light fa-angle-right',
                                url: '/services/hardware',
                                acl: this.permissionService.checkUserISServiceAdmin(
                                    this.currentUser
                                ),
                            },
                            {
                                nodeId: '10-04',
                                nodeText: 'Adresy služeb',
                                iconCss: 'fa-light fa-angle-right',
                                url: '/services/address',
                                acl: this.permissionService.checkUserISServiceAdmin(
                                    this.currentUser
                                ),
                            },
                        ],
                        acl: true,
                    },
                    {
                        nodeId: '11',
                        nodeText: 'Reporty',
                        iconCss: 'fa-light fa-file-export',
                        nodeChild: [
                            {
                                nodeId: '11-01',
                                nodeText: 'Absence zaměstnanců',
                                iconCss: 'fa-light fa-angle-right',
                                url: '/reports/vacations',
                                acl:
                                    this.permissionService.checkUserISAdministrative(
                                        this.currentUser
                                    ) ||
                                    this.permissionService.checkUserISVacationAdmin(
                                        this.currentUser
                                    ),
                            },
                            /!* {
                                nodeId: '11-02',
                                nodeText: 'Sklad',
                                iconCss: 'fa-light fa-angle-right',
                                url: '/reports/warehouse',
                                acl: true,
                            },*!/
                            {
                                nodeId: '11-03',
                                nodeText: 'Zákazníci',
                                iconCss: 'fa-light fa-angle-right',
                                url: '/reports/crm',
                                acl: this.permissionService.checkUserISAdministrative(
                                    this.currentUser
                                ),
                            },
                            {
                                nodeId: '11-04',
                                nodeText: 'Fakturace',
                                iconCss: 'fa-light fa-angle-right',
                                url: '/reports/invoices',
                                acl: this.permissionService.checkUserISBudgetAdmin(
                                    this.currentUser
                                ),
                            },
                            {
                                nodeId: '11-05',
                                nodeText: 'Služby',
                                iconCss: 'fa-light fa-angle-right',
                                url: '/reports/services',
                                acl: this.permissionService.checkUserISAdministrative(
                                    this.currentUser
                                ),
                            },
                            {
                                nodeId: '11-06',
                                nodeText: 'Hotline',
                                iconCss: 'fa-light fa-angle-right',
                                url: '/reports/hotline',
                                acl: this.permissionService.checkUserISHotlineAdmin(
                                    this.currentUser
                                ),
                            },
                            {
                                nodeId: '11-07',
                                nodeText: 'Výkazy práce',
                                iconCss: 'fa-light fa-angle-right',
                                url: '/reports/work',
                                acl: this.permissionService.checkUserISAdministrative(
                                    this.currentUser
                                ),
                            },
                            {
                                nodeId: '11-08',
                                nodeText: 'Report NetBase',
                                iconCss: 'fa-light fa-angle-right',
                                url: '/reports/netbase',
                                acl: this.permissionService.checkUserISServiceAdmin(
                                    this.currentUser
                                ),
                            },
                            {
                                nodeId: '11-09',
                                nodeText: 'Report Advins',
                                iconCss: 'fa-light fa-angle-right',
                                url: '/reports/advins',
                                acl: this.permissionService.checkUserISServiceAdmin(
                                    this.currentUser
                                ),
                            },
                        ],
                        acl: true,
                    },
                    {
                        nodeId: '12',
                        nodeText: 'Rezervace',
                        iconCss: 'fa-light fa-calendar-clock',
                        url: '/booking/',
                        acl: true,
                    },*/
                    {
                        nodeId: '13',
                        nodeText: 'Nastavení',
                        iconCss: 'fa-light fa-cog',
                        url: '/settings/',
                        acl: this.permissionService.checkUserISAdministrative(this.currentUser),
                    },
                ];

                this.data = this.data.filter((x: NavData) => {
                    if (x.nodeChild && x.nodeChild.length > 0) {
                        x.nodeChild = x.nodeChild.filter(y => y.acl);
                    }

                    return x.acl;
                });

                this.treeMenuInstance.fields.dataSource = this.data as unknown as {
                    [key: string]: object;
                }[];
            }
        }
    }

    getUserIcon(): void {
        if (this.userMenuIcon && this.currentUser) {
            // eslint-disable-next-line max-len
            this.userMenuIcon.content = `<img alt="profilový obrázek" class="img-fluid rounded-circle z-depth-0 mr-1" src="assets/img${this.currentUser.personalphoto}" style="height: 30px">${this.currentUser.fullname}`;
        }
    }

    loadRoutingContent(args: NodeClickEventArgs): void {
        if (typeof this.treeMenuInstance !== 'undefined') {
            if (args.node.classList.contains('e-level-1')) {
                this.treeMenuInstance.collapseAll();
                this.treeMenuInstance.expandAll([args.node]);
                this.treeMenuInstance.expandOn = 'None';
            }

            const data: {[key: string]: object | string}[] = this.treeMenuInstance.getTreeData(
                args.node,
            );

            if (typeof data[0].url !== 'undefined') {
                const routerLink: string = data[0].url as string;

                void this.router.navigate([routerLink]);
            }
        }
    }

    openClick(): void {
        this.sidebarTreeviewInstance?.toggle();

        if (this.sidebarTreeviewInstance?.isOpen) {
            this.navHandlerService.publish('open');
        } else {
            this.navHandlerService.publish('close');
        }
    }

    /* closeDocumentClick(): void {
        if (this.sidebarTreeviewInstance?.isOpen) {
            this.sidebarTreeviewInstance.toggle();
            this.navHandlerService.publish('open');
        }
        else {
            this.navHandlerService.publish('close');
        }
    }*/

    selectUserMenu(args: MenuEventArgs): void {
        if (args.item.text === 'Profil') {
            void this.router.navigate(['/users/detail', this.currentUser?.id]);
        }

        if (args.item.text === 'Odhlásit') {
            this.logout();
        }
    }

    startSearch(): void {
        if (this.fullTextSearchObj && this.fullTextSearchObj.value.length >= 3) {
            this.renderSearchResultDialog = true;
            this.searchQuery = this.fullTextSearchObj.value;
        }
    }

    goBack(): void {
        this.location.back();
    }

    logout(): void {
        this.isLogedIn = false;
        clearInterval(this.timeoutId);
        this.treeMenuInstance?.destroy();
        this.sidebarTreeviewInstance?.hide();
        this.logoutService.logout();
    }

    trackPluginsID(index: number, plugins: Plugins): number {
        return plugins.id;
    }

    trackApisID(index: number, apis: ApiConnections): number {
        return apis.id;
    }
}
