<div
  *ngIf="tableLoad"
  style="
        display: block !important;
        z-index: 1000000;
        width: 250px;
        height: 250px;
        margin: auto;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    "
>
  <div class="loader">
    <span></span>
    <span></span>
    <span></span>
    <span></span>
  </div>
  <div
    class="lds-circle"
    style="
            margin: auto;
            position: absolute;
            width: 150px;
            height: auto;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        "
  >
    <div></div>
  </div>
</div>
<div class="row d-flex justify-content-center modalWrapper">
  <ejs-dialog
    #confirmationDeleteUnit
    [buttons]="buttonsDeleteUnit"
    [content]="'Opravdu chcete smazat označený typ bytové jednotky?'"
    [header]="'Smazat TYP BYTOVÉ JENDOTKY'"
    [isModal]="true"
    [showCloseIcon]="true"
    [visible]="false"
    width="250px"
  ></ejs-dialog>
  <ejs-dialog
    #confirmationDeleteSpace
    [buttons]="buttonsDeleteSpace"
    [content]="'Opravdu chcete smazat označený prostor bytové jednotky?'"
    [header]="'Smazat VÝMĚRU BYTOVÉ JENDOTKY'"
    [isModal]="true"
    [showCloseIcon]="true"
    [visible]="false"
    width="250px"
  ></ejs-dialog>
  <ejs-dialog
    #confirmationCancel
    [buttons]="buttonsCancel"
    [header]="'Nelze provést akci'"
    [isModal]="true"
    [showCloseIcon]="true"
    [visible]="false"
    width="250px"
  ></ejs-dialog>
  <app-flat-unit-type-form
    (flatUnitFormDialogState)="
            renderUnitFormDialog = $event;
            isCreateUnit = $event;
            isUpdateUnit = $event;
            isCopyUnit = $event
        "
    [flatUnit]="
            clickedUnitTypesRow &&
            clickedUnitTypesRow[0] &&
            (isUpdateUnit || isCopyUnit)
                ? clickedUnitTypesRow[0]
                : null
        "
    [isCopy]="isCopyUnit"
    [isCreate]="isCreateUnit"
    [isUpdate]="isUpdateUnit"
    [isVisible]="renderUnitFormDialog"
  ></app-flat-unit-type-form>
  <app-flat-unit-space-form
    (flatSpaceFormDialogState)="
            renderSpaceFormDialog = $event;
            isCreateSpace = $event;
            isUpdateSpace = $event;
            isCopySpace = $event
        "
    [flatSpace]="
            clickedSpaceTypesRow &&
            clickedSpaceTypesRow[0] &&
            (isUpdateSpace || isCopySpace)
                ? clickedSpaceTypesRow[0]
                : null
        "
    [isCopy]="isCopySpace"
    [isCreate]="isCreateSpace"
    [isUpdate]="isUpdateSpace"
    [isVisible]="renderSpaceFormDialog"
  ></app-flat-unit-space-form>
</div>
<div class="row">
  <div class="col-12 mt-2">
    <h5>Typy bytových jednotek</h5>
    <hr />
    <ejs-toolbar
      id="filterNetToolbar"
      overflowMode="Scrollable"
      scrollStep="500"
    >
      <e-items>
        <e-item
          [cssClass]="'statusFilter'"
          tooltipText="Přednastavené hledání"
          type="input"
        >
          <ng-template #template>
            <ejs-dropdownlist
              #viewFilterUnit
              (change)="onStatusFilterChangeUnit($event)"
              [dataSource]="categoryFilterUnitData"
              [width]="500"
              placeholder="Vyberte přednastavený pohled..."
            ></ejs-dropdownlist>
          </ng-template>
        </e-item>
        <e-item type="Separator"></e-item>
        <e-item
          [cssClass]="'fulltextFilter'"
          tooltipText="Text pro vyhledávání"
          type="input"
        >
          <ng-template #template>
            <ejs-textbox
              #searchtextUnit
              [width]="500"
              class="mr-2"
              placeholder="Vyhledat..."
            ></ejs-textbox>
            <button
              (click)="searchUnit()"
              class="e-btn e-info btn-p-zero e-small"
            >
              <i class="fa-light fa-lg fa-search mr-1"></i
              ><span class="d-none d-lg-inline">vyhledat</span>
            </button>
            <button
              (click)="cancelBtnUnitClick()"
              class="e-btn e-danger btn-p-zero e-small"
            >
              <i class="fa-light fa-lg fa-times"></i>
            </button>
          </ng-template>
        </e-item>
      </e-items>
    </ejs-toolbar>
    <div class="control-section">
      <div class="scroll_wrapper1" id="scroll_wrapper1_unit">
        <div class="scroll_div" id="scroll_div_unit"></div>
      </div>
      <div class="scroll_wrapper2" id="scroll_wrapper2_unit">
        <div class="grid_parent" id="grid_parent_unit">
          <ejs-grid
            #unitsGrid
            (actionBegin)="actionBeginUnit($event)"
            (actionComplete)="actionComplete($event)"
            (created)="onCreatedUnit()"
            (dataBound)="onDataBoundUnit()"
            (load)="onLoadUnit()"
            (loadeddata)="setInitialGridFiltering()"
            (rowDataBound)="rowDataBoundUnit($event)"
            (rowDeselected)="rowSelectedUnit()"
            (rowSelected)="rowSelectedUnit()"
            (toolbarClick)="toolbarClickUnit($event)"
            [allowExcelExport]="true"
            [allowFiltering]="true"
            [allowMultiSorting]="true"
            [allowPdfExport]="false"
            [allowSelection]="true"
            [allowSorting]="true"
            [dataSource]="dataUnit"
            [editSettings]="editSettings"
            [enableHover]="false"
            [enablePersistence]="false"
            [enableVirtualization]="true"
            [filterSettings]="filterSettings"
            [groupSettings]="groupOptions"
            [pageSettings]="pageOptions"
            [selectionSettings]="selectionSettings"
            [sortSettings]="sortOptionsUnit"
            [toolbar]="toolbarUnit"
            allowGrouping="true"
            allowReordering="true"
            allowResizing="true"
            allowTextWrap="true"
            height="100%"
            id="unitsGrid"
            locale="cs"
            rowHeight="38"
            showColumnMenu="true"
            width="100%"
          >
            <e-columns>
              <e-column
                [allowEditing]="false"
                [allowFiltering]="false"
                [allowReordering]="false"
                [allowResizing]="false"
                [allowSorting]="false"
                [autoFit]="true"
                type="checkbox"
              ></e-column>
              <e-column
                [allowFiltering]="false"
                [allowReordering]="true"
                [allowResizing]="false"
                [autoFit]="true"
                [isPrimaryKey]="true"
                [textAlign]="'left'"
                [visible]="false"
                field="id"
                headerText="ID"
              >
                <ng-template #template let-data>
                  {{ data.id }}
                </ng-template>
              </e-column>
              <e-column
                [allowEditing]="false"
                [allowFiltering]="true"
                [allowReordering]="false"
                [allowResizing]="false"
                [autoFit]="true"
                [textAlign]="'left'"
                [visible]="false"
                field="created_by"
                headerText="Creator ID"
              ></e-column>
              <e-column
                [autoFit]="true"
                [filter]="filterSettings"
                [textAlign]="'left'"
                clipMode="EllipsisWithTooltip"
                field="name"
                headerText="Název"
              >
              </e-column>
              <e-column
                [autoFit]="true"
                [filter]="filterSettings"
                [textAlign]="'left'"
                clipMode="EllipsisWithTooltip"
                field="creator_str"
                headerText="Vytvořil"
              >
                <ng-template #template let-data>
                  <div>
                    <img
                      *ngIf="data.creator_img"
                      alt="profilový obrázek"
                      class="img-fluid rounded-circle avatar-xs mr-1"
                      src="assets/img{{
                                                data.creator_img
                                            }}"
                    />
                    <span>{{ data.creator_str }}</span>
                  </div>
                </ng-template>
              </e-column>
              <e-column
                [allowEditing]="false"
                [autoFit]="true"
                [filter]="filterDate"
                [format]="dateFormat"
                [textAlign]="'right'"
                clipMode="EllipsisWithTooltip"
                editType="datepickeredit"
                field="created_date"
                headerText="Vytvořeno"
                type="date"
              ></e-column>
              <e-column
                [allowEditing]="false"
                [autoFit]="true"
                [filter]="filterDate"
                [format]="dateFormat"
                [textAlign]="'right'"
                clipMode="EllipsisWithTooltip"
                editType="datepickeredit"
                field="updated_date"
                headerText="Naposledy upraveno"
                type="date"
              ></e-column>
              <e-column
                [allowEditing]="false"
                [autoFit]="true"
                [filter]="filterDate"
                [format]="dateFormat"
                [textAlign]="'right'"
                clipMode="EllipsisWithTooltip"
                editType="datepickeredit"
                field="deleted_date"
                headerText="Smazáno"
                type="date"
              ></e-column>
            </e-columns>
            <e-aggregates>
              <e-aggregate>
                <e-columns>
                  <e-column
                    [format]="'N'"
                    field="id"
                    type="Count"
                  >
                    <ng-template #footerTemplate let-data>
                      Celkem řádků:
                      {{ data.Count }}
                    </ng-template
                    >
                  </e-column>
                </e-columns>
              </e-aggregate>
            </e-aggregates>
          </ejs-grid>
        </div>
      </div>
    </div>
  </div>
  <div class="col-12 mt-2">
    <h5>Výměry bytových jednotek</h5>
    <hr />
    <ejs-toolbar
      id="filterTvToolbar"
      overflowMode="Scrollable"
      scrollStep="500"
    >
      <e-items>
        <e-item
          [cssClass]="'statusFilter'"
          tooltipText="Přednastavené hledání"
          type="input"
        >
          <ng-template #template>
            <ejs-dropdownlist
              #viewFilterSpace
              (change)="onStatusFilterChangeSpace($event)"
              [dataSource]="categoryFilterSpaceData"
              [width]="500"
              placeholder="Vyberte přednastavený pohled..."
            ></ejs-dropdownlist>
          </ng-template>
        </e-item>
        <e-item type="Separator"></e-item>
        <e-item
          [cssClass]="'fulltextFilter'"
          tooltipText="Text pro vyhledávání"
          type="input"
        >
          <ng-template #template>
            <ejs-textbox
              #searchtextSpace
              [width]="500"
              class="mr-2"
              placeholder="Vyhledat..."
            ></ejs-textbox>
            <button
              (click)="searchSpace()"
              class="e-btn e-info btn-p-zero e-small"
            >
              <i class="fa-light fa-lg fa-search mr-1"></i
              ><span class="d-none d-lg-inline">vyhledat</span>
            </button>
            <button
              (click)="cancelBtnSpaceClick()"
              class="e-btn e-danger btn-p-zero e-small"
            >
              <i class="fa-light fa-lg fa-times"></i>
            </button>
          </ng-template>
        </e-item>
      </e-items>
    </ejs-toolbar>
    <div class="control-section">
      <div class="scroll_wrapper1" id="scroll_wrapper1_space">
        <div class="scroll_div" id="scroll_div_space"></div>
      </div>
      <div class="scroll_wrapper2" id="scroll_wrapper2_space">
        <div class="grid_parent" id="grid_parent_space">
          <ejs-grid
            #spacesGrid
            (actionBegin)="actionBeginSpace($event)"
            (actionComplete)="actionComplete($event)"
            (created)="onCreatedSpace()"
            (dataBound)="onDataBoundSpace()"
            (load)="onLoadSpace()"
            (loadeddata)="setInitialGridFiltering()"
            (rowDataBound)="rowDataBoundSpace($event)"
            (rowDeselected)="rowSelectedSpace()"
            (rowSelected)="rowSelectedSpace()"
            (toolbarClick)="toolbarClickSpace($event)"
            [allowExcelExport]="true"
            [allowFiltering]="true"
            [allowMultiSorting]="true"
            [allowPdfExport]="false"
            [allowSelection]="true"
            [allowSorting]="true"
            [dataSource]="dataSpace"
            [editSettings]="editSettings"
            [enableHover]="false"
            [enablePersistence]="false"
            [enableVirtualization]="true"
            [filterSettings]="filterSettings"
            [groupSettings]="groupOptions"
            [pageSettings]="pageOptions"
            [selectionSettings]="selectionSettings"
            [sortSettings]="sortOptionsSpace"
            [toolbar]="toolbarSpace"
            allowGrouping="true"
            allowReordering="true"
            allowResizing="true"
            allowTextWrap="true"
            height="100%"
            id="spacesGrid"
            locale="cs"
            rowHeight="38"
            showColumnMenu="true"
            width="100%"
          >
            <e-columns>
              <e-column
                [allowEditing]="false"
                [allowFiltering]="false"
                [allowReordering]="false"
                [allowResizing]="false"
                [allowSorting]="false"
                [autoFit]="true"
                type="checkbox"
              ></e-column>
              <e-column
                [allowFiltering]="false"
                [allowReordering]="true"
                [allowResizing]="false"
                [autoFit]="true"
                [isPrimaryKey]="true"
                [textAlign]="'left'"
                [visible]="false"
                field="id"
                headerText="ID"
              >
                <ng-template #template let-data>
                  {{ data.id }}
                </ng-template>
              </e-column>
              <e-column
                [allowEditing]="false"
                [allowFiltering]="true"
                [allowReordering]="false"
                [allowResizing]="false"
                [autoFit]="true"
                [textAlign]="'left'"
                [visible]="false"
                field="created_by"
                headerText="Creator ID"
              ></e-column>
              <e-column
                [autoFit]="true"
                [filter]="filterSettings"
                [format]="'N'"
                [textAlign]="'right'"
                clipMode="EllipsisWithTooltip"
                field="metric_until"
                headerText="OD (m2)"
              >
              </e-column>
              <e-column
                [autoFit]="true"
                [filter]="filterSettings"
                [format]="'N'"
                [textAlign]="'right'"
                clipMode="EllipsisWithTooltip"
                field="metric_to"
                headerText="DO (m2)"
              >
              </e-column>
              <e-column
                [autoFit]="true"
                [filter]="filterSettings"
                [textAlign]="'left'"
                clipMode="EllipsisWithTooltip"
                field="creator_str"
                headerText="Vytvořil"
              >
                <ng-template #template let-data>
                  <div>
                    <img
                      *ngIf="data.creator_img"
                      alt="profilový obrázek"
                      class="img-fluid rounded-circle avatar-xs mr-1"
                      src="assets/img{{
                                                data.creator_img
                                            }}"
                    />
                    <span>{{ data.creator_str }}</span>
                  </div>
                </ng-template>
              </e-column>
              <e-column
                [allowEditing]="false"
                [autoFit]="true"
                [filter]="filterDate"
                [format]="dateFormat"
                [textAlign]="'right'"
                clipMode="EllipsisWithTooltip"
                editType="datepickeredit"
                field="created_date"
                headerText="Vytvořeno"
                type="date"
              ></e-column>
              <e-column
                [allowEditing]="false"
                [autoFit]="true"
                [filter]="filterDate"
                [format]="dateFormat"
                [textAlign]="'right'"
                clipMode="EllipsisWithTooltip"
                editType="datepickeredit"
                field="updated_date"
                headerText="Naposledy upraveno"
                type="date"
              ></e-column>
              <e-column
                [allowEditing]="false"
                [autoFit]="true"
                [filter]="filterDate"
                [format]="dateFormat"
                [textAlign]="'right'"
                clipMode="EllipsisWithTooltip"
                editType="datepickeredit"
                field="deleted_date"
                headerText="Smazáno"
                type="date"
              ></e-column>
            </e-columns>
            <e-aggregates>
              <e-aggregate>
                <e-columns>
                  <e-column
                    [format]="'N'"
                    field="id"
                    type="Count"
                  >
                    <ng-template #footerTemplate let-data>
                      Celkem řádků:
                      {{ data.Count }}
                    </ng-template
                    >
                  </e-column>
                </e-columns>
              </e-aggregate>
            </e-aggregates>
          </ejs-grid>
        </div>
      </div>
    </div>
  </div>
</div>
