import {
    AfterViewChecked,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnInit,
    ViewChild,
} from '@angular/core';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {FullCalendarComponent} from '@fullcalendar/angular';
import moment from 'moment-business-days';
import {CalendarOptions, EventInput} from '@fullcalendar/core';
import {DataService} from '@src/app/_services/data.service';
import {BookingService} from '@src/app/features/booking/services/booking.service';
import csLocale from '@fullcalendar/core/locales/cs';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import timeGrid from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import multiMonthPlugin from '@fullcalendar/multimonth';
import googleCalendarPlugin from '@fullcalendar/google-calendar';
import {Booking} from '@src/app/_models/booking/book.model';
import {User} from '@src/app/_models/user/user';
import {Department} from '@src/app/_models/department/department';
import {ActivatedRoute, Router} from '@angular/router';
import {MessageService} from '@src/app/_services/message.service';
import {ButtonPropsModel, DialogComponent} from '@syncfusion/ej2-angular-popups';
import {BehaviorSubject} from 'rxjs';

interface BookReservations {
    bookData: Booking;
    title: string;
    type: string;
    start: string;
    end: string;
    backgroundColor: string;
    borderColor: string;
    textColor: string;
    allDay: boolean;
    timeFormat: string;
}

@UntilDestroy()
@Component({
    selector: 'app-booking-calendar',
    templateUrl: './booking-calendar.component.html',
    styleUrls: ['./booking-calendar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BookingCalendarComponent implements OnInit, AfterViewChecked {
    // Variables
    today = moment()
        .format('YYYY-MM-DD');

    currentUser: User | null;

    users: User[];

    departments: Department[];

    book: Booking;

    reservations: unknown[] = [];

    bookingDisplay: Booking[] = [];

    calendarEvents: Array<unknown> = [];

    bookReservations: Array<BookReservations> = [];

    // FullCalendar
    calendarOptions: CalendarOptions = {
        googleCalendarApiKey: 'AIzaSyCCLvaMHyCqnbJC9DsQTvXGQ8lgS4D_hzo',
        initialView: 'dayGridMonth',
        firstDay: 1,
        locale: csLocale,
        selectable: true,
        headerToolbar: {
            start: 'title',
            center: 'dayGridMonth,timeGridWeek,listWeek',
            end: 'today prev,next',
        },
        buttonText: {
            today: 'dnes',
            month: 'měsíc',
            week: 'týden',
            day: 'den',
            list: 'seznam',
        },
        allDayText: 'celý den',
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        dateClick: this.clickedBooking.bind(this),
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        eventClick: this.navigateToBooking.bind(this),
        eventSources: [],
        eventTimeFormat: {
            hour: '2-digit',
            minute: '2-digit',
            meridiem: false,
        },
        timeZone: 'local',
        weekends: true,
        plugins: [
            dayGridPlugin,
            interactionPlugin,
            timeGrid,
            listPlugin,
            googleCalendarPlugin,
            multiMonthPlugin,
        ],
    };

    // Loaders
    loadingCalendar = true;

    loadingBookings: BehaviorSubject<boolean | null>;

    clickedRow: Booking;

    // Dialogs
    renderBookFormDialog = false;

    buttonsDeleteBookingDialog: ButtonPropsModel[] = [
        {
            click: (): void => {
                this.deleteBooking();
            },
            isFlat: false,
            buttonModel: {
                content: 'OK',
                cssClass: 'e-success e-outline',
            },
        },
        {
            click: (): void => {
                this.deleteBookingDialog.hide();
            },
            buttonModel: {content: 'ZRUŠIT', cssClass: 'e-danger e-outline'},
        },
    ];

    // Forms
    isCreate = false;

    isUpdate = false;

    isCopy = false;

    // FullCalendar
    @ViewChild('bookingCalendar') calendarComponent: FullCalendarComponent;

    // Dialogs
    @ViewChild('deleteBookingDialog') deleteBookingDialog: DialogComponent;

    constructor(
        private readonly router: Router,
        private readonly route: ActivatedRoute,
        private readonly dataService: DataService,
        private readonly bookingService: BookingService,
        private readonly messages: MessageService,
        private readonly ref: ChangeDetectorRef,
    ) {
        this.dataService.setBookingsDataSource();
        this.loadingBookings = this.dataService.loadingBookingSource;
    }

    private loadCalendar(): void {
        this.bookReservations = [];

        if (this.reservations.length > 0) {
            this.reservations.map((book: Booking) => {
                this.bookReservations.push({
                    bookData: book,
                    title: book.office_id ? 'Rezervace pracovního prostoru' : 'Rezervace vozidel',
                    type: 'event',
                    start: moment(book.start)
                        .format('YYYY-MM-DD HH:mm:ss'),
                    end: moment(book.end)
                        .format('YYYY-MM-DD HH:mm:ss'),
                    backgroundColor: book.office_id ? '#33af2a' : '#ff4040',
                    borderColor: book.office_id ? '#33af2a' : '#ff4040',
                    textColor: '#ffffff',
                    allDay: false,
                    timeFormat: 'H:mm',
                });
            });
        }

        this.calendarOptions.eventSources = [
            this.calendarEvents.concat(this.bookReservations) as EventInput[],
        ];

        this.loadingCalendar = false;
        this.ref.markForCheck();
    }

    ngOnInit(): void {
        this.dataService.bookingsSource.pipe(untilDestroyed(this))
            .subscribe(
                (bookings: Booking[]) => {
                    this.bookReservations = [];
                    this.reservations = [];
                    bookings.map((event: Booking) => {
                        if (!event.deleted_date) {
                            this.reservations.push(event);
                        }
                    });

                    this.reservations = this.reservations.sort((a: Booking, b: Booking) => {
                        const dateA = moment(a.start)
                            .valueOf();
                        const dateB = moment(b.end)
                            .valueOf();

                        return dateA > dateB ? -1 : 1;
                    });
                    this.loadReservations();
                    this.loadCalendar();
                    this.ref.markForCheck();
                },
                error => {
                    console.error(error);
                    this.loadingBookings.next(false);
                    this.ref.markForCheck();
                },
            );

        this.dataService.setTicketCategoriesSource();
        this.dataService.setDepartmentDataSource();
        this.dataService.setTagsDataSource();
        this.dataService.setCarsDataSource();
        this.dataService.setOfficesDataSource();
    }

    ngAfterViewChecked(): void {
        this.route.queryParamMap.pipe(untilDestroyed(this))
            .subscribe(params => {
                if (params.get('form') === 'addBooking') {
                    console.info('get book form');
                    this.isCreate = true;
                    this.isUpdate = false;
                    this.isCopy = false;
                    this.renderBookFormDialog = true;
                    this.ref.markForCheck();
                }
            });
    }

    loadReservations(): void {
        this.bookingDisplay = [];
        this.bookingDisplay = this.reservations.filter((booking: Booking) => {
            const dateStart = moment(booking.start)
                .format('YYYY-MM-DD');
            const dateEnd = moment(booking.end)
                .format('YYYY-MM-DD');

            return this.today >= dateStart && this.today <= dateEnd;
        }) as Booking[];
        this.ref.markForCheck();
    }

    navigateToBooking(arg): void {
        if (arg) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            if (arg.event.extendedProps.book_data) {
                void this.router.navigate([
                    '/booking/detail',
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                    arg.event.extendedProps.event_data.id,
                ]);
            }
        } else {
            const body = 'Data odkazu nejsou k dispozici...';
            const options = {progressBar: true, timeOut: 5000, toastClass: 'red'};

            this.messages.showError('Odkaz nelze otevřít', body, options);
        }
    }

    clickedBooking(event: any): void {
        this.today = moment(event.dateStr)
            .format('YYYY-MM-DD');
        this.loadReservations();
    }

    clickRow(booking: Booking): void {
        this.clickedRow = booking;
        this.renderBookFormDialog = true;
        this.ref.markForCheck();
    }

    deleteBooking(): void {
        this.loadingBookings.next(true);
        this.clickedRow.deleted_by = this.currentUser?.id;
    }

    redirectToEvent(idEvent: number): void {
        void this.router.navigate(['/events/detail', idEvent]);
    }

    redirectToTicket(idTicket: number): void {
        void this.router.navigate(['/tickets/detail', idTicket]);
    }

    redirectToCar(idCar: number): void {
        void this.router.navigate(['/cars/detail', idCar]);
    }
}
