<div
  *ngIf="tableBudgetsLoad"
  style="
        display: block !important;
        z-index: 1000000;
        width: 250px;
        height: 250px;
        margin: auto;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    "
>
  <div class="loader">
    <span></span>
    <span></span>
    <span></span>
    <span></span>
  </div>
  <div
    class="lds-circle"
    style="
            margin: auto;
            position: absolute;
            width: 150px;
            height: auto;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        "
  >
    <div></div>
  </div>
</div>
<div class="row d-flex justify-content-center modalWrapper">
  <ejs-dialog
    #confirmationDelete
    [buttons]="buttonsDelete"
    [content]="'Opravdu chcete deaktivovat aktivní rozpočet?'"
    [header]="'Deaktivovat rozpočet'"
    [height]="'200px'"
    [isModal]="true"
    [showCloseIcon]="true"
    [visible]="false"
    [width]="'450px'"
  ></ejs-dialog>
  <ejs-dialog
    #confirmationCancel
    [buttons]="buttonsCancel"
    [header]="'Nelze provést akci'"
    [isModal]="true"
    [showCloseIcon]="true"
    [visible]="false"
    width="250px"
  ></ejs-dialog>
  <app-budget-items
    (budgetItemsFormDialogState)="renderBudgetItemsFormDialog = $event"
    [budget]="clickedRow && clickedRow[0] ? clickedRow[0] : null"
    [isVisible]="renderBudgetItemsFormDialog"
  ></app-budget-items>
  <app-budget-form
    (budgetFormDialogState)="
            renderBudgetFormDialog = $event;
            isCreate = $event;
            isUpdate = $event;
            isCopy = $event;
            gridBudgets?.clearRowSelection();
            disableToolBarBtn()
        "
    [budget]="
            clickedRow && clickedRow[0] && (isUpdate || isCopy)
                ? clickedRow[0]
                : null
        "
    [isCopy]="isCopy"
    [isCreate]="isCreate"
    [isUpdate]="isUpdate"
    [isVisible]="renderBudgetFormDialog"
  ></app-budget-form>
</div>
<ejs-chart
  #budgetChart
  (tooltipRender)="tooltipRender($event)"
  *ngIf="chartData && chartData.length > 0"
  [primaryXAxis]="primaryXAxis"
  [primaryYAxis]="primaryYAxis"
  [title]="title"
  [tooltip]="tooltip"
  class="w-100 justify-content-center"
  id="chart-container"
>
  <e-series-collection>
    <e-series
      [dataSource]="chartData"
      name="Fronta"
      type="StackingColumn"
      xName="x"
      yName="y"
    ></e-series>
    <e-series
      [dataSource]="chartData"
      name="Schváleno"
      type="StackingColumn"
      xName="x"
      yName="y1"
    ></e-series>
    <e-series
      [dataSource]="chartData"
      name="Zamítnuto / Stornováno"
      type="StackingColumn"
      xName="x"
      yName="y2"
    ></e-series>
    <e-series
      [dataSource]="chartData"
      name="Zbytek"
      type="StackingColumn"
      xName="x"
      yName="y3"
    ></e-series>
    <e-series
      [dataSource]="chartData"
      [marker]="marker"
      name="Nastavený limit"
      opacity="0.6"
      type="Line"
      xName="x"
      yName="y4"
    ></e-series>
  </e-series-collection>
</ejs-chart>
<ejs-toolbar id="filterToolbar" overflowMode="Scrollable" scrollStep="500">
  <e-items>
    <e-item
      [cssClass]="'statusFilter'"
      tooltipText="Přednastavené hledání"
      type="input"
    >
      <ng-template #template>
        <ejs-dropdownlist
          #viewFilter
          (change)="onStatusFilterChange($event)"
          [dataSource]="categoryFilterData"
          [width]="500"
          placeholder="Vyberte přednastavený pohled..."
        ></ejs-dropdownlist>
      </ng-template>
    </e-item>
    <e-item type="Separator"></e-item>
    <e-item
      [cssClass]="'fulltextFilter'"
      tooltipText="Text pro vyhledávání"
      type="input"
    >
      <ng-template #template>
        <ejs-textbox
          #searchtext
          [width]="500"
          class="mr-2"
          placeholder="Vyhledat..."
        ></ejs-textbox>
        <button
          (click)="search()"
          class="e-btn e-info btn-p-zero e-small"
        >
          <i class="fa-light fa-lg fa-search mr-1"></i
          ><span class="d-none d-lg-inline">vyhledat</span>
        </button>
        <button
          (click)="cancelBtnClick()"
          class="e-btn e-danger btn-p-zero e-small"
        >
          <i class="fa-light fa-lg fa-times"></i>
        </button>
      </ng-template>
    </e-item>
  </e-items>
</ejs-toolbar>
<div class="control-section">
  <div class="scroll_wrapper1" id="scroll_wrapper1_budgets">
    <div class="scroll_div" id="scroll_div_budgets"></div>
  </div>
  <div class="scroll_wrapper2" id="scroll_wrapper2_budgets">
    <div class="grid_parent" id="grid_parent_budgets">
      <ejs-grid
        #budgetsGrid
        (actionBegin)="actionBegin($event)"
        (actionComplete)="actionComplete($event)"
        (created)="onCreated()"
        (dataBound)="onDataBound()"
        (load)="onLoad()"
        (loadeddata)="setInitialGridFiltering()"
        (rowDataBound)="rowDataBound($event)"
        (rowDeselected)="rowSelected()"
        (rowSelected)="rowSelected()"
        (toolbarClick)="toolbarClick($event)"
        [allowExcelExport]="true"
        [allowFiltering]="true"
        [allowMultiSorting]="true"
        [allowPdfExport]="false"
        [allowSelection]="true"
        [allowSorting]="true"
        [dataSource]="data"
        [editSettings]="editSettings"
        [enableHover]="false"
        [enablePersistence]="false"
        [enableVirtualization]="true"
        [filterSettings]="filterSettings"
        [groupSettings]="groupOptions"
        [pageSettings]="pageOptions"
        [selectionSettings]="selectionSettings"
        [toolbar]="toolbar"
        allowGrouping="true"
        allowReordering="true"
        allowResizing="true"
        allowTextWrap="true"
        height="100%"
        id="budgetsGrid"
        locale="cs"
        rowHeight="38"
        showColumnMenu="true"
        width="100%"
      >
        <e-columns>
          <e-column
            [allowEditing]="false"
            [allowFiltering]="false"
            [allowReordering]="false"
            [allowResizing]="false"
            [allowSorting]="false"
            [autoFit]="true"
            type="checkbox"
          ></e-column>
          <e-column
            [allowFiltering]="false"
            [allowReordering]="true"
            [allowResizing]="false"
            [autoFit]="true"
            [isPrimaryKey]="true"
            [visible]="true"
            field="id"
            headerText="ID"
          >
          </e-column>
          <e-column
            [allowEditing]="false"
            [allowFiltering]="true"
            [allowReordering]="false"
            [allowResizing]="false"
            [autoFit]="true"
            [visible]="false"
            field="created_by"
            headerText="Creator ID"
          ></e-column>
          <e-column
            [allowEditing]="false"
            [allowFiltering]="true"
            [allowReordering]="false"
            [allowResizing]="false"
            [autoFit]="true"
            [visible]="false"
            field="company_id"
            headerText="Company ID"
          ></e-column>
          <e-column
            [allowEditing]="false"
            [allowFiltering]="true"
            [allowReordering]="false"
            [allowResizing]="false"
            [autoFit]="true"
            [visible]="false"
            field="department_id"
            headerText="Department ID"
          ></e-column>
          <e-column
            [autoFit]="true"
            [filter]="filterSettings"
            clipMode="EllipsisWithTooltip"
            field="status"
            headerText="Stav rozpočtu"
            textAlign="center"
          >
            <ng-template #template let-data>
                            <span
                              *ngIf="data.status === 'smazán'"
                              class="e-badge e-badge-danger"
                            >smazaný</span
                            >
              <span
                *ngIf="data.status === 'expiroval'"
                class="e-badge e-badge-warning"
              >expiroval</span
              >
              <span
                *ngIf="data.status === 'aktivní'"
                class="e-badge e-badge-success"
              >aktivní</span
              >
            </ng-template>
          </e-column>
          <e-column
            [autoFit]="true"
            [filter]="filterSettings"
            clipMode="EllipsisWithTooltip"
            field="name"
            headerText="Název"
          ></e-column>
          <e-column
            [autoFit]="true"
            [edit]="{ params: { decimals: 2, step: 0.01 } }"
            [filter]="filterSettings"
            [format]="'#,###.00\',- CZK\''"
            clipMode="EllipsisWithTooltip"
            editType="numericedit"
            field="amount"
            headerText="Částka"
            textAlign="Right"
          >
          </e-column>
          <e-column
            [autoFit]="true"
            [filter]="filterSettings"
            clipMode="EllipsisWithTooltip"
            field="department"
            headerText="Oddělení"
          ></e-column>
          <e-column
            [autoFit]="true"
            [filter]="filterSettings"
            clipMode="EllipsisWithTooltip"
            field="company"
            headerText="Společnost"
          ></e-column>
          <e-column
            [autoFit]="true"
            [filter]="filterSettings"
            clipMode="EllipsisWithTooltip"
            field="approvers"
            headerText="Správci"
          >
            <ng-template #template let-data>
              <div [innerHTML]="data.approvers"></div>
            </ng-template>
          </e-column>
          <e-column
            [autoFit]="true"
            [filter]="filterSettings"
            clipMode="EllipsisWithTooltip"
            field="viewers"
            headerText="Diváci"
          >
            <ng-template #template let-data>
              <div [innerHTML]="data.viewers"></div>
            </ng-template>
          </e-column>
          <e-column
            [columns]="expendituresColumnsPrice"
            headerText="Výdaje v korunách"
          ></e-column>
          <e-column
            [columns]="expendituresColumnsItems"
            headerText="Výdaje v položkách"
          ></e-column>
          <e-column
            [autoFit]="true"
            [filter]="filterSettings"
            clipMode="EllipsisWithTooltip"
            field="creator_str"
            headerText="Vytvořil"
          >
            <ng-template #template let-data>
              <div>
                <img
                  *ngIf="data.creator_img"
                  alt="profilový obrázek"
                  class="img-fluid rounded-circle avatar-xs mr-1"
                  src="assets/img{{ data.creator_img }}"
                />
                <span>{{ data.creator_str }}</span>
              </div>
            </ng-template>
          </e-column>
          <e-column
            [allowEditing]="false"
            [autoFit]="true"
            [filter]="filterDate"
            [format]="dateFormat"
            clipMode="EllipsisWithTooltip"
            editType="datepickeredit"
            field="start_at"
            headerText="Začátek"
            textAlign="Right"
            type="date"
          ></e-column>
          <e-column
            [allowEditing]="false"
            [autoFit]="true"
            [filter]="filterDate"
            [format]="dateFormat"
            clipMode="EllipsisWithTooltip"
            editType="datepickeredit"
            field="expire_at"
            headerText="Konec"
            textAlign="Right"
            type="date"
          ></e-column>
          <e-column
            [allowEditing]="false"
            [autoFit]="true"
            [filter]="filterDate"
            [format]="dateFormat"
            clipMode="EllipsisWithTooltip"
            editType="datepickeredit"
            field="created_date"
            headerText="Vytvořeno"
            textAlign="Right"
            type="date"
          ></e-column>
          <e-column
            [allowEditing]="false"
            [autoFit]="true"
            [filter]="filterDate"
            [format]="dateFormat"
            clipMode="EllipsisWithTooltip"
            editType="datepickeredit"
            field="updated_date"
            headerText="Naposledy upraveno"
            textAlign="Right"
            type="date"
          ></e-column>
          <e-column
            [allowEditing]="false"
            [autoFit]="true"
            [filter]="filterDate"
            [format]="dateFormat"
            clipMode="EllipsisWithTooltip"
            editType="datepickeredit"
            field="deleted_date"
            headerText="Smazáno"
            textAlign="Right"
            type="date"
          ></e-column>
        </e-columns>
        <e-aggregates>
          <e-aggregate>
            <e-columns>
              <e-column [format]="'N'" field="id" type="Count">
                <ng-template #footerTemplate let-data>
                  Celkem řádků: {{ data.Count }}
                </ng-template
                >
              </e-column>
              <e-column
                [format]="'#,###.00\',- CZK\''"
                field="amount"
                type="Sum"
              >
                <ng-template #footerTemplate let-data>
                  Celková částka: {{ data.Sum }}
                </ng-template>
              </e-column>
              <e-column
                [format]="'#,###.00\',- CZK\''"
                field="expendituresQueuePrice"
                type="Sum"
              >
                <ng-template #footerTemplate let-data>
                  Celkem výdajů ce frontě: {{ data.Sum }}
                </ng-template>
              </e-column>
              <e-column
                [format]="'#,###.00\',- CZK\''"
                field="expendituresDeletedPrice"
                type="Sum"
              >
                <ng-template #footerTemplate let-data>
                  Celkem smazaných výdajů: {{ data.Sum }}
                </ng-template>
              </e-column>
              <e-column
                [format]="'#,###.00\',- CZK\''"
                field="expendituresSuccessPrice"
                type="Sum"
              >
                <ng-template #footerTemplate let-data>
                  Celkem schválených výdajů: {{ data.Sum }}
                </ng-template>
              </e-column>
              <e-column
                [format]="'#,###.00\',- CZK\''"
                field="expendituresResiduePrice"
                type="Sum"
              >
                <ng-template #footerTemplate let-data>
                  Celkem zbylých prostředků: {{ data.Sum }}
                </ng-template>
              </e-column>
              <e-column
                [format]="'N'"
                field="expendituresQueueItems"
                type="Sum"
              >
                <ng-template #footerTemplate let-data>
                  Celkem položek ve frontě: {{ data.Sum }}
                </ng-template>
              </e-column>
              <e-column
                [format]="'N'"
                field="expendituresDeletedItems"
                type="Sum"
              >
                <ng-template #footerTemplate let-data>
                  Celkem smazaných položek: {{ data.Sum }}
                </ng-template>
              </e-column>
              <e-column
                [format]="'N'"
                field="expendituresSuccessItems"
                type="Sum"
              >
                <ng-template #footerTemplate let-data>
                  Celkem schválených položek: {{ data.Sum }}
                </ng-template>
              </e-column>
              <e-column
                [format]="'N'"
                field="expendituresTotalItems"
                type="Sum"
              >
                <ng-template #footerTemplate let-data>
                  Celkem položek celkem: {{ data.Sum }}
                </ng-template>
              </e-column>
            </e-columns>
          </e-aggregate>
          <e-aggregate>
            <e-columns>
              <e-column
                [format]="'#,###.00\',- CZK\''"
                field="amount"
                type="Average"
              >
                <ng-template #footerTemplate let-data>
                  Průměrná částka: {{ data.Average }}
                </ng-template>
              </e-column>
              <e-column
                [format]="'#,###.00\',- CZK\''"
                field="expendituresQueuePrice"
                type="Average"
              >
                <ng-template #footerTemplate let-data>
                  Průměr výdajů ce frontě: {{ data.Average }}
                </ng-template>
              </e-column>
              <e-column
                [format]="'#,###.00\',- CZK\''"
                field="expendituresDeletedPrice"
                type="Average"
              >
                <ng-template #footerTemplate let-data>
                  Průměr smazaných výdajů: {{ data.Average }}
                </ng-template>
              </e-column>
              <e-column
                [format]="'#,###.00\',- CZK\''"
                field="expendituresSuccessPrice"
                type="Average"
              >
                <ng-template #footerTemplate let-data>
                  Průměr schválených výdajů:
                  {{ data.Average }}
                </ng-template>
              </e-column>
              <e-column
                [format]="'#,###.00\',- CZK\''"
                field="expendituresResiduePrice"
                type="Average"
              >
                <ng-template #footerTemplate let-data>
                  Průměr zbylých prostředků:
                  {{ data.Average }}
                </ng-template>
              </e-column>
              <e-column
                [format]="'N'"
                field="expendituresQueueItems"
                type="Average"
              >
                <ng-template #footerTemplate let-data>
                  Průměr položek ve frontě: {{ data.Average }}
                </ng-template>
              </e-column>
              <e-column
                [format]="'N'"
                field="expendituresDeletedItems"
                type="Average"
              >
                <ng-template #footerTemplate let-data>
                  Průměr smazaných položek: {{ data.Average }}
                </ng-template>
              </e-column>
              <e-column
                [format]="'N'"
                field="expendituresSuccessItems"
                type="Average"
              >
                <ng-template #footerTemplate let-data>
                  Průměr schválených položek:
                  {{ data.Average }}
                </ng-template>
              </e-column>
              <e-column
                [format]="'N'"
                field="expendituresTotalItems"
                type="Average"
              >
                <ng-template #footerTemplate let-data>
                  Průměr položek celkem: {{ data.Average }}
                </ng-template>
              </e-column>
            </e-columns>
          </e-aggregate>
          <e-aggregate>
            <e-columns>
              <e-column
                [format]="'#,###.00\',- CZK\''"
                field="amount"
                type="Max"
              >
                <ng-template #footerTemplate let-data>
                  Maximální částka: {{ data.Max }}
                </ng-template>
              </e-column>
              <e-column
                [format]="'#,###.00\',- CZK\''"
                field="expendituresQueuePrice"
                type="Max"
              >
                <ng-template #footerTemplate let-data>
                  Max výdajů ce frontě: {{ data.Max }}
                </ng-template>
              </e-column>
              <e-column
                [format]="'#,###.00\',- CZK\''"
                field="expendituresDeletedPrice"
                type="Max"
              >
                <ng-template #footerTemplate let-data>
                  Max smazaných výdajů: {{ data.Max }}
                </ng-template>
              </e-column>
              <e-column
                [format]="'#,###.00\',- CZK\''"
                field="expendituresSuccessPrice"
                type="Max"
              >
                <ng-template #footerTemplate let-data>
                  Max schválených výdajů: {{ data.Max }}
                </ng-template>
              </e-column>
              <e-column
                [format]="'#,###.00\',- CZK\''"
                field="expendituresResiduePrice"
                type="Max"
              >
                <ng-template #footerTemplate let-data>
                  Max zbylých prostředků: {{ data.Max }}
                </ng-template>
              </e-column>
              <e-column
                [format]="'N'"
                field="expendituresQueueItems"
                type="Max"
              >
                <ng-template #footerTemplate let-data>
                  Max položek ve frontě: {{ data.Max }}
                </ng-template>
              </e-column>
              <e-column
                [format]="'N'"
                field="expendituresDeletedItems"
                type="Max"
              >
                <ng-template #footerTemplate let-data>
                  Max smazaných položek: {{ data.Max }}
                </ng-template>
              </e-column>
              <e-column
                [format]="'N'"
                field="expendituresSuccessItems"
                type="Max"
              >
                <ng-template #footerTemplate let-data>
                  Max schválených položek: {{ data.Max }}
                </ng-template>
              </e-column>
              <e-column
                [format]="'N'"
                field="expendituresTotalItems"
                type="Max"
              >
                <ng-template #footerTemplate let-data>
                  Max položek celkem: {{ data.Max }}
                </ng-template>
              </e-column>
            </e-columns>
          </e-aggregate>
        </e-aggregates>
      </ejs-grid>
    </div>
  </div>
</div>
