import {User} from '@src/app/_models/user/user';
import {Company} from '@src/app/_models/company/company';

export class AddressUntrusted {
    id: number;

    // eslint-disable-next-line @typescript-eslint/naming-convention
    provider_id: number;

    address: string;

    ruian: number;

    descriptive: string;

    creator: User;

    provider: Company;

    // eslint-disable-next-line @typescript-eslint/naming-convention
    created_date: string;

    // eslint-disable-next-line @typescript-eslint/naming-convention
    updated_date?: string | null;

    // eslint-disable-next-line @typescript-eslint/naming-convention
    deleted_date?: string | null;

    // eslint-disable-next-line @typescript-eslint/naming-convention
    updated_by?: number | null;

    // eslint-disable-next-line @typescript-eslint/naming-convention
    deleted_by?: number | null;

    // eslint-disable-next-line @typescript-eslint/naming-convention
    created_by: number;

    unixCreatedTime: number;

    unixUpdatedTime: number;
}
