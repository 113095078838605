<ejs-toolbar
  #moduleToolbar
  id="moduleToolbar"
  overflowMode="Scrollable"
  scrollStep="300"
>
  <e-items>
    <e-item
      [align]="'center'"
      tooltipText="nový typ instalace"
      type="button"
    >
      <ng-template #template>
        <button
          [queryParams]="{
                        view: 'settings',
                        subview: 'installation_types',
                        form: 'addInstallType'
                    }"
          [routerLink]="['/services/']"
          cssClass="e-btn e-primary btn-p-zero e-small"
          ejs-button
          type="button"
        >
          <i aria-hidden="true" class="fa-light fa-plus mr-1"></i>nový
          typ instalace
        </button>
      </ng-template>
    </e-item>
    <e-item type="Separator"></e-item>
    <e-item
      [align]="'center'"
      tooltipText="nový typ jednotky"
      type="button"
    >
      <ng-template #template>
        <button
          [queryParams]="{
                        view: 'settings',
                        subview: 'flat_units',
                        form: 'addUnitType'
                    }"
          [routerLink]="['/services/']"
          cssClass="e-btn e-primary btn-p-zero e-small"
          ejs-button
          type="button"
        >
          <i aria-hidden="true" class="fa-light fa-plus mr-1"></i>nový
          typ jednotky
        </button>
      </ng-template>
    </e-item>
    <e-item type="Separator"></e-item>
    <e-item
      [align]="'center'"
      tooltipText="nová výměra bytových jednotek"
      type="button"
    >
      <ng-template #template>
        <button
          [queryParams]="{
                        view: 'settings',
                        subview: 'flat_units',
                        form: 'addSpaceType'
                    }"
          [routerLink]="['/services/']"
          cssClass="e-btn e-primary btn-p-zero e-small"
          ejs-button
          type="button"
        >
          <i aria-hidden="true" class="fa-light fa-plus mr-1"></i>nová
          výměra bytových jednotek
        </button>
      </ng-template>
    </e-item>
    <e-item type="Separator"></e-item>
    <e-item
      [align]="'center'"
      tooltipText="nový limit rychlosti"
      type="button"
    >
      <ng-template #template>
        <button
          [queryParams]="{
                        view: 'settings',
                        subview: 'speed_types',
                        form: 'addSpeedLimit'
                    }"
          [routerLink]="['/services/']"
          cssClass="e-btn e-primary btn-p-zero e-small"
          ejs-button
          type="button"
        >
          <i aria-hidden="true" class="fa-light fa-plus mr-1"></i>nový
          limit rychlosti
        </button>
      </ng-template>
    </e-item>
    <e-item type="Separator"></e-item>
    <e-item
      [align]="'center'"
      tooltipText="nový typ technologie"
      type="button"
    >
      <ng-template #template>
        <button
          [queryParams]="{
                        view: 'settings',
                        subview: 'technology_types',
                        form: 'addTechnologyType'
                    }"
          [routerLink]="['/services/']"
          cssClass="e-btn e-primary btn-p-zero e-small"
          ejs-button
          type="button"
        >
          <i aria-hidden="true" class="fa-light fa-plus mr-1"></i>nový
          typ technologie
        </button>
      </ng-template>
    </e-item>
    <e-item type="Separator"></e-item>
    <e-item [align]="'center'" tooltipText="nová sleva" type="button">
      <ng-template #template>
        <button
          [queryParams]="{
                        view: 'settings',
                        subview: 'transfer_types',
                        form: 'addTransferType'
                    }"
          [routerLink]="['/services/']"
          cssClass="e-btn e-primary btn-p-zero e-small"
          ejs-button
          type="button"
        >
          <i aria-hidden="true" class="fa-light fa-plus mr-1"></i>nový
          typ přenosu
        </button>
      </ng-template>
    </e-item>
    <e-item type="Separator"></e-item>
    <e-item [align]="'center'" tooltipText="nová lokace" type="button">
      <ng-template #template>
        <button
          [queryParams]="{
                        view: 'settings',
                        subview: 'deal_types',
                        form: 'addDealType'
                    }"
          [routerLink]="['/services/']"
          cssClass="e-btn e-primary btn-p-zero e-small"
          ejs-button
          type="button"
        >
          <i aria-hidden="true" class="fa-light fa-plus mr-1"></i>nový
          smluvní úvazek
        </button>
      </ng-template>
    </e-item>
  </e-items>
</ejs-toolbar>
<ejs-tab
  #servicesTab
  (created)="onCreate()"
  (selected)="select($event)"
  (selecting)="preventSwipe($event)"
  enablePersistence="true"
  height="auto"
  heightAdjustMode="Content"
  id="servicesTab"
>
  <e-tabitems>
    <e-tabitem [header]="headerText[0]">
      <ng-template #content>
        <app-services-deal-types></app-services-deal-types>
      </ng-template>
    </e-tabitem>
    <e-tabitem [header]="headerText[1]">
      <ng-template #content>
        <app-services-transfer-types></app-services-transfer-types>
      </ng-template>
    </e-tabitem>
    <e-tabitem [header]="headerText[2]">
      <ng-template #content>
        <app-services-flat-units></app-services-flat-units>
      </ng-template>
    </e-tabitem>
    <e-tabitem [header]="headerText[3]">
      <ng-template #content>
        <app-services-speed-types></app-services-speed-types>
      </ng-template>
    </e-tabitem>
    <e-tabitem [header]="headerText[4]">
      <ng-template #content>
        <app-services-technology-types></app-services-technology-types>
      </ng-template>
    </e-tabitem>
    <e-tabitem [header]="headerText[5]">
      <ng-template #content>
        <app-services-installation-types></app-services-installation-types>
      </ng-template>
    </e-tabitem>
  </e-tabitems>
</ejs-tab>
