<ngx-loading-bar></ngx-loading-bar>
<div id="toast_target"></div>
<ejs-toast
  #notifElement
  (beforeOpen)="onBeforeOpen($event)"
  (click)="onClick($event)"
  [position]="position"
  [showProgressBar]="true"
>
  <ng-template #title>
    <div>{{ notification?.title }}</div>
  </ng-template>
  <ng-template #content>
    <div>{{ notification?.body }}</div>
  </ng-template>
</ejs-toast>
<ng-container *ngIf="currentUser && currentUser.id && isLogedIn; else login">
  <ejs-dialog
    #notifDialog
    *ngIf="currentUser && currentUser.id && isLogedIn"
    [header]="'Upozornění'"
    [isModal]="true"
    [showCloseIcon]="true"
    [visible]="false"
    [width]="'85%'"
    id="notifDialog"
  >
    <ng-template #content>
      <div class="dialogContent">
        <app-notification-list></app-notification-list>
      </div>
    </ng-template>
    <ng-template #footerTemplate>
      <button
        (click)="notifDialog.hide()"
        [routerLink]="['/notifications/list']"
        cssClass="e-btn e-primary btn-p-zero e-small"
        ejs-button
        type="button"
      >
        Zobrazit v seznamu
      </button>
      <button
        (click)="notifDialog.hide()"
        cssClass="e-btn e-danger e-outline btn-p-zero e-small"
        ejs-button
        type="button"
      >
        ZRUŠIT
      </button>
    </ng-template>
  </ejs-dialog>
  <ejs-dialog
    #autoLogoutDialog
    *ngIf="autologoutService.isOpen | async"
    [header]="'Automatické odhlášení'"
    [isModal]="true"
    [showCloseIcon]="true"
    [visible]="(autologoutService.isOpen | async) ?? false"
    [width]="'85%'"
    id="autoLogoutDialog"
  >
    <ng-template #content>
      <div class="dialogContent">
        <div class="row">
          <div class="text-center justify-content-center modal-body">
            <p class="text-center h4">
              Do 1 minuty budete automaticky odhlášen z důvodu
              neaktivity...
            </p>
            <countdown
              #cd
              [config]="{ leftTime: 60 }"
              class="text-center h2 pt-3"
              id="cd"
            ></countdown>
          </div>
        </div>
      </div>
    </ng-template>
    <ng-template #footerTemplate>
      <button
        (click)="autoLogoutDialog.hide()"
        cssClass="e-btn e-danger e-outline btn-p-zero e-small"
        ejs-button
        type="button"
      >
        ZRUŠIT ODHLÁŠENÍ
      </button>
    </ng-template>
  </ejs-dialog>
  <div class="container-fluid" id="sidebar-section">
    <!--header-section  declaration -->
    <div
      *ngIf="currentUser && currentUser.id && isLogedIn"
      class="main-header fixed-top"
      id="header-section"
    >
      <ul class="header-list">
        <i
          (click)="openClick()"
          aria-hidden="true"
          class="fa-light fa-lg fa-bars float-left header-style"
          style="cursor: pointer"
        ></i>
        <li class="float-left header-style nav-pane logoLink">
          <a [routerLink]="''" title="hlavní nástěnka">JON System</a>
          <small class="ml-4 d-none d-lg-inline-block"
          >v 1.23.11-01</small
          >
        </li>
        <!--<li class="nav-item">
            <a class="nav-link">
                <i class="fa-light fa-life-ring mr-1 " aria-hidden="true"></i><span class="clearfix d-none d-sm-inline-block">Podpora</span>
            </a>
        </li>-->
        <li class="header-style float-right">
          <button
            #userMenuIcon
            (created)="getUserIcon()"
            (select)="selectUserMenu($event)"
            [items]="items"
            cssClass="e-caret-hide"
            ejs-dropdownbutton
            id="userMenuIcon"
          ></button>
        </li>
        <li class="header-style float-right">
          <!--<div
              (click)="notifDialog.show()"
              class="nav-link btn-group p-1 m-0"
          >
              <span
                  *ngIf="
                      (notificationService.notificationsNew$ | async)
                          ?.length > 0
                  "
                  class="e-badge e-badge-secondary e-badge-notification e-badge-overlap mt-1"
                  >{{
                      (notificationService.notificationsNew$ | async)
                          ?.length
                  }}</span
              >
              <i
                  aria-hidden="true"
                  class="fa-light fa-lg fa-bell mr-2"
                  style="cursor: pointer"
              ></i>
          </div>-->
        </li>
      </ul>
    </div>
    <!--end of header-section  declaration -->
    <!-- sidebar element declaration -->
    <ejs-sidebar
      #sidebarTreeviewInstance
      (created)="onCreatedSideBar()"
      [enablePersistence]="false"
      [mediaQuery]="mediaQuery"
      [target]="target"
      [width]="width"
      class="dock-menu"
      id="sidebar-treeview"
    >
      <div class="main-menu scrollbar-winter-neva square thin">
        <ejs-textbox
          #fulltextSearch
          (change)="startSearch()"
          class="p-1"
          floatLabelType="Auto"
          placeholder="Hledat..."
        ></ejs-textbox>
        <app-elastic-results
          (serachResultsDialogState)="
                        renderSearchResultDialog = $event
                    "
          [isVisible]="renderSearchResultDialog"
          [query]="searchQuery"
        ></app-elastic-results>
        <div
          *ngIf="currentUser.rule && currentUser.rule.is_admin"
          class="d-flex justify-content-between border-bottom mb-1 p-1"
        >
          <small>plugin:</small>
          <small>
            <ng-container *ngIf="plugins$ | async as plugins">
              <ng-container
                *ngFor="
                                    let plugin of plugins;
                                    trackBy: trackPluginsID
                                "
              >
                <i
                  *ngIf="plugin.state && plugin.active"
                  aria-hidden="true"
                  class="fa-light fa-lg fa-file-search text-success mr-2"
                  title="{{ plugin.name }}: {{
                                        plugin.last_check
                                            | dateFormat: 'DD.MM.YYYY HH:mm'
                                    }} "
                ></i>
                <i
                  *ngIf="!plugin.state || !plugin.active"
                  aria-hidden="true"
                  class="fa-light fa-lg fa-file-search text-danger mr-2"
                  title="{{ plugin.name }}: {{
                                        plugin.last_check
                                            | dateFormat: 'DD.MM.YYYY HH:mm'
                                    }} "
                ></i>
              </ng-container>
            </ng-container>
          </small>
        </div>
        <div
          *ngIf="currentUser.rule && currentUser.rule.is_admin"
          class="d-flex justify-content-between border-bottom mb-3 p-1"
        >
          <small>api:</small>
          <small>
            <ng-container *ngIf="apis$ | async as apis">
              <ng-container
                *ngFor="let api of apis; trackBy: trackApisID"
              >
                <i
                  *ngIf="
                                        api.state &&
                                        api.active &&
                                        api.type.name === 'ACS'
                                    "
                  aria-hidden="true"
                  class="fa-light fa-lg fa-fingerprint text-success mr-2"
                  title="{{ api.title }}: {{
                                        api.last_check
                                            | dateFormat: 'DD.MM.YYYY HH:mm'
                                    }} "
                ></i>
                <i
                  *ngIf="
                                        (!api.state || !api.active) &&
                                        api.type.name === 'ACS'
                                    "
                  aria-hidden="true"
                  class="fa-light fa-lg fa-fingerprint text-danger mr-2"
                  title="{{ api.title }}: {{
                                        api.last_check
                                            | dateFormat: 'DD.MM.YYYY HH:mm'
                                    }} "
                ></i>
                <i
                  *ngIf="
                                        api.state &&
                                        api.active &&
                                        api.type.name === 'ERP'
                                    "
                  aria-hidden="true"
                  class="fa-light fa-lg fa-abacus text-success mr-2"
                  title="{{ api.title }}: {{
                                        api.last_check
                                            | dateFormat: 'DD.MM.YYYY HH:mm'
                                    }} "
                ></i>
                <i
                  *ngIf="
                                        (!api.state || !api.active) &&
                                        api.type.name === 'ERP'
                                    "
                  aria-hidden="true"
                  class="fa-light fa-lg fa-abacus text-danger mr-2"
                  title="{{ api.title }}: {{
                                        api.last_check
                                            | dateFormat: 'DD.MM.YYYY HH:mm'
                                    }} "
                ></i>
                <i
                  *ngIf="
                                        api.state &&
                                        api.active &&
                                        api.type.name === 'MONITORING'
                                    "
                  aria-hidden="true"
                  class="fa-light fa-lg fa-monitor-heart-rate text-success mr-2"
                  title="{{ api.title }}: {{
                                        api.last_check
                                            | dateFormat: 'DD.MM.YYYY HH:mm'
                                    }} "
                ></i>
                <i
                  *ngIf="
                                        (!api.state || !api.active) &&
                                        api.type.name === 'MONITORING'
                                    "
                  aria-hidden="true"
                  class="fa-light fa-lg fa-monitor-heart-rate text-danger mr-2"
                  title="{{ api.title }}: {{
                                        api.last_check
                                            | dateFormat: 'DD.MM.YYYY HH:mm'
                                    }} "
                ></i>
              </ng-container>
            </ng-container>
          </small>
        </div>
        <div
          aria-label="Create toolbar"
          class="col-12 text-center pb-2"
        >
          <ejs-tooltip content="vytvořit ticket">
            <button
              [queryParams]="{ view: 'grid', form: 'addTicket' }"
              [routerLink]="'/tickets/'"
              cssClass="e-btn e-info btn-p-zero e-small"
              ejs-button
              type="button"
            >
              <i
                aria-hidden="true"
                class="fa-light fa-ticket-alt text-white"
              ></i>
            </button>
          </ejs-tooltip>
          <ejs-tooltip content="vytvořit žádanku">
            <button
              [queryParams]="{
                                view: 'grid',
                                form: 'addVacation'
                            }"
              [routerLink]="'/vacation/'"
              cssClass="e-btn e-danger btn-p-zero e-small"
              ejs-button
              type="button"
            >
              <i
                aria-hidden="true"
                class="fa-light fa-calendar-alt calendar text-white"
              ></i>
            </button>
          </ejs-tooltip>
          <ejs-tooltip content="vytvořit objednávku">
            <button
              [queryParams]="{
                                view: 'grid',
                                form: 'addMaterial'
                            }"
              [routerLink]="'/material/'"
              cssClass="e-btn e-success btn-p-zero e-small"
              ejs-button
              type="button"
            >
              <i
                aria-hidden="true"
                class="fa-light fa-box text-white"
              ></i>
            </button>
          </ejs-tooltip>
          <ejs-tooltip content="zobrazit nápovědu">
            <button
              cssClass="e-btn e-primary btn-p-zero e-small"
              ejs-button
              routerLink="/wiki/"
              type="button"
            >
              <i
                aria-hidden="true"
                class="fa-light fa-book text-white"
              ></i>
            </button>
          </ejs-tooltip>
        </div>
        <div>
          <!-- Treevie element declaration -->
          <ejs-treeview
            #treeMenuInstance
            (created)="onCreateMenu()"
            (nodeClicked)="loadRoutingContent($event)"
            [cssClass]="cssClass"
            [fields]="field"
            expandOn="Click"
          >
            <ng-template #nodeTemplate="" let-data="">
              <div class="listWrapper justify-content-between">
                <span>{{ data.nodeText }}</span>
                <span
                  *ngIf="data.message > 0"
                  class="{{ data.badge }} mt-2"
                >{{ data.message }}</span
                >
              </div>
            </ng-template>
          </ejs-treeview>
        </div>
      </div>
    </ejs-sidebar>
    <!-- end of sidebar element -->
    <!-- main-content declaration -->
    <div
      [ngClass]="currentUser ? 'mt-55' : ''"
      class="main-content container-fluid"
      id="main-text"
    >
      <!-- (click)="closeDocumentClick()" -->
      <div class="sidebar-content" style="z-index: 999 !important">
        <div
          *ngIf="currentUser"
          class="d-flex justify-content-between mb-1"
          style="max-height: 30px !important"
        >
          <app-ng7-bootstrap-breadcrumb></app-ng7-bootstrap-breadcrumb>
          <button
            (click)="goBack()"
            class="e-btn e-primary btn-p-zero e-small"
            ejs-button
            type="button"
          >
            <i
              aria-hidden="true"
              class="fa-light fa-lg fa-arrow-alt-left mr-1"
            ></i
            >zpět
          </button>
        </div>
        <router-outlet></router-outlet>
      </div>
    </div>
    <!-- end of main-content -->
  </div>
</ng-container>
<ng-template #login>
  <div class="container-fluid">
    <app-login></app-login>
  </div>
</ng-template>
