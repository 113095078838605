<ejs-dialog
  #formDialog
  (close)="
        isUpdate = false;
        isCreate = false;
        isDirty = false;
        isChecked = false;
        isCopy = false;
        submited = false;
        changeDialogState(false)
    "
  [isModal]="true"
  [showCloseIcon]="true"
  [visible]="isVisible"
  [width]="'85%'"
  id="formDialog"
>
  <form [formGroup]="netPackageForm">
    <ng-template #content>
      <div class="dialogContent">
        <div class="row">
          <div class="col-12 mb-3">
            <label
              [ngClass]="
                                f.name.valid && (f.name.dirty || f.name.touched)
                                    ? 'text-success'
                                    : f.name.invalid &&
                                      (f.name.dirty || f.name.touched)
                                    ? 'text-danger'
                                    : 'text-muted'
                            "
              for="name"
            >* Název tarifu:</label
            >
            <ejs-textbox
              [ngClass]="
                                (f.name.dirty || f.name.touched) &&
                                f.name.invalid
                                    ? 'e-error'
                                    : (f.name.dirty || f.name.touched) &&
                                      f.name.valid
                                    ? 'e-success'
                                    : ''
                            "
              floatLabelType="Auto"
              formControlName="name"
              id="name"
            ></ejs-textbox>
            <small
              *ngIf="
                                (f.name.dirty || f.name.touched) &&
                                f.name.errors &&
                                f.name.errors.required
                            "
              class="text-danger"
            >Název tarifu je povinný</small
            >
          </div>
          <div class="col-12 col-md-6 mb-3">
            <label
              [ngClass]="
                                f.price.valid &&
                                (f.price.dirty || f.price.touched)
                                    ? 'text-success'
                                    : f.price.invalid &&
                                      (f.price.dirty || f.price.touched)
                                    ? 'text-danger'
                                    : 'text-muted'
                            "
              for="price"
            >* Cena tarifu:</label
            >
            <ejs-numerictextbox
              [format]="'#,###.00\',- CZK\''"
              [ngClass]="
                                (f.price.dirty || f.price.touched) &&
                                f.price.invalid
                                    ? 'e-error'
                                    : (f.price.dirty || f.price.touched) &&
                                      f.price.valid
                                    ? 'e-success'
                                    : ''
                            "
              formControlName="price"
              id="price"
              step="0.01"
            ></ejs-numerictextbox>
            <small
              *ngIf="
                                (f.price.dirty || f.price.touched) &&
                                f.price.errors &&
                                f.price.errors.required
                            "
              class="text-danger"
            >Cena tarifu je povinná</small
            >
          </div>
          <div class="col-12 col-md-6 mb-3">
            <label
              [ngClass]="
                                f.provider_id.valid
                                    ? 'text-success'
                                    : f.provider_id.invalid &&
                                      (f.provider_id.dirty ||
                                          f.provider_id.touched)
                                    ? 'text-danger'
                                    : 'text-muted'
                            "
              for="provider"
            >* Provider tarifu:</label
            >
            <ejs-dropdownlist
              #provider
              (filtering)="onFilteringProviders($event)"
              [allowFiltering]="true"
              [cssClass]="
                                (f.provider_id.dirty ||
                                    f.provider_id.touched) &&
                                f.provider_id.invalid
                                    ? 'e-error'
                                    : (f.provider_id.dirty ||
                                          f.provider_id.touched) &&
                                      f.provider_id.valid
                                    ? 'e-success'
                                    : ''
                            "
              [dataSource]="companySelect"
              [fields]="fields"
              [filterBarPlaceholder]="'Vyhledat providera...'"
              [ignoreAccent]="true"
              [placeholder]="'Vyberte providera'"
              [popupHeight]="height"
              formControlName="provider_id"
              id="provider"
              sortOrder="Ascending"
            ></ejs-dropdownlist>
            <small
              *ngIf="
                                (f.provider_id.dirty ||
                                    f.provider_id.touched) &&
                                f.provider_id.errors &&
                                f.provider_id.errors.required
                            "
              class="text-danger"
            >Vyberte alespoň jednoho providera tarifu</small
            >
          </div>
          <div class="col-12 col-md-6 mb-3">
            <label
              [ngClass]="
                                f.transfer_id.valid
                                    ? 'text-success'
                                    : f.transfer_id.invalid &&
                                      (f.transfer_id.dirty ||
                                          f.transfer_id.touched)
                                    ? 'text-danger'
                                    : 'text-muted'
                            "
              for="transfer"
            >* Technologie přenosu:</label
            >
            <ejs-dropdownlist
              #transfer
              (filtering)="onFilteringTransferTypes($event)"
              [allowFiltering]="true"
              [cssClass]="
                                (f.transfer_id.dirty ||
                                    f.transfer_id.touched) &&
                                f.transfer_id.invalid
                                    ? 'e-error'
                                    : (f.transfer_id.dirty ||
                                          f.transfer_id.touched) &&
                                      f.transfer_id.valid
                                    ? 'e-success'
                                    : ''
                            "
              [dataSource]="transferSelect"
              [fields]="fields"
              [filterBarPlaceholder]="
                                'Vyhledat technologii přenosu...'
                            "
              [ignoreAccent]="true"
              [placeholder]="'Vyberte technologii přenosu'"
              [popupHeight]="height"
              formControlName="transfer_id"
              id="transfer"
              sortOrder="Ascending"
            ></ejs-dropdownlist>
            <small
              *ngIf="
                                (f.transfer_id.dirty ||
                                    f.transfer_id.touched) &&
                                f.transfer_id.errors &&
                                f.transfer_id.errors.required
                            "
              class="text-danger"
            >Vyberte alespoň jednu z technologíí přenosu</small
            >
          </div>
          <div class="col-12 col-md-6 mb-3">
            <label
              [ngClass]="
                                f.discount_id.valid
                                    ? 'text-success'
                                    : f.discount_id.invalid &&
                                      (f.discount_id.dirty ||
                                          f.discount_id.touched)
                                    ? 'text-danger'
                                    : 'text-muted'
                            "
              for="discount"
            >Sleva tarifu:</label
            >
            <ejs-dropdownlist
              #discount
              (filtering)="onFilteringDiscounts($event)"
              [allowFiltering]="true"
              [cssClass]="
                                (f.discount_id.dirty ||
                                    f.discount_id.touched) &&
                                f.discount_id.invalid
                                    ? 'e-error'
                                    : (f.discount_id.dirty ||
                                          f.discount_id.touched) &&
                                      f.discount_id.valid
                                    ? 'e-success'
                                    : ''
                            "
              [dataSource]="discountSelect"
              [fields]="fields"
              [filterBarPlaceholder]="'Vyhledat slevu...'"
              [ignoreAccent]="true"
              [placeholder]="'Vyberte slevu'"
              [popupHeight]="height"
              formControlName="discount_id"
              id="discount"
              sortOrder="Ascending"
            ></ejs-dropdownlist>
            <small
              *ngIf="
                                (f.discount_id.dirty ||
                                    f.discount_id.touched) &&
                                f.discount_id.errors &&
                                f.discount_id.errors.required
                            "
              class="text-danger"
            >Vyberte alespoň jednu ze slev tarifu</small
            >
          </div>
          <div class="col-12 col-md-6 mb-3">
            <label
              [ngClass]="
                                f.sale_order.valid &&
                                (f.sale_order.dirty || f.sale_order.touched)
                                    ? 'text-success'
                                    : f.sale_order.invalid &&
                                      (f.sale_order.dirty ||
                                          f.sale_order.touched)
                                    ? 'text-danger'
                                    : 'text-muted'
                            "
              for="sale_order"
            >* Pořadí nabídky:</label
            >
            <ejs-numerictextbox
              [format]="'###'"
              [ngClass]="
                                (f.sale_order.dirty || f.sale_order.touched) &&
                                f.sale_order.invalid
                                    ? 'e-error'
                                    : (f.sale_order.dirty ||
                                          f.sale_order.touched) &&
                                      f.sale_order.valid
                                    ? 'e-success'
                                    : ''
                            "
              formControlName="sale_order"
              id="sale_order"
              step="1"
            ></ejs-numerictextbox>
            <small
              *ngIf="
                                (f.sale_order.dirty || f.sale_order.touched) &&
                                f.sale_order.errors &&
                                f.sale_order.errors.required
                            "
              class="text-danger"
            >Pořadí nabídky je povinné</small
            >
          </div>
          <div class="col-12 col-md-6 mb-3">
            <label
              [ngClass]="
                                f.speed_up.valid &&
                                (f.speed_up.dirty || f.speed_up.touched)
                                    ? 'text-success'
                                    : f.speed_up.invalid &&
                                      (f.speed_up.dirty || f.speed_up.touched)
                                    ? 'text-danger'
                                    : 'text-muted'
                            "
              for="speed_up"
            >* Rychlost nahrávání tarifu:</label
            >
            <ejs-numerictextbox
              [format]="'###'"
              [ngClass]="
                                (f.speed_up.dirty || f.speed_up.touched) &&
                                f.speed_up.invalid
                                    ? 'e-error'
                                    : (f.speed_up.dirty ||
                                          f.speed_up.touched) &&
                                      f.speed_up.valid
                                    ? 'e-success'
                                    : ''
                            "
              formControlName="speed_up"
              id="speed_up"
              step="1"
            ></ejs-numerictextbox>
            <small
              *ngIf="
                                (f.speed_up.dirty || f.speed_up.touched) &&
                                f.speed_up.errors &&
                                f.speed_up.errors.required
                            "
              class="text-danger"
            >Rychlost nahrávání tarifu je povinná</small
            >
          </div>
          <div class="col-12 col-md-6 mb-3">
            <label
              [ngClass]="
                                f.speed_down.valid &&
                                (f.speed_down.dirty || f.speed_down.touched)
                                    ? 'text-success'
                                    : f.speed_down.invalid &&
                                      (f.speed_down.dirty ||
                                          f.speed_down.touched)
                                    ? 'text-danger'
                                    : 'text-muted'
                            "
              for="speed_down"
            >* Rychlost stahování tarifu:</label
            >
            <ejs-numerictextbox
              [format]="'###'"
              [ngClass]="
                                (f.speed_down.dirty || f.speed_down.touched) &&
                                f.speed_down.invalid
                                    ? 'e-error'
                                    : (f.speed_down.dirty ||
                                          f.speed_down.touched) &&
                                      f.speed_down.valid
                                    ? 'e-success'
                                    : ''
                            "
              formControlName="speed_down"
              id="speed_down"
              step="1"
            ></ejs-numerictextbox>
            <small
              *ngIf="
                                (f.speed_down.dirty || f.speed_down.touched) &&
                                f.speed_down.errors &&
                                f.speed_down.errors.required
                            "
              class="text-danger"
            >Rychlost stahování tarifu je povinná</small
            >
          </div>
          <div class="col-12 col-md-6 mb-3">
            <label
              [ngClass]="
                                f.aggregation.valid &&
                                (f.aggregation.dirty || f.aggregation.touched)
                                    ? 'text-success'
                                    : f.aggregation.invalid &&
                                      (f.aggregation.dirty ||
                                          f.aggregation.touched)
                                    ? 'text-danger'
                                    : 'text-muted'
                            "
              for="aggregation"
            >Agregace tarifu:</label
            >
            <ejs-textbox
              [ngClass]="
                                (f.aggregation.dirty ||
                                    f.aggregation.touched) &&
                                f.aggregation.invalid
                                    ? 'e-error'
                                    : (f.aggregation.dirty ||
                                          f.aggregation.touched) &&
                                      f.aggregation.valid
                                    ? 'e-success'
                                    : ''
                            "
              floatLabelType="Auto"
              formControlName="aggregation"
              id="aggregation"
            ></ejs-textbox>
            <small
              *ngIf="
                                (f.aggregation.dirty ||
                                    f.aggregation.touched) &&
                                f.aggregation.errors &&
                                f.aggregation.errors.required
                            "
              class="text-danger"
            >Agregace tarifu je povinná</small
            >
          </div>
          <div class="col-12 col-md-6 mb-3">
            <ejs-checkbox
              [checked]="false"
              [ngClass]="
                                (f.recommended.dirty ||
                                    f.recommended.touched) &&
                                f.recommended.invalid
                                    ? 'e-error'
                                    : (f.recommended.dirty ||
                                          f.recommended.touched) &&
                                      f.recommended.valid
                                    ? 'e-success'
                                    : ''
                            "
              cssClass="e-primary p-0 mr-3"
              formControlName="recommended"
              label="Doporučeno"
            >
            </ejs-checkbox>
            <small
              *ngIf="
                                (f.recommended.dirty ||
                                    f.recommended.touched) &&
                                f.recommended.errors &&
                                f.recommended.errors.required
                            "
              class="text-danger"
            >Vlastnost doporučeno je povinná</small
            >
          </div>
          <div class="col-12 col-md-6 mb-5">
            <ejs-checkbox
              [checked]="false"
              [ngClass]="
                                (f.extension.dirty || f.extension.touched) &&
                                f.extension.invalid
                                    ? 'e-error'
                                    : (f.extension.dirty ||
                                          f.extension.touched) &&
                                      f.extension.valid
                                    ? 'e-success'
                                    : ''
                            "
              cssClass="e-primary p-0 mr-3"
              formControlName="extension"
              label="Rozšiřující tarif"
            >
            </ejs-checkbox>
            <small
              *ngIf="
                                (f.extension.dirty || f.extension.touched) &&
                                f.extension.errors &&
                                f.extension.errors.required
                            "
              class="text-danger"
            >Vlastnost rozšíření je povinná</small
            >
          </div>
          <div class="col-12 mb-4 form-group">
            <label
              [ngClass]="
                                f.net_places.valid
                                    ? 'text-success'
                                    : f.net_places.invalid &&
                                      (f.net_places.dirty ||
                                          f.net_places.touched)
                                    ? 'text-danger'
                                    : 'text-muted'
                            "
              for="places"
            >Oblasti pokrytí:</label
            >
            <ejs-multiselect
              #places
              (filtering)="onFilteringPlaces($event)"
              [allowFiltering]="true"
              [cssClass]="
                                (f.net_places.dirty || f.net_places.touched) &&
                                f.net_places.invalid
                                    ? 'e-error'
                                    : (f.net_places.dirty ||
                                          f.net_places.touched) &&
                                      f.net_places.valid
                                    ? 'e-success'
                                    : ''
                            "
              [dataSource]="placeSelect"
              [fields]="fields"
              [filterBarPlaceholder]="'Vyhledat lokace tarifu...'"
              [ignoreAccent]="true"
              [mode]="'CheckBox'"
              [placeholder]="'Vyberte lokace tarifu'"
              [popupHeight]="height"
              [selectAllText]="'Všechny lokace tarifu'"
              formControlName="net_places"
              id="places"
              showSelectAll="true"
              sortOrder="Ascending"
            ></ejs-multiselect>
            <small
              *ngIf="
                                f.net_places.touched &&
                                f.net_places.errors &&
                                f.net_places.errors.required
                            "
              class="text-danger"
            >Vyberte oblasti pokrytí</small
            >
          </div>
          <div class="col-12 mb-4 form-group">
            <label
              [ngClass]="
                                f.net_bonuses.valid
                                    ? 'text-success'
                                    : f.net_bonuses.invalid &&
                                      (f.net_bonuses.dirty ||
                                          f.net_bonuses.touched)
                                    ? 'text-danger'
                                    : 'text-muted'
                            "
              for="bonuses"
            >Bonusy:</label
            >
            <ejs-multiselect
              #bonuses
              (filtering)="onFilteringBonuses($event)"
              [allowFiltering]="true"
              [cssClass]="
                                (f.net_bonuses.dirty ||
                                    f.net_bonuses.touched) &&
                                f.net_bonuses.invalid
                                    ? 'e-error'
                                    : (f.net_bonuses.dirty ||
                                          f.net_bonuses.touched) &&
                                      f.net_bonuses.valid
                                    ? 'e-success'
                                    : ''
                            "
              [dataSource]="bonusSelect"
              [fields]="fields"
              [filterBarPlaceholder]="'Vyhledat bonusy tarifu...'"
              [ignoreAccent]="true"
              [mode]="'CheckBox'"
              [placeholder]="'Vyberte bonusy tarifu'"
              [popupHeight]="height"
              [selectAllText]="'Všechny bonusy tarifu'"
              formControlName="net_bonuses"
              id="bonuses"
              showSelectAll="true"
              sortOrder="Ascending"
            ></ejs-multiselect>
            <small
              *ngIf="
                                f.net_bonuses.touched &&
                                f.net_bonuses.errors &&
                                f.net_bonuses.errors.required
                            "
              class="text-danger"
            >Vyberte bonusy</small
            >
          </div>
          <div class="col-12 mb-4 form-group">
            <label
              [ngClass]="
                                f.ext_services.valid
                                    ? 'text-success'
                                    : f.ext_services.invalid &&
                                      (f.ext_services.dirty ||
                                          f.ext_services.touched)
                                    ? 'text-danger'
                                    : 'text-muted'
                            "
              for="extServices"
            >Rozšiřující balíčky:</label
            >
            <ejs-multiselect
              #extServices
              (filtering)="onFilteringExtServices($event)"
              [allowFiltering]="true"
              [cssClass]="
                                (f.ext_services.dirty ||
                                    f.ext_services.touched) &&
                                f.ext_services.invalid
                                    ? 'e-error'
                                    : (f.ext_services.dirty ||
                                          f.ext_services.touched) &&
                                      f.ext_services.valid
                                    ? 'e-success'
                                    : ''
                            "
              [dataSource]="extServiceSelect"
              [fields]="fields"
              [filterBarPlaceholder]="
                                'Vyhledat rozšiřující služby...'
                            "
              [ignoreAccent]="true"
              [mode]="'CheckBox'"
              [placeholder]="'Vyberte rozšiřující služby'"
              [popupHeight]="height"
              [selectAllText]="'Všechny rozšiřující služby'"
              formControlName="ext_services"
              id="extServices"
              showSelectAll="true"
              sortOrder="Ascending"
            ></ejs-multiselect>
            <small
              *ngIf="
                                f.ext_services.touched &&
                                f.ext_services.errors &&
                                f.ext_services.errors.required
                            "
              class="text-danger"
            >Vyberte rozšiřující balíčky</small
            >
          </div>
        </div>
      </div>
    </ng-template>
    <ng-template #footerTemplate>
      <div class="d-flex justify-content-end">
                <span
                  *ngIf="netPackageForm.invalid"
                  class="e-badge e-badge-warning p-2 size_2"
                  role="alert"
                >
                    <strong>Formulář je špatně vyplněný</strong>
                </span>
        <span
          *ngIf="netPackageForm.valid"
          class="e-badge e-badge-success p-2 size_2"
          role="alert"
        >
                    <strong>Formulář je správně vyplněný</strong>
                </span>
      </div>
      <div class="row">
        <div class="col-12 pt-2">
          <button
            (click)="onSubmit()"
            [disabled]="
                            loadingNetPackage ||
                            netPackageForm.invalid ||
                            !isChecked
                        "
            cssClass="e-btn e-success btn-p-zero e-small mr-1"
            ejs-button
            type="submit"
          >
                        <span
                          *ngIf="loadingNetPackage === true"
                          aria-hidden="true"
                          class="spinner-grow spinner-grow-sm"
                          role="status"
                        ></span>
            <span *ngIf="loadingNetPackage === false">ULOŽIT</span>
            <span *ngIf="loadingNetPackage === true"
            >Provádím...</span
            >
          </button>
          <button
            (click)="formDialogObj.hide()"
            cssClass="e-btn e-danger e-outline btn-p-zero e-small"
            ejs-button
            type="button"
          >
            ZRUŠIT
          </button>
        </div>
      </div>
    </ng-template>
  </form>
</ejs-dialog>
