import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    HostListener,
    Input,
    OnChanges,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import {ComponentCanDeactivate} from '@src/app/_guards/changes.guard';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {User} from '@src/app/_models/user/user';
import {ToolbarComponent} from '@syncfusion/ej2-angular-navigations';
import {DialogComponent} from '@syncfusion/ej2-angular-popups';
import {
    DropDownListComponent,
    FilteringEventArgs,
    MultiSelectComponent,
} from '@syncfusion/ej2-angular-dropdowns';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {DataService} from '@src/app/_services/data.service';
import {PermissionService} from '@src/app/_services/permission.service';
import {AuthenticationService} from '@src/app/_services/authentication.service';
import {MessageService} from '@src/app/_services/message.service';
import {Observable} from 'rxjs';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {EmitType} from '@syncfusion/ej2-base';
import {Query} from '@syncfusion/ej2-data';
import {Car} from '@src/app/_models/cars/car.model';
import {Company} from '@src/app/_models/company/company';
import {HotlineService} from '@src/app/features/hotline/hotline.service';
import {SettingsService} from '@src/app/features/settings/settings.service';
import {ENGINE_TYPE} from '@src/app/features/settings/types/engine.type';
import {CarInput} from '@src/app/features/settings/types/car-input.type';

@UntilDestroy()
@Component({
    selector: 'app-cars-form',
    templateUrl: './cars-form.component.html',
    styleUrls: ['./cars-form.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CarsFormComponent implements OnInit, OnChanges, ComponentCanDeactivate {
    // Dropdowns
    fields: object = {text: 'label', value: 'value'};

    height = '240px';

    usersSelect: Array<{value: boolean | number | string; label: string}> = [];

    engineSelect: Array<{value: boolean | number | string; label: string}> = [];

    companySelect: Array<{value: boolean | number | string; label: string}> = [];

    // Forms
    carForm: FormGroup;

    isDirty = false;

    isChecked = false;

    submited = false;

    // Variables
    currentUser: User | null;

    users: User[];

    cars: Car[] = [];

    companies: Company[] = [];

    // Loaders
    loadingCar = false;

    @Input() car: Car | null = null;

    @Input() isCreate = false;

    @Input() isUpdate = false;

    @Input() isCopy = false;

    @Input() isVisible = false;

    @Output() readonly carFormDialogState = new EventEmitter<boolean>();

    // Maintoolbar
    @ViewChild('dialogFooterToolbar') dialogFooterToolbarObj: ToolbarComponent;

    // Dialogs
    @ViewChild('formDialog') formDialogObj: DialogComponent;

    // Dropdowns
    @ViewChild('engine') engineObj: MultiSelectComponent;

    @ViewChild('owner') ownerObj: DropDownListComponent;

    @ViewChild('company') companyObj: DropDownListComponent;

    constructor(
        private readonly route: ActivatedRoute,
        private readonly router: Router,
        private readonly ref: ChangeDetectorRef,
        private readonly formBuilder: FormBuilder,
        private readonly dataService: DataService,
        private readonly settingsService: SettingsService,
        private readonly hotlineService: HotlineService,
        private readonly permissionService: PermissionService,
        private readonly authenticationService: AuthenticationService,
        private readonly messages: MessageService,
    ) {
        this.currentUser = this.authenticationService.currentUserValue;
        this.dataService.setCompanyDataSource();
    }

    get f(): {[key: string]: AbstractControl} {
        return this.carForm.controls;
    }

    @HostListener('window:beforeunload')
    canDeactivate(): Observable<boolean> | boolean {
        return !this.isDirty;
    }

    ngOnInit(): void {
        this.dataService.carsSource.pipe(untilDestroyed(this))
            .subscribe(
                (cars: Car[]) => {
                    this.cars = cars;
                },
                error => {
                    console.error(error);
                },
            );

        this.dataService.userSource.pipe(untilDestroyed(this))
            .subscribe(
                (users: User[]) => {
                    this.users = users;
                    this.usersSelect = [];
                    users.map((user: User) => {
                        if (
                            this.currentUser &&
                            user.authorized &&
                            !user.deleted_date &&
                            (user.id === this.currentUser.id ||
                                user.boss_id === this.currentUser.id ||
                                this.permissionService.checkUserISCarAdmin(this.currentUser))
                        ) {
                            this.usersSelect = [
                                ...this.usersSelect,
                                {
                                    value: user.id,
                                    label: user.secondname + ' ' + user.firstname,
                                },
                            ];
                        }
                    });
                    this.ref.markForCheck();
                },
                error => {
                    console.error(error);
                },
            );

        this.dataService.companySource.pipe(untilDestroyed(this))
            .subscribe(
                (companies: Company[]) => {
                    this.companies = companies;
                    this.companySelect = [];
                    companies.map((company: Company) => {
                        if (!company.deleted_date) {
                            this.companySelect = [
                                ...this.companySelect,
                                {
                                    value: company.id,
                                    label: company.name,
                                },
                            ];
                        }
                    });
                    this.ref.markForCheck();
                },
                error => {
                    console.error(error);
                },
            );

        this.engineSelect = ENGINE_TYPE;

        this.carForm = this.formBuilder.group({
            owned_by: [null],
            company_id: [null, Validators.required],
            engine: [[], Validators.required],
            name: ['', Validators.required],
            bookable: [false, Validators.required],
            recharge_time: [1, Validators.required],
        });

        this.carForm.valueChanges.pipe(untilDestroyed(this))
            .subscribe(() => {
                this.isDirty = this.carForm.dirty;
                this.showCarInputErrors();
            });
    }

    ngOnChanges(): void {
        if (this.isVisible) {
            const queryParams: Params = {form: null};

            void this.router.navigate([], {
                relativeTo: this.route,
                queryParams,
                queryParamsHandling: 'merge',
            });

            if (this.isUpdate && this.car) {
                this.formDialogObj.header = `Vozidlo #${this.car.id} | ${this.car.name}`;
                this.fillTheForm();
                this.showCarInputErrors();
            }

            if (this.isCreate) {
                this.carForm.reset();
                this.formDialogObj.header = 'Nové vozidlo';
                this.fillTheForm();
            }

            if (this.isCopy) {
                this.carForm.reset();
                this.formDialogObj.header = 'Nové vozidlo';
                this.fillTheForm();
                this.showCarInputErrors();
            }
        }
    }

    changeDialogState(value: boolean): void {
        this.carFormDialogState.emit(value);
    }

    onFilteringUsers: EmitType<FilteringEventArgs> = (e: FilteringEventArgs) => {
        if (e.text === '') {
            e.updateData(this.usersSelect);
        } else {
            let query: Query = new Query();

            query = e.text !== '' ? query.where('label', 'contains', e.text, true, true) : query;
            e.updateData(this.usersSelect, query);
        }
    };

    onFilteringEngines: EmitType<FilteringEventArgs> = (e: FilteringEventArgs) => {
        if (e.text === '') {
            e.updateData(this.engineSelect);
        } else {
            let query: Query = new Query();

            query = e.text !== '' ? query.where('label', 'contains', e.text, true, true) : query;
            e.updateData(this.engineSelect, query);
        }
    };

    onRenderCell(args): void {
        if (args.date.getDay() === 0 || args.date.getDay() === 6) {
            args.isDisabled = true;
        }
    }

    fillTheForm(): void {
        const engine: Array<{value: boolean | number | string; label: string}> = [];

        if (this.car?.gasoline) {
            engine.push({label: 'gasoline', value: 'gasoline'});
        }

        if (this.car?.electricity) {
            engine.push({label: 'electricity', value: 'electricity'});
        }

        if (this.car?.lpg) {
            engine.push({label: 'lpg', value: 'lpg'});
        }

        if (this.car?.diesel) {
            engine.push({label: 'diesel', value: 'diesel'});
        }

        this.carForm.controls.owned_by.patchValue(this.car ? this.car.owned_by : null);
        this.carForm.controls.company_id.patchValue(this.car ? this.car.company_id : null);
        this.carForm.controls.name.patchValue(this.car ? this.car.name : '');
        this.carForm.controls.bookable.patchValue(this.car ? this.car.bookable : false);
        this.carForm.controls.engine.patchValue(engine);
        this.carForm.controls.recharge_time.patchValue(this.car ? this.car.recharge_time : 1);
    }

    onSubmit(): void {
        this.submited = true;
        this.isDirty = false;
        this.loadingCar = true;

        if (this.isCreate || this.isCopy) {
            this.addCar();
        }

        if (this.isUpdate) {
            this.editCar();
        }
    }

    addCar(): void {
        if (this.carForm.invalid) {
            console.error('form is not valid!');

            return;
        }

        let gasoline = false;
        let diesel = false;
        let electricity = false;
        let lpg = false;

        this.f.engine.value.forEach((engine: string) => {
            if (engine === 'gasoline') {
                gasoline = true;
            }

            if (engine === 'diesel') {
                diesel = true;
            }

            if (engine === 'electricity') {
                electricity = true;
            }

            if (engine === 'lpg') {
                lpg = true;
            }
        });

        const carData: CarInput = {
            owned_by: this.f.owned_by.value,
            company_id: this.f.company_id.value,
            name: this.f.name.value,
            bookable: this.f.bookable.value,
            diesel,
            gasoline,
            lpg,
            electricity,
            hybrid: this.f.engine.value.length > 1,
            recharge_time: this.f.recharge_time.value,
            created_by: this.currentUser?.id,
        };

        this.settingsService
            .addCar(carData)
            .pipe(untilDestroyed(this))
            .subscribe(
                data => {
                    const body = 'přidána do fronty ke schválení';
                    const options = {progressBar: true, timeOut: 5000};

                    this.messages.showSuccess('Žádanka úspěšně vytvořena', body, options);
                    this.dataService.setCarsDataSource();
                    this.loadingCar = false;
                    this.isCreate = false;
                    this.formDialogObj.hide();
                    this.ref.markForCheck();
                    void this.router.navigate(['/car/detail', data.id]);
                },
                error => {
                    console.error(error);

                    const body = 'Zkuste to znovu později';
                    const options = {
                        progressBar: true,
                        timeOut: 5000,
                        toastClass: 'red',
                    };

                    this.messages.showError('Chyba při vytváření žádanky', body, options);
                    this.loadingCar = false;
                    this.ref.markForCheck();
                },
            );
    }

    copyCar(): void {
        this.isCreate = true;
        this.isUpdate = false;
        this.carForm.reset();
        this.formDialogObj.header = 'Nová žádanka';
        this.fillTheForm();
        this.showCarInputErrors();
        this.ref.markForCheck();
        this.formDialogObj.show();
    }

    // eslint-disable-next-line complexity
    editCar(): void {
        if (this.carForm.invalid) {
            console.error('form is not valid!');

            return;
        }

        if (
            this.car &&
            this.currentUser &&
            !this.car.deleted_date &&
            (this.car.created_by === this.currentUser.id ||
                this.permissionService.checkUserISCarAdmin(this.currentUser))
        ) {
            this.loadingCar = true;
            this.isDirty = false;

            let gasoline = false;
            let diesel = false;
            let electricity = false;
            let lpg = false;

            this.f.engine.value.forEach((engine: string) => {
                if (engine === 'gasoline') {
                    gasoline = true;
                }

                if (engine === 'diesel') {
                    diesel = true;
                }

                if (engine === 'electricity') {
                    electricity = true;
                }

                if (engine === 'lpg') {
                    lpg = true;
                }
            });

            const carData: CarInput = {
                id: this.car.id,
                owned_by: this.f.owned_by.value,
                company_id: this.f.company_id.value,
                name: this.f.name.value,
                bookable: this.f.bookable.value,
                diesel,
                gasoline,
                lpg,
                electricity,
                hybrid: this.f.engine.value.length > 1,
                recharge_time: this.f.recharge_time.value,
                updated_by: this.currentUser.id,
            };

            this.settingsService
                .updateCar(carData)
                ?.pipe(untilDestroyed(this))
                .subscribe({
                    next: () => {
                        const options = {progressBar: true, timeOut: 5000};

                        this.messages.showSuccess('Žádanka', 'úspěšně upravena', options);
                        this.dataService.setCarsDataSource();
                        // this.dataService.setCarDetailDataSource(data.id);
                        this.loadingCar = false;
                        this.isUpdate = false;
                        this.formDialogObj.hide();
                        this.ref.markForCheck();
                    },
                    error: error => {
                        console.error(error);

                        const body = 'Zkuste to znovu později';
                        const options = {progressBar: true, timeOut: 5000};

                        this.messages.showError('Chyba při úpravě žádosti', body, options);
                        this.loadingCar = false;
                        this.ref.markForCheck();
                    },
                });
        } else {
            const body = 'Nemáte oprávnění provést tuto akci...';
            const options = {progressBar: true, timeOut: 5000};

            this.messages.showError('Nedostatečné oprávnění', body, options);
            this.loadingCar = false;
            this.isUpdate = false;
            this.formDialogObj.hide();
            this.ref.markForCheck();
        }
    }

    showCarInputErrors(): void {
        this.isChecked = true;
        /* console.log('VALID: ' + this.ticketForm.valid);
      Object.keys(this.ticketForm.controls).forEach(key => {
          const controlErrors: ValidationErrors = this.ticketForm.get(key).errors;
          if (controlErrors != null) {
              Object.keys(controlErrors).forEach(keyError => {
                  console.log('Key control: ' + key + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError]);
              });
          }
      });*/
        this.carForm.markAllAsTouched();
        this.ref.markForCheck();
    }
}
