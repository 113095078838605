<ejs-dialog
  #deleteFileDialog
  [buttons]="buttonsDeleteFileDialog"
  [content]="'Opravdu chcete smazat tento soubor z importu hotline?'"
  [header]="'Smazat soubor z importu hotline'"
  [height]="'200px'"
  [isModal]="true"
  [showCloseIcon]="true"
  [visible]="false"
  [width]="'450px'"
  id="deleteFileDialog"
></ejs-dialog>
<div class="row">
  <div class="col-12 text-center">
    <button
      (click)="downloadSheet()"
      cssClass="e-btn e-success e-outline btn-p-zero e-small"
      ejs-button
    >
      <i aria-hidden="true" class="fa-light fa-file-download mr-1"></i>
      stáhnout šablonu pro import
    </button>
  </div>
  <form class="col-12 text-center">
    <ejs-uploader
      #preloadupload
      (beforeUpload)="beforeFileUpload($event)"
      (removing)="onFileRemove($event)"
      (selected)="onFileSelected($event)"
      (success)="onSuccessUpload($event)"
      (uploading)="onFileUpload($event)"
      [asyncSettings]="path"
      [dropArea]="dropElement"
      allowedExtensions=".xls,.xlsx"
      id="preloadfileupload"
      maxFileSize="400000000"
    >
    </ejs-uploader>
  </form>
</div>
