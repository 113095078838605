<ejs-dialog
  #formDialog
  (close)="
        isUpdate = false;
        isCreate = false;
        isDirty = false;
        isChecked = false;
        isCopy = false;
        submited = false;
        changeDialogState(false)
    "
  [isModal]="true"
  [showCloseIcon]="true"
  [visible]="isVisible"
  [width]="'85%'"
  id="formDialog"
>
  <form [formGroup]="companyForm">
    <ng-template #content>
      <div class="dialogContent">
        <div class="row">
          <div class="col-md-6 col-12 mb-3">
            <label
              [ngClass]="
                                f.name.valid
                                    ? 'text-success'
                                    : f.name.invalid
                                    ? 'text-danger'
                                    : 'text-muted'
                            "
              for="companyForm-name"
            >* Název společnosti:</label
            >
            <ejs-textbox
              [ngClass]="
                                f.name.invalid
                                    ? 'e-error'
                                    : f.name.valid
                                    ? 'e-success'
                                    : ''
                            "
              floatLabelType="Auto"
              formControlName="name"
              id="companyForm-name"
            ></ejs-textbox>
            <small
              *ngIf="
                                f.name.touched &&
                                f.name.errors &&
                                f.name.errors.required
                            "
              class="text-danger"
            >Název je povinný</small
            >
          </div>
          <div class="col-md-6 col-12 mb-3">
            <label
              [ngClass]="
                                f.address.valid
                                    ? 'text-success'
                                    : f.address.invalid
                                    ? 'text-danger'
                                    : 'text-muted'
                            "
              for="companyForm-address"
            >* Celá adresa:</label
            >
            <ejs-textbox
              [ngClass]="
                                f.address.invalid
                                    ? 'e-error'
                                    : f.address.valid
                                    ? 'e-success'
                                    : ''
                            "
              floatLabelType="Auto"
              formControlName="address"
              id="companyForm-address"
            ></ejs-textbox>
            <small
              *ngIf="
                                f.address.touched &&
                                f.address.errors &&
                                f.address.errors.required
                            "
              class="text-danger"
            >Adresa je povinná</small
            >
          </div>
          <div class="col-md-6 col-12 mb-3">
            <label
              [ngClass]="
                                f.ico.valid
                                    ? 'text-success'
                                    : f.ico.invalid
                                    ? 'text-danger'
                                    : 'text-muted'
                            "
              for="companyForm-ico"
            >* IČO:</label
            >
            <ejs-textbox
              [ngClass]="
                                f.ico.invalid
                                    ? 'e-error'
                                    : f.ico.valid
                                    ? 'e-success'
                                    : ''
                            "
              floatLabelType="Auto"
              formControlName="ico"
              id="companyForm-ico"
            ></ejs-textbox>
            <small
              *ngIf="
                                f.ico.touched &&
                                f.ico.errors &&
                                f.ico.errors.required
                            "
              class="text-danger"
            >IČO je povinné</small
            >
          </div>
          <div class="col-md-6 col-12 mb-3">
            <label
              [ngClass]="
                                f.ruian.valid
                                    ? 'text-success'
                                    : f.ruian.invalid
                                    ? 'text-danger'
                                    : 'text-muted'
                            "
              for="companyForm-ruian"
            >RUIAN adresního místa</label
            >
            <ejs-textbox
              [ngClass]="
                                f.ruian.invalid
                                    ? 'e-error'
                                    : f.ruian.valid
                                    ? 'e-success'
                                    : ''
                            "
              floatLabelType="Auto"
              formControlName="ruian"
              id="companyForm-ruian"
            ></ejs-textbox>
            <small
              *ngIf="
                                f.ruian.touched &&
                                f.ruian.errors &&
                                f.ruian.errors.required
                            "
              class="text-danger"
            >RUIAN je povinný</small
            >
          </div>
          <div class="col-12">
            <div class="e-card">
              <div class="card-header">
                Přidat oddělení do společnosti
                <button
                  (click)="addDepartment()"
                  cssClass="e-btn e-success btn-p-zero e-small mr-1 float-right"
                  ejs-button
                  type="button"
                >
                  Přidat oddělení
                </button>
              </div>
              <div
                class="e-card-content"
                formArrayName="companyDepartments"
              >
                <div
                  *ngFor="
                                        let department of departmentsformGroup.controls;
                                        let i = index
                                    "
                  class="col-12"
                >
                  <div [formGroupName]="i" class="row">
                    <div class="form-group col-8 col-md-10">
                      <div class="col-12 mb-2">
                        <label
                          [ngClass]="
                                                        getDepartmentsFormGroup(
                                                            i
                                                        ).controls[
                                                            'departmentName'
                                                        ].valid &&
                                                        (getDepartmentsFormGroup(
                                                            i
                                                        ).controls[
                                                            'departmentName'
                                                        ].dirty ||
                                                            getDepartmentsFormGroup(
                                                                i
                                                            ).controls[
                                                                'departmentName'
                                                            ].touched)
                                                            ? 'text-success active'
                                                            : getDepartmentsFormGroup(
                                                                  i
                                                              ).controls[
                                                                  'departmentName'
                                                              ].invalid
                                                            ? 'text-danger active'
                                                            : 'text-muted active'
                                                    "
                          for="department"
                        >* Název oddělení:</label
                        >
                        <ejs-dropdownlist
                          #department
                          (filtering)="
                                                        onFilteringDepartments(
                                                            $event
                                                        )
                                                    "
                          [allowFiltering]="true"
                          [cssClass]="
                                                        getDepartmentsFormGroup(
                                                            i
                                                        ).controls[
                                                            'departmentName'
                                                        ].invalid &&
                                                        (getDepartmentsFormGroup(
                                                            i
                                                        ).controls[
                                                            'departmentName'
                                                        ].dirty ||
                                                            getDepartmentsFormGroup(
                                                                i
                                                            ).controls[
                                                                'departmentName'
                                                            ].touched)
                                                            ? 'e-error'
                                                            : getDepartmentsFormGroup(
                                                                  i
                                                              ).controls[
                                                                  'departmentName'
                                                              ].valid &&
                                                              (getDepartmentsFormGroup(
                                                                  i
                                                              ).controls[
                                                                  'departmentName'
                                                              ].dirty ||
                                                                  getDepartmentsFormGroup(
                                                                      i
                                                                  ).controls[
                                                                      'departmentName'
                                                                  ].touched)
                                                            ? 'e-success'
                                                            : ''
                                                    "
                          [dataSource]="
                                                        departmentSelect
                                                    "
                          [fields]="fields"
                          [filterBarPlaceholder]="
                                                        'Vyhledat oddělení...'
                                                    "
                          [ignoreAccent]="true"
                          [placeholder]="
                                                        'Vyberte oddělení'
                                                    "
                          [popupHeight]="height"
                          formControlName="departmentName"
                          id="department"
                          sortOrder="Ascending"
                        ></ejs-dropdownlist>
                      </div>
                      <small
                        *ngIf="
                                                    getDepartmentsFormGroup(i)
                                                        .controls[
                                                        'departmentName'
                                                    ].errors &&
                                                    getDepartmentsFormGroup(
                                                        i
                                                    ).controls[
                                                        'departmentName'
                                                    ].hasError('required')
                                                "
                        class="text-danger"
                      >Vyberte jedno z oddělení, nebo
                        odeberte pole</small
                      >
                    </div>
                    <div
                      class="form-group col-4 col-md-2 text-right"
                    >
                      <button
                        (click)="removeDepartment(i)"
                        cssClass="e-btn e-danger btn-p-zero e-small"
                        ejs-button
                      >
                        Odebrat
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
    <ng-template #footerTemplate>
      <div class="d-flex justify-content-end">
                <span
                  *ngIf="companyForm.invalid"
                  class="e-badge e-badge-warning p-2 size_2"
                  role="alert"
                >
                    <strong>Formulář je špatně vyplněný</strong>
                </span>
        <span
          *ngIf="companyForm.valid"
          class="e-badge e-badge-success p-2 size_2"
          role="alert"
        >
                    <strong>Formulář je správně vyplněný</strong>
                </span>
      </div>
      <div class="row">
        <div class="col-12 pt-2">
          <button
            (click)="onSubmit()"
            [disabled]="
                            loadingCompany || companyForm.invalid || !isChecked
                        "
            cssClass="e-btn e-success btn-p-zero e-small mr-1"
            ejs-button
            type="submit"
          >
                        <span
                          *ngIf="loadingCompany === true"
                          aria-hidden="true"
                          class="spinner-grow spinner-grow-sm"
                          role="status"
                        ></span>
            <span *ngIf="loadingCompany === false">ULOŽIT</span>
            <span *ngIf="loadingCompany === true">Provádím...</span>
          </button>
          <button
            (click)="formDialogObj.hide()"
            cssClass="e-btn e-danger e-outline btn-p-zero e-small"
            ejs-button
            type="button"
          >
            ZRUŠIT
          </button>
        </div>
      </div>
    </ng-template>
  </form>
</ejs-dialog>
