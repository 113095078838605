import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';

import {AuthenticationService} from '../_services/authentication.service';
import {User} from '../_models/user/user';

@Injectable({
    providedIn: 'root',
})
export class JwtInterceptor implements HttpInterceptor {
    currentUserToken: boolean | string;

    constructor(private readonly authenticationService: AuthenticationService) {}

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        this.authenticationService.currentUser$.subscribe((user: User | null) => {
            if (user) {
                this.currentUserToken = user.token;
            } else {
                this.currentUserToken = false;
            }
        });

        if (this.currentUserToken) {
            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${this.currentUserToken as string}`,
                },
            });
        }

        return next.handle(request);
    }
}
