import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MessageService} from '@src/app/_services/message.service';
import {AuthenticationService, LoginData} from '@src/app/_services/authentication.service';
import {first} from 'rxjs/operators';
import {registerLocaleData} from '@angular/common';
import localeCs from '@angular/common/locales/cs';
import {DataService} from '@src/app/_services/data.service';
import {User} from '@src/app/_models/user/user';

registerLocaleData(localeCs);

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginComponent implements OnInit {
    loginForm: FormGroup;

    loading = false;

    submited = false;

    returnUrl: string;

    error: string;

    loadingAcs = false;

    currentUser: User | null;

    constructor(
        private readonly formBuilder: FormBuilder,
        private readonly route: ActivatedRoute,
        private readonly ref: ChangeDetectorRef,
        private readonly dataService: DataService,
        private readonly router: Router,
        private readonly authenticationService: AuthenticationService,
        private readonly messages: MessageService,
    ) {
    }

    get f(): {[key: string]: AbstractControl} {
        return this.loginForm.controls;
    }

    ngOnInit(): void {
        this.currentUser = this.authenticationService.currentUserValue;
        this.loginForm = this.formBuilder.group({
            username: ['', Validators.compose([Validators.required, Validators.email])],
            password: ['', Validators.required],
        });

        this.route.queryParams
            .subscribe(params => {
                this.returnUrl = params.returnUrl || this.route.snapshot.queryParams.returnUrl || '/';
            });

        if (this.currentUser) {
            void this.router.navigate([this.returnUrl]);
        }

        this.dataService.clearAllCache();
        this.ref.markForCheck();
    }

    onSubmit(): void {
        this.submited = true;

        if (this.loginForm.invalid) {
            console.error('form is not valid!');

            return;
        }

        this.loading = true;
        this.loadingAcs = true;
        this.authenticationService
            .login(this.f.username.value as string, this.f.password.value as string)
            .pipe(first())
            .subscribe(
                (user: LoginData | boolean) => {
                    if (user) {
                        const userData = user as LoginData;
                        const body = `${userData.data.firstname} ${userData.data.secondname}`;
                        const options = {
                            progressBar: true,
                            timeOut: 5000,
                            toastClass: 'blue',
                        };

                        this.messages.showSuccess('Uživatel úspěšně přihlášen', body, options);
                        this.loading = false;
                        this.loadingAcs = false;
                        console.info('INFO: navigate to homepage...');
                        void this.router.navigateByUrl(decodeURI(this.returnUrl));
                        this.ref.markForCheck();
                    } else {
                        const body = 'špatné jméno nebo heslo';
                        const options = {
                            progressBar: true,
                            timeOut: 5000,
                            toastClass: 'red',
                        };

                        this.messages.showError('Uživatel nepřihlášen', body, options);
                        this.loading = false;
                    }
                },
                error => {
                    this.error = error;
                    console.error(error);
                    this.loading = false;
                },
            );
    }
}
