import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import crypto from 'crypto-js';
import {map} from 'rxjs/operators';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Router} from '@angular/router';
import {CustomStorageService} from '@src/app/_services/custom-storage.service';
import {EnvironmentService} from '@src/app/_services/environment.service';
import {User} from '@src/app/_models/user/user';

export interface LoginData {
    data: User;
    token: string;
}

@Injectable({
    providedIn: 'root',
})
export class AuthenticationService {
    currentUserSubject$: BehaviorSubject<User | null> = new BehaviorSubject<User | null>(null);

    currentTokenSubject$: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);

    currentUser$ = this.currentUserSubject$.asObservable();

    currentToken$ = this.currentTokenSubject$.asObservable();

    constructor(
        private readonly storage: CustomStorageService,
        private readonly http: HttpClient,
        public router: Router,
        private readonly environmentService: EnvironmentService,
    ) {
        const sessionUser = this.storage.getItem('currentUser');
        const sessionToken = this.storage.getItem('token');

        let currentUser: User | null = null;
        let currentToken: string | null = null;

        if (this.storage.getItem('currentUser') && typeof sessionUser === 'string') {
            currentUser = JSON.parse(sessionUser);
        }

        if (this.storage.getItem('token') && typeof sessionToken === 'string') {
            currentToken = JSON.parse(sessionToken);
        }

        // logged-in from Session
        this.currentUserSubject$.next(currentUser);
        this.currentTokenSubject$.next(currentToken);
    }

    get isLoggedIn(): boolean {
        return !!this.currentUserValue && !!this.currentUserToken;
    }

    get currentUserValue(): User | null {
        return this.currentUserSubject$.getValue();
    }

    get currentUserToken(): string | null {
        return this.currentTokenSubject$.getValue();
    }

    login(username: string, password: string): Observable<LoginData | boolean> {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
        });
        const url = this.environmentService.backendURL + '/api/user/authenticate';

        return this.http
            .post<LoginData>(
                url,
                {
                    workemail: username.replace(/<\/?[^>]+(>|$)/g, '')
                        .trim(),
                    // eslint-disable-next-line max-len
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-call
                    password: crypto.SHA1(password)
                        .toString(crypto.enc.Hex),
                },
                {headers},
            )
            .pipe(
                map((user: LoginData) => {
                    if (typeof user !== 'undefined') {
                        console.info('success login');

                        const expire = new Date();

                        expire.setHours(expire.getHours() + 3);
                        this.storage.setItem('token', JSON.stringify(user.data.token));
                        this.storage.setItem('currentUser', JSON.stringify(user.data));
                        this.currentUserSubject$.next(user.data);
                        this.currentTokenSubject$.next(user.token);

                        return user;
                    }

                    return false;
                }),
            );
    }
}
