import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnInit,
    ViewChild,
} from '@angular/core';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {Subject} from 'rxjs';
import {FieldSettingsModel} from '@syncfusion/ej2-lists';
import {User} from '@src/app/_models/user/user';
import {TabComponent, ToolbarComponent} from '@syncfusion/ej2-angular-navigations';
import {DashboardLayoutComponent} from '@syncfusion/ej2-angular-layouts';
import {ListViewComponent} from '@syncfusion/ej2-angular-lists';
import {ChartComponent} from '@syncfusion/ej2-angular-charts';
import {AuthenticationService} from '@src/app/_services/authentication.service';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {NavHandlerService} from '@src/app/_services/nav-handler.service';
import {DataService} from '@src/app/_services/data.service';
import {MessageService} from '@src/app/_services/message.service';
import {TimeagoIntl} from 'ngx-timeago';
import {strings as czechStrings} from 'ngx-timeago/language-strings/cs';
import moment from 'moment/moment';
import {debounceTime} from 'rxjs/operators';
import {DataManager, Query} from '@syncfusion/ej2-data';
import {IAccTooltipRenderEventArgs} from '@syncfusion/ej2-charts';
import {SelectEventArgs} from '@syncfusion/ej2-navigations';
import {Office} from '@src/app/_models/office/office.model';
import {SettingsService} from '@src/app/features/settings/settings.service';
import {OFFICES_TYPE} from '@src/app/features/settings/types/office-type.enum';

@UntilDestroy()
@Component({
    selector: 'app-office',
    templateUrl: './office.component.html',
    styleUrls: ['./office.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OfficeComponent implements OnInit {
    // Filters
    private readonly subjectUsersFilter: Subject<string> = new Subject();

    private readonly subjectCategoriesFilter: Subject<string> = new Subject();

    // DashBoard
    count = 8;

    cellSpacing: number[] = [10, 10];

    cellAspectRatio: number = 100 / 100;

    // Navigations
    headerText = [{text: 'nástěnka'}, {text: 'tabulka'}];

    // Lists
    dataCategories: {
        [key: string]: Array<{[key: string]: number | string}> | number | string;
    }[] = [];

    dataUsers: {[key: string]: {[key: string]: number | string}[] | number | string}[] = [];

    headerTitleCategories = 'Kategorie kanceláří';

    headerTitleTags = 'Uživatelé kanceláří';

    fields: FieldSettingsModel = {
        iconCss: 'icon',
        tooltip: 'text',
        sortBy: 'text',
    };

    // Variables
    currentUser: User | null;

    offices: Office[] | undefined = [];

    bookableOfficesCount = 0;

    // Charts
    palette = ['#E94649', '#F6B53F', '#6FAAB0', '#C4C24A'];

    chartAnimation = {enable: true};

    primaryXAxis: {[key: string]: boolean | number | string | undefined};

    primaryYAxis: {[key: string]: boolean | number | string | undefined};

    chartDataShare: object;

    chartDataOpen: object;

    chartDataPrivate: object;

    chartDataMeeting: object;

    legendSettings: object;

    tooltip: object;

    datalabel: object;

    // Loaders
    loadingOffice = true;

    // Maintoolbar
    @ViewChild('moduleToolbar') moduleToolbarObj?: ToolbarComponent;

    // DashBoard
    @ViewChild('defaultDashboard', {static: false})
    dashboard?: DashboardLayoutComponent;

    // Navigations
    @ViewChild('officesTab') officesTabObj: TabComponent;

    // Lists
    @ViewChild('listviewUsers') listviewUsersObj: ListViewComponent;

    @ViewChild('listviewCategories') listviewCategoriesObj: ListViewComponent;

    // Charts
    @ViewChild('officesChart') officesChartObj?: ChartComponent;

    constructor(
        private readonly authenticationService: AuthenticationService,
        private readonly router: Router,
        private readonly ref: ChangeDetectorRef,
        private readonly navHandlerService: NavHandlerService,
        private readonly route: ActivatedRoute,
        private readonly dataService: DataService,
        private readonly settingsService: SettingsService,
        private readonly messages: MessageService,
        private readonly intl: TimeagoIntl,
    ) {
        this.currentUser = this.authenticationService.currentUserValue;
        this.intl.strings = czechStrings;
        this.intl.changes.next();
    }

    private loadData(): void {
        let chartShare = 0;
        let chartMeeting = 0;
        let chartPrivate = 0;
        let chartOpen = 0;

        this.offices = this.offices?.sort((a, b) => {
            const dateA = moment(a.created_date)
                .valueOf();
            const dateB = moment(b.created_date)
                .valueOf();

            return dateA > dateB ? -1 : 1;
        });

        const childOpen: {[key: string]: number | string}[] = [];
        const childShare: {[key: string]: number | string}[] = [];
        const childMeeting: {[key: string]: number | string}[] = [];
        const childPrivate: {[key: string]: number | string}[] = [];

        this.offices?.forEach((office: Office) => {
            if (office.type === OFFICES_TYPE.OPEN) {
                chartOpen++;
                childOpen.push({
                    id: `1-${office.id}`,
                    text: office.name,
                    icon: 'fa-light fa-lg fa-chair-office',
                    office_id: office.id,
                });
            }

            if (office.type === OFFICES_TYPE.SHARE) {
                chartShare++;
                childShare.push({
                    id: `2-${office.id}`,
                    text: office.name,
                    icon: 'fa-light fa-lg fa-chair-office',
                    office_id: office.id,
                });
            }

            if (office.type === OFFICES_TYPE.PRIVATE) {
                chartPrivate++;
                childPrivate.push({
                    id: `3-${office.id}`,
                    text: office.name,
                    icon: 'fa-light fa-lg fa-chair-office',
                    office_id: office.id,
                });
            }

            if (office.type === OFFICES_TYPE.MEETING) {
                chartMeeting++;
                childMeeting.push({
                    id: `4-${office.id}`,
                    text: office.name,
                    icon: 'fa-light fa-lg fa-chair-office',
                    office_id: office.id,
                });
            }

            if (office.bookable) {
                this.bookableOfficesCount++;
            }
        });

        this.dataCategories = [
            {
                id: 1,
                text: 'open-office',
                icon: 'fa-light fa-lg fa-th-list',
                child: childOpen,
            },
            {
                id: 2,
                text: 'share-office',
                icon: 'fa-light fa-lg fa-th-list',
                child: childShare,
            },
            {
                id: 3,
                text: 'soukromá kancelář',
                icon: 'fa-light fa-lg fa-th-list',
                child: childPrivate,
            },
            {
                id: 4,
                text: 'zasedačka',
                icon: 'fa-light fa-lg fa-th-list',
                child: childMeeting,
            },
        ];

        this.chartDataShare = [
            {
                state: 'Share-office',
                offices: chartShare,
                text: `sdílená kancelář: ${chartShare}`,
            },
        ];

        this.chartDataPrivate = [
            {
                state: 'Soukromá kancelář',
                offices: chartPrivate,
                text: `soukromá kancelář: ${chartPrivate}`,
            },
        ];

        this.chartDataMeeting = [
            {
                state: 'Zasedačka',
                offices: chartMeeting,
                text: `zasedačka: ${chartMeeting}`,
            },
        ];

        this.chartDataOpen = [
            {
                state: 'Open-office',
                offices: chartOpen,
                text: `open-office: ${chartOpen}`,
            },
        ];

        this.loadingOffice = false;
        this.ref.markForCheck();
    }

    ngOnInit(): void {
        this.primaryXAxis = {
            valueType: 'Category',
            edgeLabelPlacement: 'Shift',
            isInversed: true,
        };

        this.primaryYAxis = {
            minimum: 0,
            maximum: 200,
            labelFormat: '{value} kanceláří',
            rangePadding: 'None',
        };

        this.legendSettings = {
            visible: true,
        };

        this.tooltip = {
            enable: true,
            header: 'Kanceláří celkem',
        };
        this.datalabel = {visible: true};

        this.dataService.officesSource.pipe(untilDestroyed(this))
            .subscribe(
                (offices: Office[] | undefined) => {
                    this.offices = offices;
                    this.primaryYAxis.maximum = this.offices?.length;

                    if (this.offices && this.offices.length > 0) {
                        this.loadData();
                    }
                },
                error => {
                    console.error(error);
                },
            );

        this.navHandlerService.navState.pipe(untilDestroyed(this))
            .subscribe(
                () => {
                    this.refreshContent();
                },
                error => {
                    console.error(error);
                },
            );

        this.subjectUsersFilter.pipe(debounceTime(500))
            .subscribe(searchTextValue => {
                const data: {[key: string]: object}[] | DataManager | number[] | object[] | string[] =
                    new DataManager(this.dataUsers).executeLocal(
                        new Query().where('text', 'contains', searchTextValue, true, true),
                    );

                if (!searchTextValue) {
                    this.listviewUsersObj.dataSource = this.dataUsers.slice();
                } else {
                    (this.listviewUsersObj.dataSource as object[]) = data;
                }

                this.listviewUsersObj.dataBind();
            });

        this.subjectCategoriesFilter.pipe(debounceTime(500))
            .subscribe(searchTextValue => {
                const data: {[key: string]: object}[] | DataManager | number[] | object[] | string[] =
                    new DataManager(this.dataCategories).executeLocal(
                        new Query().where('text', 'contains', searchTextValue, true, true),
                    );

                if (!searchTextValue) {
                    this.listviewCategoriesObj.dataSource = this.dataCategories.slice();
                } else {
                    (this.listviewCategoriesObj.dataSource as object[]) = data;
                }

                this.listviewCategoriesObj.dataBind();
            });
    }

    onFilterUsers(event: KeyboardEvent): void {
        const target: EventTarget | null = event.target;

        this.subjectUsersFilter.next((target as HTMLInputElement).value);
    }

    onFilterCategories(event): void {
        const target: EventTarget | null = event.target;

        this.subjectCategoriesFilter.next((target as HTMLInputElement).value);
    }

    onCreate(): void {
        // Nastavení GUI podle URL parametrů
        this.route.queryParamMap.pipe(untilDestroyed(this))
            .subscribe(queryParams => {
                if (queryParams.get('view') === 'dashboard') {
                    this.officesTabObj.select(0);
                } else if (queryParams.get('view') === 'grid') {
                    this.officesTabObj.select(1);
                } else {
                    this.officesTabObj.select(0);
                }
            });
    }

    refreshContent(): void {
        setTimeout(() => {
            if (this.dashboard) {
                this.dashboard.refresh();
            }

            if (this.officesChartObj) {
                this.officesChartObj.refresh();
            }

            if (this.moduleToolbarObj) {
                this.moduleToolbarObj.refresh();
            }
        }, 1000);
    }

    tooltipRender(args: IAccTooltipRenderEventArgs): void {
        const value = args.point.y / args.series.sumOfPoints * 100;

        args.text = `${args.point.x as string} : ${Math.ceil(value)} %`;
    }

    onCloseIconHandler(event: MouseEvent): void {
        if (event.target === null) {
            return;
        }

        const elementOffset = (event.target as HTMLElement).offsetParent;

        if (elementOffset) {
            this.dashboard?.removePanel(elementOffset.id);
        }
    }

    preventSwipe(e: SelectEventArgs): void {
        if (e.isSwiped) {
            e.cancel = true;
        }
    }

    navigateToTab(view: string, form: string): void {
        const queryParams = {view, form};

        if (view === 'dashboard') {
            this.officesTabObj.select(0);
        } else if (view === 'grid') {
            this.officesTabObj.select(1);
        } else {
            this.officesTabObj.select(0);
        }

        void this.router.navigate([], {
            relativeTo: this.route,
            queryParams,
            queryParamsHandling: 'merge',
        });
    }

    select(e: SelectEventArgs): void {
        let queryParams: Params = {view: null};

        this.route.queryParamMap.pipe(untilDestroyed(this))
            .subscribe(params => {
                const form = params.get('form') === 'addOffice' ? 'addOffice' : null;

                if (e.selectedItem.textContent === 'nástěnka') {
                    queryParams = {
                        view: 'dashboard',
                        form,
                    };
                }

                if (e.selectedItem.textContent === 'tabulka') {
                    queryParams = {
                        view: 'grid',
                        form: null,
                    };
                }

                if (e.selectedItem.textContent === 'to-do') {
                    queryParams = {
                        view: 'todo',
                        form,
                    };
                }

                if (e.selectedItem.textContent === 'plánování') {
                    queryParams = {
                        view: 'sheduler',
                        form,
                    };
                }

                if (e.selectedItem.textContent === 'import') {
                    queryParams = {
                        view: 'import',
                        form,
                    };
                }

                void this.router.navigate([], {
                    relativeTo: this.route,
                    queryParams,
                    queryParamsHandling: 'merge',
                });
            });
    }
}
