import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    HostListener,
    Input,
    OnChanges,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import {
    AbstractControl,
    FormArray,
    FormBuilder,
    FormGroup,
    ValidationErrors,
    ValidatorFn,
    Validators,
} from '@angular/forms';
import {User} from '@src/app/_models/user/user';
import {ToolbarComponent, ToolbarModule} from '@syncfusion/ej2-angular-navigations';
import {DialogComponent} from '@syncfusion/ej2-angular-popups';
import {
    DropDownListComponent,
    FilteringEventArgs,
    MultiSelectComponent,
} from '@syncfusion/ej2-angular-dropdowns';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {DataService} from '@src/app/_services/data.service';
import {PermissionService} from '@src/app/_services/permission.service';
import {AuthenticationService} from '@src/app/_services/authentication.service';
import {MessageService} from '@src/app/_services/message.service';
import {Observable} from 'rxjs';
import moment, {MomentInput} from 'moment-business-days';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {addClass, Browser, createElement, EmitType, removeClass} from '@syncfusion/ej2-base';
import {Query} from '@syncfusion/ej2-data';
import {ComponentCanDeactivate} from '@src/app/_guards/changes.guard';
import {Booking} from '@src/app/_models/booking/book.model';
import {Car} from '@src/app/_models/cars/car.model';
import {BookInput} from '@src/app/features/booking/types/input.type';
import {BookingService} from '@src/app/features/booking/services/booking.service';
import {Office} from '@src/app/_models/office/office.model';
import {
    FontFamilyModel,
    ImageSettingsModel,
    QuickToolbarSettingsModel,
    RichTextEditorComponent,
} from '@syncfusion/ej2-angular-richtexteditor';
import {Department} from '@src/app/_models/department/department';
import {TicketCategory} from '@src/app/_models/ticket/ticket-category';
import {Tags} from '@src/app/_models/tags/tags';
import {TicketInput} from '@src/app/features/tickets/tickets.service';
import {Tickets} from '@src/app/_models/ticket/tickets';
import {Event} from '@src/app/_models/event/event';
import {CheckBoxComponent} from '@syncfusion/ej2-angular-buttons';
import {BaseModel} from '@src/app/_models/_base/base-model';
import {map} from 'rxjs/operators';
import {EventInput} from '@src/app/features/events/events.service';

@UntilDestroy()
@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'app-booking-form',
    templateUrl: './booking-form.component.html',
    styleUrls: ['./booking-form.component.scss'],
})
export class BookingFormComponent implements OnInit, OnChanges, ComponentCanDeactivate {
    // RTE
    maxLength = 15000;

    textArea: HTMLElement;

    insertImageSettings: ImageSettingsModel;

    fontFamily: FontFamilyModel = {
        items: [
            {
                text: 'Segoe UI',
                value: 'Segoe UI',
                command: 'Font',
                subCommand: 'FontName',
            },
            {
                text: 'Roboto',
                value: 'Roboto',
                command: 'Font',
                subCommand: 'FontName',
            },
            {
                text: 'Great vibes',
                value: 'Great Vibes,cursive',
                command: 'Font',
                subCommand: 'FontName',
            },
            {
                text: 'Noto Sans',
                value: 'Noto Sans',
                command: 'Font',
                subCommand: 'FontName',
            },
            {
                text: 'Impact',
                value: 'Impact,Charcoal,sans-serif',
                command: 'Font',
                subCommand: 'FontName',
            },
            {
                text: 'Tahoma',
                value: 'Tahoma,Geneva,sans-serif',
                command: 'Font',
                subCommand: 'FontName',
            },
        ],
    };

    tools: ToolbarModule = {
        type: 'MultiRow',
        items: [
            'Bold',
            'Italic',
            'Underline',
            'StrikeThrough',
            'FontName',
            'FontSize',
            'FontColor',
            'BackgroundColor',
            'LowerCase',
            'UpperCase',
            'SuperScript',
            'SubScript',
            '|',
            'Formats',
            'Alignments',
            'OrderedList',
            'UnorderedList',
            'Outdent',
            'Indent',
            '|',
            'CreateTable',
            'CreateLink',
            'Image',
            '|',
            'ClearFormat',
            'Print',
            'SourceCode',
            'FullScreen',
            '|',
            'Undo',
            'Redo',
        ],
    };

    quickToolbarSettings: QuickToolbarSettingsModel = {
        table: [
            'TableHeader',
            'TableRows',
            'TableColumns',
            'TableCell',
            '-',
            'BackgroundColor',
            'TableRemove',
            'TableCellVerticalAlign',
            'Styles',
        ],
    };

    pasteCleanupSettings: object = {
        prompt: true,
        plainText: false,
        keepFormat: false,
        deniedTags: ['a'],
        deniedAttrs: ['class', 'title', 'id'],
        allowedStyleProps: ['color', 'margin', 'font-size'],
    };

    // Dropdowns
    fields: object = {text: 'label', value: 'value'};

    height = '240px';

    carsSelect: Array<{value: boolean | number | string; label: string}> = [];

    officesSelect: Array<{value: boolean | number | string; label: string}> = [];

    usersSelect: Array<{value: boolean | number | string; label: string}> = [];

    departmentSelect: Array<{value: boolean | number | string; label: string}> = [];

    categorySelect: Array<{value: boolean | number | string; label: string}> = [];

    tagsAutocomplete: Array<{id: number; name: string}> = [];

    // Forms
    bookingForm: FormGroup;

    bookEventsList: FormArray;

    bookTicketsList: FormArray;

    emptyEvents = false;

    emptyTickets = false;

    isDirty = false;

    isChecked = false;

    submited = false;

    // Variables
    currentUser: User | null;

    today = new Date();

    todayLimit = new Date(moment()
        .add(30, 'minutes')
        .format('YYYY-MM-DD'));

    users: User[];

    cars: Car[] = [];

    offices: Office[] = [];

    bookings: Booking[] = [];

    eventItems?: Event[];

    events: EventInput[] = [];

    ticketItems?: Tickets[];

    tickets: TicketInput[] = [];

    limitDateStart: Date | string;

    limitDateEnd: Date | string;

    reservationType = 'office';

    // Loaders
    loadingBooking = false;

    @Input() booking: Booking | null = null;

    @Input() isCreate = false;

    @Input() isUpdate = false;

    @Input() isCopy = false;

    @Input() isVisible = false;

    @Input() clickedDate: string | null = null;

    @Output() readonly bookingFormDialogState = new EventEmitter<boolean>();

    // RTE
    @ViewChild('eventDescription') eventDescription?: RichTextEditorComponent;

    @ViewChild('mainTask') mainTaskObj?: RichTextEditorComponent;

    // Maintoolbar
    @ViewChild('dialogFooterToolbar') dialogFooterToolbarObj: ToolbarComponent;

    // Dialogs
    @ViewChild('formDialog') formDialogObj: DialogComponent;

    // CheckBoxes
    @ViewChild('attendCheckBox') attendCheckBoxObj?: CheckBoxComponent;

    // Dropdowns
    @ViewChild('carList') carListObj: DropDownListComponent;

    @ViewChild('officeList') officeListObj: DropDownListComponent;

    @ViewChild('category') categoryObj: DropDownListComponent;

    @ViewChild('departments') departmentsObj: MultiSelectComponent;

    @ViewChild('users') usersObj: MultiSelectComponent;

    constructor(
        private readonly route: ActivatedRoute,
        private readonly router: Router,
        private readonly ref: ChangeDetectorRef,
        private readonly formBuilder: FormBuilder,
        private readonly dataService: DataService,
        private readonly bookingService: BookingService,
        private readonly permissionService: PermissionService,
        private readonly authenticationService: AuthenticationService,
        private readonly messages: MessageService,
    ) {
        this.currentUser = this.authenticationService.currentUserValue;

        this.bookingForm = this.formBuilder.group({
            car_id: [null],
            office_id: [null],
            reason: ['', Validators.required],
            start: [null, Validators.required],
            end: [null, Validators.required],
            bookEvents: this.formBuilder.array([this.createEvent()]),
            bookTickets: this.formBuilder.array([this.createTicket()]),
        });

        this.bookEventsList = this.bookingForm.get('bookEvents') as FormArray;
        this.bookTicketsList = this.bookingForm.get('bookTickets') as FormArray;
    }

    get f(): {[key: string]: AbstractControl} {
        return this.bookingForm.controls;
    }

    get bookEventsFormGroup(): FormArray {
        return this.bookingForm.get('bookEvents') as FormArray;
    }

    get bookTicketsFormGroup(): FormArray {
        return this.bookingForm.get('bookTickets') as FormArray;
    }

    get inputStartDates(): Date {
        return this.clickedDate
            ? new Date(moment(this.clickedDate)
                .add(30, 'minutes')
                .toISOString())
            : new Date(moment()
                .add(30, 'minutes')
                .toISOString());
    }

    get inputEndDates(): Date {
        return this.clickedDate
            ? new Date(moment(this.clickedDate)
                .add(90, 'minutes')
                .toISOString())
            : new Date(moment()
                .add(90, 'minutes')
                .toISOString());
    }

    @HostListener('window:beforeunload')
    canDeactivate(): Observable<boolean> | boolean {
        return !this.isDirty;
    }

    lessThanDate(fieldDate: string): ValidatorFn {
        return (control: AbstractControl): {lessThan: {value: string}} | null => {
            const fieldToDateCompare = control.parent?.get(fieldDate);
            const endDate = control.value;
            const startDate = fieldToDateCompare?.value;
            const isLessThan =
                Number(moment(startDate as MomentInput)
                    .valueOf()) >
                Number(moment(endDate as MomentInput)
                    .valueOf());

            return isLessThan ? {lessThan: {value: control.value}} : null;
        };
    }

    greaterThanDate(fieldDate: string): ValidatorFn {
        return (control: AbstractControl): {greaterThan: {value: string}} | null => {
            const fieldToDateCompare = control.parent?.get(fieldDate);
            const startDate = control.value;
            const endDate = fieldToDateCompare?.value;
            const isGreaterThan =
                Number(moment(startDate as MomentInput)
                    .valueOf()) >
                Number(moment(endDate as MomentInput)
                    .valueOf());

            return isGreaterThan ? {greaterThan: {value: control.value}} : null;
        };
    }

    dateInPast(): ValidatorFn {
        return (control: AbstractControl): {isInThePast: {value: string}} | null => {
            const startDateInput = control.value;
            const isInThePast =
                Number(moment(startDateInput as MomentInput)
                    .valueOf()) <
                Number(moment()
                    .startOf('day')
                    .valueOf());

            return isInThePast &&
            !(
                this.currentUser &&
                (this.permissionService.checkUserISCarAdmin(this.currentUser) ||
                    this.currentUser.is_boss)
            )
                ? {isInThePast: {value: control.value}}
                : null;
        };
    }

    getBookEventsFormGroup(index): FormGroup {
        return this.bookEventsList.controls[index] as FormGroup;
    }

    getBookTicketFormGroup(index): FormGroup {
        return this.bookTicketsList.controls[index] as FormGroup;
    }

    ngOnInit(): void {
        // DatePicker limit values
        const startLimit =
            this.currentUser &&
            (this.permissionService.checkUserISCarAdmin(this.currentUser) ||
                this.currentUser.is_boss)
                ? moment()
                    .startOf('year')
                    .format('YYYY-MM-DD')
                : moment()
                    .format('YYYY-MM-DD');
        const endLimit = moment()
            .add(1, 'y')
            .format('YYYY-MM-DD');

        this.limitDateStart = new Date(moment(startLimit)
            .toISOString());
        this.limitDateEnd = new Date(moment(endLimit)
            .toISOString());
        this.ref.markForCheck();

        this.dataService.bookingsSource.pipe(untilDestroyed(this))
            .subscribe(
                (bookings: Booking[]) => {
                    this.bookings = bookings;
                },
                error => {
                    console.error(error);
                },
            );

        this.dataService.userSource.pipe(untilDestroyed(this))
            .subscribe(
                (users: User[]) => {
                    this.users = users;
                    this.usersSelect = [];
                    users.map((user: User) => {
                        if (
                            this.currentUser &&
                            user.authorized &&
                            !user.deleted_date &&
                            (user.id === this.currentUser.id ||
                                user.boss_id === this.currentUser.id ||
                                this.permissionService.checkUserISCarAdmin(this.currentUser))
                        ) {
                            this.usersSelect = [
                                ...this.usersSelect,
                                {
                                    value: user.id,
                                    label: user.secondname + ' ' + user.firstname,
                                },
                            ];
                        }
                    });
                    this.ref.markForCheck();
                },
                error => {
                    console.error(error);
                },
            );

        this.dataService.officesSource.pipe(untilDestroyed(this))
            .subscribe(
                (offices: Office[]) => {
                    this.offices = offices;
                    this.officesSelect = [];
                    offices.map((office: Office) => {
                        if (this.currentUser && office.bookable && !office.deleted_date) {
                            this.officesSelect = [
                                ...this.officesSelect,
                                {
                                    value: office.id,
                                    label: office.name,
                                },
                            ];
                        }
                    });
                    this.ref.markForCheck();
                },
                error => {
                    console.error(error);
                },
            );

        this.dataService.carsSource.pipe(untilDestroyed(this))
            .subscribe(
                (cars: Car[]) => {
                    this.cars = cars;
                    this.carsSelect = [];
                    cars.map((car: Car) => {
                        if (this.currentUser && car.bookable && !car.deleted_date) {
                            this.carsSelect = [
                                ...this.carsSelect,
                                {
                                    value: car.id,
                                    label: car.name,
                                },
                            ];
                        }
                    });
                    this.ref.markForCheck();
                },
                error => {
                    console.error(error);
                },
            );

        this.dataService.departmentSource.pipe(untilDestroyed(this))
            .subscribe({
                next: (data: Department[]) => {
                    this.departmentSelect = [];
                    data.map((department: Department) => {
                        this.departmentSelect = [
                            ...this.departmentSelect,
                            {
                                value: department.id,
                                label: `${department.company.name} - ${department.name}`,
                            },
                        ];
                    });
                },
                error: error => {
                    console.error(error);
                },
            });

        this.dataService.ticketCategoriesSource.pipe(untilDestroyed(this))
            .subscribe({
                next: (data: TicketCategory[]) => {
                    this.categorySelect = [];
                    data.map((category: TicketCategory) => {
                        this.categorySelect = [
                            ...this.categorySelect,
                            {
                                value: category.id,
                                label: `${category.name} | SLA: ${category.sla.duration} minut`,
                            },
                        ];
                    });
                },
                error: error => {
                    console.error(error);
                },
            });

        this.dataService.tagSource.pipe(untilDestroyed(this))
            .subscribe({
                next: (data: Tags[]) => {
                    this.tagsAutocomplete = [];
                    data.map((tag: Tags) => {
                        this.tagsAutocomplete = [
                            ...this.tagsAutocomplete,
                            {
                                id: tag.id,
                                name: tag.name,
                            },
                        ];
                    });
                },
                error: error => {
                    console.error(error);
                },
            });

        // DatePicker custom validators
        this.bookingForm.get('end')
            ?.addValidators(this.lessThanDate('start'));
        this.bookingForm.get('start')
            ?.addValidators(this.greaterThanDate('end'));
        this.bookingForm.get('start')
            ?.addValidators(this.dateInPast());

        this.bookingForm.valueChanges.pipe(untilDestroyed(this))
            .subscribe(() => {
                this.isDirty = this.bookingForm.dirty && this.bookingForm.touched;
                this.showBookingInputErrors();
            });
    }

    ngOnChanges(): void {
        if (this.isVisible) {
            const queryParams: Params = {form: null};

            void this.router.navigate([], {
                relativeTo: this.route,
                queryParams,
                queryParamsHandling: 'merge',
            });

            if (this.isUpdate && this.booking) {
                this.formDialogObj.header = `Rezervace #${this.booking.id} od ${this.booking.creator.fullname}`;
                this.fillTheForm();
                this.showBookingInputErrors();
            }

            if (this.isCreate) {
                this.bookingForm.reset();
                this.formDialogObj.header = 'Nová rezervace';
                this.fillTheForm();
            }

            if (this.isCopy) {
                this.bookingForm.reset();
                this.formDialogObj.header = 'Nová rezervace';
                this.fillTheForm();
                this.showBookingInputErrors();
            }
        }
    }

    changeDialogState(value: boolean): void {
        this.bookingFormDialogState.emit(value);
    }

    createEvent(event?: EventInput): FormGroup {
        return this.formBuilder.group({
            id: event?.id,
            start_at: [
                event?.start_at ? new Date(event.start_at) : this.inputStartDates,
                Validators.required,
            ],
            end_at: [
                event?.end_at ? new Date(event.end_at) : this.inputEndDates,
                Validators.required,
            ],
            subject: [event?.subject ? event.subject : '', Validators.required],
            required: [event?.required ? event.required : false],
            address: [event?.address ? event.address : ''],
            description: [event?.description ? event.description : ''],
            users: [event?.users ? event.users : null],
            departments: [event?.departments ? event.departments : null],
        });
    }

    createTicket(ticket?: TicketInput): FormGroup {
        return this.formBuilder.group({
            id: ticket?.id,
            subject: [
                ticket?.subject ? ticket.subject : '',
                Validators.compose([
                    Validators.required,
                    Validators.minLength(2),
                    Validators.maxLength(100),
                ]),
            ],
            maintask: [ticket?.maintask ? ticket.maintask : '', Validators.required],
            tags: [ticket?.tags ? ticket.tags : []],
            start_deadline: [
                ticket?.start_deadline ? new Date(ticket.start_deadline) : this.inputStartDates,
            ],
            deadline: [ticket?.deadline ? new Date(ticket.deadline) : this.inputEndDates],
            users: [null],
            departments: [null],
            category_id: [
                ticket?.category_id ? ticket.category_id : 1,
                Validators.compose([Validators.required]),
            ],
        });
    }

    addEvent(): void {
        this.bookEventsList.push(this.createEvent());
        this.emptyEvents = false;
        this.ref.markForCheck();
    }

    addTicket(): void {
        this.bookTicketsList.push(this.createTicket());
        this.emptyTickets = false;
        this.ref.markForCheck();
    }

    removeEvent(index: number): void {
        this.bookEventsList.removeAt(index);

        if (this.bookEventsList.value.length === 0) {
            const body = 'Událost nepřiřazena k rezervaci';
            const options = {progressBar: true, timeOut: 5000, toastClass: 'red'};

            this.messages.showError('Musíte vytvořit událost k rezervaci', body, options);
            this.emptyEvents = true;
            this.ref.markForCheck();
        }
    }

    removeTicket(index: number): void {
        this.bookTicketsList.removeAt(index);
    }

    onFilteringUsers: EmitType<FilteringEventArgs> = (e: FilteringEventArgs) => {
        if (e.text === '') {
            e.updateData(this.usersSelect);
        } else {
            let query: Query = new Query();

            query = e.text !== '' ? query.where('label', 'contains', e.text, true, true) : query;
            e.updateData(this.usersSelect, query);
        }
    };

    onFilteringCars: EmitType<FilteringEventArgs> = (e: FilteringEventArgs) => {
        if (e.text === '') {
            e.updateData(this.carsSelect);
        } else {
            let query: Query = new Query();

            query = e.text !== '' ? query.where('label', 'contains', e.text, true, true) : query;
            e.updateData(this.carsSelect, query);
        }
    };

    onFilteringOffices: EmitType<FilteringEventArgs> = (e: FilteringEventArgs) => {
        if (e.text === '') {
            e.updateData(this.officesSelect);
        } else {
            let query: Query = new Query();

            query = e.text !== '' ? query.where('label', 'contains', e.text, true, true) : query;
            e.updateData(this.officesSelect, query);
        }
    };

    onFilteringDepartments: EmitType<FilteringEventArgs> = (e: FilteringEventArgs) => {
        if (e.text === '') {
            e.updateData(this.departmentSelect);
        } else {
            let query = new Query();

            query = e.text !== '' ? query.where('label', 'contains', e.text, true) : query;
            e.updateData(this.departmentSelect, query);
        }
    };

    onFilteringCategories: EmitType<FilteringEventArgs> = (e: FilteringEventArgs) => {
        if (e.text === '') {
            e.updateData(this.categorySelect);
        } else {
            let query = new Query();

            query = e.text !== '' ? query.where('label', 'contains', e.text, true) : query;
            e.updateData(this.categorySelect, query);
        }
    };

    onRenderCell(args): void {
        if (args.date.getDay() === 0 || args.date.getDay() === 6) {
            args.isDisabled = true;
        }
    }

    fillTheForm(): void {
        this.bookingForm.controls.start.patchValue(
            this.booking ? new Date(moment(this.booking.start)
                .toISOString()) : this.inputStartDates,
        );

        this.bookingForm.controls.end.patchValue(
            this.booking ? new Date(moment(this.booking.end)
                .toISOString()) : this.inputEndDates,
        );
        this.bookingForm.controls.car_id.patchValue(this.booking ? this.booking.car_id : null);
        this.bookingForm.controls.office_id.patchValue(
            this.booking ? this.booking.office_id : null,
        );
        this.bookingForm.controls.reason.patchValue(this.booking ? this.booking.reason : '');

        this.bookEventsList = this.bookingForm.get('bookEvents') as FormArray;
        this.bookTicketsList = this.bookingForm.get('bookTickets') as FormArray;

        this.getBookEventsFormGroup(0)
            .controls
            .start_at
            .setValue(this.inputStartDates, {
                onlySelf: true,
                emitEvent: false,
            });

        this.getBookEventsFormGroup(0)
            .controls
            .end_at
            .setValue(this.inputEndDates, {
                onlySelf: true,
                emitEvent: false,
            });

        if (this.getBookTicketFormGroup(0)) {
            this.getBookTicketFormGroup(0)
                .controls
                .start_deadline
                .setValue(this.inputStartDates, {
                    onlySelf: true,
                    emitEvent: false,
                });

            this.getBookTicketFormGroup(0)
                .controls
                .deadline
                .setValue(this.inputEndDates, {
                    onlySelf: true,
                    emitEvent: false,
                });
        }

        while (this.bookTicketsList.length > 0) {
            this.bookTicketsList.removeAt(0);
        }

        while (
            this.bookEventsList.length > 0 &&
            this.booking?.events.length &&
            this.booking.events.length > 0 &&
            this.isUpdate
            ) {
            this.bookEventsList.removeAt(0);
        }

        this.events = [];
        this.tickets = [];

        const users: number[] = [];
        const departments: number[] = [];

        this.booking?.events.map((event: Event) => {
            this.events.push({
                id: event.id,
                start_at: this.booking
                    ? moment(this.booking.start)
                        .toISOString()
                    : this.inputStartDates.toISOString(),
                end_at: this.booking
                    ? moment(this.booking.end)
                        .toISOString()
                    : this.inputEndDates.toISOString(),
                subject: event.subject,
                required: event.required,
                address: event.address,
                description: event.description,
                users,
                departments,
            });
        });

        this.booking?.tickets.map((ticket: Tickets) => {
            this.tickets.push({
                id: ticket.id,
                subject: ticket.subject,
                maintask: ticket.maintask,
                tags: ticket.tags,
                start_deadline: ticket.start_deadline
                    ? moment(ticket.start_deadline)
                        .toISOString()
                    : null,
                deadline: ticket.deadline ? moment(ticket.deadline)
                    .toISOString() : null,
                users: ticket.users,
                departments: ticket.departments,
                category_id: ticket.category_id,
            });
        });

        this.events.forEach(event => {
            this.bookEventsList.push(this.createEvent(event));
        });

        this.tickets.forEach(ticket => {
            this.bookTicketsList.push(this.createTicket(ticket));
        });

        if (this.events && this.events.length > 0) {
            this.bookingForm.get('bookEvents')
                ?.setValue(
                    this.events ?? {
                        start_at: this.inputStartDates,
                        end_at: this.inputEndDates,
                    },
                    {
                        onlySelf: true,
                        emitEvent: false,
                    },
                );
        }

        if (this.tickets && this.tickets.length > 0) {
            this.bookingForm.get('bookTickets')
                ?.setValue(
                    this.tickets ?? {
                        start_at: this.inputStartDates,
                        end_at: this.inputEndDates,
                    },
                    {
                        onlySelf: true,
                        emitEvent: false,
                    },
                );
        }
    }

    onSubmit(): void {
        this.submited = true;
        this.isDirty = false;
        this.loadingBooking = true;

        if (this.isCreate || this.isCopy) {
            this.addBooking();
        }

        if (this.isUpdate) {
            this.editBooking();
        }
    }

    addBooking(): void {
        if (this.bookingForm.invalid) {
            console.error('form is not valid!');

            return;
        }

        if (!this.f.car_id.value && !this.f.office_id.value) {
            const options = {progressBar: true, timeOut: 5000};

            this.messages.showError(
                'Chyba při vytváření rezervace',
                'vyberte vozidlo nebo kancelář',
                options,
            );
            this.loadingBooking = false;
            this.ref.markForCheck();
        }

        this.eventItems = this.bookEventsList.value;
        this.ticketItems = this.bookTicketsList.value;

        this.eventItems?.forEach((item: Event, index) => {
            if (this.eventItems && this.currentUser) {
                this.eventItems[index].created_by = this.currentUser.id;
                this.eventItems[index].required = this.eventItems[index].required ?? false;
            }
        });

        this.ticketItems?.forEach((item: Tickets, index) => {
            if (this.ticketItems && this.currentUser) {
                this.ticketItems[index].created_by = this.currentUser.id;
            }
        });

        const bookingData: BookInput = {
            reason: this.f.reason.value,
            car_id: this.f.car_id.value,
            office_id: this.f.office_id.value,
            start: this.f.start.value,
            end: this.f.end.value,
            created_by: this.currentUser?.id,
            ticketitems: this.ticketItems,
            eventitems: this.eventItems,
        };

        this.bookingService
            .addBooking(bookingData)
            .pipe(
                untilDestroyed(this),
                map((response: BaseModel) => response.data[0] as Booking),
            )
            .subscribe(
                (data: Booking) => {
                    const body = 'přidána do kalendáře';
                    const options = {progressBar: true, timeOut: 5000};

                    this.messages.showSuccess('Rezervace úspěšně vytvořena', body, options);
                    this.dataService.clearBooksCache();
                    this.dataService.clearEventCache();
                    this.dataService.clearTicketsCache();
                    this.dataService.clearTagsCache();
                    this.dataService.setBookingsDataSource();
                    this.loadingBooking = false;
                    this.isCreate = false;
                    this.formDialogObj.hide();
                    this.ref.markForCheck();
                    void this.router.navigate(['/booking/detail', data.id]);
                },
                error => {
                    console.error(error);

                    const body = 'Zkuste to znovu později';
                    const options = {
                        progressBar: true,
                        timeOut: 5000,
                        toastClass: 'red',
                    };

                    this.messages.showError('Chyba při vytváření rezervace', body, options);
                    this.loadingBooking = false;
                    this.ref.markForCheck();
                },
            );
    }

    copyBooking(): void {
        this.isCreate = true;
        this.isUpdate = false;
        this.bookingForm.reset();
        this.formDialogObj.header = 'Nová rezervace';
        this.fillTheForm();
        this.showBookingInputErrors();
        this.ref.markForCheck();
        this.formDialogObj.show();
    }

    // eslint-disable-next-line complexity
    editBooking(): void {
        if (this.bookingForm.invalid) {
            console.error('form is not valid!');

            return;
        }

        if (!this.f.car_id.value && !this.f.office_id.value) {
            const options = {progressBar: true, timeOut: 5000};

            this.messages.showError(
                'Chyba při vytváření rezervace',
                'vyberte vozidlo nebo kancelář',
                options,
            );
            this.loadingBooking = false;
            this.ref.markForCheck();
        }

        this.eventItems = this.bookEventsList.value;
        this.ticketItems = this.bookTicketsList.value;

        this.eventItems?.forEach((item: Event, index) => {
            if (this.eventItems && this.currentUser) {
                this.eventItems[index].updated_by = this.currentUser.id;
                this.eventItems[index].id = item.id;
                this.eventItems[index].required = this.eventItems[index].required ?? false;
            }
        });

        this.ticketItems?.forEach((item: Tickets, index) => {
            if (this.ticketItems && this.currentUser) {
                this.ticketItems[index].id = item.id;
                this.ticketItems[index].updated_by = this.currentUser.id;
            }
        });

        const check = moment(this.f.start.value as MomentInput)
            .format('YYYY-MM-DD');
        const today = moment()
            .format('YYYY-MM-DD');

        if (
            this.booking &&
            this.currentUser &&
            ((check >= today &&
                    !this.booking.deleted_date &&
                    this.booking.created_by === this.currentUser.id) ||
                (!this.booking.deleted_date &&
                    (this.permissionService.checkUserISCarAdmin(this.currentUser) ||
                        this.booking.creator.boss_id === this.currentUser.id)))
        ) {
            this.loadingBooking = true;
            this.isDirty = false;

            const bookingData: BookInput = {
                id: this.booking.id,
                car_id: this.f.car_id.value,
                office_id: this.f.office_id.value,
                reason: this.f.reason.value,
                start: this.f.start.value,
                end: this.f.end.value,
                updated_by: this.currentUser.id,
                ticketitems: this.ticketItems,
                eventitems: this.eventItems,
            };

            this.bookingService
                .updateBooking(bookingData)
                ?.pipe(untilDestroyed(this))
                .subscribe({
                    next: () => {
                        const options = {progressBar: true, timeOut: 5000};

                        this.messages.showSuccess('Rezervace', 'úspěšně upravena', options);
                        this.dataService.clearBooksCache();
                        this.dataService.clearEventCache();
                        this.dataService.clearTicketsCache();
                        this.dataService.clearTagsCache();
                        this.dataService.setBookingsDataSource();
                        this.loadingBooking = false;
                        this.isUpdate = false;
                        this.formDialogObj.hide();
                        this.ref.markForCheck();
                    },
                    error: error => {
                        console.error(error);

                        const body = 'Zkuste to znovu později';
                        const options = {progressBar: true, timeOut: 5000};

                        this.messages.showError('Chyba při úpravě rezervace', body, options);
                        this.loadingBooking = false;
                        this.ref.markForCheck();
                    },
                });
        } else {
            const body = 'Nemáte oprávnění provést tuto akci...';
            const options = {progressBar: true, timeOut: 5000};

            this.messages.showError('Nedostatečné oprávnění', body, options);
            this.loadingBooking = false;
            this.isUpdate = false;
            this.formDialogObj.hide();
            this.ref.markForCheck();
        }
    }

    setValueOfCheckBox(index: number): void {
        if (this.attendCheckBoxObj) {
            this.attendCheckBoxObj.checked = false;
        }

        this.getBookEventsFormGroup(index)
            .controls
            .required
            .setValue(false);
    }

    fillSuggestedDate(): void {
        // DatePicker initial values
        const endToday = moment(this.f.start.value as string)
            .add(30, 'minutes')
            .format('YYYY-MM-DD HH:mm');

        this.bookingForm.controls.end.patchValue(
            this.booking
                ? new Date(moment(this.booking.end)
                    .toISOString())
                : new Date(moment(endToday)
                    .toISOString()),
        );
    }

    toggleReservationType(type: string): void {
        this.reservationType = type;

        if (this.reservationType === 'office') {
            this.f.car_id.setValue(null);
        } else {
            this.f.office_id.setValue(null);
        }

        this.ref.markForCheck();
    }

    // RTE
    onCreateEventRte(): void {
        this.eventDescription?.refreshUI();
        this.eventDescription?.autoResize();
    }

    handleFullScreenEvent(e: unknown): void {
        const sbCntEle: HTMLElement | null = document.querySelector('.sb-content.e-view');
        const sbHdrEle: HTMLElement | null = document.querySelector('.sb-header.e-view');
        const leftBar: HTMLElement | null = document.querySelector('#left-sidebar');

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        if (e.targetItem === 'Maximize') {
            if (Browser.isDevice && Browser.isIos && sbCntEle && sbHdrEle) {
                addClass([sbCntEle, sbHdrEle], ['hide-header']);
            }

            if (leftBar) {
                addClass([leftBar], ['e-close']);
                removeClass([leftBar], ['e-open']);
            }
        }
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        else if (e.targetItem === 'Minimize') {
            if (Browser.isDevice && Browser.isIos && sbCntEle && sbHdrEle) {
                removeClass([sbCntEle, sbHdrEle], ['hide-header']);
            }

            if (leftBar) {
                removeClass([leftBar], ['e-close']);

                if (!Browser.isDevice) {
                    addClass([leftBar], ['e-open']);
                }
            }
        }
    }

    mirrorConversionEvent(e: unknown): void {
        if (this.eventDescription) {
            const id = `${this.eventDescription.getID()}mirror-view`;
            let mirrorView: HTMLElement | null = this.eventDescription.element.querySelector(
                `#${id}`,
            );
            const charCount: HTMLElement | null =
                this.eventDescription.element.querySelector('.e-rte-character-count');

            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            if (e.targetItem === 'Preview' && mirrorView && charCount) {
                this.textArea.style.display = 'block';
                mirrorView.style.display = 'none';
                // eslint-disable-next-line max-len
                // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-member-access
                charCount.style.display = 'block';
            } else {
                if (!mirrorView && this.textArea.parentNode) {
                    mirrorView = createElement('div', {className: 'e-content'});
                    mirrorView.id = id;
                    this.textArea.parentNode.appendChild(mirrorView);
                } else if (mirrorView) {
                    mirrorView.innerHTML = '';
                }

                if (mirrorView) {
                    this.textArea.style.display = 'none';
                    mirrorView.style.display = 'block';
                }

                if (charCount) {
                    charCount.style.display = 'none';
                }
            }
        }
    }

    onCreateTicketRte(): void {
        this.mainTaskObj?.refreshUI();
        this.mainTaskObj?.autoResize();
    }

    handleFullScreenTicket(e: unknown): void {
        const sbCntEle: HTMLElement | null = document.querySelector('.sb-content.e-view');
        const sbHdrEle: HTMLElement | null = document.querySelector('.sb-header.e-view');
        const leftBar: HTMLElement | null = document.querySelector('#left-sidebar');

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        if (e.targetItem === 'Maximize') {
            if (Browser.isDevice && Browser.isIos && sbCntEle && sbHdrEle) {
                addClass([sbCntEle, sbHdrEle], ['hide-header']);
            }

            if (leftBar) {
                addClass([leftBar], ['e-close']);
                removeClass([leftBar], ['e-open']);
            }
        }
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        else if (e.targetItem === 'Minimize') {
            if (Browser.isDevice && Browser.isIos && sbCntEle && sbHdrEle) {
                removeClass([sbCntEle, sbHdrEle], ['hide-header']);
            }

            if (leftBar) {
                removeClass([leftBar], ['e-close']);

                if (!Browser.isDevice) {
                    addClass([leftBar], ['e-open']);
                }
            }
        }
    }

    mirrorConversionTicket(e: unknown): void {
        if (this.mainTaskObj) {
            const id = `${this.mainTaskObj.getID()}mirror-view`;
            let mirrorView: HTMLElement | null = this.mainTaskObj.element.querySelector(`#${id}`);
            const charCount: HTMLElement | null =
                this.mainTaskObj.element.querySelector('.e-rte-character-count');

            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            if (e.targetItem === 'Preview' && mirrorView && charCount) {
                this.textArea.style.display = 'block';
                mirrorView.style.display = 'none';
                // eslint-disable-next-line max-len
                // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-member-access
                charCount.style.display = 'block';
            } else {
                if (!mirrorView && this.textArea.parentNode) {
                    mirrorView = createElement('div', {className: 'e-content'});
                    mirrorView.id = id;
                    this.textArea.parentNode.appendChild(mirrorView);
                } else if (mirrorView) {
                    mirrorView.innerHTML = '';
                }

                if (mirrorView) {
                    this.textArea.style.display = 'none';
                    mirrorView.style.display = 'block';
                }

                if (charCount) {
                    charCount.style.display = 'none';
                }
            }
        }
    }

    actionCompleteHandlerEvent(e: unknown): void {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        if (e.targetItem && (e.targetItem === 'SourceCode' || e.targetItem === 'Preview')) {
            (
                this.eventDescription?.sourceCodeModule.getPanel() as HTMLTextAreaElement
            ).style.display = 'none';
            this.mirrorConversionEvent(e);
        } else {
            setTimeout(() => {
                this.eventDescription?.toolbarModule.refreshToolbarOverflow();
            }, 400);
        }
    }

    actionCompleteHandlerTicket(e: unknown): void {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        if (e.targetItem && (e.targetItem === 'SourceCode' || e.targetItem === 'Preview')) {
            (this.mainTaskObj?.sourceCodeModule.getPanel() as HTMLTextAreaElement).style.display =
                'none';
            this.mirrorConversionTicket(e);
        } else {
            setTimeout(() => {
                this.mainTaskObj?.toolbarModule.refreshToolbarOverflow();
            }, 400);
        }
    }

    showBookingInputErrors(): void {
        this.isChecked = true;
        /* console.log('VALID: ' + this.bookingForm.valid);
         Object.keys(this.bookingForm.controls).forEach(key => {
             const controlErrors: ValidationErrors | null | undefined =
                 this.bookingForm.get(key)?.errors;

             if (controlErrors) {
                 Object.keys(controlErrors).forEach(keyError => {
                     console.log(
                         'Key control: ' + key + ', keyError: ' + keyError + ', err value: ',
                         controlErrors[keyError]
                     );
                 });
             }
         });

         Object.keys(this.bookTicketsFormGroup).forEach(key => {
             const controlErrors: ValidationErrors | null | undefined =
                 this.bookTicketsFormGroup.get(key)?.errors;

             if (controlErrors) {
                 Object.keys(controlErrors).forEach(keyError => {
                     console.log(
                         'Key control: ' + key + ', keyError: ' + keyError + ', err value: ',
                         controlErrors[keyError]
                     );
                 });
             }
         });

         Object.keys(this.bookEventsFormGroup).forEach(key => {
             const controlErrors: ValidationErrors | null | undefined =
                 this.bookEventsFormGroup.get(key)?.errors;

             if (controlErrors) {
                 Object.keys(controlErrors).forEach(keyError => {
                     console.log(
                         'Key control: ' + key + ', keyError: ' + keyError + ', err value: ',
                         controlErrors[keyError]
                     );
                 });
             }
         });*/
        this.bookingForm.markAllAsTouched();
        this.ref.markForCheck();
    }
}
