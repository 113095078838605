import {UntilDestroy} from '@ngneat/until-destroy';
import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    HostListener,
    Input,
    Output,
} from '@angular/core';
import {ComponentCanDeactivate} from '@src/app/_guards/changes.guard';
import {Observable} from 'rxjs';
import {Address} from '@src/app/_models/services/address';
import {HardwareConfig} from '@src/app/_models/services/hardware-config';

@UntilDestroy()
@Component({
    selector: 'app-callwizard-hw',
    templateUrl: './cw-hardware.component.html',
    styleUrls: ['./cw-hardware.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CallwizardHardwareComponent implements ComponentCanDeactivate {
    isDirty = false;

    @Input() address: Address | null = null;

    @Input() skip = false;

    @Output() readonly hwService = new EventEmitter<HardwareConfig>();

    @HostListener('window:beforeunload')
    canDeactivate(): Observable<boolean> | boolean {
        return !this.isDirty;
    }
}
