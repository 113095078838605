import {
    AfterViewChecked,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnInit,
    ViewChild,
} from '@angular/core';
import {Hotliner} from '@src/app/_models/hotline/hotliner';
import {FormBuilder} from '@angular/forms';
import {Company} from '@src/app/_models/company/company';
import {Observable, Observer} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {DataService} from '@src/app/_services/data.service';
import {MessageService} from '@src/app/_services/message.service';
import {Hotline} from '@src/app/_models/hotline/hotline';
import {AuthenticationService} from '@src/app/_services/authentication.service';
import {HotlineReport} from '@src/app/_models/hotline/report';
import {User} from '@src/app/_models/user/user';
import {PermissionService} from '@src/app/_services/permission.service';
import {ButtonPropsModel, DialogComponent} from '@syncfusion/ej2-angular-popups';
import {
    DataResult,
    EditSettingsModel,
    ExcelExportProperties,
    FilterSettingsModel,
    GridComponent,
    GroupSettingsModel,
    PageSettingsModel,
    RowDataBoundEventArgs,
    SelectionSettingsModel,
    ToolbarItems,
} from '@syncfusion/ej2-angular-grids';
import {
    ChangeEventArgs,
    DropDownListComponent,
    FilteringEventArgs,
} from '@syncfusion/ej2-angular-dropdowns';
import {EmitType} from '@syncfusion/ej2-base';
import {Query} from '@syncfusion/ej2-data';
import {ClickEventArgs, ToolbarModule} from '@syncfusion/ej2-angular-navigations';

import {registerLocaleData} from '@angular/common';
import moment from 'moment-business-days';
import localeCs from '@angular/common/locales/cs';
import {
    FontFamilyModel,
    ImageSettingsModel,
    QuickToolbarSettingsModel,
} from '@syncfusion/ej2-angular-richtexteditor';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {EnvironmentService} from '@src/app/_services/environment.service';
import {TextBoxComponent} from '@syncfusion/ej2-angular-inputs';
import convert from 'xml-js';
import {ExportHotline, HotlineService} from '@src/app/features/hotline/hotline.service';

moment.updateLocale('cs', {workingWeekdays: [1, 2, 3, 4, 5]});
registerLocaleData(localeCs);

@UntilDestroy()
@Component({
    selector: 'app-hotline-list',
    templateUrl: './hotline-list.component.html',
    styleUrls: ['./hotline-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HotlineListComponent implements OnInit, AfterViewChecked {
    // RTE
    maxLength = 15000;

    textArea: HTMLElement;

    insertImageSettings: ImageSettingsModel;

    fontFamily: FontFamilyModel = {
        items: [
            {
                text: 'Segoe UI',
                value: 'Segoe UI',
                command: 'Font',
                subCommand: 'FontName',
            },
            {
                text: 'Roboto',
                value: 'Roboto',
                command: 'Font',
                subCommand: 'FontName',
            },
            {
                text: 'Great vibes',
                value: 'Great Vibes,cursive',
                command: 'Font',
                subCommand: 'FontName',
            },
            {
                text: 'Noto Sans',
                value: 'Noto Sans',
                command: 'Font',
                subCommand: 'FontName',
            },
            {
                text: 'Impact',
                value: 'Impact,Charcoal,sans-serif',
                command: 'Font',
                subCommand: 'FontName',
            },
            {
                text: 'Tahoma',
                value: 'Tahoma,Geneva,sans-serif',
                command: 'Font',
                subCommand: 'FontName',
            },
        ],
    };

    tools: ToolbarModule = {
        type: 'MultiRow',
        items: [
            'Bold',
            'Italic',
            'Underline',
            'StrikeThrough',
            'FontName',
            'FontSize',
            'FontColor',
            'BackgroundColor',
            'LowerCase',
            'UpperCase',
            'SuperScript',
            'SubScript',
            '|',
            'Formats',
            'Alignments',
            'OrderedList',
            'UnorderedList',
            'Outdent',
            'Indent',
            '|',
            'CreateTable',
            'CreateLink',
            'Image',
            '|',
            'ClearFormat',
            'Print',
            'SourceCode',
            'FullScreen',
            '|',
            'Undo',
            'Redo',
        ],
    };

    quickToolbarSettings: QuickToolbarSettingsModel = {
        table: [
            'TableHeader',
            'TableRows',
            'TableColumns',
            'TableCell',
            '-',
            'BackgroundColor',
            'TableRemove',
            'TableCellVerticalAlign',
            'Styles',
        ],
    };

    pasteCleanupSettings: object = {
        prompt: true,
        plainText: false,
        keepFormat: false,
        deniedTags: ['a'],
        deniedAttrs: ['class', 'title', 'id'],
        allowedStyleProps: ['color', 'margin', 'font-size'],
    };

    // Grid
    sortOptions: object;

    currencyFormat: {[key: string]: string} = {format: '#,###.00\',- CZK\''};

    dateFormat: string;

    toolbar: ToolbarItems[] | object;

    data: object;

    ruianFieldObj: object = {text: 'label', value: 'value'};

    filterSettings: FilterSettingsModel;

    selectionSettings: SelectionSettingsModel;

    editSettings: EditSettingsModel;

    groupOptions: GroupSettingsModel;

    pageOptions: PageSettingsModel;

    filterDate: object;

    requiredRules: object;

    exportCurrentPage = false;

    // Dialogs
    renderHotlineFormDialog = false;

    renderHotlineReportFormDialog = false;

    buttonsDeleteHotlineDialog: ButtonPropsModel[] = [
        {
            click: this.removeHotline.bind(this),
            isFlat: false,
            buttonModel: {
                content: 'OK',
                cssClass: 'e-success e-outline',
            },
        },
        {
            click: (): void => {
                this.deleteHotlineDialog.hide();
            },
            buttonModel: {content: 'ZRUŠIT', cssClass: 'e-danger e-outline'},
        },
    ];

    buttonsActivateHotlineDialog: ButtonPropsModel[] = [
        {
            click: this.reactiveHotline.bind(this),
            isFlat: false,
            buttonModel: {
                content: 'OK',
                cssClass: 'e-success e-outline',
            },
        },
        {
            click: (): void => {
                this.activateHotlineDialog.hide();
            },
            buttonModel: {content: 'ZRUŠIT', cssClass: 'e-danger e-outline'},
        },
    ];

    buttonsCancel: ButtonPropsModel[] = [
        {
            click: (): void => {
                this.confirmationCancel.hide();
            },
            buttonModel: {
                isPrimary: true,
                content: 'OK',
            },
        },
    ];

    // Dropdowms
    height = '240px';

    fields: object = {text: 'label', value: 'value'};

    hotlineSelect: Array<{value: boolean | number | string; label: string}> = [];

    creatorSelect: Array<{value: boolean | number | string; label: string}> = [];

    categoryFilterData: Array<string> = [];

    // Forms
    isCreate = false;

    isUpdate = false;

    isCopy = false;

    isCreateReport = false;

    isCopyReport = false;

    isUpdateReport = false;

    // Variables
    currentUser: User | null;

    isHotliner = false;

    holidays: Array<string> = [];

    hotlines?: Hotline[];

    displayData: Hotline[] = [];

    hotliners?: Hotliner[];

    companies: Company[] = [];

    users: User[] = [];

    report: HotlineReport;

    clickedRow: Hotline[] = [];

    clickedRow$ = new Observable<Hotline[]>((observer: Observer<Hotline[]>) => {
        observer.next(this.clickedRow);
    });

    // Loaders
    loadingHotline = true;

    // GRID
    @ViewChild('hotlineGrid') gridHotlines: GridComponent;

    @ViewChild('searchtext') searchtextObj: TextBoxComponent;

    // Dialogs
    @ViewChild('deleteHotlineDialog') deleteHotlineDialog: DialogComponent;

    @ViewChild('activateHotlineDialog') activateHotlineDialog: DialogComponent;

    @ViewChild('confirmationCancel') confirmationCancel: DialogComponent;

    @ViewChild('viewReportDialog') viewReportDialog: DialogComponent;

    @ViewChild('formReportDialog') formReportDialogObj: DialogComponent;

    // Dropdowms
    @ViewChild('viewFilter') viewFilterInstance: DropDownListComponent;

    @ViewChild('hotline') hotlineObj: DropDownListComponent;

    constructor(
        private readonly router: Router,
        private readonly route: ActivatedRoute,
        private readonly ref: ChangeDetectorRef,
        private readonly formBuilder: FormBuilder,
        private readonly hotlineService: HotlineService,
        private readonly authenticationService: AuthenticationService,
        private readonly dataService: DataService,
        private readonly messages: MessageService,
        private readonly permissionService: PermissionService,
        private readonly environmentService: EnvironmentService,
    ) {
        this.currentUser = this.authenticationService.currentUserValue;
        this.insertImageSettings = {
            path: this.environmentService.backendURL + '/api/file/preview/hotline/',
            saveUrl:
                this.environmentService.backendURL +
                '/api/upload/attachments/hotline?type=attachments&subfolder=hotline',
        };
    }

    ngOnInit(): void {
        this.filterSettings = {
            type: 'Excel',
            showFilterBarOperator: true,
            showFilterBarStatus: true,
            ignoreAccent: true,
        };

        this.selectionSettings = {
            persistSelection: true,
            type: 'Multiple',
            checkboxOnly: true,
        };

        this.toolbar = [
            {
                text: 'Občerstvit data',
                tooltipText: 'Občerstvit data',
                prefixIcon: 'e-refresh',
                id: 'customRefresh',
            },
            'Print',
            'ExcelExport',
            'Add',
            'Edit',
            {
                text: 'Kopírovat',
                tooltipText: 'Kopírovat hot-line',
                prefixIcon: 'e-copy',
                id: 'copy',
            },
            {
                text: 'Smazat',
                tooltipText: 'Smazat hot-line',
                prefixIcon: 'e-remove',
                id: 'delete',
            },
            {
                text: 'Detail',
                tooltipText: 'Detail hot-line',
                prefixIcon: 'e-openfolder',
                id: 'detail',
            },
            {
                text: 'Zápis',
                tooltipText: 'Nový zápis',
                prefixIcon: 'e-report',
                id: 'newReport',
            },
        ];
        this.pageOptions = {pageSize: 50};
        this.dateFormat = 'dd.MM.yyyy HH:mm';
        this.filterDate = {type: 'Menu', params: {format: this.dateFormat}};
        this.groupOptions = {
            showGroupedColumn: true,
            disablePageWiseAggregates: false,
        };

        this.editSettings = {
            allowEditing: true,
            allowAdding: true,
            allowDeleting: true,
            allowEditOnDblClick: false,
            mode: 'Dialog',
        };
        this.requiredRules = {required: true};

        this.categoryFilterData = [
            'všechny hot-line',
            'nadcházející hot-line',
            'proběhlé hot-line',
            'bez zápisu',
            'se zápisem',
            'smazané hot-line',
        ];

        this.dataService.userSource.pipe(untilDestroyed(this))
            .subscribe(
                (data: User[]) => {
                    this.users = data;
                    this.creatorSelect = [];
                    data.map((user: User) => {
                        if (!user.deleted_date) {
                            this.creatorSelect = [
                                ...this.creatorSelect,
                                {value: user.id, label: user.firstname + ' ' + user.secondname},
                            ];
                        }
                    });
                },
                error => {
                    console.error(error);
                },
            );
    }

    ngAfterViewChecked(): void {
        this.route.queryParamMap.pipe(untilDestroyed(this))
            .subscribe(params => {
                if (params.get('form') === 'addHotline') {
                    if (
                        this.currentUser &&
                        this.permissionService.checkUserISHotlineAdmin(this.currentUser)
                    ) {
                        console.info('get hotline form');
                        this.isCreate = true;
                        this.renderHotlineFormDialog = true;
                        this.ref.markForCheck();
                    } else {
                        const body = 'Nemáte oprávnění provést tuto akci...';
                        const options = {progressBar: true, timeOut: 5000};

                        this.messages.showError('Nedostatečné oprávnění', body, options);
                        this.ref.markForCheck();
                    }
                }

                if (params.get('form') === 'addHotlineReport') {
                    if (
                        this.currentUser &&
                        (this.currentUser.is_hotliner ||
                            this.permissionService.checkUserISHotlineAdmin(this.currentUser))
                    ) {
                        console.info('get hotline report form');
                        this.isCreateReport = true;
                        this.isUpdateReport = false;
                        this.isCopyReport = false;
                        this.renderHotlineReportFormDialog = true;
                        this.ref.markForCheck();
                    } else {
                        const body = 'Nemáte oprávnění provést tuto akci...';
                        const options = {progressBar: true, timeOut: 5000};

                        this.messages.showError('Nedostatečné oprávnění', body, options);
                        this.ref.markForCheck();
                    }
                }
            });
    }

    loadData(): DataResult[] {
        this.gridHotlines.clearRowSelection();

        console.info('NEW DATA BOUND');

        const elements: unknown[] = [];

        if (this.hotlines && this.hotlines.length > 0) {
            this.hotlines.map((hotline: Hotline) => {
                elements.push({
                    id: hotline.id,
                    hotliner_id: hotline.hotliner_id,
                    user_id: hotline.hotliner.user_id,
                    created_by: hotline.created_by,
                    hotliner_str: hotline.hotliner.user.fullname,
                    hotliner_img: hotline.hotliner.user.personalphoto,
                    company: hotline.company.name,
                    type: hotline.type,
                    score: hotline.score,
                    start_at: new Date(hotline.start_at),
                    end_at: new Date(hotline.end_at),
                    hotliner: hotline.hotliner,
                    reports: hotline.reports.length,
                    creator_str: hotline.creator.secondname + ' ' + hotline.creator.firstname,
                    creator_img: hotline.creator.personalphoto,
                    created_date: new Date(hotline.created_date),
                    updated_date: hotline.updated_date ? new Date(hotline.updated_date) : null,
                    deleted_date: hotline.deleted_date ? new Date(hotline.deleted_date) : null,
                });
            });
        }

        this.loadingHotline = false;
        this.ref.markForCheck();

        return elements as DataResult[];
    }

    onCreated(): void {
        this.disableToolBarBtn();
    }

    setInitialGridFiltering(): void {
        this.gridHotlines.clearFiltering();
        this.viewFilterInstance.value = 'všechny hot-line';
        this.ref.markForCheck();
    }

    onLoad(): void {
        const wrapper1 = document.getElementById('scroll_wrapper1_hotlines');
        const wrapper2 = document.getElementById('scroll_wrapper2_hotlines');

        if (wrapper1 && wrapper2) {
            wrapper1.onscroll = (): void => {
                wrapper2.scrollLeft = wrapper1.scrollLeft;
            };

            wrapper2.onscroll = (): void => {
                wrapper1.scrollLeft = wrapper2.scrollLeft;
            };
        }

        this.dataService.hotlineSource.pipe(untilDestroyed(this))
            .subscribe(
                (hotlines: Hotline[]) => {
                    let dateA: number | null = null;
                    let dateB: number | null = null;

                    this.hotlineSelect = [];
                    this.hotlines = hotlines;

                    if (this.hotlines.length > 0) {
                        this.data = this.gridHotlines.dataSource = this.loadData();
                        this.sortOptions = {
                            columns: [
                                {field: 'created_date', direction: 'Descending'},
                                {field: 'id', direction: 'Descending'},
                            ],
                        };

                        this.hotlines = this.hotlines.sort((a, b) => {
                            dateA = moment(a.start_at)
                                .valueOf();
                            dateB = moment(b.start_at)
                                .valueOf();

                            return dateA > dateB ? -1 : 1;
                        });

                        hotlines.map((hotline: Hotline) => {
                            if (
                                !hotline.deleted_date &&
                                hotline.hotliner.user.id === this.currentUser?.id
                            ) {
                                const typeTrnslt =
                                    hotline.type === 'technician' ? 'služba' : 'příslužba';

                                this.hotlineSelect = [
                                    ...this.hotlineSelect,
                                    {
                                        value: hotline.id,
                                        label:
                                            typeTrnslt +
                                            ': ' +
                                            hotline.hotliner.user.firstname +
                                            ' ' +
                                            hotline.hotliner.user.secondname +
                                            ' ' +
                                            moment(hotline.start_at)
                                                .format('DD.MM.YYYY') +
                                            ' - ' +
                                            moment(hotline.end_at)
                                                .format('DD.MM.YYYY'),
                                    },
                                ];
                            }
                        });
                    } else {
                        this.loadingHotline = false;
                    }
                },
                error => {
                    console.error(error);
                    this.loadingHotline = false;
                    this.ref.markForCheck();
                },
            );
    }

    search(): void {
        this.gridHotlines.search(this.searchtextObj.value);
    }

    onDataBound(): void {
        this.rowSelected();

        const width = document.getElementById('hotlineGrid_content_table')?.offsetWidth;
        const parent1 = document.getElementById('scroll_div_hotlines');
        const parent2 = document.getElementById('grid_parent_hotlines');

        if (parent1 && parent2 && width) {
            parent1.style.width = `${width}px`;
            parent2.style.width = `${width}px`;
        }

        this.gridHotlines.autoFitColumns();
        this.ref.markForCheck();
    }

    rowDataBound(args: RowDataBoundEventArgs): void {
        const data = args.data as Hotline;

        if (args.row && data.deleted_date) {
            args.row.getElementsByClassName('e-gridchkbox')[0].classList.add('disablecheckbox');
            args.row
                .getElementsByClassName('e-checkbox-wrapper')[0]
                .classList.add('disablecheckbox');
            args.row.classList.add('e-disabled');
        }

        if (
            this.currentUser &&
            args.row &&
            data.created_by !== this.currentUser.id &&
            !this.permissionService.checkUserISHotlineAdmin(this.currentUser) &&
            !this.permissionService.checkUserISAdmin(this.currentUser)
        ) {
            args.row.getElementsByClassName('e-gridchkbox')[0].classList.add('disablecheckbox');
            args.row
                .getElementsByClassName('e-checkbox-wrapper')[0]
                .classList.add('disablecheckbox');
        }

        this.ref.markForCheck();
    }

    onStatusFilterChange(e: ChangeEventArgs): void {
        if (e.value === 'všechny hot-line') {
            this.gridHotlines.clearFiltering();
        } else if (e.value === 'nadcházející hot-line') {
            this.gridHotlines.filterSettings = {
                columns: [
                    {
                        field: 'start_at',
                        operator: 'greaterthanorequal',
                        value: new Date(moment()
                            .format('YYYY-MM-DD HH:mm:ss')),
                        predicate: 'and',
                        matchCase: false,
                        ignoreAccent: true,
                    },
                    {
                        field: 'deleted_date',
                        matchCase: false,
                        operator: 'equal',
                        predicate: 'and',
                        value: null as unknown as string,
                        ignoreAccent: true,
                    },
                ],
            };
        } else if (e.value === 'proběhlé hot-line') {
            this.gridHotlines.filterSettings = {
                columns: [
                    {
                        field: 'start_at',
                        operator: 'lessthanorequal',
                        value: new Date(moment()
                            .toISOString()),
                        predicate: 'and',
                        matchCase: false,
                        ignoreAccent: true,
                    },
                    {
                        field: 'deleted_date',
                        matchCase: false,
                        operator: 'equal',
                        predicate: 'and',
                        value: null as unknown as string,
                        ignoreAccent: true,
                    },
                ],
            };
        } else if (e.value === 'bez zápisu') {
            this.gridHotlines.filterSettings = {
                columns: [
                    {
                        field: 'reports',
                        operator: 'equal',
                        value: 0,
                        predicate: 'and',
                        matchCase: false,
                        ignoreAccent: true,
                    },
                    {
                        field: 'deleted_date',
                        matchCase: false,
                        operator: 'equal',
                        predicate: 'and',
                        value: null as unknown as string,
                        ignoreAccent: true,
                    },
                ],
            };
        } else if (e.value === 'se zápisem') {
            this.gridHotlines.filterSettings = {
                columns: [
                    {
                        field: 'reports',
                        matchCase: false,
                        operator: 'greaterthanorequal',
                        predicate: 'and',
                        value: 1,
                        ignoreAccent: true,
                    },
                    {
                        field: 'deleted_date',
                        matchCase: false,
                        operator: 'equal',
                        predicate: 'and',
                        value: null as unknown as string,
                        ignoreAccent: true,
                    },
                ],
            };
        } else if (e.value === 'smazané hot-line') {
            this.gridHotlines.clearFiltering();
            this.gridHotlines.filterByColumn('deleted_date', 'notequal', null, 'and', false, true);
        }
    }

    onFilteringHotline: EmitType<FilteringEventArgs> = (e: FilteringEventArgs) => {
        if (e.text === '') {
            e.updateData(this.hotlineSelect);
        } else {
            let query: Query = new Query();

            query = e.text !== '' ? query.where('label', 'contains', e.text, true, true) : query;
            e.updateData(this.hotlineSelect, query);
        }
    };

    cancelBtnClick(): void {
        this.searchtextObj.value = '';
        this.gridHotlines.searchSettings.key = '';
    }

    toolbarClick(args: ClickEventArgs): void {
        const xlsProp: ExcelExportProperties = {
            fileName: 'js_hotlines_export.xlsx',
            enableFilter: true,
            exportType: this.exportCurrentPage ? 'CurrentPage' : 'AllPages',
        };

        if (args.item.id === 'hotlineGrid_excelexport') {
            void this.gridHotlines.excelExport(xlsProp);
        } else if (args.item.id === 'delete') {
            if (this.gridHotlines.getSelectedRecords().length !== 0) {
                this.deleteHotlineDialog.content = `Potvrďte smazání ${this.clickedRow.length} hot-line`;
                this.deleteHotlineDialog.show();
            } else {
                this.confirmationCancel.content = 'Musíte vybrat alespoň jednu hot-line';
                this.confirmationCancel.show();
            }
        } else if (args.item.id === 'detail') {
            void this.router.navigate(['/hotline/detail', this.clickedRow[0].id]);
        } else if (args.item.id === 'copy') {
            if (this.gridHotlines.getSelectedRecords().length !== 0) {
                this.isUpdate = false;
                this.isCreate = false;
                this.isCopy = true;
                this.renderHotlineFormDialog = true;
                this.ref.markForCheck();
            } else {
                this.confirmationCancel.content = 'Musíte vybrat alespoň jednu hot-line';
                this.confirmationCancel.show();
            }
        } else if (args.item.id === 'newReport') {
            if (this.gridHotlines.getSelectedRecords().length !== 0) {
                this.isCreateReport = true;
                this.isUpdateReport = false;
                this.isCopyReport = false;
                this.renderHotlineReportFormDialog = true;
            } else {
                this.confirmationCancel.content = 'Musíte vybrat alespoň jednu hot-line';
                this.confirmationCancel.show();
            }
        } else if (args.item.id === 'customRefresh') {
            this.loadingHotline = true;
            console.info('refresh DATA!');
            this.ref.markForCheck();
            this.dataService.clearHotlinesCache();
            this.dataService.setHotlinesDataSource();
            this.onLoad();
        }
    }

    enableToolbarBtn(): void {
        if (this.currentUser && this.permissionService.checkUserISHotlineAdmin(this.currentUser)) {
            this.gridHotlines.toolbarModule.enableItems(
                ['hotlineGrid_edit', 'delete', 'detail', 'copy'],
                true,
            );
        } else {
            this.gridHotlines.toolbarModule.enableItems(['detail'], true);
        }

        if (
            !this.clickedRow[0].deleted_date &&
            this.clickedRow[0].hotliner.user.id === this.currentUser?.id
        ) {
            this.gridHotlines.toolbarModule.enableItems(['newReport'], true);
        }
    }

    disableToolBarBtn(): void {
        this.gridHotlines.toolbarModule.enableItems(
            ['hotlineGrid_edit', 'delete', 'detail', 'copy', 'newReport'],
            false,
        );
    }

    rowSelected(): void {
        this.clickedRow = [];

        const selectedrecords: object[] = this.gridHotlines.getSelectedRecords();

        if (selectedrecords.length > 0) {
            selectedrecords.map((row: Hotline) => {
                const tempData: Hotline | undefined = this.hotlines?.find(x => x.id === row.id);

                if (tempData) {
                    this.clickedRow.push(tempData);
                }

                this.ref.markForCheck();
            });

            this.clickedRow$ = new Observable<Hotline[]>((observer: Observer<Hotline[]>) => {
                observer.next(this.clickedRow);
            });
            this.enableToolbarBtn();
        } else {
            this.disableToolBarBtn();
            this.clickedRow$ = new Observable<Hotline[]>((observer: Observer<Hotline[]>) => {
                observer.next(this.clickedRow);
            });
        }
    }

    actionBegin(args): void {
        if (args.requestType === 'add' || args.requestType === 'beginEdit') {
            if (
                this.currentUser &&
                this.permissionService.checkUserISHotlineAdmin(this.currentUser)
            ) {
                args.cancel = true;

                if (args.requestType === 'beginEdit') {
                    this.isUpdate = true;
                    this.isCreate = false;
                }

                if (args.requestType === 'add') {
                    this.isCreate = true;
                    this.isUpdate = false;
                }

                this.renderHotlineFormDialog = true;
                this.ref.markForCheck();
            } else {
                const body = 'Nemáte oprávnění provést tuto akci...';
                const options = {progressBar: true, timeOut: 5000};

                this.messages.showError('Nedostatečné oprávnění', body, options);
                this.ref.markForCheck();
            }
        }
    }

    actionComplete(args): void {
        if (args.requestType === 'beginEdit' || args.requestType === 'add') {
            args.form.ej2_instances[0].rules = {};
        }
    }

    reportView(report: HotlineReport): void {
        this.report = report;
        this.viewReportDialog.show();
        this.ref.markForCheck();
    }

    removeHotline(): void {
        const promises: Promise<Hotline | undefined>[] = [];
        let rejects = 0;

        this.loadingHotline = true;

        for (const row of this.clickedRow) {
            if (
                this.currentUser &&
                this.permissionService.checkUserISHotlineAdmin(this.currentUser)
            ) {
                row.deleted_by = this.currentUser.id;

                const declineObj = this.hotlineService.deleteHotline(row)
                    .toPromise();

                promises.push(declineObj);
            } else {
                rejects++;
            }
        }

        void Promise.all(promises)
            .then(result => {
                if (result.length > 0) {
                    const body = `Celkem smazáno: ${result.length} hot-line`;
                    const options = {progressBar: true, timeOut: 5000};

                    this.messages.showSuccess('Smazání úspěšně dokončeno', body, options);
                }

                if (rejects > 0) {
                    const body = `Celkem u: ${rejects} hot-line`;
                    const options = {progressBar: true, timeOut: 5000};

                    this.messages.showError('Nedostatečné oprávnění', body, options);
                }

                this.deleteHotlineDialog.hide();
                this.gridHotlines.clearRowSelection();
                this.rowSelected();
                this.dataService.setHotlinesDataSource();
                this.loadingHotline = false;
                this.ref.markForCheck();
            });
    }

    reactiveHotline(): void {
        this.loadingHotline = true;
        this.clickedRow[0].deleted_by = null;
        this.clickedRow[0].deleted_date = null;
        this.hotlineService
            .updateHotline(this.clickedRow[0])
            .pipe(untilDestroyed(this))
            .subscribe(
                () => {
                    this.messages.showSuccess('Hot-line úspěšně obnovena', '', {
                        progressBar: true,
                        timeOut: 5000,
                    });
                    this.dataService.setHotlinesDataSource();
                    this.loadingHotline = false;
                    this.activateHotlineDialog.hide();
                    this.ref.markForCheck();
                },
                error => {
                    console.error(error);
                    this.messages.showError(
                        'Chyba při obnovení hot-line,',
                        'Zkuste to znovu později',
                        {
                            progressBar: true,
                            timeOut: 5000,
                        },
                    );
                    this.loadingHotline = false;
                    this.activateHotlineDialog.hide();
                    this.ref.markForCheck();
                },
            );
    }

    getCalendarExport(): void {
        const data: ExportHotline[] = [];

        if (this.displayData.length > 0) {
            this.displayData.forEach((hotline: Hotline) => {
                const typeTrnslt = hotline.type === 'technician' ? 'služba' : 'příslužba';

                data.push({
                    title: hotline.company.name + ' - HOTLINE - ' + typeTrnslt,
                    location: hotline.company.address_obj,
                    description: 'Přidělena služba hot-line od 8:00 do 22:00',
                    start: hotline.start_at,
                    end: hotline.end_at,
                });
            });

            const options = {compact: true, ignoreComment: true, spaces: 4};

            convert.json2xml(JSON.stringify(data), options);
        }
    }
}
