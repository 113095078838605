import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {User} from '@src/app/_models/user/user';
import {AuthenticationService} from '@src/app/_services/authentication.service';
import {Task} from '@src/app/_models/task/task';
import {TimeagoIntl} from 'ngx-timeago';
import {strings as czechStrings} from 'ngx-timeago/language-strings/cs';
import {Tickets} from '@src/app/_models/ticket/tickets';
import {Hotline} from '@src/app/_models/hotline/hotline';
import {registerLocaleData} from '@angular/common';
import {DataService} from '@src/app/_services/data.service';
import {Event} from '@src/app/_models/event/event';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import moment from 'moment';
import localeCs from '@angular/common/locales/cs';
import {TasksService} from '@src/app/features/tasks/tasks.service';
import {HotlineService} from '@src/app/features/hotline/hotline.service';
import {UsersService} from '@src/app/features/users/users.service';
import {TicketsService} from '@src/app/features/tickets/tickets.service';

moment.updateLocale('cs', {workingWeekdays: [1, 2, 3, 4, 5]});
registerLocaleData(localeCs);

@UntilDestroy()
@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HomeComponent implements OnInit {
    tasks: Task[] = [];

    tickets: Tickets[] = [];

    hotlines: Hotline[] = [];

    events: Event[] = [];

    currentUser: User | null;

    live: true;

    currDate = moment()
        .format('YYYY-MM-DD');

    loadingTaskWidget = true;

    loadingTicketkWidget = true;

    loadingHotlineWidget = true;

    loadingEventWidget = true;

    constructor(
        private readonly authenticationService: AuthenticationService,
        private readonly dataService: DataService,
        private readonly taskService: TasksService,
        private readonly hotlineService: HotlineService,
        private readonly userService: UsersService,
        private readonly intl: TimeagoIntl,
        private readonly ticketService: TicketsService,
        private readonly ref: ChangeDetectorRef,
    ) {
        this.currentUser = this.authenticationService.currentUserValue;
        this.intl.strings = czechStrings;
        this.intl.changes.next();
    }

    ngOnInit(): void {
        this.dataService.eventSource.pipe(untilDestroyed(this))
            .subscribe(
                (events: Event[]) => {
                    this.events = [];
                    events.map((event: Event) => {
                        if (
                            !event.deleted_date &&
                            this.currDate >= moment(event.start_at)
                                .format('YYYY-MM-DD') &&
                            this.currDate <= moment(event.end_at)
                                .format('YYYY-MM-DD')
                        ) {
                            this.events.push(event);
                        }
                    });
                    this.loadingEventWidget = false;
                    this.ref.markForCheck();
                },
                error => {
                    console.error(error);
                    this.loadingEventWidget = false;
                    this.ref.markForCheck();
                },
            );

        this.dataService.taskSource.pipe(untilDestroyed(this))
            .subscribe(
                (tasks: Task[]) => {
                    this.tasks = [];
                    tasks.map((task: Task) => {
                        if (
                            !task.finished_at &&
                            !task.deleted_date &&
                            task.solver.id === this.currentUser?.id
                        ) {
                            this.tasks.push(task);
                        }
                    });
                    this.loadingTaskWidget = false;
                    this.ref.markForCheck();
                },
                error => {
                    console.error(error);
                    this.loadingTaskWidget = false;
                    this.ref.markForCheck();
                },
            );

        this.dataService.ticketSource.pipe(untilDestroyed(this))
            .subscribe(
                (data: Tickets[]) => {
                    this.tickets = [];
                    data.map((ticket: Tickets) => {
                        // eslint-disable-next-line max-len
                        if (
                            this.currentUser &&
                            !ticket.deleted_date &&
                            !ticket.finished_at &&
                            this.ticketService.checkTicketAssignation(ticket, this.currentUser)
                        ) {
                            this.tickets.push(ticket);
                        }
                    });
                    this.loadingTicketkWidget = false;
                    this.ref.markForCheck();
                },
                error => {
                    console.error(error);
                    this.loadingTicketkWidget = false;
                    this.ref.markForCheck();
                },
            );

        this.dataService.hotlineSource.pipe(untilDestroyed(this))
            .subscribe(
                (data: Hotline[]) => {
                    this.hotlines = [];
                    data.map((hotline: Hotline) => {
                        if (
                            !hotline.deleted_date &&
                            moment(hotline.start_at)
                                .format('YYYY-MM-DD') === this.currDate &&
                            moment(hotline.end_at)
                                .format('YYYY-MM-DD') === this.currDate
                        ) {
                            this.hotlines.push(hotline);
                        }
                    });
                    this.loadingHotlineWidget = false;
                    this.ref.markForCheck();
                },
                error => {
                    console.error(error);
                    this.loadingHotlineWidget = false;
                    this.ref.markForCheck();
                },
            );

        this.dataService.setTicketsDataSource();
        this.dataService.setTasksDataSource();
        this.dataService.setEventsDataSource();
        this.dataService.setHotlinesDataSource();
        this.dataService.setMaterialsDataSource();
    }

    trackTaskID(index: number, task: Task): number {
        return task.id;
    }

    trackTicketID(index: number, ticket: Tickets): number {
        return ticket.id;
    }

    trackEventID(index: number, event: Event): number {
        return event.id;
    }

    trackHotlineID(index: number, hotline: Hotline): number {
        return hotline.id;
    }
}
