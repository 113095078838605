import {CanDeactivate} from '@angular/router';
import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';

export interface ComponentCanDeactivate {
    canDeactivate: () => Observable<boolean> | boolean;
}

@Injectable({
    providedIn: 'root',
})
export class PendingChangesGuard implements CanDeactivate<ComponentCanDeactivate> {
    canDeactivate(component: ComponentCanDeactivate): Observable<boolean> | boolean {
        return component.canDeactivate()
            ? true
            : confirm(
                'WARNING: You have unsaved changes. Press Cancel to go back and save these changes, or OK to lose these changes.',
            );
    }
}
