<ejs-dialog
  #formDialog
  (close)="
        isUpdate = false;
        isCreate = false;
        isDirty = false;
        isChecked = false;
        isCopy = false;
        submited = false;
        changeDialogState(false)"
  (open)="onCreateRte()"
  [header]="'Zadat řešení ticketu'"
  [height]="'500px'"
  [isModal]="true"
  [showCloseIcon]="true"
  [visible]="isVisible"
  [width]="'85%'"
  id="formDialog"
>
  <form [formGroup]="createSolvingForm">
    <ng-template #content>
      <div class="dialogContent">
        <div class="row">
          <div class="form-group col-12">
            <label
              [ngClass]="
                    editSolvingControl.solution.valid &&
                    (editSolvingControl.solution.dirty ||
                        editSolvingControl.solution.touched)
                        ? 'text-success active'
                        : editSolvingControl.solution.invalid &&
                          (editSolvingControl.solution.dirty ||
                              editSolvingControl.solution.touched)
                        ? 'text-danger active'
                        : 'text-muted active'
                "
              for="ticketSolution"
            >* Řešení ticketu:</label
            >
            <ejs-richtexteditor
              #ticketSolution
              (actionBegin)="handleFullScreen($event)"
              (actionComplete)="actionCompleteHandler($event)"
              [enablePersistence]="false"
              [fontFamily]="fontFamily"
              [insertImageSettings]="insertImageSettings"
              [maxLength]="maxLength"
              [ngClass]="
                    editSolvingControl.solution.touched &&
                    editSolvingControl.solution.invalid
                        ? 'e-error'
                        : editSolvingControl.solution.touched &&
                          editSolvingControl.solution.valid
                        ? 'e-success'
                        : ''
                "
              [pasteCleanupSettings]="pasteCleanupSettings"
              [quickToolbarSettings]="quickToolbarSettings"
              [showCharCount]="true"
              [toolbarSettings]="tools"
              formControlName="solution"
              id="ticketSolution"
              locale="cs"
              placeholder="Začněte psát řešení..."
            >
            </ejs-richtexteditor>
            <small
              *ngIf="
                    editSolvingControl.solution.touched &&
                    editSolvingControl.solution.errors &&
                    editSolvingControl.solution.errors.required
                "
              class="text-danger"
            >Řešení ticketu je povinné</small
            >
          </div>
        </div>
      </div>
    </ng-template>
  </form>
  <ng-template #footerTemplate>
    <div class="d-flex justify-content-between">
      <div class="col-6">
        <div
          *ngIf="createSolvingForm.invalid"
          class="alert alert-warning"
          role="alert"
        >
          Formulář je špatně vyplněný
        </div>
        <div
          *ngIf="createSolvingForm.valid"
          class="alert alert-success"
          role="alert"
        >
          Formulář je správně vyplněný
        </div>
      </div>
      <div class="col-6">
        <button
          (click)="solveTicket()"
          [disabled]="loadingNote || createSolvingForm.invalid || !isChecked"
          cssClass="e-btn e-success btn-p-zero e-small"
          ejs-button
          type="submit"
        >
                        <span
                          *ngIf="loadingNote"
                          aria-hidden="true"
                          class="spinner-grow spinner-grow-sm"
                          role="status"
                        ></span>
          <span *ngIf="!loadingNote">ULOŽIT</span>
          <span *ngIf="loadingNote">Provádím...</span>
        </button>
        <button
          (click)="formDialogObj.hide()"
          cssClass="e-btn e-danger e-outline btn-p-zero e-small"
          ejs-button
          type="button"
        >
          ZRUŠIT
        </button>
      </div>
    </div>
  </ng-template>
</ejs-dialog>
