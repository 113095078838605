import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    HostListener,
    Input,
    OnChanges,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {User} from '@src/app/_models/user/user';
import {ToolbarComponent} from '@syncfusion/ej2-angular-navigations';
import {DialogComponent} from '@syncfusion/ej2-angular-popups';
import {DropDownListComponent, FilteringEventArgs} from '@syncfusion/ej2-angular-dropdowns';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {DataService} from '@src/app/_services/data.service';
import {PermissionService} from '@src/app/_services/permission.service';
import {AuthenticationService} from '@src/app/_services/authentication.service';
import {MessageService} from '@src/app/_services/message.service';
import {Observable} from 'rxjs';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {EmitType} from '@syncfusion/ej2-base';
import {Query} from '@syncfusion/ej2-data';
import {ComponentCanDeactivate} from '@src/app/_guards/changes.guard';
import {Office} from '@src/app/_models/office/office.model';
import {Building} from '@src/app/_models/buildings/building.model';
import {Company} from '@src/app/_models/company/company';
import {HotlineService} from '@src/app/features/hotline/hotline.service';
import {SettingsService} from '@src/app/features/settings/settings.service';
import {OfficeInput} from '@src/app/features/settings/types/office-input.type';

@UntilDestroy()
@Component({
    selector: 'app-office-form',
    templateUrl: './office-form.component.html',
    styleUrls: ['./office-form.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OfficeFormComponent implements OnInit, OnChanges, ComponentCanDeactivate {
    // Dropdowns
    fields: object = {text: 'label', value: 'value'};

    height = '240px';

    buildingSelect: Array<{value: boolean | number | string; label: string}> = [];

    companySelect: Array<{value: boolean | number | string; label: string}> = [];

    typeSelect: Array<{value: boolean | number | string; label: string}> = [];

    // Forms
    officeForm: FormGroup;

    isDirty = false;

    isChecked = false;

    submited = false;

    // Variables
    currentUser: User | null;

    users: User[];

    offices: Office[] = [];

    // Loaders
    loadingOffice = false;

    @Input() office: Office | null = null;

    @Input() isCreate = false;

    @Input() isUpdate = false;

    @Input() isCopy = false;

    @Input() isVisible = false;

    @Output() readonly officeFormDialogState = new EventEmitter<boolean>();

    // Maintoolbar
    @ViewChild('dialogFooterToolbar') dialogFooterToolbarObj: ToolbarComponent;

    // Dialogs
    @ViewChild('formDialog') formDialogObj: DialogComponent;

    // Dropdowns

    @ViewChild('building') buildingObj: DropDownListComponent;

    @ViewChild('company') companyObj: DropDownListComponent;

    @ViewChild('type') typeObj: DropDownListComponent;

    constructor(
        private readonly route: ActivatedRoute,
        private readonly router: Router,
        private readonly ref: ChangeDetectorRef,
        private readonly formBuilder: FormBuilder,
        private readonly dataService: DataService,
        private readonly settingsService: SettingsService,
        private readonly hotlineService: HotlineService,
        private readonly permissionService: PermissionService,
        private readonly authenticationService: AuthenticationService,
        private readonly messages: MessageService,
    ) {
        this.currentUser = this.authenticationService.currentUserValue;

        this.dataService.setBuildingsDataSource();
        this.dataService.setCompanyDataSource();
    }

    get f(): {[key: string]: AbstractControl} {
        return this.officeForm.controls;
    }

    @HostListener('window:beforeunload')
    canDeactivate(): Observable<boolean> | boolean {
        return !this.isDirty;
    }

    ngOnInit(): void {
        this.dataService.companySource.pipe(untilDestroyed(this))
            .subscribe(
                (companies: Company[] | undefined) => {
                    this.companySelect = [];
                    companies?.map((company: Company) => {
                        this.companySelect = [
                            ...this.companySelect,
                            {
                                value: company.id,
                                label: company.name,
                            },
                        ];
                    });
                },
                error => {
                    console.error(error);
                },
            );

        this.dataService.buildingsSource.pipe(untilDestroyed(this))
            .subscribe(
                (buildings: Building[]) => {
                    this.buildingSelect = [];
                    buildings.forEach((building: Building) => {
                        this.buildingSelect = [
                            ...this.buildingSelect,
                            {
                                value: building.id,
                                label: building.name,
                            },
                        ];
                    });
                    this.ref.markForCheck();
                },
                error => {
                    console.error(error);
                },
            );

        this.typeSelect = [
            {
                value: 'private',
                label: 'Soukromá kancelář',
            },
            {
                value: 'open',
                label: 'Open office',
            },
            {
                value: 'share',
                label: 'Sdílená kancelář',
            },
            {
                value: 'meeting',
                label: 'Zasedací místnost',
            },
        ];

        this.officeForm = this.formBuilder.group({
            company_id: [null, Validators.required],
            building_id: [null, Validators.required],
            name: ['', Validators.required],
            bookable: [false, Validators.required],
            type: ['', Validators.required],
        });

        this.officeForm.valueChanges.pipe(untilDestroyed(this))
            .subscribe(() => {
                this.isDirty = this.officeForm.dirty;
                this.showOfficeInputErrors();
            });

        this.ref.markForCheck();
    }

    ngOnChanges(): void {
        if (this.isVisible) {
            const queryParams: Params = {form: null};

            void this.router.navigate([], {
                relativeTo: this.route,
                queryParams,
                queryParamsHandling: 'merge',
            });

            if (this.isUpdate && this.office) {
                this.formDialogObj.header = `Kancelář #${this.office.id} | ${this.office.name}`;
                this.fillTheForm();
                this.showOfficeInputErrors();
            }

            if (this.isCreate) {
                this.officeForm.reset();
                this.formDialogObj.header = 'Nová kancelář';
                this.fillTheForm();
            }

            if (this.isCopy) {
                this.officeForm.reset();
                this.formDialogObj.header = 'Nová kancelář';
                this.fillTheForm();
                this.showOfficeInputErrors();
            }
        }
    }

    changeDialogState(value: boolean): void {
        this.officeFormDialogState.emit(value);
    }

    onFilteringCompanies: EmitType<FilteringEventArgs> = (e: FilteringEventArgs) => {
        if (e.text === '') {
            e.updateData(this.companySelect);
        } else {
            let query: Query = new Query();

            query = e.text !== '' ? query.where('label', 'contains', e.text, true, true) : query;
            e.updateData(this.companySelect, query);
        }
    };

    onFilteringBuildings: EmitType<FilteringEventArgs> = (e: FilteringEventArgs) => {
        if (e.text === '') {
            e.updateData(this.buildingSelect);
        } else {
            let query: Query = new Query();

            query = e.text !== '' ? query.where('label', 'contains', e.text, true, true) : query;
            e.updateData(this.buildingSelect, query);
        }
    };

    onFilteringTypes: EmitType<FilteringEventArgs> = (e: FilteringEventArgs) => {
        if (e.text === '') {
            e.updateData(this.typeSelect);
        } else {
            let query: Query = new Query();

            query = e.text !== '' ? query.where('label', 'contains', e.text, true, true) : query;
            e.updateData(this.typeSelect, query);
        }
    };

    onRenderCell(args): void {
        if (args.date.getDay() === 0 || args.date.getDay() === 6) {
            args.isDisabled = true;
        }
    }

    fillTheForm(): void {
        this.officeForm.controls.company_id.patchValue(this.office ? this.office.company_id : null);
        this.officeForm.controls.building_id.patchValue(
            this.office ? this.office.building_id : null,
        );
        this.officeForm.controls.name.patchValue(this.office ? this.office.name : '');
        this.officeForm.controls.bookable.patchValue(this.office ? this.office.bookable : false);
        this.officeForm.controls.type.patchValue(this.office ? this.office.type : false);
    }

    onSubmit(): void {
        this.submited = true;
        this.isDirty = false;
        this.loadingOffice = true;

        if (this.isCreate || this.isCopy) {
            this.addOffice();
        }

        if (this.isUpdate) {
            this.editOffice();
        }
    }

    addOffice(): void {
        if (this.officeForm.invalid) {
            console.error('form is not valid!');

            return;
        }

        const officeData: OfficeInput = {
            company_id: this.f.company_id.value,
            building_id: this.f.building_id.value,
            name: this.f.name.value,
            bookable: this.f.bookable.value,
            type: this.f.type.value,
            created_by: this.currentUser?.id,
        };

        this.settingsService
            .addOffice(officeData)
            .pipe(untilDestroyed(this))
            .subscribe(
                () => {
                    const body = 'přidána do seznamu';
                    const options = {progressBar: true, timeOut: 5000};

                    this.messages.showSuccess('Kancelář úspěšně vytvořena', body, options);
                    this.dataService.setOfficesDataSource();
                    this.loadingOffice = false;
                    this.isCreate = false;
                    this.formDialogObj.hide();
                    this.ref.markForCheck();
                },
                error => {
                    console.error(error);

                    const body = 'Zkuste to znovu později';
                    const options = {
                        progressBar: true,
                        timeOut: 5000,
                        toastClass: 'red',
                    };

                    this.messages.showError('Chyba při vytváření žádanky', body, options);
                    this.loadingOffice = false;
                    this.ref.markForCheck();
                },
            );
    }

    copyOffice(): void {
        this.isCreate = true;
        this.isUpdate = false;
        this.officeForm.reset();
        this.formDialogObj.header = 'Nová kancelář';
        this.fillTheForm();
        this.showOfficeInputErrors();
        this.ref.markForCheck();
        this.formDialogObj.show();
    }

    // eslint-disable-next-line complexity
    editOffice(): void {
        if (this.officeForm.invalid) {
            console.error('form is not valid!');

            return;
        }

        if (
            this.office &&
            this.currentUser &&
            !this.office.deleted_date &&
            (this.office.created_by === this.currentUser.id ||
                this.permissionService.checkUserISOfficeAdmin(this.currentUser))
        ) {
            this.loadingOffice = true;
            this.isDirty = false;

            const officeData: OfficeInput = {
                id: this.office.id,
                company_id: this.f.company_id.value,
                building_id: this.f.building_id.value,
                name: this.f.name.value,
                bookable: this.f.bookable.value,
                type: this.f.type.value,
                updated_by: this.currentUser.id,
            };

            this.settingsService
                .updateOffice(officeData)
                ?.pipe(untilDestroyed(this))
                .subscribe({
                    next: () => {
                        const options = {progressBar: true, timeOut: 5000};

                        this.messages.showSuccess('Kancelář', 'úspěšně upravena', options);
                        this.dataService.setOfficesDataSource();
                        // this.dataService.setOfficeDetailDataSource(data.id);
                        this.loadingOffice = false;
                        this.isUpdate = false;
                        this.formDialogObj.hide();
                        this.ref.markForCheck();
                    },
                    error: error => {
                        console.error(error);

                        const body = 'Zkuste to znovu později';
                        const options = {progressBar: true, timeOut: 5000};

                        this.messages.showError('Chyba při úpravě kanceláře', body, options);
                        this.loadingOffice = false;
                        this.ref.markForCheck();
                    },
                });
        } else {
            const body = 'Nemáte oprávnění provést tuto akci...';
            const options = {progressBar: true, timeOut: 5000};

            this.messages.showError('Nedostatečné oprávnění', body, options);
            this.loadingOffice = false;
            this.isUpdate = false;
            this.formDialogObj.hide();
            this.ref.markForCheck();
        }
    }

    showOfficeInputErrors(): void {
        this.isChecked = true;
        /* console.log('VALID: ' + this.ticketForm.valid);
      Object.keys(this.ticketForm.controls).forEach(key => {
          const controlErrors: ValidationErrors = this.ticketForm.get(key).errors;
          if (controlErrors != null) {
              Object.keys(controlErrors).forEach(keyError => {
                  console.log('Key control: ' + key + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError]);
              });
          }
      });*/
        this.officeForm.markAllAsTouched();
        this.ref.markForCheck();
    }
}
