import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnInit,
    ViewChild,
} from '@angular/core';
import {Vacation} from '@src/app/_models/vacation/vacation';
import moment, {MomentInput} from 'moment-business-days';
import localeCs from '@angular/common/locales/cs';
import {registerLocaleData} from '@angular/common';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthenticationService} from '@src/app/_services/authentication.service';
import {addClass, extend} from '@syncfusion/ej2-base';
import {
    CardRenderedEventArgs,
    CardSettingsModel,
    ColumnsModel,
    DialogEventArgs,
    DialogSettingsModel,
    KanbanComponent,
    SwimlaneSettingsModel,
} from '@syncfusion/ej2-angular-kanban';
import {Tickets} from '@src/app/_models/ticket/tickets';
import {Tags} from '@src/app/_models/tags/tags';
import {User} from '@src/app/_models/user/user';
import {Department} from '@src/app/_models/department/department';
import {Hotline} from '@src/app/_models/hotline/hotline';
import {Task} from '@src/app/_models/task/task';
import {VacationService} from '@src/app/features/vacation/vacation.service';

moment.updateLocale('cs', {workingWeekdays: [1, 2, 3, 4, 5]});
registerLocaleData(localeCs);

interface CardData {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    Id: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    Title: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    Status: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    Summary: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    Type: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    Priority: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    Tags: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    Estimate: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    Assignee: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    RankId: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    Color: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    ClassName: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    StartDate: string | null;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    EndDate: string | null;
}

@Component({
    selector: 'app-vacation-status',
    templateUrl: './vacation-status.component.html',
    styleUrls: ['./vacation-status.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VacationStatusComponent implements OnInit {
    currentUser: User | null;

    cardData: CardData[] = [];

    cardsLoading = true;

    kanbanData = extend([], this.cardData, {}, true) as object;

    columns: ColumnsModel[] = [
        {headerText: 'Tikety', keyField: 'Ticket', allowToggle: true},
        {headerText: 'Úkoly', keyField: 'Task', allowToggle: true},
        {headerText: 'Hot-Line', keyField: 'Hotline', allowToggle: true},
        {headerText: 'Žádanky', keyField: 'Vacation', allowToggle: true},
    ];

    cardSettings: CardSettingsModel = {
        headerField: 'Title',
        template: '#cardTemplate',
        selectionType: 'Multiple',
    };

    dialogSettings: DialogSettingsModel = {
        fields: [
            {text: 'ID', key: 'Title', type: 'TextBox'},
            {key: 'Type', type: 'DropDown'},
            {key: 'Assignee', type: 'DropDown'},
            {key: 'RankId', type: 'TextBox'},
            {key: 'Summary', type: 'TextArea'},
        ],
    };

    swimlaneSettings: SwimlaneSettingsModel = {keyField: 'Assignee'};

    @ViewChild('kanbanObj') kanbanObj: KanbanComponent;

    constructor(
        private readonly route: ActivatedRoute,
        private readonly router: Router,
        private readonly ref: ChangeDetectorRef,
        private readonly vacationService: VacationService,
        private readonly authenticationService: AuthenticationService,
    ) {
        this.currentUser = this.authenticationService.currentUserValue;
    }

    ngOnInit(): void {
        this.vacationService.vacationCheckSource.subscribe(
            (data: {
                // eslint-disable-next-line @typescript-eslint/naming-convention
                ticket_indue: Tickets[];
                // eslint-disable-next-line @typescript-eslint/naming-convention
                task_indue: Task[];
                // eslint-disable-next-line @typescript-eslint/naming-convention
                hotline_indue: Hotline[];
                // eslint-disable-next-line @typescript-eslint/naming-convention
                vacation_indue: Vacation[];
            }) => {
                this.cardData = [];

                if (data.ticket_indue.length > 0) {
                    data.ticket_indue.map((ticket: Tickets) => {
                        const tags: string[] = [];

                        ticket.tags.map((tag: Tags) => {
                            tags.push(tag.name);
                        });

                        ticket.users.map((user: User) => {
                            if (!ticket.deleted_date && !ticket.finished_at) {
                                this.cardData.push({
                                    Id: ticket.id,
                                    Title: `Ticket - ${ticket.id}`,
                                    Status: 'Open',
                                    Summary: ticket.subject,
                                    Type: 'Ticket',
                                    Priority: 'High',
                                    Tags: tags.length > 0 ? tags.toString() : '',
                                    Estimate: 3.5,
                                    Assignee: `${user.firstname} ${user.secondname}`,
                                    StartDate: ticket.start_deadline
                                        ? moment(ticket.start_deadline as MomentInput)
                                            .format(
                                                'YYYY-MM-DD HH:mm',
                                            )
                                        : null,
                                    EndDate: ticket.deadline
                                        ? moment(ticket.deadline)
                                            .format('YYYY-MM-DD HH:mm')
                                        : null,
                                    RankId: 1,
                                    Color: '#0251cc',
                                    ClassName: 'e-story, e-low, e-nancy-davloio',
                                });
                            }
                        });

                        ticket.departments.map((department: Department) => {
                            if (!ticket.deleted_date && !ticket.finished_at) {
                                this.cardData.push({
                                    Id: ticket.id,
                                    Title: `Ticket - ${ticket.id}`,
                                    Status: 'Open',
                                    Summary: ticket.subject,
                                    Type: 'Ticket',
                                    Priority: 'High',
                                    Tags: tags.length > 0 ? tags.toString() : '',
                                    Estimate: 3.5,
                                    Assignee: department.name,
                                    StartDate: ticket.start_deadline
                                        ? moment(ticket.start_deadline)
                                            .format('YYYY-MM-DD HH:mm')
                                        : null,
                                    EndDate: ticket.deadline
                                        ? moment(ticket.deadline)
                                            .format('YYYY-MM-DD HH:mm')
                                        : null,
                                    RankId: 1,
                                    Color: '#0251cc',
                                    ClassName: 'e-story, e-low, e-nancy-davloio',
                                });
                            }
                        });
                    });
                }

                if (data.task_indue.length > 0) {
                    data.task_indue.map((task: Task) => {
                        const tags: string[] = [];

                        task.tags.map((tag: Tags) => {
                            tags.push(tag.name);
                        });

                        if (!task.deleted_date && !task.finished_at) {
                            this.cardData.push({
                                Id: task.id,
                                Title: `Task - ${task.id}`,
                                Status: 'Open',
                                Summary: task.subject,
                                Type: 'Task',
                                Priority: 'High',
                                Tags: tags.length > 0 ? tags.toString() : '',
                                Estimate: 3.5,
                                Assignee: `${task.solver.firstname} ${task.solver.secondname}`,
                                StartDate: moment(task.start_deadline)
                                    .format('YYYY-MM-DD HH:mm'),
                                EndDate: moment(task.deadline)
                                    .format('YYYY-MM-DD HH:mm'),
                                RankId: 1,
                                Color: '#ea9713',
                                ClassName: 'e-story, e-low, e-nancy-davloio',
                            });
                        }
                    });
                }

                if (data.hotline_indue.length > 0) {
                    data.hotline_indue.map((hotline: Hotline) => {
                        if (!hotline.deleted_date) {
                            this.cardData.push({
                                Id: hotline.id,
                                Title: `Hotline - ${hotline.id}`,
                                Status: 'Open',
                                Summary: `${hotline.company.name} / ${hotline.type}`,
                                Type: 'Hotline',
                                Priority: 'High',
                                Tags: 'hot-line',
                                Estimate: 3.5,
                                Assignee: `${hotline.hotliner.user.firstname} ${hotline.hotliner.user.secondname}`,
                                StartDate: moment(hotline.start_at)
                                    .format('YYYY-MM-DD HH:mm'),
                                EndDate: moment(hotline.end_at)
                                    .format('YYYY-MM-DD HH:mm'),
                                RankId: 1,
                                Color: '#8e4399',
                                ClassName: 'e-story, e-low, e-nancy-davloio',
                            });
                        }
                    });
                }

                if (data.vacation_indue.length > 0) {
                    data.vacation_indue.map((vacationData: Vacation) => {
                        if (!vacationData.deleted_date) {
                            this.cardData.push({
                                Id: vacationData.id,
                                Title: `Žádanka - ${vacationData.id}`,
                                Status: 'Open',
                                Summary: vacationData.category.name,
                                Type: 'Vacation',
                                Priority: 'Low',
                                Tags: 'absence',
                                Estimate: 3.5,
                                Assignee: `${vacationData.creator.firstname} ${vacationData.creator.secondname}`,
                                StartDate: moment(vacationData.start_at)
                                    .format('YYYY-MM-DD HH:mm'),
                                EndDate: moment(vacationData.end_at)
                                    .format('YYYY-MM-DD HH:mm'),
                                RankId: 1,
                                Color: '#63ba3c',
                                ClassName: 'e-story, e-low, e-nancy-davloio',
                            });
                        }
                    });
                }

                this.kanbanData = extend([], this.cardData, {}, true) as object[];
                this.cardsLoading = false;
            },
        );
    }

    getString(assignee: string): string | undefined {
        const match = assignee.match(/\b(\w)/g);

        if (match) {
            return match.join('')
                .toUpperCase();
        }
    }

    cardRendered(args: CardRenderedEventArgs): void {
        const val: string = (args.data as {[key: string]: never}).Priority as string;

        addClass([args.element], val);
    }

    dialogOpen(args: DialogEventArgs): void {
        args.cancel = true;
    }
}
