import {Injectable} from '@angular/core';
import {ApisService} from '@src/app/_api/apis.service';
import {ApiPaths} from '@src/app/_models/api/paths';
import {BehaviorSubject, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class MonitoringService {
    // Subscribers
    private readonly destroy$ = new Subject();

    private readonly resultSiteMonitorUsersSource = new BehaviorSubject<
        MonitoringService | unknown | null | undefined
    >(null);

    siteMonitorUsersSource = this.resultSiteMonitorUsersSource.asObservable();

    constructor(private readonly apisService: ApisService) {}

    setMonitoringUsers(path: ApiPaths | undefined): void {
        if (typeof path !== 'undefined') {
            this.apisService
                .getRemoteData(4, path.maps)
                .pipe(takeUntil(this.destroy$))
                .subscribe({
                    next: (data: unknown[] | null | undefined) => {
                        this.resultSiteMonitorUsersSource.next(data);
                    },
                    error: error => {
                        console.error(error);
                    },
                });
        }
    }
}
