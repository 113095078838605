<ejs-toolbar
  #moduleToolbar
  id="moduleToolbar"
  overflowMode="Scrollable"
  scrollStep="300"
>
  <e-items>
    <e-item [align]="'center'" tooltipText="nový NET tarif" type="button">
      <ng-template #template>
        <button
          (click)="navigateToTab('tarifs', 'addNetPackage')"
          cssClass="e-btn e-primary btn-p-zero e-small"
          ejs-button
          type="button"
        >
          <i aria-hidden="true" class="fa-light fa-plus mr-1"></i>nový
          NET tarif
        </button>
      </ng-template>
    </e-item>
    <e-item type="Separator"></e-item>
    <e-item [align]="'center'" tooltipText="nový TV tarif" type="button">
      <ng-template #template>
        <button
          (click)="navigateToTab('tarifs', 'addTvService')"
          cssClass="e-btn e-primary btn-p-zero e-small"
          ejs-button
          type="button"
        >
          <i aria-hidden="true" class="fa-light fa-plus mr-1"></i>nový
          TV tarif
        </button>
      </ng-template>
    </e-item>
    <e-item type="Separator"></e-item>
    <e-item [align]="'center'" tooltipText="nový TV balíček" type="button">
      <ng-template #template>
        <button
          (click)="navigateToTab('tvpackages', 'addTvPackage')"
          cssClass="e-btn e-primary btn-p-zero e-small"
          ejs-button
          type="button"
        >
          <i aria-hidden="true" class="fa-light fa-plus mr-1"></i>nový
          TV balíček
        </button>
      </ng-template>
    </e-item>
    <e-item type="Separator"></e-item>
    <e-item [align]="'center'" tooltipText="nový TV kanál" type="button">
      <ng-template #template>
        <button
          (click)="navigateToTab('tvchannels', 'addTvChannel')"
          cssClass="e-btn e-primary btn-p-zero e-small"
          ejs-button
          type="button"
        >
          <i aria-hidden="true" class="fa-light fa-plus mr-1"></i>nový
          TV kanál
        </button>
      </ng-template>
    </e-item>
    <e-item type="Separator"></e-item>
    <e-item [align]="'center'" tooltipText="nové zařízení" type="button">
      <ng-template #template>
        <button
          (click)="navigateToTab('hardware', 'addHardware')"
          cssClass="e-btn e-primary btn-p-zero e-small"
          ejs-button
          type="button"
        >
          <i aria-hidden="true" class="fa-light fa-plus mr-1"></i>nové
          zařízení
        </button>
      </ng-template>
    </e-item>
    <e-item type="Separator"></e-item>
    <e-item [align]="'center'" tooltipText="nová sleva" type="button">
      <ng-template #template>
        <button
          (click)="navigateToTab('discounts', 'addDiscount')"
          cssClass="e-btn e-primary btn-p-zero e-small"
          ejs-button
          type="button"
        >
          <i aria-hidden="true" class="fa-light fa-plus mr-1"></i>nová
          sleva
        </button>
      </ng-template>
    </e-item>
    <e-item type="Separator"></e-item>
    <e-item [align]="'center'" tooltipText="nová lokace" type="button">
      <ng-template #template>
        <button
          (click)="navigateToTab('locations', 'addPlace')"
          cssClass="e-btn e-primary btn-p-zero e-small"
          ejs-button
          type="button"
        >
          <i aria-hidden="true" class="fa-light fa-plus mr-1"></i>nová
          lokace
        </button>
      </ng-template>
    </e-item>
    <e-item type="Separator"></e-item>
    <e-item [align]="'center'" tooltipText="nový bonus" type="button">
      <ng-template #template>
        <button
          (click)="navigateToTab('bonuses', 'addBonus')"
          cssClass="e-btn e-primary btn-p-zero e-small"
          ejs-button
          type="button"
        >
          <i aria-hidden="true" class="fa-light fa-plus mr-1"></i>nový
          bonus
        </button>
      </ng-template>
    </e-item>
    <e-item type="Separator"></e-item>
    <e-item
      [align]="'center'"
      tooltipText="nová rozšiřující služba"
      type="button"
    >
      <ng-template #template>
        <button
          (click)="navigateToTab('extservices', 'addExtService')"
          cssClass="e-btn e-primary btn-p-zero e-small"
          ejs-button
          type="button"
        >
          <i aria-hidden="true" class="fa-light fa-plus mr-1"></i>nová
          rozšiřující služba
        </button>
      </ng-template>
    </e-item>
  </e-items>
</ejs-toolbar>
<ejs-tab
  #servicesTab
  (created)="onCreate()"
  (selected)="select($event)"
  (selecting)="preventSwipe($event)"
  enablePersistence="true"
  height="auto"
  heightAdjustMode="Content"
  id="servicesTab"
>
  <e-tabitems>
    <e-tabitem [header]="headerText[0]">
      <ng-template #content>
        <app-services-stats></app-services-stats>
      </ng-template>
    </e-tabitem>
    <e-tabitem [header]="headerText[1]">
      <ng-template #content>
        <app-services-tarifs></app-services-tarifs>
      </ng-template>
    </e-tabitem>
    <e-tabitem [header]="headerText[2]">
      <ng-template #content>
        <app-services-tv-packages></app-services-tv-packages>
      </ng-template>
    </e-tabitem>
    <e-tabitem [header]="headerText[3]">
      <ng-template #content>
        <app-services-channels></app-services-channels>
      </ng-template>
    </e-tabitem>
    <e-tabitem [header]="headerText[4]">
      <ng-template #content>
        <app-services-hardware></app-services-hardware>
      </ng-template>
    </e-tabitem>
    <e-tabitem [header]="headerText[5]">
      <ng-template #content>
        <app-services-discounts></app-services-discounts>
      </ng-template>
    </e-tabitem>
    <e-tabitem [header]="headerText[6]">
      <ng-template #content>
        <app-services-places></app-services-places>
      </ng-template>
    </e-tabitem>
    <e-tabitem [header]="headerText[7]">
      <ng-template #content>
        <app-services-bonuses></app-services-bonuses>
      </ng-template>
    </e-tabitem>
    <e-tabitem [header]="headerText[8]">
      <ng-template #content>
        <app-services-extended-services></app-services-extended-services>
      </ng-template>
    </e-tabitem>
    <e-tabitem [header]="headerText[9]">
      <ng-template #content>
        <app-services-settings></app-services-settings>
      </ng-template>
    </e-tabitem>
  </e-tabitems>
</ejs-tab>
