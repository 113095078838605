import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    HostListener,
    Input,
    OnChanges,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import * as crypto from 'crypto-js';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {ComponentCanDeactivate} from '@src/app/_guards/changes.guard';
import {DialogComponent} from '@syncfusion/ej2-angular-popups';
import {Observable} from 'rxjs';
import {User} from '@src/app/_models/user/user';
import {UserInput, UsersService} from '@src/app/features/users/users.service';
import {MessageService} from '@src/app/_services/message.service';

@UntilDestroy()
@Component({
    selector: 'app-user-edit-password-form',
    templateUrl: './user-edit-password-form.component.html',
    styleUrls: ['./user-edit-password-form.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserEditPasswordFormComponent implements OnInit, OnChanges, ComponentCanDeactivate {
    // forms
    changePasswordForm: FormGroup;

    isDirty = false;

    isChecked = false;

    submited = false;

    // Loaders
    loadingUser = false;

    @Input() user: User | null = null;

    @Input() isVisible = false;

    @Output() readonly passwordEditFormDialogState = new EventEmitter<boolean>();

    // Dialogs
    @ViewChild('formDialog') formDialogObj: DialogComponent;

    constructor(
        private readonly formBuilder: FormBuilder,
        private readonly route: ActivatedRoute,
        private readonly router: Router,
        private readonly userService: UsersService,
        private readonly message: MessageService,
        private readonly ref: ChangeDetectorRef,
    ) {}

    get f(): {[key: string]: AbstractControl} {
        return this.changePasswordForm.controls;
    }

    @HostListener('window:beforeunload')
    canDeactivate(): Observable<boolean> | boolean {
        return !this.isDirty;
    }

    ngOnInit(): void {
        this.changePasswordForm = this.formBuilder.group({
            oldPassword: ['', Validators.required],
            newPassword: ['', Validators.required],
            repeatPassword: ['', Validators.required],
        });

        this.changePasswordForm.valueChanges.pipe(untilDestroyed(this))
            .subscribe(() => {
                this.isDirty = this.changePasswordForm.dirty;
                this.ref.markForCheck();
            });
    }

    ngOnChanges(): void {
        if (this.isVisible && this.user) {
            this.changePasswordForm.reset();
            this.formDialogObj.header = `Heslo uživatele #${this.user.id} - ${this.user.fullname}`;
            this.showInputErrors();
        }
    }

    changeDialogState(value: boolean): void {
        this.passwordEditFormDialogState.emit(value);
    }

    onSubmit(): void {
        this.submited = true;

        if (this.changePasswordForm.invalid) {
            console.error('form is not valid!');

            return;
        }

        this.loadingUser = true;

        if (this.user && this.f.repeatPassword.value === this.f.newPassword.value) {
            const hashedOldPass = crypto.SHA1(this.f.oldPassword.value)
                .toString(crypto.enc.Hex);

            if (hashedOldPass === this.user.password) {
                this.user.password = this.f.newPassword.value;
                this.userService.updateUser(this.user as UserInput)
                    ?.subscribe(
                        () => {
                            const body = 'Úspěšně jste změnili svoje heslo';
                            const options = {
                                progressBar: true,
                                timeOut: 5000,
                                toastClass: 'success',
                            };

                            this.message.showSuccess('Heslo změněno', body, options);
                            this.loadingUser = false;
                            this.ref.markForCheck();
                            this.formDialogObj.hide();
                        },
                        error => {
                            console.error(error);

                            const body = 'Zkuste to znovu později';
                            const options = {
                                progressBar: true,
                                timeOut: 5000,
                                toastClass: 'red',
                            };

                            this.message.showError('Chyba při změně hesla', body, options);
                            this.loadingUser = false;
                            this.ref.markForCheck();
                        },
                    );
            } else {
                const body = 'Zkuste to ještě jednou...';
                const options = {progressBar: true, timeOut: 5000, toastClass: 'red'};

                this.message.showError('Staré heslo není správné!', body, options);
                this.loadingUser = false;
                this.ref.markForCheck();
            }
        } else {
            const body = 'Zkuste to ještě jednou...';
            const options = {progressBar: true, timeOut: 5000, toastClass: 'red'};

            this.message.showError('Hesla se neshodují!', body, options);
            this.loadingUser = false;
            this.ref.markForCheck();
        }
    }

    showInputErrors(): void {
        this.isChecked = true;
        /* console.log('VALID: ' + this.ticketForm.valid);
        Object.keys(this.ticketForm.controls).forEach(key => {
            const controlErrors: ValidationErrors = this.ticketForm.get(key).errors;
            if (controlErrors != null) {
                Object.keys(controlErrors).forEach(keyError => {
                    console.log('Key control: ' + key + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError]);
                });
            }
        });*/
        this.changePasswordForm.markAllAsTouched();
        this.ref.markForCheck();
    }
}
