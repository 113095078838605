import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule, Routes} from '@angular/router';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {AuthGuard} from '@src/app/_guards/auth.guard';
import {ExcelService} from '@src/app/_services/excel.service';
import {PendingChangesGuard} from '@src/app/_guards/changes.guard';
import {HotlineCalendarComponent} from '@src/app/features/hotline/components/hotline-calendar/hotline-calendar.component';
import {SharedFeaturesModule} from '@src/app/shared_features/shared-features.module';
import {NotificationService} from '@src/app/features/notifications/notification.service';
import {NotificationListComponent} from './components/list/notification-list.component';
import {NotificationDashboardComponent} from './components/dashboard/notification-dashboard.component';
import {NotificationDeatilComponent} from './components/deatil/notification-detail.component';

const routes: Routes = [
    {
        path: 'notifications',
        children: [
            {
                path: '',
                component: NotificationDashboardComponent,
                data: {
                    title: 'Notifikace nástěnka',
                    breadcrumb: [
                        {
                            label: 'Notifikace',
                            url: '',
                        },
                    ],
                },
            },
            {
                path: 'create',
                component: HotlineCalendarComponent,
                data: {
                    title: 'Vytvořit hotline',
                    breadcrumb: [
                        {
                            label: 'Hot-Line',
                            url: '/hotline',
                        },
                        {
                            label: 'Vytvořit hotline',
                            url: '',
                        },
                    ],
                },
                canDeactivate: [PendingChangesGuard],
            },
            {
                path: 'list',
                component: NotificationListComponent,
                data: {
                    title: 'Seznam notifikací',
                    breadcrumb: [
                        {
                            label: 'Notifikace',
                            url: '/notifications',
                        },
                        {
                            label: 'Fronta notifikací',
                            url: '',
                        },
                    ],
                },
            },
            {
                path: 'notification/:id',
                component: NotificationDeatilComponent,
                data: {
                    title: 'Detail notifikace',
                    breadcrumb: [
                        {
                            label: 'Notifikace',
                            url: '/notifications',
                        },
                        {
                            label: 'Detail notifikace',
                            url: '',
                        },
                    ],
                },
            },
        ],
        canActivate: [AuthGuard],
    },
];

@NgModule({
    declarations: [
        NotificationDashboardComponent,
        NotificationDeatilComponent,
        NotificationListComponent,
    ],
    imports: [
        BrowserAnimationsModule,
        RouterModule.forChild(routes),
        BrowserModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        SharedFeaturesModule,
    ],
    providers: [ExcelService, NotificationService],
    exports: [NotificationListComponent],
})
export class NotificationModule {}
