import {NgxDocViewerComponent} from '@src/app/_viewer/document-viewer.component';
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

@NgModule({
    imports: [CommonModule],
    declarations: [NgxDocViewerComponent],
    exports: [NgxDocViewerComponent],
})
export class NgxDocViewerModule {}
