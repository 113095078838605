import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    HostListener,
    Input,
    OnChanges,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {ComponentCanDeactivate} from '@src/app/_guards/changes.guard';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {DialogComponent} from '@syncfusion/ej2-angular-popups';
import {Observable} from 'rxjs';
import {AuthenticationService} from '@src/app/_services/authentication.service';
import {User} from '@src/app/_models/user/user';
import {Building} from '@src/app/_models/buildings/building.model';
import {EmitType} from '@syncfusion/ej2-base';
import {
    DropDownListComponent,
    FilteringEventArgs,
    MultiSelectComponent,
} from '@syncfusion/ej2-angular-dropdowns';
import {Query} from '@syncfusion/ej2-data';
import {Company} from '@src/app/_models/company/company';
import {DataNetbaseService} from '@src/app/_services/data-netbase.service';
import {Office} from '@src/app/_models/office/office.model';
import {AddressObj} from '@src/app/features/services/_forms/callwizard/types/smartform-address.type';
import {DataService} from '@src/app/_services/data.service';
import {MessageService} from '@src/app/_services/message.service';
import {BuildingInput} from '@src/app/features/settings/types/building-input.type';
import {UsersService} from '../../../users/users.service';
import {SettingsService} from '../../settings.service';

@UntilDestroy()
@Component({
    selector: 'app-building-form',
    templateUrl: './building-form.component.html',
    styleUrls: ['./building-form.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BuildingFormComponent implements OnInit, OnChanges, ComponentCanDeactivate {
    // Variables
    currentUser: User | null;

    // Loaders
    loadingBuilding = false;

    addressObj: AddressObj = {
        city: '',
        cityCode: 0,
        cityPart: '',
        district: '',
        districtCode: 0,
        gps: '',
        houseNumber: 0,
        jstk: '',
        orientalNumber: 0,
        partCode: 0,
        region: '',
        regionCode: 0,
        ruianCode: null,
        searchedAddress: null,
        state: '',
        street: '',
        streetCode: 0,
        zip: 0,
        buildingCode: 0,
        orientalNumberChar: 0,
        provisionalNumber: 0,
        wholeNumber: '',
        post: '',
        stateCode: 0,
        fieldNumber: 0,
        fieldNumber2: 0,
        cadastral: '',
        cadastralCode: 0,
        lift: false,
        numberOfStoreys: 0,
        numberOfFlats: 0,
        floorArea: 0,
    };

    // Forms
    buildingForm: FormGroup;

    isDirty = false;

    isChecked = false;

    submited = false;

    // Dropdowms
    height = '240px';

    fields: object = {text: 'label', value: 'value'};

    companySelect: Array<{value: boolean | number | string; label: string}> = [];

    officesSelect: Array<{value: boolean | number | string; label: string}> = [];

    @ViewChild('company') companyObj: DropDownListComponent;

    @ViewChild('offices') officesObj: MultiSelectComponent;

    @Input() building: Building | null = null;

    @Input() isCreate = false;

    @Input() isUpdate = false;

    @Input() isVisible = false;

    @Input() isCopy = false;

    @Output() readonly buildingFormDialogState = new EventEmitter<boolean>();

    // Dialogs
    @ViewChild('formDialog') formDialogObj: DialogComponent;

    constructor(
        private readonly formBuilder: FormBuilder,
        private readonly route: ActivatedRoute,
        private readonly router: Router,
        private readonly dataService: DataService,
        private readonly dataNetbaseService: DataNetbaseService,
        private readonly settingsService: SettingsService,
        private readonly authenticationService: AuthenticationService,
        private readonly userService: UsersService,
        private readonly message: MessageService,
        private readonly ref: ChangeDetectorRef,
    ) {
        this.currentUser = this.authenticationService.currentUserValue;

        this.dataService.setCompanyDataSource();
        this.dataService.setOfficesDataSource();
    }

    get f(): {[key: string]: AbstractControl} {
        return this.buildingForm.controls;
    }

    @HostListener('window:beforeunload')
    canDeactivate(): Observable<boolean> | boolean {
        return !this.isDirty;
    }

    // eslint-disable-next-line complexity
    ngOnInit(): void {
        this.dataService.companySource.pipe(untilDestroyed(this))
            .subscribe(
                (companies: Company[] | undefined) => {
                    this.companySelect = [];
                    companies?.map((company: Company) => {
                        this.companySelect = [
                            ...this.companySelect,
                            {
                                value: company.id,
                                label: company.name,
                            },
                        ];
                    });
                },
                error => {
                    console.error(error);
                },
            );

        this.dataService.officesSource.pipe(untilDestroyed(this))
            .subscribe(
                (offices: Office[] | undefined) => {
                    offices?.map((office: Office) => {
                        this.officesSelect = [
                            ...this.officesSelect,
                            {
                                value: office.id,
                                label: office.name,
                            },
                        ];
                    });
                },
                error => {
                    console.error(error);
                },
            );

        this.buildingForm = this.formBuilder.group({
            company_id: [null, Validators.compose([Validators.required])],
            // offices: [[], Validators.compose([Validators.required])],
            name: [
                '',
                Validators.compose([
                    Validators.required,
                    Validators.minLength(2),
                    Validators.maxLength(100),
                ]),
            ],
            offices: [[], Validators.compose([])],
            oriental_number: [
                {value: this.building?.address_obj?.oriental_number ?? null, disabled: true},
            ],
            house_number: [
                {value: this.building?.address_obj?.house_number ?? null, disabled: true},
                Validators.required,
            ],
            ruian: [
                {value: this.building?.address_obj?.ruian ?? null, disabled: true},
                Validators.required,
            ],
            city: [{value: this.building?.address_obj?.city ?? '', disabled: true}],
            city_code: [{value: this.building?.address_obj?.city_code ?? null, disabled: true}],
            zip: [{value: this.building?.address_obj?.zip ?? null, disabled: true}],
            district: [{value: this.building?.address_obj?.district ?? '', disabled: true}],
            district_code: [
                {value: this.building?.address_obj?.district_code ?? null, disabled: true},
            ],
            street: [{value: this.building?.address_obj?.street ?? '', disabled: true}],
            street_code: [
                {value: this.building?.address_obj?.street_code ?? null, disabled: true},
            ],
            city_part: [{value: this.building?.address_obj?.city_part ?? '', disabled: true}],
            city_part_code: [
                {value: this.building?.address_obj?.city_part_code ?? null, disabled: true},
            ],
            region: [{value: this.building?.address_obj?.region ?? '', disabled: true}],
            region_code: [
                {value: this.building?.address_obj?.region_code ?? null, disabled: true},
            ],
            state: [{value: this.building?.address_obj?.state ?? '', disabled: true}],
            gps: [{value: this.building?.address_obj?.gps ?? null, disabled: true}],
            jstk: [{value: this.building?.address_obj?.jstk ?? null, disabled: true}],
        });

        this.buildingForm.valueChanges.pipe(untilDestroyed(this))
            .subscribe(() => {
                this.isDirty = this.buildingForm.dirty;
                this.showInputErrors();
            });
    }

    ngOnChanges(): void {
        if (this.isVisible) {
            const queryParams: Params = {form: null};

            void this.router.navigate([], {
                relativeTo: this.route,
                queryParams,
                queryParamsHandling: 'merge',
            });

            if (this.isUpdate && this.building) {
                this.buildingForm.reset();
                this.formDialogObj.header = `Budova #${this.building.id} - ${this.building.name}`;
                this.fillTheForm();
                this.showInputErrors();
            }

            if (this.isCreate) {
                this.buildingForm.reset();
                this.formDialogObj.header = 'Nová budova';
            }

            if (this.isCopy) {
                this.buildingForm.reset();
                this.formDialogObj.header = 'Nová budova';
                this.fillTheForm();
                this.showInputErrors();
            }
        }
    }

    addressEventHandler(event: AddressObj): void {
        this.addressObj = event;
        this.f.oriental_number.patchValue(this.addressObj.orientalNumber);
        this.f.house_number.patchValue(this.addressObj.houseNumber);
        this.f.ruian.patchValue(this.addressObj.ruianCode);
        this.f.city.patchValue(this.addressObj.city);
        this.f.city_code.patchValue(this.addressObj.cityCode);
        this.f.zip.patchValue(this.addressObj.zip);
        this.f.district.patchValue(this.addressObj.district);
        this.f.district_code.patchValue(this.addressObj.districtCode);
        this.f.street.patchValue(this.addressObj.street);
        this.f.street_code.patchValue(this.addressObj.streetCode);
        this.f.city_part.patchValue(this.addressObj.cityPart);
        this.f.city_part_code.patchValue(this.addressObj.partCode);
        this.f.region.patchValue(this.addressObj.region);
        this.f.region_code.patchValue(this.addressObj.regionCode);
        this.f.state.patchValue(this.addressObj.state);
        this.f.gps.patchValue(this.addressObj.gps);
        this.f.jstk.patchValue(this.addressObj.jstk);
    }

    onFilteringCompanies: EmitType<FilteringEventArgs> = (e: FilteringEventArgs) => {
        if (e.text === '') {
            e.updateData(this.companySelect);
        } else {
            let query: Query = new Query();

            query = e.text !== '' ? query.where('label', 'contains', e.text, true, true) : query;
            e.updateData(this.companySelect, query);
        }
    };

    onFilteringOffices: EmitType<FilteringEventArgs> = (e: FilteringEventArgs) => {
        if (e.text === '') {
            e.updateData(this.officesSelect);
        } else {
            let query: Query = new Query();

            query = e.text !== '' ? query.where('label', 'contains', e.text, true, true) : query;
            e.updateData(this.officesSelect, query);
        }
    };

    changeDialogState(value: boolean): void {
        this.buildingFormDialogState.emit(value);
    }

    fillTheForm(): void {
        const offices: number[] = [];

        this.building?.offices.forEach(office => {
            offices.push(office.id);
        });
        this.f.name.patchValue(this.building?.name);
        this.f.company_id.patchValue(this.building?.company_id);
        this.f.offices.patchValue(offices);
        this.f.oriental_number.patchValue(this.building?.address_obj?.oriental_number);
        this.f.house_number.patchValue(this.building?.address_obj?.house_number);
        this.f.ruian.patchValue(this.building?.address_obj?.ruian);
        this.f.city.patchValue(this.building?.address_obj?.city);
        this.f.city_code.patchValue(this.building?.address_obj?.city_code);
        this.f.zip.patchValue(this.building?.address_obj?.zip);
        this.f.district.patchValue(this.building?.address_obj?.district);
        this.f.district_code.patchValue(this.building?.address_obj?.district_code);
        this.f.street.patchValue(this.building?.address_obj?.street);
        this.f.street_code.patchValue(this.building?.address_obj?.street_code);
        this.f.city_part.patchValue(this.building?.address_obj?.city_part);
        this.f.city_part_code.patchValue(this.building?.address_obj?.city_part_code);
        this.f.region.patchValue(this.building?.address_obj?.region);
        this.f.region_code.patchValue(this.building?.address_obj?.region_code);
        this.f.state.patchValue(this.building?.address_obj?.state);
        this.f.gps.patchValue(this.building?.address_obj?.gps);
        this.f.jstk.patchValue(this.building?.address_obj?.jstk);
    }

    onSubmit(): void {
        this.submited = true;
        this.isDirty = false;
        this.loadingBuilding = true;

        if (this.isCreate || this.isCopy) {
            this.createBuilding();
        }

        if (this.isUpdate) {
            this.editBuilding();
        }
    }

    createBuilding(): void {
        this.loadingBuilding = true;
        this.ref.markForCheck();

        if (this.buildingForm.invalid) {
            console.error('form is not valid!');

            return;
        }

        const building: BuildingInput = {
            company_id: this.f.company_id.value,
            name: this.f.name.value,
            offices: this.f.offices.value,
            address: this.addressObj.searchedAddress,
            oriental_number: this.f.oriental_number.value ?? null,
            house_number: this.f.house_number.value ?? null,
            ruian: this.f.ruian.value ?? null,
            street: this.f.street.value ?? null,
            street_code: this.f.street_code.value ?? null,
            city: this.f.city.value,
            city_code: this.f.city_code.value ?? null,
            city_part: this.f.city_part.value,
            city_part_code: this.f.city_part_code.value ?? null,
            district: this.f.district.value,
            district_code: this.f.district_code.value ?? null,
            region: this.f.region.value,
            region_code: this.f.region_code.value ?? null,
            state: this.f.state.value,
            gps: this.f.gps.value ?? null,
            jstk: this.f.jstk.value ?? null,
            zip: this.f.zip.value,
            created_by: this.currentUser?.id,
        };

        this.settingsService
            .addBuilding(building)
            .pipe(untilDestroyed(this))
            .subscribe(
                () => {
                    const body = 'Úspěšně jste vytvořili budovu';
                    const options = {
                        progressBar: true,
                        timeOut: 5000,
                        toastClass: 'success',
                    };

                    this.message.showSuccess('Budova vytvořena', body, options);
                    this.dataService.setBuildingsDataSource();
                    this.isCreate = false;
                    this.loadingBuilding = false;
                    this.ref.markForCheck();
                    this.formDialogObj.hide();
                },
                error => {
                    console.error(error);

                    const body = 'Zkuste to znovu později';
                    const options = {
                        progressBar: true,
                        timeOut: 5000,
                        toastClass: 'red',
                    };

                    this.message.showError('Chyba při vytváření budovy', body, options);
                    this.loadingBuilding = false;
                    this.ref.markForCheck();
                },
            );
    }

    editBuilding(): void {
        this.loadingBuilding = true;
        this.ref.markForCheck();

        if (this.buildingForm.invalid) {
            console.error('form is not valid!');

            return;
        }

        const building: BuildingInput = {
            company_id: this.f.company_id.value,
            name: this.f.name.value,
            offices: this.f.offices.value,
            oriental_number: this.f.oriental_number.value ?? null,
            house_number: this.f.house_number.value ?? null,
            ruian: this.f.ruian.value ?? null,
            street: this.f.street.value ?? null,
            street_code: this.f.street_code.value ?? null,
            city: this.f.city.value,
            city_code: this.f.city_code.value ?? null,
            city_part: this.f.city_part.value,
            city_part_code: this.f.city_part_code.value ?? null,
            district: this.f.district.value,
            district_code: this.f.district_code.value ?? null,
            region: this.f.region.value,
            region_code: this.f.region_code.value ?? null,
            state: this.f.state.value,
            gps: this.f.gps.value ?? null,
            jstk: this.f.jstk.value ?? null,
            zip: this.f.zip.value,
            updated_by: this.currentUser?.id,
        };

        this.settingsService
            .editBuilding(building)
            .pipe(untilDestroyed(this))
            .subscribe(
                () => {
                    const body = 'Úspěšně jste upravil/a budovu';
                    const options = {
                        progressBar: true,
                        timeOut: 5000,
                        toastClass: 'success',
                    };

                    this.message.showSuccess('Zaměstnavatel upraven', body, options);
                    this.dataService.setBuildingsDataSource();
                    this.isUpdate = false;
                    this.loadingBuilding = false;
                    this.ref.markForCheck();
                    this.formDialogObj.hide();
                },
                error => {
                    console.error(error);

                    const body = 'Zkuste to znovu později';
                    const options = {
                        progressBar: true,
                        timeOut: 5000,
                        toastClass: 'red',
                    };

                    this.message.showError('Chyba při úpravě budovy', body, options);
                    this.loadingBuilding = false;
                    this.ref.markForCheck();
                },
            );
    }

    showInputErrors(): void {
        this.isChecked = true;
        /*  console.log('VALID: ' + this.buildingForm.valid);
        Object.keys(this.buildingForm.controls).forEach(key => {
            const controlErrors: ValidationErrors | undefined | null =
                this.buildingForm.get(key)?.errors;
            if (controlErrors != null) {
                Object.keys(controlErrors).forEach(keyError => {
                    console.log(
                        'Key control: ' + key + ', keyError: ' + keyError + ', err value: ',
                        controlErrors[keyError]
                    );
                });
            }
        });*/
        this.buildingForm.markAllAsTouched();
        this.ref.markForCheck();
    }
}
