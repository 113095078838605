<div class="row my-md-5">
  <div class="col-12 text-center justify-align-center mb-2">
    <h2 class="h2-responsive mt-3 mb-2">404. Nenalezeno!</h2>
    <h4>Požadovaná URL adresa, nebyla nalezena :(</h4>
  </div>
  <div class="col-12 text-center justify-align-center">
    <img
      alt="Error 404"
      class="img-fluid"
      src="../../../../assets/img/wifinak_404.png"
      style="max-height: 450px; width: auto"
    />
  </div>
</div>
