<div class="container p-3">
  <div class="row">
    <div class="col-12">
      <form (submit)="onSubmit()" [formGroup]="registerForm">
        <div class="e-card">
          <div class="card-header">Společnost</div>
          <div class="e-card-content">
            <div class="row">
              <div class="col-md-6 col-12 mb-3">
                <label for="companyName"
                >Název společnosti</label
                >
                <ejs-textbox
                  [ngClass]="{
                                        'e-error':
                                            submited && f.companyName.errors
                                    }"
                  floatLabelType="Auto"
                  formControlName="companyName"
                  id="companyName"
                ></ejs-textbox>
                <div
                  *ngIf="submited && f.companyName.errors"
                  class="invalid-feedback"
                >
                  <div *ngIf="f.companyName.errors.required">
                    Název společnosti je povinný
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-12 mb-3">
                <label for="companyName">IČO</label>
                <ejs-textbox
                  [ngClass]="{
                                        'e-error':
                                            submited && f.companyIco.errors
                                    }"
                  floatLabelType="Auto"
                  formControlName="companyIco"
                  id="companyIco"
                ></ejs-textbox>
                <div
                  *ngIf="submited && f.companyIco.errors"
                  class="invalid-feedback"
                >
                  <div *ngIf="f.companyIco.errors.required">
                    IČO spoelčnosti je povinné
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-12 mb-3">
                <label for="companyName"
                >Adresa společnosti</label
                >
                <ejs-textbox
                  [ngClass]="{
                                        'e-error':
                                            submited && f.adressName.errors
                                    }"
                  floatLabelType="Auto"
                  formControlName="adressName"
                  id="adressName"
                ></ejs-textbox>
                <div
                  *ngIf="submited && f.adressName.errors"
                  class="invalid-feedback"
                >
                  <div *ngIf="f.adressName.errors.required">
                    Adresa spoelčnosti je povinná
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-12 mb-3">
                <label for="companyName"
                >RUIAN adresního místa</label
                >
                <ejs-textbox
                  [ngClass]="{
                                        'e-error':
                                            submited && f.adressName.errors
                                    }"
                  floatLabelType="Auto"
                  formControlName="adressRuian"
                  id="adressRuian"
                ></ejs-textbox>
                <div
                  *ngIf="submited && f.adressRuian.errors"
                  class="invalid-feedback"
                >
                  <div *ngIf="f.adressRuian.errors.required">
                    RUIAN spoelčnosti je povinný
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card-header">Správce společnosti</div>
          <div class="e-card-content">
            <div class="row">
              <div class="col-md-6 col-12 mb-3">
                <label for="userFirstname">Jméno</label>
                <ejs-textbox
                  [ngClass]="{
                                        'e-error':
                                            submited && f.userFirstname.errors
                                    }"
                  floatLabelType="Auto"
                  formControlName="userFirstname"
                  id="userFirstname"
                ></ejs-textbox>
                <div
                  *ngIf="submited && f.userFirstname.errors"
                  class="invalid-feedback"
                >
                  <div
                    *ngIf="f.userFirstname.errors.required"
                  >
                    Jméno uživatele je povinné
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-12 mb-3">
                <label for="userSecondname">Příjmení</label>
                <ejs-textbox
                  [ngClass]="{
                                        'e-error':
                                            submited && f.userSecondname.errors
                                    }"
                  floatLabelType="Auto"
                  formControlName="userSecondname"
                  id="userSecondname"
                ></ejs-textbox>
                <div
                  *ngIf="submited && f.userSecondname.errors"
                  class="invalid-feedback"
                >
                  <div
                    *ngIf="f.userSecondname.errors.required"
                  >
                    Jméno uživatele je povinné
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-12 mb-3">
                <label for="userWorkemail"
                >Pracovní email</label
                >
                <ejs-textbox
                  [ngClass]="{
                                        'e-error':
                                            submited && f.userWorkemail.errors
                                    }"
                  floatLabelType="Auto"
                  formControlName="userWorkemail"
                  id="userWorkemail"
                ></ejs-textbox>
                <div
                  *ngIf="submited && f.userWorkemail.errors"
                  class="invalid-feedback"
                >
                  <div
                    *ngIf="f.userWorkemail.errors.required"
                  >
                    Pracovní email je povinný
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-12 mb-3">
                <label for="userTelnumber"
                >Pracovní telefon</label
                >
                <ejs-textbox
                  [ngClass]="{
                                        'e-error':
                                            submited && f.userTelnumber.errors
                                    }"
                  floatLabelType="Auto"
                  formControlName="userTelnumber"
                  id="userTelnumber"
                ></ejs-textbox>
                <div
                  *ngIf="submited && f.userTelnumber.errors"
                  class="invalid-feedback"
                >
                  <div
                    *ngIf="f.userTelnumber.errors.required"
                  >
                    Pracovní telefon je povinný
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-6 mb-3">
                <label for="userPassword"
                >Heslo (min: 8 znaků)</label
                >
                <input
                  [ngClass]="{
                                        'e-error':
                                            submited && f.userPassword.errors
                                    }"
                  formControlName="userPassword"
                  id="userPassword"
                  type="password"
                />
                <div
                  *ngIf="submited && f.userPassword.errors"
                  class="invalid-feedback"
                >
                  <div *ngIf="f.userPassword.errors.required">
                    Heslo uživatele je povinné
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-6 mb-3">
                <label for="confirmPassword"
                >Potvrdit heslo</label
                >
                <input
                  [ngClass]="{
                                        'e-error':
                                            submited && f.userPassword.errors
                                    }"
                  formControlName="confirmPassword"
                  id="confirmPassword"
                  type="password"
                />
                <div
                  *ngIf="submited && f.confirmPassword.errors"
                  class="invalid-feedback"
                >
                  <div
                    *ngIf="
                                            f.confirmPassword.errors.required
                                        "
                  >
                    Heslo uživatele je povinné
                  </div>
                  <div
                    *ngIf="
                                            registerForm.controls[
                                                'confirmPassword'
                                            ].hasError('NoPasswordMatch')
                                        "
                  >
                    Hesla se neshodují!
                  </div>
                </div>
              </div>
              <div class="col-12">
                <div
                  *ngIf="registerForm.invalid"
                  class="alert alert-warning"
                  role="alert"
                >
                  Formulář je špatně vyplněný
                </div>
                <div
                  *ngIf="registerForm.valid"
                  class="alert alert-success"
                  role="alert"
                >
                  Formulář je správně vyplněný
                </div>
              </div>
              <div class="col-12">
                <button
                  [disabled]="
                                        loadingRegister || registerForm.invalid
                                    "
                  cssClass="e-btn e-success btn-p-zero e-small"
                  ejs-button
                  type="submit"
                >
                                    <span
                                      *ngIf="loadingRegister === true"
                                      aria-hidden="true"
                                      class="spinner-grow spinner-grow-sm"
                                      role="status"
                                    ></span>
                  <span *ngIf="loadingRegister === false"
                  >Vytvořit</span
                  >
                  <span *ngIf="loadingRegister === true"
                  >Provádím...</span
                  >
                </button>
                <button
                  (click)="goBack()"
                  class="e-btn e-primary btn-p-zero e-small"
                  ejs-button
                >
                  zpět
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
