<ejs-dialog
  #formDialog
  (close)="isDirty = false; isChecked = false; changeDialogState(false)"
  [header]="'Výsledky vyhledávání'"
  [isModal]="true"
  [showCloseIcon]="true"
  [visible]="isVisible"
  [width]="'85%'"
  id="formDialog"
>
  <ng-template #content>
    <div class="dialogContent">
      <div class="row">
        <form
          [formGroup]="searchForm"
          class="search-form col-12"
          role="search"
        >
          <div class="form-group">
            <label
              [ngClass]="
                                f.searchQuery.valid &&
                                (f.searchQuery.dirty || f.searchQuery.touched)
                                    ? 'text-success'
                                    : f.searchQuery.invalid &&
                                      (f.searchQuery.dirty ||
                                          f.searchQuery.touched)
                                    ? 'text-danger'
                                    : 'text-muted'
                            "
              for="searchForm-query"
            >* Hledat:</label
            >
            <ejs-textbox
              #fulltextSearch
              (change)="startSearch()"
              (keypress)="removeCurrentResults()"
              [ngClass]="
                                (f.searchQuery.dirty ||
                                    f.searchQuery.touched) &&
                                f.searchQuery.invalid
                                    ? 'e-error'
                                    : (f.searchQuery.dirty ||
                                          f.searchQuery.touched) &&
                                      f.searchQuery.valid
                                    ? 'e-success'
                                    : ''
                            "
              formControlName="searchQuery"
              id="searchForm-query"
              placeholder="Hledat"
            ></ejs-textbox>
            <small class="text-muted"
            >Pro vyhledávání vyplňte text a stikněte enter, nebo
              klikněte mimo pole formuláře.</small
            ><br />
            <small
              *ngIf="
                                (f.searchQuery.dirty ||
                                    f.searchQuery.touched) &&
                                f.searchQuery.errors &&
                                f.searchQuery.errors.required
                            "
              class="text-danger"
            >Hledaný řetězec je povinný</small
            >
            <small
              *ngIf="
                                (f.searchQuery.dirty ||
                                    f.searchQuery.touched) &&
                                f.searchQuery.errors &&
                                f.searchQuery.errors.minlength
                            "
              class="text-danger"
            >Hledaný řetězec musí obsahovat alespoň 3
              znaky</small
            >
          </div>
        </form>
      </div>
      <div class="row">
        <div *ngIf="esLoading" class="col-12 text-center">
          <app-loader></app-loader>
        </div>
        <ejs-accordion *ngIf="!esLoading">
          <e-accordionitems>
            <e-accordionitem *ngIf="userResults?.length > 0">
              <ng-template #header>
                <div
                  class="text-muted mb-2 d-flex justify-content-between"
                >
                                    <span
                                    ><i
                                      aria-hidden="true"
                                      class="fa-light fa-users mr-1"
                                    ></i
                                    >Uživatelé</span
                                    >
                  <span>
                                        <span
                                          class="e-badge e-badge-primary m-2 size_2"
                                        >{{ userResults?.length }}</span
                                        >
                                        <strong class="ml-1">výsledků</strong>
                                    </span>
                </div>
              </ng-template>
              <ng-template #content>
                <ul class="list-group col-12">
                  <li
                    *ngFor="
                                            let user_result of userResults;
                                            trackBy: trackByUserID
                                        "
                    class="list-group-item p-1"
                  >
                    <div class="row">
                      <div class="col-4">
                        <a
                          [routerLink]="[
                                                        '/users/detail/',
                                                        user_result.id
                                                    ]"
                          title="profil"
                        >
                          {{ user_result.fullname }}
                        </a>
                      </div>
                      <div class="col-4">
                        {{ user_result.telnumber }}
                      </div>
                      <div class="col-4">
                        {{ user_result.workemail }}
                      </div>
                    </div>
                  </li>
                </ul>
              </ng-template>
            </e-accordionitem>
            <e-accordionitem *ngIf="ticketResults?.length > 0">
              <ng-template #header>
                <div
                  class="text-muted mb-2 d-flex justify-content-between"
                >
                                    <span
                                    ><i
                                      aria-hidden="true"
                                      class="fa-light fa-ticket-alt mr-1"
                                    ></i
                                    >Tickety</span
                                    >
                  <span>
                                        <span
                                          class="e-badge e-badge-primary m-2 size_2"
                                        >{{ ticketResults?.length }}</span
                                        >
                                        <strong class="ml-1">výsledků</strong>
                                    </span>
                </div>
              </ng-template>
              <ng-template #content>
                <ul class="list-group col-12">
                  <li
                    *ngFor="
                                            let ticket_result of ticketResults
                                        "
                    class="list-group-item p-1"
                  >
                    <div class="row">
                      <div class="col-6">
                        <a
                          [routerLink]="[
                                                        '/tickets/detail/',
                                                        ticket_result.id
                                                    ]"
                          title="detail"
                        >
                          {{ ticket_result.subject }}
                        </a>
                      </div>
                      <div
                        [innerHTML]="
                                                    ticket_result.maintask
                                                "
                        class="col-6"
                      ></div>
                    </div>
                  </li>
                </ul>
              </ng-template>
            </e-accordionitem>
            <e-accordionitem *ngIf="taskResults?.length > 0">
              <ng-template #header>
                <div
                  class="text-muted mb-2 d-flex justify-content-between"
                >
                                    <span
                                    ><i
                                      aria-hidden="true"
                                      class="fa-light fa-ballot-check mr-1"
                                    ></i
                                    >Úkoly</span
                                    >
                  <span>
                                        <span
                                          class="e-badge e-badge-primary m-2 size_2"
                                        >{{ taskResults?.length }}</span
                                        >
                                        <strong class="ml-1">výsledků</strong>
                                    </span>
                </div>
              </ng-template>
              <ng-template #content>
                <ul class="list-group col-12">
                  <li
                    *ngFor="let task_result of taskResults"
                    class="list-group-item p-1"
                  >
                    <div class="row">
                      <div class="col-6">
                        <a
                          [routerLink]="[
                                                        '/tasks/detail/',
                                                        task_result.id
                                                    ]"
                          title="detail"
                        >
                          {{ task_result.subject }}
                        </a>
                      </div>
                      <div
                        [innerHTML]="
                                                    task_result.maintask
                                                "
                        class="col-6"
                      ></div>
                    </div>
                  </li>
                </ul>
              </ng-template>
            </e-accordionitem>
            <e-accordionitem *ngIf="vacationResults?.length > 0">
              <ng-template #header>
                <div
                  class="text-muted mb-2 d-flex justify-content-between"
                >
                                    <span
                                    ><i
                                      aria-hidden="true"
                                      class="fa-light fa-calendar-alt mr-1"
                                    ></i
                                    >Žádanky</span
                                    >
                  <span>
                                        <span
                                          class="e-badge e-badge-primary m-2 size_2"
                                        >{{ vacationResults?.length }}</span
                                        >
                                        <strong class="ml-1">výsledků</strong>
                                    </span>
                </div>
              </ng-template>
              <ng-template #content>
                <ul class="list-group col-12">
                  <li
                    *ngFor="
                                            let vacation_result of vacationResults
                                        "
                    class="list-group-item p-1"
                  >
                    <a
                      class="row"
                      routerLink="/vacation/detail/{{
                                                vacation_result.id
                                            }}"
                      title="detail"
                    >
                                            <span class="col-6">
                                                {{
                                                vacation_result.creator
                                                  .firstname
                                              }}&nbsp;{{
                                                vacation_result.creator
                                                  .secondname
                                              }}
                                            </span>
                      <span class="col-6">
                                                {{ vacation_result.reason }}
                                            </span>
                    </a>
                  </li>
                </ul>
              </ng-template>
            </e-accordionitem>
            <e-accordionitem *ngIf="materialResults?.length > 0">
              <ng-template #header>
                <div
                  class="text-muted mb-2 d-flex justify-content-between"
                >
                                    <span
                                    ><i
                                      aria-hidden="true"
                                      class="fa-light fa-box mr-1"
                                    ></i
                                    >Objednávky</span
                                    >
                  <span>
                                        <span
                                          class="e-badge e-badge-primary m-2 size_2"
                                        >{{ materialResults?.length }}</span
                                        >
                                        <strong class="ml-1">výsledků</strong>
                                    </span>
                </div>
              </ng-template>
              <ng-template #content>
                <ul class="list-group col-12">
                  <li
                    *ngFor="
                                            let material_result of materialResults
                                        "
                    class="list-group-item p-1"
                  >
                    <a
                      class="row"
                      routerLink="/material/detail/({{
                                                material_result.id
                                            }}"
                      title="detail"
                    >
                                            <span class="col-6">
                                                {{
                                                material_result.creator
                                                  .firstname
                                              }}&nbsp;{{
                                                material_result.creator
                                                  .secondname
                                              }}
                                            </span>
                      <span class="col-6">
                                                {{ material_result.reason }}
                                            </span>
                    </a>
                  </li>
                </ul>
              </ng-template>
            </e-accordionitem>
            <e-accordionitem *ngIf="budgetResult?.length > 0">
              <ng-template #header>
                <div
                  class="text-muted mb-2 d-flex justify-content-between"
                >
                                    <span
                                    ><i
                                      aria-hidden="true"
                                      class="fa-light fa-box mr-1"
                                    ></i
                                    >Rozpočty</span
                                    >
                  <span>
                                        <span
                                          class="e-badge e-badge-primary m-2 size_2"
                                        >{{ budgetResult?.length }}</span
                                        >
                                        <strong class="ml-1">výsledků</strong>
                                    </span>
                </div>
              </ng-template>
              <ng-template #content>
                <ul class="list-group col-12">
                  <li
                    *ngFor="
                                            let budget_result of budgetResult
                                        "
                    class="list-group-item p-1"
                  >
                    <a
                      class="row"
                      routerLink="/material/detail/({{
                                                budget_result.id
                                            }}"
                      title="detail"
                    >
                                            <span class="col-6">
                                                {{
                                                budget_result.creator
                                                  .firstname
                                              }}&nbsp;{{
                                                budget_result.creator
                                                  .secondname
                                              }}
                                            </span>
                      <span class="col-6">
                                                {{ budget_result.name }}
                                            </span>
                    </a>
                  </li>
                </ul>
              </ng-template>
            </e-accordionitem>
            <e-accordionitem *ngIf="knowledgeResult?.length > 0">
              <ng-template #header>
                <div
                  class="text-muted mb-2 d-flex justify-content-between"
                >
                                    <span
                                    ><i
                                      aria-hidden="true"
                                      class="fa-light fa-box mr-1"
                                    ></i
                                    >Pracovní postupy</span
                                    >
                  <span>
                                        <span
                                          class="e-badge e-badge-primary m-2 size_2"
                                        >{{ knowledgeResult?.length }}</span
                                        >
                                        <strong class="ml-1">výsledků</strong>
                                    </span>
                </div>
              </ng-template>
              <ng-template #content>
                <ul class="list-group col-12">
                  <li
                    *ngFor="
                                            let knowledge_result of knowledgeResult
                                        "
                    class="list-group-item p-1"
                  >
                    <a
                      class="row"
                      routerLink="/material/detail/({{
                                                knowledge_result.id
                                            }}"
                      title="detail"
                    >
                                            <span class="col-6">
                                                {{
                                                knowledge_result.creator
                                                  .firstname
                                              }}&nbsp;{{
                                                knowledge_result.creator
                                                  .secondname
                                              }}
                                            </span>
                      <span class="col-6">
                                                {{ knowledge_result.maintask }}
                                            </span>
                    </a>
                  </li>
                </ul>
              </ng-template>
            </e-accordionitem>
            <e-accordionitem
              *ngIf="ticketCommentResult?.length > 0"
            >
              <ng-template #header>
                <div
                  class="text-muted mb-2 d-flex justify-content-between"
                >
                                    <span
                                    ><i
                                      aria-hidden="true"
                                      class="fa-light fa-box mr-1"
                                    ></i
                                    >Komentáře - tickety</span
                                    >
                  <span>
                                        <span
                                          class="e-badge e-badge-primary m-2 size_2"
                                        >{{
                                            ticketCommentResult?.length
                                          }}</span
                                        >
                                        <strong class="ml-1">výsledků</strong>
                                    </span>
                </div>
              </ng-template>
              <ng-template #content>
                <ul class="list-group col-12">
                  <li
                    *ngFor="
                                            let tcktcomment_result of ticketCommentResult
                                        "
                    class="list-group-item p-1"
                  >
                    <a
                      class="row"
                      routerLink="/tickets/detail/({{
                                                tcktcomment_result.ticket_id
                                            }}"
                      title="detail"
                    >
                                            <span class="col-12">{{
                                                tcktcomment_result.text
                                              }}</span>
                    </a>
                  </li>
                </ul>
              </ng-template>
            </e-accordionitem>
            <e-accordionitem
              *ngIf="knowledgeCommentResult?.length > 0"
            >
              <ng-template #header>
                <div
                  class="text-muted mb-2 d-flex justify-content-between"
                >
                                    <span
                                    ><i
                                      aria-hidden="true"
                                      class="fa-light fa-box mr-1"
                                    ></i
                                    >Komentáře - pracovní postupy</span
                                    >
                  <span>
                                        <span
                                          class="e-badge e-badge-primary m-2 size_2"
                                        >{{
                                            knowledgeCommentResult?.length
                                          }}</span
                                        >
                                        <strong class="ml-1">výsledků</strong>
                                    </span>
                </div>
              </ng-template>
              <ng-template #content>
                <ul class="list-group col-12">
                  <li
                    *ngFor="
                                            let kwbcomment_result of knowledgeCommentResult
                                        "
                    class="list-group-item p-1"
                  >
                    <a
                      class="row"
                      routerLink="/material/detail/({{
                                                kwbcomment_result.id
                                            }}"
                      title="detail"
                    >
                                            <span class="col-12">{{
                                                kwbcomment_result.text
                                              }}</span>
                    </a>
                  </li>
                </ul>
              </ng-template>
            </e-accordionitem>
          </e-accordionitems>
        </ejs-accordion>
        <div
          *ngIf="
                        !esLoading &&
                        userResults?.length < 1 &&
                        ticketResults?.length < 1 &&
                        taskResults?.length < 1 &&
                        vacationResults?.length < 1 &&
                        materialResults?.length < 1 &&
                        budgetResult?.length < 1 &&
                        knowledgeResult?.length < 1 &&
                        ticketCommentResult?.length < 1 &&
                        knowledgeCommentResult?.length < 1
                    "
        >
          <p>Žádné výsledky hledání...</p>
        </div>
      </div>
    </div>
  </ng-template>
  <ng-template #footerTemplate>
    <div class="d-flex justify-content-end">
            <span
              *ngIf="searchForm.invalid"
              class="e-badge e-badge-warning p-2 size_2"
              role="alert"
            >
                <strong>Formulář je špatně vyplněný</strong>
            </span>
      <span
        *ngIf="searchForm.valid"
        class="e-badge e-badge-success p-2 size_2"
        role="alert"
      >
                <strong>Formulář je správně vyplněný</strong>
            </span>
    </div>
    <div class="row">
      <div class="col-12 pt-2">
        <button
          (click)="formDialogObj.hide()"
          cssClass="e-btn e-danger e-outline btn-p-zero e-small"
          ejs-button
          type="button"
        >
          ZRUŠIT
        </button>
      </div>
    </div>
  </ng-template>
</ejs-dialog>
