import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    HostListener,
    Input,
    OnChanges,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {DataService} from '@src/app/_services/data.service';
import {DataNetbaseService} from '@src/app/_services/data-netbase.service';
import {ServicesService, TvChannelInput} from '@src/app/features/services/services.service';
import {MessageService} from '@src/app/_services/message.service';
import {AuthenticationService} from '@src/app/_services/authentication.service';
import {TvThemes} from '@src/app/_models/services/tv-themes';
import {TvChannels} from '@src/app/_models/services/tv-channels';
import {User} from '@src/app/_models/user/user';
import {DropDownListComponent, FilteringEventArgs} from '@syncfusion/ej2-angular-dropdowns';
import {EmitType, isNullOrUndefined} from '@syncfusion/ej2-base';
import {Query} from '@syncfusion/ej2-data';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {DialogComponent} from '@syncfusion/ej2-angular-popups';
import {Observable} from 'rxjs';
import {saveAs} from 'file-saver';
import {
    FileInfo,
    RemovingEventArgs,
    SelectedEventArgs,
    SuccessEventArgs,
    UploaderComponent,
} from '@syncfusion/ej2-angular-inputs';
import {ComponentCanDeactivate} from '@src/app/_guards/changes.guard';
import {EnvironmentService} from '@src/app/_services/environment.service';
import {ActivatedRoute, Params, Router} from '@angular/router';

@UntilDestroy()
@Component({
    selector: 'app-tv-channel-form',
    templateUrl: './tv-channel-form.component.html',
    styleUrls: ['./tv-channel-form.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TvChannelFormComponent implements OnInit, OnChanges, ComponentCanDeactivate {
    // Upload
    path: {
        saveUrl: string;
        removeUrl: string;
    };

    autoUpload = false;

    dropElement: HTMLElement = document.getElementsByClassName('control-fluid')[0] as HTMLElement;

    humanizeBytes: (bytes: number) => void;

    deleteFileId: number;

    // Dialogs
    buttonsDeleteFileDialog = [
        {
            click: this.removeTvLogo.bind(this),
            buttonModel: {
                content: 'OK',
                cssClass: 'e-success e-outline',
                isFlat: false,
            },
        },
        {
            click: (): void => {
                this.deleteFileDialog.hide();
            },
            buttonModel: {content: 'ZRUŠIT', cssClass: 'e-danger e-outline'},
        },
    ];

    // Dropdowms
    height = '240px';

    fields: object = {text: 'label', value: 'value'};

    themeSelect: Array<{value: boolean | number | string; label: string}> = [];

    // Forms
    tvChannelsForm: FormGroup;

    isDirty = false;

    isChecked = false;

    submited = false;

    // Variables
    currentUser: User | null;

    // Loaders
    loadingTvChannels = false;

    @Input() tvChannel: TvChannels | null = null;

    @Input() isCreate = false;

    @Input() isUpdate = false;

    @Input() isVisible = false;

    @Input() isCopy = false;

    @Output() readonly tvChannelFormDialogState = new EventEmitter<boolean>();

    // Upload
    @ViewChild('preloadupload') uploadObj: UploaderComponent;

    // Dialogs
    @ViewChild('formDialog') formDialogObj: DialogComponent;

    @ViewChild('deleteFileDialog') deleteFileDialog: DialogComponent;

    // Dropdowms
    @ViewChild('theme') themeObj: DropDownListComponent;

    constructor(
        private readonly router: Router,
        private readonly route: ActivatedRoute,
        private readonly formBuilder: FormBuilder,
        private readonly ref: ChangeDetectorRef,
        private readonly dataService: DataService,
        private readonly dataNetbaseService: DataNetbaseService,
        private readonly servicesService: ServicesService,
        private readonly messages: MessageService,
        private readonly authenticationService: AuthenticationService,
        private readonly environmentService: EnvironmentService,
    ) {
        this.currentUser = this.authenticationService.currentUserValue;
        this.path = {
            saveUrl: `${this.environmentService.backendURL}/api/upload/channel?type=channel`,
            removeUrl: '',
        };
    }

    get f(): {[key: string]: AbstractControl} {
        return this.tvChannelsForm.controls;
    }

    @HostListener('window:beforeunload')
    canDeactivate(): Observable<boolean> | boolean {
        return !this.isDirty;
    }

    ngOnInit(): void {
        this.dataNetbaseService.tvThemesSource.pipe(untilDestroyed(this))
            .subscribe({
                next: (themes: TvThemes[]) => {
                    this.themeSelect = [];
                    themes.map((theme: TvThemes) => {
                        if (!theme.deleted_date) {
                            this.themeSelect = [
                                ...this.themeSelect,
                                {
                                    value: theme.id,
                                    label: theme.name,
                                },
                            ];
                        }
                    });
                    this.ref.markForCheck();
                },
                error: error => {
                    console.error(error);
                },
            });

        this.tvChannelsForm = this.formBuilder.group({
            name: [
                '',
                Validators.compose([
                    Validators.required,
                    Validators.maxLength(100),
                    Validators.minLength(2),
                ]),
            ],
            app_available: [false, Validators.required],
            hd_resolution: [false, Validators.required],
            theme_id: [null, Validators.required],
            logo: [null],
        });

        this.tvChannelsForm.valueChanges.pipe(untilDestroyed(this))
            .subscribe(() => {
                this.isDirty = this.tvChannelsForm.dirty;
                this.showInputErrors();
            });

        this.dataNetbaseService.setTvThemesDataSource();
    }

    ngOnChanges(): void {
        if (this.isVisible) {
            this.uploadObj.clearAll();

            const queryParams: Params = {form: null};

            void this.router.navigate([], {
                relativeTo: this.route,
                queryParams,
                queryParamsHandling: 'merge',
            });

            if (this.isUpdate && this.tvChannel) {
                this.tvChannelsForm.reset();
                this.formDialogObj.header = `TV kanál #${this.tvChannel.id} - ${this.tvChannel.name}`;
                this.fillTheForm();
                this.showInputErrors();
            }

            if (this.isCreate) {
                this.tvChannelsForm.reset();
                this.tvChannelsForm.controls.app_available.patchValue(false);
                this.tvChannelsForm.controls.hd_resolution.patchValue(false);
                this.formDialogObj.header = 'Nový TV kanál';
            }

            if (this.isCopy) {
                this.tvChannelsForm.reset();
                this.formDialogObj.header = 'Nový TV kanál';
                this.fillTheForm();
                this.showInputErrors();
            }
        }
    }

    changeDialogState(value: boolean): void {
        this.tvChannelFormDialogState.emit(value);
    }

    onFilteringThemes: EmitType<FilteringEventArgs> = (e: FilteringEventArgs) => {
        if (e.text === '') {
            e.updateData(this.themeSelect);
        } else {
            let query: Query = new Query();

            query = e.text !== '' ? query.where('label', 'contains', e.text, true, true) : query;
            e.updateData(this.themeSelect, query);
        }
    };

    fillTheForm(): void {
        this.tvChannelsForm.controls.name.patchValue(this.tvChannel?.name);
        this.tvChannelsForm.controls.app_available.patchValue(this.tvChannel?.app_available);
        this.tvChannelsForm.controls.hd_resolution.patchValue(this.tvChannel?.hd_resolution);
        this.tvChannelsForm.controls.theme_id.patchValue(this.tvChannel?.theme_id);
    }

    onSubmit(): void {
        this.submited = true;
        this.isDirty = false;
        this.loadingTvChannels = true;

        if (this.isCreate || this.isCopy) {
            this.createTvChannel();
        }

        if (this.isUpdate) {
            this.editTvChannel();
        }
    }

    createTvChannel(): void {
        this.submited = true;

        if (this.tvChannelsForm.invalid) {
            console.error('form is not valid!');

            return;
        }

        this.loadingTvChannels = true;

        const tvChannelsData: TvChannelInput = {
            name: this.f.name.value,
            app_available: this.f.app_available.value,
            hd_resolution: this.f.hd_resolution.value,
            theme_id: this.f.theme_id.value,
            created_by: this.currentUser?.id,
        };

        this.servicesService
            .addTvChannel(tvChannelsData)
            .pipe(untilDestroyed(this))
            .subscribe({
                next: (data: TvChannels) => {
                    if (this.uploadObj.getFilesData().length > 0) {
                        this.uploadObj.asyncSettings.saveUrl = `${this.environmentService.backendURL}/api/upload/channel?type=channel&id=${data.id}`;
                        this.uploadObj.upload(this.uploadObj.getFilesData()[0]);
                    }

                    const body = `${data.name} přidán do nabídky`;
                    const options = {progressBar: true, timeOut: 5000};

                    this.messages.showSuccess('TV program úspěšně vytvořen', body, options);
                    this.dataNetbaseService.setTvChannelsDataSource();
                    this.isCreate = false;
                    this.loadingTvChannels = false;
                    this.ref.markForCheck();
                    this.formDialogObj.hide();
                },
                error: error => {
                    console.error(error);

                    const body = 'Zkuste to znovu později';
                    const options = {
                        progressBar: true,
                        timeOut: 5000,
                        toastClass: 'red',
                    };

                    this.messages.showError('Chyba při vytváření TV programu', body, options);
                    this.loadingTvChannels = false;
                    this.ref.markForCheck();
                },
            });
    }

    editTvChannel(): void {
        this.submited = true;

        if (this.tvChannelsForm.invalid || !this.tvChannel || !this.currentUser) {
            console.error('form is not valid! || this.tvChannel | this.currentUser is missing...');

            return;
        }

        this.loadingTvChannels = true;

        const tvChannelsData: TvChannelInput = {
            id: this.tvChannel.id,
            name: this.f.name.value,
            app_available: this.f.app_available.value,
            hd_resolution: this.f.hd_resolution.value,
            theme_id: this.f.theme_id.value,
            updated_by: this.currentUser.id,
        };

        this.servicesService
            .updateTvChannel(tvChannelsData)
            ?.pipe(untilDestroyed(this))
            .subscribe({
                next: (data: TvChannels) => {
                    if (this.uploadObj.getFilesData().length > 0) {
                        this.uploadObj.asyncSettings.saveUrl = `${this.environmentService.backendURL}/api/upload/channel?type=channel&id=${data.id}`;
                        this.uploadObj.upload(this.uploadObj.getFilesData()[0]);
                    }

                    const body = `${data.name} upraven`;
                    const options = {progressBar: true, timeOut: 5000};

                    this.messages.showSuccess('TV program úspěšně upraven', body, options);
                    this.dataNetbaseService.setTvChannelsDataSource();
                    this.isUpdate = false;
                    this.loadingTvChannels = false;
                    this.ref.markForCheck();
                    this.formDialogObj.hide();
                },
                error: error => {
                    console.error(error);

                    const body = 'Zkuste to znovu později';
                    const options = {
                        progressBar: true,
                        timeOut: 5000,
                        toastClass: 'red',
                    };

                    this.messages.showError('Chyba při úpravě TV programu', body, options);
                    this.loadingTvChannels = false;
                    this.ref.markForCheck();
                },
            });
    }

    // Files
    downloadFile(file: TvChannels): void {
        this.servicesService
            .downloadTvLogo(file)
            .pipe(untilDestroyed(this))
            .subscribe({
                next: data => {
                    saveAs(data, file.name);
                },
                error: error => {
                    alert('Chyba během stahování souboru.');
                    console.error(error);
                },
            });
    }

    onFileSelected(args: SelectedEventArgs): void {
        this.uploadObj.clearAll();
        // Filter the 5 files only to showcase
        args.filesData.splice(5);

        const filesData: FileInfo[] = this.uploadObj.getFilesData();
        const allFiles: FileInfo[] = filesData.concat(args.filesData);

        if (allFiles.length > 5) {
            // eslint-disable-next-line @typescript-eslint/prefer-for-of
            for (let i = 0; i < allFiles.length; i++) {
                if (allFiles.length > 5) {
                    allFiles.shift();
                }
            }

            args.filesData = allFiles;
            // set the modified custom data
            args.modifiedFilesData = args.filesData;
        }

        let existingFiles: FileInfo[] = this.uploadObj.getFilesData();

        for (let i = 0; i < args.filesData.length; i++) {
            // eslint-disable-next-line @typescript-eslint/prefer-for-of
            for (let j = 0; j < existingFiles.length; j++) {
                if (!isNullOrUndefined(args.filesData[i])) {
                    // eslint-disable-next-line eqeqeq
                    if (existingFiles[j].name == args.filesData[i].name) {
                        args.filesData.splice(i, 1);
                    }
                }
            }
        }

        existingFiles = existingFiles.concat(args.filesData);
        args.modifiedFilesData = existingFiles;
        args.isModified = true;
    }

    onSuccessUpload(args: SuccessEventArgs): void {
        if (args.file) {
            const body = `Soubor: ${args.file.name}`;
            const options = {progressBar: true, timeOut: 5000, toastClass: 'success'};

            this.messages.showSuccess('Soubor úspěšně nahrán', body, options);
            this.dataNetbaseService.setTvChannelsDataSource();
        }
    }

    onFileRemove(args: RemovingEventArgs): void {
        args.postRawFile = false;
        this.deleteFileDialog.show();
    }

    removeTvLogo(): void {
        this.loadingTvChannels = true;

        const file = this.tvChannel;

        if (file) {
            file.deleted_by = this.currentUser?.id;
            this.servicesService
                .deleteTvLogo(file)
                ?.pipe(untilDestroyed(this))
                .subscribe({
                    next: data => {
                        const body = `Soubor #${data?.id ?? 'null'}`;
                        const options = {progressBar: true, timeOut: 5000};

                        this.messages.showSuccess('Soubor úspěšně smazán', body, options);
                        this.loadingTvChannels = false;
                        this.deleteFileDialog.hide();
                        this.ref.markForCheck();
                    },
                    error: error => {
                        alert('Chyba během mazání souboru.');
                        this.loadingTvChannels = false;
                        this.ref.markForCheck();
                        console.error(error);
                    },
                });
        }
    }

    showInputErrors(): void {
        this.isChecked = true;
        /* console.log('VALID: ' + this.ticketForm.valid);
        Object.keys(this.ticketForm.controls).forEach(key => {
            const controlErrors: ValidationErrors = this.ticketForm.get(key).errors;
            if (controlErrors != null) {
                Object.keys(controlErrors).forEach(keyError => {
                    console.log('Key control: ' + key + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError]);
                });
            }
        });*/
        this.tvChannelsForm.markAllAsTouched();
        this.ref.markForCheck();
    }
}
