import {
    AfterViewChecked,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnInit,
    ViewChild,
} from '@angular/core';
import {ApiConnections} from '@src/app/_models/api/connections';
import {VacationCategory} from '@src/app/_models/vacation/vacation-category';
import {Observable, Observer} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthenticationService} from '@src/app/_services/authentication.service';
import {ApisService} from '@src/app/_api/apis.service';
import {PermissionService} from '@src/app/_services/permission.service';
import {DataService} from '@src/app/_services/data.service';

import {registerLocaleData} from '@angular/common';
import moment from 'moment';
import localeCs from '@angular/common/locales/cs';
import {User} from '@src/app/_models/user/user';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {
    ColumnModel,
    DataResult,
    EditSettingsModel,
    ExcelExportProperties,
    FilterSettingsModel,
    GridComponent,
    GroupSettingsModel,
    PageSettingsModel,
    RowDataBoundEventArgs,
    SelectionSettingsModel,
    ToolbarItems,
} from '@syncfusion/ej2-angular-grids';
import {TextBoxComponent} from '@syncfusion/ej2-angular-inputs';
import {ButtonPropsModel, DialogComponent} from '@syncfusion/ej2-angular-popups';
import {ChangeEventArgs, DropDownListComponent} from '@syncfusion/ej2-angular-dropdowns';
import {ClickEventArgs} from '@syncfusion/ej2-angular-navigations';
import {ApiConnectionsGridItems} from '@src/app/features/settings/settings.service';

moment.updateLocale('cs', {workingWeekdays: [1, 2, 3, 4, 5]});
registerLocaleData(localeCs);

@UntilDestroy()
@Component({
    selector: 'app-apis-table',
    templateUrl: './apis-table.component.html',
    styleUrls: ['./apis-table.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ApisTableComponent implements OnInit, AfterViewChecked {
    // Grid
    currencyFormat: {format: string} = {format: '#,###.00\',- CZK\''};

    dateFormat: string;

    toolbar: ToolbarItems[] | object;

    data: object;

    fields: object = {text: 'label', value: 'value'};

    filterSettings: FilterSettingsModel;

    filterCheckboxSettings: FilterSettingsModel;

    selectionSettings: SelectionSettingsModel;

    editSettings: EditSettingsModel;

    groupOptions: GroupSettingsModel;

    pageOptions: PageSettingsModel;

    expendituresColumnsPrice: ColumnModel[];

    expendituresColumnsItems: ColumnModel[];

    filterDate: object;

    sortOptions: object;

    requiredRules: object;

    exportCurrentPage = false;

    // Dialogs
    renderApiFormDialog = false;

    buttonsDelete: ButtonPropsModel[] = [
        {
            click: (): void => {
                this.deleteApi();
            },
            isFlat: false,
            buttonModel: {
                content: 'OK',
                cssClass: 'e-success e-outline',
            },
        },
        {
            click: (): void => {
                this.confirmationDelete.hide();
            },
            buttonModel: {
                content: 'ZRUŠIT',
                cssClass: 'e-danger e-outline',
            },
        },
    ];

    buttonsCancel: ButtonPropsModel[] = [
        {
            click: (): void => {
                this.confirmationCancel.hide();
            },
            buttonModel: {
                isPrimary: true,
                content: 'OK',
            },
        },
    ];

    // Dropdowns
    height = '240px';

    categoryFilterData: Array<string> = [];

    // Forms
    isCreate = false;

    isUpdate = false;

    isCopy = false;

    // Variables
    currentUser: User | null;

    apis:
        | {
        acs: ApiConnections | boolean;
        crm: ApiConnections | boolean;
        erp: ApiConnections | boolean;
        siteMonitoring: ApiConnections | boolean;
        cars: ApiConnections | boolean;
    }
        | undefined = {
        acs: false,
        crm: false,
        erp: false,
        siteMonitoring: false,
        cars: false,
    };

    categories: VacationCategory[];

    clickedRow: ApiConnections[] = [];

    clickedRow$ = new Observable<ApiConnections[]>((observer: Observer<ApiConnections[]>) => {
        observer.next(this.clickedRow);
    });

    // Loaders
    tableLoad = false;

    // Grid
    @ViewChild('apisGrid') gridApis: GridComponent;

    @ViewChild('searchtext') searchtextObj: TextBoxComponent;

    // Dialogs
    @ViewChild('confirmationDelete') confirmationDelete: DialogComponent;

    @ViewChild('confirmationCancel') confirmationCancel: DialogComponent;

    // Dropdowns
    @ViewChild('viewFilter') viewFilterInstance: DropDownListComponent;

    constructor(
        private readonly router: Router,
        private readonly route: ActivatedRoute,
        private readonly ref: ChangeDetectorRef,
        private readonly authenticationService: AuthenticationService,
        private readonly apisService: ApisService,
        private readonly dataService: DataService,
        private readonly permissionService: PermissionService,
    ) {
        this.currentUser = this.authenticationService.currentUserValue;
    }

    ngOnInit(): void {
        this.filterSettings = {
            type: 'Menu',
            showFilterBarStatus: true,
            ignoreAccent: true,
        };

        this.filterCheckboxSettings = {
            type: 'CheckBox',
            showFilterBarStatus: true,
            ignoreAccent: true,
        };

        this.filterSettings = {
            type: 'Excel',
            showFilterBarOperator: true,
            showFilterBarStatus: true,
            ignoreAccent: true,
        };

        this.selectionSettings = {
            persistSelection: true,
            type: 'Multiple',
            checkboxOnly: true,
        };

        this.toolbar = [
            {
                text: 'Občerstvit data',
                tooltipText: 'Občerstvit data',
                prefixIcon: 'e-refresh',
                id: 'customRefresh',
            },
            'Print',
            'ExcelExport',
            'Add',
            'Edit',
            {
                text: 'Smazat',
                tooltipText: 'Smazat api',
                prefixIcon: 'e-remove',
                id: 'delete',
            },
            {
                text: 'Kopírovat',
                tooltipText: 'Kopírovat api',
                prefixIcon: 'e-copy',
                id: 'copy',
            },
        ];
        this.categoryFilterData = ['všechny api', 'aktivní api', 'neaktivní api'];
        this.pageOptions = {pageSize: 50};
        this.dateFormat = 'dd.MM.yyyy HH:mm';
        this.filterDate = {type: 'Menu', params: {format: this.dateFormat}};
        this.groupOptions = {
            showGroupedColumn: true,
            disablePageWiseAggregates: false,
        };

        this.editSettings = {
            showConfirmDialog: false,
            showDeleteConfirmDialog: false,
            allowEditing: true,
            allowAdding: true,
            allowDeleting: true,
            allowEditOnDblClick: false,
            mode: 'Dialog',
        };
        this.requiredRules = {required: true};

        this.dataService.setApiDataSource();
    }

    ngAfterViewChecked(): void {
        this.route.queryParamMap.pipe(untilDestroyed(this))
            .subscribe(params => {
                if (params.get('form') === 'addApi') {
                    this.isCreate = true;
                    this.renderApiFormDialog = true;
                    this.ref.markForCheck();
                }
            });
    }

    // eslint-disable-next-line complexity
    loadData(): DataResult[] {
        this.gridApis.clearRowSelection();

        console.info('NEW DATA BOUND');

        const elements: ApiConnectionsGridItems[] = [];

        if (this.apis) {
            if (this.apis.acs && this.apis.acs instanceof ApiConnections) {
                elements.push({
                    id: this.apis.acs.id,
                    title: this.apis.acs.title,
                    host_url: this.apis.acs.host_url,
                    port: this.apis.acs.port,
                    state: this.apis.acs.state,
                    active: this.apis.acs.active,
                    type: this.apis.acs.type.name,
                    protocol: this.apis.acs.protocol.name,
                    headers: this.apis.acs.headers.toString(),
                    last_check: this.apis.acs.last_check,
                    created_by: this.apis.acs.creator.id,
                    creator_str: this.apis.acs.creator.fullname,
                    creator_img: this.apis.acs.creator.personalphoto,
                    created_date: new Date(this.apis.acs.created_date),
                    updated_date: this.apis.acs.updated_date
                        ? new Date(this.apis.acs.updated_date)
                        : null,
                    deleted_date: this.apis.acs.deleted_date
                        ? new Date(this.apis.acs.deleted_date)
                        : null,
                });
            }

            if (this.apis.crm && this.apis.crm instanceof ApiConnections) {
                elements.push({
                    id: this.apis.crm.id,
                    title: this.apis.crm.title,
                    host_url: this.apis.crm.host_url,
                    port: this.apis.crm.port,
                    state: this.apis.crm.state,
                    active: this.apis.crm.active,
                    type: this.apis.crm.type.name,
                    protocol: this.apis.crm.protocol.name,
                    headers: this.apis.crm.headers.toString(),
                    last_check: this.apis.crm.last_check,
                    created_by: this.apis.crm.creator.id,
                    creator_str: this.apis.crm.creator.fullname,
                    creator_img: this.apis.crm.creator.personalphoto,
                    created_date: new Date(this.apis.crm.created_date),
                    updated_date: this.apis.crm.updated_date
                        ? new Date(this.apis.crm.updated_date)
                        : null,
                    deleted_date: this.apis.crm.deleted_date
                        ? new Date(this.apis.crm.deleted_date)
                        : null,
                });
            }

            if (this.apis.erp && this.apis.erp instanceof ApiConnections) {
                elements.push({
                    id: this.apis.erp.id,
                    title: this.apis.erp.title,
                    host_url: this.apis.erp.host_url,
                    port: this.apis.erp.port,
                    state: this.apis.erp.state,
                    active: this.apis.erp.active,
                    type: this.apis.erp.type.name,
                    protocol: this.apis.erp.protocol.name,
                    headers: this.apis.erp.headers.toString(),
                    last_check: this.apis.erp.last_check,
                    created_by: this.apis.erp.creator.id,
                    creator_str: this.apis.erp.creator.fullname,
                    creator_img: this.apis.erp.creator.personalphoto,
                    created_date: new Date(this.apis.erp.created_date),
                    updated_date: this.apis.erp.updated_date
                        ? new Date(this.apis.erp.updated_date)
                        : null,
                    deleted_date: this.apis.erp.deleted_date
                        ? new Date(this.apis.erp.deleted_date)
                        : null,
                });
            }

            if (this.apis.siteMonitoring && this.apis.siteMonitoring instanceof ApiConnections) {
                elements.push({
                    id: this.apis.siteMonitoring.id,
                    title: this.apis.siteMonitoring.title,
                    host_url: this.apis.siteMonitoring.host_url,
                    port: this.apis.siteMonitoring.port,
                    state: this.apis.siteMonitoring.state,
                    active: this.apis.siteMonitoring.active,
                    type: this.apis.siteMonitoring.type.name,
                    protocol: this.apis.siteMonitoring.protocol.name,
                    headers: this.apis.siteMonitoring.headers.toString(),
                    last_check: this.apis.siteMonitoring.last_check,
                    created_by: this.apis.siteMonitoring.creator.id,
                    creator_str: this.apis.siteMonitoring.creator.fullname,
                    creator_img: this.apis.siteMonitoring.creator.personalphoto,
                    created_date: new Date(this.apis.siteMonitoring.created_date),
                    updated_date: this.apis.siteMonitoring.updated_date
                        ? new Date(this.apis.siteMonitoring.updated_date)
                        : null,
                    deleted_date: this.apis.siteMonitoring.deleted_date
                        ? new Date(this.apis.siteMonitoring.deleted_date)
                        : null,
                });
            }

            if (this.apis.cars && this.apis.cars instanceof ApiConnections) {
                elements.push({
                    id: this.apis.cars.id,
                    title: this.apis.cars.title,
                    host_url: this.apis.cars.host_url,
                    port: this.apis.cars.port,
                    state: this.apis.cars.state,
                    active: this.apis.cars.active,
                    type: this.apis.cars.type.name,
                    protocol: this.apis.cars.protocol.name,
                    headers: this.apis.cars.headers.toString(),
                    last_check: this.apis.cars.last_check,
                    created_by: this.apis.cars.creator.id,
                    creator_str: this.apis.cars.creator.fullname,
                    creator_img: this.apis.cars.creator.personalphoto,
                    created_date: new Date(this.apis.cars.created_date),
                    updated_date: this.apis.cars.updated_date
                        ? new Date(this.apis.cars.updated_date)
                        : null,
                    deleted_date: this.apis.cars.deleted_date
                        ? new Date(this.apis.cars.deleted_date)
                        : null,
                });
            }
        }

        this.tableLoad = false;
        this.ref.markForCheck();

        return elements as unknown as DataResult[];
    }

    onCreated(): void {
        this.disableToolBarBtn();
    }

    setInitialGridFiltering(): void {
        this.gridApis.clearFiltering();
        // this.gridBonuses.filterByColumn('created_by', 'equal', this.currentUser.id, 'and', false, true);
        // this.viewFilterInstance.value = 'moje žádanky - všechny';
        this.ref.markForCheck();
    }

    onLoad(): void {
        const wrapper1 = document.getElementById('scroll_wrapper1_apis');
        const wrapper2 = document.getElementById('scroll_wrapper2_apis');

        if (wrapper1 && wrapper2) {
            wrapper1.onscroll = (): void => {
                wrapper2.scrollLeft = wrapper1.scrollLeft;
            };

            wrapper2.onscroll = (): void => {
                wrapper1.scrollLeft = wrapper2.scrollLeft;
            };
        }

        this.dataService.apiSource.pipe(untilDestroyed(this))
            .subscribe(
                (
                    apis:
                        | {
                        acs: ApiConnections | boolean;
                        crm: ApiConnections | boolean;
                        erp: ApiConnections | boolean;
                        siteMonitoring: ApiConnections | boolean;
                        cars: ApiConnections | boolean;
                    }
                        | undefined,
                ) => {
                    this.apis = apis;

                    if (this.apis) {
                        this.data = this.gridApis.dataSource = this.loadData();
                        this.sortOptions = {
                            columns: [
                                {field: 'created_date', direction: 'Descending'},
                                {field: 'id', direction: 'Descending'},
                            ],
                        };
                    }
                },
                error => {
                    console.error(error);
                    this.tableLoad = false;
                },
            );
    }

    search(): void {
        this.gridApis.search(this.searchtextObj.value);
    }

    onDataBound(): void {
        this.rowSelected();

        const width = document.getElementById('apisGrid_content_table')?.offsetWidth;
        const parent1 = document.getElementById('scroll_div_apis');
        const parent2 = document.getElementById('grid_parent_apis');

        if (parent1 && parent2 && width) {
            parent1.style.width = `${width}px`;
            parent2.style.width = `${width}px`;
        }

        this.gridApis.autoFitColumns();
        this.ref.markForCheck();
    }

    rowDataBound(args: RowDataBoundEventArgs): void {
        const data = args.data as ApiConnections;

        if (args.row && data.deleted_date) {
            args.row.getElementsByClassName('e-gridchkbox')[0].classList.add('disablecheckbox');
            args.row
                .getElementsByClassName('e-checkbox-wrapper')[0]
                .classList.add('disablecheckbox');
            args.row.classList.add('e-disabled');
        }

        if (
            this.currentUser &&
            args.row &&
            data.created_by !== this.currentUser.id &&
            !this.permissionService.checkUserISServiceAdmin(this.currentUser) &&
            !this.permissionService.checkUserISAdministrative(this.currentUser) &&
            !this.permissionService.checkUserISMarketingAdmin(this.currentUser)
        ) {
            args.row.getElementsByClassName('e-gridchkbox')[0].classList.add('disablecheckbox');
            args.row
                .getElementsByClassName('e-checkbox-wrapper')[0]
                .classList.add('disablecheckbox');
        }

        this.ref.markForCheck();
    }

    onStatusFilterChange(e: ChangeEventArgs): void {
        if (e.value === 'všechny api') {
            this.gridApis.clearFiltering();
        } else if (e.value === 'aktivní api') {
            this.gridApis.clearFiltering();
            this.gridApis.filterByColumn('deleted_date', 'equal', null, 'and', false, true);
        } else if (e.value === 'neaktivní api') {
            this.gridApis.clearFiltering();
            this.gridApis.filterByColumn('deleted_date', 'notequal', null, 'and', false, true);
        }
    }

    cancelBtnClick(): void {
        this.searchtextObj.value = '';
        this.gridApis.searchSettings.key = '';
    }

    toolbarClick(args: ClickEventArgs): void {
        const xlsProp: ExcelExportProperties = {
            fileName: 'js_apis_export.xlsx',
            enableFilter: true,
            exportType: this.exportCurrentPage ? 'CurrentPage' : 'AllPages',
        };

        if (args.item.id === 'apisGrid_excelexport') {
            void this.gridApis.excelExport(xlsProp);
        } else if (args.item.id === 'delete') {
            if (this.gridApis.getSelectedRecords().length !== 0) {
                this.confirmationDelete.content = `Potvrďte smazání ${this.clickedRow.length} api`;
                this.confirmationDelete.show();
            } else {
                this.confirmationCancel.content = 'Musíte vybrat alespoň jednu api';
                this.confirmationCancel.show();
            }
        } else if (args.item.id === 'copy') {
            if (this.gridApis.getSelectedRecords().length !== 0) {
                this.isUpdate = false;
                this.isCreate = false;
                this.isCopy = true;
                this.renderApiFormDialog = true;
                this.ref.markForCheck();
            } else {
                this.confirmationCancel.content = 'Musíte vybrat alespoň jednu api';
                this.confirmationCancel.show();
            }
        } else if (args.item.id === 'customRefresh') {
            this.tableLoad = true;
            console.info('refresh DATA!');
            this.ref.markForCheck();
            this.dataService.clearCompaniesCache();
            this.dataService.setCompanyDataSource();
        }
    }

    enableToolbarBtn(): void {
        this.gridApis.toolbarModule.enableItems(['apisGrid_edit', 'delete', 'copy'], true);
    }

    disableToolBarBtn(): void {
        this.gridApis.toolbarModule.enableItems(['apisGrid_edit', 'delete', 'copy'], false);
    }

    rowSelected(): void {
        this.clickedRow = [];

        const selectedrecords: object[] = this.gridApis.getSelectedRecords();

        if (selectedrecords.length > 0) {
            selectedrecords.map((row: ApiConnections) => {
                const tempData: ApiConnections | null =
                    this.apis?.acs instanceof ApiConnections && row.id === this.apis.acs.id
                        ? this.apis.acs
                        : this.apis?.crm instanceof ApiConnections && row.id === this.apis.crm.id
                            ? this.apis.crm
                            : this.apis?.erp instanceof ApiConnections && row.id === this.apis.erp.id
                                ? this.apis.erp
                                : this.apis?.siteMonitoring instanceof ApiConnections &&
                                row.id === this.apis.siteMonitoring.id
                                    ? this.apis.siteMonitoring
                                    : this.apis?.cars instanceof ApiConnections && row.id === this.apis.cars.id
                                        ? this.apis.cars
                                        : null;

                if (tempData) {
                    this.clickedRow.push(tempData);
                }

                this.ref.markForCheck();
            });

            this.clickedRow$ = new Observable<ApiConnections[]>(
                (observer: Observer<ApiConnections[]>) => {
                    observer.next(this.clickedRow);
                },
            );
            this.enableToolbarBtn();
        } else {
            this.disableToolBarBtn();
            this.clickedRow$ = new Observable<ApiConnections[]>(
                (observer: Observer<ApiConnections[]>) => {
                    observer.next(this.clickedRow);
                },
            );
        }
    }

    actionBegin(args): void {
        if (args.requestType === 'add' || args.requestType === 'beginEdit') {
            args.cancel = true;

            if (args.requestType === 'beginEdit') {
                this.isUpdate = true;
                this.isCreate = false;
                this.isCopy = false;
            }

            if (args.requestType === 'add') {
                this.isCreate = true;
                this.isUpdate = false;
                this.isCopy = false;
            }

            this.renderApiFormDialog = true;
            this.ref.markForCheck();
        }
    }

    actionComplete(args): void {
        if (args.requestType === 'beginEdit' || args.requestType === 'add') {
            args.form.ej2_instances[0].rules = {};
        }
    }

    deleteApi(): void {
        // EMPTY TODO
    }
}
