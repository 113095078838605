import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule, Routes} from '@angular/router';
import {ApiDetailComponent} from '@src/app/features/settings/components/api-detail/api-detail.component';
import {AuthGuard} from '@src/app/_guards/auth.guard';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {BrowserModule} from '@angular/platform-browser';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {SharedFeaturesModule} from '@src/app/shared_features/shared-features.module';
import {CrossroadComponent} from './components/crossroad/crossroad.component';
import {SimComponent} from './components/sim/sim.component';

const routes: Routes = [
    {
        path: 'sync',
        children: [
            {
                path: '',
                component: CrossroadComponent,
                data: {
                    title: 'IO data',
                    breadcrumb: [
                        {
                            label: 'Import/Export',
                            url: '',
                        },
                    ],
                },
            },
            {
                path: 'sync/sim',
                component: ApiDetailComponent,
                data: {
                    title: 'IO sim karty',
                    breadcrumb: [
                        {
                            label: 'Import/Export',
                            url: 'sync',
                        },
                        {
                            label: 'IO sim karty',
                            url: '',
                        },
                    ],
                },
            },
        ],
        canActivate: [AuthGuard],
    },
];

@NgModule({
    declarations: [
        CrossroadComponent,
        SimComponent,
    ],
    imports: [
        BrowserAnimationsModule,
        RouterModule.forChild(routes),
        BrowserModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        SharedFeaturesModule,
    ],
})
export class SyncModule {
}
