import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    NgZone,
    OnInit,
    ViewChild,
} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {DataNetbaseService} from '@src/app/_services/data-netbase.service';
import {AuthenticationService} from '@src/app/_services/authentication.service';
import {DataService} from '@src/app/_services/data.service';
import {
    GridComponent,
    SearchEventArgs,
    SelectionSettingsModel,
    ToolbarItems,
} from '@syncfusion/ej2-angular-grids';
import {HardwareConfig} from '@src/app/_models/services/hardware-config';
import {NetPackages} from '@src/app/_models/services/net-packages';
import {TvServices} from '@src/app/_models/services/tv-services';
import {TvPackages} from '@src/app/_models/services/tv-packages';
import {Client} from '@src/app/_models/clients/client';
import {AddressUntrusted} from '@src/app/_models/services/address-untrusted';
import {AddressExcluded} from '@src/app/_models/services/address-excluded';
import {AddressConnectedRuian} from '@src/app/_models/services/address-connected-ruian';
import {AddressConnectedField} from '@src/app/_models/services/address-connected-field';
import {PlanningOutages} from '@src/app/_models/services/planning-outages';
import {Company} from '@src/app/_models/company/company';
import {Observable, Observer} from 'rxjs';
import {ClientService} from '@src/app/_models/clients/client-service';
import {DealTypes} from '@src/app/_models/services/deal-types';
import {UnitTypes} from '@src/app/_models/services/unit-types';
import {SpeedTypes} from '@src/app/_models/services/speed-types';
import {SpaceTypes} from '@src/app/_models/services/space-types';
import {Places} from '@src/app/_models/services/places';
import {ExtendServices} from '@src/app/_models/services/extend-services';
import {registerLocaleData} from '@angular/common';
import moment from 'moment';
import localeCs from '@angular/common/locales/cs';
import {User} from '@src/app/_models/user/user';
import {DropDownListComponent} from '@syncfusion/ej2-angular-dropdowns';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {AccordionComponent} from '@syncfusion/ej2-angular-navigations';
import {CheckBoxComponent} from '@syncfusion/ej2-angular-buttons';
import {
    ClientGridItems,
    Marker,
    ServiceGridItems,
} from '@src/app/features/services/services.service';
import {Lightbox} from 'ngx-lightbox';
import {GalleryImages} from '@src/app/_models/images/images.type';
import {GoogleMap, MapInfoWindow} from '@angular/google-maps';

moment.updateLocale('cs', {workingWeekdays: [1, 2, 3, 4, 5]});
registerLocaleData(localeCs);

declare let smartform;

@UntilDestroy()
@Component({
    selector: 'app-searcher',
    templateUrl: './searcher.component.html',
    styleUrls: ['./searcher.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearcherComponent implements OnInit {
    // Grid
    pageSettings: object;

    initialClientSort: object;

    initialServiceSort: object;

    currencyFormat: {format: string} = {format: '#,###.00\',- CZK\''};

    dateFormat: {type: string; format: string} = {type: 'dateTime', format: 'dd.MM.yyyy HH:mm'};

    fields: object = {text: 'label', value: 'value'};

    clientElements: ClientGridItems[] = [];

    serviceElements: ServiceGridItems[] = [];

    selectionSettings: SelectionSettingsModel;

    toolbar: ToolbarItems[];

    // Dropdowns
    companySelect: Array<{value: boolean | number | string; label: string}> = [];

    unitsSelect: Array<{value: boolean | number | string; label: string}> = [];

    speedsSelect: Array<{value: boolean | number | string; label: string}> = [];

    spacesSelect: Array<{value: boolean | number | string; label: string}> = [];

    dealSelect: Array<{value: boolean | number | string; label: string}> = [];

    // Forms
    isDirty = false;

    addressForm: FormGroup;

    wifiForm: FormGroup;

    // Variables
    currentUser: User | null;

    activeWifiSets = false;

    stbWarn: boolean;

    ruianCode: number;

    fieldCode: number;

    streetCode: number;

    partCode: number;

    imagesBasic: GalleryImages[] = [];

    cityCode: number;

    districtCode: number;

    regionCode: number;

    searchedAddress: string | null = null;

    searchedTerm?: string = '';

    searchedDistrict = '';

    hintText = '';

    feePeriod = 'monthly';

    purchaseWifi = 'purchase';

    discount = 0;

    totalCsPrice = 0;

    setttopboxFee = 0;

    vipinstallFee = 0;

    setttopboxFeeAnnualy = 0;

    internetPriceAnnualy = 0;

    televisionPriceAnnualy = 0;

    extServicesPriceAnnualy = 0;

    wifiPriceAnnualy = 0;

    dealAge = 24;

    // Choosen variables
    choosenWiFiServices: HardwareConfig | null = null;

    choosenInternetTarif: NetPackages | null = null;

    choosenTelevisionTarif: TvServices | null = null;

    choosenTelevisionPackages: TvPackages | null = null;

    choosenTelevisionExtensions: TvPackages[] = [];

    choosenExtendedServices: ExtendServices[] = [];

    // Seraching Variables
    matchedWifiSets: HardwareConfig[] = [];

    matchedConnectedRuianAddress: Awaited<
        AddressConnectedField[] | AddressConnectedRuian | PlanningOutages[] | null | undefined
    >;

    searchedConnectedFieldAddress: Awaited<
        AddressConnectedField[] | AddressConnectedRuian | PlanningOutages[] | undefined
    > = [];

    matchedConnectedFieldAddress: AddressConnectedField | null;

    matchedUntrustedAddresses: AddressUntrusted | null | undefined;

    matchedOutages: Awaited<
        AddressConnectedField[] | AddressConnectedRuian | PlanningOutages[] | undefined
    > = [];

    matchedWirelessAddress: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        connection_method: string;
        technology: string;
        ruian: number;
        // eslint-disable-next-line @typescript-eslint/naming-convention
        net_packages: NetPackages[];
        // eslint-disable-next-line @typescript-eslint/naming-convention
        tv_services: TvServices[];
    } | null;

    matchedNetPlacesPackages: NetPackages[] = [];

    matchedTvPlacesServices: TvServices[] = [];

    // Default variables
    activeTvServices: TvServices[] = [];

    activeNetPackages: NetPackages[] = [];

    companies: Company[] = [];

    deals: DealTypes[] = [];

    speeds: SpeedTypes[] = [];

    spaces: SpaceTypes[] = [];

    units: UnitTypes[] = [];

    connectedClients: Awaited<Client | undefined>[] = [];

    connectedServices: ClientService[] = [];

    connectedClientServices: ClientService[] = [];

    unstrustedAddresses: AddressUntrusted[] = [];

    excludedAddresses: AddressExcluded[] = [];

    hardwareConfigs: HardwareConfig[] = [];

    // Loaders
    clientsLoading = false;

    servicesLoading = false;

    addressLoading = false;

    loadingLead = false;

    clickedRow: Client[] = [];

    clickedRow$ = new Observable<Client[]>((observer: Observer<Client[]>) => {
        observer.next(this.clickedRow);
    });

    currentIndex = -1;

    showFlag = false;

    // Map
    zoom = 12;

    center: google.maps.LatLngLiteral;

    options: google.maps.MapOptions = {
        mapTypeId: 'hybrid',
        zoomControl: false,
        scrollwheel: false,
        disableDoubleClickZoom: true,
        maxZoom: 15,
        minZoom: 8,
    };

    markers: Marker[] = [];

    infoContent = '';

    @ViewChild(GoogleMap, {static: false}) map: GoogleMap;

    @ViewChild(MapInfoWindow, {static: false}) info: MapInfoWindow;

    @ViewChild('hintArea') hintArea: HTMLElement;

    @ViewChild('pdfFinishTable') pdfFinishTable: ElementRef;

    @ViewChild('firstStepAccr') firstStepAccrObj: AccordionComponent;

    // Checkboxes
    @ViewChild('sendInvoicesByPost') sendInvoicesByPost: CheckBoxComponent;

    @ViewChild('sendInvoiceByEmail') sendInvoiceByEmail: CheckBoxComponent;

    @ViewChild('vipInstall') vipInstall: CheckBoxComponent;

    @ViewChild('invoiceByYear') invoiceByYear: CheckBoxComponent;

    // Grid
    @ViewChild('gridClients', {static: false}) gridClients?: GridComponent;

    @ViewChild('gridServices', {static: false}) gridServices?: GridComponent;

    // Dropdowns
    @ViewChild('deal') dealTypeObj: DropDownListComponent;

    @ViewChild('unit') unitTypeObj: DropDownListComponent;

    @ViewChild('space') flatSpaceObj: DropDownListComponent;

    @ViewChild('speed') speedTypeObj: DropDownListComponent;

    @ViewChild('company') companyTypeObj: DropDownListComponent;

    constructor(
        private readonly formBuilder: FormBuilder,
        private readonly router: Router,
        private readonly ref: ChangeDetectorRef,
        private readonly route: ActivatedRoute,
        private readonly dataNetbaseService: DataNetbaseService,
        private readonly authenticationService: AuthenticationService,
        private readonly dataService: DataService,
        private readonly zone: NgZone,
        private readonly lightbox: Lightbox,
    ) {
        this.currentUser = this.authenticationService.currentUserValue;
        this.zone = zone;
    }

    get addressFormCtrl(): {[key: string]: AbstractControl} {
        return this.addressForm.controls;
    }

    get wifiFormCtrl(): {[key: string]: AbstractControl} {
        return this.wifiForm.controls;
    }

    private getUnique(
        arr: Array<{value: boolean | number | string; label: string}>,
        comp: string,
    ): Array<{value: boolean | number | string; label: string}> {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        return (
            arr
                // eslint-disable-next-line @typescript-eslint/no-unsafe-return
                .map(e => e[comp])
                .map((e, i, final) => final.indexOf(e) === i && i)
                // eslint-disable-next-line @typescript-eslint/no-unsafe-return
                .filter(e => arr[e ? e : 0])
                // eslint-disable-next-line @typescript-eslint/no-unsafe-return
                .map(e => arr[e ? e : 0])
        );
    }

    open(index: number): void {
        this.lightbox.open(this.imagesBasic, index);
    }

    close(): void {
        this.lightbox.close();
    }

    // SMARTFORM INITs + ReInits
    reinitSmartForm(): void {
        smartform.rebindAllForms(false);
        console.info('Reinitialize ADDRESS SF objects...');

        const instanceCreate = smartform.getInstance('smartform-instance-create');

        instanceCreate.setZIndex(100000000);
        instanceCreate.setSelectionBackgroundColor('#4285f3');
        instanceCreate.addAnnounceCallback(async (validationType, addressArray) => {
            if (validationType === smartform.ValidationResultType.HIT) {
                this.ruianCode = 0;
                this.fieldCode = 0;
                this.searchedAddress = null;
                this.isDirty = this.addressForm.dirty;
                this.ref.markForCheck();
                this.streetCode = addressArray[0].STREET_CODE
                    ? parseInt(addressArray[0].STREET_CODE as string, 10)
                    : 0;

                this.partCode = addressArray[0].STREET_CODE
                    ? parseInt(addressArray[0].PART_CODE as string, 10)
                    : 0;

                this.cityCode = addressArray[0].CITY_CODE
                    ? parseInt(addressArray[0].CITY_CODE as string, 10)
                    : 0;

                this.districtCode = addressArray[0].DISTRICT_CODE
                    ? parseInt(addressArray[0].DISTRICT_CODE as string, 10)
                    : 0;

                this.regionCode = addressArray[0].REGION_CODE
                    ? parseInt(addressArray[0].REGION_CODE as string, 10)
                    : 0;
                this.searchedAddress = addressArray[0].ADDRESS_WHOLE;
                this.searchedDistrict = addressArray[0].DISTRICT;

                if (
                    this.ruianCode &&
                    this.ruianCode !== parseInt(addressArray[0].CODE as string, 10)
                ) {
                    this.ruianCode = addressArray[0].CODE
                        ? parseInt(addressArray[0].CODE as string, 10)
                        : 0;
                    await this.setAddress();
                } else if (!this.ruianCode) {
                    this.ruianCode = addressArray[0].CODE
                        ? parseInt(addressArray[0].CODE as string, 10)
                        : 0;
                    await this.setAddress();
                }
            }
        });
    }

    ngOnInit(): void {
        this.pageSettings = {pageCount: 5};
        this.initialServiceSort = {
            columns: [{field: 'price', direction: 'Descending'}],
        };

        this.initialClientSort = {
            columns: [{field: 'name', direction: 'Ascending'}],
        };
        this.selectionSettings = {type: 'Multiple', mode: 'Both'};
        this.toolbar = ['Search'];

        // Cnnected CLients + Services
        this.dataService.clientDetailSource.pipe(untilDestroyed(this))
            .subscribe(
                (client: Client | undefined) => {
                    if (client) {
                        this.connectedClients.push(client);
                    }
                },
                error => {
                    console.error(error);
                },
            );

        this.dataNetbaseService.serviceAddressSource
            .pipe(untilDestroyed(this))
            .subscribe(async (services: ClientService[] | undefined) => {
                if (services && services.length > 0) {
                    let clientsObject = [];

                    this.connectedServices = services;
                    this.tableServicesLoad();
                    clientsObject = services.reduce(
                        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
                        (a, c) => ((a[c.client_id] = ((a[c.client_id] as number) || 0) + 1), a),
                        Object.create(null),
                    );

                    const clientsArray: {[key: string]: ClientService}[] = [];

                    Object.keys(clientsObject)
                        .map(key => {
                            clientsArray.push({[key]: clientsObject[key]});

                            return clientsArray;
                        });
                    this.connectedClients = [];

                    const promises: Promise<Client | undefined>[] = [];

                    for (const ids of clientsArray) {
                        const id = Object.keys(ids)[0];
                        const clientPromise = this.dataService
                            .setClientDetailSource(parseInt(id, 10))
                            .toPromise();

                        promises.push(clientPromise);
                    }

                    await Promise.all(promises)
                        .then(result => {
                            this.connectedClients = result;
                            this.tableClientsLoad();
                        });
                }
            });

        // UNTRUSTED ADDRESSES
        this.dataNetbaseService.untrustedAddressSource.pipe(untilDestroyed(this))
            .subscribe(
                (addresses: AddressUntrusted[] | undefined) => {
                    if (addresses) {
                        this.unstrustedAddresses = addresses;
                        this.ref.markForCheck();
                    }
                },
                error => {
                    console.error(error);
                },
            );

        // EXCLUDED ADDRESSES
        this.dataNetbaseService.excludedAddressSource.pipe(untilDestroyed(this))
            .subscribe(
                (exAddresses: AddressExcluded[] | undefined) => {
                    if (exAddresses) {
                        this.excludedAddresses = exAddresses;
                        this.ref.markForCheck();
                    }
                },
                error => {
                    console.error(error);
                },
            );

        // AVAILABLE HARDWARE CONFIGURATIONS
        this.dataNetbaseService.hardwareConfigSource.pipe(untilDestroyed(this))
            .subscribe(
                (configs: HardwareConfig[] | undefined) => {
                    if (configs) {
                        this.hardwareConfigs = configs;
                        this.hardwareConfigs = this.hardwareConfigs.sort((a, b) => {
                            const aValue = a.hardware.name;
                            const bValue = b.hardware.name;

                            return aValue > bValue ? -1 : 1;
                        });
                        this.ref.markForCheck();
                    }
                },
                error => {
                    console.error(error);
                },
            );

        // NETBASE params
        this.dataNetbaseService.dealTypesSource.pipe(untilDestroyed(this))
            .subscribe(
                (deals: DealTypes[] | undefined) => {
                    this.dealSelect = [];

                    if (deals && deals.length > 0) {
                        this.deals = deals;
                        deals = deals.sort((a, b) => {
                            const aValue = a.age;
                            const bValue = b.age;

                            return aValue > bValue ? -1 : 1;
                        });

                        deals.map((deal: DealTypes) => {
                            if (!deal.deleted_date) {
                                this.dealSelect = [
                                    ...this.dealSelect,
                                    {
                                        value: deal.id,
                                        label: `${deal.age} měsíců`,
                                    },
                                ];
                            }
                        });
                    }
                },
                error => {
                    console.error(error);
                },
            );

        this.dataService.companySource.pipe(untilDestroyed(this))
            .subscribe(
                (companies: Company[] | undefined) => {
                    this.companySelect = [];

                    if (companies) {
                        this.companies = companies.sort((a, b) => {
                            const aValue = a.name;
                            const bValue = b.name;

                            return aValue > bValue ? -1 : 1;
                        });

                        companies.map((company: Company) => {
                            if (!company.deleted_date) {
                                this.companySelect = [
                                    ...this.companySelect,
                                    {
                                        value: company.id,
                                        label: company.name,
                                    },
                                ];
                            }
                        });
                    }
                },
                error => {
                    console.error(error);
                },
            );

        this.dataNetbaseService.unitTypesSource.pipe(untilDestroyed(this))
            .subscribe(
                (units: UnitTypes[] | undefined) => {
                    this.unitsSelect = [];
                    units?.sort((a, b) => {
                        const aValue = a.name;
                        const bValue = b.name;

                        return aValue > bValue ? -1 : 1;
                    });

                    units?.map((unit: UnitTypes) => {
                        if (!unit.deleted_date) {
                            this.unitsSelect = [
                                ...this.unitsSelect,
                                {
                                    value: unit.id,
                                    label: unit.name,
                                },
                            ];
                        }
                    });
                },
                error => {
                    console.error(error);
                },
            );

        this.dataNetbaseService.speedTypesSource.pipe(untilDestroyed(this))
            .subscribe(
                (speeds: SpeedTypes[] | undefined) => {
                    this.speedsSelect = [];
                    speeds?.sort((a, b) => {
                        const aValue = a.speed_until;
                        const bValue = b.speed_until;

                        return aValue > bValue ? -1 : 1;
                    });

                    speeds?.map((speed: SpeedTypes) => {
                        if (!speed.deleted_date) {
                            this.speedsSelect = [
                                ...this.speedsSelect,
                                {
                                    value: speed.id,
                                    label: `${speed.speed_until} Mbit - ${
                                        speed.speed_to > 0 ? speed.speed_to : '*'
                                    } Mbit`,
                                },
                            ];
                        }
                    });
                },
                error => {
                    console.error(error);
                },
            );

        this.dataNetbaseService.spaceTypesSource.pipe(untilDestroyed(this))
            .subscribe(
                (spaces: SpaceTypes[] | undefined) => {
                    this.spacesSelect = [];
                    spaces?.sort((a, b) => {
                        const aValue = a.metric_until;
                        const bValue = b.metric_until;

                        return aValue > bValue ? -1 : 1;
                    });

                    spaces?.map((space: SpaceTypes) => {
                        if (!space.deleted_date) {
                            this.spacesSelect = [
                                ...this.spacesSelect,
                                {
                                    value: space.id,
                                    label: `${space.metric_until} m2 - ${
                                        space.metric_to > 0 ? space.metric_to : '*'
                                    } m2`,
                                },
                            ];
                        }
                    });
                },
                error => {
                    console.error(error);
                },
            );

        this.dataNetbaseService.tvServicesSource.pipe(untilDestroyed(this))
            .subscribe(
                (tvServices: TvServices[] | undefined) => {
                    this.activeTvServices = [];
                    tvServices?.sort((a, b) => {
                        const aValue = a.name;
                        const bValue = b.name;

                        return aValue > bValue ? -1 : 1;
                    });

                    tvServices?.map((tvService: TvServices) => {
                        if (!tvService.deleted_date) {
                            this.activeTvServices.push(tvService);
                        }
                    });
                },
                error => {
                    console.error(error);
                },
            );

        this.dataNetbaseService.netPackagesSource.pipe(untilDestroyed(this))
            .subscribe(
                (netPackages: NetPackages[] | undefined) => {
                    this.activeNetPackages = [];
                    netPackages?.sort((a, b) => {
                        const aValue = a.name;
                        const bValue = b.name;

                        return aValue > bValue ? -1 : 1;
                    });

                    netPackages?.map((netPackage: NetPackages) => {
                        if (!netPackage.deleted_date) {
                            this.activeNetPackages.push(netPackage);
                        }
                    });
                },
                error => {
                    console.error(error);
                },
            );

        // Address form
        this.addressForm = this.formBuilder.group({
            address: ['', Validators.required],
        });

        // WiCon form
        this.wifiForm = this.formBuilder.group({
            basics: [false, Validators.required],
            garden: [false, Validators.required],
            cabels: [false, Validators.required],
            roaming: [false, Validators.required],
            unit_id: [null, Validators.required],
            space_id: [null, Validators.required],
            speed_id: [null, Validators.required],
        });

        this.wifiForm.valueChanges.pipe(untilDestroyed(this))
            .subscribe(value => {
                this.spacesSelect = [];
                this.speedsSelect = [];
                this.matchedWifiSets = [];

                let filteredData: HardwareConfig[] = this.hardwareConfigs;
                const {basics} = value;
                const {garden} = value;
                const {cabels} = value;
                const {roaming} = value;
                const unitId = value.unit_id;
                const spaceId = value.space_id;
                const speedId = value.speed_id;

                if (unitId) {
                    filteredData = filteredData.filter(
                        (config: HardwareConfig) => config.unit_id === unitId,
                    );

                    filteredData.forEach((config: HardwareConfig) => {
                        this.spacesSelect = [
                            ...this.spacesSelect,
                            {
                                value: config.space_id,
                                label: `${config.space.metric_until} m2 - ${
                                    config.space.metric_to > 0 ? config.space.metric_to : '*'
                                } m2`,
                            },
                        ];
                    });
                    this.spacesSelect = this.getUnique(this.spacesSelect, 'value');
                }

                if (basics) {
                    filteredData = filteredData.filter(
                        (config: HardwareConfig) => config.basics === basics,
                    );
                }

                if (cabels) {
                    filteredData = filteredData.filter(
                        (config: HardwareConfig) => config.cabels === cabels,
                    );
                }

                if (garden) {
                    filteredData = filteredData.filter(
                        (config: HardwareConfig) => config.garden === garden,
                    );
                }

                if (roaming) {
                    filteredData = filteredData.filter(
                        (config: HardwareConfig) => config.roaming === roaming,
                    );
                }

                if (spaceId) {
                    filteredData = filteredData.filter(
                        (config: HardwareConfig) => config.space_id === spaceId,
                    );

                    filteredData.forEach((config: HardwareConfig) => {
                        this.speedsSelect = [
                            ...this.speedsSelect,
                            {
                                value: config.speed_id,
                                label: `${config.speed.speed_until} Mbit - ${
                                    config.speed.speed_to > 0 ? config.speed.speed_to : '*'
                                } Mbit`,
                            },
                        ];
                    });
                    this.speedsSelect = this.getUnique(this.speedsSelect, 'value');
                }

                if (speedId) {
                    filteredData = filteredData.filter(
                        (config: HardwareConfig) => config.speed_id === speedId,
                    );
                }

                if (speedId || spaceId || roaming || garden || basics || unitId || cabels) {
                    this.activeWifiSets = true;
                    this.matchedWifiSets = filteredData;
                }

                this.isDirty = this.wifiForm.dirty;
            });

        // SET DATA
        this.dataNetbaseService.setTvServicesDataSource();
        this.dataNetbaseService.setNetPackagesDataSource();
        this.dataNetbaseService.setUntrustedAddressDataSource();
        this.dataNetbaseService.setExcludedAddressDataSource();
        this.dataService.setCompanyDataSource();
        this.dataNetbaseService.setTechnologyTypesDataSource();
        this.dataNetbaseService.setDiscountsDataSource();
        this.dataNetbaseService.setDealTypesDataSource();
        this.dataNetbaseService.setUnitTypesDataSource();
        this.dataNetbaseService.setSpaceTypesDataSource();
        this.dataNetbaseService.setSpeedTypesDataSource();
        this.dataNetbaseService.setHardwareConfigDataSource();

        this.unsetAll();

        navigator.geolocation.getCurrentPosition(position => {
            this.center = {
                lat: position.coords.latitude,
                lng: position.coords.longitude,
            };
        });
    }

    zoomIn(): void {
        if (this.options.maxZoom && this.zoom < this.options.maxZoom) {
            this.zoom++;
        }
    }

    zoomOut(): void {
        if (this.options.minZoom && this.zoom > this.options.minZoom) {
            this.zoom--;
        }
    }

    logCenter(): void {
        console.log(JSON.stringify(this.map.getCenter()));
    }

    addMarker(): void {
        this.markers.push({
            position: {
                lat: this.center.lat + ((Math.random() - 0.5) * 2) / 10,
                lng: this.center.lng + ((Math.random() - 0.5) * 2) / 10,
            },
            label: {
                color: 'red',
                text: `Marker label ${this.markers.length + 1}`,
            },
            title: `Marker title ${this.markers.length + 1}`,
            info: `Marker info ${this.markers.length + 1}`,
            options: {
                animation: google.maps.Animation.BOUNCE,
            },
        });
    }

    // Clients + Services GRID + FCE
    tableClientsLoad(): void {
        this.clientElements = [];

        if (this.connectedClients.length > 0) {
            this.connectedClients.map((data: Client) => {
                this.clientElements.push({
                    id: data.id,
                    client_number: data.client_number,
                    name: data.name,
                    isp_link: `https://isp.jon.cz/client-informations/?clientId=${data.isp_id}`,
                });
            });
        }

        this.clientsLoading = false;
        this.ref.markForCheck();
    }

    tableServicesLoad(): void {
        this.totalCsPrice = 0;
        this.serviceElements = [];

        if (this.connectedClientServices.length > 0) {
            this.connectedClientServices.map((service: ClientService) => {
                this.totalCsPrice += service.price;
                this.serviceElements.push({
                    id: service.id,
                    name: service.name,
                    price: service.price,
                });
            });
        } else if (this.connectedServices.length > 0) {
            this.connectedServices.map((service: ClientService) => {
                this.totalCsPrice += service.price;
                this.serviceElements.push({
                    id: service.id,
                    name: service.name,
                    price: service.price,
                });
            });
        }

        this.servicesLoading = false;
        this.ref.markForCheck();
    }

    actionClientsBegin(args: SearchEventArgs): void {
        if (args.requestType === 'searching') {
            if (
                !args.searchString ||
                this.searchedTerm !== args.searchString ||
                args.searchString.length === 0
            ) {
                this.clickedRow = [];
                this.connectedClientServices = [];
                this.gridClients?.clearRowSelection();
                this.tableServicesLoad();
            }

            this.searchedTerm = args.searchString;
        }
    }

    rowSelected(): void {
        const selectedrecords: Client[] = this.gridClients?.getSelectedRecords() as Client[];

        if (selectedrecords.length > 0) {
            selectedrecords.map((row: Client) => {
                const client = this.connectedClients.find(x => x && x.id === row.id);

                if (client) {
                    this.clickedRow.push(client);
                }

                this.clickedRow$ = new Observable<Client[]>((observer: Observer<Client[]>) => {
                    observer.next(this.clickedRow);
                });
            });
        } else {
            this.clickedRow = [];
            this.connectedClientServices = [];
            this.clickedRow$ = new Observable<Client[]>((observer: Observer<Client[]>) => {
                observer.next(this.clickedRow);
            });
        }

        this.tableServicesLoad();
    }

    createdClients(): void {
        const gridElement = this.gridClients?.element;
        const span = document.createElement('span');

        if (gridElement) {
            span.className = 'e-clear-icon';
            span.id = `${gridElement.id}clear`;
            span.onclick = this.cancelBtnClickClients.bind(this);
            gridElement.querySelector('.e-toolbar-item .e-input-group')
                ?.appendChild(span);
        }
    }

    cancelBtnClickClients(): void {
        if (this.gridClients) {
            this.gridClients.searchSettings.key = '';

            const element: HTMLInputElement | null = this.gridClients.element.querySelector(
                '.e-input-group.e-search .e-input',
            );

            if (element) {
                element.value = '';
            }
        }
    }

    createdServices(): void {
        const gridElement = this.gridServices?.element;
        const span = document.createElement('span');

        if (gridElement) {
            span.className = 'e-clear-icon';
            span.id = `${gridElement.id}clear`;
            span.onclick = this.cancelBtnClickServices.bind(this);
            gridElement.querySelector('.e-toolbar-item .e-input-group')
                ?.appendChild(span);
        }
    }

    cancelBtnClickServices(): void {
        if (this.gridServices) {
            this.gridServices.searchSettings.key = '';

            const element: HTMLInputElement | null = this.gridServices.element.querySelector(
                '.e-input-group.e-search .e-input',
            );

            if (element) {
                element.value = '';
            }
        }
    }

    // ADDRESS
    chooseFieldAddress(field): void {
        if (field && this.searchedAddress && this.searchedAddress.length > 0) {
            this.matchedConnectedFieldAddress = field;
            this.addressForm.controls.address.patchValue(
                this.matchedConnectedFieldAddress?.address,
            );

            if (this.matchedConnectedFieldAddress) {
                this.matchedConnectedFieldAddress.net_packages =
                    this.matchedConnectedFieldAddress.net_packages.sort((a, b) => {
                        const valueA = a.sale_order;
                        const valueB = b.sale_order;

                        return valueA < valueB ? -1 : 1;
                    });

                this.matchedConnectedFieldAddress.net_packages.map((netPackage: NetPackages) => {
                    netPackage.ext_services.map((extService: ExtendServices) => {
                        extService.is_selected = false;
                    });
                });

                this.matchedConnectedFieldAddress.tv_services =
                    this.matchedConnectedFieldAddress.tv_services?.sort((a, b) => {
                        const valueA = a.sale_order;
                        const valueB = b.sale_order;

                        return valueA < valueB ? -1 : 1;
                    });

                this.matchedConnectedFieldAddress.tv_services?.map((tvService: TvServices) => {
                    tvService.ext_services.map((extService: ExtendServices) => {
                        extService.is_selected = false;
                    });
                });

                this.matchedConnectedFieldAddress.tv_services?.forEach(
                    (service: TvServices, index) => {
                        if (this.matchedConnectedFieldAddress?.tv_services) {
                            this.matchedConnectedFieldAddress.tv_services[index].tv_packages =
                                service.tv_packages?.sort((a, b) => {
                                    const valueA = a.sale_order;
                                    const valueB = b.sale_order;

                                    return valueA < valueB ? -1 : 1;
                                });
                        }
                    },
                );
            }

            this.searchedAddress = field.address;
            this.fieldCode = field.field_number;
            // Výpadky služeb na adrese
            this.dataNetbaseService.setOutageDataSource(
                true,
                this.searchedAddress,
                this.ruianCode,
                this.fieldCode,
                this.streetCode,
                this.cityCode,
                this.partCode,
                this.districtCode,
                this.regionCode,
            );
            this.addressLoading = false;
            this.clientsLoading = false;
            this.servicesLoading = false;
            // GoogleMaps
            this.findLocation(this.searchedAddress);
            this.ref.markForCheck();
        }
    }

    async setAddress(): Promise<void> {
        if (this.searchedAddress && this.searchedAddress.length > 0) {
            this.unsetAll();
            this.dataNetbaseService.clearClientServiceCache();

            const promises: Promise<
                AddressConnectedField[] | AddressConnectedRuian | PlanningOutages[] | undefined
            >[] = [];

            this.addressLoading = true;

            // Výpadky služeb na adrese
            const outagesObj = this.dataNetbaseService
                .searchOutageDataSource(
                    true,
                    this.searchedAddress,
                    this.ruianCode,
                    this.fieldCode,
                    this.streetCode,
                    this.cityCode,
                    this.partCode,
                    this.districtCode,
                    this.regionCode,
                )
                .toPromise();

            promises.push(outagesObj);

            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            const ruianObj = this.dataNetbaseService.searchRuianData(this.ruianCode)
                .toPromise();

            promises.push(ruianObj);

            const fieldObj = this.dataNetbaseService
                .searchFieldData(this.searchedAddress)
                .toPromise();

            promises.push(fieldObj);

            await Promise.all(promises)
                .then(result => {
                    if (result.length > 0) {
                        if (result[0]) {
                            this.matchedOutages = result[0];
                        }

                        if (result[1]) {
                            this.matchedConnectedRuianAddress = result[1];
                        }

                        if (result[2]) {
                            this.searchedConnectedFieldAddress = result[2];
                        }

                        this.matchedUntrustedAddresses = this.unstrustedAddresses.find(
                            x => x.ruian === this.ruianCode,
                        );

                        if (this.ruianCode) {
                            this.dataNetbaseService.setClientAddressServicesSource(
                                this.searchedAddress,
                                this.ruianCode,
                            );
                        }

                        // Sbírá NET tarify podle oblastí
                        this.matchedNetPlacesPackages = [];
                        this.matchedTvPlacesServices = [];

                        let matchedExcludedAddresses: AddressExcluded[] = [];

                        if (this.activeNetPackages.length > 0) {
                            this.activeNetPackages.map((pckg: NetPackages) => {
                                matchedExcludedAddresses = pckg.excluded_addresses.filter(
                                    (x: AddressExcluded) =>
                                        x.address === this.searchedAddress ||
                                        x.ruian === this.ruianCode ||
                                        (x.address_obj &&
                                            ((x.address_obj.street_code &&
                                                    parseInt(x.address_obj.street_code, 10) ===
                                                    this.streetCode) ||
                                                (x.address_obj.city_part_code &&
                                                    parseInt(x.address_obj.city_part_code, 10) ===
                                                    this.partCode) ||
                                                (x.address_obj.city_code &&
                                                    parseInt(x.address_obj.city_code, 10) ===
                                                    this.cityCode) ||
                                                (x.address_obj.district_code &&
                                                    parseInt(x.address_obj.district_code, 10) ===
                                                    this.districtCode) ||
                                                (x.address_obj.region_code &&
                                                    parseInt(x.address_obj.region_code, 10) ===
                                                    this.regionCode))),
                                );

                                pckg.excludeOff = matchedExcludedAddresses.length > 0;

                                if (pckg.net_places.length > 0) {
                                    pckg.net_places.forEach((place: Places) => {
                                        if (
                                            place.ruian === this.ruianCode ||
                                            place.street_code === this.streetCode ||
                                            place.city_part_code === this.partCode ||
                                            place.city_code === this.cityCode ||
                                            place.district_code === this.districtCode ||
                                            place.region_code === this.regionCode
                                        ) {
                                            this.matchedNetPlacesPackages.push(pckg);
                                        }
                                    });
                                }
                            });
                        }

                        // Sbírá TV tarify podle oblastí
                        if (this.activeTvServices.length > 0) {
                            this.activeTvServices.map((srv: TvServices) => {
                                matchedExcludedAddresses = srv.excluded_addresses.filter(
                                    (x: AddressExcluded) =>
                                        x.address === this.searchedAddress ||
                                        x.ruian === this.ruianCode ||
                                        (x.address_obj &&
                                            ((x.address_obj.street_code &&
                                                    parseInt(x.address_obj.street_code, 10) ===
                                                    this.streetCode) ||
                                                (x.address_obj.city_part_code &&
                                                    parseInt(x.address_obj.city_part_code, 10) ===
                                                    this.partCode) ||
                                                (x.address_obj.city_code &&
                                                    parseInt(x.address_obj.city_code, 10) ===
                                                    this.cityCode) ||
                                                (x.address_obj.district_code &&
                                                    parseInt(x.address_obj.district_code, 10) ===
                                                    this.districtCode) ||
                                                (x.address_obj.region_code &&
                                                    parseInt(x.address_obj.region_code, 10) ===
                                                    this.regionCode))),
                                );

                                srv.excludeOff = matchedExcludedAddresses.length > 0;

                                if (srv.tv_places.length > 0) {
                                    srv.tv_places.forEach((place: Places) => {
                                        if (
                                            place.ruian === this.ruianCode ||
                                            place.street_code === this.streetCode ||
                                            place.city_part_code === this.partCode ||
                                            place.city_code === this.cityCode ||
                                            place.district_code === this.districtCode ||
                                            place.region_code === this.regionCode
                                        ) {
                                            this.matchedTvPlacesServices.push(srv);
                                        }
                                    });
                                }
                            });
                        }

                        if (this.matchedNetPlacesPackages.length > 0) {
                            this.matchedWirelessAddress = {
                                connection_method: 'radio',
                                technology: 'access point',
                                ruian: this.ruianCode,
                                net_packages: this.matchedNetPlacesPackages,
                                tv_services: this.matchedTvPlacesServices,
                            };

                            this.matchedWirelessAddress.net_packages.map((netPackage: NetPackages) => {
                                netPackage.ext_services.map((extService: ExtendServices) => {
                                    extService.is_selected = false;
                                });
                            });

                            this.matchedWirelessAddress.tv_services.map((tvService: TvServices) => {
                                tvService.ext_services.map((extService: ExtendServices) => {
                                    extService.is_selected = false;
                                });
                            });
                        } else if (this.searchedAddress && this.searchedAddress.length > 0) {
                            this.matchedWirelessAddress = {
                                connection_method: 'DSL',
                                technology: 'modem',
                                ruian: this.ruianCode,
                                net_packages: this.activeNetPackages.filter(
                                    x => x.transfer.connection_method === 'DSL',
                                ),
                                tv_services: this.activeTvServices.filter(
                                    x => x.transfer.connection_method === 'DSL',
                                ),
                            };

                            this.matchedWirelessAddress.net_packages.map((netPackage: NetPackages) => {
                                netPackage.ext_services.map((extService: ExtendServices) => {
                                    extService.is_selected = false;
                                });
                            });

                            this.matchedWirelessAddress.tv_services.map((tvService: TvServices) => {
                                tvService.ext_services.map((extService: ExtendServices) => {
                                    extService.is_selected = false;
                                });
                            });
                        }

                        // GoogleMaps
                        this.findLocation(this.searchedAddress);
                        this.ref.markForCheck();
                    }

                    this.addressLoading = false;
                    this.ref.markForCheck();
                });
        }
    }

    resetWifiForm(): void {
        this.activeWifiSets = false;
        this.matchedWifiSets = [];
        this.wifiForm.reset();
    }

    unsetAll(): void {
        this.clickedRow = [];
        this.connectedClientServices = [];

        if (this.gridClients) {
            this.gridClients.clearRowSelection();
        }

        this.serviceElements = [];
        this.clientElements = [];

        if (this.gridServices) {
            this.gridServices.clearRowSelection();
        }

        this.connectedClients = [];
        this.connectedServices = [];
        this.connectedClientServices = [];
        this.matchedWifiSets = [];
        this.matchedConnectedRuianAddress = null;
        this.matchedConnectedFieldAddress = null;
        this.matchedUntrustedAddresses = null;
        this.matchedWirelessAddress = null;
        this.matchedNetPlacesPackages = [];
        this.matchedTvPlacesServices = [];

        this.resetWifiForm();

        this.ref.markForCheck();
    }

    showHint(hint: string): void {
        this.hintText = hint;
    }

    // TODO refactor
    findLocation(address): void {
        /*       if (!this.geocoder) {
                   this.geocoder = new google.maps.Geocoder();
               }

               // eslint-disable-next-line @typescript-eslint/ban-ts-comment
               // @ts-expect-error
               this.geocoder.geocode(
                   {
                       address,
                   },
                   async (results, status) => {
                       if (status === google.maps.GeocoderStatus.OK) {
                           // eslint-disable-next-line @typescript-eslint/prefer-for-of
                           for (let i = 0; i < results[0].address_components.length; i++) {
                               const { types } = results[0].address_components[i];

                               if (types.indexOf('locality') !== -1) {
                                   this.location.address_level_2 =
                                       results[0].address_components[i].long_name;
                               }

                               if (types.indexOf('country') !== -1) {
                                   this.location.address_country =
                                       results[0].address_components[i].long_name;
                               }

                               if (types.indexOf('postal_code') !== -1) {
                                   this.location.address_zip = results[0].address_components[i].long_name;
                               }

                               if (types.indexOf('administrative_area_level_1') !== -1) {
                                   this.location.address_state =
                                       results[0].address_components[i].long_name;
                               }
                           }

                           if (this.location.marker && results[0].geometry.location) {
                               this.location.lat = results[0].geometry.location.lat();
                               this.location.lng = results[0].geometry.location.lng();
                               this.location.marker.lat = results[0].geometry.location.lat();
                               this.location.marker.lng = results[0].geometry.location.lng();
                               this.location.marker.draggable = true;
                               this.location.viewport = results[0].geometry.viewport;
                           }
                       }
                       else {
                           alert('Tuto adresu nelze najít na Google mapě.');
                       }
                   }
               );*/
    }
}
