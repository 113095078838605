<ejs-dialog
  #deleteFileDialog
  [buttons]="buttonsDeleteFileDialog"
  [content]="'Opravdu chcete smazat tento soubor z importu ticketů?'"
  [header]="'Smazat soubor z importu ticketů'"
  [height]="'200px'"
  [isModal]="true"
  [showCloseIcon]="true"
  [visible]="false"
  [width]="'450px'"
  id="deleteFileDialog"
></ejs-dialog>
<ejs-toolbar
  [cssClass]="'text-right'"
  height="70px"
  id="toolbar_options"
  overflowMode="Scrollable"
  scrollStep="100"
>
  <e-items>
    <e-item tooltipText="JONSystem import">
      <ng-template #template>
        <div class="icon-child" style="text-align: center">
          <ejs-switch
            (change)="onImportJSChange($event)"
            [checked]="true"
            id="timeline_views"
          ></ejs-switch>
        </div>
        <div
          class="text-child p-1" style="font-size: 14px"
        >JonSystem
        </div>
      </ng-template>
    </e-item>
    <e-item type="Separator"></e-item>
    <e-item *ngIf="apis.crm" tooltipText="{{ apis.crm.title }} import">
      <ng-template #template>
        <div class="icon-child" style="text-align: center">
          <ejs-switch
            (change)="onImportCRMChange($event)"
            [checked]="false"
            id="grouping"
          ></ejs-switch>
        </div>
        <div
          class="text-child p-1" style="font-size: 14px"
        >CRM api
        </div>
      </ng-template>
    </e-item>
    <e-item type="Separator"></e-item>
    <e-item
      *ngIf="apis.siteMonitoring"
      tooltipText="{{ apis.siteMonitoring.title }} import"
    >
      <ng-template #template>
        <div class="icon-child" style="text-align: center">
          <ejs-switch
            (change)="onImportSITEMNTChange($event)"
            [checked]="false"
            id="gridlines"
          ></ejs-switch>
        </div>
        <div class="text-child p-1" style="font-size: 14px">
          SITE MONITOR api
        </div>
      </ng-template>
    </e-item>
    <e-item type="Separator"></e-item>
    <e-item [width]="300" tooltipText="stáhnout šablonu pro import">
      <ng-template #template>
        <button
          (click)="downloadSheet()"
          cssClass="e-btn e-success e-outline btn-p-zero e-small"
          ejs-button
        >
          <i
            aria-hidden="true"
            class="fa-light fa-file-download mr-1"
          ></i>
          stáhnout šablonu pro import
        </button>
      </ng-template>
    </e-item>
  </e-items>
</ejs-toolbar>
<ejs-uploader
  #preloadupload
  (beforeUpload)="beforeFileUpload($event)"
  (removing)="onFileRemove($event)"
  (selected)="onFileSelected($event)"
  (success)="onSuccessUpload($event)"
  (uploading)="onFileUpload($event)"
  [asyncSettings]="path"
  [dropArea]="dropElement"
  allowedExtensions=".xls,.xlsx"
  id="preloadfileupload"
  maxFileSize="400000000"
>
</ejs-uploader>
