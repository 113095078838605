import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnInit,
    ViewChild,
} from '@angular/core';
import {Router} from '@angular/router';
import {FormBuilder} from '@angular/forms';
import {MessageService} from '@src/app/_services/message.service';
import {Hotliner} from '@src/app/_models/hotline/hotliner';
import {Hotline} from '@src/app/_models/hotline/hotline';
import {registerLocaleData} from '@angular/common';
import {Company} from '@src/app/_models/company/company';
import {DataService} from '@src/app/_services/data.service';
import {Holiday} from '@src/app/_models/holiday/holiday';
import {AuthenticationService} from '@src/app/_services/authentication.service';
import {FullCalendarComponent} from '@fullcalendar/angular';
import {CalendarOptions} from '@fullcalendar/core';
import csLocale from '@fullcalendar/core/locales/cs';
import {PermissionService} from '@src/app/_services/permission.service';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';

import moment, {MomentInput} from 'moment-business-days';
import localeCs from '@angular/common/locales/cs';
import {User} from '@src/app/_models/user/user';
import {NavHandlerService} from '@src/app/_services/nav-handler.service';
import {VacationService} from '@src/app/features/vacation/vacation.service';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import timeGrid from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import googleCalendarPlugin from '@fullcalendar/google-calendar';

moment.updateLocale('cs', {workingWeekdays: [1, 2, 3, 4, 5]});
registerLocaleData(localeCs);

@UntilDestroy()
@Component({
    selector: 'app-hotline-calendar',
    templateUrl: './hotline-calendar.component.html',
    styleUrls: ['./hotline-calendar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HotlineCalendarComponent implements OnInit {
    currentUser: User | null;

    loadingCalendar = true;

    hotlines: Hotline[] = [];

    hotliners: Hotliner[] = [];

    companies: Company[] = [];

    holidays: Array<string> = [];

    calendarEvents: {[key: string]: Hotline | Hotliner | boolean | number | string}[] = [];

    calendarOptions: CalendarOptions = {
        googleCalendarApiKey: 'AIzaSyCCLvaMHyCqnbJC9DsQTvXGQ8lgS4D_hzo',
        initialView: 'dayGridMonth',
        editable: true,
        firstDay: 1,
        timeZone: 'local',
        locale: csLocale,
        headerToolbar: {
            start: 'title',
            center: 'dayGridMonth,timeGridWeek,listWeek',
            end: 'today prev,next',
        },
        buttonText: {
            today: 'dnes',
            month: 'měsíc',
            week: 'týden',
            day: 'den',
            list: 'seznam',
        },
        weekends: true,
        allDayText: 'celý den',
        dateClick: this.addEvent.bind(this),
        eventClick: this.eventUpdate.bind(this),
        initialEvents: [],
        eventSources: [],
        eventTimeFormat: {
            hour: '2-digit',
            minute: '2-digit',
            meridiem: false,
        },
        plugins: [dayGridPlugin, interactionPlugin, timeGrid, listPlugin, googleCalendarPlugin],
    };

    @ViewChild('hotlineCalendar') calendarComponent?: FullCalendarComponent;

    constructor(
        private readonly ref: ChangeDetectorRef,
        private readonly formBuilder: FormBuilder,
        private readonly authenticationService: AuthenticationService,
        private readonly permissionService: PermissionService,
        private readonly dataService: DataService,
        private readonly navHandlerService: NavHandlerService,
        private readonly router: Router,
        private readonly messages: MessageService,
        private readonly vacationService: VacationService,
    ) {
        this.currentUser = this.authenticationService.currentUserValue;
    }

    private loadData(): void {
        this.calendarEvents = [
            {
                googleCalendarId: 'cs.czech.official#holiday@group.v.calendar.google.com',
            },
        ];

        this.hotlines.map((hotline: Hotline) => {
            if (hotline.deleted_date) {
                const typeTrnslt = hotline.type === 'technician' ? 'služba' : 'příslužba';

                this.calendarEvents.push({
                    hotline_data: hotline,
                    hotliner_data: hotline.hotliner,
                    save_changes: true,
                    title: `${hotline.hotliner.user.firstname} ${hotline.hotliner.user.secondname}  (${typeTrnslt})\n${hotline.company.name}\n${hotline.score}  bodů`,
                    start: moment(hotline.start_at)
                        .format('YYYY-MM-DD HH:mm:ss'),
                    end: moment(hotline.end_at)
                        .format('YYYY-MM-DD HH:mm:ss'),
                    backgroundColor: hotline.type === 'technician' ? '#b30418' : '#b30418',
                    borderColor: hotline.type === 'technician' ? '#b30418' : '#b30418',
                    textColor: '#ffffff',
                    allDay: false,
                    timeFormat: 'H:mm',
                });
            } else {
                const typeTrnslt = hotline.type === 'technician' ? 'služba' : 'příslužba';

                this.calendarEvents.push({
                    hotline_data: hotline,
                    hotliner_data: hotline.hotliner,
                    save_changes: true,
                    title: `${hotline.hotliner.user.firstname} ${hotline.hotliner.user.secondname}  (${typeTrnslt})\n${hotline.company.name}\n${hotline.score}  bodů`,
                    start: moment(hotline.start_at)
                        .format('YYYY-MM-DD HH:mm:ss'),
                    end: moment(hotline.end_at)
                        .format('YYYY-MM-DD HH:mm:ss'),
                    backgroundColor: hotline.type === 'technician' ? '#8db304' : '#2a7faf',
                    borderColor: hotline.type === 'technician' ? '#8db304' : '#2a7faf',
                    textColor: '#ffffff',
                    allDay: false,
                    timeFormat: 'H:mm',
                });
            }
        });

        this.calendarOptions.eventSources = [
            {
                googleCalendarId: 'cs.czech.official#holiday@group.v.calendar.google.com',
                color: 'yellow',
                backgroundColor: '#ff8c00',
                borderColor: '#ff8c00',
                textColor: '#ffffff',
                editable: false,
                startEditable: false,
                durationEditable: false,
            },
            this.calendarEvents,
        ];
        this.loadingCalendar = false;
        this.refreshContent();
        this.ref.markForCheck();
    }

    ngOnInit(): void {
        this.navHandlerService.navState.pipe(untilDestroyed(this))
            .subscribe(
                () => {
                    this.refreshContent();
                },
                error => {
                    console.error(error);
                },
            );

        this.dataService.hotlineSource.pipe(untilDestroyed(this))
            .subscribe(
                (hotlines: Hotline[]) => {
                    this.loadingCalendar = true;
                    this.hotlines = hotlines;
                    this.loadData();
                },
                error => {
                    console.error(error);
                },
            );

        this.dataService.hotlinersSource.pipe(untilDestroyed(this))
            .subscribe(
                (data: Hotliner[]) => {
                    data.forEach((hotliner: Hotliner) => {
                        if (!hotliner.deleted_date) {
                            this.hotliners = data;
                        }
                    });
                    this.ref.markForCheck();
                },
                error => {
                    console.error(error);
                },
            );

        this.dataService.companySource.pipe(untilDestroyed(this))
            .subscribe(
                (companies: Company[]) => {
                    this.companies = companies;
                    this.ref.markForCheck();
                },
                error => {
                    console.error(error);
                },
            );

        this.vacationService
            .getCurrCZEHolidays()
            .pipe(untilDestroyed(this))
            .subscribe(
                (holidays: Holiday[]) => {
                    this.holidays = [];
                    holidays.map(holiday => {
                        if (holiday.isPublicHoliday) {
                            this.holidays.push(moment(holiday.date)
                                .format('YYYY-MM-DD'));
                            moment.updateLocale('cs', {
                                holidays: this.holidays,
                                holidayFormat: 'YYYY-MM-DD',
                                workingWeekdays: [1, 2, 3, 4, 5],
                            });
                        }
                    });
                },
                error => {
                    console.error(error);
                },
            );

        this.dataService.setCompanyDataSource();
        this.dataService.setHotlinersDataSource();
    }

    refreshContent(): void {
        setTimeout(() => {
            if (this.calendarComponent) {
                this.calendarComponent.getApi()
                    .render();
            }
        }, 1000);
    }

    addEvent(arg?): void {
        if (this.currentUser && this.permissionService.checkUserISHotlineAdmin(this.currentUser)) {
            const check = arg
                ? moment(arg.dateStr as MomentInput)
                    .format('YYYY-MM-DD')
                : moment()
                    .format('YYYY-MM-DD');
            const firstDay = moment()
                .clone()
                .startOf('month')
                .format('YYYY-MM-DD');

            if (check >= firstDay) {
                void this.router.navigate(['/hotline/'], {
                    queryParams: {view: 'grid', form: 'addHotline'},
                });
            } else {
                const body = 'musíte vybrat datum ze současného měsíce';
                const options = {progressBar: true, timeOut: 5000, toastClass: 'red'};

                this.messages.showError('Hotline nelze zadávat na předchozí měsíce', body, options);
            }
        } else {
            const body = 'Nemáte oprávnění provést tuto akci...';
            const options = {progressBar: true, timeOut: 5000};

            this.messages.showError('Nedostatečné oprávnění', body, options);
            this.loadingCalendar = false;
            this.ref.markForCheck();
        }
    }

    eventUpdate(arg): void {
        void this.router.navigate(['/hotline/detail', arg.event.extendedProps.hotline_data.id]);
    }
}
