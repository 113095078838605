import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    HostListener,
    OnInit,
    ViewChild,
} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {User} from '@src/app/_models/user/user';
import {Department} from '@src/app/_models/department/department';
import {KnowledgeComment} from '@src/app/_models/knowledge/knowledge-comment';
import {Observable} from 'rxjs';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {AuthenticationService} from '@src/app/_services/authentication.service';
import {MessageService} from '@src/app/_services/message.service';
import {registerLocaleData} from '@angular/common';
import {KnowledgeFile} from '@src/app/_models/knowledge/knowledge-file';
import {Knowledge} from '@src/app/_models/knowledge/knowledge';
import {DataService} from '@src/app/_services/data.service';
import {UploaderComponent} from '@syncfusion/ej2-angular-inputs';
import {EmitType} from '@syncfusion/ej2-base';
import {DialogComponent} from '@syncfusion/ej2-angular-popups';
import {TabComponent, ToolbarModule} from '@syncfusion/ej2-angular-navigations';

import moment from 'moment';
import localeCs from '@angular/common/locales/cs';
import {SelectEventArgs} from '@syncfusion/ej2-navigations';
import {FilteringEventArgs, MultiSelectComponent} from '@syncfusion/ej2-angular-dropdowns';
import {Query} from '@syncfusion/ej2-data';
import {
    FontFamilyModel,
    ImageSettingsModel,
    QuickToolbarSettingsModel,
    RichTextEditorComponent,
} from '@syncfusion/ej2-angular-richtexteditor';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {ComponentCanDeactivate} from '@src/app/_guards/changes.guard';
import {EnvironmentService} from '@src/app/_services/environment.service';
import {Lightbox} from 'ngx-lightbox';
import {KnowledgeService} from '@src/app/features/knowledge/knowledge.service';
import {CommentsService} from '@src/app/shared_components/services/comments.service';

moment.updateLocale('cs', {workingWeekdays: [1, 2, 3, 4, 5]});
registerLocaleData(localeCs);

@UntilDestroy()
@Component({
    selector: 'app-knowledge-detail',
    templateUrl: './knowledge-detail.component.html',
    styleUrls: ['./knowledge-detail.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KnowledgeDetailComponent implements OnInit, ComponentCanDeactivate {
    // Navigations
    headerText = [
        {text: 'Postup'},
        {text: 'Diskuze'},
        {text: 'Soubory'},
        {text: 'Historie'},
    ];

    // Upload
    path: {
        saveUrl: string;
        removeUrl: string;
    };

    dropElement: HTMLElement = document.getElementsByClassName('control-fluid')[0] as HTMLElement;

    deleteFileId: number;

    // Files
    imagesBasic: {
        src: string;
        thumb: string;
        caption: string;
    }[] = [];

    // RTE
    maxLength = 15000;

    textArea: HTMLElement;

    insertImageSettings: ImageSettingsModel;

    fontFamily: FontFamilyModel = {
        items: [
            {
                text: 'Segoe UI',
                value: 'Segoe UI',
                command: 'Font',
                subCommand: 'FontName',
            },
            {
                text: 'Roboto',
                value: 'Roboto',
                command: 'Font',
                subCommand: 'FontName',
            },
            {
                text: 'Great vibes',
                value: 'Great Vibes,cursive',
                command: 'Font',
                subCommand: 'FontName',
            },
            {
                text: 'Noto Sans',
                value: 'Noto Sans',
                command: 'Font',
                subCommand: 'FontName',
            },
            {
                text: 'Impact',
                value: 'Impact,Charcoal,sans-serif',
                command: 'Font',
                subCommand: 'FontName',
            },
            {
                text: 'Tahoma',
                value: 'Tahoma,Geneva,sans-serif',
                command: 'Font',
                subCommand: 'FontName',
            },
        ],
    };

    tools: ToolbarModule = {
        items: [
            'Bold',
            'Italic',
            'Underline',
            'StrikeThrough',
            'FontName',
            'FontSize',
            'FontColor',
            'BackgroundColor',
            'LowerCase',
            'UpperCase',
            'SuperScript',
            'SubScript',
            '|',
            'Formats',
            'Alignments',
            'OrderedList',
            'UnorderedList',
            'Outdent',
            'Indent',
            '|',
            'CreateTable',
            'CreateLink',
            'Image',
            '|',
            'ClearFormat',
            'Print',
            'SourceCode',
            'FullScreen',
            '|',
            'Undo',
            'Redo',
        ],
    };

    quickToolbarSettings: QuickToolbarSettingsModel = {
        table: [
            'TableHeader',
            'TableRows',
            'TableColumns',
            'TableCell',
            '-',
            'BackgroundColor',
            'TableRemove',
            'TableCellVerticalAlign',
            'Styles',
        ],
    };

    pasteCleanupSettings: object = {
        prompt: true,
        plainText: false,
        keepFormat: false,
        deniedTags: ['a'],
        deniedAttrs: ['class', 'title', 'id'],
        allowedStyleProps: ['color', 'margin', 'font-size'],
    };

    // Dialogs
    renderKnowledgeFormDialog = false;

    buttonsDeleteKnowledgeDialog = [
        {
            click: this.removeKnowledge.bind(this),
            buttonModel: {
                content: 'OK',
                cssClass: 'e-success e-outline',
                isFlat: false,
            },
        },
        {
            click: (): void => {
                this.deleteKnowledgeDialog.hide();
            },
            buttonModel: {content: 'ZRUŠIT', cssClass: 'e-danger e-outline'},
        },
    ];

    buttonsDeleteUserDialog = [
        {
            click: this.removeUser.bind(this),
            buttonModel: {
                content: 'OK',
                cssClass: 'e-success e-outline',
                isFlat: false,
            },
        },
        {
            click: (): void => {
                this.deleteUserDialog.hide();
            },
            buttonModel: {content: 'ZRUŠIT', cssClass: 'e-danger e-outline'},
        },
    ];

    buttonsDeleteDepartmentDialog = [
        {
            click: this.removeDepartment.bind(this),
            buttonModel: {
                content: 'OK',
                cssClass: 'e-success e-outline',
                isFlat: false,
            },
        },
        {
            click: (): void => {
                this.deleteDepartmentDialog.hide();
            },
            buttonModel: {content: 'ZRUŠIT', cssClass: 'e-danger e-outline'},
        },
    ];

    buttonsDeleteCommentDialog = [
        {
            click: this.removeKwbComment.bind(this),
            buttonModel: {
                content: 'OK',
                cssClass: 'e-success e-outline',
                isFlat: false,
            },
        },
        {
            click: (): void => {
                this.deleteCommentDialog.hide();
            },
            buttonModel: {content: 'ZRUŠIT', cssClass: 'e-danger e-outline'},
        },
    ];

    // DropDowns
    renderCommentFormDialog = false;

    height = '240px';

    fields: object = {text: 'label', value: 'value'};

    usersSelect: Array<{value: boolean | number | string; label: string}> = [];

    departmentSelect: Array<{value: boolean | number | string; label: string}> = [];

    // Forms
    addUserForm: FormGroup;

    createCommentForm: FormGroup;

    createFileForm: FormGroup;

    addDepartmentForm: FormGroup;

    submited = false;

    isDirty = false;

    isCreate = false;

    isUpdate = false;

    isCopy = false;

    isCreateComment = false;

    isUpdateComment = false;

    isCopyComment = false;

    // Variables
    contentPrinted: boolean;

    contentSearched: number;

    currentUser: User | null;

    knowledge: Knowledge | null = null;

    highlightedUserRow: User;

    highlightedDepartmentRow: Department;

    highlightedComment: KnowledgeComment;

    // Loaders
    loadingKnowledge = false;

    @ViewChild('thread', {static: false}) thread: HTMLElement;

    // Navigations
    @ViewChild('knowledgeTab') knowledgeTabObj: TabComponent;

    // Upload
    @ViewChild('preloadupload') uploadObj?: UploaderComponent;

    // Files
    @ViewChild('targetView') targetView: ElementRef;

    // RTE
    @ViewChild('reply_text') replyText?: RichTextEditorComponent;

    // Dialogs
    @ViewChild('deleteKnowledgeDialog') deleteKnowledgeDialog: DialogComponent;

    @ViewChild('deleteUserDialog') deleteUserDialog: DialogComponent;

    @ViewChild('deleteDepartmentDialog') deleteDepartmentDialog: DialogComponent;

    @ViewChild('deleteCommentDialog') deleteCommentDialog: DialogComponent;

    @ViewChild('addUserDialog') addUserDialog: DialogComponent;

    @ViewChild('addDepartmentsDialog') addDepartmentsDialog: DialogComponent;

    // DropDowns
    @ViewChild('users') usersObj: MultiSelectComponent;

    @ViewChild('department') departmentObj: MultiSelectComponent;

    constructor(
        private readonly route: ActivatedRoute,
        private readonly authenticationService: AuthenticationService,
        private readonly knowledgeService: KnowledgeService,
        private readonly ref: ChangeDetectorRef,
        private readonly commentService: CommentsService,
        private readonly messages: MessageService,
        private readonly formBuilder: FormBuilder,
        private readonly router: Router,
        private readonly dataService: DataService,
        private readonly environmentService: EnvironmentService,
        private readonly lightbox: Lightbox,
    ) {
        this.contentPrinted = false;
        this.contentSearched = 0;
        this.currentUser = this.authenticationService.currentUserValue;
        this.path = {
            saveUrl: `${this.environmentService.backendURL}/api/upload/knowledge?type=knowledge`,
            removeUrl: '',
        };

        this.insertImageSettings = {
            path: `${this.environmentService.backendURL}/api/file/preview/knowledge/`,
            saveUrl: `${this.environmentService.backendURL}/api/upload/attachments/knowledge?type=attachments&subfolder=knowledge`,
        };
    }

    get fAddUser(): {[key: string]: AbstractControl} {
        return this.addUserForm.controls;
    }

    get fAddDepartment(): {[key: string]: AbstractControl} {
        return this.addDepartmentForm.controls;
    }

    private loadData(): void {
        this.imagesBasic = [];

        if (this.knowledge) {
            if (this.knowledge.files.length > 0) {
                this.knowledge.files.forEach((file: KnowledgeFile) => {
                    if (file.extension === 'image/jpeg' || file.extension === 'image/png') {
                        this.imagesBasic.push({
                            src: file.url,
                            thumb: file.url_thumbnail,
                            caption: file.name,
                        });
                    }
                });
            }

            this.loadingKnowledge = false;
            this.ref.detectChanges();
        }
    }

    @HostListener('window:beforeunload')
    canDeactivate(): Observable<boolean> | boolean {
        return !this.isDirty;
    }

    reloadData(): void {
        if (!this.knowledge?.id) {
            return;
        }

        this.dataService.clearKnowledgesCache();
        this.dataService.setKnowledgeDetailDataSource(this.knowledge.id);
        this.ref.detectChanges();
    }

    ngOnInit(): void {
        this.dataService.knowledgeDetailSource.pipe(untilDestroyed(this))
            .subscribe({
                next: (knowledge: Knowledge | undefined) => {
                    if (knowledge) {
                        this.knowledge = knowledge;
                        this.loadData();
                    }
                },
                error: error => {
                    console.error(error);
                    this.loadingKnowledge = false;
                    this.ref.markForCheck();
                },
            });

        this.dataService.userSource.pipe(untilDestroyed(this))
            .subscribe(
                (users: User[] | undefined) => {
                    this.usersSelect = [];

                    if (users) {
                        users.map((user: User) => {
                            if (
                                user.authorized &&
                                !user.deleted_date &&
                                user.id &&
                                !this.knowledge?.users.find(userData => userData.id === user.id)
                            ) {
                                this.usersSelect = [
                                    ...this.usersSelect,
                                    {value: user.id, label: user.fullname},
                                ];
                            }
                        });
                    }

                    this.ref.markForCheck();
                },
                error => {
                    console.error(error);
                },
            );

        this.dataService.departmentSource.pipe(untilDestroyed(this))
            .subscribe(
                (deparmtents: Department[] | undefined) => {
                    this.departmentSelect = [];

                    if (deparmtents) {
                        deparmtents.map((department: Department) => {
                            if (
                                !department.deleted_date &&
                                department.id &&
                                !this.knowledge?.departments.find(
                                    departmentData => departmentData.id === department.id,
                                )
                            ) {
                                this.departmentSelect = [
                                    ...this.departmentSelect,
                                    {
                                        value: department.id,
                                        label: department.company.name + ' - ' + department.name,
                                    },
                                ];
                            }
                        });
                    }

                    this.ref.markForCheck();
                },
                error => {
                    console.error(error);
                });

        this.createCommentForm = this.formBuilder.group({
            text: ['', Validators.required],
        });

        this.createFileForm = this.formBuilder.group({
            files: ['', Validators.required],
        });

        this.addUserForm = this.formBuilder.group({
            users: ['', Validators.required],
        });

        this.addDepartmentForm = this.formBuilder.group({
            departments: ['', Validators.required],
        });

        this.addDepartmentForm.valueChanges.pipe(untilDestroyed(this))
            .subscribe(() => {
                this.isDirty = this.addDepartmentForm.dirty;
                this.ref.markForCheck();
            });

        this.addUserForm.valueChanges.pipe(untilDestroyed(this))
            .subscribe(() => {
                this.isDirty = this.addUserForm.dirty;
                this.ref.markForCheck();
            });

        this.createFileForm.valueChanges.pipe(untilDestroyed(this))
            .subscribe(() => {
                this.isDirty = this.createFileForm.dirty;
                this.ref.markForCheck();
            });

        this.createCommentForm.valueChanges.pipe(untilDestroyed(this))
            .subscribe(() => {
                this.isDirty = this.createCommentForm.dirty;
                this.ref.markForCheck();
            });

        this.route.params.pipe(untilDestroyed(this))
            .subscribe(params => {
                this.dataService.setKnowledgeDetailDataSource(parseInt(params.id as string, 10));
            });

        this.dataService.setDepartmentDataSource();
    }

    onCreate(): void {
        // Nastavení GUI podle URL parametrů
        this.route.queryParamMap.pipe(untilDestroyed(this))
            .subscribe(queryParams => {
                if (queryParams.get('view') === 'maintask') {
                    this.knowledgeTabObj.selectedItem = 0;
                } else if (queryParams.get('view') === 'comments') {
                    this.knowledgeTabObj.selectedItem = 1;
                } else if (queryParams.get('view') === 'task') {
                    this.knowledgeTabObj.selectedItem = 2;
                } else if (queryParams.get('view') === 'files') {
                    this.knowledgeTabObj.selectedItem = 3;
                } else if (queryParams.get('view') === 'history') {
                    this.knowledgeTabObj.selectedItem = 4;
                } else {
                    this.knowledgeTabObj.selectedItem = 0;
                }
            });
    }

    preventSwipe(e: SelectEventArgs): void {
        if (e.isSwiped) {
            e.cancel = true;
        }
    }

    select(e: SelectEventArgs): void {
        let queryParams: Params = {view: null};

        if (e.selectedItem.textContent === 'přehled') {
            queryParams = {
                view: 'grid',
                form: null,
            };
        }

        if (e.selectedItem.textContent === 'kalendář') {
            queryParams = {
                view: 'calendar',
            };
        }

        if (e.selectedItem.textContent === 'statistiky') {
            queryParams = {
                view: 'stats',
                form: null,
            };
        }

        void this.router.navigate([], {
            relativeTo: this.route,
            queryParams,
            queryParamsHandling: 'merge',
        });
    }

    onFilteringUsers: EmitType<FilteringEventArgs> = (e: FilteringEventArgs) => {
        if (e.text === '') {
            e.updateData(this.usersSelect);
        } else {
            let query: Query = new Query();

            query = e.text !== '' ? query.where('label', 'contains', e.text, true, true) : query;
            e.updateData(this.usersSelect, query);
        }
    };

    onFilteringDepartments: EmitType<FilteringEventArgs> = (e: FilteringEventArgs) => {
        if (e.text === '') {
            e.updateData(this.departmentSelect);
        } else {
            let query = new Query();

            query = e.text !== '' ? query.where('label', 'contains', e.text, true) : query;
            e.updateData(this.departmentSelect, query);
        }
    };

    // FORMS SUBMITS
    addUsers(): void {
        this.submited = true;

        if (this.addUserForm.invalid) {
            console.error('form is not valid!');

            return;
        }

        this.isDirty = false;
        this.loadingKnowledge = true;

        const users = this.fAddUser.users.value;

        users.forEach((userId: number) => {
            if (!this.knowledge) {
                console.error('this.knowledge is missing...');

                return;
            }

            this.knowledgeService
                .setUsers(this.knowledge, userId)
                .pipe(untilDestroyed(this))
                .subscribe(
                    () => {
                        const body = 'Uživatel úspěšně přidán do pracovního postupu';
                        const options = {progressBar: true, timeOut: 5000};

                        this.messages.showSuccess('Zapojení uživatle', body, options);

                        if (!this.knowledge) {
                            console.error('this.knowledge is missing...');

                            return;
                        }

                        this.reloadData();
                        console.log('add');
                        this.usersSelect.forEach((item, index) => {
                            if (users.find(usersData => usersData === item.value)) {
                                this.usersSelect.splice(index, 1);
                            }
                        });
                        this.loadingKnowledge = false;
                        this.addUserDialog.hide();
                        this.addUserForm.reset();
                    },
                    error => {
                        console.error(error);

                        const body = 'Zkuste to znovu později';
                        const options = {progressBar: true, timeOut: 5000};

                        this.messages.showError(
                            'Chyba při vytváření zapojení uživatele do pracovního postupu',
                            body,
                            options,
                        );
                        this.loadingKnowledge = false;
                        this.ref.markForCheck();
                    },
                );
        });
    }

    addDepartments(): void {
        this.submited = true;

        if (this.addDepartmentForm.invalid) {
            console.error('form is not valid!');

            return;
        }

        this.isDirty = false;
        this.loadingKnowledge = true;

        const departments = this.fAddDepartment.departments.value;

        departments.forEach((departmentId: number) => {
            if (!this.knowledge) {
                console.error('this.knowledge is missing...');

                return;
            }

            this.knowledgeService
                .setDepartments(this.knowledge, departmentId)
                .pipe(untilDestroyed(this))
                .subscribe(
                    () => {
                        const body = 'Oddělení úspěšně přidáno do pracovního postupu';
                        const options = {progressBar: true, timeOut: 5000};

                        this.messages.showSuccess('Zapojení oddělení', body, options);
                        this.loadingKnowledge = false;
                        this.addDepartmentsDialog.hide();
                        this.ref.markForCheck();

                        if (!this.knowledge) {
                            console.error('this.knowledge is missing...');

                            return;
                        }

                        this.reloadData();
                        this.departmentSelect.forEach((item, index) => {
                            if (
                                departments.find(
                                    departmentDataData => departmentDataData === item.value,
                                )
                            ) {
                                this.departmentSelect.splice(index, 1);
                            }
                        });
                        this.addDepartmentsDialog.hide();
                        this.addDepartmentForm.reset();
                    },
                    error => {
                        console.error(error);

                        const body = 'Zkuste to znovu později';
                        const options = {progressBar: true, timeOut: 5000};

                        this.messages.showError(
                            'Chyba při vytváření zapojení oddělení do pracovního postupu',
                            body,
                            options,
                        );
                        this.loadingKnowledge = false;
                        this.ref.markForCheck();
                    },
                );
        });
    }

    removeUser(): void {
        if (!this.knowledge) {
            console.error('this.knowledge is missing...');

            return;
        }

        this.knowledgeService
            .deleteUserAssignation(this.highlightedUserRow, this.knowledge)
            .pipe(untilDestroyed(this))
            .subscribe(
                () => {
                    const body = 'zapojení pro uživatele odebráno z pracovního postupu';
                    const options = {progressBar: true, timeOut: 5000};

                    this.messages.showSuccess(
                        'Uživatel úspěšně odebrán z pracovního postupu',
                        body,
                        options,
                    );
                    this.loadingKnowledge = false;
                    this.deleteUserDialog.hide();
                    this.ref.markForCheck();

                    if (!this.knowledge) {
                        console.error('this.knowledge is missing...');

                        return;
                    }

                    this.reloadData();
                    this.usersSelect.push({
                        value: this.highlightedUserRow.id,
                        label: this.highlightedUserRow.fullname,
                    });
                    this.addUserDialog.hide();
                    this.addUserForm.reset();
                },
                error => {
                    console.error(error);

                    const body = 'Zkuste to znovu později ...';
                    const options = {progressBar: true, timeOut: 5000};

                    this.messages.showError(
                        'Chyba při odebrání uživatele z pracovního postupu',
                        body,
                        options,
                    );
                    this.loadingKnowledge = false;
                    this.ref.markForCheck();
                },
            );
    }

    removeDepartment(): void {
        if (!this.knowledge) {
            console.error('this.knowledge is missing...');

            return;
        }

        this.knowledgeService
            .deleteDepartmentAssignation(this.highlightedDepartmentRow, this.knowledge)
            .pipe(untilDestroyed(this))
            .subscribe(
                () => {
                    const body = 'zapojení pro oddělení odebráno z pracovního postupu';
                    const options = {progressBar: true, timeOut: 5000};

                    this.messages.showSuccess(
                        'Oddělení úspěšně odebráno z pracovního postupu',
                        body,
                        options,
                    );
                    this.loadingKnowledge = false;
                    this.deleteDepartmentDialog.hide();
                    this.ref.markForCheck();

                    if (!this.knowledge) {
                        console.error('this.knowledge is missing...');

                        return;
                    }

                    this.reloadData();
                    this.departmentSelect.push({
                        value: this.highlightedDepartmentRow.id,
                        label:
                            this.highlightedDepartmentRow.company.name +
                            ' - ' +
                            this.highlightedDepartmentRow.name,
                    });
                    this.addDepartmentsDialog.hide();
                    this.addDepartmentForm.reset();
                },
                error => {
                    console.error(error);

                    const body = 'Zkuste to znovu později ...';
                    const options = {
                        progressBar: true,
                        timeOut: 5000,
                        toastClass: 'red',
                    };

                    this.messages.showError(
                        'Chyba při odebrání oddělení z pracovního postupu',
                        body,
                        options,
                    );
                    this.loadingKnowledge = false;
                    this.ref.markForCheck();
                },
            );
    }

    removeKwbComment(): void {
        this.highlightedComment.deleted_by = this.currentUser?.id;
        this.commentService
            .deleteKwbComment(this.highlightedComment)
            ?.pipe(untilDestroyed(this))
            .subscribe(
                (data: KnowledgeComment) => {
                    const body = `komentář #${data.id}`;
                    const options = {progressBar: true, timeOut: 5000};

                    this.messages.showSuccess('Komentář úspěšně smazán', body, options);
                    this.loadingKnowledge = false;
                    this.deleteCommentDialog.hide();
                    this.ref.markForCheck();

                    if (!this.knowledge) {
                        console.error('this.knowledge is missing...');

                        return;
                    }

                    this.reloadData();
                },
                error => {
                    console.error(error);
                    this.loadingKnowledge = false;
                    this.ref.markForCheck();
                },
            );
    }

    editKnowledge(): void {
        this.isCopy = false;
        this.isUpdate = true;
        this.renderKnowledgeFormDialog = true;
        this.ref.markForCheck();
    }

    deleteKnowledge(): void {
        this.deleteKnowledgeDialog.show();
        this.ref.markForCheck();
    }

    removeKnowledge(): void {
        if (!this.knowledge) {
            console.error('this.knowledge is missing...');

            return;
        }

        this.knowledgeService
            .deleteKnowledge(this.knowledge)
            .pipe(untilDestroyed(this))
            .subscribe(
                (data: Knowledge) => {
                    const body = `Knowledge #${data.id}`;
                    const options = {progressBar: true, timeOut: 5000};

                    this.messages.showSuccess('Knowledge úspěšně smazán', body, options);
                    this.loadingKnowledge = false;
                    this.deleteKnowledgeDialog.hide();
                    this.ref.markForCheck();

                    if (!this.knowledge) {
                        console.error('this.knowledge is missing...');

                        return;
                    }

                    this.reloadData();
                    void this.router.navigate(['/knowledge/queue']);
                },
                error => {
                    console.error(error);
                    this.loadingKnowledge = false;
                    this.ref.markForCheck();
                },
            );
    }

    // MODALS
    editComment(): void {
        this.isCreateComment = false;
        this.isUpdateComment = true;
        this.isCopyComment = false;
        this.renderCommentFormDialog = true;
        this.ref.markForCheck();
    }

    // FILES
    open(index: number): void {
        this.lightbox.open(this.imagesBasic, index);
    }

    close(): void {
        this.lightbox.close();
    }

    showInputAddDepartmentsErrors(): void {
        this.addDepartmentForm.markAllAsTouched();
        this.ref.markForCheck();
    }

    showInputAddUserErrors(): void {
        this.addUserForm.markAllAsTouched();
        this.ref.markForCheck();
    }
}
