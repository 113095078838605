<ejs-dialog
  #formDialog
  (close)="
        isUpdate = false;
        isCreate = false;
        isDirty = false;
        isChecked = false;
        isCopy = false;
        submited = false;
        changeDialogState(false)
    "
  [isModal]="true"
  [showCloseIcon]="true"
  [visible]="isVisible"
  [width]="'85%'"
  id="formDialog"
>
  <form [formGroup]="buildingForm">
    <ng-template #content>
      <div class="dialogContent">
        <div class="row">
          <div class="col-12">
            <label
              [ngClass]="
                                f.name.valid
                                    ? 'text-success'
                                    : f.name.invalid
                                    ? 'text-danger'
                                    : 'text-muted'
                            "
              for="building-name"
            >* Název budovy:</label
            >
            <ejs-textbox
              [ngClass]="
                                f.name.invalid
                                    ? 'e-error'
                                    : f.name.valid
                                    ? 'e-success'
                                    : ''
                            "
              floatLabelType="Auto"
              formControlName="name"
              id="building-name"
            ></ejs-textbox>
            <small
              *ngIf="
                                f.name.touched &&
                                f.name.errors &&
                                f.name.errors.required
                            "
              class="text-danger"
            >Název budovy je povinný</small
            >
          </div>
        </div>
        <div class="row">
          <div class="col-12 mb-4">
            <label
              [ngClass]="
                                f.company_id.valid
                                    ? 'text-success'
                                    : f.company_id.invalid
                                    ? 'text-danger'
                                    : 'text-muted'
                            "
              for="company_id"
            >* Společnost:</label
            >
            <ejs-dropdownlist
              #company
              (filtering)="onFilteringCompanies($event)"
              [allowFiltering]="true"
              [cssClass]="
                                f.company_id.invalid
                                    ? 'e-error'
                                    : f.company_id.valid
                                    ? 'e-success'
                                    : ''
                            "
              [dataSource]="companySelect"
              [fields]="fields"
              [filterBarPlaceholder]="'Vyhledat společnost...'"
              [ignoreAccent]="true"
              [placeholder]="'Vyberte společnost'"
              [popupHeight]="height"
              formControlName="company_id"
              id="company_id"
              sortOrder="Ascending"
            ></ejs-dropdownlist>
            <small
              *ngIf="
                                f.company_id.touched &&
                                f.company_id.errors &&
                                f.company_id.errors.required
                            "
              class="text-danger"
            >Společnost je povinná</small
            >
          </div>
        </div>
        <div class="row">
          <div class="form-group col-12">
            <label
              [ngClass]="
                                f.offices.valid
                                    ? 'text-success'
                                    : f.offices.invalid
                                    ? 'text-danger'
                                    : 'text-muted'
                            "
              for="offices"
            >Kanceláře:</label
            >
            <ejs-multiselect
              #offices
              (filtering)="onFilteringOffices($event)"
              [allowFiltering]="true"
              [cssClass]="
                                (f.offices.dirty || f.offices.touched) &&
                                f.offices.invalid
                                    ? 'e-error'
                                    : (f.offices.dirty || f.offices.touched) &&
                                      f.offices.valid
                                    ? 'e-success'
                                    : ''
                            "
              [dataSource]="officesSelect"
              [fields]="fields"
              [filterBarPlaceholder]="'Vyhledat kancelář...'"
              [ignoreAccent]="true"
              [mode]="'CheckBox'"
              [placeholder]="'Vyberte kancelář'"
              [popupHeight]="height"
              [selectAllText]="'Všechny kanceláře'"
              formControlName="offices"
              id="offices"
              showSelectAll="true"
              sortOrder="Ascending"
            ></ejs-multiselect>
            <small
              *ngIf="
                                f.offices.touched &&
                                f.offices.errors &&
                                f.offices.errors.required
                            "
              class="text-danger"
            >Kanceláře jsou povinné</small
            >
          </div>
        </div>
        <div class="row">
          <div class="col-12 form-group">
            <app-address-validator
              (validAddress)="addressEventHandler($event)"
              [address]="
                                building && building.address_obj.whole_address
                                    ? building.address_obj.whole_address
                                    : ''
                            "
            ></app-address-validator>
          </div>
        </div>
        <div class="row">
          <div class="col-6 col-md-3 mb-3 form-group">
            <label
              [ngClass]="
                                f.oriental_number.valid &&
                                (f.oriental_number.dirty ||
                                    f.oriental_number.touched)
                                    ? 'text-success'
                                    : f.oriental_number.invalid &&
                                      (f.oriental_number.dirty ||
                                          f.oriental_number.touched)
                                    ? 'text-danger'
                                    : 'text-muted'
                            "
              for="oriental_number"
            >Číslo orientační:</label
            >
            <ejs-numerictextbox
              [format]="'###'"
              [ngClass]="
                                f.oriental_number.touched &&
                                f.oriental_number.invalid
                                    ? 'e-error'
                                    : f.oriental_number.touched &&
                                      f.oriental_number.valid
                                    ? 'e-success'
                                    : ''
                            "
              formControlName="oriental_number"
              id="oriental_number"
              min="0"
              step="1"
            ></ejs-numerictextbox>
            <small class="text-warning"
            >Tento údaj je doplňován automaticky</small
            >
            <small
              *ngIf="
                                f.oriental_number.touched &&
                                f.oriental_number.errors &&
                                f.oriental_number.errors.required
                            "
              class="text-danger"
            >číslo orientační je povinné</small
            >
          </div>
          <div class="col-6 col-md-3 mb-3 form-group">
            <label
              [ngClass]="
                                f.house_number.valid &&
                                (f.house_number.dirty || f.house_number.touched)
                                    ? 'text-success'
                                    : f.house_number.invalid &&
                                      (f.house_number.dirty ||
                                          f.house_number.touched)
                                    ? 'text-danger'
                                    : 'text-muted'
                            "
              for="house_number"
            >* Číslo popisné:</label
            >
            <ejs-numerictextbox
              [format]="'###'"
              [ngClass]="
                                f.house_number.touched && f.house_number.invalid
                                    ? 'e-error'
                                    : f.house_number.touched &&
                                      f.house_number.valid
                                    ? 'e-success'
                                    : ''
                            "
              formControlName="house_number"
              id="house_number"
              min="0"
              step="1"
            ></ejs-numerictextbox>
            <small class="text-warning"
            >Tento údaj je doplňován automaticky</small
            >
            <small
              *ngIf="
                                f.house_number.touched &&
                                f.house_number.errors &&
                                f.house_number.errors.required
                            "
              class="text-danger"
            >číslo popisné je povinné</small
            >
          </div>
          <div class="col-9 col-lg-3 mb-3 form-group">
            <label
              [ngClass]="
                                f.street.valid &&
                                (f.street.dirty || f.street.touched)
                                    ? 'text-success'
                                    : f.street.invalid &&
                                      (f.street.dirty || f.street.touched)
                                    ? 'text-danger'
                                    : 'text-muted'
                            "
              for="street"
            >Název ulice:</label
            >
            <ejs-textbox
              [ngClass]="
                                f.street.touched && f.street.invalid
                                    ? 'e-error'
                                    : f.street.touched && f.street.valid
                                    ? 'e-success'
                                    : ''
                            "
              floatLabelType="Auto"
              formControlName="street"
              id="street"
            ></ejs-textbox>
            <small class="text-warning"
            >Tento údaj je doplňován automaticky</small
            >
            <small
              *ngIf="
                                f.street.touched &&
                                f.street.errors &&
                                f.street.errors.required
                            "
              class="text-danger"
            >název ulice je povinný</small
            >
          </div>
          <div class="col-3 col-lg-2 mb-3 form-group">
            <label
              [ngClass]="
                                f.street_code.valid &&
                                (f.street_code.dirty || f.street_code.touched)
                                    ? 'text-success'
                                    : f.street_code.invalid &&
                                      (f.street_code.dirty ||
                                          f.street_code.touched)
                                    ? 'text-danger'
                                    : 'text-muted'
                            "
              for="street_code"
            >Kód ulice:</label
            >
            <ejs-numerictextbox
              [format]="'###'"
              [ngClass]="
                                f.street_code.touched && f.street_code.invalid
                                    ? 'e-error'
                                    : f.street_code.touched &&
                                      f.street_code.valid
                                    ? 'e-success'
                                    : ''
                            "
              formControlName="street_code"
              id="street_code"
              min="0"
              step="1"
            ></ejs-numerictextbox>
            <small class="text-warning"
            >Tento údaj je doplňován automaticky</small
            >
            <small
              *ngIf="
                                f.street_code.touched &&
                                f.street_code.errors &&
                                f.street_code.errors.required
                            "
              class="text-danger"
            >kód ulice je povinný</small
            >
          </div>
          <div class="col-6 col-lg-3 mb-3 form-group">
            <label
              [ngClass]="
                                f.city.valid && (f.city.dirty || f.city.touched)
                                    ? 'text-success'
                                    : f.city.invalid &&
                                      (f.city.dirty || f.city.touched)
                                    ? 'text-danger'
                                    : 'text-muted'
                            "
              for="city"
            >* Město:</label
            >
            <ejs-textbox
              [ngClass]="
                                f.city.touched && f.city.invalid
                                    ? 'e-error'
                                    : f.city.touched && f.city.valid
                                    ? 'e-success'
                                    : ''
                            "
              autocomplete="smartform-auto-city"
              floatLabelType="Auto"
              formControlName="city"
              id="city"
            ></ejs-textbox>
            <small class="text-warning"
            >Tento údaj je doplňován automaticky</small
            >
            <small
              *ngIf="
                                f.city.touched &&
                                f.city.errors &&
                                f.city.errors.required
                            "
              class="text-danger"
            >město je povinné</small
            >
          </div>
          <div class="col-3 col-lg-2 mb-3 form-group">
            <label
              [ngClass]="
                                f.zip.valid && (f.zip.dirty || f.zip.touched)
                                    ? 'text-success'
                                    : f.zip.invalid &&
                                      (f.zip.dirty || f.zip.touched)
                                    ? 'text-danger'
                                    : 'text-muted'
                            "
              for="zip"
            >* PSČ:</label
            >
            <ejs-numerictextbox
              [format]="'###'"
              [ngClass]="
                                f.zip.touched && f.zip.invalid
                                    ? 'e-error'
                                    : f.zip.touched && f.zip.valid
                                    ? 'e-success'
                                    : ''
                            "
              class="text-right"
              formControlName="zip"
              id="zip"
              min="0"
              step="1"
            ></ejs-numerictextbox>
            <small class="text-warning"
            >Tento údaj je doplňován automaticky</small
            >
            <small
              *ngIf="
                                f.zip.touched &&
                                f.zip.errors &&
                                f.zip.errors.required
                            "
              class="text-danger"
            >PSČ je povinný</small
            >
          </div>
          <div class="col-3 col-lg-2 mb-3 form-group">
            <label
              [ngClass]="
                                f.city_code.valid &&
                                (f.city_code.dirty || f.city_code.touched)
                                    ? 'text-success'
                                    : f.city_code.invalid &&
                                      (f.city_code.dirty || f.city_code.touched)
                                    ? 'text-danger'
                                    : 'text-muted'
                            "
              for="city_code"
            >Kód města:</label
            >
            <ejs-numerictextbox
              [format]="'###'"
              [ngClass]="
                                f.city_code.touched && f.city_code.invalid
                                    ? 'e-error'
                                    : f.city_code.touched && f.city_code.valid
                                    ? 'e-success'
                                    : ''
                            "
              formControlName="city_code"
              id="city_code"
              min="0"
              step="1"
            ></ejs-numerictextbox>
            <small class="text-warning"
            >Tento údaj je doplňován automaticky</small
            >
            <small
              *ngIf="
                                f.city_code.touched &&
                                f.city_code.errors &&
                                f.city_code.errors.required
                            "
              class="text-danger"
            >kód města je povinný</small
            >
          </div>
          <div class="col-9 col-lg-4 mb-3 form-group">
            <label
              [ngClass]="
                                f.city_part.valid &&
                                (f.city_part.dirty || f.city_part.touched)
                                    ? 'text-success'
                                    : f.city_part.invalid &&
                                      (f.city_part.dirty || f.city_part.touched)
                                    ? 'text-danger'
                                    : 'text-muted'
                            "
              for="city_part"
            >Městská část:</label
            >
            <ejs-textbox
              [ngClass]="
                                f.city_part.touched && f.city_part.invalid
                                    ? 'e-error'
                                    : f.city_part.touched && f.city_part.valid
                                    ? 'e-success'
                                    : ''
                            "
              floatLabelType="Auto"
              formControlName="city_part"
              id="city_part"
            ></ejs-textbox>
            <small class="text-warning"
            >Tento údaj je doplňován automaticky</small
            >
            <small
              *ngIf="
                                f.city_part.touched &&
                                f.city_part.errors &&
                                f.city_part.errors.required
                            "
              class="text-danger"
            >městská část je povinná</small
            >
          </div>
          <div class="col-3 col-lg-2 mb-3 form-group">
            <label
              [ngClass]="
                                f.city_part_code.valid &&
                                (f.city_part_code.dirty ||
                                    f.city_part_code.touched)
                                    ? 'text-success'
                                    : f.city_part_code.invalid &&
                                      (f.city_part_code.dirty ||
                                          f.city_part_code.touched)
                                    ? 'text-danger'
                                    : 'text-muted'
                            "
              for="city_part_code"
            >Kód městské části:</label
            >
            <ejs-numerictextbox
              [format]="'###'"
              [ngClass]="
                                f.city_part_code.touched &&
                                f.city_part_code.invalid
                                    ? 'e-error'
                                    : f.city_part_code.touched &&
                                      f.city_part_code.valid
                                    ? 'e-success'
                                    : ''
                            "
              class="text-right"
              formControlName="city_part_code"
              id="city_part_code"
              min="0"
              step="1"
            ></ejs-numerictextbox>
            <small class="text-warning"
            >Tento údaj je doplňován automaticky</small
            >
            <small
              *ngIf="
                                f.city_part_code.touched &&
                                f.city_part_code.errors &&
                                f.city_part_code.errors.required
                            "
              class="text-danger"
            >kód městské části je povinný</small
            >
          </div>
          <div class="col-9 col-lg-4 mb-3 form-group">
            <label
              [ngClass]="
                                f.district.valid &&
                                (f.district.dirty || f.district.touched)
                                    ? 'text-success'
                                    : f.district.invalid &&
                                      (f.district.dirty || f.district.touched)
                                    ? 'text-danger'
                                    : 'text-muted'
                            "
              for="district"
            >* Okres:</label
            >
            <ejs-textbox
              [ngClass]="
                                f.district.touched && f.district.invalid
                                    ? 'e-error'
                                    : f.district.touched && f.district.valid
                                    ? 'e-success'
                                    : ''
                            "
              floatLabelType="Auto"
              formControlName="district"
              id="district"
            ></ejs-textbox>
            <small class="text-warning"
            >Tento údaj je doplňován automaticky</small
            >
            <small
              *ngIf="
                                f.district.touched &&
                                f.district.errors &&
                                f.district.errors.required
                            "
              class="text-danger"
            >okres je povinný</small
            >
          </div>
          <div class="col-3 col-lg-2 mb-3 form-group">
            <label
              [ngClass]="
                                f.district_code.valid &&
                                (f.district_code.dirty ||
                                    f.district_code.touched)
                                    ? 'text-success'
                                    : f.district_code.invalid &&
                                      (f.district_code.dirty ||
                                          f.district_code.touched)
                                    ? 'text-danger'
                                    : 'text-muted'
                            "
              for="district_code"
            >Kód okresu:</label
            >
            <ejs-numerictextbox
              [format]="'###'"
              [ngClass]="
                                f.district_code.touched &&
                                f.district_code.invalid
                                    ? 'e-error'
                                    : f.district_code.touched &&
                                      f.district_code.valid
                                    ? 'e-success'
                                    : ''
                            "
              formControlName="district_code"
              id="district_code"
              min="0"
              step="1"
            ></ejs-numerictextbox>
            <small class="text-warning"
            >Tento údaj je doplňován automaticky</small
            >
            <small
              *ngIf="
                                f.district_code.touched &&
                                f.district_code.errors &&
                                f.district_code.errors.required
                            "
              class="text-danger"
            >kód okresu je povinný</small
            >
          </div>
          <div class="col-9 col-lg-4 mb-3 form-group">
            <label
              [ngClass]="
                                f.region.valid &&
                                (f.region.dirty || f.region.touched)
                                    ? 'text-success'
                                    : f.region.invalid &&
                                      (f.region.dirty || f.region.touched)
                                    ? 'text-danger'
                                    : 'text-muted'
                            "
              for="region"
            >* Kraj:</label
            >
            <ejs-textbox
              [ngClass]="
                                f.region.touched && f.region.invalid
                                    ? 'e-error'
                                    : f.region.touched && f.region.valid
                                    ? 'e-success'
                                    : ''
                            "
              floatLabelType="Auto"
              formControlName="region"
              id="region"
            ></ejs-textbox>
            <small class="text-warning"
            >Tento údaj je doplňován automaticky</small
            >
            <small
              *ngIf="
                                f.region.touched &&
                                f.region.errors &&
                                f.region.errors.required
                            "
              class="text-danger"
            >kraj je povinný</small
            >
          </div>
          <div class="col-3 col-lg-2 mb-3 form-group">
            <label
              [ngClass]="
                                f.region_code.valid &&
                                (f.region_code.dirty || f.region_code.touched)
                                    ? 'text-success'
                                    : f.region_code.invalid &&
                                      (f.region_code.dirty ||
                                          f.region_code.touched)
                                    ? 'text-danger'
                                    : 'text-muted'
                            "
              for="region_code"
            >Kód kraje:</label
            >
            <ejs-numerictextbox
              [format]="'###'"
              [ngClass]="
                                f.region_code.touched && f.region_code.invalid
                                    ? 'e-error'
                                    : f.region_code.touched &&
                                      f.region_code.valid
                                    ? 'e-success'
                                    : ''
                            "
              formControlName="region_code"
              id="region_code"
              min="0"
              step="1"
            ></ejs-numerictextbox>
            <small class="text-warning"
            >Tento údaj je doplňován automaticky</small
            >
            <small
              *ngIf="
                                f.region_code.touched &&
                                f.region_code.errors &&
                                f.region_code.errors.required
                            "
              class="text-danger"
            >kód kraje je povinný</small
            >
          </div>
          <div class="col-12 col-lg-6 mb-3 form-group">
            <label
              [ngClass]="
                                f.state.valid &&
                                (f.state.dirty || f.state.touched)
                                    ? 'text-success'
                                    : f.state.invalid &&
                                      (f.state.dirty || f.state.touched)
                                    ? 'text-danger'
                                    : 'text-muted'
                            "
              for="state"
            >* Stát:</label
            >
            <ejs-textbox
              [ngClass]="
                                f.state.touched && f.state.invalid
                                    ? 'e-error'
                                    : f.state.touched && f.state.valid
                                    ? 'e-success'
                                    : ''
                            "
              floatLabelType="Auto"
              formControlName="state"
              id="state"
            ></ejs-textbox>
            <small class="text-warning"
            >Tento údaj je doplňován automaticky</small
            >
            <small
              *ngIf="
                                f.state.touched &&
                                f.state.errors &&
                                f.state.errors.required
                            "
              class="text-danger"
            >stát je povinný</small
            >
          </div>
          <div class="col-12 col-md-3 mb-3 form-group">
            <label
              [ngClass]="
                                f.gps.valid && (f.gps.dirty || f.gps.touched)
                                    ? 'text-success'
                                    : f.gps.invalid &&
                                      (f.gps.dirty || f.gps.touched)
                                    ? 'text-danger'
                                    : 'text-muted'
                            "
              for="gps"
            >GPS souřadnice:</label
            >
            <ejs-textbox
              [ngClass]="
                                f.gps.touched && f.gps.invalid
                                    ? 'e-error'
                                    : f.gps.touched && f.gps.valid
                                    ? 'e-success'
                                    : ''
                            "
              floatLabelType="Auto"
              formControlName="gps"
              id="gps"
            ></ejs-textbox>
            <small class="text-warning"
            >Tento údaj je doplňován automaticky</small
            >
            <small
              *ngIf="
                                f.gps.touched &&
                                f.gps.errors &&
                                f.gps.errors.required
                            "
              class="text-danger"
            >GPS souřadnice jsou povinné</small
            >
          </div>
          <div class="col-12 col-md-3 mb-3 form-group">
            <label
              [ngClass]="
                                f.jstk.valid && (f.jstk.dirty || f.jstk.touched)
                                    ? 'text-success'
                                    : f.jstk.invalid &&
                                      (f.jstk.dirty || f.jstk.touched)
                                    ? 'text-danger'
                                    : 'text-muted'
                            "
              for="jstk"
            >JSTK souřadnice:</label
            >
            <ejs-textbox
              [ngClass]="
                                f.jstk.touched && f.jstk.invalid
                                    ? 'e-error'
                                    : f.jstk.touched && f.jstk.valid
                                    ? 'e-success'
                                    : ''
                            "
              floatLabelType="Auto"
              formControlName="jstk"
              id="jstk"
            ></ejs-textbox>
            <small class="text-warning"
            >Tento údaj je doplňován automaticky</small
            >
            <small
              *ngIf="
                                f.jstk.touched &&
                                f.jstk.errors &&
                                f.jstk.errors.required
                            "
              class="text-danger"
            >JSTK souřadnice jsou povinné</small
            >
          </div>
        </div>
      </div>
    </ng-template>
    <ng-template #footerTemplate>
      <div class="d-flex justify-content-end">
                <span
                  *ngIf="buildingForm.invalid"
                  class="e-badge e-badge-warning p-2 size_2"
                  role="alert"
                >
                    <strong>Formulář je špatně vyplněný</strong>
                </span>
        <span
          *ngIf="buildingForm.valid"
          class="e-badge e-badge-success p-2 size_2"
          role="alert"
        >
                    <strong>Formulář je správně vyplněný</strong>
                </span>
      </div>
      <div class="row">
        <div class="col-12 pt-2">
          <button
            (click)="onSubmit()"
            [disabled]="
                            loadingBuilding ||
                            buildingForm.invalid ||
                            !isChecked
                        "
            cssClass="e-btn e-success btn-p-zero e-small mr-1"
            ejs-button
            type="submit"
          >
                        <span
                          *ngIf="loadingBuilding === true"
                          aria-hidden="true"
                          class="spinner-grow spinner-grow-sm"
                          role="status"
                        ></span>
            <span *ngIf="loadingBuilding === false">ULOŽIT</span>
            <span *ngIf="loadingBuilding === true"
            >Provádím...</span
            >
          </button>
          <button
            (click)="formDialogObj.hide()"
            cssClass="e-btn e-danger e-outline btn-p-zero e-small"
            ejs-button
            type="button"
          >
            ZRUŠIT
          </button>
        </div>
      </div>
    </ng-template>
  </form>
</ejs-dialog>
