<ejs-dialog
  #formDialog
  (close)="
        isUpdate = false;
        isCreate = false;
        isDirty = false;
        isChecked = false;
        isCopy = false;
        submited = false;
        changeDialogState(false)
    "
  [isModal]="true"
  [showCloseIcon]="true"
  [visible]="isVisible"
  [width]="'85%'"
  id="formDialog"
>
  <form [formGroup]="tvPackageForm">
    <ng-template #content>
      <div class="dialogContent">
        <div class="row">
          <div class="col-12 mb-3">
            <label
              [ngClass]="
                                f.services.valid
                                    ? 'text-success'
                                    : f.services.invalid &&
                                      (f.services.dirty || f.services.touched)
                                    ? 'text-danger'
                                    : 'text-muted'
                            "
              for="services"
            >* Služba TV:</label
            >
            <ejs-multiselect
              #services
              (filtering)="onFilteringServices($event)"
              [allowFiltering]="true"
              [cssClass]="
                                (f.services.dirty || f.services.touched) &&
                                f.services.invalid
                                    ? 'e-error'
                                    : (f.services.dirty ||
                                          f.services.touched) &&
                                      f.services.valid
                                    ? 'e-success'
                                    : ''
                            "
              [dataSource]="serviceSelect"
              [fields]="fields"
              [filterBarPlaceholder]="'Vyhledat TV službu...'"
              [ignoreAccent]="true"
              [mode]="'CheckBox'"
              [placeholder]="'Vyberte TV službu'"
              [popupHeight]="height"
              [selectAllText]="'Všechny TV službu'"
              formControlName="services"
              id="services"
              showSelectAll="true"
              sortOrder="Ascending"
            ></ejs-multiselect>
            <small
              *ngIf="
                                (f.services.dirty || f.services.touched) &&
                                f.services.errors &&
                                f.services.errors.required
                            "
              class="text-danger"
            >Vyberte TV službu</small
            >
          </div>
          <div class="col-12 mb-3">
            <label
              [ngClass]="
                                f.name.valid && (f.name.dirty || f.name.touched)
                                    ? 'text-success'
                                    : f.name.invalid &&
                                      (f.name.dirty || f.name.touched)
                                    ? 'text-danger'
                                    : 'text-muted'
                            "
              for="name"
            >* Název TV balíčku:</label
            >
            <ejs-textbox
              [ngClass]="
                                (f.name.dirty || f.name.touched) &&
                                f.name.invalid
                                    ? 'e-error'
                                    : (f.name.dirty || f.name.touched) &&
                                      f.name.valid
                                    ? 'e-success'
                                    : ''
                            "
              floatLabelType="Auto"
              formControlName="name"
              id="name"
            ></ejs-textbox>
            <small
              *ngIf="
                                (f.name.dirty || f.name.touched) &&
                                f.name.errors &&
                                f.name.errors.required
                            "
              class="text-danger"
            >Název TV balíčku je povinný</small
            >
          </div>
          <div class="col-12 col-md-6 mb-3">
            <label
              [ngClass]="
                                f.price.valid &&
                                (f.price.dirty || f.price.touched)
                                    ? 'text-success'
                                    : f.price.invalid &&
                                      (f.price.dirty || f.price.touched)
                                    ? 'text-danger'
                                    : 'text-muted'
                            "
              for="price"
            >* Cena tarifu:</label
            >
            <ejs-numerictextbox
              [format]="'#,###.00\',- CZK\''"
              [ngClass]="
                                (f.price.dirty || f.price.touched) &&
                                f.price.invalid
                                    ? 'e-error'
                                    : (f.price.dirty || f.price.touched) &&
                                      f.price.valid
                                    ? 'e-success'
                                    : ''
                            "
              formControlName="price"
              id="price"
              step="0.01"
            ></ejs-numerictextbox>
            <small
              *ngIf="
                                (f.price.dirty || f.price.touched) &&
                                f.price.errors &&
                                f.price.errors.required
                            "
              class="text-danger"
            >Cena TV balíčku je povinná</small
            >
          </div>
          <div class="col-12 col-lg-4 mb-3">
            <label
              [ngClass]="
                                f.sale_order.valid &&
                                (f.sale_order.dirty || f.sale_order.touched)
                                    ? 'text-success'
                                    : f.sale_order.invalid &&
                                      (f.sale_order.dirty ||
                                          f.sale_order.touched)
                                    ? 'text-danger'
                                    : 'text-muted'
                            "
              for="sale_order"
            >* Pořadí v nabídce:</label
            >
            <ejs-numerictextbox
              [format]="'###'"
              [ngClass]="
                                (f.sale_order.dirty || f.sale_order.touched) &&
                                f.sale_order.invalid
                                    ? 'e-error'
                                    : (f.sale_order.dirty ||
                                          f.sale_order.touched) &&
                                      f.sale_order.valid
                                    ? 'e-success'
                                    : ''
                            "
              formControlName="sale_order"
              id="sale_order"
              step="1"
            ></ejs-numerictextbox>
            <small
              *ngIf="
                                (f.sale_order.dirty || f.sale_order.touched) &&
                                f.sale_order.errors &&
                                f.sale_order.errors.required
                            "
              class="text-danger"
            >Pořadí v nabídce je povinné</small
            >
          </div>
          <div class="col-12 col-md-6 col-lg-4 mb-3">
            <ejs-checkbox
              [checked]="false"
              [ngClass]="
                                (f.recommended.dirty ||
                                    f.recommended.touched) &&
                                f.recommended.invalid
                                    ? 'e-error'
                                    : (f.recommended.dirty ||
                                          f.recommended.touched) &&
                                      f.recommended.valid
                                    ? 'e-success'
                                    : ''
                            "
              cssClass="e-primary p-0 mr-3"
              formControlName="recommended"
              label="Doporučeno"
            >
            </ejs-checkbox>
            <small
              *ngIf="
                                (f.recommended.dirty ||
                                    f.recommended.touched) &&
                                f.recommended.errors &&
                                f.recommended.errors.required
                            "
              class="text-danger"
            >Vlastnost doporučeno je povinná</small
            >
          </div>
          <div class="col-12 col-md-6 col-lg-4 mb-3">
            <ejs-checkbox
              [checked]="false"
              [ngClass]="
                                (f.extension.dirty || f.extension.touched) &&
                                f.extension.invalid
                                    ? 'e-error'
                                    : (f.extension.dirty ||
                                          f.extension.touched) &&
                                      f.extension.valid
                                    ? 'e-success'
                                    : ''
                            "
              cssClass="e-primary p-0 mr-3"
              formControlName="extension"
              label="Rozšiřující tarif"
            >
            </ejs-checkbox>
            <small
              *ngIf="
                                (f.extension.dirty || f.extension.touched) &&
                                f.extension.errors &&
                                f.extension.errors.required
                            "
              class="text-danger"
            >Vlastnost rozšíření je povinná</small
            >
          </div>
          <div class="col-12 mb-3">
            <label
              [ngClass]="
                                f.channels.valid
                                    ? 'text-success'
                                    : f.channels.invalid &&
                                      (f.channels.dirty || f.channels.touched)
                                    ? 'text-danger'
                                    : 'text-muted'
                            "
              for="channels"
            >* Programy:</label
            >
            <ejs-multiselect
              #channels
              (filtering)="onFilteringChannels($event)"
              [allowFiltering]="true"
              [cssClass]="
                                (f.channels.dirty || f.channels.touched) &&
                                f.channels.invalid
                                    ? 'e-error'
                                    : (f.channels.dirty ||
                                          f.channels.touched) &&
                                      f.channels.valid
                                    ? 'e-success'
                                    : ''
                            "
              [dataSource]="channelSelect"
              [fields]="fields"
              [filterBarPlaceholder]="'Vyhledat TV kanály...'"
              [ignoreAccent]="true"
              [mode]="'CheckBox'"
              [placeholder]="'Vyberte TV kanály'"
              [popupHeight]="height"
              [selectAllText]="'Všechny TV kanály'"
              formControlName="channels"
              id="channels"
              showSelectAll="true"
              sortOrder="Ascending"
            ></ejs-multiselect>
            <small
              *ngIf="
                                (f.channels.dirty || f.channels.touched) &&
                                f.channels.errors &&
                                f.channels.errors.required
                            "
              class="text-danger"
            >Vyberte alespoň jeden TV kanál</small
            >
          </div>
        </div>
      </div>
    </ng-template>
    <ng-template #footerTemplate>
      <div class="d-flex justify-content-end">
                <span
                  *ngIf="tvPackageForm.invalid"
                  class="e-badge e-badge-warning p-2 size_2"
                  role="alert"
                >
                    <strong>Formulář je špatně vyplněný</strong>
                </span>
        <span
          *ngIf="tvPackageForm.valid"
          class="e-badge e-badge-success p-2 size_2"
          role="alert"
        >
                    <strong>Formulář je správně vyplněný</strong>
                </span>
      </div>
      <div class="row">
        <div class="col-12 pt-2">
          <button
            (click)="onSubmit()"
            [disabled]="
                            loadingTvPackages ||
                            tvPackageForm.invalid ||
                            !isChecked
                        "
            cssClass="e-btn e-success btn-p-zero e-small mr-1"
            ejs-button
            type="submit"
          >
                        <span
                          *ngIf="loadingTvPackages === true"
                          aria-hidden="true"
                          class="spinner-grow spinner-grow-sm"
                          role="status"
                        ></span>
            <span *ngIf="loadingTvPackages === false">ULOŽIT</span>
            <span *ngIf="loadingTvPackages === true"
            >Provádím...</span
            >
          </button>
          <button
            (click)="formDialogObj.hide()"
            cssClass="e-btn e-danger e-outline btn-p-zero e-small"
            ejs-button
            type="button"
          >
            ZRUŠIT
          </button>
        </div>
      </div>
    </ng-template>
  </form>
</ejs-dialog>
