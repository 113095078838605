import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    HostListener,
    OnInit,
    ViewChild,
} from '@angular/core';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {AuthenticationService} from '@src/app/_services/authentication.service';
import {Observable} from 'rxjs';
import {Hotliner} from '@src/app/_models/hotline/hotliner';
import {DataService} from '@src/app/_services/data.service';
import {registerLocaleData} from '@angular/common';
import {Hotline} from '@src/app/_models/hotline/hotline';

import moment from 'moment';
import localeCs from '@angular/common/locales/cs';
import {TabComponent, ToolbarComponent} from '@syncfusion/ej2-angular-navigations';
import {SelectEventArgs} from '@syncfusion/ej2-navigations';
import {User} from '@src/app/_models/user/user';
import {AccumulationChart, IAccTooltipRenderEventArgs} from '@syncfusion/ej2-charts';
import {NavHandlerService} from '@src/app/_services/nav-handler.service';
import {MessageService} from '@src/app/_services/message.service';
import {PermissionService} from '@src/app/_services/permission.service';
import {ComponentCanDeactivate} from '@src/app/_guards/changes.guard';
import {ExcelService} from '@src/app/_services/excel.service';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {DashboardLayoutComponent} from '@syncfusion/ej2-angular-layouts';
import convert from 'xml-js';
import {Address} from '@src/app/_models/services/address';
import {HotlineService} from '@src/app/features/hotline/hotline.service';

moment.updateLocale('cs', {workingWeekdays: [1, 2, 3, 4, 5]});
registerLocaleData(localeCs);

interface XMLIFace {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    Subject: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    Location: Address;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    Description: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    'Start Date': string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    'Start Time': string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    'End Date': string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    'End Time': string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    Private: boolean;
}

@UntilDestroy()
@Component({
    selector: 'app-hotline',
    templateUrl: './hotline.component.html',
    styleUrls: ['./hotline.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HotlineComponent implements OnInit, ComponentCanDeactivate {
    // DashBoard
    count = 8;

    cellSpacing: number[] = [10, 10];

    cellAspectRatio: number = 100 / 100;

    // Charts

    pieData: object[];

    startAngle: number;

    endAngle: number;

    center: object;

    explode: boolean;

    enableAnimation: boolean;

    enableSmartLabels: boolean;

    title: string;

    radius: string;

    legendSettings: object;

    tooltip: object;

    datalabel: object;

    // Navigations
    headerText = [
        {text: 'tabulka'},
        {text: 'hot-lineři'},
        {text: 'kalendář'},
        {text: 'statistiky'},
        {text: 'import'},
    ];

    // Variables
    currentUser: User | null;

    isDirty = false;

    hotliners?: Hotliner[] = [];

    hotlines?: Hotline[] = [];

    hotlinesResults: Hotline[] = [];

    // Loaders
    loadingHotliner = true;

    // Maintoolbar
    @ViewChild('moduleToolbar') moduleToolbarObj?: ToolbarComponent;

    // DashBoard
    @ViewChild('default_dashboard') dashboard?: DashboardLayoutComponent;

    // Charts
    @ViewChild('pieChart', {static: false}) piechart?: AccumulationChart;

    // Navigations
    @ViewChild('hotlineTab') hotlineTabObj?: TabComponent;

    constructor(
        private readonly router: Router,
        private readonly ref: ChangeDetectorRef,
        private readonly route: ActivatedRoute,
        private readonly navHandlerService: NavHandlerService,
        private readonly dataService: DataService,
        private readonly permissionService: PermissionService,
        private readonly hotlineService: HotlineService,
        private readonly excelService: ExcelService,
        private readonly messages: MessageService,
        private readonly authenticationService: AuthenticationService,
    ) {
        this.currentUser = this.authenticationService.currentUserValue;
    }

    private loadData(start?: string, end?: string): void {
        this.pieData = [];
        this.hotliners = this.hotliners?.sort((a, b) => a.score > b.score ? -1 : 1);

        this.hotliners?.map((hotliner: Hotliner) => {
            if (!hotliner.deleted_date) {
                if (start && end) {
                    let scoreByAge = 0;

                    hotliner.hotlines.forEach((hotline: Hotline) => {
                        const startHotline = moment(hotline.start_at)
                            .format('YYYY-MM-DD');
                        const endHotline = moment(hotline.end_at)
                            .format('YYYY-MM-DD');
                        const curr = moment()
                            .format('YYYY-MM-DD');

                        if (
                            !hotline.deleted_date &&
                            end < curr &&
                            startHotline >= start &&
                            endHotline <= end
                        ) {
                            scoreByAge = scoreByAge + hotline.score;
                        }
                    });
                    hotliner.score = scoreByAge;
                    this.pieData.push({
                        x: hotliner.user.fullname,
                        y: hotliner.score,
                        text: `${hotliner.user.fullname} získal bodů: ${hotliner.score}`,
                    });
                } else {
                    this.pieData.push({
                        x: hotliner.user.fullname,
                        y: hotliner.score,
                        text: `${hotliner.user.fullname} získal bodů: ${hotliner.score}`,
                    });
                }
            }
        });
        this.loadingHotliner = false;
        this.ref.markForCheck();
    }

    @HostListener('window:beforeunload')
    canDeactivate(): Observable<boolean> | boolean {
        return !this.isDirty;
    }

    ngOnInit(): void {
        this.pieData = [];
        this.legendSettings = {
            shapeHeight: 20,
            shapeWidth: 20,
            visible: true,
        };
        this.startAngle = 0;
        this.endAngle = 360;
        this.radius = 'r';
        this.enableAnimation = true;
        this.enableSmartLabels = true;
        this.title = 'Graf bodů za služby hot-line';
        this.tooltip = {
            enable: true,
            header: 'Celkem získaných bodů',
        };
        this.datalabel = {visible: true};

        this.navHandlerService.navState.pipe(untilDestroyed(this))
            .subscribe({
                next: () => {
                    this.refreshContent();
                },
                error: error => {
                    console.error(error);
                },
            });

        this.dataService.hotlinersSource.pipe(untilDestroyed(this))
            .subscribe({
                next: (hotliners: Hotliner[] | undefined) => {
                    this.loadingHotliner = true;
                    this.hotliners = hotliners;

                    if (this.hotliners && this.hotliners.length > 0) {
                        this.loadData();
                    }
                },
                error: error => {
                    console.error(error);
                    this.loadingHotliner = false;
                    this.ref.markForCheck();
                },
            });

        this.hotlineService.currentDataResult.pipe(untilDestroyed(this))
            .subscribe({
                next: (results: Hotline[]) => {
                    this.hotlinesResults = results;
                },
                error: error => {
                    console.error(error);
                },
            });

        this.dataService.hotlineSource.pipe(untilDestroyed(this))
            .subscribe({
                next: (hotlines: Hotline[] | undefined) => {
                    this.hotlines = hotlines;
                },
                error: error => {
                    console.error(error);
                },
            });

        this.dataService.setHotlinesDataSource();
        this.dataService.setHotlinersDataSource();
    }

    refreshContent(): void {
        setTimeout(() => {
            if (this.piechart) {
                this.piechart.refresh();
            }

            if (this.dashboard) {
                this.dashboard.refresh();
            }

            if (this.moduleToolbarObj) {
                this.moduleToolbarObj.refresh();
            }
        }, 1000);
    }

    onCloseIconHandler(event: MouseEvent): void {
        if (event.target === null) {
            return;
        }

        const elementOffset = (event.target as HTMLElement).offsetParent;

        if (elementOffset) {
            this.dashboard?.removePanel(elementOffset.id);
        }
    }

    tooltipRender(args: IAccTooltipRenderEventArgs): void {
        args.text = `${args.point.x as string} : ${args.point.y} bodů`;
    }

    onCreate(): void {
        // Nastavení GUI podle URL parametrů
        this.route.queryParamMap.pipe(untilDestroyed(this))
            .subscribe(queryParams => {
                if (this.hotlineTabObj) {
                    if (queryParams.get('view') === 'grid') {
                        this.hotlineTabObj.select(0);
                    } else if (queryParams.get('view') === 'users') {
                        this.hotlineTabObj.select(1);
                    } else if (queryParams.get('view') === 'calendar') {
                        this.hotlineTabObj.select(2);
                    } else if (queryParams.get('view') === 'stats') {
                        this.hotlineTabObj.select(3);
                    } else if (queryParams.get('view') === 'import') {
                        this.hotlineTabObj.select(4);
                    } else {
                        this.hotlineTabObj.select(0);
                    }
                }
            });
    }

    preventSwipe(e: SelectEventArgs): void {
        if (e.isSwiped) {
            e.cancel = true;
        }
    }

    navigateToTab(view: string, form: string): void {
        const queryParams = {view, form};

        if (this.hotlineTabObj) {
            if (view === 'grid') {
                this.hotlineTabObj.select(0);
            } else if (view === 'users') {
                this.hotlineTabObj.select(1);
            } else if (view === 'calendar') {
                this.hotlineTabObj.select(2);
            } else if (view === 'stats') {
                this.hotlineTabObj.select(3);
            } else if (view === 'import') {
                this.hotlineTabObj.select(4);
            } else {
                this.hotlineTabObj.select(0);
            }
        }

        void this.router.navigate([], {
            relativeTo: this.route,
            queryParams,
            queryParamsHandling: 'merge',
        });
    }

    select(e: SelectEventArgs): void {
        let queryParams: Params = {view: null};

        this.route.queryParamMap.pipe(untilDestroyed(this))
            .subscribe(params => {
                const form =
                    params.get('form') === 'addHotline'
                        ? 'addHotline'
                        : params.get('form') === 'addHotlineReport'
                            ? 'addHotlineReport'
                            : null;

                if (e.selectedItem.textContent === 'tabulka') {
                    queryParams = {
                        view: 'grid',
                        form,
                    };
                }

                if (e.selectedItem.textContent === 'hot-lineři') {
                    queryParams = {
                        view: 'users',
                        form,
                    };
                }

                if (e.selectedItem.textContent === 'kalendář') {
                    queryParams = {
                        view: 'calendar',
                        form,
                    };
                }

                if (e.selectedItem.textContent === 'statistiky') {
                    queryParams = {
                        view: 'stats',
                        form,
                    };
                }

                if (e.selectedItem.textContent === 'import') {
                    queryParams = {
                        view: 'import',
                        form,
                    };
                }

                void this.router.navigate([], {
                    relativeTo: this.route,
                    queryParams,
                    queryParamsHandling: 'merge',
                });
            });
    }

    print(): void {
        this.piechart?.print();
    }

    export(): void {
        this.piechart?.exportModule.export('PNG', 'hotline-score_chart_export');
    }

    getCalendarXMLExport(): void {
        const data: XMLIFace[] = [];

        this.hotlines?.forEach((hotline: Hotline) => {
            if (!hotline.deleted_date) {
                const typeTrnslt = hotline.type === 'technician' ? 'služba' : 'příslužba';

                data.push({
                    Subject: `${hotline.company.name} HOTLINE ${typeTrnslt}`,
                    Location: hotline.company.address_obj,
                    Description: `${hotline.hotliner.user.fullname} - Přidělena ${typeTrnslt} hot-line od 8:00 do 22:00`,
                    'Start Date': moment(hotline.start_at)
                        .format('MM/DD/YYYY'),
                    'Start Time': moment(hotline.start_at)
                        .format('HH:mm'),
                    'End Date': moment(hotline.end_at)
                        .format('MM/DD/YYYY'),
                    'End Time': moment(hotline.end_at)
                        .format('HH:mm'),
                    Private: true,
                });
            }
        });

        const options = {compact: true, ignoreComment: true, spaces: 4};
        const result = convert.json2xml(JSON.stringify(data), options);
        const element = document.createElement('a');
        const blob = new Blob([result], {
            type: 'text/xml',
        });

        element.href = URL.createObjectURL(blob);
        element.setAttribute('download', 'js_hotline_calendar.xml');
        document.body.appendChild(element);
        element.click();
    }

    getCalendarCSVExport(): void {
        const data: XMLIFace[] = [];

        this.hotlines?.forEach((hotline: Hotline) => {
            if (!hotline.deleted_date) {
                const typeTrnslt = hotline.type === 'technician' ? 'služba' : 'příslužba';

                data.push({
                    Subject: `${hotline.company.name} HOTLINE ${typeTrnslt}`,
                    Location: hotline.company.address_obj,
                    Description: `${hotline.hotliner.user.fullname} - Přidělena ${typeTrnslt} hot-line od 8:00 do 22:00`,
                    'Start Date': moment(hotline.start_at)
                        .format('MM/DD/YYYY'),
                    'Start Time': moment(hotline.start_at)
                        .format('HH:mm'),
                    'End Date': moment(hotline.end_at)
                        .format('MM/DD/YYYY'),
                    'End Time': moment(hotline.end_at)
                        .format('HH:mm'),
                    Private: true,
                });
            }
        });
        this.excelService.exportAsCsvFile(data, 'js_hotline_calendar');
    }

    getCalendarICALExport(): void {
        /* EMPTY */
    }
}
