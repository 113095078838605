import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Event} from '@src/app/_models/event/event';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {MessageService} from '@src/app/_services/message.service';
import {EventAssignation} from '@src/app/_models/assignation/event_assignation';
import {DataService} from '@src/app/_services/data.service';
import {EnvironmentService} from '@src/app/_services/environment.service';

const httpOptions = {
    headers: new HttpHeaders({'Content-Type': 'application/json'}),
};

export interface EventInput {
    id?: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    start_at?: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    end_at?: string;
    subject?: string;
    address?: string;
    required?: boolean;
    users?: number[];
    departments?: number[];
    description?: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    deleted_by?: number | null;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    created_by?: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    updated_by?: number | null;
}

@Injectable({
    providedIn: 'root',
})
export class EventsService {
    private readonly eventUrl: string;

    private readonly eventAssignationUrl: string;

    events: Observable<Event[]>;

    event: Observable<Event>;

    constructor(
        private readonly http: HttpClient,
        private readonly dataService: DataService,
        private readonly messageService: MessageService,
        private readonly environmentService: EnvironmentService,
    ) {
        this.eventUrl = this.environmentService.backendURL + '/api/event';
        this.eventAssignationUrl = this.environmentService.backendURL + '/api/event/membership';
    }

    addEvent(event: unknown): Observable<Event> {
        return this.http.post<Event>(this.eventUrl, event, httpOptions);
    }

    deleteEvent(event: Event): Observable<Event> {
        const url = `${this.eventUrl}/${event.id}`;
        const params = new HttpParams().set(
            'deleted_by',
            event.deleted_by ? event.deleted_by.toString() : 'null',
        );
        const headers = new HttpHeaders({'Content-Type': 'application/json'});

        return this.http.delete<Event>(url, {headers, params});
    }

    deleteEventAssignation(assignation: EventAssignation | number): Observable<EventAssignation> {
        const id = typeof assignation === 'number' ? assignation : assignation.id;
        const url = `${this.eventAssignationUrl}/${id}`;

        return this.http.delete<EventAssignation>(url, httpOptions);
    }

    updateEvent(event: Event): Observable<Event> {
        const url = `${this.eventUrl}/${event.id}`;

        return this.http.put<Event>(url, event, httpOptions);
    }
}
