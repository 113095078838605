import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    HostListener,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import {DialogComponent} from '@syncfusion/ej2-angular-popups';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {User} from '@src/app/_models/user/user';
import {DataService} from '@src/app/_services/data.service';
import {DataNetbaseService} from '@src/app/_services/data-netbase.service';
import {
    ExcludeAddressInput,
    ServicesService,
    SmartAddress,
} from '@src/app/features/services/services.service';
import {MessageService} from '@src/app/_services/message.service';
import {AuthenticationService} from '@src/app/_services/authentication.service';
import {Observable, Subject} from 'rxjs';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {ComponentCanDeactivate} from '@src/app/_guards/changes.guard';
import {AddressExcluded} from '@src/app/_models/services/address-excluded';
import {FilteringEventArgs, MultiSelectComponent} from '@syncfusion/ej2-angular-dropdowns';
import {Company} from '@src/app/_models/company/company';
import {NetPackages} from '@src/app/_models/services/net-packages';
import {TvServices} from '@src/app/_models/services/tv-services';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {EmitType} from '@syncfusion/ej2-base';
import {Query} from '@syncfusion/ej2-data';
import {PermissionService} from '@src/app/_services/permission.service';

declare let smartform;
declare let window: {routerSubject: Subject<string>};
declare let document;

@UntilDestroy()
@Component({
    selector: 'app-address-exclude-form',
    templateUrl: './address-exclude-form.component.html',
    styleUrls: ['./address-exclude-form.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddressExcludeFormComponent
    implements OnInit, OnChanges, OnDestroy, ComponentCanDeactivate {
    // Dropdowns
    addrFieldObj: object = {text: 'label', value: 'value'};

    height = '240px';

    categorySelect: Array<{value: boolean | number | string; label: string}> = [];

    departmentSelect: Array<{value: boolean | number | string; label: string}> = [];

    usersSelect: Array<{value: boolean | number | string; label: string}> = [];

    companySelect: Array<{value: boolean | number | string; label: string}> = [];

    servicesNetSelect: Array<{value: boolean | number | string; label: string}> = [];

    servicesTvSelect: Array<{value: boolean | number | string; label: string}> = [];

    // Forms
    addressExcludedForm: FormGroup;

    isDirty = false;

    isChecked = false;

    submited = false;

    // Variables
    currentUser: User | null;

    exAddresses: AddressExcluded[] = [];

    exAddress: AddressExcluded | null | undefined = null;

    smartFormData: SmartAddress | null = null;

    smartWholeAddress: string | null = null;

    routerSubject: Subject<string> = new Subject<string>();

    copyAddressFromMapy = false;

    // Loaders
    loadingAddress = false;

    @Input() address: AddressExcluded | null = null;

    @Input() isCreate = false;

    @Input() isUpdate = false;

    @Input() isVisible = false;

    @Input() isCopy = false;

    @Output() readonly addressExcludedFormDialogState = new EventEmitter<boolean>();

    // Dialogs
    @ViewChild('formDialog') formDialogObj: DialogComponent;

    // Dropdowns
    @ViewChild('net_packages') netPackagesObj: MultiSelectComponent;

    @ViewChild('tv_services') tvServicesObj: MultiSelectComponent;

    constructor(
        private readonly router: Router,
        private readonly route: ActivatedRoute,
        private readonly formBuilder: FormBuilder,
        private readonly ref: ChangeDetectorRef,
        private readonly dataService: DataService,
        private readonly dataNetbaseService: DataNetbaseService,
        private readonly servicesService: ServicesService,
        private readonly permissionService: PermissionService,
        private readonly messages: MessageService,
        private readonly authenticationService: AuthenticationService,
    ) {
        this.currentUser = this.authenticationService.currentUserValue;
        window.routerSubject = this.routerSubject;
    }

    get f(): {[key: string]: AbstractControl} {
        return this.addressExcludedForm.controls;
    }

    // eslint-disable-next-line max-params-no-constructor/max-params-no-constructor
    private checkExcludeUnique(
        ruian?,
        streetCode?,
        partCode?,
        cityCode?,
        districtCode?,
        regionCode?,
        state?,
    ): AddressExcluded | undefined {
        // TODO: Json modules refactor
        if (this.exAddresses.length > 0) {
            if (ruian) {
                return this.exAddresses.find(addr => addr.ruian === ruian);
            } else if (streetCode) {
                return this.exAddresses.find(addr => addr.street_code === streetCode);
            } else if (partCode) {
                return this.exAddresses.find(addr => addr.city_part_code === partCode);
            } else if (cityCode) {
                return this.exAddresses.find(addr => addr.city_code === cityCode);
            } else if (districtCode) {
                return this.exAddresses.find(addr => addr.district_code === districtCode);
            } else if (regionCode) {
                return this.exAddresses.find(addr => addr.region_code === regionCode);
            } else if (state) {
                return this.exAddresses.find(addr => addr.state === state);
            }
        }
    }

    @HostListener('window:beforeunload')
    canDeactivate(): Observable<boolean> | boolean {
        return !this.isDirty;
    }

    reinitSmartForm(): void {
        this.exAddress = null;
        this.ref.markForCheck();
        console.info('Reinitialize SF City objects...');
        smartform.rebindAllForms(false, () => {
            if (smartform.getInstance('smartform-instance-exclude-address')) {
                const instanceExcludeAddress = smartform.getInstance(
                    'smartform-instance-exclude-address',
                );

                instanceExcludeAddress.setZIndex(100000000);
                instanceExcludeAddress.setSelectionBackgroundColor('#4285f3');
                instanceExcludeAddress.addAnnounceCallback((validationType, addressArray) => {
                    if (validationType === smartform.ValidationResultType.HIT) {
                        const addressData: SmartAddress = {};

                        this.smartWholeAddress = addressArray[0].ADDRESS_WHOLE;
                        addressData.oriental_number = parseInt(
                            addressArray[0].NUMBER_ORIENTACNI as string,
                            10,
                        );

                        addressData.house_number = parseInt(
                            addressArray[0].NUMBER_POPISNE as string,
                            10,
                        );
                        addressData.ruian = parseInt(addressArray[0].CODE as string, 10);
                        addressData.street = addressArray[0].STREET;
                        addressData.street_code = parseInt(
                            addressArray[0].STREET_CODE as string,
                            10,
                        );
                        addressData.city_part = addressArray[0].PART;
                        addressData.city_part_code = parseInt(
                            addressArray[0].PART_CODE as string,
                            10,
                        );
                        addressData.city = addressArray[0].CITY;
                        addressData.city_code = parseInt(addressArray[0].CITY_CODE as string, 10);
                        addressData.zip = parseInt(addressArray[0].ZIP as string, 10);
                        addressData.district = addressArray[0].DISTRICT;
                        addressData.district_code = parseInt(
                            addressArray[0].DISTRICT_CODE as string,
                            10,
                        );
                        addressData.region = addressArray[0].REGION;
                        addressData.region_code = parseInt(
                            addressArray[0].REGION_CODE as string,
                            10,
                        );
                        addressData.state = addressArray[0].COUNTRY;
                        addressData.gps = `${addressArray[0].GPS_LAT as string} / ${
                            addressArray[0].GPS_LONG as string
                        }`;

                        addressData.jstk = `${addressArray[0].COORD_X as string} / ${
                            addressArray[0].COORD_Y as string
                        }`;
                        this.addressValidator(addressData);
                    }

                    this.ref.markForCheck();
                });
            }
        });
    }

    ngOnInit(): void {
        this.dataService.companySource.pipe(untilDestroyed(this))
            .subscribe({
                next: (companies: Company[]) => {
                    this.companySelect = [];
                    companies.map((company: Company) => {
                        if (!company.deleted_date) {
                            this.companySelect = [
                                ...this.companySelect,
                                {
                                    value: company.id,
                                    label: company.name,
                                },
                            ];
                        }
                    });
                    this.ref.markForCheck();
                },
                error: error => {
                    console.error(error);
                },
            });

        this.dataService.userSource.pipe(untilDestroyed(this))
            .subscribe({
                next: (data: User[]) => {
                    data.map((user: User) => {
                        if (user.authorized && !user.deleted_date) {
                            this.usersSelect = [
                                ...this.usersSelect,
                                {
                                    value: user.id,
                                    label: `${user.firstname} ${user.secondname}`,
                                },
                            ];
                        }
                    });
                },
                error: error => {
                    console.error(error);
                },
            });

        this.dataNetbaseService.netPackagesSource.pipe(untilDestroyed(this))
            .subscribe({
                next: (netTarifs: NetPackages[]) => {
                    this.servicesNetSelect = [];
                    netTarifs.map((netTarif: NetPackages) => {
                        if (!netTarif.deleted_date) {
                            this.servicesNetSelect = [
                                ...this.servicesNetSelect,
                                {
                                    value: netTarif.id,
                                    label: `${netTarif.name} - ${netTarif.provider.name}${
                                        netTarif.transfer.connection_method === 'optical cable'
                                            ? ' - optické připojení'
                                            : netTarif.transfer.connection_method === 'metalic cable'
                                                ? ' - kabelové připojení'
                                                : netTarif.transfer.connection_method === 'radio'
                                                    ? ' - rádiové připojení'
                                                    : '- xDSL připojení'
                                    }`,
                                },
                            ];
                        }
                    });
                    this.ref.markForCheck();
                },
                error: error => {
                    console.error(error);
                },
            });

        this.dataNetbaseService.tvServicesSource.pipe(untilDestroyed(this))
            .subscribe({
                next: (tvServices: TvServices[]) => {
                    this.servicesTvSelect = [];
                    tvServices.map((tvService: TvServices) => {
                        if (!tvService.deleted_date) {
                            this.servicesTvSelect = [
                                ...this.servicesTvSelect,
                                {
                                    value: tvService.id,
                                    label: `${tvService.name} - ${tvService.provider.name}${
                                        tvService.transfer.connection_method === 'optical cable'
                                            ? ' - optické připojení'
                                            : tvService.transfer.connection_method === 'metalic cable'
                                                ? ' - kabelové připojení'
                                                : tvService.transfer.connection_method === 'radio'
                                                    ? ' - rádiové připojení'
                                                    : '- xDSL připojení'
                                    }`,
                                },
                            ];
                        }
                    });
                    this.ref.markForCheck();
                },
                error: error => {
                    console.error(error);
                },
            });

        this.dataNetbaseService.excludedAddressSource.pipe(untilDestroyed(this))
            .subscribe({
                next: (addresses: AddressExcluded[]) => {
                    this.exAddresses = addresses;
                },
                error: error => {
                    console.error(error);
                },
            });

        this.addressExcludedForm = this.formBuilder.group({
            address: [
                '',
                Validators.compose([
                    Validators.required,
                    Validators.maxLength(500),
                    Validators.minLength(2),
                ]),
            ],
            oriental_number: [{value: null, disabled: true}],
            house_number: [{value: null, disabled: true}, Validators.required],
            ruian: [{value: null, disabled: true}, Validators.required],
            city: [{value: '', disabled: true}],
            city_code: [{value: null, disabled: true}],
            zip: [{value: null, disabled: true}],
            district: [{value: '', disabled: true}],
            district_code: [{value: null, disabled: true}],
            street: [{value: '', disabled: true}],
            street_code: [{value: null, disabled: true}],
            city_part: [{value: '', disabled: true}],
            city_part_code: [{value: null, disabled: true}],
            region: [{value: '', disabled: true}],
            region_code: [{value: null, disabled: true}],
            state: [{value: '', disabled: true}],
            gps: [{value: null, disabled: true}],
            jstk: [{value: null, disabled: true}],
            descriptive: [''],
            net_packages: [null],
            tv_services: [null],
            type: ['ruian', Validators.required],
        });

        this.addressExcludedForm.valueChanges.pipe(untilDestroyed(this))
            .subscribe(() => {
                this.isDirty = this.addressExcludedForm.dirty;
                this.showInputErrors();
            });

        this.routerSubject.pipe(untilDestroyed(this))
            .subscribe((url: string) => {
                void this.router.navigateByUrl(url);
            });

        this.route.queryParams.pipe(untilDestroyed(this))
            .subscribe(params => {
                if (params.mapPharse) {
                    document.addEventListener('copy', (e: ClipboardEvent) => {
                        e.clipboardData?.setData('text/plain', params.mapPharse as string);
                        e.preventDefault();
                        document.removeEventListener('copy', null);
                    });
                    document.execCommand('copy');

                    const options = {
                        progressBar: true,
                        timeOut: 5000,
                        toastClass: 'success',
                    };

                    this.messages.showInfo('Adresa úspěšně zkopírována do schránky', '', options);
                    this.copyAddressFromMapy = true;
                }

                this.ref.markForCheck();
            });
    }

    ngOnChanges(): void {
        if (this.isVisible) {
            this.reinitSmartForm();

            const queryParams: Params = {form: null};

            void this.router.navigate([], {
                relativeTo: this.route,
                queryParams,
                queryParamsHandling: 'merge',
            });

            if (this.isUpdate && this.address) {
                this.addressExcludedForm.reset();
                this.formDialogObj.header = `Vyloučená adresa #${this.address.id} - ${this.address.address}`;
                this.fillTheForm();
                this.showInputErrors();
            }

            if (this.isCreate) {
                this.addressExcludedForm.reset();
                this.formDialogObj.header = 'Nová vyloučená adresa';
            }

            if (this.isCopy) {
                this.addressExcludedForm.reset();
                this.formDialogObj.header = 'Nová vyloučená adresa';
                this.fillTheForm();
                this.showInputErrors();
            }
        }
    }

    changeDialogState(value: boolean): void {
        this.addressExcludedFormDialogState.emit(value);
    }

    onFilteringTvPackages: EmitType<FilteringEventArgs> = (e: FilteringEventArgs) => {
        if (e.text === '') {
            e.updateData(this.servicesTvSelect);
        } else {
            let query: Query = new Query();

            query = e.text !== '' ? query.where('label', 'contains', e.text, true, true) : query;
            e.updateData(this.servicesTvSelect, query);
        }
    };

    onFilteringNetPackages: EmitType<FilteringEventArgs> = (e: FilteringEventArgs) => {
        if (e.text === '') {
            e.updateData(this.servicesNetSelect);
        } else {
            let query: Query = new Query();

            query = e.text !== '' ? query.where('label', 'contains', e.text, true, true) : query;
            e.updateData(this.servicesNetSelect, query);
        }
    };

    // eslint-disable-next-line complexity
    addressValidator(address: AddressExcluded | SmartAddress | null): void {
        this.smartFormData = address;

        if (this.smartFormData) {
            this.exAddress = this.checkExcludeUnique(
                this.smartFormData.ruian ?? null,
                this.smartFormData.street_code ?? null,
                this.smartFormData.city_part_code ?? null,
                this.smartFormData.city_code ?? null,
                this.smartFormData.district_code ?? null,
                this.smartFormData.region_code ?? null,
                this.smartFormData.state ? this.smartFormData.state : null,
            );

            if (this.exAddress) {
                const netPackages: number[] = [];
                const tvPackages: number[] = [];

                if (this.exAddress.net_packages && this.exAddress.net_packages.length > 0) {
                    this.exAddress.net_packages.map((netPackage: NetPackages) => {
                        netPackages.push(netPackage.id);
                    });
                }

                if (this.exAddress.tv_services && this.exAddress.tv_services.length > 0) {
                    this.exAddress.tv_services.map((tvPackage: TvServices) => {
                        tvPackages.push(tvPackage.id);
                    });
                }

                this.addressExcludedForm.controls.descriptive.patchValue(
                    this.exAddress.descriptive,
                    {
                        emitEvent: true,
                    },
                );

                this.addressExcludedForm.controls.net_packages.patchValue(netPackages, {
                    emitEvent: true,
                });

                this.addressExcludedForm.controls.tv_services.patchValue(tvPackages, {
                    emitEvent: true,
                });
            } else {
                this.exAddress = null;
            }

            this.addressExcludedForm.controls.ruian.setValue(
                this.smartFormData.ruian ?? this.smartFormData.address_obj?.ruian ?? null,
                {emitEvent: true},
            );

            if (this.smartFormData.address) {
                this.addressExcludedForm.controls.address.patchValue(this.smartFormData.address, {
                    emitEvent: true,
                });
            }

            this.addressExcludedForm.controls.oriental_number.patchValue(
                this.smartFormData.oriental_number ??
                this.smartFormData.address_obj?.oriental_number ??
                null,
                {emitEvent: true},
            );

            this.addressExcludedForm.controls.house_number.patchValue(
                this.smartFormData.house_number ??
                this.smartFormData.address_obj?.house_number ??
                null,
                {emitEvent: true},
            );

            this.addressExcludedForm.controls.street.patchValue(
                this.smartFormData.street ?? this.smartFormData.address_obj?.street ?? null,
                {emitEvent: true},
            );

            this.addressExcludedForm.controls.street_code.patchValue(
                this.smartFormData.street_code ??
                this.smartFormData.address_obj?.street_code ??
                null,
                {emitEvent: true},
            );

            this.addressExcludedForm.controls.city_part.patchValue(
                this.smartFormData.city_part ?? this.smartFormData.address_obj?.city_part ?? null,
                {emitEvent: true},
            );

            this.addressExcludedForm.controls.city_part_code.patchValue(
                this.smartFormData.city_part_code ??
                this.smartFormData.address_obj?.city_part_code ??
                null,
                {emitEvent: true},
            );

            this.addressExcludedForm.controls.city.patchValue(
                this.smartFormData.city ?? this.smartFormData.address_obj?.city ?? null,
                {emitEvent: true},
            );

            this.addressExcludedForm.controls.city_code.patchValue(
                this.smartFormData.city_code ?? this.smartFormData.address_obj?.city_code ?? null,
                {emitEvent: true},
            );

            this.addressExcludedForm.controls.district.patchValue(
                this.smartFormData.district ?? this.smartFormData.address_obj?.district ?? null,
                {emitEvent: true},
            );

            this.addressExcludedForm.controls.district_code.patchValue(
                this.smartFormData.district_code ??
                this.smartFormData.address_obj?.district_code ??
                null,
                {emitEvent: true},
            );

            this.addressExcludedForm.controls.region.patchValue(
                this.smartFormData.region ?? this.smartFormData.address_obj?.region ?? null,
                {emitEvent: true},
            );

            this.addressExcludedForm.controls.region_code.patchValue(
                this.smartFormData.region_code ??
                this.smartFormData.address_obj?.region_code ??
                null,
                {emitEvent: true},
            );

            this.addressExcludedForm.controls.state.patchValue(
                this.smartFormData.state ?? this.smartFormData.address_obj?.state ?? null,
                {emitEvent: true},
            );

            this.addressExcludedForm.controls.zip.patchValue(
                this.smartFormData.zip ?? this.smartFormData.address_obj?.zip ?? null,
                {emitEvent: true},
            );

            this.addressExcludedForm.controls.gps.patchValue(
                this.smartFormData.gps ?? this.smartFormData.address_obj?.gps ?? null,
                {emitEvent: true},
            );

            this.addressExcludedForm.controls.jstk.patchValue(
                this.smartFormData.jstk ?? this.smartFormData.address_obj?.jstk ?? null,
                {emitEvent: true},
            );

            this.addressExcludedForm.controls.type.patchValue(
                this.smartFormData.ruian
                    ? 'ruian'
                    : this.smartFormData.street_code
                        ? 'street'
                        : this.smartFormData.city_part_code
                            ? 'part'
                            : this.smartFormData.city_code
                                ? 'city'
                                : this.smartFormData.district_code
                                    ? 'district'
                                    : this.smartFormData.region_code
                                        ? 'region'
                                        : this.smartFormData.state
                                            ? 'state'
                                            : null,
            );
        } else {
            this.addressExcludedForm.reset();
        }

        this.ref.markForCheck();
    }

    fillTheForm(): void {
        this.addressValidator(this.address);
    }

    onSubmit(): void {
        this.submited = true;
        this.isDirty = false;
        this.loadingAddress = true;

        if (this.isCreate || this.isCopy) {
            this.createExcludeAddress();
        }

        if (this.isUpdate) {
            this.editExcludeAddress();
        }
    }

    // eslint-disable-next-line complexity
    createExcludeAddress(): void {
        if (
            this.currentUser &&
            (this.permissionService.checkUserISServiceAdmin(this.currentUser) ||
                this.permissionService.checkUserISAdministrative(this.currentUser) ||
                this.permissionService.checkUserISMarketingAdmin(this.currentUser))
        ) {
            if (this.addressExcludedForm.invalid) {
                console.error('form is not valid!');

                return;
            }

            if (
                !this.f.ruian.value &&
                !this.f.city_code.value &&
                !this.f.district_code.value &&
                !this.f.city_part_code.value &&
                !this.f.region_code.value &&
                !this.f.street_code.value
            ) {
                const body = 'Vyloučená adresa musí být přiřazena na lokaci...';
                const options = {
                    progressBar: true,
                    timeOut: 5000,
                    toastClass: 'success',
                };

                this.messages.showWarning('Vyloučená adresa nelze vytvořit', body, options);
                this.loadingAddress = false;
                this.ref.markForCheck();
            } else if (!this.f.net_packages.value && !this.f.tv_services.value) {
                const body = 'Vyloučená adresa musí obsahovat aspoň jeden TV nebo NET tarif...';
                const options = {
                    progressBar: true,
                    timeOut: 5000,
                    toastClass: 'success',
                };

                this.messages.showWarning('Vyloučená adresa nelze vytvořit', body, options);
                this.loadingAddress = false;
                this.ref.markForCheck();
            } else {
                const data: ExcludeAddressInput = {
                    address:
                        this.f.type.value === 'ruian'
                            ? this.smartWholeAddress
                            : this.f.type.value === 'street'
                                ? this.f.street.value
                                : this.f.type.value === 'part'
                                    ? this.f.city_part.value
                                    : this.f.type.value === 'city'
                                        ? this.f.city.value
                                        : this.f.type.value === 'district'
                                            ? this.f.district.value
                                            : this.f.type.value === 'region'
                                                ? this.f.region.value
                                                : this.f.type.value === 'state'
                                                    ? this.f.state.value
                                                    : null,
                    ruian: this.f.type.value === 'ruian' ? this.f.ruian.value : null,
                    city_code: this.f.type.value === 'city' ? this.f.city_code.value : null,
                    district_code:
                        this.f.type.value === 'district' ? this.f.district_code.value : null,
                    street_code: this.f.type.value === 'street' ? this.f.street_code.value : null,
                    city_part_code:
                        this.f.type.value === 'part' ? this.f.city_part_code.value : null,
                    region_code: this.f.type.value === 'region' ? this.f.region_code.value : null,
                    state: this.f.type.value === 'state' ? this.f.state.value : null,
                    addr_oriental_number: this.f.oriental_number.value ?? null,
                    addr_house_number: this.f.house_number.value ?? null,
                    addr_ruian: this.f.ruian.value ?? null,
                    addr_street: this.f.street.value ?? null,
                    addr_street_code: this.f.street_code.value ?? null,
                    addr_city: this.f.city.value,
                    addr_city_code: this.f.city_code.value ?? null,
                    addr_city_part: this.f.city_part.value,
                    addr_city_part_code: this.f.city_part_code.value ?? null,
                    addr_district: this.f.district.value,
                    addr_district_code: this.f.district_code.value ?? null,
                    addr_region: this.f.region.value,
                    addr_region_code: this.f.region_code.value ?? null,
                    addr_state: this.f.state.value,
                    addr_gps: this.f.gps.value ?? null,
                    addr_jstk: this.f.jstk.value ?? null,
                    addr_zip: this.f.zip.value,
                    descriptive: this.f.descriptive.value,
                    net_packages: this.f.net_packages.value,
                    tv_services: this.f.tv_services.value,
                    created_by: this.currentUser.id,
                };

                this.servicesService
                    .addAddressExcluded(data)
                    .pipe(untilDestroyed(this))
                    .subscribe({
                        next: () => {
                            const body = 'Vyloučená adresa přidána do seznamu...';
                            const options = {
                                progressBar: true,
                                timeOut: 5000,
                                toastClass: 'success',
                            };

                            this.messages.showSuccess(
                                'Vyloučená adresa úspěšně vytvořena',
                                body,
                                options,
                            );
                            this.isCreate = false;
                            this.loadingAddress = false;
                            this.dataNetbaseService.setExcludedAddressDataSource();
                            this.formDialogObj.hide();
                            this.ref.markForCheck();
                        },
                        error: error => {
                            console.error(error);

                            const body = 'Zkuste to znovu později';
                            const options = {
                                progressBar: true,
                                timeOut: 5000,
                                toastClass: 'red',
                            };

                            this.messages.showError(
                                'Chyba při vytváření vyloučené adresy',
                                body,
                                options,
                            );
                            this.loadingAddress = false;
                            this.formDialogObj.hide();
                            this.ref.markForCheck();
                        },
                    });
            }
        } else {
            const body = 'Nemáte oprávnění provést tuto akci...';
            const options = {progressBar: true, timeOut: 5000};

            this.messages.showError('Nedostatečné oprávnění', body, options);
            this.formDialogObj.hide();
            this.ref.markForCheck();
        }
    }

    // eslint-disable-next-line complexity
    editExcludeAddress(): void {
        if (
            this.currentUser &&
            (this.permissionService.checkUserISServiceAdmin(this.currentUser) ||
                this.permissionService.checkUserISAdministrative(this.currentUser) ||
                this.permissionService.checkUserISMarketingAdmin(this.currentUser))
        ) {
            if (this.addressExcludedForm.invalid) {
                console.error('form is not valid!');

                return;
            }

            if (
                !this.f.ruian.value &&
                !this.f.city_code.value &&
                !this.f.district_code.value &&
                !this.f.city_part_code.value &&
                !this.f.region_code.value &&
                !this.f.street_code.value
            ) {
                const body = 'Vyloučená adresa musí být přiřazena na lokaci...';
                const options = {
                    progressBar: true,
                    timeOut: 5000,
                    toastClass: 'success',
                };

                this.messages.showWarning('Vyloučená adresa nelze vytvořit', body, options);
                this.loadingAddress = false;
                this.ref.markForCheck();
            } else if (!this.f.net_packages.value && !this.f.tv_services.value) {
                const body = 'Vyloučená adresa musí obsahovat aspoň jeden TV nebo NET tarif...';
                const options = {
                    progressBar: true,
                    timeOut: 5000,
                    toastClass: 'success',
                };

                this.messages.showWarning('Vyloučená adresa nelze vytvořit', body, options);
                this.loadingAddress = false;
                this.ref.markForCheck();
            } else {
                const data: ExcludeAddressInput = {
                    id: this.address?.id,
                    address:
                        this.f.type.value === 'ruian'
                            ? this.smartWholeAddress
                            : this.f.type.value === 'street'
                                ? this.f.street.value
                                : this.f.type.value === 'part'
                                    ? this.f.city_part.value
                                    : this.f.type.value === 'city'
                                        ? this.f.city.value
                                        : this.f.type.value === 'district'
                                            ? this.f.district.value
                                            : this.f.type.value === 'region'
                                                ? this.f.region.value
                                                : this.f.type.value === 'state'
                                                    ? this.f.state.value
                                                    : null,
                    ruian: this.f.type.value === 'ruian' ? this.f.ruian.value : null,
                    city_code: this.f.type.value === 'city' ? this.f.city_code.value : null,
                    district_code:
                        this.f.type.value === 'district' ? this.f.district_code.value : null,
                    street_code: this.f.type.value === 'street' ? this.f.street_code.value : null,
                    city_part_code:
                        this.f.type.value === 'part' ? this.f.city_part_code.value : null,
                    region_code: this.f.type.value === 'region' ? this.f.region_code.value : null,
                    state: this.f.type.value === 'state' ? this.f.state.value : null,
                    addr_oriental_number: this.f.oriental_number.value ?? null,
                    addr_house_number: this.f.house_number.value ?? null,
                    addr_ruian: this.f.ruian.value ?? null,
                    addr_street: this.f.street.value ?? null,
                    addr_street_code: this.f.street_code.value ?? null,
                    addr_city: this.f.city.value,
                    addr_city_code: this.f.city_code.value ?? null,
                    addr_city_part: this.f.city_part.value,
                    addr_city_part_code: this.f.city_part_code.value ?? null,
                    addr_district: this.f.district.value,
                    addr_district_code: this.f.district_code.value ?? null,
                    addr_region: this.f.region.value,
                    addr_region_code: this.f.region_code.value ?? null,
                    addr_state: this.f.state.value,
                    addr_gps: this.f.gps.value ?? null,
                    addr_jstk: this.f.jstk.value ?? null,
                    addr_zip: this.f.zip.value,
                    descriptive: this.f.descriptive.value,
                    net_packages: this.f.net_packages.value,
                    tv_services: this.f.tv_services.value,
                    updated_by: this.currentUser.id,
                };

                this.servicesService
                    .updateAddressExcluded(data)
                    ?.pipe(untilDestroyed(this))
                    .subscribe(
                        () => {
                            const options = {
                                progressBar: true,
                                timeOut: 5000,
                                toastClass: 'success',
                            };

                            this.messages.showSuccess(
                                'Vyloučená adresa úspěšně upravena',
                                '',
                                options,
                            );
                            this.isUpdate = false;
                            this.loadingAddress = false;
                            this.dataNetbaseService.setExcludedAddressDataSource();
                            this.formDialogObj.hide();
                            this.ref.markForCheck();
                        },
                        error => {
                            console.error(error);

                            const body = 'Zkuste to znovu později';
                            const options = {
                                progressBar: true,
                                timeOut: 5000,
                                toastClass: 'red',
                            };

                            this.messages.showError(
                                'Chyba při úpravě vyloučené adresy',
                                body,
                                options,
                            );
                            this.loadingAddress = false;
                            this.formDialogObj.hide();
                            this.ref.markForCheck();
                        },
                    );
            }
        } else {
            const body = 'Nemáte oprávnění provést tuto akci...';
            const options = {progressBar: true, timeOut: 5000};

            this.messages.showError('Nedostatečné oprávnění', body, options);
            this.formDialogObj.hide();
            this.ref.markForCheck();
        }
    }

    showInputErrors(): void {
        this.isChecked = true;
        /* console.log('VALID: ' + this.ticketForm.valid);
          Object.keys(this.ticketForm.controls).forEach(key => {
              const controlErrors: ValidationErrors = this.ticketForm.get(key).errors;
              if (controlErrors != null) {
                  Object.keys(controlErrors).forEach(keyError => {
                      console.log('Key control: ' + key + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError]);
                  });
              }
          });*/
        this.addressExcludedForm.markAllAsTouched();
        this.ref.markForCheck();
    }

    ngOnDestroy(): void {
        this.routerSubject.unsubscribe();
    }
}
