import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {AppComponent} from '@src/app/app.component';
import {JwtInterceptor} from '@src/app/_helpers/jwt.interceptor';
import {ErrorInterceptor} from '@src/app/_helpers/error.interceptor';
import {Ng7BootstrapBreadcrumbModule} from 'ng7-bootstrap-breadcrumb';
import {LoadingBarRouterModule} from '@ngx-loading-bar/router';
import {LocationStrategy, PathLocationStrategy} from '@angular/common';
import {SharedModule} from '@src/app/shared_components/shared.module';
import {TitleService} from '@src/app/_services/title.service';
import {AuthenticationService} from '@src/app/_services/authentication.service';
import {LayoutModule} from '@src/app/main-layout/layout.module';
import {ErrorModule} from '@src/app/main-layout/errors/error.module';
import {MessageService} from '@src/app/_services/message.service';
import {VersionCheckService} from '@src/app/_services/version-check.service';
import {ApisService} from '@src/app/_api/apis.service';
import {PluginsService} from '@src/app/_services/plugins.service';
import {DataService} from '@src/app/_services/data.service';
import {AutologoutService} from '@src/app/_services/autologout.service';
import {CustomStorageService} from '@src/app/_services/custom-storage.service';
import {DataNetbaseService} from '@src/app/_services/data-netbase.service';
import {DataSyncService} from '@src/app/_services/data-sync.service';
import {MenuAllModule, SidebarModule, TreeViewAllModule} from '@syncfusion/ej2-angular-navigations';
import {MailerModule} from '@src/app/mailer/mailer.module';
import {SharedFeaturesModule} from '@src/app/shared_features/shared-features.module';
import {BookingModule} from '@src/app/features/booking/booking.module';
import {DealsModule} from '@src/app/features/deals/deals.module';
import {EventsModule} from '@src/app/features/events/events.module';
import {HotlineModule} from '@src/app/features/hotline/hotline.module';
import {KnowledgeModule} from '@src/app/features/knowledge/knowledge.module';
import {MaterialModule} from '@src/app/features/material/material.module';
import {ServicesModule} from '@src/app/features/services/services.module';
import {SettingsModule} from '@src/app/features/settings/settings.module';
import {TaskModule} from '@src/app/features/tasks/task.module';
import {TicketsModule} from '@src/app/features/tickets/tickets.module';
import {UsersModule} from '@src/app/features/users/users.module';
import {VacationModule} from '@src/app/features/vacation/vacation.module';
import {WikiModule} from '@src/app/features/wiki/wiki.module';
import {EnvironmentService} from '@src/app/_services/environment.service';
import {INITIALIZER_PROVIDER} from '@src/app/_providers/initializer.provider';
import {NotificationModule} from '@src/app/features/notifications/notification.module';
import {ToastModule} from '@syncfusion/ej2-angular-notifications';
import {routes} from '@src/app/app.routes.const';
import {SyncModule} from '@src/app/features/sync/sync.module';

@NgModule({
    declarations: [AppComponent],
    imports: [
        BookingModule,
        BrowserAnimationsModule,
        BrowserModule,
        DealsModule,
        ErrorModule,
        EventsModule,
        FormsModule,
        HotlineModule,
        HttpClientModule,
        KnowledgeModule,
        LayoutModule,
        LoadingBarRouterModule,
        MailerModule,
        MaterialModule,
        MenuAllModule,
        Ng7BootstrapBreadcrumbModule,
        NotificationModule,
        ReactiveFormsModule,
        // ReportsModule, TODO
        RouterModule.forRoot(routes),
        ServicesModule,
        SettingsModule,
        SharedFeaturesModule,
        SharedModule,
        SidebarModule,
        SyncModule,
        TaskModule,
        TicketsModule,
        ToastModule,
        TreeViewAllModule,
        UsersModule,
        VacationModule,
        WikiModule,
    ],
    providers: [
        ApisService,
        AuthenticationService,
        AutologoutService,
        CustomStorageService,
        DataNetbaseService,
        DataService,
        DataSyncService,
        EnvironmentService,
        INITIALIZER_PROVIDER,
        MessageService,
        PluginsService,
        TitleService,
        VersionCheckService,
        {provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true},
        {provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true},
        {provide: LocationStrategy, useClass: PathLocationStrategy},
    ],
    bootstrap: [AppComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class AppModule {}
