import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    HostListener,
    Input,
    OnChanges,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {DataService} from '@src/app/_services/data.service';
import {DataNetbaseService} from '@src/app/_services/data-netbase.service';
import {HardwareInput, ServicesService} from '@src/app/features/services/services.service';
import {MessageService} from '@src/app/_services/message.service';
import {AuthenticationService} from '@src/app/_services/authentication.service';
import {Hardware} from '@src/app/_models/services/hardware';
import {User} from '@src/app/_models/user/user';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {
    FileInfo,
    RemovingEventArgs,
    SelectedEventArgs,
    SuccessEventArgs,
    UploaderComponent,
} from '@syncfusion/ej2-angular-inputs';
import {DialogComponent} from '@syncfusion/ej2-angular-popups';
import {isNullOrUndefined} from '@syncfusion/ej2-base';
import {Observable} from 'rxjs';
import {saveAs} from 'file-saver';
import {ComponentCanDeactivate} from '@src/app/_guards/changes.guard';
import {EnvironmentService} from '@src/app/_services/environment.service';
import {ActivatedRoute, Params, Router} from '@angular/router';

@UntilDestroy()
@Component({
    selector: 'app-hardware-form',
    templateUrl: './hardware-form.component.html',
    styleUrls: ['./hardware-form.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HardwareFormComponent implements OnInit, OnChanges, ComponentCanDeactivate {
    // Upload
    path: {
        saveUrl: string;
        removeUrl: string;
    };

    autoUpload = false;

    dropElement: HTMLElement = document.getElementsByClassName('control-fluid')[0] as HTMLElement;

    humanizeBytes: (bytes: number) => void;

    deleteFileId: number;

    removeFile: Array<FileInfo[]> = [];

    // Dialogs
    buttonsDeleteFileDialog = [
        {
            click: this.removeHardwareFile.bind(this),
            buttonModel: {
                content: 'OK',
                cssClass: 'e-success e-outline',
                isFlat: false,
            },
        },
        {
            click: (): void => {
                this.deleteFileDialog.hide();
            },
            buttonModel: {content: 'ZRUŠIT', cssClass: 'e-danger e-outline'},
        },
    ];

    // Forms
    hardwareForm: FormGroup;

    isDirty = false;

    isChecked = false;

    submited = false;

    // Variables
    currentUser: User | null;

    imagesBasic = [];

    visible = false;

    // Loaders
    loadingHardware = false;

    @Input() isCreate;

    @Input() isUpdate;

    @Input() isVisible;

    @Input() isCopy;

    @Input() hardware: Hardware | null = null;

    @Output() readonly hardwareFormDialogState = new EventEmitter<boolean>();

    // Upload
    @ViewChild('preloadupload') uploadObj: UploaderComponent;

    // Dialogs
    @ViewChild('formDialog') formDialogObj: DialogComponent;

    @ViewChild('deleteFileDialog') deleteFileDialog: DialogComponent;

    constructor(
        private readonly router: Router,
        private readonly route: ActivatedRoute,
        private readonly formBuilder: FormBuilder,
        private readonly ref: ChangeDetectorRef,
        private readonly dataService: DataService,
        private readonly dataNetbaseService: DataNetbaseService,
        private readonly servicesService: ServicesService,
        private readonly messages: MessageService,
        private readonly authenticationService: AuthenticationService,
        private readonly environmentService: EnvironmentService,
    ) {
        this.currentUser = this.authenticationService.currentUserValue;
        this.path = {
            saveUrl: `${this.environmentService.backendURL}/api/upload/hardware?type=hardware`,
            removeUrl: '',
        };
    }

    get f(): {[key: string]: AbstractControl} {
        return this.hardwareForm.controls;
    }

    @HostListener('window:beforeunload')
    canDeactivate(): Observable<boolean> | boolean {
        return !this.isDirty;
    }

    ngOnInit(): void {
        this.imagesBasic = [];
        this.hardwareForm = this.formBuilder.group({
            name: [
                '',
                Validators.compose([
                    Validators.required,
                    Validators.maxLength(255),
                    Validators.minLength(3),
                ]),
            ],
            fee: [
                0,
                Validators.compose([
                    Validators.required,
                    Validators.max(100000),
                    Validators.min(0),
                ]),
            ],
            price: [
                0,
                Validators.compose([
                    Validators.required,
                    Validators.max(100000),
                    Validators.min(0),
                ]),
            ],
            photoUrl: [null],
        });

        this.hardwareForm.valueChanges.pipe(untilDestroyed(this))
            .subscribe(() => {
                this.isDirty = this.hardwareForm.dirty;
                this.showInputErrors();
            });
    }

    ngOnChanges(): void {
        if (this.isVisible) {
            this.uploadObj.clearAll();

            const queryParams: Params = {form: null};

            void this.router.navigate([], {
                relativeTo: this.route,
                queryParams,
                queryParamsHandling: 'merge',
            });

            if (this.isUpdate && this.hardware) {
                this.hardwareForm.reset();
                this.formDialogObj.header = `Zařízení #${this.hardware.id} - ${this.hardware.name}`;
                this.fillTheForm();
                this.showInputErrors();
            }

            if (this.isCreate) {
                this.hardwareForm.reset();
                this.formDialogObj.header = 'Nové zařízení';
            }

            if (this.isCopy) {
                this.hardwareForm.reset();
                this.formDialogObj.header = 'Nové zařízení';
                this.fillTheForm();
                this.showInputErrors();
            }

            this.ref.markForCheck();
        }
    }

    changeDialogState(value: boolean): void {
        this.hardwareFormDialogState.emit(value);
    }

    fillTheForm(): void {
        this.hardwareForm.controls.name.patchValue(this.hardware?.name);
        this.hardwareForm.controls.fee.patchValue(this.hardware?.fee);
        this.hardwareForm.controls.price.patchValue(this.hardware?.price);
    }

    onSubmit(): void {
        this.submited = true;
        this.isDirty = false;
        this.loadingHardware = true;

        if (this.isCreate || this.isCopy) {
            this.createHardware();
        }

        if (this.isUpdate) {
            this.editHardware();
        }
    }

    createHardware(): void {
        this.submited = true;

        if (this.hardwareForm.invalid) {
            console.error('form is not valid!');

            return;
        }

        this.loadingHardware = true;

        const hardwareData: HardwareInput = {
            name: this.f.name.value,
            fee: this.f.fee.value,
            price: this.f.price.value,
            photo_thumb_url: this.f.photoUrl.value,
            created_by: this.currentUser?.id,
        };

        this.servicesService
            .addHardware(hardwareData)
            .pipe(untilDestroyed(this))
            .subscribe({
                next: (data: Hardware) => {
                    if (this.uploadObj.getFilesData().length > 0) {
                        this.uploadObj.asyncSettings.saveUrl = `${this.environmentService.backendURL}/api/upload/hardware?type=hardware&id=${data.id}`;
                        this.uploadObj.upload(this.uploadObj.getFilesData()[0]);
                    }

                    const body = `${data.name} přidán do nabídky`;
                    const options = {progressBar: true, timeOut: 5000};

                    this.messages.showSuccess('Zařízení úspěšně vytvořeno', body, options);
                    this.dataNetbaseService.setHardwareDataSource();
                    this.isCreate = false;
                    this.loadingHardware = false;
                    this.ref.markForCheck();
                    this.formDialogObj.hide();
                },
                error: error => {
                    console.error(error);

                    const body = 'Zkuste to znovu později';
                    const options = {
                        progressBar: true,
                        timeOut: 5000,
                        toastClass: 'red',
                    };

                    this.messages.showError('Chyba při vytváření zařízení', body, options);
                    this.loadingHardware = false;
                    this.ref.markForCheck();
                },
            });
    }

    editHardware(): void {
        this.submited = true;

        if (this.hardwareForm.invalid) {
            console.error('form is not valid!');

            return;
        }

        this.loadingHardware = true;

        const hardwareData: HardwareInput = {
            id: this.hardware?.id,
            name: this.f.name.value,
            fee: this.f.fee.value,
            price: this.f.price.value,
            photo_thumb_url: this.f.photoUrl.value,
            updated_by: this.currentUser?.id,
        };

        this.servicesService
            .updateHardware(hardwareData)
            ?.pipe(untilDestroyed(this))
            .subscribe({
                next: (data: Hardware) => {
                    if (this.uploadObj.getFilesData().length > 0) {
                        this.uploadObj.asyncSettings.saveUrl = `${this.environmentService.backendURL}/api/upload/hardware?type=hardware&id=${data.id}`;
                        this.uploadObj.upload(this.uploadObj.getFilesData()[0]);
                    }

                    const body = `${data.name} upraven`;
                    const options = {progressBar: true, timeOut: 5000};

                    this.messages.showSuccess('Zařízení úspěšně upraveno', body, options);
                    this.dataNetbaseService.setHardwareDataSource();
                    this.isUpdate = false;
                    this.loadingHardware = false;
                    this.ref.markForCheck();
                    this.formDialogObj.hide();
                },
                error: error => {
                    console.error(error);

                    const body = 'Zkuste to znovu později';
                    const options = {
                        progressBar: true,
                        timeOut: 5000,
                        toastClass: 'red',
                    };

                    this.messages.showError('Chyba při úpravě zařízení', body, options);
                    this.loadingHardware = false;
                    this.ref.markForCheck();
                },
            });
    }

    // Files
    downloadFile(): void {
        if (this.hardware) {
            this.servicesService
                .downloadHardwarePhoto(this.hardware)
                .pipe(untilDestroyed(this))
                .subscribe({
                    next: data => {
                        saveAs(data, this.hardware?.name);
                    },
                    error: error => {
                        alert('Chyba během stahování souboru.');
                        console.error(error);
                    },
                });
        }
    }

    onFileSelected(args: SelectedEventArgs): void {
        // Filter the 5 files only to showcase
        args.filesData.splice(5);

        const filesData: FileInfo[] = this.uploadObj.getFilesData();
        const allFiles: FileInfo[] = filesData.concat(args.filesData);

        if (allFiles.length > 5) {
            // eslint-disable-next-line @typescript-eslint/prefer-for-of
            for (let i = 0; i < allFiles.length; i++) {
                if (allFiles.length > 5) {
                    allFiles.shift();
                }
            }

            args.filesData = allFiles;
            // set the modified custom data
            args.modifiedFilesData = args.filesData;
        }

        let existingFiles: FileInfo[] = this.uploadObj.getFilesData();

        for (let i = 0; i < args.filesData.length; i++) {
            // eslint-disable-next-line @typescript-eslint/prefer-for-of
            for (let j = 0; j < existingFiles.length; j++) {
                if (!isNullOrUndefined(args.filesData[i])) {
                    // eslint-disable-next-line eqeqeq
                    if (existingFiles[j].name == args.filesData[i].name) {
                        args.filesData.splice(i, 1);
                    }
                }
            }
        }

        existingFiles = existingFiles.concat(args.filesData);
        args.modifiedFilesData = existingFiles;
        args.isModified = true;
    }

    onSuccessUpload(args: SuccessEventArgs): void {
        if (args.file) {
            const body = `Soubor: ${args.file.name}`;
            const options = {progressBar: true, timeOut: 5000, toastClass: 'success'};

            this.messages.showSuccess('Soubor úspěšně nahrán', body, options);
            this.dataNetbaseService.setHardwareDataSource();
        }
    }

    onFileRemove(args: RemovingEventArgs): void {
        this.removeFile = [];
        args.postRawFile = false;
        this.removeFile.push(args.filesData);
        this.deleteFileDialog.show();
    }

    removeHardwareFile(): void {
        this.loadingHardware = true;

        const hardwareData: HardwareInput = {
            id: this.hardware?.id,
            photo_url: null,
            photo_thumb_url: null,
            updated_by: this.currentUser?.id,
        };

        this.servicesService
            .updateHardware(hardwareData)
            ?.pipe(untilDestroyed(this))
            .subscribe({
                next: (data: Hardware) => {
                    const body = `Soubor #${data.id}`;
                    const options = {progressBar: true, timeOut: 5000};

                    this.messages.showSuccess('Soubor úspěšně smazán', body, options);
                    this.dataNetbaseService.setHardwareDataSource();
                    this.loadingHardware = false;
                    this.ref.markForCheck();
                    this.deleteFileDialog.hide();
                },
                error: error => {
                    console.error(error);

                    const body = 'Zkuste to znovu později';
                    const options = {
                        progressBar: true,
                        timeOut: 5000,
                        toastClass: 'red',
                    };

                    this.messages.showError('Chyba během mazání souboru', body, options);
                    this.loadingHardware = false;
                    this.ref.markForCheck();
                },
            });
    }

    showInputErrors(): void {
        this.isChecked = true;
        /* console.log('VALID: ' + this.ticketForm.valid);
        Object.keys(this.ticketForm.controls).forEach(key => {
            const controlErrors: ValidationErrors = this.ticketForm.get(key).errors;
            if (controlErrors != null) {
                Object.keys(controlErrors).forEach(keyError => {
                    console.log('Key control: ' + key + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError]);
                });
            }
        });*/
        this.hardwareForm.markAllAsTouched();
        this.ref.markForCheck();
    }
}
