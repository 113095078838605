import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    NgZone,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AddressObj} from '@src/app/features/services/_forms/callwizard/types/smartform-address.type';
import {GoogleMap, MapInfoWindow, MapMarker} from '@angular/google-maps';
import {Marker} from '@src/app/features/services/services.service';

declare let smartform;

@Component({
    selector: 'app-address-validator',
    templateUrl: './address-validator.component.html',
    styleUrls: ['./address-validator.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddressValidatorComponent implements OnInit {
    addressObj: AddressObj = {
        city: '',
        cityCode: 0,
        cityPart: '',
        district: '',
        districtCode: 0,
        gps: '',
        houseNumber: 0,
        jstk: '',
        orientalNumber: 0,
        partCode: 0,
        region: '',
        regionCode: 0,
        ruianCode: null,
        searchedAddress: null,
        state: '',
        street: '',
        streetCode: 0,
        zip: 0,
        buildingCode: 0,
        orientalNumberChar: 0,
        provisionalNumber: 0,
        wholeNumber: '',
        post: '',
        stateCode: 0,
        fieldNumber: 0,
        fieldNumber2: 0,
        cadastral: '',
        cadastralCode: 0,
        lift: false,
        numberOfStoreys: 0,
        numberOfFlats: 0,
        floorArea: 0,
    };

    addressForm: FormGroup;

    addressLoading = false;

    /*
        location: Location = {
            lat: 50.02698609200264,
            lng: 15.207439700497188,
            marker: {
                lat: 50.02698609200264,
                lng: 15.207439700497188,
                draggable: false,
            },
            zoom: 16,
        };
    */

    // Map
    zoom = 12;

    center: google.maps.LatLngLiteral;

    options: google.maps.MapOptions = {
        mapTypeId: 'hybrid',
        zoomControl: false,
        scrollwheel: false,
        disableDoubleClickZoom: true,
        maxZoom: 15,
        minZoom: 8,
    };

    markers: Marker[] = [];

    infoContent = '';

    @ViewChild(GoogleMap, {static: false}) map: GoogleMap;

    @ViewChild(MapInfoWindow, {static: false}) info: MapInfoWindow;

    @Input() address: string;

    @Output() readonly validAddress = new EventEmitter<AddressObj>();

    constructor(
        private readonly formBuilder: FormBuilder,
        private readonly zone: NgZone,
        private readonly ref: ChangeDetectorRef,
    ) {
        this.zone = zone;
    }

    get addressFormCtrl(): {[key: string]: AbstractControl} {
        return this.addressForm.controls;
    }

    reinitSmartForm(): void {
        smartform
            .getInstance('smartform-instance-address')
            .addressControl
            .addValidationCallback(this.validationCallback.bind(this));
    }

    validationCallback(validationResult): void {
        if (validationResult.result.type === smartform.AddressValidationResultType.HIT) {
            this.addressObj.searchedAddress = null;
            // podle vstupních dat byla nalezena jedna validní adresa
            this.addressObj.ruianCode = parseInt(
                validationResult.result.addressArray[0].CODE as string,
                10,
            );

            this.addressObj.buildingCode = parseInt(
                validationResult.result.addressArray[0].BUILDING_CODE as string,
                10,
            );

            this.addressObj.orientalNumber = parseInt(
                validationResult.result.addressArray[0].STREET_NUMBER as string,
                10,
            );

            this.addressObj.orientalNumberChar = parseInt(
                validationResult.result.addressArray[0].STREET_NUMBER_CHAR as string,
                10,
            );

            this.addressObj.houseNumber = parseInt(
                validationResult.result.addressArray[0].CONSCRIPTION_NUMBER as string,
                10,
            );

            this.addressObj.provisionalNumber = parseInt(
                validationResult.result.addressArray[0].PROVISIONAL_NUMBER as string,
                10,
            );
            this.addressObj.wholeNumber = validationResult.result.addressArray[0].WHOLE_NUMBER;
            this.addressObj.street = validationResult.result.addressArray[0].STREET;
            this.addressObj.streetCode = validationResult.result.addressArray[0].STREET_CODE
                ? parseInt(validationResult.result.addressArray[0].STREET_CODE as string, 10)
                : 0;
            this.addressObj.cityPart = validationResult.result.addressArray[0].PART;
            this.addressObj.partCode = validationResult.result.addressArray[0].PART_CODE
                ? parseInt(validationResult.result.addressArray[0].PART_CODE as string, 10)
                : 0;
            this.addressObj.city = validationResult.result.addressArray[0].CITY;
            this.addressObj.cityCode = validationResult.result.addressArray[0].CITY_CODE
                ? parseInt(validationResult.result.addressArray[0].CITY_CODE as string, 10)
                : 0;
            this.addressObj.district = validationResult.result.addressArray[0].DISTRICT;
            this.addressObj.districtCode = validationResult.result.addressArray[0].DISTRICT_CODE
                ? parseInt(validationResult.result.addressArray[0].DISTRICT_CODE as string, 10)
                : 0;
            this.addressObj.region = validationResult.result.addressArray[0].REGION;
            this.addressObj.regionCode = validationResult.result.addressArray[0].REGION_CODE
                ? parseInt(validationResult.result.addressArray[0].REGION_CODE as string, 10)
                : 0;

            this.addressObj.zip = parseInt(
                validationResult.result.addressArray[0].ZIP as string,
                10,
            );
            this.addressObj.post = validationResult.result.addressArray[0].POST;
            this.addressObj.state = validationResult.result.addressArray[0].COUNTRY;
            this.addressObj.stateCode = validationResult.result.addressArray[0].COUNTRY_CODE;
            this.addressObj.gps = `${
                validationResult.result.addressArray[0].COORD_WGS84_LATITUDE as string
            } / ${validationResult.result.addressArray[0].COORD_WGS84_LONGITUDE as string}`;

            this.addressObj.jstk = `${
                validationResult.result.addressArray[0].COORD_JTSK_X as string
            } / ${validationResult.result.addressArray[0].COORD_JTSK_Y as string}`;

            this.addressObj.zip = parseInt(
                validationResult.result.addressArray[0].ZIP as string,
                10,
            );

            this.addressObj.fieldNumber = parseInt(
                validationResult.result.addressArray[0].BUILDING_PARCEL_NUMBER_1 as string,
                10,
            );

            this.addressObj.fieldNumber2 = parseInt(
                validationResult.result.addressArray[0].BUILDING_PARCEL_NUMBER_2 as string,
                10,
            );
            this.addressObj.cadastral = validationResult.result.addressArray[0].CADASTRAL_UNIT_NAME;
            this.addressObj.cadastralCode = parseInt(
                validationResult.result.addressArray[0].CADASTRAL_UNIT_CODE as string,
                10,
            );
            this.addressObj.lift = validationResult.result.addressArray[0].BUILDING_WITH_LIFT;
            this.addressObj.numberOfStoreys = parseInt(
                validationResult.result.addressArray[0].NUMBER_OF_STOREYS as string,
                10,
            );

            this.addressObj.numberOfFlats = parseInt(
                validationResult.result.addressArray[0].NUMBER_OF_FLATS as string,
                10,
            );

            this.addressObj.floorArea = parseInt(
                validationResult.result.addressArray[0].FLOOR_AREA as string,
                10,
            );

            this.addressObj.searchedAddress =
                validationResult.result.addressArray[0].FORMATTED_ADDRESS_WHOLE;

            this.validAddress.emit(this.addressObj);
            this.findLocation(this.addressObj.searchedAddress);
            this.ref.markForCheck();
        }
    }

    ngOnInit(): void {
        this.addressForm = this.formBuilder.group({
            address: ['', Validators.required],
        });

        if (this.address.length > 0) {
            this.addressForm.controls.address.patchValue(this.address);
        }

        navigator.geolocation.getCurrentPosition(position => {
            this.center = {
                lat: position.coords.latitude,
                lng: position.coords.longitude,
            };
        });
    }

    zoomIn(): void {
        if (this.options.maxZoom && this.zoom < this.options.maxZoom) {
            this.zoom++;
        }
    }

    zoomOut(): void {
        if (this.options.minZoom && this.zoom > this.options.minZoom) {
            this.zoom--;
        }
    }

    logCenter(): void {
        console.log(JSON.stringify(this.map.getCenter()));
    }

    addMarker(): void {
        this.markers.push({
            position: {
                lat: this.center.lat + ((Math.random() - 0.5) * 2) / 10,
                lng: this.center.lng + ((Math.random() - 0.5) * 2) / 10,
            },
            label: {
                color: 'red',
                text: `Marker label ${this.markers.length + 1}`,
            },
            title: `Marker title ${this.markers.length + 1}`,
            info: `Marker info ${this.markers.length + 1}`,
            options: {
                animation: google.maps.Animation.BOUNCE,
            },
        });
    }

    openInfo(marker: MapMarker, content): void {
        this.infoContent = content;
        this.info.open(marker);
    }

    // TODO refactor
    findLocation(address): void {
        /*  if (!this.geocoder) {
            this.geocoder = new google.maps.Geocoder();
        }

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        this.geocoder.geocode(
            {
                address,
            },
            async (results, status) => {
                if (status === google.maps.GeocoderStatus.OK) {
                    // eslint-disable-next-line @typescript-eslint/prefer-for-of
                    for (let i = 0; i < results[0].address_components.length; i++) {
                        const { types } = results[0].address_components[i];

                        if (types.indexOf('locality') !== -1) {
                            this.location.address_level_2 =
                                results[0].address_components[i].long_name;
                        }

                        if (types.indexOf('country') !== -1) {
                            this.location.address_country =
                                results[0].address_components[i].long_name;
                        }

                        if (types.indexOf('postal_code') !== -1) {
                            this.location.address_zip = results[0].address_components[i].long_name;
                        }

                        if (types.indexOf('administrative_area_level_1') !== -1) {
                            this.location.address_state =
                                results[0].address_components[i].long_name;
                        }
                    }

                    if (this.location.marker && results[0].geometry.location) {
                        this.location.lat = results[0].geometry.location.lat();
                        this.location.lng = results[0].geometry.location.lng();
                        this.location.marker.lat = results[0].geometry.location.lat();
                        this.location.marker.lng = results[0].geometry.location.lng();
                        this.location.marker.draggable = true;
                        this.location.viewport = results[0].geometry.viewport;
                    }
                }
                else {
                    alert('Tuto adresu nelze najít na Google mapě.');
                }
            }
        );*/
    }
}
