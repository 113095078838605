import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {BehaviorSubject, Observable, of, switchMap} from 'rxjs';
import {Vacation} from '@src/app/_models/vacation/vacation';
import {VacationCategory} from '@src/app/_models/vacation/vacation-category';
import {catchError, map, shareReplay, tap} from 'rxjs/operators';
import {MessageService} from '@src/app/_services/message.service';
import {registerLocaleData} from '@angular/common';
import {User} from '@src/app/_models/user/user';
import {Holiday} from '@src/app/_models/holiday/holiday';
import {DataService} from '@src/app/_services/data.service';

import moment from 'moment';
import localeCs from '@angular/common/locales/cs';
import {Tickets} from '@src/app/_models/ticket/tickets';
import {Hotline} from '@src/app/_models/hotline/hotline';
import {Task} from '@src/app/_models/task/task';
import {VacationLogs} from '@src/app/_models/vacation/vacation-logs';
import {BaseModel} from '@src/app/_models/_base/base-model';
import {EnvironmentService} from '@src/app/_services/environment.service';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';

export interface VacationCategoryInput {
    id?: number;
    name?: string;
    colortag?: string;
}

moment.updateLocale('cs', {workingWeekdays: [1, 2, 3, 4, 5]});
registerLocaleData(localeCs);

const httpOptions = {
    headers: new HttpHeaders({'Content-Type': 'application/json'}),
};

export interface VacationInput {
    id?: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    category_id?: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    creator_name?: string;
    user?: string;
    reason?: string;
    status?: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    end_at?: string | null;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    start_at?: string | null;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    type?: VacationCategory | string | null;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    workdays?: number | null;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    created_date?: string;
    boss?: User;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    updated_by?: number;
}

@UntilDestroy()
@Injectable({
    providedIn: 'root',
})
export class VacationService {
    private readonly resultVacationCheckSource = new BehaviorSubject<{
        // eslint-disable-next-line @typescript-eslint/naming-convention
        ticket_indue: Tickets[];
        // eslint-disable-next-line @typescript-eslint/naming-convention
        task_indue: Task[];
        // eslint-disable-next-line @typescript-eslint/naming-convention
        hotline_indue: Hotline[];
        // eslint-disable-next-line @typescript-eslint/naming-convention
        vacation_indue: Vacation[];
    }>({
        ticket_indue: [],
        task_indue: [],
        hotline_indue: [],
        vacation_indue: [],
    });

    private readonly vacationsUrl: string;

    private readonly vacationsCategoryUrl: string;

    private readonly holidaysUrl: string;

    private readonly logsUrl: string;

    setUserFondsResiduesSource: BehaviorSubject<
        {
            userId: number;
            sickDaysResidue: number;
            vacationResidue: number;
        }[]
    > = new BehaviorSubject<
        {
            userId: number;
            sickDaysResidue: number;
            vacationResidue: number;
        }[]
    >([]);

    categories: Observable<VacationCategory[] | undefined>;

    currHolidays: Observable<Holiday[] | undefined>;

    vacationCheckSource = this.resultVacationCheckSource.asObservable();

    constructor(
        private readonly http: HttpClient,
        private readonly dataService: DataService,
        private readonly messageService: MessageService,
        private readonly environmentService: EnvironmentService,
    ) {
        this.vacationsUrl = this.environmentService.backendURL + '/api/vacation';
        this.vacationsCategoryUrl = this.environmentService.backendURL + '/api/vacation/categories';
        this.holidaysUrl = this.environmentService.backendURL + '/api/vacation/holidays/cz/';
        this.logsUrl = this.environmentService.backendURL + '/api/vacation/log';
    }

    /**
     * Handle Http operation that failed.
     * Let the app continue.
     * @param operation - name of the operation that failed
     * @param result - optional value to return as the observable result
     */
    private handleError<T>(operation = 'operation', result?: T) {
        return (error: {[key: string]: string}): Observable<T | undefined> => {
            // TODO: send the error to remote logging infrastructure
            console.error(error); // log to console instead

            // TODO: better job of transforming error for user consumption
            this.log(`${operation} failed: ${error.message}`);

            // Let the app keep running by returning an empty result.
            return of(result);
        };
    }

    /** Log a vacationService message with the MessageService */
    private log(message: string): void {
        this.messageService.addNotification(`Vacation: ${message}`, false);
    }

    setUserFondsResidues(
        users: User[],
        year: number,
    ): Observable<
        {
            userId: number;
            sickDaysResidue: number;
            vacationResidue: number;
        }[]
    > {
        return this.dataService.vacationSource.pipe(
            untilDestroyed(this),
            switchMap((vacations: Vacation[] | undefined) => {
                let vacationDaysCurr = 0;
                let sickDaysCurr = 0;
                const residues: {
                    userId: number;
                    sickDaysResidue: number;
                    vacationResidue: number;
                }[] = [];

                if (vacations && vacations.length > 0) {
                    users.map((user: User) => {
                        vacationDaysCurr = 0;
                        sickDaysCurr = 0;

                        if (user.authorized && !user.deleted_date) {
                            vacations.map((vacation: Vacation) => {
                                if (vacation.creator.id === user.id) {
                                    if (
                                        !vacation.decline &&
                                        !vacation.deleted_date &&
                                        vacation.category_id === 1
                                    ) {
                                        if (year === moment(vacation.start_at)
                                            .year()) {
                                            vacationDaysCurr += vacation.workdays;
                                        }
                                    }

                                    if (
                                        !vacation.decline &&
                                        !vacation.deleted_date &&
                                        vacation.category_id === 5
                                    ) {
                                        if (year === moment(vacation.start_at)
                                            .year()) {
                                            sickDaysCurr += vacation.workdays;
                                        }
                                    }
                                }
                            });

                            residues[user.id] = {
                                userId: user.id,
                                vacationResidue: user.vacation_fond - vacationDaysCurr,
                                sickDaysResidue: user.sickdays_fond - sickDaysCurr,
                            };
                        }
                    });
                }

                return of(residues);
            }),
            tap(result => {
                this.setUserFondsResiduesSource.next(result);
            }),
        );
    }

    getActiveCategories(): Observable<VacationCategory[] | undefined> {
        this.categories = this.http.get<VacationCategory[]>(this.vacationsCategoryUrl)
            .pipe(
                tap(() => {
                    this.log('fetched vacations categories');
                }),
                shareReplay(),
                catchError(this.handleError<VacationCategory[]>('getCategoryQueue', [])),
            );

        return this.categories;
    }

    getCurrCZEHolidays(date?: string): Observable<Holiday[] | undefined> {
        const url = date ? `${this.holidaysUrl}/${date}` : `${this.holidaysUrl}`;

        this.currHolidays = this.http.get<Holiday[]>(url)
            .pipe(
                tap(() => {
                    this.log('fetched holidays');
                }),
                shareReplay(),
                catchError(this.handleError<Holiday[]>('getCurrHoliday')),
            );

        return this.currHolidays;
    }

    addVacation(vacation: VacationInput): Observable<Vacation> {
        this.dataService.clearVacationsCache();

        return this.http.post<Vacation>(this.vacationsUrl, vacation, httpOptions);
    }

    deleteVacation(vacation: Vacation): Observable<Vacation> | undefined {
        if (!vacation.deleted_by) {
            console.error('deleted_by is missing...');

            return;
        }

        const headers = new HttpHeaders({'Content-Type': 'application/json'});
        const params = new HttpParams().set('deleted_by', vacation.deleted_by.toString());
        const {id} = vacation;
        const url = `${this.vacationsUrl}/vacation/${id}`;

        this.dataService.clearVacationsCache();

        return this.http.delete<Vacation>(url, {headers, params});
    }

    updateVacation(vacation: VacationInput): Observable<Vacation> | undefined {
        if (!vacation.id) {
            console.error('ID is missing...');

            return;
        }

        const url = `${this.vacationsUrl}/update/${vacation.id}`;

        this.dataService.clearVacationsCache();

        return this.http.put<Vacation>(url, vacation, httpOptions);
    }

    confirmApplication(vacation: Vacation): Observable<Vacation> {
        const {id} = vacation;

        vacation.confirm_action = 'confirmed';

        const url = `${this.vacationsUrl}/update/${id}`;

        this.dataService.clearVacationsCache();

        return this.http.put<Vacation>(url, vacation, httpOptions);
    }

    declineApplication(vacation: Vacation): Observable<Vacation> {
        const {id} = vacation;

        vacation.confirm_action = 'declined';

        const url = `${this.vacationsUrl}/update/${id}`;

        this.dataService.clearVacationsCache();

        return this.http.put<Vacation>(url, vacation, httpOptions);
    }

    getVacationState(
        applications: Vacation[],
        user: User,
        until?: string,
        from?: string,
    ): {atWork: boolean; vacations: Vacation[]} {
        const state: {atWork: boolean; vacations: Vacation[]} = {atWork: true, vacations: []};

        applications.map((application: Vacation) => {
            if (
                user.id === application.created_by &&
                !application.decline_at &&
                !application.deleted_date &&
                !application.decline &&
                application.confirm &&
                moment(application.start_at)
                    .format('YYYY-MM-DD HH:mm') <=
                (until
                    ? moment(until)
                        .format('YYYY-MM-DD HH:mm')
                    : moment()
                        .format('YYYY-MM-DD HH:mm')) &&
                moment(application.end_at)
                    .format('YYYY-MM-DD HH:mm') >=
                (from
                    ? moment(from)
                        .format('YYYY-MM-DD HH:mm')
                    : moment()
                        .format('YYYY-MM-DD HH:mm'))
            ) {
                state.vacations.push(application);
                state.atWork = false;
            }
        });

        return state;
    }

    checkVacationsIndex(start: string, end: string, creatorId: number): void {
        const data: {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            ticket_indue: Tickets[];
            // eslint-disable-next-line @typescript-eslint/naming-convention
            task_indue: Task[];
            // eslint-disable-next-line @typescript-eslint/naming-convention
            hotline_indue: Hotline[];
            // eslint-disable-next-line @typescript-eslint/naming-convention
            vacation_indue: Vacation[];
        } = {
            ticket_indue: [],
            task_indue: [],
            hotline_indue: [],
            vacation_indue: [],
        };
        const dateStart = moment(start)
            .format('YYYY-MM-DD HH:mm:ss');
        const dateEnd = moment(end)
            .format('YYYY-MM-DD HH:mm:ss');

        this.dataService.userSource.subscribe((users: User[] | undefined) => {
            if (creatorId && users && users.length > 0) {
                this.dataService
                    .getTicketsData(creatorId)
                    .pipe(map((response: BaseModel) => response.data as Tickets[]))
                    .subscribe(tickets => {
                        const matchedTickets: Tickets[] = [];

                        if (tickets.length > 0) {
                            tickets.map(ticket => {
                                if (
                                    !ticket.deleted_date &&
                                    !ticket.finished_at &&
                                    ticket.deadline
                                ) {
                                    const startDate = moment(
                                        ticket.start_deadline
                                            ? ticket.start_deadline
                                            : ticket.created_date,
                                    )
                                        .format('YYYY-MM-DD HH:mm:ss');
                                    const endDate = moment(ticket.deadline)
                                        .format(
                                            'YYYY-MM-DD HH:mm:ss',
                                        );

                                    if (dateStart <= endDate && dateEnd >= startDate) {
                                        matchedTickets.push(ticket);
                                    }
                                }
                            });
                        }

                        data.ticket_indue = matchedTickets;
                        this.resultVacationCheckSource.next(data);
                    });

                this.dataService.getTasksData(creatorId)
                    .subscribe(tasks => {
                        const matchedTasks: Task[] = [];

                        if (tasks && tasks.length > 0) {
                            tasks.map(task => {
                                if (!task.deleted_date && !task.finished_at) {
                                    const startDate = moment(
                                        task.start_deadline ? task.start_deadline : task.created_date,
                                    )
                                        .format('YYYY-MM-DD HH:mm:ss');
                                    const endDate = moment(task.deadline)
                                        .format('YYYY-MM-DD HH:mm:ss');
                                    const creator = users.find(user => user.id === task.created_by);
                                    const solver = users.find(user => user.id === task.user_id);

                                    if (
                                        dateStart <= endDate &&
                                        dateEnd >= startDate &&
                                        creator &&
                                        solver
                                    ) {
                                        task.creator = creator;
                                        task.solver = solver;
                                        task.unixCreatedTime = Date.parse(task.created_date as string);
                                        task.unixUpdatedTime = task.updated_date
                                            ? Date.parse(task.updated_date as string)
                                            : null;
                                        task.unixDeadlineTime = Date.parse(task.deadline);
                                        matchedTasks.push(task);
                                    }
                                }
                            });
                        }

                        data.task_indue = matchedTasks;
                        this.resultVacationCheckSource.next(data);
                    });

                this.dataService.getHotlinesData(creatorId)
                    .subscribe(hotlines => {
                        const matchedHotlines: Hotline[] = [];

                        if (hotlines && hotlines.length > 0) {
                            hotlines.map(hotline => {
                                if (!hotline.deleted_date) {
                                    const startDate = moment(hotline.start_at)
                                        .format(
                                            'YYYY-MM-DD HH:mm:ss',
                                        );
                                    const endDate = moment(hotline.end_at)
                                        .format(
                                            'YYYY-MM-DD HH:mm:ss',
                                        );

                                    if (dateStart <= endDate && dateEnd >= startDate) {
                                        matchedHotlines.push(hotline);
                                    }
                                }
                            });
                        }

                        data.hotline_indue = matchedHotlines;
                        this.resultVacationCheckSource.next(data);
                    });

                this.dataService.getVacationsData(creatorId)
                    .subscribe(vacations => {
                        const matchedVacations: Vacation[] = [];

                        if (vacations && vacations.length > 0) {
                            vacations.map(vacation => {
                                if (!vacation.deleted_date && !vacation.decline_at) {
                                    const startDate = moment(vacation.start_at)
                                        .format(
                                            'YYYY-MM-DD HH:mm:ss',
                                        );
                                    const endDate = moment(vacation.end_at)
                                        .format(
                                            'YYYY-MM-DD HH:mm:ss',
                                        );

                                    if (dateStart <= endDate && dateEnd >= startDate) {
                                        matchedVacations.push(vacation);
                                    }
                                }
                            });
                        }

                        data.vacation_indue = matchedVacations;
                        this.resultVacationCheckSource.next(data);
                    });
            }
        });
    }

    getLogs(vacation: Vacation | number): Observable<VacationLogs[] | undefined> {
        const id = typeof vacation === 'number' ? vacation : vacation.id;
        const url = `${this.logsUrl}/${id}`;

        return this.http.get<VacationLogs[]>(url)
            .pipe(
                tap(() => {
                    this.log('fetched vacation logs');
                }),
                shareReplay(),
                catchError(this.handleError<VacationLogs[]>('getVacationLogs', [])),
            );
    }
}
