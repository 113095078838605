import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import {CdkDragDrop, moveItemInArray, transferArrayItem} from '@angular/cdk/drag-drop';
import {Tickets} from '@src/app/_models/ticket/tickets';
import {DataService} from '@src/app/_services/data.service';
import {addClass, extend} from '@syncfusion/ej2-base';
import {
    CardRenderedEventArgs,
    CardSettingsModel,
    ColumnsModel,
    DialogSettingsModel,
    KanbanComponent,
    SwimlaneSettingsModel,
} from '@syncfusion/ej2-angular-kanban';
import {User} from '@src/app/_models/user/user';
import {Department} from '@src/app/_models/department/department';
import {Tags} from '@src/app/_models/tags/tags';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';

interface CardData {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    Id: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    Title: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    Status: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    Summary: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    Type: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    Priority: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    Tags: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    Estimate: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    Assignee: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    RankId: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    Color: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    ClassName: string;
}

@UntilDestroy()
@Component({
    selector: 'app-ticket-drag-and-drop',
    templateUrl: './ticket-drag-and-drop.component.html',
    styleUrls: ['./ticket-drag-and-drop.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TicketDragAndDropComponent implements OnInit {
    live: true;

    cardsLoading = true;

    tickets: Tickets[] | undefined = [];

    cardData: CardData[] = [];

    kanbanData = extend([], this.cardData, {}, true) as object;

    columns: ColumnsModel[] = [
        {headerText: 'To Do', keyField: 'Open', allowToggle: true},
        {headerText: 'In Progress', keyField: 'InProgress', allowToggle: true},
        {headerText: 'In Review', keyField: 'Review', allowToggle: true},
        {headerText: 'Done', keyField: 'Close', allowToggle: true},
    ];

    cardSettings: CardSettingsModel = {
        headerField: 'Title',
        template: '#cardTemplate',
        selectionType: 'Multiple',
    };

    dialogSettings: DialogSettingsModel = {
        fields: [
            {text: 'ID', key: 'Title', type: 'TextBox'},
            {key: 'Status', type: 'DropDown'},
            {key: 'Assignee', type: 'DropDown'},
            {key: 'RankId', type: 'TextBox'},
            {key: 'Summary', type: 'TextArea'},
        ],
    };

    swimlaneSettings: SwimlaneSettingsModel = {keyField: 'Assignee'};

    @ViewChild('kanbanObj') kanbanObj: KanbanComponent;

    constructor(
        private readonly ref: ChangeDetectorRef,
        private readonly dataService: DataService,
    ) {
    }

    ngOnInit(): void {
        this.dataService.ticketSource.pipe(untilDestroyed(this))
            .subscribe(
                (tickets: Tickets[] | undefined) => {
                    this.tickets = tickets;

                    if (this.tickets && this.tickets.length > 0) {
                        this.tickets.map((ticket: Tickets) => {
                            const tags: string[] = [];

                            ticket.tags.map((tag: Tags) => {
                                tags.push(tag.name);
                            });

                            ticket.users.map((user: User) => {
                                if (!ticket.deleted_date && !ticket.finished_at) {
                                    this.cardData.push({
                                        Id: ticket.id,
                                        Title: `Ticket - ${ticket.id}`,
                                        Status: 'Open',
                                        Summary: ticket.subject,
                                        Type: 'Story',
                                        Priority: 'Low',
                                        Tags: tags.toString(),
                                        Estimate: 3.5,
                                        Assignee: user.fullname,
                                        RankId: 1,
                                        Color: '#ffbb33',
                                        ClassName: 'e-story, e-low, e-nancy-davloio',
                                    });
                                } else if (ticket.finished_at) {
                                    this.cardData.push({
                                        Id: ticket.id,
                                        Title: `Ticket - ${ticket.id}`,
                                        Status: 'Validate',
                                        Summary: ticket.subject,
                                        Type: 'Story',
                                        Priority: 'Low',
                                        Tags: tags.toString(),
                                        Estimate: 3.5,
                                        Assignee: user.fullname,
                                        RankId: 1,
                                        Color: '#00C851',
                                        ClassName: 'e-story, e-low, e-nancy-davloio',
                                    });
                                }
                            });

                            ticket.departments.map((department: Department) => {
                                if (!ticket.deleted_date && !ticket.finished_at) {
                                    this.cardData.push({
                                        Id: ticket.id,
                                        Title: `Ticket - ${ticket.id}`,
                                        Status: 'Open',
                                        Summary: ticket.subject,
                                        Type: 'Story',
                                        Priority: 'Low',
                                        Tags: tags.toString(),
                                        Estimate: 3.5,
                                        Assignee: department.name,
                                        RankId: 1,
                                        Color: '#ffbb33',
                                        ClassName: 'e-story, e-low, e-nancy-davloio',
                                    });
                                } else if (ticket.finished_at) {
                                    this.cardData.push({
                                        Id: ticket.id,
                                        Title: `Ticket - ${ticket.id}`,
                                        Status: 'Validate',
                                        Summary: ticket.subject,
                                        Type: 'Story',
                                        Priority: 'Low',
                                        Tags: tags.toString(),
                                        Estimate: 3.5,
                                        Assignee: department.name,
                                        RankId: 1,
                                        Color: '#00C851',
                                        ClassName: 'e-story, e-low, e-nancy-davloio',
                                    });
                                }
                            });
                        });
                    }

                    this.kanbanData = extend([], this.cardData, {}, true) as object[];
                    this.cardsLoading = false;
                },
                error => {
                    console.error(error);
                },
            );
    }

    getString(assignee: string): string | undefined {
        const match = assignee.match(/\b(\w)/g);

        if (match) {
            return match.join('')
                .toUpperCase();
        }
    }

    cardRendered(args: CardRenderedEventArgs): void {
        const val: string = (args.data as {[key: string]: never}).Priority as string;

        addClass([args.element], val);
    }

    dropTodo(event: CdkDragDrop<never[]>): void {
        if (event.previousContainer === event.container) {
            moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
        } else {
            transferArrayItem(
                event.previousContainer.data,
                event.container.data,
                event.previousIndex,
                event.currentIndex,
            );
        }
    }

    dropDone(event: CdkDragDrop<never[]>): void {
        if (event.previousContainer === event.container) {
            moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
        } else {
            transferArrayItem(
                event.previousContainer.data,
                event.container.data,
                event.previousIndex,
                event.currentIndex,
            );
        }
    }

    dropDelete(event: CdkDragDrop<never[]>): void {
        if (event.previousContainer === event.container) {
            moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
        } else {
            transferArrayItem(
                event.previousContainer.data,
                event.container.data,
                event.previousIndex,
                event.currentIndex,
            );
        }
    }
}
