import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    HostListener,
    Input,
    OnChanges,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {AbstractControl, FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {DataService} from '@src/app/_services/data.service';
import {PermissionService} from '@src/app/_services/permission.service';
import {AuthenticationService} from '@src/app/_services/authentication.service';
import {MessageService} from '@src/app/_services/message.service';
import {Observable} from 'rxjs';
import {
    FileInfo,
    NumericTextBoxComponent,
    RemovingEventArgs,
    SelectedEventArgs,
    UploaderComponent,
} from '@syncfusion/ej2-angular-inputs';
import {DropDownListComponent, FilteringEventArgs} from '@syncfusion/ej2-angular-dropdowns';
import {User} from '@src/app/_models/user/user';
import {MaterialBudget} from '@src/app/_models/material/material-budget';
import {OrderItem} from '@src/app/_models/material/material-item';
import {MaterialPayment} from '@src/app/_models/material/material-payment';
import {Department} from '@src/app/_models/department/department';
import {MaterialCategory} from '@src/app/_models/material/material-category';
import {EmitType, isNullOrUndefined} from '@syncfusion/ej2-base';
import {Query} from '@syncfusion/ej2-data';
import moment from 'moment';
import {Material} from '@src/app/_models/material/material';
import {DialogComponent} from '@syncfusion/ej2-angular-popups';
import {ComponentCanDeactivate} from '@src/app/_guards/changes.guard';
import {MaterialFile} from '@src/app/_models/material/material-file';
import {EnvironmentService} from '@src/app/_services/environment.service';
import {FilePath} from '@src/app/features/hotline/hotline.service';
import {
    BudgetInput,
    MaterialService,
    OrderInput,
    OrderItemInput,
} from '@src/app/features/material/material.service';

@UntilDestroy()
@Component({
    selector: 'app-material-form',
    templateUrl: './material-form.component.html',
    styleUrls: ['./material-form.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MaterialFormComponent implements OnInit, OnChanges, ComponentCanDeactivate {
    // Dialogs
    buttonsDeleteFileDialog = [
        {
            click: this.removeMaterialFile.bind(this),
            buttonModel: {
                content: 'OK',
                cssClass: 'e-success e-outline',
                isFlat: false,
            },
        },
        {
            click: (): void => {
                this.deleteFileDialogObj.hide();
            },
            buttonModel: {content: 'ZRUŠIT', cssClass: 'e-danger e-outline'},
        },
    ];

    // Dropdowms
    height = '240px';

    fields: object = {text: 'label', value: 'value'};

    paymentSelect: Array<{value: boolean | number | string; label: string}> = [];

    departmentSelect: Array<{value: boolean | number | string; label: string}> = [];

    categorySelect: Array<{value: boolean | number | string; label: string}> = [];

    // Upload
    path: FilePath;

    autoUpload = false;

    dropElement: HTMLElement = document.getElementsByClassName('control-fluid')[0] as HTMLElement;

    removeFile: MaterialFile | undefined;

    deleteFileID: number;

    // Forms
    materialForm: FormGroup;

    orderItemsList: FormArray;

    isDirty = false;

    isChecked = false;

    submited = false;

    // Variables
    currentUser: User | null;

    hintText = '';

    departmentId: number;

    emptyItems = false;

    budget?: MaterialBudget;

    order: OrderInput;

    orderItems?: OrderItem[];

    items: OrderItemInput[] = [];

    budgets?: MaterialBudget[];

    budgetsSummary: BudgetInput[] = [];

    payments?: MaterialPayment[];

    // Loaders
    loadingMaterial = false;

    @Input() material: Material | null = null;

    @Input() isCreate = false;

    @Input() isUpdate = false;

    @Input() isVisible = false;

    @Input() isCopy = false;

    @Output() readonly materialFormDialogState = new EventEmitter<boolean>();

    @ViewChild('PriceInput') priceInputObj?: NumericTextBoxComponent;

    @ViewChild('PieceInput') pieceInputObj?: NumericTextBoxComponent;

    @ViewChild('hintArea') hintArea?: HTMLElement;

    // Dialogs
    @ViewChild('formDialog') formDialogObj: DialogComponent;

    @ViewChild('deleteFileDialog') deleteFileDialogObj: DialogComponent;

    // Dropdowms
    @ViewChild('payment') paymentObj?: DropDownListComponent;

    @ViewChild('department') departmentObj?: DropDownListComponent;

    @ViewChild('category') categoryObj?: DropDownListComponent;

    // Upload
    @ViewChild('preloaduploadMaterial') uploadObj?: UploaderComponent;

    constructor(
        private readonly router: Router,
        private readonly route: ActivatedRoute,
        private readonly ref: ChangeDetectorRef,
        private readonly formBuilder: FormBuilder,
        private readonly dataService: DataService,
        private readonly materialService: MaterialService,
        private readonly permissionService: PermissionService,
        private readonly authenticationService: AuthenticationService,
        private readonly messages: MessageService,
        private readonly environmentService: EnvironmentService,
    ) {
        this.currentUser = this.authenticationService.currentUserValue;
        this.path = {
            saveUrl: `${this.environmentService.backendURL}/api/upload/material?type=material`,
            removeUrl: '',
        };
    }

    get f(): {[key: string]: AbstractControl} {
        return this.materialForm.controls;
    }

    get orderItemsFormGroup(): FormArray {
        return this.materialForm.get('orderItems') as FormArray;
    }

    @HostListener('window:beforeunload')
    canDeactivate(): Observable<boolean> | boolean {
        return !this.isDirty;
    }

    ngOnInit(): void {
        this.dataService.departmentSource.pipe(untilDestroyed(this))
            .subscribe({
                next: (data: Department[]) => {
                    this.departmentSelect = [];
                    data.map((department: Department) => {
                        if (!department.deleted_date && department.budgets.length > 0) {
                            this.departmentSelect = [
                                ...this.departmentSelect,
                                {
                                    value: department.id,
                                    label: department.company.name + ' - ' + department.name,
                                },
                            ];
                        }
                    });
                    this.ref.markForCheck();
                },
                error: error => {
                    console.error(error);
                },
            });

        this.dataService.materialCategorySource.pipe(untilDestroyed(this))
            .subscribe({
                next: (data: MaterialCategory[]) => {
                    this.categorySelect = [];
                    data.map((category: MaterialCategory) => {
                        if (!category.deleted_date) {
                            this.categorySelect = [
                                ...this.categorySelect,
                                {
                                    value: category.id,
                                    label: category.name,
                                },
                            ];
                        }
                    });
                    this.ref.markForCheck();
                },
                error: error => {
                    console.error(error);
                },
            });

        this.materialService
            .getPayments()
            .pipe(untilDestroyed(this))
            .subscribe({
                next: (payments: MaterialPayment[]) => {
                    this.payments = payments;
                    this.paymentSelect = [];
                    payments.map((payment: MaterialPayment) => {
                        if (!payment.deleted_date) {
                            this.paymentSelect = [
                                ...this.paymentSelect,
                                {value: payment.id, label: payment.name},
                            ];
                        }
                    });
                    this.ref.markForCheck();
                },
                error: error => {
                    console.error(error);
                },
            });

        this.dataService.budgetSource.pipe(untilDestroyed(this))
            .subscribe({
                next: (budgets: MaterialBudget[]) => {
                    this.budgets = budgets;
                    this.ref.markForCheck();
                },
                error: error => {
                    console.error(error);
                },
            });

        this.materialForm = this.formBuilder.group({
            orderItems: this.formBuilder.array([this.createOrder()]),
            reason: ['', Validators.required],
            note: [''],
            payment: ['', Validators.required],
            files: [null],
            to_storage: ['to_storage', Validators.required],
            receipt: [null],
        });

        this.materialForm.valueChanges.pipe(untilDestroyed(this))
            .subscribe(() => {
                this.isDirty = this.materialForm.dirty;
                this.showInputErrors();
            });

        (this.materialForm.get('orderItems') as FormArray).valueChanges
            .pipe(untilDestroyed(this))
            .subscribe({
                next: items => {
                    items.forEach((item, index) => {
                        if (item.department_id) {
                            this.budget = this.setBudget(item.department_id as number);

                            if (this.budget) {
                                item.budget = {
                                    id: this.budget.id,
                                    creator_id: this.budget.creator.id,
                                    creator: this.budget.creator,
                                    name: this.budget.name,
                                    amount: this.budget.amount,
                                    start_at: this.budget.start_at,
                                    expire_at: this.budget.expire_at,
                                    department: this.budget.department,
                                    created_date: this.budget.created_date,
                                    approvers: this.budget.approvers,
                                    budgetitems: this.budget.budgetitems,
                                };
                                items[index] = item;
                                this.recalcSummary();
                            }
                        }
                    });
                },
            });

        this.orderItemsList = this.materialForm.get('orderItems') as FormArray;

        this.dataService.setDepartmentDataSource();
        this.dataService.setBudgetsDataSource();
        this.dataService.setMaterialsCategoryDataSource();
    }

    ngOnChanges(): void {
        if (this.isVisible) {
            this.uploadObj?.clearAll();

            const queryParams: Params = {form: null};

            void this.router.navigate([], {
                relativeTo: this.route,
                queryParams,
                queryParamsHandling: 'merge',
            });

            if (this.material && this.isUpdate) {
                this.materialForm.reset();
                this.formDialogObj.header = `Objednávka #${this.material.id}`;
                this.fillTheForm();
                this.showInputErrors();
            }

            if (this.isCreate) {
                this.formDialogObj.header = 'Nová objednávka';
                this.materialForm.controls.to_storage.patchValue('to_storage');
            }

            if (this.isCopy) {
                this.materialForm.reset();
                this.formDialogObj.header = 'Nová objednávka';
                this.fillTheForm();
                this.showInputErrors();
            }
        }
    }

    changeDialogState(value: boolean): void {
        this.materialFormDialogState.emit(value);
    }

    createOrder(items?: OrderItemInput): FormGroup {
        return this.formBuilder.group({
            name: [items?.name ? items.name : '', Validators.compose([Validators.required])],
            amount: [
                items?.amount ? items.amount : 1,
                Validators.compose([Validators.required, Validators.min(1)]),
            ],
            price: [
                items?.price ? items.price : 0.0,
                Validators.compose([Validators.required, Validators.min(0.01)]),
            ],
            dph: [!!items?.dph],
            department_id: [
                items?.department_id ? items.department_id : null,
                Validators.compose([Validators.required]),
            ],
            item_id: [items?.item_id ? items.item_id : null],
            material_id: [this.material ? this.material.id : null],
            category_id: [
                items?.category_id ? items.category_id : null,
                Validators.compose([Validators.required]),
            ],
        });
    }

    getOrderItemsFormGroup(index): FormGroup {
        return this.orderItemsList.controls[index] as FormGroup;
    }

    addItem(): void {
        this.orderItemsList.push(this.createOrder());
        this.emptyItems = false;
        this.ref.markForCheck();
    }

    removeItem(index: number): void {
        this.orderItemsList.removeAt(index);

        if (this.orderItemsList.value.length === 0) {
            const body = 'Rozpočet nepřiřazen k objednávce';
            const options = {progressBar: true, timeOut: 5000, toastClass: 'red'};

            this.messages.showError('Musíte zadat položky objednávky', body, options);
            this.emptyItems = true;
            this.ref.markForCheck();
        }
    }

    onFilteringCategory: EmitType<FilteringEventArgs> = (e: FilteringEventArgs) => {
        if (e.text === '') {
            e.updateData(this.categorySelect);
        } else {
            let query: Query = new Query();

            query = e.text !== '' ? query.where('label', 'contains', e.text, true, true) : query;
            e.updateData(this.categorySelect, query);
        }
    };

    onFilteringPayments: EmitType<FilteringEventArgs> = (e: FilteringEventArgs) => {
        if (e.text === '') {
            e.updateData(this.paymentSelect);
        } else {
            let query: Query = new Query();

            query = e.text !== '' ? query.where('label', 'contains', e.text, true, true) : query;
            e.updateData(this.paymentSelect, query);
        }
    };

    onFilteringDepartments: EmitType<FilteringEventArgs> = (e: FilteringEventArgs) => {
        if (e.text === '') {
            e.updateData(this.departmentSelect);
        } else {
            let query: Query = new Query();

            query = e.text !== '' ? query.where('label', 'contains', e.text, true, true) : query;
            e.updateData(this.departmentSelect, query);
        }
    };

    setBudget(department: Department | number): MaterialBudget | undefined {
        const departmentId = typeof department === 'number' ? department : department.id;
        const matchedBudget = this.budgets?.find(
            budget =>
                !budget.deleted_date &&
                budget.department_id === departmentId &&
                moment()
                    .format('YYYY-MM-DD HH:mm:ss') >=
                moment(budget.start_at)
                    .format('YYYY-MM-DD HH:mm:ss') &&
                moment()
                    .format('YYYY-MM-DD HH:mm:ss') <
                moment(budget.expire_at)
                    .format('YYYY-MM-DD HH:mm:ss'),
        );

        if (!matchedBudget) {
            const body = 'Rozpočet nedohledán';
            const options = {progressBar: true, timeOut: 5000, toastClass: 'red'};

            this.messages.showError('Chyba při hledání rozpočtu...', body, options);
        } else {
            return matchedBudget;
        }

        this.ref.markForCheck();
    }

    recalcSummary(): void {
        this.budgetsSummary = [];

        const items = this.orderItemsList.value;

        items.forEach(
            (item: {
                id: number;

                // eslint-disable-next-line @typescript-eslint/naming-convention
                budget_id?: number | null;

                // eslint-disable-next-line @typescript-eslint/naming-convention
                department_id: number;

                // eslint-disable-next-line @typescript-eslint/naming-convention
                category_id: number;

                // eslint-disable-next-line @typescript-eslint/naming-convention
                material_id: number;

                name: string;

                amount: number;

                price: number;

                dph: boolean;

                category: MaterialCategory;

                budget?: MaterialBudget | null;

                department: Department;

                order: Material;

                // eslint-disable-next-line @typescript-eslint/naming-convention
                created_date: string;

                // eslint-disable-next-line @typescript-eslint/naming-convention
                updated_date?: string | null;

                // eslint-disable-next-line @typescript-eslint/naming-convention
                deleted_date?: string | null;

                declined: boolean;

                confirmed: boolean;

                status: string;
            }) => {
                let cashBudgetTotal = 0;

                if (item.budget) {
                    if (!this.budgetsSummary.find(t => item.budget && t.id === item.budget.id)) {
                        item.budget.totalCountItem = item.budget.budgetitems.filter(
                            (orderItem: OrderItem) => orderItem.order.to_storage,
                        ).length;
                        item.budget.startUnix = moment(item.budget.start_at)
                            .valueOf();
                        item.budget.endUnix = moment(item.budget.expire_at)
                            .valueOf();
                        item.budget.budgetitems.forEach((data: OrderItem) => {
                            if (data.order.to_storage) {
                                cashBudgetTotal += data.dph
                                    ? data.price - data.price * 0.21
                                    : data.price;
                            }
                        });
                        item.budget.totalCountPrice = cashBudgetTotal;
                        this.budgetsSummary.push({
                            id: item.budget.id,
                            created_by: item.budget.creator.id,
                            creator: item.budget.creator,
                            name: item.budget.name,
                            amount: item.budget.amount,
                            expenditures: {
                                items: item.budget.totalCountItem,
                                price: item.budget.totalCountPrice,
                            },
                            expenditures_current: {
                                price:
                                    item.dph && item.price
                                        ? item.price - item.price * 0.21
                                        : item.price
                                            ? item.price
                                            : 0.0,
                                items: 1,
                            },
                            start_at: item.budget.start_at,
                            expire_at: item.budget.expire_at,
                            department: item.budget.department,
                            created_date: item.budget.created_date,
                            approvers: item.budget.approvers,
                            budgetitems: item.budget.budgetitems,
                        });
                        this.ref.markForCheck();
                    } else {
                        const expenditurerCurrent = this.budgetsSummary.find(
                            t => item.budget && t.id === item.budget.id,
                        )?.expenditures_current;

                        if (expenditurerCurrent) {
                            expenditurerCurrent.price += item.dph
                                ? item.price - item.price * 0.21
                                : item.price;

                            expenditurerCurrent.items += 1;
                            this.ref.markForCheck();
                        }
                    }
                }
            },
        );
        this.ref.markForCheck();
    }

    showHint(hint: string): void {
        this.hintText = hint;
        this.ref.markForCheck();
    }

    fillTheForm(): void {
        this.materialForm.controls.reason.patchValue(this.material?.reason);
        this.materialForm.controls.note.patchValue(this.material?.note);
        this.materialForm.controls.payment.patchValue(this.material?.payment_id);
        this.materialForm.controls.to_storage.patchValue(
            this.material?.to_storage ? 'to_storage' : 'for_sale',
        );
        this.orderItemsList = this.materialForm.get('orderItems') as FormArray;

        while (this.orderItemsList.length) {
            this.orderItemsList.removeAt(0);
        }

        this.items = [];
        this.material?.orderitems.map((item: OrderItem) => {
            this.items.push({
                name: item.name,
                amount: item.amount,
                price: item.price,
                dph: !!item.dph,
                department_id: item.department_id,
                category_id: item.category_id,
                item_id: item.id,
                material_id: this.material?.id,
            });
        });

        this.items.forEach(order => {
            this.orderItemsList.push(this.createOrder(order));
        });

        this.materialForm
            .get('orderItems')
            ?.setValue(this.items, {onlySelf: true, emitEvent: false});
    }

    onSubmit(): void {
        this.submited = true;
        this.isDirty = false;
        this.loadingMaterial = true;

        if (this.isCreate || this.isCopy) {
            this.createMaterial();
        }

        if (this.isUpdate) {
            this.editMaterial();
        }
    }

    createMaterial(): void {
        if (this.materialForm.invalid) {
            console.error('form is not valid!');

            return;
        }

        const confirmers: User[] = [];

        this.orderItems = this.orderItemsList.value;

        this.orderItems?.forEach((item: OrderItem, index) => {
            if (this.orderItems) {
                this.orderItems[index].budget_id = item.budget.id;
                this.orderItems[index].dph = !!item.dph;
                item.budget.approvers.forEach((approver: User) => {
                    if (!confirmers.find(x => x.id === approver.id)) {
                        confirmers.push(approver);
                    }
                });
            }
        });

        this.order = {
            reason: this.f.reason.value,
            to_storage: this.f.to_storage.value === 'to_storage',
            payment_id: this.f.payment.value,
            note: this.f.note.value,
            orderitems: this.orderItems,
            created_by: this.currentUser?.id,
            confirmers,
        };

        this.materialService
            .addInvoice(this.order)
            .pipe(untilDestroyed(this))
            .subscribe({
                next: (data: Material) => {
                    if (
                        this.currentUser &&
                        this.uploadObj &&
                        this.uploadObj.getFilesData().length > 0
                    ) {
                        this.uploadObj.asyncSettings.saveUrl = `${this.environmentService.backendURL}/api/upload/material?type=material&id=${data.id}&created_by=${this.currentUser.id}`;

                        for (const file of this.uploadObj.getFilesData()) {
                            file.id = data.id.toString();
                            this.uploadObj.upload(file);
                        }
                    }

                    const body = 'Objednávka zařazena do fronty ke schválení...';
                    const options = {progressBar: true, timeOut: 5000};

                    this.messages.showSuccess(
                        'Objednávka materiálu úspěšně vytvořena',
                        body,
                        options,
                    );
                    this.loadingMaterial = false;
                    this.ref.markForCheck();
                    this.dataService.clearMaterialsItemsCache();
                    this.dataService.setMaterialsDataSource();
                    void this.router.navigate(['/material/detail', data.id]);
                },
                error: error => {
                    console.error(error);

                    const body = 'Zkuste to znovu později';
                    const options = {progressBar: true, timeOut: 5000};

                    this.messages.showError(
                        'Chyba při vytváření objednávky materiálu,',
                        body,
                        options,
                    );
                    this.loadingMaterial = false;
                    this.ref.markForCheck();
                },
            });
    }

    editMaterial(): void {
        if (this.materialForm.invalid) {
            console.error('form is not valid!');

            return;
        }

        const confirmers: User[] = [];

        this.orderItems = this.orderItemsList.value;
        this.items.forEach((item: OrderItemInput, index: number) => {
            if (this.orderItems?.[index] && item.budget?.id) {
                this.orderItems[index].budget_id = item.budget.id;
                item.budget.approvers?.forEach((approver: User) => {
                    if (!confirmers.find(x => x.id === approver.id)) {
                        confirmers.push(approver);
                    }
                });
            }
        });

        this.order = {
            id: this.material?.id,
            reason: this.f.reason.value,
            to_storage: this.f.to_storage.value === 'to_storage',
            payment_id: this.f.payment.value,
            note: this.f.note.value,
            orderitems: this.orderItems,
            confirmers,
            updated_by: this.currentUser?.id,
        };

        this.materialService
            .updateInvoice(this.order)
            ?.pipe(untilDestroyed(this))
            .subscribe({
                next: (data: Material) => {
                    if (
                        this.currentUser &&
                        this.uploadObj &&
                        this.uploadObj.getFilesData().length > 0
                    ) {
                        this.uploadObj.asyncSettings.saveUrl = `this.environmentService.backendURL/api/upload/material?type=material&id=${data.id}&created_by=${this.currentUser.id}`;

                        for (const file of this.uploadObj.getFilesData()) {
                            file.id = data.id.toString();
                            this.uploadObj.upload(file);
                        }
                    }

                    const body = `Současný stav objednávky #${data.id} vyresetován...`;
                    const options = {progressBar: true, timeOut: 5000};

                    this.messages.showSuccess(
                        'Objednávka materiálu úspěšně upravena',
                        body,
                        options,
                    );
                    this.loadingMaterial = false;
                    this.formDialogObj.hide();
                    this.dataService.setMaterialsDataSource();
                    this.dataService.setMaterialDetailDataSource(data.id);
                    this.ref.markForCheck();
                },
                error: error => {
                    console.error(error);

                    const body = 'Zkuste to znovu později';
                    const options = {progressBar: true, timeOut: 5000};

                    this.messages.showError(
                        'Chyba při úpravě objednávky materiálu,',
                        body,
                        options,
                    );
                    this.loadingMaterial = false;
                    this.ref.markForCheck();
                },
            });
    }

    // Files
    onFileSelected(args: SelectedEventArgs): void {
        if (this.uploadObj) {
            // Filter the 5 files only to showcase
            args.filesData.splice(5);

            const filesData: FileInfo[] = this.uploadObj.getFilesData();
            const allFiles: FileInfo[] = filesData.concat(args.filesData);

            if (allFiles.length > 5) {
                // eslint-disable-next-line @typescript-eslint/prefer-for-of
                for (let i = 0; i < allFiles.length; i++) {
                    if (allFiles.length > 5) {
                        allFiles.shift();
                    }
                }

                args.filesData = allFiles;
                // set the modified custom data
                args.modifiedFilesData = args.filesData;
            }

            let existingFiles: FileInfo[] = this.uploadObj.getFilesData();

            for (let i = 0; i < args.filesData.length; i++) {
                // eslint-disable-next-line @typescript-eslint/prefer-for-of
                for (let j = 0; j < existingFiles.length; j++) {
                    if (!isNullOrUndefined(args.filesData[i])) {
                        // eslint-disable-next-line eqeqeq
                        if (existingFiles[j].name == args.filesData[i].name) {
                            args.filesData.splice(i, 1);
                        }
                    }
                }
            }

            existingFiles = existingFiles.concat(args.filesData);
            args.modifiedFilesData = existingFiles;
            args.isModified = true;
        }
    }

    onFileRemove(args: RemovingEventArgs): void {
        args.postRawFile = false;
    }

    removeMaterialFile(): void {
        this.loadingMaterial = true;

        if (this.deleteFileID) {
            this.removeFile = this.material?.receipts.find(x => x.id === this.deleteFileID);

            if (this.removeFile) {
                this.removeFile.deleted_by = this.currentUser?.id;
                this.materialService
                    .deleteFile(this.removeFile)
                    .pipe(untilDestroyed(this))
                    .subscribe({
                        next: data => {
                            if (data) {
                                const body = `Soubor #${data.id}`;
                                const options = {progressBar: true, timeOut: 5000};

                                this.messages.showSuccess('Soubor úspěšně smazán', body, options);
                                this.dataService.setMaterialsDataSource();

                                if (this.material) {
                                    this.dataService.setMaterialDetailDataSource(this.material.id);
                                }

                                this.loadingMaterial = false;
                                this.deleteFileDialogObj.hide();
                                this.ref.markForCheck();
                            } else {
                                alert('Chyba během mazání souboru.');
                                this.loadingMaterial = false;
                                this.ref.markForCheck();
                            }
                        },
                        error: error => {
                            alert('Chyba během mazání souboru.');
                            this.loadingMaterial = false;
                            this.ref.markForCheck();
                            console.error(error);
                        },
                    });
            }
        }
    }

    showInputErrors(): void {
        this.isChecked = true;
        /* console.log('VALID: ' + this.ticketForm.valid);
        Object.keys(this.ticketForm.controls).forEach(key => {
            const controlErrors: ValidationErrors = this.ticketForm.get(key).errors;
            if (controlErrors != null) {
                Object.keys(controlErrors).forEach(keyError => {
                    console.log('Key control: ' + key + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError]);
                });
            }
        });*/
        this.materialForm.markAllAsTouched();
        this.ref.markForCheck();
    }
}
