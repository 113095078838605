<div
  *ngIf="loadingHotliner"
  style="
        display: block !important;
        z-index: 1000000;
        width: 250px;
        height: 250px;
        margin: auto;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    "
>
  <div class="loader">
    <span></span>
    <span></span>
    <span></span>
    <span></span>
  </div>
  <div
    class="lds-circle"
    style="
            margin: auto;
            position: absolute;
            width: 150px;
            height: auto;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        "
  >
    <div></div>
  </div>
</div>
<div class="row d-flex justify-content-center modalWrapper">
  <ejs-dialog
    #deleteHotlinerDialog
    [buttons]="buttonsDeleteHotlinerDialog"
    [content]="'Opravdu chcete smazat označeného hot-linera?'"
    [header]="'Smazat hot-linera'"
    [height]="'200px'"
    [isModal]="true"
    [showCloseIcon]="true"
    [visible]="false"
    [width]="'450px'"
    id="deleteHotlinerDialog"
  ></ejs-dialog>
  <ejs-dialog
    #activateHotlinerDialog
    [buttons]="buttonsActivateHotlinerDialog"
    [content]="'Opravdu chcete obnovit tohoto hotlinera?'"
    [header]="'Obnovit hot-linera'"
    [height]="'200px'"
    [isModal]="true"
    [showCloseIcon]="true"
    [visible]="false"
    [width]="'450px'"
    id="activateHotlinerDialog"
  ></ejs-dialog>
  <ejs-dialog
    #confirmationCancel
    [buttons]="buttonsCancel"
    [header]="'Nelze provést akci'"
    [isModal]="true"
    [showCloseIcon]="true"
    [visible]="false"
    width="250px"
  ></ejs-dialog>
  <ejs-dialog
    #formDialog
    (close)="
            isUpdate = false;
            isCreate = false;
            isDirty = false;
            isChecked = false
        "
    [isModal]="true"
    [showCloseIcon]="true"
    [visible]="false"
    [width]="'85%'"
    id="formDialog"
  >
    <form (ngSubmit)="onSubmit()" [formGroup]="hotlinerForm">
      <ng-template #content>
        <div class="dialogContent">
          <div class="row">
            <div class="form-group col-12 col-md-6 mb-1">
              <label
                [ngClass]="
                                    f.user_id.valid
                                        ? 'text-success'
                                        : f.user_id.invalid
                                        ? 'text-danger'
                                        : 'text-muted'
                                "
                for="user_id"
              >* Uživatel:</label
              >
              <ejs-dropdownlist
                #users
                (filtering)="onFilteringUsers($event)"
                [allowFiltering]="true"
                [dataSource]="usersSelect"
                [fields]="fields"
                [filterBarPlaceholder]="'Vyhledat uživatele...'"
                [ignoreAccent]="true"
                [ngClass]="
                                    (f.user_id.dirty || f.user_id.touched) &&
                                    f.user_id.invalid
                                        ? 'e-error'
                                        : (f.user_id.dirty ||
                                              f.user_id.touched) &&
                                          f.user_id.valid
                                        ? 'e-success'
                                        : ''
                                "
                [placeholder]="'Vyberte uživatele'"
                [popupHeight]="height"
                formControlName="user_id"
                id="user_id"
                sortOrder="Ascending"
              ></ejs-dropdownlist>
              <small
                *ngIf="
                                    f.user_id.errors &&
                                    f.user_id.errors.required
                                "
                class="text-danger"
              >Vyberte jednoho uživatele</small
              >
            </div>
            <div class="form-group col-12 col-md-6 mb-1">
              <ejs-checkbox
                #weekend
                [checked]="true"
                cssClass="e-primary p-0 m-0"
                formControlName="weekend"
                id="weekend"
                label="o víkendech"
              ></ejs-checkbox>
            </div>
          </div>
        </div>
      </ng-template>
      <ng-template #footerTemplate>
        <div class="d-flex justify-content-end">
                    <span
                      *ngIf="hotlinerForm.invalid"
                      class="e-badge e-badge-warning p-2 size_2"
                      role="alert"
                    >
                        <strong>Formulář je špatně vyplněný</strong>
                    </span>
          <span
            *ngIf="hotlinerForm.valid"
            class="e-badge e-badge-success p-2 size_2"
            role="alert"
          >
                        <strong>Formulář je správně vyplněný</strong>
                    </span>
          <button
            (click)="onSubmit()"
            [disabled]="
                            loadingHotliner ||
                            hotlinerForm.invalid ||
                            !isChecked
                        "
            cssClass="e-btn e-success btn-p-zero e-small mr-1"
            ejs-button
            type="submit"
          >
                        <span
                          *ngIf="loadingHotliner === true"
                          aria-hidden="true"
                          class="spinner-grow spinner-grow-sm"
                          role="status"
                        ></span>
            <span *ngIf="loadingHotliner === false">ULOŽIT</span>
            <span *ngIf="loadingHotliner === true"
            >Provádím...</span
            >
          </button>
          <button
            (click)="
                            formDialogObj.hide();
                            isDirty = false;
                            isChecked = false
                        "
            cssClass="e-btn e-danger e-outline btn-p-zero e-small"
            ejs-button
            type="button"
          >
            ZRUŠIT
          </button>
        </div>
      </ng-template>
    </form>
  </ejs-dialog>
</div>
<ejs-toolbar id="filterToolbar" overflowMode="Scrollable" scrollStep="500">
  <e-items>
    <e-item
      [cssClass]="'statusFilter'"
      tooltipText="Přednastavené hledání"
      type="input"
    >
      <ng-template #template>
        <ejs-dropdownlist
          #viewFilter
          (change)="onStatusFilterChange($event)"
          [dataSource]="categoryFilterData"
          [width]="500"
          placeholder="Vyberte přednastavený pohled..."
        ></ejs-dropdownlist>
      </ng-template>
    </e-item>
    <e-item type="Separator"></e-item>
    <e-item
      [cssClass]="'fulltextFilter'"
      tooltipText="Text pro vyhledávání"
      type="input"
    >
      <ng-template #template>
        <ejs-textbox
          #searchtext
          [width]="500"
          class="mr-2"
          placeholder="Vyhledat..."
        ></ejs-textbox>
        <button
          (click)="search()"
          class="e-btn e-info btn-p-zero e-small"
        >
          <i class="fa-light fa-lg fa-search mr-1"></i
          ><span class="d-none d-lg-inline">vyhledat</span>
        </button>
        <button
          (click)="cancelBtnClick()"
          class="e-btn e-danger btn-p-zero e-small"
        >
          <i class="fa-light fa-lg fa-times"></i>
        </button>
      </ng-template>
    </e-item>
  </e-items>
</ejs-toolbar>
<div class="control-section">
  <div class="scroll_wrapper1" id="scroll_wrapper1_hotliners">
    <div class="scroll_div" id="scroll_div_hotliners"></div>
  </div>
  <div class="scroll_wrapper2" id="scroll_wrapper2_hotliners">
    <div class="grid_parent" id="grid_parent_hotliners">
      <ejs-grid
        #hotlineUsersGrid
        (actionBegin)="actionBegin($event)"
        (actionComplete)="actionComplete($event)"
        (created)="onCreated()"
        (dataBound)="onDataBound()"
        (load)="onLoad()"
        (loadeddata)="setInitialGridFiltering()"
        (rowDataBound)="rowDataBound($event)"
        (rowDeselected)="rowSelected()"
        (rowSelected)="rowSelected()"
        (toolbarClick)="toolbarClick($event)"
        [allowExcelExport]="true"
        [allowFiltering]="true"
        [allowMultiSorting]="true"
        [allowPdfExport]="false"
        [allowSelection]="true"
        [allowSorting]="true"
        [dataSource]="data"
        [editSettings]="editSettings"
        [enableHover]="false"
        [enablePersistence]="false"
        [enableVirtualization]="true"
        [filterSettings]="filterSettings"
        [groupSettings]="groupOptions"
        [pageSettings]="pageOptions"
        [searchSettings]="searchSettings"
        [selectionSettings]="selectionSettings"
        [toolbar]="toolbar"
        allowGrouping="true"
        allowReordering="true"
        allowResizing="true"
        allowTextWrap="true"
        height="100%"
        id="hotlineUsersGrid"
        locale="cs"
        rowHeight="38"
        showColumnMenu="true"
        width="100%"
      >
        <e-columns>
          <e-column
            [allowEditing]="false"
            [allowFiltering]="false"
            [allowReordering]="false"
            [allowResizing]="false"
            [allowSorting]="false"
            [autoFit]="true"
            type="checkbox"
          ></e-column>
          <e-column
            [allowFiltering]="false"
            [allowReordering]="false"
            [allowResizing]="false"
            [autoFit]="true"
            [isPrimaryKey]="true"
            [visible]="false"
            field="id"
            headerText="ID"
          >
          </e-column>
          <e-column
            [allowEditing]="false"
            [allowFiltering]="false"
            [allowReordering]="false"
            [allowResizing]="false"
            [autoFit]="true"
            [visible]="false"
            field="user_id"
            headerText="User ID"
          ></e-column>
          <e-column
            [allowEditing]="false"
            [allowFiltering]="false"
            [allowReordering]="false"
            [allowResizing]="false"
            [autoFit]="true"
            [visible]="false"
            field="created_by"
            headerText="Creator ID"
          ></e-column>
          <e-column
            [autoFit]="true"
            [filter]="filterSettings"
            [textAlign]="'Left'"
            clipMode="EllipsisWithTooltip"
            field="user_str"
            headerText="Uživatel"
          >
            <ng-template #template let-data>
              <div>
                <img
                  *ngIf="data.user_img"
                  alt="profilový obrázek"
                  class="img-fluid rounded-circle avatar-xs mr-1"
                  src="assets/img{{ data.user_img }}"
                />
                <a
                  [routerLink]="[
                                        '/users/detail/',
                                        data.user_id
                                    ]"
                >
                  {{ data.user_str }}
                </a>
              </div>
            </ng-template>
          </e-column>
          <e-column
            [autoFit]="true"
            [filter]="filterSettings"
            [textAlign]="'Center'"
            clipMode="EllipsisWithTooltip"
            field="weekend"
            headerText="Víkendy"
          >
            <ng-template #template let-data>
                            <span
                              *ngIf="data.weekend"
                              class="e-badge e-badge-success"
                            >ANO</span
                            >
              <span
                *ngIf="!data.weekend"
                class="e-badge e-badge-danger"
              >NE</span
              >
            </ng-template>
          </e-column>
          <e-column
            [autoFit]="true"
            [filter]="filterSettings"
            [textAlign]="'Left'"
            clipMode="EllipsisWithTooltip"
            field="score"
            headerText="Skóre"
          >
            <ng-template #template let-data>
              {{ data.score }} bodů
            </ng-template>
          </e-column>
          <e-column
            [autoFit]="true"
            [filter]="filterSettings"
            [textAlign]="'Left'"
            clipMode="EllipsisWithTooltip"
            field="creator_str"
            headerText="Vytvořil"
          >
            <ng-template #template let-data>
              <div>
                <img
                  *ngIf="data.creator_img"
                  alt="profilový obrázek"
                  class="img-fluid rounded-circle avatar-xs mr-1"
                  src="assets/img{{ data.creator_img }}"
                />
                <a
                  [routerLink]="[
                                        '/users/detail/',
                                        data.created_by
                                    ]"
                >
                  {{ data.creator_str }}
                </a>
              </div>
            </ng-template>
          </e-column>
          <e-column
            [allowEditing]="false"
            [autoFit]="true"
            [filter]="filterDate"
            [format]="dateFormat"
            [textAlign]="'Right'"
            clipMode="EllipsisWithTooltip"
            editType="datepickeredit"
            field="created_date"
            headerText="Vytvořeno"
            type="date"
          ></e-column>
          <e-column
            [allowEditing]="false"
            [autoFit]="true"
            [filter]="filterDate"
            [format]="dateFormat"
            [textAlign]="'Right'"
            clipMode="EllipsisWithTooltip"
            editType="datepickeredit"
            field="updated_date"
            headerText="Naposledy upraveno"
            type="date"
          ></e-column>
          <e-column
            [allowEditing]="false"
            [autoFit]="true"
            [filter]="filterDate"
            [format]="dateFormat"
            [textAlign]="'Right'"
            clipMode="EllipsisWithTooltip"
            editType="datepickeredit"
            field="deleted_date"
            headerText="Smazáno"
            type="date"
          ></e-column>
        </e-columns>
        <e-aggregates>
          <e-aggregate>
            <e-columns>
              <e-column [format]="'N'" field="id" type="Count">
                <ng-template #footerTemplate let-data>
                  Celkem řádků: {{ data.Count }}
                </ng-template
                >
              </e-column>
              <e-column [format]="'N'" field="score" type="Sum">
                <ng-template #footerTemplate let-data>
                  Celkem bodů: {{ data.Sum }}
                </ng-template>
              </e-column>
            </e-columns>
          </e-aggregate>
          <e-aggregate>
            <e-columns>
              <e-column
                [format]="'N'"
                field="score"
                type="Average"
              >
                <ng-template #footerTemplate let-data>
                  Průměr bodů: {{ data.Average }}
                </ng-template>
              </e-column>
            </e-columns>
          </e-aggregate>
          <e-aggregate>
            <e-columns>
              <e-column [format]="'N'" field="score" type="Max">
                <ng-template #footerTemplate let-data>
                  Max bodů: {{ data.Max }}
                </ng-template>
              </e-column>
            </e-columns>
          </e-aggregate>
        </e-aggregates>
      </ejs-grid>
    </div>
  </div>
</div>
