<div class="row d-flex justify-content-center modalWrapper" id="dialogContainer">
  <ejs-dialog
    #deleteTaskDialog [buttons]="buttonsDeleteTaskDialog" [content]="'Opravdu chcete smazat označený úkol?'"
    [header]="'Smazat úkol z ticketu'" [height]="'200px'" [isModal]="true" [showCloseIcon]="true"
    [visible]="false" [width]="'450px'" id="deleteTaskDialog"
  ></ejs-dialog>
  <ejs-dialog
    #deleteTicketDialog [buttons]="buttonsDeleteTicketDialog" [content]="
            'Opravdu chcete smazat tento ticket? Tímto smažete i všechny úkoly tohoto ticketu.'
        " [header]="'Smazat ticket'" [height]="'200px'" [isModal]="true" [showCloseIcon]="true" [visible]="false"
    [width]="'450px'" id="deleteTicketDialog"
  ></ejs-dialog>
  <ejs-dialog
    #deleteUserDialog [buttons]="buttonsDeleteUserDialog" [content]="
            'Opravdu chcete smazat označené zapojení uživatele z ticketu?'
        " [header]="'Smazat zapojení uživatele z ticketu'" [height]="'200px'" [isModal]="true" [showCloseIcon]="true"
    [visible]="false" [width]="'450px'" id="deleteUserDialog"
  ></ejs-dialog>
  <ejs-dialog
    #deleteDepartmentDialog [buttons]="buttonsDeleteDepartmentDialog" [content]="
            'Opravdu chcete smazat označené zapojení oddělení z ticketu?'
        " [header]="'Smazat zapojení oddělení z ticketu'" [height]="'200px'" [isModal]="true" [showCloseIcon]="true"
    [visible]="false" [width]="'450px'" id="deleteDepartmentDialog"
  ></ejs-dialog>
  <ejs-dialog
    #deleteCommentDialog [buttons]="buttonsDeleteCommentDialog"
    [content]="'Opravdu chcete smazat označený komentář z diskuze ticketu?'"
    [header]="'Smazat komentář z ticketu'" [height]="'200px'" [isModal]="true" [showCloseIcon]="true"
    [visible]="false" [width]="'450px'" id="deleteCommentDialog"
  ></ejs-dialog>
  <ejs-dialog
    #deleteNoteDialog [buttons]="buttonsDeleteNoteDialog" [content]="
            'Opravdu chcete smazat označenou poznámku z postupů v ticketu?'
        " [header]="'Smazat poznámnku postupu z ticketu'" [height]="'200px'" [isModal]="true" [showCloseIcon]="true"
    [visible]="false" [width]="'450px'" id="deleteNoteDialog"
  ></ejs-dialog>
  <ejs-dialog
    #activeTicketDialog [buttons]="buttonsActiveTicketDialog"
    [content]="'Tímto vrátíte ticket k opětovnému vyřešení'" [header]="'Obnovit ticket'" [height]="'200px'"
    [isModal]="true" [showCloseIcon]="true" [visible]="false" [width]="'450px'" id="activeTicketDialog"
  ></ejs-dialog>
  <app-ticket-form
    (ticketFormDialogState)="
            renderTicketFormDialog = $event;
            isCreate = $event;
            isUpdate = $event;
            isCopy = $event;
            gridLogs?.clearRowSelection();
            disableToolBarBtn();
            gridTasks?.clearRowSelection();
            disableToolBarBtn()
        " [isCopy]="isCopy" [isCreate]="false" [isUpdate]="isUpdate" [isVisible]="renderTicketFormDialog"
    [ticket]="ticket && (isUpdate || isCopy) ? ticket : null"
  ></app-ticket-form>
  <app-task-form
    (taskFormDialogState)="
            renderTaskFormDialog = $event;
            isTaskCreate = $event;
            isTaskUpdate = $event;
            isTaskCopy = $event
        " [isCopy]="isTaskCopy" [isCreate]="isTaskCreate" [isUpdate]="isTaskUpdate" [isVisible]="renderTaskFormDialog"
    [task]="
            clickedRow && clickedRow[0] && (isTaskUpdate || isTaskCopy)
                ? clickedRow[0]
                : null
        " [ticket]="ticket ? ticket : null"
  ></app-task-form>
  <app-ticket-note-form
    (ticketNoteFormDialogState)="
            renderTicketNoteFormDialog = $event;
            isCreateNote = $event;
            isUpdateNote = $event;
            isCopyNote = $event
        " [isCopy]="isCopyNote" [isCreate]="isCreateNote" [isUpdate]="isUpdateNote"
    [isVisible]="renderTicketNoteFormDialog" [note]="highlightedNote" [ticket]="ticket"
  ></app-ticket-note-form>
  <app-ticket-solving-form
    (ticketNoteFormDialogState)="
            renderTicketSolutionFormDialog = $event;
            isCreateSolution = $event;
            isUpdateSolution = $event;
            isCopySolution = $event" [isCopy]="isCopySolution" [isCreate]="isCreateSolution"
    [isUpdate]="isUpdateSolution" [isVisible]="renderTicketSolutionFormDialog" [ticket]="ticket"
  ></app-ticket-solving-form>
  <app-ticket-comment-form
    (tickeCommentFormDialogState)="
            renderTicketCommentFormDialog = $event;
            isCreateComment = $event;
            isUpdateComment = $event;
            isCopyComment = $event
        " [comment]="highlightedComment" [isCopy]="isCopyComment" [isCreate]="isCreateComment"
    [isUpdate]="isUpdateComment" [isVisible]="renderTicketCommentFormDialog" [ticket]="ticket"
  ></app-ticket-comment-form>
  <!-- TODO: move to solo component -->
  <ejs-dialog
    #addUserDialog [header]="'Zapojit uživatele'" [height]="'500px'" [isModal]="true" [showCloseIcon]="true"
    [visible]="false" [width]="'85%'" id="addUserDialog"
  >
    <form [formGroup]="addSolverUserForm">
      <div class="row">
        <div class="form-group col-12">
          <label
            [ngClass]="
                            addSolverUserControl.users.valid &&
                            (addSolverUserControl.users.dirty ||
                                addSolverUserControl.users.touched)
                                ? 'text-success'
                                : addSolverUserControl.users.invalid &&
                                  (addSolverUserControl.users.dirty ||
                                      addSolverUserControl.users.touched)
                                ? 'text-danger'
                                : 'text-muted'
                        " for="users"
          >Uživatelé:</label>
          <ejs-multiselect
            #users (filtering)="onFilteringUsers($event)" [allowFiltering]="true" [cssClass]="
                            addSolverUserControl.users.touched &&
                            addSolverUserControl.users.invalid
                                ? 'e-error'
                                : addSolverUserControl.users.touched &&
                                  addSolverUserControl.users.valid
                                ? 'e-success'
                                : ''
                        " [dataSource]="usersSelect" [fields]="fields" [filterBarPlaceholder]="'Vyhledat uživatele...'"
            [ignoreAccent]="true" [mode]="'CheckBox'" [placeholder]="'Vyberte uživatele'"
            [popupHeight]="height" [selectAllText]="'Všechny uživatele'" formControlName="users"
            id="users" showSelectAll="true" sortOrder="Ascending"
          ></ejs-multiselect>
          <small
            *ngIf="
                            addSolverUserControl.users.touched &&
                            addSolverUserControl.users.errors &&
                            addSolverUserControl.users.errors.required
                        " class="text-danger"
          >Vyberte alespoň jednoho uživatele</small>
        </div>
      </div>
    </form>
    <ng-template #footerTemplate>
      <div class="d-flex justify-content-between">
        <div class="col-6">
          <div *ngIf="addSolverUserForm.invalid" class="alert alert-warning" role="alert"> Formulář je špatně
            vyplněný
            <button
              (click)="showInputAddSolverUserFormErrors()"
              cssClass="e-btn e-warning btn-p-zero e-smallfloat-right" ejs-button type="button"
            > označ chyby
            </button>
          </div>
          <div *ngIf="addSolverUserForm.valid" class="alert alert-success" role="alert"> Formulář je správně
            vyplněný
          </div>
        </div>
        <div class="col-6">
          <button
            (click)="addUsers()" [disabled]="loadingTicket || addSolverUserForm.invalid"
            cssClass="e-btn e-success btn-p-zero e-small" ejs-button type="submit"
          >
                        <span
                          *ngIf="loadingTicket" aria-hidden="true" class="spinner-grow spinner-grow-sm"
                          role="status"
                        ></span>
            <span *ngIf="!loadingTicket">Zapojit uživatele</span>
            <span *ngIf="loadingTicket">Provádím...</span>
          </button>
          <button
            (click)="addUserDialog.hide()" cssClass="e-btn e-danger e-outline btn-p-zero e-small"
            ejs-button type="button"
          > ZRUŠIT
          </button>
        </div>
      </div>
    </ng-template>
  </ejs-dialog>
  <ejs-dialog
    #addDepartmentsDialog [header]="'Zapojit oddělení'" [height]="'500px'" [isModal]="true"
    [showCloseIcon]="true" [visible]="false" [width]="'85%'" id="addDepartmentsDialog"
  >
    <form [formGroup]="addSolverDepartmentForm">
      <div class="modal-body">
        <div class="row">
          <div class="form-group col-12">
            <label
              [ngClass]="
                                addSolverDepartmentControl.departments.valid &&
                                (addSolverDepartmentControl.departments.dirty ||
                                    addSolverDepartmentControl.departments
                                        .touched)
                                    ? 'text-success'
                                    : addSolverDepartmentControl.departments
                                          .invalid &&
                                      (addSolverDepartmentControl.departments
                                          .dirty ||
                                          addSolverDepartmentControl.departments
                                              .touched)
                                    ? 'text-danger'
                                    : 'text-muted'
                            " for="departments"
            >Oddělení:</label>
            <ejs-multiselect
              #departments (filtering)="onFilteringDepartments($event)" [allowFiltering]="true"
              [cssClass]="
                                addSolverDepartmentControl.departments.touched &&
                                addSolverDepartmentControl.departments.invalid
                                    ? 'e-error'
                                    : addSolverDepartmentControl.departments.touched &&
                                      addSolverDepartmentControl.departments.valid
                                    ? 'e-success'
                                    : ''" [dataSource]="departmentSelect" [fields]="fields"
              [filterBarPlaceholder]="'Vyhledat oddělení...'" [ignoreAccent]="true"
              [mode]="'CheckBox'" [placeholder]="'Vyberte oddělení'" [popupHeight]="height"
              [selectAllText]="'Všechny oddělení'" formControlName="departments" id="departments"
              showSelectAll="true" sortOrder="Ascending"
            ></ejs-multiselect>
            <small
              *ngIf="
                                addSolverDepartmentControl.departments.touched &&
                                addSolverDepartmentControl.departments.errors &&
                                addSolverDepartmentControl.departments.errors.required
                            " class="text-danger"
            >Vyberte alespoň jedno oddělení</small>
          </div>
        </div>
      </div>
    </form>
    <ng-template #footerTemplate>
      <div class="d-flex justify-content-between">
        <div class="col-6">
          <div *ngIf="addSolverDepartmentForm.invalid" class="alert alert-warning" role="alert"> Formulář je
            špatně
            vyplněný
            <button
              (click)="showInputAddDepartmentsErrors()"
              cssClass="e-btn e-warning btn-p-zero e-smallfloat-right" ejs-button type="button"
            > označ chyby
            </button>
          </div>
          <div *ngIf="addSolverDepartmentForm.valid" class="alert alert-success" role="alert"> Formulář je
            správně
            vyplněný
          </div>
        </div>
        <div class="col-6">
          <button
            (click)="addDepartments()" [disabled]="loadingTicket || addSolverDepartmentForm.invalid"
            cssClass="e-btn e-success btn-p-zero e-small" ejs-button type="submit"
          >
                        <span
                          *ngIf="loadingTicket" aria-hidden="true" class="spinner-grow spinner-grow-sm"
                          role="status"
                        ></span>
            <span *ngIf="!loadingTicket">Zapojit oddělení</span>
            <span *ngIf="loadingTicket">Provádím...</span>
          </button>
          <button
            (click)="addDepartmentsDialog.hide()" cssClass="e-btn e-danger e-outline btn-p-zero e-small"
            ejs-button type="button"
          > ZRUŠIT
          </button>
        </div>
      </div>
    </ng-template>
  </ejs-dialog>
  <ejs-dialog
    #promoteUserDialog [header]="'Promovat uživatele jako správce'" [height]="'200px'" [isModal]="true"
    [showCloseIcon]="true" [visible]="false" [width]="'60%'" id="promoteUserDialog"
  >
    <div class="modal-body"> Timto přidělíte vybranému uživateli možnost úprav tohoto ticketu z role správce.</div>
    <ng-template #footerTemplate>
      <div class="d-flex justify-content-center">
        <button
          (click)="promoteUser()" [disabled]="loadingTicket" cssClass="e-btn e-success btn-p-zero e-small"
          ejs-button type="submit"
        >
                    <span
                      *ngIf="loadingTicket" aria-hidden="true" class="spinner-grow spinner-grow-sm" role="status"
                    ></span>
          <span *ngIf="!loadingTicket">Promovat</span>
          <span *ngIf="loadingTicket">Provádím...</span>
        </button>
        <button
          (click)="promoteUserDialog.hide()" cssClass="e-btn e-danger e-outline btn-p-zero e-small"
          ejs-button type="button"
        > ZRUŠIT
        </button>
      </div>
    </ng-template>
  </ejs-dialog>
  <ejs-dialog
    #unPromoteUserDialog [header]="'Odebrat správce uživateli'" [height]="'200px'" [isModal]="true"
    [showCloseIcon]="true" [visible]="false" [width]="'60%'" id="unPromoteUserDialog"
  >
    <div class="modal-body"> Timto odeberte vybranému uživateli možnost úprav tohoto ticketu z role správce.</div>
    <ng-template #footerTemplate>
      <div class="d-flex justify-content-center">
        <button
          (click)="unPromoteUser()" [disabled]="loadingTicket"
          cssClass="e-btn e-success btn-p-zero e-small" ejs-button type="submit"
        >
                    <span
                      *ngIf="loadingTicket" aria-hidden="true" class="spinner-grow spinner-grow-sm" role="status"
                    ></span>
          <span *ngIf="!loadingTicket">Odebrat</span>
          <span *ngIf="loadingTicket">Provádím...</span>
        </button>
        <button
          (click)="unPromoteUserDialog.hide()" cssClass="e-btn e-danger e-outline btn-p-zero e-small"
          ejs-button type="button"
        > ZRUŠIT
        </button>
      </div>
    </ng-template>
  </ejs-dialog>
</div>
<div class="row">
  <div class="mainTaksWrapper col-12 col-md-8 col-lg-9 p-2">
    <ejs-tab
      #ticketDetailTab (created)="onCreate()" (selected)="select($event)" (selecting)="preventSwipe($event)"
      enablePersistence="true" height="auto" heightAdjustMode="Content" id="ticketDetailTab"
    >
      <e-tabitems>
        <e-tabitem [header]="headerText[0]">
          <ng-template #content>
            <div class="e-card" tabindex="0">
              <ng-container *ngIf="ticket && !loadingTicket">
                <div class="e-card-header d-flex">
                  <div class="e-card-header-caption">
                    <div class="e-card-header-title">
                      <strong
                        [className]="
                                                    ticket.status.name ===
                                                    'solved'
                                                        ? 'h4-responsive m-0 p-0 text-success'
                                                        : ticket.status.name ===
                                                          'deleted'
                                                        ? 'h4-responsive m-0 p-0 text-danger'
                                                        : 'h4-responsive m-0 p-0 text-warning'
                                                " class="m-0 p-0"
                      >Ticket: {{ ticket.subject }}</strong>
                    </div>
                  </div>
                  <app-ticket-toolbar
                    (activeTicketEvent)="reactiveTicket()" (addSolvingEvent)="addSolution()"
                    (copyTicketEvent)="copyTicket()" (createCommentEvent)="createComment()"
                    (deleteTicketEvent)="deleteTicket()" (editTicketEvent)="editTicket()"
                    (reactiveSolutionEvent)="reactiveSolution()" [ticket]="ticket"
                  ></app-ticket-toolbar>
                </div>
                <div class="e-card-separator mb-1 mt-1"></div>
                <div class="e-card-content">
                  <div class="row">
                    <div class="col-12 mt-2 mb-4">
                      <h6>ZADÁNÍ:</h6>
                      <div
                        class="scrollbar scrollbar-indigo thin"
                        style="max-height: 350px; overflow-y: auto;"
                      >
                        <div class="force-overflow">
                          <div [innerHTML]="ticket.maintaskHTML" id="maintaskText"></div>
                        </div>
                      </div>
                      <div class="e-card-separator mb-1 mt-1"></div>
                      <div *ngIf="ticket.files.length > 0" class="image-thumb-group">
                        <div>
                          <img
                            (click)="open(i) "
                            *ngFor=" let img of imagesBasic; let i = index"
                            [src]="img.thumb || img.src"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12 mt-2">
                      <button
                        (click)="createComment()"
                        *ngIf="!ticket.finished_at && !ticket.deleted_date"
                        cssClass="e-btn e-primary btn-p-zero e-small"
                        ejs-button
                        title="přidat komentář do diskuze" type="button"
                      >
                        <i
                          aria-hidden="true"
                          class="fa-light fa-edit mr-lg-1"
                        ></i>
                        <span class="d-none d-lg-inline"> Přidat komentář </span>
                      </button>
                      <div class="e-card-separator mb-1 mt-1"></div>
                      <app-ticket-comments
                        (addTicketCommentEvent)="reloadData()"
                        (deleteTicketCommentEvent)="highlightedComment = $event; deleteCommentDialog.show()"
                        (editTicketCommentEvent)="highlightedComment = $event; editComment()"
                        [ticket]="ticket"
                      ></app-ticket-comments>
                    </div>
                  </div>
                  <div *ngIf="ticket.finished_at" class="row">
                    <div class="col-12 mt-3 mb-2">
                      <div class="e-card" tabindex="0">
                        <div class="e-card-header bg-success">
                          <h6 class="text-white"> ŘEŠENÍ ({{ ticket.finished_at ? 1:0 }}): </h6>
                        </div>
                        <app-ticket-notes
                          (deleteTicketNoteEvent)="deleteNote($event)"
                          (editSolutionNoteEvent)="reactiveSolution()"
                          (editTicketNoteEvent)="editNote($event)"
                          (reactiveTicketEvent)="reactiveTicket()" [isSolving]="true"
                          [ticket]="ticket"
                        ></app-ticket-notes>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-container>
              <ng-container *ngIf="!ticket || loadingTicket">
                <div class="e-card-content text-center">
                  <app-loader></app-loader>
                </div>
              </ng-container>
            </div>
          </ng-template>
        </e-tabitem>
        <e-tabitem [header]="headerText[1]">
          <ng-template #content>
            <div class="e-card" tabindex="0">
              <ng-container *ngIf="ticket && !loadingTicket">
                <div class="e-card-content">
                  <div class="row">
                    <div class="col-12 text-center text-md-right">
                      <div aria-label="Tasks toolbar" class="e-btn-group" role="group">
                        <button
                          (click)="copyTask()" [disabled]="
                                                        !clickedRow ||
                                                        !clickedRow[0]
                                                    " cssClass="e-btn e-primary btn-p-zero e-small" ejs-button
                          type="button"
                        > kopírovat úkol <i
                          aria-hidden="true" class="fa-light fa-copy ml-1"
                        ></i>
                        </button>
                        <button
                          (click)="newTask()" *ngIf="
                                                        ticket &&
                                                        !ticket.finished_at &&
                                                        !ticket.deleted_date
                                                    " cssClass="e-btn e-primary btn-p-zero e-small" ejs-button
                          type="button"
                        > Přidat úkol
                        </button>
                      </div>
                    </div>
                    <div class="col-12 mt-2">
                      <div class="scroll_wrapper1" id="scroll_wrapper1_tasks">
                        <div class="scroll_div" id="scroll_div_tasks"></div>
                      </div>
                      <div class="scroll_wrapper2" id="scroll_wrapper2_tasks">
                        <div class="grid_parent" id="grid_parent_tasks">
                          <ejs-grid
                            #gridTasks (created)="createdTasks()"
                            (dataBound)="onDataBound()" (load)="onLoad()"
                            (loadeddata)="setInitialGridFiltering()"
                            (rowDataBound)="rowDataBound($event)"
                            (rowDeselected)="rowSelected()"
                            (rowSelected)="rowSelected()" [allowExcelExport]="true"
                            [allowFiltering]="true" [allowMultiSorting]="true"
                            [allowPdfExport]="false" [allowSelection]="true"
                            [allowSorting]="true" [dataSource]="elements"
                            [enableHover]="false" [enablePersistence]="false"
                            [enableVirtualization]="true"
                            [filterSettings]="filterSettings"
                            [groupSettings]="groupOptions" [pageSettings]="pageSettings"
                            [selectionSettings]="selectionSettings"
                            [sortSettings]="initialTasksSort" [toolbar]="toolbar"
                            allowGrouping="true" allowPaging="true"
                            allowReordering="true" allowResizing="true"
                            allowSorting="true" allowTextWrap="true" height="100%"
                            id="tasksGrid" locale="cs" rowHeight="38"
                            showColumnMenu="true" width="100%"
                          >
                            <e-columns>
                              <e-column
                                [allowEditing]="false" [allowFiltering]="false"
                                [allowReordering]="false" [allowResizing]="false"
                                [allowSorting]="false" [autoFit]="true"
                                type="checkbox"
                              ></e-column>
                              <e-column
                                [allowFiltering]="false" [allowReordering]="false"
                                [allowResizing]="false" [autoFit]="true"
                                [isPrimaryKey]="true" [visible]="true" field="id"
                                headerText="ID"
                              >
                                <ng-template #template let-data>
                                  <a
                                    [routerLink]="[
                                                                            '/tasks/detail/',
                                                                            data.id
                                                                        ]"
                                  >
                                    {{ data.id }}
                                  </a>
                                </ng-template>
                              </e-column>
                              <e-column
                                [allowFiltering]="false" [allowReordering]="false"
                                [allowResizing]="false" [autoFit]="true"
                                [visible]="false" field="created_by"
                                headerText="Creator ID"
                              ></e-column>
                              <e-column
                                [allowFiltering]="false" [allowReordering]="false"
                                [allowResizing]="false" [autoFit]="true"
                                [visible]="false" field="user_id"
                                headerText="User ID"
                              ></e-column>
                              <e-column
                                [autoFit]="true" [filter]="filterSettings"
                                clipMode="EllipsisWithTooltip"
                                editType="dropdownedit" field="user_str"
                                headerText="Řešitel"
                              >
                                <ng-template #template let-data>
                                  <div>
                                    <img
                                      *ngIf="
                                                                                data.user_img
                                                                            " alt="profilový obrázek"
                                      class="img-fluid rounded-circle avatar-xs mr-1"
                                      src="assets/img{{
                                                                                data.user_img
                                                                            }}"
                                    />
                                    <span>{{
                                        data.user_str
                                      }}</span>
                                  </div>
                                </ng-template>
                              </e-column>
                              <e-column
                                [autoFit]="true" [filter]="filterSettings"
                                clipMode="EllipsisWithTooltip" field="subject"
                                headerText="Předmět"
                              ></e-column>
                              <e-column
                                [autoFit]="true" [disableHtmlEncode]="false"
                                [filter]="filterSettings"
                                clipMode="EllipsisWithTooltip" field="maintask"
                                headerText="Zadání"
                              ></e-column>
                              <e-column
                                [autoFit]="true" [filter]="filterSettings"
                                clipMode="EllipsisWithTooltip" field="status"
                                headerText="Stav"
                              ></e-column>
                              <e-column
                                [autoFit]="true" [filter]="filterSettings"
                                clipMode="EllipsisWithTooltip"
                                editType="dropdownedit" field="creator_str"
                                headerText="Vytvořil"
                              >
                                <ng-template #template let-data>
                                  <div>
                                    <img
                                      *ngIf="
                                                                                data.creator_img
                                                                            " alt="profilový obrázek"
                                      class="img-fluid rounded-circle avatar-xs mr-1"
                                      src="assets/img{{
                                                                                data.creator_img
                                                                            }}"
                                    />
                                    <span>
                                      {{ data.creator_str }}
                                    </span>
                                  </div>
                                </ng-template>
                              </e-column>
                              <e-column
                                [allowEditing]="false" [autoFit]="true"
                                [filter]="filterDate" [format]="dateFormat"
                                clipMode="EllipsisWithTooltip"
                                editType="datepickeredit" field="deadline"
                                headerText="Termín" textAlign="Right" type="date"
                              ></e-column>
                              <e-column
                                [allowEditing]="false" [autoFit]="true"
                                [filter]="filterDate" [format]="dateFormat"
                                clipMode="EllipsisWithTooltip"
                                editType="datepickeredit" field="created_date"
                                headerText="Vytvořeno" textAlign="Right" type="date"
                              ></e-column>
                              <e-column
                                [allowEditing]="false" [autoFit]="true"
                                [filter]="filterDate" [format]="dateFormat"
                                clipMode="EllipsisWithTooltip"
                                editType="datepickeredit" field="updated_date"
                                headerText="Naposledy upraveno" textAlign="Right"
                                type="date"
                              ></e-column>
                              <e-column
                                [allowEditing]="false" [autoFit]="true"
                                [filter]="filterDate" [format]="dateFormat"
                                clipMode="EllipsisWithTooltip"
                                editType="datepickeredit" field="deleted_date"
                                headerText="Smazáno" textAlign="Right" type="date"
                              ></e-column>
                            </e-columns>
                            <e-aggregates>
                              <e-aggregate>
                                <e-columns>
                                  <e-column field="id" type="Count">
                                    <ng-template #footerTemplate let-data> Celkem
                                      řádků: {{
                                        data.Count
                                      }}
                                    </ng-template>
                                  </e-column>
                                </e-columns>
                              </e-aggregate>
                            </e-aggregates>
                          </ejs-grid>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-container>
              <ng-container *ngIf="!ticket || loadingTicket">
                <div class="e-card-content text-center">
                  <app-loader></app-loader>
                </div>
              </ng-container>
            </div>
          </ng-template>
        </e-tabitem>
        <e-tabitem [header]="headerText[2]">
          <ng-template #content>
            <app-ticket-files [imagesBasic]="imagesBasic" [ticket]="ticket"></app-ticket-files>
          </ng-template>
        </e-tabitem>
        <e-tabitem [header]="headerText[3]">
          <ng-template #content>
            <div class="e-card">
              <ng-container *ngIf="ticket && !loadingTicket">
                <div class="e-card-header">
                  <h5 class="e-card-title">
                    <i class="fa-light fa-history mr-2"></i> Historie změn
                  </h5>
                </div>
                <div class="e-card-content">
                  <ejs-toolbar id="filterToolbar" overflowMode="Scrollable" scrollStep="500">
                    <e-items>
                      <e-item
                        [cssClass]="'statusFilter'" tooltipText="Přednastavené hledání"
                        type="input"
                      >
                        <ng-template #template>
                          <ejs-dropdownlist
                            #viewFilter (change)="onStatusFilterChange($event)"
                            [dataSource]="categoryFilterData" [width]="500"
                            placeholder="Vyberte přednastavený pohled..."
                          ></ejs-dropdownlist>
                        </ng-template>
                      </e-item>
                      <e-item type="Separator"></e-item>
                      <e-item
                        [cssClass]="'fulltextFilter'" tooltipText="Text pro vyhledávání"
                        type="input"
                      >
                        <ng-template #template>
                          <ejs-textbox
                            #searchtext [width]="500" class="mr-2"
                            placeholder="Vyhledat..."
                          ></ejs-textbox>
                          <button (click)="search()" class="e-btn e-info btn-p-zero e-small">
                            <i class="fa-light fa-lg fa-search mr-1"></i>
                            <span class="d-none d-lg-inline">vyhledat</span>
                          </button>
                          <button
                            (click)="cancelBtnClick() "
                            class="e-btn e-danger e-outline btn-p-zero e-small"
                          >
                            <i class="fa-light fa-lg fa-times"></i>
                          </button>
                        </ng-template>
                      </e-item>
                    </e-items>
                  </ejs-toolbar>
                  <div class="control-section">
                    <div class="scroll_wrapper1" id="scroll_wrapper1_ticketsLogs">
                      <div class="scroll_div" id="scroll_div_ticketsLogs"></div>
                    </div>
                    <div class="scroll_wrapper2" id="scroll_wrapper2_ticketsLogs">
                      <div class="grid_parent" id="grid_parent_ticketsLogs">
                        <ejs-grid
                          #ticketLogsGrid (created)="onHistoryCreated()"
                          (dataBound)="onHistoryDataBound()" (load)="onHistoryLoad()"
                          (loadeddata)="setInitialHistoryGridFiltering()"
                          (rowDataBound)="rowHistoryDataBound($event)"
                          (rowDeselected)="rowHistorySelected()"
                          (rowSelected)="rowHistorySelected()"
                          (toolbarClick)="toolbarHistoryClick($event)"
                          [allowExcelExport]="true" [allowFiltering]="true"
                          [allowMultiSorting]="true" [allowPdfExport]="false"
                          [allowSelection]="true" [allowSorting]="true"
                          [dataSource]="dataHistory" [enableHover]="false"
                          [enablePersistence]="false" [enableVirtualization]="true"
                          [filterSettings]="filterSettings" [groupSettings]="groupOptions"
                          [pageSettings]="pageOptions"
                          [selectionSettings]="selectionSettings"
                          [sortSettings]="sortHistoryOptions" [toolbar]="toolbarHistory"
                          allowGrouping="true" allowReordering="true" allowResizing="true"
                          allowTextWrap="true" height="100%" id="ticketLogsGrid"
                          locale="cs" rowHeight="38" showColumnMenu="true" width="100%"
                        >
                          <e-columns>
                            <e-column
                              [allowEditing]="false" [allowFiltering]="false"
                              [allowReordering]="false" [allowResizing]="false"
                              [allowSorting]="false" [autoFit]="true" type="checkbox"
                            ></e-column>
                            <e-column
                              [allowEditing]="false" [allowFiltering]="true"
                              [allowReordering]="false" [allowResizing]="false"
                              [autoFit]="true" [isPrimaryKey]="true" [visible]="false"
                              field="id" headerText="Log ID"
                            ></e-column>
                            <e-column
                              [allowEditing]="false" [allowFiltering]="true"
                              [allowReordering]="false" [allowResizing]="false"
                              [autoFit]="true" [visible]="true" field="ticket_id"
                              headerText="Ticket ID"
                            >
                              <ng-template #template let-data>
                                <a [routerLink]="['/tickets/detail/', data.ticket_id]">
                                  {{ data.ticket_id }}
                                </a>
                              </ng-template>
                            </e-column>
                            <e-column
                              [allowEditing]="false" [allowFiltering]="true"
                              [allowReordering]="false" [allowResizing]="false"
                              [autoFit]="true" [visible]="false" field="created_by"
                              headerText="Creator ID"
                            >
                              <ng-template #template let-data>
                                <a [routerLink]="['/users/detail/',data.created_by]">
                                  {{ data.created_by }}
                                </a>
                              </ng-template>
                            </e-column>
                            <e-column
                              [allowEditing]="false" [allowFiltering]="true"
                              [allowReordering]="false" [allowResizing]="false"
                              [autoFit]="true" [visible]="false" field="updated_by"
                              headerText="Editor ID"
                            >
                              <ng-template #template let-data>
                                <a [routerLink]="['/users/detail/',data.updated_by]">
                                  {{ data.updated_by }}
                                </a>
                              </ng-template>
                            </e-column>
                            <e-column
                              [allowEditing]="false" [allowFiltering]="true"
                              [allowReordering]="false" [allowResizing]="false"
                              [autoFit]="true" [visible]="false" field="deleted_by"
                              headerText="Remover ID"
                            >
                              <ng-template #template let-data>
                                <a [routerLink]="['/users/detail/',data.deleted_by]">
                                  {{ data.deleted_by }}
                                </a>
                              </ng-template>
                            </e-column>
                            <e-column
                              [allowEditing]="false" [allowFiltering]="true"
                              [allowReordering]="false" [allowResizing]="false"
                              [autoFit]="true" [visible]="false" field="category_id"
                              headerText="Category ID"
                            ></e-column>
                            <e-column
                              [allowEditing]="false" [allowFiltering]="true"
                              [allowReordering]="false" [allowResizing]="false"
                              [autoFit]="true" [visible]="false" field="status_id"
                              headerText="Status ID"
                            ></e-column>
                            <e-column
                              [allowEditing]="false" [allowFiltering]="true"
                              [allowReordering]="false" [allowResizing]="false"
                              [autoFit]="true" [visible]="false" field="type_id"
                              headerText="Type ID"
                            ></e-column>
                            <e-column
                              [allowEditing]="false" [autoFit]="true"
                              [filter]="filterSettings" clipMode="EllipsisWithTooltip"
                              field="type" headerText="Typ logu" textAlign="center"
                            >
                              <ng-template #template let-data>
                                                                <span
                                                                  *ngIf="data.type_id === 1"
                                                                  class="e-badge e-badge-success e-badge-pill"
                                                                >
                                                                    {{ data.type }}
                                                                </span>
                                <span
                                  *ngIf="data.type_id === 2"
                                  class="e-badge e-badge-warning e-badge-pill"
                                >
                                                                    {{ data.type }}
                                                                </span>
                                <span
                                  *ngIf="data.type_id === 3"
                                  class="e-badge e-badge-danger e-badge-pill"
                                >
                                                                    {{ data.type }}
                                                                </span>
                              </ng-template>
                            </e-column>
                            <e-column
                              [allowEditing]="false" [autoFit]="true"
                              [filter]="filterSettings" clipMode="EllipsisWithTooltip"
                              field="subject" headerText="Předmět"
                            ></e-column>
                            <e-column
                              [allowEditing]="false" [autoFit]="true"
                              [disableHtmlEncode]="false" [filter]="filterSettings"
                              clipMode="EllipsisWithTooltip" field="maintask"
                              headerText="Zadání"
                            >
                              <ng-template #template let-data>
                                <div [innerHTML]="data.maintask | truncateText: 300"></div>
                              </ng-template>
                            </e-column>
                            <e-column
                              [autoFit]="true" [filter]="filterSettings"
                              clipMode="EllipsisWithTooltip" editType="dropdownedit"
                              field="creator_str" headerText="Vytvořil"
                            >
                              <ng-template #template let-data>
                                <div>
                                  <img
                                    *ngIf="
                                                                            data.creator_img
                                                                        " alt="profilový obrázek"
                                    class="img-fluid rounded-circle avatar-xs mr-1"
                                    src="assets/img{{
                                                                            data.creator_img
                                                                        }}"
                                  />
                                  <a
                                    [routerLink]="[
                                                                            '/users/detail/',
                                                                            data.created_by
                                                                        ]"
                                  >
                                    {{ data.creator_str }}
                                  </a>
                                </div>
                              </ng-template>
                            </e-column>
                            <e-column
                              [allowEditing]="false" [autoFit]="true"
                              [filter]="filterDate" [format]="dateFormat"
                              clipMode="EllipsisWithTooltip" editType="datepickeredit"
                              field="created_date" headerText="Vytvořeno"
                              textAlign="Right" type="date"
                            ></e-column>
                            <e-column
                              [autoFit]="true" [filter]="filterSettings"
                              clipMode="EllipsisWithTooltip" editType="dropdownedit"
                              field="editor_str" headerText="Upravil"
                            >
                              <ng-template #template let-data>
                                <div
                                  *ngIf="data.updated_by
                                                                    "
                                >
                                  <img
                                    alt="profilový obrázek"
                                    class="img-fluid rounded-circle avatar-xs mr-1"
                                    src="assets/img{{data.editor_img}}"
                                  />
                                  <a
                                    [routerLink]="[
                                                                            '/users/detail/',
                                                                            data.updated_by
                                                                        ]"
                                  >
                                    {{ data.editor_str }}
                                  </a>
                                </div>
                              </ng-template>
                            </e-column>
                            <e-column
                              [allowEditing]="false" [filter]="filterDate"
                              [format]="dateFormat" clipMode="EllipsisWithTooltip"
                              editType="datepickeredit" field="updated_date"
                              headerText="Naposledy upraveno" textAlign="Right"
                              type="date"
                            ></e-column>
                            <e-column
                              [autoFit]="true" [filter]="filterSettings"
                              clipMode="EllipsisWithTooltip" editType="dropdownedit"
                              field="remover_str" headerText="Smazal"
                            >
                              <ng-template #template let-data>
                                <div *ngIf="data.deleted_by">
                                  <img
                                    alt="profilový obrázek"
                                    class="img-fluid rounded-circle avatar-xs mr-1"
                                    src="assets/img{{data.remover_img}}"
                                  />
                                  <a
                                    [routerLink]="[
                                                                            '/users/detail/',
                                                                            data.deleted_by
                                                                        ]"
                                  >
                                    {{ data.remover_str }}
                                  </a>
                                </div>
                              </ng-template>
                            </e-column>
                            <e-column
                              [allowEditing]="false" [autoFit]="true"
                              [filter]="filterDate" [format]="dateFormat"
                              clipMode="EllipsisWithTooltip" editType="datepickeredit"
                              field="deleted_date" headerText="Smazáno"
                              textAlign="Right" type="date"
                            ></e-column>
                            <e-column
                              [allowEditing]="false" [autoFit]="true"
                              [filter]="filterDate" [format]="dateFormat"
                              clipMode="EllipsisWithTooltip" editType="datepickeredit"
                              field="timestamp" headerText="Log Vytvořen"
                              textAlign="Right" type="date"
                            ></e-column>
                          </e-columns>
                        </ejs-grid>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-container>
              <ng-container *ngIf="!ticket || loadingTicket">
                <div class="e-card-content text-center">
                  <app-loader></app-loader>
                </div>
              </ng-container>
            </div>
          </ng-template>
        </e-tabitem>
      </e-tabitems>
    </ejs-tab>
  </div>
  <div class="col-12 col-md-4 col-lg-3 p-2">
    <app-ticket-sidebar
      (addDepartmentsEvent)="addDepartmentsDialog.show()"
      (addUserEvent)="addUserDialog.show()"
      (deleteDepartmentEvent)="highlightedDepartmentRow = $event; deleteDepartmentDialog.show()"
      (deleteUserEvent)="highlightedUserRow = $event; deleteUserDialog.show()" (promoteUserEvent)="highlightedUserRow = $event; promoteUserDialog.show()"
      (unPromoteUserEvent)="highlightedUserRow = $event; unPromoteUserDialog.show()"
      [ticket]="ticket"
    ></app-ticket-sidebar>
  </div>
</div>
