import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'fileExtension',
})
export class FileExtensionPipe implements PipeTransform {
    transform(value: string, extension: boolean): string | undefined {
        return extension ? value.split('.')
            .pop() : value.split('.')
            .slice(0, -1)
            .join('.');
    }
}
