<div class="e-card">
  <ng-container *ngIf="ticket">
    <div class="e-card-header p-2">
      <div class="e-card-header-caption">
        <div
          class="d-flex justify-content-between e-card-header-title"
        >
          <strong>Ticket</strong>
          <span>ID: {{ ticket.id }}</span>
        </div>
        <div *ngIf="ticket.finished_at" class="text-muted">
          <small
            class="font-weight-bold"
          >
            dokončeno: {{ ticket.finished_at | dateFormat: 'DD.MM.YYYY HH:mm' }}
          </small>
        </div>
        <div class="d-flex justify-content-between">
          <span *ngIf="ticket.finished_at" class="e-badge e-badge-success w-50">dokončeno</span>
          <span *ngIf="!ticket.finished_at && !ticket.deleted_date"
                class="e-badge e-badge-warning w-50">ve frontě</span>
          <ng-container *ngIf="ticket.ticketTime">
                        <span
                          *ngIf="ticket.ticketTime.overtime && !ticket.deleted_date"
                          class="e-badge e-badge-danger w-50"
                        >po termínu</span>
            <span
              *ngIf="!ticket.ticketTime.overtime && !ticket.deleted_date"
              class="e-badge e-badge-success w-50"
            >v termínu</span>
          </ng-container>
        </div>
        <span *ngIf="ticket.deleted_date" class="e-badge e-badge-danger ml-1">
                    smazáno (dne: {{ ticket.deleted_date | dateFormat: 'DD.MM.YYYY HH:mm' }})
                </span>
      </div>
    </div>
    <div class="e-card-content p-2">
      <div class="text-center mb-1">
                <span class="text-muted font-weight-bold">
                    <small class="font-weight-bold">zadavatel:</small>
                    <img
                      alt="profilový obrázek"
                      class="img-fluid rounded-circle avatar-xs mr-1"
                      src="assets/img{{
                                    ticket.creator.personalphoto
                                }}"
                    />
                  {{ ticket.creator.firstname }}
                  {{ ticket.creator.secondname }}
                </span>
        <ng-container *ngIf="ticket.category_id === 7">
          <div class="e-card-separator mb-1 mt-1"></div>
          <span class="font-weight-bold">IČO: {{ ticket.business_id }}</span>
        </ng-container>
        <div class="e-card-separator mb-1 mt-1"></div>
        <div class="row">
          <div aria-label="Assignations toolbar" class="col-12" role="group">
            <button
              (click)="addUser()"
              *ngIf="!ticket.finished_at && !ticket.deleted_date"
              cssClass="e-btn e-success btn-p-zero e-small"
              ejs-button
              title="přidat uživatele do ticketu"
            >
              zapojit uživatele
            </button>
            <button
              (click)="addDepartments()"
              *ngIf="!ticket.finished_at && !ticket.deleted_date"
              cssClass="e-btn e-success btn-p-zero e-small"
              ejs-button
              title="přidat oddělení do ticketu"
            >
              zapojit oddělení
            </button>
          </div>
        </div>
        <div
          *ngIf="ticket.departments || ticket.users"
          class="mt-1"
          style="max-height: 250px; overflow-y: auto"
        >
          <small class="font-weight-bold">
            Řešitelé&nbsp;({{
              (ticket.departments.length
                ? ticket.departments.length
                :0) +
              (ticket.users.length
                ? ticket.users.length
                :0)
            }}):
          </small
          >
          <ul class="list-group list-group-flush" id="solversList">
            <li
              *ngFor="let user of ticket.users; let i = index"
              class="list-group-item list-group-item-action p-1 border-top-0"
            >
              <div class="d-flex flex-column">
                <div class="solver-name">
                  <i
                    *ngIf="ticketsService.checkPromotedAdministrators(user) || ticket.created_by === user.id || permissionService.checkUserISTicketAdmin(user)"
                    aria-hidden="true"
                    class="fa-light fa-crown mr-1 e-warning"
                  ></i>
                  <img
                    alt="profilový obrázek"
                    class="img-fluid rounded-circle avatar-xs mr-1 ml-2"
                    src="assets/img{{ user.personalphoto }}"
                  />
                  <small class="font-weight-bold">
                    {{ user.firstname }} {{ user.secondname }}
                  </small>
                </div>
                <div class="d-flex flex-row solver-timestamp justify-content-between mb-1">
                  <small
                    *ngIf="user.ticket_assignation_users.viewed_at || user.ticket_assignation_users.last_viewed_at"
                    class="text-success"
                  >
                    <i
                      aria-hidden="true"
                      class="fa-light fa-lg fa-eye mr-1"
                      title="poprvé zobrazil"
                    ></i>
                    {{ user.ticket_assignation_users.viewed_at | dateFormat: 'DD.MM.YYYY HH:mm' }}
                    <i
                      aria-hidden="true"
                      class="fa-light fa-lg fa-eye mr-1"
                      title="naposledy zobrazil"
                    ></i>
                    {{ user.ticket_assignation_users.last_viewed_at | dateFormat: 'DD.MM.YYYY HH:mm' }}
                  </small>
                  <small
                    *ngIf="!user.ticket_assignation_users.viewed_at && !user.ticket_assignation_users.last_viewed_at"
                    class="text-danger"
                  >
                    <i aria-hidden="true" class="fa-light fa-lg fa-eye-slash mr-1"></i>
                    dosud nezobrazil ticket
                  </small>
                </div>
                <div class="d-flex flex-row solver-buttons justify-content-center">
                  <button
                    (click)="promoteUser(user)"
                    *ngIf="!ticketsService.checkPromotedAdministrators(user) && ticket.created_by === currentUser.id && user.id !== ticket.created_by && !ticket.finished_at && !ticket.deleted_date"
                    cssClass="e-btn e-warning btn-p-zero e-small"
                    ejs-button
                    title="promovat uživatele jako správce ticketu"
                  >
                    <i aria-hidden="true" class="fa-light fa-user-crown mr-1"></i>
                    přidělit správce
                  </button>
                  <button
                    (click)="unPromoteUser(user)"
                    *ngIf="ticketsService.checkPromotedAdministrators(user) && ticket.created_by === currentUser.id && user.id !== ticket.created_by && !ticket.finished_at && !ticket.deleted_date"
                    cssClass="e-btn e-danger btn-p-zero e-small"
                    ejs-button
                    title="odebrat uživatele jako správce ticketu"
                  >
                    <i aria-hidden="true" class="fa-light fa-user-crown mr-1"></i>
                    odebrat správce
                  </button>
                  <button
                    (click)="deleteUser(user)"
                    *ngIf="ticket && (ticket.created_by === currentUser.id || ticketsService.checkPromotedAdministrators(currentUser) || (currentUser.rule && currentUser.rule.is_admin) || ticket.creator.boss_id === currentUser.id) && !ticket.deleted_date && !ticket.finished_at"
                    cssClass="e-btn e-danger btn-p-zero e-small"
                    ejs-button
                    type="button"
                  >
                    <i aria-hidden="true" class="fa-light fa-times mr-1"></i>
                    <span class="d-none d-lg-inline">ZRUŠIT</span>
                  </button>
                </div>
              </div>
            </li>
            <li
              *ngFor="let department of ticket.departments; let i = index"
              class="list-group-item list-group-item-action p-1"
            >
              <div class="d-flex flex-column">
                <div class="solver-name">
                  <i aria-hidden="true" class="fa-light fa-users fa-lg ml-1"></i>
                  <small class="font-weight-bold">
                    {{ department.name }}&nbsp;&middot;&nbsp;{{ department.company.name }}
                  </small>
                </div>
                <div class="d-flex flex-row solver-timestamp mb-1">
                  <small
                    *ngIf="department.ticket_assignation_departs.viewed_at || department.ticket_assignation_departs.last_viewed_at"
                    class="text-success"
                  >
                    <i
                      aria-hidden="true"
                      class="fa-light fa-lg fa-eye mr-1"
                      title="poprvé zobrazil"
                    ></i>
                    {{ department.ticket_assignation_departs.viewed_at | dateFormat: 'DD.MM.YYYY HH:mm' }}
                    <i
                      aria-hidden="true"
                      class="fa-light fa-lg fa-eye mr-1"
                      title="naposledy zobrazil"
                    ></i>
                    {{ department.ticket_assignation_departs.last_viewed_at | dateFormat: 'DD.MM.YYYY HH:mm' }}
                  </small>
                  <small
                    *ngIf="!department.ticket_assignation_departs.viewed_at && !department.ticket_assignation_departs.last_viewed_at"
                    class="text-danger"
                  >
                    <i aria-hidden="true" class="fa-light fa-lg fa-eye-slash mr-1"></i>
                    dosud nikdo z oddělení nezobrazil ticket
                  </small>
                </div>
                <div class="d-flex flex-row solver-buttons justify-content-center">
                  <button
                    (click)="deleteDepartment(department);"
                    *ngIf="ticket && (ticket.created_by === currentUser.id || ticketsService.checkPromotedAdministrators(currentUser) || (currentUser.rule && currentUser.rule.is_admin) || ticket.creator.boss_id === currentUser.id) && !ticket.deleted_date && !ticket.finished_at"
                    cssClass="e-btn e-danger btn-p-zero e-small"
                    ejs-button
                    type="button"
                  >
                    <i aria-hidden="true" class="fa-light fa-times"></i>
                    <span class="d-none d-lg-inline">ZRUŠIT</span>
                  </button>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <div class="e-card-separator mb-1 mt-1"></div>
        <span class="font-weight-bold">použité štítky:</span>
        <br />
        <ng-container *ngFor="let tag of ticket.tags">
          <h5 class="d-inline">
                        <span
                          [ngClass]="
                                        tag.fontColor &&
                                        tag.fontColor === 'white'
                                            ? 'text-white-imp'
                                            : 'text-black-imp'
                                    "
                          [ngStyle]="{
                                        'background-color': tag.color,
                                        'border-color': tag.color
                                    }"
                          class="badge badge-pill mr-1"
                        >
                            {{ tag.name }}
                        </span>
          </h5>
        </ng-container>
        <div class="e-card-separator mb-1 mt-1"></div>
        <div
          *ngIf="ticket.unixCreatedTime"
          class="d-flex mb-2 text-center text-muted justify-content-center"
        >
          <small class="text-muted font-weight-bold">
            vytvořeno:
            {{ ticket.created_date | dateFormat: 'DD.MM.YYYY HH:mm' }}
            <br />
            ( {{ ticket.unixCreatedTime | timeago: live }} )
          </small>
        </div>
        <div
          *ngIf="ticket.unixUpdatedTime"
          class="d-flex mb-2 text-center text-muted justify-content-center"
        >
          <small class="text-muted font-weight-bold">
            poslední změna:
            {{ ticket.updated_date| dateFormat: 'DD.MM.YYYY HH:mm' }}
            <br />
            ( {{ ticket.unixUpdatedTime | timeago: live }} )
          </small>
        </div>
        <div
          *ngIf="ticket.unixDeletedTime"
          class="d-flex mb-2 text-center text-muted justify-content-center"
        >
          <small class="font-weight-bold">
            smazáno:
            {{ ticket.deleted_date| dateFormat: 'DD.MM.YYYY HH:mm' }}
            <br />
            ( {{ ticket.unixDeletedTime | timeago: live }} )
          </small>
        </div>
        <div
          *ngIf="ticket.unixStartDeadlineTime"
          class="d-flex mb-2 text-center text-muted justify-content-center"
        >
          <small class="text-muted font-weight-bold">
            začátek termínu:
            {{ ticket.start_deadline| dateFormat: 'DD.MM.YYYY HH:mm' }}
            <br />
            ( {{ ticket.unixStartDeadlineTime | timeago: live }} )
          </small>
        </div>
        <div
          *ngIf="ticket.unixDeadlineTime"
          class="d-flex mb-2 text-center text-muted justify-content-center"
        >
          <small class="text-muted font-weight-bold">
            konec termínu:
            {{ ticket.deadline | dateFormat: 'DD.MM.YYYY HH:mm' }}
            <br />
            ( {{ ticket.unixDeadlineTime | timeago: live }} )
          </small>
        </div>
        <div
          *ngIf="ticket.unixFinishedTime"
          class="d-flex mb-2 text-center text-muted justify-content-center"
        >
          <small class="text-muted font-weight-bold">
            řešení vloženo:
            {{ ticket.finished_at | dateFormat: 'DD.MM.YYYY HH:mm' }}
            <br />
            ( {{ ticket.unixFinishedTime | timeago: live }} )
          </small>
        </div>
        <div class="e-card-separator mb-1 mt-1"></div>
        <div
          class="row mb-2 text-center text-muted justify-content-center"
          style="font-weight: bold"
        >
          <strong
            class="col-12 text-muted mt-2 font-weight-bold"
          >časový plán:</strong
          >
          <span
            *ngIf="ticket.start_deadline"
            class="col-12 text-muted mt-2 font-weight-bold"
          >
                        OD: {{ ticket.start_deadline | dateFormat: 'DD.MM.YYYY HH:mm' }}
                    </span>
          <span
            *ngIf="!ticket.start_deadline"
            class="col-12 text-muted mt-2 font-weight-bold"
          >
                        OD: {{ ticket.created_date | dateFormat: 'DD.MM.YYYY HH:mm' }}
                    </span>
          <span
            *ngIf="ticket.deadline"
            class="col-12 text-muted mt-2 font-weight-bold"
          >
                        DO: {{ ticket.deadline | dateFormat: 'DD.MM.YYYY HH:mm' }}
                    </span>
        </div>
        <small
          *ngIf="ticket.ticketTime"
          class="text-muted mt-2 font-weight-bold"
          style="font-weight: bold"
        >
          časový fond: {{ ticket.ticketTime.time_fond }}
        </small>
        <small
          *ngIf="!ticket.ticketTime"
          class="text-muted mt-2 font-weight-bold"
        >
          bez časového fondu
        </small>
        <app-ticket-time [ticket]="ticket"></app-ticket-time>
        <div class="e-card-separator mb-1 mt-1"></div>
        <small
          *ngIf="ticket.category"
          class="text-muted font-weight-bold"
        >
          Kategorie: {{ ticket.category.name }} </small
        ><br />
        <small
          *ngIf="ticket.unixSLATime && ticket.category.sla.duration > 0"
          class="text-muted font-weight-bold"
        >
          SLA fond:
          {{ ticket.category.sla.duration / 60 }} hodin
          <small
            class="text-muted"
          >(
            {{ ticket.unixSLATime | timeago: live }}
            )</small
          >
        </small>
        <small
          *ngIf="
                                !ticket.unixSLATime ||
                                ticket.category.sla.duration === 0
                            "
          class="text-muted font-weight-bold"
        >
          bez fondu SLA
        </small>
      </div>
    </div>
  </ng-container>
</div>
