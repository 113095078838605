import {ChangeDetectionStrategy, Component} from '@angular/core';

import moment from 'moment';
import localeCs from '@angular/common/locales/cs';
import {registerLocaleData} from '@angular/common';

moment.updateLocale('cs', {workingWeekdays: [1, 2, 3, 4, 5]});
registerLocaleData(localeCs);

@Component({
    selector: 'app-deal-queue',
    templateUrl: './deal-queue.component.html',
    styleUrls: ['./deal-queue.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DealQueueComponent {
    // TODO
}
