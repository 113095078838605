<ejs-dialog
  #formDialog
  (close)="
        isUpdate = false;
        isCreate = false;
        isDirty = false;
        isChecked = false;
        submited = false;
        changeDialogState(false)
    "
  [isModal]="true"
  [showCloseIcon]="true"
  [visible]="isVisible"
  [width]="'85%'"
  id="formDialog"
>
  <form [formGroup]="vacationForm">
    <ng-template #content>
      <div class="dialogContent">
        <div *ngIf="vacation" class="row pb-3">
          <div class="col-12">
            <h5
              *ngIf="vacation.status === 'confirmed'"
              class="e-badge e-badge-success"
            >
              schváleno
            </h5>
            <h5
              *ngIf="vacation.status === 'declined'"
              class="e-badge e-badge-danger"
            >
              zamítnuto
            </h5>
            <h5
              *ngIf="vacation.status === 'deleted'"
              class="e-badge e-badge-danger"
            >
              staženo
            </h5>
            <h5
              *ngIf="vacation.status === 'queue'"
              class="e-badge e-badge-warning"
            >
              ve frontě
            </h5>
          </div>
        </div>
        <div [hidden]="!hidden" class="tab mb-3" id="tab0">
          <div class="row">
            <div class="form-group col-12">
              <label
                [ngClass]="
                                    f.created_by.valid
                                        ? 'text-success'
                                        : f.created_by.invalid
                                        ? 'text-danger'
                                        : 'text-muted'
                                "
                for="created_by"
              >* Zadavatel žádanky:</label
              >
              <ejs-dropdownlist
                #creator
                (filtering)="onFilteringUsers($event)"
                [allowFiltering]="true"
                [dataSource]="usersSelect"
                [enabled]="
                                    (currentUser.rule &&
                                        (currentUser.rule.is_vacation_admin ||
                                            currentUser.rule.is_admin)) ||
                                    currentUser.is_boss
                                "
                [fields]="fields"
                [filterBarPlaceholder]="'Vyhledat uživatele...'"
                [ignoreAccent]="true"
                [placeholder]="'Vyberte zadavatele žádanky'"
                [popupHeight]="height"
                formControlName="created_by"
                id="created_by"
                sortOrder="Ascending"
              ></ejs-dropdownlist>
              <small
                *ngIf="
                                    f.created_by.touched &&
                                    f.created_by.errors &&
                                    f.created_by.errors.required
                                "
                class="text-danger"
              >Zadavatel je povinný</small
              >
            </div>
          </div>
          <div class="row">
            <div class="form-group col-12">
              <label
                [ngClass]="
                                    f.category_id.valid
                                        ? 'text-success'
                                        : f.category_id.invalid
                                        ? 'text-danger'
                                        : 'text-muted'
                                "
                for="category_id"
              >* Typ žádanky:</label
              >
              <ejs-dropdownlist
                #category
                (filtering)="onFilteringCategories($event)"
                [allowFiltering]="true"
                [dataSource]="categorySelect"
                [fields]="fields"
                [filterBarPlaceholder]="'Vyhledat kategorii...'"
                [ignoreAccent]="true"
                [placeholder]="'Vyberte kategorii žádanky'"
                [popupHeight]="height"
                formControlName="category_id"
                id="category_id"
                sortOrder="Ascending"
              ></ejs-dropdownlist>
              <small
                *ngIf="
                                    f.category_id.touched &&
                                    f.category_id.errors &&
                                    f.category_id.errors.required
                                "
                class="text-danger"
              >Typ žádanky je povinný</small
              >
            </div>
          </div>
          <div class="row">
            <div class="form-group col-12">
              <label
                [ngClass]="
                                    f.reason.valid
                                        ? 'text-success'
                                        : f.reason.invalid
                                        ? 'text-danger'
                                        : 'text-muted'
                                "
                for="reason"
              >* Důvod:</label
              >
              <ejs-textbox
                [cssClass]="
                                    f.reason.invalid
                                        ? 'e-error'
                                        : f.reason.valid
                                        ? 'e-success'
                                        : ''
                                "
                floatLabelType="Auto"
                formControlName="reason"
                id="reason"
              ></ejs-textbox>
              <small
                *ngIf="
                                    f.reason.touched &&
                                    f.reason.errors &&
                                    f.reason.errors.required
                                "
                class="text-danger"
              >Důvod žádanky je povinný</small
              >
            </div>
          </div>
          <div class="row">
            <div class="form-group col-12 col-md-6">
              <label
                [ngClass]="
                                    f.start_at.valid
                                        ? 'text-success'
                                        : f.start_at.invalid
                                        ? 'text-danger'
                                        : 'text-muted'
                                "
                for="start_at"
              >* Začátek termínu:</label
              >
              <ejs-datetimepicker
                (renderDayCell)="onRenderCell($event)"
                [firstDayOfWeek]="1"
                [max]="limitDateEnd"
                [min]="limitDateStart"
                floatLabelType="Always"
                formControlName="start_at"
                id="start_at"
              >
              </ejs-datetimepicker>
              <div class="d-flex justify-content-end">
                <small
                  *ngIf="
                                        f.start_at.touched &&
                                        f.start_at.errors &&
                                        f.start_at.errors.required
                                    "
                  class="text-danger"
                >Datum začátku termínu žádanky je
                  povinné</small
                ><br />
                <small
                  *ngIf="
                                        f.start_at.touched &&
                                        f.start_at.errors &&
                                        f.start_at.errors.greaterThan
                                    "
                  class="text-danger"
                >Datum začátku termínu je větší než datum
                  konce</small
                >
                <small
                  *ngIf="
                                        f.start_at.touched &&
                                        f.start_at.errors &&
                                        f.start_at.errors.isInThePast
                                    "
                  class="text-danger"
                >Datum začátku termínu nelze zadávat zpětně
                  (pro zpětné zádání kontaktujte svého
                  nadřízeného)</small
                >
              </div>
            </div>
            <div class="form-group col-12 col-md-6">
              <label
                [ngClass]="
                                    f.end_at.valid
                                        ? 'text-success'
                                        : f.end_at.invalid
                                        ? 'text-danger'
                                        : 'text-muted'
                                "
                for="end_at"
              >* Konec termínu:</label
              >
              <ejs-datetimepicker
                (renderDayCell)="onRenderCell($event)"
                [firstDayOfWeek]="1"
                [max]="limitDateEnd"
                [min]="
                                    f.start_at.value
                                        ? f.start_at.value
                                        : limitDateStart
                                "
                floatLabelType="Always"
                formControlName="end_at"
                id="end_at"
              >
              </ejs-datetimepicker>
              <div class="d-flex justify-content-end">
                <small
                  *ngIf="
                                        f.end_at.touched &&
                                        f.end_at.errors &&
                                        f.end_at.errors.required
                                    "
                  class="text-danger"
                >Datum konce termínu žádanky je
                  povinné</small
                ><br />
                <small
                  *ngIf="
                                        f.end_at.touched &&
                                        f.end_at.errors &&
                                        f.end_at.errors.lessThan
                                    "
                  class="text-danger"
                >Datum konce termínu je menší než datum
                  začátku</small
                >
              </div>
            </div>
          </div>
          <div
            *ngIf="
                            matchedUser && workdays && f.category_id.value === 1
                        "
            class="row"
          >
            <div class="col-12 p-2">
              <div
                *ngIf="
                                    matchedUser.vacation_fond +
                                        (vacation &&
                                        vacation.workdays &&
                                        vacation.confirm
                                            ? vacation.workdays
                                            : 0) -
                                        (statsVacation ? statsVacation : 0) -
                                        workdays ===
                                    0
                                "
                class="alert alert-warning p-1 text-center"
                role="alert"
              >
                <i
                  aria-hidden="true"
                  class="fa-light fa-lg fa-exclamation-triangle mr-2"
                ></i
                >Touto žádankou bude
                <strong>vyčerpán fond dovolené</strong>.
              </div>
              <div
                *ngIf="
                                    matchedUser.vacation_fond +
                                        (vacation &&
                                        vacation.workdays &&
                                        vacation.confirm
                                            ? vacation.workdays
                                            : 0) -
                                        (statsVacation ? statsVacation : 0) -
                                        workdays <
                                    0
                                "
                class="alert alert-danger p-1 text-center"
                role="alert"
              >
                <i
                  aria-hidden="true"
                  class="fa-light fa-lg fa-exclamation-triangle mr-2"
                ></i>
                Touto žádankou bude <strong>přečerpán fond dovolené</strong>. Na tuto žádost nemáte dostatek dní ve
                fondu.
              </div>
            </div>
            <div class="col-12 mt-1 text-right">
              <h6 class="text-muted">
                Fond dovolené uživatele:
                {{ matchedUser.secondname }}
                {{ matchedUser.firstname }}
              </h6>
              <ng-container
                *ngIf="
                                    matchedUser.vacation_fond +
                                        (vacation &&
                                        vacation.workdays &&
                                        vacation.confirm
                                            ? vacation.workdays
                                            : 0) >
                                    0
                                "
              >
                <ejs-progressbar
                  #vacationsPercentage
                  (valueChanged)="changedVacBar($event)"
                  [animation]="animation"
                  [labelStyle]="labelStyle"
                  [showProgressValue]="showProgressValue"
                  [value]="
                                        ((statsVacation + workdays) * 100) /
                                        (matchedUser.vacation_fond +
                                            (vacation &&
                                            vacation.workdays &&
                                            vacation.confirm
                                                ? vacation.workdays
                                                : 0))
                                    "
                  height="50"
                  id="vacationsPercentage"
                  maximum="100"
                  minimum="0"
                  progressThickness="15"
                  trackThickness="15"
                  type="Linear"
                  width="100%"
                ></ejs-progressbar>
              </ng-container>
            </div>
            <div class="col-12 text-right text-primary">
              <span>zbývá ve fondu: </span>
              <strong
                [ngPlural]="
                                    matchedUser.vacation_fond +
                                    (vacation &&
                                    vacation.workdays &&
                                    vacation.confirm
                                        ? vacation.workdays
                                        : 0) -
                                    statsVacation
                                "
              >
                <ng-template ngPluralCase="=0.5"
                >půl den dovolené
                </ng-template>
                <ng-template ngPluralCase="=1"
                >{{
                    matchedUser.vacation_fond +
                    (vacation &&
                    vacation.workdays &&
                    vacation.confirm
                      ? vacation.workdays
                      :0) -
                    statsVacation | number: '1.1-1'
                  }}
                  den dovolené
                </ng-template>
                <ng-template ngPluralCase="=2"
                >{{
                    matchedUser.vacation_fond +
                    (vacation &&
                    vacation.workdays &&
                    vacation.confirm
                      ? vacation.workdays
                      :0) -
                    statsVacation | number: '1.1-1'
                  }}
                  dny dovolené
                </ng-template>
                <ng-template ngPluralCase="=3"
                >{{
                    matchedUser.vacation_fond +
                    (vacation &&
                    vacation.workdays &&
                    vacation.confirm
                      ? vacation.workdays
                      :0) -
                    statsVacation | number: '1.1-1'
                  }}
                  dny dovolené
                </ng-template>
                <ng-template ngPluralCase="=4"
                >{{
                    matchedUser.vacation_fond +
                    (vacation &&
                    vacation.workdays &&
                    vacation.confirm
                      ? vacation.workdays
                      :0) -
                    statsVacation | number: '1.1-1'
                  }}
                  dny dovolené
                </ng-template>
                <ng-template ngPluralCase="other"
                >{{
                    matchedUser.vacation_fond +
                    (vacation &&
                    vacation.workdays &&
                    vacation.confirm
                      ? vacation.workdays
                      :0) -
                    statsVacation | number: '1.1-1'
                  }}
                  dní dovolené
                </ng-template>
              </strong>
            </div>
            <div class="col-12 text-right text-danger">
              <span>žádanka odečte: </span>
              <strong [ngPlural]="workdays" class="mr-2">
                <ng-template ngPluralCase="=0.5"
                >půl den dovolené
                </ng-template>
                <ng-template ngPluralCase="=1"
                >{{ workdays | number: '1.1-1' }} den
                  dovolené
                </ng-template>
                <ng-template ngPluralCase="=2"
                >{{ workdays | number: '1.1-1' }} dny
                  dovolené
                </ng-template>
                <ng-template ngPluralCase="=3"
                >{{ workdays | number: '1.1-1' }} dny
                  dovolené
                </ng-template>
                <ng-template ngPluralCase="=4"
                >{{ workdays | number: '1.1-1' }} dny
                  dovolené
                </ng-template>
                <ng-template ngPluralCase="other"
                >{{ workdays | number: '1.1-1' }} dní
                  dovolené
                </ng-template>
              </strong>
              <small *ngIf="workhours" [ngPlural]="workhours">
                <ng-template ngPluralCase="=1"
                >({{ workhours | number: '1.1-1' }} hodina)
                </ng-template>
                <ng-template ngPluralCase="=2"
                >({{ workhours | number: '1.1-1' }} hodiny)
                </ng-template>
                <ng-template ngPluralCase="=3"
                >({{ workhours | number: '1.1-1' }} hodiny)
                </ng-template>
                <ng-template ngPluralCase="=4"
                >({{ workhours | number: '1.1-1' }} hodiny)
                </ng-template>
                <ng-template ngPluralCase="other"
                >({{ workhours | number: '1.1-1' }} hodin)
                </ng-template>
              </small>
            </div>
            <div class="col-12 text-right text-warning">
              <span>stav fondu po schválení žádanky: </span>
              <strong
                [ngPlural]="
                                    matchedUser.vacation_fond +
                                    (vacation &&
                                    vacation.workdays &&
                                    vacation.confirm
                                        ? vacation.workdays
                                        : 0) -
                                    statsVacation -
                                    workdays
                                "
              >
                <ng-template ngPluralCase="=0.5"
                >půl den dovolené
                </ng-template>
                <ng-template ngPluralCase="=1"
                >{{
                    matchedUser.vacation_fond +
                    (vacation &&
                    vacation.workdays &&
                    vacation.confirm
                      ? vacation.workdays
                      :0) -
                    statsVacation -
                    workdays | number: '1.1-1'
                  }}
                  den dovolené
                </ng-template>
                <ng-template ngPluralCase="=2"
                >{{
                    matchedUser.vacation_fond +
                    (vacation &&
                    vacation.workdays &&
                    vacation.confirm
                      ? vacation.workdays
                      :0) -
                    statsVacation -
                    workdays | number: '1.1-1'
                  }}
                  dny dovolené
                </ng-template>
                <ng-template ngPluralCase="=3"
                >{{
                    matchedUser.vacation_fond +
                    (vacation &&
                    vacation.workdays &&
                    vacation.confirm
                      ? vacation.workdays
                      :0) -
                    statsVacation -
                    workdays | number: '1.1-1'
                  }}
                  dny dovolené
                </ng-template>
                <ng-template ngPluralCase="=4"
                >{{
                    matchedUser.vacation_fond +
                    (vacation &&
                    vacation.workdays &&
                    vacation.confirm
                      ? vacation.workdays
                      :0) -
                    statsVacation -
                    workdays | number: '1.1-1'
                  }}
                  dny dovolené
                </ng-template>
                <ng-template ngPluralCase="other"
                >{{
                    matchedUser.vacation_fond +
                    (vacation &&
                    vacation.workdays &&
                    vacation.confirm
                      ? vacation.workdays
                      :0) -
                    statsVacation -
                    workdays | number: '1.1-1'
                  }}
                  dní dovolené
                </ng-template>
              </strong>
            </div>
          </div>
          <div
            *ngIf="
                            matchedUser && workdays && f.category_id.value === 5
                        "
            class="row"
          >
            <div class="col-12 p-2">
              <div
                *ngIf="
                                    matchedUser.sickdays_fond +
                                        workdays -
                                        (statsSickDays ? statsSickDays : 0) -
                                        workdays ===
                                    0
                                "
                class="alert alert-warning p-1 text-center"
                role="alert"
              >
                <i
                  aria-hidden="true"
                  class="fa-light fa-lg fa-exclamation-triangle mr-2"
                ></i
                >Touto žádankou bude
                <strong>vyčerpán fond sickdays</strong>.
              </div>
              <div
                *ngIf="
                                    matchedUser.sickdays_fond +
                                        workdays -
                                        (statsSickDays ? statsSickDays : 0) -
                                        workdays <
                                    0
                                "
                class="alert alert-danger p-1 text-center"
                role="alert"
              >
                <i
                  aria-hidden="true"
                  class="fa-light fa-lg fa-exclamation-triangle mr-2"
                ></i>
                Touto žádankou bude <strong>přečerpán fond sickdays</strong>. Na tuto žádost nemáte dostatek dní ve
                fondu.
              </div>
            </div>
            <div
              *ngIf="
                                matchedUser.sickdays_fond +
                                    (vacation && vacation.workdays
                                        ? vacation.workdays
                                        : 0) >=
                                0
                            "
              class="col-12 mt-1 text-right"
            >
              <h6 class="text-muted">
                Fond sickdays uživatele:
                {{ matchedUser.secondname }}
                {{ matchedUser.firstname }}
              </h6>
              <ng-container
                *ngIf="
                                    matchedUser.sickdays_fond +
                                        (vacation && vacation.workdays
                                            ? vacation.workdays
                                            : 0) >
                                    0
                                "
              >
                <ejs-progressbar
                  #sickDaysPercentage
                  (valueChanged)="changedSickBar($event)"
                  [animation]="animation"
                  [labelStyle]="labelStyle"
                  [showProgressValue]="showProgressValue"
                  [value]="
                                        ((statsSickDays + workdays) * 100) /
                                        (matchedUser.sickdays_fond +
                                            (vacation && vacation.workdays
                                                ? vacation.workdays
                                                : 0))
                                    "
                  height="50"
                  id="sickDaysPercentage"
                  maximum="100"
                  minimum="0"
                  progressThickness="15"
                  trackThickness="15"
                  type="Linear"
                  width="100%"
                ></ejs-progressbar>
              </ng-container>
            </div>
            <div class="col-12 text-right text-primary">
              <span>zbývá ve fondu: </span>
              <strong
                [ngPlural]="
                                    matchedUser.sickdays_fond +
                                    (vacation && vacation.workdays
                                        ? vacation.workdays
                                        : 0) -
                                    statsSickDays
                                "
              >
                <ng-template ngPluralCase="=0.5"
                >půl den sickdays
                </ng-template>
                <ng-template ngPluralCase="=1"
                >{{
                    matchedUser.sickdays_fond +
                    (vacation && vacation.workdays
                      ? vacation.workdays
                      :0) -
                    statsSickDays | number: '1.1-1'
                  }}
                  den sickdays
                </ng-template>
                <ng-template ngPluralCase="=2"
                >{{
                    matchedUser.sickdays_fond +
                    (vacation && vacation.workdays
                      ? vacation.workdays
                      :0) -
                    statsSickDays | number: '1.1-1'
                  }}
                  dny sickdays
                </ng-template>
                <ng-template ngPluralCase="=3"
                >{{
                    matchedUser.sickdays_fond +
                    (vacation && vacation.workdays
                      ? vacation.workdays
                      :0) -
                    statsSickDays | number: '1.1-1'
                  }}
                  dny sickdays
                </ng-template>
                <ng-template ngPluralCase="=4"
                >{{
                    matchedUser.sickdays_fond +
                    (vacation && vacation.workdays
                      ? vacation.workdays
                      :0) -
                    statsSickDays | number: '1.1-1'
                  }}
                  dny sickdays
                </ng-template>
                <ng-template ngPluralCase="other"
                >{{
                    matchedUser.sickdays_fond +
                    (vacation && vacation.workdays
                      ? vacation.workdays
                      :0) -
                    statsSickDays | number: '1.1-1'
                  }}
                  dní sickdays
                </ng-template>
              </strong>
            </div>
            <div class="col-12 text-right text-danger">
              <span>žádanka odečte: </span>
              <strong [ngPlural]="workdays" class="mr-2">
                <ng-template ngPluralCase="=0.5"
                >půl den sickdays
                </ng-template>
                <ng-template ngPluralCase="=1"
                >{{ workdays | number: '1.1-1' }} den
                  sickdays
                </ng-template>
                <ng-template ngPluralCase="=2"
                >{{ workdays | number: '1.1-1' }} dny
                  sickdays
                </ng-template>
                <ng-template ngPluralCase="=3"
                >{{ workdays | number: '1.1-1' }} dny
                  sickdays
                </ng-template>
                <ng-template ngPluralCase="=4"
                >{{ workdays | number: '1.1-1' }} dny
                  sickdays
                </ng-template>
                <ng-template ngPluralCase="other"
                >{{ workdays | number: '1.1-1' }} dní
                  sickdays
                </ng-template>
              </strong>
              <small *ngIf="workhours" [ngPlural]="workhours">
                <ng-template ngPluralCase="=1"
                >({{ workhours | number: '1.1-1' }} hodina)
                </ng-template>
                <ng-template ngPluralCase="=2"
                >({{ workhours | number: '1.1-1' }} hodiny)
                </ng-template>
                <ng-template ngPluralCase="=3"
                >({{ workhours | number: '1.1-1' }} hodiny)
                </ng-template>
                <ng-template ngPluralCase="=4"
                >({{ workhours | number: '1.1-1' }} hodiny)
                </ng-template>
                <ng-template ngPluralCase="other"
                >({{ workhours | number: '1.1-1' }} hodin)
                </ng-template>
              </small>
            </div>
            <div class="col-12 text-right text-warning">
              <span>stav fondu po schválení žádanky: </span>
              <strong
                [ngPlural]="
                                    matchedUser.sickdays_fond +
                                    (vacation && vacation.workdays
                                        ? vacation.workdays
                                        : 0) -
                                    statsSickDays -
                                    workdays
                                "
              >
                <ng-template ngPluralCase="=0.5"
                >půl den sickdays
                </ng-template>
                <ng-template ngPluralCase="=1"
                >{{
                    matchedUser.sickdays_fond +
                    (vacation && vacation.workdays
                      ? vacation.workdays
                      :0) -
                    statsSickDays -
                    workdays | number: '1.1-1'
                  }}
                  den sickdays
                </ng-template>
                <ng-template ngPluralCase="=2"
                >{{
                    matchedUser.sickdays_fond +
                    (vacation && vacation.workdays
                      ? vacation.workdays
                      :0) -
                    statsSickDays -
                    workdays | number: '1.1-1'
                  }}
                  dny sickdays
                </ng-template>
                <ng-template ngPluralCase="=3"
                >{{
                    matchedUser.sickdays_fond +
                    (vacation && vacation.workdays
                      ? vacation.workdays
                      :0) -
                    statsSickDays -
                    workdays | number: '1.1-1'
                  }}
                  dny sickdays
                </ng-template>
                <ng-template ngPluralCase="=4"
                >{{
                    matchedUser.sickdays_fond +
                    (vacation && vacation.workdays
                      ? vacation.workdays
                      :0) -
                    statsSickDays -
                    workdays | number: '1.1-1'
                  }}
                  dny sickdays
                </ng-template>
                <ng-template ngPluralCase="other"
                >{{
                    matchedUser.sickdays_fond +
                    (vacation && vacation.workdays
                      ? vacation.workdays
                      :0) -
                    statsSickDays -
                    workdays | number: '1.1-1'
                  }}
                  dní sickdays
                </ng-template>
              </strong>
            </div>
          </div>
        </div>
        <div [hidden]="hidden" class="tab mb-3" id="tab1">
          <app-vacation-status></app-vacation-status>
        </div>
      </div>
    </ng-template>
    <ng-template #footerTemplate>
      <div class="d-flex justify-content-end">
                <span
                  *ngIf="vacationForm.invalid"
                  class="e-badge e-badge-warning p-2 size_2"
                  role="alert"
                >
                    <strong>Formulář je špatně vyplněný</strong>
                </span>
        <span
          *ngIf="vacationForm.valid"
          class="e-badge e-badge-success p-2 size_2"
          role="alert"
        >
                    <strong>Formulář je správně vyplněný</strong>
                </span>
      </div>
      <div class="row">
        <div class="col-12 pt-2">
          <button
            (click)="previousBtn()"
            [disabled]="currentTab === 0"
            cssClass="e-info e-btn btn-p-zero e-small m-1"
            ejs-button
            type="button"
          >
            Předchozí krok
          </button>
          <button
            (click)="nextBtn()"
            [disabled]="vacationForm.invalid || currentTab === 1"
            cssClass="e-info e-btn btn-p-zero e-small m-1"
            ejs-button
            type="button"
          >
            Další krok
          </button>
          <button
            (click)="onSubmit()"
            [disabled]="
                            loadingVacation ||
                            vacationForm.invalid ||
                            !isChecked ||
                            currentTab === 0
                        "
            cssClass="e-btn e-success btn-p-zero e-small m-1"
            ejs-button
            type="button"
          >
                        <span
                          *ngIf="loadingVacation"
                          aria-hidden="true"
                          class="spinner-grow spinner-grow-sm"
                          role="status"
                        ></span>
            <span *ngIf="!loadingVacation">ULOŽIT</span>
            <span *ngIf="loadingVacation"
            >Provádím...</span
            >
          </button>
          <button
            (click)="formDialogObj.hide()"
            cssClass="e-btn e-danger e-outline btn-p-zero e-small m-1"
            ejs-button
            type="button"
          >
            ZRUŠIT
          </button>
        </div>
      </div>
    </ng-template>
  </form>
</ejs-dialog>
