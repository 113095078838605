import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {SharedModule} from '@src/app/shared_components/shared.module';
import {FileViewerComponent} from '@src/app/shared_features/components/file-viewer/file-viewer.component';
import {TagInputModule} from 'ngx-chips';
import {AddressValidatorComponent} from '@src/app/shared_features/components/address-validator/address-validator.component';
import {LoaderComponent} from './components/loader/loader.component';

TagInputModule.withDefaults({
    tagInput: {
        placeholder: 'Přidat nový štítek',
        secondaryPlaceholder: '+ přidat štítek',
    },
    dropdown: {
        showDropdownIfEmpty: true,
        keepOpen: false,
        focusFirstElement: true,
        minimumTextLength: 1,
        limitItemsTo: 20,
        zIndex: 100000,
    },
});

@NgModule({
    declarations: [AddressValidatorComponent, FileViewerComponent, LoaderComponent],
    imports: [
        BrowserAnimationsModule,
        BrowserModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        SharedModule,
    ],
    exports: [AddressValidatorComponent, FileViewerComponent, LoaderComponent, SharedModule],
})
export class SharedFeaturesModule {}
