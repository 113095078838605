<ejs-dialog
  #deleteFileDialog
  [buttons]="buttonsDeleteFileDialog"
  [content]="'Opravdu chcete smazat tento soubor?'"
  [header]="'Smazat soubor'"
  [height]="'200px'"
  [isModal]="true"
  [showCloseIcon]="true"
  [visible]="false"
  [width]="'450px'"
  id="deleteFileDialog"
  target="#formDialog"
></ejs-dialog>
<div class="e-card" tabindex="0">
  <ng-container *ngIf="knowledge">
    <div class="e-card-content">
      <ejs-uploader
        #preloadupload
        (beforeUpload)="beforeFileUpload($event)"
        (selected)="onFileSelected($event)"
        (success)="onSuccessUpload($event)"
        (uploading)="onFileUpload($event)"
        [asyncSettings]="path"
        [dropArea]="dropElement"
        [enabled]="!knowledge.deleted_date"
        allowedExtensions=".jpg,.png,.jpeg,.xls,.xlsx,.pdf,.doc,.docx,.rar,.zip,.gif,.csv,.ics,.json,.mp3,.mpeg,.mp4,.odt,.ods,.ppt,.pptx,.txt,.xml"
        id="preloadfileupload"
        maxFileSize="400000000"
      >
      </ejs-uploader>
      <div
        class="e-card-separator mb-1 mt-1"
      ></div>
      <app-file-viewer
        (deleteFileEventEvent)="deleteFileId = $event; removeKnowledgeFile()"
        [files]="knowledge.files"
        [images]="imagesBasic"
      ></app-file-viewer>
    </div>
  </ng-container>
</div>
