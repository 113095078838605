import {ChangeDetectionStrategy, Component} from '@angular/core';
import {UntilDestroy} from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
    selector: 'app-cars-detail',
    templateUrl: './cars-detail.component.html',
    styleUrls: ['./cars-detail.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CarsDetailComponent {}
