<ejs-dialog
  #formDialog
  (close)="
        isUpdate = false;
        isCreate = false;
        isDirty = false;
        isChecked = false;
        isCopy = false;
        submited = false;
        changeDialogState(false)
    "
  [isModal]="true"
  [showCloseIcon]="true"
  [visible]="isVisible"
  [width]="'85%'"
  id="formDialog"
>
  <form [formGroup]="departmentForm">
    <ng-template #content>
      <div class="dialogContent">
        <div class="row">
          <div class="col-12 mb-4">
            <label
              [ngClass]="
                                f.name.valid
                                    ? 'text-success'
                                    : f.name.invalid
                                    ? 'text-danger'
                                    : 'text-muted'
                            "
              for="name"
            >* Název oddělení:</label
            >
            <ejs-textbox
              [ngClass]="
                                f.name.invalid
                                    ? 'e-error'
                                    : f.name.valid
                                    ? 'e-success'
                                    : ''
                            "
              floatLabelType="Auto"
              formControlName="name"
              id="name"
            ></ejs-textbox>
            <small
              *ngIf="
                                f.name.touched &&
                                f.name.errors &&
                                f.name.errors.required
                            "
              class="text-danger"
            >Název oddělení je povinný</small
            >
          </div>
          <div class="col-12 mb-4">
            <label
              [ngClass]="
                                f.company.valid
                                    ? 'text-success'
                                    : f.company.invalid
                                    ? 'text-danger'
                                    : 'text-muted'
                            "
              for="company"
            >* Společnost:</label
            >
            <ejs-dropdownlist
              #company
              (filtering)="onFilteringCompanies($event)"
              [allowFiltering]="true"
              [cssClass]="
                                f.company.invalid
                                    ? 'e-error'
                                    : f.company.valid
                                    ? 'e-success'
                                    : ''
                            "
              [dataSource]="companySelect"
              [fields]="fields"
              [filterBarPlaceholder]="'Vyhledat společnost...'"
              [ignoreAccent]="true"
              [placeholder]="'Vyberte společnost'"
              [popupHeight]="height"
              formControlName="company"
              id="company"
              sortOrder="Ascending"
            ></ejs-dropdownlist>
            <small
              *ngIf="
                                f.company.touched &&
                                f.company.errors &&
                                f.company.errors.required
                            "
              class="text-danger"
            >Společnost je povinná</small
            >
          </div>
          <div class="col-12">
            <div class="e-card">
              <div class="card-header">
                Přidat uživatele do oddělení
                <button
                  (click)="addAssignation()"
                  cssClass="e-btn e-success btn-p-zero e-small mr-1 float-right"
                  ejs-button
                  type="button"
                >
                  Přidat uživatele
                </button>
              </div>
              <div
                class="e-card-content"
                formArrayName="usersAssignation"
              >
                <div class="row">
                  <div
                    *ngFor="
                                            let user of assignationformGroup.controls;
                                            let i = index
                                        "
                    class="col-12"
                  >
                    <div [formGroupName]="i" class="row">
                      <div
                        class="form-group col-12 col-md-5 mb-4"
                      >
                        <label
                          [ngClass]="
                                                        getAssignationFormGroup(
                                                            i
                                                        ).controls['user']
                                                            .valid &&
                                                        (getAssignationFormGroup(
                                                            i
                                                        ).controls['user']
                                                            .dirty ||
                                                            getAssignationFormGroup(
                                                                i
                                                            ).controls['user']
                                                                .touched)
                                                            ? 'text-success active'
                                                            : getAssignationFormGroup(
                                                                  i
                                                              ).controls['user']
                                                                  .invalid
                                                            ? 'text-danger active'
                                                            : 'text-muted active'
                                                    "
                          for="user-{{ i }}"
                        >Uživatel:</label
                        >
                        <ejs-dropdownlist
                          #user
                          (filtering)="
                                                        onFilteringUsers($event)
                                                    "
                          [allowFiltering]="true"
                          [cssClass]="
                                                        getAssignationFormGroup(
                                                            i
                                                        ).controls['user']
                                                            .invalid &&
                                                        (getAssignationFormGroup(
                                                            i
                                                        ).controls['user']
                                                            .dirty ||
                                                            getAssignationFormGroup(
                                                                i
                                                            ).controls['user']
                                                                .touched)
                                                            ? 'e-error'
                                                            : getAssignationFormGroup(
                                                                  i
                                                              ).controls['user']
                                                                  .valid &&
                                                              (getAssignationFormGroup(
                                                                  i
                                                              ).controls['user']
                                                                  .dirty ||
                                                                  getAssignationFormGroup(
                                                                      i
                                                                  ).controls[
                                                                      'user'
                                                                  ].touched)
                                                            ? 'e-success'
                                                            : ''
                                                    "
                          [dataSource]="usersSelect"
                          [fields]="fields"
                          [filterBarPlaceholder]="
                                                        'Vyhledat uživatele...'
                                                    "
                          [ignoreAccent]="true"
                          [placeholder]="
                                                        'Vyberte uživatele'
                                                    "
                          [popupHeight]="height"
                          formControlName="user"
                          id="user-{{ i }}"
                          sortOrder="Ascending"
                        ></ejs-dropdownlist>
                        <small
                          *ngIf="
                                                        getAssignationFormGroup(
                                                            i
                                                        ).controls['user']
                                                            .errors &&
                                                        getAssignationFormGroup(
                                                            i
                                                        ).controls[
                                                            'user'
                                                        ].hasError('required')
                                                    "
                          class="text-danger"
                        >Vyberte jednoho z
                          uživatelů, nebo odeberte
                          pole</small
                        >
                      </div>
                      <div
                        class="form-group col-12 col-md-5 mb-4"
                      >
                        <label
                          [ngClass]="
                                                        getAssignationFormGroup(
                                                            i
                                                        ).controls['role']
                                                            .valid &&
                                                        (getAssignationFormGroup(
                                                            i
                                                        ).controls['role']
                                                            .dirty ||
                                                            getAssignationFormGroup(
                                                                i
                                                            ).controls['role']
                                                                .touched)
                                                            ? 'text-success active'
                                                            : getAssignationFormGroup(
                                                                  i
                                                              ).controls['role']
                                                                  .invalid
                                                            ? 'text-danger active'
                                                            : 'text-muted active'
                                                    "
                          for="role-{{ i }}"
                        >Pozice:</label
                        >
                        <ejs-dropdownlist
                          #role
                          (filtering)="
                                                        onFilteringRoles($event)
                                                    "
                          [allowFiltering]="true"
                          [cssClass]="
                                                        getAssignationFormGroup(
                                                            i
                                                        ).controls['role']
                                                            .invalid &&
                                                        (getAssignationFormGroup(
                                                            i
                                                        ).controls['role']
                                                            .dirty ||
                                                            getAssignationFormGroup(
                                                                i
                                                            ).controls['role']
                                                                .touched)
                                                            ? 'e-error'
                                                            : getAssignationFormGroup(
                                                                  i
                                                              ).controls['role']
                                                                  .valid &&
                                                              (getAssignationFormGroup(
                                                                  i
                                                              ).controls['role']
                                                                  .dirty ||
                                                                  getAssignationFormGroup(
                                                                      i
                                                                  ).controls[
                                                                      'role'
                                                                  ].touched)
                                                            ? 'e-success'
                                                            : ''
                                                    "
                          [dataSource]="roleSelect"
                          [fields]="fields"
                          [filterBarPlaceholder]="
                                                        'Vyhledat pozici...'
                                                    "
                          [ignoreAccent]="true"
                          [placeholder]="
                                                        'Vyberte pozici'
                                                    "
                          [popupHeight]="height"
                          formControlName="role"
                          id="role-{{ i }}"
                          sortOrder="Ascending"
                        ></ejs-dropdownlist>
                        <small
                          *ngIf="
                                                        getAssignationFormGroup(
                                                            i
                                                        ).controls['role']
                                                            .errors &&
                                                        getAssignationFormGroup(
                                                            i
                                                        ).controls[
                                                            'role'
                                                        ].hasError('required')
                                                    "
                          class="text-danger"
                        >Vyberte jednu z pozic, nebo
                          odeberte pole</small
                        >
                      </div>
                      <div
                        class="form-group col-12 col-md-2 text-right"
                      >
                        <button
                          (click)="
                                                        removeAssignation(i)
                                                    "
                          cssClass="e-btn e-danger btn-p-zero e-small"
                          ejs-button
                        >
                          Odebrat
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
    <ng-template #footerTemplate>
      <div class="d-flex justify-content-end">
                <span
                  *ngIf="departmentForm.invalid"
                  class="e-badge e-badge-warning p-2 size_2"
                  role="alert"
                >
                    <strong>Formulář je špatně vyplněný</strong>
                </span>
        <span
          *ngIf="departmentForm.valid"
          class="e-badge e-badge-success p-2 size_2"
          role="alert"
        >
                    <strong>Formulář je správně vyplněný</strong>
                </span>
      </div>
      <div class="row">
        <div class="col-12 pt-2">
          <button
            (click)="onSubmit()"
            [disabled]="
                            loadingDepartment ||
                            departmentForm.invalid ||
                            !isChecked
                        "
            cssClass="e-btn e-success btn-p-zero e-small mr-1"
            ejs-button
            type="submit"
          >
                        <span
                          *ngIf="loadingDepartment === true"
                          aria-hidden="true"
                          class="spinner-grow spinner-grow-sm"
                          role="status"
                        ></span>
            <span *ngIf="loadingDepartment === false">ULOŽIT</span>
            <span *ngIf="loadingDepartment === true"
            >Provádím...</span
            >
          </button>
          <button
            (click)="formDialogObj.hide()"
            cssClass="e-btn e-danger e-outline btn-p-zero e-small"
            ejs-button
            type="button"
          >
            ZRUŠIT
          </button>
        </div>
      </div>
    </ng-template>
  </form>
</ejs-dialog>
