<ejs-dialog
  #deleteFileDialog
  [buttons]="buttonsDeleteFileDialog"
  [content]="'Opravdu chcete smazat tento soubor?'"
  [header]="'Smazat soubor'"
  [height]="'200px'"
  [isModal]="true"
  [showCloseIcon]="true"
  [visible]="false"
  [width]="'450px'"
  id="deleteFileDialog"
  target="#formDialog"
></ejs-dialog>
<ejs-dialog
  #formDialog
  (close)="
        isUpdate = false;
        isCreate = false;
        isDirty = false;
        isChecked = false;
        isCopy = false;
        submited = false;
        changeDialogState(false)
    "
  [isModal]="true"
  [showCloseIcon]="true"
  [visible]="isVisible"
  [width]="'85%'"
  id="formDialog"
>
  <ng-template #content>
    <div class="dialogContent">
      <div class="row">
        <div class="col-12 col-md-8">
          <form (submit)="onSubmit()" [formGroup]="materialForm">
            <div class="e-card">
              <div class="card-header">Základní data</div>
              <div class="e-card-content">
                <div class="row p-3">
                  <div class="col-12 form-group">
                    <label
                      [ngClass]="
                                                f.reason.valid &&
                                                (f.reason.dirty ||
                                                    f.reason.touched)
                                                    ? 'text-success'
                                                    : f.reason.invalid &&
                                                      (f.reason.dirty ||
                                                          f.reason.touched)
                                                    ? 'text-danger'
                                                    : 'text-muted'
                                            "
                      for="materialForm-reason"
                    >* Důvod objednávky
                      materiálu:</label
                    >
                    <ejs-textbox
                      [ngClass]="
                                                f.reason.touched &&
                                                f.reason.invalid
                                                    ? 'e-error'
                                                    : f.reason.touched &&
                                                      f.reason.valid
                                                    ? 'e-success'
                                                    : ''
                                            "
                      floatLabelType="Auto"
                      formControlName="reason"
                      id="materialForm-reason"
                    ></ejs-textbox>
                    <small
                      *ngIf="
                                                f.reason.touched &&
                                                f.reason.errors &&
                                                f.reason.errors.required
                                            "
                      class="text-danger"
                    >Důvod objednávky materiálu je
                      povinný</small
                    >
                  </div>
                  <div class="col-12 col-md-6 form-group">
                    <label
                      [ngClass]="
                                                f.payment.valid
                                                    ? 'text-success'
                                                    : f.payment.invalid &&
                                                      (f.payment.dirty ||
                                                          f.payment.touched)
                                                    ? 'text-danger'
                                                    : 'text-muted'
                                            "
                      for="materialForm-payment"
                    >* Typ platby:</label
                    >
                    <ejs-dropdownlist
                      #payment
                      (filtering)="
                                                onFilteringPayments($event)
                                            "
                      [allowFiltering]="true"
                      [cssClass]="
                                                f.payment.invalid
                                                    ? 'e-error'
                                                    : f.payment.valid
                                                    ? 'e-success'
                                                    : ''
                                            "
                      [dataSource]="paymentSelect"
                      [fields]="fields"
                      [filterBarPlaceholder]="
                                                'Vyhledat typ platby...'
                                            "
                      [ignoreAccent]="true"
                      [placeholder]="'Vyberte typ platby'"
                      [popupHeight]="height"
                      formControlName="payment"
                      id="materialForm-payment"
                      sortOrder="Ascending"
                    ></ejs-dropdownlist>
                    <small
                      *ngIf="
                                                f.payment.touched &&
                                                f.payment.errors &&
                                                f.payment.errors.required
                                            "
                      class="text-danger"
                    >Vyberte alespoň jeden typ
                      platby</small
                    >
                  </div>
                  <div class="col-12 col-md-6 form-group">
                    <div class="d-flex mb-4">
                      <i
                        (click)="
                                                    showHint(
                                                        'Objednávky materiálu pro doplnění skladových zásob.'
                                                    )
                                                "
                        aria-hidden="true"
                        class="fa-light fa-lg fa-question-circle mr-2 pt-1"
                        style="cursor: pointer"
                      ></i>
                      <ejs-radiobutton
                        checked="true"
                        formControlName="to_storage"
                        id="materialForm-to_storage"
                        label="Materiál na doplnění skladových zásob"
                        value="to_storage"
                      ></ejs-radiobutton>
                    </div>
                    <div class="d-flex">
                      <i
                        (click)="
                                                    showHint(
                                                        'Objednávky materiálu označené pro prodej se neodečte z přiděleného rozpočtu, protože se očekává okamžitá nebo krátkodobá návratnost. Tento typ objednávky je určen pro zboží na přeprodej.'
                                                    )
                                                "
                        aria-hidden="true"
                        class="fa-light fa-lg fa-question-circle mr-2 pt-1"
                        style="cursor: pointer"
                      ></i>
                      <ejs-radiobutton
                        formControlName="to_storage"
                        id="materialForm-for_sale"
                        label="Materiál na přeprodej"
                        value="for_sale"
                      ></ejs-radiobutton>
                    </div>
                  </div>
                  <div *ngIf="isUpdate" class="col-12 mb-3">
                    <label
                      class="text-muted"
                      for="preloadfileupload"
                    >Již nahrané faktury/účtenky:</label
                    >
                    <br />
                    <ng-container
                      *ngIf="
                                                material &&
                                                material.receipts.length
                                            "
                    >
                      <div
                        *ngFor="
                                                    let receipt of material.receipts
                                                "
                        class="e-card"
                      >
                        <div class="e-card-content row">
                          <div class="col-3">
                            {{
                              receipt.name
                                | fileExtension
                                : false
                            }}
                          </div>
                          <div
                            class="col-3 text-center"
                          >
                            {{
                              receipt.size
                                | humanizeBytes
                            }}
                          </div>
                          <div
                            class="col-3 text-center"
                          >
                            {{
                              receipt.name
                                | fileExtension
                                : true
                            }}
                          </div>
                          <div
                            class="col-3 text-right"
                          >
                            <i
                              (click)="
                                                                deleteFileID =
                                                                    receipt.id;
                                                                deleteFileDialogObj.show()
                                                            "
                              class="fa-light fa-lg fa-trash text-danger pr-4 align-middle"
                              style="
                                                                cursor: pointer;
                                                            "
                            ></i>
                          </div>
                        </div>
                      </div>
                    </ng-container>
                  </div>
                  <div class="col-12 mb-3">
                    <label
                      [ngClass]="
                                                f.receipt.valid
                                                    ? 'text-success'
                                                    : f.receipt.invalid &&
                                                      (f.receipt.dirty ||
                                                          f.receipt.touched)
                                                    ? 'text-danger'
                                                    : 'text-muted'
                                            "
                      for="preloadfileupload"
                    >Nové Faktury/Účtenky:</label
                    >
                    <ejs-uploader
                      #preloaduploadMaterial
                      (removing)="onFileRemove($event)"
                      (selected)="onFileSelected($event)"
                      [asyncSettings]="path"
                      [autoUpload]="autoUpload"
                      [dropArea]="dropElement"
                      allowedExtensions=".jpg,.png,.jpeg,.xls,.xlsx,.pdf,.doc,.docx,.rar,.zip,.csv,.json,.odt,.ods,.txt,.xml"
                      formControlName="receipt"
                      id="preloadfileupload"
                      maxFileSize="400000000"
                      multiple="true"
                    >
                    </ejs-uploader>
                    <small
                      *ngIf="
                                                (f.receipt.dirty ||
                                                    f.receipt.touched) &&
                                                f.receipt.errors &&
                                                f.receipt.errors.required
                                            "
                      class="text-danger"
                    >Faktura/účtenka je povinná</small
                    >
                  </div>
                </div>
              </div>
            </div>
            <div class="e-card">
              <div
                [ngClass]="emptyItems ? 'text-danger' : ''"
                class="card-header"
              >
                Přidat položky objednávky
                <button
                  (click)="addItem()"
                  cssClass="e-btn e-success btn-p-zero e-small mr-1 float-right"
                  ejs-button
                  type="button"
                >
                  Přidat položku
                </button>
                <ng-container *ngIf="emptyItems">
                  <br />
                  <small class="text-danger"
                  >Objednávka musí obsahovat alespoň jednu
                    položku!</small
                  >
                </ng-container>
              </div>
              <div
                class="e-card-content"
                formArrayName="orderItems"
              >
                <div class="row p-3">
                  <div
                    *ngFor="
                                            let f of orderItemsFormGroup.controls;
                                            let i = index
                                        "
                    class="col-12"
                  >
                    <div [formGroupName]="i" class="row">
                      <div class="col-12 col-md-11">
                        <div class="row">
                          <div
                            class="col-12 form-group"
                          >
                            <ejs-numerictextbox
                              [format]="'N'"
                              [hidden]="true"
                              formControlName="item_id"
                              id="materialForm-item{{
                                                                i
                                                            }}-item_id"
                            ></ejs-numerictextbox>
                            <label
                              [ngClass]="
                                                                getOrderItemsFormGroup(
                                                                    i
                                                                ).controls[
                                                                    'name'
                                                                ].valid &&
                                                                (getOrderItemsFormGroup(
                                                                    i
                                                                ).controls[
                                                                    'name'
                                                                ].dirty ||
                                                                    getOrderItemsFormGroup(
                                                                        i
                                                                    ).controls[
                                                                        'name'
                                                                    ].touched)
                                                                    ? 'text-success'
                                                                    : getOrderItemsFormGroup(
                                                                          i
                                                                      )
                                                                          .controls[
                                                                          'name'
                                                                      ]
                                                                          .invalid &&
                                                                      (getOrderItemsFormGroup(
                                                                          i
                                                                      )
                                                                          .controls[
                                                                          'name'
                                                                      ].dirty ||
                                                                          getOrderItemsFormGroup(
                                                                              i
                                                                          )
                                                                              .controls[
                                                                              'name'
                                                                          ]
                                                                              .touched)
                                                                    ? 'text-danger'
                                                                    : 'text-muted'
                                                            "
                              for="materialForm-item{{
                                                                i
                                                            }}-name"
                            >* Název:</label
                            >
                            <ejs-textbox
                              [ngClass]="
                                                                getOrderItemsFormGroup(
                                                                    i
                                                                ).controls[
                                                                    'name'
                                                                ].touched &&
                                                                getOrderItemsFormGroup(
                                                                    i
                                                                ).controls[
                                                                    'name'
                                                                ].invalid
                                                                    ? 'e-error'
                                                                    : getOrderItemsFormGroup(
                                                                          i
                                                                      )
                                                                          .controls[
                                                                          'name'
                                                                      ]
                                                                          .touched &&
                                                                      getOrderItemsFormGroup(
                                                                          i
                                                                      )
                                                                          .controls[
                                                                          'name'
                                                                      ].valid
                                                                    ? 'e-success'
                                                                    : ''
                                                            "
                              floatLabelType="Auto"
                              formControlName="name"
                              id="materialForm-item{{
                                                                i
                                                            }}-name"
                            ></ejs-textbox>
                            <small
                              *ngIf="
                                                                getOrderItemsFormGroup(
                                                                    i
                                                                ).controls[
                                                                    'name'
                                                                ].touched &&
                                                                getOrderItemsFormGroup(
                                                                    i
                                                                ).controls[
                                                                    'name'
                                                                ].errors &&
                                                                getOrderItemsFormGroup(
                                                                    i
                                                                ).controls[
                                                                    'name'
                                                                ].hasError(
                                                                    'required'
                                                                )
                                                            "
                              class="text-danger"
                            >Název položky je
                              povinný</small
                            >
                          </div>
                          <div
                            class="col-12 col-md-6 form-group"
                          >
                            <label
                              [ngClass]="
                                                                getOrderItemsFormGroup(
                                                                    i
                                                                ).controls[
                                                                    'department_id'
                                                                ].valid &&
                                                                (getOrderItemsFormGroup(
                                                                    i
                                                                ).controls[
                                                                    'department_id'
                                                                ].dirty ||
                                                                    getOrderItemsFormGroup(
                                                                        i
                                                                    ).controls[
                                                                        'department_id'
                                                                    ].touched)
                                                                    ? 'text-success'
                                                                    : getOrderItemsFormGroup(
                                                                          i
                                                                      )
                                                                          .controls[
                                                                          'department_id'
                                                                      ]
                                                                          .invalid &&
                                                                      (getOrderItemsFormGroup(
                                                                          i
                                                                      )
                                                                          .controls[
                                                                          'department_id'
                                                                      ].dirty ||
                                                                          getOrderItemsFormGroup(
                                                                              i
                                                                          )
                                                                              .controls[
                                                                              'department_id'
                                                                          ]
                                                                              .touched)
                                                                    ? 'text-danger'
                                                                    : 'text-muted'
                                                            "
                              for="materialForm-item{{
                                                                i
                                                            }}-department"
                            >* Oddělení:</label
                            >
                            <ejs-dropdownlist
                              #department
                              (filtering)="
                                                                onFilteringDepartments(
                                                                    $event
                                                                )
                                                            "
                              [allowFiltering]="
                                                                true
                                                            "
                              [cssClass]="
                                                                getOrderItemsFormGroup(
                                                                    i
                                                                ).controls[
                                                                    'department_id'
                                                                ].invalid
                                                                    ? 'e-error'
                                                                    : getOrderItemsFormGroup(
                                                                          i
                                                                      )
                                                                          .controls[
                                                                          'department_id'
                                                                      ] &&
                                                                      getOrderItemsFormGroup(
                                                                          i
                                                                      )
                                                                          .controls[
                                                                          'department_id'
                                                                      ].valid
                                                                    ? 'e-success'
                                                                    : ''
                                                            "
                              [dataSource]="
                                                                departmentSelect
                                                            "
                              [fields]="fields"
                              [filterBarPlaceholder]="
                                                                'Vyhledat oddělení...'
                                                            "
                              [ignoreAccent]="
                                                                true
                                                            "
                              [placeholder]="
                                                                'Vyberte oddělení'
                                                            "
                              [popupHeight]="
                                                                height
                                                            "
                              formControlName="department_id"
                              id="materialForm-item{{
                                                                i
                                                            }}-department"
                              sortOrder="Ascending"
                            ></ejs-dropdownlist>
                            <small
                              *ngIf="
                                                                getOrderItemsFormGroup(
                                                                    i
                                                                ).controls[
                                                                    'department_id'
                                                                ].touched &&
                                                                getOrderItemsFormGroup(
                                                                    i
                                                                ).controls[
                                                                    'department_id'
                                                                ].errors &&
                                                                getOrderItemsFormGroup(
                                                                    i
                                                                ).controls[
                                                                    'department_id'
                                                                ].hasError(
                                                                    'required'
                                                                )
                                                            "
                              class="text-danger"
                            >Vyberte jedno z
                              oddělení</small
                            >
                          </div>
                          <div
                            class="col-12 col-md-6 form-group"
                          >
                            <label
                              [ngClass]="
                                                                getOrderItemsFormGroup(
                                                                    i
                                                                ).controls[
                                                                    'category_id'
                                                                ].valid &&
                                                                (getOrderItemsFormGroup(
                                                                    i
                                                                ).controls[
                                                                    'category_id'
                                                                ].dirty ||
                                                                    getOrderItemsFormGroup(
                                                                        i
                                                                    ).controls[
                                                                        'category_id'
                                                                    ].touched)
                                                                    ? 'text-success'
                                                                    : getOrderItemsFormGroup(
                                                                          i
                                                                      )
                                                                          .controls[
                                                                          'category_id'
                                                                      ]
                                                                          .invalid &&
                                                                      (getOrderItemsFormGroup(
                                                                          i
                                                                      )
                                                                          .controls[
                                                                          'category_id'
                                                                      ].dirty ||
                                                                          getOrderItemsFormGroup(
                                                                              i
                                                                          )
                                                                              .controls[
                                                                              'category_id'
                                                                          ]
                                                                              .touched)
                                                                    ? 'text-danger'
                                                                    : 'text-muted'
                                                            "
                              for="materialForm-item{{
                                                                i
                                                            }}-category"
                            >* Kategorie:</label
                            >
                            <ejs-dropdownlist
                              #category
                              (filtering)="
                                                                onFilteringCategory(
                                                                    $event
                                                                )
                                                            "
                              [allowFiltering]="
                                                                true
                                                            "
                              [cssClass]="
                                                                getOrderItemsFormGroup(
                                                                    i
                                                                ).controls[
                                                                    'category_id'
                                                                ].invalid
                                                                    ? 'e-error'
                                                                    : getOrderItemsFormGroup(
                                                                          i
                                                                      )
                                                                          .controls[
                                                                          'category_id'
                                                                      ] &&
                                                                      getOrderItemsFormGroup(
                                                                          i
                                                                      )
                                                                          .controls[
                                                                          'category_id'
                                                                      ].valid
                                                                    ? 'e-success'
                                                                    : ''
                                                            "
                              [dataSource]="
                                                                categorySelect
                                                            "
                              [fields]="fields"
                              [filterBarPlaceholder]="
                                                                'Vyhledat kategorii...'
                                                            "
                              [ignoreAccent]="
                                                                true
                                                            "
                              [placeholder]="
                                                                'Vyberte kategorii'
                                                            "
                              [popupHeight]="
                                                                height
                                                            "
                              formControlName="category_id"
                              id="materialForm-item{{
                                                                i
                                                            }}-category"
                              sortOrder="Ascending"
                            ></ejs-dropdownlist>
                            <small
                              *ngIf="
                                                                getOrderItemsFormGroup(
                                                                    i
                                                                ).controls[
                                                                    'category_id'
                                                                ].touched &&
                                                                getOrderItemsFormGroup(
                                                                    i
                                                                ).controls[
                                                                    'category_id'
                                                                ].errors &&
                                                                getOrderItemsFormGroup(
                                                                    i
                                                                ).controls[
                                                                    'category_id'
                                                                ].hasError(
                                                                    'required'
                                                                )
                                                            "
                              class="text-danger"
                            >Vyberte alespoň
                              jednu z
                              kategorií</small
                            >
                          </div>
                          <div
                            class="col-12 col-md-2 form-group"
                          >
                            <label
                              [ngClass]="
                                                                getOrderItemsFormGroup(
                                                                    i
                                                                ).controls[
                                                                    'amount'
                                                                ].valid &&
                                                                (getOrderItemsFormGroup(
                                                                    i
                                                                ).controls[
                                                                    'amount'
                                                                ].dirty ||
                                                                    getOrderItemsFormGroup(
                                                                        i
                                                                    ).controls[
                                                                        'amount'
                                                                    ].touched)
                                                                    ? 'text-success'
                                                                    : getOrderItemsFormGroup(
                                                                          i
                                                                      )
                                                                          .controls[
                                                                          'amount'
                                                                      ]
                                                                          .invalid &&
                                                                      (getOrderItemsFormGroup(
                                                                          i
                                                                      )
                                                                          .controls[
                                                                          'amount'
                                                                      ].dirty ||
                                                                          getOrderItemsFormGroup(
                                                                              i
                                                                          )
                                                                              .controls[
                                                                              'amount'
                                                                          ]
                                                                              .touched)
                                                                    ? 'text-danger'
                                                                    : 'text-muted'
                                                            "
                              for="materialForm-item{{
                                                                i
                                                            }}-amount"
                            >* Počet
                              kusů:</label
                            >
                            <ejs-numerictextbox
                              #PieceInput
                              [enabled]="
                                                                getOrderItemsFormGroup(
                                                                    i
                                                                ).controls[
                                                                    'category_id'
                                                                ].value &&
                                                                getOrderItemsFormGroup(
                                                                    i
                                                                ).controls[
                                                                    'department_id'
                                                                ].value
                                                            "
                              [format]="'###'"
                              [ngClass]="
                                                                getOrderItemsFormGroup(
                                                                    i
                                                                ).controls[
                                                                    'amount'
                                                                ].touched &&
                                                                getOrderItemsFormGroup(
                                                                    i
                                                                ).controls[
                                                                    'amount'
                                                                ].invalid
                                                                    ? 'e-error'
                                                                    : getOrderItemsFormGroup(
                                                                          i
                                                                      )
                                                                          .controls[
                                                                          'amount'
                                                                      ]
                                                                          .touched &&
                                                                      getOrderItemsFormGroup(
                                                                          i
                                                                      )
                                                                          .controls[
                                                                          'amount'
                                                                      ].valid
                                                                    ? 'e-success'
                                                                    : ''
                                                            "
                              formControlName="amount"
                              id="materialForm-item{{
                                                                i
                                                            }}-amount"
                              min="1"
                              step="1"
                            ></ejs-numerictextbox>
                            <small
                              *ngIf="
                                                                getOrderItemsFormGroup(
                                                                    i
                                                                ).controls[
                                                                    'amount'
                                                                ].touched &&
                                                                getOrderItemsFormGroup(
                                                                    i
                                                                ).controls[
                                                                    'amount'
                                                                ].errors &&
                                                                getOrderItemsFormGroup(
                                                                    i
                                                                ).controls[
                                                                    'amount'
                                                                ].hasError(
                                                                    'required'
                                                                )
                                                            "
                              class="text-danger"
                            >Počet kusů je
                              povinný</small
                            >
                          </div>
                          <div
                            class="col-12 col-md-4 form-group"
                          >
                            <label
                              [ngClass]="
                                                                getOrderItemsFormGroup(
                                                                    i
                                                                ).controls[
                                                                    'price'
                                                                ].valid &&
                                                                (getOrderItemsFormGroup(
                                                                    i
                                                                ).controls[
                                                                    'price'
                                                                ].dirty ||
                                                                    getOrderItemsFormGroup(
                                                                        i
                                                                    ).controls[
                                                                        'price'
                                                                    ].touched)
                                                                    ? 'text-success active'
                                                                    : getOrderItemsFormGroup(
                                                                          i
                                                                      )
                                                                          .controls[
                                                                          'price'
                                                                      ]
                                                                          .invalid &&
                                                                      (getOrderItemsFormGroup(
                                                                          i
                                                                      )
                                                                          .controls[
                                                                          'price'
                                                                      ].dirty ||
                                                                          getOrderItemsFormGroup(
                                                                              i
                                                                          )
                                                                              .controls[
                                                                              'price'
                                                                          ]
                                                                              .touched)
                                                                    ? 'text-danger active'
                                                                    : 'text-muted'
                                                            "
                              class="active"
                              for="materialForm-item{{
                                                                i
                                                            }}-price"
                            >* Cena
                              položky:</label
                            >
                            <ejs-numerictextbox
                              #PriceInput
                              [enabled]="
                                                                getOrderItemsFormGroup(
                                                                    i
                                                                ).controls[
                                                                    'category_id'
                                                                ].value &&
                                                                getOrderItemsFormGroup(
                                                                    i
                                                                ).controls[
                                                                    'department_id'
                                                                ].value
                                                            "
                              [format]="
                                                                '#,###.00\',- CZK\''
                                                            "
                              [ngClass]="
                                                                getOrderItemsFormGroup(
                                                                    i
                                                                ).controls[
                                                                    'price'
                                                                ].touched &&
                                                                getOrderItemsFormGroup(
                                                                    i
                                                                ).controls[
                                                                    'price'
                                                                ].invalid
                                                                    ? 'e-error'
                                                                    : getOrderItemsFormGroup(
                                                                          i
                                                                      )
                                                                          .controls[
                                                                          'price'
                                                                      ]
                                                                          .touched &&
                                                                      getOrderItemsFormGroup(
                                                                          i
                                                                      )
                                                                          .controls[
                                                                          'price'
                                                                      ].valid
                                                                    ? 'e-success'
                                                                    : ''
                                                            "
                              formControlName="price"
                              id="materialForm-item{{
                                                                i
                                                            }}-price"
                              min="0.01"
                              step="0.01"
                            ></ejs-numerictextbox>
                            <small
                              *ngIf="
                                                                getOrderItemsFormGroup(
                                                                    i
                                                                ).controls[
                                                                    'price'
                                                                ].touched &&
                                                                getOrderItemsFormGroup(
                                                                    i
                                                                ).controls[
                                                                    'price'
                                                                ].errors &&
                                                                getOrderItemsFormGroup(
                                                                    i
                                                                ).controls[
                                                                    'price'
                                                                ].hasError(
                                                                    'required'
                                                                )
                                                            "
                              class="text-danger"
                            >Cena položky
                              objednávky je
                              povinná</small
                            >
                          </div>
                          <div
                            class="col-12 col-md-6 form-group"
                          >
                            <ejs-checkbox
                              (change)="
                                                                recalcSummary()
                                                            "
                              [checked]="false"
                              [disabled]="
                                                                !getOrderItemsFormGroup(
                                                                    i
                                                                ).controls[
                                                                    'category_id'
                                                                ].value ||
                                                                !getOrderItemsFormGroup(
                                                                    i
                                                                ).controls[
                                                                    'department_id'
                                                                ].value
                                                            "
                              cssClass="e-primary p-0 mr-3"
                              formControlName="dph"
                              label="cena s DPH"
                            >
                            </ejs-checkbox>
                            <br />
                            <div
                              *ngIf="
                                                                getOrderItemsFormGroup(
                                                                    i
                                                                ).controls[
                                                                    'price'
                                                                ].value > 0 &&
                                                                getOrderItemsFormGroup(
                                                                    i
                                                                ).controls[
                                                                    'amount'
                                                                ].value > 0
                                                            "
                              class="alert alert-danger p-1"
                              role="alert"
                            >
                              <i
                                aria-hidden="true"
                                class="fa-light fa-lg fa-coins mr-2"
                              ></i
                              >Cena položky:
                              <strong>{{
                                  getOrderItemsFormGroup(
                                    i
                                  ).controls[
                                    'price'
                                    ].value
                                }}</strong>
                              za
                              {{
                                getOrderItemsFormGroup(
                                  i
                                ).controls[
                                  'amount'
                                  ].value
                              }}
                              kusů.
                            </div>
                            <div
                              *ngIf="
                                                                getOrderItemsFormGroup(
                                                                    i
                                                                ).controls[
                                                                    'dph'
                                                                ].value
                                                            "
                              class="alert alert-warning p-1"
                              role="alert"
                            >
                              DPH bude odečteno
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        class="col-12 col-md-1 text-center"
                      >
                        <button
                          (click)="
                                                        removeItem(i);
                                                        recalcSummary()
                                                    "
                          [disabled]="i === 0"
                          [ngClass]="
                                                        i === 0
                                                            ? 'e-disabled'
                                                            : 'e-danger'
                                                    "
                          cssClass="e-btn btn-p-zero e-small"
                          ejs-button
                          type="button"
                        >
                          <i
                            aria-hidden="true"
                            class="fa-light fa-lg fa-trash"
                          ></i>
                        </button>
                      </div>
                      <div class="col-12">
                        <hr />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div
          class="d-none d-md-block col-md-4"
          style="font-size: 11px !important"
        >
          <div class="sticky-without-padding">
            <div class="e-card">
              <div
                class="e-card-content"
                style="
                                    max-height: 500px !important;
                                    overflow-y: auto;
                                "
              >
                <h6 class="p-1">
                  <i
                    aria-hidden="true"
                    class="mr-2 fa-light fa-lg fa-question"
                  ></i
                  >Nápověda
                </h6>
                <hr class="p-1 m-0" />
                <p
                  #hintArea
                  *ngIf="hintText && hintText.length > 0"
                  [innerHTML]="hintText"
                  class="text-info"
                ></p>
                <h6 class="p-1">
                  <i
                    aria-hidden="true"
                    class="mr-2 fa-light fa-lg fa-clipboard-list-check"
                  ></i
                  >Shrnutí
                </h6>
                <hr class="p-1 m-0" />
                <div
                  *ngIf="
                                        budgetsSummary &&
                                        budgetsSummary.length > 0
                                    "
                  class="row"
                >
                  <div class="col-12">
                    <div class="e-card">
                      <div class="e-card-content">
                        Rozpočty - náhled objednávky:
                        <hr class="p-1 m-0" />
                        <ul
                          class="list-group list-group-flush"
                          id="budgetsList"
                        >
                          <li
                            *ngFor="
                                                            let budget of budgetsSummary;
                                                            let i = index
                                                        "
                            class="list-group-item list-group-item-action p-1 border-top-0"
                          >
                            <div class="row">
                              <div
                                *ngIf="
                                                                    budget
                                                                        .expenditures
                                                                        .residue <=
                                                                    0
                                                                "
                                class="alert alert-danger p-1"
                                role="alert"
                              >
                                <i
                                  aria-hidden="true"
                                  class="fa-light fa-lg fa-exclamation-triangle mr-2"
                                ></i
                                >Rozpočet již je
                                <strong
                                >vyčerpán</strong
                                >.
                              </div>
                              <div
                                *ngIf="
                                                                    budget.amount -
                                                                        budget
                                                                            .expenditures
                                                                            .price -
                                                                        budget
                                                                            .expenditures_current
                                                                            .price <=
                                                                    0
                                                                "
                                class="alert alert-warning p-1"
                                role="alert"
                              >
                                <i
                                  aria-hidden="true"
                                  class="fa-light fa-lg fa-exclamation-triangle mr-2"
                                ></i
                                >Touto
                                objednávkou bude
                                <strong
                                >přečerpán
                                  rozpočet</strong
                                >.
                              </div>
                              <div class="col-6">
                                <small>
                                  {{
                                    budget
                                      .department
                                      .name
                                  }}&nbsp;&middot;&nbsp;<strong
                                >{{
                                    budget.name
                                  }}</strong
                                ><br />
                                  <span
                                    class="text-primary"
                                  >Původní:
                                    {{
                                      budget.amount
                                        | number
                                        : '1.2-2'
                                    }}
                                    ,-
                                                                        CZK</span
                                  ><br />
                                  <span
                                    class="text-danger"
                                  >Čerpáno:
                                    {{
                                      budget
                                        .expenditures
                                        .price
                                        | number
                                        : '1.2-2'
                                    }}
                                    ,-
                                                                        CZK</span
                                  ><br />
                                  <span
                                    class="text-success"
                                  >Zbývá:
                                    {{
                                      budget.amount -
                                      budget
                                        .expenditures
                                        .price
                                        | number
                                        : '1.2-2'
                                    }}
                                    ,-
                                                                        CZK</span
                                  >
                                </small>
                              </div>
                              <div
                                class="col-6 text-right"
                              >
                                <strong
                                  class="text-danger"
                                >
                                  -
                                  {{
                                    budget
                                      .expenditures_current
                                      .price
                                      | number
                                      : '1.2-2'
                                  }}
                                  ,- CZK </strong
                                >&nbsp;
                                <small
                                  class="text-muted"
                                >
                                  v
                                  {{
                                    budget
                                      .expenditures_current
                                      .items
                                  }}
                                  položkách
                                </small>
                                <br />
                                <span
                                  *ngIf="
                                                                        f
                                                                            .to_storage
                                                                            .valid &&
                                                                        f
                                                                            .to_storage
                                                                            .value ===
                                                                            'to_storage'
                                                                    "
                                >Materiál na
                                                                    doplnění
                                                                    skladových
                                                                    zásob
                                                                    <small
                                                                      class="text-danger"
                                                                    >(odečítá
                                                                        se z
                                                                        rozpočtů)</small
                                                                    ></span
                                >
                                <br />
                                <span
                                  *ngIf="
                                                                        f
                                                                            .to_storage
                                                                            .valid &&
                                                                        f
                                                                            .to_storage
                                                                            .value ===
                                                                            'for_sale'
                                                                    "
                                >Materiál na
                                                                    přeprodej
                                                                    <small
                                                                      class="text-success"
                                                                    >(neodečítá
                                                                        se z
                                                                        rozpočtů)</small
                                                                    ></span
                                >
                                <br />
                                <ul
                                  class="list-group"
                                  id="approversList"
                                >
                                  <li
                                    *ngFor="
                                                                            let confirmer of budget.approvers;
                                                                            let i = index
                                                                        "
                                    class="list-group-item p-1 border-top-0 list-group-item-action"
                                  >
                                    <img
                                      alt="profilový obrázek"
                                      class="img-fluid rounded-circle avatar-xs mr-1 ml-2"
                                      src="assets/img{{
                                                                                confirmer.personalphoto
                                                                            }}"
                                    />
                                    <span
                                      class="float-right text-right"
                                    >
                                                                            <small
                                                                            >{{
                                                                                confirmer.firstname
                                                                              }}
                                                                              {{
                                                                                confirmer.secondname
                                                                              }}</small
                                                                            >
                                                                        </span>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
  <ng-template #footerTemplate>
    <div class="d-flex justify-content-end">
            <span
              *ngIf="materialForm.invalid"
              class="e-badge e-badge-warning p-2 size_2"
              role="alert"
            ><strong>Formulář je špatně vyplněný</strong></span
            >
      <span
        *ngIf="materialForm.valid"
        class="e-badge e-badge-success p-2 size_2"
        role="alert"
      ><strong>Formulář je správně vyplněný</strong></span
      >
      <button
        (click)="onSubmit()"
        [disabled]="
                    loadingMaterial || materialForm.invalid || !isChecked
                "
        cssClass="e-btn e-success btn-p-zero e-small mr-1"
        ejs-button
        type="submit"
      >
                <span
                  *ngIf="loadingMaterial === true"
                  aria-hidden="true"
                  class="spinner-grow spinner-grow-sm"
                  role="status"
                ></span>
        <span *ngIf="loadingMaterial === false">ULOŽIT</span>
        <span *ngIf="loadingMaterial === true">Provádím...</span>
      </button>
      <button
        (click)="formDialogObj.hide()"
        cssClass="e-btn e-danger e-outline btn-p-zero e-small"
        ejs-button
        type="button"
      >
        ZRUŠIT
      </button>
    </div>
  </ng-template>
</ejs-dialog>
