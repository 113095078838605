<ejs-dialog
  #formDialog
  (close)="
        isUpdate = false;
        isCreate = false;
        isDirty = false;
        isChecked = false;
        isCopy = false;
        submited = false;
        changeDialogState(false)
    "
  [isModal]="true"
  [showCloseIcon]="true"
  [visible]="isVisible"
  [width]="'85%'"
  id="formDialog"
>
  <form [formGroup]="hardwareConfigForm">
    <ng-template #content>
      <div class="dialogContent">
        <div class="row">
          <div class="col-12 col-md-6 mb-3">
            <label
              [ngClass]="
                                f.unit_id.valid
                                    ? 'text-success'
                                    : f.unit_id.invalid &&
                                      (f.unit_id.dirty || f.unit_id.touched)
                                    ? 'text-danger'
                                    : 'text-muted'
                            "
              for="unit"
            >* Typ bytové jednotky:</label
            >
            <ejs-dropdownlist
              #unit
              (filtering)="onFilteringUnitTypes($event)"
              [allowFiltering]="true"
              [cssClass]="
                                (f.unit_id.dirty || f.unit_id.touched) &&
                                f.unit_id.invalid
                                    ? 'e-error'
                                    : (f.unit_id.dirty || f.unit_id.touched) &&
                                      f.unit_id.valid
                                    ? 'e-success'
                                    : ''
                            "
              [dataSource]="unitsSelect"
              [fields]="fields"
              [filterBarPlaceholder]="
                                'Vyhledat typ bytové jednotky...'
                            "
              [ignoreAccent]="true"
              [placeholder]="'Vyberte typ bytové jednotky'"
              [popupHeight]="height"
              formControlName="unit_id"
              id="unit"
              sortOrder="Ascending"
            ></ejs-dropdownlist>
            <small
              *ngIf="
                                (f.unit_id.dirty || f.unit_id.touched) &&
                                f.unit_id.errors &&
                                f.unit_id.errors.required
                            "
              class="text-danger"
            >Vyberte typ bytové jednotky</small
            >
          </div>
          <div class="col-12 col-md-6 mb-3">
            <label
              [ngClass]="
                                f.space_id.valid
                                    ? 'text-success'
                                    : f.space_id.invalid &&
                                      (f.space_id.dirty || f.space_id.touched)
                                    ? 'text-danger'
                                    : 'text-muted'
                            "
              for="space"
            >* Prostor bytové jednotky:</label
            >
            <ejs-dropdownlist
              #space
              (filtering)="onFilteringSpaceTypes($event)"
              [allowFiltering]="true"
              [cssClass]="
                                (f.space_id.dirty || f.space_id.touched) &&
                                f.space_id.invalid
                                    ? 'e-error'
                                    : (f.space_id.dirty ||
                                          f.space_id.touched) &&
                                      f.space_id.valid
                                    ? 'e-success'
                                    : ''
                            "
              [dataSource]="spacesSelect"
              [fields]="fields"
              [filterBarPlaceholder]="
                                'Vyhledat prostor bytové jednotky...'
                            "
              [ignoreAccent]="true"
              [placeholder]="'Vyberte prostor bytové jednotky'"
              [popupHeight]="height"
              formControlName="space_id"
              id="space"
              sortOrder="Ascending"
            ></ejs-dropdownlist>
            <small
              *ngIf="
                                (f.space_id.dirty || f.space_id.touched) &&
                                f.space_id.errors &&
                                f.space_id.errors.required
                            "
              class="text-danger"
            >Vyberte prostor bytové jednotky</small
            >
          </div>
          <div class="col-12 col-md-6 mb-3">
            <label
              [ngClass]="
                                f.speed_id.valid
                                    ? 'text-success'
                                    : f.speed_id.invalid &&
                                      (f.speed_id.dirty || f.speed_id.touched)
                                    ? 'text-danger'
                                    : 'text-muted'
                            "
              for="speed"
            >* Limit rychlosti:</label
            >
            <ejs-dropdownlist
              #speed
              (filtering)="onFilteringSpeedTypes($event)"
              [allowFiltering]="true"
              [cssClass]="
                                (f.speed_id.dirty || f.speed_id.touched) &&
                                f.speed_id.invalid
                                    ? 'e-error'
                                    : (f.speed_id.dirty ||
                                          f.speed_id.touched) &&
                                      f.speed_id.valid
                                    ? 'e-success'
                                    : ''
                            "
              [dataSource]="speedsSelect"
              [fields]="fields"
              [filterBarPlaceholder]="
                                'Vyhledat limit rychlosti...'
                            "
              [ignoreAccent]="true"
              [placeholder]="'Vyberte limit rychlosti'"
              [popupHeight]="height"
              formControlName="speed_id"
              id="speed"
              sortOrder="Ascending"
            ></ejs-dropdownlist>
            <small
              *ngIf="
                                (f.speed_id.dirty || f.speed_id.touched) &&
                                f.speed_id.errors &&
                                f.speed_id.errors.required
                            "
              class="text-danger"
            >Vyberte limit rychlosti</small
            >
          </div>
          <div class="col-12 col-md-6 mb-3">
            <label
              [ngClass]="
                                f.hardware_id.valid
                                    ? 'text-success'
                                    : f.hardware_id.invalid &&
                                      (f.hardware_id.dirty ||
                                          f.hardware_id.touched)
                                    ? 'text-danger'
                                    : 'text-muted'
                            "
              for="hardware"
            >* Zařízení:</label
            >
            <ejs-dropdownlist
              #hardware
              (filtering)="onFilteringHardwares($event)"
              [allowFiltering]="true"
              [cssClass]="
                                (f.hardware_id.dirty ||
                                    f.hardware_id.touched) &&
                                f.hardware_id.invalid
                                    ? 'e-error'
                                    : (f.hardware_id.dirty ||
                                          f.hardware_id.touched) &&
                                      f.hardware_id.valid
                                    ? 'e-success'
                                    : ''
                            "
              [dataSource]="hardwareSelect"
              [fields]="fields"
              [filterBarPlaceholder]="'Vyhledat zařízení...'"
              [ignoreAccent]="true"
              [placeholder]="'Vyberte zařízení'"
              [popupHeight]="height"
              formControlName="hardware_id"
              id="hardware"
              sortOrder="Ascending"
            ></ejs-dropdownlist>
            <small
              *ngIf="
                                (f.hardware_id.dirty ||
                                    f.hardware_id.touched) &&
                                f.hardware_id.errors &&
                                f.hardware_id.errors.required
                            "
              class="text-danger"
            >Vyberte zařízení</small
            >
          </div>
          <div class="col-12 mb-3">
            <ejs-checkbox
              [checked]="false"
              cssClass="e-primary p-0 mr-3"
              formControlName="basics"
              label="Základ"
            ></ejs-checkbox>
            <ejs-checkbox
              [checked]="false"
              cssClass="e-primary p-0 mr-3"
              formControlName="cabels"
              label="Kabeláž"
            ></ejs-checkbox>
            <ejs-checkbox
              [checked]="false"
              cssClass="e-primary p-0 mr-3"
              formControlName="garden"
              label="Zahrada"
            ></ejs-checkbox>
            <ejs-checkbox
              [checked]="false"
              cssClass="e-primary p-0 mr-3"
              formControlName="roaming"
              label="Wi-Fi roaming"
            ></ejs-checkbox>
          </div>
        </div>
      </div>
    </ng-template>
    <ng-template #footerTemplate>
      <div class="d-flex justify-content-end">
                <span
                  *ngIf="hardwareConfigForm.invalid"
                  class="e-badge e-badge-warning p-2 size_2"
                  role="alert"
                >
                    <strong>Formulář je špatně vyplněný</strong>
                </span>
        <span
          *ngIf="hardwareConfigForm.valid"
          class="e-badge e-badge-success p-2 size_2"
          role="alert"
        >
                    <strong>Formulář je správně vyplněný</strong>
                </span>
      </div>
      <div class="row">
        <div class="col-12 pt-2">
          <button
            (click)="onSubmit()"
            [disabled]="
                            loadingConfig ||
                            hardwareConfigForm.invalid ||
                            !isChecked
                        "
            cssClass="e-btn e-success btn-p-zero e-small mr-1"
            ejs-button
            type="submit"
          >
                        <span
                          *ngIf="loadingConfig === true"
                          aria-hidden="true"
                          class="spinner-grow spinner-grow-sm"
                          role="status"
                        ></span>
            <span *ngIf="loadingConfig === false">ULOŽIT</span>
            <span *ngIf="loadingConfig === true">Provádím...</span>
          </button>
          <button
            (click)="formDialogObj.hide()"
            cssClass="e-btn e-danger e-outline btn-p-zero e-small"
            ejs-button
            type="button"
          >
            ZRUŠIT
          </button>
        </div>
      </div>
    </ng-template>
  </form>
</ejs-dialog>
