import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    HostListener,
    Input,
    OnChanges,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {DataService} from '@src/app/_services/data.service';
import {DataNetbaseService} from '@src/app/_services/data-netbase.service';
import {ServicesService, SpaceInput} from '@src/app/features/services/services.service';
import {MessageService} from '@src/app/_services/message.service';
import {AuthenticationService} from '@src/app/_services/authentication.service';
import {SpaceTypes} from '@src/app/_models/services/space-types';
import {User} from '@src/app/_models/user/user';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {Observable} from 'rxjs';
import {DialogComponent} from '@syncfusion/ej2-angular-popups';
import {ComponentCanDeactivate} from '@src/app/_guards/changes.guard';
import {ActivatedRoute, Params, Router} from '@angular/router';

@UntilDestroy()
@Component({
    selector: 'app-flat-unit-space-form',
    templateUrl: './flat-unit-space-form.component.html',
    styleUrls: ['./flat-unit-space-form.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FlatUnitSpaceFormComponent implements OnInit, OnChanges, ComponentCanDeactivate {
    // Forms
    flatSpaceTypeForm: FormGroup;

    isDirty = false;

    isChecked = false;

    submited = false;

    // Variables
    currentUser: User | null;

    // Loaders
    loadingFlatSpaceType = false;

    @Input() flatSpace: SpaceTypes | null = null;

    @Input() isCreate = false;

    @Input() isUpdate = false;

    @Input() isVisible = false;

    @Input() isCopy = false;

    @Output() readonly flatSpaceFormDialogState = new EventEmitter<boolean>();

    // Dialogs
    @ViewChild('formDialog') formDialogObj: DialogComponent;

    constructor(
        private readonly router: Router,
        private readonly route: ActivatedRoute,
        private readonly formBuilder: FormBuilder,
        private readonly ref: ChangeDetectorRef,
        private readonly dataService: DataService,
        private readonly dataNetbaseService: DataNetbaseService,
        private readonly servicesService: ServicesService,
        private readonly messages: MessageService,
        private readonly authenticationService: AuthenticationService,
    ) {
        this.currentUser = this.authenticationService.currentUserValue;
    }

    get f(): {[key: string]: AbstractControl} {
        return this.flatSpaceTypeForm.controls;
    }

    @HostListener('window:beforeunload')
    canDeactivate(): Observable<boolean> | boolean {
        return !this.isDirty;
    }

    ngOnInit(): void {
        this.flatSpaceTypeForm = this.formBuilder.group({
            metric_until: [0, Validators.compose([Validators.required, Validators.min(1)])],
            metric_to: [0, Validators.compose([Validators.required, Validators.min(1)])],
        });

        this.flatSpaceTypeForm.valueChanges.pipe(untilDestroyed(this))
            .subscribe(() => {
                this.isDirty = this.flatSpaceTypeForm.dirty;
                this.showInputErrors();
            });
    }

    ngOnChanges(): void {
        if (this.isVisible) {
            const queryParams: Params = {form: null};

            void this.router.navigate([], {
                relativeTo: this.route,
                queryParams,
                queryParamsHandling: 'merge',
            });

            if (this.isUpdate && this.flatSpace) {
                this.flatSpaceTypeForm.reset();
                this.formDialogObj.header = `Výměra #${this.flatSpace.id}`;
                this.fillTheForm();
                this.showInputErrors();
            }

            if (this.isCreate) {
                this.flatSpaceTypeForm.reset();
                this.formDialogObj.header = 'Nová výměra';
            }

            if (this.isCopy) {
                this.flatSpaceTypeForm.reset();
                this.formDialogObj.header = 'Nová výměra';
                this.fillTheForm();
                this.showInputErrors();
            }
        }
    }

    changeDialogState(value: boolean): void {
        this.flatSpaceFormDialogState.emit(value);
    }

    fillTheForm(): void {
        this.flatSpaceTypeForm.controls.metric_until.patchValue(this.flatSpace?.metric_until);
        this.flatSpaceTypeForm.controls.metric_to.patchValue(this.flatSpace?.metric_to);
    }

    onSubmit(): void {
        this.submited = true;
        this.isDirty = false;
        this.loadingFlatSpaceType = true;

        if (this.isCreate || this.isCopy) {
            this.createSpaceType();
        }

        if (this.isUpdate) {
            this.editSpaceType();
        }
    }

    createSpaceType(): void {
        if (this.flatSpaceTypeForm.invalid || !this.currentUser) {
            console.error('form is not valid! || this.currentUser is missing...');

            return;
        }

        this.loadingFlatSpaceType = true;

        const flatSpaceTypeData: SpaceInput = {
            metric_until: this.f.metric_until.value,
            metric_to: this.f.metric_to.value,
            created_by: this.currentUser.id,
        };

        this.servicesService
            .addSpaceType(flatSpaceTypeData)
            .pipe(untilDestroyed(this))
            .subscribe(
                (data: SpaceTypes) => {
                    const body = `${data.metric_until} ${data.metric_to} výměr přidán do nabídky`;
                    const options = {progressBar: true, timeOut: 5000};

                    this.messages.showSuccess(
                        'Výměra bytové jednotky úspěšně vytvořena',
                        body,
                        options,
                    );
                    this.dataNetbaseService.setSpaceTypesDataSource();
                    this.isCreate = false;
                    this.loadingFlatSpaceType = false;
                    this.ref.markForCheck();
                    this.formDialogObj.hide();
                },
                error => {
                    console.error(error);

                    const body = 'Zkuste to znovu později';
                    const options = {
                        progressBar: true,
                        timeOut: 5000,
                        toastClass: 'red',
                    };

                    this.messages.showError(
                        'Chyba při vytváření výměru bytové jednotky',
                        body,
                        options,
                    );
                    this.loadingFlatSpaceType = false;
                    this.ref.markForCheck();
                },
            );
    }

    editSpaceType(): void {
        this.submited = true;

        if (this.flatSpaceTypeForm.invalid || !this.currentUser) {
            console.error('form is not valid! || this.currentUser is missing...');

            return;
        }

        this.loadingFlatSpaceType = true;

        const flatSpaceTypeData: SpaceInput = {
            id: this.flatSpace?.id,
            metric_until: this.f.metric_until.value,
            metric_to: this.f.metric_to.value,
            updated_by: this.currentUser.id,
        };

        this.servicesService
            .updateSpaceType(flatSpaceTypeData)
            ?.pipe(untilDestroyed(this))
            .subscribe(
                (data: SpaceTypes) => {
                    const body = `${data.metric_until} ${data.metric_to} výměr upraven`;
                    const options = {progressBar: true, timeOut: 5000};

                    this.messages.showSuccess(
                        'Výměra bytové jednotky úspěšně upravena',
                        body,
                        options,
                    );
                    this.dataNetbaseService.setSpaceTypesDataSource();
                    this.isUpdate = false;
                    this.loadingFlatSpaceType = false;
                    this.ref.markForCheck();
                    this.formDialogObj.hide();
                },
                error => {
                    console.error(error);

                    const body = 'Zkuste to znovu později';
                    const options = {
                        progressBar: true,
                        timeOut: 5000,
                        toastClass: 'red',
                    };

                    this.messages.showError(
                        'Chyba při úpravě výměru bytové jednotky',
                        body,
                        options,
                    );
                    this.loadingFlatSpaceType = false;
                    this.ref.markForCheck();
                },
            );
    }

    showInputErrors(): void {
        this.isChecked = true;
        /* console.log('VALID: ' + this.ticketForm.valid);
        Object.keys(this.ticketForm.controls).forEach(key => {
            const controlErrors: ValidationErrors = this.ticketForm.get(key).errors;
            if (controlErrors != null) {
                Object.keys(controlErrors).forEach(keyError => {
                    console.log('Key control: ' + key + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError]);
                });
            }
        });*/
        this.flatSpaceTypeForm.markAllAsTouched();
        this.ref.markForCheck();
    }
}
