import {
    AfterViewChecked,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnInit,
    ViewChild,
} from '@angular/core';
import {User} from '@src/app/_models/user/user';
import {Observable, Observer} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {FormArray, FormGroup} from '@angular/forms';
import {AuthenticationService} from '@src/app/_services/authentication.service';
import {PermissionService} from '@src/app/_services/permission.service';
import {MessageService} from '@src/app/_services/message.service';
import {DataService} from '@src/app/_services/data.service';
import {Employer} from '@src/app/_models/employer/employer';
import {ButtonPropsModel, DialogComponent} from '@syncfusion/ej2-angular-popups';
import {
    DataResult,
    EditSettingsModel,
    ExcelExportProperties,
    FilterSettingsModel,
    GridComponent,
    GroupSettingsModel,
    PageSettingsModel,
    RowDataBoundEventArgs,
    SelectionSettingsModel,
    ToolbarItems,
} from '@syncfusion/ej2-angular-grids';
import {ChangeEventArgs, DropDownListComponent} from '@syncfusion/ej2-angular-dropdowns';
import {ClickEventArgs} from '@syncfusion/ej2-angular-navigations';

import moment from 'moment';
import localeCs from '@angular/common/locales/cs';
import {registerLocaleData} from '@angular/common';
import {TextBoxComponent} from '@syncfusion/ej2-angular-inputs';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {UserGridItems, UserInput, UsersService} from '@src/app/features/users/users.service';
import {VacationService} from '@src/app/features/vacation/vacation.service';

moment.updateLocale('cs', {workingWeekdays: [1, 2, 3, 4, 5]});
registerLocaleData(localeCs);

@UntilDestroy()
@Component({
    selector: 'app-users-list',
    templateUrl: './users-list.component.html',
    styleUrls: ['./users-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UsersListComponent implements OnInit, AfterViewChecked {
    // Grid
    currencyFormat: {format: string} = {format: '#,###.00\',- CZK\''};

    dateFormat: string;

    toolbar: ToolbarItems[] | object;

    data: object;

    ruianFieldObj: object = {text: 'label', value: 'value'};

    filterSettings: FilterSettingsModel;

    selectionSettings: SelectionSettingsModel;

    editSettings: EditSettingsModel;

    groupOptions: GroupSettingsModel;

    pageOptions: PageSettingsModel;

    filterDate: object;

    requiredRules: object;

    exportCurrentPage = false;

    // Dropdowns
    height = '240px';

    fields: object = {text: 'label', value: 'value'};

    departmentSelect: Array<{value: boolean | number | string; label: string}> = [];

    categoryFilterData: Array<string> = [];

    bossSelect: Array<{value: boolean | number | string; label: string}> = [];

    employerSelect: Array<{value: boolean | number | string; label: string}> = [];

    roleSelect: Array<{value: boolean | number | string; label: string}> = [];

    // Dialogs
    renderUserFormDialog = false;

    buttonsDelete: ButtonPropsModel[] = [
        {
            click: (): void => {
                this.deleteUser();
            },
            isFlat: false,
            buttonModel: {
                content: 'OK',
                cssClass: 'e-success e-outline',
            },
        },
        {
            click: (): void => {
                this.confirmationDelete.hide();
            },
            buttonModel: {
                content: 'ZRUŠIT',
                cssClass: 'e-danger e-outline',
            },
        },
    ];

    buttonsAuthorized: ButtonPropsModel[] = [
        {
            click: (): void => {
                this.reAuthorize();
            },
            isFlat: false,
            buttonModel: {
                content: 'OK',
                cssClass: 'e-success e-outline',
            },
        },
        {
            click: (): void => {
                this.confirmationAuthorized.hide();
            },
            buttonModel: {
                content: 'ZRUŠIT',
                cssClass: 'e-danger e-outline',
            },
        },
    ];

    buttonsCancel: ButtonPropsModel[] = [
        {
            click: (): void => {
                this.confirmationCancel.hide();
            },
            buttonModel: {
                isPrimary: true,
                content: 'OK',
            },
        },
    ];

    // Variables
    currentUser: User | null;

    users: User[] | undefined = [];

    boss: User;

    employer: Employer;

    clickedRow: User[] = [];

    clickedRow$ = new Observable<User[]>((observer: Observer<User[]>) => {
        observer.next(this.clickedRow);
    });

    // Forms
    userForm: FormGroup;

    isChecked = false;

    isDirty = false;

    isCreate = false;

    isUpdate = false;

    isCopy = false;

    submited = false;

    currGeneratedPass: string;

    assignationList: FormArray;

    // Loaders
    tableLoad = true;

    // Grid
    @ViewChild('usersGrid') gridUsers: GridComponent;

    @ViewChild('searchtext') searchtextObj: TextBoxComponent;

    // Dropdowns
    @ViewChild('viewFilter') viewFilterInstance: DropDownListComponent;

    @ViewChild('department') departmentObj: DropDownListComponent;

    @ViewChild('employer') employerObj: DropDownListComponent;

    @ViewChild('boss') bossObj: DropDownListComponent;

    @ViewChild('role') roleObj: DropDownListComponent;

    // Dialogs
    @ViewChild('confirmationCancel') confirmationCancel: DialogComponent;

    @ViewChild('confirmationDelete') confirmationDelete: DialogComponent;

    @ViewChild('confirmationAuthorized') confirmationAuthorized: DialogComponent;

    constructor(
        private readonly router: Router,
        private readonly route: ActivatedRoute,
        private readonly ref: ChangeDetectorRef,
        private readonly authenticationService: AuthenticationService,
        private readonly vacationService: VacationService,
        private readonly permissionService: PermissionService,
        private readonly userService: UsersService,
        private readonly message: MessageService,
        private readonly dataService: DataService,
    ) {
        this.currentUser = this.authenticationService.currentUserValue;
    }

    ngOnInit(): void {
        this.filterSettings = {
            type: 'Excel',
            showFilterBarOperator: true,
            showFilterBarStatus: true,
            ignoreAccent: true,
        };

        this.selectionSettings = {
            persistSelection: true,
            type: 'Multiple',
            checkboxOnly: true,
        };

        this.toolbar = [
            {
                text: 'Občerstvit data',
                tooltipText: 'Občerstvit data',
                prefixIcon: 'e-refresh',
                id: 'customRefresh',
            },
            'Print',
            'ExcelExport',
            'Add',
            'Edit',
            {
                text: 'Kopírovat',
                tooltipText: 'Kopírovat uživatele',
                prefixIcon: 'e-copy',
                id: 'copy',
            },
            {
                text: 'De-autorizovat',
                tooltipText: 'De-autorizovat uživatele',
                prefixIcon: 'e-user-times',
                id: 'delete',
            },
            {
                text: 'Re-autorizovat',
                tooltipText: 'Re-autorizovat uživatele',
                prefixIcon: 'e-user-revert',
                id: 'revert',
            },
            {
                text: 'Detail',
                tooltipText: 'Profil uživatele',
                prefixIcon: 'e-openfolder',
                id: 'detail',
            },
        ];

        this.categoryFilterData = [
            'všichni uživatelé',
            'autorizovaní uživatelé',
            'de-autorizovaní uživatelé',
            'čekající na ověření',
            'hotlineři',
        ];
        this.pageOptions = {pageSize: 50};
        this.dateFormat = 'dd.MM.yyyy HH:mm';
        this.filterDate = {type: 'Menu', params: {format: this.dateFormat}};
        this.groupOptions = {
            showGroupedColumn: true,
            disablePageWiseAggregates: false,
        };

        this.editSettings = {
            allowEditing: true,
            allowAdding: true,
            allowDeleting: true,
            allowEditOnDblClick: false,
            mode: 'Dialog',
        };
        this.requiredRules = {required: true};

        this.dataService.setRoleDataSource();
        this.dataService.setVacationsDataSource();
        this.dataService.setEmployerDataSource();
        this.dataService.setDepartmentDataSource();
    }

    ngAfterViewChecked(): void {
        this.route.queryParamMap.pipe(untilDestroyed(this))
            .subscribe(params => {
                if (params.get('form') === 'addUser') {
                    console.info('get user form');
                    this.isCreate = true;
                    this.isUpdate = false;
                    this.isCopy = false;
                    this.renderUserFormDialog = true;
                    this.ref.markForCheck();
                }
            });
    }

    loadData(): DataResult[] {
        this.gridUsers.clearRowSelection();

        console.info('NEW DATA BOUND');

        const elements: UserGridItems[] = [];

        if (this.users && this.users.length > 0) {
            this.users.map((user: User) => {
                let assignationsStr = '<ul>';

                if (user.roles && user.roles.length > 0) {
                    user.roles.map(
                        (role: {company: string; department: string; role: string}) => {
                            assignationsStr += `<li>${role.role} - ${role.department} - ${role.company}</li>`;
                        },
                    );
                } else {
                    assignationsStr = 'nemá žádné zapojení';
                }

                assignationsStr += '</ul>';

                elements.push({
                    id: user.id,
                    boss_id: user.boss_id,
                    employer_id: user.employer_id,
                    hotliner_id: user.hotliner_id,
                    created_by: user.created_by,
                    user_str: user.fullname,
                    user_img: user.personalphoto,
                    creator_str: user.creator.fullname,
                    creator_img: user.creator.personalphoto,
                    boss_str: user.boss.fullname,
                    boss_img: user.boss.personalphoto,
                    authorized: user.authorized,
                    emailverify: user.emailverify,
                    vacation:
                        this.vacationService.setUserFondsResiduesSource.getValue()[user.id]
                            ?.vacationResidue ?? user.vacation_fond,
                    sickdays:
                        this.vacationService.setUserFondsResiduesSource.getValue()[user.id]
                            ?.sickDaysResidue ?? user.sickdays_fond,
                    position: user.official_position_name,
                    assignations: assignationsStr,
                    workemail: user.workemail,
                    telnumber: user.telnumber,
                    employer_str: user.employer.name,
                    updated_date: user.updated_date ? new Date(user.updated_date) : null,
                    created_date: new Date(user.created_date),
                    deleted_date: user.deleted_date ? new Date(user.deleted_date) : null,
                });
            });
        }

        this.tableLoad = false;
        this.ref.markForCheck();

        return elements as unknown as DataResult[];
    }

    onCreated(): void {
        this.disableToolBarBtn();
    }

    setInitialGridFiltering(): void {
        this.gridUsers.clearFiltering();
        // this.gridUsers.filterByColumn('created_by', 'equal', this.currentUser.id, 'and', false, true);
        // this.viewFilterInstance.value = 'moje žádanky - všechny';
        this.ref.markForCheck();
    }

    onLoad(): void {
        const wrapper1 = document.getElementById('scroll_wrapper1_users');
        const wrapper2 = document.getElementById('scroll_wrapper2_users');

        if (wrapper1 && wrapper2) {
            wrapper1.onscroll = (): void => {
                wrapper2.scrollLeft = wrapper1.scrollLeft;
            };

            wrapper2.onscroll = (): void => {
                wrapper1.scrollLeft = wrapper2.scrollLeft;
            };
        }

        this.dataService.userSource.pipe(untilDestroyed(this))
            .subscribe(
                (users: User[] | undefined) => {
                    this.bossSelect = [];
                    this.users = users;

                    if (this.users && this.users.length > 0) {
                        this.vacationService
                            .setUserFondsResidues(this.users, moment()
                                .year())
                            .subscribe(data => {
                                this.data = this.gridUsers.dataSource = this.loadData();
                                this.users?.map((user: User) => {
                                    if (user.authorized && !user.deleted_date) {
                                        this.bossSelect = [
                                            ...this.bossSelect,
                                            {value: user.id, label: user.fullname},
                                        ];
                                    }
                                });
                            });
                    } else {
                        this.tableLoad = false;
                    }
                },
                error => {
                    console.error(error);
                    this.tableLoad = false;
                    this.ref.markForCheck();
                },
            );
    }

    search(): void {
        this.gridUsers.search(this.searchtextObj.value);
    }

    onDataBound(): void {
        this.rowSelected();

        const width = document.getElementById('usersGrid_content_table')?.offsetWidth;
        const parent1 = document.getElementById('scroll_div_users');
        const parent2 = document.getElementById('grid_parent_users');

        if (parent1 && parent2 && width) {
            parent1.style.width = `${width}px`;
            parent2.style.width = `${width}px`;
        }

        this.gridUsers.autoFitColumns();
        this.ref.markForCheck();
    }

    rowDataBound(args: RowDataBoundEventArgs): void {
        const data = args.data as User;

        if (
            this.currentUser &&
            args.row &&
            data.created_by !== this.currentUser.id &&
            !this.permissionService.checkUserISAdministrative(this.currentUser)
        ) {
            args.row.getElementsByClassName('e-gridchkbox')[0].classList.add('disablecheckbox');
            args.row
                .getElementsByClassName('e-checkbox-wrapper')[0]
                .classList.add('disablecheckbox');
        }

        this.ref.markForCheck();
    }

    onStatusFilterChange(e: ChangeEventArgs): void {
        if (e.value === 'všichni uživatelé') {
            this.gridUsers.clearFiltering();
        } else if (e.value === 'autorizovaní uživatelé') {
            this.gridUsers.filterSettings = {
                columns: [
                    {
                        field: 'authorized',
                        operator: 'equal',
                        value: true,
                        predicate: 'and',
                        matchCase: false,
                        ignoreAccent: true,
                    },
                    {
                        field: 'emailverify',
                        operator: 'equal',
                        value: true,
                        predicate: 'and',
                        matchCase: false,
                        ignoreAccent: true,
                    },
                    {
                        field: 'deleted_date',
                        operator: 'equal',
                        value: null as unknown as string,
                        predicate: 'and',
                        matchCase: false,
                        ignoreAccent: true,
                    },
                ],
            };
        } else if (e.value === 'de-autorizovaní uživatelé') {
            this.gridUsers.filterSettings = {
                columns: [
                    {
                        field: 'authorized',
                        operator: 'equal',
                        value: false,
                        predicate: 'and',
                        matchCase: false,
                        ignoreAccent: true,
                    },
                    {
                        field: 'deleted_date',
                        operator: 'notequal',
                        value: null as unknown as string,
                        predicate: 'and',
                        matchCase: false,
                        ignoreAccent: true,
                    },
                ],
            };
        } else if (e.value === 'čekající na ověření') {
            this.gridUsers.filterSettings = {
                columns: [
                    {
                        field: 'authorized',
                        operator: 'equal',
                        value: true,
                        predicate: 'and',
                        matchCase: false,
                        ignoreAccent: true,
                    },
                    {
                        field: 'emailverify',
                        operator: 'equal',
                        value: false,
                        predicate: 'and',
                        matchCase: false,
                        ignoreAccent: true,
                    },
                    {
                        field: 'deleted_date',
                        operator: 'equal',
                        value: null as unknown as string,
                        predicate: 'and',
                        matchCase: false,
                        ignoreAccent: true,
                    },
                ],
            };
        } else if (e.value === 'hotlineři') {
            this.gridUsers.filterSettings = {
                columns: [
                    {
                        field: 'hotliner_id',
                        operator: 'notequal',
                        value: null as unknown as string,
                        predicate: 'and',
                        matchCase: false,
                        ignoreAccent: true,
                    },
                    {
                        field: 'deleted_date',
                        operator: 'equal',
                        value: null as unknown as string,
                        predicate: 'and',
                        matchCase: false,
                        ignoreAccent: true,
                    },
                ],
            };
        }
    }

    cancelBtnClick(): void {
        this.searchtextObj.value = '';
        this.gridUsers.searchSettings.key = '';
    }

    toolbarClick(args: ClickEventArgs): void {
        const xlsProp: ExcelExportProperties = {
            fileName: 'js_users_export.xlsx',
            enableFilter: true,
            exportType: this.exportCurrentPage ? 'CurrentPage' : 'AllPages',
        };

        if (args.item.id === 'usersGrid_excelexport') {
            void this.gridUsers.excelExport(xlsProp);
        } else if (args.item.id === 'delete') {
            if (this.gridUsers.getSelectedRecords().length !== 0) {
                this.confirmationDelete.content = `Potvrďte smazání ${this.clickedRow.length} uživatelů`;
                this.confirmationDelete.show();
            } else {
                this.confirmationCancel.content = 'Musíte vybrat alespoň jednoho uživatele';
                this.confirmationCancel.show();
            }
        } else if (args.item.id === 'detail') {
            void this.router.navigate(['/users/detail', this.clickedRow[0].id]);
        } else if (args.item.id === 'copy') {
            if (this.gridUsers.getSelectedRecords().length !== 0) {
                this.isUpdate = false;
                this.isCreate = false;
                this.isCopy = true;
                this.renderUserFormDialog = true;
                this.ref.markForCheck();
            } else {
                this.confirmationCancel.content = 'Musíte vybrat alespoň jednoho uživatele';
                this.confirmationCancel.show();
            }
        } else if (args.item.id === 'revert') {
            if (this.gridUsers.getSelectedRecords().length !== 0) {
                this.confirmationAuthorized.show();
            } else {
                this.confirmationCancel.content = 'Musíte vybrat alespoň jednoho uživatele';
                this.confirmationCancel.show();
            }
        } else if (args.item.id === 'customRefresh') {
            this.tableLoad = true;
            console.info('refresh DATA!');
            this.ref.markForCheck();
            this.dataService.clearUserCache();
            this.dataService.setUsersDataSource();
        }
    }

    enableToolbarBtn(): void {
        if (
            this.currentUser &&
            this.permissionService.checkUserISAdministrative(this.currentUser)
        ) {
            this.gridUsers.toolbarModule.enableItems(
                ['usersGrid_edit', 'delete', 'detail', 'copy', 'revert'],
                true,
            );
        } else {
            this.gridUsers.toolbarModule.enableItems(['detail'], true);
        }
    }

    disableToolBarBtn(): void {
        this.gridUsers.toolbarModule.enableItems(
            ['usersGrid_edit', 'delete', 'detail', 'copy', 'revert'],
            false,
        );
    }

    rowSelected(): void {
        this.clickedRow = [];

        const selectedrecords: object[] = this.gridUsers.getSelectedRecords();

        if (selectedrecords.length > 0) {
            selectedrecords.map((row: User) => {
                const tempData: User | undefined = this.users?.find(x => x.id === row.id);

                if (tempData) {
                    this.clickedRow.push(tempData);
                }

                this.ref.markForCheck();
            });

            this.clickedRow$ = new Observable<User[]>((observer: Observer<User[]>) => {
                observer.next(this.clickedRow);
            });
            this.enableToolbarBtn();
        } else {
            this.disableToolBarBtn();
            this.clickedRow$ = new Observable<User[]>((observer: Observer<User[]>) => {
                observer.next(this.clickedRow);
            });
        }
    }

    actionBegin(args): void {
        if (args.requestType === 'add' || args.requestType === 'beginEdit') {
            args.cancel = true;

            if (args.requestType === 'beginEdit') {
                this.isUpdate = true;
                this.isCreate = false;
                this.isCopy = false;
            }

            if (args.requestType === 'add') {
                this.isCreate = true;
                this.isUpdate = false;
                this.isCopy = false;
            }

            this.renderUserFormDialog = true;
            this.ref.markForCheck();
        }
    }

    actionComplete(args): void {
        if (args.requestType === 'beginEdit' || args.requestType === 'add') {
            args.form.ej2_instances[0].rules = {};
        }
    }

    deleteUser(): void {
        const promises: Promise<User | undefined>[] = [];
        let rejects = 0;

        this.tableLoad = true;

        for (const row of this.clickedRow) {
            if (
                this.currentUser &&
                !row.deleted_date &&
                (this.permissionService.checkUserISAdministrative(this.currentUser) ||
                    row.creator.boss_id === this.currentUser.id)
            ) {
                row.deleted_by = this.currentUser.id;

                const declineObj = this.userService.deleteUser(row)
                    ?.toPromise();

                if (declineObj) {
                    promises.push(declineObj);
                }
            } else {
                rejects++;
            }
        }

        void Promise.all(promises)
            .then(result => {
                if (result.length > 0) {
                    const body = `Celkem smazáno: ${result.length} uživatelů`;
                    const options = {progressBar: true, timeOut: 5000};

                    this.message.showSuccess('Smazání úspěšně dokončeno', body, options);
                }

                if (rejects > 0) {
                    const body = `Celkem u: ${rejects} uživatelů`;
                    const options = {progressBar: true, timeOut: 5000};

                    this.message.showError('Nedostatečné oprávnění', body, options);
                }

                this.gridUsers.clearRowSelection();
                this.dataService.setUsersDataSource();
                this.rowSelected();
                this.tableLoad = false;
                this.confirmationDelete.hide();
                this.ref.markForCheck();
            });
    }

    reAuthorize(): void {
        this.tableLoad = true;

        if (
            this.currentUser &&
            this.permissionService.checkUserISAdministrative(this.currentUser)
        ) {
            const user: UserInput = {
                id: this.clickedRow[0].id,
                updated_by: this.currentUser.id,
                password: 'j0n2019System',
                authorized: true,
                deleted_date: null,
                deleted_by: null,
            };

            this.userService
                .updateUser(user, true)
                ?.pipe(untilDestroyed(this))
                .subscribe(
                    (data: User) => {
                        const body = `uživatele: #${data.id}`;
                        const options = {progressBar: true, timeOut: 5000};

                        this.message.showSuccess('Úspěšná obnova', body, options);
                        this.gridUsers.clearRowSelection();
                        this.dataService.setUsersDataSource();
                        this.rowSelected();
                        this.confirmationAuthorized.hide();
                        this.ref.markForCheck();
                    },
                    error => {
                        console.error(error);
                        this.tableLoad = false;
                        this.confirmationAuthorized.hide();
                        this.ref.markForCheck();
                    },
                );
        } else {
            const body = 'Nemáte oprávnění provést tuto akci...';
            const options = {progressBar: true, timeOut: 5000};

            this.message.showError('Nedostatečné oprávnění', body, options);
            this.tableLoad = false;
            this.confirmationAuthorized.hide();
            this.ref.markForCheck();
        }
    }
}
