import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {Task} from '@src/app/_models/task/task';
import {catchError, shareReplay, tap} from 'rxjs/operators';
import {MessageService} from '@src/app/_services/message.service';
import {TaskCategory} from '@src/app/_models/task/task-category';
import {TaskStatus} from '@src/app/_models/task/task-status';
import {TaskNotes} from '@src/app/_models/task/task-notes';
import {DataService} from '@src/app/_services/data.service';
import {TaskLogs} from '@src/app/_models/task/task-logs';
import {SafeHtml} from '@angular/platform-browser';
import {Tags} from '@src/app/_models/tags/tags';
import {EnvironmentService} from '@src/app/_services/environment.service';

export interface TaskGridItems {
    id: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    ticket_id: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    category_id: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    status_id: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    user_id: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    created_by: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    creator_str: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    creator_img: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    user_str: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    user_img: string;
    subject: string;
    maintask: string;
    maintaskHTML: SafeHtml;
    category: TaskCategory | string;
    due: string;
    status: string;
    tags: SafeHtml | null;
    deadline: Date | string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    finished_at: Date | string | null;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    created_date: Date | string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    updated_date: Date | string | null;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    deleted_date: Date | string | null;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    deleted_by?: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    updated_by?: number;
}

export interface TaskLogsItems {
    id: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    type_id: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    task_id: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    ticket_id: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    category_id: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    status_id: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    user_id: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    solver_str: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    solver_img: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    created_by: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    creator_str: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    creator_img: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    updated_by: number | null | undefined;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    editor_str: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    editor_img: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    deleted_by: number | null | undefined;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    remover_str: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    remover_img: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    tasks_type: number;
    type: string;
    subject: string;
    maintask: string;
    deadline: Date;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    created_date: Date;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    updated_date: Date | null;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    deleted_date: Date | null;
    timestamp: Date;
    mapclmn: string;
}

export interface TaskInput {
    id?: number;
    subject?: string;
    maintask?: string;
    solution?: string | null;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    category_id?: number;
    tags?: Tags[];
    // eslint-disable-next-line @typescript-eslint/naming-convention
    start_deadline?: Date | string;
    deadline?: Date | string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    finished_at?: Date | string | null;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    viewed_at?: Date | string | null;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    last_viewed_at?: Date | string | null;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    user_id?: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    status_id?: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    updated_by?: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    ticket_id?: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    deleted_by?: number | null;
}

const httpOptions = {
    headers: new HttpHeaders({'Content-Type': 'application/json'}),
};

@Injectable({
    providedIn: 'root',
})
export class TasksService {
    private readonly tasksUrl: string;

    private readonly tasksCategoryUrl: string;

    private readonly statusUrl: string;

    private readonly logsUrl: string;

    private readonly tasksNotesUrl: string;

    tasks: Observable<Task[]>;

    task: Observable<Task>;

    categories: Observable<TaskCategory[]>;

    statuses: Observable<TaskStatus[]>;

    constructor(
        private readonly http: HttpClient,
        private readonly dataService: DataService,
        private readonly messageService: MessageService,
        private readonly environmentService: EnvironmentService,
    ) {
        this.tasksUrl = this.environmentService.backendURL + '/api/task';
        this.tasksCategoryUrl = this.environmentService.backendURL + '/api/task/categories';
        this.statusUrl = this.environmentService.backendURL + '/api/status/';
        this.logsUrl = this.environmentService.backendURL + '/api/task/log';
        this.tasksNotesUrl = this.environmentService.backendURL + '/api/task/note';
    }

    /**
     * Handle Http operation that failed.
     * Let the app continue.
     * @param operation - name of the operation that failed
     * @param result - optional value to return as the observable result
     */
    private handleError<T>(operation = 'operation', result?: T) {
        return (error: {[key: string]: string}): Observable<T | undefined> => {
            console.error(error); // log to console instead
            this.log(`${operation} failed: ${error.message}`);

            return of(result);
        };
    }

    /** Log a taskservice message with the MessageService */
    private log(message: string): void {
        this.messageService.addNotification(`Task: ${message}`, false);
    }

    getActiveCategories(): Observable<TaskCategory[] | undefined> {
        return this.http.get<TaskCategory[]>(this.tasksCategoryUrl)
            .pipe(
                tap(() => {
                    this.log('fetched tasks categories');
                }),
                shareReplay(), // this tells Rx to cache the latest emitted
                catchError(this.handleError<TaskCategory[]>('getCategoryQueue', [])),
            );
    }

    getActiveStatuses(): Observable<TaskStatus[] | undefined> {
        return this.http.get<TaskStatus[]>(this.statusUrl)
            .pipe(
                tap(() => {
                    this.log('fetched statuses');
                }),
                shareReplay(), // this tells Rx to cache the latest emitted
                catchError(this.handleError<TaskStatus[]>('getStatusQueue', [])),
            );
    }

    addTask(task: unknown): Observable<Task> {
        this.dataService.clearTasksCache();
        this.dataService.clearTagsCache();

        return this.http.post<Task>(this.tasksUrl, task, httpOptions);
    }

    deleteTask(task: Task): Observable<Task | undefined> | undefined {
        if (!task.deleted_by) {
            console.error('task.deleted_by is missing...');

            return;
        }

        const headers = new HttpHeaders({'Content-Type': 'application/json'});
        const params = new HttpParams().set(
            'deleted_by',
            typeof task.deleted_by !== 'undefined' ? task.deleted_by.toString() : 'null',
        );

        const url = `${this.tasksUrl}/task/${task.id}`;

        this.dataService.clearTasksCache();

        return this.http.delete<Task>(url, {headers, params});
    }

    deleteTaskNote(note: TaskNotes): Observable<TaskNotes> {
        const url = `${this.tasksNotesUrl}/${note.id}`;

        this.dataService.clearTasksCache();

        return this.http.delete<TaskNotes>(url, httpOptions);
    }

    updateTaskNote(note: TaskNotes): Observable<TaskNotes> {
        const url = `${this.tasksNotesUrl}/${note.id}`;

        this.dataService.clearTasksCache();

        return this.http.put<TaskNotes>(url, note, httpOptions);
    }

    addSolvingNote(note: unknown): Observable<TaskNotes> {
        this.dataService.clearTasksCache();

        return this.http.post<TaskNotes>(this.tasksNotesUrl, note, httpOptions);
    }

    getLogs(task: Task): Observable<TaskLogs[] | undefined> {
        const url = `${this.logsUrl}/${task.id}`;

        return this.http.get<TaskLogs[]>(url)
            .pipe(
                tap(() => {
                    this.log('fetched task logs');
                }),
                shareReplay(), // this tells Rx to cache the latest emitted
                catchError(this.handleError<TaskLogs[]>('getTaskLogs', [])),
            );
    }

    updateTask(task: TaskInput): Observable<Task | object | undefined> | undefined {
        if (!task.id) {
            console.error('ID is missing...');

            return;
        }

        const url = `${this.tasksUrl}/update/${task.id}`;

        this.dataService.clearTagsCache();
        this.dataService.clearTasksCache();

        return this.http.put<Task>(url, task, httpOptions)
            .pipe(
                tap(() => {
                    this.log(`updated task id=${task.id ?? 'null'}`);
                }),
                catchError(this.handleError<Task>(`task id=${task.id}`)),
            );
    }
}
