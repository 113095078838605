<div class="fixed-action-btn">
  <a href="/wiki#ticketCreate">
    <button class="btn-info btn-floating btn-large" ejs-button>
      <i aria-hidden="true" class="fa-light fa-lg fa-ticket-alt"></i>
    </button>
  </a>
  <a href="/wiki#taskCreate">
    <button class="btn-warning btn-floating btn-large" ejs-button>
      <i aria-hidden="true" class="fa-light fa-lg fa-ballot-check"></i>
    </button>
  </a>
  <a href="/wiki#vacationCreate">
    <button class="btn-danger btn-floating btn-large" ejs-button>
      <i aria-hidden="true" class="fa-light fa-lg fa-calendar-alt"></i>
    </button>
  </a>
  <a href="/wiki#materialCreate">
    <button class="btn-secondary btn-floating btn-large" ejs-button>
      <i aria-hidden="true" class="fa-light fa-lg fa-box"></i>
    </button>
  </a>
  <a href="/wiki#hotlineCreate">
    <button class="btn-default btn-floating btn-large" ejs-button>
      <i aria-hidden="true" class="fa-light fa-lg fa-headset"></i>
    </button>
  </a>
  <button
    (click)="onPageUp()"
    cssClass="e-btn e-primary btn-p-zero e-small"
    ejs-button
  >
    <i aria-hidden="true" class="fa-light fa-lg fa-arrow-alt-to-top"></i>
  </button>
  <button
    (click)="onConvertToPDF()"
    cssClass="e-btn e-primary btn-p-zero e-small"
    ejs-button
  >
    <i aria-hidden="true" class="fa-light fa-lg fa-file-pdf"></i>
  </button>
</div>
<div class="row">
  <!-- TABLE OF CONTENT -->
  <div
    class="d-none d-lg-block col-3"
    style="
            position: -webkit-sticky !important;
            position: sticky !important;
            top: 65px !important;
        "
  >
    <div class="table-of-contents">
      <h1 class="title">
        <a href="https://github.com/jfcere/ngx-markdown">JON Systém</a>
      </h1>
      <div class="title-based-on">
        <span class="text-muted">poslední aktualizace: 23.1.2020</span>
      </div>
      <ul>
        <li class="menu-subtitle"><span>Tickety</span></li>
        <li><a href="/wiki#ticketCreate">Vytvoření ticketu</a></li>
        <li><a href="/wiki#ticketEdit">Úpravy ticketu</a></li>
        <!--<li><a href="/wiki#ticketAssignations">Zapojit uživatele</a></li>
        <li><a href="/wiki#ticketTasks">Připojit úkol</a></li>
        <li><a href="/wiki#ticketComments">Diskuze a postupy</a></li>-->
        <li><a href="/wiki#ticketFiles">Soubory</a></li>
        <!--        <li class="menu-subtitle"><span>Úkoly</span></li>
                <li><a href="/wiki#headers">Vytvoření úkolu</a></li>
                <li><a href="/wiki#headers">Editace úkolu</a></li>
                <li><a href="/wiki#headers">Smazání úkolu</a></li>
                <li><a href="/wiki#emphasis">Zapojit řešitele</a></li>
                <li><a href="/wiki#links">Postupy</a></li>
                <li class="menu-subtitle"><span>Žádanky</span></li>
                <li><a href="/wiki#remote-url">Vytvoření žádanky</a></li>
                <li><a href="/wiki#headers">Editace žádanky</a></li>
                <li><a href="/wiki#headers">Smazání žádanky</a></li>
                <li><a href="/wiki#variable-binding">Schvalování žádanek</a></li>
                <li class="menu-subtitle"><span>Objednávky</span></li>
                <li><a href="/wiki#remote-url">Vytvoření objednávky</a></li>
                <li><a href="/wiki#headers">Editace objednávky</a></li>
                <li><a href="/wiki#headers">Smazání objednávky</a></li>
                <li><a href="/wiki#line-numbers">Vytvoření rozpočtu</a></li>
                <li><a href="/wiki#headers">Editace rozpočtu</a></li>
                <li><a href="/wiki#headers">Smazání rozpočtu</a></li>
                <li><a href="/wiki#variable-binding">Schvalování objednávek</a></li>-->
        <li class="menu-subtitle"><span>Hotline</span></li>
        <li><a href="/wiki#hotlinerCreate">Vytvoření hotlinera</a></li>
        <li><a href="/wiki#hotlinerEdit">Editace hotlinera</a></li>
        <li><a href="/wiki#hotlineCreate">Vytvoření hotline</a></li>
        <li><a href="/wiki#hotlineImport">Import hotline</a></li>
        <li><a href="/wiki#hotlineEdit">Editace hotline</a></li>
      </ul>
    </div>
  </div>
  <div class="d-block d-lg-none col-12">
    <div class="table-of-contents">
      <h1 class="title">
        <a href="https://github.com/jfcere/ngx-markdown">JON Systém</a>
      </h1>
      <div class="title-based-on">
        <span class="text-muted">poslední aktualizace: 30.9.2019</span>
      </div>
      <ul>
        <li class="menu-subtitle"><span>Tickety</span></li>
        <li><a href="/wiki#ticketCreate">Vytvoření ticketu</a></li>
        <li><a href="/wiki#ticketEdit">Úpravy ticketu</a></li>
        <!--<li><a href="/wiki#ticketAssignations">Zapojit uživatele</a></li>
        <li><a href="/wiki#ticketTasks">Připojit úkol</a></li>
        <li><a href="/wiki#ticketComments">Diskuze a postupy</a></li>-->
        <li><a href="/wiki#ticketFiles">Soubory</a></li>
        <!--        <li class="menu-subtitle"><span>Úkoly</span></li>
                <li><a href="/wiki#taskCreate">Vytvoření úkolu</a></li>
                <li><a href="/wiki#taskEdit">Editace úkolu</a></li>
                <li><a href="/wiki#taskDelete">Smazání úkolu</a></li>
                <li><a href="/wiki#taskAssignations">Zapojit řešitele</a></li>
                <li class="menu-subtitle"><span>Žádanky</span></li>
                <li><a href="/wiki#vacationCreate">Vytvoření žádosti</a></li>
                <li><a href="/wiki#vacationEdit">Editace žádosti</a></li>
                <li><a href="/wiki#vacationDelete">Smazání žádosti</a></li>
                <li><a href="/wiki#vacationApprove">Schvalování žádostí</a></li>
                <li class="menu-subtitle"><span>Objednávky</span></li>
                <li><a href="/wiki#materialCreate">Vytvoření objednávky</a></li>
                <li><a href="/wiki#materialEdit">Editace objednávky</a></li>
                <li><a href="/wiki#materialDelete">Smazání objednávky</a></li>
                <li><a href="/wiki#materialBudgetCreate">Vytvoření rozpočtu</a></li>
                <li><a href="/wiki#materialBudgetEdit">Editace rozpočtu</a></li>
                <li><a href="/wiki#materialBudgetDelete">Smazání rozpočtu</a></li>
                <li><a href="/wiki#materialApprove">Schvalování objednávek</a></li>-->
        <li class="menu-subtitle"><span>Hotline</span></li>
        <li><a href="/wiki#hotlinerCreate">Vytvoření hotlinera</a></li>
        <li><a href="/wiki#hotlinerEdit">Editace hotlinera</a></li>
        <li><a href="/wiki#hotlineCreate">Vytvoření hotline</a></li>
        <li><a href="/wiki#hotlineImport">Import hotline</a></li>
        <li><a href="/wiki#hotlineEdit">Editace hotline</a></li>
      </ul>
    </div>
  </div>
  <div class="col-12 col-lg-9">
    <!-- TICKETS -->
    <section id="ticket">
      <h2 class="subtitle">Tickety</h2>
      <markdown id="ticketCreate">{{ ticketCreate.default }}</markdown>
      <br />
      <br />
      <br />
      <markdown id="ticketEdit">{{ ticketEdit.default }}</markdown>
      <br />
      <br />
      <br />
      <!-- <markdown id="ticketAssignations">{{ ticketAssignations.default }}</markdown>
       <br/>
       <br/>
       <br/>
       <markdown id="ticketTasks">{{ ticketTask.default }}</markdown>
       <br/>
       <br/>
       <br/>
       <markdown id="ticketComments">{{ ticketComments.default }}</markdown>
       <br/>
       <br/>
       <br/>-->
      <markdown id="ticketFiles">{{ ticketFiles.default }}</markdown>
    </section>
    <!--      &lt;!&ndash; TASKS &ndash;&gt;
          <section id="task">
              <h2 class="subtitle">Úkoly</h2>
              <markdown id="taskCreate">{{ ticketCreate.default }}</markdown>
              <br/>
              <br/>
              <br/>
              <markdown id="taskEdit">{{ ticketEdit.default }}</markdown>
              <br/>
              <br/>
              <br/>
              <markdown id="taskDelete">{{ ticketEdit.default }}</markdown>
              <br/>
              <br/>
              <br/>
              <markdown id="taskAssignations">{{ ticketAssignations.default }}</markdown>
          </section>
          &lt;!&ndash; VACATIONS &ndash;&gt;
          <section id="vacation">
              <h2 class="subtitle">Žádanky</h2>
              <markdown id="vacationCreate">{{ ticketCreate.default }}</markdown>
              <br/>
              <br/>
              <br/>
              <markdown id="vacationEdit">{{ ticketEdit.default }}</markdown>
              <br/>
              <br/>
              <br/>
              <markdown id="vacationkDelete">{{ ticketEdit.default }}</markdown>
              <br/>
              <br/>
              <br/>
              <markdown id="vacationApprove">{{ ticketAssignations.default }}</markdown>
          </section>
          &lt;!&ndash; MATERIAL &ndash;&gt;
          <section id="material">
              <h2 class="subtitle">Objednávky</h2>
              <markdown id="materialCreate">{{ ticketCreate.default }}</markdown>
              <br/>
              <br/>
              <br/>
              <markdown id="materialEdit">{{ ticketEdit.default }}</markdown>
              <br/>
              <br/>
              <br/>
              <markdown id="materialDelete">{{ ticketEdit.default }}</markdown>
              <br/>
              <br/>
              <br/>
              <markdown id="materialBudgetCreate">{{ ticketCreate.default }}</markdown>
              <br/>
              <br/>
              <br/>
              <markdown id="materialBudgetEdit">{{ ticketEdit.default }}</markdown>
              <br/>
              <br/>
              <br/>
              <markdown id="materialBudgetDelete">{{ ticketEdit.default }}</markdown>
              <br/>
              <br/>
              <br/>
              <markdown id="materialApprove">{{ ticketAssignations.default }}</markdown>
          </section>-->
    <!-- HOTLINE -->
    <section id="hotline">
      <h2 class="subtitle">Hotline</h2>
      <markdown id="hotlinerCreate">{{
          hotlinerCreate.default
        }}
      </markdown>
      <br />
      <br />
      <br />
      <markdown id="hotlinerEdit">{{ hotlinerEdit.default }}</markdown>
      <br />
      <br />
      <br />
      <markdown id="hotlineCreate">{{ hotlineCreate.default }}</markdown>
      <br />
      <br />
      <br />
      <markdown id="hotlineImport">{{ hotlineImport.default }}</markdown>
      <br />
      <br />
      <br />
      <markdown id="hotlineEdit">{{ hotlineEdit.default }}</markdown>
      <br />
      <br />
      <br />
    </section>
  </div>
</div>
