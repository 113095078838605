import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    Output,
    ViewChild,
} from '@angular/core';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {CommentsService, TaskCommentInput} from '@src/app/shared_components/services/comments.service';
import {
    FontFamilyModel,
    ImageSettingsModel,
    QuickToolbarSettingsModel,
    RichTextEditorComponent,
} from '@syncfusion/ej2-angular-richtexteditor';
import {ToolbarModule} from '@syncfusion/ej2-angular-navigations';
import {ActivatedRoute} from '@angular/router';
import {MessageService} from '@src/app/_services/message.service';
import {AuthenticationService} from '@src/app/_services/authentication.service';
import {User} from '@src/app/_models/user/user';
import {addClass, Browser, createElement, removeClass} from '@syncfusion/ej2-base';
import {Task} from '@src/app/_models/task/task';
import {TaskComment} from '@src/app/_models/task/task-comment';

@UntilDestroy()
@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'app-task-comments',
    templateUrl: './task-comments.component.html',
    styleUrls: ['./task-comments.component.scss'],
})
export class TaskCommentsComponent {
    loadingComment = false;

    currentUser: User | null;

    submited = false;

    showComment = false;

    live: true;

    reply = '';

    limit = 2;

    page = 1;

    pageSize = 10;

    contentPrinted: boolean;

    contentSearched: number | string;

    // RTE
    maxLength = 15000;

    textArea: HTMLElement;

    insertImageSettings: ImageSettingsModel;

    fontFamily: FontFamilyModel = {
        items: [
            {
                text: 'Segoe UI',
                value: 'Segoe UI',
                command: 'Font',
                subCommand: 'FontName',
            },
            {
                text: 'Roboto',
                value: 'Roboto',
                command: 'Font',
                subCommand: 'FontName',
            },
            {
                text: 'Great vibes',
                value: 'Great Vibes,cursive',
                command: 'Font',
                subCommand: 'FontName',
            },
            {
                text: 'Noto Sans',
                value: 'Noto Sans',
                command: 'Font',
                subCommand: 'FontName',
            },
            {
                text: 'Impact',
                value: 'Impact,Charcoal,sans-serif',
                command: 'Font',
                subCommand: 'FontName',
            },
            {
                text: 'Tahoma',
                value: 'Tahoma,Geneva,sans-serif',
                command: 'Font',
                subCommand: 'FontName',
            },
        ],
    };

    tools: ToolbarModule = {
        items: [
            'Bold',
            'Italic',
            'Underline',
            'StrikeThrough',
            'FontName',
            'FontSize',
            'FontColor',
            'BackgroundColor',
            'LowerCase',
            'UpperCase',
            'SuperScript',
            'SubScript',
            '|',
            'Formats',
            'Alignments',
            'OrderedList',
            'UnorderedList',
            'Outdent',
            'Indent',
            '|',
            'CreateTable',
            'CreateLink',
            'Image',
            '|',
            'ClearFormat',
            'Print',
            'SourceCode',
            'FullScreen',
            '|',
            'Undo',
            'Redo',
        ],
    };

    quickToolbarSettings: QuickToolbarSettingsModel = {
        table: [
            'TableHeader',
            'TableRows',
            'TableColumns',
            'TableCell',
            '-',
            'BackgroundColor',
            'TableRemove',
            'TableCellVerticalAlign',
            'Styles',
        ],
    };

    pasteCleanupSettings: object = {
        prompt: true,
        plainText: false,
        keepFormat: false,
        deniedTags: ['a'],
        deniedAttrs: ['class', 'title', 'id'],
        allowedStyleProps: ['color', 'margin', 'font-size'],
    };

    @Input() task?: Task | null;

    @Output() readonly deleteTaskCommentEvent = new EventEmitter<TaskComment>();

    @Output() readonly editTaskCommentEvent = new EventEmitter<TaskComment>();

    @Output() readonly addTaskCommentEvent = new EventEmitter<TaskCommentInput>();


    // RTE
    @ViewChild('reply_text') replyText?: RichTextEditorComponent;

    constructor(
        private readonly route: ActivatedRoute,
        private readonly authenticationService: AuthenticationService,
        private readonly ref: ChangeDetectorRef,
        private readonly commentService: CommentsService,
        private readonly messages: MessageService,
    ) {
        this.currentUser = this.authenticationService.currentUserValue;
        this.contentPrinted = false;
    }

    deleteComment(comment: TaskComment): void {
        this.deleteTaskCommentEvent.emit(comment);
    }

    editComment(comment: TaskComment): void {
        this.editTaskCommentEvent.emit(comment);
    }

    onLoopFinished(): void {
        this.route.queryParams.pipe(untilDestroyed(this))
            .subscribe(params => {
                this.contentPrinted = true;
                this.contentSearched = params.comment;

                if (this.contentSearched) {
                    setTimeout(() => {
                        const item: HTMLElement | null = document.getElementById(
                            `comment_${params.comment as string}`,
                        );

                        item?.parentElement?.scrollIntoView({
                            behavior: 'smooth',
                            block: 'start',
                        });
                    }, 2000);
                    this.ref.markForCheck();
                }
            });
    }

    markComment(x: string, y: string): boolean {
        return parseInt(x, 10) === parseInt(y, 10);
    }

    replyChange(value): void {
        this.reply = value;
        this.ref.markForCheck();
    }

    onScroll(): void {
        this.limit = this.limit + 2;
        this.ref.markForCheck();
    }

    onReply(threadId: TaskComment | number, reply: string): void {
        this.submited = true;
        this.loadingComment = true;

        const comment: TaskCommentInput = {
            text: reply,
            task_id: this.task?.id,
            created_by: this.currentUser?.id,
            is_thread: false,
            thread_id: typeof threadId === 'number' ? threadId : threadId.id,
        };

        this.commentService
            .addTaskComment(comment)
            .pipe(untilDestroyed(this))
            .subscribe(
                () => {
                    const options = {progressBar: true, timeOut: 5000};

                    this.messages.showSuccess('Diskuze aktualizována', 'Odpověď úspěšně aktualizován.', options);

                    if (!this.task) {
                        console.error('this.task is missing...');

                        return;
                    }

                    this.addTaskCommentEvent.emit(comment);
                    this.showComment = false;
                    this.loadingComment = false;
                    this.ref.markForCheck();
                },
                error => {
                    console.error(error);

                    const body = 'Zkuste to znovu později ...';
                    const options = {
                        progressBar: true,
                        timeOut: 5000,
                        toastClass: 'red',
                    };

                    this.messages.showError(
                        'Chyba při publikování odpovědi na komentář v ticketu',
                        body,
                        options,
                    );
                    this.loadingComment = false;
                    this.ref.markForCheck();
                },
            );
    }

    // RTE
    onCreateReplyRte(): void {
        this.replyText?.refreshUI();
        this.replyText?.autoResize();
    }

    handleFullScreen(e: unknown): void {
        const sbCntEle: HTMLElement | null = document.querySelector('.sb-content.e-view');
        const sbHdrEle: HTMLElement | null = document.querySelector('.sb-header.e-view');
        const leftBar: HTMLElement | null = document.querySelector('#left-sidebar');

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        if (e.targetItem === 'Maximize') {
            if (Browser.isDevice && Browser.isIos && sbCntEle && sbHdrEle) {
                addClass([sbCntEle, sbHdrEle], ['hide-header']);
            }

            if (leftBar) {
                addClass([leftBar], ['e-close']);
                removeClass([leftBar], ['e-open']);
            }
        }
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        else if (e.targetItem === 'Minimize') {
            if (Browser.isDevice && Browser.isIos && sbCntEle && sbHdrEle) {
                removeClass([sbCntEle, sbHdrEle], ['hide-header']);
            }

            if (leftBar) {
                removeClass([leftBar], ['e-close']);

                if (!Browser.isDevice) {
                    addClass([leftBar], ['e-open']);
                }
            }
        }
    }

    mirrorReplyConversion(e: unknown): void {
        if (this.replyText) {
            const id = `${this.replyText.getID()}mirror-view`;
            let mirrorView: HTMLElement | null = this.replyText.element.querySelector(`#${id}`);
            const charCount: HTMLElement | null =
                this.replyText.element.querySelector('.e-rte-character-count');

            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            if (e.targetItem === 'Preview' && mirrorView && charCount) {
                this.textArea.style.display = 'block';
                mirrorView.style.display = 'none';
                // eslint-disable-next-line max-len
                // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-member-access
                charCount.style.display = 'block';
            } else {
                if (!mirrorView && this.textArea.parentNode) {
                    mirrorView = createElement('div', {className: 'e-content'});
                    mirrorView.id = id;
                    this.textArea.parentNode.appendChild(mirrorView);
                } else if (mirrorView) {
                    mirrorView.innerHTML = '';
                }

                if (mirrorView) {
                    this.textArea.style.display = 'none';
                    mirrorView.style.display = 'block';
                }

                if (charCount) {
                    charCount.style.display = 'none';
                }
            }
        }
    }

    actionReplyCompleteHandler(e: unknown): void {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        if (e.targetItem && (e.targetItem === 'SourceCode' || e.targetItem === 'Preview')) {
            (this.replyText?.sourceCodeModule.getPanel() as HTMLTextAreaElement).style.display =
                'none';
            this.mirrorReplyConversion(e);
        } else {
            setTimeout(() => {
                this.replyText?.toolbarModule.refreshToolbarOverflow();
            }, 400);
        }
    }

}
