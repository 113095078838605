import {UntilDestroy} from '@ngneat/until-destroy';
import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    HostListener,
    Input,
    Output,
} from '@angular/core';
import {ComponentCanDeactivate} from '@src/app/_guards/changes.guard';
import {Observable} from 'rxjs';
import {Address} from '@src/app/_models/services/address';
import {NetPackages} from '@src/app/_models/services/net-packages';
import {ExtendServices} from '@src/app/_models/services/extend-services';

@UntilDestroy()
@Component({
    selector: 'app-callwizard-internet',
    templateUrl: './cw-internet.component.html',
    styleUrls: ['./cw-internet.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CallwizardInternetComponent implements ComponentCanDeactivate {
    isDirty = false;

    @Input() address: Address | null = null;

    @Input() skip = false;

    @Output() readonly netService = new EventEmitter<NetPackages>();

    @Output() readonly netExtensions = new EventEmitter<ExtendServices>();

    @HostListener('window:beforeunload')
    canDeactivate(): Observable<boolean> | boolean {
        return !this.isDirty;
    }
}
