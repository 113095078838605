import {
    AfterViewChecked,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnInit,
    ViewChild,
} from '@angular/core';

import {registerLocaleData} from '@angular/common';
import moment from 'moment';
import localeCs from '@angular/common/locales/cs';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {
    DataResult,
    EditSettingsModel,
    ExcelExportProperties,
    FilterSettingsModel,
    GridComponent,
    GroupSettingsModel,
    PageSettingsModel,
    RowDataBoundEventArgs,
    SelectionSettingsModel,
    ToolbarItems,
} from '@syncfusion/ej2-angular-grids';
import {ButtonPropsModel, DialogComponent} from '@syncfusion/ej2-angular-popups';
import {User} from '@src/app/_models/user/user';
import {Observable, Observer} from 'rxjs';
import {TextBoxComponent} from '@syncfusion/ej2-angular-inputs';
import {ChangeEventArgs, DropDownListComponent} from '@syncfusion/ej2-angular-dropdowns';
import {ActivatedRoute, Router} from '@angular/router';
import {FormBuilder} from '@angular/forms';
import {AuthenticationService} from '@src/app/_services/authentication.service';
import {DataService} from '@src/app/_services/data.service';
import {PermissionService} from '@src/app/_services/permission.service';
import {MessageService} from '@src/app/_services/message.service';
import {ClickEventArgs} from '@syncfusion/ej2-angular-navigations';
import {Car} from '@src/app/_models/cars/car.model';
import {SettingsService} from '@src/app/features/settings/settings.service';
import {CarsGridItems} from '@src/app/features/settings/types/car-grid.items.type';

moment.updateLocale('cs', {workingWeekdays: [1, 2, 3, 4, 5]});
registerLocaleData(localeCs);

@UntilDestroy()
@Component({
    selector: 'app-cars-list',
    templateUrl: './cars-list.component.html',
    styleUrls: ['./cars-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CarsListComponent implements OnInit, AfterViewChecked {
    today = moment()
        .format('YYYY-MM-DD HH:mm:ss');

    // Grid
    currencyFormat: {format: string} = {format: '#,###.00\',- CZK\''};

    dateFormat: string;

    sortOptions: object;

    filterSettings: FilterSettingsModel;

    selectionSettings: SelectionSettingsModel;

    editSettings: EditSettingsModel;

    groupOptions: GroupSettingsModel;

    pageOptions: PageSettingsModel;

    filterDate: object;

    requiredRules: object;

    categoryFilterData: string[] = [];

    cars: Car[] = [];

    toolbar: ToolbarItems[] | object;

    data: object;

    fields: object = {text: 'label', value: 'value'};

    exportCurrentPage = false;

    // Dialogs
    renderCarFormDialog = false;

    buttonsDelete: ButtonPropsModel[] = [
        {
            click: (): void => {
                this.deleteCar();
            },
            isFlat: false,
            buttonModel: {
                content: 'OK',
                cssClass: 'e-success e-outline',
            },
        },
        {
            click: (): void => {
                this.confirmationDelete.hide();
            },
            buttonModel: {
                content: 'ZRUŠIT',
                cssClass: 'e-danger e-outline',
            },
        },
    ];

    buttonsReservation: ButtonPropsModel[] = [
        {
            click: (): void => {
                this.createCarReservation();
            },
            isFlat: false,
            buttonModel: {
                content: 'OK',
                cssClass: 'e-success e-outline',
            },
        },
        {
            click: (): void => {
                this.confirmationDelete.hide();
            },
            buttonModel: {
                content: 'ZRUŠIT',
                cssClass: 'e-danger e-outline',
            },
        },
    ];

    buttonsCancel: ButtonPropsModel[] = [
        {
            click: (): void => {
                this.confirmationCancel.hide();
            },
            buttonModel: {
                isPrimary: true,
                content: 'OK',
            },
        },
    ];

    // Dropdowns
    height = '240px';

    holidays: Array<string> = [];

    // Forms
    isCreate = false;

    isUpdate = false;

    isCopy = false;

    // přípaneče a loadingy
    loadingCar = true;

    // proměnné pro data
    currentUser: User | null;

    clickedRow: Car[] = [];

    clickedRow$ = new Observable<Car[]>((observer: Observer<Car[]>) => {
        observer.next(this.clickedRow);
    });

    // Grid
    @ViewChild('carsGrid') gridCars?: GridComponent;

    @ViewChild('searchtext') searchtextObj?: TextBoxComponent;

    // Dialogs
    @ViewChild('confirmationDelete') confirmationDelete: DialogComponent;

    @ViewChild('confirmationReservation') confirmationReservation: DialogComponent;

    @ViewChild('confirmationCancel') confirmationCancel: DialogComponent;

    // Dropdowns
    @ViewChild('viewFilter') viewFilterInstance?: DropDownListComponent;

    constructor(
        private readonly router: Router,
        private readonly route: ActivatedRoute,
        private readonly ref: ChangeDetectorRef,
        private readonly formBuilder: FormBuilder,
        private readonly authenticationService: AuthenticationService,
        private readonly dataService: DataService,
        private readonly settingsService: SettingsService,
        private readonly permissionService: PermissionService,
        private readonly messages: MessageService,
    ) {
        this.currentUser = this.authenticationService.currentUserValue;
        this.dataService.setCarsDataSource();
    }

    ngOnInit(): void {
        this.filterSettings = {
            type: 'Excel',
            showFilterBarOperator: true,
            showFilterBarStatus: true,
            ignoreAccent: true,
        };

        this.selectionSettings = {
            persistSelection: true,
            checkboxOnly: true,
        };

        this.toolbar = [
            {
                text: 'Občerstvit data',
                tooltipText: 'Občerstvit data',
                prefixIcon: 'e-refresh',
                id: 'customRefresh',
            },
            'Print',
            'ExcelExport',
            'Add',
            'Edit',
            {
                text: 'Smazat',
                tooltipText: 'Smazat / Stáhnout žádanku',
                prefixIcon: 'e-remove',
                id: 'delete',
            },
            {
                text: 'Rezervovat',
                tooltipText: 'Rezervovat vůz',
                prefixIcon: 'e-check',
                id: 'reservation',
            },
            {
                text: 'Kopírovat',
                tooltipText: 'Kopírovat žádanku',
                prefixIcon: 'e-copy',
                id: 'copy',
            },
            {
                text: 'Detail',
                tooltipText: 'Detail žádanky',
                prefixIcon: 'e-openfolder',
                id: 'detail',
            },
        ];

        this.categoryFilterData = ['všechny vozy', 'moje rezervace', 'smazané vozy'];
        this.pageOptions = {pageSize: 50};
        this.dateFormat = 'dd.MM.yyyy HH:mm';
        this.filterDate = {type: 'Menu', params: {format: this.dateFormat}};
        this.groupOptions = {
            showGroupedColumn: true,
            disablePageWiseAggregates: false,
        };

        this.editSettings = {
            allowEditing: true,
            allowAdding: true,
            allowDeleting: true,
            allowEditOnDblClick: false,
            mode: 'Dialog',
        };
        this.requiredRules = {required: true};
    }

    ngAfterViewChecked(): void {
        this.route.queryParamMap.pipe(untilDestroyed(this))
            .subscribe(params => {
                if (params.get('form') === 'addCar') {
                    console.info('get cars form');
                    this.isCreate = true;
                    this.isUpdate = false;
                    this.isCopy = false;
                    this.renderCarFormDialog = true;
                    this.ref.markForCheck();
                }
            });
    }

    loadData(): DataResult[] {
        this.gridCars?.clearRowSelection();

        console.info('NEW DATA BOUND');

        let elements: CarsGridItems[] = [];

        if (this.cars.length > 0) {
            // eslint-disable-next-line complexity
            this.cars.map((car: Car) => {
                elements.push({
                    id: car.id,
                    created_by: car.created_by,
                    // eslint-disable-next-line max-len
                    creator_str: car.creator.fullname,
                    creator_img: car.creator.personalphoto,
                    // eslint-disable-next-line @typescript-eslint/naming-convention
                    company_id: car.company_id,
                    // eslint-disable-next-line @typescript-eslint/naming-convention
                    updated_by: car.updated_by,
                    // eslint-disable-next-line @typescript-eslint/naming-convention
                    deleted_by: car.deleted_by,
                    // eslint-disable-next-line @typescript-eslint/naming-convention
                    name: car.name,
                    bookable: car.bookable,
                    diesel: car.diesel,
                    gasoline: car.gasoline,
                    lpg: car.lpg,
                    electricity: car.electricity,
                    hybrid: car.hybrid,
                    recharge_time: car.recharge_time,
                    status:
                        car.status === 'queue'
                            ? 've frontě'
                            : car.status === 'confirmed'
                                ? 'schváleno'
                                : car.status === 'declined'
                                    ? 'zamítnuto'
                                    : car.status === 'deleted'
                                        ? 'staženo'
                                        : 'status error',
                    created_date: new Date(car.created_date),
                    updated_date: car.updated_date ? new Date(car.updated_date) : null,
                    deleted_date: car.deleted_date ? new Date(car.deleted_date) : null,
                });
            });

            elements = elements.sort((a: CarsGridItems, b: CarsGridItems) => {
                const dateA = moment(a.created_date)
                    .valueOf();
                const dateB = moment(b.created_date)
                    .valueOf();

                return dateA > dateB ? -1 : 1;
            });
        }

        this.loadingCar = false;
        this.ref.markForCheck();

        return elements as unknown as DataResult[];
    }

    onCreated(): void {
        this.disableToolBarBtn();
    }

    setInitialGridFiltering(): void {
        if (this.currentUser && this.viewFilterInstance) {
            this.gridCars?.clearFiltering();
            this.viewFilterInstance.value = 'všechny vozy';
            this.ref.markForCheck();
        }
    }

    onLoad(): void {
        const wrapper1 = document.getElementById('scroll_wrapper1_cars');
        const wrapper2 = document.getElementById('scroll_wrapper2_cars');

        if (wrapper1 && wrapper2) {
            wrapper1.onscroll = (): void => {
                wrapper2.scrollLeft = wrapper1.scrollLeft;
            };

            wrapper2.onscroll = (): void => {
                wrapper1.scrollLeft = wrapper2.scrollLeft;
            };
        }

        this.dataService.carsSource.pipe(untilDestroyed(this))
            .subscribe(
                (cars: Car[]) => {
                    this.cars = cars;

                    if (this.gridCars) {
                        this.data = this.gridCars.dataSource = this.loadData();
                        this.sortOptions = {
                            columns: [
                                {field: 'created_date', direction: 'Descending'},
                                {
                                    field: 'id',
                                    direction: 'Descending',
                                },
                            ],
                        };
                    }
                },
                error => {
                    console.error(error);
                    this.loadingCar = false;
                    this.ref.markForCheck();
                },
            );
    }

    search(): void {
        this.gridCars?.search(this.searchtextObj?.value ?? '');
    }

    onDataBound(): void {
        this.rowSelected();

        const width = document.getElementById('carsGrid_content_table')?.offsetWidth;
        const parent1 = document.getElementById('scroll_div_cars');
        const parent2 = document.getElementById('grid_parent_cars');

        if (parent1 && parent2 && width) {
            parent1.style.width = `${width}px`;
            parent2.style.width = `${width}px`;
        }

        this.gridCars?.autoFitColumns();
        this.ref.markForCheck();
    }

    rowDataBound(args: RowDataBoundEventArgs): void {
        const data = args.data as CarsGridItems;

        if (
            args.row &&
            args.data &&
            this.currentUser &&
            data.created_by !== this.currentUser.id &&
            !this.permissionService.checkUserISCarAdmin(this.currentUser)
        ) {
            args.row.getElementsByClassName('e-gridchkbox')[0].classList.add('disablecheckbox');
            args.row
                .getElementsByClassName('e-checkbox-wrapper')[0]
                .classList.add('disablecheckbox');
            args.row.classList.add('e-disabled');
        }

        this.ref.markForCheck();
    }

    onStatusFilterChange(e: ChangeEventArgs): void {
        if (this.gridCars && this.currentUser) {
            if (e.value === 'všechny vozy') {
                this.gridCars.clearFiltering();
            } else if (e.value === 'moje rezervace') {
                this.gridCars.clearFiltering();
                this.gridCars.filterByColumn(
                    'created_by',
                    'equal',
                    this.currentUser.id,
                    'and',
                    false,
                    true,
                );
            } else if (e.value === 'smazané vozy') {
                this.gridCars.clearFiltering();
                this.gridCars.filterSettings = {
                    columns: [
                        {
                            field: 'created_by',
                            operator: 'equal',
                            value: this.currentUser.id,
                            predicate: 'and',
                            matchCase: false,
                            ignoreAccent: true,
                        },
                        {
                            field: 'status',
                            operator: 'notequal',
                            value: 've frontě',
                            predicate: 'and',
                            matchCase: false,
                            ignoreAccent: true,
                        },
                    ],
                };
            }
        }
    }

    cancelBtnClick(): void {
        if (this.searchtextObj && this.gridCars) {
            this.searchtextObj.value = '';
            this.gridCars.searchSettings.key = '';
        }
    }

    toolbarClick(args: ClickEventArgs): void {
        const xlsProp: ExcelExportProperties = {
            fileName: 'js_cars_export.xlsx',
            enableFilter: true,
            exportType: this.exportCurrentPage ? 'CurrentPage' : 'AllPages',
        };

        if (args.item.id === 'carsGrid_excelexport') {
            void this.gridCars?.excelExport(xlsProp);
        } else if (args.item.id === 'reservation') {
            if (this.gridCars?.getSelectedRecords().length !== 0) {
                this.confirmationReservation.content =
                    'Budete přesměrování na rezervační kalendář tohoto vozu';
                this.confirmationReservation.show();
            } else {
                this.confirmationCancel.content = 'Musíte vybrat alespoň jeden vůz';
                this.confirmationCancel.show();
            }
        } else if (args.item.id === 'delete') {
            if (this.gridCars?.getSelectedRecords().length !== 0) {
                this.confirmationDelete.content = `Potvrďte smazání ${this.clickedRow.length} vozů`;
                this.confirmationDelete.show();
            } else {
                this.confirmationCancel.content = 'Musíte vybrat alespoň jeden vůz';
                this.confirmationCancel.show();
            }
        } else if (args.item.id === 'copy') {
            if (this.gridCars?.getSelectedRecords().length !== 0) {
                this.isUpdate = false;
                this.isCreate = false;
                this.isCopy = true;
                this.renderCarFormDialog = true;
                this.ref.markForCheck();
            } else {
                this.confirmationCancel.content = 'Musíte vybrat alespoň jeden vůz';
                this.confirmationCancel.show();
            }
        } else if (args.item.id === 'detail') {
            void this.router.navigate(['/booking/cars/detail', this.clickedRow[0].id]);
        } else if (args.item.id === 'customRefresh') {
            this.loadingCar = true;
            console.info('refresh DATA!');
            this.dataService.clearCarsCache();
            this.dataService.setCarsDataSource();
            this.ref.markForCheck();
        }
    }

    enableToolbarBtn(): void {
        if (
            (this.currentUser && this.permissionService.checkUserISCarAdmin(this.currentUser)) ||
            this.currentUser?.is_boss
        ) {
            this.gridCars?.toolbarModule.enableItems(
                ['carsGrid_edit', 'delete', 'reservation', 'copy', 'detail'],
                true,
            );
        } else {
            this.gridCars?.toolbarModule.enableItems(
                ['carsGrid_edit', 'delete', 'reservation', 'copy', 'detail'],
                true,
            );
        }
    }

    disableToolBarBtn(): void {
        this.gridCars?.toolbarModule.enableItems(
            ['carsGrid_edit', 'delete', 'reservation', 'copy', 'detail'],
            false,
        );
    }

    rowSelected(): void {
        this.clickedRow = [];

        const selectedrecords: Car[] = this.gridCars?.getSelectedRecords() as Car[];

        if (selectedrecords.length > 0) {
            selectedrecords.map(row => {
                const tempData = this.cars.find(x => x.id === row.id);

                if (tempData) {
                    this.clickedRow.push(tempData);
                }

                this.ref.markForCheck();
            });

            this.clickedRow$ = new Observable<Car[]>((observer: Observer<Car[]>) => {
                observer.next(this.clickedRow);
            });
            this.enableToolbarBtn();
        } else {
            this.disableToolBarBtn();
            this.clickedRow$ = new Observable<Car[]>((observer: Observer<Car[]>) => {
                observer.next(this.clickedRow);
            });
        }
    }

    actionBegin(args): void {
        if (args.requestType === 'add' || args.requestType === 'beginEdit') {
            args.cancel = true;

            if (args.requestType === 'beginEdit') {
                this.isUpdate = true;
                this.isCreate = false;
                this.isCopy = false;
            }

            if (args.requestType === 'add') {
                this.isCreate = true;
                this.isUpdate = false;
                this.isCopy = false;
            }

            this.renderCarFormDialog = true;
            this.ref.markForCheck();
        }
    }

    actionComplete(args): void {
        if (args.requestType === 'beginEdit' || args.requestType === 'add') {
            args.form.ej2_instances[0].rules = {};
        }
    }

    createCarReservation(): void {
        // TODO
    }

    deleteCar(): void {
        const promises: Array<Promise<Car | undefined> | undefined> = [];
        let rejects = 0;

        this.loadingCar = true;

        for (const row of this.clickedRow) {
            if (
                this.currentUser &&
                !row.deleted_date &&
                (row.created_by === this.currentUser.id ||
                    this.permissionService.checkUserISCarAdmin(this.currentUser))
            ) {
                row.deleted_by = this.currentUser.id;

                const deleteObj = this.settingsService.deleteCar(row)
                    .toPromise();

                promises.push(deleteObj);
            } else {
                rejects++;
            }
        }

        void Promise.all(promises)
            .then(result => {
                if (result.length > 0) {
                    const body = `Celkem stornováno: ${result.length} rezervací`;
                    const options = {progressBar: true, timeOut: 5000};

                    this.messages.showSuccess('Stornování rezervace úspěšně dokončeno', body, options);
                }

                if (rejects > 0) {
                    const body = `Celkem u: ${rejects} rezrvací`;
                    const options = {progressBar: true, timeOut: 5000};

                    this.messages.showError('Nedostatečné oprávnění', body, options);
                }

                this.confirmationDelete.hide();
                this.gridCars?.clearRowSelection();
                this.rowSelected();
                this.loadingCar = false;
                this.dataService.setCarsDataSource();
                this.ref.markForCheck();
            });
    }
}
