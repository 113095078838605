import {ChangeDetectionStrategy, Component} from '@angular/core';
import {UntilDestroy} from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
    selector: 'app-task-calendar',
    templateUrl: './task-calendar.component.html',
    styleUrls: ['./task-calendar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TaskCalendarComponent {
    // TODO
}
