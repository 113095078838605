import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {CustomStorageService} from '@src/app/_services/custom-storage.service';
import {User} from '@src/app/_models/user/user';
import {LogoutService} from '@src/app/_services/logout.service';

@Injectable({
    providedIn: 'root',
})
export class AuthGuard implements CanActivate {
    currentUser: User | null;

    constructor(
        private readonly storage: CustomStorageService,
        private readonly router: Router,
        private readonly logoutService: LogoutService,
    ) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        if (this.storage.getItem('currentUser')) {
            // logged in so return true
            return true;
        }

        // not logged in so redirect to login page with the return url
        console.info('logout by guard');
        this.logoutService.logout();
        void this.router.navigate(['/login'], {queryParams: {returnUrl: state.url}});

        return false;
    }
}
