import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule, Routes} from '@angular/router';
import {AuthGuard} from '@src/app/_guards/auth.guard';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {AcsService} from '@src/app/_api/acs/acs.service';
import {KanbanAllModule} from '@syncfusion/ej2-angular-kanban';
import {VacationComponent} from '@src/app/features/vacation/components/vacation/vacation.component';
import {VacationCalendarComponent} from '@src/app/features/vacation/components/vacation-calendar/vacation-calendar.component';
import {VacationDetailComponent} from '@src/app/features/vacation/components/vacation-detail/vacation-detail.component';
import {VacationAccountantExportFormComponent} from '@src/app/features/vacation/_forms/vacation-accountant-export-form/vacation-accountant-export-form.component';
import {VacationQueueComponent} from '@src/app/features/vacation/components/vacation-queue/vacation-queue.component';
import {VacationService} from '@src/app/features/vacation/vacation.service';
import {SharedFeaturesModule} from '@src/app/shared_features/shared-features.module';
import {SharedModule} from '@src/app/shared_components/shared.module';
import {VacationFormComponent} from './_forms/vacation-form/vacation-form.component';
import {VacationStatusComponent} from './_widgets/vacation-status/vacation-status.component';

const routes: Routes = [
    {
        path: 'vacation',
        children: [
            {
                path: '',
                component: VacationComponent,
                data: {
                    title: 'Dovolená',
                    breadcrumb: [
                        {
                            label: 'Dovolená',
                            url: '',
                        },
                    ],
                },
            },
            {
                path: 'create',
                component: VacationCalendarComponent,
                data: {
                    title: 'Vytvořit žádanku',
                    breadcrumb: [
                        {
                            label: 'Dovolená',
                            url: '/vacation/',
                        },
                        {
                            label: 'Vytvořit žádanku',
                            url: '',
                        },
                    ],
                },
            },
            {
                path: 'detail/:id',
                component: VacationDetailComponent,
                data: {
                    title: 'Detail žádanky',
                    breadcrumb: [
                        {
                            label: 'Dovolená',
                            url: '/vacation/',
                        },
                        {
                            label: 'Detail žádanky',
                            url: '',
                        },
                    ],
                },
            },
        ],
        canActivate: [AuthGuard],
    },
];

@NgModule({
    declarations: [
        VacationAccountantExportFormComponent,
        VacationCalendarComponent,
        VacationComponent,
        VacationDetailComponent,
        VacationFormComponent,
        VacationQueueComponent,
        VacationStatusComponent,
    ],
    imports: [
        BrowserAnimationsModule,
        RouterModule.forChild(routes),
        BrowserModule,
        CommonModule,
        FormsModule,
        KanbanAllModule,
        ReactiveFormsModule,
        SharedFeaturesModule,
        SharedModule,
    ],
    exports: [
        VacationCalendarComponent,
        VacationComponent,
        VacationDetailComponent,
        VacationQueueComponent,
    ],
    providers: [AcsService, VacationService],
})
export class VacationModule {}
