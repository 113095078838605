import {Injectable, Renderer2, RendererFactory2} from '@angular/core';
import {SCRIPT_STORE, Scripts} from '@src/app/_models/_script_store/script.store';

@Injectable({
    providedIn: 'root',
})
export class ScriptService {
    private scripts: Scripts[] = [];

    private readonly renderer: Renderer2;

    constructor(rendererFactory: RendererFactory2) {
        this.renderer = rendererFactory.createRenderer(null, null);
        SCRIPT_STORE.forEach((script: Scripts) => {
            this.scripts[script.name] = {
                loaded: false,
                src: script.src,
            };
        });
    }

    getScripts(): Scripts[] {
        return this.scripts;
    }

    async load(...scripts: string[]): Promise<unknown> {
        const promises: unknown[] = [];

        scripts.forEach(script => promises.push(this.loadScript(script)));

        return Promise.all(promises);
    }

    async loadScript(name: string): Promise<unknown> {
        return new Promise(resolve => {
            if (!this.scripts[name].loaded) {
                const script = this.renderer.createElement('script');

                script.type = 'text/javascript';
                script.src = this.scripts[name].src;

                if (script.readyState) {
                    // IE
                    script.onreadystatechange = (): void => {
                        if (script.readyState === 'loaded' || script.readyState === 'complete') {
                            script.onreadystatechange = null;
                            this.scripts[name].loaded = true;
                            resolve({script: name, loaded: true, status: 'Loaded'});
                        }
                    };
                } else {
                    script.onload = (): void => {
                        this.scripts[name].loaded = true;
                        resolve({script: name, loaded: true, status: 'Loaded'});
                    };
                }

                script.onerror = (): void => {
                    resolve({script: name, loaded: false, status: 'Loaded'});
                };

                document.getElementsByTagName('head')[0].appendChild(script);
            } else {
                resolve({script: name, loaded: true, status: 'Already Loaded'});
            }
        });
    }
}
