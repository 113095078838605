import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule, Routes} from '@angular/router';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ExtendedRenderer, MarkdownModule, MARKED_OPTIONS, MarkedOptions} from 'ngx-markdown';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {AuthGuard} from '@src/app/_guards/auth.guard';
import {SharedFeaturesModule} from '@src/app/shared_features/shared-features.module';
import {WikiComponent} from './components/wiki/wiki.component';
import {WikiDetailComponent} from './components/wiki-detail/wiki-detail.component';
import {WikiCreateComponent} from './components/wiki-create/wiki-create.component';

const routes: Routes = [
    {
        path: 'wiki',
        children: [
            {
                path: '',
                component: WikiComponent,
                data: {
                    title: 'Uživatelská nápověda',
                    breadcrumb: [
                        {
                            label: 'Uživatelská nápověda',
                            url: '',
                        },
                    ],
                },
            },
            {
                path: 'create',
                component: WikiCreateComponent,
                data: {
                    title: 'Vytvořit nápovědu',
                    breadcrumb: [
                        {
                            label: 'Vytvořit nápovědu',
                            url: '',
                        },
                    ],
                },
            },
            {
                path: 'detail/:id',
                component: WikiDetailComponent,
                data: {
                    title: 'Detail uživatelské nápovědy',
                    breadcrumb: [
                        {
                            label: 'Detail uživatelské nápovědy',
                            url: '',
                        },
                    ],
                },
            },
        ],
        canActivate: [AuthGuard],
    },
];

export const markedOptions = (): MarkedOptions => {
    const renderer = new ExtendedRenderer();

    renderer.blockquote = (text: string): string =>
        '<blockquote class="blockquote"><p>' + text + '</p></blockquote>';

    return {
        renderer,
        gfm: true,
        breaks: false,
        pedantic: false,
    };
};

@NgModule({
    declarations: [WikiComponent, WikiCreateComponent, WikiDetailComponent],
    imports: [
        BrowserAnimationsModule,
        RouterModule.forChild(routes),
        BrowserModule,
        CommonModule,
        FormsModule,
        HttpClientModule,
        ReactiveFormsModule,
        SharedFeaturesModule,
        MarkdownModule.forRoot({
            loader: HttpClient,
            markedOptions: {
                provide: MARKED_OPTIONS,
                useFactory: markedOptions,
            },
        }),
    ],
    exports: [WikiComponent, WikiCreateComponent, WikiDetailComponent],
})
export class WikiModule {}
