import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    HostListener,
    Input,
    OnChanges,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {DataService} from '@src/app/_services/data.service';
import {DataNetbaseService} from '@src/app/_services/data-netbase.service';
import {
    HardwareConfigurationInput,
    ServicesService,
} from '@src/app/features/services/services.service';
import {MessageService} from '@src/app/_services/message.service';
import {AuthenticationService} from '@src/app/_services/authentication.service';
import {HardwareConfig} from '@src/app/_models/services/hardware-config';
import {UnitTypes} from '@src/app/_models/services/unit-types';
import {SpeedTypes} from '@src/app/_models/services/speed-types';
import {SpaceTypes} from '@src/app/_models/services/space-types';
import {Hardware} from '@src/app/_models/services/hardware';
import {User} from '@src/app/_models/user/user';
import {DropDownListComponent, FilteringEventArgs} from '@syncfusion/ej2-angular-dropdowns';
import {EmitType} from '@syncfusion/ej2-base';
import {Query} from '@syncfusion/ej2-data';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {Observable} from 'rxjs';
import {DialogComponent} from '@syncfusion/ej2-angular-popups';
import {ComponentCanDeactivate} from '@src/app/_guards/changes.guard';

@UntilDestroy()
@Component({
    selector: 'app-service-hw-config-form',
    templateUrl: './service-hw-config-form.component.html',
    styleUrls: ['./service-hw-config-form.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ServiceHwConfigFormComponent implements OnInit, OnChanges, ComponentCanDeactivate {
    // Dropdowms
    height = '240px';

    fields: object = {text: 'label', value: 'value'};

    unitsSelect: Array<{value: boolean | number | string; label: string}> = [];

    speedsSelect: Array<{value: boolean | number | string; label: string}> = [];

    spacesSelect: Array<{value: boolean | number | string; label: string}> = [];

    hardwareSelect: Array<{value: boolean | number | string; label: string}> = [];

    // Forms
    hardwareConfigForm: FormGroup;

    isDirty = false;

    isChecked = false;

    submited = false;

    // Variables
    currentUser: User | null;

    visible = false;

    // Loaders
    loadingConfig = false;

    @Input() hardwareConfig: HardwareConfig | null = null;

    @Input() isCreate = false;

    @Input() isUpdate = false;

    @Input() isVisible = false;

    @Input() isCopy = false;

    @Output() readonly hardwareConfigFormDialogState = new EventEmitter<boolean>();

    // Dialogs
    @ViewChild('formDialog') formDialogObj: DialogComponent;

    // Dropdowms
    @ViewChild('unit') unitObj: DropDownListComponent;

    @ViewChild('space') spaceObj: DropDownListComponent;

    @ViewChild('speed') speedObj: DropDownListComponent;

    @ViewChild('hardware') hardwareObj: DropDownListComponent;

    constructor(
        private readonly formBuilder: FormBuilder,
        private readonly ref: ChangeDetectorRef,
        private readonly dataService: DataService,
        private readonly dataNetbaseService: DataNetbaseService,
        private readonly servicesService: ServicesService,
        private readonly messages: MessageService,
        private readonly authenticationService: AuthenticationService,
    ) {
        this.currentUser = this.authenticationService.currentUserValue;
    }

    get f(): {[key: string]: AbstractControl} {
        return this.hardwareConfigForm.controls;
    }

    @HostListener('window:beforeunload')
    canDeactivate(): Observable<boolean> | boolean {
        return !this.isDirty;
    }

    ngOnInit(): void {
        this.dataNetbaseService.unitTypesSource.pipe(untilDestroyed(this))
            .subscribe(
                (units: UnitTypes[]) => {
                    this.unitsSelect = [];
                    units.map((unit: UnitTypes) => {
                        if (!unit.deleted_date) {
                            this.unitsSelect = [
                                ...this.unitsSelect,
                                {
                                    value: unit.id,
                                    label: unit.name,
                                },
                            ];
                        }
                    });
                    this.ref.markForCheck();
                },
                error => {
                    console.error(error);
                },
            );

        this.dataNetbaseService.speedTypesSource.pipe(untilDestroyed(this))
            .subscribe(
                (speeds: SpeedTypes[]) => {
                    this.speedsSelect = [];
                    speeds.sort((a, b) => {
                        const aValue = a.speed_until;
                        const bValue = b.speed_until;

                        return aValue > bValue ? -1 : 1;
                    });

                    speeds.map((speed: SpeedTypes) => {
                        if (!speed.deleted_date) {
                            this.speedsSelect = [
                                ...this.speedsSelect,
                                {
                                    value: speed.id,
                                    label: `${speed.speed_until} Mbit - ${
                                        speed.speed_to > 0 ? speed.speed_to : '*'
                                    } Mbit`,
                                },
                            ];
                        }
                    });
                    this.ref.markForCheck();
                },
                error => {
                    console.error(error);
                },
            );

        this.dataNetbaseService.spaceTypesSource.pipe(untilDestroyed(this))
            .subscribe(
                (spaces: SpaceTypes[]) => {
                    this.spacesSelect = [];
                    spaces.map((space: SpaceTypes) => {
                        if (!space.deleted_date) {
                            this.spacesSelect = [
                                ...this.spacesSelect,
                                {
                                    value: space.id,
                                    label: `${space.metric_until} - ${space.metric_to}`,
                                },
                            ];
                        }
                    });
                    this.ref.markForCheck();
                },
                error => {
                    console.error(error);
                },
            );

        this.dataNetbaseService.hardwareSource.pipe(untilDestroyed(this))
            .subscribe(
                (hardwares: Hardware[]) => {
                    this.hardwareSelect = [];
                    hardwares.map((hardware: Hardware) => {
                        if (!hardware.deleted_date) {
                            this.hardwareSelect = [
                                ...this.hardwareSelect,
                                {
                                    value: hardware.id,
                                    label: hardware.name,
                                },
                            ];
                        }
                    });
                    this.ref.markForCheck();
                },
                error => {
                    console.error(error);
                },
            );

        this.hardwareConfigForm = this.formBuilder.group({
            basics: [false, Validators.compose([Validators.required])],
            cabels: [false, Validators.compose([Validators.required])],
            garden: [false, Validators.compose([Validators.required])],
            roaming: [false, Validators.compose([Validators.required])],
            unit_id: [null, Validators.compose([Validators.required])],
            space_id: [null, Validators.compose([Validators.required])],
            hardware_id: [null, Validators.compose([Validators.required])],
            speed_id: [null, Validators.compose([Validators.required])],
        });

        this.hardwareConfigForm.valueChanges.pipe(untilDestroyed(this))
            .subscribe(() => {
                this.isDirty = this.hardwareConfigForm.dirty;
                this.showInputErrors();
            });

        this.dataNetbaseService.setHardwareDataSource();
        this.dataNetbaseService.setUnitTypesDataSource();
        this.dataNetbaseService.setSpaceTypesDataSource();
        this.dataNetbaseService.setSpeedTypesDataSource();
    }

    ngOnChanges(): void {
        if (this.isVisible) {
            if (this.isUpdate && this.hardwareConfig) {
                this.hardwareConfigForm.reset();
                this.formDialogObj.header = `Konfigurace #${this.hardwareConfig.id}`;
                this.fillTheForm();
                this.showInputErrors();
            }

            if (this.isCreate) {
                this.hardwareConfigForm.reset();
                this.fillTheForm();
                this.formDialogObj.header = 'Nová konfigurace';
            }

            if (this.isCopy) {
                this.hardwareConfigForm.reset();
                this.formDialogObj.header = 'Nová konfigurace';
                this.fillTheForm();
                this.showInputErrors();
            }
        }
    }

    changeDialogState(value: boolean): void {
        this.hardwareConfigFormDialogState.emit(value);
    }

    onFilteringUnitTypes: EmitType<FilteringEventArgs> = (e: FilteringEventArgs) => {
        if (e.text === '') {
            e.updateData(this.unitsSelect);
        } else {
            let query: Query = new Query();

            query = e.text !== '' ? query.where('label', 'contains', e.text, true, true) : query;
            e.updateData(this.unitsSelect, query);
        }
    };

    onFilteringSpaceTypes: EmitType<FilteringEventArgs> = (e: FilteringEventArgs) => {
        if (e.text === '') {
            e.updateData(this.spacesSelect);
        } else {
            let query: Query = new Query();

            query = e.text !== '' ? query.where('label', 'contains', e.text, true, true) : query;
            e.updateData(this.spacesSelect, query);
        }
    };

    onFilteringSpeedTypes: EmitType<FilteringEventArgs> = (e: FilteringEventArgs) => {
        if (e.text === '') {
            e.updateData(this.speedsSelect);
        } else {
            let query: Query = new Query();

            query = e.text !== '' ? query.where('label', 'contains', e.text, true, true) : query;
            e.updateData(this.speedsSelect, query);
        }
    };

    onFilteringHardwares: EmitType<FilteringEventArgs> = (e: FilteringEventArgs) => {
        if (e.text === '') {
            e.updateData(this.hardwareSelect);
        } else {
            let query: Query = new Query();

            query = e.text !== '' ? query.where('label', 'contains', e.text, true, true) : query;
            e.updateData(this.hardwareSelect, query);
        }
    };

    fillTheForm(): void {
        if ((this.isUpdate || this.isCopy) && this.hardwareConfig) {
            this.hardwareConfigForm.controls.basics.patchValue(this.hardwareConfig.basics);
            this.hardwareConfigForm.controls.cabels.patchValue(this.hardwareConfig.cabels);
            this.hardwareConfigForm.controls.garden.patchValue(this.hardwareConfig.garden);
            this.hardwareConfigForm.controls.roaming.patchValue(this.hardwareConfig.roaming);
            this.hardwareConfigForm.controls.unit_id.patchValue(this.hardwareConfig.unit_id);
            this.hardwareConfigForm.controls.space_id.patchValue(this.hardwareConfig.space_id);
            this.hardwareConfigForm.controls.hardware_id.patchValue(
                this.hardwareConfig.hardware_id,
            );
            this.hardwareConfigForm.controls.speed_id.patchValue(this.hardwareConfig.speed_id);
        } else {
            this.hardwareConfigForm.controls.basics.patchValue(false);
            this.hardwareConfigForm.controls.cabels.patchValue(false);
            this.hardwareConfigForm.controls.garden.patchValue(false);
            this.hardwareConfigForm.controls.roaming.patchValue(false);
        }
    }

    onSubmit(): void {
        this.submited = true;
        this.isDirty = false;
        this.loadingConfig = true;

        if (this.isCreate || this.isCopy) {
            this.createConfig();
        }

        if (this.isUpdate) {
            this.editConfig();
        }
    }

    createConfig(): void {
        this.submited = true;

        if (this.hardwareConfigForm.invalid) {
            console.error('form is not valid!');

            return;
        }

        this.loadingConfig = true;

        const configData: HardwareConfigurationInput = {
            basics: this.f.basics.value,
            cabels: this.f.cabels.value,
            garden: this.f.garden.value,
            roaming: this.f.roaming.value,
            unit_id: this.f.unit_id.value,
            space_id: this.f.space_id.value,
            hardware_id: this.f.hardware_id.value,
            speed_id: this.f.speed_id.value,
            created_by: this.currentUser?.id,
        };

        this.servicesService
            .addHardwareConfig(configData)
            .pipe(untilDestroyed(this))
            .subscribe(
                (data: HardwareConfig) => {
                    const body = `${data.id} konfigurace přidána do nabídky`;
                    const options = {progressBar: true, timeOut: 5000};

                    this.messages.showSuccess('Konfigurace úspěšně vytvořena', body, options);
                    this.dataNetbaseService.setHardwareConfigDataSource();
                    this.isCreate = false;
                    this.loadingConfig = false;
                    this.ref.markForCheck();
                    this.formDialogObj.hide();
                },
                error => {
                    console.error(error);

                    const body = 'Zkuste to znovu později';
                    const options = {
                        progressBar: true,
                        timeOut: 5000,
                        toastClass: 'red',
                    };

                    this.messages.showError('Chyba při vytváření konfigurace', body, options);
                    this.loadingConfig = false;
                    this.ref.markForCheck();
                },
            );
    }

    editConfig(): void {
        this.submited = true;

        if (this.hardwareConfigForm.invalid) {
            console.error('form is not valid!');

            return;
        }

        this.loadingConfig = true;

        const hardwareConfigData: HardwareConfigurationInput = {
            id: this.hardwareConfig?.id,
            basics: this.f.basics.value,
            cabels: this.f.cabels.value,
            garden: this.f.garden.value,
            roaming: this.f.roaming.value,
            unit_id: this.f.unit_id.value,
            space_id: this.f.space_id.value,
            hardware_id: this.f.hardware_id.value,
            speed_id: this.f.speed_id.value,
            updated_by: this.currentUser?.id,
        };

        this.servicesService
            .updateHardwareConfig(hardwareConfigData)
            ?.pipe(untilDestroyed(this))
            .subscribe(
                (data: HardwareConfig) => {
                    const body = `${data.id} konfigurace upravena`;
                    const options = {progressBar: true, timeOut: 5000};

                    this.messages.showSuccess('Konfigurace úspěšně upravena', body, options);
                    this.dataNetbaseService.setHardwareConfigDataSource();
                    this.isUpdate = false;
                    this.loadingConfig = false;
                    this.ref.markForCheck();
                    this.formDialogObj.hide();
                },
                error => {
                    console.error(error);

                    const body = 'Zkuste to znovu později';
                    const options = {
                        progressBar: true,
                        timeOut: 5000,
                        toastClass: 'red',
                    };

                    this.messages.showError('Chyba při úpravě konfigurace', body, options);
                    this.loadingConfig = false;
                    this.ref.markForCheck();
                },
            );
    }

    showInputErrors(): void {
        this.isChecked = true;
        /* console.log('VALID: ' + this.ticketForm.valid);
        Object.keys(this.ticketForm.controls).forEach(key => {
            const controlErrors: ValidationErrors = this.ticketForm.get(key).errors;
            if (controlErrors != null) {
                Object.keys(controlErrors).forEach(keyError => {
                    console.log('Key control: ' + key + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError]);
                });
            }
        });*/
        this.hardwareConfigForm.markAllAsTouched();
        this.ref.markForCheck();
    }
}
