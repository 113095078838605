<div
  *ngIf="tableItemLoad"
  style="
        display: block !important;
        z-index: 1000000;
        width: 250px;
        height: 250px;
        margin: auto;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    "
>
  <div class="loader">
    <span></span>
    <span></span>
    <span></span>
    <span></span>
  </div>
  <div
    class="lds-circle"
    style="
            margin: auto;
            position: absolute;
            width: 150px;
            height: auto;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        "
  >
    <div></div>
  </div>
</div>
<div class="row d-flex justify-content-center modalWrapper">
  <ejs-dialog
    #confirmationCancel
    [buttons]="buttonsCancel"
    [header]="'Nelze provést akci'"
    [isModal]="true"
    [showCloseIcon]="true"
    [visible]="false"
    width="250px"
  ></ejs-dialog>
  <ejs-dialog
    #confirmationDelete
    [buttons]="buttonsDelete"
    [content]="'Opravdu chcete smazat označenou položku objednávky?'"
    [header]="'Smazat položku objednávky'"
    [isModal]="true"
    [showCloseIcon]="true"
    [visible]="false"
    width="250px"
  ></ejs-dialog>
</div>
<ejs-toolbar id="filterToolbar" overflowMode="Scrollable" scrollStep="500">
  <e-items>
    <e-item
      [cssClass]="'statusFilter'"
      tooltipText="Přednastavené hledání"
      type="input"
    >
      <ng-template #template>
        <ejs-dropdownlist
          #viewFilter
          (change)="onStatusFilterChange($event)"
          [dataSource]="categoryFilterData"
          [width]="500"
          placeholder="Vyberte přednastavený pohled..."
        ></ejs-dropdownlist>
      </ng-template>
    </e-item>
    <e-item type="Separator"></e-item>
    <e-item
      [cssClass]="'fulltextFilter'"
      tooltipText="Text pro vyhledávání"
      type="input"
    >
      <ng-template #template>
        <ejs-textbox
          #searchtext
          [width]="500"
          class="mr-2"
          placeholder="Vyhledat..."
        ></ejs-textbox>
        <button
          (click)="search()"
          class="e-btn e-info btn-p-zero e-small"
        >
          <i class="fa-light fa-lg fa-search mr-1"></i
          ><span class="d-none d-lg-inline">vyhledat</span>
        </button>
        <button
          (click)="cancelBtnClick()"
          class="e-btn e-danger btn-p-zero e-small"
        >
          <i class="fa-light fa-lg fa-times"></i>
        </button>
      </ng-template>
    </e-item>
  </e-items>
</ejs-toolbar>
<div class="control-section">
  <div class="scroll_wrapper1" id="scroll_wrapper1_items">
    <div class="scroll_div" id="scroll_div_items"></div>
  </div>
  <div class="scroll_wrapper2" id="scroll_wrapper2_items">
    <div class="grid_parent" id="grid_parent_items">
      <ejs-grid
        #itemsGrid
        (actionBegin)="actionBegin($event)"
        (actionComplete)="actionComplete($event)"
        (created)="onCreated()"
        (dataBound)="onDataBound()"
        (load)="onLoad()"
        (loadeddata)="setInitialGridFiltering()"
        (rowDataBound)="rowDataBound($event)"
        (rowDeselected)="rowSelected()"
        (rowSelected)="rowSelected()"
        (toolbarClick)="toolbarClick($event)"
        [allowExcelExport]="true"
        [allowFiltering]="true"
        [allowMultiSorting]="true"
        [allowPdfExport]="false"
        [allowSelection]="true"
        [allowSorting]="true"
        [dataSource]="data"
        [editSettings]="editSettings"
        [enableHover]="false"
        [enablePersistence]="false"
        [enableVirtualization]="true"
        [filterSettings]="filterSettings"
        [groupSettings]="groupOptions"
        [pageSettings]="pageOptions"
        [selectionSettings]="selectionSettings"
        [sortSettings]="sortOptions"
        [toolbar]="toolbar"
        allowGrouping="true"
        allowReordering="true"
        allowResizing="true"
        allowTextWrap="true"
        height="100%"
        id="itemsGrid"
        locale="cs"
        rowHeight="38"
        showColumnMenu="true"
        width="100%"
      >
        <e-columns>
          <e-column
            [allowEditing]="false"
            [allowFiltering]="false"
            [allowReordering]="false"
            [allowResizing]="false"
            [allowSorting]="false"
            [autoFit]="true"
            type="checkbox"
          ></e-column>
          <e-column
            [allowFiltering]="false"
            [allowReordering]="true"
            [allowResizing]="false"
            [autoFit]="true"
            [isPrimaryKey]="true"
            [visible]="true"
            field="id"
            headerText="ID"
          >
            <ng-template #template let-data>
              <a
                [routerLink]="[
                                    '/material/detail/',
                                    data.material_id
                                ]"
              >
                {{ data.id }}
              </a>
            </ng-template>
          </e-column>
          <e-column
            [allowEditing]="false"
            [allowFiltering]="true"
            [allowReordering]="false"
            [allowResizing]="false"
            [autoFit]="true"
            [visible]="false"
            field="created_by"
            headerText="Creator ID"
          ></e-column>
          <e-column
            [autoFit]="true"
            [filter]="filterSettings"
            clipMode="EllipsisWithTooltip"
            field="name"
            headerText="Název položky"
          ></e-column>
          <e-column
            [autoFit]="true"
            [filter]="filterSettings"
            clipMode="EllipsisWithTooltip"
            field="status"
            headerText="Stav"
            textAlign="center"
          >
            <ng-template #template let-data>
                            <span
                              *ngIf="data.status === 've frontě'"
                              class="e-badge e-badge-pill e-badge-warning"
                            >ve frontě</span
                            >
              <span
                *ngIf="data.status === 'stornováno'"
                class="e-badge e-badge-pill e-badge-danger"
              >stornováno</span
              >
              <span
                *ngIf="data.status === 'schváleno'"
                class="e-badge e-badge-pill e-badge-success"
              >schváleno</span
              >
              <span
                *ngIf="data.status === 'zamítnuto'"
                class="e-badge e-badge-pill e-badge-danger"
              >zamítnuto</span
              >
            </ng-template>
          </e-column>
          <e-column
            [autoFit]="true"
            [filter]="filterSettings"
            [format]="'N'"
            clipMode="EllipsisWithTooltip"
            field="amount"
            headerText="Množství"
            textAlign="Right"
          >
          </e-column>
          <e-column
            [autoFit]="true"
            [filter]="filterSettings"
            [format]="'#,###.00\',- CZK\''"
            clipMode="EllipsisWithTooltip"
            field="price"
            headerText="Cena"
            textAlign="Right"
          >
          </e-column>
          <e-column
            [autoFit]="true"
            [filter]="filterSettings"
            clipMode="EllipsisWithTooltip"
            field="dph"
            headerText="DPH"
            textAlign="center"
          >
            <ng-template #template let-data>
                            <span
                              *ngIf="data.dph"
                              class="e-badge e-badge-pill e-badge-success"
                            >s DPH</span
                            >
              <span
                *ngIf="!data.dph"
                class="e-badge e-badge-pill e-badge-danger"
              >bez DPH</span
              >
            </ng-template>
          </e-column>
          <e-column
            [autoFit]="true"
            [filter]="filterSettings"
            clipMode="EllipsisWithTooltip"
            field="category"
            headerText="Kategorie materiálu"
          >
          </e-column>
          <e-column
            [autoFit]="true"
            [filter]="filterSettings"
            clipMode="EllipsisWithTooltip"
            field="budget"
            headerText="Rozpočet"
          >
          </e-column>
          <e-column
            [autoFit]="true"
            [filter]="filterSettings"
            clipMode="EllipsisWithTooltip"
            field="department"
            headerText="Oddělení"
          >
          </e-column>
          <e-column
            [autoFit]="true"
            [filter]="filterSettings"
            clipMode="EllipsisWithTooltip"
            field="company"
            headerText="Společnost"
          >
          </e-column>
          <e-column
            [autoFit]="true"
            [filter]="filterSettings"
            clipMode="EllipsisWithTooltip"
            field="creator_str"
            headerText="Vytvořil"
          >
            <ng-template #template let-data>
              <div>
                <img
                  *ngIf="data.creator_img"
                  alt="profilový obrázek"
                  class="img-fluid rounded-circle avatar-xs mr-1"
                  src="assets/img{{ data.creator_img }}"
                />
                <span>{{ data.creator_str }}</span>
              </div>
            </ng-template>
          </e-column>
          <e-column
            [allowEditing]="false"
            [autoFit]="true"
            [filter]="filterDate"
            [format]="dateFormat"
            clipMode="EllipsisWithTooltip"
            editType="datepickeredit"
            field="created_date"
            headerText="Vytvořeno"
            textAlign="Right"
            type="date"
          ></e-column>
          <e-column
            [allowEditing]="false"
            [autoFit]="true"
            [filter]="filterDate"
            [format]="dateFormat"
            clipMode="EllipsisWithTooltip"
            editType="datepickeredit"
            field="updated_date"
            headerText="Naposledy upraveno"
            textAlign="Right"
            type="date"
          ></e-column>
          <e-column
            [allowEditing]="false"
            [autoFit]="true"
            [filter]="filterDate"
            [format]="dateFormat"
            clipMode="EllipsisWithTooltip"
            editType="datepickeredit"
            field="deleted_date"
            headerText="Smazáno"
            textAlign="Right"
            type="date"
          ></e-column>
        </e-columns>
        <e-aggregates>
          <e-aggregate>
            <e-columns>
              <e-column [format]="'N'" field="id" type="Count">
                <ng-template #footerTemplate let-data>
                  Celkem řádků: {{ data.Count }}
                </ng-template
                >
              </e-column>
              <e-column [format]="'N'" field="amount" type="Sum">
                <ng-template #footerTemplate let-data>
                  Celkové množství: {{ data.Sum }}
                </ng-template>
              </e-column>
              <e-column
                [format]="'#,###.00\',- CZK\''"
                field="price"
                type="Sum"
              >
                <ng-template #footerTemplate let-data>
                  Celkeová cena: {{ data.Sum }}
                </ng-template>
              </e-column>
            </e-columns>
          </e-aggregate>
          <e-aggregate>
            <e-columns>
              <e-column
                [format]="'N'"
                field="amount"
                type="Average"
              >
                <ng-template #footerTemplate let-data>
                  Průměrné množství: {{ data.Average }}
                </ng-template>
              </e-column>
              <e-column
                [format]="'#,###.00\',- CZK\''"
                field="price"
                type="Average"
              >
                <ng-template #footerTemplate let-data>
                  Průměrná cena: {{ data.Average }}
                </ng-template>
              </e-column>
            </e-columns>
          </e-aggregate>
          <e-aggregate>
            <e-columns>
              <e-column [format]="'N'" field="amount" type="Max">
                <ng-template #footerTemplate let-data>
                  Maximální množství: {{ data.Max }}
                </ng-template>
              </e-column>
              <e-column
                [format]="'#,###.00\',- CZK\''"
                field="price"
                type="Max"
              >
                <ng-template #footerTemplate let-data>
                  Maximální cena: {{ data.Max }}
                </ng-template>
              </e-column>
            </e-columns>
          </e-aggregate>
        </e-aggregates>
      </ejs-grid>
    </div>
  </div>
</div>
