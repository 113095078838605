import {Injectable} from '@angular/core';
import {Observable, of, Subject} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {MessageService} from '@src/app/_services/message.service';
import {Plugins} from '@src/app/_models/plugins/plugins';
import {catchError, share, shareReplay, tap} from 'rxjs/operators';
import {registerLocaleData} from '@angular/common';
import {ElasticsearchService} from '@src/app/_services/elasticsearch.service';

import moment from 'moment';
import localeCs from '@angular/common/locales/cs';
import {EnvironmentService} from '@src/app/_services/environment.service';

moment.updateLocale('cs', {workingWeekdays: [1, 2, 3, 4, 5]});
registerLocaleData(localeCs);

@Injectable({
    providedIn: 'root',
})
export class PluginsService {
    private readonly pluginUrl: string;

    private readonly resultPluginsSource: Subject<Plugins[]> = new Subject();

    allowedPlugins = this.resultPluginsSource.asObservable();

    constructor(
        private readonly http: HttpClient,
        private readonly messageService: MessageService,
        private readonly elasticService: ElasticsearchService,
        private readonly environmentService: EnvironmentService,
    ) {
        this.pluginUrl = this.environmentService.backendURL + '/api/plugin';
    }

    /**
     * Handle Http operation that failed.
     * Let the app continue.
     * @param operation - name of the operation that failed
     * @param result - optional value to return as the observable result
     */
    private handleError<T>(operation = 'operation', result?: T) {
        return (error: {[key: string]: string}): Observable<T | undefined> => {
            // TODO: send the error to remote logging infrastructure
            console.error(error); // log to console instead

            // TODO: better job of transforming error for user consumption
            this.log(`${operation} failed: ${error.message}`);

            // Let the app keep running by returning an empty result.
            return of(result);
        };
    }

    /** Log a userService message with the MessageService */
    private log(message: string): void {
        this.messageService.addNotification(`User: ${message}`, false);
    }

    getData(): Observable<Plugins[] | undefined> {
        // Cache it once if configs value is false
        return this.http.get<Plugins[]>(this.pluginUrl)
            .pipe(
                tap(() => {
                    this.log('fetched plugins');
                }),
                shareReplay(), // this tells Rx to cache the latest emitted
                catchError(this.handleError<Plugins[]>('getPlugins')),
            );
    }

    getPluginData(plugin: Plugins | number): Observable<Plugins | undefined> {
        const id = typeof plugin === 'number' ? plugin : plugin.id;
        const url = `${this.pluginUrl}/${id}`;

        return this.http.get<Plugins>(url)
            .pipe(
                tap(() => {
                    this.log(`fetched plugin id=${id}`);
                }),
                share(), // this tells Rx to cache the latest emitted
                catchError(this.handleError<Plugins>(`getPlugin id=${id}`)),
            );
    }

    setConnections(): void {
        this.getData()
            .subscribe((plugins: Plugins[]) => {
                console.info('INFO: Mount UP plugins ... done.');
                plugins.forEach((plugin: Plugins, index) => {
                    console.info(`INFO: ${index + 1}. plugin: ${plugin.name} ... detected.`);
                });
                this.resultPluginsSource.next(plugins);
            });
    }

    checkConnections(): void {
        console.info('INFO: start checking plugins states..');
        this.getData()
            .subscribe({
                next: (plugins: Plugins[] | undefined) => {
                    if (plugins) {
                        plugins.forEach((plugin: Plugins, index) => {
                            if (plugin.active) {
                                if (plugin.name === 'elastic') {
                                    this.elasticService.isAvailable()
                                        .subscribe((state: boolean) => {
                                            plugins[index].state = state;
                                            plugins[index].last_check =
                                                moment()
                                                    .format('YYYY-MM-DD HH:mm:ss');
                                        });
                                }
                            }
                        });
                        this.resultPluginsSource.next(plugins);
                    } else {
                        console.info('INFO: any stored plugins for check..');
                    }
                },
                error: error => {
                    console.error(error);
                },
            });
    }
}
