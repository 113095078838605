<!--suppress ALL -->
<div class="row d-flex justify-content-center modalWrapper">
  <app-tvpackages-channels
    (tvChannelsDialogState)="renderTvChannelsDialog = $event"
    [isVisible]="renderTvChannelsDialog"
    [tvPackage]="
            this.highlightedTvPackage ? this.highlightedTvPackage : null
        "
  ></app-tvpackages-channels>
  <app-packages-compare
    (compareDialogState)="
            renderCompareDialog = $event; net = false; tv = false
        "
    [idNetPackage]="
            this.choosenInternetTarif && this.choosenInternetTarif.id
                ? this.choosenInternetTarif.id
                : 0
        "
    [idTvPackage]="
            this.choosenTelevisionPackages
                ? this.choosenTelevisionPackages.id
                : 0
        "
    [isVisible]="renderCompareDialog"
    [netPackages]="
            this.matchedConnectedRuianAddress &&
            this.matchedConnectedRuianAddress.net_packages &&
            this.matchedConnectedRuianAddress.net_packages.length > 0
                ? this.matchedConnectedRuianAddress.net_packages
                : []
        "
    [net]="net"
    [tvPackages]="
            this.choosenTelevisionTarif &&
            this.choosenTelevisionTarif.tv_packages &&
            this.choosenTelevisionTarif.tv_packages.length > 0
                ? this.choosenTelevisionTarif.tv_packages
                : []
        "
    [tv]="tv"
  ></app-packages-compare>
</div>
<div class="row pb-5">
  <div class="col-12 col-md-8 col-lg-9">
    <div class="e-card p-1 z-depth-1">
      <div
        class="e-card-content p-0 scrollbar scrollbar-dusty-grass thin"
        style="height: 600px; overflow-y: auto; overflow-x: hidden"
      >
        <!--
                        <mdb-stepper
                            #stepper
                            (stepChange)="onUpdateLead()"
                            [vertical]="true"
                        >
                            <mdb-step
                                [stepForm]="addressForm"
                                label="Zadejte adresu pomocí našeptávače"
                                name="Zadejte adresu"
                            >
                                <small class="text-muted"
                                    >- TIP 1: všechny uvedené
                                    <strong>ceny jsou včetně DPH.</strong></small
                                ><br />
                                <small class="text-muted"
                                    >- TIP 2: Roční předplatné =
                                    <strong>10%</strong> sleva z původní ceny</small
                                ><br />
                                <small class="text-muted"
                                    >- TIP 3: Pokud budete přidávat služby na již
                                    existující přípojku koncového zákazníka,
                                    <strong
                                        >nejdříve vyberte zákazníka v tabulce
                                        níže</strong
                                    >, seznam se objeví po zadání adresy, a až potom
                                    pokračujte ve formuláři.</small
                                >
                                <form [formGroup]="addressForm">
                                    <div class="col-12 mb-3 p-1">
                                        <ejs-textbox
                                            [ngClass]="
                                                addressFormCtrl.cwAddress.touched &&
                                                addressForm.invalid
                                                    ? 'e-error'
                                                    : addressFormCtrl.cwAddress
                                                          .touched && addressForm.valid
                                                    ? 'e-success'
                                                    : ''
                                            "
                                            autocomplete="smartform-auto-address"
                                            class="smartform-address-whole-address smartform-instance-create-cw"
                                            floatLabelType="Auto"
                                            formControlName="cwAddress"
                                            id="searchAddressFormAddress"
                                            placeholder="* Adresa:"
                                        ></ejs-textbox>
                                        <small
                                            *ngIf="
                                                addressFormCtrl.cwAddress.touched &&
                                                addressFormCtrl.cwAddress.errors &&
                                                addressFormCtrl.cwAddress.errors
                                                    .required
                                            "
                                            class="text-danger"
                                            >Vyberte adresu pomocí našeptávače</small
                                        >
                                    </div>
                                </form>
                                <ng-container *ngIf="addressLoading">
                                    <div class="row">
                                        <div class="col-12 text-center p-2">
                                            <app-loader></app-loader>
                                        </div>
                                    </div>
                                </ng-container>
                                <div class="map">
                                    <google-map>
                                        <map-marker
                                            #markerElem
                                            *ngFor="let marker of markers"
                                            [position]="marker.position"
                                            [label]="marker.label"
                                            [title]="marker.title"
                                            [options]="marker.options"
                                            (mapClick)="
                                                openInfo(markerElem, marker.info)
                                            "
                                        >
                                        </map-marker>

                                        <map-info-window>{{
                                            infoContent
                                        }}</map-info-window>
                                    </google-map>
                                    <button (click)="zoomIn()">Zoom in</button>
                                    <button (click)="zoomOut()">Zoom out</button>
                                    <button (click)="logCenter()">Log center</button>
                                    <button (click)="addMarker()">Add marker</button>
                                </div>
                                <ng-container
                                    *ngIf="!addressLoading && !addressForm.valid"
                                >
                                    <div
                                        class="alert alert-warning p-1"
                                        role="alert"
                                        style="font-size: 14px !important"
                                    >
                                        <i
                                            aria-hidden="true"
                                            class="fa-light fa-lg fa-exclamation-triangle mr-2"
                                        ></i
                                        >adresa není správně vyplněna
                                    </div>
                                </ng-container>
                                <ng-container
                                    *ngIf="
                                        !addressLoading &&
                                        addressForm.valid &&
                                        (matchedConnectedRuianAddress ||
                                            matchedConnectedFieldAddress ||
                                            matchedWirelessAddress)
                                    "
                                >
                                    <ejs-accordion
                                        #firstStepAccr
                                        (created)="setAccAnimation()"
                                        expandMode="Multiple"
                                    >
                                        <e-accordionitems>
                                            <e-accordionitem>
                                                <ng-template #header>
                                                    <div
                                                        [ngClass]="
                                                            addressFormCtrl.cwAddress
                                                                .touched &&
                                                            addressForm.invalid
                                                                ? 'e-error'
                                                                : (addressFormCtrl
                                                                      .cwAddress
                                                                      .touched &&
                                                                      addressForm.valid &&
                                                                      outages &&
                                                                      outages.length ===
                                                                          0) ||
                                                                  !outages
                                                                ? 'e-success'
                                                                : 'e-warning'
                                                        "
                                                    >
                                                        Ohlášené výpadky
                                                        <span
                                                            *ngIf="
                                                                outages &&
                                                                outages.length > 0
                                                            "
                                                            class="ml-2 e-badge e-badge-warning"
                                                            >{{ outages.length }}</span
                                                        >
                                                        <span
                                                            *ngIf="
                                                                (outages &&
                                                                    outages.length ===
                                                                        0) ||
                                                                !outages
                                                            "
                                                            class="ml-2 e-badge e-badge-success"
                                                            >0</span
                                                        >
                                                    </div>
                                                </ng-template>
                                                <ng-template #content>
                                                    <div
                                                        *ngIf="
                                                            outages &&
                                                            outages.length > 0 &&
                                                            addressForm.valid
                                                        "
                                                        class="alert alert-danger p-1"
                                                        role="alert"
                                                        style="
                                                            font-size: 14px !important;
                                                        "
                                                    >
                                                        <i
                                                            aria-hidden="true"
                                                            class="fa-light fa-lg fa-exclamation-triangle mr-2"
                                                        ></i
                                                        >Tato adresa je v seznamu
                                                        <strong
                                                            >plánovaných výpadků</strong
                                                        >.
                                                        <hr class="m-0 p-1" />
                                                        <p
                                                            *ngFor="
                                                                let outage of outages
                                                            "
                                                            class="mb-0"
                                                            style="
                                                                font-size: 11px !important;
                                                            "
                                                        >
                                                            {{ outage.address }}:
                                                            (termín:
                                                            {{
                                                                outage.start_at
                                                                    | dateFormat
                                                                        : 'DD.MM.YYYY HH:mm'
                                                            }}
                                                            -
                                                            {{
                                                                outage.end_at
                                                                    | dateFormat
                                                                        : 'DD.MM.YYYY HH:mm'
                                                            }}) - {{ outage.reason }}
                                                        </p>
                                                    </div>
                                                    <strong
                                                        *ngIf="
                                                            ((outages &&
                                                                outages.length === 0) ||
                                                                !outages) &&
                                                            addressForm.valid
                                                        "
                                                        class="text-success"
                                                        >Na této lokaci nejsou hlášeny
                                                        žádné výpadky služeb</strong
                                                    >
                                                    <strong
                                                        *ngIf="addressForm.invalid"
                                                        class="text-warning"
                                                        >Vyplňte správně adresu</strong
                                                    >
                                                </ng-template>
                                            </e-accordionitem>
                                            <e-accordionitem>
                                                <ng-template #header>
                                                    <div
                                                        [ngClass]="
                                                            addressFormCtrl.cwAddress
                                                                .touched &&
                                                            addressForm.invalid
                                                                ? 'e-error'
                                                                : addressFormCtrl
                                                                      .cwAddress
                                                                      .touched &&
                                                                  addressForm.valid &&
                                                                  !matchedUntrustedAddresses
                                                                ? 'e-success'
                                                                : 'e-warning'
                                                        "
                                                    >
                                                        Známá rizika
                                                        <span
                                                            *ngIf="
                                                                matchedUntrustedAddresses
                                                            "
                                                            class="ml-2 e-badge e-badge-warning"
                                                            >SHODA!</span
                                                        >
                                                        <span
                                                            *ngIf="
                                                                !matchedUntrustedAddresses
                                                            "
                                                            class="ml-2 e-badge e-badge-success"
                                                            >V POŘÁDKU</span
                                                        >
                                                    </div>
                                                </ng-template>
                                                <ng-template #content>
                                                    <div
                                                        *ngIf="
                                                            matchedUntrustedAddresses &&
                                                            addressForm.valid
                                                        "
                                                        class="alert alert-warning p-1"
                                                        role="alert"
                                                        style="
                                                            font-size: 14px !important;
                                                        "
                                                    >
                                                        <i
                                                            aria-hidden="true"
                                                            class="fa-light fa-lg fa-exclamation-triangle mr-2"
                                                        ></i
                                                        >Tato adresa je v seznamu
                                                        <strong>rizikových adres</strong
                                                        >. Postupujte obezřetněji při
                                                        vytváření objedánky v rámci
                                                        solvenci klienta.
                                                        <hr class="m-0 p-1" />
                                                        <p
                                                            class="mb-0"
                                                            style="
                                                                font-size: 11px !important;
                                                            "
                                                        >
                                                            {{
                                                                matchedUntrustedAddresses.descriptive
                                                            }}
                                                        </p>
                                                    </div>
                                                    <strong
                                                        *ngIf="
                                                            !matchedUntrustedAddresses &&
                                                            addressForm.valid
                                                        "
                                                        class="text-success"
                                                        >Na této lokaci nejsou známá
                                                        žádná rizika</strong
                                                    >
                                                    <strong
                                                        *ngIf="addressForm.invalid"
                                                        class="text-warning"
                                                        >Vyplňte správně adresu</strong
                                                    >
                                                </ng-template>
                                            </e-accordionitem>
                                            <e-accordionitem>
                                                <ng-template #header>
                                                    <div
                                                        [ngClass]="
                                                            addressFormCtrl.cwAddress
                                                                .touched &&
                                                            addressForm.invalid
                                                                ? 'e-error'
                                                                : addressFormCtrl
                                                                      .cwAddress
                                                                      .touched &&
                                                                  addressForm.valid &&
                                                                  searchedConnectedFieldAddress &&
                                                                  searchedConnectedFieldAddress.length >
                                                                      0
                                                                ? 'e-success'
                                                                : 'e-warning'
                                                        "
                                                    >
                                                        Možné připojené parcely
                                                        <span
                                                            *ngIf="
                                                                searchedConnectedFieldAddress &&
                                                                searchedConnectedFieldAddress.length >
                                                                    0
                                                            "
                                                            class="ml-2 e-badge e-badge-success"
                                                            >{{
                                                                searchedConnectedFieldAddress.length
                                                            }}</span
                                                        >
                                                        <span
                                                            *ngIf="
                                                                (searchedConnectedFieldAddress &&
                                                                    searchedConnectedFieldAddress.length ===
                                                                        0) ||
                                                                !searchedConnectedFieldAddress
                                                            "
                                                            class="ml-2 e-badge e-badge-warning"
                                                            >0</span
                                                        >
                                                    </div>
                                                </ng-template>
                                                <ng-template #content>
                                                    <div
                                                        *ngIf="
                                                            searchedConnectedFieldAddress &&
                                                            searchedConnectedFieldAddress.length >
                                                                0 &&
                                                            addressForm.valid
                                                        "
                                                        class="e-card products_card"
                                                        style="
                                                            display: block !important;
                                                        "
                                                    >
                                                        <div
                                                            class="e-card-header light-green text-white p-2"
                                                        >
                                                            <div
                                                                class="e-card-header-caption"
                                                            >
                                                                <strong>
                                                                    Připojené parcely /
                                                                    stavby / adresy bez
                                                                    RUIAN
                                                                </strong>
                                                            </div>
                                                        </div>
                                                        <div
                                                            class="e-card-content p-0"
                                                            style="
                                                                max-height: 300px;
                                                                overflow-y: auto;
                                                                font-size: 11px;
                                                            "
                                                        >
                                                            <ul class="list-group">
                                                                <li
                                                                    (click)="
                                                                        chooseAddress(
                                                                            field
                                                                        )
                                                                    "
                                                                    *ngFor="
                                                                        let field of searchedConnectedFieldAddress
                                                                    "
                                                                    [ngClass]="
                                                                        matchedConnectedFieldAddress &&
                                                                        matchedConnectedFieldAddress.id ===
                                                                            field.id
                                                                            ? 'active'
                                                                            : ''
                                                                    "
                                                                    class="list-group-item"
                                                                    style="
                                                                        cursor: pointer;
                                                                    "
                                                                >
                                                                    {{ field.address }}
                                                                    -
                                                                    {{
                                                                        field.technology
                                                                            .name
                                                                    }}
                                                                    /
                                                                    {{
                                                                        field.transfer
                                                                            .name
                                                                    }}
                                                                    ({{
                                                                        field.provider
                                                                            .name
                                                                    }})
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <strong
                                                        *ngIf="
                                                            ((searchedConnectedFieldAddress &&
                                                                searchedConnectedFieldAddress.length ===
                                                                    0) ||
                                                                !searchedConnectedFieldAddress) &&
                                                            addressForm.valid
                                                        "
                                                        class="text-success"
                                                        >Na této lokaci nejsou známé
                                                        žádné parcely</strong
                                                    >
                                                    <strong
                                                        *ngIf="addressForm.invalid"
                                                        class="text-warning"
                                                        >Vyplňte správně adresu</strong
                                                    >
                                                </ng-template>
                                            </e-accordionitem>
                                            <e-accordionitem [expanded]="true">
                                                <ng-template #header>
                                                    <div
                                                        [ngClass]="
                                                            addressFormCtrl.cwAddress
                                                                .touched &&
                                                            addressForm.invalid
                                                                ? 'e-error'
                                                                : addressFormCtrl
                                                                      .cwAddress
                                                                      .touched &&
                                                                  addressForm.valid &&
                                                                  ((clientElements &&
                                                                      clientElements.length >
                                                                          0) ||
                                                                      (serviceElements &&
                                                                          serviceElements.length >
                                                                              0))
                                                                ? 'e-success'
                                                                : 'e-warning'
                                                        "
                                                    >
                                                        Připojení klienti a jejich
                                                        služby
                                                        <span
                                                            *ngIf="
                                                                clientElements &&
                                                                clientElements.length >
                                                                    0
                                                            "
                                                            class="ml-2 e-badge e-badge-success"
                                                            >klienti:
                                                            {{
                                                                clientElements.length
                                                            }}</span
                                                        >
                                                        <span
                                                            *ngIf="
                                                                (clientElements &&
                                                                    clientElements.length ===
                                                                        0) ||
                                                                !clientElements
                                                            "
                                                            class="ml-2 e-badge e-badge-warning"
                                                            >klienti:
                                                            {{
                                                                clientElements.length >
                                                                0
                                                                    ? clientElements.length
                                                                    : 0
                                                            }}</span
                                                        >
                                                        <span
                                                            *ngIf="
                                                                serviceElements &&
                                                                serviceElements.length >
                                                                    0
                                                            "
                                                            class="ml-2 e-badge e-badge-success"
                                                            >služby:
                                                            {{
                                                                serviceElements.length
                                                            }}</span
                                                        >
                                                        <span
                                                            *ngIf="
                                                                (serviceElements &&
                                                                    serviceElements.length ===
                                                                        0) ||
                                                                !serviceElements
                                                            "
                                                            class="ml-2 e-badge e-badge-warning"
                                                            >služby:
                                                            {{
                                                                serviceElements.length >
                                                                0
                                                                    ? serviceElements.length
                                                                    : 0
                                                            }}</span
                                                        >
                                                    </div>
                                                </ng-template>
                                                <ng-template #content>
                                                    <div class="row">
                                                        <div class="col-12 col-md-6">
                                                            <div
                                                                class="e-card products_card"
                                                                style="
                                                                    display: block !important;
                                                                "
                                                            >
                                                                <div
                                                                    class="e-card-header light-green text-white p-2"
                                                                >
                                                                    <div
                                                                        class="e-card-header-caption"
                                                                    >
                                                                        <div
                                                                            class="d-flex justify-content-center"
                                                                        >
                                                                            <strong>
                                                                                Připojení
                                                                                klienti
                                                                            </strong>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div
                                                                    class="e-card-content p-0"
                                                                    style="
                                                                        max-height: 300px;
                                                                        overflow-y: auto;
                                                                        font-size: 11px;
                                                                    "
                                                                >
                                                                    <ng-container
                                                                        *ngIf="
                                                                            clientsLoading
                                                                        "
                                                                    >
                                                                        <div
                                                                            class="row"
                                                                        >
                                                                            <div
                                                                                class="col-12 text-center p-2"
                                                                            >
                                                                                <app-loader></app-loader>
                                                                            </div>
                                                                        </div>
                                                                    </ng-container>
                                                                    <ng-container
                                                                        *ngIf="
                                                                            !clientsLoading
                                                                        "
                                                                    >
                                                                        <ejs-grid
                                                                            #gridClients
                                                                            (actionBegin)="
                                                                                actionClientsBegin(
                                                                                    $event
                                                                                )
                                                                            "
                                                                            (created)="
                                                                                createdClients()
                                                                            "
                                                                            (rowDeselected)="
                                                                                rowSelected()
                                                                            "
                                                                            (rowSelected)="
                                                                                rowSelected()
                                                                            "
                                                                            (rowDataBound)="
                                                                                rowDataBound(
                                                                                    $event
                                                                                )
                                                                            "
                                                                            (dataBound)="
                                                                                dataBound()
                                                                            "
                                                                            [dataSource]="
                                                                                clientElements
                                                                            "
                                                                            [enablePersistence]="
                                                                                false
                                                                            "
                                                                            [pageSettings]="
                                                                                pageSettings
                                                                            "
                                                                            [selectionSettings]="
                                                                                selectionSettings
                                                                            "
                                                                            [sortSettings]="
                                                                                initialClientSort
                                                                            "
                                                                            [toolbar]="
                                                                                toolbar
                                                                            "
                                                                            [allowPaging]="
                                                                                false
                                                                            "
                                                                            allowSorting="true"
                                                                        >
                                                                            <e-columns>
                                                                                <e-column
                                                                                    field="name"
                                                                                    headerText="Jméno"
                                                                                    width="150"
                                                                                ></e-column>
                                                                                <e-column
                                                                                    field="client_number"
                                                                                    [format]="
                                                                                        'N'
                                                                                    "
                                                                                    headerText="Zákaznické číslo"
                                                                                    textAlign="Right"
                                                                                    width="150"
                                                                                ></e-column>
                                                                                <e-column
                                                                                    field="isp_link"
                                                                                    headerText="ISP Link"
                                                                                    textAlign="Right"
                                                                                    width="150"
                                                                                >
                                                                                    <ng-template
                                                                                        #template
                                                                                        let-clientElements
                                                                                    >
                                                                                        <a
                                                                                            href="{{
                                                                                                clientElements.isp_link
                                                                                            }}"
                                                                                            rel="nofollow"
                                                                                            target="_blank"
                                                                                            title="Profil uživatele v ISP Admin"
                                                                                            >ISP
                                                                                            profil</a
                                                                                        >
                                                                                    </ng-template>
                                                                                </e-column>
                                                                            </e-columns>
                                                                        </ejs-grid>
                                                                    </ng-container>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-12 col-md-6">
                                                            <div
                                                                class="e-card products_card"
                                                                style="
                                                                    display: block !important;
                                                                "
                                                            >
                                                                <div
                                                                    class="e-card-header light-green text-white p-2"
                                                                >
                                                                    <div
                                                                        class="e-card-header-caption"
                                                                    >
                                                                        <div
                                                                            class="d-flex justify-content-center"
                                                                        >
                                                                            <strong>
                                                                                Připojené
                                                                                služby
                                                                            </strong>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div
                                                                    class="e-card-content p-0"
                                                                    style="
                                                                        max-height: 300px;
                                                                        overflow-y: auto;
                                                                        font-size: 11px;
                                                                    "
                                                                >
                                                                    <ng-container
                                                                        *ngIf="
                                                                            servicesLoading
                                                                        "
                                                                    >
                                                                        <div
                                                                            class="row"
                                                                        >
                                                                            <div
                                                                                class="col-12 text-center p-2"
                                                                            >
                                                                                <app-loader></app-loader>
                                                                            </div>
                                                                        </div>
                                                                    </ng-container>
                                                                    <ng-container
                                                                        *ngIf="
                                                                            !servicesLoading
                                                                        "
                                                                    >
                                                                        <ejs-grid
                                                                            #gridServices
                                                                            (created)="
                                                                                createdServices()
                                                                            "
                                                                            [dataSource]="
                                                                                serviceElements
                                                                            "
                                                                            [enablePersistence]="
                                                                                false
                                                                            "
                                                                            [pageSettings]="
                                                                                pageSettings
                                                                            "
                                                                            [selectionSettings]="
                                                                                selectionSettings
                                                                            "
                                                                            [sortSettings]="
                                                                                initialServiceSort
                                                                            "
                                                                            [toolbar]="
                                                                                toolbar
                                                                            "
                                                                            allowPaging="true"
                                                                            allowSorting="true"
                                                                        >
                                                                            <e-columns>
                                                                                <e-column
                                                                                    field="name"
                                                                                    headerText="Název služby"
                                                                                    width="150"
                                                                                ></e-column>
                                                                                <e-column
                                                                                    field="price"
                                                                                    headerText="Cena služby"
                                                                                    textAlign="Right"
                                                                                    [format]="
                                                                                        '#,###.00\',- CZK\''
                                                                                    "
                                                                                    width="150"
                                                                                ></e-column>
                                                                            </e-columns>
                                                                            <e-aggregates>
                                                                                <e-aggregate>
                                                                                    <e-columns>
                                                                                        <e-column
                                                                                            field="price"
                                                                                            type="Sum"
                                                                                        >
                                                                                            <ng-template
                                                                                                #footerTemplate
                                                                                                let-serviceElements
                                                                                            >
                                                                                                Celkem:
                                                                                                {{
                                                                                                    serviceElements.Sum
                                                                                                }}</ng-template
                                                                                            >
                                                                                        </e-column>
                                                                                    </e-columns>
                                                                                </e-aggregate>
                                                                                <e-aggregate>
                                                                                    <e-columns>
                                                                                        <e-column
                                                                                            field="price"
                                                                                            type="Average"
                                                                                        >
                                                                                            <ng-template
                                                                                                #footerTemplate
                                                                                                let-serviceElements
                                                                                            >
                                                                                                Průměr:
                                                                                                {{
                                                                                                    serviceElements.Average
                                                                                                }}</ng-template
                                                                                            >
                                                                                        </e-column>
                                                                                    </e-columns>
                                                                                </e-aggregate>
                                                                                <e-aggregate>
                                                                                    <e-columns>
                                                                                        <e-column
                                                                                            field="price"
                                                                                            type="Max"
                                                                                        >
                                                                                            <ng-template
                                                                                                #footerTemplate
                                                                                                let-serviceElements
                                                                                            >
                                                                                                Max:
                                                                                                {{
                                                                                                    serviceElements.Max
                                                                                                }}</ng-template
                                                                                            >
                                                                                        </e-column>
                                                                                    </e-columns>
                                                                                </e-aggregate>
                                                                            </e-aggregates>
                                                                        </ejs-grid>
                                                                    </ng-container>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </ng-template>
                                            </e-accordionitem>
                                            <e-accordionitem>
                                                <ng-template #header>
                                                    <div
                                                        [ngClass]="
                                                            addressFormCtrl.cwAddress
                                                                .touched &&
                                                            addressForm.invalid
                                                                ? 'e-error'
                                                                : addressFormCtrl
                                                                      .cwAddress
                                                                      .touched &&
                                                                  addressForm.valid
                                                                ? 'e-success'
                                                                : 'e-warning'
                                                        "
                                                    >
                                                        Technická specifikace
                                                    </div>
                                                </ng-template>
                                                <ng-template #content>
                                                    <ng-container
                                                        *ngIf="
                                                            !addressLoading &&
                                                            addressForm.valid
                                                        "
                                                    >
                                                        <div
                                                            *ngIf="
                                                                matchedConnectedRuianAddress
                                                            "
                                                            class="d-flex text-center"
                                                        >
                                                            <div
                                                                class="chip"
                                                                title="Technologie přenosu"
                                                            >
                                                                <i
                                                                    [ngClass]="
                                                                        matchedConnectedRuianAddress
                                                                            .transfer
                                                                            .connection_method ===
                                                                        'optical cable'
                                                                            ? 'fa-disc-drive'
                                                                            : matchedConnectedRuianAddress
                                                                                  .transfer
                                                                                  .connection_method ===
                                                                              'metalic cable'
                                                                            ? 'fa-ethernet'
                                                                            : matchedConnectedRuianAddress
                                                                                  .transfer
                                                                                  .connection_method ===
                                                                              'radio'
                                                                            ? 'fa-broadcast-tower'
                                                                            : 'fa-satellite-dish'
                                                                    "
                                                                    aria-hidden="true"
                                                                    class="fa-light fa-lg mr-2"
                                                                ></i>
                                                                {{
                                                                    matchedConnectedRuianAddress
                                                                        .transfer
                                                                        .connection_method
                                                                }}
                                                            </div>
                                                            <div
                                                                class="chip"
                                                                title="Technologie připojení / zařízení"
                                                            >
                                                                <i
                                                                    aria-hidden="true"
                                                                    class="fa-light fa-lg fa-sitemap mr-2"
                                                                ></i>
                                                                {{
                                                                    matchedConnectedRuianAddress
                                                                        .technology.name
                                                                }}
                                                            </div>
                                                            <div
                                                                class="chip"
                                                                title="Kód adresního místa"
                                                            >
                                                                <i
                                                                    aria-hidden="true"
                                                                    class="fa-light fa-lg fa-map-marked-alt mr-2"
                                                                ></i>
                                                                {{
                                                                    matchedConnectedRuianAddress.ruian
                                                                }}
                                                            </div>
                                                            <div
                                                                class="chip"
                                                                title="Budova s číslem popisným"
                                                            >
                                                                <i
                                                                    aria-hidden="true"
                                                                    class="fa-light fa-lg fa-home mr-2"
                                                                ></i>
                                                                Adresa z katastru s ČP
                                                            </div>
                                                        </div>
                                                        <div
                                                            *ngIf="
                                                                !matchedConnectedRuianAddress &&
                                                                matchedConnectedFieldAddress
                                                            "
                                                            class="d-flex text-center"
                                                        >
                                                            <div
                                                                class="chip"
                                                                title="Technologie přenosu"
                                                            >
                                                                <i
                                                                    [ngClass]="
                                                                        matchedConnectedFieldAddress
                                                                            .transfer
                                                                            .connection_method ===
                                                                        'optical cable'
                                                                            ? 'fa-disc-drive'
                                                                            : matchedConnectedFieldAddress
                                                                                  .transfer
                                                                                  .connection_method ===
                                                                              'metalic cable'
                                                                            ? 'fa-ethernet'
                                                                            : matchedConnectedFieldAddress
                                                                                  .transfer
                                                                                  .connection_method ===
                                                                              'radio'
                                                                            ? 'fa-broadcast-tower'
                                                                            : 'fa-satellite-dish'
                                                                    "
                                                                    aria-hidden="true"
                                                                    class="fa-light fa-lg mr-2"
                                                                ></i>
                                                                {{
                                                                    matchedConnectedFieldAddress
                                                                        .transfer
                                                                        .connection_method
                                                                }}
                                                            </div>
                                                            <div
                                                                class="chip"
                                                                title="Technologie připojení / zařízení"
                                                            >
                                                                <i
                                                                    aria-hidden="true"
                                                                    class="fa-light fa-lg fa-sitemap mr-2"
                                                                ></i>
                                                                {{
                                                                    matchedConnectedFieldAddress
                                                                        .technology.name
                                                                }}
                                                            </div>
                                                            <div
                                                                class="chip"
                                                                title="Kód parcely"
                                                            >
                                                                <i
                                                                    aria-hidden="true"
                                                                    class="fa-light fa-lg fa-map-marked-alt mr-2"
                                                                ></i>
                                                                {{
                                                                    matchedConnectedFieldAddress.field_number
                                                                }}
                                                            </div>
                                                            <div
                                                                class="chip"
                                                                title="Parcela bez čísla popisného"
                                                            >
                                                                <i
                                                                    aria-hidden="true"
                                                                    class="fa-light fa-lg fa-construction mr-2"
                                                                ></i>
                                                                Parcela bez ČP
                                                            </div>
                                                        </div>
                                                        <div
                                                            *ngIf="
                                                                !matchedConnectedRuianAddress &&
                                                                !matchedConnectedFieldAddress &&
                                                                matchedWirelessAddress
                                                            "
                                                            class="d-flex text-center"
                                                        >
                                                            <div
                                                                class="chip"
                                                                title="Technologie přenosu"
                                                            >
                                                                <i
                                                                    [ngClass]="
                                                                        matchedWirelessAddress.connection_method ===
                                                                        'optical cable'
                                                                            ? 'fa-disc-drive'
                                                                            : matchedWirelessAddress.connection_method ===
                                                                              'metalic cable'
                                                                            ? 'fa-ethernet'
                                                                            : matchedWirelessAddress.connection_method ===
                                                                              'radio'
                                                                            ? 'fa-broadcast-tower'
                                                                            : 'fa-satellite-dish'
                                                                    "
                                                                    aria-hidden="true"
                                                                    class="fa-light fa-lg mr-2"
                                                                ></i>
                                                                {{
                                                                    matchedWirelessAddress.connection_method
                                                                }}
                                                            </div>
                                                            <div
                                                                class="chip"
                                                                title="Technologie připojení / zařízení"
                                                            >
                                                                <i
                                                                    aria-hidden="true"
                                                                    class="fa-light fa-lg fa-sitemap mr-2"
                                                                ></i>
                                                                {{
                                                                    matchedWirelessAddress.technology
                                                                }}
                                                            </div>
                                                            <div
                                                                *ngIf="ruianCode"
                                                                class="chip"
                                                                title="Kód adresního místa"
                                                            >
                                                                <i
                                                                    aria-hidden="true"
                                                                    class="fa-light fa-lg fa-map-marked-alt mr-2"
                                                                ></i>
                                                                {{
                                                                    matchedWirelessAddress.ruian
                                                                }}
                                                            </div>
                                                            <div
                                                                *ngIf="ruianCode"
                                                                class="chip"
                                                                title="Budova s číslem popisným"
                                                            >
                                                                <i
                                                                    aria-hidden="true"
                                                                    class="fa-light fa-lg fa-home mr-2"
                                                                ></i>
                                                                Adresa z katastru s ČP
                                                            </div>
                                                            <div
                                                                *ngIf="!ruianCode"
                                                                class="chip"
                                                                title="Parcela bez čísla popisného"
                                                            >
                                                                <i
                                                                    aria-hidden="true"
                                                                    class="fa-light fa-lg fa-construction mr-2"
                                                                ></i>
                                                                Parcela bez ČP
                                                            </div>
                                                        </div>
                                                        <ejs-accordion
                                                            *ngIf="
                                                                matchedConnectedRuianAddress
                                                            "
                                                        >
                                                            <e-accordionitems>
                                                                <e-accordionitem>
                                                                    <ng-template
                                                                        #header
                                                                    >
                                                                        <div
                                                                            >Technická
                                                                            poznámka
                                                                        </div>
                                                                    </ng-template>
                                                                    <ng-template
                                                                        #content
                                                                    >
                                                                        <p>
                                                                            {{
                                                                                matchedConnectedRuianAddress.descriptive
                                                                            }}
                                                                        </p>
                                                                    </ng-template>
                                                                </e-accordionitem>
                                                                <e-accordionitem>
                                                                    <ng-template
                                                                        #header
                                                                    >
                                                                        <div
                                                                            >Obchodní
                                                                            poznámka
                                                                        </div>
                                                                    </ng-template>
                                                                    <ng-template
                                                                        #content
                                                                    >
                                                                        <p>
                                                                            {{
                                                                                matchedConnectedRuianAddress.business_note
                                                                            }}
                                                                        </p>
                                                                    </ng-template>
                                                                </e-accordionitem>
                                                                <e-accordionitem>
                                                                    <ng-template
                                                                        #header
                                                                    >
                                                                        <div
                                                                            >Marketingová
                                                                            poznámka
                                                                        </div>
                                                                    </ng-template>
                                                                    <ng-template
                                                                        #content
                                                                    >
                                                                        <p>
                                                                            {{
                                                                                matchedConnectedRuianAddress.marketingnote
                                                                            }}
                                                                        </p>
                                                                    </ng-template>
                                                                </e-accordionitem>
                                                            </e-accordionitems>
                                                        </ejs-accordion>
                                                    </ng-container>
                                                    <strong
                                                        *ngIf="
                                                            !addressLoading &&
                                                            addressForm.invalid
                                                        "
                                                        class="text-warning"
                                                        >Vyplňte správně adresu</strong
                                                    >
                                                </ng-template>
                                            </e-accordionitem>
                                        </e-accordionitems>
                                    </ejs-accordion>
                                </ng-container>
                                <button
                                    (click)="stepper.next()"
                                    [disabled]="addressForm.invalid || addressLoading"
                                    class="e-btn e-primary btn-p-zero e-small"
                                    ejs-button
                                    type="button"
                                >
                                    Pokračovat & uložit
                                </button>
                                <button
                                    (click)="showAddressInputErrors()"
                                    cssClass="e-btn e-warning btn-p-zero e-small"
                                    ejs-button
                                    type="button"
                                >
                                    označ chyby
                                </button>
                                <button
                                    (click)="resetAddressForm(); onUpdateLead()"
                                    cssClass="e-btn e-danger btn-p-zero e-small float-right"
                                    ejs-button
                                    type="button"
                                >
                                    smazat sekci
                                </button>
                            </mdb-step>
                            <mdb-step
                                label="Vyberte tarif z ceníků"
                                name="Vyberte internetový tarif"
                            >
                                <div
                                    *ngIf="
                                        clickedRow &&
                                        clickedRow[0] &&
                                        serviceElements &&
                                        serviceElements.length > 0
                                    "
                                    class="alert alert-warning p-1"
                                    role="alert"
                                    style="font-size: 14px !important"
                                >
                                    Aktuální tarify klienta:
                                    <ul>
                                        <li *ngFor="let service of serviceElements">
                                            {{ service.name }}
                                        </li>
                                    </ul>
                                    <hr class="m-0 p-1" />
                                    Adresa poskytuje tuto přenosovou technologii:<br />
                                    <div
                                        *ngIf="matchedConnectedRuianAddress"
                                        class="chip"
                                        title="Technologie přenosu"
                                    >
                                        <i
                                            [ngClass]="
                                                matchedConnectedRuianAddress.transfer
                                                    .connection_method ===
                                                'optical cable'
                                                    ? 'fa-disc-drive'
                                                    : matchedConnectedRuianAddress
                                                          .transfer
                                                          .connection_method ===
                                                      'metalic cable'
                                                    ? 'fa-ethernet'
                                                    : matchedConnectedRuianAddress
                                                          .transfer
                                                          .connection_method === 'radio'
                                                    ? 'fa-broadcast-tower'
                                                    : 'fa-satellite-dish'
                                            "
                                            aria-hidden="true"
                                            class="fa-light fa-lg mr-2"
                                        ></i>
                                        {{
                                            matchedConnectedRuianAddress.transfer
                                                .connection_method
                                        }}
                                    </div>
                                    <div
                                        *ngIf="matchedConnectedRuianAddress"
                                        class="chip"
                                        title="Technologie připojení / zařízení"
                                    >
                                        <i
                                            aria-hidden="true"
                                            class="fa-light fa-lg fa-sitemap mr-2"
                                        ></i>
                                        {{
                                            matchedConnectedRuianAddress.technology.name
                                        }}
                                    </div>
                                    <div
                                        *ngIf="
                                            matchedConnectedFieldAddress &&
                                            !matchedConnectedRuianAddress
                                        "
                                        class="chip"
                                        title="Technologie přenosu"
                                    >
                                        <i
                                            [ngClass]="
                                                matchedConnectedFieldAddress.transfer
                                                    .connection_method ===
                                                'optical cable'
                                                    ? 'fa-disc-drive'
                                                    : matchedConnectedFieldAddress
                                                          .transfer
                                                          .connection_method ===
                                                      'metalic cable'
                                                    ? 'fa-ethernet'
                                                    : matchedConnectedFieldAddress
                                                          .transfer
                                                          .connection_method === 'radio'
                                                    ? 'fa-broadcast-tower'
                                                    : 'fa-satellite-dish'
                                            "
                                            aria-hidden="true"
                                            class="fa-light fa-lg mr-2"
                                        ></i>
                                        {{
                                            matchedConnectedFieldAddress.transfer
                                                .connection_method
                                        }}
                                    </div>
                                    <div
                                        *ngIf="
                                            matchedConnectedFieldAddress &&
                                            !matchedConnectedRuianAddress
                                        "
                                        class="chip"
                                        title="Technologie připojení / zařízení"
                                    >
                                        <i
                                            aria-hidden="true"
                                            class="fa-light fa-lg fa-sitemap mr-2"
                                        ></i>
                                        {{
                                            matchedConnectedFieldAddress.technology.name
                                        }}
                                    </div>
                                    <div
                                        *ngIf="
                                            matchedWirelessAddress &&
                                            !matchedConnectedRuianAddress &&
                                            !matchedConnectedFieldAddress
                                        "
                                        class="chip"
                                        title="Technologie přenosu"
                                    >
                                        <i
                                            [ngClass]="
                                                matchedWirelessAddress.connection_method ===
                                                'optical cable'
                                                    ? 'fa-disc-drive'
                                                    : matchedWirelessAddress.connection_method ===
                                                      'metalic cable'
                                                    ? 'fa-ethernet'
                                                    : matchedWirelessAddress.connection_method ===
                                                      'radio'
                                                    ? 'fa-broadcast-tower'
                                                    : 'fa-satellite-dish'
                                            "
                                            aria-hidden="true"
                                            class="fa-light fa-lg mr-2"
                                        ></i>
                                        {{ matchedWirelessAddress.connection_method }}
                                    </div>
                                    <div
                                        *ngIf="
                                            matchedWirelessAddress &&
                                            !matchedConnectedRuianAddress &&
                                            !matchedConnectedFieldAddress
                                        "
                                        class="chip"
                                        title="Technologie připojení / zařízení"
                                    >
                                        <i
                                            aria-hidden="true"
                                            class="fa-light fa-lg fa-sitemap mr-2"
                                        ></i>
                                        {{ matchedWirelessAddress.technology }}
                                    </div>
                                    <hr class="m-0 p-1" />
                                    <p class="mb-0" style="font-size: 11px !important">
                                        Zkontrolujte prosím zda aktivní tarif odpovídá
                                        dostupné přenosové technologii, případně pokud
                                        nesedí nabídněte klientovi změnu...
                                    </p>
                                </div>
                                <div
                                    *ngIf="
                                        addressForm.valid &&
                                        matchedConnectedRuianAddress
                                    "
                                    class="d-flex"
                                    style="flex-wrap: wrap"
                                >
                                    <ng-container
                                        *ngFor="
                                            let netPackage of matchedConnectedRuianAddress.net_packages
                                        "
                                    >
                                        <div
                                            class="e-card products_card m-1"
                                            style="display: block !important"
                                        >
                                            <div class="e-card-header light-green">
                                                <div class="e-card-header-caption">
                                                    <div
                                                        class="d-flex justify-content-between e-card-header-title text-white"
                                                    >
                                                        <span>
                                                            <strong>
                                                                <i
                                                                    *ngIf="
                                                                        netPackage
                                                                            .transfer
                                                                            .connection_method ===
                                                                        'optical cable'
                                                                    "
                                                                    aria-hidden="true"
                                                                    class="fa-light fa-lg fa-disc-drive"
                                                                ></i>
                                                                <i
                                                                    *ngIf="
                                                                        netPackage
                                                                            .transfer
                                                                            .connection_method ===
                                                                        'metalic cable'
                                                                    "
                                                                    aria-hidden="true"
                                                                    class="fa-light fa-lg fa-ethernet"
                                                                ></i>
                                                                <i
                                                                    *ngIf="
                                                                        netPackage
                                                                            .transfer
                                                                            .connection_method ===
                                                                        'radio'
                                                                    "
                                                                    aria-hidden="true"
                                                                    class="fa-light fa-lg fa-broadcast-tower"
                                                                ></i>
                                                                <i
                                                                    *ngIf="
                                                                        netPackage
                                                                            .transfer
                                                                            .connection_method ===
                                                                        'DSL'
                                                                    "
                                                                    aria-hidden="true"
                                                                    class="fa-light fa-lg fa-satellite-dish"
                                                                ></i>
                                                            </strong>
                                                        </span>
                                                        <span>
                                                            <strong>
                                                                {{
                                                                    netPackage.transfer
                                                                        .connection_method ===
                                                                    'optical cable'
                                                                        ? 'optické připojení'
                                                                        : netPackage
                                                                              .transfer
                                                                              .connection_method ===
                                                                          'metalic cable'
                                                                        ? 'kabelové připojení'
                                                                        : netPackage
                                                                              .transfer
                                                                              .connection_method ===
                                                                          'radio'
                                                                        ? 'rádiové připojení'
                                                                        : 'xDSL připojení'
                                                                }}
                                                            </strong>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                class="e-card-content text-center pt-2 pb-2 pl-1 pr-1"
                                            >
                                                <h6 class="card-title">
                                                    <strong>
                                                        {{ netPackage.name }}
                                                    </strong>
                                                </h6>
                                                <h6 *ngIf="netPackage.recommended">
                                                    <span
                                                        class="e-badge e-badge-primary"
                                                        >doporučujeme</span
                                                    >
                                                </h6>
                                                <h6 *ngIf="netPackage.excludeOff">
                                                    <span class="e-badge e-badge-danger"
                                                        >nelze instalovat</span
                                                    >
                                                </h6>
                                                <h6 *ngIf="netPackage.extension">
                                                    <span
                                                        class="e-badge deep-purple darken-2 text-white"
                                                        >rozšíření</span
                                                    >
                                                </h6>
                                                <ul
                                                    class="list-group list-group-flush p-0 m-0"
                                                    style="font-size: 10px !important"
                                                >
                                                    <li
                                                        *ngIf="netPackage.provider"
                                                        class="list-group-item p-1 m-0"
                                                    >
                                                        provider:
                                                        {{ netPackage.provider.name }}
                                                    </li>
                                                    <li
                                                        *ngIf="netPackage.aggregation"
                                                        class="list-group-item p-1 m-0"
                                                    >
                                                        agregace:
                                                        {{ netPackage.aggregation }}
                                                    </li>
                                                    <li
                                                        *ngIf="
                                                            netPackage.speed_up ||
                                                            netPackage.speed_down
                                                        "
                                                        class="list-group-item p-1 m-0"
                                                    >
                                                        rychlost up:
                                                        {{
                                                            netPackage.speed_up
                                                                ? netPackage.speed_up
                                                                : 0
                                                        }}
                                                        Mbit/s<br />
                                                        rychlost down:
                                                        {{
                                                            netPackage.speed_down
                                                                ? netPackage.speed_down
                                                                : 0
                                                        }}
                                                        Mbit/s
                                                    </li>
                                                    <li class="list-group-item p-1 m-0">
                                                        sleva:
                                                        {{
                                                            netPackage.discount
                                                                ? netPackage.discount
                                                                      .discount
                                                                : 0
                                                        }}%
                                                    </li>
                                                </ul>
                                                <ng-container
                                                    *ngIf="
                                                        netPackage.net_bonuses &&
                                                        netPackage.net_bonuses.length >
                                                            0
                                                    "
                                                >
                                                    <strong>Bonusy tarifu:</strong>
                                                    <div
                                                        *ngFor="
                                                            let bonus of netPackage.net_bonuses
                                                        "
                                                    >
                                                        <span
                                                            class="text-muted p-1 m-0"
                                                            style="
                                                                font-size: 10px !important;
                                                            "
                                                            >{{ bonus.name }}</span
                                                        >
                                                        <i
                                                            aria-hidden="true"
                                                            class="fa-light fa-lg fa-question-circle"
                                                            style="cursor: pointer"
                                                        ></i>
                                                        <br />
                                                    </div>
                                                    <div class="e-card-separator"></div>
                                                </ng-container>
                                                <ng-container
                                                    *ngIf="
                                                        netPackage.net_places &&
                                                        netPackage.net_places.length > 0
                                                    "
                                                >
                                                    <strong>Oblast pokrytí:</strong>
                                                    <div
                                                        *ngFor="
                                                            let place of netPackage.net_places
                                                        "
                                                    >
                                                        <span
                                                            class="text-muted p-1 m-0"
                                                            style="
                                                                font-size: 10px !important;
                                                            "
                                                            >{{ place.name }}</span
                                                        >
                                                        <br />
                                                    </div>
                                                    <div class="e-card-separator"></div>
                                                </ng-container>
                                                <div
                                                    class="d-flex justify-content-center mt-2 mb-2"
                                                >
                                                    <strong
                                                        >{{
                                                            netPackage.price
                                                                ? (netPackage.price
                                                                  | number: '1.2-2')
                                                                : 0
                                                        }}
                                                        ,- CZK / měsíčně |
                                                        <small class="text-muted"
                                                            >{{
                                                                netPackage.price
                                                                    ? (netPackage.price *
                                                                          12 -
                                                                          (netPackage.price *
                                                                              12 *
                                                                              10) /
                                                                              100
                                                                      | number: '1.2-2')
                                                                    : 0
                                                            }}
                                                            ,- CZK / ročně</small
                                                        ></strong
                                                    ><br />
                                                </div>
                                                <div
                                                    class="d-flex justify-content-center mb-2"
                                                >
                                                    <button
                                                        (click)="
                                                            callwizardService.setNetTarif(
                                                                netPackage
                                                            )
                                                        "
                                                        *ngIf="!choosenInternetTarif"
                                                        [disabled]="
                                                            netPackage.excludeOff
                                                        "
                                                        cssClass="e-btn e-primary btn-p-zero e-small"
                                                        ejs-button
                                                        type="button"
                                                    >
                                                        <i
                                                            class="fa-light fa-cart-plus mr-2"
                                                        ></i
                                                        >objednat
                                                    </button>
                                                    <button
                                                        (click)="compare(); net = true"
                                                        [disabled]="
                                                            netPackage.excludeOff
                                                        "
                                                        cssClass="e-btn e-warning btn-p-zero e-small"
                                                        ejs-button
                                                        type="button"
                                                    >
                                                        <i
                                                            class="fa-light fa-balance-scale-right mr-2"
                                                        ></i
                                                        >porovnat
                                                    </button>
                                                    <button
                                                        (click)="
                                                            callwizardService.unsetNetTarif()
                                                        "
                                                        *ngIf="
                                                            choosenInternetTarif &&
                                                            choosenInternetTarif.id ===
                                                                netPackage.id
                                                        "
                                                        cssClass="e-btn e-danger btn-p-zero e-small"
                                                        ejs-button
                                                        type="button"
                                                    >
                                                        <i
                                                            aria-hidden="true"
                                                            class="fa-light fa-lg fa-times mr-2"
                                                        ></i
                                                        >ZRUŠIT
                                                    </button>
                                                </div>
                                                <ng-container
                                                    *ngIf="
                                                        netPackage.ext_services &&
                                                        netPackage.ext_services.length >
                                                            0
                                                    "
                                                >
                                                    <div class="e-card-separator"></div>
                                                    <strong>Rozšíření tarifu:</strong>
                                                    <table class="w-100">
                                                        <tr
                                                            *ngFor="
                                                                let extService of netPackage.ext_services
                                                            "
                                                        >
                                                            <td class="text-muted">
                                                                <ejs-checkbox
                                                                    (change)="
                                                                        callwizardService.addExtService(
                                                                            extService
                                                                        )
                                                                    "
                                                                    [(ngModel)]="
                                                                        extService.is_selected
                                                                    "
                                                                    [disabled]="
                                                                        !choosenInternetTarif ||
                                                                        choosenInternetTarif.id !==
                                                                            netPackage.id
                                                                    "
                                                                    cssClass="e-primary p-0 m-0"
                                                                    label=""
                                                                ></ejs-checkbox>
                                                            </td>
                                                            <td
                                                                class="text-muted text-left"
                                                            >
                                                                <i
                                                                    (click)="
                                                                        showHint(
                                                                            extService.description
                                                                        )
                                                                    "
                                                                    aria-hidden="true"
                                                                    class="fa-light fa-lg fa-question-circle"
                                                                    style="
                                                                        cursor: pointer;
                                                                    "
                                                                ></i
                                                                >&nbsp;&nbsp;{{
                                                                    extService.name
                                                                }}
                                                            </td>
                                                            <td class="text-right">
                                                                +{{
                                                                    extService.price
                                                                        | number
                                                                            : '1.2-2'
                                                                }},- CZK / měs.
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </ng-container>
                                            </div>
                                        </div>
                                    </ng-container>
                                </div>
                                <div
                                    *ngIf="
                                        addressForm.valid &&
                                        !matchedConnectedRuianAddress &&
                                        matchedConnectedFieldAddress
                                    "
                                    class="d-flex"
                                    style="flex-wrap: wrap"
                                >
                                    <ng-container
                                        *ngFor="
                                            let netPackage of matchedConnectedFieldAddress.net_packages
                                        "
                                    >
                                        <div
                                            class="e-card products_card m-1"
                                            style="display: block !important"
                                        >
                                            <div class="e-card-header light-green">
                                                <div class="e-card-header-caption">
                                                    <div
                                                        class="d-flex justify-content-between e-card-header-title text-white"
                                                    >
                                                        <span>
                                                            <strong>
                                                                <i
                                                                    *ngIf="
                                                                        netPackage
                                                                            .transfer
                                                                            .connection_method ===
                                                                        'optical cable'
                                                                    "
                                                                    aria-hidden="true"
                                                                    class="fa-light fa-lg fa-disc-drive"
                                                                ></i>
                                                                <i
                                                                    *ngIf="
                                                                        netPackage
                                                                            .transfer
                                                                            .connection_method ===
                                                                        'metalic cable'
                                                                    "
                                                                    aria-hidden="true"
                                                                    class="fa-light fa-lg fa-ethernet"
                                                                ></i>
                                                                <i
                                                                    *ngIf="
                                                                        netPackage
                                                                            .transfer
                                                                            .connection_method ===
                                                                        'radio'
                                                                    "
                                                                    aria-hidden="true"
                                                                    class="fa-light fa-lg fa-broadcast-tower"
                                                                ></i>
                                                                <i
                                                                    *ngIf="
                                                                        netPackage
                                                                            .transfer
                                                                            .connection_method ===
                                                                        'DSL'
                                                                    "
                                                                    aria-hidden="true"
                                                                    class="fa-light fa-lg fa-satellite-dish"
                                                                ></i>
                                                            </strong>
                                                        </span>
                                                        <span>
                                                            <strong>
                                                                {{
                                                                    netPackage.transfer
                                                                        .connection_method ===
                                                                    'optical cable'
                                                                        ? 'optické připojení'
                                                                        : netPackage
                                                                              .transfer
                                                                              .connection_method ===
                                                                          'metalic cable'
                                                                        ? 'kabelové připojení'
                                                                        : netPackage
                                                                              .transfer
                                                                              .connection_method ===
                                                                          'radio'
                                                                        ? 'rádiové připojení'
                                                                        : 'xDSL připojení'
                                                                }}
                                                            </strong>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                class="e-card-content text-center pt-2 pb-2 pl-1 pr-1"
                                            >
                                                <h6 class="card-title">
                                                    <strong>
                                                        {{ netPackage.name }}
                                                    </strong>
                                                </h6>
                                                <h6 *ngIf="netPackage.recommended">
                                                    <span
                                                        class="e-badge e-badge-primary"
                                                        >doporučujeme</span
                                                    >
                                                </h6>
                                                <h6 *ngIf="netPackage.excludeOff">
                                                    <span class="e-badge e-badge-danger"
                                                        >nelze instalovat</span
                                                    >
                                                </h6>
                                                <h6 *ngIf="netPackage.extension">
                                                    <span
                                                        class="e-badge deep-purple darken-2 text-white"
                                                        >rozšíření</span
                                                    >
                                                </h6>
                                                <ul
                                                    class="list-group list-group-flush p-0 m-0"
                                                    style="font-size: 10px !important"
                                                >
                                                    <li
                                                        *ngIf="netPackage.provider"
                                                        class="list-group-item p-1 m-0"
                                                    >
                                                        provider:
                                                        {{ netPackage.provider.name }}
                                                    </li>
                                                    <li
                                                        *ngIf="netPackage.aggregation"
                                                        class="list-group-item p-1 m-0"
                                                    >
                                                        agregace:
                                                        {{ netPackage.aggregation }}
                                                    </li>
                                                    <li
                                                        *ngIf="
                                                            netPackage.speed_up ||
                                                            netPackage.speed_down
                                                        "
                                                        class="list-group-item p-1 m-0"
                                                    >
                                                        rychlost up:
                                                        {{
                                                            netPackage.speed_up
                                                                ? netPackage.speed_up
                                                                : 0
                                                        }}
                                                        Mbit/s<br />
                                                        rychlost down:
                                                        {{
                                                            netPackage.speed_down
                                                                ? netPackage.speed_down
                                                                : 0
                                                        }}
                                                        Mbit/s
                                                    </li>
                                                    <li class="list-group-item p-1 m-0">
                                                        sleva:
                                                        {{
                                                            netPackage.discount
                                                                ? netPackage.discount
                                                                      .discount
                                                                : 0
                                                        }}%
                                                    </li>
                                                </ul>
                                                <ng-container
                                                    *ngIf="
                                                        netPackage.net_bonuses &&
                                                        netPackage.net_bonuses.length >
                                                            0
                                                    "
                                                >
                                                    <strong>Bonusy tarifu:</strong>
                                                    <div
                                                        *ngFor="
                                                            let bonus of netPackage.net_bonuses
                                                        "
                                                    >
                                                        <span
                                                            class="text-muted p-1 m-0"
                                                            style="
                                                                font-size: 10px !important;
                                                            "
                                                            >{{ bonus.name }}</span
                                                        >
                                                        <i
                                                            aria-hidden="true"
                                                            class="fa-light fa-lg fa-question-circle"
                                                            style="cursor: pointer"
                                                        ></i>
                                                        <br />
                                                    </div>
                                                    <div class="e-card-separator"></div>
                                                </ng-container>
                                                <ng-container
                                                    *ngIf="
                                                        netPackage.net_places &&
                                                        netPackage.net_places.length > 0
                                                    "
                                                >
                                                    <strong>Oblast pokrytí:</strong>
                                                    <div
                                                        *ngFor="
                                                            let place of netPackage.net_places
                                                        "
                                                    >
                                                        <span
                                                            class="text-muted p-1 m-0"
                                                            style="
                                                                font-size: 10px !important;
                                                            "
                                                            >{{ place.name }}</span
                                                        >
                                                        <br />
                                                    </div>
                                                    <div class="e-card-separator"></div>
                                                </ng-container>
                                                <div
                                                    class="d-flex justify-content-center mt-2 mb-2"
                                                >
                                                    <strong
                                                        >{{
                                                            netPackage.price
                                                                ? (netPackage.price
                                                                  | number: '1.2-2')
                                                                : 0
                                                        }}
                                                        ,- CZK / měsíčně |
                                                        <small class="text-muted"
                                                            >{{
                                                                netPackage.price
                                                                    ? (netPackage.price *
                                                                          12 -
                                                                          (netPackage.price *
                                                                              12 *
                                                                              10) /
                                                                              100
                                                                      | number: '1.2-2')
                                                                    : 0
                                                            }}
                                                            ,- CZK / ročně</small
                                                        ></strong
                                                    ><br />
                                                </div>
                                                <div
                                                    class="d-flex justify-content-center mb-2"
                                                >
                                                    <button
                                                        (click)="
                                                            callwizardService.setNetTarif(
                                                                netPackage
                                                            )
                                                        "
                                                        *ngIf="!choosenInternetTarif"
                                                        [disabled]="
                                                            netPackage.excludeOff
                                                        "
                                                        cssClass="e-btn e-primary btn-p-zero e-small"
                                                        ejs-button
                                                        type="button"
                                                    >
                                                        <i
                                                            class="fa-light fa-cart-plus mr-2"
                                                        ></i
                                                        >objednat
                                                    </button>
                                                    <button
                                                        (click)="compare(); net = true"
                                                        [disabled]="
                                                            netPackage.excludeOff
                                                        "
                                                        cssClass="e-btn e-warning btn-p-zero e-small"
                                                        ejs-button
                                                        type="button"
                                                    >
                                                        <i
                                                            class="fa-light fa-balance-scale-right mr-2"
                                                        ></i
                                                        >porovnat
                                                    </button>
                                                    <button
                                                        (click)="
                                                            callwizardService.unsetNetTarif()
                                                        "
                                                        *ngIf="
                                                            choosenInternetTarif &&
                                                            choosenInternetTarif.id ===
                                                                netPackage.id
                                                        "
                                                        cssClass="e-btn e-danger btn-p-zero e-small"
                                                        ejs-button
                                                        type="button"
                                                    >
                                                        <i
                                                            aria-hidden="true"
                                                            class="fa-light fa-lg fa-times mr-2"
                                                        ></i
                                                        >ZRUŠIT
                                                    </button>
                                                </div>
                                                <ng-container
                                                    *ngIf="
                                                        netPackage.ext_services &&
                                                        netPackage.ext_services.length >
                                                            0
                                                    "
                                                >
                                                    <div class="e-card-separator"></div>
                                                    <strong>Rozšíření tarifu:</strong>
                                                    <table class="w-100">
                                                        <tr
                                                            *ngFor="
                                                                let extService of netPackage.ext_services
                                                            "
                                                        >
                                                            <td class="text-muted">
                                                                <ejs-checkbox
                                                                    (change)="
                                                                        callwizardService.addExtService(
                                                                            extService
                                                                        )
                                                                    "
                                                                    [(ngModel)]="
                                                                        extService.is_selected
                                                                    "
                                                                    [disabled]="
                                                                        !choosenInternetTarif ||
                                                                        choosenInternetTarif.id !==
                                                                            netPackage.id
                                                                    "
                                                                    cssClass="e-primary p-0 m-0"
                                                                    label=""
                                                                ></ejs-checkbox>
                                                            </td>
                                                            <td
                                                                class="text-muted text-left"
                                                            >
                                                                <i
                                                                    (click)="
                                                                        showHint(
                                                                            extService.description
                                                                        )
                                                                    "
                                                                    aria-hidden="true"
                                                                    class="fa-light fa-lg fa-question-circle"
                                                                    style="
                                                                        cursor: pointer;
                                                                    "
                                                                ></i
                                                                >&nbsp;&nbsp;{{
                                                                    extService.name
                                                                }}
                                                            </td>
                                                            <td class="text-right">
                                                                +{{
                                                                    extService.price
                                                                        | number
                                                                            : '1.2-2'
                                                                }},-
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </ng-container>
                                            </div>
                                        </div>
                                    </ng-container>
                                </div>
                                <div
                                    *ngIf="
                                        addressForm.valid &&
                                        !matchedConnectedRuianAddress &&
                                        !matchedConnectedFieldAddress &&
                                        matchedWirelessAddress
                                    "
                                    class="d-flex"
                                    style="flex-wrap: wrap"
                                >
                                    <ng-container
                                        *ngFor="
                                            let netPackage of matchedWirelessAddress.net_packages
                                        "
                                    >
                                        <div
                                            class="e-card products_card m-1"
                                            style="display: block !important"
                                        >
                                            <div
                                                class="e-card-header light-green text-white"
                                            >
                                                <div class="e-card-header-caption">
                                                    <div
                                                        class="d-flex justify-content-between e-card-header-title text-white"
                                                    >
                                                        <span>
                                                            <strong>
                                                                <i
                                                                    *ngIf="
                                                                        netPackage
                                                                            .transfer
                                                                            .connection_method ===
                                                                        'radio'
                                                                    "
                                                                    aria-hidden="true"
                                                                    class="fa-light fa-lg fa-broadcast-tower"
                                                                ></i>
                                                                <i
                                                                    *ngIf="
                                                                        netPackage
                                                                            .transfer
                                                                            .connection_method ===
                                                                        'DSL'
                                                                    "
                                                                    aria-hidden="true"
                                                                    class="fa-light fa-lg fa-satellite-dish"
                                                                ></i>
                                                            </strong>
                                                        </span>
                                                        <span>
                                                            <strong>
                                                                {{
                                                                    netPackage.transfer
                                                                        .connection_method ===
                                                                    'radio'
                                                                        ? 'rádiové připojení'
                                                                        : 'xDSL připojení'
                                                                }}
                                                            </strong>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                class="e-card-content text-center pt-2 pb-2 pl-1 pr-1"
                                            >
                                                <h6 class="card-title">
                                                    <strong>
                                                        {{ netPackage.name }}
                                                    </strong>
                                                </h6>
                                                <h6 *ngIf="netPackage.recommended">
                                                    <span
                                                        class="e-badge e-badge-primary"
                                                        >doporučujeme</span
                                                    >
                                                </h6>
                                                <h6 *ngIf="netPackage.excludeOff">
                                                    <span class="e-badge e-badge-danger"
                                                        >nelze instalovat</span
                                                    >
                                                </h6>
                                                <h6 *ngIf="netPackage.extension">
                                                    <span
                                                        class="e-badge deep-purple darken-2 text-white"
                                                        >rozšíření</span
                                                    >
                                                </h6>
                                                <ul
                                                    class="list-group list-group-flush p-0 m-0"
                                                    style="font-size: 10px !important"
                                                >
                                                    <li
                                                        *ngIf="netPackage.provider"
                                                        class="list-group-item p-1 m-0"
                                                    >
                                                        provider:
                                                        {{ netPackage.provider.name }}
                                                    </li>
                                                    <li
                                                        *ngIf="netPackage.aggregation"
                                                        class="list-group-item p-1 m-0"
                                                    >
                                                        agregace:
                                                        {{ netPackage.aggregation }}
                                                    </li>
                                                    <li
                                                        *ngIf="
                                                            netPackage.speed_up ||
                                                            netPackage.speed_down
                                                        "
                                                        class="list-group-item p-1 m-0"
                                                    >
                                                        rychlost up:
                                                        {{
                                                            netPackage.speed_up
                                                                ? netPackage.speed_up
                                                                : 0
                                                        }}
                                                        Mbit/s<br />
                                                        rychlost down:
                                                        {{
                                                            netPackage.speed_down
                                                                ? netPackage.speed_down
                                                                : 0
                                                        }}
                                                        Mbit/s
                                                    </li>
                                                    <li class="list-group-item p-1 m-0">
                                                        sleva:
                                                        {{
                                                            netPackage.discount
                                                                ? netPackage.discount
                                                                      .discount
                                                                : 0
                                                        }}% z ceny
                                                    </li>
                                                </ul>
                                                <ng-container
                                                    *ngIf="
                                                        netPackage.net_bonuses &&
                                                        netPackage.net_bonuses.length >
                                                            0
                                                    "
                                                >
                                                    <strong>Bonusy tarifu:</strong>
                                                    <div
                                                        *ngFor="
                                                            let bonus of netPackage.net_bonuses
                                                        "
                                                    >
                                                        <span
                                                            class="text-muted p-1 m-0"
                                                            style="
                                                                font-size: 10px !important;
                                                            "
                                                            >{{ bonus.name }}</span
                                                        >
                                                        <i
                                                            aria-hidden="true"
                                                            class="fa-light fa-lg fa-question-circle"
                                                            style="cursor: pointer"
                                                        ></i>
                                                        <br />
                                                    </div>
                                                    <div class="e-card-separator"></div>
                                                </ng-container>
                                                <ng-container
                                                    *ngIf="
                                                        netPackage.net_places &&
                                                        netPackage.net_places.length > 0
                                                    "
                                                >
                                                    <strong>Oblast pokrytí:</strong>
                                                    <div
                                                        *ngFor="
                                                            let place of netPackage.net_places
                                                        "
                                                    >
                                                        <span
                                                            class="text-muted p-1 m-0"
                                                            style="
                                                                font-size: 10px !important;
                                                            "
                                                            >{{ place.name }}</span
                                                        >
                                                        <br />
                                                    </div>
                                                    <div class="e-card-separator"></div>
                                                </ng-container>
                                                <div
                                                    class="d-flex justify-content-center"
                                                >
                                                    <strong
                                                        >{{
                                                            netPackage.price
                                                                ? (netPackage.price
                                                                  | number: '1.2-2')
                                                                : 0
                                                        }}
                                                        ,- CZK / měsíčně |
                                                        <small class="text-muted"
                                                            >{{
                                                                netPackage.price
                                                                    ? (netPackage.price *
                                                                          12 -
                                                                          (netPackage.price *
                                                                              12 *
                                                                              10) /
                                                                              100
                                                                      | number: '1.2-2')
                                                                    : 0
                                                            }}
                                                            ,- CZK / ročně</small
                                                        ></strong
                                                    ><br />
                                                </div>
                                                <div
                                                    class="d-flex justify-content-center"
                                                >
                                                    <button
                                                        (click)="
                                                            callwizardService.setNetTarif(
                                                                netPackage
                                                            )
                                                        "
                                                        *ngIf="!choosenInternetTarif"
                                                        [disabled]="
                                                            netPackage.excludeOff
                                                        "
                                                        cssClass="e-btn e-primary btn-p-zero e-small"
                                                        ejs-button
                                                        type="button"
                                                    >
                                                        <i
                                                            class="fa-light fa-cart-plus mr-2"
                                                        ></i
                                                        >objednat
                                                    </button>
                                                    <button
                                                        (click)="compare(); net = true"
                                                        [disabled]="
                                                            netPackage.excludeOff
                                                        "
                                                        cssClass="e-btn e-warning btn-p-zero e-small"
                                                        ejs-button
                                                        type="button"
                                                    >
                                                        <i
                                                            class="fa-light fa-balance-scale-right mr-2"
                                                        ></i
                                                        >porovnat
                                                    </button>
                                                    <button
                                                        (click)="
                                                            callwizardService.unsetNetTarif()
                                                        "
                                                        *ngIf="
                                                            choosenInternetTarif &&
                                                            choosenInternetTarif.id ===
                                                                netPackage.id
                                                        "
                                                        cssClass="e-btn e-danger btn-p-zero e-small"
                                                        ejs-button
                                                        type="button"
                                                    >
                                                        <i
                                                            aria-hidden="true"
                                                            class="fa-light fa-lg fa-times mr-2"
                                                        ></i
                                                        >ZRUŠIT
                                                    </button>
                                                </div>
                                                <ng-container
                                                    *ngIf="
                                                        netPackage.ext_services &&
                                                        netPackage.ext_services.length >
                                                            0
                                                    "
                                                >
                                                    <div class="e-card-separator"></div>
                                                    <strong>Rozšíření tarifu:</strong>
                                                    <table class="w-100">
                                                        <tr
                                                            *ngFor="
                                                                let extService of netPackage.ext_services
                                                            "
                                                        >
                                                            <td class="text-muted">
                                                                <ejs-checkbox
                                                                    (change)="
                                                                        callwizardService.addExtService(
                                                                            extService
                                                                        )
                                                                    "
                                                                    [(ngModel)]="
                                                                        extService.is_selected
                                                                    "
                                                                    [disabled]="
                                                                        !choosenInternetTarif ||
                                                                        choosenInternetTarif.id !==
                                                                            netPackage.id
                                                                    "
                                                                    cssClass="e-primary p-0 m-0"
                                                                    label=""
                                                                ></ejs-checkbox>
                                                            </td>
                                                            <td
                                                                class="text-muted text-left"
                                                            >
                                                                <i
                                                                    (click)="
                                                                        showHint(
                                                                            extService.description
                                                                        )
                                                                    "
                                                                    aria-hidden="true"
                                                                    class="fa-light fa-lg fa-question-circle"
                                                                    style="
                                                                        cursor: pointer;
                                                                    "
                                                                ></i
                                                                >&nbsp;&nbsp;{{
                                                                    extService.name
                                                                }}
                                                            </td>
                                                            <td class="text-right">
                                                                +{{
                                                                    extService.price
                                                                        | number
                                                                            : '1.2-2'
                                                                }},-
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </ng-container>
                                            </div>
                                        </div>
                                    </ng-container>
                                </div>
                                <div
                                    *ngIf="
                                        addressForm.invalid ||
                                        (!matchedWirelessAddress &&
                                            !matchedConnectedRuianAddress &&
                                            !matchedConnectedFieldAddress)
                                    "
                                >
                                    <span
                                        ><strong class="text-danger"
                                            >Vyplňte správně předchozí krok</strong
                                        ></span
                                    >
                                </div>
                                <button
                                    (click)="stepper.previous()"
                                    cssClass="e-btn e-info e-outline btn-p-zero e-small"
                                    ejs-button
                                    type="button"
                                >
                                    Zpět
                                </button>
                                <button
                                    (click)="stepper.next()"
                                    [disabled]="
                                        (!choosenInternetTarif && !skipNetTarif) ||
                                        addressForm.invalid ||
                                        addressLoading
                                    "
                                    cssClass="e-btn e-primary btn-p-zero e-small"
                                    ejs-button
                                    type="button"
                                >
                                    Pokračovat & uložit
                                </button>
                                <button
                                    (click)="
                                        callwizardService.unsetNetTarif();
                                        onUpdateLead()
                                    "
                                    cssClass="e-btn e-danger btn-p-zero e-small float-right"
                                    ejs-button
                                    type="button"
                                >
                                    smazat sekci
                                </button>
                                <button
                                    (click)="btnNetSkipClick()"
                                    [disabled]="!clickedRow"
                                    cssClass="e-btn e-warning btn-p-zero e-smallfloat-right"
                                    ejs-button
                                    type="button"
                                >
                                    přeskočit tento krok
                                </button>
                            </mdb-step>
                            <mdb-step
                                label="Vyberte tarif z ceníků"
                                name="Vyberte televizní tarif"
                            >
                                <div
                                    *ngIf="
                                        (choosenInternetTarif || skipNetTarif) &&
                                        addressForm.valid &&
                                        matchedConnectedRuianAddress &&
                                        matchedConnectedRuianAddress.tv_services
                                    "
                                    class="d-flex"
                                    style="flex-wrap: wrap"
                                >
                                    <ng-container
                                        *ngFor="
                                            let tvService of matchedConnectedRuianAddress.tv_services
                                        "
                                    >
                                        <div
                                            class="e-card products_card m-1"
                                            style="display: block !important"
                                        >
                                            <div
                                                class="e-card-header deep-purple text-white"
                                            >
                                                <div class="e-card-header-caption">
                                                    <div
                                                        class="d-flex justify-content-between e-card-header-title text-white"
                                                    >
                                                        <span>
                                                            <strong>
                                                                <i
                                                                    *ngIf="
                                                                        tvService
                                                                            .transfer
                                                                            .connection_method ===
                                                                        'optical cable'
                                                                    "
                                                                    aria-hidden="true"
                                                                    class="fa-light fa-lg fa-disc-drive"
                                                                ></i>
                                                                <i
                                                                    *ngIf="
                                                                        tvService
                                                                            .transfer
                                                                            .connection_method ===
                                                                        'metalic cable'
                                                                    "
                                                                    aria-hidden="true"
                                                                    class="fa-light fa-lg fa-ethernet"
                                                                ></i>
                                                                <i
                                                                    *ngIf="
                                                                        tvService
                                                                            .transfer
                                                                            .connection_method ===
                                                                        'radio'
                                                                    "
                                                                    aria-hidden="true"
                                                                    class="fa-light fa-lg fa-broadcast-tower"
                                                                ></i>
                                                                <i
                                                                    *ngIf="
                                                                        tvService
                                                                            .transfer
                                                                            .connection_method ===
                                                                        'DSL'
                                                                    "
                                                                    aria-hidden="true"
                                                                    class="fa-light fa-lg fa-satellite-dish"
                                                                ></i>
                                                            </strong>
                                                        </span>
                                                        <span>
                                                            <strong>
                                                                {{
                                                                    tvService.transfer
                                                                        .connection_method ===
                                                                    'optical cable'
                                                                        ? 'optické připojení'
                                                                        : tvService
                                                                              .transfer
                                                                              .connection_method ===
                                                                          'metalic cable'
                                                                        ? 'kabelové připojení'
                                                                        : tvService
                                                                              .transfer
                                                                              .connection_method ===
                                                                          'radio'
                                                                        ? 'rádiové připojení'
                                                                        : 'xDSL připojení'
                                                                }}
                                                            </strong>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                class="e-card-content text-center pt-2 pb-2 pl-1 pr-1"
                                            >
                                                <h6 class="card-title">
                                                    <strong>
                                                        {{ tvService.name }}
                                                    </strong>
                                                </h6>
                                                <h6 *ngIf="tvService.recommended">
                                                    <span
                                                        class="e-badge e-badge-primary"
                                                        >doporučujeme</span
                                                    >
                                                </h6>
                                                <h6 *ngIf="tvService.extension">
                                                    <span
                                                        class="e-badge deep-purple darken-2 text-white"
                                                        >rozšíření</span
                                                    >
                                                </h6>
                                                <ul
                                                    class="list-group list-group-flush p-0 m-0"
                                                    style="font-size: 10px !important"
                                                >
                                                    <li
                                                        *ngIf="tvService.provider"
                                                        class="list-group-item p-1 m-0"
                                                    >
                                                        provider:
                                                        {{ tvService.provider.name }}
                                                    </li>
                                                    <li
                                                        *ngIf="tvService.tv_packages"
                                                        class="list-group-item p-1 m-0"
                                                    >
                                                        balíčků:
                                                        {{
                                                            tvService.tv_packages.length
                                                        }}
                                                    </li>
                                                    <li class="list-group-item p-1 m-0">
                                                        sleva:
                                                        {{
                                                            tvService.discount
                                                                ? tvService.discount
                                                                      .discount
                                                                : 0
                                                        }}% z ceny
                                                    </li>
                                                </ul>
                                                <ng-container
                                                    *ngIf="
                                                        tvService.tv_bonuses &&
                                                        tvService.tv_bonuses.length > 0
                                                    "
                                                >
                                                    <strong>Bonusy tarifu:</strong>
                                                    <div
                                                        *ngFor="
                                                            let bonus of tvService.tv_bonuses
                                                        "
                                                    >
                                                        <span
                                                            class="text-muted p-1 m-0"
                                                            style="
                                                                font-size: 10px !important;
                                                            "
                                                            >{{ bonus.name }}</span
                                                        >
                                                        <i
                                                            aria-hidden="true"
                                                            class="fa-light fa-lg fa-question-circle"
                                                            style="cursor: pointer"
                                                        ></i>
                                                        <br />
                                                    </div>
                                                    <div class="e-card-separator"></div>
                                                </ng-container>
                                                <ng-container
                                                    *ngIf="
                                                        tvService.tv_places &&
                                                        tvService.tv_places.length > 0
                                                    "
                                                >
                                                    <strong>Oblast pokrytí:</strong>
                                                    <div
                                                        *ngFor="
                                                            let place of tvService.tv_places
                                                        "
                                                    >
                                                        <span
                                                            class="text-muted p-1 m-0"
                                                            style="
                                                                font-size: 10px !important;
                                                            "
                                                            >{{ place.name }}</span
                                                        >
                                                        <br />
                                                    </div>
                                                    <div class="e-card-separator"></div>
                                                </ng-container>
                                                <div
                                                    class="d-flex justify-content-center mt-2 mb-2"
                                                >
                                                    <strong
                                                        >{{
                                                            tvService.pernament_price
                                                                ? (tvService.pernament_price
                                                                  | number: '1.2-2')
                                                                : 0
                                                        }}
                                                        ,- CZK / měsíčně |
                                                        <small class="text-muted"
                                                            >{{
                                                                tvService.pernament_price
                                                                    ? (tvService.pernament_price *
                                                                          12 -
                                                                          (tvService.pernament_price *
                                                                              12 *
                                                                              10) /
                                                                              100
                                                                      | number: '1.2-2')
                                                                    : 0
                                                            }}
                                                            ,- CZK / ročně</small
                                                        ></strong
                                                    >
                                                </div>
                                                <div
                                                    class="d-flex justify-content-center mb-2"
                                                >
                                                    <button
                                                        (click)="
                                                            callwizardService.setTvTarif(
                                                                tvService
                                                            )
                                                        "
                                                        *ngIf="!choosenTelevisionTarif"
                                                        [disabled]="
                                                            tvService.excludeOff
                                                        "
                                                        cssClass="e-btn e-primary btn-p-zero e-small"
                                                        ejs-button
                                                        type="button"
                                                    >
                                                        <i
                                                            class="fa-light fa-cart-plus mr-2"
                                                        ></i
                                                        >objednat
                                                    </button>
                                                    <button
                                                        (click)="
                                                            callwizardService.unsetTvTarif()
                                                        "
                                                        *ngIf="
                                                            choosenTelevisionTarif &&
                                                            choosenTelevisionTarif.id ===
                                                                tvService.id
                                                        "
                                                        cssClass="e-btn e-danger btn-p-zero e-small"
                                                        ejs-button
                                                        type="button"
                                                    >
                                                        <i
                                                            aria-hidden="true"
                                                            class="fa-light fa-lg fa-times mr-2"
                                                        ></i
                                                        >ZRUŠIT
                                                    </button>
                                                </div>
                                                <ng-container
                                                    *ngIf="
                                                        tvService.ext_services &&
                                                        tvService.ext_services.length >
                                                            0
                                                    "
                                                >
                                                    <div class="e-card-separator"></div>
                                                    <strong>Rozšíření tarifu:</strong>
                                                    <table class="w-100">
                                                        <tr
                                                            *ngFor="
                                                                let extService of tvService.ext_services
                                                            "
                                                        >
                                                            <td class="text-muted">
                                                                <ejs-checkbox
                                                                    (change)="
                                                                        callwizardService.addExtService(
                                                                            extService
                                                                        )
                                                                    "
                                                                    [(ngModel)]="
                                                                        extService.is_selected
                                                                    "
                                                                    [disabled]="
                                                                        !choosenTelevisionTarif ||
                                                                        choosenTelevisionTarif.id !==
                                                                            tvService.id
                                                                    "
                                                                    cssClass="e-primary p-0 m-0"
                                                                    label=""
                                                                ></ejs-checkbox>
                                                            </td>
                                                            <td
                                                                class="text-muted text-left"
                                                            >
                                                                <i
                                                                    (click)="
                                                                        showHint(
                                                                            extService.description
                                                                        )
                                                                    "
                                                                    aria-hidden="true"
                                                                    class="fa-light fa-lg fa-question-circle"
                                                                    style="
                                                                        cursor: pointer;
                                                                    "
                                                                ></i
                                                                >&nbsp;&nbsp;{{
                                                                    extService.name
                                                                }}
                                                            </td>
                                                            <td class="text-right">
                                                                +{{
                                                                    extService.price
                                                                        | number
                                                                            : '1.2-2'
                                                                }},-
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </ng-container>
                                            </div>
                                        </div>
                                    </ng-container>
                                </div>
                                <div
                                    *ngIf="
                                        choosenInternetTarif &&
                                        addressForm.valid &&
                                        !matchedConnectedRuianAddress &&
                                        matchedConnectedFieldAddress &&
                                        matchedConnectedFieldAddress.tv_services
                                    "
                                    class="d-flex"
                                    style="flex-wrap: wrap"
                                >
                                    <ng-container
                                        *ngFor="
                                            let tvService of matchedConnectedFieldAddress.tv_services
                                        "
                                    >
                                        <div
                                            class="e-card products_card m-1"
                                            style="display: block !important"
                                        >
                                            <div
                                                class="e-card-header deep-purple text-white"
                                            >
                                                <div class="e-card-header-caption">
                                                    <div
                                                        class="d-flex justify-content-between e-card-header-title text-white"
                                                    >
                                                        <span>
                                                            <strong>
                                                                <i
                                                                    *ngIf="
                                                                        tvService
                                                                            .transfer
                                                                            .connection_method ===
                                                                        'optical cable'
                                                                    "
                                                                    aria-hidden="true"
                                                                    class="fa-light fa-lg fa-disc-drive"
                                                                ></i>
                                                                <i
                                                                    *ngIf="
                                                                        tvService
                                                                            .transfer
                                                                            .connection_method ===
                                                                        'metalic cable'
                                                                    "
                                                                    aria-hidden="true"
                                                                    class="fa-light fa-lg fa-ethernet"
                                                                ></i>
                                                                <i
                                                                    *ngIf="
                                                                        tvService
                                                                            .transfer
                                                                            .connection_method ===
                                                                        'radio'
                                                                    "
                                                                    aria-hidden="true"
                                                                    class="fa-light fa-lg fa-broadcast-tower"
                                                                ></i>
                                                                <i
                                                                    *ngIf="
                                                                        tvService
                                                                            .transfer
                                                                            .connection_method ===
                                                                        'DSL'
                                                                    "
                                                                    aria-hidden="true"
                                                                    class="fa-light fa-lg fa-satellite-dish"
                                                                ></i>
                                                            </strong>
                                                        </span>
                                                        <span>
                                                            <strong>
                                                                {{
                                                                    tvService.transfer
                                                                        .connection_method ===
                                                                    'optical cable'
                                                                        ? 'optické připojení'
                                                                        : tvService
                                                                              .transfer
                                                                              .connection_method ===
                                                                          'metalic cable'
                                                                        ? 'kabelové připojení'
                                                                        : tvService
                                                                              .transfer
                                                                              .connection_method ===
                                                                          'radio'
                                                                        ? 'rádiové připojení'
                                                                        : 'xDSL připojení'
                                                                }}
                                                            </strong>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                class="e-card-content text-center pt-2 pb-2 pl-1 pr-1"
                                            >
                                                <h6 class="card-title">
                                                    <strong>
                                                        {{ tvService.name }}
                                                    </strong>
                                                </h6>
                                                <h6 *ngIf="tvService.recommended">
                                                    <span
                                                        class="e-badge e-badge-primary"
                                                        >doporučujeme</span
                                                    >
                                                </h6>
                                                <h6 *ngIf="tvService.extension">
                                                    <span
                                                        class="e-badge deep-purple darken-2 text-white"
                                                        >rozšíření</span
                                                    >
                                                </h6>
                                                <ul
                                                    class="list-group list-group-flush p-0 m-0"
                                                    style="font-size: 10px !important"
                                                >
                                                    <li
                                                        *ngIf="tvService.provider"
                                                        class="list-group-item p-1 m-0"
                                                    >
                                                        provider:
                                                        {{ tvService.provider.name }}
                                                    </li>
                                                    <li
                                                        *ngIf="tvService.tv_packages"
                                                        class="list-group-item p-1 m-0"
                                                    >
                                                        balíčků:
                                                        {{
                                                            tvService.tv_packages.length
                                                        }}
                                                    </li>
                                                    <li class="list-group-item p-1 m-0">
                                                        sleva:
                                                        {{
                                                            tvService.discount
                                                                ? tvService.discount
                                                                      .discount
                                                                : 0
                                                        }}%
                                                    </li>
                                                </ul>
                                                <ng-container
                                                    *ngIf="
                                                        tvService.tv_bonuses &&
                                                        tvService.tv_bonuses.length > 0
                                                    "
                                                >
                                                    <strong>Bonusy tarifu:</strong>
                                                    <div
                                                        *ngFor="
                                                            let bonus of tvService.tv_bonuses
                                                        "
                                                    >
                                                        <span
                                                            class="text-muted p-1 m-0"
                                                            style="
                                                                font-size: 10px !important;
                                                            "
                                                            >{{ bonus.name }}</span
                                                        >
                                                        <i
                                                            aria-hidden="true"
                                                            class="fa-light fa-lg fa-question-circle"
                                                            style="cursor: pointer"
                                                        ></i>
                                                        <br />
                                                    </div>
                                                    <div class="e-card-separator"></div>
                                                </ng-container>
                                                <ng-container
                                                    *ngIf="
                                                        tvService.tv_places &&
                                                        tvService.tv_places.length > 0
                                                    "
                                                >
                                                    <strong>Oblast pokrytí:</strong>
                                                    <div
                                                        *ngFor="
                                                            let place of tvService.tv_places
                                                        "
                                                    >
                                                        <span
                                                            class="text-muted p-1 m-0"
                                                            style="
                                                                font-size: 10px !important;
                                                            "
                                                            >{{ place.name }}</span
                                                        >
                                                        <br />
                                                    </div>
                                                    <div class="e-card-separator"></div>
                                                </ng-container>
                                                <div
                                                    class="d-flex justify-content-center mt-2 mb-2"
                                                >
                                                    <strong
                                                        >{{
                                                            tvService.pernament_price
                                                                ? (tvService.pernament_price
                                                                  | number: '1.2-2')
                                                                : 0
                                                        }}
                                                        ,- CZK / měsíčně |
                                                        <small class="text-muted"
                                                            >{{
                                                                tvService.pernament_price
                                                                    ? (tvService.pernament_price *
                                                                          12 -
                                                                          (tvService.pernament_price *
                                                                              12 *
                                                                              10) /
                                                                              100
                                                                      | number: '1.2-2')
                                                                    : 0
                                                            }}
                                                            ,- CZK / ročně</small
                                                        ></strong
                                                    >
                                                </div>
                                                <div
                                                    class="d-flex justify-content-center mb-2"
                                                >
                                                    <button
                                                        (click)="
                                                            callwizardService.setTvTarif(
                                                                tvService
                                                            )
                                                        "
                                                        *ngIf="!choosenTelevisionTarif"
                                                        [disabled]="
                                                            tvService.excludeOff
                                                        "
                                                        cssClass="e-btn e-primary btn-p-zero e-small"
                                                        ejs-button
                                                        type="button"
                                                    >
                                                        <i
                                                            class="fa-light fa-cart-plus mr-2"
                                                        ></i
                                                        >objednat
                                                    </button>
                                                    <button
                                                        (click)="
                                                            callwizardService.unsetTvTarif()
                                                        "
                                                        *ngIf="
                                                            choosenTelevisionTarif &&
                                                            choosenTelevisionTarif.id ===
                                                                tvService.id
                                                        "
                                                        cssClass="e-btn e-danger btn-p-zero e-small"
                                                        ejs-button
                                                        type="button"
                                                    >
                                                        <i
                                                            aria-hidden="true"
                                                            class="fa-light fa-lg fa-times mr-2"
                                                        ></i
                                                        >ZRUŠIT
                                                    </button>
                                                </div>
                                                <ng-container
                                                    *ngIf="
                                                        tvService.ext_services &&
                                                        tvService.ext_services.length >
                                                            0
                                                    "
                                                >
                                                    <div class="e-card-separator"></div>
                                                    <strong>Rozšíření tarifu:</strong>
                                                    <table class="w-100">
                                                        <tr
                                                            *ngFor="
                                                                let extService of tvService.ext_services
                                                            "
                                                        >
                                                            <td class="text-muted">
                                                                <ejs-checkbox
                                                                    (change)="
                                                                        callwizardService.addExtService(
                                                                            extService
                                                                        )
                                                                    "
                                                                    [(ngModel)]="
                                                                        extService.is_selected
                                                                    "
                                                                    [disabled]="
                                                                        !choosenTelevisionTarif ||
                                                                        choosenTelevisionTarif.id !==
                                                                            tvService.id
                                                                    "
                                                                    cssClass="e-primary p-0 m-0"
                                                                    label=""
                                                                ></ejs-checkbox>
                                                            </td>
                                                            <td
                                                                class="text-muted text-left"
                                                            >
                                                                <i
                                                                    (click)="
                                                                        showHint(
                                                                            extService.description
                                                                        )
                                                                    "
                                                                    aria-hidden="true"
                                                                    class="fa-light fa-lg fa-question-circle"
                                                                    style="
                                                                        cursor: pointer;
                                                                    "
                                                                ></i
                                                                >&nbsp;&nbsp;{{
                                                                    extService.name
                                                                }}
                                                            </td>
                                                            <td class="text-right">
                                                                +{{
                                                                    extService.price
                                                                        | number
                                                                            : '1.2-2'
                                                                }},-
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </ng-container>
                                            </div>
                                        </div>
                                    </ng-container>
                                </div>
                                <div
                                    *ngIf="
                                        choosenInternetTarif &&
                                        addressForm.valid &&
                                        !matchedConnectedRuianAddress &&
                                        !matchedConnectedFieldAddress &&
                                        matchedWirelessAddress &&
                                        matchedWirelessAddress.tv_services
                                    "
                                    class="d-flex"
                                    style="flex-wrap: wrap"
                                >
                                    <ng-container
                                        *ngFor="
                                            let tvService of matchedWirelessAddress.tv_services
                                        "
                                    >
                                        <div
                                            class="e-card products_card m-1"
                                            style="display: block !important"
                                        >
                                            <div
                                                class="e-card-header deep-purple text-white"
                                            >
                                                <div class="e-card-header-caption">
                                                    <div
                                                        class="d-flex justify-content-between e-card-header-title text-white"
                                                    >
                                                        <span>
                                                            <strong>
                                                                <i
                                                                    *ngIf="
                                                                        tvService
                                                                            .transfer
                                                                            .connection_method ===
                                                                        'optical cable'
                                                                    "
                                                                    aria-hidden="true"
                                                                    class="fa-light fa-lg fa-disc-drive"
                                                                ></i>
                                                                <i
                                                                    *ngIf="
                                                                        tvService
                                                                            .transfer
                                                                            .connection_method ===
                                                                        'metalic cable'
                                                                    "
                                                                    aria-hidden="true"
                                                                    class="fa-light fa-lg fa-ethernet"
                                                                ></i>
                                                                <i
                                                                    *ngIf="
                                                                        tvService
                                                                            .transfer
                                                                            .connection_method ===
                                                                        'radio'
                                                                    "
                                                                    aria-hidden="true"
                                                                    class="fa-light fa-lg fa-broadcast-tower"
                                                                ></i>
                                                                <i
                                                                    *ngIf="
                                                                        tvService
                                                                            .transfer
                                                                            .connection_method ===
                                                                        'DSL'
                                                                    "
                                                                    aria-hidden="true"
                                                                    class="fa-light fa-lg fa-satellite-dish"
                                                                ></i>
                                                            </strong>
                                                        </span>
                                                        <span>
                                                            <strong>
                                                                {{
                                                                    tvService.transfer
                                                                        .connection_method ===
                                                                    'optical cable'
                                                                        ? 'optické připojení'
                                                                        : tvService
                                                                              .transfer
                                                                              .connection_method ===
                                                                          'metalic cable'
                                                                        ? 'kabelové připojení'
                                                                        : tvService
                                                                              .transfer
                                                                              .connection_method ===
                                                                          'radio'
                                                                        ? 'rádiové připojení'
                                                                        : 'xDSL připojení'
                                                                }}
                                                            </strong>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                class="e-card-content text-center pt-2 pb-2 pl-1 pr-1"
                                            >
                                                <h6 class="card-title">
                                                    <strong>
                                                        {{ tvService.name }}
                                                    </strong>
                                                </h6>
                                                <h6 *ngIf="tvService.recommended">
                                                    <span
                                                        class="e-badge e-badge-primary"
                                                        >doporučujeme</span
                                                    >
                                                </h6>
                                                <h6 *ngIf="tvService.extension">
                                                    <span
                                                        class="e-badge deep-purple darken-2 text-white"
                                                        >rozšíření</span
                                                    >
                                                </h6>
                                                <ul
                                                    class="list-group list-group-flush p-0 m-0"
                                                    style="font-size: 10px !important"
                                                >
                                                    <li
                                                        *ngIf="tvService.provider"
                                                        class="list-group-item p-1 m-0"
                                                    >
                                                        provider:
                                                        {{ tvService.provider.name }}
                                                    </li>
                                                    <li
                                                        *ngIf="tvService.tv_packages"
                                                        class="list-group-item p-1 m-0"
                                                    >
                                                        balíčků:
                                                        {{
                                                            tvService.tv_packages.length
                                                        }}
                                                    </li>
                                                    <li class="list-group-item p-1 m-0">
                                                        sleva:
                                                        {{
                                                            tvService.discount
                                                                ? tvService.discount
                                                                      .discount
                                                                : 0
                                                        }}%
                                                    </li>
                                                </ul>
                                                <ng-container
                                                    *ngIf="
                                                        tvService.tv_bonuses &&
                                                        tvService.tv_bonuses.length > 0
                                                    "
                                                >
                                                    <strong>Bonusy tarifu:</strong>
                                                    <div
                                                        *ngFor="
                                                            let bonus of tvService.tv_bonuses
                                                        "
                                                    >
                                                        <span
                                                            class="text-muted p-1 m-0"
                                                            style="
                                                                font-size: 10px !important;
                                                            "
                                                            >{{ bonus.name }}</span
                                                        >
                                                        <i
                                                            aria-hidden="true"
                                                            class="fa-light fa-lg fa-question-circle"
                                                            style="cursor: pointer"
                                                        ></i>
                                                        <br />
                                                    </div>
                                                    <div class="e-card-separator"></div>
                                                </ng-container>
                                                <ng-container
                                                    *ngIf="
                                                        tvService.tv_places &&
                                                        tvService.tv_places.length > 0
                                                    "
                                                >
                                                    <strong>Oblast pokrytí:</strong>
                                                    <div
                                                        *ngFor="
                                                            let place of tvService.tv_places
                                                        "
                                                    >
                                                        <span
                                                            class="text-muted p-1 m-0"
                                                            style="
                                                                font-size: 10px !important;
                                                            "
                                                            >{{ place.name }}</span
                                                        >
                                                        <br />
                                                    </div>
                                                    <div class="e-card-separator"></div>
                                                </ng-container>
                                                <div
                                                    class="d-flex justify-content-center mt-2 mb-2"
                                                >
                                                    <strong
                                                        >{{
                                                            tvService.pernament_price
                                                                ? (tvService.pernament_price
                                                                  | number: '1.2-2')
                                                                : 0
                                                        }}
                                                        ,- CZK / měsíčně |
                                                        <small class="text-muted"
                                                            >{{
                                                                tvService.pernament_price
                                                                    ? (tvService.pernament_price *
                                                                          12 -
                                                                          (tvService.pernament_price *
                                                                              12 *
                                                                              10) /
                                                                              100
                                                                      | number: '1.2-2')
                                                                    : 0
                                                            }}
                                                            ,- CZK / ročně</small
                                                        ></strong
                                                    >
                                                </div>
                                                <div
                                                    class="d-flex justify-content-center mb-2"
                                                >
                                                    <button
                                                        (click)="
                                                            callwizardService.setTvTarif(
                                                                tvService
                                                            )
                                                        "
                                                        *ngIf="!choosenTelevisionTarif"
                                                        [disabled]="
                                                            tvService.excludeOff
                                                        "
                                                        cssClass="e-btn e-primary btn-p-zero e-small"
                                                        ejs-button
                                                        type="button"
                                                    >
                                                        <i
                                                            class="fa-light fa-cart-plus mr-2"
                                                        ></i
                                                        >objednat
                                                    </button>
                                                    <button
                                                        (click)="
                                                            callwizardService.unsetTvTarif()
                                                        "
                                                        *ngIf="
                                                            choosenTelevisionTarif &&
                                                            choosenTelevisionTarif.id ===
                                                                tvService.id
                                                        "
                                                        cssClass="e-btn e-danger btn-p-zero e-small"
                                                        ejs-button
                                                        type="button"
                                                    >
                                                        <i
                                                            aria-hidden="true"
                                                            class="fa-light fa-lg fa-times mr-2"
                                                        ></i
                                                        >ZRUŠIT
                                                    </button>
                                                </div>
                                                <ng-container
                                                    *ngIf="
                                                        tvService.ext_services &&
                                                        tvService.ext_services.length >
                                                            0
                                                    "
                                                >
                                                    <div class="e-card-separator"></div>
                                                    <strong>Rozšíření tarifu:</strong>
                                                    <table class="w-100">
                                                        <tr
                                                            *ngFor="
                                                                let extService of tvService.ext_services
                                                            "
                                                        >
                                                            <td class="text-muted">
                                                                <ejs-checkbox
                                                                    (change)="
                                                                        callwizardService.addExtService(
                                                                            extService
                                                                        )
                                                                    "
                                                                    [(ngModel)]="
                                                                        extService.is_selected
                                                                    "
                                                                    [disabled]="
                                                                        !choosenTelevisionTarif ||
                                                                        choosenTelevisionTarif.id !==
                                                                            tvService.id
                                                                    "
                                                                    cssClass="e-primary p-0 m-0"
                                                                    label=""
                                                                ></ejs-checkbox>
                                                            </td>
                                                            <td
                                                                class="text-muted text-left"
                                                            >
                                                                <i
                                                                    (click)="
                                                                        showHint(
                                                                            extService.description
                                                                        )
                                                                    "
                                                                    aria-hidden="true"
                                                                    class="fa-light fa-lg fa-question-circle"
                                                                    style="
                                                                        cursor: pointer;
                                                                    "
                                                                ></i
                                                                >&nbsp;&nbsp;{{
                                                                    extService.name
                                                                }}
                                                            </td>
                                                            <td class="text-right">
                                                                +{{
                                                                    extService.price
                                                                        | number
                                                                            : '1.2-2'
                                                                }},-
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </ng-container>
                                            </div>
                                        </div>
                                    </ng-container>
                                </div>
                                <div
                                    *ngIf="
                                        choosenInternetTarif &&
                                        addressForm.valid &&
                                        choosenTelevisionTarif
                                    "
                                    class="d-flex"
                                    style="flex-wrap: wrap"
                                >
                                    <div class="col-12">
                                        <hr />
                                        <span
                                            ><strong>Programový balíček:</strong></span
                                        >
                                    </div>
                                    <ng-container
                                        *ngFor="
                                            let tvPackage of choosenTelevisionTarif.tv_packages
                                        "
                                    >
                                        <div
                                            *ngIf="!tvPackage.extension"
                                            class="e-card products_card m-1"
                                            style="display: block !important"
                                        >
                                            <div
                                                class="e-card-header deep-purple lighten-2 text-white p-2"
                                            >
                                                <div class="e-card-header-caption">
                                                    <div
                                                        class="d-flex justify-content-center e-card-header-title text-white"
                                                    >
                                                        <button
                                                            (click)="
                                                                renderTvChannelsDialog = true;
                                                                highlightedTvPackage =
                                                                    tvPackage
                                                            "
                                                            ejs-button
                                                            type="button"
                                                            class="e-btn e-small e-warning btn-p-zero"
                                                        >
                                                            zobrazit programy v balíčku
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                class="e-card-content text-center pt-2 pb-2 pl-1 pr-1"
                                            >
                                                <h6 class="card-title">
                                                    <strong>
                                                        {{ tvPackage.name }}
                                                    </strong>
                                                </h6>
                                                <h6 *ngIf="tvPackage.recommended">
                                                    <span
                                                        class="e-badge e-badge-primary"
                                                        >doporučujeme</span
                                                    >
                                                </h6>
                                                <ul
                                                    class="list-group list-group-flush p-0 m-0"
                                                    style="font-size: 10px !important"
                                                >
                                                    <li
                                                        *ngIf="
                                                            tvPackage.tv_channels &&
                                                            tvPackage.tv_channels
                                                                .length > 0
                                                        "
                                                        class="list-group-item p-1 m-0"
                                                    >
                                                        programy:
                                                        {{
                                                            tvPackage.tv_channels.length
                                                        }}
                                                    </li>
                                                </ul>
                                                <div class="e-card-separator"></div>
                                                <strong
                                                    >{{
                                                        tvPackage.price
                                                            ? (tvPackage.price
                                                              | number: '1.2-2')
                                                            : 0
                                                    }},- CZK / měsíčně |
                                                    <small class="text-muted"
                                                        >{{
                                                            tvPackage.price
                                                                ? (tvPackage.price *
                                                                      12 -
                                                                      (tvPackage.price *
                                                                          12 *
                                                                          10) /
                                                                          100
                                                                  | number: '1.2-2')
                                                                : 0
                                                        }}
                                                        ,- CZK / ročně</small
                                                    ></strong
                                                >
                                            </div>
                                            <div
                                                class="e-card-actions text-center w-100"
                                            >
                                                <button
                                                    (click)="
                                                        callwizardService.setTvPackage(
                                                            tvPackage
                                                        )
                                                    "
                                                    *ngIf="!choosenTelevisionPackages"
                                                    cssClass="e-btn e-primary btn-p-zero e-small"
                                                    ejs-button
                                                    type="button"
                                                >
                                                    <i
                                                        class="fa-light fa-cart-plus mr-2"
                                                    ></i
                                                    >objednat
                                                </button>
                                                <button
                                                    (click)="compare(); tv = true"
                                                    cssClass="e-btn e-warning btn-p-zero e-small"
                                                    ejs-button
                                                    type="button"
                                                >
                                                    <i
                                                        class="fa-light fa-balance-scale-right mr-2"
                                                    ></i
                                                    >porovnat
                                                </button>
                                                <button
                                                    (click)="unsetTvPackage()"
                                                    *ngIf="
                                                        choosenTelevisionPackages &&
                                                        choosenTelevisionPackages.id ===
                                                            tvPackage.id
                                                    "
                                                    cssClass="e-btn e-danger btn-p-zero e-small"
                                                    ejs-button
                                                    type="button"
                                                >
                                                    <i
                                                        aria-hidden="true"
                                                        class="fa-light fa-lg fa-times mr-2"
                                                    ></i
                                                    >ZRUŠIT
                                                </button>
                                            </div>
                                        </div>
                                    </ng-container>
                                    <div class="col-12">
                                        <hr />
                                        <span
                                            ><strong
                                                >Rozšíření programového balíčku:</strong
                                            ></span
                                        >
                                    </div>
                                    <ng-container
                                        *ngFor="
                                            let tvPackage of choosenTelevisionTarif.tv_packages
                                        "
                                    >
                                        <div
                                            *ngIf="tvPackage.extension"
                                            class="e-card products_card m-1"
                                            style="display: block !important"
                                        >
                                            <div
                                                class="e-card-header deep-purple lighten-4 text-white p-2"
                                            >
                                                <div class="e-card-header-caption">
                                                    <div
                                                        class="d-flex justify-content-center e-card-header-title text-white"
                                                    >
                                                        <button
                                                            (click)="
                                                                renderTvChannelsDialog = true;
                                                                highlightedTvPackage =
                                                                    tvPackage
                                                            "
                                                            ejs-button
                                                            type="button"
                                                            class="e-btn e-small e-primary btn-p-zero"
                                                        >
                                                            zobrazit programy v balíčku
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                class="e-card-content text-center pt-2 pb-2 pl-1 pr-1"
                                            >
                                                <h6 class="card-title">
                                                    <strong>
                                                        {{ tvPackage.name }}
                                                    </strong>
                                                </h6>
                                                <h6 *ngIf="tvPackage.recommended">
                                                    <span
                                                        class="e-badge e-badge-primary"
                                                        >doporučujeme</span
                                                    >
                                                </h6>
                                                <h6>
                                                    <span
                                                        class="e-badge deep-purple darken-2 text-white"
                                                        >rozšíření</span
                                                    >
                                                </h6>
                                                <ul
                                                    class="list-group list-group-flush p-0 m-0"
                                                    style="font-size: 10px !important"
                                                >
                                                    <li
                                                        *ngIf="
                                                            tvPackage.tv_channels &&
                                                            tvPackage.tv_channels
                                                                .length > 0
                                                        "
                                                        class="list-group-item p-1 m-0"
                                                    >
                                                        programy:
                                                        {{
                                                            tvPackage.tv_channels.length
                                                        }}
                                                    </li>
                                                </ul>
                                                <div class="e-card-separator"></div>
                                                <strong
                                                    >{{
                                                        tvPackage.price
                                                            ? (tvPackage.price
                                                              | number: '1.2-2')
                                                            : 0
                                                    }},- CZK / měsíčně |
                                                    <small class="text-muted"
                                                        >{{
                                                            tvPackage.price
                                                                ? (tvPackage.price *
                                                                      12 -
                                                                      (tvPackage.price *
                                                                          12 *
                                                                          10) /
                                                                          100
                                                                  | number: '1.2-2')
                                                                : 0
                                                        }}
                                                        ,- CZK / ročně</small
                                                    ></strong
                                                >
                                            </div>
                                            <div
                                                class="e-card-actions text-center w-100"
                                            >
                                                <button
                                                    (click)="
                                                        callwizardService.addTvExtension(
                                                            tvPackage
                                                        )
                                                    "
                                                    *ngIf="
                                                        !choosenTelevisionExtensions ||
                                                        choosenTelevisionExtensions.indexOf(
                                                            tvPackage
                                                        ) < 0
                                                    "
                                                    cssClass="e-btn e-primary btn-p-zero e-small"
                                                    ejs-button
                                                    type="button"
                                                >
                                                    <i
                                                        class="fa-light fa-cart-plus mr-2"
                                                    ></i
                                                    >objednat
                                                </button>
                                                <button
                                                    (click)="
                                                        callwizardService.removeTvExtension(
                                                            tvPackage
                                                        )
                                                    "
                                                    *ngIf="
                                                        choosenTelevisionExtensions &&
                                                        choosenTelevisionExtensions.indexOf(
                                                            tvPackage
                                                        ) >= 0
                                                    "
                                                    cssClass="e-btn e-danger btn-p-zero e-small"
                                                    ejs-button
                                                    type="button"
                                                >
                                                    <i
                                                        aria-hidden="true"
                                                        class="fa-light fa-lg fa-times mr-2"
                                                    ></i
                                                    >ZRUŠIT
                                                </button>
                                            </div>
                                        </div>
                                    </ng-container>
                                    <div class="col-12">
                                        <hr />
                                    </div>
                                    <form [formGroup]="smartTvForm" class="col-12">
                                        <div class="card mb-3" formArrayName="tvs">
                                            <div
                                                class="col-12 card-header p-1 justify-content-between"
                                            >
                                                <span><strong>Televize:</strong></span>
                                                <button
                                                    (click)="addTv()"
                                                    cssClass="e-btn e-success btn-p-zero e-small float-right"
                                                    ejs-button
                                                    type="button"
                                                >
                                                    Přidat TV
                                                </button>
                                            </div>
                                            <div
                                                *ngFor="
                                                    let tv of tvFormGroup.controls;
                                                    let i = index
                                                "
                                                class="col-12 card-body p-2"
                                            >
                                                <div
                                                    [formGroupName]="i"
                                                    class="row p-0 m-0"
                                                >
                                                    <div
                                                        class="form-group col-7 col-md-5 mb-1"
                                                    >
                                                        <ejs-textbox
                                                            [ngClass]="
                                                                (getTvFormGroup(i)
                                                                    .controls['tv']
                                                                    .dirty ||
                                                                    getTvFormGroup(i)
                                                                        .controls['tv']
                                                                        .touched) &&
                                                                getTvFormGroup(i)
                                                                    .controls['tv']
                                                                    .invalid
                                                                    ? 'e-error'
                                                                    : (getTvFormGroup(i)
                                                                          .controls[
                                                                          'tv'
                                                                      ].dirty ||
                                                                          getTvFormGroup(
                                                                              i
                                                                          ).controls[
                                                                              'tv'
                                                                          ].touched) &&
                                                                      getTvFormGroup(i)
                                                                          .controls[
                                                                          'tv'
                                                                      ].valid
                                                                    ? 'e-success'
                                                                    : ''
                                                            "
                                                            autocomplete="on"
                                                            floatLabelType="Auto"
                                                            formControlName="tv"
                                                            id="smartForm-tv-{{ i }}"
                                                            placeholder="Název TV {{
                                                                i + 1
                                                            }}"
                                                        ></ejs-textbox>
                                                        <span
                                                            *ngIf="
                                                                getTvFormGroup(i)
                                                                    .controls['tv']
                                                                    .touched &&
                                                                getTvFormGroup(
                                                                    i
                                                                ).controls[
                                                                    'tv'
                                                                ].hasError('required')
                                                            "
                                                            class="text-danger"
                                                            >Napište značku / model nebo
                                                            popis TV</span
                                                        >
                                                    </div>
                                                    <div
                                                        class="form-group col-3 col-md-5 mb-1"
                                                    >
                                                        <ejs-checkbox
                                                            [checked]="true"
                                                            [disabled]="true"
                                                            cssClass="e-primary mr-2"
                                                            formControlName="smart"
                                                            id="smartForm-smart-{{ i }}"
                                                            label="SmartTV"
                                                        ></ejs-checkbox>
                                                        <ejs-checkbox
                                                            [checked]="false"
                                                            cssClass="e-primary mr-2"
                                                            formControlName="settopbox"
                                                            id="smartForm-settopbox-{{
                                                                i
                                                            }}"
                                                            label="Set-top box"
                                                        ></ejs-checkbox>
                                                        <ejs-checkbox
                                                            [checked]="false"
                                                            cssClass="e-primary mr-2"
                                                            formControlName="settopbox_fee"
                                                            id="smartForm-settopbox_fee-{{
                                                                i
                                                            }}"
                                                            label="pronájem (+99,- CZK / měsíčně)"
                                                        ></ejs-checkbox>
                                                    </div>
                                                    <div
                                                        class="form-group col-2 col-md-2 mb-1 text-right"
                                                    >
                                                        <button
                                                            (click)="removeTv(i)"
                                                            cssClass="e-btn e-danger btn-p-zero e-small"
                                                            ejs-button
                                                            type="button"
                                                        >
                                                            Odebrat TV
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <div
                                    *ngIf="
                                        (!choosenInternetTarif && !skipNetTarif) ||
                                        addressForm.invalid
                                    "
                                >
                                    <span
                                        ><strong class="text-danger"
                                            >Vyplňte správně předchozí krok</strong
                                        ></span
                                    >
                                </div>
                                <button
                                    (click)="stepper.previous()"
                                    cssClass="e-btn e-info e-outline btn-p-zero e-small"
                                    ejs-button
                                    type="button"
                                >
                                    Zpět
                                </button>
                                <button
                                    (click)="stepper.next()"
                                    [disabled]="
                                        (!choosenInternetTarif && !skipNetTarif) ||
                                        !choosenTelevisionTarif ||
                                        addressForm.invalid ||
                                        addressLoading
                                    "
                                    cssClass="e-btn e-primary btn-p-zero e-small"
                                    ejs-button
                                    type="button"
                                >
                                    Pokračovat & uložit
                                </button>
                                <button
                                    (click)="
                                        callwizardService.unsetTvTarif(); onUpdateLead()
                                    "
                                    cssClass="e-btn e-danger btn-p-zero e-small float-right"
                                    ejs-button
                                    type="button"
                                >
                                    smazat sekci
                                </button>
                            </mdb-step>
                            <mdb-step
                                [stepForm]="wifiForm"
                                label="Vyberte wi-fi služby z konfigurátoru"
                                name="Vyberte wi-fi služby"
                            >
                                <form
                                    *ngIf="
                                        choosenInternetTarif &&
                                        addressForm.status !== 'INVALID'
                                    "
                                    [formGroup]="wifiForm"
                                >
                                    <div class="col-12 mb-5">
                                        <ejs-checkbox
                                            [checked]="false"
                                            cssClass="e-primary"
                                            formControlName="own_router"
                                            label="Vlastní router = nemám zájem o Wi-Fi Služby"
                                        ></ejs-checkbox>
                                    </div>
                                    <div class="col-12 mb-3">
                                        <label
                                            [ngClass]="
                                                wifiFormCtrl.unit_id.valid
                                                    ? 'text-success'
                                                    : wifiFormCtrl.unit_id.invalid &&
                                                      (wifiFormCtrl.unit_id.dirty ||
                                                          wifiFormCtrl.unit_id.touched)
                                                    ? 'text-danger'
                                                    : 'text-muted'
                                            "
                                            for="searchAddressForm-unit"
                                            >* Typ bytové jednotky:</label
                                        >
                                        <ejs-dropdownlist
                                            #unit
                                            [allowFiltering]="true"
                                            [dataSource]="unitsSelect"
                                            [fields]="fields"
                                            [filterBarPlaceholder]="
                                                'Vyhledat typ bytové jednotk...'
                                            "
                                            [ignoreAccent]="true"
                                            [ngClass]="
                                                (wifiFormCtrl.unit_id.dirty ||
                                                    wifiFormCtrl.unit_id.touched) &&
                                                wifiFormCtrl.unit_id.invalid
                                                    ? 'e-error'
                                                    : (wifiFormCtrl.unit_id.dirty ||
                                                          wifiFormCtrl.unit_id
                                                              .touched) &&
                                                      wifiFormCtrl.unit_id.valid
                                                    ? 'e-success'
                                                    : ''
                                            "
                                            [placeholder]="
                                                'Vyberte typ bytové jednotky'
                                            "
                                            [popupHeight]="'240px'"
                                            formControlName="unit_id"
                                            id="searchAddressForm-unit"
                                            sortOrder="Ascending"
                                        ></ejs-dropdownlist>
                                        <small
                                            *ngIf="
                                                (wifiFormCtrl.unit_id.dirty ||
                                                    wifiFormCtrl.unit_id.touched) &&
                                                wifiFormCtrl.unit_id.errors &&
                                                wifiFormCtrl.unit_id.errors.required
                                            "
                                            class="text-danger"
                                            >Vyberte typ bytové jednotky</small
                                        >
                                    </div>
                                    <div class="col-12 mb-3">
                                        <label
                                            [ngClass]="
                                                wifiFormCtrl.space_id.valid
                                                    ? 'text-success'
                                                    : wifiFormCtrl.space_id.invalid &&
                                                      (wifiFormCtrl.space_id.dirty ||
                                                          wifiFormCtrl.space_id.touched)
                                                    ? 'text-danger'
                                                    : 'text-muted'
                                            "
                                            for="searchAddressForm-space"
                                            >* Prostor bytové jednotky:</label
                                        >
                                        <ejs-dropdownlist
                                            #space
                                            [allowFiltering]="true"
                                            [dataSource]="spacesSelect"
                                            [disabled]="
                                                !wifiFormCtrl.unit_id.value ||
                                                wifiFormCtrl.unit_id.invalid
                                            "
                                            [fields]="fields"
                                            [filterBarPlaceholder]="
                                                'Vyhledat prostor bytové jednotk...'
                                            "
                                            [ignoreAccent]="true"
                                            [ngClass]="
                                                (wifiFormCtrl.space_id.dirty ||
                                                    wifiFormCtrl.space_id.touched) &&
                                                wifiFormCtrl.space_id.invalid
                                                    ? 'e-error'
                                                    : (wifiFormCtrl.space_id.dirty ||
                                                          wifiFormCtrl.space_id
                                                              .touched) &&
                                                      wifiFormCtrl.space_id.valid
                                                    ? 'e-success'
                                                    : ''
                                            "
                                            [placeholder]="
                                                'Vyberte prostor bytové jednotky'
                                            "
                                            [popupHeight]="'240px'"
                                            formControlName="space_id"
                                            id="searchAddressForm-space"
                                            sortOrder="Ascending"
                                        ></ejs-dropdownlist>
                                        <small
                                            *ngIf="
                                                (wifiFormCtrl.space_id.dirty ||
                                                    wifiFormCtrl.space_id.touched) &&
                                                wifiFormCtrl.space_id.errors &&
                                                wifiFormCtrl.space_id.errors.required
                                            "
                                            class="text-danger"
                                            >Vyberte prostor bytové jednotky</small
                                        >
                                    </div>
                                    <div class="col-12 mb-3">
                                        <label
                                            [ngClass]="
                                                wifiFormCtrl.speed_id.valid
                                                    ? 'text-success'
                                                    : wifiFormCtrl.speed_id.invalid &&
                                                      (wifiFormCtrl.speed_id.dirty ||
                                                          wifiFormCtrl.speed_id.touched)
                                                    ? 'text-danger'
                                                    : 'text-muted'
                                            "
                                            for="searchAddressForm-speed"
                                            >* Limity rychlostí:</label
                                        >
                                        <ejs-dropdownlist
                                            #speed
                                            [allowFiltering]="true"
                                            [dataSource]="speedsSelect"
                                            [disabled]="
                                                !wifiFormCtrl.space_id.value ||
                                                wifiFormCtrl.space_id.invalid
                                            "
                                            [fields]="fields"
                                            [filterBarPlaceholder]="
                                                'Vyhledat limity rychlostí...'
                                            "
                                            [ignoreAccent]="true"
                                            [ngClass]="
                                                (wifiFormCtrl.speed_id.dirty ||
                                                    wifiFormCtrl.speed_id.touched) &&
                                                wifiFormCtrl.speed_id.invalid
                                                    ? 'e-error'
                                                    : (wifiFormCtrl.speed_id.dirty ||
                                                          wifiFormCtrl.speed_id
                                                              .touched) &&
                                                      wifiFormCtrl.speed_id.valid
                                                    ? 'e-success'
                                                    : ''
                                            "
                                            [placeholder]="'Vyberte limity rychlostí'"
                                            [popupHeight]="'240px'"
                                            formControlName="speed_id"
                                            id="searchAddressForm-speed"
                                            sortOrder="Ascending"
                                        ></ejs-dropdownlist>
                                        <small
                                            *ngIf="
                                                (wifiFormCtrl.speed_id.dirty ||
                                                    wifiFormCtrl.speed_id.touched) &&
                                                wifiFormCtrl.speed_id.errors &&
                                                wifiFormCtrl.speed_id.errors.required
                                            "
                                            class="text-danger"
                                            >Vyberte limity rychlostí</small
                                        >
                                    </div>
                                    <div class="col-12 mb-3 text-center">
                                        <ejs-checkbox
                                            [checked]="false"
                                            [disabled]="
                                                !wifiFormCtrl.speed_id.value ||
                                                wifiFormCtrl.speed_id.invalid ||
                                                !wifiFormCtrl.space_id.value ||
                                                wifiFormCtrl.space_id.invalid ||
                                                !wifiFormCtrl.unit_id.value ||
                                                wifiFormCtrl.unit_id.invalid
                                            "
                                            cssClass="e-primary mr-3"
                                            formControlName="basics"
                                            label="Základy"
                                        ></ejs-checkbox>
                                        <ejs-checkbox
                                            [checked]="false"
                                            [disabled]="
                                                !wifiFormCtrl.speed_id.value ||
                                                wifiFormCtrl.speed_id.invalid ||
                                                !wifiFormCtrl.space_id.value ||
                                                wifiFormCtrl.space_id.invalid ||
                                                !wifiFormCtrl.unit_id.value ||
                                                wifiFormCtrl.unit_id.invalid
                                            "
                                            cssClass="e-primary mr-3"
                                            formControlName="cabels"
                                            label="Kabeláž"
                                        ></ejs-checkbox>
                                        <ejs-checkbox
                                            [checked]="false"
                                            [disabled]="
                                                !wifiFormCtrl.speed_id.value ||
                                                wifiFormCtrl.speed_id.invalid ||
                                                !wifiFormCtrl.space_id.value ||
                                                wifiFormCtrl.space_id.invalid ||
                                                !wifiFormCtrl.unit_id.value ||
                                                wifiFormCtrl.unit_id.invalid
                                            "
                                            cssClass="e-primary mr-3"
                                            formControlName="garden"
                                            label="Zahrada"
                                        ></ejs-checkbox>
                                        <ejs-checkbox
                                            [checked]="false"
                                            [disabled]="
                                                !wifiFormCtrl.speed_id.value ||
                                                wifiFormCtrl.speed_id.invalid ||
                                                !wifiFormCtrl.space_id.value ||
                                                wifiFormCtrl.space_id.invalid ||
                                                !wifiFormCtrl.unit_id.value ||
                                                wifiFormCtrl.unit_id.invalid
                                            "
                                            cssClass="e-primary mr-3"
                                            formControlName="roaming"
                                            label="Wi-Fi roaming"
                                        ></ejs-checkbox>
                                    </div>
                                </form>
                                <div
                                    *ngIf="
                                        activeWifiSets &&
                                        (!matchedWifiSets ||
                                            matchedWifiSets.length === 0)
                                    "
                                    class="alert alert-danger p-1"
                                    role="alert"
                                    style="font-size: 14px !important"
                                >
                                    <i
                                        aria-hidden="true"
                                        class="fa-light fa-lg fa-exclamation-triangle mr-2"
                                    ></i
                                    >Tato sestava nemá přidělen
                                    <strong>hardware</strong>.
                                    <button
                                        (click)="resetWifiForm()"
                                        cssClass="e-btn e-primary btn-p-zero e-small ml-3"
                                        ejs-button
                                        type="button"
                                    >
                                        <i
                                            aria-hidden="true"
                                            class="fa-light fa-refresh mr-2"
                                        ></i
                                        >obnovit výchozí stav
                                    </button>
                                </div>
                                <div
                                    *ngIf="
                                        matchedWifiSets && matchedWifiSets.length > 0
                                    "
                                    class="d-flex"
                                    style="flex-wrap: wrap"
                                >
                                    <div
                                        *ngFor="let sets of matchedWifiSets"
                                        class="e-card products_card m-1"
                                        style="display: block !important"
                                    >
                                        <div
                                            class="e-card-header blue lighten-1 text-white p-2"
                                        >
                                            <div class="e-card-header-caption">
                                                <div
                                                    class="d-flex justify-content-between"
                                                >
                                                    <ejs-checkbox
                                                        (change)="
                                                            callwizardService.setWifiService(
                                                                sets
                                                            )
                                                        "
                                                        [checked]="
                                                            choosenWiFiServices &&
                                                            choosenWiFiServices.id ===
                                                                sets.id
                                                        "
                                                        [disabled]="
                                                            choosenWiFiServices &&
                                                            choosenWiFiServices.id !==
                                                                sets.id
                                                        "
                                                        cssClass="e-primary p-0 m-0 mr-3"
                                                        label=""
                                                    ></ejs-checkbox>
                                                    <ejs-radiobutton
                                                        (change)="
                                                            callwizardService.setWifiSellMode()
                                                        "
                                                        (click)="purchaseWifi = 'fee'"
                                                        *ngIf="
                                                            choosenWiFiServices &&
                                                            choosenWiFiServices.id ===
                                                                sets.id
                                                        "
                                                        [checked]="
                                                            purchaseWifi === 'fee'
                                                        "
                                                        cssClass="e-primary p-0 m-0 mr-3"
                                                        label="Pronájem"
                                                        name="purchaseWifi"
                                                        value="purchase"
                                                    ></ejs-radiobutton>
                                                    <ejs-radiobutton
                                                        (change)="
                                                            callwizardService.setWifiSellMode()
                                                        "
                                                        (click)="
                                                            purchaseWifi = 'purchase'
                                                        "
                                                        *ngIf="
                                                            choosenWiFiServices &&
                                                            choosenWiFiServices.id ===
                                                                sets.id
                                                        "
                                                        [checked]="
                                                            purchaseWifi === 'purchase'
                                                        "
                                                        cssClass="e-primary p-0 m-0 mr-3"
                                                        label="Odkup"
                                                        name="purchaseWifi"
                                                        value="fee"
                                                    ></ejs-radiobutton>
                                                    <strong>
                                                        #{{ sets.id }} Sestava hardware
                                                    </strong>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            class="e-card-content text-center pt-2 pb-2 pl-1 pr-1"
                                        >
                                            <h6 class="card-title">
                                                <strong>
                                                    {{ sets.hardware.name }}
                                                </strong>
                                            </h6>
                                            <h6 *ngIf="sets.recommended">
                                                <span class="e-badge e-badge-primary"
                                                    >doporučujeme</span
                                                >
                                            </h6>
                                            <ul
                                                class="list-group list-group-flush p-0 m-0"
                                                style="font-size: 10px !important"
                                            >
                                                <li class="list-group-item p-1 m-0">
                                                    cena:
                                                    {{
                                                        sets.hardware.price
                                                            ? (sets.hardware.price
                                                              | number: '1.2-2')
                                                            : 0
                                                    }}
                                                    ,- CZK
                                                </li>
                                                <li class="list-group-item p-1 m-0">
                                                    pronájem:
                                                    {{
                                                        sets.hardware.fee
                                                            ? (sets.hardware.fee
                                                              | number: '1.2-2')
                                                            : 0
                                                    }}
                                                    ,- CZK / měsíčně |
                                                    <small class="text-muted"
                                                        >{{
                                                            sets.hardware.fee
                                                                ? (sets.hardware.fee *
                                                                      12 -
                                                                      (sets.hardware
                                                                          .fee *
                                                                          12 *
                                                                          10) /
                                                                          100
                                                                  | number: '1.2-2')
                                                                : 0
                                                        }}
                                                        ,- CZK / ročně</small
                                                    >
                                                </li>
                                                <li class="list-group-item p-1 m-0">
                                                    <span
                                                        *ngIf="sets.basics"
                                                        class="e-badge e-badge-primary mr-2"
                                                        >základy</span
                                                    >
                                                    <span
                                                        *ngIf="sets.cabels"
                                                        class="e-badge e-badge-info mr-2"
                                                        >kabeláž</span
                                                    >
                                                    <span
                                                        *ngIf="sets.garden"
                                                        class="e-badge e-badge-success mr-2"
                                                        >zahrada</span
                                                    >
                                                    <span
                                                        *ngIf="sets.roaming"
                                                        class="e-badge e-badge-danger mr-2"
                                                        >wi-fi roaming</span
                                                    >
                                                </li>
                                            </ul>
                                            <mdb-image-modal
                                                *ngIf="
                                                    sets.hardware &&
                                                    sets.hardware.photo_url
                                                "
                                                [modalImages]="[
                                                    {
                                                        img: sets.hardware.photo_url,
                                                        thumb: sets.hardware
                                                            .photo_thumb_url,
                                                        description: sets.hardware.name,
                                                        caption: sets.hardware.name
                                                    }
                                                ]"
                                                type="imagesWithCaption"
                                            ></mdb-image-modal>
                                        </div>
                                        <div
                                            class="e-card-actions text-center w-100"
                                        ></div>
                                    </div>
                                </div>
                                <div
                                    *ngIf="!choosenInternetTarif || addressForm.invalid"
                                >
                                    <span
                                        ><strong class="text-danger"
                                            >Vyplňte správně předchozí krok</strong
                                        ></span
                                    >
                                </div>
                                <button
                                    (click)="stepper.previous()"
                                    cssClass="e-btn e-info e-outline btn-p-zero e-small"
                                    ejs-button
                                    type="button"
                                >
                                    Zpět
                                </button>
                                <button
                                    (click)="stepper.next()"
                                    [disabled]="
                                        wifiForm.invalid ||
                                        !choosenInternetTarif ||
                                        addressForm.invalid ||
                                        addressLoading
                                    "
                                    cssClass="e-btn e-primary btn-p-zero e-small"
                                    ejs-button
                                    type="button"
                                >
                                    Pokračovat & uložit
                                </button>
                                <button
                                    (click)="showWifiInputErrors()"
                                    cssClass="e-btn e-warning btn-p-zero e-small"
                                    ejs-button
                                    type="button"
                                >
                                    označ chyby
                                </button>
                                <button
                                    (click)="resetWifiForm(); onUpdateLead()"
                                    cssClass="e-btn e-danger btn-p-zero e-small float-right"
                                    ejs-button
                                    type="button"
                                >
                                    smazat sekci
                                </button>
                            </mdb-step>
                            <mdb-step
                                [stepForm]="clientForm"
                                label="Zadejte kontaktní informace klienta"
                                name="Klient"
                            >
                                <form
                                    *ngIf="
                                        wifiForm.status !== 'INVALID' &&
                                        choosenInternetTarif &&
                                        addressForm.status !== 'INVALID'
                                    "
                                    [formGroup]="clientForm"
                                >
                                    <div class="row p-4">
                                        <div class="col-12 m-0 p-1">
                                            <strong>Klient:</strong>
                                        </div>
                                        <div
                                            *ngIf="
                                                internetPrice.fee === 0 &&
                                                internetPrice.price === 0 &&
                                                televisionPrice.fee === 0 &&
                                                televisionPrice.price === 0 &&
                                                wifiPrice.fee === 0 &&
                                                wifiPrice.price === 0
                                            "
                                            class="alert alert-warning"
                                            role="alert"
                                        >
                                            <i
                                                aria-hidden="true"
                                                class="fa-light fa-lg fa-exclamation-triangle mr-2"
                                            ></i
                                            >Nevybrali jste žádné služby! Pokud budete
                                            pokračovat klient, se založí pouze jako
                                            lead.
                                        </div>
                                        <div class="col-12 col-md-6 mb-1">
                                            <label
                                                [ngClass]="
                                                    clientFormCtrl.firstname.valid &&
                                                    (clientFormCtrl.firstname.dirty ||
                                                        clientFormCtrl.firstname
                                                            .touched)
                                                        ? 'text-success'
                                                        : clientFormCtrl.firstname
                                                              .invalid &&
                                                          (clientFormCtrl.firstname
                                                              .dirty ||
                                                              clientFormCtrl.firstname
                                                                  .touched)
                                                        ? 'text-danger'
                                                        : 'text-muted'
                                                "
                                                for="clientForm-firstname"
                                                >* Jméno:</label
                                            >
                                            <ejs-textbox
                                                [ngClass]="
                                                    clientFormCtrl.firstname.touched &&
                                                    clientFormCtrl.firstname.invalid
                                                        ? 'e-error'
                                                        : clientFormCtrl.firstname
                                                              .touched &&
                                                          clientFormCtrl.firstname.valid
                                                        ? 'e-success'
                                                        : ''
                                                "
                                                autocomplete="on"
                                                floatLabelType="Auto"
                                                formControlName="firstname"
                                                id="clientForm-firstname"
                                            ></ejs-textbox>
                                            <small
                                                *ngIf="
                                                    clientFormCtrl.firstname.touched &&
                                                    clientFormCtrl.firstname.errors &&
                                                    clientFormCtrl.firstname.errors
                                                        .required
                                                "
                                                class="text-danger"
                                                >Vyplňte jméno klienta</small
                                            >
                                        </div>
                                        <div class="col-12 col-md-6 mb-1">
                                            <label
                                                [ngClass]="
                                                    clientFormCtrl.lastname.valid &&
                                                    (clientFormCtrl.lastname.dirty ||
                                                        clientFormCtrl.lastname.touched)
                                                        ? 'text-success'
                                                        : clientFormCtrl.lastname
                                                              .invalid &&
                                                          (clientFormCtrl.lastname
                                                              .dirty ||
                                                              clientFormCtrl.lastname
                                                                  .touched)
                                                        ? 'text-danger'
                                                        : 'text-muted'
                                                "
                                                for="clientForm-lastname"
                                                >* Příjmení:</label
                                            >
                                            <ejs-textbox
                                                [ngClass]="
                                                    clientFormCtrl.lastname.touched &&
                                                    clientFormCtrl.lastname.invalid
                                                        ? 'e-error'
                                                        : clientFormCtrl.lastname
                                                              .touched &&
                                                          clientFormCtrl.lastname.valid
                                                        ? 'e-success'
                                                        : ''
                                                "
                                                autocomplete="on"
                                                floatLabelType="Auto"
                                                formControlName="lastname"
                                                id="clientForm-lastname"
                                            ></ejs-textbox>
                                            <small
                                                *ngIf="
                                                    clientFormCtrl.lastname.touched &&
                                                    clientFormCtrl.lastname.errors &&
                                                    clientFormCtrl.lastname.errors
                                                        .required
                                                "
                                                class="text-danger"
                                                >Vyplňte příjmení klienta</small
                                            >
                                        </div>
                                        <div class="col-12 col-md-6 mb-1">
                                            <label
                                                [ngClass]="
                                                    clientFormCtrl.email.valid &&
                                                    (clientFormCtrl.email.dirty ||
                                                        clientFormCtrl.email.touched)
                                                        ? 'text-success'
                                                        : clientFormCtrl.email
                                                              .invalid &&
                                                          (clientFormCtrl.email.dirty ||
                                                              clientFormCtrl.email
                                                                  .touched)
                                                        ? 'text-danger'
                                                        : 'text-muted'
                                                "
                                                for="clientForm-email"
                                                >* E-mailová adresa:</label
                                            >
                                            <ejs-textbox
                                                [ngClass]="
                                                    clientFormCtrl.email.touched &&
                                                    clientFormCtrl.email.invalid
                                                        ? 'e-error'
                                                        : clientFormCtrl.email
                                                              .touched &&
                                                          clientFormCtrl.email.valid
                                                        ? 'e-success'
                                                        : ''
                                                "
                                                autocomplete="on"
                                                floatLabelType="Auto"
                                                formControlName="email"
                                                id="clientForm-email"
                                            ></ejs-textbox>
                                            <small
                                                *ngIf="
                                                    clientFormCtrl.email.touched &&
                                                    clientFormCtrl.email.errors &&
                                                    clientFormCtrl.email.errors.required
                                                "
                                                class="text-danger"
                                                >Vyplňte e-mail klienta</small
                                            >
                                        </div>
                                        <div class="col-12 col-md-6 mb-1">
                                            <label
                                                [ngClass]="
                                                    clientFormCtrl.telephone.valid &&
                                                    (clientFormCtrl.telephone.dirty ||
                                                        clientFormCtrl.telephone
                                                            .touched)
                                                        ? 'text-success'
                                                        : clientFormCtrl.telephone
                                                              .invalid &&
                                                          (clientFormCtrl.telephone
                                                              .dirty ||
                                                              clientFormCtrl.telephone
                                                                  .touched)
                                                        ? 'text-danger'
                                                        : 'text-muted'
                                                "
                                                for="clientForm-telephone"
                                                >* Telefon:</label
                                            >
                                            <ejs-textbox
                                                [ngClass]="
                                                    clientFormCtrl.telephone.touched &&
                                                    clientFormCtrl.telephone.invalid
                                                        ? 'e-error'
                                                        : clientFormCtrl.telephone
                                                              .touched &&
                                                          clientFormCtrl.telephone.valid
                                                        ? 'e-success'
                                                        : ''
                                                "
                                                autocomplete="on"
                                                floatLabelType="Auto"
                                                formControlName="telephone"
                                                id="clientForm-telephone"
                                            ></ejs-textbox>
                                            <small
                                                *ngIf="
                                                    clientFormCtrl.telephone.touched &&
                                                    clientFormCtrl.telephone.errors &&
                                                    clientFormCtrl.telephone.errors
                                                        .required
                                                "
                                                class="text-danger"
                                                >Vyplňte telefon klienta</small
                                            >
                                        </div>
                                        <div class="col-12 col-md-6 mb-1">
                                            <label
                                                [ngClass]="
                                                    clientFormCtrl.mobile.valid &&
                                                    (clientFormCtrl.mobile.dirty ||
                                                        clientFormCtrl.mobile.touched)
                                                        ? 'text-success'
                                                        : clientFormCtrl.mobile
                                                              .invalid &&
                                                          (clientFormCtrl.mobile
                                                              .dirty ||
                                                              clientFormCtrl.mobile
                                                                  .touched)
                                                        ? 'text-danger'
                                                        : 'text-muted'
                                                "
                                                for="clientForm-mobile"
                                                >* Mobil:</label
                                            >
                                            <ejs-textbox
                                                [ngClass]="
                                                    clientFormCtrl.mobile.touched &&
                                                    clientFormCtrl.mobile.invalid
                                                        ? 'e-error'
                                                        : clientFormCtrl.mobile
                                                              .touched &&
                                                          clientFormCtrl.mobile.valid
                                                        ? 'e-success'
                                                        : ''
                                                "
                                                autocomplete="on"
                                                floatLabelType="Auto"
                                                formControlName="mobile"
                                                id="clientForm-mobile"
                                            ></ejs-textbox>
                                            <small
                                                *ngIf="
                                                    clientFormCtrl.mobile.touched &&
                                                    clientFormCtrl.mobile.errors &&
                                                    clientFormCtrl.mobile.errors
                                                        .required
                                                "
                                                class="text-danger"
                                                >Vyplňte telefon klienta</small
                                            >
                                        </div>
                                        <div class="col-12 col-md-6 mb-1">
                                            <label
                                                [ngClass]="
                                                    clientFormCtrl.billingEmail.valid &&
                                                    (clientFormCtrl.billingEmail
                                                        .dirty ||
                                                        clientFormCtrl.billingEmail
                                                            .touched)
                                                        ? 'text-success'
                                                        : clientFormCtrl.billingEmail
                                                              .invalid &&
                                                          (clientFormCtrl.billingEmail
                                                              .dirty ||
                                                              clientFormCtrl
                                                                  .billingEmail.touched)
                                                        ? 'text-danger'
                                                        : 'text-muted'
                                                "
                                                for="clientForm-billingEmail"
                                                >Fakturační email:</label
                                            >
                                            <ejs-textbox
                                                [ngClass]="
                                                    clientFormCtrl.billingEmail
                                                        .touched &&
                                                    clientFormCtrl.billingEmail.invalid
                                                        ? 'e-error'
                                                        : clientFormCtrl.billingEmail
                                                              .touched &&
                                                          clientFormCtrl.billingEmail
                                                              .valid
                                                        ? 'e-success'
                                                        : ''
                                                "
                                                autocomplete="on"
                                                floatLabelType="Auto"
                                                formControlName="billingEmail"
                                                id="clientForm-billingEmail"
                                            ></ejs-textbox>
                                            <small
                                                *ngIf="
                                                    clientFormCtrl.billingEmail
                                                        .touched &&
                                                    clientFormCtrl.billingEmail
                                                        .errors &&
                                                    clientFormCtrl.billingEmail.errors
                                                        .required
                                                "
                                                class="text-danger"
                                                >Vyplňte fakturační email klienta</small
                                            >
                                        </div>
                                        <div class="col-12 col-md-6 mb-1">
                                            <label
                                                [ngClass]="
                                                    clientFormCtrl.technicalEmail
                                                        .valid &&
                                                    (clientFormCtrl.technicalEmail
                                                        .dirty ||
                                                        clientFormCtrl.technicalEmail
                                                            .touched)
                                                        ? 'text-success'
                                                        : clientFormCtrl.technicalEmail
                                                              .invalid &&
                                                          (clientFormCtrl.technicalEmail
                                                              .dirty ||
                                                              clientFormCtrl
                                                                  .technicalEmail
                                                                  .touched)
                                                        ? 'text-danger'
                                                        : 'text-muted'
                                                "
                                                for="clientForm-technicalEmail"
                                                >Technický email:</label
                                            >
                                            <ejs-textbox
                                                [ngClass]="
                                                    clientFormCtrl.technicalEmail
                                                        .touched &&
                                                    clientFormCtrl.technicalEmail
                                                        .invalid
                                                        ? 'e-error'
                                                        : clientFormCtrl.technicalEmail
                                                              .touched &&
                                                          clientFormCtrl.technicalEmail
                                                              .valid
                                                        ? 'e-success'
                                                        : ''
                                                "
                                                autocomplete="on"
                                                floatLabelType="Auto"
                                                formControlName="technicalEmail"
                                                id="clientForm-technicalEmail"
                                            ></ejs-textbox>
                                            <small
                                                *ngIf="
                                                    clientFormCtrl.technicalEmail
                                                        .touched &&
                                                    clientFormCtrl.technicalEmail
                                                        .errors &&
                                                    clientFormCtrl.technicalEmail.errors
                                                        .required
                                                "
                                                class="text-danger"
                                                >Vyplňte technický email klienta</small
                                            >
                                        </div>
                                        <div class="col-12 col-md-6 mb-1">
                                            <label
                                                [ngClass]="
                                                    clientFormCtrl.businessEmail
                                                        .valid &&
                                                    (clientFormCtrl.businessEmail
                                                        .dirty ||
                                                        clientFormCtrl.businessEmail
                                                            .touched)
                                                        ? 'text-success'
                                                        : clientFormCtrl.businessEmail
                                                              .invalid &&
                                                          (clientFormCtrl.businessEmail
                                                              .dirty ||
                                                              clientFormCtrl
                                                                  .businessEmail
                                                                  .touched)
                                                        ? 'text-danger'
                                                        : 'text-muted'
                                                "
                                                for="clientForm-businessEmail"
                                                >Obchodní email:</label
                                            >
                                            <ejs-textbox
                                                [ngClass]="
                                                    clientFormCtrl.businessEmail
                                                        .touched &&
                                                    clientFormCtrl.businessEmail.invalid
                                                        ? 'e-error'
                                                        : clientFormCtrl.businessEmail
                                                              .touched &&
                                                          clientFormCtrl.businessEmail
                                                              .valid
                                                        ? 'e-success'
                                                        : ''
                                                "
                                                autocomplete="on"
                                                floatLabelType="Auto"
                                                formControlName="businessEmail"
                                                id="clientForm-businessEmail"
                                            ></ejs-textbox>
                                            <small
                                                *ngIf="
                                                    clientFormCtrl.businessEmail
                                                        .touched &&
                                                    clientFormCtrl.businessEmail
                                                        .errors &&
                                                    clientFormCtrl.businessEmail.errors
                                                        .required
                                                "
                                                class="text-danger"
                                                >Vyplňte obchodní email klienta</small
                                            >
                                        </div>
                                        <div class="col-12 col-md-6 mb-1">
                                            <label
                                                [ngClass]="
                                                    clientFormCtrl.nationalIdNumber
                                                        .valid &&
                                                    (clientFormCtrl.nationalIdNumber
                                                        .dirty ||
                                                        clientFormCtrl.nationalIdNumber
                                                            .touched)
                                                        ? 'text-success'
                                                        : clientFormCtrl
                                                              .nationalIdNumber
                                                              .invalid &&
                                                          (clientFormCtrl
                                                              .nationalIdNumber.dirty ||
                                                              clientFormCtrl
                                                                  .nationalIdNumber
                                                                  .touched)
                                                        ? 'text-danger'
                                                        : 'text-muted'
                                                "
                                                for="clientForm-nationalIdNumber"
                                                >Rodné číslo:</label
                                            >
                                            <ejs-textbox
                                                [ngClass]="
                                                    clientFormCtrl.nationalIdNumber
                                                        .touched &&
                                                    clientFormCtrl.nationalIdNumber
                                                        .invalid
                                                        ? 'e-error'
                                                        : clientFormCtrl
                                                              .nationalIdNumber
                                                              .touched &&
                                                          clientFormCtrl
                                                              .nationalIdNumber.valid
                                                        ? 'e-success'
                                                        : ''
                                                "
                                                autocomplete="on"
                                                floatLabelType="Auto"
                                                formControlName="nationalIdNumber"
                                                id="clientForm-nationalIdNumber"
                                            ></ejs-textbox>
                                            <small
                                                *ngIf="
                                                    clientFormCtrl.nationalIdNumber
                                                        .touched &&
                                                    clientFormCtrl.nationalIdNumber
                                                        .errors &&
                                                    clientFormCtrl.nationalIdNumber
                                                        .errors.required
                                                "
                                                class="text-danger"
                                                >Vyplňte rodné číslo klienta</small
                                            >
                                        </div>
                                        <div class="col-12 m-0 p-1">
                                            <hr />
                                        </div>
                                        <div class="col-12 m-0 p-1">
                                            <strong>Adresa klienta:</strong>
                                        </div>
                                        <div class="col-12 mb-1">
                                            <label
                                                [ngClass]="
                                                    clientFormCtrl.address.valid &&
                                                    (clientFormCtrl.address.dirty ||
                                                        clientFormCtrl.address.touched)
                                                        ? 'text-success'
                                                        : clientFormCtrl.address
                                                              .invalid &&
                                                          (clientFormCtrl.address
                                                              .dirty ||
                                                              clientFormCtrl.address
                                                                  .touched)
                                                        ? 'text-danger'
                                                        : 'text-muted'
                                                "
                                                for="clientForm-address"
                                                >* Trvalé bydliště:</label
                                            >
                                            <ejs-textbox
                                                [ngClass]="
                                                    clientFormCtrl.address.touched &&
                                                    clientFormCtrl.address.invalid
                                                        ? 'e-error'
                                                        : clientFormCtrl.address
                                                              .touched &&
                                                          clientFormCtrl.address.valid
                                                        ? 'e-success'
                                                        : ''
                                                "
                                                autocomplete="smartform-auto-address"
                                                class="smartform-whole-address smartform-instance-clientAddress"
                                                floatLabelType="Auto"
                                                formControlName="address"
                                                id="clientForm-address"
                                            ></ejs-textbox>
                                            <small
                                                *ngIf="
                                                    clientFormCtrl.address.touched &&
                                                    clientFormCtrl.address.errors &&
                                                    clientFormCtrl.address.errors
                                                        .required
                                                "
                                                class="text-danger"
                                                >Vyberte trvalé bydliště pomocí
                                                našeptávače</small
                                            >
                                        </div>
                                        <div class="col-12 mb-1">
                                            <label
                                                [ngClass]="
                                                    clientFormCtrl.billingAddress
                                                        .valid &&
                                                    (clientFormCtrl.billingAddress
                                                        .dirty ||
                                                        clientFormCtrl.billingAddress
                                                            .touched)
                                                        ? 'text-success'
                                                        : clientFormCtrl.billingAddress
                                                              .invalid &&
                                                          (clientFormCtrl.billingAddress
                                                              .dirty ||
                                                              clientFormCtrl
                                                                  .billingAddress
                                                                  .touched)
                                                        ? 'text-danger'
                                                        : 'text-muted'
                                                "
                                                for="clientForm-billingAddress"
                                                >Fakturační adresa:</label
                                            >
                                            <ejs-textbox
                                                [ngClass]="
                                                    clientFormCtrl.billingAddress
                                                        .touched &&
                                                    clientFormCtrl.billingAddress
                                                        .invalid
                                                        ? 'e-error'
                                                        : clientFormCtrl.billingAddress
                                                              .touched &&
                                                          clientFormCtrl.billingAddress
                                                              .valid
                                                        ? 'e-success'
                                                        : ''
                                                "
                                                autocomplete="smartform-auto-address"
                                                class="smartform-whole-address smartform-instance-billingAddress"
                                                floatLabelType="Auto"
                                                formControlName="billingAddress"
                                                id="clientForm-billingAddress"
                                            ></ejs-textbox>
                                            <small
                                                *ngIf="
                                                    clientFormCtrl.billingAddress
                                                        .touched &&
                                                    clientFormCtrl.billingAddress
                                                        .errors &&
                                                    clientFormCtrl.billingAddress.errors
                                                        .required
                                                "
                                                class="text-danger"
                                                >Vyberte fakturační adresu pomocí
                                                našeptávače</small
                                            >
                                        </div>
                                        <div class="col-12 m-0 p-1">
                                            <hr />
                                        </div>
                                        <div class="col-12 m-0 p-1">
                                            <strong>Bankovní spojení:</strong>
                                        </div>
                                        <div class="col-12 col-md-6 mb-1">
                                            <label
                                                [ngClass]="
                                                    clientFormCtrl.accountNumber
                                                        .valid &&
                                                    (clientFormCtrl.accountNumber
                                                        .dirty ||
                                                        clientFormCtrl.accountNumber
                                                            .touched)
                                                        ? 'text-success'
                                                        : clientFormCtrl.accountNumber
                                                              .invalid &&
                                                          (clientFormCtrl.accountNumber
                                                              .dirty ||
                                                              clientFormCtrl
                                                                  .accountNumber
                                                                  .touched)
                                                        ? 'text-danger'
                                                        : 'text-muted'
                                                "
                                                for="clientForm-accountNumber"
                                                >Číslo účtu:</label
                                            >
                                            <ejs-numerictextbox
                                                [format]="'###'"
                                                [ngClass]="
                                                    clientFormCtrl.accountNumber
                                                        .touched &&
                                                    clientFormCtrl.accountNumber.invalid
                                                        ? 'e-error'
                                                        : clientFormCtrl.accountNumber
                                                              .touched &&
                                                          clientFormCtrl.accountNumber
                                                              .valid
                                                        ? 'e-success'
                                                        : ''
                                                "
                                                formControlName="accountNumber"
                                                id="clientForm-accountNumber"
                                                step="1"
                                            ></ejs-numerictextbox>
                                            <small
                                                *ngIf="
                                                    clientFormCtrl.accountNumber
                                                        .touched &&
                                                    clientFormCtrl.accountNumber
                                                        .errors &&
                                                    clientFormCtrl.accountNumber.errors
                                                        .required
                                                "
                                                class="text-danger"
                                                >Vyplňte číslo účtu klienta</small
                                            >
                                        </div>
                                        <div class="col-12 col-md-6 mb-1">
                                            <label
                                                [ngClass]="
                                                    clientFormCtrl.bankNumber.valid &&
                                                    (clientFormCtrl.bankNumber.dirty ||
                                                        clientFormCtrl.bankNumber
                                                            .touched)
                                                        ? 'text-success'
                                                        : clientFormCtrl.bankNumber
                                                              .invalid &&
                                                          (clientFormCtrl.bankNumber
                                                              .dirty ||
                                                              clientFormCtrl.bankNumber
                                                                  .touched)
                                                        ? 'text-danger'
                                                        : 'text-muted'
                                                "
                                                for="clientForm-bankNumber"
                                                >Číslo banky:</label
                                            >
                                            <ejs-numerictextbox
                                                [format]="'###'"
                                                [ngClass]="
                                                    clientFormCtrl.bankNumber.touched &&
                                                    clientFormCtrl.bankNumber.invalid
                                                        ? 'e-error'
                                                        : clientFormCtrl.bankNumber
                                                              .touched &&
                                                          clientFormCtrl.bankNumber
                                                              .valid
                                                        ? 'e-success'
                                                        : ''
                                                "
                                                formControlName="bankNumber"
                                                id="clientForm-bankNumber"
                                                step="1"
                                            ></ejs-numerictextbox>
                                            <small
                                                *ngIf="
                                                    clientFormCtrl.bankNumber.touched &&
                                                    clientFormCtrl.bankNumber.errors &&
                                                    clientFormCtrl.bankNumber.errors
                                                        .required
                                                "
                                                class="text-danger"
                                                >Vyplňte číslo banky klienta</small
                                            >
                                        </div>
                                        <div class="col-12 col-md-6 mb-1">
                                            <label
                                                [ngClass]="
                                                    clientFormCtrl.vatId.valid &&
                                                    (clientFormCtrl.vatId.dirty ||
                                                        clientFormCtrl.vatId.touched)
                                                        ? 'text-success'
                                                        : clientFormCtrl.vatId
                                                              .invalid &&
                                                          (clientFormCtrl.vatId.dirty ||
                                                              clientFormCtrl.vatId
                                                                  .touched)
                                                        ? 'text-danger'
                                                        : 'text-muted'
                                                "
                                                for="clientForm-vatId"
                                                >IČ:</label
                                            >
                                            <ejs-numerictextbox
                                                [format]="'###'"
                                                [ngClass]="
                                                    clientFormCtrl.vatId.touched &&
                                                    clientFormCtrl.vatId.invalid
                                                        ? 'e-error'
                                                        : clientFormCtrl.vatId
                                                              .touched &&
                                                          clientFormCtrl.vatId.valid
                                                        ? 'e-success'
                                                        : ''
                                                "
                                                formControlName="vatId"
                                                id="clientForm-vatId"
                                                min="0"
                                                step="1"
                                            ></ejs-numerictextbox>
                                            <small
                                                *ngIf="
                                                    clientFormCtrl.vatId.touched &&
                                                    clientFormCtrl.vatId.errors &&
                                                    clientFormCtrl.vatId.errors.required
                                                "
                                                class="text-danger"
                                                >Vyplňte IČ klienta</small
                                            >
                                        </div>
                                        <div class="col-12 m-0 p-1">
                                            <hr />
                                        </div>
                                        <div class="col-12 m-0 p-1">
                                            <strong>Nastavení klienta:</strong>
                                        </div>
                                        <div class="col-12 col-md-6 mb-1">
                                            <label
                                                [ngClass]="
                                                    clientFormCtrl.deal_id.valid
                                                        ? 'text-success'
                                                        : clientFormCtrl.deal_id
                                                              .invalid &&
                                                          (clientFormCtrl.deal_id
                                                              .dirty ||
                                                              clientFormCtrl.deal_id
                                                                  .touched)
                                                        ? 'text-danger'
                                                        : 'text-muted'
                                                "
                                                for="clientForm-deal_id"
                                                >* Smluvní úvazek:</label
                                            >
                                            <ejs-dropdownlist
                                                #deal
                                                [allowFiltering]="true"
                                                [dataSource]="dealSelect"
                                                [fields]="fields"
                                                [filterBarPlaceholder]="
                                                    'Vyhledat typ smluvního úvazku...'
                                                "
                                                [ignoreAccent]="true"
                                                [ngClass]="
                                                    (clientFormCtrl.deal_id.dirty ||
                                                        clientFormCtrl.deal_id
                                                            .touched) &&
                                                    clientFormCtrl.deal_id.invalid
                                                        ? 'e-error'
                                                        : (clientFormCtrl.deal_id
                                                              .dirty ||
                                                              clientFormCtrl.deal_id
                                                                  .touched) &&
                                                          clientFormCtrl.deal_id.valid
                                                        ? 'e-success'
                                                        : ''
                                                "
                                                [placeholder]="
                                                    'Vyberte typ smluvního úvazku'
                                                "
                                                [popupHeight]="'240px'"
                                                formControlName="deal_id"
                                                id="clientForm-deal_id"
                                                sortOrder="Ascending"
                                            ></ejs-dropdownlist>
                                            <small
                                                *ngIf="
                                                    (clientFormCtrl.deal_id.dirty ||
                                                        clientFormCtrl.deal_id
                                                            .touched) &&
                                                    clientFormCtrl.deal_id.errors &&
                                                    clientFormCtrl.deal_id.errors
                                                        .required
                                                "
                                                class="text-danger"
                                                >Vyberte typ smluvního úvazku</small
                                            >
                                        </div>
                                        <div class="col-12 col-md-6 mb-1">
                                            <label
                                                [ngClass]="
                                                    clientFormCtrl.companyId.valid
                                                        ? 'text-success'
                                                        : clientFormCtrl.companyId
                                                              .invalid &&
                                                          (clientFormCtrl.companyId
                                                              .dirty ||
                                                              clientFormCtrl.companyId
                                                                  .touched)
                                                        ? 'text-danger'
                                                        : 'text-muted'
                                                "
                                                for="clientForm-companyId"
                                                >Společnost:</label
                                            >
                                            <ejs-dropdownlist
                                                #company
                                                [allowFiltering]="true"
                                                [dataSource]="companySelect"
                                                [fields]="fields"
                                                [filterBarPlaceholder]="
                                                    'Vyhledat providera...'
                                                "
                                                [ignoreAccent]="true"
                                                [ngClass]="
                                                    (clientFormCtrl.companyId.dirty ||
                                                        clientFormCtrl.companyId
                                                            .touched) &&
                                                    clientFormCtrl.companyId.invalid
                                                        ? 'e-error'
                                                        : (clientFormCtrl.companyId
                                                              .dirty ||
                                                              clientFormCtrl.companyId
                                                                  .touched) &&
                                                          clientFormCtrl.companyId.valid
                                                        ? 'e-success'
                                                        : ''
                                                "
                                                [placeholder]="'Vyberte providera'"
                                                [popupHeight]="'240px'"
                                                formControlName="companyId"
                                                id="clientForm-companyId"
                                                sortOrder="Ascending"
                                            ></ejs-dropdownlist>
                                            <small
                                                *ngIf="
                                                    (clientFormCtrl.companyId.dirty ||
                                                        clientFormCtrl.companyId
                                                            .touched) &&
                                                    clientFormCtrl.companyId.errors &&
                                                    clientFormCtrl.companyId.errors
                                                        .required
                                                "
                                                class="text-danger"
                                                >Vyberte providera pro klienta</small
                                            >
                                        </div>
                                        <hr />
                                        <div class="col-12 mb-3 text-center">
                                            <ejs-checkbox
                                                #vipInstall
                                                [checked]="false"
                                                cssClass="e-primary mr-1"
                                                formControlName="vipInstall"
                                                label="Přednostní instalace (+500,- CZK / jednorázově)"
                                            ></ejs-checkbox>
                                            <i
                                                (click)="
                                                    showHint(
                                                        'Jedná se o přednostní zprovoznění služby do 3 pracovních dnů od objednání služby. Jednorázový poplatek za tuto instalaci činí 500Kč vč. DPH. Způsob úhrady je v hotovosti technickému pracovníkovi na místě.'
                                                    )
                                                "
                                                aria-hidden="true"
                                                class="fa-light fa-lg fa-question-circle mr-2"
                                                style="cursor: pointer"
                                            ></i>
                                            <ejs-checkbox
                                                #invoiceByYear
                                                [checked]="false"
                                                cssClass="e-primary mr-1"
                                                formControlName="invoiceByYear"
                                                label="Roční předplatné (sleva 10%)"
                                            ></ejs-checkbox>
                                            <i
                                                (click)="
                                                    showHint(
                                                        'Nabídneme Vám 10% slevu při roční předplatném.'
                                                    )
                                                "
                                                aria-hidden="true"
                                                class="fa-light fa-lg fa-question-circle mr-2"
                                                style="cursor: pointer"
                                            ></i>
                                        </div>
                                        <div class="col-12 mb-3">
                                            <label
                                                [ngClass]="
                                                    clientFormCtrl.internalNote.valid
                                                        ? 'text-success'
                                                        : clientFormCtrl.internalNote
                                                              .invalid
                                                        ? 'text-danger'
                                                        : 'text-muted'
                                                "
                                                data-error="wrong"
                                                data-success="right"
                                                for="clientForm-internalNote"
                                                >Interní poznámka:</label
                                            >
                                            <ejs-textbox
                                                [multiline]="true"
                                                [ngClass]="{
                                                    'e-error':
                                                        clientFormCtrl.internalNote
                                                            .errors
                                                }"
                                                floatLabelType="Auto"
                                                formControlName="internalNote"
                                                id="clientForm-internalNote"
                                            ></ejs-textbox>
                                            <small
                                                *ngIf="
                                                    clientFormCtrl.internalNote
                                                        .errors &&
                                                    clientFormCtrl.internalNote.errors
                                                        .required
                                                "
                                                class="text-danger"
                                                >Interní poznámka je povinná</small
                                            >
                                        </div>
                                    </div>
                                </form>
                                <div
                                    *ngIf="
                                        wifiForm.invalid ||
                                        !choosenInternetTarif ||
                                        addressForm.invalid
                                    "
                                >
                                    <span
                                        ><strong class="text-danger"
                                            >Vyplňte správně předchozí krok</strong
                                        ></span
                                    >
                                </div>
                                <button
                                    (click)="stepper.previous()"
                                    cssClass="e-btn e-info e-outline btn-p-zero e-small"
                                    ejs-button
                                    type="button"
                                >
                                    Zpět
                                </button>
                                <button
                                    (click)="stepper.next()"
                                    [disabled]="
                                        clientForm.invalid ||
                                        wifiForm.invalid ||
                                        !choosenInternetTarif ||
                                        addressForm.invalid ||
                                        addressLoading
                                    "
                                    cssClass="e-btn e-primary btn-p-zero e-small"
                                    ejs-button
                                    type="button"
                                >
                                    Pokračovat & uložit
                                </button>
                                <button
                                    (click)="showClientInputErrors()"
                                    cssClass="e-btn e-warning btn-p-zero e-small"
                                    ejs-button
                                    type="button"
                                >
                                    označ chyby
                                </button>
                                <button
                                    (click)="resetClientForm(); onUpdateLead()"
                                    cssClass="e-btn e-danger btn-p-zero e-small float-right"
                                    ejs-button
                                    type="button"
                                >
                                    smazat sekci
                                </button>
                            </mdb-step>
                            <mdb-step
                                label="Rekapitulace objednávky"
                                name="Rekapitulace"
                            >
                                <div
                                    #pdfFinishTable
                                    *ngIf="addressForm.valid && lead"
                                    class="col-12 p-2"
                                    id="pdfFinishTable"
                                >
                                    <div
                                        *ngIf="
                                            clientForm.valid &&
                                            wifiForm.valid &&
                                            choosenInternetTarif &&
                                            addressForm.valid &&
                                            internetPrice > 0 &&
                                            televisionPrice > 0 &&
                                            wifiPrice > 0
                                        "
                                        class="alert alert-success p-1"
                                        role="alert"
                                        style="font-size: 14px !important"
                                    >
                                        <i
                                            aria-hidden="true"
                                            class="fa-light fa-lg fa-check-circle mr-2"
                                        ></i
                                        ><strong>Hotovo! ... Výborná práce!</strong> vše
                                        v púořádku můžete potvrdit objednávku.
                                    </div>
                                    <div
                                        *ngIf="
                                            internetPrice.fee === 0 &&
                                            internetPrice.price === 0 &&
                                            televisionPrice.fee === 0 &&
                                            televisionPrice.price === 0 &&
                                            wifiPrice.fee === 0 &&
                                            wifiPrice.price === 0
                                        "
                                        class="alert alert-warning"
                                        role="alert"
                                    >
                                        <i
                                            aria-hidden="true"
                                            class="fa-light fa-lg fa-exclamation-triangle mr-2"
                                        ></i
                                        >Nevybrali jste žádné služby! Pokud budete
                                        pokračovat klient, se založí pouze jako lead.
                                    </div>
                                    <div class="row mb-4">
                                        <div
                                            *ngIf="addressForm.valid"
                                            class="col-12 col-lg-6"
                                        >
                                            <div class="e-card mb-4">
                                                <div
                                                    class="e-card-header stylish-color-dark text-white"
                                                >
                                                    <div class="e-card-header-caption">
                                                        <div
                                                            class="d-flex justify-content-between e-card-header-title text-white"
                                                        >
                                                            <strong>Adresa:</strong>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div
                                                    class="e-card-content text-center pt-2 pb-2 pl-1 pr-1"
                                                >
                                                    <table
                                                        class="table table-sm table-borderless"
                                                    >
                                                        <tbody>
                                                            <tr
                                                                class="border-bottom border-top"
                                                            >
                                                                <td>Adresa:</td>
                                                                <td
                                                                    class="text-right"
                                                                    colspan="2"
                                                                >
                                                                    {{
                                                                        addressObj.searchedAddress
                                                                    }}
                                                                </td>
                                                            </tr>
                                                            <tr class="border-bottom">
                                                                <td
                                                                    >Ruian / Číslo
                                                                    parcely:
                                                                </td>
                                                                <td
                                                                    *ngIf="ruianCode"
                                                                    class="text-right"
                                                                    colspan="2"
                                                                >
                                                                    {{ ruianCode }}
                                                                </td>
                                                                <td
                                                                    *ngIf="
                                                                        fieldCode &&
                                                                        !ruianCode
                                                                    "
                                                                    class="text-right"
                                                                    colspan="2"
                                                                >
                                                                    {{ fieldCode }}
                                                                </td>
                                                            </tr>
                                                            <tr
                                                                *ngIf="
                                                                    matchedConnectedFieldAddress ||
                                                                    matchedConnectedRuianAddress
                                                                "
                                                                class="border-bottom"
                                                            >
                                                                <td>ID adresy:</td>
                                                                <td
                                                                    *ngIf="
                                                                        matchedConnectedFieldAddress
                                                                    "
                                                                    class="text-right"
                                                                    colspan="2"
                                                                >
                                                                    {{
                                                                        matchedConnectedFieldAddress.id
                                                                    }}
                                                                </td>
                                                                <td
                                                                    *ngIf="
                                                                        matchedConnectedRuianAddress
                                                                    "
                                                                    class="text-right"
                                                                    colspan="2"
                                                                >
                                                                    {{
                                                                        matchedConnectedRuianAddress.id
                                                                    }}
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <div
                                                    class="e-card-actions text-center w-100"
                                                ></div>
                                            </div>
                                            <div class="e-card mb-4">
                                                <div
                                                    class="e-card-header stylish-color-dark text-white"
                                                >
                                                    <div class="e-card-header-caption">
                                                        <div
                                                            class="d-flex justify-content-between e-card-header-title text-white"
                                                        >
                                                            <strong>Internet:</strong>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div
                                                    class="e-card-content text-center pt-2 pb-2 pl-1 pr-1"
                                                >
                                                    <table
                                                        class="table table-sm table-borderless"
                                                    >
                                                        <tbody>
                                                            <tr
                                                                *ngIf="
                                                                    choosenInternetTarif
                                                                "
                                                                class="border-bottom"
                                                            >
                                                                <td>tarif:</td>
                                                                <td class="text-center">
                                                                    {{
                                                                        choosenInternetTarif.name
                                                                    }}
                                                                </td>
                                                                <td class="text-right">
                                                                    <strong
                                                                        >{{
                                                                            choosenInternetTarif.price
                                                                                | number
                                                                                    : '1.2-2'
                                                                        }},- Kč /
                                                                        měsíčně |
                                                                        <small
                                                                            class="text-muted"
                                                                            >{{
                                                                                choosenInternetTarif.price
                                                                                    ? (choosenInternetTarif.price *
                                                                                          12 -
                                                                                          (choosenInternetTarif.price *
                                                                                              12 *
                                                                                              10) /
                                                                                              100
                                                                                      | number
                                                                                          : '1.2-2')
                                                                                    : 0
                                                                            }}
                                                                            ,- CZK /
                                                                            ročně</small
                                                                        ></strong
                                                                    >
                                                                </td>
                                                            </tr>
                                                            <tr
                                                                *ngIf="
                                                                    !choosenInternetTarif
                                                                "
                                                                class="border-bottom"
                                                            >
                                                                <td
                                                                    >BEZ SLUŽEB INTERNET
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <div
                                                    class="e-card-actions text-center w-100"
                                                ></div>
                                                <div
                                                    class="e-card-footer d-flex justify-content-between"
                                                >
                                                    <span>Cena za internet:</span>
                                                    <span
                                                        ><strong
                                                            >{{
                                                                (internetPrice.period ===
                                                                12
                                                                    ? internetPriceAnnualy
                                                                    : internetPrice.fee
                                                                ) | number: '1.2-2'
                                                            }}
                                                            ,- CZK /
                                                            <ng-container
                                                                *ngIf="
                                                                    internetPrice.period ===
                                                                    1
                                                                "
                                                                >měsíčně</ng-container
                                                            ><ng-container
                                                                *ngIf="
                                                                    internetPrice.period ===
                                                                    12
                                                                "
                                                                >ročně</ng-container
                                                            ></strong
                                                        ></span
                                                    >
                                                </div>
                                            </div>
                                            <div class="e-card mb-4">
                                                <div
                                                    class="e-card-header stylish-color-dark text-white"
                                                >
                                                    <div class="e-card-header-caption">
                                                        <div
                                                            class="d-flex justify-content-between e-card-header-title text-white"
                                                        >
                                                            <strong
                                                                >Wifi služba:</strong
                                                            >
                                                        </div>
                                                    </div>
                                                </div>
                                                <div
                                                    class="e-card-content text-center pt-2 pb-2 pl-1 pr-1"
                                                >
                                                    <table
                                                        class="table table-sm table-borderless"
                                                    >
                                                        <tbody>
                                                            <tr
                                                                *ngIf="
                                                                    choosenWiFiServices
                                                                "
                                                                class="border-bottom"
                                                            >
                                                                <td>{{
                                                                    choosenWiFiServices
                                                                        .hardware.name
                                                                }}</td>
                                                                <td>
                                                                    <strong
                                                                        >{{
                                                                            choosenWiFiServices
                                                                                .hardware
                                                                                .fee
                                                                                | number
                                                                                    : '1.2-2'
                                                                        }},- Kč /
                                                                        měsíčně |
                                                                        {{
                                                                            choosenWiFiServices
                                                                                .hardware
                                                                                .fee
                                                                                ? (choosenWiFiServices
                                                                                      .hardware
                                                                                      .fee *
                                                                                      12 -
                                                                                      (choosenWiFiServices
                                                                                          .hardware
                                                                                          .fee *
                                                                                          12 *
                                                                                          10) /
                                                                                          100
                                                                                  | number
                                                                                      : '1.2-2')
                                                                                : 0
                                                                        }}
                                                                        ,- CZK /
                                                                        ročně</strong
                                                                    >
                                                                </td>
                                                            </tr>
                                                            <tr
                                                                *ngIf="
                                                                    !choosenWiFiServices
                                                                "
                                                                class="border-bottom"
                                                            >
                                                                <td
                                                                    >BEZ SLUŽEB Wi-Fi
                                                                    (vlastní router)
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <div
                                                    class="e-card-actions text-center w-100"
                                                ></div>
                                                <div
                                                    class="e-card-footer d-flex justify-content-between"
                                                >
                                                    <span>Cena za Wi-Fi služby:</span>
                                                    <span
                                                        ><strong
                                                            >{{
                                                                (wifiPrice.period === 12
                                                                    ? wifiPriceAnnualy
                                                                    : wifiPrice.fee
                                                                ) | number: '1.2-2'
                                                            }}
                                                            ,- CZK /
                                                            <ng-container
                                                                *ngIf="
                                                                    wifiPrice.period ===
                                                                    1
                                                                "
                                                                >měsíčně</ng-container
                                                            ><ng-container
                                                                *ngIf="
                                                                    wifiPrice.period ===
                                                                    12
                                                                "
                                                                >ročně</ng-container
                                                            ></strong
                                                        ></span
                                                    >
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            *ngIf="clientForm.valid"
                                            class="col-12 col-lg-6"
                                        >
                                            <div class="e-card mb-4">
                                                <div
                                                    class="e-card-header stylish-color-dark text-white"
                                                >
                                                    <div class="e-card-header-caption">
                                                        <div
                                                            class="d-flex justify-content-between e-card-header-title text-white"
                                                        >
                                                            <strong>Klinet:</strong>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div
                                                    class="e-card-content text-center pt-2 pb-2 pl-1 pr-1"
                                                >
                                                    <table
                                                        class="table table-sm table-borderless"
                                                    >
                                                        <tbody>
                                                            <tr class="border-bottom">
                                                                <td
                                                                    class="text-muted"
                                                                    colspan="2"
                                                                >
                                                                    Jméno a Příjmení:
                                                                </td>
                                                                <td
                                                                    class="text-right"
                                                                    colspan="2"
                                                                >
                                                                    {{
                                                                        lead.cl_firstname
                                                                    }}
                                                                    {{
                                                                        lead.cl_lastname
                                                                    }}
                                                                </td>
                                                            </tr>
                                                            <tr class="border-bottom">
                                                                <td class="text-muted"
                                                                    >Rodné číslo:
                                                                </td>
                                                                <td class="text-right">
                                                                    {{
                                                                        lead.cl_national_id_number
                                                                    }}
                                                                </td>
                                                                <td class="text-muted"
                                                                    >IČO:
                                                                </td>
                                                                <td
                                                                    class="text-right"
                                                                    >{{
                                                                        lead.cl_vat_id
                                                                    }}</td
                                                                >
                                                            </tr>
                                                            <tr class="border-bottom">
                                                                <td
                                                                    class="text-muted"
                                                                    colspan="2"
                                                                >
                                                                    Adresa trvalého
                                                                    bydlíště:
                                                                </td>
                                                                <td
                                                                    class="text-right"
                                                                    colspan="2"
                                                                >
                                                                    {{
                                                                        lead.cl_address
                                                                    }}
                                                                </td>
                                                            </tr>
                                                            <tr class="border-bottom">
                                                                <td
                                                                    class="text-muted"
                                                                    colspan="2"
                                                                >
                                                                    Fakturační adresa:
                                                                </td>
                                                                <td
                                                                    class="text-right"
                                                                    colspan="2"
                                                                >
                                                                    {{
                                                                        lead.cl_billing_address
                                                                    }}
                                                                </td>
                                                            </tr>
                                                            <tr class="border-bottom">
                                                                <td class="text-muted"
                                                                    >Telefon:
                                                                </td>
                                                                <td
                                                                    class="text-right"
                                                                    >{{
                                                                        lead.cl_telephone
                                                                    }}</td
                                                                >
                                                            </tr>
                                                            <tr class="border-bottom">
                                                                <td class="text-muted"
                                                                    >Mobil:
                                                                </td>
                                                                <td class="text-right">
                                                                    {{
                                                                        lead.cl_mobilephone
                                                                    }}
                                                                </td>
                                                            </tr>
                                                            <tr class="border-bottom">
                                                                <td class="text-muted"
                                                                    >Kontaktní email:
                                                                </td>
                                                                <td
                                                                    class="text-right"
                                                                    >{{
                                                                        lead.cl_email
                                                                    }}</td
                                                                >
                                                                <td class="text-muted"
                                                                    >Fakturační email:
                                                                </td>
                                                                <td class="text-right">
                                                                    {{
                                                                        lead.cl_billing_email
                                                                    }}
                                                                </td>
                                                            </tr>
                                                            <tr class="border-bottom">
                                                                <td class="text-muted"
                                                                    >Obchodní email:
                                                                </td>
                                                                <td class="text-right">
                                                                    {{
                                                                        lead.cl_business_email
                                                                    }}
                                                                </td>
                                                                <td class="text-muted"
                                                                    >Technický email:
                                                                </td>
                                                                <td class="text-right">
                                                                    {{
                                                                        lead.cl_technical_email
                                                                    }}
                                                                </td>
                                                            </tr>
                                                            <tr class="border-bottom">
                                                                <td class="text-muted"
                                                                    >Číslo bankovního
                                                                    účtu:
                                                                </td>
                                                                <td class="text-right">
                                                                    {{
                                                                        lead.cl_account_number
                                                                    }}
                                                                </td>
                                                                <td class="text-muted"
                                                                    >Číslo banky:
                                                                </td>
                                                                <td class="text-right">
                                                                    {{
                                                                        lead.cl_bank_number
                                                                    }}
                                                                </td>
                                                            </tr>
                                                            <tr class="border-bottom">
                                                                <td class="text-muted"
                                                                    >Faktury zasílat
                                                                    poštou:
                                                                </td>
                                                                <td class="text-right">
                                                                    {{
                                                                        lead.cl_send_invoices_by_post
                                                                            ? 'ano'
                                                                            : 'ne'
                                                                    }}
                                                                </td>
                                                                <td class="text-muted">
                                                                    Faktury zasílat
                                                                    elektronicky:
                                                                </td>
                                                                <td class="text-right">
                                                                    {{
                                                                        lead.cl_send_invoice_by_email
                                                                            ? 'ano'
                                                                            : 'ne'
                                                                    }}
                                                                </td>
                                                            </tr>
                                                            <tr class="border-bottom">
                                                                <td class="text-muted"
                                                                    >Roční předplatné:
                                                                </td>
                                                                <td class="text-right">
                                                                    {{
                                                                        lead.invoice_by_year
                                                                            ? 'ano'
                                                                            : 'ne'
                                                                    }}
                                                                </td>
                                                                <td class="text-muted"
                                                                    >Přednostní
                                                                    instalace:
                                                                </td>
                                                                <td class="text-right">
                                                                    {{
                                                                        lead.vip_install
                                                                            ? 'ano'
                                                                            : 'ne'
                                                                    }}
                                                                </td>
                                                            </tr>
                                                            <tr class="border-bottom">
                                                                <td class="text-muted"
                                                                    >Smluvní úvazek:
                                                                </td>
                                                                <td
                                                                    class="text-right"
                                                                    >{{
                                                                        lead.deal_age
                                                                    }}</td
                                                                >
                                                                <td class="text-muted"
                                                                    >Provider:
                                                                </td>
                                                                <td
                                                                    class="text-right"
                                                                    >{{
                                                                        lead.provider
                                                                            .name
                                                                    }}</td
                                                                >
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="e-card mb-4">
                                        <div
                                            class="e-card-header stylish-color-dark text-white"
                                        >
                                            <div class="e-card-header-caption">
                                                <div
                                                    class="d-flex justify-content-between e-card-header-title text-white"
                                                >
                                                    <strong>Televize:</strong>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            class="e-card-content text-center pt-2 pb-2 pl-1 pr-1"
                                        >
                                            <table
                                                class="table table-sm table-borderless"
                                            >
                                                <tbody>
                                                    <tr
                                                        *ngIf="choosenTelevisionTarif"
                                                        class="border-bottom"
                                                    >
                                                        <td>tarif:</td>
                                                        <td class="text-center">
                                                            {{
                                                                choosenTelevisionTarif.name
                                                            }}
                                                        </td>
                                                        <td class="text-right">
                                                            <strong
                                                                >{{
                                                                    choosenTelevisionTarif.pernament_price
                                                                        | number
                                                                            : '1.2-2'
                                                                }},- Kč / měsíčně |
                                                                <small
                                                                    class="text-muted"
                                                                    >{{
                                                                        choosenTelevisionTarif.pernament_price
                                                                            ? (choosenTelevisionTarif.pernament_price *
                                                                                  12 -
                                                                                  (choosenTelevisionTarif.pernament_price *
                                                                                      12 *
                                                                                      10) /
                                                                                      100
                                                                              | number
                                                                                  : '1.2-2')
                                                                            : 0
                                                                    }}
                                                                    ,- CZK /
                                                                    ročně</small
                                                                ></strong
                                                            >
                                                        </td>
                                                    </tr>
                                                    <tr
                                                        *ngIf="!choosenTelevisionTarif"
                                                        class="border-bottom"
                                                    >
                                                        <td>BEZ SLUŽEB TELEVIZE</td>
                                                    </tr>
                                                    <ng-container
                                                        *ngIf="
                                                            choosenTelevisionPackages
                                                        "
                                                    >
                                                        <tr class="text-muted">
                                                            <td colspan="3"
                                                                >balíček:
                                                            </td>
                                                        </tr>
                                                        <tr class="text-muted">
                                                            <td colspan="2">
                                                                {{
                                                                    choosenTelevisionPackages.name
                                                                }}
                                                            </td>
                                                            <td class="text-right">
                                                                <strong
                                                                    >{{
                                                                        choosenTelevisionPackages.price
                                                                            | number
                                                                                : '1.2-2'
                                                                    }},- Kč / měsíčně |
                                                                    <small
                                                                        class="text-muted"
                                                                        >{{
                                                                            choosenTelevisionPackages.price
                                                                                ? (choosenTelevisionPackages.price *
                                                                                      12 -
                                                                                      (choosenTelevisionPackages.price *
                                                                                          12 *
                                                                                          10) /
                                                                                          100
                                                                                  | number
                                                                                      : '1.2-2')
                                                                                : 0
                                                                        }}
                                                                        ,- CZK /
                                                                        ročně</small
                                                                    ></strong
                                                                >
                                                            </td>
                                                        </tr>
                                                    </ng-container>
                                                    <ng-container
                                                        *ngIf="
                                                            choosenTelevisionExtensions &&
                                                            choosenTelevisionExtensions.length >
                                                                0
                                                        "
                                                    >
                                                        <tr
                                                            class="text-muted border-top"
                                                        >
                                                            <td colspan="3"
                                                                >rozšíření:
                                                            </td>
                                                        </tr>
                                                        <tr
                                                            *ngFor="
                                                                let tvExtensions of choosenTelevisionExtensions
                                                            "
                                                            class="text-muted"
                                                        >
                                                            <td colspan="2">{{
                                                                tvExtensions.name
                                                            }}</td>
                                                            <td class="text-right">
                                                                <strong
                                                                    >{{
                                                                        tvExtensions.price
                                                                            | number
                                                                                : '1.2-2'
                                                                    }},- CZK / měsíčně |
                                                                    <small
                                                                        class="text-muted"
                                                                        >{{
                                                                            tvExtensions.price
                                                                                ? (tvExtensions.price *
                                                                                      12 -
                                                                                      (tvExtensions.price *
                                                                                          12 *
                                                                                          10) /
                                                                                          100
                                                                                  | number
                                                                                      : '1.2-2')
                                                                                : 0
                                                                        }}
                                                                        ,- CZK /
                                                                        ročně</small
                                                                    ></strong
                                                                >
                                                            </td>
                                                        </tr>
                                                    </ng-container>
                                                    <ng-container
                                                        *ngIf="
                                                            smartTvForm.valid &&
                                                            lead?.tvs.length > 0
                                                        "
                                                    >
                                                        <tr
                                                            class="text-muted border-top"
                                                        >
                                                            <td colspan="3"
                                                                >Televize (zařízení):
                                                            </td>
                                                        </tr>
                                                        <tr
                                                            *ngFor="
                                                                let tv of lead.tvs_list
                                                            "
                                                            class="text-muted"
                                                        >
                                                            <td colspan="2">{{
                                                                tv.tv
                                                            }}</td>
                                                            <td>
                                                                <small
                                                                    *ngIf="tv.settopbox"
                                                                    >+ settopbox /
                                                                    androidbox</small
                                                                >
                                                            </td>
                                                            <td class="text-right">
                                                                <strong
                                                                    >{{
                                                                        (tv.settopbox_fee
                                                                            ? 99
                                                                            : 0
                                                                        )
                                                                            | number
                                                                                : '1.2-2'
                                                                    }},- CZK / měsíčně |
                                                                    <small
                                                                        class="text-muted"
                                                                        >{{
                                                                            tv.settopbox_fee
                                                                                ? (99 *
                                                                                      12 -
                                                                                      (99 *
                                                                                          12 *
                                                                                          10) /
                                                                                          100
                                                                                  | number
                                                                                      : '1.2-2')
                                                                                : 0
                                                                        }}
                                                                        ,- CZK /
                                                                        ročně</small
                                                                    ></strong
                                                                >
                                                            </td>
                                                        </tr>
                                                    </ng-container>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div
                                            class="e-card-actions text-center w-100"
                                        ></div>
                                        <div
                                            class="e-card-footer d-flex justify-content-between"
                                        >
                                            <span>Cena za televizi:</span>
                                            <span
                                                ><strong
                                                    >{{
                                                        (televisionPrice.period === 12
                                                            ? televisionPriceAnnualy
                                                            : televisionPrice.fee
                                                        ) | number: '1.2-2'
                                                    }}
                                                    ,- CZK /
                                                    <ng-container
                                                        *ngIf="
                                                            televisionPrice.period === 1
                                                        "
                                                        >měsíčně
                                                    </ng-container>
                                                    <ng-container
                                                        *ngIf="
                                                            televisionPrice.period ===
                                                            12
                                                        "
                                                        >ročně
                                                    </ng-container>
                                                </strong></span
                                            >
                                        </div>
                                    </div>
                                    <div class="e-card mb-4">
                                        <div
                                            class="e-card-header stylish-color-dark text-white"
                                        >
                                            <div class="e-card-header-caption">
                                                <div
                                                    class="d-flex justify-content-between e-card-header-title text-white"
                                                >
                                                    <strong>Doplňkové služby:</strong>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            class="e-card-content text-center pt-2 pb-2 pl-1 pr-1"
                                        >
                                            <table
                                                class="table table-sm table-borderless"
                                            >
                                                <tbody>
                                                    <tr
                                                        *ngFor="
                                                            let extService of choosenExtendedServices
                                                        "
                                                        class="text-muted"
                                                    >
                                                        <td colspan="2">{{
                                                            extService.name
                                                        }}</td>
                                                        <td class="text-right">
                                                            <strong
                                                                >{{
                                                                    extService.price
                                                                        | number
                                                                            : '1.2-2'
                                                                }},- CZK / měsíčně |
                                                                <small
                                                                    class="text-muted"
                                                                    >{{
                                                                        extService.price
                                                                            ? (extService.price *
                                                                                  12 -
                                                                                  (extService.price *
                                                                                      12 *
                                                                                      10) /
                                                                                      100
                                                                              | number
                                                                                  : '1.2-2')
                                                                            : 0
                                                                    }}
                                                                    ,- CZK /
                                                                    ročně</small
                                                                ></strong
                                                            >
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div
                                            class="e-card-actions text-center w-100"
                                        ></div>
                                        <div
                                            class="e-card-footer d-flex justify-content-between"
                                        >
                                            <span>Cena za doplňkové služby:</span>
                                            <span
                                                ><strong
                                                    >{{
                                                        (extServicesPrice.period === 12
                                                            ? extServicesPriceAnnualy
                                                            : extServicesPrice.fee
                                                        ) | number: '1.2-2'
                                                    }}
                                                    ,- CZK /
                                                    <ng-container
                                                        *ngIf="
                                                            extServicesPrice.period ===
                                                            1
                                                        "
                                                        >měsíčně</ng-container
                                                    ><ng-container
                                                        *ngIf="
                                                            extServicesPrice.period ===
                                                            12
                                                        "
                                                        >ročně</ng-container
                                                    ></strong
                                                ></span
                                            >
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="addressForm.invalid">
                                    <span
                                        ><strong class="text-danger"
                                            >Vyplňte správně 1. krok</strong
                                        ></span
                                    >
                                </div>
                                <div class="step-actions">
                                    <button
                                        (click)="onSubmit()"
                                        [disabled]="addressForm.invalid"
                                        class="e-btn e-primary btn-p-zero e-small"
                                        ejs-button
                                        type="button"
                                    >
                                        Odeslat objednávku
                                    </button>
                                </div>
                            </mdb-step>
                        </mdb-stepper>
        -->
      </div>
    </div>
  </div>
  <div class="d-none d-md-block col-md-4 col-lg-3">
    <div class="e-card p-1 z-depth-1">
      <div
        class="e-card-content p-0 scrollbar scrollbar-dusty-grass thin"
        style="
                    height: 600px;
                    overflow-y: auto;
                    overflow-x: hidden;
                    font-size: 10px !important;
                "
      >
        <h6 class="p-1">
          <i
            aria-hidden="true"
            class="mr-2 fa-light fa-lg fa-pennant"
          ></i
          >Lead:
          <span
            *ngIf="lastLeadUpdate"
            class="e-badge e-badge-success float-right"
          >poslední změna:&nbsp;{{
              lastLeadUpdate | dateFormat: 'DD.MM.YYYY HH:mm'
            }}</span
          >
          <span
            *ngIf="lead && lead.stmnt_id && lead.cl_cnumber"
            class="e-badge e-badge-warning float-right"
          >stávající klient</span
          >
          <span
            *ngIf="!lead || (!lead.stmnt_id && !lead.cl_cnumber)"
            class="e-badge e-badge-success float-right"
          >nový klient</span
          >
        </h6>
        <div class="e-card-separator"></div>
        <ul
          #hintArea
          *ngIf="lead && lead.id"
          class="list-group list-group-flush"
        >
          <li class="list-group-item">ID: {{ lead.id }}</li>
          <li class="list-group-item">
            CRM ID: {{ lead.crm_id }}&nbsp;
            <span
              *ngIf="!lead.crm_id"
              class="e-badge e-badge-danger float-right"
            >není v CRM</span
            >
          </li>
          <li class="list-group-item">
            SITE MONITOR ID: {{ lead.stmnt_id }}&nbsp;
            <span
              *ngIf="!lead.stmnt_id"
              class="e-badge e-badge-danger float-right"
            >není v SITE MONITORINGU</span
            >
          </li>
        </ul>
        <div class="e-card-separator"></div>
        <h6 class="p-1">
          <i
            aria-hidden="true"
            class="mr-2 fa-light fa-lg fa-question"
          ></i
          >Nápověda
        </h6>
        <div class="e-card-separator"></div>
        <p
          #hintArea
          *ngIf="hintText && hintText.length > 0"
          [innerHTML]="hintText"
          class="text-info text-center"
        ></p>
        <p
          *ngIf="!hintText || hintText.length === 0"
          class="text-muted text-center"
          style="height: 12%"
        >
          <small>Zatím nebyla zvolena nápověda...</small>
        </p>
        <h6 class="p-1">
          <i
            aria-hidden="true"
            class="mr-2 fa-light fa-lg fa-clipboard-list-check"
          ></i
          >Shrnutí
        </h6>
        <div class="e-card-separator"></div>
        <ul class="list-group mb-4">
          <li
            [ngClass]="
                            addressForm.valid &&
                            (addressFormCtrl.cwAddress.dirty ||
                                addressFormCtrl.cwAddress.touched)
                                ? 'list-group-item-success'
                                : addressForm.invalid &&
                                  (addressFormCtrl.cwAddress.dirty ||
                                      addressFormCtrl.cwAddress.touched)
                                ? 'list-group-item-danger'
                                : 'list-group-item-warning'
                        "
            class="list-group-item p-1"
          >
            <div class="d-flex justify-content-between mb-2">
                            <span>
                                <i
                                  *ngIf="matchedUntrustedAddresses"
                                  aria-hidden="true"
                                  class="fa-light fa-lg fa-exclamation-triangle mr-2 text-danger"
                                ></i>
                                <i
                                  aria-hidden="true"
                                  class="mr-2 fa-light fa-lg fa-home"
                                ></i
                                >Adresa instalace
                            </span>
              <span>
                          <!--      <button
                                    (click)="stepper.setNewActiveStep(0)"
                                    cssClass="e-btn e-primary btn-p-zero e-small m-0"
                                    ejs-button
                                >
                                    <i
                                        aria-hidden="true"
                                        class="fa-light fa-1x fa-search"
                                    ></i>
                                </button>-->
                            </span>
            </div>
            <span
              *ngIf="
                                addressObj.searchedAddress &&
                                addressObj.searchedAddress.length > 0
                            "
              class="float-right"
            >
                            {{ addressObj.searchedAddress }}
                        </span>
          </li>
          <li
            [ngClass]="
                            choosenInternetTarif || skipNetTarif
                                ? 'list-group-item-success'
                                : 'list-group-item-warning'
                        "
            class="list-group-item p-1"
          >
            <div class="d-flex justify-content-between mb-2">
                            <span>
                                <i
                                  aria-hidden="true"
                                  class="mr-2 fa-light fa-lg fa-globe-europe"
                                ></i
                                >Internet
                            </span>
              <span>
                               <!-- <button
                                    (click)="stepper.setNewActiveStep(1)"
                                    cssClass="e-btn e-primary btn-p-zero e-small m-0"
                                    ejs-button
                                >
                                    <i
                                        aria-hidden="true"
                                        class="fa-light fa-1x fa-search"
                                    ></i>
                                </button>-->
                            </span>
            </div>
            <span
              *ngIf="choosenInternetTarif"
              class="d-flex justify-content-between"
            >
                            <small class="float-left">{{
                                choosenInternetTarif.name
                              }}</small>
                            <strong class="float-right"
                            >{{
                                (internetPrice.period===12
                                    ? internetPriceAnnualy
                                    :internetPrice.fee
                                ) | number: '1.2-2'
                              }}
                              ,- CZK /
                                <ng-container *ngIf="internetPrice.period === 1"
                                >měsíčně</ng-container
                                >
                                <ng-container
                                  *ngIf="internetPrice.period === 12"
                                >ročně</ng-container
                                >
                            </strong>
                        </span>
            <span
              *ngIf="!choosenInternetTarif"
              class="d-flex justify-content-end"
            >bez NET služby</span
            >
          </li>
          <li class="list-group-item p-1 list-group-item-success">
            <div class="d-flex justify-content-between mb-2">
                            <span>
                                <i
                                  aria-hidden="true"
                                  class="mr-2 fa-light fa-lg fa-tv"
                                ></i
                                >Televize
                            </span>
              <span>
                         <!--       <button
                                    (click)="stepper.setNewActiveStep(2)"
                                    cssClass="e-btn e-primary btn-p-zero e-small m-0"
                                    ejs-button
                                >
                                    <i
                                        aria-hidden="true"
                                        class="fa-light fa-1x fa-search"
                                    ></i>
                                </button>-->
                            </span>
            </div>
            <span
              *ngIf="choosenTelevisionTarif"
              class="d-flex justify-content-between"
            >
                            <small class="float-left">{{
                                choosenTelevisionTarif.name
                              }}</small>
                            <strong class="float-right"
                            >{{
                                (televisionPrice.period===12
                                    ? televisionPriceAnnualy
                                    :televisionPrice.fee
                                ) | number: '1.2-2'
                              }}
                              ,- CZK /
                                <ng-container
                                  *ngIf="televisionPrice.period === 1"
                                >měsíčně</ng-container
                                >
                                <ng-container
                                  *ngIf="televisionPrice.period === 12"
                                >ročně</ng-container
                                >
                            </strong>
                        </span>
            <span
              *ngIf="!choosenTelevisionTarif"
              class="d-flex justify-content-end"
            >bez TV služby</span
            >
          </li>
          <li class="list-group-item p-1 list-group-item-success">
            <div class="d-flex justify-content-between mb-2">
                            <span>
                                <i
                                  aria-hidden="true"
                                  class="mr-2 fa-light fa-lg fa-tv"
                                ></i
                                >Televize (zařízení):
                            </span>
              <span>
                               <!-- <button
                                    (click)="stepper.setNewActiveStep(2)"
                                    cssClass="e-btn e-primary btn-p-zero e-small m-0"
                                    ejs-button
                                >
                                    <i
                                        aria-hidden="true"
                                        class="fa-light fa-1x fa-search"
                                    ></i>
                                </button>-->
                            </span>
            </div>
            <ng-container
              *ngIf="smartTvForm.valid && lead?.tvs.length > 0"
            >
                            <span
                              *ngFor="let tv of lead.tvs_list"
                              class="d-flex justify-content-between"
                            >
                                <small>{{ tv.tv }}</small>
                                <small *ngIf="tv.settopbox"
                                >+ settopbox / androidbox</small
                                >
                                <strong
                                >{{
                                    (tv.settopbox_fee ? 99:0)
                                      | number: '1.2-2'
                                  }},- CZK / měsíčně |
                                    <small class="text-muted"
                                    >{{
                                        tv.settopbox_fee
                                          ? (99 * 12 -
                                          (99 * 12 * 10) / 100
                                            | number: '1.2-2')
                                          :0
                                      }}
                                      ,- CZK / ročně</small
                                    ></strong
                                >
                            </span>
            </ng-container>
            <ng-container
              *ngIf="smartTvForm.valid && tvsList.length === 0"
            >
                            <span class="d-flex justify-content-end">
                                bez TV zařízení
                            </span>
            </ng-container>
          </li>
          <li class="list-group-item p-1 list-group-item-success">
            <div class="d-flex justify-content-between mb-2">
                            <span
                            ><i
                              aria-hidden="true"
                              class="mr-2 fa-light fa-lg fa-tv"
                            ></i
                            >TV Balíček</span
                            >
              <span
              ><!--<button
                                    (click)="stepper.setNewActiveStep(2)"
                                    cssClass="e-btn e-primary btn-p-zero e-small m-0"
                                    ejs-button
                                >
                                    <i
                                        aria-hidden="true"
                                        class="fa-light fa-1x fa-search"
                                    ></i></button
                            >--></span>
            </div>
            <span
              *ngIf="choosenTelevisionPackages"
              class="d-flex justify-content-between"
            >
                            <small class="float-left">{{
                                choosenTelevisionPackages.name
                              }}</small>
                            <strong class="float-right"
                            >{{
                                choosenTelevisionPackages.price
                                  | number: '1.2-2'
                              }}
                              ,- CZK</strong
                            >
                        </span>
            <span
              *ngIf="!choosenTelevisionPackages"
              class="d-flex justify-content-end"
            >bez TV balíčků</span
            >
          </li>
          <li class="list-group-item p-1 list-group-item-success">
            <div class="d-flex justify-content-between mb-2">
                            <span>
                                <i
                                  aria-hidden="true"
                                  class="mr-2 fa-light fa-lg fa-tv"
                                ></i
                                >Rozšíření televize:
                            </span>
              <span>
                              <!--  <button
                                    (click)="stepper.setNewActiveStep(2)"
                                    cssClass="e-btn e-primary btn-p-zero e-small m-0"
                                    ejs-button
                                >
                                    <i
                                        aria-hidden="true"
                                        class="fa-light fa-1x fa-search"
                                    ></i>
                                </button>-->
                            </span>
            </div>
            <ng-container *ngIf="choosenTelevisionExtensions">
                            <span
                              *ngFor="
                                    let chsnTvService of choosenTelevisionExtensions
                                "
                              class="d-flex justify-content-between"
                            >
                                <small class="float-left">{{
                                    chsnTvService.name
                                  }}</small>
                                <strong class="float-right"
                                >{{
                                    chsnTvService.price | number: '1.2-2'
                                  }},- CZK</strong
                                >
                            </span>
            </ng-container>
            <ng-container
              *ngIf="
                                !choosenTelevisionExtensions ||
                                choosenTelevisionExtensions.length === 0
                            "
            >
                            <span class="d-flex justify-content-end">
                                bez TV rozšíření
                            </span>
            </ng-container>
          </li>
          <li
            class="list-group-item p-1 list-group-item p-1 list-group-item-success"
          >
            <div class="d-flex justify-content-between mb-2">
                            <span>
                                <i
                                  aria-hidden="true"
                                  class="mr-2 fa-light fa-lg fa-wifi"
                                ></i
                                >Wi-Fi služby
                            </span>
              <span>
                             <!--   <button
                                    (click)="stepper.setNewActiveStep(3)"
                                    cssClass="e-btn e-primary btn-p-zero e-small m-0"
                                    ejs-button
                                >
                                    <i
                                        aria-hidden="true"
                                        class="fa-light fa-1x fa-search"
                                    ></i>
                                </button>-->
                            </span>
            </div>
            <ng-container *ngIf="choosenWiFiServices">
                            <span class="d-flex justify-content-between">
                                <small class="float-left">{{
                                    choosenWiFiServices.hardware.name
                                  }}</small>
                                <strong
                                  *ngIf="choosenWiFiServices"
                                  class="float-right"
                                >{{
                                    choosenWiFiServices.hardware.fee
                                      ? (choosenWiFiServices.hardware.fee
                                        | number: '1.2-2')
                                      :0
                                  }}
                                  ,- CZK / paušál</strong
                                >
                                <strong
                                  *ngIf="choosenWiFiServices"
                                  class="float-right"
                                >{{
                                    choosenWiFiServices.hardware.price
                                      ? (choosenWiFiServices.hardware
                                        .price | number: '1.2-2')
                                      :0
                                  }}
                                  ,- CZK / prodej</strong
                                >
                            </span>
            </ng-container>
            <ng-container *ngIf="!choosenWiFiServices">
                            <span class="d-flex justify-content-end"
                            >bez Wi-Fi služeb / vlastní router</span
                            >
            </ng-container>
          </li>
          <li class="list-group-item p-1 list-group-item-success">
            <div class="d-flex justify-content-between mb-2">
                            <span>
                                <i
                                  aria-hidden="true"
                                  class="mr-2 fa-light fa-lg fa-boxes"
                                ></i
                                >Doplňkové služby
                            </span>
              <span>
                              <!--  <button
                                    (click)="stepper.setNewActiveStep(1)"
                                    cssClass="e-btn e-primary btn-p-zero e-small m-0"
                                    ejs-button
                                >
                                    <i
                                        aria-hidden="true"
                                        class="fa-light fa-1x fa-search"
                                    ></i>
                                    NET
                                </button>
                                <button
                                    (click)="stepper.setNewActiveStep(2)"
                                    cssClass="e-btn e-primary btn-p-zero e-small m-0"
                                    ejs-button
                                >
                                    <i
                                        aria-hidden="true"
                                        class="fa-light fa-1x fa-search"
                                    ></i>
                                    TV
                                </button>-->
                            </span>
            </div>
            <ng-container
              *ngIf="
                                choosenExtendedServices &&
                                choosenExtendedServices.length > 0
                            "
            >
                            <span
                              *ngFor="
                                    let chsnService of choosenExtendedServices
                                "
                              class="d-flex justify-content-between"
                            >
                                {{ chsnService.name }}
                              <strong
                              >{{ chsnService.price | number: '1.2-2' }},-
                                    CZK</strong
                              >
                            </span>
            </ng-container>
            <ng-container
              *ngIf="
                                !choosenExtendedServices ||
                                choosenExtendedServices.length === 0
                            "
            >
                            <span class="d-flex justify-content-end">
                                bez NET rozšíření
                            </span>
            </ng-container>
          </li>
          <li
            [ngClass]="
                            clientForm.valid && clientForm.dirty
                                ? 'list-group-item-success'
                                : clientForm.invalid && clientForm.dirty
                                ? 'list-group-item-danger'
                                : 'list-group-item-warning'
                        "
            class="list-group-item p-1"
          >
            <div class="d-flex justify-content-between mb-2">
                            <span>
                                <i
                                  aria-hidden="true"
                                  class="mr-2 fa-light fa-lg fa-user"
                                ></i
                                >Klient
                            </span>
              <span>
                              <!--  <button
                                    (click)="stepper.setNewActiveStep(4)"
                                    cssClass="e-btn e-primary btn-p-zero e-small m-0"
                                    ejs-button
                                >
                                    <i
                                        aria-hidden="true"
                                        class="fa-light fa-1x fa-search"
                                    ></i>
                                </button>-->
                            </span>
            </div>
            <ng-container
              *ngIf="
                                clientFormCtrl.firstname.valid &&
                                clientFormCtrl.lastname.valid
                            "
            >
              <small class="float-right">
                {{
                  clientFormCtrl.firstname.value +
                  ' ' +
                  clientFormCtrl.lastname.value
                }}
              </small>
              <br />
            </ng-container>
            <ng-container
              *ngIf="
                                clientFormCtrl.email.valid &&
                                clientFormCtrl.email.value
                            "
            >
              <small class="float-right">
                {{ clientFormCtrl.email.value }}
              </small>
              <br />
            </ng-container>
            <ng-container
              *ngIf="
                                (clientFormCtrl.telephone.valid &&
                                    clientFormCtrl.telephone.value) ||
                                (clientFormCtrl.mobile.valid &&
                                    clientFormCtrl.mobile.value)
                            "
            >
              <small class="float-right">
                {{
                  clientFormCtrl.telephone.value
                    ? clientFormCtrl.telephone.value
                    :clientFormCtrl.mobile.value
                }}
              </small>
              <br />
            </ng-container>
            <ng-container *ngIf="clientFormCtrl.vipInstall.valid">
              <small class="float-right">
                {{
                  clientFormCtrl.vipInstall.value
                    ? 'přednostní instalace'
                    :'standardní instalace'
                }}
              </small>
              <br />
            </ng-container>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
<div class="d-flex w-100" style="position: absolute; bottom: 0">
  <div class="e-card p-0 z-depth-1">
    <div class="row">
      <div class="col-2 skewed_bg p-1 pl-3 pr-3">
        <div class="d-flex justify-content-between">
          <ejs-tooltip content="Internet služby">
            <i
              [ngClass]="
                                internetPrice.fee && internetPrice.fee > 0
                                    ? 'text-success'
                                    : 'text-warning'
                            "
              aria-hidden="true"
              class="fa-light fa-2x fa-globe-europe mr-2"
            ></i>
            <span
              *ngIf="internetPrice.discount + discount > 0"
              class="e-badge e-badge-danger mr-2"
            >Sleva: {{ internetPrice.discount + discount }} % z
                            ceny</span
            >
          </ejs-tooltip>
          <strong class="pt-1 text-right"
          >{{
              (internetPrice.period===12
                  ? internetPriceAnnualy
                  :internetPrice.fee
              ) | number: '1.2-2'
            }}
            ,- CZK<small class="text-muted">
              /
              <ng-container *ngIf="internetPrice.period === 1"
              >měsíčně
              </ng-container>
              <ng-container *ngIf="internetPrice.period === 12">
                ročně<br />ušetřeno:&nbsp;{{
                  (internetPriceAnnualy *
                    (discount + televisionPrice.discount)) /
                  (100 -
                    (discount +
                      televisionPrice.discount))
                    | number: '1.2-2'
                }}
                ,- CZK
              </ng-container>
            </small></strong
          >
        </div>
      </div>
      <div class="col-2 skewed_bg p-1 pl-3 pr-3">
        <div class="d-flex justify-content-between">
          <ejs-tooltip content="TV služby">
            <i
              [ngClass]="
                                televisionPrice.fee && televisionPrice.fee > 0
                                    ? 'text-success'
                                    : 'text-warning'
                            "
              aria-hidden="true"
              class="fa-light fa-2x fa-tv mr-2"
            ></i>
            <span
              *ngIf="televisionPrice.discount + discount > 0"
              class="e-badge e-badge-danger mr-2"
            >Sleva: {{ televisionPrice.discount + discount }} %
                            z ceny</span
            >
          </ejs-tooltip>
          <small
            *ngIf="setttopboxFee > 0 || setttopboxFeeAnnualy > 0"
            class="text-muted"
          >+ settop box</small
          >
          <strong class="pt-1 text-right">
            {{
              (televisionPrice.period===12
                  ? televisionPriceAnnualy + setttopboxFeeAnnualy
                  :televisionPrice.fee + setttopboxFee
              ) | number: '1.2-2'
            }}
            ,- CZK<small class="text-muted">
            /
            <ng-container *ngIf="televisionPrice.period === 1"
            >měsíčně
            </ng-container>
            <ng-container *ngIf="televisionPrice.period === 12">
              ročně<br />ušetřeno:&nbsp;{{
                ((televisionPriceAnnualy +
                    setttopboxFeeAnnualy) *
                  (discount + televisionPrice.discount)) /
                (100 -
                  (discount +
                    televisionPrice.discount))
                  | number: '1.2-2'
              }}
              ,- CZK
            </ng-container>
          </small></strong
          >
        </div>
      </div>
      <div class="col-2 skewed_bg p-1 pl-3 pr-3">
        <div class="d-flex justify-content-between">
          <ejs-tooltip content="Wi-Fi služby">
            <i
              [ngClass]="
                                wifiPrice.fee && wifiPrice.fee > 0
                                    ? 'text-success'
                                    : 'text-warning'
                            "
              aria-hidden="true"
              class="fa-light fa-2x fa-wifi mr-2"
            ></i>
            <span
              *ngIf="wifiPrice.discount + discount > 0"
              class="e-badge e-badge-danger mr-2"
            >Sleva: {{ wifiPrice.discount + discount }} % z
                            ceny</span
            >
          </ejs-tooltip>
          <small class="text-muted">+ router</small>
          <strong class="pt-1 text-right"
          >{{
              (wifiPrice.period===12
                  ? wifiPriceAnnualy
                  :wifiPrice.fee
              ) | number: '1.2-2'
            }}
            ,- CZK<small class="text-muted">
              /
              <ng-container *ngIf="wifiPrice.period === 1"
              >měsíčně
              </ng-container>
              <ng-container *ngIf="wifiPrice.period === 12">
                ročně<br />ušetřeno:&nbsp;{{
                  (wifiPriceAnnualy *
                    (discount + wifiPrice.discount)) /
                  (100 - (discount + wifiPrice.discount))
                    | number: '1.2-2'
                }}
                ,- CZK
              </ng-container>
            </small></strong
          >
        </div>
      </div>
      <div class="col-2 skewed_bg p-1 pl-3 pr-3">
        <div class="d-flex justify-content-between">
          <ejs-tooltip content="Doplňkové služby">
            <i
              [ngClass]="
                                extServicesPrice.fee && extServicesPrice.fee > 0
                                    ? 'text-success'
                                    : 'text-warning'
                            "
              aria-hidden="true"
              class="fa-light fa-2x fa-boxes mr-2"
            ></i>
            <span
              *ngIf="extServicesPrice.discount + discount > 0"
              class="e-badge e-badge-danger mr-2"
            >Sleva: {{ extServicesPrice.discount + discount }} %
                            z ceny</span
            >
          </ejs-tooltip>
          <strong class="pt-1 text-right"
          >{{
              (extServicesPrice.period===12
                  ? extServicesPriceAnnualy
                  :extServicesPrice.fee
              ) | number: '1.2-2'
            }}
            ,- CZK<small class="text-muted">
              /
              <ng-container *ngIf="extServicesPrice.period === 1"
              >měsíčně
              </ng-container>
              <ng-container
                *ngIf="extServicesPrice.period === 12"
              >
                ročně<br />ušetřeno:&nbsp;{{
                  (extServicesPriceAnnualy *
                    (discount +
                      extServicesPrice.discount)) /
                  (100 -
                    (discount +
                      extServicesPrice.discount))
                    | number: '1.2-2'
                }}
                ,- CZK
              </ng-container>
            </small></strong
          >
        </div>
      </div>
      <div class="col-4 text-right pt-2">
                <span *ngIf="discount > 0" class="e-badge e-badge-danger mr-2"
                >Sleva: {{ discount }}% z ceny</span
                >
        <strong
        >Celkem: {{ totalPrice.fee | number: '1.2-2' }},- CZK s DPH
          <small class="text-muted">
            /
            <ng-container *ngIf="totalPrice.period === 1">
              měsíčně
            </ng-container>
            <ng-container *ngIf="totalPrice.period === 12">
              ročně (ušetřeno:&nbsp;{{
                (totalPrice.fee * discount) / (100 - discount)
                  | number: '1.2-2'
              }}
              ,- CZK)
            </ng-container>
          </small> </strong
        >&nbsp;|&nbsp;
        <strong
        >Celkem: {{ totalPrice.price | number: '1.2-2' }},- CZK s
          DPH
          <small class="text-muted"> / jednorázově</small>
        </strong>
      </div>
    </div>
  </div>
</div>
