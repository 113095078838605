import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnInit,
    ViewChild,
} from '@angular/core';
import {AbstractControl, FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {OrderItem} from '@src/app/_models/material/material-item';
import {MaterialBudget} from '@src/app/_models/material/material-budget';
import {MaterialPayment} from '@src/app/_models/material/material-payment';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthenticationService} from '@src/app/_services/authentication.service';
import {MessageService} from '@src/app/_services/message.service';
import {Department} from '@src/app/_models/department/department';
import {User} from '@src/app/_models/user/user';
import {Material} from '@src/app/_models/material/material';
import {DataService} from '@src/app/_services/data.service';
import {EmitType} from '@syncfusion/ej2-base';
import {DropDownListComponent, FilteringEventArgs} from '@syncfusion/ej2-angular-dropdowns';
import {Query} from '@syncfusion/ej2-data';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {MaterialService, OrderInput} from '@src/app/features/material/material.service';
import {SettingsService} from '@src/app/features/settings/settings.service';

@UntilDestroy()
@Component({
    selector: 'app-material-applications',
    templateUrl: './material-applications.component.html',
    styleUrls: ['./material-applications.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MaterialApplicationsComponent implements OnInit {
    // Dropdowms
    height = '240px';

    fields: object = {text: 'label', value: 'value'};

    paymentSelect: Array<{value: boolean | number | string; label: string}> = [];

    departmentSelect: Array<{value: boolean | number | string; label: string}> = [];

    categorySelect: Array<{value: boolean | number | string; label: string}> = [];

    currentUser: User | null;

    createMaterialForm: FormGroup;

    orderItemsList: FormArray;

    order: OrderInput;

    orderItems: OrderItem[] = [];

    budgets?: MaterialBudget[];

    payments?: MaterialPayment[];

    budget?: MaterialBudget | null;

    loadingMaterial = false;

    submited = false;

    // Dropdowms
    @ViewChild('payment') paymentObj: DropDownListComponent;

    @ViewChild('department') departmentObj: DropDownListComponent;

    @ViewChild('category') categoryObj: DropDownListComponent;

    constructor(
        private readonly formBuilder: FormBuilder,
        private readonly route: ActivatedRoute,
        private readonly router: Router,
        private readonly authenticationService: AuthenticationService,
        private readonly materialsService: MaterialService,
        private readonly settingsService: SettingsService,
        private readonly dataService: DataService,
        private readonly ref: ChangeDetectorRef,
        private readonly messages: MessageService,
    ) {
        this.currentUser = this.authenticationService.currentUserValue;
    }

    get orderItemsFormGroup(): FormArray {
        return this.createMaterialForm.get('orderItems') as FormArray;
    }

    get f(): {[key: string]: AbstractControl} {
        return this.createMaterialForm.controls;
    }

    ngOnInit(): void {
        this.dataService.departmentSource.pipe(untilDestroyed(this))
            .subscribe(
                (data: Department[]) => {
                    this.departmentSelect = [];
                    data.map((department: Department) => {
                        if (!department.deleted_date) {
                            this.departmentSelect = [
                                ...this.departmentSelect,
                                {
                                    value: department.id,
                                    label: department.company.name + ' - ' + department.name,
                                },
                            ];
                        }
                    });
                    this.ref.markForCheck();
                },
                error => {
                    console.error(error);
                },
            );

        this.dataService.budgetSource.pipe(untilDestroyed(this))
            .subscribe(
                (budgets: MaterialBudget[]) => {
                    budgets.map((budget: MaterialBudget) => {
                        if (!budget.deleted_date) {
                            this.budgets = budgets;
                        }
                    });
                    this.ref.markForCheck();
                },
                error => {
                    console.error(error);
                },
            );

        this.materialsService
            .getPayments()
            .pipe(untilDestroyed(this))
            .subscribe(
                (payments: MaterialPayment[]) => {
                    this.payments = payments;
                    this.paymentSelect = [];
                    payments.map((payment: MaterialPayment) => {
                        if (!payment.deleted_date) {
                            this.paymentSelect = [
                                ...this.paymentSelect,
                                {value: payment.id, label: payment.name},
                            ];
                        }
                    });
                    this.ref.markForCheck();
                },
                error => {
                    console.error(error);
                },
            );

        this.createMaterialForm = this.formBuilder.group({
            orderItems: this.formBuilder.array([this.createOrder()]),
            reason: ['', Validators.required],
            note: [''],
            payment: [false, Validators.required],
            to_storage: [true, Validators.required],
            receipt: [null],
        });

        (this.createMaterialForm.get('orderItems') as FormArray).valueChanges
            .pipe(untilDestroyed(this))
            .subscribe(items => {
                items.forEach(item => {
                    console.info(item);
                });
            });
        this.orderItemsList = this.createMaterialForm.get('orderItems') as FormArray;

        this.dataService.setDepartmentDataSource();
    }

    createOrder(): FormGroup {
        return this.formBuilder.group({
            name: ['', Validators.compose([Validators.required])],
            amount: ['', Validators.compose([Validators.required])],
            price: ['', Validators.compose([Validators.required])],
            dph: [false],
            department_id: [null, Validators.compose([Validators.required])],
        });
    }

    getOrderItemsFormGroup(index): FormGroup {
        return this.orderItemsList.controls[index] as FormGroup;
    }

    addItem(): void {
        this.orderItemsList.push(this.createOrder());
    }

    removeItem(index: number): void {
        this.orderItemsList.removeAt(index);
    }

    onFilteringCategory: EmitType<FilteringEventArgs> = (e: FilteringEventArgs) => {
        if (e.text === '') {
            e.updateData(this.categorySelect);
        } else {
            let query: Query = new Query();

            query = e.text !== '' ? query.where('label', 'contains', e.text, true, true) : query;
            e.updateData(this.categorySelect, query);
        }
    };

    onFilteringPayments: EmitType<FilteringEventArgs> = (e: FilteringEventArgs) => {
        if (e.text === '') {
            e.updateData(this.paymentSelect);
        } else {
            let query: Query = new Query();

            query = e.text !== '' ? query.where('label', 'contains', e.text, true, true) : query;
            e.updateData(this.paymentSelect, query);
        }
    };

    onFilteringDepartments: EmitType<FilteringEventArgs> = (e: FilteringEventArgs) => {
        if (e.text === '') {
            e.updateData(this.departmentSelect);
        } else {
            let query: Query = new Query();

            query = e.text !== '' ? query.where('label', 'contains', e.text, true, true) : query;
            e.updateData(this.departmentSelect, query);
        }
    };

    onSubmit(): void {
        this.submited = true;

        if (this.createMaterialForm.invalid) {
            console.error('form is not valid!');

            return;
        }

        this.loadingMaterial = true;

        const usedBudgets: MaterialBudget[] = [];
        const confirmers: User[] = [];

        this.orderItems = this.orderItemsList.value;
        this.orderItems.forEach((item: OrderItem, index) => {
            this.setBudget(item.department_id);

            if (!this.budget) {
                const body = 'Rozpočet nedohledán';
                const options = {progressBar: true, timeOut: 5000};

                this.messages.showError(
                    'Chyba při hledání rozpočtu položek v objednávce materiálu,',
                    body,
                    options,
                );

                return;
            }

            this.orderItems[index].budget_id = this.budget.id;

            if (!usedBudgets.find(t => t.id === this.budget?.id)) {
                usedBudgets.push(this.budget);
                this.budget.approvers.forEach((approver: User) => {
                    if (!confirmers.find(x => x.id === approver.id)) {
                        confirmers.push(approver);
                    }
                });
            }
        });

        const order: OrderInput = {
            reason: this.f.reason.value,
            to_storage: this.f.to_storage.value,
            payment_id: this.f.payment.value,
            note: this.f.note.value,
            orderitems: this.orderItems,
            created_by: this.currentUser?.id,
            confirmers,
        };

        this.materialsService
            .addInvoice(order)
            .pipe(untilDestroyed(this))
            .subscribe(
                (data: Material) => {
                    const body = 'Objednávka zařazena do fronty ke schválení...';
                    const options = {progressBar: true, timeOut: 5000};

                    this.messages.showSuccess(
                        'Objednávka materiálu úspěšně vytvořena',
                        body,
                        options,
                    );
                    this.loadingMaterial = false;
                    void this.router.navigate(['/material/detail', data.id]);
                },
                error => {
                    console.error(error);

                    const body = 'Zkuste to znovu později';
                    const options = {progressBar: true, timeOut: 5000};

                    this.messages.showError(
                        'Chyba při vytváření objednávky materiálu,',
                        body,
                        options,
                    );
                    this.loadingMaterial = false;
                },
            );
    }

    setBudget(department: Department | number): void {
        this.budget = null;

        const departmentId = typeof department === 'number' ? department : department.id;

        this.budgets?.forEach((budget: MaterialBudget) => {
            if (!budget.deleted_date && budget.department_id === departmentId) {
                this.budget = budget;
            }
        });
    }

    // FILES

    showInputErrors(): void {
        this.createMaterialForm.markAllAsTouched();
    }
}
