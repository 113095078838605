import {
    AfterViewChecked,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnInit,
    ViewChild,
} from '@angular/core';

import {registerLocaleData} from '@angular/common';
import moment from 'moment';
import localeCs from '@angular/common/locales/cs';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {
    DataResult,
    EditSettingsModel,
    ExcelExportProperties,
    FilterSettingsModel,
    GridComponent,
    GroupSettingsModel,
    PageSettingsModel,
    RowDataBoundEventArgs,
    SelectionSettingsModel,
    ToolbarItems,
} from '@syncfusion/ej2-angular-grids';
import {ButtonPropsModel, DialogComponent} from '@syncfusion/ej2-angular-popups';
import {User} from '@src/app/_models/user/user';
import {Observable, Observer} from 'rxjs';
import {TextBoxComponent} from '@syncfusion/ej2-angular-inputs';
import {ChangeEventArgs, DropDownListComponent} from '@syncfusion/ej2-angular-dropdowns';
import {ActivatedRoute, Router} from '@angular/router';
import {FormBuilder} from '@angular/forms';
import {AuthenticationService} from '@src/app/_services/authentication.service';
import {DataService} from '@src/app/_services/data.service';
import {PermissionService} from '@src/app/_services/permission.service';
import {MessageService} from '@src/app/_services/message.service';
import {ClickEventArgs} from '@syncfusion/ej2-angular-navigations';
import {Office} from '@src/app/_models/office/office.model';
import {SettingsService} from '@src/app/features/settings/settings.service';
import {OfficeGridItems} from '@src/app/features/settings/types/office-grid.items.type';

moment.updateLocale('cs', {workingWeekdays: [1, 2, 3, 4, 5]});
registerLocaleData(localeCs);

@UntilDestroy()
@Component({
    selector: 'app-office-list',
    templateUrl: './office-list.component.html',
    styleUrls: ['./office-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OfficeListComponent implements OnInit, AfterViewChecked {
    today = moment()
        .format('YYYY-MM-DD HH:mm:ss');

    // Grid
    currencyFormat: {format: string} = {format: '#,###.00\',- CZK\''};

    dateFormat: string;

    sortOptions: object;

    filterSettings: FilterSettingsModel;

    selectionSettings: SelectionSettingsModel;

    editSettings: EditSettingsModel;

    groupOptions: GroupSettingsModel;

    pageOptions: PageSettingsModel;

    filterDate: object;

    requiredRules: object;

    categoryFilterData: string[] = [];

    offices: Office[] = [];

    toolbar: ToolbarItems[] | object;

    data: object;

    fields: object = {text: 'label', value: 'value'};

    exportCurrentPage = false;

    // Dialogs
    renderOfficeFormDialog = false;

    buttonsDelete: ButtonPropsModel[] = [
        {
            click: (): void => {
                this.stornoApplication();
            },
            isFlat: false,
            buttonModel: {
                content: 'OK',
                cssClass: 'e-success e-outline',
            },
        },
        {
            click: (): void => {
                this.confirmationDelete.hide();
            },
            buttonModel: {
                content: 'ZRUŠIT',
                cssClass: 'e-danger e-outline',
            },
        },
    ];

    buttonsCancel: ButtonPropsModel[] = [
        {
            click: (): void => {
                this.confirmationCancel.hide();
            },
            buttonModel: {
                isPrimary: true,
                content: 'OK',
            },
        },
    ];

    // Dropdowns
    height = '240px';

    holidays: Array<string> = [];

    // Forms
    isCreate = false;

    isUpdate = false;

    isCopy = false;

    // přípaneče a loadingy
    loadingOffice = true;

    // proměnné pro data
    currentUser: User | null;

    clickedRow: Office[] = [];

    clickedRow$ = new Observable<Office[]>((observer: Observer<Office[]>) => {
        observer.next(this.clickedRow);
    });

    // Grid
    @ViewChild('officesGrid') gridOffice?: GridComponent;

    @ViewChild('searchtext') searchtextObj?: TextBoxComponent;

    // Dialogs
    @ViewChild('confirmationDelete') confirmationDelete: DialogComponent;

    @ViewChild('confirmationDecline') confirmationDecline: DialogComponent;

    @ViewChild('confirmationConfirm') confirmationConfirm: DialogComponent;

    @ViewChild('confirmationCancel') confirmationCancel: DialogComponent;

    // Dropdowns
    @ViewChild('viewFilter') viewFilterInstance?: DropDownListComponent;

    constructor(
        private readonly router: Router,
        private readonly route: ActivatedRoute,
        private readonly ref: ChangeDetectorRef,
        private readonly formBuilder: FormBuilder,
        private readonly authenticationService: AuthenticationService,
        private readonly dataService: DataService,
        private readonly settingsService: SettingsService,
        private readonly permissionService: PermissionService,
        private readonly messages: MessageService,
    ) {
        this.currentUser = this.authenticationService.currentUserValue;
        this.dataService.setOfficesDataSource();
    }

    ngOnInit(): void {
        this.filterSettings = {
            type: 'Excel',
            showFilterBarOperator: true,
            showFilterBarStatus: true,
            ignoreAccent: true,
        };

        this.selectionSettings = {
            persistSelection: true,
            type: 'Multiple',
            checkboxOnly: true,
        };

        this.toolbar = [
            {
                text: 'Občerstvit data',
                tooltipText: 'Občerstvit data',
                prefixIcon: 'e-refresh',
                id: 'customRefresh',
            },
            'Print',
            'ExcelExport',
            'Add',
            'Edit',
            {
                text: 'Smazat',
                tooltipText: 'Smazat / Stáhnout žádanku',
                prefixIcon: 'e-remove',
                id: 'delete',
            },
            {
                text: 'Schválit',
                tooltipText: 'Schválit žádanku',
                prefixIcon: 'e-check',
                id: 'confirm',
            },
            {
                text: 'Zamítnout',
                tooltipText: 'Zamítnout žádanku',
                prefixIcon: 'e-times',
                id: 'decline',
            },
            {
                text: 'Kopírovat',
                tooltipText: 'Kopírovat žádanku',
                prefixIcon: 'e-copy',
                id: 'copy',
            },
            {
                text: 'Detail',
                tooltipText: 'Detail žádanky',
                prefixIcon: 'e-openfolder',
                id: 'detail',
            },
        ];

        this.categoryFilterData = [
            'všechny žádanky',
            'moje žádanky - vyřízené',
            'moje žádanky - fronta',
            'moje žádanky - všechny',
            'žádanky ke schválení',
        ];
        this.pageOptions = {pageSize: 50};
        this.dateFormat = 'dd.MM.yyyy HH:mm';
        this.filterDate = {type: 'Menu', params: {format: this.dateFormat}};
        this.groupOptions = {
            showGroupedColumn: true,
            disablePageWiseAggregates: false,
        };

        this.editSettings = {
            allowEditing: true,
            allowAdding: true,
            allowDeleting: true,
            allowEditOnDblClick: false,
            mode: 'Dialog',
        };
        this.requiredRules = {required: true};
    }

    ngAfterViewChecked(): void {
        this.route.queryParamMap.pipe(untilDestroyed(this))
            .subscribe(params => {
                if (params.get('form') === 'addOffice') {
                    console.info('get office form');
                    this.isCreate = true;
                    this.isUpdate = false;
                    this.isCopy = false;
                    this.renderOfficeFormDialog = true;
                    this.ref.markForCheck();
                }
            });
    }

    loadData(): DataResult[] {
        this.gridOffice?.clearRowSelection();

        console.info('NEW DATA BOUND');

        let elements: OfficeGridItems[] = [];

        if (this.offices.length > 0) {
            // eslint-disable-next-line complexity
            this.offices.map((office: Office) => {
                elements.push({
                    id: office.id,
                    company_id: office.company_id,
                    building_id: office.building_id,
                    created_by: office.created_by,
                    // eslint-disable-next-line max-len
                    creator_str: office.creator.fullname,
                    creator_img: office.creator.personalphoto,
                    name: office.name,
                    company: office.company.name,
                    building: office.building.name,
                    bookable: office.bookable,
                    type:
                        office.type === 'open'
                            ? 'Open Office'
                            : office.type === 'shared'
                                ? 'Sdílená kancelář'
                                : office.type === 'private'
                                    ? 'Soukromá kancelář'
                                    : 'Zasedací místnost',
                    created_date: new Date(office.created_date),
                    updated_date: office.updated_date ? new Date(office.updated_date) : null,
                    deleted_date: office.deleted_date ? new Date(office.deleted_date) : null,
                });
            });

            elements = elements.sort((a: OfficeGridItems, b: OfficeGridItems) => {
                const dateA = moment(a.created_date)
                    .valueOf();
                const dateB = moment(b.created_date)
                    .valueOf();

                return dateA > dateB ? -1 : 1;
            });
        }

        this.loadingOffice = false;
        this.ref.markForCheck();

        return elements as unknown as DataResult[];
    }

    onCreated(): void {
        this.disableToolBarBtn();
    }

    setInitialGridFiltering(): void {
        if (this.currentUser && this.viewFilterInstance) {
            this.gridOffice?.clearFiltering();
            this.gridOffice?.filterByColumn(
                'created_by',
                'equal',
                this.currentUser.id,
                'and',
                false,
                true,
            );
            this.viewFilterInstance.value = 'moje žádanky - všechny';
            this.ref.markForCheck();
        }
    }

    onLoad(): void {
        const wrapper1 = document.getElementById('scroll_wrapper1_offices');
        const wrapper2 = document.getElementById('scroll_wrapper2_offices');

        if (wrapper1 && wrapper2) {
            wrapper1.onscroll = (): void => {
                wrapper2.scrollLeft = wrapper1.scrollLeft;
            };

            wrapper2.onscroll = (): void => {
                wrapper1.scrollLeft = wrapper2.scrollLeft;
            };
        }

        this.dataService.officesSource.pipe(untilDestroyed(this))
            .subscribe(
                (offices: Office[]) => {
                    this.offices = offices;

                    if (this.gridOffice && this.offices.length > 0) {
                        this.data = this.gridOffice.dataSource = this.loadData();
                        this.sortOptions = {
                            columns: [
                                {field: 'created_date', direction: 'Descending'},
                                {
                                    field: 'id',
                                    direction: 'Descending',
                                },
                            ],
                        };
                    } else {
                        this.loadingOffice = false;
                        this.ref.markForCheck();
                    }
                },
                error => {
                    console.error(error);
                    this.loadingOffice = false;
                    this.ref.markForCheck();
                },
            );
    }

    search(): void {
        this.gridOffice?.search(this.searchtextObj?.value ?? '');
    }

    onDataBound(): void {
        this.rowSelected();

        const width = document.getElementById('officesGrid_content_table')?.offsetWidth;
        const parent1 = document.getElementById('scroll_div_offices');
        const parent2 = document.getElementById('grid_parent_offices');

        if (parent1 && parent2 && width) {
            parent1.style.width = `${width}px`;
            parent2.style.width = `${width}px`;
        }

        this.gridOffice?.autoFitColumns();
        this.ref.markForCheck();
    }

    rowDataBound(args: RowDataBoundEventArgs): void {
        const data = args.data as OfficeGridItems;

        if (
            args.row &&
            args.data &&
            this.currentUser &&
            data.created_by !== this.currentUser.id &&
            !this.permissionService.checkUserISOfficeAdmin(this.currentUser) &&
            data.deleted_date
        ) {
            args.row.getElementsByClassName('e-gridchkbox')[0].classList.add('disablecheckbox');
            args.row
                .getElementsByClassName('e-checkbox-wrapper')[0]
                .classList.add('disablecheckbox');
            args.row.classList.add('e-disabled');
        }

        this.ref.markForCheck();
    }

    onStatusFilterChange(e: ChangeEventArgs): void {
        if (this.gridOffice && this.currentUser) {
            if (e.value === 'všechny žádanky') {
                this.gridOffice.clearFiltering();
            } else if (e.value === 'moje žádanky - všechny') {
                this.gridOffice.clearFiltering();
                this.gridOffice.filterByColumn(
                    'created_by',
                    'equal',
                    this.currentUser.id,
                    'and',
                    false,
                    true,
                );
            } else if (e.value === 'moje žádanky - vyřízené') {
                this.gridOffice.clearFiltering();
                this.gridOffice.filterSettings = {
                    columns: [
                        {
                            field: 'created_by',
                            operator: 'equal',
                            value: this.currentUser.id,
                            predicate: 'and',
                            matchCase: false,
                            ignoreAccent: true,
                        },
                        {
                            field: 'status',
                            operator: 'notequal',
                            value: 've frontě',
                            predicate: 'and',
                            matchCase: false,
                            ignoreAccent: true,
                        },
                    ],
                };
            } else if (e.value === 'moje žádanky - fronta') {
                this.gridOffice.clearFiltering();
                this.gridOffice.filterSettings = {
                    columns: [
                        {
                            field: 'created_by',
                            operator: 'equal',
                            value: this.currentUser.id,
                            predicate: 'and',
                            matchCase: false,
                            ignoreAccent: true,
                        },
                        {
                            field: 'status',
                            operator: 'equal',
                            value: 've frontě',
                            predicate: 'and',
                            matchCase: false,
                            ignoreAccent: true,
                        },
                    ],
                };
            } else if (e.value === 'žádanky ke schválení') {
                this.gridOffice.clearFiltering();
                this.gridOffice.filterSettings = {
                    columns: [
                        {
                            field: 'boss_id',
                            operator: 'equal',
                            value: this.currentUser.id,
                            predicate: 'and',
                            matchCase: false,
                            ignoreAccent: true,
                        },
                        {
                            field: 'status',
                            operator: 'equal',
                            value: 've frontě',
                            predicate: 'and',
                            matchCase: false,
                            ignoreAccent: true,
                        },
                    ],
                };
            }
        }
    }

    cancelBtnClick(): void {
        if (this.searchtextObj && this.gridOffice) {
            this.searchtextObj.value = '';
            this.gridOffice.searchSettings.key = '';
        }
    }

    toolbarClick(args: ClickEventArgs): void {
        const xlsProp: ExcelExportProperties = {
            fileName: 'js_offices_export.xlsx',
            enableFilter: true,
            exportType: this.exportCurrentPage ? 'CurrentPage' : 'AllPages',
        };

        if (args.item.id === 'officesGrid_excelexport') {
            void this.gridOffice?.excelExport(xlsProp);
        } else if (args.item.id === 'confirm') {
            this.confirmationConfirm.content = `Potvrďte schválení ${this.clickedRow.length} žádanek`;

            if (this.gridOffice?.getSelectedRecords().length !== 0) {
                this.confirmationConfirm.content = `Potvrďte schválení ${this.clickedRow.length} žádanek`;
                this.confirmationConfirm.show();
            } else {
                this.confirmationCancel.content = 'Musíte vybrat alespoň jednu žádanku';
                this.confirmationCancel.show();
            }
        } else if (args.item.id === 'decline') {
            if (this.gridOffice?.getSelectedRecords().length !== 0) {
                this.confirmationDecline.content = `Potvrďte zamítnutí ${this.clickedRow.length} žádanek`;
                this.confirmationDecline.show();
            } else {
                this.confirmationCancel.content = 'Musíte vybrat alespoň jednu žádanku';
                this.confirmationCancel.show();
            }
        } else if (args.item.id === 'delete') {
            if (this.gridOffice?.getSelectedRecords().length !== 0) {
                this.confirmationDelete.content = `Potvrďte smazání ${this.clickedRow.length} žádanek`;
                this.confirmationDelete.show();
            } else {
                this.confirmationCancel.content = 'Musíte vybrat alespoň jednu žádanku';
                this.confirmationCancel.show();
            }
        } else if (args.item.id === 'copy') {
            if (this.gridOffice?.getSelectedRecords().length !== 0) {
                this.isUpdate = false;
                this.isCreate = false;
                this.isCopy = true;
                this.renderOfficeFormDialog = true;
                this.ref.markForCheck();
            } else {
                this.confirmationCancel.content = 'Musíte vybrat alespoň jednu žádanku';
                this.confirmationCancel.show();
            }
        } else if (args.item.id === 'detail') {
            void this.router.navigate(['/office/detail', this.clickedRow[0].id]);
        } else if (args.item.id === 'customRefresh') {
            this.loadingOffice = true;
            console.info('refresh DATA!');
            this.dataService.clearOfficesCache();
            this.dataService.setOfficesDataSource();
            this.ref.markForCheck();
        }
    }

    enableToolbarBtn(): void {
        if (
            (this.currentUser && this.permissionService.checkUserISOfficeAdmin(this.currentUser)) ||
            this.currentUser?.is_boss
        ) {
            this.gridOffice?.toolbarModule.enableItems(
                ['officesGrid_edit', 'delete', 'confirm', 'decline', 'copy', 'detail'],
                true,
            );
        } else {
            this.gridOffice?.toolbarModule.enableItems(
                ['officesGrid_edit', 'delete', 'copy', 'detail'],
                true,
            );
        }
    }

    disableToolBarBtn(): void {
        this.gridOffice?.toolbarModule.enableItems(
            ['officesGrid_edit', 'delete', 'confirm', 'decline', 'copy', 'detail'],
            false,
        );
    }

    rowSelected(): void {
        this.clickedRow = [];

        const selectedrecords: Office[] = this.gridOffice?.getSelectedRecords() as Office[];

        if (selectedrecords.length > 0) {
            selectedrecords.map(row => {
                const tempData = this.offices.find(x => x.id === row.id);

                if (tempData) {
                    this.clickedRow.push(tempData);
                }

                this.ref.markForCheck();
            });

            this.clickedRow$ = new Observable<Office[]>((observer: Observer<Office[]>) => {
                observer.next(this.clickedRow);
            });
            this.enableToolbarBtn();
        } else {
            this.disableToolBarBtn();
            this.clickedRow$ = new Observable<Office[]>((observer: Observer<Office[]>) => {
                observer.next(this.clickedRow);
            });
        }
    }

    actionBegin(args): void {
        if (args.requestType === 'add' || args.requestType === 'beginEdit') {
            args.cancel = true;

            if (args.requestType === 'beginEdit') {
                this.isUpdate = true;
                this.isCreate = false;
                this.isCopy = false;
            }

            if (args.requestType === 'add') {
                this.isCreate = true;
                this.isUpdate = false;
                this.isCopy = false;
            }

            this.renderOfficeFormDialog = true;
            this.ref.markForCheck();
        }
    }

    actionComplete(args): void {
        if (args.requestType === 'beginEdit' || args.requestType === 'add') {
            args.form.ej2_instances[0].rules = {};
        }
    }

    stornoApplication(): void {
        const promises: Array<Promise<Office | undefined> | undefined> = [];
        let rejects = 0;

        this.loadingOffice = true;

        for (const row of this.clickedRow) {
            if (
                this.currentUser &&
                !row.deleted_date &&
                (row.created_by === this.currentUser.id ||
                    this.permissionService.checkUserISOfficeAdmin(this.currentUser))
            ) {
                row.deleted_by = this.currentUser.id;

                const deleteObj = this.settingsService.deleteOffice(row)
                    .toPromise();

                promises.push(deleteObj);
            } else {
                rejects++;
            }
        }

        void Promise.all(promises)
            .then(result => {
                if (result.length > 0) {
                    const body = `Celkem stornováno: ${result.length} žádanek`;
                    const options = {progressBar: true, timeOut: 5000};

                    this.messages.showSuccess('Stornování úspěšně dokončeno', body, options);
                }

                if (rejects > 0) {
                    const body = `Celkem u: ${rejects} žádanek`;
                    const options = {progressBar: true, timeOut: 5000};

                    this.messages.showError('Nedostatečné oprávnění', body, options);
                }

                this.confirmationDelete.hide();
                this.gridOffice?.clearRowSelection();
                this.rowSelected();
                this.loadingOffice = false;
                this.dataService.setOfficesDataSource();
                this.ref.markForCheck();
            });
    }
}
