import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {AuthenticationService} from '@src/app/_services/authentication.service';
import {PermissionService} from '@src/app/_services/permission.service';
import {User} from '@src/app/_models/user/user';
import {Task} from '@src/app/_models/task/task';

@Component({
    selector: 'app-task-toolbar',
    templateUrl: './task-toolbar.component.html',
    styleUrls: ['./task-toolbar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TaskToolbarComponent {
    currentUser: User | null;

    @Input() task?: Task | null;

    @Output() readonly loading = new EventEmitter<boolean>();

    @Output() readonly createCommentEvent = new EventEmitter<boolean>();

    @Output() readonly addSolvingEvent = new EventEmitter<boolean>();

    @Output() readonly editTaskEvent = new EventEmitter<boolean>();

    @Output() readonly copyTaskEvent = new EventEmitter<boolean>();

    @Output() readonly activeTaskEvent = new EventEmitter<boolean>();

    @Output() readonly reactiveSolutionEvent = new EventEmitter<boolean>();

    @Output() readonly deleteTaskEvent = new EventEmitter<boolean>();

    constructor(
        private readonly authenticationService: AuthenticationService,
        public permissionService: PermissionService,
    ) {
        this.currentUser = this.authenticationService.currentUserValue;
    }

    cerateComment(): void {
        this.createCommentEvent.emit(true);
    }

    addSolving(): void {
        this.addSolvingEvent.emit(true);
    }

    editTask(): void {
        this.editTaskEvent.emit(true);
    }

    copyTask(): void {
        this.copyTaskEvent.emit(true);
    }

    activeTask(): void {
        this.activeTaskEvent.emit(true);
    }

    reactiveSolution(): void {
        this.reactiveSolutionEvent.emit(true);
    }

    deleteTask(): void {
        this.deleteTaskEvent.emit(true);
    }
}
