<ejs-dialog
  #formDialog
  (close)="
        isDirty = false;
        isChecked = false;
        submited = false;
        changeDialogState(false)
    "
  [isModal]="true"
  [showCloseIcon]="true"
  [visible]="isVisible"
  [width]="'85%'"
  id="formDialog"
>
  <form [formGroup]="checkRuianForm">
    <ng-template #content>
      <div class="dialogContent">
        <div [hidden]="!hidden" class="tab mb-3" id="tab0">
          <div class="row">
            <div class="col-12 col-md-8 mb-3 form-group">
              <label
                [ngClass]="
                                    f.address.valid &&
                                    (f.address.dirty || f.address.touched)
                                        ? 'text-success'
                                        : f.address.invalid &&
                                          (f.address.dirty || f.address.touched)
                                        ? 'text-danger'
                                        : 'text-muted'
                                "
                for="chckRuian-address"
              >* Adresa parcely:</label
              >
              <ejs-textbox
                [ngClass]="
                                    f.address.touched && f.address.invalid
                                        ? 'e-error'
                                        : f.address.touched && f.address.valid
                                        ? 'e-success'
                                        : ''
                                "
                autocomplete="smartform-auto-address"
                class="smartform-whole-address smartform-instance-ruian"
                floatLabelType="Auto"
                formControlName="address"
                id="chckRuian-address"
              ></ejs-textbox>
              <small class="text-muted"
              >Vyberte adresu pomocí našeptávače</small
              ><br />
              <small
                *ngIf="
                                    f.address.touched &&
                                    f.address.errors &&
                                    f.address.errors.required
                                "
                class="text-danger"
              >Adresa parcely je povinná</small
              >
              <button
                class="e-btn e-info e-outline btn-p-zero e-small m-0"
                ejs-button
                onclick="loadMapyCZ()"
                title="pomoc najít adresu pro našeptávač"
                type="button"
              >
                <i
                  aria-hidden="true"
                  class="fa-light fa-lg fa-map-marked-alt mr-2"
                ></i
                >Najít adresu pomocí mapy
              </button>
            </div>
            <div class="col-12 col-md-4 mb-3 form-group">
              <label
                [ngClass]="
                                    f.ruian.valid &&
                                    (f.ruian.dirty || f.ruian.touched)
                                        ? 'text-success'
                                        : f.ruian.invalid &&
                                          (f.ruian.dirty || f.ruian.touched)
                                        ? 'text-danger'
                                        : 'text-muted'
                                "
                for="ruian"
              >* RUIAN:</label
              >
              <ejs-numerictextbox
                [enabled]="false"
                [format]="'###'"
                [ngClass]="
                                    f.ruian.touched && f.ruian.invalid
                                        ? 'e-error'
                                        : f.ruian.touched && f.ruian.valid
                                        ? 'e-success'
                                        : ''
                                "
                formControlName="ruian"
                id="ruian"
                min="0"
                step="1"
              ></ejs-numerictextbox>
              <small class="text-warning"
              >Tento údaj je doplňován automaticky</small
              ><br />
              <small
                *ngIf="
                                    f.ruian.touched &&
                                    f.ruian.errors &&
                                    f.ruian.errors.required
                                "
                class="text-danger"
              >kód RUIAN je povinný</small
              >
              <div
                *ngIf="!validRuian && validRuian === null"
                class="alert alert-warning p-0"
                role="alert"
              >
                Vyberte adresu z našeptávače
              </div>
              <div
                *ngIf="validRuian"
                class="alert alert-danger p-1"
                role="alert"
              >
                RUIAN již existuje
                <span
                  *ngIf="
                                        ruianAddress &&
                                        ruianAddress.deleted_date
                                    "
                  class="e-badge e-badge-success float-right"
                >aktivní adresa</span
                >
                <span
                  *ngIf="
                                        ruianAddress &&
                                        !ruianAddress.deleted_date
                                    "
                  class="e-badge e-badge-success float-right"
                >aktivní adresa</span
                >
              </div>
              <div
                *ngIf="!validRuian && validRuian === false"
                class="alert alert-success p-0"
                role="alert"
              >
                RUIAN je zkontrolován
              </div>
            </div>
            <div
              class="col-12 mb-3 form-group"
              id="mapyParent"
              style="display: none"
            >
              <div class="w-100">
                <input
                  class="w-100"
                  id="mapaSuggest"
                  placeholder="Sem napiště oblast kde budete hledat adresu..."
                />
                <small class="text-muted"
                >Kliknutím na budovu s číslem popisným,
                  zkopírujete celou adresu do vaší schránky,
                  potom můžete vložit do pole pro vyhledání
                  RUIAN adresy.</small
                >
              </div>
              <div id="mapa"></div>
            </div>
          </div>
        </div>
        <div [hidden]="hidden" class="tab mb-3" id="tab1">
          <div class="row">
            <div class="col-6 col-md-3 mb-3 form-group">
              <label
                [ngClass]="
                                    f.oriental_number.valid &&
                                    (f.oriental_number.dirty ||
                                        f.oriental_number.touched)
                                        ? 'text-success'
                                        : f.oriental_number.invalid &&
                                          (f.oriental_number.dirty ||
                                              f.oriental_number.touched)
                                        ? 'text-danger'
                                        : 'text-muted'
                                "
                for="chckRuian-oriental_number"
              >Číslo orientační:</label
              >
              <ejs-numerictextbox
                [format]="'###'"
                [ngClass]="
                                    f.oriental_number.touched &&
                                    f.oriental_number.invalid
                                        ? 'e-error'
                                        : f.oriental_number.touched &&
                                          f.oriental_number.valid
                                        ? 'e-success'
                                        : ''
                                "
                formControlName="oriental_number"
                id="chckRuian-oriental_number"
                min="0"
                step="1"
              ></ejs-numerictextbox>
              <small class="text-warning"
              >Tento údaj je doplňován automaticky</small
              >
              <small
                *ngIf="
                                    f.oriental_number.touched &&
                                    f.oriental_number.errors &&
                                    f.oriental_number.errors.required
                                "
                class="text-danger"
              >číslo orientační je povinné</small
              >
            </div>
            <div class="col-6 col-md-3 mb-3 form-group">
              <label
                [ngClass]="
                                    f.house_number.valid &&
                                    (f.house_number.dirty ||
                                        f.house_number.touched)
                                        ? 'text-success'
                                        : f.house_number.invalid &&
                                          (f.house_number.dirty ||
                                              f.house_number.touched)
                                        ? 'text-danger'
                                        : 'text-muted'
                                "
                for="chckRuian-house_number"
              >* Číslo popisné:</label
              >
              <ejs-numerictextbox
                [format]="'###'"
                [ngClass]="
                                    f.house_number.touched &&
                                    f.house_number.invalid
                                        ? 'e-error'
                                        : f.house_number.touched &&
                                          f.house_number.valid
                                        ? 'e-success'
                                        : ''
                                "
                formControlName="house_number"
                id="chckRuian-house_number"
                min="0"
                step="1"
              ></ejs-numerictextbox>
              <small class="text-warning"
              >Tento údaj je doplňován automaticky</small
              >
              <small
                *ngIf="
                                    f.house_number.touched &&
                                    f.house_number.errors &&
                                    f.house_number.errors.required
                                "
                class="text-danger"
              >číslo popisné je povinné</small
              >
            </div>
            <div class="col-9 col-md-4 mb-3 form-group">
              <label
                [ngClass]="
                                    f.street.valid &&
                                    (f.street.dirty || f.street.touched)
                                        ? 'text-success'
                                        : f.street.invalid &&
                                          (f.street.dirty || f.street.touched)
                                        ? 'text-danger'
                                        : 'text-muted'
                                "
                for="chckRuian-street"
              >Název ulice:</label
              >
              <ejs-textbox
                [ngClass]="
                                    f.street.touched && f.street.invalid
                                        ? 'e-error'
                                        : f.street.touched && f.street.valid
                                        ? 'e-success'
                                        : ''
                                "
                floatLabelType="Auto"
                formControlName="street"
                id="chckRuian-street"
              ></ejs-textbox>
              <small class="text-warning"
              >Tento údaj je doplňován automaticky</small
              >
              <small
                *ngIf="
                                    f.street.touched &&
                                    f.street.errors &&
                                    f.street.errors.required
                                "
                class="text-danger"
              >název ulice je povinný</small
              >
            </div>
            <div class="col-3 col-md-2 mb-3 form-group">
              <label
                [ngClass]="
                                    f.street_code.valid &&
                                    (f.street_code.dirty ||
                                        f.street_code.touched)
                                        ? 'text-success'
                                        : f.street_code.invalid &&
                                          (f.street_code.dirty ||
                                              f.street_code.touched)
                                        ? 'text-danger'
                                        : 'text-muted'
                                "
                for="chckRuian-street_code"
              >Kód ulice:</label
              >
              <ejs-numerictextbox
                [format]="'###'"
                [ngClass]="
                                    f.street_code.touched &&
                                    f.street_code.invalid
                                        ? 'e-error'
                                        : f.street_code.touched &&
                                          f.street_code.valid
                                        ? 'e-success'
                                        : ''
                                "
                formControlName="street_code"
                id="chckRuian-street_code"
                min="0"
                step="1"
              ></ejs-numerictextbox>
              <small class="text-warning"
              >Tento údaj je doplňován automaticky</small
              >
              <small
                *ngIf="
                                    f.street_code.touched &&
                                    f.street_code.errors &&
                                    f.street_code.errors.required
                                "
                class="text-danger"
              >kód ulice je povinný</small
              >
            </div>
            <div class="col-6 col-lg-3 mb-3 form-group">
              <label
                [ngClass]="
                                    f.city.valid &&
                                    (f.city.dirty || f.city.touched)
                                        ? 'text-success'
                                        : f.city.invalid &&
                                          (f.city.dirty || f.city.touched)
                                        ? 'text-danger'
                                        : 'text-muted'
                                "
                for="chckRuian-city"
              >* Město:</label
              >
              <ejs-textbox
                [ngClass]="
                                    f.city.touched && f.city.invalid
                                        ? 'e-error'
                                        : f.city.touched && f.city.valid
                                        ? 'e-success'
                                        : ''
                                "
                floatLabelType="Auto"
                formControlName="city"
                id="chckRuian-city"
              ></ejs-textbox>
              <small class="text-warning"
              >Tento údaj je doplňován automaticky</small
              >
              <small
                *ngIf="
                                    f.city.touched &&
                                    f.city.errors &&
                                    f.city.errors.required
                                "
                class="text-danger"
              >město je povinné</small
              >
            </div>
            <div class="col-3 col-lg-2 mb-3 form-group">
              <label
                [ngClass]="
                                    f.zip.valid &&
                                    (f.zip.dirty || f.zip.touched)
                                        ? 'text-success'
                                        : f.zip.invalid &&
                                          (f.zip.dirty || f.zip.touched)
                                        ? 'text-danger'
                                        : 'text-muted'
                                "
                for="chckRuian-zip"
              >* PSČ:</label
              >
              <ejs-numerictextbox
                [format]="'###'"
                [ngClass]="
                                    f.zip.touched && f.zip.invalid
                                        ? 'e-error'
                                        : f.zip.touched && f.zip.valid
                                        ? 'e-success'
                                        : ''
                                "
                formControlName="zip"
                id="chckRuian-zip"
                min="0"
                step="1"
              ></ejs-numerictextbox>
              <small class="text-warning"
              >Tento údaj je doplňován automaticky</small
              >
              <small
                *ngIf="
                                    f.zip.touched &&
                                    f.zip.errors &&
                                    f.zip.errors.required
                                "
                class="text-danger"
              >PSČ je povinný</small
              >
            </div>
            <div class="col-3 col-lg-2 mb-3 form-group">
              <label
                [ngClass]="
                                    f.city_code.valid &&
                                    (f.city_code.dirty || f.city_code.touched)
                                        ? 'text-success'
                                        : f.city_code.invalid &&
                                          (f.city_code.dirty ||
                                              f.city_code.touched)
                                        ? 'text-danger'
                                        : 'text-muted'
                                "
                for="chckRuian-city_code"
              >Kód města:</label
              >
              <ejs-numerictextbox
                [format]="'###'"
                [ngClass]="
                                    f.city_code.touched && f.city_code.invalid
                                        ? 'e-error'
                                        : f.city_code.touched &&
                                          f.city_code.valid
                                        ? 'e-success'
                                        : ''
                                "
                formControlName="city_code"
                id="chckRuian-city_code"
                min="0"
                step="1"
              ></ejs-numerictextbox>
              <small class="text-warning"
              >Tento údaj je doplňován automaticky</small
              >
              <small
                *ngIf="
                                    f.city_code.touched &&
                                    f.city_code.errors &&
                                    f.city_code.errors.required
                                "
                class="text-danger"
              >kód města je povinný</small
              >
            </div>
            <div class="col-9 col-lg-3 mb-3 form-group">
              <label
                [ngClass]="
                                    f.city_part.valid &&
                                    (f.city_part.dirty || f.city_part.touched)
                                        ? 'text-success'
                                        : f.city_part.invalid &&
                                          (f.city_part.dirty ||
                                              f.city_part.touched)
                                        ? 'text-danger'
                                        : 'text-muted'
                                "
                for="chckRuian-city_part"
              >Městská část:</label
              >
              <ejs-textbox
                [ngClass]="
                                    f.city_part.touched && f.city_part.invalid
                                        ? 'e-error'
                                        : f.city_part.touched &&
                                          f.city_part.valid
                                        ? 'e-success'
                                        : ''
                                "
                floatLabelType="Auto"
                formControlName="city_part"
                id="chckRuian-city_part"
              ></ejs-textbox>
              <small class="text-warning"
              >Tento údaj je doplňován automaticky</small
              >
              <small
                *ngIf="
                                    f.city_part.touched &&
                                    f.city_part.errors &&
                                    f.city_part.errors.required
                                "
                class="text-danger"
              >městská část je povinná</small
              >
            </div>
            <div class="col-3 col-lg-2 mb-3 form-group">
              <label
                [ngClass]="
                                    f.city_part_code.valid &&
                                    (f.city_part_code.dirty ||
                                        f.city_part_code.touched)
                                        ? 'text-success'
                                        : f.city_part_code.invalid &&
                                          (f.city_part_code.dirty ||
                                              f.city_part_code.touched)
                                        ? 'text-danger'
                                        : 'text-muted'
                                "
                for="chckRuian-city_part_code"
              >Kód městské části:</label
              >
              <ejs-numerictextbox
                [format]="'###'"
                [ngClass]="
                                    f.city_part_code.touched &&
                                    f.city_part_code.invalid
                                        ? 'e-error'
                                        : f.city_part_code.touched &&
                                          f.city_part_code.valid
                                        ? 'e-success'
                                        : ''
                                "
                formControlName="city_part_code"
                id="chckRuian-city_part_code"
                min="0"
                step="1"
              ></ejs-numerictextbox>
              <small class="text-warning"
              >Tento údaj je doplňován automaticky</small
              >
              <small
                *ngIf="
                                    f.city_part_code.touched &&
                                    f.city_part_code.errors &&
                                    f.city_part_code.errors.required
                                "
                class="text-danger"
              >kód městské části je povinný</small
              >
            </div>
            <div class="col-12 col-md-4 mb-3 form-group">
              <label
                [ngClass]="
                                    f.district.valid &&
                                    (f.district.dirty || f.district.touched)
                                        ? 'text-success'
                                        : f.district.invalid &&
                                          (f.district.dirty ||
                                              f.district.touched)
                                        ? 'text-danger'
                                        : 'text-muted'
                                "
                for="chckRuian-district"
              >* Okres:</label
              >
              <ejs-textbox
                [ngClass]="
                                    f.district.touched && f.district.invalid
                                        ? 'e-error'
                                        : f.district.touched && f.district.valid
                                        ? 'e-success'
                                        : ''
                                "
                floatLabelType="Auto"
                formControlName="district"
                id="chckRuian-district"
              ></ejs-textbox>
              <small class="text-warning"
              >Tento údaj je doplňován automaticky</small
              >
              <small
                *ngIf="
                                    f.district.touched &&
                                    f.district.errors &&
                                    f.district.errors.required
                                "
                class="text-danger"
              >okres je povinný</small
              >
            </div>
            <div class="col-12 col-md-2 mb-3 form-group">
              <label
                [ngClass]="
                                    f.district_code.valid &&
                                    (f.district_code.dirty ||
                                        f.district_code.touched)
                                        ? 'text-success'
                                        : f.district_code.invalid &&
                                          (f.district_code.dirty ||
                                              f.district_code.touched)
                                        ? 'text-danger'
                                        : 'text-muted'
                                "
                for="chckRuian-district_code"
              >Kód okresu:</label
              >
              <ejs-numerictextbox
                [format]="'###'"
                [ngClass]="
                                    f.district_code.touched &&
                                    f.district_code.invalid
                                        ? 'e-error'
                                        : f.district_code.touched &&
                                          f.district_code.valid
                                        ? 'e-success'
                                        : ''
                                "
                formControlName="district_code"
                id="chckRuian-district_code"
                min="0"
                step="1"
              ></ejs-numerictextbox>
              <small class="text-warning"
              >Tento údaj je doplňován automaticky</small
              >
              <small
                *ngIf="
                                    f.district_code.touched &&
                                    f.district_code.errors &&
                                    f.district_code.errors.required
                                "
                class="text-danger"
              >kód okresu je povinný</small
              >
            </div>
            <div class="col-12 col-md-4 mb-3 form-group">
              <label
                [ngClass]="
                                    f.region.valid &&
                                    (f.region.dirty || f.region.touched)
                                        ? 'text-success'
                                        : f.region.invalid &&
                                          (f.region.dirty || f.region.touched)
                                        ? 'text-danger'
                                        : 'text-muted'
                                "
                for="chckRuian-region"
              >* Kraj:</label
              >
              <ejs-textbox
                [ngClass]="
                                    f.region.touched && f.region.invalid
                                        ? 'e-error'
                                        : f.region.touched && f.region.valid
                                        ? 'e-success'
                                        : ''
                                "
                floatLabelType="Auto"
                formControlName="region"
                id="chckRuian-region"
              ></ejs-textbox>
              <small class="text-warning"
              >Tento údaj je doplňován automaticky</small
              >
              <small
                *ngIf="
                                    f.region.touched &&
                                    f.region.errors &&
                                    f.region.errors.required
                                "
                class="text-danger"
              >kraj je povinný</small
              >
            </div>
            <div class="col-12 col-md-2 mb-3 form-group">
              <label
                [ngClass]="
                                    f.region_code.valid &&
                                    (f.region_code.dirty ||
                                        f.region_code.touched)
                                        ? 'text-success'
                                        : f.region_code.invalid &&
                                          (f.region_code.dirty ||
                                              f.region_code.touched)
                                        ? 'text-danger'
                                        : 'text-muted'
                                "
                for="chckRuian-region_code"
              >Kód kraje:</label
              >
              <ejs-numerictextbox
                [format]="'###'"
                [ngClass]="
                                    f.region_code.touched &&
                                    f.region_code.invalid
                                        ? 'e-error'
                                        : f.region_code.touched &&
                                          f.region_code.valid
                                        ? 'e-success'
                                        : ''
                                "
                formControlName="region_code"
                id="chckRuian-region_code"
                min="0"
                step="1"
              ></ejs-numerictextbox>
              <small class="text-warning"
              >Tento údaj je doplňován automaticky</small
              >
              <small
                *ngIf="
                                    f.region_code.touched &&
                                    f.region_code.errors &&
                                    f.region_code.errors.required
                                "
                class="text-danger"
              >kód kraje je povinný</small
              >
            </div>
            <div class="col-12 col-md-6 mb-3 form-group">
              <label
                [ngClass]="
                                    f.state.valid &&
                                    (f.state.dirty || f.state.touched)
                                        ? 'text-success'
                                        : f.state.invalid &&
                                          (f.state.dirty || f.state.touched)
                                        ? 'text-danger'
                                        : 'text-muted'
                                "
                for="chckRuian-state"
              >* Stát:</label
              >
              <ejs-textbox
                [ngClass]="
                                    f.state.touched && f.state.invalid
                                        ? 'e-error'
                                        : f.state.touched && f.state.valid
                                        ? 'e-success'
                                        : ''
                                "
                floatLabelType="Auto"
                formControlName="state"
                id="chckRuian-state"
              ></ejs-textbox>
              <small class="text-warning"
              >Tento údaj je doplňován automaticky</small
              >
              <small
                *ngIf="
                                    f.state.touched &&
                                    f.state.errors &&
                                    f.state.errors.required
                                "
                class="text-danger"
              >stát je povinný</small
              >
            </div>
            <div class="col-12 col-md-3 mb-3 form-group">
              <label
                [ngClass]="
                                    f.gps.valid &&
                                    (f.gps.dirty || f.gps.touched)
                                        ? 'text-success'
                                        : f.gps.invalid &&
                                          (f.gps.dirty || f.gps.touched)
                                        ? 'text-danger'
                                        : 'text-muted'
                                "
                for="chckRuian-gps"
              >GPS souřadnice:</label
              >
              <ejs-textbox
                [ngClass]="
                                    f.gps.touched && f.gps.invalid
                                        ? 'e-error'
                                        : f.gps.touched && f.gps.valid
                                        ? 'e-success'
                                        : ''
                                "
                floatLabelType="Auto"
                formControlName="gps"
                id="chckRuian-gps"
              ></ejs-textbox>
              <small class="text-warning"
              >Tento údaj je doplňován automaticky</small
              >
              <small
                *ngIf="
                                    f.gps.touched &&
                                    f.gps.errors &&
                                    f.gps.errors.required
                                "
                class="text-danger"
              >GPS souřadnice jsou povinné</small
              >
            </div>
            <div class="col-12 col-md-3 mb-3 form-group">
              <label
                [ngClass]="
                                    f.jstk.valid &&
                                    (f.jstk.dirty || f.jstk.touched)
                                        ? 'text-success'
                                        : f.jstk.invalid &&
                                          (f.jstk.dirty || f.jstk.touched)
                                        ? 'text-danger'
                                        : 'text-muted'
                                "
                for="chckRuian-jstk"
              >JSTK souřadnice:</label
              >
              <ejs-textbox
                [ngClass]="
                                    f.jstk.touched && f.jstk.invalid
                                        ? 'e-error'
                                        : f.jstk.touched && f.jstk.valid
                                        ? 'e-success'
                                        : ''
                                "
                floatLabelType="Auto"
                formControlName="jstk"
                id="chckRuian-jstk"
              ></ejs-textbox>
              <small class="text-warning"
              >Tento údaj je doplňován automaticky</small
              >
              <small
                *ngIf="
                                    f.jstk.touched &&
                                    f.jstk.errors &&
                                    f.jstk.errors.required
                                "
                class="text-danger"
              >JSTK souřadnice jsou povinné</small
              >
            </div>
          </div>
          <h6 class="text-muted pt-2">
            <i
              aria-hidden="true"
              class="fa-light fa-lg fa-building"
            ></i
            >&nbsp;Vlastnosti adresy:
          </h6>
          <hr class="pt-0 mt-0" />
          <div class="row">
            <div class="col-12 col-md-6 col-lg-4 mb-3 form-group">
              <label
                [ngClass]="
                                    f.number_of_units.valid &&
                                    (f.number_of_units.dirty ||
                                        f.number_of_units.touched)
                                        ? 'text-success'
                                        : f.number_of_units.invalid &&
                                          (f.number_of_units.dirty ||
                                              f.number_of_units.touched)
                                        ? 'text-danger'
                                        : 'text-muted'
                                "
                for="chckRuian-number_of_units"
              >* Počet bytových jednotek:</label
              >
              <ejs-numerictextbox
                [format]="'###'"
                [ngClass]="
                                    f.number_of_units.touched &&
                                    f.number_of_units.invalid
                                        ? 'e-error'
                                        : f.number_of_units.touched &&
                                          f.number_of_units.valid
                                        ? 'e-success'
                                        : ''
                                "
                formControlName="number_of_units"
                id="chckRuian-number_of_units"
                min="0"
                step="1"
              ></ejs-numerictextbox>
              <small class="text-warning"
              >Tento údaj je doplňován automaticky</small
              >
              <small
                *ngIf="
                                    f.number_of_units.touched &&
                                    f.number_of_units.errors &&
                                    f.number_of_units.errors.required
                                "
                class="text-danger"
              >Počet bytových jednotek je povinný</small
              >
            </div>
            <div class="col-12 col-md-6 col-lg-4 mb-3 form-group">
              <label
                [ngClass]="
                                    f.number_of_storeys.valid &&
                                    (f.number_of_storeys.dirty ||
                                        f.number_of_storeys.touched)
                                        ? 'text-success'
                                        : f.number_of_storeys.invalid &&
                                          (f.number_of_storeys.dirty ||
                                              f.number_of_storeys.touched)
                                        ? 'text-danger'
                                        : 'text-muted'
                                "
                for="chckRuian-number_of_storeys"
              >* Počet podlaží:</label
              >
              <ejs-numerictextbox
                [format]="'###'"
                [ngClass]="
                                    f.number_of_storeys.touched &&
                                    f.number_of_storeys.invalid
                                        ? 'e-error'
                                        : f.number_of_storeys.touched &&
                                          f.number_of_storeys.valid
                                        ? 'e-success'
                                        : ''
                                "
                formControlName="number_of_storeys"
                id="chckRuian-number_of_storeys"
                min="0"
                step="1"
              ></ejs-numerictextbox>
              <small class="text-warning"
              >Tento údaj je doplňován automaticky</small
              >
              <small
                *ngIf="
                                    f.number_of_storeys.touched &&
                                    f.number_of_storeys.errors &&
                                    f.number_of_storeys.errors.required
                                "
                class="text-danger"
              >Počet podlaží je povinný</small
              >
            </div>
            <div class="col-12 col-md-6 col-lg-4 mb-3 form-group">
              <label
                [ngClass]="
                                    f.unit_id.valid
                                        ? 'text-success'
                                        : f.unit_id.invalid &&
                                          (f.unit_id.dirty || f.unit_id.touched)
                                        ? 'text-danger'
                                        : 'text-muted'
                                "
                for="chckRuian-unit_type"
              >Typ bytové jednotky:</label
              >
              <ejs-dropdownlist
                #chck_unit_type
                [allowFiltering]="true"
                [dataSource]="unitSelect"
                [fields]="fieldFieldObj"
                [filterBarPlaceholder]="
                                    'Vyhledat typ bytové jednotky...'
                                "
                [ignoreAccent]="true"
                [placeholder]="'Vyberte typ bytové jednotky'"
                [popupHeight]="height"
                formControlName="unit_id"
                id="chckRuian-unit_type"
                sortOrder="Ascending"
              ></ejs-dropdownlist>
              <small
                *ngIf="
                                    f.unit_id.touched &&
                                    f.unit_id.errors &&
                                    f.unit_id.errors.required
                                "
                class="text-danger"
              >Vyberte typ bytové jednotky</small
              >
            </div>
            <div class="col-12 col-md-6 col-lg-3 mb-3 form-group">
              <label
                [ngClass]="
                                    f.space_id.valid
                                        ? 'text-success'
                                        : f.space_id.invalid &&
                                          (f.space_id.dirty ||
                                              f.space_id.touched)
                                        ? 'text-danger'
                                        : 'text-muted'
                                "
                for="chckRuian-flat_space"
              >Typ prostoru:</label
              >
              <ejs-dropdownlist
                #chck_flat_space
                [allowFiltering]="true"
                [dataSource]="spaceSelect"
                [fields]="fieldFieldObj"
                [filterBarPlaceholder]="
                                    'Vyhledat typ prostoru...'
                                "
                [ignoreAccent]="true"
                [placeholder]="'Vyberte typ prostoru'"
                [popupHeight]="height"
                formControlName="space_id"
                id="chckRuian-flat_space"
                sortOrder="Ascending"
              ></ejs-dropdownlist>
              <small
                *ngIf="
                                    f.space_id.touched &&
                                    f.space_id.errors &&
                                    f.space_id.errors.required
                                "
                class="text-danger"
              >Vyberte typ prostoru</small
              >
            </div>
            <div class="col-12 col-md-6 col-lg-3 mb-3 form-group">
              <label
                [ngClass]="
                                    f.speed_id.valid
                                        ? 'text-success'
                                        : f.speed_id.invalid &&
                                          (f.speed_id.dirty ||
                                              f.speed_id.touched)
                                        ? 'text-danger'
                                        : 'text-muted'
                                "
                for="chckRuian-speed"
              >Limity rychlostí:</label
              >
              <ejs-dropdownlist
                #chck_speed_type
                [allowFiltering]="true"
                [dataSource]="speedsSelect"
                [fields]="fieldFieldObj"
                [filterBarPlaceholder]="
                                    'Vyhledat limit rychlosti...'
                                "
                [ignoreAccent]="true"
                [placeholder]="'Vyberte limit rychlosti'"
                [popupHeight]="height"
                formControlName="speed_id"
                id="chckRuian-speed"
                sortOrder="Ascending"
              ></ejs-dropdownlist>
              <small
                *ngIf="
                                    f.speed_id.touched &&
                                    f.speed_id.errors &&
                                    f.speed_id.errors.required
                                "
                class="text-danger"
              >Vyberte limit rychlosti</small
              >
            </div>
            <div class="col-12 col-md-4 col-lg-3 mb-3 form-group">
              <label
                [ngClass]="
                                    f.owner_name.valid &&
                                    (f.owner_name.dirty || f.owner_name.touched)
                                        ? 'text-success'
                                        : f.owner_name.invalid &&
                                          (f.owner_name.dirty ||
                                              f.owner_name.touched)
                                        ? 'text-danger'
                                        : 'text-muted'
                                "
                for="chckRuian-owner_name"
              >Vlastník:</label
              >
              <ejs-textbox
                [ngClass]="
                                    f.owner_name.touched && f.owner_name.invalid
                                        ? 'e-error'
                                        : f.owner_name.touched &&
                                          f.owner_name.valid
                                        ? 'e-success'
                                        : ''
                                "
                floatLabelType="Auto"
                formControlName="owner_name"
                id="chckRuian-owner_name"
              ></ejs-textbox>
              <small
                *ngIf="
                                    f.owner_name.touched &&
                                    f.owner_name.errors &&
                                    f.owner_name.errors.required
                                "
                class="text-danger"
              >Napište jméno vlastníka objektu</small
              >
            </div>
            <div class="col-8 col-md-4 col-lg-3 mb-3 form-group">
              <label
                [ngClass]="
                                    f.manager_name.valid &&
                                    (f.manager_name.dirty ||
                                        f.manager_name.touched)
                                        ? 'text-success'
                                        : f.manager_name.invalid &&
                                          (f.manager_name.dirty ||
                                              f.manager_name.touched)
                                        ? 'text-danger'
                                        : 'text-muted'
                                "
                for="chckRuian-manager_name"
              >Správce:</label
              >
              <ejs-textbox
                [ngClass]="
                                    f.manager_name.touched &&
                                    f.manager_name.invalid
                                        ? 'e-error'
                                        : f.manager_name.touched &&
                                          f.manager_name.valid
                                        ? 'e-success'
                                        : ''
                                "
                floatLabelType="Auto"
                formControlName="manager_name"
                id="chckRuian-manager_name"
              ></ejs-textbox>
              <small
                *ngIf="
                                    f.manager_name.touched &&
                                    f.manager_name.errors &&
                                    f.manager_name.errors.required
                                "
                class="text-danger"
              >Napište jméno správce objektu</small
              >
            </div>
            <div class="col-4 col-md-4 col-lg-3 mb-3">
              <ejs-checkbox
                [checked]="false"
                cssClass="e-primary p-0 m-0"
                formControlName="lift"
                id="chckRuian-lift"
                label="Výtah"
                name="lift"
              ></ejs-checkbox>
              <br />
              <small class="text-warning"
              >Tento údaj je doplňován automaticky</small
              >
            </div>
          </div>
          <h6 class="text-muted pt-2">
            <i aria-hidden="true" class="fa-light fa-lg fa-cog"></i
            >&nbsp;Technické vlastnosti adresy:
          </h6>
          <hr class="pt-0 mt-0" />
          <div class="row">
            <div class="col-12 mb-3 form-group">
              <label
                [ngClass]="
                                    f.provider_id.valid
                                        ? 'text-success'
                                        : f.provider_id.invalid &&
                                          (f.provider_id.dirty ||
                                              f.provider_id.touched)
                                        ? 'text-danger'
                                        : 'text-muted'
                                "
                for="chckRuian-provider"
              >* Provider:</label
              >
              <ejs-dropdownlist
                #chck_provider
                [allowFiltering]="true"
                [dataSource]="companySelect"
                [fields]="fieldFieldObj"
                [filterBarPlaceholder]="'Vyhledat providera...'"
                [ignoreAccent]="true"
                [placeholder]="'Vyberte providera'"
                [popupHeight]="height"
                formControlName="provider_id"
                id="chckRuian-provider"
                sortOrder="Ascending"
              ></ejs-dropdownlist>
              <small
                *ngIf="
                                    f.provider_id.touched &&
                                    f.provider_id.errors &&
                                    f.provider_id.errors.required
                                "
                class="text-danger"
              >Vyberte providera</small
              >
            </div>
            <div class="col-12 col-md-6 col-lg-3 mb-3 form-group">
              <label
                [ngClass]="
                                    f.technology_id.valid
                                        ? 'text-success'
                                        : f.technology_id.invalid &&
                                          (f.technology_id.dirty ||
                                              f.technology_id.touched)
                                        ? 'text-danger'
                                        : 'text-muted'
                                "
                for="chckRuian-technology"
              >Typ technologie:</label
              >
              <ejs-dropdownlist
                #chck_technology
                [allowFiltering]="true"
                [dataSource]="technologySelect"
                [fields]="fieldFieldObj"
                [filterBarPlaceholder]="
                                    'Vyhledat typ technologie...'
                                "
                [ignoreAccent]="true"
                [placeholder]="'Vyberte typ technologie'"
                [popupHeight]="height"
                formControlName="technology_id"
                id="chckRuian-technology"
                sortOrder="Ascending"
              ></ejs-dropdownlist>
              <small
                *ngIf="
                                    f.technology_id.touched &&
                                    f.technology_id.errors &&
                                    f.technology_id.errors.required
                                "
                class="text-danger"
              >Vyberte typ technologie</small
              >
            </div>
            <div class="col-12 col-md-6 col-lg-3 mb-3 form-group">
              <label
                [ngClass]="
                                    f.transfer_id.valid
                                        ? 'text-success'
                                        : f.transfer_id.invalid &&
                                          (f.transfer_id.dirty ||
                                              f.transfer_id.touched)
                                        ? 'text-danger'
                                        : 'text-muted'
                                "
                for="chckRuian-transfer"
              >Typ přenosu:</label
              >
              <ejs-dropdownlist
                #chck_transfer
                [allowFiltering]="true"
                [dataSource]="transferSelect"
                [fields]="fieldFieldObj"
                [filterBarPlaceholder]="
                                    'Vyhledat typ přenosu...'
                                "
                [ignoreAccent]="true"
                [placeholder]="'Vyberte typ přenosu'"
                [popupHeight]="height"
                formControlName="transfer_id"
                id="chckRuian-transfer"
                sortOrder="Ascending"
              ></ejs-dropdownlist>
              <small
                *ngIf="
                                    f.transfer_id.touched &&
                                    f.transfer_id.errors &&
                                    f.transfer_id.errors.required
                                "
                class="text-danger"
              >Vyberte typ přenosu</small
              >
            </div>
            <div class="col-6 col-lg-3 mb-3 form-group">
              <label
                [ngClass]="
                                    f.electrical_counter.valid &&
                                    (f.electrical_counter.dirty ||
                                        f.electrical_counter.touched)
                                        ? 'text-success'
                                        : f.electrical_counter.invalid &&
                                          (f.electrical_counter.dirty ||
                                              f.electrical_counter.touched)
                                        ? 'text-danger'
                                        : 'text-muted'
                                "
                for="chckRuian-electrical_counter"
              >Elektroměr:</label
              >
              <ejs-textbox
                [ngClass]="
                                    f.electrical_counter.touched &&
                                    f.electrical_counter.invalid
                                        ? 'e-error'
                                        : f.electrical_counter.touched &&
                                          f.electrical_counter.valid
                                        ? 'e-success'
                                        : ''
                                "
                floatLabelType="Auto"
                formControlName="electrical_counter"
                id="chckRuian-electrical_counter"
              ></ejs-textbox>
              <small
                *ngIf="
                                    f.electrical_counter.touched &&
                                    f.electrical_counter.errors &&
                                    f.electrical_counter.errors.required
                                "
                class="text-danger"
              >Elektroměr je povinný</small
              >
            </div>
            <div class="col-6 col-lg-3 mb-3">
              <ejs-checkbox
                [checked]="false"
                cssClass="e-primary p-0 m-0"
                formControlName="keys"
                id="chckRuian-keys"
                label="Klíče"
                name="keys"
              ></ejs-checkbox>
            </div>
            <div class="col-12 mb-2 form-group">
              <label
                [ngClass]="
                                    f.descriptive.valid
                                        ? 'text-success'
                                        : f.descriptive.invalid &&
                                          (f.descriptive.dirty ||
                                              f.descriptive.touched)
                                        ? 'text-danger'
                                        : 'text-muted'
                                "
                for="chckRuian-descriptive"
              >Popis:</label
              >
              <ejs-textbox
                [multiline]="true"
                [ngClass]="{
                                    'e-error': submited && f.descriptive.errors
                                }"
                floatLabelType="Auto"
                formControlName="descriptive"
                id="chckRuian-descriptive"
              ></ejs-textbox>
              <small
                *ngIf="
                                    (f.descriptive.dirty ||
                                        f.descriptive.touched) &&
                                    f.descriptive.errors &&
                                    f.descriptive.errors.required
                                "
                class="text-danger"
              >Vyplňte popis adresy</small
              >
            </div>
            <div class="col-12 mb-2 form-group">
              <label
                [ngClass]="
                                    f.business_note.valid
                                        ? 'text-success'
                                        : f.business_note.invalid &&
                                          (f.business_note.dirty ||
                                              f.business_note.touched)
                                        ? 'text-danger'
                                        : 'text-muted'
                                "
                for="chckRuian-business_note"
              >Obchodní popis:</label
              >
              <ejs-textbox
                [multiline]="true"
                [ngClass]="{
                                    'e-error':
                                        submited && f.business_note.errors
                                }"
                floatLabelType="Auto"
                formControlName="business_note"
                id="chckRuian-business_note"
              ></ejs-textbox>
              <small
                *ngIf="
                                    (f.business_note.dirty ||
                                        f.business_note.touched) &&
                                    f.business_note.errors &&
                                    f.business_note.errors.required
                                "
                class="text-danger"
              >Vyplňte obchodní popis adresy</small
              >
            </div>
          </div>
          <h6 class="text-muted pt-2">
            <i
              aria-hidden="true"
              class="fa-light fa-lg fa-clipboard-list"
            ></i
            >&nbsp;Služby:
          </h6>
          <hr class="pt-0 mt-0" />
          <div class="row">
            <div class="col-12 mb-3 form-group">
              <label
                [ngClass]="
                                    f.net_packages.valid
                                        ? 'text-success'
                                        : f.net_packages.invalid &&
                                          (f.net_packages.dirty ||
                                              f.net_packages.touched)
                                        ? 'text-danger'
                                        : 'text-muted'
                                "
                for="chckRuian-servicesNet"
              >* Služby internet:</label
              >
              <ejs-multiselect
                #chck_net_packages
                (filtering)="onFilteringNetPackages($event)"
                [allowFiltering]="true"
                [dataSource]="servicesNetSelect"
                [fields]="fieldFieldObj"
                [filterBarPlaceholder]="
                                    'Vyhledat internetové služby...'
                                "
                [ignoreAccent]="true"
                [mode]="'CheckBox'"
                [placeholder]="'Vyberte internetové služby'"
                [popupHeight]="height"
                [selectAllText]="'Všechny internetové služby'"
                formControlName="net_packages"
                id="chckRuian-servicesNet"
                showSelectAll="true"
                sortOrder="Ascending"
              ></ejs-multiselect>
              <small class="text-muted"
              >Skladbu tarifů ovlivňuje vybraný provider a
                technologie přenosu.</small
              ><br />
              <small
                *ngIf="
                                    f.net_packages.touched &&
                                    f.net_packages.errors &&
                                    f.net_packages.errors.required
                                "
                class="text-danger"
              >Vyberte internet služby</small
              >
            </div>
            <div class="col-12 mb-3 form-group">
              <label
                [ngClass]="
                                    f.tv_services.valid
                                        ? 'text-success'
                                        : f.tv_services.invalid &&
                                          (f.tv_services.dirty ||
                                              f.tv_services.touched)
                                        ? 'text-danger'
                                        : 'text-muted'
                                "
                for="chckRuian-servicesTv"
              >Služby televize:</label
              >
              <ejs-multiselect
                #chck_tv_services
                (filtering)="onFilteringTvPackages($event)"
                [allowFiltering]="true"
                [dataSource]="servicesTvSelect"
                [fields]="fieldFieldObj"
                [filterBarPlaceholder]="
                                    'Vyhledat televizní služby...'
                                "
                [ignoreAccent]="true"
                [mode]="'CheckBox'"
                [placeholder]="'Vyberte televizní služby'"
                [popupHeight]="height"
                [selectAllText]="'Všechny TV služby'"
                formControlName="tv_services"
                id="chckRuian-servicesTv"
                showSelectAll="true"
                sortOrder="Ascending"
              ></ejs-multiselect>
              <small class="text-muted"
              >Skladbu tarifů ovlivňuje vybraný provider a
                technologie přenosu.</small
              ><br />
              <small
                *ngIf="
                                    f.tv_services.touched &&
                                    f.tv_services.errors &&
                                    f.tv_services.errors.required
                                "
                class="text-danger"
              >Vyberte televizní služby</small
              >
            </div>
          </div>
          <h6 class="text-muted pt-2">
            <i
              aria-hidden="true"
              class="fa-light fa-lg fa-construction"
            ></i
            >&nbsp;Projekt:
          </h6>
          <hr class="pt-0 mt-0" />
          <div class="row">
            <div class="col-12 col-md-6 mb-3 form-group">
              <label
                [ngClass]="
                                    f.project_id.valid
                                        ? 'text-success'
                                        : f.project_id.invalid &&
                                          (f.project_id.dirty ||
                                              f.project_id.touched)
                                        ? 'text-danger'
                                        : 'text-muted'
                                "
                for="chckRuian-project"
              >Projekt:</label
              >
              <ejs-dropdownlist
                #chck_project
                [allowFiltering]="true"
                [dataSource]="projectSelect"
                [fields]="fieldFieldObj"
                [filterBarPlaceholder]="'Vyhledat projekt...'"
                [ignoreAccent]="true"
                [placeholder]="'Vyberte projekt'"
                [popupHeight]="height"
                formControlName="project_id"
                id="chckRuian-project"
                sortOrder="Ascending"
              ></ejs-dropdownlist>
              <small
                *ngIf="
                                    f.project_id.touched &&
                                    f.project_id.errors &&
                                    f.project_id.errors.required
                                "
                class="text-danger"
              >Vyberte projekt</small
              >
            </div>
            <div class="col-12 col-md-6 mb-3 form-group">
              <label
                [ngClass]="
                                    f.realized_at.valid
                                        ? 'text-success'
                                        : f.realized_at.invalid &&
                                          (f.realized_at.dirty ||
                                              f.realized_at.touched)
                                        ? 'text-danger'
                                        : 'text-muted'
                                "
                for="chckRuian-realized_at"
              >Datum realizace:</label
              >
              <ejs-datetimepicker
                [enabled]="true"
                [firstDayOfWeek]="1"
                floatLabelType="Always"
                formControlName="realized_at"
                id="chckRuian-realized_at"
              >
              </ejs-datetimepicker>
              <small
                *ngIf="
                                    f.realized_at.touched &&
                                    f.realized_at.errors &&
                                    f.realized_at.errors.required
                                "
                class="text-danger"
              >Vyberte typ bytové jednotky</small
              >
            </div>
          </div>
          <h6 class="text-muted pt-2">
            <i
              aria-hidden="true"
              class="fa-light fa-lg fa-lightbulb-dollar"
            ></i
            >&nbsp;Marketing:
          </h6>
          <hr class="pt-0 mt-0" />
          <div class="row">
            <div class="col-12 col-md-4 col-lg-5 mb-3 form-group">
              <label
                [ngClass]="
                                    f.deal_id.valid
                                        ? 'text-success'
                                        : f.deal_id.invalid &&
                                          (f.deal_id.dirty || f.deal_id.touched)
                                        ? 'text-danger'
                                        : 'text-muted'
                                "
                for="chckRuian-deal_type"
              >Typ smlouvy:</label
              >
              <ejs-dropdownlist
                #chck_deal_type
                [allowFiltering]="true"
                [dataSource]="dealSelect"
                [fields]="fieldFieldObj"
                [filterBarPlaceholder]="
                                    'Vyhledat typ smluvního úvazku...'
                                "
                [ignoreAccent]="true"
                [placeholder]="'Vyberte typ smluvního úvazku'"
                [popupHeight]="height"
                formControlName="deal_id"
                id="chckRuian-deal_type"
                sortOrder="Ascending"
              ></ejs-dropdownlist>
              <small
                *ngIf="
                                    f.deal_id.touched &&
                                    f.deal_id.errors &&
                                    f.deal_id.errors.required
                                "
                class="text-danger"
              >Vyberte typ smluvního úvazku</small
              >
            </div>
            <div class="col-8 col-md-4 col-lg-5 mb-3 form-group">
              <label
                [ngClass]="
                                    f.discount_id.valid
                                        ? 'text-success'
                                        : f.discount_id.invalid &&
                                          (f.discount_id.dirty ||
                                              f.discount_id.touched)
                                        ? 'text-danger'
                                        : 'text-muted'
                                "
                for="chckRuian-discount"
              >Typ slevy:</label
              >
              <ejs-dropdownlist
                #chck_discount
                [allowFiltering]="true"
                [dataSource]="discountSelect"
                [fields]="fieldFieldObj"
                [filterBarPlaceholder]="
                                    'Vyhledattyp typ slevy...'
                                "
                [ignoreAccent]="true"
                [placeholder]="'Vyberte typ slevy'"
                [popupHeight]="height"
                formControlName="discount_id"
                id="chckRuian-discount"
                sortOrder="Ascending"
              ></ejs-dropdownlist>
              <small
                *ngIf="
                                    f.discount_id.touched &&
                                    f.discount_id.errors &&
                                    f.discount_id.errors.required
                                "
                class="text-danger"
              >Vyberte typ slevy</small
              >
            </div>
            <div class="col-4 col-md-4 col-lg-2 mb-5">
              <ejs-checkbox
                [checked]="false"
                cssClass="e-primary p-0 m-0"
                formControlName="clip_frame"
                id="chckRuian-clip_frame"
                label="Klip rám"
                name="clip_frame"
              ></ejs-checkbox>
            </div>
            <div class="col-12 mb-2 form-group">
              <label
                [ngClass]="
                                    f.marketingnote.valid
                                        ? 'text-success'
                                        : f.marketingnote.invalid &&
                                          (f.marketingnote.dirty ||
                                              f.marketingnote.touched)
                                        ? 'text-danger'
                                        : 'text-muted'
                                "
                for="chckRuian-marketingnote"
              >Marketingový popis:</label
              >
              <ejs-textbox
                [multiline]="true"
                [ngClass]="{
                                    'e-error':
                                        submited && f.marketingnote.errors
                                }"
                floatLabelType="Auto"
                formControlName="marketingnote"
                id="chckRuian-marketingnote"
              ></ejs-textbox>
              <small
                *ngIf="
                                    (f.marketingnote.dirty ||
                                        f.marketingnote.touched) &&
                                    f.marketingnote.errors &&
                                    f.marketingnote.errors.required
                                "
                class="text-danger"
              >Vyplňte marketingový popis adresy</small
              >
            </div>
            <div class="col-12 mb-2 form-group">
              <label
                [ngClass]="
                                    f.marketing_ads.valid
                                        ? 'text-success'
                                        : f.marketing_ads.invalid &&
                                          (f.marketing_ads.dirty ||
                                              f.marketing_ads.touched)
                                        ? 'text-danger'
                                        : 'text-muted'
                                "
                for="chckRuian-marketing_ads"
              >Ostatní reklamy:</label
              >
              <ejs-textbox
                [multiline]="true"
                [ngClass]="{
                                    'e-error':
                                        submited && f.marketing_ads.errors
                                }"
                floatLabelType="Auto"
                formControlName="marketing_ads"
                id="chckRuian-marketing_ads"
              ></ejs-textbox>
              <small
                *ngIf="
                                    (f.marketing_ads.dirty ||
                                        f.marketing_ads.touched) &&
                                    f.marketing_ads.errors &&
                                    f.marketing_ads.errors.required
                                "
                class="text-danger"
              >Vyplňte popis ostatních reklam na adrese</small
              >
            </div>
          </div>
        </div>
      </div>
    </ng-template>
    <ng-template #footerTemplate>
      <div class="d-flex justify-content-end">
                <span
                  *ngIf="checkRuianForm.invalid"
                  class="e-badge e-badge-warning p-2 size_2"
                  role="alert"
                >
                    <strong>Formulář je špatně vyplněný</strong>
                </span>
        <span
          *ngIf="checkRuianForm.valid"
          class="e-badge e-badge-success p-2 size_2"
          role="alert"
        >
                    <strong>Formulář je správně vyplněný</strong>
                </span>
        <button
          (click)="previousBtn()"
          [disabled]="currentTab === 0"
          cssClass="e-info e-btn btn-p-zero e-small mr-1"
          ejs-button
          type="button"
        >
          Předchozí krok
        </button>
        <button
          (click)="nextBtn()"
          [disabled]="
                        !smartWholeAddress || validRuian || currentTab === 1
                    "
          cssClass="e-info e-btn btn-p-zero e-small mr-1"
          ejs-button
          type="button"
        >
          Další krok
        </button>
        <button
          (click)="onSubmit()"
          [disabled]="
                        loadingField || checkRuianForm.invalid || !isChecked
                    "
          cssClass="e-btn e-success btn-p-zero e-small mr-1"
          ejs-button
          type="submit"
        >
                    <span
                      *ngIf="loadingField === true"
                      aria-hidden="true"
                      class="spinner-grow spinner-grow-sm"
                      role="status"
                    ></span>
          <span *ngIf="loadingField === false">ULOŽIT</span>
          <span *ngIf="loadingField === true">Provádím...</span>
        </button>
        <button
          (click)="formDialogObj.hide()"
          cssClass="e-btn e-danger e-outline btn-p-zero e-small"
          ejs-button
          type="button"
        >
          ZRUŠIT
        </button>
      </div>
    </ng-template>
  </form>
</ejs-dialog>
