import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnInit,
    ViewChild,
} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {User} from '@src/app/_models/user/user';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {AuthenticationService} from '@src/app/_services/authentication.service';
import {UsersService} from '@src/app/features/users/users.service';
import {MessageService} from '@src/app/_services/message.service';
import {saveAs} from 'file-saver';
import {
    RuianInput,
    ServicesService,
    SmartAddress,
} from '@src/app/features/services/services.service';
import {DataNetbaseService} from '@src/app/_services/data-netbase.service';
import {AddressConnectedRuian} from '@src/app/_models/services/address-connected-ruian';
import {AddressConnectedRuianPhotos} from '@src/app/_models/services/address-connected-ruian-photos';

import moment from 'moment';
import localeCs from '@angular/common/locales/cs';
import {registerLocaleData} from '@angular/common';
import {TabComponent} from '@syncfusion/ej2-angular-navigations';
import {
    BeforeUploadEventArgs,
    FileInfo,
    RemovingEventArgs,
    SelectedEventArgs,
    SuccessEventArgs,
    UploaderComponent,
    UploadingEventArgs,
} from '@syncfusion/ej2-angular-inputs';
import {
    DropDownListModel,
    FilteringEventArgs,
    MultiSelectComponent,
} from '@syncfusion/ej2-angular-dropdowns';
import {DialogComponent} from '@syncfusion/ej2-angular-popups';
import {DataService} from '@src/app/_services/data.service';
import {PermissionService} from '@src/app/_services/permission.service';
import {Company} from '@src/app/_models/company/company';
import {NetPackages} from '@src/app/_models/services/net-packages';
import {TvServices} from '@src/app/_models/services/tv-services';
import {TechnologyTypes} from '@src/app/_models/services/technology-types';
import {UnitTypes} from '@src/app/_models/services/unit-types';
import {DealTypes} from '@src/app/_models/services/deal-types';
import {Discounts} from '@src/app/_models/services/discounts';
import {SpaceTypes} from '@src/app/_models/services/space-types';
import {TransferTypes} from '@src/app/_models/services/transfer-types';
import {Project} from '@src/app/_models/projects/project';
import {SelectEventArgs} from '@syncfusion/ej2-navigations';
import {EmitType, isNullOrUndefined} from '@syncfusion/ej2-base';
import {InPlaceEditorComponent} from '@syncfusion/ej2-angular-inplace-editor';
import {RichTextEditorModel} from '@syncfusion/ej2-richtexteditor';
import {NumericTextBoxModel, TextBoxModel} from '@syncfusion/ej2-inputs';
import {PopupSettingsModel} from '@syncfusion/ej2-inplace-editor/src/inplace-editor/base/models-model';
import {Switch} from '@syncfusion/ej2-buttons';
import {Query} from '@syncfusion/ej2-data';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {EnvironmentService} from '@src/app/_services/environment.service';
import {Lightbox} from 'ngx-lightbox';
import {GalleryImages} from '@src/app/_models/images/images.type';

moment.updateLocale('cs', {workingWeekdays: [1, 2, 3, 4, 5]});
registerLocaleData(localeCs);

declare let smartform;

@UntilDestroy()
@Component({
    selector: 'app-service-ruian-detail',
    templateUrl: './service-ruian-detail.component.html',
    styleUrls: ['./service-ruian-detail.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ServiceRuianDetailComponent implements OnInit {
    // Tabs
    headerText = [{text: 'Data parcely'}, {text: 'Soubory a fotky'}, {text: 'Historie změn'}];

    // Dropdowns
    height = '240px';

    ruianRuianObj: object = {text: 'label', value: 'value'};

    usersSelect: Array<{value: boolean | number | string; label: string}> = [];

    departmentSelect: Array<{value: boolean | number | string; label: string}> = [];

    companySelect: Array<{value: boolean | number | string; label: string}> = [];

    technologySelect: Array<{value: boolean | number | string; label: string}> = [];

    unitSelect: Array<{value: boolean | number | string; label: string}> = [];

    dealSelect: Array<{value: boolean | number | string; label: string}> = [];

    discountSelect: Array<{value: boolean | number | string; label: string}> = [];

    transferSelect: Array<{value: boolean | number | string; label: string}> = [];

    spaceSelect: Array<{value: boolean | number | string; label: string}> = [];

    servicesNetSelect: Array<{value: boolean | number | string; label: string}> = [];

    servicesTvSelect: Array<{value: boolean | number | string; label: string}> = [];

    projectSelect: Array<{value: boolean | number | string; label: string}> = [];

    // InPlace Editors
    commentEditorModel: RichTextEditorModel = {
        toolbarSettings: {
            enableFloating: false,
            items: [
                'Bold',
                'Italic',
                'Underline',
                'FontColor',
                'BackgroundColor',
                'LowerCase',
                'UpperCase',
                '|',
                'OrderedList',
                'UnorderedList',
            ],
        },
    };

    numericTextBoxModel: NumericTextBoxModel = {
        validateDecimalOnType: true,
        decimals: 0,
        format: '###',
        floatLabelType: 'Auto',
        min: 1,
        step: 1,
        width: '100%',
        placeholder: 'Zadejte celé kladné číslo',
        created() {
            if (this.value === null) {
                this.value = 1;
            }
        },
        blur(args) {
            // checks for nullable value while focus out
            if (args.value === null) {
                this.value = 1;
            }
        },
    };

    overviewModel: TextBoxModel = {
        placeholder: 'Zadejte text',
        width: '100%',
    };

    settings: PopupSettingsModel = {
        title: 'Zadejte hodnotu',
    };

    rules: {[name: string]: {[rule: string]: object}} = {
        address: {required: [true, 'Adresa je povinná']},
        ruian: {required: [true, 'Kód RUIAN je povinný']},
        street: {required: [true, 'Ulice je povinná']},
        city: {required: [true, 'Město je povinné']},
        zip: {required: [true, 'PSČ je povinné']},
    };

    dropDownListUnitModel: DropDownListModel = {
        placeholder: 'Vyberte typ bytové jednotky',
        filterBarPlaceholder: 'Vyhledat typ bytové jednotky...',
        sortOrder: 'Ascending',
        fields: {text: 'label', value: 'value'},
        popupHeight: '240px',
        allowFiltering: true,
        ignoreAccent: true,
        dataSource: [],
    };

    dropDownListSpaceModel: DropDownListModel = {
        placeholder: 'Vyberte typ prostoru',
        filterBarPlaceholder: 'Vyhledat typ prostoru...',
        sortOrder: 'Ascending',
        fields: {text: 'label', value: 'value'},
        popupHeight: '240px',
        allowFiltering: true,
        ignoreAccent: true,
        dataSource: [],
    };

    dropDownListProviderModel: DropDownListModel = {
        placeholder: 'Vyberte providera',
        filterBarPlaceholder: 'Vyhledat providera...',
        sortOrder: 'Ascending',
        fields: {text: 'label', value: 'value'},
        popupHeight: '240px',
        allowFiltering: true,
        ignoreAccent: true,
        dataSource: [],
    };

    dropDownListTechnologyModel: DropDownListModel = {
        placeholder: 'Vyberte typ technologie',
        filterBarPlaceholder: 'Vyhledat typ technologie...',
        sortOrder: 'Ascending',
        fields: {text: 'label', value: 'value'},
        popupHeight: '240px',
        allowFiltering: true,
        ignoreAccent: true,
        dataSource: [],
    };

    dropDownListTransferModel: DropDownListModel = {
        placeholder: 'Vyberte typ přenosu',
        filterBarPlaceholder: 'Vyhledat typ přenosu...',
        sortOrder: 'Ascending',
        fields: {text: 'label', value: 'value'},
        popupHeight: '240px',
        allowFiltering: true,
        ignoreAccent: true,
        dataSource: [],
    };

    dropDownListProjectModel: DropDownListModel = {
        placeholder: 'Vyberte projekt',
        filterBarPlaceholder: 'Vyhledat projekt..',
        sortOrder: 'Ascending',
        fields: {text: 'label', value: 'value'},
        popupHeight: '240px',
        allowFiltering: true,
        ignoreAccent: true,
        dataSource: [],
    };

    dropDownListDealModel: DropDownListModel = {
        placeholder: 'Vyberte typ smluvního úvazku',
        filterBarPlaceholder: 'Vyhledat typ smluvního úvazku...',
        sortOrder: 'Ascending',
        fields: {text: 'label', value: 'value'},
        popupHeight: '240px',
        allowFiltering: true,
        ignoreAccent: true,
        dataSource: [],
    };

    dropDownListDiscountModel: DropDownListModel = {
        placeholder: 'Vyberte typ slevy',
        filterBarPlaceholder: 'Vyhledat typ typ slevy...',
        sortOrder: 'Ascending',
        fields: {text: 'label', value: 'value'},
        popupHeight: '240px',
        allowFiltering: true,
        ignoreAccent: true,
        dataSource: [],
    };

    // Dialogs
    renderProjectFormDialog = false;

    buttonsRemoveRuianDialog = [
        {
            click: this.removeRuian.bind(this),
            buttonModel: {
                content: 'OK',
                cssClass: 'e-success e-outline',
                isFlat: false,
            },
        },
        {
            click: (): void => {
                this.ruianRemoveDialog.hide();
            },
            buttonModel: {content: 'ZRUŠIT', cssClass: 'e-danger e-outline'},
        },
    ];

    buttonsRemoveFileDialog = [
        {
            click: this.onConfirmRemove.bind(this),
            buttonModel: {
                content: 'OK',
                cssClass: 'e-success e-outline',
                isFlat: false,
            },
        },
        {
            click: (): void => {
                this.fileRemoveDialog.hide();
            },
            buttonModel: {content: 'ZRUŠIT', cssClass: 'e-danger e-outline'},
        },
    ];

    // Upload
    path: {
        saveUrl: string;
        removeUrl: string;
    };

    dropElement: HTMLElement = document.getElementsByClassName('control-fluid')[0] as HTMLElement;

    humanizeBytes: (bytes: number) => void;

    deleteFileId: number;

    imagesBasic: GalleryImages[] = [];

    images: AddressConnectedRuianPhotos[] = [];

    currentIndex = -1;

    showFlag = false;

    // Forms
    createFileForm: FormGroup;

    isCreate = false;

    isUpdate = false;

    isCopy = false;

    submited = false;

    // Variables
    currentUser: User | null;

    project: Project;

    ruian?: AddressConnectedRuian | null;

    ruianPhoto: AddressConnectedRuianPhotos;

    connectedClients = 0;

    connectedServices = 0;

    kvPotential = 0;

    choosenNetPackages: Array<number> = [];

    choosenTvServices: Array<number> = [];

    live = true;

    limit = 2;

    page = 1;

    pageFiles = 1;

    pageSize = 5;

    // Loaders
    loadingAddress = false;

    @ViewChild('serviceDetailTab') serviceDetailTabObj: TabComponent;

    @ViewChild('net_packages') netPackagesObj?: MultiSelectComponent;

    @ViewChild('tv_services') tvServicesObj?: MultiSelectComponent;

    @ViewChild('ctrl_address') addressInplaceObj: InPlaceEditorComponent;

    @ViewChild('ctrl_ruian') ruianInplaceObj: InPlaceEditorComponent;

    @ViewChild('ctrl_street') streetInplaceObj: InPlaceEditorComponent;

    @ViewChild('ctrl_city') cityInplaceObj: InPlaceEditorComponent;

    @ViewChild('ctrl_zip') zipInplaceObj: InPlaceEditorComponent;

    @ViewChild('ctrl_city_part') cityPartInplaceObj: InPlaceEditorComponent;

    @ViewChild('ctrl_district') districtInplaceObj: InPlaceEditorComponent;

    @ViewChild('ctrl_region') regionInplaceObj: InPlaceEditorComponent;

    @ViewChild('ctrl_state') stateInplaceObj: InPlaceEditorComponent;

    @ViewChild('ctrl_gps') gpsInplaceObj: InPlaceEditorComponent;

    @ViewChild('ctrl_jstk') jstkInplaceObj: InPlaceEditorComponent;

    @ViewChild('ctrl_number_of_units') unitsInplaceObj: InPlaceEditorComponent;

    @ViewChild('ctrl_number_of_storeys')
    storeysInplaceObj: InPlaceEditorComponent;

    @ViewChild('ctrl_descriptive') descriptiveInplaceObj: InPlaceEditorComponent;

    @ViewChild('ctrl_business_note')
    businessNoteInplaceObj: InPlaceEditorComponent;

    @ViewChild('ctrl_marketingnote')
    marketingNoteInplaceObj: InPlaceEditorComponent;

    @ViewChild('ctrl_marketing_ads')
    marketingAdsInplaceObj: InPlaceEditorComponent;

    @ViewChild('ctrl_owner_name') ownerInplaceObj: InPlaceEditorComponent;

    @ViewChild('ctrl_manager_name') managerInplaceObj: InPlaceEditorComponent;

    @ViewChild('ctrl_electrical_counter')
    electricalCounterInplaceObj: InPlaceEditorComponent;

    @ViewChild('project') projectInplaceObj: InPlaceEditorComponent;

    @ViewChild('deal_type') dealTypeInplaceObj: InPlaceEditorComponent;

    @ViewChild('discount') discountInplaceObj: InPlaceEditorComponent;

    @ViewChild('unit_type') unitTypeInplaceObj: InPlaceEditorComponent;

    @ViewChild('flat_space') flatSpaceInplaceObj: InPlaceEditorComponent;

    @ViewChild('provider') providerInplaceObj: InPlaceEditorComponent;

    @ViewChild('technology') technologyInplaceObj: InPlaceEditorComponent;

    @ViewChild('transfer') transferInplaceObj: InPlaceEditorComponent;

    @ViewChild('ruianRemoveDialog') ruianRemoveDialog: DialogComponent;

    @ViewChild('fileRemoveDialog') fileRemoveDialog: DialogComponent;

    @ViewChild('preloadupload') uploadObj: UploaderComponent;

    constructor(
        private readonly route: ActivatedRoute,
        private readonly authenticationService: AuthenticationService,
        private readonly servicesService: ServicesService,
        private readonly dataNetbaseService: DataNetbaseService,
        private readonly dataService: DataService,
        private readonly ref: ChangeDetectorRef,
        private readonly userService: UsersService,
        private readonly permissionService: PermissionService,
        private readonly messages: MessageService,
        private readonly formBuilder: FormBuilder,
        private readonly router: Router,
        private readonly environmentService: EnvironmentService,
        private readonly lightbox: Lightbox,
    ) {
        this.currentUser = this.authenticationService.currentUserValue;
        this.path = {
            saveUrl: `${this.environmentService.backendURL}/api/upload/service?type=netbase_services&subfolder=ruian`,
            removeUrl: '',
        };
    }

    private loadData(): void {
        this.imagesBasic = [];
        this.images = [];

        const netPackages: number[] = [];
        const tvPackages: number[] = [];

        if (this.ruian) {
            if (this.ruian.net_packages.length > 0) {
                this.ruian.net_packages.forEach((netPackage: NetPackages) => {
                    netPackages.push(netPackage.id);
                });
                this.choosenNetPackages = netPackages;
            }

            if (this.ruian.tv_services && this.ruian.tv_services.length > 0) {
                this.ruian.tv_services.forEach((tvPackage: TvServices) => {
                    tvPackages.push(tvPackage.id);
                });
                this.choosenTvServices = tvPackages;
            }

            if (this.ruian.photos && this.ruian.photos.length > 0) {
                this.ruian.photos.forEach((photo: AddressConnectedRuianPhotos) => {
                    if (photo.extension === 'image/jpeg' || photo.extension === 'image/png') {
                        this.images.push(photo);
                        this.imagesBasic.push({
                            src: photo.url,
                            thumb: photo.url_thumbnail,
                            caption: photo.name,
                        });
                    }
                });
            }

            if (this.tvServicesObj) {
                this.tvServicesObj.refresh();
            }

            if (this.netPackagesObj) {
                this.netPackagesObj.refresh();
            }

            new Switch({checked: true}).appendTo('#switchLift');
            new Switch({checked: true}).appendTo('#switchKeys');
            new Switch({checked: true}).appendTo('#switchClip');

            this.loadingAddress = false;
            this.ref.markForCheck();
        }
    }

    reinitSmartForm(): void {
        console.info('Reinitialize SF objects...');
        smartform.rebindAllForms(false, () => {
            if (smartform.getInstance('smartform-instance-create')) {
                const instanceCreate = smartform.getInstance('smartform-instance-create');

                instanceCreate.setZIndex(100000000);
                instanceCreate.setSelectionBackgroundColor('#4285f3');
                instanceCreate.addAnnounceCallback((validationType, addressArray) => {
                    if (validationType === smartform.ValidationResultType.HIT) {
                        const addressData: SmartAddress = {};

                        addressData.oriental_number = parseInt(
                            addressArray[0].NUMBER_ORIENTACNI as string,
                            10,
                        );

                        addressData.house_number = parseInt(
                            addressArray[0].NUMBER_POPISNE as string,
                            10,
                        );
                        addressData.street = addressArray[0].STREET;
                        addressData.street_code = parseInt(
                            addressArray[0].STREET_CODE as string,
                            10,
                        );
                        addressData.city_part = addressArray[0].PART;
                        addressData.city_part_code = parseInt(
                            addressArray[0].PART_CODE as string,
                            10,
                        );
                        addressData.city = addressArray[0].CITY;
                        addressData.city_code = parseInt(addressArray[0].CITY_CODE as string, 10);
                        addressData.zip = parseInt(addressArray[0].ZIP as string, 10);
                        addressData.district = addressArray[0].DISTRICT;
                        addressData.district_code = parseInt(
                            addressArray[0].DISTRICT_CODE as string,
                            10,
                        );
                        addressData.region = addressArray[0].REGION;
                        addressData.region_code = parseInt(
                            addressArray[0].REGION_CODE as string,
                            10,
                        );

                        addressData.number_of_units = parseInt(
                            addressArray[0].NUMBER_OF_FLATS as string,
                            10,
                        );

                        addressData.number_of_storeys = parseInt(
                            addressArray[0].NUMBER_OF_STOREYS as string,
                            10,
                        );
                        addressData.lift = addressArray[0].BUILDING_WITH_LIFT === 'true';
                        addressData.state = addressArray[0].COUNTRY;
                        addressData.gps = `${addressArray[0].GPS_LAT as string} / ${
                            addressArray[0].GPS_LONG as string
                        }`;

                        addressData.jstk = `${addressArray[0].COORD_X as string} / ${
                            addressArray[0].COORD_Y as string
                        }`;
                    }

                    this.ref.markForCheck();
                });
            }
        });
    }

    ngOnInit(): void {
        this.dataNetbaseService.ruianDetailSource.pipe(untilDestroyed(this))
            .subscribe({
                next: (ruian: AddressConnectedRuian | null | undefined) => {
                    this.ruian = ruian;

                    if (this.ruian) {
                        this.loadData();
                    }
                },
                error: error => {
                    console.error(error);
                    this.loadingAddress = false;
                    this.ref.markForCheck();
                },
            });

        this.dataService.companySource.pipe(untilDestroyed(this))
            .subscribe({
                next: (companies: Company[] | undefined) => {
                    this.companySelect = [];
                    companies?.forEach((company: Company) => {
                        if (!company.deleted_date) {
                            this.companySelect = [
                                ...this.companySelect,
                                {
                                    value: company.id,
                                    label: company.name,
                                },
                            ];
                        }
                    });
                    this.dropDownListProviderModel.dataSource = this.companySelect;
                    this.ref.markForCheck();
                },
                error: error => {
                    console.error(error);
                },
            });

        this.dataNetbaseService.netPackagesSource.pipe(untilDestroyed(this))
            .subscribe({
                next: (netTarifs: NetPackages[] | undefined) => {
                    this.servicesNetSelect = [];
                    netTarifs?.forEach((netTarif: NetPackages) => {
                        if (!netTarif.deleted_date) {
                            this.servicesNetSelect = [
                                ...this.servicesNetSelect,
                                {
                                    value: netTarif.id,
                                    label: `${netTarif.name}${
                                        netTarif.transfer.connection_method === 'optical cable'
                                            ? ' - optické připojení'
                                            : netTarif.transfer.connection_method === 'metalic cable'
                                                ? ' - kabelové připojení'
                                                : netTarif.transfer.connection_method === 'radio'
                                                    ? ' - rádiové připojení'
                                                    : '- xDSL připojení'
                                    }`,
                                },
                            ];
                        }
                    });
                    this.ref.markForCheck();
                },
                error: error => {
                    console.error(error);
                },
            });

        this.dataNetbaseService.tvServicesSource.pipe(untilDestroyed(this))
            .subscribe({
                next: (tvServices: TvServices[] | undefined) => {
                    this.servicesTvSelect = [];
                    tvServices?.forEach((tvService: TvServices) => {
                        if (!tvService.deleted_date) {
                            this.servicesTvSelect = [
                                ...this.servicesTvSelect,
                                {
                                    value: tvService.id,
                                    label: `${tvService.name}${
                                        tvService.transfer.connection_method === 'optical cable'
                                            ? ' - optické připojení'
                                            : tvService.transfer.connection_method === 'metalic cable'
                                                ? ' - kabelové připojení'
                                                : tvService.transfer.connection_method === 'radio'
                                                    ? ' - rádiové připojení'
                                                    : '- xDSL připojení'
                                    }`,
                                },
                            ];
                        }
                    });
                    this.ref.markForCheck();
                },
                error: error => {
                    console.error(error);
                },
            });

        this.dataNetbaseService.technologyTypesSource.pipe(untilDestroyed(this))
            .subscribe({
                next: (technologies: TechnologyTypes[] | undefined) => {
                    this.technologySelect = [];
                    technologies?.forEach((technology: TechnologyTypes) => {
                        if (!technology.deleted_date) {
                            this.technologySelect = [
                                ...this.technologySelect,
                                {value: technology.id, label: technology.name},
                            ];
                        }
                    });
                    this.dropDownListTechnologyModel.dataSource = this.technologySelect;
                    this.ref.markForCheck();
                },
                error: error => {
                    console.error(error);
                },
            });

        this.dataNetbaseService.unitTypesSource.pipe(untilDestroyed(this))
            .subscribe({
                next: (units: UnitTypes[] | undefined) => {
                    this.unitSelect = [];
                    units?.forEach((unit: UnitTypes) => {
                        if (!unit.deleted_date) {
                            this.unitSelect = [
                                ...this.unitSelect,
                                {value: unit.id, label: unit.name},
                            ];
                        }
                    });
                    this.dropDownListUnitModel.dataSource = this.unitSelect;
                    this.ref.markForCheck();
                },
                error: error => {
                    console.error(error);
                },
            });

        this.dataNetbaseService.dealTypesSource.pipe(untilDestroyed(this))
            .subscribe({
                next: (deals: DealTypes[] | undefined) => {
                    this.dealSelect = [];
                    deals?.forEach((deal: DealTypes) => {
                        if (!deal.deleted_date) {
                            this.dealSelect = [
                                ...this.dealSelect,
                                {value: deal.id, label: `${deal.age} měsíců`},
                            ];
                        }
                    });
                    this.dropDownListDealModel.dataSource = this.dealSelect;
                    this.ref.markForCheck();
                },
                error: error => {
                    console.error(error);
                },
            });

        this.dataNetbaseService.discountSource.pipe(untilDestroyed(this))
            .subscribe({
                next: (discounts: Discounts[] | undefined) => {
                    this.discountSelect = [];
                    discounts?.forEach((discount: Discounts) => {
                        if (!discount.deleted_date) {
                            this.discountSelect = [
                                ...this.discountSelect,
                                {value: discount.id, label: `${discount.discount}%`},
                            ];
                        }
                    });
                    this.dropDownListDiscountModel.dataSource = this.discountSelect;
                    this.ref.markForCheck();
                },
                error: error => {
                    console.error(error);
                },
            });

        this.dataNetbaseService.spaceTypesSource.pipe(untilDestroyed(this))
            .subscribe({
                next: (spaces: SpaceTypes[] | undefined) => {
                    this.spaceSelect = [];
                    spaces?.map((space: SpaceTypes) => {
                        if (!space.deleted_date) {
                            this.spaceSelect = [
                                ...this.spaceSelect,
                                {
                                    value: space.id,
                                    label: `${space.metric_until} m2 - ${
                                        space.metric_to ? space.metric_to : '*'
                                    } m2`,
                                },
                            ];
                        }
                    });
                    this.dropDownListSpaceModel.dataSource = this.spaceSelect;
                    this.ref.markForCheck();
                },
                error: error => {
                    console.error(error);
                },
            });

        this.dataNetbaseService.transferTypesSource.pipe(untilDestroyed(this))
            .subscribe({
                next: (transfers: TransferTypes[] | undefined) => {
                    this.transferSelect = [];
                    transfers?.forEach((transfer: TransferTypes) => {
                        if (!transfer.deleted_date) {
                            this.transferSelect = [
                                ...this.transferSelect,
                                {
                                    value: transfer.id,
                                    label: transfer.name,
                                },
                            ];
                        }
                    });
                    this.dropDownListTransferModel.dataSource = this.transferSelect;
                    this.ref.markForCheck();
                },
                error: error => {
                    console.error(error);
                },
            });

        this.dataService.projectSource.pipe(untilDestroyed(this))
            .subscribe({
                next: (projects: Project[] | undefined) => {
                    this.projectSelect = [];
                    projects?.forEach((project: Project) => {
                        if (!project.deleted_date) {
                            this.projectSelect = [
                                ...this.projectSelect,
                                {
                                    value: project.id,
                                    label: project.name,
                                },
                            ];
                        }
                    });
                    this.dropDownListProjectModel.dataSource = this.projectSelect;
                    this.ref.markForCheck();
                },
                error: error => {
                    console.error(error);
                },
            });

        this.createFileForm = this.formBuilder.group({
            photos: ['', Validators.required],
        });

        this.route.params.pipe(untilDestroyed(this))
            .subscribe(params => {
                this.dataNetbaseService.setRuianDetailSource(parseInt(params.id as string, 10));
                this.ref.markForCheck();
            });

        this.dataService.setCompanyDataSource();
        this.dataService.setProjectsDataSource();
        this.dataNetbaseService.setTechnologyTypesDataSource();
        this.dataNetbaseService.setDiscountsDataSource();
        this.dataNetbaseService.setDealTypesDataSource();
        this.dataNetbaseService.setUnitTypesDataSource();
        this.dataNetbaseService.setSpaceTypesDataSource();
        this.dataNetbaseService.setNetPackagesDataSource();
        this.dataNetbaseService.setTvServicesDataSource();
        this.dataNetbaseService.setTransferTypesDataSource();
    }

    open(index: number): void {
        this.lightbox.open(this.imagesBasic, index);
    }

    close(): void {
        this.lightbox.close();
    }

    onCreate(): void {
        // Nastavení GUI podle URL parametrů
        this.route.queryParamMap.pipe(untilDestroyed(this))
            .subscribe(queryParams => {
                if (queryParams.get('view') === 'data') {
                    this.serviceDetailTabObj.selectedItem = 0;
                } else if (queryParams.get('view') === 'files') {
                    this.serviceDetailTabObj.selectedItem = 1;
                } else if (queryParams.get('view') === 'logs') {
                    this.serviceDetailTabObj.selectedItem = 2;
                } else {
                    this.serviceDetailTabObj.selectedItem = 0;
                }
            });
    }

    preventSwipe(e: SelectEventArgs): void {
        if (e.isSwiped) {
            e.cancel = true;
        }
    }

    select(e: SelectEventArgs): void {
        let queryParams: Params = {view: null};

        if (e.selectedItem.textContent === 'Data parcely') {
            queryParams = {
                view: 'data',
                form: null,
            };
        }

        if (e.selectedItem.textContent === 'Soubory a fotky') {
            queryParams = {
                view: 'files',
            };
        }

        if (e.selectedItem.textContent === 'Historie změn') {
            queryParams = {
                view: 'logs',
                form: null,
            };
        }

        void this.router.navigate([], {
            relativeTo: this.route,
            queryParams,
            queryParamsHandling: 'merge',
        });

        this.reinitSmartForm();
    }

    onFilteringTvPackages: EmitType<FilteringEventArgs> = (e: FilteringEventArgs) => {
        if (e.text === '') {
            e.updateData(this.servicesTvSelect);
        } else {
            let query: Query = new Query();

            query = e.text !== '' ? query.where('label', 'contains', e.text, true, true) : query;
            e.updateData(this.servicesTvSelect, query);
        }
    };

    onFilteringNetPackages: EmitType<FilteringEventArgs> = (e: FilteringEventArgs) => {
        if (e.text === '') {
            e.updateData(this.servicesNetSelect);
        } else {
            let query: Query = new Query();

            query = e.text !== '' ? query.where('label', 'contains', e.text, true, true) : query;
            e.updateData(this.servicesNetSelect, query);
        }
    };

    actionChange(value: MouseEvent, input?: string): void {
        if (!input) {
            console.error('input is missing...');

            return;
        }

        this.loadingAddress = true;
        this.ref.markForCheck();

        if (this.currentUser && this.permissionService.checkUserISServiceAdmin(this.currentUser)) {
            this.servicesService
                .updateAddressRuian({id: this.ruian?.id, [input]: value})
                ?.pipe(untilDestroyed(this))
                .subscribe({
                    next: (ruian: AddressConnectedRuian) => {
                        if (this.uploadObj.getFilesData().length > 0) {
                            this.uploadObj.asyncSettings.saveUrl = `${this.environmentService.backendURL}/api/upload/service?type=netbase_services&subfolder=ruian&id=${ruian.id}`;
                            this.uploadObj.upload(this.uploadObj.getFilesData()[0]);
                        }

                        const body = `Adresa #${ruian.id}`;
                        const options = {
                            progressBar: true,
                            timeOut: 5000,
                            toastClass: 'success',
                        };

                        this.messages.showSuccess('Adresa úspěšně upravena', body, options);
                        this.loadingAddress = false;
                        this.dataNetbaseService.setRuianAddressDataSource();
                        this.ref.markForCheck();
                    },
                    error: error => {
                        console.error(error);

                        const body = 'Zkuste to později ...';
                        const options = {
                            progressBar: true,
                            timeOut: 5000,
                            toastClass: 'red',
                        };

                        this.messages.showError(
                            'Chyba během upravování připojené adresy',
                            body,
                            options,
                        );
                        this.loadingAddress = false;
                        this.ref.markForCheck();
                    },
                });
        } else {
            const body = 'Adresu nemůžete upravit';
            const options = {progressBar: true, timeOut: 5000};

            this.messages.showError('Nedostatečné oprávnění', body, options);
            this.loadingAddress = false;
            this.ref.markForCheck();
        }
    }

    // Edit
    editRuian(data: RuianInput): void {
        if (this.currentUser && this.permissionService.checkUserISServiceAdmin(this.currentUser)) {
            this.submited = true;
            this.loadingAddress = true;
            this.loadingAddress = false;
            this.servicesService
                .updateAddressRuian(data)
                ?.pipe(untilDestroyed(this))
                .subscribe({
                    next: () => {
                        const options = {progressBar: true, timeOut: 5000};

                        this.messages.showSuccess('Adresa RUIAN úspěšně upravena', '', options);
                        this.loadingAddress = false;
                        this.ref.markForCheck();

                        if (!this.ruian) {
                            console.error('this.ruian is missing...');

                            return;
                        }

                        this.dataNetbaseService.setRuianDetailSource(this.ruian.id);
                    },
                    error: error => {
                        console.error(error);

                        const body = 'Zkuste to znovu později';
                        const options = {progressBar: true, timeOut: 5000};

                        this.messages.showError('Chyba při úpravš parcely', body, options);
                        this.loadingAddress = false;
                        this.ref.markForCheck();
                    },
                });
        } else {
            const body = 'Nemáte oprávnění provést tuto akci...';
            const options = {progressBar: true, timeOut: 5000};

            this.messages.showError('Nedostatečné oprávnění', body, options);
            this.ref.markForCheck();
        }
    }

    saveChanges(): void {
        if (this.currentUser && this.permissionService.checkUserISServiceAdmin(this.currentUser)) {
            const data: RuianInput = this.ruian as RuianInput;

            this.editRuian(data);
        } else {
            const body = 'Nemáte oprávnění provést tuto akci...';
            const options = {progressBar: true, timeOut: 5000};

            this.messages.showError('Nedostatečné oprávnění', body, options);
            this.ref.markForCheck();
        }
    }

    // Remove
    removeRuian(): void {
        if (
            this.ruian &&
            this.currentUser &&
            this.permissionService.checkUserISServiceAdmin(this.currentUser)
        ) {
            this.ruian.deleted_by = this.currentUser.id;
            this.servicesService
                .deleteAddressRuian(this.ruian)
                ?.pipe(untilDestroyed(this))
                .subscribe({
                    next: (data: AddressConnectedRuian) => {
                        const body = `Adresa RUIAN #${data.id}`;
                        const options = {
                            progressBar: true,
                            timeOut: 5000,
                            toastClass: 'success',
                        };

                        this.messages.showSuccess('Adresa RUIAN úspěšně smazána', body, options);
                        this.loadingAddress = false;
                        this.ref.markForCheck();

                        if (!this.ruian) {
                            console.error('this.ruian is missing...');

                            return;
                        }

                        this.dataNetbaseService.setRuianDetailSource(this.ruian.id);
                    },
                    error: error => {
                        console.error(error);
                        this.loadingAddress = false;
                        this.ref.markForCheck();
                    },
                });
        } else {
            const body = 'Nemáte oprávnění provést tuto akci...';
            const options = {progressBar: true, timeOut: 5000};

            this.messages.showError('Nedostatečné oprávnění', body, options);
            this.ref.markForCheck();
        }
    }

    onConfirmRemove(): void {
        if (this.currentUser && this.permissionService.checkUserISServiceAdmin(this.currentUser)) {
            this.loadingAddress = true;
            this.ruianPhoto.deleted_by = this.currentUser.id;
            this.servicesService
                .deleteServiceFile(this.ruianPhoto, false)
                ?.pipe(untilDestroyed(this))
                .subscribe({
                    next: data => {
                        const body = `Soubor #${data?.id ?? 'null'}`;
                        const options = {
                            progressBar: true,
                            timeOut: 5000,
                            toastClass: 'success',
                        };

                        this.messages.showSuccess('Soubor úspěšně smazán', body, options);
                        this.fileRemoveDialog.hide();
                        this.loadingAddress = false;
                        this.ref.markForCheck();

                        if (!this.ruian) {
                            console.error('this.ruian is missing...');

                            return;
                        }

                        this.dataNetbaseService.setRuianDetailSource(this.ruian);
                    },
                    error: error => {
                        this.loadingAddress = false;
                        this.ref.markForCheck();
                        console.error(error);
                    },
                });
        } else {
            const body = 'Nemáte oprávnění provést tuto akci...';
            const options = {progressBar: true, timeOut: 5000};

            this.messages.showError('Nedostatečné oprávnění', body, options);
            this.ref.markForCheck();
        }
    }

    // Files
    downloadFile(file: AddressConnectedRuianPhotos): void {
        this.servicesService
            .downloadServiceFile(file)
            .pipe(untilDestroyed(this))
            .subscribe({
                next: data => {
                    saveAs(data, file.name);
                },
                error: error => {
                    alert('Chyba během stahování souboru.');
                    console.error(error);
                },
            });
    }

    onFileSelected(args: SelectedEventArgs): void {
        // Filter the 5 files only to showcase
        args.filesData.splice(5);

        const filesData: FileInfo[] = this.uploadObj.getFilesData();
        const allFiles: FileInfo[] = filesData.concat(args.filesData);

        if (allFiles.length > 5) {
            // eslint-disable-next-line @typescript-eslint/prefer-for-of
            for (let i = 0; i < allFiles.length; i++) {
                if (allFiles.length > 5) {
                    allFiles.shift();
                }
            }

            args.filesData = allFiles;
            // set the modified custom data
            args.modifiedFilesData = args.filesData;
        }

        let existingFiles: FileInfo[] = this.uploadObj.getFilesData();

        for (let i = 0; i < args.filesData.length; i++) {
            // eslint-disable-next-line @typescript-eslint/prefer-for-of
            for (let j = 0; j < existingFiles.length; j++) {
                if (!isNullOrUndefined(args.filesData[i])) {
                    // eslint-disable-next-line eqeqeq
                    if (existingFiles[j].name == args.filesData[i].name) {
                        args.filesData.splice(i, 1);
                    }
                }
            }
        }

        existingFiles = existingFiles.concat(args.filesData);
        args.modifiedFilesData = existingFiles;
        args.isModified = true;
    }

    beforeFileUpload(args: BeforeUploadEventArgs): void {
        if (this.ruian && this.currentUser) {
            args.customFormData = [
                {id: this.ruian.id},
                {created_by: this.currentUser.id},
                {valid_ruian: true},
            ];
        }
    }

    onFileUpload: EmitType<SelectedEventArgs> = (args: UploadingEventArgs) => {
        args.currentRequest?.setRequestHeader(
            'Authorization',
            `Bearer ${this.currentUser?.token ?? 'null'}`,
        );

        if (this.ruian && this.currentUser) {
            args.customFormData = [
                {id: this.ruian.id},
                {created_by: this.currentUser.id},
                {valid_ruian: true},
            ];
        }
    };

    onSuccessUpload(args: SuccessEventArgs): void {
        if (args.file && this.ruian) {
            const body = `Soubor: ${args.file.name}`;
            const options = {progressBar: true, timeOut: 5000, toastClass: 'success'};

            this.messages.showSuccess('Soubor úspěšně nahrán', body, options);
            this.dataNetbaseService.setRuianDetailSource(this.ruian);
        }
    }

    onFileRemove(args: RemovingEventArgs): void {
        args.postRawFile = false;

        const ruianPhoto = this.ruian?.photos?.find(file => file.name === args.filesData[0].name);

        if (ruianPhoto) {
            this.ruianPhoto = ruianPhoto;
        }

        this.fileRemoveDialog.show();
    }

    // Projects
    newProject(): void {
        if (this.currentUser && this.permissionService.checkUserISServiceAdmin(this.currentUser)) {
            this.isCopy = false;
            this.isCreate = true;
            this.isUpdate = false;
            this.renderProjectFormDialog = true;
        } else {
            const body = 'Nemáte oprávnění provést tuto akci...';
            const options = {progressBar: true, timeOut: 5000};

            this.messages.showError('Nedostatečné oprávnění', body, options);
            this.loadingAddress = false;
            this.ref.markForCheck();
        }
    }
}
