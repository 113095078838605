import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnInit,
    ViewChild,
} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {AuthenticationService} from '@src/app/_services/authentication.service';
import {DataService} from '@src/app/_services/data.service';
import {MessageService} from '@src/app/_services/message.service';
import {TimeagoIntl} from 'ngx-timeago';
import {strings as czechStrings} from 'ngx-timeago/language-strings/cs';
import {Hotliner} from '@src/app/_models/hotline/hotliner';
import {Hotline} from '@src/app/_models/hotline/hotline';
import {HotlineReport} from '@src/app/_models/hotline/report';
import {PermissionService} from '@src/app/_services/permission.service';
import {DialogComponent} from '@syncfusion/ej2-angular-popups';
import {User} from '@src/app/_models/user/user';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {HotlineService} from '@src/app/features/hotline/hotline.service';
import {UsersService} from '@src/app/features/users/users.service';

@UntilDestroy()
@Component({
    selector: 'app-hotline-detail',
    templateUrl: './hotline-detail.component.html',
    styleUrls: ['./hotline-detail.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HotlineDetailComponent implements OnInit {
    // Dialogs
    renderHotlineFormDialog = false;

    renderHotlineReportFormDialog = false;

    buttonsDeleteHotlineDialog = [
        {
            click: this.removeHotline.bind(this),
            buttonModel: {
                content: 'OK',
                cssClass: 'e-success e-outline',
                isFlat: false,
            },
        },
        {
            click: (): void => {
                this.deleteHotlineDialog.hide();
            },
            buttonModel: {content: 'ZRUŠIT', cssClass: 'e-danger e-outline'},
        },
    ];

    buttonsDeleteReportDialog = [
        {
            click: this.removeReportHotline.bind(this),
            buttonModel: {
                content: 'OK',
                cssClass: 'e-success e-outline',
                isFlat: false,
            },
        },
        {
            click: (): void => {
                this.deleteReportDialog.hide();
            },
            buttonModel: {content: 'ZRUŠIT', cssClass: 'e-danger e-outline'},
        },
    ];

    // Forms
    isCreate = false;

    isCopy = false;

    isUpdate = false;

    isCreateReport = false;

    isCopyReport = false;

    isUpdateReport = false;

    // Variables
    currentUser: User | null;

    hotline: Hotline;

    hotliner: Hotliner;

    hotliners: Hotliner[] = [];

    highlightedReport: HotlineReport;

    live: true;

    // Loaders
    loadingHotline = true;

    @ViewChild('deleteHotlineDialog') deleteHotlineDialog: DialogComponent;

    @ViewChild('deleteReportDialog') deleteReportDialog: DialogComponent;

    constructor(
        private readonly route: ActivatedRoute,
        private readonly authenticationService: AuthenticationService,
        private readonly ref: ChangeDetectorRef,
        private readonly dataService: DataService,
        private readonly permissionService: PermissionService,
        private readonly hotlineService: HotlineService,
        private readonly userService: UsersService,
        private readonly messages: MessageService,
        private readonly intl: TimeagoIntl,
    ) {
        this.currentUser = this.authenticationService.currentUserValue;
        this.intl.strings = czechStrings;
        this.intl.changes.next();
    }

    ngOnInit(): void {
        this.dataService.hotlineDetailSource.pipe(untilDestroyed(this))
            .subscribe(
                (hotline: Hotline) => {
                    this.hotline = hotline;
                    this.loadingHotline = false;
                    this.ref.markForCheck();
                },
                error => {
                    console.error(error);
                    this.loadingHotline = false;
                    this.ref.markForCheck();
                },
            );

        this.dataService.hotlinersSource.pipe(untilDestroyed(this))
            .subscribe(
                (hotliners: Hotliner[]) => {
                    this.hotliners = hotliners;
                    this.ref.markForCheck();
                },
                error => {
                    console.error(error);
                },
            );

        this.route.params.pipe(untilDestroyed(this))
            .subscribe(params => {
                this.dataService.setHotlineDetailDataSource(parseInt(params.id as string, 10));
            });

        this.dataService.setHotlinersDataSource();
    }

    addReport(): void {
        if (
            this.currentUser &&
            (this.currentUser.is_hotliner ||
                this.permissionService.checkUserISHotlineAdmin(this.currentUser))
        ) {
            this.isCopyReport = false;
            this.isUpdateReport = false;
            this.isCreateReport = true;
            this.renderHotlineReportFormDialog = true;
        } else {
            const body = 'Nemáte oprávnění provést tuto akci...';
            const options = {progressBar: true, timeOut: 5000};

            this.messages.showError('Nedostatečné oprávnění', body, options);
            this.loadingHotline = false;
            this.ref.markForCheck();
        }
    }

    editReport(): void {
        if (
            this.currentUser &&
            ((this.currentUser.is_hotliner &&
                    this.hotline.hotliner.user_id === this.currentUser.id) ||
                this.permissionService.checkUserISHotlineAdmin(this.currentUser))
        ) {
            this.isCopyReport = false;
            this.isUpdateReport = true;
            this.isCreateReport = false;
            this.renderHotlineReportFormDialog = true;
        } else {
            const body = 'Nemáte oprávnění provést tuto akci...';
            const options = {progressBar: true, timeOut: 5000};

            this.messages.showError('Nedostatečné oprávnění', body, options);
            this.loadingHotline = false;
            this.ref.markForCheck();
        }
    }

    removeReportHotline(): void {
        if (
            this.currentUser &&
            ((this.currentUser.is_hotliner &&
                    this.hotline.hotliner.user_id === this.currentUser.id) ||
                this.permissionService.checkUserISHotlineAdmin(this.currentUser))
        ) {
            this.highlightedReport.deleted_by = this.currentUser.id;
            this.hotlineService
                .deleteReport(this.highlightedReport)
                ?.pipe(untilDestroyed(this))
                .subscribe(
                    (data: HotlineReport) => {
                        const body = `Zápis hot-line #${data.id}`;
                        const options = {
                            progressBar: true,
                            timeOut: 5000,
                            toastClass: 'success',
                        };

                        this.messages.showSuccess('Zápis hot-line úspěšně smazán', body, options);
                        this.dataService.setHotlinesDataSource();
                        this.dataService.setHotlineDetailDataSource(this.hotline.id);
                        this.deleteReportDialog.hide();
                        this.loadingHotline = false;
                        this.ref.markForCheck();
                    },
                    error => {
                        console.error(error);
                        this.loadingHotline = false;
                        this.ref.markForCheck();
                    },
                );
        } else {
            const body = 'Nemáte oprávnění provést tuto akci...';
            const options = {progressBar: true, timeOut: 5000};

            this.messages.showError('Nedostatečné oprávnění', body, options);
            this.loadingHotline = false;
            this.deleteReportDialog.hide();
            this.ref.markForCheck();
        }
    }

    editHotline(): void {
        this.isCopy = false;
        this.isUpdate = true;
        this.renderHotlineFormDialog = true;
        this.ref.markForCheck();
    }

    removeHotline(): void {
        if (this.currentUser && this.permissionService.checkUserISHotlineAdmin(this.currentUser)) {
            this.hotline.deleted_by = this.currentUser.id;
            this.hotlineService
                .deleteHotline(this.hotline)
                .pipe(untilDestroyed(this))
                .subscribe(
                    (data: Hotline) => {
                        const body = `Hot-Line #${data.id}`;
                        const options = {
                            progressBar: true,
                            timeOut: 5000,
                            toastClass: 'success',
                        };

                        this.messages.showSuccess('Hot-Line úspěšně smazána', body, options);
                        this.dataService.setHotlinesDataSource();
                        this.dataService.setHotlineDetailDataSource(this.hotline.id);
                        this.deleteHotlineDialog.hide();
                        this.loadingHotline = false;
                        this.ref.markForCheck();
                    },
                    error => {
                        console.error(error);
                        this.loadingHotline = false;
                        this.ref.markForCheck();
                    },
                );
        } else {
            const body = 'Nemáte oprávnění provést tuto akci...';
            const options = {progressBar: true, timeOut: 5000};

            this.messages.showError('Nedostatečné oprávnění', body, options);
            this.loadingHotline = false;
            this.deleteHotlineDialog.hide();
            this.ref.markForCheck();
        }
    }
}
