<div
  class="row d-flex justify-content-center modalWrapper"
  id="dialogContainer"
>
  <ejs-dialog
    #fieldRemoveDialog
    [buttons]="buttonsRemoveRuianDialog"
    [content]="'Opravdu chcete smazat tuto parcelu?'"
    [header]="'Mazání parcely'"
    [height]="'200px'"
    [isModal]="true"
    [showCloseIcon]="true"
    [target]="'#dialogContainer'"
    [visible]="false"
    [width]="'350px'"
    id="fieldRemoveDialog"
  ></ejs-dialog>
  <ejs-dialog
    #fileRemoveDialog
    [buttons]="buttonsRemoveFileDialog"
    [content]="'Opravdu chcete smazat soubor?'"
    [header]="'Mazání souboru'"
    [height]="'200px'"
    [isModal]="true"
    [showCloseIcon]="true"
    [target]="'#dialogContainer'"
    [visible]="false"
    [width]="'350px'"
    id="fileRemoveDialog"
  ></ejs-dialog>
  <app-project-form
    (projectFormDialogState)="
            renderProjectFormDialog = $event;
            isCreate = $event;
            isUpdate = $event;
            isCopy = $event
        "
    [isCopy]="isCopy"
    [isCreate]="isCreate"
    [isUpdate]="isUpdate"
    [isVisible]="renderProjectFormDialog"
    [project]="project"
  ></app-project-form>
</div>
<div class="row">
  <div class="col-12 col-md-8">
    <ejs-tab
      #serviceDetailTab
      (created)="onCreate()"
      (selected)="select($event)"
      (selecting)="preventSwipe($event)"
      enablePersistence="true"
      height="auto"
      heightAdjustMode="Content"
      id="serviceDetailTab"
    >
      <e-tabitems>
        <e-tabitem [header]="headerText[0]">
          <ng-template #content>
            <div class="e-card m-1">
              <ng-container *ngIf="ruian && !loadingAddress">
                <div
                  class="e-card-header stylish-color-dark p-2"
                >
                  <div class="e-card-header-caption">
                    <div
                      class="d-flex justify-content-between e-card-header-title text-white"
                    >
                      <strong>
                        {{ ruian.address }}
                      </strong>
                      <span>
                                                <button
                                                  (click)="
                                                        fieldRemoveDialog.show()
                                                    "
                                                  *ngIf="
                                                        (ruian.created_by ===
                                                            currentUser.id ||
                                                            (currentUser.rule &&
                                                                currentUser.rule
                                                                    .is_admin)) &&
                                                        !ruian.deleted_date
                                                    "
                                                  cssClass="e-btn e-danger btn-p-zero e-small"
                                                  ejs-button
                                                  type="button"
                                                >
                                                    <i
                                                      aria-hidden="true"
                                                      class="fa-light fa-trash mr-lg-1"
                                                    ></i>
                                                    <span
                                                      class="d-none d-lg-inline"
                                                    >smazat</span
                                                    >
                                                </button>
                                                <button
                                                  (click)="newProject()"
                                                  cssClass="e-btn e-primary btn-p-zero e-small"
                                                  ejs-button
                                                  type="button"
                                                >
                                                    <i
                                                      aria-hidden="true"
                                                      class="fa-light fa-construction mr-lg-1"
                                                    ></i>
                                                    <span
                                                      class="d-none d-lg-inline"
                                                    >nový projekt</span
                                                    >
                                                </button>
                                            </span>
                    </div>
                  </div>
                </div>
                <div class="e-card-content pt-2 pb-2 pl-1 pr-1">
                  <h6 class="p-2">
                    <i
                      aria-hidden="true"
                      class="fa-light fa-lg fa-home"
                    ></i
                    >&nbsp;Adresa RUIAN:
                  </h6>
                  <div class="e-card-separator"></div>
                  <ul class="list-group mb-3">
                    <li
                      class="list-group-item d-flex justify-content-between"
                    >
                      <strong>Adresa RUIAN:</strong>
                      <div class="w-50 text-right">
                        <ejs-inplaceeditor
                          #ctrl_address
                          (submitClick)="
                                                        actionChange($event)
                                                    "
                          [model]="overviewModel"
                          [popupSettings]="settings"
                          [validationRules]="rules"
                          [value]="ruian.address"
                          autocomplete="smartform-auto-address"
                          cssClass="w-100 smartform-whole-address smartform-instance-create"
                          emptyText="Vyberte adresu z našeptávače"
                          id="ctrl_address"
                          mode="Inline"
                          name="address"
                          type="Text"
                        ></ejs-inplaceeditor>
                      </div>
                    </li>
                    <li
                      class="list-group-item d-flex justify-content-between"
                    >
                      <strong>Kód RUIAN:</strong>
                      <div class="w-50 text-right">
                        <ejs-inplaceeditor
                          #ctrl_ruian
                          (submitClick)="
                                                        actionChange(
                                                            $event,
                                                            'ruian'
                                                        )
                                                    "
                          [model]="
                                                        numericTextBoxModel
                                                    "
                          [validationRules]="rules"
                          [value]="ruian.ruian"
                          cssClass="w-100"
                          emptyText="Zadejte kód RUIAN"
                          mode="Inline"
                          name="ruian"
                          type="Numeric"
                        ></ejs-inplaceeditor>
                      </div>
                    </li>
                    <li
                      class="list-group-item d-flex justify-content-between"
                    >
                      <strong>Název ulice:</strong>
                      <div class="w-50 text-right">
                        <ejs-inplaceeditor
                          #ctrl_street
                          (submitClick)="
                                                        actionChange(
                                                            $event,
                                                            'street'
                                                        )
                                                    "
                          [model]="overviewModel"
                          [validationRules]="rules"
                          [value]="
                                                        ruian.address_obj &&
                                                        ruian.address_obj.street
                                                            ? ruian.address_obj
                                                                  .street
                                                            : null
                                                    "
                          cssClass="w-100"
                          emptyText="Zadejte název ulice"
                          mode="Inline"
                          name="street"
                          type="Text"
                        ></ejs-inplaceeditor>
                      </div>
                    </li>
                    <li
                      class="list-group-item d-flex justify-content-between"
                    >
                      <strong>Město:</strong>
                      <div class="w-50 text-right">
                        <ejs-inplaceeditor
                          #ctrl_city
                          [model]="overviewModel"
                          [validationRules]="rules"
                          [value]="
                                                        ruian.address_obj &&
                                                        ruian.address_obj.city
                                                            ? ruian.address_obj
                                                                  .city
                                                            : null
                                                    "
                          cssClass="w-100"
                          emptyText="Zadejte název města"
                          mode="Inline"
                          name="city"
                          type="Text"
                        ></ejs-inplaceeditor>
                      </div>
                    </li>
                    <li
                      class="list-group-item d-flex justify-content-between"
                    >
                      <strong>PSČ:</strong>
                      <div class="w-50 text-right">
                        <ejs-inplaceeditor
                          #ctrl_zip
                          [model]="
                                                        numericTextBoxModel
                                                    "
                          [validationRules]="rules"
                          [value]="
                                                        ruian.address_obj &&
                                                        ruian.address_obj.zip
                                                            ? ruian.address_obj
                                                                  .zip
                                                            : null
                                                    "
                          cssClass="w-100"
                          emptyText="Zadejte PSČ"
                          mode="Inline"
                          name="zip"
                          type="Numeric"
                        ></ejs-inplaceeditor>
                      </div>
                    </li>
                    <li
                      class="list-group-item d-flex justify-content-between"
                    >
                      <strong>Městská část:</strong>
                      <div class="w-50 text-right">
                        <ejs-inplaceeditor
                          #ctrl_city_part
                          [model]="overviewModel"
                          [value]="
                                                        ruian.address_obj &&
                                                        ruian.address_obj
                                                            .city_part
                                                            ? ruian.address_obj
                                                                  .city_part
                                                            : null
                                                    "
                          cssClass="w-100"
                          emptyText="Zadejte název městksé části"
                          mode="Inline"
                          type="Text"
                        ></ejs-inplaceeditor>
                      </div>
                    </li>
                    <li
                      class="list-group-item d-flex justify-content-between"
                    >
                      <strong>Okres:</strong>
                      <div class="w-50 text-right">
                        <ejs-inplaceeditor
                          #ctrl_district
                          [model]="overviewModel"
                          [value]="
                                                        ruian.address_obj &&
                                                        ruian.address_obj
                                                            .district
                                                            ? ruian.address_obj
                                                                  .district
                                                            : null
                                                    "
                          cssClass="w-100"
                          emptyText="Zadejte název okresu"
                          mode="Inline"
                          type="Text"
                        ></ejs-inplaceeditor>
                      </div>
                    </li>
                    <li
                      class="list-group-item d-flex justify-content-between"
                    >
                      <strong>Kraj:</strong>
                      <div class="w-50 text-right">
                        <ejs-inplaceeditor
                          #ctrl_region
                          [model]="overviewModel"
                          [value]="
                                                        ruian.address_obj &&
                                                        ruian.address_obj.region
                                                            ? ruian.address_obj
                                                                  .region
                                                            : null
                                                    "
                          cssClass="w-100"
                          emptyText="Zadejte název kraje"
                          mode="Inline"
                          type="Text"
                        ></ejs-inplaceeditor>
                      </div>
                    </li>
                    <li
                      class="list-group-item d-flex justify-content-between"
                    >
                      <strong>Stát:</strong>
                      <div class="w-50 text-right">
                        <ejs-inplaceeditor
                          #ctrl_state
                          [model]="overviewModel"
                          [value]="
                                                        ruian.address_obj &&
                                                        ruian.address_obj.state
                                                            ? ruian.address_obj
                                                                  .state
                                                            : 'Česká republika'
                                                    "
                          cssClass="w-100"
                          emptyText="Zadejte název státu"
                          mode="Inline"
                          type="Text"
                        ></ejs-inplaceeditor>
                      </div>
                    </li>
                    <li
                      class="list-group-item d-flex justify-content-between"
                    >
                      <strong>GPS souřadnice:</strong>
                      <div class="w-50 text-right">
                        <ejs-inplaceeditor
                          #ctrl_gps
                          [model]="overviewModel"
                          [value]="
                                                        ruian.address_obj &&
                                                        ruian.address_obj.gps
                                                            ? ruian.address_obj
                                                                  .gps
                                                            : null
                                                    "
                          cssClass="w-100"
                          emptyText="Zadejte GSP souřadnice"
                          mode="Inline"
                          type="Text"
                        ></ejs-inplaceeditor>
                      </div>
                    </li>
                    <li
                      class="list-group-item d-flex justify-content-between"
                    >
                      <strong>JSTK souřadnice:</strong>
                      <div class="w-50 text-right">
                        <ejs-inplaceeditor
                          #ctrl_jstk
                          [model]="overviewModel"
                          [value]="
                                                        ruian.address_obj &&
                                                        ruian.address_obj.jstk
                                                            ? ruian.address_obj
                                                                  .jstk
                                                            : null
                                                    "
                          cssClass="w-100"
                          emptyText="Zadejte JSTK souřadnice"
                          mode="Inline"
                          type="Text"
                        ></ejs-inplaceeditor>
                      </div>
                    </li>
                  </ul>
                  <h6 class="p-2">
                    <i
                      aria-hidden="true"
                      class="fa-light fa-lg fa-building"
                    ></i
                    >&nbsp;Vlastnosti parcely:
                  </h6>
                  <div class="e-card-separator"></div>
                  <ul class="list-group mb-3">
                    <li
                      class="list-group-item d-flex justify-content-between"
                    >
                      <strong
                      >Počet bytových
                        jednotek:</strong
                      >
                      <div class="w-50 text-right">
                        <ejs-inplaceeditor
                          #ctrl_number_of_units
                          [model]="
                                                        numericTextBoxModel
                                                    "
                          [value]="
                                                        ruian.number_of_units
                                                    "
                          cssClass="w-100"
                          emptyText="Zadejte počet bytových jednotek"
                          mode="Inline"
                          type="Numeric"
                        ></ejs-inplaceeditor>
                      </div>
                    </li>
                    <li
                      class="list-group-item d-flex justify-content-between"
                    >
                      <strong>Počet podlaží:</strong>
                      <div class="w-50 text-right">
                        <ejs-inplaceeditor
                          #ctrl_number_of_storeys
                          [model]="
                                                        numericTextBoxModel
                                                    "
                          [value]="
                                                        ruian.number_of_storeys
                                                    "
                          cssClass="w-100"
                          emptyText="Zadejte počet podlaží"
                          mode="Inline"
                          type="Numeric"
                        ></ejs-inplaceeditor>
                      </div>
                    </li>
                    <li
                      class="list-group-item d-flex justify-content-between"
                    >
                      <strong
                      >Typ bytové jednotky:</strong
                      >
                      <div class="w-50 text-right">
                        <ejs-inplaceeditor
                          #unit_type
                          [model]="
                                                        dropDownListUnitModel
                                                    "
                          [value]="
                                                        ruian.unit_type
                                                            ? ruian.unit_type.id
                                                            : null
                                                    "
                          cssClass="w-100"
                          emptyText="Vyberte typ bytové jednotky"
                          mode="Inline"
                          type="DropDownList"
                        ></ejs-inplaceeditor>
                      </div>
                    </li>
                    <li
                      class="list-group-item d-flex justify-content-between"
                    >
                      <strong>Typ prostoru:</strong>
                      <div class="w-50 text-right">
                        <ejs-inplaceeditor
                          #flat_space
                          [model]="
                                                        dropDownListSpaceModel
                                                    "
                          [value]="
                                                        ruian.flat_space
                                                            ? ruian.flat_space
                                                                  .id
                                                            : null
                                                    "
                          cssClass="w-100"
                          emptyText="Vyberte typ prostoru bytové jednotky"
                          mode="Inline"
                          type="DropDownList"
                        ></ejs-inplaceeditor>
                      </div>
                    </li>
                    <li
                      class="list-group-item d-flex justify-content-between"
                    >
                      <strong>Vlastník:</strong>
                      <div class="w-50 text-right">
                        <ejs-inplaceeditor
                          #ctrl_owner_name
                          [model]="overviewModel"
                          [value]="ruian.owner_name"
                          cssClass="w-100"
                          emptyText="Zadejte vlastníka objektu"
                          mode="Inline"
                          type="Text"
                        ></ejs-inplaceeditor>
                      </div>
                    </li>
                    <li
                      class="list-group-item d-flex justify-content-between"
                    >
                      <strong>Správce:</strong>
                      <div class="w-50 text-right">
                        <ejs-inplaceeditor
                          #ctrl_manager_name
                          [model]="overviewModel"
                          [value]="ruian.manager_name"
                          cssClass="w-100"
                          emptyText="Zadejte správce objektu"
                          mode="Inline"
                          type="Text"
                        ></ejs-inplaceeditor>
                      </div>
                    </li>
                    <li
                      class="list-group-item d-flex justify-content-between"
                    >
                      <strong>Výtah:</strong>
                      <ng-container>
                        <div class="w-50 text-right">
                          <ejs-checkbox
                            [checked]="ruian.lift"
                            id="switchLift"
                            name="lift"
                            type="checkbox"
                          ></ejs-checkbox>
                        </div>
                      </ng-container>
                    </li>
                  </ul>
                  <h6 class="p-2">
                    <i
                      aria-hidden="true"
                      class="fa-light fa-lg fa-cog"
                    ></i
                    >&nbsp;Technické vlastnosti parcely:
                  </h6>
                  <div class="e-card-separator"></div>
                  <ul class="list-group mb-3">
                    <li
                      class="list-group-item d-flex justify-content-between"
                    >
                      <strong>Provider:</strong>
                      <div class="w-50 text-right">
                        <ejs-inplaceeditor
                          #provider
                          [model]="
                                                        dropDownListProviderModel
                                                    "
                          [value]="
                                                        ruian.provider
                                                            ? ruian.provider.id
                                                            : null
                                                    "
                          cssClass="w-100"
                          emptyText="Vyberte providera"
                          mode="Inline"
                          type="DropDownList"
                        ></ejs-inplaceeditor>
                      </div>
                    </li>
                    <li
                      class="list-group-item d-flex justify-content-between"
                    >
                      <strong>Typ technologie:</strong>
                      <div class="w-50 text-right">
                        <ejs-inplaceeditor
                          #technology
                          [model]="
                                                        dropDownListTechnologyModel
                                                    "
                          [value]="
                                                        ruian.technology
                                                            ? ruian.technology
                                                                  .id
                                                            : null
                                                    "
                          emptyText="Vyberte typ technologie"
                          mode="Inline"
                          type="DropDownList"
                        ></ejs-inplaceeditor>
                      </div>
                    </li>
                    <li
                      class="list-group-item d-flex justify-content-between"
                    >
                      <strong>Typ přenosu:</strong>
                      <div class="w-50 text-right">
                        <ejs-inplaceeditor
                          #transfer
                          [model]="
                                                        dropDownListTransferModel
                                                    "
                          [value]="
                                                        ruian.transfer
                                                            ? ruian.transfer.id
                                                            : null
                                                    "
                          cssClass="w-100"
                          emptyText="Vyberte typ přenosu"
                          mode="Inline"
                          type="DropDownList"
                        ></ejs-inplaceeditor>
                      </div>
                    </li>
                    <li
                      class="list-group-item d-flex justify-content-between"
                    >
                      <strong>Elektroměr:</strong>
                      <div class="w-50 text-right">
                        <ejs-inplaceeditor
                          #ctrl_electrical_counter
                          [model]="overviewModel"
                          [value]="
                                                        ruian.electrical_counter
                                                    "
                          cssClass="w-100"
                          emptyText="Zadejte popis elektroměru"
                          mode="Inline"
                          type="Text"
                        ></ejs-inplaceeditor>
                      </div>
                    </li>
                    <li
                      class="list-group-item d-flex justify-content-between"
                    >
                      <strong>Klíče:</strong>
                      <ng-container>
                        <div class="w-50 text-right">
                          <ejs-checkbox
                            [checked]="ruian.keys"
                            id="switchKeys"
                            name="keys"
                            type="checkbox"
                          ></ejs-checkbox>
                        </div>
                      </ng-container>
                    </li>
                    <li
                      class="list-group-item d-flex justify-content-between"
                    >
                      <strong>Popis:</strong>
                      <div class="w-50 text-right">
                        <ejs-inplaceeditor
                          #ctrl_descriptive
                          [model]="commentEditorModel"
                          [popupSettings]="settings"
                          [value]="ruian.descriptive"
                          cssClass="w-100"
                          data-underline="false"
                          emptyText="Zadejte popis"
                          mode="Inline"
                          name="descriptive"
                          submitOnEnter="false"
                          type="RTE"
                        ></ejs-inplaceeditor>
                      </div>
                    </li>
                    <li
                      class="list-group-item d-flex justify-content-between"
                    >
                      <strong>Obchodní popis:</strong>
                      <div class="w-50 text-right">
                        <ejs-inplaceeditor
                          #ctrl_business_note
                          [model]="commentEditorModel"
                          [popupSettings]="settings"
                          [value]="
                                                        ruian.business_note
                                                    "
                          cssClass="w-100"
                          data-underline="false"
                          emptyText="Zadejte obchodní popis"
                          mode="Inline"
                          name="business_note"
                          submitOnEnter="false"
                          type="RTE"
                        ></ejs-inplaceeditor>
                      </div>
                    </li>
                  </ul>
                  <h6 class="p-2">
                    <i
                      aria-hidden="true"
                      class="fa-light fa-lg fa-clipboard-list"
                    ></i
                    >&nbsp;Služby:
                  </h6>
                  <div class="e-card-separator"></div>
                  <ul class="list-group mb-3">
                    <li
                      class="list-group-item d-flex justify-content-between"
                    >
                      <strong>Služby internet:</strong>
                      <div class="w-50 text-right">
                        <ejs-multiselect
                          #net_packages
                          (filtering)="
                                                        onFilteringNetPackages(
                                                            $event
                                                        )
                                                    "
                          [allowFiltering]="true"
                          [dataSource]="
                                                        servicesNetSelect
                                                    "
                          [fields]="ruianRuianObj"
                          [filterBarPlaceholder]="
                                                        'Vyhledat internetové služby...'
                                                    "
                          [ignoreAccent]="true"
                          [mode]="'CheckBox'"
                          [placeholder]="
                                                        'Vyberte internetové služby'
                                                    "
                          [popupHeight]="height"
                          [selectAllText]="
                                                        'Všechny internetové služby'
                                                    "
                          [value]="choosenNetPackages"
                          cssClass="w-100"
                          id="servicesNet"
                          showSelectAll="true"
                          sortOrder="Ascending"
                        ></ejs-multiselect>
                      </div>
                    </li>
                    <li
                      class="list-group-item d-flex justify-content-between"
                    >
                      <strong>Služby televize:</strong>
                      <div class="w-50 text-right">
                        <ejs-multiselect
                          #tv_services
                          (filtering)="
                                                        onFilteringTvPackages(
                                                            $event
                                                        )
                                                    "
                          [allowFiltering]="true"
                          [dataSource]="
                                                        servicesTvSelect
                                                    "
                          [fields]="ruianRuianObj"
                          [filterBarPlaceholder]="
                                                        'Vyhledat televizní služby...'
                                                    "
                          [ignoreAccent]="true"
                          [mode]="'CheckBox'"
                          [placeholder]="
                                                        'Vyberte televizní služby'
                                                    "
                          [popupHeight]="height"
                          [selectAllText]="
                                                        'Všechny TV služby'
                                                    "
                          [value]="choosenTvServices"
                          cssClass="w-100"
                          id="servicesTv"
                          showSelectAll="true"
                          sortOrder="Ascending"
                        ></ejs-multiselect>
                      </div>
                    </li>
                  </ul>
                  <h6 class="p-2">
                    <i
                      aria-hidden="true"
                      class="fa-light fa-lg fa-construction"
                    ></i
                    >&nbsp;Projekt:
                  </h6>
                  <div class="e-card-separator"></div>
                  <ul class="list-group mb-3">
                    <li
                      class="list-group-item d-flex justify-content-between"
                    >
                      <strong>Projekt:</strong>
                      <div class="w-50 text-right">
                        <ejs-inplaceeditor
                          #project
                          [model]="
                                                        dropDownListProjectModel
                                                    "
                          [value]="
                                                        ruian.project
                                                            ? ruian.project.id
                                                            : null
                                                    "
                          cssClass="w-100"
                          emptyText="Vyberte projekt"
                          mode="Inline"
                          type="DropDownList"
                        ></ejs-inplaceeditor>
                      </div>
                    </li>
                    <li
                      class="list-group-item d-flex justify-content-between"
                    >
                      <strong>Datum realizace:</strong>
                      <div class="w-50 text-right">
                        <ejs-datetimepicker
                          [enabled]="true"
                          [firstDayOfWeek]="1"
                          cssClass="w-100"
                          floatLabelType="Always"
                          id="realized_at"
                        >
                        </ejs-datetimepicker>
                      </div>
                    </li>
                  </ul>
                  <h6 class="p-2">
                    <i
                      aria-hidden="true"
                      class="fa-light fa-lg fa-lightbulb-dollar"
                    ></i
                    >&nbsp;Marketing:
                  </h6>
                  <div class="e-card-separator"></div>
                  <ul class="list-group mb-3">
                    <li
                      class="list-group-item d-flex justify-content-between"
                    >
                      <strong>Typ smlouvy:</strong>
                      <div class="w-50 text-right">
                        <ejs-inplaceeditor
                          #deal_type
                          [model]="
                                                        dropDownListDealModel
                                                    "
                          [value]="
                                                        ruian.deal_type
                                                            ? ruian.deal_type.id
                                                            : null
                                                    "
                          cssClass="w-100"
                          emptyText="Vyberte typ smlouvy"
                          mode="Inline"
                          type="DropDownList"
                        ></ejs-inplaceeditor>
                      </div>
                    </li>
                    <li
                      class="list-group-item d-flex justify-content-between"
                    >
                      <strong>Typ slevy:</strong>
                      <div class="w-50 text-right">
                        <ejs-inplaceeditor
                          #discount
                          [model]="
                                                        dropDownListDiscountModel
                                                    "
                          [value]="
                                                        ruian.discount
                                                            ? ruian.discount.id
                                                            : null
                                                    "
                          cssClass="w-100"
                          emptyText="Vyberte typ slevy"
                          mode="Inline"
                          type="DropDownList"
                        ></ejs-inplaceeditor>
                      </div>
                    </li>
                    <li
                      class="list-group-item d-flex justify-content-between"
                    >
                      <strong>Klip rám:</strong>
                      <ng-container>
                        <div class="w-50 text-right">
                          <ejs-checkbox
                            [checked]="
                                                            ruian.clip_frame
                                                        "
                            id="switchClip"
                            name="clip_frame"
                            type="checkbox"
                          ></ejs-checkbox>
                        </div>
                      </ng-container>
                    </li>
                    <li
                      class="list-group-item d-flex justify-content-between"
                    >
                      <strong>Marketingový popis:</strong>
                      <div class="w-50 text-right">
                        <ejs-inplaceeditor
                          #ctrl_marketingnote
                          [model]="commentEditorModel"
                          [popupSettings]="settings"
                          [value]="
                                                        ruian.marketingnote
                                                    "
                          cssClass="w-100"
                          data-underline="false"
                          emptyText="Zadejte marketingový popis"
                          mode="Inline"
                          name="marketingnote"
                          submitOnEnter="false"
                          type="RTE"
                        ></ejs-inplaceeditor>
                      </div>
                    </li>
                    <li
                      class="list-group-item d-flex justify-content-between"
                    >
                      <strong>Ostatní reklamy:</strong>
                      <div class="w-50 text-right">
                        <ejs-inplaceeditor
                          #ctrl_marketing_ads
                          [model]="commentEditorModel"
                          [popupSettings]="settings"
                          [value]="
                                                        ruian.marketing_ads
                                                    "
                          cssClass="w-100"
                          data-underline="false"
                          emptyText="Zadejte popis reklam v objektu"
                          mode="Inline"
                          name="marketing_ads"
                          submitOnEnter="false"
                          type="RTE"
                        ></ejs-inplaceeditor>
                      </div>
                    </li>
                  </ul>
                </div>
              </ng-container>
              <ng-container
                *ngIf="!ruian || loadingAddress"
                class="row"
              >
                <div class="e-card-content text-center">
                  <app-loader></app-loader>
                </div>
              </ng-container>
            </div>
          </ng-template>
        </e-tabitem>
        <e-tabitem [header]="headerText[1]">
          <ng-template #content>
            <div class="e-card m-1">
              <ng-container *ngIf="ruian && !loadingAddress">
                <div class="e-card-content">
                  <div *ngIf="ruian.photos.length > 0">
                    <ul class="list-group list-group-flush">
                      <li
                        *ngFor="
                                                    let file of ruian.photos
                                                        | paginate
                                                            : {
                                                                  id: 'files_list',
                                                                  itemsPerPage:
                                                                      pageSize,
                                                                  currentPage:
                                                                      pageFiles
                                                              }
                                                "
                        class="list-group-item"
                      >
                        <div class="row">
                          <div
                            class="col-12 col-md-6 col-lg-7"
                          >
                            {{ file.name }}<br />
                            <small
                              class="text-muted"
                            >
                              typ:
                              {{
                                file.extension
                              }}&nbsp;&middot;&nbsp;velikost:
                              {{
                                file.size
                                  | filesize
                              }}
                            </small>
                            <ng-container
                              *ngIf="
                                                                file.extension ===
                                                                    'image/jpeg' ||
                                                                file.extension ===
                                                                    'image/png'
                                                            "
                            >
                              <br />
                              <img
                                alt="{{
                                                                    file.name
                                                                }}"
                                src="{{
                                                                    file.url_thumbnail
                                                                }}"
                                style="
                                                                    height: 50px;
                                                                    width: auto;
                                                                "
                              />
                            </ng-container>
                          </div>
                          <div
                            class="col-12 col-md-6 col-lg-5"
                          >
                            <div
                              aria-label="File toolbar"
                              class="e-btn-group float-right"
                              role="group"
                            >
                              <button
                                (click)="
                                                                    downloadFile(
                                                                        file
                                                                    )
                                                                "
                                cssClass="e-btn e-primary btn-p-zero e-small"
                                ejs-button
                                type="button"
                              >
                                <i
                                  aria-hidden="true"
                                  class="fa-light fa-download"
                                ></i>
                              </button>
                              <button
                                (click)="
                                                                    deleteFileId =
                                                                        file.id
                                                                "
                                cssClass="e-danger e-btn btn-p-zero"
                                ejs-button
                                type="button"
                              >
                                <i
                                  aria-hidden="true"
                                  class="fa-light fa-trash"
                                ></i>
                              </button>
                            </div>
                          </div>
                        </div>
                      </li>
                    </ul>
                    <nav
                      class="d-flex justify-content-center mt-5 mb-3"
                    >
                      <pagination-controls
                        (pageChange)="
                                                    pageFiles = $event
                                                "
                        autoHide="true"
                        directionLinks="true"
                        id="files_list"
                        maxSize="9"
                        nextLabel="Další"
                        previousLabel="Předchozí"
                        responsive="true"
                      ></pagination-controls>
                    </nav>
                    <div class="force-overflow">
                      <div class="image-thumb-group">
                        <img
                          (click)="open(i)"
                          *ngFor="
                                                        let img of imagesBasic;
                                                        let i = index
                                                    "
                          [src]="img.thumb || img.src"
                        />
                      </div>
                    </div>
                  </div>
                  <h6
                    *ngIf="ruian.photos.length === 0"
                    class="text-muted col-12 text-center"
                  >
                    žádné soubory nebyly zatím přiloženy
                  </h6>
                </div>
              </ng-container>
              <ng-container *ngIf="!ruian || loadingAddress">
                <div class="e-card-content text-center">
                  <app-loader></app-loader>
                </div>
              </ng-container>
            </div>
          </ng-template>
        </e-tabitem>
        <e-tabitem [header]="headerText[2]">
          <ng-template #content>
            <div class="e-card m-1">
              <ng-container *ngIf="ruian && !loadingAddress">
                <div class="e-card-content"></div>
              </ng-container>
              <ng-container *ngIf="!ruian || loadingAddress">
                <div class="e-card-content text-center">
                  <app-loader></app-loader>
                </div>
              </ng-container>
            </div>
          </ng-template>
        </e-tabitem>
      </e-tabitems>
    </ejs-tab>
  </div>
  <div class="col-12 col-md-4">
    <div class="e-card m-1">
      <ng-container *ngIf="ruian && !loadingAddress">
        <div class="e-card-header stylish-color-dark text-white">
          <div class="e-card-header-caption">
            <div
              class="d-flex justify-content-between e-card-header-title text-white"
            >
                            <span>
                                <strong>Adresa RUIAN:</strong>
                            </span>
              <span>
                                <strong>#{{ ruian.id }}</strong>
                            </span>
            </div>
          </div>
        </div>
        <div class="e-card-content pt-2 pb-2 pl-1 pr-1">
          <div class="text-center">
            <img
              alt="profilový obrázek"
              class="img-fluid rounded-circle avatar-md p-2"
              src="assets/img/{{ ruian.creator.personalphoto }}"
            />
            <h5 class="mb-2 font-bold">
              <strong
              >{{ ruian.creator.firstname }}
                {{ ruian.creator.secondname }}</strong
              >
            </h5>
            <h6 class="font-bold blue-text mb-2">zadavatel</h6>
          </div>
          <div class="e-card-separator"></div>
          <div class="d-flex justify-content-between p-2">
            <span>Datum vytvoření:</span>
            <strong>{{
                ruian.created_date | dateFormat: 'DD.MM.YYYY HH:mm'
              }}</strong>
          </div>
          <div class="e-card-separator"></div>
          <div class="d-flex justify-content-between p-2">
            <span>Poslední změna:</span>
            <strong>{{
                ruian.updated_date | dateFormat: 'DD.MM.YYYY HH:mm'
              }}</strong>
          </div>
        </div>
        <div class="e-card-actions w-100">
          <ejs-uploader
            #preloadupload
            (beforeUpload)="beforeFileUpload($event)"
            (removing)="onFileRemove($event)"
            (selected)="onFileSelected($event)"
            (success)="onSuccessUpload($event)"
            (uploading)="onFileUpload($event)"
            [asyncSettings]="path"
            [dropArea]="dropElement"
            [enabled]="!ruian.deleted_date"
            allowedExtensions=".jpg,.png,.jpeg"
            id="preloadfileupload"
            maxFileSize="5000000"
          >
            <e-files>
              <e-uploadedfiles
                *ngFor="let file of ruian.photos"
                name="{{ file.name | fileExtension: false }}"
                size="{{ file.size }}"
                type="{{ file.name | fileExtension: true }}"
              ></e-uploadedfiles>
            </e-files>
          </ejs-uploader>
        </div>
      </ng-container>
      <div *ngIf="!ruian || loadingAddress">
        <div class="e-card-content text-center">
          <app-loader></app-loader>
        </div>
      </div>
    </div>
  </div>
</div>
