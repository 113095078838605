import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    HostListener,
    Input,
    OnChanges,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import {AbstractControl, FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {DataService} from '@src/app/_services/data.service';
import {DataNetbaseService} from '@src/app/_services/data-netbase.service';
import {SettingsService} from '@src/app/features/settings/settings.service';
import {ServicesService, TvServiceInput} from '@src/app/features/services/services.service';
import {MessageService} from '@src/app/_services/message.service';
import {AuthenticationService} from '@src/app/_services/authentication.service';
import {Places} from '@src/app/_models/services/places';
import {ExtendServices} from '@src/app/_models/services/extend-services';
import {Bonuses} from '@src/app/_models/services/bonuses';
import {TvPackages} from '@src/app/_models/services/tv-packages';
import {TvServices} from '@src/app/_models/services/tv-services';
import {Company} from '@src/app/_models/company/company';
import {TransferTypes} from '@src/app/_models/services/transfer-types';
import {Discounts} from '@src/app/_models/services/discounts';
import {User} from '@src/app/_models/user/user';
import {DropDownListComponent, FilteringEventArgs} from '@syncfusion/ej2-angular-dropdowns';
import {EmitType} from '@syncfusion/ej2-base';
import {Query} from '@syncfusion/ej2-data';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {DialogComponent} from '@syncfusion/ej2-angular-popups';
import {Observable} from 'rxjs';
import {ComponentCanDeactivate} from '@src/app/_guards/changes.guard';
import {ActivatedRoute, Params, Router} from '@angular/router';

@UntilDestroy()
@Component({
    selector: 'app-tv-service-form',
    templateUrl: './tv-service-form.component.html',
    styleUrls: ['./tv-service-form.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TvServiceFormComponent implements OnInit, OnChanges, ComponentCanDeactivate {
    // Dropdowms
    height = '240px';

    fields: object = {text: 'label', value: 'value'};

    packageSelect: Array<{value: boolean | number | string; label: string}> = [];

    extServiceSelect: Array<{value: boolean | number | string; label: string}> = [];

    bonusSelect: Array<{value: boolean | number | string; label: string}> = [];

    placeSelect: Array<{value: boolean | number | string; label: string}> = [];

    transferSelect: Array<{value: boolean | number | string; label: string}> = [];

    discountSelect: Array<{value: boolean | number | string; label: string}> = [];

    companySelect: Array<{value: boolean | number | string; label: string}> = [];

    // Forms
    tvServiceForm: FormGroup;

    placesList: FormArray;

    bonusesList: FormArray;

    extServicesList: FormArray;

    packagesList: FormArray;

    isDirty = false;

    isChecked = false;

    submited = false;

    // Variables
    currentUser: User | null;

    // Loaders
    loadingTvServices = false;

    @Input() tvService: TvServices | null = null;

    @Input() isCreate = false;

    @Input() isUpdate = false;

    @Input() isVisible = false;

    @Input() isCopy = false;

    @Output() readonly tvServiceFormDialogState = new EventEmitter<boolean>();

    // Dialogs
    @ViewChild('formDialog') formDialogObj: DialogComponent;

    // Dropdowms
    @ViewChild('provider') providerObj: DropDownListComponent;

    @ViewChild('transfer') transferObj: DropDownListComponent;

    @ViewChild('discount') discountObj: DropDownListComponent;

    @ViewChild('tVpackage') tVpackageObj: DropDownListComponent;

    @ViewChild('place') placeObj: DropDownListComponent;

    @ViewChild('bonus') bonusObj: DropDownListComponent;

    @ViewChild('extService') extServiceObj: DropDownListComponent;

    constructor(
        private readonly route: ActivatedRoute,
        private readonly router: Router,
        private readonly formBuilder: FormBuilder,
        private readonly ref: ChangeDetectorRef,
        private readonly dataService: DataService,
        private readonly dataNetbaseService: DataNetbaseService,
        private readonly settingsService: SettingsService,
        private readonly servicesService: ServicesService,
        private readonly messages: MessageService,
        private readonly authenticationService: AuthenticationService,
    ) {
        this.currentUser = this.authenticationService.currentUserValue;
    }

    get packagesFormGroup(): FormArray {
        return this.tvServiceForm.get('packages') as FormArray;
    }

    get placeFormGroup(): FormArray {
        return this.tvServiceForm.get('places') as FormArray;
    }

    get bonusFormGroup(): FormArray {
        return this.tvServiceForm.get('bonuses') as FormArray;
    }

    get extServiceFormGroup(): FormArray {
        return this.tvServiceForm.get('extServices') as FormArray;
    }

    get f(): {[key: string]: AbstractControl} {
        return this.tvServiceForm.controls;
    }

    @HostListener('window:beforeunload')
    canDeactivate(): Observable<boolean> | boolean {
        return !this.isDirty;
    }

    ngOnInit(): void {
        this.dataService.companySource.pipe(untilDestroyed(this))
            .subscribe(
                (companies: Company[]) => {
                    this.companySelect = [];
                    companies.map((company: Company) => {
                        this.companySelect = [
                            ...this.companySelect,
                            {
                                value: company.id,
                                label: company.name,
                            },
                        ];
                    });
                    this.ref.markForCheck();
                },
                error => {
                    console.error(error);
                },
            );

        this.dataNetbaseService.transferTypesSource.pipe(untilDestroyed(this))
            .subscribe(
                (transfers: TransferTypes[]) => {
                    this.transferSelect = [];
                    transfers.map((transfer: TransferTypes) => {
                        if (!transfer.deleted_date) {
                            this.transferSelect = [
                                ...this.transferSelect,
                                {
                                    value: transfer.id,
                                    label: transfer.name,
                                },
                            ];
                        }
                    });
                    this.ref.markForCheck();
                },
                error => {
                    console.error(error);
                },
            );

        this.dataNetbaseService.discountSource.pipe(untilDestroyed(this))
            .subscribe(
                (discounts: Discounts[]) => {
                    this.discountSelect = [];
                    discounts.map((discount: Discounts) => {
                        if (!discount.deleted_date) {
                            this.discountSelect = [
                                ...this.discountSelect,
                                {
                                    value: discount.id,
                                    label: `${discount.discount}% z ceny`,
                                },
                            ];
                        }
                    });
                    this.ref.markForCheck();
                },
                error => {
                    console.error(error);
                },
            );

        this.dataNetbaseService.tvPackagesSource.pipe(untilDestroyed(this))
            .subscribe(
                (packages: TvPackages[]) => {
                    this.packageSelect = [];
                    packages.map((tvPackage: TvPackages) => {
                        if (!tvPackage.deleted_date) {
                            this.packageSelect = [
                                ...this.packageSelect,
                                {
                                    value: tvPackage.id,
                                    label: tvPackage.name,
                                },
                            ];
                        }
                    });
                    this.ref.markForCheck();
                },
                error => {
                    console.error(error);
                },
            );

        this.dataNetbaseService.placesSource.pipe(untilDestroyed(this))
            .subscribe(
                (places: Places[]) => {
                    this.placeSelect = [];
                    places.map((place: Places) => {
                        if (!place.deleted_date) {
                            this.placeSelect = [
                                ...this.placeSelect,
                                {
                                    value: place.id,
                                    label: place.name,
                                },
                            ];
                        }
                    });
                    this.ref.markForCheck();
                },
                error => {
                    console.error(error);
                },
            );

        this.dataNetbaseService.extendServices.pipe(untilDestroyed(this))
            .subscribe(
                (services: ExtendServices[]) => {
                    this.extServiceSelect = [];
                    services.map((service: ExtendServices) => {
                        if (!service.deleted_date) {
                            this.extServiceSelect = [
                                ...this.extServiceSelect,
                                {
                                    value: service.id,
                                    label: service.name,
                                },
                            ];
                        }
                    });
                    this.ref.markForCheck();
                },
                error => {
                    console.error(error);
                },
            );

        this.dataNetbaseService.bonusesSource.pipe(untilDestroyed(this))
            .subscribe(
                (bonuses: Bonuses[]) => {
                    this.bonusSelect = [];
                    bonuses.map((bonus: Bonuses) => {
                        if (!bonus.deleted_date) {
                            this.bonusSelect = [
                                ...this.bonusSelect,
                                {
                                    value: bonus.id,
                                    label: bonus.name,
                                },
                            ];
                        }
                    });
                    this.ref.markForCheck();
                },
                error => {
                    console.error(error);
                },
            );

        this.tvServiceForm = this.formBuilder.group({
            name: [
                '',
                Validators.compose([
                    Validators.required,
                    Validators.maxLength(100),
                    Validators.minLength(2),
                ]),
            ],
            provider_id: [null, Validators.required],
            transfer_id: [null, Validators.required],
            discount_id: [null],
            pernament_price: [0, Validators.compose([Validators.max(100000), Validators.min(0)])],
            extension: [false, Validators.required],
            recommended: [false, Validators.required],
            sale_order: [
                0,
                Validators.compose([Validators.required, Validators.max(20), Validators.min(0)]),
            ],
            packages: this.formBuilder.array([this.createPackages()]),
            places: this.formBuilder.array([this.createPlace()]),
            bonuses: this.formBuilder.array([this.createBonus()]),
            extServices: this.formBuilder.array([this.createExtService()]),
        });

        this.tvServiceForm.valueChanges.pipe(untilDestroyed(this))
            .subscribe(() => {
                this.isDirty = this.tvServiceForm.dirty;
                this.showInputErrors();
            });

        this.packagesList = this.tvServiceForm.get('packages') as FormArray;
        this.placesList = this.tvServiceForm.get('places') as FormArray;
        this.bonusesList = this.tvServiceForm.get('bonuses') as FormArray;
        this.extServicesList = this.tvServiceForm.get('extServices') as FormArray;

        this.dataNetbaseService.setTvPackagesDataSource();
        this.dataService.setCompanyDataSource();
        this.dataNetbaseService.setTransferTypesDataSource();
        this.dataNetbaseService.setDiscountsDataSource();
        this.dataNetbaseService.setPlacesDataSource();
        this.dataNetbaseService.setBonusesDataSource();
        this.dataNetbaseService.setExtendedServicesDataSource();
    }

    ngOnChanges(): void {
        if (this.isVisible) {
            const queryParams: Params = {form: null};

            void this.router.navigate([], {
                relativeTo: this.route,
                queryParams,
                queryParamsHandling: 'merge',
            });

            if (this.isUpdate && this.tvService) {
                this.tvServiceForm.reset();
                this.formDialogObj.header = `TV služba #${this.tvService.id} - ${this.tvService.name}`;
                this.fillTheForm();
                this.showInputErrors();
            }

            if (this.isCreate) {
                this.tvServiceForm.reset();
                this.formDialogObj.header = 'Nová TV služba';
            }

            if (this.isCopy) {
                this.tvServiceForm.reset();
                this.formDialogObj.header = 'Nová TV služba';
                this.fillTheForm();
                this.showInputErrors();
            }

            this.tvServiceForm.controls.recommended.patchValue(false);
            this.tvServiceForm.controls.extension.patchValue(false);
        }
    }

    changeDialogState(value: boolean): void {
        this.tvServiceFormDialogState.emit(value);
    }

    createPackages(items?: {[key: string]: number}): FormGroup {
        return this.formBuilder.group({
            package_id: [
                items ? items.package_id : null,
                Validators.compose([Validators.required]),
            ],
        });
    }

    getPackagesFormGroup(index): FormGroup {
        return this.packagesList.controls[index] as FormGroup;
    }

    addPackage(): void {
        this.packagesList.push(this.createPackages());
    }

    removePackage(index: number): void {
        this.packagesList.removeAt(index);
    }

    createPlace(items?: {[key: string]: number}): FormGroup {
        return this.formBuilder.group({
            place_id: [items ? items.place_id : null, Validators.compose([Validators.required])],
        });
    }

    getPlaceFormGroup(index): FormGroup {
        return this.placesList.controls[index] as FormGroup;
    }

    addPlace(): void {
        this.placesList.push(this.createPlace());
    }

    removePlace(index: number): void {
        this.placesList.removeAt(index);
    }

    createBonus(items?: {[key: string]: number}): FormGroup {
        return this.formBuilder.group({
            bonus_id: [items ? items.bonus_id : null, Validators.compose([Validators.required])],
        });
    }

    getBonusFormGroup(index): FormGroup {
        return this.bonusesList.controls[index] as FormGroup;
    }

    addBonus(): void {
        this.bonusesList.push(this.createBonus());
    }

    removeBonus(index: number): void {
        this.bonusesList.removeAt(index);
    }

    createExtService(items?: {[key: string]: number}): FormGroup {
        return this.formBuilder.group({
            ext_service_id: [
                items ? items.ext_service_id : null,
                Validators.compose([Validators.required]),
            ],
        });
    }

    getExtServiceFormGroup(index): FormGroup {
        return this.extServicesList.controls[index] as FormGroup;
    }

    addExtService(): void {
        this.extServicesList.push(this.createExtService());
    }

    removeExtService(index: number): void {
        this.extServicesList.removeAt(index);
    }

    onFilteringProviders: EmitType<FilteringEventArgs> = (e: FilteringEventArgs) => {
        if (e.text === '') {
            e.updateData(this.companySelect);
        } else {
            let query: Query = new Query();

            query = e.text !== '' ? query.where('label', 'contains', e.text, true, true) : query;
            e.updateData(this.companySelect, query);
        }
    };

    onFilteringTransferTypes: EmitType<FilteringEventArgs> = (e: FilteringEventArgs) => {
        if (e.text === '') {
            e.updateData(this.transferSelect);
        } else {
            let query: Query = new Query();

            query = e.text !== '' ? query.where('label', 'contains', e.text, true, true) : query;
            e.updateData(this.transferSelect, query);
        }
    };

    onFilteringDiscounts: EmitType<FilteringEventArgs> = (e: FilteringEventArgs) => {
        if (e.text === '') {
            e.updateData(this.discountSelect);
        } else {
            let query: Query = new Query();

            query = e.text !== '' ? query.where('label', 'contains', e.text, true, true) : query;
            e.updateData(this.discountSelect, query);
        }
    };

    onFilteringTvPackages: EmitType<FilteringEventArgs> = (e: FilteringEventArgs) => {
        if (e.text === '') {
            e.updateData(this.packageSelect);
        } else {
            let query: Query = new Query();

            query = e.text !== '' ? query.where('label', 'contains', e.text, true, true) : query;
            e.updateData(this.packageSelect, query);
        }
    };

    onFilteringPlaces: EmitType<FilteringEventArgs> = (e: FilteringEventArgs) => {
        if (e.text === '') {
            e.updateData(this.placeSelect);
        } else {
            let query: Query = new Query();

            query = e.text !== '' ? query.where('label', 'contains', e.text, true, true) : query;
            e.updateData(this.placeSelect, query);
        }
    };

    onFilteringBonuses: EmitType<FilteringEventArgs> = (e: FilteringEventArgs) => {
        if (e.text === '') {
            e.updateData(this.bonusSelect);
        } else {
            let query: Query = new Query();

            query = e.text !== '' ? query.where('label', 'contains', e.text, true, true) : query;
            e.updateData(this.bonusSelect, query);
        }
    };

    onFilteringExtServices: EmitType<FilteringEventArgs> = (e: FilteringEventArgs) => {
        if (e.text === '') {
            e.updateData(this.extServiceSelect);
        } else {
            let query: Query = new Query();

            query = e.text !== '' ? query.where('label', 'contains', e.text, true, true) : query;
            e.updateData(this.extServiceSelect, query);
        }
    };

    fillTheForm(): void {
        const tempPackages: {[key: string]: number}[] = [];
        const tempBonuses: {[key: string]: number}[] = [];
        const tempPlaces: {[key: string]: number}[] = [];
        const tempExtServices: {[key: string]: number}[] = [];

        this.tvServiceForm.controls.name.patchValue(this.tvService?.name);
        this.tvServiceForm.controls.provider_id.patchValue(this.tvService?.provider_id);
        this.tvServiceForm.controls.transfer_id.patchValue(this.tvService?.transfer_id);
        this.tvServiceForm.controls.discount_id.patchValue(
            this.tvService?.discount_id ? this.tvService.discount_id : null,
        );

        this.tvServiceForm.controls.pernament_price.patchValue(
            this.tvService?.pernament_price ? this.tvService.pernament_price : 0,
        );
        this.tvServiceForm.controls.extension.patchValue(this.tvService?.extension);
        this.tvServiceForm.controls.recommended.patchValue(this.tvService?.recommended);
        this.tvServiceForm.controls.sale_order.patchValue(this.tvService?.sale_order);

        this.packagesList = this.tvServiceForm.get('packages') as FormArray;
        this.placesList = this.tvServiceForm.get('places') as FormArray;
        this.bonusesList = this.tvServiceForm.get('bonuses') as FormArray;
        this.extServicesList = this.tvServiceForm.get('extServices') as FormArray;
        this.tvService?.tv_packages?.map((tvPackage: TvPackages) => {
            tempPackages.push({
                package_id: tvPackage.id,
            });
        });

        while (this.packagesList.length) {
            this.packagesList.removeAt(0);
        }

        tempPackages.forEach(tvPackage => {
            this.packagesList.push(this.createPackages(tvPackage));
        });

        this.tvServiceForm
            .get('packages')
            ?.setValue(tempPackages, {onlySelf: true, emitEvent: false});

        this.tvService?.tv_bonuses.map((bonus: Bonuses) => {
            tempBonuses.push({
                bonus_id: bonus.id,
            });
        });

        while (this.bonusesList.length) {
            this.bonusesList.removeAt(0);
        }

        tempBonuses.forEach(bonus => {
            this.bonusesList.push(this.createBonus(bonus));
        });

        this.tvServiceForm
            .get('bonuses')
            ?.setValue(tempBonuses, {onlySelf: true, emitEvent: false});

        this.tvService?.tv_places.map((place: Places) => {
            tempPlaces.push({
                place_id: place.id,
            });
        });

        while (this.placesList.length) {
            this.placesList.removeAt(0);
        }

        tempPlaces.forEach(place => {
            this.placesList.push(this.createPlace(place));
        });

        this.tvServiceForm
            .get('places')
            ?.setValue(tempPlaces, {onlySelf: true, emitEvent: false});

        this.tvService?.ext_services.map((extService: ExtendServices) => {
            tempExtServices.push({
                ext_service_id: extService.id,
            });
        });

        while (this.extServicesList.length) {
            this.extServicesList.removeAt(0);
        }

        tempExtServices.forEach(extService => {
            this.extServicesList.push(this.createExtService(extService));
        });

        this.tvServiceForm
            .get('extServices')
            ?.setValue(tempExtServices, {onlySelf: true, emitEvent: false});
    }

    onSubmit(): void {
        this.submited = true;
        this.isDirty = false;
        this.loadingTvServices = true;

        if (this.isCreate || this.isCopy) {
            this.createTvService();
        }

        if (this.isUpdate) {
            this.editTvService();
        }
    }

    createTvService(): void {
        this.submited = true;

        if (this.tvServiceForm.invalid) {
            console.error('form is not valid!');

            return;
        }

        this.loadingTvServices = true;

        const tvServiceData: TvServiceInput = {
            name: this.f.name.value,
            provider_id: this.f.provider_id.value,
            transfer_id: this.f.transfer_id.value,
            discount_id: this.f.discount_id.value,
            pernament_price: this.f.pernament_price.value,
            recommended: this.f.recommended.value,
            extension: this.f.extension.value,
            sale_order: this.f.sale_order.value,
            packages: this.packagesList.value,
            bonuses: this.bonusesList.value,
            extServices: this.extServicesList.value,
            places: this.placesList.value,
            created_by: this.currentUser?.id,
        };

        this.servicesService
            .addTvService(tvServiceData)
            .pipe(untilDestroyed(this))
            .subscribe(
                (data: TvServices) => {
                    const body = `${data.name} přidán do nabídky`;
                    const options = {progressBar: true, timeOut: 5000};

                    this.messages.showSuccess('Tarif televize úspěšně vytvořen', body, options);
                    this.dataNetbaseService.setTvServicesDataSource();
                    this.dataNetbaseService.setTvPackagesDataSource();
                    this.isCreate = false;
                    this.loadingTvServices = false;
                    this.ref.markForCheck();
                    this.formDialogObj.hide();
                },
                error => {
                    console.error(error);

                    const body = 'Zkuste to znovu později';
                    const options = {
                        progressBar: true,
                        timeOut: 5000,
                        toastClass: 'red',
                    };

                    this.messages.showError(
                        'Chyba při vytváření televizního tarifu',
                        body,
                        options,
                    );
                    this.loadingTvServices = false;
                    this.ref.markForCheck();
                },
            );
    }

    editTvService(): void {
        this.submited = true;

        if (this.tvServiceForm.invalid) {
            console.error('form is not valid!');

            return;
        }

        this.loadingTvServices = true;

        const tvServiceData: TvServiceInput = {
            id: this.tvService?.id,
            name: this.f.name.value,
            provider_id: this.f.provider_id.value,
            transfer_id: this.f.transfer_id.value,
            discount_id: this.f.discount_id.value,
            pernament_price: this.f.pernament_price.value,
            recommended: this.f.recommended.value,
            extension: this.f.extension.value,
            sale_order: this.f.sale_order.value,
            packages: this.packagesList.value,
            bonuses: this.bonusesList.value,
            extServices: this.extServicesList.value,
            places: this.placesList.value,
            updated_by: this.currentUser?.id,
        };

        this.servicesService
            .updateTvService(tvServiceData)
            ?.pipe(untilDestroyed(this))
            .subscribe(
                (data: TvServices) => {
                    this.tvService = data;

                    const body = `${data.name} upraven`;
                    const options = {progressBar: true, timeOut: 5000};

                    this.messages.showSuccess('Tarif televize úspěšně upraven', body, options);
                    this.dataNetbaseService.setTvServicesDataSource();
                    this.dataNetbaseService.setTvPackagesDataSource();
                    this.isUpdate = false;
                    this.loadingTvServices = false;
                    this.ref.markForCheck();
                    this.formDialogObj.hide();
                },
                error => {
                    console.error(error);

                    const body = 'Zkuste to znovu později';
                    const options = {
                        progressBar: true,
                        timeOut: 5000,
                        toastClass: 'red',
                    };

                    this.messages.showError('Chyba při úpravě televizního tarifu', body, options);
                    this.loadingTvServices = false;
                    this.ref.markForCheck();
                },
            );
    }

    showInputErrors(): void {
        this.isChecked = true;
        /* console.log('VALID: ' + this.ticketForm.valid);
        Object.keys(this.ticketForm.controls).forEach(key => {
            const controlErrors: ValidationErrors = this.ticketForm.get(key).errors;
            if (controlErrors != null) {
                Object.keys(controlErrors).forEach(keyError => {
                    console.log('Key control: ' + key + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError]);
                });
            }
        });*/
        this.tvServiceForm.markAllAsTouched();
        this.ref.markForCheck();
    }
}
