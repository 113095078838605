<div
  *ngIf="tvPackagesLoad"
  style="
        display: block !important;
        z-index: 1000000;
        width: 250px;
        height: 250px;
        margin: auto;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    "
>
  <div class="loader">
    <span></span>
    <span></span>
    <span></span>
    <span></span>
  </div>
  <div
    class="lds-circle"
    style="
            margin: auto;
            position: absolute;
            width: 150px;
            height: auto;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        "
  >
    <div></div>
  </div>
</div>
<div class="row d-flex justify-content-center modalWrapper">
  <ejs-dialog
    #confirmationDelete
    [buttons]="buttonsDelete"
    [content]="'Opravdu chcete smazat televizní balíček?'"
    [header]="'Smazat TELEVIZNÍ balíček'"
    [isModal]="true"
    [showCloseIcon]="true"
    [visible]="false"
    width="250px"
  ></ejs-dialog>
  <ejs-dialog
    #confirmationCancel
    [buttons]="buttonsCancel"
    [header]="'Nelze provést akci'"
    [isModal]="true"
    [showCloseIcon]="true"
    [visible]="false"
    width="250px"
  ></ejs-dialog>
  <app-tv-package-form
    (tvServiceFormDialogState)="
            renderTvPackagesFormDialog = $event;
            isCreate = $event;
            isUpdate = $event;
            isCopy = $event;
            gridTvPackages?.clearRowSelection();
            disableToolBarBtn()
        "
    [isCopy]="isCopy"
    [isCreate]="isCreate"
    [isUpdate]="isUpdate"
    [isVisible]="renderTvPackagesFormDialog"
    [tvPackage]="
            clickedRow && clickedRow[0] && (isUpdate || isCopy)
                ? clickedRow[0]
                : null
        "
  ></app-tv-package-form>
  <app-tvpackages-channels
    (tvChannelsDialogState)="renderTvChannelsDialog = $event"
    [isVisible]="renderTvChannelsDialog"
    [tvPackage]="clickedRow && clickedRow[0] ? clickedRow[0] : null"
  ></app-tvpackages-channels>
</div>
<ejs-toolbar id="filterToolbar" overflowMode="Scrollable" scrollStep="500">
  <e-items>
    <e-item
      [cssClass]="'statusFilter'"
      tooltipText="Přednastavené hledání"
      type="input"
    >
      <ng-template #template>
        <ejs-dropdownlist
          #viewFilter
          (change)="onStatusFilterChange($event)"
          [dataSource]="categoryFilterData"
          [width]="500"
          placeholder="Vyberte přednastavený pohled..."
        ></ejs-dropdownlist>
      </ng-template>
    </e-item>
    <e-item type="Separator"></e-item>
    <e-item
      [cssClass]="'fulltextFilter'"
      tooltipText="Text pro vyhledávání"
      type="input"
    >
      <ng-template #template>
        <ejs-textbox
          #searchtext
          [width]="500"
          class="mr-2"
          placeholder="Vyhledat..."
        ></ejs-textbox>
        <button
          (click)="search()"
          class="e-btn e-info btn-p-zero e-small"
        >
          <i class="fa-light fa-lg fa-search mr-1"></i
          ><span class="d-none d-lg-inline">vyhledat</span>
        </button>
        <button
          (click)="cancelBtnClick()"
          class="e-btn e-danger btn-p-zero e-small"
        >
          <i class="fa-light fa-lg fa-times"></i>
        </button>
      </ng-template>
    </e-item>
  </e-items>
</ejs-toolbar>
<div class="control-section">
  <div class="scroll_wrapper1" id="scroll_wrapper1_tvpackages">
    <div class="scroll_div" id="scroll_div_tvpackages"></div>
  </div>
  <div class="scroll_wrapper2" id="scroll_wrapper2_tvpackages">
    <div class="grid_parent" id="grid_parent_tvpackages">
      <ejs-grid
        #tvPackagesGrid
        (actionBegin)="actionBegin($event)"
        (actionComplete)="actionComplete($event)"
        (created)="onCreated()"
        (dataBound)="onDataBound()"
        (load)="onLoad()"
        (loadeddata)="setInitialGridFiltering()"
        (rowDataBound)="rowDataBound($event)"
        (rowDeselected)="rowSelected()"
        (rowSelected)="rowSelected()"
        (toolbarClick)="toolbarClick($event)"
        [allowExcelExport]="true"
        [allowFiltering]="true"
        [allowMultiSorting]="true"
        [allowPdfExport]="false"
        [allowSelection]="true"
        [allowSorting]="true"
        [dataSource]="data"
        [editSettings]="editSettings"
        [enableHover]="false"
        [enablePersistence]="false"
        [enableVirtualization]="true"
        [filterSettings]="filterSettings"
        [groupSettings]="groupOptions"
        [pageSettings]="pageOptions"
        [selectionSettings]="selectionSettings"
        [sortSettings]="sortOptions"
        [toolbar]="toolbar"
        allowGrouping="true"
        allowReordering="true"
        allowResizing="true"
        allowTextWrap="true"
        height="100%"
        id="tvPackagesGrid"
        locale="cs"
        rowHeight="38"
        showColumnMenu="true"
        width="100%"
      >
        <e-columns>
          <e-column
            [allowEditing]="false"
            [allowFiltering]="false"
            [allowReordering]="false"
            [allowResizing]="false"
            [allowSorting]="false"
            [autoFit]="true"
            type="checkbox"
          ></e-column>
          <e-column
            [allowFiltering]="false"
            [allowReordering]="true"
            [allowResizing]="false"
            [autoFit]="true"
            [isPrimaryKey]="true"
            [textAlign]="'left'"
            [visible]="false"
            field="id"
            headerText="ID"
          >
            <ng-template #template let-data>
              {{ data.id }}
            </ng-template>
          </e-column>
          <e-column
            [allowEditing]="false"
            [allowFiltering]="true"
            [allowReordering]="false"
            [allowResizing]="false"
            [autoFit]="true"
            [textAlign]="'left'"
            [visible]="false"
            field="created_by"
            headerText="Creator ID"
          ></e-column>
          <e-column
            [autoFit]="true"
            [filter]="filterSettings"
            [textAlign]="'left'"
            clipMode="EllipsisWithTooltip"
            field="name"
            headerText="Název"
          >
          </e-column>
          <e-column
            [filter]="filterSettings"
            [textAlign]="'center'"
            clipMode="EllipsisWithTooltip"
            field="recommended"
            headerText="Doporučeno"
            maxWidth="300"
            minWidth="100"
          >
            <ng-template #template let-data>
                            <span
                              *ngIf="data.recommended"
                              class="e-badge e-badge-pill e-badge-success"
                            >ANO</span
                            >
              <span
                *ngIf="!data.recommended"
                class="e-badge e-badge-pill e-badge-danger"
              >NE</span
              >
            </ng-template>
          </e-column>
          <e-column
            [autoFit]="true"
            [filter]="filterSettings"
            [textAlign]="'center'"
            clipMode="EllipsisWithTooltip"
            field="extension"
            headerText="Rozšíření"
          >
            <ng-template #template let-data>
                            <span
                              *ngIf="data.extension"
                              class="e-badge e-badge-pill e-badge-success"
                            >ANO</span
                            >
              <span
                *ngIf="!data.extension"
                class="e-badge e-badge-pill e-badge-danger"
              >NE</span
              >
            </ng-template>
          </e-column>
          <e-column
            [autoFit]="true"
            [filter]="filterSettings"
            [format]="'#,###.00\',- CZK\''"
            [textAlign]="'right'"
            clipMode="EllipsisWithTooltip"
            field="price"
            headerText="Cena"
          >
          </e-column>
          <e-column
            [autoFit]="true"
            [filter]="filterSettings"
            [textAlign]="'right'"
            clipMode="EllipsisWithTooltip"
            field="sale_order"
            headerText="Pořadí nabídky"
          >
          </e-column>
          <e-column
            [autoFit]="true"
            [filter]="filterSettings"
            [format]="'N'"
            [textAlign]="'center'"
            clipMode="EllipsisWithTooltip"
            field="services"
            headerText="TV služby"
          >
            <ng-template #template let-data>
              <div [innerHTML]="data.services"></div>
            </ng-template>
          </e-column>
          <e-column
            [autoFit]="true"
            [filter]="filterSettings"
            [textAlign]="'center'"
            clipMode="EllipsisWithTooltip"
            field="tv_channels"
            headerText="TV kanály"
          >
            <ng-template #template let-data>
              <div [innerHTML]="data.tv_channels"></div>
            </ng-template>
          </e-column>
          <e-column
            [autoFit]="true"
            [filter]="filterSettings"
            [textAlign]="'left'"
            clipMode="EllipsisWithTooltip"
            field="creator_str"
            headerText="Vytvořil"
          >
            <ng-template #template let-data>
              <div>
                <img
                  *ngIf="data.creator_img"
                  alt="profilový obrázek"
                  class="img-fluid rounded-circle avatar-xs mr-1"
                  src="assets/img{{ data.creator_img }}"
                />
                <span>{{ data.creator_str }}</span>
              </div>
            </ng-template>
          </e-column>
          <e-column
            [allowEditing]="false"
            [autoFit]="true"
            [filter]="filterDate"
            [format]="dateFormat"
            [textAlign]="'right'"
            clipMode="EllipsisWithTooltip"
            editType="datepickeredit"
            field="created_date"
            headerText="Vytvořeno"
            type="date"
          ></e-column>
          <e-column
            [allowEditing]="false"
            [autoFit]="true"
            [filter]="filterDate"
            [format]="dateFormat"
            [textAlign]="'right'"
            clipMode="EllipsisWithTooltip"
            editType="datepickeredit"
            field="updated_date"
            headerText="Naposledy upraveno"
            type="date"
          ></e-column>
          <e-column
            [allowEditing]="false"
            [autoFit]="true"
            [filter]="filterDate"
            [format]="dateFormat"
            [textAlign]="'right'"
            clipMode="EllipsisWithTooltip"
            editType="datepickeredit"
            field="deleted_date"
            headerText="Smazáno"
            type="date"
          ></e-column>
        </e-columns>
        <e-aggregates>
          <e-aggregate>
            <e-columns>
              <e-column [format]="'N'" field="id" type="Count">
                <ng-template #footerTemplate let-data>
                  Celkem řádků: {{ data.Count }}
                </ng-template
                >
              </e-column>
            </e-columns>
          </e-aggregate>
        </e-aggregates>
      </ejs-grid>
    </div>
  </div>
</div>
