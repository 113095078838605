import {
    AfterViewChecked,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnInit,
    ViewChild,
} from '@angular/core';
import {Task} from '@src/app/_models/task/task';
import {ActivatedRoute, Router} from '@angular/router';
import {MessageService} from '@src/app/_services/message.service';
import {AuthenticationService} from '@src/app/_services/authentication.service';
import {AbstractControl, FormBuilder, FormGroup} from '@angular/forms';
import {TaskStatus} from '@src/app/_models/task/task-status';
import {registerLocaleData} from '@angular/common';
import {Observable, Observer} from 'rxjs';
import {DataService} from '@src/app/_services/data.service';
import {Tags} from '@src/app/_models/tags/tags';
import {PermissionService} from '@src/app/_services/permission.service';
import {ButtonPropsModel, DialogComponent} from '@syncfusion/ej2-angular-popups';
import {
    DataResult,
    EditSettingsModel,
    ExcelExportProperties,
    FilterSettingsModel,
    GridComponent,
    GroupSettingsModel,
    PageSettingsModel,
    RowDataBoundEventArgs,
    SelectionSettingsModel,
    ToolbarItems,
} from '@syncfusion/ej2-angular-grids';
import {ChangeEventArgs, DropDownListComponent, MultiSelectComponent} from '@syncfusion/ej2-angular-dropdowns';
import {ClickEventArgs, ToolbarModule} from '@syncfusion/ej2-angular-navigations';
import {FontFamilyModel, ImageSettingsModel, QuickToolbarSettingsModel} from '@syncfusion/ej2-angular-richtexteditor';
import {DomSanitizer} from '@angular/platform-browser';
import {TextBoxComponent} from '@syncfusion/ej2-angular-inputs';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import moment from 'moment';
import localeCs from '@angular/common/locales/cs';
import {EnvironmentService} from '@src/app/_services/environment.service';
import {TaskGridItems, TasksService} from '@src/app/features/tasks/tasks.service';
import {TaskComponent} from '@src/app/features/tasks/components';
import {NavHandlerService} from '@src/app/_services/nav-handler.service';
import {TimeagoIntl} from 'ngx-timeago';

moment.updateLocale('cs', {workingWeekdays: [1, 2, 3, 4, 5]});
registerLocaleData(localeCs);

@UntilDestroy()
@Component({
    selector: 'app-task-queue',
    templateUrl: './task-queue.component.html',
    styleUrls: ['./task-queue.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TaskQueueComponent extends TaskComponent implements OnInit, AfterViewChecked {
    // RTE
    maxLength = 15000;

    textArea: HTMLElement;

    insertImageSettings: ImageSettingsModel;

    fontFamily: FontFamilyModel = {
        items: [
            {
                text: 'Segoe UI',
                value: 'Segoe UI',
                command: 'Font',
                subCommand: 'FontName',
            },
            {
                text: 'Roboto',
                value: 'Roboto',
                command: 'Font',
                subCommand: 'FontName',
            },
            {
                text: 'Great vibes',
                value: 'Great Vibes,cursive',
                command: 'Font',
                subCommand: 'FontName',
            },
            {
                text: 'Noto Sans',
                value: 'Noto Sans',
                command: 'Font',
                subCommand: 'FontName',
            },
            {
                text: 'Impact',
                value: 'Impact,Charcoal,sans-serif',
                command: 'Font',
                subCommand: 'FontName',
            },
            {
                text: 'Tahoma',
                value: 'Tahoma,Geneva,sans-serif',
                command: 'Font',
                subCommand: 'FontName',
            },
        ],
    };

    tools: ToolbarModule = {
        type: 'MultiRow',
        items: [
            'Bold',
            'Italic',
            'Underline',
            'StrikeThrough',
            'FontName',
            'FontSize',
            'FontColor',
            'BackgroundColor',
            'LowerCase',
            'UpperCase',
            'SuperScript',
            'SubScript',
            '|',
            'Formats',
            'Alignments',
            'OrderedList',
            'UnorderedList',
            'Outdent',
            'Indent',
            '|',
            'CreateTable',
            'CreateLink',
            'Image',
            '|',
            'ClearFormat',
            'Print',
            'SourceCode',
            'FullScreen',
            '|',
            'Undo',
            'Redo',
        ],
    };

    quickToolbarSettings: QuickToolbarSettingsModel = {
        table: [
            'TableHeader',
            'TableRows',
            'TableColumns',
            'TableCell',
            '-',
            'BackgroundColor',
            'TableRemove',
            'TableCellVerticalAlign',
            'Styles',
        ],
    };

    pasteCleanupSettings: object = {
        prompt: true,
        plainText: false,
        keepFormat: false,
        deniedTags: ['a'],
        deniedAttrs: ['class', 'title', 'id'],
        allowedStyleProps: ['color', 'margin', 'font-size'],
    };


    // Dialogs
    renderTaskFormDialog = false;
    // Grid
    currencyFormat: {format: string} = {format: '#,###.00\',- CZK\''};
    dateFormat: string;
    toolbar: ToolbarItems[] | object;
    data: object;
    taskExcelExport: object[] = [];
    fields: object = {text: 'label', value: 'value'};
    filterSettings: FilterSettingsModel;
    selectionSettings: SelectionSettingsModel;
    editSettings: EditSettingsModel;
    groupOptions: GroupSettingsModel;
    pageOptions: PageSettingsModel;
    filterDate: object;
    sortOptions: object;
    requiredRules: object;
    exportCurrentPage = false;
    // Froms
    filterTagsForm: FormGroup;
    filterTag: Tags[] | null;
    isCreate = false;
    isUpdate = false;
    isCopy = false;
    // Dropdowns
    height = '240px';
    categoryFilterData: Array<string> = [];
    tagsAutocomplete: Array<{id: number; name: string}> = [];
    // Variables
    tasks: Task[] | undefined = [];
    tags: Tags[] = [];
    status: TaskStatus;
    clickedRow: Task[];
    clickedRow$ = new Observable<Task[]>((observer: Observer<Task[]>) => {
        observer.next(this.clickedRow);
    });
    // Loaders
    tableLoad = true;
    // Dialogs
    @ViewChild('confirmationDelete') confirmationDelete: DialogComponent;
    buttonsDelete: ButtonPropsModel[] = [
        {
            click: (): void => {
                this.taskRemove();
            },
            isFlat: false,
            buttonModel: {
                content: 'OK',
                cssClass: 'e-success e-outline',
            },
        },
        {
            click: (): void => {
                this.confirmationDelete.hide();
            },
            buttonModel: {
                content: 'ZRUŠIT',
                cssClass: 'e-danger e-outline',
            },
        },
    ];
    @ViewChild('confirmationCancel') confirmationCancel: DialogComponent;
    buttonsCancel: ButtonPropsModel[] = [
        {
            click: (): void => {
                this.confirmationCancel.hide();
            },
            buttonModel: {
                isPrimary: true,
                content: 'OK',
            },
        },
    ];
    // Grid
    @ViewChild('tasksGrid') gridTasks: GridComponent;

    @ViewChild('searchtext') searchtextObj: TextBoxComponent;

    // Dropdowns
    @ViewChild('viewFilter') viewFilterInstance: DropDownListComponent;

    @ViewChild('category') categoryObj: DropDownListComponent;

    @ViewChild('user') userObj: MultiSelectComponent;

    constructor(
        authenticationService: AuthenticationService,
        ref: ChangeDetectorRef,
        router: Router,
        route: ActivatedRoute,
        navHandlerService: NavHandlerService,
        dataService: DataService,
        intl: TimeagoIntl,
        private readonly formBuilder: FormBuilder,
        private readonly sanitizer: DomSanitizer,
        private readonly taskService: TasksService,
        private readonly permissionService: PermissionService,
        private readonly messages: MessageService,
        private readonly environmentService: EnvironmentService,
    ) {
        super(
            authenticationService,
            ref,
            router,
            route,
            navHandlerService,
            dataService,
            intl,
        );

        this.insertImageSettings = {
            path: this.environmentService.backendURL + '/api/file/preview/task/',
            saveUrl:
                this.environmentService.backendURL +
                '/api/upload/attachments/tasks?type=attachments&subfolder=tasks',
        };
    }

    get getTagsFilter(): {[key: string]: AbstractControl} {
        return this.filterTagsForm.controls;
    }

    ngOnInit(): void {
        this.filterSettings = {
            type: 'Excel',
            showFilterBarOperator: true,
            showFilterBarStatus: true,
            ignoreAccent: true,
        };

        this.selectionSettings = {
            persistSelection: true,
            type: 'Multiple',
            checkboxOnly: true,
        };

        this.toolbar = [
            {
                text: 'Občerstvit data',
                tooltipText: 'Občerstvit data',
                prefixIcon: 'e-refresh',
                id: 'customRefresh',
            },
            'Print',
            'ExcelExport',
            'Add',
            'Edit',
            {
                text: 'Kopírovat',
                tooltipText: 'Kopírovat úkol',
                prefixIcon: 'e-copy',
                id: 'copy',
            },
            {
                text: 'Smazat',
                tooltipText: 'Smazat úkol',
                prefixIcon: 'e-remove',
                id: 'delete',
            },
            {
                text: 'Detail',
                tooltipText: 'Detail úkolu',
                prefixIcon: 'e-openfolder',
                id: 'detail',
            },
        ];

        this.categoryFilterData = [
            'všechny úkoly',
            'fronta úkolů',
            'vyřízené úkoly',
            'smazané úkoly',
        ];
        this.pageOptions = {pageSize: 50};
        this.dateFormat = 'dd.MM.yyyy HH:mm';
        this.filterDate = {type: 'Menu', params: {format: this.dateFormat}};
        this.groupOptions = {
            showGroupedColumn: true,
            disablePageWiseAggregates: false,
        };

        this.editSettings = {
            allowEditing: true,
            allowAdding: true,
            allowDeleting: true,
            allowEditOnDblClick: false,
            mode: 'Dialog',
        };
        this.requiredRules = {required: true};

        this.dataService.tagSource.pipe(untilDestroyed(this))
            .subscribe(
                (data: Tags[]) => {
                    this.tags = data;
                    this.tagsAutocomplete = [];
                    data.map((tag: Tags) => {
                        this.tagsAutocomplete = [
                            ...this.tagsAutocomplete,
                            {
                                id: tag.id,
                                name: tag.name,
                            },
                        ];
                    });
                },
                error => {
                    console.error(error);
                },
            );

        this.filterTagsForm = this.formBuilder.group({
            tags: [[]],
        });

        this.dataService.setDepartmentDataSource();
        this.dataService.setUsersDataSource();
    }

    ngAfterViewChecked(): void {
        this.route.queryParamMap.pipe(untilDestroyed(this))
            .subscribe(params => {
                if (params.get('form') === 'addTask') {
                    this.isCreate = true;
                    this.isUpdate = false;
                    this.isCopy = false;
                    this.renderTaskFormDialog = true;
                    this.ref.markForCheck();
                }
            });
    }

    loadData(): DataResult[] {
        this.gridTasks.clearRowSelection();

        console.info('NEW DATA BOUND');

        const elements: TaskGridItems[] = [];
        let filteredData = this.tasks;

        if (this.tasks && this.tasks.length > 0) {
            if (this.filterTag) {
                const resultTags: Task[] = [];

                filteredData?.map((task: Task) => {
                    task.tags.map((tag: Tags) => {
                        if (this.filterTag?.find(filter => filter.id === tag.id)) {
                            resultTags.push(task);
                        }
                    });
                });
                filteredData = resultTags;
            }

            // eslint-disable-next-line complexity
            filteredData?.map((task: Task) => {
                let tags = '';
                let tagsStr = '';

                task.tags = task.tags.sort((a, b) => {
                    const stringA = a.name;
                    const stringB = b.name;

                    return stringA > stringB ? 1 : -1;
                });

                task.tags.map((tag: Tags, i, row) => {
                    tags +=
                        '<span class="e-badge e-badge-pill mr-2 ' +
                        (tag.fontColor && tag.fontColor === 'white'
                            ? 'text-white-imp'
                            : 'text-black-imp') +
                        '" style="background-color: ' +
                        tag.color +
                        '; border-color: ' +
                        tag.color +
                        ';">' +
                        tag.name +
                        '</span>';

                    if (i + 1 === row.length) {
                        tagsStr += tag.name;
                    } else {
                        tagsStr += tag.name + ', ';
                    }
                });

                elements.push({
                    id: task.id,
                    ticket_id: task.ticket_id,
                    category_id: task.category_id,
                    status_id: task.status_id,
                    user_id: task.user_id,
                    created_by: task.created_by,
                    creator_str: task.creator.fullname,
                    creator_img: task.creator.personalphoto,
                    user_str: task.solver.fullname,
                    user_img: task.solver.personalphoto,
                    subject: task.subject,
                    maintask: task.maintask,
                    maintaskHTML: task.maintaskHTML,
                    category: task.category?.name ?? 'nepřiřazena',
                    due: task.taskTime.overtime && !task.deleted_date ? 'po termínu' : 'v termínu',
                    status:
                        task.status.name === 'solved'
                            ? 'dokončen'
                            : task.status.name === 'in queue'
                                ? 've frontě'
                                : 'smazán',
                    tags: this.sanitizer.bypassSecurityTrustHtml(tags),
                    deadline: new Date(task.deadline),
                    finished_at: task.finished_at ? new Date(task.finished_at) : null,
                    created_date: new Date(task.created_date),
                    updated_date: task.updated_date ? new Date(task.updated_date) : null,
                    deleted_date: task.deleted_date ? new Date(task.deleted_date) : null,
                });

                this.taskExcelExport.push({
                    id: task.id,
                    ticket_id: task.ticket_id,
                    category_id: task.category_id,
                    status_id: task.status_id,
                    user_id: task.user_id,
                    created_by: task.created_by,
                    creator_str: task.creator.fullname,
                    creator_img: task.creator.personalphoto,
                    user_str: task.solver.fullname,
                    user_img: task.solver.personalphoto,
                    subject: task.subject,
                    maintask: task.maintask.replace(/<[^>]*>?/gm, ''),
                    category: task.category?.name,
                    due: task.taskTime.overtime && !task.deleted_date ? 'po termínu' : 'v termínu',
                    status:
                        task.status.name === 'solved'
                            ? 'dokončen'
                            : task.status.name === 'in queue'
                                ? 've frontě'
                                : 'smazán',
                    tags: tagsStr,
                    deadline: new Date(task.deadline),
                    finished_at: task.finished_at ? new Date(task.finished_at) : null,
                    created_date: new Date(task.created_date),
                    updated_date: task.updated_date ? new Date(task.updated_date) : null,
                    deleted_date: task.deleted_date ? new Date(task.deleted_date) : null,
                });
            });
        }

        this.tableLoad = false;
        this.ref.markForCheck();

        return elements as unknown as DataResult[];
    }

    onCreated(): void {
        this.disableToolBarBtn();
        document.getElementById('tasksGrid')
            ?.addEventListener('click', (args) => {
                if (!(args.target as any).classList.contains('e-rowcell')) {
                    const rowElement = (args.target as any).closest('.e-rowcell');

                    if (rowElement) {
                        const rowInfo = this.gridTasks.getRowInfo(rowElement as EventTarget); // get row information

                        if (rowInfo.cellIndex !== 0) {
                            void this.router.navigate(['/tasks/detail', (rowInfo.rowData as any)?.id]);
                        }
                    }
                } else {
                    const rowInfo = this.gridTasks.getRowInfo(args.target!); // get row information

                    if (rowInfo.cellIndex !== 0) {
                        void this.router.navigate(['/tasks/detail', (rowInfo.rowData as any)?.id]);
                    }
                }
            });

    }

    setInitialGridFiltering(): void {
        // this.gridTasks.filterByColumn('created_by', 'equal', this.currentUser.id, 'and', false, true);
        // this.viewFilterInstance.value = 'moje žádanky - všechny';
        this.ref.markForCheck();
    }

    onLoad(): void {
        const wrapper1 = document.getElementById('scroll_wrapper1_tasks');
        const wrapper2 = document.getElementById('scroll_wrapper2_tasks');

        if (wrapper1 && wrapper2) {
            wrapper1.onscroll = (): void => {
                wrapper2.scrollLeft = wrapper1.scrollLeft;
            };

            wrapper2.onscroll = (): void => {
                wrapper1.scrollLeft = wrapper2.scrollLeft;
            };
        }

        this.dataService.taskSource.pipe(untilDestroyed(this))
            .subscribe(
                (tasks: Task[] | undefined) => {
                    this.tasks = tasks;

                    if (this.tasks && this.tasks.length > 0) {
                        this.data = this.gridTasks.dataSource = this.loadData();
                        this.sortOptions = {
                            columns: [
                                {field: 'created_date', direction: 'Descending'},
                                {field: 'id', direction: 'Descending'},
                            ],
                        };
                    } else {
                        this.tableLoad = false;
                    }
                },
                error => {
                    console.error(error);
                    this.tableLoad = false;
                    this.ref.markForCheck();
                },
            );
    }

    search(): void {
        this.gridTasks.search(this.searchtextObj.value);
    }

    onDataBound(): void {
        this.rowSelected();

        const width = document.getElementById('tasksGrid_content_table')?.offsetWidth;
        const parent1 = document.getElementById('scroll_div_tasks');
        const parent2 = document.getElementById('grid_parent_tasks');

        if (parent1 && parent2 && width) {
            parent1.style.width = `${width}px`;
            parent2.style.width = `${width}px`;
        }

        this.gridTasks.autoFitColumns();
        this.ref.markForCheck();
    }

    rowDataBound(args: RowDataBoundEventArgs): void {
        const data = args.data as Task;

        if (args.row && data.deleted_date) {
            args.row.getElementsByClassName('e-gridchkbox')[0].classList.add('disablecheckbox');
            args.row
                .getElementsByClassName('e-checkbox-wrapper')[0]
                .classList.add('disablecheckbox');
            args.row.classList.add('e-disabled');
        }

        if (
            this.currentUser &&
            args.row &&
            data.created_by !== this.currentUser.id &&
            !this.permissionService.checkUserISAdministrative(this.currentUser)
        ) {
            args.row.getElementsByClassName('e-gridchkbox')[0].classList.add('disablecheckbox');
            args.row
                .getElementsByClassName('e-checkbox-wrapper')[0]
                .classList.add('disablecheckbox');
        }

        this.ref.markForCheck();
    }

    onStatusFilterChange(e: ChangeEventArgs): void {
        if (e.value === 'všechny úkoly') {
            this.gridTasks.clearFiltering();
        } else if (e.value === 'fronta úkolů') {
            this.gridTasks.filterSettings = {
                columns: [
                    {
                        field: 'finished_at',
                        operator: 'equal',
                        value: null as unknown as string,
                        predicate: 'and',
                        matchCase: false,
                        ignoreAccent: true,
                    },
                    {
                        field: 'deleted_date',
                        operator: 'equal',
                        value: null as unknown as string,
                        predicate: 'and',
                        matchCase: false,
                        ignoreAccent: true,
                    },
                ],
            };
        } else if (e.value === 'vyřízené úkoly') {
            this.gridTasks.clearFiltering();
            this.gridTasks.filterByColumn('finished_at', 'notequal', '', 'and', false, true);
        } else if (e.value === 'smazané úkoly') {
            this.gridTasks.clearFiltering();
            this.gridTasks.filterByColumn('deleted_date', 'notequal', null, 'and', false, true);
        }
    }

    cancelBtnClick(): void {
        this.searchtextObj.value = '';
        this.gridTasks.searchSettings.key = '';
    }

    toolbarClick(args: ClickEventArgs): void {
        const xlsProp: ExcelExportProperties = {
            dataSource: this.taskExcelExport,
            fileName: 'js_tasks_export.xlsx',
            enableFilter: true,
            exportType: this.exportCurrentPage ? 'CurrentPage' : 'AllPages',
        };

        if (args.item.id === 'tasksGrid_excelexport') {
            void this.gridTasks.excelExport(xlsProp);
        } else if (args.item.id === 'delete') {
            if (this.gridTasks.getSelectedRecords().length !== 0) {
                this.confirmationDelete.content = `Potvrďte smazání ${this.clickedRow.length} úkolů`;
                this.confirmationDelete.show();
            } else {
                this.confirmationCancel.content = 'Musíte vybrat alespoň jeden úkol';
                this.confirmationCancel.show();
            }
        } else if (args.item.id === 'copy') {
            if (this.gridTasks.getSelectedRecords().length !== 0) {
                this.isUpdate = false;
                this.isCreate = false;
                this.isCopy = true;
                this.renderTaskFormDialog = true;
                this.ref.markForCheck();
            } else {
                this.confirmationCancel.content = 'Musíte vybrat alespoň jeden úkol';
                this.confirmationCancel.show();
            }
        } else if (args.item.id === 'detail') {
            void this.router.navigate(['/tasks/detail', this.clickedRow[0].id]);
        } else if (args.item.id === 'customRefresh') {
            this.tableLoad = true;
            console.info('refresh DATA!');
            this.ref.markForCheck();
            this.dataService.clearTasksCache();
            this.dataService.setTasksDataSource();
        }
    }

    enableToolbarBtn(): void {
        this.gridTasks.toolbarModule.enableItems(
            ['tasksGrid_edit', 'delete', 'copy', 'detail'],
            true,
        );
    }

    disableToolBarBtn(): void {
        this.gridTasks.toolbarModule.enableItems(
            ['tasksGrid_edit', 'delete', 'copy', 'detail'],
            false,
        );
    }

    rowSelected(): void {
        this.clickedRow = [];

        const selectedrecords: object[] = this.gridTasks.getSelectedRecords();

        if (selectedrecords.length > 0) {
            selectedrecords.map((row: Task) => {
                const tempData: Task | undefined = this.tasks?.find(x => x.id === row.id);

                if (tempData) {
                    this.clickedRow.push(tempData);
                }

                this.ref.markForCheck();
            });

            this.clickedRow$ = new Observable<Task[]>((observer: Observer<Task[]>) => {
                observer.next(this.clickedRow);
            });
            this.enableToolbarBtn();
        } else {
            this.disableToolBarBtn();
            this.clickedRow$ = new Observable<Task[]>((observer: Observer<Task[]>) => {
                observer.next(this.clickedRow);
            });
        }
    }

    actionBegin(args): void {
        if (args.requestType === 'add' || args.requestType === 'beginEdit') {
            args.cancel = true;

            if (args.requestType === 'beginEdit') {
                this.isUpdate = true;
                this.isCreate = false;
                this.isCopy = false;
            }

            if (args.requestType === 'add') {
                this.isCreate = true;
                this.isUpdate = false;
                this.isCopy = false;
            }

            this.renderTaskFormDialog = true;
            this.ref.markForCheck();
        }
    }

    actionComplete(args): void {
        if (args.requestType === 'beginEdit' || args.requestType === 'add') {
            args.form.ej2_instances[0].rules = {};
        }
    }

    taskRemove(): void {
        const promises: Promise<Task | undefined>[] = [];
        let rejects = 0;

        this.tableLoad = true;

        for (const row of this.clickedRow) {
            if (
                this.currentUser &&
                !row.deleted_date &&
                !row.finished_at &&
                (this.permissionService.checkUserISTaskAdmin(this.currentUser) ||
                    row.created_by === this.currentUser.id)
            ) {
                row.deleted_by = this.currentUser.id;

                const declineObj = this.taskService.deleteTask(row)
                    ?.toPromise();

                if (declineObj) {
                    promises.push(declineObj);
                }
            } else {
                rejects++;
            }
        }

        void Promise.all(promises)
            .then(result => {
                if (result.length > 0) {
                    const body = `Celkem smazáno: ${result.length} úkolů`;
                    const options = {progressBar: true, timeOut: 5000};

                    this.messages.showSuccess('Smazání úspěšně dokončeno', body, options);
                }

                if (rejects > 0) {
                    const body = `Celkem u: ${rejects} úkolů`;
                    const options = {progressBar: true, timeOut: 5000};

                    this.messages.showError('Nedostatečné oprávnění', body, options);
                }

                this.gridTasks.clearRowSelection();
                this.dataService.setTasksDataSource();
                this.rowSelected();
                this.tableLoad = false;
                this.confirmationDelete.hide();
                this.ref.markForCheck();
            });
    }

    onAdd(): void {
        this.filterTags();
    }

    onRemove(): void {
        this.filterTags();
    }

    filterTags(): void {
        if (this.getTagsFilter.tags.value.length > 0) {
            this.filterTag = this.getTagsFilter.tags.value;

            const tags = this.filterTag ? this.filterTag : false;

            console.info('FILTER: tags: ' + JSON.stringify(tags));
        } else {
            this.filterTagsForm.controls.tags.reset(null, {emitEvent: false});
            this.filterTag = null;
        }

        this.data = this.gridTasks.dataSource = this.loadData();
    }
}
