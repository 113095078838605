import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    ViewChild,
} from '@angular/core';
import {DialogComponent} from '@syncfusion/ej2-angular-popups';
import {User} from '@src/app/_models/user/user';
import {TvPackages} from '@src/app/_models/services/tv-packages';
import {NetPackages} from '@src/app/_models/services/net-packages';
import {UntilDestroy} from '@ngneat/until-destroy';
import {ActivatedRoute, Router} from '@angular/router';
import {DataNetbaseService} from '@src/app/_services/data-netbase.service';
import {AuthenticationService} from '@src/app/_services/authentication.service';

@UntilDestroy()
@Component({
    selector: 'app-packages-compare',
    templateUrl: './packages-compare.component.html',
    styleUrls: ['./packages-compare.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PackagesCompareComponent implements OnChanges {
    // Variables
    currentUser: User | null;

    // Loaders
    loadingCompare = false;

    @Input() tvPackages: Awaited<TvPackages | undefined>[] = [];

    @Input() netPackages: NetPackages[] = [];

    @Input() idNetPackage: number;

    @Input() idTvPackage: number;

    @Input() net: boolean;

    @Input() tv: boolean;

    @Input() isVisible = false;

    @Output() readonly compareDialogState = new EventEmitter<boolean>();

    // Dialogs
    @ViewChild('compareDialog') compareDialogObj: DialogComponent;

    constructor(
        private readonly router: Router,
        private readonly ref: ChangeDetectorRef,
        private readonly route: ActivatedRoute,
        private readonly dataNetbaseService: DataNetbaseService,
        private readonly authenticationService: AuthenticationService,
    ) {
        this.currentUser = this.authenticationService.currentUserValue;
    }

    ngOnChanges(): void {
        if (this.isVisible) {
            this.compareDialogObj.header = 'Porovnávač balíčků';
            this.loadingCompare = true;

            if (this.tvPackages.length > 0) {
                const promises: Promise<TvPackages | undefined>[] = [];

                this.tvPackages.forEach((tvPackage: TvPackages) => {
                    const packageObj = this.dataNetbaseService
                        .getTvPackageData(tvPackage.id)
                        .toPromise();

                    promises.push(packageObj);
                });

                void Promise.all(promises)
                    .then(result => {
                        if (result.length > 0) {
                            this.tvPackages = result;
                        }

                        this.ref.markForCheck();
                    });
            }
        }
    }

    changeDialogState(value: boolean): void {
        this.compareDialogState.emit(value);
    }
}
