import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule, Routes} from '@angular/router';
import {AuthGuard} from '@src/app/_guards/auth.guard';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {EmployersTableComponent} from '@src/app/features/settings/components/tables/employers-table/employers-table.component';
import {MaterialPaymentCreateFormComponent} from '@src/app/features/settings/_forms/material-payment-create-form/material-payment-create-form.component';
import {EmployerFormComponent} from '@src/app/features/settings/_forms/employer-form/employer-form.component';
import {SettingsComponent} from '@src/app/features/settings/components/settings/settings.component';
import {ApiDetailComponent} from '@src/app/features/settings/components/api-detail/api-detail.component';
import {ApiFormComponent} from '@src/app/features/settings/_forms/api-form/api-form.component';
import {ApiHeaderFormComponent} from '@src/app/features/settings/_forms/api-header-form/api-header-form.component';
import {ApiPathFormComponent} from '@src/app/features/settings/_forms/api-path-form/api-path-form.component';
import {ApisTableComponent} from '@src/app/features/settings/components/tables/apis-table/apis-table.component';
import {BuildingFormComponent} from '@src/app/features/settings/_forms/building-form/building-form.component';
import {CompaniesTableComponent} from '@src/app/features/settings/components/tables/companies-table/companies-table.component';
import {CompanyFormComponent} from '@src/app/features/settings/_forms/company-form/company-form.component';
import {DepartmentFormComponent} from '@src/app/features/settings/_forms/department-form/department-form.component';
import {DepartmentsTableComponent} from '@src/app/features/settings/components/tables/departments-table/departments-table.component';
import {RoleFormComponent} from '@src/app/features/settings/_forms/role-form/role-form.component';
import {RolesTableComponent} from '@src/app/features/settings/components/tables/roles-table/roles-table.component';
import {SyncCreateFormComponent} from '@src/app/features/settings/_forms/sync-create-form/sync-create-form.component';
import {VacationCategoryCreateFormComponent} from '@src/app/features/settings/_forms/vacation-category-create-form/vacation-category-create-form.component';
import {SharedFeaturesModule} from '@src/app/shared_features/shared-features.module';
import {
    CarsComponent,
    CarsDetailComponent,
    CarsListComponent,
} from '@src/app/features/settings/components/tables/cars-table';
import {OfficeFormComponent} from '@src/app/features/settings/_forms/office-form/office-form.component';
import {CarsFormComponent} from '@src/app/features/settings/_forms/cars-form/cars-form.component';
import {
    OfficeComponent,
    OfficeDetailComponent,
    OfficeListComponent,
} from '@src/app/features/settings/components/tables/office-table';
import {BuildingsTableComponent} from './components/tables/buildings-table/buildings-table.component';

const routes: Routes = [
    {
        path: 'settings',
        children: [
            {
                path: '',
                component: SettingsComponent,
                data: {
                    title: 'Nastavení aplikace',
                    breadcrumb: [
                        {
                            label: 'Nastavení aplikace',
                            url: '',
                        },
                    ],
                },
            },
            {
                path: 'apis/:id',
                component: ApiDetailComponent,
                data: {
                    title: 'Detail API spojení',
                    breadcrumb: [
                        {
                            label: 'Nastavení aplikace',
                            url: '',
                        },
                        {
                            label: 'Detail API spojení',
                            url: '',
                        },
                    ],
                },
            },
        ],
        canActivate: [AuthGuard],
    },
];

@NgModule({
    declarations: [
        ApiDetailComponent,
        ApiFormComponent,
        ApiHeaderFormComponent,
        ApiPathFormComponent,
        ApisTableComponent,
        BuildingFormComponent,
        BuildingsTableComponent,
        CarsComponent,
        CarsDetailComponent,
        CarsFormComponent,
        CarsListComponent,
        CompaniesTableComponent,
        CompanyFormComponent,
        DepartmentFormComponent,
        DepartmentsTableComponent,
        EmployerFormComponent,
        EmployersTableComponent,
        MaterialPaymentCreateFormComponent,
        OfficeComponent,
        OfficeDetailComponent,
        OfficeFormComponent,
        OfficeListComponent,
        RoleFormComponent,
        RolesTableComponent,
        SettingsComponent,
        SyncCreateFormComponent,
        VacationCategoryCreateFormComponent,
    ],
    imports: [
        BrowserAnimationsModule,
        RouterModule.forChild(routes),
        BrowserModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        SharedFeaturesModule,
    ],
    exports: [SettingsComponent],
})
export class SettingsModule {}
