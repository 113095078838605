import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Params, Router} from '@angular/router';
import {User} from '@src/app/_models/user/user';
import {MessageService} from '@src/app/_services/message.service';
import {AuthenticationService} from '@src/app/_services/authentication.service';
import {registerLocaleData} from '@angular/common';
import {FullCalendarComponent} from '@fullcalendar/angular';
import {CalendarOptions, EventInput} from '@fullcalendar/core';
import {VacationCategory} from '@src/app/_models/vacation/vacation-category';
import {Vacation} from '@src/app/_models/vacation/vacation';
import {Task} from '@src/app/_models/task/task';
import {Tickets} from '@src/app/_models/ticket/tickets';
import {AcsUserLogs} from '@src/app/_models/_api_acs/logs';
import {AcsCategory} from '@src/app/_models/_api_acs/category';
import {AcsService} from '@src/app/_api/acs/acs.service';
import {DataService} from '@src/app/_services/data.service';
import {ApiConnections} from '@src/app/_models/api/connections';
import {ApisService} from '@src/app/_api/apis.service';
import {PermissionService} from '@src/app/_services/permission.service';
import csLocale from '@fullcalendar/core/locales/cs';

import moment from 'moment-business-days';
import localeCs from '@angular/common/locales/cs';
import {SignaturesCreatorService} from '@src/app/_services/signatures-creator.service';
import {HtmlSignatures} from '@src/app/_models/_html_signatures/html-signatures';
import {DomSanitizer} from '@angular/platform-browser';
import {ButtonPropsModel, DialogComponent} from '@syncfusion/ej2-angular-popups';
import {NavHandlerService} from '@src/app/_services/nav-handler.service';
import {ChartComponent} from '@syncfusion/ej2-angular-charts';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {TaskEvents, TicketEvents, VacationEvents} from '@src/app/features/events/components/events/events.component';
import {UserInput, UsersService} from '@src/app/features/users/users.service';
import {VacationService} from '@src/app/features/vacation/vacation.service';
import {TasksService} from '@src/app/features/tasks/tasks.service';
import {TicketsService} from '@src/app/features/tickets/tickets.service';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import timeGrid from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import googleCalendarPlugin from '@fullcalendar/google-calendar';
import {distinctUntilChanged} from 'rxjs';
import {filter} from 'rxjs/operators';

moment.updateLocale('cs', {workingWeekdays: [1, 2, 3, 4, 5]});
registerLocaleData(localeCs);

@UntilDestroy()
@Component({
    selector: 'app-users-detail',
    templateUrl: './users-detail.component.html',
    styleUrls: ['./users-detail.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UsersDetailComponent implements OnInit {
    // Variables
    today = moment()
        .format('YYYY-MM-DD');

    currentUser: User | null;

    acsApiState: boolean;

    crmApiState: boolean;

    erpApiState: boolean;

    carsApiState: boolean;

    mntApiState: boolean;

    vacationState: {atWork: boolean; vacations: Vacation[]} = {
        atWork: true,
        vacations: [],
    };

    user: User;

    htmlSignatures: HtmlSignatures[] = [];

    selectedFile: File;

    vacations: Vacation[] | undefined = [];

    categories: VacationCategory[] | undefined;

    tasks: Task[] | undefined = [];

    tickets: Tickets[] | undefined = [];

    acsUserLogs: AcsUserLogs[] | undefined = [];

    apis: {
        acs: ApiConnections | boolean;
        crm: ApiConnections | boolean;
        erp: ApiConnections | boolean;
        siteMonitoring: ApiConnections | boolean;
        cars: ApiConnections | boolean;
    } = {
        acs: false,
        crm: false,
        erp: false,
        siteMonitoring: false,
        cars: false,
    };

    // Forms
    isCreate = false;

    isUpdate = false;

    isCopy = false;

    // Dialogs
    renderUserFormDialog = false;

    renderChangeUserPassword = false;

    buttonsDelete: ButtonPropsModel[] = [
        {
            click: (): void => {
                this.deAuthorize();
            },
            isFlat: false,
            buttonModel: {
                content: 'OK',
                cssClass: 'e-success e-outline',
            },
        },
        {
            click: (): void => {
                this.confirmationDelete.hide();
            },
            buttonModel: {
                content: 'ZRUŠIT',
                cssClass: 'e-danger e-outline',
            },
        },
    ];

    buttonsReset: ButtonPropsModel[] = [
        {
            click: (): void => {
                this.resetPassword();
            },
            isFlat: false,
            buttonModel: {
                content: 'OK',
                cssClass: 'e-success e-outline',
            },
        },
        {
            click: (): void => {
                this.confirmationReset.hide();
            },
            buttonModel: {
                content: 'ZRUŠIT',
                cssClass: 'e-danger e-outline',
            },
        },
    ];

    buttonsAuthorized: ButtonPropsModel[] = [
        {
            click: (): void => {
                this.reAuthorize();
            },
            isFlat: false,
            buttonModel: {
                content: 'OK',
                cssClass: 'e-success e-outline',
            },
        },
        {
            click: (): void => {
                this.confirmationAuthorized.hide();
            },
            buttonModel: {
                content: 'ZRUŠIT',
                cssClass: 'e-danger e-outline',
            },
        },
    ];

    buttonsSyncToAcsAPI: ButtonPropsModel[] = [
        {
            click: (): void => {
                this.syncUserToAcsApi();
            },
            isFlat: false,
            buttonModel: {
                content: 'OK',
                cssClass: 'e-success e-outline',
            },
        },
        {
            click: (): void => {
                this.confirmationSyncToAcsAPI.hide();
            },
            buttonModel: {
                content: 'ZRUŠIT',
                cssClass: 'e-danger e-outline',
            },
        },
    ];

    buttonsSyncToMtnAPI: ButtonPropsModel[] = [
        {
            click: (): void => {
                this.syncUserToMntApi();
            },
            isFlat: false,
            buttonModel: {
                content: 'OK',
                cssClass: 'e-success e-outline',
            },
        },
        {
            click: (): void => {
                this.confirmationSyncToMtnAPI.hide();
            },
            buttonModel: {
                content: 'ZRUŠIT',
                cssClass: 'e-danger e-outline',
            },
        },
    ];

    buttonsSyncToErpAPI: ButtonPropsModel[] = [
        {
            click: (): void => {
                this.syncUserToErpApi();
            },
            isFlat: false,
            buttonModel: {
                content: 'OK',
                cssClass: 'e-success e-outline',
            },
        },
        {
            click: (): void => {
                this.confirmationSyncToErpAPI.hide();
            },
            buttonModel: {
                content: 'ZRUŠIT',
                cssClass: 'e-danger e-outline',
            },
        },
    ];

    buttonsSyncToCrmAPI: ButtonPropsModel[] = [
        {
            click: (): void => {
                this.syncUserToCrmApi();
            },
            isFlat: false,
            buttonModel: {
                content: 'OK',
                cssClass: 'e-success e-outline',
            },
        },
        {
            click: (): void => {
                this.confirmationSyncToCrmAPI.hide();
            },
            buttonModel: {
                content: 'ZRUŠIT',
                cssClass: 'e-danger e-outline',
            },
        },
    ];

    buttonsSyncToCarsAPI: ButtonPropsModel[] = [
        {
            click: (): void => {
                this.syncUserToCarsApi();
            },
            isFlat: false,
            buttonModel: {
                content: 'OK',
                cssClass: 'e-success e-outline',
            },
        },
        {
            click: (): void => {
                this.confirmationSyncToCarsAPI.hide();
            },
            buttonModel: {
                content: 'ZRUŠIT',
                cssClass: 'e-danger e-outline',
            },
        },
    ];

    buttonsCreateToAcsAPI: ButtonPropsModel[] = [
        {
            click: (): void => {
                this.createUserToAcsApi();
            },
            isFlat: false,
            buttonModel: {
                content: 'OK',
                cssClass: 'e-success e-outline',
            },
        },
        {
            click: (): void => {
                this.confirmationCreateToAcsAPI.hide();
            },
            buttonModel: {
                content: 'ZRUŠIT',
                cssClass: 'e-danger e-outline',
            },
        },
    ];

    buttonsCreateToMtnAPI: ButtonPropsModel[] = [
        {
            click: (): void => {
                this.createUserToMntApi();
            },
            isFlat: false,
            buttonModel: {
                content: 'OK',
                cssClass: 'e-success e-outline',
            },
        },
        {
            click: (): void => {
                this.confirmationCreateToMtnAPI.hide();
            },
            buttonModel: {
                content: 'ZRUŠIT',
                cssClass: 'e-danger e-outline',
            },
        },
    ];

    buttonsCreateToErpAPI: ButtonPropsModel[] = [
        {
            click: (): void => {
                this.createUserToErpApi();
            },
            isFlat: false,
            buttonModel: {
                content: 'OK',
                cssClass: 'e-success e-outline',
            },
        },
        {
            click: (): void => {
                this.confirmationCreateToErpAPI.hide();
            },
            buttonModel: {
                content: 'ZRUŠIT',
                cssClass: 'e-danger e-outline',
            },
        },
    ];

    buttonsCreateToCrmAPI: ButtonPropsModel[] = [
        {
            click: (): void => {
                this.createUserToCrmApi();
            },
            isFlat: false,
            buttonModel: {
                content: 'OK',
                cssClass: 'e-success e-outline',
            },
        },
        {
            click: (): void => {
                this.confirmationCreateToCrmAPI.hide();
            },
            buttonModel: {
                content: 'ZRUŠIT',
                cssClass: 'e-danger e-outline',
            },
        },
    ];

    buttonsCreateToCarsAPI: ButtonPropsModel[] = [
        {
            click: (): void => {
                this.createUserToCarsApi();
            },
            isFlat: false,
            buttonModel: {
                content: 'OK',
                cssClass: 'e-success e-outline',
            },
        },
        {
            click: (): void => {
                this.confirmationCreateToCarsAPI.hide();
            },
            buttonModel: {
                content: 'ZRUŠIT',
                cssClass: 'e-danger e-outline',
            },
        },
    ];

    buttonsEmailSignature: ButtonPropsModel[] = [
        {
            click: (): void => {
                this.dialogEmailSignatureObj.hide();
            },
            buttonModel: {
                content: 'ZRUŠIT',
                cssClass: 'e-danger e-outline',
            },
        },
    ];

    // FullCalendar
    calendarOptions: CalendarOptions = {
        googleCalendarApiKey: 'AIzaSyCCLvaMHyCqnbJC9DsQTvXGQ8lgS4D_hzo',
        initialView: 'dayGridMonth',
        firstDay: 1,
        headerToolbar: {
            start: 'title',
            center: 'dayGridMonth,timeGridWeek,listWeek',
            end: 'today prev,next',
        },
        locale: csLocale,
        buttonText: {
            today: 'dnes',
            month: 'měsíc',
            week: 'týden',
            day: 'den',
            list: 'seznam',
        },
        allDayText: 'celý den',
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        dateClick: this.navigateToEvent.bind(this),
        initialEvents: [],
        eventSources: [],
        eventTimeFormat: {
            hour: '2-digit',
            minute: '2-digit',
            meridiem: false,
        },
        plugins: [dayGridPlugin, interactionPlugin, timeGrid, listPlugin, googleCalendarPlugin],
    };

    calendarEvents: Array<unknown> = [];

    ticketEvents: Array<TicketEvents> = [];

    vacationEvents: Array<VacationEvents> = [];

    taskEvents: Array<TaskEvents> = [];

    // Charts
    primaryXAxis: object;

    primaryYAxis: object;

    chartVacData: object;

    chartSickData: object;

    usedVacDays = 0;

    usedSickDays = 0;

    legendSettings: object;

    tooltip: object;

    datalabel: object;

    // Loaders
    loadingUser = true;

    loadingAcsApi = true;

    loadingErpApi = true;

    loadingMntApi = true;

    loadingCrmApi = true;

    loadingCarsApi = true;

    loadingVacation = true;

    loadingTicket = true;

    loadingTask = true;

    loadingCalendar = false;

    // Dialogs
    @ViewChild('confirmationDelete') confirmationDelete: DialogComponent;

    @ViewChild('confirmationReset') confirmationReset: DialogComponent;

    @ViewChild('confirmationAuthorized') confirmationAuthorized: DialogComponent;

    @ViewChild('confirmationSyncToAcsAPI')
    confirmationSyncToAcsAPI: DialogComponent;

    @ViewChild('confirmationSyncToMtnAPI')
    confirmationSyncToMtnAPI: DialogComponent;

    @ViewChild('confirmationSyncToErpAPI')
    confirmationSyncToErpAPI: DialogComponent;

    @ViewChild('confirmationSyncToCrmAPI')
    confirmationSyncToCrmAPI: DialogComponent;

    @ViewChild('confirmationSyncToCarsAPI')
    confirmationSyncToCarsAPI: DialogComponent;

    @ViewChild('confirmationCreateToAcsAPI')
    confirmationCreateToAcsAPI: DialogComponent;

    @ViewChild('confirmationCreateToMtnAPI')
    confirmationCreateToMtnAPI: DialogComponent;

    @ViewChild('confirmationCreateToErpAPI')
    confirmationCreateToErpAPI: DialogComponent;

    @ViewChild('confirmationCreateToCrmAPI')
    confirmationCreateToCrmAPI: DialogComponent;

    @ViewChild('confirmationCreateToCarsAPI')
    confirmationCreateToCarsAPI: DialogComponent;

    @ViewChild('DialogEmailSignature') dialogEmailSignatureObj: DialogComponent;

    @ViewChild('passwordWarning') passwordWarningDialog: DialogComponent;

    @ViewChild('userPasswordForm') userPasswordFormDialog: DialogComponent;

    // FullCalendar
    @ViewChild('personalCalendar') calendarComponent: FullCalendarComponent;

    // Charts
    @ViewChild('vacationsChart') vacationsChart: ChartComponent;

    constructor(
        private readonly route: ActivatedRoute,
        private readonly router: Router,
        private readonly dataService: DataService,
        private readonly userService: UsersService,
        private readonly permissionsService: PermissionService,
        private readonly authenticationService: AuthenticationService,
        private readonly navHandlerService: NavHandlerService,
        private readonly acsService: AcsService,
        private readonly apisService: ApisService,
        private readonly vacationService: VacationService,
        private readonly messages: MessageService,
        private readonly permissionService: PermissionService,
        private readonly taskService: TasksService,
        private readonly ticketService: TicketsService,
        private readonly sanitizer: DomSanitizer,
        private readonly ref: ChangeDetectorRef,
        private readonly signatureService: SignaturesCreatorService,
    ) {
        this.currentUser = this.authenticationService.currentUserValue;
    }

    // DATA COMPONENT LOADERS
    // eslint-disable-next-line max-lines-per-function
    private loadData(): void {
        this.calendarOptions.events = [];
        this.vacationEvents = [];
        this.ticketEvents = [];
        this.taskEvents = [];
        this.calendarEvents = [];
        this.vacations?.map((vacations: Vacation) => {
            if (typeof this.vacations !== 'undefined') {
                this.vacationState =
                    this.vacations.length > 0 && typeof this.user !== 'undefined'
                        ? this.vacationService.getVacationState(this.vacations, this.user)
                        : {
                            atWork: true,
                            vacations: [],
                        };

                if (!vacations.deleted_date && !vacations.decline_at) {
                    this.vacationEvents.push({
                        vacationData: vacations,
                        title: 'Žádost: ' + vacations.category.name,
                        start: moment(vacations.start_at)
                            .format('YYYY-MM-DD HH:mm:ss'),
                        end: moment(vacations.end_at)
                            .format('YYYY-MM-DD HH:mm:ss'),
                        backgroundColor:
                            (vacations.decline || vacations.confirm) && !vacations.decline_at
                                ? vacations.category.colortag
                                : '#666666',
                        borderColor:
                            (vacations.decline || vacations.confirm) && !vacations.decline_at
                                ? vacations.category.colortag
                                : '#666666',
                        textColor: '#ffffff',
                        allDay: false,
                        editable: true,
                        startEditable: true,
                        durationEditable: true,
                        timeFormat: 'H:mm',
                    });
                }
            }
        });
        this.loadingVacation = false;
        this.tickets?.map((ticket: Tickets) => {
            if (
                !ticket.deleted_date &&
                this.ticketService.checkTicketAssignation(ticket, this.user)
            ) {
                if (ticket.deadline) {
                    this.ticketEvents.push({
                        ticketData: ticket,
                        title: 'Ticket: ' + ticket.subject,
                        start: moment(ticket.created_date)
                            .format('YYYY-MM-DD HH:mm:ss'),
                        end: moment(ticket.deadline)
                            .format('YYYY-MM-DD HH:mm:ss'),
                        backgroundColor: '#36b7db',
                        borderColor: '#36b7db',
                        textColor: '#ffffff',
                        allDay: false,
                        editable: true,
                        startEditable: true,
                        durationEditable: true,
                        timeFormat: 'H:mm',
                    });
                }
            }
        });
        this.loadingTicket = false;
        this.tasks?.map((task: Task) => {
            if (!task.deleted_date && task.user_id === this.user.id) {
                this.taskEvents.push({
                    taskData: task,
                    title: 'Úkol: ' + task.subject,
                    start: moment(task.created_date)
                        .format('YYYY-MM-DD HH:mm:ss'),
                    end: moment(task.deadline)
                        .format('YYYY-MM-DD HH:mm:ss'),
                    backgroundColor: '#ffbb32',
                    borderColor: '#ffbb32',
                    textColor: '#ffffff',
                    allDay: false,
                    editable: true,
                    startEditable: true,
                    durationEditable: true,
                    timeFormat: 'H:mm',
                });
            }
        });
        this.loadingTask = false;
        this.calendarOptions.eventSources = [
            {
                googleCalendarId: 'cs.czech.official#holiday@group.v.calendar.google.com',
                color: 'yellow',
                backgroundColor: '#ff8c00',
                borderColor: '#ff8c00',
                textColor: '#ffffff',
                editable: false,
                startEditable: false,
                durationEditable: false,
            },
            this.calendarEvents
                .concat(this.vacationEvents)
                .concat(this.ticketEvents)
                .concat(this.taskEvents) as EventInput[],
        ];
        this.loadingCalendar = false;
        this.ref.markForCheck();
    }

    private loadAscApiData(): void {
        this.acsUserLogs = [];

        if (this.user.ascApiUser_data) {
            this.acsApiState = true;
            this.acsService
                .getAcsLogs(
                    this.user.ascApiUser_data.id as number,
                    moment()
                        .format('M'),
                    moment()
                        .year(),
                )
                .pipe(untilDestroyed(this))
                .subscribe({
                    next: (logs: AcsUserLogs[]) => {
                        this.acsUserLogs = logs.sort((a, b) => {
                            const dateA = a.started_at
                                ? moment(a.started_at)
                                    .valueOf()
                                : moment(a.started_at_real)
                                    .valueOf();
                            const dateB = b.started_at
                                ? moment(b.started_at)
                                    .valueOf()
                                : moment(b.started_at_real)
                                    .valueOf();

                            return dateA > dateB ? -1 : 1;
                        });

                        this.acsService
                            .getAcsCategories()
                            .pipe(untilDestroyed(this))
                            .subscribe({
                                next: (categories: AcsCategory[]) => {
                                    this.acsUserLogs?.map((log: AcsUserLogs, index) => {
                                        if (typeof this.acsUserLogs === 'undefined') {
                                            return;
                                        }

                                        const category = categories.find(
                                            x => x.id === log.event_category_id,
                                        );

                                        if (typeof category !== 'undefined') {
                                            log.category = category;
                                        }

                                        this.acsUserLogs[index] = log;
                                    });

                                    this.user.acsApiAtWork = this.acsService.giveAcsState(
                                        this.acsUserLogs,
                                    );
                                    this.loadingAcsApi = false;
                                    this.ref.markForCheck();
                                },
                                error: error => {
                                    console.error(error);

                                    const body = 'API Acs';
                                    const options = {
                                        progressBar: true,
                                        timeOut: 5000,
                                        toastClass: 'red',
                                    };

                                    this.messages.showError(
                                        'nepodařilo se načíst data...',
                                        body,
                                        options,
                                    );
                                    this.loadingAcsApi = false;
                                    this.ref.markForCheck();
                                },
                            });
                    },
                    error: error => {
                        console.error(error);

                        const body = 'API Acs';
                        const options = {
                            progressBar: true,
                            timeOut: 5000,
                            toastClass: 'red',
                        };

                        this.messages.showError('nepodařilo se načíst data...', body, options);
                        this.loadingAcsApi = false;
                        this.ref.markForCheck();
                    },
                });
        } else {
            this.loadingAcsApi = false;
            this.acsApiState = false;
            this.ref.markForCheck();
        }
    }

    private loadCarsApiData(): void {
        if (this.user.carsApiUser_data) {
            this.carsApiState = true;
            this.loadingCarsApi = false;
            this.ref.markForCheck();
        } else {
            this.carsApiState = false;
            this.loadingCarsApi = false;
            this.ref.markForCheck();
        }
    }

    private loadCrmApiData(): void {
        if (this.user.crmApiUser_data) {
            this.crmApiState = true;
            this.loadingCrmApi = false;
            this.ref.markForCheck();
        } else {
            this.crmApiState = false;
            this.loadingCrmApi = false;
            this.ref.markForCheck();
        }
    }

    private loadMntApiData(): void {
        if (this.user.mntApiUser_data) {
            this.mntApiState = true;
            this.loadingMntApi = false;
            this.ref.markForCheck();
        } else {
            this.mntApiState = false;
            this.loadingMntApi = false;
            this.ref.markForCheck();
        }
    }

    private loadErpApiData(): void {
        if (this.user.erpApiUser_data) {
            this.erpApiState = true;
            this.loadingErpApi = false;
            this.ref.markForCheck();
        } else {
            this.erpApiState = false;
            this.loadingErpApi = false;
            this.ref.markForCheck();
        }
    }

    // eslint-disable-next-line max-lines-per-function
    private loadChart(): void {
        this.usedVacDays = 0;
        this.usedSickDays = 0;

        const chartVacData: number[] = [];
        const chartSickData: number[] = [];

        chartVacData[0] = 0;
        chartVacData[1] = 0;
        chartVacData[2] = 0;
        chartVacData[3] = 0;
        chartVacData[4] = 0;
        chartVacData[5] = 0;
        chartVacData[6] = 0;
        chartVacData[7] = 0;
        chartVacData[8] = 0;
        chartVacData[9] = 0;
        chartVacData[10] = 0;
        chartVacData[11] = 0;
        chartSickData[0] = 0;
        chartSickData[1] = 0;
        chartSickData[2] = 0;
        chartSickData[3] = 0;
        chartSickData[4] = 0;
        chartSickData[5] = 0;
        chartSickData[6] = 0;
        chartSickData[7] = 0;
        chartSickData[8] = 0;
        chartSickData[9] = 0;
        chartSickData[10] = 0;
        chartSickData[11] = 0;
        // eslint-disable-next-line complexity
        this.vacations?.map((vacations: Vacation) => {
            if (!vacations.deleted_date && !vacations.decline_at) {
                if (
                    vacations.category_id === 1 &&
                    moment(vacations.start_at)
                        .year()
                        .toString() === moment()
                        .year()
                        .toString()
                ) {
                    this.usedVacDays += vacations.workdays;

                    if (parseInt(moment(vacations.start_at)
                        .format('MM'), 10) === 1) {
                        chartVacData[0] = chartVacData[0] + vacations.workdays;
                    }

                    if (parseInt(moment(vacations.start_at)
                        .format('MM'), 10) === 2) {
                        chartVacData[1] = chartVacData[1] + vacations.workdays;
                    }

                    if (parseInt(moment(vacations.start_at)
                        .format('MM'), 10) === 3) {
                        chartVacData[2] = chartVacData[2] + vacations.workdays;
                    }

                    if (parseInt(moment(vacations.start_at)
                        .format('MM'), 10) === 4) {
                        chartVacData[3] = chartVacData[3] + vacations.workdays;
                    }

                    if (parseInt(moment(vacations.start_at)
                        .format('MM'), 10) === 5) {
                        chartVacData[4] = chartVacData[4] + vacations.workdays;
                    }

                    if (parseInt(moment(vacations.start_at)
                        .format('MM'), 10) === 6) {
                        chartVacData[5] = chartVacData[5] + vacations.workdays;
                    }

                    if (parseInt(moment(vacations.start_at)
                        .format('MM'), 10) === 7) {
                        chartVacData[6] = chartVacData[6] + vacations.workdays;
                    }

                    if (parseInt(moment(vacations.start_at)
                        .format('MM'), 10) === 8) {
                        chartVacData[7] = chartVacData[7] + vacations.workdays;
                    }

                    if (parseInt(moment(vacations.start_at)
                        .format('MM'), 10) === 9) {
                        chartVacData[8] = chartVacData[8] + vacations.workdays;
                    }

                    if (parseInt(moment(vacations.start_at)
                        .format('MM'), 10) === 10) {
                        chartVacData[9] = chartVacData[9] + vacations.workdays;
                    }

                    if (parseInt(moment(vacations.start_at)
                        .format('MM'), 10) === 11) {
                        chartVacData[10] = chartVacData[10] + vacations.workdays;
                    }

                    if (parseInt(moment(vacations.start_at)
                        .format('MM'), 10) === 12) {
                        chartVacData[11] = chartVacData[11] + vacations.workdays;
                    }
                }

                if (
                    vacations.confirm &&
                    vacations.category_id === 5 &&
                    moment(vacations.start_at)
                        .year()
                        .toString() === moment()
                        .year()
                        .toString()
                ) {
                    this.usedSickDays += vacations.workdays;

                    if (parseInt(moment(vacations.start_at)
                        .format('MM'), 10) === 1) {
                        chartSickData[0] = chartSickData[0] + vacations.workdays;
                    }

                    if (parseInt(moment(vacations.start_at)
                        .format('MM'), 10) === 2) {
                        chartSickData[1] = chartSickData[1] + vacations.workdays;
                    }

                    if (parseInt(moment(vacations.start_at)
                        .format('MM'), 10) === 3) {
                        chartSickData[2] = chartSickData[2] + vacations.workdays;
                    }

                    if (parseInt(moment(vacations.start_at)
                        .format('MM'), 10) === 4) {
                        chartSickData[3] = chartSickData[3] + vacations.workdays;
                    }

                    if (parseInt(moment(vacations.start_at)
                        .format('MM'), 10) === 5) {
                        chartSickData[4] = chartSickData[4] + vacations.workdays;
                    }

                    if (parseInt(moment(vacations.start_at)
                        .format('MM'), 10) === 6) {
                        chartSickData[5] = chartSickData[5] + vacations.workdays;
                    }

                    if (parseInt(moment(vacations.start_at)
                        .format('MM'), 10) === 7) {
                        chartSickData[6] = chartSickData[6] + vacations.workdays;
                    }

                    if (parseInt(moment(vacations.start_at)
                        .format('MM'), 10) === 8) {
                        chartSickData[7] = chartSickData[7] + vacations.workdays;
                    }

                    if (parseInt(moment(vacations.start_at)
                        .format('MM'), 10) === 9) {
                        chartSickData[8] = chartSickData[8] + vacations.workdays;
                    }

                    if (parseInt(moment(vacations.start_at)
                        .format('MM'), 10) === 10) {
                        chartSickData[9] = chartSickData[9] + vacations.workdays;
                    }

                    if (parseInt(moment(vacations.start_at)
                        .format('MM'), 10) === 11) {
                        chartSickData[10] = chartSickData[10] + vacations.workdays;
                    }

                    if (parseInt(moment(vacations.start_at)
                        .format('MM'), 10) === 12) {
                        chartSickData[11] = chartSickData[11] + vacations.workdays;
                    }
                }
            }
        });

        this.chartVacData = [
            {
                month: 'Leden',
                days: chartVacData[0],
                text: `Leden: ${chartVacData[0]}`,
            },
            {
                month: 'Únor',
                days: chartVacData[1],
                text: `Únor: ${chartVacData[1]}`,
            },
            {
                month: 'Březen',
                days: chartVacData[2],
                text: `Březen: ${chartVacData[2]}`,
            },
            {
                month: 'Duben',
                days: chartVacData[3],
                text: `Duben: ${chartVacData[3]}`,
            },
            {
                month: 'Květen',
                days: chartVacData[4],
                text: `Květen: ${chartVacData[4]}`,
            },
            {
                month: 'Červen',
                days: chartVacData[5],
                text: `Červen: ${chartVacData[5]}`,
            },
            {
                month: 'Červenec',
                days: chartVacData[6],
                text: `Červenec: ${chartVacData[6]}`,
            },
            {
                month: 'Srpen',
                days: chartVacData[7],
                text: `Srpen: ${chartVacData[7]}`,
            },
            {
                month: 'Září',
                days: chartVacData[8],
                text: `Září: ${chartVacData[8]}`,
            },
            {
                month: 'Říjen',
                days: chartVacData[9],
                text: `Říjen: ${chartVacData[9]}`,
            },
            {
                month: 'Listopad',
                days: chartVacData[10],
                text: `Listopad: ${chartVacData[10]}`,
            },
            {
                month: 'Prosinec',
                days: chartVacData[11],
                text: `Prosinec: ${chartVacData[11]}`,
            },
        ];

        this.chartSickData = [
            {
                month: 'Leden',
                days: chartSickData[0],
                text: `Leden: ${chartSickData[0]}`,
            },
            {
                month: 'Únor',
                days: chartSickData[1],
                text: `Únor: ${chartSickData[1]}`,
            },
            {
                month: 'Březen',
                days: chartSickData[2],
                text: `Březen: ${chartSickData[2]}`,
            },
            {
                month: 'Duben',
                days: chartSickData[3],
                text: `Duben: ${chartSickData[3]}`,
            },
            {
                month: 'Květen',
                days: chartSickData[4],
                text: `Květen: ${chartSickData[4]}`,
            },
            {
                month: 'Červen',
                days: chartSickData[5],
                text: `Červen: ${chartSickData[5]}`,
            },
            {
                month: 'Červenec',
                days: chartSickData[6],
                text: `Červenec: ${chartSickData[6]}`,
            },
            {
                month: 'Srpen',
                days: chartSickData[7],
                text: `Srpen: ${chartSickData[7]}`,
            },
            {
                month: 'Září',
                days: chartSickData[8],
                text: `Září: ${chartSickData[8]}`,
            },
            {
                month: 'Říjen',
                days: chartSickData[9],
                text: `Říjen: ${chartSickData[9]}`,
            },
            {
                month: 'Listopad',
                days: chartSickData[10],
                text: `Listopad: ${chartSickData[10]}`,
            },
            {
                month: 'Prosinec',
                days: chartSickData[11],
                text: `Prosinec: ${chartSickData[11]}`,
            },
        ];
    }

    private createUsertoAscApi(): void {
        /* NO EMPTY TODO*/
    }

    private createUsertoCrmApi(): void {
        /* NO EMPTY TODO*/
    }

    private createUsertoMntApi(): void {
        /* NO EMPTY TODO*/
    }

    private createUsertoErpApi(): void {
        /* NO EMPTY TODO*/
    }

    private createUsertoCarsApi(): void {
        /* NO EMPTY TODO*/
    }

    ngOnInit(): void {
        this.primaryXAxis = {
            valueType: 'Category',
            edgeLabelPlacement: 'Shift',
            isInversed: true,
        };

        this.primaryYAxis = {
            minimum: 0,
            maximum: 31,
            labelFormat: '{value} dní',
            rangePadding: 'None',
        };

        this.legendSettings = {
            visible: true,
        };

        this.tooltip = {
            enable: true,
            header: 'Čerpáno dní celkem',
        };
        this.datalabel = {visible: true};
        this.navHandlerService.navState.pipe(untilDestroyed(this))
            .subscribe({
                next: () => {
                    if (typeof this.vacationsChart !== 'undefined') {
                        this.vacationsChart.chartResize();
                    }
                },
                error: error => {
                    console.error(error);
                },
            });

        // LOCAL DATA
        this.vacationService
            .getActiveCategories()
            .pipe(untilDestroyed(this))
            .subscribe({
                next: (categories: VacationCategory[]) => {
                    this.categories = categories;

                    if (typeof this.categories === 'undefined') {
                        console.error('undefined value');

                        return;
                    }

                    if (this.categories.length > 0) {
                        this.loadData();
                    }
                },
                error: error => {
                    console.error(error);
                },
            });

        this.signatureService.userHtmlSignaturesSource.pipe(untilDestroyed(this))
            .subscribe({
                next: (signatures: HtmlSignatures[]) => {
                    this.htmlSignatures = [];

                    if (typeof signatures === 'undefined') {
                        console.error('undefined value');

                        return;
                    }

                    this.htmlSignatures = signatures;
                    this.htmlSignatures = this.htmlSignatures.sort((a, b) => {
                        const compA = a.company_id;
                        const compB = b.company_id;

                        return compA > compB ? 1 : -1;
                    });

                    this.htmlSignatures.forEach((signature: HtmlSignatures, index) => {
                        this.htmlSignatures[index].html_stringHTML =
                            this.sanitizer.bypassSecurityTrustHtml(
                                // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                                JSON.parse(signature.html_string),
                            );
                    });
                    this.ref.markForCheck();
                },
                error: error => {
                    console.error(error);
                },
            });

        this.dataService.apiSource.pipe(untilDestroyed(this))
            .subscribe({
                next: (apis: {
                    acs: ApiConnections | boolean;
                    crm: ApiConnections | boolean;
                    erp: ApiConnections | boolean;
                    siteMonitoring: ApiConnections | boolean;
                    cars: ApiConnections | boolean;
                }) => {
                    this.apis = apis;
                    this.ref.markForCheck();
                },
                error: error => {
                    console.error(error);
                },
            });

        this.dataService.userDetailSource.pipe(untilDestroyed(this))
            .subscribe({
                next: (user: User | undefined) => {
                    if (user) {
                        this.user = user;
                        this.loadingUser = false;
                        this.dataService.ticketSource.pipe(untilDestroyed(this))
                            .subscribe(
                                (tickets: Tickets[]) => {
                                    this.tickets = tickets;

                                    if (typeof this.tickets !== 'undefined' && this.tickets.length > 0) {
                                        this.loadData();
                                    }
                                },
                                error => {
                                    console.error(error);
                                },
                            );

                        this.dataService.taskSource.pipe(untilDestroyed(this))
                            .subscribe(
                                (tasks: Task[]) => {
                                    this.tasks = tasks;

                                    if (typeof this.tasks !== 'undefined' && this.tasks.length > 0) {
                                        this.loadData();
                                    }
                                },
                                error => {
                                    console.error(error);
                                },
                            );

                        // REMOTE DATA
                        this.loadAscApiData();
                        this.loadCarsApiData();
                        this.loadCrmApiData();
                        this.loadErpApiData();
                        this.loadMntApiData();
                    }
                },
                error: error => {
                    console.error(error);
                },
            });

        this.dataService.vacationPerUserSource.pipe(untilDestroyed(this))
            .subscribe({
                next: (vacations: Vacation[] | undefined) => {
                    this.vacations = vacations;

                    if (this.vacations && this.vacations.length > 0) {
                        this.loadData();
                        this.loadChart();
                    }
                },
                error: error => {
                    console.error(error);
                },
            });

        this.route.params.pipe(
            distinctUntilChanged(),
            untilDestroyed(this),
        )
            .subscribe((params: Params) => {
                this.dataService.clearVacationsPerUserCache();
                this.dataService.setUserDetailSource(parseInt(params.id as string, 10));
                this.dataService.setVacationsPerUserDataSource(parseInt(params.id as string, 10));
                this.signatureService.setSignaturesUserSource(parseInt(params.id as string, 10));
            });

        // PSWD WARN
        if (
            this.currentUser?.password === '54460754b2d445cae91ea24363cc1085b40b95be' &&
            this.user.id === this.currentUser.id
        ) {
            this.passwordWarningDialog.show();
        }

        // DATA SETTERS
        this.dataService.setTasksDataSource();
        this.dataService.setTicketsDataSource();
    }

    onFileChanged(event: unknown): void {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        this.selectedFile = event.target.files[0] as File;
    }

    editUser(): void {
        if (
            this.currentUser &&
            (this.permissionService.checkUserISAdministrative(this.currentUser) ||
                this.user.boss_id === this.currentUser.id ||
                this.user.id === this.currentUser.id)
        ) {
            this.isCopy = false;
            this.isCreate = false;
            this.isUpdate = true;
            this.renderUserFormDialog = true;
            this.ref.markForCheck();
        } else {
            const body = 'Nemáte oprávnění provést tuto akci...';
            const options = {progressBar: true, timeOut: 5000};

            this.messages.showError('Nedostatečné oprávnění', body, options);
            this.loadingUser = false;
            this.ref.markForCheck();
        }
    }

    changePassword(): void {
        if (this.currentUser?.id === this.user.id) {
            this.renderChangeUserPassword = true;
            this.ref.markForCheck();
        } else {
            const body = 'heslo patří jinému uživateli';
            const options = {progressBar: true, timeOut: 5000, toastClass: 'red'};

            this.messages.showError('Toto heslo nelze změnit', body, options);
            this.loadingUser = false;
            this.ref.markForCheck();
        }
    }

    deAuthorize(): void {
        if (
            this.currentUser &&
            (this.permissionService.checkUserISAdministrative(this.currentUser) ||
                this.user.boss_id === this.currentUser.id)
        ) {
            this.loadingUser = true;
            this.user.deleted_by = this.currentUser.id;
            this.userService
                .deleteUser(this.user)
                ?.pipe(untilDestroyed(this))
                .subscribe(
                    (user: User) => {
                        const body = 'uživatele: #' + user.fullname;
                        const options = {
                            progressBar: true,
                            timeOut: 5000,
                            toastClass: 'success',
                        };

                        this.user.authorized = false;
                        this.messages.showSuccess('Úspěšná deuatorizace', body, options);
                        this.dataService.setUserDetailSource(this.user.id);
                        this.confirmationDelete.hide();
                        this.ref.markForCheck();
                    },
                    error => {
                        console.error(error);
                        this.loadingUser = false;
                        this.confirmationDelete.hide();
                        this.ref.markForCheck();
                    },
                );
        } else {
            const body = 'Nemáte oprávnění provést tuto akci...';
            const options = {progressBar: true, timeOut: 5000};

            this.messages.showError('Nedostatečné oprávnění', body, options);
            this.loadingUser = false;
            this.ref.markForCheck();
        }
    }

    reAuthorize(): void {
        if (
            this.currentUser &&
            (
                this.permissionService.checkUserISAdministrative(this.currentUser) ||
                this.permissionService.checkUserISLeader(this.user)
                    .leaderDepartments
                    .find(
                        departmentId => this.user.assignations?.find(
                            assignations => assignations.department_id === departmentId,
                        ),
                    ) ||
                this.currentUser.id === this.user.boss_id
            )
        ) {
            this.loadingUser = true;
            this.user.password = 'j0n2019System';
            this.userService
                .updateUser(this.user as UserInput, true)
                ?.pipe(untilDestroyed(this))
                .subscribe({
                    next: (user: User) => {
                        const body = `uživatele: #${user.id}`;
                        const options = {progressBar: true, timeOut: 5000};

                        this.messages.showSuccess('Úspěšná úprava', body, options);
                        this.dataService.setUserDetailSource(this.user.id);
                        this.confirmationAuthorized.hide();
                        this.ref.markForCheck();
                    },
                    error: error => {
                        console.error(error);
                        this.loadingUser = false;
                        this.confirmationAuthorized.hide();
                        this.ref.markForCheck();
                    },
                });
        } else {
            const body = 'Nemáte oprávnění provést tuto akci...';
            const options = {progressBar: true, timeOut: 5000};

            this.messages.showError('Nedostatečné oprávnění', body, options);
            this.loadingUser = false;
            this.confirmationAuthorized.hide();
            this.ref.markForCheck();
        }
    }

    resetPassword(): void {
        if (
            this.currentUser &&
            (
                this.permissionService.checkUserISAdministrative(this.currentUser) ||
                this.permissionService.checkUserISLeader(this.user)
                    .leaderDepartments
                    .find(
                        departmentId => this.user.assignations?.find(
                            assignations => assignations.department_id === departmentId,
                        ),
                    ) ||
                this.currentUser.id === this.user.boss_id
            )
        ) {
            this.loadingUser = true;
            this.user.password = 'j0n2019System';
            this.userService
                .updateUser(this.user as UserInput)
                ?.pipe(untilDestroyed(this))
                .subscribe({
                    next: (user: User) => {
                        const body = `uživatele: #${user.fullname}`;
                        const options = {
                            progressBar: true,
                            timeOut: 5000,
                            toastClass: 'info',
                        };

                        this.messages.showSuccess('Úspěšný reset hesla', body, options);
                        this.dataService.setUserDetailSource(this.user.id);
                        this.confirmationReset.hide();
                        this.ref.markForCheck();
                    },
                    error: error => {
                        console.error(error);
                        this.loadingUser = false;
                        this.confirmationReset.hide();
                        this.ref.markForCheck();
                    },
                });
        } else {
            const body = 'Nemáte oprávnění provést tuto akci...';
            const options = {progressBar: true, timeOut: 5000};

            this.messages.showError('Nedostatečné oprávnění', body, options);
            this.loadingUser = false;
            this.confirmationReset.hide();
            this.ref.markForCheck();
        }
    }

    syncUserToAcsApi(): void {
        this.loadingAcsApi = true;

        if (
            this.currentUser &&
            this.permissionService.checkUserISAdministrative(this.currentUser)
        ) {
            this.loadingUser = true;
            this.userService
                .updateUser(this.user as UserInput)
                ?.pipe(untilDestroyed(this))
                .subscribe({
                    next: (user: User) => {
                        const body = `uživatele: #${user.id}`;
                        const options = {progressBar: true, timeOut: 5000};

                        this.messages.showSuccess(
                            'Úspěšná synchronizace do ACS api',
                            body,
                            options,
                        );
                        this.dataService.setUserDetailSource(this.user.id);
                        this.confirmationSyncToAcsAPI.hide();
                        this.ref.markForCheck();
                    },
                    error: error => {
                        console.error(error);
                        this.loadingUser = false;
                        this.confirmationSyncToAcsAPI.hide();
                        this.ref.markForCheck();
                    },
                });
        } else {
            const body = 'Nemáte oprávnění provést tuto akci...';
            const options = {progressBar: true, timeOut: 5000};

            this.messages.showError('Nedostatečné oprávnění', body, options);
            this.loadingUser = false;
            this.confirmationSyncToAcsAPI.hide();
            this.ref.markForCheck();
        }
    }

    syncUserToMntApi(): void {
        this.loadingMntApi = true;

        if (
            this.currentUser &&
            this.permissionService.checkUserISAdministrative(this.currentUser)
        ) {
            this.loadingMntApi = true;
            this.confirmationSyncToMtnAPI.hide();
            this.ref.markForCheck();
        } else {
            const body = 'Nemáte oprávnění provést tuto akci...';
            const options = {progressBar: true, timeOut: 5000};

            this.messages.showError('Nedostatečné oprávnění', body, options);
            this.loadingMntApi = false;
            this.confirmationSyncToMtnAPI.hide();
            this.ref.markForCheck();
        }
    }

    syncUserToCrmApi(): void {
        this.loadingCrmApi = true;

        if (
            this.currentUser &&
            this.permissionService.checkUserISAdministrative(this.currentUser)
        ) {
            this.loadingCrmApi = true;
            this.confirmationSyncToCrmAPI.hide();
            this.ref.markForCheck();
        } else {
            const body = 'Nemáte oprávnění provést tuto akci...';
            const options = {progressBar: true, timeOut: 5000};

            this.messages.showError('Nedostatečné oprávnění', body, options);
            this.loadingCrmApi = false;
            this.confirmationSyncToCrmAPI.hide();
            this.ref.markForCheck();
        }
    }

    syncUserToErpApi(): void {
        this.loadingErpApi = true;

        if (
            this.currentUser &&
            this.permissionService.checkUserISAdministrative(this.currentUser)
        ) {
            this.loadingErpApi = true;
            this.confirmationSyncToErpAPI.hide();
            this.ref.markForCheck();
        } else {
            const body = 'Nemáte oprávnění provést tuto akci...';
            const options = {progressBar: true, timeOut: 5000};

            this.messages.showError('Nedostatečné oprávnění', body, options);
            this.loadingErpApi = false;
            this.confirmationSyncToErpAPI.hide();
            this.ref.markForCheck();
        }
    }

    syncUserToCarsApi(): void {
        this.loadingErpApi = true;

        if (
            this.currentUser &&
            this.permissionService.checkUserISAdministrative(this.currentUser)
        ) {
            this.loadingCarsApi = true;
            this.confirmationSyncToCarsAPI.hide();
            this.ref.markForCheck();
        } else {
            const body = 'Nemáte oprávnění provést tuto akci...';
            const options = {progressBar: true, timeOut: 5000};

            this.messages.showError('Nedostatečné oprávnění', body, options);
            this.loadingCarsApi = false;
            this.confirmationSyncToCarsAPI.hide();
            this.ref.markForCheck();
        }
    }

    createUserToAcsApi(): void {
        if (
            this.currentUser &&
            this.permissionService.checkUserISAdministrative(this.currentUser)
        ) {
            this.loadingAcsApi = true;

            // eslint-disable-next-line max-len
            const queryString = `?attributes[allow_mobile_access]=false&attributes[allow_own_web_overview]=false&attributes[custom_link]=https://system.jon.cz/users/detail/${this.user.id}&attributes[email]=${this.user.workemail}&attributes[first_name]=${this.user.firstname}&attributes[last_name]=${this.user.secondname}&attributes[personal_number]=${this.user.id}&attributes[role]=zaměstnanec&attributes[use_pin]=false&attributes[pin]=0&attributes[group_id]=1`;

            this.apisService
                .getRemoteData(36, [], false, queryString)
                .pipe(untilDestroyed(this))
                .subscribe({
                    next: () => {
                        const body = 'Úspěšně vytvořen v docházkovém systému';
                        const options = {
                            progressBar: true,
                            timeOut: 5000,
                            toastClass: 'success',
                        };

                        this.messages.showSuccess(
                            'uživatel: ' + this.user.firstname + '' + this.user.secondname,
                            body,
                            options,
                        );
                        this.dataService.setUserDetailSource(this.user.id);
                        this.loadingAcsApi = false;
                        this.confirmationCreateToAcsAPI.hide();
                        this.ref.markForCheck();
                    },
                    error: error => {
                        console.error(error);
                        this.loadingUser = false;
                        this.confirmationCreateToAcsAPI.hide();
                        this.ref.markForCheck();
                    },
                });
        } else {
            const body = 'Nemáte oprávnění provést tuto akci...';
            const options = {progressBar: true, timeOut: 5000};

            this.messages.showError('Nedostatečné oprávnění', body, options);
            this.loadingUser = false;
            this.confirmationCreateToAcsAPI.hide();
            this.ref.markForCheck();
        }
    }

    createUserToCarsApi(): void {
        if (
            this.currentUser &&
            this.permissionService.checkUserISAdministrative(this.currentUser)
        ) {
            this.loadingCarsApi = true;
            this.confirmationCreateToCarsAPI.hide();
            this.ref.markForCheck();
        } else {
            const body = 'Nemáte oprávnění provést tuto akci...';
            const options = {progressBar: true, timeOut: 5000};

            this.messages.showError('Nedostatečné oprávnění', body, options);
            this.loadingCarsApi = false;
            this.confirmationCreateToCarsAPI.hide();
            this.ref.markForCheck();
        }
    }

    createUserToMntApi(): void {
        if (
            this.currentUser &&
            this.permissionService.checkUserISAdministrative(this.currentUser)
        ) {
            this.loadingMntApi = true;
            this.confirmationCreateToMtnAPI.hide();
            this.ref.markForCheck();
        } else {
            const body = 'Nemáte oprávnění provést tuto akci...';
            const options = {progressBar: true, timeOut: 5000};

            this.messages.showError('Nedostatečné oprávnění', body, options);
            this.loadingMntApi = false;
            this.confirmationCreateToMtnAPI.hide();
            this.ref.markForCheck();
        }
    }

    createUserToCrmApi(): void {
        if (
            this.currentUser &&
            this.permissionService.checkUserISAdministrative(this.currentUser)
        ) {
            this.loadingCrmApi = true;
            this.confirmationCreateToCrmAPI.hide();
            this.ref.markForCheck();
        } else {
            const body = 'Nemáte oprávnění provést tuto akci...';
            const options = {progressBar: true, timeOut: 5000};

            this.messages.showError('Nedostatečné oprávnění', body, options);
            this.loadingCrmApi = false;
            this.confirmationCreateToCrmAPI.hide();
            this.ref.markForCheck();
        }
    }

    createUserToErpApi(): void {
        this.loadingErpApi = true;

        if (
            this.currentUser &&
            this.permissionService.checkUserISAdministrative(this.currentUser)
        ) {
            this.loadingErpApi = true;

            const data = {
                winstrom: {
                    uzivatel: {
                        kod: `${this.user.firstname}_${this.user.secondname}_${this.user.id}`,
                        jmeno: this.user.firstname,
                        prijmeni: this.user.secondname,
                        role: 'code:UZIVATEL',
                        ulice: '',
                        mesto: '',
                        psc: '',
                        titul: '',
                        titulZa: '',
                        funkce: this.user.official_position_name,
                        tel: this.user.telnumber,
                        mobil: this.user.telnumber,
                        fax: '',
                        email: this.user.workemail,
                        www: 'www.jon.cz',
                        poznam: 'Uživatel vytvořen pomocí synchronizace z JONSystemu',
                        popis: '',
                        platiOd: '0',
                        platiDo: '9999',
                        password: 'erpapijonsystem',
                        passwordAgain: 'erpapijonsystem',
                    },
                },
            };

            this.apisService
                .getRemoteData(31, [], data)
                .pipe(untilDestroyed(this))
                .subscribe({
                    next: () => {
                        const body = 'Úspěšně vytvořen v systému plánování zdrojů';
                        const options = {
                            progressBar: true,
                            timeOut: 5000,
                            toastClass: 'success',
                        };

                        this.messages.showSuccess(
                            'uživatel: ' + this.user.firstname + '' + this.user.secondname,
                            body,
                            options,
                        );
                        this.dataService.setUserDetailSource(this.user.id);
                        this.loadingErpApi = false;
                        this.confirmationCreateToErpAPI.hide();
                        this.ref.markForCheck();
                    },
                    error: error => {
                        console.error(error);
                        this.confirmationCreateToErpAPI.hide();
                        this.ref.markForCheck();
                    },
                });
        } else {
            const body = 'Nemáte oprávnění provést tuto akci...';
            const options = {progressBar: true, timeOut: 5000};

            this.messages.showError('Nedostatečné oprávnění', body, options);
            this.loadingErpApi = false;
            this.confirmationCreateToErpAPI.hide();
            this.ref.markForCheck();
        }
    }

    copyText(signatureId: number): void {
        const signature = this.htmlSignatures.find(data => data.id === signatureId);
        const selBox = document.createElement('textarea');

        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        selBox.value = signature?.html_string ? JSON.parse(signature.html_string) : '';
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);
    }

    navigateToEvent(arg): void {
        if (arg) {
            if (arg.event.extendedProps.ticketData) {
                void this.router.navigate([
                    '/tickets/detail',
                    arg.event.extendedProps.ticketData.id,
                ]);
            }

            if (arg.event.extendedProps.taskData) {
                void this.router.navigate(['/tasks/detail', arg.event.extendedProps.taskData.id]);
            }

            if (arg.event.extendedProps.vacationData) {
                void this.router.navigate([
                    '/vacation/detail',
                    arg.event.extendedProps.vacationData.id,
                ]);
            }

            if (arg.event.extendedProps.event_data) {
                void this.router.navigate(['/event/detail', arg.event.extendedProps.event_data.id]);
            }
        } else {
            const body = 'Data odkazu nejsou k dispozici...';
            const options = {progressBar: true, timeOut: 5000, toastClass: 'red'};

            this.messages.showError('Odkaz nelze otevřít', body, options);
        }
    }
}
