import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import * as crypto from 'crypto-js';
import {User} from '@src/app/_models/user/user';
import {catchError, shareReplay, tap} from 'rxjs/operators';
import {MessageService} from '@src/app/_services/message.service';
import {Department} from '@src/app/_models/department/department';
import {Role} from '@src/app/_models/role/role';
import {DataService} from '@src/app/_services/data.service';
import {ApiConnections} from '@src/app/_models/api/connections';
import {AcsService} from '@src/app/_api/acs/acs.service';
import {CarsService} from '@src/app/_api/cars/cars.service';
import {CrmService} from '@src/app/_api/crm/crm.service';
import {ErpService} from '@src/app/_api/erp/erp.service';
import {MonitoringService} from '@src/app/_api/monitoring/monitoring.service';
import {ApisService} from '@src/app/_api/apis.service';
import {Assignation} from '@src/app/_models/assignation/assignation';
import {EnvironmentService} from '@src/app/_services/environment.service';
import {DateTime, Interval} from 'luxon';

const httpOptions = {
    headers: new HttpHeaders({'Content-Type': 'application/json'}),
};

export interface AssignInput {
    id: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    user_id: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    department_id: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    role_id: number;
}

export interface UserInput {
    id?: number;
    firstname?: string;
    secondname?: string;
    password?: string;
    workemail?: string;
    telnumber?: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    vacation_fond?: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    sickdays_fond?: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    official_position_name?: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    boss_id?: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    employer_id?: number;
    assignations?: Assignation[];
    authorized?: boolean;
    emailverify?: boolean;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    created_by?: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    updated_by?: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    deleted_date?: null;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    deleted_by?: null;
}

export interface UserGridItems {
    id: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    boss_id: number | undefined;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    employer_id: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    hotliner_id: number | undefined;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    created_by: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    user_str: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    user_img: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    creator_str: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    creator_img: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    boss_str: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    boss_img: string;
    authorized: boolean;
    emailverify: boolean;
    vacation: number;
    sickdays: number;
    position: string;
    assignations: string;
    workemail: string;
    telnumber: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    employer_str: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    updated_date: Date | null;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    created_date: Date;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    deleted_date: Date | null;
}

@Injectable({
    providedIn: 'root',
})
export class UsersService {
    private readonly usersUrl: string;

    private readonly usersAssignationUrl: string;

    private readonly usersUniqueUrl: string;

    user: Observable<User>;

    users: Observable<User[]>;

    activeUsers: Observable<User[]>;

    constructor(
        private readonly http: HttpClient,
        private readonly dataService: DataService,
        private readonly messageService: MessageService,
        private readonly apisService: ApisService,
        private readonly acsService: AcsService,
        private readonly carsService: CarsService,
        private readonly crmService: CrmService,
        private readonly erpService: ErpService,
        private readonly monitoringService: MonitoringService,
        private readonly environmentService: EnvironmentService,
    ) {
        this.usersUrl = this.environmentService.backendURL + '/api/user';
        this.usersAssignationUrl = this.environmentService.backendURL + '/api/role/membership';
        this.usersUniqueUrl = this.environmentService.backendURL + '/api/user/existunique';
    }

    /**
     * Handle Http operation that failed.
     * Let the app continue.
     * @param operation - name of the operation that failed
     * @param result - optional value to return as the observable result
     */
    private handleError<T>(operation = 'operation', result?: T) {
        return (error: {[key: string]: string}): Observable<T | undefined> => {
            console.error(error);
            this.log(`${operation} failed: ${error.message}`);

            return of(result);
        };
    }

    /** Log a userService message with the MessageService */
    private log(message: string): void {
        this.messageService.addNotification(`User: ${message}`, false);
    }

    addUser(user: UserInput): Observable<User> {
        let params = new HttpParams();

        if (user.password) {
            const pswdPlain = user.password;

            params = params.set('passwordPlain', pswdPlain.toString()); // TODO security
        }

        const headers = new HttpHeaders({'Content-Type': 'application/json'});

        user.password = crypto.SHA1(user.password)
            .toString(crypto.enc.Hex);
        this.dataService.clearUserCache();

        return this.http.post<User>(this.usersUrl, user, {headers, params});
    }

    deleteUser(user: User): Observable<User> | undefined {
        if (!user.deleted_by) {
            console.error('deleted_by is missing...');

            return;
        }

        const headers = new HttpHeaders({'Content-Type': 'application/json'});
        const params = new HttpParams().set('deleted_by', user.deleted_by.toString());
        const url = `${this.usersUrl}/${user.id}`;

        this.dataService.clearUserCache();

        return this.http.delete<User>(url, {headers, params});
    }

    updateUser(
        user: UserInput,
        reauthorize?: boolean,
        loop?: boolean,
        resettoken?: boolean,
    ): Observable<User | undefined> | undefined {
        if (!user.id) {
            console.error('ID is missing...');

            return;
        }

        const url = `${this.usersUrl}/update/${user.id}`;
        const headers = new HttpHeaders({'Content-Type': 'application/json'});
        const params = new HttpParams()
            .set('reauthorize', reauthorize ? 'true' : 'false')
            .set('resettoken', resettoken ? 'true' : 'false');

        if (!loop) {
            this.dataService.clearUserCache();
        }

        user.password = user.password ? crypto.SHA1(user.password)
            .toString(crypto.enc.Hex) : null;

        return this.http.put<User>(url, user, {headers, params})
            .pipe(
                tap(() => {
                    this.log(`updated users id=${user.id ?? 'null'}`);
                }),
                catchError(this.handleError<User>(`updateUser id=${user.id}`)),
            );
    }

    isEmailRegistered(email: string): Observable<User | boolean | object | undefined> {
        return this.http
            .post(this.usersUniqueUrl, JSON.stringify({workemail: email}), httpOptions)
            .pipe(
                tap(() => {
                    this.log(`email found workemail=${email}`);
                }),
                catchError(this.handleError<User>(`error find email=${email}`)),
                shareReplay(),
            );
    }

    isTelRegistered(telnumber: string): Observable<User | boolean | object | undefined> {
        return this.http.post(this.usersUniqueUrl, JSON.stringify({telnumber}), httpOptions)
            .pipe(
                tap(() => {
                    this.log(`telnumber found telnumber=${telnumber}`);
                }),
                catchError(this.handleError<User>(`error find telnumber=${telnumber}`)),
                shareReplay(),
            );
    }

    addAssignation(
        user: User | number,
        department: Department | number,
        role: Role | number,
    ): Observable<User> {
        const userId = typeof user === 'number' ? user : user.id;
        const departmentId = typeof department === 'number' ? department : department.id;
        const roleId = typeof role === 'number' ? role : role.id;

        return this.http.post<User>(
            this.usersAssignationUrl,
            {
                user_id: userId,
                department_id: departmentId,
                role_id: roleId,
            },
            httpOptions,
        );
    }

    setRemoteUserData(apis: ApiConnections[] | null | undefined): void {
        console.info('INFO: start settings remote user data..');

        if (apis) {
            apis.forEach((api: ApiConnections, index) => {
                if (api.active) {
                    this.apisService
                        .isAvailable(api)
                        .subscribe((state: ApiConnections | undefined) => {
                            apis[index].state = !!state;
                            apis[index].last_check = DateTime.now()
                                .toISO();

                            if (api.state && api.type.name === 'ACS') {
                                const path = api.paths.find(x => x.id === 1);

                                this.acsService.setAcsUsers(path);
                            }

                            if (api.state && api.type.name === 'MONITORING') {
                                const path = api.paths.find(x => x.id === 4);

                                this.monitoringService.setMonitoringUsers(path);
                            }

                            if (api.state && api.type.name === 'CRM') {
                                const path = api.paths.find(x => x.id === 21);

                                this.crmService.setCRMUsers(path);
                            }

                            if (api.state && api.type.name === 'ERP') {
                                const path = api.paths.find(x => x.id === 35);

                                this.erpService.setErpUsers(path);
                            }

                            if (api.state && api.type.name === 'CARS') {
                                const path = api.paths.find(x => x.id === 35);

                                this.carsService.setCarsUsers(path);
                            }
                        });
                }
            });
        } else {
            console.info('INFO: any stored apis for check..');
        }
    }

    checkUserStateByDate(first: DateTime, last: DateTime, user: User): boolean {
        const intervalOfExport = Interval.fromDateTimes(first, last);

        if (!user.deleted_date) {
            return true;
        }

        return (
            (intervalOfExport.contains(DateTime.fromISO(user.created_date)) ||
                intervalOfExport.isAfter(DateTime.fromISO(user.created_date))) &&
            (intervalOfExport.contains(DateTime.fromISO(user.deleted_date)) ||
                intervalOfExport.isBefore(DateTime.fromISO(user.deleted_date)))
        );
    }
}
