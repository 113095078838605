<ejs-dialog
  #deleteFileDialog
  [buttons]="buttonsDeleteFileDialog"
  [content]="'Opravdu chcete smazat tento logo z TV kanálu?'"
  [header]="'Smazat logo z TV kanálu'"
  [height]="'200px'"
  [isModal]="true"
  [showCloseIcon]="true"
  [visible]="false"
  [width]="'450px'"
  id="deleteFileDialog"
  target="#formDialog"
></ejs-dialog>
<ejs-dialog
  #formDialog
  (close)="
        isUpdate = false;
        isCreate = false;
        isDirty = false;
        isChecked = false;
        isCopy = false;
        submited = false;
        changeDialogState(false)
    "
  [isModal]="true"
  [showCloseIcon]="true"
  [visible]="isVisible"
  [width]="'85%'"
  id="formDialog"
>
  <form [formGroup]="tvChannelsForm">
    <ng-template #content>
      <div class="dialogContent">
        <div class="row">
          <div class="col-12 col-md-6 mb-3">
            <label
              [ngClass]="
                                f.name.valid && (f.name.dirty || f.name.touched)
                                    ? 'text-success'
                                    : f.name.invalid &&
                                      (f.name.dirty || f.name.touched)
                                    ? 'text-danger'
                                    : 'text-muted'
                            "
              for="name"
            >* Název programu:</label
            >
            <ejs-textbox
              [ngClass]="
                                (f.name.dirty || f.name.touched) &&
                                f.name.invalid
                                    ? 'e-error'
                                    : (f.name.dirty || f.name.touched) &&
                                      f.name.valid
                                    ? 'e-success'
                                    : ''
                            "
              floatLabelType="Auto"
              formControlName="name"
              id="name"
            ></ejs-textbox>
            <small
              *ngIf="
                                (f.name.dirty || f.name.touched) &&
                                f.name.errors &&
                                f.name.errors.required
                            "
              class="text-danger"
            >Název programu je povinný</small
            >
          </div>
          <div class="col-12 col-md-6 mb-3">
            <label
              [ngClass]="
                                f.theme_id.valid
                                    ? 'text-success'
                                    : f.theme_id.invalid &&
                                      (f.theme_id.dirty || f.theme_id.touched)
                                    ? 'text-danger'
                                    : 'text-muted'
                            "
              for="theme"
            >* Tématický okruh:</label
            >
            <ejs-dropdownlist
              #theme
              (filtering)="onFilteringThemes($event)"
              [allowFiltering]="true"
              [cssClass]="
                                (f.theme_id.dirty || f.theme_id.touched) &&
                                f.theme_id.invalid
                                    ? 'e-error'
                                    : (f.theme_id.dirty ||
                                          f.theme_id.touched) &&
                                      f.theme_id.valid
                                    ? 'e-success'
                                    : ''
                            "
              [dataSource]="themeSelect"
              [fields]="fields"
              [filterBarPlaceholder]="
                                'Vyhledat tématický okruh...'
                            "
              [ignoreAccent]="true"
              [placeholder]="'Vyberte tématický okruh'"
              [popupHeight]="height"
              formControlName="theme_id"
              id="theme"
              sortOrder="Ascending"
            ></ejs-dropdownlist>
            <small
              *ngIf="
                                (f.theme_id.dirty || f.theme_id.touched) &&
                                f.theme_id.errors &&
                                f.theme_id.errors.required
                            "
              class="text-danger"
            >Vyberte alespoň jeden tématický okruh</small
            >
          </div>
          <div class="col-12 col-md-6 mb-3">
            <ejs-checkbox
              [ngClass]="
                                (f.app_available.dirty ||
                                    f.app_available.touched) &&
                                f.app_available.invalid
                                    ? 'e-error'
                                    : (f.app_available.dirty ||
                                          f.app_available.touched) &&
                                      f.app_available.valid
                                    ? 'e-success'
                                    : ''
                            "
              cssClass="e-primary p-0 mr-3"
              formControlName="app_available"
              label="Dostupnost v aplikaci"
            >
            </ejs-checkbox>
            <small
              *ngIf="
                                (f.app_available.dirty ||
                                    f.app_available.touched) &&
                                f.app_available.errors &&
                                f.app_available.errors.required
                            "
              class="text-danger"
            >Vlastnost dostupnost v aplikaci je povinná</small
            >
          </div>
          <div class="col-12 col-md-6 mb-3">
            <ejs-checkbox
              [ngClass]="
                                (f.hd_resolution.dirty ||
                                    f.hd_resolution.touched) &&
                                f.hd_resolution.invalid
                                    ? 'e-error'
                                    : (f.hd_resolution.dirty ||
                                          f.hd_resolution.touched) &&
                                      f.hd_resolution.valid
                                    ? 'e-success'
                                    : ''
                            "
              cssClass="e-primary p-0 mr-3"
              formControlName="hd_resolution"
              label="HD rozlišení"
            >
            </ejs-checkbox>
            <small
              *ngIf="
                                (f.hd_resolution.dirty ||
                                    f.hd_resolution.touched) &&
                                f.hd_resolution.errors &&
                                f.hd_resolution.errors.required
                            "
              class="text-danger"
            >Vlastnost HD rozlišení je povinná</small
            >
          </div>
          <div class="col-12">
            <hr />
          </div>
          <div class="col-12 mb-3">
            <ejs-uploader
              #preloadupload
              (removing)="onFileRemove($event)"
              (selected)="onFileSelected($event)"
              (success)="onSuccessUpload($event)"
              [asyncSettings]="path"
              [autoUpload]="autoUpload"
              [dropArea]="dropElement"
              allowedExtensions=".jpg,.png,.jpeg"
              id="preloadfileupload"
              maxFileSize="5000000"
              multiple="false"
            >
              <e-files *ngIf="tvChannel && tvChannel.logo">
                <e-uploadedfiles
                  [name]="
                                        tvChannel.logo.substring(
                                            tvChannel.logo.lastIndexOf('/') + 1
                                        ) | fileExtension: false
                                    "
                  size="0"
                  type="{{
                                        tvChannel.logo.substring(
                                            tvChannel.logo.lastIndexOf('/') + 1
                                        ) | fileExtension: true
                                    }}"
                ></e-uploadedfiles>
              </e-files>
            </ejs-uploader>
            <small
              *ngIf="
                                (f.logo.dirty || f.logo.touched) &&
                                f.logo.errors &&
                                f.logo.errors.required
                            "
              class="text-danger"
            >Logo TV kanálu je povinné</small
            >
          </div>
        </div>
      </div>
    </ng-template>
    <ng-template #footerTemplate>
      <div class="d-flex justify-content-end">
                <span
                  *ngIf="tvChannelsForm.invalid"
                  class="e-badge e-badge-warning p-2 size_2"
                  role="alert"
                >
                    <strong>Formulář je špatně vyplněný</strong>
                </span>
        <span
          *ngIf="tvChannelsForm.valid"
          class="e-badge e-badge-success p-2 size_2"
          role="alert"
        >
                    <strong>Formulář je správně vyplněný</strong>
                </span>
      </div>
      <div class="row">
        <div class="col-12 pt-2">
          <button
            (click)="onSubmit()"
            [disabled]="
                            loadingTvChannels ||
                            tvChannelsForm.invalid ||
                            !isChecked
                        "
            cssClass="e-btn e-success btn-p-zero e-small mr-1"
            ejs-button
            type="submit"
          >
                        <span
                          *ngIf="loadingTvChannels === true"
                          aria-hidden="true"
                          class="spinner-grow spinner-grow-sm"
                          role="status"
                        ></span>
            <span *ngIf="loadingTvChannels === false">ULOŽIT</span>
            <span *ngIf="loadingTvChannels === true"
            >Provádím...</span
            >
          </button>
          <button
            (click)="formDialogObj.hide()"
            cssClass="e-btn e-danger e-outline btn-p-zero e-small"
            ejs-button
            type="button"
          >
            ZRUŠIT
          </button>
        </div>
      </div>
    </ng-template>
  </form>
</ejs-dialog>
