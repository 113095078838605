import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule, Routes} from '@angular/router';
import {AuthGuard} from '@src/app/_guards/auth.guard';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MailerService} from '@src/app/mailer/mailer.service';
import {SharedModule} from '@src/app/shared_components/shared.module';
import {MailerComponent} from './components/mailer/mailer.component';

const routes: Routes = [
    {
        path: 'mailer',
        children: [
            {
                path: '',
                component: MailerComponent,
                data: {
                    title: 'Nástěnka',
                    breadcrumb: [
                        {
                            label: 'Nástěnka',
                            url: '',
                        },
                    ],
                },
            },
        ],
        canActivate: [AuthGuard],
    },
];

@NgModule({
    declarations: [MailerComponent],
    imports: [
        BrowserAnimationsModule,
        RouterModule.forChild(routes),
        BrowserModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        SharedModule,
    ],
    exports: [MailerComponent],
    providers: [MailerService],
})
export class MailerModule {}
