import {ChangeDetectionStrategy, Component, ViewChild} from '@angular/core';
import {AuthenticationService} from '@src/app/_services/authentication.service';
import {registerLocaleData} from '@angular/common';
import {ActivatedRoute, Params, Router} from '@angular/router';

import moment from 'moment';
import localeCs from '@angular/common/locales/cs';
import {User} from '@src/app/_models/user/user';
import {TabComponent, ToolbarComponent} from '@syncfusion/ej2-angular-navigations';
import {SelectEventArgs} from '@syncfusion/ej2-navigations';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {SettingsService} from '@src/app/features/settings/settings.service';
import {MaterialService} from '@src/app/features/material/material.service';

moment.updateLocale('cs', {workingWeekdays: [1, 2, 3, 4, 5]});
registerLocaleData(localeCs);

@UntilDestroy()
@Component({
    selector: 'app-settings',
    templateUrl: './settings.component.html',
    styleUrls: ['./settings.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SettingsComponent {
    // Dialogs
    renderAccountantFormDialog = false;

    headerText = [
        {text: 'základní nastavení'},
        {text: 'zaměstnavatelé'},
        {text: 'společnosti'},
        {text: 'oddělení'},
        {text: 'pozice'},
        {text: 'api'},
        {text: 'budovy'},
        {text: 'kanceláře'},
        {text: 'vozidla'},
    ];

    // Variables
    visible = false;

    currentUser: User | null;

    today = moment()
        .format('YYYY-MM-DD HH:mm:ss');

    // Maintoolbar
    @ViewChild('moduleToolbar') moduleToolbarObj: ToolbarComponent;

    // Navigation
    @ViewChild('settingsTab') settingsTabObj: TabComponent;

    constructor(
        private readonly settingsService: SettingsService,
        private readonly materialsService: MaterialService,
        private readonly authenticationService: AuthenticationService,
        private readonly router: Router,
        private readonly route: ActivatedRoute,
    ) {
        this.currentUser = this.authenticationService.currentUserValue;
    }

    onCreate(): void {
        // Nastavení GUI podle URL parametrů
        this.route.queryParamMap.pipe(untilDestroyed(this))
            .subscribe(queryParams => {
                if (queryParams.get('view') === 'basic') {
                    this.settingsTabObj.selectedItem = 0;
                } else if (queryParams.get('view') === 'employers') {
                    this.settingsTabObj.selectedItem = 1;
                } else if (queryParams.get('view') === 'companies') {
                    this.settingsTabObj.selectedItem = 2;
                } else if (queryParams.get('view') === 'departments') {
                    this.settingsTabObj.selectedItem = 3;
                } else if (queryParams.get('view') === 'roles') {
                    this.settingsTabObj.selectedItem = 4;
                } else if (queryParams.get('view') === 'apis') {
                    this.settingsTabObj.selectedItem = 5;
                } else if (queryParams.get('view') === 'buildings') {
                    this.settingsTabObj.selectedItem = 6;
                } else if (queryParams.get('view') === 'offices') {
                    this.settingsTabObj.selectedItem = 7;
                } else if (queryParams.get('view') === 'cars') {
                    this.settingsTabObj.selectedItem = 8;
                } else {
                    this.settingsTabObj.selectedItem = 0;
                }
            });
    }

    preventSwipe(e: SelectEventArgs): void {
        if (e.isSwiped) {
            e.cancel = true;
        }
    }

    select(e: SelectEventArgs): void {
        let queryParams: Params = {view: null};

        if (e.selectedItem.textContent === 'základní nastavení') {
            queryParams = {
                view: 'basic',
                form: null,
            };
        }

        if (e.selectedItem.textContent === 'zaměstnavatelé') {
            queryParams = {
                view: 'employers',
                form: null,
            };
        }

        if (e.selectedItem.textContent === 'společnosti') {
            queryParams = {
                view: 'companies',
                form: null,
            };
        }

        if (e.selectedItem.textContent === 'oddělení') {
            queryParams = {
                view: 'departments',
                form: null,
            };
        }

        if (e.selectedItem.textContent === 'pozice') {
            queryParams = {
                view: 'roles',
                form: null,
            };
        }

        if (e.selectedItem.textContent === 'api') {
            queryParams = {
                view: 'apis',
                form: null,
            };
        }

        if (e.selectedItem.textContent === 'budovy') {
            queryParams = {
                view: 'buildings',
                form: null,
            };
        }

        if (e.selectedItem.textContent === 'kanceláře') {
            queryParams = {
                view: 'offices',
                form: null,
            };
        }

        if (e.selectedItem.textContent === 'vozidla') {
            queryParams = {
                view: 'cars',
                form: null,
            };
        }

        void this.router.navigate([], {
            relativeTo: this.route,
            queryParams,
            queryParamsHandling: 'merge',
        });
    }
}
