import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule, Routes} from '@angular/router';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {AuthGuard} from '@src/app/_guards/auth.guard';
import {MaterialComponent} from '@src/app/features/material/components/material/material.component';
import {MaterialCreateComponent} from '@src/app/features/material/components/material-create/material-create.component';
import {MaterialBudgetsComponent} from '@src/app/features/material/components/material-budgets/material-budgets.component';
import {MaterialApplicationsComponent} from '@src/app/features/material/components/material-applications/material-applications.component';
import {MaterialDetailComponent} from '@src/app/features/material/components/material-detail/material-detail.component';
import {BudgetItemsComponent} from '@src/app/features/material/_forms/budget-items/budget-items.component';
import {MaterialInvoicesComponent} from '@src/app/features/material/components/material-invoices/material-invoices.component';
import {MaterialItemFormComponent} from '@src/app/features/material/_forms/material-item-form/material-item-form.component';
import {MaterialItemsComponent} from '@src/app/features/material/components/material-items/material-items.component';
import {MaterialService} from '@src/app/features/material/material.service';
import {SharedFeaturesModule} from '@src/app/shared_features/shared-features.module';
import {BudgetFormComponent} from './_forms/budget-form/budget-form.component';
import {MaterialFormComponent} from './_forms/material-form/material-form.component';

const routes: Routes = [
    {
        path: 'material',
        children: [
            {
                path: '',
                component: MaterialComponent,
                data: {
                    title: 'Objednávky',
                    breadcrumb: [
                        {
                            label: 'Objednávky',
                            url: '',
                        },
                    ],
                },
            },
            {
                path: 'create',
                component: MaterialCreateComponent,
                data: {
                    title: 'Vytvořit objednávku',
                    breadcrumb: [
                        {
                            label: 'Objednávky',
                            url: '/material/',
                        },
                        {
                            label: 'Vytvořit objednávku',
                            url: '',
                        },
                    ],
                },
            },
            {
                path: 'budgets',
                component: MaterialBudgetsComponent,
                data: {
                    title: 'Rozpočty',
                    breadcrumb: [
                        {
                            label: 'Objednávky',
                            url: '/material/',
                        },
                        {
                            label: 'Rozpočty',
                            url: '',
                        },
                    ],
                },
            },
            {
                path: 'budgets/:id',
                component: MaterialBudgetsComponent,
                data: {
                    title: 'Rozpočty',
                    breadcrumb: [
                        {
                            label: 'Objednávky',
                            url: '/material/',
                        },
                        {
                            label: 'Rozpočty',
                            url: '',
                        },
                    ],
                },
            },
            {
                path: 'applications',
                component: MaterialApplicationsComponent,
                data: {
                    title: 'Fronta žádanek',
                    breadcrumb: [
                        {
                            label: 'Objednávky',
                            url: '/material/',
                        },
                        {
                            label: 'Fronta žádanek',
                            url: '',
                        },
                    ],
                },
            },
            {
                path: 'detail/:id',
                component: MaterialDetailComponent,
                data: {
                    title: 'Detail objednávky',
                    breadcrumb: [
                        {
                            label: 'Objednávky',
                            url: '/material/',
                        },
                        {
                            label: 'Detail objednávky',
                            url: '',
                        },
                    ],
                },
            },
        ],
        canActivate: [AuthGuard],
    },
];

@NgModule({
    declarations: [
        BudgetFormComponent,
        BudgetItemsComponent,
        MaterialApplicationsComponent,
        MaterialBudgetsComponent,
        MaterialComponent,
        MaterialCreateComponent,
        MaterialDetailComponent,
        MaterialFormComponent,
        MaterialInvoicesComponent,
        MaterialItemFormComponent,
        MaterialItemsComponent,
    ],
    imports: [
        BrowserAnimationsModule,
        RouterModule.forChild(routes),
        BrowserModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        SharedFeaturesModule,
    ],
    exports: [MaterialComponent, MaterialCreateComponent, MaterialDetailComponent],
    providers: [MaterialService],
})
export class MaterialModule {}
