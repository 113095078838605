<ejs-dialog
  #formDialog
  (close)="
        isUpdate = false;
        isCreate = false;
        isDirty = false;
        isChecked = false;
        isCopy = false;
        submited = false;
        changeDialogState(false)
    "
  (open)="onCreateRte()"
  [isModal]="true"
  [showCloseIcon]="true"
  [visible]="isVisible"
  [width]="'85%'"
  id="formDialog"
>
  <form [formGroup]="commentForm">
    <ng-template #content>
      <div class="dialogContent">
        <div class="row">
          <div class="form-group col-12">
            <label for="formComment">Text komentáře:</label>
            <ejs-richtexteditor
              #formComment
              (actionBegin)="handleFullScreen($event)"
              (actionComplete)="actionCompleteHandler($event)"
              [enablePersistence]="false"
              [fontFamily]="fontFamily"
              [insertImageSettings]="insertImageSettings"
              [maxLength]="maxLength"
              [ngClass]="
                                editCommentControl.text.touched &&
                                editCommentControl.text.invalid
                                    ? 'e-error'
                                    : editCommentControl.text.touched &&
                                      editCommentControl.text.valid
                                    ? 'e-success'
                                    : ''
                            "
              [pasteCleanupSettings]="pasteCleanupSettings"
              [quickToolbarSettings]="quickToolbarSettings"
              [showCharCount]="true"
              [toolbarSettings]="tools"
              formControlName="text"
              id="formComment"
              locale="cs"
              placeholder="Začněte psát komentář..."
            >
            </ejs-richtexteditor>
            <small
              *ngIf="
                                editCommentControl.text.invalid &&
                                (editCommentControl.text.dirty ||
                                    editCommentControl.text.touched)
                            "
              class="text-danger"
            >Chybná, nebo žádná hodnota</small
            >
            <small
              *ngIf="
                                editCommentControl.text.valid &&
                                (editCommentControl.text.dirty ||
                                    editCommentControl.text.touched)
                            "
              class="text-success"
            >Správně</small
            >
            <small
              *ngIf="
                                editCommentControl.text.touched &&
                                editCommentControl.text.errors &&
                                editCommentControl.text.errors.required
                            "
              class="text-danger"
            >Text komentáře je povinný</small
            >
          </div>
        </div>
      </div>
    </ng-template>
    <ng-template #footerTemplate>
      <div class="d-flex justify-content-end">
                <span
                  *ngIf="commentForm.invalid"
                  class="e-badge e-badge-warning p-2 size_2"
                  role="alert"
                >
                    <strong>Formulář je špatně vyplněný</strong>
                </span>
        <span
          *ngIf="commentForm.valid"
          class="e-badge e-badge-success p-2 size_2"
          role="alert"
        >
                    <strong>Formulář je správně vyplněný</strong>
                </span>
        <button
          (click)="onSubmit()"
          [disabled]="
                        loadingComment || commentForm.invalid || !isChecked
                    "
          cssClass="e-btn e-success btn-p-zero e-small mr-1"
          ejs-button
          type="submit"
        >
                    <span
                      *ngIf="loadingComment === true"
                      aria-hidden="true"
                      class="spinner-grow spinner-grow-sm"
                      role="status"
                    ></span>
          <span *ngIf="loadingComment === false">ULOŽIT</span>
          <span *ngIf="loadingComment === true">Provádím...</span>
        </button>
        <button
          (click)="
                        formDialogObj.hide(); isDirty = false; isChecked = false
                    "
          cssClass="e-btn e-danger e-outline btn-p-zero e-small"
          ejs-button
          type="button"
        >
          ZRUŠIT
        </button>
      </div>
    </ng-template>
  </form>
</ejs-dialog>
