import {ChangeDetectionStrategy, ChangeDetectorRef, Component, ViewChild} from '@angular/core';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {AuthenticationService} from '@src/app/_services/authentication.service';
import {User} from '@src/app/_models/user/user';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {DashboardLayoutComponent} from '@syncfusion/ej2-angular-layouts';
import {TabComponent, ToolbarComponent} from '@syncfusion/ej2-angular-navigations';
import {SelectEventArgs} from '@syncfusion/ej2-navigations';

@UntilDestroy()
@Component({
    selector: 'app-services',
    templateUrl: './services.component.html',
    styleUrls: ['./services.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ServicesComponent {
    // DashBoard
    count = 8;

    cellSpacing: number[] = [10, 10];

    cellAspectRatio: number = 100 / 100;

    // Navigations
    headerText = [
        {text: 'nástěnka'},
        {text: 'tarify'},
        {text: 'TV balíčky'},
        {text: 'TV kanály'},
        {text: 'hardware'},
        {text: 'akční ceny'},
        {text: 'lokace'},
        {text: 'bonusy'},
        {text: 'rozšiřující balíčky'},
        {text: 'nastavení služeb'},
    ];

    // Variables
    currentUser: User | null;

    // Maintoolbar
    @ViewChild('moduleToolbar') moduleToolbarObj: ToolbarComponent;

    // DashBoard
    @ViewChild('default_dashboard') dashboard: DashboardLayoutComponent;

    // Navigations
    @ViewChild('servicesTab') servicesTabObj: TabComponent;

    constructor(
        private readonly router: Router,
        private readonly ref: ChangeDetectorRef,
        private readonly route: ActivatedRoute,
        private readonly authenticationService: AuthenticationService,
    ) {
        this.currentUser = this.authenticationService.currentUserValue;
    }

    preventSwipe(e: SelectEventArgs): void {
        if (e.isSwiped) {
            e.cancel = true;
        }
    }

    onCreate(): void {
        // Nastavení GUI podle URL parametrů
        this.route.queryParamMap.pipe(untilDestroyed(this))
            .subscribe(queryParams => {
                if (queryParams.get('view') === 'dashboard') {
                    this.servicesTabObj.select(0);
                } else if (queryParams.get('view') === 'tarifs') {
                    this.servicesTabObj.select(1);
                } else if (queryParams.get('view') === 'tvpackages') {
                    this.servicesTabObj.select(2);
                } else if (queryParams.get('view') === 'tvchannels') {
                    this.servicesTabObj.select(3);
                } else if (queryParams.get('view') === 'hardware') {
                    this.servicesTabObj.select(4);
                } else if (queryParams.get('view') === 'discounts') {
                    this.servicesTabObj.select(5);
                } else if (queryParams.get('view') === 'locations') {
                    this.servicesTabObj.select(6);
                } else if (queryParams.get('view') === 'bonuses') {
                    this.servicesTabObj.select(7);
                } else if (queryParams.get('view') === 'extservices') {
                    this.servicesTabObj.select(8);
                } else if (queryParams.get('view') === 'settings') {
                    this.servicesTabObj.select(9);
                } else {
                    this.servicesTabObj.select(0);
                }
            });
    }

    navigateToTab(view: string, form: string): void {
        const queryParams = {view, form};

        if (view === 'dashboard') {
            this.servicesTabObj.select(0);
        } else if (view === 'tarifs') {
            this.servicesTabObj.select(1);
        } else if (view === 'tvpackages') {
            this.servicesTabObj.select(2);
        } else if (view === 'tvchannels') {
            this.servicesTabObj.select(3);
        } else if (view === 'hardware') {
            this.servicesTabObj.select(4);
        } else if (view === 'discounts') {
            this.servicesTabObj.select(5);
        } else if (view === 'locations') {
            this.servicesTabObj.select(6);
        } else if (view === 'bonuses') {
            this.servicesTabObj.select(7);
        } else if (view === 'extservices') {
            this.servicesTabObj.select(8);
        } else if (view === 'settings') {
            this.servicesTabObj.select(9);
        } else {
            this.servicesTabObj.select(0);
        }

        void this.router.navigate([], {
            relativeTo: this.route,
            queryParams,
            queryParamsHandling: 'merge',
        });
    }

    select(e: SelectEventArgs): void {
        let queryParams: Params = {view: null};

        // eslint-disable-next-line complexity
        this.route.queryParamMap.pipe(untilDestroyed(this))
            .subscribe(params => {
                const form =
                    params.get('form') === 'addNetPackage'
                        ? 'addNetPackage'
                        : params.get('form') === 'addTvService'
                            ? 'addTvService'
                            : params.get('form') === 'addTvPackage'
                                ? 'addTvPackage'
                                : params.get('form') === 'addTvChannel'
                                    ? 'addTvChannel'
                                    : params.get('form') === 'addHardware'
                                        ? 'addHardware'
                                        : params.get('form') === 'addDiscount'
                                            ? 'addDiscount'
                                            : params.get('form') === 'addPlace'
                                                ? 'addPlace'
                                                : params.get('form') === 'addBonus'
                                                    ? 'addBonus'
                                                    : params.get('form') === 'addExtService'
                                                        ? 'addExtService'
                                                        : null;

                if (e.selectedItem.textContent === 'nástěnka') {
                    queryParams = {
                        view: 'dashboard',
                        form,
                    };
                }

                if (e.selectedItem.textContent === 'tarify') {
                    queryParams = {
                        view: 'tarifs',
                        form,
                    };
                }

                if (e.selectedItem.textContent === 'TV balíčky') {
                    queryParams = {
                        view: 'tvpackages',
                        form,
                    };
                }

                if (e.selectedItem.textContent === 'TV kanály') {
                    queryParams = {
                        view: 'tvchannels',
                        form,
                    };
                }

                if (e.selectedItem.textContent === 'hardware') {
                    queryParams = {
                        view: 'hardware',
                        form,
                    };
                }

                if (e.selectedItem.textContent === 'akční ceny') {
                    queryParams = {
                        view: 'discounts',
                        form,
                    };
                }

                if (e.selectedItem.textContent === 'lokace') {
                    queryParams = {
                        view: 'locations',
                        form,
                    };
                }

                if (e.selectedItem.textContent === 'bonusy') {
                    queryParams = {
                        view: 'bonuses',
                        form,
                    };
                }

                if (e.selectedItem.textContent === 'rozšiřující balíčky') {
                    queryParams = {
                        view: 'extservices',
                        form,
                    };
                }

                if (e.selectedItem.textContent === 'nastavení služeb') {
                    queryParams = {
                        view: 'settings',
                        form,
                    };
                }

                void this.router.navigate([], {
                    relativeTo: this.route,
                    queryParams,
                    queryParamsHandling: 'merge',
                });
            });
    }
}
