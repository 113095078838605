<ejs-dialog
  #formDialog
  (close)="
        isUpdate = false;
        isCreate = false;
        isDirty = false;
        isChecked = false;
        isCopy = false;
        submited = false;
        changeDialogState(false)
    "
  [isModal]="true"
  [showCloseIcon]="true"
  [visible]="isVisible"
  [width]="'85%'"
  id="formDialog"
>
  <form [formGroup]="technologyTypeForm">
    <ng-template #content>
      <div class="dialogContent">
        <div class="row">
          <div class="col-12 mb-3">
            <label
              [ngClass]="
                                f.name.valid && (f.name.dirty || f.name.touched)
                                    ? 'text-success'
                                    : f.name.invalid &&
                                      (f.name.dirty || f.name.touched)
                                    ? 'text-danger'
                                    : 'text-muted'
                            "
              for="technologyTypeForm-name"
            >* Název:</label
            >
            <ejs-textbox
              [ngClass]="
                                (f.name.dirty || f.name.touched) &&
                                f.name.invalid
                                    ? 'e-error'
                                    : (f.name.dirty || f.name.touched) &&
                                      f.name.valid
                                    ? 'e-success'
                                    : ''
                            "
              floatLabelType="Auto"
              formControlName="name"
              id="technologyTypeForm-name"
            ></ejs-textbox>
            <small
              *ngIf="
                                (f.name.dirty || f.name.touched) &&
                                f.name.errors &&
                                f.name.errors.required
                            "
              class="text-danger"
            >Název je povinný</small
            >
          </div>
        </div>
      </div>
    </ng-template>
    <ng-template #footerTemplate>
      <div class="d-flex justify-content-end">
                <span
                  *ngIf="technologyTypeForm.invalid"
                  class="e-badge e-badge-warning p-2 size_2"
                  role="alert"
                >
                    <strong>Formulář je špatně vyplněný</strong>
                </span>
        <span
          *ngIf="technologyTypeForm.valid"
          class="e-badge e-badge-success p-2 size_2"
          role="alert"
        >
                    <strong>Formulář je správně vyplněný</strong>
                </span>
      </div>
      <div class="row">
        <div class="col-12 pt-2">
          <button
            (click)="onSubmit()"
            [disabled]="
                            loadingTechnologyType ||
                            technologyTypeForm.invalid ||
                            !isChecked
                        "
            cssClass="e-btn e-success btn-p-zero e-small mr-1"
            ejs-button
            type="submit"
          >
                        <span
                          *ngIf="loadingTechnologyType === true"
                          aria-hidden="true"
                          class="spinner-grow spinner-grow-sm"
                          role="status"
                        ></span>
            <span *ngIf="loadingTechnologyType === false"
            >ULOŽIT</span
            >
            <span *ngIf="loadingTechnologyType === true"
            >Provádím...</span
            >
          </button>
          <button
            (click)="formDialogObj.hide()"
            cssClass="e-btn e-danger e-outline btn-p-zero e-small"
            ejs-button
            type="button"
          >
            ZRUŠIT
          </button>
        </div>
      </div>
    </ng-template>
  </form>
</ejs-dialog>
