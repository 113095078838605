import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {Tickets} from '@src/app/_models/ticket/tickets';
import {AuthenticationService} from '@src/app/_services/authentication.service';
import {User} from '@src/app/_models/user/user';
import {TicketsService} from '@src/app/features/tickets/tickets.service';
import {PermissionService} from '@src/app/_services/permission.service';
import {Department} from '@src/app/_models/department/department';

@Component({
    selector: 'app-ticket-sidebar',
    templateUrl: './ticket-sidebar.component.html',
    styleUrls: ['./ticket-sidebar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TicketSidebarComponent {
    @Input() ticket?: Tickets | null;

    @Output() readonly addUserEvent = new EventEmitter<boolean>();

    @Output() readonly addDepartmentsEvent = new EventEmitter<boolean>();

    @Output() readonly promoteUserEvent = new EventEmitter<User>();

    @Output() readonly unPromoteUserEvent = new EventEmitter<User>();

    @Output() readonly deleteUserEvent = new EventEmitter<User>();

    @Output() readonly deleteDepartmentEvent = new EventEmitter<Department>();

    live: true;

    currentUser: User | null;

    constructor(
        private readonly authenticationService: AuthenticationService,
        public ticketsService: TicketsService,
        public permissionService: PermissionService,
    ) {
        this.currentUser = this.authenticationService.currentUserValue;
    }

    addUser(): void {
        this.addUserEvent.emit(true);
    }

    addDepartments(): void {
        this.addDepartmentsEvent.emit(true);
    }

    promoteUser(user: User): void {
        this.promoteUserEvent.emit(user);
    }

    unPromoteUser(user: User): void {
        this.unPromoteUserEvent.emit(user);
    }

    deleteUser(user: User): void {
        this.deleteUserEvent.emit(user);
    }

    deleteDepartment(department: Department): void {
        this.deleteDepartmentEvent.emit(department);
    }
}
