import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    HostListener,
    Input,
    OnChanges,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {DataService} from '@src/app/_services/data.service';
import {DataNetbaseService} from '@src/app/_services/data-netbase.service';
import {ServicesService, UnitInput} from '@src/app/features/services/services.service';
import {MessageService} from '@src/app/_services/message.service';
import {AuthenticationService} from '@src/app/_services/authentication.service';
import {UnitTypes} from '@src/app/_models/services/unit-types';
import {User} from '@src/app/_models/user/user';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {Observable} from 'rxjs';
import {DialogComponent} from '@syncfusion/ej2-angular-popups';
import {ComponentCanDeactivate} from '@src/app/_guards/changes.guard';
import {ActivatedRoute, Params, Router} from '@angular/router';

@UntilDestroy()
@Component({
    selector: 'app-flat-unit-type-form',
    templateUrl: './flat-unit-type-form.component.html',
    styleUrls: ['./flat-unit-type-form.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FlatUnitTypeFormComponent implements OnInit, OnChanges, ComponentCanDeactivate {
    // Forms
    flatUnitTypeForm: FormGroup;

    isDirty = false;

    isChecked = false;

    submited = false;

    // Variables
    currentUser: User | null;

    // Loaders
    loadingFlatUnitType = false;

    @Input() flatUnit: UnitTypes | null = null;

    @Input() isCreate = false;

    @Input() isUpdate = false;

    @Input() isVisible = false;

    @Input() isCopy = false;

    @Output() readonly flatUnitFormDialogState = new EventEmitter<boolean>();

    // Dialogs
    @ViewChild('formDialog') formDialogObj: DialogComponent;

    constructor(
        private readonly router: Router,
        private readonly route: ActivatedRoute,
        private readonly formBuilder: FormBuilder,
        private readonly ref: ChangeDetectorRef,
        private readonly dataService: DataService,
        private readonly dataNetbaseService: DataNetbaseService,
        private readonly servicesService: ServicesService,
        private readonly messages: MessageService,
        private readonly authenticationService: AuthenticationService,
    ) {
        this.currentUser = this.authenticationService.currentUserValue;
    }

    get f(): {[key: string]: AbstractControl} {
        return this.flatUnitTypeForm.controls;
    }

    @HostListener('window:beforeunload')
    canDeactivate(): Observable<boolean> | boolean {
        return !this.isDirty;
    }

    ngOnInit(): void {
        this.flatUnitTypeForm = this.formBuilder.group({
            name: [
                '',
                Validators.compose([
                    Validators.required,
                    Validators.maxLength(255),
                    Validators.minLength(2),
                ]),
            ],
        });

        this.flatUnitTypeForm.valueChanges.pipe(untilDestroyed(this))
            .subscribe(() => {
                this.isDirty = this.flatUnitTypeForm.dirty;
                this.showInputErrors();
            });
    }

    ngOnChanges(): void {
        if (this.isVisible) {
            const queryParams: Params = {form: null};

            void this.router.navigate([], {
                relativeTo: this.route,
                queryParams,
                queryParamsHandling: 'merge',
            });

            if (this.isUpdate && this.flatUnit) {
                this.flatUnitTypeForm.reset();
                this.formDialogObj.header = `Typ bytové jednotky #${this.flatUnit.id}`;
                this.fillTheForm();
                this.showInputErrors();
            }

            if (this.isCreate) {
                this.flatUnitTypeForm.reset();
                this.formDialogObj.header = 'Nový typ bytové jednotky';
            }

            if (this.isCopy) {
                this.flatUnitTypeForm.reset();
                this.formDialogObj.header = 'Nový typ bytové jednotky';
                this.fillTheForm();
                this.showInputErrors();
            }
        }
    }

    changeDialogState(value: boolean): void {
        this.flatUnitFormDialogState.emit(value);
    }

    fillTheForm(): void {
        this.flatUnitTypeForm.controls.name.patchValue(this.flatUnit?.name);
    }

    onSubmit(): void {
        this.submited = true;
        this.isDirty = false;
        this.loadingFlatUnitType = true;

        if (this.isCreate || this.isCopy) {
            this.createUnitType();
        }

        if (this.isUpdate) {
            this.editUnitType();
        }
    }

    createUnitType(): void {
        if (this.flatUnitTypeForm.invalid) {
            console.error('form is not valid!');

            return;
        }

        this.loadingFlatUnitType = true;

        const flatUnitTypeData: UnitInput = {
            name: this.f.name.value,
            created_by: this.currentUser?.id,
        };

        this.servicesService
            .addUnitType(flatUnitTypeData)
            .pipe(untilDestroyed(this))
            .subscribe(
                (data: UnitTypes) => {
                    const body = `${data.name} přidán do nabídky`;
                    const options = {progressBar: true, timeOut: 5000};

                    this.messages.showSuccess(
                        'Typ bytové jednotky úspěšně vytvořen',
                        body,
                        options,
                    );
                    this.dataNetbaseService.setUnitTypesDataSource();
                    this.isCreate = false;
                    this.loadingFlatUnitType = false;
                    this.ref.markForCheck();
                    this.formDialogObj.hide();
                },
                error => {
                    console.error(error);

                    const body = 'Zkuste to znovu později';
                    const options = {
                        progressBar: true,
                        timeOut: 5000,
                        toastClass: 'red',
                    };

                    this.messages.showError(
                        'Chyba při vytváření typu bytové jednotky',
                        body,
                        options,
                    );
                    this.loadingFlatUnitType = false;
                    this.ref.markForCheck();
                },
            );
    }

    editUnitType(): void {
        if (this.flatUnitTypeForm.invalid || !this.flatUnit) {
            console.error('form is not valid! || this.flatUnit is missing...');

            return;
        }

        this.loadingFlatUnitType = true;

        const flatUnitTypeData: UnitInput = {
            id: this.flatUnit.id,
            name: this.f.name.value,
            updated_by: this.currentUser?.id,
        };

        this.servicesService
            .updateUnitType(flatUnitTypeData)
            ?.pipe(untilDestroyed(this))
            .subscribe(
                (data: UnitTypes) => {
                    const body = `${data.name} upraven`;
                    const options = {progressBar: true, timeOut: 5000};

                    this.messages.showSuccess('Typ bytové jednotky úspěšně upraven', body, options);
                    this.dataNetbaseService.setUnitTypesDataSource();
                    this.isUpdate = false;
                    this.loadingFlatUnitType = false;
                    this.ref.markForCheck();
                    this.formDialogObj.hide();
                },
                error => {
                    console.error(error);

                    const body = 'Zkuste to znovu později';
                    const options = {
                        progressBar: true,
                        timeOut: 5000,
                        toastClass: 'red',
                    };

                    this.messages.showError('Chyba při úpravě typu bytové jednotky', body, options);
                    this.loadingFlatUnitType = false;
                    this.ref.markForCheck();
                },
            );
    }

    showInputErrors(): void {
        this.isChecked = true;
        /* console.log('VALID: ' + this.ticketForm.valid);
        Object.keys(this.ticketForm.controls).forEach(key => {
            const controlErrors: ValidationErrors = this.ticketForm.get(key).errors;
            if (controlErrors != null) {
                Object.keys(controlErrors).forEach(keyError => {
                    console.log('Key control: ' + key + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError]);
                });
            }
        });*/
        this.flatUnitTypeForm.markAllAsTouched();
        this.ref.markForCheck();
    }
}
