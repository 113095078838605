import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {AuthenticationService} from '@src/app/_services/authentication.service';
import {CustomStorageService} from '@src/app/_services/custom-storage.service';
import {BehaviorSubject, interval, Observable, of} from 'rxjs';
import {LogoutService} from '@src/app/_services/logout.service';
import {take} from 'rxjs/operators';

const minutesUntilAutoLogout = 45; // in mins
const checkInterval = 45; // in sec
const storeKey = 'lastAction';

@Injectable({
    providedIn: 'root',
})
export class AutologoutService {
    openDialog = new BehaviorSubject<boolean>(false);

    val: string | null;

    deadline$: Observable<number>;

    timeLeft: BehaviorSubject<number | null> = new BehaviorSubject<number | null>(null);

    timeoutId: NodeJS.Timeout | number | string | undefined;

    get isOpen(): Observable<boolean> {
        return this.openDialog.asObservable();
    }

    constructor(
        private readonly storage: CustomStorageService,
        private readonly router: Router,
        private readonly authenticationService: AuthenticationService,
        private readonly logoutService: LogoutService,
    ) {
        this.initListener();
        this.initInterval();
        this.storage.setItem(storeKey, Date.now()
            .toString());
        this.deadline$ = of(
            (this.getLastAction() ?? Date.now()) + minutesUntilAutoLogout * 60 * 1000,
        );

        if (this.authenticationService.isLoggedIn) {
            this.check();
        }
    }

    getLastAction(): number | undefined {
        const item = this.storage.getItem(storeKey);

        if (item !== null) {
            return parseInt(item, 10);
        }
    }

    setLastAction(lastAction: number): void {
        this.storage.setItem(storeKey, lastAction.toString());
    }

    initListener(): void {
        // mouse
        document.body.addEventListener('mousedown', () => {
            this.reset();
        });

        document.body.addEventListener('mouseup', () => {
            this.reset();
        });

        document.body.addEventListener('click', () => {
            this.reset();
        });

        document.body.addEventListener('wheel', () => {
            this.reset();
        });

        // mobile
        document.body.addEventListener('touchstart', () => {
            this.reset();
        });

        document.body.addEventListener('touchend', () => {
            this.reset();
        });

        document.body.addEventListener('touchcancel', () => {
            this.reset();
        });

        document.body.addEventListener('touchmove', () => {
            this.reset();
        });

        window.addEventListener('storage', () => {
            this.storageEvt();
        });
    }

    reset(): void {
        this.openDialog.next(false);
        this.setLastAction(Date.now());
        this.deadline$ = of(
            (this.getLastAction() ?? Date.now()) + minutesUntilAutoLogout * 60 * 1000,
        );
        clearTimeout(this.timeoutId);
    }

    initInterval(): void {
        interval(checkInterval * 1000)
            .subscribe(() => {
                if (this.authenticationService.isLoggedIn) {
                    this.check();
                }
            });
    }

    check(): void {
        const now = Date.now();

        this.deadline$.pipe(take(1))
            .subscribe(timeLeft => {
                const diff = timeLeft - now;
                const isTimeout = diff < 0;

                this.timeLeft.next(diff);

                if (
                    !isTimeout &&
                    diff > 0 &&
                    diff <= 60000 &&
                    !this.openDialog.getValue() &&
                    this.authenticationService.isLoggedIn
                ) {
                    this.openDialog.next(true);
                    this.timeoutId = setTimeout(() => {
                        this.openDialog.next(false);
                        console.info('logout by autologout');
                        this.logoutService.logout();
                    }, 60000);
                }
            });
    }

    storageEvt(): void {
        this.val = this.storage.getItem(storeKey);
    }
}
