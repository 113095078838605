import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    HostListener,
    Input,
    OnChanges,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import {AbstractControl, FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {EmitType} from '@syncfusion/ej2-base';
import {DropDownListComponent, FilteringEventArgs} from '@syncfusion/ej2-angular-dropdowns';
import {Query} from '@syncfusion/ej2-data';
import {Department} from '@src/app/_models/department/department';
import {ComponentCanDeactivate} from '@src/app/_guards/changes.guard';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {DialogComponent} from '@syncfusion/ej2-angular-popups';
import {Observable} from 'rxjs';
import {AuthenticationService} from '@src/app/_services/authentication.service';
import {User} from '@src/app/_models/user/user';
import {DataService} from '@src/app/_services/data.service';
import {MessageService} from '@src/app/_services/message.service';
import {Company} from '@src/app/_models/company/company';
import {Role} from '@src/app/_models/role/role';
import {DepartmentInput, SettingsService} from '../../settings.service';
import {UsersService} from '../../../users/users.service';

@UntilDestroy()
@Component({
    selector: 'app-department-form',
    templateUrl: './department-form.component.html',
    styleUrls: ['./department-form.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DepartmentFormComponent implements OnInit, OnChanges, ComponentCanDeactivate {
    // Forms
    departmentForm: FormGroup;

    usersList: FormArray;

    isDirty = false;

    isChecked = false;

    submited = false;

    // Dropdowms
    height = '240px';

    fields: object = {text: 'label', value: 'value'};

    companySelect: Array<{value: boolean | number | string; label: string}> = [];

    usersSelect: Array<{value: boolean | number | string; label: string}> = [];

    roleSelect: Array<{value: boolean | number | string; label: string}> = [];

    // Variables
    currentUser: User | null;

    // Loaders
    loadingDepartment = false;

    @Input() department: Department | null = null;

    @Input() isCreate = false;

    @Input() isUpdate = false;

    @Input() isVisible = false;

    @Input() isCopy = false;

    @Output() readonly departmentFormDialogState = new EventEmitter<boolean>();

    // Dialogs
    @ViewChild('formDialog') formDialogObj: DialogComponent;

    // Dropdowms
    @ViewChild('user') usersObj: DropDownListComponent;

    @ViewChild('company') companyObj: DropDownListComponent;

    @ViewChild('role') roleObj: DropDownListComponent;

    constructor(
        private readonly formBuilder: FormBuilder,
        private readonly route: ActivatedRoute,
        private readonly router: Router,
        private readonly dataService: DataService,
        private readonly settingsService: SettingsService,
        private readonly authenticationService: AuthenticationService,
        private readonly userService: UsersService,
        private readonly message: MessageService,
        private readonly ref: ChangeDetectorRef,
    ) {
        this.currentUser = this.authenticationService.currentUserValue;
    }

    get assignationformGroup(): FormArray {
        return this.departmentForm.get('usersAssignation') as FormArray;
    }

    get f(): {[key: string]: AbstractControl} {
        return this.departmentForm.controls;
    }

    @HostListener('window:beforeunload')
    canDeactivate(): Observable<boolean> | boolean {
        return !this.isDirty;
    }

    ngOnInit(): void {
        this.dataService.companySource.pipe(untilDestroyed(this))
            .subscribe(
                (companies: Company[] | undefined) => {
                    companies?.map((company: Company) => {
                        this.companySelect = [
                            ...this.companySelect,
                            {
                                value: company.id,
                                label: company.name,
                            },
                        ];
                    });
                },
                error => {
                    console.error(error);
                },
            );

        this.dataService.userSource.pipe(untilDestroyed(this))
            .subscribe(
                (data: User[] | undefined) => {
                    data?.map((user: User) => {
                        if (user.authorized && !user.deleted_date) {
                            this.usersSelect = [
                                ...this.usersSelect,
                                {
                                    value: user.id,
                                    label: `${user.firstname} ${user.secondname}`,
                                },
                            ];
                        }
                    });
                },
                error => {
                    console.error(error);
                },
            );

        this.dataService.roleSource.pipe(untilDestroyed(this))
            .subscribe(
                (data: Role[] | undefined) => {
                    data?.map((role: Role) => {
                        this.roleSelect = [
                            ...this.roleSelect,
                            {
                                value: role.id,
                                label: role.name,
                            },
                        ];
                    });
                },
                error => {
                    console.error(error);
                },
            );

        this.departmentForm = this.formBuilder.group({
            usersAssignation: this.formBuilder.array([this.createAssignation()]),
            name: [
                '',
                Validators.compose([
                    Validators.required,
                    Validators.minLength(2),
                    Validators.maxLength(100),
                ]),
            ],
            company: [null, Validators.required],
        });

        this.departmentForm.valueChanges.pipe(untilDestroyed(this))
            .subscribe(() => {
                this.isDirty = this.departmentForm.dirty;
                this.showInputErrors();
            });

        this.usersList = this.departmentForm.get('usersAssignation') as FormArray;

        this.dataService.setRoleDataSource();
        this.dataService.setUsersDataSource();
        this.dataService.setCompanyDataSource();
    }

    createAssignation(): FormGroup {
        return this.formBuilder.group({
            user: [
                null,
                Validators.compose([
                    Validators.required,
                    Validators.minLength(2),
                    Validators.maxLength(100),
                ]),
            ],
            role: [
                null,
                Validators.compose([
                    Validators.required,
                    Validators.minLength(2),
                    Validators.maxLength(100),
                ]),
            ],
        });
    }

    getAssignationFormGroup(index): FormGroup {
        return this.usersList.controls[index] as FormGroup;
    }

    addAssignation(): void {
        this.usersList.push(this.createAssignation());
    }

    removeAssignation(index: number): void {
        this.usersList.removeAt(index);
    }

    onFilteringCompanies: EmitType<FilteringEventArgs> = (e: FilteringEventArgs) => {
        if (e.text === '') {
            e.updateData(this.companySelect);
        } else {
            let query: Query = new Query();

            query = e.text !== '' ? query.where('label', 'contains', e.text, true, true) : query;
            e.updateData(this.companySelect, query);
        }
    };

    onFilteringUsers: EmitType<FilteringEventArgs> = (e: FilteringEventArgs) => {
        if (e.text === '') {
            e.updateData(this.usersSelect);
        } else {
            let query: Query = new Query();

            query = e.text !== '' ? query.where('label', 'contains', e.text, true, true) : query;
            e.updateData(this.usersSelect, query);
        }
    };

    onFilteringRoles: EmitType<FilteringEventArgs> = (e: FilteringEventArgs) => {
        if (e.text === '') {
            e.updateData(this.roleSelect);
        } else {
            let query: Query = new Query();

            query = e.text !== '' ? query.where('label', 'contains', e.text, true, true) : query;
            e.updateData(this.roleSelect, query);
        }
    };

    ngOnChanges(): void {
        if (this.isVisible) {
            const queryParams: Params = {form: null};

            void this.router.navigate([], {
                relativeTo: this.route,
                queryParams,
                queryParamsHandling: 'merge',
            });

            if (this.isUpdate && this.department) {
                this.departmentForm.reset();
                this.formDialogObj.header = `Oddělení #${this.department.id} - ${this.department.name}`;
                this.fillTheForm();
                this.showInputErrors();
            }

            if (this.isCreate) {
                this.departmentForm.reset();
                this.formDialogObj.header = 'Nové oddělení';
            }

            if (this.isCopy) {
                this.departmentForm.reset();
                this.formDialogObj.header = 'Nové oddělení';
                this.fillTheForm();
                this.showInputErrors();
            }
        }
    }

    changeDialogState(value: boolean): void {
        this.departmentFormDialogState.emit(value);
    }

    fillTheForm(): void {
        this.departmentForm.controls.name.patchValue(this.department?.name);
        this.departmentForm.controls.company.patchValue(this.department?.company.id);
    }

    onSubmit(): void {
        this.submited = true;
        this.isDirty = false;
        this.loadingDepartment = true;

        if (this.isCreate || this.isCopy) {
            this.createDepartment();
        }

        if (this.isUpdate) {
            this.editDepartment();
        }
    }

    createDepartment(): void {
        this.loadingDepartment = true;
        this.ref.markForCheck();

        if (this.departmentForm.invalid) {
            console.error('form is not valid!');

            return;
        }

        const users = this.usersList.value;
        const department: DepartmentInput = {
            name: this.f.name.value,
            company_id: this.f.company.value,
            created_by: this.currentUser?.id,
            users,
        };

        this.settingsService
            .addDepartment(department)
            .pipe(untilDestroyed(this))
            .subscribe(
                () => {
                    const body = 'Úspěšně jste vytvořili oddělení';
                    const options = {
                        progressBar: true,
                        timeOut: 5000,
                        toastClass: 'success',
                    };

                    this.message.showSuccess('Oddělení vytvořeno', body, options);
                    this.dataService.setDepartmentDataSource();
                    this.isCreate = false;
                    this.loadingDepartment = false;
                    this.ref.markForCheck();
                    this.formDialogObj.hide();
                },
                error => {
                    console.error(error);

                    const body = 'Zkuste to znovu později';
                    const options = {
                        progressBar: true,
                        timeOut: 5000,
                        toastClass: 'red',
                    };

                    this.message.showError('Chyba při vytváření oddělení', body, options);
                    this.loadingDepartment = false;
                    this.ref.markForCheck();
                },
            );
    }

    editDepartment(): void {
        this.loadingDepartment = true;
        this.ref.markForCheck();

        if (this.departmentForm.invalid || !this.department) {
            console.error('form is not valid! || this.department is missing...');

            return;
        }

        this.department.name = this.f.name.value;
        this.settingsService
            .editDepartment(this.department)
            ?.pipe(untilDestroyed(this))
            .subscribe(
                () => {
                    const body = 'Úspěšně jste upravil/a oddělení';
                    const options = {
                        progressBar: true,
                        timeOut: 5000,
                        toastClass: 'success',
                    };

                    this.message.showSuccess('Oddělení upraveno', body, options);
                    this.dataService.setDepartmentDataSource();
                    this.isUpdate = false;
                    this.loadingDepartment = false;
                    this.ref.markForCheck();
                    this.formDialogObj.hide();
                },
                error => {
                    console.error(error);

                    const body = 'Zkuste to znovu později';
                    const options = {
                        progressBar: true,
                        timeOut: 5000,
                        toastClass: 'red',
                    };

                    this.message.showError('Chyba při úpravě oddělení', body, options);
                    this.loadingDepartment = false;
                    this.ref.markForCheck();
                },
            );
    }

    showInputErrors(): void {
        this.isChecked = true;
        /* console.log('VALID: ' + this.ticketForm.valid);
        Object.keys(this.ticketForm.controls).forEach(key => {
            const controlErrors: ValidationErrors = this.ticketForm.get(key).errors;
            if (controlErrors != null) {
                Object.keys(controlErrors).forEach(keyError => {
                    console.log('Key control: ' + key + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError]);
                });
            }
        });*/
        this.departmentForm.markAllAsTouched();
        this.ref.markForCheck();
    }
}
