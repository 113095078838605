import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'threads',
})
export class ThreadsPipe implements PipeTransform {
    transform = (allComments: unknown[]): unknown[] =>
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        allComments.filter(comment => comment.is_thread);
}
