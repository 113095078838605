import {ChangeDetectionStrategy, ChangeDetectorRef, Component, ViewChild} from '@angular/core';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {DashboardLayoutComponent} from '@syncfusion/ej2-angular-layouts';
import {TabComponent, ToolbarComponent} from '@syncfusion/ej2-angular-navigations';
import {IAccTooltipRenderEventArgs} from '@syncfusion/ej2-charts';
import {SelectEventArgs} from '@syncfusion/ej2-navigations';
import {ActivatedRoute, Params, Router} from '@angular/router';

@UntilDestroy()
@Component({
    selector: 'app-knowledge',
    templateUrl: './knowledge.component.html',
    styleUrls: ['./knowledge.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KnowledgeComponent {
    // DashBoard
    count = 8;

    cellSpacing: number[] = [10, 10];

    cellAspectRatio: number = 100 / 100;

    // Navigations
    headerText = [{text: 'nástěnka'}, {text: 'tabulka'}, {text: 'import'}];

    // Maintoolbar
    @ViewChild('moduleToolbar') moduleToolbarObj?: ToolbarComponent;

    // DashBoard
    @ViewChild('default_dashboard') dashboard?: DashboardLayoutComponent;

    // Navigations
    @ViewChild('knowledgeTab') knowledgeTabObj?: TabComponent;

    constructor(
        private readonly router: Router,
        private readonly ref: ChangeDetectorRef,
        private readonly route: ActivatedRoute,
    ) {}

    onCreate(): void {
        // Nastavení GUI podle URL parametrů
        this.route.queryParamMap.pipe(untilDestroyed(this))
            .subscribe(queryParams => {
                if (this.knowledgeTabObj) {
                    if (queryParams.get('view') === 'dashboard') {
                        this.knowledgeTabObj.select(0);
                    } else if (queryParams.get('view') === 'grid') {
                        this.knowledgeTabObj.select(1);
                    } else if (queryParams.get('view') === 'import') {
                        this.knowledgeTabObj.select(2);
                    } else {
                        this.knowledgeTabObj.select(0);
                    }
                }
            });
    }

    refreshContent(): void {
        setTimeout(() => {
            if (this.dashboard) {
                this.dashboard.refresh();
            }

            if (this.moduleToolbarObj) {
                this.moduleToolbarObj.refresh();
            }
        }, 1000);
    }

    tooltipRender(args: IAccTooltipRenderEventArgs): void {
        const value = args.point.y / args.series.sumOfPoints * 100;

        args.text = `${args.point.x as string} : ${Math.ceil(value)} %`;
    }

    onCloseIconHandler(event: MouseEvent): void {
        if (event.target === null) {
            return;
        }

        const elementOffset = (event.target as HTMLElement).offsetParent;

        if (elementOffset) {
            this.dashboard?.removePanel(elementOffset.id);
        }
    }

    preventSwipe(e: SelectEventArgs): void {
        if (e.isSwiped) {
            e.cancel = true;
        }
    }

    navigateToTab(view: string, form: string): void {
        const queryParams = {view, form};

        if (this.knowledgeTabObj) {
            if (view === 'dashboard') {
                this.knowledgeTabObj.select(0);
            } else if (view === 'grid') {
                this.knowledgeTabObj.select(1);
            } else if (view === 'import') {
                this.knowledgeTabObj.select(2);
            } else {
                this.knowledgeTabObj.select(0);
            }
        }

        void this.router.navigate([], {
            relativeTo: this.route,
            queryParams,
            queryParamsHandling: 'merge',
        });
    }

    select(e: SelectEventArgs): void {
        let queryParams: Params = {view: null};

        this.route.queryParamMap.pipe(untilDestroyed(this))
            .subscribe(params => {
                const form = params.get('form') === 'addKnowledge' ? 'addKnowledge' : null;

                if (e.selectedItem.textContent === 'nástěnka') {
                    queryParams = {
                        view: 'dashboard',
                        form,
                    };
                }

                if (e.selectedItem.textContent === 'tabulka') {
                    queryParams = {
                        view: 'grid',
                        form,
                    };
                }

                if (e.selectedItem.textContent === 'import') {
                    queryParams = {
                        view: 'import',
                        form,
                    };
                }

                void this.router.navigate([], {
                    relativeTo: this.route,
                    queryParams,
                    queryParamsHandling: 'merge',
                });
            });
    }
}
