import {
    AfterViewChecked,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnInit,
    ViewChild,
} from '@angular/core';
import {registerLocaleData} from '@angular/common';
import {Observable, Observer} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthenticationService} from '@src/app/_services/authentication.service';
import {DataService} from '@src/app/_services/data.service';
import {HardwareInput, ServicesService} from '@src/app/features/services/services.service';
import {PermissionService} from '@src/app/_services/permission.service';
import {MessageService} from '@src/app/_services/message.service';
import {User} from '@src/app/_models/user/user';
import {Hardware} from '@src/app/_models/services/hardware';
import {DataNetbaseService} from '@src/app/_services/data-netbase.service';

import moment from 'moment';
import localeCs from '@angular/common/locales/cs';
import {ButtonPropsModel, DialogComponent} from '@syncfusion/ej2-angular-popups';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {ChangeEventArgs, DropDownListComponent} from '@syncfusion/ej2-angular-dropdowns';
import {
    ColumnModel,
    DataResult,
    EditSettingsModel,
    ExcelExportProperties,
    FilterSettingsModel,
    GridComponent,
    GroupSettingsModel,
    PageSettingsModel,
    RowDataBoundEventArgs,
    SelectionSettingsModel,
    ToolbarItems,
} from '@syncfusion/ej2-angular-grids';
import {TextBoxComponent} from '@syncfusion/ej2-angular-inputs';
import {ClickEventArgs} from '@syncfusion/ej2-angular-navigations';

moment.updateLocale('cs', {workingWeekdays: [1, 2, 3, 4, 5]});
registerLocaleData(localeCs);

@UntilDestroy()
@Component({
    selector: 'app-services-hardware',
    templateUrl: './services-hardware.component.html',
    styleUrls: ['./services-hardware.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ServicesHardwareComponent implements OnInit, AfterViewChecked {
    // Grid
    currencyFormat: {format: string} = {format: '#,###.00\',- CZK\''};

    dateFormat: string;

    toolbar: ToolbarItems[] | object;

    data: object;

    fields: object = {text: 'label', value: 'value'};

    filterSettings: FilterSettingsModel;

    filterCheckboxSettings: FilterSettingsModel;

    selectionSettings: SelectionSettingsModel;

    editSettings: EditSettingsModel;

    groupOptions: GroupSettingsModel;

    pageOptions: PageSettingsModel;

    expendituresColumnsPrice: ColumnModel[];

    expendituresColumnsItems: ColumnModel[];

    filterDate: object;

    sortOptions: object;

    requiredRules: object;

    exportCurrentPage = false;

    // Dialogs
    renderHardwareFormDialog = false;

    buttonsDelete: ButtonPropsModel[] = [
        {
            click: (): void => {
                this.deleteHardware();
            },
            isFlat: false,
            buttonModel: {
                content: 'OK',
                cssClass: 'e-success e-outline',
            },
        },
        {
            click: (): void => {
                this.confirmationDelete.hide();
            },
            buttonModel: {
                content: 'ZRUŠIT',
                cssClass: 'e-danger e-outline',
            },
        },
    ];

    buttonsCancel: ButtonPropsModel[] = [
        {
            click: (): void => {
                this.confirmationCancel.hide();
            },
            buttonModel: {
                isPrimary: true,
                content: 'OK',
            },
        },
    ];

    // Dropdowns
    height = '240px';

    categoryFilterData: Array<string> = [];

    // Forms
    isCreate = false;

    isUpdate = false;

    isCopy = false;

    // Variables
    currentUser: User | null;

    hardwares: Hardware[] | undefined = [];

    today = moment()
        .format('YYYY-MM-DD HH:mm:ss');

    clickedRow: Hardware[] = [];

    clickedRow$ = new Observable<Hardware[]>((observer: Observer<Hardware[]>) => {
        observer.next(this.clickedRow);
    });

    // Loaders
    tableLoad = true;

    // Grid
    @ViewChild('hardwaresGrid') gridHardwares: GridComponent;

    @ViewChild('searchtext') searchtextObj: TextBoxComponent;

    // Dialogs
    @ViewChild('confirmationDelete') confirmationDelete: DialogComponent;

    @ViewChild('confirmationCancel') confirmationCancel: DialogComponent;

    // Dropdowns
    @ViewChild('viewFilter') viewFilterInstance: DropDownListComponent;

    constructor(
        private readonly router: Router,
        private readonly route: ActivatedRoute,
        private readonly ref: ChangeDetectorRef,
        private readonly authenticationService: AuthenticationService,
        private readonly dataService: DataService,
        private readonly dataNetbaseService: DataNetbaseService,
        private readonly servicesService: ServicesService,
        private readonly permissionService: PermissionService,
        private readonly messages: MessageService,
    ) {
        this.currentUser = this.authenticationService.currentUserValue;
    }

    ngOnInit(): void {
        this.filterSettings = {
            type: 'Menu',
            showFilterBarStatus: true,
            ignoreAccent: true,
        };

        this.filterCheckboxSettings = {
            type: 'CheckBox',
            showFilterBarStatus: true,
            ignoreAccent: true,
        };

        this.filterSettings = {
            type: 'Excel',
            showFilterBarOperator: true,
            showFilterBarStatus: true,
            ignoreAccent: true,
        };

        this.selectionSettings = {
            persistSelection: true,
            type: 'Multiple',
            checkboxOnly: true,
        };

        this.toolbar = [
            {
                text: 'Občerstvit data',
                tooltipText: 'Občerstvit data',
                prefixIcon: 'e-refresh',
                id: 'customRefresh',
            },
            'Print',
            'ExcelExport',
            'Add',
            'Edit',
            {
                text: 'Smazat',
                tooltipText: 'Smazat zařízení',
                prefixIcon: 'e-remove',
                id: 'delete',
            },
            {
                text: 'Kopírovat',
                tooltipText: 'Kopírovat zařízení',
                prefixIcon: 'e-copy',
                id: 'copy',
            },
        ];
        this.categoryFilterData = ['všechny zařízení', 'aktivní zařízení', 'neaktivní zařízení'];
        this.pageOptions = {pageSize: 50};
        this.dateFormat = 'dd.MM.yyyy HH:mm';
        this.filterDate = {type: 'Menu', params: {format: this.dateFormat}};
        this.groupOptions = {
            showGroupedColumn: true,
            disablePageWiseAggregates: false,
        };

        this.editSettings = {
            showConfirmDialog: false,
            showDeleteConfirmDialog: false,
            allowEditing: true,
            allowAdding: true,
            allowDeleting: true,
            allowEditOnDblClick: false,
            mode: 'Dialog',
        };
        this.requiredRules = {required: true};

        this.dataService.setDepartmentDataSource();
        this.dataNetbaseService.setHardwareDataSource();
    }

    ngAfterViewChecked(): void {
        this.route.queryParamMap.pipe(untilDestroyed(this))
            .subscribe(params => {
                if (params.get('form') === 'addHardware') {
                    this.isCreate = true;
                    this.renderHardwareFormDialog = true;
                    this.ref.markForCheck();
                }
            });
    }

    loadData(): DataResult[] {
        this.gridHardwares.clearRowSelection();

        console.info('NEW DATA BOUND');

        const elements: HardwareInput[] = [];

        if (this.hardwares && this.hardwares.length > 0) {
            this.hardwares.map((hardware: Hardware) => {
                elements.push({
                    id: hardware.id,
                    name: hardware.name,
                    fee: hardware.fee,
                    price: hardware.price,
                    photo: hardware.photo_thumb_url
                        ? hardware.photo_thumb_url
                        : hardware.photo_url
                            ? hardware.photo_url
                            : null,
                    created_by: hardware.creator.id,
                    creator_str: hardware.creator.fullname,
                    creator_img: hardware.creator.personalphoto,
                    created_date: new Date(hardware.created_date),
                    updated_date: hardware.updated_date ? new Date(hardware.updated_date) : null,
                    deleted_date: hardware.deleted_date ? new Date(hardware.deleted_date) : null,
                });
            });
        }

        this.tableLoad = false;
        this.ref.markForCheck();

        return elements as DataResult[];
    }

    onCreated(): void {
        this.disableToolBarBtn();
    }

    setInitialGridFiltering(): void {
        this.gridHardwares.clearFiltering();
        // this.gridHardwares.filterByColumn('created_by', 'equal', this.currentUser.id, 'and', false, true);
        // this.viewFilterInstance.value = 'moje žádanky - všechny';
        this.ref.markForCheck();
    }

    onLoad(): void {
        const wrapper1 = document.getElementById('scroll_wrapper1_hw');
        const wrapper2 = document.getElementById('scroll_wrapper2_hw');

        if (wrapper1 && wrapper2) {
            wrapper1.onscroll = (): void => {
                wrapper2.scrollLeft = wrapper1.scrollLeft;
            };

            wrapper2.onscroll = (): void => {
                wrapper1.scrollLeft = wrapper2.scrollLeft;
            };
        }

        this.dataNetbaseService.hardwareSource.pipe(untilDestroyed(this))
            .subscribe(
                (hardwares: Hardware[] | undefined) => {
                    this.tableLoad = true;
                    this.hardwares = hardwares;

                    if (this.hardwares && this.hardwares.length > 0) {
                        this.data = this.gridHardwares.dataSource = this.loadData();
                        this.sortOptions = {
                            columns: [
                                {field: 'created_date', direction: 'Descending'},
                                {field: 'id', direction: 'Descending'},
                            ],
                        };
                    }
                },
                error => {
                    console.error(error);
                    this.tableLoad = false;
                    this.ref.markForCheck();
                },
            );
    }

    search(): void {
        this.gridHardwares.search(this.searchtextObj.value);
    }

    onDataBound(): void {
        this.rowSelected();

        const width = document.getElementById('hardwaresGrid_content_table')?.offsetWidth;
        const parent1 = document.getElementById('scroll_div_hw');
        const parent2 = document.getElementById('grid_parent_hw');

        if (parent1 && parent2 && width) {
            parent1.style.width = `${width}px`;
            parent2.style.width = `${width}px`;
        }

        this.gridHardwares.autoFitColumns();
        this.ref.markForCheck();
    }

    rowDataBound(args: RowDataBoundEventArgs): void {
        const data = args.data as Hardware;

        if (args.row && data.deleted_date) {
            args.row.getElementsByClassName('e-gridchkbox')[0].classList.add('disablecheckbox');
            args.row
                .getElementsByClassName('e-checkbox-wrapper')[0]
                .classList.add('disablecheckbox');
            args.row.classList.add('e-disabled');
        }

        if (
            this.currentUser &&
            args.row &&
            data.created_by !== this.currentUser.id &&
            !this.permissionService.checkUserISServiceAdmin(this.currentUser) &&
            !this.permissionService.checkUserISAdministrative(this.currentUser) &&
            !this.permissionService.checkUserISMarketingAdmin(this.currentUser)
        ) {
            args.row.getElementsByClassName('e-gridchkbox')[0].classList.add('disablecheckbox');
            args.row
                .getElementsByClassName('e-checkbox-wrapper')[0]
                .classList.add('disablecheckbox');
        }

        this.ref.markForCheck();
    }

    onStatusFilterChange(e: ChangeEventArgs): void {
        if (e.value === 'všechny zařízení') {
            this.gridHardwares.clearFiltering();
        } else if (e.value === 'aktivní zařízení') {
            this.gridHardwares.clearFiltering();
            this.gridHardwares.filterByColumn('deleted_date', 'equal', null, 'and', false, true);
        } else if (e.value === 'neaktivní zařízení') {
            this.gridHardwares.clearFiltering();
            this.gridHardwares.filterByColumn('deleted_date', 'notequal', null, 'and', false, true);
        }
    }

    cancelBtnClick(): void {
        this.searchtextObj.value = '';
        this.gridHardwares.searchSettings.key = '';
    }

    toolbarClick(args: ClickEventArgs): void {
        const xlsProp: ExcelExportProperties = {
            fileName: 'js_hardware_export.xlsx',
            enableFilter: true,
            exportType: this.exportCurrentPage ? 'CurrentPage' : 'AllPages',
        };

        if (args.item.id === 'hardwaresGrid_excelexport') {
            void this.gridHardwares.excelExport(xlsProp);
        } else if (args.item.id === 'delete') {
            if (this.gridHardwares.getSelectedRecords().length !== 0) {
                this.confirmationDelete.content = `Potvrďte smazání ${this.clickedRow.length} zařízení`;
                this.confirmationDelete.show();
            } else {
                this.confirmationCancel.content = 'Musíte vybrat alespoň jedno zařízení';
                this.confirmationCancel.show();
            }
        } else if (args.item.id === 'copy') {
            if (this.gridHardwares.getSelectedRecords().length !== 0) {
                this.isUpdate = false;
                this.isCreate = false;
                this.isCopy = true;
                this.renderHardwareFormDialog = true;
                this.ref.markForCheck();
            } else {
                this.confirmationCancel.content = 'Musíte vybrat alespoň jedno zařízení';
                this.confirmationCancel.show();
            }
        } else if (args.item.id === 'customRefresh') {
            this.tableLoad = true;
            console.info('refresh DATA!');
            this.ref.markForCheck();
            this.dataNetbaseService.clearHardwareCache();
            this.dataNetbaseService.setHardwareDataSource();
        }
    }

    enableToolbarBtn(): void {
        this.gridHardwares.toolbarModule.enableItems(
            ['hardwaresGrid_edit', 'delete', 'copy'],
            true,
        );
    }

    disableToolBarBtn(): void {
        this.gridHardwares.toolbarModule.enableItems(
            ['hardwaresGrid_edit', 'delete', 'copy'],
            false,
        );
    }

    rowSelected(): void {
        this.clickedRow = [];

        const selectedrecords: Hardware[] = this.gridHardwares.getSelectedRecords() as Hardware[];

        if (selectedrecords.length > 0) {
            selectedrecords.map((row: Hardware) => {
                const tempData: Hardware | undefined = this.hardwares?.find(x => x.id === row.id);

                if (tempData) {
                    this.clickedRow.push(tempData);
                }

                this.ref.markForCheck();
            });

            this.clickedRow$ = new Observable<Hardware[]>((observer: Observer<Hardware[]>) => {
                observer.next(this.clickedRow);
            });
            this.enableToolbarBtn();
        } else {
            this.disableToolBarBtn();
            this.clickedRow$ = new Observable<Hardware[]>((observer: Observer<Hardware[]>) => {
                observer.next(this.clickedRow);
            });
        }
    }

    actionBegin(args): void {
        if (args.requestType === 'add' || args.requestType === 'beginEdit') {
            args.cancel = true;

            if (args.requestType === 'beginEdit') {
                this.isUpdate = true;
                this.isCreate = false;
                this.isCopy = false;
            }

            if (args.requestType === 'add') {
                this.isCreate = true;
                this.isUpdate = false;
                this.isCopy = false;
            }

            this.renderHardwareFormDialog = true;
            this.ref.markForCheck();
        }
    }

    actionComplete(args): void {
        if (args.requestType === 'beginEdit' || args.requestType === 'add') {
            args.form.ej2_instances[0].rules = {};
        }
    }

    deleteHardware(): void {
        if (
            this.currentUser &&
            (this.permissionService.checkUserISServiceAdmin(this.currentUser) ||
                this.permissionService.checkUserISAdministrative(this.currentUser) ||
                this.permissionService.checkUserISMarketingAdmin(this.currentUser))
        ) {
            this.tableLoad = true;
            this.clickedRow[0].deleted_by = this.currentUser.id;
            this.servicesService
                .deleteHardware(this.clickedRow[0])
                ?.pipe(untilDestroyed(this))
                .subscribe(
                    (hardware: Hardware) => {
                        const body = `Hardware #${hardware.id}`;
                        const options = {progressBar: true, timeOut: 5000};

                        this.messages.showSuccess('Hardware úspěšně smazán', body, options);
                        this.dataNetbaseService.setHardwareDataSource();
                        this.confirmationDelete.hide();
                        this.ref.markForCheck();
                    },
                    error => {
                        console.error(error);

                        const body = 'Zkuste to později ...';
                        const options = {progressBar: true, timeOut: 5000};

                        this.messages.showError('Chyba během mazání hardware', body, options);
                        this.tableLoad = false;
                        this.confirmationDelete.hide();
                        this.ref.markForCheck();
                    },
                );
        } else {
            const body = 'Nemáte oprávnění provést tuto akci...';
            const options = {progressBar: true, timeOut: 5000};

            this.messages.showError('Nedostatečné oprávnění', body, options);
            this.ref.markForCheck();
        }
    }
}
