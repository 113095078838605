<ejs-toolbar
  #moduleToolbar
  id="moduleToolbar"
  overflowMode="Scrollable"
  scrollStep="300"
>
  <e-items>
    <e-item [align]="'center'" tooltipText="vytvořit žádanku" type="button">
      <ng-template #template>
        <button
          (click)="navigateToTab('grid', 'addVacation')"
          [queryParams]="{ view: 'grid', form: 'addVacation' }"
          [routerLink]="['/vacation/']"
          class="e-btn e-primary btn-p-zero e-small"
          ejs-button
          title="vytvořit žádanku"
          type="button"
        >
          <i class="fa-light fa-lg fa-plus mr-2"></i>vytvořit žádanku
        </button>
      </ng-template>
    </e-item>
    <e-item type="Separator"></e-item>
    <e-item [align]="'center'" tooltipText="seznam žádanek" type="button">
      <ng-template #template>
        <button
          (click)="navigateToTab('grid', null)"
          [queryParams]="{ view: 'grid' }"
          [routerLink]="['/vacation/']"
          class="e-btn e-warning btn-p-zero e-small"
          ejs-button
          title="seznam žádanek"
          type="button"
        >
          <i class="fa-light fa-lg fa-list mr-2"></i>seznam žádanek
        </button>
      </ng-template>
    </e-item>
    <e-item type="Separator"></e-item>
    <e-item [align]="'center'" tooltipText="účetní export" type="button">
      <ng-template #template>
        <button
          (click)="exportAsAccountantXLSX()"
          *ngIf="
                        currentUser.rule &&
                        (currentUser.rule.is_admin ||
                            currentUser.rule.is_administrative ||
                            currentUser.rule.is_vacation_admin)
                    "
          cssClass="e-btn e-info e-outline btn-p-zero e-small"
          ejs-button
          type="button"
        >
          <i
            aria-hidden="true"
            class="fa-light fa-file-excel mr-1"
          ></i>
          účetní export
        </button>
      </ng-template>
    </e-item>
  </e-items>
</ejs-toolbar>
<div class="row d-flex justify-content-center modalWrapper">
  <app-vacation-accountant-export-form
    (accountantExportFormDialogState)="renderAccountantFormDialog = $event"
    [isVisible]="renderAccountantFormDialog"
    [vacations]="vacations"
  ></app-vacation-accountant-export-form>
</div>
<ejs-tab
  #vacationTab
  (created)="onCreate()"
  (selected)="select($event)"
  (selecting)="preventSwipe($event)"
  enablePersistence="true"
  height="auto"
  heightAdjustMode="Content"
  id="vacationTab"
>
  <e-tabitems>
    <e-tabitem [header]="headerText[0]">
      <ng-template #content>
        <app-vacation-queue></app-vacation-queue>
      </ng-template>
    </e-tabitem>
    <e-tabitem [header]="headerText[1]">
      <ng-template #content>
        <app-vacation-calendar></app-vacation-calendar>
      </ng-template>
    </e-tabitem>
    <e-tabitem [header]="headerText[2]">
      <ng-template #content>
        <div class="control-section">
          <!--    <div style="padding:5px;text-align: right;">
                  <button ejs-button id="add" class="e-btn e-info" (click)="addPanel()">Add Panel</button>
              </div>-->
          <div
            class="mb-3"
            id="dashBoardParent"
            style="width: 100% !important"
          >
            <ejs-dashboardlayout
              #default_dashboard
              [allowDragging]="true"
              [allowResizing]="true"
              [cellAspectRatio]="cellAspectRatio"
              [cellSpacing]="cellSpacing"
              [enablePersistence]="false"
              columns="4"
              id="vacReports_dashboard"
            >
              <e-panels>
                <e-panel
                  [col]="0"
                  [row]="0"
                  [sizeX]="4"
                  [sizeY]="1"
                >
                  <ng-template #header>
                    <span>Statistiky fondů absencí</span>
                  </ng-template>
                  <ng-template #content>
                    <i
                      (click)="onCloseIconHandler($event)"
                      aria-hidden="true"
                      class="fa-light fa-lg fa-time"
                    ></i>
                    <div
                      class="e-panel-container scrollbar scrollbar-indigo thin p-2"
                    >
                      <div
                        *ngIf="loadingVacationWidget"
                        class="row"
                      >
                        <div class="col-12 text-center">
                          <small class="text-muted"
                          >načítám data</small
                          >
                        </div>
                      </div>
                      <div
                        *ngIf="
                                                    !loadingVacationWidget &&
                                                    currentUser.vacation_fond
                                                "
                      >
                        <table
                          *ngIf="
                                                        currentUser.vacation_fond >=
                                                        0
                                                    "
                          class="table table-sm table-borderless mb-1"
                        >
                          <tbody>
                          <tr>
                            <th>
                              Fond dovolené na
                              rok:
                              <strong>{{
                                  today
                                    | date
                                    : 'yyyy'
                                }}</strong>
                            </th>
                            <th
                              class="text-center text-primary"
                            >
                              <strong
                                [ngPlural]="
                                                                        currentUser.vacation_fond
                                                                    "
                              >
                                celkem:&nbsp;
                                <ng-template
                                  ngPluralCase="=1"
                                >{{
                                    currentUser.vacation_fond
                                      | number
                                      : '1.1-1'
                                  }}
                                  den
                                  dovolené
                                </ng-template>
                                <ng-template
                                  ngPluralCase="=2"
                                >{{
                                    currentUser.vacation_fond
                                      | number
                                      : '1.1-1'
                                  }}
                                  dny
                                  dovolené
                                </ng-template>
                                <ng-template
                                  ngPluralCase="=3"
                                >{{
                                    currentUser.vacation_fond
                                      | number
                                      : '1.1-1'
                                  }}
                                  dny
                                  dovolené
                                </ng-template>
                                <ng-template
                                  ngPluralCase="=4"
                                >{{
                                    currentUser.vacation_fond
                                      | number
                                      : '1.1-1'
                                  }}
                                  dny
                                  dovolené
                                </ng-template>
                                <ng-template
                                  ngPluralCase="other"
                                >{{
                                    currentUser.vacation_fond
                                      | number
                                      : '1.1-1'
                                  }}
                                  dní
                                  dovolené
                                </ng-template>
                              </strong>
                            </th>
                            <th
                              class="text-right"
                            >
                              <strong
                                [ngPlural]="currentUser.vacation_fond - (stats.vacation.confirmed + stats.vacation.queueCnt)"
                                class="text-danger"
                              >
                                zbývá:&nbsp;
                                <ng-template
                                  ngPluralCase="=1"
                                >{{
                                    currentUser.vacation_fond -
                                    (stats.vacation.confirmed + stats.vacation.queueCnt) | number: '1.1-1'
                                  }}
                                  den
                                  dovolené
                                </ng-template>
                                <ng-template
                                  ngPluralCase="=2"
                                >{{
                                    currentUser.vacation_fond -
                                    (stats.vacation.confirmed + stats.vacation.queueCnt) | number: '1.1-1'
                                  }}
                                  dny
                                  dovolené
                                </ng-template>
                                <ng-template
                                  ngPluralCase="=3"
                                >{{
                                    currentUser.vacation_fond -
                                    (stats.vacation.confirmed + stats.vacation.queueCnt) | number: '1.1-1'
                                  }}
                                  dny
                                  dovolené
                                </ng-template>
                                <ng-template
                                  ngPluralCase="=4"
                                >{{
                                    currentUser.vacation_fond -
                                    (stats.vacation.confirmed + stats.vacation.queueCnt) | number: '1.1-1'
                                  }}
                                  dny
                                  dovolené
                                </ng-template>
                                <ng-template
                                  ngPluralCase="other"
                                >{{
                                    currentUser.vacation_fond -
                                    (stats.vacation.confirmed + stats.vacation.queueCnt) | number: '1.1-1'
                                  }}
                                  dní
                                  dovolené
                                </ng-template>
                              </strong
                              ><br />
                              <strong
                                [ngPlural]="
                                                                        stats
                                                                            .vacation
                                                                            .queueCnt
                                                                    "
                                class="text-warning"
                              >
                                ve
                                frontě:&nbsp;
                                <ng-template
                                  ngPluralCase="=1"
                                >{{
                                    stats
                                      .vacation
                                      .queueCnt
                                      | number
                                      : '1.1-1'
                                  }}
                                  den
                                  dovolené
                                </ng-template>
                                <ng-template
                                  ngPluralCase="=2"
                                >{{
                                    stats
                                      .vacation
                                      .queueCnt
                                      | number
                                      : '1.1-1'
                                  }}
                                  dny
                                  dovolené
                                </ng-template>
                                <ng-template
                                  ngPluralCase="=3"
                                >{{
                                    stats
                                      .vacation
                                      .queueCnt
                                      | number
                                      : '1.1-1'
                                  }}
                                  dny
                                  dovolené
                                </ng-template>
                                <ng-template
                                  ngPluralCase="=4"
                                >{{
                                    stats
                                      .vacation
                                      .queueCnt
                                      | number
                                      : '1.1-1'
                                  }}
                                  dny
                                  dovolené
                                </ng-template>
                                <ng-template
                                  ngPluralCase="other"
                                >{{
                                    stats
                                      .vacation
                                      .queueCnt
                                      | number
                                      : '1.1-1'
                                  }}
                                  dní
                                  dovolené
                                </ng-template>
                              </strong
                              ><br />
                              <strong
                                [ngPlural]="
                                                                        stats
                                                                            .vacation
                                                                            .confirmed
                                                                    "
                                class="text-success"
                              >
                                schváleno:&nbsp;
                                <ng-template
                                  ngPluralCase="=1"
                                >{{
                                    stats
                                      .vacation
                                      .confirmed
                                      | number
                                      : '1.1-1'
                                  }}
                                  den
                                  dovolené
                                </ng-template>
                                <ng-template
                                  ngPluralCase="=2"
                                >{{
                                    stats
                                      .vacation
                                      .confirmed
                                      | number
                                      : '1.1-1'
                                  }}
                                  dny
                                  dovolené
                                </ng-template>
                                <ng-template
                                  ngPluralCase="=3"
                                >{{
                                    stats
                                      .vacation
                                      .confirmed
                                      | number
                                      : '1.1-1'
                                  }}
                                  dny
                                  dovolené
                                </ng-template>
                                <ng-template
                                  ngPluralCase="=4"
                                >{{
                                    stats
                                      .vacation
                                      .confirmed
                                      | number
                                      : '1.1-1'
                                  }}
                                  dny
                                  dovolené
                                </ng-template>
                                <ng-template
                                  ngPluralCase="other"
                                >{{
                                    stats
                                      .vacation
                                      .confirmed
                                      | number
                                      : '1.1-1'
                                  }}
                                  dní
                                  dovolené
                                </ng-template>
                              </strong>
                            </th>
                          </tr>
                          </tbody>
                        </table>
                        <div
                          *ngIf="
                                                        currentUser.vacation_fond
                                                    "
                          class="row"
                        >
                          <div class="col-12 w-100">
                            <ejs-progressbar
                              #vacationsPercentage
                              (valueChanged)="
                                                                changedVacBar(
                                                                    $event
                                                                )
                                                            "
                              [animation]="
                                                                animation
                                                            "
                              [labelStyle]="
                                                                labelStyle
                                                            "
                              [showProgressValue]="
                                                                showProgressValue
                                                            "
                              [value]="
                                                                (stats.vacation
                                                                    .confirmed *
                                                                    100) /
                                                                currentUser.vacation_fond
                                                            "
                              [width]="'100%'"
                              height="50"
                              id="vacationsPercentage"
                              maximum="100"
                              minimum="0"
                              progressThickness="15"
                              trackThickness="15"
                              type="Linear"
                            ></ejs-progressbar>
                          </div>
                        </div>
                        <table
                          *ngIf="
                                                        currentUser.sickdays_fond >=
                                                        0
                                                    "
                          class="table table-sm table-borderless mb-1"
                        >
                          <tbody>
                          <tr>
                            <th>
                              Fond sickdays na
                              rok:
                              <strong>{{
                                  today
                                    | date
                                    : 'yyyy'
                                }}</strong>
                            </th>
                            <th
                              class="text-center text-primary"
                            >
                              <strong
                                [ngPlural]="
                                                                        currentUser.sickdays_fond
                                                                    "
                              >
                                celkem:&nbsp;
                                <ng-template
                                  ngPluralCase="=1"
                                >{{
                                    currentUser.sickdays_fond
                                      | number
                                      : '1.1-1'
                                  }}
                                  den
                                  sickdays
                                </ng-template>
                                <ng-template
                                  ngPluralCase="=2"
                                >{{
                                    currentUser.sickdays_fond
                                      | number
                                      : '1.1-1'
                                  }}
                                  dny
                                  sickdays
                                </ng-template>
                                <ng-template
                                  ngPluralCase="=3"
                                >{{
                                    currentUser.sickdays_fond
                                      | number
                                      : '1.1-1'
                                  }}
                                  dny
                                  sickdays
                                </ng-template>
                                <ng-template
                                  ngPluralCase="=4"
                                >{{
                                    currentUser.sickdays_fond
                                      | number
                                      : '1.1-1'
                                  }}
                                  dny
                                  sickdays
                                </ng-template>
                                <ng-template
                                  ngPluralCase="other"
                                >{{
                                    currentUser.sickdays_fond
                                      | number
                                      : '1.1-1'
                                  }}
                                  dní
                                  sickdays
                                </ng-template>
                              </strong>
                            </th>
                            <th
                              class="text-right"
                            >
                              <strong
                                [ngPlural]="currentUser.sickdays_fond - (stats.sickdays.confirmed + stats.sickdays.queueCnt)"
                                class="text-danger"
                              >
                                zbývá:&nbsp;
                                <ng-template
                                  ngPluralCase="=1"
                                >{{
                                    currentUser.sickdays_fond -
                                    (stats.sickdays.confirmed + stats.sickdays.queueCnt) | number: '1.1-1'
                                  }}
                                  den
                                  sickdays
                                </ng-template>
                                <ng-template
                                  ngPluralCase="=2"
                                >{{
                                    currentUser.sickdays_fond -
                                    (stats.sickdays.confirmed + stats.sickdays.queueCnt) | number: '1.1-1'
                                  }}
                                  dny
                                  sickdays
                                </ng-template>
                                <ng-template
                                  ngPluralCase="=3"
                                >{{
                                    currentUser.sickdays_fond -
                                    (stats.sickdays.confirmed + stats.sickdays.queueCnt) | number: '1.1-1'
                                  }}
                                  dny
                                  sickdays
                                </ng-template>
                                <ng-template
                                  ngPluralCase="=4"
                                >{{
                                    currentUser.sickdays_fond -
                                    (stats.sickdays.confirmed + stats.sickdays.queueCnt) | number: '1.1-1'
                                  }}
                                  dny
                                  sickdays
                                </ng-template>
                                <ng-template
                                  ngPluralCase="other"
                                >{{
                                    currentUser.sickdays_fond -
                                    (stats.sickdays.confirmed + stats.sickdays.queueCnt) | number: '1.1-1'
                                  }}
                                  dní
                                  sickdays
                                </ng-template>
                              </strong
                              ><br />
                              <strong
                                [ngPlural]="
                                                                        stats
                                                                            .sickdays
                                                                            .queueCnt
                                                                    "
                                class="text-warning"
                              >
                                ve
                                frontě:&nbsp;
                                <ng-template
                                  ngPluralCase="=1"
                                >{{
                                    stats
                                      .sickdays
                                      .queueCnt
                                      | number
                                      : '1.1-1'
                                  }}
                                  den
                                  sickdays
                                </ng-template>
                                <ng-template
                                  ngPluralCase="=2"
                                >{{
                                    stats
                                      .sickdays
                                      .queueCnt
                                      | number
                                      : '1.1-1'
                                  }}
                                  dny
                                  sickdays
                                </ng-template>
                                <ng-template
                                  ngPluralCase="=3"
                                >{{
                                    stats
                                      .sickdays
                                      .queueCnt
                                      | number
                                      : '1.1-1'
                                  }}
                                  dny
                                  sickdays
                                </ng-template>
                                <ng-template
                                  ngPluralCase="=4"
                                >{{
                                    stats
                                      .sickdays
                                      .queueCnt
                                      | number
                                      : '1.1-1'
                                  }}
                                  dny
                                  sickdays
                                </ng-template>
                                <ng-template
                                  ngPluralCase="other"
                                >{{
                                    stats
                                      .sickdays
                                      .queueCnt
                                      | number
                                      : '1.1-1'
                                  }}
                                  dní
                                  sickdays
                                </ng-template>
                              </strong
                              ><br />
                              <strong
                                [ngPlural]="
                                                                        stats
                                                                            .sickdays
                                                                            .confirmed
                                                                    "
                                class="text-success"
                              >
                                schváleno:&nbsp;
                                <ng-template
                                  ngPluralCase="=1"
                                >{{
                                    stats
                                      .sickdays
                                      .confirmed
                                      | number
                                      : '1.1-1'
                                  }}
                                  den
                                  sickdays
                                </ng-template>
                                <ng-template
                                  ngPluralCase="=2"
                                >{{
                                    stats
                                      .sickdays
                                      .confirmed
                                      | number
                                      : '1.1-1'
                                  }}
                                  dny
                                  sickdays
                                </ng-template>
                                <ng-template
                                  ngPluralCase="=3"
                                >{{
                                    stats
                                      .sickdays
                                      .confirmed
                                      | number
                                      : '1.1-1'
                                  }}
                                  dny
                                  sickdays
                                </ng-template>
                                <ng-template
                                  ngPluralCase="=4"
                                >{{
                                    stats
                                      .sickdays
                                      .confirmed
                                      | number
                                      : '1.1-1'
                                  }}
                                  dny
                                  sickdays
                                </ng-template>
                                <ng-template
                                  ngPluralCase="other"
                                >{{
                                    stats
                                      .sickdays
                                      .confirmed
                                      | number
                                      : '1.1-1'
                                  }}
                                  dní
                                  sickdays
                                </ng-template>
                              </strong>
                            </th>
                          </tr>
                          </tbody>
                        </table>
                        <div
                          *ngIf="
                                                        currentUser.sickdays_fond
                                                    "
                          class="row"
                        >
                          <div class="col-12 w-100">
                            <ejs-progressbar
                              #sickDaysPercentage
                              (valueChanged)="
                                                                changedSickBar(
                                                                    $event
                                                                )
                                                            "
                              [animation]="
                                                                animation
                                                            "
                              [labelStyle]="
                                                                labelStyle
                                                            "
                              [showProgressValue]="
                                                                showProgressValue
                                                            "
                              [value]="
                                                                ((stats.sickdays
                                                                    .confirmed +
                                                                    stats
                                                                        .sickdays
                                                                        .queueCnt) *
                                                                    100) /
                                                                currentUser.sickdays_fond
                                                            "
                              [width]="'100%'"
                              height="50"
                              id="sickDaysPercentage"
                              maximum="100"
                              minimum="0"
                              progressThickness="15"
                              trackThickness="15"
                              type="Linear"
                            ></ejs-progressbar>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ng-template>
                </e-panel>
                <e-panel
                  [col]="0"
                  [row]="1"
                  [sizeX]="2"
                  [sizeY]="1"
                >
                  <ng-template #content>
                    <i
                      (click)="onCloseIconHandler($event)"
                      aria-hidden="true"
                      class="fa-light fa-lg fa-time"
                    ></i>
                    <div
                      class="e-panel-container scrollbar scrollbar-indigo thin p-2"
                    >
                      <ng-container *ngIf="vacationsData">
                        <div class="row">
                          <div
                            *ngIf="
                                                            loadingVacationWidget
                                                        "
                            class="col-4"
                          >
                            <app-loader></app-loader>
                          </div>
                          <div
                            *ngIf="
                                                            !loadingVacationWidget
                                                        "
                            class="col-4"
                          >
                                                        <span class="text-muted"
                                                        >Moje Žádosti</span
                                                        >
                            <span
                              class="badge badge-danger ml-2"
                            >{{
                                vacationsData.length
                              }}</span
                            >
                            <span class="sr-only"
                            >moje žádosti ve
                                                            frontě</span
                            >
                          </div>
                          <div
                            class="col-8 text-right"
                          >
                            <small
                              class="text-muted"
                            >za tento rok</small
                            >
                          </div>
                        </div>
                        <hr />
                        <div
                          *ngIf="
                                                        loadingVacationWidget
                                                    "
                          class="row"
                        >
                          <div
                            class="col-12 text-center"
                          >
                            <small
                              class="text-muted"
                            >načítám data</small
                            >
                          </div>
                        </div>
                        <div
                          *ngIf="
                                                        !loadingVacationWidget &&
                                                        chartData
                                                    "
                          class="d-flex col-12 justify-content-center"
                        >
                          <div
                            style="
                                                            display: block;
                                                            width: 100%;
                                                            height: auto;
                                                        "
                          >
                            <ejs-chart
                              #vacationsChart
                              [legendSettings]="
                                                                legendSettings
                                                            "
                              [margin]="{
                                                                left: 5,
                                                                right: 5,
                                                                top: 5,
                                                                bottom: 5
                                                            }"
                              [primaryXAxis]="
                                                                primaryXAxis
                                                            "
                              [primaryYAxis]="
                                                                primaryYAxis
                                                            "
                              [tooltip]="tooltip"
                              id="vacationsChart"
                              style="
                                                                display: block;
                                                            "
                            >
                              <e-series-collection>
                                <e-series
                                  [dataSource]="
                                                                        chartData
                                                                    "
                                  name="Dovolená"
                                  type="Bar"
                                  xName="month"
                                  yName="days"
                                ></e-series>
                              </e-series-collection>
                            </ejs-chart>
                          </div>
                        </div>
                        <div
                          *ngIf="
                                                        !loadingVacationWidget
                                                    "
                          class="table-responsive p-2"
                          style="
                                                        max-height: 400px;
                                                        overflow-y: auto;
                                                    "
                        >
                          <table
                            *ngIf="
                                                            vacationsData.length >
                                                            0
                                                        "
                            class="table mb-1"
                          >
                            <tbody>
                            <tr>
                              <th
                              >typ/stav
                              </th>
                              <th
                              >prac. dny
                              </th>
                              <th>začátek</th>
                              <th>konec</th>
                              <th></th>
                            </tr>
                            <tr
                              *ngFor="
                                                                    let item of vacationsData;
                                                                    let i = index
                                                                "
                            >
                              <td>
                                                                    <span
                                                                      [ngStyle]="{
                                                                            'background-color':
                                                                                item
                                                                                    .type
                                                                                    .colortag,
                                                                            color: '#ffffff'
                                                                        }"
                                                                      class="e-badge"
                                                                    >{{
                                                                        item
                                                                          .type
                                                                          .name
                                                                      }}</span
                                                                    >
                                <span
                                  *ngIf="
                                                                            item.status ===
                                                                            'confirmed'
                                                                        "
                                  class="e-badge e-badge-success"
                                >schváleno</span
                                >
                                <span
                                  *ngIf="
                                                                            item.status ===
                                                                            'declined'
                                                                        "
                                  class="e-badge e-badge-danger"
                                >zamítnuto</span
                                >
                                <span
                                  *ngIf="
                                                                            item.status ===
                                                                            'deleted'
                                                                        "
                                  class="e-badge e-badge-danger"
                                >staženo</span
                                >
                                <span
                                  *ngIf="
                                                                            item.status ===
                                                                            'queue'
                                                                        "
                                  class="e-badge e-badge-warning"
                                >vefrontě</span
                                >
                              </td>
                              <td>{{
                                  item.workdays
                                }}
                              </td>
                              <td>
                                {{
                                  item.start_at
                                    | dateFormat
                                    : 'DD.MM.YYYY HH:mm'
                                }}
                              </td>
                              <td>
                                {{
                                  item.end_at
                                    | dateFormat
                                    : 'DD.MM.YYYY HH:mm'
                                }}
                              </td>
                              <td
                                class="text-center"
                              >
                                <a
                                  [routerLink]="[
                                                                            '/vacation/detail/',
                                                                            item.id
                                                                        ]"
                                >
                                  <i
                                    aria-hidden="true"
                                    class="fa-light fa-search"
                                  ></i>
                                </a>
                              </td>
                            </tr>
                            </tbody>
                          </table>
                        </div>
                        ({{ vacationsData.length }})
                      </ng-container>
                    </div>
                  </ng-template>
                </e-panel>
                <e-panel
                  [col]="2"
                  [row]="1"
                  [sizeX]="2"
                  [sizeY]="1"
                >
                  <ng-template #content>
                    <i
                      (click)="onCloseIconHandler($event)"
                      aria-hidden="true"
                      class="fa-light fa-lg fa-time"
                    ></i>
                    <div
                      class="e-panel-container scrollbar scrollbar-indigo thin p-2"
                    >
                      <ng-container
                        *ngIf="
                                                    api &&
                                                    api.active &&
                                                    api.state &&
                                                    acsUserLogs
                                                "
                      >
                        <div
                          class="row d-flex justify-content-between"
                        >
                          <div
                            *ngIf="loadingAcs"
                            class="col-4"
                          >
                            <app-loader></app-loader>
                          </div>
                          <div
                            *ngIf="!loadingAcs"
                            class="col-4"
                          >
                                                        <span class="text-muted"
                                                        >Acs ERP</span
                                                        >
                            <span
                              class="badge badge-danger ml-2"
                            >{{
                                acsUserLogs.length
                              }}</span
                            >
                            <span class="sr-only"
                            >Logy z Acs
                                                            ERP</span
                            >
                          </div>
                          <div
                            class="col-8 text-right"
                          >
                            <small
                              class="text-muted"
                            >za tento
                              měsíc</small
                            >
                          </div>
                        </div>
                        <hr />
                        <div
                          *ngIf="loadingAcs"
                          class="row"
                        >
                          <div
                            class="col-12 text-center"
                          >
                            <small
                              class="text-muted"
                            >načítám
                              data...</small
                            >
                          </div>
                        </div>
                        <div
                          *ngIf="!loadingAcs"
                          class="table-responsive p-2"
                          style="
                                                        max-height: 400px;
                                                        overflow-y: auto;
                                                    "
                        >
                          <table
                            *ngIf="
                                                            acsUserLogs.length >
                                                            0
                                                        "
                            class="table mb-1"
                          >
                            <tbody>
                            <tr>
                              <th
                              >typ/stav
                              </th>
                              <th>začátek</th>
                              <th>konec</th>
                            </tr>
                            <tr
                              *ngFor="
                                                                    let log of acsUserLogs
                                                                "
                            >
                              <td
                                style="
                                                                        text-align: left !important;
                                                                    "
                              >
                                                                    <span
                                                                      *ngIf="
                                                                            log.category &&
                                                                            log
                                                                                .category
                                                                                .name ===
                                                                                'Work Time'
                                                                        "
                                                                      class="e-badge ml-1 e-badge-primary"
                                                                    >na
                                                                        pracovišti</span
                                                                    >
                                <span
                                  *ngIf="
                                                                            log.category &&
                                                                            log
                                                                                .category
                                                                                .name ===
                                                                                'Výjezd'
                                                                        "
                                  class="e-badge ml-1 e-badge-primary"
                                >výjezd</span
                                >
                                <span
                                  *ngIf="
                                                                            log.category &&
                                                                            log
                                                                                .category
                                                                                .name ===
                                                                                'HomeOffice'
                                                                        "
                                  class="e-badge ml-1 e-badge-primary"
                                >home-office</span
                                >
                                <span
                                  *ngIf="
                                                                            log.category &&
                                                                            log
                                                                                .category
                                                                                .name ===
                                                                                'Collective Dinner'
                                                                        "
                                  class="e-badge ml-1 e-badge-primary"
                                >firemní
                                                                        oběd/večeře</span
                                >
                                <span
                                  *ngIf="
                                                                            log.category &&
                                                                            (log
                                                                                .category
                                                                                .name ===
                                                                                'Break' ||
                                                                                log
                                                                                    .category
                                                                                    .name ===
                                                                                    'Break (auto)')
                                                                        "
                                  class="e-badge ml-1 e-badge-warning"
                                >přestávka</span
                                >
                                <span
                                  *ngIf="
                                                                            log.category &&
                                                                            log
                                                                                .category
                                                                                .name ===
                                                                                'Cigarette Break'
                                                                        "
                                  class="e-badge ml-1 e-badge-warning"
                                >kouřová
                                                                        přestávka</span
                                >
                                <span
                                  *ngIf="
                                                                            log.category &&
                                                                            (log
                                                                                .category
                                                                                .name ===
                                                                                'Dinner' ||
                                                                                log
                                                                                    .category
                                                                                    .name ===
                                                                                    'Dinner (auto)')
                                                                        "
                                  class="e-badge ml-1 e-badge-primary"
                                >přestávka
                                                                        na
                                                                        občerstvení</span
                                >
                                <span
                                  *ngIf="
                                                                            log.category &&
                                                                            log
                                                                                .category
                                                                                .name ===
                                                                                'Refunded overtime'
                                                                        "
                                  class="e-badge ml-1 e-badge-success"
                                >přesčas</span
                                >
                                <span
                                  *ngIf="
                                                                            log.category &&
                                                                            (log
                                                                                .category
                                                                                .name ===
                                                                                'Lunch 2' ||
                                                                                log
                                                                                    .category
                                                                                    .name ===
                                                                                    'Lunch 1')
                                                                        "
                                  class="e-badge ml-1 e-badge-warning"
                                >Oběd</span
                                >
                                <span
                                  *ngIf="
                                                                            log.category &&
                                                                            log
                                                                                .category
                                                                                .name ===
                                                                                'Pass'
                                                                        "
                                  class="e-badge ml-1 e-badge-info"
                                >pass</span
                                >
                                <span
                                  *ngIf="
                                                                            log.category &&
                                                                            log
                                                                                .category
                                                                                .name ===
                                                                                'Service'
                                                                        "
                                  class="e-badge ml-1 e-badge-info"
                                >service</span
                                >
                                <span
                                  *ngIf="
                                                                            log.category &&
                                                                            log
                                                                                .category
                                                                                .name ===
                                                                                'Half-day Vacation'
                                                                        "
                                  class="e-badge ml-1 e-badge-warning"
                                >půl den
                                                                        dovolené</span
                                >
                                <span
                                  *ngIf="
                                                                            log.category &&
                                                                            log
                                                                                .category
                                                                                .name ===
                                                                                'Private Leave'
                                                                        "
                                  class="e-badge ml-1 e-badge-warning"
                                >soukromé
                                                                        volno</span
                                >
                                <span
                                  *ngIf="
                                                                            log.category &&
                                                                            log
                                                                                .category
                                                                                .name ===
                                                                                'Study Leave'
                                                                        "
                                  class="e-badge ml-1 e-badge-warning"
                                >studijní
                                                                        volno</span
                                >
                                <span
                                  *ngIf="
                                                                            log.category &&
                                                                            log
                                                                                .category
                                                                                .name ===
                                                                                'Absence'
                                                                        "
                                  class="e-badge ml-1 e-badge-warning"
                                >absence</span
                                >
                                <span
                                  *ngIf="
                                                                            log.category &&
                                                                            log
                                                                                .category
                                                                                .name ===
                                                                                'External Work'
                                                                        "
                                  class="e-badge ml-1 e-badge-primary"
                                >externí
                                                                        práce</span
                                >
                                <span
                                  *ngIf="
                                                                            log.category &&
                                                                            log
                                                                                .category
                                                                                .name ===
                                                                                'Medical Examination with Family Member'
                                                                        "
                                  class="e-badge ml-1 e-badge-danger"
                                >doprovod
                                                                        k
                                                                        lékaři</span
                                >
                                <span
                                  *ngIf="
                                                                            log.category &&
                                                                            log
                                                                                .category
                                                                                .name ===
                                                                                'Family Member Care'
                                                                        "
                                  class="e-badge ml-1 e-badge-danger"
                                >péče o
                                                                        člena
                                                                        rodiny</span
                                >
                                <span
                                  *ngIf="
                                                                            log.category &&
                                                                            log
                                                                                .category
                                                                                .name ===
                                                                                'Work Trip'
                                                                        "
                                  class="e-badge ml-1 e-badge-primary"
                                >pracovní
                                                                        cesta</span
                                >
                                <span
                                  *ngIf="
                                                                            log.category &&
                                                                            log
                                                                                .category
                                                                                .name ===
                                                                                'Training'
                                                                        "
                                  class="e-badge ml-1 e-badge-primary"
                                >školení</span
                                >
                                <span
                                  *ngIf="
                                                                            log.category &&
                                                                            log
                                                                                .category
                                                                                .name ===
                                                                                'Sick Leave'
                                                                        "
                                  class="e-badge ml-1 e-badge-warning"
                                >sick
                                                                        day</span
                                >
                                <span
                                  *ngIf="
                                                                            log.category &&
                                                                            log
                                                                                .category
                                                                                .name ===
                                                                                'Medical Examination'
                                                                        "
                                  class="e-badge ml-1 e-badge-danger"
                                >lékař</span
                                >
                                <span
                                  *ngIf="
                                                                            log.category &&
                                                                            log
                                                                                .category
                                                                                .name ===
                                                                                'Vacation'
                                                                        "
                                  class="e-badge ml-1 e-badge-warning"
                                >dovolená</span
                                >
                                <span
                                  *ngIf="
                                                                            log.category &&
                                                                            log
                                                                                .category
                                                                                .name ===
                                                                                'Holiday'
                                                                        "
                                  class="e-badge ml-1 e-badge-warning"
                                >svátek</span
                                >
                                <span
                                  *ngIf="
                                                                            !log.finished_at &&
                                                                            !log.finished_at_real &&
                                                                            (log.started_at
                                                                                | date
                                                                                    : 'yyyy-MM-dd') ===
                                                                                today
                                                                        "
                                  class="e-badge ml-1 e-badge-warning"
                                >probíhá</span
                                >
                                <span
                                  *ngIf="
                                                                            !log.finished_at &&
                                                                            !log.finished_at_real &&
                                                                            (log.started_at
                                                                                | date
                                                                                    : 'yyyy-MM-dd') !==
                                                                                today
                                                                        "
                                  class="e-badge ml-1 e-badge-danger"
                                >odhlášen
                                                                        automaticky</span
                                >
                                <span
                                  *ngIf="
                                                                            log.finished_at ||
                                                                            log.finished_at_real
                                                                        "
                                  class="e-badge ml-1 e-badge-success"
                                >ukončeno</span
                                >
                              </td>
                              <td>
                                {{
                                  log.started_at
                                    ? (log.started_at
                                      | dateFormat
                                      : 'DD.MM.YYYY HH:mm')
                                    :(log.started_at_real
                                      | dateFormat
                                      : 'DD.MM.YYYY HH:mm')
                                }}
                              </td>
                              <td>
                                {{
                                  log.finished_at ||
                                  log.finished_at_real
                                    ? log.finished_at
                                      ? (log.finished_at
                                        | dateFormat
                                        : 'DD.MM.YYYY HH:mm')
                                      :(log.finished_at_real
                                        | dateFormat
                                        : 'DD.MM.YYYY HH:mm')
                                    :'--.--.---- --:--'
                                }}
                              </td>
                            </tr>
                            </tbody>
                          </table>
                        </div>
                        ({{ acsUserLogs.length }})
                      </ng-container>
                    </div>
                  </ng-template>
                </e-panel>
              </e-panels>
            </ejs-dashboardlayout>
          </div>
        </div>
      </ng-template>
    </e-tabitem>
    <e-tabitem [disabled]="true" [header]="headerText[3]">
      <ng-template #content></ng-template>
    </e-tabitem>
  </e-tabitems>
</ejs-tab>
