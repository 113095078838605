import {ChangeDetectionStrategy, ChangeDetectorRef, Component, HostListener, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {Tickets} from '@src/app/_models/ticket/tickets';
import {Task} from '@src/app/_models/task/task';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MessageService} from '@src/app/_services/message.service';
import {TicketComment} from '@src/app/_models/ticket/ticket-comment';
import {AuthenticationService} from '@src/app/_services/authentication.service';
import {User} from '@src/app/_models/user/user';
import {TicketFile} from '@src/app/_models/ticket/ticket-file';
import {Department} from '@src/app/_models/department/department';
import {Assignation} from '@src/app/_models/assignation/assignation';
import {distinctUntilChanged, Observable, Observer} from 'rxjs';
import {TicketNotes} from '@src/app/_models/ticket/ticket-notes';
import {DataService} from '@src/app/_services/data.service';
import {TimeagoIntl} from 'ngx-timeago';
import {strings as czechStrings} from 'ngx-timeago/language-strings/cs';
import {registerLocaleData} from '@angular/common';
import {SelectEventArgs} from '@syncfusion/ej2-navigations';
import {ClickEventArgs, TabComponent} from '@syncfusion/ej2-angular-navigations';
import {TextBoxComponent} from '@syncfusion/ej2-angular-inputs';
import {EmitType} from '@syncfusion/ej2-base';
import {ButtonPropsModel, DialogComponent} from '@syncfusion/ej2-angular-popups';
import {
    DataResult,
    ExcelExportProperties,
    FilterSettingsModel,
    GridComponent,
    GroupSettingsModel,
    PageSettingsModel,
    RowDataBoundEventArgs,
    SelectionSettingsModel,
    ToolbarItems,
} from '@syncfusion/ej2-angular-grids';
import {RichTextEditorComponent} from '@syncfusion/ej2-angular-richtexteditor';
import moment from 'moment';
import localeCs from '@angular/common/locales/cs';
import {PermissionService} from '@src/app/_services/permission.service';
import {ProgressBarComponent} from '@syncfusion/ej2-angular-progressbar';
import {
    ChangeEventArgs,
    DropDownListComponent,
    FilteringEventArgs,
    MultiSelectComponent,
} from '@syncfusion/ej2-angular-dropdowns';
import {Query} from '@syncfusion/ej2-data';
import {TicketLogs} from '@src/app/_models/ticket/ticket-logs';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {ComponentCanDeactivate} from '@src/app/_guards/changes.guard';
import {map} from 'rxjs/operators';
import {GalleryImages} from '@src/app/_models/images/images.type';
import {TaskGridItems, TasksService} from '@src/app/features/tasks/tasks.service';
import {CommentsService} from '@src/app/shared_components/services/comments.service';
import {
    TicketInput,
    TicketLogsItems,
    TicketNoteInput,
    TicketsService,
} from '@src/app/features/tickets/tickets.service';
import {Lightbox} from 'ngx-lightbox';

moment.updateLocale('cs', {workingWeekdays: [1, 2, 3, 4, 5]});
registerLocaleData(localeCs);

@UntilDestroy()
@Component({
    selector: 'app-ticket-detail',
    templateUrl: './ticket-detail.component.html',
    styleUrls: ['./ticket-detail.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TicketDetailComponent implements OnInit, ComponentCanDeactivate {
    // Navigations
    headerText = [
        {text: 'Zadání'},
        {text: 'Úkoly'},
        {text: 'Soubory'},
        {text: 'Historie'},
    ];

    // Grid
    pageSettings: object;

    initialTasksSort: object;

    dateFormat: {type: string; format: string} = {type: 'dateTime', format: 'dd.MM.yyyy HH:mm'};

    fields: object = {text: 'label', value: 'value'};

    elements: DataResult[] | null;

    filterSettings: FilterSettingsModel;

    groupOptions: GroupSettingsModel;

    selectionSettings: SelectionSettingsModel;

    toolbar: ToolbarItems[] | object;

    toolbarHistory: ToolbarItems[] | object;

    filterDate: object;

    sortHistoryOptions: object;

    requiredRules: object;

    dataHistory: object[] = [];

    pageOptions: PageSettingsModel;

    exportCurrentHistoryPage = false;

    // Forms
    createSolvingForm: FormGroup;

    addSolverUserForm: FormGroup;

    addSolverDepartmentForm: FormGroup;

    isDirty = false;

    isCreate = false;

    isCopy = false;

    isUpdate = false;

    isTaskCreate = false;

    isTaskCopy = false;

    isTaskUpdate = false;

    isCreateNote = false;

    isCopyNote = false;

    isUpdateNote = false;

    isCreateSolution = false;

    isCopySolution = false;

    isUpdateSolution = false;

    isCreateComment = false;

    isCopyComment = false;

    isUpdateComment = false;

    // Variables
    currentUser: User | null;

    users: User[] | undefined = [];

    contentPrinted: boolean;

    contentSearched: number;

    submited = false;

    ticket: Tickets | null | undefined;

    tasks: Task[] = [];

    logs: TicketLogs[] | undefined = [];

    highlightedTaskRow: Task | null = null;

    highlightedUserRow: User;

    highlightedDepartmentRow: Department;

    highlightedComment: TicketComment;

    highlightedNote: TicketNotes;

    clickedRow: Task[] = [];

    clickedRow$ = new Observable<Task[]>((observer: Observer<Task[]>) => {
        observer.next(this.clickedRow);
    });

    clickedRowHistory: TicketLogs[] = [];

    clickedRowHistory$ = new Observable<TicketLogs[]>((observer: Observer<TicketLogs[]>) => {
        observer.next(this.clickedRowHistory);
    });

    // Dialogs
    renderTicketFormDialog = false;

    renderTaskFormDialog = false;

    renderTicketCommentFormDialog = false;

    renderTicketNoteFormDialog = false;

    renderTicketSolutionFormDialog = false;

    imagesBasic: GalleryImages[] = [];

    // Dropdowns
    height = '240px';

    usersSelect: Array<{value: boolean | number | string; label: string}> = [];

    departmentSelect: Array<{value: boolean | number | string; label: string}> = [];

    categoryFilterData: Array<string> = [];

    // Loaders
    loadingTicket = true;

    firstLoad = true;

    // Navigations
    @ViewChild('ticketDetailTab') ticketDetailTabObj: TabComponent;

    // ProgressBars
    @ViewChild('ticketTimePercentage')
    ticketTimePercentageObj?: ProgressBarComponent;

    // RTE
    @ViewChild('reply_text') replyText?: RichTextEditorComponent;

    // Grid
    @ViewChild('gridTasks', {static: false}) gridTasks?: GridComponent;

    @ViewChild('ticketLogsGrid', {static: false}) gridLogs?: GridComponent;

    @ViewChild('searchtext') searchtextObj?: TextBoxComponent;

    // Dialogs
    @ViewChild('deleteTaskDialog') deleteTaskDialog: DialogComponent;

    buttonsDeleteTaskDialog: ButtonPropsModel[] = [
        {
            isFlat: false,
            type: 'Button',
            buttonModel: {
                content: 'OK',
                cssClass: 'e-success e-outline',
            },
            click: this.removeTask.bind(this),
        },
        {
            isFlat: false,
            type: 'Button',
            buttonModel: {content: 'ZRUŠIT', cssClass: 'e-danger e-outline'},
            click: (): void => {
                this.deleteTaskDialog.hide();
            },
        },
    ];

    @ViewChild('deleteTicketDialog') deleteTicketDialog: DialogComponent;

    buttonsDeleteTicketDialog: ButtonPropsModel[] = [
        {
            click: this.removeTicket.bind(this),
            isFlat: false,
            buttonModel: {
                content: 'OK',
                cssClass: 'e-success e-outline',
            },
        },
        {
            click: (): void => {
                this.deleteTicketDialog.hide();
            },
            buttonModel: {content: 'ZRUŠIT', cssClass: 'e-danger e-outline'},
        },
    ];

    @ViewChild('deleteUserDialog') deleteUserDialog: DialogComponent;

    buttonsDeleteUserDialog: ButtonPropsModel[] = [
        {
            click: this.removeUser.bind(this),
            isFlat: false,
            buttonModel: {
                content: 'OK',
                cssClass: 'e-success e-outline',
            },
        },
        {
            click: (): void => {
                this.deleteUserDialog.hide();
            },
            buttonModel: {content: 'ZRUŠIT', cssClass: 'e-danger e-outline'},
        },
    ];

    @ViewChild('deleteDepartmentDialog') deleteDepartmentDialog: DialogComponent;

    buttonsDeleteDepartmentDialog: ButtonPropsModel[] = [
        {
            click: this.removeDepartment.bind(this),
            isFlat: false,
            buttonModel: {
                content: 'OK',
                cssClass: 'e-success e-outline',
            },
        },
        {
            click: (): void => {
                this.deleteDepartmentDialog.hide();
            },
            buttonModel: {content: 'ZRUŠIT', cssClass: 'e-danger e-outline'},
        },
    ];

    @ViewChild('deleteCommentDialog') deleteCommentDialog: DialogComponent;

    buttonsDeleteCommentDialog: ButtonPropsModel[] = [
        {
            click: this.removeTicketComment.bind(this),
            isFlat: false,
            buttonModel: {
                content: 'OK',
                cssClass: 'e-success e-outline',
            },
        },
        {
            click: (): void => {
                this.deleteCommentDialog.hide();
            },
            buttonModel: {content: 'ZRUŠIT', cssClass: 'e-danger e-outline'},
        },
    ];

    @ViewChild('deleteNoteDialog') deleteNoteDialog: DialogComponent;

    buttonsDeleteNoteDialog: ButtonPropsModel[] = [
        {
            click: this.removeTicketNote.bind(this),
            isFlat: false,
            buttonModel: {
                content: 'OK',
                cssClass: 'e-success e-outline',
            },
        },
        {
            click: (): void => {
                this.deleteNoteDialog.hide();
            },
            buttonModel: {content: 'ZRUŠIT', cssClass: 'e-danger e-outline'},
        },
    ];

    @ViewChild('activeTicketDialog') activeTicketDialog: DialogComponent;

    buttonsActiveTicketDialog: ButtonPropsModel[] = [
        {
            click: this.reactiveTicket.bind(this),
            isFlat: false,
            buttonModel: {
                content: 'OK',
                cssClass: 'e-success e-outline',
            },
        },
        {
            click: (): void => {
                this.activeTicketDialog.hide();
            },
            buttonModel: {content: 'Zrušit', cssClass: 'e-flat e-danger'},
        },
    ];

    @ViewChild('addNoteDialog') addNoteDialog: DialogComponent;

    @ViewChild('addSolvingDialog') addSolvingDialog: DialogComponent;

    @ViewChild('addUserDialog') addUserDialog: DialogComponent;

    @ViewChild('promoteUserDialog') promoteUserDialog: DialogComponent;

    @ViewChild('unPromoteUserDialog') unPromoteUserDialog: DialogComponent;

    @ViewChild('addDepartmentsDialog') addDepartmentsDialog: DialogComponent;

    // Dropdowns
    @ViewChild('viewFilter') viewFilterInstance: DropDownListComponent;

    @ViewChild('users') usersObj: MultiSelectComponent;

    @ViewChild('departments') departmentsObj: MultiSelectComponent;

    constructor(
        private readonly route: ActivatedRoute,
        private readonly authenticationService: AuthenticationService,
        private readonly dataService: DataService,
        private readonly tasksService: TasksService,
        private readonly commentService: CommentsService,
        private readonly messages: MessageService,
        private readonly formBuilder: FormBuilder,
        private readonly intl: TimeagoIntl,
        private readonly router: Router,
        private readonly ref: ChangeDetectorRef,
        private readonly lightbox: Lightbox,
        public ticketsService: TicketsService,
        public permissionService: PermissionService,
    ) {
        this.currentUser = this.authenticationService.currentUserValue;
        this.contentPrinted = false;
        this.contentSearched = 0;
        this.intl.strings = czechStrings;
    }

    get addSolverUserControl(): {[key: string]: AbstractControl} {
        return this.addSolverUserForm.controls;
    }

    get addSolverDepartmentControl(): {[key: string]: AbstractControl} {
        return this.addSolverDepartmentForm.controls;
    }

    @HostListener('window:beforeunload')
    canDeactivate(): Observable<boolean> | boolean {
        return !this.isDirty;
    }

    reloadData(): void {
        if (!this.ticket?.id) {
            return;
        }

        this.dataService.clearTicketsCache();
        this.dataService.setTicketDetailSource(this.ticket.id);
        this.ref.detectChanges();
    }

    ngOnInit(): void {
        this.elements = null;
        this.pageSettings = {pageCount: 5};
        this.initialTasksSort = {
            columns: [{field: 'created_date', direction: 'Descending'}],
        };
        this.selectionSettings = {type: 'Multiple', mode: 'Both'};
        this.toolbar = ['Search'];
        this.categoryFilterData = [
            'všechny změny',
            'poslední den',
            'poslední týden',
            'poslední měsíc',
            'poslední rok',
        ];

        this.toolbarHistory = [
            {
                text: 'Občerstvit data',
                tooltipText: 'Občerstvit data',
                prefixIcon: 'e-refresh',
                id: 'customRefresh',
            },
            'Print',
            'ExcelExport',
            {
                text: 'Obnovit data',
                tooltipText: 'Obnovit data',
                prefixIcon: 'e-revert',
                id: 'revert',
            },
        ];
        this.pageOptions = {pageSize: 50};
        this.filterDate = {type: 'Menu', params: {format: this.dateFormat}};
        this.filterSettings = {
            type: 'Excel',
            showFilterBarOperator: true,
            showFilterBarStatus: true,
            ignoreAccent: true,
        };
        this.requiredRules = {required: true};
        this.filterDate = {type: 'Menu', params: {format: this.dateFormat}};

        this.dataService.ticketDetailSource.pipe(untilDestroyed(this))
            .subscribe(
                (ticket: Tickets | null | undefined) => {
                    this.ticket = ticket;

                    if (this.ticket) {
                        this.ticketsService.setPromotedAdministrators(this.ticket);
                        this.loadData();
                        this.ticketsService
                            .getLogs(this.ticket.id)
                            .pipe(
                                distinctUntilChanged(),
                                untilDestroyed(this),
                                map(logs => logs?.data as TicketLogs[]),
                            )
                            .subscribe(
                                (logs: TicketLogs[] | undefined) => {
                                    // data uživatelů
                                    this.dataService.userSource.pipe(untilDestroyed(this))
                                        .subscribe(
                                            (data: User[] | undefined) => {
                                                this.users = data;
                                                this.logs = logs;

                                                if (this.gridLogs) {
                                                    this.dataHistory = this.gridLogs.dataSource =
                                                        this.loadHistoryData();
                                                }

                                                this.sortHistoryOptions = {
                                                    columns: [
                                                        {field: 'timestamp', direction: 'Descending'},
                                                        {field: 'id', direction: 'Descending'},
                                                    ],
                                                };
                                                this.ref.markForCheck();
                                            },
                                            error => {
                                                console.error(error);
                                            },
                                        );
                                },
                                error => {
                                    console.error(error);
                                    this.loadingTicket = false;
                                    this.ref.markForCheck();
                                },
                            );
                    }
                },
                error => {
                    console.error(error);
                    this.loadingTicket = false;
                    this.ref.markForCheck();
                },
            );

        this.dataService.taskSource.pipe(untilDestroyed(this))
            .subscribe(
                (tasks: Task[]) => {
                    this.tasks = tasks;
                    this.ref.markForCheck();
                },
                error => {
                    console.error(error);
                },
            );

        this.dataService.userSource.pipe(untilDestroyed(this))
            .subscribe(
                (users: User[] | undefined) => {
                    this.usersSelect = [];

                    if (users) {
                        users.map((user: User) => {
                            if (
                                user.authorized &&
                                !user.deleted_date &&
                                user.id &&
                                !this.ticket?.users.find(userData => userData.id === user.id)
                            ) {
                                this.usersSelect = [
                                    ...this.usersSelect,
                                    {value: user.id, label: user.fullname},
                                ];
                            }
                        });
                    }

                    this.ref.markForCheck();
                },
                error => {
                    console.error(error);
                },
            );

        this.dataService.departmentSource.pipe(untilDestroyed(this))
            .subscribe(
                (deparmtents: Department[] | undefined) => {
                    this.departmentSelect = [];

                    if (deparmtents) {
                        deparmtents.map((department: Department) => {
                            if (
                                !department.deleted_date &&
                                department.id &&
                                !this.ticket?.departments.find(
                                    departmentData => departmentData.id === department.id,
                                )
                            ) {
                                this.departmentSelect = [
                                    ...this.departmentSelect,
                                    {
                                        value: department.id,
                                        label: department.company.name + ' - ' + department.name,
                                    },
                                ];
                            }
                        });
                    }

                    this.ref.markForCheck();
                },
                error => {
                    console.error(error);
                },
            );

        this.createSolvingForm = this.formBuilder.group({
            solution: ['', Validators.required],
        });

        this.addSolverUserForm = this.formBuilder.group({
            users: [[], Validators.required],
        });

        this.addSolverDepartmentForm = this.formBuilder.group({
            departments: [[], Validators.required],
        });

        this.addSolverDepartmentForm.valueChanges.pipe(untilDestroyed(this))
            .subscribe(() => {
                this.isDirty = this.addSolverDepartmentForm.dirty;
                this.ref.markForCheck();
            });

        this.addSolverUserForm.valueChanges.pipe(untilDestroyed(this))
            .subscribe(() => {
                this.isDirty = this.addSolverUserForm.dirty;
                this.ref.markForCheck();
            });

        this.createSolvingForm.valueChanges.pipe(untilDestroyed(this))
            .subscribe(() => {
                this.isDirty = this.createSolvingForm.dirty;
                this.ref.markForCheck();
            });

        this.route.params.pipe(untilDestroyed(this))
            .subscribe(params => {
                this.dataService.setTicketDetailSource(parseInt(params.id as string, 10));
            });

        this.dataService.setTagsDataSource();
        this.dataService.setDepartmentDataSource();
        this.dataService.setTasksDataSource();
    }

    onCreate(): void {
        // Nastavení GUI podle URL parametrů
        this.route.queryParamMap.pipe(untilDestroyed(this))
            .subscribe(queryParams => {
                if (queryParams.get('view') === 'maintask') {
                    this.ticketDetailTabObj.selectedItem = 0;
                } else if (queryParams.get('view') === 'task') {
                    this.ticketDetailTabObj.selectedItem = 1;
                } else if (queryParams.get('view') === 'files') {
                    this.ticketDetailTabObj.selectedItem = 2;
                } else if (queryParams.get('view') === 'history') {
                    this.ticketDetailTabObj.selectedItem = 3;
                } else {
                    this.ticketDetailTabObj.selectedItem = 0;
                }
            });
    }

    preventSwipe(e: SelectEventArgs): void {
        if (e.isSwiped) {
            e.cancel = true;
        }
    }

    select(e: SelectEventArgs): void {
        let queryParams: Params = {view: null};

        if (e.selectedItem.textContent === 'Zadání') {
            queryParams = {
                view: 'maintask',
                form: null,
            };
        }

        if (e.selectedItem.textContent === 'Úkoly') {
            queryParams = {
                view: 'task',
                form: null,
            };
        }

        if (e.selectedItem.textContent === 'Soubory') {
            queryParams = {
                view: 'files',
                form: null,
            };
        }

        if (e.selectedItem.textContent === 'Historie') {
            queryParams = {
                view: 'history',
                form: null,
            };
        }

        void this.router.navigate([], {
            relativeTo: this.route,
            queryParams,
            queryParamsHandling: 'merge',
        });
    }

    // Grid Tasks
    onLoad(): void {
        const wrapper1 = document.getElementById('scroll_wrapper1_tasks');
        const wrapper2 = document.getElementById('scroll_wrapper2_tasks');

        if (wrapper1 && wrapper2) {
            wrapper1.onscroll = (): void => {
                wrapper2.scrollLeft = wrapper1.scrollLeft;
            };

            wrapper2.onscroll = (): void => {
                wrapper1.scrollLeft = wrapper2.scrollLeft;
            };
        }

        if (this.gridTasks) {
            this.elements = this.gridTasks.dataSource = this.loadTaskData();
        }
    }

    loadTaskData(): DataResult[] {
        console.info('NEW DATA BOUND');

        const elements: TaskGridItems[] = [];

        if (this.ticket?.tasks && this.ticket.tasks.length > 0) {
            this.ticket.tasks.map((task: Task) => {
                elements.push({
                    category_id: task.category_id,
                    due: task.taskTime.overtime && !task.deleted_date ? 'po termínu' : 'v termínu',
                    status:
                        task.status.name === 'solved'
                            ? 'dokončen'
                            : task.status.name === 'in queue'
                                ? 've frontě'
                                : 'smazán',
                    status_id: task.status_id,
                    tags: task.tags,
                    ticket_id: task.ticket_id,
                    id: task.id,
                    created_by: task.created_by,
                    creator_str: task.creator.fullname,
                    creator_img: task.creator.personalphoto,
                    user_id: task.user_id,
                    user_str: task.solver.fullname,
                    user_img: task.solver.personalphoto,
                    category: task.category ?? 'nepřiřazena',
                    subject: task.subject,
                    maintask: task.maintask,
                    maintaskHTML: task.maintaskHTML,
                    deadline: task.deadline,
                    created_date: task.created_date,
                    updated_date: task.updated_date ?? null,
                    deleted_date: task.deleted_date ?? null,
                    finished_at: task.finished_at ?? null,
                });
            });
        }

        return elements as unknown as DataResult[];
    }

    createdTasks(): void {
        const gridElement = this.gridTasks?.element;
        const span = document.createElement('span');

        if (gridElement) {
            span.className = 'e-clear-icon';
            span.id = gridElement.id + 'clear';
            span.onclick = this.cancelBtnClickClients.bind(this);
            gridElement.querySelector('.e-toolbar-item .e-input-group')
                ?.appendChild(span);
        }
    }

    onDataBound(): void {
        this.rowSelected();

        if (document.getElementById('gridTasks_content_table')) {
            const width = document.getElementById('gridTasks_content_table')?.offsetWidth;
            const parent1 = document.getElementById('scroll_div_tasks');
            const parent2 = document.getElementById('grid_parent_tasks');

            if (parent1 && parent2 && width) {
                parent1.style.width = `${width}px`;
                parent2.style.width = `${width}px`;
            }
        }

        this.gridTasks?.autoFitColumns();
        this.ref.markForCheck();
    }

    setInitialGridFiltering(): void {
        this.gridTasks?.clearFiltering();
        this.ref.markForCheck();
    }

    rowDataBound(args: RowDataBoundEventArgs): void {
        const data = args.data as Tickets;

        if (args.row && data.deleted_date) {
            args.row.getElementsByClassName('e-gridchkbox')[0].classList.add('disablecheckbox');
            args.row
                .getElementsByClassName('e-checkbox-wrapper')[0]
                .classList.add('disablecheckbox');
            args.row.classList.add('e-disabled');
        }

        if (
            this.currentUser &&
            args.row &&
            data.created_by !== this.currentUser.id &&
            !this.ticketsService.checkPromotedAdministrators(this.currentUser) &&
            !this.permissionService.checkUserISTicketAdmin(this.currentUser) &&
            !this.permissionService.checkUserISAdmin(this.currentUser)
        ) {
            args.row.getElementsByClassName('e-gridchkbox')[0].classList.add('disablecheckbox');
            args.row
                .getElementsByClassName('e-checkbox-wrapper')[0]
                .classList.add('disablecheckbox');
        }

        this.ref.markForCheck();
    }

    cancelBtnClickClients(): void {
        if (this.gridTasks) {
            this.gridTasks.searchSettings.key = '';

            const element: HTMLInputElement | null = this.gridTasks.element.querySelector(
                '.e-input-group.e-search .e-input',
            );

            if (element) {
                element.value = '';
            }
        }
    }

    rowSelected(): void {
        if (this.gridTasks) {
            const selectedrecords: object[] = this.gridTasks.getSelectedRecords();

            if (selectedrecords.length > 0) {
                selectedrecords.map((row: Task) => {
                    const task = this.tasks.find(x => x.id === row.id);

                    if (task) {
                        this.clickedRow.push(task);
                    }
                });

                this.clickedRow$ = new Observable<Task[]>((observer: Observer<Task[]>) => {
                    observer.next(this.clickedRow);
                });
            } else {
                this.clickedRow = [];
                this.clickedRow$ = new Observable<Task[]>((observer: Observer<Task[]>) => {
                    observer.next(this.clickedRow);
                });
            }

            this.ref.markForCheck();
        }
    }

    // Grid Logs
    onHistoryCreated(): void {
        this.disableToolBarBtn();
    }

    setInitialHistoryGridFiltering(): void {
        this.gridLogs?.clearFiltering();
        // this.gridLogs.filterByColumn('created_by', 'equal', this.currentUser.id, 'and', false, true);
        // this.viewFilterInstance.value = 'moje žádanky - všechny';
        this.ref.markForCheck();
    }

    loadHistoryData(): DataResult[] {
        console.info('NEW DATA BOUND');

        const elements: TicketLogsItems[] = [];

        if (this.logs && this.logs.length > 0) {
            this.logs.map((log: TicketLogs) => {
                const creator = this.users?.find(x => x.id === log.created_by);
                const editor = this.users?.find(x => x.id === log.updated_by);
                const remover = this.users?.find(x => x.id === log.deleted_by);

                elements.push({
                    id: log.tickets_id,
                    type_id: log.tickets_type,
                    ticket_id: log.id,
                    category_id: log.category_id,
                    business_id: log.business_id,
                    status_id: log.status_id,
                    created_by: log.created_by,
                    creator_str: creator ? creator.fullname : '',
                    creator_img: creator ? creator.personalphoto : '',
                    updated_by: log.updated_by,
                    editor_str: editor ? editor.fullname : '',
                    editor_img: editor ? editor.personalphoto : '',
                    deleted_by: log.deleted_by,
                    remover_str: remover ? remover.fullname : '',
                    remover_img: remover ? remover.personalphoto : '',
                    type:
                        log.tickets_type === 1
                            ? 'vytvoření'
                            : log.tickets_type === 2
                                ? 'upravení'
                                : 'smazání',
                    subject: log.subject,
                    maintask: log.maintask,
                    deadline: log.deadline,
                    created_date: log.created_date,
                    updated_date: log.updated_date,
                    deleted_date: log.deleted_date ? log.deleted_date : null,
                    timestamp: new Date(log.tickets_timestamp),
                });
            });
        }

        this.loadingTicket = false;
        this.ref.markForCheck();

        return elements as unknown as DataResult[];
    }

    onHistoryLoad(): void {
        const wrapper1 = document.getElementById('scroll_wrapper1_ticketsLogs');
        const wrapper2 = document.getElementById('scroll_wrapper2_ticketsLogs');

        if (wrapper1 && wrapper2) {
            wrapper1.onscroll = (): void => {
                wrapper2.scrollLeft = wrapper1.scrollLeft;
            };

            wrapper2.onscroll = (): void => {
                wrapper1.scrollLeft = wrapper2.scrollLeft;
            };
        }

        if (this.gridLogs) {
            this.dataHistory = this.gridLogs.dataSource = this.loadHistoryData();
        }

        this.sortHistoryOptions = {
            columns: [
                {field: 'timestamp', direction: 'Descending'},
                {field: 'id', direction: 'Descending'},
            ],
        };
        this.ref.markForCheck();
    }

    search(): void {
        if (this.searchtextObj) {
            this.gridLogs?.search(this.searchtextObj.value);
        }
    }

    onHistoryDataBound(): void {
        this.rowHistorySelected();

        const width = document.getElementById('ticketLogsGrid_content_table')?.offsetWidth;
        const parent1 = document.getElementById('scroll_div_ticketsLogs');
        const parent2 = document.getElementById('grid_parent_ticketsLogs');

        if (parent1 && parent2 && width) {
            parent1.style.width = `${width}px`;
            parent2.style.width = `${width}px`;
        }

        this.gridLogs?.autoFitColumns();
        this.ref.markForCheck();
    }

    rowHistoryDataBound(args: RowDataBoundEventArgs): void {
        const data = args.data as TicketLogs;

        if (args.row && data.deleted_date) {
            args.row.getElementsByClassName('e-gridchkbox')[0].classList.add('disablecheckbox');
            args.row
                .getElementsByClassName('e-checkbox-wrapper')[0]
                .classList.add('disablecheckbox');
            args.row.classList.add('e-disabled');
        }

        if (
            this.currentUser &&
            args.row &&
            data.created_by !== this.currentUser.id &&
            !this.ticketsService.checkPromotedAdministrators(this.currentUser) &&
            !this.permissionService.checkUserISAdministrative(this.currentUser)
        ) {
            args.row.getElementsByClassName('e-gridchkbox')[0].classList.add('disablecheckbox');
            args.row
                .getElementsByClassName('e-checkbox-wrapper')[0]
                .classList.add('disablecheckbox');
        }

        this.ref.markForCheck();
    }

    onStatusFilterChange(e: ChangeEventArgs): void {
        if (e.value === 'všechny změny') {
            this.gridLogs?.clearFiltering();
        } else if (e.value === 'poslední den') {
            this.gridLogs?.clearFiltering();
            this.gridLogs?.filterByColumn(
                'timestamp',
                'greaterthanorequal',
                new Date(moment()
                    .subtract(1, 'days')
                    .format('YYYY-MM-DD HH:mm:ss')),
                'and',
                false,
                true,
            );
        } else if (e.value === 'poslední týden') {
            this.gridLogs?.clearFiltering();
            this.gridLogs?.filterByColumn(
                'timestamp',
                'greaterthanorequal',
                new Date(moment()
                    .subtract(1, 'weeks')
                    .format('YYYY-MM-DD HH:mm:ss')),
                'and',
                false,
                true,
            );
        } else if (e.value === 'poslední měsíc') {
            this.gridLogs?.clearFiltering();
            this.gridLogs?.filterByColumn(
                'timestamp',
                'greaterthanorequal',
                new Date(moment()
                    .subtract(1, 'months')
                    .format('YYYY-MM-DD HH:mm:ss')),
                'and',
                false,
                true,
            );
        } else if (e.value === 'poslední rok') {
            this.gridLogs?.clearFiltering();
            this.gridLogs?.filterByColumn(
                'timestamp',
                'greaterthanorequal',
                new Date(moment()
                    .subtract(1, 'years')
                    .format('YYYY-MM-DD HH:mm:ss')),
                'and',
                false,
                true,
            );
        }
    }

    cancelBtnClick(): void {
        if (this.searchtextObj && this.gridLogs) {
            this.searchtextObj.value = '';
            this.gridLogs.searchSettings.key = '';
        }
    }

    toolbarHistoryClick(args: ClickEventArgs): void {
        const xlsProp: ExcelExportProperties = {
            fileName: 'js_ticket_logs_export.xlsx',
            enableFilter: true,
            exportType: this.exportCurrentHistoryPage ? 'CurrentPage' : 'AllPages',
        };

        if (args.item.id === 'ticketLogsGrid_excelexport') {
            void this.gridLogs?.excelExport(xlsProp);
        } else if (args.item.id === 'customRefresh' && this.ticket) {
            this.loadingTicket = true;
            console.info('refresh DATA!');
            this.dataService.setTicketDetailSource(this.ticket);
            this.ref.markForCheck();
        } else if (args.item.id === 'revert' && this.ticket) {
            this.loadingTicket = true;
            console.info('revert DATA!');
            this.revertToTimestamp();
            this.dataService.setTicketDetailSource(this.ticket);
            this.ref.markForCheck();
        }
    }

    enableToolbarBtn(): void {
        this.gridLogs?.toolbarModule.enableItems(['revert'], true);
    }

    disableToolBarBtn(): void {
        this.gridLogs?.toolbarModule.enableItems(['revert'], false);
    }

    rowHistorySelected(): void {
        if (this.gridLogs) {
            this.clickedRowHistory = [];

            const selectedrecords: object[] = this.gridLogs.getSelectedRecords();

            if (selectedrecords.length > 0) {
                selectedrecords.map((row: TicketLogs) => {
                    const tempData: TicketLogs | undefined = this.logs?.find(
                        x => x.tickets_id === row.id,
                    );

                    if (tempData) {
                        this.clickedRowHistory.push(tempData);
                    }

                    this.ref.markForCheck();
                });

                this.clickedRowHistory$ = new Observable<TicketLogs[]>(
                    (observer: Observer<TicketLogs[]>) => {
                        observer.next(this.clickedRowHistory);
                    },
                );
                this.enableToolbarBtn();
            } else {
                this.disableToolBarBtn();
                this.clickedRowHistory$ = new Observable<TicketLogs[]>(
                    (observer: Observer<TicketLogs[]>) => {
                        observer.next(this.clickedRowHistory);
                    },
                );
            }
        }
    }

    revertToTimestamp(): void {
        console.info(this.clickedRowHistory[0]);
    }

    reactiveSolution(): void {
        if (!this.ticket?.solution) {
            return;
        }

        this.isCopySolution = false;
        this.isCreateSolution = false;
        this.isUpdateSolution = true;
        this.renderTicketSolutionFormDialog = true;
        this.ref.markForCheck();
    }

    reactiveTicket(): void {
        this.loadingTicket = true;

        if (this.ticket?.solution && this.ticket.finished_at) {
            const note: TicketNoteInput = {
                text: this.ticket.solution,
                ticket_id: this.ticket.id,
                created_by: this.currentUser?.id,
            };

            this.ticket.solution = null;
            this.ticketsService
                .addSolvingNote(note)
                .pipe(untilDestroyed(this))
                .subscribe(
                    () => {
                        const body = 'Řešení bylo úspěšně převedeno do postupů.';
                        const options = {progressBar: true, timeOut: 5000, toastClass: 'success'};

                        this.messages.showSuccess('Řešení převedeno do postupů', body, options);
                    },
                    error => {
                        console.error(error);

                        const body = 'Zkuste to znovu později ...';
                        const options = {
                            progressBar: true,
                            timeOut: 5000,
                            toastClass: 'red',
                        };

                        this.messages.showError(
                            'Chyba při publikování postupu v ticketu',
                            body,
                            options,
                        );
                        this.loadingTicket = false;
                        this.ref.markForCheck();
                    },
                );
        }

        if (!this.ticket || !this.currentUser) {
            console.error('this.ticket || this.currentUser is missing...');

            return;
        }

        const data: TicketInput = {
            id: this.ticket.id,
            status_id: 1,
            updated_by: this.currentUser.id,
            deleted_by: null,
            finished_at: null,
            solved_by: null,
            solution: null,
        };

        this.ticketsService
            .updateTicket(data)
            ?.pipe(untilDestroyed(this))
            .subscribe(
                () => {
                    const body = 'Ticket vrácen zpět k řešení do fronty';
                    const options = {progressBar: true, timeOut: 5000};

                    this.messages.showSuccess('Ticket obnoven', body, options);
                    this.loadingTicket = false;
                    this.ref.markForCheck();

                    if (!this.ticket) {
                        console.error('this.ticket is missing...');

                        return;
                    }

                    this.dataService.setTicketDetailSource(this.ticket.id);
                    this.activeTicketDialog.hide();
                },
                error => {
                    console.error(error);

                    const body = 'Zkuste to znovu později';
                    const options = {
                        progressBar: true,
                        timeOut: 5000,
                        toastClass: 'red',
                    };

                    this.messages.showError('Chyba při obnovení ticketu,', body, options);
                    this.loadingTicket = false;
                    this.ref.markForCheck();
                },
            );
    }

    // HELPER FCES
    onFilteringUsers: EmitType<FilteringEventArgs> = (e: FilteringEventArgs) => {
        if (e.text === '') {
            e.updateData(this.usersSelect);
        } else {
            let query: Query = new Query();

            query = e.text !== '' ? query.where('label', 'contains', e.text, true, true) : query;
            e.updateData(this.usersSelect, query);
        }
    };

    onFilteringDepartments: EmitType<FilteringEventArgs> = (e: FilteringEventArgs) => {
        if (e.text === '') {
            e.updateData(this.departmentSelect);
        } else {
            let query = new Query();

            query = e.text !== '' ? query.where('label', 'contains', e.text, true) : query;
            e.updateData(this.departmentSelect, query);
        }
    };

    // FORMS
    newTask(): void {
        this.isTaskCreate = true;
        this.isTaskUpdate = false;
        this.isTaskCopy = false;
        this.renderTaskFormDialog = true;
        this.ref.markForCheck();
    }

    copyTask(): void {
        this.isTaskCreate = false;
        this.isTaskUpdate = false;
        this.isTaskCopy = true;
        this.renderTaskFormDialog = true;
        this.ref.markForCheck();
    }

    addUsers(): void {
        this.submited = true;

        if (this.addSolverUserForm.invalid) {
            console.error('form is not valid!');

            return;
        }

        this.isDirty = false;
        this.loadingTicket = true;

        const users: number[] = this.addSolverUserControl.users.value;

        if (!this.ticket || !this.currentUser) {
            console.error('Ticket object or currentUser ID is missing...');

            return;
        }

        this.ticketsService
            .setAssignations(this.ticket, this.currentUser, users)
            .pipe(untilDestroyed(this))
            .subscribe(
                () => {
                    const body = 'Uživatel byl úspěšně přidán jako řešitel ticketu.';
                    const options = {progressBar: true, timeOut: 5000};

                    this.messages.showSuccess('Zapojení uživatele do ticketu', body, options);
                    this.loadingTicket = false;
                    this.ref.markForCheck();

                    if (!this.ticket) {
                        console.error('this.ticket is missing...');

                        return;
                    }

                    this.dataService.clearTicketsCache();
                    this.dataService.setTicketDetailSource(this.ticket.id);
                    this.usersSelect.forEach((item, index) => {
                        if (users.find(usersData => usersData === item.value)) {
                            this.usersSelect.splice(index, 1);
                        }
                    });
                    this.addUserDialog.hide();
                    this.addSolverUserForm.reset();
                },
                error => {
                    console.error(error);

                    const body = 'Zkuste to znovu později';
                    const options = {progressBar: true, timeOut: 5000};

                    this.messages.showError(
                        'Chyba při vytváření zapojení uživatele',
                        body,
                        options,
                    );
                    this.loadingTicket = false;
                    this.ref.markForCheck();
                },
            );
    }

    promoteUser(): void {
        if (this.ticket) {
            this.loadingTicket = true;
            this.ticketsService
                .promoteUser(this.ticket, this.highlightedUserRow)
                .pipe(untilDestroyed(this))
                .subscribe(
                    () => {
                        const body = 'Uživatel byl úspěšně nastaven jako správce ticketu.';
                        const options = {progressBar: true, timeOut: 5000};

                        this.messages.showSuccess('Přidání správce ticketu', body, options);
                        this.loadingTicket = false;
                        this.ref.markForCheck();

                        if (!this.ticket) {
                            console.error('this.ticket is missing...');

                            return;
                        }

                        this.promoteUserDialog.hide();
                        this.dataService.setTicketDetailSource(this.ticket.id);
                    },
                    error => {
                        console.error(error);

                        const body = 'Zkuste to znovu později';
                        const options = {progressBar: true, timeOut: 5000};

                        this.messages.showError(
                            'Chyba při nastavení role správce pro uživatele',
                            body,
                            options,
                        );
                        this.loadingTicket = false;
                        this.ref.markForCheck();
                    },
                );
        }
    }

    unPromoteUser(): void {
        if (this.ticket) {
            this.loadingTicket = true;
            this.ticketsService
                .unPromoteUser(this.ticket, this.highlightedUserRow)
                .pipe(untilDestroyed(this))
                .subscribe(
                    () => {
                        const body = 'Uživatel byl úspěšně odebrán jako správce ticketu.';
                        const options = {progressBar: true, timeOut: 5000};

                        this.messages.showSuccess('Odebrání správce ticketu', body, options);
                        this.loadingTicket = false;
                        this.ref.markForCheck();

                        if (!this.ticket) {
                            console.error('this.ticket is missing...');

                            return;
                        }

                        this.unPromoteUserDialog.hide();
                        this.dataService.setTicketDetailSource(this.ticket.id);
                    },
                    error => {
                        console.error(error);

                        const body = 'Zkuste to znovu později';
                        const options = {progressBar: true, timeOut: 5000};

                        this.messages.showError(
                            'Chyba při nastavení role správce pro uživatele',
                            body,
                            options,
                        );
                        this.loadingTicket = false;
                        this.ref.markForCheck();
                    },
                );
        }
    }

    addDepartments(): void {
        this.submited = true;

        if (this.addSolverDepartmentForm.invalid) {
            console.error('form is not valid!');

            return;
        }

        this.isDirty = false;
        this.loadingTicket = true;

        const departments: number[] = this.addSolverDepartmentControl.departments.value;

        if (!this.ticket || !this.currentUser) {
            console.error('Ticket object or currentUser ID is missing...');

            return;
        }

        this.ticketsService
            .setAssignations(this.ticket, this.currentUser, [], departments)
            .pipe(untilDestroyed(this))
            .subscribe(
                () => {
                    const body = 'Oddělení bylo úspěšně přidáno jako řešitel(é) ticketu.';
                    const options = {progressBar: true, timeOut: 5000};

                    this.messages.showSuccess('Zapojení oddělení', body, options);
                    this.loadingTicket = false;
                    this.ref.markForCheck();

                    if (!this.ticket) {
                        console.error('this.ticket is missing...');

                        return;
                    }

                    this.dataService.clearTicketsCache();
                    this.dataService.setTicketDetailSource(this.ticket.id);
                    this.departmentSelect.forEach((item, index) => {
                        if (
                            departments.find(
                                departmentDataData => departmentDataData === item.value,
                            )
                        ) {
                            this.departmentSelect.splice(index, 1);
                        }
                    });
                    this.addDepartmentsDialog.hide();
                    this.addSolverDepartmentForm.reset();
                },
                error => {
                    console.error(error);

                    const body = 'Zkuste to znovu později';
                    const options = {progressBar: true, timeOut: 5000};

                    this.messages.showError('Chyba při vytváření zapojení oddělení', body, options);
                    this.loadingTicket = false;
                    this.ref.markForCheck();
                },
            );
    }

    copyTicket(): void {
        this.isUpdate = false;
        this.isCopy = true;
        this.renderTicketFormDialog = true;
        this.ref.markForCheck();
    }

    editTicket(): void {
        this.isCopy = false;
        this.isUpdate = true;
        this.renderTicketFormDialog = true;
        this.ref.markForCheck();
    }

    deleteTicket(): void {
        this.deleteTicketDialog.show();
        this.ref.markForCheck();
    }

    createComment(): void {
        this.isCopyComment = false;
        this.isCreateComment = true;
        this.isUpdateComment = false;
        this.renderTicketCommentFormDialog = true;
    }

    editComment(): void {
        this.isCopyComment = false;
        this.isCreateComment = false;
        this.isUpdateComment = true;
        this.renderTicketCommentFormDialog = true;
    }

    createNote(): void {
        this.isCopyNote = false;
        this.isCreateNote = true;
        this.isUpdateNote = false;
        this.renderTicketNoteFormDialog = true;
    }

    editNote(note: TicketNotes): void {
        this.highlightedNote = note;
        this.isCopyNote = false;
        this.isCreateNote = false;
        this.isUpdateNote = true;
        this.renderTicketNoteFormDialog = true;
    }

    deleteNote(note: TicketNotes): void {
        this.highlightedNote = note;
        this.deleteNoteDialog.show();
        this.ref.markForCheck();
    }

    addSolution(): void {
        this.isCopySolution = false;
        this.isCreateSolution = true;
        this.isUpdateSolution = false;
        this.renderTicketSolutionFormDialog = true;
    }

    removeUser(): void {
        if (!this.ticket || !this.currentUser) {
            console.error('Ticket object or currentUser ID is missing...');

            return;
        }

        this.loadingTicket = true;
        this.ticketsService
            .deleteUserAssignation(this.highlightedUserRow, this.currentUser, this.ticket)
            ?.pipe(untilDestroyed(this))
            .subscribe(
                () => {
                    const body = 'Uživatel byl úspěšně odebrán z ticketu.';
                    const options = {progressBar: true, timeOut: 5000};

                    this.messages.showSuccess('Uživatel odebrán z ticketu', body, options);
                    this.loadingTicket = false;
                    this.deleteUserDialog.hide();
                    this.ref.markForCheck();

                    if (!this.ticket) {
                        console.error('this.ticket is missing...');

                        return;
                    }

                    this.dataService.clearTicketsCache();
                    this.dataService.setTicketDetailSource(this.ticket.id);
                    this.usersSelect.push({
                        value: this.highlightedUserRow.id,
                        label: this.highlightedUserRow.fullname,
                    });
                    this.addUserDialog.hide();
                    this.addSolverUserForm.reset();
                },
                error => {
                    console.error(error);

                    const body = 'Zkuste to znovu později ...';
                    const options = {
                        progressBar: true,
                        timeOut: 5000,
                        toastClass: 'red',
                    };

                    this.messages.showError(
                        'Chyba při odebrání uživatele z ticketu',
                        body,
                        options,
                    );
                    this.loadingTicket = false;
                    this.ref.markForCheck();
                },
            );
    }

    removeDepartment(): void {
        if (!this.ticket || !this.currentUser) {
            console.error('Ticket object or currentUser ID is missing...');

            return;
        }

        this.loadingTicket = true;
        // eslint-disable-next-line max-len
        this.ticketsService
            .deleteDepartmentAssignation(
                this.highlightedDepartmentRow,
                this.currentUser,
                this.ticket,
            )
            ?.pipe(untilDestroyed(this))
            .subscribe(
                () => {
                    const body = 'Oddělení bylo úspěšně odebráno z ticketu.';
                    const options = {progressBar: true, timeOut: 5000};

                    this.messages.showSuccess('Oddělení odebráno z ticketu', body, options);
                    this.loadingTicket = false;
                    this.deleteDepartmentDialog.hide();
                    this.ref.markForCheck();

                    if (!this.ticket) {
                        console.error('this.ticket is missing...');

                        return;
                    }

                    this.dataService.clearTicketsCache();
                    this.dataService.setTicketDetailSource(this.ticket.id);
                    this.departmentSelect.push({
                        value: this.highlightedDepartmentRow.id,
                        label:
                            this.highlightedDepartmentRow.company.name +
                            ' - ' +
                            this.highlightedDepartmentRow.name,
                    });
                    this.addDepartmentsDialog.hide();
                    this.addSolverDepartmentForm.reset();
                },
                error => {
                    console.error(error);

                    const body = 'Zkuste to znovu později ...';
                    const options = {progressBar: true, timeOut: 5000};

                    this.messages.showError('Chyba při odebrání oddělení z ticketu', body, options);
                    this.loadingTicket = false;
                    this.ref.markForCheck();
                },
            );
    }

    removeTicketComment(): void {
        this.loadingTicket = true;
        this.highlightedComment.deleted_by = this.currentUser?.id;
        this.commentService
            .deleteTicketComment(this.highlightedComment)
            ?.pipe(untilDestroyed(this))
            .subscribe(
                () => {
                    const body = 'Komentář byl úspěšně smazán.';
                    const options = {progressBar: true, timeOut: 5000};

                    this.messages.showSuccess('Komentář smazán', body, options);
                    this.loadingTicket = false;

                    this.ref.markForCheck();

                    if (!this.ticket) {
                        console.error('this.ticket is missing...');

                        return;
                    }

                    this.dataService.setTicketDetailSource(this.ticket.id);
                    this.deleteCommentDialog.hide();
                },
                error => {
                    console.error(error);

                    const body = 'Zkuste to znovu později ...';
                    const options = {progressBar: true, timeOut: 5000};

                    this.messages.showError('Chyba při mazání komentáře v ticketu', body, options);
                    this.loadingTicket = false;
                    this.ref.markForCheck();
                },
            );
    }

    removeTicket(): void {
        if (!this.ticket) {
            console.error('this.ticket is missing...');

            return;
        }

        this.loadingTicket = true;
        this.ticket.deleted_by = this.currentUser?.id;
        this.ticketsService
            .deleteTicket(this.ticket)
            ?.pipe(untilDestroyed(this))
            .subscribe(
                () => {
                    const body = 'Ticket byl úspěšně smazán.';
                    const options = {progressBar: true, timeOut: 5000};

                    this.messages.showSuccess('Ticket smazán', body, options);
                    this.loadingTicket = false;
                    this.deleteTicketDialog.hide();
                    void this.router.navigate(['/tickets/'], {
                        queryParams: {view: 'table'},
                    });
                },
                error => {
                    console.error(error);

                    const body = 'Zkuste to znovu později ...';
                    const options = {progressBar: true, timeOut: 5000};

                    this.messages.showError('Chyba při mazání ticketu', body, options);
                    this.loadingTicket = false;
                    this.ref.markForCheck();
                },
            );
    }

    removeTask(): void {
        if (!this.highlightedTaskRow) {
            console.error('this.highlightedTaskRow is missing...');

            return;
        }

        this.loadingTicket = true;
        this.highlightedTaskRow.deleted_by = this.currentUser?.id;
        this.tasksService
            .deleteTask(this.highlightedTaskRow)
            ?.pipe(untilDestroyed(this))
            .subscribe(
                () => {
                    const body = 'Úkol byl úspěšně smazán.';
                    const options = {progressBar: true, timeOut: 5000};

                    this.messages.showSuccess('Úkol smazán', body, options);
                    this.loadingTicket = false;
                    this.deleteTaskDialog.hide();
                    this.ref.markForCheck();

                    if (!this.ticket) {
                        console.error('this.ticket is missing...');

                        return;
                    }

                    this.dataService.setTicketDetailSource(this.ticket.id);
                },
                error => {
                    console.error(error);

                    const body = 'Zkuste to znovu později ...';
                    const options = {progressBar: true, timeOut: 5000};

                    this.messages.showError('Chyba při mazání úkolu v ticketu', body, options);
                    this.loadingTicket = false;
                    this.ref.markForCheck();
                },
            );
    }

    removeTicketNote(): void {
        this.loadingTicket = true;
        this.highlightedNote.deleted_by = this.currentUser?.id;
        this.ticketsService
            .deleteTicketNote(this.highlightedNote)
            ?.pipe(untilDestroyed(this))
            .subscribe(
                () => {
                    const body = 'Postup byl úspěšně smazán.';
                    const options = {progressBar: true, timeOut: 5000};

                    this.messages.showSuccess('Postup smazán', body, options);
                    this.loadingTicket = false;
                    this.deleteNoteDialog.hide();
                    this.ref.markForCheck();

                    if (!this.ticket) {
                        console.error('this.ticket is missing...');

                        return;
                    }

                    this.dataService.setTicketDetailSource(this.ticket.id);
                },
                error => {
                    console.error(error);
                    this.loadingTicket = false;
                    this.ref.markForCheck();
                },
            );
    }

    showInputAddDepartmentsErrors(): void {
        this.addSolverUserForm.markAllAsTouched();
        this.ref.markForCheck();
    }

    showInputAddSolverUserFormErrors(): void {
        this.addSolverDepartmentForm.markAllAsTouched();
        this.ref.markForCheck();
    }

    private loadData(): void {
        this.imagesBasic = [];
        this.ticket?.users.map((user: User) => {
            if (
                this.firstLoad &&
                this.ticket &&
                this.currentUser &&
                user.id === this.currentUser.id
            ) {
                this.ticketsService
                    .setUsersViewsTimestamps(this.ticket, user)
                    .pipe(untilDestroyed(this))
                    .subscribe({
                        next: () => {
                            console.info('značky zobrazení uživatelů aktualizovány.');
                        },
                        error: error => {
                            console.error(error);
                        },
                    });
            }
        });

        this.ticket?.departments.map((department: Department) => {
            this.currentUser?.assignations?.map((assignation: Assignation) => {
                if (this.firstLoad && this.ticket && department.id === assignation.department_id) {
                    this.ticketsService
                        .setDepartmentsViewsTimestamps(this.ticket, department)
                        .pipe(untilDestroyed(this))
                        .subscribe({
                            next: () => {
                                console.info('značky zobrazení oddělení aktualizovány.');
                            },
                            error: error => {
                                console.error(error);
                            },
                        });
                }
            });
        });

        this.firstLoad = false;

        if (this.ticket?.files && this.ticket.files.length > 0) {
            this.ticket.files.map((file: TicketFile) => {
                if (file.extension === 'image/jpeg' || file.extension === 'image/png') {
                    this.imagesBasic.push({
                        src: file.url,
                        thumb: file.url_thumbnail ?? '',
                        caption: file.name,
                    });
                }
            });
        }

        if (this.gridTasks) {
            this.elements = this.gridTasks.dataSource = this.loadTaskData();
        }

        this.loadingTicket = false;
        this.ref.markForCheck();
    }

    open(index: number): void {
        this.lightbox.open(this.imagesBasic, index);
    }
}
