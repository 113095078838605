<ejs-dialog
  #deleteFileDialog
  [buttons]="buttonsDeleteFileDialog"
  [content]="'Opravdu chcete smazat tento soubor z importu?'"
  [header]="'Smazat soubor z importu'"
  [height]="'200px'"
  [isModal]="true"
  [showCloseIcon]="true"
  [visible]="false"
  [width]="'450px'"
  id="deleteFileDialog"
></ejs-dialog>
<ejs-dialog
  #uploadDialog
  (close)="changeDialogState(false)"
  [isModal]="true"
  [showCloseIcon]="true"
  [visible]="isVisible"
  [width]="'85%'"
  id="formDialog"
>
  <ng-template #content>
    <div class="dialogContent">
      <div class="row">
        <form class="col-12 text-center">
          <ejs-uploader
            #preloadupload
            (beforeUpload)="beforeFileUpload($event)"
            (removing)="onFileRemove($event)"
            (selected)="onFileSelected($event)"
            (success)="onSuccessUpload($event)"
            (uploading)="onFileUpload($event)"
            [asyncSettings]="importType === 'TMCZ' ? pathTMCZ : pathVodafone"
            [dropArea]="dropElement"
            allowedExtensions=".xls,.xlsx"
            id="preloadfileupload"
            maxFileSize="400000000"
          >
          </ejs-uploader>
        </form>
      </div>
    </div>
  </ng-template>
  <ng-template #footerTemplate>
    <div class="row">
      <div class="col-12 pt-2">
        <button
          (click)="uploadDialogObj.hide()"
          cssClass="e-btn e-danger e-outline btn-p-zero e-small"
          ejs-button
          type="button"
        >
          ZRUŠIT
        </button>
      </div>
    </div>
  </ng-template>
</ejs-dialog>
