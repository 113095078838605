import {Injectable} from '@angular/core';
import {CustomStorageService} from '@src/app/_services/custom-storage.service';
import {AuthenticationService} from '@src/app/_services/authentication.service';
import {DataService} from '@src/app/_services/data.service';
import {DataNetbaseService} from '@src/app/_services/data-netbase.service';
import {Router} from '@angular/router';

@Injectable({
    providedIn: 'root',
})
export class LogoutService {
    constructor(
        private readonly dataService: DataService,
        private readonly dataNetbaseService: DataNetbaseService,
        private readonly storage: CustomStorageService,
        private readonly router: Router,
        private readonly authenticationService: AuthenticationService,
    ) {}

    logout(): void {
        this.dataService.clearAllCache();
        this.dataNetbaseService.clearAllCache();
        this.storage.removeItem('token');
        this.storage.removeItem('currentUser');
        this.authenticationService.currentUserSubject$.next(null);
        this.authenticationService.currentTokenSubject$.next(null);
        void this.router.navigate(['/login'], {
            queryParams: {returnUrl: this.router.url},
        });
        console.info('Logout process done...');
    }
}
