<section id="top_widgets">
  <div class="row">
    <div class="col-12 col-md-6">
      <div *ngIf="currDate && hotlines" class="card bg-transparent">
        <div class="e-card-header d-flex justify-content-between p-3">
                    <span>
                        <i
                          aria-hidden="true"
                          class="fa-light rounded fa-headset fa-2x p-2 text-primary mr-2"
                        ></i>
                        Hot-Line
                    </span>
          <span
          ><strong>{{
              currDate | date: 'dd. MM. yyyy'
            }}</strong></span
          >
        </div>
        <div class="e-card-content text-center p-0 m-0">
          <div *ngIf="loadingHotlineWidget" class="row">
            <div class="col-12 text-center">
              <small class="text-muted">načítám data</small>
            </div>
          </div>
          <div
            *ngIf="!loadingHotlineWidget"
            class="table-responsive p-2 square scrollbar-winter-neva square thin"
            style="max-height: 400px; overflow-y: auto"
          >
            <table
              *ngIf="hotlines.length > 0"
              class="table mb-1"
            >
              <tbody>
              <tr
                *ngFor="
                                        let hotline of hotlines;
                                        trackBy: trackHotlineID;
                                        let i = index
                                    "
              >
                <td class="text-left">
                  <img
                    alt="profilový obrázek"
                    class="img-fluid rounded-circle avatar-xs mr-1"
                    src="assets/img{{
                                                hotline.hotliner.user
                                                    .personalphoto
                                            }}"
                  />
                  &nbsp;{{
                    hotline.hotliner.user.firstname
                  }}
                  {{ hotline.hotliner.user.secondname }}
                </td>
                <td class="text-right">
                  {{ hotline.company.name }}&nbsp;
                  <span
                    *ngIf="
                                                hotline.type === 'technician'
                                            "
                    class="e-badge e-badge-success"
                  >služba</span
                  >
                  <span
                    *ngIf="
                                                hotline.type !== 'technician'
                                            "
                    class="e-badge e-badge-primary"
                  >příslužba</span
                  >
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="card-footer">
          <div class="d-flex justify-content-end">
            <a
              [queryParams]="{ view: 'calendar' }"
              [routerLink]="['hotline']"
              class="e-btn e-small e-info e-outline btn-p-zero m-1"
            >Zobrazit kalendář</a
            >
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 col-md-6">
      <div *ngIf="currDate && events" class="card bg-transparent">
        <div class="e-card-header d-flex justify-content-between p-3">
                    <span>
                        <i
                          aria-hidden="true"
                          class="fa-light rounded fa-calendar-week fa-2x p-2 text-primary mr-2"
                        ></i>
                        Oznámení a Události
                    </span>
          <span
          ><strong>{{
              currDate | date: 'dd. MM. yyyy'
            }}</strong></span
          >
        </div>
        <div class="e-card-content text-center p-0 m-0">
          <div *ngIf="loadingEventWidget" class="row">
            <div class="col-12 text-center">
              <small class="text-muted">načítám data</small>
            </div>
          </div>
          <div
            *ngIf="!loadingEventWidget"
            class="table-responsive p-2 square scrollbar-winter-neva square thin"
            style="max-height: 400px; overflow-y: auto"
          >
            <table
              *ngIf="events && events.length > 0"
              class="table mb-1"
            >
              <tbody>
              <tr
                *ngFor="
                                        let event of events;
                                        trackBy: trackEventID;
                                        let i = index
                                    "
              >
                <td class="text-left">
                  <img
                    alt="profilový obrázek"
                    class="img-fluid rounded-circle avatar-xs mr-1"
                    src="assets/img{{
                                                event.creator.personalphoto
                                            }}"
                  />
                  &nbsp;
                  {{ event.creator.firstname }}
                  {{ event.creator.secondname }}
                  &nbsp;
                  <small class="text-muted">
                    {{
                      event.created_date
                        | timeago: live
                    }}
                  </small>
                </td>
                <td class="text-right">
                  {{ event.subject }}
                  <br *ngIf="event.address" />
                  <span *ngIf="event.address">
                                            <i
                                              aria-hidden="true"
                                              class="fa-light fa-1x fa-map-marked mr-2"
                                            ></i
                                            >Místo konání:
                                            <a
                                              href="https://maps.google.com/?daddr={{
                                                    event.address
                                                        | urlencode: true
                                                }}"
                                              target="_blank"
                                              title="začít navigovat"
                                            >{{ event.address }}</a
                                            >
                                        </span>
                  <br />
                  <small class="text-muted">
                    OD:
                    {{
                      event.start_at
                        | dateFormat
                        : 'DD.MM.YYYY HH:mm'
                    }}
                    ({{
                      event.start_at | timeago: live
                    }}) DO:
                    {{
                      event.end_at
                        | dateFormat
                        : 'DD.MM.YYYY HH:mm'
                    }}
                    ({{ event.end_at | timeago: live }})
                  </small>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="card-footer">
          <div class="d-flex justify-content-end">
            <a
              [routerLink]="['events']"
              class="e-btn e-small e-info e-outline btn-p-zero m-1"
            >Zobrazit rozvrh</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<app-stats-card></app-stats-card>

<section id="bottom_widgets">
  <div class="row">
    <div class="col-lg-6 col-md-12">
      <div *ngIf="tickets" class="e-card">
        <div class="e-card-content">
          <div class="row d-flex justify-content-between">
            <div *ngIf="loadingTicketkWidget" class="col-4">
              <app-loader></app-loader>
            </div>
            <div *ngIf="!loadingTicketkWidget" class="col-4">
              <span class="text-muted">Tickety</span>
              <span class="badge badge-danger ml-2">{{
                  tickets.length
                }}</span>
              <span class="sr-only">tickety ve frontě</span>
            </div>
            <div class="col-8 text-right">
              <a
                [queryParams]="{ view: 'table' }"
                [routerLink]="['tickets']"
                class="btn btn-sm grey text-white m-1"
              >Zobrazit</a
              >
            </div>
          </div>
          <hr />
          <div *ngIf="loadingTicketkWidget" class="row">
            <div class="col-12 text-center">
              <small class="text-muted">načítám data</small>
            </div>
          </div>
          <div
            *ngIf="!loadingTicketkWidget"
            class="table-responsive p-2 square scrollbar-cyan square thin"
            style="max-height: 400px; overflow-y: auto"
          >
            <table
              *ngIf="tickets.length > 0"
              class="table mb-1"
            >
              <tbody>
              <tr>
                <th>stav</th>
                <th>název</th>
                <th>zadavatel</th>
                <th>vytvořeno</th>
                <th></th>
              </tr>
              <tr
                *ngFor="
                                        let ticket of tickets;
                                        trackBy: trackTicketID
                                    "
              >
                <td>
                                        <span class="e-badge e-badge-warning"
                                        >ve frontě</span
                                        >
                  <span
                    *ngIf="ticket.due === 'overdue'"
                    class="e-badge e-badge-danger"
                  >po termínu</span
                  >
                  <span
                    *ngIf="ticket.due === 'indue'"
                    class="e-badge e-badge-success"
                  >v termínu</span
                  >
                </td>
                <td>{{
                    ticket.subject | truncateText: 100
                  }}
                </td>
                <td>
                  {{ ticket.creator.secondname }}
                  {{ ticket.creator.firstname }}
                </td>
                <td class="hour">
                  <small>
                                            <span class="grey-text">
                                                <i
                                                  aria-hidden="true"
                                                  class="fa-light fa-clock"
                                                ></i>
                                              {{
                                                ticket.unixCreatedTime
                                                  | timeago: live
                                              }}
                                            </span>
                  </small>
                </td>
                <td>
                  <small>
                    <a
                      [routerLink]="[
                                                    '/tickets/detail/',
                                                    ticket.id
                                                ]"
                    >
                      <i
                        aria-hidden="true"
                        class="fa-light fa-search"
                      ></i>
                    </a>
                  </small>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="card-footer">({{ tickets.length }})</div>
      </div>
    </div>
    <div class="col-lg-6 col-md-12 pt-4 pt-md-0">
      <div *ngIf="tasks" class="e-card">
        <div class="e-card-content">
          <div class="row d-flex justify-content-between">
            <div *ngIf="loadingTaskWidget" class="col-4">
              <app-loader></app-loader>
            </div>
            <div *ngIf="!loadingTaskWidget" class="col-4">
              <span class="text-muted">To-Do list</span>
              <span class="badge badge-danger ml-2">{{
                  tasks.length
                }}</span>
              <span class="sr-only">úkoly ve frontě</span>
            </div>
            <div class="col-8 text-right">
              <a
                [queryParams]="{ view: 'table' }"
                [routerLink]="['tasks']"
                class="btn btn-sm grey text-white m-1"
              >Zobrazit</a
              >
            </div>
          </div>
          <hr />
          <div *ngIf="loadingTaskWidget" class="row">
            <div class="col-12 text-center">
              <small class="text-muted">načítám data...</small>
            </div>
          </div>
          <div
            *ngIf="!loadingTaskWidget"
            class="table-responsive p-2 square scrollbar-cyan square thin"
            style="max-height: 400px; overflow-y: auto"
          >
            <table
              *ngIf="tasks.length > 0"
              class="table mb-1"
            >
              <tbody>
              <tr>
                <th>stav</th>
                <th>název</th>
                <th>zadavatel</th>
                <th>termín</th>
                <th></th>
              </tr>
              <tr
                *ngFor="
                                        let task of tasks;
                                        trackBy: trackTaskID
                                    "
              >
                <td>
                                        <span class="e-badge e-badge-warning"
                                        >ve frontě</span
                                        >
                  <span
                    *ngIf="task.taskTime.overtime"
                    class="e-badge e-badge-danger"
                  >potermínu</span
                  >
                </td>
                <td>{{ task.subject }}</td>
                <td>
                  {{ task.creator.firstname }}
                  {{ task.creator.secondname }}
                </td>
                <td class="hour">
                  <small>
                                            <span class="grey-text">
                                                <i
                                                  aria-hidden="true"
                                                  class="fa-light fa-clock"
                                                ></i>
                                              {{
                                                task.unixDeadlineTime
                                                  | timeago: live
                                              }}
                                            </span>
                  </small>
                </td>
                <td>
                  <small>
                    <a
                      [routerLink]="[
                                                    '/tasks/detail/',
                                                    task.id
                                                ]"
                    >
                      <i
                        aria-hidden="true"
                        class="fa-light fa-search"
                      ></i>
                    </a>
                  </small>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="card-footer">({{ tasks.length }})</div>
      </div>
    </div>
  </div>
</section>
