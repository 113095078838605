<div
  *ngIf="knowledge?.comments.length > 0"
  class="row"
>
  <div class="threads-block">
    <ng-container
      *ngFor="let comment of knowledge.comments | threads | paginate : { id: 'commnet-item', itemsPerPage: pageSize, currentPage: page }; let last = last">
      <ng-container *ngIf="last && !contentPrinted">
        {{ onLoopFinished() }}
        {{ markComment(comment.id, contentSearched) }}
      </ng-container>
      <div
        [ngClass]="{'animated heartBeat delay-3s text-warning yellow lighten-5': markComment(comment.id, contentSearched)}"
        class="thread"
        id="comment-item-{{comment.id}}"
      >
        <div class="comment-toolbar">
          <div class="user-signature">
            <img
              alt="profilový obrázek"
              class="avatar-xs rounded-circle z-depth-1"
              src="assets/img{{comment.creator.personalphoto}}"
            />
            <div class="font-weight-bold time-block">
              <small class="text-muted font-weight-bold">
                {{ comment.creator.firstname }} {{ comment.creator.secondname }}
              </small>
              &nbsp;|&nbsp;
              <small
                *ngIf="comment.unixCreatedTime"
                class="text-muted font-weight-bold"
                style="font-size: 11px;"
              >
                {{ comment.created_date | dateFormat : 'DD.MM.YYYY HH:mm' }}&nbsp;({{ comment.unixCreatedTime | timeago: live }}
                )
              </small>
            </div>
          </div>
          <div
            aria-label="replyComment toolbar"
            class="comment-buttons"
            role="group"
          >
            <button
              (click)="!showComment ? (showComment = comment.id) : (showComment = false)"
              *ngIf="comment && !comment.deleted_date && !knowledge.deleted_date"
              class="e-btn e-primary btn-p-zero e-small"
              ejs-button
            >
              <i aria-hidden="true" class="fa-light fa-reply mr-1"></i>
              odpovědět
            </button>
            <button
              (click)="editComment(comment)"
              *ngIf="comment && comment.creator.id === currentUser.id &&!comment.deleted_date && !knowledge.deleted_date"
              cssClass="e-btn e-warning btn-p-zero e-small"
              ejs-button
            >
              <i aria-hidden="true" class="fa-light fa-pencil mr-1"></i>
              upravit
            </button>
            <button
              (click)="deleteComment(comment)"
              *ngIf="comment && comment.creator.id ===  currentUser.id && !comment.deleted_date && !knowledge.deleted_date"
              cssClass="e-btn e-danger btn-p-zero e-small"
              ejs-button
            >
              <i aria-hidden="true" class="fa-light fa-trash mr-1"></i>
              smazat
            </button>
          </div>
        </div>
        <div class="comment-text border-bottom">
          <p
            [innerHTML]="comment.textHTML"
            class="mt-2 text-knowledge"
          ></p>
        </div>
        <div
          *ngIf="showComment && showComment === comment.id"
          class="col-12"
        >
          <label for="quickReplyFormComment-{{comment.id}}">
            Vaše odpověď:
          </label>
          <ejs-richtexteditor
            #reply_text
            (actionBegin)="handleFullScreen($event)"
            (actionComplete)="actionReplyCompleteHandler($event)"
            (change)="replyChange(reply_text.value)"
            (created)="onCreateReplyRte()"
            [enablePersistence]="false"
            [fontFamily]="fontFamily"
            [insertImageSettings]="insertImageSettings"
            [maxLength]="maxLength"
            [pasteCleanupSettings]="pasteCleanupSettings"
            [quickToolbarSettings]="quickToolbarSettings"
            [showCharCount]="true"
            [toolbarSettings]="tools"
            id="quickReplyFormComment-{{comment.id}}"
            locale="cs"
            placeholder="Začněte psát odpověď na komentář..."
          >
          </ejs-richtexteditor>
          <div
            class="text-right my-2"
          >
            <button
              (click)="onReply(comment.id, reply)"
              [disabled]="loadingComment || knowledge.deleted_date !== null || !reply_text.value"
              cssClass="e-btn e-success btn-p-zero e-small"
              ejs-button
              type="submit"
            >
                                <span
                                  *ngIf="loadingComment"
                                  aria-hidden="true"
                                  class="spinner-grow spinner-grow-sm"
                                  role="status"
                                ></span>
              <span *ngIf="!loadingComment">Odpovědět</span>
              <span *ngIf="loadingComment">Provádím...</span>
            </button>
          </div>
        </div>
      </div>
      <div
        (scrolled)="onScroll()"
        *ngIf="comment.replies.length > 0"
        [infiniteScrollDistance]="2"
        [infiniteScrollThrottle]="50"
        class="threads-block"
        infiniteScroll
        style="max-height: 250px; overflow: auto;"
      >
        <ng-container *ngFor="let reply of comment.replies;  let i = index">
          <div
            *ngIf="i < limit"
            [ngClass]="{'animated heartBeat delay-3s text-warning yellow lighten-5': markComment(reply.id, contentSearched)}"
            class="thread isReply"
            id="reply-item-{{reply.id}}"
          >
            <div class="comment-toolbar">
              <div class="user-signature">
                <img
                  alt="profilový obrázek"
                  class="avatar-xs rounded-circle z-depth-1 d-flex mx-auto"
                  src="assets/img{{reply.creator.personalphoto}}"
                />
                <div class="font-weight-bold time-block">
                  <small class="text-muted font-weight-bold">
                    {{ reply.creator.firstname }}
                    {{ reply.creator.secondname }}
                  </small>
                  &nbsp;|&nbsp;
                  <small
                    *ngIf="reply.unixCreatedTime"
                    class="text-muted font-weight-bold"
                    style="font-size: 11px;"
                  >
                    {{ reply.created_date | dateFormat : 'DD.MM.YYYY HH:mm' }}&nbsp;({{ reply.unixCreatedTime | timeago: live }}
                    )
                  </small>
                </div>
              </div>
              <div
                aria-label="replyComment toolbar"
                class="comment-buttons"
                role="group"
              >
                <button
                  (click)="editComment(reply)"
                  *ngIf="reply && reply.creator.id === currentUser.id && !reply.deleted_date && !knowledge.deleted_date"
                  cssClass="e-btn e-warning btn-p-zero e-small"
                  ejs-button
                >
                  <i aria-hidden="true" class="fa-light fa-pencil mr-1"></i>
                  upravit
                </button>
                <button
                  (click)="deleteComment(reply)"
                  *ngIf="reply && reply.creator.id === currentUser.id && !reply.deleted_date && !knowledge.deleted_date"
                  cssClass="e-btn e-danger btn-p-zero e-small"
                  ejs-button
                >
                  <i aria-hidden="true" class="fa-light fa-trash mr-1"></i>
                  smazat
                </button>
              </div>
            </div>
            <div class="comment-text border-bottom">
              <p
                [innerHTML]="reply.textHTML"
                class="mt-2 text-knowledge"
                style="font-size: 12px;"
              ></p>
            </div>
          </div>
        </ng-container>
      </div>
    </ng-container>
  </div>
  <div class="col-12">
    <nav class="d-flex justify-content-center mt-5">
      <pagination-controls
        (pageChange)="page = $event"
        id="commnet-item"
        nextLabel="Další"
        previousLabel="Předchozí"
      >
      </pagination-controls>
    </nav>
  </div>
</div>
