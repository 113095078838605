import {Injectable} from '@angular/core';
import {AddressConnectedField} from '@src/app/_models/services/address-connected-field';
import {AddressConnectedRuian} from '@src/app/_models/services/address-connected-ruian';
import {PlanningOutages} from '@src/app/_models/services/planning-outages';
import {AddressExcluded} from '@src/app/_models/services/address-excluded';
import {NetPackages} from '@src/app/_models/services/net-packages';
import {Places} from '@src/app/_models/services/places';
import {TvServices} from '@src/app/_models/services/tv-services';
import {ExtendServices} from '@src/app/_models/services/extend-services';
import {TvPackages} from '@src/app/_models/services/tv-packages';
import {HardwareConfig} from '@src/app/_models/services/hardware-config';
import {Client} from '@src/app/_models/clients/client';
import {ClientService} from '@src/app/_models/clients/client-service';
import {AddressUntrusted} from '@src/app/_models/services/address-untrusted';
import {Company} from '@src/app/_models/company/company';
import {TvChannels} from '@src/app/_models/services/tv-channels';
import {DealTypes} from '@src/app/_models/services/deal-types';
import {BehaviorSubject} from 'rxjs';
import {DataNetbaseService} from '@src/app/_services/data-netbase.service';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {WirelessAddressType} from '@src/app/features/services/_forms/callwizard/types/wireless-address.type';
import {PriceType} from '@src/app/features/services/_forms/callwizard/types/price.type';
import {AddressObj} from '@src/app/features/services/_forms/callwizard/types/smartform-address.type';

@UntilDestroy()
@Injectable({
    providedIn: 'root',
})
export class CallwizardService {
    feePeriod = 1;

    purchaseWifi = 'purchase';

    discount = 0;

    totalCsPrice = 0;

    setttopboxFee = 0;

    vipinstallFee = 0;

    setttopboxFeeAnnualy = 0;

    internetPriceAnnualy = 0;

    televisionPriceAnnualy = 0;

    extServicesPriceAnnualy = 0;

    wifiPriceAnnualy = 0;

    dealAge: number | undefined = 24;

    choosenWiFiServices: BehaviorSubject<HardwareConfig | null> =
        new BehaviorSubject<HardwareConfig | null>(null);

    choosenInternetTarif: BehaviorSubject<NetPackages | null> =
        new BehaviorSubject<NetPackages | null>(null);

    skipNetTarif = false;

    choosenTelevisionTarif: BehaviorSubject<TvServices | null> =
        new BehaviorSubject<TvServices | null>(null);

    choosenTelevisionPackages: BehaviorSubject<TvPackages | null> =
        new BehaviorSubject<TvPackages | null>(null);

    choosenTelevisionExtensions: BehaviorSubject<TvPackages[]> = new BehaviorSubject<TvPackages[]>(
        [],
    );

    choosenExtendedServices: BehaviorSubject<ExtendServices[]> = new BehaviorSubject<
        ExtendServices[]
    >([]);

    connectedClients: BehaviorSubject<Awaited<Client | undefined>[]> = new BehaviorSubject<
        Awaited<Client | undefined>[]
    >([]);

    connectedServices: BehaviorSubject<ClientService[]> = new BehaviorSubject<ClientService[]>([]);

    connectedClientServices: BehaviorSubject<ClientService[]> = new BehaviorSubject<
        ClientService[]
    >([]);

    unstrustedAddresses: BehaviorSubject<AddressUntrusted[]> = new BehaviorSubject<
        AddressUntrusted[]
    >([]);

    excludedAddresses: BehaviorSubject<AddressExcluded[]> = new BehaviorSubject<AddressExcluded[]>(
        [],
    );

    hardwareConfigs: BehaviorSubject<HardwareConfig[]> = new BehaviorSubject<HardwareConfig[]>([]);

    matchedWifiSets: BehaviorSubject<HardwareConfig[]> = new BehaviorSubject<HardwareConfig[]>([]);

    matchedConnectedRuianAddress: BehaviorSubject<AddressConnectedRuian | null> =
        new BehaviorSubject<AddressConnectedRuian | null>(null);

    searchedConnectedFieldAddress: BehaviorSubject<AddressConnectedField[]> = new BehaviorSubject<
        AddressConnectedField[]
    >([]);

    matchedConnectedFieldAddress: BehaviorSubject<AddressConnectedField | null> =
        new BehaviorSubject<AddressConnectedField | null>(null);

    matchedUntrustedAddresses: BehaviorSubject<AddressUntrusted | null> =
        new BehaviorSubject<AddressUntrusted | null>(null);

    matchedExcludedAddresses: BehaviorSubject<AddressExcluded | null> =
        new BehaviorSubject<AddressExcluded | null>(null);

    matchedWirelessAddress: BehaviorSubject<WirelessAddressType | null> =
        new BehaviorSubject<WirelessAddressType | null>(null);

    matchedNetPlacesPackages: BehaviorSubject<NetPackages[]> = new BehaviorSubject<NetPackages[]>(
        [],
    );

    matchedTvPlacesServices: BehaviorSubject<TvServices[]> = new BehaviorSubject<TvServices[]>([]);

    matchedOutages: BehaviorSubject<PlanningOutages[]> = new BehaviorSubject<PlanningOutages[]>([]);

    activeTvServices: BehaviorSubject<TvServices[]> = new BehaviorSubject<TvServices[]>([]);

    activeNetPackages: BehaviorSubject<NetPackages[]> = new BehaviorSubject<NetPackages[]>([]);

    outages: BehaviorSubject<PlanningOutages[]> = new BehaviorSubject<PlanningOutages[]>([]);

    companies: BehaviorSubject<Company[]> = new BehaviorSubject<Company[]>([]);

    channels: BehaviorSubject<TvChannels[]> = new BehaviorSubject<TvChannels[]>([]);

    deals: BehaviorSubject<DealTypes[]> = new BehaviorSubject<DealTypes[]>([]);

    internetPrice: BehaviorSubject<PriceType> = new BehaviorSubject<PriceType>({
        period: this.feePeriod,
        fee: 0,
        price: 0,
        discount: this.discount,
    });

    televisionPrice: BehaviorSubject<PriceType> = new BehaviorSubject<PriceType>({
        period: this.feePeriod,
        fee: 0,
        price: 0,
        discount: this.discount,
    });

    extServicesPrice: BehaviorSubject<PriceType> = new BehaviorSubject<PriceType>({
        period: this.feePeriod,
        fee: 0,
        price: 0,
        discount: this.discount,
    });

    wifiPrice: BehaviorSubject<PriceType> = new BehaviorSubject<PriceType>({
        period: this.feePeriod,
        fee: 0,
        price: 0,
        discount: this.discount,
    });

    totalPrice: BehaviorSubject<PriceType> = new BehaviorSubject<PriceType>({
        period: this.feePeriod,
        fee: 0,
        price: 0,
        discount: this.discount,
    });

    constructor(private readonly dataNetbaseService: DataNetbaseService) {
        // UNTRUSTED ADDRESSES
        this.dataNetbaseService.untrustedAddressSource.pipe(untilDestroyed(this))
            .subscribe(
                (addresses: AddressUntrusted[] | undefined) => {
                    if (addresses) {
                        this.unstrustedAddresses.next(addresses);
                    }
                },
                error => {
                    console.error(error);
                },
            );

        // EXCLUDED ADDRESSES
        this.dataNetbaseService.excludedAddressSource.pipe(untilDestroyed(this))
            .subscribe(
                (exAddresses: AddressExcluded[] | undefined) => {
                    if (exAddresses) {
                        this.excludedAddresses.next(exAddresses);
                    }
                },
                error => {
                    console.error(error);
                },
            );

        // AVAILABLE HARDWARE CONFIGURATIONS
        this.dataNetbaseService.hardwareConfigSource.pipe(untilDestroyed(this))
            .subscribe(
                (configs: HardwareConfig[] | undefined) => {
                    if (configs) {
                        this.hardwareConfigs.next(
                            configs.sort((a, b) => {
                                const aValue = a.hardware.name;
                                const bValue = b.hardware.name;

                                return aValue > bValue ? -1 : 1;
                            }),
                        );
                    }
                },
                error => {
                    console.error(error);
                },
            );
    }

    async setAddress(address: AddressObj): Promise<void> {
        const promises: Promise<
            AddressConnectedField[] | AddressConnectedRuian | PlanningOutages[] | undefined
        >[] = [];

        // Výpadky služeb na adrese
        const outagesObj = this.dataNetbaseService
            .searchOutageDataSource(
                true,
                address.searchedAddress,
                address.ruianCode,
                address.streetCode,
                address.cityCode,
                address.partCode,
                address.districtCode,
                address.regionCode,
            )
            .toPromise();

        promises.push(outagesObj);

        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const ruianObj = this.dataNetbaseService.searchRuianData(address.ruianCode!)
            .toPromise();

        promises.push(ruianObj);

        const fieldObj = this.dataNetbaseService
            .searchFieldData(address.searchedAddress)
            .toPromise();

        promises.push(fieldObj);
        await Promise.all(promises)
            .then(result => {
                // MATCH: outages
                this.matchedOutages.next(result[0] as PlanningOutages[]);
                // MATCH: RUIAN (optical/cable address)
                this.matchedConnectedRuianAddress.next(result[1] as AddressConnectedRuian);
                // MATCH: FIELD (optical/cable address)
                this.searchedConnectedFieldAddress.next(result[2] as AddressConnectedField[]);

                // MATCH: unstrusted adddress
                const untrustedAddress = this.unstrustedAddresses
                    .getValue()
                    .find(x => x.ruian === address.ruianCode);

                this.matchedUntrustedAddresses.next(untrustedAddress ?? null);

                if (address.ruianCode) {
                    this.dataNetbaseService.setClientAddressServicesSource(
                        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                        address.searchedAddress,
                        address.ruianCode,
                    );
                }

                this.matchedNetPlacesPackages.next([]);
                this.matchedTvPlacesServices.next([]);

                let matchedExcludedAddresses: AddressExcluded[] = [];

                // RESOLVE: Wireless NET excluded
                if (this.activeNetPackages.getValue().length > 0) {
                    this.activeNetPackages.getValue()
                        .map((pckg: NetPackages) => {
                            matchedExcludedAddresses = pckg.excluded_addresses.filter(
                                (x: AddressExcluded) =>
                                    x.address === address.searchedAddress ||
                                    x.ruian === address.ruianCode ||
                                    (x.address_obj &&
                                        ((x.address_obj.street_code &&
                                                parseInt(x.address_obj.street_code, 10) ===
                                                address.streetCode) ||
                                            (x.address_obj.city_part_code &&
                                                parseInt(x.address_obj.city_part_code, 10) ===
                                                address.partCode) ||
                                            (x.address_obj.city_code &&
                                                parseInt(x.address_obj.city_code, 10) ===
                                                address.cityCode) ||
                                            (x.address_obj.district_code &&
                                                parseInt(x.address_obj.district_code, 10) ===
                                                address.districtCode) ||
                                            (x.address_obj.region_code &&
                                                parseInt(x.address_obj.region_code, 10) ===
                                                address.regionCode))),
                            );

                            pckg.excludeOff = matchedExcludedAddresses.length > 0;

                            if (pckg.net_places.length > 0) {
                                pckg.net_places.forEach((place: Places) => {
                                    if (
                                        place.ruian === address.ruianCode ||
                                        place.street_code === address.streetCode ||
                                        place.city_part_code === address.partCode ||
                                        place.city_code === address.cityCode ||
                                        place.district_code === address.districtCode ||
                                        place.region_code === address.regionCode
                                    ) {
                                        this.matchedNetPlacesPackages.next([
                                            ...this.matchedNetPlacesPackages.getValue(),
                                            pckg,
                                        ]);
                                    }
                                });
                            }
                        });
                }

                // RESOLVE: Wireless TV excluded
                if (this.activeTvServices.getValue().length > 0) {
                    this.activeTvServices.getValue()
                        .map((srv: TvServices) => {
                            matchedExcludedAddresses = srv.excluded_addresses.filter(
                                (x: AddressExcluded) =>
                                    x.address === address.searchedAddress ||
                                    x.ruian === address.ruianCode ||
                                    (x.address_obj &&
                                        ((x.address_obj.street_code &&
                                                parseInt(x.address_obj.street_code, 10) ===
                                                address.streetCode) ||
                                            (x.address_obj.city_part_code &&
                                                parseInt(x.address_obj.city_part_code, 10) ===
                                                address.partCode) ||
                                            (x.address_obj.city_code &&
                                                parseInt(x.address_obj.city_code, 10) ===
                                                address.cityCode) ||
                                            (x.address_obj.district_code &&
                                                parseInt(x.address_obj.district_code, 10) ===
                                                address.districtCode) ||
                                            (x.address_obj.region_code &&
                                                parseInt(x.address_obj.region_code, 10) ===
                                                address.regionCode))),
                            );

                            srv.excludeOff = matchedExcludedAddresses.length > 0;

                            if (srv.tv_places.length > 0) {
                                srv.tv_places.forEach((place: Places) => {
                                    if (
                                        place.ruian === address.ruianCode ||
                                        place.street_code === address.streetCode ||
                                        place.city_part_code === address.partCode ||
                                        place.city_code === address.cityCode ||
                                        place.district_code === address.districtCode ||
                                        place.region_code === address.regionCode
                                    ) {
                                        this.matchedTvPlacesServices.next([
                                            ...this.matchedTvPlacesServices.getValue(),
                                            srv,
                                        ]);
                                    }
                                });
                            }
                        });
                }

                // MATCH: Wireless TV+NET with excluded
                if (this.matchedNetPlacesPackages.getValue().length > 0) {
                    this.matchedWirelessAddress.next({
                        connection_method: 'radio',
                        technology: 'access point',
                        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                        ruian: address.ruianCode!,
                        net_packages: this.matchedNetPlacesPackages.getValue(),
                        tv_services: this.matchedTvPlacesServices.getValue(),
                    });
                } else if (address.searchedAddress && address.searchedAddress.length > 0) {
                    this.matchedWirelessAddress.next({
                        connection_method: 'DSL',
                        technology: 'modem',
                        ruian: address.ruianCode,
                        net_packages: this.activeNetPackages
                            .getValue()
                            .filter(x => x.transfer.connection_method === 'DSL'),
                        tv_services: this.activeTvServices
                            .getValue()
                            .filter(x => x.transfer.connection_method === 'DSL'),
                    });
                }
            });
    }

    reCountTotalFee(): void {
        if (this.feePeriod === 12) {
            this.setttopboxFeeAnnualy =
                this.setttopboxFee > 0
                    ? this.setttopboxFee * this.feePeriod -
                    (this.setttopboxFee * this.feePeriod * this.discount) / 100
                    : 0;

            this.internetPriceAnnualy =
                this.internetPrice.getValue().fee * this.internetPrice.getValue().period -
                (this.internetPrice.getValue().fee *
                    this.internetPrice.getValue().period *
                    this.discount) /
                100;

            this.televisionPriceAnnualy =
                this.televisionPrice.getValue().fee * this.televisionPrice.getValue().period -
                (this.televisionPrice.getValue().fee *
                    this.televisionPrice.getValue().period *
                    this.discount) /
                100;

            this.extServicesPriceAnnualy =
                this.extServicesPrice.getValue().fee * this.extServicesPrice.getValue().period -
                (this.extServicesPrice.getValue().fee *
                    this.extServicesPrice.getValue().period *
                    this.discount) /
                100;

            this.wifiPriceAnnualy =
                this.wifiPrice.getValue().fee * this.wifiPrice.getValue().period -
                (this.wifiPrice.getValue().fee * this.wifiPrice.getValue().period * this.discount) /
                100;

            this.totalPrice.next({
                ...this.totalPrice.getValue(),
                fee:
                    this.internetPriceAnnualy +
                    this.televisionPriceAnnualy +
                    this.extServicesPriceAnnualy +
                    this.wifiPriceAnnualy +
                    this.setttopboxFeeAnnualy,
            });
        } else {
            this.totalPrice.next({
                ...this.totalPrice.getValue(),
                fee:
                    this.internetPrice.getValue().fee +
                    this.televisionPrice.getValue().fee +
                    this.extServicesPrice.getValue().fee +
                    this.wifiPrice.getValue().fee +
                    this.setttopboxFee,
            });
        }

        this.totalPrice.next({
            ...this.totalPrice.getValue(),
            price:
                this.internetPrice.getValue().price +
                this.televisionPrice.getValue().price +
                this.extServicesPrice.getValue().price +
                this.wifiPrice.getValue().price +
                this.vipinstallFee,
        });
    }

    setNetTarif(tarif: NetPackages): void {
        this.choosenInternetTarif.next(tarif);
        this.internetPrice.next({
            ...this.internetPrice.getValue(),
            price: this.choosenInternetTarif.getValue()?.price ?? 0,
        });

        this.reCountTotalFee();
    }

    unsetNetTarif(): void {
        this.internetPrice.next({
            period: this.feePeriod,
            fee: 0,
            price: 0,
            discount: this.discount,
        });

        this.extServicesPrice.next({
            period: this.feePeriod,
            fee: 0,
            price: 0,
            discount: this.discount,
        });

        this.choosenInternetTarif.next(null);
        this.choosenExtendedServices.next([]);

        this.reCountTotalFee();
        this.unsetTvTarif();
    }

    async setTvTarif(tarif: TvServices): Promise<void> {
        this.choosenTelevisionTarif.next(tarif);
        this.televisionPrice.next({
            ...this.televisionPrice.getValue(),
            price: this.choosenTelevisionTarif.getValue()?.pernament_price ?? 0,
        });

        if (this.choosenTelevisionTarif.getValue()?.tv_packages) {
            const promises: Promise<TvPackages | undefined>[] = [];

            this.choosenTelevisionTarif
                .getValue()
                ?.tv_packages
                ?.forEach((tvPackage: TvPackages) => {
                    const packageObj = this.dataNetbaseService
                        .getTvPackageData(tvPackage.id)
                        .toPromise();

                    promises.push(packageObj);
                });

            await Promise.all(promises)
                .then(result => {
                    if (this.choosenTelevisionTarif.getValue() && result.length > 0) {
                        const sorted = result
                            .map(value => value as unknown as TvPackages)
                            .sort((a, b) => {
                                const valueA = a.sale_order;
                                const valueB = b.sale_order;

                                return valueA < valueB ? -1 : 1;
                            });

                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-expect-error
                        this.choosenTelevisionTarif.next({
                            ...this.choosenTelevisionTarif.getValue(),
                            tv_packages: sorted,
                        });
                    }
                });
        }

        this.reCountTotalFee();
    }

    unsetTvTarif(): void {
        if (this.choosenTelevisionTarif.getValue()) {
            this.televisionPrice.next({
                period: this.feePeriod,
                fee: 0,
                price: 0,
                discount: this.discount,
            });
            this.choosenTelevisionTarif.next(null);
        }

        this.reCountTotalFee();
        this.unsetTvPackage();
    }

    setTvPackage(tvPackage: TvPackages): void {
        this.choosenTelevisionPackages.next(tvPackage);

        if (!this.televisionPrice.getValue().fee) {
            this.televisionPrice.next({...this.televisionPrice.getValue(), fee: tvPackage.price});
        }

        this.reCountTotalFee();
    }

    unsetTvPackage(): void {
        if (this.choosenTelevisionPackages.getValue()) {
            this.televisionPrice.next({
                ...this.televisionPrice.getValue(),
                fee: this.choosenTelevisionPackages.getValue()?.price ?? 0,
            });
            this.choosenTelevisionPackages.next(null);
            this.unsetTvExtension();
        }

        this.reCountTotalFee();
    }

    addTvExtension(tvExtension: TvPackages): void {
        this.choosenTelevisionExtensions.next([
            ...this.choosenTelevisionExtensions.getValue(),
            tvExtension,
        ]);

        this.televisionPrice.next({
            ...this.televisionPrice.getValue(),
            fee: (this.televisionPrice.getValue().fee += tvExtension.price),
        });
        this.reCountTotalFee();
    }

    removeTvExtension(tvExtension: TvPackages): void {
        const index = this.choosenTelevisionExtensions.getValue()
            .indexOf(tvExtension);

        if (index && index > -1) {
            this.televisionPrice.next({
                ...this.televisionPrice.getValue(),
                fee: (this.televisionPrice.getValue().fee -=
                    this.choosenTelevisionExtensions[index].price),
            });

            this.choosenTelevisionExtensions.next(
                this.choosenTelevisionExtensions.getValue()
                    .splice(index, 1),
            );
        }

        this.reCountTotalFee();
    }

    unsetTvExtension(): void {
        let feePrice: number = this.televisionPrice.getValue().fee;

        this.choosenTelevisionExtensions.getValue()
            .forEach((extension: TvPackages) => {
                feePrice -= extension.price;
            });

        this.televisionPrice.next({
            ...this.televisionPrice.getValue(),
            fee: feePrice,
        });

        this.choosenTelevisionExtensions.next([]);

        this.reCountTotalFee();
    }

    addExtService(extService: ExtendServices): void {
        let matchedExtService = -1;

        this.choosenExtendedServices
            .getValue()
            .forEach((extendedService: ExtendServices, index) => {
                if (extendedService.id === extService.id) {
                    matchedExtService = index;
                }
            });

        if (matchedExtService >= 0) {
            this.extServicesPrice.next({
                ...this.extServicesPrice.getValue(),
                fee: (this.extServicesPrice.getValue().fee -= extService.price),
            });

            this.choosenExtendedServices.next(
                this.choosenExtendedServices.getValue()
                    .splice(matchedExtService, 1),
            );
        } else {
            this.extServicesPrice.next({
                ...this.extServicesPrice.getValue(),
                fee: (this.extServicesPrice.getValue().fee += extService.price),
            });

            this.choosenExtendedServices.next([
                ...this.choosenExtendedServices.getValue(),
                extService,
            ]);
        }

        this.reCountTotalFee();
    }

    setWifiService(wifi: HardwareConfig): void {
        this.choosenWiFiServices.next(wifi);
        this.wifiPrice.next({
            ...this.wifiPrice.getValue(),
            fee: 0,
            price: this.choosenWiFiServices.getValue()?.hardware.price ?? 0,
        });
        this.reCountTotalFee();
    }

    setWifiSellMode(): void {
        if (this.choosenWiFiServices.getValue()) {
            if (this.purchaseWifi === 'fee') {
                this.wifiPrice.next({
                    ...this.wifiPrice.getValue(),
                    fee: this.choosenWiFiServices.getValue()?.hardware.fee ?? 0,
                    price: 0,
                });
            } else {
                this.wifiPrice.next({
                    ...this.wifiPrice.getValue(),
                    fee: 0,
                    price: this.choosenWiFiServices.getValue()?.hardware.price ?? 0,
                });
            }
        } else {
            this.wifiPrice.next({
                ...this.wifiPrice.getValue(),
                fee: 0,
                price: 0,
            });
        }

        this.reCountTotalFee();
    }

    unsetAll(): void {
        this.dealAge = 24;
        this.feePeriod = 1;
        this.discount = 0;
        this.connectedClientServices.next([]);

        this.connectedClients.next([]);
        this.connectedServices.next([]);
        this.connectedClientServices.next([]);

        this.matchedWifiSets.next([]);
        this.matchedConnectedRuianAddress.next(null);
        this.matchedConnectedFieldAddress.next(null);
        this.matchedUntrustedAddresses.next(null);
        this.matchedExcludedAddresses.next(null);
        this.matchedWirelessAddress.next(null);
        this.matchedNetPlacesPackages.next([]);
        this.matchedTvPlacesServices.next([]);
        this.searchedConnectedFieldAddress.next([]);
        this.wifiPrice.next({
            period: this.feePeriod,
            fee: 0,
            price: 0,
            discount: this.discount,
        });
        this.totalCsPrice = 0;
        this.extServicesPrice.next({
            period: this.feePeriod,
            fee: 0,
            price: 0,
            discount: this.discount,
        });

        this.unsetNetTarif();
    }
}
