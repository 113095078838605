import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {EnvironmentService} from '@src/app/_services/environment.service';
import {TaskComment} from '@src/app/_models/task/task-comment';
import {MessageService} from '../../_services/message.service';
import {TicketComment} from '../../_models/ticket/ticket-comment';
import {KnowledgeComment} from '../../_models/knowledge/knowledge-comment';

const httpOptions = {
    headers: new HttpHeaders({'Content-Type': 'application/json'}),
};

export interface KwbCommentInput {
    text?: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    knowledge_id?: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    created_by?: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    is_thread?: boolean;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    thread_id?: number;
}

export interface TicketCommentInput {
    text: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    ticket_id?: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    created_by?: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    is_thread?: boolean;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    thread_id?: number;
}

export interface TaskCommentInput {
    text: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    task_id?: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    created_by?: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    is_thread?: boolean;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    thread_id?: number;
}

@Injectable({
    providedIn: 'root',
})
export class CommentsService {
    private readonly commentsTicketUrl: string;

    private readonly commentsTaskUrl: string;

    private readonly commentsKwbUrl: string;

    constructor(
        private readonly http: HttpClient,
        private readonly messageService: MessageService,
        private readonly environmentService: EnvironmentService,
    ) {
        this.commentsTicketUrl = this.environmentService.backendURL + '/api/comments/ticket';
        this.commentsKwbUrl = this.environmentService.backendURL + '/api/comments/kwb';
        this.commentsTaskUrl = this.environmentService.backendURL + '/api/comments/task';
    }

    /**
     * Handle Http operation that failed.
     * Let the app continue.
     * @param operation - name of the operation that failed
     * @param result - optional value to return as the observable result
     */
    private handleError<T>(operation = 'operation', result?: T) {
        return (error: {[key: string]: string}): Observable<T | undefined> => {
            // TODO: send the error to remote logging infrastructure
            console.error(error); // log to console instead

            // TODO: better job of transforming error for user consumption
            this.log(`${operation} failed: ${error.message}`);

            // Let the app keep running by returning an empty result.
            return of(result);
        };
    }

    /** Log a commentservice message with the MessageService */
    private log(message: string): void {
        this.messageService.addNotification(`Comment: ${message}`, false);
    }

    addTicketComment(comment: TicketCommentInput): Observable<TicketComment> {
        return this.http.post<TicketComment>(this.commentsTicketUrl, comment, httpOptions);
    }

    addTaskComment(comment: TaskCommentInput): Observable<TaskComment> {
        return this.http.post<TaskComment>(this.commentsTaskUrl, comment, httpOptions);
    }

    addKwbComment(comment: KwbCommentInput): Observable<KnowledgeComment> {
        return this.http.post<KnowledgeComment>(this.commentsKwbUrl, comment, httpOptions);
    }

    deleteKwbComment(
        comment: KnowledgeComment,
    ): Observable<KnowledgeComment | undefined> | undefined {
        if (!comment.deleted_by) {
            console.error('comment.deleted_by is missing...');

            return;
        }

        const headers = new HttpHeaders({'Content-Type': 'application/json'});
        const params = new HttpParams()
            .set('type', 'knowledge')
            .set('deleted_by', comment.deleted_by.toString());
        const url = `${this.commentsKwbUrl}/${comment.id}`;

        return this.http.delete<KnowledgeComment>(url, {headers, params});
    }

    deleteTicketComment(comment: TicketComment): Observable<TicketComment | undefined> | undefined {
        if (!comment.deleted_by) {
            console.error('comment.deleted_by is missing...');

            return;
        }

        const headers = new HttpHeaders({'Content-Type': 'application/json'});
        const params = new HttpParams()
            .set('type', 'ticket')
            .set('deleted_by', comment.deleted_by.toString());
        const url = `${this.commentsTicketUrl}/${comment.id}`;

        return this.http.delete<TicketComment>(url, {headers, params});
    }

    deleteTaskComment(comment: TaskComment): Observable<TicketComment | undefined> | undefined {
        if (!comment.deleted_by) {
            console.error('comment.deleted_by is missing...');

            return;
        }

        const headers = new HttpHeaders({'Content-Type': 'application/json'});
        const params = new HttpParams()
            .set('type', 'task')
            .set('deleted_by', comment.deleted_by.toString());
        const url = `${this.commentsTaskUrl}/${comment.id}`;

        return this.http.delete<TicketComment>(url, {headers, params});
    }

    updateKwbComment(comment: KnowledgeComment): Observable<KnowledgeComment | undefined> {
        const url = `${this.commentsKwbUrl}/${comment.id}`;
        const headers = new HttpHeaders({'Content-Type': 'application/json'});
        const params = new HttpParams().set('type', 'knowledge');

        return this.http.put<KnowledgeComment>(url, comment, {headers, params});
    }

    updateTicketComment(comment: TicketComment): Observable<TicketComment | undefined> {
        const url = `${this.commentsTicketUrl}/${comment.id}`;
        const headers = new HttpHeaders({'Content-Type': 'application/json'});
        const params = new HttpParams().set('type', 'ticket');

        return this.http.put<TicketComment>(url, comment, {headers, params});
    }

    updateTaskComment(comment: TaskComment): Observable<TaskComment | undefined> {
        const url = `${this.commentsTaskUrl}/${comment.id}`;
        const headers = new HttpHeaders({'Content-Type': 'application/json'});
        const params = new HttpParams().set('type', 'task');

        return this.http.put<TaskComment>(url, comment, {headers, params});
    }
}
