import {Injectable} from '@angular/core';
import {saveAs} from 'file-saver';
import * as XLSX from 'xlsx';
import {registerLocaleData} from '@angular/common';
import moment from 'moment';
import localeCs from '@angular/common/locales/cs';

const excelType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const excelExtension = '.xlsx';
const csvType = 'text/csv;charset=utf-8';
const csvExtension = '.csv';

moment.updateLocale('cs', {workingWeekdays: [1, 2, 3, 4, 5]});
registerLocaleData(localeCs);

@Injectable({
    providedIn: 'root',
})
export class ExcelService {
    private static saveAsExcelFile(buffer: ArrayBuffer, fileName: string): void {
        const data: Blob = new Blob([buffer], {
            type: excelType,
        });

        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-call
        saveAs(data, `${fileName}_export_${moment()
            .valueOf()}${excelExtension}`);
    }

    private static saveAsCsvFile(buffer: string, fileName: string): void {
        const data: Blob = new Blob([buffer], {
            type: csvType,
        });

        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-call
        saveAs(data, `${fileName}_export_${moment()
            .valueOf()}${csvExtension}`);
    }

    exportAsExcelFile(json: unknown[], excelFileName: string): void {
        const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
        const workbook: XLSX.WorkBook = {
            Sheets: {data: worksheet},
            SheetNames: ['data'],
        };
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const excelBuffer: ArrayBuffer = XLSX.write(workbook, {
            bookType: 'xlsx',
            type: 'array',
            bookSST: true,
            cellStyles: true,
            Props: {Author: 'JON.CZ s.r.o. - JONSystem export'},
        });

        ExcelService.saveAsExcelFile(excelBuffer, excelFileName);
    }

    exportAsCsvFile(json: unknown[], excelFileName: string): void {
        const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
        const csvOutput: string = XLSX.utils.sheet_to_csv(worksheet);

        ExcelService.saveAsCsvFile(csvOutput, excelFileName);
    }
}
