import {ChangeDetectionStrategy, Component} from '@angular/core';
import * as moment from 'moment-timezone';
import localeCs from '@angular/common/locales/cs';
import {registerLocaleData} from '@angular/common';
import {UntilDestroy} from '@ngneat/until-destroy';

moment.updateLocale('cs', {workingWeekdays: [1, 2, 3, 4, 5]});
registerLocaleData(localeCs);

@UntilDestroy()
@Component({
    selector: 'app-ticket-calendar',
    templateUrl: './ticket-calendar.component.html',
    styleUrls: ['./ticket-calendar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TicketCalendarComponent {
    // TODO
}
