export const isUndefined = (value: unknown): value is undefined => typeof value === 'undefined';

export const isNull = (value: unknown): value is null => value === null;

export const isNumber = (value: unknown): value is number => typeof value === 'number';

export const isNumberFinite = (value: unknown): value is number =>
    isNumber(value) && isFinite(value);

// Not strict positive
export const isPositive = (value: number): boolean => value >= 0;

export const isInteger = (value: number): boolean =>
    // No rest, is an integer
    value % 1 === 0;

export const isNil = (value: unknown): value is null | undefined =>
    value === null || typeof value === 'undefined';

export const isString = (value: unknown): value is string => typeof value === 'string';

export const isObject = (value: unknown): boolean => value !== null && typeof value === 'object';

export const isArray = (value: unknown): boolean => Array.isArray(value);

export const isFunction = (value: unknown): boolean => typeof value === 'function';

export const toDecimal = (value: number, decimal: number): number =>
    Math.round(value * Math.pow(10, decimal)) / Math.pow(10, decimal);

export const upperFirst = (value: string): string =>
    value.slice(0, 1)
        .toUpperCase() + value.slice(1);

export const toString = (value: number | string): string => `${value}`;

export const flatten = (input: unknown[], index = 0): unknown[] => {
    if (index >= input.length) {
        return input;
    }

    if (isArray(input[index])) {
        return flatten(input.slice(0, index)
            .concat(input[index], input.slice(index + 1)), index);
    }

    return flatten(input, index + 1);
};

export const sum = (input: Array<number>, initial = 0): number =>
    input.reduce((previous: number, current: number) => previous + current, initial);
