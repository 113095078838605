import {User} from '@src/app/_models/user/user';
import {Company} from '@src/app/_models/company/company';
import {TechnologyTypes} from '@src/app/_models/services/technology-types';
import {SpaceTypes} from '@src/app/_models/services/space-types';
import {Discounts} from '@src/app/_models/services/discounts';
import {DealTypes} from '@src/app/_models/services/deal-types';
import {UnitTypes} from '@src/app/_models/services/unit-types';
import {NetPackages} from '@src/app/_models/services/net-packages';
import {AddressConnectedFieldPhotos} from '@src/app/_models/services/address-connected-field-photos';
import {Project} from '@src/app/_models/projects/project';
import {TransferTypes} from '@src/app/_models/services/transfer-types';
import {TvServices} from '@src/app/_models/services/tv-services';
import {Address} from '@src/app/_models/services/address';

export class AddressConnectedField {
    id: number;

    keys: boolean;

    // eslint-disable-next-line @typescript-eslint/naming-convention
    electrical_counter: string;

    // eslint-disable-next-line @typescript-eslint/naming-convention
    clip_frame: boolean;

    // eslint-disable-next-line @typescript-eslint/naming-convention
    business_note: string;

    descriptive: string;

    marketingnote: string;

    // eslint-disable-next-line @typescript-eslint/naming-convention
    marketing_ads: string;

    // eslint-disable-next-line @typescript-eslint/naming-convention
    owner_name: string;

    // eslint-disable-next-line @typescript-eslint/naming-convention
    manager_name: string;

    // eslint-disable-next-line @typescript-eslint/naming-convention
    number_of_units: number;

    // eslint-disable-next-line @typescript-eslint/naming-convention
    number_of_storeys: number;

    lift: boolean;

    // eslint-disable-next-line @typescript-eslint/naming-convention
    field_number: number;

    address: string;

    // eslint-disable-next-line @typescript-eslint/naming-convention
    provider_id: number;

    // eslint-disable-next-line @typescript-eslint/naming-convention
    project_id: number;

    // eslint-disable-next-line @typescript-eslint/naming-convention
    speed_id: number;

    // eslint-disable-next-line @typescript-eslint/naming-convention
    realized_at: string;

    // eslint-disable-next-line @typescript-eslint/naming-convention
    updated_by?: number | null;

    // eslint-disable-next-line @typescript-eslint/naming-convention
    deleted_by?: number | null;

    // eslint-disable-next-line @typescript-eslint/naming-convention
    unit_id: number;

    // eslint-disable-next-line @typescript-eslint/naming-convention
    deal_id: number;

    // eslint-disable-next-line @typescript-eslint/naming-convention
    discount_id: number;

    // eslint-disable-next-line @typescript-eslint/naming-convention
    space_id: number;

    // eslint-disable-next-line @typescript-eslint/naming-convention
    technology_id: number;

    // eslint-disable-next-line @typescript-eslint/naming-convention
    transfer_id: number;

    // eslint-disable-next-line @typescript-eslint/naming-convention
    address_id: number;

    // eslint-disable-next-line @typescript-eslint/naming-convention
    address_obj?: Address | null;

    creator: User;

    provider: Company;

    project?: Project | null;

    technology: TechnologyTypes;

    transfer: TransferTypes;

    // eslint-disable-next-line @typescript-eslint/naming-convention
    flat_space?: SpaceTypes | null;

    discount?: Discounts | null;

    // eslint-disable-next-line @typescript-eslint/naming-convention
    deal_type?: DealTypes | null;

    // eslint-disable-next-line @typescript-eslint/naming-convention
    unit_type?: UnitTypes;

    // eslint-disable-next-line @typescript-eslint/naming-convention
    net_packages: NetPackages[];

    // eslint-disable-next-line @typescript-eslint/naming-convention
    tv_services?: TvServices[] | null;

    photos?: AddressConnectedFieldPhotos[] | null;

    // eslint-disable-next-line @typescript-eslint/naming-convention
    created_date: string;

    // eslint-disable-next-line @typescript-eslint/naming-convention
    updated_date?: string | null;

    // eslint-disable-next-line @typescript-eslint/naming-convention
    deleted_date?: string | null;

    // eslint-disable-next-line @typescript-eslint/naming-convention
    created_by: number;

    unixCreatedTime: number;

    unixUpdatedTime?: number | null | undefined;

    unixDeletedTime?: number | null | undefined;
}
