<div class="row">
  <div class="col-12">
    <form (submit)="onSubmit()" [formGroup]="createMaterialForm">
      <div class="e-card">
        <div class="card-header">Základní data</div>
        <div class="e-card-content">
          <div class="row">
            <div class="col-12 mb-5 form-group">
              <label for="materialForm-reason"
              >Důvod objednávky materiálu</label
              >
              <ejs-textbox
                [ngClass]="{
                                    'e-error': submited && f.reason.errors
                                }"
                floatLabelType="Auto"
                formControlName="reason"
                id="materialForm-reason"
              ></ejs-textbox>
              <div
                *ngIf="submited && f.reason.errors"
                class="invalid-feedback"
              >
                <div *ngIf="f.reason.errors.required">
                  Důvod objednávky materiálu je povinný
                </div>
              </div>
            </div>
            <div class="col-12">
              <div class="row">
                <div class="col-6 form-group"></div>
                <div class="col-3 form-group">
                  <label for="materialForm-payment"
                  >Typ platby</label
                  >
                  <ejs-dropdownlist
                    #payment
                    (filtering)="
                                            onFilteringPayments($event)
                                        "
                    [allowFiltering]="true"
                    [cssClass]="
                                            f.payment.invalid
                                                ? 'e-error'
                                                : f.payment.valid
                                                ? 'e-success'
                                                : ''
                                        "
                    [dataSource]="paymentSelect"
                    [fields]="fields"
                    [filterBarPlaceholder]="
                                            'Vyhledat typ platby...'
                                        "
                    [ignoreAccent]="true"
                    [placeholder]="'Vyberte typ platby'"
                    [popupHeight]="height"
                    formControlName="payment"
                    id="materialForm-payment"
                    sortOrder="Ascending"
                  ></ejs-dropdownlist>
                  <div
                    *ngIf="submited && f.payment.errors"
                    class="invalid-feedback"
                  >
                    <div *ngIf="f.payment.errors.required">
                      Typ platby objednávky je povinný
                    </div>
                  </div>
                </div>
                <div class="col-3">
                  <ejs-checkbox
                    [checked]="true"
                    cssClass="e-primary p-0 m-0"
                    formControlName="to_storage"
                    label="Materiál na sklad"
                  ></ejs-checkbox>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card-header">Přidat položky objednávky</div>
        <div class="e-card-content" formArrayName="orderItems">
          <div class="row">
            <div
              *ngFor="
                                let item of orderItemsFormGroup.controls;
                                let i = index
                            "
              class="col-12"
            >
              <div [formGroupName]="i" class="row">
                <div class="col-8 col-md-10">
                  <div class="row">
                    <div class="col-12">
                      <div class="row">
                        <div class="col-4 form-group">
                          <label
                            for="materialForm-item{{
                                                            i
                                                        }}-name"
                          >Název</label
                          >
                          <ejs-textbox
                            floatLabelType="Auto"
                            formControlName="name"
                            id="materialForm-item{{
                                                            i
                                                        }}-name"
                          ></ejs-textbox>
                          <span
                            *ngIf="
                                                            getOrderItemsFormGroup(
                                                                i
                                                            ).controls['name']
                                                                .touched &&
                                                            getOrderItemsFormGroup(
                                                                i
                                                            ).controls[
                                                                'name'
                                                            ].hasError(
                                                                'required'
                                                            )
                                                        "
                            class="text-danger"
                          >Název položky je
                                                        povinný</span
                          >
                        </div>
                        <div class="col-2 form-group">
                          <label
                            for="materialForm-item{{
                                                            i
                                                        }}-amount"
                          >Počet</label
                          >
                          <ejs-textbox
                            floatLabelType="Auto"
                            formControlName="amount"
                            id="materialForm-item{{
                                                            i
                                                        }}-amount"
                          ></ejs-textbox>
                          <span
                            *ngIf="
                                                            getOrderItemsFormGroup(
                                                                i
                                                            ).controls['amount']
                                                                .touched &&
                                                            getOrderItemsFormGroup(
                                                                i
                                                            ).controls[
                                                                'amount'
                                                            ].hasError(
                                                                'required'
                                                            )
                                                        "
                            class="text-danger"
                          >Počet kusů je
                                                        povinný</span
                          >
                        </div>
                        <div class="col-4 form-group">
                          <label
                            for="materialForm-item{{
                                                            i
                                                        }}-price"
                          >Cena</label
                          >
                          <ejs-textbox
                            floatLabelType="Auto"
                            formControlName="price"
                            id="materialForm-item{{
                                                            i
                                                        }}-price"
                          ></ejs-textbox>
                          <span
                            *ngIf="
                                                            getOrderItemsFormGroup(
                                                                i
                                                            ).controls['price']
                                                                .touched &&
                                                            getOrderItemsFormGroup(
                                                                i
                                                            ).controls[
                                                                'price'
                                                            ].hasError(
                                                                'required'
                                                            )
                                                        "
                            class="text-danger"
                          >Cena položky je
                                                        povinná</span
                          >
                        </div>
                        <div class="col-2 form-group">
                          <ejs-checkbox
                            [checked]="false"
                            cssClass="e-primary p-0 m-0"
                            formControlName="dph"
                            label="cena s DPH"
                          ></ejs-checkbox>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 pt-">
                      <div class="row">
                        <div class="col-6 form-group">
                          <label
                            for="materialForm-item{{
                                                            i
                                                        }}-department"
                          >Oddělení</label
                          >
                          <ejs-dropdownlist
                            #department
                            (filtering)="
                                                            onFilteringDepartments(
                                                                $event
                                                            )
                                                        "
                            [allowFiltering]="true"
                            [cssClass]="
                                                            getOrderItemsFormGroup(
                                                                i
                                                            ).controls[
                                                                'department_id'
                                                            ].invalid
                                                                ? 'e-error'
                                                                : getOrderItemsFormGroup(
                                                                      i
                                                                  ).controls[
                                                                      'department_id'
                                                                  ] &&
                                                                  getOrderItemsFormGroup(
                                                                      i
                                                                  ).controls[
                                                                      'department_id'
                                                                  ].valid
                                                                ? 'e-success'
                                                                : ''
                                                        "
                            [dataSource]="
                                                            departmentSelect
                                                        "
                            [fields]="fields"
                            [filterBarPlaceholder]="
                                                            'Vyhledat oddělení...'
                                                        "
                            [ignoreAccent]="true"
                            [placeholder]="
                                                            'Vyberte oddělení'
                                                        "
                            [popupHeight]="height"
                            formControlName="department_id"
                            id="materialForm-item{{
                                                            i
                                                        }}-department"
                            sortOrder="Ascending"
                          ></ejs-dropdownlist>
                          <span
                            *ngIf="
                                                            getOrderItemsFormGroup(
                                                                i
                                                            ).controls[
                                                                'department_id'
                                                            ].touched &&
                                                            getOrderItemsFormGroup(
                                                                i
                                                            ).controls[
                                                                'department_id'
                                                            ].hasError(
                                                                'required'
                                                            )
                                                        "
                            class="text-danger"
                          >Vyberte jedno z
                                                        oddělení</span
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-4 col-md-2 text-right">
                  <button
                    (click)="removeItem(i)"
                    cssClass="e-btn e-danger btn-p-zero e-small"
                    ejs-button
                  >
                    Odebrat položku
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="e-card-content">
          <div class="col-12">
            <div
              *ngIf="createMaterialForm.invalid"
              class="alert alert-warning"
              role="alert"
            >
              Formulář je špatně vyplněný
              <button
                (click)="showInputErrors()"
                cssClass="e-btn e-warning btn-p-zero e-smallfloat-right"
                ejs-button
                type="button"
              >
                označ chyby
              </button>
            </div>
            <div
              *ngIf="createMaterialForm.valid"
              class="alert alert-success"
              role="alert"
            >
              Formulář je správně vyplněný
            </div>
          </div>
          <div class="col-12">
            <button
              (click)="addItem()"
              cssClass="e-btn e-primary btn-p-zero e-small mr-1"
              ejs-button
              type="button"
            >
              Přidat položku
            </button>
            <button
              [disabled]="
                                loadingMaterial || createMaterialForm.invalid
                            "
              cssClass="e-btn e-success btn-p-zero e-small"
              ejs-button
              type="submit"
            >
                            <span
                              *ngIf="loadingMaterial === true"
                              aria-hidden="true"
                              class="spinner-grow spinner-grow-sm"
                              role="status"
                            ></span>
              <span *ngIf="loadingMaterial === false"
              >Vytvořit</span
              >
              <span *ngIf="loadingMaterial === true"
              >Provádím...</span
              >
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
