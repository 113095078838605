import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    HostListener,
    Input,
    OnChanges,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MessageService} from '@src/app/_services/message.service';
import {AuthenticationService} from '@src/app/_services/authentication.service';
import {DataService} from '@src/app/_services/data.service';
import {ComponentCanDeactivate} from '@src/app/_guards/changes.guard';
import {Observable} from 'rxjs';
import {
    FontFamilyModel,
    ImageSettingsModel,
    QuickToolbarSettingsModel,
    RichTextEditorComponent,
} from '@syncfusion/ej2-angular-richtexteditor';
import {ToolbarModule} from '@syncfusion/ej2-angular-navigations';
import {addClass, Browser, createElement, removeClass} from '@syncfusion/ej2-base';
import {User} from '@src/app/_models/user/user';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {DialogComponent} from '@syncfusion/ej2-angular-popups';
import {Tickets} from '@src/app/_models/ticket/tickets';
import {EnvironmentService} from '@src/app/_services/environment.service';
import {TicketsService} from '@src/app/features/tickets/tickets.service';
import moment from 'moment/moment';
import {map} from 'rxjs/operators';
import {BaseModel} from '@src/app/_models/_base/base-model';

@UntilDestroy()
@Component({
    selector: 'app-ticket-solving-form',
    templateUrl: './ticket-solving-form.component.html',
    styleUrls: ['./ticket-solving-form.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TicketSolvingFormComponent implements OnInit, OnChanges, ComponentCanDeactivate {
    // RTE
    @ViewChild('ticketSolution') ticketSolution?: RichTextEditorComponent;

    maxLength = 15000;

    textArea: HTMLElement;

    insertImageSettings: ImageSettingsModel;

    fontFamily: FontFamilyModel = {
        items: [
            {
                text: 'Segoe UI',
                value: 'Segoe UI',
                command: 'Font',
                subCommand: 'FontName',
            },
            {
                text: 'Roboto',
                value: 'Roboto',
                command: 'Font',
                subCommand: 'FontName',
            },
            {
                text: 'Great vibes',
                value: 'Great Vibes,cursive',
                command: 'Font',
                subCommand: 'FontName',
            },
            {
                text: 'Noto Sans',
                value: 'Noto Sans',
                command: 'Font',
                subCommand: 'FontName',
            },
            {
                text: 'Impact',
                value: 'Impact,Charcoal,sans-serif',
                command: 'Font',
                subCommand: 'FontName',
            },
            {
                text: 'Tahoma',
                value: 'Tahoma,Geneva,sans-serif',
                command: 'Font',
                subCommand: 'FontName',
            },
        ],
    };

    tools: ToolbarModule = {
        type: 'MultiRow',
        items: [
            'Bold',
            'Italic',
            'Underline',
            'StrikeThrough',
            'FontName',
            'FontSize',
            'FontColor',
            'BackgroundColor',
            'LowerCase',
            'UpperCase',
            'SuperScript',
            'SubScript',
            '|',
            'Formats',
            'Alignments',
            'OrderedList',
            'UnorderedList',
            'Outdent',
            'Indent',
            '|',
            'CreateTable',
            'CreateLink',
            'Image',
            '|',
            'ClearFormat',
            'Print',
            'SourceCode',
            'FullScreen',
            '|',
            'Undo',
            'Redo',
        ],
    };

    quickToolbarSettings: QuickToolbarSettingsModel = {
        table: [
            'TableHeader',
            'TableRows',
            'TableColumns',
            'TableCell',
            '-',
            'BackgroundColor',
            'TableRemove',
            'TableCellVerticalAlign',
            'Styles',
        ],
    };

    pasteCleanupSettings: object = {
        prompt: true,
        plainText: false,
        keepFormat: false,
        deniedTags: ['a'],
        deniedAttrs: ['class', 'title', 'id'],
        allowedStyleProps: ['color', 'margin', 'font-size'],
    };

    // Froms
    createSolvingForm: FormGroup;

    isDirty = false;

    isChecked = false;

    submited = false;

    // Variables
    currentUser: User | null;

    // Loaders
    loadingNote = false;

    @Input() ticket?: Tickets | null = null;

    @Input() isCreate = false;

    @Input() isUpdate = false;

    @Input() isVisible = false;

    @Input() isCopy = false;

    @Output() readonly ticketNoteFormDialogState = new EventEmitter<boolean>();

    // Dialogs
    @ViewChild('formDialog') formDialogObj: DialogComponent;

    constructor(
        private readonly formBuilder: FormBuilder,
        private readonly ref: ChangeDetectorRef,
        private readonly ticketsService: TicketsService,
        private readonly authenticationService: AuthenticationService,
        private readonly dataService: DataService,
        private readonly messages: MessageService,
        private readonly environmentService: EnvironmentService,
    ) {
        this.currentUser = this.authenticationService.currentUserValue;
        this.insertImageSettings = {
            path: `${this.environmentService.backendURL}/api/file/preview/ticket/`,
            saveUrl: `${this.environmentService.backendURL}/api/upload/attachments/tickets?type=attachments&subfolder=tickets`,
        };

        this.createSolvingForm = this.formBuilder.group({
            solution: ['', Validators.required],
        });
    }

    get editSolvingControl(): {[key: string]: AbstractControl} {
        return this.createSolvingForm.controls;
    }

    @HostListener('window:beforeunload')
    canDeactivate(): Observable<boolean> | boolean {
        return !this.isDirty;
    }

    ngOnInit(): void {
        this.createSolvingForm.valueChanges.pipe(untilDestroyed(this))
            .subscribe(() => {
                this.isDirty = this.createSolvingForm.dirty;
                this.showInputErrors();
            });
    }

    ngOnChanges(): void {
        if (this.isVisible) {
            if (this.isUpdate && this.ticket) {
                this.createSolvingForm.reset();
                this.formDialogObj.header = `Řešení ticketu #${this.ticket?.id}`;
                this.fillTheForm();
                this.showInputErrors();
            }

            if (this.isCreate) {
                this.createSolvingForm.reset();
                this.formDialogObj.header = 'Nové řešení ticketu';
            }

            if (this.isCopy) {
                this.createSolvingForm.reset();
                this.formDialogObj.header = 'Nové řešení ticketu';
                this.fillTheForm();
                this.showInputErrors();
            }
        }
    }

    changeDialogState(value: boolean): void {
        this.ticketNoteFormDialogState.emit(value);
    }

    fillTheForm(): void {
        this.createSolvingForm.controls.solution.patchValue(this.ticket?.solution);
    }

    onSubmit(): void {
        this.submited = true;
        this.isDirty = false;
        this.loadingNote = true;

        if (this.isCreate || this.isCopy) {
            this.solveTicket();
        }

        if (this.isUpdate) {
            this.editNote();
        }
    }

    solveTicket(): void {
        this.submited = true;

        if (this.createSolvingForm.invalid || !this.ticket || !this.currentUser) {
            return;
        }

        this.loadingNote = true;
        this.ticket.solution = this.editSolvingControl.solution.value;
        this.ticket.finished_at = moment()
            .format('YYYY-MM-DD HH:mm:ss');
        this.ticket.updated_by = this.currentUser.id;
        this.ticket.solved_by = this.currentUser.id;
        this.ticket.status_id = 2;
        this.ticketsService
            .updateTicket(this.ticket)
            ?.pipe(
                untilDestroyed(this),
                map((response: BaseModel) => response.data[0] as Tickets),
            )
            .subscribe(
                (data: Tickets) => {
                    const body = `Ticket #${data.id}`;
                    const options = {progressBar: true, timeOut: 5000};

                    this.messages.showSuccess('Ticket úspěšně upraven', body, options);
                    this.loadingNote = false;
                    this.ref.markForCheck();

                    if (!this.ticket) {
                        console.error('this.ticket is missing...');

                        return;
                    }

                    this.dataService.clearTicketsCache();
                    this.dataService.setTicketDetailSource(this.ticket.id);
                    this.loadingNote = false;
                    this.ref.markForCheck();

                    this.formDialogObj.hide();
                },
                error => {
                    console.error(error);
                    this.loadingNote = false;
                    this.ref.markForCheck();
                },
            );
    }

    editNote(): void {
        this.submited = true;

        if (this.createSolvingForm.invalid || !this.ticket) {
            return;
        }

        this.loadingNote = true;
        this.ticket.solution = this.editSolvingControl.solution.value.replace(/\r?\n/g, '<br />');
        this.ticket.updated_by = this.currentUser?.id;
        this.ticketsService
            .updateTicket(this.ticket)
            ?.pipe(
                untilDestroyed(this),
                map((response: BaseModel) => response.data[0] as Tickets),
            )
            .subscribe(
                (data: Tickets) => {
                    const body = `Ticket #${data.id}`;
                    const options = {progressBar: true, timeOut: 5000};

                    this.messages.showSuccess('řešení úspěšně aktualizováno', body, options);
                    this.isUpdate = false;
                    this.loadingNote = false;
                    this.ref.markForCheck();
                    this.formDialogObj.hide();

                    if (!this.ticket) {
                        console.error('this.ticket is missing...');

                        return;
                    }

                    this.dataService.clearTicketsCache();
                    this.dataService.setTicketDetailSource(this.ticket?.id);
                },
                error => {
                    console.error(error);

                    const body = 'Zkuste to znovu později';
                    const options = {progressBar: true, timeOut: 5000};

                    this.messages.showError('Chyba při aktualizaci řešení ticketu', body, options);
                    this.loadingNote = false;
                    this.ref.markForCheck();
                },
            );
    }

    // RTE
    onCreateRte(): void {
        this.ticketSolution?.refreshUI();
        this.ticketSolution?.autoResize();
    }

    handleFullScreen(e: unknown): void {
        const sbCntEle: HTMLElement | null = document.querySelector('.sb-content.e-view');
        const sbHdrEle: HTMLElement | null = document.querySelector('.sb-header.e-view');
        const leftBar: HTMLElement | null = document.querySelector('#left-sidebar');

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        if (e.targetItem === 'Maximize') {
            if (Browser.isDevice && Browser.isIos && sbCntEle && sbHdrEle) {
                addClass([sbCntEle, sbHdrEle], ['hide-header']);
            }

            if (leftBar) {
                addClass([leftBar], ['e-close']);
                removeClass([leftBar], ['e-open']);
            }
        }
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        else if (e.targetItem === 'Minimize') {
            if (Browser.isDevice && Browser.isIos && sbCntEle && sbHdrEle) {
                removeClass([sbCntEle, sbHdrEle], ['hide-header']);
            }

            if (leftBar) {
                removeClass([leftBar], ['e-close']);

                if (!Browser.isDevice) {
                    addClass([leftBar], ['e-open']);
                }
            }
        }
    }

    mirrorConversion(e: unknown): void {
        if (this.ticketSolution) {
            const id = `${this.ticketSolution.getID()}mirror-view`;
            let mirrorView: HTMLElement | null = this.ticketSolution.element.querySelector(`#${id}`);
            const charCount: HTMLElement | null =
                this.ticketSolution.element.querySelector('.e-rte-character-count');

            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            if (e.targetItem === 'Preview' && mirrorView && charCount) {
                this.textArea.style.display = 'block';
                mirrorView.style.display = 'none';
                charCount.style.display = 'block';
            } else {
                if (!mirrorView && this.textArea.parentNode) {
                    mirrorView = createElement('div', {className: 'e-content'});
                    mirrorView.id = id;
                    this.textArea.parentNode.appendChild(mirrorView);
                } else if (mirrorView) {
                    mirrorView.innerHTML = '';
                }

                if (mirrorView) {
                    this.textArea.style.display = 'none';
                    mirrorView.style.display = 'block';
                }

                if (charCount) {
                    charCount.style.display = 'none';
                }
            }
        }
    }

    actionCompleteHandler(e: unknown): void {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        if (e.targetItem && (e.targetItem === 'SourceCode' || e.targetItem === 'Preview')) {
            (this.ticketSolution?.sourceCodeModule.getPanel() as HTMLTextAreaElement).style.display =
                'none';
            this.mirrorConversion(e);
        } else {
            setTimeout(() => {
                this.ticketSolution?.toolbarModule.refreshToolbarOverflow();
            }, 400);
        }
    }

    showInputErrors(): void {
        this.isChecked = true;
        /* console.log('VALID: ' + this.ticketForm.valid);
    Object.keys(this.ticketForm.controls).forEach(key => {
        const controlErrors: ValidationErrors = this.ticketForm.get(key).errors;
        if (controlErrors != null) {
            Object.keys(controlErrors).forEach(keyError => {
                console.log('Key control: ' + key + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError]);
            });
        }
    });*/
        this.createSolvingForm.markAllAsTouched();
        this.ref.markForCheck();
    }
}
