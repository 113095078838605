<div *ngIf="ticket?.ticketTime" class="col-12 mt-1">
  <ejs-progressbar
    #ticketTimePercentage
    (valueChanged)="changedBar($event)"
    [animation]="animation"
    [labelStyle]="labelStyle"
    [showProgressValue]="showProgressValue"
    [value]="ticket.ticketTime.percentage"
    height="50"
    id="ticketTimePercentage"
    maximum="100"
    minimum="0"
    progressThickness="15"
    trackThickness="15"
    type="Linear"
    width="100%"
  ></ejs-progressbar>
</div>
