import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    HostListener,
    Input,
    OnChanges,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import localeCs from '@angular/common/locales/cs';
import {registerLocaleData} from '@angular/common';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {DialogComponent} from '@syncfusion/ej2-angular-popups';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {AbstractControl, FormBuilder, FormGroup, ValidatorFn, Validators} from '@angular/forms';
import {DataService} from '@src/app/_services/data.service';
import {PermissionService} from '@src/app/_services/permission.service';
import {AuthenticationService} from '@src/app/_services/authentication.service';
import {MessageService} from '@src/app/_services/message.service';
import {User} from '@src/app/_models/user/user';
import {VacationCategory} from '@src/app/_models/vacation/vacation-category';
import {Observable} from 'rxjs';
import {DropDownListComponent, FilteringEventArgs} from '@syncfusion/ej2-angular-dropdowns';
import {EmitType} from '@syncfusion/ej2-base';
import {Query} from '@syncfusion/ej2-data';
import {Vacation} from '@src/app/_models/vacation/vacation';
import {Holiday} from '@src/app/_models/holiday/holiday';
import {IProgressValueEventArgs} from '@syncfusion/ej2-angular-progressbar';
import {AnimationModel, FontModel} from '@syncfusion/ej2-progressbar';
import {ComponentCanDeactivate} from '@src/app/_guards/changes.guard';
import moment, {Moment, MomentInput} from 'moment-business-days';
import {VacationInput, VacationService} from '@src/app/features/vacation/vacation.service';
import {VacationComponent} from '@src/app/features/vacation/components/vacation/vacation.component';
import {ApisService} from '@src/app/_api/apis.service';
import {AcsService} from '@src/app/_api/acs/acs.service';
import {NavHandlerService} from '@src/app/_services/nav-handler.service';

moment.updateLocale('cs', {workingWeekdays: [1, 2, 3, 4, 5]});
registerLocaleData(localeCs);

@UntilDestroy()
@Component({
    selector: 'app-vacation-form',
    templateUrl: './vacation-form.component.html',
    styleUrls: ['./vacation-form.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VacationFormComponent extends VacationComponent implements OnInit, OnChanges, ComponentCanDeactivate {
    // ProgressBars
    animationForm: AnimationModel;

    labelStyleForm: FontModel;

    showProgressValueForm: boolean;

    // Dropdowns
    fields: object = {text: 'label', value: 'value'};

    height = '240px';

    categorySelect: Array<{value: boolean | number | string; label: string}> = [];

    usersSelect: Array<{value: boolean | number | string; label: string}> = [];

    holidays: Array<string> = [];

    // Forms
    vacationForm: FormGroup;

    isDirty = false;

    isChecked = false;

    submited = false;

    currentTab = 0;

    hidden = true;

    // Variables
    currentUser: User | null;

    categories: VacationCategory[];

    matchedUser: User | null | undefined = null;

    users: User[];

    vacations: Vacation[] = [];

    limitDateStart: Date | string;

    limitDateEnd: Date | string;

    workTime = 8;

    workTimeStart = '08:00:00';

    workTimeEnd = '16:00:00';

    statsVacation = 0;

    statsSickDays = 0;

    workdays = 0;

    workhours = 0;

    // Loaders
    loadingVacation = false;

    @Input() vacation: Vacation | null = null;

    @Input() isCreate = false;

    @Input() isUpdate = false;

    @Input() isCopy = false;

    @Input() isVisible = false;

    @Output() readonly vacationFormDialogState = new EventEmitter<boolean>();

    // Dialogs
    @ViewChild('formDialog') formDialogObj: DialogComponent;

    // Dropdowns
    @ViewChild('category') categoryObj: DropDownListComponent;

    @ViewChild('creator') userObj: DropDownListComponent;

    constructor(
        authenticationService: AuthenticationService,
        router: Router,
        ref: ChangeDetectorRef,
        route: ActivatedRoute,
        apisService: ApisService,
        acsService: AcsService,
        navHandlerService: NavHandlerService,
        dataService: DataService,
        messages: MessageService,
        permissionService: PermissionService,
        private readonly formBuilder: FormBuilder,
        private readonly vacationService: VacationService,
    ) {
        super(
            authenticationService,
            router,
            ref,
            route,
            apisService,
            acsService,
            navHandlerService,
            dataService,
            messages,
            permissionService,
        );
        this.currentUser = this.authenticationService.currentUserValue;
    }

    get f(): {[key: string]: AbstractControl} {
        return this.vacationForm.controls;
    }

    @HostListener('window:beforeunload')
    canDeactivate(): Observable<boolean> | boolean {
        return !this.isDirty;
    }

    lessThanDate(fieldDate: string): ValidatorFn {
        return (control: AbstractControl): {lessThan: {value: string}} | null => {
            const fieldToDateCompare = control.parent?.get(fieldDate);
            const endDate = control.value;
            const startDate = fieldToDateCompare?.value;
            const isLessThan =
                Number(moment(startDate as MomentInput)
                    .valueOf()) >
                Number(moment(endDate as MomentInput)
                    .valueOf());

            return isLessThan ? {lessThan: {value: control.value}} : null;
        };
    }

    greaterThanDate(fieldDate: string): ValidatorFn {
        return (control: AbstractControl): {greaterThan: {value: string}} | null => {
            const fieldToDateCompare = control.parent?.get(fieldDate);
            const startDate = control.value;
            const endDate = fieldToDateCompare?.value;
            const isGreaterThan =
                Number(moment(startDate as MomentInput)
                    .valueOf()) >
                Number(moment(endDate as MomentInput)
                    .valueOf());

            return isGreaterThan ? {greaterThan: {value: control.value}} : null;
        };
    }

    dateInPast(): ValidatorFn {
        return (control: AbstractControl): {isInThePast: {value: string}} | null => {
            const startDateInput = control.value;
            const isInThePast =
                Number(moment(startDateInput as MomentInput)
                    .valueOf()) <
                Number(moment()
                    .startOf('day')
                    .valueOf());

            return isInThePast &&
            !(
                this.currentUser &&
                (this.permissionService.checkUserISVacationAdmin(this.currentUser) ||
                    this.currentUser.is_boss)
            )
                ? {isInThePast: {value: control.value}}
                : null;
        };
    }

    ngOnInit(): void {
        // DatePicker limit values
        const startLimit =
            this.currentUser &&
            (this.permissionService.checkUserISVacationAdmin(this.currentUser) ||
                this.currentUser.is_boss)
                ? moment()
                .startOf('year')
                .format('YYYY-MM-DD') + ' ' + this.workTimeStart
                : moment()
                .format('YYYY-MM-DD') + ' ' + this.workTimeStart;
        const endLimit = moment()
            .add(1, 'y')
            .format('YYYY-MM-DD') + ' ' + this.workTimeEnd;

        this.limitDateStart = new Date(moment(startLimit)
            .toISOString());
        this.limitDateEnd = new Date(moment(endLimit)
            .toISOString());
        this.ref.markForCheck();

        this.dataService.vacationSource.pipe(untilDestroyed(this))
            .subscribe(
                (vacations: Vacation[]) => {
                    this.vacations = vacations;

                    if (this.vacation || this.matchedUser || this.currentUser) {
                        this.setStats(
                            this.vacation
                                ? this.vacation.created_by
                                : this.matchedUser?.id ?? this.currentUser?.id,
                        );
                    }
                },
                error => {
                    console.error(error);
                },
            );

        this.dataService.vacationCategoriesSource.pipe(untilDestroyed(this))
            .subscribe(
                (categories: VacationCategory[]) => {
                    this.categorySelect = [];
                    this.categories = categories;
                    categories.map((category: VacationCategory) => {
                        if (!category.deleted_date) {
                            this.categorySelect = [
                                ...this.categorySelect,
                                {
                                    value: category.id,
                                    label: category.name,
                                },
                            ];
                        }
                    });
                    this.ref.markForCheck();
                },
                error => {
                    console.error(error);
                },
            );

        this.dataService.userSource.pipe(untilDestroyed(this))
            .subscribe(
                (users: User[]) => {
                    this.users = users;
                    this.usersSelect = [];
                    users.map((user: User) => {
                        if (
                            this.currentUser &&
                            user.authorized &&
                            !user.deleted_date &&
                            (user.id === this.currentUser.id ||
                                user.boss_id === this.currentUser.id ||
                                this.permissionService.checkUserISVacationAdmin(this.currentUser))
                        ) {
                            this.usersSelect = [
                                ...this.usersSelect,
                                {
                                    value: user.id,
                                    label: user.secondname + ' ' + user.firstname,
                                },
                            ];
                        }
                    });
                    this.ref.markForCheck();
                },
                error => {
                    console.error(error);
                },
            );

        this.vacationForm = this.formBuilder.group({
            created_by: [null, Validators.required],
            category_id: [null, Validators.required],
            reason: ['', Validators.required],
            start_at: [null, Validators.required],
            end_at: [null, Validators.required],
        });

        // DatePicker custom validators
        this.vacationForm.get('end_at')
            ?.addValidators(this.lessThanDate('start_at'));
        this.vacationForm.get('start_at')
            ?.addValidators(this.greaterThanDate('end_at'));
        this.vacationForm.get('start_at')
            ?.addValidators(this.dateInPast());

        this.vacationForm.valueChanges.pipe(untilDestroyed(this))
            .subscribe(() => {
                this.isDirty = this.vacationForm.dirty;
                this.showVacationInputErrors();
            });

        this.vacationForm
            .get('created_by')
            ?.valueChanges
            .pipe(untilDestroyed(this))
            .subscribe(value => {
                this.matchedUser = this.users.find(
                    user => user.id === (value ? value : this.currentUser?.id),
                );

                if (this.matchedUser) {
                    this.setStats(this.matchedUser.id);
                }
            });

        // DatePicker correct values
        this.vacationForm
            .get('start_at')
            ?.valueChanges
            .pipe(untilDestroyed(this))
            .subscribe((value: string) => {
                if (value) {
                    if (moment(value)
                        .format('HH:mm') < this.workTimeStart) {
                        const start = moment(value)
                            .format('YYYY-MM-DD') + ' ' + this.workTimeStart;

                        this.vacationForm
                            .get('start_at')
                            ?.patchValue(new Date(moment(start)
                                .toISOString()), {
                                onlySelf: true,
                                emitEvent: false,
                            });
                    }

                    if (moment(value)
                        .format('HH:mm') > this.workTimeEnd) {
                        const end = moment(value)
                            .format('YYYY-MM-DD') + ' ' + this.workTimeEnd;

                        this.vacationForm
                            .get('start_at')
                            ?.patchValue(new Date(moment(end)
                                .toISOString()), {
                                onlySelf: true,
                                emitEvent: false,
                            });
                    }

                    this.getWorkingDays(value, this.f.end_at.value as string);
                    this.vacationService.checkVacationsIndex(
                        value,
                        this.f.end_at.value as string,
                        this.f.created_by.value as number,
                    );
                    this.ref.markForCheck();
                }
            });

        this.vacationForm
            .get('end_at')
            ?.valueChanges
            .pipe(untilDestroyed(this))
            .subscribe((value: string) => {
                if (value) {
                    if (moment(value)
                        .format('HH:mm') < this.workTimeStart) {
                        const start = moment(value)
                            .format('YYYY-MM-DD') + ' ' + this.workTimeStart;

                        this.vacationForm
                            .get('end_at')
                            ?.patchValue(new Date(moment(start)
                                .toISOString()), {
                                onlySelf: true,
                                emitEvent: false,
                            });
                    }

                    if (moment(value)
                        .format('HH:mm') > this.workTimeEnd) {
                        const end = moment(value)
                            .format('YYYY-MM-DD') + ' ' + this.workTimeEnd;

                        this.vacationForm
                            .get('end_at')
                            ?.patchValue(new Date(moment(end)
                                .toISOString()), {
                                onlySelf: true,
                                emitEvent: false,
                            });
                    }
                }

                this.getWorkingDays(this.f.start_at.value as string, this.f.end_at.value as string);
                this.vacationService.checkVacationsIndex(
                    this.f.start_at.value as string,
                    this.f.end_at.value as string,
                    this.f.created_by.value as number,
                );
                this.ref.markForCheck();
            });
    }

    ngOnChanges(): void {
        if (this.isVisible) {
            const queryParams: Params = {form: null};

            void this.router.navigate([], {
                relativeTo: this.route,
                queryParams,
                queryParamsHandling: 'merge',
            });

            if (this.isUpdate && this.vacation) {
                this.formDialogObj.header = `Žádanka #${this.vacation.id} od ${this.vacation.creator.fullname}`;
                this.fillTheForm();
                this.showVacationInputErrors();
            }

            if (this.isCreate) {
                this.vacationForm.reset();
                this.formDialogObj.header = 'Nová žádanka';
                this.fillTheForm();
            }

            if (this.isCopy) {
                this.vacationForm.reset();
                this.formDialogObj.header = 'Nová žádanka';
                this.fillTheForm();
                this.showVacationInputErrors();
            }
        }
    }

    changeDialogState(value: boolean): void {
        this.vacationFormDialogState.emit(value);
    }

    changedSickBar(args: IProgressValueEventArgs): void {
        if (args.value < 30) {
            args.progressColor = '#629337';
        } else if (args.value >= 30 && args.value <= 70) {
            args.progressColor = '#FFBB34';
        } else if (args.value > 70) {
            args.progressColor = '#FF3547';
        }
    }

    changedVacBar(args: IProgressValueEventArgs): void {
        if (args.value < 30) {
            args.progressColor = '#629337';
        } else if (args.value >= 30 && args.value <= 70) {
            args.progressColor = '#FFBB34';
        } else if (args.value > 70) {
            args.progressColor = '#FF3547';
        }
    }

    onFilteringCategories: EmitType<FilteringEventArgs> = (e: FilteringEventArgs) => {
        if (e.text === '') {
            e.updateData(this.categorySelect);
        } else {
            let query: Query = new Query();

            query = e.text !== '' ? query.where('label', 'contains', e.text, true, true) : query;
            e.updateData(this.categorySelect, query);
        }
    };

    onFilteringUsers: EmitType<FilteringEventArgs> = (e: FilteringEventArgs) => {
        if (e.text === '') {
            e.updateData(this.usersSelect);
        } else {
            let query: Query = new Query();

            query = e.text !== '' ? query.where('label', 'contains', e.text, true, true) : query;
            e.updateData(this.usersSelect, query);
        }
    };

    onRenderCell(args): void {
        if (args.date.getDay() === 0 || args.date.getDay() === 6) {
            args.isDisabled = true;
        }
    }

    nextBtn(): void {
        if (this.vacationForm.valid) {
            if (this.currentTab === 0) {
                this.currentTab++;
                this.nextpre(this.currentTab);
            }
        }
    }

    previousBtn(): void {
        if (this.vacationForm.valid) {
            this.currentTab--;
            this.nextpre(this.currentTab);
        }
    }

    nextpre(current): void {
        this.hidden = current !== 1;
        this.ref.markForCheck();
    }

    // nastavení vyčerpané dovolené
    setStats(userID: number | undefined): void {
        const currYear = moment()
            .year();

        this.statsVacation = 0;
        this.statsSickDays = 0;
        this.vacations.map((vacation: Vacation) => {
            if (
                vacation.created_by === userID &&
                currYear === moment(vacation.start_at)
                    .year() &&
                vacation.category_id === 1 &&
                !vacation.deleted_date &&
                !vacation.decline_at &&
                !vacation.decline
            ) {
                this.statsVacation = this.statsVacation + vacation.workdays;
            }

            if (
                vacation.created_by === userID &&
                currYear === moment(vacation.start_at)
                    .year() &&
                !vacation.deleted_date &&
                !vacation.decline_at &&
                !vacation.decline &&
                vacation.category_id === 5
            ) {
                this.statsSickDays = this.statsSickDays + vacation.workdays;
            }
        });
        this.ref.markForCheck();
    }

    getWorkingDays(start: string, end: string): void {
        this.vacationService
            .getCurrCZEHolidays()
            .pipe(untilDestroyed(this))
            .subscribe(
                // eslint-disable-next-line complexity
                (holidays: Holiday[]) => {
                    this.holidays = [];
                    holidays.map(holiday => {
                        if (holiday.isPublicHoliday) {
                            this.holidays.push(moment(holiday.date)
                                .format('YYYY-MM-DD'));
                            moment.updateLocale('cs', {
                                holidays: this.holidays,
                                holidayFormat: 'YYYY-MM-DD',
                                workingWeekdays: [1, 2, 3, 4, 5],
                            });
                        }
                    });
                    this.ref.markForCheck();

                    if (this.holidays.length === 0) {
                        this.workhours = 0;
                        this.workdays = 0;
                        this.ref.markForCheck();

                        const body = 'Kontaktujte administrátora...';
                        const options = {
                            progressBar: true,
                            timeOut: 5000,
                            toastClass: 'red',
                        };

                        this.messages.showError('Státní svátky nelze načíst.', body, options);
                    }

                    if (this.holidays.length > 0 && start && end) {
                        const dates: string[] = [];
                        let hours = 0;
                        const lastDate: Moment = moment(end);
                        const startDate: Moment = moment(start);
                        let date: Moment = moment(start);

                        // Get number of working hours
                        while (lastDate.diff(date) >= 0) {
                            if (
                                moment(date, 'YYYY-MM-DD')
                                    .isBusinessDay() &&
                                !this.holidays.includes(date.format('YYYY-MM-DD'))
                            ) {
                                dates.push(date.format('YYYY-MM-DD'));

                                if (
                                    startDate.format('YYYY-MM-DD') !== lastDate.format('YYYY-MM-DD')
                                ) {
                                    if (
                                        startDate.format('YYYY-MM-DD') === date.format('YYYY-MM-DD')
                                    ) {
                                        const todayWorkEnd =
                                            moment(date)
                                                .format('YYYY-MM-DD') +
                                            ' ' +
                                            this.workTimeEnd;
                                        const duration = moment.duration(
                                            moment(todayWorkEnd)
                                                .diff(date),
                                        );

                                        hours +=
                                            duration.hours() > 4 ||
                                            duration.hours() === 4 && duration.minutes() > 0
                                                ? 8
                                                : 4;
                                    } else if (
                                        lastDate.format('YYYY-MM-DD') === date.format('YYYY-MM-DD')
                                    ) {
                                        const duration = moment.duration(
                                            moment(lastDate)
                                                .diff(date),
                                        );

                                        hours +=
                                            duration.hours() > 4 ||
                                            duration.hours() === 4 && duration.minutes() > 0
                                                ? 8
                                                : 4;
                                    } else {
                                        hours += 8;
                                    }
                                } else {
                                    const duration = moment.duration(lastDate.diff(startDate));

                                    hours +=
                                        duration.hours() > 4 ||
                                        duration.hours() === 4 && duration.minutes() > 0
                                            ? 8
                                            : 4;
                                }
                            }

                            date = moment(
                                moment(date)
                                    .format('YYYY-MM-DD') + ' ' + this.workTimeStart,
                            );
                            date.add(1, 'days');
                        }

                        this.workhours = hours;
                        this.workdays = hours / this.workTime;
                    }
                },
                error => {
                    console.error(error);
                },
            );
        this.ref.markForCheck();
    }

    fillTheForm(): void {
        this.vacationForm.controls.created_by.patchValue(
            this.vacation ? this.vacation.created_by : this.currentUser?.id,
        );

        this.vacationForm.controls.category_id.patchValue(
            this.vacation ? this.vacation.category_id : 1,
        );
        this.vacationForm.controls.reason.patchValue(this.vacation ? this.vacation.reason : '');

        // DatePicker initial values
        const startToday = moment()
            .format('YYYY-MM-DD') + ' ' + this.workTimeStart;
        const endToday = moment()
            .format('YYYY-MM-DD') + ' ' + this.workTimeEnd;

        this.vacationForm.controls.start_at.patchValue(
            this.vacation
                ? new Date(moment(this.vacation.start_at)
                    .toISOString())
                : new Date(moment(startToday)
                    .toISOString()),
        );

        this.vacationForm.controls.end_at.patchValue(
            this.vacation
                ? new Date(moment(this.vacation.end_at)
                    .toISOString())
                : new Date(moment(endToday)
                    .toISOString()),
        );
    }

    onSubmit(): void {
        this.submited = true;
        this.isDirty = false;
        this.loadingVacation = true;

        if (this.isCreate || this.isCopy) {
            this.addVacation();
        }

        if (this.isUpdate) {
            this.editVacation();
        }
    }

    addVacation(): void {
        if (this.vacationForm.invalid) {
            console.error('form is not valid!');

            return;
        }

        if (
            this.f.category_id.value === 5 &&
            this.matchedUser &&
            this.workdays &&
            this.matchedUser.sickdays_fond -
            (this.statsSickDays ? this.statsSickDays : 0) -
            this.workdays <
            0
        ) {
            const body =
                'Zadejte žádanku sickdays maximálně do výše zbývajících pracovních dní ve fondu';
            const options = {progressBar: true, timeOut: 5000, toastClass: 'red'};

            this.messages.showError('Fond sickdays překročen', body, options);
            this.loadingVacation = false;

            return;
        }

        if (
            this.f.category_id.value === 1 &&
            this.matchedUser &&
            this.workdays &&
            this.matchedUser.vacation_fond -
            (this.statsVacation ? this.statsVacation : 0) -
            this.workdays <
            0
        ) {
            const body =
                'Zadejte žádanku dovolené maximálně do výše zbývajících pracovních dní ve fondu';
            const options = {progressBar: true, timeOut: 5000, toastClass: 'red'};

            this.messages.showError('Fond dovolené překročen', body, options);
            this.loadingVacation = false;

            return;
        }

        const vacationData = {
            reason: this.f.reason.value,
            category_id: this.f.category_id.value,
            start_at: this.f.start_at.value,
            end_at: this.f.end_at.value,
            created_by: this.f.created_by.value,
            workdays: this.workdays ? this.workdays : null,
        };

        this.vacationService
            .addVacation(vacationData)
            .pipe(untilDestroyed(this))
            .subscribe(
                data => {
                    const body = 'přidána do fronty ke schválení';
                    const options = {progressBar: true, timeOut: 5000};

                    this.messages.showSuccess('Žádanka úspěšně vytvořena', body, options);
                    this.dataService.setVacationsDataSource();
                    this.loadingVacation = false;
                    this.isCreate = false;
                    this.formDialogObj.hide();
                    this.ref.markForCheck();
                    void this.router.navigate(['/vacation/detail', data.id]);
                },
                error => {
                    console.error(error);

                    const body = 'Zkuste to znovu později';
                    const options = {
                        progressBar: true,
                        timeOut: 5000,
                        toastClass: 'red',
                    };

                    this.messages.showError('Chyba při vytváření žádanky', body, options);
                    this.loadingVacation = false;
                    this.ref.markForCheck();
                },
            );
    }

    copyVacation(): void {
        this.isCreate = true;
        this.isUpdate = false;
        this.vacationForm.reset();
        this.formDialogObj.header = 'Nová žádanka';
        this.fillTheForm();
        this.showVacationInputErrors();
        this.ref.markForCheck();
        this.formDialogObj.show();
    }

    // eslint-disable-next-line complexity
    editVacation(): void {
        if (this.vacationForm.invalid) {
            console.error('form is not valid!');

            return;
        }

        if (
            this.vacation &&
            this.currentUser &&
            (this.vacation.unixStartTime &&
                this.vacation.unixStartTime >= moment()
                    .valueOf() &&
                !this.vacation.deleted_date &&
                !this.vacation.confirm &&
                !this.vacation.decline &&
                this.vacation.created_by === this.currentUser.id ||
                !this.vacation.deleted_date &&
                (this.permissionService.checkUserISVacationAdmin(this.currentUser) ||
                    this.vacation.creator.boss_id === this.currentUser.id))
        ) {
            if (
                this.vacation.category_id === 5 &&
                this.matchedUser &&
                this.matchedUser.sickdays_fond +
                this.vacation.workdays -
                (this.statsSickDays ? this.statsSickDays : 0) -
                this.vacation.workdays <
                0
            ) {
                const body =
                    'Zadejte žádanku sickdays maximálně do výše zbývajících pracovních dní ve fondu';
                const options = {progressBar: true, timeOut: 5000};

                this.messages.showError('Fond sickdays překročen', body, options);
                this.loadingVacation = false;

                return;
            }

            this.loadingVacation = true;
            this.isDirty = false;

            const vacationData: VacationInput = {
                id: this.vacation.id,
                reason: this.f.reason.value,
                category_id: this.f.category_id.value,
                start_at: this.f.start_at.value,
                end_at: this.f.end_at.value,
                updated_by: this.currentUser.id,
                workdays: this.workdays ? this.workdays : null,
            };

            this.vacationService
                .updateVacation(vacationData)
                ?.pipe(untilDestroyed(this))
                .subscribe({
                    next: data => {
                        const options = {progressBar: true, timeOut: 5000};

                        this.messages.showSuccess('Žádanka', 'úspěšně upravena', options);
                        this.dataService.setVacationsDataSource();
                        this.dataService.setVacationDetailDataSource(data.id);
                        this.loadingVacation = false;
                        this.isUpdate = false;
                        this.formDialogObj.hide();
                        this.ref.markForCheck();
                    },
                    error: error => {
                        console.error(error);

                        const body = 'Zkuste to znovu později';
                        const options = {progressBar: true, timeOut: 5000};

                        this.messages.showError('Chyba při úpravě žádosti', body, options);
                        this.loadingVacation = false;
                        this.ref.markForCheck();
                    },
                });
        } else {
            const body = 'Nemáte oprávnění provést tuto akci...';
            const options = {progressBar: true, timeOut: 5000};

            this.messages.showError('Nedostatečné oprávnění', body, options);
            this.loadingVacation = false;
            this.isUpdate = false;
            this.formDialogObj.hide();
            this.ref.markForCheck();
        }
    }

    showVacationInputErrors(): void {
        this.isChecked = true;
        /* console.log('VALID: ' + this.ticketForm.valid);
          Object.keys(this.ticketForm.controls).forEach(key => {
              const controlErrors: ValidationErrors = this.ticketForm.get(key).errors;
              if (controlErrors != null) {
                  Object.keys(controlErrors).forEach(keyError => {
                      console.log('Key control: ' + key + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError]);
                  });
              }
          });*/
        this.vacationForm
            .get('end_at')
            ?.updateValueAndValidity({onlySelf: true, emitEvent: false});

        this.vacationForm
            .get('start_at')
            ?.updateValueAndValidity({onlySelf: true, emitEvent: false});
        this.vacationForm.markAllAsTouched();
        this.ref.markForCheck();
    }
}
