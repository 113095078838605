import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    HostListener,
    Input,
    OnChanges,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import {VacationCategory} from '@src/app/_models/vacation/vacation-category';
import {Vacation} from '@src/app/_models/vacation/vacation';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {registerLocaleData} from '@angular/common';
import {ActivatedRoute, Router} from '@angular/router';
import {MessageService} from '@src/app/_services/message.service';
import {AuthenticationService} from '@src/app/_services/authentication.service';
import {Employer} from '@src/app/_models/employer/employer';
import {User} from '@src/app/_models/user/user';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import {DataService} from '@src/app/_services/data.service';
import {ComponentCanDeactivate} from '@src/app/_guards/changes.guard';
import {Observable} from 'rxjs';
import moment from 'moment-business-days';
import localeCs from '@angular/common/locales/cs';
import {DropDownListComponent, FilteringEventArgs} from '@syncfusion/ej2-angular-dropdowns';
import {EmitType} from '@syncfusion/ej2-base';
import {Query} from '@syncfusion/ej2-data';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {DialogComponent} from '@syncfusion/ej2-angular-popups';
import {VacationInput, VacationService} from '@src/app/features/vacation/vacation.service';
import {UsersService} from '@src/app/features/users/users.service';
import {DateTime, Interval} from 'luxon';

moment.updateLocale('cs', {workingWeekdays: [1, 2, 3, 4, 5]});
registerLocaleData(localeCs);

const excelType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const excelExtension = '.xlsx';

@UntilDestroy()
@Component({
    selector: 'app-vacation-accountant-export-form',
    templateUrl: './vacation-accountant-export-form.component.html',
    styleUrls: ['./vacation-accountant-export-form.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VacationAccountantExportFormComponent
    implements OnInit, OnChanges, ComponentCanDeactivate {
    height = '240px';

    fields: object = {text: 'label', value: 'value'};

    monthsSelect: Array<{value: boolean | number | string; label: string}> = [];

    yearSelect: Array<{value: boolean | number | string; label: string}> = [];

    employerSelect: Array<{value: boolean | number | string; label: string}> = [];

    // Forms
    exportVacationForm: FormGroup;

    submited = false;

    isDirty = false;

    isChecked = false;

    // Variables
    currentUser: User | null;

    startExport: DateTime;

    endExport: DateTime;

    month: number;

    year: number;

    maxDay: number;

    vacationExport: Array<{
        // eslint-disable-next-line @typescript-eslint/naming-convention
        creator_name: string;
        vacations: Array<{
            type: string;
            // eslint-disable-next-line @typescript-eslint/naming-convention
            start_at: string;
            // eslint-disable-next-line @typescript-eslint/naming-convention
            end_at: string;
            workdays: number;
        }>;
    }> = [];

    categories?: VacationCategory[];

    users?: User[];

    employers?: Employer[];

    employer?: Employer;

    exporter?: User | null;

    // Loaders
    loadingVacation = false;

    @Input() vacations: Vacation[];

    @Input() isVisible = false;

    @Output() readonly accountantExportFormDialogState = new EventEmitter<boolean>();

    // Dialogs
    @ViewChild('formDialog') formDialogObj?: DialogComponent;

    // Dropdowms
    @ViewChild('yearList') yearObj?: DropDownListComponent;

    @ViewChild('monthList') monthObj?: DropDownListComponent;

    @ViewChild('employerList') employerObj?: DropDownListComponent;

    constructor(
        private readonly formBuilder: FormBuilder,
        private readonly ref: ChangeDetectorRef,
        private readonly userService: UsersService,
        private readonly messages: MessageService,
        private readonly authenticationService: AuthenticationService,
        private readonly dataService: DataService,
    ) {
        this.currentUser = this.authenticationService.currentUserValue;
    }

    get f(): {[key: string]: AbstractControl} {
        return this.exportVacationForm.controls;
    }

    private static saveAsExcelFile(buffer: ArrayBuffer, fileName: string): void {
        const data: Blob = new Blob([buffer], {
            type: excelType,
        });

        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-call
        FileSaver.saveAs(data, `${fileName}_export_${moment()
            .valueOf()}${excelExtension}`);
    }

    static daysInMonth(month: number, year: number): number {
        return moment(`${year} - ${month}`, 'YYYY-MM')
            .daysInMonth();
    }

    static addLeadingZero(num: number, size: number): string {
        let s = num.toString();

        while (s.length < size) {
            s = `0${s}`;
        }

        return s;
    }

    @HostListener('window:beforeunload')
    canDeactivate(): Observable<boolean> | boolean {
        return !this.isDirty;
    }

    ngOnInit(): void {
        this.dataService.employerSource.pipe(untilDestroyed(this))
            .subscribe(
                (data: Employer[]) => {
                    this.employers = data;
                    this.employerSelect = [];
                    data.map((employer: Employer) => {
                        if (!employer.deleted_date) {
                            this.employerSelect = [
                                ...this.employerSelect,
                                {value: employer.id, label: employer.name},
                            ];
                        }
                    });
                    this.ref.markForCheck();
                },
                error => {
                    console.error(error);
                },
            );

        this.monthsSelect = [
            {value: 1, label: 'Leden'},
            {value: 2, label: 'Únor'},
            {value: 3, label: 'Březen'},
            {
                value: 4,
                label: 'Duben',
            },
            {value: 5, label: 'Květen'},
            {value: 6, label: 'Červen'},
            {value: 7, label: 'Červenec'},
            {value: 8, label: 'Srpen'},
            {
                value: 9,
                label: 'Září',
            },
            {value: 10, label: 'Říjen'},
            {value: 11, label: 'Listopad'},
            {value: 12, label: 'Prosinec'},
        ];

        const start = DateTime.local(2017, 1, 1);
        const end = DateTime.now()
            .plus({year: 1});

        this.yearSelect = [];

        const intervals = Interval.fromDateTimes(start.startOf('day'), end.endOf('day'))
            .splitBy({year: 1})
            .map((d: Interval) => d.start);

        intervals.forEach(days => {
            if (!days) {
                return;
            }

            this.yearSelect = [
                ...this.yearSelect,
                {value: days.year, label: days.year.toString()},
            ];
        });

        this.yearSelect = this.yearSelect.sort((a, b) => {
            const compA = a.value;
            const compB = b.value;

            return compA > compB ? -1 : 1;
        });

        this.exportVacationForm = this.formBuilder.group({
            userEmployer: ['', Validators.required],
            month: ['', Validators.required],
            year: ['', Validators.required],
        });

        this.exportVacationForm.valueChanges.pipe(untilDestroyed(this))
            .subscribe(() => {
                this.isDirty = this.exportVacationForm.dirty;
                this.ref.markForCheck();
            });

        this.dataService.setEmployerDataSource();
    }

    ngOnChanges(): void {
        if (this.formDialogObj) {
            this.formDialogObj.header = 'Export žádanek dovolené pro účtárnu';
            this.showInputErrors();
        }
    }

    changeDialogState(value: boolean): void {
        this.accountantExportFormDialogState.emit(value);
    }

    onFilteringEmployer: EmitType<FilteringEventArgs> = (e: FilteringEventArgs) => {
        if (e.text === '') {
            e.updateData(this.employerSelect);
        } else {
            let query: Query = new Query();

            query = e.text !== '' ? query.where('label', 'contains', e.text, true, true) : query;
            e.updateData(this.employerSelect, query);
        }
    };

    onFilteringMonth: EmitType<FilteringEventArgs> = (e: FilteringEventArgs) => {
        if (e.text === '') {
            e.updateData(this.monthsSelect);
        } else {
            let query: Query = new Query();

            query = e.text !== '' ? query.where('label', 'contains', e.text, true, true) : query;
            e.updateData(this.monthsSelect, query);
        }
    };

    onFilteringYear: EmitType<FilteringEventArgs> = (e: FilteringEventArgs) => {
        if (e.text === '') {
            e.updateData(this.yearSelect);
        } else {
            let query: Query = new Query();

            query = e.text !== '' ? query.where('label', 'contains', e.text, true, true) : query;
            e.updateData(this.yearSelect, query);
        }
    };

    onSubmit(): void {
        if (this.exportVacationForm.invalid) {
            console.error('form is not valid!');

            return;
        }

        this.submited = true;

        const exportData: VacationInput[] = [];

        this.year = this.f.year.value;
        this.month = this.f.month.value;
        this.maxDay = DateTime.local(this.year, this.month).daysInMonth ?? 0;

        this.startExport = DateTime.local(this.year, this.month, 1, 0, 0, 0);
        this.endExport = DateTime.local(this.year, this.month, this.maxDay, 23, 59, 59);
        this.employer = this.employers?.find(x => x.id === this.f.userEmployer.value);
        this.exporter = this.currentUser;
        this.dataService.userSource.pipe(untilDestroyed(this))
            .subscribe({
                next: (users: User[]) => {
                    users.forEach((user: User) => {
                        if (
                            user.employer_id === this.employer?.id &&
                            this.userService.checkUserStateByDate(
                                this.startExport,
                                this.endExport,
                                user,
                            )
                        ) {
                            exportData.push({
                                creator_name: user.firstname + ' ' + user.secondname,
                                end_at: null,
                                start_at: null,
                                type: null,
                                workdays: null,
                            });

                            this.vacations.forEach((vacation: Vacation) => {
                                if (vacation.created_by === user.id) {
                                    const vacStart = DateTime.fromISO(vacation.start_at);
                                    const vacEnd = DateTime.fromISO(vacation.end_at);

                                    if (
                                        ((vacStart >= this.startExport && vacEnd <= this.endExport) ||
                                            (vacEnd >= this.startExport && vacEnd <= this.endExport) ||
                                            (vacStart >= this.startExport &&
                                                vacStart <= this.endExport)) &&
                                        vacStart <= vacEnd &&
                                        vacation.confirm &&
                                        !vacation.decline &&
                                        !vacation.deleted_date
                                    ) {
                                        exportData.push({
                                            creator_name:
                                                vacation.creator.firstname +
                                                ' ' +
                                                vacation.creator.secondname,
                                            end_at: vacEnd.toISO(),
                                            start_at: vacStart.toISO(),
                                            type: vacation.category.name,
                                            workdays: vacation.workdays,
                                        });
                                    }
                                }
                            });
                        }
                    });

                    const groups = exportData.reduce((obj, item: VacationInput) => {
                        if (!item.creator_name) {
                            console.error('creator_name is missing...');

                            return;
                        }

                        obj[item.creator_name] = obj[item.creator_name] || [];
                        obj[item.creator_name].push({
                            type: item.type,
                            start_at: item.start_at,
                            end_at: item.end_at,
                            workdays: item.workdays,
                        });

                        return obj;
                    }, {});

                    if (!groups) {
                        console.error('groups is missing...');

                        return;
                    }

                    this.vacationExport = Object.keys(groups)
                        .map((key: string) => ({
                            creator_name: key,
                            vacations: groups[key],
                        }));

                    const options = {progressBar: true, timeOut: 5000};

                    this.messages.showSuccess('Export', 'náhled tabulky připraven...', options);
                    this.loadingVacation = false;
                    this.ref.markForCheck();
                },
                error: error => {
                    console.error(error);
                },
            });
    }

    exportTable(): void {
        const worksheet: XLSX.WorkSheet = XLSX.utils.table_to_sheet(
            document.getElementById('exported_accountant_table'),
        );
        const workbook: XLSX.WorkBook = {
            Sheets: {data: worksheet},
            SheetNames: ['data'],
        };
        const excelBuffer: ArrayBuffer = XLSX.write(workbook, {
            bookType: 'xlsx',
            type: 'array',
            bookSST: true,
            cellStyles: true,
            Props: {Author: 'JON.CZ s.r.o. - JONSystem export'},
        });

        // const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'buffer' });
        const fileName = this.employer?.name.toLocaleLowerCase()
            .replace(' ', '_');

        VacationAccountantExportFormComponent.saveAsExcelFile(
            excelBuffer,
            `${fileName ?? 'unknown'}-vacations'`,
        );
        this.loadingVacation = false;
        this.ref.markForCheck();
        this.formDialogObj?.hide();
    }

    showInputErrors(): void {
        this.isChecked = true;
        /* console.log('VALID: ' + this.ticketForm.valid);
          Object.keys(this.ticketForm.controls).forEach(key => {
              const controlErrors: ValidationErrors = this.ticketForm.get(key).errors;
              if (controlErrors != null) {
                  Object.keys(controlErrors).forEach(keyError => {
                      console.log('Key control: ' + key + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError]);
                  });
              }
          });*/
        this.exportVacationForm.markAllAsTouched();
        this.ref.markForCheck();
    }

    compareDates(dateA: DateTime, dateB: DateTime): boolean {
        return (
            dateA
                .set({
                    hour: 0,
                    minute: 0,
                    second: 0,
                })
                .valueOf() ===
            dateB
                .set({
                    hour: 0,
                    minute: 0,
                    second: 0,
                })
                .valueOf()
        );
    }
}
