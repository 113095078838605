<app-booking-form
  (bookingFormDialogState)="
        renderBookFormDialog = $event;
        isCreate = $event;
        isUpdate = $event;
        isCopy = $event
    "
  [booking]="clickedRow && (isUpdate || isCopy) ? clickedRow : null"
  [clickedDate]="today"
  [isCopy]="isCopy"
  [isCreate]="isCreate"
  [isUpdate]="isUpdate"
  [isVisible]="renderBookFormDialog"
></app-booking-form>
<div class="row d-flex justify-content-center modalWrapper">
  <ejs-dialog
    #deleteBookingDialog
    [buttons]="buttonsDeleteBookingDialog"
    [content]="'Opravdu chcete smazat označenou rezervaci?'"
    [header]="'Smazat rezervaci'"
    [height]="'200px'"
    [isModal]="true"
    [showCloseIcon]="true"
    [visible]="false"
    [width]="'450px'"
    id="deleteBookingDialog"
  ></ejs-dialog>
</div>
<div class="row mt-3">
  <div class="col-12 col-md-6 col-lg-5">
    <div class="row">
      <div class="col-12 text-right">
        <button
          (click)="isCreate = true; renderBookFormDialog = true"
          class="e-btn e-primary btn-p-zero e-small mb-4"
          type="button"
        >
          Vytvořit rezervaci
        </button>
      </div>
      <div
        *ngIf="(this.loadingBookings | async) === true"
        class="col-12 text-center"
      >
        <app-loader></app-loader>
      </div>
      <div class="col-12">
        <ejs-accordion>
          <e-accordionitems>
            <e-accordionitem
              *ngIf="
                                (this.loadingBookings | async) === false &&
                                bookingDisplay.length > 0
                            "
              expanded="true"
            >
              <ng-template #header>
                                <span>
                                    <i
                                      aria-hidden="true"
                                      class="fa-light fa-users mr-1"
                                    ></i>
                                    Rezervace pracovních prostorů a vozidel
                                </span>
                <span
                  *ngIf="bookingDisplay.length > 0"
                  class="e-badge e-badge-primary"
                >{{ bookingDisplay.length }}</span
                >
              </ng-template>
              <ng-template #content>
                <div>
                  <ng-container
                    *ngFor="let booking of bookingDisplay"
                  >
                    <div class="media mb-3">
                      <div
                        class="d-flex justify-content-between align-items-center"
                      >
                        <h5
                          *ngIf="
                                                        today ===
                                                        (booking.start
                                                            | date
                                                                : 'yyyy-MM-dd')
                                                    "
                          class="h5-responsive font-weight-bold mr-3"
                        >
                          {{
                            booking.start
                              | date: 'HH:mm'
                          }}
                          <br />
                          <small class="text-muted"
                          >začátek</small
                          >
                        </h5>
                        <div
                          *ngIf="
                                                        today ===
                                                            (booking.end
                                                                | date
                                                                    : 'yyyy-MM-dd') &&
                                                        today ===
                                                            (booking.start
                                                                | date
                                                                    : 'yyyy-MM-dd')
                                                    "
                          class="arrow"
                        >
                        </div>
                        <h5
                          *ngIf="
                                                        today ===
                                                        (booking.end
                                                            | date
                                                                : 'yyyy-MM-dd')
                                                    "
                          class="h5-responsive font-weight-bold mr-3"
                        >
                          {{
                            booking.end
                              | date: 'HH:mm'
                          }}
                          <br />
                          <small class="text-muted"
                          >konec</small
                          >
                        </h5>
                        <h5
                          *ngIf="
                                                        today !==
                                                            (booking.end
                                                                | date
                                                                    : 'yyyy-MM-dd') &&
                                                        today !==
                                                            (booking.start
                                                                | date
                                                                    : 'yyyy-MM-dd')
                                                    "
                          class="h5-responsive font-weight-bold mr-3"
                        >
                          Celý den
                        </h5>
                      </div>
                      <div class="media-body mb-3">
                        <div
                          class="d-flex justify-content-between align-items-center"
                        >
                          <div class="btn-container">
                                                        <span
                                                          *ngIf="
                                                                booking.office_id
                                                            "
                                                          class="e-badge e-badge-success w-100"
                                                        >pracovní
                                                            prostor</span
                                                        >
                            <span
                              *ngIf="
                                                                booking.car_id
                                                            "
                              class="e-badge e-badge-danger w-100"
                            >vozidlo</span
                            >
                            <button
                              (click)="
                                                                isCreate = false;
                                                                isUpdate = false;
                                                                isCopy = false;
                                                                clickRow(
                                                                    booking
                                                                );
                                                                deleteBookingDialog.show()
                                                            "
                              cssClass="e-btn e-danger e-outline btn-p-zero e-small m-auto"
                              ejs-button
                              style="
                                                                cursor: pointer;
                                                            "
                              title="smazat rezervaci"
                            >
                              <i
                                aria-hidden="true"
                                class="fa-light fa-1x fa-times"
                              ></i>
                            </button>
                            <button
                              (click)="
                                                                isCreate = false;
                                                                isUpdate = true;
                                                                isCopy = false;
                                                                clickRow(
                                                                    booking
                                                                )
                                                            "
                              cssClass="e-btn e-warning e-outline btn-p-zero e-small m-auto"
                              ejs-button
                              style="
                                                                cursor: pointer;
                                                            "
                              title="upravit rezervaci"
                            >
                              <i
                                aria-hidden="true"
                                class="fa-light fa-1x fa-pencil"
                              ></i>
                            </button>
                          </div>
                          <div
                            class="description w-100 text-right"
                          >
                            <h6
                              class="mt-0 p-2 font-weight-bold"
                            >
                              <a
                                [routerLink]="[
                                                                    '/events/detail/',
                                                                    booking.id
                                                                ]"
                              >
                                {{
                                  booking.reason
                                }}
                              </a>
                            </h6>
                            <div
                              *ngIf="
                                                                booking.reason
                                                            "
                              class="mb-0 p-2"
                            >
                              <div
                                class="text-muted"
                              >{{
                                  booking.reason
                                }}
                              </div
                              >
                            </div>
                          </div>
                        </div>
                        <div
                          class="d-flex justify-content-between align-items-center pt-2"
                        >
                          <ng-container
                            *ngIf="
                                                            booking.events
                                                                .length > 0
                                                        "
                          >
                            <button
                              (click)="
                                                                redirectToEvent(
                                                                    event.id
                                                                )
                                                            "
                              *ngFor="
                                                                let event of booking.events
                                                            "
                              cssClass="e-btn e-primary btn-p-zero e-small"
                              ejs-button
                              type="button"
                            ><i
                              class="fa fa-md fa-calendar"
                            ></i>
                              {{
                                event.subject
                              }}
                            </button
                            >
                          </ng-container>
                          <ng-container
                            *ngIf="
                                                            booking.tickets
                                                                .length > 0
                                                        "
                          >
                            <button
                              (click)="
                                                                redirectToTicket(
                                                                    ticket.id
                                                                )
                                                            "
                              *ngFor="
                                                                let ticket of booking.tickets
                                                            "
                              cssClass="e-btn e-secondary btn-p-zero e-small"
                              ejs-button
                              type="button"
                            ><i
                              class="fa fa-md fa-ticket-simple"
                            ></i>
                              {{ ticket.subject }}
                            </button>
                          </ng-container>
                          <ng-container
                            *ngIf="booking.car"
                          >
                            <button
                              (click)="
                                                                redirectToCar(
                                                                    booking.car
                                                                        .id
                                                                )
                                                            "
                              cssClass="e-btn e-secondary btn-p-zero e-small"
                              ejs-button
                              type="button"
                            ><i
                              class="fa fa-md fa-car"
                            ></i>
                              {{
                                booking.car.name
                              }}
                            </button>
                          </ng-container>
                        </div>
                      </div>
                    </div>
                  </ng-container>
                </div>
              </ng-template>
            </e-accordionitem>
          </e-accordionitems>
        </ejs-accordion>
      </div>
      <div
        *ngIf="
                    (this.loadingBookings | async) === false &&
                    bookingDisplay.length < 1
                "
      >
        <p>Žádné výsledky hledání...</p>
      </div>
    </div>
  </div>
  <div class="col-12 col-md-6 col-lg-7">
    <full-calendar
      #bookingCalendar
      *ngIf="!loadingCalendar"
      [deepChangeDetection]="true"
      [options]="calendarOptions"
    ></full-calendar>
  </div>
</div>
