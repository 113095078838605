<div
  aria-label="Ticket toolbar"
  class="d-flex stylish-color-dark justify-content-end p-2"
  role="toolbar"
>
  <button
    (click)="editKnowledge()"
    *ngIf="(knowledge?.created_by === currentUser?.id || (currentUser?.rule && currentUser?.rule.is_admin)) && !knowledge?.deleted_date"
    cssClass="e-btn e-warning btn-p-zero e-small"
    ejs-button
  >
    <i
      aria-hidden="true"
      class="fa-light fa-pencil mr-lg-1"
    ></i>
    <span class="d-none d-lg-inline">
            upravit
        </span>
  </button>
  <button
    (click)="deleteKnowledge()"
    *ngIf="(knowledge?.created_by === currentUser?.id || (currentUser?.rule && currentUser?.rule.is_admin)) && !knowledge?.deleted_date"
    cssClass="e-btn e-danger btn-p-zero e-small"
    ejs-button
  >
    <i
      aria-hidden="true"
      class="fa-light fa-trash mr-lg-1"
    ></i>
    <span class="d-none d-lg-inline">
            smazat
        </span>
  </button>
</div>
