import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {AuthenticationService} from '@src/app/_services/authentication.service';
import {User} from '@src/app/_models/user/user';
import {TicketsService} from '@src/app/features/tickets/tickets.service';
import {PermissionService} from '@src/app/_services/permission.service';

@Component({
    selector: 'app-task-sidebar',
    templateUrl: './task-sidebar.component.html',
    styleUrls: ['./task-sidebar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TaskSidebarComponent {
    live: true;

    currentUser: User | null;

    @Input() task?: Task | null;

    constructor(
        private readonly authenticationService: AuthenticationService,
        public ticketsService: TicketsService,
        public permissionService: PermissionService,
    ) {
        this.currentUser = this.authenticationService.currentUserValue;
    }
}
