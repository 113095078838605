import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {Tickets} from '@src/app/_models/ticket/tickets';
import {AuthenticationService} from '@src/app/_services/authentication.service';
import {TicketsService} from '@src/app/features/tickets/tickets.service';
import {PermissionService} from '@src/app/_services/permission.service';
import {User} from '@src/app/_models/user/user';

@Component({
    selector: 'app-ticket-toolbar',
    templateUrl: './ticket-toolbar.component.html',
    styleUrls: ['./ticket-toolbar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TicketToolbarComponent {
    currentUser: User | null;

    @Input() ticket?: Tickets | null;

    @Output() readonly loading = new EventEmitter<boolean>();

    @Output() readonly createCommentEvent = new EventEmitter<boolean>();

    @Output() readonly addSolvingEvent = new EventEmitter<boolean>();

    @Output() readonly editTicketEvent = new EventEmitter<boolean>();

    @Output() readonly copyTicketEvent = new EventEmitter<boolean>();

    @Output() readonly activeTicketEvent = new EventEmitter<boolean>();

    @Output() readonly reactiveSolutionEvent = new EventEmitter<boolean>();

    @Output() readonly deleteTicketEvent = new EventEmitter<boolean>();

    constructor(
        private readonly authenticationService: AuthenticationService,
        public ticketsService: TicketsService,
        public permissionService: PermissionService,
    ) {
        this.currentUser = this.authenticationService.currentUserValue;
    }

    cerateComment(): void {
        this.createCommentEvent.emit(true);
    }

    addSolving(): void {
        this.addSolvingEvent.emit(true);
    }

    editTicket(): void {
        this.editTicketEvent.emit(true);
    }

    copyTicket(): void {
        this.copyTicketEvent.emit(true);
    }

    activeTicket(): void {
        this.activeTicketEvent.emit(true);
    }

    reactiveSolution(): void {
        this.reactiveSolutionEvent.emit(true);
    }

    deleteTicket(): void {
        this.deleteTicketEvent.emit(true);
    }
}
