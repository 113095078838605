import {ChangeDetectionStrategy, Component, Input, OnInit, ViewChild} from '@angular/core';
import {Tickets} from '@src/app/_models/ticket/tickets';
import {IProgressValueEventArgs, ProgressBarComponent} from '@syncfusion/ej2-angular-progressbar';
import {AnimationModel, FontModel} from '@syncfusion/ej2-progressbar';

@Component({
    selector: 'app-ticket-time',
    templateUrl: './ticket-time.component.html',
    styleUrls: ['./ticket-time.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TicketTimeComponent implements OnInit {
    @Input() ticket?: Tickets | null;

    // ProgressBars
    @ViewChild('ticketTimePercentage') ticketTimePercentageObj?: ProgressBarComponent;

    // ProgressBars
    animation: AnimationModel;

    labelStyle: FontModel;

    showProgressValue: boolean;

    changedBar(args: IProgressValueEventArgs): void {
        if (args.value < 30) {
            args.progressColor = '#629337';
        } else if (args.value >= 30 && args.value <= 70) {
            args.progressColor = '#FFBB34';
        } else if (args.value > 70) {
            args.progressColor = '#FF3547';
        }
    }

    ngOnInit(): void {
        this.animation = {enable: true, duration: 2000, delay: 0};
        this.labelStyle = {
            color: '#FFFFFF',
            fontWeight: '900',
            fontFamily: 'Montserrat, sans-serif',
            fontStyle: 'normal',
        };
        this.showProgressValue = true;
    }
}
