<ejs-dialog
  #formDialog
  (close)="
        isUpdate = false;
        isCreate = false;
        isDirty = false;
        isChecked = false;
        isCopy = false;
        submited = false;
        changeDialogState(false)
    "
  (open)="onCreateRte()"
  [isModal]="true"
  [showCloseIcon]="true"
  [visible]="isVisible"
  [width]="'85%'"
  id="formDialog"
>
  <form [formGroup]="noteForm">
    <ng-template #content>
      <div class="dialogContent">
        <div class="row">
          <div class="form-group col-12">
            <label
              [ngClass]="
                                editNoteControl.text.valid &&
                                (editNoteControl.text.dirty ||
                                    editNoteControl.text.touched)
                                    ? 'text-success'
                                    : editNoteControl.text.invalid &&
                                      (editNoteControl.text.dirty ||
                                          editNoteControl.text.touched)
                                    ? 'text-danger'
                                    : 'text-muted'
                            "
              for="formNote"
            >Text postupu:</label
            >
            <ejs-richtexteditor
              #formComment
              (actionBegin)="handleFullScreen($event)"
              (actionComplete)="actionCompleteHandler($event)"
              [enablePersistence]="false"
              [fontFamily]="fontFamily"
              [insertImageSettings]="insertImageSettings"
              [maxLength]="maxLength"
              [ngClass]="
                                editNoteControl.text.touched &&
                                editNoteControl.text.invalid
                                    ? 'e-error'
                                    : editNoteControl.text.touched &&
                                      editNoteControl.text.valid
                                    ? 'e-success'
                                    : ''
                            "
              [pasteCleanupSettings]="pasteCleanupSettings"
              [quickToolbarSettings]="quickToolbarSettings"
              [showCharCount]="true"
              [toolbarSettings]="tools"
              formControlName="text"
              id="formNote"
              locale="cs"
              placeholder="Začněte psát postup..."
            >
            </ejs-richtexteditor>
            <small
              *ngIf="
                                editNoteControl.text.invalid &&
                                (editNoteControl.text.dirty ||
                                    editNoteControl.text.touched)
                            "
              class="text-danger"
            >Chybná, nebo žádná hodnota</small
            >
            <small
              *ngIf="
                                editNoteControl.text.valid &&
                                (editNoteControl.text.dirty ||
                                    editNoteControl.text.touched)
                            "
              class="text-success"
            >Správně</small
            >
            <small
              *ngIf="
                                editNoteControl.text.touched &&
                                editNoteControl.text.errors &&
                                editNoteControl.text.errors.required
                            "
              class="text-danger"
            >Text postupu je povinný</small
            >
          </div>
        </div>
      </div>
    </ng-template>
    <ng-template #footerTemplate>
      <div class="d-flex justify-content-end">
                <span
                  *ngIf="noteForm.invalid"
                  class="e-badge e-badge-warning p-2 size_2"
                  role="alert"
                >
                    <strong>Formulář je špatně vyplněný</strong>
                </span>
        <span
          *ngIf="noteForm.valid"
          class="e-badge e-badge-success p-2 size_2"
          role="alert"
        >
                    <strong>Formulář je správně vyplněný</strong>
                </span>
      </div>
      <div class="row">
        <div class="col-12 pt-2">
          <button
            (click)="onSubmit()"
            [disabled]="loadingNote || noteForm.invalid || !isChecked"
            cssClass="e-btn e-success btn-p-zero e-small mr-1"
            ejs-button
            type="submit"
          >
                        <span
                          *ngIf="loadingNote"
                          aria-hidden="true"
                          class="spinner-grow spinner-grow-sm"
                          role="status"
                        ></span>
            <span *ngIf="!loadingNote">ULOŽIT</span>
            <span *ngIf="loadingNote">Provádím...</span>
          </button>
          <button
            (click)="formDialogObj.hide()"
            cssClass="e-btn e-danger e-outline btn-p-zero e-small"
            ejs-button
            type="button"
          >
            ZRUŠIT
          </button>
        </div>
      </div>
    </ng-template>
  </form>
</ejs-dialog>
