import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CalendarModule, DateAdapter} from 'angular-calendar';
import {adapterFactory} from 'angular-calendar/date-adapters/date-fns';
import {SharedFeaturesModule} from '@src/app/shared_features/shared-features.module';
import {MaterialService} from '@src/app/features/material/material.service';
import {TasksService} from '@src/app/features/tasks/tasks.service';
import {TicketsService} from '@src/app/features/tickets/tickets.service';
import {VacationService} from '@src/app/features/vacation/vacation.service';
import {RegisterComponent} from './register/register.component';
import {LoginComponent} from './login/login.component';
import {HomeComponent} from './dashboard/home.component';
import {StatsCardComponent} from './dashboard/common/stats-card/stats-card.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        SharedFeaturesModule,
        CalendarModule.forRoot({
            provide: DateAdapter,
            useFactory: adapterFactory,
        }),
    ],
    declarations: [HomeComponent, LoginComponent, RegisterComponent, StatsCardComponent],
    exports: [HomeComponent, LoginComponent, StatsCardComponent],
    providers: [MaterialService, TasksService, TicketsService, VacationService],
})
export class LayoutModule {}
