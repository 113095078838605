<ejs-tab
  #seracherTab
  (created)="onCreate()"
  (selected)="select($event)"
  (selecting)="preventSwipe($event)"
  enablePersistence="false"
  height="auto"
  heightAdjustMode="Content"
  id="seracherTab"
>
  <e-tabitems>
    <e-tabitem [header]="headerText[0]">
      <ng-template #content>
        <app-callwizard></app-callwizard>
      </ng-template>
    </e-tabitem>
    <e-tabitem [header]="headerText[1]">
      <ng-template #content>
        <app-searcher></app-searcher>
      </ng-template>
    </e-tabitem>
    <e-tabitem [header]="headerText[2]">
      <ng-template #content>
        <app-services-leads></app-services-leads>
      </ng-template>
    </e-tabitem>
    <e-tabitem [header]="headerText[3]">
      <ng-template #content>
        <app-services-orders></app-services-orders>
      </ng-template>
    </e-tabitem>
  </e-tabitems>
</ejs-tab>
