import {Injectable} from '@angular/core';
import {ApisService} from '@src/app/_api/apis.service';
import {ApiPaths} from '@src/app/_models/api/paths';
import {BehaviorSubject} from 'rxjs';
import {CrmUsers} from '@src/app/_models/_api_crm/users';

@Injectable({
    providedIn: 'root',
})
export class CrmService {
    private readonly resultCrmUsersSource = new BehaviorSubject<
        CrmUsers | unknown | null | undefined
    >(null);

    crmUsersSource = this.resultCrmUsersSource.asObservable();

    constructor(private readonly apisService: ApisService) {}

    setCRMUsers(path?: ApiPaths | undefined): void {
        if (typeof path !== 'undefined') {
            this.apisService.getRemoteData(21, path.maps)
                .subscribe(
                    (data: unknown[] | null | undefined) => {
                        this.resultCrmUsersSource.next(data);
                    },
                    error => {
                        console.error(error);
                    },
                );
        }
    }
}
