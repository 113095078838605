<div class="row d-flex justify-content-center modalWrapper">
  <ejs-dialog
    #confirmationRadAll
    [buttons]="buttonsReadAll"
    [content]="
            'Opravdu chcete všechny nepřečtené notifikace označit jako přečtené?'
        "
    [header]="'Označit jako přečtené'"
    [isModal]="true"
    [showCloseIcon]="true"
    [visible]="false"
    width="350px"
  ></ejs-dialog>
</div>

<ng-container *ngIf="notificationsNew$ | async as notificationsNew">
  <div class="d-flex justify-content-between">
    <div class="bold p-2">
            <span
              *ngIf="notificationsNew.length > 0"
              class="e-badge e-badge-secondary mr-2"
            >{{ notificationsNew.length }}</span
            ><small>nových upozornění:</small>
    </div>
    <button
      (click)="allAsRead()"
      [disabled]="!notificationsNew || notificationsNew.length === 0"
      class="e-btn e-info btn-p-zero e-small"
    >
      Označit vše jako přečtené
    </button>
  </div>
  <div class="container-fluid notifRow">
    <ng-container
      *ngIf="
                notificationsNew && notificationsNew.length > 0;
                then notificationList;
                else notificationEmptyList
            "
    ></ng-container>
  </div>
</ng-container>
<ng-container *ngIf="notificationsOld$ | async as notificationsOld">
  <div class="d-flex justify-content-between">
    <div class="bold p-2">
      <small>starší upozornění:</small>
    </div>
  </div>
  <div
    (scrolled)="onScroll()"
    *ngIf="notificationsOld && notificationsOld.length > 0"
    [infiniteScrollDistance]="2"
    [infiniteScrollThrottle]="50"
    [scrollWindow]="false"
    infiniteScroll
    style="overflow: auto"
  >
    <div class="container-fluid notifRow">
      <ng-container
        *ngFor="
                    let notification of notificationsOld;
                    trackBy: trackOldNotifID
                "
      >
        <div
          *ngIf="notification.viewed_at"
          class="row justify-content-between border-bottom p-0 notifContentOld"
        >
          <div class="p-1">
            <img
              alt="profilový obrázek"
              class="img-fluid rounded-circle avatar-xs mr-1"
              src="assets/img{{
                                notification.creator.personalphoto
                            }}"
            />
            <small>
              {{ notification.creator.firstname }}
              {{ notification.creator.secondname }}
            </small>
          </div>
          <div class="p-1">
            <i
              aria-hidden="true"
              class="{{ notification.type.icon_code }}"
            ></i>
            {{ notification.type.name }}
          </div>
          <div class="p-1 text-right">
            {{ notification['unixCreateDate'] | timeago: live }}
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</ng-container>
<ng-template #notificationList let-notificationsNew="notificationsNew">
  <ng-container
    *ngFor="let notification of notificationsNew; trackBy: trackNewNotifID"
  >
    <div
      (click)="readNotif(notification.id)"
      *ngIf="!notification.viewed_at"
      class="row justify-content-between border-bottom p-0 notifContentNew"
    >
      <div class="p-1">
        <img
          alt="profilový obrázek"
          class="img-fluid rounded-circle avatar-xs mr-1"
          src="assets/img{{ notification.creator.personalphoto }}"
        />
        <small>
          {{ notification.creator.firstname }}
          {{ notification.creator.secondname }}
        </small>
      </div>
      <div class="p-1">
        <i
          aria-hidden="true"
          class="{{ notification.type.icon_code }}"
        ></i>
        {{ notification.type.name }}
      </div>
      <div class="p-1 text-right">
        {{ notification['unixCreateDate'] | timeago: live }}
      </div>
    </div>
  </ng-container>
</ng-template>
<ng-template #notificationEmptyList>
  <span>nemáte žádné nové upozornění</span>
</ng-template>
