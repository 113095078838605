import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    HostListener,
    Input,
    OnChanges,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import {ComponentCanDeactivate} from '@src/app/_guards/changes.guard';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {DialogComponent} from '@syncfusion/ej2-angular-popups';
import {AbstractControl, FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {User} from '@src/app/_models/user/user';
import {Orders} from '@src/app/_models/netbase/orders';
import {DataService} from '@src/app/_services/data.service';
import {DataNetbaseService} from '@src/app/_services/data-netbase.service';
import {
    ServiceOrderInput,
    ServicesService,
    SmartTvInterface,
} from '@src/app/features/services/services.service';
import {MessageService} from '@src/app/_services/message.service';
import {AuthenticationService} from '@src/app/_services/authentication.service';
import {Observable} from 'rxjs';
import {DealTypes} from '@src/app/_models/services/deal-types';
import {UnitTypes} from '@src/app/_models/services/unit-types';
import {SpeedTypes} from '@src/app/_models/services/speed-types';
import {SpaceTypes} from '@src/app/_models/services/space-types';
import {Hardware} from '@src/app/_models/services/hardware';
import {Company} from '@src/app/_models/company/company';
import {
    DropDownListComponent,
    FilteringEventArgs,
    MultiSelectComponent,
} from '@syncfusion/ej2-angular-dropdowns';
import {EmitType} from '@syncfusion/ej2-base';
import {Query} from '@syncfusion/ej2-data';
import {PermissionService} from '@src/app/_services/permission.service';
import {ExtendServices} from '@src/app/_models/services/extend-services';
import {TvPackages} from '@src/app/_models/services/tv-packages';
import {ClientsType} from '@src/app/_models/netbase/clients-type';
import {NetPackages} from '@src/app/_models/services/net-packages';
import {TvServices} from '@src/app/_models/services/tv-services';
import {HardwareConfig} from '@src/app/_models/services/hardware-config';
import {CheckBoxComponent} from '@syncfusion/ej2-angular-buttons';
import {ApisService} from '@src/app/_api/apis.service';

@UntilDestroy()
@Component({
    selector: 'app-order-form',
    templateUrl: './order-form.component.html',
    styleUrls: ['./order-form.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderFormComponent implements OnInit, OnChanges, ComponentCanDeactivate {
    // Dropdowns
    fields: object = {text: 'label', value: 'value'};

    height = '240px';

    categorySelect: Array<{value: boolean | number | string; label: string}> = [];

    usersSelect: Array<{value: boolean | number | string; label: string}> = [];

    companySelect: Array<{value: boolean | number | string; label: string}> = [];

    unitsSelect: Array<{value: boolean | number | string; label: string}> = [];

    speedsSelect: Array<{value: boolean | number | string; label: string}> = [];

    spacesSelect: Array<{value: boolean | number | string; label: string}> = [];

    hardwareSelect: Array<{value: boolean | number | string; label: string}> = [];

    dealSelect: Array<{value: boolean | number | string; label: string}> = [];

    servicesNetSelect: Array<{value: boolean | number | string; label: string}> = [];

    servicesTvSelect: Array<{value: boolean | number | string; label: string}> = [];

    tvPackagesSelect: Array<{value: boolean | number | string; label: string}> = [];

    extServicesSelect: Array<{value: boolean | number | string; label: string}> = [];

    statusSelect: Array<{label: string; value: string}> = [
        {label: 'V řešení', value: 'open'},
        {label: 'Čeká', value: 'wait'},
        {label: 'Uzavřeno', value: 'close'},
        {label: 'Archivováno', value: 'archive'},
        {label: 'Test', value: 'test'},
    ];

    // Forms
    orderForm: FormGroup;

    tvsList: FormArray;

    isChecked = false;

    isDirty = false;

    submited = false;

    // Variables
    hardwareConfigs: HardwareConfig[] = [];

    deals: DealTypes[] = [];

    tvPackages: TvPackages[] = [];

    extServices: ExtendServices[] = [];

    netServices: NetPackages[] = [];

    tvServices: TvServices[] = [];

    choosenWiFiServices: HardwareConfig | null = null;

    choosenInternetTarif: NetPackages | null = null;

    choosenTelevisionTarif: TvServices | null = null;

    choosenTelevisionPackages: TvPackages | null = null;

    choosenTelevisionExtensions: TvPackages[] = [];

    choosenExtendedServices: ExtendServices[] = [];

    dealAge = 24;

    feePeriod = 1;

    purchaseWifi = 'purchase';

    discount = 0;

    totalCsPrice = 0;

    setttopboxFee = 0;

    vipinstallFee = 0;

    setttopboxFeeAnnualy = 0;

    internetPriceAnnualy = 0;

    televisionPriceAnnualy = 0;

    extServicesPriceAnnualy = 0;

    wifiPriceAnnualy = 0;

    currentUser: User | null;

    internetPrice = {
        period: this.feePeriod,
        fee: 0,
        price: 0,
        discount: this.discount,
    };

    televisionPrice = {
        period: this.feePeriod,
        fee: 0,
        price: 0,
        discount: this.discount,
    };

    extServicesPrice = {
        period: this.feePeriod,
        fee: 0,
        price: 0,
        discount: this.discount,
    };

    wifiPrice = {
        period: this.feePeriod,
        fee: 0,
        price: 0,
        discount: this.discount,
    };

    totalPrice = {
        period: this.feePeriod,
        fee: 0,
        price: 0,
        discount: this.discount,
    };

    // Loaders
    loadingOrder = false;

    @Input() order: Orders | null = null;

    @Input() isUpdate = false;

    @Input() isVisible = false;

    @Output() readonly orderFormDialogState = new EventEmitter<boolean>();

    // Checkboxes
    @ViewChild('wicon_own_router') wiconOwnRouterObj: CheckBoxComponent;

    @ViewChild('wicon_basics') wiconBasicsObj?: CheckBoxComponent;

    @ViewChild('wicon_garden') wiconGardenObj?: CheckBoxComponent;

    @ViewChild('wicon_cabels') wiconCabelsObj?: CheckBoxComponent;

    @ViewChild('wicon_roaming') wiconRoamingObj?: CheckBoxComponent;

    // Dialogs
    @ViewChild('formDialog') formDialogObj: DialogComponent;

    // Dropdowns
    @ViewChild('provider') providerObj: DropDownListComponent;

    @ViewChild('operator') operatorObj: DropDownListComponent;

    @ViewChild('type') typeObj: DropDownListComponent;

    @ViewChild('deal_type') dealTypeObj: DropDownListComponent;

    @ViewChild('unit_type') unitTypeObj?: DropDownListComponent;

    @ViewChild('flat_space') flatSpaceObj?: DropDownListComponent;

    @ViewChild('speed_type') speedTypeObj?: DropDownListComponent;

    @ViewChild('hardware') hardwareObj: DropDownListComponent;

    @ViewChild('status') statusObj: DropDownListComponent;

    @ViewChild('client_status') clientStatusObj: DropDownListComponent;

    @ViewChild('net_package') netPackageObj: DropDownListComponent;

    @ViewChild('tv_service') tvServiceObj: DropDownListComponent;

    @ViewChild('ext_services') extServicesObj: MultiSelectComponent;

    @ViewChild('tv_packages') tvPackagesObj: MultiSelectComponent;

    constructor(
        private readonly formBuilder: FormBuilder,
        private readonly ref: ChangeDetectorRef,
        private readonly dataService: DataService,
        private readonly dataNetbaseService: DataNetbaseService,
        private readonly permissionService: PermissionService,
        private readonly apisService: ApisService,
        private readonly message: MessageService,
        private readonly servicesService: ServicesService,
        private readonly messages: MessageService,
        private readonly authenticationService: AuthenticationService,
    ) {
        this.currentUser = this.authenticationService.currentUserValue;
    }

    get tvFormGroup(): FormArray {
        return this.orderForm.get('tvs') as FormArray;
    }

    get f(): {[key: string]: AbstractControl} {
        return this.orderForm.controls;
    }

    @HostListener('window:beforeunload')
    canDeactivate(): Observable<boolean> | boolean {
        return !this.isDirty;
    }

    // eslint-disable-next-line max-lines-per-function
    ngOnInit(): void {
        this.dataService.userSource.pipe(untilDestroyed(this))
            .subscribe(
                (data: User[] | undefined) => {
                    data?.map((user: User) => {
                        if (user.authorized && !user.deleted_date) {
                            this.usersSelect = [
                                ...this.usersSelect,
                                {
                                    value: user.id,
                                    label: `${user.firstname} ${user.secondname}`,
                                },
                            ];
                        }
                    });
                },
                error => {
                    console.error(error);
                },
            );

        this.dataNetbaseService.clientsTypesSource.pipe(untilDestroyed(this))
            .subscribe(
                (clientTypes: ClientsType[] | undefined) => {
                    this.categorySelect = [];
                    clientTypes?.map((clientType: ClientsType) => {
                        if (!clientType.deleted_date) {
                            this.categorySelect = [
                                ...this.categorySelect,
                                {
                                    value: clientType.id,
                                    label: clientType.name,
                                },
                            ];
                        }
                    });
                    this.ref.markForCheck();
                },
                error => {
                    console.error(error);
                },
            );

        this.dataNetbaseService.dealTypesSource.pipe(untilDestroyed(this))
            .subscribe(
                (deals: DealTypes[] | undefined) => {
                    this.dealSelect = [];

                    if (deals && deals.length > 0) {
                        this.deals = deals;
                        deals = deals.sort((a, b) => {
                            const aValue = a.age;
                            const bValue = b.age;

                            return aValue > bValue ? -1 : 1;
                        });

                        deals.map((deal: DealTypes) => {
                            if (!deal.deleted_date) {
                                this.dealSelect = [
                                    ...this.dealSelect,
                                    {
                                        value: deal.id,
                                        label: `${deal.age} měsíců`,
                                    },
                                ];
                            }
                        });
                    }
                },
                error => {
                    console.error(error);
                },
            );

        this.dataNetbaseService.unitTypesSource.pipe(untilDestroyed(this))
            .subscribe(
                (units: UnitTypes[] | undefined) => {
                    this.unitsSelect = [];
                    units
                        ?.sort((a, b) => {
                            const aValue = a.name;
                            const bValue = b.name;

                            return aValue > bValue ? -1 : 1;
                        })
                        .map((unit: UnitTypes) => {
                            if (!unit.deleted_date) {
                                this.unitsSelect = [
                                    ...this.unitsSelect,
                                    {
                                        value: unit.id,
                                        label: unit.name,
                                    },
                                ];
                            }
                        });
                },
                error => {
                    console.error(error);
                },
            );

        this.dataNetbaseService.speedTypesSource.pipe(untilDestroyed(this))
            .subscribe(
                (speeds: SpeedTypes[] | undefined) => {
                    this.speedsSelect = [];
                    speeds
                        ?.sort((a, b) => {
                            const aValue = a.speed_until;
                            const bValue = b.speed_until;

                            return aValue > bValue ? -1 : 1;
                        })
                        .map((speed: SpeedTypes) => {
                            if (!speed.deleted_date) {
                                this.speedsSelect = [
                                    ...this.speedsSelect,
                                    {
                                        value: speed.id,
                                        label: `${speed.speed_until} Mbit - ${
                                            speed.speed_to > 0 ? speed.speed_to : '*'
                                        } Mbit`,
                                    },
                                ];
                            }
                        });
                },
                error => {
                    console.error(error);
                },
            );

        this.dataNetbaseService.spaceTypesSource.pipe(untilDestroyed(this))
            .subscribe(
                (spaces: SpaceTypes[] | undefined) => {
                    this.spacesSelect = [];
                    spaces
                        ?.sort((a, b) => {
                            const aValue = a.metric_until;
                            const bValue = b.metric_until;

                            return aValue > bValue ? -1 : 1;
                        })
                        .map((space: SpaceTypes) => {
                            if (!space.deleted_date) {
                                this.spacesSelect = [
                                    ...this.spacesSelect,
                                    {
                                        value: space.id,
                                        label: `${space.metric_until} - ${space.metric_to}`,
                                    },
                                ];
                            }
                        });
                },
                error => {
                    console.error(error);
                },
            );

        this.dataNetbaseService.hardwareSource.pipe(untilDestroyed(this))
            .subscribe(
                (hardwares: Hardware[] | undefined) => {
                    this.hardwareSelect = [];
                    hardwares
                        ?.sort((a, b) => {
                            const aValue = a.name;
                            const bValue = b.name;

                            return aValue > bValue ? -1 : 1;
                        })
                        .map((hardware: Hardware) => {
                            if (!hardware.deleted_date) {
                                this.hardwareSelect = [
                                    ...this.hardwareSelect,
                                    {
                                        value: hardware.id,
                                        label: hardware.name,
                                    },
                                ];
                            }
                        });
                },
                error => {
                    console.error(error);
                },
            );

        this.dataService.companySource.pipe(untilDestroyed(this))
            .subscribe(
                (companies: Company[] | undefined) => {
                    companies?.map((company: Company) => {
                        this.companySelect = [
                            ...this.companySelect,
                            {
                                value: company.id,
                                label: company.name,
                            },
                        ];
                    });
                    this.ref.markForCheck();
                },
                error => {
                    console.error(error);
                },
            );

        this.dataNetbaseService.netPackagesSource.pipe(untilDestroyed(this))
            .subscribe(
                (netTarifs: NetPackages[] | undefined) => {
                    this.servicesNetSelect = [];

                    if (netTarifs && netTarifs.length > 0) {
                        this.netServices = netTarifs;
                        netTarifs.map((netTarif: NetPackages) => {
                            if (!netTarif.deleted_date) {
                                this.servicesNetSelect = [
                                    ...this.servicesNetSelect,
                                    {
                                        value: netTarif.id,
                                        label: `${netTarif.name}${
                                            netTarif.transfer.connection_method === 'optical cable'
                                                ? ' - optické připojení'
                                                : netTarif.transfer.connection_method ===
                                                'metalic cable'
                                                    ? ' - kabelové připojení'
                                                    : netTarif.transfer.connection_method === 'radio'
                                                        ? ' - rádiové připojení'
                                                        : '- xDSL připojení'
                                        }`,
                                    },
                                ];
                            }
                        });
                    }

                    this.ref.markForCheck();
                },
                error => {
                    console.error(error);
                },
            );

        this.dataNetbaseService.tvServicesSource.pipe(untilDestroyed(this))
            .subscribe(
                (tvServices: TvServices[] | undefined) => {
                    this.servicesTvSelect = [];

                    if (tvServices && tvServices.length > 0) {
                        this.tvServices = tvServices;
                        tvServices.map((tvService: TvServices) => {
                            if (!tvService.deleted_date) {
                                this.servicesTvSelect = [
                                    ...this.servicesTvSelect,
                                    {
                                        value: tvService.id,
                                        label: `${tvService.name}${
                                            tvService.transfer.connection_method === 'optical cable'
                                                ? ' - optické připojení'
                                                : tvService.transfer.connection_method ===
                                                'metalic cable'
                                                    ? ' - kabelové připojení'
                                                    : tvService.transfer.connection_method === 'radio'
                                                        ? ' - rádiové připojení'
                                                        : '- xDSL připojení'
                                        }`,
                                    },
                                ];
                            }
                        });
                    }

                    this.ref.markForCheck();
                },
                error => {
                    console.error(error);
                },
            );

        this.dataNetbaseService.extendServices.pipe(untilDestroyed(this))
            .subscribe(
                (extServices: ExtendServices[] | undefined) => {
                    this.extServicesSelect = [];

                    if (extServices && extServices.length > 0) {
                        this.extServices = extServices;
                        extServices.map((extService: ExtendServices) => {
                            if (!extService.deleted_date) {
                                this.extServicesSelect = [
                                    ...this.extServicesSelect,
                                    {
                                        value: extService.id,
                                        label: `${extService.name}${
                                            extService.tv_services.length > 0 &&
                                            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                                            (!extService.net_packages ||
                                                extService.net_packages.length === 0)
                                                ? ' - rozšiřující služba TV'
                                                : extService.net_packages.length > 0 &&
                                                // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                                                (!extService.tv_services ||
                                                    extService.tv_services.length === 0)
                                                    ? ' - rozšiřující služba NET'
                                                    : ' - rozšiřující služba NET + TV'
                                        }`,
                                    },
                                ];
                            }
                        });
                    }

                    this.ref.markForCheck();
                },
                error => {
                    console.error(error);
                },
            );

        this.dataNetbaseService.tvPackagesSource.pipe(untilDestroyed(this))
            .subscribe(
                (tvPackages: TvPackages[] | undefined) => {
                    this.tvPackagesSelect = [];

                    if (tvPackages && tvPackages.length > 0) {
                        this.tvPackages = tvPackages;
                        tvPackages.map((tvPackage: TvPackages) => {
                            if (!tvPackage.deleted_date) {
                                this.tvPackagesSelect = [
                                    ...this.tvPackagesSelect,
                                    {
                                        value: tvPackage.id,
                                        label: `${tvPackage.name}${
                                            tvPackage.extension
                                                ? ' - rozšiřující balíček'
                                                : ' - hlavní balíček'
                                        }`,
                                    },
                                ];
                            }
                        });
                    }

                    this.ref.markForCheck();
                },
                error => {
                    console.error(error);
                },
            );

        // AVAILABLE HARDWARE CONFIGURATIONS
        this.dataNetbaseService.hardwareConfigSource.pipe(untilDestroyed(this))
            .subscribe(
                (configs: HardwareConfig[] | undefined) => {
                    if (configs && configs.length > 0) {
                        this.hardwareConfigs = configs;
                        this.hardwareConfigs = this.hardwareConfigs.sort((a, b) => {
                            const aValue = a.hardware.name;
                            const bValue = b.hardware.name;

                            return aValue > bValue ? -1 : 1;
                        });
                        this.ref.markForCheck();
                    }
                },
                error => {
                    console.error(error);
                },
            );

        this.orderForm = this.formBuilder.group({
            crm_id: [null],
            erp_id: [null],
            stmnt_id: [null],
            cl_cnumber: [0],
            address: ['', Validators.required],
            ruian: [0],
            status: [null],
            client_status: [null],
            field_number: [0],
            cl_firstname: [
                '',
                Validators.compose([
                    Validators.required,
                    Validators.pattern('[a-žA-Ž]*'),
                    Validators.maxLength(100),
                ]),
            ],
            cl_lastname: [
                '',
                Validators.compose([
                    Validators.required,
                    Validators.pattern('[a-žA-Ž]*'),
                    Validators.maxLength(100),
                ]),
            ],
            cl_email: [
                '',
                Validators.compose([
                    Validators.required,
                    Validators.email,
                    Validators.pattern('\\S+@\\S+\\.\\S+'),
                ]),
            ],
            cl_billing_email: [
                '',
                Validators.compose([Validators.email, Validators.pattern('\\S+@\\S+\\.\\S+')]),
            ],
            cl_technical_email: [
                '',
                Validators.compose([Validators.email, Validators.pattern('\\S+@\\S+\\.\\S+')]),
            ],
            cl_business_email: [
                '',
                Validators.compose([Validators.email, Validators.pattern('\\S+@\\S+\\.\\S+')]),
            ],
            cl_telephone: [
                '',
                Validators.compose([
                    Validators.pattern('(\\+420)? ?[1-9][0-9]{2} ?[0-9]{3} ?[0-9]{3}'),
                    Validators.minLength(9),
                    Validators.maxLength(17),
                ]),
            ],
            cl_mobilephone: [
                '',
                Validators.compose([
                    Validators.pattern('(\\+420)? ?[1-9][0-9]{2} ?[0-9]{3} ?[0-9]{3}'),
                    Validators.minLength(9),
                    Validators.maxLength(17),
                ]),
            ],
            cl_account_number: [null],
            cl_bank_number: [null],
            cl_address: ['', Validators.required],
            cl_billing_address: [''],
            cl_vat_id: [
                '',
                Validators.compose([Validators.pattern('[a-žA-Ž0-9]*'), Validators.maxLength(15)]),
            ],
            cl_national_id_number: [
                '',
                Validators.compose([
                    Validators.pattern('[0-9]{2,6}-?[0-9]{2,10}\\/[0-9]{4}'),
                    Validators.maxLength(15),
                ]),
            ],
            cl_send_invoices_by_post: [false, Validators.compose([Validators.required])],
            cl_send_invoice_by_email: [true, Validators.compose([Validators.required])],
            invoice_by_year: [false, Validators.compose([Validators.required])],
            vip_install: [false, Validators.compose([Validators.required])],
            internal_note: ['', Validators.compose([Validators.maxLength(1000)])],
            tvs: this.formBuilder.array([this.createTv()]),
            wicon_own_router: [false, Validators.required],
            wicon_basics: [false, Validators.required],
            wicon_garden: [false, Validators.required],
            wicon_cabels: [false, Validators.required],
            wicon_roaming: [false, Validators.required],
            operator_id: [null, Validators.required],
            type_id: [null, Validators.required],
            provider_id: [null, Validators.compose([Validators.required])],
            deal_id: [null, Validators.compose([Validators.required])],
            unit_id: [null],
            space_id: [null],
            speed_id: [null],
            hw_id: [null],
            net_package_id: [null],
            tv_service_id: [null],
            tv_packages: [[]],
            ext_services: [[]],
            total_price: [0, Validators.required],
            internet_price: [0, Validators.required],
            television_price: [0, Validators.required],
            ext_services_price: [0, Validators.required],
            wifi_price: [0, Validators.required],
            total_fee: [0, Validators.required],
            internet_fee: [0, Validators.required],
            television_fee: [0, Validators.required],
            ext_services_fee: [0, Validators.required],
            wifi_fee: [0, Validators.required],
        });
        this.tvsList = this.orderForm.get('tvs') as FormArray;

        this.orderForm.valueChanges.pipe(untilDestroyed(this))
            .subscribe(() => {
                this.isDirty = this.orderForm.dirty;
                this.showInputErrors();
            });

        this.tvsList.valueChanges.pipe(untilDestroyed(this))
            .subscribe(value => {
                const tvs: SmartTvInterface[] = [];
                let countSTB = 0;

                this.setttopboxFee = 0;
                value.map((tv: SmartTvInterface) => {
                    if (tv.settopbox) {
                        tv.smart = false;
                        tv.settopbox_fee = true;
                        countSTB++;
                    } else {
                        tv.smart = true;
                        tv.settopbox_fee = false;
                    }

                    tvs.push(tv);
                });
                this.setttopboxFee = 99 * countSTB;
                this.orderForm.get('tvs')
                    ?.setValue(tvs, {onlySelf: true, emitEvent: false});
                this.reCountTotalFee();
            });

        this.orderForm.controls.wicon_own_router.valueChanges
            .pipe(untilDestroyed(this))
            .subscribe(value => {
                if (value) {
                    this.wifiPrice = {
                        period: this.feePeriod,
                        fee: 0,
                        price: 0,
                        discount: 0,
                    };
                    this.wifiPriceAnnualy = 0;
                    this.orderForm.controls.wicon_basics.reset(false, {
                        emitEvent: false,
                    });

                    this.orderForm.controls.wicon_garden.reset(false, {
                        emitEvent: false,
                    });

                    this.orderForm.controls.wicon_cabels.reset(false, {
                        emitEvent: false,
                    });

                    this.orderForm.controls.wicon_roaming.reset(false, {
                        emitEvent: false,
                    });
                    this.orderForm.controls.unit_id.reset(null, {emitEvent: false});
                    this.orderForm.controls.space_id.reset(null, {emitEvent: false});
                    this.orderForm.controls.speed_id.reset(null, {emitEvent: false});
                    this.orderForm.controls.wicon_basics.setValidators(null);
                    this.orderForm.controls.wicon_garden.setValidators(null);
                    this.orderForm.controls.wicon_cabels.setValidators(null);
                    this.orderForm.controls.wicon_roaming.setValidators(null);
                    this.orderForm.controls.unit_id.setValidators(null);
                    this.orderForm.controls.space_id.setValidators(null);
                    this.orderForm.controls.speed_id.setValidators(null);
                } else {
                    this.orderForm.controls.wicon_basics.setValidators([Validators.required]);
                    this.orderForm.controls.wicon_garden.setValidators([Validators.required]);
                    this.orderForm.controls.wicon_cabels.setValidators([Validators.required]);
                    this.orderForm.controls.wicon_roaming.setValidators([Validators.required]);
                    this.orderForm.controls.unit_id.setValidators([Validators.required]);
                    this.orderForm.controls.space_id.setValidators([Validators.required]);
                    this.orderForm.controls.speed_id.setValidators([Validators.required]);
                }

                this.orderForm.controls.wicon_basics.updateValueAndValidity();
                this.orderForm.controls.wicon_garden.updateValueAndValidity();
                this.orderForm.controls.wicon_cabels.updateValueAndValidity();
                this.orderForm.controls.wicon_roaming.updateValueAndValidity();
                this.orderForm.controls.unit_id.updateValueAndValidity();
                this.orderForm.controls.space_id.updateValueAndValidity();
                this.orderForm.controls.speed_id.updateValueAndValidity();
                this.reCountTotalFee();
            });

        this.orderForm.controls.invoice_by_year.valueChanges
            .pipe(untilDestroyed(this))
            .subscribe(value => {
                if (value) {
                    this.discount = this.discount + 10;
                    this.feePeriod = 12;
                    this.internetPrice.period = this.feePeriod;
                    this.televisionPrice.period = this.feePeriod;
                    this.extServicesPrice.period = this.feePeriod;
                    this.wifiPrice.period = this.feePeriod;
                    this.totalPrice.period = this.feePeriod;
                    this.setttopboxFeeAnnualy =
                        this.setttopboxFee > 0
                            ? this.setttopboxFee * this.feePeriod -
                            (this.setttopboxFee * this.feePeriod * this.discount) / 100
                            : 0;

                    this.internetPriceAnnualy =
                        this.internetPrice.fee * this.internetPrice.period -
                        (this.internetPrice.fee * this.internetPrice.period * this.discount) / 100;

                    this.televisionPriceAnnualy =
                        this.televisionPrice.fee * this.televisionPrice.period -
                        (this.televisionPrice.fee * this.televisionPrice.period * this.discount) /
                        100;

                    this.extServicesPriceAnnualy =
                        this.extServicesPrice.fee * this.extServicesPrice.period -
                        (this.extServicesPrice.fee * this.extServicesPrice.period * this.discount) /
                        100;

                    this.wifiPriceAnnualy =
                        this.wifiPrice.fee * this.wifiPrice.period -
                        (this.wifiPrice.fee * this.wifiPrice.period * this.discount) / 100;
                } else {
                    this.discount = this.discount > 10 ? this.discount - 10 : 0;
                    this.feePeriod = 1;
                    this.internetPrice.period = 1;
                    this.televisionPrice.period = 1;
                    this.extServicesPrice.period = 1;
                    this.wifiPrice.period = 1;
                    this.totalPrice.period = 1;
                    this.setttopboxFeeAnnualy = 0;
                    this.internetPriceAnnualy = 0;
                    this.televisionPriceAnnualy = 0;
                    this.extServicesPriceAnnualy = 0;
                    this.wifiPriceAnnualy = 0;
                }

                this.reCountTotalFee();
            });

        this.orderForm.controls.vip_install.valueChanges
            .pipe(untilDestroyed(this))
            .subscribe(value => {
                this.vipinstallFee = value ? 500 : 0;
                this.reCountTotalFee();
            });

        this.orderForm.controls.deal_id.valueChanges.pipe(untilDestroyed(this))
            .subscribe(value => {
                let age: number | undefined = 24;

                if (value) {
                    age = this.deals.find(x => x.id === value)?.age;
                }

                if (age) {
                    this.dealAge = age;
                }

                this.reCountTotalFee();
            });

        this.orderForm.controls.provider_id.valueChanges
            .pipe(untilDestroyed(this))
            .subscribe(value => {
                this.orderForm.controls.net_package_id.patchValue(null);
                this.orderForm.controls.tv_service_id.patchValue(null);
                this.orderForm.controls.tv_packages.patchValue([]);
                this.orderForm.controls.ext_services.patchValue([]);
                this.servicesNetSelect = [];
                this.netServices.forEach((netPackage: NetPackages) => {
                    if (netPackage.provider_id === value) {
                        if (!netPackage.deleted_date) {
                            this.servicesNetSelect = [
                                ...this.servicesNetSelect,
                                {
                                    value: netPackage.id,
                                    label: `${netPackage.name}${
                                        netPackage.transfer.connection_method === 'optical cable'
                                            ? ' - optické připojení'
                                            : netPackage.transfer.connection_method ===
                                            'metalic cable'
                                                ? ' - kabelové připojení'
                                                : netPackage.transfer.connection_method === 'radio'
                                                    ? ' - rádiové připojení'
                                                    : '- xDSL připojení'
                                    }`,
                                },
                            ];
                        }
                    }
                });
                this.servicesTvSelect = [];
                this.tvServices.forEach((tvService: TvServices) => {
                    if (tvService.provider_id === value) {
                        if (!tvService.deleted_date) {
                            this.servicesTvSelect = [
                                ...this.servicesTvSelect,
                                {
                                    value: tvService.id,
                                    label: `${tvService.name}${
                                        tvService.transfer.connection_method === 'optical cable'
                                            ? ' - optické připojení'
                                            : tvService.transfer.connection_method ===
                                            'metalic cable'
                                                ? ' - kabelové připojení'
                                                : tvService.transfer.connection_method === 'radio'
                                                    ? ' - rádiové připojení'
                                                    : '- xDSL připojení'
                                    }`,
                                },
                            ];
                        }
                    }
                });
                this.ref.markForCheck();
            });

        this.orderForm.controls.net_package_id.valueChanges
            .pipe(untilDestroyed(this))
            .subscribe(value => {
                this.extServicesSelect = [];
                this.choosenInternetTarif = null;
                this.orderForm.controls.ext_services.patchValue([]);

                this.extServices.forEach((extService: ExtendServices) => {
                    extService.net_packages.forEach((netPackage: NetPackages) => {
                        if (netPackage.id === value && !extService.deleted_date) {
                            this.extServicesSelect = [
                                ...this.extServicesSelect,
                                {
                                    value: extService.id,
                                    label: `${extService.name}${
                                        extService.tv_services.length > 0 &&
                                        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                                        (!extService.net_packages ||
                                            extService.net_packages.length === 0)
                                            ? ' - rozšiřující služba TV'
                                            : extService.net_packages.length > 0 &&
                                            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                                            (!extService.tv_services ||
                                                extService.tv_services.length === 0)
                                                ? ' - rozšiřující služba NET'
                                                : ' - rozšiřující služba NET + TV'
                                    }`,
                                },
                            ];
                        }
                    });
                });

                if (value) {
                    const internet = this.netServices.find(
                        (netTarif: NetPackages) => netTarif.id === value,
                    );

                    if (internet) {
                        this.choosenInternetTarif = internet;
                        this.internetPrice.fee = this.choosenInternetTarif.price;
                    }
                }

                this.reCountTotalFee();
            });

        this.orderForm.controls.tv_service_id.valueChanges
            .pipe(untilDestroyed(this))
            .subscribe(value => {
                this.tvPackagesSelect = [];
                this.choosenTelevisionTarif = null;
                this.choosenTelevisionPackages = null;
                this.choosenTelevisionExtensions = [];
                this.orderForm.controls.tv_packages.patchValue([]);

                this.tvPackages.forEach((tvPackage: TvPackages) => {
                    tvPackage.services.forEach((tvService: TvServices) => {
                        if (tvService.id === value && !tvPackage.deleted_date) {
                            this.tvPackagesSelect = [
                                ...this.tvPackagesSelect,
                                {
                                    value: tvPackage.id,
                                    label: `${tvPackage.name}${
                                        tvPackage.extension
                                            ? ' - rozšiřující balíček'
                                            : ' - hlavní balíček'
                                    }`,
                                },
                            ];
                        }
                    });
                });

                if (value) {
                    const television = this.tvServices.find(
                        (tvService: TvServices) => tvService.id === value,
                    );

                    if (television) {
                        this.choosenTelevisionTarif = television;
                        this.televisionPrice.fee = this.choosenTelevisionTarif.pernament_price
                            ? this.choosenTelevisionTarif.pernament_price
                            : 0;
                    }
                }

                this.reCountTotalFee();
            });

        this.orderForm.controls.tv_packages.valueChanges
            .pipe(untilDestroyed(this))
            .subscribe(values => {
                this.choosenTelevisionPackages = null;
                this.choosenTelevisionExtensions = [];

                if (values) {
                    values.forEach(id => {
                        const television = this.tvPackages.find(
                            (tvPackage: TvPackages) => tvPackage.id === id,
                        );

                        if (television) {
                            if (!television.extension) {
                                this.choosenTelevisionPackages = television;
                            } else {
                                this.choosenTelevisionExtensions.push(television);
                            }

                            this.televisionPrice.fee += television.price;
                        }
                    });
                }

                this.reCountTotalFee();
            });

        this.orderForm.controls.ext_services.valueChanges
            .pipe(untilDestroyed(this))
            .subscribe(values => {
                this.choosenExtendedServices = [];

                if (values) {
                    values.forEach(id => {
                        const service = this.extServices.find(
                            (extService: ExtendServices) => extService.id === id,
                        );

                        if (service) {
                            this.choosenExtendedServices.push(service);
                            this.extServicesPrice.fee += service.price;
                        }
                    });
                }

                this.reCountTotalFee();
            });

        this.orderForm.controls.hw_id.valueChanges.pipe(untilDestroyed(this))
            .subscribe(value => {
                this.choosenWiFiServices = null;

                if (value) {
                    const wifi = this.hardwareConfigs.find(
                        (hwConfig: HardwareConfig) => hwConfig.id === value,
                    );

                    this.choosenWiFiServices = wifi ? wifi : null;
                }

                this.reCountTotalFee();
            });

        this.dataService.setCompanyDataSource();
        this.dataNetbaseService.setExtendedServicesDataSource();
        this.dataNetbaseService.setHardwareDataSource();
        this.dataNetbaseService.setHardwareConfigDataSource();
        this.dataNetbaseService.setDiscountsDataSource();
        this.dataNetbaseService.setDealTypesDataSource();
        this.dataNetbaseService.setUnitTypesDataSource();
        this.dataNetbaseService.setSpaceTypesDataSource();
        this.dataNetbaseService.setSpeedTypesDataSource();
        this.dataNetbaseService.setTvServicesDataSource();
        this.dataNetbaseService.setTvPackagesDataSource();
        this.dataNetbaseService.setNetPackagesDataSource();
        this.dataNetbaseService.setClientsTypesSource();
    }

    ngOnChanges(): void {
        if (this.isVisible) {
            if (this.isUpdate && this.order) {
                this.orderForm.reset();
                this.orderForm.controls.cl_send_invoices_by_post.patchValue(false);
                this.orderForm.controls.cl_send_invoice_by_email.patchValue(true);
                this.orderForm.controls.invoice_by_year.patchValue(false);
                this.orderForm.controls.vip_install.patchValue(false);
                this.formDialogObj.header = `Objednávka #${this.order.id}`;
                this.fillTheForm();
                this.showInputErrors();
            }
        }
    }

    changeDialogState(value: boolean): void {
        this.submited = false;
        this.isChecked = false;
        this.ref.markForCheck();
        this.orderFormDialogState.emit(value);
    }

    // eslint-disable-next-line complexity
    setWiCon(): void {
        let filteredData: HardwareConfig[] = this.hardwareConfigs;
        let basics: boolean | null = null;
        let garden: boolean | null = null;
        let cabels: boolean | null = null;
        let roaming: boolean | null = null;
        let unitId: boolean | number | string | null = null;
        let spaceId: boolean | number | string | null = null;
        let speedId: boolean | number | string | null = null;

        if (this.wiconBasicsObj) {
            basics = this.wiconBasicsObj.checked;
            filteredData = filteredData.filter(
                (config: HardwareConfig) => config.basics === basics,
            );
        }

        if (this.wiconGardenObj) {
            garden = this.wiconGardenObj.checked;
            filteredData = filteredData.filter(
                (config: HardwareConfig) => config.garden === garden,
            );
        }

        if (this.wiconCabelsObj) {
            cabels = this.wiconCabelsObj.checked;
            filteredData = filteredData.filter(
                (config: HardwareConfig) => config.cabels === cabels,
            );
        }

        if (this.wiconRoamingObj) {
            roaming = this.wiconRoamingObj.checked;
            filteredData = filteredData.filter(
                (config: HardwareConfig) => config.roaming === roaming,
            );
        }

        if (this.unitTypeObj) {
            unitId = this.unitTypeObj.value;
            filteredData = filteredData.filter(
                (config: HardwareConfig) => config.unit_id === unitId,
            );
        }

        if (this.flatSpaceObj) {
            spaceId = this.flatSpaceObj.value;
            filteredData = filteredData.filter(
                (config: HardwareConfig) => config.space_id === spaceId,
            );
        }

        if (this.speedTypeObj) {
            speedId = this.speedTypeObj.value;
            filteredData = filteredData.filter(
                (config: HardwareConfig) => config.speed_id === speedId,
            );
        }

        if (speedId || spaceId || roaming || garden || basics || unitId || cabels) {
            this.orderForm.controls.hw_id.patchValue(null);

            if (filteredData.length > 0) {
                this.hardwareSelect = [];
                filteredData.sort((a, b) => {
                    const aValue = a.hardware.name;
                    const bValue = b.hardware.name;

                    return aValue > bValue ? -1 : 1;
                });

                filteredData.map((config: HardwareConfig) => {
                    if (!config.hardware.deleted_date) {
                        this.hardwareSelect = [
                            ...this.hardwareSelect,
                            {
                                value: config.hardware.id,
                                label: `${config.hardware.name} | ${
                                    config.basics ? ' základy: ANO,' : ' základy: NE,'
                                }${config.garden ? ' zahrada: ANO,' : ' zahrada: NE,'}${
                                    config.cabels ? ' kabeláž: ANO,' : ' kabeláž: NE,'
                                }${
                                    config.roaming ? ' wifi-domácnost: ANO' : ' wifi-domácnost: NE'
                                }`,
                            },
                        ];
                    }
                });
            }
        }

        this.reCountTotalFee();
    }

    setWifiSellMode(): void {
        if (this.orderForm.controls.hw_id.valid && this.orderForm.controls.hw_id.value) {
            const hwConfig = this.hardwareConfigs.find(
                hw => hw.hardware_id === this.orderForm.controls.hw_id.value,
            );

            if (hwConfig) {
                if (this.purchaseWifi === 'fee') {
                    this.wifiPrice.fee = hwConfig.hardware.fee;
                    this.wifiPrice.price = 0;
                } else {
                    this.wifiPrice.price = hwConfig.hardware.price;
                    this.wifiPrice.fee = 0;
                }
            }
        } else {
            this.wifiPrice.fee = 0;
            this.wifiPrice.price = 0;
        }

        this.orderForm.controls.wifi_price.patchValue(this.wifiPrice.price);
        this.orderForm.controls.wifi_fee.patchValue(this.wifiPrice.fee);
        this.reCountTotalFee();
    }

    createTv(tv?: SmartTvInterface): FormGroup {
        return this.formBuilder.group({
            tv: [tv?.tv ? tv.tv : '', Validators.compose([Validators.required])],
            smart: [tv?.smart ? tv.smart : true, Validators.compose([Validators.required])],
            settopbox: [
                tv?.settopbox ? tv.settopbox : false,
                Validators.compose([Validators.required]),
            ],
            settopbox_fee: [
                tv?.settopbox_fee ? tv.settopbox_fee : false,
                Validators.compose([Validators.required]),
            ],
        });
    }

    addTv(): void {
        this.tvsList.push(this.createTv());
    }

    getTvFormGroup(index): FormGroup {
        return this.tvsList.controls[index] as FormGroup;
    }

    removeTv(index: number): void {
        this.tvsList.removeAt(index);
    }

    onFilteringUsers: EmitType<FilteringEventArgs> = (e: FilteringEventArgs) => {
        if (e.text === '') {
            e.updateData(this.usersSelect);
        } else {
            let query: Query = new Query();

            query = e.text !== '' ? query.where('label', 'contains', e.text, true, true) : query;
            e.updateData(this.usersSelect, query);
        }
    };

    onFilteringTvServices: EmitType<FilteringEventArgs> = (e: FilteringEventArgs) => {
        if (e.text === '') {
            e.updateData(this.servicesTvSelect);
        } else {
            let query: Query = new Query();

            query = e.text !== '' ? query.where('label', 'contains', e.text, true, true) : query;
            e.updateData(this.servicesTvSelect, query);
        }
    };

    onFilteringTvPackages: EmitType<FilteringEventArgs> = (e: FilteringEventArgs) => {
        if (e.text === '') {
            e.updateData(this.tvPackagesSelect);
        } else {
            let query: Query = new Query();

            query = e.text !== '' ? query.where('label', 'contains', e.text, true, true) : query;
            e.updateData(this.tvPackagesSelect, query);
        }
    };

    onFilteringExtPackages: EmitType<FilteringEventArgs> = (e: FilteringEventArgs) => {
        if (e.text === '') {
            e.updateData(this.extServicesSelect);
        } else {
            let query: Query = new Query();

            query = e.text !== '' ? query.where('label', 'contains', e.text, true, true) : query;
            e.updateData(this.extServicesSelect, query);
        }
    };

    onFilteringNetPackages: EmitType<FilteringEventArgs> = (e: FilteringEventArgs) => {
        if (e.text === '') {
            e.updateData(this.servicesNetSelect);
        } else {
            let query: Query = new Query();

            query = e.text !== '' ? query.where('label', 'contains', e.text, true, true) : query;
            e.updateData(this.servicesNetSelect, query);
        }
    };

    onFilteringUnitTypes: EmitType<FilteringEventArgs> = (e: FilteringEventArgs) => {
        if (e.text === '') {
            e.updateData(this.unitsSelect);
        } else {
            let query: Query = new Query();

            query = e.text !== '' ? query.where('label', 'contains', e.text, true, true) : query;
            e.updateData(this.unitsSelect, query);
        }
    };

    onFilteringSpaceType: EmitType<FilteringEventArgs> = (e: FilteringEventArgs) => {
        if (e.text === '') {
            e.updateData(this.spacesSelect);
        } else {
            let query: Query = new Query();

            query = e.text !== '' ? query.where('label', 'contains', e.text, true, true) : query;
            e.updateData(this.spacesSelect, query);
        }
    };

    onFilteringSpeedLimits: EmitType<FilteringEventArgs> = (e: FilteringEventArgs) => {
        if (e.text === '') {
            e.updateData(this.speedsSelect);
        } else {
            let query: Query = new Query();

            query = e.text !== '' ? query.where('label', 'contains', e.text, true, true) : query;
            e.updateData(this.speedsSelect, query);
        }
    };

    onFilteringHardware: EmitType<FilteringEventArgs> = (e: FilteringEventArgs) => {
        if (e.text === '') {
            e.updateData(this.hardwareSelect);
        } else {
            let query: Query = new Query();

            query = e.text !== '' ? query.where('label', 'contains', e.text, true, true) : query;
            e.updateData(this.hardwareSelect, query);
        }
    };

    onFilteringDealTypes: EmitType<FilteringEventArgs> = (e: FilteringEventArgs) => {
        if (e.text === '') {
            e.updateData(this.dealSelect);
        } else {
            let query: Query = new Query();

            query = e.text !== '' ? query.where('label', 'contains', e.text, true, true) : query;
            e.updateData(this.dealSelect, query);
        }
    };

    onFilteringCompany: EmitType<FilteringEventArgs> = (e: FilteringEventArgs) => {
        if (e.text === '') {
            e.updateData(this.companySelect);
        } else {
            let query: Query = new Query();

            query = e.text !== '' ? query.where('label', 'contains', e.text, true, true) : query;
            e.updateData(this.companySelect, query);
        }
    };

    onFilteringCategory: EmitType<FilteringEventArgs> = (e: FilteringEventArgs) => {
        if (e.text === '') {
            e.updateData(this.categorySelect);
        } else {
            let query: Query = new Query();

            query = e.text !== '' ? query.where('label', 'contains', e.text, true, true) : query;
            e.updateData(this.categorySelect, query);
        }
    };

    onFilteringStatus: EmitType<FilteringEventArgs> = (e: FilteringEventArgs) => {
        if (e.text === '') {
            e.updateData(this.statusSelect);
        } else {
            let query: Query = new Query();

            query = e.text !== '' ? query.where('label', 'contains', e.text, true, true) : query;
            e.updateData(this.statusSelect, query);
        }
    };

    onRenderCell(args): void {
        if (args.date.getDay() === 0 || args.date.getDay() === 6) {
            args.isDisabled = true;
        }
    }

    fillTheForm(): void {
        const extServices: number[] = [];
        const tvPackages: number[] = [];

        this.orderForm.controls.crm_id.patchValue(this.order?.crm_id);
        this.orderForm.controls.erp_id.patchValue(this.order?.erp_id);
        this.orderForm.controls.stmnt_id.patchValue(this.order?.stmnt_id);
        this.orderForm.controls.address.patchValue(this.order?.address);
        this.orderForm.controls.ruian.patchValue(this.order?.ruian);
        this.orderForm.controls.status.patchValue(this.order?.status);
        this.orderForm.controls.client_status.patchValue(this.order?.client_status);
        this.orderForm.controls.field_number.patchValue(this.order?.field_number);
        this.orderForm.controls.cl_firstname.patchValue(this.order?.cl_firstname);
        this.orderForm.controls.cl_lastname.patchValue(this.order?.cl_lastname);
        this.orderForm.controls.cl_email.patchValue(this.order?.cl_email);
        this.orderForm.controls.cl_billing_email.patchValue(this.order?.cl_billing_email);
        this.orderForm.controls.cl_technical_email.patchValue(this.order?.cl_technical_email);
        this.orderForm.controls.cl_business_email.patchValue(this.order?.cl_business_email);
        this.orderForm.controls.cl_telephone.patchValue(this.order?.cl_telephone);
        this.orderForm.controls.cl_mobilephone.patchValue(this.order?.cl_mobilephone);
        this.orderForm.controls.cl_account_number.patchValue(this.order?.cl_account_number);
        this.orderForm.controls.cl_bank_number.patchValue(this.order?.cl_bank_number);
        this.orderForm.controls.cl_address.patchValue(this.order?.cl_address);
        this.orderForm.controls.cl_billing_address.patchValue(this.order?.cl_billing_address);
        this.orderForm.controls.cl_vat_id.patchValue(this.order?.cl_vat_id);
        this.orderForm.controls.cl_national_id_number.patchValue(this.order?.cl_national_id_number);
        this.orderForm.controls.cl_send_invoices_by_post.patchValue(
            this.order?.cl_send_invoices_by_post,
        );

        this.orderForm.controls.cl_send_invoice_by_email.patchValue(
            this.order?.cl_send_invoice_by_email,
        );
        this.orderForm.controls.cl_cnumber.patchValue(this.order?.cl_cnumber);
        this.orderForm.controls.invoice_by_year.patchValue(this.order?.invoice_by_year);
        this.orderForm.controls.vip_install.patchValue(this.order?.vip_install);
        this.orderForm.controls.internal_note.patchValue(this.order?.internal_note);
        this.orderForm.controls.tvs.patchValue(this.order?.tvs_list);
        this.orderForm.controls.wicon_own_router.patchValue(this.order?.wicon_own_router);
        this.orderForm.controls.wicon_basics.patchValue(this.order?.wicon_basics);
        this.orderForm.controls.wicon_garden.patchValue(this.order?.wicon_garden);
        this.orderForm.controls.wicon_cabels.patchValue(this.order?.wicon_cabels);
        this.orderForm.controls.wicon_roaming.patchValue(this.order?.wicon_roaming);
        this.orderForm.controls.operator_id.patchValue(this.order?.operator_id);
        this.orderForm.controls.type_id.patchValue(this.order?.type_id);
        this.orderForm.controls.provider_id.patchValue(this.order?.provider_id);
        this.orderForm.controls.deal_id.patchValue(this.order?.deal_id);
        this.orderForm.controls.unit_id.patchValue(this.order?.unit_id);
        this.orderForm.controls.space_id.patchValue(this.order?.space_id);
        this.orderForm.controls.speed_id.patchValue(this.order?.speed_id);
        this.orderForm.controls.hw_id.patchValue(this.order?.hw_id);
        this.orderForm.controls.net_package_id.patchValue(this.order?.net_package_id);
        this.orderForm.controls.tv_service_id.patchValue(this.order?.tv_service_id);
        this.orderForm.controls.total_price.patchValue(this.order?.total_price);
        this.orderForm.controls.internet_price.patchValue(this.order?.internet_price);
        this.orderForm.controls.television_price.patchValue(this.order?.television_price);
        this.orderForm.controls.ext_services_price.patchValue(this.order?.ext_services_price);
        this.orderForm.controls.wifi_price.patchValue(this.order?.wifi_price);
        this.orderForm.controls.total_fee.patchValue(this.order?.total_fee);
        this.orderForm.controls.internet_fee.patchValue(this.order?.internet_fee);
        this.orderForm.controls.television_fee.patchValue(this.order?.television_fee);
        this.orderForm.controls.ext_services_fee.patchValue(this.order?.ext_services_fee);
        this.orderForm.controls.wifi_fee.patchValue(this.order?.wifi_fee);

        if (this.order?.ext_services && this.order.ext_services.length > 0) {
            this.order.ext_services.map((extService: ExtendServices) => {
                extServices.push(extService.id);
            });
        }

        this.orderForm.controls.ext_services.patchValue(extServices);

        if (this.order?.tv_packages && this.order.tv_packages.length > 0) {
            this.order.tv_packages.map((tvPackage: TvPackages) => {
                tvPackages.push(tvPackage.id);
            });
        }

        this.orderForm.controls.tv_packages.patchValue(tvPackages);

        while (this.tvsList.length) {
            this.tvsList.removeAt(0);
        }

        const tvs: SmartTvInterface[] = [];

        this.order?.tvs_list.map((tv: SmartTvInterface) => {
            tvs.push({
                tv: tv.tv,
                smart: tv.smart,
                settopbox: tv.settopbox,
                settopbox_fee: tv.settopbox_fee,
            });
        });

        tvs.forEach(tv => {
            this.tvsList.push(this.createTv(tv));
        });

        if (tvs.length > 0) {
            this.orderForm.get('tvs')
                ?.setValue(tvs, {onlySelf: true, emitEvent: false});
        }

        this.setWifiSellMode();
        this.reCountTotalFee();
    }

    reCountTotalFee(): void {
        if (this.feePeriod === 12) {
            this.setttopboxFeeAnnualy =
                this.setttopboxFee > 0
                    ? this.setttopboxFee * this.feePeriod -
                    (this.setttopboxFee * this.feePeriod * this.discount) / 100
                    : 0;

            this.internetPriceAnnualy =
                this.internetPrice.fee * this.internetPrice.period -
                (this.internetPrice.fee * this.internetPrice.period * this.discount) / 100;

            this.televisionPriceAnnualy =
                this.televisionPrice.fee * this.televisionPrice.period -
                (this.televisionPrice.fee * this.televisionPrice.period * this.discount) / 100;

            this.extServicesPriceAnnualy =
                this.extServicesPrice.fee * this.extServicesPrice.period -
                (this.extServicesPrice.fee * this.extServicesPrice.period * this.discount) / 100;

            this.extServicesPriceAnnualy =
                this.extServicesPrice.fee * this.extServicesPrice.period -
                (this.extServicesPrice.fee * this.extServicesPrice.period * this.discount) / 100;

            this.wifiPriceAnnualy =
                this.wifiPrice.fee * this.wifiPrice.period -
                (this.wifiPrice.fee * this.wifiPrice.period * this.discount) / 100;

            this.totalPrice.fee =
                this.internetPriceAnnualy +
                this.televisionPriceAnnualy +
                this.extServicesPriceAnnualy +
                this.wifiPriceAnnualy +
                this.setttopboxFeeAnnualy;
        } else {
            this.totalPrice.fee =
                this.internetPrice.fee +
                this.televisionPrice.fee +
                this.extServicesPrice.fee +
                this.wifiPrice.fee +
                this.setttopboxFee;
        }

        this.totalPrice.price =
            this.internetPrice.price +
            this.televisionPrice.price +
            this.extServicesPrice.price +
            this.wifiPrice.price +
            this.vipinstallFee;
        this.ref.markForCheck();
    }

    onSubmit(): void {
        this.submited = true;
        this.isDirty = false;
        this.loadingOrder = true;

        if (this.isUpdate) {
            this.editOrder();
        }
    }

    async searchCRMClientByUName(searchTerm: string): Promise<boolean> {
        return new Promise(resolve => {
            const queryString = `&_method=GET&filter[field]=contact&filter[operator]=eq&filter[value]=${searchTerm}`;

            this.apisService
                .getRemoteData(30, [], false, queryString)
                .pipe(untilDestroyed(this))
                .subscribe(
                    (dataCRM: unknown) => {
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-expect-error
                        if (dataCRM?.result) {
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-expect-error
                            if (dataCRM.result.total > 0) {
                                resolve(true);
                            } else {
                                resolve(false);
                            }
                        } else {
                            resolve(false);
                        }
                    },
                    () => {
                        resolve(false);
                    },
                );
        });
    }

    // eslint-disable-next-line complexity
    editOrder(): void {
        if (this.orderForm.invalid) {
            console.error('form is not valid!');

            return;
        }

        this.loadingOrder = true;

        if (this.currentUser && this.permissionService.checkUserISServiceAdmin(this.currentUser)) {
            const data: ServiceOrderInput = {
                id: this.order?.id,
                address: this.f.address.value ? this.f.address.value : null,
                ruian: this.f.ruian.value ? this.f.ruian.value : null,
                field_number: this.f.field_number.value ? this.f.field_number.value : null,
                cl_firstname: this.f.cl_firstname.value ? this.f.cl_firstname.value : null,
                cl_lastname: this.f.cl_lastname.value ? this.f.cl_lastname.value : null,
                cl_email: this.f.cl_email.value ? this.f.cl_email.value : null,
                cl_billing_email: this.f.cl_billing_email.value
                    ? this.f.cl_billing_email.value
                    : null,
                cl_technical_email: this.f.cl_technical_email.value
                    ? this.f.cl_technical_email.value
                    : null,
                cl_business_email: this.f.cl_business_email.value
                    ? this.f.cl_business_email.value
                    : null,
                cl_telephone: this.f.cl_telephone.value ? this.f.cl_telephone.value : null,
                cl_mobilephone: this.f.cl_mobilephone.value ? this.f.cl_mobilephone.value : null,
                cl_account_number: this.f.cl_account_number.value
                    ? this.f.cl_account_number.value
                    : null,
                cl_bank_number: this.f.cl_bank_number.value ? this.f.cl_bank_number.value : null,
                cl_address: this.f.cl_address.value ? this.f.cl_address.value : null,
                cl_billing_address: this.f.cl_billing_address.value
                    ? this.f.cl_billing_address.value
                    : null,
                cl_vat_id: this.f.cl_vat_id.value > 0 ? this.f.cl_vat_id.value : null,
                cl_national_id_number: this.f.cl_national_id_number.value
                    ? this.f.cl_national_id_number.value
                    : null,
                cl_send_invoices_by_post: this.f.cl_send_invoices_by_post.value
                    ? this.f.cl_send_invoices_by_post.value
                    : false,
                cl_send_invoice_by_email: this.f.cl_send_invoice_by_email.value
                    ? this.f.cl_send_invoice_by_email.value
                    : true,
                cl_cnumber: this.f.cl_cnumber.value ? this.f.cl_cnumber.value : 0,
                invoice_by_year: this.f.invoice_by_year.value
                    ? this.f.invoice_by_year.value
                    : false,
                vip_install: this.f.vip_install.value ? this.f.vip_install.value : false,
                internal_note: this.f.internal_note.value ? this.f.internal_note.value : null,
                tvs: this.tvsList.value ? JSON.stringify(this.tvsList.value) : null,
                wicon_own_router: this.f.wicon_own_router.value
                    ? this.f.wicon_own_router.value
                    : false,
                wicon_basics: this.f.wicon_basics.value ? this.f.wicon_basics.value : false,
                wicon_garden: this.f.wicon_garden.value ? this.f.wicon_garden.value : false,
                wicon_cabels: this.f.wicon_cabels.value ? this.f.wicon_cabels.value : false,
                wicon_roaming: this.f.wicon_roaming.value ? this.f.wicon_roaming.value : false,
                operator_id: this.currentUser.id,
                type_id: this.f.type_id.value,
                provider_id: this.f.provider_id.value ? this.f.provider_id.value : null,
                deal_id: this.f.deal_id.value ? this.f.deal_id.value : null,
                deal_age: this.f.deal_id.value ? (this.dealAge ? this.dealAge : null) : null,
                unit_id: this.f.unit_id.value ? this.f.unit_id.value : null,
                space_id: this.f.space_id.value ? this.f.space_id.value : null,
                speed_id: this.f.speed_id.value ? this.f.speed_id.value : null,
                hw_id: this.f.hw_id.value ? this.f.hw_id.value : null,
                net_package_id: this.f.net_package_id.value ? this.f.net_package_id.value : null,
                tv_service_id: this.f.tv_service_id.value ? this.f.tv_service_id.value : null,
                ext_services: this.f.ext_services.value ? this.f.ext_services.value : null,
                total_price: this.totalPrice.price ? this.totalPrice.price : 0,
                total_fee: this.totalPrice.fee ? this.totalPrice.fee : 0,
                internet_price: this.internetPrice.price ? this.internetPrice.price : 0,
                internet_fee: this.internetPrice.fee ? this.internetPrice.fee : 0,
                television_price: this.televisionPrice.price ? this.televisionPrice.price : 0,
                television_fee: this.televisionPrice.fee ? this.televisionPrice.fee : 0,
                ext_services_price: this.extServicesPrice.price ? this.extServicesPrice.price : 0,
                ext_services_fee: this.extServicesPrice.fee ? this.extServicesPrice.fee : 0,
                wifi_price: this.wifiPrice.price ? this.wifiPrice.price : 0,
                wifi_fee: this.wifiPrice.fee ? this.wifiPrice.fee : 0,
                tv_packages: this.f.tv_packages.value ? this.f.tv_packages.value : null,
                status: this.f.status.value,
                client_status:
                    this.f.stmnt_id.value && this.f.cl_cnumber.value > 0
                        ? 'existing_client'
                        : 'new_client',
            };

            this.servicesService
                .updateOrder(data)
                ?.pipe(untilDestroyed(this))
                .subscribe(
                    // eslint-disable-next-line complexity
                    async (order: Orders) => {
                        let provider: string;
                        let tvsStr = '';
                        const extServicesStr: string[] = [];
                        const tvPackagesStr: string[] = [];

                        if (order.ext_services && order.ext_services.length > 0) {
                            order.ext_services.forEach((service: ExtendServices) => {
                                extServicesStr.push(service.name);
                            });
                        }

                        if (order.tv_packages && order.tv_packages.length > 0) {
                            order.tv_packages.forEach((tvPackage: TvPackages) => {
                                tvPackagesStr.push(tvPackage.name);
                            });
                        }

                        if (order.tvs_list.length > 0) {
                            order.tvs_list.map((tv: SmartTvInterface) => {
                                if (typeof tv.tv === 'string') {
                                    if (tv.settopbox) {
                                        tvsStr += `${tv.tv} | SETTOPBOX: ANO${
                                            tv.settopbox_fee ? ' - pronájem, ' : ' - odkup, '
                                        }`;
                                    } else {
                                        tvsStr += `${tv.tv} | SETTOPBOX: VLASTNÍ / SMART-TV, `;
                                    }
                                }
                            });
                        }

                        if (order.provider_id === 2) {
                            provider = 'categories_60a254b4cee5e528537128';
                        } else if (order.provider_id === 4) {
                            provider = 'categories_60a254bc8d0b2851127678';
                        } else {
                            provider = 'categories_60a254b4cee5e528537128';
                        }

                        let crmName = false;

                        if (order.cl_firstname && order.cl_lastname && order.cl_cnumber > 0) {
                            crmName = await this.searchCRMClientByUName(
                                `${`${order.cl_firstname}_${order.cl_lastname}`.toLowerCase()}_${
                                    order.cl_cnumber
                                }`,
                            );
                        }

                        const queryString = `${
                            order.crm_id ? `${order.crm_id}.json` : ''
                        }?accessToken=b6794a70d418fdc92848c50cda389926b3c1b572${
                            order.crm_id ? '&_method=PUT' : '&_method=POST'
                            // eslint-disable-next-line max-len
                        }&title=Nová objednávka z CallWizard JONSystemu&category=${provider}&statuses[status]=statuses_5c7ce2a8e932a153645550&stage=WAIT&priority=MEDIUM&description=Operátor na callwizardu JONSystem právě přidal novou objednávku&user=${
                            // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                            this.currentUser?.crmApiUser_data?.name
                                ? (this.currentUser.crmApiUser_data.name as string)
                                : 'admin'
                        }${
                            crmName
                                ? `&contact=${
                                    order.cl_firstname &&
                                    order.cl_lastname &&
                                    order.cl_cnumber > 0
                                        ? `${`${order.cl_firstname}_${order.cl_lastname}`.toLowerCase()}_${
                                            order.cl_cnumber
                                        }`
                                        : ''
                                }`
                                : ''
                        }&customFields[adresa_sluzby]=${
                            order.address ? order.address : ''
                        }&customFields[jmeno_zakaznika]=${
                            order.cl_firstname ? `${order.cl_firstname} ` : ''
                        }${
                            order.cl_lastname ? order.cl_lastname : ''
                        }&customFields[klientske_cislo]=${
                            order.cl_cnumber ? order.cl_cnumber : ''
                        }&customFields[id_uzivatele_z_isp]=${
                            order.stmnt_id ? order.stmnt_id : ''
                        }&customFields[proklik_do_isp]=${
                            order.stmnt_id
                                ? `https://isp.jon.cz/client-informations/?clientId=${order.stmnt_id}`
                                : ''
                        }&customFields[active_client_services]=${
                            // '&customFields[bilance]=' + ((this.clickedRow[0] && this.clickedRow[0].balance) ? this.clickedRow[0].balance : 0) + TODO
                            this.order?.cl_active_services &&
                            this.order.cl_active_services.length > 0
                                ? this.order.cl_active_services.toString()
                                : ''
                        }&customFields[email]=${
                            order.cl_email ? order.cl_email : ''
                        }&customFields[fakturacni_email]=${
                            order.cl_billing_email ? order.cl_billing_email : ''
                        }&customFields[address]=${
                            order.cl_address ? order.cl_address : ''
                        }&customFields[fakturacni_adresa]=${
                            order.cl_billing_address ? order.cl_billing_address : ''
                        }&customFields[number]=${
                            order.cl_telephone
                                ? order.cl_telephone
                                : order.cl_mobilephone
                                    ? order.cl_mobilephone
                                    : ''
                        }&customFields[ico]=${
                            order.cl_vat_id ? order.cl_vat_id : ''
                        }&customFields[zasilani_faktury]=${
                            order.cl_send_invoices_by_post ? 'poštou' : 'e-mailem'
                        }&customFields[bankovni_ucet]=${
                            order.cl_account_number ? order.cl_account_number : ''
                        }${
                            order.cl_bank_number ? `/${order.cl_bank_number}` : ''
                        }&customFields[tarif_internet]=${
                            order.net_package?.name ? order.net_package.name : ''
                        }&customFields[tarif_tv]=${
                            order.tv_service?.name ? order.tv_service.name : ''
                        }&customFields[tv_balicky]=${
                            tvPackagesStr.length > 0 ? tvPackagesStr.toString() : ''
                        }&customFields[hw_balicek]=${
                            order.hw_package?.name ? order.hw_package.name : ''
                        }&customFields[rozsirujici_sluzby]=${
                            extServicesStr.length > 0 ? extServicesStr.toString() : ''
                        }&customFields[note]=${
                            order.internal_note ? order.internal_note : ''
                        }&customFields[lead_id]=${order.id ? order.id : ''}&customFields[router]=${
                            order.wicon_own_router ? 'vlastní' : 'do pronájmu'
                        }&customFields[nazev_televize]=${tvsStr}&customFields[hw_odkup]=${
                            order.wifi_price ? 'ANO' : 'NE'
                        }&customFields[cena_sluzby]=${
                            order.wifi_fee ? order.wifi_fee : order.wifi_price
                        }&customFields[cena_tv_sluzby]=${
                            order.television_fee ? order.television_fee : order.television_price
                        }&customFields[cena_net_sluzby]=${
                            order.internet_fee ? order.internet_fee : order.internet_price
                        }&customFields[cena_rozsirujicich_sluzeb]=${
                            order.ext_services_fee
                                ? order.ext_services_fee
                                : order.ext_services_price
                        }&customFields[cena_celkem]=${
                            order.total_fee
                        }&customFields[cena_jednorazova]=${
                            order.total_price
                        }&customFields[prednostni_instalace]=${
                            order.vip_install ? 'ANO' : 'NE'
                        }&customFields[smluvni_uvazek]=${
                            order.deal_type ? order.deal_type.age : ''
                        }&customFields[rocni_predplatne]=${order.invoice_by_year ? 'ANO' : 'NE'}`;

                        if (!this.order?.crm_id) {
                            await this.apisService
                                .getRemoteData(38, [], false, queryString)
                                .pipe(untilDestroyed(this))
                                .subscribe(
                                    (dataCRM: unknown) => {
                                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                        // @ts-expect-error
                                        if (dataCRM?.result) {
                                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                            // @ts-expect-error
                                            this.order.crm_id = dataCRM.result.name;
                                            this.ref.markForCheck();
                                            console.info('CRM ticket created...');
                                        }
                                    },
                                    error => {
                                        console.error(error);
                                    },
                                );
                        } else {
                            await this.apisService
                                .getRemoteData(40, [], false, queryString)
                                .pipe(untilDestroyed(this))
                                .subscribe(
                                    (dataCRM: unknown) => {
                                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                        // @ts-expect-error
                                        if (dataCRM?.result) {
                                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                            // @ts-expect-error
                                            this.order.crm_id = dataCRM.result.name;
                                            this.ref.markForCheck();
                                            console.info('CRM ticket updated...');
                                        }
                                    },
                                    error => {
                                        console.error(error);
                                    },
                                );
                        }

                        this.dataNetbaseService.setOrdersSource();
                        this.isUpdate = false;
                        this.loadingOrder = false;
                        this.isDirty = false;

                        const options = {
                            progressBar: true,
                            timeOut: 5000,
                            toastClass: 'success',
                        };

                        this.message.showSuccess('Objednávka upravena', '', options);
                        this.ref.markForCheck();
                        this.formDialogObj.hide();
                    },
                    error => {
                        console.error(error);

                        const body = 'Zkuste to znovu později';
                        const options = {progressBar: true, timeOut: 5000};

                        this.messages.showError('Chyba při úpravě žádosti', body, options);
                        this.loadingOrder = false;
                        this.ref.markForCheck();
                    },
                );
        } else {
            const body = 'Nemáte oprávnění provést tuto akci...';
            const options = {progressBar: true, timeOut: 5000};

            this.messages.showError('Nedostatečné oprávnění', body, options);
            this.isUpdate = false;
            this.loadingOrder = false;
            this.isDirty = false;
            this.ref.markForCheck();
            this.formDialogObj.hide();
        }
    }

    showInputErrors(): void {
        this.isChecked = true;
        /* console.info('VALID: ' + this.ticketForm.valid);
        Object.keys(this.ticketForm.controls).forEach(key => {
            const controlErrors: ValidationErrors = this.ticketForm.get(key).errors;
            if (controlErrors != null) {
                Object.keys(controlErrors).forEach(keyError => {
                    console.info('Key control: ' + key + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError]);
                });
            }
        });*/
        this.orderForm.markAllAsTouched();
        this.ref.markForCheck();
    }
}
