import {
    AfterViewChecked,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnInit,
    ViewChild,
} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthenticationService} from '@src/app/_services/authentication.service';
import {Knowledge} from '@src/app/_models/knowledge/knowledge';
import {User} from '@src/app/_models/user/user';
import {PermissionService} from '@src/app/_services/permission.service';
import {FormBuilder} from '@angular/forms';
import {KnowledgeCategory} from '@src/app/_models/knowledge/knowledge-category';
import {registerLocaleData} from '@angular/common';
import {Observable, Observer} from 'rxjs';
import {DataService} from '@src/app/_services/data.service';
import {
    DataResult,
    EditSettingsModel,
    ExcelExportProperties,
    FilterSettingsModel,
    GridComponent,
    GroupSettingsModel,
    PageSettingsModel,
    RowDataBoundEventArgs,
    SelectionSettingsModel,
    ToolbarItems,
} from '@syncfusion/ej2-angular-grids';
import {ChangeEventArgs, DropDownListComponent} from '@syncfusion/ej2-angular-dropdowns';
import {ButtonPropsModel, DialogComponent} from '@syncfusion/ej2-angular-popups';
import {ClickEventArgs} from '@syncfusion/ej2-angular-navigations';
import {MessageService} from '@src/app/_services/message.service';
import moment from 'moment';
import localeCs from '@angular/common/locales/cs';
import {TextBoxComponent} from '@syncfusion/ej2-angular-inputs';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {
    KnowledgeGridItems,
    KnowledgeService,
} from '@src/app/features/knowledge/knowledge.service';
import {SettingsService} from '@src/app/features/settings/settings.service';

moment.updateLocale('cs', {workingWeekdays: [1, 2, 3, 4, 5]});
registerLocaleData(localeCs);

@UntilDestroy()
@Component({
    selector: 'app-knowledge-list',
    templateUrl: './knowledge-list.component.html',
    styleUrls: ['./knowledge-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KnowledgeListComponent implements OnInit, AfterViewChecked {
    // Grids
    fields: object = {text: 'label', value: 'value'};

    fieldsStatus: object = {text: 'value', value: 'value'};

    filterSettings: FilterSettingsModel;

    selectionSettings: SelectionSettingsModel;

    editSettings: EditSettingsModel;

    groupOptions: GroupSettingsModel;

    pageOptions: PageSettingsModel;

    filterDate: object;

    requiredRules: object;

    currencyFormat: {format: string} = {format: '#,###.00\',- CZK\''};

    dateFormat: string;

    toolbar: ToolbarItems[] | object;

    data: object;

    sortOptions: object;

    exportCurrentPage = false;

    // Dropdowns
    height = '240px';

    // Dialogs
    renderKnowledgeFormDialog = false;

    buttonsDelete: ButtonPropsModel[] = [
        {
            click: (): void => {
                this.deleteKnowledge();
            },
            isFlat: false,
            buttonModel: {
                content: 'OK',
                cssClass: 'e-success e-outline',
            },
        },
        {
            click: (): void => {
                this.confirmationDelete.hide();
            },
            buttonModel: {
                content: 'ZRUŠIT',
                cssClass: 'e-danger e-outline',
            },
        },
    ];

    buttonsCancel: ButtonPropsModel[] = [
        {
            click: (): void => {
                this.confirmationCancel.hide();
            },
            buttonModel: {
                isPrimary: true,
                content: 'OK',
            },
        },
    ];

    // Forms
    isCreate = false;

    isUpdate = false;

    isCopy = false;

    // Variables
    currentUser: User | null;

    knowledges: Knowledge[] = [];

    category: KnowledgeCategory;

    categoryFilterData: string[] = [];

    clickedRow: Knowledge[] = [];

    clickedRow$ = new Observable<Knowledge[]>((observer: Observer<Knowledge[]>) => {
        observer.next(this.clickedRow);
    });

    // Loaders
    loadingKnowledge = true;

    // Grids
    @ViewChild('knowledgeBaseGrid') gridKnowledges: GridComponent;

    @ViewChild('searchtext') searchtextObj: TextBoxComponent;

    // Dropdowns
    @ViewChild('viewFilter') viewFilterInstance: DropDownListComponent;

    // Dialogs
    @ViewChild('confirmationDelete') confirmationDelete: DialogComponent;

    @ViewChild('confirmationCancel') confirmationCancel: DialogComponent;

    constructor(
        private readonly router: Router,
        private readonly route: ActivatedRoute,
        private readonly knowledgeService: KnowledgeService,
        private readonly authenticationService: AuthenticationService,
        private readonly settingsService: SettingsService,
        private readonly permissionService: PermissionService,
        private readonly ref: ChangeDetectorRef,
        private readonly formBuilder: FormBuilder,
        private readonly dataService: DataService,
        private readonly messages: MessageService,
    ) {
        this.currentUser = this.authenticationService.currentUserValue;
    }

    ngOnInit(): void {
        this.filterSettings = {
            type: 'Excel',
            showFilterBarOperator: true,
            showFilterBarStatus: true,
            ignoreAccent: true,
        };

        this.selectionSettings = {
            persistSelection: true,
            type: 'Multiple',
            checkboxOnly: true,
        };

        this.toolbar = [
            {
                text: 'Obnovit data',
                tooltipText: 'Obnovit data',
                prefixIcon: 'e-refresh',
                id: 'customRefreshKwd',
            },
            'Print',
            'ExcelExport',
            'Add',
            'Edit',
            {
                text: 'Kopírovat',
                tooltipText: 'Kopírovat pracovní postup',
                prefixIcon: 'e-copy',
                id: 'copy',
            },
            {
                text: 'Smazat',
                tooltipText: 'Smazat pracovní postup',
                prefixIcon: 'e-remove',
                id: 'delete',
            },
            {
                text: 'Detail',
                tooltipText: 'Detail pracovního postupu',
                prefixIcon: 'e-openfolder',
                id: 'detail',
            },
        ];

        this.categoryFilterData = [
            'všechny pracovní postupy',
            'pouze aktivní pracovní postupy',
            'moje pracovní postupy - aktivní',
            'moje pracovní postupy - smazané',
            'označené pracovní postupy - pro mě',
            'označené pracovní postupy - pro moje oddělení',
            'smazané pracovní postupy',
        ];
        this.pageOptions = {pageSize: 50};
        this.dateFormat = 'dd.MM.yyyy HH:mm';
        this.filterDate = {type: 'Menu', params: {format: this.dateFormat}};
        this.groupOptions = {
            showGroupedColumn: true,
            disablePageWiseAggregates: false,
        };

        this.editSettings = {
            allowEditing: true,
            allowAdding: true,
            allowDeleting: true,
            allowEditOnDblClick: false,
            mode: 'Dialog',
        };

        this.requiredRules = {required: true};

        this.dataService.setKnowledgesDataSource();
    }

    ngAfterViewChecked(): void {
        this.route.queryParamMap.pipe(untilDestroyed(this))
            .subscribe(params => {
                if (params.get('form') === 'addKnowledge') {
                    console.info('get vacation form');
                    this.isCreate = true;
                    this.isUpdate = false;
                    this.isCopy = false;
                    this.renderKnowledgeFormDialog = true;
                    this.ref.markForCheck();
                }
            });
    }

    onCreated(): void {
        this.disableToolBarBtn();
    }

    setInitialGridFiltering(): void {
        this.gridKnowledges.clearFiltering();
        // this.gridKnowledges.filterByColumn('created_by', 'equal', this.currentUser.id, 'and', false, true);
        // this.viewFilterInstance.value = 'moje žádanky - všechny';
        this.ref.markForCheck();
    }

    loadData(): DataResult[] {
        this.gridKnowledges.clearRowSelection();

        console.info('NEW DATA BOUND');

        const elements: KnowledgeGridItems[] = [];

        if (this.knowledges.length > 0) {
            this.knowledges.map((knowledge: Knowledge) => {
                let users = '';
                let departments = '';

                if (knowledge.users.length > 0) {
                    knowledge.users.map(user => {
                        users += `${user.fullname}, `;
                    });
                }

                if (knowledge.departments.length > 0) {
                    knowledge.departments.map(department => {
                        departments += `${department.name}, `;
                    });
                }

                elements.push({
                    id: knowledge.id,
                    created_by: knowledge.created_by,
                    creator_str: knowledge.creator.fullname,
                    creator_img: knowledge.creator.personalphoto,
                    category_id: knowledge.category_id,
                    subject: knowledge.subject,
                    category: knowledge.category.name,
                    maintask: knowledge.maintask,
                    maintaskHTML: knowledge.maintaskHTML,
                    created_date: new Date(knowledge.created_date),
                    updated_date: knowledge.updated_date ? new Date(knowledge.updated_date) : null,
                    deleted_date: knowledge.deleted_date ? new Date(knowledge.deleted_date) : null,
                    files: knowledge.files,
                    users: knowledge.users,
                    users_str: users,
                    departments: knowledge.departments,
                    departments_str: departments,
                    comments: knowledge.comments,
                });
            });
        }

        this.loadingKnowledge = false;
        this.ref.markForCheck();

        return elements as unknown as DataResult[];
    }

    onLoad(): void {
        const wrapper1 = document.getElementById('scroll_wrapper1_knowledges');
        const wrapper2 = document.getElementById('scroll_wrapper2_knowledges');

        if (wrapper1 && wrapper2) {
            wrapper1.onscroll = (): void => {
                wrapper2.scrollLeft = wrapper1.scrollLeft;
            };

            wrapper2.onscroll = (): void => {
                wrapper1.scrollLeft = wrapper2.scrollLeft;
            };
        }

        this.dataService.knowledgeSource.pipe(untilDestroyed(this))
            .subscribe(
                (knowledges: Knowledge[] | undefined) => {
                    if (knowledges && knowledges.length > 0) {
                        this.knowledges = knowledges;
                        this.data = this.gridKnowledges.dataSource = this.loadData();
                        this.sortOptions = {
                            columns: [
                                {field: 'created_date', direction: 'Descending'},
                                {field: 'id', direction: 'Descending'},
                            ],
                        };
                    } else {
                        this.loadingKnowledge = false;
                    }
                },
                error => {
                    console.error(error);
                    this.loadingKnowledge = false;
                    this.ref.markForCheck();
                },
            );
    }

    search(): void {
        this.gridKnowledges.search(this.searchtextObj.value);
    }

    onDataBound(): void {
        this.rowSelected();

        const width = document.getElementById('knowledgeBaseGrid_content_table')?.offsetWidth;
        const parent1 = document.getElementById('scroll_div_knowledges');
        const parent2 = document.getElementById('grid_parent_knowledges');

        if (parent1 && parent2 && width) {
            parent1.style.width = `${width}px`;
            parent2.style.width = `${width}px`;
        }

        this.gridKnowledges.autoFitColumns();
        this.ref.markForCheck();
    }

    rowDataBound(args: RowDataBoundEventArgs): void {
        const data = args.data as Knowledge;

        if (args.row && data.deleted_date) {
            args.row.getElementsByClassName('e-gridchkbox')[0].classList.add('disablecheckbox');
            args.row
                .getElementsByClassName('e-checkbox-wrapper')[0]
                .classList.add('disablecheckbox');
            args.row.classList.add('e-disabled');
        }

        if (
            this.currentUser &&
            args.row &&
            data.created_by !== this.currentUser.id &&
            !this.permissionService.checkUserISKnowledgeAdmin(this.currentUser) &&
            !this.permissionService.checkUserISAdmin(this.currentUser)
        ) {
            args.row.getElementsByClassName('e-gridchkbox')[0].classList.add('disablecheckbox');
            args.row
                .getElementsByClassName('e-checkbox-wrapper')[0]
                .classList.add('disablecheckbox');
        }

        this.ref.markForCheck();
    }

    onStatusFilterChange(e: ChangeEventArgs): void {
        if (e.value === 'všechny pracovní postupy') {
            this.gridKnowledges.clearFiltering();
        } else if (e.value === 'pouze aktivní pracovní postupy') {
            this.gridKnowledges.clearFiltering();
            this.gridKnowledges.filterByColumn('deleted_date', 'equal', null, 'and', false, true);
        } else if (e.value === 'moje pracovní postupy - aktivní') {
            this.gridKnowledges.clearFiltering();
            this.gridKnowledges.filterSettings = {
                columns: [
                    {
                        field: 'created_by',
                        operator: 'equal',
                        value: this.currentUser?.id,
                        predicate: 'and',
                        matchCase: false,
                        ignoreAccent: true,
                    },
                    {
                        field: 'deleted_date',
                        operator: 'equal',
                        value: null as unknown as string,
                        predicate: 'and',
                        matchCase: false,
                        ignoreAccent: true,
                    },
                ],
            };
        } else if (e.value === 'moje pracovní postupy - smazané') {
            this.gridKnowledges.clearFiltering();
            this.gridKnowledges.filterSettings = {
                columns: [
                    {
                        field: 'created_by',
                        operator: 'equal',
                        value: this.currentUser?.id,
                        predicate: 'and',
                        matchCase: false,
                        ignoreAccent: true,
                    },
                    {
                        field: 'deleted_date',
                        operator: 'notequal',
                        value: null as unknown as string,
                        predicate: 'and',
                        matchCase: false,
                        ignoreAccent: true,
                    },
                ],
            };
        } else if (e.value === 'označené pracovní postupy - pro mě') {
            this.gridKnowledges.clearFiltering();
            this.gridKnowledges.filterSettings = {
                columns: [
                    {
                        field: 'created_by',
                        operator: 'equal',
                        value: this.currentUser?.id,
                        predicate: 'and',
                        matchCase: false,
                        ignoreAccent: true,
                    },
                    {
                        field: 'deleted_date',
                        operator: 'equal',
                        value: null as unknown as string,
                        predicate: 'and',
                        matchCase: false,
                        ignoreAccent: true,
                    },
                ],
            };
        } else if (e.value === 'označené pracovní postupy - pro moje oddělení') {
            this.gridKnowledges.clearFiltering();
            this.gridKnowledges.filterSettings = {
                columns: [
                    {
                        field: 'created_by',
                        operator: 'equal',
                        value: this.currentUser?.id,
                        predicate: 'and',
                        matchCase: false,
                        ignoreAccent: true,
                    },
                    {
                        field: 'deleted_date',
                        operator: 'notequal',
                        value: null as unknown as string,
                        predicate: 'and',
                        matchCase: false,
                        ignoreAccent: true,
                    },
                ],
            };
        } else if (e.value === 'smazané pracovní postupy') {
            this.gridKnowledges.clearFiltering();
            this.gridKnowledges.filterByColumn(
                'deleted_date',
                'notequal',
                null,
                'and',
                false,
                true,
            );
        }
    }

    cancelBtnClick(): void {
        this.searchtextObj.value = '';
        this.gridKnowledges.searchSettings.key = '';
    }

    toolbarClick(args: ClickEventArgs): void {
        const xlsProp: ExcelExportProperties = {
            fileName: 'js_knowledges_export.xlsx',
            enableFilter: true,
            exportType: this.exportCurrentPage ? 'CurrentPage' : 'AllPages',
        };

        if (args.item.id === 'knowledgeBaseGrid_excelexport') {
            void this.gridKnowledges.excelExport(xlsProp);
        } else if (args.item.id === 'delete') {
            if (this.gridKnowledges.getSelectedRecords().length !== 0) {
                this.confirmationDelete.content = `Potvrďte smazání ${this.clickedRow.length} pracovních postupů`;
                this.confirmationDelete.show();
            } else {
                this.confirmationCancel.content = 'Musíte vybrat alespoň jeden pracovní postup';
                this.confirmationCancel.show();
            }
        } else if (args.item.id === 'copy') {
            if (this.gridKnowledges.getSelectedRecords().length !== 0) {
                this.isUpdate = false;
                this.isCreate = false;
                this.isCopy = true;
                this.renderKnowledgeFormDialog = true;
                this.ref.markForCheck();
            } else {
                this.confirmationCancel.content = 'Musíte vybrat alespoň jeden pracovní postup';
                this.confirmationCancel.show();
            }
        } else if (args.item.id === 'detail') {
            void this.router.navigate(['/knowledge/detail', this.clickedRow[0].id]);
        } else if (args.item.id === 'customRefreshKwd') {
            this.dataService.setKnowledgesDataSource();
        }
    }

    enableToolbarBtn(): void {
        this.gridKnowledges.toolbarModule.enableItems(
            ['knowledgeBaseGrid_edit', 'delete', 'detail', 'copy'],
            true,
        );
    }

    disableToolBarBtn(): void {
        this.gridKnowledges.toolbarModule.enableItems(
            ['knowledgeBaseGrid_edit', 'delete', 'detail', 'copy'],
            false,
        );
    }

    rowSelected(): void {
        this.clickedRow = [];

        const selectedrecords: Knowledge[] =
            this.gridKnowledges.getSelectedRecords() as Knowledge[];

        if (selectedrecords.length > 0) {
            selectedrecords.map(row => {
                const tempData = this.knowledges.find(x => x.id === row.id);

                if (tempData) {
                    this.clickedRow.push(tempData);
                }

                this.ref.markForCheck();
            });

            this.clickedRow$ = new Observable<Knowledge[]>((observer: Observer<Knowledge[]>) => {
                observer.next(this.clickedRow);
            });
            this.enableToolbarBtn();
        } else {
            this.disableToolBarBtn();
            this.clickedRow$ = new Observable<Knowledge[]>((observer: Observer<Knowledge[]>) => {
                observer.next(this.clickedRow);
            });
        }
    }

    actionBegin(args): void {
        if (args.requestType === 'add' || args.requestType === 'beginEdit') {
            args.cancel = true;

            if (args.requestType === 'beginEdit') {
                this.isUpdate = true;
                this.isCreate = false;
                this.isCopy = false;
            }

            if (args.requestType === 'add') {
                this.isCreate = true;
                this.isUpdate = false;
                this.isCopy = false;
            }

            this.renderKnowledgeFormDialog = true;
            this.ref.markForCheck();
        }
    }

    actionComplete(args): void {
        if (args.requestType === 'beginEdit' || args.requestType === 'add') {
            args.form.ej2_instances[0].rules = {};
        }
    }

    deleteKnowledge(): void {
        const promises: Promise<Knowledge | undefined>[] = [];
        let rejects = 0;

        this.loadingKnowledge = true;

        for (const row of this.clickedRow) {
            if (!row.deleted_date && row.created_by === this.currentUser?.id) {
                row.deleted_by = this.currentUser.id;

                const declineObj = this.knowledgeService.deleteKnowledge(row)
                    .toPromise();

                promises.push(declineObj);
            } else {
                rejects++;
            }
        }

        void Promise.all(promises)
            .then(result => {
                if (result.length > 0) {
                    const body = `Celkem smazáno: ${result.length} postupů`;
                    const options = {progressBar: true, timeOut: 5000};

                    this.messages.showSuccess('Smazání úspěšně dokončeno', body, options);
                }

                if (rejects > 0) {
                    const body = `Celkem u: ${rejects} postupů`;
                    const options = {progressBar: true, timeOut: 5000};

                    this.messages.showError('Nedostatečné oprávnění', body, options);
                }

                this.gridKnowledges.clearRowSelection();
                this.dataService.setKnowledgesDataSource();
                this.rowSelected();
                this.loadingKnowledge = false;
                this.confirmationDelete.hide();
                this.ref.markForCheck();
            });
    }

    refreshData(): void {
        this.loadingKnowledge = true;
        this.dataService.clearKnowledgesCache();
        this.dataService.setKnowledgesDataSource();
    }
}
