<div class="limiter">
  <div class="container-login100">
    <div class="wrap-login100">
      <form
        (ngSubmit)="onSubmit()"
        [formGroup]="loginForm"
        class="login100-form"
        data-wow-delay="0.3s"
        style="animation-name: none; visibility: visible"
      >
        <span class="login100-form-title pb-3">Vítejte</span>
        <div class="d-flex justify-content-center pb-5">
          <img
            alt="logo"
            class="img-fluid"
            src="/assets/img/logo-jon-orig.png"
            style="
                            width: 100% !important;
                            height: auto !important;
                            max-height: 100px !important;
                        "
          />
        </div>
        <div class="wrap-input100">
          <input
            [ngClass]="{ 'e-error': submited && f.username.errors }"
            autocomplete="on"
            class="input100"
            formControlName="username"
            id="username"
            placeholder="Váš firemní email"
            type="email"
          />
          <span class="focus-input100"></span>
          <div
            *ngIf="submited && f.username.errors"
            class="invalid-feedback"
          >
            <div *ngIf="f.username.errors.required">
              Uživatelské jméno je povinné
            </div>
            <div *ngIf="f.username.errors.email"
            >Zadejte platný email
            </div>
          </div>
        </div>
        <div class="wrap-input100">
          <input
            [ngClass]="{ 'e-error': submited && f.password.errors }"
            autocomplete="on"
            class="input100"
            formControlName="password"
            id="password"
            placeholder="Heslo k účtu"
            type="password"
          />
          <span class="focus-input100"></span>
          <div
            *ngIf="submited && f.password.errors"
            class="invalid-feedback"
          >
            <div *ngIf="f.password.errors.required"
            >Heslo je povinné
            </div>
          </div>
        </div>
        <div class="container-login100-form-btn pb-3x">
          <div class="wrap-login100-form-btn">
            <div class="login100-form-bgbtn"></div>
            <button
              [disabled]="loading"
              class="login100-form-btn"
              ejs-button
            >
              <span *ngIf="!loading">Přihlásit</span>
              <span *ngIf="loading">Provádím...</span>
            </button>
          </div>
        </div>
        <!-- <div class="text-center pt-4">
                 <span class="txt1">
                     Don’t have an account?
                 </span>
             <a class="txt2" href="#">
                 Sign Up
             </a>
         </div>-->
        <div class="col-12 text-center text-muted pb-4">
          <small>JONSystem</small><br />
          <small>1.23.11-01</small>
        </div>
        <div class="d-flex justify-content-center text-muted">
          <small
          >©
            <a
              href="https://www.jon.cz"
              target="_blank"
              title="www.jon.cz"
            >JON.CZ</a
            >
            s.r.o. 2021</small
          >
        </div>
      </form>
    </div>
  </div>
</div>
