import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule, Routes} from '@angular/router';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {BookingCalendarComponent} from '@src/app/features/booking/components/booking-calendar.component';
import {AuthGuard} from '@src/app/_guards/auth.guard';
import {SharedFeaturesModule} from '@src/app/shared_features/shared-features.module';
import {EventsModule} from '@src/app/features/events/events.module';
import {TicketsModule} from '@src/app/features/tickets/tickets.module';
import {ChipListModule} from '@syncfusion/ej2-angular-buttons';
import {enableRipple} from '@syncfusion/ej2-base';
import {BookingFormComponent} from './_forms/booking-form/booking-form.component';

enableRipple(true);

const routes: Routes = [
    {
        path: 'booking',
        children: [
            {
                path: '',
                component: BookingCalendarComponent,
                data: {
                    title: 'Rezervace',
                    breadcrumb: [
                        {
                            label: 'Boooking',
                            url: '',
                        },
                        {
                            label: 'Rezervace',
                            url: '',
                        },
                    ],
                },
            },
        ],
        canActivate: [AuthGuard],
    },
];

@NgModule({
    declarations: [BookingCalendarComponent, BookingFormComponent],
    imports: [
        BrowserAnimationsModule,
        RouterModule.forChild(routes),
        BrowserModule,
        ChipListModule,
        CommonModule,
        EventsModule,
        FormsModule,
        ReactiveFormsModule,
        SharedFeaturesModule,
        TicketsModule,
    ],
})
export class BookingModule {}
