import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule, Routes} from '@angular/router';
import {AuthGuard} from '@src/app/_guards/auth.guard';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {TimeagoCustomFormatter, TimeagoFormatter, TimeagoIntl, TimeagoModule} from 'ngx-timeago';
import {
    CallwizardAddressComponent,
    CallwizardHardwareComponent,
    CallwizardInternetComponent,
    CallwizardTelevisionComponent,
} from '@src/app/features/services/_forms/callwizard/components';
import {BonusFormComponent} from '@src/app/features/services/_forms/bonus-form/bonus-form.component';
import {DealTypeFormComponent} from '@src/app/features/services/_forms/deal-type-form/deal-type-form.component';
import {DiscountFormComponent} from '@src/app/features/services/_forms/discount-form/discount-form.component';
import {ExtendedServiceFormComponent} from '@src/app/features/services/_forms/extended-service-form/extended-service-form.component';
import {FlatUnitTypeFormComponent} from '@src/app/features/services/_forms/flat-unit-type-form/flat-unit-type-form.component';
import {FlatUnitSpaceFormComponent} from '@src/app/features/services/_forms/flat-unit-space-form/flat-unit-space-form.component';
import {HardwareFormComponent} from '@src/app/features/services/_forms/hardware-form/hardware-form.component';
import {InstallationTypeFormComponent} from '@src/app/features/services/_forms/installation-type-form/installation-type-form.component';
import {NetPackageFormComponent} from '@src/app/features/services/_forms/net-package-form/net-package-form.component';
import {PlaceFormComponent} from '@src/app/features/services/_forms/place-form/place-form.component';
import {ProjectFormComponent} from '@src/app/features/services/_forms/project-form/project-form.component';
import {ServiceConnectedFieldComponent} from '@src/app/features/services/components/service-connected-field/service-connected-field.component';
import {ServiceConnectedRuianComponent} from '@src/app/features/services/components/service-connected-ruian/service-connected-ruian.component';
import {ServiceExcludedAddressComponent} from '@src/app/features/services/components/service-excluded-address/service-excluded-address.component';
import {ServiceHwConfigFormComponent} from '@src/app/features/services/_forms/service-hw-config-form/service-hw-config-form.component';
import {ServicesBonusesComponent} from '@src/app/features/services/components/services-bonuses/services-bonuses.component';
import {ServicesChannelsComponent} from '@src/app/features/services/components/services-channels/services-channels.component';
import {ServicesDealTypesComponent} from '@src/app/features/services/components/services-deal-types/services-deal-types.component';
import {ServicesDiscountsComponent} from '@src/app/features/services/components/services-discounts/services-discounts.component';
import {ServicesExtendedServicesComponent} from '@src/app/features/services/components/services-extended-services/services-extended-services.component';
import {ServicesFlatUnitsComponent} from '@src/app/features/services/components/services-flat-units/services-flat-units.component';
import {ServicesHardwareComponent} from '@src/app/features/services/components/services-hardware/services-hardware.component';
import {ServicesInstallationTypesComponent} from '@src/app/features/services/components/services-installation-types/services-installation-types.component';
import {ServicesPlacesComponent} from '@src/app/features/services/components/services-places/services-places.component';
import {ServicesSettingsComponent} from '@src/app/features/services/components/services-settings/services-settings.component';
import {ServicesSpeedTypesComponent} from '@src/app/features/services/components/services-speed-types/services-speed-types.component';
import {ServicesStatsComponent} from '@src/app/features/services/components/services-stats/services-stats.component';
import {ServicesTarifsComponent} from '@src/app/features/services/components/services-tarifs/services-tarifs.component';
import {ServicesTechnologyTypesComponent} from '@src/app/features/services/components/services-technology-types/services-technology-types.component';
import {ServicesTransferTypesComponent} from '@src/app/features/services/components/services-transfer-types/services-transfer-types.component';
import {ServicesTvPackagesComponent} from '@src/app/features/services/components/services-tv-packages/services-tv-packages.component';
import {ServiceUntrustedAddressComponent} from '@src/app/features/services/components/service-untrusted-address/service-untrusted-address.component';
import {SpeedTypeFormComponent} from '@src/app/features/services/_forms/speed-type-form/speed-type-form.component';
import {TechnologyTypeFormComponent} from '@src/app/features/services/_forms/technology-type-form/technology-type-form.component';
import {TransferTypeFormComponent} from '@src/app/features/services/_forms/transfer-type-form/transfer-type-form.component';
import {TvChannelFormComponent} from '@src/app/features/services/_forms/tv-channel-form/tv-channel-form.component';
import {TvPackageFormComponent} from '@src/app/features/services/_forms/tv-package-form/tv-package-form.component';
import {TvpackagesChannelsComponent} from '@src/app/features/services/_forms/tvpackages-channels/tvpackages-channels.component';
import {TvServiceFormComponent} from '@src/app/features/services/_forms/tv-service-form/tv-service-form.component';
import {ServicesService} from '@src/app/features/services/services.service';
import {SharedFeaturesModule} from '@src/app/shared_features/shared-features.module';
import {ServiceRuianDetailComponent} from './components/service-ruian-detail/service-ruian-detail.component';
import {ServiceFieldDetailComponent} from './components/service-field-detail/service-field-detail.component';
import {ServiceSearcherComponent} from './components/service-searcher/service-searcher.component';
import {ServiceAddressComponent} from './components/service-address/service-address.component';
import {ServicesHwConfiguratorComponent} from './components/services-hw-configurator/services-hw-configurator.component';
import {ServicesComponent} from './components/services/services.component';
import {AddressUntrustedFormComponent} from './_forms/address-untrusted-form/address-untrusted-form.component';
import {AddressExcludeFormComponent} from './_forms/address-exclude-form/address-exclude-form.component';
import {CheckRuianFormComponent} from './_forms/check-ruian-form/check-ruian-form.component';
import {FieldFormComponent} from './_forms/field-form/field-form.component';
import {RuianFormComponent} from './_forms/ruian-form/ruian-form.component';
import {PackagesCompareComponent} from './components/packages-compare/packages-compare.component';
import {ServiceOutagesComponent} from './components/service-outages/service-outages.component';
import {LeadFormComponent} from './_forms/lead-form/lead-form.component';
import {OrderFormComponent} from './_forms/order-form/order-form.component';
import {ServicesOrdersDetailComponent} from './components/services-orders-detail/services-orders-detail.component';
import {ServicesLeadsDetailComponent} from './components/services-leads-detail/services-leads-detail.component';
import {ServicesLeadsComponent} from './components/services-leads/services-leads.component';
import {ServicesOrdersComponent} from './components/services-orders/services-orders.component';
import {SearcherComponent} from './_forms/searcher/searcher.component';
import {CallwizardComponent} from './_forms/callwizard/callwizard.component';

const routes: Routes = [
    {
        path: 'services',
        children: [
            {
                path: '',
                component: ServicesComponent,
                data: {
                    title: 'Služby nástěnka',
                    breadcrumb: [
                        {
                            label: 'Služby',
                            url: '/services',
                        },
                    ],
                },
            },
            {
                path: 'hardware',
                component: ServicesHwConfiguratorComponent,
                data: {
                    title: 'Hardware',
                    breadcrumb: [
                        {
                            label: 'Služby',
                            url: '/services',
                        },
                        {
                            label: 'Seznam hardware',
                            url: '',
                        },
                    ],
                },
            },
            {
                path: 'address',
                component: ServiceAddressComponent,
                data: {
                    title: 'Adresy',
                    breadcrumb: [
                        {
                            label: 'Služby',
                            url: '/services/',
                        },
                        {
                            label: 'Seznam adres',
                            url: '',
                        },
                    ],
                },
            },
            {
                path: 'searcher',
                component: ServiceSearcherComponent,
                data: {
                    title: 'Vyhledávač',
                    breadcrumb: [
                        {
                            label: 'Služby',
                            url: '/services/',
                        },
                        {
                            label: 'Vyhledávač služeb',
                            url: '',
                        },
                    ],
                },
            },
            {
                path: 'field/detail/:id',
                component: ServiceFieldDetailComponent,
                data: {
                    title: 'Detail parcely',
                    breadcrumb: [
                        {
                            label: 'Služby',
                            url: '/services/',
                        },
                        {
                            label: 'Seznam adres',
                            url: '/services/address/',
                        },
                        {
                            label: 'Detail parcely',
                            url: '',
                        },
                    ],
                },
            },
            {
                path: 'ruian/detail/:id',
                component: ServiceRuianDetailComponent,
                data: {
                    title: 'Detail RUIAN adresy',
                    breadcrumb: [
                        {
                            label: 'Služby',
                            url: '/services/',
                        },
                        {
                            label: 'Seznam služeb',
                            url: '/services/address/',
                        },
                        {
                            label: 'Detail RUIAN adresy',
                            url: '',
                        },
                    ],
                },
            },
            {
                path: 'leads/:id',
                component: ServicesLeadsDetailComponent,
                data: {
                    title: 'Detail leadu',
                    breadcrumb: [
                        {
                            label: 'Služby',
                            url: '/services/',
                        },
                        {
                            label: 'Vyhledávač',
                            url: '/services/searcher/',
                        },
                        {
                            label: 'Detail leadu',
                            url: '',
                        },
                    ],
                },
            },
            {
                path: 'orders/:id',
                component: ServicesOrdersDetailComponent,
                data: {
                    title: 'Detail objednávky',
                    breadcrumb: [
                        {
                            label: 'Služby',
                            url: '/services/',
                        },
                        {
                            label: 'Vyhledávač',
                            url: '/services/searcher/',
                        },
                        {
                            label: 'Detail objednávky',
                            url: '',
                        },
                    ],
                },
            },
        ],
        canActivate: [AuthGuard],
    },
];

const shared = [
    CallwizardInternetComponent,
    CallwizardTelevisionComponent,
    CallwizardHardwareComponent,
    CallwizardAddressComponent,
];

@NgModule({
    declarations: [
        ...shared,
        AddressExcludeFormComponent,
        AddressUntrustedFormComponent,
        BonusFormComponent,
        CallwizardComponent,
        CheckRuianFormComponent,
        DealTypeFormComponent,
        DiscountFormComponent,
        ExtendedServiceFormComponent,
        FieldFormComponent,
        FlatUnitSpaceFormComponent,
        FlatUnitTypeFormComponent,
        HardwareFormComponent,
        InstallationTypeFormComponent,
        LeadFormComponent,
        NetPackageFormComponent,
        OrderFormComponent,
        PackagesCompareComponent,
        PlaceFormComponent,
        ProjectFormComponent,
        RuianFormComponent,
        SearcherComponent,
        ServiceAddressComponent,
        ServiceConnectedFieldComponent,
        ServiceConnectedRuianComponent,
        ServiceExcludedAddressComponent,
        ServiceFieldDetailComponent,
        ServiceHwConfigFormComponent,
        ServiceOutagesComponent,
        ServiceRuianDetailComponent,
        ServicesBonusesComponent,
        ServicesChannelsComponent,
        ServicesComponent,
        ServicesDealTypesComponent,
        ServicesDiscountsComponent,
        ServiceSearcherComponent,
        ServicesExtendedServicesComponent,
        ServicesFlatUnitsComponent,
        ServicesHardwareComponent,
        ServicesHwConfiguratorComponent,
        ServicesInstallationTypesComponent,
        ServicesLeadsComponent,
        ServicesLeadsDetailComponent,
        ServicesOrdersComponent,
        ServicesOrdersDetailComponent,
        ServicesPlacesComponent,
        ServicesSettingsComponent,
        ServicesSpeedTypesComponent,
        ServicesStatsComponent,
        ServicesTarifsComponent,
        ServicesTechnologyTypesComponent,
        ServicesTransferTypesComponent,
        ServicesTvPackagesComponent,
        ServiceUntrustedAddressComponent,
        SpeedTypeFormComponent,
        TechnologyTypeFormComponent,
        TransferTypeFormComponent,
        TvChannelFormComponent,
        TvPackageFormComponent,
        TvpackagesChannelsComponent,
        TvServiceFormComponent,
    ],
    imports: [
        RouterModule.forChild(routes),
        BrowserAnimationsModule,
        BrowserModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        TimeagoModule.forChild({
            intl: TimeagoIntl,
            formatter: {provide: TimeagoFormatter, useClass: TimeagoCustomFormatter},
        }),
        SharedFeaturesModule,
    ],
    exports: [...shared],
    providers: [ServicesService],
})
export class ServicesModule {}
