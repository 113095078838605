import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    HostListener,
    OnInit,
    ViewChild,
} from '@angular/core';
import {registerLocaleData} from '@angular/common';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {OrderItem} from '@src/app/_models/material/material-item';
import {MaterialBudget} from '@src/app/_models/material/material-budget';
import {User} from '@src/app/_models/user/user';
import {Observable, Observer} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthenticationService} from '@src/app/_services/authentication.service';
import {ExcelService} from '@src/app/_services/excel.service';
import {PermissionService} from '@src/app/_services/permission.service';
import {MessageService} from '@src/app/_services/message.service';
import {DataService} from '@src/app/_services/data.service';
import {saveAs} from 'file-saver';
import {MaterialFile} from '@src/app/_models/material/material-file';

import moment from 'moment';
import localeCs from '@angular/common/locales/cs';
import {ButtonPropsModel, DialogComponent} from '@syncfusion/ej2-angular-popups';
import {
    ColumnModel,
    DataResult,
    EditSettingsModel,
    ExcelExportProperties,
    FilterSettingsModel,
    GridComponent,
    GroupSettingsModel,
    PageSettingsModel,
    RowDataBoundEventArgs,
    SelectionSettingsModel,
    ToolbarItems,
} from '@syncfusion/ej2-angular-grids';
import {ChangeEventArgs, DropDownListComponent} from '@syncfusion/ej2-angular-dropdowns';
import {ClickEventArgs} from '@syncfusion/ej2-angular-navigations';
import {TextBoxComponent} from '@syncfusion/ej2-angular-inputs';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {ComponentCanDeactivate} from '@src/app/_guards/changes.guard';
import {MaterialService} from '@src/app/features/material/material.service';
import {OrderItemsGridItems} from '@src/app/features/services/services.service';

moment.updateLocale('cs', {workingWeekdays: [1, 2, 3, 4, 5]});
registerLocaleData(localeCs);

@UntilDestroy()
@Component({
    selector: 'app-material-items',
    templateUrl: './material-items.component.html',
    styleUrls: ['./material-items.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MaterialItemsComponent implements OnInit, ComponentCanDeactivate {
    // Grid
    currencyFormat: {[key: string]: string} = {format: '#,###.00\',- CZK\''};

    dateFormat: string;

    toolbar: ToolbarItems[] | object;

    data: object;

    fields: object = {text: 'label', value: 'value'};

    filterSettings: FilterSettingsModel;

    filterCheckboxSettings: FilterSettingsModel;

    selectionSettings: SelectionSettingsModel;

    editSettings: EditSettingsModel;

    groupOptions: GroupSettingsModel;

    pageOptions: PageSettingsModel;

    expendituresColumnsPrice: ColumnModel[];

    expendituresColumnsItems: ColumnModel[];

    filterDate: object;

    sortOptions: object;

    requiredRules: object;

    exportCurrentPage = false;

    // Dialogs
    buttonsDelete: ButtonPropsModel[] = [
        {
            click: (): void => {
                this.deleteItem();
            },
            isFlat: false,
            buttonModel: {
                content: 'OK',
                cssClass: 'e-success e-outline',
            },
        },
        {
            click: (): void => {
                this.confirmationDelete.hide();
            },
            buttonModel: {content: 'ZRUŠIT', cssClass: 'e-danger e-outline'},
        },
    ];

    buttonsCancel: ButtonPropsModel[] = [
        {
            click: (): void => {
                this.confirmationCancel.hide();
            },
            buttonModel: {
                isPrimary: true,
                content: 'OK',
            },
        },
    ];

    // Dropdowns
    categoryFilterData: Array<string> = [];

    // Forms
    itemForm: FormGroup;

    isDirty = false;

    isCreate = false;

    isUpdate = false;

    isChecked = false;

    submited = false;

    // Variables
    currentUser: User | null;

    items: OrderItem[] = [];

    budgets: MaterialBudget[] = [];

    clickedRow: OrderItem[];

    clickedRow$ = new Observable<OrderItem[]>((observer: Observer<OrderItem[]>) => {
        observer.next(this.clickedRow);
    });

    // Loaders
    tableItemLoad = true;

    // Grid
    @ViewChild('itemsGrid') gridItems: GridComponent;

    @ViewChild('searchtext') searchtextObj: TextBoxComponent;

    // Dialogs
    @ViewChild('formDialog') formDialogObj: DialogComponent;

    @ViewChild('confirmationDelete') confirmationDelete: DialogComponent;

    @ViewChild('confirmationCancel') confirmationCancel: DialogComponent;

    // Dropdowns
    @ViewChild('viewFilter') viewFilterInstance: DropDownListComponent;

    constructor(
        private readonly router: Router,
        private readonly route: ActivatedRoute,
        private readonly ref: ChangeDetectorRef,
        private readonly formBuilder: FormBuilder,
        private readonly authenticationService: AuthenticationService,
        private readonly excelService: ExcelService,
        private readonly permissionService: PermissionService,
        private readonly materialService: MaterialService,
        private readonly messages: MessageService,
        private readonly dataService: DataService,
    ) {
        this.currentUser = this.authenticationService.currentUserValue;
    }

    get f(): {[key: string]: AbstractControl} {
        return this.itemForm.controls;
    }

    @HostListener('window:beforeunload')
    canDeactivate(): Observable<boolean> | boolean {
        return !this.isDirty;
    }

    ngOnInit(): void {
        this.filterSettings = {
            type: 'Menu',
            showFilterBarStatus: true,
            ignoreAccent: true,
        };

        this.filterCheckboxSettings = {
            type: 'CheckBox',
            showFilterBarStatus: true,
            ignoreAccent: true,
        };

        this.filterSettings = {
            type: 'Excel',
            showFilterBarOperator: true,
            showFilterBarStatus: true,
            ignoreAccent: true,
        };

        this.selectionSettings = {
            persistSelection: true,
            type: 'Multiple',
            checkboxOnly: true,
        };

        this.toolbar = [
            {
                text: 'Občerstvit data',
                tooltipText: 'Občerstvit data',
                prefixIcon: 'e-refresh',
                id: 'customRefresh',
            },
            'Print',
            'ExcelExport',
            'Add',
            'Edit',
            {
                text: 'Smazat',
                tooltipText: 'Stornovat položku',
                prefixIcon: 'e-remove',
                id: 'delete',
            },
            {
                text: 'Kopírovat',
                tooltipText: 'Kopírovat položku',
                prefixIcon: 'e-copy',
                id: 'copy',
            },
            {
                text: 'Detail',
                tooltipText: 'Detail objednávky',
                prefixIcon: 'e-openfolder',
                id: 'detail',
            },
        ];

        this.categoryFilterData = [
            'všechny položky objednávek',
            'fronta položek objednávek',
            'schválené položky objednávek',
            'zamítnuté položky objednávek',
            'stornované položky objednávek',
        ];
        this.pageOptions = {pageSize: 50};
        this.dateFormat = 'dd.MM.yyyy HH:mm';
        this.filterDate = {type: 'Menu', params: {format: this.dateFormat}};
        this.groupOptions = {
            showGroupedColumn: true,
            disablePageWiseAggregates: false,
        };

        this.editSettings = {
            showConfirmDialog: false,
            showDeleteConfirmDialog: false,
            allowEditing: false,
            allowAdding: false,
            allowDeleting: false,
            allowEditOnDblClick: false,
            mode: 'Dialog',
        };
        this.requiredRules = {required: true};

        this.dataService.budgetSource.pipe(untilDestroyed(this))
            .subscribe(
                (budgets: MaterialBudget[]) => {
                    this.budgets = budgets;
                },
                error => {
                    console.error(error);
                },
            );

        this.itemForm = this.formBuilder.group({
            name: ['', Validators.compose([Validators.required])],
            amount: [1, Validators.compose([Validators.required, Validators.min(1)])],
            price: [0.0, Validators.compose([Validators.required, Validators.min(0.01)])],
            dph: [false],
            department_id: [null, Validators.compose([Validators.required])],
            category_id: [null, Validators.compose([Validators.required])],
        });

        this.itemForm.valueChanges.pipe(untilDestroyed(this))
            .subscribe(() => {
                this.isDirty = this.itemForm.dirty;
                this.ref.markForCheck();
            });

        this.dataService.setMaterialsCategoryDataSource();
        this.dataService.setMaterialsItemsDataSource();
    }

    loadData(): DataResult[] {
        this.gridItems.clearRowSelection();

        console.info('NEW DATA BOUND');

        const elements: OrderItemsGridItems[] = [];

        // eslint-disable-next-line complexity
        this.items.map((item: OrderItem) => {
            item.confirmed = false;
            item.declined = false;

            let decline = 0;
            let submit = 0;
            const reqSubmits = item.budget.approvers.length;

            item.budget.approvers.forEach((approver: User) => {
                item.order.confirmers.forEach((confirmer: User) => {
                    if (
                        approver.id === confirmer.id &&
                        confirmer.material_assignation_users?.confirmed_date
                    ) {
                        submit = submit + 1;
                    }

                    if (
                        approver.id === confirmer.id &&
                        confirmer.material_assignation_users?.declined_date
                    ) {
                        decline = decline + 1;
                    }
                });
            });

            if (submit > 0 && decline === 0 && submit === reqSubmits) {
                item.confirmed = true;
            }

            if (decline > 0) {
                item.declined = true;
            }

            if (item.confirmed && !item.deleted_date) {
                item.status = 'schváleno';
            }

            if (item.declined && !item.deleted_date) {
                item.status = 'zamítnuto';
            }

            if (item.deleted_date) {
                item.status = 'stornováno';
            }

            if (!item.declined && !item.confirmed && !item.deleted_date) {
                item.status = 've frontě';
            }

            elements.push({
                id: item.id,
                created_by: item.order.creator.id,
                creator_str: item.order.creator.fullname,
                creator_img: item.order.creator.personalphoto,
                name: item.name,
                amount: item.amount,
                price: item.dph ? item.price - item.price * 0.21 : item.price,
                dph: !!item.dph,
                category: item.category.name,
                status: item.status,
                budget: item.budget.name,
                department: item.budget.department.name,
                company: item.budget.department.company.name,
                material_id: item.material_id,
                to_storage: item.order.to_storage,
                created_date: new Date(item.created_date),
                updated_date: item.updated_date ? new Date(item.updated_date) : null,
                deleted_date: item.deleted_date ? new Date(item.deleted_date) : null,
            });
        });
        this.tableItemLoad = false;

        return elements as unknown as DataResult[];
    }

    onCreated(): void {
        this.disableToolBarBtn();
    }

    setInitialGridFiltering(): void {
        this.gridItems.clearFiltering();
        // this.gridItems.filterByColumn('created_by', 'equal', this.currentUser.id, 'and', false, true);
        // this.viewFilterInstance.value = 'moje žádanky - všechny';
        this.ref.markForCheck();
    }

    onLoad(): void {
        const wrapper1 = document.getElementById('scroll_wrapper1_items');
        const wrapper2 = document.getElementById('scroll_wrapper2_items');

        if (wrapper1 && wrapper2) {
            wrapper1.onscroll = (): void => {
                wrapper2.scrollLeft = wrapper1.scrollLeft;
            };

            wrapper2.onscroll = (): void => {
                wrapper1.scrollLeft = wrapper2.scrollLeft;
            };
        }

        this.dataService.materialItemSource.pipe(untilDestroyed(this))
            .subscribe(
                (items: OrderItem[]) => {
                    this.items = items;
                    this.data = this.gridItems.dataSource = this.loadData();
                    this.sortOptions = {
                        columns: [
                            {field: 'created_date', direction: 'Descending'},
                            {
                                field: 'id',
                                direction: 'Descending',
                            },
                        ],
                    };
                },
                error => {
                    console.error(error);
                    this.tableItemLoad = false;
                    this.ref.markForCheck();
                },
            );
    }

    search(): void {
        this.gridItems.search(this.searchtextObj.value);
    }

    onDataBound(): void {
        this.rowSelected();

        const width = document.getElementById('itemsGrid_content_table')?.offsetWidth;
        const parent1 = document.getElementById('scroll_div_items');
        const parent2 = document.getElementById('grid_parent_items');

        if (parent1 && parent2 && width) {
            parent1.style.width = `${width}px`;
            parent2.style.width = `${width}px`;
        }

        this.gridItems.autoFitColumns();
        this.ref.markForCheck();
    }

    rowDataBound(args: RowDataBoundEventArgs): void {
        const data = args.data as OrderItemsGridItems;

        if (args.row && data.deleted_date) {
            args.row.getElementsByClassName('e-gridchkbox')[0].classList.add('disablecheckbox');
            args.row
                .getElementsByClassName('e-checkbox-wrapper')[0]
                .classList.add('disablecheckbox');
            args.row.classList.add('e-disabled');
        }

        if (
            this.currentUser &&
            args.row &&
            data.created_by !== this.currentUser.id &&
            !this.permissionService.checkUserISMaterialAdmin(this.currentUser)
        ) {
            args.row.getElementsByClassName('e-gridchkbox')[0].classList.add('disablecheckbox');
            args.row
                .getElementsByClassName('e-checkbox-wrapper')[0]
                .classList.add('disablecheckbox');
        }

        this.ref.markForCheck();
    }

    onStatusFilterChange(e: ChangeEventArgs): void {
        if (e.value === 'všechny položky objednávek') {
            this.gridItems.clearFiltering();
        } else if (e.value === 'fronta položek objednávek') {
            this.gridItems.filterSettings = {
                columns: [
                    {
                        field: 'status',
                        operator: 'equal',
                        value: 've frontě',
                        predicate: 'and',
                        matchCase: false,
                        ignoreAccent: true,
                    },
                    {
                        field: 'deleted_date',
                        operator: 'equal',
                        value: null as unknown as string,
                        predicate: 'and',
                        matchCase: false,
                        ignoreAccent: true,
                    },
                ],
            };
        } else if (e.value === 'schválené položky objednávek') {
            this.gridItems.filterSettings = {
                columns: [
                    {
                        field: 'status',
                        operator: 'equal',
                        value: 'schváleno',
                        predicate: 'and',
                        matchCase: false,
                        ignoreAccent: true,
                    },
                    {
                        field: 'deleted_date',
                        operator: 'equal',
                        value: null as unknown as string,
                        predicate: 'and',
                        matchCase: false,
                        ignoreAccent: true,
                    },
                ],
            };
        } else if (e.value === 'zamítnuté položky objednávek') {
            this.gridItems.filterSettings = {
                columns: [
                    {
                        field: 'status',
                        operator: 'equal',
                        value: 'zamítnuto',
                        predicate: 'and',
                        matchCase: false,
                        ignoreAccent: true,
                    },
                    {
                        field: 'deleted_date',
                        operator: 'equal',
                        value: null as unknown as string,
                        predicate: 'and',
                        matchCase: false,
                        ignoreAccent: true,
                    },
                ],
            };
        } else if (e.value === 'stornované položky objednávek') {
            this.gridItems.filterSettings = {
                columns: [
                    {
                        field: 'status',
                        operator: 'equal',
                        value: 'stornováno',
                        predicate: 'and',
                        matchCase: false,
                        ignoreAccent: true,
                    },
                    {
                        field: 'deleted_date',
                        operator: 'notequal',
                        value: null as unknown as string,
                        predicate: 'and',
                        matchCase: false,
                        ignoreAccent: true,
                    },
                ],
            };
        }
    }

    cancelBtnClick(): void {
        this.searchtextObj.value = '';
        this.gridItems.searchSettings.key = '';
    }

    toolbarClick(args: ClickEventArgs): void {
        const xlsProp: ExcelExportProperties = {
            fileName: 'js_invoices_items_export.xlsx',
            enableFilter: true,
            exportType: this.exportCurrentPage ? 'CurrentPage' : 'AllPages',
        };

        if (args.item.id === 'itemsGrid_excelexport') {
            void this.gridItems.excelExport(xlsProp);
        } else if (args.item.id === 'delete') {
            if (this.gridItems.getSelectedRecords().length !== 0) {
                this.confirmationDelete.content = `Potvrďte smazání ${this.clickedRow.length} položek`;
                this.confirmationDelete.show();
            } else {
                this.confirmationCancel.content = 'Musíte vybrat alespoň jednu položku';
                this.confirmationCancel.show();
            }
        } else if (args.item.id === 'copy') {
            if (this.gridItems.getSelectedRecords().length !== 0) {
                this.copyItem();
            } else {
                this.confirmationCancel.content = 'Musíte vybrat alespoň jednu položku';
                this.confirmationCancel.show();
            }
        } else if (args.item.id === 'detail') {
            void this.router.navigate(['/material/detail', this.clickedRow[0].material_id]);
        } else if (args.item.id === 'customRefresh') {
            this.tableItemLoad = true;
            console.info('refresh DATA!');
            this.ref.markForCheck();
            this.dataService.clearMaterialsItemsCache();
            this.dataService.setMaterialsItemsDataSource();
        }
    }

    enableToolbarBtn(): void {
        this.gridItems.toolbarModule.enableItems(['detail'], true);
    }

    disableToolBarBtn(): void {
        this.gridItems.toolbarModule.enableItems(
            ['itemsGrid_edit', 'delete', 'copy', 'detail'],
            false,
        );
    }

    rowSelected(): void {
        this.clickedRow = [];

        const selectedrecords: OrderItem[] = this.gridItems.getSelectedRecords() as OrderItem[];

        if (selectedrecords.length > 0) {
            selectedrecords.map((row: OrderItem) => {
                const tempData: OrderItem | undefined = this.items.find(x => x.id === row.id);

                if (tempData) {
                    this.clickedRow.push(tempData);
                }

                this.ref.markForCheck();
            });

            this.clickedRow$ = new Observable<OrderItem[]>((observer: Observer<OrderItem[]>) => {
                observer.next(this.clickedRow);
            });
            this.enableToolbarBtn();
        } else {
            this.disableToolBarBtn();
            this.clickedRow$ = new Observable<OrderItem[]>((observer: Observer<OrderItem[]>) => {
                observer.next(this.clickedRow);
            });
        }
    }

    fillTheForm(): void {
        this.itemForm.controls.name.patchValue(this.clickedRow[0].name);
        this.itemForm.controls.amount.patchValue(this.clickedRow[0].amount);
        this.itemForm.controls.price.patchValue(this.clickedRow[0].price);
        this.itemForm.controls.dph.patchValue(this.clickedRow[0].dph);
        this.itemForm.controls.department_id.patchValue(this.clickedRow[0].department_id);
        this.itemForm.controls.category_id.patchValue(this.clickedRow[0].category_id);
    }

    actionBegin(args): void {
        if (args.requestType === 'add' || args.requestType === 'beginEdit') {
            args.cancel = true;
            this.submited = false;

            if (args.requestType === 'beginEdit') {
                this.isUpdate = true;
                this.isCreate = false;
                this.formDialogObj.header = `Objednávka #${this.clickedRow[0].id}`;
                this.fillTheForm();
                this.showInputErrors();
            }

            if (args.requestType === 'add') {
                this.isCreate = true;
                this.isUpdate = false;
                this.itemForm.reset();
                this.formDialogObj.header = 'Nová objednávka';
            }

            this.ref.markForCheck();
            this.formDialogObj.show();
        }
    }

    actionComplete(args): void {
        if (args.requestType === 'beginEdit' || args.requestType === 'add') {
            args.form.ej2_instances[0].rules = {};
        }
    }

    onSubmit(): void {
        this.submited = true;
        this.isDirty = false;
        this.tableItemLoad = true;

        if (this.isCreate) {
            this.createItem();
        }

        if (this.isUpdate) {
            this.editItem();
        }
    }

    createItem(): void {
        // TODO
    }

    editItem(): void {
        // TODO
    }

    deleteItem(): void {
        this.tableItemLoad = true;
        this.materialService
            .deleteItemInvoice(this.clickedRow[0])
            .pipe(untilDestroyed(this))
            .subscribe(
                (data: OrderItem) => {
                    const body = `Položka objednávky #${data.id}`;
                    const options = {progressBar: true, timeOut: 5000};

                    this.messages.showSuccess('Položka objednávky úspěšně smazána', body, options);
                    this.tableItemLoad = false;
                    this.dataService.setMaterialsItemsDataSource();
                    this.confirmationDelete.hide();
                    this.ref.markForCheck();
                },
                error => {
                    console.error(error);
                    this.tableItemLoad = false;
                    this.confirmationDelete.hide();
                    this.ref.markForCheck();
                },
            );
    }

    copyItem(): void {
        // TODO
    }

    downloadFile(file: MaterialFile): void {
        this.materialService
            .downloadFile(file)
            .pipe(untilDestroyed(this))
            .subscribe(
                data => {
                    saveAs(data, file.name);
                },
                err => {
                    alert('Chyba během stahování souboru.');
                    console.error(err);
                },
            );
    }

    showInputErrors(): void {
        this.isChecked = true;
        this.itemForm.markAllAsTouched();
        this.ref.markForCheck();
    }
}
