import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    HostListener,
    OnInit,
    ViewChild,
} from '@angular/core';
import {AbstractControl, FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthenticationService} from '@src/app/_services/authentication.service';
import {MessageService} from '@src/app/_services/message.service';
import {OrderItem} from '@src/app/_models/material/material-item';
import {Material} from '@src/app/_models/material/material';
import {Department} from '@src/app/_models/department/department';
import {MaterialBudget} from '@src/app/_models/material/material-budget';
import {MaterialPayment} from '@src/app/_models/material/material-payment';
import {User} from '@src/app/_models/user/user';
import {Observable} from 'rxjs';
import {DataService} from '@src/app/_services/data.service';
import {ComponentCanDeactivate} from '@src/app/_guards/changes.guard';
import {MaterialCategory} from '@src/app/_models/material/material-category';

import moment from 'moment';
import localeCs from '@angular/common/locales/cs';
import {registerLocaleData} from '@angular/common';
import {NumericTextBoxComponent} from '@syncfusion/ej2-angular-inputs';
import {DropDownListComponent, FilteringEventArgs} from '@syncfusion/ej2-angular-dropdowns';
import {EmitType} from '@syncfusion/ej2-base';
import {Query} from '@syncfusion/ej2-data';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {MaterialService, OrderInput} from '@src/app/features/material/material.service';
import {SettingsService} from '@src/app/features/settings/settings.service';

moment.updateLocale('cs', {workingWeekdays: [1, 2, 3, 4, 5]});
registerLocaleData(localeCs);

interface BudgetSmmary {
    id: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    created_by: number;
    creator: User;
    name: string;
    amount: number;
    expenditures: {price: number; items: number};
    // eslint-disable-next-line @typescript-eslint/naming-convention
    expenditures_current: {price: number; items: number};
    // eslint-disable-next-line @typescript-eslint/naming-convention
    start_at: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    expire_at: string;
    department: Department;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    created_date: string;
    approvers: User[];
    budgetitems: OrderItem[];
}

@UntilDestroy()
@Component({
    selector: 'app-material-create',
    templateUrl: './material-create.component.html',
    styleUrls: ['./material-create.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MaterialCreateComponent implements OnInit, ComponentCanDeactivate {
    // Dropdowms
    height = '240px';

    fields: object = {text: 'label', value: 'value'};

    paymentSelect: Array<{value: boolean | number | string; label: string}> = [];

    departmentSelect: Array<{value: boolean | number | string; label: string}> = [];

    categorySelect: Array<{value: boolean | number | string; label: string}> = [];

    createMaterialForm: FormGroup;

    orderItemsList: FormArray;

    currentUser: User | null;

    hintText = '';

    departmentId: number;

    loadingMaterial = false;

    isDirty = false;

    emptyItems = false;

    pieceMode = false;

    budget?: MaterialBudget;

    order: OrderItem;

    orderItems: OrderItem[];

    budgets: MaterialBudget[];

    budgetsSummary: BudgetSmmary[] = [];

    payments: MaterialPayment[];

    @ViewChild('PriceInput') priceInputObj: NumericTextBoxComponent;

    @ViewChild('PieceInput') pieceInputObj: NumericTextBoxComponent;

    @ViewChild('hintArea') hintArea: HTMLElement;

    // Dropdowms
    @ViewChild('payment') paymentObj: DropDownListComponent;

    @ViewChild('department') departmentObj: DropDownListComponent;

    @ViewChild('category') categoryObj: DropDownListComponent;

    constructor(
        private readonly formBuilder: FormBuilder,
        private readonly route: ActivatedRoute,
        private readonly router: Router,
        private readonly authenticationService: AuthenticationService,
        private readonly materialService: MaterialService,
        private readonly dataService: DataService,
        private readonly settingsService: SettingsService,
        private readonly messages: MessageService,
        private readonly ref: ChangeDetectorRef,
    ) {
        this.currentUser = this.authenticationService.currentUserValue;
    }

    get orderItemsFormGroup(): FormArray {
        return this.createMaterialForm.get('orderItems') as FormArray;
    }

    get f(): {[key: string]: AbstractControl} {
        return this.createMaterialForm.controls;
    }

    @HostListener('window:beforeunload')
    canDeactivate(): Observable<boolean> | boolean {
        return !this.isDirty;
    }

    ngOnInit(): void {
        this.dataService.departmentSource.pipe(untilDestroyed(this))
            .subscribe(
                (data: Department[]) => {
                    this.departmentSelect = [];
                    data.map((department: Department) => {
                        if (!department.deleted_date && department.budgets.length > 0) {
                            this.departmentSelect = [
                                ...this.departmentSelect,
                                {
                                    value: department.id,
                                    label: department.company.name + ' - ' + department.name,
                                },
                            ];
                        }
                    });
                    this.ref.markForCheck();
                },
                error => {
                    console.error(error);
                },
            );

        this.dataService.materialCategorySource.pipe(untilDestroyed(this))
            .subscribe(
                (data: MaterialCategory[]) => {
                    this.categorySelect = [];
                    data.map((category: MaterialCategory) => {
                        if (!category.deleted_date) {
                            this.categorySelect = [
                                ...this.categorySelect,
                                {
                                    value: category.id,
                                    label: category.name,
                                },
                            ];
                        }
                    });
                    this.ref.markForCheck();
                },
                error => {
                    console.error(error);
                },
            );

        this.materialService
            .getPayments()
            .pipe(untilDestroyed(this))
            .subscribe(
                (payments: MaterialPayment[]) => {
                    this.payments = payments;
                    this.paymentSelect = [];
                    payments.map((payment: MaterialPayment) => {
                        if (!payment.deleted_date) {
                            this.paymentSelect = [
                                ...this.paymentSelect,
                                {value: payment.id, label: payment.name},
                            ];
                        }
                    });
                    this.ref.markForCheck();
                },
                error => {
                    console.error(error);
                },
            );

        this.dataService.budgetSource.pipe(untilDestroyed(this))
            .subscribe(
                (budgets: MaterialBudget[]) => {
                    this.budgets = budgets;
                    this.ref.markForCheck();
                },
                error => {
                    console.error(error);
                },
            );

        this.createMaterialForm = this.formBuilder.group({
            orderItems: this.formBuilder.array([this.createOrder()]),
            reason: ['', Validators.required],
            note: [''],
            payment: ['', Validators.required],
            files: [null],
            to_storage: ['to_storage', Validators.required],
            receipt: [null],
        });

        this.createMaterialForm.valueChanges.pipe(untilDestroyed(this))
            .subscribe(() => {
                this.isDirty = this.createMaterialForm.dirty;
                this.ref.markForCheck();
            });

        (this.createMaterialForm.get('orderItems') as FormArray).valueChanges
            .pipe(untilDestroyed(this))
            .subscribe((items: OrderItem[]) => {
                items.forEach((item, index) => {
                    if (item.department_id) {
                        this.budget = this.setBudget(item.department_id);

                        if (this.budget) {
                            // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
                            item.budget = {
                                id: this.budget.id,
                                created_by: this.budget.creator.id,
                                creator: this.budget.creator,
                                name: this.budget.name,
                                amount: this.budget.amount,
                                start_at: this.budget.start_at,
                                expire_at: this.budget.expire_at,
                                department: this.budget.department,
                                created_date: this.budget.created_date,
                                approvers: this.budget.approvers,
                                budgetitems: this.budget.budgetitems,
                            } as MaterialBudget;
                            items[index] = item;
                        }
                    }
                });
                this.recalcSummary();
            });
        this.orderItemsList = this.createMaterialForm.get('orderItems') as FormArray;

        this.dataService.setDepartmentDataSource();
        this.dataService.setBudgetsDataSource();
        this.dataService.setMaterialsCategoryDataSource();
    }

    createOrder(): FormGroup {
        return this.formBuilder.group({
            name: ['', Validators.compose([Validators.required])],
            amount: [1, Validators.compose([Validators.required, Validators.min(1)])],
            price: [0.0, Validators.compose([Validators.required, Validators.min(0.01)])],
            dph: [false],
            department_id: [null, Validators.compose([Validators.required])],
            category_id: [null, Validators.compose([Validators.required])],
        });
    }

    getOrderItemsFormGroup(index): FormGroup {
        return this.orderItemsList.controls[index] as FormGroup;
    }

    addItem(): void {
        this.orderItemsList.push(this.createOrder());
        this.emptyItems = false;
        this.ref.markForCheck();
    }

    removeItem(index: number): void {
        this.orderItemsList.removeAt(index);

        if (this.orderItemsList.value.length === 0) {
            const body = 'Rozpočet nepřiřazen k objednávce';
            const options = {progressBar: true, timeOut: 5000, toastClass: 'red'};

            this.messages.showError('Musíte zadat položky objednávky', body, options);
            this.emptyItems = true;
            this.ref.markForCheck();
        }
    }

    onFilteringCategory: EmitType<FilteringEventArgs> = (e: FilteringEventArgs) => {
        if (e.text === '') {
            e.updateData(this.categorySelect);
        } else {
            let query: Query = new Query();

            query = e.text !== '' ? query.where('label', 'contains', e.text, true, true) : query;
            e.updateData(this.categorySelect, query);
        }
    };

    onFilteringPayments: EmitType<FilteringEventArgs> = (e: FilteringEventArgs) => {
        if (e.text === '') {
            e.updateData(this.paymentSelect);
        } else {
            let query: Query = new Query();

            query = e.text !== '' ? query.where('label', 'contains', e.text, true, true) : query;
            e.updateData(this.paymentSelect, query);
        }
    };

    onFilteringDepartments: EmitType<FilteringEventArgs> = (e: FilteringEventArgs) => {
        if (e.text === '') {
            e.updateData(this.departmentSelect);
        } else {
            let query: Query = new Query();

            query = e.text !== '' ? query.where('label', 'contains', e.text, true, true) : query;
            e.updateData(this.departmentSelect, query);
        }
    };

    onSubmit(): void {
        if (this.createMaterialForm.invalid) {
            console.error('form is not valid!');

            return;
        }

        this.isDirty = false;

        const confirmers: User[] = [];

        this.loadingMaterial = true;
        this.orderItems = this.orderItemsList.value;
        this.orderItems.forEach((item: OrderItem, index) => {
            this.orderItems[index].budget_id = item.budget.id;
            item.budget.approvers.forEach((approver: User) => {
                if (!confirmers.find(x => x.id === approver.id)) {
                    confirmers.push(approver);
                }
            });
        });

        const order: OrderInput = {
            reason: this.f.reason.value,
            to_storage: this.f.to_storage.value === 'to_storage',
            payment_id: this.f.payment.value,
            note: this.f.note.value,
            orderitems: this.orderItems,
            created_by: this.currentUser?.id,
            confirmers,
        };

        this.materialService
            .addInvoice(order)
            .pipe(untilDestroyed(this))
            .subscribe(
                (data: Material) => {
                    const body = 'Objednávka zařazena do fronty ke schválení...';
                    const options = {progressBar: true, timeOut: 5000};

                    this.messages.showSuccess(
                        'Objednávka materiálu úspěšně vytvořena',
                        body,
                        options,
                    );
                    this.loadingMaterial = false;
                    this.ref.markForCheck();
                    void this.router.navigate(['/material/detail', data.id]);
                },
                error => {
                    console.error(error);

                    const body = 'Zkuste to znovu později';
                    const options = {progressBar: true, timeOut: 5000};

                    this.messages.showError(
                        'Chyba při vytváření objednávky materiálu,',
                        body,
                        options,
                    );
                    this.loadingMaterial = false;
                    this.ref.markForCheck();
                },
            );
    }

    showInputErrors(): void {
        this.createMaterialForm.markAllAsTouched();
        this.ref.markForCheck();
    }

    setBudget(department: Department | number): MaterialBudget | undefined {
        const departmentId = typeof department === 'number' ? department : department.id;
        const matchedBudget = this.budgets.find(
            budget =>
                !budget.deleted_date &&
                budget.department_id === departmentId &&
                moment()
                    .format('YYYY-MM-DD HH:mm:ss') >= budget.start_at &&
                moment()
                    .format('YYYY-MM-DD HH:mm:ss') < budget.expire_at,
        );

        if (!matchedBudget) {
            const body = 'Rozpočet nedohledán';
            const options = {progressBar: true, timeOut: 5000, toastClass: 'red'};

            this.messages.showError('Chyba při hledání rozpočtu...', body, options);
        } else {
            return matchedBudget;
        }

        this.ref.markForCheck();
    }

    piecePrice(
        event: HTMLInputElement,
        pieceControl: AbstractControl,
        priceControl: AbstractControl,
    ): void {
        if (event.checked) {
            this.pieceMode = true;
            priceControl.patchValue(priceControl.value * pieceControl.value);
            pieceControl.disable({onlySelf: true});
            priceControl.disable({onlySelf: true});
        } else {
            this.pieceMode = false;
            priceControl.patchValue(priceControl.value / pieceControl.value);
            pieceControl.enable({onlySelf: true});
            priceControl.enable({onlySelf: true});
        }
    }

    recalcSummary(): void {
        this.budgetsSummary = [];

        const items = this.orderItemsList.value;

        items.forEach((item: OrderItem) => {
            let cashBudgetTotal = 0;

            if (!this.budgetsSummary.find(t => t.id === item.budget.id)) {
                item.budget.totalCountItem = item.budget.budgetitems.filter(
                    (orderItem: OrderItem) => orderItem.order.to_storage,
                ).length;
                item.budget.startUnix = moment(item.budget.start_at)
                    .valueOf();
                item.budget.endUnix = moment(item.budget.expire_at)
                    .valueOf();
                item.budget.budgetitems.forEach((data: OrderItem) => {
                    if (data.order.to_storage) {
                        cashBudgetTotal += data.dph ? data.price - data.price * 0.21 : data.price;
                    }
                });
                item.budget.totalCountPrice = cashBudgetTotal;
                this.budgetsSummary.push({
                    id: item.budget.id,
                    created_by: item.budget.creator.id,
                    creator: item.budget.creator,
                    name: item.budget.name,
                    amount: item.budget.amount,
                    expenditures: {
                        items: item.budget.totalCountItem,
                        price: item.budget.totalCountPrice,
                    },
                    expenditures_current: {
                        price:
                            item.dph && item.price
                                ? item.price - item.price * 0.21
                                : item.price
                                    ? item.price
                                    : 0.0,
                        items: 1,
                    },
                    start_at: item.budget.start_at,
                    expire_at: item.budget.expire_at,
                    department: item.budget.department,
                    created_date: item.budget.created_date,
                    approvers: item.budget.approvers,
                    budgetitems: item.budget.budgetitems,
                });
                this.ref.markForCheck();
            } else {
                const temBudgetSum = this.budgetsSummary.find(t => t.id === item.budget.id);

                if (temBudgetSum) {
                    temBudgetSum.expenditures_current.price += item.dph
                        ? item.price - item.price * 0.21
                        : item.price;

                    temBudgetSum.expenditures_current.items += 1;
                }

                this.ref.markForCheck();
            }
        });
        this.ref.markForCheck();
    }

    showHint(hint: string): void {
        this.hintText = hint;
        this.ref.markForCheck();
    }
}
