<ejs-toolbar
  #moduleToolbar
  id="moduleToolbar"
  overflowMode="Scrollable"
  scrollStep="300"
>
  <e-items>
    <e-item
      [align]="'center'"
      tooltipText="přidat nového úživatele"
      type="button"
    >
      <ng-template #template>
        <button
          (click)="navigateToTab('grid', 'addUser')"
          *ngIf="
                        this.currentUser.rule &&
                        (this.currentUser.rule.is_administrative ||
                            this.currentUser.rule.is_admin)
                    "
          class="e-btn e-primary btn-p-zero e-small"
          ejs-button
          title="přidat nového úživatele"
          type="button"
        >
          <i class="fa-light fa-lg fa-plus mr-2"></i>přidat nového
          úživatele
        </button>
      </ng-template>
    </e-item>
    <e-item type="Separator"></e-item>
    <e-item
      [align]="'center'"
      tooltipText="upravit uživatele"
      type="button"
    >
      <ng-template #template>
        <button
          (click)="navigateToTab('grid', null)"
          class="e-btn e-warning btn-p-zero e-small"
          ejs-button
          title="seznam uživatelů"
          type="button"
        >
          <i class="fa-light fa-lg fa-list mr-2"></i>seznam uživatelů
        </button>
      </ng-template>
    </e-item>
    <e-item type="Separator"></e-item>
    <e-item
      [align]="'center'"
      tooltipText="re-generovat HTML podpisy"
      type="button"
    >
      <ng-template #template>
        <button
          (click)="recreateHtmlSignatures()"
          *ngIf="
                        this.currentUser.rule &&
                        (this.currentUser.rule.is_administrative ||
                            this.currentUser.rule.is_admin)
                    "
          class="e-btn e-info btn-p-zero e-small"
          ejs-button
          title="re-generovat HTML podpisy"
          type="button"
        >
          <i
            aria-hidden="true"
            class="fa-light fa-id-card-alt mr-1"
          ></i>
          re-generovat HTML podpisy
        </button>
      </ng-template>
    </e-item>
    <e-item type="Separator"></e-item>
    <e-item
      [align]="'center'"
      tooltipText="exportovat data do přenositelného formátu"
      type="button"
    >
      <ng-template #template>
        <button
          (click)="getCalendarXMLExport()"
          cssClass="e-btn e-info e-outline btn-p-zero e-small"
          ejs-button
          title="exportovat data do přenositelného formátu"
        >
          <i aria-hidden="true" class="fa-light fa-file mr-lg-1"></i>
          <span class="d-none d-lg-inline">XML export</span>
        </button>
      </ng-template>
    </e-item>
    <e-item type="Separator"></e-item>
    <e-item
      [align]="'center'"
      tooltipText="exportovat data do přenositelného formátu"
      type="button"
    >
      <ng-template #template>
        <button
          (click)="getCalendarCSVExport()"
          cssClass="e-btn e-info e-outline btn-p-zero e-small"
          ejs-button
          title="exportovat data do přenositelného formátu"
        >
          <i aria-hidden="true" class="fa-light fa-file mr-lg-1"></i>
          <span class="d-none d-lg-inline">CSV export</span>
        </button>
      </ng-template>
    </e-item>
  </e-items>
</ejs-toolbar>
<ejs-tab
  #userTab
  (created)="onCreate()"
  (selected)="select($event)"
  (selecting)="preventSwipe($event)"
  enablePersistence="true"
  height="auto"
  heightAdjustMode="Content"
  id="userTab"
>
  <e-tabitems>
    <e-tabitem [header]="headerText[0]">
      <ng-template #content>
        <app-users-list></app-users-list>
      </ng-template>
    </e-tabitem>
    <e-tabitem [disabled]="true" [header]="headerText[1]">
      <ng-template #content>
        <div class="control-section">
          <!--    <div style="padding:5px;text-align: right;">
                  <button ejs-button id="add" class="e-btn e-info" (click)="addPanel()">Add Panel</button>
              </div>-->
          <div
            class="mb-3"
            id="dashBoardParent"
            style="width: 100% !important"
          >
            <ejs-dashboardlayout
              #default_dashboard
              [allowDragging]="true"
              [allowResizing]="true"
              [cellAspectRatio]="cellAspectRatio"
              [cellSpacing]="cellSpacing"
              [enablePersistence]="false"
              columns="4"
              id="vacReports_dashboard"
            >
              <e-panels>
                <e-panel
                  [col]="0"
                  [row]="0"
                  [sizeX]="4"
                  [sizeY]="1"
                >
                  <ng-template #header>
                    <span>Statistiky ticketů</span>
                  </ng-template>
                  <ng-template #content>
                    <i
                      (click)="onCloseIconHandler($event)"
                      aria-hidden="true"
                      class="fa-light fa-lg fa-time"
                    ></i>
                    <div
                      class="e-panel-container scrollbar scrollbar-indigo thin p-2"
                    ></div>
                  </ng-template>
                </e-panel>
                <e-panel
                  [col]="0"
                  [row]="1"
                  [sizeX]="2"
                  [sizeY]="1"
                >
                  <ng-template #content>
                    <i
                      (click)="onCloseIconHandler($event)"
                      aria-hidden="true"
                      class="fa-light fa-lg fa-time"
                    ></i>
                    <div
                      class="e-panel-container scrollbar scrollbar-indigo thin p-2"
                    ></div>
                  </ng-template>
                </e-panel>
                <e-panel
                  [col]="2"
                  [row]="1"
                  [sizeX]="2"
                  [sizeY]="1"
                >
                  <ng-template #content>
                    <i
                      (click)="onCloseIconHandler($event)"
                      aria-hidden="true"
                      class="fa-light fa-lg fa-time"
                    ></i>
                    <div
                      class="e-panel-container scrollbar scrollbar-indigo thin p-2"
                    ></div>
                  </ng-template>
                </e-panel>
                <e-panel
                  [col]="0"
                  [row]="2"
                  [sizeX]="4"
                  [sizeY]="1"
                >
                  <ng-template #header>
                    <div>Přehled</div>
                  </ng-template>
                  <ng-template #content>
                    <i
                      (click)="onCloseIconHandler($event)"
                      aria-hidden="true"
                      class="fa-light fa-lg fa-time"
                    ></i>
                    <div
                      class="e-panel-container scrollbar scrollbar-indigo thin p-2"
                    ></div>
                  </ng-template>
                </e-panel>
              </e-panels>
            </ejs-dashboardlayout>
          </div>
        </div>
      </ng-template>
    </e-tabitem>
    <e-tabitem [disabled]="true" [header]="headerText[2]">
      <ng-template #content></ng-template>
    </e-tabitem>
  </e-tabitems>
</ejs-tab>
