import {Injectable} from '@angular/core';
import {Tickets} from '@src/app/_models/ticket/tickets';
import {User} from '@src/app/_models/user/user';
import {Task} from '@src/app/_models/task/task';
import {Vacation} from '@src/app/_models/vacation/vacation';
import {Department} from '@src/app/_models/department/department';
import {Assignation} from '@src/app/_models/assignation/assignation';
import {AuthenticationService} from '@src/app/_services/authentication.service';
import {Hotline} from '@src/app/_models/hotline/hotline';
import {Knowledge} from '@src/app/_models/knowledge/knowledge';
import {Event} from '@src/app/_models/event/event';
import {Material} from '@src/app/_models/material/material';
import {Hotliner} from '@src/app/_models/hotline/hotliner';
import {MaterialBudget} from '@src/app/_models/material/material-budget';

@Injectable({
    providedIn: 'root',
})
export class PermissionService {
    currentUser: User | null;

    constructor(private readonly authenticationService: AuthenticationService) {}

    viewTicket(ticket: Tickets): boolean {
        let state = false;

        if (
            ticket.created_by === this.authenticationService.currentUserValue?.id ||
            ticket.creator.boss_id === this.authenticationService.currentUserValue?.id ||
            this.authenticationService.currentUserValue?.rule &&
            (this.authenticationService.currentUserValue.rule.is_ticket_admin ||
                this.authenticationService.currentUserValue.rule.is_admin)
        ) {
            state = true;
        } else {

            if (
                this.authenticationService.currentUserValue &&
                ticket.category_id === 7 &&
                (
                    this.checkUserAssignation(47, this.authenticationService.currentUserValue) ||
                    this.checkUserAssignation(48, this.authenticationService.currentUserValue)
                )
            ) {
                state = true;
            }

            ticket.users.forEach((data: User) => {
                if (data.id === this.authenticationService.currentUserValue?.id) {
                    state = true;
                }
            });

            ticket.departments.forEach((data: Department) => {
                this.authenticationService.currentUserValue?.assignations?.forEach(
                    (assignation: Assignation) => {
                        if (data.id === assignation.department_id) {
                            state = true;
                        }
                    },
                );
            });
        }

        return state;
    }

    assignationTicket(ticket: Tickets): boolean {
        let state = false;

        if (ticket.created_by === this.authenticationService.currentUserValue?.id) {
            state = true;
        } else {
            ticket.users.forEach((data: User) => {
                if (data.id === this.authenticationService.currentUserValue?.id) {
                    state = true;
                }
            });

            ticket.departments.forEach((data: Department) => {
                this.authenticationService.currentUserValue?.assignations?.forEach(
                    (assignation: Assignation) => {
                        if (data.id === assignation.department_id) {
                            state = true;
                        }
                    },
                );
            });
        }

        return state;
    }

    viewTask(task: Task, ticket?: Tickets): boolean | null | undefined {
        const inhertedFromTicket = Boolean(
            task.ticket_id && ticket && this.assignationTicket(ticket),
        );

        return (
            inhertedFromTicket ||
            task.created_by === this.authenticationService.currentUserValue?.id ||
            task.user_id === this.authenticationService.currentUserValue?.id ||
            this.authenticationService.currentUserValue?.rule &&
            (this.authenticationService.currentUserValue.rule.is_ticket_admin ||
                this.authenticationService.currentUserValue.rule.is_admin)
        );
    }

    assignationTask(task: Task): boolean {
        return (
            task.created_by === this.authenticationService.currentUserValue?.id ||
            task.user_id === this.authenticationService.currentUserValue?.id
        );
    }

    viewVacation(): boolean {
        return true;
    }

    assignationVacation(vacation: Vacation): boolean {
        return vacation.created_by === this.authenticationService.currentUserValue?.id;
    }

    viewMaterial(material: Material): boolean {
        return !!(
            material.amiboss ||
            material.amiviewer ||
            material.created_by === this.authenticationService.currentUserValue?.id ||
            this.authenticationService.currentUserValue?.rule &&
            (this.authenticationService.currentUserValue.rule.is_budget_admin ||
                this.authenticationService.currentUserValue.rule.is_admin)
        );
    }

    viewBudget(budget: MaterialBudget): boolean {
        return !!(
            budget.amiboss ||
            budget.amiviewer ||
            this.authenticationService.currentUserValue?.rule &&
            (this.authenticationService.currentUserValue.rule.is_budget_admin ||
                this.authenticationService.currentUserValue.rule.is_admin)
        );
    }

    assignationMaterial(material: Material): boolean {
        let bossCheck: boolean;

        if (material.confirmers.length > 0) {
            bossCheck = !!material.confirmers.find(
                x => x.id === this.authenticationService.currentUserValue?.id,
            );
        } else {
            bossCheck = false;
        }

        return bossCheck || material.created_by === this.authenticationService.currentUserValue?.id;
    }

    viewKnowledge(knowledge: Knowledge): boolean {
        let state = false;

        if (
            knowledge.created_by === this.authenticationService.currentUserValue?.id ||
            this.authenticationService.currentUserValue?.rule &&
            (this.authenticationService.currentUserValue.rule.is_knowledge_admin ||
                this.authenticationService.currentUserValue.rule.is_admin)
        ) {
            state = true;
        } else {
            if (knowledge.users.length > 0 || knowledge.departments.length > 0) {
                knowledge.users.forEach((data: User) => {
                    if (data.id === this.authenticationService.currentUserValue?.id) {
                        state = true;
                    }
                });

                knowledge.departments.forEach((data: Department) => {
                    this.authenticationService.currentUserValue?.assignations?.forEach(
                        (assignation: Assignation) => {
                            if (data.id === assignation.department_id) {
                                state = true;
                            }
                        },
                    );
                });
            }

            if (knowledge.users.length === 0 && knowledge.departments.length === 0) {
                state = true;
            }
        }

        return state;
    }

    assignationKnowledge(knowledge: Knowledge): boolean {
        let state = false;

        if (knowledge.created_by === this.authenticationService.currentUserValue?.id) {
            state = true;
        } else {
            if (knowledge.users.length > 0 || knowledge.departments.length > 0) {
                knowledge.users.forEach((data: User) => {
                    if (data.id === this.authenticationService.currentUserValue?.id) {
                        state = true;
                    }
                });

                knowledge.departments.forEach((data: Department) => {
                    this.authenticationService.currentUserValue?.assignations?.forEach(
                        (assignation: Assignation) => {
                            if (data.id === assignation.department_id) {
                                state = true;
                            }
                        },
                    );
                });
            }

            if (knowledge.users.length === 0 && knowledge.departments.length === 0) {
                state = true;
            }
        }

        return state;
    }

    // eslint-disable-next-line complexity
    viewHotline(hotline: Hotline): boolean {
        let state: boolean;

        if (
            hotline.created_by === this.authenticationService.currentUserValue?.id ||
            hotline.creator.boss_id === this.authenticationService.currentUserValue?.id ||
            this.authenticationService.currentUserValue?.rule?.is_hotline_admin ||
            this.authenticationService.currentUserValue?.rule?.is_admin
        ) {
            state = true;
        } else if (hotline.hotliner.user.id === this.authenticationService.currentUserValue?.id) {
            state = true;
        } else {
            state = true;
        }

        return state;
    }

    assignationHotline(hotline: Hotline): boolean {
        let state = false;

        if (hotline.created_by === this.authenticationService.currentUserValue?.id) {
            state = true;
        } else if (hotline.hotliner.user.id === this.authenticationService.currentUserValue?.id) {
            state = true;
        }

        return state;
    }

    viewEvent(): boolean {
        return true;
    }

    assignationEvent(event: Event): boolean {
        let state = false;

        if (event.created_by === this.authenticationService.currentUserValue?.id) {
            state = true;
        } else {
            if (event.users.length > 0 || event.departments.length > 0) {
                event.users.forEach((data: User) => {
                    if (data.id === this.authenticationService.currentUserValue?.id) {
                        state = true;
                    }
                });

                event.departments.forEach((data: Department) => {
                    this.authenticationService.currentUserValue?.assignations?.forEach(
                        (assignation: Assignation) => {
                            if (data.id === assignation.department_id) {
                                state = true;
                            }
                        },
                    );
                });
            }

            if (event.users.length === 0 && event.departments.length === 0) {
                state = true;
            }
        }

        return state;
    }

    checkUserAssignation(department: Department | number, user: User): boolean {
        let iAmIn = false;
        const departmentId = typeof department === 'number' ? department : department.id;

        if (departmentId) {
            user.assignations?.forEach((assignation: Assignation) => {
                if (departmentId === assignation.department_id) {
                    iAmIn = true;
                }
            });
        }

        return iAmIn;
    }

    checkUserAdminAssignation(department: Department | number, user: User): boolean {
        let iAmIn = false;
        const departmentId = typeof department === 'number' ? department : department.id;

        if (departmentId) {
            user.assignations?.forEach((assignation: Assignation) => {
                if (
                    departmentId === assignation.department_id &&
                    assignation.role.name !== 'Zaměstnanec'
                ) {
                    iAmIn = true;
                }
            });
        }

        return iAmIn;
    }

    checkUserISHotliner(hotliners: Hotliner[]): boolean {
        let iAmHotliner = false;

        hotliners.forEach((hotliner: Hotliner) => {
            if (
                hotliner.user_id === this.authenticationService.currentUserValue?.id &&
                !hotliner.deleted_date
            ) {
                iAmHotliner = true;
            }
        });

        return iAmHotliner;
    }

    checkUserISLeader(user: User): {leaderStatus: boolean; leaderDepartments: number[]} {
        let iAmLeader = false;
        const departments: number[] = [];

        user.assignations?.forEach((assignation: Assignation) => {
            if (assignation.role.name === 'Nadřízený') {
                iAmLeader = true;
                departments.push(assignation.department_id);
            }
        });

        return {leaderStatus: iAmLeader, leaderDepartments: departments};
    }

    checkUserISBoss(
        currentUser: User,
        users: User[],
    ): {bossStatus: boolean; bossUsers: number[]} {
        let iAmBoss = false;
        const employers: number[] = [];

        users.forEach((user: User) => {
            if (user.boss_id === currentUser.id) {
                iAmBoss = true;
                employers.push(user.id);
            }
        });

        return {bossStatus: iAmBoss, bossUsers: employers};
    }

    checkUserISAdmin(user: User): boolean {
        let iAmAdmin = false;

        if (user.rule?.is_admin) {
            iAmAdmin = true;
        }

        return iAmAdmin;
    }

    checkUserISBudgetAdmin(user: User): boolean {
        let iAmAdmin = false;

        if (user.rule?.is_admin || user.rule?.is_budget_admin) {
            iAmAdmin = true;
        }

        return iAmAdmin;
    }

    checkUserISKnowledgeAdmin(user: User): boolean {
        let iAmAdmin = false;

        if (user.rule?.is_admin || user.rule?.is_knowledge_admin) {
            iAmAdmin = true;
        }

        return iAmAdmin;
    }

    checkUserISCarAdmin(user: User): boolean {
        let iAmAdmin = false;

        if (user.rule?.is_admin || user.rule?.is_car_admin) {
            iAmAdmin = true;
        }

        return iAmAdmin;
    }

    checkUserISOfficeAdmin(user: User): boolean {
        let iAmAdmin = false;

        if (user.rule?.is_admin || user.rule?.is_office_admin) {
            iAmAdmin = true;
        }

        return iAmAdmin;
    }

    checkUserISBookingAdmin(user: User): boolean {
        let iAmAdmin = false;

        if (user.rule?.is_admin || user.rule?.is_book_admin) {
            iAmAdmin = true;
        }

        return iAmAdmin;
    }

    checkUserISTaskAdmin(user: User): boolean {
        let iAmAdmin = false;

        if (user.rule?.is_admin || user.rule?.is_task_admin) {
            iAmAdmin = true;
        }

        return iAmAdmin;
    }

    checkUserISTicketAdmin(user: User): boolean {
        let iAmAdmin = false;

        if (user.rule?.is_admin || user.rule?.is_ticket_admin) {
            iAmAdmin = true;
        }

        return iAmAdmin;
    }

    checkUserISHotlineAdmin(user: User): boolean {
        let iAmAdmin = false;

        if (user.rule?.is_admin || user.rule?.is_hotline_admin) {
            iAmAdmin = true;
        }

        return iAmAdmin;
    }

    checkUserISVacationAdmin(user: User): boolean {
        let iAmAdmin = false;

        if (user.rule?.is_admin || user.rule?.is_vacation_admin) {
            iAmAdmin = true;
        }

        return iAmAdmin;
    }

    checkUserISServiceAdmin(user: User): boolean {
        let iAmAdmin = false;

        if (user.rule?.is_admin || user.rule?.is_service_admin) {
            iAmAdmin = true;
        }

        return iAmAdmin;
    }

    checkUserISAdministrative(user: User): boolean {
        let iAmAdmin = false;

        if (user.rule?.is_admin || user.rule?.is_administrative) {
            iAmAdmin = true;
        }

        return iAmAdmin;
    }

    checkUserISMaterialAdmin(user: User): boolean {
        let iAmAdmin = false;

        if (user.rule?.is_admin || user.rule?.is_material_admin) {
            iAmAdmin = true;
        }

        return iAmAdmin;
    }

    checkUserISMarketingAdmin(user: User): boolean {
        let iAmAdmin = false;

        if (user.rule?.is_admin || user.rule?.is_marketing_admin) {
            iAmAdmin = true;
        }

        return iAmAdmin;
    }
}
