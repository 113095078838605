import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    HostListener,
    NgZone,
    OnDestroy,
    OnInit,
    ViewChild,
} from '@angular/core';
import {AbstractControl, FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {DataNetbaseService} from '@src/app/_services/data-netbase.service';
import {AuthenticationService} from '@src/app/_services/authentication.service';
import {DataService} from '@src/app/_services/data.service';
import {
    GridComponent,
    PageSettingsModel,
    RowDataBoundEventArgs,
    SearchEventArgs,
    SelectionSettingsModel,
    ToolbarItems,
} from '@syncfusion/ej2-angular-grids';
import {HardwareConfig} from '@src/app/_models/services/hardware-config';
import {NetPackages} from '@src/app/_models/services/net-packages';
import {TvServices} from '@src/app/_models/services/tv-services';
import {TvPackages} from '@src/app/_models/services/tv-packages';
import {Client} from '@src/app/_models/clients/client';
import {AddressUntrusted} from '@src/app/_models/services/address-untrusted';
import {AddressExcluded} from '@src/app/_models/services/address-excluded';
import {AddressConnectedRuian} from '@src/app/_models/services/address-connected-ruian';
import {AddressConnectedField} from '@src/app/_models/services/address-connected-field';
import {PlanningOutages} from '@src/app/_models/services/planning-outages';
import {Company} from '@src/app/_models/company/company';
import {Observable, Observer} from 'rxjs';
import {ClientService} from '@src/app/_models/clients/client-service';
import {DealTypes} from '@src/app/_models/services/deal-types';
import {UnitTypes} from '@src/app/_models/services/unit-types';
import {SpeedTypes} from '@src/app/_models/services/speed-types';
import {SpaceTypes} from '@src/app/_models/services/space-types';
import {ExtendServices} from '@src/app/_models/services/extend-services';
import {MessageService} from '@src/app/_services/message.service';
import * as uuid from 'uuid';
import {ApisService} from '@src/app/_api/apis.service';
import {User} from '@src/app/_models/user/user';
import moment from 'moment';
import localeCs from '@angular/common/locales/cs';
import {registerLocaleData} from '@angular/common';
import {DropDownListComponent} from '@syncfusion/ej2-angular-dropdowns';
import {CheckBoxComponent} from '@syncfusion/ej2-angular-buttons';
import {Leads} from '@src/app/_models/netbase/leads';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {AccordionComponent} from '@syncfusion/ej2-angular-navigations';
import {ComponentCanDeactivate} from '@src/app/_guards/changes.guard';
import {TvChannels} from '@src/app/_models/services/tv-channels';
import {Orders} from '@src/app/_models/netbase/orders';
import {
    ClientGridItems,
    LeadInput,
    Marker,
    ServiceGridItems,
    ServicesService,
    SmartTvInterface,
} from '@src/app/features/services/services.service';
import {AddressObj} from '@src/app/features/services/_forms/callwizard/types/smartform-address.type';
import {CallwizardService} from '@src/app/features/services/_forms/callwizard/service/callwizard.service';
import {GoogleMap, MapInfoWindow} from '@angular/google-maps';

moment.updateLocale('cs', {workingWeekdays: [1, 2, 3, 4, 5]});
registerLocaleData(localeCs);

declare let smartform;

@UntilDestroy()
@Component({
    selector: 'app-callwizard',
    templateUrl: './callwizard.component.html',
    styleUrls: ['./callwizard.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CallwizardComponent implements OnInit, ComponentCanDeactivate, OnDestroy {
    // Dialogs
    renderTvChannelsDialog = false;

    renderCompareDialog = false;

    highlightedTvPackage: TvPackages | null = null;

    net: boolean;

    tv: boolean;

    // Grid
    pageSettings: PageSettingsModel;

    initialClientSort: object;

    initialServiceSort: object;

    currencyFormat: {format: string} = {format: '#,###.00\',- CZK\''};

    dateFormat: {type: string; format: string} = {type: 'dateTime', format: 'dd.MM.yyyy HH:mm'};

    fields: object = {text: 'label', value: 'value'};

    clientElements: ClientGridItems[] = [];

    serviceElements: ServiceGridItems[] = [];

    selectionSettings: SelectionSettingsModel;

    toolbar: ToolbarItems[];

    selIndex: number[] = [];

    // Dropdowns
    companySelect: Array<{value: boolean | number | string; label: string}> = [];

    unitsSelect: Array<{value: boolean | number | string; label: string}> = [];

    speedsSelect: Array<{value: boolean | number | string; label: string}> = [];

    spacesSelect: Array<{value: boolean | number | string; label: string}> = [];

    dealSelect: Array<{value: boolean | number | string; label: string}> = [];

    // Forms
    submited = false;

    isDirty = false;

    addressForm: FormGroup;

    wifiForm: FormGroup;

    smartTvForm: FormGroup;

    clientForm: FormGroup;

    tvsList: FormArray;

    // Address variables
    addressObj: AddressObj = {
        city: '',
        cityCode: 0,
        cityPart: '',
        district: '',
        districtCode: 0,
        gps: '',
        houseNumber: 0,
        jstk: '',
        orientalNumber: 0,
        partCode: 0,
        region: '',
        regionCode: 0,
        ruianCode: null,
        searchedAddress: null,
        state: '',
        street: '',
        streetCode: 0,
        zip: 0,
        buildingCode: 0,
        orientalNumberChar: 0,
        provisionalNumber: 0,
        wholeNumber: '',
        post: '',
        stateCode: 0,
        fieldNumber: 0,
        fieldNumber2: 0,
        cadastral: '',
        cadastralCode: 0,
        lift: false,
        numberOfStoreys: 0,
        numberOfFlats: 0,
        floorArea: 0,
    };

    // Variables
    currentUser: User | null;

    lead: Leads | null;

    lastLeadUpdate: string;

    activeWifiSets = false;

    stbWarn: boolean;

    searchedTerm?: string = '';

    hintText = '';

    feePeriod = 1;

    purchaseWifi = 'purchase';

    discount = 0;

    totalCsPrice = 0;

    setttopboxFee = 0;

    vipinstallFee = 0;

    setttopboxFeeAnnualy = 0;

    internetPriceAnnualy = 0;

    televisionPriceAnnualy = 0;

    extServicesPriceAnnualy = 0;

    wifiPriceAnnualy = 0;

    dealAge: number | undefined = 24;

    choosenWiFiServices?: HardwareConfig | null = null;

    choosenInternetTarif?: NetPackages | null = null;

    skipNetTarif = false;

    choosenTelevisionTarif?: TvServices | null = null;

    choosenTelevisionPackages?: TvPackages | null = null;

    choosenTelevisionExtensions?: TvPackages[] = [];

    choosenExtendedServices?: ExtendServices[] = [];

    connectedClients: Awaited<Client | undefined>[] = [];

    connectedServices: ClientService[] = [];

    connectedClientServices: ClientService[] = [];

    unstrustedAddresses: AddressUntrusted[] = [];

    excludedAddresses: AddressExcluded[] = [];

    hardwareConfigs: HardwareConfig[] = [];

    matchedWifiSets: HardwareConfig[] = [];

    matchedConnectedRuianAddress: AddressConnectedRuian | null = null;

    searchedConnectedFieldAddress: AddressConnectedField[] = [];

    matchedConnectedFieldAddress: AddressConnectedField | null = null;

    matchedUntrustedAddresses: AddressUntrusted | null = null;

    matchedExcludedAddresses: AddressExcluded | null = null;

    matchedWirelessAddress: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        connection_method: string;
        technology: string;
        ruian: number | null;
        // eslint-disable-next-line @typescript-eslint/naming-convention
        net_packages: NetPackages[];
        // eslint-disable-next-line @typescript-eslint/naming-convention
        tv_services: TvServices[];
    } | null = null;

    matchedNetPlacesPackages: NetPackages[] = [];

    matchedTvPlacesServices: TvServices[] = [];

    matchedOutages: PlanningOutages[] = [];

    activeTvServices: TvServices[] = [];

    activeNetPackages: NetPackages[] = [];

    outages: PlanningOutages[] = [];

    companies: Company[] = [];

    channels: TvChannels[] = [];

    deals: DealTypes[] = [];

    internetPrice = {
        period: this.feePeriod,
        fee: 0,
        price: 0,
        discount: this.discount,
    };

    televisionPrice = {
        period: this.feePeriod,
        fee: 0,
        price: 0,
        discount: this.discount,
    };

    extServicesPrice = {
        period: this.feePeriod,
        fee: 0,
        price: 0,
        discount: this.discount,
    };

    wifiPrice = {
        period: this.feePeriod,
        fee: 0,
        price: 0,
        discount: this.discount,
    };

    totalPrice = {
        period: this.feePeriod,
        fee: 0,
        price: 0,
        discount: this.discount,
    };

    // Loaders
    clientsLoading = false;

    servicesLoading = false;

    addressLoading = false;

    loadingLead = false;

    clickedRow: Client[] | undefined = [];

    clickedRow$ = new Observable<Client[] | undefined>(
        (observer: Observer<Client[] | undefined>) => {
            observer.next(this.clickedRow);
        },
    );

    // Map
    zoom = 12;

    center: google.maps.LatLngLiteral;

    options: google.maps.MapOptions = {
        mapTypeId: 'hybrid',
        zoomControl: false,
        scrollwheel: false,
        disableDoubleClickZoom: true,
        maxZoom: 15,
        minZoom: 8,
    };

    markers: Marker[] = [];

    infoContent = '';

    @ViewChild(GoogleMap, {static: false}) map: GoogleMap;

    @ViewChild(MapInfoWindow, {static: false}) info: MapInfoWindow;

    @ViewChild('hintArea') hintArea: HTMLElement;

    @ViewChild('pdfFinishTable') pdfFinishTable: ElementRef;

    @ViewChild('firstStepAccr') firstStepAccrObj: AccordionComponent;

    // Checkboxes
    @ViewChild('sendInvoicesByPost') sendInvoicesByPost: CheckBoxComponent;

    @ViewChild('sendInvoiceByEmail') sendInvoiceByEmail: CheckBoxComponent;

    @ViewChild('vipInstall') vipInstall: CheckBoxComponent;

    @ViewChild('invoiceByYear') invoiceByYear: CheckBoxComponent;

    // Grid
    @ViewChild('gridClients', {static: false}) gridClients?: GridComponent;

    @ViewChild('gridServices', {static: false}) gridServices?: GridComponent;

    // Dropdowns
    @ViewChild('deal') dealTypeObj: DropDownListComponent;

    @ViewChild('unit') unitTypeObj: DropDownListComponent;

    @ViewChild('space') flatSpaceObj: DropDownListComponent;

    @ViewChild('speed') speedTypeObj: DropDownListComponent;

    @ViewChild('company') companyTypeObj: DropDownListComponent;

    constructor(
        private readonly formBuilder: FormBuilder,
        private readonly router: Router,
        private readonly ref: ChangeDetectorRef,
        private readonly route: ActivatedRoute,
        private readonly dataNetbaseService: DataNetbaseService,
        public callwizardService: CallwizardService,
        private readonly authenticationService: AuthenticationService,
        private readonly dataService: DataService,
        private readonly servicesService: ServicesService,
        private readonly apisService: ApisService,
        private readonly zone: NgZone,
        private readonly message: MessageService,
    ) {
        this.currentUser = this.authenticationService.currentUserValue;
        this.zone = zone;
    }

    get tvFormGroup(): FormArray {
        return this.smartTvForm.get('tvs') as FormArray;
    }

    get addressFormCtrl(): {[key: string]: AbstractControl} {
        return this.addressForm.controls;
    }

    get wifiFormCtrl(): {[key: string]: AbstractControl} {
        return this.wifiForm.controls;
    }

    get clientFormCtrl(): {[key: string]: AbstractControl} {
        return this.clientForm.controls;
    }

    private getUnique(
        arr: Array<{value: boolean | number | string; label: string}>,
        comp: string,
    ): Array<{value: boolean | number | string; label: string}> {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        return (
            arr
                // eslint-disable-next-line @typescript-eslint/no-unsafe-return
                .map(e => e[comp])
                .map((e, i, final) => final.indexOf(e) === i && i)
                // eslint-disable-next-line @typescript-eslint/no-unsafe-return
                .filter(e => arr[e ? e : 0])
                // eslint-disable-next-line @typescript-eslint/no-unsafe-return
                .map(e => arr[e ? e : 0])
        );
    }

    @HostListener('window:beforeunload')
    canDeactivate(): Observable<boolean> | boolean {
        return !this.isDirty;
    }

    // SMARTFORM INITs + ReInits
    reinitSmartForm(): void {
        smartform
            .getInstance('smartform-instance-create-cw')
            .addressControl
            .addValidationCallback(this.validationCallback.bind(this));
    }

    async validationCallback(validationResult): Promise<void> {
        if (validationResult.result.type === smartform.AddressValidationResultType.HIT) {
            // podle vstupních dat byla nalezena jedna validní adresa
            this.addressObj.ruianCode = 0;
            this.lead = null;
            this.addressObj.searchedAddress = null;
            this.isDirty = this.addressForm.dirty;
            this.ref.markForCheck();
            this.addressObj.orientalNumber = parseInt(
                validationResult.result.addressArray[0].NUMBER_ORIENTACNI as string,
                10,
            );

            this.addressObj.houseNumber = parseInt(
                validationResult.result.addressArray[0].NUMBER_POPISNE as string,
                10,
            );
            this.addressObj.street = validationResult.result.addressArray[0].STREET;
            this.addressObj.streetCode = validationResult.result.addressArray[0].STREET_CODE
                ? parseInt(validationResult.result.addressArray[0].STREET_CODE as string, 10)
                : 0;
            this.addressObj.cityPart = validationResult.result.addressArray[0].PART;
            this.addressObj.partCode = validationResult.result.addressArray[0].STREET_CODE
                ? parseInt(validationResult.result.addressArray[0].PART_CODE as string, 10)
                : 0;
            this.addressObj.city = validationResult.result.addressArray[0].CITY;
            this.addressObj.cityCode = validationResult.result.addressArray[0].CITY_CODE
                ? parseInt(validationResult.result.addressArray[0].CITY_CODE as string, 10)
                : 0;
            this.addressObj.district = validationResult.result.addressArray[0].DISTRICT;
            this.addressObj.districtCode = validationResult.result.addressArray[0].DISTRICT_CODE
                ? parseInt(validationResult.result.addressArray[0].DISTRICT_CODE as string, 10)
                : 0;
            this.addressObj.region = validationResult.result.addressArray[0].REGION;
            this.addressObj.regionCode = validationResult.result.addressArray[0].REGION_CODE
                ? parseInt(validationResult.result.addressArray[0].REGION_CODE as string, 10)
                : 0;

            this.addressObj.zip = parseInt(
                validationResult.result.addressArray[0].ZIP as string,
                10,
            );
            this.addressObj.state = validationResult.result.addressArray[0].COUNTRY;
            this.addressObj.gps = `${
                validationResult.result.addressArray[0].COORD_WGS84_LATITUDE as string
            } / ${validationResult.result.addressArray[0].COORD_WGS84_LONGITUDE as string}`;

            this.addressObj.jstk = `${
                validationResult.result.addressArray[0].COORD_JTSK_X as string
            } / ${validationResult.result.addressArray[0].COORD_JTSK_Y as string}`;

            this.addressObj.searchedAddress =
                validationResult.result.addressArray[0].FORMATTED_ADDRESS_WHOLE;

            if (
                this.addressObj.ruianCode &&
                this.addressObj.ruianCode !==
                parseInt(validationResult.result.addressArray[0].CODE as string, 10)
            ) {
                this.addressObj.ruianCode = validationResult.result.addressArray[0].CODE
                    ? parseInt(validationResult.result.addressArray[0].CODE as string, 10)
                    : 0;
                await this.callwizardService.setAddress(this.addressObj);
            } else if (!this.addressObj.ruianCode) {
                this.addressObj.ruianCode = validationResult.result.addressArray[0].CODE
                    ? parseInt(validationResult.result.addressArray[0].CODE as string, 10)
                    : 0;
                await this.callwizardService.setAddress(this.addressObj);
            }
        }
    }

    // eslint-disable-next-line max-lines-per-function,complexity
    ngOnInit(): void {
        this.pageSettings = {pageCount: 5};
        this.initialServiceSort = {
            columns: [{field: 'price', direction: 'Descending'}],
        };

        this.initialClientSort = {
            columns: [{field: 'name', direction: 'Ascending'}],
        };
        this.selectionSettings = {type: 'Multiple', mode: 'Both'};
        this.toolbar = ['Search'];

        this.dataService.clientDetailSource.pipe(untilDestroyed(this))
            .subscribe(
                (client: Client | undefined) => {
                    if (client) {
                        this.connectedClients.push(client);
                    }
                },
                error => {
                    console.error(error);
                },
            );

        this.dataNetbaseService.serviceAddressSource
            .pipe(untilDestroyed(this))
            .subscribe(async (services: ClientService[] | undefined): Promise<void> => {
                if (services && services.length > 0) {
                    this.servicesLoading = true;
                    this.clientsLoading = true;
                    this.ref.markForCheck();

                    let clientsObject = [];

                    this.connectedServices = services;
                    this.tableServicesLoad();
                    clientsObject = services.reduce(
                        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
                        (a, c) => ((a[c.client_id] = ((a[c.client_id] as number) || 0) + 1), a),
                        Object.create(null),
                    );

                    const clientsArray: {[key: string]: ClientService}[] = [];

                    Object.keys(clientsObject)
                        .map(key => {
                            clientsArray.push({[key]: clientsObject[key]});

                            return clientsArray;
                        });
                    this.connectedClients = [];

                    const promises: Promise<Client | undefined>[] = [];

                    for (const ids of clientsArray) {
                        const id = Object.keys(ids)[0];
                        const clientPromise = this.dataService
                            .setClientDetailSource(parseInt(id, 10))
                            .toPromise();

                        promises.push(clientPromise);
                    }

                    await Promise.all(promises)
                        .then(result => {
                            this.connectedClients = result;

                            if (this.clickedRow && this.lead && this.lead.cl_cnumber > 0) {
                                const connectedClient = this.connectedClients.find(
                                    x => x && parseInt(x.client_number, 10) === this.lead?.cl_cnumber,
                                );

                                if (connectedClient) {
                                    this.clickedRow[0] = connectedClient;
                                }
                            }

                            this.tableClientsLoad();
                        });
                }
            });

        // UNTRUSTED ADDRESSES
        this.dataNetbaseService.untrustedAddressSource.pipe(untilDestroyed(this))
            .subscribe(
                (addresses: AddressUntrusted[] | undefined) => {
                    if (addresses) {
                        this.unstrustedAddresses = addresses;
                        this.ref.markForCheck();
                    }
                },
                error => {
                    console.error(error);
                },
            );

        // EXCLUDED ADDRESSES
        this.dataNetbaseService.excludedAddressSource.pipe(untilDestroyed(this))
            .subscribe(
                (exAddresses: AddressExcluded[] | undefined) => {
                    if (exAddresses) {
                        this.excludedAddresses = exAddresses;
                        this.ref.markForCheck();
                    }
                },
                error => {
                    console.error(error);
                },
            );

        // AVAILABLE HARDWARE CONFIGURATIONS
        this.dataNetbaseService.hardwareConfigSource.pipe(untilDestroyed(this))
            .subscribe(
                (configs: HardwareConfig[] | undefined) => {
                    if (configs) {
                        this.hardwareConfigs = configs;
                        this.hardwareConfigs = this.hardwareConfigs.sort((a, b) => {
                            const aValue = a.hardware.name;
                            const bValue = b.hardware.name;

                            return aValue > bValue ? -1 : 1;
                        });
                        this.ref.markForCheck();
                    }
                },
                error => {
                    console.error(error);
                },
            );

        // NETBASE params
        this.dataNetbaseService.dealTypesSource.pipe(untilDestroyed(this))
            .subscribe(
                (deals: DealTypes[] | undefined) => {
                    this.dealSelect = [];

                    if (deals && deals.length > 0) {
                        this.deals = deals;
                        deals = deals.sort((a, b) => {
                            const aValue = a.age;
                            const bValue = b.age;

                            return aValue > bValue ? -1 : 1;
                        });

                        deals.map((deal: DealTypes) => {
                            if (!deal.deleted_date) {
                                this.dealSelect = [
                                    ...this.dealSelect,
                                    {
                                        value: deal.id,
                                        label: `${deal.age} měsíců`,
                                    },
                                ];
                            }
                        });
                    }
                },
                error => {
                    console.error(error);
                },
            );

        this.dataService.companySource.pipe(untilDestroyed(this))
            .subscribe(
                (companies: Company[] | undefined) => {
                    this.companySelect = [];

                    if (companies) {
                        this.companies = companies.sort((a, b) => {
                            const aValue = a.name;
                            const bValue = b.name;

                            return aValue > bValue ? -1 : 1;
                        });

                        companies.map((company: Company) => {
                            if (!company.deleted_date) {
                                this.companySelect = [
                                    ...this.companySelect,
                                    {
                                        value: company.id,
                                        label: company.name,
                                    },
                                ];
                            }
                        });
                    }
                },
                error => {
                    console.error(error);
                },
            );

        this.dataNetbaseService.unitTypesSource.pipe(untilDestroyed(this))
            .subscribe(
                (units: UnitTypes[] | undefined) => {
                    this.unitsSelect = [];
                    units?.sort((a, b) => {
                        const aValue = a.name;
                        const bValue = b.name;

                        return aValue > bValue ? -1 : 1;
                    });

                    units?.map((unit: UnitTypes) => {
                        if (!unit.deleted_date) {
                            this.unitsSelect = [
                                ...this.unitsSelect,
                                {
                                    value: unit.id,
                                    label: unit.name,
                                },
                            ];
                        }
                    });
                },
                error => {
                    console.error(error);
                },
            );

        this.dataNetbaseService.speedTypesSource.pipe(untilDestroyed(this))
            .subscribe(
                (speeds: SpeedTypes[] | undefined) => {
                    this.speedsSelect = [];
                    speeds?.sort((a, b) => {
                        const aValue = a.speed_until;
                        const bValue = b.speed_until;

                        return aValue > bValue ? -1 : 1;
                    });

                    speeds?.map((speed: SpeedTypes) => {
                        if (!speed.deleted_date) {
                            this.speedsSelect = [
                                ...this.speedsSelect,
                                {
                                    value: speed.id,
                                    label: `${speed.speed_until} Mbit - ${
                                        speed.speed_to > 0 ? speed.speed_to : '*'
                                    } Mbit`,
                                },
                            ];
                        }
                    });
                },
                error => {
                    console.error(error);
                },
            );

        this.dataNetbaseService.spaceTypesSource.pipe(untilDestroyed(this))
            .subscribe(
                (spaces: SpaceTypes[] | undefined) => {
                    this.spacesSelect = [];
                    spaces?.sort((a, b) => {
                        const aValue = a.metric_until;
                        const bValue = b.metric_until;

                        return aValue > bValue ? -1 : 1;
                    });

                    spaces?.map((space: SpaceTypes) => {
                        if (!space.deleted_date) {
                            this.spacesSelect = [
                                ...this.spacesSelect,
                                {
                                    value: space.id,
                                    label: `${space.metric_until} m2 - ${
                                        space.metric_to > 0 ? space.metric_to : '*'
                                    } m2`,
                                },
                            ];
                        }
                    });
                },
                error => {
                    console.error(error);
                },
            );

        this.dataNetbaseService.tvServicesSource.pipe(untilDestroyed(this))
            .subscribe(
                (tvServices: TvServices[] | undefined) => {
                    this.activeTvServices = [];
                    tvServices?.sort((a, b) => {
                        const aValue = a.name;
                        const bValue = b.name;

                        return aValue > bValue ? -1 : 1;
                    });

                    tvServices?.map((tvService: TvServices) => {
                        if (!tvService.deleted_date) {
                            this.activeTvServices.push(tvService);
                        }
                    });
                },
                error => {
                    console.error(error);
                },
            );

        this.dataNetbaseService.netPackagesSource.pipe(untilDestroyed(this))
            .subscribe(
                (netPackages: NetPackages[] | undefined) => {
                    this.activeNetPackages = [];
                    netPackages?.sort((a, b) => {
                        const aValue = a.name;
                        const bValue = b.name;

                        return aValue > bValue ? -1 : 1;
                    });

                    netPackages?.map((netPackage: NetPackages) => {
                        if (!netPackage.deleted_date) {
                            this.activeNetPackages.push(netPackage);
                        }
                    });
                },
                error => {
                    console.error(error);
                },
            );

        this.dataNetbaseService.tvChannelsSource.pipe(untilDestroyed(this))
            .subscribe(
                (channels: TvChannels[] | undefined) => {
                    if (channels) {
                        this.channels = channels;
                        this.channels.sort((a, b) => {
                            const aValue = a.name;
                            const bValue = b.name;

                            return aValue > bValue ? -1 : 1;
                        });
                    }
                },
                error => {
                    console.error(error);
                },
            );

        // KROK 1
        this.addressForm = this.formBuilder.group({
            cwAddress: ['', Validators.required],
        });

        // KROK 3
        this.smartTvForm = this.formBuilder.group({
            tvs: this.formBuilder.array([this.createTv()]),
        });
        this.tvsList = this.smartTvForm.get('tvs') as FormArray;
        // KROK 4
        this.wifiForm = this.formBuilder.group({
            own_router: [false, Validators.required],
            basics: [false, Validators.required],
            garden: [false, Validators.required],
            cabels: [false, Validators.required],
            roaming: [false, Validators.required],
            unit_id: [null, Validators.required],
            space_id: [null, Validators.required],
            speed_id: [null, Validators.required],
        });

        // KROK 5
        const ownerId = this.clickedRow?.[0]?.data_owner
            ? this.companies.find(x => x.name === this.clickedRow?.[0].data_owner)?.id
            : this.choosenInternetTarif instanceof NetPackages &&
            this.choosenInternetTarif.provider_id
                ? this.choosenInternetTarif.provider_id
                : this.matchedConnectedRuianAddress?.provider_id
                    ? this.matchedConnectedRuianAddress.provider_id
                    : null;

        this.clientForm = this.formBuilder.group({
            firstname: [
                this.clickedRow?.[0]?.firstname ? this.clickedRow[0].firstname : '',
                Validators.compose([
                    Validators.required,
                    Validators.pattern('[a-žA-Ž]*'),
                    Validators.maxLength(100),
                ]),
            ],
            lastname: [
                this.clickedRow?.[0]?.lastname ? this.clickedRow[0].lastname : '',
                Validators.compose([
                    Validators.required,
                    Validators.pattern('[a-žA-Ž]*'),
                    Validators.maxLength(100),
                ]),
            ],
            companyId: [ownerId ? ownerId : null, Validators.compose([Validators.required])],
            email: [
                this.clickedRow?.[0]?.email ? this.clickedRow[0].email : '',
                Validators.compose([
                    Validators.required,
                    Validators.email,
                    Validators.pattern('\\S+@\\S+\\.\\S+'),
                ]),
            ],
            nationalIdNumber: [
                '',
                Validators.compose([
                    Validators.pattern('[0-9]{2,6}-?[0-9]{2,10}\\/[0-9]{4}'),
                    Validators.maxLength(15),
                ]),
            ],
            billingEmail: [
                this.clickedRow?.[0]?.billing_email ? this.clickedRow[0].billing_email : '',
                Validators.compose([Validators.email, Validators.pattern('\\S+@\\S+\\.\\S+')]),
            ],
            technicalEmail: [
                this.clickedRow?.[0]?.technical_email ? this.clickedRow[0].technical_email : '',
                Validators.compose([Validators.email, Validators.pattern('\\S+@\\S+\\.\\S+')]),
            ],
            businessEmail: [
                this.clickedRow?.[0]?.business_email ? this.clickedRow[0].business_email : '',
                Validators.compose([Validators.email, Validators.pattern('\\S+@\\S+\\.\\S+')]),
            ],
            telephone: [
                this.clickedRow?.[0]?.phone ? this.clickedRow[0].phone : '',
                Validators.compose([
                    Validators.pattern('(\\+420)? ?[1-9][0-9]{2} ?[0-9]{3} ?[0-9]{3}'),
                    Validators.minLength(9),
                    Validators.maxLength(17),
                ]),
            ],
            mobile: [
                this.clickedRow?.[0]?.mobile ? this.clickedRow[0].mobile : '',
                Validators.compose([
                    Validators.pattern('(\\+420)? ?[1-9][0-9]{2} ?[0-9]{3} ?[0-9]{3}'),
                    Validators.minLength(9),
                    Validators.maxLength(17),
                    Validators.required,
                ]),
            ],
            accountNumber: [null],
            bankNumber: [null],
            address: [
                this.clickedRow?.[0]?.contact_address ? this.clickedRow[0].contact_address : '',
                Validators.compose([Validators.required]),
            ],
            billingAddress: [''],
            vatId: [
                this.clickedRow?.[0]?.dic ? this.clickedRow[0].dic : '',
                Validators.compose([Validators.pattern('[a-žA-Ž0-9]*'), Validators.maxLength(15)]),
            ],
            sendInvoicesByPost: [false, Validators.compose([Validators.required])],
            sendInvoiceByEmail: [true, Validators.compose([Validators.required])],
            invoiceByYear: [false, Validators.compose([Validators.required])],
            vipInstall: [false, Validators.compose([Validators.required])],
            deal_id: [null, Validators.compose([Validators.required])],
            internalNote: ['', Validators.compose([Validators.maxLength(1000)])],
        });

        this.addressForm.valueChanges.pipe(untilDestroyed(this))
            .subscribe(() => {
                this.isDirty = this.addressForm.dirty;
                this.ref.markForCheck();
            });

        this.smartTvForm.valueChanges.pipe(untilDestroyed(this))
            .subscribe(() => {
                this.isDirty = this.smartTvForm.dirty;
                this.callwizardService.reCountTotalFee();
            });

        this.clientForm.valueChanges.pipe(untilDestroyed(this))
            .subscribe(() => {
                this.isDirty = this.clientForm.dirty;
                this.ref.markForCheck();
            });

        this.tvsList.valueChanges.pipe(untilDestroyed(this))
            .subscribe(value => {
                const tvs: SmartTvInterface[] = [];
                let countSTB = 0;

                this.setttopboxFee = 0;
                value.map((tv: SmartTvInterface) => {
                    if (tv.settopbox) {
                        tv.smart = false;
                        tv.settopbox_fee = true;
                        countSTB++;
                    } else {
                        tv.smart = true;
                        tv.settopbox_fee = false;
                    }

                    tvs.push(tv);
                });
                this.setttopboxFee = 99 * countSTB;
                this.smartTvForm.get('tvs')
                    ?.setValue(tvs, {onlySelf: true, emitEvent: false});
                this.callwizardService.reCountTotalFee();
            });

        this.wifiForm.valueChanges.pipe(untilDestroyed(this))
            .subscribe(value => {
                this.spacesSelect = [];
                this.speedsSelect = [];
                this.matchedWifiSets = [];

                let filteredData: HardwareConfig[] = this.hardwareConfigs;
                const {basics} = value;
                const {garden} = value;
                const {cabels} = value;
                const {roaming} = value;
                const unitId = value.unit_id;
                const spaceId = value.space_id;
                const speedId = value.speed_id;

                if (unitId) {
                    filteredData = filteredData.filter(
                        (config: HardwareConfig) => config.unit_id === unitId,
                    );

                    filteredData.forEach((config: HardwareConfig) => {
                        this.spacesSelect = [
                            ...this.spacesSelect,
                            {
                                value: config.space_id,
                                label: `${config.space.metric_until} m2 - ${
                                    config.space.metric_to > 0 ? config.space.metric_to : '*'
                                } m2`,
                            },
                        ];
                    });
                    this.spacesSelect = this.getUnique(this.spacesSelect, 'value');
                }

                if (basics) {
                    filteredData = filteredData.filter(
                        (config: HardwareConfig) => config.basics === basics,
                    );
                }

                if (cabels) {
                    filteredData = filteredData.filter(
                        (config: HardwareConfig) => config.cabels === cabels,
                    );
                }

                if (garden) {
                    filteredData = filteredData.filter(
                        (config: HardwareConfig) => config.garden === garden,
                    );
                }

                if (roaming) {
                    filteredData = filteredData.filter(
                        (config: HardwareConfig) => config.roaming === roaming,
                    );
                }

                if (spaceId) {
                    filteredData = filteredData.filter(
                        (config: HardwareConfig) => config.space_id === spaceId,
                    );

                    filteredData.forEach((config: HardwareConfig) => {
                        this.speedsSelect = [
                            ...this.speedsSelect,
                            {
                                value: config.speed_id,
                                label: `${config.speed.speed_until} Mbit - ${
                                    config.speed.speed_to > 0 ? config.speed.speed_to : '*'
                                } Mbit`,
                            },
                        ];
                    });
                    this.speedsSelect = this.getUnique(this.speedsSelect, 'value');
                }

                if (speedId) {
                    filteredData = filteredData.filter(
                        (config: HardwareConfig) => config.speed_id === speedId,
                    );
                }

                if (speedId || spaceId || roaming || garden || basics || unitId || cabels) {
                    this.activeWifiSets = true;
                    this.matchedWifiSets = filteredData;
                }

                this.isDirty = this.wifiForm.dirty;
                this.callwizardService.reCountTotalFee();
            });

        this.wifiForm
            .get('own_router')
            ?.valueChanges
            .pipe(untilDestroyed(this))
            .subscribe(value => {
                if (value) {
                    this.wifiPrice = {
                        period: this.feePeriod,
                        fee: 0,
                        price: 0,
                        discount: 0,
                    };
                    this.wifiPriceAnnualy = 0;
                    this.activeWifiSets = false;
                    this.matchedWifiSets = [];
                    this.choosenWiFiServices = null;
                    this.wifiForm.controls.basics.reset(false, {emitEvent: false});
                    this.wifiForm.controls.garden.reset(false, {emitEvent: false});
                    this.wifiForm.controls.cabels.reset(false, {emitEvent: false});
                    this.wifiForm.controls.roaming.reset(false, {emitEvent: false});
                    this.wifiForm.controls.unit_id.reset(null, {emitEvent: false});
                    this.wifiForm.controls.space_id.reset(null, {emitEvent: false});
                    this.wifiForm.controls.speed_id.reset(null, {emitEvent: false});
                    this.wifiForm.controls.basics.setValidators(null);
                    this.wifiForm.controls.garden.setValidators(null);
                    this.wifiForm.controls.cabels.setValidators(null);
                    this.wifiForm.controls.roaming.setValidators(null);
                    this.wifiForm.controls.unit_id.setValidators(null);
                    this.wifiForm.controls.space_id.setValidators(null);
                    this.wifiForm.controls.speed_id.setValidators(null);
                } else {
                    this.wifiForm.controls.basics.setValidators([Validators.required]);
                    this.wifiForm.controls.garden.setValidators([Validators.required]);
                    this.wifiForm.controls.cabels.setValidators([Validators.required]);
                    this.wifiForm.controls.roaming.setValidators([Validators.required]);
                    this.wifiForm.controls.unit_id.setValidators([Validators.required]);
                    this.wifiForm.controls.space_id.setValidators([Validators.required]);
                    this.wifiForm.controls.speed_id.setValidators([Validators.required]);
                }

                this.wifiForm.controls.basics.updateValueAndValidity();
                this.wifiForm.controls.garden.updateValueAndValidity();
                this.wifiForm.controls.cabels.updateValueAndValidity();
                this.wifiForm.controls.roaming.updateValueAndValidity();
                this.wifiForm.controls.unit_id.updateValueAndValidity();
                this.wifiForm.controls.space_id.updateValueAndValidity();
                this.wifiForm.controls.speed_id.updateValueAndValidity();
                this.callwizardService.reCountTotalFee();
            });

        this.clientForm
            .get('invoiceByYear')
            ?.valueChanges
            .pipe(untilDestroyed(this))
            .subscribe(value => {
                if (value) {
                    this.discount = this.discount + 10;
                    this.feePeriod = 12;
                    this.internetPrice.period = 12;
                    this.televisionPrice.period = 12;
                    this.extServicesPrice.period = 12;
                    this.wifiPrice.period = 12;
                    this.totalPrice.period = 12;
                    this.setttopboxFeeAnnualy =
                        this.setttopboxFee > 0
                            ? this.setttopboxFee * this.feePeriod -
                            (this.setttopboxFee * this.feePeriod * this.discount) / 100
                            : 0;

                    this.internetPriceAnnualy =
                        this.internetPrice.fee * this.internetPrice.period -
                        (this.internetPrice.fee * this.internetPrice.period * this.discount) / 100;

                    this.televisionPriceAnnualy =
                        this.televisionPrice.fee * this.televisionPrice.period -
                        (this.televisionPrice.fee * this.televisionPrice.period * this.discount) /
                        100;

                    this.extServicesPriceAnnualy =
                        this.extServicesPrice.fee * this.extServicesPrice.period -
                        (this.extServicesPrice.fee * this.extServicesPrice.period * this.discount) /
                        100;

                    this.wifiPriceAnnualy =
                        this.wifiPrice.fee * this.wifiPrice.period -
                        (this.wifiPrice.fee * this.wifiPrice.period * this.discount) / 100;
                } else {
                    this.discount = this.discount > 10 ? this.discount - 10 : 0;
                    this.feePeriod = 1;
                    this.internetPrice.period = 1;
                    this.televisionPrice.period = 1;
                    this.extServicesPrice.period = 1;
                    this.wifiPrice.period = 1;
                    this.totalPrice.period = 1;
                    this.setttopboxFeeAnnualy = 0;
                    this.internetPriceAnnualy = 0;
                    this.televisionPriceAnnualy = 0;
                    this.extServicesPriceAnnualy = 0;
                    this.wifiPriceAnnualy = 0;
                }

                this.callwizardService.reCountTotalFee();
            });

        this.clientForm
            .get('vipInstall')
            ?.valueChanges
            .pipe(untilDestroyed(this))
            .subscribe(value => {
                this.vipinstallFee = value ? 500 : 0;
                this.callwizardService.reCountTotalFee();
            });

        this.clientForm
            .get('deal_id')
            ?.valueChanges
            .pipe(untilDestroyed(this))
            .subscribe(value => {
                this.dealAge = value ? this.deals.find(x => x.id === value)?.age : 24;
                this.callwizardService.reCountTotalFee();
            });

        this.dataService.setCompanyDataSource();
        this.dataNetbaseService.setFieldAddressDataSource();
        this.dataNetbaseService.setUntrustedAddressDataSource();
        this.dataNetbaseService.setExcludedAddressDataSource();
        this.dataNetbaseService.setTechnologyTypesDataSource();
        this.dataNetbaseService.setDiscountsDataSource();
        this.dataNetbaseService.setDealTypesDataSource();
        this.dataNetbaseService.setUnitTypesDataSource();
        this.dataNetbaseService.setSpaceTypesDataSource();
        this.dataNetbaseService.setSpeedTypesDataSource();
        this.dataNetbaseService.setHardwareConfigDataSource();
        this.dataNetbaseService.setTvServicesDataSource();
        this.dataNetbaseService.setNetPackagesDataSource();
        this.dataNetbaseService.setTvChannelsDataSource();

        this.serviceElements = [];
        this.clientElements = [];

        this.route.queryParamMap.pipe(untilDestroyed(this))
            .subscribe(queryParams => {
                if (queryParams.get('view') === 'callwizard') {
                    this.servicesService.leadSource
                        .pipe(untilDestroyed(this))
                        .subscribe(async (lead: Leads | null): Promise<void> => {
                            if (lead) {
                                await this.setLead(lead);
                            }
                        });
                }
            });

        this.callwizardService.unsetAll();

        navigator.geolocation.getCurrentPosition(position => {
            this.center = {
                lat: position.coords.latitude,
                lng: position.coords.longitude,
            };
        });
    }

    zoomIn(): void {
        if (this.options.maxZoom && this.zoom < this.options.maxZoom) {
            this.zoom++;
        }
    }

    zoomOut(): void {
        if (this.options.minZoom && this.zoom > this.options.minZoom) {
            this.zoom--;
        }
    }

    logCenter(): void {
        console.log(JSON.stringify(this.map.getCenter()));
    }

    addMarker(): void {
        this.markers.push({
            position: {
                lat: this.center.lat + ((Math.random() - 0.5) * 2) / 10,
                lng: this.center.lng + ((Math.random() - 0.5) * 2) / 10,
            },
            label: {
                color: 'red',
                text: `Marker label ${this.markers.length + 1}`,
            },
            title: `Marker title ${this.markers.length + 1}`,
            info: `Marker info ${this.markers.length + 1}`,
            options: {
                animation: google.maps.Animation.BOUNCE,
            },
        });
    }

    setAccAnimation(): void {
        this.firstStepAccrObj.animation.expand = {effect: 'FadeIn'};
        this.firstStepAccrObj.animation.collapse = {effect: 'SlideUp'};
    }

    tableClientsLoad(): void {
        this.clientElements = [];

        if (this.connectedClients.length > 0) {
            this.connectedClients.map((data: Client) => {
                this.clientElements.push({
                    id: data.id,
                    client_number: data.client_number,
                    name: data.name,
                    isp_link: `https://isp.jon.cz/client-informations/?clientId=${data.isp_id}`,
                });
            });
        }

        this.clientsLoading = false;
        this.ref.markForCheck();
    }

    tableServicesLoad(): void {
        this.totalCsPrice = 0;
        this.serviceElements = [];

        if (this.connectedClientServices.length > 0) {
            this.connectedClientServices.map((service: ClientService) => {
                this.totalCsPrice += service.price;
                this.serviceElements.push({
                    id: service.id,
                    name: service.name,
                    price: service.price,
                });
            });
        } else if (this.connectedServices.length > 0) {
            this.connectedServices.map((service: ClientService) => {
                this.totalCsPrice += service.price;
                this.serviceElements.push({
                    id: service.id,
                    name: service.name,
                    price: service.price,
                });
            });
        }

        this.servicesLoading = false;
        this.ref.markForCheck();
    }

    rowDataBound(args: RowDataBoundEventArgs): void {
        const data = args.data as Client;

        if (this.lead) {
            // eslint-disable-next-line eqeqeq
            if (parseInt(data.client_number, 10) == this.lead.cl_cnumber) {
                if (args.row?.getAttribute('aria-rowindex')) {
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    this.selIndex.push(parseInt(args.row.getAttribute('aria-rowindex')!, 10));
                    this.ref.markForCheck();
                }
            }
        }
    }

    dataBound(): void {
        if (this.selIndex.length) {
            this.gridClients?.selectRows(this.selIndex);
            this.selIndex = [];
            this.ref.markForCheck();
        }
    }

    actionClientsBegin(args: SearchEventArgs): void {
        if (args.requestType === 'searching') {
            if (
                !args.searchString ||
                this.searchedTerm !== args.searchString ||
                args.searchString.length === 0
            ) {
                this.clickedRow = [];
                this.connectedClientServices = [];
                this.gridClients?.clearRowSelection();
                this.tableServicesLoad();
            }

            this.searchedTerm = args.searchString;
        }
    }

    chooseAddress(field): void {
        if (field && this.addressObj.searchedAddress) {
            this.matchedConnectedFieldAddress = field;
            this.addressForm.controls.cwAddress.patchValue(
                this.matchedConnectedFieldAddress?.address,
            );

            if (this.matchedConnectedFieldAddress) {
                this.matchedConnectedFieldAddress.net_packages =
                    this.matchedConnectedFieldAddress.net_packages.sort((a, b) => {
                        const valueA = a.sale_order;
                        const valueB = b.sale_order;

                        return valueA < valueB ? -1 : 1;
                    });

                this.matchedConnectedFieldAddress.net_packages.map((netPackage: NetPackages) => {
                    netPackage.ext_services.map((extService: ExtendServices) => {
                        extService.is_selected = false;
                    });
                });

                this.matchedConnectedFieldAddress.tv_services =
                    this.matchedConnectedFieldAddress.tv_services?.sort((a, b) => {
                        const valueA = a.sale_order;
                        const valueB = b.sale_order;

                        return valueA < valueB ? -1 : 1;
                    });

                this.matchedConnectedFieldAddress.tv_services?.map((tvService: TvServices) => {
                    tvService.ext_services.map((extService: ExtendServices) => {
                        extService.is_selected = false;
                    });
                });

                this.matchedConnectedFieldAddress.tv_services?.forEach(
                    (service: TvServices, index) => {
                        if (this.matchedConnectedFieldAddress?.tv_services) {
                            this.matchedConnectedFieldAddress.tv_services[index].tv_packages =
                                service.tv_packages?.sort((a, b) => {
                                    const valueA = a.sale_order;
                                    const valueB = b.sale_order;

                                    return valueA < valueB ? -1 : 1;
                                });
                        }
                    },
                );
            }

            this.addressObj.searchedAddress = field.address;
            // Výpadky služeb na adrese
            this.dataNetbaseService.setOutageDataSource(
                true,
                this.addressObj.searchedAddress,
                this.addressObj.ruianCode,
                this.addressObj.streetCode,
                this.addressObj.cityCode,
                this.addressObj.partCode,
                this.addressObj.districtCode,
                this.addressObj.regionCode,
            );
            this.addressLoading = false;
            // GoogleMaps
            this.findLocation(this.addressObj.searchedAddress);
            this.ref.markForCheck();
        }
    }

    // eslint-disable-next-line complexity
    rowSelected(): void {
        const selectedrecords: Client[] = this.gridClients?.getSelectedRecords() as Client[];

        if (selectedrecords.length > 0) {
            selectedrecords.map((row: Client) => {
                const client = this.connectedClients.find(x => x && x.id === row.id);

                if (client) {
                    this.clickedRow?.push(client);
                }
            });

            this.clickedRow$ = new Observable<Client[]>((observer: Observer<Client[]>) => {
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                observer.next(this.clickedRow!);
            });

            const ownerId = this.clickedRow?.[0]?.data_owner
                ? this.companies.find(x => x.name === this.clickedRow?.[0].data_owner)?.id
                : this.choosenInternetTarif instanceof NetPackages &&
                this.choosenInternetTarif.provider_id
                    ? this.choosenInternetTarif.provider_id
                    : this.matchedConnectedRuianAddress?.provider_id
                        ? this.matchedConnectedRuianAddress.provider_id
                        : null;

            this.clientForm.controls.firstname.patchValue(
                this.clickedRow?.[0]?.firstname ? this.clickedRow[0].firstname : '',
            );

            this.clientForm.controls.lastname.patchValue(
                this.clickedRow?.[0]?.lastname ? this.clickedRow[0].lastname : '',
            );
            this.clientForm.controls.companyId.patchValue(ownerId ? ownerId : null);
            this.clientForm.controls.email.patchValue(
                this.clickedRow?.[0]?.email ? this.clickedRow[0].email : '',
            );

            this.clientForm.controls.billingEmail.patchValue(
                this.clickedRow?.[0]?.billing_email ? this.clickedRow[0].billing_email : '',
            );

            this.clientForm.controls.technicalEmail.patchValue(
                this.clickedRow?.[0]?.technical_email ? this.clickedRow[0].technical_email : '',
            );

            this.clientForm.controls.businessEmail.patchValue(
                this.clickedRow?.[0]?.business_email ? this.clickedRow[0].business_email : '',
            );

            this.clientForm.controls.telephone.patchValue(
                this.clickedRow?.[0]?.phone ? this.clickedRow[0].phone : '',
            );

            this.clientForm.controls.mobile.patchValue(
                this.clickedRow?.[0]?.mobile ? this.clickedRow[0].mobile : '',
            );

            this.clientForm.controls.address.patchValue(
                this.clickedRow?.[0]?.contact_address ? this.clickedRow[0].contact_address : '',
            );

            this.clientForm.controls.vatId.patchValue(
                this.clickedRow?.[0]?.dic ? this.clickedRow[0].dic : '',
            );
            this.clientForm.controls.deal_id.patchValue(this.deals.find(x => x.age === 24)?.id);

            if (this.clickedRow?.[0]) {
                this.connectedClientServices = this.connectedServices.filter(
                    (service: ClientService) =>
                        this.clickedRow?.find(x => x.id === service.client_id),
                );
            }
        } else {
            this.resetClientForm();
            this.clickedRow = [];
            this.connectedClientServices = [];
            this.clickedRow$ = new Observable<Client[]>((observer: Observer<Client[]>) => {
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                observer.next(this.clickedRow!);
            });
        }

        this.tableServicesLoad();
    }

    createTv(tv?: SmartTvInterface): FormGroup {
        return this.formBuilder.group({
            tv: [tv?.tv ? tv.tv : '', Validators.compose([Validators.required])],
            smart: [tv?.smart ? tv.smart : true, Validators.compose([Validators.required])],
            settopbox: [
                tv?.settopbox ? tv.settopbox : false,
                Validators.compose([Validators.required]),
            ],
            settopbox_fee: [
                tv?.settopbox_fee ? tv.settopbox_fee : false,
                Validators.compose([Validators.required]),
            ],
        });
    }

    getTvFormGroup(index): FormGroup {
        return this.tvsList.controls[index] as FormGroup;
    }

    addTv(): void {
        this.tvsList.push(this.createTv());
    }

    removeTv(index: number): void {
        this.tvsList.removeAt(index);
    }

    unsetNetTarif(): void {
        if (this.matchedConnectedRuianAddress) {
            this.matchedConnectedRuianAddress.net_packages.map((netPackage: NetPackages) => {
                netPackage.ext_services.map((extService: ExtendServices) => {
                    extService.is_selected = false;
                });
            });
        }

        if (this.matchedConnectedFieldAddress) {
            this.matchedConnectedFieldAddress.net_packages.map((netPackage: NetPackages) => {
                netPackage.ext_services.map((extService: ExtendServices) => {
                    extService.is_selected = false;
                });
            });
        }

        if (this.matchedWirelessAddress) {
            this.matchedWirelessAddress.net_packages.map((netPackage: NetPackages) => {
                netPackage.ext_services.map((extService: ExtendServices) => {
                    extService.is_selected = false;
                });
            });
        }
    }

    unsetTvTarif(): void {
        if (this.choosenTelevisionTarif) {
            if (this.matchedConnectedRuianAddress) {
                this.matchedConnectedRuianAddress.tv_services?.map((tvService: TvServices) => {
                    tvService.ext_services.map((extService: ExtendServices) => {
                        extService.is_selected = false;
                    });
                });
            }

            if (this.matchedConnectedFieldAddress) {
                this.matchedConnectedFieldAddress.tv_services?.map((tvService: TvServices) => {
                    tvService.ext_services.map((extService: ExtendServices) => {
                        extService.is_selected = false;
                    });
                });
            }

            if (this.matchedWirelessAddress) {
                this.matchedWirelessAddress.tv_services.map((tvService: TvServices) => {
                    tvService.ext_services.map((extService: ExtendServices) => {
                        extService.is_selected = false;
                    });
                });
            }

            this.smartTvForm.reset();
        }
    }

    showHint(hint: string): void {
        this.hintText = hint;
    }

    // TODO refactor
    findLocation(address): void {
        /* if (!this.geocoder) {
            this.geocoder = new google.maps.Geocoder();
        }

        this.geocoder.geocode(
            {
                address,
            },
            async (results, status) => {
                if (status === google.maps.GeocoderStatus.OK) {
                    // eslint-disable-next-line @typescript-eslint/prefer-for-of
                    for (let i = 0; i < results[0].address_components.length; i++) {
                        const { types } = results[0].address_components[i];

                        if (types.indexOf('locality') !== -1) {
                            this.location.address_level_2 =
                                results[0].address_components[i].long_name;
                        }

                        if (types.indexOf('country') !== -1) {
                            this.location.address_country =
                                results[0].address_components[i].long_name;
                        }

                        if (types.indexOf('postal_code') !== -1) {
                            this.location.address_zip = results[0].address_components[i].long_name;
                        }

                        if (types.indexOf('administrative_area_level_1') !== -1) {
                            this.location.address_state =
                                results[0].address_components[i].long_name;
                        }
                    }

                    if (this.location.marker && results[0].geometry.location) {
                        this.location.lat = results[0].geometry.location.lat();
                        this.location.lng = results[0].geometry.location.lng();
                        this.location.marker.lat = results[0].geometry.location.lat();
                        this.location.marker.lng = results[0].geometry.location.lng();
                        this.location.marker.draggable = true;
                        this.location.viewport = results[0].geometry.viewport;
                    }
                }
                else {
                    alert('Tuto adresu nelze najít na Google mapě.');
                }
            }
        );*/
    }

    showAddressInputErrors(): void {
        this.addressForm.markAllAsTouched();
        this.ref.markForCheck();
    }

    showWifiInputErrors(): void {
        this.wifiForm.markAllAsTouched();
        this.ref.markForCheck();
    }

    showClientInputErrors(): void {
        this.clientForm.markAllAsTouched();
        this.ref.markForCheck();
    }

    resetAddressForm(): void {
        console.info('reset address');
        this.addressForm.reset();
        this.lead = null;
        this.isDirty = false;
        this.callwizardService.unsetAll();
    }

    resetWifiForm(): void {
        this.wifiPrice = {period: this.feePeriod, fee: 0, price: 0, discount: 0};
        this.wifiPriceAnnualy = 0;
        this.activeWifiSets = false;
        this.matchedWifiSets = [];
        this.choosenWiFiServices = null;
        this.wifiForm.controls.own_router.reset();
        this.wifiForm.controls.basics.reset();
        this.wifiForm.controls.garden.reset();
        this.wifiForm.controls.cabels.reset();
        this.wifiForm.controls.roaming.reset();
        this.wifiForm.controls.unit_id.reset(null, {emitEvent: false});
        this.wifiForm.controls.space_id.reset(null, {emitEvent: false});
        this.wifiForm.controls.speed_id.reset(null, {emitEvent: false});

        this.wifiForm.controls.own_router.patchValue(false);
        this.wifiForm.controls.basics.patchValue(false);
        this.wifiForm.controls.garden.patchValue(false);
        this.wifiForm.controls.cabels.patchValue(false);
        this.wifiForm.controls.roaming.patchValue(false);

        this.callwizardService.reCountTotalFee();
    }

    resetClientForm(): void {
        this.clientForm.reset();
        this.clientForm.controls.sendInvoicesByPost.patchValue(false);
        this.clientForm.controls.sendInvoiceByEmail.patchValue(true);
        this.clientForm.controls.invoiceByYear.patchValue(false);
        this.clientForm.controls.vipInstall.patchValue(false);
        this.callwizardService.reCountTotalFee();
    }

    // eslint-disable-next-line complexity
    async setLead(lead: Leads): Promise<void> {
        this.lead = lead;
        this.callwizardService.unsetAll();
        this.addressForm.controls.cwAddress.patchValue(lead.address, {
            emitEvent: true,
        });
        this.addressForm.markAllAsTouched();
        this.addressForm.markAsDirty();
        this.addressForm.updateValueAndValidity();
        // Address data from lead
        this.addressObj.searchedAddress = lead.address;
        this.addressObj.ruianCode = lead.ruian ? lead.ruian : null;
        this.addressObj.houseNumber = lead.address_obj.house_number;
        this.addressObj.orientalNumber = lead.address_obj.house_number;
        this.addressObj.street = lead.address_obj.street;
        this.addressObj.streetCode = lead.address_obj.street_code
            ? parseInt(lead.address_obj.street_code, 10)
            : null;
        this.addressObj.cityPart = lead.address_obj.city_part;
        this.addressObj.partCode = lead.address_obj.city_part_code
            ? parseInt(lead.address_obj.city_part_code, 10)
            : null;
        this.addressObj.city = lead.address_obj.city;
        this.addressObj.cityCode = lead.address_obj.city_code
            ? parseInt(lead.address_obj.city_code, 10)
            : null;
        this.addressObj.district = lead.address_obj.district;
        this.addressObj.districtCode = lead.address_obj.district_code
            ? parseInt(lead.address_obj.district_code, 10)
            : null;
        this.addressObj.region = lead.address_obj.region;
        this.addressObj.regionCode = lead.address_obj.region_code
            ? parseInt(lead.address_obj.region_code, 10)
            : null;
        this.addressObj.jstk = lead.address_obj.jstk;
        this.addressObj.gps = lead.address_obj.gps;
        this.addressObj.zip = lead.address_obj.zip;
        this.ref.markForCheck();

        // eslint-disable-next-line complexity
        await this.callwizardService.setAddress(this.addressObj)
            .then(() => {
                this.lastLeadUpdate = moment(this.lead?.updated_date)
                    .format('YYYY-MM-DD HH:mm:ss');
                this.choosenInternetTarif = this.lead?.net_package;
                this.choosenExtendedServices =
                    this.lead?.ext_services && this.lead.ext_services.length > 0
                        ? this.lead.ext_services
                        : [];

                if (this.matchedConnectedRuianAddress) {
                    this.matchedConnectedRuianAddress.tv_services?.map((tvService: TvServices) => {
                        if (this.lead?.tv_service && this.lead.tv_service.id === tvService.id) {
                            this.choosenTelevisionTarif = tvService;
                            this.choosenTelevisionExtensions = [];

                            if (this.lead.tv_packages) {
                                this.lead.tv_packages.map((leadTvPackage: TvPackages) => {
                                    if (!leadTvPackage.extension) {
                                        this.choosenTelevisionPackages = tvService.tv_packages?.find(
                                            tvPackage => tvPackage.id === leadTvPackage.id,
                                        );
                                    }

                                    if (leadTvPackage.extension) {
                                        const tvPackageTemp = tvService.tv_packages?.find(
                                            tvPackage => tvPackage.id === leadTvPackage.id,
                                        );

                                        if (tvPackageTemp) {
                                            this.choosenTelevisionExtensions?.push(tvPackageTemp);
                                        }
                                    }
                                });
                            }

                            tvService.ext_services.map((extService: ExtendServices) => {
                                extService.is_selected = !!this.lead?.ext_services?.find(
                                    service => service.id === extService.id,
                                );
                            });
                        }
                    });

                    if (this.choosenInternetTarif) {
                        this.matchedConnectedRuianAddress.net_packages.map(
                            (netPackage: NetPackages) => {
                                if (
                                    this.choosenInternetTarif instanceof NetPackages &&
                                    netPackage.id === this.choosenInternetTarif.id
                                ) {
                                    netPackage.ext_services.map((extService: ExtendServices) => {
                                        extService.is_selected = !!this.lead?.ext_services?.find(
                                            service => service.id === extService.id,
                                        );
                                    });
                                }
                            },
                        );
                    }
                }

                if (this.matchedConnectedFieldAddress) {
                    this.matchedConnectedFieldAddress.tv_services?.map((tvService: TvServices) => {
                        if (this.lead?.tv_service && this.lead.tv_service.id === tvService.id) {
                            this.choosenTelevisionTarif = tvService;
                            this.choosenTelevisionExtensions = [];

                            if (this.lead.tv_packages) {
                                this.lead.tv_packages.map((leadTvPackage: TvPackages) => {
                                    if (!leadTvPackage.extension) {
                                        this.choosenTelevisionPackages = tvService.tv_packages?.find(
                                            tvPackage => tvPackage.id === leadTvPackage.id,
                                        );
                                    }

                                    if (leadTvPackage.extension) {
                                        const tvServiceTemp = tvService.tv_packages?.find(
                                            tvPackage => tvPackage.id === leadTvPackage.id,
                                        );

                                        if (tvServiceTemp) {
                                            this.choosenTelevisionExtensions?.push(tvServiceTemp);
                                        }
                                    }
                                });
                            }

                            tvService.ext_services.map((extService: ExtendServices) => {
                                extService.is_selected = !!this.lead?.ext_services?.find(
                                    service => service.id === extService.id,
                                );
                            });
                        }
                    });

                    if (this.choosenInternetTarif) {
                        this.matchedConnectedFieldAddress.net_packages.map(
                            (netPackage: NetPackages) => {
                                if (
                                    this.choosenInternetTarif instanceof NetPackages &&
                                    netPackage.id === this.choosenInternetTarif.id
                                ) {
                                    netPackage.ext_services.map((extService: ExtendServices) => {
                                        extService.is_selected = !!this.lead?.ext_services?.find(
                                            service => service.id === extService.id,
                                        );
                                    });
                                }
                            },
                        );
                    }
                }

                if (this.matchedWirelessAddress) {
                    this.matchedWirelessAddress.tv_services.map((tvService: TvServices) => {
                        if (this.lead?.tv_service && this.lead.tv_service.id === tvService.id) {
                            this.choosenTelevisionTarif = tvService;
                            this.choosenTelevisionExtensions = [];

                            if (this.lead.tv_packages) {
                                this.lead.tv_packages.map((leadTvPackage: TvPackages) => {
                                    if (!leadTvPackage.extension) {
                                        this.choosenTelevisionPackages = tvService.tv_packages?.find(
                                            tvPackage => tvPackage.id === leadTvPackage.id,
                                        );
                                    }

                                    if (leadTvPackage.extension) {
                                        const tvPackageTemp = tvService.tv_packages?.find(
                                            tvPackage => tvPackage.id === leadTvPackage.id,
                                        );

                                        if (tvPackageTemp) {
                                            this.choosenTelevisionExtensions?.push(tvPackageTemp);
                                        }
                                    }
                                });
                            }

                            tvService.ext_services.map((extService: ExtendServices) => {
                                extService.is_selected = !!this.choosenTelevisionExtensions?.find(
                                    service => service.id === extService.id,
                                );
                            });
                        }
                    });

                    if (this.choosenInternetTarif) {
                        this.matchedWirelessAddress.net_packages.map((netPackage: NetPackages) => {
                            if (
                                this.choosenInternetTarif instanceof NetPackages &&
                                netPackage.id === this.choosenInternetTarif.id
                            ) {
                                netPackage.ext_services.map((extService: ExtendServices) => {
                                    extService.is_selected = !!this.lead?.ext_services?.find(
                                        service => service.id === extService.id,
                                    );
                                });
                            }
                        });
                    }
                }

                this.wifiForm.controls.own_router.patchValue(!!this.lead?.wicon_own_router);
                this.wifiForm.controls.basics.patchValue(!!this.lead?.wicon_basics);
                this.wifiForm.controls.garden.patchValue(!!this.lead?.wicon_garden);
                this.wifiForm.controls.cabels.patchValue(!!this.lead?.wicon_cabels);
                this.wifiForm.controls.roaming.patchValue(!!this.lead?.wicon_roaming);
                this.wifiForm.controls.unit_id.patchValue(this.lead?.unit_id);
                this.wifiForm.controls.space_id.patchValue(this.lead?.space_id);
                this.wifiForm.controls.speed_id.patchValue(this.lead?.speed_id);

                const hwConfig = this.hardwareConfigs.find(
                    hw =>
                        hw.hardware_id === this.lead?.hw_id &&
                        hw.garden === this.lead.wicon_garden &&
                        hw.basics === this.lead.wicon_basics &&
                        hw.cabels === this.lead.wicon_cabels &&
                        hw.roaming === this.lead.wicon_roaming &&
                        hw.space_id === this.lead.space_id &&
                        hw.speed_id === this.lead.speed_id &&
                        hw.unit_id === this.lead.unit_id,
                );

                this.choosenWiFiServices = hwConfig ? hwConfig : null;
                this.purchaseWifi = this.lead?.wifi_price ? 'purchase' : 'fee';
                this.clientForm.controls.firstname.patchValue(this.lead?.cl_firstname);
                this.clientForm.controls.lastname.patchValue(this.lead?.cl_lastname);
                this.clientForm.controls.companyId.patchValue(this.lead?.provider_id);
                this.clientForm.controls.email.patchValue(this.lead?.cl_email);
                this.clientForm.controls.billingEmail.patchValue(this.lead?.cl_billing_email);
                this.clientForm.controls.technicalEmail.patchValue(this.lead?.cl_technical_email);
                this.clientForm.controls.businessEmail.patchValue(this.lead?.cl_business_email);
                this.clientForm.controls.telephone.patchValue(this.lead?.cl_telephone);
                this.clientForm.controls.mobile.patchValue(this.lead?.cl_mobilephone);
                this.clientForm.controls.address.patchValue(this.lead?.cl_address);
                this.clientForm.controls.vatId.patchValue(this.lead?.cl_vat_id);
                this.clientForm.controls.deal_id.patchValue(this.lead?.deal_id);
                this.clientForm.controls.nationalIdNumber.patchValue(this.lead?.cl_national_id_number);
                this.clientForm.controls.accountNumber.patchValue(this.lead?.cl_account_number);
                this.clientForm.controls.bankNumber.patchValue(this.lead?.cl_bank_number);
                this.clientForm.controls.billingAddress.patchValue(this.lead?.cl_billing_address);
                this.clientForm.controls.sendInvoicesByPost.patchValue(
                    this.lead?.cl_send_invoices_by_post,
                );

                this.clientForm.controls.sendInvoiceByEmail.patchValue(
                    this.lead?.cl_send_invoice_by_email,
                );
                this.clientForm.controls.invoiceByYear.patchValue(this.lead?.invoice_by_year);
                this.clientForm.controls.vipInstall.patchValue(this.lead?.vip_install);
                this.clientForm.controls.internalNote.patchValue(this.lead?.internal_note);

                while (this.tvsList.length) {
                    this.tvsList.removeAt(0);
                }

                const tvs: SmartTvInterface[] = [];

                this.lead?.tvs_list.map((tv: SmartTvInterface) => {
                    tvs.push({
                        tv: tv.tv,
                        smart: tv.smart,
                        settopbox: tv.settopbox,
                        settopbox_fee: tv.settopbox_fee,
                    });
                });

                tvs.forEach(tv => {
                    this.tvsList.push(this.createTv(tv));
                });
                this.smartTvForm.get('tvs')
                    ?.setValue(tvs, {onlySelf: true, emitEvent: false});

                this.totalPrice.price = this.lead?.total_price ? this.lead.total_price : 0;
                this.totalPrice.fee = this.lead?.total_fee ? this.lead.total_fee : 0;
                this.internetPrice.price = this.lead?.internet_price ? this.lead.internet_price : 0;
                this.internetPrice.fee = this.lead?.internet_fee ? this.lead.internet_fee : 0;
                this.televisionPrice.price = this.lead?.television_price
                    ? this.lead.television_price
                    : 0;
                this.televisionPrice.fee = this.lead?.television_fee ? this.lead.television_fee : 0;
                this.extServicesPrice.price = this.lead?.ext_services_price
                    ? this.lead.ext_services_price
                    : 0;

                this.extServicesPrice.fee = this.lead?.ext_services_fee
                    ? this.lead.ext_services_fee
                    : 0;
                this.wifiPrice.price = this.lead?.wifi_price ? this.lead.wifi_price : 0;
                this.wifiPrice.fee = this.lead?.wifi_fee ? this.lead.wifi_fee : 0;
                this.vipinstallFee = this.lead?.vip_install ? 500 : 0;
                this.feePeriod = this.lead?.invoice_by_year ? 12 : 1;

                if (
                    this.choosenTelevisionTarif?.tv_packages &&
                    this.choosenTelevisionTarif.tv_packages.length > 0
                ) {
                    const promises: Promise<TvPackages | undefined>[] = [];

                    this.choosenTelevisionTarif.tv_packages.forEach((tvPackage: TvPackages) => {
                        const packageObj = this.dataNetbaseService
                            .getTvPackageData(tvPackage.id)
                            .toPromise();

                        promises.push(packageObj);
                    });

                    void Promise.all(promises)
                        .then((result: TvPackages[]) => {
                            if (this.choosenTelevisionTarif && result.length > 0) {
                                this.choosenTelevisionTarif.tv_packages = result;
                                this.choosenTelevisionTarif.tv_packages =
                                    this.choosenTelevisionTarif.tv_packages.sort((a, b) => {
                                        const valueA = a.sale_order;
                                        const valueB = b.sale_order;

                                        return valueA < valueB ? -1 : 1;
                                    });
                            }

                            this.ref.markForCheck();
                        });
                }

                this.clientForm.markAllAsTouched();
                this.wifiForm.markAllAsTouched();
                this.smartTvForm.markAllAsTouched();
                this.clientForm.markAsDirty();
                this.wifiForm.markAsDirty();
                this.smartTvForm.markAsDirty();
                this.clientForm.updateValueAndValidity();
                this.wifiForm.updateValueAndValidity();
                this.smartTvForm.updateValueAndValidity();
                this.isDirty = true;
                this.callwizardService.reCountTotalFee();
            });
    }

    compare(): void {
        this.renderCompareDialog = true;
    }

    async searchCRMClientByUName(searchTerm: string): Promise<boolean> {
        return new Promise(resolve => {
            const queryString = `&_method=GET&filter[field]=contact&filter[operator]=eq&filter[value]=${searchTerm}`;

            this.apisService
                .getRemoteData(30, [], false, queryString)
                .pipe(untilDestroyed(this))
                .subscribe(
                    (dataCRM: unknown) => {
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-expect-error
                        if (dataCRM.result) {
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-expect-error
                            if (dataCRM.result.total > 0) {
                                resolve(true);
                            } else {
                                resolve(false);
                            }
                        } else {
                            resolve(false);
                        }
                    },
                    () => {
                        resolve(false);
                    },
                );
        });
    }

    createLead(): void {
        if (this.lead?.address) {
            this.servicesService
                .createLead(this.lead)
                .pipe(untilDestroyed(this))
                .subscribe(
                    // eslint-disable-next-line complexity
                    async (lead: Leads): Promise<void> => {
                        let provider: string;
                        let tvsStr = '';
                        const extServicesStr: string[] = [];
                        const tvPackagesStr: string[] = [];

                        if (lead.ext_services && lead.ext_services.length > 0) {
                            lead.ext_services.forEach((service: ExtendServices) => {
                                extServicesStr.push(service.name);
                            });
                        }

                        if (lead.tv_packages && lead.tv_packages.length > 0) {
                            lead.tv_packages.forEach((tvPackage: TvPackages) => {
                                tvPackagesStr.push(tvPackage.name);
                            });
                        }

                        if (lead.tvs_list.length > 0) {
                            lead.tvs_list.map(tv => {
                                if (tv.tv) {
                                    if (tv.settopbox) {
                                        tvsStr += `${tv.tv as string} | SETTOPBOX: ANO${
                                            tv.settopbox_fee ? ' - pronájem, ' : ' - odkup, '
                                        }`;
                                    } else {
                                        tvsStr += `${
                                            tv.tv as string
                                        } | SETTOPBOX: VLASTNÍ / SMART-TV, `;
                                    }
                                }
                            });
                        }

                        if (lead.provider_id === 2) {
                            provider = 'categories_60a2225069def383002306';
                        } else if (lead.provider_id === 4) {
                            provider = 'categories_60a2273c8517c798736721';
                        } else {
                            provider = 'categories_60a2225069def383002306';
                        }

                        let crmName = false;

                        if (lead.cl_firstname && lead.cl_lastname && lead.cl_cnumber > 0) {
                            crmName = await this.searchCRMClientByUName(
                                `${`${lead.cl_firstname}_${lead.cl_lastname}`.toLowerCase()}_${
                                    lead.cl_cnumber
                                }`,
                            );
                        }

                        const queryString = `${
                            lead.crm_id ? `${lead.crm_id}.json` : ''
                        }?accessToken=b6794a70d418fdc92848c50cda389926b3c1b572${
                            lead.crm_id ? '&_method=PUT' : '&_method=POST'
                            // eslint-disable-next-line max-len
                        }&title=Nový lead z CallWizard JONSystemu&category=${provider}&statuses[status]=statuses_5c7ce2a8e932a153645550&stage=WAIT&priority=MEDIUM&description=Operátor na callwizardu JONSystem právě přidal nový lead&user=${
                            // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                            this.currentUser?.crmApiUser_data?.name
                                ? (this.currentUser.crmApiUser_data.name as string)
                                : 'admin'
                        }${
                            crmName
                                ? `&contact=${
                                    lead.cl_firstname && lead.cl_lastname && lead.cl_cnumber > 0
                                        ? `${`${lead.cl_firstname}_${lead.cl_lastname}`.toLowerCase()}_${
                                            lead.cl_cnumber
                                        }`
                                        : ''
                                }`
                                : ''
                        }&customFields[adresa_sluzby]=${
                            lead.address ? lead.address : ''
                        }&customFields[jmeno_zakaznika]=${
                            lead.cl_firstname ? `${lead.cl_firstname} ` : ''
                        }${
                            lead.cl_lastname ? lead.cl_lastname : ''
                        }&customFields[klientske_cislo]=${
                            lead.cl_cnumber
                        }&customFields[id_uzivatele_z_isp]=${
                            lead.stmnt_id ? lead.stmnt_id : ''
                        }&customFields[proklik_do_isp]=${
                            this.clickedRow?.[0]?.isp_id
                                ? `https://isp.jon.cz/client-informations/?clientId=${this.clickedRow[0].isp_id}`
                                : ''
                        }&customFields[bilance]=${
                            this.clickedRow?.[0]?.balance ? this.clickedRow[0].balance : 0
                        }&customFields[active_client_services]=${
                            this.lead?.cl_active_services && this.lead.cl_active_services.length > 0
                                ? this.lead.cl_active_services.toString()
                                : ''
                        }&customFields[email]=${
                            lead.cl_email ? lead.cl_email : ''
                        }&customFields[fakturacni_email]=${
                            lead.cl_billing_email ? lead.cl_billing_email : ''
                        }&customFields[address]=${
                            lead.cl_address ? lead.cl_address : ''
                        }&customFields[fakturacni_adresa]=${
                            lead.cl_billing_address ? lead.cl_billing_address : ''
                        }&customFields[number]=${
                            lead.cl_telephone
                                ? lead.cl_telephone
                                : lead.cl_mobilephone
                                    ? lead.cl_mobilephone
                                    : ''
                        }&customFields[ico]=${
                            lead.cl_vat_id ? lead.cl_vat_id : ''
                        }&customFields[zasilani_faktury]=${
                            lead.cl_send_invoices_by_post ? 'poštou' : 'e-mailem'
                        }&customFields[bankovni_ucet]=${
                            lead.cl_account_number ? lead.cl_account_number : ''
                        }${
                            lead.cl_bank_number ? `/${lead.cl_bank_number}` : ''
                        }&customFields[tarif_internet]=${
                            lead.net_package?.name ? lead.net_package.name : ''
                        }&customFields[tarif_tv]=${
                            lead.tv_service?.name ? lead.tv_service.name : ''
                        }&customFields[tv_balicky]=${
                            tvPackagesStr.length > 0 ? tvPackagesStr.toString() : ''
                        }&customFields[hw_balicek]=${
                            lead.hw_package?.name ? lead.hw_package.name : ''
                        }&customFields[rozsirujici_sluzby]=${
                            extServicesStr.length > 0 ? extServicesStr.toString() : ''
                        }&customFields[note]=${
                            lead.internal_note ? lead.internal_note : ''
                        }&customFields[lead_id]=${lead.id ? lead.id : ''}&customFields[order_id]=${
                            lead.orders ? lead.orders.length : ''
                        }&customFields[router]=${
                            lead.wicon_own_router ? 'vlastní' : 'do pronájmu'
                        }&customFields[nazev_televize]=${tvsStr}&customFields[hw_odkup]=${
                            lead.wifi_price ? 'ANO' : 'NE'
                        }&customFields[cena_sluzby]=${
                            lead.wifi_fee ? lead.wifi_fee : lead.wifi_price
                        }&customFields[cena_tv_sluzby]=${
                            lead.television_fee ? lead.television_fee : lead.television_price
                        }&customFields[cena_net_sluzby]=${
                            lead.internet_fee ? lead.internet_fee : lead.internet_price
                        }&customFields[cena_rozsirujicich_sluzeb]=${
                            lead.ext_services_fee ? lead.ext_services_fee : lead.ext_services_price
                        }&customFields[cena_celkem]=${
                            lead.total_fee
                        }&customFields[cena_jednorazova]=${
                            lead.total_price
                        }&customFields[prednostni_instalace]=${
                            lead.vip_install ? 'ANO' : 'NE'
                        }&customFields[smluvni_uvazek]=${
                            lead.deal_type ? lead.deal_type.age : ''
                        }&customFields[rocni_predplatne]=${lead.invoice_by_year ? 'ANO' : 'NE'}`;

                        if (!this.lead?.crm_id) {
                            await this.apisService
                                .getRemoteData(38, [], false, queryString)
                                .pipe(untilDestroyed(this))
                                .subscribe(
                                    (dataCRM: unknown) => {
                                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                        // @ts-expect-error
                                        if (this.lead && dataCRM?.result) {
                                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                            // @ts-expect-error
                                            this.lead.crm_id = dataCRM.result.name;
                                            this.ref.markForCheck();
                                            console.info('CRM ticket created...');
                                        }
                                    },
                                    error => {
                                        console.error(error);
                                    },
                                );
                        } else {
                            await this.apisService
                                .getRemoteData(40, [], false, queryString)
                                .pipe(untilDestroyed(this))
                                .subscribe(
                                    (dataCRM: unknown) => {
                                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                        // @ts-expect-error
                                        if (this.lead && dataCRM?.result) {
                                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                            // @ts-expect-error
                                            this.lead.crm_id = dataCRM.result.name;
                                            this.ref.markForCheck();
                                            console.info('CRM ticket updated...');
                                        }
                                    },
                                    error => {
                                        console.error(error);
                                    },
                                );
                        }

                        this.lastLeadUpdate = moment()
                            .format('YYYY-MM-DD HH:mm:ss');
                        this.ref.markForCheck();
                    },
                    error => {
                        console.error(error);

                        const body = 'Zkuste to znovu později';
                        const options = {
                            progressBar: true,
                            timeOut: 5000,
                            toastClass: 'red',
                        };

                        this.message.showError('Chyba při ukládání leadu', body, options);
                        this.ref.markForCheck();
                    },
                );
        }
    }

    // eslint-disable-next-line complexity
    onUpdateLead(): void {
        this.callwizardService.reCountTotalFee();

        const extServices: number[] = [];
        const tvPackages: number[] = [];
        const activeServices: string[] = [];

        if (this.choosenExtendedServices && this.choosenExtendedServices.length > 0) {
            this.choosenExtendedServices.forEach((service: ExtendServices) => {
                extServices.push(service.id);
            });
        }

        if (this.choosenTelevisionPackages) {
            tvPackages.push(this.choosenTelevisionPackages.id);
        }

        if (this.choosenTelevisionExtensions && this.choosenTelevisionExtensions.length > 0) {
            this.choosenTelevisionExtensions.forEach((tvPackage: TvPackages) => {
                tvPackages.push(tvPackage.id);
            });
        }

        if (this.connectedClientServices.length > 0) {
            this.connectedClientServices.forEach((service: ClientService) => {
                activeServices.push(service.name);
            });
        }

        const lead: LeadInput = {
            id: this.lead?.id ? this.lead.id : uuid.v4(),
            crm_id: this.lead?.crm_id ? this.lead.crm_id : null,
            erp_id: this.lead?.erp_id ? this.lead.erp_id : null, // TODO
            stmnt_id: this.clickedRow?.[0]?.isp_id ? this.clickedRow[0].isp_id : null,
            address: this.addressObj.searchedAddress ? this.addressObj.searchedAddress : null,
            ruian: this.addressObj.ruianCode ? this.addressObj.ruianCode : null,
            oriental_number: this.addressObj.orientalNumber ?? null,
            house_number: this.addressObj.houseNumber ?? null,
            street: this.addressObj.street ?? null,
            street_code: this.addressObj.streetCode ?? null,
            city: this.addressObj.city,
            city_code: this.addressObj.cityCode ?? null,
            city_part: this.addressObj.cityPart ?? null,
            city_part_code: this.addressObj.partCode ?? null,
            district: this.addressObj.districtCode ?? null,
            district_code: this.addressObj.districtCode ?? null,
            region: this.addressObj.region,
            region_code: this.addressObj.regionCode ?? null,
            state: this.addressObj.state,
            gps: this.addressObj.gps ?? null,
            jstk: this.addressObj.jstk ?? null,
            zip: this.addressObj.zip,
            cl_firstname: this.clientFormCtrl.firstname.value
                ? this.clientFormCtrl.firstname.value
                : null,
            cl_lastname: this.clientFormCtrl.lastname.value
                ? this.clientFormCtrl.lastname.value
                : null,
            cl_email: this.clientFormCtrl.email.value ? this.clientFormCtrl.email.value : null,
            // eslint-disable-next-line max-len
            cl_billing_email: this.clientFormCtrl.billingEmail.value
                ? this.clientFormCtrl.billingEmail.value
                : null,
            // eslint-disable-next-line max-len
            cl_technical_email: this.clientFormCtrl.technicalEmail.value
                ? this.clientFormCtrl.technicalEmail.value
                : null,
            // eslint-disable-next-line max-len
            cl_business_email: this.clientFormCtrl.businessEmail.value
                ? this.clientFormCtrl.businessEmail.value
                : null,
            cl_telephone: this.clientFormCtrl.telephone.value
                ? this.clientFormCtrl.telephone.value
                : null,
            // eslint-disable-next-line max-len
            cl_mobilephone: this.clientFormCtrl.mobile.value
                ? this.clientFormCtrl.mobile.value
                : null,
            // eslint-disable-next-line max-len
            cl_account_number: this.clientFormCtrl.accountNumber.value
                ? parseInt(this.clientFormCtrl.accountNumber.value as string, 10)
                : null,
            cl_bank_number: this.clientFormCtrl.bankNumber.value
                ? parseInt(this.clientFormCtrl.bankNumber.value as string, 10)
                : null,
            cl_address: this.clientFormCtrl.address.value
                ? this.clientFormCtrl.address.value
                : null,
            // eslint-disable-next-line max-len
            cl_billing_address: this.clientFormCtrl.billingAddress.value
                ? this.clientFormCtrl.billingAddress.value
                : null,
            cl_vat_id: this.clientFormCtrl.vatId.value > 0 ? this.clientFormCtrl.vatId.value : null,
            // eslint-disable-next-line max-len
            cl_national_id_number: this.clientFormCtrl.nationalIdNumber.value
                ? this.clientFormCtrl.nationalIdNumber.value
                : null,
            // eslint-disable-next-line max-len
            cl_send_invoices_by_post: this.clientFormCtrl.sendInvoicesByPost.value
                ? this.clientFormCtrl.sendInvoicesByPost.value
                : false,
            // eslint-disable-next-line max-len
            cl_send_invoice_by_email: this.clientFormCtrl.sendInvoiceByEmail.value
                ? this.clientFormCtrl.sendInvoiceByEmail.value
                : true,
            cl_cnumber: this.clickedRow?.[0]?.client_number
                ? parseInt(this.clickedRow[0].client_number, 10)
                : 0,
            cl_isplink: this.clickedRow?.[0]?.isp_id
                ? `https://isp.jon.cz/client-informations/?clientId=${this.clickedRow[0].isp_id}`
                : '',
            cl_bilance: this.clickedRow?.[0]?.balance ? this.clickedRow[0].balance : 0,
            cl_active_services: activeServices.length > 0 ? activeServices : [],
            // eslint-disable-next-line max-len
            invoice_by_year: this.clientFormCtrl.invoiceByYear.value
                ? this.clientFormCtrl.invoiceByYear.value
                : false,
            vip_install: this.clientFormCtrl.vipInstall.value
                ? this.clientFormCtrl.vipInstall.value
                : false,
            internal_note: this.clientFormCtrl.internalNote.value
                ? this.clientFormCtrl.internalNote.value
                : null,
            tvs: this.tvsList.value ? JSON.stringify(this.tvsList.value) : null,
            wicon_own_router: !!this.wifiFormCtrl.own_router.value,
            wicon_basics: this.wifiFormCtrl.basics.value ? this.wifiFormCtrl.basics.value : false,
            wicon_garden: this.wifiFormCtrl.garden.value ? this.wifiFormCtrl.garden.value : false,
            wicon_cabels: this.wifiFormCtrl.cabels.value ? this.wifiFormCtrl.cabels.value : false,
            wicon_roaming: this.wifiFormCtrl.roaming.value
                ? this.wifiFormCtrl.roaming.value
                : false,
            operator_id: this.currentUser?.id,
            type_id: 1,
            provider_id: this.clientFormCtrl.companyId.value
                ? this.clientFormCtrl.companyId.value
                : this.choosenInternetTarif instanceof NetPackages &&
                this.choosenInternetTarif.provider_id
                    ? this.choosenInternetTarif.provider_id
                    : this.matchedConnectedRuianAddress
                        ? this.matchedConnectedRuianAddress.provider_id
                        : null,
            deal_id: this.clientFormCtrl.deal_id.value
                ? parseInt(this.clientFormCtrl.deal_id.value as string, 10)
                : null,
            deal_age: this.clientFormCtrl.deal_id.value
                ? this.dealAge
                    ? this.dealAge
                    : null
                : null,
            unit_id: this.wifiFormCtrl.unit_id.value
                ? parseInt(this.wifiFormCtrl.unit_id.value as string, 10)
                : null,
            space_id: this.wifiFormCtrl.space_id.value
                ? parseInt(this.wifiFormCtrl.space_id.value as string, 10)
                : null,
            speed_id: this.wifiFormCtrl.speed_id.value
                ? parseInt(this.wifiFormCtrl.speed_id.value as string, 10)
                : null,
            hw_id: this.choosenWiFiServices ? this.choosenWiFiServices.hardware_id : null,
            net_package_id:
                this.choosenInternetTarif instanceof NetPackages
                    ? this.choosenInternetTarif.id
                    : null,
            tv_service_id: this.choosenTelevisionTarif ? this.choosenTelevisionTarif.id : null,
            ext_services: extServices.length > 0 ? extServices : null,
            total_price: this.totalPrice.price ? this.totalPrice.price : 0,
            total_fee: this.totalPrice.fee ? this.totalPrice.fee : 0,
            internet_price: this.internetPrice.price ? this.internetPrice.price : 0,
            internet_fee: this.internetPrice.fee ? this.internetPrice.fee : 0,
            television_price: this.televisionPrice.price ? this.televisionPrice.price : 0,
            television_fee: this.televisionPrice.fee ? this.televisionPrice.fee : 0,
            ext_services_price: this.extServicesPrice.price ? this.extServicesPrice.price : 0,
            ext_services_fee: this.extServicesPrice.fee ? this.extServicesPrice.fee : 0,
            wifi_price: this.wifiPrice.price ? this.wifiPrice.price : 0,
            wifi_fee: this.wifiPrice.fee ? this.wifiPrice.fee : 0,
            tv_packages: tvPackages.length > 0 ? tvPackages : null,
            status: 'wait',
            client_status:
                this.clickedRow?.[0]?.client_number &&
                this.clickedRow[0] &&
                this.clickedRow[0].client_number
                    ? 'existing_client'
                    : 'new_client',
        };

        this.lead = lead as unknown as Leads;

        if (!this.lead.provider) {
            const provider = this.companies.find(company => company.id === this.lead?.provider_id);

            if (provider) {
                this.lead.provider = provider;
            }
        }

        this.createLead();
    }

    onSubmit(): void {
        this.submited = true;

        if (this.addressForm.invalid) {
            console.error('form is not valid!');

            return;
        }

        if (this.smartTvForm.invalid) {
            console.error('form is not valid!');

            return;
        }

        if (this.wifiForm.invalid) {
            console.error('form is not valid!');

            return;
        }

        if (this.clientForm.invalid) {
            console.error('form is not valid!');

            return;
        }

        if (!this.lead) {
            console.error('this.lead is missing...');

            return;
        }

        this.loadingLead = true;
        this.lead.status = 'close';
        this.lead.client_status =
            this.lead.stmnt_id && this.lead.cl_cnumber > 0 ? 'existing_client' : 'new_client';
        this.createLead();

        this.lead.status = 'wait';
        this.servicesService
            .createOrder(this.lead as unknown as Orders)
            .pipe(untilDestroyed(this))
            .subscribe(
                // eslint-disable-next-line complexity
                async (order: Orders) => {
                    let provider: string;
                    let tvsStr = '';
                    const extServicesStr: string[] = [];
                    const tvPackagesStr: string[] = [];

                    if (order.ext_services && order.ext_services.length > 0) {
                        order.ext_services.forEach((service: ExtendServices) => {
                            extServicesStr.push(service.name);
                        });
                    }

                    if (order.tv_packages && order.tv_packages.length > 0) {
                        order.tv_packages.forEach((tvPackage: TvPackages) => {
                            tvPackagesStr.push(tvPackage.name);
                        });
                    }

                    if (order.tvs_list.length > 0) {
                        order.tvs_list.map(tv => {
                            if (tv.settopbox) {
                                tvsStr += `${tv.tv as string} | SETTOPBOX: ANO${
                                    tv.settopbox_fee ? ' - pronájem, ' : ' - odkup, '
                                }`;
                            } else {
                                tvsStr += `${tv.tv as string} | SETTOPBOX: VLASTNÍ / SMART-TV, `;
                            }
                        });
                    }

                    if (order.provider_id === 2) {
                        provider = 'categories_60a254b4cee5e528537128';
                    } else if (order.provider_id === 4) {
                        provider = 'categories_60a254bc8d0b2851127678';
                    } else {
                        provider = 'categories_60a254b4cee5e528537128';
                    }

                    let crmName = false;

                    if (order.cl_firstname && order.cl_lastname && order.cl_cnumber > 0) {
                        crmName = await this.searchCRMClientByUName(
                            `${`${order.cl_firstname}_${order.cl_lastname}`.toLowerCase()}_${
                                order.cl_cnumber
                            }`,
                        );
                    }

                    const queryString = `${
                        order.crm_id ? `${order.crm_id}.json` : ''
                    }?accessToken=b6794a70d418fdc92848c50cda389926b3c1b572${
                        order.crm_id ? '&_method=PUT' : '&_method=POST'
                        // eslint-disable-next-line max-len
                    }&title=Nová objednávka z CallWizard JONSystemu&category=${provider}&statuses[status]=statuses_5c7ce2a8e932a153645550&stage=WAIT&priority=MEDIUM&description=Operátor na callwizardu JONSystem právě přidal novou objednávku&user=${
                        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                        this.currentUser?.crmApiUser_data?.name
                            ? (this.currentUser.crmApiUser_data.name as string)
                            : 'admin'
                    }${
                        crmName
                            ? `&contact=${
                                order.cl_firstname && order.cl_lastname && order.cl_cnumber > 0
                                    ? `${`${order.cl_firstname}_${order.cl_lastname}`.toLowerCase()}_${
                                        order.cl_cnumber
                                    }`
                                    : ''
                            }`
                            : ''
                    }&customFields[adresa_sluzby]=${
                        order.address ? order.address : ''
                    }&customFields[jmeno_zakaznika]=${
                        order.cl_firstname ? `${order.cl_firstname} ` : ''
                    }${order.cl_lastname ? order.cl_lastname : ''}&customFields[klientske_cislo]=${
                        order.cl_cnumber
                    }&customFields[id_uzivatele_z_isp]=${
                        order.stmnt_id ? order.stmnt_id : ''
                    }&customFields[proklik_do_isp]=${
                        this.clickedRow?.[0]?.isp_id
                            ? `https://isp.jon.cz/client-informations/?clientId=${this.clickedRow[0].isp_id}`
                            : ''
                    }&customFields[bilance]=${
                        this.clickedRow?.[0]?.balance ? this.clickedRow[0].balance : 0
                    }&customFields[active_client_services]=${
                        this.lead?.cl_active_services && this.lead.cl_active_services.length > 0
                            ? this.lead.cl_active_services.toString()
                            : ''
                    }&customFields[email]=${
                        order.cl_email ? order.cl_email : ''
                    }&customFields[fakturacni_email]=${
                        order.cl_billing_email ? order.cl_billing_email : ''
                    }&customFields[address]=${
                        order.cl_address ? order.cl_address : ''
                    }&customFields[fakturacni_adresa]=${
                        order.cl_billing_address ? order.cl_billing_address : ''
                    }&customFields[number]=${
                        order.cl_telephone
                            ? order.cl_telephone
                            : order.cl_mobilephone
                                ? order.cl_mobilephone
                                : ''
                    }&customFields[ico]=${
                        order.cl_vat_id ? order.cl_vat_id : ''
                    }&customFields[zasilani_faktury]=${
                        order.cl_send_invoices_by_post ? 'poštou' : 'e-mailem'
                    }&customFields[bankovni_ucet]=${
                        order.cl_account_number ? order.cl_account_number : ''
                    }${
                        order.cl_bank_number ? `/${order.cl_bank_number}` : ''
                    }&customFields[tarif_internet]=${
                        order.net_package?.name ? order.net_package.name : ''
                    }&customFields[tarif_tv]=${
                        order.tv_service?.name ? order.tv_service.name : ''
                    }&customFields[tv_balicky]=${
                        tvPackagesStr.length > 0 ? tvPackagesStr.toString() : ''
                    }&customFields[hw_balicek]=${
                        order.hw_package?.name ? order.hw_package.name : ''
                    }&customFields[rozsirujici_sluzby]=${
                        extServicesStr.length > 0 ? extServicesStr.toString() : ''
                    }&customFields[note]=${
                        order.internal_note ? order.internal_note : ''
                    }&customFields[lead_id]=${
                        order.lead_id ? order.lead_id : ''
                    }&customFields[order_id]=${order.id ? order.id : ''}&customFields[router]=${
                        order.wicon_own_router ? 'vlastní' : 'do pronájmu'
                    }&customFields[nazev_televize]=${tvsStr}&customFields[hw_odkup]=${
                        order.wifi_price ? 'ANO' : 'NE'
                    }&customFields[cena_sluzby]=${
                        order.wifi_fee ? order.wifi_fee : order.wifi_price
                    }&customFields[cena_tv_sluzby]=${
                        order.television_fee ? order.television_fee : order.television_price
                    }&customFields[cena_net_sluzby]=${
                        order.internet_fee ? order.internet_fee : order.internet_price
                    }&customFields[cena_rozsirujicich_sluzeb]=${
                        order.ext_services_fee ? order.ext_services_fee : order.ext_services_price
                    }&customFields[cena_celkem]=${order.total_fee}&customFields[cena_jednorazova]=${
                        order.total_price
                    }&customFields[prednostni_instalace]=${
                        order.vip_install ? 'ANO' : 'NE'
                    }&customFields[smluvni_uvazek]=${
                        order.deal_type ? order.deal_type.age : ''
                    }&customFields[rocni_predplatne]=${order.invoice_by_year ? 'ANO' : 'NE'}`;

                    if (!this.lead?.crm_id) {
                        this.apisService
                            .getRemoteData(38, [], false, queryString)
                            .pipe(untilDestroyed(this))
                            .subscribe(
                                (dataCRM: unknown) => {
                                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                    // @ts-expect-error
                                    if (this.lead && dataCRM?.result) {
                                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                        // @ts-expect-error
                                        this.lead.crm_id = dataCRM.result.name;
                                        this.ref.markForCheck();
                                        console.info('CRM ticket created...');
                                    }
                                },
                                error => {
                                    console.error(error);
                                },
                            );
                    } else {
                        this.apisService
                            .getRemoteData(40, [], false, queryString)
                            .pipe(untilDestroyed(this))
                            .subscribe(
                                (dataCRM: unknown) => {
                                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                    // @ts-expect-error
                                    if (this.lead && dataCRM.result) {
                                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                        // @ts-expect-error
                                        this.lead.crm_id = dataCRM.result.name;
                                        this.ref.markForCheck();
                                        console.info('CRM ticket updated...');
                                    }
                                },
                                error => {
                                    console.error(error);
                                },
                            );
                    }

                    const options = {
                        progressBar: true,
                        timeOut: 5000,
                        toastClass: 'success',
                    };

                    this.message.showSuccess('Objednávka vytvořena', '', options);
                    this.isDirty = false;
                    this.callwizardService.unsetAll();
                    void this.router.navigate(['/services/orders/', order.id]);
                    this.ref.markForCheck();
                },
                error => {
                    console.error(error);

                    const body = 'Zkuste to znovu později';
                    const options = {
                        progressBar: true,
                        timeOut: 5000,
                        toastClass: 'red',
                    };

                    this.message.showError('Chyba při vytváření uživatele', body, options);
                    this.ref.markForCheck();
                },
            );
    }

    createdClients(): void {
        const gridElement = this.gridClients?.element;
        const span = document.createElement('span');

        if (gridElement) {
            span.className = 'e-clear-icon';
            span.id = `${gridElement.id}clear`;
            span.onclick = this.cancelBtnClickClients.bind(this);
            gridElement.querySelector('.e-toolbar-item .e-input-group')
                ?.appendChild(span);
        }
    }

    cancelBtnClickClients(): void {
        if (this.gridClients) {
            this.gridClients.searchSettings.key = '';

            const element: HTMLInputElement | null = this.gridClients.element.querySelector(
                '.e-input-group.e-search .e-input',
            );

            if (element) {
                element.value = '';
            }
        }
    }

    createdServices(): void {
        const gridElement = this.gridServices?.element;
        const span = document.createElement('span');

        if (gridElement) {
            span.className = 'e-clear-icon';
            span.id = `${gridElement.id}clear`;
            span.onclick = this.cancelBtnClickServices.bind(this);
            gridElement.querySelector('.e-toolbar-item .e-input-group')
                ?.appendChild(span);
        }
    }

    cancelBtnClickServices(): void {
        if (this.gridServices) {
            this.gridServices.searchSettings.key = '';

            const element: HTMLInputElement | null = this.gridServices.element.querySelector(
                '.e-input-group.e-search .e-input',
            );

            if (element) {
                element.value = '';
            }
        }
    }

    btnNetSkipClick(): void {
        this.skipNetTarif = true;
        this.choosenInternetTarif = null;
        this.internetPrice = {
            period: this.feePeriod,
            fee: 0,
            price: 0,
            discount: this.discount,
        };
    }

    ngOnDestroy(): void {
        this.servicesService.setLead(null);
    }
}
