import {Injectable} from '@angular/core';
import {filter, map, mergeMap} from 'rxjs/operators';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {Title} from '@angular/platform-browser';

const appTitle = 'JONSystem';
const separator = ' | ';

@Injectable({
    providedIn: 'root',
})
export class TitleService {
    constructor(
        private readonly router: Router,
        private readonly activatedRoute: ActivatedRoute,
        private readonly titleService: Title,
    ) {}

    static ucFirst(text: string): string {
        if (!text) {
            return text;
        }

        return `${text.charAt(0)
            .toUpperCase()} ${text.slice(1)}`;
    }

    init(): void {
        this.router.events
            .pipe(
                filter(event => event instanceof NavigationEnd),
                map(() => {
                    let route = this.activatedRoute;

                    while (route.firstChild) {
                        route = route.firstChild;
                    }

                    return route;
                }),
                filter(route => route.outlet === 'primary'),
                mergeMap(route => route.data),
                map(data => {
                    if (data.title) {
                        // If a route has a title set (e.g. data: {title: "Foo"}) then we use it
                        return data.title as string;
                    }

                    // If not, we do a little magic on the url to create an approximation
                    return this.router.url.split('/')
                        .reduce((acc, frag) => {
                            if (acc && frag) {
                                acc += separator;
                            }

                            return acc + TitleService.ucFirst(frag);
                        });
                }),
            )
            .subscribe(pathString => {
                this.titleService.setTitle(`${appTitle}${separator}${pathString}`);
            });
    }
}
