<ejs-dialog
  #formDialog
  (close)="
        isUpdate = false;
        isCreate = false;
        isDirty = false;
        isChecked = false;
        submited = false;
        changeDialogState(false)
    "
  (open)="onCreateRte()"
  [isModal]="true"
  [showCloseIcon]="true"
  [visible]="isVisible"
  [width]="'85%'"
  id="formDialog"
>
  <form [formGroup]="eventForm">
    <ng-template #content>
      <div class="dialogContent">
        <div class="row">
          <div class="col-12 col-md-6 mb-2 form-group">
            <label
              [ngClass]="
                                f.start_at.valid
                                    ? 'text-success'
                                    : f.start_at.invalid
                                    ? 'text-danger'
                                    : 'text-muted'
                            "
              for="createEventForm-start_at"
            >* Začátek události:</label
            >
            <ejs-datetimepicker
              [max]="f.end_at.value ? f.end_at.value : ''"
              floatLabelType="Always"
              formControlName="start_at"
              id="createEventForm-start_at"
            >
            </ejs-datetimepicker>
            <div class="d-flex justify-content-end">
              <small
                *ngIf="
                                    f.start_at.touched &&
                                    f.start_at.errors &&
                                    f.start_at.errors.required
                                "
                class="text-danger"
              >Datum začátku události je povinné</small
              >
              <small
                *ngIf="
                                    f.start_at.touched &&
                                    f.start_at.errors &&
                                    f.start_at.errors.greaterThan
                                "
                class="text-danger"
              >Datum začátku události je větší než datum
                konce</small
              >
            </div>
          </div>
          <div class="col-12 col-md-6 mb-2 form-group">
            <label
              [ngClass]="
                                f.end_at.valid
                                    ? 'text-success'
                                    : f.end_at.invalid
                                    ? 'text-danger'
                                    : 'text-muted'
                            "
              for="createEventForm-end_at"
            >* Konec události:</label
            >
            <ejs-datetimepicker
              [min]="f.start_at.value ? f.start_at.value : ''"
              floatLabelType="Always"
              formControlName="end_at"
              id="createEventForm-end_at"
            >
            </ejs-datetimepicker>
            <div class="d-flex justify-content-end">
              <small
                *ngIf="
                                    f.end_at.touched &&
                                    f.end_at.errors &&
                                    f.end_at.errors.required
                                "
                class="text-danger"
              >Datum konce události je povinné</small
              >
              <small
                *ngIf="
                                    f.end_at.touched &&
                                    f.end_at.errors &&
                                    f.end_at.errors.lessThan
                                "
                class="text-danger"
              >Datum konce události je menší než datum
                začátku</small
              >
            </div>
          </div>
          <div class="col-12 mb-2 form-group">
            <label
              [ngClass]="
                                f.subject.valid
                                    ? 'text-success'
                                    : f.subject.invalid &&
                                      (f.subject.dirty || f.subject.touched)
                                    ? 'text-danger'
                                    : 'text-muted'
                            "
              for="createEventForm-subject"
            >Předmět:</label
            >
            <ejs-textbox
              [ngClass]="{
                                'e-error': submited && f.subject.errors
                            }"
              floatLabelType="Auto"
              formControlName="subject"
              id="createEventForm-subject"
            ></ejs-textbox>
            <small
              *ngIf="
                                (f.subject.dirty || f.subject.touched) &&
                                f.subject.errors &&
                                f.subject.errors.required
                            "
              class="text-danger"
            >Vyplňte předmět události</small
            >
          </div>
          <div class="col-12 mb-2 form-group">
            <label
              [ngClass]="
                                f.address.valid
                                    ? 'text-success'
                                    : f.address.invalid &&
                                      (f.address.dirty || f.address.touched)
                                    ? 'text-danger'
                                    : 'text-muted'
                            "
              for="createEventForm-address"
            >Adresa:</label
            >
            <ejs-textbox
              [ngClass]="{
                                'e-error': submited && f.address.errors
                            }"
              autocomplete="smartform-auto-address"
              class="smartform-whole-address smartform-instance-create"
              floatLabelType="Auto"
              formControlName="address"
              id="createEventForm-address"
            ></ejs-textbox>
            <small
              *ngIf="
                                (f.address.dirty || f.address.touched) &&
                                f.address.errors &&
                                f.address.errors.required
                            "
              class="text-danger"
            >Vyplňte adresu události</small
            >
          </div>
          <div class="col-12 mb-2 form-group">
            <label
              [ngClass]="
                                f.description.valid
                                    ? 'text-success'
                                    : f.description.invalid &&
                                      (f.description.dirty ||
                                          f.description.touched)
                                    ? 'text-danger'
                                    : 'text-muted'
                            "
              for="createEventForm-description"
            >Popis události:
            </label>
            <ejs-richtexteditor
              #eventDescription
              (actionBegin)="handleFullScreen($event)"
              (actionComplete)="actionCompleteHandler($event)"
              [enablePersistence]="false"
              [fontFamily]="fontFamily"
              [insertImageSettings]="insertImageSettings"
              [maxLength]="maxLength"
              [ngClass]="
                                f.description.touched && f.description.invalid
                                    ? 'e-error'
                                    : f.description.touched &&
                                      f.description.valid
                                    ? 'e-success'
                                    : ''
                            "
              [pasteCleanupSettings]="pasteCleanupSettings"
              [quickToolbarSettings]="quickToolbarSettings"
              [showCharCount]="true"
              [toolbarSettings]="tools"
              formControlName="description"
              id="createEventForm-description"
              locale="cs"
              placeholder="Začněte psát popis události..."
            >
            </ejs-richtexteditor>
            <small
              *ngIf="
                                f.description.touched &&
                                f.description.errors &&
                                f.description.errors.required
                            "
              class="text-danger"
            >Popis události je povinné</small
            >
          </div>
          <div class="col-12 col-md-6 mb-2 form-group">
            <label
              [ngClass]="
                                f.users.valid
                                    ? 'text-success'
                                    : f.users.invalid &&
                                      (f.users.dirty || f.users.touched)
                                    ? 'text-danger'
                                    : 'text-muted'
                            "
              for="createEventForm-users"
            >Zapojit uživatele:</label
            >
            <ejs-multiselect
              #users
              (filtering)="onFilteringUsers($event)"
              [allowFiltering]="true"
              [dataSource]="usersSelect"
              [fields]="fields"
              [filterBarPlaceholder]="'Vyhledat uživatele...'"
              [ignoreAccent]="true"
              [mode]="'CheckBox'"
              [placeholder]="'Vyberte uživatele'"
              [popupHeight]="height"
              [selectAllText]="'Všechny uživatele'"
              formControlName="users"
              id="createEventForm-users"
              showSelectAll="true"
              sortOrder="Ascending"
            ></ejs-multiselect>
            <small
              *ngIf="
                                f.users.touched &&
                                f.users.errors &&
                                f.users.errors.required
                            "
              class="text-danger"
            >Vyberte uživatele</small
            >
          </div>
          <div class="col-12 col-md-6 mb-2 form-group">
            <label
              [ngClass]="
                                f.departments.valid
                                    ? 'text-success'
                                    : f.departments.invalid &&
                                      (f.departments.dirty ||
                                          f.departments.touched)
                                    ? 'text-danger'
                                    : 'text-muted'
                            "
              for="createEventForm-departments"
            >Zapojit oddělení:</label
            >
            <ejs-multiselect
              #departments
              (filtering)="onFilteringDepartments($event)"
              [allowFiltering]="true"
              [dataSource]="departmentSelect"
              [fields]="fields"
              [filterBarPlaceholder]="'Vyhledat oddělení...'"
              [ignoreAccent]="true"
              [mode]="'CheckBox'"
              [placeholder]="'Vyberte oddělení'"
              [popupHeight]="height"
              [selectAllText]="'Všechny oddělení'"
              formControlName="departments"
              id="createEventForm-departments"
              showSelectAll="true"
              sortOrder="Ascending"
            ></ejs-multiselect>
            <small
              *ngIf="
                                f.departments.touched &&
                                f.departments.errors &&
                                f.departments.errors.required
                            "
              class="text-danger"
            >Vyberte oddělení</small
            >
          </div>
          <div class="col-12 mb-2">
            <ejs-checkbox
              #attendCheckBox
              [checked]="false"
              [ngClass]="
                                f.required.valid
                                    ? 'text-success'
                                    : f.required.invalid
                                    ? 'text-danger'
                                    : 'text-muted'
                            "
              cssClass="e-primary p-0 m-0"
              formControlName="required"
              label="Účast povinná"
            ></ejs-checkbox>
          </div>
        </div>
      </div>
    </ng-template>
    <ng-template #footerTemplate>
      <div class="d-flex justify-content-end">
                <span
                  *ngIf="eventForm.invalid"
                  class="e-badge e-badge-warning p-2 size_2"
                  role="alert"
                >
                    <strong>Formulář je špatně vyplněný</strong>
                </span>
        <span
          *ngIf="eventForm.valid"
          class="e-badge e-badge-success p-2 size_2"
          role="alert"
        >
                    <strong>Formulář je správně vyplněný</strong>
                </span>
        <button
          (click)="onSubmit()"
          [disabled]="
                        loadingEvents || eventForm.invalid || !isChecked
                    "
          cssClass="e-btn e-success btn-p-zero e-small mr-1"
          ejs-button
          type="submit"
        >
                    <span
                      *ngIf="loadingEvents"
                      aria-hidden="true"
                      class="spinner-grow spinner-grow-sm"
                      role="status"
                    ></span>
          <span *ngIf="!loadingEvents">ULOŽIT</span>
          <span *ngIf="loadingEvents">Provádím...</span>
        </button>
        <button
          (click)="
                        formDialog.hide(); isDirty = false; isChecked = false
                    "
          cssClass="e-btn e-danger e-outline btn-p-zero e-small"
          ejs-button
          type="button"
        >
          ZRUŠIT
        </button>
      </div>
    </ng-template>
  </form>
</ejs-dialog>
