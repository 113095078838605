<div
  *ngIf="loadingHotline"
  style="
        display: block !important;
        z-index: 1000000;
        width: 250px;
        height: 250px;
        margin: auto;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    "
>
  <div class="loader">
    <span></span>
    <span></span>
    <span></span>
    <span></span>
  </div>
  <div
    class="lds-circle"
    style="
            margin: auto;
            position: absolute;
            width: 150px;
            height: auto;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        "
  >
    <div></div>
  </div>
</div>
<div class="row d-flex justify-content-center modalWrapper">
  <ejs-dialog
    #deleteHotlineDialog
    [buttons]="buttonsDeleteHotlineDialog"
    [content]="'Opravdu chcete smazat označenou hot-line?'"
    [header]="'Smazat hot-line'"
    [height]="'200px'"
    [isModal]="true"
    [showCloseIcon]="true"
    [visible]="false"
    [width]="'450px'"
    id="deleteHotlineDialog"
  ></ejs-dialog>
  <ejs-dialog
    #activateHotlineDialog
    [buttons]="buttonsActivateHotlineDialog"
    [content]="'Opravdu chcete obnovit označenou hot-line?'"
    [header]="'Obnovit hot-line'"
    [height]="'200px'"
    [isModal]="true"
    [showCloseIcon]="true"
    [visible]="false"
    [width]="'450px'"
    id="activateHotlineDialog"
  ></ejs-dialog>
  <ejs-dialog
    #confirmationCancel
    [buttons]="buttonsCancel"
    [header]="'Nelze provést akci'"
    [isModal]="true"
    [showCloseIcon]="true"
    [visible]="false"
    width="250px"
  ></ejs-dialog>
  <app-hotline-form
    (hotlineFormDialogState)="
            renderHotlineFormDialog = $event;
            isCreate = $event;
            isUpdate = $event;
            isCopy = $event;
            gridHotlines?.clearRowSelection();
            disableToolBarBtn()
        "
    [hotline]="
            clickedRow && clickedRow[0] && (isUpdate || isCopy)
                ? clickedRow[0]
                : null
        "
    [isCopy]="isCopy"
    [isCreate]="isCreate"
    [isUpdate]="isUpdate"
    [isVisible]="renderHotlineFormDialog"
  ></app-hotline-form>
  <app-hotline-report-form
    (hotlineReportFormDialogState)="
            renderHotlineReportFormDialog = $event;
            isCreateReport = $event;
            isUpdateReport = $event;
            isCopyReport = $event
        "
    [hotline]="clickedRow && clickedRow[0] ? clickedRow[0] : null"
    [isCopy]="isCopyReport"
    [isCreate]="isCreateReport"
    [isUpdate]="isUpdateReport"
    [isVisible]="renderHotlineReportFormDialog"
    [report]="null"
  ></app-hotline-report-form>
  <ejs-dialog
    #viewReportDialog
    [isModal]="true"
    [showCloseIcon]="true"
    [visible]="false"
    [width]="'85%'"
    id="viewReportDialog"
  >
    <ng-template #content>
      <div *ngIf="report" class="dialogContent">
        <div class="row">
          <div class="col-12">
            <ul class="list-group">
              <li class="list-group-item">
                Zadavatel:&nbsp;&nbsp;
                <small
                >{{ report.creator.firstname }}
                  {{ report.creator.secondname }}</small
                >&nbsp;&nbsp;
                <small
                  *ngIf="report.unixCreatedTime"
                  class="text-muted"
                  style="font-size: 11px"
                >
                  {{
                    report.created_date
                      | dateFormat: 'DD.MM.YYYY HH:mm'
                  }}&nbsp;&middot;&nbsp;{{
                    report.unixCreatedTime | timeago: true
                  }}
                </small>
              </li>
              <li class="list-group-item"
              >Předmět: {{ report.subject }}
              </li
              >
              <li class="list-group-item">
                <p [innerHTML]="report.reportHTML"></p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </ng-template>
  </ejs-dialog>
</div>
<div class="row">
  <div class="col-12 text-right justify-content-end">
    <small class="text-muted">legenda:</small>&nbsp;
    <span
      class="e-badge"
      style="
                background-color: #8db304;
                border-color: #8db304;
                color: #ffffff;
            "
    >služba</span
    >
    <span
      class="e-badge"
      style="
                background-color: #2a7faf;
                border-color: #2a7faf;
                color: #ffffff;
            "
    >příslužba</span
    >
    <span
      class="e-badge"
      style="
                background-color: #b30418;
                border-color: #b30418;
                color: #ffffff;
            "
    >smazáno</span
    >
  </div>
</div>
<ejs-toolbar id="filterToolbar" overflowMode="Scrollable" scrollStep="500">
  <e-items>
    <e-item
      [cssClass]="'statusFilter'"
      tooltipText="Přednastavené hledání"
      type="input"
    >
      <ng-template #template>
        <ejs-dropdownlist
          #viewFilter
          (change)="onStatusFilterChange($event)"
          [dataSource]="categoryFilterData"
          [width]="500"
          placeholder="Vyberte přednastavený pohled..."
        ></ejs-dropdownlist>
      </ng-template>
    </e-item>
    <e-item type="Separator"></e-item>
    <e-item
      [cssClass]="'fulltextFilter'"
      tooltipText="Text pro vyhledávání"
      type="input"
    >
      <ng-template #template>
        <ejs-textbox
          #searchtext
          [width]="500"
          class="mr-2"
          placeholder="Vyhledat..."
        ></ejs-textbox>
        <button
          (click)="search()"
          class="e-btn e-info btn-p-zero e-small"
        >
          <i class="fa-light fa-lg fa-search mr-1"></i
          ><span class="d-none d-lg-inline">vyhledat</span>
        </button>
        <button
          (click)="cancelBtnClick()"
          class="e-btn e-danger btn-p-zero e-small"
        >
          <i class="fa-light fa-lg fa-times"></i>
        </button>
      </ng-template>
    </e-item>
  </e-items>
</ejs-toolbar>
<div class="control-section">
  <div class="scroll_wrapper1" id="scroll_wrapper1_hotlines">
    <div class="scroll_div" id="scroll_div_hotlines"></div>
  </div>
  <div class="scroll_wrapper2" id="scroll_wrapper2_hotlines">
    <div class="grid_parent" id="grid_parent_hotlines">
      <ejs-grid
        #hotlineGrid
        (actionBegin)="actionBegin($event)"
        (actionComplete)="actionComplete($event)"
        (created)="onCreated()"
        (dataBound)="onDataBound()"
        (load)="onLoad()"
        (loadeddata)="setInitialGridFiltering()"
        (rowDataBound)="rowDataBound($event)"
        (rowDeselected)="rowSelected()"
        (rowSelected)="rowSelected()"
        (toolbarClick)="toolbarClick($event)"
        [allowExcelExport]="true"
        [allowFiltering]="true"
        [allowMultiSorting]="true"
        [allowPdfExport]="false"
        [allowSelection]="true"
        [allowSorting]="true"
        [dataSource]="data"
        [editSettings]="editSettings"
        [enableHover]="false"
        [enablePersistence]="false"
        [enableVirtualization]="true"
        [filterSettings]="filterSettings"
        [groupSettings]="groupOptions"
        [pageSettings]="pageOptions"
        [selectionSettings]="selectionSettings"
        [sortSettings]="sortOptions"
        [toolbar]="toolbar"
        allowGrouping="true"
        allowReordering="true"
        allowResizing="true"
        allowTextWrap="true"
        height="100%"
        id="hotlineGrid"
        locale="cs"
        rowHeight="38"
        showColumnMenu="true"
        width="100%"
      >
        <e-columns>
          <e-column
            [allowEditing]="false"
            [allowFiltering]="false"
            [allowReordering]="false"
            [allowResizing]="false"
            [allowSorting]="false"
            [autoFit]="true"
            type="checkbox"
          ></e-column>
          <e-column
            [allowFiltering]="false"
            [allowReordering]="true"
            [allowResizing]="true"
            [autoFit]="true"
            [isPrimaryKey]="true"
            [visible]="true"
            field="id"
            headerText="ID"
          >
            <ng-template #template let-data>
              <a routerLink="/hotline/detail/{{ data.id }}">{{
                  data.id
                }}</a>
            </ng-template>
          </e-column>
          <e-column
            [allowEditing]="false"
            [allowFiltering]="false"
            [allowReordering]="false"
            [allowResizing]="false"
            [autoFit]="true"
            [visible]="false"
            field="hotliner_id"
            headerText="Hotliner ID"
          ></e-column>
          <e-column
            [allowEditing]="false"
            [allowFiltering]="false"
            [allowReordering]="false"
            [allowResizing]="false"
            [autoFit]="true"
            [visible]="false"
            field="user_id"
            headerText="User ID"
          ></e-column>
          <e-column
            [allowEditing]="false"
            [allowFiltering]="false"
            [allowReordering]="false"
            [allowResizing]="false"
            [autoFit]="true"
            [visible]="false"
            field="created_by"
            headerText="Creator ID"
          ></e-column>
          <e-column
            [autoFit]="true"
            [filter]="filterSettings"
            clipMode="EllipsisWithTooltip"
            field="hotliner_str"
            headerText="Hotliner"
          >
            <ng-template #template let-data>
              <div>
                <img
                  *ngIf="data.hotliner_img"
                  alt="profilový obrázek"
                  class="img-fluid rounded-circle avatar-xs mr-1"
                  src="assets/img{{ data.hotliner_img }}"
                />
                <span>{{ data.hotliner_str }}</span>
              </div>
            </ng-template>
          </e-column>
          <e-column
            [autoFit]="true"
            [filter]="filterSettings"
            clipMode="EllipsisWithTooltip"
            field="type"
            headerText="Typ služby"
          >
            <ng-template #template let-data>
                            <span
                              *ngIf="
                                    data.type === 'technician' &&
                                    data.deleted_date === null
                                "
                              class="e-badge e-badge-success"
                            >služba</span
                            >
              <span
                *ngIf="
                                    data.type !== 'technician' &&
                                    data.deleted_date === null
                                "
                class="e-badge e-badge-primary"
              >příslužba</span
              >
              <span
                *ngIf="data.deleted_date !== null"
                class="e-badge e-badge-danger"
              >smazáno</span
              >
            </ng-template>
          </e-column>
          <e-column
            [allowEditing]="false"
            [autoFit]="true"
            [filter]="filterDate"
            [format]="dateFormat"
            clipMode="EllipsisWithTooltip"
            editType="datepickeredit"
            field="start_at"
            headerText="Začátek služby"
            textAlign="Right"
            type="date"
          ></e-column>
          <e-column
            [allowEditing]="false"
            [autoFit]="true"
            [filter]="filterDate"
            [format]="dateFormat"
            clipMode="EllipsisWithTooltip"
            editType="datepickeredit"
            field="end_at"
            headerText="Konec služby"
            textAlign="Right"
            type="date"
          ></e-column>
          <e-column
            [autoFit]="true"
            [filter]="filterSettings"
            clipMode="EllipsisWithTooltip"
            field="score"
            headerText="Skóre"
          >
            <ng-template #template let-data>
              {{ data.score }} bodů
            </ng-template>
          </e-column>
          <e-column
            [autoFit]="true"
            [filter]="filterSettings"
            clipMode="EllipsisWithTooltip"
            field="company"
            headerText="Společnost"
          ></e-column>
          <e-column
            [autoFit]="true"
            [filter]="filterSettings"
            clipMode="EllipsisWithTooltip"
            field="reports"
            headerText="Počet zápisů"
          >
          </e-column>
          <e-column
            [autoFit]="true"
            [filter]="filterSettings"
            clipMode="EllipsisWithTooltip"
            field="creator_str"
            headerText="Vytvořil"
          >
            <ng-template #template let-data>
              <div>
                <img
                  *ngIf="data.creator_img"
                  alt="profilový obrázek"
                  class="img-fluid rounded-circle avatar-xs mr-1"
                  src="assets/img{{ data.creator_img }}"
                />
                <span>{{ data.creator_str }}</span>
              </div>
            </ng-template>
          </e-column>
          <e-column
            [allowEditing]="false"
            [autoFit]="true"
            [filter]="filterDate"
            [format]="dateFormat"
            clipMode="EllipsisWithTooltip"
            editType="datepickeredit"
            field="created_date"
            headerText="Vytvořeno"
            textAlign="Right"
            type="date"
          ></e-column>
          <e-column
            [allowEditing]="false"
            [autoFit]="true"
            [filter]="filterDate"
            [format]="dateFormat"
            clipMode="EllipsisWithTooltip"
            editType="datepickeredit"
            field="updated_date"
            headerText="Naposledy upraveno"
            textAlign="Right"
            type="date"
          ></e-column>
          <e-column
            [allowEditing]="false"
            [autoFit]="true"
            [filter]="filterDate"
            [format]="dateFormat"
            clipMode="EllipsisWithTooltip"
            editType="datepickeredit"
            field="deleted_date"
            headerText="Smazáno"
            textAlign="Right"
            type="date"
          ></e-column>
        </e-columns>
        <e-aggregates>
          <e-aggregate>
            <e-columns>
              <e-column [format]="'N'" field="id" type="Count">
                <ng-template #footerTemplate let-data>
                  Celkem řádků: {{ data.Count }}
                </ng-template
                >
              </e-column>
              <e-column [format]="'N'" field="score" type="Sum">
                <ng-template #footerTemplate let-data>
                  Celkem bodů: {{ data.Sum }}
                </ng-template>
              </e-column>
              <e-column [format]="'N'" field="reports" type="Sum">
                <ng-template #footerTemplate let-data>
                  Celkem zápisů: {{ data.Sum }}
                </ng-template>
              </e-column>
            </e-columns>
          </e-aggregate>
          <e-aggregate>
            <e-columns>
              <e-column
                [format]="'N'"
                field="score"
                type="Average"
              >
                <ng-template #footerTemplate let-data>
                  Průměr bodů: {{ data.Average }}
                </ng-template>
              </e-column>
              <e-column
                [format]="'N'"
                field="reports"
                type="Average"
              >
                <ng-template #footerTemplate let-data>
                  Průměrně zápisů: {{ data.Average }}
                </ng-template>
              </e-column>
            </e-columns>
          </e-aggregate>
          <e-aggregate>
            <e-columns>
              <e-column [format]="'N'" field="score" type="Max">
                <ng-template #footerTemplate let-data>
                  Max bodů: {{ data.Max }}
                </ng-template>
              </e-column>
              <e-column [format]="'N'" field="reports" type="Max">
                <ng-template #footerTemplate let-data>
                  Max zápisů: {{ data.Max }}
                </ng-template>
              </e-column>
            </e-columns>
          </e-aggregate>
        </e-aggregates>
      </ejs-grid>
    </div>
  </div>
</div>
