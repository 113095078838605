<ng-container *ngIf="!externalViewer">
  <div *ngIf="configuredViewer !== 'pdf'" [innerHtml]="docHtml"></div>
  <object
    *ngIf="fullUrl && configuredViewer === 'pdf'"
    [data]="fullUrl"
    height="100%"
    type="application/pdf"
    width="100%"
  >
    <p>
      Váš prohlížeč nepodporuje PDF soubory.
      <a [href]="fullUrl">Stáhnout PDF</a>.
    </p>
  </object>
</ng-container>
<ng-container *ngIf="externalViewer">
  <iframe
    #iframe
    (load)="iframeLoaded()"
    *ngIf="fullUrl && disableContent === 'none'"
    [src]="fullUrl"
    frameBorder="0"
    id="iframe"
  ></iframe>
  <div *ngIf="disableContent !== 'none'" class="container">
    <div
      [class.overlay-full]="disableContent === 'all'"
      [class.overlay-popout-google]="
                configuredViewer === 'google' &&
                (disableContent === 'popout' ||
                    disableContent === 'popout-hide')
            "
      [class.overlay-popout-office]="
                configuredViewer === 'office' &&
                (disableContent === 'popout' ||
                    disableContent === 'popout-hide')
            "
      [style.background-color]="
                disableContent === 'popout-hide' ? '#fff' : 'transparent'
            "
    ></div>
    <iframe
      #iframe
      (load)="iframeLoaded()"
      *ngIf="fullUrl"
      [src]="fullUrl"
      frameBorder="0"
      id="iframe"
    ></iframe>
  </div>
</ng-container>
