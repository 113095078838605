import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule, Routes} from '@angular/router';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {AuthGuard} from '@src/app/_guards/auth.guard';
import {TimeagoCustomFormatter, TimeagoFormatter, TimeagoIntl, TimeagoModule} from 'ngx-timeago';
import {SharedFeaturesModule} from '@src/app/shared_features/shared-features.module';
import {
    KnowledgeCommentFormComponent,
} from '@src/app/features/knowledge/_forms/knowledge-comment-form/knowledge-comment-form.component';
import {KnowledgeService} from '@src/app/features/knowledge/knowledge.service';
import {
    KnowledgeCommentsComponent,
    KnowledgeFilesComponent,
    KnowledgeToolbarComponent,
    KnowledgetSidebarComponent,
    KnowledgeDetailComponent,
    KnowledgeComponent,
    KnowledgeListComponent,
} from '@src/app/features/knowledge/components';
import {KnowledgeFormComponent} from './_forms/knowledge-form/knowledge-form.component';

const routes: Routes = [
    {
        path: 'knowledge',
        children: [
            {
                path: '',
                component: KnowledgeComponent,
                data: {
                    title: 'Pracovní postupy nástěnka',
                    breadcrumb: [
                        {
                            label: 'Pracovní postupy',
                            url: '/knowledge',
                        },
                    ],
                },
            },
            {
                path: 'list',
                component: KnowledgeListComponent,
                data: {
                    title: 'Seznam pracovních postupů',
                    breadcrumb: [
                        {
                            label: 'Pracovní postupy',
                            url: '/knowledge',
                        },
                        {
                            label: 'Seznam pracovních postupů',
                            url: '/knowledge/list',
                        },
                    ],
                },
            },
            {
                path: 'detail/:id',
                component: KnowledgeDetailComponent,
                data: {
                    title: 'Detail pracovních postupů',
                    breadcrumb: [
                        {
                            label: 'Pracovní postupy',
                            url: '/knowledge',
                        },
                        {
                            label: 'Seznam pracovních postupů',
                            url: '/knowledge/list',
                        },
                        {
                            label: 'Detail pracovního postupu',
                            url: '',
                        },
                    ],
                },
            },
        ],
        canActivate: [AuthGuard],
    },
];

@NgModule({
    declarations: [
        KnowledgeCommentFormComponent,
        KnowledgeCommentsComponent,
        KnowledgeComponent,
        KnowledgeDetailComponent,
        KnowledgeFilesComponent,
        KnowledgeFormComponent,
        KnowledgeListComponent,
        KnowledgeToolbarComponent,
        KnowledgetSidebarComponent,
    ],
    imports: [
        BrowserAnimationsModule,
        RouterModule.forChild(routes),
        BrowserModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        TimeagoModule.forChild({
            intl: TimeagoIntl,
            formatter: {provide: TimeagoFormatter, useClass: TimeagoCustomFormatter},
        }),
        SharedFeaturesModule,
    ],
    providers: [KnowledgeService],
})
export class KnowledgeModule {
}
