<div class="row d-flex justify-content-center modalWrapper">
  <ejs-dialog
    #deleteHotlineDialog
    [buttons]="buttonsDeleteHotlineDialog"
    [content]="'Opravdu chcete smazat označenou hot-line?'"
    [header]="'Smazat hot-line'"
    [height]="'200px'"
    [isModal]="true"
    [showCloseIcon]="true"
    [visible]="false"
    [width]="'450px'"
    id="deleteHotlineDialog"
  ></ejs-dialog>
  <ejs-dialog
    #deleteReportDialog
    [buttons]="buttonsDeleteReportDialog"
    [content]="'Opravdu chcete smazat označený report hot-line?'"
    [header]="'Smazat report hot-line'"
    [height]="'200px'"
    [isModal]="true"
    [showCloseIcon]="true"
    [visible]="false"
    [width]="'450px'"
    id="deleteReportDialog"
  ></ejs-dialog>
  <app-hotline-form
    (hotlineFormDialogState)="
            renderHotlineFormDialog = $event;
            isCreate = $event;
            isUpdate = $event;
            isCopy = $event
        "
    [hotline]="hotline && (isUpdate || isCopy) ? hotline : null"
    [isCopy]="isCopy"
    [isCreate]="false"
    [isUpdate]="isUpdate"
    [isVisible]="renderHotlineFormDialog"
  ></app-hotline-form>
  <app-hotline-report-form
    (hotlineReportFormDialogState)="
            renderHotlineReportFormDialog = $event;
            isCreateReport = $event;
            isUpdateReport = $event;
            isCopyReport = $event
        "
    [hotline]="hotline"
    [isCopy]="isCopyReport"
    [isCreate]="isCreateReport"
    [isUpdate]="isUpdateReport"
    [isVisible]="renderHotlineReportFormDialog"
    [report]="highlightedReport"
  ></app-hotline-report-form>
</div>
<div class="row">
  <div class="col-12 col-md-8 col-lg-9 p-2">
    <div class="row">
      <div class="col-12 mb-1">
        <div class="e-card">
          <ng-container *ngIf="hotline && !loadingHotline">
            <div class="e-card-header stylish-color-dark p-2">
              <div class="e-card-header-caption">
                <div class="e-card-header-title text-white">
                  <strong>
                    Hot-line:
                    {{ hotline.start_at | date: 'dd.MM.yyyy' }}
                    {{ hotline.start_at | date: 'HH:mm' }} -
                    {{ hotline.end_at | date: 'HH:mm' }}
                  </strong>
                </div>
              </div>
            </div>
            <div class="row stylish-color-dark p-2">
              <div
                aria-label="Hot-line toolbar"
                class="col-12 text-right"
                role="toolbar"
              >
                <button
                  (click)="editHotline()"
                  *ngIf="
                                        currentUser.rule &&
                                        (currentUser.rule.is_hotline_admin ||
                                            currentUser.rule.is_admin)
                                    "
                  cssClass="e-btn e-warning btn-p-zero e-small"
                  ejs-button
                  type="button"
                >
                  <i
                    aria-hidden="true"
                    class="fa-light fa-pencil mr-lg-1"
                  ></i>
                  <span
                    *ngIf="
                                            !hotline.deleted_date &&
                                            !loadingHotline
                                        "
                    class="d-none d-lg-inline"
                  >Upravit</span
                  >
                  <span
                    *ngIf="
                                            hotline.deleted_date &&
                                            !loadingHotline
                                        "
                    class="d-none d-lg-inline"
                  >Obnovit</span
                  >
                </button>
                <button
                  (click)="deleteHotlineDialog.show()"
                  *ngIf="
                                        currentUser.rule &&
                                        (currentUser.rule.is_hotline_admin ||
                                            currentUser.rule.is_admin) &&
                                        !hotline.deleted_date
                                    "
                  cssClass="e-btn e-danger btn-p-zero e-small"
                  ejs-button
                  type="button"
                >
                  <i
                    aria-hidden="true"
                    class="fa-light fa-trash mr-lg-1"
                  ></i>
                  <span
                    class="d-none d-lg-inline"
                  >smazat</span
                  >
                </button>
                <button
                  (click)="addReport()"
                  *ngIf="
                                        (currentUser.rule &&
                                            (currentUser.rule
                                                .is_hotline_admin ||
                                                currentUser.rule.is_admin)) ||
                                        currentUser.is_hotliner
                                    "
                  cssClass="e-btn e-success btn-p-zero e-small float-right"
                  ejs-button
                  type="button"
                >
                  <i
                    aria-hidden="true"
                    class="fa-light fa-pencil mr-lg-1"
                  ></i>
                  <span
                    class="d-none d-lg-inline"
                  >Vytvořit zápis</span
                  >
                </button>
              </div>
            </div>
            <div class="e-card-content">
              <div class="row">
                <div class="col-6 text-left text-muted">
                  <small *ngIf="hotline.unixCreatedTime" class="font-weight-bold">OD:
                    {{
                      hotline.start_at
                        | dateFormat: 'DD.MM.YYYY HH:mm'
                    }}
                    <span
                      *ngIf="hotline.unixCreatedTime"
                    >(
                      {{
                        hotline.unixCreatedTime
                          | timeago: live
                      }}
                      )</span
                    >
                  </small>
                </div>
                <div class="col-6 text-right text-muted">
                  <small
                    class="font-weight-bold"
                  >DO:
                    {{
                      hotline.end_at
                        | dateFormat: 'DD.MM.YYYY HH:mm'
                    }}
                    <span
                      *ngIf="hotline.unixEndDate"
                    >(
                      {{
                        hotline.unixEndDate
                          | timeago: live
                      }}
                      )</span
                    ></small
                  >
                </div>
              </div>
              <div class="row">
                <div class="col-12 mt-3 mb-2">
                  <div class="e-card reports-block">
                    <div
                      class="e-card-header bg-success"
                    >
                      <h6 class="text-white">
                        Zápisy ({{
                          hotline.reports.length
                        }}):
                      </h6>
                    </div>
                    <div class="e-card-content container-fluid scrollbar scrollbar-dusty-grass thin">
                      <div *ngIf="hotline.reports.length > 0" class="threads-block">
                        <ng-container *ngFor="let report of hotline.reports">
                          <div *ngIf="report.hotline_id === hotline.id" class="thread">
                            <div class="note-toolbar">
                              <div class="user-signature">
                                <img
                                  alt="profilový obrázek"
                                  class="avatar-xs rounded-circle z-depth-1"
                                  src="assets/img{{report.creator.personalphoto}}"
                                />
                                <div class="font-weight-bold time-block">
                                  <small class="text-muted font-weight-bold">
                                    {{ report.creator.firstname }} {{ report.creator.secondname }}
                                  </small>
                                  &nbsp;&nbsp;
                                  <small
                                    *ngIf="report.unixCreatedTime"
                                    class="text-muted font-weight-bold"
                                    style="
                                                                                            font-size: 11px;
                                                                                        "
                                  >{{
                                      report.created_date
                                        | dateFormat
                                        : 'DD.MM.YYYY HH:mm'
                                    }}&nbsp;&middot;&nbsp;{{
                                      report.unixCreatedTime
                                        | timeago
                                        : live
                                    }}
                                  </small>
                                </div>
                              </div>
                              <div
                                aria-label="notes toolbar"
                                class="note-buttons e-small"
                                role="group"
                              >
                                <button
                                  (click)="
                                                                                           highlightedReport =
                                                                                                report;
                                                                                            editReport()
                                                                                        "
                                  *ngIf="
                                                                                            (report &&
                                                                                                report
                                                                                                    .creator
                                                                                                    .id ===
                                                                                                    currentUser.id &&
                                                                                                currentUser.is_hotliner &&
                                                                                                !report.deleted_date &&
                                                                                                !hotline.deleted_date) ||
                                                                                            (currentUser.rule &&
                                                                                                (currentUser
                                                                                                    .rule
                                                                                                    .is_hotline_admin ||
                                                                                                    currentUser
                                                                                                        .rule
                                                                                                        .is_admin))
                                                                                        "
                                  cssClass="e-btn e-warning btn-p-zero e-small"
                                  ejs-button
                                  type="button"
                                >
                                  <i
                                    aria-hidden="true"
                                    class="fa-light fa-pencil mr-1"
                                  ></i>
                                  upravit
                                </button>
                                <button
                                  (click)="
                                                                                            highlightedReport =
                                                                                                report;
                                                                                            deleteReportDialog.show()
                                                                                        "
                                  *ngIf="
                                                                                            (report &&
                                                                                                report
                                                                                                    .creator
                                                                                                    .id ===
                                                                                                    currentUser.id &&
                                                                                                currentUser.is_hotliner &&
                                                                                                !report.deleted_date &&
                                                                                                !hotline.deleted_date) ||
                                                                                            (currentUser.rule &&
                                                                                                (currentUser
                                                                                                    .rule
                                                                                                    .is_hotline_admin ||
                                                                                                    currentUser
                                                                                                        .rule
                                                                                                        .is_admin))
                                                                                        "
                                  cssClass="e-btn e-danger btn-p-zero e-small"
                                  ejs-button
                                  type="button"
                                >
                                  <i
                                    aria-hidden="true"
                                    class="fa-light fa-trash mr-1"
                                  ></i>
                                  smazat
                                </button>
                              </div>
                            </div>
                            <div class="note-text border-bottom">
                              <p [innerHTML]="report.reportHTML" class="text-ticket"></p>
                            </div>
                          </div>
                        </ng-container>
                      </div>
                      <div *ngIf="hotline.reports.length === 0" class="col-12 text-center">
                        <p class="text-ticket font-weight-bold">
                          Zápis nevytvořen...
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="!hotline || loadingHotline">
            <div class="e-card-content text-center">
              <app-loader></app-loader>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
  <div class="col-12 col-md-4 col-lg-3 p-2">
    <div
      class="e-card"
      style="position: -webkit-sticky; position: sticky; top: 65px"
    >
      <ng-container *ngIf="hotline && !loadingHotline">
        <div class="e-card-header stylish-color-dark p-2">
          <div class="e-card-header-caption">
            <div
              class="d-flex justify-content-between e-card-header-title text-white"
            >
              <strong>Hot-line</strong>
              <span>ID: {{ hotline.id }}</span>
            </div>
          </div>
        </div>
        <div class="e-card-content p-2">
          <div class="text-center mb-1">
            <h6 class="font-weight-bold">
              {{ hotline.start_at | date: 'dd.MM.yyyy' }}
              {{ hotline.start_at | date: 'HH:mm' }} -
              {{ hotline.end_at | date: 'HH:mm' }}
            </h6>
            <ul
              class="list-group list-group-flush text-center"
              id="creator"
            >
              <li
                class="list-group-item list-group-item-action p-1 mt-2 text-center"
              >
                <a
                  class="mr-1"
                  routerLink="/users/detail/{{
                                        hotline.creator.id
                                    }}"
                ><img
                  alt="profilový obrázek"
                  class="img-fluid rounded-circle avatar-xs"
                  src="assets/img{{
                                            hotline.creator.personalphoto
                                        }}"
                />{{ hotline.creator.firstname }}
                  {{ hotline.creator.secondname }}
                </a>
                <br />
                <span class="float-right text-right">
                                    <small class="font-weight-bold">zadavatel</small>
                                </span>
              </li>
              <li
                class="list-group-item list-group-item-action p-1 mt-2 text-left"
              >
                <small class="font-weight-bold">
                  začátek:
                  {{
                    hotline.start_at
                      | dateFormat: 'DD.MM.YYYY HH:mm'
                  }}
                </small>
                <span class="float-right text-right">
                                    <small class="font-weight-bold">
                                        {{
                                        hotline.unixStartDate
                                          | timeago: live
                                      }}
                                      )
                                    </small>
                                </span>
              </li>
              <li
                class="list-group-item list-group-item-action p-1 mt-2 text-left"
              >
                <small class="font-weight-bold">
                  konec:
                  {{
                    hotline.end_at
                      | dateFormat: 'DD.MM.YYYY HH:mm'
                  }}
                </small>
                <span class="float-right text-right">
                                    <small
                                      class="font-weight-bold"
                                    >(
                                      {{
                                        hotline.unixEndDate | timeago: live
                                      }}
                                      )</small
                                    >
                                </span>
              </li>
              <li
                class="list-group-item list-group-item-action p-1 mt-2 text-left"
              >
                <small class="font-weight-bold">
                  vytvořeno:
                  {{
                    hotline.created_date
                      | dateFormat: 'DD.MM.YYYY HH:mm'
                  }}
                </small>
                <span class="float-right text-right">
                                    <small
                                      class="font-weight-bold"
                                    >(
                                      {{
                                        hotline.unixCreatedTime
                                          | timeago: live
                                      }}
                                      )</small
                                    >
                                </span>
              </li>
              <li
                class="list-group-item list-group-item-action p-1 mt-2 text-left"
              >
                <small class="font-weight-bold">
                  poslední změna:
                  {{
                    hotline.updated_date
                      | dateFormat: 'DD.MM.YYYY HH:mm'
                  }}
                </small>
                <span class="float-right text-right">
                                    <small
                                      class="font-weight-bold"
                                    >(
                                      {{
                                        hotline.unixUpdatedTime
                                          | timeago: live
                                      }}
                                      )</small
                                    >
                                </span>
              </li>
            </ul>
            <span
              class="e-badge e-badge-success ml-1"
            >{{ hotline.score }} bodů</span
            >
            <span
              *ngIf="hotline.deleted_date"
              class="e-badge e-badge-danger ml-1"
            >smazáno (dne:
              {{
                hotline.deleted_date
                  | dateFormat: 'DD.MM.YYYY HH:mm'
              }}
              )</span
            >
            <ng-container>
              <ul class="list-group list-group-flush text-left">
                <li
                  class="list-group-item list-group-item-action p-1 mt-2 text-left"
                >
                                    <span
                                      class="d-flex justify-content-between"
                                    >
                                        <a
                                          [ngClass]="{
                                                striked: hotline.deleted_date
                                            }"
                                          class="mr-1"
                                          routerLink="/users/detail/{{
                                                hotline.hotliner.user.id
                                            }}"
                                        >
                                            <img
                                              alt="profilový obrázek"
                                              class="img-fluid rounded-circle avatar-xs mr-1"
                                              src="assets/img{{
                                                    hotline.hotliner.user
                                                        .personalphoto
                                                }}"
                                            />{{
                                            hotline.hotliner.user.firstname
                                          }}
                                          {{
                                            hotline.hotliner.user.secondname
                                          }}
                                        </a>
                                        <small
                                          [ngClass]="{
                                                striked: hotline.deleted_date
                                            }" class="font-weight-bold"
                                        >{{
                                            hotline.company.name
                                          }}</small
                                        >&nbsp;
                                        <span
                                          *ngIf="
                                                hotline.type === 'technician'
                                            "
                                          [ngClass]="{
                                                striked: hotline.deleted_date
                                            }"
                                          class="e-badge e-badge-success"
                                        >
                                            služba
                                        </span>
                                        <span
                                          *ngIf="
                                                hotline.type !== 'technician'
                                            "
                                          [ngClass]="{
                                                striked: hotline.deleted_date
                                            }"
                                          class="e-badge e-badge-primary"
                                        >
                                            příslužba
                                        </span>
                                        <span
                                          *ngIf="hotline.deleted_date"
                                          class="e-badge e-badge-danger"
                                        >smazáno</span
                                        >
                                    </span>
                </li>
              </ul>
            </ng-container>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="!hotline || loadingHotline">
        <div class="e-card-content p-2">
          <div class="text-center mb-1">
            <app-loader></app-loader>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>
