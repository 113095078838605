import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    OnInit,
    ViewChild,
} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {User} from '@src/app/_models/user/user';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {AuthenticationService} from '@src/app/_services/authentication.service';
import {UsersService} from '@src/app/features/users/users.service';
import {MessageService} from '@src/app/_services/message.service';
import {registerLocaleData} from '@angular/common';
import {saveAs} from 'file-saver';
import {AddressConnectedField} from '@src/app/_models/services/address-connected-field';
import {AddressConnectedFieldPhotos} from '@src/app/_models/services/address-connected-field-photos';
import {FieldInput, ServicesService} from '@src/app/features/services/services.service';
import {DataNetbaseService} from '@src/app/_services/data-netbase.service';
// eslint-disable-next-line max-len
import {TabComponent} from '@syncfusion/ej2-angular-navigations';
import moment from 'moment';
import localeCs from '@angular/common/locales/cs';
import {SelectEventArgs} from '@syncfusion/ej2-navigations';
import {PermissionService} from '@src/app/_services/permission.service';
import {EmitType, isNullOrUndefined, L10n} from '@syncfusion/ej2-base';
import {
    BeforeUploadEventArgs,
    FileInfo,
    RemovingEventArgs,
    SelectedEventArgs,
    SuccessEventArgs,
    UploaderComponent,
    UploadingEventArgs,
} from '@syncfusion/ej2-angular-inputs';
import {
    DropDownListComponent,
    FilteringEventArgs,
    MultiSelectComponent,
} from '@syncfusion/ej2-angular-dropdowns';
import {Company} from '@src/app/_models/company/company';
import {NetPackages} from '@src/app/_models/services/net-packages';
import {TvServices} from '@src/app/_models/services/tv-services';
import {TechnologyTypes} from '@src/app/_models/services/technology-types';
import {UnitTypes} from '@src/app/_models/services/unit-types';
import {DealTypes} from '@src/app/_models/services/deal-types';
import {Discounts} from '@src/app/_models/services/discounts';
import {SpaceTypes} from '@src/app/_models/services/space-types';
import {TransferTypes} from '@src/app/_models/services/transfer-types';
import {Project} from '@src/app/_models/projects/project';
import {DataService} from '@src/app/_services/data.service';
import {DialogComponent} from '@syncfusion/ej2-angular-popups';
import {Query} from '@syncfusion/ej2-data';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {EnvironmentService} from '@src/app/_services/environment.service';
import {Lightbox} from 'ngx-lightbox';
import {GalleryImages} from '@src/app/_models/images/images.type';

moment.updateLocale('cs', {workingWeekdays: [1, 2, 3, 4, 5]});
registerLocaleData(localeCs);

L10n.load({
    cs: {
        filemanager: {
            NewFolder: 'Nová složka',
            Upload: 'Nahrát soubor',
            Delete: 'Smazat',
            Rename: 'Přejmenovat',
            Download: 'Stáhnout',
            Cut: 'Vyjmout',
            Copy: 'Kopírovat',
            Paste: 'Vložit',
            SortBy: 'Seřadit',
            Refresh: 'Aktualizovat',
            'Item-Selection': 'Vybraná položka',
            'Items-Selection': 'Vybrané položky',
            View: 'Náhled',
            Details: 'Detail',
            SelectAll: 'Označit vše',
            Open: 'Otevřít',
            'Tooltip-NewFolder': 'Nová složka',
            'Tooltip-Upload': 'Nahrát soubor',
            'Tooltip-Delete': 'Smazat',
            'Tooltip-Rename': 'Přejmenovat',
            'Tooltip-Download': 'Stáhnout',
            'Tooltip-Cut': 'Vyjmout',
            'Tooltip-Copy': 'Kopírovat',
            'Tooltip-Paste': 'Vložit',
            'Tooltip-SortBy': 'Seřadit',
            'Tooltip-Refresh': 'Aktualizovat',
            'Tooltip-Selection': 'Zrušit výběr',
            'Tooltip-View': 'Náhled',
            'Tooltip-Details': 'Detail',
            'Tooltip-SelectAll': 'Označit vše',
            Name: 'Název',
            Size: 'Velikost',
            DateModified: 'Datum změny',
            DateCreated: 'Datum vytvoření',
            Path: 'Cesta',
            Modified: 'Upraveno',
            Created: 'Vytvořeno',
            Location: 'Lokace',
            Type: 'Typ',
            Permission: 'Práva',
            Ascending: 'Vzestupně',
            Descending: 'Sestupně',
            None: 'Keiner',
            'View-LargeIcons': 'Velké ikony',
            'View-Details': 'Detail',
            Search: 'Hledat',
            'Button-Ok': 'OK',
            'Button-Cancel': 'Storno',
            'Button-Yes': 'ANO',
            'Button-No': 'NE',
            'Button-Create': 'Vytvořit',
            'Button-Save': 'Uložit',
            'Header-NewFolder': 'Mapy',
            'Content-NewFolder': 'Zadejte název složky',
            'Header-Rename': 'Přejmenovat',
            'Content-Rename': 'Zadejte nový název',
            'Header-Rename-Confirmation': 'Potvrdit přejmenování',
            'Content-Rename-Confirmation':
                'Pokud změníte příponu názvu souboru, může být soubor nestabilní. Opravdu je chcete změnit?',
            'Header-Delete': 'smazat soubor',
            'Content-Delete': 'Opravdu chcete tento soubor smazat?',
            'Header-Multiple-Delete': 'Odstranit více souborů',
            'Content-Multiple-Delete': 'Opravdu chcete tyto soubory (počet: {0}) smazat?',
            'Header-Folder-Delete': 'Odstranit složku',
            'Content-Folder-Delete': 'Opravdu chcete tuto složku smazat?',
            'Header-Duplicate': 'Soubor / složka existuje',
            'Content-Duplicate': '{0} již existuje. Chcete přejmenovat a vložit?',
            'Header-Upload': 'Nahrajte data',
            Error: 'Error',
            'Validation-Empty': 'Název souboru nebo složky nesmí být prázdný.',
            'Validation-Invalid':
            // eslint-disable-next-line max-len
                'Název souboru nebo složky {0} obsahuje neplatné znaky. Použijte jiný název. Platné názvy souborů nebo složek nemohou končit tečkou nebo mezerou a nesmí obsahovat žádný z následujících znaků: \\ /: *? " < > |',
            'Validation-NewFolder-Exists': 'Soubor nebo složka s názvem {0} již existuje.',
            'Validation-Rename-Exists': '{0} nelze přejmenovat na {1}: cíl již existuje.',
            'Folder-Empty': 'Tato složka je prázdná',
            'File-Upload': 'Sem nahrajte soubory, které chcete nahrát',
            'Search-Empty': 'Nebyly nalezeny žádné výsledky',
            'Search-Key': 'Zkuste jiná klíčová slova',
            'Filter-Empty': 'Nebyly nalezeny žádné výsledky',
            'Filter-Key': 'Zkuste jiný filtr',
            'Sub-Folder-Error': 'Cílová složka je podsložkou zdrojové složky.',
            'Same-Folder-Error': 'Cílová složka je stejná jako zdrojová složka.',
            'Access-Denied': 'Přístup odepřen',
            'Access-Details': 'K přístupu do této složky nemáte oprávnění.',
            'Header-Retry': 'Soubor již existuje',
            'Content-Retry': 'Soubor s tímto názvem již v této složce existuje. Co chceš dělat?',
            'Button-Keep-Both': 'Ponechat oboje',
            'Button-Replace': 'Nahradit',
            'Button-Skip': 'Přeskočit',
            'ApplyAll-Label': 'Udělat to pro všechny aktuální položky',
            KB: 'KB',
            'Access-Message': '{0} neplatný přístupný. K provedení akce potřebujete oprávnění {1}.',
            'Network-Error':
                'NetworkError: Chyba při odesílání na XMLHTTPRequest: Chyba při načítání',
            'Server-Error': 'ServerError: Neplatná odpověď od',
        },
    },
});

declare let smartform;

@UntilDestroy()
@Component({
    selector: 'app-service-field-detail',
    templateUrl: './service-field-detail.component.html',
    styleUrls: ['./service-field-detail.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ServiceFieldDetailComponent implements OnInit {
    // Dropdowns
    ruianFieldObj: object = {text: 'label', value: 'value'};

    height = '240px';

    usersSelect: Array<{value: boolean | number | string; label: string}> = [];

    departmentSelect: Array<{value: boolean | number | string; label: string}> = [];

    companySelect: Array<{value: boolean | number | string; label: string}> = [];

    technologySelect: Array<{value: boolean | number | string; label: string}> = [];

    unitSelect: Array<{value: boolean | number | string; label: string}> = [];

    dealSelect: Array<{value: boolean | number | string; label: string}> = [];

    discountSelect: Array<{value: boolean | number | string; label: string}> = [];

    transferSelect: Array<{value: boolean | number | string; label: string}> = [];

    spaceSelect: Array<{value: boolean | number | string; label: string}> = [];

    servicesNetSelect: Array<{value: boolean | number | string; label: string}> = [];

    servicesTvSelect: Array<{value: boolean | number | string; label: string}> = [];

    projectSelect: Array<{value: boolean | number | string; label: string}> = [];

    choosenNetPackages: Array<number> = [];

    choosenTvServices: Array<number> = [];

    // Dialogs
    renderProjectFormDialog = false;

    buttonsRemoveFieldDialog = [
        {
            click: this.removeField.bind(this),
            buttonModel: {
                content: 'OK',
                cssClass: 'e-success e-outline',
                isFlat: false,
            },
        },
        {
            click: (): void => {
                this.fieldRemoveDialog.hide();
            },
            buttonModel: {content: 'ZRUŠIT', cssClass: 'e-danger e-outline'},
        },
    ];

    buttonsRemoveFileDialog = [
        {
            click: this.onConfirmRemove.bind(this),
            buttonModel: {
                content: 'OK',
                cssClass: 'e-success e-outline',
                isFlat: false,
            },
        },
        {
            click: (): void => {
                this.fileRemoveDialog.hide();
            },
            buttonModel: {content: 'ZRUŠIT', cssClass: 'e-danger e-outline'},
        },
    ];

    // Forms
    createFileForm: FormGroup;

    isCreate = false;

    isUpdate = false;

    isCopy = false;

    submited = false;

    // Uploads
    dropElement: HTMLElement = document.getElementsByClassName('control-fluid')[0] as HTMLElement;

    headerText = [{text: 'Data parcely'}, {text: 'Soubory a fotky'}, {text: 'Historie změn'}];

    path: {
        saveUrl: string;
        removeUrl: string;
    };

    imagesBasic: GalleryImages[] = [];

    images: AddressConnectedFieldPhotos[] = [];

    fieldPhoto: AddressConnectedFieldPhotos;

    filesData = [];

    deleteFileId: number;

    currentIndex: number;

    showFlag: boolean;

    // Variables
    visible = false;

    contentPrinted: boolean;

    contentSearched: number;

    currentUser: User | null;

    project: Project;

    field: AddressConnectedField | null | undefined;

    users: User[] = [];

    unixCreatedTime: number | null = null;

    dragOver: boolean;

    live = true;

    editMode = false;

    reply = '';

    limit = 2;

    page = 1;

    pageFiles = 1;

    pageSize = 5;

    showComment: boolean;

    showReply: boolean;

    today = moment()
        .format('YYYY-MM-DD HH:mm:ss');

    todayUnix = moment()
        .valueOf();

    // Loaders
    loadingAddress = false;

    @ViewChild('serviceFieldDetailTab') serviceFieldDetailTabObj: TabComponent;

    @ViewChild('thread', {static: false}) thread: HTMLElement;

    // Dropdowns
    @ViewChild('deal_type') dealTypeObj: DropDownListComponent;

    @ViewChild('unit_type') unitTypeObj: DropDownListComponent;

    @ViewChild('provider') providerObj: DropDownListComponent;

    @ViewChild('discount') discountObj: DropDownListComponent;

    @ViewChild('flat_space') flatSpaceObj: DropDownListComponent;

    @ViewChild('speed_type') speedTypeObj: DropDownListComponent;

    @ViewChild('hardware') hardwareObj: DropDownListComponent;

    @ViewChild('technology') technologyObj: DropDownListComponent;

    @ViewChild('transfer') transferObj: DropDownListComponent;

    @ViewChild('project') projectObj: DropDownListComponent;

    @ViewChild('net_packages') netPackagesObj?: MultiSelectComponent;

    @ViewChild('tv_services') tvServicesObj?: MultiSelectComponent;

    // Dialogs
    @ViewChild('fieldRemoveDialog') fieldRemoveDialog: DialogComponent;

    @ViewChild('fileRemoveDialog') fileRemoveDialog: DialogComponent;

    // Uploads
    @ViewChild('targetView') targetView: ElementRef;

    @ViewChild('preloadupload') uploadObj?: UploaderComponent;

    constructor(
        private readonly route: ActivatedRoute,
        private readonly authenticationService: AuthenticationService,
        private readonly servicesService: ServicesService,
        private readonly dataNetbaseService: DataNetbaseService,
        private readonly dataService: DataService,
        private readonly ref: ChangeDetectorRef,
        private readonly userService: UsersService,
        private readonly permissionService: PermissionService,
        private readonly messages: MessageService,
        private readonly formBuilder: FormBuilder,
        private readonly router: Router,
        private readonly environmentService: EnvironmentService,
        private readonly lightbox: Lightbox,
    ) {
        this.contentPrinted = false;
        this.contentSearched = 0;
        this.currentUser = this.authenticationService.currentUserValue;
        this.path = {
            saveUrl: `${this.environmentService.backendURL}/api/upload/service?type=netbase_services&subfolder=field`,
            removeUrl: '',
        };
    }

    get filesControl(): {[key: string]: AbstractControl} {
        return this.createFileForm.controls;
    }

    private loadData(): void {
        this.imagesBasic = [];
        this.images = [];

        const netPackages: number[] = [];
        const tvPackages: number[] = [];

        if (this.field) {
            if (this.field.net_packages.length > 0) {
                this.field.net_packages.forEach((netPackage: NetPackages) => {
                    netPackages.push(netPackage.id);
                });
                this.choosenNetPackages = netPackages;
            }

            if (this.field.tv_services && this.field.tv_services.length > 0) {
                this.field.tv_services.forEach((tvPackage: TvServices) => {
                    tvPackages.push(tvPackage.id);
                });
                this.choosenTvServices = tvPackages;
            }

            if (this.field.photos && this.field.photos.length > 0) {
                this.field.photos.forEach((photo: AddressConnectedFieldPhotos) => {
                    if (photo.extension === 'image/jpeg' || photo.extension === 'image/png') {
                        this.images.push(photo);
                        this.imagesBasic.push({
                            src: photo.url,
                            thumb: photo.url_thumbnail,
                            caption: photo.name,
                        });
                    }
                });
            }

            if (this.tvServicesObj) {
                this.tvServicesObj.refresh();
            }

            if (this.netPackagesObj) {
                this.netPackagesObj.refresh();
            }

            this.loadingAddress = false;
            this.ref.markForCheck();
        }
    }

    reinitSmartForm(): void {
        console.info('Reinitialize SF objects...');
        smartform.rebindAllForms(false, () => {
            if (smartform.getInstance()) {
                const instanceCity = smartform.getInstance('smartform-instance-city');

                instanceCity.setZIndex(100000000);
                instanceCity.setSelectionBackgroundColor('#4285f3');

                const instanceCityPart = smartform.getInstance('smartform-instance-cityPart');

                instanceCityPart.setZIndex(100000000);
                instanceCityPart.setSelectionBackgroundColor('#4285f3');

                const instanceDistrict = smartform.getInstance('smartform-instance-district');

                instanceDistrict.setZIndex(100000000);
                instanceDistrict.setSelectionBackgroundColor('#4285f3');
            }
        });
    }

    ngOnInit(): void {
        this.showComment = false;
        this.showReply = false;
        this.showFlag = false;
        this.currentIndex = -1;
        this.dataNetbaseService.fieldDetailSource.pipe(untilDestroyed(this))
            .subscribe(
                (field: AddressConnectedField | null | undefined) => {
                    this.field = field;

                    if (this.field) {
                        this.loadData();
                    }
                },
                error => {
                    console.error(error);
                    this.loadingAddress = false;
                    this.ref.markForCheck();
                },
            );

        this.dataService.companySource.pipe(untilDestroyed(this))
            .subscribe(
                (companies: Company[]) => {
                    this.companySelect = [];
                    companies.forEach((company: Company) => {
                        if (!company.deleted_date) {
                            this.companySelect = [
                                ...this.companySelect,
                                {
                                    value: company.id,
                                    label: company.name,
                                },
                            ];
                        }
                    });
                    this.ref.markForCheck();
                },
                error => {
                    console.error(error);
                },
            );

        this.dataNetbaseService.netPackagesSource.pipe(untilDestroyed(this))
            .subscribe(
                (netTarifs: NetPackages[]) => {
                    this.servicesNetSelect = [];
                    netTarifs.forEach((netTarif: NetPackages) => {
                        if (!netTarif.deleted_date) {
                            this.servicesNetSelect = [
                                ...this.servicesNetSelect,
                                {
                                    value: netTarif.id,
                                    label: `${netTarif.name}${
                                        netTarif.transfer.connection_method === 'optical cable'
                                            ? ' - optické připojení'
                                            : netTarif.transfer.connection_method === 'metalic cable'
                                                ? ' - kabelové připojení'
                                                : netTarif.transfer.connection_method === 'radio'
                                                    ? ' - rádiové připojení'
                                                    : '- xDSL připojení'
                                    }`,
                                },
                            ];
                        }
                    });
                    this.ref.markForCheck();
                },
                error => {
                    console.error(error);
                },
            );

        this.dataNetbaseService.tvServicesSource.pipe(untilDestroyed(this))
            .subscribe(
                (tvServices: TvServices[]) => {
                    this.servicesTvSelect = [];
                    tvServices.forEach((tvService: TvServices) => {
                        if (!tvService.deleted_date) {
                            this.servicesTvSelect = [
                                ...this.servicesTvSelect,
                                {
                                    value: tvService.id,
                                    label: `${tvService.name}${
                                        tvService.transfer.connection_method === 'optical cable'
                                            ? ' - optické připojení'
                                            : tvService.transfer.connection_method === 'metalic cable'
                                                ? ' - kabelové připojení'
                                                : tvService.transfer.connection_method === 'radio'
                                                    ? ' - rádiové připojení'
                                                    : '- xDSL připojení'
                                    }`,
                                },
                            ];
                        }
                    });
                    this.ref.markForCheck();
                },
                error => {
                    console.error(error);
                },
            );

        this.dataNetbaseService.technologyTypesSource.pipe(untilDestroyed(this))
            .subscribe(
                (technologies: TechnologyTypes[]) => {
                    this.technologySelect = [];
                    technologies.forEach((technology: TechnologyTypes) => {
                        if (!technology.deleted_date) {
                            this.technologySelect = [
                                ...this.technologySelect,
                                {value: technology.id, label: technology.name},
                            ];
                        }
                    });
                    this.ref.markForCheck();
                },
                error => {
                    console.error(error);
                },
            );

        this.dataNetbaseService.unitTypesSource.pipe(untilDestroyed(this))
            .subscribe(
                (units: UnitTypes[]) => {
                    this.unitSelect = [];
                    units.forEach((unit: UnitTypes) => {
                        if (!unit.deleted_date) {
                            this.unitSelect = [
                                ...this.unitSelect,
                                {value: unit.id, label: unit.name},
                            ];
                        }
                    });
                    this.ref.markForCheck();
                },
                error => {
                    console.error(error);
                },
            );

        this.dataNetbaseService.dealTypesSource.pipe(untilDestroyed(this))
            .subscribe(
                (deals: DealTypes[]) => {
                    this.dealSelect = [];
                    deals.forEach((deal: DealTypes) => {
                        if (!deal.deleted_date) {
                            this.dealSelect = [
                                ...this.dealSelect,
                                {value: deal.id, label: `${deal.age} měsíců`},
                            ];
                        }
                    });
                    this.ref.markForCheck();
                },
                error => {
                    console.error(error);
                },
            );

        this.dataNetbaseService.discountSource.pipe(untilDestroyed(this))
            .subscribe(
                (discounts: Discounts[]) => {
                    this.discountSelect = [];
                    discounts.forEach((discount: Discounts) => {
                        if (!discount.deleted_date) {
                            this.discountSelect = [
                                ...this.discountSelect,
                                {value: discount.id, label: `${discount.discount}%`},
                            ];
                        }
                    });
                    this.ref.markForCheck();
                },
                error => {
                    console.error(error);
                },
            );

        this.dataNetbaseService.spaceTypesSource.pipe(untilDestroyed(this))
            .subscribe(
                (spaces: SpaceTypes[]) => {
                    this.spaceSelect = [];
                    spaces.forEach((space: SpaceTypes) => {
                        if (!space.deleted_date) {
                            this.spaceSelect = [
                                ...this.spaceSelect,
                                {
                                    value: space.id,
                                    label: `${space.metric_until} m2 - ${
                                        space.metric_to ? space.metric_to : '*'
                                    } m2`,
                                },
                            ];
                        }
                    });
                    this.ref.markForCheck();
                },
                error => {
                    console.error(error);
                },
            );

        this.dataNetbaseService.transferTypesSource.pipe(untilDestroyed(this))
            .subscribe(
                (transfers: TransferTypes[]) => {
                    this.transferSelect = [];
                    transfers.forEach((transfer: TransferTypes) => {
                        if (!transfer.deleted_date) {
                            this.transferSelect = [
                                ...this.transferSelect,
                                {
                                    value: transfer.id,
                                    label: transfer.name,
                                },
                            ];
                        }
                    });
                    this.ref.markForCheck();
                },
                error => {
                    console.error(error);
                },
            );

        this.dataService.projectSource.pipe(untilDestroyed(this))
            .subscribe(
                (projects: Project[]) => {
                    this.projectSelect = [];
                    projects.forEach((project: Project) => {
                        if (!project.deleted_date) {
                            this.projectSelect = [
                                ...this.projectSelect,
                                {
                                    value: project.id,
                                    label: project.name,
                                },
                            ];
                        }
                    });
                    this.ref.markForCheck();
                },
                error => {
                    console.error(error);
                },
            );

        this.createFileForm = this.formBuilder.group({
            photos: ['', Validators.required],
        });

        this.route.params.pipe(untilDestroyed(this))
            .subscribe(params => {
                this.dataNetbaseService.setFieldDetailSource(parseInt(params.id as string, 10));
                this.ref.markForCheck();
            });

        this.dataService.setCompanyDataSource();
        this.dataService.setProjectsDataSource();
        this.dataNetbaseService.setTechnologyTypesDataSource();
        this.dataNetbaseService.setDiscountsDataSource();
        this.dataNetbaseService.setDealTypesDataSource();
        this.dataNetbaseService.setUnitTypesDataSource();
        this.dataNetbaseService.setSpaceTypesDataSource();
        this.dataNetbaseService.setNetPackagesDataSource();
        this.dataNetbaseService.setTvServicesDataSource();
        this.dataNetbaseService.setTransferTypesDataSource();
    }

    open(index: number): void {
        this.lightbox.open(this.imagesBasic, index);
    }

    close(): void {
        this.lightbox.close();
    }

    onCreate(): void {
        // Nastavení GUI podle URL parametrů
        this.route.queryParamMap.pipe(untilDestroyed(this))
            .subscribe(queryParams => {
                if (queryParams.get('view') === 'data') {
                    this.serviceFieldDetailTabObj.selectedItem = 0;
                } else if (queryParams.get('view') === 'files') {
                    this.serviceFieldDetailTabObj.selectedItem = 1;
                } else if (queryParams.get('view') === 'logs') {
                    this.serviceFieldDetailTabObj.selectedItem = 2;
                } else {
                    this.serviceFieldDetailTabObj.selectedItem = 0;
                }
            });
    }

    preventSwipe(e: SelectEventArgs): void {
        if (e.isSwiped) {
            e.cancel = true;
        }
    }

    select(e: SelectEventArgs): void {
        let queryParams: Params = {view: null};

        if (e.selectedItem.textContent === 'Data parcely') {
            queryParams = {
                view: 'data',
                form: null,
            };
        }

        if (e.selectedItem.textContent === 'Soubory a fotky') {
            queryParams = {
                view: 'files',
            };
        }

        if (e.selectedItem.textContent === 'Historie změn') {
            queryParams = {
                view: 'logs',
                form: null,
            };
        }

        void this.router.navigate([], {
            relativeTo: this.route,
            queryParams,
            queryParamsHandling: 'merge',
        });
    }

    onFilteringTvPackages: EmitType<FilteringEventArgs> = (e: FilteringEventArgs) => {
        if (e.text === '') {
            e.updateData(this.servicesTvSelect);
        } else {
            let query: Query = new Query();

            query = e.text !== '' ? query.where('label', 'contains', e.text, true, true) : query;
            e.updateData(this.servicesTvSelect, query);
        }
    };

    onFilteringNetPackages: EmitType<FilteringEventArgs> = (e: FilteringEventArgs) => {
        if (e.text === '') {
            e.updateData(this.servicesNetSelect);
        } else {
            let query: Query = new Query();

            query = e.text !== '' ? query.where('label', 'contains', e.text, true, true) : query;
            e.updateData(this.servicesNetSelect, query);
        }
    };

    // eslint-disable-next-line complexity
    saveChanges(): void {
        if (this.currentUser && this.permissionService.checkUserISServiceAdmin(this.currentUser)) {
            const data: FieldInput = this.field as FieldInput;

            data.address = (document.getElementById('ctrl_address') as HTMLInputElement).value
                ? (document.getElementById('ctrl_address') as HTMLInputElement).value
                : this.field?.address;

            data.field_number = (document.getElementById('ctrl_field_number') as HTMLInputElement)
                .value
                ? parseInt(
                    (document.getElementById('ctrl_field_number') as HTMLInputElement).value,
                    10,
                )
                : this.field?.field_number;

            data.street = (document.getElementById('ctrl_street') as HTMLInputElement).value
                ? (document.getElementById('ctrl_street') as HTMLInputElement).value
                : this.field?.address_obj
                    ? this.field.address_obj.street
                    : null;

            data.city = (document.getElementById('ctrl_city') as HTMLInputElement).value
                ? (document.getElementById('ctrl_city') as HTMLInputElement).value
                : this.field?.address_obj
                    ? this.field.address_obj.city
                    : null;

            data.zip = (document.getElementById('ctrl_zip') as HTMLInputElement).value
                ? parseInt((document.getElementById('ctrl_zip') as HTMLInputElement).value, 10)
                : this.field?.address_obj
                    ? this.field.address_obj.zip
                    : null;

            data.city_part = (document.getElementById('ctrl_city_part') as HTMLInputElement).value
                ? (document.getElementById('ctrl_city_part') as HTMLInputElement).value
                : this.field?.address_obj
                    ? this.field.address_obj.city_part
                    : null;

            data.district = (document.getElementById('ctrl_district') as HTMLInputElement).value
                ? (document.getElementById('ctrl_district') as HTMLInputElement).value
                : this.field?.address_obj
                    ? this.field.address_obj.district
                    : null;

            data.region = (document.getElementById('ctrl_region') as HTMLInputElement).value
                ? (document.getElementById('ctrl_region') as HTMLInputElement).value
                : this.field?.address_obj
                    ? this.field.address_obj.region
                    : null;

            data.state = (document.getElementById('ctrl_state') as HTMLInputElement).value
                ? (document.getElementById('ctrl_state') as HTMLInputElement).value
                : this.field?.address_obj
                    ? this.field.address_obj.state
                    : null;

            data.gps = (document.getElementById('ctrl_gps') as HTMLInputElement).value
                ? (document.getElementById('ctrl_gps') as HTMLInputElement).value
                : this.field?.address_obj
                    ? this.field.address_obj.gps
                    : null;

            data.jstk = (document.getElementById('ctrl_jstk') as HTMLInputElement).value
                ? (document.getElementById('ctrl_jstk') as HTMLInputElement).value
                : this.field?.address_obj
                    ? this.field.address_obj.jstk
                    : null;

            data.number_of_units = (
                document.getElementById('ctrl_number_of_units') as HTMLInputElement
            ).value
                ? parseInt(
                    (document.getElementById('ctrl_number_of_units') as HTMLInputElement).value,
                    10,
                )
                : this.field?.number_of_units;

            data.number_of_storeys = (
                document.getElementById('ctrl_number_of_storeys') as HTMLInputElement
            ).value
                ? parseInt(
                    (document.getElementById('ctrl_number_of_storeys') as HTMLInputElement).value,
                    10,
                )
                : this.field?.number_of_storeys;

            data.unit_id = this.unitTypeObj.value
                ? (this.unitTypeObj.value as number)
                : this.field?.unit_id;

            data.space_id = this.flatSpaceObj.value
                ? (this.flatSpaceObj.value as number)
                : this.field?.space_id;

            data.owner_name = (document.getElementById('ctrl_owner_name') as HTMLInputElement).value
                ? (document.getElementById('ctrl_owner_name') as HTMLInputElement).value
                : this.field?.owner_name;

            data.manager_name = (document.getElementById('ctrl_manager_name') as HTMLInputElement)
                .value
                ? (document.getElementById('ctrl_manager_name') as HTMLInputElement).value
                : this.field?.manager_name;

            data.provider_id = this.providerObj.value
                ? (this.providerObj.value as number)
                : this.field?.provider_id;

            data.technology_id = this.technologyObj.value
                ? (this.technologyObj.value as number)
                : this.field?.technology_id;

            data.transfer_id = this.transferObj.value
                ? (this.transferObj.value as number)
                : this.field?.transfer_id;

            data.electrical_counter = (
                document.getElementById('ctrl_electrical_counter') as HTMLInputElement
            ).value
                ? (document.getElementById('ctrl_electrical_counter') as HTMLInputElement).value
                : this.field?.electrical_counter;

            data.descriptive = (document.getElementById('ctrl_descriptive') as HTMLInputElement)
                .value
                ? (document.getElementById('ctrl_descriptive') as HTMLInputElement).value
                : this.field?.descriptive;

            data.business_note = (document.getElementById('ctrl_business_note') as HTMLInputElement)
                .value
                ? (document.getElementById('ctrl_business_note') as HTMLInputElement).value
                : this.field?.business_note;

            data.net_packages = this.netPackagesObj?.value
                ? (this.netPackagesObj.value as number[])
                : this.field?.net_packages;

            data.tv_services = this.tvServicesObj?.value
                ? (this.tvServicesObj.value as number[])
                : this.field?.tv_services;

            data.project_id = this.projectObj.value
                ? (this.projectObj.value as number)
                : this.field?.project_id;

            data.realized_at = (document.getElementById('realized_at') as HTMLInputElement).value
                ? (document.getElementById('realized_at') as HTMLInputElement).value
                : this.field?.realized_at;

            data.deal_id = this.dealTypeObj.value
                ? (this.dealTypeObj.value as number)
                : this.field?.deal_id;

            data.discount_id = this.discountObj.value
                ? (this.discountObj.value as number)
                : this.field?.discount_id;

            data.marketingnote = (document.getElementById('ctrl_marketingnote') as HTMLInputElement)
                .value
                ? (document.getElementById('ctrl_marketingnote') as HTMLInputElement).value
                : this.field?.marketingnote;

            data.marketing_ads = (document.getElementById('ctrl_marketing_ads') as HTMLInputElement)
                .value
                ? (document.getElementById('ctrl_marketing_ads') as HTMLInputElement).value
                : this.field?.marketing_ads;
            this.editField(data);
        } else {
            const body = 'Nemáte oprávnění provést tuto akci...';
            const options = {progressBar: true, timeOut: 5000};

            this.messages.showError('Nedostatečné oprávnění', body, options);
            this.loadingAddress = false;
            this.ref.markForCheck();
        }
    }

    editField(data: FieldInput): void {
        if (this.currentUser && this.permissionService.checkUserISServiceAdmin(this.currentUser)) {
            this.submited = true;
            this.loadingAddress = true;
            this.loadingAddress = false;
            this.servicesService
                .updateAddressField(data)
                ?.pipe(untilDestroyed(this))
                .subscribe(
                    () => {
                        const options = {progressBar: true, timeOut: 5000};

                        this.messages.showSuccess('Parcela úspěšně upravena', '', options);
                        this.loadingAddress = false;
                        this.ref.markForCheck();

                        if (!this.field) {
                            console.error('this.field is missing...');

                            return;
                        }

                        this.dataNetbaseService.setFieldDetailSource(this.field.id);
                    },
                    error => {
                        console.error(error);

                        const body = 'Zkuste to znovu později';
                        const options = {progressBar: true, timeOut: 5000};

                        this.messages.showError('Chyba při úpravš parcely', body, options);
                        this.loadingAddress = false;
                        this.ref.markForCheck();
                    },
                );
        } else {
            const body = 'Nemáte oprávnění provést tuto akci...';
            const options = {progressBar: true, timeOut: 5000};

            this.messages.showError('Nedostatečné oprávnění', body, options);
            this.loadingAddress = false;
            this.ref.markForCheck();
        }
    }

    removeField(): void {
        if (
            this.field &&
            this.currentUser &&
            this.permissionService.checkUserISServiceAdmin(this.currentUser)
        ) {
            this.field.deleted_by = this.currentUser.id;
            this.servicesService
                .deleteAddressField(this.field)
                ?.pipe(untilDestroyed(this))
                .subscribe(
                    (data: AddressConnectedField) => {
                        const body = `Parcela #${data.id}`;
                        const options = {
                            progressBar: true,
                            timeOut: 5000,
                            toastClass: 'success',
                        };

                        this.messages.showSuccess('Parcela úspěšně smazána', body, options);
                        this.loadingAddress = false;
                        this.ref.markForCheck();

                        if (!this.field) {
                            console.error('this.field is missing...');

                            return;
                        }

                        this.dataNetbaseService.setFieldDetailSource(this.field.id);
                    },
                    error => {
                        console.error(error);
                        this.loadingAddress = false;
                        this.ref.markForCheck();
                    },
                );
        } else {
            const body = 'Nemáte oprávnění provést tuto akci...';
            const options = {progressBar: true, timeOut: 5000};

            this.messages.showError('Nedostatečné oprávnění', body, options);
            this.loadingAddress = false;
            this.ref.markForCheck();
        }
    }

    // FILES
    applyDeafultUploadSetting(): void {
        if (this.uploadObj && this.field) {
            this.uploadObj.asyncSettings.saveUrl = `${this.environmentService.backendURL}/api/upload/service?type=netbase_services&subfolder=field&id=${this.field.id}`;
        }
    }

    downloadFile(file: AddressConnectedFieldPhotos): void {
        this.servicesService
            .downloadServiceFile(file)
            .pipe(untilDestroyed(this))
            .subscribe(
                data => {
                    saveAs(data, file.name);
                },
                err => {
                    alert('Chyba během stahování souboru.');
                    console.error(err);
                },
            );
    }

    onFileSelected(args: SelectedEventArgs): void {
        // Filter the 5 files only to showcase
        args.filesData.splice(5);

        const filesData: FileInfo[] | undefined = this.uploadObj?.getFilesData();
        const allFiles: FileInfo[] | undefined = filesData?.concat(args.filesData);

        if (allFiles && allFiles.length > 5) {
            // eslint-disable-next-line @typescript-eslint/prefer-for-of
            for (let i = 0; i < allFiles.length; i++) {
                if (allFiles.length > 5) {
                    allFiles.shift();
                }
            }

            args.filesData = allFiles;
            // set the modified custom data
            args.modifiedFilesData = args.filesData;
        }

        let existingFiles: FileInfo[] | undefined = this.uploadObj?.getFilesData();

        if (existingFiles) {
            for (let i = 0; i < args.filesData.length; i++) {
                // eslint-disable-next-line @typescript-eslint/prefer-for-of
                for (let j = 0; j < existingFiles.length; j++) {
                    if (!isNullOrUndefined(args.filesData[i])) {
                        // eslint-disable-next-line eqeqeq
                        if (existingFiles[j].name == args.filesData[i].name) {
                            args.filesData.splice(i, 1);
                        }
                    }
                }
            }

            existingFiles = existingFiles.concat(args.filesData);
            args.modifiedFilesData = existingFiles;
            args.isModified = true;
        }
    }

    beforeFileUpload(args: BeforeUploadEventArgs): void {
        if (this.field && this.currentUser) {
            args.customFormData = [
                {id: this.field.id},
                {created_by: this.currentUser.id},
                {valid_ruian: false},
            ];
        }
    }

    onFileUpload: EmitType<SelectedEventArgs> = (args: UploadingEventArgs) => {
        if (this.field && this.currentUser) {
            args.currentRequest?.setRequestHeader(
                'Authorization',
                `Bearer ${this.currentUser.token}`,
            );

            args.customFormData = [
                {id: this.field.id},
                {created_by: this.currentUser.id},
                {valid_ruian: false},
            ];
        }
    };

    onSuccessUpload(args: SuccessEventArgs): void {
        const body = `Soubor: ${args.file?.name ?? 'null'}`;
        const options = {progressBar: true, timeOut: 5000, toastClass: 'success'};

        this.messages.showSuccess('Soubor úspěšně nahrán', body, options);

        if (!this.field) {
            console.error('this.field is missing...');

            return;
        }

        this.dataNetbaseService.setFieldDetailSource(this.field);
    }

    onFileRemove(args: RemovingEventArgs): void {
        args.postRawFile = false;

        const photos = this.field?.photos?.find(file => file.name === args.filesData[0].name);

        if (photos) {
            this.fieldPhoto = photos;
        }

        this.fileRemoveDialog.show();
    }

    onConfirmRemove(): void {
        if (this.currentUser && this.permissionService.checkUserISServiceAdmin(this.currentUser)) {
            this.loadingAddress = true;
            this.fieldPhoto.deleted_by = this.currentUser.id;
            this.servicesService
                .deleteServiceFile(this.fieldPhoto, false)
                ?.pipe(untilDestroyed(this))
                .subscribe(
                    data => {
                        const body = `Soubor #${data?.id ?? 'null'}`;
                        const options = {
                            progressBar: true,
                            timeOut: 5000,
                            toastClass: 'success',
                        };

                        this.messages.showSuccess('Soubor úspěšně smazán', body, options);
                        this.fileRemoveDialog.hide();
                        this.loadingAddress = false;
                        this.ref.markForCheck();

                        if (!this.field) {
                            console.error('this.field is missing...');

                            return;
                        }

                        this.dataNetbaseService.setFieldDetailSource(this.field);
                    },
                    err => {
                        this.loadingAddress = false;
                        this.ref.markForCheck();
                        console.error(err);
                    },
                );
        } else {
            const body = 'Nemáte oprávnění provést tuto akci...';
            const options = {progressBar: true, timeOut: 5000};

            this.messages.showError('Nedostatečné oprávnění', body, options);
            this.loadingAddress = false;
            this.ref.markForCheck();
        }
    }

    newProject(): void {
        if (this.currentUser && this.permissionService.checkUserISServiceAdmin(this.currentUser)) {
            this.isCopy = false;
            this.isCreate = true;
            this.isUpdate = false;
            this.renderProjectFormDialog = true;
        } else {
            const body = 'Nemáte oprávnění provést tuto akci...';
            const options = {progressBar: true, timeOut: 5000};

            this.messages.showError('Nedostatečné oprávnění', body, options);
            this.loadingAddress = false;
            this.ref.markForCheck();
        }
    }
}
