<ejs-dialog
  #formDialog
  (close)="
        isUpdate = false;
        isCreate = false;
        isDirty = false;
        isChecked = false;
        submited = false;
        changeDialogState(false)
    "
  [isModal]="true"
  [showCloseIcon]="true"
  [visible]="isVisible"
  [width]="'85%'"
  id="formDialog"
>
  <form [formGroup]="budgetForm">
    <ng-template #content>
      <div class="dialogContent">
        <div class="row">
          <div class="col-12">
            <label
              [ngClass]="
                                f.department.valid &&
                                (f.department.dirty || f.department.touched)
                                    ? 'text-success'
                                    : f.department.invalid &&
                                      (f.department.dirty ||
                                          f.department.touched)
                                    ? 'text-danger'
                                    : 'text-muted'
                            "
              for="budgetForm-department"
            >* Pro oddělení:</label
            >
            <ejs-dropdownlist
              #department
              (filtering)="onFilteringDepartments($event)"
              [allowFiltering]="true"
              [cssClass]="
                                f.department.touched && f.department.invalid
                                    ? 'e-error'
                                    : f.department.touched && f.department.valid
                                    ? 'e-success'
                                    : ''
                            "
              [dataSource]="departmentSelect"
              [fields]="fields"
              [filterBarPlaceholder]="'Vyhledat oddělení...'"
              [ignoreAccent]="true"
              [placeholder]="'Vyberte oddělení'"
              [popupHeight]="height"
              formControlName="department"
              id="budgetForm-department"
              sortOrder="Ascending"
            ></ejs-dropdownlist>
            <small
              *ngIf="
                                f.department.touched &&
                                f.department.errors &&
                                f.department.errors.required
                            "
              class="text-danger"
            >Musíte vybrat jedno oddělení</small
            >
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-12">
            <label
              [ngClass]="
                                f.expire_at.valid &&
                                (f.expire_at.dirty || f.expire_at.touched) &&
                                f.start_at.valid &&
                                (f.start_at.dirty || f.start_at.touched)
                                    ? 'text-success'
                                    : (f.expire_at.invalid &&
                                          (f.expire_at.dirty ||
                                              f.expire_at.touched)) ||
                                      (f.start_at.invalid &&
                                          (f.start_at.dirty ||
                                              f.start_at.touched))
                                    ? 'text-danger'
                                    : 'text-muted'
                            "
            >* Rozpočtové období: </label
            ><br />
            <small class="text-muted"
            >Kontrola na expiraci rozpočtů se provádí
              automaticky každou hodinu.</small
            >
          </div>
          <div class="col-12 col-md-6">
            <label
              [ngClass]="
                                f.start_at.valid
                                    ? 'text-success'
                                    : f.start_at.invalid
                                    ? 'text-danger'
                                    : 'text-muted'
                            "
              for="budgetForm-start_at"
            >* Začátek rozpočtového období:</label
            >
            <ejs-datetimepicker
              [firstDayOfWeek]="1"
              [max]="f.expire_at.value ? f.expire_at.value : ''"
              floatLabelType="Always"
              formControlName="start_at"
              id="budgetForm-start_at"
            >
            </ejs-datetimepicker>
            <div class="d-flex justify-content-end">
              <small
                *ngIf="
                                    f.start_at.touched &&
                                    f.start_at.errors &&
                                    f.start_at.errors.required
                                "
                class="text-danger"
              >Datum začátku rozpočtového období je
                povinné</small
              >
              <small
                *ngIf="
                                    f.start_at.touched &&
                                    f.start_at.errors &&
                                    f.start_at.errors.greaterThan
                                "
                class="text-danger"
              >Datum začátku rozpočtového období je větší než
                datum konce</small
              >
            </div>
          </div>
          <div class="col-12 col-md-6">
            <label
              [ngClass]="
                                f.expire_at.valid
                                    ? 'text-success'
                                    : f.expire_at.invalid
                                    ? 'text-danger'
                                    : 'text-muted'
                            "
              for="budgetForm-expire_at"
            >* Konec rozpočtového období:</label
            >
            <ejs-datetimepicker
              [firstDayOfWeek]="1"
              [min]="f.start_at.value ? f.start_at.value : ''"
              floatLabelType="Always"
              formControlName="expire_at"
              id="budgetForm-expire_at"
            >
            </ejs-datetimepicker>
            <div class="d-flex justify-content-end">
              <small class="text-muted"
              >Datum expirace zadané do půlnoci, expiruje na
                začátku zadaného dne!</small
              ><br />
              <small
                *ngIf="
                                    f.expire_at.touched &&
                                    f.expire_at.errors &&
                                    f.expire_at.errors.required
                                "
                class="text-danger"
              >Datum konce rozpočtového období je
                povinné</small
              ><br />
              <small
                *ngIf="
                                    f.expire_at.touched &&
                                    f.expire_at.errors &&
                                    f.expire_at.errors.lessThan
                                "
                class="text-danger"
              >Datum konce rozpočtového období je menší než
                datum začátku</small
              ><br />
            </div>
          </div>
        </div>
        <div
          *ngIf="
                        isUpdate ||
                        isCopy ||
                        (isCreate &&
                            !matchedActiveBudget &&
                            f.expire_at.valid &&
                            f.start_at.valid &&
                            f.department.valid)
                    "
          class="row"
        >
          <div class="col-12 col-md-6 mb-2">
            <label
              [ngClass]="
                                f.name.valid && (f.name.dirty || f.name.touched)
                                    ? 'text-success'
                                    : f.name.invalid &&
                                      (f.name.dirty || f.name.touched)
                                    ? 'text-danger'
                                    : 'text-muted'
                            "
              for="budgetForm-name"
            >* Název rozpočtu:</label
            >
            <ejs-textbox
              [ngClass]="
                                f.name.touched && f.name.invalid
                                    ? 'e-error'
                                    : f.name.touched && f.name.valid
                                    ? 'e-success'
                                    : ''
                            "
              floatLabelType="Auto"
              formControlName="name"
              id="budgetForm-name"
              required
            ></ejs-textbox>
            <small
              *ngIf="
                                f.name.touched &&
                                f.name.errors &&
                                f.name.errors.required
                            "
              class="text-danger"
            >Název rozpočtu je povinný</small
            >
          </div>
          <div class="col-12 col-md-6 mb-2">
            <label
              [ngClass]="
                                f.amount.valid &&
                                (f.amount.dirty || f.amount.touched)
                                    ? 'text-success'
                                    : f.amount.invalid &&
                                      (f.amount.dirty || f.amount.touched)
                                    ? 'text-danger'
                                    : 'text-muted'
                            "
              class="active"
              for="budgetForm-amount"
            >* Částka rozpočtu:</label
            >
            <ejs-numerictextbox
              [format]="'N2'"
              [ngClass]="
                                f.amount.touched && f.amount.invalid
                                    ? 'e-error'
                                    : f.amount.touched && f.amount.valid
                                    ? 'e-success'
                                    : ''
                            "
              formControlName="amount"
              id="budgetForm-amount"
              min="0.01"
              step="0.01"
            ></ejs-numerictextbox>
            <small
              *ngIf="
                                f.amount.touched &&
                                f.amount.errors &&
                                f.amount.errors.required
                            "
              class="text-danger"
            >Částka rozpočtu je povinná</small
            >
          </div>
          <div class="col-12">
            <label
              [ngClass]="
                                f.approvers.valid &&
                                (f.approvers.dirty || f.approvers.touched)
                                    ? 'text-success'
                                    : f.approvers.invalid &&
                                      (f.approvers.dirty || f.approvers.touched)
                                    ? 'text-danger'
                                    : 'text-muted'
                            "
              for="budgetForm-approvers"
            >* Schvalovatel(é) objednávek v rozpočtu:</label
            >
            <ejs-multiselect
              #approvers
              (filtering)="onFilteringUsers($event)"
              [allowFiltering]="true"
              [cssClass]="
                                f.approvers.touched && f.approvers.invalid
                                    ? 'e-error'
                                    : f.approvers.touched && f.approvers.valid
                                    ? 'e-success'
                                    : ''
                            "
              [dataSource]="usersSelect"
              [fields]="fields"
              [filterBarPlaceholder]="'Vyhledat uživatele...'"
              [ignoreAccent]="true"
              [mode]="'CheckBox'"
              [placeholder]="'Vyberte uživatele'"
              [popupHeight]="height"
              [selectAllText]="'Všechny uživatele'"
              formControlName="approvers"
              id="budgetForm-approvers"
              showSelectAll="true"
              sortOrder="Ascending"
            ></ejs-multiselect>
            <small
              *ngIf="
                                f.approvers.touched &&
                                f.approvers.errors &&
                                f.approvers.errors.required
                            "
              class="text-danger"
            >Vyberte alespoň jednoho uživatele</small
            >
          </div>
          <div class="col-12">
            <label
              [ngClass]="
                                f.viewers.valid &&
                                (f.viewers.dirty || f.viewers.touched)
                                    ? 'text-success'
                                    : f.viewers.invalid &&
                                      (f.viewers.dirty || f.viewers.touched)
                                    ? 'text-danger'
                                    : 'text-muted'
                            "
              for="budgetForm-viewers"
            >* Diváci objednávek v rozpočtu:</label
            >
            <ejs-multiselect
              #viewers
              (filtering)="onFilteringUsers($event)"
              [allowFiltering]="true"
              [cssClass]="
                                f.viewers.touched && f.viewers.invalid
                                    ? 'e-error'
                                    : f.viewers.touched && f.viewers.valid
                                    ? 'e-success'
                                    : ''
                            "
              [dataSource]="usersSelect"
              [fields]="fields"
              [filterBarPlaceholder]="'Vyhledat uživatele...'"
              [ignoreAccent]="true"
              [mode]="'CheckBox'"
              [placeholder]="'Vyberte uživatele'"
              [popupHeight]="height"
              [selectAllText]="'Všechny uživatele'"
              formControlName="viewers"
              id="budgetForm-viewers"
              showSelectAll="true"
              sortOrder="Ascending"
            ></ejs-multiselect>
            <small
              *ngIf="
                                f.viewers.touched &&
                                f.viewers.errors &&
                                f.viewers.errors.required
                            "
              class="text-danger"
            >Vyberte alespoň jednoho diváka</small
            >
          </div>
        </div>
        <div
          *ngIf="
                        matchedActiveBudget &&
                        f.expire_at.valid &&
                        f.start_at.valid &&
                        f.department.valid
                    "
          class="alert alert-warning p-1 mt-2"
          role="alert"
        >
          <i
            aria-hidden="true"
            class="fa-light fa-lg fa-exclamation-triangle mr-2"
          ></i
          >Toto oddělení má <strong> aktivní rozpočet </strong> v
          zadaném období {{ f.start_at.value | date: 'dd.MM.yyyy' }} -
          {{ f.expire_at.value | date: 'dd.MM.yyyy' }}.
          <hr />
          <small>
            <a
              routerLink="/material/budgets/{{
                                matchedActiveBudget.id
                            }}"
            >{{ matchedActiveBudget.name }}</a
            >:
            {{ matchedActiveBudget.start_at | date: 'dd.MM.yyyy' }}
            -
            {{ matchedActiveBudget.expire_at | date: 'dd.MM.yyyy' }}
          </small>
        </div>
      </div>
    </ng-template>
    <ng-template #footerTemplate>
      <div class="d-flex justify-content-end">
                <span
                  *ngIf="budgetForm.invalid"
                  class="e-badge e-badge-warning p-2 size_2"
                  role="alert"
                ><strong>Formulář je špatně vyplněný</strong>
                </span>
        <span
          *ngIf="budgetForm.valid"
          class="e-badge e-badge-success p-2 size_2"
          role="alert"
        ><strong>Formulář je správně vyplněný</strong>
                </span>
      </div>
      <div class="row">
        <div class="col-12 pt-2">
          <button
            (click)="onSubmit()"
            [disabled]="
                            budgetLoading ||
                            budgetForm.invalid ||
                            !isChecked ||
                            (matchedActiveBudget &&
                                f.expire_at.valid &&
                                f.start_at.valid &&
                                f.department.valid)
                        "
            cssClass="e-btn e-success btn-p-zero e-small mr-1"
            ejs-button
            type="submit"
          ><span
            *ngIf="budgetLoading === true"
            aria-hidden="true"
            class="spinner-grow spinner-grow-sm"
            role="status"
          ></span>
            <span *ngIf="budgetLoading === false">ULOŽIT</span>
            <span *ngIf="budgetLoading === true">Provádím...</span>
          </button>
          <button
            (click)="formDialogObj.hide()"
            cssClass="e-btn e-danger e-outline btn-p-zero e-small"
            ejs-button
            type="button"
          >
            ZRUŠIT
          </button>
        </div>
      </div>
    </ng-template>
  </form>
</ejs-dialog>
