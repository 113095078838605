import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {Knowledge} from '@src/app/_models/knowledge/knowledge';
import {catchError, tap} from 'rxjs/operators';
import {MessageService} from '@src/app/_services/message.service';
import {KnowledgeFile} from '@src/app/_models/knowledge/knowledge-file';
import {Department} from '@src/app/_models/department/department';
import {User} from '@src/app/_models/user/user';
import {KnowledgeAssignation} from '@src/app/_models/assignation/knowledge_assignation';
import {KnowledgeCategory} from '@src/app/_models/knowledge/knowledge-category';
import {DataService} from '@src/app/_services/data.service';
import {EnvironmentService} from '@src/app/_services/environment.service';
import {SafeHtml} from '@angular/platform-browser';
import {KnowledgeComment} from '@src/app/_models/knowledge/knowledge-comment';

const httpOptions = {
    headers: new HttpHeaders({'Content-Type': 'application/json'}),
};

export interface KnowledgeInput {
    id?: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    category_id: number;
    maintask: string;
    subject: string;
    users: User[];
    departments: Department[];
    // eslint-disable-next-line @typescript-eslint/naming-convention
    updated_by?: number;
}

export interface KnowledgeGridItems {
    id: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    created_by: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    creator_str: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    creator_img: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    category_id: number;
    subject: string;
    category: string;
    maintask: string;
    maintaskHTML: SafeHtml;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    created_date: Date;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    updated_date: Date | null;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    deleted_date: Date | null;
    files: KnowledgeFile[];
    users: User[];
    // eslint-disable-next-line @typescript-eslint/naming-convention
    users_str: string;
    departments: Department[];
    // eslint-disable-next-line @typescript-eslint/naming-convention
    departments_str: string;
    comments: KnowledgeComment[];
}

@Injectable({
    providedIn: 'root',
})
export class KnowledgeService {
    private readonly knowledgeUrl: string;

    private readonly knowledgeAssignationUrl: string;

    private readonly knowledgesFilesUrl: string;

    private readonly knowledgeFileUrl: string;

    knowledges: Observable<Knowledge[]>;

    knowledge: Observable<Knowledge>;

    categories: Observable<KnowledgeCategory[]>;

    constructor(
        private readonly http: HttpClient,
        private readonly dataService: DataService,
        private readonly messageService: MessageService,
        private readonly environmentService: EnvironmentService,
    ) {
        this.knowledgeUrl = this.environmentService.backendURL + '/api/knowledge';
        this.knowledgeAssignationUrl =
            this.environmentService.backendURL + '/api/knowledge/membership';
        this.knowledgesFilesUrl = this.environmentService.backendURL + '/api/file/download/';
        this.knowledgeFileUrl = this.environmentService.backendURL + '/api/file';
    }

    /**
     * Handle Http operation that failed.
     * Let the app continue.
     * @param operation - name of the operation that failed
     * @param result - optional value to return as the observable result
     */
    private handleError<T>(operation = 'operation', result?: T) {
        return (error: {[key: string]: string}): Observable<T | undefined> => {
            console.error(error);
            this.log(`${operation} failed: ${error.message}`);

            return of(result);
        };
    }

    /** Log a knowledgeService message with the MessageService */
    private log(message: string): void {
        this.messageService.addNotification(`Knowledge: ${message}`, false);
    }

    addKnowledge(knowledge: KnowledgeInput): Observable<Knowledge> {
        this.dataService.clearKnowledgesCache();

        return this.http.post<Knowledge>(this.knowledgeUrl, knowledge, httpOptions);
    }

    deleteKnowledge(knowledge: Knowledge): Observable<Knowledge> {
        const url = `${this.knowledgeUrl}/kwb/${knowledge.id}`;

        this.dataService.clearKnowledgesCache();

        return this.http.delete<Knowledge>(url, httpOptions);
    }

    updateKnowledge(knowledge: KnowledgeInput): Observable<Knowledge> | undefined {
        if (!knowledge.id) {
            console.error('ID is missing...');

            return;
        }

        const url = `${this.knowledgeUrl}/update/${knowledge.id}`;

        this.dataService.clearKnowledgesCache();

        return this.http.put<Knowledge>(url, knowledge, httpOptions);
    }

    setUsers(
        knowledge: Knowledge | number,
        users: User | number,
    ): Observable<KnowledgeAssignation | undefined> {
        const userId = typeof users === 'number' ? users : users.id;
        const knowledgeId = typeof knowledge === 'number' ? knowledge : knowledge.id;

        this.dataService.clearKnowledgesCache();

        return this.http
            .post<KnowledgeAssignation>(
                `${this.knowledgeAssignationUrl}`,
                {knowledge_id: knowledgeId, user_id: userId},
                httpOptions,
            )
            .pipe(
                tap(() => {
                    this.log(`updated ticket id=${knowledgeId}`);
                }),
                catchError(this.handleError<KnowledgeAssignation>(`ticket id=${knowledgeId}`)),
            );
    }

    setDepartments(
        knowledge: Knowledge | number,
        departments: Department | number,
    ): Observable<KnowledgeAssignation | undefined> {
        const departmentId = typeof departments === 'number' ? departments : departments.id;
        const knowledgeId = typeof knowledge === 'number' ? knowledge : knowledge.id;

        this.dataService.clearKnowledgesCache();

        return this.http
            .post<KnowledgeAssignation>(
                `${this.knowledgeAssignationUrl}`,
                {
                    knowledge_id: knowledgeId,
                    department_id: departmentId,
                },
                httpOptions,
            )
            .pipe(
                tap(() => {
                    this.log(`updated ticket id=${knowledgeId}`);
                }),
                catchError(this.handleError<KnowledgeAssignation>(`ticket id=${knowledgeId}`)),
            );
    }

    deleteDepartmentAssignation(
        department: Department | number,
        knowledge: Knowledge | number,
    ): Observable<KnowledgeAssignation | undefined> {
        const departmentId = typeof department === 'number' ? department : department.id;
        const knowledgeId = typeof knowledge === 'number' ? knowledge : knowledge.id;
        const headers = new HttpHeaders({'Content-Type': 'application/json'});
        const params = new HttpParams()
            .set('department_id', departmentId.toString())
            .set('knowledge_id', knowledgeId.toString());

        this.dataService.clearKnowledgesCache();

        return this.http.delete<KnowledgeAssignation>(`${this.knowledgeAssignationUrl}`, {
            headers,
            params,
        });
    }

    deleteUserAssignation(
        user: User | number,
        knowledge: Knowledge | number,
    ): Observable<KnowledgeAssignation | undefined> {
        const userId = typeof user === 'number' ? user : user.id;
        const knowledgeId = typeof knowledge === 'number' ? knowledge : knowledge.id;
        const headers = new HttpHeaders({'Content-Type': 'application/json'});
        const params = new HttpParams()
            .set('user_id', userId.toString())
            .set('knowledge_id', knowledgeId.toString());

        this.dataService.clearKnowledgesCache();

        return this.http.delete<KnowledgeAssignation>(`${this.knowledgeAssignationUrl}`, {
            headers,
            params,
        });
    }

    downloadFile(file: KnowledgeFile): Observable<Blob | KnowledgeFile | undefined> {
        return this.http
            .post(
                `${this.knowledgesFilesUrl}`,
                {file: file.name, module: 'knowledge', id: file.knowledge_id},
                {
                    responseType: 'blob',
                    headers: new HttpHeaders().append('Content-Type', 'application/json'),
                },
            )
            .pipe(
                tap(() => {
                    this.log(`start download knowledge file name=${file.name}`);
                }),
                catchError(this.handleError<KnowledgeFile>(`file name=${file.name}`)),
            );
    }

    deleteFile(file: KnowledgeFile): Observable<Blob | KnowledgeFile | undefined> {
        const headers = new HttpHeaders({'Content-Type': 'application/json'});
        const params = new HttpParams()
            .set('type', 'knowledge')
            .set('deleted_by', file.deleted_by?.toString() ?? 'null');
        const url = `${this.knowledgeFileUrl}/${file.id}`;

        this.dataService.clearKnowledgesCache();

        return this.http.delete<KnowledgeFile>(url, {headers, params});
    }
}
