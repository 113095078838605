import {Injectable} from '@angular/core';
import {catchError, shareReplay, tap} from 'rxjs/operators';
import {User} from '@src/app/_models/user/user';
import {Tickets} from '@src/app/_models/ticket/tickets';
import {Task} from '@src/app/_models/task/task';
import {Vacation} from '@src/app/_models/vacation/vacation';
import {Material} from '@src/app/_models/material/material';
import {Observable, of, Subject} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {MessageService} from '@src/app/_services/message.service';
import {MaterialBudget} from '@src/app/_models/material/material-budget';
import {KnowledgeComment} from '@src/app/_models/knowledge/knowledge-comment';
import {TicketComment} from '@src/app/_models/ticket/ticket-comment';
import {Knowledge} from '@src/app/_models/knowledge/knowledge';
import {EnvironmentService} from '@src/app/_services/environment.service';

export interface ElasticResult {
    hits: {
        hits: [
            {
                [key: string]:
                    | Knowledge
                    | KnowledgeComment
                    | Material
                    | MaterialBudget
                    | Task
                    | TicketComment
                    | Tickets
                    | User
                    | Vacation
                    | boolean
                    | number
                    | string;
            },
        ];
        total: {value: number};
    };
}

@Injectable({
    providedIn: 'root',
})
export class ElasticsearchService {
    /* private queryalldocs = {
         query: {
             match_all: {}
         }
     };*/
    private readonly statusUrl: string;

    private readonly ticketsUrl: string;

    private readonly tasksUrl: string;

    private readonly usersUrl: string;

    private readonly materialsUrl: string;

    private readonly vacationsUrl: string;

    private readonly budgetsUrl: string;

    private readonly knowledgesUrl: string;

    private readonly ticketCommentsUrl: string;

    private readonly knowledgeCommentsUrl: string;

    private readonly resultUserSource = new Subject();

    private readonly resultTicketSource = new Subject();

    private readonly resultTaskSource = new Subject();

    private readonly resultVacationSource = new Subject();

    private readonly resultMaterialSource = new Subject();

    private readonly resultBudgetSource = new Subject();

    private readonly resultKnowledgeSource = new Subject();

    private readonly resultTicketCommentSource = new Subject();

    private readonly resultKnowledgeCommentSource = new Subject();

    currentUserResult = this.resultUserSource.asObservable();

    currentTicketResult = this.resultTicketSource.asObservable();

    currentTaskResult = this.resultTaskSource.asObservable();

    currentVacationResult = this.resultVacationSource.asObservable();

    currentMaterialResult = this.resultMaterialSource.asObservable();

    currentBudgetResult = this.resultBudgetSource.asObservable();

    currentKnowledgeResult = this.resultKnowledgeSource.asObservable();

    currentTicketCommentResult = this.resultTicketCommentSource.asObservable();

    currentKnowledgeCommentResult = this.resultKnowledgeCommentSource.asObservable();

    constructor(
        private readonly http: HttpClient,
        private readonly messageService: MessageService,
        private readonly environmentService: EnvironmentService,
    ) {
        this.statusUrl = this.environmentService.backendURL + '/api/plugin/elastic';
        this.ticketsUrl = this.environmentService.backendURL + '/api/search/ticket';
        this.tasksUrl = this.environmentService.backendURL + '/api/search/task';
        this.usersUrl = this.environmentService.backendURL + '/api/search/user';
        this.materialsUrl = this.environmentService.backendURL + '/api/search/material';
        this.vacationsUrl = this.environmentService.backendURL + '/api/search/vacation';
        this.budgetsUrl = this.environmentService.backendURL + '/api/search/budget';
        this.knowledgesUrl = this.environmentService.backendURL + '/api/search/knowledge';
        this.ticketCommentsUrl = this.environmentService.backendURL + '/api/search/comments/ticket';
        this.knowledgeCommentsUrl =
            this.environmentService.backendURL + '/api/search/comments/knowledge';
    }

    /**
     * Handle Http operation that failed.
     * Let the app continue.
     * @param operation - name of the operation that failed
     * @param result - optional value to return as the observable result
     */
    private handleError<T>(operation = 'operation', result?: T) {
        return (error: {[key: string]: string}): Observable<T | undefined> => {
            console.error(error);
            this.log(`${operation} failed: ${error.message}`);

            return of(result);
        };
    }

    /** Log a userService message with the MessageService */
    private log(message: string): void {
        this.messageService.addNotification(`User: ${message}`, false);
    }

    isAvailable(): Observable<unknown> {
        return this.http.get<unknown>(this.statusUrl)
            .pipe(
                tap(() => {
                    this.log('elastic status');
                }),
                shareReplay(),
                catchError(this.handleError<User[]>('getStatus', [])),
            );
    }

    changeUserResults(hits: User[]): void {
        this.resultUserSource.next(hits);
    }

    changeTicketResults(hits: Tickets[]): void {
        this.resultTicketSource.next(hits);
    }

    changeTaskResults(hits: Task[]): void {
        this.resultTaskSource.next(hits);
    }

    changeVacationResults(hits: Vacation[]): void {
        this.resultVacationSource.next(hits);
    }

    changeMaterialResults(hits: Material[]): void {
        this.resultMaterialSource.next(hits);
    }

    changeBudgetResults(hits: MaterialBudget[]): void {
        this.resultBudgetSource.next(hits);
    }

    changeKnowledgeResults(hits: Knowledge[]): void {
        this.resultKnowledgeSource.next(hits);
    }

    changeTicketCommentResults(hits: TicketComment[]): void {
        this.resultTicketCommentSource.next(hits);
    }

    changeKnowledgeCommentResults(hits: KnowledgeComment[]): void {
        this.resultKnowledgeCommentSource.next(hits);
    }

    /*
    getAllDocuments(index: string, type: string): any {
    }*/

    searchUserDocuments(queryText: string): Observable<ElasticResult | undefined> {
        const url = `${this.usersUrl}/${queryText}`;

        return this.http.get<ElasticResult>(url)
            .pipe(
                tap(() => {
                    this.log('fetched users suggestions');
                }),
                shareReplay(),
                catchError(this.handleError<ElasticResult>('getSuggestion user')),
            );
    }

    searchTicketDocuments(queryText: string): Observable<ElasticResult | undefined> {
        const url = `${this.ticketsUrl}/${queryText}`;

        return this.http.get<ElasticResult>(url)
            .pipe(
                tap(() => {
                    this.log('fetched tickets suggestions');
                }),
                shareReplay(),
                catchError(this.handleError<ElasticResult>('getSuggestion ticket')),
            );
    }

    searchTaskDocuments(queryText: string): Observable<ElasticResult | undefined> {
        const url = `${this.tasksUrl}/${queryText}`;

        return this.http.get<ElasticResult>(url)
            .pipe(
                tap(() => {
                    this.log('fetched tasks suggestions');
                }),
                shareReplay(),
                catchError(this.handleError<ElasticResult>('getSuggestion task')),
            );
    }

    searchVacationDocuments(queryText: string): Observable<ElasticResult | undefined> {
        const url = `${this.vacationsUrl}/${queryText}`;

        return this.http.get<ElasticResult>(url)
            .pipe(
                tap(() => {
                    this.log('fetched vacations suggestions');
                }),
                shareReplay(),
                catchError(this.handleError<ElasticResult>('getSuggestion vacation')),
            );
    }

    searchMaterialDocuments(queryText: string): Observable<ElasticResult | undefined> {
        const url = `${this.materialsUrl}/${queryText}`;

        return this.http.get<ElasticResult>(url)
            .pipe(
                tap(() => {
                    this.log('fetched materials suggestions');
                }),
                shareReplay(),
                catchError(this.handleError<ElasticResult>('getSuggestion material')),
            );
    }

    searchBudgetDocuments(queryText: string): Observable<ElasticResult | undefined> {
        const url = `${this.budgetsUrl}/${queryText}`;

        return this.http.get<ElasticResult>(url)
            .pipe(
                tap(() => {
                    this.log('fetched budgets suggestions');
                }),
                shareReplay(),
                catchError(this.handleError<ElasticResult>('getSuggestion budget')),
            );
    }

    searchKnowledgeDocuments(queryText: string): Observable<ElasticResult | undefined> {
        const url = `${this.knowledgesUrl}/${queryText}`;

        return this.http.get<ElasticResult>(url)
            .pipe(
                tap(() => {
                    this.log('fetched knowledges suggestions');
                }),
                shareReplay(),
                catchError(this.handleError<ElasticResult>('getSuggestion knowledge')),
            );
    }

    searchTicketCommentsDocuments(queryText: string): Observable<ElasticResult | undefined> {
        const url = `${this.ticketCommentsUrl}/${queryText}`;

        return this.http.get<ElasticResult>(url)
            .pipe(
                tap(() => {
                    this.log('fetched ticket comments suggestions');
                }),
                shareReplay(),
                catchError(this.handleError<ElasticResult>('getSuggestion ticket comments')),
            );
    }

    searchKnowledgeCommentsDocuments(queryText: string): Observable<ElasticResult | undefined> {
        const url = `${this.knowledgeCommentsUrl}/${queryText}`;

        return this.http.get<ElasticResult>(url)
            .pipe(
                tap(() => {
                    this.log('fetched knowledge comments suggestions');
                }),
                shareReplay(),
                catchError(this.handleError<ElasticResult>('getSuggestion knowledge comments')),
            );
    }
}
