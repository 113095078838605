<div class="e-card">
  <ng-container *ngIf="task">
    <div class="e-card-header p-2">
      <div class="e-card-header-caption">
        <div
          class="d-flex justify-content-between e-card-header-title"
        >
          <strong>Úkol</strong>
          <span>ID: {{ task.id }}</span>
        </div>
        <div *ngIf="task.finished_at" class="text-muted">
          <small
            class="font-weight-bold"
          >
            dokončeno: {{ task.finished_at | dateFormat: 'DD.MM.YYYY HH:mm' }}
          </small>
        </div>
        <div class="d-flex justify-content-between">
          <span *ngIf="task.finished_at" class="e-badge e-badge-success w-50">dokončeno</span>
          <span
            *ngIf="!task.finished_at && !task.deleted_date" class="e-badge e-badge-warning w-50"
          >ve frontě</span>
          <span *ngIf="task.taskTime.overtime && !task.deleted_date" class="e-badge e-badge-danger w-50">
                            po termínu
                        </span>
          <span *ngIf="!task.taskTime.overtime && !task.deleted_date" class="e-badge e-badge-success w-50">
                        v termínu
                    </span>
        </div>
        <span *ngIf="task.deleted_date" class="e-badge e-badge-danger ml-1">
                    smazáno (dne: {{ task.deleted_date | dateFormat: 'DD.MM.YYYY HH:mm' }})
                </span>
      </div>
    </div>
    <div class="e-card-content p-2">
      <div class="text-center mb-1">
                <span class="text-muted font-weight-bold">
                    <small class="font-weight-bold">zadavatel:</small>
                    <img
                      alt="profilový obrázek"
                      class="img-fluid rounded-circle avatar-xs mr-1"
                      src="assets/img{{
                                    task.creator.personalphoto
                                }}"
                    />
                  {{ task.creator.firstname }}
                  {{ task.creator.secondname }}
                </span>
        <div class="e-card-separator mb-1 mt-1"></div>
        <ul class="list-group list-group-flush" id="solversList">
          <li class="list-group-item list-group-item-action p-1 border-top-0">
            <div class="d-flex flex-column">
              <div class="solver-name">
                <small class="font-weight-bold">řešitel:</small>
                <img
                  alt="profilový obrázek"
                  class="img-fluid rounded-circle avatar-xs mr-1 ml-2"
                  src="assets/img{{ task.solver.personalphoto }}"
                />
                <small class="font-weight-bold">
                  {{ task.solver.firstname }} {{ task.solver.secondname }}
                </small>
              </div>
              <div class="d-flex flex-row solver-timestamp justify-content-between mb-1">
                <small
                  *ngIf="task.viewed_at || task.last_viewed_at"
                  class="text-success"
                >
                  <i
                    aria-hidden="true"
                    class="fa-light fa-lg fa-eye mr-1"
                    title="poprvé zobrazil"
                  ></i>
                  {{ task.viewed_at | dateFormat: 'DD.MM.YYYY HH:mm' }}
                  <i
                    aria-hidden="true"
                    class="fa-light fa-lg fa-eye mr-1"
                    title="naposledy zobrazil"
                  ></i>
                  {{ task.last_viewed_at | dateFormat: 'DD.MM.YYYY HH:mm' }}
                </small>
                <small
                  *ngIf="!task.viewed_at && !task.last_viewed_at"
                  class="text-danger"
                >
                  <i aria-hidden="true" class="fa-light fa-lg fa-eye-slash mr-1"></i>
                  dosud nezobrazil task
                </small>
              </div>
            </div>
          </li>
        </ul>
        <div class="e-card-separator mb-1 mt-1"></div>
        <span class="font-weight-bold">použité štítky:</span>
        <br />
        <ng-container *ngFor="let tag of task.tags">
          <h5 class="d-inline">
                        <span
                          [ngClass]="
                                        tag.fontColor &&
                                        tag.fontColor === 'white'
                                            ? 'text-white-imp'
                                            : 'text-black-imp'
                                    "
                          [ngStyle]="{
                                        'background-color': tag.color,
                                        'border-color': tag.color
                                    }"
                          class="badge badge-pill mr-1"
                        >
                            {{ tag.name }}
                        </span>
          </h5>
        </ng-container>
        <div class="e-card-separator mb-1 mt-1"></div>
        <div
          *ngIf="task.unixCreatedTime"
          class="d-flex mb-2 text-center text-muted justify-content-center"
        >
          <small class="text-muted font-weight-bold">
            vytvořeno:
            {{ task.created_date | dateFormat: 'DD.MM.YYYY HH:mm' }}
            <br />
            ( {{ task.unixCreatedTime | timeago: live }} )
          </small>
        </div>
        <div
          *ngIf="task.unixUpdatedTime"
          class="d-flex mb-2 text-center text-muted justify-content-center"
        >
          <small class="text-muted font-weight-bold">
            poslední změna:
            {{ task.updated_date| dateFormat: 'DD.MM.YYYY HH:mm' }}
            <br />
            ( {{ task.unixUpdatedTime | timeago: live }} )
          </small>
        </div>
        <div
          *ngIf="task.unixDeletedTime"
          class="d-flex mb-2 text-center text-muted justify-content-center"
        >
          <small class="font-weight-bold">
            smazáno:
            {{ task.deleted_date| dateFormat: 'DD.MM.YYYY HH:mm' }}
            <br />
            ( {{ task.unixDeletedTime | timeago: live }} )
          </small>
        </div>
        <div
          *ngIf="task.unixStartDeadlineTime"
          class="d-flex mb-2 text-center text-muted justify-content-center"
        >
          <small class="text-muted font-weight-bold">
            začátek termínu:
            {{ task.start_deadline| dateFormat: 'DD.MM.YYYY HH:mm' }}
            <br />
            ( {{ task.unixStartDeadlineTime | timeago: live }} )
          </small>
        </div>
        <div
          *ngIf="task.unixDeadlineTime"
          class="d-flex mb-2 text-center text-muted justify-content-center"
        >
          <small class="text-muted font-weight-bold">
            konec termínu:
            {{ task.deadline | dateFormat: 'DD.MM.YYYY HH:mm' }}
            <br />
            ( {{ task.unixDeadlineTime | timeago: live }} )
          </small>
        </div>
        <div
          *ngIf="task.unixFinishedTime"
          class="d-flex mb-2 text-center text-muted justify-content-center"
        >
          <small class="text-muted font-weight-bold">
            řešení vloženo:
            {{ task.finished_at | dateFormat: 'DD.MM.YYYY HH:mm' }}
            <br />
            ( {{ task.unixFinishedTime | timeago: live }} )
          </small>
        </div>
        <div class="e-card-separator mb-1 mt-1"></div>
        <div
          class="row mb-2 text-center text-muted justify-content-center"
          style="font-weight: bold"
        >
          <strong
            class="col-12 text-muted mt-2 font-weight-bold"
          >časový plán:</strong
          >
          <span
            *ngIf="task.start_deadline"
            class="col-12 text-muted mt-2 font-weight-bold"
          >
                        OD: {{ task.start_deadline | dateFormat: 'DD.MM.YYYY HH:mm' }}
                    </span>
          <span
            *ngIf="!task.start_deadline"
            class="col-12 text-muted mt-2 font-weight-bold"
          >
                        OD: {{ task.created_date | dateFormat: 'DD.MM.YYYY HH:mm' }}
                    </span>
          <span
            *ngIf="task.deadline"
            class="col-12 text-muted mt-2 font-weight-bold"
          >
                        DO: {{ task.deadline | dateFormat: 'DD.MM.YYYY HH:mm' }}
                    </span>
        </div>
        <small
          *ngIf="task.ticketTime"
          class="text-muted mt-2 font-weight-bold"
          style="font-weight: bold"
        >
          časový fond: {{ task.ticketTime.time_fond }}
        </small>
        <small
          *ngIf="!task.ticketTime"
          class="text-muted mt-2 font-weight-bold"
        >
          bez časového fondu
        </small>
        <app-task-time [task]="task"></app-task-time>
        <div class="e-card-separator mb-1 mt-1"></div>
        <small
          *ngIf="task.category"
          class="text-muted font-weight-bold"
        >
          Kategorie: {{ task.category.name }} </small
        ><br />
        <small
          *ngIf="task.unixSLATime && task.category.sla.duration > 0"
          class="text-muted font-weight-bold"
        >
          SLA fond:
          {{ task.category.sla.duration / 60 }} hodin
          <small
            class="text-muted"
          >(
            {{ task.unixSLATime | timeago: live }}
            )</small
          >
        </small>
        <small
          *ngIf="
                                !task.unixSLATime ||
                                task.category.sla.duration === 0
                            "
          class="text-muted font-weight-bold"
        >
          bez fondu SLA
        </small>
      </div>
    </div>
  </ng-container>
</div>
