import {ChangeDetectionStrategy, Component, OnInit, ViewChild} from '@angular/core';
import {
    BeforeUploadEventArgs,
    FileInfo,
    RemovingEventArgs,
    SelectedEventArgs,
    SuccessEventArgs,
    UploaderComponent,
    UploadingEventArgs,
} from '@syncfusion/ej2-angular-inputs';
import {DialogComponent} from '@syncfusion/ej2-angular-popups';
import {User} from '@src/app/_models/user/user';
import {ApiConnections} from '@src/app/_models/api/connections';
import {Subscription} from 'rxjs';
import {DataService} from '@src/app/_services/data.service';
import {MessageService} from '@src/app/_services/message.service';
import {AuthenticationService} from '@src/app/_services/authentication.service';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {ChangeEventArgs as SwitchEventArgs} from '@syncfusion/ej2-buttons/src/common/common';
import * as XLSX from 'xlsx';
import {saveAs} from 'file-saver';
import {EmitType, isNullOrUndefined} from '@syncfusion/ej2-base';
import {EnvironmentService} from '@src/app/_services/environment.service';
import {TicketsService} from '@src/app/features/tickets/tickets.service';

@UntilDestroy()
@Component({
    selector: 'app-task-import',
    templateUrl: './task-import.component.html',
    styleUrls: ['./task-import.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TaskImportComponent implements OnInit {
    // Upload
    path: {
        saveUrl: string;
        removeUrl: string;
    };
    dropElement: HTMLElement = document.getElementsByClassName('control-fluid')[0] as HTMLElement;
    humanizeBytes: (bytes: number) => void;
    // Variables
    currentUser: User | null;
    removeFile: Array<FileInfo[]> = [];
    apis: {
        acs: ApiConnections | boolean;
        crm: ApiConnections | boolean;
        erp: ApiConnections | boolean;
        siteMonitoring: ApiConnections | boolean;
        cars: ApiConnections | boolean;
    } = {
        acs: false,
        crm: false,
        erp: false,
        siteMonitoring: false,
        cars: false,
    };
    // Loaders
    loadingImport = false;
    // Upload
    @ViewChild('preloadupload') uploadObj: UploaderComponent;
    // Dialogs
    @ViewChild('deleteFileDialog') deleteFileDialog: DialogComponent;
    // Dialogs
    buttonsDeleteFileDialog = [
        {
            click: this.removeTaskFile.bind(this),
            buttonModel: {
                content: 'OK',
                cssClass: 'e-success e-outline',
                isFlat: false,
            },
        },
        {
            click: (): void => {
                this.deleteFileDialog.hide();
            },
            buttonModel: {content: 'ZRUŠIT', cssClass: 'e-danger e-outline'},
        },
    ];
    // Subscribers
    private apiSubscription: Subscription;

    constructor(
        private readonly dataService: DataService,
        private readonly ticketService: TicketsService,
        private readonly messages: MessageService,
        private readonly authenticationService: AuthenticationService,
        private readonly environmentService: EnvironmentService,
    ) {
        this.currentUser = this.authenticationService.currentUserValue;
        this.path = {
            saveUrl: `${this.environmentService.backendURL}/api/upload/sheet/ticket?type=tickets&subfolder=import`,
            removeUrl: '',
        };
    }

    ngOnInit(): void {
        this.apiSubscription = this.dataService.apiSource
            .pipe(untilDestroyed(this))
            .subscribe(
                (apis: {
                    acs: ApiConnections | boolean;
                    crm: ApiConnections | boolean;
                    erp: ApiConnections | boolean;
                    siteMonitoring: ApiConnections | boolean;
                    cars: ApiConnections | boolean;
                }) => {
                    this.apis = apis;
                },
            );
    }

    onImportJSChange(args: SwitchEventArgs): void {
        console.info(args);
    }

    onImportCRMChange(args: SwitchEventArgs): void {
        console.info(args);
    }

    onImportSITEMNTChange(args: SwitchEventArgs): void {
        console.info(args);
    }

    downloadSheet(name = 'ticket_sheet.xlsx'): void {
        this.ticketService
            .downloadImportSheet(name)
            .pipe(untilDestroyed(this))
            .subscribe(
                data => {
                    saveAs(data, name);
                },
                err => {
                    alert('Chyba během stahování souboru.');
                    console.error(err);
                },
            );
    }

    onFileSelected(args: SelectedEventArgs): void {
        // Filter the 5 files only to showcase
        args.filesData.splice(5);

        const filesData: FileInfo[] = this.uploadObj.getFilesData();
        const allFiles: FileInfo[] = filesData.concat(args.filesData);

        if (allFiles.length > 5) {
            // eslint-disable-next-line @typescript-eslint/prefer-for-of
            for (let i = 0; i < allFiles.length; i++) {
                if (allFiles.length > 5) {
                    allFiles.shift();
                }
            }

            args.filesData = allFiles;
            // set the modified custom data
            args.modifiedFilesData = args.filesData;
        }

        let existingFiles: FileInfo[] = this.uploadObj.getFilesData();

        for (let i = 0; i < args.filesData.length; i++) {
            // eslint-disable-next-line @typescript-eslint/prefer-for-of
            for (let j = 0; j < existingFiles.length; j++) {
                if (!isNullOrUndefined(args.filesData[i])) {
                    // eslint-disable-next-line eqeqeq
                    if (existingFiles[j].name == args.filesData[i].name) {
                        args.filesData.splice(i, 1);
                    }
                }
            }
        }

        existingFiles = existingFiles.concat(args.filesData);
        args.modifiedFilesData = existingFiles;
        args.isModified = true;
    }

    beforeFileUpload(args: BeforeUploadEventArgs): void {
        if (this.currentUser) {
            args.customFormData = [{created_by: this.currentUser.id}];
        }
    }

    onFileUpload: EmitType<SelectedEventArgs> = (args: UploadingEventArgs) => {
        if (this.currentUser) {
            args.currentRequest?.setRequestHeader(
                'Authorization',
                `Bearer ${this.currentUser.token}`,
            );
            args.customFormData = [{created_by: this.currentUser.id}];
        }
    };

    onSuccessUpload(args: SuccessEventArgs): void {
        if (args.file) {
            const body = `Soubor: ${args.file.name}`;
            const options = {progressBar: true, timeOut: 5000, toastClass: 'success'};

            this.messages.showSuccess('Soubor úspěšně nahrán', body, options);
            this.getXLSXData(args.file.rawFile as Blob, (dataRow: Task[]) => {
                dataRow.forEach(data => {
                    console.info(data);
                });
            });
        }
    }

    onFileRemove(args: RemovingEventArgs): void {
        args.postRawFile = false;
        this.removeFile.push(args.filesData);
        this.deleteFileDialog.show();
    }

    removeTaskFile(): void {
        this.loadingImport = true;
        this.uploadObj.clearAll();
        this.deleteFileDialog.hide();
        this.removeFile = [];
        this.loadingImport = false;
    }

    private getXLSXData(file: Blob, callback: (row: Task[]) => void): void {
        let data: string[] | string;
        const row: Task[] = [];
        const reader = new FileReader();

        reader.onload = (e): void => {
            const bstr = e.target?.result;
            const workbook = XLSX.read(bstr, {type: 'binary'});
            const sheetNameList = workbook.SheetNames;

            sheetNameList.forEach(y => {
                /* iterate through sheets */
                const worksheet = workbook.Sheets[y];

                for (data in worksheet) {
                    if (
                        // eslint-disable-next-line @typescript-eslint/prefer-string-starts-ends-with
                        data[0] === '!' ||
                        // eslint-disable-next-line @typescript-eslint/prefer-string-starts-ends-with
                        data[0] === 'A1' ||
                        // eslint-disable-next-line @typescript-eslint/prefer-string-starts-ends-with
                        data[0] === 'B1' ||
                        // eslint-disable-next-line @typescript-eslint/prefer-string-starts-ends-with
                        data[0] === 'C1'
                    ) {
                        continue;
                    }
                    /*
                    if (data !== 'A1' && data !== 'B1' && data !== 'C1') {
                        if (data.includes('A')) {
                            // eslint-disable-next-line @typescript-eslint/no-unused-vars
                            date = moment(worksheet[data].w.toString() as MomentInput).format(
                                'YYYY-MM-DD'
                            );
                        }
                    }*/
                }
            });
            console.info(row);
            callback(row);
        };

        reader.readAsBinaryString(file);
    }
}
