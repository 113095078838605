import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Task} from '@src/app/_models/task/task';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {registerLocaleData} from '@angular/common';
import {MessageService} from '@src/app/_services/message.service';
import {User} from '@src/app/_models/user/user';
import {TimeagoIntl} from 'ngx-timeago';
import {strings as czechStrings} from 'ngx-timeago/language-strings/cs';
import {AuthenticationService} from '@src/app/_services/authentication.service';
import {BehaviorSubject, Observable, Observer} from 'rxjs';
import {TaskNotes} from '@src/app/_models/task/task-notes';
import {DataService} from '@src/app/_services/data.service';
import {ButtonPropsModel, Dialog, DialogComponent} from '@syncfusion/ej2-angular-popups';
import {
    FontFamilyModel,
    ImageSettingsModel,
    QuickToolbarSettingsModel,
    RichTextEditorComponent,
} from '@syncfusion/ej2-angular-richtexteditor';
import {ClickEventArgs, ToolbarModule} from '@syncfusion/ej2-angular-navigations';
import {AnimationModel, FontModel} from '@syncfusion/ej2-progressbar';
import {addClass, Browser, createElement, removeClass} from '@syncfusion/ej2-base';
import moment from 'moment';
import localeCs from '@angular/common/locales/cs';
import {IProgressValueEventArgs, ProgressBarComponent} from '@syncfusion/ej2-angular-progressbar';
import {TaskLogs} from '@src/app/_models/task/task-logs';
import {ChangeEventArgs, DropDownListComponent} from '@syncfusion/ej2-angular-dropdowns';
import {
    DataResult,
    ExcelExportProperties,
    FilterSettingsModel,
    GridComponent,
    GroupSettingsModel,
    PageSettingsModel,
    RowDataBoundEventArgs,
    SelectionSettingsModel,
    ToolbarItems,
} from '@syncfusion/ej2-angular-grids';
import {PermissionService} from '@src/app/_services/permission.service';
import {TextBoxComponent} from '@syncfusion/ej2-angular-inputs';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {EnvironmentService} from '@src/app/_services/environment.service';
import {TaskInput, TaskLogsItems, TasksService} from '@src/app/features/tasks/tasks.service';
import {TaskComment} from '@src/app/_models/task/task-comment';
import {CommentsService} from '@src/app/shared_components/services/comments.service';

moment.updateLocale('cs', {workingWeekdays: [1, 2, 3, 4, 5]});
registerLocaleData(localeCs);

@UntilDestroy()
@Component({
    selector: 'app-task-detail',
    templateUrl: './task-detail.component.html',
    styleUrls: ['./task-detail.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TaskDetailComponent implements OnInit {
    // ProgressBars
    animation: AnimationModel;

    labelStyle: FontModel;

    showProgressValue: boolean;

    // Dialogs
    dialogObject: Dialog;

    renderTaskFormDialog = false;

    renderTaskNoteFormDialog = false;

    renderTaskCommentFormDialog = false;
    // RTE
    maxLength = 15000;
    textArea: HTMLElement;
    insertImageSettings: ImageSettingsModel;
    fontFamily: FontFamilyModel = {
        items: [
            {
                text: 'Segoe UI',
                value: 'Segoe UI',
                command: 'Font',
                subCommand: 'FontName',
            },
            {
                text: 'Roboto',
                value: 'Roboto',
                command: 'Font',
                subCommand: 'FontName',
            },
            {
                text: 'Great vibes',
                value: 'Great Vibes,cursive',
                command: 'Font',
                subCommand: 'FontName',
            },
            {
                text: 'Noto Sans',
                value: 'Noto Sans',
                command: 'Font',
                subCommand: 'FontName',
            },
            {
                text: 'Impact',
                value: 'Impact,Charcoal,sans-serif',
                command: 'Font',
                subCommand: 'FontName',
            },
            {
                text: 'Tahoma',
                value: 'Tahoma,Geneva,sans-serif',
                command: 'Font',
                subCommand: 'FontName',
            },
        ],
    };
    tools: ToolbarModule = {
        items: [
            'Bold',
            'Italic',
            'Underline',
            'StrikeThrough',
            'FontName',
            'FontSize',
            'FontColor',
            'BackgroundColor',
            'LowerCase',
            'UpperCase',
            'SuperScript',
            'SubScript',
            '|',
            'Formats',
            'Alignments',
            'OrderedList',
            'UnorderedList',
            'Outdent',
            'Indent',
            '|',
            'CreateTable',
            'CreateLink',
            'Image',
            '|',
            'ClearFormat',
            'Print',
            'SourceCode',
            'FullScreen',
            '|',
            'Undo',
            'Redo',
        ],
    };
    quickToolbarSettings: QuickToolbarSettingsModel = {
        table: [
            'TableHeader',
            'TableRows',
            'TableColumns',
            'TableCell',
            '-',
            'BackgroundColor',
            'TableRemove',
            'TableCellVerticalAlign',
            'Styles',
        ],
    };
    pasteCleanupSettings: object = {
        prompt: true,
        plainText: false,
        keepFormat: false,
        deniedTags: ['a'],
        deniedAttrs: ['class', 'title', 'id'],
        allowedStyleProps: ['color', 'margin', 'font-size'],
    };
    // Grid
    data: object[] = [];
    currencyFormat: {format: string} = {format: '#,###.00\',- CZK\''};
    dateFormat: {type: string; format: string} = {type: 'dateTime', format: 'dd.MM.yyyy HH:mm'};
    toolbar: ToolbarItems[] | object;
    fields: object = {text: 'label', value: 'value'};
    filterSettings: FilterSettingsModel;
    selectionSettings: SelectionSettingsModel;
    groupOptions: GroupSettingsModel;
    pageOptions: PageSettingsModel;
    filterDate: object;
    requiredRules: object;
    sortOptions: object;
    exportCurrentPage = false;
    // Dropdowns
    height = '240px';
    categoryFilterData: Array<string> = [];
    // Forms
    createSolvingForm: FormGroup;
    createSolvingCommentForm: FormGroup;
    submited = false;
    isCreate = false;
    isCopy = false;
    isUpdate = false;
    isCreateNote = false;
    isCopyNote = false;
    isUpdateNote = false;
    // Variables
    currentUser: User | null;
    task: Task | undefined;
    solver: User;
    editSolution = false;
    highlightedNote: TaskNotes;
    logs: TaskLogs[] | undefined = [];
    users: User[] | undefined = [];
    live: true;
    clickedRow: TaskLogs[];
    clickedRow$ = new Observable<TaskLogs[]>((observer: Observer<TaskLogs[]>) => {
        observer.next(this.clickedRow);
    });
    isCreateComment = false;
    isCopyComment = false;
    isUpdateComment = false;
    highlightedComment: TaskComment;
    // Loaders
    loadingTask$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
    firstLoad = true;
    // ProgressBars
    @ViewChild('taskTimePercentage') taskTimePercentageObj: ProgressBarComponent;
    // Dialogs
    @ViewChild('deleteTaskDialog') deleteTaskDialog: DialogComponent;
    buttonsDeleteTaskDialog = [
        {
            click: this.removeTask.bind(this),
            buttonModel: {
                content: 'OK',
                cssClass: 'e-success e-outline',
                isFlat: false,
            },
        },
        {
            click: (): void => {
                this.deleteTaskDialog.hide();
            },
            buttonModel: {content: 'ZRUŠIT', cssClass: 'e-danger e-outline'},
        },
    ];
    @ViewChild('deleteNoteDialog') deleteNoteDialog: DialogComponent;
    buttonsDeleteNoteDialog = [
        {
            click: this.removeTaskNote.bind(this),
            buttonModel: {
                content: 'OK',
                cssClass: 'e-success e-outline',
                isFlat: false,
            },
        },
        {
            click: (): void => {
                this.deleteNoteDialog.hide();
            },
            buttonModel: {content: 'ZRUŠIT', cssClass: 'e-danger e-outline'},
        },
    ];
    @ViewChild('activeTaskDialog') activeTaskDialog: DialogComponent;
    buttonsActiveTaskDialog = [
        {
            click: this.reactiveTask.bind(this),
            buttonModel: {
                content: 'OK',
                cssClass: 'e-success e-outline',
                isFlat: false,
            },
        },
        {
            click: (): void => {
                this.activeTaskDialog.hide();
            },
            buttonModel: {content: 'Zrušit', cssClass: 'e-flat e-danger'},
        },
    ];
    @ViewChild('addNoteDialog') addNoteDialogDialog: DialogComponent;

    @ViewChild('addSolvingDialog') addSolvingDialog: DialogComponent;

    @ViewChild('deleteCommentDialog') deleteCommentDialog: DialogComponent;

    buttonsDeleteCommentDialog: ButtonPropsModel[] = [
        {
            click: this.removeTaskComment.bind(this),
            isFlat: false,
            buttonModel: {
                content: 'OK',
                cssClass: 'e-success e-outline',
            },
        },
        {
            click: (): void => {
                this.deleteCommentDialog.hide();
            },
            buttonModel: {content: 'ZRUŠIT', cssClass: 'e-danger e-outline'},
        },
    ];


    // RTE
    @ViewChild('taskSolution') taskSolution?: RichTextEditorComponent;

    // Grid
    @ViewChild('taskLogsGrid') gridLogs: GridComponent;

    @ViewChild('searchtext') searchtextObj: TextBoxComponent;

    // Dropdowns
    @ViewChild('viewFilter') viewFilterInstance: DropDownListComponent;

    constructor(
        private readonly route: ActivatedRoute,
        private readonly authenticationService: AuthenticationService,
        private readonly ref: ChangeDetectorRef,
        private readonly dataService: DataService,
        private readonly tasksService: TasksService,
        private readonly commentService: CommentsService,
        private readonly permissionService: PermissionService,
        private readonly formBuilder: FormBuilder,
        private readonly messages: MessageService,
        private readonly intl: TimeagoIntl,
        private readonly environmentService: EnvironmentService,
    ) {
        this.currentUser = this.authenticationService.currentUserValue;
        this.intl.strings = czechStrings;
        this.intl.changes.next();
        this.insertImageSettings = {
            path: `${this.environmentService.backendURL}/api/file/preview/task/`,
            saveUrl: `${this.environmentService.backendURL}/api/upload/attachments/tasks?type=attachments&subfolder=tasks`,
        };
    }

    get solutionControl(): {[key: string]: AbstractControl} {
        return this.createSolvingForm.controls;
    }

    get solvingCommentControl(): {[key: string]: AbstractControl} {
        return this.createSolvingCommentForm.controls;
    }

    ngOnInit(): void {
        this.filterSettings = {
            type: 'Excel',
            showFilterBarOperator: true,
            showFilterBarStatus: true,
            ignoreAccent: true,
        };

        this.selectionSettings = {
            persistSelection: true,
            type: 'Multiple',
            checkboxOnly: true,
        };

        this.toolbar = [
            {
                text: 'Občerstvit data',
                tooltipText: 'Občerstvit data',
                prefixIcon: 'e-refresh',
                id: 'customRefresh',
            },
            'Print',
            'ExcelExport',
            {
                text: 'Obnovit data',
                tooltipText: 'Obnovit data',
                prefixIcon: 'e-revert',
                id: 'revert',
            },
        ];

        this.categoryFilterData = [
            'všechny změny',
            'poslední den',
            'poslední týden',
            'poslední měsíc',
            'poslední rok',
        ];
        this.pageOptions = {pageSize: 50};
        this.filterDate = {type: 'Menu', params: {format: this.dateFormat}};
        this.groupOptions = {
            showGroupedColumn: true,
            disablePageWiseAggregates: false,
        };
        this.requiredRules = {required: true};
        this.animation = {enable: true, duration: 2000, delay: 0};
        this.labelStyle = {
            color: '#FFFFFF',
            fontWeight: '900',
            fontFamily: 'Montserrat, sans-serif',
            fontStyle: 'normal',
        };
        this.showProgressValue = true;
        this.dataService.taskDetailSource.pipe(untilDestroyed(this))
            .subscribe(
                (task: Task | undefined) => {
                    this.task = task;

                    if (!this.task) {
                        return;
                    }

                    if (this.firstLoad && this.task.solver.id === this.currentUser?.id) {
                        this.firstLoad = false;

                        const taskData: TaskInput = {
                            id: this.task.id,
                            viewed_at: !this.task.viewed_at
                                ? moment()
                                    .format('YYYY-MM-DD HH:mm:ss')
                                : this.task.viewed_at,
                            last_viewed_at: moment()
                                .format('YYYY-MM-DD HH:mm:ss'),
                            updated_by: this.currentUser.id,
                        };

                        this.tasksService
                            .updateTask(taskData)
                            ?.pipe(untilDestroyed(this))
                            .subscribe(
                                () => {
                                    console.info('značky zobrazení aktualizovány.');
                                },
                                error => {
                                    console.error(error);
                                },
                            );
                    }

                    this.tasksService
                        .getLogs(this.task)
                        .pipe(untilDestroyed(this))
                        .subscribe(
                            (logs: TaskLogs[]) => {
                                // data uživatelů
                                this.dataService.userSource.pipe(untilDestroyed(this))
                                    .subscribe(
                                        (data: User[] | undefined) => {
                                            this.users = data;
                                            this.logs = logs;
                                            this.data = this.gridLogs.dataSource = this.loadData();
                                            this.ref.markForCheck();
                                        },
                                        error => {
                                            console.error(error);
                                        },
                                    );
                            },
                            error => {
                                console.error(error);
                                this.loadingTask$.next(false);
                                this.ref.markForCheck();
                            },
                        );

                    this.loadingTask$.next(false);
                    this.ref.markForCheck();
                },
                error => {
                    console.error(error);
                    this.loadingTask$.next(false);
                },
            );

        this.createSolvingForm = this.formBuilder.group({
            solution: ['', Validators.required],
        });

        this.createSolvingCommentForm = this.formBuilder.group({
            text: ['', Validators.required],
        });

        this.route.params.pipe(untilDestroyed(this))
            .subscribe(params => {
                this.dataService.setTaskDetailSource(parseInt(params.id as string, 10));
            });
    }

    changedBar(args: IProgressValueEventArgs): void {
        if (args.value < 30) {
            args.progressColor = '#629337';
        } else if (args.value >= 30 && args.value <= 70) {
            args.progressColor = '#FFBB34';
        } else if (args.value > 70) {
            args.progressColor = '#FF3547';
        }
    }

    onCreated(): void {
        this.disableToolBarBtn();
    }

    setInitialGridFiltering(): void {
        this.gridLogs.clearFiltering();
        // this.gridLogs.filterByColumn('created_by', 'equal', this.currentUser.id, 'and', false, true);
        // this.viewFilterInstance.value = 'moje žádanky - všechny';
        this.ref.markForCheck();
    }

    loadData(): DataResult[] {
        this.gridLogs.clearRowSelection();

        console.info('NEW DATA BOUND');

        const elements: TaskLogsItems[] = [];

        if (this.logs && this.logs.length > 0) {
            this.logs.map((log: TaskLogs) => {
                const solver = this.users?.find(x => x.id === log.user_id);
                const creator = this.users?.find(x => x.id === log.created_by);
                const editor = this.users?.find(x => x.id === log.updated_by);
                const remover = this.users?.find(x => x.id === log.deleted_by);

                elements.push({
                    id: log.tasks_id,
                    type_id: log.tasks_type,
                    task_id: log.id,
                    ticket_id: log.ticket_id,
                    category_id: log.category_id,
                    status_id: log.status_id,
                    user_id: log.user_id,
                    solver_str: solver ? solver.fullname : '',
                    solver_img: solver ? solver.personalphoto : '',
                    created_by: log.created_by,
                    creator_str: creator ? creator.fullname : '',
                    creator_img: creator ? creator.personalphoto : '',
                    updated_by: log.updated_by,
                    editor_str: editor ? editor.fullname : '',
                    editor_img: editor ? editor.personalphoto : '',
                    deleted_by: log.deleted_by,
                    remover_str: remover ? remover.fullname : '',
                    remover_img: remover ? remover.personalphoto : '',
                    tasks_type: log.tasks_type,
                    type:
                        log.tasks_type === 1
                            ? 'vytvoření'
                            : log.tasks_type === 2
                                ? 'upravení'
                                : 'smazání',
                    subject: log.subject,
                    maintask: log.maintask,
                    deadline: new Date(log.deadline),
                    created_date: new Date(log.created_date),
                    updated_date: log.updated_date ? new Date(log.updated_date) : null,
                    deleted_date: log.deleted_date ? new Date(log.deleted_date) : null,
                    timestamp: new Date(log.tasks_timestamp),
                    mapclmn: moment(log.tasks_timestamp)
                        .format('YYYY-MM-DD'),
                });
            });
        }

        this.loadingTask$.next(false);
        this.ref.markForCheck();

        return elements as unknown as DataResult[];
    }

    onLoad(): void {
        const wrapper1 = document.getElementById('scroll_wrapper1_tasksLogs');
        const wrapper2 = document.getElementById('scroll_wrapper2_tasksLogs');

        if (wrapper1 && wrapper2) {
            wrapper1.onscroll = (): void => {
                wrapper2.scrollLeft = wrapper1.scrollLeft;
            };

            wrapper2.onscroll = (): void => {
                wrapper1.scrollLeft = wrapper2.scrollLeft;
            };
        }

        this.data = this.gridLogs.dataSource = this.loadData();
        this.sortOptions = {
            columns: [
                {field: 'timestamp', direction: 'Descending'},
                {field: 'id', direction: 'Descending'},
            ],
        };
        this.ref.markForCheck();
    }

    search(): void {
        this.gridLogs.search(this.searchtextObj.value);
    }

    onDataBound(): void {
        this.rowSelected();

        const width = document.getElementById('taskLogsGrid_content_table')?.offsetWidth;
        const parent1 = document.getElementById('scroll_div_tasksLogs');
        const parent2 = document.getElementById('grid_parent_tasksLogs');

        if (parent1 && parent2 && width) {
            parent1.style.width = `${width}px`;
            parent2.style.width = `${width}px`;
        }

        this.gridLogs.autoFitColumns();
        this.ref.markForCheck();
    }

    rowDataBound(args: RowDataBoundEventArgs): void {
        const data = args.data as TaskLogs;

        if (args.row && data.deleted_date) {
            args.row.getElementsByClassName('e-gridchkbox')[0].classList.add('disablecheckbox');
            args.row
                .getElementsByClassName('e-checkbox-wrapper')[0]
                .classList.add('disablecheckbox');
            args.row.classList.add('e-disabled');
        }

        if (
            this.currentUser &&
            args.row &&
            data.created_by !== this.currentUser.id &&
            !this.permissionService.checkUserISAdministrative(this.currentUser)
        ) {
            args.row.getElementsByClassName('e-gridchkbox')[0].classList.add('disablecheckbox');
            args.row
                .getElementsByClassName('e-checkbox-wrapper')[0]
                .classList.add('disablecheckbox');
        }

        this.ref.markForCheck();
    }

    onStatusFilterChange(e: ChangeEventArgs): void {
        if (e.value === 'všechny změny') {
            this.gridLogs.clearFiltering();
        } else if (e.value === 'poslední den') {
            this.gridLogs.clearFiltering();
            this.gridLogs.filterByColumn(
                'timestamp',
                'greaterthanorequal',
                new Date(moment()
                    .subtract(1, 'days')
                    .format('YYYY-MM-DD HH:mm:ss')),
                'and',
                false,
                true,
            );
        } else if (e.value === 'poslední týden') {
            this.gridLogs.clearFiltering();
            this.gridLogs.filterByColumn(
                'timestamp',
                'greaterthanorequal',
                new Date(moment()
                    .subtract(1, 'weeks')
                    .format('YYYY-MM-DD HH:mm:ss')),
                'and',
                false,
                true,
            );
        } else if (e.value === 'poslední měsíc') {
            this.gridLogs.clearFiltering();
            this.gridLogs.filterByColumn(
                'timestamp',
                'greaterthanorequal',
                new Date(moment()
                    .subtract(1, 'months')
                    .format('YYYY-MM-DD HH:mm:ss')),
                'and',
                false,
                true,
            );
        } else if (e.value === 'poslední rok') {
            this.gridLogs.clearFiltering();
            this.gridLogs.filterByColumn(
                'timestamp',
                'greaterthanorequal',
                new Date(moment()
                    .subtract(1, 'years')
                    .format('YYYY-MM-DD HH:mm:ss')),
                'and',
                false,
                true,
            );
        }
    }

    cancelBtnClick(): void {
        this.searchtextObj.value = '';
        this.gridLogs.searchSettings.key = '';
    }

    toolbarClick(args: ClickEventArgs): void {
        const xlsProp: ExcelExportProperties = {
            fileName: 'js_tasks_export.xlsx',
            enableFilter: true,
            exportType: this.exportCurrentPage ? 'CurrentPage' : 'AllPages',
        };

        if (args.item.id === 'taskLogsGrid_excelexport') {
            void this.gridLogs.excelExport(xlsProp);
        } else if (this.task && args.item.id === 'customRefresh') {
            this.loadingTask$.next(true);
            console.info('refresh DATA!');
            this.dataService.setTaskDetailSource(this.task);
            this.ref.markForCheck();
        } else if (this.task && args.item.id === 'revert') {
            this.loadingTask$.next(true);
            console.info('revert DATA!');
            this.revertToTimestamp();
            this.dataService.setTaskDetailSource(this.task);
            this.ref.markForCheck();
        }
    }

    enableToolbarBtn(): void {
        this.gridLogs.toolbarModule.enableItems(['revert'], true);
    }

    disableToolBarBtn(): void {
        this.gridLogs.toolbarModule.enableItems(['revert'], false);
    }

    rowSelected(): void {
        this.clickedRow = [];

        const selectedrecords: object[] = this.gridLogs.getSelectedRecords();

        if (selectedrecords.length > 0) {
            selectedrecords.map((row: TaskLogs) => {
                const tempData: TaskLogs | undefined = this.logs?.find(x => x.tasks_id === row.id);

                if (tempData) {
                    this.clickedRow.push(tempData);
                }

                this.ref.markForCheck();
            });

            this.clickedRow$ = new Observable<TaskLogs[]>((observer: Observer<TaskLogs[]>) => {
                observer.next(this.clickedRow);
            });
            this.enableToolbarBtn();
        } else {
            this.disableToolBarBtn();
            this.clickedRow$ = new Observable<TaskLogs[]>((observer: Observer<TaskLogs[]>) => {
                observer.next(this.clickedRow);
            });
        }
    }

    actionBegin(args): void {
        if (args.requestType === 'add' || args.requestType === 'beginEdit') {
            if (args.requestType === 'beginEdit') {
                // EMPTY TODO
            }

            if (args.requestType === 'add') {
                // EMPTY TODO
            }
        }

        if (args.requestType === 'save') {
            // EMPTY TODO
        }
    }

    actionComplete(args): void {
        if (args.requestType === 'beginEdit' || args.requestType === 'add') {
            args.form.ej2_instances[0].rules = {};
            this.dialogObject = args.dialog;
            this.dialogObject.width = '80%';
            this.dialogObject.height = 'auto';

            if (Browser.isDevice) {
                this.dialogObject.height = `${window.innerHeight - 90}px`;
                this.dialogObject.dataBind();
            }

            this.dialogObject.buttons[0].isFlat = false;

            if (this.dialogObject.buttons[0].buttonModel) {
                this.dialogObject.buttons[0].buttonModel.cssClass = 'e-success';
            }

            if (args.requestType === 'beginEdit') {
                this.dialogObject.header = `Úkol #${this.clickedRow[0].id} - ${this.clickedRow[0].subject}`;
            } else if (args.requestType === 'add') {
                this.dialogObject.header = 'Nový úkol';
            }

            this.ref.markForCheck();
            this.dialogObject.refresh();
        }
    }

    createNote(): void {
        this.isCopyNote = false;
        this.isCreateNote = true;
        this.isUpdateNote = false;
        this.renderTaskNoteFormDialog = true;
    }

    onSolve(): void {
        this.submited = true;

        if (this.createSolvingForm.invalid || !this.task) {
            console.error('form is not valid! || this.task is missing...');

            return;
        }

        this.loadingTask$.next(true);

        const taskData: TaskInput = {
            id: this.task.id,
            status_id: 2,
            solution: this.solutionControl.solution.value,
            finished_at: moment()
                .format('YYYY-MM-DD HH:mm:ss'),
            updated_by: this.currentUser?.id,
        };

        this.tasksService
            .updateTask(taskData)
            ?.pipe(untilDestroyed(this))
            .subscribe(
                (data: Task) => {
                    const body = `úkol #${data.id}`;
                    const options = {progressBar: true, timeOut: 5000};

                    this.messages.showSuccess('Úkol úspěšně dokončen a uzamčen', body, options);
                    this.loadingTask$.next(false);
                    this.editSolution = false;
                    this.addSolvingDialog.hide();
                    this.ref.markForCheck();

                    if (!this.task) {
                        console.error('this.task is missing...');

                        return;
                    }

                    this.dataService.setTaskDetailSource(this.task.id);
                },
                error => {
                    console.error(error);
                    this.loadingTask$.next(false);
                    this.ref.markForCheck();
                },
            );
    }

    copyTask(): void {
        this.isUpdate = false;
        this.isCopy = true;
        this.renderTaskFormDialog = true;
        this.ref.markForCheck();
    }

    editTask(): void {
        this.isCopy = false;
        this.isUpdate = true;
        this.renderTaskFormDialog = true;
        this.ref.markForCheck();
    }

    reactiveSolution(): void {
        this.editSolution = true;
        this.createSolvingForm.controls.solution.patchValue(this.task?.solution);
        this.addSolvingDialog.show();
        this.ref.markForCheck();
    }

    removeTask(): void {
        if (!this.task) {
            console.error('this.task is missing...');

            return;
        }

        this.task.deleted_by = this.currentUser?.id;
        this.tasksService
            .deleteTask(this.task)
            ?.pipe(untilDestroyed(this))
            .subscribe(
                (data: Task) => {
                    const body = `úkol #${data.id}`;
                    const options = {progressBar: true, timeOut: 5000};

                    this.messages.showSuccess('Úkol úspěšně smazán', body, options);
                    this.loadingTask$.next(false);
                    this.dataService.setTaskDetailSource(data.id);
                    this.deleteTaskDialog.hide();
                    this.ref.markForCheck();
                },
                error => {
                    console.error(error);
                    this.loadingTask$.next(false);
                    this.ref.markForCheck();
                },
            );
    }

    removeTaskNote(): void {
        this.tasksService
            .deleteTaskNote(this.highlightedNote)
            .pipe(untilDestroyed(this))
            .subscribe({
                next: (data: TaskNotes) => {
                    const body = `postup #${data.id}`;
                    const options = {progressBar: true, timeOut: 5000};

                    this.messages.showSuccess('Postup úspěšně smazán', body, options);
                    this.deleteNoteDialog.hide();
                    this.loadingTask$.next(false);
                    this.ref.markForCheck();

                    if (!this.task) {
                        console.error('this.task is missing...');

                        return;
                    }

                    this.dataService.setTaskDetailSource(this.task.id);
                },
                error: error => {
                    console.error(error);
                    this.loadingTask$.next(false);
                    this.ref.markForCheck();
                },
            });
    }

    editTaskNote(note: TaskNotes): void {
        this.highlightedNote = note;
        this.isCopyNote = false;
        this.isCreateNote = false;
        this.isUpdateNote = true;
        this.renderTaskNoteFormDialog = true;
        this.ref.markForCheck();
    }

    reactiveTask(): void {
        if (!this.task) {
            console.error('this.task is missing...');

            return;
        }

        this.loadingTask$.next(true);

        if (this.task.solution && this.task.finished_at) {
            const note = {
                text: this.task.solution,
                task_id: this.task.id,
                created_by: this.currentUser?.id,
            };

            this.tasksService
                .addSolvingNote(note)
                .pipe(untilDestroyed(this))
                .subscribe(
                    (data: TaskNotes) => {
                        const options = {
                            progressBar: true,
                            timeOut: 5000,
                            toastClass: 'success',
                        };

                        this.messages.showSuccess(
                            'řešení úspěšně převedeno do postupů',
                            `postup #${data.id}`,
                            options,
                        );
                        this.ref.markForCheck();
                    },
                    error => {
                        console.error(error);

                        const options = {
                            progressBar: true,
                            timeOut: 5000,
                            toastClass: 'red',
                        };

                        this.messages.showError(
                            'Chyba při publikování postupu v úkolu',
                            'Zkuste to znovu později ...',
                            options,
                        );
                        this.loadingTask$.next(false);
                        this.ref.markForCheck();
                    },
                );
        }

        const taskData: TaskInput = {
            id: this.task.id,
            status_id: 1,
            updated_by: this.currentUser?.id,
            deleted_by: null,
            finished_at: null,
            solution: null,
        };

        this.tasksService
            .updateTask(taskData)
            ?.pipe(untilDestroyed(this))
            .subscribe(
                () => {
                    const body = 'Úkol vrácen zpět k řešení do fronty';
                    const options = {progressBar: true, timeOut: 5000};

                    this.messages.showSuccess('Úkol úspěšně obnoven', body, options);
                    this.loadingTask$.next(false);
                    this.activeTaskDialog.hide();

                    if (!this.task) {
                        console.error('this.task is missing...');

                        return;
                    }

                    this.dataService.setTaskDetailSource(this.task.id);
                },
                error => {
                    console.error(error);

                    const options = {
                        progressBar: true,
                        timeOut: 5000,
                        toastClass: 'red',
                    };

                    this.messages.showError(
                        'Chyba při obnovení úkolu,',
                        'Zkuste to znovu později',
                        options,
                    );
                    this.loadingTask$.next(false);
                    this.ref.markForCheck();
                },
            );
    }

    revertToTimestamp(): void {
        console.info(this.clickedRow[0]);
    }

    reloadData(): void {
        if (!this.task?.id) {
            return;
        }

        this.dataService.clearTasksCache();
        this.dataService.setTaskDetailSource(this.task.id);
        this.ref.detectChanges();
    }

    createComment(): void {
        this.isCopyComment = false;
        this.isCreateComment = true;
        this.isUpdateComment = false;
        this.renderTaskCommentFormDialog = true;
    }

    editComment(): void {
        this.isCopyComment = false;
        this.isCreateComment = false;
        this.isUpdateComment = true;
        this.renderTaskCommentFormDialog = true;
    }

    removeTaskComment(): void {
        this.loadingTask$.next(true);
        this.highlightedComment.deleted_by = this.currentUser?.id;
        this.commentService
            .deleteTaskComment(this.highlightedComment)
            ?.pipe(untilDestroyed(this))
            .subscribe(
                () => {
                    const body = 'Komentář byl úspěšně smazán.';
                    const options = {progressBar: true, timeOut: 5000};

                    this.messages.showSuccess('Komentář smazán', body, options);
                    this.loadingTask$.next(false);

                    this.ref.markForCheck();

                    if (!this.task) {
                        console.error('this.ticket is missing...');

                        return;
                    }

                    this.dataService.setTaskDetailSource(this.task.id);
                    this.deleteCommentDialog.hide();
                },
                error => {
                    console.error(error);

                    const body = 'Zkuste to znovu později ...';
                    const options = {progressBar: true, timeOut: 5000};

                    this.messages.showError('Chyba při mazání komentáře v ticketu', body, options);
                    this.loadingTask$.next(false);
                    this.ref.markForCheck();
                },
            );
    }

    // RTE
    onCreateRteSolution(): void {
        this.taskSolution?.refreshUI();
        this.taskSolution?.autoResize();
    }

    handleFullScreen(e: unknown): void {
        const sbCntEle: HTMLElement | null = document.querySelector('.sb-content.e-view');
        const sbHdrEle: HTMLElement | null = document.querySelector('.sb-header.e-view');
        const leftBar: HTMLElement | null = document.querySelector('#left-sidebar');

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        if (e.targetItem === 'Maximize') {
            if (Browser.isDevice && Browser.isIos && sbCntEle && sbHdrEle) {
                addClass([sbCntEle, sbHdrEle], ['hide-header']);
            }

            if (leftBar) {
                addClass([leftBar], ['e-close']);
                removeClass([leftBar], ['e-open']);
            }
        }
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        else if (e.targetItem === 'Minimize') {
            if (Browser.isDevice && Browser.isIos && sbCntEle && sbHdrEle) {
                removeClass([sbCntEle, sbHdrEle], ['hide-header']);
            }

            if (leftBar) {
                removeClass([leftBar], ['e-close']);

                if (!Browser.isDevice) {
                    addClass([leftBar], ['e-open']);
                }
            }
        }
    }

    mirrorSolutionConversion(e: unknown): void {
        if (this.taskSolution) {
            const id = `${this.taskSolution.getID()}mirror-view`;
            let mirrorView: HTMLElement | null = this.taskSolution.element.querySelector(`#${id}`);
            const charCount: HTMLElement | null =
                this.taskSolution.element.querySelector('.e-rte-character-count');

            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            if (e.targetItem === 'Preview' && mirrorView && charCount) {
                this.textArea.style.display = 'block';
                mirrorView.style.display = 'none';
                // eslint-disable-next-line max-len
                // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-member-access
                charCount.style.display = 'block';
            } else {
                if (!mirrorView && this.textArea.parentNode) {
                    mirrorView = createElement('div', {className: 'e-content'});
                    mirrorView.id = id;
                    this.textArea.parentNode.appendChild(mirrorView);
                } else if (mirrorView) {
                    mirrorView.innerHTML = '';
                }

                if (mirrorView) {
                    this.textArea.style.display = 'none';
                    mirrorView.style.display = 'block';
                }

                if (charCount) {
                    charCount.style.display = 'none';
                }
            }
        }
    }

    actionSolutionCompleteHandler(e: unknown): void {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        if (e.targetItem && (e.targetItem === 'SourceCode' || e.targetItem === 'Preview')) {
            (this.taskSolution?.sourceCodeModule.getPanel() as HTMLTextAreaElement).style.display =
                'none';
            this.mirrorSolutionConversion(e);
        } else {
            setTimeout(() => {
                this.taskSolution?.toolbarModule.refreshToolbarOverflow();
            }, 400);
        }
    }

    showSolvingErrors(): void {
        this.createSolvingForm.markAllAsTouched();
        this.ref.markForCheck();
    }
}
