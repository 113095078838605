import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule, Routes} from '@angular/router';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {AuthGuard} from '@src/app/_guards/auth.guard';
import {ExcelService} from '@src/app/_services/excel.service';
import {PendingChangesGuard} from '@src/app/_guards/changes.guard';
import {HotlineComponent} from '@src/app/features/hotline/components/hotline/hotline.component';
import {HotlineListComponent} from '@src/app/features/hotline/components/hotline-list/hotline-list.component';
import {HotlineUsersComponent} from '@src/app/features/hotline/components/hotline-users/hotline-users.component';
import {HotlineDetailComponent} from '@src/app/features/hotline/components/hotline-detail/hotline-detail.component';
import {HotlineCalendarComponent} from '@src/app/features/hotline/components/hotline-calendar/hotline-calendar.component';
import {HotlineImportComponent} from '@src/app/features/hotline/components/hotline-import/hotline-import.component';
import {HotlineReportFormComponent} from '@src/app/features/hotline/_forms/hotline-report-form/hotline-report-form.component';
import {HotlineService} from '@src/app/features/hotline/hotline.service';
import {SharedFeaturesModule} from '@src/app/shared_features/shared-features.module';
import {HotlineFormComponent} from './_forms/hotline-form/hotline-form.component';

const routes: Routes = [
    {
        path: 'hotline',
        children: [
            {
                path: '',
                component: HotlineComponent,
                data: {
                    title: 'Hotline nástěnka',
                    breadcrumb: [
                        {
                            label: 'Hot-Line',
                            url: '',
                        },
                    ],
                },
            },
            {
                path: 'create',
                component: HotlineCalendarComponent,
                data: {
                    title: 'Vytvořit hotline',
                    breadcrumb: [
                        {
                            label: 'Hot-Line',
                            url: '/hotline',
                        },
                        {
                            label: 'Vytvořit hotline',
                            url: '',
                        },
                    ],
                },
                canDeactivate: [PendingChangesGuard],
            },
            {
                path: 'queue',
                component: HotlineListComponent,
                data: {
                    title: 'Fronta hotline',
                    breadcrumb: [
                        {
                            label: 'Hot-Line',
                            url: '/hotline',
                        },
                        {
                            label: 'Fronta hotline',
                            url: '',
                        },
                    ],
                },
            },
            {
                path: 'users',
                component: HotlineUsersComponent,
                data: {
                    title: 'Seznam hotlinerů',
                    breadcrumb: [
                        {
                            label: 'Hot-Line',
                            url: '/hotline',
                        },
                        {
                            label: 'Seznam hotlinerů',
                            url: '',
                        },
                    ],
                },
            },
            {
                path: 'detail/:id',
                component: HotlineDetailComponent,
                data: {
                    title: 'Detail hot-line',
                    breadcrumb: [
                        {
                            label: 'Hot-Line',
                            url: '/hotline',
                        },
                        {
                            label: 'Detail hot-line',
                            url: '',
                        },
                    ],
                },
            },
        ],
        canActivate: [AuthGuard],
    },
];

@NgModule({
    declarations: [
        HotlineCalendarComponent,
        HotlineComponent,
        HotlineDetailComponent,
        HotlineFormComponent,
        HotlineImportComponent,
        HotlineListComponent,
        HotlineReportFormComponent,
        HotlineUsersComponent,
    ],
    imports: [
        BrowserAnimationsModule,
        RouterModule.forChild(routes),
        BrowserModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        SharedFeaturesModule,
    ],
    providers: [ExcelService, HotlineService],
})
export class HotlineModule {}
