import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {Notifications} from '@src/app/_models/notification/notification';
import {catchError, share, tap} from 'rxjs/operators';
import {User} from '@src/app/_models/user/user';
import {registerLocaleData} from '@angular/common';

import moment from 'moment';
import localeCs from '@angular/common/locales/cs';
import {EnvironmentService} from '@src/app/_services/environment.service';

moment.updateLocale('cs', {workingWeekdays: [1, 2, 3, 4, 5]});
registerLocaleData(localeCs);

const httpOptions = {
    headers: new HttpHeaders({'Content-Type': 'application/json'}),
};

export interface NotificationMessage {
    type: 'error' | 'info' | 'success' | 'warning';
    title: string;
    body: string;
    options: {progressBar: boolean; timeOut: number};
}

@Injectable({
    providedIn: 'root',
})
export class MessageService {
    private readonly notificationsUrl: string;

    messages: string[] = [];

    notifs$: BehaviorSubject<NotificationMessage | null> =
        new BehaviorSubject<NotificationMessage | null>(null);

    notificationsOld: Observable<Notifications[] | undefined>;

    constructor(
        private readonly http: HttpClient,
        private readonly environmentService: EnvironmentService,
    ) {
        this.notificationsUrl = this.environmentService.backendURL + '/api/notify';
    }

    /**
     * Handle Http operation that failed.
     * Let the app continue.
     * @param operation
     * @param result - optional value to return as the observable result
     */
    private handleError<T>(operation = 'operation', result?: T) {
        return (error: {[key: string]: string}): Observable<T | undefined> => {
            // TODO: send the error to remote logging infrastructure
            console.error(error); // log to console instead

            // TODO: better job of transforming error for user consumption
            this.log(`${operation} failed: ${error.message}`);

            // Let the app keep running by returning an empty result.
            return of(result);
        };
    }

    /** Log a ticketService message with the MessageService */
    private log(message: string): void {
        this.addNotification(`Ticket: ${message}`, false);
    }

    getQueue(
        user: User | number,
        limit: number,
        offset: number,
    ): Observable<Notifications[] | undefined> {
        const id = typeof user === 'number' ? user : user.id;
        const url = `${this.notificationsUrl}/readed/user/${id}`;

        if (limit || offset) {
            this.notificationsOld = this.http
                .post<Notifications[]>(url, {user_id: id, limit, offset}, httpOptions)
                .pipe(
                    tap(() => this.addNotification('fetched notifications', false)),
                    share(),
                    catchError(this.handleError<Notifications[]>('getQueue OLD')),
                );
        }

        return this.notificationsOld;
    }

    updateNotification(notification: Notifications): Observable<Notifications> {
        const {id} = notification;

        notification.viewed_at = moment()
            .format('YYYY-MM-DD HH:mm:ss');

        const url = `${this.notificationsUrl}/update/${id}`;

        return this.http.put<Notifications>(url, notification, httpOptions);
    }

    addNotification(
        message: string,
        store: boolean,
    ): Observable<Notifications | Notifications[] | undefined> {
        this.messages.push(message);

        if (store) {
            return this.http.post<Notifications>(this.notificationsUrl, message, httpOptions);
        }

        return this.notificationsOld;
    }

    deleteNotification(notification: Notifications | number): Observable<Notifications> {
        const id = typeof notification === 'number' ? notification : notification.id;
        const url = `${this.notificationsUrl}/${id}`;

        return this.http.delete<Notifications>(url, httpOptions);
    }

    showSuccess(
        title: string,
        body: string,
        options: {progressBar: boolean; timeOut: number},
    ): void {
        this.notifs$.next({type: 'success', body, title, options});
    }

    showWarning(
        title: string,
        body: string,
        options: {progressBar: boolean; timeOut: number},
    ): void {
        this.notifs$.next({type: 'warning', body, title, options});
    }

    showInfo(
        title: string,
        body: string,
        options: {progressBar: boolean; timeOut: number},
    ): void {
        this.notifs$.next({type: 'info', body, title, options});
    }

    showError(
        title: string,
        body: string,
        options: {progressBar: boolean; timeOut: number},
    ): void {
        this.notifs$.next({type: 'error', body, title, options});
    }
}
