import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthenticationService} from '@src/app/_services/authentication.service';
import {TvPackages} from '@src/app/_models/services/tv-packages';
import {TvChannels} from '@src/app/_models/services/tv-channels';
import {DataNetbaseService} from '@src/app/_services/data-netbase.service';
import {User} from '@src/app/_models/user/user';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {ButtonPropsModel, DialogComponent} from '@syncfusion/ej2-angular-popups';
import {
    ColumnModel,
    DataResult,
    EditSettingsModel,
    ExcelExportProperties,
    FilterSettingsModel,
    GridComponent,
    GroupSettingsModel,
    PageSettingsModel,
    RowDataBoundEventArgs,
    SelectionSettingsModel,
    ToolbarItems,
} from '@syncfusion/ej2-angular-grids';
import {TextBoxComponent} from '@syncfusion/ej2-angular-inputs';
import {ChangeEventArgs, DropDownListComponent} from '@syncfusion/ej2-angular-dropdowns';
import {ClickEventArgs} from '@syncfusion/ej2-angular-navigations';
import {PermissionService} from '@src/app/_services/permission.service';
import {TvChannelsGrid} from '@src/app/features/services/services.service';

@UntilDestroy()
@Component({
    selector: 'app-tvpackages-channels',
    templateUrl: './tvpackages-channels.component.html',
    styleUrls: ['./tvpackages-channels.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TvpackagesChannelsComponent implements OnInit, OnChanges {
    // Grid
    currencyFormat: {format: string} = {format: '#,###.00\',- CZK\''};

    dateFormat: string;

    toolbar: ToolbarItems[] | object;

    data: object;

    fields: object = {text: 'label', value: 'value'};

    filterSettings: FilterSettingsModel;

    filterCheckboxSettings: FilterSettingsModel;

    selectionSettings: SelectionSettingsModel;

    editSettings: EditSettingsModel;

    groupOptions: GroupSettingsModel;

    pageOptions: PageSettingsModel;

    expendituresColumnsPrice: ColumnModel[];

    expendituresColumnsItems: ColumnModel[];

    filterDate: object;

    sortOptions: object;

    requiredRules: object;

    exportCurrentPage = false;

    // Dropdowns
    height = '240px';

    categoryFilterData: Array<string> = [];

    // Dialogs
    buttonsCancel: ButtonPropsModel[] = [
        {
            click: (): void => {
                this.confirmationCancel.hide();
            },
            buttonModel: {
                isPrimary: true,
                content: 'OK',
            },
        },
    ];

    // Variables
    currentUser: User | null;

    channels: TvChannels[] = [];

    // Loaders
    tableLoad = true;

    @Input() tvPackage: TvPackages | null = null;

    @Input() isVisible = false;

    @Output() readonly tvChannelsDialogState = new EventEmitter<boolean>();

    // Grid
    @ViewChild('tvChannelsGrid', {static: false}) gridTvChannels: GridComponent;

    @ViewChild('searchtext') searchtextObj: TextBoxComponent;

    // Dropdowns
    @ViewChild('viewFilter') viewFilterInstance: DropDownListComponent;

    // Dialogs
    @ViewChild('formDialog') formDialogObj: DialogComponent;

    @ViewChild('confirmationCancel') confirmationCancel: DialogComponent;

    constructor(
        private readonly route: ActivatedRoute,
        private readonly router: Router,
        private readonly dataNetbaseService: DataNetbaseService,
        private readonly authenticationService: AuthenticationService,
        private readonly permissionService: PermissionService,
        private readonly ref: ChangeDetectorRef,
    ) {
        this.currentUser = this.authenticationService.currentUserValue;
    }

    ngOnInit(): void {
        this.filterSettings = {
            type: 'Menu',
            showFilterBarStatus: true,
            ignoreAccent: true,
        };

        this.filterCheckboxSettings = {
            type: 'CheckBox',
            showFilterBarStatus: true,
            ignoreAccent: true,
        };

        this.filterSettings = {
            type: 'Excel',
            showFilterBarOperator: true,
            showFilterBarStatus: true,
            ignoreAccent: true,
        };

        this.selectionSettings = {
            persistSelection: true,
            type: 'Multiple',
            checkboxOnly: true,
        };

        this.toolbar = [
            {
                text: 'Občerstvit data',
                tooltipText: 'Občerstvit data',
                prefixIcon: 'e-refresh',
                id: 'customRefresh',
            },
            'Print',
            'ExcelExport',
        ];
        this.categoryFilterData = ['všechny bonusy', 'aktivní bonusy', 'neaktivní bonusy'];
        this.pageOptions = {pageSize: 50};
        this.dateFormat = 'dd.MM.yyyy HH:mm';
        this.filterDate = {type: 'Menu', params: {format: this.dateFormat}};
        this.groupOptions = {
            showGroupedColumn: true,
            disablePageWiseAggregates: false,
        };

        this.editSettings = {
            showConfirmDialog: false,
            showDeleteConfirmDialog: false,
            allowEditing: false,
            allowAdding: false,
            allowDeleting: false,
            allowEditOnDblClick: false,
            mode: 'Dialog',
        };
        this.requiredRules = {required: true};
    }

    ngOnChanges(): void {
        if (this.isVisible && this.tvPackage) {
            this.data = [];
            this.tableLoad = true;
            this.formDialogObj.header = `TV kanály k TV balíčku #${this.tvPackage.id} - ${this.tvPackage.name}`;
        }
    }

    changeDialogState(value: boolean): void {
        this.tvChannelsDialogState.emit(value);
    }

    loadData(): DataResult[] {
        this.gridTvChannels.clearRowSelection();

        console.info('NEW DATA BOUND');

        const elements: TvChannelsGrid[] = [];

        if (this.channels.length > 0) {
            this.channels.map((channel: TvChannels) => {
                elements.push({
                    id: channel.id,
                    name: channel.name,
                    logo: channel.logo
                        ? `<img src="${channel.logo}" class="tvlogo" alt="TV logo"/>`
                        : '',
                    app_available: channel.app_available,
                    hd_resolution: channel.hd_resolution,
                    theme: channel.theme.name,
                    created_by: channel.creator.id,
                    creator_str: channel.creator.fullname,
                    creator_img: channel.creator.personalphoto,
                    created_date: new Date(channel.created_date),
                    updated_date: channel.updated_date ? new Date(channel.updated_date) : null,
                    deleted_date: channel.deleted_date ? new Date(channel.deleted_date) : null,
                });
            });
        }

        this.tableLoad = false;
        this.formDialogObj.header = `TV kanály k TV balíčku #${this.tvPackage?.id ?? 'null'} - ${
            this.tvPackage?.name ?? 'null'
        } | celkem: (${elements.length} kanálů)`;
        this.ref.markForCheck();

        return elements as unknown as DataResult[];
    }

    setInitialGridFiltering(): void {
        this.gridTvChannels.clearFiltering();
        // this.gridBonuses.filterByColumn('created_by', 'equal', this.currentUser.id, 'and', false, true);
        // this.viewFilterInstance.value = 'moje žádanky - všechny';
        this.ref.markForCheck();
    }

    onLoad(): void {
        const wrapper1 = document.getElementById('scroll_wrapper1_bonuses');
        const wrapper2 = document.getElementById('scroll_wrapper2_bonuses');

        if (wrapper1 && wrapper2) {
            wrapper1.onscroll = (): void => {
                wrapper2.scrollLeft = wrapper1.scrollLeft;
            };

            wrapper2.onscroll = (): void => {
                wrapper1.scrollLeft = wrapper2.scrollLeft;
            };
        }

        if (this.tvPackage) {
            this.dataNetbaseService
                .getTvPackageData(this.tvPackage.id)
                .pipe(untilDestroyed(this))
                .subscribe(
                    (tvPackage: TvPackages) => {
                        this.channels = tvPackage.tv_channels;
                        this.data = this.gridTvChannels.dataSource = this.loadData();
                        this.sortOptions = {
                            columns: [
                                {field: 'created_date', direction: 'Descending'},
                                {
                                    field: 'id',
                                    direction: 'Descending',
                                },
                            ],
                        };
                    },
                    error => {
                        console.error(error);
                        this.tableLoad = false;
                        this.ref.markForCheck();
                    },
                );
        }
    }

    search(): void {
        this.gridTvChannels.search(this.searchtextObj.value);
    }

    rowDataBound(args: RowDataBoundEventArgs): void {
        const data = args.data as TvChannels;

        if (args.row && data.deleted_date) {
            args.row.getElementsByClassName('e-gridchkbox')[0].classList.add('disablecheckbox');
            args.row
                .getElementsByClassName('e-checkbox-wrapper')[0]
                .classList.add('disablecheckbox');
            args.row.classList.add('e-disabled');
        }

        if (
            this.currentUser &&
            args.row &&
            data.created_by !== this.currentUser.id &&
            !this.permissionService.checkUserISServiceAdmin(this.currentUser) &&
            !this.permissionService.checkUserISAdministrative(this.currentUser) &&
            !this.permissionService.checkUserISMarketingAdmin(this.currentUser)
        ) {
            args.row.getElementsByClassName('e-gridchkbox')[0].classList.add('disablecheckbox');
            args.row
                .getElementsByClassName('e-checkbox-wrapper')[0]
                .classList.add('disablecheckbox');
        }

        this.ref.markForCheck();
    }

    onStatusFilterChange(e: ChangeEventArgs): void {
        if (e.value === 'všechny TV kanály') {
            this.gridTvChannels.clearFiltering();
        } else if (e.value === 'aktivní TV kanály') {
            this.gridTvChannels.clearFiltering();
            this.gridTvChannels.filterByColumn('deleted_date', 'equal', null, 'and', false, true);
        } else if (e.value === 'neaktivní TV kanály') {
            this.gridTvChannels.clearFiltering();
            this.gridTvChannels.filterByColumn(
                'deleted_date',
                'notequal',
                null,
                'and',
                false,
                true,
            );
        }
    }

    cancelBtnClick(): void {
        this.searchtextObj.value = '';
        this.gridTvChannels.searchSettings.key = '';
    }

    toolbarClick(args: ClickEventArgs): void {
        const xlsProp: ExcelExportProperties = {
            fileName: `js_tvChannels-${this.tvPackage?.name ?? 'null'}_export.xlsx`,
            enableFilter: true,
            exportType: this.exportCurrentPage ? 'CurrentPage' : 'AllPages',
        };

        if (args.item.id === 'tvChannelsGrid_excelexport') {
            void this.gridTvChannels.excelExport(xlsProp);
        } else if (args.item.id === 'customRefresh') {
            this.tableLoad = true;
            console.info('refresh DATA!');
            this.ref.markForCheck();
            this.dataNetbaseService.clearTvPackagesCache();
            this.dataNetbaseService.setTvPackagesDataSource();
        }
    }
}
