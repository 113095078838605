<section id="middle_widgets">
  <div class="row">
    <div class="col-xl-3 col-lg-4 col-md-6 my-4">
      <div class="card-wrap">
        <div class="e-card e-rou e-card-horizontal" tabindex="0">
          <i
            aria-hidden="true"
            class="fa-light fa-5x fa-umbrella-beach e-warning m-auto"
          ></i>
          <div class="e-card-stacked">
            <div class="e-card-header">
              <div class="e-card-header-caption">
                <div class="e-card-header-title">
                  <h5 class="e-card-title">DOVOLENÁ</h5>
                </div>
              </div>
            </div>
            <div class="e-card-content">
              <h6 *ngIf="loadingVacationWidget">
                <app-loader></app-loader>
              </h6>
              <h5 *ngIf="!loadingVacationWidget">
                <strong>{{ vacations.length }}</strong>
              </h5>
              <p>
                <small>ve frontě</small>
              </p>
              <a
                [queryParams]="{ view: 'grid' }"
                [routerLink]="['vacation']"
                class="e-btn e-warning btn-p-zero e-small m-0 mb-1"
              >Zobrazit</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xl-3 col-lg-4 col-md-6 my-4">
      <div class="card-wrap">
        <div class="e-card e-rou e-card-horizontal" tabindex="0">
          <i
            aria-hidden="true"
            class="fa-light fa-5x fa-box text-primary m-auto"
          ></i>
          <div class="e-card-stacked">
            <div class="e-card-header">
              <div class="e-card-header-caption">
                <div class="e-card-header-title">
                  <h5 class="e-card-title">MATERIÁL</h5>
                </div>
              </div>
            </div>
            <div class="e-card-content">
              <h6 *ngIf="loadingMaterialWidget">
                <app-loader></app-loader>
              </h6>
              <h5 *ngIf="!loadingMaterialWidget">
                <strong>{{ materials.length }}</strong>
              </h5>
              <p>
                <small>ve frontě</small>
              </p>
              <a
                [queryParams]="{ view: 'table' }"
                [routerLink]="['material']"
                class="e-btn e-info btn-p-zero e-small m-0 mb-1"
              >Zobrazit</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xl-3 col-lg-4 col-md-6 my-4">
      <div class="card-wrap">
        <div class="e-card e-rou e-card-horizontal" tabindex="0">
          <i
            aria-hidden="true"
            class="fa-light fa-5x fa-books e-success m-auto"
          ></i>
          <div class="e-card-stacked">
            <div class="e-card-header">
              <div class="e-card-header-caption">
                <div class="e-card-header-title">
                  <h5 class="e-card-title">POSTUPY</h5>
                </div>
              </div>
            </div>
            <div class="e-card-content">
              <h6 *ngIf="loadingKnowledgeWidget">
                <app-loader></app-loader>
              </h6>
              <h5 *ngIf="!loadingKnowledgeWidget">
                <strong>{{ knowledges.length }}</strong>
              </h5>
              <p>
                <small>ve frontě</small>
              </p>
              <a
                class="e-btn e-success btn-p-zero e-small m-0 mb-1"
                routerLink="/knowledge/list"
              >Zobrazit</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xl-3 col-lg-4 col-md-6 my-4">
      <div class="card-wrap">
        <div class="e-card e-rounded e-card-horizontal" tabindex="0">
          <i
            aria-hidden="true"
            class="fa-light fa-5x fa-gift text-danger m-auto"
          ></i>
          <div class="e-card-stacked">
            <div class="e-card-header">
              <div class="e-card-header-caption">
                <div class="e-card-header-title">
                  <h5 class="e-card-title">DNES MÁ SVÁTEK</h5>
                </div>
              </div>
            </div>
            <div class="e-card-content">
              <h6 *ngIf="loadingHolidaysWidget">
                <app-loader></app-loader>
              </h6>
              <div *ngIf="!loadingHolidaysWidget">
                <h5 class="text-center">
                  <strong>{{ holidays.name }}</strong>
                </h5>
                <h6 class="text-center mt-0 mb-0">
                  {{
                    holidays.date | dateFormat: 'DD.MM.YYYY'
                  }}
                </h6>
                <hr />
                <div class="d-flex justify-content-between p-2">
                                    <span class="text-muted"
                                    >státní svátek:</span
                                    >
                  <span class="text-right">
                                        <span
                                          *ngIf="holidays.isPublicHoliday"
                                          class="e-badge e-badge-pill e-badge-success"
                                        >
                                            ano
                                        </span>
                                        <span
                                          *ngIf="!holidays.isPublicHoliday"
                                          class="e-badge e-badge-pill e-badge-danger"
                                        >
                                            ne
                                        </span>
                                    </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
