<ejs-tab
  #adressTab
  (created)="onCreate()"
  (selected)="select($event)"
  (selecting)="preventSwipe($event)"
  enablePersistence="true"
  height="auto"
  heightAdjustMode="Content"
  id="adressTab"
>
  <e-tabitems>
    <e-tabitem [header]="headerText[0]">
      <ng-template #content>
        <app-service-connected-ruian></app-service-connected-ruian>
      </ng-template>
    </e-tabitem>
    <e-tabitem [header]="headerText[1]">
      <ng-template #content>
        <app-service-connected-field></app-service-connected-field>
      </ng-template>
    </e-tabitem>
    <e-tabitem [header]="headerText[2]">
      <ng-template #content>
        <app-service-excluded-address></app-service-excluded-address>
      </ng-template>
    </e-tabitem>
    <e-tabitem [header]="headerText[3]">
      <ng-template #content>
        <app-service-untrusted-address></app-service-untrusted-address>
      </ng-template>
    </e-tabitem>
    <e-tabitem [disabled]="true" [header]="headerText[4]">
      <ng-template #content>
        <app-service-outages></app-service-outages>
      </ng-template>
    </e-tabitem>
  </e-tabitems>
</ejs-tab>
