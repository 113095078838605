import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    HostListener,
    Input,
    OnChanges,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {DataService} from '@src/app/_services/data.service';
import {DataNetbaseService} from '@src/app/_services/data-netbase.service';
import {InstallInput, ServicesService} from '@src/app/features/services/services.service';
import {MessageService} from '@src/app/_services/message.service';
import {AuthenticationService} from '@src/app/_services/authentication.service';
import {InstallTypes} from '@src/app/_models/services/install-types';
import {User} from '@src/app/_models/user/user';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {DialogComponent} from '@syncfusion/ej2-angular-popups';
import {Observable} from 'rxjs';
import {ComponentCanDeactivate} from '@src/app/_guards/changes.guard';
import {ActivatedRoute, Params, Router} from '@angular/router';

@UntilDestroy()
@Component({
    selector: 'app-installation-type-form',
    templateUrl: './installation-type-form.component.html',
    styleUrls: ['./installation-type-form.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InstallationTypeFormComponent implements OnInit, OnChanges, ComponentCanDeactivate {
    // Forms
    installationTypeForm: FormGroup;

    isDirty = false;

    isChecked = false;

    submited = false;

    // Variables
    currentUser: User | null;

    // Loaders
    loadingInstallationType = false;

    @Input() installType: InstallTypes | null = null;

    @Input() isCreate = false;

    @Input() isUpdate = false;

    @Input() isVisible = false;

    @Input() isCopy = false;

    @Output() readonly installTypeFormDialogState = new EventEmitter<boolean>();

    // Dialogs
    @ViewChild('formDialog') formDialogObj: DialogComponent;

    constructor(
        private readonly router: Router,
        private readonly route: ActivatedRoute,
        private readonly formBuilder: FormBuilder,
        private readonly ref: ChangeDetectorRef,
        private readonly dataService: DataService,
        private readonly dataNetbaseService: DataNetbaseService,
        private readonly servicesService: ServicesService,
        private readonly messages: MessageService,
        private readonly authenticationService: AuthenticationService,
    ) {
        this.currentUser = this.authenticationService.currentUserValue;
    }

    get f(): {[key: string]: AbstractControl} {
        return this.installationTypeForm.controls;
    }

    @HostListener('window:beforeunload')
    canDeactivate(): Observable<boolean> | boolean {
        return !this.isDirty;
    }

    ngOnInit(): void {
        this.installationTypeForm = this.formBuilder.group({
            name: [
                '',
                Validators.compose([
                    Validators.required,
                    Validators.maxLength(255),
                    Validators.minLength(2),
                ]),
            ],
            inst_price: [0, Validators.compose([Validators.required, Validators.min(0)])],
            description: [''],
        });

        this.installationTypeForm.valueChanges.pipe(untilDestroyed(this))
            .subscribe(() => {
                this.isDirty = this.installationTypeForm.dirty;
                this.showInputErrors();
            });
    }

    ngOnChanges(): void {
        if (this.isVisible) {
            const queryParams: Params = {form: null};

            void this.router.navigate([], {
                relativeTo: this.route,
                queryParams,
                queryParamsHandling: 'merge',
            });

            if (this.isUpdate && this.installType) {
                this.installationTypeForm.reset();
                this.formDialogObj.header = `Typ technologie instalace #${this.installType.id}`;
                this.fillTheForm();
                this.showInputErrors();
            }

            if (this.isCreate) {
                this.installationTypeForm.reset();
                this.formDialogObj.header = 'Nový typ technologie instalace';
            }

            if (this.isCopy) {
                this.installationTypeForm.reset();
                this.formDialogObj.header = 'Nový typ technologie instalace';
                this.fillTheForm();
                this.showInputErrors();
            }
        }
    }

    changeDialogState(value: boolean): void {
        this.installTypeFormDialogState.emit(value);
    }

    fillTheForm(): void {
        this.installationTypeForm.controls.name.patchValue(this.installType?.name);
        this.installationTypeForm.controls.inst_price.patchValue(this.installType?.inst_price);
        this.installationTypeForm.controls.description.patchValue(this.installType?.description);
    }

    onSubmit(): void {
        this.submited = true;
        this.isDirty = false;
        this.loadingInstallationType = true;

        if (this.isCreate || this.isCopy) {
            this.createInstallationType();
        }

        if (this.isUpdate) {
            this.editInstallationType();
        }
    }

    createInstallationType(): void {
        if (this.installationTypeForm.invalid) {
            console.error('form is not valid!');

            return;
        }

        this.loadingInstallationType = true;

        const installationTypeData: InstallInput = {
            name: this.f.name.value,
            inst_price: this.f.inst_price.value,
            description: this.f.description.value,
            created_by: this.currentUser?.id,
        };

        this.servicesService
            .addInstallType(installationTypeData)
            .pipe(untilDestroyed(this))
            .subscribe(
                (data: InstallTypes) => {
                    const body = `${data.name} přidán do nabídky`;
                    const options = {progressBar: true, timeOut: 5000};

                    this.messages.showSuccess(
                        'Typ smluvního úvazku úspěšně vytvořen',
                        body,
                        options,
                    );
                    this.dataNetbaseService.setInstallationTypesDataSource();
                    this.isCreate = false;
                    this.loadingInstallationType = false;
                    this.ref.markForCheck();
                    this.formDialogObj.hide();
                },
                error => {
                    console.error(error);

                    const body = 'Zkuste to znovu později';
                    const options = {
                        progressBar: true,
                        timeOut: 5000,
                        toastClass: 'red',
                    };

                    this.messages.showError('Chyba při vytváření typu instalace', body, options);
                    this.loadingInstallationType = false;
                    this.ref.markForCheck();
                },
            );
    }

    editInstallationType(): void {
        if (this.installationTypeForm.invalid) {
            console.error('form is not valid!');

            return;
        }

        this.loadingInstallationType = true;

        const installationTypeData: InstallInput = {
            id: this.installType?.id,
            name: this.f.name.value,
            inst_price: this.f.inst_price.value,
            description: this.f.description.value,
            created_by: this.currentUser?.id,
        };

        this.servicesService
            .updateInstallType(installationTypeData)
            ?.pipe(untilDestroyed(this))
            .subscribe(
                (data: InstallTypes) => {
                    const body = `${data.name} upraven`;
                    const options = {progressBar: true, timeOut: 5000};

                    this.messages.showSuccess(
                        'Typ smluvního úvazku úspěšně upraven',
                        body,
                        options,
                    );
                    this.dataNetbaseService.setInstallationTypesDataSource();
                    this.isUpdate = false;
                    this.loadingInstallationType = false;
                    this.ref.markForCheck();
                    this.formDialogObj.hide();
                },
                error => {
                    console.error(error);

                    const body = 'Zkuste to znovu později';
                    const options = {
                        progressBar: true,
                        timeOut: 5000,
                        toastClass: 'red',
                    };

                    this.messages.showError('Chyba při úpravě typu instalace', body, options);
                    this.loadingInstallationType = false;
                    this.ref.markForCheck();
                },
            );
    }

    showInputErrors(): void {
        this.isChecked = true;
        /* console.log('VALID: ' + this.ticketForm.valid);
        Object.keys(this.ticketForm.controls).forEach(key => {
            const controlErrors: ValidationErrors = this.ticketForm.get(key).errors;
            if (controlErrors != null) {
                Object.keys(controlErrors).forEach(keyError => {
                    console.log('Key control: ' + key + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError]);
                });
            }
        });*/
        this.installationTypeForm.markAllAsTouched();
        this.ref.markForCheck();
    }
}
