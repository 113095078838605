<div class="d-flex justify-content-end">
  <small class="text-muted">legenda:</small>&nbsp;
  <span
    class="e-badge"
    style="background-color: #8db304; border-color: #8db304; color: #ffffff"
  >služba</span
  >
  <span
    class="e-badge"
    style="background-color: #2a7faf; border-color: #2a7faf; color: #ffffff"
  >příslužba</span
  >
  <span
    class="e-badge"
    style="background-color: #b30418; border-color: #b30418; color: #ffffff"
  >smazáno</span
  >
  <span
    class="e-badge"
    style="background-color: #ff8c00; border-color: #ff8c00; color: #ffffff"
  >státní svátek</span
  >
</div>
<full-calendar
  #hotlineCalendar
  [deepChangeDetection]="false"
  [options]="calendarOptions"
></full-calendar>
<div *ngIf="loadingCalendar" class="row">
  <div class="col-12 text-center">
    <app-loader></app-loader>
  </div>
</div>
