<ejs-dialog
  #formDialog
  (close)="
        isUpdate = false;
        isCreate = false;
        isDirty = false;
        isChecked = false;
        isCopy = false;
        submited = false;
        changeDialogState(false)
    "
  [isModal]="true"
  [showCloseIcon]="true"
  [visible]="isVisible"
  [width]="'85%'"
  id="formDialog"
>
  <form [formGroup]="tvServiceForm">
    <ng-template #content>
      <div class="dialogContent">
        <div class="row">
          <div class="col-12 mb-3">
            <label
              [ngClass]="
                                f.name.valid && (f.name.dirty || f.name.touched)
                                    ? 'text-success'
                                    : f.name.invalid &&
                                      (f.name.dirty || f.name.touched)
                                    ? 'text-danger'
                                    : 'text-muted'
                            "
              for="tvPackageForm-name"
            >* Název tarifu:</label
            >
            <ejs-textbox
              [ngClass]="
                                (f.name.dirty || f.name.touched) &&
                                f.name.invalid
                                    ? 'e-error'
                                    : (f.name.dirty || f.name.touched) &&
                                      f.name.valid
                                    ? 'e-success'
                                    : ''
                            "
              floatLabelType="Auto"
              formControlName="name"
              id="tvPackageForm-name"
            ></ejs-textbox>
            <small
              *ngIf="
                                (f.name.dirty || f.name.touched) &&
                                f.name.errors &&
                                f.name.errors.required
                            "
              class="text-danger"
            >Název tarifu je povinný</small
            >
          </div>
          <div class="col-12 col-md-6 mb-3">
            <label
              [ngClass]="
                                f.provider_id.valid
                                    ? 'text-success'
                                    : f.provider_id.invalid &&
                                      (f.provider_id.dirty ||
                                          f.provider_id.touched)
                                    ? 'text-danger'
                                    : 'text-muted'
                            "
              for="provider"
            >* Provider tarifu:</label
            >
            <ejs-dropdownlist
              #provider
              (filtering)="onFilteringProviders($event)"
              [allowFiltering]="true"
              [cssClass]="
                                (f.provider_id.dirty ||
                                    f.provider_id.touched) &&
                                f.provider_id.invalid
                                    ? 'e-error'
                                    : (f.provider_id.dirty ||
                                          f.provider_id.touched) &&
                                      f.provider_id.valid
                                    ? 'e-success'
                                    : ''
                            "
              [dataSource]="companySelect"
              [fields]="fields"
              [filterBarPlaceholder]="'Vyhledat providera...'"
              [ignoreAccent]="true"
              [placeholder]="'Vyberte providera'"
              [popupHeight]="height"
              formControlName="provider_id"
              id="provider"
              sortOrder="Ascending"
            ></ejs-dropdownlist>
            <small
              *ngIf="
                                (f.provider_id.dirty ||
                                    f.provider_id.touched) &&
                                f.provider_id.errors &&
                                f.provider_id.errors.required
                            "
              class="text-danger"
            >Vyberte alespoň jednoho providera tarifu</small
            >
          </div>
          <div class="col-12 col-md-6 mb-3">
            <label
              [ngClass]="
                                f.transfer_id.valid
                                    ? 'text-success'
                                    : f.transfer_id.invalid &&
                                      (f.transfer_id.dirty ||
                                          f.transfer_id.touched)
                                    ? 'text-danger'
                                    : 'text-muted'
                            "
              for="transfer"
            >* Technologie přenosu:</label
            >
            <ejs-dropdownlist
              #transfer
              (filtering)="onFilteringTransferTypes($event)"
              [allowFiltering]="true"
              [cssClass]="
                                (f.transfer_id.dirty ||
                                    f.transfer_id.touched) &&
                                f.transfer_id.invalid
                                    ? 'e-error'
                                    : (f.transfer_id.dirty ||
                                          f.transfer_id.touched) &&
                                      f.transfer_id.valid
                                    ? 'e-success'
                                    : ''
                            "
              [dataSource]="transferSelect"
              [fields]="fields"
              [filterBarPlaceholder]="
                                'Vyhledat technologii přenosu...'
                            "
              [ignoreAccent]="true"
              [placeholder]="'Vyberte technologii přenosu'"
              [popupHeight]="height"
              formControlName="transfer_id"
              id="transfer"
              sortOrder="Ascending"
            ></ejs-dropdownlist>
            <small
              *ngIf="
                                (f.transfer_id.dirty ||
                                    f.transfer_id.touched) &&
                                f.transfer_id.errors &&
                                f.transfer_id.errors.required
                            "
              class="text-danger"
            >Vyberte alespoň jednu z technologíí přenosu</small
            >
          </div>
          <div class="col-12 col-md-6 mb-3">
            <label
              [ngClass]="
                                f.pernament_price.valid &&
                                (f.pernament_price.dirty ||
                                    f.pernament_price.touched)
                                    ? 'text-success'
                                    : f.pernament_price.invalid &&
                                      (f.pernament_price.dirty ||
                                          f.pernament_price.touched)
                                    ? 'text-danger'
                                    : 'text-muted'
                            "
              for="tvPackageForm-pernament_price"
            >* Cena tarifu:</label
            >
            <ejs-numerictextbox
              [format]="'#,###.00\',- CZK\''"
              [ngClass]="
                                (f.pernament_price.dirty ||
                                    f.pernament_price.touched) &&
                                f.pernament_price.invalid
                                    ? 'e-error'
                                    : (f.pernament_price.dirty ||
                                          f.pernament_price.touched) &&
                                      f.pernament_price.valid
                                    ? 'e-success'
                                    : ''
                            "
              formControlName="pernament_price"
              id="tvPackageForm-pernament_price"
              step="0.01"
            ></ejs-numerictextbox>
            <small
              *ngIf="
                                (f.pernament_price.dirty ||
                                    f.pernament_price.touched) &&
                                f.pernament_price.errors &&
                                f.pernament_price.errors.required
                            "
              class="text-danger"
            >Cena tarifu je povinná</small
            >
          </div>
          <div class="col-12 col-md-6 mb-3">
            <label
              [ngClass]="
                                f.discount_id.valid
                                    ? 'text-success'
                                    : f.discount_id.invalid &&
                                      (f.discount_id.dirty ||
                                          f.discount_id.touched)
                                    ? 'text-danger'
                                    : 'text-muted'
                            "
              for="discount"
            >Sleva tarifu:</label
            >
            <ejs-dropdownlist
              #discount
              (filtering)="onFilteringDiscounts($event)"
              [allowFiltering]="true"
              [cssClass]="
                                (f.discount_id.dirty ||
                                    f.discount_id.touched) &&
                                f.discount_id.invalid
                                    ? 'e-error'
                                    : (f.discount_id.dirty ||
                                          f.discount_id.touched) &&
                                      f.discount_id.valid
                                    ? 'e-success'
                                    : ''
                            "
              [dataSource]="discountSelect"
              [fields]="fields"
              [filterBarPlaceholder]="'Vyhledat slevu...'"
              [ignoreAccent]="true"
              [placeholder]="'Vyberte slevu'"
              [popupHeight]="height"
              formControlName="discount_id"
              id="discount"
              sortOrder="Ascending"
            ></ejs-dropdownlist>
            <small
              *ngIf="
                                (f.discount_id.dirty ||
                                    f.discount_id.touched) &&
                                f.discount_id.errors &&
                                f.discount_id.errors.required
                            "
              class="text-danger"
            >Vyberte alespoň jednu ze slev tarifu</small
            >
          </div>
          <div class="col-12 col-lg-4 mb-3">
            <label
              [ngClass]="
                                f.sale_order.valid &&
                                (f.sale_order.dirty || f.sale_order.touched)
                                    ? 'text-success'
                                    : f.sale_order.invalid &&
                                      (f.sale_order.dirty ||
                                          f.sale_order.touched)
                                    ? 'text-danger'
                                    : 'text-muted'
                            "
              for="tvPackageForm-sale_order"
            >* Pořadí nabídky:</label
            >
            <ejs-numerictextbox
              [format]="'###'"
              [ngClass]="
                                (f.sale_order.dirty || f.sale_order.touched) &&
                                f.sale_order.invalid
                                    ? 'e-error'
                                    : (f.sale_order.dirty ||
                                          f.sale_order.touched) &&
                                      f.sale_order.valid
                                    ? 'e-success'
                                    : ''
                            "
              formControlName="sale_order"
              id="tvPackageForm-sale_order"
              step="1"
            ></ejs-numerictextbox>
            <small
              *ngIf="
                                (f.sale_order.dirty || f.sale_order.touched) &&
                                f.sale_order.errors &&
                                f.sale_order.errors.required
                            "
              class="text-danger"
            >Pořadí nabídky je povinné</small
            >
          </div>
          <div class="col-12 col-md-6 col-lg-4 mb-3">
            <ejs-checkbox
              [checked]="false"
              [ngClass]="
                                (f.recommended.dirty ||
                                    f.recommended.touched) &&
                                f.recommended.invalid
                                    ? 'e-error'
                                    : (f.recommended.dirty ||
                                          f.recommended.touched) &&
                                      f.recommended.valid
                                    ? 'e-success'
                                    : ''
                            "
              cssClass="e-primary p-0 mr-3"
              formControlName="recommended"
              label="Doporučeno"
            >
            </ejs-checkbox>
            <small
              *ngIf="
                                (f.recommended.dirty ||
                                    f.recommended.touched) &&
                                f.recommended.errors &&
                                f.recommended.errors.required
                            "
              class="text-danger"
            >Vlastnost doporučeno je povinná</small
            >
          </div>
          <div class="col-12 col-md-6 col-lg-4 mb-3">
            <ejs-checkbox
              [checked]="false"
              [ngClass]="
                                (f.extension.dirty || f.extension.touched) &&
                                f.extension.invalid
                                    ? 'e-error'
                                    : (f.extension.dirty ||
                                          f.extension.touched) &&
                                      f.extension.valid
                                    ? 'e-success'
                                    : ''
                            "
              cssClass="e-primary p-0 mr-3"
              formControlName="extension"
              label="Rozšiřující tarif"
            >
            </ejs-checkbox>
            <small
              *ngIf="
                                (f.extension.dirty || f.extension.touched) &&
                                f.extension.errors &&
                                f.extension.errors.required
                            "
              class="text-danger"
            >Vlastnost rozšíření je povinná</small
            >
          </div>
        </div>
        <div class="row e-card" formArrayName="packages">
          <div class="d-flex e-card-header justify-content-between">
            <span><strong>Balíčky:</strong></span>
            <button
              (click)="addPackage()"
              cssClass="e-btn e-success btn-p-zero e-small float-right"
              ejs-button
              type="button"
            >
                            <span
                              *ngIf="loadingTvServices === true"
                              aria-hidden="true"
                              class="spinner-grow spinner-grow-sm"
                              role="status"
                            ></span>
              <span *ngIf="loadingTvServices === false"
              >Přidat TV balíček</span
              >
              <span *ngIf="loadingTvServices === true"
              >Provádím...</span
              >
            </button>
          </div>
          <div
            *ngFor="
                            let tvPackage of packagesFormGroup.controls;
                            let i = index
                        "
            class="col-12 e-card-content"
          >
            <div [formGroupName]="i" class="row p-0 m-0">
              <div
                class="form-group col-12 col-md-8 col-lg-9 col-md-6 mb-1"
              >
                <label
                  [ngClass]="
                                        getPackagesFormGroup(i).controls[
                                            'package_id'
                                        ].valid
                                            ? 'text-success'
                                            : getPackagesFormGroup(i).controls[
                                                  'package_id'
                                              ].invalid &&
                                              (getPackagesFormGroup(i).controls[
                                                  'package_id'
                                              ].dirty ||
                                                  getPackagesFormGroup(i)
                                                      .controls['package_id']
                                                      .touched)
                                            ? 'text-danger'
                                            : 'text-muted'
                                    "
                  for="tVpackage-{{ i }}"
                >* TV balíček:</label
                >
                <ejs-dropdownlist
                  #tVpackage
                  (filtering)="onFilteringTvPackages($event)"
                  [allowFiltering]="true"
                  [cssClass]="
                                        (getPackagesFormGroup(i).controls[
                                            'package_id'
                                        ].dirty ||
                                            getPackagesFormGroup(i).controls[
                                                'package_id'
                                            ].touched) &&
                                        getPackagesFormGroup(i).controls[
                                            'package_id'
                                        ].invalid
                                            ? 'e-error'
                                            : (getPackagesFormGroup(i).controls[
                                                  'package_id'
                                              ].dirty ||
                                                  getPackagesFormGroup(i)
                                                      .controls['package_id']
                                                      .touched) &&
                                              getPackagesFormGroup(i).controls[
                                                  'package_id'
                                              ].valid
                                            ? 'e-success'
                                            : ''
                                    "
                  [dataSource]="packageSelect"
                  [fields]="fields"
                  [filterBarPlaceholder]="
                                        'Vyhledat TV balíček...'
                                    "
                  [ignoreAccent]="true"
                  [placeholder]="'Vyberte TV balíček'"
                  [popupHeight]="height"
                  formControlName="package_id"
                  id="tVpackage-{{ i }}"
                  sortOrder="Ascending"
                ></ejs-dropdownlist>
                <span
                  *ngIf="
                                        getPackagesFormGroup(i).controls[
                                            'package_id'
                                        ].touched &&
                                        getPackagesFormGroup(i).controls[
                                            'package_id'
                                        ].hasError('required')
                                    "
                  class="text-danger"
                >Vyberte jeden TV balíček</span
                >
              </div>
              <div
                class="form-group col-12 col-md-4 col-lg-3 mb-1 text-right"
              >
                <button
                  (click)="removePackage(i)"
                  cssClass="e-btn e-danger btn-p-zero e-small"
                  ejs-button
                  type="button"
                >
                  Odebrat TV balíček
                </button>
              </div>
            </div>
          </div>
        </div>
        <hr />
        <div class="row e-card" formArrayName="places">
          <div class="d-flex e-card-header justify-content-between">
            <span><strong>Oblasti pokrytí:</strong></span>
            <button
              (click)="addPlace()"
              cssClass="e-btn e-success btn-p-zero e-small float-right"
              ejs-button
              type="button"
            >
                            <span
                              *ngIf="loadingTvServices === true"
                              aria-hidden="true"
                              class="spinner-grow spinner-grow-sm"
                              role="status"
                            ></span>
              <span *ngIf="loadingTvServices === false"
              >Přidat oblast</span
              >
              <span *ngIf="loadingTvServices === true"
              >Provádím...</span
              >
            </button>
          </div>
          <div
            *ngFor="
                            let place of placeFormGroup.controls;
                            let i = index
                        "
            class="col-12 e-card-content"
          >
            <div [formGroupName]="i" class="row p-0 m-0">
              <div class="form-group col-9 col-md-10 mb-1">
                <label
                  [ngClass]="
                                        getPlaceFormGroup(i).controls[
                                            'place_id'
                                        ].valid
                                            ? 'text-success'
                                            : getPlaceFormGroup(i).controls[
                                                  'place_id'
                                              ].invalid &&
                                              (getPlaceFormGroup(i).controls[
                                                  'place_id'
                                              ].dirty ||
                                                  getPlaceFormGroup(i).controls[
                                                      'place_id'
                                                  ].touched)
                                            ? 'text-danger'
                                            : 'text-muted'
                                    "
                  for="place-{{ i }}"
                >* Oblast:</label
                >
                <ejs-dropdownlist
                  #place
                  (filtering)="onFilteringPlaces($event)"
                  [allowFiltering]="true"
                  [cssClass]="
                                        (getPlaceFormGroup(i).controls[
                                            'place_id'
                                        ].dirty ||
                                            getPlaceFormGroup(i).controls[
                                                'place_id'
                                            ].touched) &&
                                        getPlaceFormGroup(i).controls[
                                            'place_id'
                                        ].invalid
                                            ? 'e-error'
                                            : (getPlaceFormGroup(i).controls[
                                                  'place_id'
                                              ].dirty ||
                                                  getPlaceFormGroup(i).controls[
                                                      'place_id'
                                                  ].touched) &&
                                              getPlaceFormGroup(i).controls[
                                                  'place_id'
                                              ].valid
                                            ? 'e-success'
                                            : ''
                                    "
                  [dataSource]="placeSelect"
                  [fields]="fields"
                  [filterBarPlaceholder]="
                                        'Vyhledat oblast...'
                                    "
                  [ignoreAccent]="true"
                  [placeholder]="'Vyberte oblast'"
                  [popupHeight]="height"
                  formControlName="place_id"
                  id="place-{{ i }}"
                  sortOrder="Ascending"
                ></ejs-dropdownlist>
                <span
                  *ngIf="
                                        getPlaceFormGroup(i).controls[
                                            'place_id'
                                        ].touched &&
                                        getPlaceFormGroup(i).controls[
                                            'place_id'
                                        ].hasError('required')
                                    "
                  class="text-danger"
                >Vyberte jednu z oblastí</span
                >
              </div>
              <div
                class="form-group col-3 col-md-2 mb-1 text-right"
              >
                <button
                  (click)="removePlace(i)"
                  cssClass="e-btn e-danger btn-p-zero e-small"
                  ejs-button
                  type="button"
                >
                  Odebrat oblast
                </button>
              </div>
            </div>
          </div>
        </div>
        <hr />
        <div class="row e-card" formArrayName="bonuses">
          <div class="d-flex e-card-header justify-content-between">
            <span><strong>Bonusy:</strong></span>
            <button
              (click)="addBonus()"
              cssClass="e-btn e-success btn-p-zero e-small float-right"
              ejs-button
              type="button"
            >
                            <span
                              *ngIf="loadingTvServices === true"
                              aria-hidden="true"
                              class="spinner-grow spinner-grow-sm"
                              role="status"
                            ></span>
              <span *ngIf="loadingTvServices === false"
              >Přidat bonus</span
              >
              <span *ngIf="loadingTvServices === true"
              >Provádím...</span
              >
            </button>
          </div>
          <div
            *ngFor="
                            let bonus of bonusFormGroup.controls;
                            let i = index
                        "
            class="col-12 e-card-content"
          >
            <div [formGroupName]="i" class="row p-0 m-0">
              <div
                class="form-group col-12 col-md-8 col-lg-9 mb-1"
              >
                <label
                  [ngClass]="
                                        getBonusFormGroup(i).controls[
                                            'bonus_id'
                                        ].valid
                                            ? 'text-success'
                                            : getBonusFormGroup(i).controls[
                                                  'bonus_id'
                                              ].invalid &&
                                              (getBonusFormGroup(i).controls[
                                                  'bonus_id'
                                              ].dirty ||
                                                  getBonusFormGroup(i).controls[
                                                      'bonus_id'
                                                  ].touched)
                                            ? 'text-danger'
                                            : 'text-muted'
                                    "
                  for="bonus-{{ i }}"
                >* Oblast:</label
                >
                <ejs-dropdownlist
                  #bonus
                  (filtering)="onFilteringBonuses($event)"
                  [allowFiltering]="true"
                  [cssClass]="
                                        (getBonusFormGroup(i).controls[
                                            'bonus_id'
                                        ].dirty ||
                                            getBonusFormGroup(i).controls[
                                                'bonus_id'
                                            ].touched) &&
                                        getBonusFormGroup(i).controls[
                                            'bonus_id'
                                        ].invalid
                                            ? 'e-error'
                                            : (getBonusFormGroup(i).controls[
                                                  'bonus_id'
                                              ].dirty ||
                                                  getBonusFormGroup(i).controls[
                                                      'bonus_id'
                                                  ].touched) &&
                                              getBonusFormGroup(i).controls[
                                                  'bonus_id'
                                              ].valid
                                            ? 'e-success'
                                            : ''
                                    "
                  [dataSource]="bonusSelect"
                  [fields]="fields"
                  [filterBarPlaceholder]="'Vyhledat bonus...'"
                  [ignoreAccent]="true"
                  [placeholder]="'Vyberte bonus'"
                  [popupHeight]="height"
                  formControlName="bonus_id"
                  id="bonus-{{ i }}"
                  sortOrder="Ascending"
                ></ejs-dropdownlist>
                <span
                  *ngIf="
                                        getBonusFormGroup(i).controls[
                                            'bonus_id'
                                        ].touched &&
                                        getBonusFormGroup(i).controls[
                                            'bonus_id'
                                        ].hasError('required')
                                    "
                  class="text-danger"
                >Vyberte jeden z bonusů</span
                >
              </div>
              <div
                class="form-group col-12 col-md-4 col-lg-3 mb-1 text-right"
              >
                <button
                  (click)="removeBonus(i)"
                  cssClass="e-btn e-danger btn-p-zero e-small"
                  ejs-button
                  type="button"
                >
                  Odebrat bonus
                </button>
              </div>
            </div>
          </div>
        </div>
        <hr />
        <div class="row e-card" formArrayName="extServices">
          <div class="d-flex e-card-header justify-content-between">
            <span><strong>Rozšiřující balíčky:</strong></span>
            <button
              (click)="addExtService()"
              cssClass="e-btn e-success btn-p-zero e-small float-right"
              ejs-button
              type="button"
            >
                            <span
                              *ngIf="loadingTvServices === true"
                              aria-hidden="true"
                              class="spinner-grow spinner-grow-sm"
                              role="status"
                            ></span>
              <span *ngIf="loadingTvServices === false"
              >Přidat balíček</span
              >
              <span *ngIf="loadingTvServices === true"
              >Provádím...</span
              >
            </button>
          </div>
          <div
            *ngFor="
                            let extService of extServiceFormGroup.controls;
                            let i = index
                        "
            class="col-12 e-card-content"
          >
            <div [formGroupName]="i" class="row p-0 m-0">
              <div
                class="form-group col-12 col-md-8 col-lg-9 col-md-6 mb-1"
              >
                <label
                  [ngClass]="
                                        getExtServiceFormGroup(i).controls[
                                            'ext_service_id'
                                        ].valid
                                            ? 'text-success'
                                            : getExtServiceFormGroup(i)
                                                  .controls['ext_service_id']
                                                  .invalid &&
                                              (getExtServiceFormGroup(i)
                                                  .controls['ext_service_id']
                                                  .dirty ||
                                                  getExtServiceFormGroup(i)
                                                      .controls[
                                                      'ext_service_id'
                                                  ].touched)
                                            ? 'text-danger'
                                            : 'text-muted'
                                    "
                  for="extService-{{ i }}"
                >* Rozšiřující balíček:</label
                >
                <ejs-dropdownlist
                  #extService
                  (filtering)="onFilteringExtServices($event)"
                  [allowFiltering]="true"
                  [cssClass]="
                                        (getExtServiceFormGroup(i).controls[
                                            'ext_service_id'
                                        ].dirty ||
                                            getExtServiceFormGroup(i).controls[
                                                'ext_service_id'
                                            ].touched) &&
                                        getExtServiceFormGroup(i).controls[
                                            'ext_service_id'
                                        ].invalid
                                            ? 'e-error'
                                            : (getExtServiceFormGroup(i)
                                                  .controls['ext_service_id']
                                                  .dirty ||
                                                  getExtServiceFormGroup(i)
                                                      .controls[
                                                      'ext_service_id'
                                                  ].touched) &&
                                              getExtServiceFormGroup(i)
                                                  .controls['ext_service_id']
                                                  .valid
                                            ? 'e-success'
                                            : ''
                                    "
                  [dataSource]="extServiceSelect"
                  [fields]="fields"
                  [filterBarPlaceholder]="
                                        'Vyhledat balíček...'
                                    "
                  [ignoreAccent]="true"
                  [placeholder]="'Vyberte balíček'"
                  [popupHeight]="height"
                  formControlName="ext_service_id"
                  id="extService-{{ i }}"
                  sortOrder="Ascending"
                ></ejs-dropdownlist>
                <span
                  *ngIf="
                                        getExtServiceFormGroup(i).controls[
                                            'ext_service_id'
                                        ].touched &&
                                        getExtServiceFormGroup(i).controls[
                                            'ext_service_id'
                                        ].hasError('required')
                                    "
                  class="text-danger"
                >Vyberte jednu z rozšiřujících služeb</span
                >
              </div>
              <div
                class="form-group col-12 col-md-4 col-lg-3 mb-1 text-right"
              >
                <button
                  (click)="removeExtService(i)"
                  cssClass="e-btn e-danger btn-p-zero e-small"
                  ejs-button
                  type="button"
                >
                  Odebrat balíček
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
    <ng-template #footerTemplate>
      <div class="d-flex justify-content-end">
                <span
                  *ngIf="tvServiceForm.invalid"
                  class="e-badge e-badge-warning p-2 size_2"
                  role="alert"
                >
                    <strong>Formulář je špatně vyplněný</strong>
                </span>
        <span
          *ngIf="tvServiceForm.valid"
          class="e-badge e-badge-success p-2 size_2"
          role="alert"
        >
                    <strong>Formulář je správně vyplněný</strong>
                </span>
      </div>
      <div class="row">
        <div class="col-12 pt-2">
          <button
            (click)="onSubmit()"
            [disabled]="
                            loadingTvServices ||
                            tvServiceForm.invalid ||
                            !isChecked
                        "
            cssClass="e-btn e-success btn-p-zero e-small mr-1"
            ejs-button
            type="submit"
          >
                        <span
                          *ngIf="loadingTvServices === true"
                          aria-hidden="true"
                          class="spinner-grow spinner-grow-sm"
                          role="status"
                        ></span>
            <span *ngIf="loadingTvServices === false">ULOŽIT</span>
            <span *ngIf="loadingTvServices === true"
            >Provádím...</span
            >
          </button>
          <button
            (click)="formDialogObj.hide()"
            cssClass="e-btn e-danger e-outline btn-p-zero e-small"
            ejs-button
            type="button"
          >
            ZRUŠIT
          </button>
        </div>
      </div>
    </ng-template>
  </form>
</ejs-dialog>
