import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnInit,
    ViewChild,
} from '@angular/core';
import {
    ChangeEventArgs,
    DropDownListComponent,
    FilteringEventArgs,
} from '@syncfusion/ej2-angular-dropdowns';
import {
    ColumnModel,
    DataResult,
    EditSettingsModel,
    ExcelExportProperties,
    FilterSettingsModel,
    GridComponent,
    GroupSettingsModel,
    PageSettingsModel,
    RowDataBoundEventArgs,
    SelectionSettingsModel,
    ToolbarItems,
} from '@syncfusion/ej2-angular-grids';
import {ButtonPropsModel, DialogComponent} from '@syncfusion/ej2-angular-popups';
import {User} from '@src/app/_models/user/user';
import {FormBuilder} from '@angular/forms';
import {Observable, Observer} from 'rxjs';
import {Router} from '@angular/router';
import {AuthenticationService} from '@src/app/_services/authentication.service';
import {DataService} from '@src/app/_services/data.service';
import {PermissionService} from '@src/app/_services/permission.service';
import {MessageService} from '@src/app/_services/message.service';
import {EmitType} from '@syncfusion/ej2-base';
import {Query} from '@syncfusion/ej2-data';
import {ClickEventArgs} from '@syncfusion/ej2-angular-navigations';
import {registerLocaleData} from '@angular/common';
import moment from 'moment';
import localeCs from '@angular/common/locales/cs';
import {DataNetbaseService} from '@src/app/_services/data-netbase.service';
import {LeadsGridItems, ServicesService} from '@src/app/features/services/services.service';
import {Leads} from '@src/app/_models/netbase/leads';
import {ExtendServices} from '@src/app/_models/services/extend-services';
import {TextBoxComponent} from '@syncfusion/ej2-angular-inputs';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {TvPackages} from '@src/app/_models/services/tv-packages';

moment.updateLocale('cs', {workingWeekdays: [1, 2, 3, 4, 5]});
registerLocaleData(localeCs);

@UntilDestroy()
@Component({
    selector: 'app-services-leads',
    templateUrl: './services-leads.component.html',
    styleUrls: ['./services-leads.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ServicesLeadsComponent implements OnInit {
    // Grids
    filterSettings: FilterSettingsModel;

    selectionSettings: SelectionSettingsModel;

    editSettings: EditSettingsModel;

    groupOptions: GroupSettingsModel;

    pageOptions: PageSettingsModel;

    pricesColumns: ColumnModel[];

    feesColumns: ColumnModel[];

    filterDate: object;

    filterText: object;

    requiredRules: object;

    currencyFormat: {format: string} = {format: '#,###.00\',- CZK\''};

    dateFormat: string;

    toolbar: ToolbarItems[] | object;

    dataLeads: object;

    sortOptions: object;

    exportCurrentPage = false;

    // Dropdowns
    height = '240px';

    categorySelect: Array<{value: boolean | number | string; label: string}> = [];

    // Dialogs
    renderLeadFormDialog = false;

    buttonsDelete: ButtonPropsModel[] = [
        {
            click: (): void => {
                this.deleteLead();
            },
            isFlat: false,
            buttonModel: {
                content: 'OK',
                cssClass: 'e-success e-outline',
            },
        },
        {
            click: (): void => {
                this.confirmationDelete.hide();
            },
            buttonModel: {
                content: 'ZRUŠIT',
                cssClass: 'e-danger e-outline',
            },
        },
    ];

    buttonsConfirmationFinishCW: ButtonPropsModel[] = [
        {
            click: (): void => {
                this.finishLeadCW();
            },
            isFlat: false,
            buttonModel: {
                content: 'OK',
                cssClass: 'e-success e-outline',
            },
        },
        {
            click: (): void => {
                this.confirmationFinishCW.hide();
            },
            buttonModel: {
                content: 'ZRUŠIT',
                cssClass: 'e-danger e-outline',
            },
        },
    ];

    buttonsCancel: ButtonPropsModel[] = [
        {
            click: (): void => {
                this.confirmationCancel.hide();
            },
            buttonModel: {
                isPrimary: true,
                content: 'OK',
            },
        },
    ];

    // Forms
    isUpdate = false;

    // Variables
    currentUser: User | null;

    leads: Leads[] | undefined = [];

    categoryFilterData: string[] = [];

    clickedRow: Leads[] = [];

    clickedRow$ = new Observable<Leads[]>((observer: Observer<Leads[]>) => {
        observer.next(this.clickedRow);
    });

    // Loaders
    loadingLead = false;

    // Grids
    @ViewChild('leadsGrid') gridLeads: GridComponent;

    @ViewChild('searchtext') searchtextObj: TextBoxComponent;

    // Dropdowns
    @ViewChild('viewFilter') viewFilterInstance: DropDownListComponent;

    // Dialogs
    @ViewChild('confirmationDelete') confirmationDelete: DialogComponent;

    @ViewChild('confirmationFinishCW') confirmationFinishCW: DialogComponent;

    @ViewChild('confirmationCancel') confirmationCancel: DialogComponent;

    constructor(
        private readonly router: Router,
        private readonly ref: ChangeDetectorRef,
        private readonly formBuilder: FormBuilder,
        private readonly authenticationService: AuthenticationService,
        private readonly dataService: DataService,
        private readonly dataNetbaseService: DataNetbaseService,
        private readonly servicesService: ServicesService,
        private readonly permissionService: PermissionService,
        private readonly messages: MessageService,
    ) {
        this.currentUser = this.authenticationService.currentUserValue;
    }

    ngOnInit(): void {
        this.filterSettings = {
            type: 'Excel',
            showFilterBarOperator: true,
            showFilterBarStatus: true,
            ignoreAccent: true,
        };

        this.selectionSettings = {
            persistSelection: false,
            type: 'Multiple',
            checkboxOnly: true,
        };

        this.toolbar = [
            {
                text: 'Občerstvit data',
                tooltipText: 'Občerstvit data',
                prefixIcon: 'e-refresh',
                id: 'customRefresh',
            },
            'Print',
            'ExcelExport',
            'Edit',
            {
                text: 'Smazat',
                tooltipText: 'Smazat lead',
                prefixIcon: 'e-remove',
                id: 'delete',
            },
            {
                text: 'Dokončit v CallWizardu',
                tooltipText: 'Dokončit lead v callwizardu',
                prefixIcon: 'e-check',
                id: 'cw_finish',
            },
            {
                text: 'Detail',
                tooltipText: 'Detail leadu',
                prefixIcon: 'e-openfolder',
                id: 'detail',
            },
        ];

        this.categoryFilterData = [
            'všechny leady',
            'moje leady - vyřízené',
            'moje leady - fronta',
            'leady ke zpracování',
        ];
        this.pageOptions = {pageSize: 50};
        this.dateFormat = 'dd.MM.yyyy HH:mm';
        this.filterDate = {type: 'Menu', params: {format: this.dateFormat}};
        this.filterText = {
            type: 'FilterBar',
            params: {format: this.dateFormat},
        };

        this.groupOptions = {
            showGroupedColumn: true,
            disablePageWiseAggregates: false,
        };

        this.editSettings = {
            allowEditing: true,
            allowAdding: false,
            allowDeleting: true,
            allowEditOnDblClick: false,
            mode: 'Dialog',
        };

        this.requiredRules = {required: true};

        this.pricesColumns = [
            {
                field: 'internet_price',
                headerText: 'Internet',
                format: '#,###.00\',- CZK\'',
                textAlign: 'Right',
                maxWidth: 125,
                minWidth: 100,
                clipMode: 'EllipsisWithTooltip',
                autoFit: true,
            },
            {
                field: 'television_price',
                headerText: 'Televize',
                format: '#,###.00\',- CZK\'',
                textAlign: 'Right',
                maxWidth: 125,
                minWidth: 100,
                clipMode: 'EllipsisWithTooltip',
                autoFit: true,
            },
            {
                field: 'wifi_price',
                headerText: 'Wi-Fi',
                format: '#,###.00\',- CZK\'',
                textAlign: 'Right',
                maxWidth: 125,
                minWidth: 100,
                clipMode: 'EllipsisWithTooltip',
                autoFit: true,
            },
            {
                field: 'ext_services_price',
                headerText: 'Rozšíření',
                format: '#,###.00\',- CZK\'',
                textAlign: 'Right',
                maxWidth: 125,
                minWidth: 100,
                clipMode: 'EllipsisWithTooltip',
                autoFit: true,
            },
            {
                field: 'total_price',
                headerText: 'Celkem',
                format: '#,###.00\',- CZK\'',
                textAlign: 'Right',
                maxWidth: 125,
                minWidth: 100,
                clipMode: 'EllipsisWithTooltip',
                autoFit: true,
            },
        ];

        this.feesColumns = [
            {
                field: 'internet_fee',
                headerText: 'Internet',
                format: '#,###.00\',- CZK\'',
                textAlign: 'Right',
                maxWidth: 125,
                minWidth: 100,
                clipMode: 'EllipsisWithTooltip',
                autoFit: true,
            },
            {
                field: 'television_fee',
                headerText: 'Televize',
                format: '#,###.00\',- CZK\'',
                textAlign: 'Right',
                maxWidth: 125,
                minWidth: 100,
                clipMode: 'EllipsisWithTooltip',
                autoFit: true,
            },
            {
                field: 'wifi_fee',
                headerText: 'Wi-Fi',
                format: '#,###.00\',- CZK\'',
                textAlign: 'Right',
                maxWidth: 125,
                minWidth: 100,
                clipMode: 'EllipsisWithTooltip',
                autoFit: true,
            },
            {
                field: 'ext_services_fee',
                headerText: 'Rozšíření',
                format: '#,###.00\',- CZK\'',
                textAlign: 'Right',
                maxWidth: 125,
                minWidth: 100,
                clipMode: 'EllipsisWithTooltip',
                autoFit: true,
            },
            {
                field: 'total_fee',
                headerText: 'Celkem',
                format: '#,###.00\',- CZK\'',
                textAlign: 'Right',
                maxWidth: 125,
                minWidth: 100,
                clipMode: 'EllipsisWithTooltip',
                autoFit: true,
            },
        ];

        this.dataNetbaseService.setLeadsSource();
    }

    onCreated(): void {
        this.disableToolBarBtn();
    }

    onLoad(): void {
        const wrapper1 = document.getElementById('scroll_wrapper1_leads');
        const wrapper2 = document.getElementById('scroll_wrapper2_leads');

        if (wrapper1 && wrapper2) {
            wrapper1.onscroll = (): void => {
                wrapper2.scrollLeft = wrapper1.scrollLeft;
            };

            wrapper2.onscroll = (): void => {
                wrapper1.scrollLeft = wrapper2.scrollLeft;
            };
        }

        this.dataNetbaseService.leadSource.pipe(untilDestroyed(this))
            .subscribe(
                (leads: Leads[] | undefined) => {
                    this.leads = leads;

                    if (this.leads && this.leads.length > 0) {
                        this.dataLeads = this.gridLeads.dataSource = this.loadData();
                        this.sortOptions = {
                            columns: [
                                {field: 'created_date', direction: 'Descending'},
                                {
                                    field: 'id',
                                    direction: 'Descending',
                                },
                            ],
                        };
                    } else {
                        this.loadingLead = false;
                    }
                },
                error => {
                    console.error(error);
                    this.loadingLead = false;
                    this.ref.markForCheck();
                },
            );
    }

    loadData(): DataResult[] {
        this.gridLeads.clearRowSelection();

        console.info('NEW DATA LEADS BOUND');

        let elements: LeadsGridItems[] = [];

        // eslint-disable-next-line complexity
        this.leads?.map((lead: Leads) => {
            let tvsStr = '';

            if (lead.tvs_list.length > 0) {
                lead.tvs_list.map(tv => {
                    tvsStr += (tv.tv as string)
                        ? (tv.tv as string)
                        : `Neznámý model TV - ${
                            tv.smart ? 'SMART TV' : 'SET-TOP Box do pronájmu'
                        }<br/>`;
                });
            } else {
                tvsStr = 'nespecifikováno = vlastní set-top box / SMART TV';
            }

            let extServicesStr = '';

            if (lead.ext_services && lead.ext_services.length > 0) {
                lead.ext_services.map((service: ExtendServices) => {
                    extServicesStr += `${service.name}<br/>`;
                });
            } else {
                extServicesStr = 'bez rozšiřujících služeb';
            }

            let tvPackagesStr = '';

            if (lead.tv_packages && lead.tv_packages.length > 0) {
                lead.tv_packages.map((tvPackage: TvPackages) => {
                    tvPackagesStr += `${tvPackage.name}<br/>`;
                });
            } else {
                tvPackagesStr = 'bez TV balíčků';
            }

            elements.push({
                id: lead.id,
                crm_id: lead.crm_id,
                erp_id: lead.erp_id,
                stmnt_id: lead.stmnt_id,
                type_id: lead.type_id,
                provider_id: lead.provider_id,
                deal_id: lead.deal_id,
                unit_id: lead.unit_id,
                space_id: lead.space_id,
                speed_id: lead.speed_id,
                hw_id: lead.hw_id,
                operator_id: lead.operator_id,
                operator_str: `${lead.operator.secondname} ${lead.operator.firstname}`,
                operator_img: lead.operator.personalphoto,
                status:
                    lead.status === 'wait'
                        ? 'Nový lead'
                        : lead.status === 'open'
                            ? 'Otevřený lead'
                            : lead.status === 'close'
                                ? 'Uzavřený lead'
                                : lead.status === 'archive'
                                    ? 'Archivováno'
                                    : 'test',
                client_status:
                    lead.client_status === 'new_client'
                        ? 'Nový klient'
                        : lead.client_status === 'existing_client'
                            ? 'Stávající klient'
                            : lead.client_status === 'deleted_client'
                                ? 'Smazaný klient'
                                : 'test',
                address: lead.address,
                ruian: lead.ruian,
                field_number: lead.field_number,
                cl_cnumber: lead.cl_cnumber,
                cl_firstname: lead.cl_firstname,
                cl_lastname: lead.cl_lastname,
                cl_fullname:
                    lead.cl_firstname && lead.cl_lastname
                        ? `${lead.cl_lastname} ${lead.cl_firstname}`
                        : '',
                cl_email: lead.cl_email,
                cl_billing_email: lead.cl_billing_email,
                cl_technical_email: lead.cl_technical_email,
                cl_business_email: lead.cl_business_email,
                cl_telephone: lead.cl_telephone,
                cl_mobilephone: lead.cl_mobilephone,
                cl_account_number: lead.cl_account_number,
                cl_bank_number: lead.cl_bank_number,
                cl_address: lead.cl_address,
                cl_billing_address: lead.cl_billing_address,
                cl_vat_id: lead.cl_vat_id,
                cl_national_id_number: lead.cl_national_id_number,
                cl_send_invoices_by_post: lead.cl_send_invoices_by_post ? 'ANO' : 'NE',
                cl_send_invoice_by_email: lead.cl_send_invoice_by_email ? 'ANO' : 'NE',
                invoice_by_year: lead.invoice_by_year ? 'ANO' : 'NE',
                vip_install: lead.vip_install ? 'ANO' : 'NE',
                internal_note: lead.internal_note,
                tvs: lead.tvs,
                tvs_str: tvsStr,
                wicon_own_router: lead.wicon_own_router ? 'ANO' : 'NE',
                wicon_basics: lead.wicon_basics ? 'ANO' : 'NE',
                wicon_garden: lead.wicon_garden ? 'ANO' : 'NE',
                wicon_cabels: lead.wicon_cabels ? 'ANO' : 'NE',
                wicon_roaming: lead.wicon_roaming ? 'ANO' : 'NE',
                created_date: new Date(lead.created_date),
                updated_date: lead.updated_date ? new Date(lead.updated_date) : null,
                deleted_date: lead.deleted_date ? new Date(lead.deleted_date) : null,
                provider: lead.provider,
                operator: lead.operator,
                type: lead.type.name,
                hw_package: lead.hw_package,
                hw_package_str: lead.hw_package ? lead.hw_package.name : 'bez Wi-FI služeb',
                speed_type: lead.speed_type
                    ? `${lead.speed_type.speed_until} - ${lead.speed_type.speed_to}`
                    : '',
                unit_type: lead.unit_type ? lead.unit_type.name : '',
                deal_type: lead.deal_type ? lead.deal_type.age : '',
                flat_space: lead.flat_space
                    ? `${lead.flat_space.metric_until} - ${lead.flat_space.metric_to}`
                    : '',
                net_package: lead.net_package ? lead.net_package.name : 'bez NET služeb',
                tv_service: lead.tv_service ? lead.tv_service.name : 'bez TV služeb',
                tv_packages: tvPackagesStr,
                ext_services: lead.ext_services,
                ext_services_str: extServicesStr,
                total_price: lead.total_price,
                internet_price: lead.internet_price,
                television_price: lead.television_price,
                ext_services_price: lead.ext_services_price,
                wifi_price: lead.wifi_price,
                total_fee: lead.total_fee,
                internet_fee: lead.internet_fee,
                television_fee: lead.television_fee,
                ext_services_fee: lead.ext_services_fee,
                wifi_fee: lead.wifi_fee,
            });
        });

        elements = elements.sort((a, b) => {
            const dateA = moment(a.created_date)
                .valueOf();
            const dateB = moment(b.created_date)
                .valueOf();

            return dateA > dateB ? -1 : 1;
        });
        this.loadingLead = false;
        this.ref.markForCheck();

        return elements as unknown as DataResult[];
    }

    search(): void {
        this.gridLeads.search(this.searchtextObj.value);
    }

    onDataBound(): void {
        this.rowSelected();

        const width = document.getElementById('leadsGrid_content_table')?.offsetWidth;
        const parent1 = document.getElementById('scroll_div_leads');
        const parent2 = document.getElementById('grid_parent_leads');

        if (parent1 && parent2 && width) {
            parent1.style.width = `${width}px`;
            parent2.style.width = `${width}px`;
        }

        this.gridLeads.autoFitColumns();
        this.ref.markForCheck();
    }

    rowDataBound(args: RowDataBoundEventArgs): void {
        const data = args.data as Leads;

        if (args.row && data.deleted_date) {
            args.row.getElementsByClassName('e-gridchkbox')[0].classList.add('disablecheckbox');
            args.row
                .getElementsByClassName('e-checkbox-wrapper')[0]
                .classList.add('disablecheckbox');
            args.row.classList.add('e-disabled');
        }

        if (
            this.currentUser &&
            args.row &&
            data.operator_id !== this.currentUser.id &&
            !this.permissionService.checkUserISServiceAdmin(this.currentUser)
        ) {
            args.row.getElementsByClassName('e-gridchkbox')[0].classList.add('disablecheckbox');
            args.row
                .getElementsByClassName('e-checkbox-wrapper')[0]
                .classList.add('disablecheckbox');
        }

        this.ref.markForCheck();
    }

    onStatusFilterChange(e: ChangeEventArgs): void {
        if (e.value === 'všechny leady') {
            this.gridLeads.clearFiltering();
        } else if (e.value === 'moje leady - vyřízené') {
            this.gridLeads.filterSettings = {
                columns: [
                    {
                        field: 'operator_id',
                        operator: 'equal',
                        value: this.currentUser?.id,
                        predicate: 'and',
                        matchCase: false,
                        ignoreAccent: true,
                    },
                    {
                        field: 'status',
                        operator: 'notequal',
                        value: 'queue',
                        predicate: 'and',
                        matchCase: false,
                        ignoreAccent: true,
                    },
                ],
            };
        } else if (e.value === 'moje leady - fronta') {
            this.gridLeads.filterSettings = {
                columns: [
                    {
                        field: 'created_by',
                        operator: 'equal',
                        value: this.currentUser?.id,
                        predicate: 'and',
                        matchCase: false,
                        ignoreAccent: true,
                    },
                    {
                        field: 'status',
                        operator: 'equal',
                        value: 'queue',
                        predicate: 'and',
                        matchCase: false,
                        ignoreAccent: true,
                    },
                ],
            };
        } else if (e.value === 'leady ke zpracování') {
            this.gridLeads.filterSettings = {
                columns: [
                    {
                        field: 'boss_id',
                        operator: 'equal',
                        value: this.currentUser?.id,
                        predicate: 'and',
                        matchCase: false,
                        ignoreAccent: true,
                    },
                    {
                        field: 'status',
                        operator: 'equal',
                        value: 'queue',
                        predicate: 'and',
                        matchCase: false,
                        ignoreAccent: true,
                    },
                ],
            };
        }
    }

    onFilteringCategories: EmitType<FilteringEventArgs> = (e: FilteringEventArgs) => {
        if (e.text === '') {
            e.updateData(this.categorySelect);
        } else {
            let query: Query = new Query();

            query = e.text !== '' ? query.where('label', 'contains', e.text, true, true) : query;
            e.updateData(this.categorySelect, query);
        }
    };

    cancelBtnClick(): void {
        this.searchtextObj.value = '';
        this.gridLeads.searchSettings.key = '';
    }

    toolbarClick(args: ClickEventArgs): void {
        const xlsProp: ExcelExportProperties = {
            fileName: 'js_leads_export.xlsx',
            enableFilter: true,
            exportType: this.exportCurrentPage ? 'CurrentPage' : 'AllPages',
        };

        if (args.item.id === 'leadsGrid_excelexport') {
            console.info('EXCEL EXPORT');
            void this.gridLeads.excelExport(xlsProp);
        } else if (args.item.id === 'cw_finish') {
            console.info('Decline application');
            this.confirmationFinishCW.show();
        } else if (args.item.id === 'delete') {
            console.info('Delete lead');

            if (this.gridLeads.getSelectedRecords().length !== 0) {
                this.confirmationDelete.content = `Potvrďte smazání ${this.clickedRow.length} leadů`;
                this.confirmationDelete.show();
            } else {
                this.confirmationCancel.show();
            }
        } else if (args.item.id === 'detail') {
            console.info('Deatil lead');
            void this.router.navigate(['/services/leads', this.clickedRow[0].id]);
        } else if (args.item.id === 'customRefresh') {
            console.info('refresh DATA!');
            this.loadingLead = true;
            this.ref.markForCheck();
            this.dataNetbaseService.clearLeadsCache();
            this.dataNetbaseService.setLeadsSource();
        }
    }

    enableToolbarBtn(): void {
        if (this.currentUser && this.permissionService.checkUserISServiceAdmin(this.currentUser)) {
            this.gridLeads.toolbarModule.enableItems(
                ['leadsGrid_edit', 'delete', 'cw_finish', 'detail'],
                true,
            );
        } else {
            this.gridLeads.toolbarModule.enableItems(['detail'], true);
        }
    }

    disableToolBarBtn(): void {
        this.gridLeads.toolbarModule.enableItems(
            ['leadsGrid_edit', 'delete', 'cw_finish', 'detail'],
            false,
        );
    }

    setInitialGridFiltering(): void {
        this.gridLeads.clearFiltering();
        // this.gridLeads.filterByColumn('created_by', 'equal', this.currentUser.id, 'and', false, true);
        // this.viewFilterInstance.value = 'moje žádanky - všechny';
        this.ref.markForCheck();
    }

    rowSelected(): void {
        this.clickedRow = [];

        const selectedrecords: object[] = this.gridLeads.getSelectedRecords();

        if (selectedrecords.length > 0) {
            selectedrecords.map((row: Leads) => {
                const tempData = this.leads?.find(x => x.id === row.id);

                if (tempData) {
                    this.clickedRow.push(tempData);
                }

                this.ref.markForCheck();
            });

            this.clickedRow$ = new Observable<Leads[]>((observer: Observer<Leads[]>) => {
                observer.next(this.clickedRow);
            });
            this.enableToolbarBtn();
        } else {
            this.disableToolBarBtn();
            this.clickedRow$ = new Observable<Leads[]>((observer: Observer<Leads[]>) => {
                observer.next(this.clickedRow);
            });
        }
    }

    actionBegin(args): void {
        if (args.requestType === 'add' || args.requestType === 'beginEdit') {
            args.cancel = true;

            if (args.requestType === 'beginEdit') {
                this.isUpdate = true;
            }

            this.renderLeadFormDialog = true;
            this.ref.markForCheck();
        }
    }

    actionComplete(args): void {
        if (args.requestType === 'beginEdit' || args.requestType === 'add') {
            args.form.ej2_instances[0].rules = {};
        }
    }

    finishLeadCW(): void {
        this.confirmationFinishCW.hide();

        const data = this.leads?.find(lead => lead.id === this.clickedRow[0].id);

        if (data) {
            this.servicesService.setLead(data);
            void this.router.navigate(['/services/searcher'], {
                queryParams: {view: 'callwizard'},
            });
        }
    }

    deleteLead(): void {
        for (const row of this.clickedRow) {
            if (
                this.currentUser &&
                !row.deleted_date &&
                this.permissionService.checkUserISServiceAdmin(this.currentUser)
            ) {
                this.loadingLead = true;
                row.deleted_by = this.currentUser.id;
                void new Promise((resolve, reject) => {
                    this.servicesService
                        .deleteLead(row)
                        ?.pipe(untilDestroyed(this))
                        .subscribe(
                            (lead: Leads) => {
                                const body = `Lead #${lead.id}`;
                                const options = {
                                    progressBar: true,
                                    timeOut: 5000,
                                    toastClass: 'success',
                                };

                                this.messages.showSuccess('Lead úspěšně smazán', body, options);
                                resolve(true);
                            },
                            error => {
                                console.error(error);

                                const body = 'Zkuste to později ...';
                                const options = {
                                    progressBar: true,
                                    timeOut: 5000,
                                    toastClass: 'red',
                                };

                                this.messages.showError('Chyba během mazání leadu', body, options);
                                this.loadingLead = false;
                                this.ref.markForCheck();
                                reject();
                            },
                        );
                });
            } else {
                const body = 'Nemáte oprávnění provést tuto akci...';
                const options = {progressBar: true, timeOut: 5000};

                this.messages.showWarning('Nedostatečné oprávnění', body, options);
                this.loadingLead = false;
                this.ref.markForCheck();
            }
        }

        this.dataNetbaseService.setLeadsSource();
        this.gridLeads.clearRowSelection();
        this.confirmationDelete.hide();
    }
}
