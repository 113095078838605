<ejs-dialog
  #formDialog
  (close)="
        isUpdate = false;
        isCreate = false;
        isDirty = false;
        isChecked = false;
        isCopy = false;
        submited = false;
        changeDialogState(false)
    "
  [isModal]="true"
  [showCloseIcon]="true"
  [visible]="isVisible"
  [width]="'85%'"
  id="formDialog"
>
  <form [formGroup]="projectForm">
    <ng-template #content>
      <div class="dialogContent">
        <div class="row">
          <div class="col-12 mb-2">
            <label
              [ngClass]="
                                f.name.valid && (f.name.dirty || f.name.touched)
                                    ? 'text-success'
                                    : f.name.invalid &&
                                      (f.name.dirty || f.name.touched)
                                    ? 'text-danger'
                                    : 'text-muted'
                            "
              for="projectForm-name"
            >* Název projektu:</label
            >
            <ejs-textbox
              [ngClass]="
                                f.name.touched && f.name.invalid
                                    ? 'e-error'
                                    : f.name.touched && f.name.valid
                                    ? 'e-success'
                                    : ''
                            "
              floatLabelType="Auto"
              formControlName="name"
              id="projectForm-name"
            ></ejs-textbox>
            <small
              *ngIf="
                                f.name.touched &&
                                f.name.errors &&
                                f.name.errors.required
                            "
              class="text-danger"
            >Název projektu je povinný</small
            >
          </div>
          <div class="col-12 mb-2 form-group">
            <label
              [ngClass]="
                                f.description.valid
                                    ? 'text-success'
                                    : f.description.invalid &&
                                      (f.description.dirty ||
                                          f.description.touched)
                                    ? 'text-danger'
                                    : 'text-muted'
                            "
              for="projectForm-description"
            >Popis:</label
            >
            <ejs-textbox
              [multiline]="true"
              [ngClass]="{
                                'e-error': submited && f.description.errors
                            }"
              floatLabelType="Auto"
              formControlName="description"
              id="projectForm-description"
            ></ejs-textbox>
            <small
              *ngIf="
                                (f.description.dirty ||
                                    f.description.touched) &&
                                f.description.errors &&
                                f.description.errors.required
                            "
              class="text-danger"
            >Vyplňte popis projektu</small
            >
          </div>
          <div class="col-12 mb-2">
            <label
              [ngClass]="
                                f.budget.valid &&
                                (f.budget.dirty || f.budget.touched)
                                    ? 'text-success'
                                    : f.budget.invalid &&
                                      (f.budget.dirty || f.budget.touched)
                                    ? 'text-danger'
                                    : 'text-muted'
                            "
              for="projectForm-budget"
            >* Rozpočet projektu:</label
            >
            <ejs-numerictextbox
              [format]="'#,###.00\',- CZK\''"
              [ngClass]="
                                f.budget.touched && f.budget.invalid
                                    ? 'e-error'
                                    : f.budget.touched && f.budget.valid
                                    ? 'e-success'
                                    : ''
                            "
              formControlName="budget"
              id="projectForm-budget"
              step="0.01"
            ></ejs-numerictextbox>
            <small
              *ngIf="
                                f.budget.touched &&
                                f.budget.errors &&
                                f.budget.errors.required
                            "
              class="text-danger"
            >Rozpočet projektu je povinný</small
            >
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <label
              [ngClass]="
                                f.end_at.valid &&
                                (f.end_at.dirty || f.end_at.touched) &&
                                f.start_at.valid &&
                                (f.start_at.dirty || f.start_at.touched)
                                    ? 'text-success'
                                    : (f.end_at.invalid &&
                                          (f.end_at.dirty ||
                                              f.end_at.touched)) ||
                                      (f.start_at.invalid &&
                                          (f.start_at.dirty ||
                                              f.start_at.touched))
                                    ? 'text-danger'
                                    : 'text-muted'
                            "
            >* Projektové období:
            </label>
          </div>
          <div class="col-12 col-md-6">
            <label for="projectForm-start_at">OD:</label>
            <ejs-datetimepicker
              [enabled]="true"
              [firstDayOfWeek]="1"
              [ngClass]="
                                f.start_at.touched && f.start_at.invalid
                                    ? 'e-error'
                                    : f.start_at.touched && f.start_at.valid
                                    ? 'e-success'
                                    : ''
                            "
              floatLabelType="Always"
              formControlName="start_at"
              id="projectForm-start_at"
            >
            </ejs-datetimepicker>
            <div class="d-flex justify-content-end">
              <small
                *ngIf="
                                    f.start_at.touched &&
                                    f.start_at.errors &&
                                    f.start_at.errors.required
                                "
                class="text-danger"
              >Datum začátku projektu je povinné</small
              >
            </div>
          </div>
          <div class="col-12 col-md-6">
            <label for="projectForm-end_at">DO:</label>
            <ejs-datetimepicker
              [enabled]="true"
              [firstDayOfWeek]="1"
              [ngClass]="
                                f.end_at.touched && f.end_at.invalid
                                    ? 'e-error'
                                    : f.end_at.touched && f.end_at.valid
                                    ? 'e-success'
                                    : ''
                            "
              floatLabelType="Always"
              formControlName="end_at"
              id="projectForm-end_at"
              min="{{ f.end_at.value ? f.end_at.value : '' }}"
            >
            </ejs-datetimepicker>
            <div class="d-flex justify-content-end">
              <small
                *ngIf="
                                    f.end_at.touched &&
                                    f.end_at.errors &&
                                    f.end_at.errors.required
                                "
                class="text-danger"
              >Datum konce projektu je povinné</small
              >
            </div>
          </div>
        </div>
      </div>
    </ng-template>
    <ng-template #footerTemplate>
      <div class="d-flex justify-content-end">
                <span
                  *ngIf="projectForm.invalid"
                  class="e-badge e-badge-warning p-2 size_2"
                  role="alert"
                >
                    <strong>Formulář je špatně vyplněný</strong>
                </span>
        <span
          *ngIf="projectForm.valid"
          class="e-badge e-badge-success p-2 size_2"
          role="alert"
        >
                    <strong>Formulář je správně vyplněný</strong>
                </span>
      </div>
      <div class="row">
        <div class="col-12 pt-2">
          <button
            (click)="onSubmit()"
            [disabled]="
                            loadingProject || projectForm.invalid || !isChecked
                        "
            cssClass="e-btn e-success btn-p-zero e-small mr-1"
            ejs-button
            type="submit"
          >
                        <span
                          *ngIf="loadingProject === true"
                          aria-hidden="true"
                          class="spinner-grow spinner-grow-sm"
                          role="status"
                        ></span>
            <span *ngIf="loadingProject === false">ULOŽIT</span>
            <span *ngIf="loadingProject === true">Provádím...</span>
          </button>
          <button
            (click)="formDialogObj.hide()"
            cssClass="e-btn e-danger e-outline btn-p-zero e-small"
            ejs-button
            type="button"
          >
            ZRUŠIT
          </button>
        </div>
      </div>
    </ng-template>
  </form>
</ejs-dialog>
