import {Pipe, PipeTransform} from '@angular/core';

import moment from 'moment';
import localeCs from '@angular/common/locales/cs';
import {registerLocaleData} from '@angular/common';

moment.updateLocale('cs', {workingWeekdays: [1, 2, 3, 4, 5]});
registerLocaleData(localeCs);

@Pipe({
    name: 'dateFormat',
})
export class MomentPipe implements PipeTransform {
    transform(value: string, dateFormat: string): string | null {
        return value && value.length > 0 ? moment(value)
            .format(dateFormat) : null;
    }
}
