import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule, Routes} from '@angular/router';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {AuthGuard} from '@src/app/_guards/auth.guard';
import {SharedFeaturesModule} from '@src/app/shared_features/shared-features.module';
import {DealEditComponent} from './_forms/deal-edit/deal-edit.component';
import {DealsComponent} from './components/deals/deals.component';
import {DealDetailComponent} from './components/deal-detail/deal-detail.component';
import {DealQueueComponent} from './components/deal-queue/deal-queue.component';

const routes: Routes = [
    {
        path: 'deals',
        children: [
            {
                path: '',
                component: DealsComponent,
                data: {
                    title: 'Smlouvy nástěnka',
                    breadcrumb: [
                        {
                            label: 'Smlouvy',
                            url: '',
                        },
                    ],
                },
            },
            {
                path: 'queue',
                component: DealQueueComponent,
                data: {
                    title: 'Fronta smluv',
                    breadcrumb: [
                        {
                            label: 'Smlouvy',
                            url: '',
                        },
                        {
                            label: 'Fronta smluv',
                            url: '',
                        },
                    ],
                },
            },
            {
                path: 'detail/:id',
                component: DealDetailComponent,
                data: {
                    title: 'Detail smlouvy',
                    breadcrumb: [
                        {
                            label: 'Smlouvy',
                            url: '',
                        },
                        {
                            label: 'Fronta smluv',
                            url: '/deals/queue',
                        },
                        {
                            label: 'Detail smlouvy',
                            url: '',
                        },
                    ],
                },
            },
        ],
        canActivate: [AuthGuard],
    },
];

@NgModule({
    declarations: [DealDetailComponent, DealEditComponent, DealQueueComponent, DealsComponent],
    imports: [
        BrowserAnimationsModule,
        RouterModule.forChild(routes),
        BrowserModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        SharedFeaturesModule,
    ],
})
export class DealsModule {}
