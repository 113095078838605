import {ChangeDetectionStrategy, Component} from '@angular/core';

import moment from 'moment';
import {registerLocaleData} from '@angular/common';
import localeCs from '@angular/common/locales/cs';
import {AuthenticationService} from '@src/app/_services/authentication.service';
import {User} from '@src/app/_models/user/user';

moment.updateLocale('cs', {workingWeekdays: [1, 2, 3, 4, 5]});
registerLocaleData(localeCs);

@Component({
    selector: 'app-mailer',
    templateUrl: './mailer.component.html',
    styleUrls: ['./mailer.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MailerComponent {
    currentUser: User | null;

    constructor(private readonly authenticationService: AuthenticationService) {
        this.currentUser = this.authenticationService.currentUserValue;
    }
}
