export class BaseModel {
    success: boolean;

    code: number;

    count: number;

    errors: string[];

    payload: {body: never; query: never};

    data: never[];

    options: never;
}
