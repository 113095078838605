<ejs-toolbar
  #moduleToolbar
  id="moduleToolbar"
  overflowMode="Scrollable"
  scrollStep="300"
>
  <e-items>
    <e-item [align]="'center'" tooltipText="vytvořit úkol" type="button">
      <ng-template #template>
        <button
          (click)="navigateToTab('grid', 'addTask')"
          class="e-btn e-primary btn-p-zero e-small"
          ejs-button
          title="vytvořit úkol"
          type="button"
        >
          <i class="fa-light fa-lg fa-plus mr-2"></i>vytvořit úkol
        </button>
      </ng-template>
    </e-item>
    <e-item type="Separator"></e-item>
    <e-item [align]="'center'" tooltipText="seznam úkolů" type="button">
      <ng-template #template>
        <button
          (click)="navigateToTab('grid', null)"
          class="e-btn e-warning btn-p-zero e-small"
          ejs-button
          title="seznam úkolů"
          type="button"
        >
          <i class="fa-light fa-lg fa-list mr-2"></i>seznam úkolů
        </button>
      </ng-template>
    </e-item>
  </e-items>
</ejs-toolbar>
<ejs-tab
  #taskTab
  (created)="onCreate()"
  (selected)="select($event)"
  (selecting)="preventSwipe($event)"
  enablePersistence="true"
  height="auto"
  heightAdjustMode="Content"
  id="taskTab"
>
  <e-tabitems>
    <e-tabitem [header]="headerText[0]">
      <ng-template #content>
        <app-task-queue></app-task-queue>
      </ng-template>
    </e-tabitem>
    <e-tabitem [disabled]="true" [header]="headerText[1]">
      <ng-template #content>
        <app-task-drag-and-drop></app-task-drag-and-drop>
      </ng-template>
    </e-tabitem>
    <e-tabitem [disabled]="true" [header]="headerText[2]">
      <ng-template #content>
        <app-task-calendar></app-task-calendar>
      </ng-template>
    </e-tabitem>
    <e-tabitem [header]="headerText[3]">
      <ng-template #content>
        <div class="control-section">
          <div *ngIf="stats" class="card bg-transparent">
            <div class="e-card-header d-flex  p-3">
              <i
                aria-hidden="true"
                class="fa-light rounded fa-ticket bg-primary fa-2x p-2 text-white mr-2"
              ></i>
              <h2>Úkoly</h2>
            </div>
            <div class="e-card-content text-center p-0 m-0">
              <div *ngIf="loadingTask" class="row">
                <div class="col-12 text-center">
                  <app-loader></app-loader>
                </div>
              </div>
              <div
                *ngIf="!loadingTask"
                class="table-responsive p-2"
                style="max-height: 400px; overflow-y: auto"
              >
                <table class="table mb-1">
                  <tbody>
                  <tr>
                    <th class="text-left">
                      celkem:
                      {{
                        stats.solved.indue +
                        stats.solved.overdue +
                        (stats.deleted.indue +
                          stats.deleted
                            .overdue) +
                        (stats.queue.indue +
                          stats.queue.overdue)
                      }}
                      úkolů
                    </th>
                    <th class="text-right">
                      fronta:
                      {{
                        stats.queue.indue +
                        stats.queue.overdue
                      }}
                      úkolů
                    </th>
                  </tr>
                  <tr>
                    <td colspan="2">
                      <div
                        class="col-12 mt-1 text-center"
                      >
                        <ejs-progressbar
                          #tasksPercentage
                          (valueChanged)="
                                                            changedBar($event)
                                                        "
                          [animation]="animation"
                          [labelStyle]="
                                                            labelStyle
                                                        "
                          [showProgressValue]="
                                                            showProgressValue
                                                        "
                          [value]="
                                                            ((stats.solved
                                                                .indue +
                                                                stats.solved
                                                                    .overdue +
                                                                (stats.deleted
                                                                    .indue +
                                                                    stats
                                                                        .deleted
                                                                        .overdue)) *
                                                                100) /
                                                            (stats.solved
                                                                .indue +
                                                                stats.solved
                                                                    .overdue +
                                                                (stats.deleted
                                                                    .indue +
                                                                    stats
                                                                        .deleted
                                                                        .overdue) +
                                                                (stats.queue
                                                                    .indue +
                                                                    stats.queue
                                                                        .overdue))
                                                        "
                          height="50"
                          id="tasksPercentage"
                          maximum="100"
                          minimum="0"
                          progressThickness="15"
                          trackThickness="15"
                          type="Linear"
                          width="100%"
                        ></ejs-progressbar>
                        <strong
                          class="text-muted"
                        >zbývá:&nbsp;{{
                            stats.solved.indue +
                            stats.solved
                              .overdue +
                            (stats.deleted
                                .indue +
                              stats
                                .deleted
                                .overdue) +
                            (stats.queue
                                .indue +
                              stats.queue
                                .overdue) -
                            (stats.solved
                                .indue +
                              stats.solved
                                .overdue +
                              (stats
                                  .deleted
                                  .indue +
                                stats
                                  .deleted
                                  .overdue))
                          }}
                          úkolů</strong
                        >
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="text-left">Vyřešeno:</td>
                    <td class="text-right">
                      {{
                        stats.solved.indue +
                        stats.solved.overdue
                      }}
                      úkolů
                    </td>
                  </tr>
                  <tr>
                    <td class="text-left">Smazáno:</td>
                    <td class="text-right">
                      {{
                        stats.deleted.indue +
                        stats.deleted.overdue
                      }}
                      úkolů
                    </td>
                  </tr>
                  <tr>
                    <td
                      class="text-left"
                    >Ve frontě:
                    </td>
                    <td class="text-right">
                      {{
                        stats.queue.indue +
                        stats.queue.overdue
                      }}
                      úkolů
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div
            class="mb-3"
            id="dashBoardParent"
            style="width: 100% !important"
          >
            <ejs-dashboardlayout
              #default_dashboard
              [allowDragging]="true"
              [allowResizing]="true"
              [cellAspectRatio]="cellAspectRatio"
              [cellSpacing]="cellSpacing"
              [enablePersistence]="false"
              columns="4"
              id="vacReports_dashboard"
            >
              <e-panels>
                <e-panel
                  [col]="0"
                  [row]="0"
                  [sizeX]="4"
                  [sizeY]="1"
                >
                  <ng-template #content>
                    <i
                      (click)="onCloseIconHandler($event)"
                      aria-hidden="true"
                      class="fa-light fa-lg fa-time"
                    ></i>
                    <div
                      class="e-panel-container scrollbar scrollbar-indigo thin p-2"
                    >
                      <div
                        *ngIf="loadingTask"
                        class="col-12"
                      >
                        <div class="col-12 text-center">
                          <small
                            class="text-muted"
                          >načítám data</small
                          >
                        </div>
                      </div>
                      <div
                        *ngIf="!loadingTask"
                        style="display: block"
                      >
                        <ejs-chart
                          #taskChart
                          [legendSettings]="
                                                        legendSettings
                                                    "
                          [margin]="{
                                                        left: 5,
                                                        right: 5,
                                                        top: 5,
                                                        bottom: 5
                                                    }"
                          [palettes]="palette"
                          [primaryXAxis]="
                                                        primaryXAxis
                                                    "
                          [primaryYAxis]="
                                                        primaryYAxis
                                                    "
                          [tooltip]="tooltip"
                          id="taskChart"
                        >
                          <e-series-collection>
                            <e-series
                              [animation]="
                                                                chartAnimation
                                                            "
                              [dataSource]="
                                                                chartDataInDue
                                                            "
                              name="V termínu"
                              type="Column"
                              xName="state"
                              yName="tasks"
                            ></e-series>
                            <e-series
                              [animation]="
                                                                chartAnimation
                                                            "
                              [dataSource]="
                                                                chartDataOverDue
                                                            "
                              name="Po termínu"
                              type="Column"
                              xName="state"
                              yName="tasks"
                            ></e-series>
                          </e-series-collection>
                        </ejs-chart>
                      </div>
                    </div>
                  </ng-template>
                </e-panel>
                <e-panel
                  [col]="0"
                  [row]="1"
                  [sizeX]="2"
                  [sizeY]="1"
                >
                  <ng-template #content>
                    <i
                      (click)="onCloseIconHandler($event)"
                      aria-hidden="true"
                      class="fa-light fa-lg fa-time"
                    ></i>
                    <div
                      class="e-panel-container scrollbar scrollbar-indigo thin p-2"
                    >
                      <div class="control-section">
                        <ejs-textbox
                          (keyup)="
                                                        onFilterCategories(
                                                            $event
                                                        )
                                                    "
                          id="filterCategoriesList"
                          placeholder="Filtrovat kategorie..."
                          title="Napiš název"
                        ></ejs-textbox>
                        <ejs-listview
                          #listviewCategories
                          [dataSource]="
                                                        dataCategories
                                                    "
                          [fields]="fields"
                          [headerTitle]="
                                                        headerTitleCategories
                                                    "
                          [showHeader]="true"
                          [showIcon]="true"
                          id="listviewCategories"
                        >
                          <ng-template
                            #template
                            let-dataCategories=""
                          >
                            <div
                              class="d-flex justify-content-between"
                            >
                              <div
                                class="e-list-wrapper e-list-multi-line"
                              >
                                <i
                                  class="{{
                                                                        dataCategories.icon
                                                                    }} align-middle mr-2"
                                ></i>
                                <strong
                                  class="e-list-item-header mr-2"
                                >{{
                                    dataCategories.text
                                  }}</strong
                                >
                                <small
                                  *ngIf="
                                                                        !dataCategories.task_id
                                                                    "
                                  class="timeStamp text-muted"
                                >{{
                                    dataCategories.child
                                      ? dataCategories
                                        .child
                                        .length
                                      :0
                                  }}
                                  úkolů</small
                                >
                              </div>
                              <span>
                                                                <i
                                                                  *ngIf="
                                                                        !dataCategories.task_id
                                                                    "
                                                                  class="fa-light fa-lg fa-chevron-right align-middle"
                                                                ></i>
                                                                <a
                                                                  *ngIf="
                                                                        dataCategories.task_id
                                                                    "
                                                                  [routerLink]="[
                                                                        '/tasks/detail/',
                                                                        dataCategories.task_id
                                                                    ]"
                                                                ><i
                                                                  class="fa-light fa-lg fa-search"
                                                                ></i
                                                                ></a>
                                                            </span>
                            </div>
                          </ng-template>
                        </ejs-listview>
                      </div>
                    </div>
                  </ng-template>
                </e-panel>
                <e-panel
                  [col]="2"
                  [row]="1"
                  [sizeX]="2"
                  [sizeY]="1"
                >
                  <ng-template #content>
                    <i
                      (click)="onCloseIconHandler($event)"
                      aria-hidden="true"
                      class="fa-light fa-lg fa-time"
                    ></i>
                    <div
                      class="e-panel-container scrollbar scrollbar-indigo thin p-2"
                    >
                      <div class="control-section">
                        <ejs-textbox
                          (keyup)="
                                                        onFilterTags($event)
                                                    "
                          id="filterTagsList"
                          placeholder="Filtrovat štítky..."
                          title="Napiš název"
                        ></ejs-textbox>
                        <ejs-listview
                          #listviewTags
                          [dataSource]="dataTags"
                          [fields]="fields"
                          [headerTitle]="
                                                        headerTitleTags
                                                    "
                          [showHeader]="true"
                          [showIcon]="true"
                          id="listviewTags"
                        >
                          <ng-template
                            #template
                            let-dataTags=""
                          >
                            <div
                              class="d-flex justify-content-between"
                            >
                              <div
                                class="e-list-wrapper e-list-multi-line"
                              >
                                <i
                                  class="{{
                                                                        dataTags.icon
                                                                    }} align-middle mr-2"
                                ></i>
                                <strong
                                  class="e-list-item-header mr-2"
                                >{{
                                    dataTags.text
                                  }}</strong
                                >
                                <small
                                  *ngIf="
                                                                        !dataTags.task_id
                                                                    "
                                  class="timeStamp text-muted"
                                >{{
                                    dataTags.child
                                      ? dataTags
                                        .child
                                        .length
                                      :0
                                  }}
                                  úkolů</small
                                >
                              </div>
                              <span>
                                                                <i
                                                                  *ngIf="
                                                                        !dataTags.task_id
                                                                    "
                                                                  class="fa-light fa-lg fa-chevron-right align-middle"
                                                                ></i>
                                                                <a
                                                                  *ngIf="
                                                                        dataTags.task_id
                                                                    "
                                                                  [routerLink]="[
                                                                        '/tasks/detail/',
                                                                        dataTags.task_id
                                                                    ]"
                                                                ><i
                                                                  class="fa-light fa-lg fa-search"
                                                                ></i
                                                                ></a>
                                                            </span>
                            </div>
                          </ng-template>
                        </ejs-listview>
                      </div>
                    </div>
                  </ng-template>
                </e-panel>
              </e-panels>
            </ejs-dashboardlayout>
          </div>
        </div>
      </ng-template>
    </e-tabitem>
    <e-tabitem [disabled]="true" [header]="headerText[4]">
      <ng-template #content>
        <app-task-import></app-task-import>
      </ng-template>
    </e-tabitem>
  </e-tabitems>
</ejs-tab>
