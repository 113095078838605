import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnInit,
    ViewChild,
} from '@angular/core';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {Subject} from 'rxjs';
import {FieldSettingsModel} from '@syncfusion/ej2-lists';
import {User} from '@src/app/_models/user/user';
import {TabComponent, ToolbarComponent} from '@syncfusion/ej2-angular-navigations';
import {DashboardLayoutComponent} from '@syncfusion/ej2-angular-layouts';
import {ListViewComponent} from '@syncfusion/ej2-angular-lists';
import {ChartComponent} from '@syncfusion/ej2-angular-charts';
import {AuthenticationService} from '@src/app/_services/authentication.service';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {NavHandlerService} from '@src/app/_services/nav-handler.service';
import {DataService} from '@src/app/_services/data.service';
import {MessageService} from '@src/app/_services/message.service';
import {TimeagoIntl} from 'ngx-timeago';
import {strings as czechStrings} from 'ngx-timeago/language-strings/cs';
import moment from 'moment/moment';
import {debounceTime} from 'rxjs/operators';
import {DataManager, Query} from '@syncfusion/ej2-data';
import {IAccTooltipRenderEventArgs} from '@syncfusion/ej2-charts';
import {SelectEventArgs} from '@syncfusion/ej2-navigations';
import {Car} from '@src/app/_models/cars/car.model';
import {SettingsService} from '@src/app/features/settings/settings.service';

@UntilDestroy()
@Component({
    selector: 'app-cars',
    templateUrl: './cars.component.html',
    styleUrls: ['./cars.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CarsComponent implements OnInit {
    // Filters
    private readonly subjectUsersFilter: Subject<string> = new Subject();

    private readonly subjectCategoriesFilter: Subject<string> = new Subject();

    // DashBoard
    count = 8;

    cellSpacing: number[] = [10, 10];

    cellAspectRatio: number = 100 / 100;

    // Navigations
    headerText = [{text: 'nástěnka'}, {text: 'tabulka'}];

    // Lists
    dataCategories: {
        [key: string]: Array<{[key: string]: number | string}> | number | string;
    }[] = [];

    dataUsers: {[key: string]: {[key: string]: number | string}[] | number | string}[] = [];

    headerTitleCategories = 'Kategorie automobilů';

    headerTitleTags = 'Uživatelé automobilů';

    fields: FieldSettingsModel = {
        iconCss: 'icon',
        tooltip: 'text',
        sortBy: 'text',
    };

    // Variables
    currentUser: User | null;

    cars: Car[] | undefined = [];

    bookableCarsCount = 0;

    // Charts
    palette = ['#E94649', '#F6B53F', '#6FAAB0', '#C4C24A'];

    chartAnimation = {enable: true};

    primaryXAxis: {[key: string]: boolean | number | string | undefined};

    primaryYAxis: {[key: string]: boolean | number | string | undefined};

    chartDataGasoline: object;

    chartDataDiesel: object;

    chartDataGas: object;

    chartDataElectricity: object;

    legendSettings: object;

    tooltip: object;

    datalabel: object;

    // Loaders
    loadingCar = true;

    // Maintoolbar
    @ViewChild('moduleToolbar') moduleToolbarObj?: ToolbarComponent;

    // DashBoard
    @ViewChild('defaultDashboard', {static: false})
    dashboard?: DashboardLayoutComponent;

    // Navigations
    @ViewChild('carsTab') carsTabObj: TabComponent;

    // Lists
    @ViewChild('listviewUsers') listviewUsersObj: ListViewComponent;

    @ViewChild('listviewCategories') listviewCategoriesObj: ListViewComponent;

    // Charts
    @ViewChild('carsChart') carsChartObj?: ChartComponent;

    constructor(
        private readonly authenticationService: AuthenticationService,
        private readonly router: Router,
        private readonly ref: ChangeDetectorRef,
        private readonly navHandlerService: NavHandlerService,
        private readonly route: ActivatedRoute,
        private readonly dataService: DataService,
        private readonly settingsService: SettingsService,
        private readonly messages: MessageService,
        private readonly intl: TimeagoIntl,
    ) {
        this.currentUser = this.authenticationService.currentUserValue;
        this.intl.strings = czechStrings;
        this.intl.changes.next();
    }

    private loadData(): void {
        let chartDiesel = 0;
        let chartGas = 0;
        let chartElectricity = 0;
        let chartGasoline = 0;

        this.cars = this.cars?.sort((a, b) => {
            const dateA = moment(a.created_date)
                .valueOf();
            const dateB = moment(b.created_date)
                .valueOf();

            return dateA > dateB ? -1 : 1;
        });

        const childGasoline: {[key: string]: number | string}[] = [];
        const childDiesel: {[key: string]: number | string}[] = [];
        const childGas: {[key: string]: number | string}[] = [];
        const childElectricity: {[key: string]: number | string}[] = [];

        this.cars?.forEach((car: Car) => {
            if (car.gasoline) {
                chartGasoline++;
                childGasoline.push({
                    id: `1-${car.id}`,
                    text: car.name,
                    icon: 'fa-light fa-lg fa-cars',
                    car_id: car.id,
                });
            }

            if (car.diesel) {
                chartDiesel++;
                childDiesel.push({
                    id: `2-${car.id}`,
                    text: car.name,
                    icon: 'fa-light fa-lg fa-cars',
                    car_id: car.id,
                });
            }

            if (car.electricity) {
                chartElectricity++;
                childElectricity.push({
                    id: `3-${car.id}`,
                    text: car.name,
                    icon: 'fa-light fa-lg fa-cars',
                    car_id: car.id,
                });
            }

            if (car.lpg) {
                chartGas++;
                childGas.push({
                    id: `4-${car.id}`,
                    text: car.name,
                    icon: 'fa-light fa-lg fa-cars',
                    car_id: car.id,
                });
            }

            if (car.bookable) {
                this.bookableCarsCount++;
            }
        });

        this.dataCategories = [
            {
                id: 1,
                text: 'benzín',
                icon: 'fa-light fa-lg fa-th-list',
                child: childGasoline,
            },
            {
                id: 2,
                text: 'diesel',
                icon: 'fa-light fa-lg fa-th-list',
                child: childDiesel,
            },
            {
                id: 3,
                text: 'eleketrický pohon',
                icon: 'fa-light fa-lg fa-th-list',
                child: childElectricity,
            },
            {
                id: 4,
                text: 'plyn',
                icon: 'fa-light fa-lg fa-th-list',
                child: childGas,
            },
        ];

        this.chartDataDiesel = [
            {
                state: 'Diesel',
                cars: chartDiesel,
                text: `Benzínový pohon: ${chartDiesel}`,
            },
        ];

        this.chartDataElectricity = [
            {
                state: 'Elektrický pohon',
                cars: chartElectricity,
                text: `Elektrický pohon: ${chartElectricity}`,
            },
        ];

        this.chartDataGas = [
            {
                state: 'Plyn',
                cars: chartGas,
                text: `LPG pohon: ${chartGas}`,
            },
        ];

        this.chartDataGasoline = [
            {
                state: 'Benzín',
                cars: chartGasoline,
                text: `Dieselový pohon: ${chartGasoline}`,
            },
        ];

        this.loadingCar = false;
        this.ref.markForCheck();
    }

    ngOnInit(): void {
        this.primaryXAxis = {
            valueType: 'Category',
            edgeLabelPlacement: 'Shift',
            isInversed: true,
        };

        this.primaryYAxis = {
            minimum: 0,
            maximum: 200,
            labelFormat: '{value} automobilů',
            rangePadding: 'None',
        };

        this.legendSettings = {
            visible: true,
        };

        this.tooltip = {
            enable: true,
            header: 'Automobilů celkem',
        };
        this.datalabel = {visible: true};

        this.dataService.carsSource.pipe(untilDestroyed(this))
            .subscribe(
                (cars: Car[] | undefined) => {
                    this.cars = cars;
                    this.primaryYAxis.maximum = this.cars?.length;

                    if (this.cars && this.cars.length > 0) {
                        this.loadData();
                    }
                },
                error => {
                    console.error(error);
                },
            );

        this.navHandlerService.navState.pipe(untilDestroyed(this))
            .subscribe(
                () => {
                    this.refreshContent();
                },
                error => {
                    console.error(error);
                },
            );

        this.subjectUsersFilter.pipe(debounceTime(500))
            .subscribe(searchTextValue => {
                const data: {[key: string]: object}[] | DataManager | number[] | object[] | string[] =
                    new DataManager(this.dataUsers).executeLocal(
                        new Query().where('text', 'contains', searchTextValue, true, true),
                    );

                if (!searchTextValue) {
                    this.listviewUsersObj.dataSource = this.dataUsers.slice();
                } else {
                    (this.listviewUsersObj.dataSource as object[]) = data;
                }

                this.listviewUsersObj.dataBind();
            });

        this.subjectCategoriesFilter.pipe(debounceTime(500))
            .subscribe(searchTextValue => {
                const data: {[key: string]: object}[] | DataManager | number[] | object[] | string[] =
                    new DataManager(this.dataCategories).executeLocal(
                        new Query().where('text', 'contains', searchTextValue, true, true),
                    );

                if (!searchTextValue) {
                    this.listviewCategoriesObj.dataSource = this.dataCategories.slice();
                } else {
                    (this.listviewCategoriesObj.dataSource as object[]) = data;
                }

                this.listviewCategoriesObj.dataBind();
            });
    }

    onFilterUsers(event: KeyboardEvent): void {
        const target: EventTarget | null = event.target;

        this.subjectUsersFilter.next((target as HTMLInputElement).value);
    }

    onFilterCategories(event): void {
        const target: EventTarget | null = event.target;

        this.subjectCategoriesFilter.next((target as HTMLInputElement).value);
    }

    onCreate(): void {
        // Nastavení GUI podle URL parametrů
        this.route.queryParamMap.pipe(untilDestroyed(this))
            .subscribe(queryParams => {
                if (queryParams.get('view') === 'dashboard') {
                    this.carsTabObj.select(0);
                } else if (queryParams.get('view') === 'grid') {
                    this.carsTabObj.select(1);
                } else {
                    this.carsTabObj.select(0);
                }
            });
    }

    refreshContent(): void {
        setTimeout(() => {
            if (this.dashboard) {
                this.dashboard.refresh();
            }

            if (this.carsChartObj) {
                this.carsChartObj.refresh();
            }

            if (this.moduleToolbarObj) {
                this.moduleToolbarObj.refresh();
            }
        }, 1000);
    }

    tooltipRender(args: IAccTooltipRenderEventArgs): void {
        const value = args.point.y / args.series.sumOfPoints * 100;

        args.text = `${args.point.x as string} : ${Math.ceil(value)} %`;
    }

    onCloseIconHandler(event: MouseEvent): void {
        if (event.target === null) {
            return;
        }

        const elementOffset = (event.target as HTMLElement).offsetParent;

        if (elementOffset) {
            this.dashboard?.removePanel(elementOffset.id);
        }
    }

    preventSwipe(e: SelectEventArgs): void {
        if (e.isSwiped) {
            e.cancel = true;
        }
    }

    navigateToTab(view: string, form: string): void {
        const queryParams = {view, form};

        if (view === 'dashboard') {
            this.carsTabObj.select(0);
        } else if (view === 'grid') {
            this.carsTabObj.select(1);
        } else {
            this.carsTabObj.select(0);
        }

        void this.router.navigate([], {
            relativeTo: this.route,
            queryParams,
            queryParamsHandling: 'merge',
        });
    }

    select(e: SelectEventArgs): void {
        let queryParams: Params = {view: null};

        this.route.queryParamMap.pipe(untilDestroyed(this))
            .subscribe(params => {
                const form = params.get('form') === 'addCar' ? 'addCar' : null;

                if (e.selectedItem.textContent === 'nástěnka') {
                    queryParams = {
                        view: 'dashboard',
                        form,
                    };
                }

                if (e.selectedItem.textContent === 'tabulka') {
                    queryParams = {
                        view: 'grid',
                        form,
                    };
                }

                if (e.selectedItem.textContent === 'to-do') {
                    queryParams = {
                        view: 'todo',
                        form,
                    };
                }

                if (e.selectedItem.textContent === 'plánování') {
                    queryParams = {
                        view: 'sheduler',
                        form,
                    };
                }

                if (e.selectedItem.textContent === 'import') {
                    queryParams = {
                        view: 'import',
                        form,
                    };
                }

                void this.router.navigate([], {
                    relativeTo: this.route,
                    queryParams,
                    queryParamsHandling: 'merge',
                });
            });
    }
}
