import {
    AfterViewChecked,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnInit,
    ViewChild,
} from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {Observable, Observer} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthenticationService} from '@src/app/_services/authentication.service';
import {ExcelService} from '@src/app/_services/excel.service';
import {DataService} from '@src/app/_services/data.service';
import {DataNetbaseService} from '@src/app/_services/data-netbase.service';
import {ServicesService, TechnologyInput} from '@src/app/features/services/services.service';
import {PermissionService} from '@src/app/_services/permission.service';
import {MessageService} from '@src/app/_services/message.service';
import {TechnologyTypes} from '@src/app/_models/services/technology-types';
import {ButtonPropsModel, DialogComponent} from '@syncfusion/ej2-angular-popups';
import {User} from '@src/app/_models/user/user';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {
    ColumnModel,
    DataResult,
    EditSettingsModel,
    ExcelExportProperties,
    FilterSettingsModel,
    GridComponent,
    GroupSettingsModel,
    PageSettingsModel,
    RowDataBoundEventArgs,
    SelectionSettingsModel,
    ToolbarItems,
} from '@syncfusion/ej2-angular-grids';
import {TextBoxComponent} from '@syncfusion/ej2-angular-inputs';
import {ChangeEventArgs, DropDownListComponent} from '@syncfusion/ej2-angular-dropdowns';
import {ClickEventArgs} from '@syncfusion/ej2-angular-navigations';

@UntilDestroy()
@Component({
    selector: 'app-services-technology-types',
    templateUrl: './services-technology-types.component.html',
    styleUrls: ['./services-technology-types.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ServicesTechnologyTypesComponent implements OnInit, AfterViewChecked {
    // Grid
    currencyFormat: {format: string} = {format: '#,###.00\',- CZK\''};

    dateFormat: string;

    toolbar: ToolbarItems[] | object;

    data: object;

    fields: object = {text: 'label', value: 'value'};

    filterSettings: FilterSettingsModel;

    filterCheckboxSettings: FilterSettingsModel;

    selectionSettings: SelectionSettingsModel;

    editSettings: EditSettingsModel;

    groupOptions: GroupSettingsModel;

    pageOptions: PageSettingsModel;

    expendituresColumnsPrice: ColumnModel[];

    expendituresColumnsItems: ColumnModel[];

    filterDate: object;

    sortOptions: object;

    requiredRules: object;

    exportCurrentPage = false;

    // Dialogs
    renderTechnologyTypeFormDialog = false;

    buttonsDelete: ButtonPropsModel[] = [
        {
            click: (): void => {
                this.deleteTechnologyType();
            },
            isFlat: false,
            buttonModel: {
                content: 'OK',
                cssClass: 'e-success e-outline',
            },
        },
        {
            click: (): void => {
                this.confirmationDelete.hide();
            },
            buttonModel: {
                content: 'ZRUŠIT',
                cssClass: 'e-danger e-outline',
            },
        },
    ];

    buttonsCancel: ButtonPropsModel[] = [
        {
            click: (): void => {
                this.confirmationCancel.hide();
            },
            buttonModel: {
                isPrimary: true,
                content: 'OK',
            },
        },
    ];

    // Dropdowns
    height = '240px';

    categoryFilterData: Array<string> = [];

    // Forms
    isCreate = false;

    isUpdate = false;

    isCopy = false;

    // Variables
    currentUser: User | null;

    technologies: TechnologyTypes[] | undefined = [];

    clickedRow: TechnologyTypes[] = [];

    clickedRow$ = new Observable<TechnologyTypes[]>((observer: Observer<TechnologyTypes[]>) => {
        observer.next(this.clickedRow);
    });

    // Loaders
    tableLoad = true;

    // Grid
    @ViewChild('technologyTypesGrid') gridTechnologyTypes: GridComponent;

    @ViewChild('searchtext') searchtextObj: TextBoxComponent;

    // Dialogs
    @ViewChild('confirmationDelete') confirmationDelete: DialogComponent;

    @ViewChild('confirmationCancel') confirmationCancel: DialogComponent;

    // Dropdowns
    @ViewChild('viewFilter') viewFilterInstance: DropDownListComponent;

    constructor(
        private readonly router: Router,
        private readonly route: ActivatedRoute,
        private readonly ref: ChangeDetectorRef,
        private readonly formBuilder: FormBuilder,
        private readonly authenticationService: AuthenticationService,
        private readonly excelService: ExcelService,
        private readonly dataService: DataService,
        private readonly dataNetbaseService: DataNetbaseService,
        private readonly servicesService: ServicesService,
        private readonly permissionService: PermissionService,
        private readonly messages: MessageService,
    ) {
        this.currentUser = this.authenticationService.currentUserValue;
    }

    ngOnInit(): void {
        this.filterSettings = {
            type: 'Menu',
            showFilterBarStatus: true,
            ignoreAccent: true,
        };

        this.filterCheckboxSettings = {
            type: 'CheckBox',
            showFilterBarStatus: true,
            ignoreAccent: true,
        };

        this.filterSettings = {
            type: 'Excel',
            showFilterBarOperator: true,
            showFilterBarStatus: true,
            ignoreAccent: true,
        };

        this.selectionSettings = {
            persistSelection: true,
            type: 'Multiple',
            checkboxOnly: true,
        };

        this.toolbar = [
            {
                text: 'Občerstvit data',
                tooltipText: 'Občerstvit data',
                prefixIcon: 'e-refresh',
                id: 'customRefresh',
            },
            'Print',
            'ExcelExport',
            'Add',
            'Edit',
            {
                text: 'Smazat',
                tooltipText: 'Smazat typ technologie',
                prefixIcon: 'e-remove',
                id: 'delete',
            },
            {
                text: 'Kopírovat',
                tooltipText: 'Kopírovat typ technologie',
                prefixIcon: 'e-copy',
                id: 'copy',
            },
        ];

        this.categoryFilterData = [
            'všechny typy technologie',
            'aktivní typy technologie',
            'neaktivní typy technologie',
        ];
        this.pageOptions = {pageSize: 50};
        this.dateFormat = 'dd.MM.yyyy HH:mm';
        this.filterDate = {type: 'Menu', params: {format: this.dateFormat}};
        this.groupOptions = {
            showGroupedColumn: true,
            disablePageWiseAggregates: false,
        };

        this.editSettings = {
            showConfirmDialog: false,
            showDeleteConfirmDialog: false,
            allowEditing: true,
            allowAdding: true,
            allowDeleting: true,
            allowEditOnDblClick: false,
            mode: 'Dialog',
        };
        this.requiredRules = {required: true};

        this.dataNetbaseService.setTechnologyTypesDataSource();
    }

    ngAfterViewChecked(): void {
        this.route.queryParamMap.pipe(untilDestroyed(this))
            .subscribe(params => {
                if (params.get('form') === 'addTechnologyType') {
                    this.isCreate = true;
                    this.renderTechnologyTypeFormDialog = true;
                    this.ref.markForCheck();
                }
            });
    }

    loadData(): DataResult[] {
        this.gridTechnologyTypes.clearRowSelection();

        console.info('NEW DATA BOUND');

        const elements: TechnologyInput[] = [];

        if (this.technologies && this.technologies.length > 0) {
            this.technologies.map((technology: TechnologyTypes) => {
                elements.push({
                    id: technology.id,
                    name: technology.name,
                    created_by: technology.creator.id,
                    creator_str: technology.creator.fullname,
                    creator_img: technology.creator.personalphoto,
                    created_date: new Date(technology.created_date),
                    updated_date: technology.updated_date
                        ? new Date(technology.updated_date)
                        : null,
                    deleted_date: technology.deleted_date
                        ? new Date(technology.deleted_date)
                        : null,
                });
            });
        }

        this.tableLoad = false;
        this.ref.markForCheck();

        return elements as unknown as DataResult[];
    }

    onCreated(): void {
        this.disableToolBarBtn();
    }

    setInitialGridFiltering(): void {
        this.gridTechnologyTypes.clearFiltering();
        // this.gridBonuses.filterByColumn('created_by', 'equal', this.currentUser.id, 'and', false, true);
        // this.viewFilterInstance.value = 'moje žádanky - všechny';
        this.ref.markForCheck();
    }

    onLoad(): void {
        const wrapper1 = document.getElementById('scroll_wrapper1_technologies');
        const wrapper2 = document.getElementById('scroll_wrapper2_technologies');

        if (wrapper1 && wrapper2) {
            wrapper1.onscroll = (): void => {
                wrapper2.scrollLeft = wrapper1.scrollLeft;
            };

            wrapper2.onscroll = (): void => {
                wrapper1.scrollLeft = wrapper2.scrollLeft;
            };
        }

        this.dataNetbaseService.technologyTypesSource.pipe(untilDestroyed(this))
            .subscribe(
                (technologies: TechnologyTypes[] | undefined) => {
                    this.technologies = technologies;

                    if (this.technologies && this.technologies.length > 0) {
                        this.data = this.gridTechnologyTypes.dataSource = this.loadData();
                        this.sortOptions = {
                            columns: [
                                {field: 'created_date', direction: 'Descending'},
                                {field: 'id', direction: 'Descending'},
                            ],
                        };
                    }
                },
                error => {
                    console.error(error);
                    this.tableLoad = false;
                    this.ref.markForCheck();
                },
            );
    }

    search(): void {
        this.gridTechnologyTypes.search(this.searchtextObj.value);
    }

    onDataBound(): void {
        this.rowSelected();

        const width = document.getElementById('technologyTypesGrid_content_table')?.offsetWidth;
        const parent1 = document.getElementById('scroll_div_technologies');
        const parent2 = document.getElementById('grid_parent_technologies');

        if (parent1 && parent2 && width) {
            parent1.style.width = `${width}px`;
            parent2.style.width = `${width}px`;
        }

        this.gridTechnologyTypes.autoFitColumns();
        this.ref.markForCheck();
    }

    rowDataBound(args: RowDataBoundEventArgs): void {
        const data = args.data as TechnologyTypes;

        if (args.row && data.deleted_date) {
            args.row.getElementsByClassName('e-gridchkbox')[0].classList.add('disablecheckbox');
            args.row
                .getElementsByClassName('e-checkbox-wrapper')[0]
                .classList.add('disablecheckbox');
            args.row.classList.add('e-disabled');
        }

        if (
            this.currentUser &&
            args.row &&
            data.created_by !== this.currentUser.id &&
            !this.permissionService.checkUserISServiceAdmin(this.currentUser) &&
            !this.permissionService.checkUserISAdministrative(this.currentUser) &&
            !this.permissionService.checkUserISMarketingAdmin(this.currentUser)
        ) {
            args.row.getElementsByClassName('e-gridchkbox')[0].classList.add('disablecheckbox');
            args.row
                .getElementsByClassName('e-checkbox-wrapper')[0]
                .classList.add('disablecheckbox');
        }

        this.ref.markForCheck();
    }

    onStatusFilterChange(e: ChangeEventArgs): void {
        if (e.value === 'všechny typy technologie') {
            this.gridTechnologyTypes.clearFiltering();
        } else if (e.value === 'aktivní typy technologie') {
            this.gridTechnologyTypes.clearFiltering();
            this.gridTechnologyTypes.filterByColumn(
                'deleted_date',
                'equal',
                null,
                'and',
                false,
                true,
            );
        } else if (e.value === 'neaktivní typy technologie') {
            this.gridTechnologyTypes.clearFiltering();
            this.gridTechnologyTypes.filterByColumn(
                'deleted_date',
                'notequal',
                null,
                'and',
                false,
                true,
            );
        }
    }

    cancelBtnClick(): void {
        this.searchtextObj.value = '';
        this.gridTechnologyTypes.searchSettings.key = '';
    }

    toolbarClick(args: ClickEventArgs): void {
        const xlsProp: ExcelExportProperties = {
            fileName: 'js_technologyTypes_export.xlsx',
            enableFilter: true,
            exportType: this.exportCurrentPage ? 'CurrentPage' : 'AllPages',
        };

        if (args.item.id === 'technologyTypesGrid_excelexport') {
            void this.gridTechnologyTypes.excelExport(xlsProp);
        } else if (args.item.id === 'delete') {
            if (this.gridTechnologyTypes.getSelectedRecords().length !== 0) {
                this.confirmationDelete.content = `Potvrďte smazání ${this.clickedRow.length} typů technologie`;
                this.confirmationDelete.show();
            } else {
                this.confirmationCancel.content = 'Musíte vybrat alespoň jeden typ technologie';
                this.confirmationCancel.show();
            }
        } else if (args.item.id === 'copy') {
            if (this.gridTechnologyTypes.getSelectedRecords().length !== 0) {
                this.isUpdate = false;
                this.isCreate = false;
                this.isCopy = true;
                this.renderTechnologyTypeFormDialog = true;
                this.ref.markForCheck();
            } else {
                this.confirmationCancel.content = 'Musíte vybrat alespoň jeden typ technologie';
                this.confirmationCancel.show();
            }
        } else if (args.item.id === 'customRefresh') {
            this.tableLoad = true;
            console.info('refresh DATA!');
            this.ref.markForCheck();
            this.dataNetbaseService.clearTechnologyTypesCache();
            this.dataNetbaseService.setTechnologyTypesDataSource();
        }
    }

    enableToolbarBtn(): void {
        this.gridTechnologyTypes.toolbarModule.enableItems(
            ['technologyTypesGrid_edit', 'delete', 'copy'],
            true,
        );
    }

    disableToolBarBtn(): void {
        this.gridTechnologyTypes.toolbarModule.enableItems(
            ['technologyTypesGrid_edit', 'delete', 'copy'],
            false,
        );
    }

    rowSelected(): void {
        this.clickedRow = [];

        const selectedrecords: TechnologyTypes[] =
            this.gridTechnologyTypes.getSelectedRecords() as TechnologyTypes[];

        if (selectedrecords.length > 0) {
            selectedrecords.map((row: TechnologyTypes) => {
                const tempData: TechnologyTypes | undefined = this.technologies?.find(
                    x => x.id === row.id,
                );

                if (tempData) {
                    this.clickedRow.push(tempData);
                }

                this.ref.markForCheck();
            });

            this.clickedRow$ = new Observable<TechnologyTypes[]>(
                (observer: Observer<TechnologyTypes[]>) => {
                    observer.next(this.clickedRow);
                },
            );
            this.enableToolbarBtn();
        } else {
            this.disableToolBarBtn();
            this.clickedRow$ = new Observable<TechnologyTypes[]>(
                (observer: Observer<TechnologyTypes[]>) => {
                    observer.next(this.clickedRow);
                },
            );
        }
    }

    actionBegin(args): void {
        if (args.requestType === 'add' || args.requestType === 'beginEdit') {
            args.cancel = true;

            if (args.requestType === 'beginEdit') {
                this.isUpdate = true;
                this.isCreate = false;
                this.isCopy = false;
            }

            if (args.requestType === 'add') {
                this.isCreate = true;
                this.isUpdate = false;
                this.isCopy = false;
            }

            this.renderTechnologyTypeFormDialog = true;
            this.ref.markForCheck();
        }
    }

    actionComplete(args): void {
        if (args.requestType === 'beginEdit' || args.requestType === 'add') {
            args.form.ej2_instances[0].rules = {};
        }
    }

    deleteTechnologyType(): void {
        if (
            this.currentUser &&
            (this.permissionService.checkUserISServiceAdmin(this.currentUser) ||
                this.permissionService.checkUserISAdministrative(this.currentUser) ||
                this.permissionService.checkUserISMarketingAdmin(this.currentUser))
        ) {
            this.tableLoad = true;
            this.clickedRow[0].deleted_by = this.currentUser.id;
            this.servicesService
                .deleteTechnologyType(this.clickedRow[0])
                ?.pipe(untilDestroyed(this))
                .subscribe(
                    (type: TechnologyTypes) => {
                        const body = `Typ technologie #${type.id}`;
                        const options = {progressBar: true, timeOut: 5000};

                        this.messages.showSuccess('Typ technologie úspěšně smazán', body, options);
                        this.dataNetbaseService.setTechnologyTypesDataSource();
                        this.confirmationDelete.hide();
                        this.ref.markForCheck();
                    },
                    error => {
                        console.error(error);

                        const body = 'Zkuste to později ...';
                        const options = {progressBar: true, timeOut: 5000};

                        this.messages.showError(
                            'Chyba během mazání typu technologie',
                            body,
                            options,
                        );
                        this.tableLoad = false;
                        this.confirmationDelete.hide();
                        this.ref.markForCheck();
                    },
                );
        } else {
            const body = 'Nemáte oprávnění provést tuto akci...';
            const options = {progressBar: true, timeOut: 5000};

            this.messages.showError('Nedostatečné oprávnění', body, options);
            this.ref.markForCheck();
        }
    }
}
