<div *ngIf="task?.taskTime" class="col-12 mt-1">
  <ejs-progressbar
    #taskTimePercentage
    (valueChanged)="changedBar($event)"
    [animation]="animation"
    [labelStyle]="labelStyle"
    [showProgressValue]="showProgressValue"
    [value]="task.taskTime.percentage"
    height="50"
    id="taskTimePercentage"
    maximum="100"
    minimum="0"
    progressThickness="15"
    trackThickness="15"
    type="Linear"
    width="100%"
  ></ejs-progressbar>
</div>
