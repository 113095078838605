<div class="row d-flex justify-content-center modalWrapper">
  <ejs-dialog
    #confirmationDeleteApi
    [buttons]="buttonsDeleteApi"
    [content]="'Opravdu chcete smazat api?'"
    [header]="'Smazat api'"
    [isModal]="true"
    [showCloseIcon]="true"
    [visible]="false"
    width="250px"
  ></ejs-dialog>
  <ejs-dialog
    #confirmationDeactivateApi
    [buttons]="buttonsDeactivateApi"
    [content]="'Opravdu chcete deaktivovat api?'"
    [header]="'Deaktivovat api'"
    [isModal]="true"
    [showCloseIcon]="true"
    [visible]="false"
    width="250px"
  ></ejs-dialog>
  <ejs-dialog
    #confirmationActivateApi
    [buttons]="buttonsActivateApi"
    [content]="'Opravdu chcete aktivovat api?'"
    [header]="'Aktivovat api'"
    [isModal]="true"
    [showCloseIcon]="true"
    [visible]="false"
    width="250px"
  ></ejs-dialog>
  <ejs-dialog
    #confirmationDeletePath
    [buttons]="buttonsDeleteApiPath"
    [content]="'Opravdu chcete smazat cestu api?'"
    [header]="'Smazat cestu api'"
    [isModal]="true"
    [showCloseIcon]="true"
    [visible]="false"
    width="250px"
  ></ejs-dialog>
  <ejs-dialog
    #confirmationDeleteHeader
    [buttons]="buttonsDeleteApiHeader"
    [content]="'Opravdu chcete smazat hlavičku api?'"
    [header]="'Smazat hlavičku api'"
    [isModal]="true"
    [showCloseIcon]="true"
    [visible]="false"
    width="250px"
  ></ejs-dialog>
  <ejs-dialog
    #confirmationCancel
    [buttons]="buttonsCancel"
    [header]="'Nelze provést akci'"
    [isModal]="true"
    [showCloseIcon]="true"
    [visible]="false"
    width="250px"
  ></ejs-dialog>
</div>
<div class="row">
  <div class="col-12 col-md-8 col-lg-9">
    <ejs-tab
      #apisDetailTab
      (created)="onCreate()"
      (selected)="select($event)"
      (selecting)="preventSwipe($event)"
      enablePersistence="true"
      height="auto"
      heightAdjustMode="Content"
      id="apisDetailTab"
    >
      <e-tabitems>
        <e-tabitem [header]="headerText[0]">
          <ng-template #content>
            <div
              aria-expanded="true"
              class="tab-pane fade active show p-0 m-0"
              id="taskMain"
              role="tabpanel"
            >
              <ng-container *ngIf="api">
                <div class="row mt-2">
                  <div
                    class="col-12 col-md-4 text-center text-md-left"
                  >
                    <h4
                      [className]="
                                                api.active && api.state
                                                    ? 'h4-responsive m-0 p-0 text-success'
                                                    : !api.active
                                                    ? 'h4-responsive m-0 p-0 text-danger'
                                                    : api.deleted_date
                                                    ? 'h4-responsive m-0 p-0 text-danger'
                                                    : 'h4-responsive m-0 p-0 text-warning'
                                            "
                      class="h4-responsive m-0 p-0"
                    >
                      API #{{ api.id }}: {{
                        api.title
                      }}<br />
                      <span
                        *ngIf="
                                                    api.active &&
                                                    api.state &&
                                                    !api.deleted_date
                                                "
                        class="e-badge e-badge-success ml-1"
                      >
                                                online<br /><small>{{
                          api.last_check
                            | dateFormat
                            : 'DD.MM.YYYY HH:mm'
                        }}</small>
                                            </span>
                      <span
                        *ngIf="
                                                    api.active &&
                                                    !api.state &&
                                                    !api.deleted_date
                                                "
                        class="e-badge e-badge-danger ml-1"
                      >
                                                offline<br /><small>{{
                          api.last_check
                            | dateFormat
                            : 'DD.MM.YYYY HH:mm'
                        }}</small>
                                            </span>
                      <span
                        *ngIf="
                                                    !api.active &&
                                                    !api.deleted_date
                                                "
                        class="e-badge e-badge-warning ml-1"
                      >deaktivováno</span
                      >
                      <span
                        *ngIf="api.deleted_date"
                        class="e-badge e-badge-danger ml-1"
                      >
                                                smazáno (dne:
                        {{
                          api.deleted_date
                            | dateFormat
                            : 'DD.MM.YYYY HH:mm'
                        }})
                                            </span>
                    </h4>
                  </div>
                  <div
                    class="col-12 col-md-8 text-center text-md-right"
                  >
                    <div
                      aria-label="Vacation toolbar"
                      class="e-btn-group"
                      role="group"
                    >
                      <button
                        (click)="
                                                    confirmationActivateApi.show()
                                                "
                        *ngIf="
                                                    api &&
                                                    currentUser.rule &&
                                                    currentUser.rule.is_admin &&
                                                    !api.deleted_date &&
                                                    !api.active
                                                "
                        cssClass="e-btn e-success btn-p-zero e-small"
                        ejs-button
                        type="button"
                      >
                        <i
                          aria-hidden="true"
                          class="fa-light fa-check mr-1"
                        ></i>
                        <span
                          class="d-none d-lg-inline"
                        >aktivovat</span
                        >
                      </button>
                      <button
                        (click)="
                                                    confirmationDeactivateApi.show()
                                                "
                        *ngIf="
                                                    api &&
                                                    currentUser.rule &&
                                                    currentUser.rule.is_admin &&
                                                    !api.deleted_date &&
                                                    api.active
                                                "
                        cssClass="e-btn e-danger btn-p-zero e-small"
                        ejs-button
                      >
                        <i
                          aria-hidden="true"
                          class="fa-light fa-times mr-lg-1"
                        ></i>
                        <span
                          class="d-none d-lg-inline"
                        >deaktivovat</span
                        >
                      </button>
                      <button
                        (click)="editConnection()"
                        *ngIf="
                                                    api &&
                                                    ((api.created_by ===
                                                        currentUser.id &&
                                                        api.active) ||
                                                        (currentUser.rule &&
                                                            currentUser.rule
                                                                .is_admin)) &&
                                                    !api.deleted_date
                                                "
                        cssClass="e-btn e-warning btn-p-zero e-small"
                        ejs-button
                      >
                        <i
                          aria-hidden="true"
                          class="fa-light fa-pencil mr-lg-1"
                        ></i>
                        <span
                          class="d-none d-lg-inline"
                        >upravit</span
                        >
                      </button>
                      <button
                        (click)="
                                                    confirmationDeleteApi.show()
                                                "
                        *ngIf="
                                                    api &&
                                                    ((api.created_by ===
                                                        currentUser.id &&
                                                        api.active) ||
                                                        (currentUser.rule &&
                                                            currentUser.rule
                                                                .is_admin)) &&
                                                    !api.deleted_date
                                                "
                        cssClass="e-btn e-danger btn-p-zero e-small"
                        ejs-button
                      >
                        <i
                          aria-hidden="true"
                          class="fa-light fa-trash mr-lg-1"
                        ></i>
                        <span
                          class="d-none d-lg-inline"
                        >smazat</span
                        >
                      </button>
                    </div>
                  </div>
                </div>
                <div class="row mt-3 mb-2 p-2">
                  <div
                    class="col-12"
                    style="overflow-x: auto"
                  >
                    <table
                      class="col-12 table-sm-responsive"
                    >
                      <tr class="border-bottom">
                        <th>Název:</th>
                        <td class="text-right">{{
                            api.title
                          }}
                        </td>
                      </tr>
                      <tr class="border-bottom">
                        <th>Vytvořeno:</th>
                        <td class="text-right">
                          {{
                            api.created_date
                              | dateFormat
                              : 'DD.MM.YYYY HH:mm'
                          }}
                          <span
                            *ngIf="unixCreatedTime"
                            class="text-muted"
                          >(
                            {{
                              unixCreatedTime
                                | timeago: live
                            }}
                            )</span
                          >
                        </td>
                      </tr>
                      <tr class="border-bottom">
                        <th>Typ spojeni:</th>
                        <td class="text-right">
                                                    <span
                                                      *ngIf="api.type.name"
                                                    >{{
                                                        api.type.name
                                                      }}</span
                                                    >
                        </td>
                      </tr>
                      <tr class="border-bottom">
                        <th>Port:</th>
                        <td class="text-right">
                          {{ api.port }}
                        </td>
                      </tr>
                      <tr class="border-bottom">
                        <th>Protocol:</th>
                        <td class="text-right">
                          {{ api.protocol.name }}
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
              </ng-container>
              <div class="row">
                <div class="col-12 mt-2 mb-4">
                  <div class="e-card">
                    <div
                      class="e-card-header bg-warning"
                    >
                      <h6 class="text-white">
                        CESTY ({{ api?.paths.length }}):
                      </h6>
                    </div>
                    <div class="e-card-content">
                      <div
                        class="scrollbar scrollbar-sunny-morning thin"
                        style="
                                                    max-height: 350px;
                                                    overflow-y: auto;
                                                    overflow-x: hidden;
                                                "
                      >
                        <div class="force-overflow">
                          <div
                            class="col-md-12 mx-auto my-2"
                          >
                            <ejs-toolbar
                              id="filterPathToolbar"
                              overflowMode="Scrollable"
                              scrollStep="500"
                            >
                              <e-items>
                                <e-item
                                  [cssClass]="
                                                                        'statusFilter'
                                                                    "
                                  tooltipText="Přednastavené hledání"
                                  type="input"
                                >
                                  <ng-template
                                    #template
                                  >
                                    <ejs-dropdownlist
                                      #viewFilterPath
                                      (change)="
                                                                                onStatusFilterChangePath(
                                                                                    $event
                                                                                )
                                                                            "
                                      [dataSource]="
                                                                                categoryFilterPathData
                                                                            "
                                      [width]="
                                                                                500
                                                                            "
                                      placeholder="Vyberte přednastavený pohled..."
                                    ></ejs-dropdownlist>
                                  </ng-template>
                                </e-item>
                                <e-item
                                  type="Separator"
                                ></e-item>
                                <e-item
                                  [cssClass]="
                                                                        'fulltextFilter'
                                                                    "
                                  tooltipText="Text pro vyhledávání"
                                  type="input"
                                >
                                  <ng-template
                                    #template
                                  >
                                    <ejs-textbox
                                      #searchtextPath
                                      [width]="
                                                                                500
                                                                            "
                                      class="mr-2"
                                      placeholder="Vyhledat..."
                                    ></ejs-textbox>
                                    <button
                                      (click)="
                                                                                searchPath()
                                                                            "
                                      class="e-btn e-info btn-p-zero e-small"
                                    >
                                      <i
                                        class="fa-light fa-lg fa-search mr-1"
                                      ></i
                                      ><span
                                      class="d-none d-lg-inline"
                                    >vyhledat</span
                                    >
                                    </button>
                                    <button
                                      (click)="
                                                                                cancelBtnPathClick()
                                                                            "
                                      class="e-btn e-danger btn-p-zero e-small"
                                    >
                                      <i
                                        class="fa-light fa-lg fa-times"
                                      ></i>
                                    </button>
                                  </ng-template>
                                </e-item>
                              </e-items>
                            </ejs-toolbar>
                            <div
                              class="control-section"
                            >
                              <div
                                class="scroll_wrapper1"
                                id="scroll_wrapper1_path"
                              >
                                <div
                                  class="scroll_div"
                                ></div>
                              </div>
                              <div
                                class="scroll_wrapper2"
                                id="scroll_wrapper2_path"
                              >
                                <div
                                  class="grid_parent"
                                >
                                  <ejs-grid
                                    #pathsGrid
                                    (actionBegin)="
                                                                            actionBeginPath(
                                                                                $event
                                                                            )
                                                                        "
                                    (actionComplete)="
                                                                            actionComplete(
                                                                                $event
                                                                            )
                                                                        "
                                    (created)="
                                                                            onCreatedPath()
                                                                        "
                                    (dataBound)="
                                                                            onDataBoundPath()
                                                                        "
                                    (load)="
                                                                            onLoad()
                                                                        "
                                    (loadeddata)="
                                                                            setInitialGridFiltering()
                                                                        "
                                    (rowDataBound)="
                                                                            rowDataBoundPath(
                                                                                $event
                                                                            )
                                                                        "
                                    (rowDeselected)="
                                                                            rowSelectedPath()
                                                                        "
                                    (rowSelected)="
                                                                            rowSelectedPath()
                                                                        "
                                    (toolbarClick)="
                                                                            toolbarClickPath(
                                                                                $event
                                                                            )
                                                                        "
                                    [allowExcelExport]="
                                                                            true
                                                                        "
                                    [allowFiltering]="
                                                                            true
                                                                        "
                                    [allowMultiSorting]="
                                                                            true
                                                                        "
                                    [allowPdfExport]="
                                                                            false
                                                                        "
                                    [allowSelection]="
                                                                            true
                                                                        "
                                    [allowSorting]="
                                                                            true
                                                                        "
                                    [dataSource]="
                                                                            dataPath
                                                                        "
                                    [editSettings]="
                                                                            editSettings
                                                                        "
                                    [enableHover]="
                                                                            false
                                                                        "
                                    [enablePersistence]="
                                                                            false
                                                                        "
                                    [enableVirtualization]="
                                                                            true
                                                                        "
                                    [filterSettings]="
                                                                            filterSettings
                                                                        "
                                    [groupSettings]="
                                                                            groupOptions
                                                                        "
                                    [pageSettings]="
                                                                            pageOptions
                                                                        "
                                    [selectionSettings]="
                                                                            selectionSettings
                                                                        "
                                    [sortSettings]="
                                                                            sortOptionsPath
                                                                        "
                                    [toolbar]="
                                                                            toolbarPath
                                                                        "
                                    allowGrouping="true"
                                    allowReordering="true"
                                    allowResizing="true"
                                    allowTextWrap="true"
                                    height="100%"
                                    id="pathsGrid"
                                    locale="cs"
                                    rowHeight="38"
                                    showColumnMenu="true"
                                    width="100%"
                                  >
                                    <e-columns>
                                      <e-column
                                        [allowEditing]="
                                                                                    false
                                                                                "
                                        [allowFiltering]="
                                                                                    false
                                                                                "
                                        [allowReordering]="
                                                                                    false
                                                                                "
                                        [allowResizing]="
                                                                                    false
                                                                                "
                                        [allowSorting]="
                                                                                    false
                                                                                "
                                        [autoFit]="
                                                                                    true
                                                                                "
                                        type="checkbox"
                                      ></e-column>
                                      <e-column
                                        [allowFiltering]="
                                                                                    false
                                                                                "
                                        [allowReordering]="
                                                                                    true
                                                                                "
                                        [allowResizing]="
                                                                                    false
                                                                                "
                                        [autoFit]="
                                                                                    true
                                                                                "
                                        [isPrimaryKey]="
                                                                                    true
                                                                                "
                                        [textAlign]="
                                                                                    'left'
                                                                                "
                                        [visible]="
                                                                                    true
                                                                                "
                                        field="id"
                                        headerText="ID"
                                      >
                                        <ng-template
                                          #template
                                          let-data
                                        >
                                          <a
                                            routerLink="/settings/apis/{{
                                                                                            data.id
                                                                                        }}"
                                          >{{
                                              data.id
                                            }}</a
                                          >
                                        </ng-template>
                                      </e-column>
                                      <e-column
                                        [allowEditing]="
                                                                                    false
                                                                                "
                                        [allowFiltering]="
                                                                                    true
                                                                                "
                                        [allowReordering]="
                                                                                    false
                                                                                "
                                        [allowResizing]="
                                                                                    false
                                                                                "
                                        [autoFit]="
                                                                                    true
                                                                                "
                                        [textAlign]="
                                                                                    'left'
                                                                                "
                                        [visible]="
                                                                                    false
                                                                                "
                                        field="created_by"
                                        headerText="Creator ID"
                                      ></e-column>
                                      <e-column
                                        [autoFit]="
                                                                                    true
                                                                                "
                                        [filter]="
                                                                                    filterSettings
                                                                                "
                                        [textAlign]="
                                                                                    'left'
                                                                                "
                                        clipMode="EllipsisWithTooltip"
                                        field="title"
                                        headerText="Název"
                                      >
                                      </e-column>
                                      <e-column
                                        [autoFit]="
                                                                                    true
                                                                                "
                                        [filter]="
                                                                                    filterSettings
                                                                                "
                                        [textAlign]="
                                                                                    'left'
                                                                                "
                                        clipMode="EllipsisWithTooltip"
                                        field="host_url"
                                        headerText="Host"
                                      >
                                      </e-column>
                                      <e-column
                                        [autoFit]="
                                                                                    true
                                                                                "
                                        [filter]="
                                                                                    filterSettings
                                                                                "
                                        [format]="
                                                                                    'N'
                                                                                "
                                        [textAlign]="
                                                                                    'right'
                                                                                "
                                        clipMode="EllipsisWithTooltip"
                                        field="port"
                                        headerText="Port"
                                      >
                                      </e-column>
                                      <e-column
                                        [autoFit]="
                                                                                    true
                                                                                "
                                        [filter]="
                                                                                    filterSettings
                                                                                "
                                        [textAlign]="
                                                                                    'left'
                                                                                "
                                        clipMode="EllipsisWithTooltip"
                                        field="state"
                                        headerText="Stav"
                                      >
                                        <ng-template
                                          #template
                                          let-data
                                        >
                                                                                    <span
                                                                                      *ngIf="
                                                                                            data.state
                                                                                        "
                                                                                      class="e-badge e-badge-pill e-badge-success"
                                                                                    >online</span
                                                                                    >
                                          <span
                                            *ngIf="
                                                                                            !data.state
                                                                                        "
                                            class="e-badge e-badge-pill e-badge-danger"
                                          >offline</span
                                          >
                                        </ng-template>
                                      </e-column>
                                      <e-column
                                        [autoFit]="
                                                                                    true
                                                                                "
                                        [filter]="
                                                                                    filterSettings
                                                                                "
                                        [textAlign]="
                                                                                    'left'
                                                                                "
                                        clipMode="EllipsisWithTooltip"
                                        field="active"
                                        headerText="Aktivní"
                                      >
                                        <ng-template
                                          #template
                                          let-data
                                        >
                                                                                    <span
                                                                                      *ngIf="
                                                                                            data.active
                                                                                        "
                                                                                      class="e-badge e-badge-pill e-badge-success"
                                                                                    >aktivní</span
                                                                                    >
                                          <span
                                            *ngIf="
                                                                                            !data.active
                                                                                        "
                                            class="e-badge e-badge-pill e-badge-danger"
                                          >neaktivní</span
                                          >
                                        </ng-template>
                                      </e-column>
                                      <e-column
                                        [autoFit]="
                                                                                    true
                                                                                "
                                        [filter]="
                                                                                    filterSettings
                                                                                "
                                        [textAlign]="
                                                                                    'left'
                                                                                "
                                        clipMode="EllipsisWithTooltip"
                                        field="type"
                                        headerText="Typ"
                                      >
                                      </e-column>
                                      <e-column
                                        [autoFit]="
                                                                                    true
                                                                                "
                                        [filter]="
                                                                                    filterSettings
                                                                                "
                                        [textAlign]="
                                                                                    'left'
                                                                                "
                                        clipMode="EllipsisWithTooltip"
                                        field="protocol"
                                        headerText="Protokol"
                                      >
                                      </e-column>
                                      <e-column
                                        [autoFit]="
                                                                                    true
                                                                                "
                                        [filter]="
                                                                                    filterSettings
                                                                                "
                                        [textAlign]="
                                                                                    'left'
                                                                                "
                                        clipMode="EllipsisWithTooltip"
                                        field="headers"
                                        headerText="Hlavičky"
                                      >
                                      </e-column>

                                      <e-column
                                        [autoFit]="
                                                                                    true
                                                                                "
                                        [filter]="
                                                                                    filterSettings
                                                                                "
                                        [textAlign]="
                                                                                    'left'
                                                                                "
                                        clipMode="EllipsisWithTooltip"
                                        field="creator_str"
                                        headerText="Vytvořil"
                                      >
                                        <ng-template
                                          #template
                                          let-data
                                        >
                                          <div>
                                            <img
                                              *ngIf="
                                                                                                data.creator_img
                                                                                            "
                                              alt="profilový obrázek"
                                              class="img-fluid rounded-circle avatar-xs mr-1"
                                              src="assets/img{{
                                                                                                data.creator_img
                                                                                            }}"
                                            />
                                            <span
                                            >{{
                                                data.creator_str
                                              }}</span
                                            >
                                          </div>
                                        </ng-template>
                                      </e-column>
                                      <e-column
                                        [allowEditing]="
                                                                                    false
                                                                                "
                                        [autoFit]="
                                                                                    true
                                                                                "
                                        [filter]="
                                                                                    filterDate
                                                                                "
                                        [format]="
                                                                                    dateFormat
                                                                                "
                                        [textAlign]="
                                                                                    'right'
                                                                                "
                                        clipMode="EllipsisWithTooltip"
                                        editType="datepickeredit"
                                        field="created_date"
                                        headerText="Vytvořeno"
                                        type="date"
                                      ></e-column>
                                      <e-column
                                        [allowEditing]="
                                                                                    false
                                                                                "
                                        [autoFit]="
                                                                                    true
                                                                                "
                                        [filter]="
                                                                                    filterDate
                                                                                "
                                        [format]="
                                                                                    dateFormat
                                                                                "
                                        [textAlign]="
                                                                                    'right'
                                                                                "
                                        clipMode="EllipsisWithTooltip"
                                        editType="datepickeredit"
                                        field="updated_date"
                                        headerText="Naposledy upraveno"
                                        type="date"
                                      ></e-column>
                                      <e-column
                                        [allowEditing]="
                                                                                    false
                                                                                "
                                        [autoFit]="
                                                                                    true
                                                                                "
                                        [filter]="
                                                                                    filterDate
                                                                                "
                                        [format]="
                                                                                    dateFormat
                                                                                "
                                        [textAlign]="
                                                                                    'right'
                                                                                "
                                        clipMode="EllipsisWithTooltip"
                                        editType="datepickeredit"
                                        field="deleted_date"
                                        headerText="Smazáno"
                                        type="date"
                                      ></e-column>
                                      <e-column
                                        [allowEditing]="
                                                                                    false
                                                                                "
                                        [autoFit]="
                                                                                    true
                                                                                "
                                        [filter]="
                                                                                    filterDate
                                                                                "
                                        [format]="
                                                                                    dateFormat
                                                                                "
                                        [textAlign]="
                                                                                    'right'
                                                                                "
                                        clipMode="EllipsisWithTooltip"
                                        editType="datepickeredit"
                                        field="last_check"
                                        headerText="Poslední kontrola"
                                        type="date"
                                      ></e-column>
                                    </e-columns>
                                    <e-aggregates>
                                      <e-aggregate>
                                        <e-columns>
                                          <e-column
                                            [format]="
                                                                                            'N'
                                                                                        "
                                            field="id"
                                            type="Count"
                                          >
                                            <ng-template
                                              #footerTemplate
                                              let-data
                                            >
                                              Celkem
                                              řádků:
                                              {{
                                                data.Count
                                              }}
                                            </ng-template
                                            >
                                          </e-column>
                                        </e-columns>
                                      </e-aggregate>
                                    </e-aggregates>
                                  </ejs-grid>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12 mt-2 mb-4">
                  <div class="e-card">
                    <div
                      class="e-card-header bg-warning"
                    >
                      <h6 class="text-white">
                        HLAVIČKY ({{
                          api?.headers.length
                        }}):
                      </h6>
                    </div>
                    <div class="e-card-content">
                      <div
                        class="scrollbar scrollbar-sunny-morning thin"
                        style="
                                                    max-height: 350px;
                                                    overflow-y: auto;
                                                    overflow-x: hidden;
                                                "
                      >
                        <div class="force-overflow">
                          <div
                            class="col-md-12 mx-auto my-2"
                          >
                            <ejs-toolbar
                              id="filterHeaderToolbar"
                              overflowMode="Scrollable"
                              scrollStep="500"
                            >
                              <e-items>
                                <e-item
                                  [cssClass]="
                                                                        'statusFilter'
                                                                    "
                                  tooltipText="Přednastavené hledání"
                                  type="input"
                                >
                                  <ng-template
                                    #template
                                  >
                                    <ejs-dropdownlist
                                      #viewFilterHeader
                                      (change)="
                                                                                onStatusFilterChangeHeader(
                                                                                    $event
                                                                                )
                                                                            "
                                      [dataSource]="
                                                                                categoryFilterHeaderData
                                                                            "
                                      [width]="
                                                                                500
                                                                            "
                                      placeholder="Vyberte přednastavený pohled..."
                                    ></ejs-dropdownlist>
                                  </ng-template>
                                </e-item>
                                <e-item
                                  type="Separator"
                                ></e-item>
                                <e-item
                                  [cssClass]="
                                                                        'fulltextFilter'
                                                                    "
                                  tooltipText="Text pro vyhledávání"
                                  type="input"
                                >
                                  <ng-template
                                    #template
                                  >
                                    <ejs-textbox
                                      #searchtextHeader
                                      [width]="
                                                                                500
                                                                            "
                                      class="mr-2"
                                      placeholder="Vyhledat..."
                                    ></ejs-textbox>
                                    <button
                                      (click)="
                                                                                searchHeader()
                                                                            "
                                      class="e-btn e-info btn-p-zero e-small"
                                    >
                                      <i
                                        class="fa-light fa-lg fa-search mr-1"
                                      ></i
                                      ><span
                                      class="d-none d-lg-inline"
                                    >vyhledat</span
                                    >
                                    </button>
                                    <button
                                      (click)="
                                                                                cancelBtnHeaderClick()
                                                                            "
                                      class="e-btn e-danger btn-p-zero e-small"
                                    >
                                      <i
                                        class="fa-light fa-lg fa-times"
                                      ></i>
                                    </button>
                                  </ng-template>
                                </e-item>
                              </e-items>
                            </ejs-toolbar>
                            <div
                              class="control-section"
                            >
                              <div
                                class="scroll_wrapper1"
                                id="scroll_wrapper1_header"
                              >
                                <div
                                  class="scroll_div"
                                ></div>
                              </div>
                              <div
                                class="scroll_wrapper2"
                                id="scroll_wrapper2_header"
                              >
                                <div
                                  class="grid_parent"
                                >
                                  <ejs-grid
                                    #headersGrid
                                    (actionBegin)="
                                                                            actionBeginHeader(
                                                                                $event
                                                                            )
                                                                        "
                                    (actionComplete)="
                                                                            actionComplete(
                                                                                $event
                                                                            )
                                                                        "
                                    (created)="
                                                                            onCreatedHeader()
                                                                        "
                                    (dataBound)="
                                                                            onDataBoundHeader()
                                                                        "
                                    (load)="
                                                                            onLoad()
                                                                        "
                                    (loadeddata)="
                                                                            setInitialGridFiltering()
                                                                        "
                                    (rowDataBound)="
                                                                            rowDataBoundHeader(
                                                                                $event
                                                                            )
                                                                        "
                                    (rowDeselected)="
                                                                            rowSelectedHeader()
                                                                        "
                                    (rowSelected)="
                                                                            rowSelectedHeader()
                                                                        "
                                    (toolbarClick)="
                                                                            toolbarClickHeader(
                                                                                $event
                                                                            )
                                                                        "
                                    [allowExcelExport]="
                                                                            true
                                                                        "
                                    [allowFiltering]="
                                                                            true
                                                                        "
                                    [allowMultiSorting]="
                                                                            true
                                                                        "
                                    [allowPdfExport]="
                                                                            false
                                                                        "
                                    [allowSelection]="
                                                                            true
                                                                        "
                                    [allowSorting]="
                                                                            true
                                                                        "
                                    [dataSource]="
                                                                            dataHeader
                                                                        "
                                    [editSettings]="
                                                                            editSettings
                                                                        "
                                    [enableHover]="
                                                                            false
                                                                        "
                                    [enablePersistence]="
                                                                            false
                                                                        "
                                    [enableVirtualization]="
                                                                            true
                                                                        "
                                    [filterSettings]="
                                                                            filterSettings
                                                                        "
                                    [groupSettings]="
                                                                            groupOptions
                                                                        "
                                    [pageSettings]="
                                                                            pageOptions
                                                                        "
                                    [selectionSettings]="
                                                                            selectionSettings
                                                                        "
                                    [sortSettings]="
                                                                            sortOptionsHeader
                                                                        "
                                    [toolbar]="
                                                                            toolbarHeader
                                                                        "
                                    allowGrouping="true"
                                    allowReordering="true"
                                    allowResizing="true"
                                    allowTextWrap="true"
                                    height="100%"
                                    id="headersGrid"
                                    locale="cs"
                                    rowHeight="38"
                                    showColumnMenu="true"
                                    width="100%"
                                  >
                                    <e-columns>
                                      <e-column
                                        [allowEditing]="
                                                                                    false
                                                                                "
                                        [allowFiltering]="
                                                                                    false
                                                                                "
                                        [allowReordering]="
                                                                                    false
                                                                                "
                                        [allowResizing]="
                                                                                    false
                                                                                "
                                        [allowSorting]="
                                                                                    false
                                                                                "
                                        [autoFit]="
                                                                                    true
                                                                                "
                                        type="checkbox"
                                      ></e-column>
                                      <e-column
                                        [allowFiltering]="
                                                                                    false
                                                                                "
                                        [allowReordering]="
                                                                                    true
                                                                                "
                                        [allowResizing]="
                                                                                    false
                                                                                "
                                        [autoFit]="
                                                                                    true
                                                                                "
                                        [isPrimaryKey]="
                                                                                    true
                                                                                "
                                        [textAlign]="
                                                                                    'left'
                                                                                "
                                        [visible]="
                                                                                    true
                                                                                "
                                        field="id"
                                        headerText="ID"
                                      >
                                        <ng-template
                                          #template
                                          let-data
                                        >
                                          <a
                                            routerLink="/settings/apis/{{
                                                                                            data.id
                                                                                        }}"
                                          >{{
                                              data.id
                                            }}</a
                                          >
                                        </ng-template>
                                      </e-column>
                                      <e-column
                                        [allowEditing]="
                                                                                    false
                                                                                "
                                        [allowFiltering]="
                                                                                    true
                                                                                "
                                        [allowReordering]="
                                                                                    false
                                                                                "
                                        [allowResizing]="
                                                                                    false
                                                                                "
                                        [autoFit]="
                                                                                    true
                                                                                "
                                        [textAlign]="
                                                                                    'left'
                                                                                "
                                        [visible]="
                                                                                    false
                                                                                "
                                        field="created_by"
                                        headerText="Creator ID"
                                      ></e-column>
                                      <e-column
                                        [autoFit]="
                                                                                    true
                                                                                "
                                        [filter]="
                                                                                    filterSettings
                                                                                "
                                        [textAlign]="
                                                                                    'left'
                                                                                "
                                        clipMode="EllipsisWithTooltip"
                                        field="title"
                                        headerText="Název"
                                      >
                                      </e-column>
                                      <e-column
                                        [autoFit]="
                                                                                    true
                                                                                "
                                        [filter]="
                                                                                    filterSettings
                                                                                "
                                        [textAlign]="
                                                                                    'left'
                                                                                "
                                        clipMode="EllipsisWithTooltip"
                                        field="host_url"
                                        headerText="Host"
                                      >
                                      </e-column>
                                      <e-column
                                        [autoFit]="
                                                                                    true
                                                                                "
                                        [filter]="
                                                                                    filterSettings
                                                                                "
                                        [format]="
                                                                                    'N'
                                                                                "
                                        [textAlign]="
                                                                                    'right'
                                                                                "
                                        clipMode="EllipsisWithTooltip"
                                        field="port"
                                        headerText="Port"
                                      >
                                      </e-column>
                                      <e-column
                                        [autoFit]="
                                                                                    true
                                                                                "
                                        [filter]="
                                                                                    filterSettings
                                                                                "
                                        [textAlign]="
                                                                                    'left'
                                                                                "
                                        clipMode="EllipsisWithTooltip"
                                        field="state"
                                        headerText="Stav"
                                      >
                                        <ng-template
                                          #template
                                          let-data
                                        >
                                                                                    <span
                                                                                      *ngIf="
                                                                                            data.state
                                                                                        "
                                                                                      class="e-badge e-badge-pill e-badge-success"
                                                                                    >online</span
                                                                                    >
                                          <span
                                            *ngIf="
                                                                                            !data.state
                                                                                        "
                                            class="e-badge e-badge-pill e-badge-danger"
                                          >offline</span
                                          >
                                        </ng-template>
                                      </e-column>
                                      <e-column
                                        [autoFit]="
                                                                                    true
                                                                                "
                                        [filter]="
                                                                                    filterSettings
                                                                                "
                                        [textAlign]="
                                                                                    'left'
                                                                                "
                                        clipMode="EllipsisWithTooltip"
                                        field="active"
                                        headerText="Aktivní"
                                      >
                                        <ng-template
                                          #template
                                          let-data
                                        >
                                                                                    <span
                                                                                      *ngIf="
                                                                                            data.active
                                                                                        "
                                                                                      class="e-badge e-badge-pill e-badge-success"
                                                                                    >aktivní</span
                                                                                    >
                                          <span
                                            *ngIf="
                                                                                            !data.active
                                                                                        "
                                            class="e-badge e-badge-pill e-badge-danger"
                                          >neaktivní</span
                                          >
                                        </ng-template>
                                      </e-column>
                                      <e-column
                                        [autoFit]="
                                                                                    true
                                                                                "
                                        [filter]="
                                                                                    filterSettings
                                                                                "
                                        [textAlign]="
                                                                                    'left'
                                                                                "
                                        clipMode="EllipsisWithTooltip"
                                        field="type"
                                        headerText="Typ"
                                      >
                                      </e-column>
                                      <e-column
                                        [autoFit]="
                                                                                    true
                                                                                "
                                        [filter]="
                                                                                    filterSettings
                                                                                "
                                        [textAlign]="
                                                                                    'left'
                                                                                "
                                        clipMode="EllipsisWithTooltip"
                                        field="protocol"
                                        headerText="Protokol"
                                      >
                                      </e-column>
                                      <e-column
                                        [autoFit]="
                                                                                    true
                                                                                "
                                        [filter]="
                                                                                    filterSettings
                                                                                "
                                        [textAlign]="
                                                                                    'left'
                                                                                "
                                        clipMode="EllipsisWithTooltip"
                                        field="headers"
                                        headerText="Hlavičky"
                                      >
                                      </e-column>

                                      <e-column
                                        [autoFit]="
                                                                                    true
                                                                                "
                                        [filter]="
                                                                                    filterSettings
                                                                                "
                                        [textAlign]="
                                                                                    'left'
                                                                                "
                                        clipMode="EllipsisWithTooltip"
                                        field="creator_str"
                                        headerText="Vytvořil"
                                      >
                                        <ng-template
                                          #template
                                          let-data
                                        >
                                          <div>
                                            <img
                                              *ngIf="
                                                                                                data.creator_img
                                                                                            "
                                              alt="profilový obrázek"
                                              class="img-fluid rounded-circle avatar-xs mr-1"
                                              src="assets/img{{
                                                                                                data.creator_img
                                                                                            }}"
                                            />
                                            <span
                                            >{{
                                                data.creator_str
                                              }}</span
                                            >
                                          </div>
                                        </ng-template>
                                      </e-column>
                                      <e-column
                                        [allowEditing]="
                                                                                    false
                                                                                "
                                        [autoFit]="
                                                                                    true
                                                                                "
                                        [filter]="
                                                                                    filterDate
                                                                                "
                                        [format]="
                                                                                    dateFormat
                                                                                "
                                        [textAlign]="
                                                                                    'right'
                                                                                "
                                        clipMode="EllipsisWithTooltip"
                                        editType="datepickeredit"
                                        field="created_date"
                                        headerText="Vytvořeno"
                                        type="date"
                                      ></e-column>
                                      <e-column
                                        [allowEditing]="
                                                                                    false
                                                                                "
                                        [autoFit]="
                                                                                    true
                                                                                "
                                        [filter]="
                                                                                    filterDate
                                                                                "
                                        [format]="
                                                                                    dateFormat
                                                                                "
                                        [textAlign]="
                                                                                    'right'
                                                                                "
                                        clipMode="EllipsisWithTooltip"
                                        editType="datepickeredit"
                                        field="updated_date"
                                        headerText="Naposledy upraveno"
                                        type="date"
                                      ></e-column>
                                      <e-column
                                        [allowEditing]="
                                                                                    false
                                                                                "
                                        [autoFit]="
                                                                                    true
                                                                                "
                                        [filter]="
                                                                                    filterDate
                                                                                "
                                        [format]="
                                                                                    dateFormat
                                                                                "
                                        [textAlign]="
                                                                                    'right'
                                                                                "
                                        clipMode="EllipsisWithTooltip"
                                        editType="datepickeredit"
                                        field="deleted_date"
                                        headerText="Smazáno"
                                        type="date"
                                      ></e-column>
                                      <e-column
                                        [allowEditing]="
                                                                                    false
                                                                                "
                                        [autoFit]="
                                                                                    true
                                                                                "
                                        [filter]="
                                                                                    filterDate
                                                                                "
                                        [format]="
                                                                                    dateFormat
                                                                                "
                                        [textAlign]="
                                                                                    'right'
                                                                                "
                                        clipMode="EllipsisWithTooltip"
                                        editType="datepickeredit"
                                        field="last_check"
                                        headerText="Poslední kontrola"
                                        type="date"
                                      ></e-column>
                                    </e-columns>
                                    <e-aggregates>
                                      <e-aggregate>
                                        <e-columns>
                                          <e-column
                                            [format]="
                                                                                            'N'
                                                                                        "
                                            field="id"
                                            type="Count"
                                          >
                                            <ng-template
                                              #footerTemplate
                                              let-data
                                            >
                                              Celkem
                                              řádků:
                                              {{
                                                data.Count
                                              }}
                                            </ng-template
                                            >
                                          </e-column>
                                        </e-columns>
                                      </e-aggregate>
                                    </e-aggregates>
                                  </ejs-grid>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-template>
        </e-tabitem>
        <e-tabitem [header]="headerText[1]">
          <ng-template #content>
            <div
              aria-expanded="false"
              class="tab-pane fade active show"
              id="taskLog"
              role="tabpanel"
            >
              <div class="table-responsive">
                <table class="table">
                  <thead>
                  <tr>
                    <th>#</th>
                    <th>Company Name</th>
                    <th>Username</th>
                    <th>Actions</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr>
                    <th scope="row">1</th>
                    <td>PiedPiper</td>
                    <td>piedpiper</td>
                    <td>
                      <a
                        class="blue-text"
                        title="See results"
                      >
                        <i
                          aria-hidden="true"
                          class="fa-light fa-user"
                        ></i>
                      </a>
                      <a
                        class="teal-text"
                        title="Edit"
                      >
                        <i
                          aria-hidden="true"
                          class="fa-light fa-pencil"
                        ></i>
                      </a>
                      <a
                        class="red-text"
                        title="Remove"
                      >
                        <i
                          aria-hidden="true"
                          class="fa-light fa-times"
                        ></i>
                      </a>

                      <a
                        class="blue-text"
                        title="See results"
                      >
                        <i
                          aria-hidden="true"
                          class="fa-light fa-user px-1"
                        ></i>
                      </a>
                      <a
                        class="teal-text"
                        title="Edit"
                      >
                        <i
                          aria-hidden="true"
                          class="fa-light fa-pencil-alt px-1"
                        ></i>
                      </a>
                      <a
                        class="red-text"
                        title="Remove"
                      >
                        <i
                          aria-hidden="true"
                          class="fa-light fa-times px-1"
                        ></i>
                      </a>

                    </td>
                  </tr>
                  <tr>
                    <th scope="row">2</th>
                    <td>Github, Inc</td>
                    <td>github</td>
                    <td>
                      <a
                        class="blue-text"
                        title="See results"
                      >
                        <i
                          aria-hidden="true"
                          class="fa-light fa-user"
                        ></i>
                      </a>
                      <a
                        class="teal-text"
                        title="Edit"
                      >
                        <i
                          aria-hidden="true"
                          class="fa-light fa-pencil"
                        ></i>
                      </a>
                      <a
                        class="red-text"
                        title="Remove"
                      >
                        <i
                          aria-hidden="true"
                          class="fa-light fa-times"
                        ></i>
                      </a>

                      <a
                        class="blue-text"
                        title="See results"
                      >
                        <i
                          aria-hidden="true"
                          class="fa-light fa-user px-1"
                        ></i>
                      </a>
                      <a
                        class="teal-text"
                        title="Edit"
                      >
                        <i
                          aria-hidden="true"
                          class="fa-light fa-pencil-alt px-1"
                        ></i>
                      </a>
                      <a
                        class="red-text"
                        title="Remove"
                      >
                        <i
                          aria-hidden="true"
                          class="fa-light fa-times px-1"
                        ></i>
                      </a>

                    </td>
                  </tr>
                  <tr>
                    <th scope="row">3</th>
                    <td>Twitter, Inc</td>
                    <td>twitter</td>
                    <td>
                      <a
                        class="blue-text"
                        title="See results"
                      >
                        <i
                          aria-hidden="true"
                          class="fa-light fa-user"
                        ></i>
                      </a>
                      <a
                        class="teal-text"
                        title="Edit"
                      >
                        <i
                          aria-hidden="true"
                          class="fa-light fa-pencil"
                        ></i>
                      </a>
                      <a
                        class="red-text"
                        title="Remove"
                      >
                        <i
                          aria-hidden="true"
                          class="fa-light fa-times"
                        ></i>
                      </a>

                      <a
                        class="blue-text"
                        title="See results"
                      >
                        <i
                          aria-hidden="true"
                          class="fa-light fa-user px-1"
                        ></i>
                      </a>
                      <a
                        class="teal-text"
                        title="Edit"
                      >
                        <i
                          aria-hidden="true"
                          class="fa-light fa-pencil-alt px-1"
                        ></i>
                      </a>
                      <a
                        class="red-text"
                        title="Remove"
                      >
                        <i
                          aria-hidden="true"
                          class="fa-light fa-times px-1"
                        ></i>
                      </a>

                    </td>
                  </tr>
                  <tr>
                    <th scope="row">4</th>
                    <td>Alphabet, Inc</td>
                    <td>alphabet</td>
                    <td>
                      <a
                        class="blue-text"
                        title="See results"
                      >
                        <i
                          aria-hidden="true"
                          class="fa-light fa-user"
                        ></i>
                      </a>
                      <a
                        class="teal-text"
                        title="Edit"
                      >
                        <i
                          aria-hidden="true"
                          class="fa-light fa-pencil"
                        ></i>
                      </a>
                      <a
                        class="red-text"
                        title="Remove"
                      >
                        <i
                          aria-hidden="true"
                          class="fa-light fa-times"
                        ></i>
                      </a>

                      <a
                        class="blue-text"
                        title="See results"
                      >
                        <i
                          aria-hidden="true"
                          class="fa-light fa-user px-1"
                        ></i>
                      </a>
                      <a
                        class="teal-text"
                        title="Edit"
                      >
                        <i
                          aria-hidden="true"
                          class="fa-light fa-pencil-alt px-1"
                        ></i>
                      </a>
                      <a
                        class="red-text"
                        title="Remove"
                      >
                        <i
                          aria-hidden="true"
                          class="fa-light fa-times px-1"
                        ></i>
                      </a>

                    </td>
                  </tr>
                  <tr>
                    <th scope="row">5</th>
                    <td>Adobe Corporation</td>
                    <td>adobe</td>
                    <td>
                      <a
                        class="blue-text"
                        title="See results"
                      >
                        <i
                          aria-hidden="true"
                          class="fa-light fa-user"
                        ></i>
                      </a>
                      <a
                        class="teal-text"
                        title="Edit"
                      >
                        <i
                          aria-hidden="true"
                          class="fa-light fa-pencil"
                        ></i>
                      </a>
                      <a
                        class="red-text"
                        title="Remove"
                      >
                        <i
                          aria-hidden="true"
                          class="fa-light fa-times"
                        ></i>
                      </a>

                      <a
                        class="blue-text"
                        title="See results"
                      >
                        <i
                          aria-hidden="true"
                          class="fa-light fa-user px-1"
                        ></i>
                      </a>
                      <a
                        class="teal-text"
                        title="Edit"
                      >
                        <i
                          aria-hidden="true"
                          class="fa-light fa-pencil-alt px-1"
                        ></i>
                      </a>
                      <a
                        class="red-text"
                        title="Remove"
                      >
                        <i
                          aria-hidden="true"
                          class="fa-light fa-times px-1"
                        ></i>
                      </a>

                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </ng-template>
        </e-tabitem>
      </e-tabitems>
    </ejs-tab>
  </div>
  <div *ngIf="!api" class="col-12 col-md-8 col-lg-9 text-center">
    <div class="e-card">
      <div class="e-card-content">
        <app-loader></app-loader>
      </div>
    </div>
  </div>
  <div *ngIf="api" class="col-12 col-md-4 col-lg-3 mb-1 pt-3">
    <div class="e-card">
      <div class="e-card-content pt-0 mt-0">
        <div class="text-center">
          <img
            alt="profilový obrázek"
            class="img-fluid rounded-circle avatar-md p-2"
            src="assets/img/{{ api.creator.personalphoto }}"
          />
          <h5 class="mb-2 font-bold">
            <strong
            >{{ api.creator.firstname }}
              {{ api.creator.secondname }}</strong
            >
          </h5>
          <h6 class="font-bold blue-text mb-2">zadavatel</h6>
        </div>
        <ul class="striped list-unstyled text-center">
          <li>
            <small
            ><strong>E-mai: </strong>
              <a
                href="mailto:{{ api.creator.workemail }}"
                title="napsat zprávu"
              >{{ api.creator.workemail }}</a
              >
            </small>
          </li>
          <li>
            <small
            ><strong>Telefon: </strong>
              <a
                href="tel:{{ api.creator.telnumber }}"
                title="zavolat"
              >{{ api.creator.telnumber }}</a
              ></small
            >
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div *ngIf="!api" class="col-12 col-md-4 col-lg-3 mb-1 text-center">
    <div class="e-card">
      <div class="e-card-content">
        <app-loader></app-loader>
      </div>
    </div>
  </div>
</div>
