<ejs-toolbar
  #moduleToolbar
  id="moduleToolbar"
  overflowMode="Scrollable"
  scrollStep="300"
>
  <e-items>
    <e-item
      [align]="'center'"
      tooltipText="vytvořit hot-line"
      type="button"
    >
      <ng-template #template>
        <button
          (click)="navigateToTab('grid', 'addHotline')"
          cssClass="e-btn e-primary btn-p-zero e-small"
          ejs-button
          title="vytvořit hot-line"
          type="button"
        >
          <i class="fa-light fa-lg fa-plus mr-2"></i>
          vytvořit hot-line
        </button>
      </ng-template>
    </e-item>
    <e-item type="Separator"></e-item>
    <e-item
      [align]="'center'"
      tooltipText="vytvořit zápis hot-line"
      type="button"
    >
      <ng-template #template>
        <button
          (click)="navigateToTab('grid', 'addHotlineReport')"
          cssClass="e-btn e-primary btn-p-zero e-small"
          ejs-button
          title="vytvořit zápis hot-line"
          type="button"
        >
          <i class="fa-light fa-lg fa-sticky-note mr-2"></i>
          vytvořit zápis hot-line
        </button>
      </ng-template>
    </e-item>
    <e-item type="Separator"></e-item>
    <e-item [align]="'center'" tooltipText="seznam hot-line" type="button">
      <ng-template #template>
        <button
          (click)="navigateToTab('grid', null)"
          cssClass="e-btn e-warning btn-p-zero e-small"
          ejs-button
          title="seznam hot-line"
          type="button"
        >
          <i class="fa-light fa-lg fa-list mr-2"></i>
          seznam hot-line
        </button>
      </ng-template>
    </e-item>
    <e-item type="Separator"></e-item>
    <e-item [align]="'center'" tooltipText="XML export" type="button">
      <ng-template #template>
        <button
          (click)="getCalendarXMLExport()"
          cssClass="e-btn e-info e-outline btn-p-zero e-small"
          ejs-button
          title="exportovat data do přenositelného formátu"
        >
          <i aria-hidden="true" class="fa-light fa-file mr-lg-1"></i>
          XML export
        </button>
      </ng-template>
    </e-item>
    <e-item type="Separator"></e-item>
    <e-item [align]="'center'" tooltipText="CSV export" type="button">
      <ng-template #template>
        <button
          (click)="getCalendarCSVExport()"
          cssClass="e-btn e-info e-outline btn-p-zero e-small"
          ejs-button
          title="exportovat data do přenositelného formátu"
        >
          <i
            aria-hidden="true"
            class="fa-light fa-file-csv mr-lg-1"
          ></i>
          CSV export
        </button>
      </ng-template>
    </e-item>
    <e-item type="Separator"></e-item>
    <e-item [align]="'center'" tooltipText="ICAL export" type="button">
      <ng-template #template>
        <button
          (click)="getCalendarICALExport()"
          [disabled]="true"
          cssClass="e-btn e-info e-outline btn-p-zero e-small"
          ejs-button
          title="exportovat data do přenositelného formátu"
        >
          <i
            aria-hidden="true"
            class="fa-light fa-calendar-day mr-lg-1"
          ></i>
          ICAL export
        </button>
      </ng-template>
    </e-item>
  </e-items>
</ejs-toolbar>
<ejs-tab
  #hotlineTab
  (created)="onCreate()"
  (selected)="select($event)"
  (selecting)="preventSwipe($event)"
  enablePersistence="true"
  height="auto"
  heightAdjustMode="Content"
  id="hotlineTab"
>
  <e-tabitems>
    <e-tabitem [header]="headerText[0]">
      <ng-template #content>
        <app-hotline-list></app-hotline-list>
      </ng-template>
    </e-tabitem>
    <e-tabitem [header]="headerText[1]">
      <ng-template #content>
        <app-hotline-users></app-hotline-users>
      </ng-template>
    </e-tabitem>
    <e-tabitem [header]="headerText[2]">
      <ng-template #content>
        <app-hotline-calendar></app-hotline-calendar>
      </ng-template>
    </e-tabitem>
    <e-tabitem [header]="headerText[3]">
      <ng-template #content>
        <div class="control-section">
          <!--    <div style="padding:5px;text-align: right;">
                  <button ejs-button id="add" class="e-btn e-info" (click)="addPanel()">Add Panel</button>
              </div>-->
          <div
            class="mb-3"
            id="dashBoardParent"
            style="width: 100% !important"
          >
            <ejs-dashboardlayout
              #default_dashboard
              [allowDragging]="true"
              [allowResizing]="true"
              [cellAspectRatio]="cellAspectRatio"
              [cellSpacing]="cellSpacing"
              [enablePersistence]="false"
              columns="4"
              id="vacReports_dashboard"
            >
              <e-panels>
                <e-panel
                  [col]="0"
                  [row]="0"
                  [sizeX]="4"
                  [sizeY]="2"
                >
                  <ng-template #content>
                    <i
                      (click)="onCloseIconHandler($event)"
                      aria-hidden="true"
                      class="fa-light fa-lg fa-time"
                    ></i>
                    <div
                      class="e-panel-container scrollbar scrollbar-indigo thin p-2"
                    >
                      <ng-container
                        *ngIf="
                                                    !loadingHotliner &&
                                                    hotliners
                                                "
                      >
                        <ejs-accumulationchart
                          #pieChart
                          (tooltipRender)="
                                                        tooltipRender($event)
                                                    "
                          [enableAnimation]="
                                                        enableAnimation
                                                    "
                          [enableSmartLabels]="
                                                        enableSmartLabels
                                                    "
                          [legendSettings]="
                                                        legendSettings
                                                    "
                          [margin]="{
                                                        left: 5,
                                                        right: 5,
                                                        top: 5,
                                                        bottom: 5
                                                    }"
                          [title]="title"
                          [tooltip]="tooltip"
                          id="container"
                          width="100%"
                        >
                          <e-accumulation-series-collection>
                            <e-accumulation-series
                              [dataLabel]="
                                                                datalabel
                                                            "
                              [dataSource]="
                                                                pieData
                                                            "
                              [endAngle]="
                                                                endAngle
                                                            "
                              [startAngle]="
                                                                startAngle
                                                            "
                              innerRadius="20%"
                              name="Hotline score"
                              radius="r"
                              xName="x"
                              yName="y"
                            >
                            </e-accumulation-series>
                          </e-accumulation-series-collection>
                        </ejs-accumulationchart>
                        <div
                          class="d-flex justify-content-center p-4"
                        >
                          <button
                            (click)="print()"
                            cssClass="e-btn e-info e-outline btn-p-zero e-small"
                            ejs-button
                            id="print"
                          >
                            Tisknout
                          </button>
                          <button
                            (click)="export()"
                            cssClass="e-btn e-info e-outline btn-p-zero e-small"
                            ejs-button
                            id="export"
                          >
                            Exportovat
                          </button>
                        </div>
                      </ng-container>
                    </div>
                  </ng-template>
                </e-panel>
              </e-panels>
            </ejs-dashboardlayout>
          </div>
        </div>
      </ng-template>
    </e-tabitem>
    <e-tabitem [header]="headerText[4]">
      <ng-template #content>
        <app-hotline-import></app-hotline-import>
      </ng-template>
    </e-tabitem>
  </e-tabitems>
</ejs-tab>
