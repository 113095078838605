<ejs-dialog
  #formDialog
  (close)="isDirty = false; isChecked = false; changeDialogState(false)"
  [isModal]="true"
  [showCloseIcon]="true"
  [visible]="isVisible"
  [width]="'85%'"
  id="formDialog"
>
  <form [formGroup]="userForm">
    <ng-template #content>
      <div class="dialogContent">
        <div class="row">
          <div class="form-group col-md-6 col-12 mb-4">
            <label
              [ngClass]="
                                f.userEmployer.valid &&
                                (f.userEmployer.dirty || f.userEmployer.touched)
                                    ? 'text-success'
                                    : f.userEmployer.invalid &&
                                      (f.userEmployer.dirty ||
                                          f.userEmployer.touched)
                                    ? 'text-danger'
                                    : 'text-muted'
                            "
              for="userForm-employer_id"
            >* Zaměstnavatel:</label
            >
            <ejs-dropdownlist
              #employer
              (filtering)="onFilteringEmployers($event)"
              [allowFiltering]="true"
              [cssClass]="
                                (f.userEmployer.dirty ||
                                    f.userEmployer.touched) &&
                                f.userEmployer.invalid
                                    ? 'e-error'
                                    : (f.userEmployer.dirty ||
                                          f.userEmployer.touched) &&
                                      f.userEmployer.valid
                                    ? 'e-success'
                                    : ''
                            "
              [dataSource]="employerSelect"
              [fields]="fields"
              [filterBarPlaceholder]="
                                'Vyhledat zaměstnavatele...'
                            "
              [ignoreAccent]="true"
              [placeholder]="'Vyberte zaměstnavatele'"
              [popupHeight]="height"
              formControlName="userEmployer"
              id="userForm-employer_id"
              sortOrder="Ascending"
            ></ejs-dropdownlist>
            <small
              *ngIf="
                                (f.userEmployer.dirty ||
                                    f.userEmployer.touched) &&
                                f.userEmployer.errors &&
                                f.userEmployer.errors.required
                            "
              class="text-danger"
            >Zaměstnavatel je povinný</small
            >
          </div>
          <div class="form-group col-md-6 col-12 mb-4">
            <label
              [ngClass]="
                                f.userBoss.valid &&
                                (f.userBoss.dirty || f.userBoss.touched)
                                    ? 'text-success'
                                    : f.userBoss.invalid &&
                                      (f.userBoss.dirty || f.userBoss.touched)
                                    ? 'text-danger'
                                    : 'text-muted'
                            "
              for="userForm-boss_id"
            >* Přímý nadřízený:</label
            >
            <ejs-dropdownlist
              #boss
              (filtering)="onFilteringUsers($event)"
              [allowFiltering]="true"
              [cssClass]="
                                (f.userBoss.dirty || f.userBoss.touched) &&
                                f.userBoss.invalid
                                    ? 'e-error'
                                    : (f.userBoss.dirty ||
                                          f.userBoss.touched) &&
                                      f.userBoss.valid
                                    ? 'e-success'
                                    : ''
                            "
              [dataSource]="bossSelect"
              [fields]="fields"
              [filterBarPlaceholder]="'Vyhledat nadřízeného...'"
              [ignoreAccent]="true"
              [placeholder]="'Vyberte nadřízeného'"
              [popupHeight]="height"
              formControlName="userBoss"
              id="userForm-boss_id"
              sortOrder="Ascending"
            ></ejs-dropdownlist>
            <small
              *ngIf="
                                (f.userBoss.dirty || f.userBoss.touched) &&
                                f.userBoss.errors &&
                                f.userBoss.errors.required
                            "
              class="text-danger"
            >Přímý nadřízený je povinný</small
            >
          </div>
          <div class="form-group col-md-6 col-12 mb-4">
            <label
              [ngClass]="
                                f.userFirstname.valid &&
                                (f.userFirstname.dirty ||
                                    f.userFirstname.touched)
                                    ? 'text-success'
                                    : f.userFirstname.invalid &&
                                      (f.userFirstname.dirty ||
                                          f.userFirstname.touched)
                                    ? 'text-danger'
                                    : 'text-muted'
                            "
              for="userForm-firstname"
            >* Jméno:</label
            >
            <ejs-textbox
              [ngClass]="
                                (f.userFirstname.dirty ||
                                    f.userFirstname.touched) &&
                                f.userFirstname.invalid
                                    ? 'e-error'
                                    : (f.userFirstname.dirty ||
                                          f.userFirstname.touched) &&
                                      f.userFirstname.valid
                                    ? 'e-success'
                                    : ''
                            "
              floatLabelType="Auto"
              formControlName="userFirstname"
              id="userForm-firstname"
            ></ejs-textbox>
            <small
              *ngIf="
                                (f.userFirstname.dirty ||
                                    f.userFirstname.touched) &&
                                f.userFirstname.errors &&
                                f.userFirstname.errors.required
                            "
              class="text-danger"
            >Jméno je povinné</small
            >
          </div>
          <div class="form-group col-md-6 col-12 mb-4">
            <label
              [ngClass]="
                                f.userSecondname.valid &&
                                (f.userSecondname.dirty ||
                                    f.userSecondname.touched)
                                    ? 'text-success'
                                    : f.userSecondname.invalid &&
                                      (f.userSecondname.dirty ||
                                          f.userSecondname.touched)
                                    ? 'text-danger'
                                    : 'text-muted'
                            "
              for="userForm-secondname"
            >* Příjmení:</label
            >
            <ejs-textbox
              [ngClass]="
                                (f.userSecondname.dirty ||
                                    f.userSecondname.touched) &&
                                f.userSecondname.invalid
                                    ? 'e-error'
                                    : (f.userSecondname.dirty ||
                                          f.userSecondname.touched) &&
                                      f.userSecondname.valid
                                    ? 'e-success'
                                    : ''
                            "
              floatLabelType="Auto"
              formControlName="userSecondname"
              id="userForm-secondname"
            ></ejs-textbox>
            <small
              *ngIf="
                                (f.userSecondname.dirty ||
                                    f.userSecondname.touched) &&
                                f.userSecondname.errors &&
                                f.userSecondname.errors.required
                            "
              class="text-danger"
            >Příjmení je povinné</small
            >
          </div>
          <div class="form-group col-md-6 col-12 mb-4">
            <label
              [ngClass]="
                                f.userWorkemail.valid
                                    ? 'text-success'
                                    : f.userWorkemail.invalid
                                    ? 'text-danger'
                                    : 'text-muted'
                            "
              for="userForm-workemail"
            >* Pracovní email:</label
            >
            <ejs-textbox
              [ngClass]="
                                f.userWorkemail.invalid
                                    ? 'e-error'
                                    : f.userWorkemail.valid
                                    ? 'e-success'
                                    : ''
                            "
              floatLabelType="Auto"
              formControlName="userWorkemail"
              id="userForm-workemail"
            ></ejs-textbox>
            <small
              *ngIf="
                                f.userWorkemail.errors &&
                                f.userWorkemail.errors.pattern
                            "
              class="text-danger"
            >Pracovní email není ve správném formátu</small
            >
            <small
              *ngIf="
                                f.userWorkemail.errors &&
                                f.userWorkemail.errors.taken
                            "
              class="text-danger"
            >Pracovní email již používá někdo jiný</small
            >
            <small
              *ngIf="
                                f.userWorkemail.errors &&
                                f.userWorkemail.errors.required
                            "
              class="text-danger"
            >Pracovní email je povinný</small
            >
          </div>
          <div class="form-group col-md-6 col-12 mb-4">
            <label
              [ngClass]="
                                f.userTelnumber.valid &&
                                (f.userTelnumber.dirty ||
                                    f.userTelnumber.touched)
                                    ? 'text-success'
                                    : f.userTelnumber.invalid &&
                                      (f.userTelnumber.dirty ||
                                          f.userTelnumber.touched)
                                    ? 'text-danger'
                                    : 'text-muted'
                            "
              for="userForm-telnumber"
            >* Pracovní telefon:</label
            >
            <ejs-textbox
              [ngClass]="
                                (f.userTelnumber.dirty ||
                                    f.userTelnumber.touched) &&
                                f.userTelnumber.invalid
                                    ? 'e-error'
                                    : (f.userTelnumber.dirty ||
                                          f.userTelnumber.touched) &&
                                      f.userTelnumber.valid
                                    ? 'e-success'
                                    : ''
                            "
              floatLabelType="Auto"
              formControlName="userTelnumber"
              id="userForm-telnumber"
            ></ejs-textbox>
            <small
              *ngIf="
                                (f.userTelnumber.dirty ||
                                    f.userTelnumber.touched) &&
                                f.userTelnumber.errors &&
                                f.userTelnumber.errors.pattern
                            "
              class="text-danger"
            >Pracovní telefon není ve správném formátu</small
            >
            <small
              *ngIf="
                                (f.userTelnumber.dirty ||
                                    f.userTelnumber.touched) &&
                                f.userTelnumber.errors &&
                                f.userTelnumber.errors.taken
                            "
              class="text-danger"
            >Pracovní telefon již používá někdo jiný</small
            >
            <small
              *ngIf="
                                (f.userTelnumber.dirty ||
                                    f.userTelnumber.touched) &&
                                f.userTelnumber.errors &&
                                f.userTelnumber.errors.required
                            "
              class="text-danger"
            >Pracovní telefon je povinný</small
            >
          </div>
          <div class="form-group col-12 mb-4">
            <label
              [ngClass]="
                                f.userPositionName.valid &&
                                (f.userPositionName.dirty ||
                                    f.userPositionName.touched)
                                    ? 'text-success'
                                    : f.userPositionName.invalid &&
                                      (f.userPositionName.dirty ||
                                          f.userPositionName.touched)
                                    ? 'text-danger'
                                    : 'text-muted'
                            "
              for="userForm-positionName"
            >* Název pracovní pozice:</label
            >
            <ejs-textbox
              [ngClass]="
                                (f.userPositionName.dirty ||
                                    f.userPositionName.touched) &&
                                f.userPositionName.invalid
                                    ? 'e-error'
                                    : (f.userPositionName.dirty ||
                                          f.userPositionName.touched) &&
                                      f.userPositionName.valid
                                    ? 'e-success'
                                    : ''
                            "
              floatLabelType="Auto"
              formControlName="userPositionName"
              id="userForm-positionName"
            ></ejs-textbox>
            <small
              *ngIf="
                                (f.userPositionName.dirty ||
                                    f.userPositionName.touched) &&
                                f.userPositionName.errors &&
                                f.userPositionName.errors.required
                            "
              class="text-danger"
            >Název pracovní pozice je povinný</small
            >
          </div>
          <div *ngIf="isCreate" class="form-group col-4 mb-4">
            <label class="text-muted" for="userForm-hoursWeek"
            >Počet pracovních hodin týdně:</label
            >
            <ejs-numerictextbox
              [enabled]="false"
              [format]="'N1'"
              id="userForm-hoursWeek"
              max="40"
              min="0"
              step="0.5"
              value="40"
            ></ejs-numerictextbox>
          </div>
          <div *ngIf="isCreate" class="form-group col-4 mb-4">
            <label class="text-muted" for="userForm-vacWeek"
            >Nárok na dovolenou v týdnech:</label
            >
            <ejs-numerictextbox
              [enabled]="false"
              [format]="'N'"
              id="userForm-vacWeek"
              max="5"
              min="0"
              step="1"
              value="4"
            ></ejs-numerictextbox>
          </div>
          <div *ngIf="isCreate" class="form-group col-4 mb-4">
            <label class="text-muted" for="userForm-worksWeek"
            >Počet již odpracovaných týdnů:</label
            >
            <ejs-numerictextbox
              [enabled]="true"
              [format]="'N'"
              [value]="weekCount"
              id="userForm-worksWeek"
              max="53"
              min="0"
              step="1"
            ></ejs-numerictextbox>
          </div>
          <div class="form-group col-md-6 col-12 mb-4">
            <label
              [ngClass]="
                                f.userVacDays.valid &&
                                (f.userVacDays.dirty || f.userVacDays.touched)
                                    ? 'text-success'
                                    : f.userVacDays.invalid &&
                                      (f.userVacDays.dirty ||
                                          f.userVacDays.touched)
                                    ? 'text-danger'
                                    : 'text-muted'
                            "
              for="userForm-vacDays"
            >* Počet dní dovolené:</label
            >
            <ejs-numerictextbox
              [format]="'N1'"
              [ngClass]="
                                (f.userVacDays.dirty ||
                                    f.userVacDays.touched) &&
                                f.userVacDays.invalid
                                    ? 'e-error'
                                    : (f.userVacDays.dirty ||
                                          f.userVacDays.touched) &&
                                      f.userVacDays.valid
                                    ? 'e-success'
                                    : ''
                            "
              formControlName="userVacDays"
              id="userForm-vacDays"
              max="40"
              min="0"
              step="0.5"
            ></ejs-numerictextbox>
            <small
              *ngIf="
                                (f.userVacDays.dirty ||
                                    f.userVacDays.touched) &&
                                f.userVacDays.errors &&
                                f.userVacDays.errors.required
                            "
              class="text-danger"
            >Počet dní dovolené je povinný</small
            >
            <small
              *ngIf="
                                (f.userVacDays.dirty ||
                                    f.userVacDays.touched) &&
                                f.userVacDays.errors &&
                                f.userVacDays.errors.max
                            "
              class="text-danger"
            >Překročen maximální možný fond dovolené</small
            >
          </div>
          <div class="form-group col-md-6 col-12 mb-4">
            <label
              [ngClass]="
                                f.userSickDays.valid &&
                                (f.userSickDays.dirty || f.userSickDays.touched)
                                    ? 'text-success'
                                    : f.userSickDays.invalid &&
                                      (f.userSickDays.dirty ||
                                          f.userSickDays.touched)
                                    ? 'text-danger'
                                    : 'text-muted'
                            "
              for="userForm-sickDays"
            >* Počet dní sickdays:</label
            >
            <ejs-numerictextbox
              [format]="'N1'"
              [ngClass]="
                                (f.userSickDays.dirty ||
                                    f.userSickDays.touched) &&
                                f.userSickDays.invalid
                                    ? 'e-error'
                                    : (f.userSickDays.dirty ||
                                          f.userSickDays.touched) &&
                                      f.userSickDays.valid
                                    ? 'e-success'
                                    : ''
                            "
              formControlName="userSickDays"
              id="userForm-sickDays"
              max="3"
              min="0"
              step="0.5"
            ></ejs-numerictextbox>
            <small
              *ngIf="
                                (f.userSickDays.dirty ||
                                    f.userSickDays.touched) &&
                                f.userSickDays.errors &&
                                f.userSickDays.errors.required
                            "
              class="text-danger"
            >Počet dní sickdays je povinný</small
            >
            <small
              *ngIf="
                                (f.userSickDays.dirty ||
                                    f.userSickDays.touched) &&
                                f.userSickDays.errors &&
                                f.userSickDays.errors.max
                            "
              class="text-danger"
            >Překročen maximální možný fond sickdays</small
            >
          </div>
          <div class="form-group col-12 col-md-6 mb-2">
            <label
              [ngClass]="
                                f.userPassword.valid &&
                                (f.userPassword.dirty || f.userPassword.touched)
                                    ? 'text-success'
                                    : f.userPassword.invalid &&
                                      (f.userPassword.dirty ||
                                          f.userPassword.touched)
                                    ? 'text-danger'
                                    : 'text-muted'
                            "
              for="userForm-password"
            >* Heslo</label
            >
            <input
              [ngClass]="
                                (f.userPassword.dirty ||
                                    f.userPassword.touched) &&
                                f.userPassword.invalid
                                    ? 'e-error'
                                    : (f.userPassword.dirty ||
                                          f.userPassword.touched) &&
                                      f.userPassword.valid
                                    ? 'e-success'
                                    : ''
                            "
              autocomplete="new-password"
              class="e-input"
              formControlName="userPassword"
              id="userForm-password"
              type="password"
            />
            <small class="text-muted"
            >(min: 10 znaků + číslo + velké písmeno + malé
              písmeno)</small
            ><br />
            <small
              *ngIf="
                                (f.userPassword.dirty ||
                                    f.userPassword.touched) &&
                                f.userPassword.errors &&
                                f.userPassword.errors.required
                            "
              class="text-danger"
            >Heslo uživatele je povinné</small
            >
            <br
              *ngIf="
                                (f.userPassword.dirty ||
                                    f.userPassword.touched) &&
                                f.userPassword.errors &&
                                f.userPassword.errors.required
                            "
            />
            <small
              *ngIf="
                                (f.userPassword.dirty ||
                                    f.userPassword.touched) &&
                                f.userPassword.errors &&
                                f.userPassword.errors.minlength
                            "
              class="text-danger"
            >Heslo musí obsahovat alespoň 10 znaků</small
            >
            <br
              *ngIf="
                                (f.userPassword.dirty ||
                                    f.userPassword.touched) &&
                                f.userPassword.errors &&
                                f.userPassword.errors.minlength
                            "
            />
            <small
              *ngIf="
                                (f.userPassword.dirty ||
                                    f.userPassword.touched) &&
                                f.userPassword.errors &&
                                f.userPassword.errors.hasNumber
                            "
              class="text-danger"
            >Heslo musí obsahovat alespoň jednu číslici</small
            >
            <br
              *ngIf="
                                (f.userPassword.dirty ||
                                    f.userPassword.touched) &&
                                f.userPassword.errors &&
                                f.userPassword.errors.hasNumber
                            "
            />
            <small
              *ngIf="
                                (f.userPassword.dirty ||
                                    f.userPassword.touched) &&
                                f.userPassword.errors &&
                                f.userPassword.errors.hasCapitalCase
                            "
              class="text-danger"
            >Heslo musí obsahovat alespoň jedno velké
              písmeno</small
            >
            <br
              *ngIf="
                                (f.userPassword.dirty ||
                                    f.userPassword.touched) &&
                                f.userPassword.errors &&
                                f.userPassword.errors.hasCapitalCase
                            "
            />
            <small
              *ngIf="
                                (f.userPassword.dirty ||
                                    f.userPassword.touched) &&
                                f.userPassword.errors &&
                                f.userPassword.errors.hasSmallCase
                            "
              class="text-danger"
            >Heslo musí obsahovat alespoň jedno malé
              písmeno</small
            >
            <br
              *ngIf="
                                (f.userPassword.dirty ||
                                    f.userPassword.touched) &&
                                f.userPassword.errors &&
                                f.userPassword.errors.hasSmallCase
                            "
            />
            <small
              *ngIf="
                                (f.confirmPassword.dirty ||
                                    f.confirmPassword.touched) &&
                                f.confirmPassword.errors &&
                                userForm.controls['confirmPassword'].hasError(
                                    'NoPasswordMatch'
                                )
                            "
              class="text-danger"
            >Hesla se neshodují!</small
            >
          </div>
          <div class="form-group col-12 col-md-6 mb-2">
            <label
              [ngClass]="
                                f.confirmPassword.valid &&
                                (f.confirmPassword.dirty ||
                                    f.confirmPassword.touched)
                                    ? 'text-success'
                                    : f.confirmPassword.invalid &&
                                      (f.confirmPassword.dirty ||
                                          f.confirmPassword.touched)
                                    ? 'text-danger'
                                    : 'text-muted'
                            "
              for="userForm-confirmPassword"
            >* Potvrdit heslo</label
            >
            <input
              [ngClass]="
                                (f.confirmPassword.dirty ||
                                    f.confirmPassword.touched) &&
                                f.confirmPassword.invalid
                                    ? 'e-error'
                                    : (f.confirmPassword.dirty ||
                                          f.confirmPassword.touched) &&
                                      f.confirmPassword.valid
                                    ? 'e-success'
                                    : ''
                            "
              autocomplete="repeat-password"
              class="e-input"
              formControlName="confirmPassword"
              id="userForm-confirmPassword"
              type="password"
            />
            <small
              *ngIf="
                                (f.confirmPassword.dirty ||
                                    f.confirmPassword.touched) &&
                                f.confirmPassword.errors &&
                                f.confirmPassword.errors.required
                            "
              class="text-danger"
            >Heslo uživatele je povinné</small
            >
            <br
              *ngIf="
                                (f.confirmPassword.dirty ||
                                    f.confirmPassword.touched) &&
                                f.confirmPassword.errors &&
                                f.confirmPassword.errors.required
                            "
            />
            <small
              *ngIf="
                                (f.confirmPassword.dirty ||
                                    f.confirmPassword.touched) &&
                                f.confirmPassword.errors &&
                                f.confirmPassword.errors.minlength
                            "
              class="text-danger"
            >Heslo musí obsahovat alespoň 10 znaků</small
            >
            <br
              *ngIf="
                                (f.confirmPassword.dirty ||
                                    f.confirmPassword.touched) &&
                                f.confirmPassword.errors &&
                                f.confirmPassword.errors.minlength
                            "
            />
            <small
              *ngIf="
                                (f.confirmPassword.dirty ||
                                    f.confirmPassword.touched) &&
                                f.confirmPassword.errors &&
                                f.confirmPassword.errors.hasNumber
                            "
              class="text-danger"
            >Heslo musí obsahovat alespoň jednu číslici</small
            >
            <br
              *ngIf="
                                (f.confirmPassword.dirty ||
                                    f.confirmPassword.touched) &&
                                f.confirmPassword.errors &&
                                f.confirmPassword.errors.hasNumber
                            "
            />
            <small
              *ngIf="
                                (f.confirmPassword.dirty ||
                                    f.confirmPassword.touched) &&
                                f.confirmPassword.errors &&
                                f.confirmPassword.errors.hasCapitalCase
                            "
              class="text-danger"
            >Heslo musí obsahovat alespoň jedno velké
              písmeno</small
            >
            <br
              *ngIf="
                                (f.confirmPassword.dirty ||
                                    f.confirmPassword.touched) &&
                                f.confirmPassword.errors &&
                                f.confirmPassword.errors.hasCapitalCase
                            "
            />
            <small
              *ngIf="
                                (f.confirmPassword.dirty ||
                                    f.confirmPassword.touched) &&
                                f.confirmPassword.errors &&
                                f.confirmPassword.errors.hasSmallCase
                            "
              class="text-danger"
            >Heslo musí obsahovat alespoň jedno malé
              písmeno</small
            >
            <br
              *ngIf="
                                (f.confirmPassword.dirty ||
                                    f.confirmPassword.touched) &&
                                f.confirmPassword.errors &&
                                f.confirmPassword.errors.hasSmallCase
                            "
            />
            <small
              *ngIf="
                                (f.confirmPassword.dirty ||
                                    f.confirmPassword.touched) &&
                                f.confirmPassword.errors &&
                                userForm.controls['confirmPassword'].hasError(
                                    'NoPasswordMatch'
                                )
                            "
              class="text-danger"
            >Hesla se neshodují!</small
            >
          </div>
          <div
            class="form-group col-12 d-flex justify-content-center mb-2"
          >
            <button
              (click)="generate()"
              cssClass="e-btn e-info btn-p-zero e-small mr-2"
              ejs-button
              type="button"
            >
              vygenerovat nové heslo
            </button>
            <ejs-textbox
              *ngIf="currGeneratedPass"
              floatLabelType="Auto"
              id="pswdPlainView"
              value="{{ currGeneratedPass }}"
            ></ejs-textbox>
          </div>
        </div>
        <div class="row e-card" formArrayName="userAssignations">
          <div class="col-12 e-card-header justify-content-between">
            <span><strong>Pozice zaměstnance:</strong></span>
            <button
              (click)="addAssignation()"
              cssClass="e-btn e-success btn-p-zero e-small float-right"
              ejs-button
              type="button"
            >
                            <span
                              *ngIf="loadingUser === true"
                              aria-hidden="true"
                              class="spinner-grow spinner-grow-sm"
                              role="status"
                            ></span>
              <span *ngIf="loadingUser === false"
              >Přidat pozici</span
              >
              <span *ngIf="loadingUser === true"
              >Provádím...</span
              >
            </button>
          </div>
          <div
            *ngFor="
                            let assignation of assignationsformGroup.controls;
                            let i = index
                        "
            class="col-12 e-card-content"
          >
            <div [formGroupName]="i" class="row p-0 m-0">
              <div class="form-group col-12 col-md-6">
                <label
                  [ngClass]="
                                        getAssignationFormGroup(i).controls[
                                            'department_id'
                                        ].valid
                                            ? 'text-success'
                                            : getAssignationFormGroup(i)
                                                  .controls['department_id']
                                                  .invalid &&
                                              (getAssignationFormGroup(i)
                                                  .controls['department_id']
                                                  .dirty ||
                                                  getAssignationFormGroup(i)
                                                      .controls['department_id']
                                                      .touched)
                                            ? 'text-danger'
                                            : 'text-muted'
                                    "
                >* Oddělení:</label
                >
                <ejs-dropdownlist
                  #department
                  (filtering)="onFilteringDepartments($event)"
                  [allowFiltering]="true"
                  [cssClass]="
                                        (getAssignationFormGroup(i).controls[
                                            'department_id'
                                        ].dirty ||
                                            getAssignationFormGroup(i).controls[
                                                'department_id'
                                            ].touched) &&
                                        getAssignationFormGroup(i).controls[
                                            'department_id'
                                        ].invalid
                                            ? 'e-error'
                                            : (getAssignationFormGroup(i)
                                                  .controls['department_id']
                                                  .dirty ||
                                                  getAssignationFormGroup(i)
                                                      .controls['department_id']
                                                      .touched) &&
                                              getAssignationFormGroup(i)
                                                  .controls['department_id']
                                                  .valid
                                            ? 'e-success'
                                            : ''
                                    "
                  [dataSource]="departmentSelect"
                  [fields]="fields"
                  [filterBarPlaceholder]="
                                        'Vyhledat oddělení...'
                                    "
                  [ignoreAccent]="true"
                  [placeholder]="'Vyberte oddělení'"
                  [popupHeight]="height"
                  formControlName="department_id"
                  id="department_id-{{ i }}"
                  sortOrder="Ascending"
                ></ejs-dropdownlist>
                <span
                  *ngIf="
                                        getAssignationFormGroup(i).controls[
                                            'department_id'
                                        ].touched &&
                                        getAssignationFormGroup(i).controls[
                                            'department_id'
                                        ].hasError('required')
                                    "
                  class="text-danger"
                >Vyberte jedno z oddělení</span
                >
              </div>
              <div class="form-group col-12 col-md-6">
                <label
                  [ngClass]="
                                        getAssignationFormGroup(i).controls[
                                            'role_id'
                                        ].valid
                                            ? 'text-success'
                                            : getAssignationFormGroup(i)
                                                  .controls['role_id']
                                                  .invalid &&
                                              (getAssignationFormGroup(i)
                                                  .controls['role_id'].dirty ||
                                                  getAssignationFormGroup(i)
                                                      .controls['role_id']
                                                      .touched)
                                            ? 'text-danger'
                                            : 'text-muted'
                                    "
                >* Pozice:</label
                >
                <ejs-dropdownlist
                  #role
                  (filtering)="onFilteringRoles($event)"
                  [allowFiltering]="true"
                  [cssClass]="
                                        (getAssignationFormGroup(i).controls[
                                            'role_id'
                                        ].dirty ||
                                            getAssignationFormGroup(i).controls[
                                                'role_id'
                                            ].touched) &&
                                        getAssignationFormGroup(i).controls[
                                            'role_id'
                                        ].invalid
                                            ? 'e-error'
                                            : (getAssignationFormGroup(i)
                                                  .controls['role_id'].dirty ||
                                                  getAssignationFormGroup(i)
                                                      .controls['role_id']
                                                      .touched) &&
                                              getAssignationFormGroup(i)
                                                  .controls['role_id'].valid
                                            ? 'e-success'
                                            : ''
                                    "
                  [dataSource]="roleSelect"
                  [fields]="fields"
                  [filterBarPlaceholder]="
                                        'Vyhledat pozici...'
                                    "
                  [ignoreAccent]="true"
                  [placeholder]="'Vyberte pozici'"
                  [popupHeight]="height"
                  formControlName="role_id"
                  id="role_id-{{ i }}"
                  sortOrder="Ascending"
                ></ejs-dropdownlist>
                <span
                  *ngIf="
                                        getAssignationFormGroup(i).controls[
                                            'role_id'
                                        ].touched &&
                                        getAssignationFormGroup(i).controls[
                                            'role_id'
                                        ].hasError('required')
                                    "
                  class="text-danger"
                >Vyberte jednu z pozic</span
                >
              </div>
              <div class="form-group col-12 text-right">
                <button
                  (click)="removeAssignation(i)"
                  cssClass="e-btn e-danger btn-p-zero e-small"
                  ejs-button
                >
                  Odebrat
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
    <ng-template #footerTemplate>
      <div class="d-flex justify-content-end">
                <span
                  *ngIf="userForm.invalid"
                  class="e-badge e-badge-warning p-2 size_2"
                  role="alert"
                >
                    <strong>Formulář je špatně vyplněný</strong>
                </span>
        <span
          *ngIf="userForm.valid"
          class="e-badge e-badge-success p-2 size_2"
          role="alert"
        >
                    <strong>Formulář je správně vyplněný</strong>
                </span>
        <button
          (click)="onSubmit()"
          [disabled]="loadingUser || userForm.invalid || !isChecked"
          cssClass="e-btn e-success btn-p-zero e-small mr-1"
          ejs-button
          type="submit"
        >
                    <span
                      *ngIf="loadingUser === true"
                      aria-hidden="true"
                      class="spinner-grow spinner-grow-sm"
                      role="status"
                    ></span>
          <span *ngIf="loadingUser === false">ULOŽIT</span>
          <span *ngIf="loadingUser === true">Provádím...</span>
        </button>
        <button
          (click)="formDialogObj.hide()"
          cssClass="e-btn e-danger e-outline btn-p-zero e-small"
          ejs-button
          type="button"
        >
          ZRUŠIT
        </button>
      </div>
    </ng-template>
  </form>
</ejs-dialog>
