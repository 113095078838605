import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'urlencode',
})
export class UrlencodePipe implements PipeTransform {
    transform(value: string, encode: boolean): string {
        return encode ? encodeURI(value) : decodeURI(value);
    }
}
