<ejs-dialog
  #compareDialog
  (close)="changeDialogState(false)"
  [isModal]="true"
  [showCloseIcon]="true"
  [visible]="isVisible"
  [width]="'85%'"
  id="compareDialog"
>
  <ng-template #content>
    <div class="dialogContent">
      <div class="row">
        <div class="col-12 mb-3">
          <table
            *ngIf="netPackages && netPackages.length > 0"
            class="w-100"
          >
            <tr>
              <td
                *ngFor="let netPackage of netPackages"
                style="vertical-align: top"
              >
                <div
                  [ngStyle]="{
                                        border:
                                            idNetPackage &&
                                            idNetPackage === netPackage.id
                                                ? '2px solid red'
                                                : 'none'
                                    }"
                  class="e-card"
                >
                  <div
                    [ngClass]="
                                            idNetPackage &&
                                            idNetPackage === netPackage.id
                                                ? 'yellow'
                                                : 'light-green text-white'
                                        "
                    class="e-card-header p-2"
                  >
                    <h5 class="e-card-title">
                      {{ netPackage.name }}
                    </h5>
                  </div>
                  <div class="e-card-content text-center">
                                        <span
                                        >agregace:&nbsp;{{
                                            netPackage.aggregation
                                          }}</span
                                        >
                    <br />
                    <span class="e-card-separator"></span>
                    <br />
                    <span
                    >sleva:&nbsp;{{
                        netPackage.discount
                          ? netPackage.discount
                            .discount
                          :0
                      }}
                      % z ceny</span
                    >
                    <br />
                    <span class="e-card-separator"></span>
                    <br />
                    <span
                    >je rozšířením:&nbsp;
                                            <span
                                              *ngIf="netPackage.extension"
                                              class="e-badge e-badge-success"
                                            >ANO</span
                                            >
                                            <span
                                              *ngIf="!netPackage.extension"
                                              class="e-badge e-badge-danger"
                                            >NE</span
                                            >
                                        </span>
                    <br />
                    <span class="e-card-separator"></span>
                    <br />
                    <span
                    >doporučeno:&nbsp;
                                            <span
                                              *ngIf="netPackage.recommended"
                                              class="e-badge e-badge-success"
                                            >ANO</span
                                            >
                                            <span
                                              *ngIf="!netPackage.recommended"
                                              class="e-badge e-badge-danger"
                                            >NE</span
                                            >
                                        </span>
                    <br />
                    <span class="e-card-separator"></span>
                    <br />
                    <span
                    >rychlost down:&nbsp;{{
                        netPackage.speed_down
                      }}
                      mbps</span
                    >
                    <br />
                    <span class="e-card-separator"></span>
                    <br />
                    <span
                    >rychlost up:&nbsp;{{
                        netPackage.speed_up
                      }}
                      mbps</span
                    >
                    <br />
                    <span class="e-card-separator"></span>
                    <br />
                    <span
                    >typ přenosu:&nbsp;{{
                        netPackage.transfer.name
                      }}</span
                    >
                    <br />
                    <span class="e-card-separator"></span>
                    <br />
                    <span
                    >celkem možných rozšíření:&nbsp;{{
                        netPackage.ext_services.length
                      }}</span
                    >
                    <br />
                    <span class="e-card-separator"></span>
                    <br />
                    <span
                    >celkem bonusů:&nbsp;{{
                        netPackage.net_bonuses.length
                      }}</span
                    >
                  </div>
                  <div class="e-card-actions">
                    <div class="d-flex justify-content-end">
                                            <span class="text-muted"
                                            >{{
                                                netPackage.price
                                                  | number: '1.2-2'
                                              }},- CZK / měsíčně</span
                                            >
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </table>
          <table
            *ngIf="tvPackages && tvPackages.length > 0"
            class="w-100"
          >
            <tr>
              <ng-container *ngFor="let tvPackage of tvPackages">
                <td
                  *ngIf="!tvPackage.extension"
                  style="vertical-align: top"
                >
                  <div
                    [ngStyle]="{
                                            border:
                                                idTvPackage &&
                                                idTvPackage === tvPackage.id
                                                    ? '2px solid red'
                                                    : 'none'
                                        }"
                    class="e-card"
                  >
                    <div
                      [ngClass]="
                                                idTvPackage &&
                                                idTvPackage === tvPackage.id
                                                    ? 'yellow'
                                                    : 'deep-purple lighten-2 text-white'
                                            "
                      class="e-card-header p-2"
                    >
                      <h5 class="e-card-title">
                        {{ tvPackage.name }}
                      </h5>
                    </div>
                    <div class="e-card-content text-center">
                                            <span
                                              *ngIf="
                                                    tvPackage &&
                                                    tvPackage.tv_channels
                                                "
                                            >Celkem programů:&nbsp;{{
                                                tvPackage.tv_channels.length
                                              }}</span
                                            >
                      <div class="e-card-separator"></div>
                      <div
                        style="
                                                    height: 200px;
                                                    overflow-y: auto;
                                                "
                      >
                        <table class="w-100">
                          <tr
                            *ngFor="
                                                            let channel of tvPackage.tv_channels
                                                        "
                          >
                            <td>{{
                                channel.name
                              }}
                            </td>
                            <td>
                              <small>{{
                                  channel.theme
                                    .name
                                }}</small>
                            </td>
                            <td class="text-center">
                                                            <span
                                                              [ngClass]="
                                                                    channel.app_available
                                                                        ? 'e-badge-success'
                                                                        : 'e-badge-danger'
                                                                "
                                                              class="e-badge"
                                                            >APP</span
                                                            >
                            </td>
                            <td class="text-center">
                                                            <span
                                                              [ngClass]="
                                                                    channel.hd_resolution
                                                                        ? 'e-badge-success'
                                                                        : 'e-badge-danger'
                                                                "
                                                              class="e-badge"
                                                            >HD</span
                                                            >
                            </td>
                            <td class="text-right">
                              <img
                                alt="TV logo"
                                height="20px"
                                src="{{
                                                                    channel.logo
                                                                }}"
                                width="auto"
                              />
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                    <div class="e-card-actions">
                      <div
                        class="d-flex justify-content-end"
                      >
                                                <span class="text-muted"
                                                >{{
                                                    tvPackage.price
                                                      | number: '1.2-2'
                                                  }},- CZK / měsíčně</span
                                                >
                      </div>
                    </div>
                  </div>
                </td>
              </ng-container>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </ng-template>
  <ng-template #footerTemplate>
    <div class="row">
      <div class="col-12 pt-2">
        <button
          (click)="compareDialog.hide()"
          cssClass="e-btn e-danger e-outline btn-p-zero e-small"
          ejs-button
          type="button"
        >
          ZAVŘÍT
        </button>
      </div>
    </div>
  </ng-template>
</ejs-dialog>
