<ejs-dialog
  #formDialog
  (close)="isDirty = false; isChecked = false; changeDialogState(false)"
  [isModal]="true"
  [showCloseIcon]="true"
  [visible]="isVisible"
  [width]="'85%'"
  id="formDialog"
>
  <form [formGroup]="changePasswordForm">
    <ng-template #content>
      <div class="dialogContent">
        <div class="row">
          <div class="col-12 col-lg-4">
            <label for="changePasswordForm-old">Staré heslo:</label>
            <ejs-textbox
              [ngClass]="{
                                'e-error': submited && f.oldPassword.errors
                            }"
              floatLabelType="Auto"
              formControlName="oldPassword"
              id="changePasswordForm-old"
            ></ejs-textbox>
            <div
              *ngIf="submited && f.oldPassword.errors"
              class="invalid-feedback"
            >
              <div *ngIf="f.oldPassword.errors.required">
                Steré heslo je povinné
              </div>
            </div>
          </div>
          <div class="col-12 col-lg-4">
            <label for="changePasswordForm-new">Nové heslo:</label>
            <ejs-textbox
              [ngClass]="{
                                'e-error': submited && f.newPassword.errors
                            }"
              floatLabelType="Auto"
              formControlName="newPassword"
              id="changePasswordForm-new"
            ></ejs-textbox>
            <div
              *ngIf="submited && f.newPassword.errors"
              class="invalid-feedback"
            >
              <div *ngIf="f.newPassword.errors.required">
                Nové heslo je povinné
              </div>
            </div>
          </div>
          <div class="col-12 col-lg-4">
            <label for="changePasswordForm-repeat"
            >Nové heslo pro ověření:</label
            >
            <ejs-textbox
              [ngClass]="{
                                'e-error': submited && f.repeatPassword.errors
                            }"
              floatLabelType="Auto"
              formControlName="repeatPassword"
              id="changePasswordForm-repeat"
            ></ejs-textbox>
            <div
              *ngIf="submited && f.repeatPassword.errors"
              class="invalid-feedback"
            >
              <div *ngIf="f.repeatPassword.errors.required">
                Ověření hesla je povinné
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
    <ng-template #footerTemplate>
      <div class="d-flex justify-content-end">
                <span
                  *ngIf="changePasswordForm.invalid"
                  class="e-badge e-badge-warning p-2 size_2"
                  role="alert"
                >
                    <strong>Formulář je špatně vyplněný</strong>
                </span>
        <span
          *ngIf="changePasswordForm.valid"
          class="e-badge e-badge-success p-2 size_2"
          role="alert"
        >
                    <strong>Formulář je správně vyplněný</strong>
                </span>
      </div>
      <div class="row">
        <div class="col-12 pt-2">
          <button
            (click)="onSubmit()"
            [disabled]="
                            loadingUser ||
                            changePasswordForm.invalid ||
                            !isChecked
                        "
            cssClass="e-btn e-success btn-p-zero e-small mr-1"
            ejs-button
            type="submit"
          >
                        <span
                          *ngIf="loadingUser === true"
                          aria-hidden="true"
                          class="spinner-grow spinner-grow-sm"
                          role="status"
                        ></span>
            <span *ngIf="loadingUser === false">ULOŽIT</span>
            <span *ngIf="loadingUser === true">Provádím...</span>
          </button>
          <button
            (click)="formDialogObj.hide()"
            cssClass="e-btn e-danger e-outline btn-p-zero e-small"
            ejs-button
            type="button"
          >
            ZRUŠIT
          </button>
        </div>
      </div>
    </ng-template>
  </form>
</ejs-dialog>
