<div #container class='root-container'></div>
<ejs-dialog
  #formTaskDialog
  (close)="closeModal()"
  (open)="onCreateRte()"
  [isModal]="true"
  [showCloseIcon]="true"
  [target]='targetElement'
  [visible]="isVisible"
  [width]="'85%'"
  id="formDialog"
>
  <form [formGroup]="taskForm">
    <ng-template #content>
      <div class="dialogContent">
        <div class="row">
          <div class="form-group col-12 mb-3">
            <label
              [ngClass]="
                                f.taskSolvers.valid &&
                                (f.taskSolvers.dirty || f.taskSolvers.touched)
                                    ? 'text-success'
                                    : f.taskSolvers.invalid &&
                                      (f.taskSolvers.dirty ||
                                          f.taskSolvers.touched)
                                    ? 'text-danger'
                                    : 'text-muted'
                            "
              for="taskForm-solvers"
            >* Delegovat pro:</label
            >
            <ejs-multiselect
              #user
              (filtering)="onFilteringUsers($event)"
              [allowFiltering]="true"
              [cssClass]="
                                f.taskSolvers.touched && f.taskSolvers.invalid
                                    ? 'e-error'
                                    : f.taskSolvers.touched &&
                                      f.taskSolvers.valid
                                    ? 'e-success'
                                    : ''
                            "
              [dataSource]="usersSelect"
              [fields]="fields"
              [filterBarPlaceholder]="'Vyhledat uživatele...'"
              [ignoreAccent]="true"
              [mode]="'CheckBox'"
              [placeholder]="'Vyberte uživatele'"
              [popupHeight]="height"
              [selectAllText]="'Všechna uživatele'"
              formControlName="taskSolvers"
              id="taskForm-solvers"
              showSelectAll="true"
              sortOrder="Ascending"
            ></ejs-multiselect>
            <small
              *ngIf="
                                f.taskSolvers.touched &&
                                f.taskSolvers.errors &&
                                f.taskSolvers.errors.required
                            "
              class="text-danger"
            >Vyberte alespoň jednoho řešitele úkolu</small
            >
          </div>
        </div>
        <div class="row">
          <div class="form-group col-12 mb-3">
            <label
              [ngClass]="
                                f.taskSubject.valid &&
                                (f.taskSubject.dirty || f.taskSubject.touched)
                                    ? 'text-success'
                                    : f.taskSubject.invalid &&
                                      (f.taskSubject.dirty ||
                                          f.taskSubject.touched)
                                    ? 'text-danger'
                                    : 'text-muted'
                            "
              for="taskForm-subject"
            >* Název:</label
            >
            <ejs-textbox
              [ngClass]="
                                f.taskSubject.touched && f.taskSubject.invalid
                                    ? 'e-error'
                                    : f.taskSubject.touched &&
                                      f.taskSubject.valid
                                    ? 'e-success'
                                    : ''
                            "
              floatLabelType="Auto"
              formControlName="taskSubject"
              id="taskForm-subject"
              required
            ></ejs-textbox>
            <small
              *ngIf="
                                f.taskSubject.touched &&
                                f.taskSubject.errors &&
                                f.taskSubject.errors.required
                            "
              class="text-danger"
            >Název úkolu je povinný</small
            >
          </div>
        </div>
        <div class="row">
          <div class="form-group col-12 col-md-6 mb-3">
            <label
              [ngClass]="
                                f.taskCategory.valid
                                    ? 'text-success'
                                    : f.taskCategory.invalid &&
                                      (f.taskCategory.dirty ||
                                          f.taskCategory.touched)
                                    ? 'text-danger'
                                    : 'text-muted'
                            "
              for="taskForm-category"
            >* Kategorie úkolu:</label
            >
            <ejs-dropdownlist
              #category
              (filtering)="onFilteringCategories($event)"
              [allowFiltering]="true"
              [cssClass]="
                                f.taskCategory.touched && f.taskCategory.invalid
                                    ? 'e-error'
                                    : f.taskCategory.touched &&
                                      f.taskCategory.valid
                                    ? 'e-success'
                                    : ''
                            "
              [dataSource]="categorySelect"
              [fields]="fields"
              [filterBarPlaceholder]="'Vyhledat kategorii...'"
              [ignoreAccent]="true"
              [placeholder]="'Vyberte kategorii'"
              [popupHeight]="height"
              formControlName="taskCategory"
              id="taskForm-category"
              sortOrder="Ascending"
            ></ejs-dropdownlist>
            <small
              *ngIf="
                                (f.taskCategory.dirty ||
                                    f.taskCategory.touched) &&
                                f.taskCategory.errors &&
                                f.taskCategory.errors.required
                            "
              class="text-danger"
            >Vyberte alespoň jednu kategorii úkolu</small
            >
          </div>
          <div class="form-group col-12 col-md-6 mb-3">
            <tag-input
              [displayBy]="'name'"
              [identifyBy]="'id'"
              [onlyFromAutocomplete]="false"
              formControlName="tags"
            >
              <tag-input-dropdown
                [autocompleteItems]="tagsAutocomplete"
                [displayBy]="'name'"
                [identifyBy]="'id'"
              >
              </tag-input-dropdown>
            </tag-input>
          </div>
        </div>
        <div class="row">
          <div class="form-group col-12 mb-3">
            <label
              [ngClass]="
                                f.taskMaintask.valid &&
                                (f.taskMaintask.dirty || f.taskMaintask.touched)
                                    ? 'text-success active'
                                    : f.taskMaintask.invalid &&
                                      (f.taskMaintask.dirty ||
                                          f.taskMaintask.touched)
                                    ? 'text-danger active'
                                    : 'text-muted active'
                            "
              for="taskForm-maintask"
            >* Zadání:</label
            >
            <ejs-richtexteditor
              #mainTask
              (actionBegin)="handleFullScreen($event)"
              (actionComplete)="actionCompleteHandler($event)"
              [enablePersistence]="false"
              [enableResize]="true"
              [fontFamily]="fontFamily"
              [height]="200"
              [insertImageSettings]="insertImageSettings"
              [maxLength]="maxLength"
              [ngClass]="
                                (f.taskMaintask.dirty ||
                                    f.taskMaintask.touched) &&
                                f.taskMaintask.invalid
                                    ? 'e-error'
                                    : (f.taskMaintask.dirty ||
                                          f.taskMaintask.touched) &&
                                      f.taskMaintask.valid
                                    ? 'e-success'
                                    : ''
                            "
              [pasteCleanupSettings]="pasteCleanupSettings"
              [quickToolbarSettings]="quickToolbarSettings"
              [showCharCount]="true"
              [toolbarSettings]="tools"
              formControlName="taskMaintask"
              id="taskForm-maintask"
              locale="cs"
              placeholder="Začněte psát zadání..."
            >
            </ejs-richtexteditor>
            <small
              *ngIf="
                                (f.taskMaintask.dirty ||
                                    f.taskMaintask.touched) &&
                                f.taskMaintask.errors &&
                                f.taskMaintask.errors.required
                            "
              class="text-danger"
            >Zadání úkolu je povinné</small
            >
          </div>
        </div>
        <div class="row">
          <div class="form-group col-12 col-md-6 mb-3">
            <label
              [ngClass]="
                                f.taskStartDeadlineDate.valid &&
                                (f.taskStartDeadlineDate.dirty ||
                                    f.taskStartDeadlineDate.touched)
                                    ? 'text-success'
                                    : f.taskStartDeadlineDate.invalid &&
                                      (f.taskStartDeadlineDate.dirty ||
                                          f.taskStartDeadlineDate.touched)
                                    ? 'text-danger'
                                    : 'text-muted'
                            "
              for="taskStartDeadlineDate"
            >* Začátek termínu:</label
            >
            <ejs-datetimepicker
              [cssClass]="
                                f.taskStartDeadlineDate.touched &&
                                f.taskStartDeadlineDate.invalid
                                    ? 'e-error'
                                    : f.taskStartDeadlineDate.touched &&
                                      f.taskStartDeadlineDate.valid
                                    ? 'e-success'
                                    : ''
                            "
              [enabled]="true"
              [firstDayOfWeek]="1"
              [min]="today"
              [value]="today"
              floatLabelType="Always"
              formControlName="taskStartDeadlineDate"
              id="taskStartDeadlineDate"
            ></ejs-datetimepicker>
            <small
              *ngIf="
                                f.taskStartDeadlineDate.touched &&
                                f.taskStartDeadlineDate.errors &&
                                f.taskStartDeadlineDate.errors.required
                            "
              class="text-danger"
            >Datum začátku termínu úkolu je povinné</small
            ><br />
            <small
              *ngIf="
                                f.taskStartDeadlineDate.touched &&
                                f.taskStartDeadlineDate.errors &&
                                f.taskStartDeadlineDate.errors.greaterThan
                            "
              class="text-danger"
            >Datum začátku termínu je větší než datum
              konce</small
            >
          </div>
          <div class="form-group col-12 col-md-6 mb-3">
            <label
              [ngClass]="
                                f.taskDeadlineDate.valid &&
                                (f.taskDeadlineDate.dirty ||
                                    f.taskDeadlineDate.touched)
                                    ? 'text-success'
                                    : f.taskDeadlineDate.invalid &&
                                      (f.taskDeadlineDate.dirty ||
                                          f.taskDeadlineDate.touched)
                                    ? 'text-danger'
                                    : 'text-muted'
                            "
              for="taskDeadlineDate"
            >* Konec termínu:
            </label>
            <ejs-datetimepicker
              [cssClass]="
                                f.taskDeadlineDate.touched &&
                                f.taskDeadlineDate.invalid
                                    ? 'e-error'
                                    : f.taskDeadlineDate.touched &&
                                      f.taskDeadlineDate.valid
                                    ? 'e-success'
                                    : ''
                            "
              [enabled]="true"
              [firstDayOfWeek]="1"
              [min]="f.taskStartDeadlineDate.value"
              floatLabelType="Always"
              formControlName="taskDeadlineDate"
              id="taskDeadlineDate"
            >
            </ejs-datetimepicker>
            <small
              *ngIf="
                                f.taskDeadlineDate.touched &&
                                f.taskDeadlineDate.errors &&
                                f.taskDeadlineDate.errors.required
                            "
              class="text-danger"
            >Datum konce termínu úkolu je povinné</small
            ><br />
            <small
              *ngIf="
                                f.taskDeadlineDate.touched &&
                                f.taskDeadlineDate.errors &&
                                f.taskDeadlineDate.errors.lessThan
                            "
              class="text-danger"
            >Datum konce termínu je menší než datum
              začátku</small
            >
          </div>
        </div>
      </div>
    </ng-template>
    <ng-template #footerTemplate>
      <div class="d-flex justify-content-end">
                <span
                  *ngIf="taskForm.invalid"
                  class="e-badge e-badge-warning p-2 size_2"
                  role="alert"
                >
                    <strong>Formulář je špatně vyplněný</strong>
                </span>
        <span
          *ngIf="taskForm.valid"
          class="e-badge e-badge-success p-2 size_2"
          role="alert"
        >
                    <strong>Formulář je správně vyplněný</strong>
                </span>
      </div>
      <div class="row">
        <div class="col-12 pt-2">
          <button
            (click)="onSubmit()"
            [disabled]="
                            taskLoading || taskForm.invalid || !isChecked
                        "
            cssClass="e-btn e-success btn-p-zero e-small mr-1"
            ejs-button
            type="submit"
          >
                        <span
                          *ngIf="taskLoading === true"
                          aria-hidden="true"
                          class="spinner-grow spinner-grow-sm"
                          role="status"
                        ></span>
            <span *ngIf="taskLoading === false">ULOŽIT</span>
            <span *ngIf="taskLoading === true">Provádím...</span>
          </button>
          <button
            (click)="closeModal()"
            cssClass="e-btn e-danger e-outline btn-p-zero e-small"
            ejs-button
            type="button"
          >
            ZRUŠIT
          </button>
        </div>
      </div>
    </ng-template>
  </form>
</ejs-dialog>
