import {
    AfterViewChecked,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnInit,
    ViewChild,
} from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {Observable, Observer} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthenticationService} from '@src/app/_services/authentication.service';
import {ExcelService} from '@src/app/_services/excel.service';
import {DataService} from '@src/app/_services/data.service';
import {DataNetbaseService} from '@src/app/_services/data-netbase.service';
import {InstallInput, ServicesService} from '@src/app/features/services/services.service';
import {PermissionService} from '@src/app/_services/permission.service';
import {MessageService} from '@src/app/_services/message.service';
import {InstallTypes} from '@src/app/_models/services/install-types';
import {ButtonPropsModel, DialogComponent} from '@syncfusion/ej2-angular-popups';
import {User} from '@src/app/_models/user/user';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {
    ColumnModel,
    DataResult,
    EditSettingsModel,
    ExcelExportProperties,
    FilterSettingsModel,
    GridComponent,
    GroupSettingsModel,
    PageSettingsModel,
    RowDataBoundEventArgs,
    SelectionSettingsModel,
    ToolbarItems,
} from '@syncfusion/ej2-angular-grids';
import {TextBoxComponent} from '@syncfusion/ej2-angular-inputs';
import {ChangeEventArgs, DropDownListComponent} from '@syncfusion/ej2-angular-dropdowns';
import {ClickEventArgs} from '@syncfusion/ej2-angular-navigations';

@UntilDestroy()
@Component({
    selector: 'app-services-installation-types',
    templateUrl: './services-installation-types.component.html',
    styleUrls: ['./services-installation-types.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ServicesInstallationTypesComponent implements OnInit, AfterViewChecked {
    // Grid
    currencyFormat: {format: string} = {format: '#,###.00\',- CZK\''};

    dateFormat: string;

    toolbar: ToolbarItems[] | object;

    data: object;

    fields: object = {text: 'label', value: 'value'};

    filterSettings: FilterSettingsModel;

    filterCheckboxSettings: FilterSettingsModel;

    selectionSettings: SelectionSettingsModel;

    editSettings: EditSettingsModel;

    groupOptions: GroupSettingsModel;

    pageOptions: PageSettingsModel;

    expendituresColumnsPrice: ColumnModel[];

    expendituresColumnsItems: ColumnModel[];

    filterDate: object;

    sortOptions: object;

    requiredRules: object;

    exportCurrentPage = false;

    // Dialogs
    renderInstallTypeFormDialog = false;

    buttonsDelete: ButtonPropsModel[] = [
        {
            click: (): void => {
                this.deleteInstallationType();
            },
            isFlat: false,
            buttonModel: {
                content: 'OK',
                cssClass: 'e-success e-outline',
            },
        },
        {
            click: (): void => {
                this.confirmationDelete.hide();
            },
            buttonModel: {
                content: 'ZRUŠIT',
                cssClass: 'e-danger e-outline',
            },
        },
    ];

    buttonsCancel: ButtonPropsModel[] = [
        {
            click: (): void => {
                this.confirmationCancel.hide();
            },
            buttonModel: {
                isPrimary: true,
                content: 'OK',
            },
        },
    ];

    // Dropdowns
    height = '240px';

    categoryFilterData: Array<string> = [];

    // Forms
    isCreate = false;

    isUpdate = false;

    isCopy = false;

    // Variables
    currentUser: User | null;

    installations: InstallTypes[] | undefined = [];

    clickedRow: InstallTypes[] = [];

    clickedRow$ = new Observable<InstallTypes[]>((observer: Observer<InstallTypes[]>) => {
        observer.next(this.clickedRow);
    });

    // Loaders
    tableLoad = true;

    // Grid
    @ViewChild('installTypesGrid') gridInstallTypes: GridComponent;

    @ViewChild('searchtext') searchtextObj: TextBoxComponent;

    // Dialogs
    @ViewChild('confirmationDelete') confirmationDelete: DialogComponent;

    @ViewChild('confirmationCancel') confirmationCancel: DialogComponent;

    // Dropdowns
    @ViewChild('viewFilter') viewFilterInstance: DropDownListComponent;

    constructor(
        private readonly router: Router,
        private readonly route: ActivatedRoute,
        private readonly ref: ChangeDetectorRef,
        private readonly formBuilder: FormBuilder,
        private readonly authenticationService: AuthenticationService,
        private readonly excelService: ExcelService,
        private readonly dataService: DataService,
        private readonly dataNetbaseService: DataNetbaseService,
        private readonly servicesService: ServicesService,
        private readonly permissionService: PermissionService,
        private readonly messages: MessageService,
    ) {
        this.currentUser = this.authenticationService.currentUserValue;
    }

    ngOnInit(): void {
        this.filterSettings = {
            type: 'Menu',
            showFilterBarStatus: true,
            ignoreAccent: true,
        };

        this.filterCheckboxSettings = {
            type: 'CheckBox',
            showFilterBarStatus: true,
            ignoreAccent: true,
        };

        this.filterSettings = {
            type: 'Excel',
            showFilterBarOperator: true,
            showFilterBarStatus: true,
            ignoreAccent: true,
        };

        this.selectionSettings = {
            persistSelection: true,
            type: 'Multiple',
            checkboxOnly: true,
        };

        this.toolbar = [
            {
                text: 'Občerstvit data',
                tooltipText: 'Občerstvit data',
                prefixIcon: 'e-refresh',
                id: 'customRefresh',
            },
            'Print',
            'ExcelExport',
            'Add',
            'Edit',
            {
                text: 'Smazat',
                tooltipText: 'Smazat typ instalace',
                prefixIcon: 'e-remove',
                id: 'delete',
            },
            {
                text: 'Kopírovat',
                tooltipText: 'Kopírovat typ instalace',
                prefixIcon: 'e-copy',
                id: 'copy',
            },
        ];

        this.categoryFilterData = [
            'všechny typy instalace',
            'aktivní typy instalace',
            'neaktivní typy instalace',
        ];
        this.pageOptions = {pageSize: 50};
        this.dateFormat = 'dd.MM.yyyy HH:mm';
        this.filterDate = {type: 'Menu', params: {format: this.dateFormat}};
        this.groupOptions = {
            showGroupedColumn: true,
            disablePageWiseAggregates: false,
        };

        this.editSettings = {
            showConfirmDialog: false,
            showDeleteConfirmDialog: false,
            allowEditing: true,
            allowAdding: true,
            allowDeleting: true,
            allowEditOnDblClick: false,
            mode: 'Dialog',
        };
        this.requiredRules = {required: true};

        this.dataNetbaseService.setInstallationTypesDataSource();
    }

    ngAfterViewChecked(): void {
        this.route.queryParamMap.pipe(untilDestroyed(this))
            .subscribe(params => {
                if (params.get('form') === 'addInstallType') {
                    this.isCreate = true;
                    this.renderInstallTypeFormDialog = true;
                    this.ref.markForCheck();
                }
            });
    }

    loadData(): DataResult[] {
        this.gridInstallTypes.clearRowSelection();

        console.info('NEW DATA BOUND');

        const elements: InstallInput[] = [];

        if (this.installations && this.installations.length > 0) {
            this.installations.map((install: InstallTypes) => {
                elements.push({
                    id: install.id,
                    name: install.name,
                    description: install.description,
                    inst_price: install.inst_price,
                    created_by: install.creator.id,
                    creator_str: install.creator.fullname,
                    creator_img: install.creator.personalphoto,
                    created_date: new Date(install.created_date),
                    updated_date: install.updated_date ? new Date(install.updated_date) : null,
                    deleted_date: install.deleted_date ? new Date(install.deleted_date) : null,
                });
            });
        }

        this.tableLoad = false;
        this.ref.markForCheck();

        return elements as DataResult[];
    }

    onCreated(): void {
        this.disableToolBarBtn();
    }

    setInitialGridFiltering(): void {
        this.gridInstallTypes.clearFiltering();
        // this.gridBonuses.filterByColumn('created_by', 'equal', this.currentUser.id, 'and', false, true);
        // this.viewFilterInstance.value = 'moje žádanky - všechny';
        this.ref.markForCheck();
    }

    onLoad(): void {
        const wrapper1 = document.getElementById('scroll_wrapper1_instTypes');
        const wrapper2 = document.getElementById('scroll_wrapper2_instTypes');

        if (wrapper1 && wrapper2) {
            wrapper1.onscroll = (): void => {
                wrapper2.scrollLeft = wrapper1.scrollLeft;
            };

            wrapper2.onscroll = (): void => {
                wrapper1.scrollLeft = wrapper2.scrollLeft;
            };
        }

        this.dataNetbaseService.installTypesSource.pipe(untilDestroyed(this))
            .subscribe(
                (installations: InstallTypes[] | undefined) => {
                    this.installations = installations;

                    if (this.installations && this.installations.length > 0) {
                        this.data = this.gridInstallTypes.dataSource = this.loadData();
                        this.sortOptions = {
                            columns: [
                                {field: 'created_date', direction: 'Descending'},
                                {field: 'id', direction: 'Descending'},
                            ],
                        };
                    }
                },
                error => {
                    console.error(error);
                    this.tableLoad = false;
                    this.ref.markForCheck();
                },
            );
    }

    search(): void {
        this.gridInstallTypes.search(this.searchtextObj.value);
    }

    onDataBound(): void {
        this.rowSelected();

        const width = document.getElementById('installTypesGrid_content_table')?.offsetWidth;
        const parent1 = document.getElementById('scroll_div_instTypes');
        const parent2 = document.getElementById('grid_parent_instTypes');

        if (parent1 && parent2 && width) {
            parent1.style.width = `${width}px`;
            parent2.style.width = `${width}px`;
        }

        this.gridInstallTypes.autoFitColumns();
        this.ref.markForCheck();
    }

    rowDataBound(args: RowDataBoundEventArgs): void {
        const data = args.data as InstallTypes;

        if (args.row && data.deleted_date) {
            args.row.getElementsByClassName('e-gridchkbox')[0].classList.add('disablecheckbox');
            args.row
                .getElementsByClassName('e-checkbox-wrapper')[0]
                .classList.add('disablecheckbox');
            args.row.classList.add('e-disabled');
        }

        if (
            this.currentUser &&
            args.row &&
            data.created_by !== this.currentUser.id &&
            !this.permissionService.checkUserISServiceAdmin(this.currentUser) &&
            !this.permissionService.checkUserISAdministrative(this.currentUser) &&
            !this.permissionService.checkUserISMarketingAdmin(this.currentUser)
        ) {
            args.row.getElementsByClassName('e-gridchkbox')[0].classList.add('disablecheckbox');
            args.row
                .getElementsByClassName('e-checkbox-wrapper')[0]
                .classList.add('disablecheckbox');
        }

        this.ref.markForCheck();
    }

    onStatusFilterChange(e: ChangeEventArgs): void {
        if (e.value === 'všechny typy instalace') {
            this.gridInstallTypes.clearFiltering();
        } else if (e.value === 'aktivní typy instalace') {
            this.gridInstallTypes.clearFiltering();
            this.gridInstallTypes.filterByColumn('deleted_date', 'equal', null, 'and', false, true);
        } else if (e.value === 'neaktivní typy instalace') {
            this.gridInstallTypes.clearFiltering();
            this.gridInstallTypes.filterByColumn(
                'deleted_date',
                'notequal',
                null,
                'and',
                false,
                true,
            );
        }
    }

    cancelBtnClick(): void {
        this.searchtextObj.value = '';
        this.gridInstallTypes.searchSettings.key = '';
    }

    toolbarClick(args: ClickEventArgs): void {
        const xlsProp: ExcelExportProperties = {
            fileName: 'js_installationsTypes_export.xlsx',
            enableFilter: true,
            exportType: this.exportCurrentPage ? 'CurrentPage' : 'AllPages',
        };

        if (args.item.id === 'installTypesGrid_excelexport') {
            void this.gridInstallTypes.excelExport(xlsProp);
        } else if (args.item.id === 'delete') {
            if (this.gridInstallTypes.getSelectedRecords().length !== 0) {
                this.confirmationDelete.content = `Potvrďte smazání ${this.clickedRow.length} typů instalace`;
                this.confirmationDelete.show();
            } else {
                this.confirmationCancel.content = 'Musíte vybrat alespoň jeden typ instalace';
                this.confirmationCancel.show();
            }
        } else if (args.item.id === 'copy') {
            if (this.gridInstallTypes.getSelectedRecords().length !== 0) {
                this.isUpdate = false;
                this.isCreate = false;
                this.isCopy = true;
                this.renderInstallTypeFormDialog = true;
                this.ref.markForCheck();
            } else {
                this.confirmationCancel.content = 'Musíte vybrat alespoň jeden typ instalace';
                this.confirmationCancel.show();
            }
        } else if (args.item.id === 'customRefresh') {
            this.tableLoad = true;
            console.info('refresh DATA!');
            this.ref.markForCheck();
            this.dataNetbaseService.clearInstallTypesCache();
            this.dataNetbaseService.setInstallationTypesDataSource();
        }
    }

    enableToolbarBtn(): void {
        this.gridInstallTypes.toolbarModule.enableItems(
            ['installTypesGrid_edit', 'delete', 'copy'],
            true,
        );
    }

    disableToolBarBtn(): void {
        this.gridInstallTypes.toolbarModule.enableItems(
            ['installTypesGrid_edit', 'delete', 'copy'],
            false,
        );
    }

    rowSelected(): void {
        this.clickedRow = [];

        const selectedrecords: InstallTypes[] =
            this.gridInstallTypes.getSelectedRecords() as InstallTypes[];

        if (selectedrecords.length > 0) {
            selectedrecords.map((row: InstallTypes) => {
                const tempData: InstallTypes | undefined = this.installations?.find(
                    x => x.id === row.id,
                );

                if (tempData) {
                    this.clickedRow.push(tempData);
                }

                this.ref.markForCheck();
            });

            this.clickedRow$ = new Observable<InstallTypes[]>(
                (observer: Observer<InstallTypes[]>) => {
                    observer.next(this.clickedRow);
                },
            );
            this.enableToolbarBtn();
        } else {
            this.disableToolBarBtn();
            this.clickedRow$ = new Observable<InstallTypes[]>(
                (observer: Observer<InstallTypes[]>) => {
                    observer.next(this.clickedRow);
                },
            );
        }
    }

    actionBegin(args): void {
        if (args.requestType === 'add' || args.requestType === 'beginEdit') {
            args.cancel = true;

            if (args.requestType === 'beginEdit') {
                this.isUpdate = true;
                this.isCreate = false;
                this.isCopy = false;
            }

            if (args.requestType === 'add') {
                this.isCreate = true;
                this.isUpdate = false;
                this.isCopy = false;
            }

            this.renderInstallTypeFormDialog = true;
            this.ref.markForCheck();
        }
    }

    actionComplete(args): void {
        if (args.requestType === 'beginEdit' || args.requestType === 'add') {
            args.form.ej2_instances[0].rules = {};
        }
    }

    deleteInstallationType(): void {
        if (
            this.currentUser &&
            (this.permissionService.checkUserISServiceAdmin(this.currentUser) ||
                this.permissionService.checkUserISAdministrative(this.currentUser) ||
                this.permissionService.checkUserISMarketingAdmin(this.currentUser))
        ) {
            this.tableLoad = true;
            this.clickedRow[0].deleted_by = this.currentUser.id;
            this.servicesService
                .deleteInstallType(this.clickedRow[0])
                ?.pipe(untilDestroyed(this))
                .subscribe(
                    (type: InstallTypes) => {
                        const body = `Typ instalace #${type.id}`;
                        const options = {progressBar: true, timeOut: 5000};

                        this.messages.showSuccess('Typ instalace úspěšně smazána', body, options);
                        this.dataNetbaseService.setInstallationTypesDataSource();
                        this.confirmationDelete.hide();
                        this.ref.markForCheck();
                    },
                    error => {
                        console.error(error);

                        const body = 'Zkuste to později ...';
                        const options = {progressBar: true, timeOut: 5000};

                        this.messages.showError('Chyba během mazání typu instalace', body, options);
                        this.tableLoad = false;
                        this.confirmationDelete.hide();
                        this.ref.markForCheck();
                    },
                );
        } else {
            const body = 'Nemáte oprávnění provést tuto akci...';
            const options = {progressBar: true, timeOut: 5000};

            this.messages.showError('Nedostatečné oprávnění', body, options);
            this.ref.markForCheck();
        }
    }
}
