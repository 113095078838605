export class AcsUsers {
    id: number;

    address: string;

    // eslint-disable-next-line @typescript-eslint/naming-convention
    allow_approval_access: boolean;

    // eslint-disable-next-line @typescript-eslint/naming-convention
    allow_mobile_access: boolean;

    // eslint-disable-next-line @typescript-eslint/naming-convention
    allow_own_web_overview: boolean;

    archived: boolean;

    // eslint-disable-next-line @typescript-eslint/naming-convention
    archived_at: string;

    // eslint-disable-next-line @typescript-eslint/naming-convention
    authorization_group_id: number;

    // eslint-disable-next-line @typescript-eslint/naming-convention
    birth_number: string;

    // eslint-disable-next-line @typescript-eslint/naming-convention
    collect_gps: boolean;

    // eslint-disable-next-line @typescript-eslint/naming-convention
    created_at: string;

    // eslint-disable-next-line @typescript-eslint/naming-convention
    custom_link: string;

    // eslint-disable-next-line @typescript-eslint/naming-convention
    date_of_birth: string;

    deleted: boolean;

    email: string;

    // eslint-disable-next-line @typescript-eslint/naming-convention
    employed_since: string;

    // eslint-disable-next-line @typescript-eslint/naming-convention
    first_name: string;

    // eslint-disable-next-line @typescript-eslint/naming-convention
    fund_vacation: number;

    // eslint-disable-next-line @typescript-eslint/naming-convention
    group_id: number;

    // eslint-disable-next-line @typescript-eslint/naming-convention
    last_name: string;

    // eslint-disable-next-line @typescript-eslint/naming-convention
    personal_number: number;

    pin: number;

    // eslint-disable-next-line @typescript-eslint/naming-convention
    registration_type: number;

    // eslint-disable-next-line @typescript-eslint/naming-convention
    restore_password_token: string;

    // eslint-disable-next-line @typescript-eslint/naming-convention
    restore_password_token_expiry_at: string;

    role: string;

    // eslint-disable-next-line @typescript-eslint/naming-convention
    updated_at: string;

    // eslint-disable-next-line @typescript-eslint/naming-convention
    use_pin: boolean;
}
