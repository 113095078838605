import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    HostListener,
    Input,
    OnChanges,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import {ComponentCanDeactivate} from '@src/app/_guards/changes.guard';
import {AddressConnectedField} from '@src/app/_models/services/address-connected-field';
import {DialogComponent} from '@syncfusion/ej2-angular-popups';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {User} from '@src/app/_models/user/user';
import {DataService} from '@src/app/_services/data.service';
import {DataNetbaseService} from '@src/app/_services/data-netbase.service';
import {FieldInput, ServicesService} from '@src/app/features/services/services.service';
import {MessageService} from '@src/app/_services/message.service';
import {AuthenticationService} from '@src/app/_services/authentication.service';
import {Observable} from 'rxjs';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {Department} from '@src/app/_models/department/department';
import {Company} from '@src/app/_models/company/company';
import {NetPackages} from '@src/app/_models/services/net-packages';
import {TvServices} from '@src/app/_models/services/tv-services';
import {TechnologyTypes} from '@src/app/_models/services/technology-types';
import {UnitTypes} from '@src/app/_models/services/unit-types';
import {SpeedTypes} from '@src/app/_models/services/speed-types';
import {DealTypes} from '@src/app/_models/services/deal-types';
import {Discounts} from '@src/app/_models/services/discounts';
import {SpaceTypes} from '@src/app/_models/services/space-types';
import {TransferTypes} from '@src/app/_models/services/transfer-types';
import {Project} from '@src/app/_models/projects/project';
import {EmitType} from '@syncfusion/ej2-base';
import {
    DropDownListComponent,
    FilteringEventArgs,
    MultiSelectComponent,
} from '@syncfusion/ej2-angular-dropdowns';
import {Query} from '@syncfusion/ej2-data';
import moment from 'moment';
import {PermissionService} from '@src/app/_services/permission.service';
import {MomentInput} from 'moment/moment';

declare let smartform;

@UntilDestroy()
@Component({
    selector: 'app-field-form',
    templateUrl: './field-form.component.html',
    styleUrls: ['./field-form.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FieldFormComponent implements OnInit, OnChanges, ComponentCanDeactivate {
    // Dropdowns
    fieldFieldObj: object = {text: 'label', value: 'value'};

    height = '240px';

    departmentSelect: Array<{value: boolean | number | string; label: string}> = [];

    usersSelect: Array<{value: boolean | number | string; label: string}> = [];

    typeSelect: Array<{value: boolean | number | string; label: string}> = [];

    viewSelect: Array<{value: boolean | number | string; label: string}> = [];

    companySelect: Array<{value: boolean | number | string; label: string}> = [];

    technologySelect: Array<{value: boolean | number | string; label: string}> = [];

    unitSelect: Array<{value: boolean | number | string; label: string}> = [];

    dealSelect: Array<{value: boolean | number | string; label: string}> = [];

    discountSelect: Array<{value: boolean | number | string; label: string}> = [];

    transferSelect: Array<{value: boolean | number | string; label: string}> = [];

    spaceSelect: Array<{value: boolean | number | string; label: string}> = [];

    servicesNetSelect: Array<{value: boolean | number | string; label: string}> = [];

    servicesTvSelect: Array<{value: boolean | number | string; label: string}> = [];

    projectSelect: Array<{value: boolean | number | string; label: string}> = [];

    categorySelect: Array<{value: boolean | number | string; label: string}> = [];

    speedsSelect: Array<{value: boolean | number | string; label: string}> = [];

    // Forms
    fieldForm: FormGroup;

    isDirty = false;

    isChecked = false;

    submited = false;

    // Variables
    currentUser: User | null;

    fields: AddressConnectedField[] = [];

    validField: boolean | null = null;

    fieldAddress?: AddressConnectedField | boolean | null = null;

    // Loaders
    loadingField = false;

    @Input() field: AddressConnectedField | null = null;

    @Input() isCreate = false;

    @Input() isUpdate = false;

    @Input() isVisible = false;

    @Input() isCopy = false;

    @Output() readonly feildFormDialogState = new EventEmitter<boolean>();

    // Dialogs
    @ViewChild('formDialog') formDialogObj: DialogComponent;

    // Dropdowns
    // @ViewChild('creator') creatorObj: DropDownListComponent;
    @ViewChild('type') typeObj: DropDownListComponent;

    @ViewChild('deal_type') dealTypeObj: DropDownListComponent;

    @ViewChild('unit_type') unitTypeObj: DropDownListComponent;

    @ViewChild('provider') providerObj: DropDownListComponent;

    @ViewChild('discount') discountObj: DropDownListComponent;

    @ViewChild('flat_space') flatSpaceObj: DropDownListComponent;

    @ViewChild('speed_type') speedTypeObj: DropDownListComponent;

    @ViewChild('hardware') hardwareObj: DropDownListComponent;

    @ViewChild('technology') technologyObj: DropDownListComponent;

    @ViewChild('transfer') transferObj: DropDownListComponent;

    @ViewChild('project') projectObj: DropDownListComponent;

    @ViewChild('net_packages') netPackagesObj: MultiSelectComponent;

    @ViewChild('tv_services') tvServicesObj: MultiSelectComponent;

    constructor(
        private readonly formBuilder: FormBuilder,
        private readonly ref: ChangeDetectorRef,
        private readonly dataService: DataService,
        private readonly dataNetbaseService: DataNetbaseService,
        private readonly servicesService: ServicesService,
        private readonly messages: MessageService,
        private readonly permissionService: PermissionService,
        private readonly authenticationService: AuthenticationService,
    ) {
        this.currentUser = this.authenticationService.currentUserValue;
    }

    get f(): {[key: string]: AbstractControl} {
        return this.fieldForm.controls;
    }

    private checkFieldUnique(fieldNumber): AddressConnectedField | boolean {
        if (this.fields.length > 0) {
            // eslint-disable-next-line eqeqeq
            const match = this.fields.find(
                addr => addr.field_number === parseInt(fieldNumber as string, 10),
            );

            return typeof match !== 'undefined' ? match : false;
        }

        return false;
    }

    @HostListener('window:beforeunload')
    canDeactivate(): Observable<boolean> | boolean {
        return !this.isDirty;
    }

    reinitSmartFormCity(): void {
        console.info('Reinitialize SF City objects...');
        smartform.rebindAllForms(false, () => {
            if (smartform.getInstance('smartform-instance-city')) {
                const instanceCity = smartform.getInstance('smartform-instance-city');

                instanceCity.setZIndex(100000000);
                instanceCity.setSelectionBackgroundColor('#4285f3');
                instanceCity.setSelectionCallback((element, text) => {
                    this.f.city.setValue(text);
                });
            }
        });
    }

    reinitSmartFormDistrict(): void {
        console.info('Reinitialize SF District objects...');
        smartform.rebindAllForms(false, () => {
            if (smartform.getInstance('smartform-instance-district')) {
                const instanceDistrict = smartform.getInstance('smartform-instance-district');

                instanceDistrict.setZIndex(100000000);
                instanceDistrict.setSelectionBackgroundColor('#4285f3');
                instanceDistrict.setSelectionCallback((element, text) => {
                    this.f.district.setValue(text);
                });
            }
        });
    }

    reinitSmartFormZip(): void {
        console.info('Reinitialize SF ZIP objects...');
        smartform.rebindAllForms(false, () => {
            if (smartform.getInstance('smartform-instance-zip')) {
                const instanceZip = smartform.getInstance('smartform-instance-zip');

                instanceZip.setZIndex(100000000);
                instanceZip.setSelectionBackgroundColor('#4285f3');
                instanceZip.setSelectionCallback((element, text) => {
                    this.f.zip.setValue(text);
                });
            }
        });
    }

    // eslint-disable-next-line max-lines-per-function
    ngOnInit(): void {
        this.dataService.departmentSource.pipe(untilDestroyed(this))
            .subscribe({
                next: (data: Department[]) => {
                    data.map((department: Department) => {
                        this.departmentSelect = [
                            ...this.departmentSelect,
                            {
                                value: department.id,
                                label: `${department.company.name} - ${department.name}`,
                            },
                        ];
                    });
                },
                error: error => {
                    console.error(error);
                },
            });

        this.dataService.userSource.pipe(untilDestroyed(this))
            .subscribe({
                next: (data: User[]) => {
                    data.map((user: User) => {
                        if (user.authorized && !user.deleted_date) {
                            this.usersSelect = [
                                ...this.usersSelect,
                                {
                                    value: user.id,
                                    label: `${user.firstname} ${user.secondname}`,
                                },
                            ];
                        }
                    });
                },
                error: error => {
                    console.error(error);
                },
            });

        this.dataService.companySource.pipe(untilDestroyed(this))
            .subscribe({
                next: (companies: Company[]) => {
                    this.companySelect = [];
                    companies.map((company: Company) => {
                        if (!company.deleted_date) {
                            this.companySelect = [
                                ...this.companySelect,
                                {
                                    value: company.id,
                                    label: company.name,
                                },
                            ];
                        }
                    });
                    this.ref.markForCheck();
                },
                error: error => {
                    console.error(error);
                },
            });

        this.dataNetbaseService.netPackagesSource.pipe(untilDestroyed(this))
            .subscribe({
                next: (netTarifs: NetPackages[]) => {
                    this.servicesNetSelect = [];
                    netTarifs.map((netTarif: NetPackages) => {
                        if (!netTarif.deleted_date) {
                            this.servicesNetSelect = [
                                ...this.servicesNetSelect,
                                {
                                    value: netTarif.id,
                                    label: `${netTarif.name}${
                                        netTarif.transfer.connection_method === 'optical cable'
                                            ? ' - optické připojení'
                                            : netTarif.transfer.connection_method === 'metalic cable'
                                                ? ' - kabelové připojení'
                                                : netTarif.transfer.connection_method === 'radio'
                                                    ? ' - rádiové připojení'
                                                    : '- xDSL připojení'
                                    }`,
                                },
                            ];
                        }
                    });
                    this.ref.markForCheck();
                },
                error: error => {
                    console.error(error);
                },
            });

        this.dataNetbaseService.tvServicesSource.pipe(untilDestroyed(this))
            .subscribe({
                next: (tvServices: TvServices[]) => {
                    this.servicesTvSelect = [];
                    tvServices.map((tvService: TvServices) => {
                        if (!tvService.deleted_date) {
                            this.servicesTvSelect = [
                                ...this.servicesTvSelect,
                                {
                                    value: tvService.id,
                                    label: `${tvService.name}${
                                        tvService.transfer.connection_method === 'optical cable'
                                            ? ' - optické připojení'
                                            : tvService.transfer.connection_method === 'metalic cable'
                                                ? ' - kabelové připojení'
                                                : tvService.transfer.connection_method === 'radio'
                                                    ? ' - rádiové připojení'
                                                    : '- xDSL připojení'
                                    }`,
                                },
                            ];
                        }
                    });
                    this.ref.markForCheck();
                },
                error: error => {
                    console.error(error);
                },
            });

        this.dataNetbaseService.technologyTypesSource.pipe(untilDestroyed(this))
            .subscribe({
                next: (technologies: TechnologyTypes[]) => {
                    this.technologySelect = [];
                    technologies.map((technology: TechnologyTypes) => {
                        if (!technology.deleted_date) {
                            this.technologySelect = [
                                ...this.technologySelect,
                                {value: technology.id, label: technology.name},
                            ];
                        }
                    });
                    this.ref.markForCheck();
                },
                error: error => {
                    console.error(error);
                },
            });

        this.dataNetbaseService.unitTypesSource.pipe(untilDestroyed(this))
            .subscribe({
                next: (units: UnitTypes[]) => {
                    this.unitSelect = [];
                    units.map((unit: UnitTypes) => {
                        if (!unit.deleted_date) {
                            this.unitSelect = [
                                ...this.unitSelect,
                                {value: unit.id, label: unit.name},
                            ];
                        }
                    });
                    this.ref.markForCheck();
                },
                error: error => {
                    console.error(error);
                },
            });

        this.dataNetbaseService.speedTypesSource.pipe(untilDestroyed(this))
            .subscribe({
                next: (speeds: SpeedTypes[]) => {
                    this.speedsSelect = [];
                    speeds.sort((a, b) => {
                        const aValue = a.speed_until;
                        const bValue = b.speed_until;

                        return aValue > bValue ? -1 : 1;
                    });

                    speeds.map((speed: SpeedTypes) => {
                        if (!speed.deleted_date) {
                            this.speedsSelect = [
                                ...this.speedsSelect,
                                {
                                    value: speed.id,
                                    label: `${speed.speed_until} Mbit - ${
                                        speed.speed_to > 0 ? speed.speed_to : '*'
                                    } Mbit`,
                                },
                            ];
                        }
                    });
                    this.ref.markForCheck();
                },
                error: error => {
                    console.error(error);
                },
            });

        this.dataNetbaseService.dealTypesSource.pipe(untilDestroyed(this))
            .subscribe({
                next: (deals: DealTypes[]) => {
                    this.dealSelect = [];
                    deals.map((deal: DealTypes) => {
                        if (!deal.deleted_date) {
                            this.dealSelect = [
                                ...this.dealSelect,
                                {value: deal.id, label: `${deal.age} měsíců`},
                            ];
                        }
                    });
                    this.ref.markForCheck();
                },
                error: error => {
                    console.error(error);
                },
            });

        this.dataNetbaseService.discountSource.pipe(untilDestroyed(this))
            .subscribe({
                next: (discounts: Discounts[]) => {
                    this.discountSelect = [];
                    discounts.map((discount: Discounts) => {
                        if (!discount.deleted_date) {
                            this.discountSelect = [
                                ...this.discountSelect,
                                {value: discount.id, label: `${discount.discount}%`},
                            ];
                        }
                    });
                    this.ref.markForCheck();
                },
                error: error => {
                    console.error(error);
                },
            });

        this.dataNetbaseService.spaceTypesSource.pipe(untilDestroyed(this))
            .subscribe({
                next: (spaces: SpaceTypes[]) => {
                    this.spaceSelect = [];
                    spaces.map((space: SpaceTypes) => {
                        if (!space.deleted_date) {
                            this.spaceSelect = [
                                ...this.spaceSelect,
                                {
                                    value: space.id,
                                    label: `${space.metric_until} m2 - ${
                                        space.metric_to ? space.metric_to : '*'
                                    } m2`,
                                },
                            ];
                        }
                    });
                    this.ref.markForCheck();
                },
                error: error => {
                    console.error(error);
                },
            });

        this.dataNetbaseService.transferTypesSource.pipe(untilDestroyed(this))
            .subscribe({
                next: (transfers: TransferTypes[]) => {
                    this.transferSelect = [];
                    transfers.map((transfer: TransferTypes) => {
                        if (!transfer.deleted_date) {
                            this.transferSelect = [
                                ...this.transferSelect,
                                {
                                    value: transfer.id,
                                    label: transfer.name,
                                },
                            ];
                        }
                    });
                    this.ref.markForCheck();
                },
                error: error => {
                    console.error(error);
                },
            });

        this.dataService.projectSource.pipe(untilDestroyed(this))
            .subscribe({
                next: (projects: Project[]) => {
                    this.projectSelect = [];
                    projects.map((project: Project) => {
                        if (!project.deleted_date) {
                            this.projectSelect = [
                                ...this.projectSelect,
                                {
                                    value: project.id,
                                    label: project.name,
                                },
                            ];
                        }
                    });
                    this.ref.markForCheck();
                },
                error: error => {
                    console.error(error);
                },
            });

        this.dataNetbaseService.fieldAddressSource.pipe(untilDestroyed(this))
            .subscribe({
                next: (addresses: AddressConnectedField[]) => {
                    this.fields = addresses;
                },
                error: error => {
                    console.error(error);
                },
            });

        this.fieldForm = this.formBuilder.group({
            address: ['', Validators.required],
            field_number: [null, Validators.required],
            provider_id: [null, Validators.required],
            deal_id: [null],
            unit_id: [null],
            space_id: [null],
            speed_id: [null],
            project_id: [null],
            discount_id: [null],
            transfer_id: [null, Validators.required],
            technology_id: [null, Validators.required],
            keys: [false, Validators.required],
            electrical_counter: [''],
            clip_frame: [false, Validators.required],
            business_note: [''],
            descriptive: [''],
            marketingnote: [''],
            marketing_ads: [''],
            owner_name: [''],
            manager_name: [''],
            number_of_units: [
                1,
                Validators.compose([Validators.required, Validators.min(1), Validators.max(1000)]),
            ],
            number_of_storeys: [
                0,
                Validators.compose([Validators.required, Validators.min(0), Validators.max(1000)]),
            ],
            lift: [null, Validators.required],
            realized_at: [null],
            net_packages: [null, Validators.required],
            tv_services: [null],
            street: [null],
            city: [null, Validators.required],
            city_part: [null],
            zip: [null, Validators.required],
            district: [null, Validators.required],
            region: [null, Validators.required],
            state: [{value: 'Česká republika', disabled: true}, Validators.required],
            gps: [null],
            jstk: [null],
        });

        this.fieldForm.valueChanges.pipe(untilDestroyed(this))
            .subscribe(() => {
                this.isDirty = this.fieldForm.dirty;
                this.showInputErrors();
            });

        this.fieldForm
            .get('provider_id')
            ?.valueChanges
            .pipe(untilDestroyed(this))
            .subscribe(value => {
                if (value) {
                    this.dataNetbaseService.netPackagesSource.pipe(untilDestroyed(this))
                        .subscribe({
                            next: (netTarifs: NetPackages[]) => {
                                this.servicesNetSelect = [];
                                this.f.net_packages.patchValue([]);
                                netTarifs.map((netTarif: NetPackages) => {
                                    if (
                                        !netTarif.deleted_date &&
                                        this.f.transfer_id.value === netTarif.transfer_id &&
                                        value === netTarif.provider_id
                                    ) {
                                        this.servicesNetSelect = [
                                            ...this.servicesNetSelect,
                                            {
                                                value: netTarif.id,
                                                label: `${netTarif.name}${
                                                    netTarif.transfer.connection_method ===
                                                    'optical cable'
                                                        ? ' - optické připojení'
                                                        : netTarif.transfer.connection_method ===
                                                        'metalic cable'
                                                            ? ' - kabelové připojení'
                                                            : netTarif.transfer.connection_method ===
                                                            'radio'
                                                                ? ' - rádiové připojení'
                                                                : '- xDSL připojení'
                                                }`,
                                            },
                                        ];
                                    }
                                });
                                this.ref.markForCheck();
                            },
                            error: error => {
                                console.error(error);
                            },
                        });

                    this.dataNetbaseService.tvServicesSource.pipe(untilDestroyed(this))
                        .subscribe({
                            next: (tvServices: TvServices[]) => {
                                this.servicesTvSelect = [];
                                this.f.tv_services.patchValue([]);
                                tvServices.map((tvService: TvServices) => {
                                    if (
                                        !tvService.deleted_date &&
                                        this.f.transfer_id.value === tvService.transfer_id &&
                                        value === tvService.provider_id
                                    ) {
                                        this.servicesTvSelect = [
                                            ...this.servicesTvSelect,
                                            {
                                                value: tvService.id,
                                                label: `${tvService.name}${
                                                    tvService.transfer.connection_method ===
                                                    'optical cable'
                                                        ? ' - optické připojení'
                                                        : tvService.transfer.connection_method ===
                                                        'metalic cable'
                                                            ? ' - kabelové připojení'
                                                            : tvService.transfer.connection_method ===
                                                            'radio'
                                                                ? ' - rádiové připojení'
                                                                : '- xDSL připojení'
                                                }`,
                                            },
                                        ];
                                    }
                                });
                                this.ref.markForCheck();
                            },
                            error: error => {
                                console.error(error);
                            },
                        });
                }
            });

        this.fieldForm
            .get('transfer_id')
            ?.valueChanges
            .pipe(untilDestroyed(this))
            .subscribe(value => {
                if (value) {
                    this.dataNetbaseService.netPackagesSource.pipe(untilDestroyed(this))
                        .subscribe({
                            next: (netTarifs: NetPackages[]) => {
                                this.servicesNetSelect = [];
                                console.info('reset by transfer');
                                this.f.net_packages.patchValue([]);
                                netTarifs.map((netTarif: NetPackages) => {
                                    if (
                                        !netTarif.deleted_date &&
                                        this.f.provider_id.value === netTarif.provider_id &&
                                        value === netTarif.transfer_id
                                    ) {
                                        this.servicesNetSelect = [
                                            ...this.servicesNetSelect,
                                            {
                                                value: netTarif.id,
                                                label: `${netTarif.name}${
                                                    netTarif.transfer.connection_method ===
                                                    'optical cable'
                                                        ? ' - optické připojení'
                                                        : netTarif.transfer.connection_method ===
                                                        'metalic cable'
                                                            ? ' - kabelové připojení'
                                                            : netTarif.transfer.connection_method ===
                                                            'radio'
                                                                ? ' - rádiové připojení'
                                                                : '- xDSL připojení'
                                                }`,
                                            },
                                        ];
                                    }
                                });
                                this.ref.markForCheck();
                            },
                            error: error => {
                                console.error(error);
                            },
                        });

                    this.dataNetbaseService.tvServicesSource.pipe(untilDestroyed(this))
                        .subscribe({
                            next: (tvServices: TvServices[]) => {
                                this.servicesTvSelect = [];
                                this.f.tv_services.patchValue([]);
                                tvServices.map((tvService: TvServices) => {
                                    if (
                                        !tvService.deleted_date &&
                                        this.f.provider_id.value === tvService.provider_id &&
                                        value === tvService.transfer_id
                                    ) {
                                        this.servicesTvSelect = [
                                            ...this.servicesTvSelect,
                                            {
                                                value: tvService.id,
                                                label: `${tvService.name}${
                                                    tvService.transfer.connection_method ===
                                                    'optical cable'
                                                        ? ' - optické připojení'
                                                        : tvService.transfer.connection_method ===
                                                        'metalic cable'
                                                            ? ' - kabelové připojení'
                                                            : tvService.transfer.connection_method ===
                                                            'radio'
                                                                ? ' - rádiové připojení'
                                                                : '- xDSL připojení'
                                                }`,
                                            },
                                        ];
                                    }
                                });
                                this.ref.markForCheck();
                            },
                            error: error => {
                                console.error(error);
                            },
                        });
                }
            });

        this.fieldForm
            .get('field_number')
            ?.valueChanges
            .pipe(untilDestroyed(this))
            .subscribe(value => {
                if (value) {
                    this.fieldValidator(value);
                }
            });
    }

    ngOnChanges(): void {
        if (this.isVisible) {
            this.reinitSmartFormCity();
            this.reinitSmartFormDistrict();
            this.reinitSmartFormZip();

            if (this.isUpdate && this.field) {
                this.resetForm();
                this.formDialogObj.header = `Parcela #${this.field.id} - ${this.field.address}`;
                this.fillTheForm();
                this.showInputErrors();
            }

            if (this.isCreate) {
                this.resetForm();
                this.formDialogObj.header = 'Nová parcela';
            }

            if (this.isCopy) {
                this.resetForm();
                this.formDialogObj.header = 'Nová parcela';
                this.fillTheForm();
                this.showInputErrors();
            }
        }
    }

    changeDialogState(value: boolean): void {
        this.feildFormDialogState.emit(value);
    }

    onFilteringTvPackages: EmitType<FilteringEventArgs> = (e: FilteringEventArgs) => {
        if (e.text === '') {
            e.updateData(this.servicesTvSelect);
        } else {
            let query: Query = new Query();

            query = e.text !== '' ? query.where('label', 'contains', e.text, true, true) : query;
            e.updateData(this.servicesTvSelect, query);
        }
    };

    onFilteringNetPackages: EmitType<FilteringEventArgs> = (e: FilteringEventArgs) => {
        if (e.text === '') {
            e.updateData(this.servicesNetSelect);
        } else {
            let query: Query = new Query();

            query = e.text !== '' ? query.where('label', 'contains', e.text, true, true) : query;
            e.updateData(this.servicesNetSelect, query);
        }
    };

    fieldValidator(fieldNumber): void {
        if (fieldNumber && fieldNumber > 0) {
            const netPackages: number[] = [];
            const tvPackages: number[] = [];

            this.fieldAddress = this.checkFieldUnique(fieldNumber);

            if (this.fieldAddress instanceof AddressConnectedField) {
                this.validField = !!this.fieldAddress.field_number;

                if (this.validField) {
                    this.fieldForm.controls.address.patchValue(this.fieldAddress.address);
                    this.fieldForm.controls.unit_id.patchValue(this.fieldAddress.unit_id);
                    this.fieldForm.controls.deal_id.patchValue(this.fieldAddress.deal_id);
                    this.fieldForm.controls.provider_id.patchValue(this.fieldAddress.provider_id);
                    this.fieldForm.controls.discount_id.patchValue(this.fieldAddress.discount_id);
                    this.fieldForm.controls.space_id.patchValue(this.fieldAddress.space_id);
                    this.fieldForm.controls.speed_id.patchValue(this.fieldAddress.speed_id);
                    this.fieldForm.controls.project_id.patchValue(this.fieldAddress.project_id);
                    this.fieldForm.controls.technology_id.patchValue(
                        this.fieldAddress.technology_id,
                    );
                    this.fieldForm.controls.transfer_id.patchValue(this.fieldAddress.transfer_id);
                    this.fieldForm.controls.lift.patchValue(this.fieldAddress.lift);
                    this.fieldForm.controls.keys.patchValue(this.fieldAddress.keys);
                    this.fieldForm.controls.electrical_counter.patchValue(
                        this.fieldAddress.electrical_counter,
                    );
                    this.fieldForm.controls.clip_frame.patchValue(this.fieldAddress.clip_frame);
                    this.fieldForm.controls.business_note.patchValue(
                        this.fieldAddress.business_note,
                    );
                    this.fieldForm.controls.descriptive.patchValue(this.fieldAddress.descriptive);
                    this.fieldForm.controls.marketingnote.patchValue(
                        this.fieldAddress.marketingnote,
                    );

                    this.fieldForm.controls.marketing_ads.patchValue(
                        this.fieldAddress.marketing_ads,
                    );
                    this.fieldForm.controls.owner_name.patchValue(this.fieldAddress.owner_name);
                    this.fieldForm.controls.manager_name.patchValue(this.fieldAddress.manager_name);
                    this.fieldForm.controls.number_of_units.patchValue(
                        // eslint-disable-next-line eqeqeq
                        !this.fieldAddress.number_of_units ? 1 : this.fieldAddress.number_of_units,
                    );

                    this.fieldForm.controls.number_of_storeys.patchValue(
                        // eslint-disable-next-line eqeqeq
                        !this.fieldAddress.number_of_storeys
                            ? 0
                            : this.fieldAddress.number_of_storeys,
                    );

                    this.fieldForm.controls.realized_at.patchValue(
                        this.fieldAddress.realized_at
                            ? moment(this.fieldAddress.realized_at)
                                .format('YYYY-MM-DD HH:mm:ss')
                            : null,
                    );

                    if (this.fieldAddress.net_packages.length > 0) {
                        this.fieldAddress.net_packages.map((netPackage: NetPackages) => {
                            netPackages.push(netPackage.id);
                        });
                    }

                    if (this.fieldAddress.tv_services && this.fieldAddress.tv_services.length > 0) {
                        this.fieldAddress.tv_services.map((tvPackage: TvServices) => {
                            tvPackages.push(tvPackage.id);
                        });
                    }

                    this.fieldForm.controls.net_packages.patchValue(netPackages);
                    this.fieldForm.controls.tv_services.patchValue(tvPackages);
                }
            } else {
                this.resetForm();
            }
        }
    }

    addressValidator(address): void {
        if (address) {
            this.fieldForm.controls.street.patchValue(address.street);
            this.fieldForm.controls.city_part.patchValue(address.city_part);
            this.fieldForm.controls.city.patchValue(address.city);
            this.fieldForm.controls.zip.patchValue(address.zip);
            this.fieldForm.controls.district.patchValue(address.district);
            this.fieldForm.controls.region.patchValue(address.region);
            this.fieldForm.controls.state.patchValue(address.state);
            this.fieldForm.controls.gps.patchValue(address.gps);
            this.fieldForm.controls.jstk.patchValue(address.jstk);
            this.fieldForm.controls.zip.patchValue(address.zip);
        }
    }

    resetForm(): void {
        console.info('form reset');
        this.fieldForm.controls.address.reset();
        this.fieldForm.controls.field_number.reset();
        this.fieldForm.controls.electrical_counter.reset();
        this.fieldForm.controls.business_note.reset();
        this.fieldForm.controls.descriptive.reset();
        this.fieldForm.controls.marketingnote.reset();
        this.fieldForm.controls.marketing_ads.reset();
        this.fieldForm.controls.owner_name.reset();
        this.fieldForm.controls.manager_name.reset();
        this.fieldForm.controls.number_of_units.reset();
        this.fieldForm.controls.realized_at.reset();
        this.fieldForm.controls.provider_id.reset();
        this.fieldForm.controls.net_packages.reset();
        this.fieldForm.controls.tv_services.reset();
        this.fieldForm.controls.unit_id.reset();
        this.fieldForm.controls.deal_id.reset();
        this.fieldForm.controls.discount_id.reset();
        this.fieldForm.controls.space_id.reset();
        this.fieldForm.controls.project_id.reset();
        this.fieldForm.controls.technology_id.reset();
        this.fieldForm.controls.transfer_id.reset();
        this.fieldForm.controls.street.reset();
        this.fieldForm.controls.city_part.reset();
        this.fieldForm.controls.city.reset();
        this.fieldForm.controls.zip.reset();
        this.fieldForm.controls.district.reset();
        this.fieldForm.controls.region.reset();
        this.fieldForm.controls.state.reset();
        this.fieldForm.controls.gps.reset();
        this.fieldForm.controls.jstk.reset();
        this.fieldForm.controls.zip.reset();
        this.fieldForm.controls.state.patchValue('Česká republika');
        this.fieldForm.controls.number_of_units.patchValue(1);
        this.fieldForm.controls.number_of_storeys.patchValue(0);
        this.fieldForm.controls.keys.patchValue(false);
        this.fieldForm.controls.clip_frame.patchValue(false);
        this.fieldForm.controls.lift.patchValue(false);
    }

    fillTheForm(): void {
        if (this.field?.address_obj) {
            this.addressValidator(this.field.address_obj);
        }

        this.fieldForm.controls.field_number.patchValue(this.field?.field_number);
    }

    onSubmit(): void {
        this.submited = true;
        this.isDirty = false;
        this.loadingField = true;

        if (this.isCreate || this.isCopy) {
            this.createField();
        }

        if (this.isUpdate) {
            this.editField();
        }
    }

    createField(): void {
        if (this.fieldForm.invalid) {
            console.error('form is not valid!');

            return;
        }

        if (this.currentUser && this.permissionService.checkUserISServiceAdmin(this.currentUser)) {
            const data: FieldInput = {
                keys: this.f.keys.value,
                electrical_counter: this.f.electrical_counter.value,
                clip_frame: this.f.clip_frame.value,
                business_note: this.f.business_note.value,
                descriptive: this.f.descriptive.value,
                marketingnote: this.f.marketingnote.value,
                marketing_ads: this.f.marketing_ads.value,
                owner_name: this.f.owner_name.value,
                manager_name: this.f.manager_name.value,
                number_of_units: this.f.number_of_units.value,
                field_number: this.f.field_number.value,
                address: this.f.address.value,
                provider_id: this.f.provider_id.value,
                project_id: this.f.project_id.value,
                realized_at: this.f.realized_at.value
                    ? moment(this.f.realized_at.value as MomentInput)
                        .format('YYYY-MM-DD HH:mm:ss')
                    : null,
                unit_id: this.f.unit_id.value,
                deal_id: this.f.deal_id.value,
                discount_id: this.f.discount_id.value,
                space_id: this.f.space_id.value,
                transfer_id: this.f.transfer_id.value,
                net_packages: this.f.net_packages.value,
                tv_services: this.f.tv_services.value,
                technology_id: this.f.technology_id.value,
                created_by: this.currentUser.id,
                valid_ruian: false,
                street: this.f.street.value,
                city: this.f.city.value,
                city_part: this.f.city_part.value,
                district: this.f.district.value,
                region: this.f.region.value,
                state: this.f.state.value,
                gps: this.f.gps.value,
                jstk: this.f.jstk.value,
                speed_id: this.f.speed_id.value,
                number_of_storeys: this.f.number_of_storeys.value,
                lift: this.f.lift.value,
                zip: this.f.zip.value,
            };

            this.servicesService
                .addAddressField(data)
                .pipe(untilDestroyed(this))
                .subscribe({
                    next: (field: AddressConnectedField) => {
                        const body = `Parcela #${field.id}`;
                        const options = {
                            progressBar: true,
                            timeOut: 5000,
                            toastClass: 'success',
                        };

                        this.messages.showSuccess('Parcela úspěšně přidána', body, options);
                        this.loadingField = false;
                        this.isCreate = false;
                        this.dataNetbaseService.setFieldAddressDataSource();
                        this.formDialogObj.hide();
                        this.ref.markForCheck();
                    },
                    error: error => {
                        console.error(error);

                        const body = 'Zkuste to později ...';
                        const options = {
                            progressBar: true,
                            timeOut: 5000,
                            toastClass: 'red',
                        };

                        this.messages.showError(
                            'Chyba během přidávání připojené parcely',
                            body,
                            options,
                        );
                        this.loadingField = false;
                        this.ref.markForCheck();
                    },
                });
        } else {
            const body = 'Parcelu nemůžete vytvořit';
            const options = {progressBar: true, timeOut: 5000};

            this.messages.showError('Nedostatečné oprávnění', body, options);
            this.loadingField = false;
            this.isCreate = false;
            this.formDialogObj.hide();
            this.ref.markForCheck();
        }
    }

    editField(): void {
        if (this.fieldForm.invalid) {
            console.error('form is not valid!');

            return;
        }

        this.loadingField = true;
        this.ref.markForCheck();

        if (this.currentUser && this.permissionService.checkUserISServiceAdmin(this.currentUser)) {
            const data: FieldInput = {
                id: this.field?.id,
                keys: this.f.keys.value,
                electrical_counter: this.f.electrical_counter.value,
                clip_frame: this.f.clip_frame.value,
                business_note: this.f.business_note.value,
                descriptive: this.f.descriptive.value,
                marketingnote: this.f.marketingnote.value,
                marketing_ads: this.f.marketing_ads.value,
                owner_name: this.f.owner_name.value,
                manager_name: this.f.manager_name.value,
                number_of_units: this.f.number_of_units.value,
                field_number: this.f.field_number.value,
                address: this.f.address.value,
                provider_id: this.f.provider_id.value,
                project_id: this.f.project_id.value,
                realized_at: this.f.realized_at.value
                    ? moment(this.f.realized_at.value as MomentInput)
                        .format('YYYY-MM-DD HH:mm:ss')
                    : null,
                unit_id: this.f.unit_id.value,
                deal_id: this.f.deal_id.value,
                discount_id: this.f.discount_id.value,
                space_id: this.f.space_id.value,
                transfer_id: this.f.transfer_id.value,
                net_packages: this.f.net_packages.value,
                tv_services: this.f.tv_services.value,
                technology_id: this.f.technology_id.value,
                created_by: this.currentUser.id,
                valid_ruian: false,
                street: this.f.street.value,
                city: this.f.city.value,
                city_part: this.f.city_part.value,
                district: this.f.district.value,
                region: this.f.region.value,
                state: this.f.state.value,
                gps: this.f.gps.value,
                jstk: this.f.jstk.value,
                speed_id: this.f.speed_id.value,
                number_of_storeys: this.f.number_of_storeys.value,
                lift: this.f.lift.value,
                zip: this.f.zip.value,
            };

            this.servicesService
                .updateAddressField(data)
                ?.pipe(untilDestroyed(this))
                .subscribe({
                    next: (field: AddressConnectedField) => {
                        const body = `Parcela #${field.id}`;
                        const options = {
                            progressBar: true,
                            timeOut: 5000,
                            toastClass: 'success',
                        };

                        this.messages.showSuccess('Parcela úspěšně upravena', body, options);
                        this.loadingField = false;
                        this.isUpdate = false;
                        this.dataNetbaseService.setFieldAddressDataSource();
                        this.formDialogObj.hide();
                        this.ref.markForCheck();
                    },
                    error: error => {
                        console.error(error);

                        const body = 'Zkuste to později ...';
                        const options = {
                            progressBar: true,
                            timeOut: 5000,
                            toastClass: 'red',
                        };

                        this.messages.showError(
                            'Chyba během upravování připojené parcely',
                            body,
                            options,
                        );
                        this.loadingField = false;
                        this.ref.markForCheck();
                    },
                });
        } else {
            const body = 'Parcelu nemůžete upravit';
            const options = {progressBar: true, timeOut: 5000};

            this.messages.showError('Nedostatečné oprávnění', body, options);
            this.loadingField = false;
            this.isUpdate = false;
            this.formDialogObj.hide();
            this.ref.markForCheck();
        }
    }

    showInputErrors(): void {
        this.isChecked = true;
        /*    console.info(`VALID: ${this.fieldForm.valid}`);
            Object.keys(this.fieldForm.controls).forEach(key => {
                const controlErrors: ValidationErrors = this.fieldForm.get(key).errors;

                if (controlErrors != null) {
                    Object.keys(controlErrors).forEach(keyError => {
                        console.log(
                            `Key control: ${key}, keyError: ${keyError}, err value: `,
                            controlErrors[keyError]
                        );
                    });
                }
            });*/
        this.fieldForm.markAllAsTouched();
        this.ref.markForCheck();
    }
}
