<div
  aria-label="Task toolbar"
  class="d-block text-right p-2"
  role="toolbar"
>
  <button
    *ngIf="task?.ticket_id"
    [queryParams]="{ view: 'task' }"
    [routerLink]="['/tickets/detail/', task?.ticket_id]"
    cssClass="e-btn e-info e-outline btn-p-zero e-small"
    ejs-button
    title="zobrazit nadřazený tiket"
    type="button"
  >
    <i
      aria-hidden="true"
      class="fa-light fa-search"
    ></i>
    <span
      class="d-none d-lg-inline"
    >Zobrazit ticket</span
    >
  </button>
  <button
    (click)="reactiveSolution()"
    *ngIf="
                                (task?.created_by === currentUser?.id ||
                                    (currentUser?.rule &&
                                        currentUser?.rule.is_admin) ||
                                    task?.creator.boss_id === currentUser?.id ||
                                    task?.solver.id === currentUser?.id) &&
                                !task?.deleted_date &&
                                task?.finished_at
                            "
    cssClass="e-btn e-warning btn-p-zero e-small"
    ejs-button
    title="upravit řešení úkolu"
    type="button"
  >
    <i
      aria-hidden="true"
      class="fa-light fa-edit"
    ></i>
    <span
      class="d-none d-lg-inline"
    >upravit řešení</span
    >
  </button>
  <button
    (click)="activeTask()"
    *ngIf="
                                (task?.created_by === currentUser?.id ||
                                    (currentUser?.rule &&
                                        currentUser?.rule.is_admin) ||
                                    task?.creator.boss_id === currentUser?.id) &&
                                (task?.deleted_date || task?.finished_at)
                            "
    cssClass="e-btn e-danger btn-p-zero e-small"
    ejs-button
    title="re-aktivovat uzamčený úkol"
    type="button"
  >
    <i
      aria-hidden="true"
      class="fa-light fa-repeat"
    ></i>
    <span
      class="d-none d-lg-inline"
    >re-aktivovat úkol</span
    >
  </button>
  <button
    (click)="cerateComment()"
    *ngIf="
                                (task?.created_by === currentUser?.id ||
                                    (currentUser?.rule &&
                                        (currentUser?.rule.is_task_admin ||
                                            currentUser?.rule.is_admin)) ||
                                    task?.creator.boss_id === currentUser?.id ||
                                    task?.solver.id === currentUser?.id) &&
                                !task?.finished_at &&
                                !task?.deleted_date
                            "
    cssClass="e-btn e-primary btn-p-zero e-small"
    ejs-button
    title="přidat komentář do diskuze" type="button"
  >
    <i
      aria-hidden="true"
      class="fa-light fa-edit mr-lg-1"
    ></i>
    <span class="d-none d-lg-inline"> Přidat komentář </span>
  </button>
  <button
    (click)="copyTask()"
    *ngIf="
                                task?.created_by === currentUser?.id ||
                                (currentUser?.rule &&
                                    (currentUser?.rule.is_task_admin ||
                                        currentUser?.rule.is_admin)) ||
                                task?.creator.boss_id === currentUser?.id ||
                                task?.solver.id === currentUser?.id
                            "
    cssClass="e-btn e-primary btn-p-zero e-small"
    ejs-button
    title="kopírovat úkol"
    type="button"
  >
    <i
      aria-hidden="true"
      class="fa-light fa-copy"
    ></i>
    <span class="d-none d-lg-inline">kopírovat</span>
  </button>
  <button
    (click)="editTask()"
    *ngIf="
                                (task?.created_by === currentUser?.id ||
                                    (currentUser?.rule &&
                                        (currentUser?.rule.is_task_admin ||
                                            currentUser?.rule.is_admin)) ||
                                    task?.creator.boss_id === currentUser?.id) &&
                                !task?.deleted_date &&
                                !task?.finished_at
                            "
    cssClass="e-btn e-warning btn-p-zero e-small"
    ejs-button
    title="upravit úkol"
    type="button"
  >
    <i
      aria-hidden="true"
      class="fa-light fa-pencil"
    ></i>
    <span class="d-none d-lg-inline">upravit</span>
  </button>
  <button
    (click)="addSolving()"
    *ngIf="
                                (task?.created_by === currentUser?.id ||
                                    (currentUser?.rule &&
                                        (currentUser?.rule.is_task_admin ||
                                            currentUser?.rule.is_admin)) ||
                                    task?.creator.boss_id === currentUser?.id ||
                                    task?.solver.id === currentUser?.id) &&
                                !task?.deleted_date &&
                                !task?.finished_at
                            "
    cssClass="e-btn e-success btn-p-zero e-small"
    ejs-button
    title="vložit řešení úkolu"
    type="button"
  >
    <i
      aria-hidden="true"
      class="fa-light fa-lock-alt"
    ></i>
    <span
      class="d-none d-lg-inline"
    >Vložit řešení / uzamknout</span
    >
  </button>
  <button
    (click)="deleteTask()"
    *ngIf="
                                (task?.created_by === currentUser?.id ||
                                    (currentUser?.rule &&
                                        (currentUser?.rule.is_task_admin ||
                                            currentUser?.rule.is_admin)) ||
                                    task?.creator.boss_id === currentUser?.id) &&
                                !task?.deleted_date &&
                                !task?.finished_at
                            "
    cssClass="e-btn e-danger btn-p-zero e-small"
    ejs-button
    title="smazat úkol"
    type="button"
  >
    <i
      aria-hidden="true"
      class="fa-light fa-trash"
    ></i>
    <span class="d-none d-lg-inline">smazat</span>
  </button>
</div>
