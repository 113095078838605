<ejs-dialog
  #formDialog
  (close)="isDirty = false; isChecked = false; changeDialogState(false)"
  [isModal]="true"
  [showCloseIcon]="true"
  [visible]="isVisible"
  [width]="'85%'"
  id="formDialog"
>
  <form [formGroup]="exportVacationForm">
    <ng-template #content>
      <div class="dialogContent">
        <div class="row">
          <div class="col-12 col-md-5 mb-5 mb-md-0">
            <label for="employer">Zaměstnavatel</label>
            <ejs-dropdownlist
              #employerList
              (filtering)="onFilteringEmployer($event)"
              [allowFiltering]="true"
              [cssClass]="
                                (f.userEmployer.dirty ||
                                    f.userEmployer.touched) &&
                                f.userEmployer.invalid
                                    ? 'e-error'
                                    : (f.userEmployer.dirty ||
                                          f.userEmployer.touched) &&
                                      f.userEmployer.valid
                                    ? 'e-success'
                                    : ''
                            "
              [dataSource]="employerSelect"
              [fields]="fields"
              [filterBarPlaceholder]="
                                'Vyhledat zaměstnavatele...'
                            "
              [ignoreAccent]="true"
              [placeholder]="'Vyberte zaměstnavatele'"
              [popupHeight]="height"
              formControlName="userEmployer"
              id="employer"
              sortOrder="Ascending"
            ></ejs-dropdownlist>
            <div
              *ngIf="submited && f.userEmployer.errors"
              class="invalid-feedback"
            >
              <div *ngIf="f.userEmployer.errors.required">
                Vyberte jednoho zaměstnavatele
              </div>
            </div>
          </div>
          <div class="col-12 col-md-4 mb-5 mb-md-0">
            <label for="month">Měsíc</label>
            <ejs-dropdownlist
              #monthList
              (filtering)="onFilteringMonth($event)"
              [allowFiltering]="true"
              [cssClass]="
                                (f.month.dirty || f.month.touched) &&
                                f.month.invalid
                                    ? 'e-error'
                                    : (f.month.dirty || f.month.touched) &&
                                      f.month.valid
                                    ? 'e-success'
                                    : ''
                            "
              [dataSource]="monthsSelect"
              [fields]="fields"
              [filterBarPlaceholder]="'Vyhledat měsíc...'"
              [ignoreAccent]="true"
              [placeholder]="'Vyberte měsíc'"
              [popupHeight]="height"
              formControlName="month"
              id="month"
              sortOrder="Ascending"
            ></ejs-dropdownlist>
            <div
              *ngIf="submited && f.month.errors"
              class="invalid-feedback"
            >
              <div *ngIf="f.month.errors.required">
                Vyberte jeden měsíc z roku
              </div>
            </div>
          </div>
          <div class="col-12 col-md-3 mb-5 mb-md-0">
            <label for="year">Rok</label>
            <ejs-dropdownlist
              #yearList
              (filtering)="onFilteringYear($event)"
              [allowFiltering]="true"
              [cssClass]="
                                (f.year.dirty || f.year.touched) &&
                                f.year.invalid
                                    ? 'e-error'
                                    : (f.year.dirty || f.year.touched) &&
                                      f.year.valid
                                    ? 'e-success'
                                    : ''
                            "
              [dataSource]="yearSelect"
              [fields]="fields"
              [filterBarPlaceholder]="'Vyhledat rok...'"
              [ignoreAccent]="true"
              [placeholder]="'Vyberte rok'"
              [popupHeight]="height"
              formControlName="year"
              id="year"
              sortOrder="Descending"
            ></ejs-dropdownlist>
            <div
              *ngIf="submited && f.year.errors"
              class="invalid-feedback"
            >
              <div *ngIf="f.year.errors.required"
              >Vyberte jeden z roků
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
    <ng-template #footerTemplate>
      <div class="d-flex justify-content-end">
                <span
                  *ngIf="exportVacationForm.invalid"
                  class="e-badge e-badge-warning p-2 size_2"
                  role="alert"
                >
                    <strong>Formulář je špatně vyplněný</strong>
                </span>
        <span
          *ngIf="exportVacationForm.valid"
          class="e-badge e-badge-success p-2 size_2"
          role="alert"
        >
                    <strong>Formulář je správně vyplněný</strong>
                </span>
      </div>
      <div class="row">
        <div class="col-12 pt-2">
          <button
            (click)="onSubmit()"
            [disabled]="
                            loadingVacation ||
                            exportVacationForm.invalid ||
                            !isChecked
                        "
            cssClass="e-btn e-success btn-p-zero e-small mr-1"
            ejs-button
            type="submit"
          >
                        <span
                          *ngIf="loadingVacation === true"
                          aria-hidden="true"
                          class="spinner-grow spinner-grow-sm"
                          role="status"
                        ></span>
            <span *ngIf="loadingVacation === false">EXPORT</span>
            <span *ngIf="loadingVacation === true"
            >Provádím...</span
            >
          </button>
          <button
            (click)="formDialogObj.hide()"
            cssClass="e-btn e-danger e-outline btn-p-zero e-small"
            ejs-button
            type="button"
          >
            ZRUŠIT
          </button>
        </div>
      </div>
    </ng-template>
  </form>
  <div
    class="col-12 pr-3 pl-3"
    style="max-height: 350px; overflow: auto; color: black"
  >
    <table *ngIf="submited" id="exported_accountant_table">
      <tr>
        <th colspan="15">Zaměstnavatel: {{ employer.name }}</th>
        <th colspan="14">
          Exportoval: {{ exporter.firstname }}
          {{ exporter.secondname }}
        </th>
      </tr>
      <tr>
        <th colspan="29">
          Docházka:
          <strong>
            {{ startExport | dateTimeToLocaleString }} -
            {{ endExport | dateTimeToLocaleString }}
          </strong>
        </th>
      </tr>
      <tr>
        <th>jméno</th>
        <th *ngFor="let day of [].constructor(maxDay); let i = index">
          {{ i + 1 < 10 ? '0' + (i + 1):i + 1 }}
        </th>
      </tr>
      <tr *ngFor="let data of vacationExport">
        <td>{{ data.creator_name }}:</td>
        <td
          *ngFor="
                        let day of [].constructor(maxDay);
                        let day_id = index
                    "
        >
          <ng-container
            *ngFor="let vacation of data.vacations; let i = index"
          >
            <ng-container *ngIf="vacation.type !== 'Home Office'">
              <ng-container *ngIf="vacation.workdays">
                                <span
                                  *ngIf="
                                        compareDates(
                                            vacation.start_at | dateTimeFromIso,
                                            year +
                                                '-' +
                                                month +
                                                '-' +
                                                (day_id + 1)
                                                | dateTimeFromFormat: 'yyyy-L-d'
                                        )
                                    "
                                >
                                    ({{
                                    vacation.start_at
                                      | dateTimeFromIso
                                      | dateTimeToFormat: 'T'
                                  }}-
                                </span>
              </ng-container>
              <span
                *ngIf="
                                    (vacation.end_at
                                        | dateTimeFromIso
                                        | dateTimeToIsoDate
                                        | dateTimeFromIso) >=
                                        (year + '-' + month + '-' + (day_id + 1)
                                            | dateTimeFromFormat: 'yyyy-L-d') &&
                                    (vacation.start_at
                                        | dateTimeFromIso
                                        | dateTimeToIsoDate
                                        | dateTimeFromIso) <=
                                        (year + '-' + month + '-' + (day_id + 1)
                                            | dateTimeFromFormat: 'yyyy-L-d')
                                "
              >
                                <ng-container
                                  *ngIf="vacation.type === 'Dovolená'"
                                ><strong> D </strong></ng-container
                                >
                                <ng-container *ngIf="vacation.type === 'Lékař'"
                                ><strong> L </strong></ng-container
                                >
                                <ng-container
                                  *ngIf="vacation.type === 'Neplacené volno'"
                                ><strong> N </strong></ng-container
                                >
                                <ng-container
                                  *ngIf="vacation.type === 'SickDays'"
                                ><strong> Sd </strong></ng-container
                                >
                                <ng-container
                                  *ngIf="vacation.type === 'Neschopenka'"
                                ><strong> Nes </strong></ng-container
                                >
                                <ng-container *ngIf="vacation.type === 'Nemoc'"
                                ><strong> Nem </strong></ng-container
                                >
                            </span>
              <ng-container *ngIf="vacation.workdays">
                                <span
                                  *ngIf="
                                        compareDates(
                                            vacation.end_at | dateTimeFromIso,
                                            year +
                                                '-' +
                                                month +
                                                '-' +
                                                (day_id + 1)
                                                | dateTimeFromFormat: 'yyyy-L-d'
                                        )
                                    "
                                >
                                    -{{
                                    vacation.end_at
                                      | dateTimeFromIso
                                      | dateTimeToFormat: 'T'
                                  }})
                                </span>
              </ng-container>
            </ng-container>
          </ng-container>
        </td>
      </tr>
    </table>
  </div>
  <button
    (click)="exportTable()"
    *ngIf="submited"
    cssClass="e-btn e-primary btn-p-zero e-small"
    ejs-button
  >
    exportovat
  </button>
</ejs-dialog>
