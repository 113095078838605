import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {Tickets} from '@src/app/_models/ticket/tickets';
import {TicketNotes} from '@src/app/_models/ticket/ticket-notes';
import {AuthenticationService} from '@src/app/_services/authentication.service';
import {User} from '@src/app/_models/user/user';
import {TicketsService} from '@src/app/features/tickets/tickets.service';

@Component({
    selector: 'app-ticket-notes',
    templateUrl: './ticket-notes.component.html',
    styleUrls: ['./ticket-notes.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TicketNotesComponent {
    @Input() ticket?: Tickets | null;
    @Input() isSolving?: boolean | null;

    @Output() readonly deleteTicketNoteEvent = new EventEmitter<TicketNotes>();
    @Output() readonly editTicketNoteEvent = new EventEmitter<TicketNotes>();
    @Output() readonly editSolutionNoteEvent = new EventEmitter<boolean>();
    @Output() readonly reactiveTicketEvent = new EventEmitter<boolean>();

    currentUser: User | null;
    live: true;

    constructor(
        private readonly authenticationService: AuthenticationService,
        public ticketsService: TicketsService,
    ) {
        this.currentUser = this.authenticationService.currentUserValue;
    }

    deleteNote(note: TicketNotes): void {
        this.deleteTicketNoteEvent.emit(note);
    }

    editNote(note: TicketNotes): void {
        this.editTicketNoteEvent.emit(note);
    }

    reactiveTicket(): void {
        this.reactiveTicketEvent.emit(true);
    }

    editSolution(): void {
        this.editSolutionNoteEvent.emit(true);
    }
}
