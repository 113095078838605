import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {Department} from '@src/app/_models/department/department';
import {Role} from '@src/app/_models/role/role';
import {Employer} from '@src/app/_models/employer/employer';
import {Company} from '@src/app/_models/company/company';
import {catchError, share, tap} from 'rxjs/operators';
import {MessageService} from '@src/app/_services/message.service';
import {User} from '@src/app/_models/user/user';
import {VacationCategory} from '@src/app/_models/vacation/vacation-category';
import {MaterialPayment} from '@src/app/_models/material/material-payment';
import {Assignation} from '@src/app/_models/assignation/assignation';
import {Building} from '@src/app/_models/buildings/building.model';
import {Address} from '@src/app/_models/services/address';
import {EnvironmentService} from '@src/app/_services/environment.service';
import {BuildingInput} from '@src/app/features/settings/types/building-input.type';
import {VacationCategoryInput} from '@src/app/features/vacation/vacation.service';
import {Office} from '@src/app/_models/office/office.model';
import {Car} from '@src/app/_models/cars/car.model';
import {CarInput} from '@src/app/features/settings/types/car-input.type';
import {DataService} from '@src/app/_services/data.service';
import {OfficeInput} from '@src/app/features/settings/types/office-input.type';
import {BookingService} from '@src/app/features/booking/services/booking.service';

export interface ApiConnectionsGridItems {
    id: number;
    title: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    host_url: string;
    port: number;
    state: boolean;
    active: boolean;
    type: string;
    protocol: string;
    headers: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    last_check: Date | string | null;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    created_by: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    creator_str: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    creator_img: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    created_date: Date;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    updated_date: Date | null;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    deleted_date: Date | null;
}

export interface DepartmentGridItems {
    id: number;
    name: string;
    assignations: Assignation[];
    company: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    created_by: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    creator_str: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    creator_img: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    created_date: Date;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    updated_date: Date | null;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    deleted_date: Date | null;
}

export interface CompaniesGridItems {
    id: number;
    name: string;
    departments: Department[];
    address: Address;
    ico: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    created_by: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    creator_str: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    creator_img: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    created_date: Date;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    updated_date: Date | null;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    deleted_date: Date | null;
}

export interface CompanyInput {
    id?: number;
    name?: string;
    address?: Address;
    ruian?: number;
    ico?: number;
    active?: boolean;
    departments?: number[];
}

export interface DepartmentInput {
    id?: number;
    name?: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    company_id?: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    created_by?: number;
    users?: number[];
}

export interface RoleInput {
    id?: number;
    name?: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    created_by?: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    creator_str?: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    creator_img?: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    created_date?: Date;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    updated_date?: Date | null;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    deleted_date?: Date | null;
}

export interface EmployerInput {
    id?: number;
    name?: string;
    users?: User[];
    // eslint-disable-next-line @typescript-eslint/naming-convention
    created_by?: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    updated_by?: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    creator_str?: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    creator_img?: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    created_date?: Date;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    updated_date?: Date | null;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    deleted_date?: Date | null;
}

export interface PathInput {
    path?: string;
    method?: string;
    ping?: boolean;
    fields?: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    api_id?: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    created_by?: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    updated_by?: number;
}

const httpOptions = {
    headers: new HttpHeaders({'Content-Type': 'application/json'}),
};

@Injectable({
    providedIn: 'root',
})
export class SettingsService {
    private readonly departmentUrl: string;

    private readonly roleUrl: string;

    private readonly employerUrl: string;

    private readonly companyrUrl: string;

    private readonly companyUniqueUrl: string;

    private readonly departmentUniqueUrl: string;

    private readonly employerUniqueUrl: string;

    private readonly roleUniqueUrl: string;

    private readonly vacationsCategoryUrl: string;

    private readonly materialsPaymentUrl: string;

    private readonly buildingsUrl: string;

    private readonly carsUrl: string;

    private readonly officesUrl: string;

    constructor(
        private readonly http: HttpClient,
        private readonly messageService: MessageService,
        private readonly environmentService: EnvironmentService,
        private readonly dataService: DataService,
        private readonly bookingService: BookingService,
    ) {
        this.departmentUrl = this.environmentService.backendURL + '/api/department';
        this.roleUrl = this.environmentService.backendURL + '/api/role';
        this.employerUrl = this.environmentService.backendURL + '/api/employer';
        this.companyrUrl = this.environmentService.backendURL + '/api/company/';
        this.companyUniqueUrl = this.environmentService.backendURL + '/api/company/existunique';
        this.departmentUniqueUrl =
            this.environmentService.backendURL + '/api/department/existunique';
        this.employerUniqueUrl = this.environmentService.backendURL + '/api/employer/existunique';
        this.roleUniqueUrl = this.environmentService.backendURL + '/api/employer/existunique';
        this.vacationsCategoryUrl = this.environmentService.backendURL + '/api/vacation/categories';
        this.materialsPaymentUrl = this.environmentService.backendURL + '/api/material/payment';
        this.buildingsUrl = this.environmentService.backendURL + '/api/buildings';
        this.carsUrl = this.environmentService.backendURL + '/api/car';
        this.officesUrl = this.environmentService.backendURL + '/api/office';
    }

    /**
     * Handle Http operation that failed.
     * Let the app continue.
     * @param operation - name of the operation that failed
     * @param result - optional value to return as the observable result
     */
    private handleError<T>(operation = 'operation', result?: T) {
        return (error: {[key: string]: string}): Observable<T | undefined> => {
            // TODO: send the error to remote logging infrastructure
            console.error(error); // log to console instead

            // TODO: better job of transforming error for user consumption
            this.log(`${operation} failed: ${error.message}`);

            // Let the app keep running by returning an empty result.
            return of(result);
        };
    }

    /** Log a settingservice message with the MessageService */
    private log(message: string): void {
        this.messageService.addNotification(`Settings: ${message}`, false);
    }

    addCompany(company: CompanyInput): Observable<Company> {
        return this.http.post<Company>(this.companyrUrl, company, httpOptions);
    }

    editCompany(company: CompanyInput): Observable<Company> {
        return this.http.post<Company>(this.companyrUrl, company, httpOptions);
    }

    removeCompany(company: Company): Observable<Company> {
        return this.http.post<Company>(this.companyrUrl, company, httpOptions);
    }

    addBuilding(building: BuildingInput): Observable<Building> {
        return this.http.post<Building>(this.buildingsUrl, building, httpOptions);
    }

    editBuilding(building: BuildingInput): Observable<Building> {
        return this.http.post<Building>(this.buildingsUrl, building, httpOptions);
    }

    removeBuilding(building: Building): Observable<Building> {
        return this.http.post<Building>(this.buildingsUrl, building, httpOptions);
    }

    addDepartment(department: DepartmentInput): Observable<Department> {
        return this.http.post<Department>(this.departmentUrl, department, httpOptions);
    }

    editDepartment(department: DepartmentInput): Observable<Department> | undefined {
        if (!department.id) {
            return;
        }

        const url = `${this.departmentUrl}/update/${department.id}`;

        this.dataService.clearDepartmentsCache();

        return this.http.put<Department>(url, department, httpOptions);
    }

    removeDepartment(department: Department): Observable<Department> {
        return this.http.post<Department>(this.departmentUrl, department, httpOptions);
    }

    addRole(role: RoleInput): Observable<Role> {
        return this.http.post<Role>(this.roleUrl, role, httpOptions);
    }

    editRole(role: RoleInput): Observable<Role> {
        return this.http.post<Role>(this.roleUrl, role, httpOptions);
    }

    removeRole(role: Role): Observable<Role> {
        return this.http.post<Role>(this.roleUrl, role, httpOptions);
    }

    addEmployer(employer: EmployerInput): Observable<Employer> {
        return this.http.post<Employer>(this.employerUrl, employer, httpOptions);
    }

    editEmployer(employer: EmployerInput): Observable<Employer> {
        return this.http.post<Employer>(this.employerUrl, employer, httpOptions);
    }

    removeEmployer(employer: Employer): Observable<Employer> {
        return this.http.post<Employer>(this.employerUrl, employer, httpOptions);
    }

    addVacationCategory(category: VacationCategoryInput): Observable<VacationCategory> {
        return this.http.post<VacationCategory>(this.vacationsCategoryUrl, category, httpOptions);
    }

    addMaterialPayment(payment: MaterialPayment): Observable<MaterialPayment> {
        return this.http.post<MaterialPayment>(this.materialsPaymentUrl, payment, httpOptions);
    }

    getEmployer(id: number): Observable<Employer | undefined> {
        const url = `${this.employerUrl}/${id}`;

        return this.http.get<Employer>(url)
            .pipe(
                tap(() => {
                    this.log(`fetched employer id=${id}`);
                }),
                catchError(this.handleError<Employer>(`getEmployer id=${id}`)),
                share(),
            );
    }

    isCompanyRuianRegistered(ruian: number): Observable<boolean | undefined> {
        return this.http
            .post<boolean>(this.companyUniqueUrl, JSON.stringify({ruian}), httpOptions)
            .pipe(
                tap(() => {
                    this.log(`ruian found ruian=${ruian}`);
                }),
                catchError(this.handleError<boolean>(`error find ruian=${ruian}`)),
                share(),
            );
    }

    isCompanyIcoRegistered(ico: number): Observable<boolean | undefined> {
        return this.http
            .post<boolean>(this.companyUniqueUrl, JSON.stringify({ico}), httpOptions)
            .pipe(
                tap(() => {
                    this.log(`ico found ico=${ico}`);
                }),
                catchError(this.handleError<boolean>(`error find ico=${ico}`)),
                share(),
            );
    }

    isCompanyNameRegistered(name: string): Observable<boolean | undefined> {
        return this.http
            .post<boolean>(this.companyUniqueUrl, JSON.stringify({name}), httpOptions)
            .pipe(
                tap(() => {
                    this.log(`company name found company name=${name}`);
                }),
                catchError(this.handleError<boolean>(`error find company name=${name}`)),
                share(),
            );
    }

    isDepartmentNameRegistered(name: string): Observable<boolean | undefined> {
        return this.http
            .post<boolean>(this.departmentUniqueUrl, JSON.stringify({name}), httpOptions)
            .pipe(
                tap(() => {
                    this.log(`department found department=${name}`);
                }),
                catchError(this.handleError<boolean>(`error find department=${name}`)),
                share(),
            );
    }

    isEmployerNameRegistered(name: string): Observable<boolean | undefined> {
        return this.http
            .post<boolean>(this.employerUniqueUrl, JSON.stringify({name}), httpOptions)
            .pipe(
                tap(() => {
                    this.log(`employer found employer=${name}`);
                }),
                catchError(this.handleError<boolean>(`error find employer=${name}`)),
                share(),
            );
    }

    isRoleNameRegistered(name: string): Observable<boolean | undefined> {
        return this.http
            .post<boolean>(this.roleUniqueUrl, JSON.stringify({name}), httpOptions)
            .pipe(
                tap(() => {
                    this.log(`role found role=${name}`);
                }),
                catchError(this.handleError<boolean>(`error find role=${name}`)),
                share(),
            );
    }

    addOffice(office: OfficeInput): Observable<Office> {
        this.dataService.clearOfficesCache();

        return this.http.post<Office>(this.officesUrl, office, httpOptions);
    }

    updateOffice(office: OfficeInput): Observable<Office> | undefined {
        if (!office.id) {
            return;
        }

        const url = `${this.officesUrl}/update/${office.id}`;

        this.dataService.clearOfficesCache();

        return this.http.put<Office>(url, office, httpOptions);
    }

    deleteOffice(office: Office): Observable<Office> {
        const headers = new HttpHeaders({'Content-Type': 'application/json'});
        const params = new HttpParams().set('deleted_by', office.deleted_by.toString());
        const url = `${this.officesUrl}/${office.id}`;

        this.dataService.clearOfficesCache();

        return this.http.delete<Office>(url, {headers, params});
    }

    addCar(car: CarInput): Observable<Car> {
        this.dataService.clearCarsCache();

        return this.http.post<Car>(this.carsUrl, car, httpOptions);
    }

    updateCar(car: CarInput): Observable<Car> | undefined {
        if (!car.id) {
            return;
        }

        const url = `${this.carsUrl}/update/${car.id}`;

        this.dataService.clearCarsCache();

        return this.http.put<Car>(url, car, httpOptions);
    }

    deleteCar(car: Car): Observable<Car> {
        const headers = new HttpHeaders({'Content-Type': 'application/json'});
        const params = new HttpParams().set('deleted_by', car.deleted_by.toString());
        const url = `${this.carsUrl}/${car.id}`;

        this.dataService.clearCarsCache();

        return this.http.delete<Car>(url, {headers, params});
    }

    checkCarsStatus(start: string, end: string, cars: Car[]): Car[] {
        return cars.filter(
            (car: Car) =>
                car.bookable && this.bookingService.checkAvailableTerm(start, end, car.bookings),
        );
    }
}
