import {
    AfterViewChecked,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnInit,
    ViewChild,
} from '@angular/core';
import { Tickets } from '@src/app/_models/ticket/tickets';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '@src/app/_services/authentication.service';
import { User } from '@src/app/_models/user/user';
import { Department } from '@src/app/_models/department/department';
import { PermissionService } from '@src/app/_services/permission.service';
import { AbstractControl, FormBuilder, FormGroup } from '@angular/forms';
import { MessageService } from '@src/app/_services/message.service';
import { Observable, Observer } from 'rxjs';
import { registerLocaleData } from '@angular/common';
import { DataService } from '@src/app/_services/data.service';
import { DomSanitizer } from '@angular/platform-browser';
import { Tags } from '@src/app/_models/tags/tags';
import { ButtonPropsModel, DialogComponent } from '@syncfusion/ej2-angular-popups';
import {
    ColumnModel,
    DataResult,
    EditSettingsModel,
    ExcelExportProperties,
    FilterSettingsModel,
    GridComponent,
    GroupSettingsModel,
    PageSettingsModel,
    RowDataBoundEventArgs,
    SelectionSettingsModel,
    ToolbarItems,
} from '@syncfusion/ej2-angular-grids';
import { ChangeEventArgs, DropDownListComponent } from '@syncfusion/ej2-angular-dropdowns';
import { ClickEventArgs } from '@syncfusion/ej2-angular-navigations';

import moment from 'moment';
import localeCs from '@angular/common/locales/cs';
import { TextBoxComponent } from '@syncfusion/ej2-angular-inputs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TicketGridItems, TicketsService } from '@src/app/features/tickets/tickets.service';
import { TicketComponent } from '@src/app/features/tickets/components';
import { NavHandlerService } from '@src/app/_services/nav-handler.service';
import { TimeagoIntl } from 'ngx-timeago';

moment.updateLocale('cs', { workingWeekdays: [1, 2, 3, 4, 5] });
registerLocaleData(localeCs);

@UntilDestroy()
@Component({
    selector: 'app-ticket-queue',
    templateUrl: './ticket-queue.component.html',
    styleUrls: ['./ticket-queue.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TicketQueueComponent extends TicketComponent implements OnInit, AfterViewChecked {
    // Dialogs
    renderTicketFormDialog = false;

    // Dropdowns
    height = '240px';

    categoryFilterData: Array<string> = [];

    tagsAutocomplete: Array<{ id: number; name: string }> = [];

    // Grid
    currencyFormat: { format: string } = { format: '#,###.00\',- CZK\'' };

    dateFormat: string;

    toolbar: ToolbarItems[] | object;

    data: object;

    ticketExcelExport: object[] = [];

    fields: object = { text: 'label', value: 'value' };

    filterSettings: FilterSettingsModel;

    filterCheckboxSettings: FilterSettingsModel;

    selectionSettings: SelectionSettingsModel;

    editSettings: EditSettingsModel;

    groupOptions: GroupSettingsModel;

    pageOptions: PageSettingsModel;

    expendituresColumnsPrice: ColumnModel[];

    expendituresColumnsItems: ColumnModel[];

    filterDate: object;

    sortOptions: object;

    requiredRules: object;

    exportCurrentPage = false;

    // Forms
    filterTagsForm: FormGroup;

    filterTag: Tags[] | null | undefined;

    isCreate = false;

    isUpdate = false;

    isCopy = false;

    // Variables
    tickets: Tickets[] | undefined = [];

    tags: Tags[] = [];

    clickedRow: Tickets[];

    clickedRow$ = new Observable<Tickets[]>((observer: Observer<Tickets[]>) => {
        observer.next(this.clickedRow);
    });

    // Loaders
    tableLoad = true;

    // SF buttons
    buttonsCancel: ButtonPropsModel[] = [
        {
            click: (): void => {
                this.confirmationCancel.hide();
            },
            buttonModel: {
                isPrimary: true,
                content: 'OK',
            },
        },
    ];

    buttonsDelete: ButtonPropsModel[] = [
        {
            click: (): void => {
                this.deleteTicket();
            },
            isFlat: false,
            buttonModel: {
                content: 'OK',
                cssClass: 'e-success e-outline',
            },
        },
        {
            click: (): void => {
                this.confirmationDelete.hide();
            },
            buttonModel: {
                content: 'ZRUŠIT',
                cssClass: 'e-danger e-outline',
            },
        },
    ];

    // Dialogs
    @ViewChild('confirmationDelete') confirmationDelete: DialogComponent;

    @ViewChild('confirmationCancel') confirmationCancel: DialogComponent;

    // Dropdowns
    @ViewChild('viewFilter') viewFilterInstance: DropDownListComponent;

    // Grid
    @ViewChild('ticketsGrid') gridTickets: GridComponent;

    @ViewChild('searchtext') searchtextObj: TextBoxComponent;

    constructor(
        authenticationService: AuthenticationService,
        router: Router,
        ref: ChangeDetectorRef,
        navHandlerService: NavHandlerService,
        route: ActivatedRoute,
        dataService: DataService,
        intl: TimeagoIntl,
        private readonly formBuilder: FormBuilder,
        private readonly ticketService: TicketsService,
        private readonly sanitizer: DomSanitizer,
        private readonly permissionService: PermissionService,
        private readonly messages: MessageService,
    ) {
        super(
            authenticationService,
            router,
            ref,
            navHandlerService,
            route,
            dataService,
            intl,
        );
    }

    get getTagsFilter(): { [key: string]: AbstractControl } {
        return this.filterTagsForm.controls;
    }

    ngOnInit(): void {
        this.filterSettings = {
            type: 'Menu',
            showFilterBarStatus: true,
            ignoreAccent: true,
        };

        this.filterCheckboxSettings = {
            type: 'CheckBox',
            showFilterBarStatus: true,
            ignoreAccent: true,
        };

        this.filterSettings = {
            type: 'Excel',
            showFilterBarOperator: true,
            showFilterBarStatus: true,
            ignoreAccent: true,
        };

        this.selectionSettings = {
            persistSelection: true,
            type: 'Multiple',
            checkboxOnly: true,
        };

        this.toolbar = [
            {
                text: 'Občerstvit data',
                tooltipText: 'Občerstvit data',
                prefixIcon: 'e-refresh',
                id: 'customRefresh',
            },
            'Print',
            'ExcelExport',
            'Add',
            'Edit',
            {
                text: 'Smazat',
                tooltipText: 'Smazat ticket',
                prefixIcon: 'e-remove',
                id: 'delete',
            },
            {
                text: 'Kopírovat',
                tooltipText: 'Kopírovat ticket',
                prefixIcon: 'e-copy',
                id: 'copy',
            },
            {
                text: 'Detail',
                tooltipText: 'Detail ticketu',
                prefixIcon: 'e-openfolder',
                id: 'detail',
            },
        ];

        this.categoryFilterData = [
            'všechny tickety',
            'fronta ticketů',
            'vyřízené tickety',
            'smazané tickety',
        ];
        this.pageOptions = { pageSize: 50 };
        this.dateFormat = 'dd.MM.yyyy HH:mm';
        this.filterDate = { type: 'Menu', params: { format: this.dateFormat } };
        this.groupOptions = {
            showGroupedColumn: true,
            disablePageWiseAggregates: false,
        };

        this.editSettings = {
            showConfirmDialog: false,
            showDeleteConfirmDialog: false,
            allowEditing: true,
            allowAdding: true,
            allowDeleting: true,
            allowEditOnDblClick: false,
            mode: 'Dialog',
        };
        this.requiredRules = { required: true };

        this.dataService.tagSource.pipe(untilDestroyed(this))
            .subscribe(
                (data: Tags[]) => {
                    this.tags = data;
                    this.tagsAutocomplete = [];
                    data.map((tag: Tags) => {
                        this.tagsAutocomplete = [
                            ...this.tagsAutocomplete,
                            {
                                id: tag.id,
                                name: tag.name,
                            },
                        ];
                    });
                },
                error => {
                    console.error(error);
                },
            );

        this.filterTagsForm = this.formBuilder.group({
            tags: [[]],
        });
    }

    ngAfterViewChecked(): void {
        this.route.queryParamMap.pipe(untilDestroyed(this))
            .subscribe(params => {
                if (params.get('form') === 'addTicket') {
                    this.isCreate = true;
                    this.isUpdate = false;
                    this.isCopy = false;
                    this.renderTicketFormDialog = true;
                    this.ref.markForCheck();
                }
            });
    }

    loadData(): DataResult[] {
        this.gridTickets.clearRowSelection();

        console.info('NEW DATA BOUND');

        const elements: TicketGridItems[] = [];

        if (this.tickets && this.tickets.length > 0) {
            let filteredData = this.tickets;

            if (this.filterTag) {
                const resultTags: Tickets[] = [];

                filteredData.map((ticket: Tickets) => {
                    ticket.tags.map((tag: Tags) => {
                        if (this.filterTag?.find(filter => filter.id === tag.id)) {
                            resultTags.push(ticket);
                        }
                    });
                });
                filteredData = resultTags;
            }

            // eslint-disable-next-line complexity
            filteredData.map((ticket: Tickets) => {
                let departmentsStr = '';
                let usersStr = '';
                let tags = '';
                let tagsStr = '';

                ticket.departments.forEach((department: Department, i, row) => {
                    if (i + 1 === row.length) {
                        departmentsStr += department.name;
                    }
                    else {
                        departmentsStr += department.name + ', ';
                    }
                });

                ticket.users.forEach((user: User, i, row) => {
                    if (i + 1 === row.length) {
                        usersStr += user.fullname;
                    }
                    else {
                        usersStr += user.fullname + ', ';
                    }
                });

                ticket.tags = ticket.tags.sort((a, b) => {
                    const stringA = a.name;
                    const stringB = b.name;

                    return stringA > stringB ? 1 : -1;
                });

                ticket.tags.map((tag: Tags, i, row) => {
                    // eslint-disable-next-line max-len
                    tags += `<span class="e-badge e-badge-pill mr-2 ${
                        tag.fontColor && tag.fontColor === 'white'
                            ? 'text-white-imp'
                            : 'text-black-imp'
                    }" style="background-color: ${tag.color}; border-color: ${tag.color};">${
                        tag.name
                    }</span><br/>`;

                    if (i + 1 === row.length) {
                        tagsStr += tag.name;
                    }
                    else {
                        tagsStr += tag.name + ', ';
                    }
                });

                elements.push({
                    id: ticket.id,
                    category_id: ticket.category_id,
                    status_id: ticket.status_id,
                    created_by: ticket.creator.id,
                    creator_str: ticket.creator.fullname,
                    creator_img: ticket.creator.personalphoto,
                    business_id: ticket.business_id,
                    subject: ticket.subject,
                    maintask: ticket.maintask,
                    category: ticket.category.name,
                    status:
                        ticket.status.name === 'solved'
                            ? 'dokončen'
                            : ticket.status.name === 'in queue'
                                ? 've frontě'
                                : 'smazán',
                    due: ticket.due === 'overdue' ? 'po termínu' : 'v termínu',
                    users: usersStr,
                    departments: departmentsStr,
                    tags: this.sanitizer.bypassSecurityTrustHtml(tags),
                    start_deadline: ticket.start_deadline ? new Date(ticket.start_deadline) : null,
                    deadline: ticket.deadline ? new Date(ticket.deadline) : null,
                    created_date: new Date(ticket.created_date),
                    updated_date: ticket.updated_date ? new Date(ticket.updated_date) : null,
                    deleted_date: ticket.deleted_date ? new Date(ticket.deleted_date) : null,
                    finished_at: ticket.finished_at ? new Date(ticket.finished_at) : null,
                });

                this.ticketExcelExport.push({
                    id: ticket.id,
                    created_by: ticket.creator.id,
                    creator_str: ticket.creator.fullname,
                    creator_img: ticket.creator.personalphoto,
                    subject: ticket.subject,
                    maintask: ticket.maintask.replace(/<[^>]*>?/gm, ''),
                    category: ticket.category.name,
                    status:
                        ticket.status.name === 'solved'
                            ? 'dokončen'
                            : ticket.status.name === 'in queue'
                                ? 've frontě'
                                : 'smazán',
                    due: ticket.due === 'overdue' ? 'po termínu' : 'v termínu',
                    users: usersStr,
                    departments: departmentsStr,
                    tags: tagsStr,
                    start_deadline: ticket.start_deadline ? new Date(ticket.start_deadline) : null,
                    deadline: ticket.deadline ? new Date(ticket.deadline) : null,
                    created_date: new Date(ticket.created_date),
                    updated_date: ticket.updated_date ? new Date(ticket.updated_date) : null,
                    deleted_date: ticket.deleted_date ? new Date(ticket.deleted_date) : null,
                    finished_at: ticket.finished_at ? new Date(ticket.finished_at) : null,
                });
            });
        }

        this.tableLoad = false;
        this.ref.markForCheck();

        return elements as unknown as DataResult[];
    }

    onCreated(): void {
        this.disableToolBarBtn();
        document.getElementById('ticketsGrid')
            ?.addEventListener('click', (args) => {
                if (!(args.target as any).classList.contains('e-rowcell')) {
                    const rowElement = (args.target as any).closest('.e-rowcell');

                    if (rowElement) {
                        const rowInfo = this.gridTickets.getRowInfo(rowElement as EventTarget); // get row information

                        if (rowInfo.cellIndex !== 0) {
                            void this.router.navigate(['/tickets/detail', (rowInfo.rowData as any)?.id]);
                        }
                    }
                }
                else {
                    const rowInfo = this.gridTickets.getRowInfo(args.target!); // get row information

                    if (rowInfo.cellIndex !== 0) {
                        void this.router.navigate(['/tickets/detail', (rowInfo.rowData as any)?.id]);
                    }
                }
            });
    }

    setInitialGridFiltering(): void {
        this.gridTickets.clearFiltering();
        // this.gridTickets.filterByColumn('created_by', 'equal', this.currentUser.id, 'and', false, true);
        // this.viewFilterInstance.value = 'moje žádanky - všechny';
        this.ref.markForCheck();
    }

    onLoad(): void {
        (this.gridTickets as any).adaptiveDlgTarget = document.getElementsByClassName('e-mobile-content')[0] as HTMLElement;

        const wrapper1 = document.getElementById('scroll_wrapper1_tickets');
        const wrapper2 = document.getElementById('scroll_wrapper2_tickets');

        if (wrapper1 && wrapper2) {
            wrapper1.onscroll = (): void => {
                wrapper2.scrollLeft = wrapper1.scrollLeft;
            };

            wrapper2.onscroll = (): void => {
                wrapper1.scrollLeft = wrapper2.scrollLeft;
            };
        }

        this.dataService.ticketSource.pipe(untilDestroyed(this))
            .subscribe(
                (tickets: Tickets[] | undefined) => {
                    this.tickets = tickets;

                    if (this.tickets && this.tickets.length > 0) {
                        this.data = this.gridTickets.dataSource = this.loadData();
                        this.sortOptions = {
                            columns: [
                                { field: 'created_date', direction: 'Descending' },
                                { field: 'id', direction: 'Descending' },
                            ],
                        };
                    }
                    else {
                        this.tableLoad = false;
                    }
                },
                error => {
                    console.error(error);
                    this.tableLoad = false;
                    this.ref.markForCheck();
                },
            );
    }

    search(): void {
        this.gridTickets.search(this.searchtextObj.value);
    }

    onDataBound(): void {
        this.rowSelected();

        const width = document.getElementById('ticketsGrid_content_table')?.offsetWidth;
        const parent1 = document.getElementById('scroll_div_tickets');
        const parent2 = document.getElementById('grid_parent_tickets');

        if (parent1 && parent2 && width) {
            parent1.style.width = `${width}px`;
            parent2.style.width = `${width}px`;
        }

        this.gridTickets.autoFitColumns();
        this.ref.markForCheck();
    }

    rowDataBound(args: RowDataBoundEventArgs): void {
        const data = args.data as Tickets;

        if (args.row && data.deleted_date) {
            args.row.getElementsByClassName('e-gridchkbox')[0].classList.add('disablecheckbox');
            args.row
                .getElementsByClassName('e-checkbox-wrapper')[0]
                .classList.add('disablecheckbox');
            args.row.classList.add('e-disabled');
        }

        if (
            this.currentUser &&
            args.row &&
            data.created_by !== this.currentUser.id &&
            !this.permissionService.checkUserISAdministrative(this.currentUser)
        ) {
            args.row.getElementsByClassName('e-gridchkbox')[0].classList.add('disablecheckbox');
            args.row
                .getElementsByClassName('e-checkbox-wrapper')[0]
                .classList.add('disablecheckbox');
        }

        this.ref.markForCheck();
    }

    onStatusFilterChange(e: ChangeEventArgs): void {
        if (e.value === 'všechny tickety') {
            this.gridTickets.clearFiltering();
        }
        else if (e.value === 'fronta ticketů') {
            this.gridTickets.filterSettings = {
                columns: [
                    {
                        field: 'finished_at',
                        operator: 'equal',
                        value: null as unknown as string,
                        predicate: 'and',
                        matchCase: false,
                        ignoreAccent: true,
                    },
                    {
                        field: 'deleted_date',
                        operator: 'equal',
                        value: null as unknown as string,
                        predicate: 'and',
                        matchCase: false,
                        ignoreAccent: true,
                    },
                ],
            };
        }
        else if (e.value === 'vyřízené tickety') {
            this.gridTickets.filterSettings = {
                columns: [
                    {
                        field: 'finished_at',
                        operator: 'notequal',
                        value: null as unknown as string,
                        predicate: 'and',
                        matchCase: false,
                        ignoreAccent: true,
                    },
                    {
                        field: 'deleted_date',
                        operator: 'equal',
                        value: null as unknown as string,
                        predicate: 'and',
                        matchCase: false,
                        ignoreAccent: true,
                    },
                ],
            };
        }
        else if (e.value === 'smazané tickety') {
            this.gridTickets.clearFiltering();
            this.gridTickets.filterByColumn('deleted_date', 'notequal', null, 'and', false, true);
        }
    }

    cancelBtnClick(): void {
        this.searchtextObj.value = '';
        this.gridTickets.searchSettings.key = '';
    }

    toolbarClick(args: ClickEventArgs): void {
        const xlsProp: ExcelExportProperties = {
            dataSource: this.ticketExcelExport,
            fileName: 'js_tickets_export.xlsx',
            enableFilter: true,
            exportType: this.exportCurrentPage ? 'CurrentPage' : 'AllPages',
        };

        if (args.item.id === 'ticketsGrid_excelexport') {
            void this.gridTickets.excelExport(xlsProp);
        }
        else if (args.item.id === 'delete') {
            if (this.gridTickets.getSelectedRecords().length !== 0) {
                this.confirmationDelete.content = `Potvrďte smazání ${this.clickedRow.length} ticketů`;
                this.confirmationDelete.show();
            }
            else {
                this.confirmationCancel.content = 'Musíte vybrat alespoň jeden ticket';
                this.confirmationCancel.show();
            }
        }
        else if (args.item.id === 'copy') {
            if (this.gridTickets.getSelectedRecords().length !== 0) {
                this.isUpdate = false;
                this.isCreate = false;
                this.isCopy = true;
                this.renderTicketFormDialog = true;
                this.ref.markForCheck();
            }
            else {
                this.confirmationCancel.content = 'Musíte vybrat alespoň jeden ticket';
                this.confirmationCancel.show();
            }
        }
        else if (args.item.id === 'detail') {
            void this.router.navigate(['/tickets/detail', this.clickedRow[0].id]);
        }
        else if (args.item.id === 'customRefresh') {
            this.tableLoad = true;
            console.info('refresh DATA!');
            this.ref.markForCheck();
            this.dataService.clearTicketsCache();
            this.dataService.setTicketsDataSource();
        }
    }

    enableToolbarBtn(): void {
        this.gridTickets.toolbarModule.enableItems(
            ['ticketsGrid_edit', 'delete', 'copy', 'detail'],
            true,
        );
    }

    disableToolBarBtn(): void {
        this.gridTickets.toolbarModule.enableItems(
            ['ticketsGrid_edit', 'delete', 'copy', 'detail'],
            false,
        );
    }

    rowSelected(): void {
        this.clickedRow = [];

        const selectedrecords: object[] = this.gridTickets.getSelectedRecords();

        if (selectedrecords.length > 0) {
            selectedrecords.map((row: Tickets) => {
                const tempData: Tickets | undefined = this.tickets?.find(x => x.id === row.id);

                if (tempData) {
                    this.clickedRow.push(tempData);
                }

                this.ref.markForCheck();
            });

            this.clickedRow$ = new Observable<Tickets[]>((observer: Observer<Tickets[]>) => {
                observer.next(this.clickedRow);
            });
            this.enableToolbarBtn();
        }
        else {
            this.disableToolBarBtn();
            this.clickedRow$ = new Observable<Tickets[]>((observer: Observer<Tickets[]>) => {
                observer.next(this.clickedRow);
            });
        }
    }

    actionBegin(args): void {
        if (args.requestType === 'add' || args.requestType === 'beginEdit') {
            args.cancel = true;

            if (args.requestType === 'beginEdit') {
                this.isUpdate = true;
                this.isCreate = false;
                this.isCopy = false;
            }

            if (args.requestType === 'add') {
                this.isCreate = true;
                this.isUpdate = false;
                this.isCopy = false;
            }

            this.renderTicketFormDialog = true;
            this.ref.markForCheck();
        }
    }

    actionComplete(args): void {
        if (args.requestType === 'beginEdit' || args.requestType === 'add') {
            args.form.ej2_instances[0].rules = {};
        }
    }

    deleteTicket(): void {
        const promises: Promise<Tickets | undefined>[] = [];
        let rejects = 0;

        this.tableLoad = true;

        for (const row of this.clickedRow) {
            if (
                this.currentUser &&
                !row.deleted_date &&
                !row.finished_at &&
                (this.permissionService.checkUserISTicketAdmin(this.currentUser) ||
                    row.created_by === this.currentUser.id)
            ) {
                row.deleted_by = this.currentUser.id;

                const declineObj = this.ticketService.deleteTicket(row)
                    ?.toPromise();

                if (declineObj) {
                    promises.push(declineObj);
                }
            }
            else {
                rejects++;
            }
        }

        void Promise.all(promises)
            .then(result => {
                if (result.length > 0) {
                    const body = `Celkem smazáno: ${result.length} ticketů`;
                    const options = { progressBar: true, timeOut: 5000 };

                    this.messages.showSuccess('Smazání úspěšně dokončeno', body, options);
                }

                if (rejects > 0) {
                    const body = `Celkem u: ${rejects} ticketů`;
                    const options = { progressBar: true, timeOut: 5000 };

                    this.messages.showError('Nedostatečné oprávnění', body, options);
                }

                this.gridTickets.clearRowSelection();
                this.dataService.setTicketsDataSource();
                this.rowSelected();
                this.tableLoad = false;
                this.confirmationDelete.hide();
                this.ref.markForCheck();
            });
    }

    onAdd(): void {
        this.filterTags();
    }

    onRemove(): void {
        this.filterTags();
    }

    filterTags(): void {
        if (this.getTagsFilter.tags.value.length > 0) {
            this.filterTag = this.getTagsFilter.tags.value;

            const tags = this.filterTag ? this.filterTag : false;

            console.info('FILTER: tags: ' + JSON.stringify(tags));
        }
        else {
            this.filterTagsForm.controls.tags.reset(null, { emitEvent: false });
            this.filterTag = null;
        }

        this.data = this.gridTickets.dataSource = this.loadData();
    }
}
