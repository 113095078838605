<app-sim
  (uploadDialogState)="renderMobileImport = $event"
  [importType]="this.mobileImportType"
  [isVisible]="this.renderMobileImport"
></app-sim>
<div class="container">
  <div class="row">
    <div class="col">
      <div class="card bg-transparent">
        <div class="e-card-header d-flex justify-content-between p-3">
                    <span>
                        <i
                          aria-hidden="true"
                          class="fa-light rounded fa-sim-cards fa-2x p-2 text-primary mr-2"
                        ></i>
                        Mobilní tarify
                    </span>
        </div>
        <div class="e-card-content text-center p-0 m-0">
          <table class="table table-responsive table-bordered">
            <tr>
              <th>poslední import</th>
              <th>status</th>
              <th>počet</th>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td></td>
            </tr>
          </table>
        </div>
        <div class="card-footer">
          <div class="d-flex justify-content-end">
            <a
              [queryParams]="{ view: 'import', type: 'tmcz' }"
              [routerLink]="['/sync']"
              class="e-btn e-small e-info e-outline btn-p-zero m-1"
            >Importovat data TMCZ</a>
            <a
              [queryParams]="{ view: 'import', type: 'vdf' }"
              [routerLink]="['/sync']"
              class="e-btn e-small e-info e-outline btn-p-zero m-1"
            >Importovat data VDF</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
