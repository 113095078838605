import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    ViewChild,
} from '@angular/core';
import {KnowledgeFile} from '@src/app/_models/knowledge/knowledge-file';
import {TicketFile} from '@src/app/_models/ticket/ticket-file';
import {GalleryImages} from '@src/app/_models/images/images.type';
import {Lightbox} from 'ngx-lightbox';
import {ViewerType} from '@src/app/_viewer/document-viewer.component';
import {ViewportScroller} from '@angular/common';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {saveAs} from 'file-saver';
import {MaterialFile} from '@src/app/_models/material/material-file';
import {DialogComponent} from '@syncfusion/ej2-angular-popups';
import {RemovingEventArgs} from '@syncfusion/ej2-angular-inputs';
import {User} from '@src/app/_models/user/user';
import {AuthenticationService} from '@src/app/_services/authentication.service';
import {KnowledgeService} from '@src/app/features/knowledge/knowledge.service';
import {TicketsService} from '@src/app/features/tickets/tickets.service';
import {MaterialService} from '@src/app/features/material/material.service';

@UntilDestroy()
@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'app-file-viewer',
    templateUrl: './file-viewer.component.html',
    styleUrls: ['./file-viewer.component.scss'],
})
export class FileViewerComponent implements OnChanges {
    loading = true;
    page = 1;
    pageFiles = 1;
    pageSize = 5;
    deleteFileId: number;
    viewers:
        | {
        name: ViewerType;
        docs: string[];
        custom: boolean;
        acceptedUploadTypes: string;
        viewerUrl?: string;
    }[]
        | undefined = [
        {
            name: 'google',
            docs: [],
            custom: true,
            acceptedUploadTypes:
            // eslint-disable-next-line max-len
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/msword, application/vnd.ms-powerpoint, text/csv, application/vnd.ms-excel, application/pdf',
        },
        {
            name: 'pdf',
            docs: [],
            custom: false,
            acceptedUploadTypes: 'application/pdf',
        },
    ];
    selectedViewer:
        | {
        name: ViewerType;
        docs: string[];
        custom: boolean;
        acceptedUploadTypes: string;
        viewerUrl?: string;
    }
        | undefined = this.viewers?.[0];
    selectedDoc: string | undefined = this.selectedViewer?.docs[0];
    currentUser: User | null;
    @Input() files: KnowledgeFile[] | MaterialFile[] | TicketFile[] = [];
    @Input() images: GalleryImages[] = [];
    @Output() readonly changeMadeEvent = new EventEmitter<boolean>();
    @Output() readonly deleteFileEventEvent = new EventEmitter<number>();
    @ViewChild('deleteFileDialog') deleteFileDialog: DialogComponent;
    buttonsDeleteFileDialog = [
        {
            click: this.removeFile.bind(this),
            buttonModel: {
                content: 'OK',
                cssClass: 'e-success e-outline',
                isFlat: false,
            },
        },
        {
            click: (): void => {
                this.deleteFileDialog.hide();
            },
            buttonModel: {content: 'ZRUŠIT', cssClass: 'e-danger e-outline'},
        },
    ];

    constructor(
        private readonly lightbox: Lightbox,
        private readonly ref: ChangeDetectorRef,
        private readonly vps: ViewportScroller,
        private readonly knowledgeService: KnowledgeService,
        private readonly ticketsService: TicketsService,
        private readonly materialService: MaterialService,
        private readonly authenticationService: AuthenticationService,
    ) {
        if (this.selectedViewer) {
            this.selectedViewer.docs = [];
        }

        this.currentUser = this.authenticationService.currentUserValue;
    }

    ngOnChanges(): void {
        if (this.files.length > 0) {
            this.selectedViewer = this.viewers?.[0];
            this.selectedDoc = this.selectedViewer?.docs[0];
        }

        this.loading = false;
    }

    open(index: number): void {
        this.lightbox.open(this.images, index);
    }

    close(): void {
        this.lightbox.close();
    }

    onFileRemove(args: RemovingEventArgs): void {
        args.postRawFile = false;
        this.deleteFileDialog.show();
    }

    selectViewer(viewerName: ViewerType): void {
        if (viewerName !== this.selectViewer.name) {
            this.selectedViewer = this.viewers?.find(v => v.name === viewerName);
            this.selectedDoc = this.selectedViewer?.docs[0];
        }
    }

    scrollToTarget(id: string): void {
        this.vps.scrollToAnchor(id);
        this.ref.markForCheck();
    }

    downloadFile(file: KnowledgeFile | MaterialFile | TicketFile): void {
        if (file.hasOwnProperty('knowledge_id')) {
            this.knowledgeService
                .downloadFile(file as KnowledgeFile)
                .pipe(untilDestroyed(this))
                .subscribe(
                    data => {
                        saveAs(data, file.name);
                    },
                    err => {
                        alert('Chyba během stahování souboru.');
                        console.error(err);
                    },
                );
        }

        if (file.hasOwnProperty('ticket_id')) {
            this.ticketsService
                .downloadFile(file as TicketFile)
                .pipe(untilDestroyed(this))
                .subscribe(
                    data => {
                        saveAs(data, file.name);
                    },
                    err => {
                        alert('Chyba během stahování souboru.');
                        console.error(err);
                    },
                );
        }

        if (file.hasOwnProperty('material_id')) {
            this.materialService
                .downloadFile(file as MaterialFile)
                .pipe(untilDestroyed(this))
                .subscribe(
                    data => {
                        saveAs(data, file.name);
                    },
                    err => {
                        alert('Chyba během stahování souboru.');
                        console.error(err);
                    },
                );
        }
    }

    removeFile(): void {
        this.deleteFileEventEvent.emit(this.deleteFileId);
        this.loading = false;
        this.deleteFileDialog.hide();
    }
}
