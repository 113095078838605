import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    HostListener,
    Input,
    OnChanges,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import {AbstractControl, FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Department} from '@src/app/_models/department/department';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {DataService} from '@src/app/_services/data.service';

import {EmitType} from '@syncfusion/ej2-base';
import {DropDownListComponent, FilteringEventArgs} from '@syncfusion/ej2-angular-dropdowns';
import {Query} from '@syncfusion/ej2-data';
import {DialogComponent} from '@syncfusion/ej2-angular-popups';
import {Company} from '@src/app/_models/company/company';
import {ComponentCanDeactivate} from '@src/app/_guards/changes.guard';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {Observable} from 'rxjs';
import {User} from '@src/app/_models/user/user';
import {AuthenticationService} from '@src/app/_services/authentication.service';
import {CompanyInput, SettingsService} from '@src/app/features/settings/settings.service';
import {UsersService} from '@src/app/features/users/users.service';
import {MessageService} from '@src/app/_services/message.service';
import {CustomValidators} from '@src/app/features/users/custom-validators';

@UntilDestroy()
@Component({
    selector: 'app-company-form',
    templateUrl: './company-form.component.html',
    styleUrls: ['./company-form.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CompanyFormComponent implements OnInit, OnChanges, ComponentCanDeactivate {
    // Forms
    companyForm: FormGroup;

    departmentList: FormArray;

    isDirty = false;

    isChecked = false;

    submited = false;

    // Dropdowms
    height = '240px';

    fields: object = {text: 'label', value: 'value'};

    departmentSelect: Array<{value: boolean | number | string; label: string}> = [];

    // Variables
    currentUser: User | null;

    departments: Department[];

    // Loaders
    loadingCompany = false;

    @Input() company: Company | null = null;

    @Input() isCreate = false;

    @Input() isUpdate = false;

    @Input() isVisible = false;

    @Input() isCopy = false;

    @Output() readonly companyFormDialogState = new EventEmitter<boolean>();

    // Dialogs
    @ViewChild('formDialog') formDialogObj: DialogComponent;

    // Dropdowms
    @ViewChild('department') departmentObj: DropDownListComponent;

    constructor(
        private readonly formBuilder: FormBuilder,
        private readonly route: ActivatedRoute,
        private readonly router: Router,
        private readonly dataService: DataService,
        private readonly settingsService: SettingsService,
        private readonly authenticationService: AuthenticationService,
        private readonly userService: UsersService,
        private readonly message: MessageService,
        private readonly ref: ChangeDetectorRef,
    ) {
        this.currentUser = this.authenticationService.currentUserValue;
    }

    get departmentsformGroup(): FormArray {
        return this.companyForm.get('companyDepartments') as FormArray;
    }

    get f(): {[key: string]: AbstractControl} {
        return this.companyForm.controls;
    }

    @HostListener('window:beforeunload')
    canDeactivate(): Observable<boolean> | boolean {
        return !this.isDirty;
    }

    ngOnInit(): void {
        this.dataService.departmentSource.pipe(untilDestroyed(this))
            .subscribe(
                (data: Department[]) => {
                    this.departmentSelect = [];
                    data.map((department: Department) => {
                        if (!department.deleted_date) {
                            this.departmentSelect = [
                                ...this.departmentSelect,
                                {
                                    value: department.id,
                                    label: `${department.company.name} - ${department.name}`,
                                },
                            ];
                        }
                    });
                    this.ref.markForCheck();
                },
                error => {
                    console.error(error);
                },
            );

        this.companyForm = this.formBuilder.group({
            companyDepartments: this.formBuilder.array([this.createDepartment()]),
            name: [
                '',
                Validators.compose([
                    Validators.required,
                    Validators.minLength(2),
                    Validators.maxLength(100),
                ]),
            ],
            address: [
                '',
                Validators.compose([
                    Validators.required,
                    Validators.minLength(10),
                    Validators.maxLength(500),
                ]),
            ],
            ico: [
                '',
                Validators.compose([
                    Validators.required,
                    CustomValidators.patternValidator(/[0-9]/, {isIco: true}),
                    Validators.minLength(8),
                    Validators.maxLength(8),
                ]),
            ],
            ruian: [
                '',
                Validators.compose([
                    Validators.required,
                    CustomValidators.patternValidator(/[0-9]/, {isRuian: true}),
                    Validators.minLength(5),
                    Validators.maxLength(20),
                ]),
            ],
        });

        this.companyForm.valueChanges.pipe(untilDestroyed(this))
            .subscribe(() => {
                this.isDirty = this.companyForm.dirty;
                this.showInputErrors();
            });

        this.departmentList = this.companyForm.get('companyDepartments') as FormArray;

        this.dataService.setDepartmentDataSource();
    }

    createDepartment(): FormGroup {
        return this.formBuilder.group({
            departmentName: [
                '',
                Validators.compose([
                    Validators.required,
                    Validators.minLength(2),
                    Validators.maxLength(100),
                ]),
            ],
        });
    }

    getDepartmentsFormGroup(index): FormGroup {
        return this.departmentList.controls[index] as FormGroup;
    }

    addDepartment(): void {
        this.departmentList.push(this.createDepartment());
    }

    removeDepartment(index: number): void {
        this.departmentList.removeAt(index);
    }

    onFilteringDepartments: EmitType<FilteringEventArgs> = (e: FilteringEventArgs) => {
        if (e.text === '') {
            e.updateData(this.departmentSelect);
        } else {
            let query: Query = new Query();

            query = e.text !== '' ? query.where('label', 'contains', e.text, true, true) : query;
            e.updateData(this.departmentSelect, query);
        }
    };

    ngOnChanges(): void {
        if (this.isVisible) {
            const queryParams: Params = {form: null};

            void this.router.navigate([], {
                relativeTo: this.route,
                queryParams,
                queryParamsHandling: 'merge',
            });

            if (this.isUpdate && this.company) {
                this.companyForm.reset();
                this.formDialogObj.header = `Společnost #${this.company.id} - ${this.company.name}`;
                this.fillTheForm();
                this.showInputErrors();
            }

            if (this.isCreate) {
                this.companyForm.reset();
                this.formDialogObj.header = 'Nová společnost';
            }

            if (this.isCopy) {
                this.companyForm.reset();
                this.formDialogObj.header = 'Nová společnost';
                this.fillTheForm();
                this.showInputErrors();
            }
        }
    }

    changeDialogState(value: boolean): void {
        this.companyFormDialogState.emit(value);
    }

    fillTheForm(): void {
        this.companyForm.controls.name.patchValue(this.company?.name);
        this.companyForm.controls.address.patchValue(this.company?.address_obj.whole_address);
        this.companyForm.controls.ico.patchValue(this.company?.ico);
        this.companyForm.controls.ruian.patchValue(this.company?.address_obj.ruian);
    }

    onSubmit(): void {
        this.submited = true;
        this.isDirty = false;
        this.loadingCompany = true;

        if (this.isCreate || this.isCopy) {
            this.createCompany();
        }

        if (this.isUpdate) {
            this.editCompany();
        }
    }

    createCompany(): void {
        this.loadingCompany = true;
        this.ref.markForCheck();

        if (this.companyForm.invalid) {
            console.error('form is not valid!');

            return;
        }

        const company: CompanyInput = {
            name: this.f.name.value,
            address: this.f.address.value,
            ruian: this.f.ruian.value,
            ico: this.f.ico.value,
            active: true,
            departments: this.departmentList.value,
        };

        this.settingsService
            .addCompany(company)
            .pipe(untilDestroyed(this))
            .subscribe(
                () => {
                    const body = 'Úspěšně jste vytvořili společnost';
                    const options = {
                        progressBar: true,
                        timeOut: 5000,
                        toastClass: 'success',
                    };

                    this.message.showSuccess('Společnost vytvořena', body, options);
                    this.dataService.setCompanyDataSource();
                    this.isCreate = false;
                    this.loadingCompany = false;
                    this.ref.markForCheck();
                    this.formDialogObj.hide();
                },
                error => {
                    console.error(error);

                    const body = 'Zkuste to znovu později';
                    const options = {
                        progressBar: true,
                        timeOut: 5000,
                        toastClass: 'red',
                    };

                    this.message.showError('Chyba při vytváření společnosti', body, options);
                    this.loadingCompany = false;
                    this.ref.markForCheck();
                },
            );
    }

    editCompany(): void {
        this.loadingCompany = true;
        this.ref.markForCheck();

        if (this.companyForm.invalid || !this.company) {
            console.error('form is not valid! || this.company is missing...');

            return;
        }

        const company: CompanyInput = {
            name: this.f.name.value,
            address: this.f.address.value,
            ruian: this.f.ruian.value,
            ico: this.f.ico.value,
            active: true,
            departments: this.departmentList.value,
        };

        this.settingsService
            .editCompany(company)
            .pipe(untilDestroyed(this))
            .subscribe(
                () => {
                    const body = 'Úspěšně jste upravil/a společnost';
                    const options = {
                        progressBar: true,
                        timeOut: 5000,
                        toastClass: 'success',
                    };

                    this.message.showSuccess('Společnost upravena', body, options);
                    this.dataService.setCompanyDataSource();
                    this.isUpdate = false;
                    this.loadingCompany = false;
                    this.ref.markForCheck();
                    this.formDialogObj.hide();
                },
                error => {
                    console.error(error);

                    const body = 'Zkuste to znovu později';
                    const options = {
                        progressBar: true,
                        timeOut: 5000,
                        toastClass: 'red',
                    };

                    this.message.showError('Chyba při úpravě společnosti', body, options);
                    this.loadingCompany = false;
                    this.ref.markForCheck();
                },
            );
    }

    showInputErrors(): void {
        this.isChecked = true;
        /* console.log('VALID: ' + this.ticketForm.valid);
        Object.keys(this.ticketForm.controls).forEach(key => {
            const controlErrors: ValidationErrors = this.ticketForm.get(key).errors;
            if (controlErrors != null) {
                Object.keys(controlErrors).forEach(keyError => {
                    console.log('Key control: ' + key + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError]);
                });
            }
        });*/
        this.companyForm.markAllAsTouched();
        this.ref.markForCheck();
    }
}
