import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Observable, of, Subject} from 'rxjs';
import {Hotline} from '@src/app/_models/hotline/hotline';
import {Hotliner} from '@src/app/_models/hotline/hotliner';
import {catchError, tap} from 'rxjs/operators';
import {MessageService} from '@src/app/_services/message.service';
import {HotlineFile} from '@src/app/_models/hotline/hotline-file';
import {HotlineReport} from '@src/app/_models/hotline/report';
import {DataService} from '@src/app/_services/data.service';
import {EnvironmentService} from '@src/app/_services/environment.service';
import {Address} from '@src/app/_models/services/address';

export interface FilePath {
    saveUrl: string;
    removeUrl: string;
}

export interface FileImportHotline {
    date: string;
    type: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    hotliner_id: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    company_id: number;
}

export interface HotlineInput {
    id?: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    start_at: Date | string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    end_at: Date | string;
    type: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    hotliner_id: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    company_id: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    created_by?: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    updated_by?: number | null;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    deleted_by?: number | null;
    score?: number;
}

export interface HotlinerInput {
    id?: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    user_id: number;
    weekend: boolean;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    created_by: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    updated_by?: number;
}

export interface ExportHotline {
    title: string;
    location: Address;
    description: string;
    start: string;
    end: string;
}

const httpOptions = {
    headers: new HttpHeaders({'Content-Type': 'application/json'}),
};

export interface HotlineReportInput {
    id?: number;
    subject: string;
    report: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    updated_by?: number | null;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    deleted_by?: number | null;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    created_by?: number | null;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    hotline_id: number;
}

@Injectable({
    providedIn: 'root',
})
export class HotlineService {
    private readonly hotlineUrl: string;

    private readonly hotlinersUrl: string;

    private readonly hotlineFilesUrl: string;

    private readonly hotlineReportUrl: string;

    private readonly resultDataSource = new Subject();

    currentDataResult = this.resultDataSource.asObservable();

    constructor(
        private readonly http: HttpClient,
        private readonly dataService: DataService,
        private readonly messageService: MessageService,
        private readonly environmentService: EnvironmentService,
    ) {
        this.hotlineUrl = this.environmentService.backendURL + '/api/hotline';
        this.hotlinersUrl = this.environmentService.backendURL + '/api/hotliners';
        this.hotlineFilesUrl = this.environmentService.backendURL + '/api/file/download';
        this.hotlineReportUrl = this.environmentService.backendURL + '/api/hotline/report';
    }

    /**
     * Handle Http operation that failed.
     * Let the app continue.
     * @param operation - name of the operation that failed
     * @param result - optional value to return as the observable result
     */
    private handleError<T>(operation = 'operation', result?: T) {
        return (error: {[key: string]: string}): Observable<T | undefined> => {
            console.error(error);
            this.log(`${operation} failed: ${error.message}`);

            return of(result);
        };
    }

    /** Log a hotlineService message with the MessageService */
    private log(message: string): void {
        this.messageService.addNotification(`Hotline: ${message}`, false);
    }

    changeDataResults(data: HotlineInput[]): void {
        this.resultDataSource.next(data);
    }

    addHotline(hotline: HotlineInput[]): Observable<Hotline[]> {
        this.dataService.clearHotlinesCache();

        return this.http.post<Hotline[]>(this.hotlineUrl, hotline, httpOptions);
    }

    addHotliner(hotliner: HotlinerInput): Observable<Hotliner> {
        this.dataService.clearHotlinersCache();

        return this.http.post<Hotliner>(this.hotlinersUrl, hotliner, httpOptions);
    }

    addReport(report: HotlineReportInput): Observable<HotlineReport> {
        this.dataService.clearHotlinesCache();

        return this.http.post<HotlineReport>(this.hotlineReportUrl, report, httpOptions);
    }

    deleteHotline(hotline: Hotline): Observable<Hotline> {
        const headers = new HttpHeaders({'Content-Type': 'application/json'});
        const params = new HttpParams().set('deleted_by', hotline.deleted_by?.toString() ?? 'null');
        const url = `${this.hotlineUrl}/hotline/${hotline.id}`;

        this.dataService.clearHotlinesCache();

        return this.http.delete<Hotline>(url, {headers, params});
    }

    deleteHotliner(hotliner: Hotliner): Observable<Hotliner> {
        const headers = new HttpHeaders({'Content-Type': 'application/json'});
        const params = new HttpParams().set(
            'deleted_by',
            hotliner.deleted_by?.toString() ?? 'null',
        );
        const url = `${this.hotlinersUrl}/${hotliner.id}`;

        this.dataService.clearHotlinersCache();

        return this.http.delete<Hotliner>(url, {headers, params});
    }

    deleteReport(report: HotlineReportInput): Observable<HotlineReport> | undefined {
        if (!report.id) {
            console.error('ID is missing...');

            return;
        }

        const headers = new HttpHeaders({'Content-Type': 'application/json'});
        const params = new HttpParams().set('deleted_by', report.deleted_by?.toString() ?? 'null');
        const url = `${this.hotlineReportUrl}/${report.id}`;

        this.dataService.clearHotlinesCache();

        return this.http.delete<HotlineReport>(url, {headers, params});
    }

    updateHotline(hotline: Hotline): Observable<Hotline> {
        const url = `${this.hotlineUrl}/${hotline.id}`;

        this.dataService.clearHotlinesCache();

        return this.http.put<Hotline>(url, hotline, httpOptions);
    }

    updateHotliner(hotliner: Hotliner): Observable<Hotliner> {
        const url = `${this.hotlinersUrl}/${hotliner.id}`;

        this.dataService.clearHotlinersCache();

        return this.http.put<Hotliner>(url, hotliner, httpOptions);
    }

    updateReport(report: HotlineReportInput): Observable<HotlineReport> | undefined {
        if (!report.id) {
            console.error('ID is missing...');

            return;
        }

        const url = `${this.hotlineReportUrl}/${report.id}`;

        this.dataService.clearHotlinesCache();

        return this.http.put<HotlineReport>(url, report, httpOptions);
    }

    downloadFile(file: HotlineFile | string): Observable<Blob | undefined> {
        const name = typeof file === 'string' ? file : file.name;

        return this.http
            .post(
                `${this.hotlineFilesUrl}`,
                {file: name, module: 'hotline'},
                {
                    responseType: 'blob',
                    headers: new HttpHeaders().append('Content-Type', 'application/json'),
                },
            )
            .pipe(
                tap(() => {
                    this.log(`start download hotline file name=${name}`);
                }),
                catchError(this.handleError<Blob>(`file name=${name}`)),
            );
    }
}
