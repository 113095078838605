import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule, Routes} from '@angular/router';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {AuthGuard} from '@src/app/_guards/auth.guard';
import {TimeagoCustomFormatter, TimeagoFormatter, TimeagoIntl, TimeagoModule} from 'ngx-timeago';
import {KanbanAllModule} from '@syncfusion/ej2-angular-kanban';
import {
    AgendaService,
    DayService,
    ExcelExportService,
    ICalendarExportService,
    MonthService,
    PrintService,
    ScheduleAllModule,
    TimelineMonthService,
    TimelineViewsService,
    TimelineYearService,
    WeekService,
    WorkWeekService,
    YearService,
} from '@syncfusion/ej2-angular-schedule';
import {
    TicketCommentFormComponent,
} from '@src/app/features/tickets/_forms/ticket-comment-form/ticket-comment-form.component';
import {TicketNoteFormComponent} from '@src/app/features/tickets/_forms/ticket-note-form/ticket-note-form.component';
import {TicketsService} from '@src/app/features/tickets/tickets.service';
import {SharedFeaturesModule} from '@src/app/shared_features/shared-features.module';
import {
    TicketCalendarComponent,
    TicketComponent,
    TicketDetailComponent,
    TicketDragAndDropComponent,
    TicketFilesComponent,
    TicketImportComponent,
    TicketNotesComponent,
    TicketQueueComponent,
    TicketSidebarComponent,
    TicketToolbarComponent,
    TicketTimeComponent, TicketCommentsComponent,
} from '@src/app/features/tickets/components';
import {
    TicketSolvingFormComponent,
} from '@src/app/features/tickets/_forms/ticket-solving-form/ticket-solving-form.component';
import {TicketFormComponent} from './_forms/ticket-form/ticket-form.component';

const routes: Routes = [
    {
        path: 'tickets',
        children: [
            {
                path: '',
                component: TicketComponent,
                data: {
                    title: 'Ticket nástěnka',
                    breadcrumb: [
                        {
                            label: 'Tickety',
                            url: '',
                        },
                    ],
                },
            },
            {
                path: 'detail/:id',
                component: TicketDetailComponent,
                data: {
                    title: 'Detail ticketu',
                    breadcrumb: [
                        {
                            label: 'Tickety',
                            url: '/tickets/',
                        },
                        {
                            label: 'Detail ticketu',
                            url: '',
                        },
                    ],
                },
            },
        ],
        canActivate: [AuthGuard],
    },
];

@NgModule({
    declarations: [
        TicketCalendarComponent,
        TicketCommentFormComponent,
        TicketCommentsComponent,
        TicketComponent,
        TicketDetailComponent,
        TicketDragAndDropComponent,
        TicketFilesComponent,
        TicketFormComponent,
        TicketImportComponent,
        TicketNoteFormComponent,
        TicketNotesComponent,
        TicketQueueComponent,
        TicketSidebarComponent,
        TicketSolvingFormComponent,
        TicketTimeComponent,
        TicketToolbarComponent,
    ],
    imports: [
        BrowserAnimationsModule,
        RouterModule.forChild(routes),
        BrowserModule,
        CommonModule,
        FormsModule,
        KanbanAllModule,
        ReactiveFormsModule,
        ScheduleAllModule,
        TimeagoModule.forChild({
            intl: TimeagoIntl,
            formatter: {provide: TimeagoFormatter, useClass: TimeagoCustomFormatter},
        }),
        SharedFeaturesModule,
    ],
    providers: [
        AgendaService,
        DayService,
        ExcelExportService,
        ICalendarExportService,
        MonthService,
        PrintService,
        TicketsService,
        TimelineMonthService,
        TimelineViewsService,
        TimelineYearService,
        WeekService,
        WorkWeekService,
        YearService,
    ],
    exports: [TicketFormComponent],
})
export class TicketsModule {
}
