import {
    AfterViewChecked,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnInit,
    ViewChild,
} from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {Observable, Observer} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthenticationService} from '@src/app/_services/authentication.service';
import {ExcelService} from '@src/app/_services/excel.service';
import {DataService} from '@src/app/_services/data.service';
import {
    ServicesService,
    SpaceInput,
    UnitInput,
} from '@src/app/features/services/services.service';
import {PermissionService} from '@src/app/_services/permission.service';
import {MessageService} from '@src/app/_services/message.service';
import {DataNetbaseService} from '@src/app/_services/data-netbase.service';
import {SpaceTypes} from '@src/app/_models/services/space-types';
import {UnitTypes} from '@src/app/_models/services/unit-types';
import {ButtonPropsModel, DialogComponent} from '@syncfusion/ej2-angular-popups';
import {User} from '@src/app/_models/user/user';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {
    ColumnModel,
    DataResult,
    EditSettingsModel,
    ExcelExportProperties,
    FilterSettingsModel,
    GridComponent,
    GroupSettingsModel,
    PageSettingsModel,
    RowDataBoundEventArgs,
    SelectionSettingsModel,
    ToolbarItems,
} from '@syncfusion/ej2-angular-grids';
import {TextBoxComponent} from '@syncfusion/ej2-angular-inputs';
import {ChangeEventArgs, DropDownListComponent} from '@syncfusion/ej2-angular-dropdowns';
import {ClickEventArgs} from '@syncfusion/ej2-angular-navigations';

@UntilDestroy()
@Component({
    selector: 'app-services-flat-units',
    templateUrl: './services-flat-units.component.html',
    styleUrls: ['./services-flat-units.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ServicesFlatUnitsComponent implements OnInit, AfterViewChecked {
    // Grid
    currencyFormat: {format: string} = {format: '#,###.00\',- CZK\''};

    dateFormat: string;

    toolbarUnit: ToolbarItems[] | object;

    toolbarSpace: ToolbarItems[] | object;

    dataSpace: object;

    dataUnit: object;

    fields: object = {text: 'label', value: 'value'};

    filterSettings: FilterSettingsModel;

    filterCheckboxSettings: FilterSettingsModel;

    selectionSettings: SelectionSettingsModel;

    editSettings: EditSettingsModel;

    groupOptions: GroupSettingsModel;

    pageOptions: PageSettingsModel;

    expendituresColumnsPrice: ColumnModel[];

    expendituresColumnsItems: ColumnModel[];

    filterDate: object;

    sortOptionsUnit: object;

    sortOptionsSpace: object;

    requiredRules: object;

    exportCurrentPage = false;

    // Dialogs
    renderSpaceFormDialog = false;

    renderUnitFormDialog = false;

    buttonsDeleteUnit: ButtonPropsModel[] = [
        {
            click: (): void => {
                this.deleteUnitType();
            },
            isFlat: false,
            buttonModel: {
                content: 'OK',
                cssClass: 'e-success e-outline',
            },
        },
        {
            click: (): void => {
                this.confirmationDeleteUnit.hide();
            },
            buttonModel: {
                content: 'ZRUŠIT',
                cssClass: 'e-danger e-outline',
            },
        },
    ];

    buttonsDeleteSpace: ButtonPropsModel[] = [
        {
            click: (): void => {
                this.deleteSpaceType();
            },
            isFlat: false,
            buttonModel: {
                content: 'OK',
                cssClass: 'e-success e-outline',
            },
        },
        {
            click: (): void => {
                this.confirmationDeleteSpace.hide();
            },
            buttonModel: {
                content: 'ZRUŠIT',
                cssClass: 'e-danger e-outline',
            },
        },
    ];

    buttonsCancel: ButtonPropsModel[] = [
        {
            click: (): void => {
                this.confirmationCancel.hide();
            },
            buttonModel: {
                isPrimary: true,
                content: 'OK',
            },
        },
    ];

    // Dropdowns
    height = '240px';

    categoryFilterUnitData: Array<string> = [];

    categoryFilterSpaceData: Array<string> = [];

    // Forms
    isCreateUnit = false;

    isCreateSpace = false;

    isUpdateUnit = false;

    isUpdateSpace = false;

    isCopyUnit = false;

    isCopySpace = false;

    // Variables
    currentUser: User | null;

    units: UnitTypes[] | undefined = [];

    spaces: SpaceTypes[] | undefined = [];

    clickedUnitTypesRow: UnitTypes[] = [];

    clickedSpaceTypesRow: SpaceTypes[] = [];

    clickedUnitTypesRow$ = new Observable<UnitTypes[]>((observer: Observer<UnitTypes[]>) => {
        observer.next(this.clickedUnitTypesRow);
    });

    clickedSpaceTypesRow$ = new Observable<SpaceTypes[]>((observer: Observer<SpaceTypes[]>) => {
        observer.next(this.clickedSpaceTypesRow);
    });

    // Loaders
    tableLoad = true;

    // Grid
    @ViewChild('unitsGrid') gridUnits: GridComponent;

    @ViewChild('spacesGrid') gridSpaces: GridComponent;

    @ViewChild('searchtextUnit') searchtextUnitObj: TextBoxComponent;

    @ViewChild('searchtextSpace') searchtextSpaceObj: TextBoxComponent;

    // Dialogs'
    @ViewChild('confirmationDeleteUnit') confirmationDeleteUnit: DialogComponent;

    @ViewChild('confirmationDeleteSpace') confirmationDeleteSpace: DialogComponent;

    @ViewChild('confirmationCancel') confirmationCancel: DialogComponent;

    // Dropdowns
    @ViewChild('viewFilterUnit') viewFilterInstanceUnit: DropDownListComponent;

    @ViewChild('viewFilterSpace') viewFilterInstanceSpace: DropDownListComponent;

    constructor(
        private readonly router: Router,
        private readonly route: ActivatedRoute,
        private readonly ref: ChangeDetectorRef,
        private readonly formBuilder: FormBuilder,
        private readonly authenticationService: AuthenticationService,
        private readonly excelService: ExcelService,
        private readonly dataService: DataService,
        private readonly dataNetbaseService: DataNetbaseService,
        private readonly servicesService: ServicesService,
        private readonly permissionService: PermissionService,
        private readonly messages: MessageService,
    ) {
        this.currentUser = this.authenticationService.currentUserValue;
    }

    ngOnInit(): void {
        this.filterSettings = {
            type: 'Menu',
            showFilterBarStatus: true,
            ignoreAccent: true,
        };

        this.filterCheckboxSettings = {
            type: 'CheckBox',
            showFilterBarStatus: true,
            ignoreAccent: true,
        };

        this.filterSettings = {
            type: 'Excel',
            showFilterBarOperator: true,
            showFilterBarStatus: true,
            ignoreAccent: true,
        };

        this.selectionSettings = {
            persistSelection: true,
            type: 'Multiple',
            checkboxOnly: true,
        };

        this.toolbarUnit = [
            {
                text: 'Občerstvit data',
                tooltipText: 'Občerstvit data',
                prefixIcon: 'e-refresh',
                id: 'customRefresh',
            },
            'Print',
            'ExcelExport',
            'Add',
            'Edit',
            {
                text: 'Smazat',
                tooltipText: 'Smazat typ bytové jednotky',
                prefixIcon: 'e-remove',
                id: 'delete',
            },
            {
                text: 'Kopírovat',
                tooltipText: 'Kopírovat typ bytové jednotky',
                prefixIcon: 'e-copy',
                id: 'copy',
            },
        ];

        this.toolbarSpace = [
            {
                text: 'Občerstvit data',
                tooltipText: 'Občerstvit data',
                prefixIcon: 'e-refresh',
                id: 'customRefresh',
            },
            'Print',
            'ExcelExport',
            'Add',
            'Edit',
            {
                text: 'Smazat',
                tooltipText: 'Smazat typ prostoru',
                prefixIcon: 'e-remove',
                id: 'delete',
            },
            {
                text: 'Kopírovat',
                tooltipText: 'Kopírovat typ prostoru',
                prefixIcon: 'e-copy',
                id: 'copy',
            },
        ];

        this.categoryFilterUnitData = [
            'všechny typy bytových jednotek',
            'aktivní typy bytových jednotek',
            'neaktivní typy bytových jednotek',
        ];

        this.categoryFilterSpaceData = [
            'všechny typy prostoru',
            'aktivní typy prostoru',
            'neaktivní typy prostoru',
        ];
        this.pageOptions = {pageSize: 50};
        this.dateFormat = 'dd.MM.yyyy HH:mm';
        this.filterDate = {type: 'Menu', params: {format: this.dateFormat}};
        this.groupOptions = {
            showGroupedColumn: true,
            disablePageWiseAggregates: false,
        };

        this.editSettings = {
            showConfirmDialog: false,
            showDeleteConfirmDialog: false,
            allowEditing: true,
            allowAdding: true,
            allowDeleting: true,
            allowEditOnDblClick: false,
            mode: 'Dialog',
        };
        this.requiredRules = {required: true};

        this.dataNetbaseService.setUnitTypesDataSource();
        this.dataNetbaseService.setSpaceTypesDataSource();
    }

    ngAfterViewChecked(): void {
        this.route.queryParamMap.pipe(untilDestroyed(this))
            .subscribe(params => {
                if (params.get('form') === 'addUnitType') {
                    this.isCreateUnit = true;
                    this.renderUnitFormDialog = true;
                    this.ref.markForCheck();
                }

                if (params.get('form') === 'addSpaceType') {
                    this.isCreateSpace = true;
                    this.renderSpaceFormDialog = true;
                    this.ref.markForCheck();
                }
            });
    }

    loadDataUnit(): DataResult[] {
        console.info('NEW UNIT DATA BOUND');

        const elements: UnitInput[] = [];

        if (this.units && this.units.length > 0) {
            this.units.map((unit: UnitTypes) => {
                elements.push({
                    id: unit.id,
                    name: unit.name,
                    created_by: unit.creator.id,
                    creator_str: unit.creator.fullname,
                    creator_img: unit.creator.personalphoto,
                    created_date: new Date(unit.created_date),
                    updated_date: unit.updated_date ? new Date(unit.updated_date) : null,
                    deleted_date: unit.deleted_date ? new Date(unit.deleted_date) : null,
                });
            });
        }

        this.tableLoad = false;

        return elements as DataResult[];
    }

    loadDataSpace(): DataResult[] {
        console.info('NEW SPACE DATA BOUND');

        const elements: SpaceInput[] = [];

        if (this.spaces && this.spaces.length > 0) {
            this.spaces.map((space: SpaceTypes) => {
                if (
                    this.currentUser &&
                    this.permissionService.checkUserISServiceAdmin(this.currentUser)
                ) {
                    elements.push({
                        id: space.id,
                        metric_until: space.metric_until,
                        metric_to: space.metric_to,
                        created_by: space.creator.id,
                        creator_str: space.creator.fullname,
                        creator_img: space.creator.personalphoto,
                        created_date: new Date(space.created_date),
                        updated_date: space.updated_date ? new Date(space.updated_date) : null,
                        deleted_date: space.deleted_date ? new Date(space.deleted_date) : null,
                    });
                }
            });
        }

        this.tableLoad = false;

        return elements as DataResult[];
    }

    onCreatedUnit(): void {
        this.disableToolBarBtnUnit();
    }

    onCreatedSpace(): void {
        this.disableToolBarBtnSpace();
    }

    setInitialGridFiltering(): void {
        this.gridUnits.clearFiltering();
        this.gridSpaces.clearFiltering();
        this.ref.markForCheck();
    }

    onLoadUnit(): void {
        const wrapper1 = document.getElementById('scroll_wrapper1_unit');
        const wrapper2 = document.getElementById('scroll_wrapper2_unit');

        if (wrapper1 && wrapper2) {
            wrapper1.onscroll = (): void => {
                wrapper2.scrollLeft = wrapper1.scrollLeft;
            };

            wrapper2.onscroll = (): void => {
                wrapper1.scrollLeft = wrapper2.scrollLeft;
            };
        }

        this.dataNetbaseService.unitTypesSource.pipe(untilDestroyed(this))
            .subscribe(
                (units: UnitTypes[] | undefined) => {
                    this.units = units;

                    if (this.units && this.units.length > 0) {
                        this.dataUnit = this.gridUnits.dataSource = this.loadDataUnit();
                        this.sortOptionsUnit = {
                            columns: [
                                {field: 'created_date', direction: 'Descending'},
                                {
                                    field: 'id',
                                    direction: 'Descending',
                                },
                            ],
                        };
                    }
                },
                error => {
                    console.error(error);
                    this.tableLoad = false;
                    this.ref.markForCheck();
                },
            );
    }

    onLoadSpace(): void {
        const wrapper1 = document.getElementById('scroll_wrapper1_space');
        const wrapper2 = document.getElementById('scroll_wrapper2_space');

        if (wrapper1 && wrapper2) {
            wrapper1.onscroll = (): void => {
                wrapper2.scrollLeft = wrapper1.scrollLeft;
            };

            wrapper2.onscroll = (): void => {
                wrapper1.scrollLeft = wrapper2.scrollLeft;
            };
        }

        this.dataNetbaseService.spaceTypesSource.pipe(untilDestroyed(this))
            .subscribe(
                (spaces: SpaceTypes[] | undefined) => {
                    this.spaces = spaces;

                    if (this.spaces && this.spaces.length > 0) {
                        this.dataSpace = this.gridSpaces.dataSource = this.loadDataSpace();
                        this.sortOptionsSpace = {
                            columns: [
                                {field: 'created_date', direction: 'Descending'},
                                {
                                    field: 'id',
                                    direction: 'Descending',
                                },
                            ],
                        };
                    }
                },
                error => {
                    console.error(error);
                    this.tableLoad = false;
                    this.ref.markForCheck();
                },
            );
    }

    onDataBoundSpace(): void {
        this.rowSelectedSpace();

        const width = document.getElementById('spacesGrid_content_table')?.offsetWidth;
        const parent1 = document.getElementById('scroll_div_space');
        const parent2 = document.getElementById('grid_parent_space');

        if (parent1 && parent2 && width) {
            parent1.style.width = `${width}px`;
            parent2.style.width = `${width}px`;
        }

        this.gridSpaces.autoFitColumns();
        this.ref.markForCheck();
    }

    onDataBoundUnit(): void {
        this.rowSelectedUnit();

        const width = document.getElementById('unitsGrid_content_table')?.offsetWidth;
        const parent1 = document.getElementById('scroll_div_unit');
        const parent2 = document.getElementById('grid_parent_unit');

        if (parent1 && parent2 && width) {
            parent1.style.width = `${width}px`;
            parent2.style.width = `${width}px`;
        }

        this.gridUnits.autoFitColumns();
        this.ref.markForCheck();
    }

    searchUnit(): void {
        this.gridUnits.search(this.searchtextUnitObj.value);
    }

    searchSpace(): void {
        this.gridSpaces.search(this.searchtextSpaceObj.value);
    }

    rowDataBoundUnit(args: RowDataBoundEventArgs): void {
        const data = args.data as UnitTypes;

        if (args.row && data.deleted_date) {
            args.row.getElementsByClassName('e-gridchkbox')[0].classList.add('disablecheckbox');
            args.row
                .getElementsByClassName('e-checkbox-wrapper')[0]
                .classList.add('disablecheckbox');
            args.row.classList.add('e-disabled');
        }

        if (
            this.currentUser &&
            args.row &&
            data.created_by !== this.currentUser.id &&
            !this.permissionService.checkUserISAdministrative(this.currentUser)
        ) {
            args.row.getElementsByClassName('e-gridchkbox')[0].classList.add('disablecheckbox');
            args.row
                .getElementsByClassName('e-checkbox-wrapper')[0]
                .classList.add('disablecheckbox');
        }

        this.ref.markForCheck();
    }

    rowDataBoundSpace(args: RowDataBoundEventArgs): void {
        const data = args.data as SpaceTypes;

        if (args.row && data.deleted_date) {
            args.row.getElementsByClassName('e-gridchkbox')[0].classList.add('disablecheckbox');
            args.row
                .getElementsByClassName('e-checkbox-wrapper')[0]
                .classList.add('disablecheckbox');
            args.row.classList.add('e-disabled');
        }

        if (
            this.currentUser &&
            args.row &&
            data.created_by !== this.currentUser.id &&
            !this.permissionService.checkUserISAdministrative(this.currentUser)
        ) {
            args.row.getElementsByClassName('e-gridchkbox')[0].classList.add('disablecheckbox');
            args.row
                .getElementsByClassName('e-checkbox-wrapper')[0]
                .classList.add('disablecheckbox');
        }

        this.ref.markForCheck();
    }

    onStatusFilterChangeUnit(e: ChangeEventArgs): void {
        if (e.value === 'všechny typy bytových jednotek') {
            this.gridUnits.clearFiltering();
        } else if (e.value === 'aktivní typy bytových jednotek') {
            this.gridUnits.clearFiltering();
            this.gridUnits.filterByColumn('deleted_date', 'equal', null, 'and', false, true);
        } else if (e.value === 'neaktivní typy bytových jednotek') {
            this.gridUnits.clearFiltering();
            this.gridUnits.filterByColumn('deleted_date', 'notequal', null, 'and', false, true);
        }
    }

    onStatusFilterChangeSpace(e: ChangeEventArgs): void {
        if (e.value === 'všechny typy prostoru') {
            this.gridSpaces.clearFiltering();
        } else if (e.value === 'aktivní typy prostoru') {
            this.gridSpaces.clearFiltering();
            this.gridSpaces.filterByColumn('deleted_date', 'equal', null, 'and', false, true);
        } else if (e.value === 'neaktivní typy prostoru') {
            this.gridSpaces.clearFiltering();
            this.gridSpaces.filterByColumn('deleted_date', 'notequal', null, 'and', false, true);
        }
    }

    cancelBtnUnitClick(): void {
        this.searchtextUnitObj.value = '';
        this.gridUnits.searchSettings.key = '';
    }

    cancelBtnSpaceClick(): void {
        this.searchtextSpaceObj.value = '';
        this.gridSpaces.searchSettings.key = '';
    }

    toolbarClickUnit(args: ClickEventArgs): void {
        const xlsProp: ExcelExportProperties = {
            fileName: 'js_unitTypes_export.xlsx',
            enableFilter: true,
            exportType: this.exportCurrentPage ? 'CurrentPage' : 'AllPages',
        };

        if (args.item.id === 'unitsGrid_excelexport') {
            void this.gridUnits.excelExport(xlsProp);
        } else if (args.item.id === 'delete') {
            if (this.gridUnits.getSelectedRecords().length !== 0) {
                this.confirmationDeleteUnit.content = `Potvrďte smazání ${this.clickedUnitTypesRow.length} typů bytových jednotek`;
                this.confirmationDeleteUnit.show();
            } else {
                this.confirmationCancel.content = 'Musíte vybrat alespoň jeden typ bytové jednotky';
                this.confirmationCancel.show();
            }
        } else if (args.item.id === 'copy') {
            if (this.gridUnits.getSelectedRecords().length !== 0) {
                this.isUpdateUnit = false;
                this.isCreateUnit = false;
                this.isCopyUnit = true;
                this.renderUnitFormDialog = true;
                this.ref.markForCheck();
            } else {
                this.confirmationCancel.content = 'Musíte vybrat alespoň jeden typ bytové jednotky';
                this.confirmationCancel.show();
            }
        } else if (args.item.id === 'customRefresh') {
            this.tableLoad = true;
            console.info('refresh DATA!');
            this.ref.markForCheck();
            this.dataNetbaseService.clearUnitTypesCache();
            this.dataNetbaseService.setUnitTypesDataSource();
        }
    }

    toolbarClickSpace(args: ClickEventArgs): void {
        const xlsProp: ExcelExportProperties = {
            fileName: 'js_spaceTypes_export.xlsx',
            enableFilter: true,
            exportType: this.exportCurrentPage ? 'CurrentPage' : 'AllPages',
        };

        if (args.item.id === 'spacesGrid_excelexport') {
            void this.gridSpaces.excelExport(xlsProp);
        } else if (args.item.id === 'delete') {
            if (this.gridSpaces.getSelectedRecords().length !== 0) {
                this.confirmationDeleteSpace.content = `Potvrďte smazání ${this.clickedSpaceTypesRow.length} vyměření býtových jednotek`;
                this.confirmationDeleteSpace.show();
            } else {
                this.confirmationCancel.content =
                    'Musíte vybrat alespoň jednu výměru bytové jednotky';
                this.confirmationCancel.show();
            }
        } else if (args.item.id === 'copy') {
            if (this.gridSpaces.getSelectedRecords().length !== 0) {
                this.isUpdateSpace = false;
                this.isCreateSpace = false;
                this.isCopySpace = true;
                this.renderSpaceFormDialog = true;
                this.ref.markForCheck();
            } else {
                this.confirmationCancel.content =
                    'Musíte vybrat alespoň jednu výměru bytové jednotky';
                this.confirmationCancel.show();
            }
        } else if (args.item.id === 'detail') {
            void this.router.navigate(['/tickets/detail', this.clickedSpaceTypesRow[0].id]);
        } else if (args.item.id === 'customRefresh') {
            this.tableLoad = true;
            console.info('refresh DATA!');
            this.ref.markForCheck();
            this.dataNetbaseService.clearSpaceTypesCache();
            this.dataNetbaseService.setSpaceTypesDataSource();
        }
    }

    enableToolbarBtnUnit(): void {
        this.gridUnits.toolbarModule.enableItems(
            ['unitsGrid_edit', 'delete', 'copy', 'detail'],
            true,
        );
    }

    disableToolBarBtnUnit(): void {
        this.gridUnits.toolbarModule.enableItems(
            ['unitsGrid_edit', 'delete', 'copy', 'detail'],
            false,
        );
    }

    enableToolbarBtnSPace(): void {
        this.gridSpaces.toolbarModule.enableItems(
            ['spacesGrid_edit', 'delete', 'copy', 'detail'],
            true,
        );
    }

    disableToolBarBtnSpace(): void {
        this.gridSpaces.toolbarModule.enableItems(
            ['spacesGrid_edit', 'delete', 'copy', 'detail'],
            false,
        );
    }

    rowSelectedUnit(): void {
        this.clickedUnitTypesRow = [];

        const selectedrecords: UnitTypes[] = this.gridUnits.getSelectedRecords() as UnitTypes[];

        if (selectedrecords.length > 0) {
            selectedrecords.map((row: UnitTypes) => {
                const tempData: UnitTypes | undefined = this.units?.find(x => x.id === row.id);

                if (tempData) {
                    this.clickedUnitTypesRow.push(tempData);
                }

                this.ref.markForCheck();
            });

            this.clickedUnitTypesRow$ = new Observable<UnitTypes[]>(
                (observer: Observer<UnitTypes[]>) => {
                    observer.next(this.clickedUnitTypesRow);
                },
            );
            this.enableToolbarBtnUnit();
        } else {
            this.disableToolBarBtnUnit();
            this.clickedUnitTypesRow$ = new Observable<UnitTypes[]>(
                (observer: Observer<UnitTypes[]>) => {
                    observer.next(this.clickedUnitTypesRow);
                },
            );
        }
    }

    rowSelectedSpace(): void {
        this.clickedSpaceTypesRow = [];

        const selectedrecords: SpaceTypes[] = this.gridSpaces.getSelectedRecords() as SpaceTypes[];

        if (selectedrecords.length > 0) {
            selectedrecords.map((row: SpaceTypes) => {
                const tempData: SpaceTypes | undefined = this.spaces?.find(x => x.id === row.id);

                if (tempData) {
                    this.clickedSpaceTypesRow.push(tempData);
                }

                this.ref.markForCheck();
            });

            this.clickedSpaceTypesRow$ = new Observable<SpaceTypes[]>(
                (observer: Observer<SpaceTypes[]>) => {
                    observer.next(this.clickedSpaceTypesRow);
                },
            );
            this.enableToolbarBtnSPace();
        } else {
            this.disableToolBarBtnSpace();
            this.clickedSpaceTypesRow$ = new Observable<SpaceTypes[]>(
                (observer: Observer<SpaceTypes[]>) => {
                    observer.next(this.clickedSpaceTypesRow);
                },
            );
        }
    }

    actionBeginUnit(args): void {
        if (args.requestType === 'add' || args.requestType === 'beginEdit') {
            args.cancel = true;

            if (args.requestType === 'beginEdit') {
                this.isUpdateUnit = true;
                this.isCreateUnit = false;
                this.isCopyUnit = false;
            }

            if (args.requestType === 'add') {
                this.isCreateUnit = true;
                this.isUpdateUnit = false;
                this.isCopyUnit = false;
            }

            this.renderUnitFormDialog = true;
            this.ref.markForCheck();
        }
    }

    actionBeginSpace(args): void {
        if (args.requestType === 'add' || args.requestType === 'beginEdit') {
            args.cancel = true;

            if (args.requestType === 'beginEdit') {
                this.isUpdateSpace = true;
                this.isCreateSpace = false;
                this.isCopySpace = false;
            }

            if (args.requestType === 'add') {
                this.isCreateSpace = true;
                this.isUpdateSpace = false;
                this.isCopySpace = false;
            }

            this.renderSpaceFormDialog = true;
            this.ref.markForCheck();
        }
    }

    actionComplete(args): void {
        if (args.requestType === 'beginEdit' || args.requestType === 'add') {
            args.form.ej2_instances[0].rules = {};
        }
    }

    deleteUnitType(): void {
        if (
            this.currentUser &&
            (this.permissionService.checkUserISServiceAdmin(this.currentUser) ||
                this.permissionService.checkUserISAdministrative(this.currentUser) ||
                this.permissionService.checkUserISMarketingAdmin(this.currentUser))
        ) {
            this.tableLoad = true;
            this.clickedUnitTypesRow[0].deleted_by = this.currentUser.id;
            this.servicesService
                .deleteUnitType(this.clickedUnitTypesRow[0])
                ?.pipe(untilDestroyed(this))
                .subscribe(
                    (type: UnitTypes) => {
                        const body = `Typ bytové jednotky #${type.id}`;
                        const options = {progressBar: true, timeOut: 5000};

                        this.messages.showSuccess(
                            'Typ bytové jednotky úspěšně smazán',
                            body,
                            options,
                        );
                        this.dataNetbaseService.setUnitTypesDataSource();
                        this.confirmationDeleteUnit.hide();
                        this.ref.markForCheck();
                    },
                    error => {
                        console.error(error);

                        const body = 'Zkuste to později ...';
                        const options = {progressBar: true, timeOut: 5000};

                        this.messages.showError(
                            'Chyba během mazání typu bytové jednotky',
                            body,
                            options,
                        );
                        this.tableLoad = false;
                        this.confirmationDeleteUnit.hide();
                        this.ref.markForCheck();
                    },
                );
        } else {
            const body = 'Nemáte oprávnění provést tuto akci...';
            const options = {progressBar: true, timeOut: 5000};

            this.messages.showError('Nedostatečné oprávnění', body, options);
            this.ref.markForCheck();
        }
    }

    deleteSpaceType(): void {
        if (
            this.currentUser &&
            (this.permissionService.checkUserISServiceAdmin(this.currentUser) ||
                this.permissionService.checkUserISAdministrative(this.currentUser) ||
                this.permissionService.checkUserISMarketingAdmin(this.currentUser))
        ) {
            this.tableLoad = true;
            this.clickedSpaceTypesRow[0].deleted_by = this.currentUser.id;
            this.servicesService
                .deleteSpaceType(this.clickedSpaceTypesRow[0])
                ?.pipe(untilDestroyed(this))
                .subscribe(
                    (space: SpaceTypes) => {
                        const body = `Limity výměry bytové jednotky #${space.id}`;
                        const options = {
                            progressBar: true,
                            timeOut: 5000,
                            toastClass: 'success',
                        };

                        this.messages.showSuccess(
                            'Limity výměry bytové jednotky úspěšně smazány',
                            body,
                            options,
                        );
                        this.dataNetbaseService.setSpaceTypesDataSource();
                        this.confirmationDeleteSpace.hide();
                        this.ref.markForCheck();
                    },
                    error => {
                        console.error(error);

                        const body = 'Zkuste to později ...';
                        const options = {
                            progressBar: true,
                            timeOut: 5000,
                            toastClass: 'red',
                        };

                        this.messages.showError(
                            'Chyba během mazání limitů výměry bytové jednotky',
                            body,
                            options,
                        );
                        this.tableLoad = false;
                        this.confirmationDeleteSpace.hide();
                        this.ref.markForCheck();
                    },
                );
        } else {
            const body = 'Nemáte oprávnění provést tuto akci...';
            const options = {progressBar: true, timeOut: 5000};

            this.messages.showError('Nedostatečné oprávnění', body, options);
            this.ref.markForCheck();
        }
    }
}
