import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';

import {registerLocaleData} from '@angular/common';
import moment from 'moment';
import localeCs from '@angular/common/locales/cs';
import {LogoutService} from '@src/app/_services/logout.service';
import {CustomStorageService} from '@src/app/_services/custom-storage.service';

moment.updateLocale('cs', {workingWeekdays: [1, 2, 3, 4, 5]});
registerLocaleData(localeCs);

@Injectable({providedIn: 'root'})
export class VersionCheckService {
    // this will be replaced by actual hash post-build.js
    private currentHash = '{{POST_BUILD_ENTERS_HASH_HERE}}';

    constructor(
        private readonly http: HttpClient,
        private readonly storage: CustomStorageService,
        private readonly logoutService: LogoutService,
    ) {}

    private static hasHashChanged(currentHash, newHash): boolean | string {
        if (!currentHash || currentHash === '{{POST_BUILD_ENTERS_HASH_HERE}}') {
            return false;
        }

        return currentHash !== newHash;
    }

    checkVersion(url: string): void {
        const httpOptions = {
            headers: new HttpHeaders({
                'Cache-Control': 'no-cache',
            }),
        };

        // timestamp these requests to invalidate caches
        this.http.get(`${url}?t=${moment()
            .valueOf()}`, httpOptions)
            .subscribe({
                next: response => {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-expect-error
                    const {hash} = response;
                    const hashChanged = VersionCheckService.hasHashChanged(this.currentHash, hash);

                    // If new version, do something
                    if (hashChanged) {
                        console.info('logout by version');
                        this.logoutService.logout();
                        this.storage.clear();
                        console.info('NEW VERSION LOADED...');
                        // ENTER YOUR CODE TO DO SOMETHING UPON VERSION CHANGE
                        // for an example: location.reload();
                        window.location.reload();
                    } else {
                        console.info('VERSION IS OK...');
                    }

                    // store the new hash so we wouldn't trigger versionChange again
                    // only necessary in case you did not force refresh
                    this.currentHash = hash as string;
                },
                error: err => {
                    console.error(err, 'Could not get version');
                },
            });
    }
}
