import {Route} from '@angular/router';
import {NotFoundComponent} from '@src/app/main-layout/errors/not-found/not-found.component';
import {HomeComponent} from '@src/app/main-layout/dashboard/home.component';
import {LoginComponent} from '@src/app/main-layout/login/login.component';
import {RegisterComponent} from '@src/app/main-layout/register/register.component';
import {AuthGuard} from '@src/app/_guards/auth.guard';

export const routes: Route[] = [
    {
        path: '',
        component: HomeComponent,
        data: {title: 'Hlavní nástěnka'},
        canActivate: [AuthGuard],
    },
    {
        path: 'tickets',
        loadChildren: () => import('./features/tickets/tickets.module').then(m => m.TicketsModule),
    },
    {
        path: 'tasks',
        loadChildren: () => import('./features/tasks/task.module').then(m => m.TaskModule),
    },
    {
        path: 'users',
        loadChildren: () => import('./features/users/users.module').then(m => m.UsersModule),
    },
    {
        path: 'material',
        loadChildren: () =>
            import('./features/material/material.module').then(m => m.MaterialModule),
    },
    {
        path: 'vacation',
        loadChildren: () =>
            import('./features/vacation/vacation.module').then(m => m.VacationModule),
    },
    {
        path: 'hotline',
        loadChildren: () =>
            import('./features/hotline/hotline.module').then(m => m.HotlineModule),
    },
    {
        path: 'knowledge',
        loadChildren: () =>
            import('./features/knowledge/knowledge.module').then(m => m.KnowledgeModule),
    },
    {
        path: 'wiki',
        loadChildren: () => import('./features/wiki/wiki.module').then(m => m.WikiModule),
    },
    {
        path: 'booking',
        loadChildren: () =>
            import('./features/booking/booking.module').then(m => m.BookingModule),
    },
    {
        path: 'events',
        loadChildren: () =>
            import('./features/events/events.module').then(m => m.EventsModule),
    },
    {
        path: 'deals',
        loadChildren: () => import('./features/deals/deals.module').then(m => m.DealsModule),
    },
    {
        path: 'services',
        loadChildren: () =>
            import('./features/services/services.module').then(m => m.ServicesModule),
    },
    /* {
        path: 'reports',
        loadChildren: () => import('./reports/reports.module').then(m => m.ReportsModule),
    },*/
    {
        path: 'mailer',
        loadChildren: () => import('./mailer/mailer.module').then(m => m.MailerModule),
    },
    {
        path: 'settings',
        loadChildren: () => import('./features/settings/settings.module').then(m => m.SettingsModule),
    },
    {
        path: 'sync',
        loadChildren: () => import('./features/sync/sync.module').then(m => m.SyncModule),
    },
    {
        path: 'login',
        component: LoginComponent,
        data: {title: 'Online Office Solutions', value: 'login'},
    },
    {
        path: 'register',
        component: RegisterComponent,
        data: {value: 'register'},
    },

    {path: '**', component: NotFoundComponent, data: {value: 'notfound'}},
];
