import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnInit,
    ViewChild,
} from '@angular/core';
import {
    ChangeEventArgs,
    DropDownListComponent,
    FilteringEventArgs,
} from '@syncfusion/ej2-angular-dropdowns';
import {
    ColumnModel,
    DataResult,
    EditSettingsModel,
    ExcelExportProperties,
    FilterSettingsModel,
    GridComponent,
    GroupSettingsModel,
    PageSettingsModel,
    RowDataBoundEventArgs,
    SelectionSettingsModel,
    ToolbarItems,
} from '@syncfusion/ej2-angular-grids';
import {ButtonPropsModel, DialogComponent} from '@syncfusion/ej2-angular-popups';
import {User} from '@src/app/_models/user/user';
import {FormBuilder} from '@angular/forms';
import {Observable, Observer} from 'rxjs';
import {Router} from '@angular/router';
import {AuthenticationService} from '@src/app/_services/authentication.service';
import {DataService} from '@src/app/_services/data.service';
import {PermissionService} from '@src/app/_services/permission.service';
import {MessageService} from '@src/app/_services/message.service';
import {ClickEventArgs} from '@syncfusion/ej2-angular-navigations';
import {registerLocaleData} from '@angular/common';
import moment from 'moment';
import localeCs from '@angular/common/locales/cs';
import {DataNetbaseService} from '@src/app/_services/data-netbase.service';
import {OrderGridItems, ServicesService} from '@src/app/features/services/services.service';
import {Orders} from '@src/app/_models/netbase/orders';
import {TextBoxComponent} from '@syncfusion/ej2-angular-inputs';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {TvPackages} from '@src/app/_models/services/tv-packages';
import {EmitType} from '@syncfusion/ej2-base';
import {Query} from '@syncfusion/ej2-data';
import {ExtendServices} from '@src/app/_models/services/extend-services';

moment.updateLocale('cs', {workingWeekdays: [1, 2, 3, 4, 5]});
registerLocaleData(localeCs);

@UntilDestroy()
@Component({
    selector: 'app-services-orders',
    templateUrl: './services-orders.component.html',
    styleUrls: ['./services-orders.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ServicesOrdersComponent implements OnInit {
    // Grid
    filterSettings: FilterSettingsModel;

    selectionSettings: SelectionSettingsModel;

    pricesColumns: ColumnModel[];

    feesColumns: ColumnModel[];

    editSettings: EditSettingsModel;

    groupOptions: GroupSettingsModel;

    pageOptions: PageSettingsModel;

    filterDate: object;

    filterText: object;

    requiredRules: object;

    currencyFormat: {format: string} = {format: '#,###.00\',- CZK\''};

    dateFormat: string;

    toolbar: ToolbarItems[] | object;

    dataOrders: object;

    sortOptions: object;

    exportCurrentPage = false;

    // Dropdowns
    height = '240px';

    categorySelect: Array<{value: boolean | number | string; label: string}> = [];

    // Dialogs
    renderOrderFormDialog = false;

    buttonsDelete: ButtonPropsModel[] = [
        {
            click: (): void => {
                this.deleteOrder();
            },
            isFlat: false,
            buttonModel: {
                content: 'OK',
                cssClass: 'e-success e-outline',
            },
        },
        {
            click: (): void => {
                this.confirmationDelete.hide();
            },
            buttonModel: {
                content: 'ZRUŠIT',
                cssClass: 'e-danger e-outline',
            },
        },
    ];

    buttonsDecline: ButtonPropsModel[] = [
        {
            click: (): void => {
                // this.declineApplication();
            },
            isFlat: false,
            buttonModel: {
                content: 'OK',
                cssClass: 'e-success e-outline',
            },
        },
        {
            click: (): void => {
                this.confirmationDecline.hide();
            },
            buttonModel: {
                content: 'ZRUŠIT',
                cssClass: 'e-danger e-outline',
            },
        },
    ];

    buttonsConfirm: ButtonPropsModel[] = [
        {
            click: (): void => {
                // this.confirmApplication();
            },
            isFlat: false,
            buttonModel: {
                content: 'OK',
                cssClass: 'e-success e-outline',
            },
        },
        {
            click: (): void => {
                this.confirmationConfirm.hide();
            },
            buttonModel: {
                content: 'ZRUŠIT',
                cssClass: 'e-danger e-outline',
            },
        },
    ];

    buttonsCancel: ButtonPropsModel[] = [
        {
            click: (): void => {
                this.confirmationCancel.hide();
            },
            buttonModel: {
                isPrimary: true,
                content: 'OK',
            },
        },
    ];

    buttonsSMTicket: ButtonPropsModel[] = [
        {
            click: (): void => {
                this.createTicket2SiteMonitor.hide();
            },
            buttonModel: {
                isPrimary: true,
                content: 'OK',
            },
        },
    ];

    // Forms
    isUpdate = false;

    // Loaders
    loadingOrder = false;

    // Variables
    currentUser: User | null;

    orders: Orders[] | undefined = [];

    categoryFilterData: string[] = [];

    clickedRow: Orders[] = [];

    clickedRow$ = new Observable<Orders[]>((observer: Observer<Orders[]>) => {
        observer.next(this.clickedRow);
    });

    // Grid
    @ViewChild('ordersGrid') gridOrders: GridComponent;

    @ViewChild('searchtext') searchtextObj: TextBoxComponent;

    // Dropdowns
    @ViewChild('viewFilter') viewFilterInstance: DropDownListComponent;

    // Dialogs
    @ViewChild('confirmationDelete') confirmationDelete: DialogComponent;

    @ViewChild('confirmationDecline') confirmationDecline: DialogComponent;

    @ViewChild('confirmationConfirm') confirmationConfirm: DialogComponent;

    @ViewChild('confirmationCancel') confirmationCancel: DialogComponent;

    @ViewChild('createTicket2SiteMonitor')
    createTicket2SiteMonitor: DialogComponent;

    constructor(
        private readonly router: Router,
        private readonly ref: ChangeDetectorRef,
        private readonly formBuilder: FormBuilder,
        private readonly authenticationService: AuthenticationService,
        private readonly dataService: DataService,
        private readonly dataNetbaseService: DataNetbaseService,
        private readonly servicesService: ServicesService,
        private readonly permissionService: PermissionService,
        private readonly messages: MessageService,
    ) {
        this.currentUser = this.authenticationService.currentUserValue;
    }

    ngOnInit(): void {
        this.filterSettings = {
            type: 'Excel',
            showFilterBarOperator: true,
            showFilterBarStatus: true,
            ignoreAccent: true,
        };

        this.selectionSettings = {
            persistSelection: true,
            type: 'Multiple',
            checkboxOnly: true,
        };

        this.toolbar = [
            {
                text: 'Občerstvit data',
                tooltipText: 'Občerstvit data',
                prefixIcon: 'e-refresh',
                id: 'customRefresh',
            },
            'Print',
            'ExcelExport',
            'Edit',
            {
                text: 'Smazat',
                tooltipText: 'Smazat objednávku',
                prefixIcon: 'e-remove',
                id: 'delete',
            },
            {
                text: 'Založit SZ v ISP Admin',
                tooltipText: 'Založit servisní zásah v ISP Admin',
                prefixIcon: 'e-check',
                id: 'isp_service',
            },
            {
                text: 'Detail',
                tooltipText: 'Detail ovjednávky',
                prefixIcon: 'e-openfolder',
                id: 'detail',
            },
        ];
        this.pageOptions = {pageSize: 50};
        this.dateFormat = 'dd.MM.yyyy HH:mm';
        this.filterDate = {type: 'Menu', params: {format: this.dateFormat}};
        this.groupOptions = {
            showGroupedColumn: true,
            disablePageWiseAggregates: false,
        };

        this.editSettings = {
            allowEditing: true,
            allowAdding: false,
            allowDeleting: true,
            allowEditOnDblClick: false,
            mode: 'Dialog',
        };

        this.requiredRules = {required: true};

        this.pricesColumns = [
            {
                field: 'internet_price',
                headerText: 'Internet',
                format: '#,###.00\',- CZK\'',
                textAlign: 'Right',
                maxWidth: 125,
                minWidth: 100,
                clipMode: 'EllipsisWithTooltip',
                autoFit: true,
            },
            {
                field: 'television_price',
                headerText: 'Televize',
                format: '#,###.00\',- CZK\'',
                textAlign: 'Right',
                maxWidth: 125,
                minWidth: 100,
                clipMode: 'EllipsisWithTooltip',
                autoFit: true,
            },
            {
                field: 'wifi_price',
                headerText: 'Wi-Fi',
                format: '#,###.00\',- CZK\'',
                textAlign: 'Right',
                maxWidth: 125,
                minWidth: 100,
                clipMode: 'EllipsisWithTooltip',
                autoFit: true,
            },
            {
                field: 'ext_services_price',
                headerText: 'Rozšíření',
                format: '#,###.00\',- CZK\'',
                textAlign: 'Right',
                maxWidth: 125,
                minWidth: 100,
                clipMode: 'EllipsisWithTooltip',
                autoFit: true,
            },
            {
                field: 'total_price',
                headerText: 'Celkem',
                format: '#,###.00\',- CZK\'',
                textAlign: 'Right',
                maxWidth: 125,
                minWidth: 100,
                clipMode: 'EllipsisWithTooltip',
                autoFit: true,
            },
        ];

        this.feesColumns = [
            {
                field: 'internet_fee',
                headerText: 'Internet',
                format: '#,###.00\',- CZK\'',
                textAlign: 'Right',
                maxWidth: 125,
                minWidth: 100,
                clipMode: 'EllipsisWithTooltip',
                autoFit: true,
            },
            {
                field: 'television_fee',
                headerText: 'Televize',
                format: '#,###.00\',- CZK\'',
                textAlign: 'Right',
                maxWidth: 125,
                minWidth: 100,
                clipMode: 'EllipsisWithTooltip',
                autoFit: true,
            },
            {
                field: 'wifi_fee',
                headerText: 'Wi-Fi',
                format: '#,###.00\',- CZK\'',
                textAlign: 'Right',
                maxWidth: 125,
                minWidth: 100,
                clipMode: 'EllipsisWithTooltip',
                autoFit: true,
            },
            {
                field: 'ext_services_fee',
                headerText: 'Rozšíření',
                format: '#,###.00\',- CZK\'',
                textAlign: 'Right',
                maxWidth: 125,
                minWidth: 100,
                clipMode: 'EllipsisWithTooltip',
                autoFit: true,
            },
            {
                field: 'total_fee',
                headerText: 'Celkem',
                format: '#,###.00\',- CZK\'',
                textAlign: 'Right',
                maxWidth: 125,
                minWidth: 100,
                clipMode: 'EllipsisWithTooltip',
                autoFit: true,
            },
        ];

        this.dataNetbaseService.setOrdersSource();
    }

    onCreatedOrder(): void {
        this.disableToolBarBtn();
    }

    setInitialGridFiltering(): void {
        this.gridOrders.clearFiltering();
        // this.gridOrders.filterByColumn('created_by', 'equal', this.currentUser.id, 'and', false, true);
        // this.viewFilterInstance.value = 'moje žádanky - všechny';
        this.ref.markForCheck();
    }

    onLoadOrder(): void {
        const wrapper1 = document.getElementById('scroll_wrapper1_orders');
        const wrapper2 = document.getElementById('scroll_wrapper2_orders');

        if (wrapper1 && wrapper2) {
            wrapper1.onscroll = (): void => {
                wrapper2.scrollLeft = wrapper1.scrollLeft;
            };

            wrapper2.onscroll = (): void => {
                wrapper1.scrollLeft = wrapper2.scrollLeft;
            };
        }

        this.dataNetbaseService.orderSource.pipe(untilDestroyed(this))
            .subscribe(
                (orders: Orders[] | undefined) => {
                    this.orders = orders;

                    if (this.orders && this.orders.length > 0) {
                        this.dataOrders = this.gridOrders.dataSource = this.loadData();
                    } else {
                        this.loadingOrder = false;
                    }
                },
                error => {
                    console.error(error);
                    this.loadingOrder = false;
                    this.ref.markForCheck();
                },
            );
    }

    search(): void {
        this.gridOrders.search(this.searchtextObj.value);
    }

    onDataBound(): void {
        this.rowSelected();

        const width = document.getElementById('ordersGrid_content_table')?.offsetWidth;
        const parent1 = document.getElementById('scroll_div_orders');
        const parent2 = document.getElementById('grid_parent_orders');

        if (parent1 && parent2 && width) {
            parent1.style.width = `${width}px`;
            parent2.style.width = `${width}px`;
        }

        this.gridOrders.autoFitColumns();
        this.ref.markForCheck();
    }

    rowDataBound(args: RowDataBoundEventArgs): void {
        const data = args.data as Orders;

        if (args.row && data.deleted_date) {
            args.row.getElementsByClassName('e-gridchkbox')[0].classList.add('disablecheckbox');
            args.row
                .getElementsByClassName('e-checkbox-wrapper')[0]
                .classList.add('disablecheckbox');
            args.row.classList.add('e-disabled');
        }

        if (
            this.currentUser &&
            args.row &&
            data.operator_id !== this.currentUser.id &&
            !this.permissionService.checkUserISServiceAdmin(this.currentUser) &&
            !this.permissionService.checkUserISAdmin(this.currentUser)
        ) {
            args.row.getElementsByClassName('e-gridchkbox')[0].classList.add('disablecheckbox');
            args.row
                .getElementsByClassName('e-checkbox-wrapper')[0]
                .classList.add('disablecheckbox');
        }

        this.ref.markForCheck();
    }

    onStatusFilterChange(e: ChangeEventArgs): void {
        if (e.value === 'všechny objednávky') {
            this.gridOrders.clearFiltering();
        } else if (e.value === 'moje objednávky - vyřízené') {
            this.gridOrders.filterSettings = {
                columns: [
                    {
                        field: 'operator_id',
                        operator: 'equal',
                        value: this.currentUser?.id,
                        predicate: 'and',
                        matchCase: false,
                        ignoreAccent: true,
                    },
                    {
                        field: 'status',
                        operator: 'notequal',
                        value: 'queue',
                        predicate: 'and',
                        matchCase: false,
                        ignoreAccent: true,
                    },
                ],
            };
        } else if (e.value === 'moje objednávky - fronta') {
            this.gridOrders.filterSettings = {
                columns: [
                    {
                        field: 'created_by',
                        operator: 'equal',
                        value: this.currentUser?.id,
                        predicate: 'and',
                        matchCase: false,
                        ignoreAccent: true,
                    },
                    {
                        field: 'status',
                        operator: 'equal',
                        value: 'queue',
                        predicate: 'and',
                        matchCase: false,
                        ignoreAccent: true,
                    },
                ],
            };
        } else if (e.value === 'objednávky ke zpracování') {
            this.gridOrders.filterSettings = {
                columns: [
                    {
                        field: 'boss_id',
                        operator: 'equal',
                        value: this.currentUser?.id,
                        predicate: 'and',
                        matchCase: false,
                        ignoreAccent: true,
                    },
                    {
                        field: 'status',
                        operator: 'equal',
                        value: 'queue',
                        predicate: 'and',
                        matchCase: false,
                        ignoreAccent: true,
                    },
                ],
            };
        }
    }

    onFilteringCategories: EmitType<FilteringEventArgs> = (e: FilteringEventArgs) => {
        if (e.text === '') {
            e.updateData(this.categorySelect);
        } else {
            let query: Query = new Query();

            query = e.text !== '' ? query.where('label', 'contains', e.text, true, true) : query;
            e.updateData(this.categorySelect, query);
        }
    };

    cancelBtnClick(): void {
        this.searchtextObj.value = '';
        this.gridOrders.searchSettings.key = '';
    }

    toolbarClick(args: ClickEventArgs): void {
        this.confirmationDecline.buttons = this.buttonsDecline;
        this.confirmationDelete.buttons = this.buttonsDelete;
        this.confirmationConfirm.buttons = this.buttonsConfirm;

        const xlsProp: ExcelExportProperties = {
            fileName: 'js_orders_export.xlsx',
            enableFilter: true,
            exportType: this.exportCurrentPage ? 'CurrentPage' : 'AllPages',
        };

        if (args.item.id === 'ordersGrid_excelexport') {
            console.info('EXCEL EXPORT');
            void this.gridOrders.excelExport(xlsProp);
        } else if (args.item.id === 'isp_service') {
            // console.info('Decline application');
            this.createTicket2SiteMonitor.show();
        } else if (args.item.id === 'delete') {
            console.info('Delete order');

            if (this.gridOrders.getSelectedRecords().length !== 0) {
                this.confirmationDelete.content = `Potvrďte smazání ${this.clickedRow.length} objednávek`;
                this.confirmationDelete.show();
            } else {
                this.confirmationCancel.buttons = this.buttonsCancel;
                this.confirmationCancel.show();
            }
        } else if (args.item.id === 'detail') {
            console.info('Deatil order');
            void this.router.navigate(['/services/orders', this.clickedRow[0].id]);
        } else if (args.item.id === 'customRefresh') {
            this.loadingOrder = true;
            console.info('refresh DATA!');
            this.ref.markForCheck();
            this.dataNetbaseService.clearOrdersCache();
            this.dataNetbaseService.setOrdersSource();
        }
    }

    enableToolbarBtn(): void {
        if (this.currentUser && this.permissionService.checkUserISServiceAdmin(this.currentUser)) {
            this.gridOrders.toolbarModule.enableItems(
                ['ordersGrid_edit', 'delete', 'isp_service', 'detail'],
                true,
            );
        } else {
            this.gridOrders.toolbarModule.enableItems(['detail'], true);
        }

        this.ref.markForCheck();
    }

    disableToolBarBtn(): void {
        this.gridOrders.toolbarModule.enableItems(
            ['ordersGrid_edit', 'delete', 'isp_service', 'detail'],
            false,
        );
        this.ref.markForCheck();
    }

    loadData(): DataResult[] {
        this.gridOrders.clearRowSelection();

        console.info('NEW DATA ORDERS BOUND');

        const elements: OrderGridItems[] = [];

        if (this.orders && this.orders.length > 0) {
            // eslint-disable-next-line complexity
            this.orders.map((order: Orders) => {
                let tvsStr = '';

                if (order.tvs_list.length > 0) {
                    order.tvs_list.map(tv => {
                        tvsStr += (tv.tv as string)
                            ? (tv.tv as string)
                            : `Neznámý model TV - ${
                                tv.smart ? 'SMART TV' : 'SET-TOP Box do pronájmu'
                            }<br/>`;
                    });
                } else {
                    tvsStr = 'nespecifikováno = vlastní set-top box / SMART TV';
                }

                let extServicesStr = '';

                if (order.ext_services && order.ext_services.length > 0) {
                    order.ext_services.map((service: ExtendServices) => {
                        extServicesStr += `${service.name}<br/>`;
                    });
                } else {
                    extServicesStr = 'bez rozšiřujících služeb';
                }

                let tvPackagesStr = '';

                if (order.tv_packages && order.tv_packages.length > 0) {
                    order.tv_packages.map((tvPackage: TvPackages) => {
                        tvPackagesStr += `${tvPackage.name}<br/>`;
                    });
                } else {
                    tvPackagesStr = 'bez TV balíčků';
                }

                elements.push({
                    id: order.id,
                    crm_id: order.crm_id,
                    erp_id: order.erp_id,
                    stmnt_id: order.stmnt_id,
                    type_id: order.type_id,
                    provider_id: order.provider_id,
                    deal_id: order.deal_id,
                    unit_id: order.unit_id,
                    space_id: order.space_id,
                    speed_id: order.speed_id,
                    hw_id: order.hw_id,
                    lead_id: order.lead_id,
                    operator_id: order.operator_id,
                    operator_str: order.operator.secondname + ' ' + order.operator.firstname,
                    operator_img: order.operator.personalphoto,
                    status:
                        order.status === 'wait'
                            ? 'Nová objednávka'
                            : order.status === 'open'
                                ? 'Otevřená objednávka'
                                : order.status === 'close'
                                    ? 'Uzavřená objednávka'
                                    : order.status === 'archive'
                                        ? 'Archivováno'
                                        : 'test',
                    client_status:
                        order.client_status === 'new_client'
                            ? 'Nový klient'
                            : order.client_status === 'existing_client'
                                ? 'Stávající klient'
                                : order.client_status === 'deleted_client'
                                    ? 'Smazaný klient'
                                    : 'test',
                    address: order.address,
                    ruian: order.ruian,
                    field_number: order.field_number,
                    cl_cnumber: order.cl_cnumber,
                    cl_firstname: order.cl_firstname,
                    cl_lastname: order.cl_lastname,
                    cl_fullname:
                        order.cl_firstname && order.cl_lastname
                            ? order.cl_lastname + ' ' + order.cl_firstname
                            : '',
                    cl_email: order.cl_email,
                    cl_billing_email: order.cl_billing_email,
                    cl_technical_email: order.cl_technical_email,
                    cl_business_email: order.cl_business_email,
                    cl_telephone: order.cl_telephone,
                    cl_mobilephone: order.cl_mobilephone,
                    cl_account_number: order.cl_account_number,
                    cl_bank_number: order.cl_bank_number,
                    cl_address: order.cl_address,
                    cl_billing_address: order.cl_billing_address,
                    cl_vat_id: order.cl_vat_id,
                    cl_national_id_number: order.cl_national_id_number,
                    cl_send_invoices_by_post: order.cl_send_invoices_by_post ? 'ANO' : 'NE',
                    cl_send_invoice_by_email: order.cl_send_invoice_by_email ? 'ANO' : 'NE',
                    invoice_by_year: order.invoice_by_year ? 'ANO' : 'NE',
                    vip_install: order.vip_install ? 'ANO' : 'NE',
                    internal_note: order.internal_note,
                    tvs: order.tvs,
                    tvs_str: tvsStr,
                    wicon_own_router: order.wicon_own_router ? 'ANO' : 'NE',
                    wicon_basics: order.wicon_basics ? 'ANO' : 'NE',
                    wicon_garden: order.wicon_garden ? 'ANO' : 'NE',
                    wicon_cabels: order.wicon_cabels ? 'ANO' : 'NE',
                    wicon_roaming: order.wicon_roaming ? 'ANO' : 'NE',
                    created_date: new Date(order.created_date),
                    updated_date: order.updated_date ? new Date(order.updated_date) : null,
                    deleted_date: order.deleted_date ? new Date(order.deleted_date) : null,
                    provider: order.provider,
                    operator: order.operator,
                    type: order.type.name,
                    hw_package: order.hw_package,
                    hw_package_str: order.hw_package ? order.hw_package.name : 'bez Wi-FI služeb',
                    speed_type: order.speed_type
                        ? `${order.speed_type.speed_until} - ${order.speed_type.speed_to}`
                        : '',
                    unit_type: order.unit_type ? order.unit_type.name : '',
                    deal_type: order.deal_type ? order.deal_type.age : '',
                    flat_space: order.flat_space
                        ? `${order.flat_space.metric_until} - ${order.flat_space.metric_to}`
                        : '',
                    net_package: order.net_package ? order.net_package.name : 'bez NET služeb',
                    tv_service: order.tv_service ? order.tv_service.name : 'bez TV služeb',
                    tv_packages: tvPackagesStr,
                    ext_services: order.ext_services,
                    ext_services_str: extServicesStr,
                    total_price: order.total_price,
                    internet_price: order.internet_price,
                    television_price: order.television_price,
                    ext_services_price: order.ext_services_price,
                    wifi_price: order.wifi_price,
                    total_fee: order.total_fee,
                    internet_fee: order.internet_fee,
                    television_fee: order.television_fee,
                    ext_services_fee: order.ext_services_fee,
                    wifi_fee: order.wifi_fee,
                });
            });
        }

        this.loadingOrder = false;

        return elements as unknown as DataResult[];
    }

    rowSelected(): void {
        this.clickedRow = [];

        const selectedrecords: object[] = this.gridOrders.getSelectedRecords();

        if (selectedrecords.length > 0) {
            selectedrecords.map((row: Orders) => {
                const tempData = this.orders?.find(x => x.id === row.id);

                if (tempData) {
                    this.clickedRow.push(tempData);
                }

                this.ref.markForCheck();
            });

            this.clickedRow$ = new Observable<Orders[]>((observer: Observer<Orders[]>) => {
                observer.next(this.clickedRow);
            });
            this.enableToolbarBtn();
        } else {
            this.disableToolBarBtn();
            this.clickedRow$ = new Observable<Orders[]>((observer: Observer<Orders[]>) => {
                observer.next(this.clickedRow);
            });
        }
    }

    actionBegin(args): void {
        if (args.requestType === 'add' || args.requestType === 'beginEdit') {
            args.cancel = true;

            if (args.requestType === 'beginEdit') {
                this.isUpdate = true;
            }

            this.renderOrderFormDialog = true;
            this.ref.markForCheck();
        }
    }

    actionComplete(args): void {
        if (args.requestType === 'beginEdit' || args.requestType === 'add') {
            args.form.ej2_instances[0].rules = {};
        }
    }

    deleteOrder(): void {
        for (const row of this.clickedRow) {
            if (
                this.currentUser &&
                !row.deleted_date &&
                this.permissionService.checkUserISServiceAdmin(this.currentUser)
            ) {
                this.loadingOrder = true;
                row.deleted_by = this.currentUser.id;
                void new Promise((resolve, reject) => {
                    this.servicesService
                        .deleteOrder(row)
                        ?.pipe(untilDestroyed(this))
                        .subscribe(
                            (order: Orders) => {
                                const body = `Objednávka #${order.id}`;
                                const options = {
                                    progressBar: true,
                                    timeOut: 5000,
                                    toastClass: 'success',
                                };

                                this.messages.showSuccess(
                                    'Objednávka úspěšně smazána',
                                    body,
                                    options,
                                );
                                resolve(true);
                            },
                            error => {
                                console.error(error);

                                const body = 'Zkuste to později ...';
                                const options = {
                                    progressBar: true,
                                    timeOut: 5000,
                                    toastClass: 'red',
                                };

                                this.messages.showError(
                                    'Chyba během mazání objednávky',
                                    body,
                                    options,
                                );
                                this.loadingOrder = false;
                                this.ref.markForCheck();
                                reject();
                            },
                        );
                });
            } else {
                const body = 'Nemáte oprávnění provést tuto akci...';
                const options = {progressBar: true, timeOut: 5000};

                this.messages.showWarning('Nedostatečné oprávnění', body, options);
                this.loadingOrder = false;
                this.ref.markForCheck();
            }
        }

        this.dataNetbaseService.setOrdersSource();
        this.gridOrders.clearRowSelection();
        this.confirmationDelete.hide();
    }
}
