import {
    AfterViewChecked,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnInit,
    ViewChild,
} from '@angular/core';
import {User} from '@src/app/_models/user/user';
import {Observable, Observer} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {DataService} from '@src/app/_services/data.service';
import {AuthenticationService} from '@src/app/_services/authentication.service';
import {PermissionService} from '@src/app/_services/permission.service';
import {MessageService} from '@src/app/_services/message.service';
import {registerLocaleData} from '@angular/common';
import {Role} from '@src/app/_models/role/role';

import moment from 'moment';
import localeCs from '@angular/common/locales/cs';
import {ButtonPropsModel, DialogComponent} from '@syncfusion/ej2-angular-popups';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {
    ColumnModel,
    DataResult,
    EditSettingsModel,
    ExcelExportProperties,
    FilterSettingsModel,
    GridComponent,
    GroupSettingsModel,
    PageSettingsModel,
    RowDataBoundEventArgs,
    SelectionSettingsModel,
    ToolbarItems,
} from '@syncfusion/ej2-angular-grids';
import {ChangeEventArgs, DropDownListComponent} from '@syncfusion/ej2-angular-dropdowns';
import {ClickEventArgs} from '@syncfusion/ej2-angular-navigations';
import {TextBoxComponent} from '@syncfusion/ej2-angular-inputs';
import {RoleInput, SettingsService} from '@src/app/features/settings/settings.service';

moment.updateLocale('cs', {workingWeekdays: [1, 2, 3, 4, 5]});
registerLocaleData(localeCs);

@UntilDestroy()
@Component({
    selector: 'app-roles-table',
    templateUrl: './roles-table.component.html',
    styleUrls: ['./roles-table.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RolesTableComponent implements OnInit, AfterViewChecked {
    // Grid
    currencyFormat: {format: string} = {format: '#,###.00\',- CZK\''};

    dateFormat: string;

    toolbar: ToolbarItems[] | object;

    data: object;

    fields: object = {text: 'label', value: 'value'};

    filterSettings: FilterSettingsModel;

    filterCheckboxSettings: FilterSettingsModel;

    selectionSettings: SelectionSettingsModel;

    editSettings: EditSettingsModel;

    groupOptions: GroupSettingsModel;

    pageOptions: PageSettingsModel;

    expendituresColumnsPrice: ColumnModel[];

    expendituresColumnsItems: ColumnModel[];

    filterDate: object;

    sortOptions: object;

    requiredRules: object;

    exportCurrentPage = false;

    // Dialogs
    renderRoleFormDialog = false;

    buttonsDelete: ButtonPropsModel[] = [
        {
            click: (): void => {
                this.deleteRole();
            },
            isFlat: false,
            buttonModel: {
                content: 'OK',
                cssClass: 'e-success e-outline',
            },
        },
        {
            click: (): void => {
                this.confirmationDelete.hide();
            },
            buttonModel: {
                content: 'ZRUŠIT',
                cssClass: 'e-danger e-outline',
            },
        },
    ];

    buttonsCancel: ButtonPropsModel[] = [
        {
            click: (): void => {
                this.confirmationCancel.hide();
            },
            buttonModel: {
                isPrimary: true,
                content: 'OK',
            },
        },
    ];

    // Dropdowns
    height = '240px';

    categoryFilterData: Array<string> = [];

    // Forms
    isCreate = false;

    isUpdate = false;

    isCopy = false;

    // Variables
    roles: Role[] | undefined = [];

    currentUser: User | null;

    clickedRow: Role[] = [];

    clickedRow$ = new Observable<Role[]>((observer: Observer<Role[]>) => {
        observer.next(this.clickedRow);
    });

    // Loaders
    tableLoad = true;

    // Grid
    @ViewChild('rolesGrid') gridRoles: GridComponent;

    @ViewChild('searchtext') searchtextObj: TextBoxComponent;

    // Dialogs
    @ViewChild('confirmationDelete') confirmationDelete: DialogComponent;

    @ViewChild('confirmationCancel') confirmationCancel: DialogComponent;

    // Dropdowns
    @ViewChild('viewFilter') viewFilterInstance: DropDownListComponent;

    constructor(
        private readonly router: Router,
        private readonly route: ActivatedRoute,
        private readonly ref: ChangeDetectorRef,
        private readonly dataService: DataService,
        private readonly authenticationService: AuthenticationService,
        private readonly settingsService: SettingsService,
        private readonly permissionService: PermissionService,
        private readonly messages: MessageService,
    ) {
        this.currentUser = this.authenticationService.currentUserValue;
    }

    ngOnInit(): void {
        this.filterSettings = {
            type: 'Menu',
            showFilterBarStatus: true,
            ignoreAccent: true,
        };

        this.filterCheckboxSettings = {
            type: 'CheckBox',
            showFilterBarStatus: true,
            ignoreAccent: true,
        };

        this.filterSettings = {
            type: 'Excel',
            showFilterBarOperator: true,
            showFilterBarStatus: true,
            ignoreAccent: true,
        };

        this.selectionSettings = {
            persistSelection: true,
            type: 'Multiple',
            checkboxOnly: true,
        };

        this.toolbar = [
            {
                text: 'Občerstvit data',
                tooltipText: 'Občerstvit data',
                prefixIcon: 'e-refresh',
                id: 'customRefresh',
            },
            'Print',
            'ExcelExport',
            'Add',
            'Edit',
            {
                text: 'Smazat',
                tooltipText: 'Smazat pozici',
                prefixIcon: 'e-remove',
                id: 'delete',
            },
            {
                text: 'Kopírovat',
                tooltipText: 'Kopírovat pozici',
                prefixIcon: 'e-copy',
                id: 'copy',
            },
        ];
        this.categoryFilterData = ['všechny pozice', 'aktivní pozice', 'neaktivní pozice'];
        this.pageOptions = {pageSize: 50};
        this.dateFormat = 'dd.MM.yyyy HH:mm';
        this.filterDate = {type: 'Menu', params: {format: this.dateFormat}};
        this.groupOptions = {
            showGroupedColumn: true,
            disablePageWiseAggregates: false,
        };

        this.editSettings = {
            showConfirmDialog: false,
            showDeleteConfirmDialog: false,
            allowEditing: true,
            allowAdding: true,
            allowDeleting: true,
            allowEditOnDblClick: false,
            mode: 'Dialog',
        };
        this.requiredRules = {required: true};

        this.dataService.setRoleDataSource();
    }

    ngAfterViewChecked(): void {
        this.route.queryParamMap.pipe(untilDestroyed(this))
            .subscribe(params => {
                if (params.get('form') === 'addRole') {
                    this.isCreate = true;
                    this.renderRoleFormDialog = true;
                    this.ref.markForCheck();
                }
            });
    }

    loadData(): DataResult[] {
        this.gridRoles.clearRowSelection();

        console.info('NEW DATA BOUND');

        const elements: RoleInput[] = [];

        if (this.roles && this.roles.length > 0) {
            this.roles.map((role: Role) => {
                elements.push({
                    id: role.id,
                    name: role.name,
                    created_by: role.creator.id,
                    creator_str: role.creator.fullname,
                    creator_img: role.creator.personalphoto,
                    created_date: new Date(role.created_date),
                    updated_date: role.updated_date ? new Date(role.updated_date) : null,
                    deleted_date: role.deleted_date ? new Date(role.deleted_date) : null,
                });
            });
        }

        this.tableLoad = false;
        this.ref.markForCheck();

        return elements as unknown as DataResult[];
    }

    onCreated(): void {
        this.disableToolBarBtn();
    }

    setInitialGridFiltering(): void {
        this.gridRoles.clearFiltering();
        // this.gridBonuses.filterByColumn('created_by', 'equal', this.currentUser.id, 'and', false, true);
        // this.viewFilterInstance.value = 'moje žádanky - všechny';
        this.ref.markForCheck();
    }

    onLoad(): void {
        const wrapper1 = document.getElementById('scroll_wrapper1_role');
        const wrapper2 = document.getElementById('scroll_wrapper2_role');

        if (wrapper1 && wrapper2) {
            wrapper1.onscroll = (): void => {
                wrapper2.scrollLeft = wrapper1.scrollLeft;
            };

            wrapper2.onscroll = (): void => {
                wrapper1.scrollLeft = wrapper2.scrollLeft;
            };
        }

        this.dataService.roleSource.pipe(untilDestroyed(this))
            .subscribe(
                (roles: Role[] | undefined) => {
                    this.roles = roles;

                    if (this.roles && this.roles.length > 0) {
                        this.data = this.gridRoles.dataSource = this.loadData();
                        this.sortOptions = {
                            columns: [
                                {field: 'created_date', direction: 'Descending'},
                                {field: 'id', direction: 'Descending'},
                            ],
                        };
                    }
                },
                error => {
                    console.error(error);
                    this.tableLoad = false;
                    this.ref.markForCheck();
                },
            );
    }

    search(): void {
        this.gridRoles.search(this.searchtextObj.value);
    }

    onDataBound(): void {
        this.rowSelected();

        const width = document.getElementById('rolesGrid_content_table')?.offsetWidth;
        const parent1 = document.getElementById('scroll_div_role');
        const parent2 = document.getElementById('grid_parent_role');

        if (parent1 && parent2 && width) {
            parent1.style.width = `${width}px`;
            parent2.style.width = `${width}px`;
        }

        this.gridRoles.autoFitColumns();
        this.ref.markForCheck();
    }

    rowDataBound(args: RowDataBoundEventArgs): void {
        const data = args.data as Role;

        if (args.row && data.deleted_date) {
            args.row.getElementsByClassName('e-gridchkbox')[0].classList.add('disablecheckbox');
            args.row
                .getElementsByClassName('e-checkbox-wrapper')[0]
                .classList.add('disablecheckbox');
            args.row.classList.add('e-disabled');
        }

        if (
            this.currentUser &&
            args.row &&
            data.created_by !== this.currentUser.id &&
            !this.permissionService.checkUserISServiceAdmin(this.currentUser) &&
            !this.permissionService.checkUserISAdministrative(this.currentUser) &&
            !this.permissionService.checkUserISMarketingAdmin(this.currentUser)
        ) {
            args.row.getElementsByClassName('e-gridchkbox')[0].classList.add('disablecheckbox');
            args.row
                .getElementsByClassName('e-checkbox-wrapper')[0]
                .classList.add('disablecheckbox');
        }

        this.ref.markForCheck();
    }

    onStatusFilterChange(e: ChangeEventArgs): void {
        if (e.value === 'všechny pozice') {
            this.gridRoles.clearFiltering();
        } else if (e.value === 'aktivní pozice') {
            this.gridRoles.clearFiltering();
            this.gridRoles.filterByColumn('deleted_date', 'equal', null, 'and', false, true);
        } else if (e.value === 'neaktivní pozice') {
            this.gridRoles.clearFiltering();
            this.gridRoles.filterByColumn('deleted_date', 'notequal', null, 'and', false, true);
        }
    }

    cancelBtnClick(): void {
        this.searchtextObj.value = '';
        this.gridRoles.searchSettings.key = '';
    }

    toolbarClick(args: ClickEventArgs): void {
        const xlsProp: ExcelExportProperties = {
            fileName: 'js_roles_export.xlsx',
            enableFilter: true,
            exportType: this.exportCurrentPage ? 'CurrentPage' : 'AllPages',
        };

        if (args.item.id === 'rolesGrid_excelexport') {
            void this.gridRoles.excelExport(xlsProp);
        } else if (args.item.id === 'delete') {
            if (this.gridRoles.getSelectedRecords().length !== 0) {
                this.confirmationDelete.content = `Potvrďte smazání ${this.clickedRow.length} oddělení`;
                this.confirmationDelete.show();
            } else {
                this.confirmationCancel.content = 'Musíte vybrat alespoň jedno oddělení';
                this.confirmationCancel.show();
            }
        } else if (args.item.id === 'copy') {
            if (this.gridRoles.getSelectedRecords().length !== 0) {
                this.isUpdate = false;
                this.isCreate = false;
                this.isCopy = true;
                this.renderRoleFormDialog = true;
                this.ref.markForCheck();
            } else {
                this.confirmationCancel.content = 'Musíte vybrat alespoň jedno oddělení';
                this.confirmationCancel.show();
            }
        } else if (args.item.id === 'customRefresh') {
            this.tableLoad = true;
            console.info('refresh DATA!');
            this.ref.markForCheck();
            this.dataService.clearDepartmentsCache();
            this.dataService.setDepartmentDataSource();
        }
    }

    enableToolbarBtn(): void {
        this.gridRoles.toolbarModule.enableItems(['rolesGrid_edit', 'delete', 'copy'], true);
    }

    disableToolBarBtn(): void {
        this.gridRoles.toolbarModule.enableItems(['rolesGrid_edit', 'delete', 'copy'], false);
    }

    rowSelected(): void {
        this.clickedRow = [];

        const selectedrecords: object[] = this.gridRoles.getSelectedRecords();

        if (selectedrecords.length > 0) {
            selectedrecords.map((row: Role) => {
                const tempData: Role | undefined = this.roles?.find(x => x.id === row.id);

                if (tempData) {
                    this.clickedRow.push(tempData);
                }

                this.ref.markForCheck();
            });

            this.clickedRow$ = new Observable<Role[]>((observer: Observer<Role[]>) => {
                observer.next(this.clickedRow);
            });
            this.enableToolbarBtn();
        } else {
            this.disableToolBarBtn();
            this.clickedRow$ = new Observable<Role[]>((observer: Observer<Role[]>) => {
                observer.next(this.clickedRow);
            });
        }
    }

    actionBegin(args): void {
        if (args.requestType === 'add' || args.requestType === 'beginEdit') {
            args.cancel = true;

            if (args.requestType === 'beginEdit') {
                this.isUpdate = true;
                this.isCreate = false;
                this.isCopy = false;
            }

            if (args.requestType === 'add') {
                this.isCreate = true;
                this.isUpdate = false;
                this.isCopy = false;
            }

            this.renderRoleFormDialog = true;
            this.ref.markForCheck();
        }
    }

    actionComplete(args): void {
        if (args.requestType === 'beginEdit' || args.requestType === 'add') {
            args.form.ej2_instances[0].rules = {};
        }
    }

    deleteRole(): void {
        this.tableLoad = true;
        this.clickedRow[0].deleted_by = this.currentUser?.id;
        this.settingsService
            .removeRole(this.clickedRow[0])
            .pipe(untilDestroyed(this))
            .subscribe(
                (role: Role) => {
                    const body = `Pozice #${role.id}`;
                    const options = {progressBar: true, timeOut: 5000};

                    this.messages.showSuccess('Pozice úspěšně smazána', body, options);
                    this.loadData();
                    this.confirmationDelete.hide();
                    this.ref.markForCheck();
                },
                error => {
                    console.error(error);

                    const body = 'Zkuste to později ...';
                    const options = {
                        progressBar: true,
                        timeOut: 5000,
                        toastClass: 'red',
                    };

                    this.messages.showError('Chyba během mazání pozice', body, options);
                    this.tableLoad = false;
                    this.confirmationDelete.hide();
                    this.ref.markForCheck();
                },
            );
    }
}
