import {
    AfterViewChecked,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnInit,
    ViewChild,
} from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {Observable, Observer} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthenticationService} from '@src/app/_services/authentication.service';
import {DataService} from '@src/app/_services/data.service';
import {DataNetbaseService} from '@src/app/_services/data-netbase.service';
import {
    ServicesService,
    ServiceUntrustedAddressGridItem,
} from '@src/app/features/services/services.service';
import {PermissionService} from '@src/app/_services/permission.service';
import {MessageService} from '@src/app/_services/message.service';
import {User} from '@src/app/_models/user/user';
import {AddressUntrusted} from '@src/app/_models/services/address-untrusted';
import {ButtonPropsModel, DialogComponent} from '@syncfusion/ej2-angular-popups';
import {
    DataResult,
    EditSettingsModel,
    ExcelExportProperties,
    FilterSettingsModel,
    GridComponent,
    GroupSettingsModel,
    PageSettingsModel,
    RowDataBoundEventArgs,
    SelectionSettingsModel,
    ToolbarItems,
} from '@syncfusion/ej2-angular-grids';
import {ChangeEventArgs, DropDownListComponent} from '@syncfusion/ej2-angular-dropdowns';
import {ClickEventArgs} from '@syncfusion/ej2-angular-navigations';

import {registerLocaleData} from '@angular/common';
import moment from 'moment';
import localeCs from '@angular/common/locales/cs';
import {TextBoxComponent} from '@syncfusion/ej2-angular-inputs';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';

moment.updateLocale('cs', {workingWeekdays: [1, 2, 3, 4, 5]});
registerLocaleData(localeCs);

@UntilDestroy()
@Component({
    selector: 'app-service-untrusted-address',
    templateUrl: './service-untrusted-address.component.html',
    styleUrls: ['./service-untrusted-address.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ServiceUntrustedAddressComponent implements OnInit, AfterViewChecked {
    // Grid
    currencyFormat: {format: string} = {format: '#,###.00\',- CZK\''};

    simpleNumberFormat: {format: string} = {format: '###'};

    dateFormat: string;

    toolbar: ToolbarItems[] | object;

    data: object;

    addrFieldObj: object = {text: 'label', value: 'value'};

    filterSettings: FilterSettingsModel;

    selectionSettings: SelectionSettingsModel;

    editSettings: EditSettingsModel;

    groupOptions: GroupSettingsModel;

    pageOptions: PageSettingsModel;

    filterDate: object;

    requiredRules: object;

    sortOptions: object;

    exportCurrentPage = false;

    // Dialogs
    renderAddressUntrustedFormDialog = false;

    buttonsDelete: ButtonPropsModel[] = [
        {
            click: async (): Promise<void> => {
                await this.deleteAddress();
            },
            isFlat: false,
            buttonModel: {
                content: 'OK',
                cssClass: 'e-success e-outline',
            },
        },
        {
            click: (): void => {
                this.confirmationDelete.hide();
            },
            buttonModel: {
                content: 'ZRUŠIT',
                cssClass: 'e-danger e-outline',
            },
        },
    ];

    buttonsCancel: ButtonPropsModel[] = [
        {
            click: (): void => {
                this.confirmationCancel.hide();
            },
            buttonModel: {
                isPrimary: true,
                content: 'OK',
            },
        },
    ];

    // Forms
    isCreate = false;

    isUpdate = false;

    isCopy = false;

    // Dropdowns
    height = '240px';

    categoryFilterData: Array<string> = [];

    // Variables
    currentUser: User | null;

    addresses: AddressUntrusted[] | undefined = [];

    address: AddressUntrusted | boolean | null = null;

    // Loaders
    untrustedAddressLoad = true;

    clickedRow: AddressUntrusted[] = [];

    clickedRow$ = new Observable<AddressUntrusted[]>((observer: Observer<AddressUntrusted[]>) => {
        observer.next(this.clickedRow);
    });

    // Grid
    @ViewChild('untrustedAddressGrid') gridUntrustedAdresses: GridComponent;

    @ViewChild('searchtext') searchtextObj: TextBoxComponent;

    // Dialogs
    @ViewChild('confirmationDelete') confirmationDelete: DialogComponent;

    @ViewChild('confirmationCancel') confirmationCancel: DialogComponent;

    // Dropdowns
    @ViewChild('viewFilter') viewFilterInstance: DropDownListComponent;

    constructor(
        private readonly router: Router,
        private readonly route: ActivatedRoute,
        private readonly ref: ChangeDetectorRef,
        private readonly formBuilder: FormBuilder,
        private readonly authenticationService: AuthenticationService,
        private readonly dataService: DataService,
        private readonly dataNetbaseService: DataNetbaseService,
        private readonly servicesService: ServicesService,
        private readonly permissionService: PermissionService,
        private readonly messages: MessageService,
    ) {
        this.currentUser = this.authenticationService.currentUserValue;
    }

    ngOnInit(): void {
        this.filterSettings = {
            type: 'Excel',
            showFilterBarOperator: true,
            showFilterBarStatus: true,
            ignoreAccent: true,
        };

        this.selectionSettings = {
            persistSelection: true,
            type: 'Multiple',
            checkboxOnly: true,
        };

        this.toolbar = [
            {
                text: 'Občerstvit data',
                tooltipText: 'Občerstvit data',
                prefixIcon: 'e-refresh',
                id: 'customRefresh',
            },
            'Print',
            'ExcelExport',
            'Add',
            'Edit',
            {
                text: 'Kopírovat',
                tooltipText: 'Kopírovat rizikovou adresu',
                prefixIcon: 'e-copy',
                id: 'copy',
            },
            {
                text: 'Smazat',
                tooltipText: 'Smazat rizikovou adresu',
                prefixIcon: 'e-remove',
                id: 'delete',
            },
        ];
        this.categoryFilterData = ['všechny rizikové adresy', 'pouze aktivní rizikové adresy'];
        this.pageOptions = {pageSize: 50};
        this.dateFormat = 'dd.MM.yyyy HH:mm';
        this.filterDate = {type: 'Menu', params: {format: this.dateFormat}};
        this.groupOptions = {
            showGroupedColumn: true,
            disablePageWiseAggregates: false,
        };

        this.editSettings = {
            allowEditing: true,
            allowAdding: true,
            allowDeleting: true,
            allowEditOnDblClick: false,
            mode: 'Dialog',
        };
        this.requiredRules = {required: true};
    }

    ngAfterViewChecked(): void {
        this.route.queryParamMap.pipe(untilDestroyed(this))
            .subscribe(params => {
                if (params.get('form') === 'addUntrustedAddress') {
                    this.isCreate = true;
                    this.isUpdate = false;
                    this.isCopy = false;
                    this.renderAddressUntrustedFormDialog = true;
                    this.ref.markForCheck();
                }
            });
    }

    loadData(): DataResult[] {
        this.gridUntrustedAdresses.clearRowSelection();

        console.info('NEW DATA BOUND');

        const elements: ServiceUntrustedAddressGridItem[] = [];

        if (this.addresses && this.addresses.length > 0) {
            this.addresses.map((address: AddressUntrusted) => {
                if (
                    this.currentUser &&
                    this.permissionService.checkUserISServiceAdmin(this.currentUser)
                ) {
                    elements.push({
                        id: address.id,
                        address: address.address,
                        ruian: address.ruian,
                        descriptive: address.descriptive,
                        creator_id: address.created_by,
                        creator_str: address.creator.fullname,
                        creator_img: address.creator.personalphoto,
                        created_date: new Date(address.created_date),
                        updated_date: address.updated_date ? new Date(address.updated_date) : null,
                        deleted_date: address.deleted_date ? new Date(address.deleted_date) : null,
                    });
                }
            });
        }

        this.untrustedAddressLoad = false;

        return elements as unknown as DataResult[];
    }

    setInitialGridFiltering(): void {
        this.gridUntrustedAdresses.clearFiltering();
        // this.gridUntrustedAdresses.filterByColumn('created_by', 'equal', this.currentUser.id, 'and', false, true);
        // this.viewFilterInstance.value = 'moje žádanky - všechny';
        this.ref.markForCheck();
    }

    onCreated(): void {
        this.disableToolBarBtn();
    }

    onLoad(): void {
        const wrapper1 = document.getElementById('scroll_wrapper1_unAddresses');
        const wrapper2 = document.getElementById('scroll_wrapper2_unAddresses');

        if (wrapper1 && wrapper2) {
            wrapper1.onscroll = (): void => {
                wrapper2.scrollLeft = wrapper1.scrollLeft;
            };

            wrapper2.onscroll = (): void => {
                wrapper1.scrollLeft = wrapper2.scrollLeft;
            };
        }

        this.dataNetbaseService.untrustedAddressSource.pipe(untilDestroyed(this))
            .subscribe({
                next: (addresses: AddressUntrusted[] | undefined) => {
                    this.addresses = addresses;

                    if (this.addresses && this.addresses.length > 0) {
                        this.data = this.gridUntrustedAdresses.dataSource = this.loadData();
                        this.sortOptions = {
                            columns: [
                                {field: 'created_date', direction: 'Descending'},
                                {
                                    field: 'id',
                                    direction: 'Descending',
                                },
                            ],
                        };
                    } else {
                        this.untrustedAddressLoad = false;
                    }
                },
                error: error => {
                    console.error(error);
                    this.untrustedAddressLoad = false;
                    this.ref.markForCheck();
                },
            });
    }

    search(): void {
        this.gridUntrustedAdresses.search(this.searchtextObj.value);
    }

    onDataBound(): void {
        this.rowSelected();

        const width = document.getElementById('untrustedAddressGrid_content_table')?.offsetWidth;
        const parent1 = document.getElementById('scroll_div_unAddresses');
        const parent2 = document.getElementById('grid_parent_unAddresses');

        if (parent1 && parent2 && width) {
            parent1.style.width = `${width}px`;
            parent2.style.width = `${width}px`;
        }

        this.gridUntrustedAdresses.autoFitColumns();
        this.ref.markForCheck();
    }

    rowDataBound(args: RowDataBoundEventArgs): void {
        const data = args.data as AddressUntrusted;

        if (args.row && data.deleted_date) {
            args.row.getElementsByClassName('e-gridchkbox')[0].classList.add('disablecheckbox');
            args.row
                .getElementsByClassName('e-checkbox-wrapper')[0]
                .classList.add('disablecheckbox');
            args.row.classList.add('e-disabled');
        }

        if (
            this.currentUser &&
            args.row &&
            data.created_by !== this.currentUser.id &&
            !this.permissionService.checkUserISServiceAdmin(this.currentUser) &&
            !this.permissionService.checkUserISAdmin(this.currentUser)
        ) {
            args.row.getElementsByClassName('e-gridchkbox')[0].classList.add('disablecheckbox');
            args.row
                .getElementsByClassName('e-checkbox-wrapper')[0]
                .classList.add('disablecheckbox');
        }

        this.ref.markForCheck();
    }

    onStatusFilterChange(e: ChangeEventArgs): void {
        if (e.value === 'všechny vyloučené adresy') {
            this.gridUntrustedAdresses.clearFiltering();
        } else if (e.value === 'pouze aktivní vyloučené adresy') {
            this.gridUntrustedAdresses.clearFiltering();
            this.gridUntrustedAdresses.filterByColumn(
                'deleted_date',
                'notequal',
                null,
                'and',
                false,
                true,
            );
        }
    }

    cancelBtnClick(): void {
        this.searchtextObj.value = '';
        this.gridUntrustedAdresses.searchSettings.key = '';
    }

    toolbarClick(args: ClickEventArgs): void {
        const xlsProp: ExcelExportProperties = {
            fileName: 'js_untrusted_addresses_export.xlsx',
            enableFilter: true,
            exportType: this.exportCurrentPage ? 'CurrentPage' : 'AllPages',
        };

        if (args.item.id === 'untrustedAddressGrid_excelexport') {
            void this.gridUntrustedAdresses.excelExport(xlsProp);
        } else if (args.item.id === 'delete') {
            if (this.gridUntrustedAdresses.getSelectedRecords().length !== 0) {
                this.confirmationDelete.content = `Potvrďte smazání ${this.clickedRow.length} adres`;
                this.confirmationDelete.show();
            } else {
                this.confirmationCancel.content = 'Musíte vybrat alespoň jednu adresu';
                this.confirmationCancel.show();
            }
        } else if (args.item.id === 'copy') {
            if (this.gridUntrustedAdresses.getSelectedRecords().length !== 0) {
                this.isUpdate = false;
                this.isCreate = false;
                this.isCopy = true;
                this.renderAddressUntrustedFormDialog = true;
                this.ref.markForCheck();
            } else {
                this.confirmationCancel.content = 'Musíte vybrat alespoň jeden bonus';
                this.confirmationCancel.show();
            }
        } else if (args.item.id === 'customRefresh') {
            this.untrustedAddressLoad = true;
            console.info('refresh DATA!');
            this.ref.markForCheck();
            this.dataNetbaseService.clearUntrustedAddressCache();
            this.dataNetbaseService.setUntrustedAddressDataSource();
        }
    }

    enableToolbarBtn(): void {
        if (this.currentUser && this.permissionService.checkUserISServiceAdmin(this.currentUser)) {
            this.gridUntrustedAdresses.toolbarModule.enableItems(
                ['untrustedAddressGrid_edit', 'delete', 'copy'],
                true,
            );
        }
    }

    disableToolBarBtn(): void {
        this.gridUntrustedAdresses.toolbarModule.enableItems(
            ['untrustedAddressGrid_edit', 'delete', 'copy'],
            false,
        );
    }

    rowSelected(): void {
        this.clickedRow = [];

        const selectedrecords: AddressUntrusted[] =
            this.gridUntrustedAdresses.getSelectedRecords() as AddressUntrusted[];

        if (selectedrecords.length > 0) {
            selectedrecords.map((row: AddressUntrusted) => {
                const tempData: AddressUntrusted | undefined = this.addresses?.find(
                    x => x.id === row.id,
                );

                if (tempData) {
                    this.clickedRow.push(tempData);
                }

                this.ref.markForCheck();
            });

            this.clickedRow$ = new Observable<AddressUntrusted[]>(
                (observer: Observer<AddressUntrusted[]>) => {
                    observer.next(this.clickedRow);
                },
            );
            this.enableToolbarBtn();
        } else {
            this.disableToolBarBtn();
            this.clickedRow$ = new Observable<AddressUntrusted[]>(
                (observer: Observer<AddressUntrusted[]>) => {
                    observer.next(this.clickedRow);
                },
            );
        }
    }

    actionBegin(args): void {
        if (args.requestType === 'add' || args.requestType === 'beginEdit') {
            args.cancel = true;

            if (args.requestType === 'beginEdit') {
                this.isUpdate = true;
                this.isCreate = false;
                this.isCopy = false;
            }

            if (args.requestType === 'add') {
                this.isCreate = true;
                this.isUpdate = false;
                this.isCopy = false;
            }

            this.renderAddressUntrustedFormDialog = true;
            this.ref.markForCheck();
        }
    }

    actionComplete(args): void {
        if (args.requestType === 'beginEdit' || args.requestType === 'add') {
            args.form.ej2_instances[0].rules = {};
        }
    }

    async deleteAddress(): Promise<void> {
        if (
            this.currentUser &&
            (this.permissionService.checkUserISServiceAdmin(this.currentUser) ||
                this.permissionService.checkUserISAdministrative(this.currentUser) ||
                this.permissionService.checkUserISMarketingAdmin(this.currentUser))
        ) {
            for (const row of this.clickedRow) {
                if (
                    !row.deleted_date &&
                    this.permissionService.checkUserISServiceAdmin(this.currentUser)
                ) {
                    this.untrustedAddressLoad = true;
                    row.deleted_by = this.currentUser.id;
                    await new Promise((resolve, reject) => {
                        this.servicesService
                            .deleteAddressUntrusted(row)
                            ?.pipe(untilDestroyed(this))
                            .subscribe(
                                (address: AddressUntrusted) => {
                                    const body = `Riziková adresa #${address.id}`;
                                    const options = {
                                        progressBar: true,
                                        timeOut: 5000,
                                        toastClass: 'success',
                                    };

                                    this.messages.showSuccess(
                                        'Riziková adresa úspěšně smazána',
                                        body,
                                        options,
                                    );
                                    resolve(true);
                                },
                                error => {
                                    console.error(error);

                                    const body = 'Zkuste to později ...';
                                    const options = {
                                        progressBar: true,
                                        timeOut: 5000,
                                        toastClass: 'red',
                                    };

                                    this.messages.showError(
                                        'Chyba během mazání vyloučené adresy',
                                        body,
                                        options,
                                    );
                                    this.untrustedAddressLoad = false;
                                    this.ref.markForCheck();
                                    reject();
                                },
                            );
                    });
                } else {
                    const body = 'Nemáte oprávnění provést tuto akci...';
                    const options = {
                        progressBar: true,
                        timeOut: 5000,
                        toastClass: 'red',
                    };

                    this.messages.showWarning('Nedostatečné oprávnění', body, options);
                    this.untrustedAddressLoad = false;
                    this.ref.markForCheck();
                }
            }

            this.dataNetbaseService.setUntrustedAddressDataSource();
            this.gridUntrustedAdresses.clearRowSelection();
            this.confirmationDelete.hide();
        } else {
            const body = 'Nemáte oprávnění provést tuto akci...';
            const options = {progressBar: true, timeOut: 5000};

            this.messages.showError('Nedostatečné oprávnění', body, options);
            this.ref.markForCheck();
        }
    }
}
