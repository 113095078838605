import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    HostListener,
    Input,
    OnChanges,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, ValidatorFn, Validators} from '@angular/forms';
import {
    FontFamilyModel,
    ImageSettingsModel,
    QuickToolbarSettingsModel,
    RichTextEditorComponent,
} from '@syncfusion/ej2-angular-richtexteditor';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {EventInput, EventsService} from '@src/app/features/events/events.service';
import {AuthenticationService} from '@src/app/_services/authentication.service';
import {PermissionService} from '@src/app/_services/permission.service';
import {MessageService} from '@src/app/_services/message.service';
import {DataService} from '@src/app/_services/data.service';
import {EnvironmentService} from '@src/app/_services/environment.service';
import {ToolbarModule} from '@syncfusion/ej2-angular-navigations';
import {User} from '@src/app/_models/user/user';
import moment, {MomentInput} from 'moment-business-days';
import localeCs from '@angular/common/locales/cs';
import {registerLocaleData} from '@angular/common';
import {addClass, Browser, createElement, EmitType, removeClass} from '@syncfusion/ej2-base';
import {FilteringEventArgs} from '@syncfusion/ej2-angular-dropdowns';
import {Query} from '@syncfusion/ej2-data';
import {Observable} from 'rxjs';
import {Event} from '@src/app/_models/event/event';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {Department} from '@src/app/_models/department/department';
import {DialogComponent} from '@syncfusion/ej2-angular-popups';
import {CheckBoxComponent} from '@syncfusion/ej2-angular-buttons';

moment.updateLocale('cs', {workingWeekdays: [1, 2, 3, 4, 5]});
registerLocaleData(localeCs);

declare let smartform;

@UntilDestroy()
@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'app-event-form',
    templateUrl: './event-form.component.html',
    styleUrls: ['./event-form.component.scss'],
})
export class EventFormComponent implements OnInit, OnChanges {
    // RTE
    maxLength = 15000;

    textArea: HTMLElement;

    insertImageSettings: ImageSettingsModel;

    fontFamily: FontFamilyModel = {
        items: [
            {
                text: 'Segoe UI',
                value: 'Segoe UI',
                command: 'Font',
                subCommand: 'FontName',
            },
            {
                text: 'Roboto',
                value: 'Roboto',
                command: 'Font',
                subCommand: 'FontName',
            },
            {
                text: 'Great vibes',
                value: 'Great Vibes,cursive',
                command: 'Font',
                subCommand: 'FontName',
            },
            {
                text: 'Noto Sans',
                value: 'Noto Sans',
                command: 'Font',
                subCommand: 'FontName',
            },
            {
                text: 'Impact',
                value: 'Impact,Charcoal,sans-serif',
                command: 'Font',
                subCommand: 'FontName',
            },
            {
                text: 'Tahoma',
                value: 'Tahoma,Geneva,sans-serif',
                command: 'Font',
                subCommand: 'FontName',
            },
        ],
    };

    tools: ToolbarModule = {
        items: [
            'Bold',
            'Italic',
            'Underline',
            'StrikeThrough',
            'FontName',
            'FontSize',
            'FontColor',
            'BackgroundColor',
            'LowerCase',
            'UpperCase',
            'SuperScript',
            'SubScript',
            '|',
            'Formats',
            'Alignments',
            'OrderedList',
            'UnorderedList',
            'Outdent',
            'Indent',
            '|',
            'CreateTable',
            'CreateLink',
            'Image',
            '|',
            'ClearFormat',
            'Print',
            'SourceCode',
            'FullScreen',
            '|',
            'Undo',
            'Redo',
        ],
    };

    quickToolbarSettings: QuickToolbarSettingsModel = {
        table: [
            'TableHeader',
            'TableRows',
            'TableColumns',
            'TableCell',
            '-',
            'BackgroundColor',
            'TableRemove',
            'TableCellVerticalAlign',
            'Styles',
        ],
    };

    pasteCleanupSettings: object = {
        prompt: true,
        plainText: false,
        keepFormat: false,
        deniedTags: ['a'],
        deniedAttrs: ['class', 'title', 'id'],
        allowedStyleProps: ['color', 'margin', 'font-size'],
    };

    // Dropdowns
    fields: object = {text: 'label', value: 'value'};

    height = '240px';

    usersSelect: Array<{[key: string]: boolean | number | string}> = [];

    departmentSelect: Array<{[key: string]: boolean | number | string}> = [];

    // Forms
    eventForm: FormGroup;

    isDirty = false;

    isChecked = false;

    submited = false;

    // Variables
    today = moment()
        .format('YYYY-MM-DD');

    currentUser: User | null;

    users: User[];

    departments: Department[];

    // Loaders
    loadingEvents = true;

    // RTE
    @ViewChild('eventDescription') eventDescription?: RichTextEditorComponent;

    // CheckBoxes
    @ViewChild('attendCheckBox') attendCheckBoxObj?: CheckBoxComponent;

    // Dialogs
    @ViewChild('formDialog') formDialogObj: DialogComponent;

    @Input() event: Event | null = null;

    @Input() isCreate = false;

    @Input() isUpdate = false;

    @Input() isCopy = false;

    @Input() isVisible = false;

    @Output() readonly eventFormDialogState = new EventEmitter<boolean>();

    get f(): {[key: string]: AbstractControl} {
        return this.eventForm.controls;
    }

    @HostListener('window:beforeunload')
    canDeactivate(): Observable<boolean> | boolean {
        return !this.isDirty;
    }

    lessThanDate(fieldDate: string): ValidatorFn {
        return (control: AbstractControl): {lessThan: {value: string}} | null => {
            const fieldToDateCompare = control.parent?.get(fieldDate);
            const endDate = control.value;
            const startDate = fieldToDateCompare?.value;
            const isLessThan =
                Number(moment(startDate as MomentInput)
                    .valueOf()) >
                Number(moment(endDate as MomentInput)
                    .valueOf());

            return isLessThan ? {lessThan: {value: control.value}} : null;
        };
    }

    greaterThanDate(fieldDate: string): ValidatorFn {
        return (control: AbstractControl): {greaterThan: {value: string}} | null => {
            const fieldToDateCompare = control.parent?.get(fieldDate);
            const startDate = control.value;
            const endDate = fieldToDateCompare?.value;
            const isGreaterThan =
                Number(moment(startDate as MomentInput)
                    .valueOf()) >
                Number(moment(endDate as MomentInput)
                    .valueOf());

            return isGreaterThan ? {greaterThan: {value: control.value}} : null;
        };
    }

    reinitSmartForm(): void {
        console.info('Reinitialize SF objects...');
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-call
        smartform.rebindAllForms(false, () => {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-call
            if (smartform.getInstance('smartform-instance-create')) {
                // eslint-disable-next-line max-len
                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-assignment
                const instanceCreate = smartform.getInstance('smartform-instance-addressCreate');

                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-call
                instanceCreate.setZIndex(100000000);
                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-call
                instanceCreate.setSelectionBackgroundColor('#4285f3');
                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-call
                instanceCreate.addAnnounceCallback((validationType, addressArray) => {
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                    if (validationType === smartform.ValidationResultType.HIT) {
                        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                        this.eventForm.controls.address.patchValue(addressArray[0].ADDRESS_WHOLE);
                    }
                });
            }
        });
    }

    constructor(
        private readonly route: ActivatedRoute,
        private readonly router: Router,
        private readonly ref: ChangeDetectorRef,
        private readonly formBuilder: FormBuilder,
        private readonly eventService: EventsService,
        private readonly authenticationService: AuthenticationService,
        private readonly permissionService: PermissionService,
        private readonly messages: MessageService,
        private readonly dataService: DataService,
        private readonly environmentService: EnvironmentService,
    ) {
        this.currentUser = this.authenticationService.currentUserValue;
        this.insertImageSettings = {
            path: this.environmentService.backendURL + '/api/file/preview/events/',
            saveUrl:
                this.environmentService.backendURL +
                '/api/upload/attachments/events?type=attachments&subfolder=events',
        };

        this.eventForm = this.formBuilder.group({
            start_at: [new Date(), Validators.required],
            end_at: [new Date(), Validators.required],
            subject: ['', Validators.required],
            required: [false, Validators.required],
            address: [''],
            description: [''],
            users: [[]],
            departments: [[]],
        });

        this.eventForm.get('end_at')
            ?.addValidators(this.lessThanDate('start_at'));
        this.eventForm.get('start_at')
            ?.addValidators(this.greaterThanDate('end_at'));

        this.eventForm.valueChanges.pipe(untilDestroyed(this))
            .subscribe(() => {
                this.isDirty = this.eventForm.dirty;
                this.showInputErrors();
            });
    }

    ngOnInit(): void {
        this.dataService.userSource.pipe(untilDestroyed(this))
            .subscribe(
                (data: User[]) => {
                    this.usersSelect = [];
                    this.users = data;
                    data.map((user: User) => {
                        if (user.authorized && !user.deleted_date) {
                            this.usersSelect = [
                                ...this.usersSelect,
                                {value: user.id, label: user.firstname + ' ' + user.secondname},
                            ];
                        }
                    });
                },
                error => {
                    console.error(error);
                    this.loadingEvents = false;
                    this.ref.markForCheck();
                },
            );

        this.dataService.departmentSource.pipe(untilDestroyed(this))
            .subscribe(
                (data: Department[]) => {
                    this.departmentSelect = [];
                    this.departments = data;
                    data.map((department: Department) => {
                        this.departmentSelect = [
                            ...this.departmentSelect,
                            {
                                value: department.id,
                                label: department.company.name + ' - ' + department.name,
                            },
                        ];
                    });
                },
                error => {
                    console.error(error);
                    this.loadingEvents = false;
                    this.ref.markForCheck();
                },
            );
    }

    ngOnChanges(): void {
        if (this.isVisible) {
            const queryParams: Params = {form: null};

            void this.router.navigate([], {
                relativeTo: this.route,
                queryParams,
                queryParamsHandling: 'merge',
            });

            if (this.isUpdate && this.event) {
                this.formDialogObj.header = `Vozidlo #${this.event.id} | ${this.event.subject}`;
                this.fillTheForm();
                this.showInputErrors();
                this.loadingEvents = false;
                this.ref.markForCheck();
            }

            if (this.isCreate) {
                this.eventForm.reset();
                this.formDialogObj.header = 'Nové vozidlo';

                if (this.attendCheckBoxObj) {
                    this.attendCheckBoxObj.checked = false;
                }

                this.fillTheForm();
                this.loadingEvents = false;
                this.ref.markForCheck();
            }

            if (this.isCopy) {
                this.eventForm.reset();
                this.formDialogObj.header = 'Nové vozidlo';

                if (this.attendCheckBoxObj) {
                    this.attendCheckBoxObj.checked = false;
                }

                this.fillTheForm();
                this.showInputErrors();
                this.loadingEvents = false;
                this.ref.markForCheck();
            }
        }
    }

    fillTheForm(): void {
        this.eventForm.controls.start_at.patchValue(
            moment(this.event?.start_at)
                .format('YYYY-MM-DD HH:mm:ss'),
        );

        this.eventForm.controls.end_at.patchValue(
            moment(this.event?.end_at)
                .format('YYYY-MM-DD HH:mm:ss'),
        );

        this.eventForm.controls.subject.setValue(this.event?.subject, {
            emitEvent: true,
        });
        this.eventForm.controls.required.patchValue(this.event?.required);
        this.eventForm.controls.address.patchValue(this.event?.address ? this.event.address : '');

        this.eventForm.controls.description.patchValue(
            this.event?.description ? this.event.description : '',
        );

        this.eventForm.controls.users.patchValue(this.event?.users);

        this.eventForm.controls.departments.patchValue(this.event?.departments);
    }

    resetForm(): void {
        this.eventForm.reset();
        this.eventForm.get('start_at')
            ?.patchValue(new Date());
        this.eventForm.get('end_at')
            ?.patchValue(new Date());
        this.eventForm.get('required')
            ?.patchValue(false);
    }

    onSubmit(): void {
        this.submited = true;
        this.isDirty = false;
        this.loadingEvents = true;
        this.ref.markForCheck();

        if (this.isCreate) {
            this.createEvent();
        }

        if (this.isUpdate) {
            this.editEvent();
        }
    }

    createEvent(): void {
        if (this.eventForm.invalid) {
            console.error('form is not valid!');

            return;
        }

        const data: EventInput = {
            start_at: moment(this.f.start_at.value as MomentInput)
                .format('YYYY-MM-DD HH:mm:ss'),
            end_at: moment(this.f.end_at.value as MomentInput)
                .format('YYYY-MM-DD HH:mm:ss'),
            subject: this.f.subject.value as string,
            address: this.f.address.value as string,
            description: this.f.description.value as string,
            required: this.f.required.value as boolean,
            users: this.f.users.value as number[],
            departments: this.f.departments.value as number[],
            created_by: this.currentUser?.id,
        };

        this.eventService
            .addEvent(data)
            .pipe(untilDestroyed(this))
            .subscribe({
                next: (event: Event) => {
                    const body = 'Událost: ' + event.subject;
                    const options = {progressBar: true, timeOut: 5000};

                    this.messages.showSuccess('Událost úspěšně vytvořena', body, options);
                    this.dataService.setEventsDataSource();
                    this.loadingEvents = false;
                    this.formDialogObj.hide();
                    this.ref.markForCheck();
                },
                error: error => {
                    console.error(error);

                    const body = 'Zkuste to znovu později...';
                    const options = {progressBar: true, timeOut: 5000};

                    this.messages.showError('Chyba při vytváření události', body, options);
                    this.loadingEvents = false;
                    this.ref.markForCheck();
                },
            });
    }

    editEvent(): void {
        if (this.eventForm.invalid) {
            console.error('form is not valid!');

            return;
        }

        if (!this.event) {
            return;
        }

        const users: User[] = [];
        const departments: Department[] = [];

        if (this.f.users.value && this.f.users.value.length > 0) {
            this.f.users.value?.map((id: number) => {
                const user = this.users.find(x => x.id === id);

                if (user) {
                    users.push(user);
                }
            });
        }

        if (this.f.departments.value && this.f.departments.value.length > 0) {
            this.f.departments.value.map((id: number) => {
                const department = this.departments.find(x => x.id === id);

                if (department) {
                    departments.push(department);
                }
            });
        }

        this.event.start_at = moment(this.f.start_at.value as MomentInput)
            .format(
                'YYYY-MM-DD HH:mm:ss',
            );

        this.event.end_at = moment(this.f.end_at.value as MomentInput)
            .format(
                'YYYY-MM-DD HH:mm:ss',
            );
        this.event.subject = this.f.subject.value as string;
        this.event.address = this.f.address.value as string;
        this.event.description = this.f.description.value as string;
        this.event.required = this.f.required.value as boolean;
        this.event.users = users;
        this.event.departments = departments;
        this.event.updated_by = this.currentUser?.id;

        this.eventService
            .updateEvent(this.event)
            .pipe(untilDestroyed(this))
            .subscribe({
                next: (data: Event) => {
                    const body = data.subject;
                    const options = {progressBar: true, timeOut: 5000};

                    this.messages.showSuccess('Událost úspěšně upravena', body, options);
                    this.dataService.setEventsDataSource();
                    this.loadingEvents = false;
                    this.formDialogObj.hide();
                    this.ref.markForCheck();
                },
                error: error => {
                    console.error(error);

                    const body = 'Zkuste to znovu později';
                    const options = {
                        progressBar: true,
                        timeOut: 5000,
                        toastClass: 'red',
                    };

                    this.messages.showError('Chyba při úpravě údálosti,', body, options);
                    this.loadingEvents = false;
                    this.ref.markForCheck();
                },
            });
    }

    onFilteringUsers: EmitType<FilteringEventArgs> = (e: FilteringEventArgs) => {
        if (e.text === '') {
            e.updateData(this.usersSelect);
        } else {
            let query: Query = new Query();

            query = e.text !== '' ? query.where('label', 'contains', e.text, true, true) : query;
            e.updateData(this.usersSelect, query);
        }
    };

    onFilteringDepartments: EmitType<FilteringEventArgs> = (e: FilteringEventArgs) => {
        if (e.text === '') {
            e.updateData(this.departmentSelect);
        } else {
            let query = new Query();

            query = e.text !== '' ? query.where('label', 'contains', e.text, true) : query;
            e.updateData(this.departmentSelect, query);
        }
    };

    onCreateRte(): void {
        this.eventDescription?.refreshUI();
        this.eventDescription?.autoResize();
    }

    changeDialogState(value: boolean): void {
        this.eventFormDialogState.emit(value);
    }

    handleFullScreen(e: unknown): void {
        const sbCntEle: HTMLElement | null = document.querySelector('.sb-content.e-view');
        const sbHdrEle: HTMLElement | null = document.querySelector('.sb-header.e-view');
        const leftBar: HTMLElement | null = document.querySelector('#left-sidebar');

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        if (e.targetItem === 'Maximize') {
            if (Browser.isDevice && Browser.isIos && sbCntEle && sbHdrEle) {
                addClass([sbCntEle, sbHdrEle], ['hide-header']);
            }

            if (leftBar) {
                addClass([leftBar], ['e-close']);
                removeClass([leftBar], ['e-open']);
            }
        }

            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        else if (e.targetItem === 'Minimize') {
            if (Browser.isDevice && Browser.isIos && sbCntEle && sbHdrEle) {
                removeClass([sbCntEle, sbHdrEle], ['hide-header']);
            }

            if (leftBar) {
                removeClass([leftBar], ['e-close']);

                if (!Browser.isDevice) {
                    addClass([leftBar], ['e-open']);
                }
            }
        }
    }

    mirrorConversion(e: unknown): void {
        if (this.eventDescription) {
            const id = `${this.eventDescription.getID()}mirror-view`;
            let mirrorView: HTMLElement | null = this.eventDescription.element.querySelector(
                '#' + id,
            );
            const charCount: HTMLElement | null =
                this.eventDescription.element.querySelector('.e-rte-character-count');

            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            if (e.targetItem === 'Preview' && mirrorView && charCount) {
                this.textArea.style.display = 'block';
                mirrorView.style.display = 'none';
                // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-assignment
                charCount.style.display = 'block';
            } else {
                if (!mirrorView && this.textArea.parentNode) {
                    mirrorView = createElement('div', {className: 'e-content'});
                    mirrorView.id = id;
                    this.textArea.parentNode.appendChild(mirrorView);
                } else if (mirrorView) {
                    mirrorView.innerHTML = '';
                }

                if (mirrorView) {
                    this.textArea.style.display = 'none';
                    mirrorView.style.display = 'block';
                }

                if (charCount) {
                    charCount.style.display = 'none';
                }
            }
        }
    }

    actionCompleteHandler(e: unknown): void {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        if (e.targetItem && (e.targetItem === 'SourceCode' || e.targetItem === 'Preview')) {
            (
                this.eventDescription?.sourceCodeModule.getPanel() as HTMLTextAreaElement
            ).style.display = 'none';
            this.mirrorConversion(e);
        } else {
            setTimeout(() => {
                this.eventDescription?.toolbarModule.refreshToolbarOverflow();
            }, 400);
        }
    }

    showInputErrors(): void {
        this.ref.markForCheck();
        this.isChecked = true;
        /* console.log('VALID: ' + this.ticketForm.valid);
      Object.keys(this.ticketForm.controls).forEach(key => {
          const controlErrors: ValidationErrors = this.ticketForm.get(key).errors;
          if (controlErrors != null) {
              Object.keys(controlErrors).forEach(keyError => {
                  console.log('Key control: ' + key + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError]);
              });
          }
      });*/
        this.eventForm.get('end_at')
            ?.updateValueAndValidity({onlySelf: true, emitEvent: false});
        this.eventForm
            .get('start_at')
            ?.updateValueAndValidity({onlySelf: true, emitEvent: false});
        this.eventForm.markAllAsTouched();
        this.ref.markForCheck();
    }
}
