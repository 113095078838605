<div
  class="row d-flex justify-content-center modalWrapper"
  id="dialogContainer"
>
  <ejs-dialog
    #deleteKnowledgeDialog
    [buttons]="buttonsDeleteKnowledgeDialog"
    [content]="'Opravdu chcete smazat tento pracovní postup?'"
    [header]="'Smazat pracovní postup'"
    [height]="'200px'"
    [isModal]="true"
    [showCloseIcon]="true"
    [visible]="false"
    [width]="'450px'"
    id="deleteKnowledgeDialog"
  ></ejs-dialog>
  <ejs-dialog
    #deleteUserDialog
    [buttons]="buttonsDeleteUserDialog"
    [content]="
            'Opravdu chcete smazat označené zapojení uživatele z pracovního postupu?'
        "
    [header]="'Smazat zapojení uživatele v pracovního postupu'"
    [height]="'200px'"
    [isModal]="true"
    [showCloseIcon]="true"
    [visible]="false"
    [width]="'450px'"
    id="deleteUserDialog"
  ></ejs-dialog>
  <ejs-dialog
    #deleteDepartmentDialog
    [buttons]="buttonsDeleteDepartmentDialog"
    [content]="
            'Opravdu chcete smazat označené zapojení oddělení z pracovního postupu?'
        "
    [header]="'Smazat zapojení oddělení z pracovního postupu'"
    [height]="'200px'"
    [isModal]="true"
    [showCloseIcon]="true"
    [visible]="false"
    [width]="'450px'"
    id="deleteDepartmentDialog"
  ></ejs-dialog>
  <ejs-dialog
    #deleteCommentDialog
    [buttons]="buttonsDeleteCommentDialog"
    [content]="
            'Opravdu chcete smazat označený komentář z diskuze pracovního postupu?'
        "
    [header]="'Smazat komentář z pracovního postupu'"
    [height]="'200px'"
    [isModal]="true"
    [showCloseIcon]="true"
    [visible]="false"
    [width]="'450px'"
    id="deleteCommentDialog"
  ></ejs-dialog>
  <ejs-dialog
    #addUserDialog
    [header]="'Zapojit uživatele'"
    [height]="'500px'"
    [isModal]="true"
    [showCloseIcon]="true"
    [visible]="false"
    [width]="'85%'"
    id="addUserDialog"
  >
    <form [formGroup]="addUserForm">
      <div class="row">
        <div class="form-group col-12">
          <label
            [ngClass]="
                            fAddUser.users.valid &&
                            (fAddUser.users.dirty || fAddUser.users.touched)
                                ? 'text-success'
                                : fAddUser.users.invalid &&
                                  (fAddUser.users.dirty ||
                                      fAddUser.users.touched)
                                ? 'text-danger'
                                : 'text-muted'
                        "
            for="users"
          >Uživatelé:</label
          >
          <ejs-multiselect
            #users
            (filtering)="onFilteringUsers($event)"
            [allowFiltering]="true"
            [cssClass]="
                            fAddUser.users.touched && fAddUser.users.invalid
                                ? 'e-error'
                                : fAddUser.users.touched && fAddUser.users.valid
                                ? 'e-success'
                                : ''
                        "
            [dataSource]="usersSelect"
            [fields]="fields"
            [filterBarPlaceholder]="'Vyhledat uživatele...'"
            [ignoreAccent]="true"
            [mode]="'CheckBox'"
            [placeholder]="'Vyberte uživatele'"
            [popupHeight]="height"
            [selectAllText]="'Všechny uživatele'"
            formControlName="users"
            id="users"
            showSelectAll="true"
            sortOrder="Ascending"
          ></ejs-multiselect>
          <small
            *ngIf="
                            fAddUser.users.touched &&
                            fAddUser.users.errors &&
                            fAddUser.users.errors.required
                        "
            class="text-danger"
          >Vyberte alespoň jednoho uživatele</small
          >
        </div>
      </div>
    </form>
    <ng-template #footerTemplate>
      <div class="d-flex justify-content-between">
        <div class="col-6">
          <div
            *ngIf="addUserForm.invalid"
            class="alert alert-warning"
            role="alert"
          >
            Formulář je špatně vyplněný
            <button
              (click)="showInputAddUserErrors()"
              cssClass="e-btn e-warning btn-p-zero e-smallfloat-right"
              ejs-button
              type="button"
            >
              označ chyby
            </button>
          </div>
          <div
            *ngIf="addUserForm.valid"
            class="alert alert-success"
            role="alert"
          >
            Formulář je správně vyplněný
          </div>
        </div>
        <div class="col-6">
          <button
            (click)="addUsers()"
            [disabled]="loadingKnowledge || addUserForm.invalid"
            cssClass="e-btn e-success btn-p-zero e-small"
            ejs-button
            type="submit"
          >
                        <span
                          *ngIf="loadingKnowledge"
                          aria-hidden="true"
                          class="spinner-grow spinner-grow-sm"
                          role="status"
                        ></span>
            <span *ngIf="!loadingKnowledge">Zapojit uživatele</span>
            <span *ngIf="loadingKnowledge">Provádím...</span>
          </button>
          <button
            (click)="addUserDialog.hide()"
            cssClass="e-btn e-danger e-outline btn-p-zero e-small"
            ejs-button
            type="button"
          >
            ZRUŠIT
          </button>
        </div>
      </div>
    </ng-template>
  </ejs-dialog>
  <ejs-dialog
    #addDepartmentsDialog
    [header]="'Zapojit oddělení'"
    [height]="'500px'"
    [isModal]="true"
    [showCloseIcon]="true"
    [visible]="false"
    [width]="'85%'"
    id="addDepartmentsDialog"
  >
    <form [formGroup]="addDepartmentForm">
      <div class="modal-body">
        <div class="row">
          <div class="form-group col-12">
            <label
              [ngClass]="
                                fAddDepartment.departments.valid &&
                                (fAddDepartment.departments.dirty ||
                                    fAddDepartment.departments.touched)
                                    ? 'text-success'
                                    : fAddDepartment.departments.invalid &&
                                      (fAddDepartment.departments.dirty ||
                                          fAddDepartment.departments.touched)
                                    ? 'text-danger'
                                    : 'text-muted'
                            "
              for="departments"
            >Oddělení:</label
            >
            <ejs-multiselect
              #departments
              (filtering)="onFilteringDepartments($event)"
              [allowFiltering]="true"
              [cssClass]="
                                fAddDepartment.departments.touched &&
                                fAddDepartment.departments.invalid
                                    ? 'e-error'
                                    : fAddDepartment.departments.touched &&
                                      fAddDepartment.departments.valid
                                    ? 'e-success'
                                    : ''
                            "
              [dataSource]="departmentSelect"
              [fields]="fields"
              [filterBarPlaceholder]="'Vyhledat oddělení...'"
              [ignoreAccent]="true"
              [mode]="'CheckBox'"
              [placeholder]="'Vyberte oddělení'"
              [popupHeight]="height"
              [selectAllText]="'Všechny oddělení'"
              formControlName="departments"
              id="departments"
              showSelectAll="true"
              sortOrder="Ascending"
            ></ejs-multiselect>
            <small
              *ngIf="
                                fAddDepartment.departments.touched &&
                                fAddDepartment.departments.errors &&
                                fAddDepartment.departments.errors.required
                            "
              class="text-danger"
            >Vyberte alespoň jedno oddělení</small
            >
          </div>
        </div>
      </div>
    </form>
    <ng-template #footerTemplate>
      <div class="d-flex justify-content-between">
        <div class="col-6">
          <div
            *ngIf="addDepartmentForm.invalid"
            class="alert alert-warning"
            role="alert"
          >
            Formulář je špatně vyplněný
            <button
              (click)="showInputAddDepartmentsErrors()"
              cssClass="e-btn e-warning btn-p-zero e-smallfloat-right"
              ejs-button
              type="button"
            >
              označ chyby
            </button>
          </div>
          <div
            *ngIf="addDepartmentForm.valid"
            class="alert alert-success"
            role="alert"
          >
            Formulář je správně vyplněný
          </div>
        </div>
        <div class="col-6">
          <button
            (click)="addDepartments()"
            [disabled]="
                            loadingKnowledge || addDepartmentForm.invalid
                        "
            cssClass="e-btn e-success btn-p-zero e-small"
            ejs-button
            type="submit"
          >
                        <span
                          *ngIf="loadingKnowledge"
                          aria-hidden="true"
                          class="spinner-grow spinner-grow-sm"
                          role="status"
                        ></span>
            <span *ngIf="!loadingKnowledge">Zapojit oddělení</span>
            <span *ngIf="loadingKnowledge">Provádím...</span>
          </button>
          <button
            (click)="addDepartmentsDialog.hide()"
            cssClass="e-btn e-danger e-outline btn-p-zero e-small"
            ejs-button
            type="button"
          >
            ZRUŠIT
          </button>
        </div>
      </div>
    </ng-template>
  </ejs-dialog>
  <app-knowledge-form
    (knowledgeFormDialogState)="
            renderKnowledgeFormDialog = $event;
            isCreate = $event;
            isUpdate = $event;
            isCopy = $event
        "
    [isCopy]="isCopy"
    [isCreate]="isCreate"
    [isUpdate]="isUpdate"
    [isVisible]="renderKnowledgeFormDialog"
    [knowledge]="knowledge"
  ></app-knowledge-form>
  <app-knowledge-comment-form
    (knowledgeCommentFormDialogState)="
            renderCommentFormDialog = $event;
            isCreateComment = $event;
            isUpdateComment = $event;
            isCopyComment = $event
        "
    [comment]="highlightedComment"
    [isCopy]="isCopyComment"
    [isCreate]="isCreateComment"
    [isUpdate]="isUpdateComment"
    [isVisible]="renderCommentFormDialog"
    [knowledge]="knowledge"
  ></app-knowledge-comment-form>
</div>
<div class="row">
  <div class="col-12 col-md-8 col-lg-9 p-2">
    <ejs-tab
      #knowledgeTab
      (created)="onCreate()"
      (selected)="select($event)"
      (selecting)="preventSwipe($event)"
      enablePersistence="true"
      height="auto"
      heightAdjustMode="Content"
      id="knowledgeTab"
    >
      <e-tabitems>
        <e-tabitem [header]="headerText[0]">
          <ng-template #content>
            <div class="e-card" tabindex="0">
              <ng-container *ngIf="knowledge && !loadingKnowledge">
                <div class="e-card-header stylish-color-dark p-2">
                  <div class="e-card-header-caption">
                    <div class="e-card-header-title text-white">
                      <strong>{{ knowledge.subject }}</strong>
                    </div>
                  </div>
                </div>
                <app-knowledge-toolbar
                  (deleteKnowledgeEvent)="deleteKnowledge()"
                  (editKnowledgeEvent)="editKnowledge()"
                  [knowledge]="knowledge"
                ></app-knowledge-toolbar>
                <div class="e-card-content">
                  <div
                    class="scrollbar scrollbar-indigo thin"
                    style="
                                            max-height: 450px;
                                            overflow-y: auto;
                                        "
                  >
                    <div class="force-overflow">
                      <div
                        [innerHTML]="
                                                    knowledge.maintaskHTML
                                                "
                        id="maintaskText"
                      ></div>
                      <div
                        class="e-card-separator mb-1 mt-1"
                      ></div>
                      <div
                        *ngIf="
                                                    knowledge.files.length > 0
                                                "
                        class="image-thumb-group"
                      >
                        <div>
                          <img
                            (click)="open(i)"
                            *ngFor="
                                                            let img of imagesBasic;
                                                            let i = index
                                                        "
                            [src]="
                                                            img.thumb || img.src
                                                        "
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-container>
              <ng-container
                *ngIf="!knowledge || loadingKnowledge"
              >
                <div class="e-card-content text-center">
                  <app-loader></app-loader>
                </div>
              </ng-container>
            </div>
          </ng-template>
        </e-tabitem>
        <e-tabitem [header]="headerText[1]">
          <ng-template #content>
            <div class="e-card" tabindex="0">
              <ng-container
                *ngIf="knowledge && !loadingKnowledge"
              >
                <div class="e-card-content">
                  <div class="row">
                    <div class="col-12 mt-2">
                      <button
                        (click)="
                                                    renderCommentFormDialog = true;
                                                    isCreateComment = true;
                                                    isUpdateComment = false;
                                                    isCopyComment = false
                                                "
                        *ngIf="!knowledge.deleted_date"
                        cssClass="e-btn e-primary btn-p-zero e-small"
                        ejs-button
                        title="přidat komentář do diskuze"
                        type="button"
                      >
                        <i
                          aria-hidden="true"
                          class="fa-light fa-edit mr-lg-1"
                        ></i>
                        <span
                          class="d-none d-lg-inline"
                        >Přidat komentář</span
                        >
                      </button>
                      <div class="clearfix"></div>
                      <div
                        class="e-card-separator mb-1 mt-1"
                      ></div>
                      <app-knowledge-comments
                        (addKnowledgeCommentEvent)="reloadData()"
                        (deleteKnowledgeCommentEvent)="highlightedComment = $event; deleteCommentDialog.show()"
                        (editKnowledgeCommentEvent)="highlightedComment = $event; editComment()"
                        [knowledge]="knowledge"
                      ></app-knowledge-comments>
                    </div>
                  </div>
                </div>
              </ng-container>
              <ng-container
                *ngIf="!knowledge || loadingKnowledge"
              >
                <div class="e-card-content text-center">
                  <app-loader></app-loader>
                </div>
              </ng-container>
            </div>
          </ng-template>
        </e-tabitem>
        <e-tabitem [header]="headerText[2]">
          <ng-template #content>
            <app-knowledge-files [imagesBasic]="imagesBasic" [knowledge]="knowledge"></app-knowledge-files>
          </ng-template>
        </e-tabitem>
        <e-tabitem [header]="headerText[3]">
          <ng-template #content>
            <div class="e-card" tabindex="0">
              <ng-container
                *ngIf="knowledge && !loadingKnowledge"
              >
                <div class="e-card-content">
                  <div class="row">
                    <div class="col-12 mt-2"></div>
                  </div>
                </div>
              </ng-container>
              <ng-container
                *ngIf="!knowledge || loadingKnowledge"
              >
                <div class="e-card-content text-center">
                  <app-loader></app-loader>
                </div>
              </ng-container>
            </div>
          </ng-template>
        </e-tabitem>
      </e-tabitems>
    </ejs-tab>
  </div>
  <div class="col-12 col-md-4 col-lg-3 p-2">
    <app-knowledge-sidebar
      (addDepartmentsEvent)="addDepartmentsDialog.show()"
      (addUserEvent)="addUserDialog.show()"
      (deleteDepartmentEvent)="highlightedDepartmentRow = $event; deleteDepartmentDialog.show()"
      (deleteUserEvent)="highlightedUserRow = $event; deleteUserDialog.show()" [knowledge]="knowledge"
    ></app-knowledge-sidebar>
  </div>
</div>
