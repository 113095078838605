<ejs-dialog
  #formDialog
  (close)="
        isUpdate = false;
        isCreate = false;
        isDirty = false;
        isChecked = false;
        submited = false;
        changeDialogState(false)
    "
  [isModal]="true"
  [showCloseIcon]="true"
  [visible]="isVisible"
  [width]="'85%'"
  id="formDialog"
>
  <form [formGroup]="officeForm">
    <ng-template #content>
      <div class="dialogContent">
        <div *ngIf="office" class="row pb-3">
          <div class="col-12">
            <h5
              *ngIf="office.type === 'open'"
              class="e-badge e-badge-primary"
            >
              Open Office
            </h5>
            <h5
              *ngIf="office.type === 'private'"
              class="e-badge e-badge-primary"
            >
              Soukromá kancelář
            </h5>
            <h5
              *ngIf="office.type === 'share'"
              class="e-badge e-badge-primary"
            >
              Sdílená kancelář
            </h5>
            <h5
              *ngIf="office.type === 'meeting'"
              class="e-badge e-badge-primary"
            >
              Zasedací místnost
            </h5>
          </div>
        </div>
        <div class="tab mb-3" id="tab0">
          <div class="row">
            <div class="form-group col-12">
              <label
                [ngClass]="
                                    f.company_id.valid
                                        ? 'text-success'
                                        : f.company_id.invalid
                                        ? 'text-danger'
                                        : 'text-muted'
                                "
                for="company_id"
              >* Vlastník kanceláře:</label
              >
              <ejs-dropdownlist
                #company
                (filtering)="onFilteringCompanies($event)"
                [allowFiltering]="true"
                [dataSource]="companySelect"
                [fields]="fields"
                [filterBarPlaceholder]="
                                    'Vyhledat společnost...'
                                "
                [ignoreAccent]="true"
                [placeholder]="'Vyberte společnost'"
                [popupHeight]="height"
                formControlName="company_id"
                id="company_id"
                sortOrder="Ascending"
              ></ejs-dropdownlist>
              <small
                *ngIf="
                                    f.company_id.touched &&
                                    f.company_id.errors &&
                                    f.company_id.errors.required
                                "
                class="text-danger"
              >Vlastník je povinný</small
              >
            </div>
          </div>
          <div class="row">
            <div class="form-group col-12">
              <label
                [ngClass]="
                                    f.building_id.valid
                                        ? 'text-success'
                                        : f.building_id.invalid
                                        ? 'text-danger'
                                        : 'text-muted'
                                "
                for="company_id"
              >* Budova:</label
              >
              <ejs-dropdownlist
                #building
                (filtering)="onFilteringBuildings($event)"
                [allowFiltering]="true"
                [dataSource]="buildingSelect"
                [fields]="fields"
                [filterBarPlaceholder]="'Vyhledat budovu...'"
                [ignoreAccent]="true"
                [placeholder]="'Vyberte budovu'"
                [popupHeight]="height"
                formControlName="building_id"
                id="building_id"
                sortOrder="Ascending"
              ></ejs-dropdownlist>
              <small
                *ngIf="
                                    f.building_id.touched &&
                                    f.building_id.errors &&
                                    f.building_id.errors.required
                                "
                class="text-danger"
              >Budova je povinná</small
              >
            </div>
          </div>
          <div class="row">
            <div class="form-group col-12">
              <label
                [ngClass]="
                                    f.type.valid
                                        ? 'text-success'
                                        : f.type.invalid
                                        ? 'text-danger'
                                        : 'text-muted'
                                "
                for="engine"
              >* Typ kanceláře:</label
              >
              <ejs-dropdownlist
                #type
                (filtering)="onFilteringTypes($event)"
                [allowFiltering]="true"
                [dataSource]="typeSelect"
                [fields]="fields"
                [filterBarPlaceholder]="
                                    'Vyhledat typ kanceláře...'
                                "
                [ignoreAccent]="true"
                [placeholder]="'Vyberte typ kanceláře'"
                [popupHeight]="height"
                formControlName="type"
                id="type"
                sortOrder="Ascending"
              ></ejs-dropdownlist>
              <small
                *ngIf="
                                    f.type.touched &&
                                    f.type.errors &&
                                    f.type.errors.required
                                "
                class="text-danger"
              >Typ kanceláře je povinný</small
              >
            </div>
          </div>
          <div class="row">
            <div class="form-group col-12">
              <label
                [ngClass]="
                                    f.name.valid
                                        ? 'text-success'
                                        : f.name.invalid
                                        ? 'text-danger'
                                        : 'text-muted'
                                "
                for="reason"
              >* Název:</label
              >
              <ejs-textbox
                [cssClass]="
                                    f.name.invalid
                                        ? 'e-error'
                                        : f.name.valid
                                        ? 'e-success'
                                        : ''
                                "
                floatLabelType="Auto"
                formControlName="name"
                id="name"
              ></ejs-textbox>
              <small
                *ngIf="
                                    f.name.touched &&
                                    f.name.errors &&
                                    f.name.errors.required
                                "
                class="text-danger"
              >Název vozu je povinný</small
              >
            </div>
          </div>
          <div class="row">
            <div class="form-group col-4">
              <ejs-checkbox
                [ngClass]="
                                    f.bookable.valid
                                        ? 'text-success'
                                        : f.bookable.invalid
                                        ? 'text-danger'
                                        : 'text-muted'
                                "
                cssClass="e-primary p-0 m-0"
                formControlName="bookable"
                label="Možnost rezervace"
              ></ejs-checkbox>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
    <ng-template #footerTemplate>
      <div class="d-flex justify-content-end">
                <span
                  *ngIf="officeForm.invalid"
                  class="e-badge e-badge-warning p-2 size_2"
                  role="alert"
                >
                    <strong>Formulář je špatně vyplněný</strong>
                </span>
        <span
          *ngIf="officeForm.valid"
          class="e-badge e-badge-success p-2 size_2"
          role="alert"
        >
                    <strong>Formulář je správně vyplněný</strong>
                </span>
      </div>
      <div class="row">
        <div class="col-12 pt-2">
          <button
            (click)="onSubmit()"
            [disabled]="
                            loadingOffice || officeForm.invalid || !isChecked
                        "
            cssClass="e-btn e-success btn-p-zero e-small m-1"
            ejs-button
            type="button"
          >
                        <span
                          *ngIf="loadingOffice === true"
                          aria-hidden="true"
                          class="spinner-grow spinner-grow-sm"
                          role="status"
                        ></span>
            <span *ngIf="loadingOffice === false">ULOŽIT</span>
            <span *ngIf="loadingOffice === true">Provádím...</span>
          </button>
          <button
            (click)="formDialogObj.hide()"
            cssClass="e-btn e-danger e-outline btn-p-zero e-small m-1"
            ejs-button
            type="button"
          >
            ZRUŠIT
          </button>
        </div>
      </div>
    </ng-template>
  </form>
</ejs-dialog>
