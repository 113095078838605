import {ChangeDetectionStrategy, Component, HostListener, OnInit} from '@angular/core';
import {MarkdownService} from 'ngx-markdown';
import {of} from 'rxjs';
import {delay, first, tap} from 'rxjs/operators';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'app-wiki',
    templateUrl: './wiki.component.html',
    styleUrls: ['./wiki.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WikiComponent implements OnInit {
    pathToFile = '../remote/';
    // eslint-disable-next-line @typescript-eslint/no-require-imports
    ticketCreate = require('!raw-loader!./markdown/tickets/create.md');
    // eslint-disable-next-line @typescript-eslint/no-require-imports
    ticketEdit = require('!raw-loader!./markdown/tickets/edit.md');

    // #region markdown
    // eslint-disable-next-line @typescript-eslint/no-require-imports
    ticketAssignations = require('!raw-loader!./markdown/tickets/assignations.md');
    // eslint-disable-next-line @typescript-eslint/no-require-imports
    ticketTask = require('!raw-loader!./markdown/tickets/tasks.md');
    // eslint-disable-next-line @typescript-eslint/no-require-imports
    ticketComments = require('!raw-loader!./markdown/tickets/comments.md');
    // eslint-disable-next-line @typescript-eslint/no-require-imports
    ticketFiles = require('!raw-loader!./markdown/tickets/files.md');
    // eslint-disable-next-line @typescript-eslint/no-require-imports
    hotlinerCreate = require('!raw-loader!./markdown/hotlines/hotliner_create.md');
    // eslint-disable-next-line @typescript-eslint/no-require-imports
    hotlinerEdit = require('!raw-loader!./markdown/hotlines/hotliner_edit.md');
    // eslint-disable-next-line @typescript-eslint/no-require-imports
    hotlineCreate = require('!raw-loader!./markdown/hotlines/hotline_create.md');
    // eslint-disable-next-line @typescript-eslint/no-require-imports
    hotlineImport = require('!raw-loader!./markdown/hotlines/hotline_import.md');
    // eslint-disable-next-line @typescript-eslint/no-require-imports
    hotlineEdit = require('!raw-loader!./markdown/hotlines/hotline_edit.md');
    // eslint-disable-next-line @typescript-eslint/no-require-imports
    blockquotes = require('!raw-loader!./markdown/blockquotes.md');
    // eslint-disable-next-line @typescript-eslint/no-require-imports
    codeAndSynthaxHighlighting = require('!raw-loader!./markdown/code-and-synthax-highlighting.md');
    // eslint-disable-next-line @typescript-eslint/no-require-imports
    emphasis = require('!raw-loader!./markdown/emphasis.md');
    // eslint-disable-next-line @typescript-eslint/no-require-imports
    horizontalRule = require('!raw-loader!./markdown/horizontal-rule.md');
    // eslint-disable-next-line @typescript-eslint/no-require-imports
    images = require('!raw-loader!./markdown/images.md');
    // eslint-disable-next-line @typescript-eslint/no-require-imports
    links = require('!raw-loader!./markdown/links.md');
    // eslint-disable-next-line @typescript-eslint/no-require-imports
    lists = require('!raw-loader!./markdown/lists.md');
    // eslint-disable-next-line @typescript-eslint/no-require-imports
    listsDot = require('!raw-loader!./markdown/lists-dot.md');
    // eslint-disable-next-line @typescript-eslint/no-require-imports
    tables = require('!raw-loader!./markdown/tables.md');
    // eslint-disable-next-line @typescript-eslint/no-require-imports
    demoPython = require('!raw-loader!./remote/demo.py');
    // eslint-disable-next-line @typescript-eslint/no-require-imports
    languagePipe = require('!raw-loader!./remote/language-pipe.html');
    // #endregion

    // #region remote
    // #region variable-binding
    markdown = `### Markdown example
---
This is an **example** where we bind a variable to the \`markdown\` component that is also bind to a textarea.
#### example.component.ts
\`\`\`typescript
public markdown = "# Markdown";
\`\`\`
#### example.component.html
\`\`\`html
<textarea [(ngModel)]="markdown"></textarea>
<markdown [data]="markdown"></markdown>
\`\`\``;
    // #region pipe
    typescriptMarkdown = `import { Component } from '@angular/core';
@Component({
  // tslint:disable-next-line:component-selector
    selector: 'markdown-demo',
  templateUrl: './markdown-demo.component.html',
  styleUrls: ['./markdown-demo.component.scss'],
})
export class MarkdownDemoComponent {
  public pipeMarkdown = '# Markdown';
}`;
    // #endregion
    // eslint-disable-next-line @typescript-eslint/naming-convention
    protected _titleIsAnimating = false;
    // #endregion
    // eslint-disable-next-line @typescript-eslint/naming-convention
    protected _pushpinIsOn = false;

    // #endregion

    constructor(private readonly markdownService: MarkdownService) {}

    @HostListener('window:resize')
    onWindowResize(): void {
        // this.initPushpin();
    }

    @HostListener('window:scroll')
    onWindowScroll(): void {
        this.animateTitle();
        this.animateScrollTop();
    }

    ngOnInit(): void {
        this.initMarkdown();
        // this.initPushpin();
        // this.initScrollSpy();
    }

    onPageUp(): void {
        $('html, body')
            .animate(
                {scrollTop: 0},
                {
                    duration: 400,
                    queue: false,
                    easing: 'easeOutCubic',
                },
            );
    }

    onConvertToPDF(): void {
        // EMPTY TODO
    }

    private animateScrollTop(): void {
        const scrollTop = $('.fixed-action-btn button');
        const windowOffset = window.pageYOffset;
        const hasScaleInClass = scrollTop.hasClass('scale-in');
        const targetScaleInClass = windowOffset > 100;

        // scale-in
        if (!hasScaleInClass && targetScaleInClass) {
            scrollTop.addClass('scale-in');
            of(null)
                .pipe(
                    tap(() => scrollTop.addClass('pulse')),
                    delay(1000),
                    tap(() => scrollTop.removeClass('pulse')),
                    first(),
                )
                .subscribe();
        }

        // scale-out
        if (hasScaleInClass && !targetScaleInClass) {
            scrollTop.removeClass('scale-in');
        }
    }

    private animateTitle(): void {
        const title = $('.title a');
        const titleOffset = title[0].offsetTop;
        const windowOffset = window.pageYOffset;
        const currentFontSize = title[0].style.fontSize;
        const targetFontSize = windowOffset > titleOffset ? '2.28rem' : '2.92rem';

        // eslint-disable-next-line no-underscore-dangle
        if (currentFontSize !== targetFontSize && !this._titleIsAnimating) {
            title.animate(
                {fontSize: targetFontSize},
                {
                    duration: 200,
                    queue: false,
                    easing: 'easeOutCubic',
                    // eslint-disable-next-line no-underscore-dangle
                    start: () => (this._titleIsAnimating = true),
                    // eslint-disable-next-line no-underscore-dangle
                    complete: () => (this._titleIsAnimating = false),
                },
            );
        }
    }

    private initMarkdown(): void {
        this.markdownService.renderer.heading = (text: string, level: number): string => {
            const escapedText = text.toLowerCase()
                .replace(/[^\w]+/g, '-');

            return `<h${level}><a name="${escapedText}" class="anchor" href="#${escapedText}"><span class="header-link"></span></a>${text}</h${level}>`;
        };
    }

    /* private initPushpin() {
         const tableOfContent = $('.table-of-contents');
         // add pushpin
         if (!this._pushpinIsOn && window.innerWidth > 992) {
             const pushpinTop = tableOfContent.parent().offset().top;
             tableOfContent.pushpin({top: pushpinTop});
             this._pushpinIsOn = true;
         }
         // remove pushpin
         if (this._pushpinIsOn && window.innerWidth <= 992) {
             tableOfContent.pushpin('remove' as any);
             this._pushpinIsOn = false;
         }
     }

     private initScrollSpy() {
         $('section').scrollSpy();
     }*/
}
