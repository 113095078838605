import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {AbstractControl, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {Location} from '@angular/common';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {Company} from '@src/app/_models/company/company';
import {User} from '@src/app/_models/user/user';
import {CompanyInput, SettingsService} from '@src/app/features/settings/settings.service';
import {UserInput, UsersService} from '@src/app/features/users/users.service';
import {CustomValidators} from '@src/app/features/users/custom-validators';

@UntilDestroy()
@Component({
    selector: 'app-register',
    templateUrl: './register.component.html',
    styleUrls: ['./register.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RegisterComponent implements OnInit {
    registerForm: FormGroup;

    loadingRegister = false;

    submited = false;

    user: User;

    company: Company;

    constructor(
        private readonly formBuilder: FormBuilder,
        private readonly route: ActivatedRoute,
        private readonly router: Router,
        private readonly location: Location,
        private readonly settingsService: SettingsService,
        private readonly userService: UsersService,
    ) {}

    // convenience getter for easy access to form fields
    get f(): {[key: string]: AbstractControl} {
        return this.registerForm.controls;
    }

    ngOnInit(): void {
        this.registerForm = this.formBuilder.group(
            {
                companyName: [
                    '',
                    Validators.compose([
                        Validators.required,
                        Validators.minLength(2),
                        Validators.maxLength(100),
                    ]),
                ],
                adressName: [
                    '',
                    Validators.compose([
                        Validators.required,
                        Validators.minLength(20),
                        Validators.maxLength(500),
                    ]),
                ],
                adressRuian: [
                    '',
                    Validators.compose([
                        Validators.required,
                        Validators.minLength(6),
                        Validators.maxLength(20),
                    ]),
                ],
                companyIco: [
                    '',
                    Validators.compose([
                        Validators.required,
                        Validators.minLength(8),
                        Validators.maxLength(8),
                    ]),
                ],
                userFirstname: [
                    '',
                    Validators.compose([
                        Validators.required,
                        Validators.minLength(2),
                        Validators.maxLength(100),
                    ]),
                ],
                userSecondname: [
                    '',
                    Validators.compose([
                        Validators.required,
                        Validators.minLength(2),
                        Validators.maxLength(100),
                    ]),
                ],
                userPassword: [
                    '',
                    Validators.compose([
                        Validators.required,
                        CustomValidators.patternValidator(/\d/, {hasNumber: true}),
                        CustomValidators.patternValidator(/[A-Z]/, {
                            hasCapitalCase: true,
                        }),
                        CustomValidators.patternValidator(/[a-z]/, {hasSmallCase: true}),
                        Validators.minLength(8),
                    ]),
                ],
                confirmPassword: ['', Validators.compose([Validators.required])],
                userWorkemail: [
                    '',
                    Validators.compose([
                        Validators.required,
                        Validators.minLength(7),
                        Validators.maxLength(100),
                    ]),
                    this.isEmailUnique.bind(this),
                ],
                userTelnumber: [
                    '',
                    Validators.compose([
                        Validators.required,
                        CustomValidators.patternValidator(/[0-9]/, {isTelNumber: true}),
                        Validators.minLength(9),
                        Validators.maxLength(9),
                    ]),
                    this.isTelUnique.bind(this),
                ],
                userFoto: ['/foto/avatar-default.png'],
            },
            {validator: CustomValidators.passwordMatchValidator},
        );
    }

    isEmailUnique(control: FormControl): Promise<{[key: string]: boolean} | null> | undefined {
        return new Promise(resolve => {
            setTimeout(() => {
                this.userService
                    .isEmailRegistered(control.value as string)
                    .pipe(untilDestroyed(this))
                    .subscribe(
                        (data: boolean) => {
                            if (data) {
                                resolve({
                                    taken: true,
                                });
                            } else {
                                resolve(null);
                            }
                        },
                        () => {
                            resolve(null);
                        },
                    );
            }, 1000);
        });
    }

    isTelUnique(control: FormControl): Promise<{[key: string]: boolean} | null> | undefined {
        return new Promise(resolve => {
            setTimeout(() => {
                this.userService
                    .isTelRegistered(control.value as string)
                    .pipe(untilDestroyed(this))
                    .subscribe(
                        (data: boolean) => {
                            if (data) {
                                resolve({
                                    taken: true,
                                });
                            } else {
                                resolve(null);
                            }
                        },
                        () => {
                            resolve(null);
                        },
                    );
            }, 1000);
        });
    }

    onSubmit(): void {
        this.submited = true;

        if (this.registerForm.invalid) {
            console.error('form is not valid!');

            return;
        }

        this.loadingRegister = true;

        const company: CompanyInput = {
            name: this.f.companyName.value,
            address: this.f.adressName.value,
            ico: this.f.companyIco.value,
            ruian: this.f.adressRuian.value,
        };

        const user: UserInput = {
            firstname: this.f.userFirstname.value,
            secondname: this.f.userSecondname.value,
            password: this.f.userPassword.value,
            workemail: this.f.userWorkemail.value,
            telnumber: this.f.userTelnumber.value,
            authorized: true,
            emailverify: false,
        };

        this.settingsService
            .addCompany(company)
            .pipe(untilDestroyed(this))
            .subscribe(
                () => {
                    this.userService
                        .addUser(user)
                        .pipe(untilDestroyed(this))
                        .subscribe(
                            () => {
                                void this.router.navigate(['/login']);
                            },
                            error => {
                                console.error(error);
                                this.loadingRegister = false;
                            },
                        );
                },
                error => {
                    console.error(error);
                    this.loadingRegister = false;
                },
            );
    }

    goBack(): void {
        this.location.back();
    }
}
