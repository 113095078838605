import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {DatePipe, registerLocaleData} from '@angular/common';
import {Vacation} from '@src/app/_models/vacation/vacation';
import {AuthenticationService} from '@src/app/_services/authentication.service';
import {Material} from '@src/app/_models/material/material';
import {Holiday} from '@src/app/_models/holiday/holiday';
import {DataService} from '@src/app/_services/data.service';
import {Knowledge} from '@src/app/_models/knowledge/knowledge';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';

import moment from 'moment';
import localeCs from '@angular/common/locales/cs';
import {User} from '@src/app/_models/user/user';
import {UsersService} from '@src/app/features/users/users.service';
import {VacationService} from '@src/app/features/vacation/vacation.service';
import {MaterialService} from '@src/app/features/material/material.service';

moment.updateLocale('cs', {workingWeekdays: [1, 2, 3, 4, 5]});
registerLocaleData(localeCs);

@UntilDestroy()
@Component({
    selector: 'app-stats-card',
    templateUrl: './stats-card.component.html',
    styleUrls: ['./stats-card.component.scss'],
    providers: [DatePipe],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StatsCardComponent implements OnInit {
    vacations: Vacation[] = [];

    materials: Material[] = [];

    knowledges: Knowledge[] = [];

    holidays: Holiday;

    currentUser: User | null;

    loadingMaterialWidget = true;

    loadingVacationWidget = true;

    loadingHolidaysWidget = true;

    loadingKnowledgeWidget = true;

    constructor(
        private readonly authenticationService: AuthenticationService,
        private readonly userService: UsersService,
        private readonly vacationService: VacationService,
        private readonly materialService: MaterialService,
        private readonly dataService: DataService,
        private readonly ref: ChangeDetectorRef,
    ) {
        this.currentUser = this.authenticationService.currentUserValue;
    }

    ngOnInit(): void {
        this.dataService.vacationSource.pipe(untilDestroyed(this))
            .subscribe(
                (data: Vacation[]) => {
                    this.vacations = [];
                    data.map((vacation: Vacation) => {
                        if (
                            (vacation.creator.id === this.currentUser?.id ||
                                (vacation.confirmer &&
                                    vacation.confirmer.id === this.currentUser?.id) ||
                                (vacation.decliner && vacation.decliner.id === this.currentUser?.id) ||
                                vacation.creator.boss_id === this.currentUser?.id) &&
                            !vacation.deleted_date &&
                            !vacation.decline &&
                            !vacation.confirm
                        ) {
                            this.vacations.push(vacation);
                        }
                    });
                    this.loadingVacationWidget = false;
                    this.ref.markForCheck();
                },
                error => {
                    console.error(error);
                    this.loadingVacationWidget = false;
                    this.ref.markForCheck();
                },
            );

        this.dataService.knowledgeSource.pipe(untilDestroyed(this))
            .subscribe(
                (knowledges: Knowledge[]) => {
                    this.knowledges = [];
                    knowledges.map((knowledge: Knowledge) => {
                        if (!knowledge.deleted_date) {
                            this.knowledges.push(knowledge);
                        }
                    });
                    this.loadingKnowledgeWidget = false;
                    this.ref.markForCheck();
                },
                error => {
                    console.error(error);
                    this.loadingKnowledgeWidget = false;
                    this.ref.markForCheck();
                },
            );

        this.dataService.materialSource.pipe(untilDestroyed(this))
            .subscribe(
                (data: Material[]) => {
                    this.materials = [];
                    data.map((material: Material) => {
                        if (material.created_by === this.currentUser?.id || material.amiboss) {
                            if (!material.declined && !material.submited && !material.deleted_date) {
                                this.materials.push(material);
                            }
                        }
                    });
                    this.loadingMaterialWidget = false;
                    this.ref.markForCheck();
                },
                error => {
                    console.error(error);
                    this.loadingMaterialWidget = false;
                    this.ref.markForCheck();
                },
            );

        this.vacationService
            .getCurrCZEHolidays()
            .pipe(untilDestroyed(this))
            .subscribe(
                (holidays: Holiday[]) => {
                    holidays.map(() => {
                        const holidayTemp = holidays.find(
                            holiday => holiday.date === moment()
                                .format('YYYY-MM-DD'),
                        );

                        if (holidayTemp) {
                            this.holidays = holidayTemp;
                        }

                        this.loadingHolidaysWidget = false;
                        this.ref.markForCheck();
                    });
                },
                error => {
                    console.error(error);
                    this.loadingHolidaysWidget = false;
                    this.ref.markForCheck();
                },
            );

        this.dataService.setKnowledgesDataSource();
        this.dataService.setVacationsDataSource();
    }
}
