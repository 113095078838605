<div
  class="row d-flex justify-content-center modalWrapper"
  id="dialogContainer"
>
  <ejs-dialog
    #fieldRemoveDialog
    [buttons]="buttonsRemoveFieldDialog"
    [content]="'Opravdu chcete smazat tuto parcelu?'"
    [header]="'Mazání parcely'"
    [height]="'200px'"
    [isModal]="true"
    [showCloseIcon]="true"
    [target]="'#dialogContainer'"
    [visible]="false"
    [width]="'350px'"
    id="fieldRemoveDialog"
  ></ejs-dialog>
  <ejs-dialog
    #fileRemoveDialog
    [buttons]="buttonsRemoveFileDialog"
    [content]="'Opravdu chcete smazat soubor?'"
    [header]="'Mazání souboru'"
    [height]="'200px'"
    [isModal]="true"
    [showCloseIcon]="true"
    [target]="'#dialogContainer'"
    [visible]="false"
    [width]="'350px'"
    id="fileRemoveDialog"
  ></ejs-dialog>
  <app-project-form
    (projectFormDialogState)="
            renderProjectFormDialog = $event;
            isCreate = $event;
            isUpdate = $event;
            isCopy = $event
        "
    [isCopy]="isCopy"
    [isCreate]="isCreate"
    [isUpdate]="isUpdate"
    [isVisible]="renderProjectFormDialog"
    [project]="project"
  ></app-project-form>
</div>
<div class="row">
  <div class="col-12 col-md-8">
    <ejs-tab
      #serviceFieldDetailTab
      (created)="onCreate()"
      (selected)="select($event)"
      (selecting)="preventSwipe($event)"
      enablePersistence="true"
      height="auto"
      heightAdjustMode="Content"
      id="serviceFieldDetailTab"
    >
      <e-tabitems>
        <e-tabitem [header]="headerText[0]">
          <ng-template #content>
            <div class="e-card m-1">
              <ng-container *ngIf="field && !loadingAddress">
                <div
                  class="e-card-header stylish-color-dark p-2"
                >
                  <div class="e-card-header-caption">
                    <div
                      class="d-flex justify-content-between e-card-header-title text-white"
                    >
                      <strong>
                        {{ field.address }}
                      </strong>
                      <span>
                                                <button
                                                  (click)="
                                                        editMode = false;
                                                        saveChanges()
                                                    "
                                                  *ngIf="editMode"
                                                  cssClass="e-success e-btn btn-p-zero"
                                                  ejs-button
                                                  type="button"
                                                >
                                                    <i
                                                      aria-hidden="true"
                                                      class="fa-light fa-pencil mr-lg-1"
                                                    ></i>
                                                    <span
                                                      class="d-none d-lg-inline"
                                                    >uložit úpravy</span
                                                    >
                                                </button>
                                                <button
                                                  (click)="
                                                        editMode =
                                                            editMode !== true
                                                    "
                                                  *ngIf="
                                                        (field.created_by ===
                                                            currentUser.id ||
                                                            (currentUser.rule &&
                                                                currentUser.rule
                                                                    .is_admin)) &&
                                                        !field.deleted_date
                                                    "
                                                  cssClass="e-btn e-warning btn-p-zero e-small"
                                                  ejs-button
                                                  type="button"
                                                >
                                                    <i
                                                      *ngIf="!editMode"
                                                      aria-hidden="true"
                                                      class="fa-light fa-pencil mr-lg-1"
                                                    ></i>
                                                    <i
                                                      *ngIf="editMode"
                                                      aria-hidden="true"
                                                      class="fa-light fa-times mr-lg-1"
                                                    ></i>
                                                    <span
                                                      *ngIf="!editMode"
                                                      class="d-none d-lg-inline"
                                                    >upravit</span
                                                    >
                                                    <span
                                                      *ngIf="editMode"
                                                      class="d-none d-lg-inline"
                                                    >ZRUŠIT úpravy</span
                                                    >
                                                </button>
                                                <button
                                                  (click)="
                                                        fieldRemoveDialog.show()
                                                    "
                                                  *ngIf="
                                                        (field.created_by ===
                                                            currentUser.id ||
                                                            (currentUser.rule &&
                                                                currentUser.rule
                                                                    .is_admin)) &&
                                                        !field.deleted_date
                                                    "
                                                  cssClass="e-btn e-danger btn-p-zero e-small"
                                                  ejs-button
                                                  type="button"
                                                >
                                                    <i
                                                      aria-hidden="true"
                                                      class="fa-light fa-trash mr-lg-1"
                                                    ></i>
                                                    <span
                                                      class="d-none d-lg-inline"
                                                    >smazat</span
                                                    >
                                                </button>
                                                <button
                                                  (click)="newProject()"
                                                  cssClass="e-btn e-primary btn-p-zero e-small"
                                                  ejs-button
                                                  type="button"
                                                >
                                                    <i
                                                      aria-hidden="true"
                                                      class="fa-light fa-construction mr-lg-1"
                                                    ></i>
                                                    <span
                                                      class="d-none d-lg-inline"
                                                    >nový projekt</span
                                                    >
                                                </button>
                                            </span>
                    </div>
                  </div>
                </div>
                <div class="e-card-content pt-2 pb-2 pl-1 pr-1">
                  <h6 class="p-2">
                    <i
                      aria-hidden="true"
                      class="fa-light fa-lg fa-home"
                    ></i
                    >&nbsp;Parcela:
                  </h6>
                  <div class="e-card-separator"></div>
                  <ul class="list-group mb-3">
                    <li
                      class="list-group-item d-flex justify-content-between"
                    >
                      <strong>Parcela:</strong>
                      <div
                        [ngClass]="
                                                    editMode
                                                        ? 'e-success'
                                                        : 'e-disable'
                                                "
                        class="e-input-group w-50"
                      >
                        <input
                          [disabled]="!editMode"
                          [value]="field.address"
                          class="text-right e-input"
                          id="ctrl_address"
                          placeholder="Adresa parcely"
                          type="text"
                        />
                      </div>
                    </li>
                    <li
                      class="list-group-item d-flex justify-content-between"
                    >
                      <strong>Číslo parcely:</strong>
                      <div
                        [ngClass]="
                                                    editMode
                                                        ? 'e-success'
                                                        : 'e-disable'
                                                "
                        class="e-input-group w-50"
                      >
                        <ejs-numerictextbox
                          [enabled]="!editMode"
                          [format]="'###'"
                          [value]="field.field_number"
                          id="ctrl_field_number"
                          min="1"
                          placeholder="Číslo parcely"
                          step="1"
                        ></ejs-numerictextbox>
                      </div>
                    </li>
                    <li
                      class="list-group-item d-flex justify-content-between"
                    >
                      <strong>Název ulice:</strong>
                      <div
                        [ngClass]="
                                                    editMode
                                                        ? 'e-success'
                                                        : 'e-disable'
                                                "
                        class="e-input-group w-50"
                      >
                        <input
                          [disabled]="!editMode"
                          [value]="
                                                        field.address_obj &&
                                                        field.address_obj.street
                                                            ? field.address_obj
                                                                  .street
                                                            : null
                                                    "
                          class="text-right e-input"
                          id="ctrl_street"
                          placeholder="Název ulice"
                          type="text"
                        />
                      </div>
                    </li>
                    <li
                      class="list-group-item d-flex justify-content-between"
                    >
                      <strong>Město:</strong>
                      <div class="w-50">
                        <div
                          [ngClass]="
                                                        editMode
                                                            ? 'e-success'
                                                            : 'e-disable'
                                                    "
                          class="e-input-group"
                        >
                          <input
                            (focus)="
                                                            reinitSmartForm()
                                                        "
                            [disabled]="!editMode"
                            [value]="
                                                            field.address_obj &&
                                                            field.address_obj
                                                                .city
                                                                ? field
                                                                      .address_obj
                                                                      .city
                                                                : null
                                                        "
                            autocomplete="smartform-auto-city"
                            class="text-right e-input smartform-instance-city smartform-city"
                            id="ctrl_city"
                            placeholder="Město"
                            type="text"
                          />
                        </div>
                        <small
                          *ngIf="editMode"
                          class="d-block text-danger w-100 text-right"
                        >Město je povinné</small
                        >
                      </div>
                    </li>
                    <li
                      class="list-group-item d-flex justify-content-between"
                    >
                      <strong>PSČ:</strong>
                      <div class="w-50">
                        <div
                          [ngClass]="
                                                        editMode
                                                            ? 'e-success'
                                                            : 'e-disable'
                                                    "
                          class="e-input-group"
                        >
                          <input
                            (focus)="
                                                            reinitSmartForm()
                                                        "
                            [disabled]="!editMode"
                            [value]="
                                                            field.address_obj &&
                                                            field.address_obj
                                                                .zip
                                                                ? field
                                                                      .address_obj
                                                                      .zip
                                                                : null
                                                        "
                            autocomplete="smartform-auto-zip"
                            class="text-right e-input smartform-instance-city smartform-zip"
                            id="ctrl_zip"
                            placeholder="PSČ"
                            type="text"
                          />
                        </div>
                        <small
                          *ngIf="editMode"
                          class="d-block text-danger w-100 text-right"
                        >PSČ je povinné</small
                        >
                      </div>
                    </li>
                    <li
                      class="list-group-item d-flex justify-content-between"
                    >
                      <strong>Městská část:</strong>
                      <div
                        [ngClass]="
                                                    editMode
                                                        ? 'e-success'
                                                        : 'e-disable'
                                                "
                        class="e-input-group w-50"
                      >
                        <input
                          [disabled]="!editMode"
                          [value]="
                                                        field.address_obj &&
                                                        field.address_obj
                                                            .city_part
                                                            ? field.address_obj
                                                                  .city_part
                                                            : null
                                                    "
                          autocomplete="smartform-auto-city"
                          class="text-right e-input smartform-instance-cityPart smartform-city"
                          id="ctrl_city_part"
                          placeholder="Městská část"
                          type="text"
                        />
                      </div>
                    </li>
                    <li
                      class="list-group-item d-flex justify-content-between"
                    >
                      <strong>Okres:</strong>
                      <div class="w-50">
                        <div
                          [ngClass]="
                                                        editMode
                                                            ? 'e-success'
                                                            : 'e-disable'
                                                    "
                          class="e-input-group"
                        >
                          <input
                            (focus)="
                                                            reinitSmartForm()
                                                        "
                            [disabled]="!editMode"
                            [value]="
                                                            field.address_obj &&
                                                            field.address_obj
                                                                .district
                                                                ? field
                                                                      .address_obj
                                                                      .district
                                                                : null
                                                        "
                            autocomplete="smartform-auto-district"
                            class="text-right e-input smartform-instance-district smartform-city"
                            id="ctrl_district"
                            placeholder="Okres"
                            type="text"
                          />
                        </div>
                        <small
                          *ngIf="editMode"
                          class="d-block text-danger w-100 text-right"
                        >Okres je povinný</small
                        >
                      </div>
                    </li>
                    <li
                      class="list-group-item d-flex justify-content-between"
                    >
                      <strong>Kraj:</strong>
                      <div class="w-50">
                        <div
                          [ngClass]="
                                                        editMode
                                                            ? 'e-success'
                                                            : 'e-disable'
                                                    "
                          class="e-input-group"
                        >
                          <input
                            [disabled]="!editMode"
                            [value]="
                                                            field.address_obj &&
                                                            field.address_obj
                                                                .region
                                                                ? field
                                                                      .address_obj
                                                                      .region
                                                                : null
                                                        "
                            class="text-right e-input"
                            id="ctrl_region"
                            placeholder="Kraj"
                            type="text"
                          />
                        </div>
                        <small
                          *ngIf="editMode"
                          class="d-block text-danger w-100 text-right"
                        >Kraj je povinný</small
                        >
                      </div>
                    </li>
                    <li
                      class="list-group-item d-flex justify-content-between"
                    >
                      <strong>Stát:</strong>
                      <div class="w-50">
                        <div
                          [ngClass]="
                                                        editMode
                                                            ? 'e-success'
                                                            : 'e-disable'
                                                    "
                          class="e-input-group"
                        >
                          <input
                            [disabled]="!editMode"
                            [value]="
                                                            field.address_obj &&
                                                            field.address_obj
                                                                .state
                                                                ? field
                                                                      .address_obj
                                                                      .state
                                                                : 'Česká republika'
                                                        "
                            class="text-right e-input"
                            id="ctrl_state"
                            placeholder="Stát"
                            type="text"
                          />
                        </div>
                        <small
                          *ngIf="editMode"
                          class="d-block text-danger w-100 text-right"
                        >Stát je povinný</small
                        >
                      </div>
                    </li>
                    <li
                      class="list-group-item d-flex justify-content-between"
                    >
                      <strong>GPS souřadnice:</strong>
                      <div
                        [ngClass]="
                                                    editMode
                                                        ? 'e-success'
                                                        : 'e-disable'
                                                "
                        class="e-input-group w-50"
                      >
                        <input
                          [disabled]="!editMode"
                          [value]="
                                                        field.address_obj &&
                                                        field.address_obj.gps
                                                            ? field.address_obj
                                                                  .gps
                                                            : null
                                                    "
                          class="text-right e-input"
                          id="ctrl_gps"
                          placeholder="GPS souřadnice"
                          type="text"
                        />
                      </div>
                    </li>
                    <li
                      class="list-group-item d-flex justify-content-between"
                    >
                      <strong>JSTK souřadnice:</strong>
                      <div
                        [ngClass]="
                                                    editMode
                                                        ? 'e-success'
                                                        : 'e-disable'
                                                "
                        class="e-input-group w-50"
                      >
                        <input
                          [disabled]="!editMode"
                          [value]="
                                                        field.address_obj &&
                                                        field.address_obj.jstk
                                                            ? field.address_obj
                                                                  .jstk
                                                            : null
                                                    "
                          class="text-right e-input"
                          id="ctrl_jstk"
                          placeholder="JSTK souřadnice"
                          type="text"
                        />
                      </div>
                    </li>
                  </ul>
                  <h6 class="p-2">
                    <i
                      aria-hidden="true"
                      class="fa-light fa-lg fa-building"
                    ></i
                    >&nbsp;Vlastnosti parcely:
                  </h6>
                  <div class="e-card-separator"></div>
                  <ul class="list-group mb-3">
                    <li
                      class="list-group-item d-flex justify-content-between"
                    >
                      <strong
                      >Počet bytových
                        jednotek:</strong
                      >
                      <div
                        [ngClass]="
                                                    editMode
                                                        ? 'e-success'
                                                        : 'e-disable'
                                                "
                        class="e-input-group w-50"
                      >
                        <ejs-numerictextbox
                          [enabled]="!editMode"
                          [format]="'###'"
                          [value]="
                                                        field.number_of_units
                                                    "
                          id="ctrl_number_of_units"
                          min="1"
                          placeholder="Počet bytových jednotek"
                          step="1"
                        ></ejs-numerictextbox>
                      </div>
                    </li>
                    <li
                      class="list-group-item d-flex justify-content-between"
                    >
                      <strong>Počet podlaží:</strong>
                      <div
                        [ngClass]="
                                                    editMode
                                                        ? 'e-success'
                                                        : 'e-disable'
                                                "
                        class="e-input-group w-50"
                      >
                        <ejs-numerictextbox
                          [enabled]="!editMode"
                          [format]="'###'"
                          [value]="
                                                        field.number_of_storeys
                                                    "
                          id="ctrl_number_of_storeys"
                          min="0"
                          placeholder="Počet podlaží"
                          step="1"
                        ></ejs-numerictextbox>
                      </div>
                    </li>
                    <li
                      class="list-group-item d-flex justify-content-between"
                    >
                      <strong
                      >Typ bytové jednotky:</strong
                      >
                      <div
                        [ngClass]="
                                                    editMode
                                                        ? 'e-success'
                                                        : 'e-disable'
                                                "
                        class="w-50"
                      >
                        <ejs-dropdownlist
                          #unit_type
                          [allowFiltering]="true"
                          [dataSource]="unitSelect"
                          [enabled]="editMode"
                          [fields]="ruianFieldObj"
                          [filterBarPlaceholder]="
                                                        'Vyhledat typ bytové jednotky...'
                                                    "
                          [ignoreAccent]="true"
                          [placeholder]="
                                                        'Vyberte typ bytové jednotky'
                                                    "
                          [popupHeight]="height"
                          [value]="
                                                        field.unit_type
                                                            ? field.unit_type.id
                                                            : null
                                                    "
                          id="unit_type"
                          sortOrder="Ascending"
                        ></ejs-dropdownlist>
                      </div>
                    </li>
                    <li
                      class="list-group-item d-flex justify-content-between"
                    >
                      <strong>Typ prostoru:</strong>
                      <div
                        [ngClass]="
                                                    editMode
                                                        ? 'e-success'
                                                        : 'e-disable'
                                                "
                        class="w-50"
                      >
                        <ejs-dropdownlist
                          #flat_space
                          [allowFiltering]="true"
                          [dataSource]="spaceSelect"
                          [enabled]="editMode"
                          [fields]="ruianFieldObj"
                          [filterBarPlaceholder]="
                                                        'Vyhledat typ prostoru...'
                                                    "
                          [ignoreAccent]="true"
                          [placeholder]="
                                                        'Vyberte typ prostoru'
                                                    "
                          [popupHeight]="height"
                          [value]="
                                                        field.flat_space
                                                            ? field.flat_space
                                                                  .id
                                                            : null
                                                    "
                          id="flat_space"
                          sortOrder="Ascending"
                        ></ejs-dropdownlist>
                      </div>
                    </li>
                    <li
                      class="list-group-item d-flex justify-content-between"
                    >
                      <strong>Vlastník:</strong>
                      <div
                        [ngClass]="
                                                    editMode
                                                        ? 'e-success'
                                                        : 'e-disable'
                                                "
                        class="e-input-group w-50"
                      >
                        <input
                          [disabled]="!editMode"
                          [value]="field.owner_name"
                          class="text-right e-input"
                          id="ctrl_owner_name"
                          placeholder="Vlastník"
                          type="text"
                        />
                      </div>
                    </li>
                    <li
                      class="list-group-item d-flex justify-content-between"
                    >
                      <strong>Správce:</strong>
                      <div
                        [ngClass]="
                                                    editMode
                                                        ? 'e-success'
                                                        : 'e-disable'
                                                "
                        class="e-input-group w-50"
                      >
                        <input
                          [disabled]="!editMode"
                          [value]="field.manager_name"
                          class="text-right e-input"
                          id="ctrl_manager_name"
                          placeholder="Správce"
                          type="text"
                        />
                      </div>
                    </li>
                    <li
                      class="list-group-item d-flex justify-content-between"
                    >
                      <strong>Výtah:</strong>
                      <ng-container *ngIf="editMode">
                        <div
                          [ngClass]="
                                                        editMode
                                                            ? 'e-success'
                                                            : 'e-disable'
                                                    "
                          class="w-50"
                        >
                          <ejs-radiobutton
                            checked="{{
                                                            field.lift
                                                        }}"
                            label="ANO"
                            name="lift"
                            value="true"
                          ></ejs-radiobutton>
                          <br />
                          <ejs-radiobutton
                            checked="{{
                                                            !field.lift
                                                        }}"
                            label="NE"
                            name="lift"
                            value="false"
                          ></ejs-radiobutton>
                        </div>
                      </ng-container>
                      <ng-container *ngIf="!editMode">
                                                <span
                                                  *ngIf="field.lift"
                                                  class="e-badge e-badge-success"
                                                >ANO</span
                                                >
                        <span
                          *ngIf="!field.lift"
                          class="e-badge e-badge-danger"
                        >NE</span
                        >
                      </ng-container>
                    </li>
                  </ul>
                  <h6 class="p-2">
                    <i
                      aria-hidden="true"
                      class="fa-light fa-lg fa-cog"
                    ></i
                    >&nbsp;Technické vlastnosti parcely:
                  </h6>
                  <div class="e-card-separator"></div>
                  <ul class="list-group mb-3">
                    <li
                      class="list-group-item d-flex justify-content-between"
                    >
                      <strong>Provider:</strong>
                      <div
                        [ngClass]="
                                                    editMode
                                                        ? 'e-success'
                                                        : 'e-disable'
                                                "
                        class="w-50"
                      >
                        <ejs-dropdownlist
                          #provider
                          [allowFiltering]="true"
                          [dataSource]="companySelect"
                          [enabled]="editMode"
                          [fields]="ruianFieldObj"
                          [filterBarPlaceholder]="
                                                        'Vyhledat providera...'
                                                    "
                          [ignoreAccent]="true"
                          [placeholder]="
                                                        'Vyberte providera'
                                                    "
                          [popupHeight]="height"
                          [value]="
                                                        field.provider
                                                            ? field.provider.id
                                                            : null
                                                    "
                          id="provider"
                          sortOrder="Ascending"
                        ></ejs-dropdownlist>
                      </div>
                    </li>
                    <li
                      class="list-group-item d-flex justify-content-between"
                    >
                      <strong>Typ technologie:</strong>
                      <div
                        [ngClass]="
                                                    editMode
                                                        ? 'e-success'
                                                        : 'e-disable'
                                                "
                        class="w-50"
                      >
                        <ejs-dropdownlist
                          #technology
                          [allowFiltering]="true"
                          [dataSource]="
                                                        technologySelect
                                                    "
                          [enabled]="editMode"
                          [fields]="ruianFieldObj"
                          [filterBarPlaceholder]="
                                                        'Vyhledat typ technologie...'
                                                    "
                          [ignoreAccent]="true"
                          [placeholder]="
                                                        'Vyberte typ technologie'
                                                    "
                          [popupHeight]="height"
                          [value]="
                                                        field.technology
                                                            ? field.technology
                                                                  .id
                                                            : null
                                                    "
                          id="technology"
                          sortOrder="Ascending"
                        ></ejs-dropdownlist>
                      </div>
                    </li>
                    <li
                      class="list-group-item d-flex justify-content-between"
                    >
                      <strong>Typ přenosu:</strong>
                      <div
                        [ngClass]="
                                                    editMode
                                                        ? 'e-success'
                                                        : 'e-disable'
                                                "
                        class="w-50"
                      >
                        <ejs-dropdownlist
                          #transfer
                          [allowFiltering]="true"
                          [dataSource]="
                                                        transferSelect
                                                    "
                          [enabled]="editMode"
                          [fields]="ruianFieldObj"
                          [filterBarPlaceholder]="
                                                        'Vyhledat typ přenosu...'
                                                    "
                          [ignoreAccent]="true"
                          [placeholder]="
                                                        'Vyberte typ přenosu'
                                                    "
                          [popupHeight]="height"
                          [value]="
                                                        field.transfer
                                                            ? field.transfer.id
                                                            : null
                                                    "
                          id="transfer"
                          sortOrder="Ascending"
                        ></ejs-dropdownlist>
                      </div>
                    </li>
                    <li
                      class="list-group-item d-flex justify-content-between"
                    >
                      <strong>Elektroměr:</strong>
                      <div
                        [ngClass]="
                                                    editMode
                                                        ? 'e-success'
                                                        : 'e-disable'
                                                "
                        class="e-input-group w-50"
                      >
                        <input
                          [disabled]="!editMode"
                          [value]="
                                                        field.electrical_counter
                                                    "
                          class="text-right e-input"
                          id="ctrl_electrical_counter"
                          placeholder="Elektroměr"
                          type="text"
                        />
                      </div>
                    </li>
                    <li
                      class="list-group-item d-flex justify-content-between"
                    >
                      <strong>Klíče:</strong>
                      <ng-container *ngIf="editMode">
                        <div
                          [ngClass]="
                                                        editMode
                                                            ? 'e-success'
                                                            : 'e-disable'
                                                    "
                          class="w-50"
                        >
                          <ejs-radiobutton
                            checked="{{
                                                            field.keys
                                                        }}"
                            label="ANO"
                            name="keys"
                            value="true"
                          ></ejs-radiobutton>
                          <br />
                          <ejs-radiobutton
                            checked="{{
                                                            !field.keys
                                                        }}"
                            label="NE"
                            name="keys"
                            value="false"
                          ></ejs-radiobutton>
                        </div>
                      </ng-container>
                      <ng-container *ngIf="!editMode">
                                                <span
                                                  *ngIf="field.keys"
                                                  class="e-badge e-badge-success"
                                                >ANO</span
                                                >
                        <span
                          *ngIf="!field.keys"
                          class="e-badge e-badge-danger"
                        >NE</span
                        >
                      </ng-container>
                    </li>
                    <li
                      class="list-group-item d-flex justify-content-between"
                    >
                      <strong>Popis:</strong>
                      <div
                        [ngClass]="
                                                    editMode
                                                        ? 'e-success'
                                                        : 'e-disable'
                                                "
                        class="e-input-group w-50"
                      >
                        <ejs-textbox
                          [enabled]="!editMode"
                          [multiline]="true"
                          [value]="field.descriptive"
                          floatLabelType="Auto"
                          id="ctrl_descriptive"
                          placeholder="Popis"
                        ></ejs-textbox>
                      </div>
                    </li>
                    <li
                      class="list-group-item d-flex justify-content-between"
                    >
                      <strong>Obchodní popis:</strong>
                      <div
                        [ngClass]="
                                                    editMode
                                                        ? 'e-success'
                                                        : 'e-disable'
                                                "
                        class="e-input-group w-50"
                      >
                        <ejs-textbox
                          [enabled]="!editMode"
                          [multiline]="true"
                          [value]="
                                                        field.business_note
                                                    "
                          floatLabelType="Auto"
                          id="ctrl_business_note"
                          placeholder="Obchodní popis"
                        ></ejs-textbox>
                      </div>
                    </li>
                  </ul>
                  <h6 class="p-2">
                    <i
                      aria-hidden="true"
                      class="fa-light fa-lg fa-clipboard-list"
                    ></i
                    >&nbsp;Služby:
                  </h6>
                  <div class="e-card-separator"></div>
                  <ul class="list-group mb-3">
                    <li
                      class="list-group-item d-flex justify-content-between"
                    >
                      <strong>Služby internet:</strong>
                      <div
                        [ngClass]="
                                                    editMode
                                                        ? 'e-success'
                                                        : 'e-disable'
                                                "
                        class="w-50"
                      >
                        <ejs-multiselect
                          #net_packages
                          (filtering)="
                                                        onFilteringNetPackages(
                                                            $event
                                                        )
                                                    "
                          [allowFiltering]="true"
                          [dataSource]="
                                                        servicesNetSelect
                                                    "
                          [fields]="ruianFieldObj"
                          [filterBarPlaceholder]="
                                                        'Vyhledat internetové služby...'
                                                    "
                          [ignoreAccent]="true"
                          [mode]="'CheckBox'"
                          [placeholder]="
                                                        'Vyberte internetové služby'
                                                    "
                          [popupHeight]="height"
                          [selectAllText]="
                                                        'Všechny internetové služby'
                                                    "
                          [value]="choosenNetPackages"
                          id="servicesNet"
                          showSelectAll="true"
                          sortOrder="Ascending"
                        ></ejs-multiselect>
                      </div>
                    </li>
                    <li
                      class="list-group-item d-flex justify-content-between"
                    >
                      <strong>Služby televize:</strong>
                      <div
                        [ngClass]="
                                                    editMode
                                                        ? 'e-success'
                                                        : 'e-disable'
                                                "
                        class="w-50"
                      >
                        <ejs-multiselect
                          #tv_services
                          (filtering)="
                                                        onFilteringTvPackages(
                                                            $event
                                                        )
                                                    "
                          [allowFiltering]="true"
                          [dataSource]="
                                                        servicesTvSelect
                                                    "
                          [fields]="ruianFieldObj"
                          [filterBarPlaceholder]="
                                                        'Vyhledat televizní služby...'
                                                    "
                          [ignoreAccent]="true"
                          [mode]="'CheckBox'"
                          [placeholder]="
                                                        'Vyberte televizní služby'
                                                    "
                          [popupHeight]="height"
                          [selectAllText]="
                                                        'Všechny TV služby'
                                                    "
                          [value]="choosenTvServices"
                          id="servicesTv"
                          showSelectAll="true"
                          sortOrder="Ascending"
                        ></ejs-multiselect>
                      </div>
                    </li>
                  </ul>
                  <h6 class="p-2">
                    <i
                      aria-hidden="true"
                      class="fa-light fa-lg fa-construction"
                    ></i
                    >&nbsp;Projekt:
                  </h6>
                  <div class="e-card-separator"></div>
                  <ul class="list-group mb-3">
                    <li
                      class="list-group-item d-flex justify-content-between"
                    >
                      <strong>Projekt:</strong>
                      <div
                        [ngClass]="
                                                    editMode
                                                        ? 'e-success'
                                                        : 'e-disable'
                                                "
                        class="w-50"
                      >
                        <ejs-dropdownlist
                          #project
                          [allowFiltering]="true"
                          [dataSource]="projectSelect"
                          [enabled]="editMode"
                          [fields]="ruianFieldObj"
                          [filterBarPlaceholder]="
                                                        'Vyhledat projekt...'
                                                    "
                          [ignoreAccent]="true"
                          [placeholder]="
                                                        'Vyberte projekt'
                                                    "
                          [popupHeight]="height"
                          [value]="
                                                        field.project
                                                            ? field.project.id
                                                            : null
                                                    "
                          id="project"
                          sortOrder="Ascending"
                        ></ejs-dropdownlist>
                      </div>
                    </li>
                    <li
                      class="list-group-item d-flex justify-content-between"
                    >
                      <strong>Datum realizace:</strong>
                      <div
                        [ngClass]="
                                                    editMode
                                                        ? 'e-success'
                                                        : 'e-disable'
                                                "
                        class="w-50"
                      >
                        <ejs-datetimepicker
                          [enabled]="true"
                          [firstDayOfWeek]="1"
                          floatLabelType="Always"
                          id="realized_at"
                        >
                        </ejs-datetimepicker>
                      </div>
                    </li>
                  </ul>
                  <h6 class="p-2">
                    <i
                      aria-hidden="true"
                      class="fa-light fa-lg fa-lightbulb-dollar"
                    ></i
                    >&nbsp;Marketing:
                  </h6>
                  <div class="e-card-separator"></div>
                  <ul class="list-group mb-3">
                    <li
                      class="list-group-item d-flex justify-content-between"
                    >
                      <strong>Typ smlouvy:</strong>
                      <div
                        [ngClass]="
                                                    editMode
                                                        ? 'e-success'
                                                        : 'e-disable'
                                                "
                        class="w-50"
                      >
                        <ejs-dropdownlist
                          #deal_type
                          [allowFiltering]="true"
                          [dataSource]="dealSelect"
                          [enabled]="editMode"
                          [fields]="ruianFieldObj"
                          [filterBarPlaceholder]="
                                                        'Vyhledat typ smluvního úvazku...'
                                                    "
                          [ignoreAccent]="true"
                          [placeholder]="
                                                        'Vyberte typ smluvního úvazku'
                                                    "
                          [popupHeight]="height"
                          [value]="
                                                        field.deal_type
                                                            ? field.deal_type.id
                                                            : null
                                                    "
                          id="deal_type"
                          sortOrder="Ascending"
                        ></ejs-dropdownlist>
                      </div>
                    </li>
                    <li
                      class="list-group-item d-flex justify-content-between"
                    >
                      <strong>Typ slevy:</strong>
                      <div
                        [ngClass]="
                                                    editMode
                                                        ? 'e-success'
                                                        : 'e-disable'
                                                "
                        class="w-50"
                      >
                        <ejs-dropdownlist
                          #discount
                          [allowFiltering]="true"
                          [dataSource]="
                                                        discountSelect
                                                    "
                          [enabled]="editMode"
                          [fields]="ruianFieldObj"
                          [filterBarPlaceholder]="
                                                        'Vyhledattyp typ slevy...'
                                                    "
                          [ignoreAccent]="true"
                          [placeholder]="
                                                        'Vyberte typ slevy'
                                                    "
                          [popupHeight]="height"
                          [value]="
                                                        field.discount
                                                            ? field.discount.id
                                                            : null
                                                    "
                          id="discount"
                          sortOrder="Ascending"
                        ></ejs-dropdownlist>
                      </div>
                    </li>
                    <li
                      class="list-group-item d-flex justify-content-between"
                    >
                      <strong>Klip rám:</strong>
                      <ng-container *ngIf="editMode">
                        <div class="e-success w-50">
                          <ejs-radiobutton
                            checked="{{
                                                            field.clip_frame
                                                        }}"
                            label="ANO"
                            name="clip_frame"
                            value="true"
                          ></ejs-radiobutton>
                          <br />
                          <ejs-radiobutton
                            checked="{{
                                                            !field.clip_frame
                                                        }}"
                            label="NE"
                            name="clip_frame"
                            value="false"
                          ></ejs-radiobutton>
                        </div>
                      </ng-container>
                      <ng-container *ngIf="!editMode">
                                                <span
                                                  *ngIf="field.clip_frame"
                                                  class="e-badge e-badge-success"
                                                >ANO</span
                                                >
                        <span
                          *ngIf="!field.clip_frame"
                          class="e-badge e-badge-danger"
                        >NE</span
                        >
                      </ng-container>
                    </li>
                    <li
                      class="list-group-item d-flex justify-content-between"
                    >
                      <strong>Marketingový popis:</strong>
                      <div
                        [ngClass]="
                                                    editMode
                                                        ? 'e-success'
                                                        : 'e-disable'
                                                "
                        class="e-input-group w-50"
                      >
                        <ejs-textbox
                          [enabled]="!editMode"
                          [multiline]="true"
                          [value]="
                                                        field.marketingnote
                                                    "
                          floatLabelType="Auto"
                          id="ctrl_marketingnote"
                          placeholder="Marketingový popis"
                        ></ejs-textbox>
                      </div>
                    </li>
                    <li
                      class="list-group-item d-flex justify-content-between"
                    >
                      <strong>Ostatní reklamy:</strong>
                      <div
                        [ngClass]="
                                                    editMode
                                                        ? 'e-success'
                                                        : 'e-disable'
                                                "
                        class="e-input-group w-50"
                      >
                        <ejs-textbox
                          [enabled]="!editMode"
                          [multiline]="true"
                          [value]="
                                                        field.marketing_ads
                                                    "
                          floatLabelType="Auto"
                          id="ctrl_marketing_ads"
                          placeholder="Ostatní reklamy"
                        ></ejs-textbox>
                      </div>
                    </li>
                  </ul>
                </div>
              </ng-container>
              <ng-container *ngIf="!field || loadingAddress">
                <div class="e-card-content text-center">
                  <app-loader></app-loader>
                </div>
              </ng-container>
            </div>
          </ng-template>
        </e-tabitem>
        <e-tabitem [header]="headerText[1]">
          <ng-template #content>
            <div class="e-card m-1">
              <ng-container *ngIf="field && !loadingAddress">
                <div class="e-card-content">
                  <div *ngIf="field.photos.length > 0">
                    <ul class="list-group list-group-flush">
                      <li
                        *ngFor="
                                                    let file of field.photos
                                                        | paginate
                                                            : {
                                                                  id: 'files_list',
                                                                  itemsPerPage:
                                                                      pageSize,
                                                                  currentPage:
                                                                      pageFiles
                                                              }
                                                "
                        class="list-group-item"
                      >
                        <div class="row">
                          <div
                            class="col-12 col-md-6 col-lg-7"
                          >
                            {{ file.name }}<br />
                            <small
                              class="text-muted"
                            >
                              typ:
                              {{
                                file.extension
                              }}&nbsp;&middot;&nbsp;velikost:
                              {{
                                file.size
                                  | filesize
                              }}
                            </small>
                            <ng-container
                              *ngIf="
                                                                file.extension ===
                                                                    'image/jpeg' ||
                                                                file.extension ===
                                                                    'image/png'
                                                            "
                            >
                              <br />
                              <img
                                alt="{{
                                                                    file.name
                                                                }}"
                                src="{{
                                                                    file.url_thumbnail
                                                                }}"
                                style="
                                                                    height: 50px;
                                                                    width: auto;
                                                                "
                              />
                            </ng-container>
                          </div>
                          <div
                            class="col-12 col-md-6 col-lg-5"
                          >
                            <div
                              aria-label="File toolbar"
                              class="e-btn-group float-right"
                              role="group"
                            >
                              <button
                                (click)="
                                                                    downloadFile(
                                                                        file
                                                                    )
                                                                "
                                cssClass="e-btn e-primary btn-p-zero e-small"
                                ejs-button
                                type="button"
                              >
                                <i
                                  aria-hidden="true"
                                  class="fa-light fa-download"
                                ></i>
                              </button>
                              <button
                                (click)="
                                                                    deleteFileId =
                                                                        file.id
                                                                "
                                cssClass="e-danger e-btn btn-p-zero"
                                ejs-button
                                type="button"
                              >
                                <i
                                  aria-hidden="true"
                                  class="fa-light fa-trash"
                                ></i>
                              </button>
                            </div>
                          </div>
                        </div>
                      </li>
                    </ul>
                    <nav
                      class="d-flex justify-content-center mt-5 mb-3"
                    >
                      <pagination-controls
                        (pageChange)="
                                                    pageFiles = $event
                                                "
                        autoHide="true"
                        directionLinks="true"
                        id="files_list"
                        maxSize="9"
                        nextLabel="Další"
                        previousLabel="Předchozí"
                        responsive="true"
                      ></pagination-controls>
                    </nav>
                    <div class="force-overflow">
                      <div class="image-thumb-group">
                        <img
                          (click)="open(i)"
                          *ngFor="
                                                        let img of imagesBasic;
                                                        let i = index
                                                    "
                          [src]="img.thumb || img.src"
                        />
                      </div>
                    </div>
                  </div>
                  <h6
                    *ngIf="field.photos.length === 0"
                    class="text-muted col-12 text-center"
                  >
                    žádné soubory nebyly zatím přiloženy
                  </h6>
                </div>
              </ng-container>
              <ng-container *ngIf="!field || loadingAddress">
                <div class="e-card-content text-center">
                  <app-loader></app-loader>
                </div>
              </ng-container>
            </div>
          </ng-template>
        </e-tabitem>
        <e-tabitem [header]="headerText[2]">
          <ng-template #content>
            <div class="e-card m-1">
              <ng-container *ngIf="field && !loadingAddress">
                <div class="e-card-content"></div>
              </ng-container>
              <ng-container *ngIf="!field || loadingAddress">
                <div class="e-card-content text-center">
                  <app-loader></app-loader>
                </div>
              </ng-container>
            </div>
          </ng-template>
        </e-tabitem>
      </e-tabitems>
    </ejs-tab>
  </div>
  <div class="col-12 col-md-4">
    <div class="e-card m-1" style="display: block !important">
      <ng-container *ngIf="field && !loadingAddress">
        <div class="e-card-header stylish-color-dark text-white">
          <div class="e-card-header-caption">
            <div
              class="d-flex justify-content-between e-card-header-title text-white"
            >
                            <span>
                                <strong>Parcela:</strong>
                            </span>
              <span>
                                <strong>#{{ field.id }}</strong>
                            </span>
            </div>
          </div>
        </div>
        <div class="e-card-content text-center pt-2 pb-2 pl-1 pr-1">
          <div class="text-center">
            <img
              alt="profilový obrázek"
              class="img-fluid rounded-circle avatar-md p-2"
              src="assets/img/{{ field.creator.personalphoto }}"
            />
            <h5 class="mb-2 font-bold">
              <strong
              >{{ field.creator.firstname }}
                {{ field.creator.secondname }}</strong
              >
            </h5>
            <h6 class="font-bold blue-text mb-2">zadavatel</h6>
          </div>
          <div class="e-card-separator"></div>
          <div class="d-flex justify-content-between p-2">
            <span>Datum vytvoření:</span>
            <strong>{{
                field.created_date | dateFormat: 'DD.MM.YYYY HH:mm'
              }}</strong>
          </div>
          <div class="e-card-separator"></div>
          <div class="d-flex justify-content-between p-2">
            <span>Poslední změna:</span>
            <strong>{{
                field.updated_date | dateFormat: 'DD.MM.YYYY HH:mm'
              }}</strong>
          </div>
        </div>
        <div class="e-card-actions w-100">
          <ejs-uploader
            #preloadupload
            (beforeUpload)="beforeFileUpload($event)"
            (created)="applyDeafultUploadSetting()"
            (removing)="onFileRemove($event)"
            (selected)="onFileSelected($event)"
            (success)="onSuccessUpload($event)"
            (uploading)="onFileUpload($event)"
            [asyncSettings]="path"
            [dropArea]="dropElement"
            [enabled]="!field.deleted_date"
            allowedExtensions=".jpg,.png,.jpeg"
            id="preloadfileupload"
            maxFileSize="5000000"
          >
            <e-files>
              <e-uploadedfiles
                *ngFor="let file of field.photos"
                name="{{ file.name | fileExtension: false }}"
                size="{{ file.size }}"
                type="{{ file.name | fileExtension: true }}"
              ></e-uploadedfiles>
            </e-files>
          </ejs-uploader>
        </div>
      </ng-container>
      <div *ngIf="!field || loadingAddress">
        <div class="e-card-content text-center">
          <app-loader></app-loader>
        </div>
      </div>
    </div>
  </div>
</div>
