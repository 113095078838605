<ejs-toolbar
  #moduleToolbar
  id="moduleToolbar"
  overflowMode="Scrollable"
  scrollStep="300"
>
  <e-items>
    <e-item
      [align]="'center'"
      tooltipText="vytvořit společnost"
      type="button"
    >
      <ng-template #template>
        <button
          [queryParams]="{ view: 'companies', form: 'addCompany' }"
          [routerLink]="['/settings/']"
          class="e-btn e-primary btn-p-zero e-small"
          ejs-button
          title="vytvořit společnost"
          type="button"
        >
          <i class="fa-light fa-lg fa-plus mr-2"></i>vytvořit
          společnost
        </button>
      </ng-template>
    </e-item>
    <e-item type="Separator"></e-item>
    <e-item
      [align]="'center'"
      tooltipText="vytvořit oddělení"
      type="button"
    >
      <ng-template #template>
        <button
          [queryParams]="{
                        view: 'departments',
                        form: 'addDepartment'
                    }"
          [routerLink]="['/settings/']"
          class="e-btn e-primary btn-p-zero e-small"
          ejs-button
          title="vytvořit oddělení"
          type="button"
        >
          <i class="fa-light fa-lg fa-plus mr-2"></i>vytvořit oddělení
        </button>
      </ng-template>
    </e-item>
    <e-item type="Separator"></e-item>
    <e-item [align]="'center'" tooltipText="vytvořit pozici" type="button">
      <ng-template #template>
        <button
          [queryParams]="{ view: 'roles', form: 'addRole' }"
          [routerLink]="['/settings/']"
          class="e-btn e-primary btn-p-zero e-small"
          ejs-button
          title="vytvořit pozici"
          type="button"
        >
          <i class="fa-light fa-lg fa-plus mr-2"></i>vytvořit pozici
        </button>
      </ng-template>
    </e-item>
    <e-item type="Separator"></e-item>
    <e-item
      [align]="'center'"
      tooltipText="vytvořit zaměstnavatele"
      type="button"
    >
      <ng-template #template>
        <button
          [queryParams]="{ view: 'employers', form: 'addEmployer' }"
          [routerLink]="['/settings/']"
          class="e-btn e-primary btn-p-zero e-small"
          ejs-button
          title="vytvořit zaměstnavatele"
          type="button"
        >
          <i class="fa-light fa-lg fa-plus mr-2"></i>vytvořit
          zaměstnavatele
        </button>
      </ng-template>
    </e-item>
    <e-item type="Separator"></e-item>
    <e-item
      [align]="'center'"
      tooltipText="vytvořit zaměstnavatele"
      type="button"
    >
      <ng-template #template>
        <button
          [queryParams]="{ view: 'buildings', form: 'addBuilding' }"
          [routerLink]="['/settings/']"
          class="e-btn e-primary btn-p-zero e-small"
          ejs-button
          title="vytvořit zaměstnavatele"
          type="button"
        >
          <i class="fa-light fa-lg fa-plus mr-2"></i>vytvořit budovu
        </button>
      </ng-template>
    </e-item>
    <e-item type="Separator"></e-item>
  </e-items>
</ejs-toolbar>
<div class="row d-flex justify-content-center modalWrapper"></div>
<ejs-tab
  #settingsTab
  (created)="onCreate()"
  (selected)="select($event)"
  (selecting)="preventSwipe($event)"
  enablePersistence="true"
  height="auto"
  heightAdjustMode="Content"
  id="settingsTab"
>
  <e-tabitems>
    <e-tabitem [header]="headerText[0]">
      <ng-template #content></ng-template>
    </e-tabitem>
    <e-tabitem [header]="headerText[1]">
      <ng-template #content>
        <app-employers-table></app-employers-table>
      </ng-template>
    </e-tabitem>
    <e-tabitem [header]="headerText[2]">
      <ng-template #content>
        <app-companies-table></app-companies-table>
      </ng-template>
    </e-tabitem>
    <e-tabitem [header]="headerText[3]">
      <ng-template #content>
        <app-departments-table></app-departments-table>
      </ng-template>
    </e-tabitem>
    <e-tabitem [header]="headerText[4]">
      <ng-template #content>
        <app-roles-table></app-roles-table>
      </ng-template>
    </e-tabitem>
    <e-tabitem [header]="headerText[5]">
      <ng-template #content>
        <app-apis-table></app-apis-table>
      </ng-template>
    </e-tabitem>
    <e-tabitem [header]="headerText[6]">
      <ng-template #content>
        <app-buildings-table></app-buildings-table>
      </ng-template>
    </e-tabitem>
    <e-tabitem [header]="headerText[7]">
      <ng-template #content>
        <app-office-list></app-office-list>
      </ng-template>
    </e-tabitem>
    <e-tabitem [header]="headerText[8]">
      <ng-template #content>
        <app-cars-list></app-cars-list>
      </ng-template>
    </e-tabitem>
  </e-tabitems>
</ejs-tab>
