import {Pipe, PipeTransform} from '@angular/core';
import prettyBytes from 'pretty-bytes';

@Pipe({
    name: 'filesize',
})
export class FileSizePipe implements PipeTransform {
    transform(value: number): string | undefined {
        return prettyBytes(value, {locale: 'cs'});
    }
}
