import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    HostListener,
    Input,
    OnChanges,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {DialogComponent} from '@syncfusion/ej2-angular-popups';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {
    FontFamilyModel,
    ImageSettingsModel,
    QuickToolbarSettingsModel,
    RichTextEditorComponent,
} from '@syncfusion/ej2-angular-richtexteditor';
import {ToolbarModule} from '@syncfusion/ej2-angular-navigations';
import {
    DropDownListComponent,
    FilteringEventArgs,
    MultiSelectComponent,
} from '@syncfusion/ej2-angular-dropdowns';
import {User} from '@src/app/_models/user/user';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {AuthenticationService} from '@src/app/_services/authentication.service';
import {PermissionService} from '@src/app/_services/permission.service';
import {DataService} from '@src/app/_services/data.service';
import {MessageService} from '@src/app/_services/message.service';
import {Observable} from 'rxjs';
import {Department} from '@src/app/_models/department/department';
import {KnowledgeCategory} from '@src/app/_models/knowledge/knowledge-category';
import {
    addClass,
    Browser,
    createElement,
    EmitType,
    isNullOrUndefined,
    removeClass,
} from '@syncfusion/ej2-base';
import {Query} from '@syncfusion/ej2-data';
import {Knowledge} from '@src/app/_models/knowledge/knowledge';
import moment from 'moment';
import localeCs from '@angular/common/locales/cs';
import {registerLocaleData} from '@angular/common';
import {ComponentCanDeactivate} from '@src/app/_guards/changes.guard';
import {
    FileInfo,
    RemovingEventArgs,
    SelectedEventArgs,
    UploaderComponent,
} from '@syncfusion/ej2-angular-inputs';
import {KnowledgeFile} from '@src/app/_models/knowledge/knowledge-file';
import {EnvironmentService} from '@src/app/_services/environment.service';
import {FilePath} from '@src/app/features/hotline/hotline.service';
import {KnowledgeInput, KnowledgeService} from '@src/app/features/knowledge/knowledge.service';
import {SettingsService} from '@src/app/features/settings/settings.service';

moment.updateLocale('cs', {workingWeekdays: [1, 2, 3, 4, 5]});
registerLocaleData(localeCs);

@UntilDestroy()
@Component({
    selector: 'app-knowledge-form',
    templateUrl: './knowledge-form.component.html',
    styleUrls: ['./knowledge-form.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KnowledgeFormComponent implements OnInit, OnChanges, ComponentCanDeactivate {
    // RTE
    maxLength = 15000;

    textArea: HTMLElement;

    fontFamily: FontFamilyModel = {
        items: [
            {
                text: 'Segoe UI',
                value: 'Segoe UI',
                command: 'Font',
                subCommand: 'FontName',
            },
            {
                text: 'Roboto',
                value: 'Roboto',
                command: 'Font',
                subCommand: 'FontName',
            }, // here font is added
            {
                text: 'Great vibes',
                value: 'Great Vibes,cursive',
                command: 'Font',
                subCommand: 'FontName',
            }, // here font is added
            {
                text: 'Noto Sans',
                value: 'Noto Sans',
                command: 'Font',
                subCommand: 'FontName',
            },
            {
                text: 'Impact',
                value: 'Impact,Charcoal,sans-serif',
                command: 'Font',
                subCommand: 'FontName',
            },
            {
                text: 'Tahoma',
                value: 'Tahoma,Geneva,sans-serif',
                command: 'Font',
                subCommand: 'FontName',
            },
        ],
    };

    tools: ToolbarModule = {
        type: 'Scrollable',
        items: [
            'Bold',
            'Italic',
            'Underline',
            'StrikeThrough',
            'FontName',
            'FontSize',
            'FontColor',
            'BackgroundColor',
            'LowerCase',
            'UpperCase',
            'SuperScript',
            'SubScript',
            '|',
            'Formats',
            'Alignments',
            'OrderedList',
            'UnorderedList',
            'Outdent',
            'Indent',
            '|',
            'CreateTable',
            'CreateLink',
            'Image',
            'FileManager',
            '|',
            'ClearFormat',
            'Print',
            'SourceCode',
            'FullScreen',
            '|',
            'Undo',
            'Redo',
        ],
    };

    insertImageSettings: ImageSettingsModel;

    quickToolbarSettings: QuickToolbarSettingsModel = {
        table: [
            'TableHeader',
            'TableRows',
            'TableColumns',
            'TableCell',
            '-',
            'BackgroundColor',
            'TableRemove',
            'TableCellVerticalAlign',
            'Styles',
        ],
    };

    pasteCleanupSettings: object = {
        prompt: true,
        plainText: false,
        keepFormat: false,
        deniedTags: ['a'],
        deniedAttrs: ['class', 'title', 'id'],
        allowedStyleProps: ['color', 'margin', 'font-size'],
    };

    // Upload
    path: FilePath;

    autoUpload = false;

    dropElement: HTMLElement = document.getElementsByClassName('control-fluid')[0] as HTMLElement;

    removeFile: KnowledgeFile | undefined;

    deleteFileID: number;

    // Dialogs
    buttonsDeleteFileDialog = [
        {
            click: this.removeKnowledgeFile.bind(this),
            buttonModel: {
                content: 'OK',
                cssClass: 'e-success e-outline',
                isFlat: false,
            },
        },
        {
            click: (): void => {
                this.deleteFileDialogObj.hide();
            },
            buttonModel: {content: 'ZRUŠIT', cssClass: 'e-danger e-outline'},
        },
    ];

    // Dropdowns
    height = '240px';

    fields: object = {text: 'label', value: 'value'};

    categorySelect: Array<{value: boolean | number | string; label: string}> = [];

    usersSelect: Array<{value: boolean | number | string; label: string}> = [];

    departmentSelect: Array<{value: boolean | number | string; label: string}> = [];

    // Forms
    knowledgeForm: FormGroup;

    isDirty = false;

    submited = false;

    isChecked = false;

    // Variables
    currentUser: User | null;

    // Loaders
    knowledgeLoading = false;

    @Input() knowledge: Knowledge | null = null;

    @Input() isCreate = false;

    @Input() isUpdate = false;

    @Input() isCopy = false;

    @Input() isVisible = false;

    @Output() readonly knowledgeFormDialogState = new EventEmitter<boolean>();

    // RTE
    @ViewChild('maintask') maintaskObj?: RichTextEditorComponent;

    // Upload

    @ViewChild('preloadupload') uploadObj: UploaderComponent;

    // Dialogs
    @ViewChild('formDialog') formDialogObj: DialogComponent;

    @ViewChild('deleteFileDialog') deleteFileDialogObj: DialogComponent;

    // Dropdowns
    @ViewChild('users') usersObj: MultiSelectComponent;

    @ViewChild('departments') departmentsObj: MultiSelectComponent;

    @ViewChild('category') categoryObj: DropDownListComponent;

    constructor(
        private readonly router: Router,
        private readonly route: ActivatedRoute,
        private readonly knowledgeService: KnowledgeService,
        private readonly authenticationService: AuthenticationService,
        private readonly settingsService: SettingsService,
        private readonly permissionService: PermissionService,
        private readonly ref: ChangeDetectorRef,
        private readonly formBuilder: FormBuilder,
        private readonly dataService: DataService,
        private readonly messages: MessageService,
        private readonly environmentService: EnvironmentService,
    ) {
        this.currentUser = this.authenticationService.currentUserValue;
        this.insertImageSettings = {
            path: this.environmentService.backendURL + '/api/file/preview/knowledge/',
            saveUrl:
                this.environmentService.backendURL +
                '/api/upload/attachments/knowledges?type=attachments&subfolder=knowledges',
        };

        this.path = {
            saveUrl: this.environmentService.backendURL + '/api/upload/knowledge?type=knowledge',
            removeUrl: '',
        };
    }

    get knowledgeFormCtrl(): {[key: string]: AbstractControl} {
        return this.knowledgeForm.controls;
    }

    @HostListener('window:beforeunload')
    canDeactivate(): Observable<boolean> | boolean {
        return !this.isDirty;
    }

    ngOnInit(): void {
        this.dataService.userSource.pipe(untilDestroyed(this))
            .subscribe(
                (data: User[]) => {
                    this.usersSelect = [];
                    data.map((user: User) => {
                        if (user.authorized && !user.deleted_date) {
                            this.usersSelect = [
                                ...this.usersSelect,
                                {value: user.id, label: user.firstname + ' ' + user.secondname},
                            ];
                        }
                    });
                },
                error => {
                    console.error(error);
                },
            );

        this.dataService.departmentSource.pipe(untilDestroyed(this))
            .subscribe(
                (data: Department[]) => {
                    this.departmentSelect = [];
                    data.map((department: Department) => {
                        if (!department.deleted_date) {
                            this.departmentSelect = [
                                ...this.departmentSelect,
                                {
                                    value: department.id,
                                    label: department.company.name + ' - ' + department.name,
                                },
                            ];
                        }
                    });
                },
                error => {
                    console.error(error);
                },
            );

        this.dataService.knowledgeCategoriesSource.pipe(untilDestroyed(this))
            .subscribe(
                (data: KnowledgeCategory[]) => {
                    this.categorySelect = [];
                    data.map((category: KnowledgeCategory) => {
                        if (!category.deleted_date) {
                            this.categorySelect = [
                                ...this.categorySelect,
                                {
                                    value: category.id,
                                    label: category.name,
                                },
                            ];
                        }
                    });
                    this.ref.markForCheck();
                },
                error => {
                    console.error(error);
                },
            );

        this.knowledgeForm = this.formBuilder.group({
            category_id: [this.knowledge?.category_id ? this.knowledge.category_id : null],
            subject: [
                this.knowledge?.subject ? this.knowledge.subject : '',
                Validators.compose([Validators.required, Validators.maxLength(100)]),
            ],
            maintask: [
                this.knowledge?.maintask ? this.knowledge.maintask : '',
                Validators.compose([Validators.required, Validators.maxLength(15000)]),
            ],
            users: [null],
            departments: [null],
            files: [null],
        });

        this.knowledgeForm.valueChanges.pipe(untilDestroyed(this))
            .subscribe(() => {
                this.isDirty = this.knowledgeForm.dirty;
                this.showInputErrors();
            });

        this.dataService.setDepartmentDataSource();
        this.dataService.setKnowledgeCategoriesSource();
    }

    ngOnChanges(): void {
        if (this.isVisible) {
            this.uploadObj.clearAll();

            const queryParams: Params = {form: null};

            void this.router.navigate([], {
                relativeTo: this.route,
                queryParams,
                queryParamsHandling: 'merge',
            });

            if (this.isUpdate && this.knowledge) {
                this.formDialogObj.header = `Pracovní postup #${this.knowledge.id}: ${this.knowledge.subject}`;
                this.fillTheForm();
                this.showInputErrors();
            }

            if (this.isCreate) {
                this.knowledgeForm.reset();
                this.formDialogObj.header = 'Nový pracovní postup';
            }

            if (this.isCopy) {
                this.knowledgeForm.reset();
                this.formDialogObj.header = 'Nový pracovní postup';
                this.fillTheForm();
                this.showInputErrors();
            }
        }
    }

    changeDialogState(value: boolean): void {
        this.knowledgeFormDialogState.emit(value);
    }

    onFilteringCategories: EmitType<FilteringEventArgs> = (e: FilteringEventArgs) => {
        if (e.text === '') {
            e.updateData(this.categorySelect);
        } else {
            let query: Query = new Query();

            query = e.text !== '' ? query.where('label', 'contains', e.text, true, true) : query;
            e.updateData(this.categorySelect, query);
        }
    };

    onFilteringUsers: EmitType<FilteringEventArgs> = (e: FilteringEventArgs) => {
        if (e.text === '') {
            e.updateData(this.usersSelect);
        } else {
            let query: Query = new Query();

            query = e.text !== '' ? query.where('label', 'contains', e.text, true, true) : query;
            e.updateData(this.usersSelect, query);
        }
    };

    onFilteringDepartments: EmitType<FilteringEventArgs> = (e: FilteringEventArgs) => {
        if (e.text === '') {
            e.updateData(this.departmentSelect);
        } else {
            let query = new Query();

            query = e.text !== '' ? query.where('label', 'contains', e.text, true) : query;
            e.updateData(this.departmentSelect, query);
        }
    };

    fillTheForm(): void {
        const users: number[] = [];
        const departments: number[] = [];

        this.knowledge?.users.map((user: User) => {
            users.push(user.id);
        });

        this.knowledge?.departments.map((department: Department) => {
            departments.push(department.id);
        });
        this.knowledgeForm.controls.category_id.patchValue(this.knowledge?.category_id);
        this.knowledgeForm.controls.subject.patchValue(this.knowledge?.subject);
        this.knowledgeForm.controls.maintask.patchValue(this.knowledge?.maintask);
        this.knowledgeForm.controls.users.patchValue(users);
        this.knowledgeForm.controls.departments.patchValue(departments);
    }

    onSubmit(): void {
        this.submited = true;
        this.isDirty = false;
        this.knowledgeLoading = true;

        if (this.isCreate || this.isCopy) {
            this.addKnowledge();
        }

        if (this.isUpdate) {
            this.editKnowledge();
        }
    }

    addKnowledge(): void {
        if (this.knowledgeForm.invalid) {
            console.error('form is not valid!');

            return;
        }

        this.knowledgeLoading = true;

        const knowledge = {
            category_id: this.knowledgeFormCtrl.category_id.value,
            maintask: this.knowledgeFormCtrl.maintask.value,
            subject: this.knowledgeFormCtrl.subject.value,
            users: this.knowledgeFormCtrl.users.value,
            departments: this.knowledgeFormCtrl.departments.value,
            created_by: this.currentUser?.id,
        };

        this.knowledgeService
            .addKnowledge(knowledge)
            .pipe(untilDestroyed(this))
            .subscribe({
                next: data => {
                    if (this.currentUser && this.uploadObj.getFilesData().length > 0) {
                        this.uploadObj.asyncSettings.saveUrl = `${this.environmentService.backendURL}/api/upload/knowledge?type=knowledge&id=${data.id}&created_by=${this.currentUser.id}`;

                        for (const file of this.uploadObj.getFilesData()) {
                            this.uploadObj.upload(file);
                        }
                    }

                    const options = {progressBar: true, timeOut: 5000};

                    this.messages.showSuccess('Pracovní postup', 'úspěšně vytvořen', options);
                    this.isCreate = false;
                    this.dataService.setKnowledgesDataSource();
                    this.knowledgeLoading = false;
                    this.formDialogObj.hide();
                    this.ref.markForCheck();
                },
                error: error => {
                    console.error(error);

                    const body = 'Zkuste to znovu později';
                    const options = {progressBar: true, timeOut: 5000};

                    this.messages.showError(
                        'Chyba při vytváření pracovního postupu',
                        body,
                        options,
                    );
                    this.knowledgeLoading = false;
                    this.ref.markForCheck();
                },
            });
    }

    editKnowledge(): void {
        if (this.knowledgeForm.invalid) {
            console.error('form is not valid!');

            return;
        }

        this.knowledgeLoading = true;

        if (
            this.currentUser &&
            (this.permissionService.checkUserISAdministrative(this.currentUser) ||
                this.permissionService.checkUserISVacationAdmin(this.currentUser))
        ) {
            const data: KnowledgeInput = {
                id: this.knowledge?.id,
                category_id: this.knowledgeFormCtrl.category_id.value,
                maintask: this.knowledgeFormCtrl.maintask.value,
                subject: this.knowledgeFormCtrl.subject.value,
                users: this.knowledgeFormCtrl.users.value,
                departments: this.knowledgeFormCtrl.departments.value,
                updated_by: this.currentUser.id,
            };

            this.knowledgeService
                .updateKnowledge(data)
                ?.pipe(untilDestroyed(this))
                .subscribe({
                    next: knowledge => {
                        if (this.currentUser && this.uploadObj.getFilesData().length > 0) {
                            this.uploadObj.asyncSettings.saveUrl = `this.environmentService.backendURL/api/upload/knowledge?type=knowledge&id=${knowledge.id}&created_by=${this.currentUser.id}`;

                            for (const file of this.uploadObj.getFilesData()) {
                                this.uploadObj.upload(file);
                            }
                        }

                        const options = {progressBar: true, timeOut: 5000};

                        this.messages.showSuccess('Pracovní postup', 'úspěšně upraven', options);
                        this.isUpdate = false;
                        this.dataService.setKnowledgesDataSource();
                        this.dataService.setKnowledgeDetailDataSource(knowledge.id);
                        this.knowledgeLoading = false;
                        this.formDialogObj.hide();
                        this.ref.markForCheck();
                    },
                    error: error => {
                        console.error(error);

                        const body = 'Zkuste to znovu později';
                        const options = {progressBar: true, timeOut: 5000};

                        this.messages.showError('Chyba při úpravě pravního postupu', body, options);
                        this.knowledgeLoading = false;
                        this.ref.markForCheck();
                    },
                });
        } else {
            const body = 'Nemáte oprávnění provést tuto akci...';
            const options = {progressBar: true, timeOut: 5000};

            this.messages.showError('Nedostatečné oprávnění', body, options);
            this.isUpdate = false;
            this.knowledgeLoading = false;
            this.formDialogObj.hide();
            this.ref.markForCheck();
        }
    }

    // RTE
    onCreateRte(): void {
        this.maintaskObj?.refreshUI();
        this.maintaskObj?.autoResize();
    }

    handleFullScreen(e: unknown): void {
        const sbCntEle: HTMLElement | null = document.querySelector('.sb-content.e-view');
        const sbHdrEle: HTMLElement | null = document.querySelector('.sb-header.e-view');
        const leftBar: HTMLElement | null = document.querySelector('#left-sidebar');

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        if (e.targetItem === 'Maximize') {
            if (Browser.isDevice && Browser.isIos && sbCntEle && sbHdrEle) {
                addClass([sbCntEle, sbHdrEle], ['hide-header']);
            }

            if (leftBar) {
                addClass([leftBar], ['e-close']);
                removeClass([leftBar], ['e-open']);
            }
        }
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        else if (e.targetItem === 'Minimize') {
            if (Browser.isDevice && Browser.isIos && sbCntEle && sbHdrEle) {
                removeClass([sbCntEle, sbHdrEle], ['hide-header']);
            }

            if (leftBar) {
                removeClass([leftBar], ['e-close']);

                if (!Browser.isDevice) {
                    addClass([leftBar], ['e-open']);
                }
            }
        }
    }

    mirrorConversion(e: unknown): void {
        if (this.maintaskObj) {
            const id = `${this.maintaskObj.getID()}mirror-view`;
            let mirrorView: HTMLElement | null = this.maintaskObj.element.querySelector('#' + id);
            const charCount: HTMLElement | null =
                this.maintaskObj.element.querySelector('.e-rte-character-count');

            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            if (e.targetItem === 'Preview' && mirrorView && charCount) {
                this.textArea.style.display = 'block';
                mirrorView.style.display = 'none';
                // eslint-disable-next-line max-len
                // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-member-access
                charCount.style.display = 'block';
            } else {
                if (!mirrorView && this.textArea.parentNode) {
                    mirrorView = createElement('div', {className: 'e-content'});
                    mirrorView.id = id;
                    this.textArea.parentNode.appendChild(mirrorView);
                } else if (mirrorView) {
                    mirrorView.innerHTML = '';
                }

                if (mirrorView) {
                    this.textArea.style.display = 'none';
                    mirrorView.style.display = 'block';
                }

                if (charCount) {
                    charCount.style.display = 'none';
                }
            }
        }
    }

    actionCompleteHandler(e: unknown): void {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        if (e.targetItem && (e.targetItem === 'SourceCode' || e.targetItem === 'Preview')) {
            (this.maintaskObj?.sourceCodeModule.getPanel() as HTMLTextAreaElement).style.display =
                'none';
            this.mirrorConversion(e);
        } else {
            setTimeout(() => {
                this.maintaskObj?.toolbarModule.refreshToolbarOverflow();
            }, 400);
        }
    }

    // Files
    onFileSelected(args: SelectedEventArgs): void {
        // Filter the 5 files only to showcase
        args.filesData.splice(5);

        const filesData: FileInfo[] = this.uploadObj.getFilesData();
        const allFiles: FileInfo[] = filesData.concat(args.filesData);

        if (allFiles.length > 5) {
            // eslint-disable-next-line @typescript-eslint/prefer-for-of
            for (let i = 0; i < allFiles.length; i++) {
                if (allFiles.length > 5) {
                    allFiles.shift();
                }
            }

            args.filesData = allFiles;
            // set the modified custom data
            args.modifiedFilesData = args.filesData;
        }

        let existingFiles: FileInfo[] = this.uploadObj.getFilesData();

        for (let i = 0; i < args.filesData.length; i++) {
            // eslint-disable-next-line @typescript-eslint/prefer-for-of
            for (let j = 0; j < existingFiles.length; j++) {
                if (!isNullOrUndefined(args.filesData[i])) {
                    // eslint-disable-next-line eqeqeq
                    if (existingFiles[j].name == args.filesData[i].name) {
                        args.filesData.splice(i, 1);
                    }
                }
            }
        }

        existingFiles = existingFiles.concat(args.filesData);
        args.modifiedFilesData = existingFiles;
        args.isModified = true;
    }

    onFileRemove(args: RemovingEventArgs): void {
        args.postRawFile = false;
    }

    removeKnowledgeFile(): void {
        this.knowledgeLoading = true;

        if (this.deleteFileID) {
            this.removeFile = this.knowledge?.files.find(x => x.id === this.deleteFileID);

            if (this.removeFile) {
                this.removeFile.deleted_by = this.currentUser?.id;
                this.knowledgeService
                    .deleteFile(this.removeFile)
                    .pipe(untilDestroyed(this))
                    .subscribe({
                        next: (data: KnowledgeFile) => {
                            const body = `Soubor #${data.id}`;
                            const options = {progressBar: true, timeOut: 5000};

                            this.messages.showSuccess('Soubor úspěšně smazán', body, options);
                            this.dataService.setKnowledgesDataSource();

                            if (this.knowledge) {
                                this.dataService.setKnowledgeDetailDataSource(data.knowledge_id);
                            }

                            this.knowledgeLoading = false;
                            this.deleteFileDialogObj.hide();
                            this.ref.markForCheck();
                        },
                        error: error => {
                            alert('Chyba během mazání souboru.');
                            this.knowledgeLoading = false;
                            this.ref.markForCheck();
                            console.error(error);
                        },
                    });
            }
        }
    }

    showInputErrors(): void {
        this.isChecked = true;
        /* console.log('VALID: ' + this.ticketForm.valid);
      Object.keys(this.ticketForm.controls).forEach(key => {
          const controlErrors: ValidationErrors = this.ticketForm.get(key).errors;
          if (controlErrors != null) {
              Object.keys(controlErrors).forEach(keyError => {
                  console.log('Key control: ' + key + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError]);
              });
          }
      });*/
        this.knowledgeForm.markAllAsTouched();
        this.ref.markForCheck();
    }
}
