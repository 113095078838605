import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnInit,
    ViewChild,
} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthenticationService} from '@src/app/_services/authentication.service';
import {DataService} from '@src/app/_services/data.service';
import {registerLocaleData} from '@angular/common';

import moment from 'moment';
import localeCs from '@angular/common/locales/cs';
import {User} from '@src/app/_models/user/user';
import {NavHandlerService} from '@src/app/_services/nav-handler.service';
import {MessageService} from '@src/app/_services/message.service';
import {PermissionService} from '@src/app/_services/permission.service';
import {ExcelService} from '@src/app/_services/excel.service';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {HotlineService} from '@src/app/features/hotline/hotline.service';
import {Observable} from 'rxjs';
import {Notifications} from '@src/app/_models/notification/notification';
import {NotificationService} from '@src/app/features/notifications/notification.service';
import {ButtonPropsModel, DialogComponent} from '@syncfusion/ej2-angular-popups';

moment.updateLocale('cs', {workingWeekdays: [1, 2, 3, 4, 5]});
registerLocaleData(localeCs);

@UntilDestroy()
@Component({
    selector: 'app-notification-list',
    templateUrl: './notification-list.component.html',
    styleUrls: ['./notification-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationListComponent implements OnInit {
    // Variables
    currentUser: User | null;

    // Loaders
    loadingNotification = true;

    limit = 20;

    live: true;

    notificationsOld$: Observable<Notifications[] | undefined>;

    notificationsNew$: Observable<Notifications[] | undefined>;

    buttonsReadAll: ButtonPropsModel[] = [
        {
            click: (): void => {
                this.allReaded();
            },
            isFlat: false,
            buttonModel: {
                content: 'OK',
                cssClass: 'e-success e-outline',
            },
        },
        {
            click: (): void => {
                this.confirmationRadAll.hide();
            },
            buttonModel: {
                content: 'ZRUŠIT',
                cssClass: 'e-danger e-outline',
            },
        },
    ];

    @ViewChild('confirmationRadAll') confirmationRadAll: DialogComponent;

    constructor(
        private readonly router: Router,
        private readonly ref: ChangeDetectorRef,
        private readonly dataService: DataService,
        private readonly messages: MessageService,
        private readonly notificationService: NotificationService,
        private readonly authenticationService: AuthenticationService,
    ) {
        this.currentUser = this.authenticationService.currentUserValue;
        this.dataService.setOldNotificationsDataSource(this.currentUser, 20, this.limit);
    }

    ngOnInit(): void {
        this.notificationsOld$ = this.notificationService.notificationsOld$;

        this.notificationsNew$ = this.notificationService.notificationsNew$;
    }

    onScroll(): void {
        this.limit = this.limit + 20;
        console.info('load more  old notifications');

        if (this.currentUser) {
            this.dataService.setOldNotificationsDataSource(this.currentUser, 20, this.limit);
        }
    }

    readNotif(id: number): void {
        if (this.authenticationService.currentUserValue || this.currentUser) {
            this.dataService.newNotificationSource
                .pipe(untilDestroyed(this))
                .subscribe((notifications: Notifications[]) => {
                    const notification = notifications.find(x => x.id === id);

                    if (notification) {
                        this.messages
                            .updateNotification(notification)
                            .pipe(untilDestroyed(this))
                            .subscribe({
                                next: () => {
                                    this.dataService.setNewNotificationsDataSource(
                                        this.authenticationService.currentUserValue ??
                                        this.currentUser,
                                    );

                                    this.dataService.setOldNotificationsDataSource(
                                        this.authenticationService.currentUserValue ??
                                        this.currentUser,
                                        20,
                                        this.limit,
                                    );
                                    void this.router.navigateByUrl(notification.url);
                                },
                                error: error => {
                                    console.error(error);

                                    const body = 'Zkuste to znovu později';
                                    const options = {
                                        progressBar: true,
                                        timeOut: 5000,
                                        toastClass: 'red',
                                    };

                                    this.messages.showError(
                                        'Chyba při aktualizaci notifikace',
                                        body,
                                        options,
                                    );
                                },
                            });
                    } else {
                        this.dataService.oldNotificationSource
                            .pipe(untilDestroyed(this))
                            .subscribe((notificationsOld: Notifications[]) => {
                                const notificationOld = notificationsOld.find(x => x.id === id);

                                if (notificationOld) {
                                    void this.router.navigateByUrl(notificationOld.url);
                                }
                            });
                    }
                });
        }

        this.dataService.clearNotificationOldCache();
        this.dataService.setOldNotificationsDataSource(this.currentUser, 20, this.limit);
    }

    allAsRead(): void {
        this.confirmationRadAll.content =
            'Opravdu chcete všechny nepřečtené notifikace označit jako přečtené?';
        this.confirmationRadAll.show();
    }

    allReaded(): void {
        if (!this.currentUser?.id) {
            return;
        }

        this.loadingNotification = true;

        this.notificationService.allAsRead(this.currentUser.id)
            .subscribe(
                result => {
                    if (result) {
                        this.dataService.clearNotificationOldCache();

                        this.confirmationRadAll.hide();
                        this.dataService.setNewNotificationsDataSource(this.currentUser);
                        this.dataService.setOldNotificationsDataSource(
                            this.currentUser,
                            20,
                            this.limit,
                        );
                        this.loadingNotification = false;

                        this.ref.markForCheck();
                    } else {
                        const body = 'Zkuste to znovu později';
                        const options = {progressBar: true, timeOut: 5000};

                        this.messages.showError('Chyba během úpravy szavů notifikací', body, options);
                        this.loadingNotification = false;
                        this.ref.markForCheck();
                    }
                },
                error => {
                    console.error(error);

                    const body = 'Zkuste to znovu později';
                    const options = {progressBar: true, timeOut: 5000};

                    this.messages.showError('Chyba během úpravy szavů notifikací', body, options);
                    this.loadingNotification = false;
                    this.ref.markForCheck();
                },
            );
    }

    trackNewNotifID(index: number, notifications: Notifications): number {
        return notifications.id;
    }

    trackOldNotifID(index: number, notifications: Notifications): number {
        return notifications.id;
    }
}
