import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    NgZone,
    OnChanges,
    OnDestroy,
    Output,
    QueryList,
    SimpleChanges,
    ViewChildren,
} from '@angular/core';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {take} from 'rxjs/operators';
import {Subscription, timer} from 'rxjs';

declare let mammoth;

export type ViewerType = 'google' | 'mammoth' | 'pdf' | 'url';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'ngx-doc-viewer',
    templateUrl: 'document-viewer.component.html',
    // eslint-disable-next-line @angular-eslint/component-max-inline-declarations
    styles: [
        `
            :host {
                display: block;
            }

            .container {
                width: 100%;
                height: 100%;
                position: relative;
            }

            .overlay-popout-google {
                width: 40px;
                height: 40px;
                right: 26px;
                top: 11.5px;
                position: absolute;
                z-index: 1000;
            }

            .overlay-popout-office {
                width: 100px;
                height: 20px;
                right: 0;
                bottom: 0;
                position: absolute;
                z-index: 1000;
            }

            .overlay-full {
                width: 100%;
                height: 100%;
                right: 0;
                top: 0;
                position: absolute;
                z-index: 1000;
            }

            iframe {
                width: 100%;
                height: 100%;
            }
        `,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NgxDocViewerComponent implements OnChanges, OnDestroy {
    fullUrl: SafeResourceUrl | null = null;
    externalViewer = false;
    docHtml = '';
    configuredViewer: ViewerType = 'google';
    @Output() readonly loaded = new EventEmitter();
    @Input() url = '';
    @Input() queryParams = '';
    @Input() viewerUrl: string | null = '';
    @Input() googleCheckInterval = 3000;
    @Input() disableContent: 'all' | 'none' | 'popout-hide' | 'popout' = 'none';
    @Input() googleCheckContentLoaded = true;
    @Input() viewer: ViewerType;
    @ViewChildren('iframe') iframes: QueryList<ElementRef>;
    private checkIFrameSubscription: Subscription | null = null;

    constructor(
        private readonly domSanitizer: DomSanitizer,
        private readonly ngZone: NgZone,
    ) {}

    // eslint-disable-next-line complexity
    async ngOnChanges(changes: SimpleChanges): Promise<void> {
        if (
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
            changes.viewer &&
            (changes.viewer.isFirstChange() ||
                changes.viewer.currentValue !== changes.viewer.previousValue)
        ) {
            if (
                this.viewer !== 'google' &&
                this.viewer !== 'mammoth' &&
                this.viewer !== 'pdf' &&
                // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                this.viewer !== 'url'
            ) {
                console.error(
                    `Unsupported viewer: '${
                        this.viewer as string
                    }'. Supported viewers: google, mammoth and pdf`,
                );
            }

            if (this.viewer === 'mammoth') {
                if (mammoth === null) {
                    console.error('please install mammoth when using local viewer');
                }
            }

            this.configuredViewer = this.viewer;
        }

        this.viewerUrl = null;

        if (this.configuredViewer === 'google') {
            this.viewerUrl = 'https://docs.google.com/gview?url=%URL%&embedded=true';
        }

        this.docHtml = '';
        this.externalViewer = this.configuredViewer === 'google' || this.configuredViewer === 'url';

        if (this.checkIFrameSubscription) {
            this.checkIFrameSubscription.unsubscribe();
        }

        if (!this.url) {
            this.fullUrl = null;
        } else if (
            this.configuredViewer === 'google' ||
            this.configuredViewer === 'pdf' ||
            this.configuredViewer === 'url'
        ) {
            const u = this.url.indexOf('/') ? encodeURIComponent(this.url) : this.url;
            let url = this.viewerUrl ? this.viewerUrl.replace('%URL%', u) : this.url;

            if (!!this.queryParams && this.configuredViewer !== 'pdf') {
                const start = this.queryParams.startsWith('&') ? '' : '&';

                url = `${url}${start}${this.queryParams}`;
            }

            this.fullUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(url);

            // see:
            // https://stackoverflow.com/questions/40414039/google-docs-viewer-returning-204-responses-no-longer-working-alternatives
            // hack to reload iframe if it's not loaded.
            // would maybe be better to use view.officeapps.live.com but seems not to work with sas token.
            if (this.configuredViewer === 'google' && this.googleCheckContentLoaded) {
                this.ngZone.runOutsideAngular(() => {
                    // if it's not loaded after the googleIntervalCheck, then open load again.
                    this.checkIFrameSubscription = timer(100, this.googleCheckInterval)
                        .pipe(
                            take(
                                Math.round(
                                    this.googleCheckInterval === 0
                                        ? 0
                                        : 20000 / this.googleCheckInterval,
                                ),
                            ),
                        )
                        .subscribe(() => {
                            const iframe = this.iframes.first.nativeElement as HTMLIFrameElement;

                            this.reloadIFrame(iframe);
                        });
                });
            }
        }
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        else if (this.configuredViewer === 'mammoth') {
            if (!mammoth) {
                console.error(
                    'Please install mammoth and make sure mammoth.browser.min.js is loaded.',
                );
            }

            this.docHtml = await this.getDocxToHtml(this.url);
        }
    }

    iframeLoaded(): void {
        this.loaded.emit(null);

        if (this.checkIFrameSubscription) {
            this.checkIFrameSubscription.unsubscribe();
        }
    }

    reloadIFrame(iframe: HTMLIFrameElement): void {
        // eslint-disable-next-line no-self-assign
        iframe.src = iframe.src;
    }

    ngOnDestroy(): void {
        if (this.checkIFrameSubscription) {
            this.checkIFrameSubscription.unsubscribe();
        }
    }

    private async getDocxToHtml(url: string): Promise<string> {
        const arrayBuffer = await this.fileToArray(url);
        const resultObject = await mammoth.convertToHtml({arrayBuffer});

        return resultObject.value as string;
    }

    private async fileToArray(url: string): Promise<ArrayBuffer> {
        return new Promise<ArrayBuffer>((resolve, reject) => {
            try {
                const request = new XMLHttpRequest();

                request.open('GET', url, true);
                request.responseType = 'blob';

                request.onload = (): void => {
                    const reader = new FileReader();

                    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                    reader.readAsArrayBuffer(request.response);

                    reader.onloadend = (): void => {
                        resolve(reader.result as ArrayBuffer);
                    };
                };

                request.send();
            } catch {
                reject(`error while retrieving file ${url}.`);
            }
        });
    }
}
