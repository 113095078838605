<ejs-dialog
  #formDialog
  (close)="
        isUpdate = false;
        isCreate = false;
        isDirty = false;
        isChecked = false;
        isCopy = false;
        submited = false;
        changeDialogState(false)
    "
  [isModal]="true"
  [showCloseIcon]="true"
  [visible]="isVisible"
  [width]="'85%'"
  id="formDialog"
>
  <form [formGroup]="addressExcludedForm">
    <ng-template #content>
      <div class="dialogContent">
        <h6 class="text-muted pt-2">
          <i aria-hidden="true" class="fa-light fa-lg fa-home"></i
          >&nbsp;Adresa:
        </h6>
        <hr class="pt-0 mt-0" />
        <div class="row">
          <div class="col-9 mb-3 form-group">
            <label
              [ngClass]="
                                f.address.valid &&
                                (f.address.dirty || f.address.touched)
                                    ? 'text-success'
                                    : f.address.invalid &&
                                      (f.address.dirty || f.address.touched)
                                    ? 'text-danger'
                                    : 'text-muted'
                            "
              for="address"
            >* RUIAN Adresa:</label
            >
            <ejs-textbox
              [ngClass]="
                                f.address.touched && f.address.invalid
                                    ? 'e-error'
                                    : f.address.touched && f.address.valid
                                    ? 'e-success'
                                    : ''
                            "
              autocomplete="smartform-auto-address"
              class="smartform-whole-address smartform-instance-exclude-address"
              floatLabelType="Auto"
              formControlName="address"
              id="address"
            >
            </ejs-textbox>
            <small class="text-muted mr-3"
            >Vyberte jakoukoli RUIAN adresu z našeptávače v
              oblasti, kterou chcete přidat.</small
            >
            <small
              *ngIf="
                                f.address.touched &&
                                f.address.errors &&
                                f.address.errors.required
                            "
              class="text-danger"
            >Vyberte adresu pomocí našeptávače</small
            ><br />
            <button
              class="e-btn e-info e-outline btn-p-zero e-small m-0"
              ejs-button
              onclick="loadMapyCZ('param')"
              title="pomoc najít adresu pro našeptávač"
              type="button"
            >
              <i
                aria-hidden="true"
                class="fa-light fa-lg fa-map-marked-alt mr-2"
              ></i
              >Najít adresu pomocí mapy
            </button>
          </div>
          <div class="col-3 mb-3 form-group">
            <label
              [ngClass]="
                                f.ruian.valid &&
                                (f.ruian.dirty || f.ruian.touched)
                                    ? 'text-success'
                                    : f.ruian.invalid &&
                                      (f.ruian.dirty || f.ruian.touched)
                                    ? 'text-danger'
                                    : 'text-muted'
                            "
              for="ruian"
            >* RUIAN:</label
            >
            <ejs-numerictextbox
              [format]="'###'"
              [ngClass]="
                                f.ruian.touched && f.ruian.invalid
                                    ? 'e-error'
                                    : f.ruian.touched && f.ruian.valid
                                    ? 'e-success'
                                    : ''
                            "
              formControlName="ruian"
              id="ruian"
              min="0"
              step="1"
            ></ejs-numerictextbox>
            <small class="text-warning"
            >Tento údaj je doplňován automaticky</small
            >
            <small
              *ngIf="
                                f.ruian.touched &&
                                f.ruian.errors &&
                                f.ruian.errors.required
                            "
              class="text-danger"
            >kód RUIAN je povinný</small
            >
            <div
              *ngIf="!exAddress && exAddress === null"
              class="alert alert-warning p-0"
              role="alert"
            >
              Vyberte adresu z našeptávače
            </div>
            <div
              *ngIf="exAddress"
              class="alert alert-danger p-1"
              role="alert"
            >
              RUIAN již existuje
              <span
                *ngIf="exAddress && exAddress.deleted_date"
                class="e-badge e-badge-danger float-right"
              >smazaná adresa</span
              >
              <span
                *ngIf="exAddress && !exAddress.deleted_date"
                class="e-badge e-badge-success float-right"
              >aktivní adresa</span
              >
            </div>
            <div
              *ngIf="!exAddress && exAddress === false"
              class="alert alert-success p-0"
              role="alert"
            >
              RUIAN je zkontrolován
            </div>
          </div>
          <div
            class="col-12 mb-3 form-group"
            id="mapyParent"
            style="display: none"
          >
            <div class="w-100">
              <input
                class="w-100"
                id="mapaSuggest"
                placeholder="Sem napiště oblast kde budete hledat adresu..."
              />
              <small class="text-muted"
              >Kliknutím na budovu s číslem popisným,
                zkopírujete celou adresu do vaší schránky, potom
                můžete vložit do pole pro vyhledání RUIAN
                adresy.</small
              >
            </div>
            <div id="mapa"></div>
          </div>
        </div>
        <div class="row">
          <div class="col-6 col-md-3 mb-3 form-group">
            <label
              [ngClass]="
                                f.oriental_number.valid &&
                                (f.oriental_number.dirty ||
                                    f.oriental_number.touched)
                                    ? 'text-success'
                                    : f.oriental_number.invalid &&
                                      (f.oriental_number.dirty ||
                                          f.oriental_number.touched)
                                    ? 'text-danger'
                                    : 'text-muted'
                            "
              for="oriental_number"
            >Číslo orientační:</label
            >
            <ejs-numerictextbox
              [format]="'###'"
              [ngClass]="
                                f.oriental_number.touched &&
                                f.oriental_number.invalid
                                    ? 'e-error'
                                    : f.oriental_number.touched &&
                                      f.oriental_number.valid
                                    ? 'e-success'
                                    : ''
                            "
              formControlName="oriental_number"
              id="oriental_number"
              min="0"
              step="1"
            ></ejs-numerictextbox>
            <small class="text-warning"
            >Tento údaj je doplňován automaticky</small
            >
            <small
              *ngIf="
                                f.oriental_number.touched &&
                                f.oriental_number.errors &&
                                f.oriental_number.errors.required
                            "
              class="text-danger"
            >číslo orientační je povinné</small
            >
          </div>
          <div class="col-6 col-md-3 mb-3 form-group">
            <label
              [ngClass]="
                                f.house_number.valid &&
                                (f.house_number.dirty || f.house_number.touched)
                                    ? 'text-success'
                                    : f.house_number.invalid &&
                                      (f.house_number.dirty ||
                                          f.house_number.touched)
                                    ? 'text-danger'
                                    : 'text-muted'
                            "
              for="house_number"
            >* Číslo popisné:</label
            >
            <ejs-numerictextbox
              [format]="'###'"
              [ngClass]="
                                f.house_number.touched && f.house_number.invalid
                                    ? 'e-error'
                                    : f.house_number.touched &&
                                      f.house_number.valid
                                    ? 'e-success'
                                    : ''
                            "
              formControlName="house_number"
              id="house_number"
              min="0"
              step="1"
            ></ejs-numerictextbox>
            <small class="text-warning"
            >Tento údaj je doplňován automaticky</small
            >
            <small
              *ngIf="
                                f.house_number.touched &&
                                f.house_number.errors &&
                                f.house_number.errors.required
                            "
              class="text-danger"
            >číslo popisné je povinné</small
            >
          </div>
          <div class="col-9 col-lg-3 mb-3 form-group">
            <label
              [ngClass]="
                                f.street.valid &&
                                (f.street.dirty || f.street.touched)
                                    ? 'text-success'
                                    : f.street.invalid &&
                                      (f.street.dirty || f.street.touched)
                                    ? 'text-danger'
                                    : 'text-muted'
                            "
              for="street"
            >Název ulice:</label
            >
            <ejs-textbox
              [ngClass]="
                                f.street.touched && f.street.invalid
                                    ? 'e-error'
                                    : f.street.touched && f.street.valid
                                    ? 'e-success'
                                    : ''
                            "
              floatLabelType="Auto"
              formControlName="street"
              id="street"
            ></ejs-textbox>
            <small class="text-warning"
            >Tento údaj je doplňován automaticky</small
            >
            <small
              *ngIf="
                                f.street.touched &&
                                f.street.errors &&
                                f.street.errors.required
                            "
              class="text-danger"
            >název ulice je povinný</small
            >
          </div>
          <div class="col-3 col-lg-2 mb-3 form-group">
            <label
              [ngClass]="
                                f.street_code.valid &&
                                (f.street_code.dirty || f.street_code.touched)
                                    ? 'text-success'
                                    : f.street_code.invalid &&
                                      (f.street_code.dirty ||
                                          f.street_code.touched)
                                    ? 'text-danger'
                                    : 'text-muted'
                            "
              for="street_code"
            >Kód ulice:</label
            >
            <ejs-numerictextbox
              [format]="'###'"
              [ngClass]="
                                f.street_code.touched && f.street_code.invalid
                                    ? 'e-error'
                                    : f.street_code.touched &&
                                      f.street_code.valid
                                    ? 'e-success'
                                    : ''
                            "
              formControlName="street_code"
              id="street_code"
              min="0"
              step="1"
            ></ejs-numerictextbox>
            <small class="text-warning"
            >Tento údaj je doplňován automaticky</small
            >
            <small
              *ngIf="
                                f.street_code.touched &&
                                f.street_code.errors &&
                                f.street_code.errors.required
                            "
              class="text-danger"
            >kód ulice je povinný</small
            >
          </div>
          <div class="col-6 col-lg-3 mb-3 form-group">
            <label
              [ngClass]="
                                f.city.valid && (f.city.dirty || f.city.touched)
                                    ? 'text-success'
                                    : f.city.invalid &&
                                      (f.city.dirty || f.city.touched)
                                    ? 'text-danger'
                                    : 'text-muted'
                            "
              for="city"
            >* Město:</label
            >
            <ejs-textbox
              [ngClass]="
                                f.city.touched && f.city.invalid
                                    ? 'e-error'
                                    : f.city.touched && f.city.valid
                                    ? 'e-success'
                                    : ''
                            "
              autocomplete="smartform-auto-city"
              floatLabelType="Auto"
              formControlName="city"
              id="city"
            ></ejs-textbox>
            <small class="text-warning"
            >Tento údaj je doplňován automaticky</small
            >
            <small
              *ngIf="
                                f.city.touched &&
                                f.city.errors &&
                                f.city.errors.required
                            "
              class="text-danger"
            >město je povinné</small
            >
          </div>
          <div class="col-3 col-lg-2 mb-3 form-group">
            <label
              [ngClass]="
                                f.zip.valid && (f.zip.dirty || f.zip.touched)
                                    ? 'text-success'
                                    : f.zip.invalid &&
                                      (f.zip.dirty || f.zip.touched)
                                    ? 'text-danger'
                                    : 'text-muted'
                            "
              for="zip"
            >* PSČ:</label
            >
            <ejs-numerictextbox
              [format]="'###'"
              [ngClass]="
                                f.zip.touched && f.zip.invalid
                                    ? 'e-error'
                                    : f.zip.touched && f.zip.valid
                                    ? 'e-success'
                                    : ''
                            "
              class="text-right"
              formControlName="zip"
              id="zip"
              min="0"
              step="1"
            ></ejs-numerictextbox>
            <small class="text-warning"
            >Tento údaj je doplňován automaticky</small
            >
            <small
              *ngIf="
                                f.zip.touched &&
                                f.zip.errors &&
                                f.zip.errors.required
                            "
              class="text-danger"
            >PSČ je povinný</small
            >
          </div>
          <div class="col-3 col-lg-2 mb-3 form-group">
            <label
              [ngClass]="
                                f.city_code.valid &&
                                (f.city_code.dirty || f.city_code.touched)
                                    ? 'text-success'
                                    : f.city_code.invalid &&
                                      (f.city_code.dirty || f.city_code.touched)
                                    ? 'text-danger'
                                    : 'text-muted'
                            "
              for="city_code"
            >Kód města:</label
            >
            <ejs-numerictextbox
              [format]="'###'"
              [ngClass]="
                                f.city_code.touched && f.city_code.invalid
                                    ? 'e-error'
                                    : f.city_code.touched && f.city_code.valid
                                    ? 'e-success'
                                    : ''
                            "
              formControlName="city_code"
              id="city_code"
              min="0"
              step="1"
            ></ejs-numerictextbox>
            <small class="text-warning"
            >Tento údaj je doplňován automaticky</small
            >
            <small
              *ngIf="
                                f.city_code.touched &&
                                f.city_code.errors &&
                                f.city_code.errors.required
                            "
              class="text-danger"
            >kód města je povinný</small
            >
          </div>
          <div class="col-9 col-lg-4 mb-3 form-group">
            <label
              [ngClass]="
                                f.city_part.valid &&
                                (f.city_part.dirty || f.city_part.touched)
                                    ? 'text-success'
                                    : f.city_part.invalid &&
                                      (f.city_part.dirty || f.city_part.touched)
                                    ? 'text-danger'
                                    : 'text-muted'
                            "
              for="city_part"
            >Městská část:</label
            >
            <ejs-textbox
              [ngClass]="
                                f.city_part.touched && f.city_part.invalid
                                    ? 'e-error'
                                    : f.city_part.touched && f.city_part.valid
                                    ? 'e-success'
                                    : ''
                            "
              floatLabelType="Auto"
              formControlName="city_part"
              id="city_part"
            ></ejs-textbox>
            <small class="text-warning"
            >Tento údaj je doplňován automaticky</small
            >
            <small
              *ngIf="
                                f.city_part.touched &&
                                f.city_part.errors &&
                                f.city_part.errors.required
                            "
              class="text-danger"
            >městská část je povinná</small
            >
          </div>
          <div class="col-3 col-lg-2 mb-3 form-group">
            <label
              [ngClass]="
                                f.city_part_code.valid &&
                                (f.city_part_code.dirty ||
                                    f.city_part_code.touched)
                                    ? 'text-success'
                                    : f.city_part_code.invalid &&
                                      (f.city_part_code.dirty ||
                                          f.city_part_code.touched)
                                    ? 'text-danger'
                                    : 'text-muted'
                            "
              for="city_part_code"
            >Kód městské části:</label
            >
            <ejs-numerictextbox
              [format]="'###'"
              [ngClass]="
                                f.city_part_code.touched &&
                                f.city_part_code.invalid
                                    ? 'e-error'
                                    : f.city_part_code.touched &&
                                      f.city_part_code.valid
                                    ? 'e-success'
                                    : ''
                            "
              class="text-right"
              formControlName="city_part_code"
              id="city_part_code"
              min="0"
              step="1"
            ></ejs-numerictextbox>
            <small class="text-warning"
            >Tento údaj je doplňován automaticky</small
            >
            <small
              *ngIf="
                                f.city_part_code.touched &&
                                f.city_part_code.errors &&
                                f.city_part_code.errors.required
                            "
              class="text-danger"
            >kód městské části je povinný</small
            >
          </div>
          <div class="col-9 col-lg-4 mb-3 form-group">
            <label
              [ngClass]="
                                f.district.valid &&
                                (f.district.dirty || f.district.touched)
                                    ? 'text-success'
                                    : f.district.invalid &&
                                      (f.district.dirty || f.district.touched)
                                    ? 'text-danger'
                                    : 'text-muted'
                            "
              for="district"
            >* Okres:</label
            >
            <ejs-textbox
              [ngClass]="
                                f.district.touched && f.district.invalid
                                    ? 'e-error'
                                    : f.district.touched && f.district.valid
                                    ? 'e-success'
                                    : ''
                            "
              floatLabelType="Auto"
              formControlName="district"
              id="district"
            ></ejs-textbox>
            <small class="text-warning"
            >Tento údaj je doplňován automaticky</small
            >
            <small
              *ngIf="
                                f.district.touched &&
                                f.district.errors &&
                                f.district.errors.required
                            "
              class="text-danger"
            >okres je povinný</small
            >
          </div>
          <div class="col-3 col-lg-2 mb-3 form-group">
            <label
              [ngClass]="
                                f.district_code.valid &&
                                (f.district_code.dirty ||
                                    f.district_code.touched)
                                    ? 'text-success'
                                    : f.district_code.invalid &&
                                      (f.district_code.dirty ||
                                          f.district_code.touched)
                                    ? 'text-danger'
                                    : 'text-muted'
                            "
              for="district_code"
            >Kód okresu:</label
            >
            <ejs-numerictextbox
              [format]="'###'"
              [ngClass]="
                                f.district_code.touched &&
                                f.district_code.invalid
                                    ? 'e-error'
                                    : f.district_code.touched &&
                                      f.district_code.valid
                                    ? 'e-success'
                                    : ''
                            "
              formControlName="district_code"
              id="district_code"
              min="0"
              step="1"
            ></ejs-numerictextbox>
            <small class="text-warning"
            >Tento údaj je doplňován automaticky</small
            >
            <small
              *ngIf="
                                f.district_code.touched &&
                                f.district_code.errors &&
                                f.district_code.errors.required
                            "
              class="text-danger"
            >kód okresu je povinný</small
            >
          </div>
          <div class="col-9 col-lg-4 mb-3 form-group">
            <label
              [ngClass]="
                                f.region.valid &&
                                (f.region.dirty || f.region.touched)
                                    ? 'text-success'
                                    : f.region.invalid &&
                                      (f.region.dirty || f.region.touched)
                                    ? 'text-danger'
                                    : 'text-muted'
                            "
              for="region"
            >* Kraj:</label
            >
            <ejs-textbox
              [ngClass]="
                                f.region.touched && f.region.invalid
                                    ? 'e-error'
                                    : f.region.touched && f.region.valid
                                    ? 'e-success'
                                    : ''
                            "
              floatLabelType="Auto"
              formControlName="region"
              id="region"
            ></ejs-textbox>
            <small class="text-warning"
            >Tento údaj je doplňován automaticky</small
            >
            <small
              *ngIf="
                                f.region.touched &&
                                f.region.errors &&
                                f.region.errors.required
                            "
              class="text-danger"
            >kraj je povinný</small
            >
          </div>
          <div class="col-3 col-lg-2 mb-3 form-group">
            <label
              [ngClass]="
                                f.region_code.valid &&
                                (f.region_code.dirty || f.region_code.touched)
                                    ? 'text-success'
                                    : f.region_code.invalid &&
                                      (f.region_code.dirty ||
                                          f.region_code.touched)
                                    ? 'text-danger'
                                    : 'text-muted'
                            "
              for="region_code"
            >Kód kraje:</label
            >
            <ejs-numerictextbox
              [format]="'###'"
              [ngClass]="
                                f.region_code.touched && f.region_code.invalid
                                    ? 'e-error'
                                    : f.region_code.touched &&
                                      f.region_code.valid
                                    ? 'e-success'
                                    : ''
                            "
              formControlName="region_code"
              id="region_code"
              min="0"
              step="1"
            ></ejs-numerictextbox>
            <small class="text-warning"
            >Tento údaj je doplňován automaticky</small
            >
            <small
              *ngIf="
                                f.region_code.touched &&
                                f.region_code.errors &&
                                f.region_code.errors.required
                            "
              class="text-danger"
            >kód kraje je povinný</small
            >
          </div>
          <div class="col-12 col-lg-6 mb-3 form-group">
            <label
              [ngClass]="
                                f.state.valid &&
                                (f.state.dirty || f.state.touched)
                                    ? 'text-success'
                                    : f.state.invalid &&
                                      (f.state.dirty || f.state.touched)
                                    ? 'text-danger'
                                    : 'text-muted'
                            "
              for="state"
            >* Stát:</label
            >
            <ejs-textbox
              [ngClass]="
                                f.state.touched && f.state.invalid
                                    ? 'e-error'
                                    : f.state.touched && f.state.valid
                                    ? 'e-success'
                                    : ''
                            "
              floatLabelType="Auto"
              formControlName="state"
              id="state"
            ></ejs-textbox>
            <small class="text-warning"
            >Tento údaj je doplňován automaticky</small
            >
            <small
              *ngIf="
                                f.state.touched &&
                                f.state.errors &&
                                f.state.errors.required
                            "
              class="text-danger"
            >stát je povinný</small
            >
          </div>
          <div class="col-12 col-md-3 mb-3 form-group">
            <label
              [ngClass]="
                                f.gps.valid && (f.gps.dirty || f.gps.touched)
                                    ? 'text-success'
                                    : f.gps.invalid &&
                                      (f.gps.dirty || f.gps.touched)
                                    ? 'text-danger'
                                    : 'text-muted'
                            "
              for="gps"
            >GPS souřadnice:</label
            >
            <ejs-textbox
              [ngClass]="
                                f.gps.touched && f.gps.invalid
                                    ? 'e-error'
                                    : f.gps.touched && f.gps.valid
                                    ? 'e-success'
                                    : ''
                            "
              floatLabelType="Auto"
              formControlName="gps"
              id="gps"
            ></ejs-textbox>
            <small class="text-warning"
            >Tento údaj je doplňován automaticky</small
            >
            <small
              *ngIf="
                                f.gps.touched &&
                                f.gps.errors &&
                                f.gps.errors.required
                            "
              class="text-danger"
            >GPS souřadnice jsou povinné</small
            >
          </div>
          <div class="col-12 col-md-3 mb-3 form-group">
            <label
              [ngClass]="
                                f.jstk.valid && (f.jstk.dirty || f.jstk.touched)
                                    ? 'text-success'
                                    : f.jstk.invalid &&
                                      (f.jstk.dirty || f.jstk.touched)
                                    ? 'text-danger'
                                    : 'text-muted'
                            "
              for="jstk"
            >JSTK souřadnice:</label
            >
            <ejs-textbox
              [ngClass]="
                                f.jstk.touched && f.jstk.invalid
                                    ? 'e-error'
                                    : f.jstk.touched && f.jstk.valid
                                    ? 'e-success'
                                    : ''
                            "
              floatLabelType="Auto"
              formControlName="jstk"
              id="jstk"
            ></ejs-textbox>
            <small class="text-warning"
            >Tento údaj je doplňován automaticky</small
            >
            <small
              *ngIf="
                                f.jstk.touched &&
                                f.jstk.errors &&
                                f.jstk.errors.required
                            "
              class="text-danger"
            >JSTK souřadnice jsou povinné</small
            >
          </div>
          <div class="col-2 mb-3 d-none d-lg-block">
            <label
              [ngClass]="
                                f.type.valid && (f.type.dirty || f.type.touched)
                                    ? 'text-success'
                                    : f.type.invalid &&
                                      (f.type.dirty || f.type.touched)
                                    ? 'text-danger'
                                    : 'text-muted'
                            "
            >
              Typ vyloučené adresy:
            </label>
          </div>
          <div
            class="col-12 col-lg-10 mb-3 form-group text-center text-lg-right"
          >
            <ejs-radiobutton
              [checked]="
                                smartFormData && smartFormData.ruian
                                    ? true
                                    : !(
                                          smartFormData &&
                                          (smartFormData.street_code ||
                                              smartFormData.city_part_code ||
                                              smartFormData.city_code ||
                                              smartFormData.district_code ||
                                              smartFormData.region_code ||
                                              smartFormData.state)
                                      )
                            "
              cssClass="e-info mr-3"
              formControlName="type"
              label="RUIAN"
              name="type"
              value="ruian"
            ></ejs-radiobutton>
            <ejs-radiobutton
              [checked]="
                                smartFormData && !!smartFormData.street_code
                            "
              cssClass="e-info mr-3"
              formControlName="type"
              label="Ulice"
              name="type"
              value="street"
            ></ejs-radiobutton>
            <ejs-radiobutton
              [checked]="
                                smartFormData && !!smartFormData.city_part_code
                            "
              cssClass="e-info mr-3"
              formControlName="type"
              label="Městská část"
              name="type"
              value="part"
            ></ejs-radiobutton>
            <ejs-radiobutton
              [checked]="
                                smartFormData && !!smartFormData.city_code
                            "
              cssClass="e-info mr-3"
              formControlName="type"
              label="Město"
              name="type"
              value="city"
            ></ejs-radiobutton>
            <ejs-radiobutton
              [checked]="
                                smartFormData && !!smartFormData.district_code
                            "
              cssClass="e-info mr-3"
              formControlName="type"
              label="Okres"
              name="type"
              value="district"
            ></ejs-radiobutton>
            <ejs-radiobutton
              [checked]="
                                smartFormData && !!smartFormData.region_code
                            "
              cssClass="e-info mr-3"
              formControlName="type"
              label="Kraj"
              name="type"
              value="region"
            ></ejs-radiobutton>
            <ejs-radiobutton
              [checked]="smartFormData && !!smartFormData.state"
              cssClass="e-info"
              formControlName="type"
              label="Stát"
              name="type"
              value="country"
            ></ejs-radiobutton>
          </div>
        </div>
        <h6 class="text-muted pt-2">
          <i
            aria-hidden="true"
            class="fa-light fa-lg fa-clipboard-list"
          ></i
          >&nbsp;Služby:
        </h6>
        <hr class="pt-0 mt-0" />
        <div class="row">
          <div class="col-12 mb-3 form-group">
            <label
              [ngClass]="
                                f.net_packages.valid
                                    ? 'text-success'
                                    : f.net_packages.invalid &&
                                      (f.net_packages.dirty ||
                                          f.net_packages.touched)
                                    ? 'text-danger'
                                    : 'text-muted'
                            "
              for="servicesNet"
            >* Služby internet:</label
            >
            <ejs-multiselect
              #net_packages
              (filtering)="onFilteringNetPackages($event)"
              [allowFiltering]="true"
              [dataSource]="servicesNetSelect"
              [fields]="addrFieldObj"
              [filterBarPlaceholder]="
                                'Vyhledat internet služby...'
                            "
              [ignoreAccent]="true"
              [mode]="'CheckBox'"
              [placeholder]="'Vyberte internet služby'"
              [popupHeight]="height"
              [selectAllText]="'Všechny internetové tarify'"
              formControlName="net_packages"
              id="servicesNet"
              showSelectAll="true"
              sortOrder="Ascending"
            ></ejs-multiselect>
            <small class="text-muted mr-3"
            >Pokud zůstane prázdné bude aplikováno na všechny
              dostupné tarify</small
            >
            <small
              *ngIf="
                                f.net_packages.touched &&
                                f.net_packages.errors &&
                                f.net_packages.errors.required
                            "
              class="text-danger"
            >Vyberte internet služby</small
            >
          </div>
          <div class="col-12 mb-3 form-group">
            <label
              [ngClass]="
                                f.tv_services.valid
                                    ? 'text-success'
                                    : f.tv_services.invalid &&
                                      (f.tv_services.dirty ||
                                          f.tv_services.touched)
                                    ? 'text-danger'
                                    : 'text-muted'
                            "
              for="servicesTv"
            >Služby televize:</label
            >
            <ejs-multiselect
              #tv_services
              (filtering)="onFilteringTvPackages($event)"
              [allowFiltering]="true"
              [dataSource]="servicesTvSelect"
              [fields]="addrFieldObj"
              [filterBarPlaceholder]="
                                'Vyhledat televizní služby...'
                            "
              [ignoreAccent]="true"
              [mode]="'CheckBox'"
              [placeholder]="'Vyberte televizní služby'"
              [popupHeight]="height"
              [selectAllText]="'Všechny TV služby'"
              formControlName="tv_services"
              id="servicesTv"
              showSelectAll="true"
              sortOrder="Ascending"
            ></ejs-multiselect>
            <small class="text-muted mr-3"
            >Pokud zůstane prázdné bude aplikováno na všechny
              dostupné tarify</small
            >
            <small
              *ngIf="
                                f.tv_services.touched &&
                                f.tv_services.errors &&
                                f.tv_services.errors.required
                            "
              class="text-danger"
            >Vyberte televizní služby</small
            >
          </div>
        </div>
        <h6 class="text-muted pt-2">
          <i
            aria-hidden="true"
            class="fa-light fa-lg fa-sticky-note"
          ></i
          >&nbsp;Popis:
        </h6>
        <hr class="pt-0 mt-0" />
        <div class="row">
          <div class="col-12 mb-2 form-group">
            <label
              [ngClass]="
                                f.descriptive.valid
                                    ? 'text-success'
                                    : f.descriptive.invalid &&
                                      (f.descriptive.dirty ||
                                          f.descriptive.touched)
                                    ? 'text-danger'
                                    : 'text-muted'
                            "
              for="descriptive"
            >Popis:</label
            >
            <ejs-textbox
              [multiline]="true"
              [ngClass]="{
                                'e-error': submited && f.descriptive.errors
                            }"
              floatLabelType="Auto"
              formControlName="descriptive"
              id="descriptive"
            ></ejs-textbox>
            <small
              *ngIf="
                                (f.descriptive.dirty ||
                                    f.descriptive.touched) &&
                                f.descriptive.errors &&
                                f.descriptive.errors.required
                            "
              class="text-danger"
            >Popis vyloučené adresy</small
            >
          </div>
        </div>
      </div>
    </ng-template>
    <ng-template #footerTemplate>
      <div class="d-flex justify-content-end">
                <span
                  *ngIf="addressExcludedForm.invalid"
                  class="e-badge e-badge-warning p-2 size_2"
                  role="alert"
                >
                    <strong>Formulář je špatně vyplněný</strong>
                </span>
        <span
          *ngIf="addressExcludedForm.valid"
          class="e-badge e-badge-success p-2 size_2"
          role="alert"
        >
                    <strong>Formulář je správně vyplněný</strong>
                </span>
      </div>
      <div class="row">
        <div class="col-12 pt-2">
          <button
            (click)="onSubmit()"
            [disabled]="
                            loadingAddress ||
                            addressExcludedForm.invalid ||
                            !isChecked
                        "
            cssClass="e-btn e-success btn-p-zero e-small mr-1"
            ejs-button
            type="submit"
          >
                        <span
                          *ngIf="loadingAddress === true"
                          aria-hidden="true"
                          class="spinner-grow spinner-grow-sm"
                          role="status"
                        ></span>
            <span *ngIf="loadingAddress === false">ULOŽIT</span>
            <span *ngIf="loadingAddress === true">Provádím...</span>
          </button>
          <button
            (click)="formDialogObj.hide()"
            cssClass="e-btn e-danger e-outline btn-p-zero e-small"
            ejs-button
            type="button"
          >
            ZRUŠIT
          </button>
        </div>
      </div>
    </ng-template>
  </form>
</ejs-dialog>
