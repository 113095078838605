<ejs-dialog
  #deleteFileDialog
  [buttons]="buttonsDeleteFileDialog"
  [content]="'Opravdu chcete smazat tento soubor?'"
  [header]="'Smazat soubor'"
  [height]="'200px'"
  [isModal]="true"
  [showCloseIcon]="true"
  [visible]="false"
  [width]="'450px'"
  id="deleteFileDialog"
  target="#formDialog"
></ejs-dialog>
<ejs-dialog
  #formDialog
  (close)="
        isUpdate = false;
        isCreate = false;
        isDirty = false;
        isChecked = false;
        isCopy = false;
        submited = false;
        changeDialogState(false)
    "
  (open)="onCreateRte()"
  [isModal]="true"
  [showCloseIcon]="true"
  [visible]="isVisible"
  [width]="'85%'"
  id="formDialog"
>
  <form [formGroup]="knowledgeForm">
    <ng-template #content>
      <div class="dialogContent">
        <div class="row">
          <div class="form-group col-12 mb-3">
            <label
              [ngClass]="
                                knowledgeFormCtrl.category_id.valid
                                    ? 'text-success'
                                    : knowledgeFormCtrl.category_id.invalid
                                    ? 'text-danger'
                                    : 'text-muted'
                            "
              for="category_id"
            >* Kategorie pracovního postupu:</label
            >
            <ejs-dropdownlist
              #category
              [allowFiltering]="true"
              [dataSource]="categorySelect"
              [fields]="fields"
              [filterBarPlaceholder]="'Vyhledat kategorii...'"
              [ignoreAccent]="true"
              [placeholder]="'Vyberte kategorii postupu'"
              [popupHeight]="height"
              formControlName="category_id"
              id="category_id"
              sortOrder="Ascending"
            ></ejs-dropdownlist>
            <small
              *ngIf="
                                knowledgeFormCtrl.category_id.touched &&
                                knowledgeFormCtrl.category_id.errors &&
                                knowledgeFormCtrl.category_id.errors.required
                            "
              class="text-danger"
            >Kategorie je povinná</small
            >
          </div>
          <div class="form-group col-12">
            <label
              [ngClass]="
                                knowledgeFormCtrl.subject.valid
                                    ? 'text-success'
                                    : knowledgeFormCtrl.subject.invalid
                                    ? 'text-danger'
                                    : 'text-muted'
                            "
              for="subject"
            >* Nadpis pracovního postupu:</label
            >
            <ejs-textbox
              [ngClass]="
                                knowledgeFormCtrl.subject.invalid
                                    ? 'e-error'
                                    : knowledgeFormCtrl.subject.valid
                                    ? 'e-success'
                                    : ''
                            "
              floatLabelType="Auto"
              formControlName="subject"
              id="subject"
            ></ejs-textbox>
            <small
              *ngIf="
                                knowledgeFormCtrl.subject.touched &&
                                knowledgeFormCtrl.subject.errors &&
                                knowledgeFormCtrl.subject.errors.required
                            "
              class="text-danger"
            >Nadpis pracovního postupu je povinný</small
            >
          </div>
        </div>
        <div class="row">
          <div class="form-group col-12 mb-3">
            <label
              [ngClass]="
                                knowledgeFormCtrl.maintask.valid
                                    ? 'text-success'
                                    : knowledgeFormCtrl.maintask.invalid
                                    ? 'text-danger'
                                    : 'text-muted'
                            "
              for="maintask"
            >* Obsah pracovního postupu:</label
            >
            <ejs-richtexteditor
              #maintask
              (actionBegin)="handleFullScreen($event)"
              (actionComplete)="actionCompleteHandler($event)"
              [enablePersistence]="false"
              [fontFamily]="fontFamily"
              [insertImageSettings]="insertImageSettings"
              [maxLength]="maxLength"
              [ngClass]="
                                knowledgeFormCtrl.maintask.invalid
                                    ? 'e-error'
                                    : knowledgeFormCtrl.maintask.valid
                                    ? 'e-success'
                                    : ''
                            "
              [pasteCleanupSettings]="pasteCleanupSettings"
              [quickToolbarSettings]="quickToolbarSettings"
              [showCharCount]="true"
              [toolbarSettings]="tools"
              formControlName="maintask"
              id="maintask"
              placeholder="Začněte psát pracovní postup..."
            >
            </ejs-richtexteditor>
            <small
              *ngIf="
                                knowledgeFormCtrl.maintask.touched &&
                                knowledgeFormCtrl.maintask.errors &&
                                knowledgeFormCtrl.maintask.errors.required
                            "
              class="text-danger"
            >Obsah pracovního postupu je povinný</small
            >
          </div>
        </div>
        <div class="row">
          <div class="col-12 mb-3 form-group">
            <label
              [ngClass]="
                                knowledgeFormCtrl.users.valid
                                    ? 'text-success'
                                    : knowledgeFormCtrl.users.invalid &&
                                      (knowledgeFormCtrl.users.dirty ||
                                          knowledgeFormCtrl.users.touched)
                                    ? 'text-danger'
                                    : 'text-muted'
                            "
              for="users"
            >Zapojit uživatele:</label
            >
            <ejs-multiselect
              #users
              (filtering)="onFilteringUsers($event)"
              [allowFiltering]="true"
              [dataSource]="usersSelect"
              [fields]="fields"
              [filterBarPlaceholder]="'Vyhledat uživatele...'"
              [ignoreAccent]="true"
              [mode]="'CheckBox'"
              [placeholder]="'Vyberte uživatele'"
              [popupHeight]="height"
              [selectAllText]="'Všechny uživatele'"
              formControlName="users"
              id="users"
              showSelectAll="true"
              sortOrder="Ascending"
            ></ejs-multiselect>
            <small
              *ngIf="
                                knowledgeFormCtrl.users.touched &&
                                knowledgeFormCtrl.users.errors &&
                                knowledgeFormCtrl.users.errors.required
                            "
              class="text-danger"
            >Vyberte uživatele</small
            >
          </div>
          <div class="col-12 mb-3 form-group">
            <label
              [ngClass]="
                                knowledgeFormCtrl.departments.valid
                                    ? 'text-success'
                                    : knowledgeFormCtrl.departments.invalid &&
                                      (knowledgeFormCtrl.departments.dirty ||
                                          knowledgeFormCtrl.departments.touched)
                                    ? 'text-danger'
                                    : 'text-muted'
                            "
              for="departments"
            >Zapojit oddělení:</label
            >
            <ejs-multiselect
              #departments
              (filtering)="onFilteringDepartments($event)"
              [allowFiltering]="true"
              [dataSource]="departmentSelect"
              [fields]="fields"
              [filterBarPlaceholder]="'Vyhledat oddělení...'"
              [ignoreAccent]="true"
              [mode]="'CheckBox'"
              [placeholder]="'Vyberte oddělení'"
              [popupHeight]="height"
              [selectAllText]="'Všechny oddělení'"
              formControlName="departments"
              id="departments"
              showSelectAll="true"
              sortOrder="Ascending"
            ></ejs-multiselect>
            <small
              *ngIf="
                                knowledgeFormCtrl.departments.touched &&
                                knowledgeFormCtrl.departments.errors &&
                                knowledgeFormCtrl.departments.errors.required
                            "
              class="text-danger"
            >Vyberte oddělení</small
            >
          </div>
          <div *ngIf="isUpdate" class="col-12 mb-3">
            <label class="text-muted" for="preloadfileupload"
            >Již nahrané soubory:</label
            >
            <br />
            <ng-container
              *ngIf="knowledge && knowledge.files.length"
            >
              <div
                *ngFor="let file of knowledge.files"
                class="e-card"
              >
                <div class="e-card-content row">
                  <div class="col-3">
                    {{ file.name | fileExtension: false }}
                  </div>
                  <div class="col-3 text-center">
                    {{ file.size | humanizeBytes }}
                  </div>
                  <div class="col-3 text-center">
                    {{ file.name | fileExtension: true }}
                  </div>
                  <div class="col-3 text-right">
                    <i
                      (click)="
                                                deleteFileID = file.id;
                                                deleteFileDialogObj.show()
                                            "
                      class="fa-light fa-lg fa-trash text-danger pr-4 align-middle"
                      style="cursor: pointer"
                    ></i>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
          <div class="col-12 mb-3">
            <label
              [ngClass]="
                                knowledgeFormCtrl.files.valid
                                    ? 'text-success'
                                    : knowledgeFormCtrl.files.invalid &&
                                      (knowledgeFormCtrl.files.dirty ||
                                          knowledgeFormCtrl.files.touched)
                                    ? 'text-danger'
                                    : 'text-muted'
                            "
              for="preloadfileupload"
            >Soubory:</label
            >
            <ejs-uploader
              #preloadupload
              (removing)="onFileRemove($event)"
              (selected)="onFileSelected($event)"
              [asyncSettings]="path"
              [autoUpload]="autoUpload"
              [dropArea]="dropElement"
              allowedExtensions=".jpg,.png,.jpeg,.xls,.xlsx,.pdf,.doc,.docx,.rar,.zip,.csv,.json,.odt,.ods,.txt,.xml"
              formControlName="files"
              id="preloadfileupload"
              maxFileSize="5000000"
              multiple="true"
            >
            </ejs-uploader>
            <small
              *ngIf="
                                (knowledgeFormCtrl.files.dirty ||
                                    knowledgeFormCtrl.files.touched) &&
                                knowledgeFormCtrl.files.errors &&
                                knowledgeFormCtrl.files.errors.required
                            "
              class="text-danger"
            >Soubor je povinný</small
            >
          </div>
        </div>
      </div>
    </ng-template>
    <ng-template #footerTemplate>
      <div class="d-flex justify-content-end">
                <span
                  *ngIf="knowledgeForm.invalid"
                  class="e-badge e-badge-warning p-2 size_2"
                  role="alert"
                >
                    <strong>Formulář je špatně vyplněný</strong>
                </span>
        <span
          *ngIf="knowledgeForm.valid"
          class="e-badge e-badge-success p-2 size_2"
          role="alert"
        >
                    <strong>Formulář je správně vyplněný</strong>
                </span>
      </div>
      <div class="row">
        <div class="col-12 pt-2">
          <button
            (click)="onSubmit()"
            [disabled]="
                            knowledgeLoading ||
                            knowledgeForm.invalid ||
                            !isChecked
                        "
            cssClass="e-btn e-success btn-p-zero e-small mr-1"
            ejs-button
            type="submit"
          >
                        <span
                          *ngIf="knowledgeLoading === true"
                          aria-hidden="true"
                          class="spinner-grow spinner-grow-sm"
                          role="status"
                        ></span>
            <span *ngIf="knowledgeLoading === false">ULOŽIT</span>
            <span *ngIf="knowledgeLoading === true"
            >Provádím...</span
            >
          </button>
          <button
            (click)="formDialogObj.hide()"
            cssClass="e-btn e-danger e-outline btn-p-zero e-small"
            ejs-button
            type="button"
          >
            ZRUŠIT
          </button>
        </div>
      </div>
    </ng-template>
  </form>
</ejs-dialog>
