import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    HostListener,
    Input,
    OnChanges,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import {DropDownListComponent, FilteringEventArgs} from '@syncfusion/ej2-angular-dropdowns';
import {
    AbstractControl,
    FormArray,
    FormBuilder,
    FormControl,
    FormGroup,
    Validators,
} from '@angular/forms';
import {User} from '@src/app/_models/user/user';
import {Assignation} from '@src/app/_models/assignation/assignation';
import {Employer} from '@src/app/_models/employer/employer';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {AuthenticationService} from '@src/app/_services/authentication.service';
import {DataService} from '@src/app/_services/data.service';
import {MessageService} from '@src/app/_services/message.service';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {Department} from '@src/app/_models/department/department';
import {Role} from '@src/app/_models/role/role';
import {EmitType} from '@syncfusion/ej2-base';
import {Query} from '@syncfusion/ej2-data';
import {Observable} from 'rxjs';
import {DialogComponent} from '@syncfusion/ej2-angular-popups';
import {ComponentCanDeactivate} from '@src/app/_guards/changes.guard';
import {PermissionService} from '@src/app/_services/permission.service';
import * as moment from 'moment';
import {SettingsService} from '@src/app/features/settings/settings.service';
import {AssignInput, UserInput, UsersService} from '@src/app/features/users/users.service';
import {CustomValidators} from '@src/app/features/users/custom-validators';
import {SignaturesCreatorService} from '@src/app/_services/signatures-creator.service';

@UntilDestroy()
@Component({
    selector: 'app-user-form',
    templateUrl: './user-form.component.html',
    styleUrls: ['./user-form.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserFormComponent implements OnInit, OnChanges, ComponentCanDeactivate {
    // Dropdowms
    height = '240px';

    fields: object = {text: 'label', value: 'value'};

    roleSelect: Array<{value: boolean | number | string; label: string}> = [];

    departmentSelect: Array<{value: boolean | number | string; label: string}> = [];

    bossSelect: Array<{value: boolean | number | string; label: string}> = [];

    employerSelect: Array<{value: boolean | number | string; label: string}> = [];

    // forms
    userForm: FormGroup;

    assignationList: FormArray;

    isDirty = false;

    isChecked = false;

    submited = false;

    // Variables
    boss: User;

    users: User[] | undefined;

    currGeneratedPass: string;

    assignation: Assignation[];

    employer: Employer;

    currentUser: User | null;

    weekCount = UserFormComponent.weeksInYear(moment()
        .year());

    password = {
        pattern: /[\w\d\\?-]/,
        getRandomByte: (): number => {
            const result = new Uint8Array(1);

            crypto.getRandomValues(result);

            return result[0];
        },
        generate: (length: number): string =>
            Math.random()
                .toString(length)
                .slice(2) +
            Math.random()
                .toString(length)
                .toUpperCase()
                .slice(2),
    };

    // Loaders
    loadingUser = false;

    @Input() user: User | null = null;

    @Input() isCreate = false;

    @Input() isUpdate = false;

    @Input() isVisible = false;

    @Input() isCopy = false;

    @Output() readonly userFormDialogState = new EventEmitter<boolean>();

    // Dropdowms
    @ViewChild('role') usersObj: DropDownListComponent;

    @ViewChild('department') departmentObj: DropDownListComponent;

    @ViewChild('boss') bossObj: DropDownListComponent;

    @ViewChild('employer') employerObj: DropDownListComponent;

    // Dialogs
    @ViewChild('formDialog') formDialogObj: DialogComponent;

    constructor(
        private readonly formBuilder: FormBuilder,
        private readonly route: ActivatedRoute,
        private readonly router: Router,
        private readonly ref: ChangeDetectorRef,
        private readonly signaturesService: SignaturesCreatorService,
        private readonly authenticationService: AuthenticationService,
        private readonly dataService: DataService,
        private readonly userService: UsersService,
        private readonly permissionService: PermissionService,
        private readonly message: MessageService,
        private readonly messages: MessageService,
    ) {
        this.currentUser = this.authenticationService.currentUserValue;
    }

    get assignationsformGroup(): FormArray {
        return this.userForm.get('userAssignations') as FormArray;
    }

    get f(): {[key: string]: AbstractControl} {
        return this.userForm.controls;
    }

    static getWeekNumber(d: Date): {year: number; weekNo: number} {
        d = new Date(+d);
        d.setHours(0, 0, 0, 0);
        d.setDate(d.getDate() + 4 - (d.getDay() || 7));

        const yearStart = new Date(d.getFullYear(), 0, 1);
        const weekNo = Math.ceil(((d.valueOf() - yearStart.valueOf()) / 86400000 + 1) / 7);

        return {year: d.getFullYear(), weekNo};
    }

    static weeksInYear(year: number): number {
        const d = new Date(year, 11, 31);
        const {weekNo} = UserFormComponent.getWeekNumber(d);

        return weekNo === 1 ? 52 : weekNo;
    }

    @HostListener('window:beforeunload')
    canDeactivate(): Observable<boolean> | boolean {
        return !this.isDirty;
    }

    ngOnInit(): void {
        this.dataService.userSource.pipe(untilDestroyed(this))
            .subscribe(
                (data: User[]) => {
                    this.users = data;
                    this.bossSelect = [];
                    data.map((user: User) => {
                        if (user.authorized && !user.deleted_date) {
                            this.bossSelect = [
                                ...this.bossSelect,
                                {value: user.id, label: user.firstname + ' ' + user.secondname},
                            ];
                        }
                    });
                },
                error => {
                    console.error(error);
                },
            );

        this.dataService.departmentSource.pipe(untilDestroyed(this))
            .subscribe(
                (data: Department[]) => {
                    this.departmentSelect = [];
                    data.map((department: Department) => {
                        this.departmentSelect = [
                            ...this.departmentSelect,
                            {
                                value: department.id,
                                label: department.company.name + ' - ' + department.name,
                            },
                        ];
                    });
                },
                error => {
                    console.error(error);
                },
            );

        this.dataService.roleSource.pipe(untilDestroyed(this))
            .subscribe(
                (data: Role[]) => {
                    this.roleSelect = [];
                    data.map((role: Role) => {
                        this.roleSelect = [...this.roleSelect, {value: role.id, label: role.name}];
                    });
                },
                error => {
                    console.error(error);
                },
            );

        this.dataService.employerSource.pipe(untilDestroyed(this))
            .subscribe(
                (data: Employer[]) => {
                    this.employerSelect = [];
                    data.map((employer: Employer) => {
                        if (!employer.deleted_date) {
                            this.employerSelect = [
                                ...this.employerSelect,
                                {value: employer.id, label: employer.name},
                            ];
                        }
                    });
                },
                error => {
                    console.error(error);
                },
            );

        this.currGeneratedPass = this.password.generate(16);

        this.userForm = this.formBuilder.group(
            {
                userBoss: ['', Validators.required],
                userEmployer: ['', Validators.required],
                userAssignations: this.formBuilder.array([this.createAssignation()]),
                userFirstname: [
                    '',
                    Validators.compose([
                        Validators.required,
                        Validators.minLength(2),
                        Validators.maxLength(100),
                    ]),
                ],
                userSecondname: [
                    '',
                    Validators.compose([
                        Validators.required,
                        Validators.minLength(2),
                        Validators.maxLength(100),
                    ]),
                ],
                userPassword: [
                    '',
                    Validators.compose(
                        this.isCreate
                            ? [
                                Validators.required,
                                CustomValidators.patternValidator(/\d/, {hasNumber: true}),
                                CustomValidators.patternValidator(/[A-Z]/, {
                                    hasCapitalCase: true,
                                }),
                                CustomValidators.patternValidator(/[a-z]/, {
                                    hasSmallCase: true,
                                }),
                                Validators.minLength(8),
                            ]
                            : [
                                CustomValidators.patternValidator(/\d/, {hasNumber: true}),
                                CustomValidators.patternValidator(/[A-Z]/, {
                                    hasCapitalCase: true,
                                }),
                                CustomValidators.patternValidator(/[a-z]/, {
                                    hasSmallCase: true,
                                }),
                                Validators.minLength(8),
                            ],
                    ),
                ],
                confirmPassword: [
                    '',
                    Validators.compose(
                        this.isCreate
                            ? [
                                Validators.required,
                                CustomValidators.patternValidator(/\d/, {hasNumber: true}),
                                CustomValidators.patternValidator(/[A-Z]/, {
                                    hasCapitalCase: true,
                                }),
                                CustomValidators.patternValidator(/[a-z]/, {
                                    hasSmallCase: true,
                                }),
                                Validators.minLength(8),
                            ]
                            : [
                                CustomValidators.patternValidator(/\d/, {hasNumber: true}),
                                CustomValidators.patternValidator(/[A-Z]/, {
                                    hasCapitalCase: true,
                                }),
                                CustomValidators.patternValidator(/[a-z]/, {
                                    hasSmallCase: true,
                                }),
                                Validators.minLength(8),
                            ],
                    ),
                ],
                userWorkemail: [
                    '',
                    Validators.compose([
                        Validators.required,
                        Validators.minLength(7),
                        Validators.maxLength(100),
                        Validators.email,
                        Validators.pattern('\\S+@\\S+\\.\\S+'),
                    ]),
                    this.isEmailUnique.bind(this),
                ],
                userTelnumber: [
                    '',
                    Validators.compose([
                        Validators.required,
                        Validators.pattern('(\\+420)? ?[1-9][0-9]{2} ?[0-9]{3} ?[0-9]{3}'),
                        Validators.minLength(9),
                        Validators.maxLength(17),
                    ]),
                    this.isTelUnique.bind(this),
                ],
                userVacDays: [
                    '',
                    Validators.compose([
                        Validators.required,
                        Validators.min(0),
                        Validators.max(40),
                    ]),
                ],
                userSickDays: [
                    '',
                    Validators.compose([Validators.required, Validators.min(0), Validators.max(3)]),
                ],
                userPositionName: [
                    '',
                    Validators.compose([
                        Validators.required,
                        Validators.minLength(5),
                        Validators.maxLength(255),
                    ]),
                ],
            },
            {validator: CustomValidators.passwordMatchValidator},
        );

        this.assignationList = this.userForm.get('userAssignations') as FormArray;
        this.userForm.controls.userPassword.patchValue(this.currGeneratedPass);
        this.userForm.controls.confirmPassword.patchValue(this.currGeneratedPass);
        this.userForm.controls.userPassword.markAsTouched();
        this.userForm.controls.confirmPassword.markAsTouched();

        this.userForm.valueChanges.pipe(untilDestroyed(this))
            .subscribe(() => {
                this.isDirty = this.userForm.dirty;
                this.showInputErrors();
            });

        this.dataService.setRoleDataSource();
        this.dataService.setEmployerDataSource();
        this.dataService.setDepartmentDataSource();
        this.ref.markForCheck();
    }

    ngOnChanges(): void {
        if (this.isVisible) {
            const queryParams: Params = {form: null};

            void this.router.navigate([], {
                relativeTo: this.route,
                queryParams,
                queryParamsHandling: 'merge',
            });

            if (this.isUpdate && this.user) {
                this.userForm.reset();
                this.formDialogObj.header = `Uživatel #${this.user.id} - ${this.user.fullname}`;
                this.fillTheForm();
                this.showInputErrors();
            }

            if (this.isCreate) {
                this.userForm.reset();
                this.formDialogObj.header = 'Nový uživatel';
            }

            if (this.isCopy) {
                this.userForm.reset();
                this.formDialogObj.header = 'Nový uživatel';
                this.fillTheForm();
                this.showInputErrors();
            }
        }
    }

    changeDialogState(value: boolean): void {
        this.userFormDialogState.emit(value);
    }

    generate(): void {
        this.currGeneratedPass = this.password.generate(16);
        this.userForm.controls.userPassword.patchValue(this.currGeneratedPass);
        this.userForm.controls.confirmPassword.patchValue(this.currGeneratedPass);
    }

    createAssignation(items?: AssignInput): FormGroup {
        return this.formBuilder.group({
            user_id: [items ? items.user_id : this.user?.id ?? null],
            department_id: [
                items ? items.department_id : null,
                Validators.compose([Validators.required]),
            ],
            role_id: [items ? items.role_id : null, Validators.compose([Validators.required])],
            id: [items ? items.id : null],
        });
    }

    getAssignationFormGroup(index): FormGroup {
        return this.assignationList.controls[index] as FormGroup;
    }

    addAssignation(): void {
        this.assignationList.push(this.createAssignation());
    }

    removeAssignation(index: number): void {
        this.assignationList.removeAt(index);
    }

    async isEmailUnique(
        control: FormControl,
    ): Promise<{[key: string]: boolean} | null | undefined> {
        if (control.value) {
            if (this.isUpdate) {
                return new Promise(resolve => {
                    resolve(null);
                });
            }

            return new Promise(resolve => {
                this.userService
                    .isEmailRegistered(control.value as string)
                    .pipe(untilDestroyed(this))
                    .subscribe({
                        next: (response: boolean) => {
                            if (response) {
                                resolve({
                                    taken: true,
                                });
                            } else {
                                resolve(null);
                            }

                            this.ref.markForCheck();
                        },
                        error: () => {
                            resolve(null);
                        },
                    });
            });
        }
    }

    async isTelUnique(
        control: FormControl,
    ): Promise<{[key: string]: boolean} | null | undefined> {
        if (control.value) {
            if (this.isUpdate) {
                return new Promise(resolve => {
                    resolve(null);
                });
            }

            return new Promise(resolve => {
                this.userService
                    .isTelRegistered(control.value.replace(/\+420/g, '') as string)
                    .pipe(untilDestroyed(this))
                    .subscribe({
                        next: (response: boolean) => {
                            if (response) {
                                resolve({
                                    taken: true,
                                });
                            } else {
                                resolve(null);
                            }

                            this.ref.markForCheck();
                        },
                        error: () => {
                            resolve(null);
                        },
                    });
            });
        }
    }

    onFilteringEmployers: EmitType<FilteringEventArgs> = (e: FilteringEventArgs) => {
        if (e.text === '') {
            e.updateData(this.employerSelect);
        } else {
            let query: Query = new Query();

            query = e.text !== '' ? query.where('label', 'contains', e.text, true, true) : query;
            e.updateData(this.employerSelect, query);
        }
    };

    onFilteringUsers: EmitType<FilteringEventArgs> = (e: FilteringEventArgs) => {
        if (e.text === '') {
            e.updateData(this.bossSelect);
        } else {
            let query: Query = new Query();

            query = e.text !== '' ? query.where('label', 'contains', e.text, true, true) : query;
            e.updateData(this.bossSelect, query);
        }
    };

    onFilteringDepartments: EmitType<FilteringEventArgs> = (e: FilteringEventArgs) => {
        if (e.text === '') {
            e.updateData(this.departmentSelect);
        } else {
            let query = new Query();

            query = e.text !== '' ? query.where('label', 'contains', e.text, true) : query;
            e.updateData(this.departmentSelect, query);
        }
    };

    onFilteringRoles: EmitType<FilteringEventArgs> = (e: FilteringEventArgs) => {
        if (e.text === '') {
            e.updateData(this.roleSelect);
        } else {
            let query = new Query();

            query = e.text !== '' ? query.where('label', 'contains', e.text, true) : query;
            e.updateData(this.roleSelect, query);
        }
    };

    fillTheForm(): void {
        this.userForm.controls.userBoss.patchValue(this.user?.boss ? this.user.boss.id : null);
        this.userForm.controls.userEmployer.patchValue(
            this.user?.employer ? this.user.employer.id : null,
        );

        this.userForm.controls.userPositionName.patchValue(
            this.user?.official_position_name ? this.user.official_position_name : null,
        );
        this.userForm.controls.userFirstname.patchValue(this.user?.firstname);
        this.userForm.controls.userSecondname.patchValue(this.user?.secondname);
        this.userForm.controls.userWorkemail.patchValue(this.user?.workemail);
        this.userForm.controls.userTelnumber.patchValue(this.user?.telnumber);
        this.userForm.controls.userVacDays.patchValue(this.user?.vacation_fond);
        this.userForm.controls.userSickDays.patchValue(this.user?.sickdays_fond);

        const assigns: AssignInput[] = [];

        this.assignationList = this.userForm.get('userAssignations') as FormArray;
        this.user?.assignations?.map((assign: Assignation) => {
            assigns.push({
                id: assign.id,
                user_id: assign.user_id,
                department_id: assign.department_id,
                role_id: assign.role_id,
            });
        });

        const assignations = this.userForm.get('userAssignations') as FormArray;

        while (assignations.length) {
            assignations.removeAt(0);
        }

        assigns.forEach(assign => {
            assignations.push(this.createAssignation(assign));
        });

        this.userForm
            .get('userAssignations')
            ?.setValue(assigns, {onlySelf: true, emitEvent: false});
    }

    onSubmit(): void {
        this.submited = true;
        this.isDirty = false;
        this.loadingUser = true;

        if (this.isCreate || this.isCopy) {
            this.createUser();
        }

        if (this.isUpdate) {
            this.editUser();
        }
    }

    createUser(): void {
        if (this.userForm.invalid) {
            console.error('form is not valid!');

            return;
        }

        if (
            this.currentUser &&
            this.permissionService.checkUserISAdministrative(this.currentUser)
        ) {
            const assignation = this.assignationList.value;
            const user: UserInput = {
                firstname: this.f.userFirstname.value,
                secondname: this.f.userSecondname.value,
                password: this.f.userPassword.value,
                workemail: this.f.userWorkemail.value,
                telnumber: this.f.userTelnumber.value,
                vacation_fond: this.f.userVacDays.value,
                sickdays_fond: this.f.userSickDays.value,
                official_position_name: this.f.userPositionName.value,
                boss_id: this.f.userBoss.value,
                employer_id: this.f.userEmployer.value,
                assignations: assignation,
                authorized: true,
                emailverify: true,
                created_by: this.currentUser.id,
            };

            this.userService
                .addUser(user)
                .pipe(untilDestroyed(this))
                .subscribe(
                    (data: User) => {
                        const body = 'Úspěšně jste vytvořili nového uživatele';
                        const options = {
                            progressBar: true,
                            timeOut: 5000,
                            toastClass: 'success',
                        };

                        this.message.showSuccess('Uživatel vytvořen', body, options);
                        this.dataService.clearUserCache();
                        this.dataService.setUsersDataSource();

                        if (this.users && this.users.length > 0) {
                            this.signaturesService
                                .recreateSignatures(this.users)
                                .pipe(untilDestroyed(this))
                                .subscribe(
                                    () => {
                                        const notifBody = 'Podpisy má nyní dostupné každý uživatel na svém profilu';
                                        const notifOptions = {
                                            progressBar: true,
                                            timeOut: 5000,
                                            toastClass: 'success',
                                        };

                                        this.messages.showSuccess(
                                            'Úspěšně vytvořeny HTML podpisy uživatelů',
                                            notifBody,
                                            notifOptions,
                                        );
                                        this.dataService.setUsersDataSource();
                                        this.ref.markForCheck();
                                    },
                                    error => {
                                        console.error(error);

                                        const notifOptions = {
                                            progressBar: true,
                                            timeOut: 5000,
                                            toastClass: 'red',
                                        };
                                        const notifBody = 'Zkuste to znovu později...';

                                        this.messages.showError('Chyba u generování HTML podpisů', notifBody, notifOptions);
                                        this.ref.markForCheck();
                                    },
                                );
                        }

                        this.loadingUser = false;
                        this.isCreate = false;
                        this.formDialogObj.hide();
                        this.ref.markForCheck();
                        void this.router.navigate(['/users/detail', data.id]);
                    },
                    error => {
                        console.error(error);

                        const body = 'Zkuste to znovu později';
                        const options = {
                            progressBar: true,
                            timeOut: 5000,
                            toastClass: 'red',
                        };

                        this.message.showError('Chyba při vytváření uživatele', body, options);
                        this.loadingUser = false;
                        this.ref.markForCheck();
                    },
                );
        } else {
            const body = 'Nemáte oprávnění provést tuto akci...';
            const options = {progressBar: true, timeOut: 5000};

            this.messages.showError('Nedostatečné oprávnění', body, options);
            this.loadingUser = false;
            this.isCreate = false;
            this.ref.markForCheck();
        }
    }

    editUser(): void {
        if (this.userForm.invalid) {
            console.error('form is not valid!');

            return;
        }

        if (
            this.currentUser &&
            this.user &&
            (
                this.permissionService.checkUserISAdministrative(this.currentUser) ||
                this.permissionService.checkUserISLeader(this.user)
                    .leaderDepartments
                    .find(
                        departmentId => this.user?.assignations?.find(
                            assignations => assignations.department_id === departmentId,
                        ),
                    ) ||
                this.currentUser.id === this.user.boss_id
            )
        ) {
            const assignation = this.assignationList.value;
            const user: UserInput = {
                id: this.user.id,
                firstname: this.f.userFirstname.value,
                secondname: this.f.userSecondname.value,
                password: this.f.userPassword.value,
                workemail: this.f.userWorkemail.value,
                telnumber: this.f.userTelnumber.value,
                vacation_fond: this.f.userVacDays.value,
                sickdays_fond: this.f.userSickDays.value,
                official_position_name: this.f.userPositionName.value,
                boss_id: this.f.userBoss.value,
                employer_id: this.f.userEmployer.value,
                assignations: assignation,
                updated_by: this.currentUser.id,
            };

            this.userService
                .updateUser(user)
                ?.pipe(untilDestroyed(this))
                .subscribe(
                    (data: User) => {
                        const body = `uživatele: #${data.id}`;
                        const options = {progressBar: true, timeOut: 5000};

                        this.messages.showSuccess('Úspěšná úprava', body, options);
                        this.dataService.setUsersDataSource();
                        this.dataService.setUserDetailSource(data.id);
                        this.loadingUser = false;
                        this.isUpdate = false;
                        this.formDialogObj.hide();
                        this.ref.markForCheck();
                    },
                    error => {
                        console.error(error);

                        const body = 'Zkuste to znovu později';
                        const options = {progressBar: true, timeOut: 5000};

                        this.messages.showError('Chyba při úpravě uživatele', body, options);
                        this.loadingUser = false;
                        this.ref.markForCheck();
                    },
                );
        } else {
            const body = 'Nemáte oprávnění provést tuto akci...';
            const options = {progressBar: true, timeOut: 5000};

            this.messages.showError('Nedostatečné oprávnění', body, options);
            this.loadingUser = false;
            this.isUpdate = false;
            this.ref.markForCheck();
        }
    }

    showInputErrors(): void {
        this.isChecked = true;
        /*     console.log('VALID: ' + this.userForm.valid);
Object.keys(this.userForm.controls).forEach(key => {
    const controlErrors: ValidationErrors | null | undefined =
        this.userForm.get(key)?.errors;

    if (controlErrors) {
        Object.keys(controlErrors).forEach(keyError => {
            console.log(
                'Key control: ' + key + ', keyError: ' + keyError + ', err value: ',
                controlErrors[keyError]
            );
        });
    }
});*/
        this.userForm.markAllAsTouched();
        this.ref.markForCheck();
    }
}
