import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    HostListener,
    Input,
    OnChanges,
    OnInit,
    Output,
    Renderer2,
    ViewChild,
} from '@angular/core';
import {ComponentCanDeactivate} from '@src/app/_guards/changes.guard';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthenticationService} from '@src/app/_services/authentication.service';
import {PermissionService} from '@src/app/_services/permission.service';
import {DataService} from '@src/app/_services/data.service';
import {DataNetbaseService} from '@src/app/_services/data-netbase.service';
import {ProjectInput, ServicesService} from '@src/app/features/services/services.service';
import {MessageService} from '@src/app/_services/message.service';
import {Observable} from 'rxjs';
import {User} from '@src/app/_models/user/user';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {saveAs} from 'file-saver';
import {DialogComponent} from '@syncfusion/ej2-angular-popups';
import {Project} from '@src/app/_models/projects/project';
import {ProjectFile} from '@src/app/_models/projects/project-file';
import {
    BeforeUploadEventArgs,
    FileInfo,
    RemovingEventArgs,
    SelectedEventArgs,
    SuccessEventArgs,
    UploaderComponent,
    UploadingEventArgs,
} from '@syncfusion/ej2-angular-inputs';
import {EmitType, isNullOrUndefined} from '@syncfusion/ej2-base';
import {EnvironmentService} from '@src/app/_services/environment.service';

@UntilDestroy()
@Component({
    selector: 'app-project-form',
    templateUrl: './project-form.component.html',
    styleUrls: ['./project-form.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectFormComponent implements OnInit, OnChanges, ComponentCanDeactivate {
    // Dialogs
    buttonsDeleteFileDialog = [
        {
            click: this.removeProjectFile.bind(this),
            buttonModel: {
                content: 'OK',
                cssClass: 'e-success e-outline',
                isFlat: false,
            },
        },
        {
            click: (): void => {
                this.deleteFileDialog.hide();
            },
            buttonModel: {content: 'ZRUŠIT', cssClass: 'e-danger e-outline'},
        },
    ];

    // Upload
    path: {saveUrl: string; removeUrl: string};

    dropElement: HTMLElement = document.getElementsByClassName('control-fluid')[0] as HTMLElement;

    humanizeBytes: (bytes: number) => void;

    projectFile: ProjectFile;

    deleteFileId: number;

    // Forms
    projectForm: FormGroup;

    isDirty = false;

    isChecked = false;

    submited = false;

    currentUser: User | null;

    emptyItems = false;

    // Loaders
    loadingProject = false;

    @Input() project: Project | null = null;

    @Input() isCreate = false;

    @Input() isUpdate = false;

    @Input() isVisible = false;

    @Input() isCopy = false;

    @Output() readonly projectFormDialogState = new EventEmitter<boolean>();

    // Dialogs
    @ViewChild('formDialog') formDialogObj: DialogComponent;

    @ViewChild('deleteFileDialog') deleteFileDialog: DialogComponent;

    // Upload
    @ViewChild('preloadupload') uploadObj: UploaderComponent;

    constructor(
        private readonly formBuilder: FormBuilder,
        private readonly route: ActivatedRoute,
        private readonly router: Router,
        private readonly authenticationService: AuthenticationService,
        private readonly permissionService: PermissionService,
        private readonly dataService: DataService,
        private readonly renderer: Renderer2,
        private readonly dataNetbaseService: DataNetbaseService,
        private readonly servicesService: ServicesService,
        private readonly messages: MessageService,
        private readonly ref: ChangeDetectorRef,
        private readonly environmentService: EnvironmentService,
    ) {
        this.currentUser = this.authenticationService.currentUserValue;
        this.path = {
            saveUrl: `${this.environmentService.backendURL}/api/upload/project?type=projects`,
            removeUrl: '',
        };
    }

    get f(): {[key: string]: AbstractControl} {
        return this.projectForm.controls;
    }

    @HostListener('window:beforeunload')
    canDeactivate(): Observable<boolean> | boolean {
        return !this.isDirty;
    }

    ngOnInit(): void {
        this.projectForm = this.formBuilder.group({
            name: ['', Validators.required],
            description: ['', Validators.required],
            budget: [0, Validators.required],
            start_at: [null, Validators.required],
            end_at: [null, Validators.required],
            files: [null],
        });

        this.projectForm.valueChanges.pipe(untilDestroyed(this))
            .subscribe(() => {
                this.isDirty = this.projectForm.dirty;
                this.ref.markForCheck();
            });
    }

    ngOnChanges(): void {
        if (this.isVisible) {
            if (this.isUpdate && this.project) {
                this.projectForm.reset();
                this.formDialogObj.header = `Projekt #${this.project.id} - ${this.project.name}`;
                this.fillTheForm();
                this.showInputErrors();
            }

            if (this.isCreate) {
                this.projectForm.reset();
                this.formDialogObj.header = 'Nový projekt';
            }

            if (this.isCopy) {
                this.projectForm.reset();
                this.formDialogObj.header = 'Nový projekt';
                this.fillTheForm();
                this.showInputErrors();
            }
        }
    }

    changeDialogState(value: boolean): void {
        this.projectFormDialogState.emit(value);
    }

    fillTheForm(): void {
        this.projectForm.controls.name.patchValue(this.project?.name);
        this.projectForm.controls.description.patchValue(this.project?.description);
        this.projectForm.controls.budget.patchValue(this.project?.budget);
        this.projectForm.controls.start_at.patchValue(this.project?.start_at);
        this.projectForm.controls.end_at.patchValue(this.project?.end_at);
    }

    onSubmit(): void {
        this.submited = true;
        this.isDirty = false;
        this.loadingProject = true;

        if (this.isCreate || this.isCopy) {
            this.createProject();
        }

        if (this.isUpdate) {
            this.editProject();
        }
    }

    createProject(): void {
        if (this.projectForm.invalid) {
            console.error('form is not valid!');

            return;
        }

        const data: ProjectInput = {
            name: this.f.name.value,
            description: this.f.description.value,
            budget: this.f.budget.value,
            start_at: this.f.start_at.value,
            end_at: this.f.end_at.value,
            created_by: this.currentUser?.id,
        };

        this.servicesService
            .addProject(data)
            .pipe(untilDestroyed(this))
            .subscribe({
                next: () => {
                    const options = {progressBar: true, timeOut: 5000};

                    this.messages.showSuccess('Projekt úspěšně vytvořen', '', options);
                    this.dataService.setProjectsDataSource();
                    this.isCreate = false;
                    this.loadingProject = false;
                    this.ref.markForCheck();
                    this.formDialogObj.hide();
                },
                error: error => {
                    console.error(error);

                    const body = 'Zkuste to znovu později';
                    const options = {
                        progressBar: true,
                        timeOut: 5000,
                        toastClass: 'red',
                    };

                    this.messages.showError('Chyba při vytváření projektu', body, options);
                    this.loadingProject = false;
                    this.ref.markForCheck();
                },
            });
    }

    editProject(): void {
        if (this.projectForm.invalid) {
            console.error('form is not valid!');

            return;
        }

        const data: ProjectInput = {
            id: this.project?.id,
            name: this.f.name.value,
            description: this.f.description.value,
            budget: this.f.budget.value,
            start_at: this.f.start_at.value,
            end_at: this.f.end_at.value,
            updated_by: this.currentUser?.id,
        };

        this.servicesService
            .updateProject(data)
            ?.pipe(untilDestroyed(this))
            .subscribe(
                () => {
                    const options = {progressBar: true, timeOut: 5000};

                    this.messages.showSuccess('Projekt úspěšně upraven', '', options);
                    this.isUpdate = false;
                    this.loadingProject = false;
                    this.ref.markForCheck();
                    this.formDialogObj.hide();
                    // void this.router.navigate(['/material/detail', project.id]); TODO
                },
                error => {
                    console.error(error);

                    const body = 'Zkuste to znovu později';
                    const options = {
                        progressBar: true,
                        timeOut: 5000,
                        toastClass: 'red',
                    };

                    this.messages.showError('Chyba při úpravě projektu', body, options);
                    this.loadingProject = false;
                    this.ref.markForCheck();
                },
            );
    }

    // Files
    downloadFile(file: ProjectFile): void {
        this.servicesService
            .downloadProjectFile(file)
            .pipe(untilDestroyed(this))
            .subscribe(
                data => {
                    saveAs(data, file.name);
                },
                err => {
                    alert('Chyba během stahování souboru.');
                    console.error(err);
                },
            );
    }

    onFileSelected(args: SelectedEventArgs): void {
        // Filter the 5 files only to showcase
        args.filesData.splice(5);

        const filesData: FileInfo[] = this.uploadObj.getFilesData();
        const allFiles: FileInfo[] = filesData.concat(args.filesData);

        if (allFiles.length > 5) {
            // eslint-disable-next-line @typescript-eslint/prefer-for-of
            for (let i = 0; i < allFiles.length; i++) {
                if (allFiles.length > 5) {
                    allFiles.shift();
                }
            }

            args.filesData = allFiles;
            // set the modified custom data
            args.modifiedFilesData = args.filesData;
        }

        let existingFiles: FileInfo[] = this.uploadObj.getFilesData();

        for (let i = 0; i < args.filesData.length; i++) {
            // eslint-disable-next-line @typescript-eslint/prefer-for-of
            for (let j = 0; j < existingFiles.length; j++) {
                if (!isNullOrUndefined(args.filesData[i])) {
                    // eslint-disable-next-line eqeqeq
                    if (existingFiles[j].name == args.filesData[i].name) {
                        args.filesData.splice(i, 1);
                    }
                }
            }
        }

        existingFiles = existingFiles.concat(args.filesData);
        args.modifiedFilesData = existingFiles;
        args.isModified = true;
    }

    beforeFileUpload(args: BeforeUploadEventArgs): void {
        if (this.currentUser && this.project) {
            args.customFormData = [{id: this.project.id}, {created_by: this.currentUser.id}];
        }
    }

    onFileUpload: EmitType<SelectedEventArgs> = (args: UploadingEventArgs) => {
        if (this.currentUser && this.project) {
            args.currentRequest?.setRequestHeader(
                'Authorization',
                `Bearer ${this.currentUser.token}`,
            );
            args.customFormData = [{id: this.project.id}, {created_by: this.currentUser.id}];
        }
    };

    onSuccessUpload(args: SuccessEventArgs): void {
        if (args.file) {
            const body = `Soubor: ${args.file.name}`;
            const options = {progressBar: true, timeOut: 5000, toastClass: 'success'};

            this.messages.showSuccess('Soubor úspěšně nahrán', body, options);
            this.dataService.setProjectsDataSource();
        }
    }

    onFileRemove(args: RemovingEventArgs): void {
        args.postRawFile = false;

        const fileProject = this.project?.files.find(file => file.name === args.filesData[0].name);

        if (fileProject) {
            this.projectFile = fileProject;
        }

        this.deleteFileDialog.show();
    }

    removeProjectFile(): void {
        this.loadingProject = true;

        const file = this.project?.files.find(x => x.id === this.deleteFileId);

        if (file) {
            file.deleted_by = this.currentUser?.id;

            this.servicesService
                .deleteProjectFile(file)
                ?.pipe(untilDestroyed(this))
                .subscribe(
                    data => {
                        const body = `Soubor #${data?.id ?? 'null'}`;
                        const options = {progressBar: true, timeOut: 5000};

                        this.messages.showSuccess('Soubor úspěšně smazán', body, options);
                        this.loadingProject = false;
                        this.deleteFileDialog.hide();
                        this.ref.markForCheck();
                    },
                    err => {
                        alert('Chyba během mazání souboru.');
                        this.loadingProject = false;
                        this.ref.markForCheck();
                        console.error(err);
                    },
                );
        }
    }

    showInputErrors(): void {
        this.isChecked = true;
        /* console.log('VALID: ' + this.ticketForm.valid);
        Object.keys(this.ticketForm.controls).forEach(key => {
            const controlErrors: ValidationErrors = this.ticketForm.get(key).errors;
            if (controlErrors != null) {
                Object.keys(controlErrors).forEach(keyError => {
                    console.log('Key control: ' + key + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError]);
                });
            }
        });*/
        this.projectForm.markAllAsTouched();
        this.ref.markForCheck();
    }
}
