<div
  *ngIf="excludeAddressLoad"
  style="
        display: block !important;
        z-index: 1000000;
        width: 250px;
        height: 250px;
        margin: auto;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    "
>
  <div class="loader">
    <span></span>
    <span></span>
    <span></span>
    <span></span>
  </div>
  <div
    class="lds-circle"
    style="
            margin: auto;
            position: absolute;
            width: 150px;
            height: auto;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        "
  >
    <div></div>
  </div>
</div>
<div class="row d-flex justify-content-center modalWrapper">
  <ejs-dialog
    #confirmationDelete
    [buttons]="buttonsDelete"
    [header]="'Smazání parcely'"
    [isModal]="true"
    [showCloseIcon]="true"
    [visible]="false"
    width="250px"
  ></ejs-dialog>
  <ejs-dialog
    #confirmationCancel
    [buttons]="buttonsCancel"
    [header]="'Nelze provést akci'"
    [isModal]="true"
    [showCloseIcon]="true"
    [visible]="false"
    width="250px"
  ></ejs-dialog>
  <app-address-exclude-form
    (addressExcludedFormDialogState)="
            renderAddressExcludedFormDialog = $event;
            isCreate = $event;
            isUpdate = $event;
            isCopy = $event;
            gridExcludedAdresses?.clearRowSelection();
            disableToolBarBtn()
        "
    [address]="
            clickedRow && clickedRow[0] && (isUpdate || isCopy)
                ? clickedRow[0]
                : null
        "
    [isCopy]="isCopy"
    [isCreate]="isCreate"
    [isUpdate]="isUpdate"
    [isVisible]="renderAddressExcludedFormDialog"
  ></app-address-exclude-form>
</div>
<ejs-toolbar id="filterToolbar" overflowMode="Scrollable" scrollStep="500">
  <e-items>
    <e-item
      [cssClass]="'statusFilter'"
      tooltipText="Přednastavené hledání"
      type="input"
    >
      <ng-template #template>
        <ejs-dropdownlist
          #viewFilter
          (change)="onStatusFilterChange($event)"
          [dataSource]="categoryFilterData"
          [width]="500"
          placeholder="Vyberte přednastavený pohled..."
        ></ejs-dropdownlist>
      </ng-template>
    </e-item>
    <e-item type="Separator"></e-item>
    <e-item
      [cssClass]="'fulltextFilter'"
      tooltipText="Text pro vyhledávání"
      type="input"
    >
      <ng-template #template>
        <ejs-textbox
          #searchtext
          [width]="500"
          class="mr-2"
          placeholder="Vyhledat..."
        ></ejs-textbox>
        <button
          (click)="search()"
          class="e-btn e-info btn-p-zero e-small"
        >
          <i class="fa-light fa-lg fa-search mr-1"></i
          ><span class="d-none d-lg-inline">vyhledat</span>
        </button>
        <button
          (click)="cancelBtnClick()"
          class="e-btn e-danger btn-p-zero e-small"
        >
          <i class="fa-light fa-lg fa-times"></i>
        </button>
      </ng-template>
    </e-item>
  </e-items>
</ejs-toolbar>
<div class="control-section">
  <div class="scroll_wrapper1" id="scroll_wrapper1_exAddresses">
    <div class="scroll_div" id="scroll_div_exAddresses"></div>
  </div>
  <div class="scroll_wrapper2" id="scroll_wrapper2_exAddresses">
    <div class="grid_parent" id="grid_parent_exAddresses">
      <ejs-grid
        #excludeAddressGrid
        (actionBegin)="actionBegin($event)"
        (actionComplete)="actionComplete($event)"
        (created)="onCreated()"
        (dataBound)="onDataBound()"
        (load)="onLoad()"
        (loadeddata)="setInitialGridFiltering()"
        (rowDataBound)="rowDataBound($event)"
        (rowDeselected)="rowSelected()"
        (rowSelected)="rowSelected()"
        (toolbarClick)="toolbarClick($event)"
        [allowExcelExport]="true"
        [allowFiltering]="true"
        [allowMultiSorting]="true"
        [allowPdfExport]="false"
        [allowSelection]="true"
        [allowSorting]="true"
        [dataSource]="data"
        [editSettings]="editSettings"
        [enableHover]="false"
        [enablePersistence]="false"
        [enableVirtualization]="true"
        [filterSettings]="filterSettings"
        [groupSettings]="groupOptions"
        [pageSettings]="pageOptions"
        [selectionSettings]="selectionSettings"
        [sortSettings]="sortOptions"
        [toolbar]="toolbar"
        allowGrouping="true"
        allowReordering="true"
        allowResizing="true"
        allowTextWrap="true"
        height="100%"
        id="excludeAddressGrid"
        locale="cs"
        rowHeight="38"
        showColumnMenu="true"
        width="100%"
      >
        <e-columns>
          <e-column
            [allowEditing]="false"
            [allowFiltering]="false"
            [allowReordering]="false"
            [allowResizing]="false"
            [allowSorting]="false"
            [autoFit]="true"
            type="checkbox"
          ></e-column>
          <e-column
            [allowFiltering]="false"
            [allowReordering]="false"
            [allowResizing]="false"
            [autoFit]="true"
            [isPrimaryKey]="true"
            [visible]="false"
            field="id"
            headerText="ID"
          ></e-column>
          <e-column
            [allowEditing]="false"
            [allowFiltering]="false"
            [allowReordering]="false"
            [allowResizing]="false"
            [autoFit]="true"
            [visible]="false"
            field="created_by"
            headerText="User ID"
          ></e-column>
          <e-column
            [autoFit]="true"
            [filter]="filterSettings"
            clipMode="EllipsisWithTooltip"
            field="address"
            headerText="Adresa"
          >
            <ng-template #template let-data>
              {{ data.address }}<br /><small class="text-muted"
            >Typ:
              {{
                data.ruian
                  ? 'konkrétní adresa s ČP'
                  :data.street_code
                    ? 'celá ulice'
                    :data.city_part_code
                      ? 'celá čtvrť'
                      :data.city_code
                        ? 'celé město'
                        :data.district_code
                          ? 'celý okres'
                          :data.region_code
                            ? 'celý kraj'
                            :data.state
                              ? 'celý stát'
                              :null
              }}</small
            >
            </ng-template>
          </e-column>
          <e-column
            [autoFit]="true"
            [filter]="filterSettings"
            clipMode="EllipsisWithTooltip"
            field="descriptive"
            headerText="Popis"
          >
            <ng-template #template let-data>
              {{
                data.descriptive && data.descriptive.length > 0
                  ? (data.descriptive | truncateText: 200)
                  :''
              }}
            </ng-template>
          </e-column>
          <e-column
            [autoFit]="true"
            [filter]="filterSettings"
            clipMode="EllipsisWithTooltip"
            field="net_packages"
            headerText="NET tarify"
          >
            <ng-template #template let-data>
              <ul
                *ngIf="
                                    data.net_packages &&
                                    data.net_packages.length > 0
                                "
                class="list-group scrollbar scrollbar-indigo thin m-0 p-0"
                style="
                                    max-height: 150px !important;
                                    overflow-y: auto !important;
                                "
              >
                <li
                  *ngFor="let netPackage of data.net_packages"
                  class="list-group-item p-1"
                >
                  <small>{{ netPackage.name }}</small
                  ><br /><small class="text-muted">{{
                    data.provider
                  }}</small>
                </li>
              </ul>
            </ng-template>
          </e-column>
          <e-column
            [autoFit]="true"
            [filter]="filterSettings"
            clipMode="EllipsisWithTooltip"
            field="tv_services"
            headerText="TV tarify"
          >
            <ng-template #template let-data>
              <ul
                *ngIf="
                                    data.tv_services &&
                                    data.tv_services.length > 0
                                "
                class="list-group scrollbar scrollbar-indigo thin m-0 p-0"
                style="
                                    max-height: 150px !important;
                                    overflow-y: auto !important;
                                "
              >
                <li
                  *ngFor="let tvService of data.tv_services"
                  class="list-group-item p-1"
                >
                  <small>{{ tvService.name }}</small
                  ><br /><small class="text-muted">{{
                    data.provider
                  }}</small>
                </li>
              </ul>
            </ng-template>
          </e-column>
          <e-column
            [autoFit]="true"
            [filter]="filterSettings"
            clipMode="EllipsisWithTooltip"
            field="creator_str"
            headerText="Vytvořil"
          >
            <ng-template #template let-data>
              <div>
                <img
                  *ngIf="data.creator_img"
                  alt="profilový obrázek"
                  class="img-fluid rounded-circle avatar-xs mr-1"
                  src="assets/img{{ data.creator_img }}"
                />
                <span>{{ data.creator_str }}</span>
              </div>
            </ng-template>
          </e-column>
          <e-column
            [allowEditing]="false"
            [autoFit]="true"
            [filter]="filterDate"
            [format]="dateFormat"
            clipMode="EllipsisWithTooltip"
            editType="datepickeredit"
            field="created_date"
            headerText="Vytvořeno"
            textAlign="Right"
            type="date"
          ></e-column>
          <e-column
            [allowEditing]="false"
            [autoFit]="true"
            [filter]="filterDate"
            [format]="dateFormat"
            clipMode="EllipsisWithTooltip"
            editType="datepickeredit"
            field="updated_date"
            headerText="Naposledy upraveno"
            textAlign="Right"
            type="date"
          ></e-column>
          <e-column
            [allowEditing]="false"
            [autoFit]="true"
            [filter]="filterDate"
            [format]="dateFormat"
            clipMode="EllipsisWithTooltip"
            editType="datepickeredit"
            field="deleted_date"
            headerText="Smazáno"
            textAlign="Right"
            type="date"
          ></e-column>
        </e-columns>
        <e-aggregates>
          <e-aggregate>
            <e-columns>
              <e-column
                [format]="'N'"
                field="address"
                type="Count"
              >
                <ng-template #footerTemplate let-data>
                  Celkem řádků: {{ data.Count }}
                </ng-template
                >
              </e-column>
            </e-columns>
          </e-aggregate>
        </e-aggregates>
      </ejs-grid>
    </div>
  </div>
</div>
