<div class="row d-flex justify-content-center modalWrapper">
  <ejs-dialog
    #confirmationDelete
    [buttons]="buttonsDelete"
    [content]="'Opravdu chcete stornovat tuto žádanku?'"
    [header]="'Smazání žádanky'"
    [isModal]="true"
    [showCloseIcon]="true"
    [visible]="false"
    width="250px"
  ></ejs-dialog>
  <ejs-dialog
    #confirmationDecline
    [buttons]="buttonsDecline"
    [content]="'Opravdu chcete zamítnout tuto žádanku?'"
    [header]="'Zamítnutí žádanky'"
    [isModal]="true"
    [showCloseIcon]="true"
    [visible]="false"
    width="250px"
  ></ejs-dialog>
  <ejs-dialog
    #confirmationConfirm
    [buttons]="buttonsConfirm"
    [content]="'Opravdu chcete schválit tuto žádanku?'"
    [header]="'Schválení žádanky'"
    [isModal]="true"
    [showCloseIcon]="true"
    [visible]="false"
    width="250px"
  ></ejs-dialog>
  <app-vacation-form
    (vacationFormDialogState)="
            renderVacationFormDialog = $event;
            isCreate = $event;
            isUpdate = $event;
            isCopy = $event;
            gridLogs?.clearRowSelection();
            disableToolBarBtn()
        "
    [isCopy]="isCopy"
    [isCreate]="isCreate"
    [isUpdate]="isUpdate"
    [isVisible]="renderVacationFormDialog"
    [vacation]="vacation && (isUpdate || isCopy) ? vacation : null"
  ></app-vacation-form>
</div>
<div class="row">
  <div class="col-12 col-md-8 col-lg-9 p-2">
    <ejs-tab
      #vacationTab
      (created)="onCreate()"
      (selected)="select($event)"
      (selecting)="preventSwipe($event)"
      enablePersistence="true"
      height="auto"
      heightAdjustMode="Content"
      id="vacationTab"
    >
      <e-tabitems>
        <e-tabitem [header]="headerText[0]">
          <ng-template #content>
            <div class="e-card">
              <ng-container *ngIf="vacation && !loadingVacation">
                <div
                  class="e-card-header stylish-color-dark p-2"
                >
                  <div class="e-card-header-caption">
                    <div
                      class="e-card-header-title text-white"
                    >
                      <strong
                        [className]="
                                                    vacation.status ===
                                                    'confirmed'
                                                        ? 'h4-responsive m-0 p-0 text-success'
                                                        : vacation.status ===
                                                          'declined'
                                                        ? 'h4-responsive m-0 p-0 text-danger'
                                                        : vacation.status ===
                                                          'deleted'
                                                        ? 'h4-responsive m-0 p-0 text-danger'
                                                        : 'h4-responsive m-0 p-0 text-warning'
                                                "
                        class="m-0 p-0"
                      >Žádanka:
                        {{ vacation.creator.fullname }}
                        -
                        {{
                          vacation.category.name
                        }}</strong
                      ><br />
                      <strong
                        *ngIf="
                                                    vacation.status ===
                                                    'confirmed'
                                                "
                        class="e-badge e-badge-success"
                      >schváleno</strong
                      >
                      <strong
                        *ngIf="
                                                    vacation.status ===
                                                    'declined'
                                                "
                        class="e-badge e-badge-danger"
                      >zamítnuto</strong
                      >
                      <strong
                        *ngIf="
                                                    vacation.status ===
                                                    'deleted'
                                                "
                        class="e-badge e-badge-danger"
                      >staženo</strong
                      >
                      <strong
                        *ngIf="
                                                    vacation.status === 'queue'
                                                "
                        class="e-badge e-badge-warning"
                      >ve frontě</strong
                      >
                    </div>
                  </div>
                </div>
                <div class="row stylish-color-dark p-2">
                  <div
                    aria-label="Vacation toolbar"
                    class="col-12 text-right"
                    role="toolbar"
                  >
                    <div
                      class="e-btn-group mr-1"
                      role="group"
                    >
                      <button
                        (click)="
                                                    confirmationConfirm.show()
                                                "
                        *ngIf="
                                                    vacation &&
                                                    (vacation.creator
                                                        .boss_id ===
                                                        currentUser.id ||
                                                        (currentUser.rule &&
                                                            currentUser.rule
                                                                .is_admin)) &&
                                                    !vacation.deleted_date &&
                                                    !vacation.confirm &&
                                                    !vacation.decline
                                                "
                        cssClass="e-btn e-success btn-p-zero e-small"
                        ejs-button
                        type="button"
                      >
                        <i
                          aria-hidden="true"
                          class="fa-light fa-check mr-1"
                        ></i>
                        <span class="d-none d-lg-inline"
                        >schválit</span
                        >
                      </button>
                      <button
                        (click)="
                                                    confirmationDecline.show()
                                                "
                        *ngIf="
                                                    vacation &&
                                                    (vacation.creator
                                                        .boss_id ===
                                                        currentUser.id ||
                                                        (currentUser.rule &&
                                                            currentUser.rule
                                                                .is_admin)) &&
                                                    !vacation.deleted_date &&
                                                    !vacation.confirm &&
                                                    !vacation.decline
                                                "
                        cssClass="e-btn e-danger btn-p-zero e-small"
                        ejs-button
                        type="button"
                      >
                        <i
                          aria-hidden="true"
                          class="fa-light fa-times mr-lg-1"
                        ></i>
                        <span class="d-none d-lg-inline"
                        >zamítnout</span
                        >
                      </button>
                    </div>
                    <div class="e-btn-group" role="group">
                      <button
                        (click)="actionEditBegin()"
                        *ngIf="
                                                    currentUser &&
                                                    vacation &&
                                                    (
                                                        (
                                                            vacation.created_by === currentUser.id &&
                                                            !vacation.deleted_date &&
                                                            vacation.unixStartTime >= todayUnix &&
                                                            !vacation.confirm &&
                                                            !vacation.decline
                                                        ) ||
                                                        (
                                                            currentUser.rule &&
                                                            currentUser.rule.is_admin
                                                        ) ||
                                                        vacation.creator.boss_id === currentUser.id
                                                    )
                                                "
                        cssClass="e-btn e-warning btn-p-zero e-small"
                        ejs-button
                        type="button"
                      >
                        <i
                          aria-hidden="true"
                          class="fa-light fa-pencil mr-lg-1"
                        ></i>
                        <span class="d-none d-lg-inline"
                        >upravit</span
                        >
                      </button>
                      <button
                        (click)="
                                                    confirmationDelete.show()
                                                "
                        *ngIf="
                                                    currentUser &&
                                                    vacation &&
                                                    ((vacation.created_by ===
                                                        currentUser.id &&
                                                        !vacation.deleted_date &&
                                                        vacation.unixStartTime >=
                                                            todayUnix &&
                                                        !vacation.confirm &&
                                                        !vacation.decline) ||
                                                        (currentUser.rule &&
                                                            currentUser.rule
                                                                .is_admin) ||
                                                        vacation.creator
                                                            .boss_id ===
                                                            currentUser.id)
                                                "
                        cssClass="e-btn e-danger btn-p-zero e-small"
                        ejs-button
                        type="button"
                      >
                        <i
                          aria-hidden="true"
                          class="fa-light fa-trash mr-lg-1"
                        ></i>
                        <span class="d-none d-lg-inline"
                        >smazat</span
                        >
                      </button>
                    </div>
                  </div>
                </div>
                <div class="e-card-content">
                  <div class="row mt-3 mb-2 p-2">
                    <div
                      class="col-12"
                      style="overflow-x: auto"
                    >
                      <table class="table table-sm">
                        <tr class="border-bottom">
                          <th>Důvod:</th>
                          <td class="text-right">
                            {{
                              (currentUser.rule &&
                                (currentUser
                                    .rule
                                    .is_admin ||
                                  currentUser
                                    .rule
                                    .is_vacation_admin)) ||
                              currentUser.id===
                              vacation.creator
                                .boss.id ||
                              currentUser.id===
                              vacation.creator
                                .boss_id ||
                              currentUser.id===
                              vacation.created_by
                                ? vacation.reason
                                :'*****'
                            }}
                          </td>
                        </tr>
                        <tr class="border-bottom">
                          <th>Vytvořeno:</th>
                          <td class="text-right">
                            {{
                              vacation.created_date
                                | dateFormat
                                : 'DD.MM.YYYY HH:mm'
                            }}
                            <span
                              *ngIf="
                                                                vacation.unixCreatedTime
                                                            "
                              class="text-muted"
                            >({{
                                vacation.unixCreatedTime
                                  | timeago
                                  : live
                              }})</span
                            >
                          </td>
                        </tr>
                        <tr class="border-bottom">
                          <th>Typ žádosti:</th>
                          <td class="text-right"
                          ><span
                            *ngIf="
                                                                vacation
                                                                    .category
                                                                    .name
                                                            "
                          >{{
                              vacation
                                .category
                                .name
                            }}</span
                          >
                          </td>
                        </tr>
                        <tr class="border-bottom">
                          <th>Termín:</th>
                          <td class="text-right">
                            {{
                              vacation.start_at
                                | dateFormat
                                : 'DD.MM.YYYY HH:mm'
                            }}
                            -
                            {{
                              vacation.end_at
                                | dateFormat
                                : 'DD.MM.YYYY HH:mm'
                            }}
                          </td>
                        </tr>
                        <tr class="border-bottom">
                          <th
                          >Počet pracovních dní:
                          </th>
                          <td class="text-right">
                            <ng-container
                              *ngIf="
                                                                vacation.workdays
                                                            "
                            >{{
                                vacation.workdays
                              }}
                            </ng-container
                            >
                          </td>
                        </tr>
                      </table>
                    </div>
                  </div>
                  <hr />
                  <div
                    *ngIf="
                                            matchedUser &&
                                            workdays &&
                                            vacation.category_id === 1
                                        "
                    class="row"
                  >
                    <div class="col-12 p-2">
                      <div
                        *ngIf="
                                                    matchedUser.vacation_fond +
                                                        (vacation.workdays &&
                                                        vacation.confirm
                                                            ? vacation.workdays
                                                            : 0) -
                                                        (statsVacation
                                                            ? statsVacation
                                                            : 0) -
                                                        workdays ===
                                                    0
                                                "
                        class="alert alert-warning p-1 text-center"
                        role="alert"
                      >
                        <i
                          aria-hidden="true"
                          class="fa-light fa-lg fa-exclamation-triangle mr-2"
                        ></i
                        >Touto žádankou bude
                        <strong
                        >vyčerpán fond
                          dovolené</strong
                        >.
                      </div>
                      <div
                        *ngIf="
                                                    matchedUser.vacation_fond +
                                                        (vacation.workdays &&
                                                        vacation.confirm
                                                            ? vacation.workdays
                                                            : 0) -
                                                        (statsVacation
                                                            ? statsVacation
                                                            : 0) -
                                                        workdays <
                                                    0
                                                "
                        class="alert alert-danger p-1 text-center"
                        role="alert"
                      >
                        <i
                          aria-hidden="true"
                          class="fa-light fa-lg fa-exclamation-triangle mr-2"
                        ></i
                        >Touto žádankou bude
                        <strong
                        >přečerpán fond
                          dovolené</strong
                        >.
                      </div>
                    </div>
                    <div class="col-12 mt-1 text-right">
                      <h6 class="text-muted">
                        Fond dovolené uživatele:
                        {{ matchedUser.secondname }}
                        {{ matchedUser.firstname }}
                      </h6>
                      <ng-container
                        *ngIf="
                                                    matchedUser.vacation_fond +
                                                        (vacation.workdays &&
                                                        vacation.confirm
                                                            ? vacation.workdays
                                                            : 0) >
                                                    0
                                                "
                      >
                        <ejs-progressbar
                          #vacationsPercentage
                          (valueChanged)="
                                                        changedVacBar($event)
                                                    "
                          [animation]="animation"
                          [labelStyle]="labelStyle"
                          [showProgressValue]="
                                                        showProgressValue
                                                    "
                          [value]="
                                                        ((statsVacation +
                                                            workdays) *
                                                            100) /
                                                        (matchedUser.vacation_fond +
                                                            (vacation.workdays &&
                                                            vacation.confirm
                                                                ? vacation.workdays
                                                                : 0))
                                                    "
                          height="50"
                          id="vacationsPercentage"
                          maximum="100"
                          minimum="0"
                          progressThickness="15"
                          trackThickness="15"
                          type="Linear"
                          width="100%"
                        ></ejs-progressbar>
                      </ng-container>
                    </div>
                    <div
                      class="col-12 text-right text-primary"
                    >
                      <span>zbývá ve fondu: </span>
                      <strong
                        [ngPlural]="
                                                    matchedUser.vacation_fond +
                                                    (vacation.workdays &&
                                                    vacation.confirm
                                                        ? vacation.workdays
                                                        : 0) -
                                                    statsVacation
                                                "
                      >
                        <ng-template ngPluralCase="=0.5"
                        >půl den dovolené
                        </ng-template>
                        <ng-template ngPluralCase="=1"
                        >{{
                            matchedUser.vacation_fond +
                            (vacation.workdays &&
                            vacation.confirm
                              ? vacation.workdays
                              :0) -
                            statsVacation
                              | number: '1.1-1'
                          }}
                          den dovolené
                        </ng-template>
                        <ng-template ngPluralCase="=2"
                        >{{
                            matchedUser.vacation_fond +
                            (vacation.workdays &&
                            vacation.confirm
                              ? vacation.workdays
                              :0) -
                            statsVacation
                              | number: '1.1-1'
                          }}
                          dny dovolené
                        </ng-template>
                        <ng-template ngPluralCase="=3"
                        >{{
                            matchedUser.vacation_fond +
                            (vacation.workdays &&
                            vacation.confirm
                              ? vacation.workdays
                              :0) -
                            statsVacation
                              | number: '1.1-1'
                          }}
                          dny dovolené
                        </ng-template>
                        <ng-template ngPluralCase="=4"
                        >{{
                            matchedUser.vacation_fond +
                            (vacation.workdays &&
                            vacation.confirm
                              ? vacation.workdays
                              :0) -
                            statsVacation
                              | number: '1.1-1'
                          }}
                          dny dovolené
                        </ng-template>
                        <ng-template
                          ngPluralCase="other"
                        >{{
                            matchedUser.vacation_fond +
                            (vacation.workdays &&
                            vacation.confirm
                              ? vacation.workdays
                              :0) -
                            statsVacation
                              | number: '1.1-1'
                          }}
                          dní dovolené
                        </ng-template>
                      </strong>
                    </div>
                    <div
                      class="col-12 text-right text-danger"
                    >
                      <span>žádanka odečte: </span>
                      <strong
                        [ngPlural]="workdays"
                        class="mr-2"
                      >
                        <ng-template ngPluralCase="=0.5"
                        >půl den dovolené
                        </ng-template>
                        <ng-template ngPluralCase="=1"
                        >{{
                            workdays
                              | number: '1.1-1'
                          }}
                          den dovolené
                        </ng-template>
                        <ng-template ngPluralCase="=2"
                        >{{
                            workdays
                              | number: '1.1-1'
                          }}
                          dny dovolené
                        </ng-template>
                        <ng-template ngPluralCase="=3"
                        >{{
                            workdays
                              | number: '1.1-1'
                          }}
                          dny dovolené
                        </ng-template>
                        <ng-template ngPluralCase="=4"
                        >{{
                            workdays
                              | number: '1.1-1'
                          }}
                          dny dovolené
                        </ng-template>
                        <ng-template
                          ngPluralCase="other"
                        >{{
                            workdays
                              | number: '1.1-1'
                          }}
                          dní dovolené
                        </ng-template>
                      </strong>
                      <small
                        *ngIf="workhours"
                        [ngPlural]="workhours"
                      >
                        <ng-template ngPluralCase="=1"
                        >({{
                            workhours
                              | number: '1.1-1'
                          }}
                          hodina)
                        </ng-template>
                        <ng-template ngPluralCase="=2"
                        >({{
                            workhours
                              | number: '1.1-1'
                          }}
                          hodiny)
                        </ng-template>
                        <ng-template ngPluralCase="=3"
                        >({{
                            workhours
                              | number: '1.1-1'
                          }}
                          hodiny)
                        </ng-template>
                        <ng-template ngPluralCase="=4"
                        >({{
                            workhours
                              | number: '1.1-1'
                          }}
                          hodiny)
                        </ng-template>
                        <ng-template
                          ngPluralCase="other"
                        >
                          ({{
                            workhours
                              | number: '1.1-1'
                          }}
                          hodin)
                        </ng-template>
                      </small>
                    </div>
                    <div
                      class="col-12 text-right text-warning"
                    >
                                            <span
                                            >stav fondu po schválenížádanky:
                                            </span>
                      <strong
                        [ngPlural]="
                                                    matchedUser.vacation_fond +
                                                    (vacation.workdays &&
                                                    vacation.confirm
                                                        ? vacation.workdays
                                                        : 0) -
                                                    statsVacation -
                                                    workdays
                                                "
                      >
                        <ng-template ngPluralCase="=0.5"
                        >půl den dovolené
                        </ng-template>
                        <ng-template ngPluralCase="=1"
                        >{{
                            matchedUser.vacation_fond +
                            (vacation.workdays &&
                            vacation.confirm
                              ? vacation.workdays
                              :0) -
                            statsVacation -
                            workdays
                              | number: '1.1-1'
                          }}
                          den dovolené
                        </ng-template>
                        <ng-template ngPluralCase="=2"
                        >{{
                            matchedUser.vacation_fond +
                            (vacation.workdays &&
                            vacation.confirm
                              ? vacation.workdays
                              :0) -
                            statsVacation -
                            workdays
                              | number: '1.1-1'
                          }}
                          dny dovolené
                        </ng-template>
                        <ng-template ngPluralCase="=3"
                        >{{
                            matchedUser.vacation_fond +
                            (vacation.workdays &&
                            vacation.confirm
                              ? vacation.workdays
                              :0) -
                            statsVacation -
                            workdays
                              | number: '1.1-1'
                          }}
                          dny dovolené
                        </ng-template>
                        <ng-template ngPluralCase="=4"
                        >{{
                            matchedUser.vacation_fond +
                            (vacation.workdays &&
                            vacation.confirm
                              ? vacation.workdays
                              :0) -
                            statsVacation -
                            workdays
                              | number: '1.1-1'
                          }}
                          dny dovolené
                        </ng-template>
                        <ng-template
                          ngPluralCase="other"
                        >{{
                            matchedUser.vacation_fond +
                            (vacation.workdays &&
                            vacation.confirm
                              ? vacation.workdays
                              :0) -
                            statsVacation -
                            workdays
                              | number: '1.1-1'
                          }}
                          dní dovolené
                        </ng-template>
                      </strong>
                    </div>
                  </div>
                  <div
                    *ngIf="
                                            matchedUser &&
                                            workdays &&
                                            vacation.category_id === 5
                                        "
                    class="row"
                  >
                    <div class="col-12 p-2">
                      <div
                        *ngIf="
                                                    matchedUser.sickdays_fond +
                                                        workdays -
                                                        (statsSickDays
                                                            ? statsSickDays
                                                            : 0) -
                                                        workdays ===
                                                    0
                                                "
                        class="alert alert-warning p-1 text-center"
                        role="alert"
                      >
                        <i
                          aria-hidden="true"
                          class="fa-light fa-lg fa-exclamation-triangle mr-2"
                        ></i
                        >Touto žádankou bude
                        <strong
                        >vyčerpán fond
                          sickdays</strong
                        >.
                      </div>
                      <div
                        *ngIf="
                                                    matchedUser.sickdays_fond +
                                                        workdays -
                                                        (statsSickDays
                                                            ? statsSickDays
                                                            : 0) -
                                                        workdays <
                                                    0
                                                "
                        class="alert alert-danger p-1 text-center"
                        role="alert"
                      >
                        <i
                          aria-hidden="true"
                          class="fa-light fa-lg fa-exclamation-triangle mr-2"
                        ></i
                        >Touto žádankou bude
                        <strong
                        >přečerpán fond
                          sickdays</strong
                        >.
                      </div>
                    </div>
                    <div
                      *ngIf="
                                                matchedUser.sickdays_fond +
                                                    vacation.workdays >=
                                                0
                                            "
                      class="col-12 mt-1 text-right"
                    >
                      <h6 class="text-muted">
                        Fond sickdays uživatele:
                        {{ matchedUser.secondname }}
                        {{ matchedUser.firstname }}
                      </h6>
                      <ng-container
                        *ngIf="
                                                    matchedUser.sickdays_fond +
                                                        vacation.workdays >
                                                    0
                                                "
                      >
                        <ejs-progressbar
                          #sickDaysPercentage
                          (valueChanged)="
                                                        changedSickBar($event)
                                                    "
                          [animation]="animation"
                          [labelStyle]="labelStyle"
                          [showProgressValue]="
                                                        showProgressValue
                                                    "
                          [value]="
                                                        ((statsSickDays +
                                                            workdays) *
                                                            100) /
                                                        (matchedUser.sickdays_fond +
                                                            vacation.workdays)
                                                    "
                          height="50"
                          id="sickDaysPercentage"
                          maximum="100"
                          minimum="0"
                          progressThickness="15"
                          trackThickness="15"
                          type="Linear"
                          width="100%"
                        ></ejs-progressbar>
                      </ng-container>
                    </div>
                    <div
                      class="col-12 text-right text-primary"
                    >
                      <span>zbývá ve fondu: </span>
                      <strong
                        [ngPlural]="
                                                    matchedUser.sickdays_fond +
                                                    vacation.workdays -
                                                    statsSickDays
                                                "
                      >
                        <ng-template ngPluralCase="=0.5"
                        >půl den sickdays
                        </ng-template>
                        <ng-template ngPluralCase="=1"
                        >{{
                            matchedUser.sickdays_fond +
                            vacation.workdays -
                            statsSickDays
                              | number: '1.1-1'
                          }}
                          den sickdays
                        </ng-template>
                        <ng-template ngPluralCase="=2"
                        >{{
                            matchedUser.sickdays_fond +
                            vacation.workdays -
                            statsSickDays
                              | number: '1.1-1'
                          }}
                          dny sickdays
                        </ng-template>
                        <ng-template ngPluralCase="=3"
                        >{{
                            matchedUser.sickdays_fond +
                            vacation.workdays -
                            statsSickDays
                              | number: '1.1-1'
                          }}
                          dny sickdays
                        </ng-template>
                        <ng-template ngPluralCase="=4"
                        >{{
                            matchedUser.sickdays_fond +
                            vacation.workdays -
                            statsSickDays
                              | number: '1.1-1'
                          }}
                          dny sickdays
                        </ng-template>
                        <ng-template
                          ngPluralCase="other"
                        >{{
                            matchedUser.sickdays_fond +
                            vacation.workdays -
                            statsSickDays
                              | number: '1.1-1'
                          }}
                          dní sickdays
                        </ng-template>
                      </strong>
                    </div>
                    <div
                      class="col-12 text-right text-danger"
                    >
                      <span>žádanka odečte: </span>
                      <strong
                        [ngPlural]="workdays"
                        class="mr-2"
                      >
                        <ng-template ngPluralCase="=0.5"
                        >půl den sickdays
                        </ng-template>
                        <ng-template ngPluralCase="=1"
                        >{{
                            workdays
                              | number: '1.1-1'
                          }}
                          den sickdays
                        </ng-template>
                        <ng-template ngPluralCase="=2"
                        >{{
                            workdays
                              | number: '1.1-1'
                          }}
                          dny sickdays
                        </ng-template>
                        <ng-template ngPluralCase="=3"
                        >{{
                            workdays
                              | number: '1.1-1'
                          }}
                          dny sickdays
                        </ng-template>
                        <ng-template ngPluralCase="=4"
                        >{{
                            workdays
                              | number: '1.1-1'
                          }}
                          dny sickdays
                        </ng-template>
                        <ng-template
                          ngPluralCase="other"
                        >{{
                            workdays
                              | number: '1.1-1'
                          }}
                          dní sickdays
                        </ng-template>
                      </strong>
                      <small
                        *ngIf="workhours"
                        [ngPlural]="workhours"
                      >
                        <ng-template ngPluralCase="=1"
                        >({{
                            workhours
                              | number: '1.1-1'
                          }}
                          hodina)
                        </ng-template>
                        <ng-template ngPluralCase="=2"
                        >({{
                            workhours
                              | number: '1.1-1'
                          }}
                          hodiny)
                        </ng-template>
                        <ng-template ngPluralCase="=3"
                        >({{
                            workhours
                              | number: '1.1-1'
                          }}
                          hodiny)
                        </ng-template>
                        <ng-template ngPluralCase="=4"
                        >({{
                            workhours
                              | number: '1.1-1'
                          }}
                          hodiny)
                        </ng-template>
                        <ng-template
                          ngPluralCase="other"
                        >
                          ({{
                            workhours
                              | number: '1.1-1'
                          }}
                          hodin)
                        </ng-template>
                      </small>
                    </div>
                    <div
                      class="col-12 text-right text-warning"
                    >
                                            <span
                                            >stav fondu po schválenížádanky:
                                            </span>
                      <strong
                        [ngPlural]="
                                                    matchedUser.sickdays_fond +
                                                    vacation.workdays -
                                                    statsSickDays -
                                                    workdays
                                                "
                      >
                        <ng-template ngPluralCase="=0.5"
                        >půl den sickdays
                        </ng-template>
                        <ng-template ngPluralCase="=1"
                        >{{
                            matchedUser.sickdays_fond +
                            vacation.workdays -
                            statsSickDays -
                            workdays
                              | number: '1.1-1'
                          }}
                          den sickdays
                        </ng-template>
                        <ng-template ngPluralCase="=2"
                        >{{
                            matchedUser.sickdays_fond +
                            vacation.workdays -
                            statsSickDays -
                            workdays
                              | number: '1.1-1'
                          }}
                          dny sickdays
                        </ng-template>
                        <ng-template ngPluralCase="=3"
                        >{{
                            matchedUser.sickdays_fond +
                            vacation.workdays -
                            statsSickDays -
                            workdays
                              | number: '1.1-1'
                          }}
                          dny sickdays
                        </ng-template>
                        <ng-template ngPluralCase="=4"
                        >{{
                            matchedUser.sickdays_fond +
                            vacation.workdays -
                            statsSickDays -
                            workdays
                              | number: '1.1-1'
                          }}
                          dny sickdays
                        </ng-template>
                        <ng-template
                          ngPluralCase="other"
                        >{{
                            matchedUser.sickdays_fond +
                            vacation.workdays -
                            statsSickDays -
                            workdays
                              | number: '1.1-1'
                          }}
                          dní sickdays
                        </ng-template>
                      </strong>
                    </div>
                  </div>
                </div>
              </ng-container>
              <ng-container *ngIf="!vacation || loadingVacation">
                <div class="e-card-content text-center">
                  <app-loader></app-loader>
                </div>
              </ng-container>
            </div>
          </ng-template>
        </e-tabitem>
        <e-tabitem [header]="headerText[1]">
          <ng-template #content>
            <div class="e-card">
              <div class="e-card-header">
                <h5 class="e-card-title">
                  <i class="fa-light fa-history mr-2"></i
                  >Historie změn
                </h5>
              </div>
              <div class="e-card-content">
                <ejs-toolbar
                  id="filterToolbar"
                  overflowMode="Scrollable"
                  scrollStep="500"
                >
                  <e-items>
                    <e-item
                      [cssClass]="'statusFilter'"
                      tooltipText="Přednastavené hledání"
                      type="input"
                    >
                      <ng-template #template>
                        <ejs-dropdownlist
                          #viewFilter
                          (change)="
                                                        onStatusFilterChange(
                                                            $event
                                                        )
                                                    "
                          [dataSource]="
                                                        categoryFilterData
                                                    "
                          [width]="500"
                          placeholder="Vyberte přednastavený pohled..."
                        ></ejs-dropdownlist>
                      </ng-template>
                    </e-item>
                    <e-item type="Separator"></e-item>
                    <e-item
                      [cssClass]="'fulltextFilter'"
                      tooltipText="Text pro vyhledávání"
                      type="input"
                    >
                      <ng-template #template>
                        <ejs-textbox
                          #searchtext
                          [width]="500"
                          class="mr-2"
                          placeholder="Vyhledat..."
                        ></ejs-textbox>
                        <button
                          (click)="search()"
                          class="e-btn e-info btn-p-zero e-small"
                        >
                          <i
                            class="fa-light fa-lg fa-search mr-1"
                          ></i
                          ><span
                          class="d-none d-lg-inline"
                        >vyhledat</span
                        >
                        </button>
                        <button
                          (click)="cancelBtnClick()"
                          class="e-btn e-danger e-outline btn-p-zero e-small"
                        >
                          <i
                            class="fa-light fa-lg fa-times"
                          ></i>
                        </button>
                      </ng-template>
                    </e-item>
                  </e-items>
                </ejs-toolbar>
                <div class="control-section">
                  <div
                    class="scroll_wrapper1"
                    id="scroll_wrapper1"
                  >
                    <div class="scroll_div"></div>
                  </div>
                  <div
                    class="scroll_wrapper2"
                    id="scroll_wrapper2"
                  >
                    <div class="grid_parent">
                      <ejs-grid
                        #vacationLogsGrid
                        (created)="onHistoryCreated()"
                        (dataBound)="
                                                    onHistoryDataBound()
                                                "
                        (load)="onHistoryLoad()"
                        (loadeddata)="
                                                    setInitialHistoryGridFiltering()
                                                "
                        (rowDataBound)="
                                                    rowHistoryDataBound($event)
                                                "
                        (rowDeselected)="
                                                    rowHistorySelected()
                                                "
                        (rowSelected)="
                                                    rowHistorySelected()
                                                "
                        (toolbarClick)="
                                                    toolbarHistoryClick($event)
                                                "
                        [allowExcelExport]="true"
                        [allowFiltering]="true"
                        [allowMultiSorting]="true"
                        [allowPdfExport]="false"
                        [allowSelection]="true"
                        [allowSorting]="true"
                        [dataSource]="dataHistory"
                        [enableHover]="false"
                        [enablePersistence]="false"
                        [enableVirtualization]="true"
                        [filterSettings]="
                                                    filterSettings
                                                "
                        [groupSettings]="groupOptions"
                        [pageSettings]="pageOptions"
                        [selectionSettings]="
                                                    selectionSettings
                                                "
                        [sortSettings]="
                                                    sortHistoryOptions
                                                "
                        [toolbar]="toolbarHistory"
                        allowGrouping="true"
                        allowReordering="true"
                        allowResizing="true"
                        allowTextWrap="true"
                        height="100%"
                        id="vacationLogsGrid"
                        locale="cs"
                        rowHeight="38"
                        showColumnMenu="true"
                        width="100%"
                      >
                        <e-columns>
                          <e-column
                            [allowEditing]="false"
                            [allowFiltering]="false"
                            [allowReordering]="
                                                            false
                                                        "
                            [allowResizing]="false"
                            [allowSorting]="false"
                            [autoFit]="true"
                            type="checkbox"
                          ></e-column>
                          <e-column
                            [allowEditing]="false"
                            [allowFiltering]="true"
                            [allowReordering]="
                                                            false
                                                        "
                            [allowResizing]="false"
                            [autoFit]="true"
                            [isPrimaryKey]="true"
                            [visible]="false"
                            field="id"
                            headerText="Log ID"
                          >
                          </e-column>
                          <e-column
                            [allowEditing]="false"
                            [allowFiltering]="true"
                            [allowReordering]="
                                                            false
                                                        "
                            [allowResizing]="false"
                            [autoFit]="true"
                            [visible]="true"
                            field="vacation_id"
                            headerText="Vacation ID"
                          >
                            <ng-template
                              #template
                              let-data
                            >
                              <a
                                routerLink="/vacation/detail/{{
                                                                    data.vacation_id
                                                                }}"
                              >{{
                                  data.vacation_id
                                }}</a
                              >
                            </ng-template>
                          </e-column>
                          <e-column
                            [allowEditing]="false"
                            [allowFiltering]="true"
                            [allowReordering]="
                                                            false
                                                        "
                            [allowResizing]="false"
                            [autoFit]="true"
                            [visible]="false"
                            field="created_by"
                            headerText="Creator ID"
                          >
                            <ng-template
                              #template
                              let-data
                            >
                              <a
                                routerLink="/users/detail/{{
                                                                    data.created_by
                                                                }}"
                              >{{
                                  data.created_by
                                }}</a
                              >
                            </ng-template>
                          </e-column>
                          <e-column
                            [allowEditing]="false"
                            [allowFiltering]="true"
                            [allowReordering]="
                                                            false
                                                        "
                            [allowResizing]="false"
                            [autoFit]="true"
                            [visible]="false"
                            field="updated_by"
                            headerText="Editor ID"
                          >
                            <ng-template
                              #template
                              let-data
                            >
                              <a
                                routerLink="/users/detail/{{
                                                                    data.updated_by
                                                                }}"
                              >{{
                                  data.updated_by
                                }}</a
                              >
                            </ng-template>
                          </e-column>
                          <e-column
                            [allowEditing]="false"
                            [allowFiltering]="true"
                            [allowReordering]="
                                                            false
                                                        "
                            [allowResizing]="false"
                            [autoFit]="true"
                            [visible]="false"
                            field="deleted_by"
                            headerText="Remover ID"
                          >
                            <ng-template
                              #template
                              let-data
                            >
                              <a
                                routerLink="/users/detail/{{
                                                                    data.deleted_by
                                                                }}"
                              >{{
                                  data.deleted_by
                                }}</a
                              >
                            </ng-template>
                          </e-column>
                          <e-column
                            [allowEditing]="false"
                            [allowFiltering]="true"
                            [allowReordering]="
                                                            false
                                                        "
                            [allowResizing]="false"
                            [autoFit]="true"
                            [visible]="false"
                            field="confirmed_by"
                            headerText="Confirmer ID"
                          >
                            <ng-template
                              #template
                              let-data
                            >
                              <a
                                routerLink="/users/detail/{{
                                                                    data.confirmed_by
                                                                }}"
                              >{{
                                  data.confirmed_by
                                }}</a
                              >
                            </ng-template>
                          </e-column>
                          <e-column
                            [allowEditing]="false"
                            [allowFiltering]="true"
                            [allowReordering]="
                                                            false
                                                        "
                            [allowResizing]="false"
                            [autoFit]="true"
                            [visible]="false"
                            field="declined_by"
                            headerText="Decliner ID"
                          >
                            <ng-template
                              #template
                              let-data
                            >
                              <a
                                routerLink="/users/detail/{{
                                                                    data.declined_by
                                                                }}"
                              >{{
                                  data.declined_by
                                }}</a
                              >
                            </ng-template>
                          </e-column>
                          <e-column
                            [allowEditing]="false"
                            [allowFiltering]="true"
                            [allowReordering]="
                                                            false
                                                        "
                            [allowResizing]="false"
                            [autoFit]="true"
                            [visible]="false"
                            field="category_id"
                            headerText="Category ID"
                          ></e-column>
                          <e-column
                            [allowEditing]="false"
                            [allowFiltering]="true"
                            [allowReordering]="
                                                            false
                                                        "
                            [allowResizing]="false"
                            [autoFit]="true"
                            [visible]="false"
                            field="vacations_type"
                            headerText="Type ID"
                          ></e-column>
                          <e-column
                            [allowEditing]="false"
                            [autoFit]="true"
                            [filter]="
                                                            filterSettings
                                                        "
                            clipMode="EllipsisWithTooltip"
                            field="type"
                            headerText="Typ logu"
                          >
                            <ng-template
                              #template
                              let-data
                            ><span
                              *ngIf="
                                                                    data.vacations_type ===
                                                                    1
                                                                "
                              class="e-badge e-badge-success e-badge-pill"
                            >{{
                                data.type
                              }}</span
                            >
                              <span
                                *ngIf="
                                                                    data.vacations_type ===
                                                                    2
                                                                "
                                class="e-badge e-badge-warning e-badge-pill"
                              >{{
                                  data.type
                                }}</span
                              >
                              <span
                                *ngIf="
                                                                    data.vacations_type ===
                                                                    3
                                                                "
                                class="e-badge e-badge-danger e-badge-pill"
                              >{{
                                  data.type
                                }}</span
                              >
                            </ng-template>
                          </e-column>
                          <e-column
                            [allowEditing]="false"
                            [autoFit]="true"
                            [filter]="
                                                            filterSettings
                                                        "
                            clipMode="EllipsisWithTooltip"
                            field="status"
                            headerText="Stav"
                            textAlign="Center"
                          >
                            <ng-template
                              #template
                              let-data
                            ><span
                              *ngIf="
                                                                    data.status ===
                                                                    'schváleno'
                                                                "
                              class="e-badge e-badge-success"
                            >schváleno</span
                            >
                              <span
                                *ngIf="
                                                                    data.status ===
                                                                    'zamítnuto'
                                                                "
                                class="e-badge e-badge-danger"
                              >zamítnuto</span
                              >
                              <span
                                *ngIf="
                                                                    data.status ===
                                                                    'staženo'
                                                                "
                                class="e-badge e-badge-danger"
                              >staženo</span
                              >
                              <span
                                *ngIf="
                                                                    data.status ===
                                                                    've frontě'
                                                                "
                                class="e-badge e-badge-warning"
                              >ve frontě</span
                              >
                            </ng-template>
                          </e-column>
                          <e-column
                            [autoFit]="true"
                            [edit]="{
                                                            params: {
                                                                decimals: 1,
                                                                step: 0.5
                                                            }
                                                        }"
                            [filter]="
                                                            filterSettings
                                                        "
                            clipMode="EllipsisWithTooltip"
                            editType="numericedit"
                            field="workdays"
                            format="N1"
                            headerText="Pracovních dní"
                            textAlign="Right"
                          >
                          </e-column>
                          <e-column
                            [allowEditing]="false"
                            [autoFit]="true"
                            [filter]="
                                                            filterSettings
                                                        "
                            clipMode="EllipsisWithTooltip"
                            field="reason"
                            headerText="Důvod"
                          ></e-column>
                          <e-column
                            [allowEditing]="false"
                            [autoFit]="true"
                            [filter]="filterDate"
                            [format]="dateFormat"
                            clipMode="EllipsisWithTooltip"
                            editType="datepickeredit"
                            field="start_at"
                            headerText="Začátek"
                            textAlign="Right"
                            type="date"
                          ></e-column>
                          <e-column
                            [allowEditing]="false"
                            [autoFit]="true"
                            [filter]="filterDate"
                            [format]="dateFormat"
                            clipMode="EllipsisWithTooltip"
                            editType="datepickeredit"
                            field="end_at"
                            headerText="Konec"
                            textAlign="Right"
                            type="date"
                          ></e-column>
                          <e-column
                            [autoFit]="true"
                            [filter]="
                                                            filterSettings
                                                        "
                            clipMode="EllipsisWithTooltip"
                            editType="dropdownedit"
                            field="confirmer_str"
                            headerText="Schválil"
                          >
                            <ng-template
                              #template
                              let-data
                            >
                              <div
                                *ngIf="
                                                                    data.confirmed_by
                                                                "
                              >
                                <img
                                  alt="profilový obrázek"
                                  class="img-fluid rounded-circle avatar-xs mr-1"
                                  src="assets/img{{
                                                                        data.confirmer_img
                                                                    }}"
                                />
                                <a
                                  routerLink="/users/detail/{{
                                                                        data.confirmed_by
                                                                    }}"
                                >{{
                                    data.confirmer_str
                                  }}</a
                                >
                              </div>
                            </ng-template>
                          </e-column>
                          <e-column
                            [allowEditing]="false"
                            [autoFit]="true"
                            [filter]="filterDate"
                            [format]="dateFormat"
                            clipMode="EllipsisWithTooltip"
                            editType="datepickeredit"
                            field="confirm_at"
                            headerText="Schváleno"
                            textAlign="Right"
                            type="date"
                          ></e-column>
                          <e-column
                            [autoFit]="true"
                            [filter]="
                                                            filterSettings
                                                        "
                            clipMode="EllipsisWithTooltip"
                            editType="dropdownedit"
                            field="decliner_str"
                            headerText="Zamítl"
                          >
                            <ng-template
                              #template
                              let-data
                            >
                              <div
                                *ngIf="
                                                                    data.declined_by
                                                                "
                              >
                                <img
                                  alt="profilový obrázek"
                                  class="img-fluid rounded-circle avatar-xs mr-1"
                                  src="assets/img{{
                                                                        data.decliner_img
                                                                    }}"
                                />
                                <a
                                  routerLink="/users/detail/{{
                                                                        data.declined_by
                                                                    }}"
                                >{{
                                    data.decliner_str
                                  }}</a
                                >
                              </div>
                            </ng-template>
                          </e-column>
                          <e-column
                            [allowEditing]="false"
                            [autoFit]="true"
                            [filter]="filterDate"
                            [format]="dateFormat"
                            clipMode="EllipsisWithTooltip"
                            editType="datepickeredit"
                            field="decline_at"
                            headerText="Zamítnuto"
                            textAlign="Right"
                            type="date"
                          ></e-column>
                          <e-column
                            [autoFit]="true"
                            [filter]="
                                                            filterSettings
                                                        "
                            clipMode="EllipsisWithTooltip"
                            editType="dropdownedit"
                            field="creator_str"
                            headerText="Vytvořil"
                          >
                            <ng-template
                              #template
                              let-data
                            >
                              <div>
                                <img
                                  *ngIf="
                                                                        data.creator_img
                                                                    "
                                  alt="profilový obrázek"
                                  class="img-fluid rounded-circle avatar-xs mr-1"
                                  src="assets/img{{
                                                                        data.creator_img
                                                                    }}"
                                />
                                <a
                                  [routerLink]="[
                                                                        '/users/detail/',
                                                                        data.created_by
                                                                    ]"
                                >
                                  <strong>{{
                                      data.creator_str
                                    }}</strong>
                                </a>
                              </div>
                            </ng-template>
                          </e-column>
                          <e-column
                            [allowEditing]="false"
                            [autoFit]="true"
                            [filter]="filterDate"
                            [format]="dateFormat"
                            clipMode="EllipsisWithTooltip"
                            editType="datepickeredit"
                            field="created_date"
                            headerText="Vytvořeno"
                            textAlign="Right"
                            type="date"
                          ></e-column>
                          <e-column
                            [autoFit]="true"
                            [filter]="
                                                            filterSettings
                                                        "
                            clipMode="EllipsisWithTooltip"
                            editType="dropdownedit"
                            field="editor_str"
                            headerText="Upravil"
                          >
                            <ng-template
                              #template
                              let-data
                            >
                              <div
                                *ngIf="
                                                                    data.updated_by
                                                                "
                              >
                                <img
                                  alt="profilový obrázek"
                                  class="img-fluid rounded-circle avatar-xs mr-1"
                                  src="assets/img{{
                                                                        data.editor_img
                                                                    }}"
                                />
                                <a
                                  [routerLink]="[
                                                                        '/users/detail/',
                                                                        data.updated_by
                                                                    ]"
                                >
                                  <strong>{{
                                      data.editor_str
                                    }}</strong>
                                </a>
                              </div>
                            </ng-template>
                          </e-column>
                          <e-column
                            [allowEditing]="false"
                            [autoFit]="true"
                            [filter]="filterDate"
                            [format]="dateFormat"
                            clipMode="EllipsisWithTooltip"
                            editType="datepickeredit"
                            field="updated_date"
                            headerText="Naposledy upraveno"
                            textAlign="Right"
                            type="date"
                          ></e-column>
                          <e-column
                            [autoFit]="true"
                            [filter]="
                                                            filterSettings
                                                        "
                            clipMode="EllipsisWithTooltip"
                            editType="dropdownedit"
                            field="remover_str"
                            headerText="Smazal"
                          >
                            <ng-template
                              #template
                              let-data
                            >
                              <div
                                *ngIf="
                                                                    data.deleted_by
                                                                "
                              >
                                <img
                                  alt="profilový obrázek"
                                  class="img-fluid rounded-circle avatar-xs mr-1"
                                  src="assets/img{{
                                                                        data.remover_img
                                                                    }}"
                                />
                                <a
                                  [routerLink]="[
                                                                        '/users/detail/',
                                                                        data.deleted_by
                                                                    ]"
                                >
                                  <strong>{{
                                      data.remover_str
                                    }}</strong>
                                </a>
                              </div>
                            </ng-template>
                          </e-column>
                          <e-column
                            [allowEditing]="false"
                            [autoFit]="true"
                            [filter]="filterDate"
                            [format]="dateFormat"
                            clipMode="EllipsisWithTooltip"
                            editType="datepickeredit"
                            field="deleted_date"
                            headerText="Smazáno"
                            textAlign="Right"
                            type="date"
                          ></e-column>
                          <e-column
                            [allowEditing]="false"
                            [autoFit]="true"
                            [filter]="filterDate"
                            [format]="dateFormat"
                            clipMode="EllipsisWithTooltip"
                            editType="datepickeredit"
                            field="timestamp"
                            headerText="Log Vytvořen"
                            textAlign="Right"
                            type="date"
                          ></e-column>
                        </e-columns>
                      </ejs-grid>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-template>
        </e-tabitem>
      </e-tabitems>
    </ejs-tab>
  </div>
  <div class="col-12 col-md-4 col-lg-3 p-2">
    <div class="e-card">
      <ng-container *ngIf="vacation && !loadingVacation">
        <div class="e-card-header stylish-color-dark p-2">
          <div class="e-card-header-caption">
            <div
              class="d-flex justify-content-between e-card-header-title text-white"
            >
              <strong>Žádanka o uvolnění v pracovní době</strong>
              <span>ID: {{ vacation.id }}</span>
            </div>
          </div>
        </div>
        <div class="e-card-content pt-0 mt-0">
          <div class="text-center">
            <img
              alt="profilový obrázek"
              class="img-fluid rounded-circle avatar-md p-2"
              src="assets/img/{{
                                vacation.creator.personalphoto
                            }}"
            />
            <h5 class="mb-2 font-bold">
              <strong
              >{{ vacation.creator.firstname }}
                {{ vacation.creator.secondname }}</strong
              >
            </h5>
            <h6 class="font-bold blue-text mb-2">zadavatel</h6>
          </div>
          <ul class="striped list-unstyled text-center">
            <li>
              <small
              ><strong>E-mai: </strong>
                <a
                  href="mailto:{{
                                        vacation.creator.workemail
                                    }}"
                  title="napsat zprávu"
                >{{ vacation.creator.workemail }}</a
                >
              </small>
            </li>
            <li>
              <small
              ><strong>Telefon: </strong>
                <a
                  href="tel:{{ vacation.creator.telnumber }}"
                  title="zavolat"
                >{{ vacation.creator.telnumber }}</a
                ></small
              >
            </li>
          </ul>
        </div>
        <div class="e-card-content pt-2 mt-0">
          <div class="text-center">
            <h6 class="font-bold blue-text mb-2">Schvalovatel</h6>
          </div>
          <ul class="list-group" id="approversList">
            <li
              [ngClass]="
                                vacation.confirm_at
                                    ? 'list-group-item-success'
                                    : vacation.decline_at
                                    ? 'list-group-item-danger'
                                    : vacation.deleted_date
                                    ? 'list-group-item-danger'
                                    : 'list-group-item-warning'
                            "
              class="list-group-item p-1 border-top-0"
            >
              <img
                *ngIf="
                                    vacation.creator.boss &&
                                    vacation.creator.boss.personalphoto
                                "
                alt="profilový obrázek"
                class="img-fluid rounded-circle avatar-xs mr-1"
                src="assets/img{{
                                    vacation.creator.boss.personalphoto
                                }}"
              />
              <span
              >{{ vacation.creator.boss.firstname }}
                {{ vacation.creator.boss.secondname }}</span
              >
            </li>
          </ul>
        </div>
      </ng-container>
      <ng-container *ngIf="!vacation || loadingVacation">
        <div class="e-card-content text-center">
          <app-loader></app-loader>
        </div>
      </ng-container>
    </div>
  </div>
</div>
