import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';

import {Router} from '@angular/router';
import {User} from '@src/app/_models/user/user';
import {AuthenticationService} from '../_services/authentication.service';
import {MessageService} from '../_services/message.service';

export interface ErrorLog {
    status: number;
    statusText: string;
    message?: string;
    error?: {[key: string]: string};
}

@Injectable({
    providedIn: 'root',
})
export class ErrorInterceptor implements HttpInterceptor {
    currentUser: User | null;

    constructor(
        private readonly authenticationService: AuthenticationService,
        private readonly router: Router,
        private readonly messages: MessageService,
    ) {}

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        this.authenticationService.currentUser$.subscribe((user: User | null) => {
            this.currentUser = user;
        });

        if (this.currentUser) {
            return next.handle(request)
                .pipe(
                    catchError((err: ErrorLog) => {
                        if (err.status === 401) {
                            console.info('err --- logout');

                            // auto logout if 401 response returned from api
                            const body = 'Špatné jméno nebo heslo';
                            const options = {
                                progressBar: true,
                                timeOut: 5000,
                                toastClass: 'red',
                            };

                            this.messages.showError('Neplatné přihlášení!', body, options);
                            console.info('logout by interceptor 401');
                            void this.router.navigate(['/login']);
                        }

                        if (err.status === 408) {
                            console.info('ERR: Jeden nebo více připojených zdrojů není dostupných...');
                        }

                        if (err.status === 500) {
                            const options = {
                                progressBar: true,
                                timeOut: 5000,
                                toastClass: 'red',
                            };
                            const body = 'kontaktujte správce systému';

                            this.messages.showError('Kritická chyba!', body, options);
                        }

                        if (err.status === 504) {
                            const options = {
                                progressBar: true,
                                timeOut: 5000,
                                toastClass: 'red',
                            };
                            const body = 'je nutné opětovné přihlášení, žádná odezva z API';

                            this.messages.showError('Spojení ztraceno!', body, options);
                            console.info('logout by interceptor 504');
                            void this.router.navigate(['/login']);
                        }

                        if (err.status === 404) {
                            const options = {
                                progressBar: true,
                                timeOut: 5000,
                                toastClass: 'red',
                            };
                            const body = 'neplatný odkaz';

                            this.messages.showError('Záznam nedohledán!', body, options);
                        }

                        let error: string;

                        if (typeof err.error !== 'undefined' && err.error) {
                            error = err.error.message || err.statusText;
                        } else {
                            error = err.message ?? err.statusText;
                        }

                        return throwError(error);
                    }),
                );
        }

        return next.handle(request)
            .pipe(
                catchError((err: ErrorLog) => {
                    if (err.status === 401) {
                        console.info('err --- logout');

                        // auto logout if 401 response returned from api
                        const body = 'Špatné jméno nebo heslo';
                        const options = {
                            progressBar: true,
                            timeOut: 5000,
                            toastClass: 'red',
                        };

                        this.messages.showError('Neplatné přihlášení!', body, options);
                        console.info('logout by interceptor 401');
                        void this.router.navigate(['/login']);
                    }

                    if (err.status === 500) {
                        const options = {
                            progressBar: true,
                            timeOut: 5000,
                            toastClass: 'red',
                        };
                        const body = 'kontaktujte správce systému';

                        this.messages.showError('Kritická chyba!', body, options);
                    }

                    const error = err.error?.message ?? err.statusText;

                    return throwError(error);
                }),
            );
    }
}
