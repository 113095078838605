<ejs-dialog
  #formDialog
  (close)="
        isUpdate = false;
        isDirty = false;
        isChecked = false;
        submited = false;
        changeDialogState(false)
    "
  [isModal]="true"
  [showCloseIcon]="true"
  [visible]="isVisible"
  [width]="'85%'"
  id="formDialog"
>
  <form [formGroup]="orderForm">
    <ng-template #content>
      <div class="dialogContent">
        <!--STATUS-->
        <h6 class="e-dlg-header pb-2">Stav objednávky</h6>
        <div class="row">
          <div class="form-group col-12 col-md-4">
            <label
              [ngClass]="
                                f.crm_id.valid
                                    ? 'text-success'
                                    : f.crm_id.invalid
                                    ? 'text-danger'
                                    : 'text-muted'
                            "
              for="crm_id"
            >ID v CRM:</label
            >
            <ejs-numerictextbox
              [enabled]="!isUpdate"
              [format]="'###'"
              [ngClass]="
                                f.crm_id.invalid
                                    ? 'e-error'
                                    : f.crm_id.valid
                                    ? 'e-success'
                                    : ''
                            "
              formControlName="crm_id"
              id="crm_id"
              step="1"
            ></ejs-numerictextbox>
            <small
              *ngIf="
                                f.crm_id.touched &&
                                f.crm_id.errors &&
                                f.crm_id.errors.required
                            "
              class="text-danger"
            >ID CRM je povinné</small
            >
          </div>
          <div class="form-group col-12 col-md-4">
            <label
              [ngClass]="
                                f.erp_id.valid
                                    ? 'text-success'
                                    : f.erp_id.invalid
                                    ? 'text-danger'
                                    : 'text-muted'
                            "
              for="erp_id"
            >ID v ERP:</label
            >
            <ejs-numerictextbox
              [enabled]="!isUpdate"
              [format]="'###'"
              [ngClass]="
                                f.erp_id.invalid
                                    ? 'e-error'
                                    : f.erp_id.valid
                                    ? 'e-success'
                                    : ''
                            "
              formControlName="erp_id"
              id="erp_id"
              step="1"
            ></ejs-numerictextbox>
            <small
              *ngIf="
                                f.erp_id.touched &&
                                f.erp_id.errors &&
                                f.erp_id.errors.required
                            "
              class="text-danger"
            >ID ERP je povinné</small
            >
          </div>
          <div class="form-group col-12 col-md-4">
            <label
              [ngClass]="
                                f.stmnt_id.valid
                                    ? 'text-success'
                                    : f.stmnt_id.invalid
                                    ? 'text-danger'
                                    : 'text-muted'
                            "
              for="stmnt_id"
            >ID v SITE MONITOR:</label
            >
            <ejs-numerictextbox
              [enabled]="!isUpdate"
              [format]="'###'"
              [ngClass]="
                                f.stmnt_id.invalid
                                    ? 'e-error'
                                    : f.stmnt_id.valid
                                    ? 'e-success'
                                    : ''
                            "
              formControlName="stmnt_id"
              id="stmnt_id"
              step="1"
            ></ejs-numerictextbox>
            <small
              *ngIf="
                                f.stmnt_id.touched &&
                                f.stmnt_id.errors &&
                                f.stmnt_id.errors.required
                            "
              class="text-danger"
            >ID SITE MONITOR je povinné</small
            >
          </div>
          <div class="form-group col-12 col-md-6">
            <label
              [ngClass]="
                                f.provider_id.valid
                                    ? 'text-success'
                                    : f.provider_id.invalid
                                    ? 'text-danger'
                                    : 'text-muted'
                            "
              for="provider_id"
            >* Provider objednávky:</label
            >
            <ejs-dropdownlist
              #provider
              (filtering)="onFilteringCompany($event)"
              [allowFiltering]="true"
              [dataSource]="companySelect"
              [fields]="fields"
              [filterBarPlaceholder]="'Vyhledat providera...'"
              [ignoreAccent]="true"
              [placeholder]="'Vyberte providera objednávky'"
              [popupHeight]="height"
              formControlName="provider_id"
              id="provider_id"
              sortOrder="Ascending"
            ></ejs-dropdownlist>
            <small
              *ngIf="
                                f.provider_id.touched &&
                                f.provider_id.errors &&
                                f.provider_id.errors.required
                            "
              class="text-danger"
            >Provider je povinný</small
            >
          </div>
          <div class="form-group col-12 col-md-6">
            <label
              [ngClass]="
                                f.operator_id.valid
                                    ? 'text-success'
                                    : f.operator_id.invalid
                                    ? 'text-danger'
                                    : 'text-muted'
                            "
              for="operator_id"
            >* Operátor objednávky:</label
            >
            <ejs-dropdownlist
              #operator
              (filtering)="onFilteringUsers($event)"
              [allowFiltering]="true"
              [dataSource]="usersSelect"
              [fields]="fields"
              [filterBarPlaceholder]="'Vyhledat operátora...'"
              [ignoreAccent]="true"
              [placeholder]="'Vyberte operátora objednávky'"
              [popupHeight]="height"
              formControlName="operator_id"
              id="operator_id"
              sortOrder="Ascending"
            ></ejs-dropdownlist>
            <small
              *ngIf="
                                f.operator_id.touched &&
                                f.operator_id.errors &&
                                f.operator_id.errors.required
                            "
              class="text-danger"
            >Operátor je povinný</small
            >
          </div>
          <div class="form-group col-12 col-md-6">
            <label
              [ngClass]="
                                f.type_id.valid
                                    ? 'text-success'
                                    : f.type_id.invalid
                                    ? 'text-danger'
                                    : 'text-muted'
                            "
              for="type_id"
            >* Typ objednávky:</label
            >
            <ejs-dropdownlist
              #type
              (filtering)="onFilteringCategory($event)"
              [allowFiltering]="true"
              [dataSource]="categorySelect"
              [fields]="fields"
              [filterBarPlaceholder]="'Vyhledat typ...'"
              [ignoreAccent]="true"
              [placeholder]="'Vyberte typ objednávky'"
              [popupHeight]="height"
              formControlName="type_id"
              id="type_id"
              sortOrder="Ascending"
            ></ejs-dropdownlist>
            <small
              *ngIf="
                                f.type_id.touched &&
                                f.type_id.errors &&
                                f.type_id.errors.required
                            "
              class="text-danger"
            >Typ objednávky je povinný</small
            >
          </div>
          <div class="form-group col-12 col-md-6">
            <label
              [ngClass]="
                                f.status.valid
                                    ? 'text-success'
                                    : f.status.invalid
                                    ? 'text-danger'
                                    : 'text-muted'
                            "
              for="status"
            >* Stav objednávky:</label
            >
            <ejs-dropdownlist
              #status
              (filtering)="onFilteringStatus($event)"
              [allowFiltering]="true"
              [dataSource]="statusSelect"
              [fields]="fields"
              [filterBarPlaceholder]="
                                'Vyhledat stav objednávky...'
                            "
              [ignoreAccent]="true"
              [placeholder]="'Vyberte stav objednávky'"
              [popupHeight]="height"
              formControlName="status"
              id="status"
              sortOrder="Ascending"
            ></ejs-dropdownlist>
            <small
              *ngIf="
                                f.status.touched &&
                                f.status.errors &&
                                f.status.errors.required
                            "
              class="text-danger"
            >Stav objednávky je povinný</small
            >
          </div>
        </div>
        <hr class="e-separator" />
        <!--Addrress-->
        <h6 class="e-dlg-header pb-2">Sekce: Adresa</h6>
        <div class="row">
          <div class="form-group col-12">
            <label
              [ngClass]="
                                f.address.valid
                                    ? 'text-success'
                                    : f.address.invalid
                                    ? 'text-danger'
                                    : 'text-muted'
                            "
              for="address"
            >* Adresa instalace:</label
            >
            <ejs-textbox
              [ngClass]="
                                f.address.invalid
                                    ? 'e-error'
                                    : f.address.valid
                                    ? 'e-success'
                                    : ''
                            "
              floatLabelType="Auto"
              formControlName="address"
              id="address"
            ></ejs-textbox>
            <small
              *ngIf="
                                f.address.touched &&
                                f.address.errors &&
                                f.address.errors.required
                            "
              class="text-danger"
            >Adresa instalace je povinná</small
            >
          </div>
          <div class="form-group col-12 col-md-6">
            <label
              [ngClass]="
                                f.ruian.valid
                                    ? 'text-success'
                                    : f.ruian.invalid
                                    ? 'text-danger'
                                    : 'text-muted'
                            "
              for="ruian"
            >* RUIAN:</label
            >
            <ejs-numerictextbox
              [format]="'###'"
              [ngClass]="
                                f.ruian.invalid
                                    ? 'e-error'
                                    : f.ruian.valid
                                    ? 'e-success'
                                    : ''
                            "
              formControlName="ruian"
              id="ruian"
              step="1"
            ></ejs-numerictextbox>
            <small
              *ngIf="
                                f.ruian.touched &&
                                f.ruian.errors &&
                                f.ruian.errors.required
                            "
              class="text-danger"
            >RUIAN je povinný</small
            >
          </div>
          <div class="form-group col-12 col-md-6">
            <label
              [ngClass]="
                                f.field_number.valid
                                    ? 'text-success'
                                    : f.field_number.invalid
                                    ? 'text-danger'
                                    : 'text-muted'
                            "
              for="field_number"
            >Číslo parcely:</label
            >
            <ejs-numerictextbox
              [enabled]="!f.ruian.value"
              [format]="'###'"
              [ngClass]="
                                f.field_number.invalid
                                    ? 'e-error'
                                    : f.field_number.valid
                                    ? 'e-success'
                                    : ''
                            "
              formControlName="field_number"
              id="field_number"
              step="1"
            ></ejs-numerictextbox>
            <small
              *ngIf="
                                f.field_number.touched &&
                                f.field_number.errors &&
                                f.field_number.errors.required
                            "
              class="text-danger"
            >Číslo parcely je povinné</small
            >
          </div>
        </div>
        <hr class="e-separator" />
        <!--NET-->
        <h6 class="e-dlg-header pb-2">Sekce: NET tarif</h6>
        <div class="row">
          <div class="form-group col-12">
            <label
              [ngClass]="
                                f.net_package_id.valid
                                    ? 'text-success'
                                    : f.net_package_id.invalid
                                    ? 'text-danger'
                                    : 'text-muted'
                            "
              for="net_package_id"
            >Tarif internet:</label
            >
            <ejs-dropdownlist
              #net_package
              (filtering)="onFilteringNetPackages($event)"
              [allowFiltering]="true"
              [cssClass]="
                                (f.net_package_id.dirty ||
                                    f.net_package_id.touched) &&
                                f.net_package_id.invalid
                                    ? 'e-error'
                                    : (f.net_package_id.dirty ||
                                          f.net_package_id.touched) &&
                                      f.net_package_id.valid
                                    ? 'e-success'
                                    : ''
                            "
              [dataSource]="servicesNetSelect"
              [fields]="fields"
              [filterBarPlaceholder]="
                                'Vyhledat internetový tarif...'
                            "
              [ignoreAccent]="true"
              [placeholder]="'Vyberte internetový tarif'"
              [popupHeight]="height"
              formControlName="net_package_id"
              id="net_package_id"
              sortOrder="Ascending"
            ></ejs-dropdownlist>
            <small
              *ngIf="
                                f.net_package_id.touched &&
                                f.net_package_id.errors &&
                                f.net_package_id.errors.required
                            "
              class="text-danger"
            >Tarif internet je povinný</small
            >
          </div>
        </div>
        <hr class="e-separator" />
        <!--TV Service-->
        <h6 class="e-dlg-header pb-2">Sekce: TV služba</h6>
        <div class="row">
          <div class="form-group col-12">
            <label
              [ngClass]="
                                f.tv_service_id.valid
                                    ? 'text-success'
                                    : f.tv_service_id.invalid
                                    ? 'text-danger'
                                    : 'text-muted'
                            "
              for="tv_service_id"
            >Tarif televize:</label
            >
            <ejs-dropdownlist
              #tv_service
              (filtering)="onFilteringTvServices($event)"
              [allowFiltering]="true"
              [cssClass]="
                                (f.tv_service_id.dirty ||
                                    f.tv_service_id.touched) &&
                                f.tv_service_id.invalid
                                    ? 'e-error'
                                    : (f.tv_service_id.dirty ||
                                          f.tv_service_id.touched) &&
                                      f.tv_service_id.valid
                                    ? 'e-success'
                                    : ''
                            "
              [dataSource]="servicesTvSelect"
              [fields]="fields"
              [filterBarPlaceholder]="
                                'Vyhledat televizní tarif...'
                            "
              [ignoreAccent]="true"
              [placeholder]="'Vyberte televizní tarif'"
              [popupHeight]="height"
              formControlName="tv_service_id"
              id="tv_service_id"
              sortOrder="Ascending"
            ></ejs-dropdownlist>
            <small
              *ngIf="
                                f.tv_service_id.touched &&
                                f.tv_service_id.errors &&
                                f.tv_service_id.errors.required
                            "
              class="text-danger"
            >Tarif televize je povinný</small
            >
          </div>
        </div>
        <hr class="e-separator" />
        <!--TV Packages-->
        <h6 class="e-dlg-header pb-2">Sekce: TV balíček</h6>
        <div class="row">
          <div class="form-group col-12">
            <label
              [ngClass]="
                                f.tv_packages.valid
                                    ? 'text-success'
                                    : f.tv_packages.invalid
                                    ? 'text-danger'
                                    : 'text-muted'
                            "
              for="tv_packages"
            >Balíčky televize:</label
            >
            <ejs-multiselect
              #tv_packages
              (filtering)="onFilteringTvPackages($event)"
              [allowFiltering]="true"
              [cssClass]="
                                (f.tv_packages.dirty ||
                                    f.tv_packages.touched) &&
                                f.tv_packages.invalid
                                    ? 'e-error'
                                    : (f.tv_packages.dirty ||
                                          f.tv_packages.touched) &&
                                      f.tv_packages.valid
                                    ? 'e-success'
                                    : ''
                            "
              [dataSource]="tvPackagesSelect"
              [fields]="fields"
              [filterBarPlaceholder]="'Vyhledat TV balíčky...'"
              [ignoreAccent]="true"
              [mode]="'CheckBox'"
              [placeholder]="'Vyberte TV balíčky'"
              [popupHeight]="height"
              [selectAllText]="'Všechny TV balíčky'"
              formControlName="tv_packages"
              id="tv_packages"
              showSelectAll="true"
              sortOrder="Ascending"
            ></ejs-multiselect>
            <small
              *ngIf="
                                f.tv_packages.touched &&
                                f.tv_packages.errors &&
                                f.tv_packages.errors.required
                            "
              class="text-danger"
            >Balíčky televize jsou povinné</small
            >
          </div>
        </div>
        <hr class="e-separator" />
        <!--TV HW-->
        <h6 class="e-dlg-header pb-2">Sekce: TV zařízení</h6>
        <div class="row">
          <div class="form-group col-12">
            <div class="card mb-3" formArrayName="tvs">
              <div
                class="col-12 card-header p-1 justify-content-between"
              >
                <span><strong>Televize:</strong></span>
                <button
                  (click)="addTv()"
                  cssClass="e-btn e-success btn-p-zero e-small float-right"
                  ejs-button
                  type="button"
                >
                  Přidat TV
                </button>
              </div>
              <div
                *ngFor="
                                    let tv of tvFormGroup.controls;
                                    let i = index
                                "
                class="col-12 card-body p-2"
              >
                <div [formGroupName]="i" class="row p-0 m-0">
                  <div class="form-group col-7 col-md-5 mb-1">
                    <ejs-textbox
                      [ngClass]="
                                                (getTvFormGroup(i).controls[
                                                    'tv'
                                                ].dirty ||
                                                    getTvFormGroup(i).controls[
                                                        'tv'
                                                    ].touched) &&
                                                getTvFormGroup(i).controls['tv']
                                                    .invalid
                                                    ? 'e-error'
                                                    : (getTvFormGroup(i)
                                                          .controls['tv']
                                                          .dirty ||
                                                          getTvFormGroup(i)
                                                              .controls['tv']
                                                              .touched) &&
                                                      getTvFormGroup(i)
                                                          .controls['tv'].valid
                                                    ? 'e-success'
                                                    : ''
                                            "
                      autocomplete="on"
                      floatLabelType="Auto"
                      formControlName="tv"
                      id="smartForm-tv-{{ i }}"
                      placeholder="Název TV {{ i + 1 }}"
                    ></ejs-textbox>
                    <span
                      *ngIf="
                                                getTvFormGroup(i).controls['tv']
                                                    .touched &&
                                                getTvFormGroup(i).controls[
                                                    'tv'
                                                ].hasError('required')
                                            "
                      class="text-danger"
                    >Napište značku / model nebo popis
                                            TV</span
                    >
                  </div>
                  <div class="form-group col-3 col-md-5 mb-1">
                    <ejs-checkbox
                      [checked]="true"
                      [disabled]="true"
                      cssClass="e-primary mr-2"
                      formControlName="smart"
                      id="smartForm-smart-{{ i }}"
                      label="SmartTV"
                    ></ejs-checkbox>
                    <ejs-checkbox
                      [checked]="false"
                      cssClass="e-primary mr-2"
                      formControlName="settopbox"
                      id="smartForm-settopbox-{{ i }}"
                      label="Set-top box"
                    ></ejs-checkbox>
                    <ejs-checkbox
                      [checked]="false"
                      cssClass="e-primary mr-2"
                      formControlName="settopbox_fee"
                      id="smartForm-settopbox_fee-{{ i }}"
                      label="pronájem (+99,- CZK / měsíčně)"
                    ></ejs-checkbox>
                  </div>
                  <div
                    class="form-group col-2 col-md-2 mb-1 text-right"
                  >
                    <button
                      (click)="removeTv(i)"
                      cssClass="e-btn e-danger btn-p-zero e-small"
                      ejs-button
                      type="button"
                    >
                      Odebrat TV
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr class="e-separator" />
        <!--Ext Services-->
        <h6 class="e-dlg-header pb-2">Sekce: Rozšiřující služby</h6>
        <div class="row">
          <div class="form-group col-12">
            <label
              [ngClass]="
                                f.ext_services.valid
                                    ? 'text-success'
                                    : f.ext_services.invalid
                                    ? 'text-danger'
                                    : 'text-muted'
                            "
              for="ext_services"
            >Rorzšiřující služby:</label
            >
            <ejs-multiselect
              #ext_services
              (filtering)="onFilteringExtPackages($event)"
              [allowFiltering]="true"
              [cssClass]="
                                (f.ext_services.dirty ||
                                    f.ext_services.touched) &&
                                f.ext_services.invalid
                                    ? 'e-error'
                                    : (f.ext_services.dirty ||
                                          f.ext_services.touched) &&
                                      f.ext_services.valid
                                    ? 'e-success'
                                    : ''
                            "
              [dataSource]="extServicesSelect"
              [fields]="fields"
              [filterBarPlaceholder]="
                                'Vyhledat rozšiřující služby...'
                            "
              [ignoreAccent]="true"
              [mode]="'CheckBox'"
              [placeholder]="'Vyberte rozšiřující služby'"
              [popupHeight]="height"
              [selectAllText]="'Všechny rozšiřující služby'"
              formControlName="ext_services"
              id="ext_services"
              showSelectAll="true"
              sortOrder="Ascending"
            ></ejs-multiselect>
            <small
              *ngIf="
                                f.ext_services.touched &&
                                f.ext_services.errors &&
                                f.ext_services.errors.required
                            "
              class="text-danger"
            >Tarif internet je povinný</small
            >
          </div>
        </div>
        <hr class="e-separator" />
        <!--Wicon-->
        <h6 class="e-dlg-header pb-2">Sekce: Wi-Fi konfigurátor</h6>
        <div class="row">
          <div class="form-group col-12 col-md-4">
            <label
              [ngClass]="
                                f.unit_id.valid
                                    ? 'text-success'
                                    : f.unit_id.invalid
                                    ? 'text-danger'
                                    : 'text-muted'
                            "
              for="unit_id"
            >Typ bytové jednotky:</label
            >
            <ejs-dropdownlist
              #unit_type
              (change)="setWiCon()"
              (filtering)="onFilteringUnitTypes($event)"
              [allowFiltering]="true"
              [dataSource]="unitsSelect"
              [fields]="fields"
              [filterBarPlaceholder]="
                                'Vyhledat typ bytové jednotky...'
                            "
              [ignoreAccent]="true"
              [placeholder]="'Vyberte typ bytové jednotky'"
              [popupHeight]="height"
              formControlName="unit_id"
              id="unit_id"
              sortOrder="Ascending"
            ></ejs-dropdownlist>
            <small
              *ngIf="
                                f.unit_id.touched &&
                                f.unit_id.errors &&
                                f.unit_id.errors.required
                            "
              class="text-danger"
            >Typ bytové jednotky je povinný</small
            >
          </div>
          <div class="form-group col-12 col-md-4">
            <label
              [ngClass]="
                                f.space_id.valid
                                    ? 'text-success'
                                    : f.space_id.invalid
                                    ? 'text-danger'
                                    : 'text-muted'
                            "
              for="space_id"
            >Prostor bytové jednotky:</label
            >
            <ejs-dropdownlist
              #flat_space
              (change)="setWiCon()"
              (filtering)="onFilteringSpaceType($event)"
              [allowFiltering]="true"
              [dataSource]="spacesSelect"
              [enabled]="f.unit_id.valid"
              [fields]="fields"
              [filterBarPlaceholder]="
                                'Vyhledat prostor bytové jednotky...'
                            "
              [ignoreAccent]="true"
              [placeholder]="'Vyberte prostor bytové jednotky'"
              [popupHeight]="height"
              formControlName="space_id"
              id="space_id"
              sortOrder="Ascending"
            ></ejs-dropdownlist>
            <small
              *ngIf="
                                f.space_id.touched &&
                                f.space_id.errors &&
                                f.space_id.errors.required
                            "
              class="text-danger"
            >Prostor bytové jednotky je povinný</small
            >
          </div>
          <div class="form-group col-12 col-md-4">
            <label
              [ngClass]="
                                f.speed_id.valid
                                    ? 'text-success'
                                    : f.speed_id.invalid
                                    ? 'text-danger'
                                    : 'text-muted'
                            "
              for="speed_id"
            >Limit rychlosti:</label
            >
            <ejs-dropdownlist
              #speed_type
              (change)="setWiCon()"
              (filtering)="onFilteringSpeedLimits($event)"
              [allowFiltering]="true"
              [dataSource]="speedsSelect"
              [enabled]="f.unit_id.valid && f.space_id.valid"
              [fields]="fields"
              [filterBarPlaceholder]="
                                'Vyhledat limit rychlosti...'
                            "
              [ignoreAccent]="true"
              [placeholder]="'Vyberte limit rychlosti'"
              [popupHeight]="height"
              formControlName="speed_id"
              id="speed_id"
              sortOrder="Ascending"
            ></ejs-dropdownlist>
            <small
              *ngIf="
                                f.speed_id.touched &&
                                f.speed_id.errors &&
                                f.speed_id.errors.required
                            "
              class="text-danger"
            >Limit rychlosti je povinný</small
            >
          </div>
          <div class="form-group col-12 text-center">
            <ejs-checkbox
              #wicon_own_router
              (checkedChange)="setWiCon()"
              cssClass="e-primary p-0 mr-3"
              formControlName="wicon_own_router"
              id="wicon_own_router"
              label="vlastní router"
            >
            </ejs-checkbox>
            <ejs-checkbox
              #wicon_basics
              (checkedChange)="setWiCon()"
              cssClass="e-primary p-0 mr-3"
              formControlName="wicon_basics"
              id="wicon_basics"
              label="základy"
            >
            </ejs-checkbox>
            <ejs-checkbox
              #wicon_garden
              (checkedChange)="setWiCon()"
              cssClass="e-primary p-0 mr-3"
              formControlName="wicon_garden"
              id="wicon_garden"
              label="zahrada"
            >
            </ejs-checkbox>
            <ejs-checkbox
              #wicon_cabels
              (checkedChange)="setWiCon()"
              cssClass="e-primary p-0 mr-3"
              formControlName="wicon_cabels"
              id="wicon_cabels"
              label="kabeláž"
            >
            </ejs-checkbox>
            <ejs-checkbox
              #wicon_roaming
              (checkedChange)="setWiCon()"
              cssClass="e-primary p-0 mr-3"
              formControlName="wicon_roaming"
              id="wicon_roaming"
              label="wi-fi roaming"
            >
            </ejs-checkbox>
          </div>
          <div class="form-group col-12">
            <label
              [ngClass]="
                                f.hw_id.valid
                                    ? 'text-success'
                                    : f.hw_id.invalid
                                    ? 'text-danger'
                                    : 'text-muted'
                            "
              for="hw_id"
            >Instalované zařízení:</label
            >
            <ejs-dropdownlist
              #hardware
              (filtering)="onFilteringHardware($event)"
              [allowFiltering]="true"
              [dataSource]="hardwareSelect"
              [enabled]="
                                f.speed_id.valid &&
                                f.unit_id.valid &&
                                f.space_id.valid
                            "
              [fields]="fields"
              [filterBarPlaceholder]="
                                'Vyhledat instalované zařízení...'
                            "
              [ignoreAccent]="true"
              [placeholder]="'Vyberte instalované zařízení'"
              [popupHeight]="height"
              formControlName="hw_id"
              id="hw_id"
              sortOrder="Ascending"
            ></ejs-dropdownlist>
            <small
              *ngIf="
                                f.hw_id.touched &&
                                f.hw_id.errors &&
                                f.hw_id.errors.required
                            "
              class="text-danger"
            >Instalované zařízení je povinné</small
            >
          </div>
          <div class="form-group col-12">
            <ejs-radiobutton
              (change)="setWifiSellMode()"
              (click)="purchaseWifi = 'fee'"
              *ngIf="f.hw_id.valid && f.hw_id.value"
              [checked]="purchaseWifi === 'fee'"
              cssClass="e-primary p-0 m-0 mr-3"
              label="Pronájem"
              name="purchaseWifi"
              value="purchase"
            ></ejs-radiobutton>
            <ejs-radiobutton
              (change)="setWifiSellMode()"
              (click)="purchaseWifi = 'purchase'"
              *ngIf="f.hw_id.valid && f.hw_id.value"
              [checked]="purchaseWifi === 'purchase'"
              cssClass="e-primary p-0 m-0 mr-3"
              label="Odkup"
              name="purchaseWifi"
              value="fee"
            ></ejs-radiobutton>
          </div>
        </div>
        <hr class="e-separator" />
        <!--Client-->
        <h6 class="e-dlg-header pb-2">Sekce: Klient</h6>
        <div class="row">
          <div class="form-group col-12 col-md-6">
            <label
              [ngClass]="
                                f.cl_firstname.valid
                                    ? 'text-success'
                                    : f.cl_firstname.invalid
                                    ? 'text-danger'
                                    : 'text-muted'
                            "
              for="cl_firstname"
            >* Jméno klienta:</label
            >
            <ejs-textbox
              [ngClass]="
                                f.cl_firstname.invalid
                                    ? 'e-error'
                                    : f.cl_firstname.valid
                                    ? 'e-success'
                                    : ''
                            "
              floatLabelType="Auto"
              formControlName="cl_firstname"
              id="cl_firstname"
            ></ejs-textbox>
            <small
              *ngIf="
                                f.cl_firstname.touched &&
                                f.cl_firstname.errors &&
                                f.cl_firstname.errors.required
                            "
              class="text-danger"
            >Jméno klienta je povinné</small
            >
          </div>
          <div class="form-group col-12 col-md-6">
            <label
              [ngClass]="
                                f.cl_lastname.valid
                                    ? 'text-success'
                                    : f.cl_lastname.invalid
                                    ? 'text-danger'
                                    : 'text-muted'
                            "
              for="cl_lastname"
            >* Přjmení klienta:</label
            >
            <ejs-textbox
              [ngClass]="
                                f.cl_lastname.invalid
                                    ? 'e-error'
                                    : f.cl_lastname.valid
                                    ? 'e-success'
                                    : ''
                            "
              floatLabelType="Auto"
              formControlName="cl_lastname"
              id="cl_lastname"
            ></ejs-textbox>
            <small
              *ngIf="
                                f.cl_lastname.touched &&
                                f.cl_lastname.errors &&
                                f.cl_lastname.errors.required
                            "
              class="text-danger"
            >Přjmení klienta je povinné</small
            >
          </div>
          <div class="form-group col-12 col-md-6">
            <label
              [ngClass]="
                                f.cl_email.valid
                                    ? 'text-success'
                                    : f.cl_email.invalid
                                    ? 'text-danger'
                                    : 'text-muted'
                            "
              for="cl_email"
            >* E-mail:</label
            >
            <ejs-textbox
              [ngClass]="
                                f.cl_email.invalid
                                    ? 'e-error'
                                    : f.cl_email.valid
                                    ? 'e-success'
                                    : ''
                            "
              floatLabelType="Auto"
              formControlName="cl_email"
              id="cl_email"
            ></ejs-textbox>
            <small
              *ngIf="
                                f.cl_email.touched &&
                                f.cl_email.errors &&
                                f.cl_email.errors.required
                            "
              class="text-danger"
            >E-mail je povinný</small
            >
          </div>
          <div class="form-group col-12 col-md-6">
            <label
              [ngClass]="
                                f.cl_billing_email.valid
                                    ? 'text-success'
                                    : f.cl_billing_email.invalid
                                    ? 'text-danger'
                                    : 'text-muted'
                            "
              for="cl_billing_email"
            >Fakturační e-mail:</label
            >
            <ejs-textbox
              [ngClass]="
                                f.cl_billing_email.invalid
                                    ? 'e-error'
                                    : f.cl_billing_email.valid
                                    ? 'e-success'
                                    : ''
                            "
              floatLabelType="Auto"
              formControlName="cl_billing_email"
              id="cl_billing_email"
            ></ejs-textbox>
            <small
              *ngIf="
                                f.cl_billing_email.touched &&
                                f.cl_billing_email.errors &&
                                f.cl_billing_email.errors.required
                            "
              class="text-danger"
            >Fakturační e-mail je povinný</small
            >
          </div>
          <div class="form-group col-12 col-md-6">
            <label
              [ngClass]="
                                f.cl_technical_email.valid
                                    ? 'text-success'
                                    : f.cl_technical_email.invalid
                                    ? 'text-danger'
                                    : 'text-muted'
                            "
              for="cl_technical_email"
            >Technický e-mail:</label
            >
            <ejs-textbox
              [ngClass]="
                                f.cl_technical_email.invalid
                                    ? 'e-error'
                                    : f.cl_technical_email.valid
                                    ? 'e-success'
                                    : ''
                            "
              floatLabelType="Auto"
              formControlName="cl_technical_email"
              id="cl_technical_email"
            ></ejs-textbox>
            <small
              *ngIf="
                                f.cl_technical_email.touched &&
                                f.cl_technical_email.errors &&
                                f.cl_technical_email.errors.required
                            "
              class="text-danger"
            >Technický e-mail je povinný</small
            >
          </div>
          <div class="form-group col-12 col-md-6">
            <label
              [ngClass]="
                                f.cl_business_email.valid
                                    ? 'text-success'
                                    : f.cl_business_email.invalid
                                    ? 'text-danger'
                                    : 'text-muted'
                            "
              for="cl_technical_email"
            >Obchodní e-mail:</label
            >
            <ejs-textbox
              [ngClass]="
                                f.cl_business_email.invalid
                                    ? 'e-error'
                                    : f.cl_business_email.valid
                                    ? 'e-success'
                                    : ''
                            "
              floatLabelType="Auto"
              formControlName="cl_business_email"
              id="cl_business_email"
            ></ejs-textbox>
            <small
              *ngIf="
                                f.cl_business_email.touched &&
                                f.cl_business_email.errors &&
                                f.cl_business_email.errors.required
                            "
              class="text-danger"
            >Obchodní e-mail je povinný</small
            >
          </div>
          <div class="form-group col-12 col-md-6">
            <label
              [ngClass]="
                                f.cl_telephone.valid
                                    ? 'text-success'
                                    : f.cl_telephone.invalid
                                    ? 'text-danger'
                                    : 'text-muted'
                            "
              for="cl_technical_email"
            >Telefon:</label
            >
            <ejs-textbox
              [ngClass]="
                                f.cl_telephone.invalid
                                    ? 'e-error'
                                    : f.cl_telephone.valid
                                    ? 'e-success'
                                    : ''
                            "
              floatLabelType="Auto"
              formControlName="cl_telephone"
              id="cl_telephone"
            ></ejs-textbox>
            <small
              *ngIf="
                                f.cl_telephone.touched &&
                                f.cl_telephone.errors &&
                                f.cl_telephone.errors.required
                            "
              class="text-danger"
            >Telefon je povinný</small
            >
          </div>
          <div class="form-group col-12 col-md-6">
            <label
              [ngClass]="
                                f.cl_mobilephone.valid
                                    ? 'text-success'
                                    : f.cl_mobilephone.invalid
                                    ? 'text-danger'
                                    : 'text-muted'
                            "
              for="cl_technical_email"
            >Mobil:</label
            >
            <ejs-textbox
              [ngClass]="
                                f.cl_mobilephone.invalid
                                    ? 'e-error'
                                    : f.cl_mobilephone.valid
                                    ? 'e-success'
                                    : ''
                            "
              floatLabelType="Auto"
              formControlName="cl_mobilephone"
              id="cl_mobilephone"
            ></ejs-textbox>
            <small
              *ngIf="
                                f.cl_mobilephone.touched &&
                                f.cl_mobilephone.errors &&
                                f.cl_mobilephone.errors.required
                            "
              class="text-danger"
            >Telefon je povinný</small
            >
          </div>
          <div class="form-group col-12 col-md-6">
            <label
              [ngClass]="
                                f.cl_account_number.valid
                                    ? 'text-success'
                                    : f.cl_account_number.invalid
                                    ? 'text-danger'
                                    : 'text-muted'
                            "
              for="cl_technical_email"
            >Číslo bankovního účtu:</label
            >
            <ejs-textbox
              [ngClass]="
                                f.cl_account_number.invalid
                                    ? 'e-error'
                                    : f.cl_account_number.valid
                                    ? 'e-success'
                                    : ''
                            "
              floatLabelType="Auto"
              formControlName="cl_account_number"
              id="cl_account_number"
            ></ejs-textbox>
            <small
              *ngIf="
                                f.cl_account_number.touched &&
                                f.cl_account_number.errors &&
                                f.cl_account_number.errors.required
                            "
              class="text-danger"
            >Číslo bankovního účtu je povinné</small
            >
          </div>
          <div class="form-group col-12 col-md-6">
            <label
              [ngClass]="
                                f.cl_bank_number.valid
                                    ? 'text-success'
                                    : f.cl_bank_number.invalid
                                    ? 'text-danger'
                                    : 'text-muted'
                            "
              for="cl_technical_email"
            >Číslo banky:</label
            >
            <ejs-textbox
              [ngClass]="
                                f.cl_bank_number.invalid
                                    ? 'e-error'
                                    : f.cl_bank_number.valid
                                    ? 'e-success'
                                    : ''
                            "
              floatLabelType="Auto"
              formControlName="cl_bank_number"
              id="cl_bank_number"
            ></ejs-textbox>
            <small
              *ngIf="
                                f.cl_bank_number.touched &&
                                f.cl_bank_number.errors &&
                                f.cl_bank_number.errors.required
                            "
              class="text-danger"
            >Číslo banky je povinné</small
            >
          </div>
          <div class="form-group col-12 col-md-6">
            <label
              [ngClass]="
                                f.cl_address.valid
                                    ? 'text-success'
                                    : f.cl_address.invalid
                                    ? 'text-danger'
                                    : 'text-muted'
                            "
              for="cl_technical_email"
            >*Trvalé bydliště:</label
            >
            <ejs-textbox
              [ngClass]="
                                f.cl_address.invalid
                                    ? 'e-error'
                                    : f.cl_address.valid
                                    ? 'e-success'
                                    : ''
                            "
              floatLabelType="Auto"
              formControlName="cl_address"
              id="cl_address"
            ></ejs-textbox>
            <small
              *ngIf="
                                f.cl_address.touched &&
                                f.cl_address.errors &&
                                f.cl_address.errors.required
                            "
              class="text-danger"
            >Trvalé bydliště je povinné</small
            >
          </div>
          <div class="form-group col-12 col-md-6">
            <label
              [ngClass]="
                                f.cl_billing_address.valid
                                    ? 'text-success'
                                    : f.cl_billing_address.invalid
                                    ? 'text-danger'
                                    : 'text-muted'
                            "
              for="cl_technical_email"
            >Fakturační adresa:</label
            >
            <ejs-textbox
              [ngClass]="
                                f.cl_billing_address.invalid
                                    ? 'e-error'
                                    : f.cl_billing_address.valid
                                    ? 'e-success'
                                    : ''
                            "
              floatLabelType="Auto"
              formControlName="cl_billing_address"
              id="cl_billing_address"
            ></ejs-textbox>
            <small
              *ngIf="
                                f.cl_billing_address.touched &&
                                f.cl_billing_address.errors &&
                                f.cl_billing_address.errors.required
                            "
              class="text-danger"
            >Fakturační adresa je povinná</small
            >
          </div>
          <div class="form-group col-12 col-md-6">
            <label
              [ngClass]="
                                f.cl_vat_id.valid
                                    ? 'text-success'
                                    : f.cl_vat_id.invalid
                                    ? 'text-danger'
                                    : 'text-muted'
                            "
              for="cl_technical_email"
            >IČO:</label
            >
            <ejs-textbox
              [ngClass]="
                                f.cl_vat_id.invalid
                                    ? 'e-error'
                                    : f.cl_vat_id.valid
                                    ? 'e-success'
                                    : ''
                            "
              floatLabelType="Auto"
              formControlName="cl_vat_id"
              id="cl_vat_id"
            ></ejs-textbox>
            <small
              *ngIf="
                                f.cl_vat_id.touched &&
                                f.cl_vat_id.errors &&
                                f.cl_vat_id.errors.required
                            "
              class="text-danger"
            >IČO je povinné</small
            >
          </div>
          <div class="form-group col-12 col-md-6">
            <label
              [ngClass]="
                                f.cl_national_id_number.valid
                                    ? 'text-success'
                                    : f.cl_national_id_number.invalid
                                    ? 'text-danger'
                                    : 'text-muted'
                            "
              for="cl_technical_email"
            >Rodné číslo:</label
            >
            <ejs-textbox
              [ngClass]="
                                f.cl_national_id_number.invalid
                                    ? 'e-error'
                                    : f.cl_national_id_number.valid
                                    ? 'e-success'
                                    : ''
                            "
              floatLabelType="Auto"
              formControlName="cl_national_id_number"
              id="cl_national_id_number"
            ></ejs-textbox>
            <small
              *ngIf="
                                f.cl_national_id_number.touched &&
                                f.cl_national_id_number.errors &&
                                f.cl_national_id_number.errors.required
                            "
              class="text-danger"
            >Rodné číslo je povinné</small
            >
          </div>
        </div>
        <hr class="e-separator" />
        <!--FA metoda-->
        <h6 class="e-dlg-header pb-2">Sekce: Smlouva a platba</h6>
        <div class="row"></div>
        <!--Fees and Prices-->
        <div class="row">
          <div class="form-group col-12 col-md-6">
            <label
              [ngClass]="
                                f.deal_id.valid
                                    ? 'text-success'
                                    : f.deal_id.invalid
                                    ? 'text-danger'
                                    : 'text-muted'
                            "
              for="deal_id"
            >* Typ smluvního úvazku:</label
            >
            <ejs-dropdownlist
              #deal_type
              (filtering)="onFilteringDealTypes($event)"
              [allowFiltering]="true"
              [dataSource]="dealSelect"
              [fields]="fields"
              [filterBarPlaceholder]="
                                'Vyhledat typ smluvního úvazku...'
                            "
              [ignoreAccent]="true"
              [placeholder]="'Vyberte typ smluvního úvazku'"
              [popupHeight]="height"
              formControlName="deal_id"
              id="deal_id"
              sortOrder="Ascending"
            ></ejs-dropdownlist>
            <small
              *ngIf="
                                f.deal_id.touched &&
                                f.deal_id.errors &&
                                f.deal_id.errors.required
                            "
              class="text-danger"
            >Typ smluvního úvazku je povinný</small
            >
          </div>
          <div class="col-12 col-md-3 form-group">
            <ejs-checkbox
              cssClass="e-primary p-0 m-2"
              formControlName="cl_send_invoices_by_post"
              id="cl_send_invoices_by_post"
              label="zasílat faktury poštou"
              name="cl_send_invoices_by_post"
            >
            </ejs-checkbox>
            <ejs-checkbox
              cssClass="e-primary p-0 m-2"
              formControlName="cl_send_invoice_by_email"
              id="cl_send_invoice_by_email"
              label="zasílat faktury elektronicky"
              name="cl_send_invoice_by_email"
            >
            </ejs-checkbox>
          </div>
          <div class="col-12 col-md-3 form-group">
            <ejs-checkbox
              cssClass="e-primary p-0 m-2"
              formControlName="invoice_by_year"
              id="invoice_by_year"
              label="roční předplatné (sleva 10%)"
              name="invoice_by_year"
            >
            </ejs-checkbox>
            <ejs-checkbox
              cssClass="e-primary p-0 m-2"
              formControlName="vip_install"
              id="vip_install"
              label="přednostní instalace (+500,- CZK / jednorázově)"
              name="vip_install"
            >
            </ejs-checkbox>
          </div>
          <div class="form-group col-6">
            <label
              [ngClass]="
                                f.internet_fee.valid
                                    ? 'text-success'
                                    : f.internet_fee.invalid
                                    ? 'text-danger'
                                    : 'text-muted'
                            "
              for="cl_technical_email"
            >Pravidelný poplatek internet:</label
            >
            <ejs-numerictextbox
              [format]="'#,###.00\',- CZK\''"
              [locale]="'cs'"
              [ngClass]="
                                f.internet_fee.invalid
                                    ? 'e-error'
                                    : f.internet_fee.valid
                                    ? 'e-success'
                                    : ''
                            "
              formControlName="internet_fee"
              id="internet_fee"
              step="0.01"
            ></ejs-numerictextbox>
            <small
              *ngIf="
                                f.internet_fee.touched &&
                                f.internet_fee.errors &&
                                f.internet_fee.errors.required
                            "
              class="text-danger"
            >Pravidelný poplatek internet je povinný</small
            >
          </div>
          <div class="form-group col-6">
            <label
              [ngClass]="
                                f.internet_price.valid
                                    ? 'text-success'
                                    : f.internet_price.invalid
                                    ? 'text-danger'
                                    : 'text-muted'
                            "
              for="cl_technical_email"
            >Jednorázový poplatek internet:</label
            >
            <ejs-numerictextbox
              [format]="'#,###.00\',- CZK\''"
              [ngClass]="
                                f.internet_price.invalid
                                    ? 'e-error'
                                    : f.internet_price.valid
                                    ? 'e-success'
                                    : ''
                            "
              formControlName="internet_price"
              id="internet_price"
              step="0.01"
            ></ejs-numerictextbox>
            <small
              *ngIf="
                                f.internet_price.touched &&
                                f.internet_price.errors &&
                                f.internet_price.errors.required
                            "
              class="text-danger"
            >Pravidelný poplatek internet je povinný</small
            >
          </div>
          <div class="form-group col-6">
            <label
              [ngClass]="
                                f.television_fee.valid
                                    ? 'text-success'
                                    : f.television_fee.invalid
                                    ? 'text-danger'
                                    : 'text-muted'
                            "
              for="cl_technical_email"
            >Pravidelný poplatek televize:</label
            >
            <ejs-numerictextbox
              [format]="'#,###.00\',- CZK\''"
              [ngClass]="
                                f.television_fee.invalid
                                    ? 'e-error'
                                    : f.television_fee.valid
                                    ? 'e-success'
                                    : ''
                            "
              formControlName="television_fee"
              id="television_fee"
              step="0.01"
            ></ejs-numerictextbox>
            <small
              *ngIf="
                                f.television_fee.touched &&
                                f.television_fee.errors &&
                                f.television_fee.errors.required
                            "
              class="text-danger"
            >Pravidelný poplatek televize je povinný</small
            >
          </div>
          <div class="form-group col-6">
            <label
              [ngClass]="
                                f.television_price.valid
                                    ? 'text-success'
                                    : f.television_price.invalid
                                    ? 'text-danger'
                                    : 'text-muted'
                            "
              for="cl_technical_email"
            >Jednorázový poplatek televize:</label
            >
            <ejs-numerictextbox
              [format]="'#,###.00\',- CZK\''"
              [ngClass]="
                                f.television_price.invalid
                                    ? 'e-error'
                                    : f.television_price.valid
                                    ? 'e-success'
                                    : ''
                            "
              formControlName="television_price"
              id="television_price"
              step="0.01"
            ></ejs-numerictextbox>
            <small
              *ngIf="
                                f.television_price.touched &&
                                f.television_price.errors &&
                                f.television_price.errors.required
                            "
              class="text-danger"
            >Jednorázový poplatek televize je povinný</small
            >
          </div>
          <div class="form-group col-6">
            <label
              [ngClass]="
                                f.ext_services_fee.valid
                                    ? 'text-success'
                                    : f.ext_services_fee.invalid
                                    ? 'text-danger'
                                    : 'text-muted'
                            "
              for="cl_technical_email"
            >Pravidelný poplatek rozšířující služby:</label
            >
            <ejs-numerictextbox
              [format]="'#,###.00\',- CZK\''"
              [ngClass]="
                                f.ext_services_fee.invalid
                                    ? 'e-error'
                                    : f.ext_services_fee.valid
                                    ? 'e-success'
                                    : ''
                            "
              formControlName="ext_services_fee"
              id="ext_services_fee"
              step="0.01"
            ></ejs-numerictextbox>
            <small
              *ngIf="
                                f.ext_services_fee.touched &&
                                f.ext_services_fee.errors &&
                                f.ext_services_fee.errors.required
                            "
              class="text-danger"
            >Pravidelný poplatek rozšířující služby je
              povinný</small
            >
          </div>
          <div class="form-group col-6">
            <label
              [ngClass]="
                                f.ext_services_price.valid
                                    ? 'text-success'
                                    : f.ext_services_price.invalid
                                    ? 'text-danger'
                                    : 'text-muted'
                            "
              for="cl_technical_email"
            >Jednorázový poplatek rozšířující služby:</label
            >
            <ejs-numerictextbox
              [format]="'#,###.00\',- CZK\''"
              [ngClass]="
                                f.ext_services_price.invalid
                                    ? 'e-error'
                                    : f.ext_services_price.valid
                                    ? 'e-success'
                                    : ''
                            "
              formControlName="ext_services_price"
              id="ext_services_price"
              step="0.01"
            ></ejs-numerictextbox>
            <small
              *ngIf="
                                f.ext_services_price.touched &&
                                f.ext_services_price.errors &&
                                f.ext_services_price.errors.required
                            "
              class="text-danger"
            >Jednorázový poplatek rozšířující služby je
              povinný</small
            >
          </div>
          <div class="form-group col-6">
            <label
              [ngClass]="
                                f.wifi_fee.valid
                                    ? 'text-success'
                                    : f.wifi_fee.invalid
                                    ? 'text-danger'
                                    : 'text-muted'
                            "
              for="cl_technical_email"
            >Pravidelný poplatek wi-fi služby:</label
            >
            <ejs-numerictextbox
              [format]="'#,###.00\',- CZK\''"
              [ngClass]="
                                f.wifi_fee.invalid
                                    ? 'e-error'
                                    : f.wifi_fee.valid
                                    ? 'e-success'
                                    : ''
                            "
              formControlName="wifi_fee"
              id="wifi_fee"
              step="0.01"
            ></ejs-numerictextbox>
            <small
              *ngIf="
                                f.wifi_fee.touched &&
                                f.wifi_fee.errors &&
                                f.wifi_fee.errors.required
                            "
              class="text-danger"
            >Pravidelný poplatek wi-fi služby je povinný</small
            >
          </div>
          <div class="form-group col-6">
            <label
              [ngClass]="
                                f.wifi_price.valid
                                    ? 'text-success'
                                    : f.wifi_price.invalid
                                    ? 'text-danger'
                                    : 'text-muted'
                            "
              for="cl_technical_email"
            >Jednorázový poplatek wi-fi služby:</label
            >
            <ejs-numerictextbox
              [format]="'#,###.00\',- CZK\''"
              [ngClass]="
                                f.wifi_price.invalid
                                    ? 'e-error'
                                    : f.wifi_price.valid
                                    ? 'e-success'
                                    : ''
                            "
              formControlName="wifi_price"
              id="wifi_price"
              step="0.01"
            ></ejs-numerictextbox>
            <small
              *ngIf="
                                f.wifi_price.touched &&
                                f.wifi_price.errors &&
                                f.wifi_price.errors.required
                            "
              class="text-danger"
            >Jednorázový poplatek wi-fi služby je povinný</small
            >
          </div>
          <div class="form-group col-6">
            <label
              [ngClass]="
                                f.total_fee.valid
                                    ? 'text-success'
                                    : f.total_fee.invalid
                                    ? 'text-danger'
                                    : 'text-muted'
                            "
              for="cl_technical_email"
            >Pravidelný poplatek celkem:</label
            >
            <ejs-numerictextbox
              [format]="'#,###.00\',- CZK\''"
              [ngClass]="
                                f.total_fee.invalid
                                    ? 'e-error'
                                    : f.total_fee.valid
                                    ? 'e-success'
                                    : ''
                            "
              formControlName="total_fee"
              id="total_fee"
              step="0.01"
            ></ejs-numerictextbox>
            <small
              *ngIf="
                                f.total_fee.touched &&
                                f.total_fee.errors &&
                                f.total_fee.errors.required
                            "
              class="text-danger"
            >Pravidelný poplatek celkem je povinný</small
            >
          </div>
          <div class="form-group col-6">
            <label
              [ngClass]="
                                f.total_price.valid
                                    ? 'text-success'
                                    : f.total_price.invalid
                                    ? 'text-danger'
                                    : 'text-muted'
                            "
              for="cl_technical_email"
            >Jednorázový poplatek celkem:</label
            >
            <ejs-numerictextbox
              [format]="'#,###.00\',- CZK\''"
              [ngClass]="
                                f.total_price.invalid
                                    ? 'e-error'
                                    : f.total_price.valid
                                    ? 'e-success'
                                    : ''
                            "
              formControlName="total_price"
              id="total_price"
              step="0.01"
            ></ejs-numerictextbox>
            <small
              *ngIf="
                                f.total_price.touched &&
                                f.total_price.errors &&
                                f.total_price.errors.required
                            "
              class="text-danger"
            >Jednorázový poplatek celkem je povinný</small
            >
          </div>
        </div>
        <div class="row">
          <div class="col-12 mb-2 form-group">
            <label
              [ngClass]="
                                f.internal_note.valid
                                    ? 'text-success'
                                    : f.internal_note.invalid &&
                                      (f.internal_note.dirty ||
                                          f.internal_note.touched)
                                    ? 'text-danger'
                                    : 'text-muted'
                            "
              for="projectForm-internal_note"
            >Intenrí poznámka:</label
            >
            <ejs-textbox
              [multiline]="true"
              [ngClass]="{
                                'e-error': submited && f.internal_note.errors
                            }"
              floatLabelType="Auto"
              formControlName="internal_note"
              id="internal_note"
            ></ejs-textbox>
            <small
              *ngIf="
                                (f.internal_note.dirty ||
                                    f.internal_note.touched) &&
                                f.internal_note.errors &&
                                f.internal_note.errors.required
                            "
              class="text-danger"
            >Vyplňte intenrí poznámku</small
            >
          </div>
        </div>
      </div>
    </ng-template>
    <ng-template #footerTemplate>
      <div class="e-card p-0 z-depth-1">
        <div class="row">
          <div class="col-2 skewed_bg p-1 pl-3 pr-3">
            <div class="d-flex justify-content-between">
              <ejs-tooltip content="Internet služby">
                <i
                  [ngClass]="
                                        internetPrice.fee &&
                                        internetPrice.fee > 0
                                            ? 'text-success'
                                            : 'text-warning'
                                    "
                  aria-hidden="true"
                  class="fa-light fa-2x fa-globe-europe mr-2"
                ></i>
                <span
                  *ngIf="
                                        internetPrice.discount + discount > 0
                                    "
                  class="e-badge e-badge-danger mr-2"
                >Sleva:
                  {{ internetPrice.discount + discount }} % z
                                    ceny</span
                >
              </ejs-tooltip>
              <strong class="pt-1 text-right"
              >{{
                  (internetPrice.period===12
                      ? internetPriceAnnualy
                      :internetPrice.fee
                  ) | number: '1.2-2'
                }}
                ,- CZK<small class="text-muted">
                  /
                  <ng-container
                    *ngIf="internetPrice.period === 1"
                  >měsíčně
                  </ng-container>
                  <ng-container
                    *ngIf="internetPrice.period === 12"
                  >
                    ročně<br />ušetřeno:&nbsp;{{
                      (internetPriceAnnualy *
                        (discount +
                          televisionPrice.discount)) /
                      (100 -
                        (discount +
                          televisionPrice.discount))
                        | number: '1.2-2'
                    }}
                    ,- CZK
                  </ng-container>
                </small></strong
              >
            </div>
          </div>
          <div class="col-2 skewed_bg p-1 pl-3 pr-3">
            <div class="d-flex justify-content-between">
              <ejs-tooltip content="TV služby">
                <i
                  [ngClass]="
                                        televisionPrice.fee &&
                                        televisionPrice.fee > 0
                                            ? 'text-success'
                                            : 'text-warning'
                                    "
                  aria-hidden="true"
                  class="fa-light fa-2x fa-tv mr-2"
                ></i>
                <span
                  *ngIf="
                                        televisionPrice.discount + discount > 0
                                    "
                  class="e-badge e-badge-danger mr-2"
                >Sleva:
                  {{ televisionPrice.discount + discount }} %
                                    z ceny</span
                >
              </ejs-tooltip>
              <small
                *ngIf="
                                    setttopboxFee > 0 ||
                                    setttopboxFeeAnnualy > 0
                                "
                class="text-muted"
              >+ settop box</small
              >
              <strong class="pt-1 text-right">
                {{
                  (televisionPrice.period===12
                      ? televisionPriceAnnualy +
                      setttopboxFeeAnnualy
                      :televisionPrice.fee + setttopboxFee
                  ) | number: '1.2-2'
                }}
                ,- CZK<small class="text-muted">
                /
                <ng-container
                  *ngIf="televisionPrice.period === 1"
                >měsíčně
                </ng-container>
                <ng-container
                  *ngIf="televisionPrice.period === 12"
                >
                  ročně<br />ušetřeno:&nbsp;{{
                    ((televisionPriceAnnualy +
                        setttopboxFeeAnnualy) *
                      (discount +
                        televisionPrice.discount)) /
                    (100 -
                      (discount +
                        televisionPrice.discount))
                      | number: '1.2-2'
                  }}
                  ,- CZK
                </ng-container>
              </small></strong
              >
            </div>
          </div>
          <div class="col-2 skewed_bg p-1 pl-3 pr-3">
            <div class="d-flex justify-content-between">
              <ejs-tooltip content="Wi-Fi služby">
                <i
                  [ngClass]="
                                        wifiPrice.fee && wifiPrice.fee > 0
                                            ? 'text-success'
                                            : 'text-warning'
                                    "
                  aria-hidden="true"
                  class="fa-light fa-2x fa-wifi mr-2"
                ></i>
                <span
                  *ngIf="wifiPrice.discount + discount > 0"
                  class="e-badge e-badge-danger mr-2"
                >Sleva:
                  {{ wifiPrice.discount + discount }} % z
                                    ceny</span
                >
              </ejs-tooltip>
              <small class="text-muted">+ router</small>
              <strong class="pt-1 text-right"
              >{{
                  (wifiPrice.period===12
                      ? wifiPriceAnnualy
                      :wifiPrice.fee
                  ) | number: '1.2-2'
                }}
                ,- CZK
                <small class="text-muted">
                  /
                  <ng-container *ngIf="wifiPrice.period === 1"
                  >měsíčně
                  </ng-container>
                  <ng-container
                    *ngIf="wifiPrice.period === 12"
                  >
                    ročně<br />ušetřeno:&nbsp;{{
                      (wifiPriceAnnualy *
                        (discount +
                          wifiPrice.discount)) /
                      (100 -
                        (discount +
                          wifiPrice.discount))
                        | number: '1.2-2'
                    }}
                    ,- CZK
                  </ng-container>
                </small>
              </strong>
            </div>
          </div>
          <div class="col-2 skewed_bg p-1 pl-3 pr-3">
            <div class="d-flex justify-content-between">
              <ejs-tooltip content="Doplňkové služby">
                <i
                  [ngClass]="
                                        extServicesPrice.fee &&
                                        extServicesPrice.fee > 0
                                            ? 'text-success'
                                            : 'text-warning'
                                    "
                  aria-hidden="true"
                  class="fa-light fa-2x fa-boxes mr-2"
                ></i>
                <span
                  *ngIf="
                                        extServicesPrice.discount + discount > 0
                                    "
                  class="e-badge e-badge-danger mr-2"
                >Sleva:
                  {{ extServicesPrice.discount + discount }} %
                                    z ceny</span
                >
              </ejs-tooltip>
              <strong class="pt-1 text-right"
              >{{
                  (extServicesPrice.period===12
                      ? extServicesPriceAnnualy
                      :extServicesPrice.fee
                  ) | number: '1.2-2'
                }}
                ,- CZK<small class="text-muted">
                  /
                  <ng-container
                    *ngIf="extServicesPrice.period === 1"
                  >měsíčně
                  </ng-container>
                  <ng-container
                    *ngIf="extServicesPrice.period === 12"
                  >
                    ročně<br />ušetřeno:&nbsp;{{
                      (extServicesPriceAnnualy *
                        (discount +
                          extServicesPrice.discount)) /
                      (100 -
                        (discount +
                          extServicesPrice.discount))
                        | number: '1.2-2'
                    }}
                    ,- CZK
                  </ng-container>
                </small></strong
              >
            </div>
          </div>
          <div class="col-4 text-right pt-2">
                        <span
                          *ngIf="discount > 0"
                          class="e-badge e-badge-danger mr-2"
                        >Sleva: {{ discount }} % z ceny</span
                        >
            <strong
            >Celkem: {{ totalPrice.fee | number: '1.2-2' }},-
              CZK s DPH
              <small class="text-muted">
                /
                <ng-container *ngIf="totalPrice.period === 1">
                  měsíčně
                </ng-container>
                <ng-container *ngIf="totalPrice.period === 12">
                  ročně (ušetřeno:&nbsp;{{
                    (totalPrice.fee * discount) /
                    (100 - discount) | number: '1.2-2'
                  }}
                  ,- CZK)
                </ng-container>
              </small> </strong
            >&nbsp;|&nbsp;
            <strong
            >Celkem: {{ totalPrice.price | number: '1.2-2' }},-
              CZK s DPH
              <small class="text-muted"> / jednorázově</small>
            </strong>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 pt-2">
                    <span
                      *ngIf="orderForm.invalid"
                      class="e-badge e-badge-warning p-2 size_2"
                      role="alert"
                    >
                        <strong>Formulář je špatně vyplněný</strong>
                    </span>
          <span
            *ngIf="orderForm.valid"
            class="e-badge e-badge-success p-2 size_2"
            role="alert"
          >
                        <strong>Formulář je správně vyplněný</strong>
                    </span>
          <button
            (click)="onSubmit()"
            [disabled]="
                            loadingOrder || orderForm.invalid || !isChecked
                        "
            cssClass="e-btn e-success btn-p-zero e-small mr-1"
            ejs-button
            type="submit"
          >
                        <span
                          *ngIf="loadingOrder === true"
                          aria-hidden="true"
                          class="spinner-grow spinner-grow-sm"
                          role="status"
                        ></span>
            <span *ngIf="loadingOrder === false">ULOŽIT</span>
            <span *ngIf="loadingOrder === true">Provádím...</span>
          </button>
          <button
            (click)="formDialogObj.hide()"
            cssClass="e-btn e-danger e-outline btn-p-zero e-small"
            ejs-button
            type="button"
          >
            ZRUŠIT
          </button>
        </div>
      </div>
    </ng-template>
  </form>
</ejs-dialog>
