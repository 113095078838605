import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {MessageService} from '@src/app/_services/message.service';
import {DataService} from '@src/app/_services/data.service';
import {shareReplay} from 'rxjs/operators';
import {Notifications} from '@src/app/_models/notification/notification';
import {EnvironmentService} from '@src/app/_services/environment.service';

const httpOptions = {
    headers: new HttpHeaders({'Content-Type': 'application/json'}),
};

@Injectable({
    providedIn: 'root',
})
export class NotificationService {
    private readonly notificationsUrl: string;

    notificationsOld$: Observable<Notifications[] | undefined>;

    notificationsNew$: Observable<Notifications[] | undefined>;

    constructor(
        private readonly http: HttpClient,
        private readonly dataService: DataService,
        private readonly messageService: MessageService,
        private readonly environmentService: EnvironmentService,
    ) {
        this.notificationsOld$ = this.dataService.oldNotificationSource.pipe(shareReplay());
        this.notificationsNew$ = this.dataService.newNotificationSource.pipe(shareReplay());
        this.notificationsUrl = this.environmentService.backendURL + '/api/notify';
    }

    /**
     * Handle Http operation that failed.
     * Let the app continue.
     * @param operation - name of the operation that failed
     * @param result - optional value to return as the observable result
     */
    private handleError<T>(operation = 'operation', result?: T) {
        return (error: {[key: string]: string}): Observable<T | undefined> => {
            console.error(error);
            this.log(`${operation} failed: ${error.message}`);

            return of(result);
        };
    }

    /** Log a knowledgeService message with the MessageService */
    private log(message: string): void {
        this.messageService.addNotification(`Knowledge: ${message}`, false);
    }

    allAsRead(userId: number): Observable<boolean> {
        const url = `${this.notificationsUrl}/allAsRead`;

        this.dataService.clearTicketsCache();

        return this.http.put<boolean>(url, {user_id: userId}, httpOptions);
    }
}
