<div
  *ngIf="tableLoad"
  style="
        display: block !important;
        z-index: 1000000;
        width: 250px;
        height: 250px;
        margin: auto;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    "
>
  <div class="loader">
    <span></span>
    <span></span>
    <span></span>
    <span></span>
  </div>
  <div
    class="lds-circle"
    style="
            margin: auto;
            position: absolute;
            width: 150px;
            height: auto;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        "
  >
    <div></div>
  </div>
</div>
<div class="row d-flex justify-content-center modalWrapper">
  <ejs-dialog
    #confirmationDelete
    [buttons]="buttonsDelete"
    [content]="'Opravdu chcete smazat označený úkol?'"
    [header]="'Smazat úkol'"
    [isModal]="true"
    [showCloseIcon]="true"
    [visible]="false"
    width="250px"
  ></ejs-dialog>
  <ejs-dialog
    #confirmationCancel
    [buttons]="buttonsCancel"
    [header]="'Nelze provést akci'"
    [isModal]="true"
    [showCloseIcon]="true"
    [visible]="false"
    width="250px"
  ></ejs-dialog>
  <app-task-form
    (taskFormDialogState)="
            renderTaskFormDialog = $event;
            isCreate = $event;
            isUpdate = $event;
            isCopy = $event;
            gridTasks?.clearRowSelection();
            disableToolBarBtn()
        "
    [isCopy]="isCopy"
    [isCreate]="isCreate"
    [isUpdate]="isUpdate"
    [isVisible]="renderTaskFormDialog"
    [task]="
            clickedRow && clickedRow[0] && (isUpdate || isCopy)
                ? clickedRow[0]
                : null
        "
  ></app-task-form>
</div>
<div class="control-section">
  <ejs-toolbar id="filterToolbar" overflowMode="Scrollable" scrollStep="500">
    <e-items>
      <e-item
        [cssClass]="'statusFilter'"
        tooltipText="Přednastavené hledání"
        type="input"
      >
        <ng-template #template>
          <ejs-dropdownlist
            #viewFilter
            (change)="onStatusFilterChange($event)"
            [dataSource]="categoryFilterData"
            [width]="500"
            placeholder="Vyberte přednastavený pohled..."
          ></ejs-dropdownlist>
        </ng-template>
      </e-item>
      <e-item type="Separator"></e-item>
      <e-item
        [cssClass]="'fulltextFilter'"
        tooltipText="Text pro vyhledávání"
        type="input"
      >
        <ng-template #template>
          <ejs-textbox
            #searchtext
            [width]="500"
            class="mr-2"
            placeholder="Vyhledat..."
          ></ejs-textbox>
          <button
            (click)="search()"
            class="e-btn e-info btn-p-zero e-small"
          >
            <i class="fa-light fa-lg fa-search mr-1"></i
            ><span class="d-none d-lg-inline">vyhledat</span>
          </button>
          <button
            (click)="cancelBtnClick()"
            class="e-btn e-danger btn-p-zero e-small"
          >
            <i class="fa-light fa-lg fa-times"></i>
          </button>
        </ng-template>
      </e-item>
    </e-items>
  </ejs-toolbar>
  <div
    class="justify-content-center text-center"
    id="filterTagsToolbar"
    style="background-color: rgba(255, 255, 255, 0.6)"
  >
    <form
      (submit)="filterTags()"
      [formGroup]="filterTagsForm"
      class="col-12 p-1"
      id="filterTags"
      style="border-top: #cfcfcf solid thin"
    >
      <tag-input
        (onAdd)="onAdd()"
        (onRemove)="onRemove()"
        [displayBy]="'name'"
        [identifyBy]="'id'"
        [onlyFromAutocomplete]="true"
        formControlName="tags"
      >
        <tag-input-dropdown
          [autocompleteItems]="tagsAutocomplete"
          [displayBy]="'name'"
          [identifyBy]="'id'"
        >
        </tag-input-dropdown>
      </tag-input>
    </form>
  </div>
  <div class="scroll_wrapper1" id="scroll_wrapper1_tasks">
    <div class="scroll_div" id="scroll_div_tasks"></div>
  </div>
  <div class="scroll_wrapper2" id="scroll_wrapper2_tasks">
    <div class="grid_parent" id="grid_parent_tasks">
      <ejs-grid
        #tasksGrid
        (actionBegin)="actionBegin($event)"
        (actionComplete)="actionComplete($event)"
        (created)="onCreated()"
        (dataBound)="onDataBound()"
        (load)="onLoad()"
        (loadeddata)="setInitialGridFiltering()"
        (rowDataBound)="rowDataBound($event)"
        (rowDeselected)="rowSelected()"
        (rowSelected)="rowSelected()"
        (toolbarClick)="toolbarClick($event)"
        [allowExcelExport]="true"
        [allowFiltering]="true"
        [allowMultiSorting]="true"
        [allowPdfExport]="false"
        [allowSelection]="true"
        [allowSorting]="true"
        [dataSource]="data"
        [editSettings]="editSettings"
        [enableHover]="false"
        [enablePersistence]="false"
        [enableVirtualization]="true"
        [filterSettings]="filterSettings"
        [groupSettings]="groupOptions"
        [pageSettings]="pageOptions"
        [selectionSettings]="selectionSettings"
        [sortSettings]="sortOptions"
        [toolbar]="toolbar"
        allowGrouping="true"
        allowReordering="true"
        allowResizing="true"
        allowTextWrap="true"
        height="100%"
        id="tasksGrid"
        locale="cs"
        rowHeight="38"
        showColumnMenu="true"
        width="100%"
      >
        <e-columns>
          <e-column
            [allowEditing]="false"
            [allowFiltering]="false"
            [allowReordering]="false"
            [allowResizing]="false"
            [allowSorting]="false"
            [autoFit]="true"
            type="checkbox"
          ></e-column>
          <e-column
            [allowFiltering]="false"
            [allowReordering]="true"
            [allowResizing]="false"
            [autoFit]="true"
            [isPrimaryKey]="true"
            [visible]="false"
            field="id"
            headerText="ID"
          >
            <ng-template #template let-data>
              <a [routerLink]="['/tasks/detail/', data.id]">
                {{ data.id }}
              </a>
            </ng-template>
          </e-column>
          <e-column
            [allowEditing]="false"
            [allowFiltering]="true"
            [allowReordering]="false"
            [allowResizing]="false"
            [autoFit]="true"
            [visible]="false"
            field="user_id"
            headerText="User ID"
          ></e-column>
          <e-column
            [allowEditing]="false"
            [allowFiltering]="true"
            [allowReordering]="false"
            [allowResizing]="false"
            [autoFit]="true"
            [visible]="false"
            field="category_id"
            headerText="Category ID"
          ></e-column>
          <e-column
            [allowEditing]="false"
            [allowFiltering]="true"
            [allowReordering]="false"
            [allowResizing]="false"
            [autoFit]="true"
            [visible]="false"
            field="status_id"
            headerText="Status ID"
          ></e-column>
          <e-column
            [allowEditing]="false"
            [allowFiltering]="true"
            [allowReordering]="false"
            [allowResizing]="false"
            [autoFit]="true"
            [visible]="false"
            field="created_by"
            headerText="Creator ID"
          ></e-column>
          <e-column
            [autoFit]="true"
            [filter]="filterSettings"
            clipMode="EllipsisWithTooltip"
            field="user_str"
            headerText="Řešitel"
          >
          </e-column>
          <e-column
            [autoFit]="true"
            [filter]="filterSettings"
            [textAlign]="'left'"
            clipMode="EllipsisWithTooltip"
            field="creator_str"
            headerText="Vytvořil"
          >
          </e-column>
          <e-column
            [autoFit]="true"
            [filter]="filterSettings"
            [textAlign]="'left'"
            clipMode="EllipsisWithTooltip"
            field="subject"
            headerText="Předmět"
          ></e-column>
          <e-column
            [autoFit]="true"
            [filter]="filterSettings"
            [textAlign]="'center'"
            clipMode="EllipsisWithTooltip"
            field="status"
            headerText="Stav"
          >
            <ng-template #template let-data>
                            <span
                              *ngIf="data.status === 'dokončen'"
                              class="e-badge e-badge-pill e-badge-success"
                            >dokončen</span
                            >
              <span
                *ngIf="data.status === 've frontě'"
                class="e-badge e-badge-pill e-badge-warning"
              >ve frontě</span
              >
              <span
                *ngIf="data.status === 'smazán'"
                class="e-badge e-badge-pill e-badge-danger"
              >smazán</span
              >
            </ng-template>
          </e-column>
          <e-column
            [autoFit]="true"
            [disableHtmlEncode]="false"
            [filter]="filterSettings"
            [textAlign]="'left'"
            clipMode="EllipsisWithTooltip"
            field="maintask"
            headerText="Zadání"
          >
            <ng-template #template let-data>
              <div
                [innerHTML]="data.maintask | truncateText: 300"
              ></div>
            </ng-template>
          </e-column>
          <e-column
            [autoFit]="true"
            [filter]="filterSettings"
            [textAlign]="'center'"
            clipMode="EllipsisWithTooltip"
            field="due"
            headerText="Status termínu"
          >
            <ng-template #template let-data>
              <span
                *ngIf="data.due === 'v termínu'"
                class="e-badge e-badge-pill e-badge-success"
              >v termínu</span
              >
              <span
                *ngIf="data.due === 'po termínu'"
                class="e-badge e-badge-pill e-badge-danger"
              >po termínu</span
              >
            </ng-template>
          </e-column>
          <e-column
            [autoFit]="true"
            [filter]="filterSettings"
            [textAlign]="'center'"
            clipMode="EllipsisWithTooltip"
            field="category"
            headerText="Kategorie"
          ></e-column>
          <e-column
            [autoFit]="true"
            [filter]="filterSettings"
            clipMode="EllipsisWithTooltip"
            field="tags"
            headerText="Štítky"
          >
            <ng-template #template let-data>
              <div [innerHTML]="data.tags"></div>
            </ng-template>
          </e-column>
          <e-column
            [allowFiltering]="true"
            [allowReordering]="false"
            [allowResizing]="false"
            [autoFit]="true"
            [visible]="true"
            field="ticket_id"
            headerText="Ticket ID"
          >
            <ng-template #template let-data>
              <a [routerLink]="['/tickets/detail/', data.ticket_id]">
                {{ data.ticket_id }}
              </a>
            </ng-template>
          </e-column>
          <e-column
            [allowEditing]="false"
            [autoFit]="true"
            [filter]="filterDate"
            [format]="dateFormat"
            [textAlign]="'right'"
            clipMode="EllipsisWithTooltip"
            editType="datepickeredit"
            field="finished_at"
            headerText="Dokončeno"
            type="date"
          ></e-column>
          <e-column
            [allowEditing]="false"
            [autoFit]="true"
            [filter]="filterDate"
            [format]="dateFormat"
            [textAlign]="'right'"
            clipMode="EllipsisWithTooltip"
            editType="datepickeredit"
            field="deadline"
            headerText="Termín"
            type="date"
          ></e-column>
          <e-column
            [allowEditing]="false"
            [autoFit]="true"
            [filter]="filterDate"
            [format]="dateFormat"
            [textAlign]="'right'"
            clipMode="EllipsisWithTooltip"
            editType="datepickeredit"
            field="created_date"
            headerText="Vytvořeno"
            type="date"
          ></e-column>
          <e-column
            [allowEditing]="false"
            [autoFit]="true"
            [filter]="filterDate"
            [format]="dateFormat"
            [textAlign]="'right'"
            clipMode="EllipsisWithTooltip"
            editType="datepickeredit"
            field="updated_date"
            headerText="Naposledy upraveno"
            type="date"
          ></e-column>
          <e-column
            [allowEditing]="false"
            [autoFit]="true"
            [filter]="filterDate"
            [format]="dateFormat"
            [textAlign]="'right'"
            clipMode="EllipsisWithTooltip"
            editType="datepickeredit"
            field="deleted_date"
            headerText="Smazáno"
            type="date"
          ></e-column>
        </e-columns>
        <e-aggregates>
          <e-aggregate>
            <e-columns>
              <e-column [format]="'N'" field="id" type="Count">
                <ng-template #footerTemplate let-data>
                  Celkem řádků: {{ data.Count }}
                </ng-template
                >
              </e-column>
            </e-columns>
          </e-aggregate>
        </e-aggregates>
      </ejs-grid>
    </div>
  </div>
</div>
