<div
  *ngIf="tableLoad"
  style="
        display: block !important;
        z-index: 1000000;
        width: 250px;
        height: 250px;
        margin: auto;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    "
>
  <div class="loader">
    <span></span>
    <span></span>
    <span></span>
    <span></span>
  </div>
  <div
    class="lds-circle"
    style="
            margin: auto;
            position: absolute;
            width: 150px;
            height: auto;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        "
  >
    <div></div>
  </div>
</div>
<div class="row d-flex justify-content-center modalWrapper">
  <ejs-dialog
    #confirmationDeleteTvTarif
    [buttons]="buttonsDeleteTvTarif"
    [content]="'Opravdu chcete smazat televizní tarif?'"
    [header]="'Smazat TELEVIZNÍ tarif'"
    [isModal]="true"
    [showCloseIcon]="true"
    [visible]="false"
    width="250px"
  ></ejs-dialog>
  <ejs-dialog
    #confirmationDeleteNetTarif
    [buttons]="buttonsDeleteNetTarif"
    [content]="'Opravdu chcete smazat označený tarif?'"
    [header]="'Smazat INTERNET tarif'"
    [isModal]="true"
    [showCloseIcon]="true"
    [visible]="false"
    width="250px"
  ></ejs-dialog>
  <ejs-dialog
    #confirmationCancel
    [buttons]="buttonsCancel"
    [header]="'Nelze provést akci'"
    [isModal]="true"
    [showCloseIcon]="true"
    [visible]="false"
    width="250px"
  ></ejs-dialog>
  <app-net-package-form
    (netPackageFormDialogState)="
            renderNetPackageFormDialog = $event;
            isCreateNet = $event;
            isUpdateNet = $event;
            isCopyNet = $event
        "
    [isCopy]="isCopyNet"
    [isCreate]="isCreateNet"
    [isUpdate]="isUpdateNet"
    [isVisible]="renderNetPackageFormDialog"
    [netPackage]="
            clickedNetRow && clickedNetRow[0] && (isUpdateNet || isCopyNet)
                ? clickedNetRow[0]
                : null
        "
  ></app-net-package-form>
  <app-tv-service-form
    (tvServiceFormDialogState)="
            renderTvServiceFormDialog = $event;
            isCreateTv = $event;
            isUpdateTv = $event;
            isCopyTv = $event
        "
    [isCopy]="isCopyTv"
    [isCreate]="isCreateTv"
    [isUpdate]="isUpdateTv"
    [isVisible]="renderTvServiceFormDialog"
    [tvService]="
            clickedTvRow && clickedTvRow[0] && (isUpdateTv || isCopyTv)
                ? clickedTvRow[0]
                : null
        "
  ></app-tv-service-form>
</div>
<div class="row">
  <div class="col-12 mt-2">
    <h5>Tarify INTERNET</h5>
    <hr />
    <ejs-toolbar
      id="filterNetToolbar"
      overflowMode="Scrollable"
      scrollStep="500"
    >
      <e-items>
        <e-item
          [cssClass]="'statusFilter'"
          tooltipText="Přednastavené hledání"
          type="input"
        >
          <ng-template #template>
            <ejs-dropdownlist
              #viewFilterNet
              (change)="onStatusFilterChangeNet($event)"
              [dataSource]="categoryFilterNetData"
              [width]="500"
              placeholder="Vyberte přednastavený pohled..."
            ></ejs-dropdownlist>
          </ng-template>
        </e-item>
        <e-item type="Separator"></e-item>
        <e-item
          [cssClass]="'fulltextFilter'"
          tooltipText="Text pro vyhledávání"
          type="input"
        >
          <ng-template #template>
            <ejs-textbox
              #searchtextNet
              [width]="500"
              class="mr-2"
              placeholder="Vyhledat..."
            ></ejs-textbox>
            <button
              (click)="searchNet()"
              class="e-btn e-info btn-p-zero e-small"
            >
              <i class="fa-light fa-lg fa-search mr-1"></i
              ><span class="d-none d-lg-inline">vyhledat</span>
            </button>
            <button
              (click)="cancelBtnNetClick()"
              class="e-btn e-danger btn-p-zero e-small"
            >
              <i class="fa-light fa-lg fa-times"></i>
            </button>
          </ng-template>
        </e-item>
      </e-items>
    </ejs-toolbar>
    <div class="control-section">
      <div class="scroll_wrapper1" id="scroll_wrapper1_net">
        <div class="scroll_div" id="scroll_div_net"></div>
      </div>
      <div class="scroll_wrapper2" id="scroll_wrapper2_net">
        <div class="grid_parent" id="grid_parent_net">
          <ejs-grid
            #netTarifsGrid
            (actionBegin)="actionBeginNet($event)"
            (actionComplete)="actionComplete($event)"
            (created)="onCreatedNet()"
            (dataBound)="onDataBoundNet()"
            (load)="onLoadNet()"
            (loadeddata)="setInitialGridFiltering()"
            (rowDataBound)="rowDataBoundNet($event)"
            (rowDeselected)="rowSelectedNet()"
            (rowSelected)="rowSelectedNet()"
            (toolbarClick)="toolbarClickNet($event)"
            [allowExcelExport]="true"
            [allowFiltering]="true"
            [allowMultiSorting]="true"
            [allowPdfExport]="false"
            [allowSelection]="true"
            [allowSorting]="true"
            [dataSource]="dataNet"
            [editSettings]="editSettings"
            [enableHover]="false"
            [enablePersistence]="false"
            [enableVirtualization]="true"
            [filterSettings]="filterSettings"
            [groupSettings]="groupOptions"
            [pageSettings]="pageOptions"
            [selectionSettings]="selectionSettings"
            [sortSettings]="sortOptionsNet"
            [toolbar]="toolbarNet"
            allowGrouping="true"
            allowReordering="true"
            allowResizing="true"
            allowTextWrap="true"
            height="100%"
            id="netTarifsGrid"
            locale="cs"
            rowHeight="38"
            showColumnMenu="true"
            width="100%"
          >
            <e-columns>
              <e-column
                [allowEditing]="false"
                [allowFiltering]="false"
                [allowReordering]="false"
                [allowResizing]="false"
                [allowSorting]="false"
                [autoFit]="true"
                type="checkbox"
              ></e-column>
              <e-column
                [allowFiltering]="false"
                [allowReordering]="true"
                [allowResizing]="false"
                [autoFit]="true"
                [isPrimaryKey]="true"
                [textAlign]="'left'"
                [visible]="false"
                field="id"
                headerText="ID"
              >
                <ng-template #template let-data>
                  {{ data.id }}
                </ng-template>
              </e-column>
              <e-column
                [allowEditing]="false"
                [allowFiltering]="true"
                [allowReordering]="false"
                [allowResizing]="false"
                [autoFit]="true"
                [textAlign]="'left'"
                [visible]="false"
                field="created_by"
                headerText="Creator ID"
              ></e-column>
              <e-column
                [autoFit]="true"
                [filter]="filterSettings"
                [textAlign]="'left'"
                clipMode="EllipsisWithTooltip"
                field="name"
                headerText="Název"
              >
              </e-column>
              <e-column
                [autoFit]="true"
                [disableHtmlEncode]="false"
                [filter]="filterSettings"
                [textAlign]="'left'"
                clipMode="EllipsisWithTooltip"
                field="provider"
                headerText="Provider"
              >
              </e-column>
              <e-column
                [autoFit]="true"
                [filter]="filterSettings"
                [textAlign]="'center'"
                clipMode="EllipsisWithTooltip"
                field="aggregation"
                headerText="Agregace"
              ></e-column>
              <e-column
                [autoFit]="true"
                [filter]="filterSettings"
                [textAlign]="'center'"
                clipMode="EllipsisWithTooltip"
                field="transfer"
                headerText="Typ přenosu"
              >
                <ng-template #template let-data>
                  <div [innerHTML]="data.transfer"></div>
                </ng-template>
              </e-column>
              <e-column
                [autoFit]="true"
                [filter]="filterSettings"
                [textAlign]="'center'"
                clipMode="EllipsisWithTooltip"
                field="recommended"
                headerText="Doporučeno"
              >
                <ng-template #template let-data>
                                    <span
                                      *ngIf="data.recommended"
                                      class="e-badge e-badge-pill e-badge-success"
                                    >ANO</span
                                    >
                  <span
                    *ngIf="!data.recommended"
                    class="e-badge e-badge-pill e-badge-danger"
                  >NE</span
                  >
                </ng-template>
              </e-column>
              <e-column
                [autoFit]="true"
                [filter]="filterSettings"
                [textAlign]="'center'"
                clipMode="EllipsisWithTooltip"
                field="extension"
                headerText="Rozšíření"
              >
                <ng-template #template let-data>
                                    <span
                                      *ngIf="data.extension"
                                      class="e-badge e-badge-pill e-badge-success"
                                    >ANO</span
                                    >
                  <span
                    *ngIf="!data.extension"
                    class="e-badge e-badge-pill e-badge-danger"
                  >NE</span
                  >
                </ng-template>
              </e-column>
              <e-column
                [autoFit]="true"
                [filter]="filterSettings"
                [format]="'N'"
                [textAlign]="'right'"
                clipMode="EllipsisWithTooltip"
                field="speed_up"
                headerText="Upload"
              >
              </e-column>
              <e-column
                [autoFit]="true"
                [filter]="filterSettings"
                [format]="'N'"
                [textAlign]="'right'"
                clipMode="EllipsisWithTooltip"
                field="speed_down"
                headerText="Download"
              >
              </e-column>
              <e-column
                [autoFit]="true"
                [filter]="filterSettings"
                [format]="'#,###.00\',- CZK\''"
                [textAlign]="'right'"
                clipMode="EllipsisWithTooltip"
                field="price"
                headerText="Cena"
              >
              </e-column>
              <e-column
                [autoFit]="true"
                [filter]="filterSettings"
                [textAlign]="'right'"
                clipMode="EllipsisWithTooltip"
                field="discount"
                headerText="Sleva"
              >
              </e-column>
              <e-column
                [autoFit]="true"
                [filter]="filterSettings"
                [textAlign]="'right'"
                clipMode="EllipsisWithTooltip"
                field="sale_order"
                headerText="Pořadí nabídky"
              >
              </e-column>
              <e-column
                [autoFit]="true"
                [filter]="filterSettings"
                [format]="'N'"
                [textAlign]="'center'"
                clipMode="EllipsisWithTooltip"
                field="ext_services"
                headerText="Rozšiřující služby"
              >
                <ng-template #template let-data>
                  <div [innerHTML]="data.ext_services"></div>
                </ng-template>
              </e-column>
              <e-column
                [autoFit]="true"
                [filter]="filterSettings"
                [textAlign]="'center'"
                clipMode="EllipsisWithTooltip"
                field="net_places"
                headerText="Lokace"
              >
                <ng-template #template let-data>
                  <div [innerHTML]="data.net_places"></div>
                </ng-template>
              </e-column>
              <e-column
                [autoFit]="true"
                [filter]="filterSettings"
                [textAlign]="'center'"
                clipMode="EllipsisWithTooltip"
                field="net_bonuses"
                headerText="Bonusy"
              >
                <ng-template #template let-data>
                  <div [innerHTML]="data.net_bonuses"></div>
                </ng-template>
              </e-column>
              <e-column
                [autoFit]="true"
                [filter]="filterSettings"
                [textAlign]="'left'"
                clipMode="EllipsisWithTooltip"
                field="creator_str"
                headerText="Vytvořil"
              >
                <ng-template #template let-data>
                  <div>
                    <img
                      *ngIf="data.creator_img"
                      alt="profilový obrázek"
                      class="img-fluid rounded-circle avatar-xs mr-1"
                      src="assets/img{{
                                                data.creator_img
                                            }}"
                    />
                    <span>{{ data.creator_str }}</span>
                  </div>
                </ng-template>
              </e-column>
              <e-column
                [allowEditing]="false"
                [autoFit]="true"
                [filter]="filterDate"
                [format]="dateFormat"
                [textAlign]="'right'"
                clipMode="EllipsisWithTooltip"
                editType="datepickeredit"
                field="created_date"
                headerText="Vytvořeno"
                type="date"
              ></e-column>
              <e-column
                [allowEditing]="false"
                [autoFit]="true"
                [filter]="filterDate"
                [format]="dateFormat"
                [textAlign]="'right'"
                clipMode="EllipsisWithTooltip"
                editType="datepickeredit"
                field="updated_date"
                headerText="Naposledy upraveno"
                type="date"
              ></e-column>
              <e-column
                [allowEditing]="false"
                [autoFit]="true"
                [filter]="filterDate"
                [format]="dateFormat"
                [textAlign]="'right'"
                clipMode="EllipsisWithTooltip"
                editType="datepickeredit"
                field="deleted_date"
                headerText="Smazáno"
                type="date"
              ></e-column>
            </e-columns>
            <e-aggregates>
              <e-aggregate>
                <e-columns>
                  <e-column
                    [format]="'N'"
                    field="id"
                    type="Count"
                  >
                    <ng-template #footerTemplate let-data>
                      Celkem řádků:
                      {{ data.Count }}
                    </ng-template
                    >
                  </e-column>
                </e-columns>
              </e-aggregate>
            </e-aggregates>
          </ejs-grid>
        </div>
      </div>
    </div>
  </div>
  <div class="col-12 mt-2">
    <h5>Tarify TV</h5>
    <hr />
    <ejs-toolbar
      id="filterTvToolbar"
      overflowMode="Scrollable"
      scrollStep="500"
    >
      <e-items>
        <e-item
          [cssClass]="'statusFilter'"
          tooltipText="Přednastavené hledání"
          type="input"
        >
          <ng-template #template>
            <ejs-dropdownlist
              #viewFilterTv
              (change)="onStatusFilterChangeTv($event)"
              [dataSource]="categoryFilterTvData"
              [width]="500"
              placeholder="Vyberte přednastavený pohled..."
            ></ejs-dropdownlist>
          </ng-template>
        </e-item>
        <e-item type="Separator"></e-item>
        <e-item
          [cssClass]="'fulltextFilter'"
          tooltipText="Text pro vyhledávání"
          type="input"
        >
          <ng-template #template>
            <ejs-textbox
              #searchtextTv
              [width]="500"
              class="mr-2"
              placeholder="Vyhledat..."
            ></ejs-textbox>
            <button
              (click)="searchTv()"
              class="e-btn e-info btn-p-zero e-small"
            >
              <i class="fa-light fa-lg fa-search mr-1"></i
              ><span class="d-none d-lg-inline">vyhledat</span>
            </button>
            <button
              (click)="cancelBtnTvClick()"
              class="e-btn e-danger btn-p-zero e-small"
            >
              <i class="fa-light fa-lg fa-times"></i>
            </button>
          </ng-template>
        </e-item>
      </e-items>
    </ejs-toolbar>
    <div class="control-section">
      <div class="scroll_wrapper1" id="scroll_wrapper1_tv">
        <div class="scroll_div" id="scroll_div_tv"></div>
      </div>
      <div class="scroll_wrapper2" id="scroll_wrapper2_tv">
        <div class="grid_parent" id="grid_parent_tv">
          <ejs-grid
            #tvTarifsGrid
            (actionBegin)="actionBeginTv($event)"
            (actionComplete)="actionComplete($event)"
            (created)="onCreatedTv()"
            (dataBound)="onDataBoundTv()"
            (load)="onLoadTv()"
            (loadeddata)="setInitialGridFiltering()"
            (rowDataBound)="rowDataBoundTv($event)"
            (rowDeselected)="rowSelectedTv()"
            (rowSelected)="rowSelectedTv()"
            (toolbarClick)="toolbarClickTv($event)"
            [allowExcelExport]="true"
            [allowFiltering]="true"
            [allowMultiSorting]="true"
            [allowPdfExport]="false"
            [allowSelection]="true"
            [allowSorting]="true"
            [dataSource]="dataTv"
            [editSettings]="editSettings"
            [enableHover]="false"
            [enablePersistence]="false"
            [enableVirtualization]="true"
            [filterSettings]="filterSettings"
            [groupSettings]="groupOptions"
            [pageSettings]="pageOptions"
            [selectionSettings]="selectionSettings"
            [sortSettings]="sortOptionsTv"
            [toolbar]="toolbarTv"
            allowGrouping="true"
            allowReordering="true"
            allowResizing="true"
            allowTextWrap="true"
            height="100%"
            id="tvTarifsGrid"
            locale="cs"
            rowHeight="38"
            showColumnMenu="true"
            width="100%"
          >
            <e-columns>
              <e-column
                [allowEditing]="false"
                [allowFiltering]="false"
                [allowReordering]="false"
                [allowResizing]="false"
                [allowSorting]="false"
                [autoFit]="true"
                type="checkbox"
              ></e-column>
              <e-column
                [allowFiltering]="false"
                [allowReordering]="true"
                [allowResizing]="false"
                [autoFit]="true"
                [isPrimaryKey]="true"
                [textAlign]="'left'"
                [visible]="false"
                field="id"
                headerText="ID"
              >
                <ng-template #template let-data>
                  {{ data.id }}
                </ng-template>
              </e-column>
              <e-column
                [allowEditing]="false"
                [allowFiltering]="true"
                [allowReordering]="false"
                [allowResizing]="false"
                [autoFit]="true"
                [textAlign]="'left'"
                [visible]="false"
                field="created_by"
                headerText="Creator ID"
              ></e-column>
              <e-column
                [autoFit]="true"
                [filter]="filterSettings"
                [textAlign]="'left'"
                clipMode="EllipsisWithTooltip"
                field="name"
                headerText="Název"
              >
              </e-column>
              <e-column
                [autoFit]="true"
                [disableHtmlEncode]="false"
                [filter]="filterSettings"
                [textAlign]="'left'"
                clipMode="EllipsisWithTooltip"
                field="provider"
                headerText="Provider"
              >
              </e-column>
              <e-column
                [autoFit]="true"
                [filter]="filterSettings"
                [textAlign]="'center'"
                clipMode="EllipsisWithTooltip"
                field="aggregation"
                headerText="Agregace"
              ></e-column>
              <e-column
                [autoFit]="true"
                [filter]="filterSettings"
                [textAlign]="'center'"
                clipMode="EllipsisWithTooltip"
                field="transfer"
                headerText="Typ přenosu"
              >
                <ng-template #template let-data>
                  <div [innerHTML]="data.transfer"></div>
                </ng-template>
              </e-column>
              <e-column
                [autoFit]="true"
                [filter]="filterSettings"
                [textAlign]="'center'"
                clipMode="EllipsisWithTooltip"
                field="recommended"
                headerText="Doporučeno"
              >
                <ng-template #template let-data>
                                    <span
                                      *ngIf="data.recommended"
                                      class="e-badge e-badge-pill e-badge-success"
                                    >ANO</span
                                    >
                  <span
                    *ngIf="!data.recommended"
                    class="e-badge e-badge-pill e-badge-danger"
                  >NE</span
                  >
                </ng-template>
              </e-column>
              <e-column
                [autoFit]="true"
                [filter]="filterSettings"
                [textAlign]="'center'"
                clipMode="EllipsisWithTooltip"
                field="extension"
                headerText="Rozšíření"
              >
                <ng-template #template let-data>
                                    <span
                                      *ngIf="data.extension"
                                      class="e-badge e-badge-pill e-badge-success"
                                    >ANO</span
                                    >
                  <span
                    *ngIf="!data.extension"
                    class="e-badge e-badge-pill e-badge-danger"
                  >NE</span
                  >
                </ng-template>
              </e-column>
              <e-column
                [autoFit]="true"
                [filter]="filterSettings"
                [format]="'N'"
                [textAlign]="'right'"
                clipMode="EllipsisWithTooltip"
                field="speed_up"
                headerText="Upload"
              >
              </e-column>
              <e-column
                [autoFit]="true"
                [filter]="filterSettings"
                [format]="'N'"
                [textAlign]="'right'"
                clipMode="EllipsisWithTooltip"
                field="speed_down"
                headerText="Download"
              >
              </e-column>
              <e-column
                [autoFit]="true"
                [filter]="filterSettings"
                [format]="'#,###.00\',- CZK\''"
                [textAlign]="'right'"
                clipMode="EllipsisWithTooltip"
                field="price"
                headerText="Cena"
              >
              </e-column>
              <e-column
                [autoFit]="true"
                [filter]="filterSettings"
                [textAlign]="'right'"
                clipMode="EllipsisWithTooltip"
                field="discount"
                headerText="Sleva"
              >
              </e-column>
              <e-column
                [autoFit]="true"
                [filter]="filterSettings"
                [textAlign]="'right'"
                clipMode="EllipsisWithTooltip"
                field="sale_order"
                headerText="Pořadí nabídky"
              >
              </e-column>
              <e-column
                [autoFit]="true"
                [filter]="filterSettings"
                [textAlign]="'center'"
                clipMode="EllipsisWithTooltip"
                field="ext_services"
                headerText="Rozšiřující služby"
              >
                <ng-template #template let-data>
                  <div [innerHTML]="data.ext_services"></div>
                </ng-template>
              </e-column>
              <e-column
                [autoFit]="true"
                [filter]="filterSettings"
                [textAlign]="'center'"
                clipMode="EllipsisWithTooltip"
                field="net_places"
                headerText="Lokace"
              >
                <ng-template #template let-data>
                  <div [innerHTML]="data.net_places"></div>
                </ng-template>
              </e-column>
              <e-column
                [autoFit]="true"
                [filter]="filterSettings"
                [textAlign]="'center'"
                clipMode="EllipsisWithTooltip"
                field="net_bonuses"
                headerText="Bonusy"
              >
                <ng-template #template let-data>
                  <div [innerHTML]="data.net_bonuses"></div>
                </ng-template>
              </e-column>
              <e-column
                [autoFit]="true"
                [filter]="filterSettings"
                [textAlign]="'left'"
                clipMode="EllipsisWithTooltip"
                field="creator_str"
                headerText="Vytvořil"
              >
                <ng-template #template let-data>
                  <div>
                    <img
                      *ngIf="data.creator_img"
                      alt="profilový obrázek"
                      class="img-fluid rounded-circle avatar-xs mr-1"
                      src="assets/img{{
                                                data.creator_img
                                            }}"
                    />
                    <span>{{ data.creator_str }}</span>
                  </div>
                </ng-template>
              </e-column>
              <e-column
                [allowEditing]="false"
                [autoFit]="true"
                [filter]="filterDate"
                [format]="dateFormat"
                [textAlign]="'right'"
                clipMode="EllipsisWithTooltip"
                editType="datepickeredit"
                field="created_date"
                headerText="Vytvořeno"
                type="date"
              ></e-column>
              <e-column
                [allowEditing]="false"
                [autoFit]="true"
                [filter]="filterDate"
                [format]="dateFormat"
                [textAlign]="'right'"
                clipMode="EllipsisWithTooltip"
                editType="datepickeredit"
                field="updated_date"
                headerText="Naposledy upraveno"
                type="date"
              ></e-column>
              <e-column
                [allowEditing]="false"
                [autoFit]="true"
                [filter]="filterDate"
                [format]="dateFormat"
                [textAlign]="'right'"
                clipMode="EllipsisWithTooltip"
                editType="datepickeredit"
                field="deleted_date"
                headerText="Smazáno"
                type="date"
              ></e-column>
            </e-columns>
            <e-aggregates>
              <e-aggregate>
                <e-columns>
                  <e-column
                    [format]="'N'"
                    field="id"
                    type="Count"
                  >
                    <ng-template #footerTemplate let-data>
                      Celkem řádků:
                      {{ data.Count }}
                    </ng-template
                    >
                  </e-column>
                </e-columns>
              </e-aggregate>
            </e-aggregates>
          </ejs-grid>
        </div>
      </div>
    </div>
  </div>
</div>
