<ejs-dialog
  #formDialog
  (close)="
        isUpdate = false;
        isCreate = false;
        isDirty = false;
        isChecked = false;
        isCopy = false;
        submited = false;
        changeDialogState(false)
    "
  [isModal]="true"
  [showCloseIcon]="true"
  [visible]="isVisible"
  [width]="'85%'"
  id="formDialog"
>
  <form [formGroup]="addressUntrustedForm">
    <ng-template #content>
      <div class="dialogContent">
        <h6 class="text-muted pt-2">
          <i aria-hidden="true" class="fa-light fa-lg fa-home"></i
          >&nbsp;Adresa:
        </h6>
        <hr class="pt-0 mt-0" />
        <div class="row">
          <div class="col-12 mb-3 form-group">
            <label
              [ngClass]="
                                f.provider_id.valid
                                    ? 'text-success'
                                    : f.provider_id.invalid &&
                                      (f.provider_id.dirty ||
                                          f.provider_id.touched)
                                    ? 'text-danger'
                                    : 'text-muted'
                            "
              for="provider"
            >* Provider:</label
            >
            <ejs-dropdownlist
              #provider
              [allowFiltering]="true"
              [dataSource]="companySelect"
              [fields]="addrFieldObj"
              [filterBarPlaceholder]="'Vyhledat providera...'"
              [ignoreAccent]="true"
              [placeholder]="'Vyberte providera'"
              [popupHeight]="height"
              formControlName="provider_id"
              id="provider"
              sortOrder="Ascending"
            ></ejs-dropdownlist>
            <small
              *ngIf="
                                f.provider_id.touched &&
                                f.provider_id.errors &&
                                f.provider_id.errors.required
                            "
              class="text-danger"
            >Vyberte providera</small
            >
          </div>
        </div>
        <div class="row">
          <div class="col-9 mb-3 form-group">
            <label
              [ngClass]="
                                f.address.valid &&
                                (f.address.dirty || f.address.touched)
                                    ? 'text-success'
                                    : f.address.invalid &&
                                      (f.address.dirty || f.address.touched)
                                    ? 'text-danger'
                                    : 'text-muted'
                            "
              for="untrustedAddress"
            >* RUIAN Adresa:</label
            >
            <ejs-textbox
              [ngClass]="
                                f.address.touched && f.address.invalid
                                    ? 'e-error'
                                    : f.address.touched && f.address.valid
                                    ? 'e-success'
                                    : ''
                            "
              autocomplete="smartform-auto-address"
              class="smartform-whole-address smartform-instance-address"
              formControlName="address"
              id="untrustedAddress"
            >
            </ejs-textbox>
            <small class="text-muted mr-3"
            >Vyberte jakoukoli RUIAN adresu z našeptávače v
              oblasti, kterou chcete přidat.</small
            >
            <small
              *ngIf="
                                f.address.touched &&
                                f.address.errors &&
                                f.address.errors.required
                            "
              class="text-danger"
            >Vyberte adresu pomocí našeptávače</small
            >
            <button
              class="e-btn e-info e-outline btn-p-zero e-small m-0"
              ejs-button
              onclick="loadMapyCZ('untrustedAddresses')"
              title="pomoc najít adresu pro našeptávač"
              type="button"
            >
              <i
                aria-hidden="true"
                class="fa-light fa-lg fa-map-marked-alt mr-2"
              ></i
              >Najít adresu pomocí mapy
            </button>
          </div>
          <div class="col-3 mb-3 form-group">
            <label
              [ngClass]="
                                f.ruian.valid &&
                                (f.ruian.dirty || f.ruian.touched)
                                    ? 'text-success'
                                    : f.ruian.invalid &&
                                      (f.ruian.dirty || f.ruian.touched)
                                    ? 'text-danger'
                                    : 'text-muted'
                            "
              for="ruian"
            >* RUIAN:</label
            >
            <ejs-numerictextbox
              [format]="'###'"
              [ngClass]="
                                f.ruian.touched && f.ruian.invalid
                                    ? 'e-error'
                                    : f.ruian.touched && f.ruian.valid
                                    ? 'e-success'
                                    : ''
                            "
              formControlName="ruian"
              id="ruian"
              min="0"
              step="1"
            ></ejs-numerictextbox>
            <small class="text-warning"
            >Tento údaj je doplňován automaticky</small
            >
            <small
              *ngIf="
                                f.ruian.touched &&
                                f.ruian.errors &&
                                f.ruian.errors.required
                            "
              class="text-danger"
            >kód RUIAN je povinný</small
            >
            <div
              *ngIf="!address && address === null"
              class="alert alert-warning p-0"
              role="alert"
            >
              Vyberte adresu z našeptávače
            </div>
            <div
              *ngIf="unAddress"
              class="alert alert-danger p-1"
              role="alert"
            >
              RUIAN již existuje
              <span
                *ngIf="unAddress && unAddress.deleted_date"
                class="e-badge e-badge-danger float-right"
              >smazaná adresa</span
              >
              <span
                *ngIf="unAddress && !unAddress.deleted_date"
                class="e-badge e-badge-success float-right"
              >aktivní adresa</span
              >
            </div>
            <div
              *ngIf="!unAddress && unAddress === false"
              class="alert alert-success p-0"
              role="alert"
            >
              RUIAN je zkontrolován
            </div>
          </div>
          <div
            class="col-12 mb-3 form-group"
            id="mapyParentUntrusted"
            style="display: none"
          >
            <div class="w-100">
              <input
                class="w-100"
                id="mapaSuggestUntrusted"
                placeholder="Sem napiště oblast kde budete hledat adresu..."
              />
              <small class="text-muted"
              >Kliknutím na budovu s číslem popisným,
                zkopírujete celou adresu do vaší schránky, potom
                můžete vložit do pole pro vyhledání RUIAN
                adresy.</small
              >
            </div>
            <div id="mapaUntrusted"></div>
          </div>
        </div>
        <h6 class="text-muted pt-2">
          <i
            aria-hidden="true"
            class="fa-light fa-lg fa-sticky-note"
          ></i
          >&nbsp;Popis:
        </h6>
        <hr class="pt-0 mt-0" />
        <div class="row">
          <div class="col-12 mb-2 form-group">
            <label
              [ngClass]="
                                f.descriptive.valid
                                    ? 'text-success'
                                    : f.descriptive.invalid &&
                                      (f.descriptive.dirty ||
                                          f.descriptive.touched)
                                    ? 'text-danger'
                                    : 'text-muted'
                            "
              for="descriptive"
            >Popis:</label
            >
            <ejs-textbox
              [multiline]="true"
              [ngClass]="{
                                'e-error': submited && f.descriptive.errors
                            }"
              floatLabelType="Auto"
              formControlName="descriptive"
              id="descriptive"
            ></ejs-textbox>
            <small
              *ngIf="
                                (f.descriptive.dirty ||
                                    f.descriptive.touched) &&
                                f.descriptive.errors &&
                                f.descriptive.errors.required
                            "
              class="text-danger"
            >Popis vyloučené adresy</small
            >
          </div>
        </div>
      </div>
    </ng-template>
    <ng-template #footerTemplate>
      <div class="d-flex justify-content-end">
                <span
                  *ngIf="addressUntrustedForm.invalid"
                  class="e-badge e-badge-warning p-2 size_2"
                  role="alert"
                >
                    <strong>Formulář je špatně vyplněný</strong>
                </span>
        <span
          *ngIf="addressUntrustedForm.valid"
          class="e-badge e-badge-success p-2 size_2"
          role="alert"
        >
                    <strong>Formulář je správně vyplněný</strong>
                </span>
      </div>
      <div class="row">
        <div class="col-12 pt-2">
          <button
            (click)="onSubmit()"
            [disabled]="
                            loadingAddress ||
                            addressUntrustedForm.invalid ||
                            !isChecked
                        "
            cssClass="e-btn e-success btn-p-zero e-small mr-1"
            ejs-button
            type="submit"
          >
                        <span
                          *ngIf="loadingAddress === true"
                          aria-hidden="true"
                          class="spinner-grow spinner-grow-sm"
                          role="status"
                        ></span>
            <span *ngIf="loadingAddress === false">ULOŽIT</span>
            <span *ngIf="loadingAddress === true">Provádím...</span>
          </button>
          <button
            (click)="formDialogObj.hide()"
            cssClass="e-btn e-danger e-outline btn-p-zero e-small"
            ejs-button
            type="button"
          >
            ZRUŠIT
          </button>
        </div>
      </div>
    </ng-template>
  </form>
</ejs-dialog>
