import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import {DashboardLayoutComponent} from '@syncfusion/ej2-angular-layouts';
import {TabComponent, ToolbarComponent} from '@syncfusion/ej2-angular-navigations';
import {User} from '@src/app/_models/user/user';
import {AuthenticationService} from '@src/app/_services/authentication.service';
import {SelectEventArgs} from '@syncfusion/ej2-navigations';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {DataService} from '@src/app/_services/data.service';
import {MessageService} from '@src/app/_services/message.service';
import {SignaturesCreatorService} from '@src/app/_services/signatures-creator.service';
import {ExcelService} from '@src/app/_services/excel.service';
import convert from 'xml-js';

import moment from 'moment';
import localeCs from '@angular/common/locales/cs';
import {registerLocaleData} from '@angular/common';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {Address} from '@src/app/_models/services/address';

moment.updateLocale('cs', {workingWeekdays: [1, 2, 3, 4, 5]});
registerLocaleData(localeCs);

interface ExportCalendarXML {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    Name: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    'Given Name': string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    'Family Name': string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    'E-mail 1 - Value': string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    'Phone 1 - Value': string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    'Organization 1 - Name': string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    'Organization 1 - Title': string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    'Organization 1 - Department': string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    'Organization 1 - Location': Address | string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    'Organization 1 - Job Description': string;
}

@UntilDestroy()
@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'app-users',
    templateUrl: './users.component.html',
    styleUrls: ['./users.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UsersComponent implements OnInit {
    // DashBoard
    count = 8;

    cellSpacing: number[] = [10, 10];

    cellAspectRatio: number = 100 / 100;

    // Navigations
    headerText = [{text: 'tabulka'}, {text: 'statistiky'}, {text: 'import'}];

    // Variables
    currentUser: User | null;

    users: User[] | undefined;

    // Maintoolbar
    @ViewChild('moduleToolbar') moduleToolbarObj?: ToolbarComponent;

    // DashBoard
    @ViewChild('default_dashboard') dashboard?: DashboardLayoutComponent;

    // Navigations
    @ViewChild('userTab') userTabObj?: TabComponent;

    constructor(
        private readonly authenticationService: AuthenticationService,
        private readonly route: ActivatedRoute,
        private readonly router: Router,
        private readonly ref: ChangeDetectorRef,
        private readonly signaturesService: SignaturesCreatorService,
        private readonly excelService: ExcelService,
        private readonly dataService: DataService,
        private readonly messages: MessageService,
    ) {
        this.currentUser = this.authenticationService.currentUserValue;
    }

    ngOnInit(): void {
        this.dataService.userSource.pipe(untilDestroyed(this))
            .subscribe(
                (users: User[] | undefined) => {
                    this.users = users;
                },
                error => {
                    console.error(error);
                    this.ref.markForCheck();
                },
            );
    }

    onCreate(): void {
        // Nastavení GUI podle URL parametrů
        this.route.queryParamMap.pipe(untilDestroyed(this))
            .subscribe(queryParams => {
                if (this.userTabObj) {
                    if (queryParams.get('view') === 'grid') {
                        this.userTabObj.select(0);
                    } else if (queryParams.get('view') === 'stats') {
                        this.userTabObj.select(1);
                    } else if (queryParams.get('view') === 'import') {
                        this.userTabObj.select(2);
                    } else {
                        this.userTabObj.select(0);
                    }
                }
            });
    }

    refreshContent(): void {
        setTimeout(() => {
            if (this.dashboard) {
                this.dashboard.refresh();
            }

            if (this.moduleToolbarObj) {
                this.moduleToolbarObj.refresh();
            }
        }, 1000);
    }

    onCloseIconHandler(event: MouseEvent): void {
        if (event.target === null) {
            return;
        }

        const elementOffset = (event.target as HTMLElement).offsetParent;

        if (elementOffset) {
            this.dashboard?.removePanel(elementOffset.id);
        }
    }

    preventSwipe(e: SelectEventArgs): void {
        if (e.isSwiped) {
            e.cancel = true;
        }
    }

    navigateToTab(view: string, form: string): void {
        const queryParams = {view, form};

        if (this.userTabObj) {
            if (view === 'grid') {
                this.userTabObj.select(0);
            } else if (view === 'stats') {
                this.userTabObj.select(1);
            } else if (view === 'import') {
                this.userTabObj.select(2);
            } else {
                this.userTabObj.select(0);
            }
        }

        void this.router.navigate([], {
            relativeTo: this.route,
            queryParams,
            queryParamsHandling: 'merge',
        });
    }

    select(e: SelectEventArgs): void {
        let queryParams: Params = {view: null};

        this.route.queryParamMap.pipe(untilDestroyed(this))
            .subscribe(params => {
                const form = params.get('form') === 'addUser' ? 'addUser' : null;

                if (e.selectedItem.textContent === 'tabulka') {
                    queryParams = {
                        view: 'grid',
                        form,
                    };
                }

                if (e.selectedItem.textContent === 'statistiky') {
                    queryParams = {
                        view: 'stats',
                        form,
                    };
                }

                if (e.selectedItem.textContent === 'import') {
                    queryParams = {
                        view: 'import',
                        form,
                    };
                }

                void this.router.navigate([], {
                    relativeTo: this.route,
                    queryParams,
                    queryParamsHandling: 'merge',
                });
            });
    }

    recreateHtmlSignatures(): void {
        if (this.users && this.users.length > 0) {
            this.signaturesService
                .recreateSignatures(this.users)
                .pipe(untilDestroyed(this))
                .subscribe(
                    () => {
                        const body = 'Podpisy má nyní dostupné každý uživatel na svém profilu';
                        const options = {
                            progressBar: true,
                            timeOut: 5000,
                            toastClass: 'success',
                        };

                        this.messages.showSuccess(
                            'Úspěšně vytvořeny HTML podpisy uživatelů',
                            body,
                            options,
                        );
                        this.dataService.setUsersDataSource();
                        this.ref.markForCheck();
                    },
                    error => {
                        console.error(error);

                        const options = {
                            progressBar: true,
                            timeOut: 5000,
                            toastClass: 'red',
                        };
                        const body = 'Zkuste to znovu později...';

                        this.messages.showError('Chyba u generování HTML podpisů', body, options);
                        this.ref.markForCheck();
                    },
                );
        }
    }

    getCalendarXMLExport(): void {
        const data: ExportCalendarXML[] = [];

        this.users?.forEach((user: User) => {
            data.push({
                Name: user.fullname,
                'Given Name': user.firstname,
                'Family Name': user.secondname,
                'E-mail 1 - Value': user.workemail,
                'Phone 1 - Value': user.telnumber,
                'Organization 1 - Name':
                    user.assignations && user.assignations.length > 0
                        ? user.assignations[0].department.company.name
                        : '',
                'Organization 1 - Title': user.official_position_name,
                'Organization 1 - Department':
                    user.assignations && user.assignations.length > 0
                        ? user.assignations[0].department.name
                        : '',
                'Organization 1 - Location':
                    user.assignations && user.assignations.length > 0
                        ? user.assignations[0].department.company.address_obj
                        : '',
                'Organization 1 - Job Description': user.employer.name,
            });
        });

        const options = {compact: true, ignoreComment: true, spaces: 4};
        const result = convert.json2xml(JSON.stringify(data), options);
        const element = document.createElement('a');
        const blob = new Blob([result], {
            type: 'text/xml',
        });

        element.href = URL.createObjectURL(blob);
        element.setAttribute('download', 'js_contacts.xml');
        document.body.appendChild(element);
        element.click();
    }

    getCalendarCSVExport(): void {
        const data: ExportCalendarXML[] = [];

        this.users?.forEach((user: User) => {
            if (!user.deleted_date) {
                data.push({
                    Name: user.fullname,
                    'Given Name': user.firstname,
                    'Family Name': user.secondname,
                    'E-mail 1 - Value': user.workemail,
                    'Phone 1 - Value': user.telnumber,
                    'Organization 1 - Name':
                        user.assignations && user.assignations.length > 0
                            ? user.assignations[0].department.company.name
                            : '',
                    'Organization 1 - Title': user.official_position_name,
                    'Organization 1 - Department':
                        user.assignations && user.assignations.length > 0
                            ? user.assignations[0].department.name
                            : '',
                    'Organization 1 - Location':
                        user.assignations && user.assignations.length > 0
                            ? user.assignations[0].department.company.address_obj
                            : '',
                    'Organization 1 - Job Description': user.employer.name,
                });
            }
        });
        this.excelService.exportAsCsvFile(data, 'js_contacts');
    }

    resetApiTokens(): void {
        // EMPTY: TODO
    }
}
